const catchErrorDecorator =
    (func: any) =>
    (...rest: any[]) =>
        func.apply(this, rest);

const set = catchErrorDecorator((key: string, value: string) => localStorage.setItem(key, value));
const get = catchErrorDecorator((key: string) => localStorage.getItem(key));
const remove = catchErrorDecorator((key: string) => localStorage.removeItem(key));
const clear = catchErrorDecorator(() => localStorage.clear());

export default {
    set,
    get,
    remove,
    clear,
};
