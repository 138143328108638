<template>
    <AbstractSubSidebarPageWrapper
        :pageTitle="$i18n.t('settings.manualProxyRequest')"
        :isOverviewEnabled="false"
        :isSearchEnabled="false"
    >
        <template #subSidebar>
            <SubSidebar
                v-model="selectedRouteName"
                :sections="permissionFilteredSections"
                class="sidebar"
                @change="changeActiveSection"
            />
        </template>
        <template #content>
            <div class="proxy-section">
                <section>
                    <AppMultiselectV3
                        v-model="requestMethod"
                        :options="requestMethods"
                        :small="true"
                        :showLabels="false"
                        :allowEmpty="false"
                        additionalLabel="Method"
                        label="method"
                        trackBy="method"
                        data-test-id="method"
                    />
                    <AppInputV3
                        key="serviceName"
                        v-model="serviceName"
                        :label="$i18n.t('manualProxyRequest.serviceName')"
                        :placeholder="exampleServiceName"
                    />
                    <AppInputV3
                        key="requestPath"
                        v-model="requestPath"
                        :label="$i18n.t('manualProxyRequest.requestPath')"
                        type="text"
                        class="editor-input-largest mb-3"
                        :placeholder="exampleRequestPath"
                    />
                </section>
                <section>
                    <h3 class="lf-title my-3">
                        {{ $i18n.t('manualProxyRequest.requestHeaders') }}
                    </h3>
                    <AppJSON
                        v-model="requestHeaders"
                        textareaHeight="5rem"
                    />
                </section>
                <section>
                    <h3 class="lf-title my-3">
                        {{ $i18n.t('manualProxyRequest.proxyClaims') }}
                    </h3>
                    <AppJSON
                        v-model="requestClaims"
                        textareaHeight="5rem"
                    />
                </section>
                <section v-if="requestMethod.method === 'POST'">
                    <h3 class="lf-title my-3">
                        {{ $i18n.t('settings.manualProxyRequest') }}
                    </h3>
                    <AppJSON
                        v-model="requestBody"
                        textareaHeight="10rem"
                    />
                </section>
                <section v-if="requestMethod.method === 'GET'">
                    <h3 class="lf-title my-3">
                        {{ $i18n.t('manualProxyRequest.queryParams') }}
                    </h3>
                    <AppJSON
                        v-model="queryParams"
                        textareaHeight="10rem"
                    />
                </section>
                <section>
                    <h3 class="lf-title my-3">
                        {{ $i18n.t('manualProxyRequest.proxyResponse') }}
                    </h3>
                    <AppJSON
                        v-model="responseBody"
                        textareaHeight="15rem"
                    />
                </section>
                <section>
                    <h3 class="lf-title my-3">
                        {{ $i18n.t('manualProxyRequest.proxyResponseHeaders') }}
                    </h3>
                    <AppJSON
                        v-model="responseHeaders"
                        textareaHeight="10rem"
                    />
                </section>
                <section>
                    <div class="button-wrapper">
                        <AppButton
                            :buttonType="BUTTON_TYPES.SECONDARY"
                            :label="$t('manualProxyRequest.copyResponse')"
                            class="mb-3 mr-3"
                            @click="copyToClipboard(JSON.stringify(responseBody))"
                        />
                        <AppButton
                            :buttonType="BUTTON_TYPES.PRIMARY"
                            :label="$i18n.t('manualProxyRequest.submitRequest')"
                            class="smb-3 mr-3"
                            @click="submitRequest"
                        />
                    </div>
                </section>
            </div>
        </template>
    </AbstractSubSidebarPageWrapper>
</template>

<script>
// Components
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import AppMultiselectV3 from '@/components/partials/inputs/AppMultiselectV3.vue';
import { ICON_TYPES } from '@/common/iconHelper';
import AppInputV3 from '@/components/partials/inputs/AppInputV3.vue';
import AbstractSubSidebarPageWrapper from '@/components/layout/AbstractSubSidebarPageWrapper.vue';
import SubSidebarMixin from '@/components/partials/SubSidebarMixin.vue';
import SettingsSubSidebarMixin from '@/__new__/features/settings/SettingsSubSidebarMixin.vue';
import SubSidebar from '@/components/layout/SubSidebar.vue';
import AppJSON from '@/components/partials/AppJSON.vue';

// Helpers
import { copyToClipboard } from '@/common/utils';

// Http
import { manualProxyRequest } from '@/__new__/services/portal/manualProxy/http/manualProxy';

export default {
    name: 'ManualProxyRequest',
    components: {
        AppButton,
        AppInputV3,
        AppMultiselectV3,
        AbstractSubSidebarPageWrapper,
        SubSidebar,
        AppJSON,
    },
    mixins: [SubSidebarMixin, SettingsSubSidebarMixin],
    data() {
        return {
            ICON_TYPES,
            BUTTON_TYPES,
            requestBody: {},
            requestHeaders: {},
            requestClaims: {},
            requestPath: '',
            serviceName: '',
            requestMethod: { method: 'POST' },
            responseBody: {},
            responseHeaders: {},
            queryParams: {},
            exampleServiceName: 'catalog',
            exampleRequestPath: '/api/v3/catalog/offer/get',
            requestMethods: [
                {
                    method: 'POST',
                },
            ],
        };
    },
    methods: {
        submitRequest() {
            this.$withLoadingSpinner(async () => {
                try {
                    const response = await manualProxyRequest({
                        methodType: this.requestMethod.method,
                        serviceName: this.serviceName,
                        servicePath: this.requestPath,
                        bodyContent: this.requestBody,
                        queryParams: this.queryParams ?? {},
                        requestClaims: this.requestClaims,
                        requestHeaders: this.requestHeaders ?? {},
                    });
                    this.responseBody = response.data.proxyBody;
                    this.responseHeaders = response.data.proxyHeaders;
                } catch (e) {
                    this.responseBody = e;
                }
            });
        },
        copyToClipboard,
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/_palette.scss';
@import '~@/assets/scss/_typographyV2';
.proxy-section {
    padding: 2rem 4rem;
    height: 100%;
    position: relative;
    overflow: scroll;
}

.button-wrapper {
    padding-top: 2rem;
    display: flex;
    bottom: 0;
    right: 0;
}
</style>
