
// components
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import AppInputV3 from '@/components/partials/inputs/AppInputV3.vue';
import AppDialogV2 from '@/components/partials/AppDialogV2.vue';
import AppToggleV2 from '@/components/partials/inputs/AppToggleV2.vue';

// helpers
import * as Sentry from '@sentry/vue';
import { ALERT_TYPES } from '@/common/alerts/Alert';
import tableColumnType from '@/common/filterTable';
import { USER_MANAGER_HIERARCHY } from '@/__new__/features/customerCare/common/customerCareHelper';
import luaErrors from '@/common/luaErrors';
import customerCareHTTP from '@/__new__/services/dno/user/http/customer-care';
import { PLURALIZATION } from '@/common/locale/labelSingularOrPlural';
import { isEmpty, cloneDeep } from 'lodash';
import supportButtonMixin from '@/components/alerts/supportButtonMixin';
import { NAME_OF_TRANSACTION_HISTORY_TABLE } from '@/__new__/features/customerCare/common/transactionHistoryHelper';
import refundAPI from '@/http/refund';

export default {
    name: 'RefundDialog',
    components: {
        AppButton,
        AppInputV3,
        AppDialogV2,
        AppToggleV2,
    },
    mixins: [supportButtonMixin],
    props: {
        initTransactionData: {
            required: true,
            type: Object,
        },
        nameOfTableForRefund: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            modalVisible: false,
            transactionData: {},
            refundAmount: null,
            amountForRefund: 0,
            refundAllIsChecked: false,

            isEmpty,
            BUTTON_TYPES,
        };
    },
    computed: {
        columnsDataRefundModal() {
            return [
                {
                    name: this.$i18n.tc('productCatalog.entities.service', PLURALIZATION.SINGULAR),
                    key: 'chargedItemName',
                    field: 'chargedItemName',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('generic.totalAmount'),
                    key: 'chargedItemPrice',
                    formatter: val => this.$localeLibrary.getFormattedAmount(val),
                    field: 'chargedItemPrice',
                    filterType: tableColumnType.NUMBER,
                },
            ];
        },
        formattedRefundEntities() {
            const dataCharged = [];
            if (this.transactionData?.chargesData) {
                this.transactionData.chargesData.forEach((tr, index) => {
                    dataCharged[index] = {
                        ...dataCharged[index],
                        chargedItemName: tr.serviceDescription || tr.offerName,
                    };
                });
            }

            if (this.transactionData?.itemPrices) {
                this.transactionData.itemPrices.forEach((tr, index) => {
                    dataCharged[index] = { ...dataCharged[index], chargedItemPrice: tr.grossAmount };
                });
            }

            dataCharged.forEach((data, index) => {
                dataCharged[index] = {
                    ...dataCharged[index],
                    isChecked: false,
                };
            });
            return dataCharged;
        },
        isPayment() {
            return this.nameOfTableForRefund === NAME_OF_TRANSACTION_HISTORY_TABLE.PAYMENT;
        },
        isBilling() {
            return this.nameOfTableForRefund === NAME_OF_TRANSACTION_HISTORY_TABLE.BILLING;
        },
    },
    created() {
        this.modalVisible = true;
        this.transactionData = cloneDeep(this.initTransactionData);
        if (this.isPayment) {
            this.refundAmount = this.transactionData.amount;
        }
    },
    methods: {
        onCloseModal() {
            this.$emit('close');
        },
        async refundPayment() {
            this.$Progress.start();
            try {
                let refundingAmount = this.amountForRefund;

                if (this.isBilling) {
                    if (this.refundAmount) {
                        refundingAmount = this.refundAmount;
                    }

                    await customerCareHTTP.refundTransaction(
                        this.$route.params.id,
                        USER_MANAGER_HIERARCHY.ACCOUNT,
                        this.transactionData.id,
                        refundingAmount.toString(),
                    );
                } else if (this.isPayment) {
                    await refundAPI.refundOrCancel(
                        this.$route.params.id,
                        USER_MANAGER_HIERARCHY.ACCOUNT,
                        this.transactionData.transaction_id,
                        this.transactionData.transaction_type,
                        this.refundAmount.toString(),
                    );

                    refundingAmount = this.refundAmount;
                }
                this.$Progress.finish();

                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('alertMessage.userManagement.refundSuccessefull', {
                        amount: refundingAmount,
                    }),
                    type: ALERT_TYPES.success,
                });

                this.$emit('refetchTileData');
                this.onCloseModal();
            } catch (error) {
                Sentry.captureException(error);
                this.$Progress.fail();
                if (error.response?.data.code === luaErrors.BILLING_V4.INVALID_REFUND_AMOUNT.code) {
                    this.$eventBus.$emit('showAlert', {
                        message: this.$i18n.t('alertMessage.userManagement.alertMessages.invalidRefundAmount'),
                        type: ALERT_TYPES.error,
                    });
                } else if (error.response?.data.code === luaErrors.BILLING_V4.CHARGE_ALREADY_REFUNDED.code) {
                    this.$eventBus.$emit('showAlert', {
                        message: this.$i18n.t('alertMessage.userManagement.alertMessages.chargeIsAlreadyRefunded'),
                        type: ALERT_TYPES.error,
                    });
                } else {
                    this.showSupportAlert(this.$i18n.t('alertMessage.userManagement.refundError'), ALERT_TYPES.error);
                }
            }
        },
        onRefundOneItemToggled(index) {
            if (this.formattedRefundEntities[index].isChecked) {
                this.amountForRefund += parseFloat(this.formattedRefundEntities[index].chargedItemPrice);

                // if every refund toggle is toggled set refund All toggle as toggled
                const numOfUncheckedRefundToggles = this.formattedRefundEntities.filter(tr => !tr.isChecked);
                if (numOfUncheckedRefundToggles.length === 0) {
                    this.refundAllIsChecked = true;
                }
            } else {
                this.amountForRefund -= parseFloat(this.formattedRefundEntities[index].chargedItemPrice);

                // if we uncheck one toggle button then refund all toggle should be unchecked too
                if (this.refundAllIsChecked) {
                    this.refundAllIsChecked = false;
                }
            }
        },
        onRefundAllToggled() {
            const { refundAllIsChecked, formattedEntities } = this;
            this.amountForRefund = 0;

            if (refundAllIsChecked) {
                formattedEntities.forEach((transaction, index) => {
                    if (!transaction.isChecked) {
                        formattedEntities[index].isChecked = true;
                    }
                    this.amountForRefund += parseFloat(this.formattedRefundEntities[index].chargedItemPrice);
                });
            } else {
                formattedEntities.forEach((transaction, index) => {
                    if (transaction.isChecked) {
                        formattedEntities[index].isChecked = false;
                    }
                });
            }
        },
    },
};
