import Actions, { Mutations, State } from '../mutation-types';
import { getTriggerDefinitions } from '@/__new__/services/dno/triggers/http/triggers';
import { TriggerDefinition } from '@/__new__/services/dno/triggers/models/TriggerDefinition';
import keyBy from 'lodash/keyBy';
import { Module } from 'vuex';

interface ModuleState {
    [State.TRIGGER_DEFINITIONS_BY_ID]: { [key: string]: TriggerDefinition };
}

export default {
    namespaced: true,
    state: {
        [State.TRIGGER_DEFINITIONS_BY_ID]: {},
    },
    mutations: {
        [Mutations.SET_TRIGGER_DEFINITIONS_BY_ID]: (_state, definitionsById) => {
            _state[State.TRIGGER_DEFINITIONS_BY_ID] = definitionsById;
        },
    },
    actions: {
        [Actions.LOAD_TRIGGER_DEFINITIONS]: ({ commit }) =>
            new Promise((resolve, reject) => {
                getTriggerDefinitions()
                    .then(response => {
                        const newTriggerDefinitions = keyBy(response.data, def => def.id);
                        commit(Mutations.SET_TRIGGER_DEFINITIONS_BY_ID, newTriggerDefinitions);
                        resolve(newTriggerDefinitions);
                    })
                    .catch(error => reject(error));
            }),
    },
} as Module<ModuleState, unknown>;
