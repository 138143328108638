<template>
    <div>
        <div>Events occurring within following zones:</div>
        <div class="zones">
            <div class="zone">
                <div>
                    Originating {{ zones.srcCountries.length > 1 ? 'countries' : 'country' }}:
                    <strong>{{ srcCountries }}</strong>
                </div>
                <div>
                    Originating {{ zones.srcNetworks.length > 1 ? 'networks' : 'network' }}:
                    <strong>{{ srcNetworks }}</strong>
                </div>
                <div>
                    Inverted: <strong>{{ srcInverted }}</strong>
                </div>
            </div>
            <div class="zone">
                <div>
                    Destination {{ zones.srcCountries.length > 1 ? 'countries' : 'country' }}:
                    <strong>{{ dstCountries }}</strong>
                </div>
                <div>
                    Destination {{ zones.dstNetworks.length > 1 ? 'networks' : 'network' }}:
                    <strong>{{ dstNetworks }}</strong>
                </div>
                <div>
                    Inverted: <strong>{{ dstInverted }}</strong>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ZoneDetails',
    props: {
        zones: {
            type: Object,
            required: true,
        },
    },
    computed: {
        srcCountries() {
            if (Object.values(this.zones.srcCountries).length === 0) {
                return 'All';
            }
            return this.zones.srcCountries.map(c => c.country).join(', ');
        },
        srcNetworks() {
            if (Object.values(this.zones.srcNetworks).length === 0) {
                return 'All';
            }
            return this.zones.srcNetworks.map(c => c.network).join(', ');
        },
        dstCountries() {
            if (Object.values(this.zones.dstCountries).length === 0) {
                return 'All';
            }
            return this.zones.dstCountries.map(c => c.country).join(', ');
        },
        dstNetworks() {
            if (Object.values(this.zones.dstNetworks).length === 0) {
                return 'All';
            }
            return this.zones.dstNetworks.map(c => c.network).join(', ');
        },
        srcInverted() {
            return this.zones.srcInverted ? 'Yes' : 'No';
        },
        dstInverted() {
            return this.zones.dstInverted ? 'Yes' : 'No';
        },
    },
};
</script>

<style lang="scss" scoped>
@import '../~@/assets/scss/palette';

.zone {
    border-top: 1px solid $dirty-white;
    padding: 4px 0;

    &:first-child {
        border: none;
    }
}
</style>
