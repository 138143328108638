import { FeatureList, OperatorFeatures, Features, FeaturesRestructure } from '@/models/FeaturesHelper';
import { GetFeaturesResponseBody, GetCompanyFeaturesResponseBody } from '@/__new__/services/portal/auth/http/login';

export type OperatorFeaturesList = { [key: number]: string[] };

export type FeatureListRemapped = {
    [key: string]: {
        id: number;
        featureId: string;
        parentFeatureId: number | null;
        rootFeatureId: number | null;
    };
};

export function mapDataFromAPI(
    getFeaturesResponseBody: GetFeaturesResponseBody,
    getCompanyFeaturesResponseBody: GetCompanyFeaturesResponseBody,
) {
    const features: OperatorFeatures = {};
    for (const companyId in getCompanyFeaturesResponseBody) {
        if (Object.prototype.hasOwnProperty.call(getCompanyFeaturesResponseBody, companyId)) {
            const featuresRemap = getCompanyFeaturesResponseBody[companyId].reduce(
                (featuresObj: FeatureListRemapped, key: string) => {
                    if (key && getFeaturesResponseBody[key]) {
                        featuresObj[getFeaturesResponseBody[key].id] = {
                            id: getFeaturesResponseBody[key].id,
                            featureId: getFeaturesResponseBody[key].key,
                            rootFeatureId: getFeaturesResponseBody[key].rootFeatureId,
                            parentFeatureId: getFeaturesResponseBody[key].parentFeatureId,
                        };
                    }
                    return featuresObj;
                },
                {},
            );

            features[companyId] = {};

            for (const feature in featuresRemap) {
                if (Object.prototype.hasOwnProperty.call(featuresRemap, feature)) {
                    const { rootFeatureId, parentFeatureId, featureId } = featuresRemap[feature];

                    if (!rootFeatureId && !parentFeatureId) {
                        features[companyId][featureId] = {
                            enabled: true,
                        };
                    }

                    if (rootFeatureId && rootFeatureId === parentFeatureId) {
                        if (!features[companyId][featuresRemap[parentFeatureId].featureId].features) {
                            features[companyId][featuresRemap[parentFeatureId].featureId].features = {};
                        }
                        features[companyId][featuresRemap[parentFeatureId].featureId].features![featureId] = {
                            enabled: true,
                        };
                    }

                    if (rootFeatureId && parentFeatureId && rootFeatureId !== parentFeatureId) {
                        if (
                            !features[companyId][featuresRemap[rootFeatureId].featureId].features?.[
                                featuresRemap[parentFeatureId].featureId
                            ].features
                        ) {
                            features[companyId][featuresRemap[rootFeatureId].featureId].features![
                                featuresRemap[parentFeatureId].featureId
                            ].features = {};
                        }
                        features[companyId][featuresRemap[rootFeatureId].featureId].features![
                            featuresRemap[parentFeatureId].featureId
                        ].features![featureId] = true;
                    }
                }
            }
        }
    }
    return features;
}

export function mapFeaturesTemplate(featuresList: FeatureList, operatorFeatures: Features) {
    const featuresRestructure: FeaturesRestructure = {};

    const featuresRemap = Object.values(featuresList).reduce((featuresObj: FeatureListRemapped, feature) => {
        featuresObj[feature.id] = {
            id: feature.id,
            featureId: feature.key,
            rootFeatureId: feature.rootFeatureId,
            parentFeatureId: feature.parentFeatureId,
        };
        return featuresObj;
    }, {});

    // Cycle to create object with all features structured
    for (const feature in featuresRemap) {
        if (Object.prototype.hasOwnProperty.call(featuresRemap, feature)) {
            const { rootFeatureId, parentFeatureId, featureId } = featuresRemap[feature];

            if (!rootFeatureId && !parentFeatureId) {
                featuresRestructure[featureId] = {
                    enabled: false,
                    featureId: featuresRemap[feature].id,
                    id: featureId,
                };
            }

            if (rootFeatureId && rootFeatureId === parentFeatureId) {
                if (!featuresRestructure[featuresRemap[parentFeatureId].featureId].features) {
                    featuresRestructure[featuresRemap[parentFeatureId].featureId].features = {};
                }
                featuresRestructure[featuresRemap[parentFeatureId].featureId].features![featureId] = {
                    enabled: false,
                    featureId: featuresRemap[feature].id,
                    id: featureId,
                };
            }

            if (rootFeatureId && parentFeatureId && rootFeatureId !== parentFeatureId) {
                if (
                    !featuresRestructure[featuresRemap[rootFeatureId].featureId].features![
                        featuresRemap[parentFeatureId].featureId
                    ].features
                ) {
                    featuresRestructure[featuresRemap[rootFeatureId].featureId].features![
                        featuresRemap[parentFeatureId].featureId
                    ].features = {};
                }
                featuresRestructure[featuresRemap[rootFeatureId].featureId].features![
                    featuresRemap[parentFeatureId].featureId
                ].features![featureId] = {
                    enabled: false,
                    featureId: featuresRemap[feature].id,
                    id: featureId,
                };
            }
        }
    }

    // Remap data from operator features onto blank template created earlier
    for (const rootFeatureKey in operatorFeatures) {
        if (
            Object.prototype.hasOwnProperty.call(operatorFeatures, rootFeatureKey) &&
            rootFeatureKey &&
            operatorFeatures[rootFeatureKey]
        ) {
            const rootFeature = operatorFeatures[rootFeatureKey];
            featuresRestructure[rootFeatureKey].enabled = rootFeature.enabled;
            if (rootFeature.features) {
                for (const subFeatureKey in rootFeature.features) {
                    if (
                        Object.prototype.hasOwnProperty.call(rootFeature.features, subFeatureKey) &&
                        subFeatureKey &&
                        rootFeature.features[subFeatureKey]
                    ) {
                        const subFeature = rootFeature.features[subFeatureKey];
                        featuresRestructure[rootFeatureKey].features![subFeatureKey].enabled = subFeature.enabled;
                        if (subFeature.features) {
                            for (const featureKey in subFeature.features) {
                                if (
                                    Object.prototype.hasOwnProperty.call(subFeature.features, featureKey) &&
                                    featureKey &&
                                    subFeature.features[featureKey]
                                ) {
                                    const feature = subFeature.features[featureKey];
                                    featuresRestructure[rootFeatureKey].features![subFeatureKey].features![
                                        featureKey
                                    ].enabled = feature;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    return featuresRestructure;
}

export default {
    mapDataFromAPI,
    mapFeaturesTemplate,
};
