var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[(_vm.displayTable)?_c('AbstractTableTile',{attrs:{"entities":_vm.formattedData,"columnsData":_vm.columnsData,"apiResponse":_vm.apiResponse,"entityKey":'index',"tabs":_vm.tabs,"activeTabId":_vm.activeTab,"tableKey":_vm.tableKey},on:{"tabSelected":id => _vm.onTabSelected(id)},scopedSlots:_vm._u([{key:"status",fn:function({ entity }){return [_c('EntityStatusIndicator',{attrs:{"status":entity.status,"stateMap":_vm.stateMam,"stateMapColor":_vm.stateMapColor}})]}},{key:"afterButtons",fn:function(){return [(_vm.isSimSwapEnabled)?_c('AppButton',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                    content: _vm.simSwapDisabledMessage,
                    classes: ['dark'],
                    placement: 'bottom',
                    trigger: 'hover click',
                    autoHide: false,
                }),expression:"{\n                    content: simSwapDisabledMessage,\n                    classes: ['dark'],\n                    placement: 'bottom',\n                    trigger: 'hover click',\n                    autoHide: false,\n                }"}],class:['ml-3', { 'allow-pointer-events': _vm.isSimSwapButtonDisabled }],attrs:{"label":_vm.$i18n.t('authorizationPage.sensitiveOperation.simSwap'),"disabled":_vm.isSimSwapButtonDisabled,"data-test-id":"sim-swap-btn"},on:{"click":function($event){return _vm.simSwap()}}}):_vm._e()]},proxy:true}],null,false,2771804943)}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }