import { find, isEmpty } from 'lodash';
import { PRIORITY_LABELS, PRIORITY_LABELS_INVERT } from '@/common/cepHelper';
import { CampaignTypes } from '@/__new__/services/dno/campaigns/models/Campaign';

export class CampaignCategory {
    id?: string | undefined;
    name: string;
    inboxName: string;
    campaignType: string;
    campaignPriority: string;
    qhRules: any;
    applyLimits: boolean;
    ignoreDailyLimits: boolean;
    limitNumber: string | number;
    limitPerDay: string | number;
    version?: string | number;

    constructor(
        id = '',
        name = '',
        inboxName = '',
        campaignType = '',
        campaignPriority = '',
        qhRules = [],
        applyLimits = false,
        ignoreDailyLimits = false,
        limitNumber = 0,
        limitPerDay = 0,
        version = 0,
    ) {
        this.id = id;
        this.name = name;
        this.inboxName = inboxName;
        this.campaignType = campaignType;
        this.campaignPriority = campaignPriority;
        this.qhRules = qhRules;
        this.applyLimits = applyLimits;
        this.ignoreDailyLimits = ignoreDailyLimits;
        this.limitNumber = limitNumber;
        this.limitPerDay = limitPerDay;
        this.version = version;
    }

    static fromJson(response: any) {
        return new CampaignCategory(
            response.id,
            response.data.name,
            response.data.inbox_name,
            CampaignTypes[response.data.campaign_type]?.label,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            PRIORITY_LABELS[response.data.campaign_priority],
            response.data.quiet_hours_profiles,
            !isEmpty(response.data.limit),
            response.data.ignore_global_daily_limit,
            response.data.limit?.at_most_messages,
            response.data.limit?.per_days,
            response.version,
        );
    }
    buildJson() {
        const BECampaignType = find(CampaignTypes, campaign => campaign.label === this.campaignType)?.value;
        const limitValue = {
            at_most_messages: this.limitNumber,
            per_days: this.limitPerDay,
        };

        return {
            ...(this.version && { version: this.version }),
            ...(this.id && { id: this.id }),
            data: {
                ...(this.id && { id: this.id }),
                name: this.name,
                inbox_name: this.inboxName,
                campaign_type: BECampaignType,
                campaign_priority: Number(PRIORITY_LABELS_INVERT[this.campaignPriority]),
                ...(!isEmpty(this.qhRules) && { quiet_hours_profiles: this.qhRules.map((qh: any) => qh.id) }),
                ...(this.applyLimits && { limit: limitValue }),
                ignore_global_daily_limit: this.ignoreDailyLimits,
            },
        };
    }
}
