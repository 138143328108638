import { Messages } from '@/__new__/features/campaigns/common/Messages';
import { TranslateResult } from 'vue-i18n';
import i18n from '@/i18n';
import { invert } from 'lodash';

export const SECOND = 1000; // ms
export const MINUTE = SECOND * 60; // ms
export const HOUR = MINUTE * 60; // ms

type MessageBuilder = (...args: any[]) => TranslateResult;
type CatchHandler = (msg: string) => void;

/**
 * Get messageBuilder for specified error status or just a message
 */
export function getMessageBuilder(status: string): MessageBuilder | undefined {
    const statusCode = parseInt(status, 10);
    return statusCode && Messages.has(statusCode)
        ? // $FlowFixMe
          Messages.get(statusCode)
        : () => `${status}`; // coerce to string
}

/**
 * Extract ErrorMessageBuilder and calls handler with it
 * Usage of this:
 * callCatchWithStatus(errorMessageBuilder(builder => console.log(builder(messageArgs))));
 */
/* eslint-disable function-paren-newline */
export function errorMessageBuilder(
    errorMessageBuilderHandler: (mb: MessageBuilder | undefined) => TranslateResult,
): (status: string) => TranslateResult {
    return (status: string) => errorMessageBuilderHandler(getMessageBuilder(status));
}

/**
 * Extracts error status from response body
 * or error message.
 */
export function extractErrorInfo(error: any): string {
    return (
        (error.response && error.response.data.details) ||
        // (error.response && error.response.data.status) // todo uncomment server fixes error codes
        error.message
    );
}

/**
 * Extracts error status from response body and calls catch handler with status.
 * Status is a custom field in json, set on server.
 * Usage of this:
 * .catch(callCatchWithStatus(status => console.log(status)));
 */
export function callCatchWithStatus(catchHandler: CatchHandler): (error: any) => void {
    return (error: any) => catchHandler(extractErrorInfo(error));
}

/**
 * Extract error info from `e` and invoke appropriate messageBuilder with `args`
 */
export function getErrorMessage(e: any, ...args: any[]): TranslateResult | undefined {
    return getMessageBuilder(extractErrorInfo(e))?.(args);
}

export function getErrorMessageByCode(status: string, ...args: any[]): TranslateResult | undefined {
    return getMessageBuilder(status)?.(args);
}

export const PRIORITY_LABELS = {
    5: i18n.t('campaigns.priority.theLowest'),
    4: i18n.t('campaigns.priority.low'),
    3: i18n.t('campaigns.priority.medium'),
    2: i18n.t('campaigns.priority.high'),
    1: i18n.t('campaigns.priority.theHighest'),
};
export const PRIORITY_LABELS_INVERT = invert(PRIORITY_LABELS);
