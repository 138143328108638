<template>
    <div>
        <div class="section-content">
            {{ $i18n.t('charging.chargingSpecifications.editor.serviceType') }}
        </div>
        <AppMultiselectV3
            key="conditionServiceType"
            v-model="serviceTypeData.data"
            :options="serviceTypeOptions"
            :showLabels="false"
            :isSmall="true"
            :placeholder="$i18n.t('productCatalog.ratingGroups.chooseRatingGroups')"
            :error="serviceTypeData.dataEmpty"
            :errorMessage="$i18n.t('generic.validations.fieldIsRequired')"
            label="label"
            trackBy="key"
            data-test-id="service-type-condition-select"
            class="editor-input-largest mb-3"
            @input="onDataInput"
        />
    </div>
</template>

<script>
import AppMultiselectV3 from '@/components/partials/inputs/AppMultiselectV3.vue';
import { serviceTypeOptions } from '@/__new__/services/dno/charging/common/chargingSpecificationHelper';
import ServiceTypeCondition from '@/__new__/services/dno/charging/models/chargingSpecificationConditions/ServiceTypeCondition';

export default {
    name: 'ServiceType',
    components: {
        AppMultiselectV3,
    },
    props: {
        value: {
            type: Object,
            default: () => undefined,
        },
    },
    data() {
        return {
            serviceTypeOptions,
            serviceTypeData: new ServiceTypeCondition(this.value),
        };
    },
    watch: {
        value: {
            handler() {
                this.serviceTypeData = new ServiceTypeCondition(this.value);
            },
        },
    },
    methods: {
        onDataInput() {
            this.serviceTypeData.validate();
            this.$emit('input', this.serviceTypeData);
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/_palette.scss';

.section-content {
    font-size: 0.75rem;
    font-weight: 600;
    margin-bottom: 1rem;
    margin-right: 1rem;
    color: $gray90;
}
</style>
