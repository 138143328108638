import Account from '@/models/Account';
import { permissionGroups } from '@/common/roles/roleHelper';
import { LF_TELECOM_TENANT_NAME } from '@/models/Tenant';
import { isViewConfig } from '@/services/permissions/permissions.service';

export function isUserLotusFlareAdmin() {
    if (isViewConfig()) {
        return true;
    }
    try {
        return (
            Account.storedInstance.permissions.includes(permissionGroups.admin.read.key) && // Admin user
            Account.storedInstance.companyName === LF_TELECOM_TENANT_NAME
        ); // Belongs to LF tenant
    } catch (e) {
        return false;
    }
}

export default {};
