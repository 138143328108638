<template>
    <div class="section pb-3">
        <div class="heading mb-3">
            {{ $i18n.t('generic.entityTypeLimits', { entityType: entityTypeModified }) }}
            <span class="text-gray"> ({{ $i18n.t('generic.optional') }}) </span>
        </div>
        <div class="d-flex align-items-center">
            <span class="payout-limits-text">
                {{ $i18n.t('rewards.editor.maxOf') }}
            </span>
            <AppInputV3
                v-model="maxPurchases"
                :invalid="$v.maxPurchases.$error"
                :step="1"
                :disabled="disabled"
                class="mx-3"
                type="number"
                placeholder="0"
                data-test-id="max-purchases"
                @input="emitValue"
            />
            <span class="payout-limits-text">
                {{ $i18n.t('generic.entityTypeLInTotal', { entityType: entityTypeModified }) }}
            </span>
        </div>
        <div class="d-flex align-items-center mt-2">
            <span class="payout-limits-text">
                {{ $i18n.t('rewards.editor.maxOf') }}
            </span>
            <AppInputV3
                v-model="maxPurchasesPerUser"
                :invalid="$v.maxPurchasesPerUser.$error"
                :step="1"
                :disabled="disabled"
                class="mx-3"
                type="number"
                placeholder="0"
                data-test-id="max-purchases-per-user"
                @input="emitValue"
            />
            <span class="payout-limits-text">
                {{ $i18n.t('generic.entityTypePerCustomerInLifetime', { entityType: entityTypeModified }) }}
            </span>
        </div>
        <div class="d-flex align-items-center mt-2">
            <span class="payout-limits-text">{{ $i18n.t('rewards.editor.maxOf') }}</span>
            <AppInputV3
                v-model="maxPurchasesPerDuration.overall.max"
                :invalid="$v.maxPurchasesPerDuration.overall.max.$error"
                :step="1"
                :disabled="disabled"
                data-test-id="overall-max-input"
                class="mx-3"
                type="number"
                placeholder="0"
                @input="emitValue"
            />
            <span class="payout-limits-text">
                {{ $i18n.t('generic.entityTypeOverallEvery', { entityType: entityTypeModified }) }}
            </span>
            <AppInputV3
                v-model="maxPurchasesPerDuration.overall.numberOfDurations"
                :invalid="$v.maxPurchasesPerDuration.overall.numberOfDurations.$error"
                :step="1"
                :disabled="disabled"
                data-test-id="overall-number-of-durations-input"
                class="mx-3"
                type="number"
                placeholder="0"
                @input="emitValue"
            />
            <AppMultiselectV3
                v-model="maxPurchasesPerDuration.overall.duration"
                class="time-select"
                :options="payoutLimitDurationOptions"
                :showLabels="false"
                :borderNone="true"
                :small="true"
                :allowEmpty="false"
                :preselectFirst="true"
                :disabled="disabled"
                label="label"
                trackBy="id"
                optionId="id"
                @input="emitValue"
            />
        </div>
        <div class="d-flex align-items-center mt-2">
            <span class="payout-limits-text">
                {{ $i18n.t('rewards.editor.maxOf') }}
            </span>
            <AppInputV3
                v-model="maxPurchasesPerDuration.perSubscriber.max"
                :invalid="$v.maxPurchasesPerDuration.perSubscriber.max.$error"
                :step="1"
                :disabled="disabled"
                data-test-id="per-subscriber-max-input"
                class="mx-3"
                type="number"
                placeholder="0"
                @input="emitValue"
            />
            <span class="payout-limits-text">
                {{ $i18n.t('generic.entityTypePerCustomerEvery', { entityType: entityTypeModified }) }}
            </span>
            <AppInputV3
                v-model="maxPurchasesPerDuration.perSubscriber.numberOfDurations"
                :invalid="$v.maxPurchasesPerDuration.perSubscriber.numberOfDurations.$error"
                :step="1"
                :disabled="disabled"
                data-test-id="per-subscriber-number-of-durations-input"
                class="mx-3"
                type="number"
                placeholder="0"
                @input="emitValue"
            />
            <AppMultiselectV3
                v-model="maxPurchasesPerDuration.perSubscriber.duration"
                class="time-select"
                :options="payoutLimitDurationOptions"
                :showLabels="false"
                :borderNone="true"
                :small="true"
                :allowEmpty="false"
                :preselectFirst="true"
                :disabled="disabled"
                label="label"
                trackBy="id"
                optionId="id"
                @input="emitValue"
            />
        </div>
    </div>
</template>

<script>
import AppInputV3 from '@/components/partials/inputs/AppInputV3.vue';
import AppMultiselectV3 from '@/components/partials/inputs/AppMultiselectV3.vue';

import { validationMixin } from 'vuelidate';
import { minValue, numeric, requiredIf } from 'vuelidate/lib/validators';
import { payoutLimitDuration } from '@/modules/rewards/common/rewardsRulesHelper';

export default {
    name: 'PayoutLimits',
    components: {
        AppInputV3,
        AppMultiselectV3,
    },
    mixins: [validationMixin],
    props: {
        disabled: {
            type: Boolean,
            default: false,
        },
        value: {
            type: Object,
            // The object that is bound to this component needs to
            // have the following fields
            // maxPurchases: null, // number
            // maxPurchasesPerUser: null, // number
            // maxPurchasesPerDuration: {
            //     overall: {
            //         max: null, // number
            //         duration: null, // id from payoutLimitDurationOptions
            //         numberOfDurations: null, // number
            //     },
            //     perSubscriber: {
            //         max: null, // number
            //         duration: null, // id from payoutLimitDurationOptions
            //         numberOfDurations: null, // number
            //     },
            // },
            default: null,
        },
        entityType: {
            type: String,
            default: '',
        },
    },
    validations() {
        return {
            maxPurchases: {
                numeric,
                minValue: minValue(1),
            },
            maxPurchasesPerUser: {
                numeric,
                minValue: minValue(1),
            },
            maxPurchasesPerDuration: {
                overall: {
                    max: {
                        required: requiredIf(() => !!this.maxPurchasesPerDuration?.overall?.numberOfDurations),
                        numeric,
                        minValue: minValue(1),
                    },
                    numberOfDurations: {
                        required: requiredIf(() => !!this.maxPurchasesPerDuration?.overall?.max),
                        numeric,
                        minValue: minValue(1),
                    },
                },
                perSubscriber: {
                    max: {
                        required: requiredIf(() => !!this.maxPurchasesPerDuration.perSubscriber.numberOfDurations),
                        numeric,
                        minValue: minValue(1),
                    },
                    numberOfDurations: {
                        required: requiredIf(() => !!this.maxPurchasesPerDuration.perSubscriber.max),
                        numeric,
                        minValue: minValue(1),
                    },
                },
            },
        };
    },
    data() {
        return {
            maxPurchases: null,
            maxPurchasesPerUser: null,
            maxPurchasesPerDuration: {
                overall: {
                    max: null,
                    duration: null,
                    numberOfDurations: null,
                },
                perSubscriber: {
                    max: null,
                    duration: null,
                    numberOfDurations: null,
                },
            },
        };
    },
    computed: {
        entityTypeModified() {
            return this.entityType || this.$i18n.t('generic.payout');
        },
        payoutLimitDurationOptions() {
            return [
                {
                    id: 1,
                    label: this.$i18n.t('generic.days').toString().toUpperCase(),
                    value: payoutLimitDuration.DAYS,
                },
                {
                    id: 2,
                    label: this.$i18n.t('generic.weeks').toString().toUpperCase(),
                    value: payoutLimitDuration.WEEKS,
                },
                {
                    id: 3,
                    label: this.$i18n.t('generic.months').toString().toUpperCase(),
                    value: payoutLimitDuration.MONTHS,
                },
                {
                    id: 5,
                    label: this.$i18n.t('generic.years').toString().toUpperCase(),
                    value: payoutLimitDuration.YEARS,
                },
            ];
        },
    },
    created() {
        if (this.value) {
            this.maxPurchases = this.value.max_purchases || null;
            this.maxPurchasesPerUser = this.value.max_purchases_per_user || null;
            if (this.value.max_purchases_per_duration.overall) {
                this.maxPurchasesPerDuration.overall = this.value.max_purchases_per_duration.overall;
            }
            if (this.value.max_purchases_per_duration.per_subscriber) {
                this.maxPurchasesPerDuration.perSubscriber = this.value.max_purchases_per_duration.per_subscriber;
            }
        }
        this.emitValue();
    },
    methods: {
        emitValue() {
            this.$v.$touch();
            const value = {
                error: this.$v.$invalid,
                max_purchases_per_duration: {},
            };
            if (this.maxPurchasesPerDuration.overall.max) {
                value.max_purchases_per_duration.overall = {
                    max: this.maxPurchasesPerDuration.overall.max,
                    duration: this.maxPurchasesPerDuration.overall.duration,
                    number_of_durations: this.maxPurchasesPerDuration.overall.numberOfDurations,
                };
            }
            if (this.maxPurchasesPerDuration.perSubscriber.max) {
                value.max_purchases_per_duration.per_subscriber = {
                    max: this.maxPurchasesPerDuration.perSubscriber.max,
                    duration: this.maxPurchasesPerDuration.perSubscriber.duration,
                    number_of_durations: this.maxPurchasesPerDuration.perSubscriber.numberOfDurations,
                };
            }
            if (this.maxPurchases) {
                value.max_purchases = this.maxPurchases;
            }
            if (this.maxPurchasesPerUser) {
                value.max_purchases_per_user = this.maxPurchasesPerUser;
            }
            this.$emit('input', value);
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/palette';
@import '~@/assets/scss/icons';

$icon-path: '~@/assets/icons/';

.payout-limits-text {
    color: $gray-blue;
    font-size: 0.75rem;
    font-weight: 700;
    text-transform: uppercase;
}

.heading {
    margin: 0;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.63;
    font-size: 1rem;
    color: $gray90;
}

.text-gray {
    color: $gray30;
    font-weight: 400;
}
</style>
