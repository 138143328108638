<template>
    <div
        :class="{ 'vertically-aligned': verticalAlignment }"
        class="list-wrapper"
    >
        <div
            v-for="item in items"
            :key="item.id"
            class="list-item"
            @click="onItemClick(item.id)"
        >
            {{ item.text }}
        </div>
    </div>
</template>
<script>
export default {
    name: 'AppDisplayList',
    props: {
        items: {
            type: Array,
            default: () => [],
        },
        verticalAlignment: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {};
    },
    computed: {},
    methods: {
        onItemClick(id) {
            this.$emit('click', id);
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/palette';

.list-wrapper {
    display: flex;

    .list-item {
        display: flex;
        align-content: center;
        justify-content: center;
        width: auto;
        min-width: 200px;
        margin-right: 1rem;
        border: solid 1px $blue15;
        border-radius: 8px;
        padding: 1.5rem;
        font-size: 1rem;
        color: $blue;
        font-weight: bold;
        cursor: pointer;

        &:hover {
            background-color: $blue15;
            color: $blueDark20;
        }
    }

    &.vertically-aligned {
        flex-direction: column;

        .list-item {
            margin-right: 0;
            margin-bottom: 1rem;
        }
    }
}
</style>
