import UserManagerBaseModel from '@/__new__/services/dno/user/models/UserManagerBaseModel';

export default class BillingStatementCycle extends UserManagerBaseModel {
    id;

    start;

    end;

    constructor({ id, start, end }) {
        // Call constructor of UserManagerBaseModel class for creating new object with unique invalidKeys Map
        super();
        this.id = this.validateString('id', id) ? id : '';
        this.start = this.validateNumberInteger('start', start) ? start : '';
        this.end = this.validateNumberInteger('end', end) ? end : '';
    }

    //   example response of calling /get_billing_cycles
    //   {
    //     "code": 0,
    //     "module": "ORDER",
    //     "server_time": 1588261757,
    //     "billing_cycles": {
    //       "56d686ae-d685-11ea-af76-0242ac110002": {
    //         "end_time": 1599807599,
    //         "start_time": 1597129200
    //       },
    //       "4a1bf99e-d685-11ea-af76-0242ac110002": {
    //         "end_time": 1597129199,
    //         "start_time": 1596567855
    //       }
    //     }
    //   }
    static remapBillingCyclesFromBe(response) {
        const billingCyclesMapById = response.billing_cycles;
        const billingCycles = Object.keys(billingCyclesMapById).map(key => ({
            id: key,
            start: billingCyclesMapById[key].start_time,
            end: billingCyclesMapById[key].end_time,
        }));
        return billingCycles;
    }

    static remapRecurringCyclesFromBe(response) {
        const recurrenceCyclesMapById = response.recurrence_cycles;
        const recurrenceCycles = Object.keys(recurrenceCyclesMapById).map(key => ({
            id: key,
            start: recurrenceCyclesMapById[key].start_time,
            end: recurrenceCyclesMapById[key].end_time,
        }));
        return recurrenceCycles;
    }

    static remapOMDEBillingsFromBe(response) {
        const billings = response.map(billing => ({
            id: billing.billing_id,
            start: billing.billing_data.start_time,
            end: billing.billing_data.end_time,
        }));
        return billings;
    }

    static remapTransactionsFromBe(response) {
        const transactions = response.map(transaction => {
            // since we could have few offers with separate start_time and end_time
            // we need min date for start_time and max date for end_time
            let endTime = '';
            let startTime = '';

            // eslint-disable-next-line camelcase
            const chargesDataArray = transaction?.data?.misc?.charges_data || [];
            // eslint-disable-next-line camelcase
            const startTimeArray = chargesDataArray.filter(charge => charge?.start_time);
            // eslint-disable-next-line camelcase
            const endTimeArray = chargesDataArray.filter(charge => charge?.end_time);

            if (endTimeArray.length > 0) {
                const allEndTimes = endTimeArray.map(charge => charge.end_time);
                endTime = Math.max(allEndTimes);
            } else {
                const { end_time: endTimeForTransaction = '' } = transaction?.data;
                endTime = endTimeForTransaction || '';
            }

            if (startTimeArray.length) {
                const allStartTimes = endTimeArray.map(charge => charge.start_time);
                startTime = Math.min(allStartTimes);
            } else {
                const { start_time: startTimeForTransaction = '' } = transaction?.data;
                startTime = startTimeForTransaction || '';
            }

            return {
                id: transaction.id,
                start: startTime,
                end: endTime,
                state: transaction?.state || '',
                // eslint-disable-next-line camelcase
                isRefund: transaction?.data?.refund?.refund_transaction || false,
            };
        });
        return transactions;
    }
}
