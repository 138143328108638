<template>
    <div
        v-if="languages.length > 1"
        class="d-flex align-items-center"
    >
        <AppMultiselectV3
            :value="mapValue"
            :options="languages"
            :showLabels="false"
            :borderNone="true"
            :blueArrow="true"
            :small="true"
            :allowEmpty="false"
            :preselectFirst="true"
            label="label"
            trackBy="key"
            :placeholder="'Pick language'"
            class="language-picker lf-all-caps"
            @input="onLanguagePicked"
        />
        <div v-if="tooltipEnabled">
            <AppTooltip
                :offset="20"
                :tooltipPosition="TOOLTIP_POSITION.bottom"
            >
                <template #label>
                    <div class="info-icon tooltip-position" />
                </template>

                <template #content>
                    <div class="lf-primary-text">
                        {{ $i18n.t('productCatalog.products.editor.tooltips.multipleLanguages') }}
                        <ul>
                            <li>{{ $i18n.t('generic.name') }}</li>
                            <li>{{ $i18n.t('generic.description') }}</li>
                        </ul>
                    </div>
                </template>
            </AppTooltip>
        </div>
    </div>
</template>

<script>
import AppMultiselectV3 from '@/components/partials/inputs/AppMultiselectV3.vue';
import AppTooltip from '@/components/partials/AppTooltip.vue';
import { TOOLTIP_POSITION } from '@/common/tooltip';
import { State } from '@/store/mutation-types';

export default {
    name: 'LanguageSwitcher',
    components: {
        AppMultiselectV3,
        AppTooltip,
    },
    props: {
        value: {
            type: String,
            required: true,
        },
        tooltipEnabled: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            TOOLTIP_POSITION,
        };
    },
    computed: {
        languages() {
            return this.$store.state.operators[State.Languages];
        },
        mapValue() {
            return this.languages.find(lang => lang.key === this.value);
        },
    },
    methods: {
        onLanguagePicked(language) {
            this.$emit('input', language.key);
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/layout';
@import '~@/assets/scss/_typographyV2';
@import '~@/assets/scss/palette';

.language-picker {
    width: 7.3125rem;
    margin-left: $spacing-xl;
}

.tooltip-position {
    position: absolute;
    bottom: -5px;
}

ul li::before {
    content: '\2022'; /* Add content: \2022 is the CSS Code/unicode for a bullet */
    color: $blue;
    display: inline-block;
    width: 1em;
}
</style>
