import { uuidV4 } from '@/common/utils';

class Threshold {
    constructor(value, message) {
        this.id = uuidV4();
        this.value = value;
        this.message = message;
    }
}

export default Threshold;
