<template>
    <div>
        <div
            v-for="({ actionTrigger, actionFlag }, index) in actionsModel"
            :key="`counter-model-${index}`"
            :data-test-id="`action-${index}`"
        >
            <div class="d-flex justify-content-between">
                <div class="d-flex align-items-center">
                    <div class="action-label">
                        {{ $i18n.t('generic.action') }}
                        {{ index + 1 }}
                    </div>
                </div>
                <AppButton
                    v-if="index !== 0"
                    :label="$i18n.t('generic.remove')"
                    :buttonType="BUTTON_TYPES.TERTIARY"
                    :isSmall="true"
                    :disabled="disabled"
                    :data-test-id="`remove-action-${index}`"
                    @click="removeAction(index)"
                />
            </div>
            <div class="mb-4 pb-4 action-wrapper">
                <AppMultiselectV3
                    v-model="actionFlag.type"
                    :options="actionsOptions"
                    :error="validationModelError(index, 'actionFlag', 'type')"
                    :additionalLabel="$i18n.t('charging.chargingSpecifications.editor.actionToPerform')"
                    :placeholder="$i18n.t('charging.chargingSpecifications.editor.chooseActionPerform')"
                    label="label"
                    trackBy="id"
                    :data-test-id="`action-action-type-${index}`"
                    :disabled="disabled"
                    class="editor-input-largest mb-3"
                    @input="onChangeActionFlagType(actionFlag.type, index)"
                />
                <div class="d-flex col mb-3">
                    <div
                        v-if="showActionFlagName(index)"
                        class="d-flex align-items-center"
                    >
                        <div class="inline-text">
                            {{ $i18n.t('charging.chargingSpecifications.editor.actionsBucketFlag') }}
                        </div>
                        <AppInputV3
                            v-model="actionFlag.name"
                            :invalid="validationModelError(index, 'actionFlag', 'name')"
                            :placeholder="$i18n.t('charging.chargingSpecifications.editor.actionsAddBucketFlag')"
                            :disabled="disabled"
                            :data-test-id="`action-flag-name-${index}`"
                        />
                    </div>
                    <div
                        v-if="showActionFlagValue(index)"
                        class="d-flex align-items-center"
                    >
                        <div class="inline-text ml-2">
                            {{ $i18n.t('charging.chargingSpecifications.editor.setValueTo') }}
                        </div>
                        <AppToggle
                            v-model="actionFlag.flagValue"
                            :small="true"
                            :disabled="disabled"
                        />
                    </div>
                </div>
                <AppMultiselectV3
                    v-model="actionTrigger.type"
                    :options="triggersOptions"
                    :error="validationModelError(index, 'actionTrigger', 'type')"
                    :additionalLabel="$i18n.t('charging.chargingSpecifications.editor.actionTrigger')"
                    :placeholder="$i18n.t('charging.chargingSpecifications.editor.chooseAt')"
                    :disabled="disabled"
                    label="label"
                    trackBy="id"
                    :data-test-id="`actions-trigger-type-${index}`"
                    class="editor-input-largest mb-3"
                />
                <div
                    v-if="showActionUcTreshold(index)"
                    class="d-flex align-items-center col mb-3"
                >
                    <div class="inline-text">
                        {{ $i18n.tc('charging.usageCounters.name', PLURALIZATION.SINGULAR) }}
                    </div>
                    <AppMultiselectV3
                        v-model="actionTrigger.usageCounter"
                        :options="selectedUsageCounters"
                        :error="validationModelError(index, 'actionTrigger', 'usageCounter')"
                        :placeholder="$i18n.t('charging.chargingSpecifications.editor.chooseUsageCounter')"
                        :disabled="disabled"
                        label="label"
                        trackBy="id"
                        :data-test-id="`actions-usage-counter-${index}`"
                        class="editor-input-large"
                    />
                    <div
                        v-if="showActionTriggerThresholdCalcType(index)"
                        class="inline-text ml-2"
                    >
                        {{ $i18n.t('generic.reaches') }}
                    </div>
                    <div
                        v-if="showActionTriggerNotificationPercentage(index)"
                        class="inline-text ml-2"
                    >
                        <div class="d-flex align-items-center">
                            <AppInputV3
                                v-model.number="actionTrigger.notificationPercentage"
                                :type="'number'"
                                :invalid="validationModelError(index, 'actionTrigger', 'notificationPercentage')"
                                :min="1"
                                :max="100"
                                :disabled="disabled"
                                :data-test-id="`notification-percentage-${index}`"
                            />
                            <div class="inline-text ml-2">%</div>
                        </div>
                    </div>
                    <AppMultiselectV3
                        v-if="showActionTriggerThresholdCalcType(index)"
                        v-model="actionTrigger.thresholdCalcType"
                        :options="Object.values(thresholdCalculationTypesMapper)"
                        :error="validationModelError(index, 'actionTrigger', 'thresholdCalcType')"
                        :placeholder="$i18n.t('charging.chargingSpecifications.editor.thresholdCalc')"
                        :disabled="disabled"
                        label="label"
                        trackBy="id"
                        :data-test-id="`actions-threshold-calc-type-${index}`"
                        class="editor-input-large"
                    />
                </div>
                <div
                    v-if="showActionTriggerUcThreshold(index)"
                    class="mb-3"
                >
                    <div class="d-flex align-items-center col">
                        <UnitPicker
                            v-model.number="actionTrigger.ucThreshold"
                            :label="$i18n.t('charging.chargingSpecifications.editor.setThreshold')"
                            :error="validationModelError(index, 'actionTrigger', 'ucThreshold')"
                            :min="0"
                            :definitionsWidth="5"
                            :type="getFormulaCalculatedThreshold"
                            :isEditorDesign="true"
                            :disabled="disabled"
                            placeholder="123"
                            :data-test-id="`actions-uc-threshold-${index}`"
                        />
                    </div>
                </div>
            </div>
        </div>
        <AppButton
            :label="$i18n.t('charging.chargingSpecifications.editor.addCsAction')"
            :iconType="ICON_TYPES.PLUS"
            :isSmall="true"
            :disabled="disabled"
            data-test-id="add-action"
            @click="createNewCsAction()"
        />
    </div>
</template>

<script>
import AppMultiselectV3 from '@/components/partials/inputs/AppMultiselectV3.vue';
import AppInputV3 from '@/components/partials/inputs/AppInputV3.vue';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import { ICON_TYPES } from '@/common/iconHelper';
import UnitPicker from '@/components/partials/inputs/UnitPicker.vue';
import AppToggle from '@/components/partials/inputs/AppToggle.vue';
import { CHARGING_SPEC_TYPE } from '@/__new__/services/dno/charging/common/chargingSpecificationHelper';
import {
    TRIGGER_TYPES,
    ACTION_TYPES_OPTIONS,
    THRESHOLD_CALCULATION_TYPES,
    thresholdCalculationTypesMapper,
} from '@/__new__/services/dno/charging/common/chargingActionsHelper';
import { unitTypes } from '@/common/formatting';
import ActionModel from '@/__new__/services/dno/charging/models/ActionModel';
import { PLURALIZATION } from '@/common/locale/labelSingularOrPlural';

export default {
    name: 'ChargingActions',
    components: {
        AppMultiselectV3,
        AppInputV3,
        AppButton,
        UnitPicker,
        AppToggle,
    },
    props: {
        actionsOptions: {
            type: Array,
            require: true,
            default: () => [],
        },
        triggersOptions: {
            type: Array,
            require: true,
            default: () => [],
        },
        actionsModel: {
            type: Array,
            require: true,
            default: () => [],
        },
        validationsModel: {
            type: Object,
            require: true,
            default: () => undefined,
        },
        selectedUsageCounters: {
            type: Array,
            default: () => [],
        },
        selectedCSType: {
            type: Object,
            default: () => undefined,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            ICON_TYPES,
            BUTTON_TYPES,
            CHARGING_SPEC_TYPE,
            TRIGGER_TYPES,
            PLURALIZATION,
            thresholdCalculationTypesMapper,
        };
    },
    computed: {
        getFormulaCalculatedThreshold() {
            return this.isSelectedCSTypeEqualTo(CHARGING_SPEC_TYPE.voice.id) ? unitTypes.DURATION : unitTypes.DATA;
        },
    },
    methods: {
        isSelectedCSTypeEqualTo(type) {
            return this.selectedCSType?.id === type;
        },
        showActionFlagName(index) {
            const actionFlagType = this.actionsModel?.[index]?.actionFlag?.type?.id;
            return (
                actionFlagType === ACTION_TYPES_OPTIONS.BUCKET_FLAG ||
                actionFlagType === ACTION_TYPES_OPTIONS.SET_SUBSCRIBER_LEVEL_FLAG
            );
        },
        showActionFlagValue(index) {
            return this.actionsModel?.[index]?.actionFlag?.type?.id === ACTION_TYPES_OPTIONS.SET_SUBSCRIBER_LEVEL_FLAG;
        },
        showActionUcTreshold(index) {
            const actionTriggerId = this.actionsModel?.[index]?.actionTrigger?.type?.id;
            return (
                actionTriggerId === this.TRIGGER_TYPES.US_THRESHOLD ||
                actionTriggerId === this.TRIGGER_TYPES.THRESHOLD_PERCENTAGE
            );
        },
        showActionTriggerUcThreshold(index) {
            return (
                this.actionsModel?.[index]?.actionTrigger?.thresholdCalcType?.id ===
                THRESHOLD_CALCULATION_TYPES.SET_THRESHOLD_MANUALLY
            );
        },
        showActionTriggerNotificationPercentage(index) {
            const { actionFlag, actionTrigger } = this.actionsModel?.[index];
            return (
                actionFlag?.type?.id === ACTION_TYPES_OPTIONS.NOTIFICATION ||
                actionTrigger?.type?.id === this.TRIGGER_TYPES.THRESHOLD_PERCENTAGE
            );
        },
        showActionTriggerThresholdCalcType(index) {
            return this.actionsModel?.[index]?.actionTrigger?.type?.id === this.TRIGGER_TYPES.US_THRESHOLD;
        },
        onChangeActionFlagType(data, index) {
            if (this.actionsModel?.[index]?.actionTrigger) {
                this.actionsModel[index].actionTrigger.enablePerceptions =
                    data?.id === ACTION_TYPES_OPTIONS.NOTIFICATION;
            }
        },
        removeAction(index) {
            this.actionsModel.splice(index, 1);
        },
        createNewCsAction() {
            this.actionsModel.push(new ActionModel());
        },
        validationModelError(index, type, key) {
            return this.validationsModel?.$each?.[index]?.[type]?.[key]?.$error;
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/editor-layout-v2';
@import '~@/assets/scss/typographyV2';
@import '~@/assets/scss/palette';
@import '~@/assets/scss/icons';
@import '~@/assets/scss/z-indexes';

.action-wrapper {
    margin-top: 0.75rem;
    padding-top: 0.75rem;
    border-top: solid 0.1rem $blue15;
    border-bottom: solid 0.1rem $blue15;
}

.action-label {
    text-transform: uppercase;
    font-size: 0.625rem;
    font-weight: bold;
    line-height: 2;
    color: $steel;
    margin-right: 0.75rem;
}
</style>
