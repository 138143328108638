
import AbstractEditPageWrapper from '@/components/layout/AbstractEditPageWrapper.vue';
import AppHeader from '@/components/layout/AppHeader.vue';
import AppInputV3 from '@/components/partials/inputs/AppInputV3.vue';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import IconButton from '@/components/partials/IconButton.vue';

import { ICON_TYPES } from '@/common/iconHelper';
import {
    ApplicationDetailsResponse,
    getApplicationByAppClientId,
} from '@/__new__/services/dno/ossdevedge/http/ossdevedge';
import ApplicationData from '@/__new__/services/dno/ossdevedge/models/ApplicationData';
import { type AxiosResponse } from 'axios';
import luaErrorCodes, { modules } from '@/common/luaErrors';
import * as Sentry from '@sentry/vue';

import { getRouteNameFromTargetType } from '@/__new__/features/customerCare/common/customerCareHelper';
import { isEqual } from 'lodash';
import { TARGET_TYPE } from '@/__new__/services/dno/user/models/targetTuple';
import { getAccount } from '@/__new__/services/dno/user/userManagerService';
import Account from '@/__new__/services/dno/user/models/Account';
import { copyToClipboard } from '@/common/utils';

export default {
    name: 'DevedgeApplication',
    components: {
        AbstractEditPageWrapper,
        AppHeader,
        AppInputV3,
        AppButton,
        IconButton,
    },
    props: {},
    data() {
        return {
            BUTTON_TYPES,
            ICON_TYPES,
            getRouteNameFromTargetType,
            // Account that owns the application
            account: null as Account | null,
            apiResponse: null as AxiosResponse<ApplicationDetailsResponse> | null,
            applicationData: null as ApplicationData | null,
            pageTitle: this.$t('devedgeApplications.applications'),
            searchString: '',
            showSearchStringError: false,
        };
    },
    methods: {
        copyToClipboard,
        isEqual,
        async onSearch() {
            this.showSearchStringError = false;
            if (!this.searchString) {
                this.showSearchStringError = true;
                return;
            }
            await this.$withLoadingSpinner(async () => {
                try {
                    this.apiResponse = await getApplicationByAppClientId(this.searchString);
                    this.applicationData = new ApplicationData(
                        ApplicationData.mapResourceFromBE(this.apiResponse?.data?.app),
                    );
                    // Get owner account
                    if (this.applicationData?.targetType === TARGET_TYPE.ACCOUNT && this.applicationData?.targetId) {
                        this.account = await getAccount(this.applicationData.targetId);
                    }
                } catch (error) {
                    const { module, code } = { ...error?.response?.data };
                    if (module === modules.OSS_DEVEDGE && code === luaErrorCodes.APP_NOT_FOUND_IN_REVERSE_INDEX_TABLE) {
                        this.$alert(this.$t('devedgeApplications.applicationMatchingCriteriaNotFound'));
                    } else {
                        this.$alert(this.$t('devedgeApplications.errorOccurredWhileSearching'));
                        Sentry.captureException(error);
                    }
                }
            });
        },
    },
};
