
import Vue from 'vue';

// Vuex
import { mapGetters, mapMutations } from 'vuex';
import { Modules } from '@/store/store';
import { Getters, Mutations } from '@/store/mutation-types';

// HTTP
import customerCareHTTP from '@/__new__/services/dno/user/http/customer-care';

// Helpers
import { isUserAllowed, isViewEnabled } from '@/services/permissions/permissions.service';
import {
    USER_API_KEY_MANAGER_HIERARCHY,
    USER_MANAGER_HIERARCHY,
} from '@/__new__/features/customerCare/common/customerCareHelper';

export default Vue.extend({
    name: 'EndUserAuthorizationMixin',
    computed: {
        ...mapGetters(Modules.endUserAuthorization, [
            Getters.GET_AUTH_TOKEN_1FA,
            Getters.GET_AUTH_2FA_SUCCESSFUL,
            Getters.GET_AUTH_USER_ID_FOR_AUTH_STATE_MAPPING,
        ]),
        viewedEntityId(): number {
            return this.$route.params.id || this.$route.params.targetId;
        },
        viewedEntityTypeEnum(): USER_MANAGER_HIERARCHY | null {
            const { path } = this.$route;

            if (this.$route.params?.targetType) {
                return Number(this.$route.params.targetType);
            }

            const [, targetType] = path.match(/\/customer-care-suite\/(account|user|subscriber|organization)\//);
            return (USER_API_KEY_MANAGER_HIERARCHY as any)?.[`${targetType}_id`] || null;
        },
        isEndUserAuthorizedForUm(): boolean {
            // tenant specific permission, if is not enabled on tenet level don't use 2fa process for end user
            if (!isViewEnabled('UMendUserAuthorization')) {
                return true;
            }

            // if end user completed 2fa process and session exists don't force end user to do it again
            if (this[Getters.GET_AUTH_2FA_SUCCESSFUL](this[Getters.GET_AUTH_USER_ID_FOR_AUTH_STATE_MAPPING])) {
                return true;
            }

            // if portal has 1fa token, end user has authorization on AWS connect
            // and there is no OTP permission on tenant level doesn't show 2fa process to end user
            if (Boolean(this[Getters.GET_AUTH_TOKEN_1FA]) && !isViewEnabled('UMuseOtpforUserAuthorization')) {
                this[Mutations.UPDATE_AUTH_2FA_SUCCESSFUL_STATUS]({
                    targetId: this[Getters.GET_AUTH_USER_ID_FOR_AUTH_STATE_MAPPING],
                    authState: true,
                });
                return true;
            }

            return isUserAllowed('UMEndUserFullDataAccess');
        },
    },
    methods: {
        ...mapMutations(Modules.endUserAuthorization, [
            Mutations.UPDATE_AUTH_2FA_SUCCESSFUL_STATUS,
            Mutations.SET_AUTH_USER_ID_FOR_AUTH_STATE_MAPPING,
        ]),
        async getUserIdFromNonSensitiveInfo(): Promise<string> {
            if (!this.viewedEntityId) {
                return '';
            }

            if (this[Getters.GET_AUTH_USER_ID_FOR_AUTH_STATE_MAPPING]) {
                return this[Getters.GET_AUTH_USER_ID_FOR_AUTH_STATE_MAPPING];
            }

            const {
                data: { basic_info: basicInfo },
            } = await customerCareHTTP.getNonSensitiveInfo(this.viewedEntityId, this.viewedEntityTypeEnum);

            switch (this.viewedEntityTypeEnum) {
                case USER_MANAGER_HIERARCHY.USER:
                    this[Mutations.SET_AUTH_USER_ID_FOR_AUTH_STATE_MAPPING](this.viewedEntityId);
                    break;
                case USER_MANAGER_HIERARCHY.ACCOUNT:
                    this[Mutations.SET_AUTH_USER_ID_FOR_AUTH_STATE_MAPPING](
                        basicInfo?.account_members?.[0]?.user_id || '',
                    );
                    break;
                case USER_MANAGER_HIERARCHY.SUBSCRIBER:
                    this[Mutations.SET_AUTH_USER_ID_FOR_AUTH_STATE_MAPPING](basicInfo?.user_id || '');
                    break;
                default:
                    this[Mutations.SET_AUTH_USER_ID_FOR_AUTH_STATE_MAPPING]('');
            }

            return this[Getters.GET_AUTH_USER_ID_FOR_AUTH_STATE_MAPPING];
        },
    },
});
