/* eslint-disable camelcase */
import ProductCatalogEntityBase from '@/__new__/services/dno/pc/models/ProductCatalogEntityBase';

import { serviceReservedKeys } from '@/__new__/features/pc/common/entitiesReservedKeys';

class ProductCatalogServiceModel extends ProductCatalogEntityBase {
    properties;

    type;

    constructor(entityJson) {
        super(entityJson);

        this.type = entityJson.data.type;

        // seting template engine properties data here
        this.properties = Object.keys(entityJson.data)
            .filter(key => !serviceReservedKeys.includes(key))
            .reduce((result, key) => {
                result[key] = entityJson.data[key];
                return result;
            }, {});
    }

    // to parse data for the API call
    static toJson(entityData) {
        const genericDataFromatted = super.toJson(entityData);

        return {
            ...genericDataFromatted,
        };
    }
}

export default ProductCatalogServiceModel;
