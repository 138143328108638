import Vue from 'vue';
import Actions, { Mutations, State } from '@/store/mutation-types';
import { getProfileInfo } from '@/__new__/services/dno/esim/http/esim';
import EsimProfileData from '@/__new__/services/dno/esim/models/EsimProfileData';

export default {
    namespaced: true,
    state: {
        // EsimProfileData structure
        [State.ESIM_DATA]: new EsimProfileData({}),
    },
    mutations: {
        [Mutations.SET_ESIM_DATA]: (state, esimData) => {
            Vue.set(state, State.ESIM_DATA, esimData);
        },
    },
    actions: {
        async [Actions.REQUEST_ESIM_DATA]({ commit }, iccid) {
            const esimProfileData = new EsimProfileData((await getProfileInfo(iccid)).data);
            commit(Mutations.SET_ESIM_DATA, esimProfileData);
            return esimProfileData.iccid;
        },
    },
};
