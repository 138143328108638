<template>
    <div class="zone-block flexbox-column">
        <div class="input-fields">
            <div class="d-flex justify-content-between">
                <div class="grow">
                    <div class="d-flex row">
                        <div class="col-6">
                            <div>
                                <div class="zone-title">
                                    {{ $i18n.t('charging.chargingSpecifications.editor.originatingCountry') }}
                                </div>
                                <TagsMultiselect
                                    v-model="zoneData.data.srcCountries"
                                    :options="countriesList"
                                    :placeholder="$i18n.t('charging.chargingSpecifications.editor.chooseCountries')"
                                    :labelFormatter="e => e.country"
                                    :wrapHeader="false"
                                    :explanation="$i18n.t('messages.countryConditionMessage')"
                                    label="country"
                                    trackBy="id"
                                    data-test-id="src-countries"
                                    @input="onChangeSrcCountries"
                                >
                                    <template #beforeMultiselect>
                                        <AppMultiselectV3
                                            v-model="zoneData.data.srcInverted"
                                            :allowEmpty="false"
                                            :options="Object.values(options)"
                                            :searchable="false"
                                            :showLabels="false"
                                            :borderNone="true"
                                            :blueArrow="true"
                                            class="multiselect small condition-options"
                                            label="label"
                                            trackBy="id"
                                            @input="onChangeSrcCountries"
                                        />
                                    </template>
                                </TagsMultiselect>
                            </div>
                        </div>
                        <div class="col-6">
                            <div>
                                <div class="zone-title">
                                    {{ $i18n.t('charging.chargingSpecifications.editor.originatingOperator') }}
                                </div>
                                <TagsMultiselect
                                    v-model="zoneData.data.srcNetworks"
                                    :options="networkListSrc"
                                    :placeholder="srcOperatorPlaceholder"
                                    :labelFormatter="e => e.network"
                                    :explanation="$i18n.t('messages.operatorConditionMessage')"
                                    :wrapHeader="false"
                                    label="network"
                                    trackBy="id"
                                    @input="emitData"
                                />
                            </div>
                        </div>
                    </div>
                    <div
                        v-if="!disableDestination"
                        class="d-flex row"
                    >
                        <div class="col-6">
                            <div>
                                <div class="zone-title">
                                    {{ $i18n.t('charging.chargingSpecifications.editor.destinationCountry') }}
                                </div>
                                <TagsMultiselect
                                    v-model="zoneData.data.dstCountries"
                                    :options="countriesList"
                                    :placeholder="$i18n.t('charging.chargingSpecifications.editor.chooseCountries')"
                                    :labelFormatter="e => e.country"
                                    :wrapHeader="false"
                                    :explanation="$i18n.t('messages.countryConditionMessage')"
                                    label="country"
                                    trackBy="id"
                                    data-test-id="dst-countries"
                                    @input="onChangeDstCountries"
                                >
                                    <template #beforeMultiselect>
                                        <AppMultiselectV3
                                            v-model="zoneData.data.dstInverted"
                                            :allowEmpty="false"
                                            :options="Object.values(options)"
                                            :searchable="false"
                                            :showLabels="false"
                                            :borderNone="true"
                                            :blueArrow="true"
                                            class="multiselect small condition-options"
                                            label="label"
                                            trackBy="id"
                                            @input="onChangeDstCountries"
                                        />
                                    </template>
                                </TagsMultiselect>
                            </div>
                        </div>
                        <div class="col-6">
                            <div>
                                <div class="zone-title">
                                    {{ $i18n.t('charging.chargingSpecifications.editor.destinationOperator') }}
                                </div>
                                <TagsMultiselect
                                    v-model="zoneData.data.dstNetworks"
                                    :options="networkListDst"
                                    :placeholder="dstOperatorPlaceholder"
                                    :labelFormatter="e => e.network"
                                    :explanation="$i18n.t('messages.operatorConditionMessage')"
                                    :wrapHeader="false"
                                    label="network"
                                    trackBy="id"
                                    @input="emitData"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// Components
import AppMultiselectV3 from '@/components/partials/inputs/AppMultiselectV3.vue';
import TagsMultiselect from '@/components/partials/inputs/TagsMultiselect.vue';

// Helpers
import Zones, { csConditionZoneOptions } from '@/__new__/services/dno/charging/common/zone/zones';
import { countries, networksForCountrySelected } from '@/__new__/services/dno/charging/common/zone/zoneHelper';
import ZoneCondition from '@/__new__/services/dno/charging/models/chargingSpecificationConditions/ZoneCondition';

export default {
    name: 'Zone',
    components: {
        AppMultiselectV3,
        TagsMultiselect,
    },
    props: {
        value: {
            type: Object,
            default: null,
        },
        disableDestination: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            zone: {
                srcCountries: [],
                srcNetworks: [],
                dstCountries: [],
                dstNetworks: [],
                srcInverted: csConditionZoneOptions.isNot,
                dstInverted: csConditionZoneOptions.isNot,
            },
            networkListSrc: [],
            networkListDst: [],
            countriesList: countries(Zones),
            options: csConditionZoneOptions,
            zoneData: null,
        };
    },
    computed: {
        srcOperatorPlaceholder() {
            if (Object.values(this.zoneData.data.srcNetworks).length < 1) {
                return this.$i18n.t('charging.chargingSpecifications.editor.allOperatorsSelected');
            }
            return this.$i18n.t('charging.chargingSpecifications.editor.chooseOperator');
        },
        dstOperatorPlaceholder() {
            if (Object.values(this.zoneData.data.dstNetworks).length < 1) {
                return this.$i18n.t('charging.chargingSpecifications.editor.allOperatorsSelected');
            }
            return this.$i18n.t('charging.chargingSpecifications.editor.chooseOperator');
        },
    },
    watch: {
        value: {
            handler(value) {
                if (value !== this.zoneData) {
                    this.createZoneDataObj();
                }
            },
        },
        disableDestination: {
            handler(value) {
                if (value && this.zoneData?.data) {
                    this.zoneData.data.dstCountries = [];
                    this.emitData();
                }
            },
            immediate: true,
        },
    },
    created() {
        this.createZoneDataObj();
    },
    methods: {
        isCountrySelected(c) {
            return Array.isArray(c) && c.length === 1;
        },
        filterNetworkForSelectedCountry(c) {
            return networksForCountrySelected(c[0].iso, Zones);
        },
        onChangeSrcCountries() {
            if (
                this.isCountrySelected(this.zoneData.data.srcCountries) &&
                this.isNotSelected(this.zoneData.data.srcInverted.id)
            ) {
                this.networkListSrc = this.filterNetworkForSelectedCountry(this.zoneData.data.srcCountries);
            } else {
                this.zoneData.data.srcNetworks = [];
                this.networkListSrc = [];
            }

            this.emitData();
        },
        onChangeDstCountries() {
            if (
                this.isCountrySelected(this.zoneData.data.dstCountries) &&
                this.isNotSelected(this.zoneData.data.dstInverted.id)
            ) {
                this.networkListDst = this.filterNetworkForSelectedCountry(this.zoneData.data.dstCountries);
            } else {
                this.zoneData.data.dstNetworks = [];
                this.networkListDst = [];
            }

            this.emitData();
        },
        isNotSelected(selectedValId) {
            return selectedValId === this.options.isNot.id;
        },
        emitData() {
            this.zoneData.validate();
            this.$emit('input', this.zoneData);
        },
        createZoneDataObj() {
            if (
                this.value &&
                Object.prototype.hasOwnProperty.call(this.value.data, 'srcCountries') &&
                Object.prototype.hasOwnProperty.call(this.value.data, 'srcNetworks')
            ) {
                const newData = this.value;
                this.zoneData = new ZoneCondition(newData);
                this.zoneData.data.srcInverted = this.value.data.srcInverted ? this.options.isNot : this.options.is;
                this.zoneData.data.dstInverted = this.value.data.dstInverted ? this.options.isNot : this.options.is;

                // If all operators are selected by default we are receiving an empty object
                // that's why we are replacing it with empty array
                if (Object.values(this.zoneData.data.srcNetworks).length < 1) {
                    this.zoneData.data.srcNetworks = [];
                }
                if (Object.values(this.zoneData.data.dstNetworks).length < 1) {
                    this.zoneData.data.dstNetworks = [];
                }
                this.onChangeSrcCountries();
                this.onChangeDstCountries();
            } else {
                const newData = this.value;
                newData.data = this.zone;
                this.zoneData = new ZoneCondition(newData, this.selectedCSType);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/_palette.scss';

.grow {
    flex-grow: 1;
}

.zone-title {
    font-size: 0.75rem;
    font-weight: 600;
    line-height: 2.17;
    color: $gray90;
    margin-bottom: 0.25rem;
}

.condition-options {
    min-width: 80px;
    width: max-content;
}
</style>
