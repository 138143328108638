<script>
import { displayNaIfNotProvided } from '../../common/formatting';

export default {
    name: 'CommonFilters',
    filters: {
        displayNaIfNotProvided(val) {
            return displayNaIfNotProvided(val);
        },
    },
};
</script>

<style lang="scss" scoped></style>
