import http, { type DnoResponseBase } from '@/http/index';
import { type AxiosPromise } from 'axios';
import { type OutageInfoResponse } from '@/__new__/features/customerCare/common/outageHelper';
import { type DeviceInfoFromBE } from '@/__new__/services/dno/osstmofiber/models/DeviceInfo';

const getOutageHistory = (
    id: string,
    idType: number,
): AxiosPromise<
    DnoResponseBase & {
        outage_history: OutageInfoResponse[] | Record<any, never>;
    }
> =>
    http({
        method: 'POST',
        url: '/v3osstmofiber/getoutagehistory',
        data: {
            target_id: id,
            target_type: idType,
        },
    });

type DeviceInfoResponse = {
    device_info: DeviceInfoFromBE[];
};

const getDeviceInfo = (id: string, idType: number): AxiosPromise<DnoResponseBase & DeviceInfoResponse> =>
    http({
        method: 'POST',
        url: '/v3osstmofiber/getdeviceinfo',
        data: {
            target_id: id,
            target_type: idType,
        },
    });

const getServiceInfo = (id: string, idType: number): AxiosPromise<DnoResponseBase & { provider_product: string }> =>
    http({
        method: 'POST',
        url: '/v3osstmofiber/getserviceinfo',
        data: {
            target_id: id,
            target_type: idType,
        },
    });

const updateNotificationEmail = (id: string, idType: number, email: string): AxiosPromise<void> =>
    http({
        method: 'POST',
        url: '/v3osstmofiber/updatenotificationemail',
        data: {
            target_id: id,
            target_type: idType,
            email,
        },
    });

const updateLoginFlow = (id: string, idType: number, pin: string): AxiosPromise<void> =>
    http({
        method: 'POST',
        url: '/v3osstmofiber/updateloginflow',
        data: {
            target_id: id,
            target_type: idType,
            pin,
        },
    });

const getExternalEmails = (id: string, idType: number): AxiosPromise<void> =>
    http({
        method: 'POST',
        url: '/v3osstmofiber/getexternalemails',
        data: {
            target_id: id,
            target_type: idType,
        },
    });

const getWifiCredentials = (id: string, idType: number): AxiosPromise<void> =>
    http({
        method: 'POST',
        url: '/v3osstmofiber/getwificredentials',
        data: {
            target_id: id,
            target_type: idType,
        },
    });

const updateWifiCredential = (id: string, idType: number, wifiName: string, wifiPassword: string): AxiosPromise<void> =>
    http({
        method: 'POST',
        url: '/v3osstmofiber/updatewificredential',
        data: {
            target_id: id,
            target_type: idType,
            wifi_ssid: wifiName,
            wifi_password: wifiPassword,
        },
    });

export default {
    getOutageHistory,
    getDeviceInfo,
    getServiceInfo,
    updateNotificationEmail,
    updateLoginFlow,
    getExternalEmails,
    getWifiCredentials,
    updateWifiCredential,
};
