<template>
    <div>
        <div class="section-content">
            {{ $i18n.t('charging.chargingSpecifications.editor.bearer') }}
        </div>
        <TagsMultiselect
            v-model="bearerData.data"
            :options="bearerOptions"
            :placeholder="$i18n.t('charging.chargingSpecifications.editor.chooseBearer')"
            :error="bearerData.dataEmpty"
            :errorMessage="$i18n.t('generic.validations.fieldIsRequired')"
            data-test-id="bearer-condition-multiselect"
            @input="onDataInput"
        />
    </div>
</template>

<script>
import TagsMultiselect from '@/components/partials/inputs/TagsMultiselect.vue';
import BearerCondition from '@/__new__/services/dno/charging/models/chargingSpecificationConditions/BearerCondition';

export default {
    name: 'BearerV2',
    components: {
        TagsMultiselect,
    },
    props: {
        value: {
            type: Object,
            default: () => undefined,
        },
    },
    data() {
        return {
            bearerOptions: ['5G', '4G', '3G', '2G'],
            bearerData: new BearerCondition(this.value),
        };
    },
    watch: {
        value: {
            handler() {
                this.bearerData = new BearerCondition(this.value);
            },
        },
    },
    methods: {
        onDataInput() {
            this.bearerData.validate();
            this.$emit('input', this.bearerData);
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/_palette.scss';

.section-content {
    font-size: 0.75rem;
    font-weight: 600;
    margin-bottom: 1rem;
    margin-right: 1rem;
    color: $gray90;
}
</style>
