
import Vue from 'vue';

// components
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import AppJSON from '@/components/partials/AppJSON.vue';
import AppDialogV2 from '@/components/partials/AppDialogV2.vue';
import AppYAML from '@/components/partials/AppYAML.vue';
import BulkUploadTabs, { Tab } from '@/__new__/features/resources/BulkUploadTabs.vue';
// helpers
import { ICON_TYPES } from '@/common/iconHelper';
import { copyToClipboard } from '@/common/utils';
import { JOB_EXPORT_DOC_URL } from '@/__new__/features/settings/common/applicationManagerHelper';

export default Vue.extend({
    name: 'SinkConfigDialog',
    components: {
        AppButton,
        AppJSON,
        AppDialogV2,
        AppYAML,
        BulkUploadTabs,
    },
    props: {
        value: {
            type: Boolean,
            required: true,
        },
        jsonData: {
            type: Object,
            default: () => ({}),
        },
        yamlData: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            BUTTON_TYPES,
            ICON_TYPES,
            JOB_EXPORT_DOC_URL,
            submitBtn: {
                buttonType: BUTTON_TYPES.PRIMARY,
                iconType: ICON_TYPES.COPY,
                label: this.$t('generic.copyToClipboard'),
            },
            currentTab: 'json',
            tabs: [
                {
                    id: 'json',
                    label: this.$t('formBuilderTypes.JSON'),
                },
                {
                    id: 'yaml',
                    label: this.$t('generic.export'),
                },
            ] as Tab[],
        };
    },
    computed: {
        modalData(): Record<string, any> | string {
            if (this.isJsonTab) {
                return this.jsonData;
            }
            return this.yamlData;
        },
        isJsonTab(): boolean {
            return this.currentTab === 'json';
        },
    },
    methods: {
        copyToClipboard,
    },
});
