<template>
    <div class="page-wrap">
        <SinkConfigEditor
            v-if="shouldShowContent"
            :entityType="ENTITY_TYPES.KAFKA_SINK_CONFIG"
            :uiSettings="kafkaConfigsSettings"
            :createFunction="createKafkaConfig"
            :updateFunction="updateKafkaConfig"
            :saveDraftFunction="createKafkaDraft"
            :typeCasters="typeCasters"
            :dashboardPageName="RouteNames.KAFKA_SINK_CONFIG_DASHBOARD"
            :editableConfig="editableConfig"
            :triggerDefinitions="triggerDefinitions"
            :events="events"
            :formatters="getSinkFormatters"
        />
    </div>
</template>

<script>
import {
    createKafkaConfig,
    updateKafkaConfig,
    createKafkaDraft,
} from '@/__new__/services/dno/sinkConfigs/http/sinkConfigs';
import { mapGetters, mapActions } from 'vuex';
import { Modules } from '@/store/store';
import Actions, { Getters } from '@/store/mutation-types';
import { ALERT_TYPES } from '@/common/alerts/Alert';
import SinkConfigEditorMixin from '@/__new__/features/sinkConfigs/common/SinkConfigEditorMixin';
import isEmpty from 'lodash/isEmpty';

export default {
    name: 'KafkaConfigsEditor',
    mixins: [SinkConfigEditorMixin],
    data() {
        return {
            createKafkaConfig,
            updateKafkaConfig,
            createKafkaDraft,
        };
    },
    computed: {
        ...mapGetters(Modules.sinkConfigs, {
            kafkaConfigsSettings: Getters.GET_KAFKA_SINK_CONFIGS_SETTINGS,
            allKafkaConfigs: Getters.GET_KAFKA_SINK_CONFIGS,
            getConfigById: Getters.GET_KAFKA_SINK_CONFIG_BY_ID,
            getSinkFormatters: Getters.GET_SINK_FORMATTERS,
            typeCasters: Getters.GET_SINK_TYPE_CASTERS,
        }),
        ...mapGetters(Modules.segments, {
            segments: Getters.CACHED_SEGMENTS,
        }),

        editableConfig() {
            return this.getConfigById(this.$route.params.id);
        },
        shouldShowContent() {
            if (this.$route.params.id) {
                return !!this.editableConfig && this.events.length;
            }
            return !isEmpty(this.kafkaConfigsSettings) && this.triggerDefinitions && this.events;
        },
    },
    created() {
        this.$withLoadingSpinner(
            async () => {
                const promises = [
                    this[Actions.LOAD_KAFKA_SINK_CONFIGS_SETTINGS](),
                    this.fetchTriggers(),
                    this.fetchEvents(),
                    this.fetchSinkFormatters(),
                    this.fetchSegments(),
                    this.fetchSinkTypeCasters(),
                ];

                if (!this.segments.length) {
                    promises.push(this.fetchSegments());
                }

                await Promise.all(promises);
                if (this.$route.params.id && this.allKafkaConfigs.length === 0) {
                    await this[Actions.LOAD_KAFKA_SINK_CONFIGS]();
                }
            },
            {
                errorHandler: () => {
                    this.$eventBus.$emit('showAlert', {
                        message: this.$i18n.t('events.alerts.failedToLoadTableConfig'),
                        type: ALERT_TYPES.alert,
                    });
                },
            },
        );
    },

    methods: {
        ...mapActions(Modules.sinkConfigs, [Actions.LOAD_KAFKA_SINK_CONFIGS_SETTINGS, Actions.LOAD_KAFKA_SINK_CONFIGS]),
    },
};
</script>
