import {
    EntityBaseConstructorArgs,
    EntityBaseModel,
    EntityBaseDraftModel,
} from '@/__new__/services/dno/charging/models/EntityBaseModel';

export interface TariffSpecGroupConstructorArgs extends EntityBaseConstructorArgs {
    tariff_specs: string[];
    priority: number;
}

export interface TariffSpecGroupDraftConstructorArgs extends TariffSpecGroupConstructorArgs {
    operator_name: string;
    type: string;
    update_portal_id: string;
    data?: TariffSpecGroupConstructorArgs;
}

export class TariffSpecGroup extends EntityBaseModel {
    tariffSpecs: string[];
    priority: number;

    constructor(data: TariffSpecGroupConstructorArgs, draftEntities: TariffSpecGroupDraft[]) {
        super(data, draftEntities);
        this.tariffSpecs = data.tariff_specs;
        this.priority = data.priority;
    }
}

export class TariffSpecGroupDraft extends EntityBaseDraftModel {
    tariffSpecs: string[];
    priority: number;

    constructor(data: TariffSpecGroupDraftConstructorArgs) {
        super(data);
        this.tariffSpecs = data.tariff_specs;
        this.priority = data.priority;
    }
}
