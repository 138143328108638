<!--
    Purpose: Clicking this button will open a modal with a JSON input field.
             This can be useful for batch/individual importing entities.
-->
<template>
    <div v-if="isAvailable">
        <AppButton
            :buttonType="BUTTON_TYPES.SECONDARY"
            :iconType="ICON_TYPES.UPLOAD"
            :label="$i18n.t('generic.import')"
            @click="modalOpen = true"
        />

        <ImportEntitiesModal
            v-if="modalOpen"
            ref="ImportEntitiesModal"
            :open="modalOpen"
            :entityType="entityType"
            @close="modalOpen = false"
            @finish="$emit('finishImport')"
        />
    </div>
</template>

<script>
// Components
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import ImportEntitiesModal from '@/components/partials/ImportEntitiesModal.vue';

// Helpers
import { isUserAllowed, isUserInternal } from '@/services/permissions/permissions.service';
import { ICON_TYPES } from '@/common/iconHelper';

export default {
    name: 'ImportEntitiesModalButton',
    components: { AppButton, ImportEntitiesModal },
    props: {
        entityType: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            modalOpen: false,
            BUTTON_TYPES,
            ICON_TYPES,
        };
    },
    computed: {
        isAvailable() {
            return isUserInternal() && isUserAllowed('ImportEntities');
        },
    },
};
</script>
