<template>
    <TableFiltersRenderless :entities="rolesData">
        <template
            #default="{
                // state
                allFilters,
                filteredEntities,
                // methods
                onFilterAdded,
                removeFilter,
                removeAllFilters,
            }"
        >
            <div>
                <div class="d-flex justify-content-between align-items-center position-relative">
                    <div class="table-title">
                        {{ $i18n.t('customerCare.userInformation.associatedRoles') }}
                    </div>

                    <div class="d-flex align-items-center">
                        <SearchBox
                            v-model="searchQuery"
                            class="search-box"
                            :smallSearchIcon="true"
                        />

                        <ResponseModalButton
                            :response="apiResponse"
                            :title="$i18n.t('customerCare.userInformation.associatedRoles')"
                        />

                        <FilterTable
                            :columns="columnsData"
                            :multiselectWidth="{ width: '12rem' }"
                            @filterAdded="onFilterAdded"
                        >
                            <template #button="slotProps">
                                <IconButton
                                    :label="$i18n.t('generic.filter')"
                                    :icon="ICON_TYPES.FILTER"
                                    @iconClick="slotProps.clickHandler"
                                />
                            </template>
                        </FilterTable>
                    </div>
                </div>
                <TableFiltersTags
                    :filterTableMixin="{ allFilters }"
                    class="mt-1 mb-3"
                    @removeFilter="removeFilter"
                    @removeAllFilters="removeAllFilters"
                />
                <AppTable
                    :entities="filteredEntities"
                    :isDefaultHoverEnabled="false"
                    :isPaginationEnabled="true"
                    :canSelectColumns="true"
                    :newDesign="true"
                    :columnsData="columnsData"
                    :isSearchEnabled="true"
                    :innerSearchQuery="searchQuery"
                    tableKey="customer-care-new/associated-roles"
                >
                    <template #targetId="{ entity }">
                        <div
                            v-if="entity.targetId"
                            class="link"
                            @click="goToRoute(getBreadcrumbPath(entity.targetTypeId, entity.targetId))"
                        >
                            {{ entity.targetId }}
                        </div>
                        <i v-else>
                            {{ $i18n.t('generic.empty') }}
                        </i>
                    </template>
                </AppTable>
            </div>
        </template>
    </TableFiltersRenderless>
</template>

<script>
// components
import AppTable from '@/components/partials/AppTable.vue';
import FilterTable from '@/components/partials/FilterTable.vue';
import IconButton from '@/components/partials/IconButton.vue';
import { ICON_TYPES } from '@/common/iconHelper';
import SearchBox from '@/components/partials/inputs/SearchBox.vue';
import TableFiltersRenderless from '@/components/filters/TableFiltersRenderless.vue';
import TableFiltersTags from '@/components/filters/TableFiltersTags.vue';
import ResponseModalButton from '@/components/partials/ResponseModalButton.vue';

// http
import userManagementAJAX from '@/__new__/services/dno/user/http/user-management';

// helpers
import tableColumnType from '@/common/filterTable';
import { USER_MANAGER_HIERARCHY } from '@/__new__/features/customerCare/common/customerCareHelper';
import AccessRole from '@/__new__/services/dno/user/models/AccessRole';

export default {
    name: 'AssociatedRoles',
    components: {
        AppTable,
        FilterTable,
        IconButton,
        SearchBox,
        TableFiltersRenderless,
        TableFiltersTags,
        ResponseModalButton,
    },
    props: {
        id: {
            required: true,
            type: String,
        },
        idType: {
            required: true,
            type: Number,
        },
        userOwnerId: {
            default: '',
            type: String,
        },
        roles: {
            type: Array,
            default: () => [],
        },
        showEmail: {
            default: false,
            type: Boolean,
        },
        apiResponse: {
            type: [Array, Object],
            default: null,
        },
    },
    data() {
        return {
            ICON_TYPES,
            searchQuery: '',
            accessRoles: [],
        };
    },
    computed: {
        hasAllData() {
            return USER_MANAGER_HIERARCHY.USER === this.idType;
        },
        isWithoutRole() {
            return USER_MANAGER_HIERARCHY.ACCOUNT === this.idType || USER_MANAGER_HIERARCHY.SUBSCRIBER === this.idType;
        },
        rolesData() {
            if (this.hasAllData) {
                return this.roles;
            }
            if (this.accessRoles.length) {
                if (this.isWithoutRole) {
                    if (this.userOwnerId && this.roles.length) {
                        this.mapUserRole();
                        return [...this.accessRoles, ...this.roles];
                    }
                    return [];
                }
                return [...this.accessRoles, ...this.roles];
            }
            return [];
        },
        columnsData() {
            const cols = [
                {
                    name: this.$i18n.t('generic.id'),
                    key: 'targetId',
                    field: 'targetId',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('generic.type'),
                    key: 'targetType',
                    field: 'targetType',
                    filterType: tableColumnType.TEXT_LIMITED_OPTIONS,
                    limitedOptions: [...new Set(this.rolesData.map(entity => entity?.targetType))],
                },
                {
                    name: this.$i18n.t('generic.role'),
                    key: 'roleName',
                    field: 'roleName',
                    filterType: tableColumnType.TEXT_LIMITED_OPTIONS,
                    limitedOptions: [...new Set(this.rolesData.map(entity => entity?.roleName))],
                },
            ];

            if (this.showEmail) {
                cols.push({
                    name: this.$i18n.t('customerCare.userInformation.email'),
                    key: 'email',
                    field: 'email',
                    filterType: tableColumnType.TEXT_LIMITED_OPTIONS,
                    limitedOptions: [...new Set(this.rolesData.map(entity => entity?.email))],
                });
            }
            return cols;
        },
    },
    created() {
        if (!this.hasAllData) {
            this.fetchAccessData();
        }
    },
    methods: {
        fetchAccessData() {
            this.$withProgressBar(
                async () => {
                    const res = await userManagementAJAX.getAccessList(this.id, this.idType);
                    const accessList = res?.data?.access_list;

                    if (Array.isArray(accessList)) {
                        this.accessRoles = accessList.map(user => new AccessRole(AccessRole.remapFromAccessList(user)));
                    }
                },
                {
                    errorHandler: () =>
                        this.$showErrorAlert({
                            message: this.$i18n.t('alertMessage.somethingWentWrong'),
                        }),
                },
            );
        },
        mapUserRole() {
            // Notice: DevEdge has multiple users on account(only 1 is owner).
            const userAccessRole = this.accessRoles.find(user => user.targetId === this.userOwnerId);
            const roleName = userAccessRole ? userAccessRole.roleName : '';
            this.roles.forEach(item => {
                item.roleName = roleName;
            });
        },
        goToRoute(path) {
            this.$router.push({ path: `/${this.$route.params.companyId}${path}` });
        },
        getBreadcrumbPath(type, id) {
            switch (type) {
                case USER_MANAGER_HIERARCHY.SUBSCRIBER:
                    return `/customer-care/subscriber/${id}`;
                case USER_MANAGER_HIERARCHY.ACCOUNT:
                    return `/customer-care/account/${id}`;
                case USER_MANAGER_HIERARCHY.USER:
                    return `/customer-care/user/${id}`;
                case USER_MANAGER_HIERARCHY.ORGANIZATION:
                    return `/customer-care/organization/${id}`;
                default:
                    return '';
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/animations';
@import '~@/assets/scss/palette';

.table-title {
    font-size: 1rem;
    font-weight: 600;
}

.search-box {
    width: 15rem;
}

.link {
    cursor: pointer;
    transition: color $fast-animation-time;
}

.link:hover {
    color: $blue;
    text-decoration: underline;
}
</style>
