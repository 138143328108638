
// Vue components
import AppDialogV2 from '@/components/partials/AppDialogV2.vue';
import AppInputV3 from '@/components/partials/inputs/AppInputV3.vue';
import AppMultiselectV3 from '@/components/partials/inputs/AppMultiselectV3.vue';
import AppTooltip from '@/components/partials/AppTooltip.vue';
import { TOOLTIP_POSITION } from '@/common/tooltip';
import { ALERT_TYPES } from '@/common/alerts/Alert';
import AppJSON from '@/components/partials/AppJSON.vue';
import AppToggleV2 from '@/components/partials/inputs/AppToggleV2.vue';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';

// Validation
import { required } from 'vuelidate/lib/validators';

// Helpers & Misc
import { VISIBILITY_OPTIONS } from '@/__new__/features/settings/remoteConfig/remoteConfigHelper';
import * as Sentry from '@sentry/vue';
import supportButtonMixin from '@/components/alerts/supportButtonMixin';
import { TranslateResult } from 'vue-i18n';
import luaErrorCodes, { modules } from '@/common/luaErrors';
import { ICON_TYPES } from '@/common/iconHelper';

// HTTP
import remoteConfigHttp, { validateRemoteConfig } from '@/__new__/services/dno/remoteConfig/http/remoteConfig';

interface RemoteConfigModel {
    key: string;
    value: any;
    visibility: any;
    validationInput?: any;
}

interface VisibilityOption {
    label: string;
    value: number;
}

export default {
    name: 'RemoteConfigEditModal',

    components: {
        AppButton,
        AppTooltip,
        AppMultiselectV3,
        AppDialogV2,
        AppInputV3,
        AppJSON,
        AppToggleV2,
    },

    mixins: [supportButtonMixin],

    /**
     * key and value props are reserved so adding
     * config in front of them
     */
    props: {
        visible: {
            required: true,
            type: Boolean,
        },
        configData: {
            required: false,
            default: () => undefined,
            type: Object,
        },
    },

    data() {
        return {
            // Proxy imports
            TOOLTIP_POSITION: typeof TOOLTIP_POSITION,

            areModalButtonsDisabled: false as boolean,
            visibilityOptions: [] as VisibilityOption[],
            isValidationEnabled: false as boolean,
            jsonInvalid: true,
            remoteConfigModel: {
                key: this.configData.key,
                value: this.configData.value,
                visibility: this.configData.visibility,
                validationInput: this.configData.validationInput,
            } as RemoteConfigModel,

            BUTTON_TYPES,
            ICON_TYPES,
        };
    },

    validations() {
        return {
            remoteConfigModel: {
                key: {
                    required,
                },
                value: {
                    required,
                },
                visibility: {
                    required,
                },
                validationInput: {
                    validation: value => {
                        if (this.isValidationEnabled) {
                            if (!value) {
                                return false;
                            }

                            return typeof value === 'object';
                        }

                        return true;
                    },
                },
            },
        };
    },

    computed: {
        validationInputErrorMessage(): TranslateResult {
            if (this.remoteConfigModel.validationInput) {
                return this.$i18n.t('settings.remoteConfig.validationInputErrorMessage');
            }

            return '';
        },
    },
    created() {
        this.initData();

        this.visibilityOptions = Object.values(VISIBILITY_OPTIONS).filter(op => op !== VISIBILITY_OPTIONS.DELETED);
    },

    methods: {
        onJsonValidate(isValid: boolean) {
            this.jsonInvalid = isValid;
        },
        initData(): void {
            this.remoteConfigModel.key = this.configData.key;
            this.remoteConfigModel.value = this.configData.value ? JSON.parse(this.configData.value) : null;
            this.remoteConfigModel.visibility = this.configData.visibility;
            this.remoteConfigModel.validationInput = this.configData.validationInput || '';

            // Set Enable validation to true if validation schema is provided
            if (this.remoteConfigModel.validationInput) {
                this.isValidationEnabled = true;
            }
        },

        onCloseModal(): void {
            this.$emit('close');
        },

        async onSave(): Promise<any> {
            if (!this.jsonInvalid) {
                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('alertMessage.operatorConfig.jsonFormatError'),
                });
                return;
            }

            // Validate model
            this.validate();

            if (!this.$v.$invalid) {
                try {
                    this.$Progress.start();

                    this.areModalButtonsDisabled = true;

                    /**
                     * Same API call is used for both updating existing configurations and creating new ones.
                     */
                    await remoteConfigHttp.updateItemRequest({
                        key: this.remoteConfigModel.key,
                        value: JSON.stringify(this.remoteConfigModel.value),
                        visibility: this.remoteConfigModel.visibility.value,
                        ...(this.isValidationEnabled && { validation_schema: this.remoteConfigModel.validationInput }),
                    });

                    this.$eventBus.$emit('showAlert', {
                        message: this.$i18n.t('alertMessage.operatorConfig.successfullyUpdated'),
                        type: ALERT_TYPES.success,
                    });

                    setTimeout(() => {
                        this.$emit('update');
                        this.onCloseModal();
                    }, 1000);

                    // Handle response
                    this.$Progress.finish();
                } catch (error: any) {
                    Sentry.captureException(error);
                    this.$Progress.fail();

                    if (
                        error.response?.data?.module === modules.COMMON &&
                        error.response?.data?.code === luaErrorCodes.COMMON.INVALID.code
                    ) {
                        this.$eventBus.$emit('showAlert', {
                            message: this.$i18n.t('settings.remoteConfig.savingInvalidRemoteConfigError'),
                        });
                    } else {
                        this.$eventBus.$emit('showAlert', {
                            message: this.$i18n.t('settings.remoteConfig.savingRemoteConfigError'),
                        });
                    }
                } finally {
                    this.$Progress.finish();
                    this.areModalButtonsDisabled = false;
                }
            }
        },
        validate() {
            this.$v.$touch();
            if (this.$v.$invalid) {
                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('alertMessage.pleaseFixValidation'),
                });
            }
        },
        onCheckValidationInput() {
            this.$withProgressBar(
                async () => {
                    this.areModalButtonsDisabled = true;

                    await validateRemoteConfig({
                        key: this.remoteConfigModel.key,
                        value: JSON.stringify(this.remoteConfigModel.value),
                        visibility: this.remoteConfigModel.visibility.value,
                        validation_schema: this.remoteConfigModel.validationInput,
                    });

                    this.$eventBus.$emit('showAlert', {
                        message: this.$i18n.t('settings.remoteConfig.validationCheckSuccess'),
                        type: ALERT_TYPES.success,
                    });

                    this.areModalButtonsDisabled = false;
                },
                {
                    errorHandler: () => {
                        this.$alert(this.$t('settings.remoteConfig.validationCheckFail'));
                        this.areModalButtonsDisabled = false;
                    },
                },
            );
        },
    },
};
