
import Vue from 'vue';

// Vuex
import { mapActions, mapGetters } from 'vuex';
import { Modules } from '@/store/store';
import Actions, { Getters } from '@/store/mutation-types';

// Components
import AbstractListPageWrapper from '@/components/layout/AbstractListPageWrapper.vue';
import AppTable from '@/components/partials/AppTable.vue';
import DetailsJsonModal from '@/components/partials/DetailsJsonModal.vue';
import EntityOverview from '@/components/partials/entityOverview/EntityOverview.vue';
import OverviewList from '@/components/partials/entityOverview/OverviewList.vue';
import ResponseModalButton from '@/components/partials/ResponseModalButton.vue';
import tableColumnType, { type TableColumn } from '@/common/filterTable';

// Models
import {
    type default as SharedLocation,
    SHARED_LOCATION_GATEWAY_I18N_MAP,
} from '@/__new__/services/dno/dataflows/models/SharedLocation';

// Helpers
import { getBeautifulBoolean } from '@/common/formatting';

type EntityOverviewData = {
    name: string;
    properties: Array<{ label: string; value: string | number }>;
};

export default Vue.extend({
    name: 'SharedFileLocations',
    components: {
        OverviewList,
        EntityOverview,
        AppTable,
        DetailsJsonModal,
        AbstractListPageWrapper,
        ResponseModalButton,
    },

    data() {
        return {
            isOverviewEnabled: false,
            selectedEntityId: null as string | null,
            selectedEntity: null as SharedLocation | null,
            isLoading: false,
        };
    },

    computed: {
        ...mapGetters(Modules.sharedFileLocations, {
            sharedLocations: Getters.GET_SHARED_FILE_LOCATIONS,
            apiResponse: Getters.GET_SHARED_FILE_LOCATIONS_API_RESPONSE,
        }),
        entityOverviewDetails(): EntityOverviewData[] {
            const data = [
                {
                    name: this.$t('pipelines.properties.source'),
                    properties: [
                        {
                            label: this.$t('pipelines.properties.producer'),
                            value: this.selectedEntity?.source?.producer,
                        },
                        {
                            label: this.$t('pipelines.properties.jobId'),
                            value: this.selectedEntity?.source?.job_id,
                        },
                    ],
                },
                {
                    name: this.$t('pipelines.properties.destination'),
                    properties: [
                        {
                            label: this.selectedEntity?.destination?.bucket
                                ? this.$t('pipelines.properties.bucket')
                                : this.$t('generic.host'),
                            value: this.selectedEntity?.host,
                        },
                        {
                            label: this.$t('pipelines.properties.directory'),
                            value: this.selectedEntity?.directory,
                        },
                        {
                            label: this.$t('pipelines.properties.fullURI'),
                            value: this.selectedEntity?.fullPath,
                        },
                        {
                            label: this.$t('pipelines.properties.port'),
                            value: this.selectedEntity?.port,
                        },
                        {
                            label: this.$t('generic.filename'),
                            value: this.selectedEntity?.destination?.filename || this.$t('generic.N/A'),
                        },
                    ],
                },
            ];

            if (this.selectedEntity?.encryption?.public_key) {
                data.push({
                    name: this.$t('generic.encryption'),
                    properties: [
                        {
                            label: this.$t('generic.publicKey'),
                            value: `ssm:${this.selectedEntity.encryption.public_key}`,
                        },
                    ],
                });
            }

            return data;
        },

        tableColumnsData(): TableColumn[] {
            return [
                {
                    name: this.$t('generic.id'),
                    key: 'id',
                    field: 'id',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$t('generic.name'),
                    key: 'name',
                    field: 'name',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$t('generic.type'),
                    key: 'type',
                    field: 'type',
                    formatter: e => this.$t(SHARED_LOCATION_GATEWAY_I18N_MAP.get(e)),
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$t('generic.encryption'),
                    key: 'encryption',
                    field: 'encryption',
                    formatter: e => getBeautifulBoolean(!!e),
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$t('generic.lastUpdated'),
                    key: 'lastUpdated',
                    field: 'lastUpdated',
                    formatter: date => this.$localeLibrary.getFormattedDate(date),
                    filterType: tableColumnType.DATETIME,
                },
            ];
        },
    },

    async created() {
        await this.fetchSharedLocations();

        if (this.$route?.query?.id) {
            this.onSelectEntity(this.$route.query.id);
        }
    },

    methods: {
        ...mapActions(Modules.sharedFileLocations, [Actions.FETCH_SHARED_FILE_LOCATIONS]),

        onSelectEntity(id: string): void {
            this.selectedEntityId = id;
            this.selectedEntity = this.sharedLocations.find((item: SharedLocation) => item.id === id) as SharedLocation;
            this.isOverviewEnabled = true;
        },

        async fetchSharedLocations(): Promise<void> {
            await this.$withProgressBar(
                async () => {
                    this.isLoading = true;
                    await this[Actions.FETCH_SHARED_FILE_LOCATIONS]();
                    this.isLoading = false;
                },
                {
                    errorHandler: () => {
                        this.isLoading = false;
                        this.$alert(this.$t('pipelines.errors.fetchConfigs'));
                    },
                },
            );
        },
    },
});
