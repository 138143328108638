import http from '@/http';
import { type AxiosPromise } from 'axios';
import { type DnoResponseBase } from '@/http/index';
import { type ExternalResourceBe } from '@/__new__/services/dno/ossgpfiber/models/ExternalResource';

export interface ResourceResponse extends DnoResponseBase {
    resource: ExternalResourceBe;
    history: ExternalResourceBe[];
}

const getResource = (id: string, idType: number): AxiosPromise<ResourceResponse> =>
    http({
        method: 'POST',
        url: '/v3ossgpfiber/resourcesgetresource',
        data: {
            target_id: id,
            target_type: idType,
        },
    });

const getPaymentTransaction = (data: { target_id: string; target_type: string }) =>
    http({
        method: 'POST',
        url: '/v3ossgpfiber/getpaymenttransaction',
        data,
    });

export default {
    getResource,
    getPaymentTransaction,
};
