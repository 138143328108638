<template>
    <div class="banner d-flex align-items-center body-xs">
        <AppIcon
            type="info-new"
            size="1rem"
            class="icon mr-3"
        />
        <p class="mb-0">
            <a
                class="link-xs"
                @click.prevent="onClick"
            >
                {{ $i18n.t('customerCare.userAuthentication.banner.verifyUserFirst') }}
            </a>
            &nbsp;{{ $i18n.t('customerCare.userAuthentication.banner.toEnableUserAccountSubEditing') }}
        </p>
    </div>
</template>

<script>
import AppIcon from '@/components/partials/icon/AppIcon.vue';
import RouteNames from '@/router/routeNames';

export default {
    name: 'UserAuthenticationBanner',
    components: {
        AppIcon,
    },
    props: {
        userId: {
            type: String,
            required: true,
        },
    },
    computed: {
        isUserRoute() {
            return this.$route.name === RouteNames.USER_MANAGEMENT_USER_V2;
        },
    },
    methods: {
        onClick() {
            if (this.isUserRoute) {
                this.$eventBus.$emit('openAuthModal');
            } else {
                this.$router.push({
                    name: RouteNames.USER_MANAGEMENT_USER_V2,
                    params: { id: this.userId, companyId: this.$route.params.companyId },
                });
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/consistency-colors';
@import '~@/assets/scss/consistency-spacing';
@import '~@/assets/scss/consistency-typography';

.banner {
    padding: $spacing-m $spacing-xl;
    color: $yellow-800;
    background-color: $yellow-100;
}

.icon {
    flex: 0 0 max-content;
    color: $yellow-400 !important;
}

.link-xs {
    cursor: pointer;
    color: inherit;
}
</style>
