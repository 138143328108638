import { render, staticRenderFns } from "./RemoteConfig.vue?vue&type=template&id=763a365b&scoped=true"
import script from "./RemoteConfig.vue?vue&type=script&lang=js"
export * from "./RemoteConfig.vue?vue&type=script&lang=js"
import style0 from "./RemoteConfig.vue?vue&type=style&index=0&id=763a365b&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "763a365b",
  null
  
)

export default component.exports