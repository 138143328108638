
import Vue from '@/common/typedVue';
import { PropType } from 'vue';

// components
import IconButton from '@/components/partials/IconButton.vue';
import AbstractTableTile from '@/__new__/features/customerCareSuite/components/AbstractTableTile.vue';
import AdditionalSidebarForSubscriptions from '@/__new__/features/customerCareSuite/components/AdditionalSidebarForSubscriptions.vue';
import EntityStatusIndicator from '@/components/partials/EntityStatusIndicator.vue';
import BasePlanFCCLabelDialog from './BasePlanFCCLabelDialog.vue';

// helpers
import {
    SUBSCRIPTION_STATUS_INDICATOR_MAP,
    SUBSCRIPTION_STATES_TO_STATUS_NAME_MAP,
    SUBSCRIPTION_STATE_DECORATORS_INDICATOR_MAP,
    SUBSCRIPTION_STATE_DECORATORS_TO_STATUS_NAME_MAP,
} from '@/__new__/features/customerCareSuite/common/subscriptionStateHelper';
import supportButtonMixin from '@/components/alerts/supportButtonMixin';
import tableColumnType, { TableColumn } from '@/common/filterTable';
import { USER_MANAGER_HIERARCHY } from '@/__new__/features/customerCare/common/customerCareHelper';
import RouteNames from '@/router/routeNames';
import { ICON_TYPES } from '@/common/iconHelper';
import Subscription from '@/__new__/services/dno/subscriptions/models/Subscription';

// Http
import subscriptionsHTTP from '@/__new__/services/dno/subscriptions/http/subscriptions';
import offerHTTP from '@/__new__/services/dno/pc/http/offer';

export default Vue.extend({
    name: 'SubscriptionsTile',
    components: {
        IconButton,
        AbstractTableTile,
        AdditionalSidebarForSubscriptions,
        EntityStatusIndicator,
        BasePlanFCCLabelDialog,
    },
    mixins: [supportButtonMixin],
    props: {
        userManagerHierarchy: {
            type: Number as PropType<USER_MANAGER_HIERARCHY>,
            required: true,
        },
    },
    data() {
        return {
            entities: [] as Subscription[],
            apiResponse: {},
            showSidebar: false,
            selectedEntityId: '' as string,

            selectedBasePlanOfferData: {
                id: '',
                timestamp: 0,
            } as { id: string; timestamp: number },
            showBasePlanFCCLabelDialog: false,

            // proxy
            RouteNames,
            ICON_TYPES,
            SUBSCRIPTION_STATES_TO_STATUS_NAME_MAP,
            SUBSCRIPTION_STATUS_INDICATOR_MAP,
            SUBSCRIPTION_STATE_DECORATORS_INDICATOR_MAP,
            SUBSCRIPTION_STATE_DECORATORS_TO_STATUS_NAME_MAP,
        };
    },
    computed: {
        // Table related properties
        selectedEntity(): Subscription | undefined {
            return this.selectedEntityId
                ? this.entities.find(({ id }: Subscription) => id === this.selectedEntityId)
                : undefined;
        },
        columnsData(): TableColumn[] {
            return [
                {
                    name: this.$i18n.t('customerCareSuite.subscriptionsTile.subscriptionId'),
                    key: 'id',
                    forbidHideColumn: true,
                    field: 'id',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('customerCareSuite.subscriptionsTile.offerId'),
                    key: 'offerId',
                    field: 'offerId',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('customerCareSuite.subscriptionsTile.offerName'),
                    key: 'offerName',
                    field: 'offerName',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('generic.state'),
                    key: 'state',
                    classes: ['overflow-visible-all'],
                    field: 'state',
                    filterType: tableColumnType.TEXT_LIMITED_OPTIONS,
                    limitedOptions: Array.from(new Set(this.entities.map((entity: Subscription) => entity.state))),
                },
                {
                    name: this.$i18n.t('customerCareSuite.subscriptionsTile.stateDecorator'),
                    key: 'stateDecorator',
                    field: 'stateDecorator',
                    filterType: tableColumnType.TEXT_LIMITED_OPTIONS,
                    limitedOptions: Array.from(
                        new Set(this.entities.map((entity: Subscription) => entity.stateDecorator)),
                    ),
                },
                {
                    name: this.$i18n.t('generic.recurring'),
                    key: 'recurring',
                    field: 'recurring',
                    filterType: tableColumnType.TEXT_LIMITED_OPTIONS,
                    limitedOptions: Array.from(new Set(this.entities.map((entity: Subscription) => entity.recurring))),
                },
                {
                    name: this.$i18n.t('customerCareSuite.subscriptionsTile.offerDescription'),
                    key: 'offerDescription',
                    field: 'offerDescription',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('customerCareSuite.subscriptionsTile.activationDate'),
                    key: 'activationDate',
                    field: 'activationDate',
                    filterType: tableColumnType.GENERAL_TEXT,
                    formatter: activationDate => this.$localeLibrary.getFormattedDateAndTime(activationDate),
                },
            ];
        },
    },
    mounted() {
        this.fetchTileData();
    },
    methods: {
        fetchTileData() {
            return this.$withProgressBar(
                async () => {
                    this.$emit('isDataLoadingUpd', true);

                    const response = await subscriptionsHTTP.getSubscriptions(
                        this.$route.params.id,
                        this.userManagerHierarchy,
                        { include_state_history: true },
                    );

                    this.apiResponse = response;

                    if (response?.data?.subscriptions) {
                        this.entities = Object.values(response.data.subscriptions).map(
                            el => new Subscription(Subscription.mapSubscriptionsFromBE(el)),
                        );

                        const offerIds = this.entities.map(({ offerId }) => offerId);
                        const { data } = await offerHTTP.getOffers(offerIds);
                        const offers = data?.offer_by_id || [];

                        this.entities = this.entities.map(el => ({
                            ...el,
                            offerName: offers?.[el?.offerId]?.data.name || '',
                            offerDescription: offers?.[el?.offerId]?.data.description || '',
                            recurring: offers?.[el?.offerId]?.data.subscription_type || '',
                            offerIsBasePlan: offers?.[el?.offerId]?.data.is_base_plan,
                        }));
                    }

                    this.$emit('isDataLoadingUpd', false);
                },
                {
                    errorHandler: (e: any) => {
                        this.apiResponse = e.response;
                        this.$emit('dataFetchingError');
                        this.$emit('isDataLoadingUpd', false);
                    },
                },
            );
        },
        showFccLabel(entity: Subscription) {
            this.selectedBasePlanOfferData = {
                id: entity.offerId,
                timestamp: entity.activationTimestamp,
            };
            this.showBasePlanFCCLabelDialog = true;
        },
        onShowSidebar(id: string) {
            this.selectedEntityId = id;
            this.showSidebar = true;
        },
        onCloseSidebar() {
            this.selectedEntityId = '';
            this.showSidebar = false;
        },
    },
});
