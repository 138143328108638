
import Vue from 'vue';

// Components
import AppMultiselectV3 from '@/components/partials/inputs/AppMultiselectV3.vue';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import AppDialogV2 from '@/components/partials/AppDialogV2.vue';

// Helpers
import { STATUS_CODES_OPERATIONS } from '@/common/commonHelper';
import { ORDER_ENTITY_STATES } from '@/__new__/features/customerCare/common/orderStateHelper';
import luaErrors from '@/common/luaErrors';
import { getOperatorConfigValue } from '@/services/permissions/permissions.service';
import { AccountAddress } from '@/__new__/services/dno/user/models/Account';
import isEmpty from 'lodash/isEmpty';
import ProductCatalogOfferModel from '@/__new__/services/dno/pc/models/ProductCatalogOfferModel';

// Vuex
import { Modules } from '@/store/store';
import { mapActions, mapGetters } from 'vuex';
import Actions, { Getters } from '@/store/mutation-types';

// HTTP
import { getOffersClientAPI } from '@/__new__/services/dno/pc/http/offer';

export const CHANGE_TYPE = {
    ACTIVE: 1,
    PENDING: 2,
};

export default Vue.extend({
    name: 'OrderChangePlanModal',
    components: {
        AppMultiselectV3,
        AppButton,
        AppDialogV2,
    },
    props: {
        isVisible: {
            required: true,
            type: Boolean,
            default: false,
        },
        selectedOrder: {
            required: true,
            type: Object,
            default: () => ({}),
        },
        offers: {
            required: true,
            type: Array,
            default: () => [],
        },
        accountId: {
            type: String,
            required: true,
        },
        relatedAccount: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            selectedPlan: null,
            planChangeError: false,
            offersOptionsFormatted: [],

            // proxy
            BUTTON_TYPES,
        };
    },
    computed: {
        ...mapGetters(Modules.productcatalog, [Getters.PC_GET_ENTITY_BY_TYPE_AND_ID]),
        currentPlan() {
            if (!isEmpty(this.selectedOrder)) {
                const changePending = !!(this.selectedOrder.replaceInfo && this.selectedOrder.replaceInfo.entity_id);

                if (this.isSuborderDisabled) {
                    return Object.keys(this.selectedOrder.entities.offer).find(Boolean);
                }

                for (const [, subOrder] of Object.entries(this.selectedOrder.subOrders)) {
                    return changePending ? this.selectedOrder.replaceInfo.entity_id : subOrder.entity_id;
                }
            }
            return '';
        },
        relatedAccountAddressWithFilterData() {
            return this.relatedAccount?.addresses.find((addressInstance: AccountAddress) => {
                return Boolean(addressInstance.marketId && addressInstance.addressbookId && addressInstance.provider);
            });
        },
        isFiberSpecificListOfOptions() {
            return getOperatorConfigValue('limitedChangePlanOptions');
        },
    },
    created() {
        this.fetchFilteredOffers();
    },
    methods: {
        ...mapActions(Modules.orders, [Actions.REPLACE_ORDER]),
        checkPromoForOffer() {
            this.planChangeError = false;
        },
        formatOfferForDropdown(offers) {
            return offers
                .map(offer => {
                    return {
                        ...offer,
                        label: offer.name,
                    };
                })
                .filter(offer => offer.operation === STATUS_CODES_OPERATIONS.RUNNING && offer.id !== this.currentPlan);
        },
        fetchFilteredOffers() {
            this.$withProgressBar(
                async () => {
                    if (this.isFiberSpecificListOfOptions && this.relatedAccountAddressWithFilterData) {
                        const response = await getOffersClientAPI({
                            provider_id: this.relatedAccountAddressWithFilterData.provider,
                            market_id: this.relatedAccountAddressWithFilterData.marketId,
                            addressbook_id: this.relatedAccountAddressWithFilterData.addressbookId,
                        });
                        this.offersForFormatting = Object.values(response?.data?.offer_by_id).map(
                            offer => new ProductCatalogOfferModel(offer),
                        );
                    } else {
                        this.offersForFormatting = this.offers;
                    }
                    this.offersOptionsFormatted = this.formatOfferForDropdown(this.offersForFormatting);
                },
                {
                    errorHandler: () => {
                        this.$eventBus.$emit('showAlert', {
                            message: this.$i18n.t('alertMessage.errorDoingSmthTryAgain', {
                                action: 'fetching',
                                entityName: 'offers',
                            }),
                        });
                        // fallback for failed API call, so users still have all offers available
                        this.offersOptionsFormatted = this.formatOfferForDropdown(this.offers);
                    },
                },
            );
        },
        onSavePlanChange() {
            if (!this.selectedPlan) {
                this.planChangeError = true;
                return;
            }

            let changeType = 0;
            const { state } = this.selectedOrder;
            if (state === ORDER_ENTITY_STATES.PENDING || state === ORDER_ENTITY_STATES.PENDING_ACTIVATION) {
                changeType = CHANGE_TYPE.PENDING;
            } else if (state === ORDER_ENTITY_STATES.ACTIVE) {
                changeType = CHANGE_TYPE.ACTIVE;
            }

            this.planChange(this.accountId, this.selectedPlan.id, changeType);
        },
        emitClose() {
            this.$emit('close');
        },
        async planChange(accountId, offerId, changeType) {
            await this.$withProgressBar(
                async () => {
                    await this[Actions.REPLACE_ORDER]({
                        accountId,
                        offerId,
                        changeType,
                    });
                    this.emitClose();
                },
                {
                    errorHandler: error => {
                        if (error.response?.data?.code === luaErrors.ORDEROE.TIME_CONSTRAINTS_NOT_SATISFIED.code) {
                            this.showBlackoutDialog = true;
                        } else {
                            this.$showErrorAlert({
                                message: this.$i18n.t('alertMessage.somethingWentWrong'),
                            });
                        }
                        this.emitClose();
                    },
                },
            );
        },
    },
});
