import http, { DnoResponseBase, Entity } from '@/http/index';
import { AxiosPromise } from 'axios';

type CategoryData = {
    name: string;
    description: string;
    parent?: string;
};

export type Category = Entity<CategoryData> & {
    portal_id: string;
};

type getCategoriesResponseBody = DnoResponseBase & {
    category_by_id: Record<string, Category>;
};

/**
 * Get categories.
 *
 * @returns {AxiosPromise}
 */
export function getCategories(ids = []): AxiosPromise<getCategoriesResponseBody> {
    let data = {};
    if (ids.length) {
        data = {
            ids,
        };
    }
    return http({
        method: 'POST',
        url: '/v3productcatalog/getcategories',
        data,
    });
}

/**
 * Add category.
 *
 * @returns {AxiosPromise}
 */
export function addCategory(data: unknown): AxiosPromise<DnoResponseBase> {
    return http({
        method: 'POST',
        url: '/v3productcatalogwrite/addcategory',
        data,
    });
}

/**
 * Update category.
 *
 * @returns {AxiosPromise}
 */
export function updateCategory(id: string, version: number, data: unknown): AxiosPromise<DnoResponseBase> {
    return http({
        method: 'POST',
        url: '/v3productcatalogwrite/updatecategory',
        data: {
            id,
            version,
            data,
        },
    });
}

/**
 * Update category state.
 *
 * @returns {AxiosPromise}
 */
export function updateCategoryState(id: string, version: number, state: number): AxiosPromise<DnoResponseBase> {
    return http({
        method: 'POST',
        url: '/v3productcatalogwrite/updatecategorystate',
        data: {
            id,
            version,
            state,
        },
    });
}

/**
 * Get category draft list.
 *
 * @returns {AxiosPromise}
 */
export function getCategoryDraftList(): AxiosPromise<DnoResponseBase> {
    return http({
        method: 'POST',
        url: '/v3productcatalog/getcategorydraftlist',
    });
}

/**
 * Delete category draft.
 *
 * @returns {AxiosPromise}
 */
export function deleteCategoryDraft(id: string): AxiosPromise<DnoResponseBase> {
    return http({
        method: 'POST',
        url: '/v3productcatalog/categorydraftdelete',
        data: {
            id,
        },
    });
}

/**
 * Get category draft.
 *
 * @returns {AxiosPromise}
 */
export function getCategoryDraft(id: string): AxiosPromise<DnoResponseBase> {
    return http({
        method: 'POST',
        url: '/v3productcatalog/getcategorydraft',
        data: {
            id,
        },
    });
}

/**
 * Set category draft.
 *
 * @returns {AxiosPromise}
 */
type CategoryDraftPayload = {
    id?: string;
    data: object;
};

export function setCategoryDraft(data: CategoryDraftPayload): AxiosPromise<DnoResponseBase> {
    return http({
        method: 'POST',
        url: '/v3productcatalogwrite/setcategorydraft',
        data,
    });
}
