import { uuidV4 } from '@/common/utils';

export default class Graph {
    uuid = undefined;

    name = '';

    colors = [];

    columns = [];

    constructor(name, columns, colors) {
        this.name = name;
        this.columns = columns;
        this.colors = colors || Graph.colorPatterns;
        this.uuid = Graph.generateUUID();
    }

    /**
     * Abstraction layer for creating UUIDs
     * @returns {string}
     */
    static generateUUID() {
        return uuidV4();
    }

    /**
     * List of colors available for assignment to data points in graphs and charts.
     * @type {string[]}
     */
    static colorPatterns = [
        '#6da4e4',
        '#ffd400',
        '#54cfe4',
        '#bcec49',
        '#318ba3',
        '#26ecb1',
        '#4ab4f8',
        '#c59eec',
        '#fecea8',
        '#ff9489',
    ];
}
