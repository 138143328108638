import UserManagerBaseModel, {
    PROPERTY_TYPE_BE_MAP,
    PROPERTY_TYPE_VALIDATE_FN_MAP,
} from '@/__new__/services/dno/user/models/UserManagerBaseModel';
import Flag, { Flags, FLAG_MAP } from '@/__new__/services/dno/user/models/Flag';
import { get } from 'lodash';
import { UM_SUBSCRIPTION_TYPES_MAP } from '@/__new__/features/customerCare/common/userInfoHelper';
import localeLibrary from '@/common/locale/localeLibrary';
import { getOperatorConfigValue } from '@/services/permissions/permissions.service';
import { USER_MANAGER_HIERARCHY } from '@/__new__/features/customerCare/common/customerCareHelper';

interface Address {
    address_1: string;
    address_2: string;
    city: string;
    state: string;
    zip: string;
    zip_4: string;
    hierarchy: number;
    type: number;
}
interface SubscriberConstructorArgs {
    id: string;
    name: string;
    surname: string;
    fullName: string;
    state: number;
    activationDate: number;
    creationDate: number;
    subscriptionType: number;
    subscriptionTypeString: string;
    msisdn: string;
    imsi: [];
    iccid: [];
    username: string;
    email: string;
    phone: string;
    flags: Flags<number>;
    accountId: string;
    userId: string;
    updateReason: string;
    cardinality: number;
    marketZip: string;
    addresses: Record<string, Address>;
}

interface SubscriberProperty {
    responsePath?: string;
    fallbackType?: keyof typeof PROPERTY_TYPE_VALIDATE_FN_MAP;
    defaultValue?: any;
    mapFn?(response: any): any;
    computeFn?(args: Partial<SubscriberConstructorArgs>): any;
}

interface SubscriberProperties {
    [key: string]: SubscriberProperty;
}

const SUBSCRIBER_PROPERTIES: SubscriberProperties = {
    id: {
        responsePath: 'subscriber_id',
        fallbackType: 'string',
        defaultValue: '',
    },
    name: {
        responsePath: 'properties.first_name',
        fallbackType: 'string',
        defaultValue: null,
    },
    surname: {
        responsePath: 'properties.last_name',
        fallbackType: 'string',
        defaultValue: null,
    },
    fullName: {
        computeFn: function (args) {
            return (<any>this).validateString('fullName', args?.name) &&
                (<any>this).validateString('fullName', args?.surname)
                ? `${args?.name} ${args?.surname}`
                : '';
        },
    },
    state: {
        responsePath: 'state',
        fallbackType: 'integer',
        defaultValue: null,
    },
    activationDate: {
        responsePath: 'first_activation',
        computeFn: function (args) {
            return (<any>this).validateNumberInteger('activationDate', args?.activationDate)
                ? (localeLibrary.getFormattedDate(args?.activationDate || 0) as string)
                : '';
        },
        fallbackType: 'string',
        defaultValue: '',
    },
    creationDate: {
        responsePath: 'created',
        computeFn: ({ creationDate }) => creationDate && localeLibrary.getFormattedDateAndTime(creationDate),
        fallbackType: 'string',
        defaultValue: '',
    },
    subscriptionType: {
        responsePath: 'subscription_type',
        fallbackType: 'integer',
        defaultValue: 0,
    },
    subscriptionTypeString: {
        computeFn: args => {
            return Subscriber.mapSubscriptionType(args?.subscriptionType) as string;
        },
        fallbackType: 'string',
        defaultValue: '',
    },
    msisdn: {
        responsePath: 'msisdn',
        fallbackType: 'string',
        defaultValue: '',
    },
    imsi: {
        responsePath: 'imsis',
        computeFn: function (args) {
            return (<any>this).validateArray('imsi', args?.imsi) && args?.imsi?.length ? args?.imsi.join(', ') : '';
        },
        fallbackType: 'string',
        defaultValue: '',
    },
    iccid: {
        responsePath: 'iccids',
        computeFn: function (args) {
            return (<any>this).validateArray('iccid', args?.iccid) && args?.iccid?.length ? args?.iccid.join(', ') : '';
        },
        fallbackType: 'string',
        defaultValue: '',
    },
    username: {
        responsePath: 'properties.alias',
        fallbackType: 'string',
        defaultValue: '',
    },
    email: {
        responsePath: 'subscriber_email',
        fallbackType: 'string',
        defaultValue: '',
    },
    phone: {
        responsePath: 'alternative_phone',
        fallbackType: 'string',
        defaultValue: '',
    },
    flags: {
        responsePath: 'flags',
        mapFn: response => {
            return {
                ...response.flags,
                is_blocked: response.is_blocked ? FLAG_MAP.TRUE : FLAG_MAP.FALSE,
            };
        },
        computeFn: args => {
            return Flag.mapSubscriberFlags(args?.flags);
        },
    },
    accountId: {
        responsePath: 'primary_account_id',
        fallbackType: 'string',
        defaultValue: '',
    },
    userId: {
        responsePath: 'user_owner',
        fallbackType: 'string',
        defaultValue: '',
    },
    updateReason: {
        responsePath: 'properties.state_update_reason',
        fallbackType: 'string',
        defaultValue: '',
    },
    wps: {
        responsePath: 'properties.wps',
        fallbackType: 'string',
        defaultValue: '',
    },
    marketZip: {
        responsePath: 'market_zip',
        fallbackType: 'string',
        defaultValue: '',
    },
    cardinality: {
        responsePath: 'cardinality',
        fallbackType: 'integer',
        defaultValue: '',
    },
    addresses: {
        responsePath: 'addresses',
        mapFn: response => response.addresses || {},
    },
};

export default class Subscriber extends UserManagerBaseModel {
    id!: string;
    fullName?: string;
    state?: number;
    activationDate?: string;
    creationDate?: string;
    subscriptionType?: number;
    subscriptionTypeString?: string;
    msisdn?: string;
    imsi?: string;
    iccid?: string;
    username?: string;
    email?: string;
    phone?: string;
    flags?: Flags;
    accountId?: string;
    userId?: string;
    updateReason?: string;
    umEntityType: USER_MANAGER_HIERARCHY.SUBSCRIBER;
    wps?: string; // mvne operator specific field
    marketZip?: string;
    addresses?: Record<string, Address>;

    constructor(args: Partial<SubscriberConstructorArgs>) {
        // Call constructor of UserManagerBaseModel class for creating new object with unique invalidKeys Map
        super();
        for (const [key, props] of Object.entries(SUBSCRIBER_PROPERTIES)) {
            let value = args?.[key as keyof SubscriberConstructorArgs];
            if (props?.computeFn instanceof Function) {
                value = props.computeFn.call(this, args);
            }
            (<any>this)[key] = this.validateProperty(key, value, props);
        }
        this.umEntityType = USER_MANAGER_HIERARCHY.SUBSCRIBER;
    }

    validateProperty(key: string, value: any, props: SubscriberProperty) {
        // validate property value
        let propertyType = props?.fallbackType;
        const propertyKeyBe = props?.responsePath?.split('.').pop();
        if (propertyKeyBe) {
            // get property type from BE config
            const propertyTypeBe = getOperatorConfigValue(
                `service_config.lf-user.properties.subscriber_properties.${propertyKeyBe}`,
            );
            if (PROPERTY_TYPE_BE_MAP[propertyTypeBe as keyof typeof PROPERTY_TYPE_BE_MAP]) {
                propertyType = PROPERTY_TYPE_BE_MAP[propertyTypeBe as keyof typeof PROPERTY_TYPE_BE_MAP];
            }
        }
        const defaultValue = props?.defaultValue;
        // apply validation
        let validatedValue = value;
        if (propertyType && !this.validateByType(key, value, propertyType)) {
            validatedValue = defaultValue;
        }
        return validatedValue ?? defaultValue;
    }

    static mapSubscriptionType(type: number | undefined) {
        if (type === undefined || !UM_SUBSCRIPTION_TYPES_MAP.has(type)) {
            return '';
        }

        return UM_SUBSCRIPTION_TYPES_MAP.get(type);
    }

    static remapUserFromBe(response: any) {
        const mappedAccountObj: Record<string, any> = {};
        for (const [key, props] of Object.entries(SUBSCRIBER_PROPERTIES)) {
            const path = props?.responsePath;
            if (props?.mapFn) {
                mappedAccountObj[key] = props.mapFn(response);
            } else if (path) {
                mappedAccountObj[key] = get(response, path);
            }
        }
        return mappedAccountObj;
    }
}
