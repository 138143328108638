import NcsBaseCondition from '@/__new__/services/dno/charging/models/chargingSpecificationConditions/NCSbaseCondition';
import { DataBalanceConditionArg } from '@/__new__/services/dno/charging/common/dataBalanceCondition';

export default class DataBalanceCondition extends NcsBaseCondition {
    data: any;

    type: string;

    uuid: string;

    dataValid: boolean;

    dataEmpty: boolean;

    constructor({ data, uuid, type, dataEmpty = true, dataValid = false }: DataBalanceConditionArg) {
        super();
        this.data = data;
        this.type = type;
        this.uuid = uuid;
        this.dataEmpty = dataEmpty;
        this.dataValid = dataValid;
    }

    validationCondition(): boolean {
        return !this.data?.value;
    }

    isDataValid(): void {
        this.dataValid = !this.validationCondition();
    }
}
