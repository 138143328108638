import RouteNames from '@/router/routeNames';
import ENTITY_TYPES from '@/common/entities/entityTypes';

export const MAP_ENTITY_TYPE_TO_ROUTE_NAME = {
    [ENTITY_TYPES.CHARGING_SPECIFICATION]: RouteNames.CHARGING_CHARGING_SPECIFICATIONS,
    [ENTITY_TYPES.CHARGING_SPECIFICATIONS_GROUP]: RouteNames.CHARGING_CHARGING_SPECIFICATIONS_GROUPS,
    [ENTITY_TYPES.POLICY_COUNTER]: RouteNames.CHARGING_POLICY_COUNTERS,
    [ENTITY_TYPES.POLICY_RULE]: RouteNames.CHARGING_POLICY_RULES,
    [ENTITY_TYPES.RATING_GROUP]: RouteNames.CHARGING_RATING_GROUPS,
    [ENTITY_TYPES.USAGE_COUNTER]: RouteNames.CHARGING_USAGE_COUNTERS,
    [ENTITY_TYPES.CONDITION_PARAMETERS]: RouteNames.CHARGING_CONDITION_PARAMETERS,
    [ENTITY_TYPES.WALLET_COUNTERS]: RouteNames.CHARGING_WALLET_COUNTERS,
    [ENTITY_TYPES.CONTENT_TYPES]: RouteNames.CHARGING_CONTENT_TYPES,
    [ENTITY_TYPES.TARIFF_SPECIFICATION]: RouteNames.CHARGING_TARIFF_SPECIFICATIONS,
    [ENTITY_TYPES.TARIFF_SPECIFICATION_ZERO_RATE]: RouteNames.ZERO_RATE_CHARGING_TARIFF_SPECIFICATIONS,
    [ENTITY_TYPES.TARIFF_SPECIFICATION_GROUP]: RouteNames.CHARGING_TARIFF_SPECIFICATION_GROUPS,
};
