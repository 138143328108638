import { getChargingVersionHistory } from '@/__new__/services/dno/charging/http/chargingCommon';
import { ChargingEntityVersions } from '@/__new__/services/dno/charging/models/ChargingEntityVersions';
import { ChargingEntityVersion } from '@/__new__/services/dno/charging/models/ChargingInterfaces';
import { getMultiLangFieldValueByLocale } from '@/common/entities/entityHelper';

export enum CHARGING_ENTITY_TYPES {
    WALLET_COUNTER = 'wallet_counter',
    RATING_GROUP = 'rating_group',
    CONDITION_PARAMETER = 'condition_parameter',
    CONDITION_PARAMETER_SURCHARGE = 'condition_parameter_surcharge',
    POLICY_RULE = 'policy_rule',
    POLICY_COUNTER = 'policy_counter',
    USAGE_COUNTER = 'usage_counter',
    CHARGING_SPECIFICATION = 'charging_spec',
    CONTENT_TYPE = 'content_type',
    TARIFF_SPECIFICATION = 'tariff_spec',
}

export async function loadVersionHistory(
    entityType: CHARGING_ENTITY_TYPES,
    id: string,
): Promise<ChargingEntityVersion[]> {
    const res = await getChargingVersionHistory(entityType, [id]);
    const resObj = res?.data?.charging_versions_by_id;

    const chargingVersionsArr = resObj[id]?.map((el: any) => {
        const versionData = new ChargingEntityVersions({
            entityId: id,
            ...el,
        });
        versionData.getUpdateUserName();
        return versionData;
    });

    return chargingVersionsArr || [];
}

export type LowerEntitiesVersions = {
    id: string;
    name: string;
    chargingVersion: string;
};

export function mapLowerEntitiesVersions(entities: any, selectetData: string[]): any[] {
    return selectetData?.map(el => {
        const data = entities?.[el];
        if (data) {
            const getEntityName = getMultiLangFieldValueByLocale(data?.data?.name);
            return {
                id: data?.id || null,
                name: `${getEntityName} (${data?.charging_version})`,
                chargingVersion: data?.charging_version || null,
            };
        }
    });
}

export type LowerEntitiesVersionsObject = Record<CHARGING_ENTITY_TYPES, LowerEntitiesVersions[]>;

export function getLowerEntitiesVersions(
    entitiType: CHARGING_ENTITY_TYPES,
    data: LowerEntitiesVersionsObject,
): LowerEntitiesVersions[] {
    return data?.[entitiType] || [];
}

export default {};
