
import { required, minLength } from 'vuelidate/lib/validators';
import AppAditionalSidebar from '@/components/partials/AppAditionalSidebar.vue';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import AppTable from '@/components/partials/AppTable.vue';
import AppTextareaV3 from '@/components/partials/inputs/AppTextareaV3.vue';
import { formatAmountBasedOnCurrency, sumNumbers } from '@/common/formatting';
import tableColumnType from '@/common/filterTable';
// HTTP
import customerCareHTTP from '@/__new__/services/dno/user/http/customer-care';
import ordersHTTP from '@/__new__/services/dno/orders/http/orders';
// Helpers
import { ALERT_TYPES } from '@/common/alerts/Alert';
import luaErrors from '@/common/luaErrors';
import Account from '@/models/Account';
import isEmpty from 'lodash/isEmpty';
import { BILLING_TRX_PAYMENT_TYPES } from '@/__new__/features/customerCareSuite/common/transactionHelper';
import _ from 'lodash';

export default {
    name: 'AdditionalSidebarForCharges',
    components: {
        AppAditionalSidebar,
        AppButton,
        AppTable,
        AppTextareaV3,
    },
    props: {
        transactionData: {
            type: Object,
            default: null,
        },
        showAdditionalSidebar: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            isDataLoading: false,
            chargesFromApi: [],
            selectedChargesIds: [] as Array<string>,
            refundReason: '',
            BUTTON_TYPES,
            shouldShowTable: true,
        };
    },
    validations() {
        return {
            refundReason: {
                required,
            },
            selectedChargesIds: {
                required,
                minLength: minLength(1),
            },
        };
    },
    computed: {
        charges() {
            return (this.chargesFromApi || []).map(charge => ({
                chargeId: charge?.charge_id,
                subscriberId: charge?.item_price?.subscriber_id,
                offerName: charge?.offer_data?.entity_name,
                amountWithoutTax: charge?.item_price?.sub_total,
                amountWithTax: charge?.item_price?.amount,
                discount:
                    charge?.item_price?.pricing_rule_adjustments
                        ?.reduce((total, adjustment) => {
                            const amount = parseFloat(adjustment.amount || 0);
                            return total + amount;
                        }, 0)
                        .toFixed(2) || '0.00',
                taxes: charge?.item_price?.tax?.total_tax_amount,
            }));
        },
        pricing() {
            return (this.chargesFromApi || [])
                .flatMap(charge => {
                    const adjustments = charge?.item_price?.pricing_rule_adjustments || [];
                    if (adjustments.length) {
                        return adjustments.map(adjustment => ({
                            offerName: charge?.offer_data?.entity_name,
                            pricingRuleName: adjustment?.pricing_rule_info?.pricing_rule_name?.en,
                            discount: adjustment?.amount,
                            description: adjustment?.pricing_rule_info?.pricing_rule_description?.en,
                            pricingRuleId: adjustment?.pricing_rule_id,
                        }));
                    }
                    return null;
                })
                .filter(Boolean);
        },
        refundDetail() {
            return (this.chargesFromApi || [])
                .flatMap(charge => {
                    const refundDetails = charge?.refund_details || [];
                    if (refundDetails.length) {
                        return refundDetails.map(refund => ({
                            refundId: refund.refund_id,
                            refundAmount: refund.refund_amount,
                            refundDate: refund.refund_date,
                            refundStatus: refund.refund_status,
                            chargeId: charge.charge_id,
                        }));
                    }
                    return null;
                })
                .filter(Boolean);
        },
        chargesColumnsData() {
            return [
                {
                    name: this.$i18n.t('customerCare.transactionHistory.offerName'),
                    key: 'offerName',
                    field: 'offerName',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('generic.amountWithoutTax'),
                    key: 'amountWithoutTax',
                    field: 'amountWithoutTax',
                    filterType: tableColumnType.NUMBER,
                },
                {
                    name: this.$i18n.t('generic.discount'),
                    key: 'discount',
                    field: 'discount',
                    filterType: tableColumnType.NUMBER,
                },
                {
                    name: this.$i18n.t('customerCare.account.taxes'),
                    key: 'taxes',
                    field: 'taxes',
                    filterType: tableColumnType.NUMBER,
                },
                {
                    name: this.$i18n.t('generic.amountWithTax'),
                    key: 'amountWithTax',
                    field: 'amountWithTax',
                    filterType: tableColumnType.NUMBER,
                },
                {
                    name: this.$i18n.t('charterDemo.chargeId'),
                    key: 'chargeId',
                    field: 'chargeId',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('customerCare.subscriberId'),
                    key: 'subscriberId',
                    field: 'subscriberId',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
            ];
        },
        pricingColumnsData() {
            return [
                {
                    name: this.$i18n.t('customerCare.transactionHistory.offerName'),
                    key: 'offerName',
                    field: 'offerName',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('generic.discount'),
                    key: 'discount',
                    field: 'discount',
                    filterType: tableColumnType.NUMBER,
                },
                {
                    name: this.$i18n.t('pricingAndFees.pricingRuleName'),
                    key: 'pricingRuleName',
                    field: 'pricingRuleName',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('pricingAndFees.pricingRuleDescription'),
                    key: 'description',
                    field: 'description',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('pricingAndFees.pricingRuleId'),
                    key: 'pricingRuleId',
                    field: 'pricingRuleId',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
            ];
        },
        refundColumnsData() {
            return [
                {
                    name: this.$i18n.t('generic.refundId'),
                    key: 'refundId',
                    field: 'refundId',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('generic.status'),
                    key: 'refundStatus',
                    field: 'refundStatus',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('generic.refundAmount'),
                    key: 'refundAmount',
                    field: 'refundAmount',
                    filterType: tableColumnType.Number,
                },
                {
                    name: this.$i18n.t('generic.date'),
                    key: 'refundDate',
                    field: 'refundDate',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('charterDemo.chargeId'),
                    key: 'chargeId',
                    field: 'chargeId',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
            ];
        },
        disabledCheckboxIds() {
            return (this.chargesFromApi || [])
                .filter(charge => {
                    const price = Number(charge?.item_price?.tax?.gross_amount);
                    const refunded = Boolean(charge?.refund_details || !isEmpty(charge?.refund_details));
                    return refunded || !price;
                })
                .map(charge => charge.charge_id);
        },

        totalRefundAmount(): string {
            return this.selectedChargesIds.reduce((totalAmount: number, chargeId: string) => {
                const relatedCharge = this.chargesFromApi.find(charge => charge.charge_id === chargeId);
                if (relatedCharge) {
                    const chargeSubTotal = relatedCharge?.item_price?.tax?.gross_amount;
                    return sumNumbers(totalAmount, chargeSubTotal);
                }
                return totalAmount;
            }, 0);
        },
        totalRefundAmountFormatted() {
            return this.totalRefundAmount
                ? formatAmountBasedOnCurrency(this.totalRefundAmount)
                : formatAmountBasedOnCurrency(0);
        },
        formatChargesForRefund() {
            const chargesById = _.keyBy(this.chargesFromApi, ({ charge_id: chargeId }) => chargeId);
            return this.selectedChargesIds.map(selectedChargeId => ({
                ref_id:
                    this.transactionData.paymentType === BILLING_TRX_PAYMENT_TYPES.DIRECT_BILL_PAYMENT
                        ? this.transactionData.billId
                        : this.transactionData.orderId,
                refund_amount: chargesById[selectedChargeId]?.item_price?.tax?.gross_amount,
                charge_id: selectedChargeId,
                refund_reason: this.refundReason,
            }));
        },
    },
    watch: {
        transactionData: {
            async handler(newVal) {
                if (newVal && !isEmpty(newVal)) {
                    this.shouldShowTable = false;
                    this.resetData();
                    const response = await this.fetchCharges(newVal);
                    this.chargesFromApi = response.data?.charge_data?.charges;
                    this.shouldShowTable = true;
                }
            },
        },
    },
    methods: {
        formatAmountBasedOnCurrency,
        hideSidebar() {
            this.resetData();
            this.$emit('closeSidebar');
        },
        resetData() {
            this.selectedChargesIds = [];
            this.refundReason = '';
            this.$v.$reset();
        },
        async fetchCharges(transactionData) {
            try {
                this.$Progress.start();
                const chargesResponse = await ordersHTTP.getCharges({
                    target_id: transactionData.targetId,
                    target_type: transactionData.targetType,
                    billing_payment_id: transactionData.billingPaymentId,
                    payment_type: transactionData.paymentType,
                    filters:
                        transactionData.paymentType === BILLING_TRX_PAYMENT_TYPES.DIRECT_BILL_PAYMENT
                            ? {
                                  bill_id: transactionData.billId,
                                  // TODO: fill once updated
                                  // charge_aggregation_id: newVal.orderId,
                              }
                            : {
                                  order_id: transactionData.orderId,
                              },
                });
                this.$Progress.finish();
                return chargesResponse;
            } catch (err) {
                this.$Progress.fail();
                this.$alert(this.$i18n.t('alertMessage.somethingWentWrongFetchingNecessaryData'));
                return err;
            }
        },
        async refundPayment() {
            this.$v.$touch();

            if (this.selectedChargesIds.length === 0) {
                this.$alert(this.$t('alertMessage.userManagement.alertMessages.noChargeSelected'));
                return;
            }

            if (!this.$v.$anyError) {
                await this.$withProgressBar(
                    async () => {
                        await customerCareHTTP.refundTransactionNextGen({
                            target_id: this.transactionData.targetId,
                            target_type: this.transactionData.targetType,
                            ref_type: this.transactionData.paymentType,
                            agent_id: Account.storedInstance?.email,
                            charges: this.formatChargesForRefund,
                        });
                        this.$alert(
                            this.$i18n.t('alertMessage.userManagement.refundSuccessefull', {
                                amount: this.totalRefundAmountFormatted,
                            }),
                            {
                                type: ALERT_TYPES.success,
                            },
                        );
                        this.hideSidebar();
                    },
                    {
                        errorHandler: error => {
                            if (error.response?.data.code === luaErrors.BILLING_V4.INVALID_REFUND_AMOUNT.code) {
                                this.$alert(
                                    this.$i18n.t('alertMessage.userManagement.alertMessages.invalidRefundAmount'),
                                    {
                                        type: ALERT_TYPES.error,
                                    },
                                );
                            } else if (
                                error.response?.data.code === luaErrors.BILLING_V4.CHARGE_ALREADY_REFUNDED.code
                            ) {
                                this.$alert(
                                    this.$i18n.t('alertMessage.userManagement.alertMessages.chargeIsAlreadyRefunded'),
                                    {
                                        type: ALERT_TYPES.error,
                                    },
                                );
                            } else {
                                this.$alert(this.$i18n.t('alertMessage.userManagement.refundError'), {
                                    type: ALERT_TYPES.error,
                                });
                            }
                        },
                    },
                );
            }
        },
    },
};
