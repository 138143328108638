<template>
    <AbstractListPageWrapper
        :isOverviewEnabled="isOverviewEnabled"
        :pageTitle="$i18n.t('promotions.promotions')"
        :entitiesCount="formattedFilteredEntities.length"
        @searchQueryChanged="setSearchQuery"
    >
        <template #button>
            <ResponseModalButton
                :response="promotionsApiResponse"
                :title="$i18n.t('promotions.promotions')"
            />
        </template>

        <template #filterTable>
            <FilterTable
                :columns="tableColumnsData"
                @filterAdded="onFilterAdded"
            />
        </template>

        <template #headerButtons>
            <AppButton
                id="add-item-button"
                :buttonType="BUTTON_TYPES.PRIMARY"
                :iconType="ICON_TYPES.PLUS"
                :label="$i18n.t('promotions.newPromotion')"
                @click="addNewEntity"
            />
        </template>

        <template #allFilters>
            <TableFiltersTags
                :filterTableMixin="filterTableMixin"
                class="my-3 ml-2"
                @removeFilter="removeFilter"
                @removeAllFilters="removeAllFilters"
            />
        </template>

        <template #table>
            <AppTable
                :entities="formattedFilteredEntities"
                :canSelectColumns="true"
                :canSort="true"
                :selectedEntityId="selectedEntityId"
                :innerSearchQuery="searchQueryForTable"
                :isSearchEnabled="true"
                :isInnerSearchQueryPassed="false"
                :columnsData="tableColumnsData"
                :isDataLoading="isDataLoading"
                :entityType="entityType"
                :enableRowStateControls="true"
                tableKey="promotionsMainTable"
                @selectedEntityModel="onEntitySelected"
            >
                <template #status="{ entity }">
                    <EntityStatusIndicator :status="entity.state" />
                </template>
            </AppTable>
        </template>

        <template #overview>
            <MutationDialog
                v-model="isMutationModalVisible"
                :entities="affectedEntities"
                @input="clearMutationDialogDataType"
            />

            <EntityOverview
                :entityType="ENTITY_TYPES.PROMOTION"
                :entity="selectedEntity"
                @closeOverview="isOverviewEnabled = false"
            >
                <template #section-1-header>
                    <div>
                        <OverviewHeader
                            v-if="selectedEntity"
                            :entityName="selectedEntity.name[selectedLanguage]"
                            :entityType="ENTITY_TYPES.PROMOTION"
                        />
                        <LanguageSwitcher v-model="selectedLanguage" />
                    </div>
                </template>

                <template #section-1-content>
                    <div>
                        <OverviewList
                            :lists="entityOverviewDetails"
                            :collapse="false"
                            :isNewDesignSelected="true"
                            @showDialog="showMutationDialog"
                        />
                    </div>
                </template>
            </EntityOverview>
        </template>
    </AbstractListPageWrapper>
</template>

<script>
// Vue Components
import AbstractListPageWrapper from '@/components/layout/AbstractListPageWrapper.vue';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import { ICON_TYPES } from '@/common/iconHelper';
import AppTable from '@/components/partials/AppTable.vue';
import EntityStatusIndicator from '@/components/partials/EntityStatusIndicator.vue';
import FilterTable from '@/components/partials/FilterTable.vue';
import EntityOverview from '@/components/partials/entityOverview/EntityOverview.vue';
import OverviewList from '@/components/partials/entityOverview/OverviewList.vue';
import OverviewHeader from '@/components/partials/entityOverview/OverviewHeader.vue';
import MutationDialog from '@/components/partials/MutationDialog.vue';
import LanguageSwitcher from '@/components/partials/inputs/LanguageSwitcher.vue';
import TableFiltersTags from '@/components/filters/TableFiltersTags.vue';
import ResponseModalButton from '@/components/partials/ResponseModalButton.vue';

// Libs and helpers
import tableColumnType from '@/common/filterTable';
import ENTITY_TYPES from '@/common/entities/entityTypes';
import {
    PROMOTION_TYPES,
    PROMOTION_ENUM_TO_KEY_MAP,
    GRANT_TYPE_ENUM_TO_OBJECT,
} from '@/modules/promotions/common/promotionsHelper';
import lowerFirst from 'lodash/lowerFirst';
import { getEntityStateName } from '@/common/commonEntityStateMapper';
import {
    formatMutationDialogEntities,
    getMultiLangFieldValueByLocale,
    getMutationDialogTitleKey,
} from '@/common/entities/entityHelper';

// Mixins
import FilterTableMixin from '@/components/partials/FilterTableMixin.vue';

// Routing
import RouteNames from '@/router/routeNames';

// vuex store
import { mapActions, mapGetters } from 'vuex';
import Actions, { Getters } from '@/store/mutation-types';

// HTTP
import { getPromotions } from '@/modules/promotions/http/promotion';

export default {
    name: 'Promotions',

    components: {
        AppTable,
        AppButton,
        AbstractListPageWrapper,
        FilterTable,
        EntityStatusIndicator,
        EntityOverview,
        OverviewHeader,
        OverviewList,
        MutationDialog,
        LanguageSwitcher,
        TableFiltersTags,
        ResponseModalButton,
    },

    mixins: [FilterTableMixin],

    data() {
        return {
            // Proxy imports
            ICON_TYPES,
            BUTTON_TYPES,
            selectedLanguage: '',
            entityType: ENTITY_TYPES.PROMOTION,
            ENTITY_TYPES,
            searchQueryForTable: '',
            selectedEntity: null,
            isDataLoading: false,
            isMutationModalVisible: false,
            mutationDialogDataType: '',
            isOverviewEnabled: false,
            promotionsApiResponse: null,
            promotionsList: [],
        };
    },

    computed: {
        ...mapGetters('productcatalog', [Getters.PC_GET_ENTITIES_BY_TYPE_APPROVED]),
        languageDefault() {
            return this.$store.getters[`operators/${Getters.languageDefault}`];
        },
        approvedOffers() {
            return this[Getters.PC_GET_ENTITIES_BY_TYPE_APPROVED](ENTITY_TYPES.OFFER);
        },
        tableColumnsData() {
            return [
                {
                    name: this.$i18n.t('promotions.promotionRuleType'),
                    key: 'grantTypeLabel',
                    field: 'grantTypeLabel',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('generic.name'),
                    key: 'nameLabel',
                    field: 'nameLabel',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('promotions.benefitType'),
                    key: 'benefitTypeLabel',
                    field: 'benefitTypeLabel',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('promotions.promotionShortDesc'),
                    key: 'promoShortDescriptionLabel',
                    field: 'promoShortDescriptionLabel',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('promotions.benefitShortDesc'),
                    key: 'benefitShortDescriptionLabel',
                    field: 'benefitShortDescriptionLabel',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('generic.startDate'),
                    key: 'startDateLabel',
                    field: 'startDateLabel',
                    filterType: tableColumnType.DATE,
                },
                {
                    name: this.$i18n.t('generic.endDate'),
                    key: 'endDateLabel',
                    field: 'endDateLabel',
                    filterType: tableColumnType.DATE,
                },
                {
                    name: this.$i18n.t('promotions.numberOfApplicableEntities'),
                    key: 'numberOfAppliedEntitiesLabel',
                    field: 'numberOfAppliedEntitiesLabel',
                    filterType: tableColumnType.GENERAL_TEXT,
                    sortBy: promotion => promotion.numberOfAppliedEntities,
                },
                {
                    name: this.$i18n.t('promotions.numberOfRecurrences'),
                    key: 'recurrencesLabel',
                    field: 'recurrencesLabel',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
            ];
        },
        formattedEntities() {
            return this.promotionsList.map(promotionResObject => {
                const promotionData = promotionResObject?.data;
                const promotionDisplayLabels = {};

                // name and description english labels
                promotionDisplayLabels.nameLabel =
                    promotionData.name && getMultiLangFieldValueByLocale(promotionData.name);
                promotionDisplayLabels.descriptionLabel =
                    promotionData.description && getMultiLangFieldValueByLocale(promotionData.description);

                // promotion type
                const promoRuleTypeEnum = promotionData.promo_rule_type;
                const promotionTypeKey = PROMOTION_ENUM_TO_KEY_MAP[promoRuleTypeEnum];
                promotionDisplayLabels.benefitTypeLabel =
                    PROMOTION_TYPES[promotionTypeKey]?.label || this.$i18n.t('generic.N/A');

                // promotion discount amount
                const amount = promotionData.amounts && promotionData.amounts.primary;
                if (promotionTypeKey === PROMOTION_TYPES.PriceOverride.key) {
                    promotionDisplayLabels.amountLabel = `${this.$localeLibrary.getFormattedAmount(
                        amount,
                    )} ${this.$i18n.t('promotions.amountPriceLabel')}`;
                } else if (promotionTypeKey === PROMOTION_TYPES.FlatDiscount.key) {
                    promotionDisplayLabels.amountLabel = `${this.$localeLibrary.getFormattedAmount(
                        amount,
                    )} ${this.$i18n.t('promotions.amountOffLabel')}`;
                } else {
                    promotionDisplayLabels.amountLabel = `${amount}% ${this.$i18n.t('promotions.amountOffLabel')}`;
                }

                // recurrence
                if (promotionData.recurrence_limit > 0) {
                    promotionDisplayLabels.appliesToLabel = this.$i18n.t('promotions.nextNRecurrences', {
                        n: promotionData.recurrence_limit,
                    });
                } else {
                    promotionDisplayLabels.appliesToLabel = this.$i18n.t('promotions.allRecurrences');
                }

                promotionDisplayLabels.startRecurrenceLabel = promotionData.start_recurrence_number;

                promotionDisplayLabels.startDateLabel = this.$localeLibrary.getFormattedDate(promotionData.start_time);
                promotionDisplayLabels.endDateLabel = this.$localeLibrary.getFormattedDate(promotionData.end_time);

                const shortDescriptions = promotionData.short_descriptions;
                if (shortDescriptions && shortDescriptions.length > 0) {
                    const firstShortDesc = shortDescriptions[0];
                    promotionDisplayLabels.promoShortDescriptionLabel = firstShortDesc.promotion_short_description;
                    promotionDisplayLabels.benefitShortDescriptionLabel = firstShortDesc.benefit_short_description;
                }

                // number of applied offers
                if (promotionData.applicable_offers) {
                    const numOfApplidOffers = promotionData.applicable_offers.length;
                    promotionDisplayLabels.numberOfAppliedEntities = numOfApplidOffers;
                    promotionDisplayLabels.numberOfAppliedEntitiesLabel = `${numOfApplidOffers} Offer`;
                } else {
                    promotionDisplayLabels.numberOfAppliedEntities = 0;
                }

                // recurrences
                promotionDisplayLabels.recurrencesLabel =
                    promotionData.recurrence_limit || this.$i18n.t('generic.unlimited');

                // grant type
                promotionDisplayLabels.grantTypeLabel =
                    GRANT_TYPE_ENUM_TO_OBJECT[promotionData.grant_type]?.label || this.$i18n.t('promotions.voucher');

                return {
                    ...promotionResObject.data,
                    id: promotionResObject.id,
                    state: promotionResObject.state,
                    version: promotionResObject.version,

                    ...promotionDisplayLabels,
                };
            });
        },
        formattedFilteredEntities() {
            return this.filteredEntitiesMixin(this.formattedEntities);
        },
        selectedEntityId() {
            return this.selectedEntity ? this.selectedEntity.id : null;
        },
        selectedEntityApplicableOffersAmount() {
            return this.selectedEntityId && this.selectedEntity.applicable_offers
                ? this.selectedEntity.applicable_offers.length
                : this.approvedOffers.length;
        },
        affectedEntities() {
            if (this.mutationDialogDataType === this.ENTITY_TYPES.OFFER) {
                let affectedOffers = [];
                if (this.selectedEntity.applicable_offers?.length) {
                    // getting list of affeccted offers from Vuex
                    affectedOffers = this.selectedEntity.applicable_offers.map(offerId =>
                        this.$store.getters[`productcatalog/${Getters.PC_GET_ENTITY_BY_TYPE_AND_ID}`](
                            ENTITY_TYPES.OFFER,
                            offerId,
                        ),
                    );
                } else {
                    affectedOffers = this.approvedOffers;
                }

                // Formatting it as per MutationDialog.vue requirements
                return formatMutationDialogEntities(
                    {
                        [ENTITY_TYPES.OFFER]: affectedOffers.map(affectedOffer => ({
                            name: affectedOffer.name,
                            state: getEntityStateName(affectedOffer.state),
                        })),
                    },
                    ENTITY_TYPES.OFFER,
                );
            }
            return {};
        },
        entityOverviewDetails() {
            if (this.selectedEntity) {
                return [
                    {
                        name: this.$i18n.t('generic.details'),
                        properties: [
                            {
                                label: this.$i18n.t('generic.id'),
                                value: this.selectedEntity.id,
                            },
                            {
                                label: this.$i18n.t('promotions.promotionRuleType'),
                                value: this.selectedEntity.grantTypeLabel,
                            },
                            {
                                label: this.$i18n.t('generic.name'),
                                value: this.selectedEntity.name[this.selectedLanguage],
                            },
                            {
                                label: this.$i18n.t('promotions.promotionShortDesc'),
                                value: this.selectedEntity.promoShortDescriptionLabel,
                            },
                            {
                                label: this.$i18n.t('promotions.benefitShortDesc'),
                                value: this.selectedEntity.benefitShortDescriptionLabel,
                            },

                            {
                                label: this.$i18n.t('promotions.numberOfApplicableEntities'),
                                value: this.selectedEntity.numberOfAppliedEntitiesLabel,
                            },
                        ],
                    },
                    {
                        name: this.$i18n.t('promotions.promotionBenefit'),
                        properties: [
                            {
                                label: this.$i18n.t('promotions.benefitType'),
                                value: this.selectedEntity.benefitTypeLabel,
                            },
                            {
                                label: this.$i18n.t('promotions.discountAmount'),
                                value: this.selectedEntity.amountLabel,
                            },
                            {
                                label: this.$i18n.t('promotions.applyTo'),
                                value: this.selectedEntity.appliesToLabel,
                            },
                            {
                                label: this.$i18n.t('promotions.startFromRecurrenceLabel'),
                                value: this.selectedEntity.startRecurrenceLabel,
                            },
                        ],
                    },
                    {
                        name: this.$i18n.t('promotions.promotionParameters'),
                        properties: [
                            {
                                label: this.$i18n.t('generic.startDate'),
                                value: this.selectedEntity.startDateLabel,
                            },
                            {
                                label: this.$i18n.t('generic.endDate'),
                                value: this.selectedEntity.endDateLabel,
                            },
                        ],
                    },
                    {
                        name: this.$i18n.t('promotions.promotionsUsedBy'),
                        properties: [
                            {
                                label:
                                    this.selectedEntityApplicableOffersAmount === 1
                                        ? `${this.selectedEntityApplicableOffersAmount} ${lowerFirst(
                                              this.$i18n.tc('productCatalog.entities.offer'),
                                          )}`
                                        : `${this.selectedEntityApplicableOffersAmount} ${lowerFirst(
                                              this.$i18n.t('productCatalog.entities.plural.offers'),
                                          )}`,
                                value: this.selectedEntityApplicableOffersAmount,
                                formatter: 'link',
                                type: ENTITY_TYPES.OFFER,
                                linkLabel: this.$i18n.t('generic.seeDetails'),
                            },
                        ],
                    },
                ];
            }
            return [];
        },
    },

    async created() {
        this.$Progress.start();
        this.isDataLoading = true;
        this.selectedLanguage = this.languageDefault;
        let errorFetchingData = false;

        // Get Promotions
        try {
            const response = await getPromotions();
            this.promotionsApiResponse = response;
            this.promotionsList = Object.values(response.data.promo_rule_by_id);
        } catch (error) {
            this.promotionsApiResponse = error.response;
            this.promotionList = [];
            errorFetchingData = true;
        }

        // Get PC Entities
        try {
            await this.$store.dispatch(`productcatalog/${Actions.PC_REQUEST_ENTITIES_BY_TYPE_AND_IDS}`, {
                entityType: ENTITY_TYPES.OFFER,
            });
        } catch (e) {
            errorFetchingData = true;
        }

        // Handle resource fetching failures
        if (errorFetchingData) {
            this.$Progress.fail();
            this.$showErrorAlert({
                message: this.$i18n.t('alertMessage.failedToLoadNecessaryData'),
            });
        } else {
            this.$Progress.finish();
        }

        this.isDataLoading = false;
    },

    methods: {
        ...mapActions('promotions', [Actions.REQUEST_PROMOTIONS]),
        setSearchQuery(query) {
            this.searchQueryForTable = query;
        },
        addNewEntity() {
            this.$router.push({
                name: RouteNames.PROMOTIONS_EDITOR,
                params: { companyId: this.$route.params.companyId },
            });
        },
        onEntitySelected(entity) {
            this.selectedEntity = entity;
            this.isOverviewEnabled = true;
        },
        showMutationDialog(type) {
            this.isMutationModalVisible = true;
            this.mutationDialogDataType = type;
        },
        clearMutationDialogDataType(value) {
            if (!value) {
                this.mutationDialogDataType = '';
            }
        },
        getMutationDialogTitleKey,
    },
};
</script>

<style scoped></style>
