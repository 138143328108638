import { AxiosPromise } from 'axios';
import http, { DnoResponseBase } from '@/http/index';
import { ContentType } from '@/__new__/services/dno/charging/models/ContentType';

interface ContentTypeApiResponse extends DnoResponseBase {
    content_type_by_id: Record<string, any>;
}

/**
 * Get content types.
 *
 * @returns {AxiosPromise}
 */
export function getContentTypes(
    ids: string[] = [],
    returnChargingVersion = true,
    includeReferencedEntities = false,
    includePortalId = true,
    chargingVersion?: string,
): AxiosPromise<ContentTypeApiResponse> {
    return http({
        method: 'POST',
        url: '/v3charging/getcontenttypes',
        data: {
            ids,
            return_charging_version: returnChargingVersion,
            include_referenced_entities: includeReferencedEntities,
            charging_version: chargingVersion,
            include_portal_id: includePortalId,
        },
    });
}

/**
 * Add content type.
 *
 * @returns {AxiosPromise}
 */
export function addContentType(data: any): AxiosPromise<any> {
    return http({
        method: 'POST',
        url: '/v3chargingwrite/addcontenttype',
        data,
    });
}

/**
 * Update content type.
 *
 * @returns {AxiosPromise}
 */
export function updateContentType(id: string, version: number, data: ContentType): AxiosPromise<ContentType> {
    return http({
        method: 'POST',
        url: '/v3chargingwrite/updatecontenttype',
        data: {
            id,
            version,
            data,
        },
    });
}

/**
 * Update content type state.
 *
 * @returns {AxiosPromise}
 */
export function updateContentTypeState(id: string, version: number, state: number): AxiosPromise<ContentType> {
    return http({
        method: 'POST',
        url: '/v3chargingwrite/updatestatecontenttype',
        data: {
            id,
            version,
            state,
        },
    });
}

interface ContentTypesDraftListApiResponse extends DnoResponseBase {
    data: any;
}

/**
 * Get content type draft list.
 *
 * @returns {AxiosPromise}
 */
export function getContentTypesDraftList(): AxiosPromise<ContentTypesDraftListApiResponse> {
    return http({
        method: 'POST',
        url: '/v3charging/getcontenttypesdraftlist',
    });
}

/**
 * Get content type entity draft.
 *
 * @returns {AxiosPromise}
 */
export function getContentTypeEntityDraft(id: string): AxiosPromise<DnoResponseBase> {
    return http({
        method: 'POST',
        url: '/v3charging/getcontenttypedraft',
        data: {
            id,
        },
    });
}

/**
 * Set content type draft.
 *
 * @returns {AxiosPromise}
 */
type ContentTypeDraftPayload = {
    id?: string;
    data: object;
};

export function setContentTypeDraft(data: ContentTypeDraftPayload): AxiosPromise<DnoResponseBase> {
    return http({
        method: 'POST',
        url: '/v3charging/setcontenttypedraft',
        data,
    });
}

/**
 * Delete content type draft.
 *
 * @returns {AxiosPromise}
 */
export function deleteContentTypeDraft(id: string): AxiosPromise<DnoResponseBase> {
    return http({
        method: 'POST',
        url: '/v3charging/deletecontenttypedraft',
        data: {
            id,
        },
    });
}

export default {
    getContentTypes,
    addContentType,
    updateContentType,
    updateContentTypeState,
    getContentTypesDraftList,
    getContentTypeEntityDraft,
    setContentTypeDraft,
    deleteContentTypeDraft,
};
