<template>
    <div
        v-show="isVisible"
        ref="dropdown"
        :class="['dropdown', colorSchema, { disabled, invalid: !isValidInput, button: asButton, isFilterOperation }]"
    >
        <div
            v-if="widthDependsOnContent"
            ref="widthChecker"
            class="width-checker"
        >
            {{ showCaption }}
        </div>
        <div class="d-flex">
            <input
                :value="showCaption"
                :class="[
                    'no-outline d-flex justify-content-between',
                    'align-items-center flex-grow-1 dropdown-input',
                    { searchable, invalid: !isValidInput },
                ]"
                :disabled="disabled"
                :readonly="!searchable"
                :placeholder="caption"
                type="text"
                data-toggle="dropdown"
                aria-expanded="false"
                @focus="focus"
                @blur="blur"
                @input="e => inputString(e.target.value, false)"
                @change="e => inputString(e.target.value, true)"
            />
            <div class="dropdown-arrow" />

            <div class="dropdown-menu m-0 p-0 rounded-0">
                <!-- todo move to the last visible position as per design -->
                <div
                    v-show="searchRunning"
                    class="loading-more py-2 pl-3 pr-2 display-flex justify-content-between align-items-center"
                >
                    <span>Loading more...</span>
                    <div class="spinner" />
                </div>

                <template v-if="!groupBy">
                    <span
                        v-for="(item, index) in displayedItems"
                        :key="item.id || index"
                        :class="['dropdown-item', { selected: isSelected(item) }]"
                        :title="getItemLabel(item)"
                        @mousedown="select(item)"
                    >
                        <slot
                            :item="{ item: item, label: getItemLabel(item) }"
                            name="item"
                            class="item-slot"
                        >
                            {{ getItemLabel(item) }}
                            <span
                                v-if="isShowSubLabel(item)"
                                class="item-sub-label"
                            >
                                {{ getItemSubLabel(item) }}
                            </span>
                        </slot>
                    </span>
                </template>

                <template v-else>
                    <div
                        v-for="(itemsGroup, group) in groupedDisplayedItems"
                        :key="group"
                        class="dropdown-items-group"
                    >
                        <div class="dropdown-item-header ml-3 mr-1 font-weight-bold">
                            {{ group }}
                        </div>

                        <span
                            v-for="(item, index) in itemsGroup"
                            :key="item.id || index"
                            :class="['dropdown-item', { selected: isSelected(item) }]"
                            @mousedown="select(item)"
                        >
                            <slot
                                :item="{ item: item, label: getItemLabel(item) }"
                                name="item"
                                class="item-slot"
                            >
                                {{ getItemLabel(item) }}
                            </slot>
                        </span>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import Vue from 'vue';
import groupBy from 'lodash/groupBy';
import * as $ from 'jquery'; // needed to fix bootstrap/popperjs dropdown dynamic positioning

export const Styles = {
    Default: 'default',
    White: 'white',
};

export const Events = {
    select: 'selected',
    deselect: 'deselected',
};

export default {
    name: 'Dropdown',
    props: {
        caption: { type: String, default: '' },
        multiselect: { type: Boolean, default: false },
        items: { type: Array, default: () => [] },
        selected: { type: Array, default: () => [] },
        displayProperty: { type: String, default: null },
        displaySubLabelProperty: { type: String, default: null },
        disabled: { type: Boolean, default: false },
        groupBy: { type: Function, default: null }, // function for grouping items
        searchable: { type: Boolean, default: false },
        editable: { type: Boolean, default: false },
        editValidationFunc: { type: Function, default: null }, // to validate input inplace
        invalid: { type: Boolean, default: false },
        emitAlreadySelected: { type: Boolean, default: false }, // emit even if already selected
        colorSchema: { type: String, default: Styles.White },
        emitInputOnChangeOnly: { type: Boolean, default: false },
        asButton: { type: Boolean, default: false },
        // should return promise with new items, i.e. fetched from server
        searchCallback: { type: Function, default: null },
        isVisible: { type: Boolean, default: true },
        widthDependsOnContent: { type: Boolean, default: false },
        isFilterOperation: { type: Boolean, default: false },
        isShowFullLabel: { type: Boolean, default: false },
    },
    data() {
        return {
            searchString: null,
            inputFocused: false,
            // used to prevent re-emitting input change event after select
            selectJustEmitted: false,
            foundItems: [],
            searchRunning: false,
        };
    },
    computed: {
        groupedDisplayedItems() {
            return groupBy(this.displayedItems, this.groupBy);
        },
        displayedItems() {
            if (this.searchable && this.searchString) {
                // eslint-disable-next-line vue/no-async-in-computed-properties
                Vue.nextTick(() => $('input[data-toggle="dropdown"]').dropdown('update')); // fix bootstrap
                return this.items
                    .concat(this.foundItems)
                    .filter(item => this.getItemLabel(item).toLowerCase().includes(this.searchString.toLowerCase()));
            }
            return this.items;
        },
        showCaption() {
            if (this.searchable && this.inputFocused) {
                if (this.editable && this.searchString == null) {
                    return this.getItemLabel(this.selected[0]);
                }
                return this.searchString;
            }
            if (this.selected[0] && !this.multiselect) {
                if (this.isShowFullLabel) return this.getItemEmailFullLabel(this.selected[0]);
                return this.getItemLabel(this.selected[0]);
            }
            return null; // html input placeholder will be displayed
        },
        isValidInput() {
            if (this.invalid) return false;
            return !this.editValidationFunc || this.searchString == null || this.editValidationFunc(this.searchString);
        },
    },
    mounted() {
        if (this.widthDependsOnContent) {
            this.updateInputWidth();
        }
    },
    methods: {
        focus() {
            this.inputFocused = true;
        },
        blur() {
            this.inputFocused = false;
            if (!this.isValidInput) {
                // clear string to display last valid selected value
                this.searchString = null;
            }
        },
        updateInputWidth() {
            if (!this.$refs.dropdown || !this.$refs.widthChecker) return;
            const defaultWidth = 88;
            const inputPaddings = 52;
            const fieldSize = this.$refs.widthChecker.clientWidth || defaultWidth;
            const widthValue = `calc(${fieldSize}px + ${inputPaddings}px)`;
            this.$refs.dropdown.style.width = widthValue;
            this.$refs.dropdown.style.minWidth = widthValue;
        },
        inputString(string, isChangeEvent) {
            this.searchString = string;
            if (this.editable && string && this.isValidInput) {
                if ((this.emitInputOnChangeOnly && isChangeEvent) || !this.emitInputOnChangeOnly) {
                    if (this.selectJustEmitted) {
                        this.selectJustEmitted = false;
                    } else {
                        this.$emit(Events.select, string);
                    }
                }
            }

            if (
                this.searchable &&
                !isChangeEvent &&
                this.searchCallback &&
                (string.length > 3 || !this.displayedItems.length)
            ) {
                // todo make rule configurable
                this.searchRunning = true;

                this.searchCallback(string).then(items => {
                    // just to prevent high memory usage
                    if (this.foundItems.length > 10000) {
                        this.foundItems = [];
                    }
                    this.foundItems = items;
                    this.searchRunning = false;
                });
            }
        },
        getItemLabel(item) {
            return this.displayProperty ? item[this.displayProperty] : item;
        },
        getItemSubLabel(item) {
            return item[this.displaySubLabelProperty] || undefined;
        },
        getItemEmailFullLabel(item) {
            const subLabel = this.getItemSubLabel(item) ? ` (${this.getItemSubLabel(item)})` : '';

            return `<${this.getItemLabel(item)}>${subLabel}`;
        },
        select(item) {
            if (this.selected.includes(item)) {
                if (this.multiselect) {
                    // deselect for multiselect
                    this.$emit(Events.deselect, item);
                } else if (this.emitAlreadySelected) {
                    this.$emit(Events.select, item);
                }
            } else {
                // select
                this.selectJustEmitted = true;
                this.$emit(Events.select, item);
            }
            if (this.widthDependsOnContent) {
                this.$nextTick().then(() => this.updateInputWidth());
            }
        },
        isSelected(item) {
            return this.selected.includes(item);
        },
        isShowSubLabel(item) {
            return this.displaySubLabelProperty && this.getItemSubLabel(item);
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/_typographyV2';
@import '~@/assets/scss/_palette';
@import '~@/assets/scss/_icons';
@import '~@/assets/scss/_animations';

$icon-path: '~@/assets/icons/';

.dropdown {
    min-width: 12.5rem;

    .width-checker {
        visibility: hidden;
        height: 0;
        position: absolute;
        text-transform: uppercase;
    }

    &.small > div {
        height: 2rem;

        .dropdown-arrow {
            min-width: 2rem;
            width: 2rem;
            margin-left: -2rem;
        }
    }

    &.white:not(.invalid) > div {
        .dropdown-input,
        .dropdown-arrow {
            border-color: $gray5;
            background-color: $white;
        }
    }

    & > div {
        height: 2.1875rem;

        &.show:first-child {
            .dropdown-input,
            .dropdown-arrow {
                border-color: $blue;
            }
        }
    }

    &.invalid > div {
        .dropdown-input,
        .dropdown-arrow {
            border-color: $red;
        }

        .dropdown-arrow {
            border-left-color: $white;
        }
    }

    &.button {
        & > div {
            .dropdown-input {
                background-color: $dirty-white;
                border-top-left-radius: 0.125rem;
                border-bottom-left-radius: 0.125rem;

                &::placeholder {
                    color: $gray60;
                    font-weight: bolder;
                }
            }

            .dropdown-arrow {
                background-color: $dirty-white;
                border-top-right-radius: 0.125rem;
                border-bottom-right-radius: 0.125rem;
            }

            &.show:first-child {
                .dropdown-input,
                .dropdown-arrow {
                    border-color: $gray5;
                }
            }
        }
    }

    &.isFilterOperation {
        .dropdown-menu {
            text-transform: uppercase;
            border-radius: 0.25rem !important;
            box-shadow: 0 0.25rem 0.5rem 0 $blue30;

            .dropdown-item {
                color: $gray-blue;

                &.selected {
                    background-image: none;
                }
            }
        }

        &.white:not(.invalid) > div .dropdown-input,
        &.white:not(.invalid) > div .dropdown-arrow {
            border: none;
            background: transparent;
            color: $gray-blue;
            text-transform: uppercase;
        }

        .dropdown-arrow:after {
            content: url($icon-path + $dropdown-arrow-gray-blue);
        }
    }

    .dropdown-input {
        max-width: 100%;
        padding: 0 2.5rem 0 0.75rem;
        font-size: 0.875rem;
        border-radius: 0;

        border: solid 1px $gray5;
        background: $white;
        cursor: pointer;

        &[disabled] {
            cursor: default;
        }

        &::placeholder {
            color: $gray30;
        }

        &.searchable:not([disabled]):not(.invalid) {
            cursor: text;

            &:focus,
            &:focus + .dropdown-arrow {
                border-color: $blue;
            }
        }
    }

    .dropdown-arrow {
        min-width: 2.1875rem;
        width: 2.1875rem;
        margin-left: -2.1875rem;

        border: 1px solid $gray5;
        border-left-width: 0;
        background: $gray5;
        pointer-events: none;

        &:after {
            height: 100%;
            line-height: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            content: url($icon-path + $dropdown-arrow);
        }
    }

    .dropdown-menu {
        width: 100%;
        min-width: 9rem;
        border: none;
        box-shadow: 0 0.0625rem 0.125rem 0 $gray30;

        max-height: 15rem;
        overflow-x: hidden;

        // scroll bar styling only works in webkit
        &::-webkit-scrollbar {
            width: 0.625rem;
            background-color: $dirty-white;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 0.3125rem;
            background-color: $gray30;
        }

        .dropdown-items-group {
            .dropdown-item-header {
                height: 2.4375rem;
                line-height: 2.4375rem;

                font-size: 0.75rem;
                text-transform: uppercase;
            }

            .dropdown-item {
                padding-left: 1.875rem;
            }
        }

        .dropdown-item {
            padding: 0 2rem 0 1rem;
            height: 2.5rem;
            line-height: 2.5rem;

            background: right 0.375rem center no-repeat;
            overflow: hidden;
            text-overflow: ellipsis;

            cursor: pointer;
            font-size: 0.875rem;
            color: $navy;

            &:hover {
                background-color: $gray5;
            }

            &:active {
                color: $black;
            }

            &.selected {
                background-image: url($icon-path + $check-mark);
            }

            .item-sub-label {
                color: $blue60;
                background-color: $blue5;
                padding: 0.125rem 0.875rem;
                border-radius: 0.25rem;
                font-weight: $bold-font-weight;
            }
        }

        .loading-more {
            color: $gray30;
            font-size: 0.875rem;

            .spinner:after {
                width: 1.125rem;
                height: 1.125rem;
                content: url($icon-path + $gray-spinner);
                line-height: 0;
                display: flex;
                animation: spin 1000ms infinite linear;
            }
        }
    }
}
</style>
