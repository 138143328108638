import { PC_CONDITION_TYPES } from '@/__new__/services/dno/charging/common/chargingSpecificationHelper';

const timeInSeconds = time => {
    let newTime = time;
    const hours = Math.trunc(time / 3600);
    newTime %= 3600;
    const min = Math.trunc(newTime / 60);
    newTime %= 60;
    newTime = `${hours}:${min}:${newTime}`;

    return newTime;
};

export default (id, time) => {
    time.start_offset = timeInSeconds(time.start_offset);
    time.end_offset = timeInSeconds(time.end_offset);

    return {
        type: PC_CONDITION_TYPES.TIME_IN_CALL,
        data: [
            {
                start_offset: time.start_offset,
                end_offset: time.end_offset,
                invalid: false,
            },
        ],
        uuid: id,
    };
};
