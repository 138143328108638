import {
    USER_MANAGER_HIERARCHY,
    getUserManagerEntityTypeById,
} from '@/__new__/features/customerCare/common/customerCareHelper';
import { getMultiLangFieldValueByLocale } from '@/common/entities/entityHelper';
import localeLibrary from '@/common/locale/localeLibrary';

type DocumentId = string;
type DocumentURL = string;

export type AgentNoteBe = {
    id: string;
    id_type: USER_MANAGER_HIERARCHY;
    crm_user_id: string;
    document_urls?: Record<DocumentId, DocumentURL>;
    epoch: number;
    note: Record<'en' | string, string>;
    tags: string[];
};

export default class AgentNote {
    targetId: string;
    targetType: ReturnType<typeof getUserManagerEntityTypeById> | string;
    creatorName: string;
    documentUrls: Record<string, string> | null;
    epoch: ReturnType<typeof localeLibrary.normalizeTimestamp>;
    noteText: ReturnType<typeof getMultiLangFieldValueByLocale>;
    tags: string[];

    constructor(data: Partial<AgentNote>) {
        this.targetId = data.targetId || '';
        this.targetType = data.targetType || '';
        this.creatorName = data.creatorName || '';
        this.documentUrls = data.documentUrls || null;
        this.epoch = data.epoch || '';
        this.noteText = data.noteText;
        this.tags = Array.isArray(data.tags) ? data.tags : [];
    }

    static remapNoteFromBe(entity: AgentNoteBe) {
        return {
            targetId: entity.id,
            targetType: getUserManagerEntityTypeById(entity.id_type),
            creatorName: entity.crm_user_id,
            documentUrls: entity.document_urls,
            epoch: localeLibrary.normalizeTimestamp(entity.epoch),
            noteText: getMultiLangFieldValueByLocale(entity.note),
            tags: entity.tags,
        };
    }
}
