<template>
    <div class="row-state-controls">
        <!-- row reverse flexbox here to make them right-aligned -->
        <IconButton
            v-for="action in formattedActions"
            :key="action"
            :label="formatInputLabel(action)"
            :icon="getIconForAction(action)"
            :href="entityActionToHrefFunction ? getHrefFromAction(action) : null"
            @iconClick="stateChangeAction(action)"
        />
    </div>
</template>

<script>
// helpers
import { EntityActions, entityActionList, StateActionsMapping } from '@/common/baseStatesHelper';
import { onlyFirstLetterUppercase } from '@/common/utils';
import { ICON_TYPES } from '@/common/iconHelper';
// components
import IconButton from '@/components/partials/IconButton.vue';

export default {
    name: 'CommonTemplateRowStateControls',
    components: {
        IconButton,
    },
    props: {
        entity: {
            type: Object,
            required: true,
        },
        allowedActionsExternal: {
            type: Array,
            default: () => [],
        },
        /**
         * Summary:
         * This property is used to determine links for our icon button,
         * so we can use browser supported navigation operations (eg: 'Open in New Tab')
         *
         * It's needed because the icons are not aware of links and merely emit events
         * which are propagated upwards.
         *
         * Details:
         * Map from string representation of an entity action (eg: 'EDIT')
         * to a function can be used to build a link for that entity.
         *
         * The function must accept the entity as a parameter and return an href string.
         * Simplified example:
         *      entityActionToHrefFunction = {
         *          'EDIT': (entity) => `/offer/${entity.id}/edit`,
         *          'READ_ONLY': (entity) => `/offer/${entity.id}/view`,
         *      }
         */
        entityActionToHrefFunction: {
            type: Object,
            required: false,
            default: () => ({}),
        },
    },

    data() {
        return {
            ICON_TYPES,
        };
    },

    computed: {
        formattedActions() {
            return entityActionList().filter(
                action => this.allowedActions() && this.allowedActions().includes(EntityActions[action]),
            );
        },
    },

    methods: {
        allowedActions() {
            if (this.allowedActionsExternal.length) {
                return this.allowedActionsExternal;
            }
            return StateActionsMapping[this.entity.state];
        },
        stateChangeAction(a) {
            switch (a) {
                case 'APPROVE':
                    return this.approveAction();
                case 'CLONE':
                    return this.cloneAction();
                case 'EDIT':
                    return this.editAction();
                case 'DELETE':
                    return this.determineDeleteAction();
                case 'READ_ONLY':
                    return this.readOnlyAction();
                default:
            }
            return null;
        },
        getIconForAction(action) {
            switch (action) {
                case 'APPROVE':
                    return ICON_TYPES.CHECK;
                case 'CLONE':
                    return ICON_TYPES.CLONE;
                case 'EDIT':
                    return ICON_TYPES.EDIT;
                case 'DELETE':
                    return ICON_TYPES.DELETE;
                case 'READ_ONLY':
                    return ICON_TYPES.INFO;
                default:
            }
            return null;
        },
        determineDeleteAction() {
            this.$emit('delete', this.entity.id);
        },
        approveAction() {
            this.$emit('approve', { id: this.entity.id, data: this.entity });
        },
        editAction() {
            const { id } = this.entity;
            this.$emit('edit', id);
        },
        cloneAction() {
            const { id } = this.entity;
            this.$emit('clone', id);
        },
        readOnlyAction() {
            const { id } = this.entity;
            this.$emit('readonly', id);
        },
        formatInputLabel(a) {
            if (a === 'READ_ONLY') {
                return this.$i18n.t('generic.view');
            }
            return onlyFirstLetterUppercase(a);
        },
        /**
         * Builds link for entity based on action.
         * If no function exists in `entityActionToHrefFunction` for `action`,
         * then this function returns `null`.
         * @param {*} action string representation of key in EntityActions
         */
        getHrefFromAction(action) {
            const func = this.entityActionToHrefFunction?.[action];
            if (!func || typeof func !== 'function') {
                return null;
            }
            try {
                return func(this.entity);
            } catch {
                return null;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/palette';
@import '~@/assets/scss/icons';

$icon-path: '~@/assets/icons/';

.row-state-controls {
    height: 40px;
    display: flex !important;
    padding-right: 0.5rem;
}
</style>
