
import Vue from 'vue';

// Components
import AppDialogV2 from '@/components/partials/AppDialogV2.vue';
import AppIcon from '@/components/partials/icon/AppIcon.vue';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import FileUploader from '@/components/partials/inputs/FileUploader.vue';

// Helpers
import { ICON_TYPES, ICON_COLORS } from '@/common/iconHelper';

// Http
import { replaceReport, replaceReportSignedUrl } from '@/__new__/services/dno/reports/http/reports';
import { uploadToSignedURL } from '@/http/fileUploader';

export default Vue.extend({
    name: 'ReportsReplaceDialog',
    components: {
        AppDialogV2,
        AppIcon,
        AppButton,
        FileUploader,
    },
    props: {
        visible: {
            type: Boolean,
            default: false,
        },
        report: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {
            BUTTON_TYPES,
            ICON_COLORS,
            ICON_TYPES,
            file: undefined as File | undefined,
            fileLoadingPercentage: 0 as number,
            fileIndex: 0 as number,
            fileUploadId: '' as string,
            isReplacing: false as boolean,
            isUploading: false as boolean,
        };
    },
    computed: {
        isVisible(): boolean {
            return this.visible;
        },
        isSubmitDisabled(): boolean {
            return !this.file || this.isUploading || this.isReplacing;
        },
    },
    methods: {
        closeModal() {
            this.file = undefined;
            this.fileLoadingPercentage = 0;
            this.$emit('close');
        },
        onFileChange(files: File[]) {
            [this.file] = files;
        },
        trackFileUploadPercentage() {
            const inter = setInterval(() => {
                this.fileLoadingPercentage = this.$Progress.get();

                if (this.isReplacing) {
                    this.fileLoadingPercentage = 100;
                    clearInterval(inter);
                }
            }, 500);
        },
        async uploadFile() {
            await this.$withProgressBar(
                async () => {
                    this.isUploading = true;
                    const { signedUrl, uploadId } = await replaceReportSignedUrl(this.report);
                    this.fileUploadId = uploadId;

                    const newFile = new File([this.file as File], this.fileUploadId);
                    const blob = new Blob([newFile], { type: 'binary' });

                    this.trackFileUploadPercentage();
                    await uploadToSignedURL(blob, signedUrl, this.fileIndex, undefined);
                    this.$showSuccessAlert({ message: this.$t('analytics.reportsPage.reportUploadSuccess') });
                },
                {
                    errorHandler: () => {
                        this.isUploading = false;
                        this.$alert(this.$t('analytics.reportsPage.somethingWentWrongWhileUploadingReq'));
                    },
                },
            );
        },
        async replaceFile() {
            await this.$withProgressBar(
                async () => {
                    // Exsits if report upload failed.
                    if (!this.isUploading) {
                        return;
                    }
                    this.isUploading = false;
                    this.isReplacing = true;
                    await replaceReport({ ...this.report, uploadId: this.fileUploadId });
                    this.$showSuccessAlert({ message: this.$t('analytics.reportsPage.reportReplaceSuccess') });
                    this.isReplacing = false;
                },
                {
                    errorHandler: () => {
                        this.isReplacing = false;
                        this.$alert(this.$t('analytics.reportsPage.somethingWentWrongWhileReplacingReport'));
                    },
                },
            );
        },
        async onSubmit() {
            await this.uploadFile();
            await this.replaceFile();
            this.closeModal();
        },
    },
});
