
import Vue from 'vue';

// Components
import CardListWrapper from '@/components/partials/cards/CardListWrapper.vue';
import AppIcon from '@/components/partials/icon/AppIcon.vue';
import AppMultiselectV3 from '@/components/partials/inputs/AppMultiselectV3.vue';
import AppInputV3 from '@/components/partials/inputs/AppInputV3.vue';
import Tags from '@/components/partials/inputs/Tags.vue';

// Http
import { getChargingRequestFields } from '@/__new__/services/dno/charging/http/conditionParameters';

// Validations
import { validationMixin } from 'vuelidate';
import { required, requiredIf } from 'vuelidate/lib/validators';

// Helpers
import {
    CHARGING_REQUEST_FIELDS,
    CustomCondition,
    createRequestFieldsData,
    CUSTOM_CONDITION_TYPE,
    CUSTOM_CONDITION_OPERATOR,
    CoditionOption,
    allowMultipleValues,
    CUSTOM_CONDITION_KEYS,
} from '@/__new__/services/dno/charging/common/customConditionHelper';
import {
    CHARGING_SPEC_TYPE,
    CONDITION_TYPES,
} from '@/__new__/services/dno/charging/common/chargingSpecificationHelper';
import permissionsService from '@/services/permissions/permissions.service';
import { ICON_COLORS, ICON_TYPES } from '@/common/iconHelper';
import { TranslateResult } from 'vue-i18n';
import CustomConditionModel from '@/__new__/services/dno/charging/models/chargingSpecificationConditions/CustomCondition';
import * as Utils from '@/common/utils';
import * as Sentry from '@sentry/vue';

export default Vue.extend({
    name: 'CustomCondition',
    components: {
        CardListWrapper,
        AppIcon,
        AppMultiselectV3,
        AppInputV3,
        Tags,
    },
    mixins: [validationMixin],
    props: {
        value: {
            type: Object,
            default: () => undefined,
        },
    },
    data() {
        return {
            ICON_COLORS,
            ICON_TYPES,
            CUSTOM_CONDITION_TYPE,

            isCsApiTypeEnabled: permissionsService.chargingChargingSpecificationsApiTypeEnabled(),
            serviceType: {},
            requestFields: [] as CustomCondition[],
            customConditionSelected: null as null | CustomCondition,
            booleanOptions: [
                { id: true, label: this.$t('generic.true').toUpperCase() },
                { id: false, label: this.$t('generic.false').toUpperCase() },
            ] as { id: boolean; label: TranslateResult }[],
            currnetVal: null as string | number | null,
            dontUseOnEdit: true,
        };
    },
    validations: {
        serviceType: {
            key: {
                required,
            },
        },
        customConditionSelected: {
            id: {
                // eslint-disable-next-line func-names
                required: requiredIf(function () {
                    return !this.hideOnEdit;
                }),
            },
            selectedOperator: {
                id: {
                    // eslint-disable-next-line func-names
                    required: requiredIf(function () {
                        return !this.hideOnEdit;
                    }),
                },
            },
            selectedAllowedValues: {
                // eslint-disable-next-line func-names
                required: requiredIf(function () {
                    return (
                        this.customConditionSelected.allowMultipleValues &&
                        this.customConditionSelected?.allowedValues?.length
                    );
                }),
            },
            selectedAllowedValue: {
                id: {
                    // eslint-disable-next-line func-names
                    required: requiredIf(function () {
                        return (
                            !this.customConditionSelected.allowMultipleValues &&
                            this.customConditionSelected?.allowedValues?.length
                        );
                    }),
                },
            },
            values: {
                // eslint-disable-next-line func-names
                required: requiredIf(function () {
                    return (
                        this.customConditionSelected.allowMultipleValues &&
                        !this.customConditionSelected?.allowedValues?.length &&
                        !this.isSelectedConditionBooleanType
                    );
                }),
            },
            value: {
                // eslint-disable-next-line func-names
                required: requiredIf(function () {
                    return (
                        (!this.customConditionSelected.allowMultipleValues &&
                            !this.customConditionSelected?.allowedValues?.length) ||
                        this.isSelectedConditionBooleanType
                    );
                }),
            },
        },
    },
    computed: {
        serviceTypeOptions() {
            const options = [];
            options.push(
                CHARGING_SPEC_TYPE.data,
                CHARGING_SPEC_TYPE.voice,
                CHARGING_SPEC_TYPE.sms,
                CHARGING_SPEC_TYPE.mms,
            );

            if (this.isCsApiTypeEnabled) {
                options.push(CHARGING_SPEC_TYPE.api);
            }

            return options;
        },
        isSelectedConditionBooleanType(): boolean {
            return this.customConditionSelected?.type === CUSTOM_CONDITION_TYPE.BOOLEAN;
        },
    },
    created() {
        if (this.$route.params?.id) {
            this.$withLoadingSpinner(
                async () => {
                    this.dontUseOnEdit = false;

                    const {
                        field,
                        operation: selectedOperation,
                        service_type: serviceType,
                        values = undefined,
                        value = undefined,
                    } = this.value.data;

                    this.serviceType = this.serviceTypeOptions.find(({ id }) => id === serviceType);

                    if (!this.serviceType?.id) {
                        this.$alert(this.$i18n.t('charging.conditionParameters.errorGettingCustomCondition'));
                        return;
                    }

                    const { data } = await getChargingRequestFields(this.serviceType.id);
                    this.requestFields = createRequestFieldsData(data.charging_request_fields?.[this.serviceType.id]);
                    const customCondition = this.requestFields.find(el => el.id === field);

                    if (!customCondition) {
                        this.$alert(this.$i18n.t('charging.conditionParameters.errorGettingCustomCondition'));
                        return;
                    }

                    this.customConditionSelected = customCondition;
                    this.customConditionSelected.selectedOperator = this.customConditionSelected.operator.find(
                        el => el.id === selectedOperation,
                    );

                    this.customConditionSelected.allowMultipleValues = allowMultipleValues(selectedOperation);
                    const { allowedValues } = this.customConditionSelected;

                    if (this.isSelectedConditionBooleanType) {
                        this.customConditionSelected.value = this.booleanOptions.find(({ id }) => id === value);
                        return;
                    }

                    if (this.customConditionSelected.allowMultipleValues) {
                        this.customConditionSelected[
                            allowedValues ? CUSTOM_CONDITION_KEYS.SELECTED_ALLOWED_VALUES : CUSTOM_CONDITION_KEYS.VALUES
                        ] = allowedValues ? values.map(el => allowedValues.find(({ id }) => id === el)) : values;
                    } else {
                        this.customConditionSelected[
                            allowedValues ? CUSTOM_CONDITION_KEYS.SELECTED_ALLOWED_VALUE : CUSTOM_CONDITION_KEYS.VALUE
                        ] = allowedValues ? allowedValues.find(({ id }) => id === value) : value;
                    }
                },
                {
                    errorHandler: e => {
                        this.$alert(this.$i18n.t('charging.conditionParameters.errorGettingCustomCondition'));
                        Sentry.captureException(e);
                    },
                },
            );
        }
    },
    methods: {
        onSelectedServiceType(): void {
            this.requestFields = [];
            this.customConditionSelected = null;
            this.getCustomConditionsData();
        },
        getCustomConditionsData(): void {
            this.$withLoadingSpinner(
                async () => {
                    const serviceType: CHARGING_REQUEST_FIELDS = this.serviceType.id;
                    const { data } = await getChargingRequestFields(serviceType);
                    this.requestFields = createRequestFieldsData(data.charging_request_fields[serviceType]);
                },
                {
                    errorHandler: e => {
                        this.$alert(this.$i18n.t('alertMessage.failedToLoadNecessaryData'));
                        Sentry.captureException(e);
                    },
                },
            );
        },
        emitData(): void {
            this.$v.$touch();

            let data = { ...this.customConditionSelected };
            if (this.isSelectedConditionBooleanType) {
                data = {
                    ...data,
                    value: this.customConditionSelected?.value?.id,
                };
            }

            const codition = new CustomConditionModel({
                data,
                uuid: this.$route.params.id || Utils.uuidV4(),
                type: CONDITION_TYPES.CUSTOM_CONDITION,
                dataValid: !this.$v.$invalid,
                dataEmpty: false,
                serviceType: this.serviceType.id,
            });

            if (this.$v.$invalid) {
                return;
            }

            this.$emit('input', codition);
        },
        onSelectedOperatorChange(val: CoditionOption<CUSTOM_CONDITION_OPERATOR>): void {
            this.$v.$reset();
            if (this.customConditionSelected) {
                this.customConditionSelected.allowMultipleValues = allowMultipleValues(val.id);
                this.customConditionSelected.selectedAllowedValues = [];
                this.customConditionSelected.selectedAllowedValue = null;
                this.customConditionSelected.values = [];
                this.customConditionSelected.value = null;
            }
        },
        updateValue(): void {
            if (this.customConditionSelected && this.currnetVal) {
                this.customConditionSelected.values.push(this.currnetVal);
                this.currnetVal = null;
            }
        },
        onCustomConditionSelected(): void {
            this.$v.$reset();
            if (this.customConditionSelected) {
                this.customConditionSelected.selectedOperator = this.customConditionSelected?.operator[0];
                this.customConditionSelected.allowMultipleValues = allowMultipleValues(
                    this.customConditionSelected.selectedOperator?.id,
                );
            }
        },
    },
});
