
import Vue from 'vue';

// components
import AppInputV3 from '@/components/partials/inputs/AppInputV3.vue';
import AppMultiselectV3 from '@/components/partials/inputs/AppMultiselectV3.vue';
import DateTimePicker from '@/components/partials/inputs/DateTimePicker.vue';

// helpers
import {
    DAY,
    TIME_FREQUENCY,
    DAY_TO_I18N_LABEL,
    TIME_FREQUENCY_TO_I18N_LABEL,
    formatTimeToFrequencyInterval,
} from '@/common/timeFrequencyHelper';

// models
import CronExpression from '@/common/CronExpression';

interface SelectOption {
    id: any;
    label: string;
}

interface TimeFrequency extends SelectOption {
    id: TIME_FREQUENCY;
}

interface MonthDayOption extends SelectOption {
    id: number;
}

interface WeekDayOption extends SelectOption {
    id: DAY;
}

export default Vue.extend({
    name: 'CronExpressionInput',
    components: {
        AppInputV3,
        AppMultiselectV3,
        DateTimePicker,
    },
    props: {
        label: {
            type: String,
            default: '',
        },
        value: {
            type: String,
            default: new CronExpression().value,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        errorMessage: {
            type: String,
            default: '',
        },
        invalid: {
            type: Boolean,
            default: false,
        },
        error: {
            type: Boolean,
            default: false,
        },
        hasError: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            TIME_FREQUENCY,
            mode: undefined as TimeFrequency | undefined,
            day: null as WeekDayOption[] | null,
            frequency: 1 as number,
            monthDay: null as MonthDayOption[] | null,
            time: new Date(new Date().setHours(24, 0, 0)) as Date,
            cron: new CronExpression().value as string,
            cronValue: new CronExpression() as CronExpression,
        };
    },
    computed: {
        currentMode(): TIME_FREQUENCY {
            return this.mode?.id || CronExpression.getTimeFreqFromCronValue(this.inputValue);
        },
        inputValue(): string {
            return this.cronValue.value;
        },
        modeOptions(): TimeFrequency[] {
            return Object.values(TIME_FREQUENCY).map(id => ({
                id,
                label: this.$t(TIME_FREQUENCY_TO_I18N_LABEL.get(id)),
            }));
        },
        dayOptions(): WeekDayOption[] {
            return Object.values(DAY).map(id => ({
                id,
                label: this.$t(DAY_TO_I18N_LABEL.get(id)),
            }));
        },
        monthDayOptions(): MonthDayOption[] {
            return Array.from(Array(28), (_: any, i: number) => ({
                id: i + 1,
                label: `${i + 1}.`,
            }));
        },
        isTimeEnabled(): boolean {
            return ![TIME_FREQUENCY.CRON, TIME_FREQUENCY.MINUTES].includes(this.currentMode);
        },
    },
    created() {
        if (!this.value) {
            // Emit value so it can be validated on the parent.
            this.$emit('input', this.inputValue);
        }

        const value = this.value || this.inputValue;
        const mode = CronExpression.getTimeFreqFromCronValue(value);

        this.mode = this.modeOptions.find(({ id }) => id === mode) as TimeFrequency;
        this.mapCronValue(value);
    },
    methods: {
        mapCronValue(cron: string | undefined) {
            if (!cron) {
                return;
            }
            const { minute, hour, monthDay, weekDay } = CronExpression.getArgumentsFromString(cron);

            this.cronValue = new CronExpression({ minute, hour, monthDay, weekDay });
            this.cron = cron;

            switch (this.currentMode) {
                case TIME_FREQUENCY.MINUTES:
                    this.frequency = formatTimeToFrequencyInterval(hour, minute);
                    break;

                case TIME_FREQUENCY.WEEKLY:
                    this.day = weekDay
                        .split(',')
                        .map(day => this.dayOptions.find(({ id }) => id === day))
                        .filter(Boolean) as WeekDayOption[];

                    break;

                case TIME_FREQUENCY.MONTHLY:
                    this.monthDay = monthDay
                        .split(',')
                        .map(day => this.monthDayOptions.find(({ id }) => String(id) === day))
                        .filter(Boolean) as MonthDayOption[];
                    break;

                default:
                    break;
            }

            if ([Number(hour), Number(minute)].every(Number.isInteger)) {
                this.time = new Date(new Date().setHours(+hour, +minute));
            }
        },
        formatTimeInputValue(time: Date) {
            const hour = String(time.getHours());
            const minute = String(time.getMinutes());

            return { hour, minute };
        },
        onCronInput(cron: string) {
            const data = cron.split(' ');
            const isValid = data.length === 5 && data.every(Boolean);

            this.$emit('input', isValid ? cron : '');
        },
        onFrequencyInput(frequency: number) {
            this.cronValue.setMinuteFrequency(frequency);
            this.$emit('input', this.inputValue);
        },
        onModeSelect(mode: TimeFrequency) {
            const { hour, minute } = this.formatTimeInputValue(this.time);
            let isValid = true; // Used for triggering validation on parrent

            switch (mode.id) {
                case TIME_FREQUENCY.MINUTES:
                    this.cronValue.setMinuteFrequency(this.frequency);
                    break;

                case TIME_FREQUENCY.DAILY:
                    this.cronValue.hour = hour;
                    this.cronValue.minute = minute;
                    this.cronValue.monthDay = '*';
                    this.cronValue.weekDay = '*';
                    break;

                case TIME_FREQUENCY.MONTHLY:
                    isValid = !!this.monthDay;
                    this.cronValue.hour = hour;
                    this.cronValue.minute = minute;
                    this.cronValue.monthDay = this.monthDay ? this.monthDay.map(({ id }) => id).join(',') : '*';
                    this.cronValue.weekDay = '*';
                    break;

                case TIME_FREQUENCY.WEEKLY:
                    isValid = !!this.day;
                    this.cronValue.hour = hour;
                    this.cronValue.minute = minute;
                    this.cronValue.monthDay = '*';
                    this.cronValue.weekDay = this.day ? this.day.map(({ id }) => id).join(',') : '*';
                    break;

                case TIME_FREQUENCY.CRON:
                    this.cronValue = CronExpression.createFromString(this.cron);
                    break;

                default:
                    break;
            }

            // Emit falsy value to trigger validation on parent.
            this.$emit('input', isValid ? this.inputValue : '');
        },
        onTimeInput(time: Date) {
            const { hour, minute } = this.formatTimeInputValue(time);

            this.cronValue.hour = hour;
            this.cronValue.minute = minute;

            this.$emit('input', this.inputValue);
        },
        onMonthSelect(days: MonthDayOption[]) {
            this.cronValue.monthDay = days.map(({ id }) => id).join(',');

            this.$emit('input', this.inputValue);
        },
        onWeekSelect(weekDays: WeekDayOption[]) {
            this.cronValue.weekDay = weekDays.map(({ id }) => id).join(',');

            this.$emit('input', this.inputValue);
        },
    },
});
