<template>
    <ListPageLayout
        :pageTitle="pageTitle"
        :addButtonLabel="addButtonLabel"
        :editRouteName="editRouteName"
        :entityType="entityType"
        :entities="entities"
        :entitiesApiResponse="entitiesApiResponse"
        :entityKeyDepth="2"
        :listViewColumns="listViewColumnsFormatted"
        :excludeBaseColumns="excludeBaseColumns"
        :overviewContentBuilder="overviewContentBuilder"
        :multiselectEnabled="isMultiselectEnabled"
        :multiselectIdColumn="'id'"
        :multiselectActions="bulkEditActions"
        :searchKeys="['id', 'name', 'description']"
        :userGuideArticleId="userGuideArticleId"
        :writePermission="writePermission"
        @multiselectActionClick="onMultiselectActionClick"
    >
        <template #statusIndicator="{ entity }">
            <EntityStatusIndicator :status="entity.state" />
        </template>

        <template #versionIndicator="{ entity }">
            <EntityStatusIndicator :status="entity.entityVersion" />
        </template>

        <template #operations="{ entity }">
            <slot
                name="operations"
                :entity="entity"
            />
        </template>

        <template #modals="{ selectedEntity }">
            <slot
                name="modals"
                :selectedEntity="selectedEntity"
                :entities="entities"
            />
        </template>
        <template #overviewButtons="{ selectedEntity }">
            <slot
                name="overviewButtons"
                :selectedEntity="selectedEntity"
            />
        </template>
    </ListPageLayout>
</template>

<script>
// Vuex
import { mapGetters, mapMutations } from 'vuex';
import { Getters, Mutations } from '@/store/mutation-types';

// components
import EntityStatusIndicator from '@/components/partials/EntityStatusIndicator.vue';
import ListPageLayout from '@/__new__/features/pc/ListPageLayout.vue';
import EntityFetcherMixin from '@/__new__/features/pc/EntityFetcherMixin.vue';
import ENTITY_TYPES from '@/common/entities/entityTypes';
import cloneDeep from 'lodash/cloneDeep';

// helpers
import permissionsService, { isUserAllowed } from '@/services/permissions/permissions.service';
import { PLURALIZATION } from '@/common/locale/labelSingularOrPlural';
import RouteNames from '@/router/routeNames';
import * as entityOverviewUtils from '@/__new__/features/pc/common/entityOverviewUtils';
import tableColumnType from '@/common/filterTable';
import { ICON_TYPES } from '@/common/iconHelper';

export default {
    name: 'EntityListPageLayout',
    components: {
        ListPageLayout,
        EntityStatusIndicator,
    },
    mixins: [EntityFetcherMixin],
    props: {
        entityType: {
            type: String,
            required: true,
        },
        listViewColumns: {
            type: Array,
            default: () => [],
        },
        overviewContentBuilder: {
            type: Function,
            default: entityOverviewUtils.defaultOverviewContentBuilder,
        },
        overrideEditRouteName: {
            type: String,
            default: null,
        },
        excludeBaseColumns: {
            type: Array,
            default: null,
        },
        multiselectIdColumn: {
            type: String,
            default: null,
        },
        multiselectActions: {
            type: Array,
            default: () => [],
        },
        userGuideArticleId: {
            type: Number,
            default: null,
        },
        writePermission: {
            type: String,
            required: false,
            default: null,
        },
    },
    data() {
        return {
            addButtonLabel: this.$i18n.t('productCatalog.newEntity', { entityType: this.entityType }),
            editRouteName: this.overrideEditRouteName ? this.overrideEditRouteName : RouteNames.ENTITY_EDITOR,
            bulkEditActions: [],
            bulkEditActionData: {
                label: this.$i18n.t('generic.bulkEdit'),
                buttonIcon: ICON_TYPES.EDIT,
                route: RouteNames.BULK_EDIT_ENTITIES,
                refName: 'bulk-edit-button',
                selectedMutationName: Mutations.SET_BULK_ENTITIES,
            },
        };
    },
    computed: {
        ...mapGetters('productcatalog', [
            Getters.PC_GET_ENTITIES_BY_TYPE_NOT_DELETED,
            Getters.PC_GET_TEMPLATE_BY_ID,
            Getters.PC_GET_ENTITIES_API_RESPONSE_BY_TYPE,
        ]),
        listViewColumnsFormatted() {
            return this.listViewColumns.map(col => {
                const colFormatted = { ...col };
                if (col.filterType === tableColumnType.TEXT_LIMITED_OPTIONS) {
                    colFormatted.limitedOptions = [
                        ...new Set(
                            this.entities.reduce((acc, entity) => {
                                if (entity[col.field] !== null && entity[col.field] !== undefined) {
                                    acc.push(entity[col.field]);
                                }
                                return acc;
                            }, []),
                        ),
                    ];
                }
                return colFormatted;
            });
        },
        entities() {
            const entityObject = Object.values(this[Getters.PC_GET_ENTITIES_BY_TYPE_NOT_DELETED](this.entityType));
            const formatterColumns = this.listViewColumns.filter(({ additionalFormatter }) => additionalFormatter);

            const returnEntityObject = cloneDeep(entityObject);
            return returnEntityObject.map(entity => {
                const catList = entity.categories?.map(item => {
                    const catName = this.allCategories.find(x => x.id === item);
                    if (catName) {
                        return catName.name;
                    }

                    return item;
                });
                entity.categoriesDisplay = catList;
                const { name, externalId } = this[Getters.PC_GET_TEMPLATE_BY_ID](entity.templateId);
                entity.templateName = name;
                entity.templateExternalId = externalId;

                formatterColumns.forEach(col => {
                    entity[col.field] = col.additionalFormatter(entity);
                });
                return entity;
            });
        },
        entitiesApiResponse() {
            return this[Getters.PC_GET_ENTITIES_API_RESPONSE_BY_TYPE](this.entityType);
        },
        pageTitle() {
            return this.$i18n.tc(`productCatalog.entities.${this.entityType.toLowerCase()}`, PLURALIZATION.PLURAL);
        },
        allCategories() {
            return this[Getters.PC_GET_ENTITIES_BY_TYPE_NOT_DELETED](ENTITY_TYPES.CATEGORY);
        },
        isMultiselectEnabled() {
            return permissionsService.isUserLotusFlareAdmin();
        },
    },
    mounted() {
        this.$withLoadingSpinner(() => this.fetchEntities(this.entityType));
    },
    created() {
        if (isUserAllowed(this.writePermission)) {
            this.bulkEditActions = [this.bulkEditActionData];
        }
    },
    methods: {
        ...mapMutations('productcatalog', [Mutations.PC_SET_BULK_ENTITY_IDS]),
        onMultiselectActionClick(data) {
            if (data.action.label === this.$i18n.t('generic.bulkEdit')) {
                this[Mutations.PC_SET_BULK_ENTITY_IDS]({
                    entityType: this.entityType,
                    entityIds: data.selectedRows,
                });

                this.$router.push({
                    name: data.action.route,
                    params: {
                        entityType: this.entityType,
                        companyId: this.$route.params.companyId,
                    },
                });
            }
        },
    },
};
</script>
