import http, { type DnoResponseBase } from '@/http/index';
import type { AxiosPromise } from 'axios';
import type {
    DataflowTemplatesApiResponse,
    DataflowTemplateUiModelApiResponse,
    DataflowValidationApiResponse,
    DataflowExportResponse,
    GetSharedLocationsResponse,
} from '@/__new__/features/dataflows/common/DataflowInterfaces';
import type { GetDataflowsResponse } from '@/__new__/services/dno/dataflows/models/Dataflow';

export type DataflowRequestParams = {
    blueprintId: string;
    dataflowId: string;
    data: {
        name: string;
        ui_input: Record<string, string>;
    };
};

/**
 * Get all dataflow templates
 *
 * @returns {AxiosPromise}
 */
export function getDataflowTemplates(): AxiosPromise<DataflowTemplatesApiResponse> {
    return http({
        method: 'GET',
        url: '/v3dataflow/dataflowtemplates',
    });
}

/**
 * Get dataflow template ui model
 *
 * @returns {AxiosPromise}
 */
export function getDataflowTemplateUiModel(blueprintId: string): AxiosPromise<DataflowTemplateUiModelApiResponse> {
    return http({
        method: 'GET',
        url: '/v3dataflow/dataflowtemplateuimodel',
        params: {
            blueprint_id: blueprintId,
        },
    });
}

export function getSharedLocations(): AxiosPromise<GetSharedLocationsResponse> {
    return http('/v3dataflow/getsharedlocations');
}

/**
 * Validate dataflow
 *
 * @returns {AxiosPromise}
 */
export function dataflowValidation({
    blueprintId,
    dataflowId,
    data,
}: DataflowRequestParams): AxiosPromise<DataflowValidationApiResponse> {
    return http({
        method: 'POST',
        url: '/v3dataflow/validationdataflowtemplate',
        params: {
            blueprint_id: blueprintId,
            dataflow_id: dataflowId,
        },
        data,
    });
}

/**
 * Create dataflow
 *
 * @returns {AxiosPromise}
 */
export function createDataflow({
    blueprintId,
    dataflowId,
    data,
}: DataflowRequestParams): AxiosPromise<DnoResponseBase> {
    return http({
        method: 'POST',
        url: '/v3dataflow/createdataflowtemplate',
        params: {
            blueprint_id: blueprintId,
            dataflow_id: dataflowId,
        },
        data,
    });
}

export function exportDataflow({
    blueprintId,
    dataflowId,
    data,
}: DataflowRequestParams): AxiosPromise<DataflowExportResponse> {
    return http({
        method: 'POST',
        url: '/v3dataflow/export',
        params: {
            blueprint_id: blueprintId,
            dataflow_id: dataflowId,
        },
        data,
    });
}

export async function getDataflows(force?: boolean): AxiosPromise<GetDataflowsResponse> {
    return http({
        method: 'GET',
        url: '/v3dataflow/getdataflows',
        params: {
            force_update_cache: force,
        },
    });
}

export default {
    getDataflowTemplates,
    getDataflowTemplateUiModel,
    getSharedLocations,
    dataflowValidation,
    createDataflow,
    exportDataflow,
};
