
// components
import AppDialogV2 from '@/components/partials/AppDialogV2.vue';
// helpers
import { ICON_COLORS, ICON_TYPES } from '@/common/iconHelper';
import { copyToClipboard } from '@/common/utils';
import CohortExpressionOverviewItem from '@/components/partials/CohortExpressionOverviewItem.vue';
import { EntityType, EntityTypeRouteName } from '@/components/entityTracker/entityTracker';
import { PropType } from 'vue';

type CohortExpressionOverviewGroupRow = {
    id: string;
    name: string;
};
export type CohortExpressionOverviewGroup = {
    title: string;
    subtitle: string;
    rowsCombinator: string;
    rows: CohortExpressionOverviewGroupRow[];
};

export default {
    name: 'CohortExpressionOverview',
    components: {
        CohortExpressionOverviewItem,
        AppDialogV2,
    },
    props: {
        title: {
            type: String,
            default: '',
        },
        popupTitle: {
            type: String,
            default: '',
        },
        groups: {
            type: Array as PropType<CohortExpressionOverviewGroup[]>,
            default: () => [],
            required: true,
        },
        maxItemRows: {
            type: Number,
            default: 5,
        },
    },
    data() {
        return {
            copyToClipboard,
            ICON_TYPES,
            ICON_COLORS,
            isPopupVisible: false,
        };
    },
    computed: {
        shouldShowFullGroupsData(): boolean {
            return !this.groups.some((group: CohortExpressionOverviewGroup) => group.rows?.length > this.maxItemRows);
        },
    },
    methods: {
        navigateToSegment(id): void {
            this.$router.push({
                name: EntityTypeRouteName[EntityType.SegmentConfig],
                params: { id, companyId: this.$route.params.companyId },
            });
        },
    },
};
