<template>
    <div class="h-100">
        <AbstractListPageWrapper
            :pageTitle="$i18n.t('vouchers.vouchers')"
            :isOverviewEnabled="isOverviewEnabled"
            :entitiesCount="sortFilterVoucherSets.length"
            @searchQueryChanged="setSearchQuery"
        >
            <template #button>
                <ResponseModalButton
                    :response="voucherSetsApiResponse"
                    :title="$i18n.t('vouchers.vouchers')"
                />
            </template>

            <template #filterTable>
                <FilterTable
                    :columns="tableColumnsData"
                    @filterAdded="onFilterAdded"
                />
            </template>

            <template #headerButtons>
                <AppButton
                    v-if="permissionsService.voucherEnabled()"
                    :buttonType="BUTTON_TYPES.PRIMARY"
                    :iconType="ICON_TYPES.PLUS"
                    :label="$i18n.t('vouchers.newVoucher')"
                    :disabled="!writeEnabled"
                    @click="onCreate"
                />
            </template>

            <template #allFilters>
                <TableFiltersTags
                    :filterTableMixin="filterTableMixin"
                    class="my-3 ml-2"
                    @removeFilter="removeFilter"
                    @removeAllFilters="removeAllFilters"
                />
            </template>

            <template #table>
                <AppTable
                    :entities="sortFilterVoucherSets"
                    :selectedEntityId="selectedEntityId"
                    :innerSearchQuery="searchQueryForTable"
                    :isSearchEnabled="true"
                    :columnsData="tableColumnsData"
                    :isDataLoading="isDataLoading"
                    :entityType="ENTITY_TYPES.VOUCHER_SET"
                    :enableRowStateControls="writeEnabled"
                    tableKey="vouchers/voucherSets"
                    data-testid="app-table"
                    @approve="onApprove"
                    @selectEntity="onSelect"
                    @edit="goToEditPage"
                    @delete="confirmVoucherDeletion"
                    @clone="id => goToEditPage(id, true)"
                    @readonly="id => goToViewPage(id)"
                >
                    <template #state="{ entity }">
                        <EntityStatusIndicator :status="entity.state" />
                    </template>
                    <template #entityVersion="{ entity }">
                        <EntityStatusIndicator :status="entity.entityVersion" />
                    </template>
                    <template #inventoryState="{ entity }">
                        <EntityStatusIndicator
                            :status="entity.inventoryState"
                            :stateMap="voucherSetInventoryStateMap"
                            :stateMapColor="inventoryStateIndicatorMap"
                        />
                    </template>
                </AppTable>
            </template>

            <template #overview>
                <MutationDialog
                    v-model="isMutationModalVisible"
                    :entities="upperEntities[mutationDialogDataType]"
                    @input="clearMutationDialogDataType"
                />
                <EntityOverview
                    :entityType="ENTITY_TYPES.VOUCHER_SET"
                    :entity="selectedEntity"
                    @closeOverview="isOverviewEnabled = false"
                >
                    <template #section-1-header>
                        <div>
                            <OverviewHeader
                                v-if="selectedEntityId"
                                :entityName="entityName(selectedEntity.data.name)"
                                :entityType="ENTITY_TYPES.VOUCHER_SET"
                                :isNewDesignSelected="true"
                            />
                            <LanguageSwitcher v-model="selectedLanguage" />
                        </div>
                    </template>

                    <template #section-1-content>
                        <div>
                            <OverviewList
                                :lists="detailsSections"
                                @showDialog="showMutationDialog"
                            />
                            <template v-if="voucherSelected">
                                <!-- EXPIRATION VOUCHER  -->
                                <div
                                    v-if="hasExpiration()"
                                    class="card-container"
                                >
                                    <div class="card-title">
                                        {{ $i18n.t('generic.expiration') }}
                                    </div>
                                    <div class="card-list">
                                        <div class="card-content">
                                            {{ $i18n.t('vouchers.voucherAvailable') }}
                                            {{ formatAvailability() }}
                                        </div>
                                    </div>
                                </div>

                                <!-- TRANSFER VOUCHER  -->
                                <div class="card-container">
                                    <div class="card-title">
                                        {{ $i18n.t('vouchers.voucherTransfer') }}
                                    </div>
                                    <div class="card-list">
                                        <div class="card-content">
                                            {{ $i18n.t('vouchers.voucherIs') }}
                                            {{ isTransferable() }}
                                            {{ $i18n.t('vouchers.voucherTransferable') }}
                                        </div>
                                    </div>
                                </div>

                                <!-- REDEEMING RULE VOUCHER  -->
                                <div
                                    v-if="hasRedeemingRule()"
                                    class="card-container"
                                >
                                    <div class="card-title">
                                        {{ $i18n.t('vouchers.voucherRedeemingRule') }}
                                    </div>
                                    <div class="card-list">
                                        <div class="card-content">
                                            {{ getRedeemingRule() }}
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </div>
                    </template>

                    <template #section-2-header>
                        <div>
                            <OverviewHeader
                                v-if="selectedEntityId"
                                :entityName="entityName(selectedEntity.data.name)"
                                :entityType="ENTITY_TYPES.VOUCHER_SET"
                            />
                        </div>
                    </template>
                </EntityOverview>
            </template>
        </AbstractListPageWrapper>
    </div>
</template>

<script>
// Vuex
import { mapActions, mapGetters } from 'vuex';
import Actions, { Getters, State } from '@/store/mutation-types';

// Generic Vue Components
import AppTable from '@/components/partials/AppTable.vue';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import { ICON_TYPES } from '@/common/iconHelper';
import AbstractListPageWrapper from '@/components/layout/AbstractListPageWrapper.vue';
import EntityOverview from '@/components/partials/entityOverview/EntityOverview.vue';
import OverviewHeader from '@/components/partials/entityOverview/OverviewHeader.vue';
import OverviewList from '@/components/partials/entityOverview/OverviewList.vue';
import MutationDialog from '@/components/partials/MutationDialog.vue';
import EntityStatusIndicator from '@/components/partials/EntityStatusIndicator.vue';
import FilterTable from '@/components/partials/FilterTable.vue';
import FilterTableMixin from '@/components/partials/FilterTableMixin.vue';
import LanguageSwitcher from '@/components/partials/inputs/LanguageSwitcher.vue';
import TableFiltersTags from '@/components/filters/TableFiltersTags.vue';
import ResponseModalButton from '@/components/partials/ResponseModalButton.vue';
import { updatestateVoucherSet, deleteVoucherSetDraft, updateVoucherSet } from '@/modules/rewards/http/vouchers';
import { EntityStateMapping } from '@/common/baseStatesHelper';
import Button from '@/common/button/Button';
import { ALERT_TYPES } from '@/common/alerts/Alert';

// Helpers
import RouteNames from '@/router/routeNames';
import ENTITY_TYPES from '@/common/entities/entityTypes';
import { onlyFirstLetterUppercase } from '@/common/utils';
import { entityStateReverseMap, StateActionsMapping } from '@/modules/rewards/common/entityStateMapper';
import {
    getMultiLangFieldValueByLocale,
    getProperlyFormattedMultilangFieldValue,
    getEntityConsumers,
    getMutationDialogTitleKey,
    entityTypeToEntityLabel,
} from '@/common/entities/entityHelper';
import {
    voucherSetInventoryStateMap,
    inventoryStateIndicatorMap,
    INVENTORY_STATE_KEYS,
    CODES_RELEASE_OPTION,
} from '@/modules/rewards/common/voucherSetsHelper';
import upperFirst from 'lodash/upperFirst';
import toUpper from 'lodash/toUpper';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import tableColumnType from '@/common/filterTable';
import permissionsService, { isUserAllowed } from '@/services/permissions/permissions.service';
import { STATUS_CODES } from '@/common/commonHelper';
import * as Sentry from '@sentry/vue';

export default {
    name: 'VoucherSetsDashboard',

    components: {
        AbstractListPageWrapper,
        AppTable,
        AppButton,
        EntityOverview,
        OverviewHeader,
        OverviewList,
        MutationDialog,
        EntityStatusIndicator,
        FilterTable,
        LanguageSwitcher,
        TableFiltersTags,
        ResponseModalButton,
    },

    mixins: [FilterTableMixin],

    data() {
        return {
            columns: [],
            selectedEntityId: null,
            selectedEntity: null,
            searchQueryForTable: '',
            isDataLoading: false,
            isMutationModalVisible: false,
            mutationDialogDataType: '',
            selectedLanguage: '',
            permissionsService,
            ICON_TYPES,
            BUTTON_TYPES,
            ENTITY_TYPES,
            isOverviewEnabled: false,
            voucherSetInventoryStateMap,
            inventoryStateIndicatorMap,
            isRewardsDraftEnabled: permissionsService.isRewardsDraftEnabled(),
            deletingEntityVersion: null,
        };
    },

    computed: {
        ...mapGetters('rewards', [
            Getters.GET_NOT_DELETED_REWARDS_ENTITIES_BY_TYPE,
            Getters.GET_REWARDS_ENTITY_BY_TYPE_AND_ID,
            Getters.GET_REWARDS_ENTITIES_LIST_BY_TYPE,
            Getters.GET_REWARDS_ENTITIES_API_RESPONSE_BY_TYPE,
        ]),
        ...mapGetters('operators', [Getters.languageDefault, Getters.languagesWithoutDefault]),
        languages() {
            return this.$store.state.operators[State.Languages];
        },
        voucherSets() {
            return this[Getters.GET_NOT_DELETED_REWARDS_ENTITIES_BY_TYPE](ENTITY_TYPES.VOUCHER_SET);
        },
        voucherSetsApiResponse() {
            return this[Getters.GET_REWARDS_ENTITIES_API_RESPONSE_BY_TYPE](ENTITY_TYPES.VOUCHER_SET);
        },
        sortFilterVoucherSets() {
            return this.filteredEntitiesMixin(this.sortedVoucherSets);
        },
        sortedVoucherSets() {
            if (this.voucherSets.length) {
                return [...this.voucherSets]
                    .map(voucherSet => ({
                        ...voucherSet,
                        data: {
                            ...voucherSet.data,
                            name: getMultiLangFieldValueByLocale(voucherSet.data.name, this.languageDefault),
                        },
                        status: this.entityState(voucherSet.state),
                        inventoryState: this.getInventoryState(voucherSet),
                        name: getMultiLangFieldValueByLocale(voucherSet.data.name, this.languageDefault),
                        updatedTime: Object.prototype.hasOwnProperty.call(voucherSet, 'update_time')
                            ? moment.unix(voucherSet.update_time)
                            : null,
                        // eslint-disable-next-line camelcase
                        availableCodes: voucherSet.data.metadata?.total_available_voucher_codes ?? 0,
                        // eslint-disable-next-line camelcase
                        totalCodes: voucherSet.data.metadata?.total_added_voucher_codes ?? 0,
                    }))
                    .sort((group1, group2) => group1.data.name.localeCompare(group2.data.name));
            }
            return [];
        },
        allowedActions() {
            return StateActionsMapping[this.selectedEntity.state];
        },
        detailsSections() {
            if (!this.selectedEntityId) {
                return [];
            }
            return this.voucherSections();
        },
        generalSection() {
            const selectedEntityDescription = getProperlyFormattedMultilangFieldValue(
                this.selectedEntity.data.description,
                this[Getters.languageDefault],
            );

            // all available descriptions are calculated here
            const descProperties = isEmpty(selectedEntityDescription)
                ? [
                      {
                          value: this.$i18n.t('generic.noDescription'),
                          label: `${this.$i18n.t('generic.description')}`,
                      },
                  ]
                : [
                      {
                          value: selectedEntityDescription[this.selectedLanguage],
                          label: `${this.$i18n.t('generic.description')}`,
                      },
                  ];

            // eslint-disable-next-line camelcase
            const thresholds = this.selectedEntity?.data?.voucher_settings?.minimum_threshold ?? [];

            return {
                name: toUpper(this.$i18n.t('generic.general')),
                properties: [
                    {
                        value: this.selectedEntity.id,
                        label: this.$i18n.t('generic.id'),
                    },
                    ...descProperties,
                    {
                        value: [...thresholds].sort((a, b) => a - b).join(', '),
                        label: this.$i18n.t('vouchers.inventoryThresholds'),
                    },
                ],
            };
        },
        codeCreationSection() {
            return {
                name: toUpper(this.$i18n.t('vouchers.codeCreation')),
                properties: [
                    {
                        value: upperFirst(this.$i18n.t('vouchers.voucher')),
                        label: this.$i18n.t('generic.type'),
                    },
                ],
            };
        },
        codeUsageSection() {
            return {
                name: toUpper(this.$i18n.t('vouchers.codeUsage')),
                properties: [
                    {
                        value:
                            Object.keys(this.selectedEntity.data.voucher_state)[0] === 'internal_voucher_state'
                                ? this.$i18n.t('vouchers.internalUsage')
                                : this.$i18n.t('vouchers.externalUsage'),
                        label: this.$i18n.t('generic.usage'),
                    },
                ],
            };
        },
        upperEntities() {
            return getEntityConsumers(this.selectedEntityId, ENTITY_TYPES.VOUCHER_SET);
        },
        voucherSelected() {
            return this.selectedEntity?.data;
        },
        tableColumnsData() {
            const columns = [
                {
                    name: this.$i18n.t('generic.name'),
                    key: 'name',
                    mapper: entity => entity.data.name,
                    classes: ['data-name'],
                    forbidHideColumn: true,
                    field: 'name',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('vouchers.updatedTime'),
                    key: 'updatedTime',
                    mapper: entity => this.$localeLibrary.getFormattedDate(entity.update_time),
                    sortBy: entity => entity.update_time,
                    field: 'updatedTime',
                    filterType: tableColumnType.DATE,
                },
                {
                    name: this.$i18n.t('generic.state'),
                    key: 'state',
                    mapper: entity => this.entityState(entity.state),
                    filterType: tableColumnType.TEXT_LIMITED_OPTIONS,
                    field: 'status',
                    limitedOptions: Array.from(new Set(this.sortFilterVoucherSets.map(entity => entity.status))),
                },
                {
                    name: this.$i18n.t('vouchers.inventoryState'),
                    key: 'inventoryState',
                    mapper: entity => entity.inventoryState,
                    filterType: tableColumnType.TEXT_LIMITED_OPTIONS,
                    field: 'inventoryState',
                    limitedOptions: [...voucherSetInventoryStateMap.values()],
                },
                {
                    name: this.$i18n.t('vouchers.availableCodes'),
                    key: 'availableCodes',
                    mapper: entity => entity.availableCodes,
                    filterType: tableColumnType.NUMBER,
                    field: 'availableCodes',
                },
                {
                    name: this.$i18n.t('vouchers.totalCodes'),
                    key: 'totalCodes',
                    mapper: entity => entity.totalCodes,
                    filterType: tableColumnType.NUMBER,
                    field: 'totalCodes',
                },
            ];

            if (this.isRewardsDraftEnabled) {
                columns.push({
                    name: this.$i18n.t('generic.version'),
                    key: 'entityVersion',
                    field: 'entityVersionLabel',
                    filterType: tableColumnType.TEXT_LIMITED_OPTIONS,
                    limitedOptions: [...new Set(this.sortFilterVoucherSets.map(entity => entity.entityVersionLabel))],
                });
            }

            return columns;
        },
        writeEnabled() {
            return isUserAllowed('VouchersSetsWrite') && permissionsService.rewardsEnabled();
        },
    },

    async created() {
        try {
            this.$Progress.start();
            this.isDataLoading = true;
            this.selectedLanguage = this[Getters.languageDefault];
            await this[Actions.REQUEST_REWARDS_ENTITIES_BY_TYPE](ENTITY_TYPES.VOUCHER_SET);
            this.$Progress.finish();
        } catch (error) {
            this.$Progress.fail();
            this.$eventBus.$emit('showAlert', {
                message: this.$i18n.t('alertMessage.failedToLoadNecessaryData'),
            });
        } finally {
            this.isDataLoading = false;
        }
    },

    methods: {
        ...mapActions('rewards', [Actions.REQUEST_REWARDS_ENTITIES_BY_TYPE]),

        onApprove({ id, data }) {
            this.$alert(this.$i18n.t('alerts.areYouSureApprove'), {
                type: this.$ALERT_TYPES.warning,
                buttons: [
                    new Button({
                        label: this.$i18n.t('generic.approve'),
                        alertType: ALERT_TYPES.warning,
                        id: 'confirm-approve-voucher-set',
                        handler: () =>
                            this.$withProgressBar(
                                async () => {
                                    await updatestateVoucherSet(id, data.version, EntityStateMapping.APPROVED);
                                    await this[Actions.REQUEST_REWARDS_ENTITIES_BY_TYPE](ENTITY_TYPES.VOUCHER_SET);
                                    this.$alert(
                                        this.$i18n.t('alertMessage.successMessageWithoutRedirect', {
                                            entityName: entityTypeToEntityLabel[ENTITY_TYPES.VOUCHER_SET],
                                            action: this.$i18n.t('generic.stateMap.approved').toLowerCase(),
                                        }),
                                        {
                                            type: this.$ALERT_TYPES.success,
                                        },
                                    );
                                },
                                {
                                    errorHandler: () => {
                                        this.$alert(
                                            this.$i18n.t('alertMessage.errorDoingSmthTryAgain', {
                                                action: this.$i18n.t('alerts.updating'),
                                                entityName: entityTypeToEntityLabel[ENTITY_TYPES.VOUCHER_SET],
                                            }),
                                            {
                                                type: this.$ALERT_TYPES.error,
                                            },
                                        );
                                    },
                                },
                            ),
                    }),
                ],
            });
        },
        onSelect(voucherSetId) {
            this.selectedEntity = this[Getters.GET_REWARDS_ENTITY_BY_TYPE_AND_ID](
                ENTITY_TYPES.VOUCHER_SET,
                voucherSetId,
            );
            this.selectedEntityId = voucherSetId;
            this.isOverviewEnabled = true;
        },
        onCreate() {
            this.$router.push({
                name: RouteNames.VOUCHER_SET_EDITOR,
                params: { companyId: this.$route.params.companyId },
            });
        },
        entityState(s) {
            return s && s !== STATUS_CODES.NA
                ? this.$i18n.t(`finalStateMapper.${onlyFirstLetterUppercase(entityStateReverseMap(s))}`)
                : this.$i18n.t('generic.N/A');
        },
        setSearchQuery(query) {
            this.searchQueryForTable = query;
        },
        entityName(name) {
            return getMultiLangFieldValueByLocale(name, this.selectedLanguage);
        },
        voucherSections() {
            const sections = [this.generalSection, this.codeCreationSection];
            const selectedEntityData = this.selectedEntity.data;
            if (
                Object.prototype.hasOwnProperty.call(selectedEntityData, 'voucher_state') &&
                !isEmpty(selectedEntityData.voucher_state)
            ) {
                sections.push(this.codeUsageSection);
            }
            return sections;
        },
        hasExpiration() {
            const selectedEntityData = this.selectedEntity.data;
            return (
                Object.prototype.hasOwnProperty.call(selectedEntityData, 'usability_duration') &&
                Object.prototype.hasOwnProperty.call(selectedEntityData.usability_duration, 'duration_in_units') &&
                Object.prototype.hasOwnProperty.call(selectedEntityData.usability_duration, 'unit')
            );
        },
        hasRedeemingRule() {
            const selectedEntityData = this.selectedEntity.data;
            return (
                Object.prototype.hasOwnProperty.call(selectedEntityData, 'voucher_settings') &&
                Object.prototype.hasOwnProperty.call(selectedEntityData.voucher_settings, 'unlimited_uses')
            );
        },
        formatAvailability() {
            const plural =
                this.hasExpiration() && this.selectedEntity.data.usability_duration.duration_in_units > 1 ? 's' : '';
            return this.hasExpiration()
                ? `${this.selectedEntity.data.usability_duration.duration_in_units} ${this.selectedEntity.data.usability_duration.unit}${plural}.`
                : '';
        },
        isTransferable() {
            return this.selectedEntity.data.voucher_settings.is_transferrable ? '' : this.$i18n.t('generic.not');
        },
        getRedeemingRule() {
            return `${this.$i18n.t('vouchers.voucherCanBeRedeemed')}
                    ${
                        this.selectedEntity.data.voucher_settings.unlimited_uses
                            ? this.$i18n.t('generic.unlimitedTime')
                            : this.$i18n.t('generic.only1Time')
                    }.`;
        },
        showMutationDialog(type) {
            this.isMutationModalVisible = true;
            this.mutationDialogDataType = type;
        },
        clearMutationDialogDataType(value) {
            if (!value) {
                this.mutationDialogDataType = '';
            }
        },
        getInventoryState(voucherSet) {
            // eslint-disable-next-line camelcase
            let thresholds = voucherSet?.data?.voucher_settings?.minimum_threshold ?? [];
            // Sort in DESC order
            thresholds = [...thresholds].sort((a, b) => b - a);
            const { metadata } = voucherSet?.data;
            // eslint-disable-next-line camelcase
            const codesAmount = metadata?.total_available_voucher_codes;
            if (codesAmount === 0) {
                return voucherSetInventoryStateMap.get(INVENTORY_STATE_KEYS.EMPTY);
            }
            if (!thresholds.length) {
                return voucherSetInventoryStateMap.get(INVENTORY_STATE_KEYS.OK);
            }
            if (!Number.isInteger(codesAmount)) {
                return voucherSetInventoryStateMap.get(INVENTORY_STATE_KEYS.UNKNOWN);
            }
            // Haven't passed the biggest threshold point yet.
            if (codesAmount > thresholds[0]) {
                return voucherSetInventoryStateMap.get(INVENTORY_STATE_KEYS.OK);
            }
            // Haven't passed the smallest threshold point yet.
            if (codesAmount > thresholds[thresholds.length - 1]) {
                return voucherSetInventoryStateMap.get(INVENTORY_STATE_KEYS.WARNING);
            }
            // More than 0 and less or equal to the last threshold.
            return voucherSetInventoryStateMap.get(INVENTORY_STATE_KEYS.OVER);
        },
        getMutationDialogTitleKey,
        goToEditPage(id, clone = false) {
            this.$router.push({
                name: RouteNames.VOUCHER_SET_EDITOR,
                params: { id, clone, companyId: this.$route.params.companyId },
            });
        },
        goToViewPage(id) {
            this.$router.push({
                name: RouteNames.VOUCHER_SET_EDITOR,
                params: { id, readonly: true, companyId: this.$route.params.companyId },
            });
        },
        async releaseCodesInVoucher(id, releaseOption) {
            const entityToDelete = this[Getters.GET_REWARDS_ENTITY_BY_TYPE_AND_ID](ENTITY_TYPES.VOUCHER_SET, id);
            const entityName = getMultiLangFieldValueByLocale(entityToDelete.data.name);
            let { version } = entityToDelete;
            if (!releaseOption) {
                return version;
            }
            try {
                entityToDelete.data.codes_release_option = releaseOption;
                if (entityToDelete.state !== STATUS_CODES.NA) {
                    const result = await updateVoucherSet(id, entityToDelete.version, entityToDelete.data);
                    version = result.data.version;
                }
            } catch (e) {
                Sentry.captureException(e);
                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('alertMessage.errorDoingSmthTryAgain', {
                        action: this.$i18n.t('vouchers.releaseCodesAction'),
                        entityName,
                    }),
                });
            }
            return version;
        },
        async deleteVoucherSet(id, version) {
            const entityToDelete = this[Getters.GET_REWARDS_ENTITY_BY_TYPE_AND_ID](ENTITY_TYPES.VOUCHER_SET, id);
            const entityName = getMultiLangFieldValueByLocale(entityToDelete.data.name);
            await this.$withProgressBar(
                async () => {
                    let newVersion = version;
                    if (!newVersion) {
                        newVersion = entityToDelete.version;
                    }
                    if (entityToDelete.state !== STATUS_CODES.NA) {
                        await updatestateVoucherSet(id, newVersion, EntityStateMapping.DELETED);
                    }
                    await deleteVoucherSetDraft(id);
                    await this[Actions.REQUEST_REWARDS_ENTITIES_BY_TYPE](ENTITY_TYPES.VOUCHER_SET);
                    this.$eventBus.$emit('showAlert', {
                        message: this.$i18n.t('alertMessage.successMessageWithoutRedirect', {
                            entityName,
                            action: this.$i18n.t('generic.stateMap.deleted').toLowerCase(),
                        }),
                        type: ALERT_TYPES.success,
                    });
                },
                {
                    errorHandler: e => {
                        Sentry.captureException(e);
                        this.$showErrorAlert({
                            message: this.$i18n.t('alertMessage.errorDoingSmthTryAgain', {
                                action: this.$i18n.t('generic.deleting').toLowerCase(),
                                entityName,
                            }),
                        });
                    },
                },
            );
        },
        releaseThenDelete(id, releaseOption) {
            this.releaseCodesInVoucher(id, releaseOption)
                .then(newVersion => {
                    this.deleteVoucherSet(id, newVersion);
                })
                .catch(e => {
                    Sentry.captureException(e);
                    this.$eventBus.$emit('showAlert', {
                        message: this.$i18n.t('alertMessage.errorDoingSmthTryAgain', {
                            entityName: entityTypeToEntityLabel[ENTITY_TYPES.VOUCHER_SET],
                            action: this.$i18n.t('generic.deleting'),
                        }),
                    });
                });
        },
        openReleaseOptions(id, entityName) {
            this.$alert(this.$i18n.t('vouchers.releaseCodesMessage', { entityName }), {
                type: ALERT_TYPES.warning,
                buttons: [
                    new Button({
                        label: this.$i18n.t('vouchers.releaseAllButton'),
                        id: 'confirm-release-all-voucher-codes',
                        handler: () => this.releaseThenDelete(id, CODES_RELEASE_OPTION.ALL),
                    }),
                    new Button({
                        label: this.$i18n.t('vouchers.releaseUnassidneButton'),
                        id: 'confirm-release-unassigned-voucher-codes',
                        handler: () => this.releaseThenDelete(id, CODES_RELEASE_OPTION.UNASSIGNED),
                    }),
                    new Button({
                        label: this.$i18n.t('vouchers.dontReleaseButton'),
                        id: 'confirm-no-vouchers-release',
                        handler: () => this.deleteVoucherSet(id),
                    }),
                ],
            });
        },
        confirmVoucherDeletion(id) {
            const entityToDelete = this[Getters.GET_REWARDS_ENTITY_BY_TYPE_AND_ID](ENTITY_TYPES.VOUCHER_SET, id);
            const entityName = getMultiLangFieldValueByLocale(entityToDelete.data.name);
            this.$alert(this.$t('alerts.areYouSureDeleteEntity', { entityName }), {
                type: ALERT_TYPES.warning,
                buttons: [
                    new Button({
                        label: this.$i18n.t('generic.delete'),
                        handler: () => this.openReleaseOptions(id, entityName),
                    }),
                ],
            });
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/palette';

.card-container {
    margin: 0 1.5rem 0.625rem 1.5rem;
    padding: 0.625rem 1rem;
    border-radius: 0.5rem;
    background-color: rgba($color: $blue, $alpha: 0.15);

    .card-title {
        color: $blue;
        font-size: 0.75rem;
        font-weight: 600;
    }
    .card-list {
        .card-content {
            display: flex;
            flex-direction: row;
            font-size: 0.875rem;
            color: $gray90;
        }
    }
}

.footer-button {
    margin-right: 0.75rem;
}

.data-name {
    font-weight: bold;
}
</style>
