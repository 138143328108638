<template>
    <div>
        <AppHeader :pageTitle="$i18n.t('customerCare.messageHistory')" />
        <div class="message-history-title-wrapper">
            <div
                v-t="'campaigns.searchForUserCampaignMessageHistory'"
                class="body-sm my-3"
            />
            <form
                class="message-history-title-content d-flex align-items-center"
                @submit.prevent="onSearch"
            >
                <AppMultiselectV3
                    id="serachBy-input"
                    v-model="idType"
                    :options="idTypeOptions"
                    label="label"
                    :preselectFirst="true"
                    class="search-inputs"
                />

                <AppInputV3
                    id="user-id-input"
                    :value="userId"
                    :placeholder="$t('billingAccount.id')"
                    :invalid="$v.userId.$error"
                    class="search-inputs"
                    @input="onUserIdChanged"
                />
                <AppButton
                    id="message-history-search"
                    :buttonType="BUTTON_TYPES.PRIMARY"
                    :label="$i18n.t('generic.search')"
                    :iconType="ICON_TYPES.SEARCH"
                    @click="onSearch"
                />
            </form>
        </div>
        <div class="message-history-wrapper">
            <UserMessageHistory
                :id="userIdToPass"
                :idType="idType.id"
            />
        </div>
    </div>
</template>
<script>
// components
import AppHeader from '@/components/layout/AppHeader.vue';
import AppInputV3 from '@/components/partials/inputs/AppInputV3.vue';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import { ICON_TYPES } from '@/common/iconHelper';

// helpers
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import {
    isValidSearchByAccountIdData,
    SEARCH_QUERY_OPTION,
} from '@/__new__/features/customerCare/common/userInfoHelper';
import UserMessageHistory from '@/__new__/features/customerCare/UserMessageHistory.vue';
import AppMultiselectV3 from '@/components/partials/inputs/AppMultiselectV3.vue';
import { SEGMENT_ID_TYPE, StaticFilterLabel } from '@/common/StaticFilter';

export default {
    name: 'MessageHistory',
    components: {
        AppMultiselectV3,
        AppHeader,
        AppInputV3,
        AppButton,
        UserMessageHistory,
    },
    mixins: [validationMixin],
    data() {
        return {
            ICON_TYPES,
            BUTTON_TYPES,
            userId: '',
            userIdToPass: '',
            SEGMENT_ID_TYPE,
            idType: '',
        };
    },
    validations() {
        return {
            userId: {
                required,
                validUserId: isValidSearchByAccountIdData(SEARCH_QUERY_OPTION.ACCOUNT_ID, this.userId),
            },
        };
    },
    computed: {
        idTypeOptions() {
            return [
                {
                    id: SEGMENT_ID_TYPE.USER,
                    label: StaticFilterLabel[SEGMENT_ID_TYPE.USER],
                },
                {
                    id: SEGMENT_ID_TYPE.EXTERNAL_IDP_ID,
                    label: StaticFilterLabel[SEGMENT_ID_TYPE.EXTERNAL_IDP_ID],
                },
            ];
        },
    },
    methods: {
        onUserIdChanged(userId) {
            this.userId = userId.trim();
            this.$v.userId.$touch();
        },
        onSearch() {
            this.$v.$touch();
            if (this.$v.$anyError) {
                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('alertMessage.pleaseFixValidation'),
                });
            } else {
                this.userIdToPass = this.userId;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/layout';
@import '~@/assets/scss/consistency-typography';

.message-history-title-wrapper {
    padding: $spacing-m $spacing-xl;

    .message-history-title-content {
        * {
            margin-right: $spacing-xs;
        }

        .search-inputs {
            width: 15rem;
        }
    }
}
.message-history-wrapper {
    padding: $spacing-m $spacing-xl;
}
</style>
