<template>
    <div class="d-flex justify-content-end py-2 app-pagination-component">
        <div class="d-flex align-items-center">
            <AppButton
                ref="prev"
                :disabled="value === 0 || disabled"
                :iconType="ICON_TYPES.ARROW_LEFT"
                :label="$i18n.t('generic.previousPage')"
                class="pagination-element mr-4"
                @click="prevPage"
            />
            <AppButton
                ref="next"
                :disabled="!hasMore || disabled"
                :iconType="ICON_TYPES.ARROW_RIGHT"
                :isIconRight="true"
                :label="$i18n.t('generic.nextPage')"
                class="pagination-element"
                @click="nextPage"
            />
        </div>
    </div>
</template>

<script>
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import { ICON_TYPES } from '@/common/iconHelper';

export default {
    name: 'AppPaginationLoadMore',
    components: {
        AppButton,
    },
    props: {
        value: {
            type: Number,
            default: 0,
            required: true,
        },
        hasMore: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            ICON_TYPES,
            BUTTON_TYPES,
        };
    },
    methods: {
        prevPage() {
            this.$emit('prevPage');
            this.emitPage(this.value - 1);
        },
        nextPage() {
            this.$emit('nextPage');
            this.emitPage(this.value + 1);
        },
        emitPage(page) {
            this.$emit('input', page);
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/palette';
</style>
