<template>
    <div>
        <div class="d-flex">
            <div class="d-flex control-bar">
                <SearchBox
                    v-model="benefitsSearchQuery"
                    class="table-search-box"
                    :smallSearchIcon="true"
                    data-test-id="search-input-test-id"
                />
                <ResponseModalButton :response="benefitsApiResponse" />
                <AppButton
                    v-show="isAvailableBenefits && isUserAllowed('UMAccountApplyNewBenefitAccountLevel')"
                    :iconType="ICON_TYPES.PLUS"
                    :label="$i18n.t('benefits.newBenefit')"
                    :disabled="!isEditAllowed"
                    class="new-benefit-btn"
                    data-test-id="new-benefit-button"
                    @click="createAction"
                />
                <div />
            </div>
        </div>
        <AppTable
            :columnsData="benefitsColumnsData"
            :entities="benefits"
            :selectedEntityId="selectedEntityId"
            :keyName="keyId"
            :isSearchEnabled="true"
            :innerSearchQuery="benefitsSearchQuery"
            :isPaginationEnabled="true"
            :isDefaultHoverEnabled="true"
            :canSelectColumns="true"
            :isDataLoading="isLoading"
            :newDesign="true"
            :tableTabs="true"
            tableKey="user/pendingBenefits"
            class="mb-3"
            data-test-id="benefits-table-test-id"
            @selectedEntityModel="selectBenefit"
        >
            <template
                v-if="isAvailableBenefits && isUserAllowed('UMAccountEditOrRemoveBenefitAccountLevel')"
                #customRowButtons="{ entity }"
            >
                <IconButton
                    :label="$i18n.t('generic.edit')"
                    :icon="ICON_TYPES.EDIT"
                    :disabled="!isEditAllowed"
                    @iconClick="editAction(entity)"
                />
                <IconButton
                    :label="$i18n.t('generic.delete')"
                    :icon="ICON_TYPES.DELETE"
                    :disabled="!isEditAllowed"
                    class="mr-2"
                    @iconClick="deleteAction(entity)"
                />
            </template>

            <template #subscriberStatus="{ entity }">
                <SubscriberStatusIndicator
                    v-if="entity.subscriberStatus"
                    :status="entity.subscriberStatus"
                />
                <i v-else>{{ $i18n.t('generic.empty') }}</i>
            </template>
        </AppTable>

        <BenefitEditor
            v-model="isBenefitEditorVisible"
            :benefitEntity="benefitEntity"
            :idType="idType"
            @input="clearBenefitEntity"
        />

        <BenefitTableSidebar
            :isVisible="isBenefitSidebarVisible"
            :selectedBenefit="benefitEntity"
            @closeSidebar="isBenefitSidebarVisible = false"
        />
    </div>
</template>

<script>
// components
import AppTable from '@/components/partials/AppTable.vue';
import SearchBox from '@/components/partials/inputs/SearchBox.vue';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import { ALERT_TYPES } from '@/common/alerts/Alert';
import IconButton from '@/components/partials/IconButton.vue';
import { ICON_TYPES } from '@/common/iconHelper';
import Button from '@/common/button/Button';
import BenefitEditor from '@/__new__/features/customerCare/account/BenefitEditor.vue';
import ResponseModalButton from '@/components/partials/ResponseModalButton.vue';
import BenefitTableSidebar from '@/__new__/features/customerCare/account/BenefitTableSidebar.vue';
import SubscriberStatusIndicator from '@/__new__/features/customerCare/subscriber/SubscriberStatusIndicator.vue';
import supportButtonMixin from '@/components/alerts/supportButtonMixin';
// helpers
import { BENEFITS_TYPES, getBenefits, deleteBenefit } from '@/http/benefits';
import {
    PROMOTION_ENUM_TO_LABEL_MAP,
    GRANT_TYPE_ENUM_TO_OBJECT,
    amountByTypeFormatting,
} from '@/modules/promotions/common/promotionsHelper';
import { USER_MANAGER_HIERARCHY } from '@/__new__/features/customerCare/common/customerCareHelper';
import permissionsService, { isUserAllowed, isViewEnabled } from '@/services/permissions/permissions.service';
import * as Sentry from '@sentry/vue';
import { isEmpty } from 'lodash';
// models
import Benefit from '@/models/Benefit';
import Promotion from '@/models/Promotion';

// Vuex
import { mapActions, mapGetters } from 'vuex';
import { Getters } from '@/store/mutation-types';

export default {
    name: 'BenefitsTable',
    components: {
        AppTable,
        SearchBox,
        AppButton,
        IconButton,
        BenefitEditor,
        BenefitTableSidebar,
        ResponseModalButton,
        SubscriberStatusIndicator,
    },
    mixins: [supportButtonMixin],
    props: {
        idType: {
            type: Number,
            default: USER_MANAGER_HIERARCHY.ACCOUNT,
        },
        /**
         * The type of benefits which will be shown.
         * Value must be from BENEFITS_TYPES enum.
         */
        benefitsType: {
            type: String,
            default: BENEFITS_TYPES.AVAILABLE,
        },
        isEditAllowed: {
            type: Boolean,
            default: true,
        },
        transactionsData: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            benefitEntity: {},
            benefitsSearchQuery: '',
            benefits: [],
            benefitsApiResponse: null,
            isBenefitEditorVisible: false,
            isBenefitSidebarVisible: false,
            isLoading: false,
            isSubscriberEnabled: permissionsService.subscriberLevelEnabled() && isViewEnabled('UMSubscriber'),
            keyId: 'benefitId',
            subscribers: [],
            ICON_TYPES,
            BUTTON_TYPES,
        };
    },
    computed: {
        ...mapGetters('userManagementAccount', ['getTransactionsHistoriesById']),
        ...mapGetters('userManagementSubscriber', ['getSubscriberInfoById']),
        benefitsColumnsData() {
            const cols = [
                {
                    name: this.$i18n.t('customerCare.promotions.billingTransactionId'),
                    key: this.isAvailableBenefits ? 'baseOrderId' : 'transactionId',
                    width: 24,
                },
                {
                    name: this.$i18n.t('benefits.creationDate'),
                    key: 'creationDateLabel',
                    sortBy: el => el.creationDate,
                },
                {
                    name: this.$i18n.t('benefits.grantedType'),
                    key: 'grantedTypeLabel',
                },
                {
                    name: this.$i18n.t('promotions.promotionType'),
                    key: 'promotionType',
                },
                {
                    name: this.$i18n.t('generic.amount'),
                    key: 'amountLabel',
                    sortBy: el => el.amount,
                },
                {
                    name: this.$i18n.t('benefits.remainingUses'),
                    key: 'remainingUses',
                },
                {
                    name: this.$i18n.t('benefits.override'),
                    key: 'override',
                },
            ];

            if (this.isSubscriberEnabled) {
                cols.push(
                    {
                        name: this.$i18n.t('customerCare.subscriberId'),
                        key: 'subscriberId',
                    },
                    {
                        name: this.$i18n.t('customerCare.promotions.subscriberStatus'),
                        key: 'subscriberStatus',
                    },
                    {
                        name: this.$i18n.t('customerCare.msisdn'),
                        key: 'msisdn',
                    },
                );
            }

            return cols;
        },
        /**
         * Boolean value that indicates whether the benefits being displayed are available.
         * Used internally to determine if editting UIs should be disabled.
         */
        isAvailableBenefits() {
            return this.benefitsType === BENEFITS_TYPES.AVAILABLE;
        },
        selectedEntityId() {
            return this.benefitEntity?.[this.keyId] || '';
        },
    },
    watch: {
        transactionsData: {
            handler() {
                if (this.isSubscriberEnabled) {
                    this.getSubscribersData();
                }
            },
        },
    },
    async created() {
        await this.fetchTableData();
    },
    methods: {
        ...mapActions('userManagementSubscriber', ['getSubscriberInfo']),
        isUserAllowed,
        async fetchTableData() {
            await this.fetchBenefitsData();
            if (this.isSubscriberEnabled) {
                await this.getSubscribersData();
            }
        },
        async fetchBenefitsData() {
            try {
                this.isLoading = true;
                this.$Progress.start();

                const result = await getBenefits(this.benefitsType, this.$route.params.id, this.idType);
                this.benefitsApiResponse = result;

                // eslint-disable-next-line camelcase
                const rawBenefits = result?.data?.benefits_by_id;

                if (rawBenefits) {
                    this.benefits = this.mapBenefitsForDisplay(rawBenefits);
                }
                this.$Progress.finish();
            } catch (error) {
                Sentry.captureException(error);
                const alertMessage = `${this.$i18n.t('alertMessage.customerCare.promotions.fetchFailure')} (${
                    this.benefitsType
                })`;
                this.showSupportAlert(alertMessage, ALERT_TYPES.error);
                this.$Progress.fail();
                this.benefitsApiResponse = error.response;
            } finally {
                this.isLoading = false;
            }
        },
        mapBenefitsForDisplay(rawBenefits) {
            return Object.values(rawBenefits).reduce((map, el) => {
                const mappedBenefit = Benefit.remapBenefitFromBe(el);

                map.push({
                    ...mappedBenefit,
                    creationDateLabel: this.$localeLibrary.getFormattedDateAndTimeWithSecondsPrecision(
                        mappedBenefit.creationDate,
                    ),
                    grantedTypeLabel: GRANT_TYPE_ENUM_TO_OBJECT[mappedBenefit.grantType]?.label,
                    promotionType: PROMOTION_ENUM_TO_LABEL_MAP[mappedBenefit.promotionTypeId],
                    amountLabel: amountByTypeFormatting(mappedBenefit.amount, mappedBenefit.promotionTypeId),
                    remainingUses: this.remainingUsesMapper(
                        mappedBenefit.recurrenceLimit,
                        mappedBenefit.recurrenceCounter,
                    ),
                    override: this.checkOverride(mappedBenefit),
                    ...(this.isSubscriberEnabled && {
                        subscriberId: this.getSubscriberId(mappedBenefit),
                        subscriberStatus: '',
                        msisdn: '',
                    }),
                });
                return map;
            }, []);
        },
        getSubscriberId(benefit) {
            const transactionEntity = this.transactionsData.find(({ id }) =>
                [benefit.baseOrderId, benefit.transactionId].includes(id),
            );
            return transactionEntity?.chargesData?.[0].subscriberId;
        },
        async getSubscribersData() {
            const subscriberIds = new Set(this.benefits.map(b => this.getSubscriberId(b)));
            const promises = [...subscriberIds].map(subId => this.getSubscriberData(subId));

            await Promise.all(promises);
            this.mapSubscribersData();
        },
        async getSubscriberData(subscriberId) {
            try {
                if (!subscriberId) {
                    return;
                }
                const subscriber = this.getSubscriberInfoById(subscriberId);
                if (!isEmpty(subscriber)) {
                    this.subscribers.push(subscriber);
                } else {
                    await this.getSubscriberInfo(subscriberId);
                    this.getSubscriberData(subscriberId);
                }
            } catch (error) {
                this.$Progress.fail();
                this.$alert(this.$i18n.t('alertMessage.userManagement.somethingWentWrongFetchingSubscriberData'));
                Sentry.captureException(error);
            }
        },
        mapSubscribersData() {
            this.benefits.forEach(benefit => {
                const subscriberId = this.getSubscriberId(benefit);
                const { msisdn, state } = {
                    ...this.subscribers.find(({ subscriber_id: id }) => id === subscriberId),
                };
                benefit.subscriberId = subscriberId;
                benefit.msisdn = msisdn;
                benefit.subscriberStatus = state;
            });
        },
        createAction() {
            this.isBenefitEditorVisible = true;
        },
        editAction(entity) {
            this.benefitEntity = entity;
            this.isBenefitEditorVisible = true;
        },
        deleteAction(entity) {
            const deleteButton = new Button({
                label: this.$i18n.t('generic.confirm'),
                alertType: ALERT_TYPES.warning,
            });
            this.$eventBus.$emit('showAlert', {
                message: this.$i18n.t('benefits.alerts.areYouSureBenefitAction', {
                    action: this.$i18n.t('generic.delete').toLowerCase(),
                }),
                type: ALERT_TYPES.warning,
                buttons: [deleteButton],
            });
            this.$eventBus.$once('buttonClicked', async buttonId => {
                if (buttonId === deleteButton.id) {
                    try {
                        this.$Progress.start();
                        await deleteBenefit(this.$route.params.id, this.idType, entity.benefitId);
                        this.$eventBus.$emit('showAlert', {
                            message: this.$i18n.t('benefits.alerts.benefitSuccessfulAction', {
                                action: this.$i18n.t('generic.stateMap.deleted').toLowerCase(),
                            }),
                            type: ALERT_TYPES.success,
                        });
                        await this.fetchTableData();
                        this.$Progress.finish();
                    } catch (e) {
                        Sentry.captureException(e);
                        this.showSupportAlert(
                            this.$i18n.t('alertMessage.somethingWentWrongFetchingNecessaryData'),
                            ALERT_TYPES.error,
                        );
                        this.$Progress.fail();
                    }
                }
            });
        },
        clearBenefitEntity(val) {
            if (!val) {
                this.benefitEntity = {};
                this.fetchTableData();
            }
        },
        selectBenefit(entity) {
            this.benefitEntity = entity;
            this.isBenefitSidebarVisible = true;
        },
        checkOverride(benefit) {
            try {
                if (benefit.promotionId) {
                    const promoRuleUnmapped = this.$store.getters[`promotions/${Getters.PROMOTION_BY_ID}`](
                        benefit.promotionId,
                    );
                    const promoRule = Promotion.remapPromotionFromBe(promoRuleUnmapped);

                    if (
                        promoRuleUnmapped &&
                        promoRule &&
                        !(
                            benefit.name === promoRule.name &&
                            benefit.description === promoRule.description &&
                            benefit.promoShortDescription === promoRule.promoShortDescription &&
                            benefit.benefitShortDescription === promoRule.benefitShortDescription &&
                            benefit.termsAndConditions === promoRule.termsAndConditions &&
                            benefit.amount === promoRule.amount &&
                            benefit.recurrenceLimit === promoRule.recurrenceLimit
                        )
                    ) {
                        return this.$i18n.t('generic.yes');
                    }
                }
                return this.$i18n.t('generic.no');
            } catch (e) {
                return this.$i18n.t('generic.no');
            }
        },
        remainingUsesMapper(recurrenceLimit, recurrenceCounter) {
            if (!recurrenceLimit) {
                return this.$i18n.t('generic.unlimitedCapital');
            }

            return recurrenceLimit - (recurrenceCounter || 0);
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/typographyV2';
@import '~@/assets/scss/layout';

.control-bar {
    margin-left: auto;
    padding-bottom: 0.5rem;
    .new-benefit-btn {
        padding: 0.75rem;
    }

    .search-box-wrapper {
        position: relative;
        width: 2.9rem;
        height: 2.5rem;

        &.opened {
            width: 15rem;
        }
    }
}

.table-search-box {
    width: 15rem;
}
</style>
