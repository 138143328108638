import { type DnoResponseBase } from '@/http';

export enum CONSENT_CONTEXT_TYPE {
    ACCEPTABLE_USE_POLICY = 1,
    PLATFORM_TERMS_AND_CONDITIONS = 2,
    OFFER = 3,
    ACH_TERMS_AND_CONDITIONS = 4,
    MARKETING_EMAIL = 5,
    MARKETING_SMS = 6,
    TAX_EXEMPTION = 7,
    AUTO_PAYMENT_TC = 8,
    MANAGE_PERSONAL_DATA_AND_INFORM = 9,
    MARKETING_OFFERS_AND_SERVICES = 10,
    MARKETING_SMS_MMS = 11,
    MARKETING_DIRECT_CALLS = 12,
    MARKETING_DIGITAL = 13,
    MARKETING_PUSH = 14,
    TAILORED_OFFERS = 15,
    PARTNER_OFFERS = 16,
    REG_COLLECT_USER_INFO = 17,
    PAYMENT_TERMS_AND_CONDITIONS = 18,
}

export const CONSENT_CONTEXT_TYPE_TO_I18N_KEY_MAP = new Map([
    [CONSENT_CONTEXT_TYPE.ACCEPTABLE_USE_POLICY, 'customerCare.consentsContextType.acceptableUsePolicy'],
    [CONSENT_CONTEXT_TYPE.PLATFORM_TERMS_AND_CONDITIONS, 'customerCare.consentsContextType.platformTermsAndConditions'],
    [CONSENT_CONTEXT_TYPE.OFFER, 'productCatalog.entities.offer'],
    [CONSENT_CONTEXT_TYPE.ACH_TERMS_AND_CONDITIONS, 'customerCare.consentsContextType.achTermsAndConditions'],
    [CONSENT_CONTEXT_TYPE.MARKETING_EMAIL, 'customerCare.consentsContextType.marketingEmail'],
    [CONSENT_CONTEXT_TYPE.MARKETING_SMS, 'customerCare.consentsContextType.marketingSms'],
    [CONSENT_CONTEXT_TYPE.TAX_EXEMPTION, 'customerCare.consentsContextType.taxExemption'],
    [CONSENT_CONTEXT_TYPE.AUTO_PAYMENT_TC, 'customerCare.consentsContextType.autoPaymentTc'],
    [
        CONSENT_CONTEXT_TYPE.MANAGE_PERSONAL_DATA_AND_INFORM,
        'customerCare.consentsContextType.managePersonalDataAndInform',
    ],
    [CONSENT_CONTEXT_TYPE.MARKETING_OFFERS_AND_SERVICES, 'customerCare.consentsContextType.marketingOffersAndServices'],
    [CONSENT_CONTEXT_TYPE.MARKETING_SMS_MMS, 'customerCare.consentsContextType.marketingSmsMms'],
    [CONSENT_CONTEXT_TYPE.MARKETING_DIRECT_CALLS, 'customerCare.consentsContextType.marketingDirectCalls'],
    [CONSENT_CONTEXT_TYPE.MARKETING_DIGITAL, 'customerCare.consentsContextType.marketingDigital'],
    [CONSENT_CONTEXT_TYPE.MARKETING_PUSH, 'customerCare.consentsContextType.marketingPush'],
    [CONSENT_CONTEXT_TYPE.TAILORED_OFFERS, 'customerCare.consentsContextType.tailoredOffers'],
    [CONSENT_CONTEXT_TYPE.PARTNER_OFFERS, 'customerCare.consentsContextType.partnerOffers'],
    [CONSENT_CONTEXT_TYPE.REG_COLLECT_USER_INFO, 'customerCare.consentsContextType.collectRegistrationData'],
    [CONSENT_CONTEXT_TYPE.PAYMENT_TERMS_AND_CONDITIONS, 'customerCare.consentsContextType.paymentTermsAndConditions'],
]);

export const getConsentsContextTypeI18nKey = (contextType: CONSENT_CONTEXT_TYPE): string => {
    return CONSENT_CONTEXT_TYPE_TO_I18N_KEY_MAP.get(contextType) || 'generic.stateMap.undefined';
};

// customize i18n for account terms consent section
export const ACCOUNT_TERMS_PANEL_CONSENT_CONTEXT_TYPE_TO_I18N_KEY_MAP = new Map([
    [
        CONSENT_CONTEXT_TYPE.PLATFORM_TERMS_AND_CONDITIONS,
        'customerCareSuite.termsConsentsContextType.platformTermsAndConditions',
    ],
    [CONSENT_CONTEXT_TYPE.AUTO_PAYMENT_TC, 'customerCareSuite.termsConsentsContextType.autoPaymentTc'],
    [
        CONSENT_CONTEXT_TYPE.PAYMENT_TERMS_AND_CONDITIONS,
        'customerCareSuite.termsConsentsContextType.paymentTermsAndConditions',
    ],
]);

export const getTermsConsentTypeI18nKey = (contextType: CONSENT_CONTEXT_TYPE): string => {
    return (
        ACCOUNT_TERMS_PANEL_CONSENT_CONTEXT_TYPE_TO_I18N_KEY_MAP.get(contextType) ||
        CONSENT_CONTEXT_TYPE_TO_I18N_KEY_MAP.get(contextType) ||
        'generic.stateMap.undefined'
    );
};

// TODO: make a class and move to services/dno/consent/models once old UM deprecated
export type Consent = {
    context_type: CONSENT_CONTEXT_TYPE;
    context_id: string;
    invoked_by_target_id: string;
    invoked_by_target_type: number;
    is_set: boolean;
    target_type: number;
    epoch: number;
    target_id: string;
    operator_name: string;
};

export interface GetConsentsResponse extends DnoResponseBase {
    consents: Consent[];
}
