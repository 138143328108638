import Vue from 'vue';
import CommonTemplateEntityEditorWrapperBase from '@/__new__/features/template/CommonTemplateEntityEditorWrapperBase.vue';
import EntityLoaderMixin from '@/__new__/features/template/common/EntityLoaderMixin';
import EntityDraftLoaderMixin from '@/__new__/features/template/common/EntityDraftLoaderMixin';
import EntityInitDataLoaderMixin from '@/__new__/features/template/common/EntityInitDataLoaderMixin';
import { validationMixin } from 'vuelidate';

export default function createCommonTemplateEntityEditorWrapper(
    businessMixins,
    componentName = 'DynamicCommonTemplateEntityEditorWrapper',
) {
    const mixinsArray = Array.isArray(businessMixins) ? businessMixins : [businessMixins];
    return Vue.extend({
        name: componentName,
        extends: CommonTemplateEntityEditorWrapperBase,
        mixins: [...mixinsArray, EntityDraftLoaderMixin, EntityInitDataLoaderMixin, EntityLoaderMixin, validationMixin],
    });
}
