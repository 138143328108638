
// components
import AppHeader from '@/components/layout/AppHeader.vue';
import AbstractEditPageWrapper from '@/components/layout/AbstractEditPageWrapper.vue';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import AppInputV3 from '@/components/partials/inputs/AppInputV3.vue';
import AppMultiselectV3 from '@/components/partials/inputs/AppMultiselectV3.vue';

// Http
import { createApplication, getApplicationOwners } from '@/__new__/services/dno/operateAPIs/http/operateAPIs';

// Helpers
import RouteNames from '@/router/routeNames';
import { ALERT_TYPES } from '@/common/alerts/Alert';
import { ICON_TYPES } from '@/common/iconHelper';
import { required } from 'vuelidate/lib/validators';
import { isUserAllowed } from '@/services/permissions/permissions.service';
import { PLURALIZATION } from '@/common/locale/labelSingularOrPlural';

export default {
    name: 'ApplicationsEditorPage',

    components: {
        AppHeader,
        AbstractEditPageWrapper,
        AppButton,
        AppInputV3,
        AppMultiselectV3,
    },

    data() {
        return {
            name: '' as string,
            description: '' as string,
            redirectUrl: '' as string,
            applicationOwner: { name: '', value: '' } as { name: string; value: string },
            applicationOwnerOptions: [] as { name: string; value: string }[],

            BUTTON_TYPES,
            ICON_TYPES,
            PLURALIZATION,
        };
    },

    computed: {
        writeEnabled(): boolean {
            return isUserAllowed('OperateAPIsApplicationsWrite');
        },
    },

    created() {
        this.$withLoadingSpinner(async () => {
            try {
                const applicationOwnersResponse = await getApplicationOwners();

                this.applicationOwnerOptions = (applicationOwnersResponse.data || []).map((appOwner: any) => ({
                    name: appOwner.name,
                    value: appOwner.id,
                }));

                if (this.applicationOwnerOptions.length) {
                    [this.applicationOwner] = this.applicationOwnerOptions;
                }
            } catch {
                this.$alert(this.$i18n.t('alertMessage.somethingWentWrongFetchingNecessaryData'));
            }
        });
    },

    validations: {
        name: {
            required,
        },
        description: {
            required,
        },
        applicationOwner: {
            required,
        },
    },

    methods: {
        async onSave(): Promise<any> {
            try {
                this.$v.$touch();

                if (this.$v.$invalid) {
                    return;
                }

                await createApplication({
                    '@type': 'Application',
                    '@baseType': 'LogicalResource',
                    name: this.name,
                    commercialName: 'Evole Quest',
                    description: this.description,
                    category: 'games',
                    digitalIdentity: {
                        '@type': 'ApiDigitalIdentity',
                        clientId: 'evolve_quest_channelPartner15',
                        redirectUrl: this.redirectUrl ? [this.redirectUrl] : ['https://evolvequest.com/redirect'],
                        jwksUri: 'https://evolvequest.com/jwk',
                    },
                    applicationOwner: {
                        '@type': 'PartyRef',
                    },
                    externalIdentifier: [
                        {
                            '@type': 'ExternalIdentifier',
                            owner: '61bfb733-7c45-4c75-b8a9-960bfa8444af',
                            externalIdentifierType: 'ChannelPartner',
                        },
                        {
                            '@type': 'ExternalIdentifier',
                            owner: this.applicationOwner,
                            externalIdentifierType: 'ApplicationOwner',
                        },
                    ],
                });

                this.$showSuccessAlert({
                    message: this.$i18n.t('operateAPIs.successfullyCreatedApplication'),
                });

                this.redirectToListPage();
            } catch {
                this.$alert(this.$i18n.t('operateAPIs.somethingWentWrongCreateApplication'), {
                    type: ALERT_TYPES.error,
                });
            }
        },
        redirectToListPage(): void {
            this.$router.push({
                name: RouteNames.OPERATE_APIS_APPLICATIONS_LIST_PAGE,
            });
        },
    },
};
