<template>
    <div class="container">
        <div
            :class="{
                'section-title-basic row': isNewDesignSelected,
                'section-title-capitalized': capitalizedTitles,
            }"
            class="section-title row"
            @click="isCollapsed = !(isCollapsed || alwaysExpanded)"
        >
            <div class="col-12 d-flex justify-content-between">
                <span class="d-flex align-items-center">
                    {{ section.name }}
                    <slot name="nameTooltip" />
                </span>
                <img
                    :class="[{ collapsed: isCollapsed }]"
                    src="../../../assets/icons/arrow-up-dark.svg"
                    alt="arrow"
                    class="arrow"
                />
            </div>
        </div>
        <div
            v-for="(property, index) in section.properties"
            v-show="!isCollapsed"
            :key="index"
            class="container section-properties"
        >
            <!-- Everything else is handled by regular formatters -->
            <div
                :class="{
                    'section-property-basic': isNewDesignSelected,
                    'section-property-big-gap': useBigRowGaps,
                    'section-property-error': property.isError,
                }"
                class="section-property row no-gutters"
            >
                <template v-if="property.value || property.value === 0">
                    <div
                        v-tooltip="{
                            content:
                                property.formatter === PROPERTY_FORMATTERS.TEMPLATE_PROPERTY
                                    ? `${$i18n.t('generic.externalId')}: ${property.externalId}`
                                    : '',
                            placement: 'top',
                            autoHide: false,
                            classes: ['tooltip-bold'],
                        }"
                        :class="{ 'property-value-name': isNewDesignSelected }"
                        class="col-4 label-bold"
                    >
                        {{ property.label }}
                    </div>

                    <div
                        v-if="property.formatter === 'link'"
                        :class="{ disabled: isDisabled(property.value) }"
                        class="col-8 value link"
                    >
                        <a
                            class="hover-underline"
                            @click="changeLocation(property)"
                        >
                            {{ property.linkLabel || 'More Info' }}
                        </a>
                    </div>

                    <div
                        v-else
                        class="col-8 value"
                        :class="{ error: property.isError }"
                    >
                        {{ formatProperty(property.value, property.formatter) }}
                    </div>
                </template>
                <template v-else>
                    <div class="col-4 label-bold">
                        {{ property.label }}
                    </div>
                    <div
                        v-if="typeof property.value === 'boolean'"
                        class="col-8 value"
                    >
                        {{ formatBooleanValue(property.value) }}
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import { formatValueForDisplaying, getBeautifulBoolean } from '@/common/formatting';

export const PROPERTY_FORMATTERS = {
    TEMPLATE_PROPERTY: 'template_property',
};

export default {
    name: 'ListDropdownSection',
    props: {
        // Structure of the section prop:
        // {
        //     name: String,
        //     properties: Array ({
        //         label: String,
        //         linkLabel: String,
        //         value: String/Object({
        //             name: Strings,
        //             params: Object
        //         })
        //     })
        // }
        // TODO: create class for section prop
        section: {
            type: Object,
            required: true,
        },
        collapse: {
            type: Boolean,
            default: false,
        },
        isNewDesignSelected: {
            type: Boolean,
            default: false,
        },
        alwaysExpanded: {
            type: Boolean,
            default: false,
        },
        useBigRowGaps: {
            type: Boolean,
            default: false,
        },
        capitalizedTitles: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            isCollapsed: this.collapse,
            PROPERTY_FORMATTERS,
        };
    },
    watch: {
        collapse(val) {
            this.isCollapsed = val;
        },
    },
    methods: {
        formatProperty(val, formatter) {
            if (typeof formatter === 'function') {
                return formatter(val);
            }
            return formatValueForDisplaying(val, formatter);
        },
        formatBooleanValue(val) {
            return getBeautifulBoolean(val);
        },

        // todo refactor
        changeLocation(link) {
            if (typeof link.value === 'string') {
                window.location = link.value;
            } else if (typeof link.value === 'number') {
                if (link.value) {
                    this.$emit('showDialog', link.type);
                }
            }
        },
        isDisabled(value) {
            return typeof value === 'number' && !value;
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/palette';
@import '../../../assets/scss/outlines';
@import '../../../assets/scss/animations';

.section-title {
    background-color: $white;
    font-size: 0.75rem;
    font-weight: bold;
    line-height: 2.17;
    color: $gray-blue;
    padding: 1rem 1rem 0.5rem 0;
    justify-content: space-between;
    text-transform: uppercase;
    &.section-title-capitalized {
        text-transform: capitalize;
        font-size: 0.875rem;
    }
    &.section-title-basic {
        margin: 0;
        border-bottom: solid 1px #f1f1f1;
    }
}

.arrow {
    transition: $fast-animation-time ease-in-out;

    &.collapsed {
        transform: rotate(180deg);
    }
}

.section-properties {
    min-height: 1.25rem;
}

.section-property {
    margin: 0 1.5rem 0 0;
    padding: 3px 0;
    font-size: 0.75rem;
    font-weight: 600;
    line-height: 1.67;
    color: $gray90;

    &.section-property-basic {
        align-items: top;
        margin: 0 8px;

        .property-value-name {
            line-height: 2;
        }
    }
    &.section-property-big-gap {
        margin-top: 2rem;
        &:first-of-type {
            margin-top: 1.5rem;
        }
    }
    &.section-property-error {
        color: $red;
    }

    .value {
        font-size: 0.875rem;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.43;
        color: $gray90;
        word-break: break-all;

        &.link {
            cursor: pointer;
        }
        &.disabled {
            cursor: default;
            a {
                color: $gray30;
                text-decoration: none;
                cursor: default;
            }
        }
        &:not(.disabled) {
            a {
                color: $blue;

                &:hover {
                    color: $blue;
                    text-decoration: underline;
                }
            }
        }
        &.error {
            color: $red;
        }
    }
}
</style>
