
import Vue from 'vue';

// Components
import AppDialogV2 from '@/components/partials/AppDialogV2.vue';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import { ICON_TYPES } from '@/common/iconHelper';
import AppInputV3 from '@/components/partials/inputs/AppInputV3.vue';
import AppTextareaV3 from '@/components/partials/inputs/AppTextareaV3.vue';

// HTTP
import { registerExternalPartnerPubKeys } from '@/__new__/services/dno/authorization/http/authorization';

// Helpers
import * as Sentry from '@sentry/vue';
import { cloneDeep } from 'lodash';
import {
    emptyPartnerPubKeysRequest,
    placeholderPartnerPubKeysRequest,
    pubKeyValidator,
    cidrValidator,
} from '@/__new__/features/settings/thirdPartyApplications/common/ThirdPartyApplicationsHelper';

// Validators
import { validationMixin } from 'vuelidate';
import { required, ipAddress, or } from 'vuelidate/lib/validators';

export default Vue.extend({
    name: 'NewThirdPartyApplication',
    components: {
        AppDialogV2,
        AppButton,
        AppInputV3,
        AppTextareaV3,
    },
    mixins: [validationMixin],
    props: {
        isVisible: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            isLoading: false,
            app: cloneDeep(emptyPartnerPubKeysRequest),
            BUTTON_TYPES,
            ICON_TYPES,
            placeholderPartnerPubKeysRequest,
        };
    },
    validations() {
        return {
            app: {
                partner_name: {
                    required,
                },
                key_info: {
                    scope: {
                        required,
                    },
                    acl: {
                        required,
                        $each: {
                            required,
                            cidr: or(cidrValidator, ipAddress),
                        },
                    },
                    pub_key: {
                        required,
                        pubKeyValidator,
                    },
                },
            },
        };
    },
    methods: {
        addAcl(): void {
            this.app.key_info.acl.push('');
        },
        removeAcl(index: number): void {
            this.app.key_info.acl.splice(index, 1);
        },
        async submit(): Promise<void> {
            try {
                this.$v.$touch();
                if (this.$v.$invalid) {
                    this.$eventBus.$emit('showAlert', {
                        message: this.$i18n.t('alertMessage.pleaseFixValidation'),
                    });
                    return;
                }
                this.$Progress.start();
                this.isLoading = true;
                await registerExternalPartnerPubKeys(this.app);
                this.app = cloneDeep(emptyPartnerPubKeysRequest);
                this.$Progress.finish();
                this.$showSuccessAlert({
                    message: this.$i18n.t('alertMessage.registerExternalPartnerPublicKeysSuccess'),
                });
                this.$emit('submit');
                this.$v.$reset();
            } catch (error) {
                Sentry.captureException(error);
                this.$Progress.fail();
                this.$alert(this.$i18n.t('alertMessage.failedToRegisterExternalPartnerPublicKeys'));
            } finally {
                this.isLoading = false;
            }
        },
    },
});
