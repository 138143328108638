import {
    CHARGING_ENTITY_TYPES,
    mapLowerEntitiesVersions,
    LowerEntitiesVersionsObject,
} from '@/__new__/services/dno/charging/common/versioningHelper';
import { PolicyCountersApiResponse } from '@/__new__/services/dno/charging/http/policyCounters';
import {
    EntityBaseConstructorArgs,
    EntityBaseModel,
    EntityBaseDraftModel,
} from '@/__new__/services/dno/charging/models/EntityBaseModel';

export interface PolicyCounterConstructorArgs extends EntityBaseConstructorArgs {
    usage_counter_id: string;
    policy_counter_id?: string;
    statuses: string[];
    thresholds_percentage: number[];
    thresholds: number[];
    available_statuses: string[];
}

export interface PolicyCounterDraftConstructorArgs extends PolicyCounterConstructorArgs {
    operator_name: string;
    type: string;
    update_portal_id: string;
    data?: PolicyCounterConstructorArgs;
}

export class PolicyCounter extends EntityBaseModel {
    usageCounterId?: string;
    statuses?: string[];
    thresholdsPercentage?: number[];
    thresholds?: number[];
    availableStatuses?: string[];
    lowerEntitiesVersions?: LowerEntitiesVersionsObject | null;

    constructor(
        data: PolicyCounterConstructorArgs,
        draftEntities: PolicyCounterDraft[],
        responseData?: PolicyCountersApiResponse,
    ) {
        super(data, draftEntities);
        this.usageCounterId = data.usage_counter_id || undefined;
        this.statuses = data.statuses || undefined;
        this.thresholdsPercentage = data.thresholds_percentage || undefined;
        this.thresholds = data.thresholds || undefined;
        this.availableStatuses = data.available_statuses || undefined;
        this.lowerEntitiesVersions =
            responseData?.usage_counter_by_id && this.usageCounterId
                ? this.getLowerEntitiesVersions(responseData?.usage_counter_by_id, this.usageCounterId)
                : null;
    }

    getLowerEntitiesVersions(data: Record<string, any>, usageCounterId: string): any {
        return {
            [CHARGING_ENTITY_TYPES.USAGE_COUNTER]: mapLowerEntitiesVersions(data, [usageCounterId]),
        };
    }
}

export class PolicyCounterDraft extends EntityBaseDraftModel {
    usageCounterId?: string;
    statuses?: string[];
    thresholdsPercentage?: number[];
    thresholds?: number[];
    availableStatuses?: string[];

    constructor(data: PolicyCounterDraftConstructorArgs) {
        super(data);
        this.usageCounterId = data.usage_counter_id || undefined;
        this.statuses = data.statuses || undefined;
        this.thresholdsPercentage = data.thresholds_percentage || undefined;
        this.thresholds = data.thresholds || undefined;
        this.availableStatuses = data.available_statuses || undefined;
    }
}

export default PolicyCounter;
