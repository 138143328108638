<template>
    <div v-click-outside="closeFilter">
        <slot
            name="button"
            :clickHandler="toggleFilterVisibility"
        >
            <IconButton
                :label="$i18n.t('generic.billingCycle')"
                :icon="ICON_TYPES.CALENDAR"
                :allowClickPropagation="true"
                :manualTriggerActiveIcon="isFiltered"
                @iconClick="toggleFilterVisibility"
            />
        </slot>
        <div
            v-if="isFilterVisible"
            class="d-flex align-items-center filter-component pr-4 pl-1"
        >
            <div class="d-flex ml-2 mr-2 pt-1 align-items-center">
                <AppMultiselectV3
                    :value="timeRangeTypeValue"
                    :options="timeRangeOptionsList"
                    :allowEmpty="false"
                    :searchable="false"
                    :showLabels="false"
                    :isSmall="true"
                    :borderNone="true"
                    :blueArrow="true"
                    openDirection="bottom"
                    class="narrow time-range-type-picker"
                    @input="onTimeRangeValuePicked"
                />
            </div>
            <div class="d-flex align-items-center">
                <div
                    v-if="timeRangeTypeValue === TimeRangeOptions.postpaid.choose"
                    class="d-flex align-items-center"
                >
                    <div class="mr-2 all-caps-text">
                        {{ $i18n.t('generic.is') }}
                    </div>
                    <AppMultiselectV3
                        v-model="chosenBillingCycle"
                        :allowEmpty="false"
                        :preselectFirst="true"
                        :maxHeight="162"
                        :options="chooseBillingCycleOptions"
                        :searchable="false"
                        :showLabels="false"
                        :isSmall="true"
                        :placeholder="$i18n.t('customerCare.transactionHistory.billingCycle.choose')"
                        openDirection="bottom"
                        label="label"
                        trackBy="label"
                        class="small time-range-type-picker"
                    />
                </div>

                <div
                    v-if="isCustomTimeRange"
                    class="d-flex align-items-center"
                >
                    <div class="mr-2 all-caps-text">
                        {{ $i18n.t('generic.between') }}
                    </div>
                    <DateTimePicker
                        v-model="dateStart"
                        :disabledDates="disableDateStart"
                        type="datetime"
                        class="date-picker small mr-2"
                    />
                    <div class="mr-2 all-caps-text">
                        {{ $i18n.t('generic.and') }}
                    </div>
                    <DateTimePicker
                        v-model="dateEnd"
                        :disabledDates="disableDateEnd"
                        type="datetime"
                        class="date-picker small"
                    />
                </div>

                <div
                    v-if="isCustomMonth"
                    class="d-flex align-items-center"
                >
                    <div class="mr-2 all-caps-text">
                        {{ $i18n.t('generic.is') }}
                    </div>
                    <DateTimePicker
                        v-model="month"
                        :disabledDates="disableDateStart"
                        type="month"
                        class="date-picker small"
                    />
                </div>

                <AppButton
                    :label="$i18n.t('generic.add')"
                    :disabled="disableSubmit"
                    @click="onApplyClick"
                />
            </div>
        </div>
    </div>
</template>
<script>
// COMPONENTS
import AppMultiselectV3 from '@/components/partials/inputs/AppMultiselectV3.vue';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import DateTimePicker from '@/components/partials/inputs/DateTimePicker.vue';
import IconButton from '@/components/partials/IconButton.vue';
import { ICON_TYPES } from '@/common/iconHelper';
import vClickOutside from 'v-click-outside';

// HELPERS
import moment, { Moment } from 'moment';
import { UM_SUBSCRIPTION_TYPES } from '@/__new__/features/customerCare/common/userInfoHelper';

export default {
    name: 'TransactionDurationFilter',
    components: {
        AppButton,
        AppMultiselectV3,
        DateTimePicker,
        IconButton,
    },
    directives: {
        clickOutside: vClickOutside.directive,
    },
    props: {
        lastBillingDate: {
            type: Moment,
            required: true,
        },
        subscriberRegistrationTimestamp: {
            type: Number,
            required: true,
        },
        subscriptionType: {
            type: Number,
            required: true,
        },
        usedInRewards: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data() {
        return {
            isFilterVisible: false,
            isFiltered: false,
            chosenBillingCycle: '',
            timeRangeTypeValue: '',
            cachedTimestampsForTransactionsRequest: null,
            ICON_TYPES,
            BUTTON_TYPES,
            dateStart: moment().subtract(7, 'days').toDate(),
            dateEnd: moment().toDate(),
            month: moment().toDate(),
            walletTypeMonitored: '',
        };
    },
    computed: {
        disableDateEnd() {
            return date => {
                const restrictions = {
                    from: moment(this.dateStart).add(31, 'day').toDate(),
                    to: moment(this.dateStart).toDate(),
                };

                return date < restrictions.to || date > restrictions.from;
            };
        },
        timeRanges() {
            return {
                current: this.$i18n.t('customerCare.transactionHistory.billingCycle.current'),
                choose: this.$i18n.t('customerCare.transactionHistory.billingCycle.choose'),
                custom: this.$i18n.t('generic.custom'),
                currentMonth: this.$i18n.t('customerCare.transactionHistory.billingCycle.currentMonth'),
                lastMonth: this.$i18n.t('customerCare.transactionHistory.billingCycle.lastMonth'),
                chooseCalendarMonth: this.$i18n.t('customerCare.transactionHistory.billingCycle.chooseCalendarMonth'),
            };
        },
        TimeRangeOptions() {
            return {
                postpaid: {
                    current: this.timeRanges.current,
                    choose: this.timeRanges.choose,
                    custom: this.timeRanges.custom,
                },
                allOther: {
                    currentMonth: this.timeRanges.currentMonth,
                    lastMonth: this.timeRanges.lastMonth,
                    chooseCalendarMonth: this.timeRanges.chooseCalendarMonth,
                    custom: this.timeRanges.custom,
                },
                rewards: {
                    currentMonth: this.timeRanges.currentMonth,
                    lastMonth: this.timeRanges.lastMonth,
                    custom: this.timeRanges.custom,
                },
            };
        },
        disableDateStart() {
            return date => {
                const restrictions = {
                    from: moment(this.dateEnd).toDate(),
                };

                return date > restrictions.from;
            };
        },
        disableSubmit() {
            if (this.isCustomTimeRange) {
                return !(this.dateStart && this.dateEnd);
            }
            if (this.isCustomMonth) {
                return !this.month;
            }
            return false;
        },
        chooseBillingCycleOptions() {
            if (this.lastBillingDate && this.subscriberRegistrationTimestamp) {
                const resultArray = [];
                resultArray.push({
                    startTime: this.lastBillingDate,
                    endTime: moment(),
                    label: this.getLabelForCustomBillingCycleOption(this.lastBillingDate, moment()),
                });

                const monthsAmountBetweenLastBillingAndSubscriberCreation = moment
                    .duration(moment(this.lastBillingDate).diff(moment.unix(this.subscriberRegistrationTimestamp)))
                    .asMonths();

                for (let i = 0; i < Math.trunc(monthsAmountBetweenLastBillingAndSubscriberCreation); i += 1) {
                    const endTime = resultArray[resultArray.length - 1].startTime;
                    const startTime = endTime.clone().subtract(1, 'month');
                    resultArray.push({
                        endTime,
                        startTime,
                        label: this.getLabelForCustomBillingCycleOption(startTime, endTime),
                    });
                }

                if (monthsAmountBetweenLastBillingAndSubscriberCreation % 1 !== 0) {
                    const endTime = resultArray[resultArray.length - 1].startTime;
                    const startTime = moment.unix(this.subscriberRegistrationTimestamp);
                    resultArray.push({
                        endTime,
                        startTime,
                        label: this.getLabelForCustomBillingCycleOption(startTime, endTime),
                    });
                }

                return resultArray;
            }
            return [];
        },
        getTimestampsForTransactionsRequest() {
            let startTime = this.lastBillingDate;

            // adding one day to current time to make sure we cover all time zones
            let endTime = moment().add(1, 'day');

            switch (this.timeRangeTypeValue) {
                case this.timeRanges.currentMonth:
                    startTime = moment().startOf('month');
                    break;
                case this.timeRanges.lastMonth:
                    startTime = moment().subtract(1, 'months').startOf('month');
                    endTime = moment().subtract(1, 'months').endOf('month');
                    break;
                case this.timeRanges.chooseCalendarMonth:
                    startTime = moment(this.month).startOf('month');
                    endTime = moment(this.month).endOf('month');

                    break;
                case this.timeRanges.choose:
                    ({ startTime, endTime } = { ...this.chosenBillingCycle });
                    if (this.chosenBillingCycle && endTime.date() === moment().date()) {
                        // adding +1 day if current billing cycle was picked
                        endTime.add(1, 'day');
                    }
                    break;
                case this.timeRanges.custom:
                    startTime = moment(this.dateStart).seconds(0);
                    endTime = moment(this.dateEnd).seconds(0);
                    break;
                default:
                    break;
            }
            return {
                startTime,
                endTime,
            };
        },
        timeRangeOptionsList() {
            if (this.usedInRewards) {
                return Object.values(this.TimeRangeOptions.rewards);
            }
            return this.subscriptionType === UM_SUBSCRIPTION_TYPES.POSTPAID
                ? Object.values(this.TimeRangeOptions.postpaid)
                : Object.values(this.TimeRangeOptions.allOther);
        },
        isDefaultTimeRange() {
            return (
                this.timeRangeTypeValue === this.TimeRangeOptions.postpaid.current ||
                this.timeRangeTypeValue === this.TimeRangeOptions.allOther.currentMonth ||
                this.timeRangeTypeValue === this.TimeRangeOptions.rewards.currentMonth
            );
        },
        isCustomTimeRange() {
            return (
                this.timeRangeTypeValue === this.TimeRangeOptions.postpaid.custom ||
                this.timeRangeTypeValue === this.TimeRangeOptions.allOther.custom ||
                this.timeRangeTypeValue === this.TimeRangeOptions.rewards.custom
            );
        },
        isCustomMonth() {
            return this.timeRangeTypeValue === this.TimeRangeOptions.allOther.chooseCalendarMonth;
        },
    },
    created() {
        [this.timeRangeTypeValue] = this.timeRangeOptionsList;
        this.onApplyClick();
    },
    methods: {
        toggleFilterVisibility() {
            this.isFilterVisible = !this.isFilterVisible;
        },
        closeFilter(event) {
            // Check if we clicked on datepicker dropdown
            if (event?.target.closest('.mx-datepicker-content')) {
                return;
            }
            this.isFilterVisible = false;
        },
        onTimeRangeValuePicked(value) {
            this.timeRangeTypeValue = value;
        },
        getLabelForCustomBillingCycleOption(startTime, endTime) {
            return `${startTime.format('MMM DD, YYYY')} - ${endTime.format('MMM DD, YYYY')}`;
        },
        onApplyClick() {
            this.cachedTimestampsForTransactionsRequest = this.getTimestampsForTransactionsRequest;
            // if the values of the newly selected wallet_type and previosly selected wallet_type are the same,
            // keep the Apply button disabled
            this.isFiltered = !this.isDefaultTimeRange;
            this.closeFilter();
            this.$emit('timeRangeChanged', {
                timestamps: this.getTimestampsForTransactionsRequest,
            });
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/palette';
@import '~@/assets/scss/mixins';
@import '~@/assets/scss/z-indexes';

.filter-component {
    height: 2.5rem;
    width: fit-content;
    position: absolute;
    right: 2rem;
    top: 3.75rem;
    box-shadow: 0 2px 6px 0 rgba(51, 81, 149, 0.3);
    border-radius: 0.5rem;
    background-color: $white;
    z-index: $icon-button-z-index;
}

.add-button {
    color: $blue;
    cursor: pointer;
    font-weight: 700;
    font-size: 0.875rem;
    line-height: 1.625rem;

    &.disabled {
        cursor: default;
        color: $gray10;
    }
}

.time-range-type-picker {
    width: 205px;
}

.date-picker ::v-deep .datepicker-wrapper {
    min-width: 9rem;
    width: 9rem;
}
</style>
