import { invert } from 'lodash';
import i18n from '@/i18n';
import { ICON_TYPES } from '@/common/iconHelper';
import { SEGMENT_ID_TYPES } from '@/common/StaticFilter';

export enum ID_TYPE {
    SUBSCRIBER = 'Subscriber',
    ACCOUNT = 'Account',
    USER = 'User',
    UNREGISTERED_ID = 'Unregistered id',
    UNREGISTERED_EMAIL = 'Unregistered email',
    UNREGISTERED_MSISDN = 'Unregistered Msisdn',
    MSISDN = 'MSISDN',
    ICCID = 'ICCID',
    IMSI = 'IMSI',
    EMAIL = 'Email',
    ADDRESS = 'Address',
    GOOGLE_PLACE_ID = 'Google Place ID',
    ADDRESSBOOK_ID = 'Addressbook ID',
    PROVIDER_ID = 'Provider ID',
    MARKET_ID = 'Market ID',
    ACCOUNT_ID = 'Account ID',
    SUBSCRIBER_ID = 'Subscriber ID',
    USER_ID = 'User ID',
    EXTERNAL_IDP_ID = 'External IDP ID',
}

export const LOGICAL_OPERATORS = {
    and: 'AND',
    or: 'OR',
};

export const SEGMENT_SUPPORTED_ID_TYPES_BY_KEYS = {
    [ID_TYPE.SUBSCRIBER]: 1,
    [ID_TYPE.ACCOUNT]: 2,
    [ID_TYPE.USER]: 3,
    [ID_TYPE.UNREGISTERED_ID]: 100,
    [ID_TYPE.EXTERNAL_IDP_ID]: 400,
};

export const ID_TYPES_ICONS = {
    [ID_TYPE.SUBSCRIBER]: {
        largeIcon: ICON_TYPES.USER,
        smallIcon: ICON_TYPES.USER,
        label: i18n.t('metrics.totalSubscribers'),
    },
    [ID_TYPE.ACCOUNT]: {
        largeIcon: ICON_TYPES.USER,
        smallIcon: ICON_TYPES.USER,
        label: i18n.t('metrics.totalAccounts'),
    },
    [ID_TYPE.USER]: {
        largeIcon: ICON_TYPES.USER,
        smallIcon: ICON_TYPES.USER,
        label: i18n.t('campaigns.totalUsers'),
    },
    [ID_TYPE.UNREGISTERED_ID]: {
        largeIcon: ICON_TYPES.USER,
        label: ID_TYPE.UNREGISTERED_ID,
    },
    [ID_TYPE.UNREGISTERED_EMAIL]: {
        largeIcon: ICON_TYPES.MAIL,
        label: ID_TYPE.UNREGISTERED_EMAIL,
    },
    [ID_TYPE.UNREGISTERED_MSISDN]: {
        largeIcon: ICON_TYPES.USER,
        label: ID_TYPE.UNREGISTERED_MSISDN,
    },
    [ID_TYPE.EXTERNAL_IDP_ID]: {
        largeIcon: ICON_TYPES.USER,
        label: ID_TYPE.EXTERNAL_IDP_ID,
    },
};

export const SEGMENT_SUPPORTED_ID_TYPES_BY_VALUES = invert(SEGMENT_SUPPORTED_ID_TYPES_BY_KEYS);

export const GENERATION_TYPES_BY_KEYS = {
    DYNAMIC: 1,
    TRIGGER_BASED: 2,
    STATIC_FILTER: 3,
};
export const SEGMENT_CREATION_SOURCES = {
    USER_MADE: 'User made',
    PRE_CREATED: 'Pre created',
};

export const SEGMENT_CREATION_SOURCES_CODES: { [key: number]: string } = {
    0: SEGMENT_CREATION_SOURCES.USER_MADE,
    1: SEGMENT_CREATION_SOURCES.PRE_CREATED,
};
export const SEGMENT_CREATION_SOURCES_MAP = invert(SEGMENT_CREATION_SOURCES_CODES);
export const GENERATION_TYPES_BY_VALUE = invert(GENERATION_TYPES_BY_KEYS);

export const LIST_ALLOW_TYPES_FOR_GET_REGISTERED_IDS_COUNT = [
    SEGMENT_ID_TYPES.USER,
    SEGMENT_ID_TYPES.ACCOUNT,
    SEGMENT_ID_TYPES.SUBSCRIBER,
];

export default {
    LOGICAL_OPERATORS,
    SEGMENT_SUPPORTED_ID_TYPES_BY_KEYS,
    SEGMENT_SUPPORTED_ID_TYPES_BY_VALUES,
    ID_TYPES_ICONS,
    GENERATION_TYPES_BY_KEYS,
    GENERATION_TYPES_BY_VALUE,
    LIST_ALLOW_TYPES_FOR_GET_REGISTERED_IDS_COUNT,
};
