
import Vue, { PropType } from 'vue';

// Components
import AppButton from '@/components/partials/inputs/AppButton.vue';
import AppMultiselectV3 from '@/components/partials/inputs/AppMultiselectV3.vue';
import AppToggle from '@/components/partials/inputs/AppToggle.vue';
import AppRadioButton from '@/components/partials/inputs/AppRadioButton.vue';
import AppTooltip from '@/components/partials/AppTooltip.vue';
import IconButton from '@/components/partials/IconButton.vue';

// Validations
import { validationMixin } from 'vuelidate';
import { required, requiredIf } from 'vuelidate/lib/validators';

// Helpers
import { ICON_TYPES } from '@/common/iconHelper';
import { TOOLTIP_POSITION } from '@/common/tooltip';
import { TaxAttributionData, TaxAttributionEntry } from './common/taxAttribution';
import { capitalize } from 'lodash';
import { TaxDistributionTypeDno } from './common/taxAttributionDno';

export default Vue.extend({
    name: 'TaxAttributionTable',
    components: {
        AppButton,
        AppMultiselectV3,
        AppToggle,
        AppTooltip,
        AppRadioButton,
        IconButton,
    },
    mixins: [validationMixin],
    props: {
        /**
         * Contains all relevant tax attribution data configurable by the user.
         */
        value: {
            type: Object as PropType<TaxAttributionData>,
            default: () => ({
                amountType: TaxDistributionTypeDno.PERCENT,
                roundingIndicatorIndex: 0,
                taxAttributionList: [],
                taxInclusive: false,
            }),
        },
        childEntities: {
            type: Array,
            default: () => [],
        },
        /**
         * Disables all child components. Used for read only mode.
         */
        disabled: {
            type: Boolean,
            default: false,
        },
        /**
         * Indicates if errors will be shown. Used for validation purposes.
         */
        showValidation: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            ICON_TYPES,
            TOOLTIP_POSITION,
            amountTypeOptions: [
                {
                    id: TaxDistributionTypeDno.FIXED,
                    displayStr: '$',
                },
                {
                    id: TaxDistributionTypeDno.PERCENT,
                    displayStr: '%',
                },
            ],
            capitalize,
        };
    },
    computed: {
        amountPlaceholder() {
            return this.amountTypeOptions.find(option => option.id === this.value.amountType)?.displayStr ?? '';
        },
        /**
         * Determines if sum of amounts is valid.
         * Determined using vuelidate validations.
         * `true` if sum validation specified in vuelidate is true.
         * `true` if table has no entries.
         * `false` otherwise
         */
        isAmountSumValid() {
            // Zero entries in our list, pretend our sum is valid:
            if (this.value.taxAttributionList.length === 0) {
                return true;
            }
            return this.$v.value.taxAttributionList.$each[0].amount.amountSumValid;
        },
        /**
         * Determines if any amount is left empty.
         * Determined using vuelidate validations.
         * `true` if atleast 1 amount is unspecified.
         * `false` otherwise.
         */
        isAmountMissing() {
            for (let i = 0; i < this.value.taxAttributionList.length; i += 1) {
                if (!this.$v.value.taxAttributionList.$each[i].amount.required) {
                    return true;
                }
            }
            return false;
        },
        /**
         * Determines if any child entity is unselected.
         * Determined using vuelidate validations.
         * `true` if atleast 1 child entity is unspecified.
         * `false` otherwise.
         */
        isChildIdMissing() {
            for (let i = 0; i < this.value.taxAttributionList.length; i += 1) {
                if (this.$v.value.taxAttributionList.$each[i].id.$error) {
                    return true;
                }
            }
            return false;
        },
        /**
         * List of error messages to display.
         */
        errorMessages() {
            const messages = [];
            // Amount type error message
            if (this.$v.value.amountType.$error) {
                messages.push(this.$i18n.t('productCatalog.offers.editor.amountTypeMustBeSpecified'));
            }

            // Child id error message
            if (this.isChildIdMissing) {
                messages.push(this.$i18n.t('productCatalog.offers.editor.childEntityMustBeSpecified'));
            }

            // Amount errors
            if (this.isAmountMissing) {
                messages.push(this.$i18n.t('productCatalog.offers.editor.amountMustBeSpecified'));
            } else if (!this.isAmountSumValid) {
                if (this.value.amountType === TaxDistributionTypeDno.PERCENT) {
                    messages.push(this.$i18n.t('productCatalog.offers.editor.amountMustSumTo100'));
                } else if (this.value.amountType === TaxDistributionTypeDno.FIXED) {
                    messages.push(this.$i18n.t('productCatalog.offers.editor.amountMustSumToOfferPrice'));
                }
            }

            return messages;
        },
    },
    watch: {
        // Perform validation as soon as we get data:
        value: {
            immediate: true,
            handler() {
                this.$v.$touch();
                this.$emit('error', this.$v.$error);
            },
        },
    },
    methods: {
        /**
         * Add a row to the tax attribution table
         */
        addTableRow() {
            this.value.taxAttributionList.push({} as TaxAttributionEntry);
            this.onTableChange();
        },
        /**
         * Removes a row at specified `index` from the tax attribution table
         * @param {number} index
         */
        deleteTableRow(index: number) {
            // Remove row from tax table
            this.value.taxAttributionList.splice(index, 1);

            // Adjust rounding indicator index if necessary
            if (index === this.value.roundingIndicatorIndex) {
                this.value.roundingIndicatorIndex = 0;
            } else if (index < this.value.roundingIndicatorIndex) {
                this.value.roundingIndicatorIndex -= 1;
            }

            this.onTableChange();
        },
        /**
         * Function called when the tax attribution table changes.
         */
        onTableChange() {
            this.$v.$touch();
            this.$emit('error', this.$v.$error);
            this.$emit('input', this.value);
        },
    },
    validations: {
        value: {
            amountType: {
                // Amount type only needs to be set if we have some rows in our table
                required: requiredIf(function isRequired() {
                    return this.value?.taxAttributionList.length > 0;
                }),
            },
            taxAttributionList: {
                $each: {
                    id: {
                        required,
                    },
                    amount: {
                        required,
                        // Amounts must sum up to 100% or Offer price
                        amountSumValid: function amountSumValid() {
                            const amounts = this.value.taxAttributionList.map(entry => Number(entry.amount));
                            const sum = amounts.reduce((total, amount) => total + amount, 0);
                            switch (this.value.amountType) {
                                case TaxDistributionTypeDno.PERCENT:
                                    return sum === 100;
                                case TaxDistributionTypeDno.FIXED:
                                    // To be implemented
                                    return true;
                                default:
                                    return true;
                            }
                        },
                    },
                },
            },
        },
    },
});
