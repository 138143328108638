import CronExpression from '@/common//CronExpression';
import i18n from '@/i18n';

export enum DAY {
    MONDAY = 'MON',
    TUESDAY = 'TUE',
    WEDNESDAY = 'WED',
    THURSDAY = 'THU',
    FRIDAY = 'FRI',
    SATURDAY = 'SAT',
    SUNDAY = 'SUN',
}

export enum TIME_FREQUENCY {
    MINUTES = 'Minutes',
    DAILY = 'Daily',
    WEEKLY = 'Weekly',
    MONTHLY = 'Monthly',
    CRON = 'Cron',
}

export const TIME_FREQUENCY_TO_I18N_LABEL = new Map([
    [TIME_FREQUENCY.MINUTES, 'analytics.reportsPage.minutes'],
    [TIME_FREQUENCY.DAILY, 'analytics.reportsPage.daily'],
    [TIME_FREQUENCY.WEEKLY, 'analytics.reportsPage.weekly'],
    [TIME_FREQUENCY.MONTHLY, 'analytics.reportsPage.monthly'],
    [TIME_FREQUENCY.CRON, 'generic.advanced'],
]);

export const DAY_TO_I18N_LABEL = new Map([
    [DAY.MONDAY, 'generic.weekDays.monday'],
    [DAY.TUESDAY, 'generic.weekDays.tuesday'],
    [DAY.WEDNESDAY, 'generic.weekDays.wednesday'],
    [DAY.THURSDAY, 'generic.weekDays.thursday'],
    [DAY.FRIDAY, 'generic.weekDays.friday'],
    [DAY.SATURDAY, 'generic.weekDays.saturday'],
    [DAY.SUNDAY, 'generic.weekDays.sunday'],
]);

export function formatTimeToFrequencyInterval(hour: string, minute: string): number {
    const getNumeric = (val: string) => Number(val.substring(2));

    if (hour === '*') {
        if (minute === '*') {
            return 1;
        }

        return minute.includes('*/') ? getNumeric(minute) : Number(minute);
    }

    if (minute === '*') {
        return getNumeric(hour) * 60;
    }

    return getNumeric(hour) * 60 + Number(minute);
}

export function formatHourAndMinToTime(hour: string, minute: string) {
    const [h, min] = [hour, minute].map(t => {
        if (t.length === 1) {
            t = '0' + t;
        }
        return t;
    });
    return `${h}:${min}`;
}

export function formatArrayToText(items: any[]): string {
    let days;

    if (!items.length) {
        return '';
    }

    if (items.length > 1) {
        const last = items.pop();

        days = items.join(', ');
        days += ` ${i18n.t('generic.and')} ${last}`;
    } else {
        days = items[0];
    }

    return days;
}

/**
 *
 * @returns formatted value depending on the frequency of occurence
 * e.g.:
 *  - Each 5 minutes;
 *  - Daily at 15:30;
 *  - Weekly on Monday, Wednesday and Friday at 00:00;
 *  - Monthly on 1., 15. and 20. at 00:00;
 */
export function formatCronValueToText(cron: string) {
    const { minute, hour, monthDay, weekDay } = CronExpression.getArgumentsFromString(cron);
    const freq: TIME_FREQUENCY = CronExpression.getTimeFreqFromCronValue(cron);
    let days: string;

    switch (freq) {
        case TIME_FREQUENCY.MINUTES:
            const minutes = formatTimeToFrequencyInterval(hour, minute);
            return i18n.t('ord.interval.minutes', { minutes });

        case TIME_FREQUENCY.DAILY:
            return i18n.t('ord.interval.daily', { time: formatHourAndMinToTime(hour, minute) });

        case TIME_FREQUENCY.WEEKLY:
            const wdays = (weekDay.split(',') as DAY[]).map(day => i18n.t(DAY_TO_I18N_LABEL.get(day) as string));
            days = formatArrayToText(wdays);

            return i18n.t('ord.interval.weekly', { days, time: formatHourAndMinToTime(hour, minute) });

        case TIME_FREQUENCY.MONTHLY:
            const dates = monthDay.split(',').map(d => `${d}.`);
            days = formatArrayToText(dates);

            return i18n.t('ord.interval.monthly', { days, time: formatHourAndMinToTime(hour, minute) });

        case TIME_FREQUENCY.CRON:
            return [minute, hour, monthDay, weekDay].every(Boolean) ? cron : 'N/A';
    }
}
