import axios from '@/http/index';

/**
 * Fetches a single metric information from prometheus.
 *
 * @param metric
 * @return {AxiosPromise}
 */
function fetchMetric(metric) {
    const options = {
        method: 'POST',
        url: '/ops/metric',
        data: {
            metric,
        },
    };
    return axios(options);
}

/**
 * Fetches a list of a metrics that currently logged in operator has access to.
 *
 * @return {AxiosPromise}
 */
function fetchMetricsList() {
    const options = {
        method: 'GET',
        url: '/ops/metricslist',
    };

    return axios(options);
}

export default {
    fetchMetric,
    fetchMetricsList,
};
