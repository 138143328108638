// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { permissionGroups } from '@/common/roles/roleHelper';
import Account from '@/models/Account';
import {
    GrantOptions,
    USER_VERIFICATION_ID_TYPES,
    ADDRESS_FIELDS,
    ADDRESS_TYPE,
    USER_MANAGER_HIERARCHY,
} from '@/__new__/features/customerCare/common/customerCareHelper';
import {
    UserManagerSearchOption,
    UserManagerSearchOptions,
} from '@/__new__/features/customerCareSuite/common/ccsSearchHelper';
import { CONSENT_CONTEXT_TYPE } from '@/__new__/features/customerCare/common/consentsHelper';
import {
    USER_DETAIL_FIELD,
    ACCOUNT_DETAIL_FIELD,
    SUBSCRIBER_DETAIL_FIELD,
} from '@/__new__/features/customerCare/common/detailFieldsHelper';
import { SEGMENT_SUPPORTED_ID_TYPES_BY_KEYS } from '@/common/segments';
import { isUserLotusFlareAdmin } from '@/services/permissions/permissionHelper';
import store from '@/store/store';
import { Modules } from '@/store/store';
import { get, isEmpty } from 'lodash';
import VueCookie from 'vue-cookie';
import { Views } from '@/models/ViewsHelper';
import { TranslateResult } from 'vue-i18n';
import Permission from '@/models/Permission';
import { LF_TELECOM_TENANT_NAME } from '@/models/Tenant';
import i18n from '@/i18n';

/*
 * Following map structure is constructed in next manner.
 *
 * Top level is Service (Product Catalog, User Manager, Segments, etc...)
 * Next level is Component of that service (e.g. Product Catalog has Offers, Products and such).
 * Each Component can have further level of customization internally.
 */

export function isRewardsDraftEnabled(): boolean {
    return isFeatureFlagEnabled('rewards-draft');
}

export function isBulkeditCoreFieldsEnabled(): boolean {
    return isFeatureFlagEnabled('bulk-edit-core-fields');
}

/**
 * Not the same as LF ADMIN just if user has admin permission.
 * @return {boolean|*}
 */

export function isViewConfig(): boolean {
    try {
        // Temporary support for `"viewConfig:" false` untill POR-6296 is done.
        return getOperatorConfigValue('viewConfig', true);
    } catch (e) {
        return true;
    }
}

// Function to check whether a feature flag is enabled.
//
// It's indented that feature flags be enabled in one of the following
// ways (in priority order):
//
//  1. Via a URL. This method is used if you only want to enable a feature for
//     one particular user. The URL to use should look similar to the following:
//
//     https://<PORTAL_HOST>/featureFlags/set?id=<FEATURE_FLAG_ID>&action=set
//
//  2. Via operator config ("featureFlags" should be a top level key that
//     contains a list of enabled feature flag ID strings). This method enables
//     the feature for all users on a particular tenant.
//
export function isFeatureFlagEnabled(id: string): boolean {
    const cookieExists = VueCookie.get(`ff-${id}`);
    if (cookieExists) {
        return true;
    }

    try {
        const featureFlagList = getOperatorConfigValue('featureFlags', []);
        return featureFlagList.find(ff => ff === id);
    } catch (e) {
        return false;
    }
}

function isUserAdmin(): boolean {
    if (isViewConfig()) {
        return true;
    }
    try {
        return Account.storedInstance.permissions.includes(permissionGroups.admin.write.key);
    } catch (e) {
        return false;
    }
}

// TODO: Replace this with Factory function per Service.

function getOperatorConfig(): TenantConfig {
    return store?.getters[`${Modules.config}/getOperatorConfig`];
}

export function getCurrentCompanyId(): number {
    // Ideally we'd be able to take the company ID from the $route object,
    // and flow it down to this function. Due to the amount of legacy code,
    // this is quite a bit of work so we will take it directly from the URL
    // instead.
    //
    return (
        window.location.hash.match('^#/(?<companyId>[0-9]+)(/.*)?$')?.groups?.companyId ??
        Number(Account.storedInstance.companyId)
    );
}

function getOperatorViews(): Views {
    const companyId = getCurrentCompanyId();
    return store?.getters[`${Modules.config}/getViews`]?.[companyId];
}

function flattenViewsRecursive(views, flattenedViews = []) {
    for (const [key, value] of Object.entries(views)) {
        flattenedViews[key] = value;
        if (value['views']) {
            flattenViewsRecursive(value['views'], flattenedViews);
        }
    }
    return flattenedViews;
}

export function isViewEnabled(viewKey: string): boolean {
    if (!isViewConfig()) {
        return true;
    }

    if (getOperatorViews()) {
        const flattenedViews = flattenViewsRecursive(getOperatorViews());
        if (flattenedViews[viewKey]) {
            return flattenedViews[viewKey]['enabled'];
        }
    }

    return false;
}

export function isUserAllowed(...permissionsKeys: string[]): boolean {
    if (!isViewConfig() || permissionsKeys.length < 1) {
        return true;
    }

    const companyPermissions: Permission[] = store?.getters[`${Modules.config}/getPermissions`];

    return permissionsKeys.some((key: string) => {
        const lookupPermission = companyPermissions?.find(item => item.key === key);
        return Boolean(lookupPermission && Account.storedInstance.permissions.includes(lookupPermission.key));
    });
}

export function isUserInternal(): boolean {
    if (!isViewConfig()) {
        return true;
    }
    const companyPermissions: Permission[] = store?.getters[`${Modules.config}/getPermissions`];
    return companyPermissions.some(permission => permission.internal);
}

export function getOperatorConfigValue(path: string, defaultValue?: any) {
    const config = getOperatorConfig();
    return get(config, path, get(DefaultOperatorConfig, path, defaultValue));
}

/**
 * Checks if a service is enabled for an operator.
 * If this function returns false expect any fetch requests to the service to fail.
 * Service names should match those defined in route proxies(`portal/server/routes.json`).
 * @param dnoServiceName
 * @returns
 */
export function isServiceEnabled(dnoServiceName: string) {
    const disabledServices = getOperatorConfigValue('disabledServices', []);
    return !disabledServices.includes(dnoServiceName);
}

export const BILLING_VERSIONS = {
    v3: 3,
    v4: 4,
};

type TenantConfig = { [key: string]: any };

export const DefaultOperatorConfig: TenantConfig = {
    userGroupsExternalIdTypes: [],
    staticSegmentExternalIdTypes: [],
    cepSegmentIdTypes: [SEGMENT_SUPPORTED_ID_TYPES_BY_KEYS.Subscriber],
    cepMarketingCampaignDeliveryTypes: [0, 1],
    cepServiceNotificationCampaignDeliveryTypes: [0, 1],
    cepBackofficeCampaignDeliveryTypes: [1],
    userConsentsDefault: [],
    userManagerMaxWalletAmountAfterTopUp: {
        maxAmount: 200,
    },
    userVerificationIdType: USER_VERIFICATION_ID_TYPES.EMAIL,
    Metrics: 2,
    BillingStatement: 3,
    billingStatementLimit: 24,
    hideTransactionsUnpaidCanceled: false,
    userManagerSearchOptions: ['AccountID'],
    userManagerGrantOptions: ['Account'],
    userSearchEmailMultipleResults: false,
    userSearchShowTerminated: false,
    userSearchShowUserCreated: false,
    userFieldsMap: {},
    userDetailFields: [
        USER_DETAIL_FIELD.USER_ID,
        USER_DETAIL_FIELD.FIRST_NAME,
        USER_DETAIL_FIELD.LAST_NAME,
        USER_DETAIL_FIELD.VERIFICATION_STATUS,
        USER_DETAIL_FIELD.MSISDN,
        USER_DETAIL_FIELD.EMAIL,
        USER_DETAIL_FIELD.CREATION_DATE,
        USER_DETAIL_FIELD.LAST_ACTIVE,
        USER_DETAIL_FIELD.CARDINALITY,
    ],
    accountConsentFields: [],
    accountDetailFields: [
        ACCOUNT_DETAIL_FIELD.ACCOUNT_ID,
        ACCOUNT_DETAIL_FIELD.BILLING_DAY,
        ACCOUNT_DETAIL_FIELD.LAST_4_DIGITS,
        ACCOUNT_DETAIL_FIELD.CREATION_DATE,
        ACCOUNT_DETAIL_FIELD.CARDINALITY,
        ACCOUNT_DETAIL_FIELD.BUSSINES_ADDRESS_TYPE,
        ACCOUNT_DETAIL_FIELD.ADDRESS,
        ACCOUNT_DETAIL_FIELD.FLAGS,
        ACCOUNT_DETAIL_FIELD.ACCOUNT_PROPERTIES,
        ACCOUNT_DETAIL_FIELD.WIRELESS_BRAND_CODE,
        ACCOUNT_DETAIL_FIELD.WIRELESS_NUMBER_ELIGIBLE,
        ACCOUNT_DETAIL_FIELD.WIRELESS_NUMBER_STATUS,
    ],
    subscriberDetailFields: [
        SUBSCRIBER_DETAIL_FIELD.SUBSCRIBER_ID,
        SUBSCRIBER_DETAIL_FIELD.ACTIVATION_DATE,
        SUBSCRIBER_DETAIL_FIELD.SUBSCRIPTION_TYPE,
        SUBSCRIBER_DETAIL_FIELD.REFERRAL_CODE,
        SUBSCRIBER_DETAIL_FIELD.CREATION_DATE,
        SUBSCRIBER_DETAIL_FIELD.CARDINALITY,
        SUBSCRIBER_DETAIL_FIELD.MSISDN,
        SUBSCRIBER_DETAIL_FIELD.IMSI,
        SUBSCRIBER_DETAIL_FIELD.ICCID,
        SUBSCRIBER_DETAIL_FIELD.PUK,
        SUBSCRIBER_DETAIL_FIELD.FLAGS,
        SUBSCRIBER_DETAIL_FIELD.ADDRESS,
        SUBSCRIBER_DETAIL_FIELD.TAX_AREA_ID,
        SUBSCRIBER_DETAIL_FIELD.DOCUMENT,
        SUBSCRIBER_DETAIL_FIELD.DOCUMENT_NUMBER,
        SUBSCRIBER_DETAIL_FIELD.PROVIDER,
        SUBSCRIBER_DETAIL_FIELD.LOCATION_ID,
        SUBSCRIBER_DETAIL_FIELD.SERVICE_ID,
        SUBSCRIBER_DETAIL_FIELD.EXTERNAL_SERVICE_ID,
        SUBSCRIBER_DETAIL_FIELD.PROVIDER_PRODUCT,
        SUBSCRIBER_DETAIL_FIELD.ORDER_ID,
        SUBSCRIBER_DETAIL_FIELD.ORDER_ACTION_ID,
        SUBSCRIBER_DETAIL_FIELD.RESERVATION_ID,
        SUBSCRIBER_DETAIL_FIELD.RESERVED_DP_NAP_ID,
        SUBSCRIBER_DETAIL_FIELD.CVLAN,
        SUBSCRIBER_DETAIL_FIELD.SVLAN,
        SUBSCRIBER_DETAIL_FIELD.MAC_ADDRESS,
        SUBSCRIBER_DETAIL_FIELD.DEVICE_TYPE,
        SUBSCRIBER_DETAIL_FIELD.DEVICE_MODEL,
        SUBSCRIBER_DETAIL_FIELD.OUTAGE_INFO,
        SUBSCRIBER_DETAIL_FIELD.DEVICE_SECTION,
    ],
    addressFieldsMap: {
        [ADDRESS_FIELDS.ADDRESS]: { enabled: true, optional: true },
        [ADDRESS_FIELDS.CITY]: { enabled: true, optional: true },
        [ADDRESS_FIELDS.STATE]: { enabled: true, optional: true },
        [ADDRESS_FIELDS.COUNTRY]: { enabled: true, optional: true },
    },
    addressFieldsOrder: [ADDRESS_FIELDS.ADDRESS, ADDRESS_FIELDS.CITY, ADDRESS_FIELDS.STATE, ADDRESS_FIELDS.COUNTRY],
    allowedAddressTypes: [
        ADDRESS_TYPE.UNKNOWN,
        ADDRESS_TYPE.BILLING,
        ADDRESS_TYPE.SHIPPING,
        ADDRESS_TYPE.SERVICE,
        ADDRESS_TYPE.PERMANENT,
        ADDRESS_TYPE.MAILING,
    ],
    isMVNESimSwap: false,
    isMVNESuspend: false,
    isMVNETenant: false,
    isViasatDemo: false,
    isKuiperDemo: false,
    isAtiotDemo: false,
    isPasswordResetEnabled: false,
    uploadFileExtensions: [],
    isFlagFilteringDisabled: false,
    enrichedAppointment: false,
    disableOldUM: false,
};

export function getTenantUMSearchOptions(): UserManagerSearchOption {
    const options = getOperatorConfig()?.userManagerSearchOptions || [];
    const result: UserManagerSearchOption = {};
    for (const key of options) {
        result[key] = UserManagerSearchOptions[key];
    }

    // Check if MSISDN label is defined
    const msisdnLabel = getOperatorConfigValue('msisdnLabel');
    if (msisdnLabel && result['MSISDN']) {
        result['MSISDN'].label = i18n.t(msisdnLabel);
        result['MSISDN'].errorMessage = i18n.t('customerCare.errorAlertMessages.msisdnValidation', {
            msisdnLabel: i18n.t(msisdnLabel),
        });
    }

    return result;
}

type GrantOption = { label: TranslateResult; id: number };
export function getGrantRoleOptions(): GrantOption[] {
    const options = getOperatorConfig()?.userManagerGrantOptions || [];
    const result: GrantOption[] = [];
    for (const key of options) {
        result.push((GrantOptions as Record<string, GrantOption>)[key]);
    }

    return result;
}

export const getFeatureVersion = (featureName: string): number => {
    const featureNameString = `${featureName}`;
    try {
        return getOperatorConfig()[featureNameString] || DefaultOperatorConfig[featureNameString];
    } catch (e) {
        return 0;
    }
};

export function getEkycConfig(): boolean {
    try {
        return getOperatorConfigValue('ekyc', false);
    } catch (e) {
        return false;
    }
}

export function getBillingVersion(): number {
    return getFeatureVersion('BillingStatement');
}

export function getIsEmailCaseSensitive(): boolean {
    try {
        return getOperatorConfigValue('service_config.lf-user.is_email_case_sensitive', false);
    } catch (e) {
        return false;
    }
}

export function getUserConsentsDefault(): CONSENT_CONTEXT_TYPE[] {
    try {
        return getOperatorConfigValue('userConsentsDefault');
    } catch (e) {
        return [];
    }
}

export function getUserDetailFields(): USER_DETAIL_FIELD[] {
    try {
        return getOperatorConfigValue('userDetailFields', DefaultOperatorConfig.userDetailFields);
    } catch (e) {
        return [];
    }
}

export function getAccountDetailFields(): ACCOUNT_DETAIL_FIELD[] {
    try {
        return getOperatorConfigValue('accountDetailFields', DefaultOperatorConfig.accountDetailFields);
    } catch (e) {
        return [];
    }
}

export function getAccountConsentFields(): CONSENT_CONTEXT_TYPE[] {
    try {
        return getOperatorConfigValue('accountConsentFields', DefaultOperatorConfig.accountConsentFields);
    } catch (e) {
        return [];
    }
}

export function getSubscriberDetailFields(): SUBSCRIBER_DETAIL_FIELD[] {
    try {
        return getOperatorConfigValue('subscriberDetailFields', DefaultOperatorConfig.subscriberDetailFields);
    } catch (e) {
        return [];
    }
}

/**
 *
 * @returns {string|boolean}
 */
export function getUserVerificationIdType(): string | boolean {
    try {
        return getOperatorConfig()?.userVerificationIdType || DefaultOperatorConfig.userVerificationIdType;
    } catch (e) {
        return false;
    }
}

export function hideTransactionsUnpaidCanceled() {
    try {
        return (
            getOperatorConfig()?.hideTransactionsUnpaidCanceled || DefaultOperatorConfig.hideTransactionsUnpaidCanceled
        );
    } catch (e) {
        return false;
    }
}

function getTenantFeatureMap() {
    const companyId = getCurrentCompanyId();
    const loadedFeatures = store.getters[`${Modules.config}/getFeatures`]?.features?.[companyId];
    return loadedFeatures || {};
}

function getOperatorsUserFields() {
    return getOperatorConfigValue('userFieldsMap', {});
}

type ValueOf<T> = T[keyof T];

type TargetType = ValueOf<typeof USER_MANAGER_HIERARCHY>;
type AllowedAddressTypes = ValueOf<typeof ADDRESS_TYPE>[];

type AddressType = ValueOf<typeof ADDRESS_TYPE>;
type AddressField = keyof typeof ADDRESS_FIELDS;
type AddressFieldValue = ValueOf<typeof ADDRESS_FIELDS>;
type AddressFieldsMap = Partial<{
    [key: string]: {
        enabled: boolean;
        optional: boolean;
    };
}>;

function getOperatorAllowedAddressTypes(targetType: TargetType): AllowedAddressTypes {
    const addressConfigBe = getOperatorConfigValue('service_config.lf-user.address_management');
    const addressTypes: Map<TargetType, Set<AddressType>> = new Map();
    if (addressConfigBe) {
        for (const addressTypeMap of addressConfigBe?.enabled_target_types_for_address_types) {
            for (const currentTargetType of addressTypeMap.target_types) {
                const addressType = addressTypes.get(currentTargetType);
                if (!addressType) {
                    addressTypes.set(currentTargetType, new Set([addressTypeMap.address_type]));
                } else {
                    addressTypes.set(currentTargetType, addressType.add(addressTypeMap.address_type));
                }
            }
        }
    }

    const addressType = addressTypes.get(targetType);
    if (addressType) {
        return [...addressType];
    }

    return DefaultOperatorConfig.allowedAddressTypes as AllowedAddressTypes;
}

function getOperatorsAddressFieldsByAddressType(type: AddressType): AddressFieldsMap {
    // get operator address config from DNO and remap
    const addressConfigBe = getOperatorConfigValue('service_config.lf-user.address_management');
    const addressConfigs: Record<AddressType, AddressFieldsMap> = {};
    if (addressConfigBe) {
        const addressTypes: AddressType[] = addressConfigBe?.enabled_target_types_for_address_types?.map(
            (address: { address_type: AddressType }) => address.address_type,
        );
        const addressFields = Object.keys(addressConfigBe?.fields) as AddressFieldValue[];
        for (const addressType of addressTypes) {
            const fieldsForType: AddressFieldsMap = {};
            const requiredFields = addressConfigBe?.required_fields?.find(
                (req: { address_type: number }) => req.address_type === addressType,
            )?.required_fields;
            for (const addressField of addressFields) {
                fieldsForType[addressField] = {
                    enabled: true,
                    optional: !requiredFields?.includes(addressField),
                };
            }
            addressConfigs[addressType] = fieldsForType;
        }
    }
    const addressConfig: AddressFieldsMap = addressConfigs[type];
    if (!isEmpty(addressConfig)) {
        // fields returned by DNO operator config are not consistently ordered
        // sort them according to the order stored in `addressFieldsOrder` of operator configs on Portal side
        const addressFieldsOrder: AddressField[] = getOperatorConfigValue(
            `addressFieldsOrder.${type}`,
            DefaultOperatorConfig.addressFieldsOrder,
        );
        const sortedKeys = (Object.keys(addressConfig) as AddressField[]).sort(
            (a, b) => addressFieldsOrder.indexOf(a) - addressFieldsOrder.indexOf(b),
        );
        const sortedAddressConfig: AddressFieldsMap = {};
        for (const key of sortedKeys) {
            sortedAddressConfig[key] = addressConfig[key];
        }
        return sortedAddressConfig;
    }
    return DefaultOperatorConfig.addressFieldsMap as AddressFieldsMap;
}

function getOperatorsAddressFields(): ADDRESS_FIELDS[] {
    const addressConfigBe = getOperatorConfigValue('service_config.lf-user.address_management.fields', {});
    return Object.keys({ ...addressConfigBe });
}

export function getOperatorMaxWalletAmountAfterTopUp() {
    try {
        return getOperatorConfigValue('userManagerMaxWalletAmountAfterTopUp.maxAmount', false);
    } catch (e) {
        return false;
    }
}

function productCatalogEnabled() {
    if (isViewConfig()) {
        return true;
    }
    try {
        return (
            getTenantFeatureMap()?.ProductCatalog?.enabled &&
            Account.storedInstance.permissions.includes(permissionGroups.productCatalog.read.key)
        );
    } catch (e) {
        return false;
    }
}

function productCatalogOffersEnabled() {
    if (isViewConfig()) {
        return true;
    }
    try {
        return (
            getTenantFeatureMap() &&
            getTenantFeatureMap().ProductCatalog.enabled &&
            getTenantFeatureMap().ProductCatalog.features.Offers.enabled &&
            Account.storedInstance.permissions.includes(permissionGroups.productCatalog.read.key)
        );
    } catch (e) {
        return false;
    }
}

function productCatalogCategoriesEnabled() {
    if (isViewConfig()) {
        return true;
    }
    try {
        return (
            getTenantFeatureMap() &&
            getTenantFeatureMap().ProductCatalog.enabled &&
            getTenantFeatureMap().ProductCatalog.features.Categories.enabled &&
            Account.storedInstance.permissions.includes(permissionGroups.productCatalog.read.key)
        );
    } catch (e) {
        return false;
    }
}

function productCatalogFacetsEnabled() {
    if (isViewConfig()) {
        return true;
    }
    try {
        return (
            getTenantFeatureMap() &&
            getTenantFeatureMap().ProductCatalog.enabled &&
            getTenantFeatureMap().ProductCatalog.features.Facets.enabled &&
            Account.storedInstance.permissions.includes(permissionGroups.productCatalog.read.key) &&
            isUserLotusFlareAdmin()
        );
    } catch (e) {
        return false;
    }
}

function productCatalogProductsEnabled() {
    if (isViewConfig()) {
        return true;
    }
    try {
        return (
            getTenantFeatureMap() &&
            getTenantFeatureMap().ProductCatalog.enabled &&
            getTenantFeatureMap().ProductCatalog.features.Products.enabled &&
            Account.storedInstance.permissions.includes(permissionGroups.productCatalog.read.key)
        );
    } catch (e) {
        return false;
    }
}

function productCatalogServicesEnabled() {
    if (isViewConfig()) {
        return true;
    }
    try {
        return (
            getTenantFeatureMap() &&
            getTenantFeatureMap().ProductCatalog.enabled &&
            getTenantFeatureMap().ProductCatalog.features.Services.enabled &&
            Account.storedInstance.permissions.includes(permissionGroups.productCatalog.read.key)
        );
    } catch (e) {
        return false;
    }
}

function productCatalogResourcesEnabled() {
    if (isViewConfig()) {
        return true;
    }
    try {
        return (
            getTenantFeatureMap() &&
            getTenantFeatureMap().ProductCatalog.enabled &&
            getTenantFeatureMap().ProductCatalog.features.Resources.enabled &&
            Account.storedInstance.permissions.includes(permissionGroups.productCatalog.read.key)
        );
    } catch (e) {
        return false;
    }
}

function productCatalogEnumsEnabled() {
    if (isViewConfig()) {
        return true;
    }
    try {
        return (
            getTenantFeatureMap() &&
            getTenantFeatureMap().ProductCatalog.enabled &&
            getTenantFeatureMap().ProductCatalog.features.Enums.enabled
        );
    } catch (e) {
        return false;
    }
}

function rewardsEnabled() {
    if (isViewConfig()) {
        return true;
    }
    try {
        return (
            getTenantFeatureMap() &&
            getTenantFeatureMap().Rewards.enabled &&
            Account.storedInstance.permissions.includes(permissionGroups.rewards.read.key)
        );
    } catch (e) {
        return false;
    }
}

function prizeEnabled() {
    if (isViewConfig()) {
        return true;
    }
    try {
        return (
            getTenantFeatureMap() &&
            getTenantFeatureMap().Rewards.enabled &&
            getTenantFeatureMap().Rewards.features.Prize.enabled &&
            Account.storedInstance.permissions.includes(permissionGroups.rewards.read.key)
        );
    } catch (e) {
        return false;
    }
}

function prizeEngineEnabled() {
    if (isViewConfig()) {
        return true;
    }
    try {
        return (
            getTenantFeatureMap() &&
            getTenantFeatureMap().Rewards.enabled &&
            getTenantFeatureMap().Rewards.features.PrizeEngine.enabled &&
            Account.storedInstance.permissions.includes(permissionGroups.rewards.read.key)
        );
    } catch (e) {
        return false;
    }
}

function lotteryEnabled() {
    if (isViewConfig()) {
        return true;
    }
    try {
        return (
            getTenantFeatureMap() &&
            getTenantFeatureMap().Rewards.enabled &&
            getTenantFeatureMap().Rewards.features.Lottery.enabled &&
            Account.storedInstance.permissions.includes(permissionGroups.rewards.read.key)
        );
    } catch (e) {
        return false;
    }
}

function voucherEnabled() {
    if (isViewConfig()) {
        return true;
    }
    try {
        return (
            getTenantFeatureMap() &&
            getTenantFeatureMap().Rewards.enabled &&
            getTenantFeatureMap().Rewards.features.Voucher?.enabled &&
            Account.storedInstance.permissions.includes(permissionGroups.vouchers.write.key)
        );
    } catch (e) {
        return false;
    }
}

function userVoucherEnabled() {
    if (isViewConfig()) {
        return true;
    }
    try {
        return voucherEnabled() && getTenantFeatureMap().Rewards.features.Voucher.features.UserVoucher;
    } catch (e) {
        return false;
    }
}

function accountVoucherEnabled() {
    if (isViewConfig()) {
        return true;
    }
    try {
        return voucherEnabled() && getTenantFeatureMap().Rewards.features.Voucher.features.AccountVoucher;
    } catch (e) {
        return false;
    }
}

function subscriberVoucherEnabled() {
    if (isViewConfig()) {
        return true;
    }
    try {
        return voucherEnabled() && getTenantFeatureMap().Rewards.features.Voucher.features.SubscriberVoucher;
    } catch (e) {
        return false;
    }
}

function payoutEnabled() {
    if (isViewConfig()) {
        return true;
    }
    try {
        return (
            getTenantFeatureMap()?.Rewards?.features?.Payout.enabled &&
            Account.storedInstance.permissions.includes(permissionGroups.rewards.read.key)
        );
    } catch (e) {
        return false;
    }
}

function chargingEnabled() {
    if (isViewConfig()) {
        return true;
    }
    try {
        return (
            getTenantFeatureMap() &&
            getTenantFeatureMap().Charging.enabled &&
            Account.storedInstance.permissions.includes(permissionGroups.chargingManager.read.key)
        );
    } catch (e) {
        return false;
    }
}

function chargingChargingSpecificationsEnabled() {
    if (isViewConfig()) {
        return true;
    }
    try {
        return (
            getTenantFeatureMap() &&
            getTenantFeatureMap().Charging.enabled &&
            Account.storedInstance.permissions.includes(permissionGroups.chargingManager.read.key) &&
            getTenantFeatureMap().Charging.features.ChargingSpecifications.enabled
        );
    } catch (e) {
        return false;
    }
}

function chargingChargingSpecificationsApiTypeEnabled() {
    if (isViewConfig()) {
        return true;
    }
    try {
        return (
            getTenantFeatureMap()?.Charging?.enabled &&
            Account.storedInstance.permissions.includes(permissionGroups.chargingManager.read.key) &&
            getTenantFeatureMap().Charging.features.ChargingSpecificationsApiType.enabled
        );
    } catch (e) {
        return false;
    }
}

function chargingChargingSpecificationsGroupsEnabled() {
    if (isViewConfig()) {
        return true;
    }
    try {
        return (
            getTenantFeatureMap() &&
            getTenantFeatureMap().Charging.enabled &&
            Account.storedInstance.permissions.includes(permissionGroups.chargingManager.read.key) &&
            getTenantFeatureMap().Charging.features.ChargingSpecificationsGroups.enabled
        );
    } catch (e) {
        return false;
    }
}

function chargingPolicyCountersEnabled() {
    if (isViewConfig()) {
        return true;
    }
    try {
        return (
            getTenantFeatureMap() &&
            getTenantFeatureMap().Charging.enabled &&
            Account.storedInstance.permissions.includes(permissionGroups.chargingManager.read.key) &&
            getTenantFeatureMap().Charging.features.PolicyCounters.enabled
        );
    } catch (e) {
        return false;
    }
}

function chargingPolicyRulesEnabled() {
    if (isViewConfig()) {
        return true;
    }
    try {
        return (
            getTenantFeatureMap() &&
            getTenantFeatureMap().Charging.enabled &&
            Account.storedInstance.permissions.includes(permissionGroups.chargingManager.read.key) &&
            getTenantFeatureMap().Charging.features.PolicyRules.enabled
        );
    } catch (e) {
        return false;
    }
}

function chargingRatingGroupsEnabled() {
    if (isViewConfig()) {
        return true;
    }
    try {
        return (
            getTenantFeatureMap() &&
            getTenantFeatureMap().Charging.enabled &&
            getTenantFeatureMap().Charging.features.RatingGroup.enabled &&
            Account.storedInstance.permissions.includes(permissionGroups.chargingManager.read.key)
        );
    } catch (e) {
        return false;
    }
}

function chargingUsageCountersEnabled() {
    if (isViewConfig()) {
        return true;
    }
    try {
        return (
            getTenantFeatureMap() &&
            getTenantFeatureMap().Charging.enabled &&
            getTenantFeatureMap().Charging.features.UsageCounters.enabled &&
            Account.storedInstance.permissions.includes(permissionGroups.chargingManager.read.key)
        );
    } catch (e) {
        return false;
    }
}

function chargingConditionParametersEnabled() {
    if (isViewConfig()) {
        return true;
    }
    try {
        return (
            getTenantFeatureMap() &&
            getTenantFeatureMap().Charging.enabled &&
            Account.storedInstance.permissions.includes(permissionGroups.chargingManager.read.key) &&
            getTenantFeatureMap().Charging.features.ConditionParameters.enabled
        );
    } catch (e) {
        return false;
    }
}

function chargingWalletCountersEnabled() {
    if (isViewConfig()) {
        return true;
    }
    try {
        return (
            getTenantFeatureMap() &&
            getTenantFeatureMap().Charging?.enabled &&
            Account.storedInstance.permissions.includes(permissionGroups.chargingManager.read.key) &&
            getTenantFeatureMap().Charging?.features?.WalletCounters?.enabled
        );
    } catch (e) {
        return false;
    }
}

function chargingContentTypesEnabled() {
    if (isViewConfig()) {
        return true;
    }
    try {
        return (
            getTenantFeatureMap() &&
            getTenantFeatureMap().Charging.enabled &&
            Account.storedInstance.permissions.includes(permissionGroups.chargingManager.read.key) &&
            getTenantFeatureMap().Charging.features.ContentType.enabled
        );
    } catch (e) {
        return false;
    }
}

function chargingTarifSpecificationsEnabled() {
    if (isViewConfig()) {
        return true;
    }
    try {
        return (
            getTenantFeatureMap() &&
            getTenantFeatureMap().Charging.enabled &&
            Account.storedInstance.permissions.includes(permissionGroups.chargingManager.read.key) &&
            getTenantFeatureMap().Charging.features.TariffSpecifications.enabled
        );
    } catch (e) {
        return false;
    }
}

function chargingTarifSpecificationsZeroRateEnabled() {
    if (isViewConfig()) {
        return true;
    }
    try {
        return (
            getTenantFeatureMap() &&
            getTenantFeatureMap().Charging.enabled &&
            Account.storedInstance.permissions.includes(permissionGroups.chargingManager.read.key) &&
            getTenantFeatureMap().Charging.features.TariffSpecificationsZeroRate.enabled
        );
    } catch (e) {
        return false;
    }
}

function chargingTarifSpecificationGroupsEnabled() {
    if (isViewConfig()) {
        return true;
    }
    try {
        return (
            getTenantFeatureMap() &&
            getTenantFeatureMap().Charging.enabled &&
            Account.storedInstance.permissions.includes(permissionGroups.chargingManager.read.key) &&
            getTenantFeatureMap().Charging.features.TariffSpecificationGroups.enabled
        );
    } catch (e) {
        return false;
    }
}

function userManagementEnabled() {
    if (isViewConfig()) {
        return true;
    }
    try {
        return (
            getTenantFeatureMap() &&
            getTenantFeatureMap().UserManager?.enabled &&
            Account.storedInstance.permissions.includes(permissionGroups.userManager.read.key)
        );
    } catch (e) {
        return false;
    }
}

function userManagementUserGrantPermissionsEnabled() {
    if (isViewConfig()) {
        return true;
    }
    try {
        return (
            getTenantFeatureMap() &&
            getTenantFeatureMap().UserManager.features.UserGrantPermission.enabled &&
            Account.storedInstance.permissions.includes(permissionGroups.userManagerGrant.write.key)
        );
    } catch (e) {
        return false;
    }
}

function userManagementUserGroupsEnabled() {
    if (isViewConfig()) {
        return true;
    }
    try {
        return getTenantFeatureMap() && getTenantFeatureMap().UserManager?.features?.UMUserGroups?.enabled;
    } catch (e) {
        return false;
    }
}

function userManagementEditMSISDNEnabled() {
    if (isViewConfig()) {
        return true;
    }
    try {
        return getTenantFeatureMap() && getTenantFeatureMap()?.UserManager?.features?.UMEditMSISDN?.enabled;
    } catch (e) {
        return false;
    }
}

function userManagementPaymentTransactionHistoryEnabled() {
    if (isViewConfig()) {
        return true;
    }
    try {
        return (
            getTenantFeatureMap() &&
            getTenantFeatureMap().UserManager.enabled &&
            getTenantFeatureMap().UserManager.features.PaymentTransactionHistory &&
            Account.storedInstance.permissions.includes(permissionGroups.payment.read.key)
        );
    } catch (e) {
        return false;
    }
}

function paymentTransactionHistoryRefundEnabled() {
    if (isViewConfig()) {
        return true;
    }
    try {
        return (
            getTenantFeatureMap() &&
            getTenantFeatureMap().UserManager &&
            getTenantFeatureMap().UserManager.features.PaymentTransactionHistoryRefundEnabled
        );
    } catch (e) {
        return false;
    }
}

function dataTransferEnabled() {
    if (isViewConfig()) {
        return true;
    }
    try {
        return (
            getTenantFeatureMap() &&
            getTenantFeatureMap().UserManager &&
            getTenantFeatureMap().UserManager.features.DataTransfer
        );
    } catch (e) {
        return false;
    }
}

function userManagementBillingTransactionHistoryEnabled() {
    if (isViewConfig()) {
        return true;
    }
    try {
        return (
            getTenantFeatureMap() &&
            getTenantFeatureMap().UserManager.enabled &&
            getTenantFeatureMap().UserManager.features.BillingTransactionHistory &&
            Account.storedInstance.permissions.includes(permissionGroups.payment.read.key)
        );
    } catch (e) {
        return false;
    }
}

function refundTransactionsVisibleEnabled() {
    if (isViewConfig()) {
        return true;
    }
    try {
        return (
            getTenantFeatureMap() &&
            getTenantFeatureMap().UserManager &&
            getTenantFeatureMap().UserManager.features.BillingTransactionHistory &&
            getTenantFeatureMap().UserManager.features.BillingTransactionHistory.features.RefundTransactionsVisible
        );
    } catch (e) {
        return false;
    }
}

function billingTransactionInvoicesEnabled() {
    if (isViewConfig()) {
        return true;
    }
    try {
        return (
            getTenantFeatureMap() &&
            getTenantFeatureMap().UserManager &&
            getTenantFeatureMap().UserManager.features.BillingTransactionHistory &&
            getTenantFeatureMap().UserManager.features.BillingTransactionHistory.features.InvoiceButtons
        );
    } catch (e) {
        return false;
    }
}

function billingTransactionRefundEnabled() {
    if (isViewConfig()) {
        return true;
    }
    try {
        return (
            getTenantFeatureMap() &&
            getTenantFeatureMap().UserManager &&
            getTenantFeatureMap().UserManager.features.BillingTransactionHistory &&
            getTenantFeatureMap().UserManager.features.BillingTransactionHistory.features.RefundButton
        );
    } catch (e) {
        return false;
    }
}

function userManagementRefundModalChargeBasedEnabled() {
    if (isViewConfig()) {
        return true;
    }
    try {
        return (
            getTenantFeatureMap() &&
            getTenantFeatureMap().UserManager.enabled &&
            getTenantFeatureMap().UserManager.features.RefundModalChargeBased &&
            Account.storedInstance.permissions.includes(permissionGroups.payment.read.key)
        );
    } catch (e) {
        return false;
    }
}

function userManagementSessionsEnabled() {
    if (isViewConfig()) {
        return true;
    }
    try {
        return (
            getTenantFeatureMap() &&
            getTenantFeatureMap().UserManager.enabled &&
            getTenantFeatureMap().UserManager.features.Sessions.enabled &&
            Account.storedInstance.permissions.includes(permissionGroups.userManager.read.key)
        );
    } catch (e) {
        return false;
    }
}

function userManagementCredsHistoryEnabled() {
    if (isViewConfig()) {
        return true;
    }
    try {
        return (
            getTenantFeatureMap() &&
            getTenantFeatureMap().UserManager.enabled &&
            getTenantFeatureMap().UserManager.features.CredentialsHistory.enabled &&
            Account.storedInstance.permissions.includes(permissionGroups.userManager.read.key)
        );
    } catch (e) {
        return false;
    }
}

function userManagementCancelSubscriptionEnabled() {
    if (isViewConfig()) {
        return true;
    }
    try {
        return (
            getTenantFeatureMap() &&
            getTenantFeatureMap().UserManager.enabled &&
            getTenantFeatureMap().UserManager.features.CancelSubscription.enabled &&
            Account.storedInstance.permissions.includes(permissionGroups.userManager.read.key)
        );
    } catch (e) {
        return false;
    }
}

function userManagementCancelSubscriptionUpdateFlagsEnabled() {
    if (isViewConfig()) {
        return true;
    }
    try {
        return (
            getTenantFeatureMap() &&
            getTenantFeatureMap().UserManager.enabled &&
            getTenantFeatureMap().UserManager.features.CancelSubscriptionUpdateFlags.enabled &&
            Account.storedInstance.permissions.includes(permissionGroups.userManager.read.key)
        );
    } catch (e) {
        return false;
    }
}

function userManagementCancelSubscriptionAndDeleteAccountEnabled() {
    if (isViewConfig()) {
        return true;
    }
    try {
        return (
            userManagementCancelSubscriptionEnabled() &&
            getTenantFeatureMap().UserManager.features?.CancelSubscriptionAndDeleteAccount?.enabled &&
            Account.storedInstance.permissions.includes(permissionGroups.userManager.read.key)
        );
    } catch (e) {
        return false;
    }
}

function userManagementCRMEnabled() {
    if (isViewConfig()) {
        return true;
    }
    try {
        return (
            getTenantFeatureMap() &&
            getTenantFeatureMap().UserManager.enabled &&
            getTenantFeatureMap().UserManager.features.CRM.enabled &&
            Account.storedInstance.permissions.includes(permissionGroups.crm.write.key)
        );
    } catch (e) {
        return false;
    }
}

function userManagementOMDevEdgeServiceEnabled() {
    if (isViewConfig()) {
        return true;
    }
    try {
        return (
            getTenantFeatureMap() &&
            getTenantFeatureMap().UserManager.enabled &&
            getTenantFeatureMap().UserManager.features.OMDevEdgeService.enabled &&
            Account.storedInstance.permissions.includes(permissionGroups.omdevedge.read.key)
        );
    } catch (e) {
        return false;
    }
}

function userManagementOutageHistoryEnabled() {
    if (isViewConfig()) {
        return true;
    }
    try {
        return (
            getTenantFeatureMap() &&
            getTenantFeatureMap().UserManager?.enabled &&
            getTenantFeatureMap().UserManager?.features?.OutageHistoryAccount?.enabled &&
            Account.storedInstance.permissions.includes(permissionGroups.osstmofiber.read.key)
        );
    } catch (e) {
        return false;
    }
}

function userManagementServiceInfoEnabled() {
    if (isViewConfig()) {
        return true;
    }
    try {
        return (
            getTenantFeatureMap() &&
            getTenantFeatureMap().UserManager?.enabled &&
            getTenantFeatureMap().UserManager?.features?.ServiceInfoAccount?.enabled &&
            Account.storedInstance.permissions.includes(permissionGroups.osstmofiber.read.key)
        );
    } catch (e) {
        return false;
    }
}

function userManagerDeliveryServiceEnabled() {
    if (isViewConfig()) {
        return true;
    }
    try {
        return (
            getTenantFeatureMap() &&
            getTenantFeatureMap().UserManager.enabled &&
            getTenantFeatureMap().UserManager.features.Deliveries.enabled &&
            Account.storedInstance.permissions.includes(permissionGroups.omdevedge.read.key)
        );
    } catch (e) {
        return false;
    }
}

function userManagementEKYCEnabled() {
    if (isViewConfig()) {
        return true;
    }
    try {
        return (
            getTenantFeatureMap() &&
            getTenantFeatureMap().UserManager.enabled &&
            getTenantFeatureMap().UserManager.features?.EKYC.enabled &&
            Account.storedInstance.permissions.includes(permissionGroups.userManager.read.key)
        );
    } catch (e) {
        return false;
    }
}

function userManagementConsentsEnabled() {
    if (isViewConfig()) {
        return true;
    }
    try {
        return userManagementEnabled() && getTenantFeatureMap().UserManager.features?.Consents?.enabled;
    } catch (e) {
        return false;
    }
}

function userManagementOEExecutionHierarchyEnabled() {
    if (isViewConfig()) {
        return true;
    }
    try {
        return (
            getTenantFeatureMap() &&
            getTenantFeatureMap()?.UserManager?.enabled &&
            getTenantFeatureMap()?.UserManager?.features?.OeExecutionsHierarchy &&
            Account.storedInstance.permissions.includes(permissionGroups.oeExecutionsHierarchy.read.key)
        );
    } catch (e) {
        return false;
    }
}

function subscriberOrdersEnabled() {
    if (isViewConfig()) {
        return true;
    }
    try {
        return (
            getTenantFeatureMap() &&
            getTenantFeatureMap().UserManager.enabled &&
            getTenantFeatureMap().UserManager.features.SubscriberOrders.enabled
        );
    } catch (e) {
        return false;
    }
}

function accountOrdersEnabled() {
    if (isViewConfig()) {
        return true;
    }
    try {
        return (
            getTenantFeatureMap() &&
            getTenantFeatureMap().UserManager?.enabled &&
            getTenantFeatureMap().UserManager?.features?.AccountOrders?.enabled
        );
    } catch (e) {
        return false;
    }
}

function accountOEOrdersEnabled() {
    if (isViewConfig()) {
        return true;
    }
    try {
        return accountOrdersEnabled() && getTenantFeatureMap().UserManager?.features?.AccountOrders?.features?.Oeorders;
    } catch (e) {
        return false;
    }
}

function accountOEPlansEnabled() {
    if (isViewConfig()) {
        return true;
    }
    try {
        return (
            getTenantFeatureMap() &&
            getTenantFeatureMap().UserManager.enabled &&
            getTenantFeatureMap().UserManager.features.Oeplans.enabled
        );
    } catch (e) {
        return false;
    }
}

function accountOrdersSubordersDisabled() {
    if (isViewConfig()) {
        return true;
    }
    try {
        return (
            accountOrdersEnabled() &&
            getTenantFeatureMap().UserManager.features.AccountOrders.features.subordersDisabled
        );
    } catch (e) {
        return false;
    }
}

function accountOrdersFriendlyOrderIdEnabled() {
    if (isViewConfig()) {
        return true;
    }
    try {
        return (
            accountOrdersEnabled() &&
            getTenantFeatureMap().UserManager.features.AccountOrders?.features?.friendlyOrderIdColumn
        );
    } catch (e) {
        return false;
    }
}

function accountOrdersOrderTypeEnabled() {
    if (isViewConfig()) {
        return true;
    }
    try {
        return (
            accountOrdersEnabled() &&
            getTenantFeatureMap().UserManager.features.AccountOrders?.features?.orderTypeColumn
        );
    } catch (e) {
        return false;
    }
}

function numberManagementEnabled() {
    if (isViewConfig()) {
        return true;
    }
    try {
        return (
            getTenantFeatureMap() &&
            getTenantFeatureMap().Operations &&
            getTenantFeatureMap().Operations.features.NumberManagement.enabled &&
            Account.storedInstance.permissions.includes(permissionGroups.numberManagement.read.key)
        );
    } catch (e) {
        return false;
    }
}

function operationsEnabled() {
    if (isViewConfig()) {
        return true;
    }
    try {
        return (
            getTenantFeatureMap() &&
            getTenantFeatureMap().Operations.enabled &&
            Account.storedInstance.permissions.includes(permissionGroups.ops.read.key)
        );
    } catch (e) {
        return false;
    }
}

function simUploadEnabled() {
    if (isViewConfig()) {
        return true;
    }
    try {
        return (
            getTenantFeatureMap() &&
            getTenantFeatureMap().Operations &&
            getTenantFeatureMap().Operations.features.SimUpload.enabled &&
            Account.storedInstance.permissions.includes(permissionGroups.numberManagement.read.key)
        );
    } catch (e) {
        return false;
    }
}

function opsGraphsEnabled() {
    if (isViewConfig()) {
        return true;
    }
    try {
        return (
            getTenantFeatureMap()?.Operations?.features?.Graphs?.enabled &&
            Account.storedInstance.permissions.includes(permissionGroups.ops.read.key)
        );
    } catch (e) {
        return false;
    }
}

function analyticsEnabled() {
    if (isViewConfig()) {
        return true;
    }
    try {
        return (
            getTenantFeatureMap() &&
            getTenantFeatureMap().Analytics.enabled &&
            Account.storedInstance.permissions.includes(permissionGroups.metrics.read.key)
        );
    } catch (e) {
        return false;
    }
}

function analyticsReportsEnabled() {
    if (isViewConfig()) {
        return true;
    }
    try {
        return (
            getTenantFeatureMap() &&
            getTenantFeatureMap()?.Analytics?.enabled &&
            getTenantFeatureMap()?.Analytics?.features?.Reports?.enabled &&
            Account.storedInstance.permissions.includes(permissionGroups.reports.read.key)
        );
    } catch (e) {
        return false;
    }
}

function analyticsORDReportsEnabled() {
    if (isViewConfig()) {
        return true;
    }
    try {
        return analyticsReportsEnabled() && getTenantFeatureMap()?.Analytics?.features?.Reports?.features?.ORD;
    } catch (e) {
        return false;
    }
}

function analyticsQRDReportsEnabled() {
    if (isViewConfig()) {
        return true;
    }
    try {
        return analyticsReportsEnabled() && getTenantFeatureMap()?.Analytics?.features?.Reports?.features?.QRD;
    } catch (e) {
        return false;
    }
}

function segmentsEnabled() {
    if (isViewConfig()) {
        return true;
    }
    try {
        return (
            getTenantFeatureMap() &&
            getTenantFeatureMap().SegmentsCEP.enabled &&
            Account.storedInstance.permissions.includes(permissionGroups.cep.read.key)
        );
    } catch (e) {
        return false;
    }
}

function userGroupsEnabled() {
    if (isViewConfig()) {
        return true;
    }
    try {
        return (
            getTenantFeatureMap() &&
            getTenantFeatureMap().UserGroups.enabled &&
            Account.storedInstance.permissions.includes(permissionGroups.cep.read.key)
        );
    } catch (e) {
        return false;
    }
}

function cepEnabled() {
    if (isViewConfig()) {
        return true;
    }
    try {
        return (
            Account.storedInstance.permissions.includes(permissionGroups.cep.read.key) &&
            getTenantFeatureMap() &&
            getTenantFeatureMap().CEP.enabled
        );
    } catch (e) {
        return false;
    }
}

function cepCampaignsEnabled() {
    if (isViewConfig()) {
        return true;
    }
    try {
        return (
            Account.storedInstance.permissions.includes(permissionGroups.cepCampaigns.read.key) &&
            getTenantFeatureMap() &&
            getTenantFeatureMap().CEP.enabled &&
            getTenantFeatureMap().CEP.features.Campaigns.enabled
        );
    } catch (e) {
        return false;
    }
}

function cepCampaignsWriteEnabled() {
    if (isViewConfig()) {
        return true;
    }
    try {
        return (
            Account.storedInstance.permissions.includes(permissionGroups.cepCampaigns.write.key) &&
            getTenantFeatureMap() &&
            getTenantFeatureMap().CEP.enabled &&
            getTenantFeatureMap().CEP.features.Campaigns.enabled
        );
    } catch (e) {
        return false;
    }
}

function userManagementAccountTerminationEnabled() {
    if (isViewConfig()) {
        return true;
    }
    try {
        return (
            getTenantFeatureMap() &&
            getTenantFeatureMap().UserManager.enabled &&
            getTenantFeatureMap().UserManager.features.AccountTermination.enabled &&
            Account.storedInstance.permissions.includes(permissionGroups.userManager.write.key) &&
            Account.storedInstance.permissions.includes(permissionGroups.orderManagement.write.key)
        );
    } catch (e) {
        return false;
    }
}

function userManagementAccountExternalDataEnabled() {
    if (isViewConfig()) {
        return true;
    }
    try {
        return Account.storedInstance.permissions.includes(permissionGroups.ossgpfiber.read.key);
    } catch (e) {
        return false;
    }
}

function userManagementTerminationEnabled() {
    if (isViewConfig()) {
        return true;
    }
    try {
        return (
            getTenantFeatureMap() &&
            getTenantFeatureMap().UserManager.enabled &&
            getTenantFeatureMap().UserManager.features.Termination.enabled &&
            Account.storedInstance.permissions.includes(permissionGroups.userManager.write.key) &&
            Account.storedInstance.permissions.includes(permissionGroups.orderManagement.write.key)
        );
    } catch (e) {
        return false;
    }
}

function userManagementCaseSensitiveEmailsEnabled() {
    if (isViewConfig()) {
        return true;
    }
    try {
        return (
            getTenantFeatureMap() &&
            getTenantFeatureMap().UserManager.enabled &&
            getTenantFeatureMap().UserManager.features.CaseSensitiveEmailSearch.enabled
        );
    } catch (e) {
        return false;
    }
}

function userManagementSearchMultipleResultsEnabled() {
    if (isViewConfig()) {
        return true;
    }
    try {
        return userManagementEnabled() && getTenantFeatureMap().UserManager?.features?.SearchMultipleResults?.enabled;
    } catch (e) {
        return false;
    }
}

function messageTypesAvailable(): string[] {
    const MessageTypes: Record<string, boolean> = {
        Push: true,
        Ott: true,
        Sms: true,
        Email: true,
    };
    if (isViewConfig()) {
        return Object.keys(MessageTypes).map(key => key.toLowerCase());
    }
    try {
        const resultArray: string[] = [];
        if (
            getTenantFeatureMap() &&
            getTenantFeatureMap().CEP.enabled &&
            getTenantFeatureMap().CEP.features.Campaigns.enabled
        ) {
            Object.entries(getTenantFeatureMap().CEP.features.Campaigns.features).forEach(entry => {
                if (entry[1] && MessageTypes[entry[0]]) {
                    // Lowercase first letter because it use them like that further in the code.
                    const feature = (entry[0] && entry[0][0].toLowerCase() + entry[0].slice(1)) || entry[0];
                    resultArray.push(feature);
                }
            });
        }
        return resultArray;
    } catch (e) {
        return [];
    }
}

function topUpFeaturesAvailable() {
    try {
        // TODO: Consider do we need customercare check here. This feature might be in places outside it as well.
        return userManagementEnabled() && Account.storedInstance.permissions.includes(permissionGroups.topUp.write.key);
    } catch (e) {
        return false;
    }
}

/**
 * Really descriptive name am I right?
 * @return {*|boolean}
 */
function balanceAdjustmentWalletConfigurationEnabled() {
    if (isViewConfig()) {
        return true;
    }
    try {
        return (
            Account.storedInstance.permissions.includes(permissionGroups.admin.read.key) && // Admin user
            Account.storedInstance.permissions.includes(permissionGroups.topUp.write.key) && // Has topup permisison
            Account.storedInstance.companyName === LF_TELECOM_TENANT_NAME
        ); // Is LF user
    } catch (e) {
        return false;
    }
}

function userManagementEditEnabled() {
    if (isViewConfig()) {
        return true;
    }
    try {
        return (
            getTenantFeatureMap() &&
            getTenantFeatureMap().UserManager.enabled &&
            getTenantFeatureMap().UserManager.features.Edit.enabled &&
            Account.storedInstance.permissions.includes(permissionGroups.userManager.write.key)
        );
    } catch (e) {
        return false;
    }
}

function isCeRoleAndAdmin() {
    if (isViewConfig()) {
        return true;
    }
    try {
        return (
            getTenantFeatureMap() &&
            getTenantFeatureMap().CEP.enabled &&
            Account.storedInstance.permissions.includes(permissionGroups.cep.write.key) &&
            isUserAdmin()
        );
    } catch (e) {
        return false;
    }
}

function isCepReadRole() {
    if (isViewConfig()) {
        return true;
    }
    try {
        return Account.storedInstance.permissions.includes(permissionGroups.cep.read.key);
    } catch (e) {
        return false;
    }
}

function campaignReachBlockEnabled() {
    if (isViewConfig()) {
        return true;
    }
    try {
        return (
            getTenantFeatureMap() &&
            getTenantFeatureMap().CEP.enabled &&
            getTenantFeatureMap().CEP.features.Campaigns.enabled
        );
    } catch (e) {
        return false;
    }
}

function realTimeSegmentsEnabled() {
    if (isViewConfig()) {
        return true;
    }
    try {
        return (
            (getTenantFeatureMap() &&
                getTenantFeatureMap().CEP.enabled &&
                getTenantFeatureMap().CEP.features.Segments.enabled &&
                getTenantFeatureMap().CEP.features.Segments.features.RealTimeSegments) ||
            isCeRoleAndAdmin()
        );
    } catch (e) {
        return false;
    }
}
function dynamicSegmentsEnabled() {
    if (isViewConfig()) {
        return true;
    }
    try {
        return (
            getTenantFeatureMap() &&
            getTenantFeatureMap().CEP.enabled &&
            getTenantFeatureMap().CEP.features.Segments.enabled &&
            getTenantFeatureMap().CEP.features.Segments.features.DynamicSegments
        );
    } catch (e) {
        return false;
    }
}

const quietHoursEnabled = isCeRoleAndAdmin;

function offersTaxesEnabled() {
    if (isViewConfig()) {
        return true;
    }
    try {
        return (
            getTenantFeatureMap() &&
            getTenantFeatureMap().ProductCatalog &&
            getTenantFeatureMap().ProductCatalog.features.Offers &&
            getTenantFeatureMap().ProductCatalog.features.Offers.features.Tax
        );
    } catch (e) {
        return false;
    }
}

/**
 * @return {*|boolean}
 */
function taxWriteEnabled() {
    if (isViewConfig()) {
        return true;
    }
    try {
        return Account.storedInstance.permissions.includes(permissionGroups.payment.write.key) && offersTaxesEnabled();
    } catch (e) {
        return false;
    }
}

function taxScrubbingEnabled() {
    if (isViewConfig()) {
        return true;
    }
    try {
        return (
            getTenantFeatureMap() &&
            operationsEnabled() &&
            Account.storedInstance.permissions.includes(permissionGroups.tax.read.key) &&
            getTenantFeatureMap().Operations?.features?.TaxScrubbing?.enabled
        );
    } catch (e) {
        return false;
    }
}

function availableStaticSegmentTypes() {
    try {
        const externalIdTypesSpecified = userGroupsEnabled() && getOperatorConfig()?.staticSegmentExternalIdTypes;
        return externalIdTypesSpecified || DefaultOperatorConfig.staticSegmentExternalIdTypes;
    } catch (e) {
        return [];
    }
}

function availableCampaignDeliveryTypes(campaignType) {
    try {
        const externalDeliveryTypes = getOperatorConfig()?.[`cep${campaignType}DeliveryTypes`];
        return externalDeliveryTypes || DefaultOperatorConfig[`cep${campaignType}DeliveryTypes`];
    } catch (e) {
        return [];
    }
}

/**
 * Check if user has access to Promotions module
 * @return {*|boolean|boolean}
 */
function promotionsEnabled() {
    if (isViewConfig()) {
        return true;
    }
    try {
        return (
            getTenantFeatureMap() &&
            getTenantFeatureMap().Promotions?.enabled &&
            Account.storedInstance.permissions.includes(permissionGroups.promotion.read.key)
        );
    } catch (e) {
        return false;
    }
}

function productCatalogWrite() {
    if (isViewConfig()) {
        return true;
    }
    return Account.storedInstance.permissions.includes(permissionGroups.productCatalog.write.key);
}

function subscriberLevelEnabled() {
    if (isViewConfig()) {
        return true;
    }
    try {
        return (
            getTenantFeatureMap() &&
            getTenantFeatureMap().UserManager &&
            getTenantFeatureMap().UserManager.features &&
            getTenantFeatureMap().UserManager.features.Subscriber.enabled
        );
    } catch (e) {
        return false;
    }
}

function orchestrationEngineEnabled() {
    if (isViewConfig()) {
        return true;
    }
    try {
        return (
            getTenantFeatureMap() &&
            getTenantFeatureMap().OrchestrationEngine &&
            getTenantFeatureMap().OrchestrationEngine.enabled &&
            Account.storedInstance.permissions.includes(permissionGroups.orchestration.read.key)
        );
    } catch (e) {
        return false;
    }
}

function orchestrationEnginePlansEnabled() {
    if (isViewConfig()) {
        return true;
    }
    try {
        return (
            getTenantFeatureMap() &&
            getTenantFeatureMap().OrchestrationEngine.enabled &&
            getTenantFeatureMap().OrchestrationEngine.features?.Plans.enabled &&
            Account.storedInstance.permissions.includes(permissionGroups.orchestration.read.key)
        );
    } catch (e) {
        return false;
    }
}

function orchestrationEngineTemplatesEnabled() {
    if (isViewConfig()) {
        return true;
    }
    try {
        return (
            getTenantFeatureMap() &&
            getTenantFeatureMap().OrchestrationEngine.enabled &&
            getTenantFeatureMap().OrchestrationEngine.features?.Templates.enabled &&
            Account.storedInstance.permissions.includes(permissionGroups.orchestration.read.key)
        );
    } catch (e) {
        return false;
    }
}

function orchestrationEngineMappedExecutionsSearchEnabled() {
    if (isViewConfig()) {
        return true;
    }
    try {
        return (
            getTenantFeatureMap() &&
            getTenantFeatureMap().OrchestrationEngine?.enabled &&
            getTenantFeatureMap().OrchestrationEngine?.features?.MappedExecutionsSearch?.enabled &&
            Account.storedInstance.permissions.includes(permissionGroups.orchestration.read.key)
        );
    } catch (e) {
        return false;
    }
}

function orchestrationEnginePausedExecutionsEnabled() {
    if (isViewConfig()) {
        return true;
    }
    try {
        return (
            getTenantFeatureMap() &&
            getTenantFeatureMap().OrchestrationEngine?.enabled &&
            getTenantFeatureMap().OrchestrationEngine?.features?.PausedExecutions?.enabled &&
            Account.storedInstance.permissions.includes(permissionGroups.orchestration.read.key)
        );
    } catch (e) {
        return false;
    }
}

function userManagementBillingStatementsEnabled() {
    if (isViewConfig()) {
        return true;
    }
    try {
        return (
            getTenantFeatureMap() &&
            getTenantFeatureMap().UserManager.enabled &&
            getTenantFeatureMap().UserManager.features.BillingStatements.enabled &&
            Account.storedInstance.permissions.includes(permissionGroups.billingStatements.read.key)
        );
    } catch (e) {
        return false;
    }
}

function userManagementUserAuthenticationEnabled() {
    if (isViewConfig()) {
        return true;
    }
    try {
        return (
            getTenantFeatureMap() &&
            getTenantFeatureMap().UserManager.enabled &&
            getTenantFeatureMap().UserManager.features?.UserAuthentication?.enabled
        );
    } catch (e) {
        return false;
    }
}

function userManagementEditVerificationIdsClearEnabled() {
    if (isViewConfig()) {
        return true;
    }
    try {
        return Account.storedInstance.permissions.includes(
            permissionGroups.userManagerEditVerificationIdentifier.read.key,
        );
    } catch (e) {
        return false;
    }
}

function userManagementEditVerificationIdsSetEnabled() {
    if (isViewConfig()) {
        return true;
    }
    try {
        return Account.storedInstance.permissions.includes(
            permissionGroups.userManagerEditVerificationIdentifier.write.key,
        );
    } catch (e) {
        return false;
    }
}

function userManagementEditWithoutVerificationEnabled() {
    if (isViewConfig()) {
        return true;
    }
    try {
        return (
            Account.storedInstance.permissions.includes(permissionGroups.userManagerEditWithoutVerification.read.key) ||
            Account.storedInstance.permissions.includes(permissionGroups.userManagerEditWithoutVerification.write.key)
        );
    } catch (e) {
        return false;
    }
}

function userManagementAccountBalanceOverviewEnabled() {
    if (isViewConfig()) {
        return true;
    }
    try {
        return (
            getTenantFeatureMap()?.UserManager?.enabled &&
            getTenantFeatureMap()?.UserManager.features?.BalanceOverviewAccount?.enabled
        );
    } catch (e) {
        return false;
    }
}

function eSIMEnabled() {
    if (isViewConfig()) {
        return true;
    }
    try {
        return (
            getTenantFeatureMap() &&
            getTenantFeatureMap().ESIM &&
            getTenantFeatureMap().ESIM.enabled &&
            Account.storedInstance.permissions.includes(permissionGroups.esim.read.key)
        );
    } catch (e) {
        return false;
    }
}

function getSegmentIdTypes() {
    try {
        return getOperatorConfig()?.cepSegmentIdTypes || DefaultOperatorConfig.cepSegmentIdTypes;
    } catch (e) {
        return [SEGMENT_SUPPORTED_ID_TYPES_BY_KEYS.Subscriber];
    }
}

/**
 *
 * @returns {boolean|*|boolean}
 */
function userManagerOrgsEnabled() {
    if (isViewConfig()) {
        return true;
    }
    try {
        return (
            getTenantFeatureMap() &&
            getTenantFeatureMap().UserManager.enabled &&
            getTenantFeatureMap().UserManager.features.Organizations.enabled &&
            isUserLotusFlareAdmin()
        );
    } catch (e) {
        return false;
    }
}

/**
 *
 * @returns {boolean|*|boolean}
 */
function accountAppointmentsEnabled() {
    if (isViewConfig()) {
        return true;
    }
    try {
        return (
            getTenantFeatureMap() &&
            getTenantFeatureMap().UserManager.enabled &&
            getTenantFeatureMap().UserManager.features.Appointments.enabled
        );
    } catch (e) {
        return false;
    }
}
/**
 *
 * @returns {boolean|*|boolean}
 */
function accountAppointmentCRUDInstallAlways() {
    if (isViewConfig()) {
        return true;
    }
    try {
        return (
            Account.storedInstance.permissions.includes(permissionGroups.appointmentCRUDInstall.read.key) ||
            Account.storedInstance.permissions.includes(permissionGroups.appointmentCRUDInstall.write.key)
        );
    } catch (e) {
        return false;
    }
}

/**
 *
 * @returns {boolean|*|boolean}
 */
function accountAppointmentCRUDMaintenanceAlways() {
    if (isViewConfig()) {
        return true;
    }
    try {
        return (
            Account.storedInstance.permissions.includes(permissionGroups.appointmentCRUDMaintenance.read.key) ||
            Account.storedInstance.permissions.includes(permissionGroups.appointmentCRUDMaintenance.write.key)
        );
    } catch (e) {
        return false;
    }
}

/**
 *
 * @returns {boolean|*|boolean}
 */
function accountWalletTopUpEnabled() {
    if (isViewConfig()) {
        return true;
    }
    try {
        return (
            getTenantFeatureMap() &&
            getTenantFeatureMap().UserManager.enabled &&
            getTenantFeatureMap().UserManager.features.AccountWalletTopUp.enabled
        );
    } catch (e) {
        return false;
    }
}

/**
 *
 * @returns {boolean|*|boolean}
 */
function subscriberUnmaskedCdrEnabled() {
    if (isViewConfig()) {
        return true;
    }
    try {
        return (
            Account.storedInstance.permissions.includes(permissionGroups.chargingTransactionUnmasked.read.key) ||
            Account.storedInstance.permissions.includes(permissionGroups.chargingTransactionUnmasked.write.key)
        );
    } catch (e) {
        return false;
    }
}

/**
 *
 * @returns {boolean|*|boolean}
 */
function subscriberWalletTopUpEnabled() {
    if (isViewConfig()) {
        return true;
    }
    try {
        return (
            getTenantFeatureMap() &&
            getTenantFeatureMap().UserManager.enabled &&
            getTenantFeatureMap().UserManager.features.SubscriberWalletTopUp.enabled
        );
    } catch (e) {
        return false;
    }
}

/**
 *
 * @returns {boolean|*|boolean}
 */
function editSubscriberDetailsPageEnabled() {
    if (isViewConfig()) {
        return true;
    }
    try {
        return (
            getTenantFeatureMap() &&
            getTenantFeatureMap().UserManager.enabled &&
            getTenantFeatureMap().UserManager.features.EditSubscriberDetailsPage.enabled
        );
    } catch (e) {
        return false;
    }
}

function rewardsHistoryUserProfileEnabled() {
    if (isViewConfig()) {
        return true;
    }
    try {
        return userManagementEnabled() && getTenantFeatureMap().UserManager.features?.RewardsHistoryUser?.enabled;
    } catch (e) {
        return false;
    }
}

function rewardsHistoryAccountDetailsEnabled() {
    if (isViewConfig()) {
        return true;
    }
    try {
        return userManagementEnabled() && getTenantFeatureMap().UserManager.features?.RewardsHistoryAccount?.enabled;
    } catch (e) {
        return false;
    }
}

function rewardsHistorySubscriberDetailsEnabled() {
    if (isViewConfig()) {
        return true;
    }
    try {
        return userManagementEnabled() && getTenantFeatureMap().UserManager.features?.RewardsHistorySubscriber?.enabled;
    } catch (e) {
        return false;
    }
}

/**
 *
 * @returns {boolean|*|boolean}
 */
function changePlanEnabled() {
    if (isViewConfig()) {
        return true;
    }
    try {
        return (
            getTenantFeatureMap() &&
            getTenantFeatureMap().UserManager.enabled &&
            getTenantFeatureMap().UserManager.features.ChangePlanEnabled.enabled
        );
    } catch (e) {
        return false;
    }
}

/**
 *
 * @returns {boolean|*|boolean}
 */
function dataHubEnabled() {
    if (isViewConfig()) {
        return true;
    }
    try {
        return getTenantFeatureMap() && getTenantFeatureMap()?.DataHub?.enabled && isUserLotusFlareAdmin();
    } catch (e) {
        return false;
    }
}

function pricingAndFeesEnabled() {
    if (isViewConfig()) {
        return true;
    }
    try {
        return (
            getTenantFeatureMap() &&
            getTenantFeatureMap()?.PricingAndFees?.enabled &&
            Account.storedInstance.permissions.includes(permissionGroups.pricing.read.key)
        );
    } catch (e) {
        return false;
    }
}

function feeRulesEnabled() {
    if (isViewConfig()) {
        return true;
    }
    try {
        return (
            getTenantFeatureMap() &&
            getTenantFeatureMap()?.PricingAndFees?.enabled &&
            getTenantFeatureMap()?.PricingAndFees?.features.FeeRules.enabled &&
            Account.storedInstance.permissions.includes(permissionGroups.pricing.read.key)
        );
    } catch (e) {
        return false;
    }
}

function feeRulesEditorEnabled() {
    if (isViewConfig()) {
        return true;
    }
    try {
        return (
            getTenantFeatureMap() &&
            getTenantFeatureMap()?.PricingAndFees?.enabled &&
            getTenantFeatureMap()?.PricingAndFees?.features.FeeRules.enabled &&
            Account.storedInstance.permissions.includes(permissionGroups.pricing.write.key)
        );
    } catch (e) {
        return false;
    }
}

function userManagerActivateServiceEnabled() {
    if (isViewConfig()) {
        return true;
    }
    try {
        return (
            userManagementEnabled() &&
            Account.storedInstance.permissions.includes(permissionGroups.accountActivateService.write.key)
        );
    } catch (e) {
        return false;
    }
}

export default {
    isFeatureFlagEnabled,
    isRewardsDraftEnabled,
    isBulkeditCoreFieldsEnabled,
    isUserLotusFlareAdmin,
    isUserAdmin,
    isUserAllowed,
    productCatalogEnabled,
    productCatalogWrite,
    productCatalogOffersEnabled,
    productCatalogCategoriesEnabled,
    productCatalogProductsEnabled,
    productCatalogServicesEnabled,
    productCatalogResourcesEnabled,
    productCatalogEnumsEnabled,
    productCatalogFacetsEnabled,
    chargingRatingGroupsEnabled,
    chargingUsageCountersEnabled,
    rewardsEnabled,
    prizeEnabled,
    prizeEngineEnabled,
    lotteryEnabled,
    voucherEnabled,
    userVoucherEnabled,
    accountVoucherEnabled,
    subscriberVoucherEnabled,
    payoutEnabled,
    chargingEnabled,
    chargingPolicyCountersEnabled,
    chargingPolicyRulesEnabled,
    chargingChargingSpecificationsEnabled,
    chargingChargingSpecificationsApiTypeEnabled,
    chargingChargingSpecificationsGroupsEnabled,
    chargingConditionParametersEnabled,
    chargingWalletCountersEnabled,
    chargingContentTypesEnabled,
    chargingTarifSpecificationsEnabled,
    chargingTarifSpecificationsZeroRateEnabled,
    chargingTarifSpecificationGroupsEnabled,
    userManagementEnabled,
    userManagementUserGrantPermissionsEnabled,
    userManagementPaymentTransactionHistoryEnabled,
    paymentTransactionHistoryRefundEnabled,
    userManagementBillingTransactionHistoryEnabled,
    refundTransactionsVisibleEnabled,
    billingTransactionInvoicesEnabled,
    billingTransactionRefundEnabled,
    userManagementRefundModalChargeBasedEnabled,
    userManagementSessionsEnabled,
    userManagementCredsHistoryEnabled,
    userManagementCancelSubscriptionEnabled,
    userManagementCancelSubscriptionUpdateFlagsEnabled,
    userManagementCancelSubscriptionAndDeleteAccountEnabled,
    userManagementCRMEnabled,
    accountOrdersEnabled,
    accountOEOrdersEnabled,
    accountOEPlansEnabled,
    accountOrdersSubordersDisabled,
    accountOrdersFriendlyOrderIdEnabled,
    accountOrdersOrderTypeEnabled,
    numberManagementEnabled,
    operationsEnabled,
    simUploadEnabled,
    opsGraphsEnabled,
    analyticsEnabled,
    analyticsReportsEnabled,
    analyticsORDReportsEnabled,
    analyticsQRDReportsEnabled,
    segmentsEnabled,
    userGroupsEnabled,
    cepEnabled,
    cepCampaignsEnabled,
    cepCampaignsWriteEnabled,
    userManagementAccountTerminationEnabled,
    userManagementAccountExternalDataEnabled,
    userManagementTerminationEnabled,
    userManagementCaseSensitiveEmailsEnabled,
    userManagementSearchMultipleResultsEnabled,
    messageTypesAvailable,
    topUpFeaturesAvailable,
    userManagementEditEnabled,
    balanceAdjustmentWalletConfigurationEnabled,
    quietHoursEnabled,
    campaignReachBlockEnabled,
    realTimeSegmentsEnabled,
    dynamicSegmentsEnabled,
    offersTaxesEnabled,
    taxWriteEnabled,
    taxScrubbingEnabled,
    availableStaticSegmentTypes,
    promotionsEnabled,
    subscriberLevelEnabled,
    orchestrationEngineEnabled,
    orchestrationEnginePlansEnabled,
    orchestrationEngineTemplatesEnabled,
    orchestrationEngineMappedExecutionsSearchEnabled,
    orchestrationEnginePausedExecutionsEnabled,
    userManagementBillingStatementsEnabled,
    eSIMEnabled,
    getSegmentIdTypes,
    changePlanEnabled,
    userManagementOMDevEdgeServiceEnabled,
    userManagementOutageHistoryEnabled,
    userManagementServiceInfoEnabled,
    userManagerDeliveryServiceEnabled,
    userManagerOrgsEnabled,
    accountAppointmentsEnabled,
    accountAppointmentCRUDInstallAlways,
    accountAppointmentCRUDInstallAlways,
    accountAppointmentCRUDMaintenanceAlways,
    accountWalletTopUpEnabled,
    subscriberWalletTopUpEnabled,
    userManagementEKYCEnabled,
    userManagementConsentsEnabled,
    userManagementUserAuthenticationEnabled,
    userManagementEditVerificationIdsClearEnabled,
    userManagementEditVerificationIdsSetEnabled,
    userManagementEditWithoutVerificationEnabled,
    userManagementAccountBalanceOverviewEnabled,
    rewardsHistoryUserProfileEnabled,
    rewardsHistoryAccountDetailsEnabled,
    rewardsHistorySubscriberDetailsEnabled,
    editSubscriberDetailsPageEnabled,
    subscriberUnmaskedCdrEnabled,
    subscriberOrdersEnabled,
    dataHubEnabled,
    isCepReadRole,
    getOperatorsUserFields,
    getOperatorsAddressFieldsByAddressType,
    getOperatorsAddressFields,
    getOperatorAllowedAddressTypes,
    pricingAndFeesEnabled,
    feeRulesEnabled,
    feeRulesEditorEnabled,
    userManagementOEExecutionHierarchyEnabled,
    dataTransferEnabled,
    availableCampaignDeliveryTypes,
    userManagementUserGroupsEnabled,
    userManagerActivateServiceEnabled,
    userManagementEditMSISDNEnabled,
};
