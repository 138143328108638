import { AxiosPromise } from 'axios';
import http from '@/http/index';

export function getChargesForBillingAccount(billingAccountId: number, unprocessedOnly?: boolean): AxiosPromise<any> {
    return http({
        method: 'GET',
        url: `/charge?billingAccountId=${billingAccountId}&unprocessedOnly=${unprocessedOnly}`,
        headers: {
            'Content-type': 'application/json',
        },
    });
}
