<template>
    <div>
        <div class="section-content">
            {{ $t('charging.chargingSpecifications.editor.specialNumber') }}
        </div>
        <div class="notice mb-3">
            <b>{{ $i18n.t('generic.notice') }}:</b>
            {{ $i18n.t('charging.chargingSpecifications.editor.specialNumberNotice') }}
        </div>
        <div class="special-numbers">
            <div
                v-for="(item, index) in specialNumberData.data"
                :key="index"
                class="number-slot d-flex mb-3"
                @mouseover="hoveredNumberSlot = index"
                @mouseleave="hoveredNumberSlot = null"
            >
                <AppInputV3
                    v-model="specialNumberData.data[index]"
                    :placeholder="$t('charging.chargingSpecifications.editor.specialNumberInputPlaceholder')"
                    :invalid="isDataInvalid(specialNumberData.data[index])"
                    :errorMessage="$i18n.t('generic.validations.fieldIsRequired')"
                    :data-test-id="`special-number-condition-input-${index}`"
                    class="mt-1 mb-3"
                    @input="onInput"
                />
                <IconButton
                    v-if="index != 0 && hoveredNumberSlot === index"
                    :icon="ICON_TYPES.DELETE"
                    :data-test-id="`remove-special-number-condition-${index}`"
                    @iconClick="removeSpecialNumber(index)"
                />
            </div>
            <AppButton
                :label="$i18n.t('charging.chargingSpecifications.editor.addNewRegex')"
                :iconType="ICON_TYPES.PLUS"
                :isSmall="true"
                data-test-id="add-special-number-condition-btn"
                @click="addNewItemToList"
            />
        </div>
    </div>
</template>

<script>
// Components
import AppInputV3 from '@/components/partials/inputs/AppInputV3.vue';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import SpecialNumberCondition from '@/__new__/services/dno/charging/models/chargingSpecificationConditions/SpecialNumberCondition';
import IconButton from '@/components/partials/IconButton.vue';

// Helpers
import { ICON_TYPES } from '@/common/iconHelper';

export default {
    name: 'SpecialNumber',
    components: {
        AppButton,
        AppInputV3,
        IconButton,
    },
    props: {
        value: {
            type: Object,
            default: () => undefined,
        },
    },
    data() {
        return {
            ICON_TYPES,
            BUTTON_TYPES,
            specialNumberData: new SpecialNumberCondition(this.value),
            hoveredNumberSlot: null,
        };
    },
    watch: {
        value: {
            handler() {
                this.specialNumberData = new SpecialNumberCondition(this.value);
            },
        },
    },
    methods: {
        isDataInvalid(data) {
            return data === '';
        },
        onInput() {
            this.specialNumberData.validate();
            this.$emit('input', this.specialNumberData);
        },
        addNewItemToList() {
            this.specialNumberData.data.push('');
            this.specialNumberData.isDataValid();
        },
        removeSpecialNumber(index) {
            this.specialNumberData.data.splice(index, 1);
            this.specialNumberData.isDataValid();
            this.$emit('input', this.specialNumberData);
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/_palette.scss';

.special-numbers {
    max-width: 283px;
}

.section-content {
    font-size: 0.75rem;
    font-weight: 600;
    margin-bottom: 1rem;
    margin-right: 1rem;
    color: $gray90;
}

.notice {
    font-size: 0.75rem;
    font-style: italic;
}
</style>
