<template>
    <div class="f-text-input d-flex align-items-center">
        <Dropdown
            :items="operationLabels"
            :colorSchema="Styles.White"
            :selected="[value && StringOperationsToLabels[value.op]]"
            caption="Select operation"
            :isFilterOperation="true"
            :widthDependsOnContent="true"
            @selected="opLabel => onChange({ op: StringOperationLabelsToType[opLabel] })"
        />

        <Dropdown
            :items="isItemsLabeled ? predefinedValues : []"
            :displayProperty="isItemsLabeled ? 'label' : null"
            :searchable="true"
            :class="['ml-3', { invalid: !isValid }]"
            :colorSchema="Styles.White"
            :selected="[selectedValue]"
            :editable="true"
            :emitInputOnChangeOnly="true"
            :isVisible="!value || !NoArgOperations[value.op]"
            caption="Select value"
            @selected="selected => onChange({ value: selected.value || selected })"
        />
    </div>
</template>

<script>
import Dropdown, { Styles } from '@/components/partials/Dropdown.vue';
import {
    StringOperationLabels,
    StringOperationLabelsToType,
    StringOperationsToLabels,
    NoArgOperations,
} from '@/common/filters/InputOperations';

export default {
    components: { Dropdown },
    props: {
        changeCallback: { type: Function, default: null },
    },
    data() {
        return {
            Styles,
            StringOperationsToLabels,
            StringOperationLabelsToType,
            NoArgOperations,
        };
    },
    computed: {
        isValid() {
            return true;
        }, // override
        predefinedValues() {
            return null;
        }, // override
        isItemsLabeled() {
            return !!this.predefinedValues;
        },
        selectedValue() {
            if (this.isItemsLabeled && this.value) {
                const predefValue = this.predefinedValues.find(v => v.value === this.value.value);
                const label = (predefValue && predefValue.label) || this.value.value;
                return { value: this.value.value, label };
            }
            return this.value && this.value.value;
        },
        operationLabels() {
            return StringOperationLabels;
        },
    },
    methods: {
        onChange(partialValue) {
            this.onValueChange(partialValue);
            if (this.changeCallback) this.changeCallback();
        },
        onValueChange(partialValue = {}) {
            return partialValue;
        }, // override
    },
};
</script>

<style lang="scss" scoped>
@import '../../../~@/assets/scss/mixins';

.dropdown {
    @include custom-height-dropdown(32px);
    @include custom-width-dropdown(180px);
}
</style>
