var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('TableFiltersRenderless',{attrs:{"entities":_vm.credsHistory},scopedSlots:_vm._u([{key:"default",fn:function({
            // state
            allFilters,
            filteredEntities,
            // methods
            onFilterAdded,
            removeFilter,
            removeAllFilters,
        }){return [_c('div',[_c('div',{staticClass:"d-flex justify-content-between align-items-center position-relative"},[_c('h2',{staticClass:"heading-md"},[_vm._v(" "+_vm._s(_vm.$i18n.t('customerCare.userInformation.credentialsChangeHistory'))+" ")]),_c('div',{staticClass:"d-flex align-items-center"},[_c('SearchBox',{staticClass:"search-box",attrs:{"smallSearchIcon":true},model:{value:(_vm.searchQueryCredentialsChangeHistory),callback:function ($$v) {_vm.searchQueryCredentialsChangeHistory=$$v},expression:"searchQueryCredentialsChangeHistory"}}),_c('ResponseModalButton',{attrs:{"response":_vm.credsHistoryApiResponse,"title":_vm.$i18n.t('customerCare.userInformation.credentialsChangeHistory')}}),_c('FilterTable',{attrs:{"columns":_vm.credentialsChangeHistoryColumnsData,"multiselectWidth":{ width: '12rem' }},on:{"filterAdded":onFilterAdded},scopedSlots:_vm._u([{key:"button",fn:function(slotProps){return [_c('IconButton',{attrs:{"label":_vm.$i18n.t('generic.filter'),"icon":_vm.ICON_TYPES.FILTER},on:{"iconClick":slotProps.clickHandler}})]}}],null,true)})],1)]),_c('TableFiltersTags',{staticClass:"mt-1 mb-3",attrs:{"filterTableMixin":{ allFilters }},on:{"removeFilter":removeFilter,"removeAllFilters":removeAllFilters}}),_c('AppTable',{attrs:{"entities":filteredEntities,"isDefaultHoverEnabled":false,"isPaginationEnabled":true,"canSelectColumns":true,"newDesign":true,"columnsData":_vm.credentialsChangeHistoryColumnsData,"isSearchEnabled":true,"innerSearchQuery":_vm.searchQueryCredentialsChangeHistory,"tableKey":"customer-care-new/credentialsChangeHistory"}})],1)]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }