
import Vue, { PropType } from 'vue';

// Components
import AppToggleV2 from '@/components/partials/inputs/AppToggleV2.vue';
import DataflowElement from '@/__new__/features/dataflows/DataflowElement.vue';

// Models
import type DataflowSectionModel from '@/__new__/services/dno/dataflows/models/DataflowSectionModel';

export default Vue.extend({
    name: 'DataflowModelSection',
    components: {
        AppToggleV2,
        DataflowElement,
    },
    props: {
        value: {
            type: Object as PropType<DataflowSectionModel>,
            required: true,
        },
    },
    computed: {
        sectionHasError(): boolean {
            return this.value.elements.some(el => el.error);
        },
    },
    watch: {
        value: {
            immediate: true,
            deep: true,
            handler() {
                this.value.error = this.value.enabled && this.sectionHasError;
                this.$emit('input', this.value);
            },
        },
    },
    methods: {
        getElComponentName(data: DataflowSectionModel['elements'][number]): string {
            return data.isSection ? 'DataflowModelSection' : 'DataflowElement';
        },
    },
});
