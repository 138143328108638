import http from '@/http/index';

const readBaseUrl = '/v3catalog/';
const writeBaseUrl = '/v3catalogwrite/';

export function getTemplates(entityType, templatesIds) {
    return http({
        method: 'POST',
        url: `${readBaseUrl}gettemplates`,
        data: {
            template_type: entityType,
            // should be empty array in order to get full list of the spicified type
            template_ids: templatesIds || [],
        },
    });
}

export function getAllTemplates(types) {
    const data = {};

    if (types.length) {
        data.types = types;
    }

    return http({
        method: 'POST',
        url: `${readBaseUrl}getalltemplates`,
        headers: {
            'Content-Type': 'application/json',
        },
        data,
    });
}

export function addTemplate(entityType, templateData) {
    return http({
        method: 'POST',
        url: `${writeBaseUrl}addtemplate`,
        data: {
            template_type: entityType,
            ...templateData,
        },
    });
}

export function updateTemplate(id, entityType, templateData, updateTime) {
    return http({
        method: 'POST',
        url: `${writeBaseUrl}updatetemplate`,
        data: {
            template_id: id,
            template_type: entityType,
            ...templateData,
            update_time: updateTime,
        },
    });
}

export function updateTemplateState(templateId, entityType, state, updateTime) {
    return http({
        method: 'POST',
        url: `${writeBaseUrl}updatetemplatestate`,
        data: {
            template_id: templateId,
            template_type: entityType,
            state,
            update_time: updateTime,
        },
    });
}

export default {
    addTemplate,
    getTemplates,
    getAllTemplates,
    updateTemplate,
    updateTemplateState,
};
