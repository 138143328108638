import NcsBaseCondition from '@/__new__/services/dno/charging/models/chargingSpecificationConditions/NCSbaseCondition';
import { ConditionConditionDataObject } from '@/__new__/services/dno/charging/common/conditionCommonHelper';
import { CustomCondition as TCustomCondition } from '@/__new__/services/dno/charging/common/customConditionHelper';

type CustomConditionConditionArg = ConditionConditionDataObject<TCustomCondition>;

export default class CustomCondition extends NcsBaseCondition {
    data: any;
    type: string;
    uuid: string;
    dataValid: boolean;
    dataEmpty: boolean;
    serviceType?: string;

    constructor({ data, uuid, type, serviceType, dataEmpty = true, dataValid = false }: CustomConditionConditionArg) {
        super();
        this.data = data;
        this.type = type;
        this.uuid = uuid;
        this.dataEmpty = dataEmpty;
        this.dataValid = dataValid;
        this.serviceType = serviceType;
    }
}
