
import Vue, { PropType } from 'vue';

// Components
import AppHeader from '@/components/layout/AppHeader.vue';
import EndUserAuthorizationBanner from '@/__new__/features/customerCareSuite/components/EndUserAuthorizationBanner.vue';
import Breadcrumbs from '@/components/partials/Breadcrumbs.vue';
import AppCustomerHeader from '@/components/partials/AppCustomerHeader.vue';
import AppTable from '@/components/partials/AppTable.vue';

// Mixins
import EndUserAuthorizationMixin from '@/__new__/features/customerCareSuite/components/EndUserAuthorizationMixin.vue';

// HTTP
import customerCareHTTP from '@/__new__/services/dno/user/http/customer-care';

// Helpers
import { USER_MANAGER_HIERARCHY } from '@/__new__/features/customerCare/common/customerCareHelper';
import { Breadcrumb } from '@/__new__/features/customerCareSuite/common/breadcrumbsHelper';
import tableColumnType, { type TableColumn } from '@/common/filterTable';
import { TranslateResult } from 'vue-i18n';
import RouteNames from '@/router/routeNames';

export default Vue.extend({
    name: 'NonSensitiveInfoPage',
    components: {
        AppHeader,
        EndUserAuthorizationBanner,
        Breadcrumbs,
        AppCustomerHeader,
        AppTable,
    },
    mixins: [EndUserAuthorizationMixin],
    props: {
        targetType: {
            type: Number as PropType<USER_MANAGER_HIERARCHY>,
            required: true,
        },
        targetId: {
            type: String,
            required: true,
        },
        pageTitle: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            USER_MANAGER_HIERARCHY,
            RouteNames,
            isDataLoading: false as boolean,
            basicInfo: null,
            searchQueryForTable: '' as string,
        };
    },
    computed: {
        getTargetTypeName(): TranslateResult {
            switch (this.targetType) {
                case USER_MANAGER_HIERARCHY.USER:
                    return this.$i18n.t('customerCare.userInformation.userId');
                case USER_MANAGER_HIERARCHY.ACCOUNT:
                    return this.$i18n.t('customerCare.accountId');
                case USER_MANAGER_HIERARCHY.SUBSCRIBER:
                    return this.$i18n.t('customerCare.subscriberId');
                default:
                    return '';
            }
        },
        infoData() {
            return [
                {
                    title: this.$i18n.t('customerCare.basicInfo'),
                    value: [
                        {
                            name: this.getTargetTypeName,
                            value: this.targetId,
                        },
                    ],
                    initiallyExpanded: true,
                },
            ];
        },
        breadcrumbList(): Breadcrumb[] {
            const result: Array<Breadcrumb> = [new Breadcrumb()];
            if (this.isCurrentTargetType(USER_MANAGER_HIERARCHY.SUBSCRIBER)) {
                result.push(new Breadcrumb(USER_MANAGER_HIERARCHY.USER, this.getUserIdForSubscriber));
                result.push(new Breadcrumb(USER_MANAGER_HIERARCHY.ACCOUNT, this.getAccountIdForSubscriber));
                result.push(new Breadcrumb(USER_MANAGER_HIERARCHY.SUBSCRIBER, this.targetId, true));
            } else if (this.isCurrentTargetType(USER_MANAGER_HIERARCHY.ACCOUNT)) {
                result.push(new Breadcrumb(USER_MANAGER_HIERARCHY.USER, this.getUserIdForAccount));
                result.push(new Breadcrumb(USER_MANAGER_HIERARCHY.ACCOUNT, this.targetId, true));
            } else if (this.isCurrentTargetType(USER_MANAGER_HIERARCHY.USER)) {
                result.push(new Breadcrumb(USER_MANAGER_HIERARCHY.USER, this.targetId, true));
            }

            return result;
        },
        getUserIdForAccount(): string {
            if (!this.basicInfo) {
                return '';
            }
            const [data] = this.basicInfo?.account_members;
            return data?.user_id || '';
        },
        getUserIdForSubscriber(): string {
            return this.basicInfo?.user_id || '';
        },
        getAccountIdForSubscriber(): string {
            return this.basicInfo?.account_id || '';
        },
        getUserAssociatedAccounts(): Array<{ accountId: string; accountRole: string }> {
            return (
                this.basicInfo?.associated_accounts?.map(({ account_id: accountId, account_role: accountRole }) => ({
                    accountId,
                    accountRole,
                })) || []
            );
        },
        getAccountMembers(): Array<{ userId: string; accountRole: string; email: string }> {
            return (
                this.basicInfo?.account_members?.map(({ user_id: userId, account_role: accountRole, emails }) => ({
                    userId,
                    accountRole,
                    email: emails?.[0] || '',
                })) || []
            );
        },
        getAccountSubscribers(): Array<{ subscriberId: string; msisdn: string }> {
            return (
                this.basicInfo?.account_subscribers?.map(({ subscriber_id: subscriberId, msisdn = '' }) => ({
                    subscriberId,
                    msisdn,
                })) || []
            );
        },
        getSubscriberEmails(): Array<{ email: string }> {
            return (
                this.basicInfo?.owner_emails?.map((el: string) => ({
                    email: el,
                })) || []
            );
        },
        userAssociatedAccountsTableColumnsData(): TableColumn[] {
            return [
                {
                    name: this.$i18n.t('customerCare.accountId'),
                    key: 'accountId',
                    field: 'accountId',
                    forbidHideColumn: true,
                    classes: ['font-weight-bold'],
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('generic.role'),
                    key: 'accountRole',
                    field: 'accountRole',
                    forbidHideColumn: true,
                    classes: ['font-weight-bold'],
                    filterType: tableColumnType.GENERAL_TEXT,
                },
            ];
        },
        accountMembersTableColumnsData(): TableColumn[] {
            return [
                {
                    name: this.$i18n.t('customerCare.userId'),
                    key: 'userId',
                    field: 'userId',
                    forbidHideColumn: true,
                    classes: ['font-weight-bold'],
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('generic.role'),
                    key: 'role',
                    field: 'role',
                    forbidHideColumn: true,
                    classes: ['font-weight-bold'],
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('generic.email'),
                    key: 'email',
                    field: 'email',
                    forbidHideColumn: true,
                    classes: ['font-weight-bold'],
                    filterType: tableColumnType.GENERAL_TEXT,
                },
            ];
        },
        accountSubscribersTableColumnsData(): TableColumn[] {
            return [
                {
                    name: this.$i18n.t('customerCare.subscriberId'),
                    key: 'subscriberId',
                    field: 'subscriberId',
                    forbidHideColumn: true,
                    classes: ['font-weight-bold'],
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('customerCare.msisdn'),
                    key: 'msisdn',
                    field: 'msisdn',
                    forbidHideColumn: true,
                    classes: ['font-weight-bold'],
                    filterType: tableColumnType.GENERAL_TEXT,
                },
            ];
        },
        subscriberEmailsTableColumnsData(): TableColumn[] {
            return [
                {
                    name: this.$i18n.t('generic.email'),
                    key: 'email',
                    field: 'email',
                    forbidHideColumn: true,
                    classes: ['font-weight-bold'],
                    filterType: tableColumnType.GENERAL_TEXT,
                },
            ];
        },
        getTargetIdForAuthBanner(): string {
            switch (this.targetType) {
                case USER_MANAGER_HIERARCHY.USER:
                    return this.basicInfo?.associated_accounts?.[0]?.account_id || '';
                case USER_MANAGER_HIERARCHY.ACCOUNT:
                    return this.targetId;
                case USER_MANAGER_HIERARCHY.SUBSCRIBER:
                    return this.basicInfo?.account_id || '';
                default:
                    return '';
            }
        },
    },
    async created() {
        await this.loadNonSensitiveInfo();
    },
    methods: {
        async loadNonSensitiveInfo() {
            await this.$withProgressBar(
                async () => {
                    this.isDataLoading = true;
                    const {
                        data: { basic_info: basicInfo },
                    } = await customerCareHTTP.getNonSensitiveInfo(this.targetId, this.targetType);
                    this.basicInfo = basicInfo;
                    this.isDataLoading = false;
                },
                {
                    errorHandler: () =>
                        this.$showErrorAlert({
                            message: this.$i18n.t('alertMessage.failedToLoadNecessaryData'),
                        }),
                },
            );
        },
        isCurrentTargetType(type: USER_MANAGER_HIERARCHY): boolean {
            return this.targetType === type;
        },
    },
});
