
// Components
import AppDialogV2 from '@/components/partials/AppDialogV2.vue';
import { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import CustomTextarea from '@/__new__/features/customerCare/subscriber/CustomTextarea.vue';
import LabelDanger from '@/components/partials/LabelDanger.vue';

// http
import ossmvneHTTP from '@/__new__/services/dno/ossmvne/http/ossmvne';
import notesHTTP from '@/__new__/services/dno/crm/http/crm';

// Helpers
import { USER_MANAGER_HIERARCHY } from '@/__new__/features/customerCare/common/customerCareHelper';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import { dateToEpoch } from '@/common/formatting';

export default {
    name: 'ResetVoiceMailPwdModal',
    components: {
        AppDialogV2,
        CustomTextarea,
        LabelDanger,
    },
    mixins: [validationMixin],
    props: {
        value: {
            required: true,
            type: Boolean,
        },
        subscriberId: {
            default: '',
            type: String,
        },
        msisdn: {
            default: '',
            type: String,
        },
    },
    data() {
        return {
            isLoading: false,
            reason: '',
            notesTxt: '',
            BUTTON_TYPES,
        };
    },
    computed: {
        isNotesInvalid() {
            return this?.$v?.notesTxt?.$error;
        },
    },
    validations() {
        return {
            notesTxt: {
                required,
            },
        };
    },
    methods: {
        close() {
            this.$emit('close');
        },
        onSubmit() {
            this.$v.$touch();

            if (this.$v.$invalid) {
                return;
            }

            this.submit();
        },
        async submit() {
            this.isLoading = true;
            await this.resetVoiceMailPwd(() => this.setAgentNote());
            this.isLoading = false;
            this.close();
        },
        setAgentNote() {
            return this.$withProgressBar(
                () =>
                    notesHTTP.addNote({
                        id: this.subscriberId,
                        idType: USER_MANAGER_HIERARCHY.SUBSCRIBER,
                        epoch: dateToEpoch(Date.now()),
                        noteText: this.notesTxt,
                        tags: [this.$t('customerCare.resetVoiceMailPwd.modalTitle')],
                    }),
                {
                    errorHandler: () => {
                        this.$alert(this.$t('alertMessage.somethingWentWrongSavingAgentNotes'));
                        this.isLoading = false;
                    },
                },
            );
        },
        resetVoiceMailPwd(successHandler: () => void) {
            return this.$withProgressBar(
                async () => {
                    await ossmvneHTTP.resetVoiceMailPwd(
                        this.subscriberId,
                        USER_MANAGER_HIERARCHY.SUBSCRIBER,
                        this.msisdn,
                    );
                    this.$showSuccessAlert({
                        message: this.$i18n.t('customerCare.resetVoiceMailPwd.alerts.resetSuccess'),
                    });
                },
                {
                    successHandler,
                    errorHandler: () => {
                        this.$alert(this.$t('customerCare.resetVoiceMailPwd.alerts.resetFailed'));
                        this.isLoading = false;
                    },
                },
            );
        },
    },
};
