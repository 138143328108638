
import Vue, { type PropType } from 'vue';

// components
import AppIcon from '@/components/partials/icon/AppIcon.vue';

// helpers
import { ICON_TYPES } from '@/common/iconHelper';
import { formatDataAmount } from '@/common/formatting';
import download from 'downloadjs';

type FileItem = File | { name: string; size: number; type: string };

export default Vue.extend({
    name: 'FileItem',
    components: {
        AppIcon,
    },
    props: {
        file: {
            type: [File, Object] as PropType<FileItem>,
            required: true,
        },
        errorMessage: {
            type: String,
            default: '',
        },
        loadingPercentage: {
            type: Number,
            default: 0,
        },
        status: {
            type: String,
            default: '',
        },
        hideProgress: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            ICON_TYPES,
        };
    },
    computed: {
        getLoadingPercentage(): string {
            return `${this.loadingPercentage}%`;
        },
        fileSize(): string {
            return this.getSizeText(this.file);
        },
        isFile(): boolean {
            return this.file instanceof File;
        },
        imageSrc(): string {
            if (this.isFile && this.file.type.includes('image/')) {
                return URL.createObjectURL(this.file as File);
            }
            return '';
        },
    },
    methods: {
        getSizeText({ size }: FileItem): string {
            const sizeFormatted = formatDataAmount(size);

            if (!this.hideProgress) {
                const sizeLoaded = Math.round(this.loadingPercentage * parseFloat(sizeFormatted)) / 100;
                const status = this.loadingPercentage < 100 ? this.$t('generic.stateMap.uploading') : this.status;
                let text = `${sizeLoaded} ${this.$t('generic.of')} ${sizeFormatted}`;

                if (this.loadingPercentage) {
                    text += ` - ${status}`;
                }

                return text;
            }

            return `${this.$t('generic.size')}: ${sizeFormatted}`;
        },
        downloadFile(): void {
            if (this.isFile) {
                download(this.file, this.file.name, 'text/plain');
            }
            this.$emit('download', this.file);
        },
    },
});
