const genericReservedKeys = [
    'id',
    'name',
    'description',
    'data',
    'relationships',
    'template_id',
    'entityType',
    'state',
    'version',
    'update_time',
    'external_id',
];

export const offerReservedKeys = [
    ...genericReservedKeys,
    'start_time',
    'end_time',
    'amounts',
    'categories',
    'segments',
    'ui_order',
];

export const productReservedKeys = [...genericReservedKeys];

export const serviceReservedKeys = [...genericReservedKeys, 'type'];

export const resourceReservedKeys = [...genericReservedKeys, 'type'];
