import { SUBSCRIBER_PORT_IN_MAP } from '@/__new__/features/customerCare/common/customerCareHelper';
import UserManagerBaseModel from '@/__new__/services/dno/user/models/UserManagerBaseModel';
import { SUBSCRIBER_PORTING_ACTIONS } from '@/__new__/features/customerCare/common/customerCareHelper';

type PortingResponse = {
    msisdn: string;
    state: SUBSCRIBER_PORT_IN_MAP;
    created_at: number;
    request_id?: string;
    execution_data?: {
        port_in_date?: number;
        external_port_in_request_id?: string;
        account_number?: string;
        error_code?: string;
        error_message?: string;
        temp_msisdn?: string;
    };
    properties?: {
        completed_at?: number;
        account_number?: string;
        reason?: string;
    };
};

export default class Porting extends UserManagerBaseModel {
    msisdn: string;
    status: SUBSCRIBER_PORT_IN_MAP | false;
    requestDate: number | null;
    completeDate: number | null;
    scheduledDate: number | null;
    portInRequestId?: string;
    validationResultDescription?: string;
    resultDescription?: string;
    accountId?: string;
    tempMsisdn?: string;

    constructor({
        msisdn,
        status,
        requestDate,
        completeDate,
        scheduledDate,
        portInRequestId,
        validationResultDescription,
        resultDescription,
        accountId,
        tempMsisdn,
    }: Porting) {
        super();
        this.msisdn = this.validateString('msisdn', msisdn) ? msisdn : '';
        this.status = this.validateNumberInteger('status', status) ? status : false;
        this.requestDate = this.validateNumberInteger('requestDate', requestDate) ? requestDate : null;
        this.completeDate = this.validateNumberInteger('completeDate', completeDate) ? completeDate : null;
        this.scheduledDate = this.validateNumberInteger('scheduledDate', scheduledDate) ? scheduledDate : null;
        this.portInRequestId = this.validateString('portInRequestId', portInRequestId) ? portInRequestId : '';
        this.validationResultDescription = this.validateString(
            'validationResultDescription',
            validationResultDescription,
        )
            ? validationResultDescription
            : '';
        this.resultDescription = this.validateString('resultDescription', resultDescription) ? resultDescription : '';
        this.accountId = this.validateString('accountId', accountId) ? accountId : '';
        this.tempMsisdn = this.validateString('tempMsisdn', tempMsisdn) ? tempMsisdn : '';
    }

    static remapPortingFromBe(response: PortingResponse, action: SUBSCRIBER_PORTING_ACTIONS) {
        if (action === SUBSCRIBER_PORTING_ACTIONS.PORT_IN) return Porting.remapPortIn(response);
        if (action === SUBSCRIBER_PORTING_ACTIONS.PORT_OUT) return Porting.remapPortOut(response);
        return Porting.remapPortIn(response);
    }

    static remapPortIn(response: PortingResponse) {
        return {
            msisdn: response.msisdn,
            status: response.state,
            requestDate: response.created_at,
            completeDate: response?.execution_data?.port_in_date
                ? response.execution_data.port_in_date * 1000 // convert to milliseconds
                : null,
            scheduledDate: response?.execution_data?.port_in_date
                ? response.execution_data.port_in_date * 1000 // convert to milliseconds
                : null,
            portInRequestId: response?.request_id,
            resultDescription:
                response?.execution_data?.error_code && response?.execution_data?.error_message
                    ? `${response?.execution_data?.error_code}: ${response?.execution_data?.error_message}`
                    : '',
            accountId: response?.execution_data?.account_number,
            tempMsisdn: response?.execution_data?.temp_msisdn || '',
        };
    }

    static remapPortOut(response: PortingResponse) {
        return {
            msisdn: response.msisdn,
            status: response.state,
            requestDate: response.created_at,
            completeDate: response?.properties?.completed_at
                ? new Date(response.properties.completed_at).getTime()
                : null,
            validationResultDescription: response?.properties?.reason,
            accountId: response?.properties?.account_number,
        };
    }

    static maxAllowedDate(date: number) {
        return new Date(date).setDate(new Date(date).getDate() + 7);
    }
}
