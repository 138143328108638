<template>
    <div>
        <div
            v-t="'generic.days'"
            class="table-title text-capitalize"
        />
        <div class="primary-text">
            <!-- Render selected days -->
            <span
                v-for="(day, index) in activeDays"
                :key="index"
            >
                {{ $t(day.i18nKey) }}
            </span>
        </div>
        <div
            v-t="'charging.chargingSpecifications.editor.conditions.dateTime.timeFrame'"
            class="table-title"
        />
        <div class="primary-text">
            <!-- Render timeslots -->
            <span
                v-for="(timeSlot, index) in timeSlots"
                :key="index"
            >
                {{ `${$t(timeSlot.s)}-${$t(timeSlot.e)}` }}
            </span>
        </div>
    </div>
</template>

<script>
import { getScheduleInitValue } from '@/__new__/services/dno/charging/common/chargingSpecificationHelper';

export default {
    name: 'ScheduleValue',
    props: {
        value: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            schedule: null,
        };
    },
    computed: {
        days() {
            return Object.values(this.schedule.days)
                .filter(day => this.value.schedule[day.apiKey].length > 0)
                .map(day => ({
                    ...day,
                    active: true,
                }));
        },
        activeDays() {
            return this.days.filter(day => day.active);
        },
        timeSlots() {
            return Object.values(this.value.schedule).find(timeSlotsArray => timeSlotsArray.length > 0);
        },
    },
    created() {
        this.schedule = getScheduleInitValue();
    },
};
</script>
