
import Vue, { PropType } from 'vue';

// Components
import AppCustomerHeader from '@/components/partials/AppCustomerHeader.vue';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';

// Helpers
import { triggerSnrSession, triggerRarSession } from '@/__new__/services/dno/charging/http/bucketWizard';
import { Session, SESSION_TYPES } from '@/__new__/services/dno/charging/common/bucketWizardHelper';
import moment from 'moment';
import { ICON_TYPES, ICON_COLORS } from '@/common/iconHelper';
import Button from '@/common/button/Button';
import { ALERT_TYPES } from '@/common/alerts/Alert';
import { USER_MANAGER_HIERARCHY } from '@/__new__/features/customerCare/common/customerCareHelper';

export default Vue.extend({
    name: 'SessionsData',
    components: {
        AppCustomerHeader,
        AppButton,
    },
    props: {
        sessionType: {
            type: String as PropType<SESSION_TYPES>,
            required: true,
        },
        sessionKey: {
            type: String,
            required: true,
        },
        value: {
            type: Object as PropType<Session>,
            required: true,
        },
        targetId: {
            type: String,
            required: true,
        },
        targetType: {
            type: Number as PropType<USER_MANAGER_HIERARCHY>,
            required: true,
        },
    },
    data() {
        return {
            ICON_TYPES,
            ICON_COLORS,
            BUTTON_TYPES,
            collapseSection: true,
            alertButtons: {
                triggerSnr: new Button({
                    label: this.$i18n.t('charging.bucketWizard.triggerSnr'),
                    alertType: ALERT_TYPES.warning,
                }),
                triggerRar: new Button({
                    label: this.$i18n.t('charging.bucketWizard.triggerRar'),
                    alertType: ALERT_TYPES.warning,
                }),
            } as Record<string, Button>,
        };
    },
    computed: {
        sessionSyInfoData() {
            return [
                {
                    title: this.$i18n.t('customerCare.basicInfo'),
                    initiallyExpanded: true,
                    value: [
                        {
                            name: this.$i18n.t('charging.bucketWizard.waitingForSna'),
                            value: this.value?.waiting_for_sna
                                ? this.$i18n.t('generic.yes')
                                : this.$i18n.t('generic.no'),
                        },
                        {
                            name: this.$i18n.t('charging.bucketWizard.snrTriggerTime'),
                            value: this.value?.snr_trigger_time
                                ? moment(this.value.snr_trigger_time * 1000).format('DD-MM-YYYY')
                                : '',
                        },
                        {
                            name: this.$i18n.t('charging.bucketWizard.triggerSnr'),
                            key: `triggerSnr`,
                        },
                    ],
                },
            ];
        },
        sessionInfoData() {
            return [
                {
                    title: this.$i18n.t('customerCare.basicInfo'),
                    initiallyExpanded: true,
                    value: [
                        {
                            name: this.$i18n.t('charging.bucketWizard.sessionExpiry'),
                            value: this.value?.session_expiry
                                ? moment(this.value.session_expiry * 1000).format('DD-MM-YYYY')
                                : '',
                        },
                        {
                            name: this.$i18n.t('charging.bucketWizard.totalUnitsUsed'),
                            value: this.value?.total_units_used || 0,
                        },
                    ],
                },
            ];
        },
        reservationsInfoData() {
            return Object.keys(this.value.reservations).map((key: string) => ({
                title: `${this.$i18n.t('customerCare.account.reservationId')}: ${key}`,
                initiallyExpanded: true,
                value: [
                    {
                        name: this.$i18n.t('charging.bucketWizard.rgNumber'),
                        value: key,
                    },
                    {
                        name: this.$i18n.t('charging.ratingGroups.ratingGroup'),
                        value: this.value.reservations[key]?.rating_group_name || '',
                    },
                    {
                        name: this.$i18n.t('charging.bucketWizard.expiresAt'),
                        value: this.value.reservations[key]?.expiry
                            ? moment(this.value.reservations[key]?.expiry * 1000).format('DD-MM-YYYY')
                            : '',
                    },
                    {
                        name: this.$i18n.t('charging.bucketWizard.reservedAmount'),
                        value: this.value.reservations[key]?.reserved_amount || 0,
                    },
                ],
            }));
        },
        infoData() {
            let data = this.sessionType === SESSION_TYPES?.SESSIONS_SY ? this.sessionSyInfoData : this.sessionInfoData;

            if (this.sessionType === SESSION_TYPES?.SESSIONS) {
                data[0].value.push({
                    name: this.$i18n.t('charging.bucketWizard.triggerRar'),
                    key: `triggerRar`,
                });
            }

            if (this.value.reservations) {
                data = [...data, ...this.reservationsInfoData];
            }

            return data;
        },
    },
    methods: {
        onTriggerSnr(): void {
            this.$eventBus.$emit('closeAllAlerts');
            this.$eventBus.$emit('showAlert', {
                message: this.$i18n.t('charging.bucketWizard.triggerSnrInfo'),
                type: ALERT_TYPES.warning,
                buttons: [this.alertButtons.triggerSnr],
            });
            this.$eventBus.$once('buttonClicked', (buttonId: string) => {
                if (buttonId === this.alertButtons.triggerSnr.id) {
                    this.$withLoadingSpinner(
                        async () => {
                            await triggerSnrSession(this.targetId, this.targetType, this.sessionKey);
                            this.$eventBus.$emit('showAlert', {
                                message: this.$i18n.t('charging.bucketWizard.triggerSnrSuccess'),
                                type: ALERT_TYPES.success,
                            });
                        },
                        {
                            errorHandler: () => {
                                this.$eventBus.$emit('showAlert', {
                                    message: this.$i18n.t('charging.bucketWizard.triggerSnrError'),
                                });
                            },
                        },
                    );
                }
            });
        },
        onTriggerRar(): void {
            this.$eventBus.$emit('closeAllAlerts');
            this.$eventBus.$emit('showAlert', {
                message: this.$i18n.t('charging.bucketWizard.triggerRarInfo'),
                type: ALERT_TYPES.warning,
                buttons: [this.alertButtons.triggerRar],
            });
            this.$eventBus.$once('buttonClicked', (buttonId: string) => {
                if (buttonId === this.alertButtons.triggerRar.id) {
                    this.$withLoadingSpinner(
                        async () => {
                            await triggerRarSession(this.targetId, this.targetType, this.sessionKey);
                            this.$eventBus.$emit('showAlert', {
                                message: this.$i18n.t('charging.bucketWizard.triggerRarSuccess'),
                                type: ALERT_TYPES.success,
                            });
                        },
                        {
                            errorHandler: () => {
                                this.$eventBus.$emit('showAlert', {
                                    message: this.$i18n.t('charging.bucketWizard.triggerRarError'),
                                });
                            },
                        },
                    );
                }
            });
        },
        toggleCollapseSection(): void {
            this.collapseSection = !this.collapseSection;
        },
    },
});
