import {
    EntityBaseConstructorArgs,
    EntityBaseModel,
    EntityBaseDraftModel,
} from '@/__new__/services/dno/charging/models/EntityBaseModel';

interface PolicyRuleConstructorArgs extends EntityBaseConstructorArgs {
    conditions: any;
    available_statuses?: string[];
}

export interface PolicyRuleDraftConstructorArgs extends PolicyRuleConstructorArgs {
    operator_name: string;
    type: string;
    update_portal_id: string;
    data?: PolicyRuleConstructorArgs;
}

export class PolicyRule extends EntityBaseModel {
    conditions: any;
    availableStatuses?: string[];

    constructor(data: PolicyRuleConstructorArgs, draftEntities: PolicyRuleDraft[]) {
        super(data, draftEntities);
        this.conditions = data.conditions;
        this.availableStatuses = data.available_statuses || undefined;
    }
}

export class PolicyRuleDraft extends EntityBaseDraftModel {
    conditions: any;
    availableStatuses?: string[];

    constructor(data: PolicyRuleDraftConstructorArgs) {
        super(data);
        this.conditions = data.conditions;
        this.availableStatuses = data.available_statuses || undefined;
    }
}

export default PolicyRule;
