export enum DLQ_INGESTION_TABLE_ACTIONS {
    FETCH = 'Fetch',
    REDRIVE = 'Redrive',
    REPLACE = 'Replace',
}

export type DlqFileResponse = {
    fileName: string;
    tableName: string;
    lastModified: number;
    actions: DLQ_INGESTION_TABLE_ACTIONS[];
    size: number;
    fileEntryID: string;
};
