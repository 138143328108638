<template>
    <div>
        <AbstractTableTile
            :entities="formattedEntities"
            :columnsData="columnsData"
            :apiResponse="rawResponse"
            :entityKey="'id'"
            data-test-id="table"
        />
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { Getters } from '@/store/mutation-types';

// Components
import AbstractTableTile from '@/__new__/features/customerCareSuite/components/AbstractTableTile.vue';

// Helpers
import * as Sentry from '@sentry/vue';
import { ALERT_TYPES } from '@/common/alerts/Alert';
import supportButtonMixin from '@/components/alerts/supportButtonMixin';
import tableColumnType from '@/common/filterTable';
import { formatAmountBasedOnCurrency } from '@/common/formatting';
import { CHARGE_STATES } from '@/common/userManager/transactionHistoryV4StatusHelper';
import { USER_MANAGER_HIERARCHY } from '@/__new__/features/customerCare/common/customerCareHelper';
import { TransactionV4 } from '@/__new__/services/dno/orders/models/TransactionV4';
import {
    PAYMENT_TRANSACTION_TYPE,
    paymentTransactionTypeToString,
} from '@/common/userManager/transactionHistoryV4Helper';

// HTTP
import ordersHTTP from '@/__new__/services/dno/orders/http/orders';

export default {
    name: 'PricingRulesDiscountsTile',
    components: {
        AbstractTableTile,
    },
    mixins: [supportButtonMixin],
    data() {
        return {
            transactionHistories: [],
            rawResponse: {},
            allowedTransactionTypes: [
                PAYMENT_TRANSACTION_TYPE.COMMIT,
                PAYMENT_TRANSACTION_TYPE.DIRECT_DEBIT,
                PAYMENT_TRANSACTION_TYPE.RESERVE,
            ],
        };
    },
    computed: {
        ...mapGetters('operators', {
            selectedLanguage: Getters.languageDefault,
        }),
        formattedEntities() {
            const discounts = [];
            // We only care about successful charges:
            const successfulCharges = this.transactionHistories.filter(
                t =>
                    t.state === CHARGE_STATES.SUCCESS &&
                    this.allowedTransactionTypes.includes(t.paymentTransactionType),
            );
            // Record all discounts for each transaction
            successfulCharges.forEach(transaction =>
                transaction.itemPrices.forEach(itemPrice => {
                    itemPrice.adjustments.forEach(adjustment => {
                        // Ignore fees
                        if (Number(adjustment.amount) > 0) {
                            return;
                        }
                        const discountAmount = Math.abs(adjustment.amount);
                        discounts.push({
                            id: transaction.id,
                            amount: discountAmount,
                            amountFormatted: formatAmountBasedOnCurrency(discountAmount, transaction.currency),
                            pricingRuleName: adjustment.pricingRuleName[this.selectedLanguage],
                            pricingRuleId: adjustment.pricingRuleId,
                            timestampFormatted: this.$localeLibrary.getFormattedDateAndTimeWithSecondsPrecision(
                                transaction.timestamp,
                            ),
                            transactionTypeStr: paymentTransactionTypeToString(transaction.paymentTransactionType),
                            timestamp: transaction.timestamp,
                        });
                    });
                }),
            );
            return discounts;
        },
        columnsData() {
            return [
                {
                    name: this.$i18n.t('customerCare.transactionHistory.transactionId'),
                    key: 'id',
                    field: 'id',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('generic.name'),
                    key: 'pricingRuleName',
                    field: 'pricingRuleName',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('generic.amount'),
                    key: 'amountFormatted',
                    sortBy: entity => entity.amount,
                    field: 'amount',
                    filterType: tableColumnType.NUMBER,
                },
                {
                    name: this.$i18n.t('billing.transactionType'),
                    key: 'transactionTypeStr',
                    field: 'transactionTypeStr',
                    limitedOptions: this.allowedTransactionTypes.map(type => paymentTransactionTypeToString(type)),
                    filterType: tableColumnType.TEXT_LIMITED_OPTIONS,
                },
                {
                    name: this.$i18n.t('customerCare.transactionHistory.timestamp'),
                    key: 'timestampFormatted',
                    sortBy: entity => entity.timestamp,
                    field: 'timestamp',
                    filterType: tableColumnType.DATE,
                },
            ];
        },
    },
    async created() {
        await this.fetchTileData();
    },
    methods: {
        async fetchTileData() {
            try {
                this.$Progress.start();
                this.$emit('isDataLoadingUpd', true);
                this.rawResponse = await ordersHTTP.getTransactions(
                    this.$route.params.id,
                    USER_MANAGER_HIERARCHY.ACCOUNT,
                    'DESC',
                );
                this.transactionHistories = this.rawResponse?.data?.transactions.map(
                    transactionJSON => new TransactionV4(transactionJSON),
                );
                this.$Progress.finish();
            } catch (error) {
                Sentry.captureException(error);
                this.$Progress.fail();
                this.showSupportAlert(
                    this.$i18n.t('alertMessage.somethingWentWrongFetchingNecessaryData'),
                    ALERT_TYPES.error,
                );
            } finally {
                this.$emit('isDataLoadingUpd', false);
            }
        },
    },
};
</script>
