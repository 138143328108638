import { CHARGE_STATES } from '@/common/userManager/transactionHistoryV4StatusHelper';
import permissionsService, { isViewEnabled } from '@/services/permissions/permissions.service';
import {
    PAYMENT_TRANSACTION_TYPE,
    getResponseMessageFromModuleCode,
} from '@/common/userManager/transactionHistoryV4Helper';

/* eslint-disable camelcase */
export class TransactionV4 {
    id;

    timestamp;

    state;

    transactionName;

    amount;

    constructor(transactionJSON) {
        this.id = transactionJSON?.id;
        this.timestamp = transactionJSON?.charged_at || '';
        this.fileNumber = transactionJSON?.data?.invoice_number || '';
        this.state = transactionJSON?.state || '';
        this.transactionName = transactionJSON?.data?.invoice_number || '';
        this.amount =
            transactionJSON?.data?.refunded_amount ||
            transactionJSON?.data?.pricing_response?.total_price?.amount ||
            '';
        this.chargesData = transactionJSON?.data?.misc?.charges_data?.map(data => this.formatChargesData(data));
        this.itemPrices =
            transactionJSON?.data?.pricing_response?.item_price?.map(res => this.formatItemPrices(res)) || [];
        this.failedTries = transactionJSON?.data?.failed_tries
            ? this.formatFailedTries(transactionJSON?.data?.failed_tries)
            : [];
        this.paymentResponse = transactionJSON?.data?.payment_response?.response
            ? this.formatPaymentResponse(transactionJSON?.data)
            : {};
        this.allPaymentAttempts = this.formatAllPaymentAttempts(this.failedTries, this.paymentResponse);
        this.canRefund = Number(this.amount) && this.constructor.refundIsAllowed(transactionJSON);
        this.transactionHasInvoiceButtons = this.constructor.invoiceButtonsAreVisible(transactionJSON);
        this.paymentTransactionType = transactionJSON?.payment_transaction_type || '';
        // Remove fallback support for getting currency from 'failedTries' once BM-484 is done.
        this.currency =
            transactionJSON?.data?.currency || this.failedTries[this.failedTries.length - 1]?.currency || '';
        this.originalTransactionId = transactionJSON?.data?.refund?.original_transaction_id || null;
        this.partiallyRefunded =
            (this.state === CHARGE_STATES.REFUNDED && transactionJSON?.data?.refund?.partially_refunded) || false;
        this.remainingBalance = this.partiallyRefunded
            ? this.amount - transactionJSON?.data?.refund?.refunded_amount
            : '';
    }

    formatChargesData(data) {
        return {
            offerName: data?.offer_name || '',
            offerDescription: data?.offer_description || '',
            chargeType: data?.charge_type || '',
            serviceDescription: data?.service_description || '',
            subscriberId: data?.subscriber_id || '',
        };
    }

    formatItemPrices(data) {
        return {
            serviceAmount: data?.tax?.service_amount || '',
            totalTaxAmount: data?.tax?.total_tax_amount || '',
            grossAmount: data?.tax?.gross_amount || '',
            alterationsAmount: data?.alterations_amount || '',
            promotions: Object.entries(data?.alterations?.promo_rule_info || {}).map(([id, data]) => ({
                id,
                ...data,
            })),
            adjustments: Array.isArray(data?.pricing_rule_adjustments)
                ? data?.pricing_rule_adjustments?.map(adjustment => this.formatAdjustment(adjustment)) || []
                : [],
        };
    }

    formatAdjustment(data) {
        return {
            pricingRuleId: data?.pricing_rule_id || '',
            amount: data?.amount || 0,
            pricingRuleName: data?.pricing_rule_info?.pricing_rule_name || '',
            pricingRuleDescription: data?.pricing_rule_info?.pricing_rule_description || '',
        };
    }

    formatFailedTries(failedTries) {
        const formattedFailedTries = [];
        failedTries.forEach(failedTry => {
            formattedFailedTries.push(this.formatPaymentResponse(failedTry));
        });

        return formattedFailedTries;
    }

    formatPaymentResponse(data) {
        const responseRoute = data?.payment_response?.response || data?.payment_response;
        const response = {
            paymentTimestamp: data?.attempt_timestamp || this.timestamp,
            externalPaymentId:
                responseRoute?.external_transaction_id ||
                responseRoute?.original_response?.external_transaction_id ||
                '',
            internalPaymentId:
                responseRoute?.internal_transaction_id ||
                responseRoute?.original_response?.internal_transaction_id ||
                '',
            paymentMethod:
                responseRoute?.original_response?.payment_method ||
                responseRoute?.payment_method?.pgw_info?.external_response?.payment_method ||
                '',
            cardType:
                responseRoute?.original_response?.card_type ||
                responseRoute?.payment_method?.pgw_info?.external_response?.card_type ||
                '',
            last4:
                responseRoute?.original_response?.last_4 ||
                responseRoute?.payment_method?.pgw_info?.external_response?.last_4 ||
                '',
            paymentTransactionType: responseRoute?.internal_transaction_type || '',
            status: responseRoute?.original_error ? 'Failure' : 'Success',
            currency: data?.currency || '',
        };

        if (responseRoute?.original_error) {
            const { msg, module: moduleName, code } = responseRoute.original_error;
            response.failureMessage = msg || getResponseMessageFromModuleCode(moduleName, code);
        }

        return response;
    }

    formatAllPaymentAttempts(failedTries, paymentResponse) {
        // set to failedTries since it is always array and array we need to return
        const allPaymentAttempts = failedTries;

        if (Object.keys(paymentResponse).length) {
            allPaymentAttempts.push(paymentResponse);
        }

        return allPaymentAttempts;
    }

    static refundIsAllowed({ state, data }) {
        return (
            (state === 100 && data?.commit?.is_committed) ||
            (state === 100 && !data?.commit && !data.refund) ||
            (state === 500 && data?.refund?.partially_refunded)
        );
    }

    static invoiceButtonsAreVisible(transactionJSON) {
        const { state, data } = transactionJSON;

        return (
            (state === CHARGE_STATES.SUCCESS && data?.commit?.is_committed) ||
            (state === CHARGE_STATES.SUCCESS && !data?.commit && !data.refund) ||
            (state === CHARGE_STATES.REFUNDED && data?.commit?.is_committed) ||
            (state === CHARGE_STATES.REFUNDED && !data?.reserve) ||
            (transactionJSON?.payment_transaction_type === PAYMENT_TRANSACTION_TYPE.REFUND &&
                permissionsService.refundTransactionsVisibleEnabled() &&
                isViewEnabled('UMAccountBillingTransactionHistoryRefundVisible'))
        );
    }
}

export default TransactionV4;
