import isEmpty from 'lodash/isEmpty';
import localeLibrary from '@/common/locale/localeLibrary';

export type RelationshipDataBe = {
    offer_id: string;
    id: string;
    type: string;
};

export type InventoryRefBe = {
    entity_type: string;
    entity_id: string;
    item_id: string;
};

export type BillingObligationRefBe = {
    billing_account_id: string;
    obligation_id: string;
};

export type OrderRefsBe = {
    target_id: string;
    target_type: string;
    order_id: string;
};

export type CharacteristicsBe = {
    volume: number;
};

export type SubscriptionStateHistoryBe = {
    id: string;
    id_type: string;
    state: number;
    updated_time_ms: number;
};

export type RelationshipData = {
    offerId: string;
    id: string;
};

export type InventoryRef = {
    entityYype: string;
    entityId: string;
    itemId: string;
};

export type BillingObligationRef = {
    billingAccountId: string;
    obligationId: string;
};

export type OrderRefs = {
    targetId: string;
    targetType: string;
    orderId: string;
};

export type Characteristics = {
    volume: number;
};

export type SubscriptionStateHistory = {
    id: string;
    idYype: string;
    state: number;
    updatedTimeMs: number;
};

const SUSPENSION_REASONS = {
    FRAUD: 'FRAUD',
    VOLUNTARY: 'VOLUNTARY',
    BILLING_INITIATED: 'BILLING_INITIATED',
    CANCEL_REQUESTED: 'CANCEL_REQUESTED',
};

export type SubscriptionDataBe = {
    id: string;
    target_id: string;
    target_type: number;
    relationships: RelationshipDataBe[];
    inventory_ref: InventoryRefBe[];
    billing_obligation_ref: BillingObligationRefBe;
    order_refs: OrderRefsBe[];
    characteristics: CharacteristicsBe;
    offer_id: string;
    offer_name: string;
    grandfathered: boolean;
    create_timestamp: number;
    state: number;
    state_decorator: number;
    state_history: SubscriptionStateHistoryBe[];
    suspension_reasons: Record<keyof typeof SUSPENSION_REASONS, number>;
    recurring: string;
};

export default class SubscriptionData {
    id: string;
    targetId: string;
    targetType: string;
    relationships: RelationshipData[];
    inventoryRef: InventoryRef[];
    billingObligationRef: BillingObligationRef;
    orderRefs: OrderRefs[];
    characteristics: Characteristics;
    offerId: string;
    offerName?: string;
    grandfathered: boolean;
    createTimestamp: number;
    state: number;
    stateDecorator: number;
    stateHistory: SubscriptionStateHistory[];
    suspensionReasons: Array<string>;
    recurring?: string;

    constructor(data: SubscriptionData | Record<string, never> = {}) {
        this.id = data?.id || '';
        this.targetId = data?.targetId || '';
        this.targetType = data?.targetType || '';
        this.relationships = data?.relationships || [];
        this.inventoryRef = data?.inventoryRef || [];
        this.billingObligationRef = data?.billingObligationRef || {};
        this.orderRefs = data?.orderRefs || [];
        this.characteristics = data?.characteristics || {};
        this.offerId = data?.offerId || '';
        this.offerName = data?.offerName || '';
        this.grandfathered = data?.grandfathered || false;
        this.createTimestamp = data?.createTimestamp || 0;
        this.state = data?.state || 0;
        this.stateDecorator = data?.stateDecorator || 0;
        this.stateHistory = data?.stateHistory || [];
        this.suspensionReasons = data?.suspensionReasons || '';
        this.recurring = data?.recurring || '';
    }

    static mapSubscriptionsFromBE(app: SubscriptionDataBe) {
        try {
            return {
                id: app.id,
                targetId: app.target_id,
                targetType: app.target_type,
                relationships: SubscriptionData.mapRelationshipsFromBE(app.relationships),
                inventoryRef: SubscriptionData.mapInventoryRefFromBE(app.inventory_ref),
                billingObligationRef: {
                    billingAccountId: app?.billing_obligation_ref?.billing_account_id,
                    obligationId: app?.billing_obligation_ref?.obligation_id,
                },
                orderRefs: SubscriptionData.mapOrderRefsFromBE(app.order_refs),
                characteristics: app.characteristics,
                offerId: app.offer_id,
                grandfathered: app.grandfathered,
                createTimestamp: app.create_timestamp,
                state: app.state,
                stateDecorator: app.state_decorator,
                stateHistory: app.state_history
                    ? SubscriptionData.mapSubscriptionStateHistoryFromBE(app.state_history)
                    : [],
                suspensionReasons: isEmpty(app?.suspension_reasons)
                    ? ''
                    : Object.entries(app.suspension_reasons).map(([reason, timestamp]) => ({
                          reason,
                          timestamp,
                          timeFormatted: localeLibrary.getFormattedDateAndTime(timestamp),
                      })),
            };
        } catch (e) {
            console.log(e);
        }
    }

    static mapRelationshipsFromBE(items: RelationshipDataBe[]) {
        return items.map(el => ({
            offerId: el.offer_id,
            id: el.id,
            type: el.type,
        }));
    }

    static mapInventoryRefFromBE(items: InventoryRefBe[]) {
        return items.map(el => ({
            entityType: el.entity_type,
            entityId: el.entity_id,
            itemId: el.item_id,
        }));
    }

    static mapOrderRefsFromBE(items: OrderRefsBe[]) {
        return items.map(el => ({
            targetId: el.target_id,
            targetType: el.target_type,
            orderId: el.order_id,
        }));
    }

    static mapSubscriptionStateHistoryFromBE(items: SubscriptionStateHistoryBe[]) {
        return items.map(el => ({
            id: el.id,
            idType: el.id_type,
            state: el.state,
            updatedTimeMs: el.updated_time_ms,
        }));
    }
}
