
import Vue, { PropType } from 'vue';
import { mapGetters } from 'vuex';
import { Getters } from '@/store/mutation-types';
import ENTITY_TYPES from '@/common/entities/entityTypes';
import { STATUS_CODES } from '@/common/commonHelper';
import AppButton from '@/components/partials/inputs/AppButton.vue';
import AppDialog from '@/components/partials/AppDialog.vue';
import AppToggle from '@/components/partials/inputs/AppToggle.vue';
import EntityNotes from '@/components/partials/inputs/EntityNotes.vue';
import { getOperatorConfigValue } from '@/services/permissions/permissions.service';

// Catalog Vuex store is not yet strongly typed. We'll just use a minimal
// dummy placeholder type for now.
//
type Offer = {
    id: string;
    state: number;
    name: string;
    childEntities: { id: string; type: string }[];
};

export default Vue.extend({
    name: 'OfferImpactModal',
    components: {
        AppButton,
        AppDialog,
        AppToggle,
        EntityNotes,
    },
    props: {
        offer: {
            type: Object as PropType<Offer>,
            required: true,
        },
    },
    data() {
        return {
            childPauseFlags: {} as Record<string, boolean>,
            entityNotes: undefined,
            entityNoteOperatorConfig: getOperatorConfigValue('entityNotesEnabled', false),
            ENTITY_TYPES,
        };
    },
    computed: {
        ...mapGetters('productcatalog', {
            getEntitiesByType: Getters.PC_GET_ENTITIES_BY_TYPE,
        }),
        offersById(): Record<string, Offer> {
            return this.getEntitiesByType(ENTITY_TYPES.OFFER);
        },
        childOffers(): Offer[] {
            const childOfferIds = this.offer.childEntities.filter(r => r.type === ENTITY_TYPES.OFFER).map(r => r.id);
            return childOfferIds.map(id => this.offersById[id]);
        },
        orphanedChildOffers(): Offer[] {
            return this.childOffers.filter(childOffer => this.getActiveParentOffers(childOffer.id).length === 1);
        },
        activeParentOffers(): Offer[] {
            return this.getActiveParentOffers(this.offer.id);
        },
        isAppButtonDisabled(): boolean {
            return this.entityNoteOperatorConfig && this.entityNotes?.length === 0;
        },
    },
    mounted() {
        this.childPauseFlags = Object.fromEntries(this.orphanedChildOffers.map(o => [o.id, true]));
    },
    methods: {
        hasChildOffer(offer: Offer, childOfferId: string): boolean {
            return Boolean(offer.childEntities.find(r => r.type === ENTITY_TYPES.OFFER && r.id === childOfferId));
        },
        getActiveParentOffers(childOfferId: string): Offer[] {
            return Object.values(this.offersById).filter(
                o => this.hasChildOffer(o, childOfferId) && o.state === STATUS_CODES.APPROVED,
            );
        },

        // Event handlers
        onCancelButtonClicked() {
            this.$emit('cancel');
        },
        onContinueButtonClicked() {
            const childOfferIdsToPause = Object.entries<boolean>(this.childPauseFlags)
                .filter(([, pauseFlag]) => pauseFlag)
                .map(([offerId]) => offerId);
            const offerIdsToPause = [this.offer.id, ...childOfferIdsToPause];
            const offersToPause = offerIdsToPause.map(id => this.offersById[id]);

            this.$emit('continue', { publishMessage: this.entityNotes, offersToPause });
        },
    },
});
