import http from '@/http';
import download from 'downloadjs';
import { AxiosPromise } from 'axios';
import { type EntityUploadStatus } from '@/__new__/services/dno/progressTracker/models/entity';

type GetSignedUrlResponse = {
    data: {
        signed_url: string;
        required_signed_url_headers?: {
            host: string;
        };
    };
};

export type GetEntityDetailsByNameResponse = {
    data: {
        expected_count: number;
        started_processing_at: number;
        last_updated_at: number;
        event_category_counters: Record<string, number>;
        status: EntityUploadStatus;
        metadata: {
            uploaded_by: string | null;
            principal_id: string | null;
            registered_file_location: string | null;
            processed_file_location: string | null;
            context_metadata?: Record<string, any>;
        };
    };
};

export async function getDownloadUrl(fileName: string, dataflowId: string): AxiosPromise<GetSignedUrlResponse> {
    return http({
        method: 'GET',
        url: '/v3operations/signedurl/download',
        headers: {
            'Content-type': 'application/json',
        },
        params: {
            entity_name: fileName,
            dataflow_id: dataflowId,
        },
    });
}

export async function downloadSignedUrlFile(fileName: string, dataflowId: string): Promise<void> {
    const {
        data: {
            data: { signed_url: url, required_signed_url_headers: headers },
        },
    } = await getDownloadUrl(fileName, dataflowId);
    const fileRes = await fetch(url, { headers });
    const file = await fileRes.text();

    if (!fileRes.ok) {
        throw new Error(`Fetch to endpoint ${url} to download ${fileName} failed.`);
    }

    download(file, fileName, 'text/plain');
}

export async function getEntityDetailsByName(
    name: string,
    dataflowId: string,
): AxiosPromise<GetEntityDetailsByNameResponse> {
    return http({
        method: 'GET',
        url: '/v3operations/entitydetailsbyname',
        headers: {
            'Content-type': 'application/json',
        },
        params: {
            dataflow_id: dataflowId,
            name,
        },
    });
}
export async function getEntityDetailsByCategory(
    name: string,
    dataflowId: string,
    category: string,
    page = 1,
    size = 500,
): AxiosPromise<any> {
    return http({
        method: 'GET',
        url: '/v3operations/entitydetailsbycategory',
        headers: {
            'Content-type': 'application/json',
        },
        params: {
            dataflow_id: dataflowId,
            name,
            category,
            page,
            size,
        },
    });
}
