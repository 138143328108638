import http, { DnoResponseBase } from '@/http/index';
import { AxiosPromise } from 'axios';
import { USER_MANAGER_HIERARCHY } from '@/__new__/features/customerCare/common/customerCareHelper';
import moment from 'moment';
import {
    ChargingBucketWizardApiResponse,
    BucketWizardBucket,
} from '@/__new__/services/dno/charging/common/bucketWizardHelper';

/**
 * Get charging bucket wizard buckets.
 *
 * @returns {AxiosPromise}
 */
export function getChargingBucketWizardBuckets(
    targetType: USER_MANAGER_HIERARCHY,
    targetId: string,
): AxiosPromise<ChargingBucketWizardApiResponse> {
    return http({
        method: 'POST',
        url: '/v3chargingbucketwizard/getbuckets',
        data: {
            target_type: targetType,
            target_id: targetId,
        },
    });
}

/**
 * Update charging bucket wizard buckets.
 *
 * @returns {AxiosPromise}
 */
export function setChargingBucketWizardBuckets(
    targetId: string,
    targetType: USER_MANAGER_HIERARCHY,
    buckets: Record<string, BucketWizardBucket>,
): AxiosPromise<DnoResponseBase> {
    return http({
        method: 'POST',
        url: '/v3chargingbucketwizard/setbuckets',
        data: {
            target_id: targetId,
            target_type: targetType,
            buckets,
        },
    });
}

/**
 * Refer invaliadate bucket metadata.
 *
 * @returns {AxiosPromise}
 */
export function referBucketMetadata(
    targetType: USER_MANAGER_HIERARCHY,
    targetId: string,
): AxiosPromise<ChargingBucketWizardApiResponse> {
    return http({
        method: 'POST',
        url: '/v3chargingbucketwizard/referbucketmetadata',
        data: {
            target_type: targetType,
            target_id: targetId,
        },
    });
}

/**
 * Remove all order, buckets etc.
 *
 * @returns {AxiosPromise}
 */
export function clearBucketMetadata(
    targetType: USER_MANAGER_HIERARCHY,
    targetId: string,
): AxiosPromise<ChargingBucketWizardApiResponse> {
    return http({
        method: 'POST',
        url: '/v3chargingbucketwizard/clearbucketmetadata',
        data: {
            target_type: targetType,
            target_id: targetId,
        },
    });
}

/**
 * Update Order End Time.
 *
 * @returns {AxiosPromise}
 */
export function updateOrderEndTime(
    orders: Record<
        string,
        {
            end_time: number;
        }
    >,
    targetId: string,
    targetType: USER_MANAGER_HIERARCHY,
): AxiosPromise<DnoResponseBase> {
    return http({
        method: 'POST',
        url: '/v3chargingbucketwizard/setorderendtime',
        data: {
            orders,
            target_id: targetId,
            target_type: targetType,
        },
    });
}

/**
 * Charging bucket wizard create order for testing
 *
 * @returns {AxiosPromise}
 */
export function chargingBucketWizardCreateOrder(
    csgId: string,
    endTime: number,
    targetId: string,
    targetType: USER_MANAGER_HIERARCHY,
    orderIdPrefix = '4c770634-ec49-11e8-a12b-0242ac',
): AxiosPromise<DnoResponseBase> {
    const randomId = Math.floor(100000 + Math.random() * 900000);
    return http({
        method: 'POST',
        url: '/v3chargingbucketwizard/createorder',
        data: {
            external_transaction_id: `transaction_id_${randomId}`,
            cs_groups: {
                [csgId]: {
                    parent: {
                        id: 'service_id_1',
                        name: 'Service 1',
                        type: 'service',
                        parent: {
                            id: 'product_id_1',
                            name: 'Product 1',
                            type: 'product',
                        },
                    },
                },
            },
            partial_first_cycle: false,
            offer_name: `Offer mms monetary ${randomId}`,
            offer_amount: '30000',
            entity_id: `offer_id_mms_monetary_${randomId}`,
            offer_id: `offer_id_mms_monetary_${randomId}`,
            entity_type: 'offer',
            order_id: `${orderIdPrefix}${randomId}`,
            start_time: moment().unix(),
            end_time: endTime,
            charged_to: 'bill',
            order_state: 3,
            base_order_id: `${orderIdPrefix}${randomId}`,
            recurrence_limit: 0,
            target_id: targetId,
            target_type: targetType,
        },
    });
}

/**
 * Clear sessions for charging bucket metadata
 *
 * @returns {AxiosPromise}
 */
export function clearSessions(
    targetId: string,
    targetType: USER_MANAGER_HIERARCHY,
    sessions = [] as string[],
    sessionsSy = [] as string[],
    sessionsGx = [] as string[],
): AxiosPromise<DnoResponseBase> {
    return http({
        method: 'POST',
        url: '/v3chargingbucketwizard/clearsessions',
        data: {
            active_sessions: {
                sessions,
                sessions_sy: sessionsSy,
                sessions_gx: sessionsGx,
            },
            target_id: targetId,
            target_type: targetType,
        },
    });
}

/**
 * Trigger RAR sessions for charging bucket metadata
 *
 * @returns {AxiosPromise}
 */
export function triggerRarSession(
    targetId: string,
    targetType: USER_MANAGER_HIERARCHY,
    sessionId: string,
): AxiosPromise<DnoResponseBase> {
    return http({
        method: 'POST',
        url: '/v3chargingbucketwizard/triggerrar',
        data: {
            session_id: sessionId,
            target_id: targetId,
            target_type: targetType,
        },
    });
}

/**
 * Trigger SNR sessions for charging bucket metadata
 *
 * @returns {AxiosPromise}
 */
export function triggerSnrSession(
    targetId: string,
    targetType: USER_MANAGER_HIERARCHY,
    sessionId: string,
): AxiosPromise<DnoResponseBase> {
    return http({
        method: 'POST',
        url: '/v3chargingbucketwizard/triggersnr',
        data: {
            session_id: sessionId,
            target_id: targetId,
            target_type: targetType,
        },
    });
}

/**
 * Update flags value
 *
 * @returns {AxiosPromise}
 */
export function setFlags(
    targetId: string,
    targetType: USER_MANAGER_HIERARCHY,
    flags: Record<string, boolean>,
): AxiosPromise<DnoResponseBase> {
    return http({
        method: 'POST',
        url: '/v3chargingbucketwizard/setflags',
        data: {
            target_id: targetId,
            target_type: targetType,
            flags,
        },
    });
}

/**
 * Update flags value
 *
 * @returns {AxiosPromise}
 */
export function upsertResourceState(
    targetId: string,
    targetType: USER_MANAGER_HIERARCHY,
    state: number,
): AxiosPromise<DnoResponseBase> {
    return http({
        method: 'POST',
        url: '/v3chargingbucketwizard/upsertresourcestate',
        data: {
            target_id: targetId,
            target_type: targetType,
            state,
        },
    });
}

export default {
    getChargingBucketWizardBuckets,
    referBucketMetadata,
    clearBucketMetadata,
    updateOrderEndTime,
    chargingBucketWizardCreateOrder,
    clearSessions,
    triggerRarSession,
    triggerSnrSession,
    setFlags,
    upsertResourceState,
};
