<template>
    <AppMultiselectV3
        :label="label"
        :trackBy="trackBy"
        :value="value"
        :options="options"
        :placeholder="placeholder"
        :multiple="!!config.multiple"
        :error="invalid && displayError"
        :disabled="disabled"
        @input="emitUpdate"
    />
</template>

<script>
import AppMultiselectV3 from '@/components/partials/inputs/AppMultiselectV3.vue';

export default {
    name: 'ConditionArgSelect',
    components: {
        AppMultiselectV3,
    },
    props: {
        value: {
            type: [Object, Array],
            default: null,
        },
        options: {
            type: Array,
            default: () => [],
        },
        placeholder: {
            type: String,
            default: '',
        },
        displayError: {
            type: Boolean,
            default: false,
        },
        config: {
            type: Object,
            default: () => ({}),
        },
        label: {
            type: String,
            default: 'label',
        },
        trackBy: {
            type: String,
            default: 'id',
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        invalid() {
            return this.config.multiple ? !this.value.length : !this.value;
        },
    },
    watch: {
        invalid: {
            immediate: true,
            handler(value) {
                this.$emit('invalid', value);
            },
        },
    },
    methods: {
        emitUpdate(value) {
            let result = value?.id ?? null;
            if (this.config.multiple) {
                result = value.map(({ id }) => id);
            }
            this.$emit('input', result);
        },
    },
};
</script>
