<template>
    <AbstractEditPageWrapper sidebarEnabled="true">
        <template #header>
            <AppHeader :pageTitle="pageTitle" />
        </template>

        <template #content>
            <!-- General section -->
            <div class="editor-section">
                <div class="d-flex align-items-center mb-3">
                    <div
                        v-show="isEditing"
                        class="lf-subtitle mb-0"
                    >
                        {{ $i18n.t('generic.general') }}
                        <p class="lf-secondary-text mb-0">{{ $i18n.t('generic.id') }}: {{ entityId }}</p>
                        <p class="lf-secondary-text mb-0">
                            {{ $i18n.t('generic.updateTime') }}:
                            {{ $localeLibrary.getFormattedDateAndTime(updateTime) }}
                        </p>
                        <p class="lf-secondary-text mb-0">{{ $i18n.t('generic.updateUser') }}: {{ updateName }}</p>
                    </div>
                    <div class="flex-grow-1 d-flex align-items-center justify-content-end">
                        <AppLabel
                            v-if="isDraft"
                            :title="$i18n.t('generic.stateMap.draft')"
                            :color="LABEL_COLOR.gray"
                            class="ml-3"
                        />
                        <AppLabel
                            v-if="isPublished"
                            :title="$i18n.t('generic.stateMap.published')"
                            :color="LABEL_COLOR.green"
                            class="ml-3"
                        />
                        <AppLabel
                            v-if="isUnpublished"
                            :title="$i18n.t('generic.stateMap.unpublishedChanges')"
                            :color="LABEL_COLOR.gray"
                            class="ml-3"
                        />
                        <AppButton
                            v-if="allowEditDraftBtn"
                            :buttonType="BUTTON_TYPES.SECONDARY"
                            :label="$i18n.t('generic.editDraft')"
                            :class="{ 'ml-auto': !isDraft && !isPublished }"
                            class="ml-3"
                            @click="reloadEditor(EDITOR_MODE.EDIT)"
                        />
                        <AppButton
                            v-if="allowViewPublishedBtn"
                            :buttonType="BUTTON_TYPES.SECONDARY"
                            :label="$i18n.t('generic.viewPublished')"
                            :class="{ 'ml-auto': !isDraft && !isPublished }"
                            class="ml-3"
                            @click="reloadEditor(EDITOR_MODE.VIEW)"
                        />
                        <AppButton
                            v-if="isRevertAvailable"
                            :buttonType="BUTTON_TYPES.SECONDARY"
                            :label="$i18n.t('productCatalog.editors.revertToPublished')"
                            class="ml-3"
                            @click="resetToPublished"
                        />
                    </div>
                </div>
            </div>
            <AppMultiselectV3
                v-if="operateApiPermissions"
                v-model="selectedPartyRole"
                :additionalLabel="$i18n.t('operateAPIs.selectChannelPartner')"
                :options="partyRoleOptions"
                :small="true"
                :allowEmpty="false"
                :preselectFirst="true"
                :error="$v.selectedPartyRole.$error"
                :errorMessage="$i18n.t('generic.validations.fieldIsRequired')"
                label="name"
                optionId="id"
                class="editor-input-largest mb-3"
                data-test-id="party-role-multiselect"
            />
            <AppInputV3
                v-model.trim="name[selectedLanguage]"
                :invalid="$v.name[selectedLanguage] && $v.name[selectedLanguage].$error"
                :label="`${$i18n.t('generic.name')}`"
                :placeholder="$i18n.t('generic.addName')"
                :disabled="readonly"
                class="editor-input-largest mb-3"
                type="text"
                data-test-id="input-name"
            />
            <AppTextareaV3
                v-model.trim="description[selectedLanguage]"
                :label="`${$i18n.t('generic.description')}`"
                :placeholder="$i18n.t('partials.appTextarea.description')"
                :disabled="readonly"
                class="editor-input-largest mb-5"
            />
            <div
                v-t="'charging.CSG.editor.chargingSpecificationsGroupParameters'"
                class="section-title"
            />
            <AppToggle
                v-model="isBasePlan"
                :label="$i18n.t('charging.CSG.isBasePlan')"
                :small="true"
                :disabled="readonly"
                class="editor-input-largest mb-3"
            />
            <template v-if="isChargingSpecificationEnabled">
                <AppMultiselectV3
                    v-model="notifications.selectedChargingSpecifications"
                    :additionalLabel="$i18n.t('charging.entities.plural.chargingSpecifications')"
                    :multiple="true"
                    :isSmall="true"
                    :options="realChargingSpecifications"
                    :showLabels="false"
                    :placeholder="$i18n.t('charging.CSG.editor.chooseChargingSpecifications')"
                    :error="$v.notifications.selectedChargingSpecifications.$error"
                    :disabled="readonly"
                    class="editor-input-largest mb-3"
                    label="name"
                    trackBy="id"
                    data-test-id="multiselec-cs"
                    @select="addUiOrder"
                    @remove="removeUiOrder"
                />
                <div class="mb-3">
                    <div class="text-label">
                        {{ $i18n.t('charging.CSG.editor.editChargingSpecification') }}
                        <span
                            class="link"
                            @click="displayAlert"
                        >
                            {{ $i18n.t('charging.CSG.editor.chargingSpecificationsPage') }}
                        </span>
                    </div>
                </div>
            </template>
            <AppInputV3
                v-model.trim="priority"
                :invalid="$v.priority.$error"
                :label="`${$i18n.t('charging.CSG.editor.priorityInOffers')}`"
                :placeholder="$i18n.t('charging.CSG.editor.priorityInOffers')"
                :disabled="readonly"
                class="editor-input-largest mb-3"
                type="text"
                data-test-id="input-priority"
            />
            <div
                v-t="'charging.CSG.editor.usageNotifications'"
                class="section-title"
            />
            <div class="small-gray-note">
                {{ $i18n.t('productCatalog.offers.editor.usageNotificationInfo') }}
            </div>
            <AppToggle
                v-model="notifications.enabled"
                :label="$i18n.t('productCatalog.products.editor.enableNotifications')"
                :disabled="disableNotificationsToggle || readonly"
                :small="true"
                :fitContent="true"
                class="mb-4"
            />
            <UsageNotifications
                v-if="notifications.enabled"
                ref="usageNotificationBasePlan"
                v-model="notifications"
                :errors="$v.notifications"
                :useMessageField="false"
                :disabled="readonly"
            />
            <template v-if="arePolicyCountersEnabled">
                <div
                    v-t="'charging.CSG.editor.policyNotifications'"
                    class="section-title"
                />
                <AppToggle
                    v-model="showPolicyNotification"
                    :label="$i18n.t('productCatalog.products.editor.enableNotifications')"
                    :small="true"
                    :fitContent="true"
                    :disabled="disableNotificationsToggle || readonly"
                    class="mb-4"
                />
                <PolicyNotifications
                    v-if="showPolicyNotification"
                    :initValue="policyNotificationsInitValue"
                    :chargingSpecifications="notifications.selectedChargingSpecifications"
                    :policyCounters="policyCounters"
                    :errors="$v.policyNotifications"
                    :disabled="readonly"
                    @updatePolicyNotifications="updatePolicyNotifications"
                />
            </template>
            <div
                v-t="'charging.CSG.editor.rollovers'"
                class="section-title"
            />
            <AppToggle
                v-model="rollovers.enabled"
                :label="$i18n.t('charging.CSG.editor.enableRollovers')"
                :small="true"
                :fitContent="true"
                :disabled="disableRolloversToggle || readonly"
                class="mb-4"
            />
            <Rollovers
                v-if="rollovers.enabled"
                :rollovers="rollovers"
                :chargingSpecifications="filterQuotaChargingSpecifications"
                :errors="$v.rollovers"
                :disabled="readonly"
                @updateRolloversData="updateRolloversData"
            />
        </template>
        <template #sideBar>
            <!-- Right side dropdown section -->
            <div :class="['white-card-bg', 'draggable-container', { 'disabled-area': readonly }]">
                <div class="card-content-spacing description-block">
                    <div class="card-title mb-4 pl-2">
                        {{ $i18n.t('charging.CSG.chargingSpecificationsExecutionOrder') }}
                    </div>
                    <div class="small-gray-text mb-4 pl-2">
                        {{ $i18n.t('charging.CSG.executionDescription') }}
                    </div>
                    <Draggable
                        v-model="pickedChargingSpecifications"
                        handle=".handle"
                    >
                        <div
                            v-for="(pickedChargingSpecification, index) in pickedChargingSpecifications"
                            :key="pickedChargingSpecification.id"
                            class="dnd-item handle"
                        >
                            <div class="number-wrapper">
                                <div class="number">
                                    {{ index + 1 }}
                                </div>
                            </div>
                            <div class="name-wrapper">
                                {{ pickedChargingSpecification.name[languageDefault] }}
                            </div>
                            <div class="charging-spec-details card-content-spacing white-card-bg">
                                <div class="info">
                                    <div>
                                        <span class="property-name">
                                            {{ `${$i18n.t('generic.id')}:` }}
                                        </span>
                                        {{ pickedChargingSpecification.id }}
                                    </div>
                                    <div>
                                        <span class="property-name">
                                            {{ `${$i18n.t('productCatalog.chargingSpecifications.type')}:` }}
                                        </span>
                                        {{ pickedChargingSpecification.chargingSpecificationType }}
                                    </div>
                                    <div>
                                        <span class="property-name">
                                            {{ `${$i18n.t('productCatalog.entities.charge')}:` }}
                                        </span>
                                        {{ pickedChargingSpecification.chargeString }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Draggable>
                </div>
            </div>
            <div :class="['white-card-bg', 'draggable-container', { 'disabled-area': readonly }]">
                <div class="card-content-spacing description-block">
                    <div class="card-title mb-4 pl-2">
                        {{ $i18n.t('charging.CSG.chargingSpecificationsDisplayOrder') }}
                    </div>
                    <div class="small-gray-text mb-4 pl-2">
                        {{ $i18n.t('charging.CSG.displayDescription') }}
                    </div>
                    <Draggable
                        v-model="pickedChargingSpecificationsForUIOrder"
                        :move="checkDraggabillity"
                        handle=".handle"
                    >
                        <div
                            v-for="(chargingSpecification, index) in pickedChargingSpecificationsForUIOrder"
                            :key="chargingSpecification.value.id"
                            class="dnd-item handle"
                        >
                            <div
                                :class="{ empty: !chargingSpecification.visibillity }"
                                class="number-wrapper"
                            >
                                <div class="number">
                                    {{ getOrderTitle(chargingSpecification.visibillity, index) }}
                                </div>
                            </div>
                            <div
                                :class="{ 'hidden-chargingSpecification': !chargingSpecification.visibillity }"
                                class="charging-spec-content"
                            >
                                <div class="name-wrapper truncate-text">
                                    {{ getDefaultName(chargingSpecification.value.name) }}

                                    <span class="hidden-label">
                                        {{ $i18n.t('generic.hidden') }}
                                    </span>
                                </div>

                                <div
                                    :class="{ 'cross-eye': chargingSpecification.visibillity }"
                                    class="icon-wrap"
                                    @click="changeChargingSpecificationVisibillity(index)"
                                >
                                    <div class="icon-text">
                                        {{ getOrderIconText(chargingSpecification.visibillity) }}
                                    </div>
                                </div>
                            </div>
                            <div class="charging-spec-details card-content-spacing white-card-bg">
                                <div>
                                    {{ chargingSpecification.value.name[languageDefault] }}
                                </div>
                                <div class="info">
                                    <div>
                                        <span class="property-name">
                                            {{ `${$i18n.t('generic.id')}:` }}
                                        </span>
                                        {{ chargingSpecification.value.id }}
                                    </div>
                                    <div>
                                        <span class="property-name">
                                            {{ `${$i18n.t('charging.chargingSpecifications.type')}:` }}
                                        </span>
                                        {{ chargingSpecification.value.chargingSpecificationType }}
                                    </div>
                                    <div>
                                        <span class="property-name">
                                            {{ `${$i18n.t('productCatalog.entities.charge')}:` }}
                                        </span>
                                        {{ chargingSpecification.value.chargeString }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Draggable>
                </div>
            </div>
        </template>
        <template #modal>
            <MutationDialog
                v-model="showModal"
                :saveButtonEnabled="true"
                :entities="getAffectedEntities()"
                @updateEntity="saveAfterConfirm"
            />
        </template>
        <template #controls>
            <EditorButtons
                :showSaveDraft="!readonly"
                :showPublish="!readonly"
                :disableSave="readonly || disableSaveButton"
                data-test-id="submit-button"
                @cancel="onCancel"
                @saveDraft="onSave(false)"
                @publish="onSave(true)"
                @save="onSave"
            />
        </template>
    </AbstractEditPageWrapper>
</template>

<script>
// vuex
import { mapGetters, mapActions } from 'vuex';
import Actions, { Getters, State } from '@/store/mutation-types';
import { Modules } from '@/store/store';

// Http
import { getChargingSpecificationsGroupEntityDraft } from '@/__new__/services/dno/charging/http/chargingSpecificationsGroups';
import { getUserNameById } from '@/__new__/services/portal/profile/http/profile';
import { getPartyRoles } from '@/__new__/services/dno/partyRolesPermissionsManagement/http/partyRolesPermissionsManagement';

// components
import AbstractEditPageWrapper from '@/components/layout/AbstractEditPageWrapper.vue';
import AppMultiselectV3 from '@/components/partials/inputs/AppMultiselectV3.vue';
import { ALERT_TYPES, ALERT_COLORS } from '@/common/alerts/Alert';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import { ICON_TYPES } from '@/common/iconHelper';
import AppHeader from '@/components/layout/AppHeader.vue';
import AppTextareaV3 from '@/components/partials/inputs/AppTextareaV3.vue';
import AppInputV3 from '@/components/partials/inputs/AppInputV3.vue';
import AppToggle from '@/components/partials/inputs/AppToggle.vue';
import Button from '@/common/button/Button';
import { checkObjectPath } from '@/common/utils';
import UsageNotifications from '@/__new__/features/charging/chargingSpecificationsGroups/UsageNotifications.vue';
import PolicyNotifications from '@/__new__/features/charging/chargingSpecificationsGroups/PolicyNotifications.vue';
import Rollovers from '@/__new__/features/charging/chargingSpecificationsGroups/Rollovers.vue';
import EditorButtons from '@/components/layout/EditorButtons.vue';
import AppLabel from '@/components/partials/AppLabel.vue';

// helpers
import ENTITY_TYPES from '@/common/entities/entityTypes';
import Threshold from '@/__new__/services/dno/charging/common/threshold';
import {
    getProperlyFormattedMultilangFieldValue,
    getMultiLangFieldValueByLocale,
    getAffectedEntities,
    getEntityConsumers,
    formatMutationDialogEntities,
    EDITOR_MODE,
} from '@/common/entities/entityHelper';
import {
    getChargingSpecificationTypeAsString,
    getChargeText,
    CHARGING_TYPE,
    getCSType,
    CSG_ROLLOVERS_FIRST_USE_TYPES,
    CSG_ROLLOVERS_FIRST_USE_OPTIONS,
    CHARGING_TYPE_MAP_VALUES,
} from '@/__new__/services/dno/charging/common/chargingSpecificationHelper';
import RouteNames from '@/router/routeNames';
import MutationDialog from '@/components/partials/MutationDialog.vue';
import Draggable from 'vuedraggable';
import permissionsService, { isUserAllowed } from '@/services/permissions/permissions.service';
import mapValues from 'lodash/mapValues';
import { languageMap } from '@/common/locale/language';
import {
    thresholdTypes as rolloverTypes,
    TYPES_OPTIONS,
} from '@/__new__/services/dno/charging/common/policyCounterHelper';
import { LABEL_COLOR } from '@/common/labelsHelper';
import { STATUS_CODES } from '@/common/commonHelper';
import cloneDeep from 'lodash/cloneDeep';
import * as Sentry from '@sentry/vue';

// validations
import { validationMixin } from 'vuelidate';
import { required, requiredIf, numeric, minValue, maxValue } from 'vuelidate/lib/validators';

// i18n
import i18n from '@/i18n';

// mixins
import entityEditorMixin from '@/common/entityEditorMixin';
import mutationDialogMixin from '@/components/partials/mutations/mutationDialogMixin.vue';
import { DATA_DEFINITIONS, getScaledAmount } from '@/common/formatting';
import ChargingCommonActionsMixin from '@/__new__/features/charging/ChargingCommonActionsMixin.vue';

export default {
    name: 'ChargingSpecificationsGroupEditor',
    components: {
        AbstractEditPageWrapper,
        AppTextareaV3,
        AppHeader,
        AppInputV3,
        AppButton,
        AppToggle,
        AppMultiselectV3,
        Draggable,
        MutationDialog,
        UsageNotifications,
        PolicyNotifications,
        Rollovers,
        EditorButtons,
        AppLabel,
    },
    mixins: [validationMixin, entityEditorMixin, mutationDialogMixin, ChargingCommonActionsMixin],
    data() {
        return {
            selectedPartyRole: null,
            partyRoleOptions: [],
            name: {
                en: '',
            },
            description: {
                en: '',
            },
            data: {},
            permissionsService,
            selectedLanguage: '',
            isBasePlan: false,
            priority: null,
            notifications: {
                enabled: false,
                selectedThresholdChargingSpecification: null,
                thresholds: {},
                selectedChargingSpecifications: [],
            },
            rollovers: {
                maxRolloverCount: null,
                enabled: false,
                data: [],
            },
            enablePolicyNotification: [],
            showPolicyNotification: false,
            policyNotifications: [],
            policyNotificationsInitValue: [],
            uiOrderChargingSpecifications: [],
            entityType: ENTITY_TYPES.CHARGING_SPECIFICATIONS_GROUP,
            ICON_TYPES,
            BUTTON_TYPES,
            activeLocale: 'en',
            ENTITY_TYPES,
            showSubscriptionTypeTooltip: false,
            entityId: null,
            version: null,

            // data for drafts
            EDITOR_MODE,
            LABEL_COLOR,
            STATUS_CODES,
            updateTime: null,
            readonly: false,
            updateName: this.$i18n.t('generic.N/A'),
            entityDraft: null,
            isUnpublished: false,
            isOnlyDraft: false,
            revertConfirmationButton: new Button({
                label: this.$i18n.t('productCatalog.editors.revert'),
                alertType: ALERT_TYPES.warning,
                handler: () => this.initData(true),
            }),
            operateApiPermissions: isUserAllowed('OperateAPIsApplicationsWrite'),
        };
    },
    validations() {
        return {
            selectedPartyRole: {
                // eslint-disable-next-line func-names
                required: requiredIf(function () {
                    return this.operateApiPermissions;
                }),
            },
            name: {
                en: {
                    required,
                },
            },
            priority: {
                required,
                minValue: minValue(1),
                numeric,
            },
            notifications: {
                selectedChargingSpecifications: {
                    required: requiredIf(() => this.areUsageNotificationsEnabled),
                },
                selectedThresholdChargingSpecification: {
                    required: requiredIf(
                        () => this.areUsageNotificationsEnabled && !Object.values(this.notifications.thresholds).length,
                    ),
                },
                thresholds: {
                    required: requiredIf(() => this.areUsageNotificationsEnabled),
                    $each: {
                        $each: {
                            value: {
                                required: requiredIf(() => this.areUsageNotificationsEnabled),
                                minValue: minValue(1),
                            },
                        },
                    },
                },
            },
            policyNotifications: {
                $each: {
                    chargingSpec: {
                        required,
                    },
                    policyCounter: {
                        required,
                    },
                    policyCounterThreshold: {
                        required,
                    },
                    thresholds: {
                        $each: {
                            value: {
                                required,
                                minValue: minValue(1),
                            },
                        },
                    },
                },
            },
            rollovers: {
                maxRolloverCount: {
                    // eslint-disable-next-line func-names
                    required: requiredIf(function () {
                        return this.rollovers?.enabled;
                    }),
                    minValue: minValue(0),
                },
                data: {
                    $each: {
                        chargingSpecification: {
                            id: {
                                // eslint-disable-next-line func-names
                                required: requiredIf(function () {
                                    return this.rollovers?.enabled;
                                }),
                            },
                        },
                        type: {
                            value: {
                                // eslint-disable-next-line func-names
                                required: requiredIf(function () {
                                    return this.rollovers?.enabled;
                                }),
                            },
                        },
                        maxRolloverUnits: {
                            // eslint-disable-next-line func-names
                            required: requiredIf(function (value) {
                                return this.rollovers?.enabled && value.type.value === TYPES_OPTIONS.QUOTA;
                            }),
                            minValue: minValue(0),
                        },
                        maxRolloverPercentage: {
                            // eslint-disable-next-line func-names
                            required: requiredIf(function (value) {
                                return this.rollovers?.enabled && value.type.value === TYPES_OPTIONS.PERCENTAGE;
                            }),
                            minValue: minValue(0),
                            maxValue: maxValue(100),
                        },
                        firstUse: {
                            id: {
                                // eslint-disable-next-line func-names
                                required: requiredIf(function () {
                                    return this.rollovers?.enabled;
                                }),
                            },
                        },
                    },
                },
            },
        };
    },
    computed: {
        ...mapGetters(Modules.charging, [
            Getters.GET_CHARGING_SPECIFICATION_BY_ID,
            Getters.GET_CHARGING_SPECIFICATIONS_GROUP_BY_ID,
            Getters.GET_ONLY_PUBLISHED_CHARGING_SPECIFICATIONS,
        ]),
        ...mapGetters(Modules.chargingV2, [Getters.GET_POLICY_COUNTERS]),
        languages() {
            return this.$store.state.operators[State.Languages];
        },
        languageDefault() {
            return this.$store.getters[`operators/${Getters.languageDefault}`];
        },
        arePolicyCountersEnabled() {
            return this.permissionsService.chargingPolicyCountersEnabled() && isUserAllowed('PolicyCountersReadOnly');
        },
        realChargingSpecifications() {
            if (this[Getters.GET_ONLY_PUBLISHED_CHARGING_SPECIFICATIONS]) {
                return this.formatChargingSpecificationsForDropdown(
                    this[Getters.GET_ONLY_PUBLISHED_CHARGING_SPECIFICATIONS],
                );
            }
            return [];
        },
        filterQuotaChargingSpecifications() {
            return (
                this.notifications?.selectedChargingSpecifications?.filter(
                    ({ chargeType }) => chargeType === CHARGING_TYPE.quota,
                ) || []
            );
        },
        affectedEntities() {
            return getAffectedEntities(this.$attrs.id, ENTITY_TYPES.CHARGING_SPECIFICATIONS_GROUP);
        },
        upperEntities() {
            return getEntityConsumers(this.$attrs.id, ENTITY_TYPES.CHARGING_SPECIFICATIONS_GROUP);
        },
        pickedChargingSpecifications: {
            get() {
                return this.notifications.selectedChargingSpecifications.map(s =>
                    this.getFormattedChargingSpecificationForOverview(s.id),
                );
            },
            set(val) {
                this.notifications.selectedChargingSpecifications = this.formatChargingSpecificationsForOverview(val);
            },
        },
        pickedChargingSpecificationsForUIOrder: {
            get() {
                return this.uiOrderChargingSpecifications;
            },
            set(val) {
                this.uiOrderChargingSpecifications = val;
            },
        },
        policyCounters() {
            if (this.arePolicyCountersEnabled) {
                return this[Getters.GET_POLICY_COUNTERS].map(el => {
                    el.label = el.name;
                    return el;
                });
            }
            return [];
        },
        disableRolloversToggle() {
            return this.filterQuotaChargingSpecifications.length < 1;
        },
        disableNotificationsToggle() {
            return this.notifications?.selectedChargingSpecifications?.length < 1;
        },
        areUsageNotificationsEnabled() {
            return this.notifications?.enabled && this.isChargingSpecificationEnabled;
        },
        isChargingSpecificationEnabled() {
            return (
                this.permissionsService.chargingChargingSpecificationsEnabled() &&
                isUserAllowed('ChargingSpecificationsReadOnly')
            );
        },
        chargingSpecificationGroupData() {
            return this[Getters.GET_CHARGING_SPECIFICATIONS_GROUP_BY_ID](this.$route.params.id) || {};
        },
        isDraft() {
            return this.$route.params.id && !this.readonly && this.entityDraft;
        },
        isPublished() {
            return this.$route.params.id && this.readonly;
        },
        allowEditDraftBtn() {
            return this.$route.params.id && this.readonly && !this.isOnlyDraft;
        },
        isRevertAvailable() {
            return this.isEditing && !this.readonly && !this.isOnlyDraft;
        },
        allowViewPublishedBtn() {
            return this.$route.params.id && !this.readonly && !this.isOnlyDraft;
        },
    },
    created() {
        this.$withLoadingSpinner(
            async () => {
                const { id, readonly, mode, clone } = this.$route.params;
                this.entityId = id;

                if (this.entityId && readonly) {
                    this.readonly = readonly;
                }

                if (this.entityId && mode) {
                    this.readonly = mode === EDITOR_MODE.VIEW;
                }

                this.selectedLanguage = (this[Getters.languageDefault] || languageMap.en.key).toString();

                const promises = [
                    this[Actions.REQUEST_CHARGING_SPECIFICATIONS](),
                    this[Actions.REQUEST_CHARGING_SPECIFICATIONS_GROUPS](),
                    this[Actions.PC_REQUEST_ENTITIES_BY_TYPE_AND_IDS]({ entityType: ENTITY_TYPES.OFFER }),
                    this[Actions.PC_REQUEST_ENTITIES_BY_TYPE_AND_IDS]({ entityType: ENTITY_TYPES.CATEGORY }),
                ];

                if (this.arePolicyCountersEnabled) {
                    promises.push(this[Actions.REQUEST_POLICY_COUNTERS]());
                }

                if (!clone && !this.readonly) {
                    promises.push(this.loadEntityDraft());
                }

                if (this.operateApiPermissions) {
                    const response = await getPartyRoles('ChannelPartner');
                    this.partyRoleOptions = response?.data?.map(partyRole => ({
                        id: partyRole?.id || '',
                        name: partyRole?.name || '',
                    }));
                }

                await Promise.all(promises);

                this.alertButtons.openChargingSpecification = new Button(
                    this.$i18n.t('charging.chargingSpecifications.editor.openChargingSpecifications'),
                    '',
                    ALERT_COLORS.yellow,
                );

                if (id) {
                    this.initData();
                }
            },
            {
                errorHandler: () => {
                    this.$eventBus.$emit('showAlert', {
                        message: i18n.t('alertMessage.errorDoingSmthTryAgain', {
                            action: 'fetching',
                            entityName: 'chargingSpecificationsGroups',
                        }),
                    });
                },
            },
        );
    },
    methods: {
        ...mapActions(Modules.productcatalog, [Actions.PC_REQUEST_ENTITIES_BY_TYPE_AND_IDS]),
        ...mapActions(Modules.charging, [
            Actions.REQUEST_CHARGING_SPECIFICATIONS_GROUPS,
            Actions.REQUEST_CHARGING_SPECIFICATIONS,
        ]),
        ...mapActions(Modules.chargingV2, [Actions.REQUEST_POLICY_COUNTERS]),
        getDefaultName(name) {
            return getMultiLangFieldValueByLocale(name, this.languageDefault);
        },
        createDropdownPayload(cs) {
            return {
                id: cs.id,
                name: getMultiLangFieldValueByLocale(cs.data.name),
                policyCounters: this.getPolicyCountersForCS(cs.data),
                chargeType: CHARGING_TYPE_MAP_VALUES.get(cs.data.charge_type),
                // eslint-disable-next-line camelcase
                serviceTypes: getCSType(cs.data.service_types?.[0]),
            };
        },
        formatChargingSpecificationsForDropdown(chargingSpecification) {
            return chargingSpecification.map(cs => this.createDropdownPayload(cs));
        },
        getChargingSpecificationsAndFormatForDropdown(sId) {
            const cs = Object.create(this[Getters.GET_CHARGING_SPECIFICATION_BY_ID](sId));
            return this.createDropdownPayload(cs);
        },
        getPolicyCountersForCS(data) {
            // If there is only one id API return string with policy_counter_id key,
            // if several it return an array with policy_counter_ids key.
            if (data && data.policy_counter_ids) {
                return data.policy_counter_ids;
            }
            if (data && data.policy_counter_id) {
                return [data.policy_counter_id];
            }
            return [];
        },
        formatChargingSpecificationsForOverview(chargingSpecification) {
            return chargingSpecification.map(cs => ({
                id: cs.id,
                name: getMultiLangFieldValueByLocale(cs.name),
            }));
        },
        getFormattedChargingSpecificationForOverview(sId) {
            const { data = {}, id } = Object.create(this[Getters.GET_CHARGING_SPECIFICATION_BY_ID](sId));
            return {
                name: getProperlyFormattedMultilangFieldValue(data.name),
                id,
                // eslint-disable-next-line camelcase
                chargingSpecificationType: getChargingSpecificationTypeAsString(data?.service_types?.[0]),
                chargeString: getChargeText(data),
            };
        },
        displayAlert() {
            this.$eventBus.$emit('showAlert', {
                message: this.$i18n.t('alertMessage.doYouWantToLeaveThePage'),
                type: ALERT_TYPES.warning,
                buttons: [this.alertButtons.openChargingSpecification],
            });
            this.$eventBus.$once('buttonClicked', id => {
                if (id === this.alertButtons.openChargingSpecification.id) {
                    this.$router.push({
                        name: RouteNames.CHARGING_CHARGING_SPECIFICATIONS,
                        params: { companyId: this.$route.params.companyId },
                    });
                }
            });
        },
        async onSave(isPublish = true) {
            if (isPublish) {
                this.$v.$touch();

                if (this.$v.$invalid) {
                    this.$Progress.fail();
                    return;
                }

                const isAnyTresholdMessageEmpty = !this.checkUsageNotificationMessage(this.notifications);
                if (isAnyTresholdMessageEmpty) {
                    this.$eventBus.$emit('showAlert', {
                        message: this.$i18n.t('messages.usageNotificationMessage'),
                    });
                    this.$Progress.fail();
                    return;
                }
            }

            this.data = this.buildData();
            this.data.approve_on_create = isPublish;

            await this.saveEntityData(this.isOnlyDraft, this.affectedEntities.offer || [], isPublish);
        },
        getChargingSpecification(id) {
            return this.realChargingSpecifications.find(cs => cs.id === id);
        },
        checkUsageNotificationMessage(chargingSpecificationTypeNotification) {
            if (chargingSpecificationTypeNotification.enabled) {
                return Object.values(chargingSpecificationTypeNotification.thresholds).length;
            }
            return true;
        },
        initData(forceInitPublished = false) {
            const csg = this.chargingSpecificationGroupData;
            let entityData;
            let csgData;

            if (this.entityDraft && !forceInitPublished) {
                entityData = cloneDeep(this.entityDraft);
                entityData.version = csg.version;
                csgData = entityData.data;
                const publishedEntity = csg;
                this.isOnlyDraft = publishedEntity?.state === STATUS_CODES.NA;
                if (publishedEntity?.update_time <= entityData?.update_time) {
                    this.isUnpublished = true;
                }
            } else {
                entityData = cloneDeep(csg);
                csgData = entityData.data;
            }

            this.name = getProperlyFormattedMultilangFieldValue(csgData.name);
            if (this.$route.params.clone) {
                this.name[this.selectedLanguage] += ' (cloned)';
            }

            this.version = entityData.version;
            this.updateTime = entityData.update_time;
            this.getUpdateUserName(entityData.update_portal_id);

            this.description = getProperlyFormattedMultilangFieldValue(csgData.description);
            this.priority = csgData.priority;
            this.isBasePlan = csgData.is_base_plan;
            const chargingSpecifications = csgData.chargingSpecifications || [];
            const uiOrder = csgData.ui_order || [];

            if (this.operateApiPermissions) {
                this.selectedPartyRole = csgData.channel_partner_id;
            }

            const visibleChargingSpecifications = uiOrder.map(visibleCsId => ({
                value: this.getChargingSpecification(visibleCsId),
                visibillity: true,
            }));

            const invisibleChargingSpecifications = chargingSpecifications
                .filter(csId => !uiOrder.includes(csId))
                .map(csId => ({
                    value: this.getChargingSpecification(csId),
                    visibillity: false,
                }));

            this.$set(
                this.notifications,
                'selectedChargingSpecifications',
                csgData.charging_specs
                    .map(csId => this.getChargingSpecification(csId))
                    .filter(el => {
                        if (!el) {
                            this.$eventBus.$emit('showAlert', {
                                message: this.$i18n.t('charging.CSG.editor.errors.chargingSpecificationMissing'),
                            });
                        }
                        return Boolean(el);
                    }),
            );

            this.uiOrderChargingSpecifications = visibleChargingSpecifications
                .concat(invisibleChargingSpecifications)
                .filter(el => {
                    if (!el || !el.value) {
                        this.$eventBus.$emit('showAlert', {
                            message: this.$i18n.t('charging.CSG.editor.errors.chargingSpecificationMissing'),
                        });
                    }
                    return Boolean(el?.value);
                });

            if (csgData.usage_notifications) {
                this.notifications.enabled = true;
                this.$set(
                    this.notifications,
                    'thresholds',
                    mapValues(csgData.usage_notifications, thresholds => thresholds.map(t => new Threshold(t.value))),
                );
            }

            if (csgData.policy_notifications) {
                this.showPolicyNotification = true;
                const policyNotificationsLocal = [];
                // eslint-disable-next-line guard-for-in
                for (const chargingSpecId in csgData.policy_notifications) {
                    const chargingSpec = this.notifications.selectedChargingSpecifications.find(
                        el => el.id === chargingSpecId,
                    );
                    // eslint-disable-next-line guard-for-in
                    for (const policyCounterId in csgData.policy_notifications[chargingSpecId]) {
                        const policyCounter = this.policyCounters.find(el => el.id === policyCounterId);
                        // eslint-disable-next-line guard-for-in
                        for (const threshold in csgData.policy_notifications[chargingSpecId][policyCounterId]) {
                            policyNotificationsLocal.push({
                                chargingSpec,
                                policyCounter,
                                policyCounterThreshold: {
                                    value: Number(threshold),
                                    label: `${getScaledAmount(DATA_DEFINITIONS[0], threshold)}${
                                        DATA_DEFINITIONS[0].label
                                    }`,
                                },
                                thresholds: csgData.policy_notifications[chargingSpecId][policyCounterId][
                                    threshold
                                ].map(el => ({ value: el })),
                            });
                        }
                    }
                }
                this.policyNotificationsInitValue = policyNotificationsLocal;
            }

            if (csgData.rollover) {
                const { max_rollover_count: maxRolloverCount, charging_spec_map: chargingSpecMap } = csgData.rollover;

                this.rollovers = {
                    enabled: true,
                    maxRolloverCount,
                    data: Object.keys(chargingSpecMap).map(csID => {
                        const {
                            max_rollover_units: maxRolloverUnits = null,
                            max_rollover_percentage: maxRolloverPercentage = null,
                            newest_first: newestFirst,
                        } = chargingSpecMap[csID];

                        return {
                            chargingSpecification: this.getChargingSpecificationsAndFormatForDropdown(csID),
                            type: maxRolloverUnits
                                ? rolloverTypes[TYPES_OPTIONS.QUOTA]
                                : rolloverTypes[TYPES_OPTIONS.PERCENTAGE],
                            maxRolloverUnits,
                            maxRolloverPercentage,
                            firstUse: newestFirst
                                ? CSG_ROLLOVERS_FIRST_USE_OPTIONS[CSG_ROLLOVERS_FIRST_USE_TYPES.NEW_BALANCE]
                                : CSG_ROLLOVERS_FIRST_USE_OPTIONS[CSG_ROLLOVERS_FIRST_USE_TYPES.ROLLED_OVER_BALANCE],
                        };
                    }),
                };
            }
        },
        buildData() {
            let data = {
                name: this.name,
                description: this.description,
                priority: parseInt(this.priority, 10),
                is_base_plan: this.isBasePlan,
            };

            // Setting chargingSpecifications
            if (this.isChargingSpecificationEnabled) {
                data.charging_specs = this.notifications?.selectedChargingSpecifications?.map(s => s.id);
            }

            // setting order of chargingSpecifications to show to user
            if (this.uiOrderChargingSpecifications.length) {
                data.ui_order = this.uiOrderChargingSpecifications
                    .filter(chargingSpecification => chargingSpecification.visibillity)
                    .map(chargingSpecification => chargingSpecification.value.id);
            }
            // If usage notification is enabled, create that key and populate it with thresholds.
            if (this.notifications.enabled) {
                // Threshold objects in the Vue application have "id" properties to allow
                // v-for to work properly. However, the product schema doesn't have IDs on its
                // thresholds, so we strip it off here.
                //
                data.usage_notifications = this.notifications?.thresholds
                    ? mapValues(this.notifications.thresholds, thresholds =>
                          thresholds.map(({ value }) => ({
                              value,
                          })),
                      )
                    : [];
            }
            // We check if we have any policy notification and if policy notifications enabled,
            // in other case we just not add policy notification on payload
            if (this.policyNotifications.length && this.enablePolicyNotification) {
                data.policy_notifications = this.policyNotifications.reduce((accumulator, currentValue) => {
                    if (
                        currentValue?.chargingSpec?.id &&
                        currentValue?.policyCounter?.id &&
                        currentValue?.policyCounterThreshold
                    ) {
                        if (!Object.prototype.hasOwnProperty.call(accumulator, currentValue?.chargingSpec?.id)) {
                            accumulator[`${currentValue?.chargingSpec?.id}`] = {};
                        }
                        if (
                            !Object.prototype.hasOwnProperty.call(
                                accumulator[`${currentValue?.chargingSpec?.id}`],
                                currentValue.policyCounter.id,
                            )
                        ) {
                            accumulator[`${currentValue?.chargingSpec?.id}`][`${currentValue?.policyCounter?.id}`] = {};
                        }
                        if (
                            !Object.prototype.hasOwnProperty.call(
                                accumulator[`${currentValue.chargingSpec.id}`][`${currentValue?.policyCounter?.id}`],
                                currentValue?.policyCounterThreshold?.value,
                            )
                        ) {
                            accumulator[`${currentValue?.chargingSpec?.id}`][`${currentValue?.policyCounter?.id}`][
                                `${currentValue?.policyCounterThreshold?.value}`
                            ] = [];
                        }

                        const thresholdArr = [];
                        // eslint-disable-next-line guard-for-in
                        for (const threshold of currentValue?.thresholds) {
                            thresholdArr.push(threshold.value);
                        }
                        accumulator[`${currentValue?.chargingSpec?.id}`][`${currentValue?.policyCounter?.id}`][
                            `${currentValue?.policyCounterThreshold?.value}`
                        ]?.push(...thresholdArr);
                    }

                    return accumulator;
                }, {});
            }

            if (this.rollovers.enabled) {
                data = {
                    ...data,
                    rollover: {
                        max_rollover_count: this.rollovers?.maxRolloverCount ?? null,
                        charging_spec_map: {},
                    },
                };

                this.rollovers.data.forEach(el => {
                    const isQuota = el?.type?.value === TYPES_OPTIONS.QUOTA;
                    if (el?.chargingSpecification?.id) {
                        data.rollover.charging_spec_map[el.chargingSpecification.id] = {
                            newest_first: el?.firstUse?.id === CSG_ROLLOVERS_FIRST_USE_TYPES.NEW_BALANCE,
                            [isQuota ? 'max_rollover_units' : 'max_rollover_percentage']: isQuota
                                ? el.maxRolloverUnits
                                : el.maxRolloverPercentage,
                        };
                    }
                });
            }

            if (this.operateApiPermissions) {
                data.channel_partner_id = this.selectedPartyRole;
            }

            return data;
        },
        addUiOrder(value) {
            const invisibleChargingSpecifications = this.uiOrderChargingSpecifications.filter(
                chargingSpecification => !chargingSpecification.visibillity,
            );
            const visibleChargingSpecifications = this.uiOrderChargingSpecifications.filter(
                chargingSpecification => chargingSpecification.visibillity,
            );
            visibleChargingSpecifications.push({
                value,
                visibillity: true,
            });
            this.uiOrderChargingSpecifications = visibleChargingSpecifications.concat(invisibleChargingSpecifications);
        },
        removeUiOrder(deletedChargingSpecification) {
            this.uiOrderChargingSpecifications = this.uiOrderChargingSpecifications.filter(
                chargingSpecification => chargingSpecification.value.id !== deletedChargingSpecification.id,
            );
            this.$refs.usageNotificationBasePlan?.removeChargingSpecFromThreshold(deletedChargingSpecification.id);
        },
        changeChargingSpecificationVisibillity(index) {
            this.uiOrderChargingSpecifications[index].visibillity =
                !this.uiOrderChargingSpecifications[index].visibillity;
            if (!this.uiOrderChargingSpecifications[index].visibillity) {
                const currentChargingSpecification = this.uiOrderChargingSpecifications[index];
                this.uiOrderChargingSpecifications = this.uiOrderChargingSpecifications.filter(
                    chargingSpecification => chargingSpecification.value.id !== currentChargingSpecification.value.id,
                );
                this.uiOrderChargingSpecifications.push(currentChargingSpecification);
            } else {
                const invisibleChargingSpecifications = this.uiOrderChargingSpecifications.filter(
                    chargingSpecification => !chargingSpecification.visibillity,
                );
                this.uiOrderChargingSpecifications = this.uiOrderChargingSpecifications
                    .filter(chargingSpecification => chargingSpecification.visibillity)
                    .concat(invisibleChargingSpecifications);
            }
        },
        checkDraggabillity(e) {
            return (
                checkObjectPath(e, 'draggedContext.element.visibillity') &&
                checkObjectPath(e, 'relatedContext.element.visibillity') &&
                e.draggedContext.element.visibillity &&
                e.relatedContext.element.visibillity
            );
        },
        getOrderTitle(visibillity, index) {
            return visibillity ? index + 1 : '';
        },
        getOrderIconText(visibillity) {
            return visibillity ? this.$i18n.t('generic.hide') : this.$i18n.t('generic.show');
        },
        getAffectedEntities() {
            return formatMutationDialogEntities(this.affectedEntities);
        },
        updatePolicyNotifications(policyNotifications) {
            this.policyNotifications = [...policyNotifications];
        },
        updatePolicyNotificationToggle(value) {
            this.enablePolicyNotification = value;
        },
        updateRolloversData(value) {
            this.rollovers = value;
        },
        async loadEntityDraft() {
            if (this.$route.params.id) {
                try {
                    if (!this.readonly) {
                        const result = await getChargingSpecificationsGroupEntityDraft(this.$route.params.id);
                        const data = result?.data?.data?.[this.$route.params.id] || null;
                        this.entityDraft = {
                            ...data,
                            isDraft: true,
                            state: STATUS_CODES.NA,
                            entityVersion: STATUS_CODES.DRAFT,
                            updateTime: data.update_time,
                        };
                    }
                } catch (e) {
                    this.$alert(this.$i18n.t('alertMessage.failedToLoadNecessaryData'), { type: ALERT_TYPES.error });
                }
            }
        },
        reloadEditor(mode) {
            const { id } = this.$route.params;
            // Use push to list page because router don`t want ot reload same page
            this.$router
                .push({
                    name: RouteNames.CHARGING_CHARGING_SPECIFICATIONS_GROUPS,
                    params: { companyId: this.$route.params.companyId },
                })
                .then(() => {
                    this.$router.push({
                        name: RouteNames.CHARGING_SPECIFICATIONS_GROUP_EDITOR,
                        params: {
                            id,
                            mode,
                            companyId: this.$route.params.companyId,
                        },
                    });
                });
        },
        async getUpdateUserName(id) {
            try {
                if (id) {
                    const response = await getUserNameById(Number(id));
                    if (response?.data) {
                        this.updateName = response.data;
                    }
                }
            } catch (e) {
                Sentry.captureException(e);
            }
        },
        resetToPublished() {
            this.$alert(this.$i18n.t('productCatalog.editors.revertWarning'), {
                type: ALERT_TYPES.warning,
                buttons: [this.revertConfirmationButton],
            });
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/editor-layout-v2';
@import '~@/assets/scss/typographyV2';
@import '~@/assets/scss/palette';
@import '~@/assets/scss/icons';
@import '~@/assets/scss/mixins';
@import '~@/assets/scss/animations';

$icon-path: '~@/assets/icons/';

.text-label {
    font-size: 0.875rem;
    line-height: 1.625rem;
    color: $gray60;
}

.link {
    @include linkStyle(underline);
}

.description-block {
    textarea {
        width: 100%;
    }
}

.product-type-label {
    text-align: right;
}

.row.save-button {
    justify-content: flex-end;
    margin: 1rem 2rem;
}

.buttons-row {
    margin: 0.5rem;
}

.small-gray-text {
    margin-bottom: 0.938rem;
    font-size: 0.813rem;
    color: $gray5;
}

.gray-bar {
    background-color: $white;
    padding: 0.25rem;
    font-weight: bold;
    margin-bottom: 1.125rem;

    .select {
        font-weight: initial;
    }

    button {
        font-weight: bold;
    }
}

.product-options {
    .product-option {
        margin: 0.938rem 0;
        align-items: center;

        .unit-select {
            width: 6.25rem;
        }
    }

    .threshold-config {
        border: 0.063rem solid $dirty-white;
        background-color: $white;
    }

    .gray-bar {
        background-color: $white;
    }

    .sku {
        border: 0.063rem solid $dirty-white;
        background-color: $white;
    }
}

.icon-wrap {
    height: 2rem;
    width: 2rem;
    position: relative;
    cursor: pointer;
    opacity: 0;
    transition: $fast-animation-time;

    &::before,
    &::after {
        position: absolute;
        display: block;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    &::before {
        content: '';
        height: 0;
        width: 0;
        background: $gray10;
        border-radius: 50%;
        transition: $fast-animation-time;
        background: $gray10;
    }

    &::after {
        content: url($icon-path + $eye-icon-hover);
        height: 1.5rem;
        width: 1.5rem;
    }

    &.cross-eye::after {
        content: url($icon-path + $eye-crossed);
    }

    .icon-text {
        border-radius: 0.25rem;
        opacity: 0;
        transition: $fast-animation-time;
        background: rgba($color: $gray60, $alpha: 0.15);
        padding: 0.063rem 0.813rem 0.813rem 0.188rem;
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translate(-50%, 0.063rem);
    }

    &:hover {
        .icon-text {
            opacity: 1;
        }

        &::before {
            height: 2rem;
            width: 2rem;
        }
    }
}

.dnd {
    &-item {
        position: relative;
        display: flex;
        align-items: center;
        width: 100%;
        min-height: 2.5rem;
        padding: 0 3.5rem 0 1rem;
        border-radius: 0.125rem;
        border: solid 0.063rem $dirty-white;
        margin-bottom: 0.25rem;
        color: $gray90;

        &:hover {
            box-shadow: 0 0.125rem 0.25rem 0 $blue30;

            .name-wrapper {
                color: $gray60;
                font-weight: bold;
            }

            .handle {
                display: flex;
            }

            .icon-wrap {
                opacity: 1;
            }

            .hidden-label {
                opacity: 0;
            }
        }

        .charging-spec-details {
            position: absolute;
            left: 1.875rem;
            top: 0;
            transform: translateY(-100%);
            display: none;
            width: 21.25rem;
            height: auto;
            border-radius: 0.25rem;
            color: $gray60;
            transition: 0.2s ease-in-out;

            .name {
                padding-left: 0.25rem;
                margin-bottom: 0.75rem;
                font-size: 0.875rem;
            }

            .info {
                background-color: $gray5;
                padding: 0.75rem 1.25rem;
                font-size: 0.813rem;

                .property-name {
                    font-weight: bold;
                }
            }
        }

        .name-wrapper:hover ~ .charging-spec-details {
            display: block;
        }

        .handle:hover ~ .charging-spec-details {
            display: none;
        }

        .name-wrapper {
            cursor: pointer;
            font-size: 0.875rem;
        }

        .number-wrapper {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 0;
            height: 0;
            border: 0.688 solid $gray5;
            border-radius: 1.375rem;
            margin-right: 1rem;

            .number {
                position: absolute;
                height: 1.125rem;
                background-color: transparent;
                color: $gray60;
                font-size: 0.813rem;
                font-weight: bold;
            }

            &.empty {
                width: 1.375rem;
                height: 1.375rem;
                border: none;

                .number {
                    height: 0.5rem;
                    width: 0.5rem;
                    border: 0.25rem solid $gray5;
                    border-radius: 50%;
                }
            }
        }
    }
}

.editor {
    background-color: $white;
    display: flex;
    flex-flow: column;
    height: 100%;
}

.main {
    padding: 1.875rem 3.125rem;
}

.error-layout {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 0;

    .label {
        flex: 0 1 12.5rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}

.has-text-under {
    padding: 0.5rem 0 0 0;
}

.multiselect {
    min-height: 0;
    flex: 0 1 36.5rem;
}

.multiselect-small {
    min-height: 0;
    flex: 0 1 18rem;
}

.approve-on-create {
    padding-top: 0.75rem;

    & > .approve-checkbox {
        margin-left: -0.25rem;
    }
}

.optional-label {
    margin-left: auto;
    padding-left: 1rem;
    font-size: 0.75rem;
    font-style: italic;
    align-self: flex-start;
    margin-top: 0.5rem;
    color: $gray60;
}

.footer {
    display: flex;
    justify-content: space-between;
    margin: 0.625rem 0.5rem 1.75rem 0.5xv;
}

.page-height {
    flex: 1 0 0;
}

.fixed-on-top {
    align-self: flex-start;
    margin-top: 0.25rem;
}

.right-side {
    position: sticky;
    top: 5rem;
    max-height: calc(100vh - 5.625rem);
    overflow-y: scroll;
    box-shadow: 0 0.063rem 0.125rem 0 $box-shadow-color;
    margin-top: 0.688rem;
}

.draggable-container {
    height: auto;
    min-height: 50%;
}

.hidden-label {
    display: none;
    font-size: 0.875rem;
    color: $gray90;
    font-style: italic;
}

.charging-spec-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 80%;

    &.hidden-chargingSpecification {
        .hidden-label {
            display: inline;
        }
    }
}

.delete-btn {
    cursor: pointer;
    position: absolute;
    top: 1rem;
    right: 1.625rem;
    transform: translateY(-50%);
    opacity: 0;
}

.tooltip-text-width {
    width: max-content;
}

.error-font {
    color: $red;
    font-size: 0.688rem;
}

.card-slot-content {
    display: flex;
    align-items: center;
    padding-top: 1.25rem;
    margin-left: 3.25rem;

    & > p {
        font-size: 0.75rem;
        font-weight: bold;
        color: $gray-blue;
        text-transform: uppercase;
    }

    .input-wrapper {
        width: 4rem;
        margin: 0 0.5rem;
    }
}
</style>
