import Vue from 'vue';
import { Getters, State, Mutations } from '@/store/mutation-types';

export default {
    namespaced: true,
    state: {
        [State.AFFECTED_ENTITIES]: {},
        [State.SHOW_MODAL]: false,
    },
    mutations: {
        [Mutations.SET_AFFECTED_ENTITIES]: (state, { affectedEntities }) => {
            Vue.set(state, [State.AFFECTED_ENTITIES], affectedEntities);
        },
        [Mutations.TOGGLE_SHOW_MODAL]: (state, { toState }) => {
            if (toState) {
                Vue.set(state, [State.SHOW_MODAL], toState);
                return;
            }
            Vue.set(state, [State.SHOW_MODAL], !state[State.SHOW_MODAL]);
        },
    },
    actions: {},
    getters: {
        [Getters.GET_AFFECTED_ENTITIES]: state => state[State.AFFECTED_ENTITIES] || {},
        [Getters.GET_SHOW_MODAL]: state => state[State.SHOW_MODAL] || false,
    },
};
