<!-- Demo Component: Throw away code -->
<template>
    <AbstractListPageWrapper
        :pageTitle="$i18n.t('pricing.pricingRules')"
        :isOverviewEnabled="isOverviewEnabled"
        :entitiesCount="filteredValidationRules.length"
        @searchQueryChanged="setSearchQuery"
    >
        <template #filterTable>
            <FilterTable
                :columns="tableColumnsData"
                @filterAdded="onFilterAdded"
            />
        </template>

        <template #headerButtons>
            <template>
                <AppButton
                    :buttonType="BUTTON_TYPES.PRIMARY"
                    :iconType="ICON_TYPES.PLUS"
                    :label="$t('pricing.addPricingRule')"
                    @click="openEditor()"
                />
            </template>
        </template>

        <template #allFilters>
            <TableFiltersTags
                :filterTableMixin="filterTableMixin"
                @removeFilter="removeFilter"
                @removeAllFilters="removeAllFilters"
            />
        </template>

        <template #table>
            <AppTable
                data-test="pricing-rules-table"
                tableKey="pricing-rules"
                :enableRowStateControls="false"
                :entities="filteredValidationRules"
                :columnsData="tableColumnsData"
                :innerSearchQuery="searchQueryForTable"
                :isSearchEnabled="true"
                @selectedEntityModel="onEntitySelected"
            >
                <template #state="{ entity }">
                    <EntityStatusIndicator :status="entity.state" />
                </template>
                <template #customRowButtons="{ entity }">
                    <template>
                        <IconButton
                            v-if="entity.state === EntityStateMapping.UNAPPROVED"
                            :label="$i18n.t('generic.approve')"
                            :icon="ICON_TYPES.CHECK"
                            @iconClick="approvePricingRule(entity)"
                        />
                        <IconButton
                            :label="$i18n.t('generic.edit')"
                            :icon="ICON_TYPES.EDIT"
                            @iconClick="openEditor(entity)"
                        />
                        <IconButton
                            :label="$i18n.t('generic.delete')"
                            :icon="ICON_TYPES.DELETE"
                            @iconClick="confirmDelete(entity)"
                        />
                    </template>
                </template>
            </AppTable>
        </template>

        <template #overview>
            <EntityOverview
                v-if="selectedEntity"
                :entityType="ENTITY_TYPES.PRICING_RULE"
                :entity="selectedEntity"
                @closeOverview="isOverviewEnabled = false"
            >
                <template #section-1-header>
                    <div>
                        <OverviewHeader
                            :entityName="selectedEntity.name"
                            :entityType="ENTITY_TYPES.PRICING_RULE"
                        />
                        <LanguageSwitcher v-model="selectedLanguage" />
                    </div>
                </template>
                <template #section-1-content>
                    <div>
                        <SummaryCard :summary="summary" />
                        <OverviewList :lists="entityOverviewDetails" />
                    </div>
                </template>
            </EntityOverview>
        </template>
    </AbstractListPageWrapper>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Actions, { Getters } from '@/store/mutation-types';

// Components
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import FilterTable from '@/components/partials/FilterTable.vue';
import FilterTableMixin from '@/components/partials/FilterTableMixin.vue';
import AbstractListPageWrapper from '@/components/layout/AbstractListPageWrapper.vue';
import AppTable from '@/components/partials/AppTable.vue';
import TableFiltersTags from '@/components/filters/TableFiltersTags.vue';
import tableColumnType from '@/common/filterTable';
import IconButton from '@/components/partials/IconButton.vue';
import Button from '@/common/button/Button';
import EntityStatusIndicator from '@/components/partials/EntityStatusIndicator.vue';
import EntityOverview from '@/components/partials/entityOverview/EntityOverview.vue';
import OverviewHeader from '@/components/partials/entityOverview/OverviewHeader.vue';
import OverviewList from '@/components/partials/entityOverview/OverviewList.vue';
import LanguageSwitcher from '@/components/partials/inputs/LanguageSwitcher.vue';
import SummaryCard from '@/components/partials/cards/SummaryCard.vue';

// Types
import { ALERT_TYPES } from '@/common/alerts/Alert';
import ENTITY_TYPES from '@/common/entities/entityTypes';
import { ICON_TYPES } from '@/common/iconHelper';

// Misc
import { STATUS_CODE_TO_STATUS_NAME_MAP } from '@/common/commonHelper';
import RouteNames from '@/router/routeNames';
import { EntityStateMapping } from '@/common/commonEntityStateMapper';
import { DEFAULT_OPERATION_GROUPS } from '@/common/conditions-tree';

export default {
    name: 'PricingRulesListPageKuiperDemo',
    components: {
        AppButton,
        AbstractListPageWrapper,
        AppTable,
        FilterTable,
        IconButton,
        TableFiltersTags,
        EntityStatusIndicator,
        EntityOverview,
        OverviewHeader,
        OverviewList,
        LanguageSwitcher,
        SummaryCard,
    },
    mixins: [FilterTableMixin],
    data() {
        return {
            // Proxy imports
            ALERT_TYPES,
            BUTTON_TYPES,
            ICON_TYPES,
            ENTITY_TYPES,
            EntityStateMapping,

            searchQueryForTable: '',
            pricingRules: [],
            selectedEntity: null,
            isOverviewEnabled: false,
            initialPricingRules: {
                '123d41d6db184aqwda4cc04e2f9a9100': {
                    id: '123d41d6db184aqwda4cc04e2f9a9100',
                    name: 'Pricing rule 1',
                    description: 'test description',
                    state: 2,
                    condition: {
                        type: 'LOGICAL',
                        op: 'AND',
                        args: {
                            nested_conditions: [
                                {
                                    type: 'COMPARISON',
                                    op: 'MATCHES',
                                    args: {
                                        data_type: 2,
                                        key: null,
                                        value: ['7a5bf7f7fa4b4c70b9e113c2608a0944', '6720de37d2674537906b41e832a502b5'],
                                    },
                                },
                                {
                                    type: 'LOGICAL',
                                    op: 'OR',
                                    args: {
                                        nested_conditions: [
                                            {
                                                type: 'COMPARISON',
                                                op: 'EQ',
                                                args: {
                                                    data_type: 3,
                                                    key: null,
                                                    value: 'ae685daecd344f1dbd6c75fa9cdb49d5',
                                                },
                                            },
                                            {
                                                type: 'LOGICAL',
                                                op: 'NOT',
                                                args: {
                                                    nested_conditions: [
                                                        {
                                                            type: 'COMPARISON',
                                                            op: 'NOT_EQ',
                                                            args: {
                                                                data_type: 2,
                                                                key: null,
                                                                value: '1ee24cc5075241569f03df2bc4f70599',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    actions: [
                        {
                            discount: 1,
                            amount: '10',
                            offer: '48d09cc49da84138bd28e51d020406bc',
                        },
                        {
                            discount: 2,
                            amount: '5',
                            offer: '8edc1d108de84c888392c415f40ae611',
                        },
                    ],
                },
                '3a8d41d6db184a58934cc04e2f9a9259': {
                    id: '3a8d41d6db184a58934cc04e2f9a9259',
                    name: 'Pricing rule 2',
                    state: 1,
                    condition: {
                        type: 'COMPARISON',
                        op: 'EQ',
                        args: {
                            key: null,
                            data_type: 2,
                            value: '0a40ece9408f4314b7783637fd4e2a7a',
                        },
                    },
                    actions: [
                        {
                            discount: 1,
                            amount: '10',
                            offer: '48d09cc49da84138bd28e51d020406bc',
                        },
                    ],
                },
            },
        };
    },
    computed: {
        ...mapGetters('operators', {
            selectedLanguage: Getters.languageDefault,
        }),
        ...mapGetters('productcatalog', {
            getEntitiesByType: Getters.PC_GET_ENTITIES_BY_TYPE,
        }),
        offers() {
            return Object.values(this.getEntitiesByType(ENTITY_TYPES.OFFER));
        },
        products() {
            return Object.values(this.getEntitiesByType(ENTITY_TYPES.PRODUCT));
        },
        displayPricingRules() {
            return this.pricingRules.map(pr => ({
                ...pr,
                name: pr.name,
                state: pr.state || null,
                stateLabel: STATUS_CODE_TO_STATUS_NAME_MAP.get(pr.state),
            }));
        },
        filteredValidationRules() {
            return this.filteredEntitiesMixin(this.displayPricingRules);
        },
        tableColumnsData() {
            return [
                {
                    name: this.$i18n.t('generic.name'),
                    key: 'name',
                    field: 'name',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('generic.status'),
                    key: 'state',
                    field: 'stateLabel',
                    filterType: tableColumnType.TEXT_LIMITED_OPTIONS,
                },
            ];
        },

        entityOverviewDetails() {
            if (!this.selectedEntity) {
                return [];
            }

            return [
                {
                    name: this.$i18n.t('generic.general'),
                    properties: [
                        {
                            label: this.$i18n.t('generic.name'),
                            value: this.selectedEntity.name,
                        },
                        {
                            label: this.$i18n.t('generic.description'),
                            value: this.selectedEntity.description || 'Empty',
                        },
                    ],
                },
            ];
        },
        operations() {
            return DEFAULT_OPERATION_GROUPS.map(group => group.operations)
                .flat()
                .map(operation => ({
                    i18n: this.$i18n.t(`${operation.i18nNameKey}`),
                    key: operation.op,
                }));
        },

        summary() {
            return `IF Pricing Rule meets all conditions: ${this.conditionsToSentence(
                this.selectedEntity.condition,
            )} THEN it has discounts: (${this.actionsToSentence(this.selectedEntity.actions)})`;
        },
    },
    async created() {
        const promises = [
            this[Actions.PC_REQUEST_ENTITIES_BY_TYPE_AND_IDS]({ entityType: ENTITY_TYPES.PRODUCT }),
            this[Actions.PC_REQUEST_ENTITIES_BY_TYPE_AND_IDS]({ entityType: ENTITY_TYPES.OFFER }),
        ];

        await Promise.all(promises);

        // Initialize Pricing Rules in LocalStorage
        const vrStr = localStorage.getItem('pricingRules');
        if (!vrStr) {
            localStorage.setItem('pricingRules', JSON.stringify(this.initialPricingRules));
            this.pricingRules = Object.values(this.initialPricingRules);
        } else {
            this.pricingRules = Object.values(JSON.parse(vrStr));
        }
    },
    methods: {
        ...mapActions('productcatalog', [Actions.PC_REQUEST_ENTITIES_BY_TYPE_AND_IDS]),
        approvePricingRule(entity) {
            const priceRules = JSON.parse(localStorage.getItem('pricingRules') || '{}');
            priceRules[entity.id] = { ...priceRules[entity.id], state: 2 };

            // Save to LocalStorage
            localStorage.setItem('pricingRules', JSON.stringify(priceRules));

            // Update displayed data
            this.pricingRules = Object.values(priceRules);
        },
        confirmDelete(entity) {
            // Create delete button
            const button = new Button({
                label: this.$i18n.t('generic.delete'),
                alertType: ALERT_TYPES.warning,
                handler: () => {
                    this.deletePR(entity);
                },
            });

            // Deletion Confirmation Alert
            this.$alert(
                this.$i18n.t('alerts.areYouSureDeleteEntity', {
                    entityName: entity.name,
                }),
                {
                    type: ALERT_TYPES.warning,
                    buttons: [button],
                },
            );
        },
        deletePR(entity) {
            const vrs = JSON.parse(localStorage.getItem('pricingRules') || '{}');
            delete vrs[entity.id];
            localStorage.setItem('pricingRules', JSON.stringify(vrs));
            this.$alert(`Successfully deleted Pricing Rule "${entity.name}"`, {
                type: ALERT_TYPES.success,
            });
            this.pricingRules = Object.values(vrs);
        },
        conditionsToSentence(json) {
            if (json.op === 'AND' || json.op === 'OR') {
                const operator = json.op === 'AND' ? 'AND' : 'OR';
                const conditions = json.args.nested_conditions.map(this.conditionsToSentence);
                return `(${conditions.join(` ${operator} `)})`;
            }
            if (json.op === 'NOT') {
                const nestedCondition = this.conditionsToSentence(json.args.nested_conditions[0]);
                return `(IS NOT ${nestedCondition})`;
            }
            if (json.op) {
                const dataType = json.args.data_type === 3 ? 'product' : 'offer';
                const { value } = json.args;
                let entityName = '';

                const operationName = this.operations.find(op => op.key === json.op).i18n || '';

                if (Array.isArray(value)) {
                    const entityNamesArray = value.map(item => {
                        return this[`${dataType}s`].find(e => e.id === item)?.name || '';
                    });

                    entityName = `[${entityNamesArray.join(', ')}]`;
                } else {
                    entityName = this[`${dataType}s`].find(e => e.id === value)?.name || '';
                }

                return `${dataType} ${operationName} ${entityName}`;
            }

            return '';
        },
        actionsToSentence(actions) {
            return actions
                .map(item => {
                    const amount = parseFloat(item.amount);
                    const discountSymbol = item.discount === 2 ? '$' : '%';
                    const offerName = this.offers.find(e => e.id === item.offer)?.name || '';

                    return `${amount}${discountSymbol} for offer ${offerName}`;
                })
                .join(', ');
        },
        openEditor(entity) {
            this.$router.push({
                name: RouteNames.PRICING_RULES_EDITOR_KUIPER_DEMO,
                params: {
                    id: entity?.id,
                    companyId: this.$route.params.companyId,
                },
            });
        },
        setSearchQuery(query) {
            this.searchQueryForTable = query;
        },
        onEntitySelected(entity) {
            this.selectedEntity = entity;
            this.isOverviewEnabled = true;
        },
    },
};
</script>
