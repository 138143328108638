<template>
    <div class="mb-5">
        <!-- <div class="mb-3">
            <AppToggle
                v-model="profileSpecification.specUpgrade"
                :small="true"
                :label="$t('consent.partyPrivacyProfileSpecificationLocale.requireNewVersion')"
                :explanationText="$t('consent.partyPrivacyProfileSpecificationLocale.requireNewVersionTips')"
            />
        </div> -->
        <AppMultiselectV3
            v-model="profileSpecification.lifecycleStatus"
            :options="lifecycleStatusOptions"
            :additionalLabel="`${$i18n.t('consent.partyPrivacyProfileSpecificationLocale.lifecycleStatus')}`"
            :placeholder="$i18n.t('consent.partyPrivacyProfileSpecificationLocale.lifecycleStatus')"
            :showLabels="false"
            :small="true"
            :allowEmpty="false"
            :disabled="readonly"
            class="editor-input-largest mb-3"
            label="description"
            trackBy="key"
            :error="$v.profileSpecification.lifecycleStatus.$error"
            errorMessage="lifecycle status is required"
        />
        <Divider :text="$i18n.t('consent.partyPrivacyProfileSpecificationLocale.requestAttributes')" />
        <AppMultiselectV3
            v-model="profileSpecification.validityControl"
            :options="validityControlOptions"
            :additionalLabel="`${$i18n.t('consent.partyPrivacyProfileSpecificationLocale.validityControl')}`"
            :placeholder="$i18n.t('consent.partyPrivacyProfileSpecificationLocale.validityControl')"
            :showLabels="false"
            :tooltipOffset="20"
            :explanationText="$i18n.t('consent.partyPrivacyProfileSpecificationLocale.validityControlTips')"
            :small="true"
            :allowEmpty="false"
            :disabled="readonly"
            class="editor-input-largest mb-3"
            label="description"
            trackBy="key"
            :error="$v.profileSpecification.validityControl.$error"
            errorMessage="validity type is required"
        />
        <AppMultiselectV3
            v-if="
                profileSpecification.validityControl &&
                profileSpecification.validityControl.key === 'durationFromGrantDate'
            "
            v-model="profileSpecification.validityDurationUnit"
            :options="validityDurationUnitOptions"
            :additionalLabel="`${$i18n.t('consent.partyPrivacyProfileSpecificationLocale.validityDurationUnit')}`"
            :placeholder="$i18n.t('consent.partyPrivacyProfileSpecificationLocale.validityDurationUnit')"
            :showLabels="false"
            :small="true"
            :allowEmpty="true"
            :disabled="readonly"
            class="editor-input-largest mb-3"
            label="description"
            trackBy="key"
            :error="$v.profileSpecification.validityDurationUnit.$error"
            errorMessage="validity duration unit is required"
        />
        <AppInputV3
            v-if="
                profileSpecification.validityControl &&
                profileSpecification.validityControl.key === 'durationFromGrantDate'
            "
            v-model="profileSpecification.validityDurationAmount"
            :placeholder="`${$i18n.t('consent.partyPrivacyProfileSpecificationLocale.validityDurationAmount')}`"
            :label="$i18n.t('consent.partyPrivacyProfileSpecificationLocale.validityDurationAmount')"
            :disabled="readonly"
            type="number"
            class="editor-input-largest mb-3"
            :invalid="$v.profileSpecification.validityDurationAmount.$error"
            errorMessage="validity duration amount is required"
        />
        <AppInputV3
            v-if="
                profileSpecification.validityControl && profileSpecification.validityControl.key === 'numberOfQueries'
            "
            v-model="profileSpecification.validityQueryCount"
            :placeholder="`${$i18n.t('consent.partyPrivacyProfileSpecificationLocale.validityQueryCount')}`"
            :label="$i18n.t('consent.partyPrivacyProfileSpecificationLocale.validityQueryCount')"
            :disabled="readonly"
            type="number"
            class="editor-input-largest mb-3"
            :invalid="$v.profileSpecification.validityQueryCount.$error"
            errorMessage="validity query count is required"
        />

        <DateTimePicker
            v-if="
                profileSpecification.validityControl && profileSpecification.validityControl.key === 'specificDateRange'
            "
            v-model="profileSpecification.validityStartDate"
            :additionalLabel="$i18n.t('consent.partyPrivacyProfileSpecificationLocale.validityStartDate')"
            :clearable="false"
            class="editor-input-large mb-3"
            :error="$v.profileSpecification.validityStartDate.$error"
        />
        <DateTimePicker
            v-if="
                profileSpecification.validityControl && profileSpecification.validityControl.key === 'specificDateRange'
            "
            v-model="profileSpecification.validityEndDate"
            :additionalLabel="$i18n.t('consent.partyPrivacyProfileSpecificationLocale.validityEndDate')"
            :clearable="false"
            class="editor-input-large mb-3"
            :error="$v.profileSpecification.validityEndDate.$error"
        />
        <AppMultiselectV3
            v-model="profileSpecification.service"
            :options="consentServices"
            :additionalLabel="$i18n.t('consent.service')"
            :placeholder="$i18n.t('consent.partyPrivacyProfileSpecificationLocale.selectService')"
            :showLabels="false"
            :tooltipOffset="20"
            :explanationText="$i18n.t('consent.partyPrivacyProfileSpecificationLocale.serviceTips')"
            :small="true"
            :allowEmpty="true"
            :disabled="readonly || isLoadingServices"
            :loading="isLoadingServices"
            class="editor-input-largest mb-3"
            label="name"
            trackBy="id"
            :error="$v.profileSpecification.service.$error"
            errorMessage="service is required"
        />
        <!-- Predicates section now wrapped in v-if with service condition -->
        <div
            v-if="profileSpecification.service"
            class="predicates mt-3"
        >
            <div
                v-if="profileSpecification.predicates && profileSpecification.predicates.length"
                class="predicates-list"
            >
                <div
                    v-for="(predicate, index) in profileSpecification.predicates"
                    :key="predicate.id"
                    class="predicate-form mb-4"
                >
                    <div class="d-flex justify-content-between align-items-center mb-2">
                        <div class="section-title">
                            {{ $i18n.t('consent.partyPrivacyProfileSpecificationLocale.predicate') }} #{{ index + 1 }}
                        </div>
                        <AppButton
                            v-if="!readonly"
                            :buttonType="BUTTON_TYPES.PRIMARY"
                            :iconType="'delete-new'"
                            :isSmall="true"
                            :clicked="true"
                            :label="$t('consent.partyPrivacyProfileSpecificationLocale.removePredicate')"
                            class="mt-3"
                            @click="removePredicate(index)"
                        >
                        </AppButton>
                    </div>
                    <Divider :showPointer="false" />
                    <AppInputV3
                        v-model="predicate.name"
                        :placeholder="$i18n.t('generic.name')"
                        :label="$i18n.t('generic.name')"
                        :disabled="readonly"
                        type="text"
                        class="editor-input-largest mb-3"
                    />
                    <AppMultiselectV3
                        v-model="predicate.purpose"
                        :options="purposeOptions"
                        :additionalLabel="$i18n.t('consent.purpose')"
                        :placeholder="$i18n.t('consent.partyPrivacyProfileSpecificationLocale.selectPurpose')"
                        :showLabels="false"
                        :small="true"
                        :multiple="true"
                        :disabled="readonly"
                        class="editor-input-largest mb-3"
                        label="description"
                        trackBy="key"
                    />
                    <AppMultiselectV3
                        v-model="predicate.pd"
                        :options="personalDataOptions"
                        :additionalLabel="$i18n.t('consent.personalData')"
                        :placeholder="$i18n.t('consent.partyPrivacyProfileSpecificationLocale.selectPersonalData')"
                        :showLabels="false"
                        :small="true"
                        :multiple="true"
                        :disabled="readonly"
                        class="editor-input-largest mb-3"
                        label="description"
                        trackBy="key"
                    />
                    <AppMultiselectV3
                        v-if="profileSpecification.service && profileSpecification.service.type === 'api'"
                        v-model="predicate.scope"
                        :options="scopeOptions"
                        :additionalLabel="$i18n.t('consent.scope')"
                        :placeholder="$i18n.t('consent.scope')"
                        :showLabels="false"
                        :small="true"
                        :multiple="true"
                        :disabled="readonly"
                        class="editor-input-largest mb-3"
                        label="description"
                        trackBy="key"
                    />
                    <AppMultiselectV3
                        v-model="predicate.legalBasis"
                        :options="legalBasisOptions"
                        :additionalLabel="$i18n.t('consent.partyPrivacyProfileSpecificationLocale.legalBasis')"
                        :placeholder="$i18n.t('consent.partyPrivacyProfileSpecificationLocale.legalBasis')"
                        :showLabels="false"
                        :small="true"
                        :multiple="true"
                        :disabled="readonly"
                        class="editor-input-largest mb-3"
                        label="description"
                        trackBy="key"
                    />
                    <AppInputV3
                        v-model="predicate.description"
                        :placeholder="$i18n.t('generic.description')"
                        :label="$i18n.t('generic.description')"
                        :disabled="readonly"
                        type="text"
                        class="editor-input-largest mb-3"
                    />
                    <div class="mb-3">
                        <AppToggle
                            v-model="predicate.consentCheck"
                            :small="true"
                            :disabled="readonly"
                            :tooltipOffset="30"
                            :label="$t('consent.consentCheck')"
                            :explanationText="$t('consent.partyPrivacyProfileSpecificationLocale.consentCheckTips')"
                        />
                    </div>
                    <div class="mb-3">
                        <AppToggle
                            v-model="predicate.optoutCheck"
                            :small="true"
                            :disabled="readonly"
                            :tooltipOffset="30"
                            :label="$t('consent.optoutCheck')"
                            :explanationText="$t('consent.partyPrivacyProfileSpecificationLocale.optoutCheckTips')"
                        />
                    </div>
                </div>
            </div>

            <AppButton
                v-if="!readonly"
                :isSmall="true"
                :iconType="'more-new'"
                :buttonType="BUTTON_TYPES.PRIMARY"
                :clicked="true"
                :label="$t('consent.partyPrivacyProfileSpecificationLocale.addPredicate')"
                class="mt-3"
                :tooltip="$t('consent.partyPrivacyProfileSpecificationLocale.newPredicatesFromServiceTips')"
                @click="addPredicate"
            >
            </AppButton>
        </div>

        <!-- Legal Text Editor moved to bottom -->
        <div class="mt-5">
            <Divider
                :text="$i18n.t('consent.partyPrivacyProfileSpecificationLocale.legalText')"
                :tipsText="$i18n.t('consent.partyPrivacyProfileSpecificationLocale.legalTextTips')"
            />
            <div
                ref="monaco_container"
                :class="['monaco-container', { invalid: false }]"
                class="editor-input-largest"
            >
                <div
                    ref="legalTextEditor"
                    class="monaco-editor-container"
                    style="height: 300px; width: 100%"
                />
            </div>
        </div>
    </div>
</template>

<script>
// Components
import ENTITY_TYPES from '@/common/entities/entityTypes';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import AppInputV3 from '@/components/partials/inputs/AppInputV3.vue';
import AppMultiselectV3 from '@/components/partials/inputs/AppMultiselectV3.vue';
import AppToggle from '@/components/partials/inputs/AppToggle.vue';
import DateTimePicker from '@/components/partials/inputs/DateTimePicker.vue';
import MonacoLoader from '@monaco-editor/loader';
import { validationMixin } from 'vuelidate';
import { required, requiredIf } from 'vuelidate/lib/validators';
import Divider from '@/__new__/features/consent/Divider.vue';
// Vuex
import { getConsentServices } from '@/__new__/services/dno/privacyConsent/http/consent';
import Actions, { Getters } from '@/store/mutation-types';
import { mapActions, mapGetters } from 'vuex';
import { cloneDeep } from 'lodash';

export default {
    name: 'PolicySpecificationCoreData',

    components: {
        AppInputV3,
        AppMultiselectV3,
        AppToggle,
        AppButton,
        Divider,
        DateTimePicker,
    },

    mixins: [validationMixin],
    props: {
        // passed in case of editing of the existing offer in EntityEditor
        initialPolicySpecificationData: {
            type: Object,
            default: null,
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        childEntities: {
            type: Array,
            default: () => [],
        },
        inEditMode: {
            type: Boolean,
            default: false,
        },
        showMainValidation: {
            type: Boolean,
            default: false,
        },
        // Basically disabled categories in case of true passed
        isInBulkEdit: {
            type: Boolean,
            default: false,
        },
        // only available for Bulk edit case
        selectedCoreProperties: {
            type: Array,
            default: () => [],
        },
    },

    data() {
        return {
            BUTTON_TYPES,
            profileSpecification: {
                specUpgrade: false,
                validityControl: null,
                validityDurationUnit: null,
                validityDurationAmount: null,
                validityQueryCount: null,
                validityStartDate: null,
                validityEndDate: null,
                service: null,
                lifecycleStatus: null,
                legalText: null,
                predicates: null, // Initialize predicates array
            },
            beforeChangedServicePolicySpecification: {},
            validityControlOptions: [
                {
                    key: 'canNotBeRevoked',
                    description: this.$i18n.t(
                        'consent.partyPrivacyProfileSpecificationLocale.dropDown.validityControls.canNotBeRevoked',
                    ),
                },
                {
                    key: 'validUntilRevocation',
                    description: this.$i18n.t(
                        'consent.partyPrivacyProfileSpecificationLocale.dropDown.validityControls.validUntilRevocation',
                    ),
                },
                {
                    key: 'durationFromGrantDate',
                    description: this.$i18n.t(
                        'consent.partyPrivacyProfileSpecificationLocale.dropDown.validityControls.durationFromGrantDate',
                    ),
                },
                {
                    key: 'specificDateRange',
                    description: this.$i18n.t(
                        'consent.partyPrivacyProfileSpecificationLocale.dropDown.validityControls.specificDateRange',
                    ),
                },
                {
                    key: 'numberOfQueries',
                    description: this.$i18n.t(
                        'consent.partyPrivacyProfileSpecificationLocale.dropDown.validityControls.numberOfQueries',
                    ),
                },
            ],
            lifecycleStatusOptions: [
                {
                    key: 'active',
                    description: this.$i18n.t(
                        'consent.partyPrivacyProfileSpecificationLocale.dropDown.lifecycleStatuses.active',
                    ),
                },
                {
                    key: 'inActive',
                    description: this.$i18n.t(
                        'consent.partyPrivacyProfileSpecificationLocale.dropDown.lifecycleStatuses.inActive',
                    ),
                },
            ],
            validityDurationUnitOptions: [
                {
                    key: 'year',
                    description: this.$i18n.t(
                        'consent.partyPrivacyProfileSpecificationLocale.dropDown.validityDurationUnits.year',
                    ),
                },
                {
                    key: 'month',
                    description: this.$i18n.t(
                        'consent.partyPrivacyProfileSpecificationLocale.dropDown.validityDurationUnits.month',
                    ),
                },
                {
                    key: 'day',
                    description: this.$i18n.t(
                        'consent.partyPrivacyProfileSpecificationLocale.dropDown.validityDurationUnits.day',
                    ),
                },
                {
                    key: 'week',
                    description: this.$i18n.t(
                        'consent.partyPrivacyProfileSpecificationLocale.dropDown.validityDurationUnits.week',
                    ),
                },
            ],
            policySpecificationCoreFieldsInvalid: false,
            consentServices: [],
            isLoadingServices: false,
            editor: null,
        };
    },
    validations() {
        return {
            profileSpecification: {
                lifecycleStatus: { required },
                validityControl: { required },
                service: { required },
                validityDurationUnit: {
                    required: requiredIf(
                        () => this.profileSpecification?.validityControl?.key === 'durationFromGrantDate',
                    ),
                },
                validityDurationAmount: {
                    required: requiredIf(
                        () => this.profileSpecification?.validityControl?.key === 'durationFromGrantDate',
                    ),
                },
                validityStartDate: {
                    required: requiredIf(() => this.profileSpecification?.validityControl?.key === 'specificDateRange'),
                },
                validityEndDate: {
                    required: requiredIf(() => this.profileSpecification?.validityControl?.key === 'specificDateRange'),
                },
                validityQueryCount: {
                    required: requiredIf(() => this.profileSpecification?.validityControl?.key === 'numberOfQueries'),
                },
            },
        };
    },
    computed: {
        ...mapGetters('privacy', {
            getEntitiesApproved: Getters.GET_PRIVACY_ENTITIES_BY_TYPE_APPROVED,
            getEntityByTypeAndId: Getters.GET_PRIVACY_ENTITY_BY_TYPE_AND_ID,
            getEntitiesByType: Getters.GET_PRIVACY_ENTITIES_BY_TYPE,
        }),
        showValidation() {
            return this.showMainValidation || this.policySpecificationCoreFieldsInvalid;
        },
        purposeOptions() {
            return this.profileSpecification.service?.validPurpose || null;
        },
        personalDataOptions() {
            return this.profileSpecification.service?.personalDataCategories || null;
        },
        scopeOptions() {
            return this.profileSpecification.service?.scope || null;
        },
        legalBasisOptions() {
            return this.profileSpecification.service?.legalBasis || null;
        },
    },
    watch: {
        initialPolicySpecificationData: {
            handler(newPolicySpecificationData, oldPolicySpecificationData) {
                if (newPolicySpecificationData !== oldPolicySpecificationData) {
                    this.initData();
                }
            },
        },
        profileSpecification: {
            handler(newVal) {
                this.resetConsentCheckAndOptoutCheck(
                    this.beforeChangedServicePolicySpecification?.predicates,
                    newVal?.predicates,
                );
                this.resetValidityFields(newVal);
                const payload = {
                    specUpgrade: newVal?.specUpgrade,
                    lifecycleStatus: newVal?.lifecycleStatus,
                    validityControl: newVal?.validityControl,
                    validityDurationUnit: newVal?.validityDurationUnit,
                    validityDurationAmount: newVal?.validityDurationAmount,
                    validityQueryCount: newVal?.validityQueryCount,
                    validityStartDate: newVal?.validityStartDate
                        ? new Date(newVal?.validityStartDate).getTime().toString()
                        : null,
                    validityEndDate: newVal?.validityEndDate
                        ? new Date(newVal?.validityEndDate).getTime().toString()
                        : null,
                    service: newVal?.service,
                    predicates: newVal?.predicates,
                    legalText: newVal?.legalText,
                };
                this.beforeChangedServicePolicySpecification = cloneDeep(payload);
                this.$emit('input', payload);
            },
            deep: true,
        },
        'profileSpecification.legalText': {
            handler(value) {
                if (this.editor && value !== this.editor.getValue()) {
                    this.editor.setValue(value || '');
                }
            },
        },
        readonly(value) {
            if (this.editor) {
                this.editor.updateOptions({ readOnly: value });
            }
        },
    },
    async created() {
        await Promise.all([
            this.requestEntitiesByType({ entityType: ENTITY_TYPES.PARTY_PRIVACY_PROFILE_SPECIFICATION }),
            this.fetchConsentServices(),
        ]);
    },
    async mounted() {
        await this.initMonacoEditor();
    },
    beforeDestroy() {
        if (this.editor) {
            this.editor.dispose();
        }
    },
    methods: {
        ...mapActions('privacy', {
            requestEntitiesByType: Actions.PRIVACY_REQUEST_ENTITIES_BY_TYPE_AND_IDS,
        }),
        resetValidityFields(newVal) {
            // Check if newVal is defined
            if (!newVal) return;

            // Reset fields based on validityControl key
            switch (newVal.validityControl?.key) {
                case 'durationFromGrantDate':
                    newVal.validityQueryCount = null;
                    newVal.validityStartDate = null;
                    newVal.validityEndDate = null;
                    break;

                case 'specificDateRange':
                    newVal.validityDurationAmount = null;
                    newVal.validityDurationUnit = null;
                    newVal.validityQueryCount = null;
                    break;

                case 'numberOfQueries':
                    newVal.validityDurationAmount = null;
                    newVal.validityDurationUnit = null;
                    newVal.validityStartDate = null;
                    newVal.validityEndDate = null;
                    break;

                default:
                    newVal.validityDurationAmount = null;
                    newVal.validityDurationUnit = null;
                    newVal.validityQueryCount = null;
                    newVal.validityStartDate = null;
                    newVal.validityEndDate = null;
                    break;
            }

            // Handle service comparison
            if (newVal.service && this.beforeChangedServicePolicySpecification?.service) {
                if (newVal.service.id !== this.beforeChangedServicePolicySpecification.service.id) {
                    newVal.predicates = null;
                }
            }
        },
        resetConsentCheckAndOptoutCheck(oldPredicates, changedPredicates) {
            if (!oldPredicates) {
                return;
            }
            oldPredicates.forEach((oldPredicate, index) => {
                const changedPredicate = changedPredicates[index];
                if (!changedPredicate) {
                    return;
                }
                if (oldPredicate.consentCheck !== changedPredicate.consentCheck && changedPredicate.consentCheck) {
                    changedPredicate.optoutCheck = false;
                }
                if (oldPredicate.optoutCheck !== changedPredicate.optoutCheck && changedPredicate.optoutCheck) {
                    changedPredicate.consentCheck = !changedPredicate.optoutCheck;
                }
            });
        },
        initData() {
            if (!this.initialPolicySpecificationData) {
                return;
            }
            this.profileSpecification = {
                ...this.initialPolicySpecificationData,
            };
            this.initializeProfileSpecification();
            if (this.profileSpecification?.service) {
                this.profileSpecification.service = this.consentServices.find(
                    service => service.id === this.profileSpecification.service,
                );
            }
            if (this.profileSpecification?.predicates) {
                const selectedService = this.consentServices.find(
                    service => service.id === this.profileSpecification.service.id,
                );
                const initPredicates = [];
                this.profileSpecification.predicates.forEach(predicate =>
                    initPredicates.push({
                        ...predicate,
                        pd: predicate?.pd
                            ? predicate?.pd.map(x => {
                                  return {
                                      key: x,
                                      description: selectedService.personalDataCategories.find(y => y.key === x)
                                          ?.description,
                                  };
                              })
                            : null,
                        purpose: predicate?.purpose
                            ? predicate?.purpose.map(x => {
                                  return {
                                      key: x,
                                      description: selectedService.validPurpose.find(y => y.key === x)?.description,
                                  };
                              })
                            : null,
                        scope: predicate?.scope
                            ? predicate?.scope.map(x => {
                                  return {
                                      key: x,
                                      description: selectedService.scope.find(y => y.key === x)?.description,
                                  };
                              })
                            : null,
                        legalBasis: predicate?.legalBasis
                            ? predicate?.legalBasis.map(x => {
                                  return {
                                      key: x,
                                      description: selectedService.legalBasis.find(y => y.key === x)?.description,
                                  };
                              })
                            : null,
                    }),
                );
                this.profileSpecification.predicates = initPredicates;
            }
            if (this.profileSpecification?.validityStartDate) {
                this.profileSpecification.validityStartDate = new Date(
                    Number(this.profileSpecification.validityStartDate),
                );
            }
            if (this.profileSpecification?.validityEndDate) {
                this.profileSpecification.validityEndDate = new Date(Number(this.profileSpecification.validityEndDate));
            }
        },
        initializeProfileSpecification() {
            this.updateSpecificationField('lifecycleStatus', 'lifecycleStatus', this.lifecycleStatusOptions);
            this.updateSpecificationField('validityControl', 'validityControl', this.validityControlOptions);
            this.updateSpecificationField(
                'validityDurationUnit',
                'validityDurationUnit',
                this.validityDurationUnitOptions,
            );
        },
        updateSpecificationField(field, initialKey, options) {
            if (this.profileSpecification?.[field]) {
                this.profileSpecification[field] = {
                    key: this.initialPolicySpecificationData[initialKey],
                    description: options.find(option => option.key === this.initialPolicySpecificationData[initialKey])
                        ?.description,
                };
            }
        },
        async fetchConsentServices() {
            try {
                this.isLoadingServices = true;
                const response = await getConsentServices();
                this.consentServices = response?.data || [];
            } catch (error) {
                this.$alert(this.$i18n.t('alertMessage.failedToLoadServices'), {
                    type: 'error',
                });
            } finally {
                this.isLoadingServices = false;
            }
        },
        addPredicate() {
            if (!this.profileSpecification.predicates) {
                this.profileSpecification.predicates = [];
                this.beforeChangedServicePolicySpecification.predicates = [];
            }
            const newPredicate = {
                pd: null,
                purpose: null,
                scope: null,
                description: null,
                consentCheck: false,
                optoutCheck: false,
                legalBasis: null,
            };
            this.profileSpecification.predicates.push(newPredicate);
            this.beforeChangedServicePolicySpecification.predicates.push(newPredicate);
        },
        removePredicate(index) {
            this.profileSpecification.predicates.splice(index, 1);
            this.beforeChangedServicePolicySpecification.predicates.splice(index, 1);
        },
        async initMonacoEditor() {
            const monaco = await MonacoLoader.init();
            this.editor = monaco.editor.create(this.$refs.legalTextEditor, {
                value: this.profileSpecification.legalText || '',
                language: 'html',
                automaticLayout: true,
                readOnly: this.readonly,
                minimap: {
                    enabled: false,
                },
                scrollBeyondLastLine: false,
                fontSize: 14,
                fontFamily: 'Open Sans',
                padding: { top: 5, bottom: 5 },
                renderLineHighlight: 'none',
                renderLineHighlightOnlyWhenFocus: false,
            });

            this.editor.onDidChangeModelContent(() => {
                this.profileSpecification.legalText = this.editor.getValue();
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.service-details {
    background-color: var(--color-background-light);
    border: 1px solid var(--color-border);
    border-radius: 4px;
}

.service-details .section-title {
    font-weight: 600;
    color: var(--color-text-primary);
}

.service-details .json-display {
    margin: 0;
    padding: 8px;
    background-color: var(--color-background);
    border-radius: 4px;
    font-family: monospace;
    font-size: 13px;
    white-space: pre-wrap;
    word-break: break-all;
    color: var(--color-text-primary);
}

.monaco-container {
    position: relative;
    margin-bottom: 1rem;
    width: 100%;
}

.monaco-container.invalid {
    border: 1px solid var(--color-error);
    border-radius: 4px;
}

.monaco-container .label {
    font-size: 14px;
    font-weight: 500;
    color: var(--color-text-primary);
    margin-bottom: 0.5rem;
}

.monaco-container .monaco-editor-container {
    border: 1px solid var(--color-border);
    border-radius: 4px;
    width: 100% !important;
}

.editor-input-largest.monaco-container {
    max-width: none;
    width: 100%;
}
</style>
