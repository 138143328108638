
// COMPONENTS
import AppLabel from '@/components/partials/AppLabel.vue';

// HELPERS
import { STATUS_INDICATOR_MAP, STATUS_CODE_TO_STATUS_NAME_MAP } from '@/common/commonHelper';
import { TranslateResult } from 'vue-i18n';

export default {
    name: 'EntityStatusIndicator',
    components: {
        AppLabel,
    },
    props: {
        status: {
            required: true,
            default: '',
            type: [String, Number],
        },
        // todo merge these 2 props into one map
        stateMap: {
            type: Map, // Map
            default: () => STATUS_CODE_TO_STATUS_NAME_MAP,
        },
        stateMapColor: {
            type: Map, // Map
            default: () => STATUS_INDICATOR_MAP,
        },
    },
    computed: {
        label(): TranslateResult | string {
            return this.stateMap.get(this.status) || String(this.status);
        },
        labelColor(): string | undefined {
            return this.stateMapColor.get(this.status);
        },
    },
};
