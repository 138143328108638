<template>
    <AppDialogV2
        :visible="detailsModalVisible"
        :title="title"
        @close="detailsModalVisible = false"
    >
        <AppJSON
            :value="jsonData"
            :disabled="true"
            textareaHeight="27rem"
        />
        <template #modalFooter>
            <AppButton
                :buttonType="BUTTON_TYPES.PRIMARY"
                :label="$t('generic.copyToClipboard')"
                class="mr-3"
                @click="copyToClipboard(JSON.stringify(jsonData))"
            />
        </template>
    </AppDialogV2>
</template>

<script>
import { copyToClipboard } from '@/common/utils';
import AppJSON from '@/components/partials/AppJSON.vue';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import AppDialogV2 from '@/components/partials/AppDialogV2.vue';

export default {
    components: { AppButton, AppJSON, AppDialogV2 },
    data() {
        return {
            detailsModalVisible: false,
            jsonData: null,
            title: '',
            BUTTON_TYPES,
        };
    },
    methods: {
        copyToClipboard,
        display(jsonData, title) {
            // should be called from outside instead of managing data outside
            this.jsonData = jsonData;
            this.title = this.$i18n.t('formBuilderTypes.JSON');
            if (title) {
                this.title += `: ${title}`;
            }
            this.detailsModalVisible = true;
        },
    },
};
</script>
