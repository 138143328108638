
// Components
import AppSpinner from '@/components/partials/AppSpinner.vue';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import SubSidebarMixin from '@/components/partials/SubSidebarMixin.vue';
import SettingsSubSidebarMixin from '@/__new__/features/settings/SettingsSubSidebarMixin.vue';
import AbstractSubSidebarPageWrapper from '@/components/layout/AbstractSubSidebarPageWrapper.vue';
import SubSidebar from '@/components/layout/SubSidebar.vue';

// Helpers
import { isEmpty } from 'lodash';
import RouteNames from '@/router/routeNames';
import * as Sentry from '@sentry/vue';
import AppTable from '@/components/partials/AppTable.vue';
import { ICON_TYPES } from '@/common/iconHelper';
import { mapActions, mapGetters, mapState } from 'vuex';
import { Modules } from '@/store/store';
import Actions, { Getters, State } from '@/store/mutation-types';
import tableColumnType from '@/common/filterTable';
import AbstractListPageWrapper from '@/components/layout/AbstractListPageWrapper.vue';
import EntityOverview from '@/components/partials/entityOverview/EntityOverview.vue';
import ENTITY_TYPES from '@/common/entities/entityTypes';
import OverviewHeaderV2 from '@/components/partials/entityOverview/OverviewHeaderV2.vue';
import AppOverviewBlock from '@/components/partials/AppOverviewBlock.vue';
import { CollapsibleListItem } from '@/common/AppCollapsibleListHelper';
import QuietHoursDisplayRules from '@/__new__/features/settings/quietHours/QuietHoursDisplayRules.vue';
import { getBeautifulBoolean } from '@/common/formatting';
import { isUserAllowed } from '@/services/permissions/permissions.service';
import IconButton from '@/components/partials/IconButton.vue';
import { deleteCampaignCategory } from '@/__new__/services/dno/campaigns/http/campaigns';
import { ALERT_TYPES } from '@/common/alerts/Alert';
import Button from '@/common/button/Button';
import DetailsJsonModal from '@/components/partials/DetailsJsonModal.vue';
import ImportEntitiesModalButton from '@/components/partials/ImportEntitiesModalButton.vue';
import { QuietHoursConfig } from '@/__new__/features/settings/quietHours/quietHoursHelper';

export default {
    name: 'CampaignCategories',
    components: {
        ImportEntitiesModalButton,
        DetailsJsonModal,
        QuietHoursDisplayRules,
        AppOverviewBlock,
        EntityOverview,
        OverviewHeaderV2,
        AbstractListPageWrapper,
        AppTable,
        AppButton,
        AppSpinner,
        SubSidebar,
        AbstractSubSidebarPageWrapper,
        IconButton,
    },
    mixins: [SubSidebarMixin, SettingsSubSidebarMixin],
    data() {
        return {
            ENTITY_TYPES,
            BUTTON_TYPES,
            ICON_TYPES,
            hasRules: null,
            isDataLoading: false,
            quietHoursData: null,
            selectedCategory: null,
            selectedCategoryId: null,
            isOverviewEnabled: false,
        };
    },
    computed: {
        ...mapGetters(Modules.campaigns, {
            allCategories: [Getters.GET_CAMPAIGN_CATEGORIES],
        }),
        ...mapGetters(Modules.quietHours, {
            allQuietHoursRules: [Getters.GET_MAPPED_QUIET_HOURS],
        }),
        ...mapState(Modules.campaigns, {
            allRawCategories: [State.RAW_CAMPAIGN_CATEGORIES],
        }),
        qhRulesOfSelectedCategory(): QuietHoursConfig[] {
            if (this.selectedCategory?.qhRules) {
                return this.allQuietHoursRules.filter((qhr: QuietHoursConfig) =>
                    this.selectedCategory.qhRules.includes(qhr.id),
                );
            }
            return [];
        },
        tableColumnsData(): any {
            return [
                {
                    name: this.$t('generic.name'),
                    key: 'name',
                    field: 'name',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$t('settings.campaignCategories.campaignType'),
                    key: 'campaignType',
                    field: 'campaignType',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$t('campaigns.priority.campaignPriority'),
                    key: 'campaignPriority',
                    field: 'campaignPriority',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
            ];
        },
        generalOverviewItems(): CollapsibleListItem[] {
            const overviewItems = [
                {
                    name: this.$t('generic.general'),
                    isCollapsed: false,
                    rows: [
                        {
                            name: this.$t('settings.campaignCategories.inboxCategoryName'),
                            value: this.selectedCategory.inboxName,
                        },

                        {
                            name: this.$t('campaigns.priority.campaignPriority'),
                            value: this.selectedCategory.campaignPriority,
                        },
                        {
                            name: this.$t('settings.campaignCategories.campaignType'),
                            value: this.selectedCategory.campaignType,
                        },
                        {
                            name: this.$t('settings.campaignCategories.ignoreGlobalDailyLimits'),
                            value: getBeautifulBoolean(this.selectedCategory.ignoreDailyLimits),
                        },
                        {
                            name: this.$t('settings.campaignCategories.applyLimits'),
                            value: getBeautifulBoolean(this.selectedCategory.applyLimits),
                        },
                    ],
                },
            ];
            if (this.selectedCategory.applyLimits) {
                overviewItems.push({
                    isCollapsed: false,
                    name: 'Limits',
                    rows: [
                        {
                            name: this.$t('settings.campaignCategories.limitOfMessages'),
                            value: this.selectedCategory.limitNumber,
                        },
                        {
                            name: this.$t('settings.campaignCategories.daysAppliedForLimit'),
                            value: this.selectedCategory.limitPerDay,
                        },
                    ],
                });
            }
            return overviewItems;
        },
    },
    created() {
        this.loadData();
    },
    methods: {
        isUserAllowed,
        isEmpty,
        ...mapActions(Modules.campaigns, [Actions.FETCH_CAMPAIGN_CATEGORIES]),
        ...mapActions(Modules.quietHours, [Actions.LOAD_QUIET_HOURS]),
        loadData(): void {
            this.$Progress.start();
            this.isDataLoading = true;
            const promises = [this[Actions.FETCH_CAMPAIGN_CATEGORIES](), this[Actions.LOAD_QUIET_HOURS]()];
            Promise.all(promises)
                .then(() => {
                    this.$Progress.finish();
                })
                .catch((e: any) => {
                    Sentry.captureException(e);
                    this.$Progress.fail();
                    this.$alert('settings.campaignCategories.failedToLoadAll');
                })
                .finally(() => {
                    this.isDataLoading = false;
                });
        },
        onSelectEntity(id: string | number) {
            this.selectedCategoryId = id;
            this.selectedCategory = this.allCategories.find(e => e.id === id);
            this.isOverviewEnabled = true;
        },
        onDeleteButtonClicked(category) {
            const { name, id, version } = category;
            this.$alert(this.$i18n.t('alerts.areYouSureDeleteEntity', { entityName: name }), {
                type: ALERT_TYPES.warning,
                buttons: [
                    new Button({
                        label: this.$t('generic.delete'),
                        alertType: ALERT_TYPES.warning,
                        handler: () => this.deleteCategory(id, version),
                    }),
                ],
            });
        },
        onDetailsBtnClick({ id }: any) {
            const rawCategory = this.allRawCategories.data.entities.find(c => c.id === id);
            if (rawCategory) {
                this.$refs.DetailsJsonModal.display(rawCategory);
            }
        },
        onEditButtonClicked(category) {
            this.$router.push({
                name: RouteNames.CAMPAIGN_CATEGORIES_EDITOR,
                params: { id: category.id, companyId: this.$route.params.companyId },
            });
        },
        async deleteCategory(id: string, version: number) {
            this.$Progress.start();
            try {
                await deleteCampaignCategory(id, version);
                this.$Progress.finish();
                this.$alert(
                    this.$t('alertMessage.successMessageWithoutRedirect', {
                        entityName: this.$t('settings.campaignCategories.campaignCategory'),
                        action: this.$t('generic.stateMap.deleted').toLowerCase(),
                    }),
                    { type: ALERT_TYPES.success },
                );
                this[Actions.FETCH_CAMPAIGN_CATEGORIES]();
            } catch (e) {
                this.$Progress.fail();
                Sentry.captureException(e);
                this.$alert(this.$t('settings.campaignCategories.alerts.failedToDelete'));
            }
        },
        onAddNewClick() {
            this.$router.push({
                name: RouteNames.CAMPAIGN_CATEGORIES_EDITOR,
            });
        },
    },
};
