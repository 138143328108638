import http from '@/http';
import { type AxiosPromise } from 'axios';
import { type DnoResponseBase } from '@/http';
import { type OrderDataBe } from '@/__new__/services/dno/coreom/models/OrderData';

export interface OrdersResponse extends DnoResponseBase {
    orders: Record<string, OrderDataBe>;
}

const getOrders = (id: string, idType: number): AxiosPromise<OrdersResponse> => {
    return http({
        method: 'POST',
        url: '/v3coreom/getorders',
        data: {
            target_id: id,
            target_type: idType,
            options: {
                include_timestamp: true,
            },
        },
    });
};

const getOrdersByIds = (id: string, idType: number, orderIds: string[]): AxiosPromise<OrdersResponse> => {
    return http({
        method: 'POST',
        url: '/v3coreom/getorders',
        data: {
            target_id: id,
            target_type: idType,
            ids: orderIds,
        },
    });
};

export default {
    getOrders,
    getOrdersByIds,
};
