<script>
//  TableFilterRenderless.vue
import { filterEntities } from '@/common/filterTable';

export default {
    name: 'TableFilterRenderless',
    props: {
        entities: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            allFilters: [],
        };
    },
    computed: {
        filteredEntities() {
            return this.filteredEntitiesMixin(this.entities);
        },
    },
    methods: {
        onFilterAdded(filter) {
            this.allFilters.push(filter);
            this.allFiltersEmit();
        },
        removeFilter(index) {
            this.allFilters.splice(index, 1);
            this.allFiltersEmit();
        },
        removeAllFilters() {
            this.allFilters = [];
            this.allFiltersEmit();
        },
        filteredEntitiesMixin(entities) {
            return filterEntities(entities, this.allFilters);
        },
        computedFilterValue(filter) {
            return filter.condition.getDisplayValue
                ? filter.condition.getDisplayValue(filter.values)
                : filter.values.value;
        },
        columnsForFiltering() {
            return this.tableColumnsData.filter(
                column => !Object.prototype.hasOwnProperty.call(column, 'notForFiltering'),
            );
        },
        allFiltersEmit() {
            this.$emit('allFiltersUpdate', this.allFilters);
        },
    },
    render() {
        return this.$scopedSlots.default({
            allFilters: this.allFilters,
            onFilterAdded: this.onFilterAdded,
            removeFilter: this.removeFilter,
            removeAllFilters: this.removeAllFilters,
            filteredEntitiesMixin: this.filteredEntitiesMixin,
            computedFilterValue: this.computedFilterValue,
            columnsForFiltering: this.columnsForFiltering,
            filteredEntities: this.filteredEntities,
        });
    },
};
</script>

<style></style>
