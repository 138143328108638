var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"label-wrapper"},[(_vm.additionalLabel)?_c('div',{staticClass:"label d-flex align-items-center",class:{ small: _vm.small }},[_c('span',{staticClass:"label-text"},[_vm._v(" "+_vm._s(_vm.additionalLabel)+" ")]),(_vm.explanationText)?_c('AppTooltip',{staticClass:"ml-2",attrs:{"offset":_vm.tooltipOffset,"tooltipPosition":_vm.tooltipPosition},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticClass:"info-icon"})]},proxy:true},{key:"content",fn:function(){return [_c('div',{staticClass:"tooltip-info"},[_vm._v(_vm._s(_vm.explanationText))])]},proxy:true}],null,false,1572705593)}):_vm._e(),_vm._t("additionalLabelSlot")],2):_vm._e(),(_vm.optional)?_c('span',{staticClass:"input-optional"},[_vm._v(" "+_vm._s(_vm.$i18n.t('generic.optional'))+" ")]):_vm._e()]),_c('Multiselect',_vm._b({class:{
            error: _vm.error,
            small: _vm.small,
            disabled: _vm.disabled,
            verified: _vm.verified,
            'border-none': _vm.borderNone,
            'blue-arrow': _vm.blueArrow,
            'transparent-background': _vm.transparentBg,
            'single-label': _vm.isCustomLabel,
        },style:(_vm.widthStyleObject),attrs:{"data-test-id":"multiselect-appmultiselectv3","value":_vm.valueFormatted,"tagPlaceholder":_vm.tagPlaceholder,"placeholder":_vm.finalPlaceholder},on:{"select":(selectedOption, id) => [
                _vm.emitEvent('input', selectedOption, id),
                _vm.emitEvent('select', selectedOption, id),
            ],"search-change":(searchQuery, id) => _vm.emitEvent('search-change', searchQuery, id),"tag":(searchQuery, id) => _vm.emitEvent('tag', searchQuery, id),"open":function($event){return _vm.emitEvent('open', $event)},"close":(value, id) => _vm.emitEvent('close', value, id),"remove":_vm.removeItem},scopedSlots:_vm._u([_vm._l((_vm.scopedSlotKeys),function(key){return {key:key,fn:function({ option, tag, search, remove, toggle }){return [_vm._t(key,null,{"option":option,"tag":tag,"search":search,"remove":remove,"toggle":toggle})]}}})],null,true)},'Multiselect',_vm.customProps,false)),(_vm.error && _vm.errorMessage)?_c('div',{staticClass:"error-message"},[_vm._v(" "+_vm._s(_vm.errorMessage)+" ")]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }