import { getHistoryMetadata, getHistoryVersion } from '@/__new__/services/dno/privacyConsent/http/privacyHistory';
import { ENTITIES_TYPES_TO_MODEL_MAP } from '@/__new__/services/dno/privacyConsent/entitiesTypesToModel';
import { updatePolicySpecificationState } from '@/__new__/services/dno/privacyConsent/http/privacy';
import PolicySpecificationModel from '@/__new__/services/dno/template/models/PolicySpecificationModel';
import { TEMPLATE_TYPES_MAP } from '@/__new__/services/dno/template/models/templateTypes';
import { EntityStateMapping } from '@/common/entityStateMapper';
import Actions, { Getters } from '@/store/mutation-types';
import RouteNames from '@/router/routeNames';
import { mapActions, mapGetters } from 'vuex';
import {
    addEntity,
    deleteEntityDraft,
    getEntityDraft,
    setEntityDraft,
    updateEntity,
} from '@/__new__/services/dno/privacyConsent/entities';

export default {
    computed: {
        ...mapGetters('privacy', {
            getTemplatesApproved: Getters.GET_PRIVACY_TEMPLATES_BY_TYPE_APPROVED,
            getTemplate: Getters.GET_PRIVACY_TEMPLATE_BY_TYPE_AND_ID,
            getEntity: Getters.GET_PRIVACY_ENTITY_BY_TYPE_AND_ID,
            getEntitiesApproved: Getters.GET_PRIVACY_ENTITIES_BY_TYPE_APPROVED,
            getEntitiesByType: Getters.GET_PRIVACY_ENTITIES_BY_TYPE,
        }),
    },
    data() {
        return {
            // policy specification specific data
            policySpecificationCoreData: {},
            initialPolicySpecificationData: null,
            isPolicySpecificationValid: true,
            //
            entityTypeString: this.$i18n.tc('consent.partyPrivacyProfileSpecification'),
            routeListName: RouteNames.POLICY_SPECIFICATION_TEMPLATE_LIST,
            isPolicySpecificationEditor: true,
        };
    },
    methods: {
        ...mapActions('privacy', {
            requestTemplatesByType: Actions.PRIVACY_REQUEST_TEMPLATES_BY_TYPE_AND_IDS,
            requestEntitiesByType: Actions.PRIVACY_REQUEST_ENTITIES_BY_TYPE_AND_IDS,
        }),
        collectBaseDataPromises() {
            const promises = [
                this.requestTemplatesByType({ entityType: this.entityType }),
                this.fetchEntities(this.entityType),
                this.requestTemplatesByType({
                    entityType: TEMPLATE_TYPES_MAP.PRESET,
                }),
            ];
            return promises;
        },
        collectChildEntitiesPromises() {
            const childPromises = [];
            this.childEntitiesTypes.forEach(type => {
                if (type !== this.entityType) {
                    childPromises.push(this.fetchEntities(type));
                }
            });
            return childPromises;
        },
        async fetchEntities(entityType) {
            await this.requestEntitiesByType({ entityType });
        },
        async getHistoryVersion(id, entityType, version) {
            const result = await getHistoryVersion(id, entityType, version);
            if (result?.data?.specific_entity_version && this.readonly) {
                const EntityTypeModel = ENTITIES_TYPES_TO_MODEL_MAP.get(entityType);
                this.historyEntity = new EntityTypeModel(result.data.specific_entity_version);
            }
        },
        async collectEntityDraft(entityType, id) {
            return await getEntityDraft(entityType, id);
        },
        async collectHistoryMetadataPromises(id, entityType, properties) {
            return await getHistoryMetadata(id, entityType, properties);
        },
        getEntityTypesToModelMap() {
            return ENTITIES_TYPES_TO_MODEL_MAP;
        },
        processEntitySpecificData(entityData) {
            this.initialPolicySpecificationData = {
                specUpgrade: entityData.specUpgrade || false,
                lifecycleStatus: entityData.lifecycleStatus,
                validityControl: entityData.validityControl,
                validityStartDate: entityData.validityStartDate,
                validityEndDate: entityData.validityEndDate,
                validityDurationUnit: entityData.validityDurationUnit,
                validityDurationAmount: entityData.validityDurationAmount,
                validityQueryCount: entityData.validityQueryCount,
                service: entityData.serviceId,
                legalText: entityData.legalText,
                predicates: entityData.dataElement,
            };
        },
        async addEntity(formattedPayload, entityType) {
            await addEntity(formattedPayload, entityType);
        },
        async deleteEntityDraft(entityType, id) {
            await deleteEntityDraft(entityType, id);
        },
        async setEntityDraft(payload, entityType) {
            return await setEntityDraft(payload, entityType);
        },
        async updateEntity(payload, entityType, publishMessage) {
            await updateEntity(payload, entityType, publishMessage);
        },
        async deleteEntity(id, version) {
            await updatePolicySpecificationState(id, version, EntityStateMapping.DELETED);
        },
        formatSpecificEntityPayload() {
            const policySpecificationPayload = {
                ...this.entityData,
                ...this.policySpecificationCoreData,
            };
            if (this.policySpecificationCoreData?.lifecycleStatus) {
                policySpecificationPayload.lifecycleStatus = this.policySpecificationCoreData?.lifecycleStatus?.key;
            }
            if (this.policySpecificationCoreData?.validityControl) {
                policySpecificationPayload.validityControl = this.policySpecificationCoreData?.validityControl?.key;
            }
            if (this.policySpecificationCoreData?.validityDurationUnit) {
                policySpecificationPayload.validityDurationUnit =
                    this.policySpecificationCoreData?.validityDurationUnit?.key;
            }
            if (this.policySpecificationCoreData?.service) {
                policySpecificationPayload.serviceId = this.policySpecificationCoreData.service.id;
                policySpecificationPayload.serviceType = this.policySpecificationCoreData.service.type;
            }
            if (this.policySpecificationCoreData?.predicates) {
                policySpecificationPayload.dataElement = this.constructDataElement();
                delete policySpecificationPayload.predicates;
            }
            return PolicySpecificationModel.toJson(policySpecificationPayload);
        },
        constructDataElement() {
            const dataElement = [];
            if (this.policySpecificationCoreData?.service) {
                delete this.policySpecificationCoreData.service;
            }
            if (this.policySpecificationCoreData?.predicates) {
                this.policySpecificationCoreData.predicates.forEach(predicate => {
                    dataElement.push({
                        name: predicate.name,
                        pd: predicate.pd ? predicate.pd.map(x => x.key) : null,
                        purpose: predicate.purpose ? predicate.purpose.map(x => x.key) : null,
                        scope: predicate.scope ? predicate.scope.map(x => x.key) : null,
                        description: predicate.description,
                        consentCheck: predicate.consentCheck,
                        optoutCheck: predicate.optoutCheck,
                        legalBasis: predicate.legalBasis ? predicate.legalBasis.map(x => x.key) : null,
                        validityStartDate: this.policySpecificationCoreData.validityStartDate,
                        validityEndDate: this.policySpecificationCoreData.validityEndDate,
                    });
                });
            }
            return dataElement;
        },
        onPolicySpecificationValidated(isPolicySpecificationValid) {
            this.isPolicySpecificationValid = isPolicySpecificationValid;
        },
        validateSpecificEntityData() {
            console.log(`======validateSpecificEntityData=====starting====`);
            if (!this.$refs.policySpecificationCoreDataComponent) {
                return true;
            }
            this.$refs.policySpecificationCoreDataComponent.$v.$touch();
            const invalid = this.$refs.policySpecificationCoreDataComponent.$v.$invalid;
            if (invalid) {
                return false;
            }
            if (!this.policySpecificationCoreData.legalText) {
                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('alertMessage.policySpecification.legalTextRequired'),
                });
                return false;
            }
            if (!this.policySpecificationCoreData?.predicates?.length) {
                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('alertMessage.policySpecification.predicatesRequired'),
                });
                return false;
            }
            if (this.policySpecificationCoreData?.predicates?.length > 0) {
                const counts = {};
                this.policySpecificationCoreData?.predicates.forEach(predicate => {
                    counts[predicate.name] = (counts[predicate.name] || 0) + 1;
                });
                const hasDuplicate = Object.values(counts).some(count => count > 1);
                if (hasDuplicate) {
                    this.$eventBus.$emit('showAlert', {
                        message: this.$i18n.t('alertMessage.policySpecification.predicateNameIsUnique'),
                    });
                    return false;
                }
                const uniqueKeys = new Set();
                const errors = [];
                this.policySpecificationCoreData?.predicates.forEach(predicate => {
                    const purposeKeys = predicate.purpose?.map(p => p.key) || [];
                    const scopeKeys = predicate.scope?.map(s => s.key) || [];
                    if (purposeKeys.length > 0 && scopeKeys.length > 0) {
                        purposeKeys.forEach(purposeKey => {
                            scopeKeys.forEach(scopeKey => {
                                const uniqueKey = `${purposeKey}-${scopeKey}`;
                                if (uniqueKeys.has(uniqueKey)) {
                                    errors.push(uniqueKey);
                                } else {
                                    uniqueKeys.add(uniqueKey);
                                }
                            });
                        });
                    } else if (purposeKeys.length === 0 && scopeKeys.length > 0) {
                        scopeKeys.forEach(scopeKey => {
                            const uniqueKey = `no-purpose-${scopeKey}`;
                            if (uniqueKeys.has(uniqueKey)) {
                                errors.push(uniqueKey);
                            } else {
                                uniqueKeys.add(uniqueKey);
                            }
                        });
                    } else if (scopeKeys.length === 0 && purposeKeys.length > 0) {
                        purposeKeys.forEach(purposeKey => {
                            const uniqueKey = `${purposeKey}-no-scope`;
                            if (uniqueKeys.has(uniqueKey)) {
                                errors.push(uniqueKey);
                            } else {
                                uniqueKeys.add(uniqueKey);
                            }
                        });
                    } else {
                        const uniqueKey = 'no-purpose-no-scope';
                        if (uniqueKeys.has(uniqueKey)) {
                            errors.push(uniqueKey);
                        } else {
                            uniqueKeys.add(uniqueKey);
                        }
                    }
                });
                if (errors.length > 0) {
                    this.$eventBus.$emit('showAlert', {
                        message: this.$i18n.t('alertMessage.policySpecification.purposeAndScopeCombinationUnique'),
                    });
                    return false;
                }
            }
            return true;
        },
        async deleteActionApiCall(entityData, routeToListPage) {
            console.log(`======deleteActionApiCall=====starting====`);
            await this.$withProgressBar(
                async () => {
                    if (entityData.state !== STATUS_CODES.NA) {
                        await this.deleteEntity(entityData.id, entityData.version);
                    } else {
                        await this.deleteEntityDraft(this.entityType, entityData.id);
                    }
                    this.$alert(
                        this.$i18n.t('alertMessage.successActionMessageWithRedirect', {
                            action: this.$i18n.t('generic.deleted'),
                            entityName: this.entityTypeString,
                            entityNameRedirect: this.entityTypeString.toLowerCase(),
                        }),
                        { type: ALERT_TYPES.success },
                    );
                    setTimeout(routeToListPage, 1000);
                },
                {
                    errorHandler: error => {
                        console.log(`======deleteActionApiCall=====error====`);
                        console.log(`====error: ${JSON.stringify(error)}`);
                        console.log(`===error.response===: ${JSON.stringify(error?.response)}`);
                        console.log(`====error.response.data====: ${JSON.stringify(error?.response?.data)}`);
                        if (
                            error?.response?.status === 400 &&
                            error?.response?.data?.module === 'TEMPLATE' &&
                            error?.response?.data?.code === 26
                        ) {
                            this.externalIdShowError = true;
                            this.$eventBus.$emit('showAlert', {
                                message: this.$i18n.t('alertMessage.pc.externalIdUnique'),
                            });
                        } else {
                            this.$eventBus.$emit('showAlert', {
                                message: this.$i18n.t('alertMessage.errorDoingSmthTryAgain', {
                                    action: this.$i18n.t('generic.saving'),
                                    entityName: this.$i18n.tc('productCatalog.entities.template').toLowerCase(),
                                }),
                            });
                        }
                    },
                },
            );
        },
    },
};
