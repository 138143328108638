import ENTITY_TYPES from '@/common/entities/entityTypes';
import RouteNames from '@/router/routeNames';

const LIST_PAGE_BY_ENTITY_TYPE = {
    [ENTITY_TYPES.OFFER]: RouteNames.OFFERS_LIST_NEW,
    [ENTITY_TYPES.PRODUCT]: RouteNames.PRODUCT_LIST_NEW,
    [ENTITY_TYPES.SERVICE]: RouteNames.SERVICES_LIST_NEW,
    [ENTITY_TYPES.RESOURCE]: RouteNames.RESOURCE_LIST_NEW,
};

export default LIST_PAGE_BY_ENTITY_TYPE;
