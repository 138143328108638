import { LABEL_COLOR } from '@/common/labelsHelper';

export enum TEMPLATE_STATES_NUMERIC {
    UNAPPROVED = 1,
    APPROVED = 2,
    ARCHIVED = 3,
}

// TODO: use i18n and fix tests breaking because of i18n
export const TEMPLATE_STATE_TO_STRING_MAP = new Map([
    [TEMPLATE_STATES_NUMERIC.UNAPPROVED, 'Unapproved'],
    [TEMPLATE_STATES_NUMERIC.APPROVED, 'Approved'],
    [TEMPLATE_STATES_NUMERIC.ARCHIVED, 'Archived'],
]);

export const TEMPLATE_STATE_TO_COLOR_MAP = new Map([
    [TEMPLATE_STATES_NUMERIC.UNAPPROVED, LABEL_COLOR.yellow],
    [TEMPLATE_STATES_NUMERIC.APPROVED, LABEL_COLOR.green],
    [TEMPLATE_STATES_NUMERIC.ARCHIVED, LABEL_COLOR.red],
]);

// export const TEMPLATE_ENUM_TO_STATE = new Map([
//     [TEMPLATE_STATES.UNAPPROVED, 1],
//     [TEMPLATE_STATES.APPROVED, 2],
//     [TEMPLATE_STATES.ARCHIVED, 3],
// ])

export default {
    TEMPLATE_STATES_NUMERIC,
    TEMPLATE_STATE_TO_STRING_MAP,
    TEMPLATE_STATE_TO_COLOR_MAP,
};
