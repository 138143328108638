
import Vue, { PropType } from '@/common/typedVue';
import Actions, { Getters } from '@/store/mutation-types';

// Components
import CardListRadioInput from '@/components/partials/cards/CardListRadioInput.vue';
import AppInputV3 from '@/components/partials/inputs/AppInputV3.vue';
import AppToggle from '@/components/partials/inputs/AppToggle.vue';

// Helpers
import { TOOLTIP_POSITION } from '@/common/tooltip';
import ENTITY_TYPES from '@/common/entities/entityTypes';
import { Modules } from '@/store/store';
import ProductCatalogOfferModel from '@/__new__/services/dno/pc/models/ProductCatalogOfferModel';
import CategorizedOffers from '@/components/partials/inputs/CategorizedOffers.vue';
import { RECURRENCE_CADENCE } from '@/__new__/services/dno/pc/models/recurrenceCadence';

/* eslint-disable camelcase */
interface DnoObligationPropsData {
    subscription_model: number;
    first_charge_at: number;
    activation_type: number;
    override_first_charge_at: Record<string, string[]> | null;
    continue_recurrence: boolean;
    override_activation_type: Record<string, string[]> | null;
    recurrence_cadence: string;
    recurrence_limit: number;
}
/* eslint-enable camelcase */

export default Vue.extend({
    name: 'DnoObligation',
    components: {
        AppInputV3,
        AppToggle,
        CardListRadioInput,
        CategorizedOffers,
    },
    props: {
        value: {
            type: Object as PropType<Partial<DnoObligationPropsData> | null>,
            default: null,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            offersLoading: false,

            subscriptionModelOptions: [
                { id: 1, label: this.$t('productCatalog.offers.dnoObligation.subscriptionModelPrepaid') },
                { id: 2, label: this.$t('productCatalog.offers.dnoObligation.subscriptionModelPostpaid') },
            ],
            recurrenceCadenceOptions: [
                {
                    id: RECURRENCE_CADENCE.NONE,
                    label: this.$t('productCatalog.offers.dnoObligation.recurrenceCadenceNone'),
                },
                {
                    id: RECURRENCE_CADENCE.MONTHLY,
                    label: this.$t('productCatalog.offers.dnoObligation.recurrenceCadenceMonthly'),
                },
                {
                    id: RECURRENCE_CADENCE.QUARTERLY,
                    label: this.$t('productCatalog.offers.dnoObligation.recurrenceCadenceQuarterly'),
                },
                {
                    id: RECURRENCE_CADENCE.ANNUALLY,
                    label: this.$t('productCatalog.offers.dnoObligation.recurrenceCadenceAnnually'),
                },
            ],
            overrideFirstChargeAtCategories: [
                { id: 1, label: this.$t('productCatalog.offers.dnoObligation.overrideFirstChargeAtImmediate') },
                { id: 2, label: this.$t('productCatalog.offers.dnoObligation.overrideFirstChargeAtOnActivation') },
                { id: 3, label: this.$t('productCatalog.offers.dnoObligation.overrideFirstChargeAtOnBill') },
            ],
            overrideActivationTypeCategories: [
                { id: 1, label: this.$t('productCatalog.offers.dnoObligation.overrideActivationTypeOnBillCycle') },
                { id: 2, label: this.$t('productCatalog.offers.dnoObligation.overrideActivationTypeOnDemand') },
                { id: 3, label: this.$t('productCatalog.offers.dnoObligation.overrideActivationTypeImmediate') },
                { id: 4, label: this.$t('productCatalog.offers.dnoObligation.overrideActivationTypeIfCycleExists') },
            ],

            // Proxy
            TOOLTIP_POSITION,
        };
    },
    computed: {
        offers(): ProductCatalogOfferModel[] {
            return this.$store.getters[`${Modules.productcatalog}/${Getters.PC_GET_ENTITIES_BY_TYPE_NOT_DELETED}`](
                ENTITY_TYPES.OFFER,
            ) as ProductCatalogOfferModel[];
        },
        obligationData(): DnoObligationPropsData {
            return {
                subscription_model: this.value?.subscription_model || 1,
                first_charge_at: this.value?.first_charge_at || 1,
                activation_type: this.value?.activation_type || 1,
                override_first_charge_at: this.value?.override_first_charge_at || {},
                continue_recurrence: this.value?.continue_recurrence || false,
                override_activation_type: this.value?.override_activation_type || {},
                recurrence_cadence: this.value?.recurrence_cadence || RECURRENCE_CADENCE.NONE,
                recurrence_limit: this.value?.recurrence_limit || 1,
            };
        },
    },
    created() {
        Promise.all([this.fetchOffers()]);
    },
    methods: {
        async fetchOffers() {
            this.offersLoading = true;
            try {
                await this.$store.dispatch(`${Modules.productcatalog}/${Actions.PC_REQUEST_ENTITIES_BY_TYPE_AND_IDS}`, {
                    entityType: ENTITY_TYPES.OFFER,
                });
            } catch (error) {
                this.$alert(this.$t('alertMessage.failedToLoadNecessaryData'));
            }
            this.offersLoading = false;
        },
        emitValue(prop: string, value: any) {
            this.$emit('input', { ...this.value, [prop]: value });
        },
    },
});
