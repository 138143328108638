import { render, staticRenderFns } from "./BenefitEditor.vue?vue&type=template&id=66cad8fa&scoped=true"
import script from "./BenefitEditor.vue?vue&type=script&lang=js"
export * from "./BenefitEditor.vue?vue&type=script&lang=js"
import style0 from "./BenefitEditor.vue?vue&type=style&index=0&id=66cad8fa&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "66cad8fa",
  null
  
)

export default component.exports