import { render, staticRenderFns } from "./BucketPolicyCountersData.vue?vue&type=template&id=58868d63&scoped=true"
import script from "./BucketPolicyCountersData.vue?vue&type=script&lang=ts"
export * from "./BucketPolicyCountersData.vue?vue&type=script&lang=ts"
import style0 from "./BucketPolicyCountersData.vue?vue&type=style&index=0&id=58868d63&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "58868d63",
  null
  
)

export default component.exports