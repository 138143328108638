import { AxiosResponse } from 'axios';
import {
    DunningProfilesResponse,
    createDunningProfile as createDnoDunningProfile,
    getDunningProfiles as getDnoDunningProfiles,
    getDunningProfileById as getDnoDunningProfileById,
    updateDunningProfile as updateDnoDunningProfile,
    updateDunningProfileState,
} from '@/__new__/services/dno/billing/http/dunningProfiles';
import {
    DunningProfileEntity,
    ScheduleEntry as ScheduleEntryPortal,
} from '@/__new__/services/dno/billing/models/dunningProfilesPortal';

import {
    DunningProfile,
    ScheduleEntry as ScheduleEntryDno,
    TIME_REFERENCE,
} from '@/__new__/services/dno/billing/models/dunningProfilesDno';
import { Entity, EntityState } from '@/http';

export type DunningProfilesData = {
    response: AxiosResponse<DunningProfilesResponse>;
    entities: DunningProfileEntity[];
};

export const getDunningProfiles = async (languageKey = 'en'): Promise<DunningProfilesData> => {
    const response = await getDnoDunningProfiles();

    const dunningProfiles = Object.values(response.data.dunning_profile_by_id);
    const entities = dunningProfiles.map(dunningProfile =>
        convertDunningProfileDnoToPortal(dunningProfile, languageKey),
    );

    return { response, entities };
};

export const getDunningProfileById = async (id: string, languageKey = 'en'): Promise<DunningProfileEntity> => {
    const response = await getDnoDunningProfileById(id);

    // Build Portal consumable entities
    const dunningProfiles = Object.values(response.data.dunning_profile_by_id ?? {});
    const entities = dunningProfiles.map(dunningProfile =>
        convertDunningProfileDnoToPortal(dunningProfile, languageKey),
    );

    // Throw error if we're unable to locate desired entity in payload
    if (entities.length && entities[0].id === id) {
        return entities[0];
    } else {
        throw new Error(`Error fetching dunning profile (${id}) from DNO`);
    }
};

const convertDunningProfileDnoToPortal = (
    dunningProfile: Entity<DunningProfile>,
    languageKey = 'en',
): DunningProfileEntity => {
    return {
        id: dunningProfile.id,
        name: dunningProfile.data.name[languageKey] ?? '',
        description: dunningProfile.data.description[languageKey] ?? '',
        version: dunningProfile.version,
        state: dunningProfile.state,
        nameByLanguage: dunningProfile.data.name,
        descriptionByLanguage: dunningProfile.data.description,
        schedule: dunningProfile.data.schedule.map(entry => convertScheduleEntryDnoToPortal(entry)),
    };
};

const convertDunningProfilePortalToDno = (dunningProfile: DunningProfileEntity, languageKey = 'en'): DunningProfile => {
    return {
        name: {
            ...dunningProfile.nameByLanguage,
            [languageKey]: dunningProfile.name,
        },
        description: {
            ...dunningProfile.descriptionByLanguage,
            [languageKey]: dunningProfile.description,
        },
        schedule: convertSchedulePortalToDno(dunningProfile.schedule),
    };
};
const convertSchedulePortalToDno = (schedule: ScheduleEntryPortal[]): ScheduleEntryDno[] => {
    // Conver to DNO
    const scheduleDno = schedule.map(entry => convertScheduleEntryPortalToDno(entry));

    // Sort before submission: split array based on timeReference
    const availableInEntries = scheduleDno.filter(entry => entry.time_reference === TIME_REFERENCE.BILL_AVAIABLE_IN);
    const dueInEntries = scheduleDno.filter(entry => entry.time_reference === TIME_REFERENCE.BILL_DUE_IN);
    const pastDueEntries = scheduleDno.filter(entry => entry.time_reference === TIME_REFERENCE.BILL_PAST_DUE);

    // Sort each of the pieces
    availableInEntries.sort((a, b) => b.time_amount - a.time_amount); // desc
    dueInEntries.sort((a, b) => b.time_amount - a.time_amount); // desc
    pastDueEntries.sort((a, b) => a.time_amount - b.time_amount); // asc

    // Convert to DNO format
    return [...availableInEntries, ...dueInEntries, ...pastDueEntries];
};

const convertScheduleEntryDnoToPortal = (entry: ScheduleEntryDno): ScheduleEntryPortal => {
    return {
        timeUnit: entry.time_unit,
        timeAmount: String(entry.time_amount),
        timeReference: entry.time_reference,
        actions: entry.actions,
    };
};

const convertScheduleEntryPortalToDno = (entry: ScheduleEntryPortal): ScheduleEntryDno => {
    return {
        time_unit: entry.timeUnit,
        time_amount: Number(entry.timeAmount),
        time_reference: entry.timeReference,
        actions: entry.actions,
    };
};

export const deleteDunningProfile = async (entity: DunningProfileEntity): Promise<void> => {
    await updateDunningProfileState(entity.id, entity.version, EntityState.DELETED);
};

export const createDunningProfile = async (entity: DunningProfileEntity, languageKey = 'en'): Promise<void> => {
    const dnoDunningProfile = convertDunningProfilePortalToDno(entity, languageKey);
    await createDnoDunningProfile(dnoDunningProfile);
};

export const updateDunningProfile = async (entity: DunningProfileEntity, languageKey = 'en'): Promise<void> => {
    await updateDnoDunningProfile(entity.id, entity.version, convertDunningProfilePortalToDno(entity, languageKey));
};
