<template>
    <div>
        <div class="row align-items-center">
            <div class="d-flex col-9">
                <div class="d-flex">
                    <span
                        v-for="index in depth - 1"
                        :key="`vertical-line-${feature.id}-${index}`"
                    >
                        <svg
                            v-if="index < depth - 1"
                            class="vertical-svg"
                            width="24"
                            height="48"
                            viewBox="0 0 24 48"
                        >
                            <line
                                x1="0"
                                y1="0"
                                x2="0"
                                y2="48"
                                style="stroke: #335195; stroke-width: 2px"
                            />
                        </svg>
                        <svg
                            v-if="index === depth - 1 && !lastChild"
                            class="vertical-svg"
                            width="24"
                            height="48"
                            viewBox="0 0 24 48"
                        >
                            <line
                                x1="0"
                                y1="0"
                                x2="0"
                                y2="48"
                                style="stroke: #335195; stroke-width: 2px"
                            />
                            <line
                                x1="0"
                                y1="0"
                                x2="0"
                                y2="24"
                                transform="rotate(-90 12 12)"
                                style="stroke: #335195; stroke-width: 2px"
                            />
                        </svg>
                        <svg
                            v-if="index === depth - 1 && lastChild"
                            class="vertical-svg"
                            width="24"
                            height="48"
                            viewBox="0 0 24 48"
                        >
                            <line
                                x1="0"
                                y1="0"
                                x2="0"
                                y2="24"
                                style="stroke: #335195; stroke-width: 2px"
                            />
                            <line
                                x1="0"
                                y1="0"
                                x2="0"
                                y2="24"
                                transform="rotate(-90 12 12)"
                                style="stroke: #335195; stroke-width: 2px"
                            />
                        </svg>
                    </span>
                </div>
                <div class="content">
                    <AppIcon
                        v-if="numChildren > 0"
                        :type="ICON_TYPES.ARROW_DOWN"
                        :color="ICON_COLORS.BLUE"
                        :class="['dropdown-arrow', { rotate180: isOpen }]"
                        @click="onArrowClicked"
                    />
                    <span :class="['label lf-table-title', { 'arrow-margin': !(numChildren > 0) }]">
                        {{ feature.id }}
                    </span>
                </div>
            </div>
            <AppCheckbox
                :value="feature.enabled"
                class="col-3"
                style="margin-left: auto"
                @input="emitFeatureStateChange"
            />
        </div>
        <template v-for="(value, key, index) in children">
            <FeaturesTree
                v-show="isOpen"
                :key="key"
                :feature="value"
                :depth="depth + 1"
                :lastChild="index === numChildren - 1"
                @change="emitFeatureTreeChange"
            />
        </template>
    </div>
</template>

<script>
// Components
import AppIcon from '@/components/partials/icon/AppIcon.vue';
import AppCheckbox from '@/components/partials/inputs/AppCheckbox.vue';

// Helpers
import { ICON_TYPES, ICON_COLORS } from '@/common/iconHelper';

export default {
    name: 'FeaturesTree',
    components: {
        AppIcon,
        AppCheckbox,
    },
    props: {
        feature: {
            type: Object,
            default: () => undefined,
            required: true,
        },
        depth: {
            type: Number,
            default: 1,
        },
        lastChild: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            isOpen: false,
            isRowHovered: false,

            // Proxy imports
            ICON_TYPES,
            ICON_COLORS,
        };
    },
    computed: {
        children() {
            return this.feature.features;
        },
        numChildren() {
            return typeof this?.feature?.features === 'object' && !Array.isArray(this.feature.features)
                ? Object.values(this.feature.features).length
                : 0;
        },
    },
    methods: {
        onArrowClicked() {
            this.isOpen = !this.isOpen;
        },
        emitFeatureStateChange(value) {
            if (value) {
                this.isOpen = true;
            }
            this.$emit('change', {
                keys: [
                    {
                        featureId: this.feature.id,
                    },
                ],
                value,
                featureId: this.feature.id,
            });
        },
        emitFeatureTreeChange(event) {
            this.$emit('change', {
                keys: [
                    {
                        featureId: this.feature.id,
                    },
                    ...event.keys,
                ],
                value: event.value,
                featureId: event.featureId,
            });
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/palette';
@import '~@/assets/scss/icons';
@import '~@/assets/scss/animations';
@import '~@/assets/scss/z-indexes';
@import '~@/assets/scss/typographyV2';

$icon-path: '~@/assets/icons/';

.row {
    display: flex;
    align-items: center;
    height: 3rem;
    position: relative;

    &:hover {
        background: $blue5;
    }
}

.content {
    display: flex;
    align-items: center;
    margin-left: 0.25rem;
    max-height: 3rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.vertical-svg {
    position: relative;
    top: 0;
    height: 3rem;
    width: 1.625rem;
    margin-left: 2rem;
}

.dropdown-arrow {
    cursor: pointer;
}

.rotate180 {
    transform: rotate(180deg);
}

.arrow-margin {
    margin-left: 1.125rem;
}

.label {
    padding-left: 0.5rem;
}
</style>
