<template>
    <div
        class="tree-node"
        :style="computedNodeStyles"
        :class="computedNodeClasses"
        :disabled="disabled"
    >
        <div v-show="!localIsCollapsed">
            <div class="tree-node__operations">
                <AppMultiselectV3
                    v-model="selectedOperation"
                    class="tree-node__op-select"
                    data-testid="operation-select"
                    :options="computedOperationGroups"
                    groupLabel="group"
                    groupValues="operations"
                    label="name"
                    trackBy="id"
                    :placeholder="$t('generic.selectOperation')"
                    :error="!selectedOperation && localDisplayError"
                    :disabled="disabled"
                    :allowEmpty="false"
                />
                <div class="tree-node__btns">
                    <AppButton
                        v-if="isAddBtnEnabled"
                        class="tree-node__btn"
                        data-testid="add-node-btn"
                        :label="$t('generic.add')"
                        :disabled="disabled"
                        isSmall
                        @click="addSiblingNode"
                    />
                    <AppButton
                        class="tree-node__btn"
                        data-testid="collapse-node-btn"
                        :label="toggleBtnLabel"
                        :disabled="disabled"
                        isSmall
                        @click="localIsCollapsed = !localIsCollapsed"
                    />
                    <AppButton
                        class="tree-node__btn"
                        data-testid="delete-node-btn"
                        :label="$t('generic.delete')"
                        :disabled="disabled"
                        isSmall
                        @click="deleteNode"
                    />
                </div>
            </div>
            <div
                v-if="selectedOperation"
                class="tree-node__info"
            >
                <ConditionArguments
                    v-if="selectedOperation && node.args && Object.keys(node.args).length > 0"
                    class="tree-node__args"
                    data-testid="condition-arguments"
                    :args="node.args"
                    :config="node.config.args || {}"
                    :displayError="localDisplayError"
                    :disabled="disabled"
                    :pricingDemo="pricingDemo"
                    @update="updateTreeNodeArgs"
                    @updateErrorState="argsHaveError = $event"
                />
            </div>
        </div>
        <div v-show="localIsCollapsed">
            <div class="tree-node__operations">
                <div>
                    <div>{{ opShortDesc }}</div>
                </div>
                <div class="tree-node__btns">
                    <AppButton
                        v-if="isAddBtnEnabled"
                        class="tree-node__btn"
                        :label="$t('generic.add')"
                        :disabled="disabled"
                        isSmall
                        @click="addSiblingNode"
                    />
                    <AppButton
                        class="tree-node__btn"
                        :label="toggleBtnLabel"
                        :disabled="disabled"
                        isSmall
                        @click="localIsCollapsed = !localIsCollapsed"
                    />
                    <AppButton
                        class="tree-node__btn"
                        :label="$t('generic.delete')"
                        :disabled="disabled"
                        isSmall
                        @click="deleteNode"
                    />
                </div>
            </div>
        </div>
        <div v-if="nestedNodesList.length">
            <ConditionsTree
                v-for="(nestedNode, index) in nestedNodesList"
                :key="nestedNode.id"
                :node="nestedNode"
                :depth="depth + 1"
                :isCollapsed="isCollapsed"
                :displayError="displayError"
                :isAddBtnEnabled="getNestedNodeAddBtnState(index)"
                :disabled="disabled"
                :pricingDemo="pricingDemo"
                :optionsConfigPath="node.config.path"
                :optionsConfig="optionsConfig"
                :showLegacy="showLegacy"
                :insideField="isInsideField"
                @updateErrorState="updateChildrenErrorState(index, $event)"
            />
        </div>
    </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';
import { mapMutations } from 'vuex';
import AppMultiselectV3 from '@/components/partials/inputs/AppMultiselectV3.vue';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import ConditionArguments from '@/modules/rewards/components/conditions-tree/ConditionArguments.vue';
import {
    OPERATION,
    DEFAULT_OPERATION_GROUPS,
    COLOR_SCHEME,
    createTreeNode,
    CUSTOM_TYPE,
    getOptionsFromConfig,
} from '@/common/conditions-tree';
import Button from '@/common/button/Button';
import { ALERT_TYPES, ALERT_COLORS } from '@/common/alerts/Alert';

export default {
    name: 'ConditionsTree',
    components: {
        ConditionArguments,
        AppMultiselectV3,
        AppButton,
    },
    props: {
        node: {
            type: Object,
            required: true,
        },
        depth: {
            type: Number,
            default: 0,
        },
        isCollapsed: {
            type: Boolean,
            default: false,
        },
        isAddBtnEnabled: {
            type: Boolean,
            default: false,
        },
        displayError: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        pricingDemo: {
            type: Boolean,
            default: false,
        },
        optionsConfig: {
            type: Object,
            default: null,
        },
        optionsConfigPath: {
            type: String,
            default: null,
        },
        showLegacy: {
            type: Boolean,
            default: false,
        },
        insideField: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            localIsCollapsed: false,
            localDisplayError: false,
            selectedOperation: null,
            childrenHaveError: [],
            argsHaveError: false,
            argsShortDesc: '',
            BUTTON_TYPES,
        };
    },
    computed: {
        computedOperationsIdMap() {
            return this.computedOperationGroups.reduce((acc, operation) => {
                acc[operation.id] = operation;
                return acc;
            }, {});
        },
        computedOperationGroups() {
            if (!this.optionsConfig) {
                return DEFAULT_OPERATION_GROUPS.map(group => ({
                    ...group,
                    group: this.$i18n.t(group.i18nGroupKey),
                    operations: group.operations.map(op => ({
                        ...op,
                        name: op.i18nNameKey ? this.$i18n.t(op.i18nNameKey) : op.label,
                    })),
                }));
            }
            return getOptionsFromConfig(this.optionsConfig, this.optionsConfigPath, !this.showLegacy);
        },
        computedOperationGroupsWithLegacy() {
            if (!this.optionsConfig) {
                return DEFAULT_OPERATION_GROUPS.map(group => ({
                    ...group,
                    group: this.$i18n.t(group.i18nGroupKey),
                    operations: group.operations.map(op => ({
                        ...op,
                        name: op.i18nNameKey ? this.$i18n.t(op.i18nNameKey) : op.label,
                    })),
                }));
            }
            return getOptionsFromConfig(this.optionsConfig, this.optionsConfigPath, false);
        },
        nestedNodesList() {
            if (!this.node?.nested) return [];
            return Object.values(this.node.nested);
        },
        computedNodeStyles() {
            return {
                backgroundColor: COLOR_SCHEME[this.depth] || COLOR_SCHEME[COLOR_SCHEME.length - 1],
                padding: this.localIsCollapsed ? '0.3125rem 1.25rem' : '1.25rem',
            };
        },
        computedNodeClasses() {
            return {
                'tree-node__error': this.localIsCollapsed && this.localDisplayError && this.nodeHasError,
            };
        },
        toggleBtnLabel() {
            return this.localIsCollapsed ? this.$t('generic.expand') : this.$t('generic.collapse');
        },
        opShortDesc() {
            let valueFormatted = (this.node?.args?.value ?? '').toString();
            valueFormatted = valueFormatted.replace(/,/g, ',\n');
            return this.selectedOperation
                ? `${this.selectedOperation.name}\n${valueFormatted}`
                : this.$t('rewards.editor.operationIsNotSelected');
        },
        nodeHasError() {
            return !this.selectedOperation || this.argsHaveError;
        },
        conditionHasError() {
            return this.nodeHasError || this.childrenHaveError.some(value => !!value);
        },
        isInsideField() {
            return this.selectedOperation?.op === CUSTOM_TYPE.FIELD || this.insideField;
        },
    },
    watch: {
        node: {
            immediate: true,
            deep: true,
            handler(newItem, oldItem) {
                if (!this.node?.args) {
                    this.argsHaveError = false;
                }
                if (newItem && oldItem && newItem?.id !== oldItem?.id) {
                    // old node is being replaced
                    this.selectedOperation = this.optionsConfig
                        ? this.getConfigOperationByPath(newItem.config.path)
                        : this.getDefaultOperationByOp(newItem.operation);
                    return;
                }

                if (this.nestedNodesList.length >= this.node?.config?.minNestedNum) {
                    return;
                }

                // eslint-disable-next-line no-plusplus
                for (let i = this.nestedNodesList.length; i < this.node?.config?.minNestedNum; i++) {
                    const newNode = createTreeNode({
                        parentId: this.node.id,
                    });
                    this.mUpdateTree(newNode);
                }
            },
        },
        nestedNodesList() {
            this.childrenHaveError = this.childrenHaveError.slice(0, this.nestedNodesList.length);
        },
        selectedOperation(newItem, oldItem) {
            if (!newItem || !this.selectedOperation) {
                return;
            }
            if (this.optionsConfig) {
                if (this.selectedOperation.config?.path === this.node?.config?.path) {
                    return;
                }
            } else if (this.selectedOperation?.op === this.node?.operation) {
                return;
            }

            this.localDisplayError = false;
            const isReplaceable = oldItem?.config?.isReplaceableWith?.[newItem?.op];
            const isNestable =
                newItem?.op === OPERATION.AND || newItem?.op === OPERATION.OR || newItem?.op === OPERATION.NOT;
            const isUnedited =
                (this.node.nested &&
                    Object.keys(this.node.nested).every(k => this.node.nested[k].operation === null)) ||
                (this.node.args &&
                    Object.keys(this.node.args).every(
                        k =>
                            this.node.args[k] === [] ||
                            this.node.args[k] === '' ||
                            this.node.args[k] === null ||
                            k === 'dataType',
                    ));

            const newNode = createTreeNode({
                id: this.node.id,
                parentId: this.node.parentId,
                operation: this.selectedOperation.op,
                args: this.selectedOperation.args,
                nested: null,
                config: this.selectedOperation.config,
            });

            // Delete "key" when we're inside a schema/field
            if (this.insideField && newNode.args) {
                delete newNode.args.key;
            }

            if (!oldItem) {
                this.selectedOperation = newItem;
                this.mUpdateTree(newNode);
            } else if (isUnedited) {
                this.selectedOperation = newItem;
                this.mUpdateTree(newNode);
            } else if (isReplaceable && !isNestable) {
                // transfer args/nested, no alert
                this.selectedOperation = newItem;
                newNode.args = this.node.args;
                newNode.nested = this.node.nested;
                this.mUpdateTree(newNode);
            } else if (isNestable) {
                // prompt
                let alertString = '';
                const buttons = [];

                // add swap alert/button
                if (isReplaceable) {
                    alertString = `${alertString}${this.$t('rewards.editor.swapOperationOnly')} `;
                    const swapButton = new Button({
                        label: this.$i18n.t('rewards.editor.swap'),
                        colorScheme: ALERT_COLORS.blue,
                    });
                    this.$eventBus.$once('buttonClicked', id => {
                        if (id === swapButton.id) {
                            this.selectedOperation = newItem;
                            newNode.args = this.node.args;
                            newNode.nested = this.node.nested;
                            this.mUpdateTree(newNode);
                        }
                    });
                    buttons.push(swapButton);
                }

                // add nesting alert/button
                alertString = `${alertString}${this.$t('rewards.editor.nestExistingCondition')} `;
                const nestButton = new Button({
                    label: this.$i18n.t('rewards.editor.nest'),
                    colorScheme: ALERT_COLORS.blue,
                });
                this.$eventBus.$once('buttonClicked', id => {
                    if (id === nestButton.id) {
                        this.selectedOperation = newItem;
                        this.mNestTree(newNode);
                    }
                });
                buttons.push(nestButton);

                // add overwrite alert/button
                alertString = `${alertString}${this.$t('rewards.editor.overwriteCompletely')} `;
                const overwriteButton = new Button({
                    label: this.$i18n.t('rewards.editor.overwrite'),
                    colorScheme: ALERT_COLORS.blue,
                });
                this.$eventBus.$once('buttonClicked', id => {
                    if (id === overwriteButton.id) {
                        this.selectedOperation = newItem;
                        this.mUpdateTree(newNode);
                    }
                });
                buttons.push(overwriteButton);

                // rollback if no options selected
                this.rollbackIfClosed(oldItem);

                // show the alert
                this.$eventBus.$emit('showAlert', {
                    message: alertString,
                    type: ALERT_TYPES.warning,
                    buttons,
                });
            } else {
                const continueButton = new Button({
                    label: this.$i18n.t('generic.continue'),
                    colorScheme: ALERT_COLORS.blue,
                });
                this.$eventBus.$emit('showAlert', {
                    message: this.$t('rewards.editor.existingConditionsWillBeRemoved'),
                    type: ALERT_TYPES.warning,
                    buttons: [continueButton],
                });
                this.$eventBus.$once('buttonClicked', id => {
                    if (id === continueButton.id) {
                        this.selectedOperation = newItem;
                        this.mUpdateTree(newNode);
                    }
                });

                // rollback if no options selected
                this.rollbackIfClosed(oldItem);
            }
        },
        isCollapsed() {
            this.localIsCollapsed = this.isCollapsed;
        },
        displayError() {
            this.localDisplayError = this.displayError;
        },
        conditionHasError: {
            immediate: true,
            handler(value) {
                this.$emit('updateErrorState', value);
            },
        },
    },
    created() {
        const config = this.optionsConfig;
        const path = this.node?.config?.path;
        const { operation } = this.node;
        const currentOperation = config ? this.getConfigOperationByPath(path) : this.getDefaultOperationByOp(operation);
        if (currentOperation) this.selectedOperation = currentOperation;
    },
    methods: {
        ...mapMutations({
            mNestTree: 'conditionsTree/nestTree',
            mUpdateTree: 'conditionsTree/updateTree',
            mDeleteTreeNode: 'conditionsTree/deleteTreeNode',
        }),
        addSiblingNode() {
            const newNode = createTreeNode({
                parentId: this.node.parentId,
            });
            this.mUpdateTree(newNode);
        },
        updateTreeNodeArgs({ key, value }) {
            this.mUpdateTree({
                ...this.node,
                args: {
                    ...this.node.args,
                    [key]: value,
                },
            });
        },
        performDelete(node, parent, transfer) {
            // if only 2 children and we are deleting one, ask if we want to delete the entire AND/OR above it
            const parentDeletable =
                !transfer &&
                parent &&
                (parent.operation === OPERATION.AND || parent.operation === OPERATION.OR) &&
                Object.keys(parent.nested).length === 2;
            if (parentDeletable) {
                const deleteButton = new Button({
                    label: this.$i18n.t('generic.delete'),
                    colorScheme: ALERT_COLORS.blue,
                });
                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('rewards.editor.deleteAndOrAbove'),
                    type: ALERT_TYPES.warning,
                    buttons: [deleteButton],
                });
                this.$eventBus.$once('buttonClicked', id => {
                    if (id === deleteButton.id) {
                        this.mDeleteTreeNode({
                            nodeId: parent.id,
                            transferChildrenToParent: true,
                        });
                    }
                });
                this.$eventBus.$once('alertClosed', () => {
                    const updatedParent = parent.id ? this.$store.getters['conditionsTree/nodeById'](parent.id) : null;
                    const parentCopy = cloneDeep(updatedParent);
                    parentCopy.config.minNestedNum = 2;
                    this.mUpdateTree(parentCopy); // revert the hack from before
                });

                const parentCopy = cloneDeep(parent);
                parentCopy.config.minNestedNum = 1;
                this.mUpdateTree(parentCopy); // hack to prevent auto-adding placeholders before alert
            }

            this.mDeleteTreeNode({
                nodeId: node.id,
                transferChildrenToParent: transfer,
            });
        },
        deleteNode() {
            const deleteButton = new Button({
                label: this.$i18n.t('generic.delete'),
                colorScheme: ALERT_COLORS.blue,
            });

            let messaged = false;

            const parent = this.node.parentId
                ? this.$store.getters['conditionsTree/nodeById'](this.node.parentId)
                : null;

            if (parent) {
                // ask if child nodes should be kept or not
                if (parent.operation === OPERATION.AND || parent.operation === OPERATION.OR) {
                    // ask if we want to keep children of this node (if this node has transferrable children (see boolean below))
                    if (
                        this.node.operation === OPERATION.AND ||
                        this.node.operation === OPERATION.OR ||
                        this.node.operation === OPERATION.NOT
                    ) {
                        const transferButton = new Button({
                            label: this.$i18n.t('rewards.editor.transfer'),
                            colorScheme: ALERT_COLORS.blue,
                        });
                        this.$eventBus.$emit('showAlert', {
                            message: this.$t('rewards.editor.transferChildrenToParent'),
                            type: ALERT_TYPES.warning,
                            buttons: [transferButton, deleteButton],
                        });
                        this.$eventBus.$once('buttonClicked', id => {
                            if (id === transferButton.id) {
                                this.performDelete(this.node, parent, true);
                            }
                        });
                        messaged = true;
                    }
                }
            }

            // default message
            if (!messaged) {
                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('alerts.areYouSure'),
                    type: ALERT_TYPES.warning,
                    buttons: [deleteButton],
                });
            }
            this.$eventBus.$once('buttonClicked', id => {
                if (id === deleteButton.id) {
                    this.performDelete(this.node, parent, false);
                }
            });
        },
        getNestedNodeAddBtnState(nestedNodeIndex) {
            if (nestedNodeIndex !== this.nestedNodesList.length - 1) {
                return false;
            }
            const opName = this.node.operation;
            return opName === OPERATION.AND || opName === OPERATION.OR;
        },
        updateChildrenErrorState(index, value) {
            this.childrenHaveError.splice(index, 1, value);
        },
        getDefaultOperationByOp(op) {
            return this.computedOperationGroupsWithLegacy.reduce(
                (acc, curr) => acc ?? curr.operations.find(o => o.op === op),
                null,
            );
        },
        getConfigOperationByPath(path) {
            return this.computedOperationGroupsWithLegacy.reduce(
                (acc, curr) => acc ?? curr.operations.find(o => o.config.path === path),
                null,
            );
        },
        rollbackIfClosed(oldItem) {
            this.$eventBus.$once('alertClosed', () => {
                this.selectedOperation = oldItem;
            });
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/palette';

.tree-node {
    margin: 0.625rem 0 0 0;
    border: 0.0625rem solid $gray5;
    border-radius: 0.5rem;

    &__operations {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__op-select {
        width: 21.875rem;
    }

    &__btns {
        display: flex;
    }

    &__btn {
        margin-left: 0.625rem;
    }

    &__info {
        padding: 1.25rem 0 0 0;
    }

    &__info-key {
        width: 6.25rem;
    }

    &__info-element {
        margin-top: 0.3125rem;
        display: flex;
        align-items: center;
    }

    &__args {
        margin-top: 0.9375rem;
    }

    &__nested-label {
        margin-top: 0.625rem;
        font-weight: 700;
    }

    &__error {
        border-color: $red;
    }
}
</style>
