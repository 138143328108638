
import { PropType } from 'vue';
import AppLabel from '@/components/partials/AppLabel.vue';
import AppSpinner from '@/components/partials/AppSpinner.vue';

export type StatusLabelAndColor = {
    title: string;
    color: string | undefined;
};

export default {
    name: 'StatusChecker',
    components: {
        AppLabel,
        AppSpinner,
    },
    props: {
        title: {
            type: String,
            required: true,
        },
        status: {
            type: Object as PropType<StatusLabelAndColor>,
            required: true,
        },
        isLoading: {
            type: Boolean,
            required: true,
        },
    },
    computed: {
        hasStatus() {
            return this.status.title && !this.isLoading;
        },
        label() {
            return !this.hasStatus ? this.title : this.$i18n.t('generic.refresh');
        },
    },
    methods: {
        getStatus() {
            this.$emit('getStatus');
        },
    },
};
