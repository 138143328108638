
export default {
    name: 'SummaryCard',
    props: {
        summary: {
            type: String,
            required: true,
        },
    },
};
