<template>
    <!-- modals -->
    <AppDialogV2
        :visible="true"
        :title="$i18n.t('productCatalog.compatibilityRules.compatibilityRules')"
        :disableDefaultSaveBtn="true"
        @close="onCancel()"
    >
        <div class="compatibility-modal">
            <div class="lf-subtitle header">{{ $i18n.t('generic.name') }} : {{ currentOffer.name }}</div>
            <div class="lf-subtitle header">{{ $i18n.t('generic.externalId') }} : {{ externalIDToDisplay }}</div>
            <div>
                <AppMultiselectV3
                    ref="prereqSelection"
                    v-model="prereqTargets"
                    class="mt-5"
                    :multiple="true"
                    :options="eligiblePrereqTargetOffers"
                    :placeholder="$i18n.t('productCatalog.compatibilityRules.choosePrerequisiteOffers')"
                    :additionalLabel="$i18n.t('productCatalog.compatibilityRules.prerequisites')"
                    :explanationText="$i18n.t('productCatalog.compatibilityRules.prereqExplanation')"
                    :tooltipOffset="-50"
                    label="name"
                    @input="enableHasChangesFlag"
                >
                    <template
                        slot="option"
                        slot-scope="props"
                    >
                        <div>
                            <div
                                class="font-weight-bold mb-2"
                                data-test-id="prereq-selection-option-name"
                            >
                                {{ props.option.originName }}
                            </div>
                            <div class="d-flex align-items-center mb-2">
                                {{ $i18n.t('generic.state') }}: <EntityStatusIndicator :status="props.option.state" />
                            </div>
                            <div class="mb-2">{{ $i18n.t('generic.id') }}: {{ props.option.id }}</div>
                            <div>{{ $i18n.t('generic.externalId') }}: {{ props.option.externalId }}</div>
                        </div>
                    </template>
                </AppMultiselectV3>
                <div class="mt-1 d-flex">
                    {{ $i18n.t('productCatalog.compatibilityRules.requireAllPrerequisites') }}
                    <AppToggle
                        v-model="requireAllPrereqs"
                        class="ml-2 toggle-btn"
                        :small="true"
                        @input="enableHasChangesFlag"
                    />
                </div>
                <AppMultiselectV3
                    ref="conflictSelection"
                    v-model="conflictTargets"
                    class="mt-5"
                    :multiple="true"
                    :options="eligibleConflictTargetOffers"
                    :placeholder="$i18n.t('productCatalog.compatibilityRules.chooseConflictingOffers')"
                    :additionalLabel="$i18n.t('productCatalog.compatibilityRules.conflicts')"
                    :explanationText="$i18n.t('productCatalog.compatibilityRules.conflictExplanation')"
                    :tooltipOffset="-50"
                    label="name"
                    @input="enableHasChangesFlag"
                >
                    <template
                        slot="option"
                        slot-scope="props"
                    >
                        <div>
                            <div
                                class="font-weight-bold mb-2"
                                data-test-id="conflict-selection-option-name"
                            >
                                {{ props.option.originName }}
                            </div>
                            <div class="d-flex align-items-center mb-2">
                                {{ $i18n.t('generic.state') }}: <EntityStatusIndicator :status="props.option.state" />
                            </div>
                            <div class="mb-2">{{ $i18n.t('generic.id') }}: {{ props.option.id }}</div>
                            <div>{{ $i18n.t('generic.externalId') }}: {{ props.option.externalId }}</div>
                        </div>
                    </template>
                </AppMultiselectV3>
            </div>
        </div>
        <template #modalFooter>
            <AppButton
                ref="saveButton"
                :isMandatory="true"
                :label="$i18n.t('generic.save')"
                :disabled="disabledSaveButton"
                @click="onSave"
            />
        </template>
    </AppDialogV2>
</template>

<script>
import groupBy from 'lodash/groupBy';
import * as Sentry from '@sentry/vue';
import { mapGetters } from 'vuex';
import ENTITY_TYPES from '@/common/entities/entityTypes';

import { addCompatibilityRule, removeCompatibilityRule } from '@/__new__/services/dno/pc/http/offer';
import CompatibilityRuleModel, {
    RULE_TYPES,
    RULE_EVAL_OPERATION,
} from '@/__new__/services/dno/pc/models/CompatibilityRuleModel';
import { STATUS_CODES, STATUS_CODE_TO_STATUS_NAME_MAP } from '@/common/commonHelper';

import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import AppDialogV2 from '@/components/partials/AppDialogV2.vue';
import AppMultiselectV3 from '@/components/partials/inputs/AppMultiselectV3.vue';
import AppToggle from '@/components/partials/inputs/AppToggle.vue';
import EntityStatusIndicator from '@/components/partials/EntityStatusIndicator.vue';

import { ALERT_TYPES } from '@/common/alerts/Alert';
import Button from '@/common/button/Button';
import { Getters } from '@/store/mutation-types';

// Unlike CONFLICT rules, REQUIRE rules cannot be defined against offers in
// the UNAPPROVED state. This is to prevent a published offer from becoming
// unpurchaseable.
//
const VALID_PREREQ_TARGET_STATES = new Set([STATUS_CODES.APPROVED, STATUS_CODES.PAUSED]);

const VALID_CONFLICT_TARGET_STATES = new Set([STATUS_CODES.UNAPPROVED, STATUS_CODES.APPROVED, STATUS_CODES.PAUSED]);

export default {
    name: 'CompatibilityModal',
    components: {
        AppButton,
        AppDialogV2,
        AppMultiselectV3,
        AppToggle,
        EntityStatusIndicator,
    },
    props: {
        currentOffer: {
            type: Object,
            required: true,
        },
        offers: {
            type: Array,
            default: () => [],
            required: true,
        },
    },
    data() {
        return {
            prereqTargets: [],
            conflictTargets: [],
            requireAllPrereqs: true,
            isSaveClicked: false,
            hasChanges: false,
            BUTTON_TYPES,
        };
    },
    computed: {
        ...mapGetters('productcatalog', [Getters.PC_GET_ENTITY_BY_TYPE_AND_ID]),
        offersIncludingDeletedRefference() {
            if (this.offers.length) {
                const deletedOffers = [];
                if (this.currentOffer && this.currentOffer?.compatibilityRules.length) {
                    this.currentOffer.compatibilityRules.forEach(rule => {
                        if (this?.offers?.length && !this.offers.map(offer => offer.id).includes(rule.targetId)) {
                            const deletedOffer = this[Getters.PC_GET_ENTITY_BY_TYPE_AND_ID](
                                ENTITY_TYPES.OFFER,
                                rule.targetId,
                            );
                            deletedOffers.push({
                                ...deletedOffer,
                                isDeleted: true,
                            });
                        }
                    });
                }

                return [...deletedOffers, ...this.offers].map(offer => {
                    let { name } = offer;
                    if (offer.isdeleted) {
                        name += ` (${this.$i18n.t('generic.deleted')})`;
                    } else {
                        name += ` (${STATUS_CODE_TO_STATUS_NAME_MAP.get(offer.state)})`;
                    }
                    if (offer.externalId) {
                        name += ` (${offer.externalId})`;
                    }

                    return {
                        ...offer,
                        originName: offer.name,
                        name,
                    };
                });
            }

            return [];
        },
        eligiblePrereqTargetOffers() {
            return this.offersIncludingDeletedRefference
                .filter(
                    o =>
                        VALID_PREREQ_TARGET_STATES.has(o.state) &&
                        o.id !== this.currentOffer.id &&
                        !this.conflictTargets.find(target => target.id === o.id),
                )
                .sort((a, b) => a.name.localeCompare(b.name));
        },
        eligibleConflictTargetOffers() {
            return this.offersIncludingDeletedRefference
                .filter(
                    o =>
                        VALID_CONFLICT_TARGET_STATES.has(o.state) &&
                        o.id !== this.currentOffer.id &&
                        !this.prereqTargets.find(target => target.id === o.id),
                )
                .sort((a, b) => a.name.localeCompare(b.name));
        },
        finalRules() {
            const prereqRules = this.prereqTargets.map(
                target =>
                    new CompatibilityRuleModel(
                        target.id,
                        RULE_TYPES.REQUIRE,
                        this.requireAllPrereqs ? RULE_EVAL_OPERATION.AND : RULE_EVAL_OPERATION.OR,
                    ),
            );
            const conflictRules = this.conflictTargets.map(
                target => new CompatibilityRuleModel(target.id, RULE_TYPES.CONFLICT),
            );
            return [...prereqRules, ...conflictRules];
        },
        rulesToRemove() {
            return this.currentOffer.compatibilityRules.filter(r => !this.findCompatibilityRule(this.finalRules, r));
        },
        rulesToAdd() {
            return this.finalRules.filter(r => !this.findCompatibilityRule(this.currentOffer.compatibilityRules, r));
        },
        disabledSaveButton() {
            return !this.hasChanges || this.isSaveClicked;
        },
        externalIDToDisplay() {
            return this.currentOffer?.externalId || this.$i18n.t('generic.N/A');
        },
    },
    mounted() {
        const rulesByType = groupBy(this.currentOffer.compatibilityRules, r => r.ruleType);
        this.prereqTargets = rulesByType[RULE_TYPES.REQUIRE]?.map(r => this.findOffer(r.targetId)) || [];
        this.conflictTargets = rulesByType[RULE_TYPES.CONFLICT]?.map(r => this.findOffer(r.targetId)) || [];
        this.requireAllPrereqs =
            this.prereqTargets.length > 0
                ? this.prereqTargets[0]?.compatibilityRules[0]?.ruleEvalOperation === RULE_EVAL_OPERATION.AND
                : true;
    },
    methods: {
        addCompatibilityRule,
        removeCompatibilityRule,

        enableHasChangesFlag() {
            this.hasChanges = true;
        },
        findOffer(id) {
            return this.offersIncludingDeletedRefference.find(o => o.id === id);
        },
        findCompatibilityRule(rules, ruleToFind) {
            return rules.find(r => r.equals(ruleToFind));
        },
        async updateRules() {
            // Perform compatibility rule requests in sequence instead of in parallel (i.e., Promise.all). The
            // reason is that performing the requests in parallel results in a high chance of the server
            // responding with an error due to running into locking issues on the backend.
            //
            // To avoid the server responding with an error due to conflicting rules (i.e., the server will
            // reject a request that results in a target entity is part of both a REQUIRE and a CONFLICT rule),
            // removals of rules are performed before additions of new rules.
            //
            /* eslint-disable no-await-in-loop */
            try {
                this.isSaveClicked = true;
                for (const rule of this.rulesToRemove) {
                    await this.removeCompatibilityRule(this.currentOffer.id, rule);
                }

                for (const rule of this.rulesToAdd) {
                    await this.addCompatibilityRule(this.currentOffer.id, rule);
                }

                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('alertMessage.successSavedCompatibilityRules', {
                        name: this.currentOffer.name,
                    }),
                    type: ALERT_TYPES.success,
                });
            } catch (error) {
                this.$Progress.fail();
                Sentry.captureException(error);
                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('productCatalog.compatibilityRules.failedToSave'),
                });
            } finally {
                this.isSaveClicked = false;
            }
            /* eslint-enable no-await-in-loop */
        },
        onCancel() {
            if (this.hasChanges) {
                const yesButton = new Button({
                    label: this.$i18n.t('generic.yes'),
                    alertType: ALERT_TYPES.warning,
                });
                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('alerts.allRuleChangesWillBeLost'),
                    type: ALERT_TYPES.warning,
                    buttons: [yesButton],
                });
                this.$eventBus.$once('buttonClicked', () => {
                    this.$emit('cancel', false);
                });
            } else {
                this.$emit('cancel', false);
            }
        },
        async onSave() {
            await this.updateRules();
            this.$emit('close');
        },
    },
};
</script>

<style scoped lang="scss">
@import '~@/assets/scss/layout';

.compatibility-modal {
    .header {
        margin: $spacing-m 0;
    }
}
</style>
