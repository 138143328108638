import i18n from '@/i18n';
import { TranslateResult } from 'vue-i18n';

export enum SERVICE_ID_TYPES {
    STARTS_WITH = 'starts_with',
    ENDS_WITH = 'ends_with',
    IS = 'is',
}

export interface ServiceIdOptions {
    id: SERVICE_ID_TYPES;
    label: TranslateResult;
}

export type ServiceIdTypeOptions = {
    [T in SERVICE_ID_TYPES]: ServiceIdOptions;
};

export const MAP_SERVICE_ID_TYPE_TO_OPTION_VALUES: Readonly<ServiceIdTypeOptions> = {
    [SERVICE_ID_TYPES.STARTS_WITH]: {
        id: SERVICE_ID_TYPES.STARTS_WITH,
        label: i18n.t('charging.chargingSpecifications.editor.conditions.vlrID.startsWith'),
    },
    [SERVICE_ID_TYPES.ENDS_WITH]: {
        id: SERVICE_ID_TYPES.ENDS_WITH,
        label: i18n.t('charging.chargingSpecifications.editor.conditions.vlrID.endsWith'),
    },
    [SERVICE_ID_TYPES.IS]: {
        id: SERVICE_ID_TYPES.IS,
        label: i18n.t('charging.chargingSpecifications.editor.conditions.vlrID.is'),
    },
};

export const MAP_SERVICE_ID_OPTION_TO_LABEL = {
    [SERVICE_ID_TYPES.STARTS_WITH]: i18n.t('charging.chargingSpecifications.editor.conditions.vlrID.startsWith'),
    [SERVICE_ID_TYPES.ENDS_WITH]: i18n.t('charging.chargingSpecifications.editor.conditions.vlrID.endsWith'),
    [SERVICE_ID_TYPES.IS]: i18n.t('charging.chargingSpecifications.editor.conditions.vlrID.is'),
};

export type ServiceIdConditionValues = {
    serviceIdType: ServiceIdOptions | null;
    values: string[];
};

export type ServiceIdCondition = {
    inverted: boolean;
    src: ServiceIdConditionValues[];
};

export type ServiceIdConditionDataObject = {
    data: ServiceIdCondition;
    uuid: string;
    type: string;
    dataEmpty: boolean | undefined;
    dataValid: boolean;
    isDataValid(): void;
};

export const DEFAULT_DATA_OBJECT: ServiceIdCondition = {
    inverted: false,
    src: [
        {
            serviceIdType: null,
            values: [],
        },
    ],
};
