
import Vue from 'vue';

// Vuex
import { mapActions, mapGetters } from 'vuex';

// Components
import AbstractTableTile from '@/__new__/features/customerCareSuite/components/AbstractTableTile.vue';
import tableColumnType from '@/common/filterTable';

export default Vue.extend({
    name: 'CredentialChangeHistoryTile',
    components: {
        AbstractTableTile,
    },
    props: {
        tableKey: {
            type: String,
            default: '',
        },
    },
    data() {
        return {};
    },
    computed: {
        ...mapGetters('userManagementUser', {
            getCredsHistoryById: 'getCredsHistoryById',
            getCredsHistoryApiResponseById: 'getCredsHistoryApiResponseById',
        }),
        userId() {
            return this.$route.params.id || '';
        },
        credsHistory() {
            return this.userId ? this.getCredsHistoryById(this.userId) : [];
        },
        apiResponse() {
            return this.userId ? this.getCredsHistoryApiResponseById(this.userId) : null;
        },
        columnsData() {
            return [
                {
                    name: this.$i18n.t('customerCare.userInformation.credChangeHistory.changeTime'),
                    key: 'changeTimeLabel',
                    sortBy: entity => entity.changeTime,
                    field: 'changeTime',
                    filterType: tableColumnType.DATE,
                },
                {
                    name: this.$i18n.t('customerCare.userInformation.credChangeHistory.activity'),
                    key: 'activityLabel',
                    field: 'activityLabel',
                    filterType: tableColumnType.TEXT_LIMITED_OPTIONS,
                    limitedOptions: [...new Set(this.credsHistory.map(entity => entity.activityLabel))],
                },
                {
                    name: this.$i18n.t('customerCare.userInformation.credChangeHistory.methodOfLogin'),
                    key: 'loginMethodLabel',
                    field: 'loginMethodLabel',
                    filterType: tableColumnType.TEXT_LIMITED_OPTIONS,
                    limitedOptions: [...new Set(this.credsHistory.map(entity => entity.loginMethodLabel))],
                },
                {
                    name: this.$i18n.t('generic.status'),
                    key: 'statusLabel',
                    field: 'statusLabel',
                    filterType: tableColumnType.TEXT_LIMITED_OPTIONS,
                    limitedOptions: [...new Set(this.credsHistory.map(entity => entity.statusLabel))],
                },
            ];
        },
    },
    created() {
        this.fetchTileData();
    },
    methods: {
        ...mapActions('userManagementUser', ['getCredentialsChangeHistory']),
        fetchTileData() {
            this.$emit('isDataLoadingUpd', true);
            this.$withProgressBar(
                async () => {
                    await this.getCredentialsChangeHistory(this.userId);
                    this.$emit('isDataLoadingUpd', false);
                },
                {
                    errorHandler: () => {
                        this.$emit('dataFetchingError');
                        this.$emit('isDataLoadingUpd', false);
                    },
                },
            );
        },
    },
});
