import { render, staticRenderFns } from "./ChargingSpecifications.vue?vue&type=template&id=34b6e530&scoped=true"
import script from "./ChargingSpecifications.vue?vue&type=script&lang=js"
export * from "./ChargingSpecifications.vue?vue&type=script&lang=js"
import style0 from "./ChargingSpecifications.vue?vue&type=style&index=0&id=34b6e530&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "34b6e530",
  null
  
)

export default component.exports