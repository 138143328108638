// vuex
import { Getters } from '@/store/mutation-types';
import store, { Modules } from '@/store/store';

import type { Job } from '@/__new__/features/settings/common/applicationManagerHelper';
import type { DataflowTemplate } from '@/__new__/features/dataflows/common/DataflowInterfaces';

export type DataflowJobResponse = {
    app_name: string;
    id: Job['id'];
    blueprint_id?: string | null;
};

const EMPTY_BLUEPRINT_ID = 'N/A';

export default class DataflowJob {
    id: string;
    app: string;
    job: Job['id'];
    blueprintId: string;

    constructor(data: Omit<DataflowJob, 'id' | 'blueprint'>) {
        this.id = `${data.app}/${data.job}`;
        this.app = data.app;
        this.job = data.job;
        this.blueprintId = data.blueprintId || EMPTY_BLUEPRINT_ID;
    }

    get blueprint() {
        const blueprints = DataflowJob.getTemplates();
        return blueprints.find(({ id }) => id === this.blueprintId);
    }

    static getTemplates(): DataflowTemplate[] {
        return store.getters[`${Modules.dataflows}/${Getters.GET_DATAFLOW_TEMPLATES}`];
    }

    static mapFromResponse(item: DataflowJobResponse) {
        return new DataflowJob({
            job: item.id,
            app: item.app_name.startsWith('lf-') ? item.app_name.substring(3) : item.app_name,
            blueprintId: item.blueprint_id || EMPTY_BLUEPRINT_ID,
        });
    }
}
