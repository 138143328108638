<template>
    <div class="buttons-wrapper">
        <AppButton
            :buttonType="BUTTON_TYPES.TERTIARY"
            :label="cancelButtonLabel"
            class="mr-5"
            data-test-id="cancel-button"
            @click="emitEvent('cancel')"
        />
        <AppButton
            v-if="showSave"
            :buttonType="BUTTON_TYPES.PRIMARY"
            :label="saveButtonLabel || $i18n.t('generic.save')"
            :disabled="disableSave"
            class="mr-3"
            data-test-id="save-button"
            @click="emitEvent('save')"
        />
        <AppButton
            v-if="showSaveDraft"
            :buttonType="BUTTON_TYPES.PRIMARY"
            :label="$i18n.t('generic.saveDraft')"
            :disabled="disableSave"
            class="mr-3"
            data-test-id="save-draft-button"
            @click="emitEvent('saveDraft')"
        />
        <AppButton
            v-if="showPublish"
            :buttonType="BUTTON_TYPES.PRIMARY"
            :label="$i18n.t('generic.publish')"
            :disabled="disableSave"
            class="mr-3"
            data-test-id="publish-button"
            @click="emitEvent('publish')"
        />
    </div>
</template>

<script>
// Vue components
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';

export default {
    name: 'EditorButtons',

    components: {
        AppButton,
    },

    props: {
        showSave: {
            type: Boolean,
            default: false,
        },
        showSaveDraft: {
            type: Boolean,
            default: false,
        },
        showPublish: {
            type: Boolean,
            default: false,
        },
        disableSave: {
            type: Boolean,
            default: false,
        },
        saveButtonLabel: {
            type: String,
            default: () => undefined,
        },
    },
    data() {
        return {
            BUTTON_TYPES,
        };
    },
    computed: {
        cancelButtonLabel() {
            if (!this.showSaveDraft && !this.showPublish && !this.showSave) {
                return this.$i18n.t('generic.close');
            }
            return this.$i18n.t('generic.cancel');
        },
    },
    methods: {
        emitEvent(val) {
            this.$emit(val);
        },
    },
};
</script>

<style lang="scss" scoped>
.buttons-wrapper {
    display: flex;
    justify-content: flex-end;
    overflow: visible;
}
</style>
