<template>
    <div class="appointments-section-container">
        <!-- modals -->
        <AppDialog
            :visible="isModalVisible"
            maxWidth="47.5rem"
            maxHeight="100%"
            :overflowAuto="false"
        >
            <div class="appointment-modal-form">
                <h2 class="lf-title appointment-modal-header">
                    {{ getModalHeader }}
                </h2>

                <div
                    v-if="modalMode === APPT_ACTION.EDIT"
                    class="appointments-change-appointment-section mt-5"
                >
                    <h2 class="lf-labels">
                        {{ $i18n.t('appointments.currentAppointmentTimeSlot') }}
                    </h2>

                    <div class="lf-all-caps date-and-time-tooltip">
                        {{ dateAndTimeLabel }}
                    </div>

                    <div class="d-flex">
                        <p class="lf-labels">
                            {{ $i18n.t('appointments.changeAppointmentTime') }}
                        </p>
                        <AppToggle
                            v-model="changeAppointmentTime"
                            :small="true"
                            :disabled="isFetchingTimeSlots"
                            class="toggle-btn ml-3"
                            @input="enabled => enabled && fetchTimeSlots()"
                        />
                    </div>
                </div>

                <div v-if="shouldShowDateAndTimeSlotPicker">
                    <div v-if="modalMode === APPT_ACTION.CREATE">
                        <p class="lf-labels appointment-label">
                            {{ $i18n.t('customerCare.appointmentsSection.appointmentType') }}
                        </p>
                        <AppMultiselectV3
                            v-model="appointmentsModalData.type"
                            :small="true"
                            :options="createOptions"
                            :placeholder="$i18n.t('customerCare.appointmentsSection.chooseAppt')"
                            :disabled="isFetchingTimeSlots"
                            label="label"
                            class="appointment-dropdown"
                            data-test-id="activity-type"
                            @input="fetchTimeSlots"
                        />
                    </div>
                    <p
                        v-if="appointmentsModalData.type"
                        class="lf-labels appointment-label"
                    >
                        {{ $i18n.t('customerCare.appointmentsSection.appointmentDate') }}
                    </p>
                    <DateTimePicker
                        v-if="appointmentsModalData.type"
                        v-model="appointmentsModalData.date"
                        :placeholder="$i18n.t('customerCare.appointmentsSection.chooseDate')"
                        :disabled="isFetchingTimeSlots"
                        :disabledDates="checkDisabledDate"
                        :popupStyle="{ zIndex: 10000 }"
                        :timezoneNormalizing="false"
                        class="appointment-date-picker"
                        type="date"
                        @input="filterAvailableTimeSlots"
                    />

                    <AppMultiselectV3
                        v-if="appointmentsModalData.date"
                        v-model="appointmentsModalData.timeSlot"
                        :small="true"
                        :options="appointmentsTimeSlots"
                        :additionalLabel="$i18n.t('customerCare.appointmentsSection.appointmentTime')"
                        :placeholder="$i18n.t('customerCare.appointmentsSection.chooseTime')"
                        :disabled="isFetchingTimeSlots"
                        label="label"
                        trackBy="timeFrom"
                        class="appointment-dropdown"
                    />
                </div>

                <template v-if="appointmentsModalData.date">
                    <AppTextareaV3
                        v-model="appointmentsModalData.memoAgent"
                        :optional="true"
                        :placeholder="$i18n.t('customerCare.appointmentsSection.addAgentNotes')"
                        :label="$i18n.t('customerCare.appointmentsSection.agentNotes')"
                        class="appointment-note-textarea"
                    >
                        <template #additionalLabel>
                            <span class="additional-label text--xs ml-2">
                                {{ $i18n.t('customerCare.appointmentsSection.notVisibleToUsers') }}
                            </span>
                        </template>
                    </AppTextareaV3>

                    <template v-if="modalMode === APPT_ACTION.EDIT">
                        <div class="d-flex appointment-notify-toggle">
                            <p class="lf-labels">
                                {{ $i18n.t('customerCare.appointmentsSection.notifyCustomer') }}
                            </p>
                            <AppToggle
                                v-model="appointmentsModalData.isNotificationEnabled"
                                :small="true"
                                class="toggle-btn ml-3"
                            />
                        </div>
                    </template>
                </template>

                <div class="modal-footer d-flex justify-content-end">
                    <AppButton
                        :label="$i18n.t('generic.cancel')"
                        class="mr-3"
                        @click="closeModal"
                    />
                    <AppButton
                        :label="$i18n.t('generic.save')"
                        :buttonType="BUTTON_TYPES.PRIMARY"
                        :isLoading="isFetchingTimeSlots"
                        data-test-id="submit-btn"
                        @click="onSave"
                    />
                </div>
            </div>
        </AppDialog>

        <!-- actual section content -->
        <div class="appointments-section-header">
            <h2 class="appointments-section-title lf-subtitle">
                {{ $i18n.t('appointments.appointments') }}
            </h2>

            <AppButton
                :iconType="ICON_TYPES.PLUS"
                :label="$i18n.t('appointments.addAppointment')"
                :disabled="!allowCreateAppointment"
                data-test-id="add-appointment"
                @click="openCreateModal"
            />
        </div>

        <div
            v-for="item in appointmentsData"
            :key="item.type"
            :data-test-id="`appointments-${item.type}`.toLowerCase()"
        >
            <h3 class="appointments-section-title lf-subtitle">
                {{ item.title }}
            </h3>

            <h4
                v-if="item.noItemsLabel"
                class="no-active-appointments-placeholder lf-all-caps"
            >
                {{ item.noItemsLabel }}
            </h4>

            <div class="appointment-section-content">
                <div class="cards-container">
                    <AppointmentCard
                        v-for="appointment in item.appointments"
                        :key="appointment.appointmentId"
                        :appointment="appointment"
                        :allowedActions="getApptActionsPerType(item.type)"
                        class="mr-4"
                        @deleteAppointment="onDeleteAppointment"
                        @editAppointment="openEditModal"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// Vue components
import AppointmentCard from '@/components/partials/cards/AppointmentCard.vue';
import AppDialog from '@/components/partials/AppDialog.vue';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import { ICON_TYPES } from '@/common/iconHelper';
import DateTimePicker from '@/components/partials/inputs/DateTimePicker.vue';
import AppMultiselectV3 from '@/components/partials/inputs/AppMultiselectV3.vue';
import AppTextareaV3 from '@/components/partials/inputs/AppTextareaV3.vue';
import AppToggle from '@/components/partials/inputs/AppToggle.vue';

// HTTP
import {
    bookAppointment,
    deleteAppointment,
    updateAppointment,
    getAvailableTimeslots,
    reserveAppointment,
} from '@/http/appointments';
import addressbookHTTP from '@/http/addressbook';
import ordersHTTP from '@/__new__/services/dno/orders/http/orders';
import providerHTTP from '@/http/provider';

// Misc
import {
    APPT_ACTION,
    APPT_STATE,
    APPT_TYPE,
    getAppointmentActivityLabel,
} from '@/__new__/features/customerCare/common/appointmentsHelper';
import { SUBSCRIBER_STATE } from '@/__new__/features/customerCare/common/customerCareHelper';
import { ORDER_ENTITY_STATES } from '@/__new__/features/customerCare/common/orderStateHelper';
import * as Sentry from '@sentry/vue';
import Button from '@/common/button/Button';
import { ALERT_TYPES } from '@/common/alerts/Alert';
import Account from '@/__new__/services/dno/user/models/Account';
import UserAccount from '@/models/Account';
import { mapActions, mapGetters } from 'vuex';
import { isAddressEligible, getAddressbookParams, getOperatorEligibilityIds } from '@/common/addressbookHelper';
import permissionsService, { getOperatorConfigValue, isUserAllowed } from '@/services/permissions/permissions.service';
import LocalStorageHelper from '@/common/LocalStorageHelper';
import { TARGET_TYPE } from '@/__new__/services/dno/user/models/targetTuple';

export default {
    name: 'AppointmentsSection',
    components: {
        AppointmentCard,
        AppDialog,
        AppButton,
        AppMultiselectV3,
        AppTextareaV3,
        AppToggle,
        DateTimePicker,
    },
    props: {
        targetId: {
            required: true,
            type: String,
        },
        appointmentsInfo: {
            type: Object,
            default: null,
        },
        orders: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {
            account: null,
            installationAppointments: null,
            maintenanceAppointments: null,
            damageAppointments: null,
            modalMode: null,
            isModalVisible: false,
            changeAppointmentTime: false,
            edittedAppointment: false,
            appointmentsModalData: {
                date: null,
                timeSlot: '',
                type: '',
                memoAgent: '',
                isNotificationEnabled: true,
            },
            isFetchingTimeSlots: false,
            appointmentsTimeSlots: [],
            allAvailableTimeSlots: [],
            hasEligibleAddress: false,
            providerInfo: null,

            ICON_TYPES,
            BUTTON_TYPES,

            alertButtons: {
                confirmEditButton: new Button({
                    label: this.$i18n.t('generic.confirm'),
                    alertType: ALERT_TYPES.warning,
                }),
                confirmDeleteButton: new Button({
                    label: this.$i18n.t('generic.confirm'),
                    alertType: ALERT_TYPES.warning,
                }),
            },

            APPT_ACTION,
        };
    },
    computed: {
        ...mapGetters('userManagementAccount', ['getAccountInfoById']),
        accountInfo() {
            return this.targetId ? this.getAccountInfoById(this.targetId) : {};
        },
        activityType() {
            return this.appointmentsModalData.type?.value || this.appointmentsModalData.type;
        },
        allowCreateAppointment() {
            return isUserAllowed('UMAccountAppointmentWrite') && this.createOptions.length;
        },
        allowAllInstallActions() {
            return (
                permissionsService.accountAppointmentCRUDInstallAlways() &&
                isUserAllowed('UMAccountAppointmentCRUDInstallAlways')
            );
        },
        allowAllMaintenanceActions() {
            return (
                permissionsService.accountAppointmentCRUDMaintenanceAlways() &&
                isUserAllowed('UMAccountAppointmentCRUDMaintenanceAlways')
            );
        },
        dateAndTimeLabel() {
            const localizedDateLabel = this.formatDate(this.edittedAppointment.timeSlot.date);
            const localizedStartTime = this.$localeLibrary.getFormattedTime(this.edittedAppointment.timeSlot.timeFrom);
            const localizedEndTime = this.$localeLibrary.getFormattedTime(this.edittedAppointment.timeSlot.timeTo);

            return `${localizedDateLabel}, ${localizedStartTime} - ${localizedEndTime}`;
        },
        isInstallationAppointmentsEmpty() {
            return Array.isArray(this.installationAppointments) && !this.installationAppointments.length;
        },
        isMaintenanceAppointmentsEmpty() {
            return Array.isArray(this.maintenanceAppointments) && !this.maintenanceAppointments.length;
        },
        isDamageAppointmentsEmpty() {
            return Array.isArray(this.damageAppointments) && !this.damageAppointments.length;
        },
        supportedAppointmentTypes() {
            return this.providerInfo?.integration_params?.appointments?.supported_appointment_types || [];
        },
        isDamageAppointmentEnabled() {
            return this.supportedAppointmentTypes.includes(APPT_TYPE.DAMAGE);
        },
        isMaintenanceAppointmentAllowed() {
            return this.allowAllMaintenanceActions || this.account?.state === SUBSCRIBER_STATE.ACTIVE;
        },
        isPreorderAppointmentEnabled() {
            return Boolean(getOperatorEligibilityIds());
        },
        isPreorderAppointment() {
            if (!this.isPreorderAppointmentEnabled) {
                return false;
            }

            return (
                this.account?.state === SUBSCRIBER_STATE.PENDING &&
                this.hasNoAppointments &&
                this.hasPreorder &&
                this.hasEligibleAddress
            );
        },
        isViasatDemo() {
            return getOperatorConfigValue('isViasatDemo');
        },
        hasNoAppointments() {
            return (
                this.isInstallationAppointmentsEmpty &&
                this.isMaintenanceAppointmentsEmpty &&
                this.isDamageAppointmentsEmpty
            );
        },
        getModalHeader() {
            if (this.modalMode === APPT_ACTION.EDIT) {
                return this.$i18n.t(
                    getAppointmentActivityLabel(this.edittedAppointment.activity_type, APPT_ACTION.EDIT),
                );
            }

            return this.$i18n.t('customerCare.appointmentsSection.scheduleAppointment');
        },
        shouldShowDateAndTimeSlotPicker() {
            if (this.modalMode === APPT_ACTION.EDIT) {
                return this.changeAppointmentTime;
            }
            return true;
        },
        appointmentsData() {
            const data = [
                {
                    type: APPT_TYPE.INSTALLATION,
                    title: this.$i18n.t('appointments.installations'),
                    noItemsLabel: this.isInstallationAppointmentsEmpty
                        ? this.$i18n.t('appointments.noInstallationAppointments')
                        : '',
                    appointments: this.installationAppointments,
                },
                {
                    type: APPT_TYPE.MAINTAINANCE,
                    title: this.$i18n.t('appointments.maintenance'),
                    noItemsLabel: this.isMaintenanceAppointmentsEmpty
                        ? this.$i18n.t('appointments.noActiveMaintenanceAppointments')
                        : '',
                    appointments: this.maintenanceAppointments,
                },
            ];

            if (this.isDamageAppointmentEnabled) {
                data.push({
                    type: APPT_TYPE.DAMAGE,
                    title: this.$i18n.t('appointments.damages'),
                    noItemsLabel: this.isDamageAppointmentsEmpty
                        ? this.$i18n.t('appointments.noActiveDamageAppointments')
                        : '',
                    appointments: this.damageAppointments,
                });
            }

            return data;
        },
        createOptions() {
            return this.appointmentsData
                .filter(i => this.getApptActionsPerType(i.type).includes(APPT_ACTION.CREATE))
                .map(i => ({
                    value: i.type,
                    label: this.$i18n.t(getAppointmentActivityLabel(i.type, APPT_ACTION.CREATE)),
                }));
        },
        // orders
        ordersData() {
            return Object.values(this.orders).sort((a, b) => b.createTimestamp - a.createTimestamp);
        },
        currentOrder() {
            return this.ordersData[0];
        },
        hasPreorder() {
            return this.currentOrder && this.currentOrder.isPreorder;
        },
        hasCanceledOrder() {
            return this.currentOrder && this.currentOrder.state === ORDER_ENTITY_STATES.CANCELED;
        },
    },
    watch: {
        appointmentsInfo: {
            handler() {
                this.fetchAppointments();
            },
            immediate: true,
        },
    },
    async created() {
        try {
            await this.getAccountInfo(this.targetId);
            this.account = new Account(Account.remapAccountFromBe(this.accountInfo));
            await Promise.all([this.getProvider(), this.fetchAddressEligibility()]);
        } catch (e) {
            Sentry.captureException(e);
            this.$Progress.fail();
            this.$alert(this.$i18n.t('alertMessage.somethingWentWrongFetchingNecessaryData'));
        }
    },
    methods: {
        ...mapActions('userManagementAccount', ['getAccountInfo']),
        closeModal() {
            this.isModalVisible = false;
            this.clearModalData();
        },
        clearModalData() {
            this.appointmentsModalData = {
                date: null,
                timeSlot: '',
                memoAgent: undefined,
                type: '',
                isNotificationEnabled: true,
            };
            this.allAvailableTimeSlots = [];
        },
        openCreateModal() {
            this.clearModalData();
            this.modalMode = APPT_ACTION.CREATE;
            this.isModalVisible = true;
        },
        openEditModal(appointment) {
            this.edittedAppointment = appointment;
            this.modalMode = APPT_ACTION.EDIT;
            this.changeAppointmentTime = false;
            this.appointmentsModalData = {
                date: appointment.timeSlot.date,
                memoAgent: appointment.memoAgent,
                idOfEditedAppointment: appointment.appointmentId,
                timeSlot: null,
                type: appointment.activity_type,
            };
            this.filterAvailableTimeSlots(appointment);

            // this.$set(this.appointmentsModalData, 'timeSlot', this.selectTimeSlotOnEdit(appointment));
            this.isModalVisible = true;
        },
        async fetchTimeSlots() {
            try {
                this.appointmentsTimeSlots = [];
                this.allAvailableTimeSlots = [];
                this.isFetchingTimeSlots = true;
                this.appointmentsModalData.date = '';

                if (this.activityType) {
                    this.$Progress.start();
                    const res = await getAvailableTimeslots({
                        targetId: this.targetId,
                        targetType: TARGET_TYPE.ACCOUNT,
                        type: this.activityType,
                    });
                    this.allAvailableTimeSlots = res?.data?.time_slots.map(el => ({
                        date: new Date(el.start_time),
                        timeFrom: el.start_time,
                        timeTo: el.end_time,
                        id: el.time_slot_id,
                    }));

                    if (!this.allAvailableTimeSlots?.length) {
                        this.$showInfoAlert({
                            message: this.$i18n.t('appointments.alerts.noAppointmentTimeSlots'),
                        });
                    }

                    this.$Progress.finish();
                }
            } catch (error) {
                this.$Progress.fail();
                Sentry.captureException(error);
                if (!error.isInterceptorAlertShown) {
                    this.$alert(this.$i18n.t('appointments.alerts.failedLoadingAppointmentTimeSlots'));
                }
                this.clearModalData();
            } finally {
                this.isFetchingTimeSlots = false;
            }
        },
        fetchAppointments() {
            this.$Progress.start();
            const appointmentsResponse = this.appointmentsInfo?.data || [];
            if (Array.isArray(appointmentsResponse.appointments)) {
                const appts = appointmentsResponse.appointments.map(el => ({
                    appointmentId: el.appointment_id,
                    timeSlot: {
                        date: new Date(el.time_slot.start_time),
                        timeFrom: el.time_slot.start_time,
                        timeTo: el.time_slot.end_time,
                        id: el.time_slot.time_slot_id,
                    },
                    memoAgent: el.memo_agent,
                    activity_type: el.activity_type,
                    state: el.state,
                }));

                this.installationAppointments = appts.filter(
                    el => el.activity_type === APPT_TYPE.INSTALLATION || !el.activity_type,
                );
                this.maintenanceAppointments = appts.filter(el => el.activity_type === APPT_TYPE.MAINTAINANCE);
                this.damageAppointments = appts.filter(el => el.activity_type === APPT_TYPE.DAMAGE);
            } else {
                this.installationAppointments = [];
                this.maintenanceAppointments = [];
                this.damageAppointments = [];
            }
            this.$Progress.finish();
        },
        formatDate(date) {
            let month = `${date.getMonth() + 1}`;
            let day = `${date.getDate()}`;
            const year = date.getFullYear();

            if (month.length < 2) {
                month = `0${month}`;
            }
            if (day.length < 2) {
                day = `0${day}`;
            }

            return [year, month, day].join('-');
        },
        filterAvailableTimeSlots() {
            this.$set(this.appointmentsModalData, 'timeSlot', '');
            const selectedDate = this.formatDate(this.appointmentsModalData.date);
            /**
             *  @param {string} time - e.g. '2023-04-26T15:00:00'
             *  @returns {string} e.g. '3:00 PM'
             */
            const formatTimeLabel = time => {
                const hour = time.substring(11, 13) > 12 ? time.substring(11, 13) - 12 : time.substring(11, 13);
                const min = time.substring(14, 16);
                const meridiem = time.substring(11, 13) >= 12 ? 'PM' : 'AM';

                return `${hour}:${min} ${meridiem}`;
            };

            this.appointmentsTimeSlots = this.allAvailableTimeSlots.reduce((arr, ts) => {
                if (ts.timeFrom.substring(0, 10) === selectedDate) {
                    const { timeFrom, timeTo, id } = ts;

                    arr.push({
                        label: `${formatTimeLabel(timeFrom)} - ${formatTimeLabel(timeTo)}`,
                        timeFrom,
                        timeTo,
                        id,
                    });
                }
                return arr;
            }, []);
        },
        getApptActionsPerType(type) {
            let actions = [];
            const isNotAllowingMultiple = ({ state }) => ![APPT_STATE.CANCELED, APPT_STATE.COMPLETED].includes(state);

            switch (type) {
                case APPT_TYPE.DAMAGE:
                    actions = [APPT_ACTION.EDIT, APPT_ACTION.DELETE];

                    if (!this.damageAppointments.some(isNotAllowingMultiple)) {
                        actions.push(APPT_ACTION.CREATE);
                    }
                    break;

                case APPT_TYPE.MAINTAINANCE:
                    if (this.isMaintenanceAppointmentAllowed) {
                        actions = [APPT_ACTION.EDIT, APPT_ACTION.DELETE];

                        if (!this.maintenanceAppointments.some(isNotAllowingMultiple)) {
                            actions.push(APPT_ACTION.CREATE);
                        }
                    }
                    break;

                case APPT_TYPE.INSTALLATION:
                default:
                    if (this.allowAllInstallActions && !this.installationAppointments.some(isNotAllowingMultiple)) {
                        actions.push(APPT_ACTION.CREATE);
                    }

                    if (
                        this.allowAllInstallActions ||
                        (this.currentOrder &&
                            !this.hasCanceledOrder &&
                            (!this.hasPreorder || (this.hasPreorder && this.hasEligibleAddress)))
                    ) {
                        actions.push(APPT_ACTION.EDIT);
                    }

                    if (this.allowAllInstallActions || this.hasCanceledOrder) {
                        actions.push(APPT_ACTION.DELETE);
                    }
            }

            return actions;
        },
        selectTimeSlotOnEdit(appointment) {
            const timeSlot = this.appointmentsTimeSlots.find(
                ts => ts.timeFrom === appointment.timeSlot.timeFrom && ts.timeTo === appointment.timeSlot.timeTo,
            );

            return timeSlot;
        },
        async onSave() {
            if (this.modalMode === APPT_ACTION.EDIT) {
                this.onUpdateAppointment();
            } else {
                this.$Progress.start();
                this.isFetchingTimeSlots = true;
                // Refetch eligibility as Account could have changed address.
                await this.fetchAddressEligibility();
                await this.createAppointmentCall();
                this.isFetchingTimeSlots = false;
                this.$Progress.finish();
            }
        },
        onUpdateAppointment() {
            this.$alert(this.$i18n.t('appointments.alerts.editAppointmentConfirm'), {
                type: ALERT_TYPES.warning,
                buttons: [this.alertButtons.confirmEditButton],
            });
            this.$eventBus.$once('buttonClicked', buttonId => {
                if (buttonId === this.alertButtons.confirmEditButton.id) {
                    this.updateAppointmentsCall();
                }
            });
        },
        onDeleteAppointment(appointmentId) {
            this.$alert(this.$i18n.t('appointments.alerts.areYouSureDelete'), {
                type: ALERT_TYPES.warning,
                buttons: [this.alertButtons.confirmDeleteButton],
            });
            this.$eventBus.$once('buttonClicked', buttonId => {
                if (buttonId === this.alertButtons.confirmDeleteButton.id) {
                    this.deleteAppointmentCall(appointmentId);
                }
            });
        },
        async createAppointmentCall() {
            try {
                this.$Progress.start();
                const res = await reserveAppointment({
                    targetId: this.targetId,
                    targetType: TARGET_TYPE.ACCOUNT,
                    timeFrom: this.appointmentsModalData.timeSlot.timeFrom,
                    timeTo: this.appointmentsModalData.timeSlot.timeTo,
                    timeSlotId: this.appointmentsModalData.timeSlot.id,
                    activityType: this.activityType,
                    createdBy: UserAccount.storedInstance.name,
                });

                const reservationId = res.data.reservation_id;

                if (this.isPreorderAppointment) {
                    await ordersHTTP.completePreorder({
                        targetId: this.targetId,
                        targetType: TARGET_TYPE.ACCOUNT,
                        reservationId,
                    });
                } else {
                    await bookAppointment({
                        targetId: this.targetId,
                        targetType: TARGET_TYPE.ACCOUNT,
                        reservationId,
                        note: this.appointmentsModalData.memoAgent,
                        activityType: this.activityType,
                    });
                }

                if (this.isViasatDemo && this.activityType === APPT_TYPE.MAINTAINANCE) {
                    LocalStorageHelper.set(`viasatDemo-showTroubleAppointment-${this.targetId}`, 'true');
                }

                this.isModalVisible = false;

                this.$showSuccessAlert({
                    message: this.$i18n.t('appointments.alerts.successfullyCreated'),
                });

                // refresh appointments
                this.$emit('fetchAppointments');

                this.$Progress.finish();
            } catch (e) {
                Sentry.captureException(e);
                this.$Progress.fail();
                this.$alert(this.$i18n.t('appointments.errors.unableToCreate'));
            }
        },
        async updateAppointmentsCall() {
            try {
                this.$Progress.start();
                this.isFetchingTimeSlots = true;

                const { targetId } = this;
                const { timeSlot, memoAgent, isNotificationEnabled, idOfEditedAppointment, type } =
                    this.appointmentsModalData;

                let ts;
                if (this.changeAppointmentTime) {
                    ts = {
                        start_time: timeSlot.timeFrom,
                        end_time: timeSlot.timeTo,
                        ...(timeSlot.id && { time_slot_id: timeSlot.id }),
                    };
                } else {
                    ts = null;
                }

                await updateAppointment({
                    targetId,
                    targetType: TARGET_TYPE.ACCOUNT,
                    idOfEditedAppointment,
                    timeSlot: ts,
                    note: memoAgent,
                    isNotificationEnabled,
                    activityType: type,
                    updatedBy: UserAccount.storedInstance.name,
                });

                this.isModalVisible = false;

                this.$showSuccessAlert({
                    message: this.$i18n.t('appointments.alerts.successfullyUpdated'),
                });

                // refresh appointments
                this.$emit('fetchAppointments');

                this.$Progress.finish();
            } catch (e) {
                Sentry.captureException(e);
                this.$Progress.fail();
                this.$alert(this.$i18n.t('appointments.errors.unableToUpdate'));
            } finally {
                this.isFetchingTimeSlots = false;
            }
        },
        async deleteAppointmentCall(appointmentId) {
            try {
                this.$Progress.start();

                await deleteAppointment({
                    targetId: this.targetId,
                    targetType: TARGET_TYPE.ACCOUNT,
                    appointmentId,
                    updatedBy: UserAccount.storedInstance.name,
                    reasonCode: 'SELF_SERVICE',
                });

                this.isModalVisible = false;

                this.$showSuccessAlert({
                    message: this.$i18n.t('appointments.alerts.successfullyDeleted'),
                });

                if (this.isViasatDemo) {
                    LocalStorageHelper.remove(`viasatDemo-showTroubleAppointment-${this.targetId}`);
                }

                // refresh appointments
                this.$emit('fetchAppointments');
                this.$Progress.finish();
            } catch (e) {
                this.$Progress.fail();
                Sentry.captureException(e);
                this.$alert(this.$i18n.t('appointments.errors.unableToDelete'));
            }
        },
        async fetchAddressEligibility() {
            if (!this.isPreorderAppointmentEnabled) {
                return;
            }
            try {
                const reqs = Object.values(this.account.addresses).map(address =>
                    addressbookHTTP.checkEligibility(getAddressbookParams(address)),
                );
                const results = await Promise.all(reqs);

                this.hasEligibleAddress = results
                    .map(({ data }, i) => isAddressEligible(data, this.account.addresses[i].unitNumber))
                    .some(Boolean);
            } catch (e) {
                this.$Progress.fail();
                Sentry.captureException(e);
                this.$showErrorAlert({
                    message: this.$i18n.t('alertMessage.userManagement.somethingWentWrongFetchingAddressEligibility'),
                });
            }
        },
        checkDisabledDate(date) {
            for (const ts of this.allAvailableTimeSlots) {
                if (ts.date.toDateString() === date.toDateString()) {
                    return false;
                }
            }
            return true;
        },
        async getProvider() {
            if (!this.account?.providerId) {
                return;
            }
            await this.$withProgressBar(
                async () => {
                    const res = await providerHTTP.getProviders([this.account.providerId]);
                    const providers = res?.data?.providers;

                    if (Array.isArray(providers)) {
                        this.providerInfo = providers.find(p => p.provider_id === this.account.providerId);
                    }
                },
                {
                    errorHandler: () =>
                        this.$showErrorAlert({
                            message: this.$i18n.t('alertMessage.userManagement.somethingWentWrongGettingProvider'),
                        }),
                },
            );
        },
    },
};
</script>

<style scoped lang="scss">
@import '~@/assets/scss/palette';
@import '~@/assets/scss/typographyV2';
@import '~@/assets/scss/layout';

.cards-container {
    display: flex;
    justify-content: flex-start;
}

.no-active-appointments-placeholder {
    width: 17.1875rem;
    height: 5rem;
    margin-bottom: 0;
    border-radius: 0.5rem;
    box-shadow: 0 0.125rem 0.375rem 0 rgb(51 81 149 / 30%);
    background-color: $white;

    display: flex;
    justify-content: center;
    align-items: center;

    color: $gray-blue;
}

.appointment-section-content {
    padding-bottom: $spacing-xxxl;
}

.appointments-section-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
    padding-right: 1rem;
}

.appointment-modal-form {
    .appointment-modal-header {
        margin: $spacing-xxl $spacing-xxxl 0;
    }

    .appointments-change-appointment-section {
        margin-left: $spacing-xxxl;
    }
    .appointment-label {
        margin: $spacing-s $spacing-xxxl $spacing-xxs;
    }

    .appointment-date-picker {
        width: 18rem;
        margin-left: $spacing-xxxl;
    }

    .appointment-dropdown {
        width: 18rem;
        margin: $spacing-s $spacing-xxxl;
    }

    .appointment-note-textarea {
        width: 36rem;
        margin-left: $spacing-xxxl;

        .additional-label {
            font-style: italic;
            color: $gray60;
        }
    }

    .appointment-notify-toggle {
        margin: $spacing-s $spacing-xxxl 0;
    }

    .modal-footer {
        margin-top: 4rem;
        padding: $spacing-l $spacing-xxl;
        border-top: solid 0.0625rem rgba($color: $blue, $alpha: 0.15);
    }
}
</style>
