import { AxiosPromise } from 'axios';
import http, { DnoResponseBase } from '@/http/index';
import {
    getDnoClaimsHeader,
    CHALLENGE_TYPE,
    CONTEXT_TYPE,
    TRANSACTION_TYPE,
} from '@/__new__/features/customerCare/common/customerCareHelper';
import {
    MembersNotificationIdentifiersApiResponse,
    CreateChallengeApiResponse,
} from '@/__new__/features/customerCareSuite/common/endUserAuthorizationHelper';
import { TARGET_TYPE } from '@/__new__/services/dno/user/models/targetTuple';

/**
 * Get all msisdn, email for target id.
 * @returns {AxiosPromise}
 */
export function getMembersNotificationIdentifiers(
    targetId: string,
    targetType: TARGET_TYPE,
    adminsOnly = false,
    notificationTypes = ['msisdn', 'email'],
): AxiosPromise<MembersNotificationIdentifiersApiResponse> {
    return http({
        method: 'POST',
        url: '/v3userauth/getmembersnotificationidentifiers',
        data: {
            target_id: targetId,
            target_type: targetType,
            admins_only: adminsOnly,
            notification_types: notificationTypes,
        },
    });
}

/**
 * Verify account PIN.
 * @returns {AxiosPromise}
 */
export function verifyAccountPin(
    targetId: string,
    targetType: TARGET_TYPE,
    pin: string,
): AxiosPromise<DnoResponseBase> {
    return http({
        method: 'POST',
        url: '/v3userauth/verifypin',
        data: {
            target_id: targetId,
            target_type: targetType,
            pin,
        },
    });
}

type CreateChallengeData = {
    email?: string;
    msisdn?: string;
};

/**
 * Send OTP code to user
 * @returns {AxiosPromise}
 */
export function createChallenge(
    targetId: string,
    targetType: TARGET_TYPE,
    challengeType: CHALLENGE_TYPE,
    sendTo: CreateChallengeData,
    transactionType: TRANSACTION_TYPE = TRANSACTION_TYPE.FULL_INFO,
    contextType: CONTEXT_TYPE = CONTEXT_TYPE.END_USER_AUTHORIZATION,
): AxiosPromise<CreateChallengeApiResponse> {
    return http({
        method: 'POST',
        url: '/v3usermanagerwrite/createchallenge',
        headers: {
            'Dno-Claims': JSON.stringify(getDnoClaimsHeader(targetId, targetType)),
        },
        data: {
            challenge_type: challengeType,
            context_type: contextType,
            email: sendTo.email || undefined,
            msisdn: sendTo.msisdn || undefined,
            transaction_type: transactionType,
        },
    });
}

/**
 * Verify OTP challenge for user authorization
 * @returns {AxiosPromise}
 */
export function verifyChallengeEndUserAuthorization(
    targetId: string,
    targetType: TARGET_TYPE,
    challengeType: CHALLENGE_TYPE,
    challengeId: string,
    otpCode: string,
    contextType: CONTEXT_TYPE = CONTEXT_TYPE.END_USER_AUTHORIZATION,
): AxiosPromise<DnoResponseBase> {
    return http({
        method: 'POST',
        url: '/v3usermanagerwrite/verifychallengeenduserauthorization',
        headers: {
            'Dno-Claims': JSON.stringify(getDnoClaimsHeader(targetId, targetType)),
        },
        data: {
            challenge_id: challengeId,
            challenge_type: challengeType,
            context_type: contextType,
            otp_code: otpCode,
        },
    });
}

export default {
    getMembersNotificationIdentifiers,
    verifyAccountPin,
    createChallenge,
    verifyChallengeEndUserAuthorization,
};
