<template>
    <svg
        height="100"
        width="100"
    >
        <circle
            cx="50"
            cy="50"
            r="40"
            stroke="black"
            stroke-width="3"
            fill="red"
        />
        Just a mock svg used for unit test snapshots.
    </svg>
</template>

<script>
export default {};
</script>
