
import Vue, { PropType } from 'vue';
import { mapGetters } from 'vuex';
import { Modules } from '@/store/store';
import { Getters } from '@/store/mutation-types';

// Layout
import EntityOverview from '@/components/partials/entityOverview/EntityOverview.vue';
import OverviewList from '@/components/partials/entityOverview/OverviewList.vue';
import OverviewHeaderV2 from '@/components/partials/entityOverview/OverviewHeaderV2.vue';
import LanguageSwitcher from '@/components/partials/inputs/LanguageSwitcher.vue';
import SummaryCard from '@/components/partials/cards/SummaryCard.vue';

// Types
import ENTITY_TYPES from '@/common/entities/entityTypes';
import {
    PrerequisiteEval,
    TargetTypeMap,
    TargetTypeMapReverse,
    ValidationRuleTargetType,
} from '@/__new__/features/pc/validationRules/common/validationRule';
import ProductCatalogOfferModel from '@/__new__/services/dno/pc/models/ProductCatalogOfferModel';

// Misc
import { validationTargetsToString, ValidationRule, getLabel } from '@/__new__/services/dno/pc/models/ValidationRule';
import { compact } from 'lodash';

export default Vue.extend({
    name: 'ValidationRuleOverview',
    components: {
        EntityOverview,
        OverviewList,
        OverviewHeaderV2,
        LanguageSwitcher,
        SummaryCard,
    },
    props: {
        selectedRule: {
            type: Object as PropType<ValidationRule>,
            required: false,
            default: () => ValidationRule.fromDefault(),
        },
    },
    data() {
        return {
            ENTITY_TYPES,
        };
    },
    computed: {
        ...mapGetters('operators', {
            selectedLanguage: Getters.languageDefault,
        }),
        ...mapGetters(Modules.productcatalog, [Getters.PC_GET_ENTITIES_BY_TYPE_NOT_DELETED]),
        offers(): ProductCatalogOfferModel[] {
            return this[Getters.PC_GET_ENTITIES_BY_TYPE_NOT_DELETED](ENTITY_TYPES.OFFER);
        },
        entityOverviewDetails() {
            if (!this.selectedRule) return [];

            return [
                {
                    name: this.$i18n.t('generic.general'),
                    properties: [
                        {
                            label: this.$i18n.t('productCatalog.validationRules.ruleType'),
                            value: getLabel(this.selectedRule.type),
                        },
                        {
                            label: this.$i18n.t('generic.description'),
                            value: this.selectedRule.description,
                        },
                        {
                            label: this.$i18n.t('generic.startTime'),
                            value: this.selectedRule.start_time,
                        },
                        {
                            label: this.$i18n.t('generic.endTime'),
                            value: this.selectedRule.end_time,
                        },
                    ],
                },
                {
                    name: this.$i18n.t('productCatalog.validationRules.validationTargets'),
                    properties: compact([
                        {
                            label: this.$i18n.t('generic.targetType'),
                            value: getLabel(
                                TargetTypeMapReverse[
                                    this.selectedRule.target_type || TargetTypeMap[ValidationRuleTargetType.GLOBAL]
                                ],
                            ),
                        },
                        {
                            label: this.$i18n.t('productCatalog.validationRules.choosePrimaryOffers'),
                            value: validationTargetsToString(this.selectedRule.entity_ids, this.offers),
                        },
                        this.selectedRule.conflicting_entity_ids.length > 0
                            ? {
                                  label: this.$i18n.t('productCatalog.validationRules.chooseConflictingOffers'),
                                  value: validationTargetsToString(
                                      this.selectedRule.conflicting_entity_ids,
                                      this.offers,
                                  ),
                              }
                            : null,
                        this.selectedRule.requires_entity_ids.length > 0
                            ? {
                                  label: this.$i18n.t('productCatalog.validationRules.chooseRequiredOffers'),
                                  value: validationTargetsToString(this.selectedRule.requires_entity_ids, this.offers),
                              }
                            : null,
                        this.selectedRule.requires_entity_ids.length > 0
                            ? {
                                  label: this.$i18n.t('productCatalog.validationRules.evalOperationLabel'),
                                  value:
                                      this.selectedRule.eval_operation === PrerequisiteEval.ANY
                                          ? this.$i18n.t('productCatalog.validationRules.evalMustHaveOne')
                                          : this.$i18n.t('productCatalog.validationRules.evalMustHaveAll'),
                              }
                            : null,
                    ]),
                },
            ];
        },
    },
});
