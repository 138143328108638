
// Components
import AppMultiselectV3 from '@/components/partials/inputs/AppMultiselectV3.vue';
import AppInputV3 from '@/components/partials/inputs/AppInputV3.vue';
import IconButton from '@/components/partials/IconButton.vue';
import AppButton from '@/components/partials/inputs/AppButton.vue';
import AppTooltip from '@/components/partials/AppTooltip.vue';

// Helpers
import { ICON_TYPES } from '@/common/iconHelper';
import UM_HIERARCHY from '@/common/userManagerHierarchy';
import {
    DURATION_ENUM,
    TimeOption,
    TIME_FRAME_OPTIONS_WITH_LIFETIME,
    TIME_FRAME_OPTIONS_WITHOUT_LIFETIME,
    HierarchyOption,
    PURCHASE_LIMIT_HIERARCHY_OPTIONS,
} from '@/__new__/features/pc/common/limitsHelper';
import isEmpty from 'lodash/isEmpty';
import { TOOLTIP_POSITION } from '@/common/tooltip';
import { PropType } from 'vue';
import { uuidV4 } from '@/common/utils';
import { isViewEnabled } from '@/services/permissions/permissions.service';

// Types and Classes
/* eslint-disable camelcase */
type PurchaseLimitOneBE = {
    number_of_duration_units: number | null;
    max_purchase: number;
    duration_unit: DURATION_ENUM;
};

type MaxPurchasePerTargetBE = Record<UM_HIERARCHY, PurchaseLimitOneBE>;

type PurchaseLimitFullBE = {
    max_purchases: number;
    max_purchases_overall_per_duration: any;
    max_purchase_per_target: MaxPurchasePerTargetBE;
};
/* eslint-enable camelcase */

class MaxPurchasePerTargetRowData {
    maxPurchaseLimitPerTime: number | null;

    maxPurchaseLimit: number | null;

    hierarchy: HierarchyOption;

    timeOption: TimeOption;

    uuid: string;

    constructor(
        hierarchyKey: UM_HIERARCHY,
        numberOfDurationUnits: number,
        maxPurchase: number,
        durationUnit: DURATION_ENUM,
        uuid: string | undefined,
    ) {
        this.hierarchy = PURCHASE_LIMIT_HIERARCHY_OPTIONS
            // eslint-disable-next-line eqeqeq
            .find((option: HierarchyOption) => option.key == hierarchyKey);
        this.maxPurchaseLimitPerTime = numberOfDurationUnits || null;
        this.maxPurchaseLimit = maxPurchase;
        this.timeOption = TIME_FRAME_OPTIONS_WITH_LIFETIME.find(option => option.key === durationUnit);
        this.uuid = uuid || uuidV4();
    }
}

type PurchaseLimitComponentData = {
    maxPurchases: number;
    maxPurchasesOverallPerDuration: {
        maxPurchase: number;
        numberOfDurationUnits: number;
        durationUnit: DURATION_ENUM.LIFETIME;
    };
    maxPurchasePerTarget: Array<MaxPurchasePerTargetRowData>;
};

export default {
    name: 'PurchaseLimit',
    components: {
        AppMultiselectV3,
        AppInputV3,
        IconButton,
        AppButton,
        AppTooltip,
    },
    props: {
        disabled: {
            type: Boolean,
            default: false,
        },
        value: {
            type: (Object as PropType<PurchaseLimitFullBE>) || null,
            default: null,
        },
    },
    data() {
        return {
            purchaseLimitData: {
                maxPurchases: null,
                maxPurchasesOverallPerDuration: {
                    maxPurchase: null,
                    numberOfDurationUnits: null,
                    durationUnit: TIME_FRAME_OPTIONS_WITHOUT_LIFETIME[0],
                },
                maxPurchasePerTarget: [],
            } as PurchaseLimitComponentData,
            PURCHASE_LIMIT_HIERARCHY_OPTIONS,
            TIME_FRAME_OPTIONS_WITH_LIFETIME,
            TIME_FRAME_OPTIONS_WITHOUT_LIFETIME,
            ICON_TYPES,
            DURATION_ENUM,
            TOOLTIP_POSITION,
            uuidV4,
        };
    },
    watch: {
        purchaseLimitData: {
            handler(newVal: PurchaseLimitComponentData) {
                const formattedValue = {};
                formattedValue.max_purchase_per_target = {};
                formattedValue.max_purchases = newVal.maxPurchases;
                if (
                    newVal?.maxPurchasesOverallPerDuration &&
                    (newVal?.maxPurchasesOverallPerDuration?.maxPurchase ||
                        newVal?.maxPurchasesOverallPerDuration?.numberOfDurationUnits)
                ) {
                    formattedValue.max_purchases_overall_per_duration = {
                        max_purchase: newVal.maxPurchasesOverallPerDuration.maxPurchase,
                        number_of_duration_units: newVal.maxPurchasesOverallPerDuration.numberOfDurationUnits,
                        duration_unit: newVal.maxPurchasesOverallPerDuration.durationUnit.key,
                    };
                }
                if (newVal?.maxPurchasePerTarget && newVal?.maxPurchasePerTarget.length) {
                    newVal.maxPurchasePerTarget.forEach(row => {
                        formattedValue.max_purchase_per_target[row.hierarchy.key] = {
                            ...formattedValue.max_purchase_per_target[row.hierarchy.key],
                            ...(row.timeOption.key !== DURATION_ENUM.LIFETIME
                                ? {
                                      max_purchases_per_duration: {
                                          max_purchase: row.maxPurchaseLimit,
                                          duration_unit: row.timeOption.key,
                                          number_of_duration_units: row.maxPurchaseLimitPerTime,
                                      },
                                  }
                                : { max_purchase: row.maxPurchaseLimit }),
                        };
                    });
                }

                this.$emit('input', formattedValue);
            },
            deep: true,
            immediate: true,
        },
    },
    created() {
        this.initHierarchyOptions();
    },
    mounted() {
        this.fillForm(this.value);
    },
    methods: {
        initHierarchyOptions() {
            if (isViewEnabled('PCUIChangeGlobe')) {
                this.PURCHASE_LIMIT_HIERARCHY_OPTIONS = this.PURCHASE_LIMIT_HIERARCHY_OPTIONS.map(option => {
                    if (option.key === UM_HIERARCHY.UNREGISTERED_MSISDN) {
                        option.value = this.$i18n.t('customerCare.msisdn');
                    }
                    return option;
                });
            }
        },
        fillForm(formData) {
            if (!formData) return;
            this.purchaseLimitData.maxPurchases = formData?.max_purchases;
            const maxPurchesesOverallPerDurationFromBe = formData?.max_purchases_overall_per_duration || {};
            this.purchaseLimitData.maxPurchasesOverallPerDuration = {
                maxPurchase: maxPurchesesOverallPerDurationFromBe?.max_purchase || 0,
                numberOfDurationUnits: maxPurchesesOverallPerDurationFromBe?.number_of_duration_units || 0,
                durationUnit:
                    TIME_FRAME_OPTIONS_WITHOUT_LIFETIME.find(
                        option => option.key === maxPurchesesOverallPerDurationFromBe?.duration_unit,
                    ) || TIME_FRAME_OPTIONS_WITHOUT_LIFETIME[0],
            };
            if (formData?.max_purchase_per_target && !isEmpty(formData.max_purchase_per_target)) {
                Object.entries(formData.max_purchase_per_target).forEach(([hierarchyKey, maxPurchasePerTargetData]) => {
                    if (
                        maxPurchasePerTargetData?.max_purchases_per_duration &&
                        maxPurchasePerTargetData.max_purchases_per_duration.duration_unit !== DURATION_ENUM.LIFETIME
                    ) {
                        this.purchaseLimitData.maxPurchasePerTarget.push(
                            new MaxPurchasePerTargetRowData(
                                hierarchyKey,
                                maxPurchasePerTargetData.max_purchases_per_duration.number_of_duration_units,
                                maxPurchasePerTargetData.max_purchases_per_duration.max_purchase,
                                maxPurchasePerTargetData.max_purchases_per_duration.duration_unit,
                            ),
                        );
                    }
                    if (maxPurchasePerTargetData?.max_purchase) {
                        this.purchaseLimitData.maxPurchasePerTarget.push(
                            new MaxPurchasePerTargetRowData(
                                hierarchyKey,
                                0,
                                maxPurchasePerTargetData?.max_purchase,
                                DURATION_ENUM.LIFETIME,
                            ),
                        );
                    }
                });
            }
        },
        onAddPurchaseLimit() {
            this.$set(this.purchaseLimitData.maxPurchasePerTarget, this.purchaseLimitData.maxPurchasePerTarget.length, {
                hierarchy: PURCHASE_LIMIT_HIERARCHY_OPTIONS[0],
                maxPurchaseLimit: 1,
                maxPurchaseLimitPerTime: '',
                timeValue: '',
                timeOption: TIME_FRAME_OPTIONS_WITH_LIFETIME[0],
                uuid: uuidV4(),
            });
        },
        onDeleteRow(hierarchyKey: UM_HIERARCHY) {
            const index = this.purchaseLimitData.maxPurchasePerTarget.findIndex(
                rowItem => rowItem.hierarchy.key === hierarchyKey,
            );
            this.purchaseLimitData.maxPurchasePerTarget.splice(index, 1);
        },
        onPurchasePerTargetTimeOptionInput(value: TIME_FRAME_OPTIONS_WITH_LIFETIME, index: number) {
            const maxPurchaseToUpdate = this.purchaseLimitData.maxPurchasePerTarget[index];
            maxPurchaseToUpdate.timeOption = value;
            if (value.key === DURATION_ENUM.LIFETIME) {
                maxPurchaseToUpdate.maxPurchaseLimitPerTime = null;
            }
        },
    },
};
