import UserManagerBaseModel from '@/__new__/services/dno/user/models/UserManagerBaseModel';

export default class RewardRule extends UserManagerBaseModel {
    id;

    name;

    type;

    version;

    state;

    data;

    constructor({ id, name, type, version, state, data }) {
        super();
        this.id = this.validateString('id', id) ? id : '';
        this.name = this.validateString('name', name) ? name : '';
        this.type = this.validateString('type', type) ? type : '';
        this.version = this.validateNumberInteger('version', version) ? version : '';
        this.state = this.validateNumberInteger('state', state) ? state : '';
        this.data = data;
    }

    static remapRewardRuleFromBe({ id, type, version, state, data } = {}) {
        return {
            id,
            name: data?.name?.en || '',
            version,
            state,
            type,
            data,
        };
    }
}
