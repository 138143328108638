
// Components
import AbstractTableTile from '@/__new__/features/customerCareSuite/components/AbstractTableTile.vue';
import AppToggle from '@/components/partials/inputs/AppToggle.vue';
import IconButton from '@/components/partials/IconButton.vue';

// Helpers
import { ALERT_TYPES } from '@/common/alerts/Alert';
import { cloneDeep } from 'lodash';
import { ICON_TYPES } from '@/common/iconHelper';
import { formatAmountBasedOnCurrency } from '@/common/formatting';

import BillingAccountViewBillDialog from '@/__new__/features/customerCareSuite/components/BillingAccountViewBillDialog.vue';

// HTTP
import { getInvoicesForBillingAccount } from '@/__new__/services/portal/postpaid/invoices/invoices';

export default {
    name: 'BillingAccountInvoicesTile',
    components: {
        AbstractTableTile,
        AppToggle,
        IconButton,
        BillingAccountViewBillDialog,
    },
    data() {
        return {
            entities: [],
            rawResponse: {},
            selectedEntity: {},
            showAdditionalSidebar: false,
            account: null,
            showOnlyPaidInvoices: false,
            // proxy
            ICON_TYPES,
            isModalVisible: false,
            selectedInvoiceId: 0,
        };
    },
    computed: {
        formattedEntities() {
            const invoicesInfoCopy = cloneDeep(this.rawResponse);
            const invoices = invoicesInfoCopy?.data;
            if (invoices?.length) {
                return invoices.map(item => ({
                    id: item.id,
                    startDate: item.startDate,
                    endDate: item.endDate,
                    amount: formatAmountBasedOnCurrency(item.amount),
                    isPaid: item.isPaid,
                    url: item.url,
                    dueDate: item.dueDate,
                    isOverdue: item.dueDate ? new Date(item.dueDate).getTime() < Date.now() : false,
                }));
            }
            return [];
        },
        columnsData() {
            return [
                {
                    name: this.$i18n.t('billingAccount.id'),
                    key: 'id',
                },
                {
                    name: this.$i18n.t('generic.startDate'),
                    key: 'startDate',
                },
                {
                    name: this.$i18n.t('generic.endDate'),
                    key: 'endDate',
                },
                {
                    name: this.$i18n.t('generic.amount'),
                    key: 'amount',
                },
                {
                    name: this.$i18n.t('billingAccount.isPaid'),
                    key: 'isPaid',
                },
                {
                    name: this.$i18n.t('billingAccount.dueDate'),
                    key: 'dueDate',
                },
                {
                    name: this.$i18n.t('billingAccount.isOverdue'),
                    key: 'isOverdue',
                },
            ];
        },
    },
    watch: {
        showOnlyPaidInvoices: {
            deep: true,
            handler() {
                this.fetchTileData();
            },
        },
    },
    async created() {
        await this.fetchTileData();
    },
    methods: {
        async fetchTileData() {
            await this.$withProgressBar(
                async () => {
                    this.$emit('isDataLoadingUpd', true);
                    this.rawResponse = await getInvoicesForBillingAccount(
                        this.$route.params.id,
                        this.showOnlyPaidInvoices,
                    );
                    this.$emit('isDataLoadingUpd', false);
                },
                {
                    errorHandler: () => {
                        this.$alert(this.$i18n.t('alertMessage.somethingWentWrongFetchingNecessaryData'), {
                            type: ALERT_TYPES.error,
                        });
                        this.$emit('isDataLoadingUpd', false);
                    },
                },
            );
        },
        selectedRow(entity: object): void {
            this.selectedEntity = entity;
            this.showAdditionalSidebar = true;
        },
        openModal(invoiceId: number) {
            this.isModalVisible = false;
            this.selectedInvoiceId = invoiceId;
            this.isModalVisible = true;
        },
        closeModal() {
            this.isModalVisible = false;
            this.selectedInvoiceId = 0;
        },
    },
};
