<template>
    <div
        data-test-id="account-order-details"
        :class="{ 'shrink-width': sidebarState }"
    >
        <TableFiltersRenderless :entities="deliveriesList">
            <template
                #default="{
                    // state
                    allFilters,
                    filteredEntities,
                    // methods
                    onFilterAdded,
                    removeFilter,
                    removeAllFilters,
                }"
            >
                <div>
                    <div class="d-flex align-items-center justify-content-between mb-2">
                        <div
                            v-show="title"
                            class="d-flex component-title-um"
                        >
                            {{ title }}
                        </div>
                        <div class="d-flex">
                            <SearchBox
                                v-model="searchQuery"
                                class="table-search-box"
                                :smallSearchIcon="true"
                            />
                            <ResponseModalButton
                                :response="apiResponse"
                                :title="title"
                            />
                            <div class="filters-control position-relative">
                                <FilterTable
                                    :columns="deliveriesColumnsData"
                                    :multiselectWidth="{ width: '12rem' }"
                                    @filterAdded="onFilterAdded"
                                >
                                    <template #button="slotProps">
                                        <IconButton
                                            :label="$i18n.t('generic.filter')"
                                            :icon="ICON_TYPES.FILTER"
                                            @iconClick="slotProps.clickHandler"
                                        />
                                    </template>
                                </FilterTable>
                            </div>
                        </div>
                    </div>

                    <TableFiltersTags
                        :filterTableMixin="{ allFilters }"
                        class="mt-1 mb-3"
                        @removeFilter="removeFilter"
                        @removeAllFilters="removeAllFilters"
                    />

                    <AppTable
                        :entities="filteredEntities"
                        :selectedEntityId="selectedParentDelivery ? selectedParentDelivery.deliveryId : ''"
                        :innerSearchQuery="searchQuery"
                        :isSearchEnabled="true"
                        :isDefaultHoverEnabled="true"
                        :isCustomButtonPassed="true"
                        :disableBoxShadow="true"
                        :canSelectColumns="true"
                        :newDesign="true"
                        :enableRowStateControls="false"
                        :columnsData="deliveriesColumnsData"
                        tableKey="user/account-delivery"
                        keyName="deliveryId"
                        @selectedEntityModel="o => [selectDelivery(o), toggleModal(true, sectionKeys.DELIVERIES)]"
                    >
                        <template
                            slot="status"
                            slot-scope="{ entity }"
                        >
                            <EntityStatusIndicator
                                v-if="!isUndefined(entity.status)"
                                :stateMap="DELIVERY_STATES_TO_STATUS_NAME_MAP"
                                :stateMapColor="DELIVERY_STATUS_INDICATOR_MAP"
                                :status="entity.status"
                                :isInTable="true"
                            />

                            <i v-else>{{ $i18n.t('generic.empty') }}</i>
                        </template>
                    </AppTable>
                </div>
            </template>
        </TableFiltersRenderless>

        <AdditionalSidebarDeliveries
            :selectedParentDelivery="selectedParentDelivery"
            :showAdditionalSidebar="showAdditionalSidebar"
            @closeSidebar="toggleModal($event, sectionKeys.DELIVERIES)"
        />
    </div>
</template>

<script>
// COMPONENTS
import AppTable from '@/components/partials/AppTable.vue';
import EntityStatusIndicator from '@/components/partials/EntityStatusIndicator.vue';
import SearchBox from '@/components/partials/inputs/SearchBox.vue';
import IconButton from '@/components/partials/IconButton.vue';
import { ICON_TYPES } from '@/common/iconHelper';
import TableFiltersRenderless from '@/components/filters/TableFiltersRenderless.vue';
import TableFiltersTags from '@/components/filters/TableFiltersTags.vue';
import FilterTable from '@/components/partials/FilterTable.vue';

import AdditionalSidebarDeliveries from '@/__new__/features/customerCare/account/AdditionalSidebarDeliveries.vue';
import ResponseModalButton from '@/components/partials/ResponseModalButton.vue';

// HELPERS
import {
    DELIVERY_STATES_TO_STATUS_NAME_MAP,
    DELIVERY_STATUS_INDICATOR_MAP,
} from '@/__new__/features/customerCare/account/common/deliveryStateHelper';
import { isUndefined } from 'lodash';
import tableColumnType from '@/common/filterTable';
import AccountSidebarMixin, { sectionKeys } from '@/__new__/features/customerCare/mixins/AccountSidebarMixin.vue';

export default {
    name: 'Deliveries',
    components: {
        AppTable,
        EntityStatusIndicator,
        SearchBox,
        IconButton,
        TableFiltersRenderless,
        TableFiltersTags,
        FilterTable,
        AdditionalSidebarDeliveries,
        ResponseModalButton,
    },
    mixins: [AccountSidebarMixin],
    props: {
        deliveries: {
            type: Object,
            required: true,
        },
        apiResponse: {
            type: Object,
            default: null,
        },
        title: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            DELIVERY_STATES_TO_STATUS_NAME_MAP,
            DELIVERY_STATUS_INDICATOR_MAP,
            showOnlyActiveDeliveries: false,
            selectedParentDelivery: null,
            searchQuery: '',
            ICON_TYPES,
        };
    },
    computed: {
        showAdditionalSidebar() {
            return this.getAdditionalSidebarStateBySectionKey(sectionKeys.DELIVERIES);
        },
        deliveriesColumnsData() {
            return [
                {
                    name: this.$i18n.t('customerCare.deliveries.deliveryId'),
                    key: 'deliveryId',
                    forbidHideColumn: true,
                    field: 'deliveryId',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('customerCare.orderHistory.orderId'),
                    key: 'orderId',
                    forbidHideColumn: true,
                    field: 'orderId',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('customerCare.deliveries.addressId'),
                    key: 'addressId',
                    field: 'addressId',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('customerCare.deliveries.trackingId'),
                    key: 'trackingId',
                    field: 'trackingId',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('generic.status'),
                    key: 'status',
                    classes: ['overflow-visible-all'],
                    field: 'status',
                    filterType: tableColumnType.TEXT_LIMITED_OPTIONS,
                    limitedOptions: Array.from(new Set(this.deliveriesList.map(entity => entity.status))),
                },
            ];
        },
        deliveriesList() {
            return Object.values(this.deliveries);
        },
    },
    methods: {
        isUndefined,
        selectDelivery(delivery) {
            this.selectedParentDelivery = this.deliveriesList.find(entity => entity.deliveryId === delivery.deliveryId);
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/base';
@import '~@/assets/scss/palette';

.search-box-wrapper {
    position: relative;
    width: 15rem;
    height: 2.5rem;
}

.auto-height {
    height: auto !important;
}

.table-search-box {
    width: 15rem;
}

.order-wrap {
    margin-bottom: 1.75rem;
}

.modal-row {
    margin-bottom: 0.875rem;
}

.row-title {
    font-size: 0.75rem;
    font-weight: 600;
    width: 7.875rem;
}

.row-value {
    font-size: 0.875rem;
    width: 100%;
}

.plan-change {
    height: 22.25rem;
}
.plan-change {
    height: 22.25rem;
}

.plan-change-cancel {
    height: 13rem;
}

.plan-change-blackout {
    height: 12.813rem;
}
.plan-change-title {
    margin-top: 1.813rem;
    margin-left: 2.5rem;
}

.plan-change-multiselect {
    margin-top: 1.75rem;
    margin-left: 2.5rem;
    margin-right: 9rem;
}

.plan-change-buttons {
    margin-top: 2.813rem;
    margin-right: 2rem;
    display: flex;
    justify-content: flex-end;
}

.plan-change-text {
    font-size: 0.875rem;
    margin-top: 1.688rem;
    margin-left: 2.5rem;
    margin-right: 2.375rem;
}
.plan-change-text-bold {
    font-weight: bold;
}
</style>
