
import Vue from 'vue';

// Components
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import AppDialog from '@/components/partials/AppDialog.vue';
import DeductBalanceForm from '@/__new__/features/customerCare/DeductBalanceForm.vue';
import SensitiveEditModal from '@/__new__/features/customerCare/SensitiveEditModal.vue';
import TopupForm from '@/__new__/features/customerCare/TopupForm.vue';
import AssociatedUMEntityPicker from '@/__new__/features/customerCareSuite/components/AssociatedUMEntityPicker.vue';

// Helpers
import { MODAL_CONTENT } from '@/__new__/features/customerCare/common/userInfoHelper';
import { USER_MANAGER_HIERARCHY } from '@/__new__/features/customerCare/common/customerCareHelper';
import { WALLET_TYPES } from '@/__new__/features/customerCare/common/ordersHelper';
import permissionsService, { isUserAllowed, isViewEnabled } from '@/services/permissions/permissions.service';

// HTTP
import ordersHTTP from '@/__new__/services/dno/orders/http/orders';

// Vuex
import { Modules } from '@/store/store';
import { mapGetters } from 'vuex';
import { Getters } from '@/store/mutation-types';

export default Vue.extend({
    name: 'WalletTile',
    components: {
        AppButton,
        AppDialog,
        SensitiveEditModal,
        TopupForm,
        DeductBalanceForm,
        AssociatedUMEntityPicker,
    },
    props: {
        userManagerHierarchy: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            amount: 0,
            amountWithCurrency: this.$localeLibrary.getFormattedAmount(0),
            activeEpoch: '',
            graceEpoch: '',

            selectedAssociatedUMEntity: null,

            selectedWalletType: WALLET_TYPES.PRIMARY,
            modalVisible: false,
            editingContent: 0,
            sensitiveEditing: false,

            // permissions related
            isDeductEnabled: isUserAllowed('UMAccountWalletDeductBalanceAccountLevel'),
            isTopUpEnabled: isUserAllowed('UMAccountWalletTopUpAccountLevel'),

            BUTTON_TYPES,
            MODAL_CONTENT,
            WALLET_TYPES,
        };
    },
    computed: {
        ...mapGetters(Modules.customerCareSuite, [Getters.GET_USER_AUTHENTICATION_STATE]),
        isUserAuthenticationEnabled() {
            return (
                permissionsService.userManagementUserAuthenticationEnabled() &&
                isViewEnabled('UMUserUserAuthentication')
            );
        },
        isParentUserAuthenticated() {
            return this[Getters.GET_USER_AUTHENTICATION_STATE]
                ? this[Getters.GET_USER_AUTHENTICATION_STATE]?.status ===
                      this.i18n.t('customerCare.userAuthentication.verified')
                : false;
        },
        isEditAllowed() {
            return !this.isUserAuthenticationEnabled || this.isParentUserAuthenticated;
        },
        isUserTile() {
            return this.userManagerHierarchy === USER_MANAGER_HIERARCHY.USER;
        },
    },
    watch: {
        selectedAssociatedUMEntity: {
            handler(newVal) {
                if (newVal) {
                    this.fetchBalances();
                }
            },
            immediate: true,
            deep: true,
        },
    },
    created() {
        this.fetchTileData();
    },
    methods: {
        async fetchBalances() {
            try {
                this.$Progress.start();
                let balances;
                if (this.selectedAssociatedUMEntity && this.selectedAssociatedUMEntity.id) {
                    if (this.selectedAssociatedUMEntity.type === USER_MANAGER_HIERARCHY.SUBSCRIBER) {
                        balances = await ordersHTTP.getBalances(
                            this.selectedAssociatedUMEntity.id,
                            this.selectedAssociatedUMEntity.type,
                        );
                    } else {
                        balances = await ordersHTTP.getSharedWalletBalances(
                            this.selectedAssociatedUMEntity.id,
                            this.selectedAssociatedUMEntity.type,
                        );
                    }
                    if (balances?.wallets?.primary) {
                        const { active_epoch: activeEpoch, grace_epoch: graceEpoch, amount } = balances.wallets.primary;

                        this.activeEpoch = activeEpoch ? this.$localeLibrary.getFormattedDate(activeEpoch) : '';
                        this.graceEpoch = graceEpoch ? this.$localeLibrary.getFormattedDate(graceEpoch) : '';
                        this.amountWithCurrency = this.$localeLibrary.getFormattedAmount(amount);
                        this.amount = Number(amount);
                    }
                }

                this.$Progress.finish();
            } catch (e) {
                this.$Progress.fail();
                this.$alert(this.$t('alertMessage.failedToLoadNecessaryData'));
            }
        },
        onDeductAmount(walletType) {
            this.selectedWalletType = walletType;
            this.modalVisible = true;
            this.editingContent = this.MODAL_CONTENT.DEDUCT;
        },
        onTopUpAmount(walletType) {
            this.selectedWalletType = walletType;
            this.modalVisible = true;
            this.editingContent = this.MODAL_CONTENT.TOP_UP;
        },
        closeModal() {
            this.modalVisible = false;
            this.editingContent = 0;
            this.sensitiveEditing = false;
        },
        onRefreshButtonClicked() {
            this.fetchBalances();
        },
        fetchTileData() {
            this.$withProgressBar(
                async () => {
                    this.$emit('isDataLoadingUpd', true);
                    await this.fetchBalances();
                    this.$emit('isDataLoadingUpd', false);
                },
                {
                    errorHandler: () => {
                        this.$showErrorAlert({
                            message: this.$i18n.t('alertMessage.somethingWentWrongFetchingNecessaryData'),
                        });
                        this.$emit('isDataLoadingUpd', false);
                    },
                },
            );
        },
    },
});
