import VueCookie from 'vue-cookie';
import { getTenants } from '@/http/config/tenant';
import { getOperators } from '@/__new__/services/portal/operators/http/operators';
import Actions, { Mutations, State, Getters } from '../mutation-types';
import { languageMap } from '@/common/locale/language';

export default {
    namespaced: true,
    state: {
        activeOperatorId: null,
        availableOperators: {},
        availableTenants: {},
        [State.Languages]: [],
    },
    mutations: {
        setActiveOperator: (state, id) => {
            state.activeOperatorId = id;
        },
        setAllOperators: (state, operators) => {
            state.availableOperators = operators;
        },
        setAllTenants: (state, operators) => {
            state.availableTenants = operators;
        },
        [Mutations.SetLanguages]: (state, languages) => {
            state[State.Languages] = languages;
        },
    },
    actions: {
        setActiveOperator: ({ commit }) => {
            commit('setActiveOperator', VueCookie.get('CID'));
        },
        setAllOperators: ({ commit }) => {
            getOperators().then(res => {
                commit('setAllOperators', res.data);
            });
        },
        setAllTenants: ({ commit }) => {
            getTenants().then(res => {
                commit('setAllTenants', res.data);
            });
        },
        [Actions.SetLanguages]: ({ commit, getters }) => {
            const languages = [languageMap.en];
            commit(Mutations.SetLanguages, languages);
        },
    },
    getters: {
        [Getters.languageDefault]: _state =>
            _state[State.Languages] ? _state[State.Languages][0]?.key : languageMap.en.key,
        [Getters.languagesWithoutDefault]: _state =>
            _state[State.Languages].length > 1 ? _state[State.Languages].slice(1) : [],
        [Getters.allLanguages]: _state => (_state[State.Languages].length > 1 ? _state[State.Languages] : ['en']),
    },
};
