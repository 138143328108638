import { GetDocumentTemplateResponse } from '@/__new__/services/dno/documents/models/DocumentInterfaces';

export default class DocumentTemplate {
    id: string;
    name: string;
    version?: number;
    created?: number;
    updated?: number;
    updatedBy?: string;
    templateDefinition: string;

    constructor(data: Partial<DocumentTemplate>) {
        this.id = data.id || '';
        this.name = data.name || '';
        this.version = data.version;
        this.created = data.created;
        this.updated = data.updated;
        this.updatedBy = data.updatedBy;
        this.templateDefinition = data.templateDefinition || '';
    }

    static mapFromResponse(data: GetDocumentTemplateResponse['data'] & { id: string }) {
        return new DocumentTemplate({
            id: data.id,
            name: data.id,
            version: data.metadata?.version,
            created: data.metadata?.created_at,
            updated: data.metadata?.updated_at,
            updatedBy: data.metadata?.updated_by,
            templateDefinition: data.template_definition,
        });
    }
}
