<template>
    <div class="page-background">
        <AppHeader :pageTitle="$i18n.t('customerCare.eSIM.esimDetails')" />
        <div class="page-content-wrapper">
            <Breadcrumbs
                :breadcrumbList="breadcrumbList"
                class="section-layout"
                @breadcrumbClicked="breadcrumbNavigation($event)"
            />
            <AppCustomerHeader :infoData="infoData" />
        </div>
    </div>
</template>

<script>
// Vue components
import AppHeader from '@/components/layout/AppHeader.vue';
import Breadcrumbs from '@/components/partials/Breadcrumbs.vue';
import AppCustomerHeader from '@/components/partials/AppCustomerHeader.vue';
import supportButtonMixin from '@/components/alerts/supportButtonMixin';
import { ALERT_TYPES } from '@/common/alerts/Alert';

// helpers
import { mapState, mapActions } from 'vuex';
import Actions, { State } from '@/store/mutation-types';
import { getBeautifulBoolean, epochToDate } from '@/common/formatting';
import { getEsimSubscriptoinStatus, getEsimIdentifierHistory } from '@/__new__/services/dno/esim/http/esim';
import * as Sentry from '@sentry/vue';

export default {
    name: 'ESimDetails',

    components: {
        AppHeader,
        Breadcrumbs,
        AppCustomerHeader,
    },

    mixins: [supportButtonMixin],

    data() {
        return {
            esimSubscriptionStatus: '',
            esimIdentifierHistory: [],
        };
    },

    computed: {
        ...mapState('esim', {
            esimData: State.ESIM_DATA,
        }),
        iccid() {
            return this.$route.params.iccid || '';
        },
        breadcrumbList() {
            return [
                {
                    name: this.$i18n.t('generic.search'),
                    link: '/customer-care/esim',
                },
                {
                    name: this.$i18n.t('customerCare.iccid'),
                },
            ];
        },
        infoData() {
            return [
                {
                    title: this.$i18n.t('generic.details'),
                    value: [
                        {
                            name: this.$i18n.t('customerCare.eSIM.resourceId'),
                            value: this.esimData.resourceId,
                        },
                        {
                            name: this.$i18n.t('generic.activationDate'),
                            value: this.activationDate || '',
                        },
                        {
                            name: this.$i18n.t('customerCare.eSIM.profileType'),
                            value: this.esimData.profileType,
                        },
                        {
                            name: this.$i18n.t('customerCare.eSIM.activeSubscription'),
                            value: this.getFormatActiveSubscriptionData(),
                        },
                        {
                            name: this.$i18n.t('customerCare.eSIM.EID'),
                            value: this.esimData.eid,
                        },
                        {
                            name: this.$i18n.t('customerCare.msisdn'),
                            value: this.esimData.msisdn,
                        },
                    ],
                    initiallyExpanded: true,
                },
                {
                    title: '',
                    value: [
                        {
                            name: this.$i18n.t('customerCare.imei'),
                            value: this.esimData.imei,
                        },
                        {
                            name: this.$i18n.t('customerCare.imsi'),
                            value: this.esimData.imsi,
                        },
                        {
                            name: this.$i18n.t('customerCare.iccid'),
                            value: this.esimData.iccid,
                        },
                    ],
                },
            ];
        },
        activationDate() {
            // figuring out the smallest datetime which stands for the activation date
            return this.esimIdentifierHistory.length
                ? epochToDate(Math.min(...this.esimIdentifierHistory.map(historyEntity => historyEntity.timestamp)))
                : '';
        },
    },

    async created() {
        if (this.esimData.iccid !== this.$route.params.iccid) {
            await this.requestProfileData();
        }
        this.esimSubscriptionStatus = await this.getEsimSubscriptionStatus();
        this.esimIdentifierHistory = await this.getEsimIdentifierHistory();
    },

    methods: {
        ...mapActions('esim', {
            requestEsimData: Actions.REQUEST_ESIM_DATA,
        }),
        breadcrumbNavigation(breadcrumb) {
            this.$router.push({ path: `/${this.$route.params.companyId}${breadcrumb.link}` });
        },
        getFormatActiveSubscriptionData() {
            // In case if related API call fails - we assign '' to esimSubscriptionStatus and
            // returning '' here for proper handling in the AppCustomerHeader
            return this.esimSubscriptionStatus === '' ? '' : getBeautifulBoolean(this.esimSubscriptionStatus);
        },

        async requestProfileData() {
            try {
                this.$Progress.start();
                await this.requestEsimData(this.iccid);
                this.$Progress.finish();
            } catch (error) {
                Sentry.captureException(error);
                this.$Progress.fail();
                this.showSupportAlert(
                    this.$i18n.t('alertMessage.somethingWentWrongFetchingNecessaryData'),
                    ALERT_TYPES.error,
                );
            }
        },

        async getEsimSubscriptionStatus() {
            try {
                this.$Progress.start();
                const response = await getEsimSubscriptoinStatus(this.iccid);
                this.$Progress.finish();
                return response.data.subscriptions.esim_profile_active;
            } catch (error) {
                this.$Progress.fail();
                Sentry.captureException(error);
                this.showSupportAlert(
                    this.$i18n.t('alertMessage.somethingWentWrongFetchingNecessaryData'),
                    ALERT_TYPES.error,
                );
                return '';
            }
        },

        async getEsimIdentifierHistory() {
            try {
                this.$Progress.start();
                const response = await getEsimIdentifierHistory(this.iccid);
                this.$Progress.finish();
                return response.data.history;
            } catch (error) {
                this.$Progress.fail();
                Sentry.captureException(error);
                this.showSupportAlert(
                    this.$i18n.t('alertMessage.somethingWentWrongFetchingNecessaryData'),
                    ALERT_TYPES.error,
                );
                return [];
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/palette';
@import '~@/assets/scss/layout';

.page-background {
    background-color: $dirty-white;
    height: 100%;
}

.page-content-wrapper {
    padding: $spacing-l;
}

.search-wrapper {
    width: 60%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding-top: 3px;
}

.search-with-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 50%;
}

.search-options-dropdown {
    width: 50%;
}

.search-query-input-wrapper {
    width: 50%;
    min-width: 200px;
}

.inline-text {
    font-size: 0.75rem;
    text-transform: uppercase;
    font-weight: bold;
    color: $gray-blue;
    margin-right: 0.75rem;
}
</style>
