<template>
    <div class="editor-input-largest pr-3">
        <table class="position-relative w-100 mb-3">
            <tr
                class="w-100 no-gutters text--sm"
                :class="{
                    'disabled-area': disabled,
                }"
            >
                <th class="pl-3 pr-1 py-1">
                    {{ entityLabel }}
                </th>
                <th class="p-1 cardinality">
                    {{ $t('generic.min') }}
                </th>
                <th class="p-1 cardinality">
                    {{ $t('generic.max') }}
                </th>
            </tr>

            <tbody>
                <tr
                    v-for="(option, n) in entities"
                    :key="n"
                    class="position-relative w-100 no-gutters text--sm truncate-text"
                >
                    <td class="pl-3 pr-1 py-1">
                        <AppMultiselectV3
                            v-model="entities[n].entity"
                            :options="options"
                            :placeholder="$t('productCatalog.pleaseSelectChildEntityYouWantToAdd')"
                            :disabled="disabled"
                            :small="true"
                            label="name"
                            trackBy="id"
                            @input="updateStorage"
                        />
                    </td>

                    <td class="p-1">
                        <AppInputV3
                            :id="`cardinality-min-${n}`"
                            v-model="entities[n].min"
                            :placeholder="$t('generic.min')"
                            :min="0"
                            :step="step"
                            :disabled="disabled"
                            type="number"
                            @change="onCardinalityChange(n)"
                        />
                    </td>

                    <td class="p-1">
                        <AppInputV3
                            :id="`cardinality-max-${n}`"
                            v-model.number="entities[n].max"
                            :placeholder="$t('generic.max')"
                            :min="0"
                            :step="step"
                            :disabled="disabled"
                            type="number"
                            @change="onCardinalityChange(n)"
                        />
                    </td>

                    <AppIcon
                        v-show="enableDeleteButton && !disabled && entities[n].entity"
                        :id="`delete-btn-${n}`"
                        :type="ICON_TYPES.DELETE"
                        class="delete-btn"
                        @click="deleteSlot(n)"
                    />
                </tr>
            </tbody>
        </table>

        <AppButton
            :label="addButtonLabel || $t('generic.addNew')"
            :iconType="ICON_TYPES.PLUS"
            :isSmall="true"
            :disabled="disabled"
            class="pl-3"
            @click="addSlot"
        />
    </div>
</template>
<script>
// Components
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import AppIcon from '@/components/partials/icon/AppIcon.vue';
import AppInputV3 from '@/components/partials/inputs/AppInputV3.vue';
import AppMultiselectV3 from '@/components/partials/inputs/AppMultiselectV3.vue';

// Helpers
import { ICON_TYPES } from '@/common/iconHelper';
import LocalStorageHelper from '@/common/LocalStorageHelper';

class EntityCardinality {
    constructor({ entity, min, max } = {}) {
        this.entity = entity || null;
        this.min = min || 0;
        this.max = max || min || 0;
    }
}

export default {
    name: 'AtiotOfferEntityCardinality',
    components: {
        AppButton,
        AppIcon,
        AppInputV3,
        AppMultiselectV3,
    },
    props: {
        options: {
            type: Array,
            default: () => [],
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        optionsType: {
            type: Object,
            default: () => ({ type: undefined, typeName: undefined }),
        },
        addButtonLabel: {
            type: String,
            default: '',
        },
        enableDeleteButton: {
            type: Boolean,
            default: true,
        },
        step: {
            type: Number,
            default: 1,
        },
    },
    data() {
        return {
            BUTTON_TYPES,
            ICON_TYPES,
            entities: [new EntityCardinality()],
            storedChildCardinality: JSON.parse(LocalStorageHelper.get('atiotOfferChildCardinality')) || {},
        };
    },
    computed: {
        storedEntities() {
            return this.storedChildCardinality?.[this.$route.params.id]?.[this.optionsType?.type] || [];
        },
        entityLabel() {
            return this.optionsType?.typeName || 'Entity';
        },
    },
    watch: {
        optionsType: {
            immediate: true,
            deep: true,
            handler() {
                let entities = [new EntityCardinality()];

                if (this.storedEntities.length) {
                    entities = this.storedEntities;
                } else if (this.$attrs.value?.length) {
                    entities = this.$attrs.value.map(entity => new EntityCardinality({ entity }));
                }

                this.entities = entities;
                this.updateParentForm();
            },
        },
    },
    methods: {
        onCardinalityChange(index) {
            if (!this.entities[index].max || this.entities[index].max < this.entities[index].min) {
                this.entities[index].max = Number(this.entities[index].min);
            }
            this.updateStorage();
        },
        addSlot() {
            this.entities.push(new EntityCardinality());
        },
        deleteSlot(index) {
            this.entities.splice(index, 1);
            this.updateStorage();
            // Update UI so it's not broken
            if (!this.entities.length) {
                this.entities.push(new EntityCardinality());
            }
        },
        updateParentForm() {
            this.$emit('input', this.entities.map(({ entity }) => entity).filter(Boolean));
        },
        updateStorage() {
            this.updateParentForm();
            this.storedChildCardinality[this.$route.params.id] = {
                [this.optionsType.type]: this.entities.filter(e => e.entity?.name),
            };
            LocalStorageHelper.set('atiotOfferChildCardinality', JSON.stringify(this.storedChildCardinality));
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/consistency';
@import '~@/assets/scss/layout';

.cardinality {
    width: 5rem;
    min-width: 4rem;
}

.delete-btn {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(100%, -50%);
    cursor: pointer;
}
</style>
