
import Vue from 'vue';
import { mapGetters } from 'vuex';
import { Getters } from '@/store/mutation-types';
import { Modules } from '@/store/store';

// Components
import AbstractTableTile from '@/__new__/features/customerCareSuite/components/AbstractTableTile.vue';

// Helpers
import Subscriber from '@/__new__/services/dno/user/models/Subscriber';
import tableColumnType, { TableColumn } from '@/common/filterTable';
import { USER_MANAGER_HIERARCHY } from '@/__new__/features/customerCare/common/customerCareHelper';

// HTTP
import ossmvneHTTP, { NetworkSpecItem } from '@/__new__/services/dno/ossmvne/http/ossmvne';

export default Vue.extend({
    name: 'NetworkSpecificationTile',
    components: {
        AbstractTableTile,
    },
    props: {},
    data() {
        return {
            details: [] as NetworkSpecItem[],
            apiResponse: {},
        };
    },
    computed: {
        ...mapGetters(Modules.userManagementSubscriber, [Getters.GET_SUBSCRIBER_INFO_BY_ID]),
        subscriber(): Subscriber {
            return new Subscriber(
                Subscriber.remapUserFromBe(this[Getters.GET_SUBSCRIBER_INFO_BY_ID](this.$route.params.id)),
            );
        },
        columnsData(): TableColumn[] {
            return [
                {
                    name: this.$t('generic.name'),
                    key: 'networkSpecificationDataName',
                    forbidHideColumn: true,
                    field: 'networkSpecificationDataName',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$t('generic.value'),
                    key: 'networkSpecificationDataValue',
                    forbidHideColumn: true,
                    field: 'networkSpecificationDataValue',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$t('generic.remarks'),
                    key: 'remarks',
                    forbidHideColumn: true,
                    field: 'remarks',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
            ];
        },
    },
    created() {
        this.$emit('isDataLoadingUpd', false);
    },
    methods: {
        async fetchTileData() {
            this.$emit('isDataLoadingUpd', true);
            await this.$withProgressBar(
                async () => {
                    const remarks = Object.fromEntries(
                        this.$t('customerCareSuite.networkSpecificationTileRemarks').map(
                            (entry: { name: string; remarks: string }) => [entry.name, entry.remarks],
                        ),
                    );
                    const response = await ossmvneHTTP.subscriberNetworkSpecification({
                        msisdn: this.subscriber?.msisdn || '',
                        target_id: this.subscriber?.id || '',
                        target_type: USER_MANAGER_HIERARCHY.SUBSCRIBER,
                    });
                    this.details = response.data?.networkSpecificationDetails.map((item, i) => ({
                        ...item,
                        id: i + 1,
                        remarks: remarks[item.networkSpecificationDataName] || '',
                    }));
                    this.apiResponse = response;
                },
                {
                    errorHandler: () => {
                        this.$alert(this.$t('alertMessage.somethingWentWrongFetchingNecessaryData'));
                    },
                },
            );
            this.$emit('isDataLoadingUpd', false);
        },
    },
});
