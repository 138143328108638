
// Generic
import Vue from 'vue';

// components
import AbstractTableTile from '@/__new__/features/customerCareSuite/components/AbstractTableTile.vue';
import EntityStatusIndicator from '@/components/partials/EntityStatusIndicator.vue';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';

// helpers
import customerCareHttp from '@/__new__/services/dno/user/http/customer-care';
import { ALERT_TYPES } from '@/common/alerts/Alert';
import { ICON_TYPES } from '@/common/iconHelper';
import RouteNames from '@/router/routeNames';
import supportButtonMixin from '@/components/alerts/supportButtonMixin';
import tableColumnType, { type TableColumn } from '@/common/filterTable';
import localeLibrary from '@/common/locale/localeLibrary';
import { STATUS_CODE_TO_STATUS_NAME_MAP, STATUS_INDICATOR_MAP } from '@/common/commonHelper';
import { DnoResponseBase } from '@/http';
import {
    SensitiveTransactionHistory,
    SensitiveTransactionVerificationHistory,
    CHANNEL_STATES_TO_STATUS_NAME_MAP,
    SENSITIVE_TRANSACTIOS_USE_CASE,
    SERVICE_TYPE,
    CHANNEL,
} from '@/__new__/features/customerCareSuite/common/SensitiveTransactionHistoryHelper';
import { SENSITIVE_OPERATION_TYPES } from '@/__new__/features/customerCareSuite/common/endUserAuthorizationHelper';
import { isUserAllowed } from '@/services/permissions/permissions.service';
import * as Sentry from '@sentry/vue';
import { mapGetters } from 'vuex';
import { Modules } from '@/store/store';
import { Getters } from '@/store/mutation-types';
import {
    SUBSCRIBER_STATE,
    TRANSACTION_STATUS_TO_COLOR,
    TRANSACTION_STATUS_TO_LABEL,
    USER_MANAGER_HIERARCHY,
} from '@/__new__/features/customerCare/common/customerCareHelper';
import isEmpty from 'lodash/isEmpty';
import { FLAG_TYPE } from '@/__new__/services/dno/user/models/Flag';
import Subscriber from '@/__new__/services/dno/user/models/Subscriber';

// eslint-disable-next-line no-shadow
enum MSISDN_CHANGES_TABLE {
    CHANGES_HISTORY = 'changesHistory',
    VERIFICATION_HISTORY = 'verificationHistory',
}

export default Vue.extend({
    name: 'MsisdnTransactionHistoryTile',
    components: {
        AbstractTableTile,
        EntityStatusIndicator,
        AppButton,
    },
    mixins: [supportButtonMixin],
    props: {
        userManagerHierarchy: {
            required: true,
            type: Number,
        },
    },
    data() {
        return {
            ICON_TYPES,
            BUTTON_TYPES,
            displayTable: true,
            activeTab: MSISDN_CHANGES_TABLE.CHANGES_HISTORY,
            dataLoading: false,
            msisdnHistory: [] as SensitiveTransactionHistory[],
            msisdnVerificationHistory: [] as SensitiveTransactionHistory[],
            rawResponse: {} as DnoResponseBase,
            rawResponseVerificationHistory: {} as DnoResponseBase,
        };
    },
    computed: {
        ...mapGetters(Modules.customerCareSuite, [Getters.GET_ASSOCIATED_UM_ENTITY_BY_TYPE_AND_ID]),
        subscriberData(): Subscriber {
            return this[Getters.GET_ASSOCIATED_UM_ENTITY_BY_TYPE_AND_ID]({
                targetType: USER_MANAGER_HIERARCHY.SUBSCRIBER,
                targetId: this.$route.params.id,
            });
        },
        isFraud(): boolean {
            return !!this.subscriberData?.flags?.[FLAG_TYPE.IS_FRAUD]?.value;
        },
        isSuspended(): boolean {
            return Boolean(
                this.subscriberData?.flags?.[FLAG_TYPE.SUSPEND_LINE_LOST]?.value ||
                    this.subscriberData?.flags?.[FLAG_TYPE.SUSPEND_LINE_STOLEN]?.value,
            );
        },
        isSubscriberNotActiveState(): boolean {
            return this.subscriberData?.state !== SUBSCRIBER_STATE.ACTIVE;
        },
        isChangeMsisdnButtonDisabled(): boolean {
            return !!(this.isFraud || this.isSuspended || this.isSubscriberNotActiveState);
        },
        changeMsisdnDisabledMessage() {
            if (this.isFraud) {
                return this.$t('customerCareSuite.msisdnTransactionHistoryTile.msisdnChangeDisabled');
            }
            if (this.isSuspended) {
                return this.$t('customerCareSuite.msisdnTransactionHistoryTile.msisdnChangeSuspended');
            }
            if (this.isSubscriberNotActiveState) {
                return this.$t('customerCareSuite.msisdnTransactionHistoryTile.msisdnChangeNotAllowed');
            }
            return '';
        },
        tableKey() {
            return `msisdn-changes-table-${this.activeTab}`;
        },
        tabs() {
            return [
                {
                    id: MSISDN_CHANGES_TABLE.CHANGES_HISTORY,
                    title: this.$i18n.t('customerCareSuite.msisdnTransactionHistoryTile.changesHistory'),
                },
                {
                    id: MSISDN_CHANGES_TABLE.VERIFICATION_HISTORY,
                    title: this.$i18n.t('customerCareSuite.msisdnTransactionHistoryTile.verificationHistory'),
                },
            ];
        },
        formattedData(): SensitiveTransactionHistory[] | SensitiveTransactionVerificationHistory[] {
            if (this.isChangeHistory && !isEmpty(this.msisdnHistory)) {
                return this.msisdnHistory?.map((entity: any) => ({
                    channel: entity.channel,
                    channelLabel: CHANNEL_STATES_TO_STATUS_NAME_MAP.get(entity.channel),
                    startTime: entity.start_time,
                    endTime: entity.end_time,
                    targetId: entity.target_id,
                    status: entity.status,
                    statusLabel: STATUS_CODE_TO_STATUS_NAME_MAP.get(entity.status) || '',
                }));
            }
            if (this.isVerificationHistory && !isEmpty(this.msisdnVerificationHistory)) {
                return this.msisdnVerificationHistory
                    ?.filter(el => el?.properties?.transaction_type === SENSITIVE_TRANSACTIOS_USE_CASE.CHANGE_MSISDN)
                    .map(el => {
                        return {
                            time: el.event_time,
                            channel: CHANNEL_STATES_TO_STATUS_NAME_MAP.get(
                                el?.properties?.portal_id ? CHANNEL.PORTAL : CHANNEL.CLIENT,
                            ),
                            method: el?.properties?.method || '',
                            status: el.event_type,
                            id: el?.properties?.external_id || '',
                            userId: el?.identifier || '',
                            portalUserId: el?.properties?.portal_id || '',
                        };
                    });
            }
            return [];
        },
        isChangeHistory() {
            return this.activeTab === MSISDN_CHANGES_TABLE.CHANGES_HISTORY;
        },
        isVerificationHistory() {
            return this.activeTab === MSISDN_CHANGES_TABLE.VERIFICATION_HISTORY;
        },
        apiResponse() {
            if (this.isChangeHistory) {
                return this.rawResponse;
            }
            if (this.isVerificationHistory) {
                return this.rawResponseVerificationHistory;
            }
            return {};
        },
        columnsData() {
            if (this.isChangeHistory) {
                return this.changesHistoryColumnsData;
            }
            if (this.isVerificationHistory) {
                return this.changesVerificationHistoryColumnsData;
            }
            return [];
        },
        changesHistoryColumnsData(): TableColumn[] {
            return [
                {
                    name: this.$i18n.t('generic.startTime'),
                    key: 'startTime',
                    mapper: (entity: SensitiveTransactionHistory) =>
                        localeLibrary.getFormattedDateAndTime(entity.startTime),
                    sortBy: (entity: SensitiveTransactionHistory) => entity.startTime,
                    field: 'startTime',
                    filterType: tableColumnType.DATE,
                },
                {
                    name: this.$i18n.t('generic.endTime'),
                    key: 'endTime',
                    mapper: (entity: SensitiveTransactionHistory) =>
                        localeLibrary.getFormattedDateAndTime(entity.endTime),
                    sortBy: (entity: SensitiveTransactionHistory) => entity.endTime,
                    field: 'endTime',
                    filterType: tableColumnType.DATE,
                },
                {
                    name: this.$i18n.t('generic.channel'),
                    key: 'channelLabel',
                    field: 'channelLabel',
                    filterType: tableColumnType.TEXT_LIMITED_OPTIONS,
                    limitedOptions: [
                        ...new Set(this.formattedData.map((e: SensitiveTransactionHistory) => e.statusLabel)),
                    ],
                },
                {
                    name: this.$i18n.t('generic.status'),
                    key: 'status',
                    field: 'statusLabel',
                    filterType: tableColumnType.TEXT_LIMITED_OPTIONS,
                    limitedOptions: [
                        ...new Set(this.formattedData.map((e: SensitiveTransactionHistory) => e.statusLabel)),
                    ],
                },
                {
                    name: this.$i18n.t('generic.targetEntity'),
                    key: 'targetId',
                    field: 'targetId',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
            ];
        },
        changesVerificationHistoryColumnsData(): TableColumn[] {
            return [
                {
                    name: this.$i18n.t('generic.time'),
                    key: 'timeFormatted',
                    mapper: entity => this.$localeLibrary.getFormattedDateAndTime(entity.time),
                    sortBy: entity => entity.time,
                    field: 'time',
                    filterType: tableColumnType.DATE,
                },
                {
                    key: 'channel',
                    name: this.$i18n.t('generic.channel'),
                    field: 'channel',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    key: 'method',
                    name: this.$i18n.t('generic.method'),
                    field: 'method',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    key: 'status',
                    name: this.$i18n.t('generic.status'),
                    field: 'status',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    key: 'id',
                    name: this.$i18n.t('generic.id'),
                    field: 'id',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    key: 'userId',
                    name: this.$i18n.t('customerCare.userId'),
                    field: 'userId',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    key: 'portalUserId',
                    name: this.$i18n.t('generic.portalUserId'),
                    field: 'portalUserId',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
            ];
        },
        stateMam() {
            if (this.isChangeHistory) {
                return STATUS_CODE_TO_STATUS_NAME_MAP;
            }
            if (this.isVerificationHistory) {
                return TRANSACTION_STATUS_TO_LABEL;
            }
            return [];
        },
        stateMapColor() {
            if (this.isChangeHistory) {
                return STATUS_INDICATOR_MAP;
            }
            if (this.isVerificationHistory) {
                return TRANSACTION_STATUS_TO_COLOR;
            }
            return [];
        },
    },
    async created() {
        await this.fetchTileData();
    },
    methods: {
        isUserAllowed,
        async fetchTileData() {
            try {
                this.$emit('isDataLoadingUpd', true);
                this.$Progress.start();
                this.dataLoading = true;

                this.rawResponse = await customerCareHttp.getSimSensitiveTransactionHistory(
                    this.$route.params.id,
                    this.userManagerHierarchy,
                    SENSITIVE_TRANSACTIOS_USE_CASE.CHANGE_MSISDN,
                );
                this.msisdnHistory = Array.isArray(this.rawResponse?.data?.transactions)
                    ? this.rawResponse?.data?.transactions
                    : [];

                this.rawResponseVerificationHistory = await customerCareHttp.fetchServiceBlockerHistory(
                    this.subscriberData?.userId,
                    USER_MANAGER_HIERARCHY.USER,
                    SERVICE_TYPE.END_USER_AUTHORIZATION,
                );
                this.msisdnVerificationHistory = !isEmpty(this.rawResponseVerificationHistory?.data?.otp_attempts)
                    ? this.rawResponseVerificationHistory.data.otp_attempts
                    : [];

                this.$Progress.finish();
            } catch (error) {
                this.$Progress.fail();
                this.showSupportAlert(
                    this.$i18n.t('alertMessage.somethingWentWrongFetchingNecessaryData'),
                    ALERT_TYPES.error,
                );
                this.rawResponse = error.response;
                Sentry.captureException(error);
            } finally {
                this.dataLoading = false;
                this.$emit('isDataLoadingUpd', false);
            }
        },
        changeMsisdn() {
            this.$router.push({
                name: RouteNames.END_USER_AUTHORIZATION,
                params: {
                    targetId: this.subscriberData?.accountId || '',
                    targetType: USER_MANAGER_HIERARCHY.ACCOUNT,
                    operationType: SENSITIVE_OPERATION_TYPES.CHANGE_MSISDN,
                    operationTargetType: USER_MANAGER_HIERARCHY.SUBSCRIBER,
                    operationTargetId: this.$route.params.id,
                },
            });
        },
        onTabSelected(id) {
            this.activeTab = id;
            this.forceRerender();
        },
        async forceRerender() {
            // Seems like table sets columns only on created or so
            this.displayTable = false;
            await this.$nextTick();
            this.displayTable = true;
        },
    },
});
