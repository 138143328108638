<template>
    <OperateApisListPageLayout
        :pageTitle="$i18n.t('operateAPIs.applications')"
        :entityType="OPERATE_APIS_TYPES.APPLICATION"
        :entityResponse="applicationsApiResponse"
        :entityData="entityData"
        :addButtonLabel="$i18n.t('operateAPIs.addNewApplication')"
        :writePermission="isUserAllowed('OperateAPIsApplicationsWrite')"
        :addNewRouteName="addNewRouteName"
        :tabs="tabs"
        :defaultTabId="defaultTabId"
        @onSelectEntity="onSelectApplication"
        @onEditEntity="onEditEntity"
        @onSelectPartyRole="onSelectPartyRole"
        @onTabChange="selectedTabId = $event"
    >
        <template #sidebarHeader>
            <OverviewHeaderV2
                v-if="selectedApplication"
                :entityName="selectedApplication.name"
                :entityId="selectedApplication.id"
            >
                <template #statusIndicator>
                    <EntityStatusIndicator
                        :status="selectedApplication.approvalStatus"
                        :stateMap="APPROVAL_STATUS_TO_LABEL_MAPPING"
                        :stateMapColor="APPROVAL_STATUS_TO_COLOR_MAPPING"
                    />
                </template>
            </OverviewHeaderV2>
        </template>
        <template #sidebarOverview>
            <AppApprovalBlock
                v-if="selectedApplication && selectedApplication.approvalStatus === ApprovalStatusType.PENDING_APPROVAL"
                :confirmRejectionMessage="
                    $i18n.t('operateAPIs.rejectionIsFinal', {
                        entity: $i18n.t('operateAPIs.application'),
                    })
                "
                :disabled="
                    !selectedApplication.applicationOwner ||
                    selectedApplication.applicationOwner.approvalStatus !== ApprovalStatusType.APPROVED
                "
                @approve="updateApprovalStatus(ApprovalStatusType.APPROVED, $event)"
                @reject="updateApprovalStatus(ApprovalStatusType.REJECTED, $event)"
            >
                <template
                    v-if="!loadingApplicationOwner"
                    #description
                >
                    <span v-if="!selectedApplication.applicationOwner">
                        {{ $i18n.t('operateAPIs.errorCouldNotDetermineIfApplicationOwnerIsApproved') }}
                    </span>
                    <span
                        v-else-if="selectedApplication.applicationOwner.approvalStatus === ApprovalStatusType.APPROVED"
                    >
                        {{
                            $i18n.t('operateAPIs.thisEntityRequiredApproval', {
                                entity: $i18n.t('operateAPIs.application'),
                            })
                        }}
                    </span>
                    <router-link
                        v-else
                        :to="{
                            name: RouteNames.OPERATE_APIS_APPLICATION_OWNER_VIEW_PAGE,
                            params: {
                                id: selectedApplication.applicationOwner.id,
                                channelPartnerId: selectedApplication.applicationOwner.channelPartnerId,
                            },
                        }"
                        target="_blank"
                    >
                        {{ $i18n.t('operateAPIs.applicationOwnerForApplicationNotApproved') }}
                    </router-link>
                </template>
            </AppApprovalBlock>
            <div v-if="selectedTabId === 'general'">
                <template v-if="isUserInternal()">
                    <h2 class="lf-table-title mb-2">
                        {{ $i18n.t('operateAPIs.relatedExecutions') }}
                    </h2>
                    <AppTable
                        :entities="executionsForEntity"
                        :columnsData="tableColumnConfig"
                        :isDataLoading="executionsTableLoading"
                        :canSelectColumns="true"
                    >
                        <template #executionId="{ entity }">
                            <div
                                v-if="entity.executionId"
                                class="truncate-text"
                            >
                                <router-link
                                    :to="{
                                        name: RouteNames.ORCHESTRATION_ENGINE_EXECUTION_DETAILS,
                                        params: {
                                            id: 'create_application',
                                            execId: entity.executionId,
                                        },
                                    }"
                                    target="_blank"
                                >
                                    {{ entity.executionId }}
                                </router-link>
                            </div>
                        </template>
                        <template #status="{ entity }">
                            <ExecutionStatusIndicator
                                v-if="entity.status"
                                :status="entity.status"
                            />
                        </template>
                        <template #customRowButtons="{ entity }">
                            <IconButton
                                v-if="
                                    entity.lastState === 'register_new_application' &&
                                    entity.status === EXECUTION_STATES.WAITINGFORCALLBACK
                                "
                                :label="$i18n.t('operateAPIs.submitCredentials')"
                                :icon="ICON_TYPES.REGENERATE"
                                class="mr-2"
                                @iconClick="openSubmitCallbackApplicationModal(entity)"
                            />
                        </template>
                    </AppTable>
                </template>
                <template v-if="Object.keys(lastMappedExecution).length">
                    <AppButton
                        :buttonType="BUTTON_TYPES.SECONDARY"
                        :label="$i18n.t('operateAPIs.submitCredentials')"
                        @click="openSubmitCallbackApplicationModal(lastMappedExecution)"
                    />
                </template>
                <AppOverviewBlock
                    :items="sidebarDetails"
                    :isRowTitleCounterVisible="false"
                    :maxItemRows="10"
                    class="mb-3"
                />
            </div>
        </template>

        <template #additionalModal>
            <AppDialogV2
                :title="$i18n.t('operateAPIs.submitCredentials')"
                :visible="submitCallbackApplicationModalVisible"
                @close="submitCallbackApplicationModalVisible = false"
            >
                <OverviewList :lists="modalDetails" />

                <AppInputV3
                    v-model="submitApplicationCallbackData.clientId"
                    class="ml-2"
                    :label="$i18n.t('orderResume.clientId')"
                />
                <AppInputV3
                    v-model="submitApplicationCallbackData.clientSecret"
                    :label="$i18n.t('orderResume.clientSecret')"
                    class="mt-2 ml-2"
                />
                <template #modalFooter>
                    <AppButton
                        :buttonType="BUTTON_TYPES.PRIMARY"
                        :label="$i18n.t('operateAPIs.submitCallback')"
                        :disabled="modalButtonClicked"
                        @click="approveRejectApplication(true)"
                    />
                    <AppButton
                        :buttonType="BUTTON_TYPES.PRIMARY"
                        :label="$i18n.t('operateAPIs.rejectApplication')"
                        :disabled="modalButtonClicked"
                        @click="approveRejectApplication(false)"
                    />
                </template>
            </AppDialogV2>
        </template>
    </OperateApisListPageLayout>
</template>

<script>
// Components
import AppApprovalBlock from '@/components/partials/AppApprovalBlock.vue';
import OperateApisListPageLayout from '@/__new__/features/operateAPIs/OperateApisListPageLayout.vue';
import AppTable from '@/components/partials/AppTable.vue';
import ExecutionStatusIndicator from '@/__new__/features/orchestrationengine/ExecutionStatusIndicator.vue';
import IconButton from '@/components/partials/IconButton.vue';
import AppDialogV2 from '@/components/partials/AppDialogV2.vue';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import AppInputV3 from '@/components/partials/inputs/AppInputV3.vue';
import AppOverviewBlock from '@/components/partials/AppOverviewBlock.vue';
import OverviewHeaderV2 from '@/components/partials/entityOverview/OverviewHeaderV2.vue';
import EntityStatusIndicator from '@/components/partials/EntityStatusIndicator.vue';

// HTTP
import {
    getApplications,
    // getApiTransactions,
    updateApplication,
} from '@/__new__/services/dno/operateAPIs/http/operateAPIs';
import { getApplicationOwnerById } from '@/__new__/features/operateAPIs/common/operateAPIsService';
import { callbackExecution } from '@/__new__/services/dno/orchestrationengine/http/orchestration-engine';

// Helpers
import { isUserAllowed, isUserInternal } from '@/services/permissions/permissions.service';
import RouteNames from '@/router/routeNames';
import tableColumnType from '@/common/filterTable';
import Actions, { Getters } from '@/store/mutation-types';
import { ICON_TYPES } from '@/common/iconHelper';
import { EXECUTION_STATES } from '@/__new__/features/orchestrationengine/common/executionStatusIndicatorHelper';
import { OPERATE_APIS_TYPES } from '@/__new__/features/operateAPIs/common/operateAPIsHelper';
import * as Sentry from '@sentry/vue';
import {
    ApprovalStatusType,
    APPROVAL_STATUS_TO_LABEL_MAPPING,
    APPROVAL_STATUS_TO_COLOR_MAPPING,
} from '@/__new__/features/operateAPIs/common/ApplicationOwner';
// Vuex
import { mapActions, mapGetters } from 'vuex';

export default {
    name: 'ApplicationsListPage',

    components: {
        AppApprovalBlock,
        AppDialogV2,
        AppButton,
        AppOverviewBlock,
        AppTable,
        AppInputV3,
        ExecutionStatusIndicator,
        OperateApisListPageLayout,
        IconButton,
        OverviewHeaderV2,
        EntityStatusIndicator,
    },

    data() {
        return {
            loadingApplicationOwner: false,
            applicationsApiResponse: {},
            entityData: [],
            transactions: [],
            transactionsToDisplay: [],
            addNewRouteName: RouteNames.OPERATE_APIS_APPLICATIONS_EDITOR,
            selectedApplication: null,
            executionsForEntity: [],
            executionsTableLoading: false,
            submitCallbackApplicationModalVisible: false,
            modalButtonClicked: false,
            lastMappedExecution: {},
            selectedChannelPartnerId: '',
            submitApplicationCallbackData: {
                clientId: '',
                clientSecret: '',
                executionId: '',
                planId: '',
            },

            tableColumnConfig: [
                {
                    name: this.$i18n.t('orchestrationEngine.execId'),
                    key: 'executionId',
                    field: 'executionId',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('generic.status'),
                    key: 'status',
                    field: 'status',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
            ],
            defaultTabId: 'general',
            selectedTabId: 'general',
            tabs: [
                {
                    id: 'general',
                    label: this.$i18n.t('generic.general'),
                },
                {
                    id: 'json',
                    label: this.$i18n.t('generic.json'),
                },
            ],
            isUserInternal,
            RouteNames,
            ICON_TYPES,
            EXECUTION_STATES,
            BUTTON_TYPES,
            OPERATE_APIS_TYPES,
            ApprovalStatusType,
            APPROVAL_STATUS_TO_LABEL_MAPPING,
            APPROVAL_STATUS_TO_COLOR_MAPPING,
        };
    },

    computed: {
        ...mapGetters('orchestrationengine', {
            getOEMappedExecutions: Getters.GET_OE_MAPPED_EXECUTIONS,
        }),
        selectedEntityId() {
            return this.selectedApplication ? this.selectedApplication.id : null;
        },
        sidebarDetails() {
            return [
                {
                    isCollapsed: false,
                    name: this.$i18n.t('generic.general'),
                    rows: [
                        {
                            value: this.selectedApplication?.id || this.$i18n.t('generic.empty'),
                            name: this.$i18n.t('generic.id'),
                        },
                        {
                            value: this.selectedApplication?.name || this.$i18n.t('generic.empty'),
                            name: this.$i18n.t('generic.name'),
                        },
                        {
                            value: this.selectedApplication?.commercialName || this.$i18n.t('generic.empty'),
                            name: this.$i18n.t('operateAPIs.commercialName'),
                        },
                        {
                            value: this.selectedApplication?.description || this.$i18n.t('generic.empty'),
                            name: this.$i18n.t('generic.description'),
                        },
                        {
                            value: this.selectedApplication?.channelPartnerId || this.$i18n.t('generic.empty'),
                            name: this.$i18n.t('operateAPIs.channelPartner'),
                        },
                        {
                            value: this.selectedApplication?.applicationOwnerId || this.$i18n.t('generic.empty'),
                            name: this.$i18n.tc('operateAPIs.applicationOwners', 1),
                        },
                        {
                            value: this.selectedApplication?.category || this.$i18n.t('generic.empty'),
                            name: this.$i18n.t('productCatalog.entities.category'),
                        },
                    ],
                },
                {
                    isCollapsed: false,
                    name: this.$i18n.t('generic.state'),
                    rows: [
                        {
                            value: this.selectedApplication?.operationalState || this.$i18n.t('generic.empty'),
                            name: this.$i18n.t('operateAPIs.operationalState'),
                        },
                        {
                            value: this.selectedApplication?.approvalStatus || this.$i18n.t('generic.empty'),
                            name: this.$i18n.t('operateAPIs.approvalStatus'),
                        },
                        {
                            value: this.selectedApplication?.approvalStatusReason || this.$i18n.t('generic.empty'),
                            name: this.$i18n.t('operateAPIs.approvalStatusReason'),
                        },
                    ],
                },
                {
                    isCollapsed: false,
                    name: this.$i18n.t('generic.resources'),
                    rows: [
                        {
                            value: this.selectedApplication?.href || this.$i18n.t('generic.empty'),
                            name: this.$i18n.t('generic.link'),
                        },
                        {
                            value: this.selectedApplication?.privacyPolicyURL || this.$i18n.t('generic.empty'),
                            name: this.$i18n.t('operateAPIs.privacyPolicyUrl'),
                        },
                        {
                            value: this.selectedApplication?.jwksUri || this.$i18n.t('generic.empty'),
                            name: this.$i18n.t('operateAPIs.jwksUri'),
                        },
                    ],
                },
                {
                    isCollapsed: false,
                    name: this.$i18n.t('operateAPIs.redirectUrls'),
                    rows: this.selectedApplication?.redirectUrl.map(redirectUrl => ({
                        name: redirectUrl,
                    })),
                },
                {
                    isCollapsed: false,
                    name: this.$i18n.t('operateAPIs.digitalIdentity'),
                    rows: [
                        {
                            value: this.selectedApplication?.digitalIdentity?.clientId || this.$i18n.t('generic.empty'),
                            name: this.$i18n.t('operateAPIs.clientId'),
                        },
                        {
                            value: this.selectedApplication?.digitalIdentity?.status || this.$i18n.t('generic.empty'),
                            name: this.$i18n.t('generic.status'),
                        },
                        {
                            value:
                                this.$localeLibrary.iso8601ToFormattedDateAndTime(
                                    this.selectedApplication?.digitalIdentity?.validFor?.startDateTime,
                                ) || this.$i18n.t('generic.empty'),
                            name: this.$i18n.t('operateAPIs.startDateTime'),
                        },
                        {
                            value:
                                this.$localeLibrary.iso8601ToFormattedDateAndTime(
                                    this.selectedApplication?.digitalIdentity?.validFor?.endDateTime,
                                ) || this.$i18n.t('generic.empty'),
                            name: this.$i18n.t('operateAPIs.endDateTime'),
                        },
                    ],
                },
            ];
        },
        modalDetails() {
            return [
                {
                    name: this.$i18n.t('generic.general'),
                    properties: [
                        {
                            value: this.selectedApplication?.id || this.$i18n.t('generic.empty'),
                            label: this.$i18n.t('generic.id'),
                        },
                        {
                            value: this.selectedApplication?.name || this.$i18n.t('generic.empty'),
                            label: this.$i18n.t('generic.name'),
                        },
                    ],
                },
            ];
        },
    },

    // TODO: Revert when BE fix it on their side
    // async created() {
    //     await this.$withProgressBar(
    //         async () => {
    //             await getApiTransactions({
    //                 start_timestamp: 0,
    //                 end_timestamp: Math.round(new Date().getTime() / 1000),
    //                 target_id: '61bfb733-7c45-4c75-b8a9-960bfa8444af',
    //                 target_type: 1,
    //             }).then(resp => {
    //                 this.transactions = (resp.data.api_transactions || []).map(transaction => ({
    //                     offerName: transaction?.offer_name || this.$i18n.t('generic.empty'),
    //                     chargingSpecName: transaction?.charging_spec_name || this.$i18n.t('generic.empty'),
    //                     amountUsed: transaction?.amount_used || this.$i18n.t('generic.empty'),
    //                     appId: transaction?.app_id || null,
    //                 }));
    //             });
    //         },
    //         {
    //             errorHandler: () => {
    //                 this.$alert(this.$i18n.t('alertMessage.somethingWentWrongFetchingNecessaryData'));
    //             },
    //         },
    //     );
    // },

    methods: {
        ...mapActions('orchestrationengine', {
            requestOEMappedExecutions: Actions.REQUEST_OE_MAPPED_EXECUTIONS,
        }),
        isUserAllowed,
        onSelectApplication(applicationId) {
            this.$withLoadingSpinner(
                async () => {
                    // TODO: Revert when BE fix it on their side
                    // this.transactionsToDisplay = this.transactions.filter(
                    //     transaction => transaction.appId === applicationId,
                    // );

                    [this.selectedApplication] = this.entityData.filter(data => data.id === applicationId);

                    // Fetch application owner for selected application
                    await this.fetchApplicationOwnerForSelectedApplication();

                    // Reset displayed executions
                    this.executionsForEntity = [];

                    // Set flag for table loading
                    this.executionsTableLoading = true;

                    await this.requestOEMappedExecutions({
                        plan_ids: ['create_application'],
                        mapper_ids: [applicationId],
                    });

                    // Set executions for selected entity
                    this.executionsForEntity = this.getOEMappedExecutions;

                    // Get the latest execution in order to have that data for approve/reject button for non lf admins
                    const filteredRelatedExecutions = this.executionsForEntity.filter(
                        execution =>
                            execution.status === EXECUTION_STATES.WAITINGFORCALLBACK &&
                            execution.lastState === 'register_new_application',
                    );

                    if (filteredRelatedExecutions.length) {
                        this.lastMappedExecution = this.executionsForEntity[this.executionsForEntity.length - 1];
                    }

                    // Reset flag for table loading
                    this.executionsTableLoading = false;
                },
                {
                    errorHandler: () => {
                        this.$alert(this.$t('operateAPIs.somethingWentWrongLoadingApplicationExecutions'));
                    },
                },
            );
        },
        async fetchApplications(partyRoleId) {
            const response = await getApplications(partyRoleId);
            this.applicationsApiResponse = response;

            this.entityData = response.data.map(application => ({
                id: application?.id || '',
                name: application?.name || '',
                description: application?.description || '',
                commercialName: application?.commercialName || '',
                category: application?.category || '',
                operationalState: application?.operationalState || '',
                approvalStatus: application?.approvalStatus || '',
                approvalStatusReason: application?.approvalStatusReason || '',
                clientId: application?.digitalIdentity?.clientId || '',
                redirectUrl:
                    Array.isArray(application?.redirectUrl) && application.redirectUrl.length
                        ? application.redirectUrl
                        : [this.$i18n.t('generic.none')],
                lastUpdateTimestamp: this.$localeLibrary.iso8601ToEpoch(application?.lastUpdate),
                href: application?.href || '',
                channelPartnerId: application?.channelPartner?.id || '',
                applicationOwner: undefined,
                applicationOwnerId: application?.applicationOwner?.id || '',
                privacyPolicyURL: application?.privacyPolicyURL || '',
                logoUrl: application?.logoUrl || '',
                externalIdentifier: application?.externalIdentifier || [],
                jwksUri: application?.jwksUri || '',
                digitalIdentity: application?.digitalIdentity,
            }));
        },
        async fetchApplicationOwnerForSelectedApplication() {
            try {
                this.loadingApplicationOwner = true;
                this.selectedApplication.applicationOwner = await getApplicationOwnerById(
                    this.selectedApplication?.applicationOwnerId,
                    this.selectedChannelPartnerId,
                );
                this.loadingApplicationOwner = false;
            } catch (error) {
                this.loadingApplicationOwner = false;
                Sentry.captureException(
                    new Error(`Error fetching application owner for application '${this.selectedApplication?.name}'`, {
                        cause: error,
                    }),
                );
                this.$alert(
                    this.$t('operateAPIs.errorFetchingApplicationOwnerForApplication', {
                        applicationName: this.selectedApplication?.name,
                    }),
                );
            }
        },
        async updateApprovalStatus(approvalStatus, approvalStatusReason) {
            await this.$withLoadingSpinner(
                async () => {
                    await updateApplication({
                        id: this.selectedApplication.id,
                        payload: {
                            approvalStatus,
                            approvalStatusReason,
                        },
                        channelPartnerId: this.selectedChannelPartnerId,
                    });
                    this.selectedApplication.approvalStatus = approvalStatus;
                    this.selectedApplication.approvalStatusReason = approvalStatusReason;
                    this.$showSuccessAlert({
                        message: this.$i18n.t('operateAPIs.successfullyUpdatedApplication'),
                    });
                },
                {
                    errorHandler: () => {
                        this.$alert(this.$i18n.t('operateAPIs.somethingWentWrongUpdatedApplication'));
                    },
                },
            );
        },
        async onEditEntity(entity) {
            await this.$withProgressBar(
                async () => {
                    await updateApplication({
                        id: entity.id,
                        payload: {
                            approvalStatus: entity.selectedApprovalOption,
                            ...(entity.approvalStatusReason && { approvalStatusReason: entity.approvalStatusReason }),
                        },
                        channelPartnerId: this.selectedChannelPartnerId,
                    });

                    this.$showSuccessAlert({
                        message: this.$i18n.t('operateAPIs.successfullyUpdatedApplication'),
                    });

                    this.$router.go();
                },
                {
                    errorHandler: () => {
                        this.$alert(this.$i18n.t('operateAPIs.somethingWentWrongUpdatedApplication'));
                    },
                },
            );
        },
        onSelectPartyRole(id) {
            this.$withLoadingSpinner(
                async () => {
                    await this.fetchApplications(id);
                    this.selectedChannelPartnerId = id;
                },
                {
                    errorHandler: () => {
                        this.$alert(this.$t('operateAPIs.somethingWentWrongFetchingApplications'));
                    },
                },
            );
        },
        openSubmitCallbackApplicationModal(entity) {
            this.submitApplicationCallbackData = {
                ...this.submitApplicationCallbackData,
                executionId: entity.executionId,
                planId: entity.planId,
            };

            this.submitCallbackApplicationModalVisible = true;
        },
        approveRejectApplication(approved) {
            this.$withProgressBar(
                async () => {
                    this.modalButtonClicked = true;
                    const { executionId, planId, clientId, clientSecret } = this.submitApplicationCallbackData;

                    await callbackExecution({
                        execution_id: executionId,
                        plan_id: planId,
                        client_id: clientId,
                        client_secret: clientSecret,
                        approved,
                    });

                    this.$router.go();
                    this.modalButtonClicked = false;
                },
                {
                    errorHandler: () => {
                        this.$alert(this.$t('operateAPIs.somethingWentWrongApproveRejectApplication'));
                        this.modalButtonClicked = false;
                    },
                },
            );
        },
    },
};
</script>
