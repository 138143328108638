<template>
    <div class="pb-4">
        <div class="notifications-add-wrapper">
            <AppMultiselectV3
                v-model="value.selectedThresholdChargingSpecification"
                :options="chargingSpecsAvailableForUsageNotifications"
                :multiple="false"
                :showLabels="false"
                :isSmall="true"
                :additionalLabel="$i18n.t('productCatalog.products.editor.configureNotifications')"
                :error="errors && errors.selectedThresholdChargingSpecification.$error"
                :disabled="disabled"
                label="name"
                trackBy="id"
            />
            <AppButton
                :label="$i18n.t('generic.add')"
                :iconType="ICON_TYPES.PLUS"
                :isSmall="true"
                :disabled="disabledAddThreshold || disabled"
                class="ml-3"
                @click="addChargingSpecToThresholdList"
            />
        </div>

        <div
            v-for="(cs, csId) in value.thresholds"
            :key="csId"
            class="threshold-group"
        >
            <div class="inner-layout">
                <span class="label">
                    {{ $i18n.t('generic.name') }}
                </span>
                <span class="text-label">
                    {{ getChargingSpec(csId).name }}
                </span>
                <span
                    :class="['delete-btn', { 'disabled-area': disabled }]"
                    @click="removeChargingSpecFromThreshold(csId)"
                >
                    <img
                        src="@/assets/icons/close-gray.svg"
                        alt="close-icon"
                    />
                </span>
            </div>

            <!-- Threshold list -->
            <div
                v-for="(threshold, index) in value.thresholds[csId]"
                :key="threshold.id"
                class="section-content threshold my-3"
            >
                <div class="inner-layout">
                    <AppInputV3
                        v-model.number="threshold.value"
                        :label="$i18n.t('productCatalog.products.editor.thresholdNotifyWhen')"
                        :min="0"
                        :max="100"
                        :invalid="isThresholdValid(csId, index)"
                        :disabled="disabled"
                        class="px-1"
                        type="number"
                    />
                    <span class="text-label">
                        {{ $i18n.t('productCatalog.products.editor.thresholdPercentReached') }}
                    </span>
                    <span
                        :class="['delete-btn', { 'disabled-area': disabled }]"
                        @click="removeValueFromThreshold(csId, index)"
                    >
                        <img
                            src="@/assets/icons/close-gray.svg"
                            alt="close-icon"
                        />
                    </span>
                </div>
                <AppTextareaV3
                    v-if="useMessageField"
                    v-model="threshold.message"
                    :label="$i18n.t('productCatalog.products.editor.thresholdMessage')"
                    :invalid="!threshold.message"
                    :disabled="disabled"
                    class="inner-layout"
                />
            </div>

            <div class="inner-layout">
                <AppButton
                    :buttonType="BUTTON_TYPES.PRIMARY"
                    :iconType="ICON_TYPES.PLUS"
                    :disabled="disabled"
                    label="Add Threshold"
                    @click="addThresholdToChargingSpec(csId)"
                />
            </div>
        </div>
    </div>
</template>

<script>
// Vuex
import { mapGetters } from 'vuex';
import { Getters } from '@/store/mutation-types';

// Components
import AppMultiselectV3 from '@/components/partials/inputs/AppMultiselectV3.vue';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import { ICON_TYPES } from '@/common/iconHelper';
import AppInputV3 from '@/components/partials/inputs/AppInputV3.vue';
import AppTextareaV3 from '@/components/partials/inputs/AppTextareaV3.vue';

// Helpers
import Threshold from '@/__new__/services/dno/charging/common/threshold';
import { getMultiLangFieldValueByLocale } from '@/common/entities/entityHelper';
import {
    CHARGING_TYPE,
    CHARGING_TYPE_OPTIONS,
} from '@/__new__/services/dno/charging/common/chargingSpecificationHelper';

export default {
    name: 'UsageNotifications',
    components: {
        AppMultiselectV3,
        AppButton,
        AppInputV3,
        AppTextareaV3,
    },
    props: {
        value: {
            type: Object,
            default: null,
        },
        useMessageField: {
            type: Boolean,
            default: true,
        },
        errors: {
            type: Object,
            default: () => undefined,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            ICON_TYPES,
            BUTTON_TYPES,
        };
    },
    computed: {
        ...mapGetters('charging', [Getters.GET_CHARGING_SPECIFICATION_BY_ID, Getters.GET_CHARGING_SPECIFICATIONS]),
        chargingSpecsAvailableForUsageNotifications() {
            return this.value.selectedChargingSpecifications.filter(cs =>
                this.isChargingSpecAvailableForUsageNotifications(
                    this[Getters.GET_CHARGING_SPECIFICATION_BY_ID](cs.id),
                ),
            );
        },
        realChargingSpecs() {
            return this.formatChargingSpecsForDropdown(this[Getters.GET_CHARGING_SPECIFICATIONS]);
        },
        disabledAddThreshold() {
            return (
                !this.chargingSpecsAvailableForUsageNotifications.length ||
                this.value.selectedThresholdChargingSpecification === null ||
                Array.isArray(this.value.selectedThresholdChargingSpecification)
            );
        },
    },
    methods: {
        isChargingSpecAvailableForUsageNotifications(cs) {
            return (
                cs.data.charge_type === CHARGING_TYPE_OPTIONS[CHARGING_TYPE.quota] &&
                !Object.keys(this.value.thresholds).includes(cs.id)
            );
        },
        addChargingSpecToThresholdList() {
            this.$set(this.value.thresholds, this.value.selectedThresholdChargingSpecification.id, [
                new Threshold(0, ''),
            ]);
            this.$set(this.value, 'selectedThresholdChargingSpecification', []);
        },
        getChargingSpec(id) {
            return this.realChargingSpecs.find(s => s.id === id);
        },
        formatChargingSpecsForDropdown(chargingSpecs) {
            return chargingSpecs.map(cs => ({
                id: cs.id,
                name: getMultiLangFieldValueByLocale(cs.data.name),
            }));
        },
        removeChargingSpecFromThreshold(id) {
            this.$delete(this.value.thresholds, id);
        },
        removeValueFromThreshold(csId, index) {
            this.$delete(this.value.thresholds[csId], index);
        },
        addThresholdToChargingSpec(id) {
            this.value.thresholds[id].push(new Threshold(0, ''));
        },
        isThresholdValid(csId, index) {
            return this.errors?.thresholds?.$each?.[csId]?.$each?.[index]?.value?.$error;
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/palette';
@import '~@/assets/scss/icons';

.section-content {
    padding: 12px 0 12px 48px;
}

.inner-layout {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 8px 0;

    .label {
        flex: 0 1 200px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 0.875rem;
        color: $gray60;

        &.disabled {
            color: $gray30;
        }
    }
}

.threshold-group,
.threshold {
    position: relative;

    &:hover {
        > .inner-layout > .delete-btn {
            opacity: 1;
        }
    }
}

.threshold {
    background-color: $dirty-white;
}

.text-label {
    font-size: 0.875rem;
    line-height: 26px;
    color: $gray60;
}

.delete-btn {
    cursor: pointer;
    position: absolute;
    top: 16px;
    right: 26px;
    transform: translateY(-50%);
    opacity: 0;
}

.notifications-add-wrapper {
    display: flex;
    align-items: flex-end;
}
</style>
