
import Vue from 'vue';

import AppInfoBlock from '@/components/partials/AppInfoBlock.vue';
import ValidationMethodChecker from '@/__new__/features/customerCareSuite/components/ValidationMethodChecker.vue';
import TMOIDLinked from '@/__new__/features/customerCareSuite/components/TMOIDLinked.vue';

import { Getters } from '@/store/mutation-types';
import { mapGetters } from 'vuex';
import { Modules } from '@/store/store';

import { USER_MANAGER_HIERARCHY } from '@/__new__/features/customerCare/common/customerCareHelper';
import { IS_PIN_SETUP } from '@/__new__/services/dno/user/models/User';

export default Vue.extend({
    name: 'TMOIDInfoTile',
    components: {
        AppInfoBlock,
        ValidationMethodChecker,
        TMOIDLinked,
    },
    computed: {
        ...mapGetters(Modules.customerCareSuite, [Getters.GET_ASSOCIATED_UM_ENTITY_BY_TYPE_AND_ID]),
        userInfo() {
            return this[Getters.GET_ASSOCIATED_UM_ENTITY_BY_TYPE_AND_ID]({
                targetType: USER_MANAGER_HIERARCHY.USER,
                targetId: this.$route.params.id,
            });
        },
        fields() {
            return [
                {
                    name: this.$i18n.t('customerCareSuite.tmoIdLinked'),
                    key: 'tmoIdLinked',
                },
                {
                    name: this.$i18n.t('generic.email'),
                    value: this.userInfo?.externalEmail || '',
                },
                {
                    name: this.$i18n.t('customerCareSuite.phoneNumber'),
                    value: this.userInfo?.externalMSISDN || '',
                },
                {
                    name: this.$i18n.t('customerCareSuite.validationMethod'),
                    key: 'validationMethod',
                },
                {
                    name: this.$i18n.t('customerCareSuite.isPinEnabled'),
                    value:
                        this.userInfo?.flags?.is_pin_setup?.originalValue === IS_PIN_SETUP.PIN
                            ? this.$i18n.t('generic.yes')
                            : this.$i18n.t('generic.no'),
                },
                {
                    name: this.$i18n.t('customerCareSuite.phoneNumberForOtp'),
                    value: this.userInfo?.notificationMSISDN || '',
                },
                {
                    name: this.$i18n.t('customerCareSuite.tmoWirelessNumber'),
                    value: this.userInfo?.isTmoUser ? this.$i18n.t('generic.yes') : this.$i18n.t('generic.no'),
                },
            ];
        },
    },
    created() {
        this.$emit('isDataLoadingUpd', false);
    },
});
