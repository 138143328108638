<template>
    <AppDialog
        :visible="value"
        maxWidth="70%"
        maxHeight="calc(100vh - 120px)"
        :overflowAuto="false"
        class="dialog-wrapper"
        @input="emitValue"
    >
        <div :class="['editor-wrapper', 'pb-4', 'mt-4', { 'overflow-auto': promotionRule }]">
            <!-- Rule selection section-->
            <div>
                <h2 class="section-title">
                    {{ $i18n.t('benefits.ruleSelection') }}
                </h2>
                <!--Temporary disabled because API didn`t receive grant_type parameter now-->
                <AppMultiselectV3
                    v-model="promotionRuleType"
                    trackBy="key"
                    :options="Object.values(GRANT_TYPES)"
                    label="label"
                    :small="true"
                    :additionalLabel="$i18n.t('benefits.promotionRuleType')"
                    :placeholder="$i18n.t('promotions.chooseType')"
                    :disabled="true"
                    class="input-width mb-3"
                    @input="setFieldsToDefaultValues"
                />
                <AppMultiselectV3
                    v-model="promotionRule"
                    trackBy="id"
                    :options="getFormattedPromotionRules"
                    label="label"
                    :small="true"
                    :additionalLabel="$i18n.t('benefits.promotionRuleName')"
                    :placeholder="$i18n.t('benefits.chooseRule')"
                    :disabled="isEditing"
                    class="input-width mb-3"
                    data-test-id="promotionList"
                    @input="setDefaultFieldsFromPromotion"
                />
            </div>
            <!-- General section -->
            <div v-if="promotionRule">
                <h2 class="section-title">
                    {{ $i18n.t('generic.general') }}
                </h2>

                <div class="mb-3">
                    <div class="d-flex mb-1 align-items-center">
                        <span class="lf-labels">{{ $i18n.t('generic.name') }}</span>
                        <AppToggleV2
                            v-model="overwrite.name.flag"
                            :small="true"
                            class="ml-2 pb-1"
                            data-test-id="toggle-overview-name"
                            @input="toggleOverwrite('name')"
                        />
                    </div>
                    <AppInputV3
                        v-model="name"
                        class="input-max-width"
                        :disabled="!overwrite.name.flag"
                        data-test-id="input-name"
                        :placeholder="$i18n.t('promotions.addPromotionName')"
                    />
                </div>

                <div class="mb-3">
                    <div class="d-flex mb-1 align-items-center">
                        <span class="lf-labels">{{ $i18n.t('generic.description') }}</span>
                        <AppToggleV2
                            v-model="overwrite.description.flag"
                            :small="true"
                            class="ml-2 pb-1"
                            @input="toggleOverwrite('description')"
                        />
                    </div>
                    <AppTextareaV3
                        v-model="description"
                        :disabled="!overwrite.description.flag"
                        :placeholder="$i18n.t('promotions.addPromotionDescription')"
                        class="input-max-width"
                    />
                </div>
                <div class="input-label display-flex align-items-center mt-3">
                    {{ $i18n.t('promotions.promotionShortDescription') }}
                    <AppTooltip
                        :tooltipPosition="TOOLTIP_POSITION.right"
                        class="tooltip-container ml-2"
                    >
                        <template #label>
                            <span class="info-icon mr-2" />
                        </template>

                        <template #content>
                            <div class="tooltip-inner-content ml-3 pb-3">
                                <p class="primary-text font-weight-normal mb-2">
                                    {{ $i18n.t('promotions.promotionShortDescriptionTooltip') }}
                                </p>
                                <p class="primary-text font-weight-normal mb-0">
                                    {{ $i18n.t('promotions.promotionShortDescriptionTooltipEx') }}
                                </p>
                            </div>
                        </template>
                    </AppTooltip>
                    <AppToggleV2
                        v-model="overwrite.promoShortDescription.flag"
                        :small="true"
                        class="ml-2 pb-1"
                        @input="toggleOverwrite('promoShortDescription')"
                    />
                </div>
                <AppInputV3
                    v-model="promoShortDescription"
                    class="input-max-width mt-2"
                    :disabled="!overwrite.promoShortDescription.flag"
                    :placeholder="$i18n.t('promotions.promotionShortDescriptionInfo')"
                />

                <div class="input-label display-flex align-items-center mt-3">
                    {{ $i18n.t('promotions.benefitShortDescription') }}
                    <AppTooltip
                        :tooltipPosition="TOOLTIP_POSITION.right"
                        class="tooltip-container ml-2"
                    >
                        <template #label>
                            <span class="info-icon mr-2" />
                        </template>

                        <template #content>
                            <div class="tooltip-inner-content ml-3 pb-3">
                                <p class="primary-text font-weight-normal mb-2">
                                    {{ $i18n.t('promotions.benefitShortDescriptionTooltip') }}
                                </p>
                                <p class="primary-text font-weight-normal mb-0">
                                    {{ $i18n.t('promotions.benefitShortDescriptionTooltipEx') }}
                                </p>
                            </div>
                        </template>
                    </AppTooltip>
                    <AppToggleV2
                        v-model="overwrite.benefitShortDescription.flag"
                        :small="true"
                        class="ml-2 pb-1"
                        @input="toggleOverwrite('benefitShortDescription')"
                    />
                </div>
                <AppInputV3
                    v-model="benefitShortDescription"
                    class="input-max-width mt-2"
                    :disabled="!overwrite.benefitShortDescription.flag"
                    :placeholder="$i18n.t('promotions.benefitShortDescriptionInfo')"
                />
                <div class="mt-3">
                    <div class="d-flex mb-1 align-items-center">
                        <span class="lf-labels">{{ $i18n.t('promotions.termsAndConditions') }}</span>
                        <AppToggleV2
                            v-model="overwrite.termsAndConditions.flag"
                            :small="true"
                            class="ml-2 pb-1"
                            @input="toggleOverwrite('termsAndConditions')"
                        />
                    </div>
                    <AppTextareaV3
                        v-model="termsAndConditions"
                        :disabled="!overwrite.termsAndConditions.flag"
                        :placeholder="$i18n.t('promotions.termsAndConditionsPlaceholder')"
                        class="input-max-width"
                    />
                </div>
            </div>
            <!-- Promotion Benefit Section -->
            <div v-if="promotionRule">
                <h2 class="section-title">
                    {{ $i18n.t('promotions.promotionBenefit') }}
                </h2>

                <div class="input-label display-flex align-items-center mb-2 mt-3">
                    {{ $i18n.t('generic.type') }}
                </div>
                <AppMultiselectV3
                    v-model="promotionType"
                    trackBy="key"
                    :options="Object.values(PROMOTION_TYPES)"
                    label="label"
                    :small="true"
                    :disabled="true"
                    :placeholder="$i18n.t('promotions.chooseType')"
                    class="input-width mb-3"
                />

                <div
                    v-if="promotionType"
                    class="mt-3"
                >
                    <div class="d-flex mb-1 align-items-center">
                        <span class="lf-labels">{{ $i18n.t('promotions.discountAmount') }}</span>
                        <AppToggleV2
                            v-model="overwrite.amount.flag"
                            :small="true"
                            class="ml-2 pb-1"
                            @input="toggleOverwrite('amount')"
                        />
                    </div>
                    <div class="d-flex mb-3">
                        <AppInputV3
                            v-model="amount"
                            type="number"
                            :disabled="!overwrite.amount.flag"
                            :placeholder="getAmountPlaceholder"
                            class="input-width"
                        />
                        <span class="amount-label all-caps-text ml-2">{{ getAmountLabel }}</span>
                    </div>
                </div>
            </div>
            <!-- Promotion Parameters section -->
            <div v-if="promotionRule">
                <h2 class="section-title">
                    {{ $i18n.t('promotions.promotionParameters') }}
                </h2>

                <div
                    v-if="!isManualGrantType"
                    class="input-label mb-1"
                >
                    {{ $i18n.t('generic.startTime') }}
                </div>
                <DateTimePicker
                    v-if="!isManualGrantType"
                    v-model="startTime"
                    :disabled="true"
                    type="datetime"
                    class="mb-3"
                />

                <div
                    v-if="!isManualGrantType"
                    class="input-label mb-1"
                >
                    {{ $i18n.t('generic.endTime') }}
                </div>
                <DateTimePicker
                    v-if="!isManualGrantType"
                    v-model="endTime"
                    :disabled="true"
                    type="datetime"
                />
                <p
                    v-if="!isManualGrantType"
                    class="small-gray-date-label mt-2"
                >
                    {{ $i18n.t('promotions.timezoneExplanation') }}
                </p>

                <div class="mt-3 mb-3">
                    <div class="d-flex mb-1 align-items-center">
                        <span class="lf-labels">Stackable promotion</span>
                        <AppToggleV2
                            v-model="stackable"
                            :small="true"
                            :disabled="true"
                            class="ml-2 pb-1"
                        />
                    </div>
                    <p class="small-gray-date-label mt-2">
                        Stackable functionality is in development and will be made available in a future release
                    </p>
                </div>

                <TagsMultiselect
                    v-model="offersForDiscount"
                    :options="availableOffersForDiscount"
                    :additionalLabel="$i18n.t('promotions.applicableTo')"
                    :disabled="true"
                    :small="true"
                    :multiple="true"
                    :label="'label'"
                    :labelFormatter="offer => offer.label"
                    class="input-max-width mb-2"
                    :explanation="$i18n.t('promotions.selectOfferExplanation')"
                    :placeholder="$i18n.t('promotions.chooseOffers')"
                />

                <AppInputV3
                    v-model="recurrenceCount"
                    type="number"
                    :label="$i18n.t('benefits.recurrenceCount')"
                    :placeholder="$i18n.t('benefits.inputRecurrenceCount')"
                    :disabled="true"
                    class="input-width mb-2"
                />
                <div class="mt-3">
                    <div class="d-flex mb-1 align-items-center">
                        <span class="lf-labels">{{ $i18n.t('benefits.recurrenceLimit') }}</span>
                        <AppTooltip
                            :tooltipPosition="TOOLTIP_POSITION.right"
                            class="tooltip-container ml-2"
                        >
                            <template #label>
                                <span class="info-icon mr-2" />
                            </template>

                            <template #content>
                                <div class="tooltip-inner-content ml-3 pb-3">
                                    <p class="primary-text font-weight-normal mb-2">
                                        {{ $i18n.t('benefits.recurrenceLimitDescriptionTooltip') }}
                                    </p>
                                    <p class="primary-text font-weight-normal mb-0">
                                        {{ $i18n.t('benefits.recurrenceLimitDescriptionTooltipEx') }}
                                    </p>
                                </div>
                            </template>
                        </AppTooltip>
                        <AppToggleV2
                            v-model="overwrite.recurrenceLimit.flag"
                            :small="true"
                            class="ml-2 pb-1"
                            @input="toggleOverwrite('recurrenceLimit')"
                        />
                    </div>
                    <div class="d-flex mb-3">
                        <AppInputV3
                            v-model="recurrenceLimit"
                            type="number"
                            :placeholder="$i18n.t('benefits.inputRecurrenceLimit')"
                            :disabled="!overwrite.recurrenceLimit.flag"
                            class="input-width mb-2"
                        />
                    </div>
                </div>
            </div>
        </div>
        <div class="editor-controls-wrapper">
            <div class="editor-controls-content">
                <AppButton
                    :label="$t('generic.cancel')"
                    class="mr-5"
                    data-test-id="cancel-button"
                    @click="onCancel"
                />
                <AppButton
                    :label="$t('generic.save')"
                    :buttonType="BUTTON_TYPES.PRIMARY"
                    :iconType="ICON_TYPES.CHECK"
                    :disabled="!promotionRule"
                    data-test-id="save-button"
                    @click="onSave"
                />
            </div>
        </div>
    </AppDialog>
</template>

<script>
// Vue components
import AppInputV3 from '@/components/partials/inputs/AppInputV3.vue';
import AppToggleV2 from '@/components/partials/inputs/AppToggleV2.vue';
import AppTextareaV3 from '@/components/partials/inputs/AppTextareaV3.vue';
import AppMultiselectV3 from '@/components/partials/inputs/AppMultiselectV3.vue';
import TagsMultiselect from '@/components/partials/inputs/TagsMultiselect.vue';
import DateTimePicker from '@/components/partials/inputs/DateTimePicker.vue';
import AppTooltip from '@/components/partials/AppTooltip.vue';
import { TOOLTIP_POSITION } from '@/common/tooltip';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import { ICON_TYPES } from '@/common/iconHelper';
import AppDialog from '@/components/partials/AppDialog.vue';
import { ALERT_TYPES } from '@/common/alerts/Alert';

// Helpers
import {
    PROMOTION_TYPES,
    PROMOTION_ENUM_TO_KEY_MAP,
    GRANT_TYPES,
    GRANT_TYPE_ENUM_TO_OBJECT,
} from '@/modules/promotions/common/promotionsHelper';
import ENTITY_TYPES from '@/common/entities/entityTypes';
import { grantBenefit, updateBenefit } from '@/http/benefits';
import { USER_MANAGER_HIERARCHY } from '@/__new__/features/customerCare/common/customerCareHelper';
import * as Sentry from '@sentry/vue';
import Promotion from '@/models/Promotion';
import supportButtonMixin from '@/components/alerts/supportButtonMixin';
import { isEmpty } from 'lodash';

// Vuex
import { mapGetters } from 'vuex';
import { Getters } from '@/store/mutation-types';
import { EntityStateMapping } from '@/common/commonEntityStateMapper';

export default {
    name: 'BenefitEditor',
    components: {
        AppButton,
        AppMultiselectV3,
        AppTextareaV3,
        AppInputV3,
        TagsMultiselect,
        AppTooltip,
        AppDialog,
        AppToggleV2,
        DateTimePicker,
    },
    mixins: [supportButtonMixin],
    props: {
        value: {
            type: Boolean,
            required: true,
        },
        benefitEntity: {
            type: Object,
            default: () => ({}),
        },
        idType: {
            type: Number,
            default: USER_MANAGER_HIERARCHY.ACCOUNT,
        },
        accountId: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            promotionRuleType: GRANT_TYPES.Manual,
            promotionRule: undefined,
            name: '',
            description: '',
            promoShortDescription: '',
            benefitShortDescription: '',
            termsAndConditions: '',
            promotionType: '',
            amount: null,
            startTime: null,
            endTime: null,
            offersForDiscount: [],
            recurrenceCount: null,
            recurrenceLimit: null,
            stackable: false,
            overwrite: {
                name: {
                    flag: false,
                    promoVal: '',
                    benefitVal: '',
                },
                description: {
                    flag: false,
                    promoVal: '',
                    benefitVal: '',
                },
                promoShortDescription: {
                    flag: false,
                    promoVal: '',
                    benefitVal: '',
                },
                benefitShortDescription: {
                    flag: false,
                    promoVal: '',
                    benefitVal: '',
                },
                termsAndConditions: {
                    flag: false,
                    promoVal: '',
                    benefitVal: '',
                },
                amount: {
                    flag: false,
                    promoVal: '',
                    benefitVal: '',
                },
                recurrenceLimit: {
                    flag: false,
                    promoVal: '',
                    benefitVal: '',
                },
            },

            // Proxy imports
            GRANT_TYPES,
            PROMOTION_TYPES,
            TOOLTIP_POSITION,
            ICON_TYPES,
            BUTTON_TYPES,
        };
    },
    computed: {
        ...mapGetters('promotions', { promotionRules: Getters.PROMOTIONS_LIST }),
        getFormattedPromotionRules() {
            return this.promotionRules
                .reduce((arr, el) => {
                    // eslint-disable-next-line camelcase
                    if (
                        (el?.data?.grant_type || GRANT_TYPES.Voucher.id) === this.promotionRuleType.id &&
                        el.state !== EntityStateMapping.DELETED
                    ) {
                        arr.push({
                            ...Promotion.remapPromotionFromBe(el),
                            label: el?.data?.name?.en || '',
                        });
                    }
                    return arr;
                }, [])
                .filter(
                    promotion =>
                        !promotion?.endTimestamp ||
                        this.$localeLibrary.normalizeTimestamp(promotion.endTimestamp) > Date.now(),
                );
        },
        getAmountLabel() {
            if (this.promotionType && this.promotionType.key === PROMOTION_TYPES.PriceOverride.key) {
                return this.$i18n.t('promotions.amountPriceLabel');
            }
            return this.$i18n.t('promotions.amountOffLabel');
        },
        getAmountPlaceholder() {
            if (this.promotionType && this.promotionType.key === PROMOTION_TYPES.PercentageDiscount.key) {
                return '10%';
            }
            return '$10';
        },
        availableOffersForDiscount() {
            const approvedOffers = this.$store.getters[`productcatalog/${Getters.PC_GET_ENTITIES_BY_TYPE_APPROVED}`](
                ENTITY_TYPES.OFFER,
            );
            return approvedOffers.map(offer => ({
                ...offer,
                label: offer.name,
            }));
        },
        isManualGrantType() {
            return this.promotionRuleType === GRANT_TYPES.Manual;
        },
        isEditing() {
            return !isEmpty(this.benefitEntity);
        },
    },
    watch: {
        value() {
            // Set default values when editing benefit
            if (this.value && this.isEditing) {
                this.promotionRuleType = GRANT_TYPE_ENUM_TO_OBJECT[this.benefitEntity.grantType] || GRANT_TYPES.Manual;
                this.promotionRule = this.getPromotionRule(this.benefitEntity.promotionId);
                this.name = this.benefitEntity.name;
                this.description = this.benefitEntity.description;
                this.promoShortDescription = this.benefitEntity.promoShortDescription;
                this.benefitShortDescription = this.benefitEntity.benefitShortDescription;
                this.termsAndConditions = this.benefitEntity.termsAndConditions;
                this.promotionType = this.getPromoById(this.benefitEntity.promotionTypeId);
                this.amount = this.benefitEntity.amount;
                this.recurrenceLimit = this.benefitEntity.recurrenceLimit;
                this.startTime = this.getDateFromTimestamp(this.promotionRule?.startTimestamp);
                this.endTime = this.getDateFromTimestamp(this.promotionRule?.endTimestamp);
                this.offersForDiscount = this.getAffectedOffers(this.promotionRule?.offersForDiscountIds);
                this.setOverwriteFlags();
                this.setOverwriteValues();
            }
        },
    },
    methods: {
        emitValue(val) {
            this.$emit('input', val);
            if (!val) {
                this.setFieldsToDefaultValues();
            }
        },
        onCancel() {
            this.emitValue(false);
        },
        async onSave() {
            try {
                this.$Progress.start();
                if (this.promotionRule?.id) {
                    let benefitUpdateId;
                    if (!this.isEditing) {
                        const grantResponse = await grantBenefit(
                            this.accountId || this.$route.params.id,
                            this.idType,
                            this.promotionRule.id,
                        );
                        benefitUpdateId = grantResponse.data.benefit_id;
                    } else {
                        benefitUpdateId = this.benefitEntity.benefitId;
                    }

                    const updateData = {
                        recurrence_limit: Number(this.recurrenceLimit) || null,
                        name: this.name,
                        description: this.description,
                        short_descriptions: [
                            {
                                promotion_short_description: this.promoShortDescription,
                                benefit_short_description: this.benefitShortDescription,
                            },
                        ],
                        amounts: {
                            primary: `${this.amount}`,
                        },
                        terms_and_conditions: this.termsAndConditions,
                    };
                    await updateBenefit(
                        this.accountId || this.$route.params.id,
                        this.idType,
                        benefitUpdateId,
                        updateData,
                    );
                }
                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('benefits.alerts.benefitSuccessfulAction', {
                        action: this.isEditing ? 'updated' : 'created',
                    }),
                    type: ALERT_TYPES.success,
                });
                this.$Progress.finish();
                setTimeout(() => this.emitValue(false), 1000);
            } catch (e) {
                Sentry.captureException(e);
                this.showSupportAlert(this.$i18n.t('alertMessage.somethingWentWrong'), ALERT_TYPES.error);
                this.$Progress.fail();
            }
        },
        setDefaultFieldsFromPromotion() {
            // Set default values from selected promotion rule when creating benefit
            if (this.promotionRule && !this.isEditing) {
                this.name = this.promotionRule?.name;
                this.description = this.promotionRule?.description;
                this.promoShortDescription = this.promotionRule?.promoShortDescription;
                this.benefitShortDescription = this.promotionRule?.benefitShortDescription;
                this.termsAndConditions = this.promotionRule?.termsAndConditions;
                this.promotionType = this.getPromoById(this.promotionRule?.promotionTypeId);
                this.amount = this.promotionRule?.amount;
                this.startTime = this.getDateFromTimestamp(this.promotionRule?.startTimestamp);
                this.endTime = this.getDateFromTimestamp(this.promotionRule?.endTimestamp);
                this.offersForDiscount = this.getAffectedOffers(this.promotionRule?.offersForDiscountIds);
                this.recurrenceLimit = this.promotionRule?.recurrenceLimit;
                this.resetOverwriteFlagsToDefault();
                this.setOverwriteValues();
            }
        },
        setFieldsToDefaultValues() {
            this.promotionRuleType = GRANT_TYPES.Manual;
            this.promotionRule = undefined;
            this.name = '';
            this.description = '';
            this.promoShortDescription = '';
            this.benefitShortDescription = '';
            this.termsAndConditions = '';
            this.promotionType = '';
            this.amount = null;
            this.startTime = null;
            this.endTime = null;
            this.offersForDiscount = [];
            this.recurrenceCount = null;
            this.recurrenceLimit = null;
            this.stackable = false;
        },
        resetOverwriteFlagsToDefault() {
            this.overwrite.name.flag = false;
            this.overwrite.description.flag = false;
            this.overwrite.promoShortDescription.flag = false;
            this.overwrite.benefitShortDescription.flag = false;
            this.overwrite.termsAndConditions.flag = false;
            this.overwrite.amount.flag = false;
            this.overwrite.recurrenceLimit.flag = false;
        },
        setOverwriteFlags() {
            // Set default toggle values for overridable fields
            if (this.promotionRule) {
                this.overwrite.name.flag = !(this.promotionRule?.name === this.benefitEntity.name);
                this.overwrite.description.flag = !(this.promotionRule?.description === this.benefitEntity.description);
                this.overwrite.promoShortDescription.flag = !(
                    this.promotionRule?.promoShortDescription === this.benefitEntity.promoShortDescription
                );
                this.overwrite.benefitShortDescription.flag = !(
                    this.promotionRule?.benefitShortDescription === this.benefitEntity.benefitShortDescription
                );
                this.overwrite.termsAndConditions.flag = !(
                    this.promotionRule?.termsAndConditions === this.benefitEntity.termsAndConditions
                );
                this.overwrite.amount.flag = !(this.promotionRule?.amount === this.benefitEntity.amount);
                this.overwrite.recurrenceLimit.flag = !(
                    this.promotionRule?.recurrenceLimit === this.benefitEntity.recurrenceLimit
                );
            }
        },
        setOverwriteValues() {
            // Set promotion rule values to be able toggle override
            this.overwrite.name.promoVal = this.promotionRule?.name;
            this.overwrite.description.promoVal = this.promotionRule?.description;
            this.overwrite.promoShortDescription.promoVal = this.promotionRule?.promoShortDescription;
            this.overwrite.benefitShortDescription.promoVal = this.promotionRule?.benefitShortDescription;
            this.overwrite.termsAndConditions.promoVal = this.promotionRule?.termsAndConditions;
            this.overwrite.amount.promoVal = this.promotionRule?.amount;
            this.overwrite.recurrenceLimit.promoVal = this.promotionRule?.recurrenceLimit;
            // Set benefit values to be able toggle override
            if (this.isEditing) {
                this.overwrite.name.benefitVal = this.benefitEntity.name;
                this.overwrite.description.benefitVal = this.benefitEntity.description;
                this.overwrite.promoShortDescription.benefitVal = this.benefitEntity.promoShortDescription;
                this.overwrite.benefitShortDescription.benefitVal = this.benefitEntity.benefitShortDescription;
                this.overwrite.termsAndConditions.benefitVal = this.benefitEntity.termsAndConditions;
                this.overwrite.amount.benefitVal = this.benefitEntity.amount;
                this.overwrite.recurrenceLimit.benefitVal = this.benefitEntity.recurrenceLimit;
            }
        },
        toggleOverwrite(key) {
            const { flag } = this.overwrite[key];
            if (!flag) {
                this.overwrite[key].benefitVal = this[key];
                this[key] = this.overwrite[key].promoVal;
            } else {
                this[key] = this.overwrite[key].benefitVal;
            }
        },
        getPromoById(id) {
            if (id) {
                const promoKey = PROMOTION_ENUM_TO_KEY_MAP[id];
                return Object.values(PROMOTION_TYPES).find(el => el.key === promoKey) || '';
            }
            return '';
        },
        getAffectedOffers(affectedOffersIds) {
            if (affectedOffersIds && Array.isArray(affectedOffersIds)) {
                return affectedOffersIds.map(id => {
                    const offer = this.$store.getters[`productcatalog/${Getters.PC_GET_ENTITY_BY_TYPE_AND_ID}`](
                        ENTITY_TYPES.OFFER,
                        id,
                    );
                    return {
                        ...offer,
                        label: offer?.name,
                    };
                });
            }
            return [];
        },
        getPromotionRule(promotionId) {
            const promotionRule = this.$store.getters[`promotions/${Getters.PROMOTION_BY_ID}`](promotionId);
            if (promotionRule) {
                return {
                    ...Promotion.remapPromotionFromBe(promotionRule),
                    label: promotionRule?.data?.name?.en,
                };
            }
            return promotionRule;
        },
        getDateFromTimestamp(timestamp) {
            if (timestamp) {
                return new Date(this.$localeLibrary.normalizeTimestamp(timestamp));
            }
            return null;
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/palette';
@import '~@/assets/scss/icons';
@import '~@/assets/scss/typographyV2';

$icon-path: '~@/assets/icons/';

.info-icon {
    width: 1rem;
    height: 1rem;
    content: url($icon-path + $info-grey);
}

.tooltip-container {
    height: 1rem;
}

.tooltip-inner-content {
    width: 26.25rem;
}

.input-max-width {
    max-width: 567px;
}

.input-label {
    font-weight: bold;

    font-size: 0.75rem;

    color: $gray90;
}

.amount-label {
    display: flex;
    align-items: center;
    height: 2.125rem;
}

.dialog-wrapper {
    ::v-deep .content {
        overflow: initial;
    }
}

.editor-wrapper {
    max-height: 550px;
    max-width: 880px;
    margin: 0 3rem;
}

.section-title {
    font-size: 1rem;
    font-weight: bold;

    color: $gray90;

    margin-top: 2rem;
    margin-bottom: 1rem;
}

.input-width {
    min-width: 17.6875rem;
    width: 30%;
}

.editor-controls-wrapper {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    height: 5.56rem;
    padding: 1.5rem 6rem;
    border-top: 1px solid $blue15;
    border-radius: 0 0 0.5rem 0.5rem;
    background-color: $white;

    .editor-controls-content {
        display: flex;
        justify-content: flex-end;
    }
}
</style>
