// copy of the https://github.com/lotusflare/lua/blob/master/services_root/libs/enum/Enum.lua
enum UM_HIERARCHY {
    SPECIAL_ALL = 0,
    SUBSCRIBER = 1,
    ACCOUNT = 2,
    USER = 3,
    KEY_ID = 4,
    ORGANIZATION = 5,

    // not registered in UM but still may be processed by `user` service
    UNREGISTERED_ID = 100,
    UNREGISTERED_EMAIL = 101,
    UNREGISTERED_MSISDN = 102,

    // non-UM id type
    EXECUTION_ID = 200,
    EXTERNAL_IDP_ID = 400,
}

export default UM_HIERARCHY;
