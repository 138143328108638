import httpClient, { DnoResponseBase, Entity, EntityState } from '@/http/index';
import { AxiosPromise } from 'axios';
import { DunningProfile } from '@/__new__/services/dno/billing/models/dunningProfilesDno';

export interface DunningProfilesResponse extends DnoResponseBase {
    dunning_profile_by_id: Record<string, Entity<DunningProfile>>;
}

export const getDunningProfiles = (): AxiosPromise<DunningProfilesResponse> => {
    return httpClient({
        method: 'GET',
        url: '/dunning',
    });
};

export const getDunningProfileById = (id: string): AxiosPromise<DunningProfilesResponse> => {
    return httpClient({
        method: 'GET',
        url: `/dunning/${id}`,
    });
};

export const createDunningProfile = (dunningProfile: DunningProfile): AxiosPromise<DunningProfilesResponse> => {
    return httpClient({
        method: 'POST',
        url: `/dunning`,
        data: {
            json_data: JSON.stringify(dunningProfile),
        },
    });
};

export const updateDunningProfile = (
    id: string,
    version: number,
    dunningProfile: DunningProfile,
): AxiosPromise<DunningProfilesResponse> => {
    return httpClient({
        method: 'PATCH',
        url: `/dunning`,
        data: {
            id,
            version,
            json_data: JSON.stringify(dunningProfile),
        },
    });
};

export const updateDunningProfileState = (id: string, version: number, state: EntityState) => {
    if (state === EntityState.DELETED) {
        return httpClient({
            method: 'DELETE',
            url: `/dunning`,
            data: {
                id,
                version,
                state,
            },
        });
    }
    return null;
};
