<template>
    <div
        :class="{ compact }"
        class="menu-item"
    >
        <div
            class="mode-active-wrapper"
            @mouseenter="isHover = true"
            @mouseleave="isHover = false"
        >
            <div
                :class="{ compact }"
                class="img-wrapper"
                @click="toggleDropdown"
            >
                <AppIcon
                    type="redo-new"
                    class="menu-icon"
                />
            </div>

            <span
                v-show="!compact"
                :class="{ compact, active: isDropdownVisible }"
                class="dropdown-header"
                @click="toggleDropdown"
            >
                <span
                    :class="{ active: isDropdownVisible }"
                    class="hover-indicator"
                />
                {{ activeMode.title }}
                <span
                    v-show="!compact"
                    class="menu-dropdown-toggle"
                >
                    <img
                        src="../../assets/icons/arrow-down.svg"
                        alt="dropdown-arrow"
                        class="dropdown-arrow"
                    />
                </span>
            </span>
        </div>
        <div
            :class="{ compact }"
            class="dropdown-wrapper"
        >
            <div
                :class="{ visible: isDropdownVisible, compact }"
                class="dropdown"
            >
                <span
                    v-for="(mode, i) in options"
                    :key="i"
                >
                    <div
                        :key="i"
                        :class="{
                            active: mode.value === activeMode.value,
                            compact,
                            'compact-active': compact && mode.value === activeMode.value,
                        }"
                        class="sub-item"
                        @click="toggleMode(mode)"
                    >
                        <span
                            :key="i"
                            class="hover-sub-indicator"
                        />
                        {{ mode.title }}
                    </div>
                </span>
            </div>
        </div>
    </div>
</template>

<script>
import AppIcon from '@/components/partials/icon/AppIcon.vue';
import Actions from '@/store/mutation-types';
import { USER_MODES } from '@/common/commonHelper';

export default {
    name: 'TenantSwitcher',
    components: {
        AppIcon,
    },
    props: {
        compact: {
            type: Boolean,
            default: false,
        },
        showDropdown: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            dropdownVisible: false,
            isHover: false,
            activeMode: {
                title: this.$i18n.t('components.modeSwitcher.regularMode'),
                value: USER_MODES.REGULAR,
            },
            options: [
                {
                    title: this.$i18n.t('components.modeSwitcher.regularMode'),
                    value: USER_MODES.REGULAR,
                },
                {
                    title: this.$i18n.t('components.modeSwitcher.powerUserMode'),
                    value: USER_MODES.POWER,
                },
                {
                    title: this.$i18n.t('components.modeSwitcher.experimentalMode'),
                    value: USER_MODES.EXPERIMENTAL,
                },
            ],
        };
    },

    computed: {
        isDropdownVisible() {
            return this.dropdownVisible;
        },
    },
    methods: {
        toggleDropdown() {
            this.dropdownVisible = !this.dropdownVisible;
        },
        toggleMode(mode) {
            this.activeMode = mode;
            this.$store.dispatch(Actions.CHANGE_MODE, { mode: mode.value });
            this.dropdownVisible = false;
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/animations';
@import '~@/assets/scss/mixins';
@import '~@/assets/scss/palette';
@import '~@/assets/scss/sidebar';
@import '~@/assets/scss/layout';
@import '~@/assets/scss/z-indexes';

.hover-indicator {
    @include sidebarHoverIndicator(0, $white, none);
    height: 37px;

    .compact & {
        left: -64px;
    }
}

.menu-item {
    position: relative;

    .mode-active-wrapper {
        display: flex;
        justify-content: space-between;
        width: 100%;
        height: 41px; // 41px needed because of the browser's height miscalculation

        .img-wrapper {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            flex: 0 0 35px;
            height: 41px;
            width: 41px;
            z-index: $sidebar-stuff-z-index;
            cursor: pointer;

            &.compact {
                position: static;
                .menu-icon {
                    margin-left: 21px;
                }
            }

            .menu-icon {
                width: 16px;
                height: 16px;
                margin-left: 16px;

                .compact & {
                    position: static;
                }
            }
        }

        .dropdown-header {
            display: flex;
            justify-content: space-between;
            padding: $spacing-xs 0 $spacing-xs 3.25rem;
            width: 100%;
            color: rgba($white, 0.8);
            cursor: pointer;

            &.compact {
                padding-left: $spacing-xl;
            }

            .menu-dropdown-toggle {
                padding-right: $spacing-xs;
            }

            &.active,
            &.active-parent {
                font-weight: bold;
                color: $white;
            }

            .hover-indicator {
                &.active {
                    width: 4px;
                }
            }

            &:hover {
                .hover-indicator {
                    width: 4px;
                }
            }
        }
    }
}

.dropdown-wrapper {
    max-height: 300px;
    overflow-y: auto;
}

.dropdown {
    display: none;
    &.compact {
        &.visible {
            display: block;
            position: absolute;
            max-height: 300px;
            min-width: 250px;
            overflow-y: scroll;
            left: 64px;
            bottom: 0;
            padding: 5px 0 15px 0;
            background: $navy;
            border-bottom-right-radius: 13px;
            border-top-right-radius: 13px;
        }
    }

    &.visible {
        display: block;
    }

    .sub-item {
        position: relative;
        padding: 7px 0 7px 52px;
        max-width: 95%;
        color: rgba($white, 0.6);
        cursor: pointer;

        &.compact {
            padding-left: 1.5rem;
        }

        &.active {
            border-bottom-right-radius: 100px;
            border-top-right-radius: 100px;
            background-color: #21395b;
            width: 95%;
            font-weight: bold;
            color: $white;
        }

        &.compact-active {
            font-weight: bold;
            color: $white;
        }

        .hover-sub-indicator {
            display: block;
            position: absolute;
            left: 0;
            top: 0;
            width: 0;
            height: 100%;
            border-bottom-right-radius: 100px;
            border-top-right-radius: 100px;
            background-color: $white;
            transition: width 0.2s ease;
            z-index: $sidebar-stuff-z-index;
            color: $white;

            &.active-sub-indicator {
                @extend .hover-sub-indicator;
                background-image: $sidebar-gradient;
                width: 4px;
            }
        }

        &:hover {
            .hover-sub-indicator {
                width: 4px;
                border-bottom-right-radius: 100px;
                border-top-right-radius: 100px;
                background-color: $white;
                font-weight: bold;
            }
        }

        &.disabled {
            pointer-events: none;
        }
    }
}
</style>
