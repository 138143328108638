<template>
    <div v-if="isOrganizationIdUrlParamPresent">
        <div class="container-fluid wrapper-container background-color h-100">
            <AppHeader
                :pageTitle="$i18n.t('generic.organization')"
                :isSearchEnabled="false"
                class="mb-2"
            >
                <template
                    v-if="isNewCustomerCareEnabled"
                    slot="headerButtons"
                >
                    <AppButton
                        :buttonType="BUTTON_TYPES.PRIMARY"
                        :iconType="ICON_TYPES.DATA"
                        :label="$i18n.t('customerCareSuite.tryNewCustomerCare')"
                        class="mx-2"
                        @click="newCustomerCare"
                    />
                </template>
            </AppHeader>

            <Breadcrumbs
                :breadcrumbList="breadcrumbList"
                class="ml-4"
            />

            <AppCustomerHeader :infoData="organizationData" />

            <!--    Accounts        -->
            <!--    TODO: check if enabled flag is needed       -->
            <!--            <div v-if="permissionsService.subscriberLevelEnabled()" class="section-layout">-->
            <div class="section-layout">
                <TableFiltersRenderless :entities="filteredAccounts">
                    <!-- eslint-disable vue/no-unused-vars -->
                    <template
                        #default="{
                            // state
                            allFilters,
                            filteredEntities,
                            // methods
                            onFilterAdded,
                            removeFilter,
                            removeAllFilters,
                            filteredEntitiesMixin,
                        }"
                    >
                        <div>
                            <div class="row mb-2 align-items-center table-header-row justify-content-between">
                                <div class="component-title-um">
                                    {{ $i18n.t('customerCare.accounts') }}
                                </div>
                                <div class="display-flex">
                                    <SearchBox
                                        v-model="accountsSearchQuery"
                                        class="table-search-box"
                                        :smallSearchIcon="true"
                                    />
                                    <div class="filters-control position-relative">
                                        <FilterTable
                                            :columns="accountsTableColumnsData"
                                            :multiselectWidth="{ width: '12rem' }"
                                            @filterAdded="onFilterAdded"
                                        >
                                            <template #button="slotProps">
                                                <IconButton
                                                    :label="$i18n.t('generic.filter')"
                                                    :icon="ICON_TYPES.FILTER"
                                                    @iconClick="slotProps.clickHandler"
                                                />
                                            </template>
                                        </FilterTable>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <TableFiltersTags
                                    :filterTableMixin="{ allFilters }"
                                    class="mt-1 mb-3"
                                    @removeFilter="removeFilter"
                                    @removeAllFilters="removeAllFilters"
                                />
                                <AppTable
                                    :entities="filteredEntities"
                                    :innerSearchQuery="accountsSearchQuery"
                                    :isDefaultHoverEnabled="false"
                                    :isPaginationEnabled="true"
                                    :isSearchEnabled="true"
                                    :newDesign="true"
                                    :columnsData="accountsTableColumnsData"
                                    :canSelectColumns="true"
                                    tableKey="customer-care-new/organization/accounts"
                                >
                                    <template #status="{ entity }">
                                        <EntityStatusIndicator :status="entity.state" />
                                    </template>

                                    <template #account_id="{ entity }">
                                        <div
                                            v-if="entity.account_id"
                                            class="link pointer"
                                            @click="
                                                goToRoute(
                                                    getBreadcrumbPath(
                                                        USER_MANAGER_HIERARCHY.ACCOUNT,
                                                        entity.account_id,
                                                    ),
                                                )
                                            "
                                        >
                                            {{ entity.account_id }}
                                        </div>
                                        <i v-else>
                                            {{ $i18n.t('generic.empty') }}
                                        </i>
                                    </template>
                                </AppTable>
                            </div>
                        </div>
                    </template>
                </TableFiltersRenderless>
            </div>

            <!--   Roles management    -->
            <div class="section-layout">
                <TableFiltersRenderless :entities="filteredRoles">
                    <template
                        #default="{
                            // state
                            allFilters,
                            filteredEntities,
                            // methods
                            onFilterAdded,
                            removeFilter,
                            removeAllFilters,
                            filteredEntitiesMixin,
                        }"
                    >
                        <div>
                            <div class="row mb-2 align-items-center table-header-row justify-content-between">
                                <div class="component-title-um">
                                    {{ $i18n.t('rolesAndPermissions.rolesPage') }}
                                </div>
                                <div class="display-flex">
                                    <SearchBox
                                        v-model="rolesSearchQuery"
                                        class="table-search-box"
                                        :smallSearchIcon="true"
                                    />
                                    <div class="filters-control position-relative">
                                        <FilterTable
                                            :columns="rolesTableColumnsData"
                                            :multiselectWidth="{ width: '12rem' }"
                                            @filterAdded="onFilterAdded"
                                        >
                                            <template #button="slotProps">
                                                <IconButton
                                                    :label="$i18n.t('generic.filter')"
                                                    :icon="ICON_TYPES.FILTER"
                                                    @iconClick="slotProps.clickHandler"
                                                />
                                            </template>
                                        </FilterTable>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <TableFiltersTags
                                    :filterTableMixin="{ allFilters }"
                                    class="mt-1 mb-3"
                                    @removeFilter="removeFilter"
                                    @removeAllFilters="removeAllFilters"
                                />
                                <AppTable
                                    :entities="filteredEntities"
                                    :innerSearchQuery="rolesSearchQuery"
                                    :isSearchEnabled="true"
                                    :isDefaultHoverEnabled="false"
                                    :isPaginationEnabled="true"
                                    :newDesign="true"
                                    :columnsData="rolesTableColumnsData"
                                    :canSelectColumns="true"
                                    tableKey="customer-care-new/account/roles"
                                >
                                    <template #user_id="{ entity }">
                                        <div
                                            v-if="entity.user_id"
                                            class="link pointer"
                                            @click="
                                                goToRoute(
                                                    getBreadcrumbPath(USER_MANAGER_HIERARCHY.USER, entity.user_id),
                                                )
                                            "
                                        >
                                            {{ entity.user_id }}
                                        </div>
                                        <i v-else>
                                            {{ $i18n.t('generic.empty') }}
                                        </i>
                                    </template>
                                </AppTable>
                            </div>
                        </div>
                    </template>
                </TableFiltersRenderless>
            </div>
            <div
                v-if="isLFAdmin"
                class="section-layout"
            >
                <AssociatedRoles
                    :id="$route.params.id"
                    :idType="USER_MANAGER_HIERARCHY.ORGANIZATION"
                    :showEmail="true"
                />
            </div>
        </div>
    </div>
</template>

<script>
// components
import Breadcrumbs from '@/components/partials/Breadcrumbs.vue';
import AppHeader from '@/components/layout/AppHeader.vue';
import AppCustomerHeader from '@/components/partials/AppCustomerHeader.vue';
import AppTable from '@/components/partials/AppTable.vue';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import AssociatedRoles from '@/__new__/features/customerCare/AssociatedRoles.vue';
import EntityStatusIndicator from '@/components/partials/EntityStatusIndicator.vue';
import FilterTableMixin from '@/components/partials/FilterTableMixin.vue';
import SearchBox from '@/components/partials/inputs/SearchBox.vue';
import TableFiltersRenderless from '@/components/filters/TableFiltersRenderless.vue';
import FilterTable from '@/components/partials/FilterTable.vue';
import TableFiltersTags from '@/components/filters/TableFiltersTags.vue';
import IconButton from '@/components/partials/IconButton.vue';
import { ICON_TYPES } from '@/common/iconHelper';

// helpers
import { mapActions, mapGetters } from 'vuex';
import Organization from '@/__new__/services/dno/user/models/Organization';
import { USER_MANAGER_HIERARCHY } from '@/__new__/features/customerCare/common/customerCareHelper';
import * as Sentry from '@sentry/vue';
import permissionsService, { getOperatorConfigValue } from '@/services/permissions/permissions.service';
import RouteNames from '@/router/routeNames';
import tableColumnType from '@/common/filterTable';
import { ALERT_TYPES } from '@/common/alerts/Alert';
import supportButtonMixin from '@/components/alerts/supportButtonMixin';

export default {
    name: 'Organization',
    components: {
        SearchBox,
        AppHeader,
        AppButton,
        Breadcrumbs,
        AppCustomerHeader,
        AppTable,
        AssociatedRoles,
        EntityStatusIndicator,
        TableFiltersRenderless,
        TableFiltersTags,
        FilterTable,
        IconButton,
    },

    mixins: [FilterTableMixin, supportButtonMixin],

    data() {
        return {
            ICON_TYPES,
            isLFAdmin: permissionsService.isUserLotusFlareAdmin(),
            organization: new Organization({}),
            accountsSearchQuery: '',
            rolesSearchQuery: '',
            accountsTableColumnsData: [
                {
                    name: this.$i18n.t('customerCare.accountId'),
                    key: 'account_id',
                    field: 'account_id',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('customerCare.accountName'),
                    key: 'account_name',
                    field: 'account_name',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('generic.status'),
                    key: 'status',
                    field: 'status',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
            ],
            rolesTableColumnsData: [
                {
                    name: this.$i18n.t('customerCare.roleName'),
                    key: 'role',
                    field: 'role',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('customerCare.userId'),
                    key: 'caller_id',
                    field: 'caller_id',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
            ],
            USER_MANAGER_HIERARCHY,
            BUTTON_TYPES,
            isNewCustomerCareEnabled: getOperatorConfigValue('customerCareOptIn', false),
        };
    },
    computed: {
        ...mapGetters('userManagementOrganization', [
            'getOrganizationInfoById',
            'getAccessListById',
            'getAccountInfos',
        ]),
        isOrganizationIdUrlParamPresent() {
            return !!this.$route.params.id;
        },
        organizationInfo() {
            return this.$route.params.id ? this.getOrganizationInfoById(this.$route.params.id) : {};
        },
        roles() {
            return this.$route.params.id ? this.getAccessListById(this.$route.params.id) : [];
        },
        accountInfos() {
            return this.getAccountInfos();
        },
        breadcrumbList() {
            return [
                {
                    name: this.$i18n.t('customerCare.breadcrumbs.search'),
                    link: '/customer-care/user-management',
                },
                {
                    name: this.$i18n.t('customerCare.breadcrumbs.organization'),
                },
            ];
        },
        filteredAccounts() {
            return this.filteredEntitiesMixin(this.accountInfos);
        },
        filteredRoles() {
            return this.filteredEntitiesMixin(this.roles);
        },
        organizationData() {
            return [
                {
                    title: this.$i18n.t('generic.details'),
                    value: [
                        {
                            name: this.$i18n.t('customerCare.organizationId'),
                            value: this.organization.organizationId,
                        },
                        {
                            name: this.$i18n.t('customerCare.domain'),
                            value: this.organizationInfo?.properties?.domain,
                        },
                    ],
                    initiallyExpanded: true,
                },
            ];
        },
    },
    async created() {
        try {
            const { id } = this.$route.params;
            if (!id) {
                await this.$router.push({
                    name: RouteNames.CUSTOMER_CARE_USER_MANAGEMENT,
                    params: { companyId: this.$route.params.companyId },
                });
                return;
            }
            await this.fetchOrganizationData();
            this.setOrganization();
            await this.fetchAccessListById(id);
        } catch (error) {
            Sentry.captureException(error);
            await this.$router.push({
                name: RouteNames.CUSTOMER_CARE_USER_MANAGEMENT,
                params: { companyId: this.$route.params.companyId },
            });
            this.showSupportAlert(
                this.$i18n.t('alertMessage.somethingWentWrongFetchingNecessaryData'),
                ALERT_TYPES.error,
            );
        } finally {
            this.isDataLoading = false;
        }
    },
    methods: {
        ...mapActions('userManagementOrganization', ['getOrganizationInfo', 'getAccessList']),
        goToRoute(breadcrumb) {
            this.$router.push({ path: `/${this.$route.params.companyId}${breadcrumb.link}` });
        },
        async fetchOrganizationData() {
            try {
                this.$Progress.start();
                await this.getOrganizationInfo(this.$route.params.id);
                this.setOrganization();
                this.$Progress.finish();
            } catch (error) {
                Sentry.captureException(error);
                this.$Progress.fail();
                this.showSupportAlert(
                    this.$i18n.t('alertMessage.somethingWentWrongFetchingNecessaryData'),
                    ALERT_TYPES.error,
                );
            }
        },
        async fetchAccessListById(id) {
            try {
                this.$Progress.start();
                await this.getAccessList(id);
                this.$Progress.finish();
            } catch (error) {
                this.$Progress.fail();
                Sentry.captureException(error);
                this.showSupportAlert(
                    this.$i18n.t('alertMessage.somethingWentWrongFetchingNecessaryData'),
                    ALERT_TYPES.error,
                );
            }
        },
        setOrganization() {
            this.organization = new Organization(Organization.remapOrganizationFromBe(this.organizationInfo));
        },
        getBreadcrumbPath(type, id) {
            switch (type) {
                case USER_MANAGER_HIERARCHY.ACCOUNT:
                    return {
                        link: `/customer-care/account/${id}`,
                    };
                case USER_MANAGER_HIERARCHY.USER:
                    return {
                        link: `/customer-care/user/${id}`,
                    };
                // TODO: delete?
                // case USER_MANAGER_HIERARCHY.ORGANIZATION:
                //     return {
                //         link: '/customer-care/organization',
                //         // link: `/customer-care/organization/${id}`,
                //     };
                default:
                    return '';
            }
        },
        newCustomerCare() {
            this.$router.push({
                name: RouteNames.CCS_ORGANIZATION_PAGE,
                params: { id: this.$route.params.id },
            });
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/palette';
@import '~@/assets/scss/typography';

.background-color {
    background-color: $dirty-white;
}

.section-layout {
    margin: 0 1.5rem 1.5rem 1.5rem;
}

.table-header-row {
    //searchBox is dictating height, it is in px, so this should also be in px
    min-height: 36px;
}

.search-box-wrapper {
    position: relative;
    width: 15rem;
    height: 2.5rem;
}

.component-title {
    font-size: 1rem;
    font-weight: 600;
    color: $gray90;
}

.modal-title {
    padding: 2rem 2.5rem 2.1875rem;
}

.modal-buttons {
    padding: 1.4375rem 2rem;
}

.gray-note {
    padding: 0.6875rem 2.5rem;
}

.refund-input {
    padding: 2.125rem 2.5rem;
}

strong {
    color: $navy;
}

.link:hover {
    color: $blue;
    text-decoration: underline;
}

.tags-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.tag-margins {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
}

.table-search-box {
    width: 15rem;
}

.pointer {
    cursor: pointer;
}
</style>
