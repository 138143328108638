
import AppMultiselectV3 from '@/components/partials/inputs/AppMultiselectV3.vue';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import TransactionHistoryV4Sidebar from '@/__new__/features/customerCare/account/TransactionHistoryV4Sidebar.vue';
import ordersHTTP from '@/__new__/services/dno/orders/http/orders';
import { USER_MANAGER_HIERARCHY } from '@/__new__/features/customerCare/common/customerCareHelper';
import { TransactionV4 } from '@/__new__/services/dno/orders/models/TransactionV4';
import { PAYMENT_TRANSACTION_TYPE } from '@/common/userManager/transactionHistoryV4Helper';
import { formatAmountBasedOnCurrency } from '@/common/formatting';
import { CHARGE_STATES } from '@/common/userManager/transactionHistoryV4StatusHelper';
import HTTPCustomerCare from '@/__new__/services/dno/user/http/customer-care';
import luaErrors from '@/common/luaErrors';
import userManagementHTTP from '@/__new__/services/dno/user/http/user-management';
import localeLibrary from '@/common/locale/localeLibrary';

export default {
    name: 'BillingTile',
    components: {
        AppMultiselectV3,
        AppButton,
        TransactionHistoryV4Sidebar,
    },
    props: {
        userManagerHierarchy: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            billing: {
                amount: '',
                isPaid: false,
            },
            currentAccId: '',
            accounts: [],

            selectedTransactionHistory: {},
            showAdditionalSidebar: false,

            BUTTON_TYPES,
            USER_MANAGER_HIERARCHY,
        };
    },
    created() {
        this.fetchTileData();
    },
    methods: {
        async fetchTileData() {
            try {
                this.$emit('isDataLoadingUpd', true);
                this.$Progress.start();
                if (this.userManagerHierarchy === USER_MANAGER_HIERARCHY.USER) {
                    const userInfoResponse = await userManagementHTTP.getUser(
                        this.$route.params.id,
                        USER_MANAGER_HIERARCHY.USER,
                    );
                    this.accounts = userInfoResponse.data.user_info.account_ids;
                    [this.currentAccId] = this.accounts;
                } else {
                    this.currentAccId = [this.$route.params.id];
                }
                let transactionsArray = [];
                const transactionHistoryResponse = await ordersHTTP.getTransactions(
                    this.currentAccId,
                    USER_MANAGER_HIERARCHY.ACCOUNT,
                    'DESC',
                );
                const transactionResponseV4 = transactionHistoryResponse?.data?.transactions.map(
                    transactionJSON => new TransactionV4(transactionJSON),
                );

                const filteredTransactionHistories = transactionResponseV4.filter(
                    transaction =>
                        transaction.paymentTransactionType === PAYMENT_TRANSACTION_TYPE.RESERVE ||
                        transaction.paymentTransactionType === PAYMENT_TRANSACTION_TYPE.DIRECT_DEBIT ||
                        (transaction.paymentTransactionType === PAYMENT_TRANSACTION_TYPE.REFUND &&
                            this.isRefundTransactionVisible),
                );

                transactionsArray = filteredTransactionHistories.map(transaction => ({
                    ...transaction,
                    refundAction: true,
                    amountFormatted: formatAmountBasedOnCurrency(transaction.amount, transaction.currency),
                    remainingBalance: formatAmountBasedOnCurrency(transaction.remainingBalance, transaction.currency),
                    state: transaction.partiallyRefunded ? CHARGE_STATES.PARTIALLY_REFUNDED : transaction.state,
                }));

                transactionsArray = transactionsArray.filter(t => t.state !== CHARGE_STATES.UNPAID_CANCELED);

                [this.selectedTransactionHistory] = transactionsArray;

                this.billing.isPaid = this.selectedTransactionHistory?.paymentResponse?.status === 'Success';
                if (this.billing.isPaid) {
                    this.billing.amount = localeLibrary.getFormattedAmount(0);
                } else {
                    this.billing.amount = this.selectedTransactionHistory.amountFormatted;
                }

                this.$Progress.finish();
            } catch (error) {
                this.$alert(this.$i18n.t('alertMessage.failedToLoadNecessaryData'));
                this.$Progress.fail();
            } finally {
                this.$emit('isDataLoadingUpd', false);
            }
        },
        async onDownloadButtonClicked() {
            const { id } = this.selectedTransactionHistory;
            this.$Progress.start();
            try {
                const response = await HTTPCustomerCare.downloadInvoice(
                    this.currentAccId,
                    USER_MANAGER_HIERARCHY.ACCOUNT,
                    id,
                );

                if (response.data.pdf_link) {
                    window.open(response.data.pdf_link, '_blank');
                }

                this.$Progress.finish();
            } catch (error) {
                if (error.response?.data.code === luaErrors.BILLING_V4.FILE_NOT_FOUND_ON_S3.code) {
                    this.$alert(this.$i18n.t('customerCare.billingStatementDownloadNotFoundInvoice'));
                } else {
                    this.$alert(this.$i18n.t('customerCare.billingStatementDownloadError'));
                }
                this.$Progress.fail();
            }
        },
    },
};
