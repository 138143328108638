<template>
    <CommonTemplateListPageWrapper
        :pageTitle="pageTitle"
        :addButtonLabel="addButtonLabel"
        :editRouteName="editRouteName"
        :entities="entities"
        :overviewContentBuilder="overviewContentBuilder"
        :pagesWithImplementedDraft="pagesWithImplementedDraft"
        :entitiesApiResponse="allTemplatesApiResponse"
        :entityKeyDepth="2"
        :listViewColumns="listViewColumns"
        :writePermission="writePermission"
        @delete="onDelete"
        @approve="onApprove"
    />
</template>

<script>
// components
import CommonTemplateListPageWrapper from '@/__new__/features/template/CommonTemplateListPageWrapper.vue';
// vuex
import Actions, { Getters } from '@/store/mutation-types';
import { mapActions, mapGetters } from 'vuex';
// http
import { updateTemplateState } from '@/__new__/services/dno/privacyConsent/http/templates';
// helpers
import RouteNames from '@/router/routeNames';
import ENTITY_TYPES from '@/common/entities/entityTypes';
import { EntityActions, EntityStateMapping } from '@/common/baseStatesHelper';
import tableColumnType from '@/common/filterTable';
import { PLURALIZATION } from '@/common/locale/labelSingularOrPlural';
import { ALERT_TYPES } from '@/common/alerts/Alert';
import { overviewContentBuilder } from '@/__new__/features/template/common/templateHelper';
// constants
import { TEMPLATE_TYPES_MAP } from '@/__new__/services/dno/template/models/templateTypes';
import { TEMPLATE_STATES_NUMERIC } from '@/__new__/services/dno/template/models/templateStates';

export default {
    name: 'ConsentTemplatesListPage',
    components: {
        CommonTemplateListPageWrapper,
    },
    data() {
        return {
            overviewContentBuilder,
            pageTitle: this.$i18n.tc('commonTemplate.template', PLURALIZATION.PLURAL),
            addButtonLabel: this.$i18n.t('commonTemplate.newTemplate'),
            editRouteName: RouteNames.CONSENT_TEMPLATE_EDITOR,
            writePermission: 'PartyPrivacyProfileSpecificationWrite',
            pagesWithImplementedDraft: [ENTITY_TYPES.PARTY_PRIVACY_PROFILE_SPECIFICATION],
            templatesRequestData: {
                types: [TEMPLATE_TYPES_MAP.POLICY_SPECIFICATION, TEMPLATE_TYPES_MAP.PRESET],
            },
            ENTITIES_TYPES_MAP: {
                template: 'productCatalog.entities.template',
                policy_specification: 'consent.partyPrivacyProfileSpecification',
            },
        };
    },
    computed: {
        ...mapGetters('privacy', {
            allNonPresetTemplates: Getters.GET_PRIVACY_NOT_PRESET_TEMPLATES,
            allPresetTemplates: Getters.GET_PRIVACY_ALL_TEMPLATES_NOT_DELETED,
            allTemplatesApiResponse: Getters.GET_PRIVACY_ALL_TEMPLATES_API_RESPONSE,
        }),
        entities() {
            return Object.values(this.allNonPresetTemplates).map(template => ({
                ...template,
                presetTemplates: template.presetTemplates.map(pt => {
                    const presetRef = this.allPresetTemplates.find(item => item.id === pt.id);
                    return {
                        ...presetRef,
                        name: presetRef?.name,
                        externalId: presetRef?.name,
                    };
                }),
                entityTypeName: this.$i18n.tc(this.ENTITIES_TYPES_MAP[template.entityType]),
                usesPresetTemplates: template.presetTemplates.length
                    ? this.$i18n.t('generic.yes')
                    : this.$i18n.t('generic.no'),
                allowedActionsExternal: [
                    ...(template.state === TEMPLATE_STATES_NUMERIC.UNAPPROVED ? [EntityActions.APPROVE] : []),
                    EntityActions.CLONE,
                    EntityActions.EDIT,
                    EntityActions.DELETE,
                    EntityActions.READ_ONLY,
                ],
            }));
        },
        listViewColumns() {
            return [
                {
                    name: this.$i18n.t('productCatalog.templates.templateType'),
                    key: 'entityTypeName',
                    field: 'entityTypeName',
                    filterType: tableColumnType.TEXT_LIMITED_OPTIONS,
                    limitedOptions: [...new Set(this.entities.map(e => e.entityTypeName))],
                },
                {
                    name: this.$i18n.t('productCatalog.templates.usesPresets'),
                    key: 'usesPresetTemplates',
                    field: 'usesPresetTemplates',
                    filterType: tableColumnType.TEXT_LIMITED_OPTIONS,
                },
            ];
        },
    },
    created() {
        this.fetchTemplates();
    },
    methods: {
        ...mapActions('privacy', {
            requestAllTemplates: Actions.PRIVACY_REQUEST_ALL_TEMPLATES,
        }),
        async fetchTemplates() {
            await this.$withProgressBar(
                async () => {
                    await this.requestAllTemplates(this.templatesRequestData);
                },
                {
                    errorHandler: () => {
                        this.$eventBus.$emit('showAlert', {
                            message: this.$i18n.t('alertMessage.failedToLoadNecessaryData'),
                        });
                    },
                },
            );
        },
        async onDelete(template) {
            await this.$withProgressBar(
                async () => {
                    await updateTemplateState(
                        template.id,
                        template.entityType,
                        TEMPLATE_STATES_NUMERIC.ARCHIVED,
                        template.updateTime,
                    );
                    this.fetchTemplates();
                    this.$eventBus.$emit('showAlert', {
                        message: this.$i18n.t('alertMessage.successMessageWithoutRedirect', {
                            action: this.$i18n.t('generic.stateMap.deleted').toLowerCase(),
                            entityName: template.name,
                        }),
                        type: ALERT_TYPES.success,
                    });
                },
                {
                    errorHandler: () => {
                        this.$eventBus.$emit('showAlert', {
                            message: this.$i18n.t('alertMessage.errorDoingSmthTryAgain', {
                                action: this.$i18n.t('generic.deleting'),
                                entityName: template.name,
                            }),
                        });
                    },
                },
            );
        },
        async onApprove(template) {
            await this.$withProgressBar(
                async () => {
                    await updateTemplateState(
                        template.id,
                        template.entityType,
                        EntityStateMapping.APPROVED,
                        template.updateTime,
                    );
                    this.fetchTemplates();
                    this.$eventBus.$emit('showAlert', {
                        message: this.$i18n.t('alertMessage.successMessageWithoutRedirect', {
                            action: this.$i18n.t('generic.stateMap.approved').toLowerCase(),
                            entityName: template.name,
                        }),
                        type: ALERT_TYPES.success,
                    });
                },
                {
                    errorHandler: () => {
                        this.$eventBus.$emit('showAlert', {
                            message: this.$i18n.t('alertMessage.errorDoingSmthTryAgain', {
                                action: this.$i18n.t('generic.approving'),
                                entityName: template.name,
                            }),
                        });
                    },
                },
            );
        },
    },
};
</script>
