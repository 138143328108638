import { REPORT_ENGINE } from '@/__new__/services/dno/reports/models/Report';
import { getQrdArchiveReportStatus, startQrdArchiveCreation } from '@/__new__/services/dno/reports/http/qrdReports';
import { getOrdArchiveReportStatus, startOrdArchiveCreation } from '@/__new__/services/dno/reports/http/ordReports';
import type { BuiltReportRequestParams } from '@/__new__/features/reports/common/reportsStateHelper';
import type { ReportArchive } from '@/__new__/features/reports/common/reportArchiveHelper';

export function startArchiveCreation({ reportEngine, ...data }: BuiltReportRequestParams) {
    switch (reportEngine) {
        case REPORT_ENGINE.QRD:
            return startQrdArchiveCreation(data);

        case REPORT_ENGINE.ORD:
            return startOrdArchiveCreation(data);

        default:
            return Promise.reject(new Error(`Unknown type: ${reportEngine}.`));
    }
}

export function getArchiveReportStatus({ type, id }: ReportArchive) {
    switch (type) {
        case REPORT_ENGINE.QRD:
            return getQrdArchiveReportStatus(id);

        case REPORT_ENGINE.ORD:
            return getOrdArchiveReportStatus(id);

        default:
            return Promise.reject(new Error(`Unknown type: ${type}.`));
    }
}

export function getArchiveReportStatusUrl({ type, id }: ReportArchive): string | Error {
    switch (type) {
        case REPORT_ENGINE.QRD:
            return `/v3reports/archivestatusqrd?taskId=${id}`;

        case REPORT_ENGINE.ORD:
            return `/v3reports/archivestatusord?taskId=${id}`;

        default:
            throw new Error(`Unknown type: ${type}.`);
    }
}

export default {
    startArchiveCreation,
    getArchiveReportStatus,
    getArchiveReportStatusUrl,
};
