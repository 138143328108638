<template>
    <div>
        <div
            v-t="flagTitle"
            class="table-title"
        />
        <div class="primary-text">
            {{ value.flag }}
        </div>
    </div>
</template>

<script>
import { PC_CONDITION_TYPES } from '@/__new__/services/dno/charging/common/chargingSpecificationHelper';

export default {
    name: 'FlagValue',
    props: {
        value: {
            type: Object,
            required: true,
        },
    },
    computed: {
        flagTitle() {
            return this.value?.type === PC_CONDITION_TYPES.SUBSCRIBER_FLAG
                ? this.$i18n.t('charging.chargingSpecifications.editor.subscriberFlag')
                : this.$i18n.t('charging.chargingSpecifications.editor.bucketFlag');
        },
    },
};
</script>
