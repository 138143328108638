<template>
    <div class="section-layout">
        <TableFiltersRenderless :entities="tableData">
            <template
                #default="{
                    // state
                    allFilters,
                    filteredEntities,
                    // methods
                    onFilterAdded,
                    removeFilter,
                    removeAllFilters,
                    computedFilterValue,
                }"
            >
                <div>
                    <div class="d-flex align-items-center justify-content-between position-relative mb-2">
                        <h2 class="component-title-um text--md mb-0">
                            {{ $i18n.t('customerCare.rewardsHistory') }}
                        </h2>
                        <div class="d-flex">
                            <ResponseModalButton
                                :response="payoutsApiResponse"
                                :title="$i18n.t('customerCare.rewardsHistory')"
                            />
                            <FilterTable
                                :columns="tableColumns"
                                :multiselectWidth="{ width: '12rem' }"
                                @filterAdded="onFilterAdded"
                            >
                                <template #button="slotProps">
                                    <IconButton
                                        :label="$i18n.t('generic.filter')"
                                        :icon="ICON_TYPES.FILTER"
                                        data-test-id="filter-icon-btn"
                                        @iconClick="slotProps.clickHandler"
                                    />
                                </template>
                            </FilterTable>
                        </div>
                    </div>

                    <div class="position-relative mb-2">
                        <div class="tags-container">
                            <Tag
                                v-for="(filter, index) in allFilters"
                                :key="index"
                                class="tag-margins"
                                :label="getFilterLabel(filter, computedFilterValue)"
                                @closeIconClick="removeFilter(index)"
                            />
                        </div>

                        <span
                            v-if="allFilters.length > 1"
                            v-t="'generic.clearAll'"
                            class="clear-all-filters m-2 ml-4"
                            @click="removeAllFilters"
                        />
                    </div>

                    <AppTable
                        :entities="filteredEntities"
                        :isDefaultHoverEnabled="true"
                        :isCustomButtonPassed="true"
                        :disableBoxShadow="true"
                        :isPaginationEnabled="true"
                        :canSelectColumns="true"
                        :newDesign="true"
                        :columnsData="tableColumns"
                        tableKey="user/rewards-history"
                        @selectedEntityModel="openSidebar"
                    >
                        <template #state="{ entity }">
                            <div class="d-table">
                                <AppLabel
                                    :title="entity.stateLabel"
                                    :color="entity.stateColor"
                                />
                            </div>
                        </template>
                    </AppTable>
                </div>
            </template>
        </TableFiltersRenderless>
        <AppAditionalSidebar
            :visible="showSidebar"
            @input="closeSidebar"
        >
            <template #header>
                <h2 class="lf-table-title mb-2 text-uppercase">
                    {{ $i18n.t('generic.rewardHistoryDetails') }}
                </h2>
            </template>

            <template #content>
                <dl
                    v-if="selectedItem"
                    class="sidebar-data"
                >
                    <dt>{{ $i18n.t('generic.rewardRuleId') }}</dt>
                    <dd class="mb-0">
                        <router-link
                            :to="{
                                name: RouteNames.REWARD_EDITOR,
                                params: {
                                    id: selectedItem.rewardRule.id,
                                },
                            }"
                            target="_blank"
                        >
                            {{ selectedItem.rewardRule.id }}
                        </router-link>
                    </dd>

                    <dt>{{ $i18n.t('generic.rewardRuleDetails') }}</dt>
                    <dd>
                        <AppJSON
                            v-if="selectedItem.rewardRule.data"
                            :value="selectedItem.rewardRule.data"
                            textareaHeight="20rem"
                        />
                        <template v-else>
                            {{ $i18n.t('generic.empty') }}
                        </template>
                    </dd>

                    <dt>{{ $i18n.t('generic.payoutProps') }}</dt>
                    <dd>
                        <AppJSON
                            :value="selectedItem.props"
                            textareaHeight="20rem"
                        />
                    </dd>

                    <template v-if="isOrchestrationEngineEnabled">
                        <dt>{{ $i18n.t('generic.orchestrationExecId') }}</dt>
                        <dd>
                            <router-link
                                v-if="selectedItem.executionId && selectedItem.executionPlan"
                                :to="{
                                    name: RouteNames.ORCHESTRATION_ENGINE_EXECUTION_DETAILS,
                                    params: {
                                        id: selectedItem.executionPlan,
                                        execId: selectedItem.executionId,
                                    },
                                }"
                                target="_blank"
                            >
                                {{ selectedItem.executionId }}
                            </router-link>
                            <template v-else>
                                {{ $i18n.t('generic.none') }}
                            </template>
                        </dd>
                    </template>
                </dl>
            </template>
        </AppAditionalSidebar>
    </div>
</template>

<script>
// components
import AppAditionalSidebar from '@/components/partials/AppAditionalSidebar.vue';
import AppTable from '@/components/partials/AppTable.vue';
import AppLabel from '@/components/partials/AppLabel.vue';
import FilterTable from '@/components/partials/FilterTable.vue';
import IconButton from '@/components/partials/IconButton.vue';
import { ICON_TYPES } from '@/common/iconHelper';
import TableFiltersRenderless from '@/components/filters/TableFiltersRenderless.vue';
import tableColumnType from '@/common/filterTable';
import Tag from '@/components/partials/inputs/Tag.vue';
import AppJSON from '@/components/partials/AppJSON.vue';
import ResponseModalButton from '@/components/partials/ResponseModalButton.vue';

// http
import { getRewardHistoryV4, getRewardRulesV4 } from '@/__new__/services/dno/rewards/http/rewards';
import { getExecution } from '@/__new__/services/dno/orchestrationengine/http/orchestration-engine';

// helpers
import PayoutRecord from '@/__new__/services/dno/rewards/models/PayoutRecord';
import RewardRule from '@/__new__/services/dno/rewards/models/RewardRule';
import {
    payoutTypeMap,
    payoutProvidedStateMap,
    payoutProvidedStateColorMap,
} from '@/modules/rewards/common/payoutsHelper';
import RouteNames from '@/router/routeNames';
import permissionsService, { isUserAllowed } from '@/services/permissions/permissions.service';

export default {
    name: 'RewardsHistory',
    components: {
        AppAditionalSidebar,
        AppTable,
        AppLabel,
        AppJSON,
        FilterTable,
        IconButton,
        TableFiltersRenderless,
        Tag,
        ResponseModalButton,
    },
    props: {
        id: {
            required: true,
            type: String,
        },
        idType: {
            required: true,
            type: Number,
        },
    },
    data() {
        return {
            payouts: [],
            payoutsApiResponse: null,
            showSidebar: false,
            selectedItem: null,
            ICON_TYPES,
            RouteNames,
            isOrchestrationEngineEnabled:
                permissionsService.orchestrationEngineEnabled() &&
                isUserAllowed('OrchestrationPlansRead', 'OrchestrationPlansWrite'),
        };
    },
    computed: {
        tableColumns() {
            return [
                {
                    name: this.$i18n.t('generic.rewardRuleName'),
                    key: 'rewardRuleName',
                    field: 'rewardRuleName',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('generic.payoutName'),
                    key: 'name',
                    field: 'name',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('generic.payoutType'),
                    key: 'typeLabel',
                    field: 'typeLabel',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('generic.time'),
                    key: 'time',
                    field: 'time',
                    filterType: tableColumnType.DATE,
                },
                {
                    name: this.$i18n.t('generic.state'),
                    key: 'state',
                    field: 'stateLabel',
                    filterType: tableColumnType.TEXT_LIMITED_OPTIONS,
                    limitedOptions: [this.$i18n.t('generic.stateMap.success'), this.$i18n.t('generic.stateMap.failed')],
                },
            ];
        },
        tableData() {
            return this.payouts.map(payout => ({
                ...payout,
                rewardRuleName: payout.rewardRule.name, // Used for table filtering.
                typeLabel: this.$i18n.t(payoutTypeMap.get(payout.type)),
                stateLabel: this.$i18n.t(payoutProvidedStateMap.get(payout.state)),
                stateColor: payoutProvidedStateColorMap.get(payout.state),
            }));
        },
    },
    async created() {
        await this.fetchPayoutsHistory();
        await Promise.all([this.fetchPayoutsRewardRuleData(), this.fetchPayoutsOEPlan()]);
    },
    methods: {
        closeSidebar() {
            this.showSidebar = false;
        },
        openSidebar(entity) {
            this.selectedItem = entity;
            this.showSidebar = true;
        },
        getFilterLabel(filter, computedFilterValue) {
            return `${filter.column.name} ${this.$i18n.t(filter.condition.i18nLabel)} ${computedFilterValue(filter)}`;
        },
        async fetchPayoutsRewardRuleData() {
            await Promise.all(
                this.payouts.map(async payout => {
                    if (payout.rewardRuleId) {
                        const reward = await this.fetchRewardRule(payout.rewardRuleId);
                        payout.rewardRule = new RewardRule(
                            (reward && RewardRule.remapRewardRuleFromBe(reward)) || { id: payout.rewardRuleId },
                        );
                    }
                }),
            );
        },
        async fetchPayoutsOEPlan() {
            if (this.isOrchestrationEngineEnabled) {
                await Promise.all(
                    this.payouts.map(async payout => {
                        if (payout.executionId) {
                            payout.executionPlan = await this.fetchOEPlanId(payout.executionId);
                        }
                    }),
                );
            }
        },
        async fetchPayoutsHistory() {
            await this.$withProgressBar(
                async () => {
                    this.payoutsApiResponse = await getRewardHistoryV4({
                        targetId: this.id,
                        targetType: this.idType,
                    });
                    if (Array.isArray(this.payoutsApiResponse?.data?.payouts)) {
                        this.payouts = this.payoutsApiResponse.data.payouts.map(
                            payout => new PayoutRecord(PayoutRecord.remapPayoutfromBe(payout)),
                        );
                    }
                },
                {
                    errorHandler: error => {
                        this.$showErrorAlert({
                            message: this.$i18n.t(
                                'alertMessage.userManagement.somethingWentWrongFetchingRewardsHistoryData',
                            ),
                        });
                        this.payoutsApiResponse = error.response;
                    },
                },
            );
        },
        async fetchRewardRule(ruleId) {
            let rewardRule;

            await this.$withProgressBar(
                async () => {
                    const response = await getRewardRulesV4([ruleId]);
                    const { reward_rule_v4_by_id: rewardRules } = { ...response?.data };
                    rewardRule = rewardRules?.[ruleId];
                },
                {
                    errorHandler: () =>
                        this.$showErrorAlert({
                            message: this.$i18n.t(
                                'alertMessage.userManagement.somethingWentWrongFetchingRewardsHistoryRelatedData',
                            ),
                        }),
                },
            );

            return rewardRule;
        },
        async fetchOEPlanId(executionId) {
            let id;

            await this.$withProgressBar(
                async () => {
                    const response = await getExecution(executionId);
                    const { plan_id: pId } = { ...response?.data?.execution };
                    id = pId;
                },
                {
                    errorHandler: () =>
                        this.$showErrorAlert({
                            message: this.$i18n.t(
                                'alertMessage.userManagement.somethingWentWrongFetchingRewardsHistoryRelatedData',
                            ),
                        }),
                },
            );

            return id;
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/layout';
@import '~@/assets/scss/palette';
@import '~@/assets/scss/typographyV2';

.lf-table-title {
    color: $blue50;
}

.sidebar-data {
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: baseline;
    gap: 0.875rem $spacing-m;
}

dt {
    font-weight: 600;
    white-space: nowrap;
}

dd {
    margin-bottom: 0;
    font-size: $text-sm;
}

pre {
    margin-bottom: 0;
    padding: $spacing-xxs $spacing-xs;
    background-color: $dirty-white;
}
</style>
