<template>
    <TemplateEditorBase :isPresetEditor="false" />
</template>

<script>
import TemplateEditorBase from '@/__new__/features/pc/templates/TemplateEditorBase.vue';

export default {
    name: 'TemplateEditor',

    components: {
        TemplateEditorBase,
    },
};
</script>
