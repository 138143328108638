<template>
    <div>
        <TableFiltersRenderless :entities="transactionHistoriesFormatted">
            <template
                #default="{
                    // state
                    allFilters,
                    filteredEntities,
                    // methods
                    onFilterAdded,
                    removeFilter,
                    removeAllFilters,
                }"
            >
                <div>
                    <div class="row mb-2 align-items-center table-header-row justify-content-between">
                        <div class="component-title-um">
                            {{ $i18n.t('customerCare.account.paymentTransactionHistory') }}
                        </div>
                        <div class="display-flex">
                            <ResponseModalButton
                                :response="transactionHistoriesApiResponse"
                                :title="$i18n.t('customerCare.account.paymentTransactionHistory')"
                            />
                            <div class="filters-control position-relative">
                                <FilterTable
                                    :columns="columnsData"
                                    :multiselectWidth="{ width: '12rem' }"
                                    @filterAdded="onFilterAdded"
                                >
                                    <template #button="slotProps">
                                        <IconButton
                                            :label="$i18n.t('generic.filter')"
                                            :icon="ICON_TYPES.FILTER"
                                            @iconClick="slotProps.clickHandler"
                                        />
                                    </template>
                                </FilterTable>
                            </div>
                        </div>
                    </div>

                    <TableFiltersTags
                        :filterTableMixin="{ allFilters }"
                        class="mt-1 mb-3"
                        @removeFilter="removeFilter"
                        @removeAllFilters="removeAllFilters"
                    />

                    <AppTable
                        :entities="filteredEntities"
                        :selectedEntityId="selectedEntityId"
                        :isDefaultHoverEnabled="true"
                        :isCustomButtonPassed="true"
                        :disableBoxShadow="true"
                        :canSelectColumns="true"
                        :newDesign="true"
                        :columnsData="columnsData"
                        tableKey="account/transaction-history"
                        @selectEntity="e => [selectTransaction(e), showModal()]"
                    >
                        <template #state="{ entity }">
                            <TransactionHistoryStatusIndicator :status="entity.state" />
                        </template>
                        <template #customRowButtons="{ entity }">
                            <IconButton
                                v-if="isRefundEnabled && entity.refundAction"
                                data-test-id="acc-payment-history-refund-btn"
                                :label="$i18n.t('customerCare.paymentStatuses.refund')"
                                :icon="ICON_TYPES.REFUND"
                                @iconClick="refundPayment(entity)"
                            />
                        </template>
                    </AppTable>
                </div>
            </template>
        </TableFiltersRenderless>

        <AppAditionalSidebar
            v-model="showAditionalSidebar"
            class="transaction-sidebar"
        >
            <template
                v-if="selectedTransactionHistory"
                #content
            >
                <div class="modal-title">
                    {{ get(selectedTransactionHistory, 'payment_details.entity_name', $i18n.t('generic.N/A')) }}
                </div>

                <div class="modal-row d-flex align-items-center">
                    <div class="row-title">
                        {{ $i18n.t('customerCare.transactionHistory.externalPaymentId') }}
                    </div>

                    <div class="row-value">
                        {{ get(selectedTransactionHistory, 'externalPaymentId', $i18n.t('generic.N/A')) }}
                    </div>
                </div>

                <div class="modal-row d-flex align-items-center">
                    <div class="row-title">
                        {{ $i18n.t('customerCare.transactionHistory.transactionId') }}
                    </div>

                    <div class="row-value">
                        {{ get(selectedTransactionHistory, 'transaction_id', $i18n.t('generic.N/A')) }}
                    </div>
                </div>

                <div
                    v-if="selectedTransactionHistory.create_timestamp"
                    class="modal-row d-flex align-items-center"
                >
                    <div class="row-title">
                        {{ $i18n.t('customerCare.transactionHistory.timestamp') }}
                    </div>

                    <div class="row-value">
                        {{
                            $localeLibrary.getFormattedDateAndTimeWithSecondsPrecision(
                                selectedTransactionHistory.create_timestamp,
                            )
                        }}
                    </div>
                </div>

                <div class="modal-row d-flex align-items-center">
                    <div class="row-title">
                        {{ $i18n.t('customerCare.transactionHistory.chargeFor') }}
                    </div>

                    <div class="row-value">
                        {{ get(selectedTransactionHistory, 'payment_details.entity_name', $i18n.t('generic.N/A')) }}
                    </div>
                </div>

                <div class="modal-row d-flex align-items-center">
                    <div class="row-title">
                        {{ $i18n.t('generic.type') }}
                    </div>

                    <div class="row-value">
                        {{ getPaymentTransactionTypeInString(selectedTransactionHistory.transaction_type) }}
                    </div>
                </div>

                <div class="modal-row d-flex align-items-center">
                    <div class="row-title">
                        {{ $i18n.t('customerCare.userInformation.hold') }}
                    </div>

                    <div class="row-value">
                        {{
                            Object.hasOwnProperty.call(selectedTransactionHistory, 'is_hold')
                                ? getBeautifulBoolean(get(selectedTransactionHistory, 'is_hold'))
                                : $i18n.t('generic.N/A')
                        }}
                    </div>
                </div>

                <div class="modal-row d-flex align-items-center">
                    <div class="row-title">
                        {{ $i18n.t('customerCare.transactionHistory.amount') }}
                    </div>

                    <div class="row-value">
                        {{ selectedTransactionHistory.amount }}
                    </div>
                </div>

                <div class="modal-row d-flex align-items-center">
                    <div class="row-title">
                        {{ $i18n.t('customerCare.transactionHistory.method') }}
                    </div>

                    <div class="row-value">
                        {{ selectedTransactionHistory.payment_method }}
                    </div>
                </div>

                <div class="modal-row d-flex align-items-center">
                    <div class="row-title">
                        {{ $i18n.t('customerCare.userInformation.last4Digits') }}
                    </div>

                    <div class="row-value">
                        {{ get(selectedTransactionHistory, 'card_type') }} -
                        {{ get(selectedTransactionHistory, 'last_4', $i18n.t('generic.N/A')) }}
                    </div>
                </div>

                <div class="modal-row d-flex align-items-center">
                    <div class="row-title">
                        {{ $i18n.t('generic.status') }}
                    </div>

                    <div class="row-value">
                        <TransactionHistoryStatusIndicator :status="selectedTransactionHistory.state" />
                    </div>
                </div>

                <div
                    v-if="failedReasonMessage"
                    class="modal-row d-flex align-items-center"
                >
                    <div class="row-title">
                        {{ $i18n.t('generic.failedReason') }}
                    </div>

                    <div class="row-value">
                        {{ failedReasonMessage }}
                    </div>
                </div>

                <template v-if="hasTaxInfo">
                    <div class="all-caps-text">
                        {{ $i18n.t('generic.taxDetails') }}
                    </div>

                    <div
                        v-if="hasTotalTaxAmount"
                        class="modal-row d-flex align-items-center"
                    >
                        <div class="row-title">
                            {{ $i18n.t('generic.amount') }}
                        </div>

                        <div class="row-value">
                            {{ selectedTransactionHistory.tax_info.total_tax_amount }}
                        </div>
                    </div>

                    <template v-if="hasTaxPresentations">
                        <div
                            v-for="(tax, index) in getTaxPresentantions"
                            :key="index"
                            class="modal-row d-flex align-items-center"
                        >
                            <div class="row-title">
                                {{ tax.customer_text }}
                            </div>

                            <div class="row-value">
                                {{ tax.amount }}
                            </div>
                        </div>
                    </template>
                </template>
            </template>
        </AppAditionalSidebar>
    </div>
</template>

<script>
// COMPONENTS
import AppTable from '@/components/partials/AppTable.vue';
import TransactionHistoryStatusIndicator, {
    STATUS_CODES,
} from '@/__new__/features/customerCare/account/TransactionHistoryStatusIndicator.vue';
import AppAditionalSidebar from '@/components/partials/AppAditionalSidebar.vue';
import IconButton from '@/components/partials/IconButton.vue';
import TableFiltersRenderless from '@/components/filters/TableFiltersRenderless.vue';
import TableFiltersTags from '@/components/filters/TableFiltersTags.vue';
import FilterTable from '@/components/partials/FilterTable.vue';
import ResponseModalButton from '@/components/partials/ResponseModalButton.vue';

// HELPERS
import { uuidV4, checkObjectPath } from '@/common/utils';
import { getBeautifulBoolean, formatAmountBasedOnCurrency } from '@/common/formatting';
import get from 'lodash/get';
import tableColumnType from '@/common/filterTable';
import permissionsService, { isUserAllowed } from '@/services/permissions/permissions.service';
import { ICON_TYPES } from '@/common/iconHelper';
import { getResponseMessageFromModuleCode } from '@/common/userManager/transactionHistoryV4Helper';

const PAYMENT_TRANSACTION_TYPE_CODES = {
    ORDER_PAYMENT: 1,
    MICROCHARGING_PAYMENT: 2,
    MANUAL_PAYMENT: 3,
    VALIDITY_CHECK: 4,
    PAYMENT_RETURN: 5,
};

export default {
    name: 'AccountPaymentHistory',
    components: {
        AppTable,
        IconButton,
        ResponseModalButton,
        TransactionHistoryStatusIndicator,
        AppAditionalSidebar,
        TableFiltersRenderless,
        TableFiltersTags,
        FilterTable,
    },
    props: {
        transactionHistories: {
            required: true,
            type: Array,
        },
        transactionHistoriesApiResponse: {
            type: Object,
            default: null,
        },
    },
    data() {
        return {
            permissionsService,
            ICON_TYPES,
            selectedTransactionHistory: null,
            showAditionalSidebar: false,
            isRefundEnabled:
                permissionsService.paymentTransactionHistoryRefundEnabled() &&
                permissionsService.isUserLotusFlareAdmin() &&
                isUserAllowed('UMAccountPaymentTransactionHistoryIssueRefund'),
        };
    },
    computed: {
        getTaxPresentantions() {
            return this.selectedTransactionHistory?.tax_info?.invoice_presentation?.tax_presentations;
        },
        failedReasonMessage() {
            if (this.selectedTransactionHistory?.state === STATUS_CODES.FAIL) {
                const { msg, code, module: moduleName } = this.selectedTransactionHistory.failure_reason;

                return msg || getResponseMessageFromModuleCode(moduleName, code);
            }

            return '';
        },
        transactionHistoriesFormatted() {
            if (this.transactionHistories && this.transactionHistories.length) {
                return this.transactionHistories.map(transaction => ({
                    ...transaction,
                    transactionTypeMapped: this.getPaymentTransactionTypeInString(transaction.transaction_type),
                    amountFormatted: formatAmountBasedOnCurrency(transaction.amount, transaction.currency),
                    refundAction: this.showRefundButton(transaction.transaction_type),
                    id: uuidV4(),
                }));
            }

            return [];
        },
        columnsData() {
            return [
                {
                    name: this.$i18n.t('customerCare.transactionHistory.transactionId'),
                    key: 'transaction_id',
                    mapper: entity => get(entity, 'transaction_id', this.$i18n.t('generic.N/A')),
                    field: 'transaction_id',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('customerCare.transactionHistory.timestamp'),
                    key: 'create_timestamp',
                    mapper: entity => {
                        if (entity.create_timestamp) {
                            return this.$localeLibrary.getFormattedDateAndTimeWithSecondsPrecision(
                                entity.create_timestamp,
                            );
                        }

                        return '';
                    },
                    sortBy: entity => entity.create_timestamp,
                    field: 'create_timestamp',
                    filterType: tableColumnType.DATE,
                },
                {
                    name: this.$i18n.t('customerCare.transactionHistory.chargeFor'),
                    key: 'entity_name',
                    mapper: entity => get(entity, 'payment_details.entity_name', this.$i18n.t('generic.N/A')),
                    field: 'entity_name',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('generic.type'),
                    key: 'transactionTypeMapped',
                    field: 'transactionTypeMapped',
                    filterType: tableColumnType.TEXT_LIMITED_OPTIONS,
                    limitedOptions: Array.from(
                        new Set(this.transactionHistoriesFormatted.map(entity => entity.transactionTypeMapped)),
                    ),
                },
                {
                    name: this.$i18n.t('customerCare.userInformation.hold'),
                    key: 'is_hold',
                    mapper: entity =>
                        Object.hasOwnProperty.call(entity, 'is_hold')
                            ? this.getBeautifulBoolean(entity.is_hold)
                            : this.$i18n.t('generic.N/A'),
                    field: 'is_hold',
                    filterType: tableColumnType.TEXT_LIMITED_OPTIONS,
                    limitedOptions: Array.from(
                        new Set(
                            this.transactionHistoriesFormatted.map(entity =>
                                Object.hasOwnProperty.call(entity, 'is_hold')
                                    ? this.getBeautifulBoolean(entity.is_hold)
                                    : this.$i18n.t('generic.N/A'),
                            ),
                        ),
                    ),
                },
                {
                    name: this.$i18n.t('customerCare.transactionHistory.amount'),
                    key: 'amountFormatted',
                    sortBy: entity => entity.amount,
                    field: 'amount',
                    filterType: tableColumnType.NUMBER,
                },
                {
                    name: this.$i18n.t('customerCare.transactionHistory.method'),
                    key: 'payment_method',
                    field: 'payment_method',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('generic.status'),
                    key: 'state',
                    field: 'state',
                    filterType: tableColumnType.TEXT_LIMITED_OPTIONS,
                    limitedOptions: Array.from(new Set(this.transactionHistoriesFormatted.map(entity => entity.state))),
                },
            ];
        },
        hasTaxInfo() {
            return checkObjectPath(this.selectedTransactionHistory, 'tax_info');
        },
        hasTotalTaxAmount() {
            return checkObjectPath(this.selectedTransactionHistory, 'tax_info.total_tax_amount');
        },
        hasTaxPresentations() {
            return checkObjectPath(this.selectedTransactionHistory, 'tax_info.invoice_presentation.tax_presentations');
        },
        selectedEntityId() {
            return this.selectedTransactionHistory?.id || '';
        },
    },
    methods: {
        get,
        getBeautifulBoolean,
        showRefundButton(transactionType) {
            const allowedTransactionTypes = [
                PAYMENT_TRANSACTION_TYPE_CODES.ORDER_PAYMENT,
                PAYMENT_TRANSACTION_TYPE_CODES.MICROCHARGING_PAYMENT,
                PAYMENT_TRANSACTION_TYPE_CODES.MANUAL_PAYMENT,
            ];

            return this.isRefundEnabled && allowedTransactionTypes.includes(transactionType);
        },
        getPaymentTransactionTypeInString(id) {
            switch (id) {
                case PAYMENT_TRANSACTION_TYPE_CODES.ORDER_PAYMENT: {
                    return this.$i18n.t('customerCare.paymentStatuses.regular');
                }
                case PAYMENT_TRANSACTION_TYPE_CODES.MICROCHARGING_PAYMENT: {
                    return this.$i18n.t('customerCare.paymentStatuses.userBased');
                }
                case PAYMENT_TRANSACTION_TYPE_CODES.MANUAL_PAYMENT: {
                    return this.$i18n.t('customerCare.paymentStatuses.manualPayment');
                }
                case PAYMENT_TRANSACTION_TYPE_CODES.VALIDITY_CHECK: {
                    return this.$i18n.t('customerCare.paymentStatuses.validityCheck');
                }
                case PAYMENT_TRANSACTION_TYPE_CODES.PAYMENT_RETURN: {
                    return this.$i18n.t('customerCare.paymentStatuses.refund');
                }
                default:
                    return this.$i18n.t('generic.empty');
            }
        },
        refundPayment(e) {
            this.$emit('refundPayment', e);
        },
        showModal() {
            this.showAditionalSidebar = true;
        },
        selectTransaction(id) {
            this.selectedTransactionHistory = this.transactionHistoriesFormatted.find(
                transaction => transaction.id === id,
            );
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/palette';

.all-caps-text {
    margin-bottom: 1.125rem;
}

.modal-title {
    font-size: 1.375rem;
    font-weight: 600;
    color: $gray90;
    margin-bottom: 2.1875rem;
}

.modal-row {
    margin-bottom: 0.875rem;
}

.row-title {
    font-size: 0.75rem;
    font-weight: 600;
    width: 10rem;
}

.row-value {
    font-size: 0.875rem;
    width: 100%;
}
</style>
