import ENTITY_TYPES from '@/common/entities/entityTypes';

import {
    getOffers,
    addOffer,
    updateOffer,
    getOfferDraftList,
    deleteOfferDraft,
    getOfferDraft,
    setOfferDraft,
} from '@/__new__/services/dno/pc/http/offer';
import {
    getProducts,
    addProduct,
    updateProduct,
    getProductDraftList,
    deleteProductDraft,
    getProductDraft,
    setProductDraft,
} from '@/__new__/services/dno/pc/http/product';
import {
    getServices,
    addService,
    updateService,
    getServiceDraftList,
    deleteServiceDraft,
    getServiceDraft,
    setServiceDraft,
} from '@/__new__/services/dno/pc/http/service';
import {
    getResources,
    addResource,
    updateResource,
    getResourceDraftList,
    deleteResourceDraft,
    getResourceDraft,
    setResourceDraft,
} from '@/__new__/services/dno/pc/http/resource';
import {
    getCategories,
    getCategoryDraftList,
    deleteCategoryDraft,
    getCategoryDraft,
    setCategoryDraft,
} from '@/__new__/services/dno/pc/http/categories';
import { getFacets, addFacet, updateFacet } from '@/__new__/services/dno/pc/http/facets';

export function getEntities(entityType, entityIds = []) {
    if (entityType === ENTITY_TYPES.OFFER) {
        return getOffers(entityIds);
    }
    if (entityType === ENTITY_TYPES.PRODUCT) {
        return getProducts(entityIds);
    }
    if (entityType === ENTITY_TYPES.SERVICE) {
        return getServices(entityIds);
    }
    if (entityType === ENTITY_TYPES.RESOURCE) {
        return getResources(entityIds);
    }
    if (entityType === ENTITY_TYPES.CATEGORY) {
        return getCategories(entityIds);
    }
    if (entityType === ENTITY_TYPES.FACET) {
        return getFacets(entityIds);
    }
    return new Error('wrong entity type');
}

export function getEntityDrafts(entityType) {
    if (entityType === ENTITY_TYPES.OFFER) {
        return getOfferDraftList();
    }
    if (entityType === ENTITY_TYPES.PRODUCT) {
        return getProductDraftList();
    }
    if (entityType === ENTITY_TYPES.SERVICE) {
        return getServiceDraftList();
    }
    if (entityType === ENTITY_TYPES.RESOURCE) {
        return getResourceDraftList();
    }
    if (entityType === ENTITY_TYPES.CATEGORY) {
        return getCategoryDraftList();
    }
    return new Error('wrong entity type');
}

export function deleteEntityDraft(entityType, id) {
    if (entityType === ENTITY_TYPES.OFFER) {
        return deleteOfferDraft(id);
    }
    if (entityType === ENTITY_TYPES.PRODUCT) {
        return deleteProductDraft(id);
    }
    if (entityType === ENTITY_TYPES.SERVICE) {
        return deleteServiceDraft(id);
    }
    if (entityType === ENTITY_TYPES.RESOURCE) {
        return deleteResourceDraft(id);
    }
    if (entityType === ENTITY_TYPES.CATEGORY) {
        return deleteCategoryDraft(id);
    }
    return new Error('wrong entity type');
}

export function addEntity(entityData, entityType) {
    if (entityType === ENTITY_TYPES.OFFER) {
        return addOffer(entityData);
    }
    if (entityType === ENTITY_TYPES.PRODUCT) {
        return addProduct(entityData);
    }
    if (entityType === ENTITY_TYPES.SERVICE) {
        return addService(entityData);
    }
    if (entityType === ENTITY_TYPES.RESOURCE) {
        return addResource(entityData);
    }
    if (entityType === ENTITY_TYPES.FACET) {
        return addFacet(entityData);
    }
    return new Error('wrong entity type');
}

export function updateEntity(entityData, entityType, publishMessage) {
    if (entityType === ENTITY_TYPES.OFFER) {
        return updateOffer(entityData.id, entityData.version, entityData.data, publishMessage);
    }
    if (entityType === ENTITY_TYPES.PRODUCT) {
        return updateProduct(entityData.id, entityData.version, entityData.data, publishMessage);
    }
    if (entityType === ENTITY_TYPES.SERVICE) {
        return updateService(entityData.id, entityData.version, entityData.data);
    }
    if (entityType === ENTITY_TYPES.RESOURCE) {
        return updateResource(entityData.id, entityData.version, entityData.data);
    }
    if (entityType === ENTITY_TYPES.FACET) {
        return updateFacet(entityData.id, entityData.version, entityData.data);
    }
    return new Error('wrong entity type');
}

export function getEntityDraft(entityType, entityId) {
    if (entityType === ENTITY_TYPES.OFFER) {
        return getOfferDraft(entityId);
    }
    if (entityType === ENTITY_TYPES.PRODUCT) {
        return getProductDraft(entityId);
    }
    if (entityType === ENTITY_TYPES.SERVICE) {
        return getServiceDraft(entityId);
    }
    if (entityType === ENTITY_TYPES.RESOURCE) {
        return getResourceDraft(entityId);
    }
    if (entityType === ENTITY_TYPES.CATEGORY) {
        return getCategoryDraft(entityId);
    }
    return new Error('wrong entity type');
}

export function setEntityDraft(entityData, entityType) {
    if (entityType === ENTITY_TYPES.OFFER) {
        return setOfferDraft(entityData);
    }
    if (entityType === ENTITY_TYPES.PRODUCT) {
        return setProductDraft(entityData);
    }
    if (entityType === ENTITY_TYPES.SERVICE) {
        return setServiceDraft(entityData);
    }
    if (entityType === ENTITY_TYPES.RESOURCE) {
        return setResourceDraft(entityData);
    }
    if (entityType === ENTITY_TYPES.CATEGORY) {
        return setCategoryDraft(entityData);
    }
    return new Error('wrong entity type');
}

export default {
    getEntities,
    addEntity,
    updateEntity,
};
