import ActionFlag from '@/__new__/services/dno/charging/models/ActionFlag';
import ActionTrigger from '@/__new__/services/dno/charging/models/ActionTrigger';

export default class ActionModel {
    actionTrigger;

    actionFlag;

    constructor({ actionTrigger = {}, actionFlag = {} } = {}) {
        this.actionFlag = new ActionFlag(actionFlag);
        this.actionTrigger = new ActionTrigger(actionTrigger);
    }
}
