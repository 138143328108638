<template>
    <AbstractEditPageWrapper>
        <template #header>
            <AppHeader :pageTitle="pageTitle" />
        </template>

        <template #content>
            <div class="editor-section">
                <div class="d-flex align-items-center mb-3">
                    <div class="lf-subtitle mb-0">
                        {{ $i18n.t('generic.general') }}
                        <p
                            v-show="inEditMode && isRewardsDraftEnabled"
                            class="lf-secondary-text mb-0"
                        >
                            {{ $i18n.t('generic.id') }}: {{ $route.params.id }}
                        </p>
                        <p
                            v-show="inEditMode && isRewardsDraftEnabled"
                            class="lf-secondary-text mb-0"
                        >
                            {{ $i18n.t('generic.updateTime') }}:
                            {{ $localeLibrary.getFormattedDateAndTime(updateTime) }}
                        </p>
                        <p
                            v-show="inEditMode && isRewardsDraftEnabled"
                            class="lf-secondary-text mb-0"
                        >
                            {{ $i18n.t('generic.updateUser') }}: {{ updateName }}
                        </p>
                    </div>
                    <AppLabel
                        v-if="isDraft"
                        :title="$i18n.t('generic.stateMap.draft')"
                        :color="LABEL_COLOR.gray"
                        class="justify-content-center state-label ml-auto"
                    />
                    <AppLabel
                        v-if="isPublished"
                        :title="$i18n.t('generic.stateMap.published')"
                        :color="LABEL_COLOR.green"
                        class="justify-content-center state-label ml-auto"
                    />
                    <AppLabel
                        v-if="isUnpublished"
                        :title="$i18n.t('generic.stateMap.unpublishedChanges')"
                        :color="LABEL_COLOR.gray"
                        class="justify-content-center state-label ml-3"
                    />
                    <AppButton
                        v-if="allowEditDraftBtn"
                        :buttonType="BUTTON_TYPES.SECONDARY"
                        :label="$i18n.t('generic.editDraft')"
                        :class="['ml-3', { 'ml-auto': noLabels }]"
                        @click="reloadEditor(false)"
                    />
                    <AppButton
                        v-if="allowViewPublishedBtn"
                        :buttonType="BUTTON_TYPES.SECONDARY"
                        :label="$i18n.t('generic.viewPublished')"
                        :class="['ml-3', { 'ml-auto': noLabels }]"
                        @click="reloadEditor(true)"
                    />
                </div>
                <!-- hardcoded to english name for now -->
                <AppInputV3
                    v-model="prize.data.name[selectedLanguage]"
                    :placeholder="`${$i18n.t('generic.name')}`"
                    :label="$i18n.t('generic.name')"
                    :invalid="$v.prize.data.name[selectedLanguage].$error"
                    :disabled="readonly"
                    type="text"
                    class="editor-input-largest mb-3"
                />
                <!-- hardcoded to english description for now -->
                <AppTextareaV3
                    v-model="prize.data.description[selectedLanguage]"
                    :placeholder="`${$i18n.t('generic.addDescription')}`"
                    :label="$i18n.t('generic.description')"
                    :resizeNone="true"
                    :disabled="readonly"
                    class="editor-input-largest"
                />
                <div
                    v-if="!isEditing"
                    class="d-flex mt-3"
                >
                    <div class="sub-section-title">
                        {{ $i18n.t('rewards.editor.general.approveOnCreation') }}
                    </div>
                    <AppToggle
                        v-model="approveOnCreation"
                        :small="true"
                        :disabled="readonly"
                        class="group-checkbox"
                    />
                </div>
            </div>
            <div class="editor-section last-editor-section">
                <div
                    v-t="'rewards.prizeParameters'"
                    class="editor-section-heading"
                />
                <AppMultiselectV3
                    v-model="selectedPayout"
                    :options="payoutOptions"
                    :additionalLabel="$i18n.t('productCatalog.entities.payout')"
                    :placeholder="$i18n.t('prize.editor.selectPayout')"
                    :showLabels="false"
                    :small="true"
                    :allowEmpty="false"
                    :disabled="readonly"
                    :error="$v.selectedPayout.$error"
                    class="editor-input-largest mb-3"
                    label="name"
                    trackBy="id"
                />

                <div class="mb-3">
                    <div
                        v-t="'prize.editor.prizeWinningRules'"
                        class="row no-gutters label"
                    />
                    <div class="d-flex align-items-center prize-winning-rules-row-container">
                        <div class="inline-text">
                            {{ $i18n.t('prize.editor.thisPrizeCanBeWon') }}
                        </div>
                        <AppMultiselectV3
                            v-model="overallDurationUnlimited"
                            :options="limitOptions"
                            :showLabels="false"
                            :borderNone="true"
                            :small="true"
                            :allowEmpty="false"
                            :preselectFirst="true"
                            :disabled="readonly"
                            class="col-1 fit-content"
                            label="label"
                            trackBy="id"
                        />
                        <AppInputV3
                            v-show="overallDurationUnlimited && !overallDurationUnlimited.id"
                            v-model.number="prize.data.max_purchases_per_duration.overall.max"
                            :min="1"
                            :invalid="invalidPrizeWinningRules[0]"
                            :disabled="readonly"
                            type="number"
                            class="input-margin"
                            placeholder="0"
                            @input="onInputValidatePrizeWinningRules($event, 0)"
                        />
                        <div
                            v-if="overallDurationUnlimited && overallDurationUnlimited.id"
                            class="inline-text"
                        >
                            {{ $i18n.t('prize.editor.timesInAnyDuration') }}
                        </div>
                        <div
                            v-else
                            class="inline-text"
                        >
                            {{ $i18n.t('prize.editor.timesIn') }}
                        </div>
                        <AppInputV3
                            v-show="overallDurationUnlimited && !overallDurationUnlimited.id"
                            v-model.number="prize.data.max_purchases_per_duration.overall.number_of_durations"
                            :min="1"
                            :invalid="invalidPrizeWinningRules[1]"
                            :disabled="readonly"
                            type="number"
                            class="input-margin"
                            placeholder="0"
                            @input="onInputValidatePrizeWinningRules($event, 1)"
                        />
                        <AppMultiselectV3
                            v-show="overallDurationUnlimited && !overallDurationUnlimited.id"
                            v-model="overallDuration"
                            :options="durationsOptionsList"
                            :placeholder="$i18n.t('generic.type')"
                            :showLabels="false"
                            :borderNone="true"
                            :small="true"
                            :allowEmpty="false"
                            :preselectFirst="true"
                            :disabled="readonly"
                            class="col-1 fit-content"
                            label="label"
                            trackBy="id"
                        />
                    </div>
                    <div class="d-flex align-items-center prize-winning-rules-row-container mb-0">
                        <div class="inline-text">
                            {{ $i18n.t('prize.editor.thisPrizeCanBeWon') }}
                        </div>
                        <AppMultiselectV3
                            v-model="maxPurchaseUnlimited"
                            :options="limitOptions"
                            :showLabels="false"
                            :borderNone="true"
                            :small="true"
                            :allowEmpty="false"
                            :preselectFirst="true"
                            :disabled="readonly"
                            class="col-1 fit-content"
                            label="label"
                            trackBy="id"
                        />
                        <AppInputV3
                            v-show="maxPurchaseUnlimited && !maxPurchaseUnlimited.id"
                            v-model.number="prize.data.max_purchases"
                            :min="1"
                            :invalid="invalidPrizeWinningRules[2]"
                            :disabled="readonly"
                            type="number"
                            class="input-margin"
                            placeholder="0"
                            @input="onInputValidatePrizeWinningRules($event, 2)"
                        />
                        <div class="inline-text">
                            {{ $i18n.t('prize.editor.timesInTotal') }}
                        </div>
                    </div>
                </div>

                <div class="mb-3">
                    <div
                        v-t="'prize.editor.prizeWinningRulesPerUser'"
                        class="row no-gutters label"
                    />
                    <div class="d-flex align-items-center prize-winning-rules-row-container">
                        <div class="inline-text">
                            {{ $i18n.t('prize.editor.userCanWinThisPrize') }}
                        </div>
                        <AppMultiselectV3
                            v-model="perSubscriberDurationUnlimited"
                            :options="limitOptions"
                            :showLabels="false"
                            :borderNone="true"
                            :small="true"
                            :allowEmpty="false"
                            :preselectFirst="true"
                            :disabled="readonly"
                            class="col-1 fit-content"
                            label="label"
                            trackBy="id"
                        />
                        <AppInputV3
                            v-show="perSubscriberDurationUnlimited && !perSubscriberDurationUnlimited.id"
                            v-model.number="prize.data.max_purchases_per_duration.per_subscriber.max"
                            :min="1"
                            :invalid="invalidPrizeWinningRules[3]"
                            :disabled="readonly"
                            type="number"
                            class="input-margin"
                            placeholder="0"
                            @input="onInputValidatePrizeWinningRules($event, 3)"
                        />
                        <div
                            v-if="perSubscriberDurationUnlimited && perSubscriberDurationUnlimited.id"
                            class="inline-text"
                        >
                            {{ $i18n.t('prize.editor.timesInAnyDuration') }}
                        </div>
                        <div
                            v-else
                            class="inline-text"
                        >
                            {{ $i18n.t('prize.editor.timesIn') }}
                        </div>
                        <AppInputV3
                            v-show="perSubscriberDurationUnlimited && !perSubscriberDurationUnlimited.id"
                            v-model.number="prize.data.max_purchases_per_duration.per_subscriber.number_of_durations"
                            :min="1"
                            :invalid="invalidPrizeWinningRules[4]"
                            :disabled="readonly"
                            type="number"
                            class="input-margin"
                            placeholder="0"
                            @input="onInputValidatePrizeWinningRules($event, 4)"
                        />
                        <AppMultiselectV3
                            v-show="perSubscriberDurationUnlimited && !perSubscriberDurationUnlimited.id"
                            v-model="perSubscriberDuration"
                            :options="durationsOptionsList"
                            :placeholder="$i18n.t('generic.type')"
                            :showLabels="false"
                            :borderNone="true"
                            :small="true"
                            :allowEmpty="false"
                            :preselectFirst="true"
                            :disabled="readonly"
                            class="col-1 fit-content"
                            label="label"
                            trackBy="id"
                        />
                    </div>
                    <div class="d-flex align-items-center prize-winning-rules-row-container mb-0">
                        <div class="inline-text">
                            {{ $i18n.t('prize.editor.userCanWinThisPrize') }}
                        </div>
                        <AppMultiselectV3
                            v-model="maxPurchasePerUserUnlimited"
                            :options="limitOptions"
                            :showLabels="false"
                            :borderNone="true"
                            :small="true"
                            :allowEmpty="false"
                            :preselectFirst="true"
                            :disabled="readonly"
                            class="col-1 fit-content"
                            label="label"
                            trackBy="id"
                        />
                        <AppInputV3
                            v-show="maxPurchasePerUserUnlimited && !maxPurchasePerUserUnlimited.id"
                            v-model.number="prize.data.max_purchases_per_user"
                            :min="1"
                            :invalid="invalidPrizeWinningRules[5]"
                            :disabled="readonly"
                            type="number"
                            class="input-margin"
                            placeholder="0"
                            @input="onInputValidatePrizeWinningRules($event, 5)"
                        />
                        <div class="inline-text">
                            {{ $i18n.t('prize.editor.timesInTotal') }}
                        </div>
                    </div>
                </div>
            </div>
            <!-- Integration parameters -->
            <div class="editor-section">
                <div
                    v-t="'generic.integrationParameter'"
                    class="editor-section-heading pb-0"
                />
                <div class="position-relative">
                    <div class="row optional-key-value-max-width">
                        <span
                            v-t="'partials.appInput.optional'"
                            class="d-flex justify-content-end col-11 pr-2 mb-2 optional-label"
                        />
                    </div>
                    <KeyValueInputs
                        :value="prize.data.integration_params"
                        :addButtonLabel="$i18n.t('generic.addIntegrationParameter')"
                        :firstInputPlaceholder="$i18n.t('generic.key')"
                        :secondInputPlaceholder="$i18n.t('generic.value')"
                        :initInputValues="initIntegrationParameters"
                        :disabled="readonly"
                        :showInputErrors="true"
                        @input="onIntegrationParamInput"
                    />
                </div>
            </div>
            <div class="editor-section">
                <div
                    v-t="'generic.miscParams'"
                    class="editor-section-heading pb-0"
                />
                <div>
                    <div class="row optional-key-value-max-width">
                        <span
                            v-t="'partials.appInput.optional'"
                            class="d-flex justify-content-end col-11 pr-2 mb-2 optional-label"
                        />
                    </div>
                    <KeyValueInputs
                        :value="prize.data.misc_params"
                        :addButtonLabel="$i18n.t('generic.addMiscParams')"
                        :firstInputPlaceholder="$i18n.t('generic.key')"
                        :secondInputPlaceholder="$i18n.t('generic.value')"
                        :initInputValues="initIMiscParameters"
                        :disabled="readonly"
                        :showInputErrors="true"
                        @input="onMiscParamInput"
                    />
                </div>
            </div>
        </template>

        <template #controls>
            <EditorButtons
                :showSaveDraft="isRewardsDraftEnabled && !readonly"
                :showPublish="isRewardsDraftEnabled && !readonly"
                :showSave="!isRewardsDraftEnabled"
                :disableSave="readonly"
                @cancel="onCancel"
                @saveDraft="onSave(false)"
                @publish="onSave(true)"
                @save="onSave(true)"
            />
        </template>
    </AbstractEditPageWrapper>
</template>

<script>
// Generic Vue Components
import AbstractEditPageWrapper from '@/components/layout/AbstractEditPageWrapper.vue';
import AppHeader from '../../../components/layout/AppHeader.vue';
import AppInputV3 from '../../../components/partials/inputs/AppInputV3.vue';
import AppTextareaV3 from '@/components/partials/inputs/AppTextareaV3.vue';
import AppMultiselectV3 from '@/components/partials/inputs/AppMultiselectV3.vue';
import AppToggle from '@/components/partials/inputs/AppToggle.vue';
import { ALERT_TYPES } from '@/common/alerts/Alert';
import KeyValueInputs from '@/components/partials/inputs/KeyValueInputs.vue';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import { ICON_TYPES } from '@/common/iconHelper';
import EditorButtons from '@/components/layout/EditorButtons.vue';
import { LABEL_COLOR } from '@/common/labelsHelper';
import AppLabel from '@/components/partials/AppLabel.vue';
import { STATUS_CODES } from '@/common/commonHelper';
import * as Sentry from '@sentry/vue';

// Helpers
import {
    PRIZE_ENGINE_CURRENCY_TYPES,
    PRIZE_ENGINE_TYPE_OPTIONS,
    rewardTimeLimits,
    rewardTimeUnit,
} from '@/modules/rewards/common/RewardsHelper';
import ENTITY_TYPES from '@/common/entities/entityTypes';
import { addPrize, getPrizeDraft, setPrizeDraft, updatePrize } from '@/__new__/services/dno/rewards/http/rewards';
import { entityTypeToEntityLabel, getMultiLangFieldValueByLocale } from '@/common/entities/entityHelper';
import entityEditorMixin from '@/common/entityEditorMixin';
import RouteNames from '@/router/routeNames';
import cloneDeep from 'lodash/cloneDeep';
import merge from 'lodash/merge';
import { getArrayFromObject, getObjectFromArray } from '@/common/formatting';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import { isEmpty } from 'lodash';
import { manageEntityAdd, manageEntityUpdate } from '@/common/EntityLoadHelper';
import { getUserNameById } from '@/__new__/services/portal/profile/http/profile';
import permissionsService from '@/services/permissions/permissions.service';

// Vuex
import Actions, { Getters, State } from '@/store/mutation-types';
import { mapActions, mapGetters, mapState } from 'vuex';

const isUnlimited = param => value => param ? true : value && value >= 1 && Number.isInteger(value);

export default {
    name: 'PrizeEditor',

    components: {
        AbstractEditPageWrapper,
        AppInputV3,
        AppHeader,
        AppTextareaV3,
        AppMultiselectV3,
        AppButton,
        AppToggle,
        KeyValueInputs,
        AppLabel,
        EditorButtons,
    },

    mixins: [entityEditorMixin, validationMixin],

    data() {
        return {
            PRIZE_ENGINE_TYPE_OPTIONS,
            PRIZE_ENGINE_CURRENCY_TYPES,
            entityType: ENTITY_TYPES.PRIZE,
            ICON_TYPES,
            BUTTON_TYPES,
            LABEL_COLOR,
            approveOnCreation: false,
            selectedLanguage: '',
            // prize data model
            prize: {
                data: {
                    name: {},
                    description: {},
                    payout_id: null,
                    max_purchases: 1,
                    max_purchases_per_user: 1,
                    max_purchases_per_duration: {
                        per_subscriber: {
                            max: 1,
                            duration: 1,
                            number_of_durations: 1,
                        },
                        overall: {
                            max: 1,
                            duration: 1,
                            number_of_durations: 1,
                        },
                    },
                    integration_params: null,
                    misc_params: null,
                },
            },
            initIntegrationParameters: null,
            initIMiscParameters: null,
            invalidPrizeWinningRules: [false, false, false, false, false, false],
            overallDurationUnlimited: null,
            maxPurchaseUnlimited: null,
            perSubscriberDurationUnlimited: null,
            maxPurchasePerUserUnlimited: null,
            selectedPayout: null,
            initialData: null,
            entityDraft: undefined,
            publishedEntity: undefined,
            updateName: this.$i18n.t('generic.N/A'),
            updateTime: null,
            readonly: false,
            isUnpublished: false,
            isOnlyDraft: false,
            isRewardsDraftEnabled: permissionsService.isRewardsDraftEnabled(),
            saveButtonClicked: false,
            draftSavedId: null,
        };
    },

    validations() {
        return {
            selectedPayout: {
                required,
            },
            prize: {
                data: {
                    name: {
                        [this.selectedLanguage]: {
                            required,
                        },
                    },
                    max_purchases: {
                        ifRequiredGreaterThanOne: isUnlimited(
                            this.maxPurchaseUnlimited && this.maxPurchaseUnlimited.id,
                        ),
                    },
                    max_purchases_per_user: {
                        ifRequiredGreaterThanOne: isUnlimited(
                            this.maxPurchasePerUserUnlimited && this.maxPurchasePerUserUnlimited.id,
                        ),
                    },
                    max_purchases_per_duration: {
                        per_subscriber: {
                            max: {
                                ifRequiredGreaterThanOne: isUnlimited(
                                    this.perSubscriberDurationUnlimited && this.perSubscriberDurationUnlimited.id,
                                ),
                            },
                            number_of_durations: {
                                ifRequiredGreaterThanOne: isUnlimited(
                                    this.perSubscriberDurationUnlimited && this.perSubscriberDurationUnlimited.id,
                                ),
                            },
                        },
                        overall: {
                            max: {
                                ifRequiredGreaterThanOne: isUnlimited(
                                    this.overallDurationUnlimited && this.overallDurationUnlimited.id,
                                ),
                            },
                            number_of_durations: {
                                ifRequiredGreaterThanOne: isUnlimited(
                                    this.overallDurationUnlimited && this.overallDurationUnlimited.id,
                                ),
                            },
                        },
                    },
                    integration_params: {
                        valid: params => params === null || params.isValid,
                    },
                    misc_params: {
                        valid: params => params === null || params.isValid,
                    },
                },
            },
        };
    },

    computed: {
        ...mapGetters('productcatalog', [
            Getters.PC_GET_ENTITIES_BY_TYPE_APPROVED,
            Getters.PC_GET_ENTITY_BY_TYPE_AND_ID,
        ]),
        ...mapGetters('rewards', [Getters.GET_NOT_DELETED_REWARDS_ENTITIES_BY_TYPE]),
        ...mapState('rewards', [State.REWARDS_ENTITIES_BY_TYPE]),
        languages() {
            return this.$store.state.operators[State.Languages];
        },
        payouts() {
            return this[Getters.GET_NOT_DELETED_REWARDS_ENTITIES_BY_TYPE](ENTITY_TYPES.REWARD_PAYOUT);
        },
        payoutOptions() {
            return this.payouts.map(entity => {
                const { name } = entity.data;
                return {
                    id: entity.id,
                    name: getMultiLangFieldValueByLocale(name),
                };
            });
        },
        payoutOptionsMap() {
            return this.payoutOptions.reduce((acc, payout) => {
                acc[payout.id] = payout;
                return acc;
            }, {});
        },
        languageDefault() {
            return this.$store.getters[`operators/${Getters.languageDefault}`];
        },
        typeOptions() {
            return Object.values(this.PRIZE_ENGINE_TYPE_OPTIONS).map(opt => ({
                ...opt,
                label: this.$i18n.t(opt.i18nLabel),
            }));
        },
        costOptions() {
            return Object.values(this.PRIZE_ENGINE_CURRENCY_TYPES).map(opt => ({
                ...opt,
                label: this.$i18n.t(opt.i18nLabel),
            }));
        },
        perSubscriberDuration: {
            get() {
                const enumKey = this.prize.data.max_purchases_per_duration.per_subscriber.duration;
                const enumLabel = rewardTimeUnit.get(enumKey);
                return { id: enumKey, label: this.$i18n.t(enumLabel) };
            },
            set(option) {
                this.prize.data.max_purchases_per_duration.per_subscriber.duration = option.id;
            },
        },
        overallDuration: {
            get() {
                const enumKey = this.prize.data.max_purchases_per_duration.overall.duration;
                const enumLabel = rewardTimeUnit.get(enumKey);
                return { id: enumKey, label: this.$i18n.t(enumLabel) };
            },
            set(option) {
                this.prize.data.max_purchases_per_duration.overall.duration = option.id;
            },
        },
        durationsOptionsList() {
            // needs to follow correct mapping since duration type is stored
            // on the BE
            const arr = [];
            for (const [key, value] of rewardTimeUnit) {
                arr.push({ id: key, label: this.$i18n.t(value) });
            }
            return arr;
        },
        limitOptions() {
            const arr = [];
            for (const [key, value] of rewardTimeLimits) {
                arr.push({ id: key, label: this.$i18n.t(value) });
            }
            return arr;
        },
        durationPerUser() {
            if (this.selectedEntity) {
                return this.$i18n.t(
                    this.rewardTimeUnit.get(
                        this.selectedEntity.data.max_purchases_per_duration.per_subscriber.duration,
                    ),
                );
            }
            return this.$i18n.t('generic.N/A');
        },
        inEditMode() {
            return Boolean(this.$route.params.id) && !this.$route.params.clone;
        },
        isDraft() {
            return (
                this.$route.params.id && !this.$route.params.readonly && this.entityDraft && this.isRewardsDraftEnabled
            );
        },
        isPublished() {
            return this.$route.params.id && this.$route.params.readonly && this.isRewardsDraftEnabled;
        },
        noLabels() {
            return !this.isDraft && !this.isPublished;
        },
        allowViewPublishedBtn() {
            return (
                this.$route.params.id && !this.$route.params.readonly && !this.isOnlyDraft && this.isRewardsDraftEnabled
            );
        },
        allowEditDraftBtn() {
            return (
                this.$route.params.id && this.$route.params.readonly && !this.isOnlyDraft && this.isRewardsDraftEnabled
            );
        },
        pageTitle() {
            const label = entityTypeToEntityLabel[ENTITY_TYPES.PRIZE];
            if (this.$route.params.readonly) {
                return label;
            }
            if (this.inEditMode) {
                return `${this.$i18n.t('generic.edit')} ${label} ${
                    this.isDraft ? this.$i18n.t('generic.stateMap.draft') : ''
                }`;
            }
            return `${this.$i18n.t('generic.addNew')} ${label}`;
        },
    },

    created() {
        this.$withLoadingSpinner(
            async () => {
                if (this.$route.params.readonly) {
                    this.readonly = this.$route.params.readonly;
                }
                await this.$store.dispatch(
                    `rewards/${Actions.REQUEST_REWARDS_ENTITIES_BY_TYPE}`,
                    ENTITY_TYPES.REWARD_PAYOUT,
                );

                this.selectedLanguage = this[Getters.languageDefault];

                if (this.inEditMode) {
                    await this.$store.dispatch(
                        `rewards/${Actions.REQUEST_REWARDS_ENTITIES_BY_TYPE}`,
                        ENTITY_TYPES.PRIZE,
                    );

                    const { id } = this.$route.params;
                    if (this.isRewardsDraftEnabled && !this.$route.params.readonly) {
                        const result = await getPrizeDraft(id);
                        this.entityDraft = result?.data?.data ? result.data.data[id] : null;
                        if (this.entityDraft) this.entityDraft.state = STATUS_CODES.NA;
                    }

                    this.publishedEntity = this[State.REWARDS_ENTITIES_BY_TYPE][ENTITY_TYPES.PRIZE][id];
                    if (this.entityDraft && !(this.$route.params.clone && this.publishedEntity)) {
                        this.initialData = cloneDeep(this.entityDraft);
                        this.initialData.version = this.publishedEntity.version;
                        this.isOnlyDraft = !this.publishedEntity || this.publishedEntity.state === STATUS_CODES.NA;
                        if (this.publishedEntity.update_time <= this.initialData.update_time) {
                            this.isUnpublished = true;
                        }
                    } else {
                        this.initialData = cloneDeep(this.publishedEntity);
                    }

                    this.updateTime = this.initialData.update_time;
                    this.getUpdateUserName(this.initialData.update_portal_id || this.initialData.portal_id || null);

                    // separate handling for integration params initialization
                    if (this.initialData.data.integration_params) {
                        if (isEmpty(this.initialData.data.integration_params)) {
                            this.initialData.data.integration_params = null;
                            this.prize.data.integration_params = null;
                        } else {
                            this.initIntegrationParameters = getArrayFromObject(
                                this.initialData.data.integration_params,
                            );
                        }
                    }

                    // separate handling for misc params initialization
                    if (this.initialData.data.misc_params) {
                        if (isEmpty(this.initialData.data.misc_params)) {
                            this.initialData.data.misc_params = null;
                            this.prize.data.misc_params = null;
                        } else {
                            this.initIMiscParameters = getArrayFromObject(this.initialData.data.misc_params);
                        }
                    }

                    // initialize drop downs for limits
                    if (this.initialData.data.max_purchases_per_duration) {
                        if (this.initialData.data.max_purchases_per_duration.overall) {
                            this.overallDurationUnlimited = this.limitOptions.find(e => !e.id);
                        }
                        if (this.initialData.data.max_purchases_per_duration.per_subscriber) {
                            this.perSubscriberDurationUnlimited = this.limitOptions.find(e => !e.id);
                        }
                    }
                    if (this.initialData.data.max_purchases) {
                        this.maxPurchaseUnlimited = this.limitOptions.find(e => !e.id);
                    }
                    if (this.initialData.data.max_purchases_per_user) {
                        this.maxPurchasePerUserUnlimited = this.limitOptions.find(e => !e.id);
                    }
                    if (this.initialData.data.payout_id) {
                        this.selectedPayout = this.payoutOptionsMap[this.initialData.data.payout_id];
                    }
                    // deeply merging data from API and default data set in the component
                    // in order to prevent accessing not existing properties in edited entity
                    this.prize = merge(this.prize, this.initialData);

                    this.addWatcher('$data');
                }

                if (this.$route.params.clone) {
                    this.prize.data.name[this.selectedLanguage] += ' (cloned)';
                }
            },
            {
                errorHandler: () => {
                    this.$showErrorAlert({
                        message: this.$i18n.t('alertMessage.somethingWentWrongFetchingNecessaryData'),
                    });
                    // redirect to list page
                    this.$router.push({ name: RouteNames.PRIZE_VIEW });
                },
            },
        );
    },

    methods: {
        ...mapActions('productcatalog', [Actions.PC_REQUEST_ENTITIES_BY_TYPE_AND_IDS]),
        onCancel() {
            this.$router.go(-1);
        },
        async onSave(isPublish) {
            if (this.saveButtonClicked) {
                return;
            }
            this.saveButtonClicked = true;
            if (isPublish) {
                this.$v.$touch();
                if (this.$v.$invalid) {
                    this.$eventBus.$emit('showAlert', {
                        message: this.$i18n.t('alertMessage.pleaseFixValidation'),
                    });
                } else {
                    await this.submit(true);
                }
            } else {
                await this.submit(false);
            }
            this.saveButtonClicked = false;
        },
        async submit(isPublish) {
            try {
                // build Prize
                this.$Progress.start();

                const prizeDto = {
                    ...this.prize.data,
                };

                if (this.maxPurchasePerUserUnlimited.id) {
                    delete prizeDto.max_purchases_per_user;
                }
                if (this.maxPurchaseUnlimited.id) {
                    delete prizeDto.max_purchases;
                }

                if (this.overallDurationUnlimited.id && this.perSubscriberDurationUnlimited.id) {
                    delete prizeDto.max_purchases_per_duration;
                } else if (this.overallDurationUnlimited.id) {
                    delete prizeDto.max_purchases_per_duration.overall;
                } else if (this.perSubscriberDurationUnlimited.id) {
                    delete prizeDto.max_purchases_per_duration.per_subscriber;
                }

                if (this.prize.data.misc_params) {
                    prizeDto.misc_params = getObjectFromArray(prizeDto.misc_params.data);
                } else {
                    delete prizeDto.misc_params;
                }

                if (this.prize.data.integration_params) {
                    prizeDto.integration_params = getObjectFromArray(prizeDto.integration_params.data);
                } else {
                    delete prizeDto.integration_params;
                }
                prizeDto.payout_id = this.selectedPayout?.id;
                const addDraftAction = async () => {
                    if (!this.isRewardsDraftEnabled) return null;
                    const resp = await setPrizeDraft({ data: prizeDto });
                    this.draftSavedId = resp?.data?.id;
                    return resp;
                };
                const addEntityAction = id =>
                    addPrize({
                        data: prizeDto,
                        approve_on_create: this.approveOnCreation,
                        id,
                    });
                const updateDraftAction = () =>
                    this.isRewardsDraftEnabled
                        ? setPrizeDraft({
                              data: prizeDto,
                              id: this.$route.params.id ?? this.draftSavedId,
                          })
                        : null;
                const updateEntityAction = () => updatePrize(this.$route.params.id, this.prize.version, prizeDto);
                const hasStoredDraft = this.draftSavedId || (this.entityDraft && !this.$route.params.clone);
                const hasStoredEntity =
                    this.publishedEntity && this.publishedEntity.state !== STATUS_CODES.NA && !this.$route.params.clone;
                if (!hasStoredEntity) {
                    await manageEntityAdd(
                        hasStoredDraft ? updateDraftAction : addDraftAction,
                        addEntityAction,
                        isPublish,
                    );
                } else {
                    await manageEntityUpdate(updateDraftAction, updateEntityAction, isPublish);
                }
                this.$Progress.finish();
                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('alertMessage.successActionMessage', {
                        entityName: this.$i18n.t('prize.prize'),
                        action: this.isEditing ? this.$i18n.t('generic.updated') : this.$i18n.t('generic.created'),
                    }),
                    type: ALERT_TYPES.success,
                });
                this.entityEditorMixin.successfullySaved = true;
                setTimeout(
                    () =>
                        this.$router.push({
                            name: RouteNames.PRIZE_VIEW,
                            params: { companyId: this.$route.params.companyId },
                        }),
                    1000,
                );
            } catch (e) {
                // show alert failed to save data.
                this.$Progress.fail();
                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('prize.editor.errorSavingPrize'),
                });
            } finally {
                this.$Progress.finish();
            }
        },
        onIntegrationParamInput(integrationParam) {
            if (integrationParam.data.length === 0) {
                this.prize.data.integration_params = null;
            } else {
                this.prize.data.integration_params = integrationParam;
            }
        },
        onMiscParamInput(miscParam) {
            if (miscParam.data.length === 0) {
                this.prize.data.misc_params = null;
            } else {
                this.prize.data.misc_params = miscParam;
            }
        },
        onInputValidatePrizeWinningRules(value, index) {
            this.invalidPrizeWinningRules[index] = !value || !Number.isInteger(parseFloat(value)) || value < 1;
        },
        async getUpdateUserName(id) {
            try {
                if (id) {
                    const response = await getUserNameById(Number(id));
                    if (response?.data) {
                        this.updateName = response.data;
                    }
                }
            } catch (e) {
                Sentry.captureException(e);
            }
        },
        reloadEditor(isReadonly) {
            const { id } = this.$route.params;
            // Use push to list page because router don`t want to reload same page
            this.$router.push({ name: RouteNames.PRIZE_VIEW }).then(() => {
                this.$router.push({
                    name: RouteNames.PRIZE_EDITOR,
                    params: {
                        entityType: ENTITY_TYPES.PRIZE,
                        id,
                        readonly: isReadonly,
                    },
                });
            });
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/_colors';
@import '~@/assets/scss/_palette';
@import '~@/assets/scss/_editor-layout-v2';
@import '~@/assets/scss/_typographyV2';

.fit-content {
    min-width: fit-content;
}

.label {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 0.75rem;
    color: $gray90;
    line-height: 26px;
    font-weight: 600;
    margin-bottom: 0.5rem;

    &.disabled {
        color: $gray30;
    }
}

.inline-text {
    font-size: 0.75rem;
    text-transform: uppercase;
    font-weight: bold;
    color: $gray-blue;
    margin-right: 0.75rem;
}

.prize-winning-rules-row-container {
    margin-bottom: 0.5rem;
}

.prize-winning-rules-row-container > * {
    margin-right: 0.75rem;
}

.optionalPosition {
    position: absolute;
    left: 78%;
    top: -20px;
}

.optional-label {
    font-size: 0.75rem;
    font-style: italic;
    color: $gray60;
}

.optional-key-value-max-width {
    max-width: 36.5rem;
}
</style>
