import Button from '@/common/button/Button';

export enum ALERT_COLORS {
    yellow = 'yellow',
    green = 'green',
    blue = 'blue',
    red = 'red',
}

export enum ALERT_TYPES {
    error = 'alert-danger',
    success = 'alert-success',
    warning = 'alert-warning',
    info = 'alert-info',
    default = '',
}

export default class Alert {
    message: string;

    type: string;

    buttons: { [key: string]: Button };

    description?: string;

    constructor(message: string, type: string, buttons: { [key: string]: Button }, description?: string) {
        this.message = message;
        this.type = type || ALERT_TYPES.error;
        this.buttons = buttons;
        this.description = description;
    }
}
