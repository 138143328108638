import { render, staticRenderFns } from "./BillingAccountEditDialog.vue?vue&type=template&id=4ed5da36&scoped=true"
import script from "./BillingAccountEditDialog.vue?vue&type=script&lang=ts"
export * from "./BillingAccountEditDialog.vue?vue&type=script&lang=ts"
import style0 from "./BillingAccountEditDialog.vue?vue&type=style&index=0&id=4ed5da36&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4ed5da36",
  null
  
)

export default component.exports