<template>
    <AbstractListPageWrapper
        :pageTitle="pageTitle"
        :entitiesCount="entitiesCount"
        :isOverviewEnabled="isOverviewEnabled"
        @searchQueryChanged="onSearchQueryChanged"
    >
        <template #button>
            <ResponseModalButton
                :response="entityResponse"
                :title="pageTitle"
            />
        </template>

        <template #filterTable>
            <FilterTable
                :columns="tableColumnsData"
                @filterAdded="onFilterAdd"
            />
        </template>

        <!-- Commented for now because it doesn't work and we have demo -->
        <!-- <template #headerButtons>
            <AppButton
                v-if="writePermission"
                :buttonType="BUTTON_TYPES.PRIMARY"
                :iconType="ICON_TYPES.PLUS"
                :label="addButtonLabel"
                data-test-id="add-entity-button"
                @click="onAdd"
            />
        </template> -->

        <template #allFilters>
            <TableFiltersTags
                :filterTableMixin="filterTableMixin"
                class="my-3 ml-2"
                @removeFilter="index => removeFilter(index, filtersLocalStorageKey)"
                @removeAllFilters="removeAllFilters(filtersLocalStorageKey)"
            />
        </template>

        <template #customHtml>
            <div class="m-4">
                <AppMultiselectV3
                    :value="selectedPartyRole"
                    :additionalLabel="$i18n.t('operateAPIs.selectChannelPartner')"
                    :options="partyRoleOptions"
                    :small="true"
                    :allowEmpty="false"
                    label="name"
                    optionId="id"
                    class="party-role-multiselect"
                    data-test-id="channel-partner"
                    @input="onSelectPartyRole"
                />
            </div>
        </template>

        <template #table>
            <AppTable
                :entities="formattedFilteredEntities"
                :innerSearchQuery="searchQueryForTable"
                :isSearchEnabled="true"
                :columnsData="tableColumnsData"
                :selectedEntityId="selectedEntityId"
                :tableKey="`${entityType}-table`"
                :data-test-id="`${entityType}-table`"
                @selectEntity="selectEntity"
            >
                <template #approvalStatus="{ entity }">
                    <EntityStatusIndicator
                        :status="entity.approvalStatus"
                        :stateMap="APPROVAL_HISTORY_STATUS_TO_STATUS_NAME_MAP"
                        :stateMapColor="APPROVAL_HISTORY_STATUS_INDICATOR_MAP"
                    >
                    </EntityStatusIndicator>
                </template>
                <template #state="{ entity }">
                    <EntityStatusIndicator
                        :status="entity.state"
                        :stateMap="API_PRODUCT_ORDER_STATE_TO_LABEL_MAP"
                        :stateMapColor="API_PRODUCT_ORDER_STATE_TO_COLOR_MAP"
                    >
                    </EntityStatusIndicator>
                </template>

                <template #customRowButtons="{ entity }">
                    <IconButton
                        v-if="showModalOnPendingApproval(entity.approvalStatus)"
                        :label="$i18n.t('operateAPIs.approvalWorkflow')"
                        :icon="ICON_TYPES.CHECK"
                        class="mr-2"
                        @iconClick="onOpenEditEntityModal(entity)"
                    />
                    <IconButton
                        v-if="entityType === OPERATE_APIS_TYPES.APPLICATION_OWNER"
                        :label="$i18n.t('generic.view')"
                        :icon="ICON_TYPES.EYE_SHOW"
                        class="mr-2"
                        @iconClick="$emit('onViewEntity', entity)"
                    />
                </template>
            </AppTable>
        </template>

        <template #overview>
            <EntityOverview
                :entityType="entityType"
                :entity="selectedEntity"
                @closeOverview="isOverviewEnabled = false"
            >
                <template #section-1-content>
                    <div key="1">
                        <slot name="sidebarHeader" />
                        <BulkUploadTabs
                            :value="selectedTabId"
                            :tabs="tabs"
                            class="mb-3"
                            @input="onTabChange"
                        />
                        <slot name="sidebarOverview" />

                        <VueJsonPretty
                            v-if="selectedTabId === 'json'"
                            selectableType="single"
                            :data="selectedEntityJSON"
                            :deep="2"
                            class="state-history-content"
                        />
                    </div>
                </template>
            </EntityOverview>
        </template>

        <template #modal>
            <AppDialogV2
                :title="$i18n.t('operateAPIs.editEntity')"
                :visible="isEditEntityModalVisible"
                @close="isEditEntityModalVisible = false"
            >
                <AppMultiselectV3
                    v-model="selectedApprovalStatus"
                    :additionalLabel="$i18n.t('operateAPIs.approvalStatus')"
                    :options="approvalStatusOptions"
                    :small="true"
                    :showLabels="false"
                    label="label"
                    optionId="id"
                    data-test-id="approval-status"
                />
                <AppInputV3
                    v-model="approvalStatusReason"
                    :label="$i18n.t('operateAPIs.approvalStatusReason')"
                    :invalid="$v.approvalStatusReason.$error"
                    :placeholder="$i18n.t('operateAPIs.approvalStatusReason')"
                    class="mt-3"
                    data-test-id="approval-status-reason"
                />
                <template #modalFooter>
                    <AppButton
                        :buttonType="BUTTON_TYPES.PRIMARY"
                        :label="$t('generic.save')"
                        class="mr-3"
                        data-test-id="entity-status-save-button"
                        @click="onEditEntity"
                    />
                </template>
            </AppDialogV2>

            <AppDialogV2
                :title="$i18n.t('operateAPIs.approvalHistory')"
                :visible="isApprovalHistoryEntityModalVisible"
                :disableDefaultSaveBtn="true"
                @close="onCloseApprovalHistoryEntityModal"
            >
                <VueJsonPretty
                    selectableType="single"
                    :data="selectedApprovalHistoryEntityJSON"
                    :deep="2"
                    class="state-history-content"
                />
            </AppDialogV2>

            <slot name="additionalModal" />
        </template>
    </AbstractListPageWrapper>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { Modules } from '@/store/store';
import Actions, { Getters } from '@/store/mutation-types';

// Components
import AppDialogV2 from '@/components/partials/AppDialogV2.vue';
import AppMultiselectV3 from '@/components/partials/inputs/AppMultiselectV3.vue';
import AbstractListPageWrapper from '@/components/layout/AbstractListPageWrapper.vue';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import AppTable from '@/components/partials/AppTable.vue';
import FilterTableMixin from '@/components/partials/FilterTableMixin.vue';
import ListPageMixin from '@/__new__/features/pc/ListPageMixin.vue';
import FilterTable from '@/components/partials/FilterTable.vue';
import TableFiltersTags from '@/components/filters/TableFiltersTags.vue';
import ResponseModalButton from '@/components/partials/ResponseModalButton.vue';
import EntityOverview from '@/components/partials/entityOverview/EntityOverview.vue';
import IconButton from '@/components/partials/IconButton.vue';
import AppInputV3 from '@/components/partials/inputs/AppInputV3.vue';
import EntityStatusIndicator from '@/components/partials/EntityStatusIndicator.vue';
import BulkUploadTabs from '@/__new__/features/resources/BulkUploadTabs.vue';

// 3rd party components
import VueJsonPretty from 'vue-json-pretty';

// Helpers
import { ICON_TYPES } from '@/common/iconHelper';
import tableColumnType from '@/common/filterTable';
import {
    APPROVAL_HISTORY_STATUS,
    API_PRODUCT_ORDER_STATE,
    API_PRODUCT_ORDER_STATE_TO_LABEL_MAP,
    API_PRODUCT_ORDER_STATE_TO_COLOR_MAP,
    OPERATE_APIS_TYPES,
    APPROVAL_HISTORY_STATUS_TO_STATUS_NAME_MAP,
    APPROVAL_HISTORY_STATUS_INDICATOR_MAP,
    OPERATE_API_TYPES_TO_LABEL_MAP,
} from '@/__new__/features/operateAPIs/common/operateAPIsHelper';
import { required } from 'vuelidate/lib/validators';
import { ALERT_TYPES } from '@/common/alerts/Alert';
import Button from '@/common/button/Button';

// Http
import { getPartyRoles } from '@/__new__/services/dno/partyRolesPermissionsManagement/http/partyRolesPermissionsManagement';

const APPROVAL_STATUS_OPTIONS = {
    APPROVED: 'approved',
    REJECTED: 'rejected',
    PENDING_APPROVAL: 'pendingApproval',
};

export default {
    name: 'OperateApisListPageLayout',

    components: {
        AppMultiselectV3,
        AppDialogV2,
        AppTable,
        AppButton,
        AppInputV3,
        AbstractListPageWrapper,
        BulkUploadTabs,
        ResponseModalButton,
        TableFiltersTags,
        FilterTable,
        EntityOverview,
        VueJsonPretty,
        IconButton,
        EntityStatusIndicator,
    },

    mixins: [FilterTableMixin, ListPageMixin],

    props: {
        writePermission: {
            type: Boolean,
            required: false,
            default: false,
        },
        entityType: {
            type: String,
            required: true,
        },
        pageTitle: {
            type: String,
            default: '',
        },
        entityResponse: {
            type: Object,
            default: () => undefined,
        },
        entityData: {
            type: Array,
            default: () => undefined,
        },
        addButtonLabel: {
            type: String,
            default: '',
        },
        addNewRouteName: {
            type: String,
            default: '',
        },
        tabs: {
            type: Array,
            default: () => [],
        },
        defaultTabId: {
            type: String,
            default: 'general',
        },
    },

    data() {
        return {
            approvalStatusOptions: [
                { label: this.$i18n.t('generic.stateMap.approved'), id: APPROVAL_STATUS_OPTIONS.APPROVED },
                { label: this.$i18n.t('generic.stateMap.rejected'), id: APPROVAL_STATUS_OPTIONS.REJECTED },
            ],
            approvalStatusReason: '',
            searchQueryForTable: '',
            isOverviewEnabled: false,
            selectedEntity: null,
            selectedEntityJSON: {},
            selectedApprovalStatus: '',
            selectedEnityId: '',
            isEditEntityModalVisible: false,
            partyRoleOptions: [],
            selectedPartyRole: null,
            selectedEntityId: null,
            approvalHistoryEntities: [],
            selectedApprovalHistoryEntityJSON: {},
            isApprovalHistoryEntityModalVisible: false,
            filtersLocalStorageKey: `operate-apis-${this.entityType}`,

            ICON_TYPES,
            BUTTON_TYPES,
            OPERATE_APIS_TYPES,
            APPROVAL_HISTORY_STATUS_TO_STATUS_NAME_MAP,
            APPROVAL_HISTORY_STATUS_INDICATOR_MAP,
            API_PRODUCT_ORDER_STATE_TO_LABEL_MAP,
            API_PRODUCT_ORDER_STATE_TO_COLOR_MAP,
            editEntityConfirmationButton: new Button({
                label: this.$i18n.t('generic.yes').toUpperCase(),
                alertType: ALERT_TYPES.warning,
                handler: this.onEditEntityConfirmed.bind(this),
            }),
            selectedTabId: '',
        };
    },

    validations() {
        return {
            approvalStatusReason: {
                required,
            },
        };
    },

    computed: {
        ...mapGetters(Modules.operateApis, {
            getChannelPartner: Getters.GET_CHANNEL_PARTNER,
        }),
        formattedFilteredEntities() {
            return this.filteredEntitiesMixin(this.entityData);
        },
        entitiesCount() {
            return this.entityData?.length || 0;
        },
        tableColumnsData() {
            const columns = [
                {
                    name: this.$i18n.t('generic.description'),
                    key: 'description',
                    field: 'description',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('generic.updateTime'),
                    key: 'lastUpdateTimestampStr',
                    mapper: entity => this.$localeLibrary.getFormattedDateAndTime(entity.lastUpdateTimestamp),
                    sortBy: 'lastUpdateTimestamp',
                    filterType: tableColumnType.DATE,
                },
            ];

            if (this.entityType !== OPERATE_APIS_TYPES.API_PRODUCT_ORDER) {
                columns.unshift({
                    name: this.$i18n.t('generic.name'),
                    key: 'name',
                    field: 'name',
                    filterType: tableColumnType.GENERAL_TEXT,
                });
            }

            if (this.entityType !== OPERATE_APIS_TYPES.API_PRODUCT) {
                columns.unshift({
                    name: this.$i18n.t('generic.id'),
                    key: 'id',
                    field: 'id',
                    filterType: tableColumnType.GENERAL_TEXT,
                });
            }

            if ([OPERATE_APIS_TYPES.APPLICATION, OPERATE_APIS_TYPES.APPLICATION_OWNER].includes(this.entityType)) {
                columns.push({
                    name: this.$i18n.t('operateAPIs.approvalStatus'),
                    key: 'approvalStatus',
                    field: 'approvalStatus',
                    filterType: tableColumnType.TEXT_LIMITED_OPTIONS,
                    limitedOptions: Object.values(APPROVAL_HISTORY_STATUS),
                });
            }

            if (this.entityType === OPERATE_APIS_TYPES.API_PRODUCT_ORDER) {
                columns.push({
                    name: this.$i18n.t('generic.state'),
                    key: 'state',
                    field: 'state',
                    filterType: tableColumnType.TEXT_LIMITED_OPTIONS,
                    limitedOptions: Object.values(API_PRODUCT_ORDER_STATE),
                });
            }
            return columns;
        },
    },

    watch: {
        selectedPartyRole: {
            handler(val) {
                this.$emit('onSelectPartyRole', val);
            },
        },
    },

    created() {
        this.$withLoadingSpinner(
            async () => {
                const response = await getPartyRoles('ChannelPartner');

                this.partyRoleOptions = response?.data?.map(partyRole => ({
                    id: partyRole?.id || '',
                    name: partyRole?.name || '',
                }));

                // Determine channel partner
                const cachedChannelPartner = this.getChannelPartner(this.$route.params?.companyId);
                const firstKnownChannelPartnerId = this.partyRoleOptions[0]?.id;
                this.selectedPartyRole = cachedChannelPartner ?? firstKnownChannelPartnerId;

                this.setAppliedFiltersFromLocalStorage(this.filtersLocalStorageKey);
                this.selectedTabId = this.defaultTabId;
            },
            {
                errorHandler: () => {
                    this.$alert(this.$i18n.t('alertMessage.somethingWentWrongFetchingNecessaryData'));
                },
            },
        );
    },

    methods: {
        ...mapActions(Modules.operateApis, {
            setChannelPartner: Actions.SET_CHANNEL_PARTNER_FOR_COMPANY,
        }),
        onSelectPartyRole(partyRole) {
            this.selectedPartyRole = partyRole;
            this.setChannelPartner({
                channelPartnerId: partyRole,
                companyId: this.$route.params?.companyId,
            });
        },
        onSearchQueryChanged(searchQuery) {
            this.searchQueryForTable = searchQuery;
        },
        onAdd() {
            this.$router.push({ name: this.addNewRouteName });
        },
        selectApprovalHistoryEntity(entity) {
            this.selectedApprovalHistoryEntityJSON = entity.data;
            this.isApprovalHistoryEntityModalVisible = true;
        },
        onCloseApprovalHistoryEntityModal() {
            this.isApprovalHistoryEntityModalVisible = false;
            this.selectedApprovalHistoryEntityJSON = '';
        },
        selectEntity(entity) {
            this.selectedEntityId = entity;
            this.selectedEntity = this.entityData.find(pr => pr.id === entity);
            this.isOverviewEnabled = true;
            this.$emit('onSelectEntity', entity);
            this.onTabChange(this.defaultTabId);

            // Set value for JSON editor
            this.selectedEntityJSON = this.entityResponse.data.find(obj => obj.id === entity);
        },
        onOpenEditEntityModal(entity) {
            this.selectedEnityId = entity.id;
            this.selectedApprovalStatus = entity.approvalStatus;
            this.isEditEntityModalVisible = true;
        },
        onEditEntity() {
            this.$v.$touch();
            if (this.$v.$invalid) {
                return;
            }

            if (this.selectedApprovalStatus === APPROVAL_STATUS_OPTIONS.REJECTED) {
                this.showRejectionIsFinalAlert();
            } else {
                this.onEditEntityConfirmed();
            }
        },
        showRejectionIsFinalAlert() {
            this.$alert(
                this.$i18n.t('operateAPIs.rejectionIsFinal', {
                    entity:
                        OPERATE_API_TYPES_TO_LABEL_MAP.get(this.entityType) ||
                        this.$i18n.t('generic.entity').toLowerCase(),
                }),
                {
                    type: ALERT_TYPES.warning,
                    buttons: [this.editEntityConfirmationButton],
                },
            );
        },
        onEditEntityConfirmed() {
            this.$emit('onEditEntity', {
                id: this.selectedEnityId,
                selectedApprovalOption: this.selectedApprovalStatus,
                approvalStatusReason: this.approvalStatusReason,
            });

            this.isEditEntityModalVisible = false;
        },
        onFilterAdd(filter) {
            this.onFilterAdded(filter);
            this.saveFiltersToLocalStorage(filter, this.filtersLocalStorageKey);
        },
        showModalOnPendingApproval(approvalStatus) {
            return approvalStatus === APPROVAL_STATUS_OPTIONS.PENDING_APPROVAL;
        },
        onTabChange(tabId) {
            this.selectedTabId = tabId;
            this.$emit('onTabChange', tabId);
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/consistency';

.state-history-content {
    border: 1px solid $gray-200;
    padding: $spacing-m !important;
}

.party-role-multiselect {
    width: 50% !important;
}

.version-item {
    font-size: 0.875rem;
    padding: 1rem 1.5rem;
    cursor: pointer;

    &:hover {
        background-color: $blue-200;
    }

    &.active {
        color: $blue;
        background-color: $blue-300;
    }

    .icon {
        position: relative;
        top: 3px;
    }
}

.state-label {
    height: 1.5rem;
}
</style>
