import { AxiosPromise } from 'axios';
import http, { DnoResponseBase, Entity } from '@/http/index';

type ServiceData = {
    name: string;
    description: string;
} & Record<string, any>;

type GetServicesResponseBody = DnoResponseBase & {
    service_by_id: Record<string, Entity<ServiceData>>;
};

/**
 * get services
 *
 * @returns {AxiosPromise}
 */
export function getServices(ids: string[] = []): AxiosPromise<GetServicesResponseBody> {
    return http({
        method: 'POST',
        url: '/v3productcatalog/getservices',
        headers: {
            'X-Requested-With': 'XMLHttpRequest',
        },
        data: {
            ids,
        },
    });
}

export type AddServiceRequestBody = {
    data: ServiceData;
    template_id: string;
    entity_id?: string;
};

/**
 * Add service.
 *
 * @returns {AxiosPromise}
 */
export function addService(data: AddServiceRequestBody): AxiosPromise<DnoResponseBase> {
    return http({
        method: 'POST',
        url: '/v3productcatalogwrite/addservice',
        headers: {
            'X-Requested-With': 'XMLHttpRequest',
        },
        data,
    });
}

/**
 * Update service state.
 *
 * @returns {AxiosPromise}
 */
export function updateServiceState(id: string, version: number, state: number): AxiosPromise<DnoResponseBase> {
    return http({
        method: 'POST',
        url: '/v3productcatalogwrite/updateservicestate',
        headers: {
            'X-Requested-With': 'XMLHttpRequest',
        },
        data: {
            id,
            version,
            state,
        },
    });
}

/**
 * Update service.
 *
 * @returns {AxiosPromise}
 */
export function updateService(id: string, version: number, data: ServiceData): AxiosPromise<DnoResponseBase> {
    return http({
        method: 'POST',
        url: '/v3productcatalogwrite/updateservice',
        headers: {
            'X-Requested-With': 'XMLHttpRequest',
        },
        data: {
            id,
            version,
            data,
        },
    });
}

/**
 * Get service draft list.
 *
 * @returns {AxiosPromise}
 */
export function getServiceDraftList() {
    return http({
        method: 'POST',
        url: '/v3productcatalog/getservicedraftlist',
    });
}

/**
 * Delete service draft.
 *
 * @returns {AxiosPromise}
 */
export function deleteServiceDraft(id: string) {
    return http({
        method: 'POST',
        url: '/v3productcatalog/servicedraftdelete',
        data: {
            id,
        },
    });
}

/**
 * Get service draft.
 *
 * @returns {AxiosPromise}
 */
export function getServiceDraft(id: string) {
    return http({
        method: 'POST',
        url: '/v3productcatalog/getservicedraft',
        data: {
            id,
        },
    });
}

/**
 * Set service draft.
 *
 * @returns {AxiosPromise}
 */
type ServiceDraftPayload = {
    id?: string;
    data: object;
    template_id: string;
};

export function setServiceDraft(data: ServiceDraftPayload) {
    return http({
        method: 'POST',
        url: '/v3productcatalogwrite/setservicedraft',
        data,
    });
}

export default {
    getServices,
    addService,
    updateServiceState,
    updateService,
};
