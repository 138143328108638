import COLOR_PALETTE from '@/common/colorsHelper';

export const LABEL_COLOR = {
    blue: COLOR_PALETTE.blue[800],
    yellow: COLOR_PALETTE.yellow[800],
    red: COLOR_PALETTE.red[800],
    green: COLOR_PALETTE.green[800],
    gray: COLOR_PALETTE.gray[800],
};

// key: text color
// value: backgroundColor
export const LABEL_COLOR_TO_BACKGROUND_MAP = new Map([
    [LABEL_COLOR.blue, COLOR_PALETTE.gray[200]],
    [LABEL_COLOR.yellow, COLOR_PALETTE.yellow[100]],
    [LABEL_COLOR.red, COLOR_PALETTE.red[100]],
    [LABEL_COLOR.green, COLOR_PALETTE.green[100]],
    [LABEL_COLOR.gray, COLOR_PALETTE.gray[100]],
]);

export default {};
