<template>
    <div>
        <h3 class="mb-3">{{ $i18n.t('userGuide.availableArticles') }}:</h3>
        <div class="editor-input-small">
            <AppDisplayList
                :items="mappedArticles"
                :verticalAlignment="true"
                @click="id => onArticleClick(id)"
            />
        </div>
    </div>
</template>

<script>
// components
import AppDisplayList from '@/components/partials/AppDisplayList.vue';

// helpers
import { getArticles } from '@/modules/userGuide/http/strapiHTTP';
import RouteNames from '@/router/routeNames';

export default {
    name: 'ArticlesPage',
    components: {
        AppDisplayList,
    },
    data() {
        return {
            articlesData: [],
        };
    },
    computed: {
        mappedArticles() {
            return this.articlesData.map(article => ({
                text: article.attributes.title,
                id: article.id,
            }));
        },
    },
    mounted() {
        this.fetchData();
    },
    methods: {
        async fetchData() {
            const response = await getArticles();
            this.articlesData = response?.data?.data;
        },
        onArticleClick(id) {
            this.$router.push({
                name: RouteNames.USER_GUIDE_ARTICLE,
                params: { id, companyId: this.$route.params.companyId },
            });
        },
    },
};
</script>

<style lang="scss" scoped></style>
