import { LABEL_COLOR } from '@/common/labelsHelper';
import i18n from '@/i18n';

export const enum PRODUCT_STATUS {
    CREATED = 'created',
    PENDING_ACTIVE = 'pendingActive',
    CANCELLED = 'cancelled',
    ACTIVE = 'active',
    PENDING_TERMINATE = 'pendingTerminate',
    TERMINATED = 'terminated',
    SUSPENDED = 'suspended',
    ABORTED = 'aborted',
}

export const PRODUCT_STATUS_TO_LABEL_MAP = new Map([
    [PRODUCT_STATUS.CREATED, i18n.t('generic.stateMap.created')],
    [PRODUCT_STATUS.PENDING_ACTIVE, i18n.t('generic.stateMap.pendingActive')],
    [PRODUCT_STATUS.CANCELLED, i18n.t('generic.stateMap.canceled')],
    [PRODUCT_STATUS.ACTIVE, i18n.t('generic.stateMap.active')],
    [PRODUCT_STATUS.PENDING_TERMINATE, i18n.t('generic.stateMap.pendingTerminate')],
    [PRODUCT_STATUS.TERMINATED, i18n.t('generic.stateMap.terminated')],
    [PRODUCT_STATUS.SUSPENDED, i18n.t('generic.stateMap.suspended')],
    [PRODUCT_STATUS.ABORTED, i18n.t('generic.stateMap.aborted')],
]);

export const PRODUCT_STATUS_TO_COLOR_MAP = new Map([
    [PRODUCT_STATUS.CREATED, LABEL_COLOR.green],
    [PRODUCT_STATUS.PENDING_ACTIVE, LABEL_COLOR.yellow],
    [PRODUCT_STATUS.CANCELLED, LABEL_COLOR.red],
    [PRODUCT_STATUS.ACTIVE, LABEL_COLOR.green],
    [PRODUCT_STATUS.PENDING_TERMINATE, LABEL_COLOR.yellow],
    [PRODUCT_STATUS.TERMINATED, LABEL_COLOR.red],
    [PRODUCT_STATUS.SUSPENDED, LABEL_COLOR.red],
    [PRODUCT_STATUS.ABORTED, LABEL_COLOR.red],
]);
