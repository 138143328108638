import { chain, map, merge, reduce } from 'lodash';
import {
    cohortExpressionsFromJson,
    createEmptyCohortExpressionsForSink,
    cohortExpressionsToJson,
    isNonEmptyCohortExpression,
} from '@/common/cohortExpressionHelper';
import { SEGMENT_ID_TYPES, SEGMENT_ID_TYPE, StaticFilterLabel, SEGMENT_ID_TYPES_REVERSE } from '@/common/StaticFilter';

export default class CohortConfig {
    cohortsObj?: ReturnType<typeof cohortExpressionsFromJson>;
    entryType?: {
        id: SEGMENT_ID_TYPE;
        label: string;
    };
    identifierField?: string;

    constructor(data: Partial<CohortConfig> = {}) {
        this.cohortsObj = data.cohortsObj;
        this.entryType = data.entryType;
        this.identifierField = data.identifierField;
    }

    static mapFromCohortExpression(
        cohort: {
            cohort_expression: string;
            identifier_field: string;
            segment_entry_type: keyof typeof SEGMENT_ID_TYPE;
        },
        segments: any[],
    ) {
        const id = SEGMENT_ID_TYPES[cohort.segment_entry_type];

        return new CohortConfig({
            cohortsObj: cohortExpressionsFromJson(cohort.cohort_expression, segments),
            entryType: {
                id,
                label: StaticFilterLabel[id],
            },
            identifierField: cohort.identifier_field,
        });
    }
}

export function configCohortsDataFromJson(cohortConfig: Record<string, any>, filters: any[], segments: any[]) {
    const eventIds = filters.map(f => f.filterDefinitionId);
    const allCohortsByIds = createEmptyCohortExpressionsForSink(eventIds);

    const nonEmptyCohortsByEventId = reduce(
        cohortConfig,
        (acc: Record<string, any>, cohort) => {
            acc[cohort.event_type] = CohortConfig.mapFromCohortExpression(cohort, segments);
            return acc;
        },
        {},
    );
    // merge two objects, nonempty cohorts will override empty ones
    return merge({}, allCohortsByIds, nonEmptyCohortsByEventId);
}

export function configCohortsDataToJson(cohortData?: Record<string, any>) {
    const mappedCohortsData: Array<CohortConfig & { eventType: string }> = map(cohortData, (data, name) => ({
        ...data,
        eventType: name,
    }));

    return chain(mappedCohortsData)
        .filter(data => isNonEmptyCohortExpression(data.cohortsObj))
        .map(data => {
            return {
                event_type: data.eventType,
                segment_entry_type: SEGMENT_ID_TYPES_REVERSE[data?.entryType?.id as SEGMENT_ID_TYPE],
                identifier_field: data.identifierField,
                cohort_expression: cohortExpressionsToJson(data.cohortsObj),
            } as CohortConfig & {
                event_type: string;
            };
        })
        .value();
}
