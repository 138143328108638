import { MAP_VLR_ID_OPTION_TO_LABEL } from '@/__new__/services/dno/charging/common/vlrIdConditionHelper';

export default (id, pcObject) => ({
    type: pcObject.type,
    data: {
        inverted: pcObject.inverted,
        src: Object.keys(pcObject.values).map(key => ({
            vlrIdType: {
                id: key,
                label: MAP_VLR_ID_OPTION_TO_LABEL[key],
            },
            values: pcObject.values[key],
        })),
    },
    uuid: id,
});
