
import Vue from '@/common/typedVue';

// components
import BulkUploadPageLayout from '@/__new__/features/resources/BulkUploadPageLayout.vue';

// http
import { getUploadHistory } from '@/__new__/services/dno/progressTracker/http/progressTrackerService';

// helpers
import tableColumnType, { type TableColumn } from '@/common/filterTable';
import { getAllFileUploadStatusStrings, type UploadedFileDetails } from '@/common/fileUploadHelper';
import type { CollapsibleListItem } from '@/common/AppCollapsibleListHelper';
import { DATAFLOW_API_TYPE } from '@/__new__/services/dno/progressTracker/progressTrackerHelper';

export default Vue.extend({
    name: 'ManualSeedUploadManagement',

    components: {
        BulkUploadPageLayout,
    },

    data() {
        return {
            dataflow: DATAFLOW_API_TYPE.MANUAL_CREATE_ORDER_UPLOAD,
            // table data
            entities: [] as UploadedFileDetails[],
            uploadHistoryColumnsData: [
                {
                    name: this.$t('generic.uploadTime'),
                    key: 'createdAtStr',
                    mapper: entity => this.$localeLibrary.getFormattedDateAndTime(entity.createdAt),
                    sortBy: (entity: UploadedFileDetails) => entity.createdAt,
                    field: 'createdAt',
                    filterType: tableColumnType.DATE,
                },
                {
                    name: this.$t('generic.filename'),
                    key: 'fileName',
                    field: 'fileName',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$t('qodNumberManagement.recordCount'),
                    key: 'recordCount',
                    field: 'recordCount',
                    filterType: tableColumnType.NUMBER,
                },
                {
                    name: this.$t('generic.status'),
                    key: 'fileUploadStatusStr',
                    field: 'fileUploadStatusStr',
                    filterType: tableColumnType.TEXT_LIMITED_OPTIONS,
                    limitedOptions: getAllFileUploadStatusStrings(),
                },
            ] as TableColumn[],
            uploadHistoryDefaultSort: {
                key: 'createdAtStr',
                sortBy: (entity: UploadedFileDetails) => entity.createdAt,
                type: 'desc',
            },
            isHistoryDataLoading: false as boolean,
        };
    },

    computed: {
        title(): string {
            return `${this.$t('manualSeedManagement.manualSeedUploadManagement')}`;
        },
    },

    async created() {
        const apis = [this.loadHistory()];
        await Promise.all(apis);
    },

    methods: {
        mapSidebarData(entity: any): CollapsibleListItem[] {
            return [
                {
                    name: this.$t('generic.general'),
                    isCollapsed: false,
                    rows: [
                        {
                            name: this.$t('generic.filename'),
                            value: entity?.fileName ?? '',
                        },
                        {
                            name: this.$t('qodNumberManagement.bulkUploadId'),
                            value: entity?.bulkUploadId ?? this.$t('generic.unknown'),
                        },
                        {
                            name: this.$t('qodNumberManagement.recordCount'),
                            value: entity?.recordCount ?? this.$t('generic.unknown'),
                        },
                        {
                            name: this.$t('generic.uploadedBy'),
                            value: entity?.createdBy ?? this.$t('generic.unknown'),
                        },
                        {
                            name: this.$t('generic.uploadTime'),
                            value: this.$localeLibrary.getFormattedDateAndTime(entity?.createdAt),
                        },
                    ],
                },
            ];
        },
        loadHistory(): Promise<void> {
            return this.$withProgressBar(
                async () => {
                    this.isHistoryDataLoading = true;
                    this.entities = await getUploadHistory(this.dataflow);
                    this.isHistoryDataLoading = false;
                },
                {
                    errorHandler: () => {
                        this.isHistoryDataLoading = false;
                        this.$alert(this.$t('qodNumberManagement.loadingBulkUploadHistoryFailed'));
                    },
                },
            );
        },
    },
});
