
// Vuex
import Actions, { Getters } from '@/store/mutation-types';
import { mapActions, mapGetters } from 'vuex';
import { Modules } from '@/store/store';

// components
import IconButton from '@/components/partials/IconButton.vue';
import AppAditionalSidebar from '@/components/partials/AppAditionalSidebar.vue';
import AbstractTableTile from '@/__new__/features/customerCareSuite/components/AbstractTableTile.vue';

// helpers
import { MessageTypes, MessageTypesHistoryIntMapping, MessageTypeLabels } from '@/common/CampaignMessage';
import { CampaignStatuses } from '@/__new__/services/dno/campaigns/models/Campaign';
import { millisTimestampToDateWithSeconds } from '@/common/formatting';
import * as Sentry from '@sentry/vue';
import { ALERT_TYPES } from '@/common/alerts/Alert';
import { USER_MANAGER_HIERARCHY } from '@/__new__/features/customerCare/common/customerCareHelper';
import tableColumnType from '@/common/filterTable';
import { channelTabs, parseMessage } from '@/__new__/features/customerCare/common/messageHelper';
import { ICON_TYPES } from '@/common/iconHelper';

// HTTP
import campaignsHTTP from '@/__new__/services/dno/campaigns/http/campaigns';

export default {
    name: 'MessageHistoryTile',
    components: {
        AbstractTableTile,
        IconButton,
        AppAditionalSidebar,
    },
    props: {
        idType: {
            type: Number,
            default: USER_MANAGER_HIERARCHY.USER,
        },
    },
    data() {
        return {
            messageHistory: [],
            ICON_TYPES,
            millisTimestampToDateWithSeconds,
            activeChannelId: MessageTypes.PUSH,
            channelTabs,
            columnsData: [
                {
                    name: this.$i18n.t('campaigns.campaignName'),
                    key: 'campaignName',
                    field: 'campaignName',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('campaigns.messages.messageSent'),
                    key: 'messageSent',
                    formatter: millisTimestampToDateWithSeconds,
                    field: 'messageSent',
                    filterType: tableColumnType.DATE,
                },
                {
                    name: this.$i18n.t('campaigns.messages.messageText'),
                    key: 'messageText',
                    field: 'message',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
            ],
            searchQuery: '',
            selectedMessage: null,
            showAditionalSidebar: false,
            rawResponses: new Map(),
        };
    },
    computed: {
        ...mapGetters(Modules.campaigns, {
            campaignsListFromStore: Getters.GET_CACHED_CAMPAIGNS_ARRAY,
        }),
        id() {
            return this.$route.params.id || null;
        },
        messagesFormatted() {
            return this.campaignsListFromStore.length
                ? this.messageHistory.map(this.formatPushDataForDisplaying).sort(this.sortMessagesForDisplaying)
                : [];
        },
        selectedMessageId() {
            return this.selectedMessage?.messageId;
        },
    },
    watch: {
        async id() {
            if (this.id) {
                await this.getMessageHistory();
            }
        },
        async activeChannelId() {
            await this.getMessageHistory();
        },
    },
    async created() {
        await this.fetchTileData();
    },
    methods: {
        ...mapActions(Modules.campaigns, {
            fetchCampaignsAction: Actions.FETCH_ALL_CAMPAIGNS,
        }),
        async fetchTileData() {
            this.$emit('isDataLoadingUpd', true);
            await this.getCampaigns();
            await this.getMessageHistory();
            this.$emit('isDataLoadingUpd', false);
        },
        onTabSelected(tabId) {
            this.activeChannelId = tabId;
        },
        copyToClipboard(data) {
            try {
                navigator.clipboard.writeText(data);
                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('alertMessage.copySuccess'),
                    type: ALERT_TYPES.success,
                });
            } catch (e) {
                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('alertMessage.copyFail'),
                });
            }
        },
        formatPushDataForDisplaying(pushMessage) {
            const relatedCampaign = this.campaignsListFromStore.find(
                camp => camp.id === pushMessage?.misc?.campaign_id,
            );

            let campaignName;

            if (relatedCampaign) {
                // for test message we don't have real campaign, so we do this only if real campaign exists
                const deletedLabel = this.$i18n.t('generic.deleted').toUpperCase();
                campaignName =
                    relatedCampaign.status.title === CampaignStatuses.Deleted.title
                        ? `${relatedCampaign.name} (${deletedLabel})`
                        : relatedCampaign.name;
            } else {
                campaignName = this.$i18n.t('campaigns.testMessage');
            }

            const parsedMessageFields = parseMessage(pushMessage);

            // mapping to display
            return {
                messageId: pushMessage.message_id,
                campaignName,
                messageSent: pushMessage.insert_timestamp,
                message: pushMessage.message,
                parsedMessage: parsedMessageFields,
                messageText: parsedMessageFields?.text,
            };
        },
        sortMessagesForDisplaying(fistItem, secondItem) {
            return secondItem.insert_timestamp - fistItem.insert_timestamp;
        },
        async getCampaigns() {
            try {
                this.$Progress.start();
                await this.fetchCampaignsAction();
                this.$Progress.finish();
            } catch (error) {
                this.$Progress.fail();
                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('alertMessage.failedToLoadNecessaryData'),
                });
                Sentry.captureException(error);
            }
        },
        async getMessageHistory() {
            this.messageHistory = [];
            const channelType = MessageTypesHistoryIntMapping[this.activeChannelId];
            let response;

            try {
                this.$Progress.start();
                response = await campaignsHTTP.getInboxHistory(this.id, channelType, this.idType);
                this.messageHistory = response.data.message_history_items.flat();
                this.$Progress.finish();
                this.$emit('isDataLoadingUpd', false);
            } catch (error) {
                response = error?.response;
                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('alertMessage.errorDoingSmthTryAgain', {
                        action: this.$i18n.t('generic.fetching'),
                        entityName: this.$i18n.t('campaigns.userMessagesHistory'),
                    }),
                });
                this.$Progress.fail();
                Sentry.captureException(error);
            } finally {
                this.rawResponses.set(this.activeChannelId, response);
            }
        },
        selectMessage(messageId) {
            this.selectedMessage = this.messagesFormatted.find(m => m.messageId === messageId);
            this.showAditionalSidebar = true;
        },
        fixIframeHeight(e) {
            const iframe = e.target;
            iframe.contentWindow.document.body.style.margin = 0;
            iframe.style.height = `${iframe.contentWindow.document.body.scrollHeight}px`;
        },
        responseModalData() {
            return {
                response: this.rawResponses.get(this.activeChannelId),
                title: `${this.$i18n.t('customerCare.messageHistory')} - ${MessageTypeLabels[this.activeChannelId]}`,
            };
        },
    },
};
