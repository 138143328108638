export default class Counts {
    total;

    available;

    reserved;

    used;

    constructor(counters) {
        this.total = counters?.total || 0;
        this.available = counters?.available || 0;
        this.reserved = counters?.reserved || 0;
        this.used = counters?.assigned || 0;
    }
}
