
import AbstractEditPageWrapper from '@/components/layout/AbstractEditPageWrapper.vue';
import AppHeader from '@/components/layout/AppHeader.vue';
import AppInputV3 from '@/components/partials/inputs/AppInputV3.vue';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import entityEditorMixin from '@/common/entityEditorMixin';
import mutationDialogMixin from '@/components/partials/mutations/mutationDialogMixin.vue';
import supportButtonMixin from '@/components/alerts/supportButtonMixin';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import * as Sentry from '@sentry/vue';
import importMonacoHelper from '@/common/importMonacoHelper';
import { ALERT_TYPES } from '@/common/alerts/Alert';
import { executeJSONPath } from '@/__new__/services/dno/events/http/events';

// helper for error "Property 'monaco'
// does not exist on type 'Window & typeof globalThis'"
declare const window: any;

export default {
    name: 'JSONPathTester',
    components: {
        AppInputV3,
        AbstractEditPageWrapper,
        AppHeader,
        AppButton,
    },
    mixins: [validationMixin, entityEditorMixin, mutationDialogMixin, supportButtonMixin],
    data() {
        return {
            BUTTON_TYPES,
            resultEditor: null as any,
            jsonEditor: null as any,
            inputJsonString: '{}' as string,
            JSONPathInput: '$' as string,
            isDataLoading: false as boolean,
        };
    },
    async mounted() {
        try {
            await importMonacoHelper.importMonaco();
            this.loadJsonEditor();
            this.loadResultEditor();
        } catch (e: any) {
            Sentry.captureException(e);
            this.showSupportAlert(this.$i18n.t('alertMessage.somethingWentWrong'), ALERT_TYPES.error);
        }
    },
    validations: {
        inputJsonString: {
            required,
        },
        JSONPathInput: {
            required,
        },
    },
    methods: {
        loadResultEditor(): void {
            this.resultEditor = window.monaco.editor.create(document.getElementById('resultEditor'), {
                language: 'json',
                automaticLayout: true,
                readOnly: true,
            });
        },
        loadJsonEditor(): void {
            this.jsonEditor = window.monaco.editor.create(document.getElementById('jsonEditor'), {
                value: this.inputJsonString,
                language: 'json',
                automaticLayout: true,
            });
            this.jsonEditor.onDidChangeModelContent(() => {
                this.inputJsonString = this.jsonEditor.getValue();
            });
        },
        async executeJSONPath(): Promise<void> {
            this.$v.$touch();
            if (this.$v.$invalid) {
                return;
            }

            this.$Progress.start();
            this.isDataLoading = true;

            try {
                const response = await executeJSONPath(this.JSONPathInput, this.inputJsonString);

                this.resultEditor.setValue(JSON.stringify(response.data.result, null, 2));

                this.isDataLoading = false;
                this.$Progress.finish();
            } catch (e: any) {
                if (e?.response?.data?.error) {
                    this.resultEditor.setValue(e.response.data.error);
                }
                this.isDataLoading = false;
                Sentry.captureException(e);
                this.$Progress.fail();
                this.$eventBus.$emit('showAlert', {
                    message: e?.response?.data?.message ?? this.$i18n.t('events.jsonpathTestFailure'),
                    type: ALERT_TYPES.error,
                });
            }
        },
    },
};
