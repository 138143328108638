<template>
    <div>
        <tr
            :class="{ selected: selectedEntity, 'default-hover': activateHover() }"
            class="entity-row text--sm no-gutters align-items-center row no-gutters w-100"
            @mouseover="isRowHovered = true"
            @mouseleave="isRowHovered = false"
        >
            <td v-if="isCheckboxVisible">
                <AppCheckbox
                    :value="isChecked"
                    class="font-weight-bold checkbox-pad"
                    :small="true"
                    :disabled="!isCheckboxEnabled"
                    @input="onUpdateChecked"
                />
            </td>
            <template v-for="(column, index) in columnsData">
                <td
                    v-if="showTableCell(entity[column.key])"
                    :key="column.key"
                    :class="[
                        column.classes,
                        { 'pr-5': index + 1 === columnsData.length },
                        { 'lf-table-title': index === 0 },
                        { 'lf-table-title-blue': index === 0 && selectedEntity },
                        { 'overflow-visible': column.overflowVisible },
                    ]"
                    :style="getStyles(column.width, index === columnsData.length - 1)"
                    class="table-cell truncate-text-all pl-4"
                    @mouseover="setHoveredColumn(index)"
                    @mouseleave="setHoveredColumn(null)"
                    @click="$emit('clicked')"
                >
                    <div
                        v-if="$slots[column.key]"
                        class="w-100"
                    >
                        <slot :name="column.key" />
                    </div>
                    <span
                        v-else-if="isEmptyCell(entity[column.key])"
                        :title="$i18n.t('generic.empty')"
                    >
                        <i>{{ $i18n.t('generic.empty') }}</i>
                    </span>
                    <span
                        v-else-if="Array.isArray(entity[column.key])"
                        :title="entity[column.key]"
                    >
                        {{ entity[column.key].join(', ') }}
                    </span>
                    <span
                        v-else
                        :title="entity[column.key]"
                    >
                        {{ entity[column.key] }}
                    </span>
                </td>
            </template>
            <td
                v-if="entity.nestedRows && entity.nestedRows.length"
                :style="getNestedRowsWidth(entity)"
            >
                <table class="d-flex flex-column">
                    <tr
                        v-for="(nestedData, k) in entity.nestedRows"
                        :key="k"
                    >
                        <td
                            v-for="[columnName, val] in Object.entries(nestedData)"
                            :key="columnName"
                            :style="getNestedRowColumnWidth(columnName)"
                            class="table-cell truncate-text-all pl-4"
                            @mouseover="setHoveredColumn(columnsData.indexOf(columnName))"
                            @mouseleave="setHoveredColumn(null)"
                            @click="$emit('clicked')"
                        >
                            <div
                                v-if="$slots[columnName]"
                                class="w-100"
                            >
                                <slot :name="columnName" />
                            </div>
                            <span
                                v-else-if="isEmptyCell(val)"
                                :title="$i18n.t('generic.empty')"
                            >
                                <i>{{ $i18n.t('generic.empty') }}</i>
                            </span>
                            <span
                                v-else-if="Array.isArray(val)"
                                :title="val"
                            >
                                {{ val.join(', ') }}
                            </span>
                            <span
                                v-else
                                :title="val"
                            >
                                {{ val }}
                            </span>
                        </td>
                    </tr>
                </table>
            </td>
            <div
                v-if="isRowHovered || alwaysShowRowButtons"
                class="hover-row-buttons"
                :style="{ right: controlsOffset + 'px' }"
            >
                <div class="button-wrapper">
                    <slot name="customRowButtons" />
                    <IconButton
                        v-if="$slots.details"
                        :label="$i18n.t('generic.show')"
                        :icon="showDetails ? ICON_TYPES.ARROW_UP : ICON_TYPES.ARROW_DOWN"
                        data-test-id="table-details-button"
                        @iconClick="toggleDetailsVisibility"
                    />
                </div>
                <div class="transitioning-area" />
            </div>
        </tr>

        <div v-show="showDetails">
            <slot name="details" />
        </div>
    </div>
</template>

<script>
// Components
import IconButton from '@/components/partials/IconButton.vue';
import AppCheckbox from '@/components/partials/inputs/AppCheckbox.vue';

// Helpers
import { ICON_TYPES } from '@/common/iconHelper';

export default {
    name: 'AppRow',
    components: {
        IconButton,
        AppCheckbox,
    },
    props: {
        entity: {
            type: [Object, Array],
            required: true,
        },
        columnsData: {
            type: Array,
            required: true,
        },
        selectedEntity: {
            type: Boolean,
            default: false,
        },
        isDefaultHoverEnabled: {
            type: Boolean,
            default: true,
        },
        showDetailsByDefault: {
            type: Boolean,
            default: false,
        },
        isCheckboxVisible: {
            type: Boolean,
            default: false,
        },
        isCheckboxEnabled: {
            type: Boolean,
            default: true,
        },
        isChecked: {
            type: Boolean,
            default: false,
        },
        controlsOffset: {
            type: Number,
            default: 0,
        },
        nestedRows: {
            type: Boolean,
            default: false,
        },
        alwaysShowRowButtons: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            changeWidth: false,
            isRowHovered: false,
            showDetails: this.showDetailsByDefault,
            nestedRowsWidth: 0,

            ICON_TYPES,
        };
    },
    methods: {
        getStyles(width) {
            const currentWidth = width || 100 / this.columnsData.length;
            return `width: ${currentWidth}%;`;
        },
        getNestedRowsWidth(entity) {
            const [row] = entity?.nestedRows;
            const nestedWidth = this.columnsData.reduce((totalWidth, { key, width }) => {
                if (Object.keys(row).includes(key)) {
                    /* eslint-disable-next-line no-param-reassign */
                    totalWidth += width;
                }
                return totalWidth;
            }, 0);

            this.nestedRowsWidth = nestedWidth;

            return `width: ${nestedWidth}%;`;
        },
        getNestedRowColumnWidth(columnName) {
            // Default zero value is used when column is removed from display.
            const { width = 0 } = { ...this.columnsData.find(({ key }) => key === columnName) };

            return `width: ${width * (100 / this.nestedRowsWidth)}%;`;
        },
        setHoveredColumn(index) {
            this.$emit('mouseOverColumn', index);
        },
        toggleDetailsVisibility() {
            this.showDetails = !this.showDetails;
            this.$emit('toggleShowDetails', this.entity, this.showDetails);
        },
        isEmpty(val) {
            switch (val) {
                case 'Empty':
                case 'N/A':
                case undefined:
                    return true;
                default:
                    return false;
            }
        },
        activateHover() {
            return !!(this.isDefaultHoverEnabled || this.$slots?.customRowButtons || this.$slots?.details);
        },
        onUpdateChecked(isChecked) {
            this.$emit('rowSelected', isChecked);
        },
        isEmptyCell(val) {
            return this.isEmpty(val) || (Array.isArray(val) && val.length === 0);
        },
        showTableCell(val) {
            return !this.nestedRows || !this.isEmptyCell(val);
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/palette';
@import '~@/assets/scss/base';
@import '~@/assets/scss/icons';
@import '~@/assets/scss/typographyV2';
@import '~@/assets/scss/z-indexes';

$icon-path: '~@/assets/icons/';

.entity-row {
    // position: relative;
    border-bottom: 1px solid $dirty-white;

    color: $gray90;
    background-color: $white;
    overflow: visible;

    transition-property: background-color;
    transition-duration: 0.25s;

    &.default-hover:hover {
        cursor: pointer;
        background-color: $blue5;
    }

    &.selected {
        background-color: $blue15;

        .edit-column {
            opacity: 1;
        }
    }

    & > * {
        line-height: 40px;
    }

    .hover-button {
        position: absolute;
        top: 8px;
        right: 24px;
        display: none !important;
    }

    &:hover {
        .hover-button {
            display: flex !important;
        }

        .close span {
            display: inline;
            position: absolute;
        }
        .edit span {
            display: inline;
            position: absolute;
        }
    }
}

.buttons-container {
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translateY(-50%);
    display: flex !important;
    align-items: center;

    button {
        margin-right: 8px;
        margin-left: 8px;
    }
}

tr {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}

tr td {
    display: inline-block;
    flex-direction: row;
    flex-wrap: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    &.overflow-visible {
        overflow: visible;
    }
}

td table tr {
    border: solid $dirty-white;
    border-width: 0 0 1px 1px;

    &:last-of-type {
        border-width: 0 0 0 1px;
    }
}

.lf-table-title-blue {
    color: $blue;
}

.hover-row-buttons {
    position: absolute;
    width: 0;
    align-self: flex-end;
    z-index: $overlap-smth-z-index;
}

.transitioning-area {
    background-image: linear-gradient(to right, transparent, $dirty-white);
    width: 20px;
    height: 100%;
}

.button-wrapper {
    background-color: $dirty-white;
    display: flex;
    position: absolute;
    bottom: 0;
    right: 0;
}

.checkbox-pad {
    padding: 0.313rem 0.313rem 0.313rem;
    margin-top: -0.35rem;
}
</style>
