import { MAP_SERVICE_ID_OPTION_TO_LABEL } from '@/__new__/services/dno/charging/common/serviceIdConditionHelper';

export default (id, object) => ({
    type: object.type,
    data: {
        inverted: object.inverted,
        src: Object.keys(object.values).map(key => ({
            serviceIdType: {
                id: key,
                label: MAP_SERVICE_ID_OPTION_TO_LABEL[key],
            },
            values: object.values[key],
        })),
    },
    uuid: id,
});
