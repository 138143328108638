// Generic
import * as Sentry from '@sentry/vue';
import { Module, ActionContext } from 'vuex';
import Actions, { State, Getters, Mutations } from '@/store/mutation-types';

// Helpers
import { Document, DocumentTemplate, DocumentAsset } from '@/__new__/services/dno/documents/models/DocumentInterfaces';
import { EntityStateMapping } from '@/common/commonEntityStateMapper';

// Http
import { getGeneratedDocuments } from '@/__new__/services/dno/documents/http/documents';
import { getTemplates } from '@/__new__/services/dno/documents/http/templates';
import { getAssets } from '@/__new__/services/dno/documents/http/assets';

interface DocumentPayload {
    operatorName: string;
    templateId: string;
    extension: string;
}

interface DocumentsState {
    [State.DOCUMENTS]: Record<string, Document>;
    [State.DOCUMENTS_API_RESPONSE]: object;
    [State.DOCUMENT_TEMPLATES]: Record<string, DocumentTemplate>;
    [State.DOCUMENT_TEMPLATES_API_RESPONSE]: object;
    [State.DOCUMENT_ASSETS]: Record<string, DocumentAsset>;
    [State.DOCUMENT_ASSETS_API_RESPONSE]: object;
}

const store: Module<DocumentsState, unknown> = {
    namespaced: true,
    state: {
        [State.DOCUMENTS]: {},
        [State.DOCUMENTS_API_RESPONSE]: {},
        [State.DOCUMENT_TEMPLATES]: {},
        [State.DOCUMENT_TEMPLATES_API_RESPONSE]: {},
        [State.DOCUMENT_ASSETS]: {},
        [State.DOCUMENT_ASSETS_API_RESPONSE]: {},
    },
    mutations: {
        [Mutations.SET_DOCUMENTS]: (state: DocumentsState, response: Record<string, Document>): void => {
            state[State.DOCUMENTS] = response;
        },
        [Mutations.SET_DOCUMENTS_API_RESPONSE]: (state: DocumentsState, response: any): void => {
            state[State.DOCUMENTS_API_RESPONSE] = response;
        },
        [Mutations.SET_DOCUMENT_TEMPLATES]: (
            state: DocumentsState,
            response: Record<string, DocumentTemplate>,
        ): void => {
            state[State.DOCUMENT_TEMPLATES] = response;
        },
        [Mutations.SET_DOCUMENT_TEMPLATES_API_RESPONSE]: (state: DocumentsState, response: any): void => {
            state[State.DOCUMENT_TEMPLATES_API_RESPONSE] = response;
        },
        [Mutations.SET_DOCUMENT_ASSETS]: (state: DocumentsState, response: Record<string, DocumentAsset>): void => {
            state[State.DOCUMENT_ASSETS] = response;
        },
        [Mutations.SET_DOCUMENT_ASSETS_API_RESPONSE]: (state: DocumentsState, response: any): void => {
            state[State.DOCUMENT_ASSETS_API_RESPONSE] = response;
        },
    },
    actions: {
        async [Actions.REQUEST_DOCUMENTS](
            context: ActionContext<DocumentsState, unknown>,
            payload: DocumentPayload,
        ): Promise<void> {
            try {
                const response = await getGeneratedDocuments(payload.templateId, payload.extension);
                context.commit(Mutations.SET_DOCUMENTS, response.data);
                context.commit(Mutations.SET_DOCUMENTS_API_RESPONSE, response);
            } catch (e: any) {
                context.commit(Mutations.SET_DOCUMENTS_API_RESPONSE, { response: e.response });
                Sentry.captureException(e);
                throw e;
            }
        },
        async [Actions.REQUEST_DOCUMENT_TEMPLATES](context: ActionContext<DocumentsState, unknown>): Promise<void> {
            try {
                const response = await getTemplates();
                const { template_ids: templateIds = [] }: { template_ids: Record<string, any> } = response.data?.data;

                const templateIdsData: Record<string, DocumentTemplate> = templateIds.reduce(
                    (data: any, dt: string) => {
                        data[dt] = {
                            id: dt,
                            name: dt,
                        };
                        return data;
                    },
                    {} as Record<string, DocumentTemplate>,
                );

                context.commit(Mutations.SET_DOCUMENT_TEMPLATES, templateIdsData);
                context.commit(Mutations.SET_DOCUMENT_TEMPLATES_API_RESPONSE, response);
            } catch (e: any) {
                context.commit(Mutations.SET_DOCUMENT_TEMPLATES_API_RESPONSE, { response: e.response });
                Sentry.captureException(e);
                throw e;
            }
        },
        async [Actions.REQUEST_DOCUMENT_ASSETS](context: ActionContext<DocumentsState, unknown>): Promise<void> {
            try {
                const response = await getAssets();
                const { assets = [] }: { assets: Record<string, any> } = response.data?.data;

                const assetsData: Record<string, DocumentAsset> = assets.reduce((data: any, asset: string) => {
                    data[asset] = {
                        id: asset,
                        name: asset,
                    };
                    return data;
                }, {} as Record<string, DocumentAsset>);

                context.commit(Mutations.SET_DOCUMENT_ASSETS, assetsData);
                context.commit(Mutations.SET_DOCUMENT_ASSETS_API_RESPONSE, response);
            } catch (e: any) {
                context.commit(Mutations.SET_DOCUMENT_ASSETS_API_RESPONSE, { response: e.response });
                Sentry.captureException(e);
                throw e;
            }
        },
    },
    getters: {
        // Documents
        [Getters.GET_DOCUMENTS]: (state: DocumentsState): Document[] => Object.values(state[State.DOCUMENTS]) || [],
        [Getters.GET_DOCUMENTS_API_RESPONSE]: (state: DocumentsState): any => state[State.DOCUMENTS_API_RESPONSE] || {},
        [Getters.GET_DOCUMENT_BY_ID]:
            (state: DocumentsState): Document | object =>
            (id: string) =>
                state[State.DOCUMENTS][id] || {},
        [Getters.GET_DOCUMENT_BY_IDS]: (state: DocumentsState) => (ids: string[]) => {
            const documents: Document[] = [];
            ids.forEach((id: string) => {
                if (state[State.DOCUMENTS] && state[State.DOCUMENTS][id]) {
                    documents.push(state[State.DOCUMENTS][id]);
                }
            });
            return documents;
        },

        // Document templates
        [Getters.GET_DOCUMENT_TEMPLATES]: (state: DocumentsState): DocumentTemplate[] =>
            Object.values(state[State.DOCUMENT_TEMPLATES]) || [],
        [Getters.GET_APPROVED_DOCUMENT_TEMPLATES]: (state: DocumentsState): DocumentTemplate[] =>
            Object.values(state[State.DOCUMENT_TEMPLATES]).filter(rg => rg.state === EntityStateMapping.APPROVED),
        [Getters.GET_DOCUMENT_TEMPLATES_API_RESPONSE]: (state: DocumentsState): any =>
            state[State.DOCUMENT_TEMPLATES_API_RESPONSE] || {},
        [Getters.GET_DOCUMENT_TEMPLATE_BY_ID]:
            (state: DocumentsState): DocumentTemplate | object =>
            (id: string) =>
                state[State.DOCUMENT_TEMPLATES][id] || {},
        [Getters.GET_DOCUMENT_TEMPLATE_BY_IDS]: (state: DocumentsState) => (ids: string[]) => {
            const templates: DocumentTemplate[] = [];
            ids.forEach((id: string) => {
                if (state[State.DOCUMENT_TEMPLATES] && state[State.DOCUMENT_TEMPLATES][id]) {
                    templates.push(state[State.DOCUMENT_TEMPLATES][id]);
                }
            });
            return templates;
        },

        // Document assets
        [Getters.GET_DOCUMENT_ASSETS]: (state: DocumentsState): DocumentAsset[] =>
            Object.values(state[State.DOCUMENT_ASSETS]) || [],
        [Getters.GET_DOCUMENT_ASSET_BY_ID]:
            (state: DocumentsState): DocumentAsset | object =>
            (id: string) =>
                state[State.DOCUMENT_ASSETS][id] || {},
        [Getters.GET_DOCUMENT_ASSETS_API_RESPONSE]: (state: DocumentsState): any =>
            state[State.DOCUMENT_ASSETS_API_RESPONSE] || {},
    },
};

export default store;
