
import Vue, { type PropType } from 'vue';

// components
import AppIcon from '@/components/partials/icon/AppIcon.vue';
import FileItem from '@/components/partials/inputs/FileItem.vue';

// helpers
import { ICON_TYPES } from '@/common/iconHelper';
import { formatDataAmount } from '@/common/formatting';
import { type EntityUploadStatus } from '@/__new__/services/dno/progressTracker/models/entity';

export type FileListItem = {
    file: File;
    loadingPercentage: number;
    errorMessage?: string;
    entityName?: string;
    status?: EntityUploadStatus;
};

export default Vue.extend({
    name: 'FileUploaderV2',
    components: {
        AppIcon,
        FileItem,
    },
    props: {
        value: {
            type: Array as PropType<FileListItem[]>,
            default: () => [],
        },
        // a string of comma separated extension ( ".txt, .csv")
        acceptType: {
            type: String,
            required: true,
        },
        multiple: {
            type: Boolean,
            default: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        maxSize: {
            type: Number,
            default: 5 * 1024 * 1024, // 5MB
        },
    },
    data() {
        return {
            ICON_TYPES,
            isDragOn: false as boolean,
        };
    },
    computed: {
        acceptTypes(): string {
            return this.acceptType.replace(/\./g, ' ').trim().toUpperCase();
        },
        hasSelectedFiles(): boolean {
            return !!this.value.length;
        },
        maxSizeText(): string {
            return `${this.$t('generic.max').toString().toLowerCase()}. ${formatDataAmount(this.maxSize)}`;
        },
        isUploadDisabled(): boolean {
            return this.multiple ? this.disabled : this.disabled || this.hasSelectedFiles;
        },
    },
    methods: {
        isAppropriateFileType({ name }: File): boolean {
            const types = this.acceptType.split(',').map(ext => ext.trim());

            return types.some(t => name.endsWith(t));
        },
        isValidFileSize({ size }: File) {
            return size <= this.maxSize;
        },
        getFileItem({ name }: File): FileListItem | undefined {
            return this.value.find(({ file }) => file.name === name);
        },
        mapFiles(files: File[]): FileListItem[] {
            return files.map(file => ({
                file,
                loadingPercentage: this.getFileItem(file)?.loadingPercentage || 0,
                errorMessage: this.getFileItem(file)?.errorMessage || '',
            }));
        },
        updateFileList(files?: File[]): void {
            const value = this.value.concat(this.mapFiles(files || []));
            this.$emit('input', value);
        },
        validateAndUpdate(files: FileList | File[]): void {
            const fileNames = this.value.map(({ file }) => file.name);
            const fileList = Array.from(files).reduce(
                (acc, file) => {
                    if (this.isValidFileSize(file)) {
                        if (!fileNames.includes(file.name)) {
                            acc.valid.push(file);

                            return acc;
                        }

                        acc.duplicate.push(file);
                    } else {
                        acc.invalid.push(file);
                    }

                    return acc;
                },
                { valid: [], invalid: [], duplicate: [] } as Record<'valid' | 'invalid' | 'duplicate', File[]>,
            );

            this.updateFileList(fileList.valid);

            if (fileList.invalid.length) {
                const names = fileList.invalid.map(file => file.name).join(',\n');
                this.$showWarningAlert({
                    message: this.$tc('partials.fileUploader.fileExceedingMaxAllowedSize', names.length, { names }),
                });
            }

            if (fileList.duplicate.length) {
                const names = fileList.duplicate.map(file => file.name).join(',\n');
                this.$showWarningAlert({
                    message: this.$tc('partials.fileUploader.fileAlreadyPresent', names.length, { names }),
                });
            }
        },
        onChange(e: Event): void {
            const { files } = e.target as HTMLInputElement;
            this.validateAndUpdate(files as FileList);
        },
        onDrop(e: DragEvent): void {
            const { files } = e.dataTransfer as DataTransfer;
            const validFiles = Array.from(files).filter(this.isAppropriateFileType);
            this.isDragOn = false;

            if (validFiles.length !== files.length) {
                this.$showWarningAlert({
                    message: `${this.$t('partials.fileUploader.invalidFileTypeText')} ${this.$t(
                        'partials.fileUploader.allowedFileTypeText',
                    )} ${this.acceptType}.`,
                });
            }

            this.validateAndUpdate(validFiles);
        },
        onFileRemove(index: number): void {
            this.$delete(this.value, index);
            this.updateFileList();
        },
        onFileRetry(fileItem: FileListItem): void {
            this.$emit('retry', fileItem);
        },
        onErrorDetails(fileItem: FileListItem) {
            this.$emit('errorDetails', fileItem);
        },
    },
});
