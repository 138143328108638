import UserManagerBaseModel, {
    PROPERTY_TYPE_BE_MAP,
    PROPERTY_TYPE_VALIDATE_FN_MAP,
} from '@/__new__/services/dno/user/models/UserManagerBaseModel';
import AccessRole from '@/__new__/services/dno/user/models/AccessRole';
import Flag, { FLAG_MAP, FLAG_TYPE, Flags, FLAG_IS_LOCALLY_TIED } from '@/__new__/services/dno/user/models/Flag';
import { USER_FIELDS, USER_MANAGER_HIERARCHY } from '@/__new__/features/customerCare/common/customerCareHelper';
import { epochToDate } from '@/common/formatting';
import { getOperatorConfigValue, getUserVerificationIdType } from '@/services/permissions/permissions.service';
import { get, isEqual } from 'lodash';
import moment from 'moment';
import localeLibrary from '@/common/locale/localeLibrary';

export enum NOTIFICATION_INFO_TYPES {
    MSISDN = 'msisdn',
    EMAIL = 'email',
}

export enum USER_INFO_CRED_TYPES {
    SECURE_ID_INTERNAL = 'SECURE_ID_INTERNAL',
}

export enum IS_OTP_VALIDATED {
    OTP = 3,
}

export enum IS_PIN_SETUP {
    PIN = 3,
}

type EditUserPropsForUpdatePayload = {
    field: string;
    key: string;
    optional: boolean;
    formatter?: (val: string) => string;
    linkedToField?: string;
    restrictEmpty?: boolean;
};

interface EditUserPropsForUpdate {
    [key: string]: EditUserPropsForUpdatePayload[];
}

export type NotificationIdentifier = {
    identifier_type: NOTIFICATION_INFO_TYPES.MSISDN | NOTIFICATION_INFO_TYPES.EMAIL;
    verified: boolean;
    identifier: string;
};

interface UserConstructorArgs {
    id: string;
    name: string;
    surname: string;
    middleName: string;
    mothersMaidenName: string;
    tcAcceptanceTimestamp: number | null;
    isTcGlobalAcceptedCode: number;
    documentType: string;
    documentSrc: string;
    documentNumber: string;
    emailSecret: string;
    emailSecretVerified: boolean;
    creds: { id_type: string; verified: boolean; id: string }[];
    facebook: string;
    msisdn: string;
    notificationEmail: string;
    notificationMSISDN: string;
    notificationIdentifiers: NotificationIdentifier[];
    birthday: string | number;
    ownedResources: { [key: string]: number };
    permissions: any;
    nationality: string;
    documentSubtype: string;
    flags: Flags<number>;
    issuingCountry: string;
    creationDate: number;
    lastActiveDate: number;
    cardinality: number;
    externalEmail: string;
    externalMSISDN: string;
    pin: boolean;
    isTmoUser: boolean;
    groups: Record<string, number>;
    phone: string;
}

interface UserProperty {
    responsePath?: string;
    fallbackType?: keyof typeof PROPERTY_TYPE_VALIDATE_FN_MAP;
    defaultValue?: any;
    mapFn?(response: any): any;
    computeFn?(args: Partial<UserConstructorArgs>): any;
}

interface UserProperties {
    [key: string]: UserProperty;
}

export const USER_PROPERTIES: UserProperties = {
    id: {
        responsePath: 'user_id',
        fallbackType: 'string',
        defaultValue: '',
    },
    name: {
        responsePath: 'properties.first_name',
    },
    surname: {
        responsePath: 'properties.last_name',
    },
    middleName: {
        responsePath: 'properties.middle_name',
    },
    mothersMaidenName: {
        responsePath: 'properties.mothers_maiden_name',
    },
    tcAcceptanceTimestamp: {
        responsePath: 'properties.is_global_tc_accepted_timestamp',
        fallbackType: 'integer',
        defaultValue: '',
    },
    isTcGlobalAcceptedCode: {
        responsePath: 'flags.is_global_tc_accepted',
        defaultValue: null,
    },
    tcGlobalAccepted: {
        computeFn: (args: any) => {
            return Flag.getValueLabel(FLAG_TYPE.TC_GLOBAL_ACCEPTED, args?.isTcGlobalAcceptedCode);
        },
        fallbackType: 'string',
        defaultValue: '',
    },
    fullName: {
        computeFn: function (this: any, args) {
            return this.validateString('fullName', args?.name) && this.validateString('fullName', args?.surname)
                ? `${args?.name} ${args?.surname}`
                : '';
        },
        fallbackType: 'string',
        defaultValue: '',
    },
    documentType: {
        responsePath: 'properties.document_type',
        fallbackType: 'string',
        defaultValue: '',
    },
    documentSubtype: {
        responsePath: 'properties.document_subtype',
        fallbackType: 'string',
        defaultValue: '',
    },
    documentSrc: {
        responsePath: 'properties.document_pic_url',
        fallbackType: 'string',
        defaultValue: '',
    },
    documentNumber: {
        responsePath: 'properties.document_number',
        fallbackType: 'string',
        defaultValue: '',
    },
    emailSecret: {
        mapFn: (response: any) => {
            const emailSecretObj = response.creds
                ? response.creds.find((cred: any) => cred.id_type.toLowerCase() === 'email_secret')
                : null;
            return emailSecretObj ? emailSecretObj.id : '';
        },
        fallbackType: 'string',
        defaultValue: '',
    },
    emailSecretVerified: {
        mapFn: (response: any) => {
            const emailSecretObj = response.creds
                ? response.creds.find((cred: any) => cred.id_type.toLowerCase() === 'email_secret')
                : null;
            return emailSecretObj ? emailSecretObj.verified : false;
        },
    },
    creds: {
        responsePath: 'creds',
        fallbackType: 'array',
        defaultValue: [],
    },
    facebook: {
        mapFn: response => {
            const facebook = response.creds
                ? response.creds.find((cred: any) => cred.id_type.toLowerCase() === 'facebook')
                : null;
            return facebook ? facebook.id : '';
        },
        fallbackType: 'string',
        defaultValue: '',
    },
    msisdn: {
        mapFn: response => {
            const msisdns: string[] = [];
            if (
                response.notification_info &&
                Object.entries(response.notification_info).filter(
                    (notInfo: any[]) => notInfo[1].toLowerCase() === NOTIFICATION_INFO_TYPES.MSISDN,
                ).length
            ) {
                Object.entries(response.notification_info)
                    .filter((notInfo: any[]) => notInfo[1].toLowerCase() === NOTIFICATION_INFO_TYPES.MSISDN)
                    .forEach(notInfo => {
                        msisdns.push(notInfo[0]);
                    });
            } else if (response.creds) {
                response.creds
                    .filter((cred: any) => cred.id_type.toLowerCase() === NOTIFICATION_INFO_TYPES.MSISDN)
                    .forEach((cred: any) => {
                        msisdns.push(cred.id);
                    });
            }
            return msisdns.join(',');
        },
        fallbackType: 'string',
        defaultValue: '',
    },
    notificationEmail: {
        mapFn: response => {
            const notificationEmail = response.notification_info
                ? Object.entries(response.notification_info).find(
                      (notInfo: any[]) => notInfo[1].toLowerCase() === NOTIFICATION_INFO_TYPES.EMAIL,
                  )
                : null;
            return notificationEmail ? notificationEmail[0] : '';
        },
        fallbackType: 'string',
        defaultValue: '',
    },
    notificationMSISDN: {
        mapFn: response => {
            const notificationMSISDN = response.notification_info
                ? Object.entries(response.notification_info).find(
                      (notInfo: any[]) => notInfo[1].toLowerCase() === NOTIFICATION_INFO_TYPES.MSISDN,
                  )
                : null;
            return notificationMSISDN ? notificationMSISDN[0] : '';
        },
        fallbackType: 'string',
        defaultValue: '',
    },
    notificationIdentifiers: {
        mapFn: ({ notification_identifiers = [] }: { notification_identifiers: [] }) => {
            const defaultIdType = getUserVerificationIdType();
            return notification_identifiers.map((i: NotificationIdentifier) => ({
                ...i,
                isDefault: defaultIdType === i.identifier_type,
            }));
        },
        fallbackType: 'array',
        defaultValue: [],
    },
    birthday: {
        responsePath: 'properties.birthday',
        computeFn: function (this: any, args: any) {
            let bDay: UserConstructorArgs['birthday'] = '';
            const birthdayTypeBe = getOperatorConfigValue('service_config.lf-user.properties.user_properties.birthday');
            if (birthdayTypeBe === 'number' || this.validateNumberInteger('birthday', args.birthday)) {
                bDay = epochToDate(args.birthday);
            } else if (birthdayTypeBe === 'string' || this.validateString('birthday', args.birthday)) {
                bDay = args.birthday;
            }
            return bDay;
        },
    },
    ownedResources: {
        responsePath: 'owned_resources',
    },
    subscriberIds: {
        computeFn: function (this: any, args: any) {
            return this.validateObject('subscriberIds', args.ownedResources)
                ? Object.entries(args.ownedResources)
                      .filter(res => res[1] === USER_MANAGER_HIERARCHY.SUBSCRIBER)
                      .map(res => res[0])
                : [];
        },
    },
    accountIds: {
        computeFn: function (this: any, args: any) {
            return this.validateArray('accountIds', args.ownedResources)
                ? Object.entries(args.ownedResources)
                      .filter(res => res[1] === USER_MANAGER_HIERARCHY.ACCOUNT)
                      .map(res => res[0])
                : [];
        },
    },
    organizationIds: {
        computeFn: function (this: any, args: any) {
            return this.validateArray('organizationIds', args.ownedResources)
                ? Object.entries(args.ownedResources)
                      .filter(res => res[1] === USER_MANAGER_HIERARCHY.ORGANIZATION)
                      .map(res => res[0])
                : [];
        },
    },
    permissions: {
        responsePath: 'permissions',
        mapFn: response => {
            return response?.permissions ? User.mapPermissions(response.permissions) : [];
        },
        fallbackType: 'array',
        defaultValue: [],
    },
    nationality: {
        responsePath: 'properties.issuing_country',
        fallbackType: 'string',
        defaultValue: '',
    },
    flags: {
        responsePath: 'flags',
        mapFn: response => {
            const flags = response?.flags ?? {};
            const flagsConfig: FLAG_TYPE[] | Record<string, never> = getOperatorConfigValue(
                'service_config.lf-user.flags.user_flags',
                [],
            );
            // add is_test
            if (!Object.hasOwnProperty.call(flags, FLAG_TYPE.IS_TEST)) {
                flags[FLAG_TYPE.IS_TEST] = FLAG_MAP.FALSE;
            }
            // add is_profile_deleted if enabled in operator config
            if (
                getOperatorConfigValue('service_config.lf-user.allow_marking_profile_as_deleted') &&
                !Object.hasOwnProperty.call(flags, FLAG_TYPE.IS_PROFILE_DELETED)
            ) {
                flags[FLAG_TYPE.IS_PROFILE_DELETED] = undefined;
            }
            // add is_password_update_required if enabled in operator config
            if (
                Array.isArray(flagsConfig) &&
                flagsConfig.includes(FLAG_TYPE.IS_PASSWORD_UPDATE_REQUIRED) &&
                !Object.hasOwnProperty.call(flags, FLAG_TYPE.IS_PASSWORD_UPDATE_REQUIRED)
            ) {
                flags[FLAG_TYPE.IS_PASSWORD_UPDATE_REQUIRED] = undefined;
            }
            return flags;
        },
        computeFn: args => {
            return Flag.mapUserFlags(args?.flags ?? {});
        },
    },
    issuingCountry: {
        responsePath: 'properties.issuing_country',
        fallbackType: 'string',
        defaultValue: '',
    },
    creationDate: {
        responsePath: 'created',
        fallbackType: 'string',
        computeFn: ({ creationDate }) => creationDate && localeLibrary.getFormattedDateAndTime(creationDate),
        defaultValue: '',
    },
    lastActiveDate: {
        responsePath: 'last_activity_time',
        fallbackType: 'string',
        defaultValue: '',
        computeFn: ({ lastActiveDate }) => lastActiveDate && localeLibrary.getFormattedDateAndTime(lastActiveDate),
    },
    cardinality: {
        responsePath: 'cardinality',
        fallbackType: 'integer',
        defaultValue: '',
    },
    externalEmail: {
        responsePath: 'properties.social_email',
        fallbackType: 'string',
        defaultValue: '',
    },
    externalMSISDN: {
        responsePath: 'properties.social_msisdn',
        fallbackType: 'string',
        defaultValue: '',
    },
    pin: {
        responsePath: 'properties.pin',
        fallbackType: 'boolean',
        defaultValue: false,
    },
    isTmoUser: {
        responsePath: 'properties.is_tmo_user',
        fallbackType: 'boolean',
        defaultValue: false,
    },
    groups: {
        responsePath: 'groups',
        fallbackType: 'object',
        defaultValue: {},
    },
    phone: {
        responsePath: 'properties.phone',
        fallbackType: 'string',
        defaultValue: '',
    },
};

export default class User extends UserManagerBaseModel {
    id!: string;
    name?: string;
    surname?: string;
    fullName?: string;
    middleName?: string;
    mothersMaidenName?: string;
    tcAcceptanceTimestamp?: number | string;
    tcGlobalAccepted?: any;
    documentType?: string;
    documentSrc?: string;
    documentNumber?: string;
    documentSubtype?: string;
    emailSecret?: string;
    emailSecretVerified?: boolean;
    creds?: { id_type?: string; verified?: boolean; id?: string }[];
    facebook?: string;
    msisdn?: string;
    notificationEmail?: string;
    notificationMSISDN?: string;
    notificationIdentifiers?: { identifier_type?: string; verified?: boolean; identifier?: string }[];
    birthday?: string | number;
    subscriberIds?: string[];
    accountIds?: string[];
    organizationIds?: string[];
    permissions?: AccessRole[];
    nationality?: string;
    flags?: Flags;
    groups?: Record<string, number>;
    umEntityType: USER_MANAGER_HIERARCHY.USER;
    // Same as nationality.
    issuingCountry?: string;
    externalEmail?: string;
    externalMSISDN?: string;
    pin?: boolean;
    isTmoUser?: boolean;
    creationDate?: string;
    lastActiveDate?: string;
    phone?: string;

    static propertiesForUpdate: EditUserPropsForUpdate = {
        EKYC: [
            {
                field: 'name',
                key: USER_FIELDS.FIRST_NAME,
                optional: false,
                formatter: val => val.toUpperCase(),
            },
            {
                field: 'surname',
                key: USER_FIELDS.LAST_NAME,
                optional: false,
                formatter: val => val.toUpperCase(),
            },
            {
                field: 'birthday',
                key: USER_FIELDS.BIRTHDAY,
                optional: false,
                formatter: val => moment(val).format('YYYY-MM-DD'),
            },
            {
                field: 'issuingCountry',
                key: USER_FIELDS.ISSUING_COUNTRY,
                optional: false,
            },
            {
                field: 'documentType',
                key: USER_FIELDS.DOCUMENT_TYPE,
                optional: true,
                linkedToField: 'documentSubtype',
            },
            {
                field: 'documentSubtype',
                key: USER_FIELDS.DOCUMENT_SUBTYPE,
                optional: true,
            },
            {
                field: 'documentNumber',
                key: USER_FIELDS.DOCUMENT_NUMBER,
                optional: true,
            },
            {
                field: 'nationality',
                key: USER_FIELDS.NATIONALITY,
                optional: true,
            },
        ],
        DEFAULT: [
            {
                field: 'name',
                key: USER_FIELDS.FIRST_NAME,
                optional: true,
            },
            {
                field: 'surname',
                key: USER_FIELDS.LAST_NAME,
                optional: true,
            },
            {
                field: 'birthday',
                key: USER_FIELDS.BIRTHDAY,
                optional: true,
                formatter: val => moment(val).format('YYYY-MM-DD'),
                restrictEmpty: true,
            },
            {
                field: 'documentType',
                key: USER_FIELDS.DOCUMENT_TYPE,
                optional: true,
            },
            {
                field: 'phone',
                key: USER_FIELDS.PHONE,
                optional: true,
            },
        ],
    };

    constructor(args: Partial<UserConstructorArgs>) {
        // Call constructor of UserManagerBaseModel class for creating new object with unique invalidKeys Map
        super();
        this.umEntityType = USER_MANAGER_HIERARCHY.USER;

        for (const [key, props] of Object.entries(USER_PROPERTIES)) {
            let value: any = args?.[key as keyof Partial<UserConstructorArgs>];
            if (props?.computeFn instanceof Function) {
                value = props.computeFn.call(this, args);
            }
            (<any>this)[key] = this.validateProperty(key, value, props);
        }
    }

    validateProperty(key: string, value: any, props: UserProperty) {
        // validate property value
        let propertyType = props?.fallbackType;
        const propertyKeyBe = props?.responsePath?.split('.').pop();
        if (propertyKeyBe) {
            // get property type from BE config
            const propertyTypeBe = getOperatorConfigValue(
                `service_config.lf-user.properties.user_properties.${propertyKeyBe}`,
            );

            propertyType = (<any>PROPERTY_TYPE_BE_MAP)[propertyTypeBe];
            // For the birthday property, after being processed by computeFn, is string type.
            if (propertyKeyBe === USER_FIELDS.BIRTHDAY) {
                propertyType = 'string';
            }
        }
        const defaultValue = props?.defaultValue ?? null;
        // apply validation
        let validatedValue = value;
        if (propertyType && !this.validateByType(key, value, propertyType)) {
            validatedValue = defaultValue;
        }
        return validatedValue ?? defaultValue;
    }

    static clone(original: User): User {
        return Object.assign(Object.create(Object.getPrototypeOf(original)), original);
    }

    static mapPermissions(permissions: any): AccessRole[] {
        return permissions.map(
            (perm: any) =>
                new AccessRole({
                    targetId: perm.target_id,
                    targetTypeId: perm.target_type,
                    roleName: perm.role_name,
                    email: perm?.email || '',
                } as AccessRole),
        );
    }

    static remapUserFromBe(response: any): any {
        const mappedUserObj: Record<string, any> = {};
        for (const [key, props] of Object.entries(USER_PROPERTIES)) {
            const path = props?.responsePath;
            if (props?.mapFn) {
                mappedUserObj[key] = props.mapFn(response);
            } else if (path) {
                mappedUserObj[key] = get(response, path);
            }
        }
        return mappedUserObj;
    }

    static mapUserPropsForUpdate(
        newUser: any,
        oldUser: any,
        propsForUpdate: EditUserPropsForUpdatePayload[] = User.propertiesForUpdate.DEFAULT,
    ): Record<string, any> {
        const result: any = {};

        if (propsForUpdate.length) {
            propsForUpdate.forEach(prop => {
                // Add mandatory props in any case, and add optional props only if value has changed
                if (!prop.optional || (prop.optional && !isEqual(newUser[prop.field], oldUser[prop.field]))) {
                    // Skip prop if it can't be empty and new value doesn't exist
                    if (prop.restrictEmpty && !newUser[prop.field]) {
                        return;
                    }

                    if (prop.key === USER_FIELDS.ISSUING_COUNTRY && !newUser[prop.field]) {
                        result[prop.key] = newUser.nationality;
                        return;
                    }
                    result[prop.key] = prop.formatter ? prop.formatter(newUser[prop.field]) : newUser[prop.field];
                }
                // If linked field value changed, send prop value as well
                if (
                    prop.linkedToField &&
                    !isEqual(newUser[prop.linkedToField], oldUser[prop.linkedToField]) &&
                    !result[prop.key]
                ) {
                    result[prop.key] = prop.formatter ? prop.formatter(newUser[prop.field]) : newUser[prop.field];
                }
            });
        }

        return result;
    }

    static mapUserEKYCPropsForUpdate(
        ekycStatus: string,
        isLocallyTied: FLAG_IS_LOCALLY_TIED,
        newUser: any,
        oldUser: any,
    ): { [key: string]: any } {
        return {
            status: ekycStatus,
            is_locally_tied: isLocallyTied === FLAG_IS_LOCALLY_TIED.YES,
            user_properties: User.mapUserPropsForUpdate(newUser, oldUser, User.propertiesForUpdate.EKYC),
        };
    }
}
