<template>
    <div class="form">
        <h2>{{ formTitle }}</h2>

        <div
            v-if="!isUndo"
            class="radios"
        >
            <div class="d-flex mb-3">
                <AppRadioButton
                    v-model="suspendAction"
                    :checked="!isTerminated"
                    :inputValue="CANCEL_TERMINATE_ACTIONS.CANCEL"
                    :labelRight="$i18n.t('generic.cancel')"
                    :disabled="!canToggle"
                    name="action"
                    class="mr-2"
                    @input="onRadioInput"
                />
                <AppRadioButton
                    v-model="suspendAction"
                    :checked="isTerminated"
                    :inputValue="CANCEL_TERMINATE_ACTIONS.TERMINATE"
                    :labelRight="$i18n.t('generic.terminate')"
                    :disabled="!canToggle"
                    name="action"
                    @input="onRadioInput"
                />
            </div>

            <transition name="slide-fade-in">
                <p
                    v-show="isTerminated"
                    ref="terminateNotice"
                    class="terminate-notice"
                >
                    <img
                        src="@/assets/icons/info.svg"
                        class="alert-icon"
                        alt="info"
                    />
                    <span>{{ $i18n.t('alerts.nonReversibleAction') }} {{ $i18n.t('alerts.proceedWithCaution') }}</span>
                </p>
            </transition>
        </div>

        <div>
            <div class="reason mb-3">
                <h3 class="text--xs mb-1">
                    {{ reasonTitle }}
                </h3>

                <AppMultiselectV3
                    v-model="reason"
                    :options="reasonOptions"
                    :showLabels="false"
                    :error="$v.reason.$error"
                    :errorMessage="$i18n.t('generic.validations.fieldIsRequired')"
                    label="label"
                >
                    <template
                        slot="option"
                        slot-scope="props"
                    >
                        <span>{{ props.option.label }}</span>
                        <span
                            v-if="props.option.description"
                            :style="`color: ${props.option.description.color};`"
                        >
                            ({{ props.option.description.text }})
                        </span>
                    </template>
                </AppMultiselectV3>
            </div>

            <div class="mb-5">
                <h3 class="text--xs mb-1">
                    {{ $i18n.t('generic.notes') }}
                </h3>

                <CustomTextarea
                    v-model="notesTxt"
                    :placeholder="$i18n.t('generic.addNotes')"
                    :invalid="$v.notesTxt.$error"
                    class="input"
                />

                <p
                    v-show="$v.notesTxt.$error"
                    class="error-message mt-2"
                >
                    {{ $i18n.t('generic.validations.fieldIsRequired') }}
                </p>
            </div>
        </div>

        <div class="d-flex justify-content-end">
            <AppButton
                :label="$i18n.t('generic.cancel')"
                data-test-id="btn-close"
                @click="close"
            />
            <AppButton
                :buttonType="BUTTON_TYPES.PRIMARY"
                :label="submitLabel"
                :disabled="isTrial"
                class="ml-5"
                data-test-id="btn-submit"
                @click="onSubmit"
            />
        </div>
    </div>
</template>

<script>
// Components
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import AppMultiselectV3 from '@/components/partials/inputs/AppMultiselectV3.vue';
import AppRadioButton from '@/components/partials/inputs/AppRadioButton.vue';
import CustomTextarea from '@/__new__/features/customerCare/subscriber/CustomTextarea.vue';
import supportButtonMixin from '@/components/alerts/supportButtonMixin';

// http
import userManagementHTTP from '@/__new__/services/dno/user/http/user-management';
import ordersHTTP from '@/__new__/services/dno/orders/http/orders';
import notesHTTP from '@/__new__/services/dno/crm/http/crm';

// vuex
import { mapActions } from 'vuex';

// Helpers
import {
    USER_MANAGER_HIERARCHY,
    CANCEL_TERMINATE_ACTIONS,
    CANCELATION_REASON,
    TERMINATION_REASON,
    SERVICES_REASON_TAGS_LABEL_MAP,
} from '@/__new__/features/customerCare/common/customerCareHelper';
import { required } from 'vuelidate/lib/validators';
import { ALERT_TYPES } from '@/common/alerts/Alert';
import luaErrors, { modules } from '@/common/luaErrors';
import permissionsService, { isUserAllowed, isViewEnabled } from '@/services/permissions/permissions.service';
import { dateToEpoch } from '@/common/formatting';

// sentry
import * as Sentry from '@sentry/vue';

export default {
    name: 'SubscriberCancelTerminateForm',
    components: {
        AppButton,
        AppMultiselectV3,
        AppRadioButton,
        CustomTextarea,
    },
    mixins: [supportButtonMixin],
    props: {
        action: {
            required: true,
            type: String,
        },
        accountId: {
            required: true,
            type: String,
        },
        subscriberId: {
            default: '',
            type: String,
        },
    },
    data() {
        return {
            CANCEL_TERMINATE_ACTIONS,
            suspendAction: '',
            reason: '',
            notesTxt: '',
            isTrial: true,
            BUTTON_TYPES,
            isSubscriberTerminationEnabled:
                permissionsService.userManagementTerminationEnabled() &&
                isUserAllowed('UMSubscriberEditSubscriberTerminateUndoTermination'),
            isAccountTerminationEnabled:
                permissionsService.userManagementAccountTerminationEnabled() &&
                isUserAllowed('UMAccountEditAccountTerminateUndoTermination'),
            isAccountDeleteOnCancelEnabled:
                permissionsService.userManagementCancelSubscriptionAndDeleteAccountEnabled() &&
                isViewEnabled('UMAccountDeleteOnCancel'),
        };
    },
    computed: {
        canToggle() {
            const canTerminate = this.subscriberId
                ? this.isSubscriberTerminationEnabled
                : this.isAccountTerminationEnabled;

            return canTerminate && !this.isTrial && this.isCancelSubscriptionEnabled;
        },
        isUndo() {
            return CANCEL_TERMINATE_ACTIONS.CANCEL_UNDO === this.action;
        },
        isCancelSubscriptionEnabled() {
            const isAllowed = this.subscriberId
                ? isUserAllowed('UMSubscriberEditSubscriberCancelUndoCancel')
                : isUserAllowed('UMAccountEditAccountCancelUndoCancel');

            return permissionsService.userManagementCancelSubscriptionEnabled() && isAllowed;
        },
        isTerminated() {
            return this.suspendAction === CANCEL_TERMINATE_ACTIONS.TERMINATE;
        },
        reasonOptions() {
            if (!this.isTerminated) {
                return [
                    {
                        id: CANCELATION_REASON.CUSTOMER_REQUESTED,
                        label: this.$i18n.t('customerCare.cancelTerminate.cancelReqByCustomer'),
                        description: {
                            text: this.$i18n.t('customerCare.cancelTerminate.terminationByTheEndOfBillCycle'),
                            color: '#999',
                        },
                    },
                ];
            }
            return [
                {
                    id: TERMINATION_REASON.FRAUD,
                    label: this.$i18n.t('customerCare.subscriberTab.isFraud'),
                    description: {
                        text: this.$i18n.t('customerCare.cancelTerminate.immediateTermination'),
                        color: '#e8504c',
                    },
                },
            ];
        },
        formTitle() {
            return this.isUndo
                ? this.$i18n.t('customerCare.cancelTerminate.undoCancelation')
                : this.$i18n.t('customerCare.cancelTerminate.cancelTerminateSubscription');
        },
        reasonTitle() {
            if (this.isUndo) {
                return this.$i18n.t('customerCare.cancelTerminate.undoCancelationReason');
            }
            return this.isTerminated
                ? this.$i18n.t('customerCare.cancelTerminate.terminationReason')
                : this.$i18n.t('customerCare.cancelTerminate.cancelationReason');
        },
        submitLabel() {
            if (this.isUndo) {
                return this.$i18n.t('customerCare.cancelTerminate.undoCancelation');
            }
            return this.isTerminated
                ? this.$i18n.t('customerCare.cancelTerminate.terminateServices')
                : this.$i18n.t('customerCare.cancelTerminate.cancelServices');
        },
        inProgressNoticeTxt() {
            return this.isTerminated
                ? this.$i18n.t('customerCare.cancelTerminate.alerts.terminationBeingProcessed')
                : this.$i18n.t('customerCare.cancelTerminate.alerts.cancelationBeingProcessed');
        },
    },
    validations() {
        return {
            reason: {
                required,
            },
            notesTxt: {
                required,
            },
        };
    },
    mounted() {
        if (!this.isUndo) this.suspendAction = this.action;

        this.fetchIsOnTrialPlan();
    },
    methods: {
        ...mapActions('userManagementSubscriber', ['setSuspendedService']),
        close() {
            this.$emit('close');
            // Reset the values used for redirection
            if (this.subscriberId) {
                this.setSuspendedService({
                    subscriberId: this.subscriberId,
                    suspendedService: {},
                });
            }
        },
        onRadioInput() {
            this.reason = '';
        },
        getTags() {
            const tags = [];

            if (this.isUndo) {
                return [this.$i18n.t('customerCare.cancelTerminate.undoCancelation')];
            }

            tags.push(
                this.isTerminated
                    ? this.$i18n.t('generic.stateMap.terminated')
                    : this.$i18n.t('generic.stateMap.canceled'),
            );

            if (this.reason.id) {
                tags.push(this.$i18n.t(SERVICES_REASON_TAGS_LABEL_MAP.get(this.reason.id)));
            }

            return tags;
        },
        getSubmitAction() {
            if (this.isUndo) {
                return this.undoCancelSubscription();
            }

            if (this.isTerminated) {
                return this.terminateServices();
            }

            if (this.isAccountDeleteOnCancelEnabled && !this.subscriberId) {
                return this.scheduleAccountDelete();
            }

            return this.cancelServices();
        },
        async onSubmit() {
            this.$v.$touch();

            if (this.$v.$invalid) return;

            this.close();

            if (!this.isUndo) {
                this.$eventBus.$emit('showAlert', {
                    message: this.inProgressNoticeTxt,
                    type: ALERT_TYPES.info,
                });
            }

            try {
                await this.getSubmitAction();
                await this.setAgentNote();

                this.$eventBus.$emit('updateEntity');
            } catch (e) {
                // eslint-disable-next-line consistent-return
                return e;
            }
        },
        /**
         * Sets agent notes that will be used by the NotesSection component.
         */
        async setAgentNote() {
            try {
                this.$Progress.start();

                // Notice: Update id/idType when subscriber get 'NotesSection'
                await notesHTTP.addNote({
                    id: this.accountId,
                    idType: USER_MANAGER_HIERARCHY.ACCOUNT,
                    epoch: dateToEpoch(Date.now()),
                    noteText: this.notesTxt,
                    tags: this.getTags(),
                });
                this.$Progress.finish();
            } catch (error) {
                this.$Progress.fail();
                Sentry.captureException(error);
                this.showSupportAlert(this.$i18n.t('alertMessage.somethingWentWrong'), ALERT_TYPES.error);
            }
        },
        async cancelServices() {
            try {
                this.$Progress.start();

                await userManagementHTTP.cancelServices({
                    targetId: this.accountId,
                    targetType: USER_MANAGER_HIERARCHY.ACCOUNT,
                    subscriberId: this.subscriberId,
                });

                this.$Progress.finish();
                this.$showWarningAlert({
                    message: this.$i18n.t('customerCare.cancelTerminate.alerts.isSetForCancelAtTheEndOfBillCycle'),
                });
            } catch (e) {
                const { module: moduleName, code } = { ...e.response?.data };
                const noActiveServices =
                    (moduleName === modules.ORDEROE && code === luaErrors.ORDEROE.ORDER_NOT_FOUND.code) ||
                    (moduleName === modules.ORCHESTRATION && code === luaErrors.ORCHESTRATION.ENTITY_NOT_FOUND.code);
                const isPorting =
                    moduleName === modules.ORDEROE && code === luaErrors.ORDEROE.PORT_IN_ALREADY_INITIATED.code;

                this.$Progress.fail();
                if (noActiveServices) {
                    this.$alert(this.$i18n.t('customerCare.cancelTerminate.alerts.noActiveServicesToCancel'), {
                        type: ALERT_TYPES.warning,
                    });
                } else if (isPorting) {
                    this.$alert(this.$i18n.t('customerCare.cancelTerminate.alerts.cannotCancelWhenPorting'), {
                        type: ALERT_TYPES.warning,
                    });
                } else {
                    Sentry.captureException(e);
                    this.$alert(this.$i18n.t('customerCare.cancelTerminate.alerts.cancelationFailed'), {
                        type: ALERT_TYPES.error,
                    });
                }
                throw e;
            }
        },
        async undoCancelSubscription() {
            try {
                this.$Progress.start();

                await userManagementHTTP.resumeServices({
                    targetId: this.accountId,
                    targetType: USER_MANAGER_HIERARCHY.ACCOUNT,
                    subscriberId: this.subscriberId,
                });

                this.$Progress.finish();
                this.$showSuccessAlert({
                    message: this.$i18n.t('customerCare.cancelTerminate.alerts.undoCancelationRequest'),
                });
            } catch (e) {
                const { module: moduleName, code } = { ...e.response?.data };

                this.$Progress.fail();
                if (moduleName === modules.ORDEROE && code === luaErrors.ORDEROE.ORDER_NOT_FOUND.code) {
                    this.$alert(
                        this.$i18n.t('customerCare.cancelTerminate.alerts.noOrdersPendingCancelationToResume'),
                        {
                            type: ALERT_TYPES.warning,
                        },
                    );
                } else {
                    Sentry.captureException(e);
                    this.$alert(this.$i18n.t('customerCare.subscriberTab.somethingWrongInCancelation'), {
                        type: ALERT_TYPES.error,
                    });
                }
                throw e;
            }
        },
        async scheduleAccountDelete() {
            try {
                this.$Progress.start();

                await userManagementHTTP.deleteAccount(this.accountId);

                this.$eventBus.$emit('accountDelete');
                this.$showWarningAlert({
                    message: this.$i18n.t('customerCare.cancelTerminate.alerts.isAccountSetForCancelation'),
                });

                await this.$Progress.finish();
            } catch (e) {
                Sentry.captureException(e);
                this.$Progress.fail();
                this.$alert(this.$i18n.t('customerCare.cancelTerminate.alerts.accountCancelationFailed'));
                throw e;
            }
        },
        async terminateServices() {
            try {
                this.$Progress.start();

                const data = {
                    targetId: this.accountId,
                    targetType: USER_MANAGER_HIERARCHY.ACCOUNT,
                    reasonCode: this.reason.id,
                    reasonTxt: this.notesTxt,
                };

                if (this.subscriberId) {
                    data.subscriberId = this.subscriberId;
                }

                await ordersHTTP.terminateAccount(data);

                this.$Progress.finish();
                this.$showSuccessAlert({
                    message: this.$i18n.t('customerCare.cancelTerminate.alerts.isNowTerminated'),
                });
            } catch (e) {
                const { module: moduleName, code } = { ...e.response?.data?.value };
                this.$Progress.fail();
                if (moduleName === modules.ORDEROE && code === luaErrors.ORDEROE.TERMINATION_ALREADY_REQUESTED.code) {
                    this.$alert(this.$i18n.t('customerCare.cancelTerminate.alerts.terminationAlreadyRequested'), {
                        type: ALERT_TYPES.warning,
                    });
                } else {
                    Sentry.captureException(e);
                    this.$alert(this.$i18n.t('customerCare.cancelTerminate.alerts.terminationFailed'), {
                        type: ALERT_TYPES.error,
                    });
                }
                throw e;
            }
        },
        async fetchIsOnTrialPlan() {
            try {
                this.$Progress.start();

                const {
                    data: { user_data: userData },
                } = await ordersHTTP.oeGetUserData({
                    targetId: this.subscriberId || this.accountId,
                    targetType: this.subscriberId ? USER_MANAGER_HIERARCHY.SUBSCRIBER : USER_MANAGER_HIERARCHY.ACCOUNT,
                });

                this.isTrial = Boolean(Number(userData.is_trial));

                this.$Progress.finish();
            } catch (e) {
                this.$Progress.fail();
                Sentry.captureException(e);
                this.showSupportAlert(this.$i18n.t('alertMessage.somethingWentWrong'), ALERT_TYPES.error);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/animations';
@import '~@/assets/scss/palette';
@import '~@/assets/scss/layout';
@import '~@/assets/scss/typographyV2';

.form {
    padding: 2.5rem;
}

h2 {
    margin-bottom: 2.5rem;
}

h3 {
    font-weight: $bold-font-weight;
    line-height: $label-line-height;
}

.error-message {
    color: $red;
}

.terminate-notice {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    min-height: 3rem;
    padding: 0.625rem 1rem;
    border-radius: 0.25rem;
    font-size: $text-sm;
    font-weight: $medium-font-weight;
    color: $white;
    background-color: $yellow;
    transition: $fast-animation-time;

    &.slide-fade-in-enter-active,
    &.slide-fade-in-leave-active {
        opacity: 0;
        transform: translateX(-1.5rem);
    }
}

.radios ::v-deep label {
    color: $black;
}

#app .reason ::v-deep :is(.multiselect, .multiselect__input, .multiselect__single) {
    font-size: $text-sm;
}

#app .reason ::v-deep .multiselect__tags {
    padding-left: $spacing-xs;
}

.form ::v-deep textarea {
    width: 100%;
    padding-inline: $spacing-xs;
    border-radius: 0.5rem;
    font-size: $text-sm;

    &::placeholder {
        font-style: italic;
        opacity: 1;
        font-size: inherit;
    }
}
</style>
