<template>
    <div class="file-list d-flex align-items-center justify-content-between">
        <div
            v-if="file"
            class="file-container d-flex w-100"
        >
            <div
                class="file-info d-flex flex-column"
                :class="{ 'justify-content-center': file.status === FILE_UPLOAD_STATES.PENDING }"
            >
                <div class="basic-info d-flex justify-content-between">
                    <div class="d-flex align-items-center">
                        <QuickFilterTooltip
                            v-if="file.name"
                            :text="file.name"
                        >
                            <div class="file-text mr-2">
                                {{ file.name }}
                            </div>
                        </QuickFilterTooltip>
                        <EntityStatusIndicator
                            :stateMap="FILE_UPLOAD_STATES_TO_STATUS_NAME_MAP"
                            :stateMapColor="FILE_UPLOAD_INDICATOR_MAP"
                            :status="FILE_UPLOAD_STATES_TO_STATUS_NAME_MAP.get(file.status)"
                            :isInTable="false"
                        />
                    </div>
                    <div
                        v-if="statusProcessingOrCompleted"
                        class="d-flex"
                    >
                        <span
                            v-if="file.hasOwnProperty('total')"
                            class="file-text light"
                        >
                            {{ $i18n.t('partials.fileUploader.total') }}
                        </span>
                        <span
                            v-if="file.hasOwnProperty('total')"
                            class="file-text mr-2 ml-1"
                        >
                            {{ file.total }}</span
                        >
                        <span
                            v-if="file.hasOwnProperty('success')"
                            class="file-text light"
                        >
                            {{ $i18n.t('partials.fileUploader.success') }}</span
                        >
                        <span
                            v-if="file.hasOwnProperty('success')"
                            class="file-text green mr-2 ml-1"
                        >
                            {{ file.success }}</span
                        >
                        <span
                            v-if="file.hasOwnProperty('fail')"
                            class="file-text light"
                        >
                            {{ $i18n.t('partials.fileUploader.fail') }}</span
                        >
                        <span
                            v-if="file.hasOwnProperty('fail')"
                            class="file-text red ml-1"
                        >
                            {{ file.fail }}</span
                        >
                    </div>
                    <div v-if="file.status === FILE_UPLOAD_STATES.UPLOADING">
                        <span class="file-text light">{{ fileSize }}</span>
                    </div>
                </div>
                <div
                    v-if="displayProgressBar"
                    class="file-status d-flex justify-content-between align-items-center"
                >
                    <div class="file-loading">
                        <div
                            v-if="statusUploadingFailed"
                            class="file-loading-red"
                            :style="loadingWidth"
                        />
                        <div
                            v-else
                            class="file-loading-green"
                            :class="{ pulse: pulseLoadingBar }"
                            :style="loadingWidth"
                        />
                        <div class="file-loading-gray" />
                    </div>
                </div>
                <div
                    v-if="file.status === FILE_UPLOAD_STATES.PENDING"
                    class="file-subtext"
                >
                    {{ fileSize }}
                </div>
                <div
                    v-else-if="statusUploadingFailed"
                    class="file-subtext error"
                >
                    {{ $i18n.t('operator.numberManagement.error.uploadingFailedMessage') }}
                </div>
                <div
                    v-else-if="statusFailedOrPartial"
                    class="file-subtext error link"
                    @click="$parent.$emit('error', file.failedLinesString, file.failureEventToLineNumbers)"
                >
                    {{ $i18n.t('operator.numberManagement.error.processingFailedMessage') }}
                </div>
            </div>
            <div
                class="d-flex align-items-center justify-content-between"
                style="width: 3rem"
            >
                <AppIcon
                    v-if="displayIcons && iconConfig && file.loadingPercentage === 100"
                    :type="iconConfig.type"
                    :color="iconConfig.color"
                    class="file-icon"
                />
                <img
                    v-if="displayIcons"
                    src="@/assets/icons/icon-close-gray.svg"
                    class="file-icon close-icon"
                    alt="Remove file"
                    @click="$emit('removeFile')"
                />
            </div>
        </div>
    </div>
</template>

<script>
// COMPONENTS
import EntityStatusIndicator from '@/components/partials/EntityStatusIndicator.vue';
import AppIcon from '@/components/partials/icon/AppIcon.vue';
import QuickFilterTooltip from '@/components/partials/fileUploader/QuickFilterTooltip.vue';

// HELPERS
import {
    FILE_UPLOAD_STATES,
    FILE_UPLOAD_STATES_TO_STATUS_NAME_MAP,
    FILE_UPLOAD_INDICATOR_MAP,
} from '@/common/commonHelper';
import { formatDataAmount } from '@/common/formatting';
import { ICON_COLORS, ICON_TYPES } from '@/common/iconHelper';

export const FILE_UPLOAD_STATES_TO_ICONS_MAP = new Map([
    [FILE_UPLOAD_STATES.SUCCESSFUL_COMPLETED, { type: ICON_TYPES.WARNING, color: ICON_COLORS.GREEN }],
    [FILE_UPLOAD_STATES.PARTIAL_COMPLETED, { type: ICON_TYPES.WARNING, color: ICON_COLORS.YELLOW }],
    [FILE_UPLOAD_STATES.FAILED_COMPLETED, { type: ICON_TYPES.WARNING, color: ICON_COLORS.RED }],
    [FILE_UPLOAD_STATES.UPLOADING_FAILED, { type: ICON_TYPES.WARNING, color: ICON_COLORS.RED }],
]);

export default {
    name: 'FileUploaderSingleFile',
    components: { EntityStatusIndicator, AppIcon, QuickFilterTooltip },
    props: {
        file: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            FILE_UPLOAD_STATES_TO_STATUS_NAME_MAP,
            FILE_UPLOAD_INDICATOR_MAP,
            FILE_UPLOAD_STATES,
            ICON_COLORS,
            ICON_TYPES,
        };
    },
    computed: {
        loadingWidth() {
            return { width: `${this.file.loadingPercentage}%` };
        },
        fileSize() {
            return this.$i18n.t('operator.numberManagement.fileSize', {
                size: formatDataAmount(this.file.size),
            });
        },
        displayProgressBar() {
            return this.file.status !== FILE_UPLOAD_STATES.PENDING;
        },
        displayIcons() {
            return (
                this.file.status !== FILE_UPLOAD_STATES.UPLOADING && this.file.status !== FILE_UPLOAD_STATES.PROCESSING
            );
        },
        iconConfig() {
            return FILE_UPLOAD_STATES_TO_ICONS_MAP.has(this.file.status)
                ? FILE_UPLOAD_STATES_TO_ICONS_MAP.get(this.file.status)
                : { type: ICON_TYPES.WARNING, color: ICON_COLORS.GRAY };
        },
        statusFailedOrPartial() {
            return (
                this.file.status === FILE_UPLOAD_STATES.FAILED_COMPLETED ||
                this.file.status === FILE_UPLOAD_STATES.PARTIAL_COMPLETED
            );
        },
        statusProcessingOrCompleted() {
            return (
                this.statusFailedOrPartial ||
                this.file.status === FILE_UPLOAD_STATES.SUCCESSFUL_COMPLETED ||
                this.file.status === FILE_UPLOAD_STATES.PROCESSING
            );
        },
        statusUploadingFailed() {
            return this.file.status === FILE_UPLOAD_STATES.UPLOADING_FAILED;
        },
        pulseLoadingBar() {
            return (
                this.file.status === FILE_UPLOAD_STATES.UPLOADING || this.file.status === FILE_UPLOAD_STATES.PROCESSING
            );
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/mixins';
@import '~@/assets/scss/palette';
@import '~@/assets/scss/z-indexes';

@keyframes pulse {
    0% {
        opacity: 100%;
    }
    50% {
        opacity: 30%;
    }
    100% {
        opacity: 100%;
    }
}

.file-container {
    height: 5rem;
    margin-bottom: 1rem;
    width: 33rem;
}

.file-info {
    font-size: 0.75rem;
    width: 100%;

    .basic-info {
        height: 1.625rem;
        margin-bottom: 0.5rem;
        margin-top: 0.25rem;
    }
}

.file-text {
    color: $gray90;
    line-height: 2.17;
    height: 1.625rem;
    font-size: 0.75rem;
    font-weight: 600;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 12rem;
    overflow-x: hidden;

    &.light {
        font-weight: normal;
        color: $gray30;
    }

    &.red {
        color: $red;
    }

    &.green {
        color: $green;
    }
}

.file-loading {
    height: 0.5rem;
    width: 100%;
    position: relative;
    margin-bottom: 0.25rem;
    border-radius: 0.5rem;

    &-green,
    &-gray,
    &-red {
        height: 100%;
        border-radius: 0.5rem;
        position: absolute;
        top: 0;
        left: 0;
    }

    &-green {
        background: $green;
        z-index: $overlap-smth-z-index;

        &.pulse {
            animation: pulse 3s infinite;
            animation-fill-mode: forwards;
        }
    }

    &-red {
        background: $red;
        z-index: $overlap-smth-z-index;
    }

    &-gray {
        background: $dirty-white;
        width: 100%;
    }
}

.file-subtext {
    color: $gray30;
    line-height: 1.75;
    height: 1.25rem;

    &.error {
        color: $red;

        &.link {
            cursor: pointer;
        }
    }
}

.file-icon {
    width: 1rem;
    height: 1rem;
    margin: 0 0 0 0.5rem;

    &.hidden {
        visibility: hidden;
    }

    &.close-icon {
        cursor: pointer;
    }
}
</style>
