import i18n from '@/i18n';
import { TranslateResult } from 'vue-i18n';

export const TARIFF_SPEC_ZERO_RATE_STRING = 'zero-rate';

export enum ZERO_RATE_PRIORITY {
    MINIMUM = 1,
    MAXIMUM = 2,
}

export type VlrIdOptions = {
    id: ZERO_RATE_PRIORITY;
    label: TranslateResult;
};

export const ZERO_RATE_PRIORITY_OPTION: Readonly<Record<ZERO_RATE_PRIORITY, VlrIdOptions>> = {
    [ZERO_RATE_PRIORITY.MINIMUM]: {
        id: ZERO_RATE_PRIORITY.MINIMUM,
        label: i18n.t('charging.tariffSpec.minPriority'),
    },
    [ZERO_RATE_PRIORITY.MAXIMUM]: {
        id: ZERO_RATE_PRIORITY.MAXIMUM,
        label: i18n.t('charging.tariffSpec.maxPriority'),
    },
};
