import Vue from 'vue';
import customerCareHTTP from '@/__new__/services/dno/user/http/customer-care';
import userManagementHTTP from '@/__new__/services/dno/user/http/user-management';
import paymentHTTP from '@/http/payment';
import {
    USER_MANAGER_HIERARCHY,
    getUMIdStateFromText,
} from '@/__new__/features/customerCare/common/customerCareHelper';
import { TransactionV4 } from '@/__new__/services/dno/orders/models/TransactionV4';
import ordersHTTP from '@/__new__/services/dno/orders/http/orders';

export default {
    namespaced: true,
    // Objects in state are list
    // key is account id
    // value is info
    state: {
        // All keys are in Account.js model
        accountInfo: {},
        // Array<Object>
        // All keys for object are defined in Subscriber.js model
        subscriberInfos: {},
        // Array<Object>
        // All keys for object
        accountTransactionHistories: {},
        accountTransactionHistoriesApiResponse: {},
        // Array<Object>
        transactionHistoriesData: {},
        transactionHistoriesApiResponse: {},
        // Array<Object>
        // String role_name
        // String user_id
        // String email
        stateHistory: {},
        // Array<Object>
        paymentMethods: {},
        additionalSidebarState: {},
        isSidebarOpen: false,
    },
    mutations: {
        setAccountInfo: (state, { accountInfo, accountId }) => {
            Vue.set(state.accountInfo, accountId, accountInfo);
        },
        setSubscriberInfos: (state, { subscriberInfos, accountId }) => {
            Vue.set(state.subscriberInfos, accountId, subscriberInfos);
        },
        setAccountTransactionHistory: (state, { accountTransactionHistory, accountId }) => {
            Vue.set(state.accountTransactionHistories, accountId, accountTransactionHistory);
        },
        setAccountTransactionHistoryApiResponse: (state, { accountTransactionHistoryApiResponse, accountId }) => {
            Vue.set(state.accountTransactionHistoriesApiResponse, accountId, accountTransactionHistoryApiResponse);
        },
        setTransactionHistoryTableData: (state, { transactionHistory, accountId }) => {
            Vue.set(state.transactionHistoriesData, accountId, transactionHistory);
        },
        setTransactionHistoryApiResponse: (state, { transactionHistoryApiResponse, accountId }) => {
            Vue.set(state.transactionHistoriesApiResponse, accountId, transactionHistoryApiResponse);
        },
        setStateHistory: (state, { stateHistory, accountId }) => {
            Vue.set(state.stateHistory, accountId, stateHistory);
        },
        setPaymentMethods: (state, { paymentMethods, accountId }) => {
            Vue.set(state.paymentMethods, accountId, paymentMethods);
        },
        toggleAdditionalSidebarState: (state, { sectionId, value }) => {
            Vue.set(state.additionalSidebarState, sectionId, value);
            Object.keys(state.additionalSidebarState).forEach(id => {
                if (id !== sectionId) {
                    state.additionalSidebarState[id] = false;
                }
            });
            state.isSidebarOpen = Object.values(state.additionalSidebarState)?.some(flag => !!flag);
        },
        resetAdditionalSidebarState(state) {
            state.additionalSidebarState = {};
        },
    },
    actions: {
        async getAccountInfo({ commit }, accountId) {
            const accountInfoResponse = await customerCareHTTP.getAccount(accountId, USER_MANAGER_HIERARCHY.ACCOUNT);
            commit('setAccountInfo', {
                accountInfo: accountInfoResponse?.data?.account_info,
                accountId,
            });
            if (accountInfoResponse?.data?.account_info?.subscribers?.length) {
                const subscriberDetailsPromise = accountInfoResponse.data.account_info.subscribers.map(subID =>
                    userManagementHTTP.getSubscriber(subID, USER_MANAGER_HIERARCHY.SUBSCRIBER),
                );
                const subscriberDetailsResponse = await Promise.all(subscriberDetailsPromise);

                const subscriberDetails = subscriberDetailsResponse.map(res => res.data.subscriber_info);
                commit('setSubscriberInfos', {
                    subscriberInfos: subscriberDetails,
                    accountId,
                });
            } else {
                commit('setSubscriberInfos', {
                    subscriberInfos: [],
                    accountId,
                });
            }
        },
        async getAccountTransactionHistory({ commit }, accountId) {
            try {
                const transactionHistoryResponse = await paymentHTTP.getTransactionHistory({
                    target_id: accountId,
                    target_type: USER_MANAGER_HIERARCHY.ACCOUNT,
                    include_tax: true,
                });
                commit('setAccountTransactionHistoryApiResponse', {
                    accountTransactionHistoryApiResponse: transactionHistoryResponse,
                    accountId,
                });
                if (Array.isArray(transactionHistoryResponse.data.transactions)) {
                    commit('setAccountTransactionHistory', {
                        accountTransactionHistory: transactionHistoryResponse.data.transactions,
                        accountId,
                    });
                }
            } catch (error) {
                commit('setAccountTransactionHistoryApiResponse', {
                    accountTransactionHistoryApiResponse: error.response,
                    accountId,
                });
            }
        },
        async getTransactionHistoryTableData({ commit }, { accountId, sortType }) {
            try {
                const transactionHistoryResponse = await ordersHTTP.getTransactions(
                    accountId,
                    USER_MANAGER_HIERARCHY.ACCOUNT,
                    sortType,
                );
                commit('setTransactionHistoryApiResponse', {
                    transactionHistoryApiResponse: transactionHistoryResponse,
                    accountId,
                });
                const transactionResponseV4 = transactionHistoryResponse?.data?.transactions.map(
                    transactionJSON => new TransactionV4(transactionJSON),
                );

                if (Array.isArray(transactionHistoryResponse.data.transactions)) {
                    commit('setTransactionHistoryTableData', {
                        transactionHistory: transactionResponseV4,
                        accountId,
                    });
                }
            } catch (error) {
                commit('setTransactionHistoryApiResponse', {
                    transactionHistoryApiResponse: error.response,
                    accountId,
                });
            }
        },
        async getStateHistory({ commit }, accountId) {
            const response = await userManagementHTTP.getStateHistory(accountId, USER_MANAGER_HIERARCHY.ACCOUNT);
            commit('setStateHistory', {
                stateHistory: Object.values(response?.data?.state_history || {}).map(state => ({
                    ...state,
                    state: getUMIdStateFromText(state.state),
                })),
                accountId,
            });
        },
        async getPaymentMethods({ commit }, accountId) {
            const response = await paymentHTTP.fetchAllPaymentMethods(accountId, USER_MANAGER_HIERARCHY.ACCOUNT);
            const allPaymentMethods = response.data?.payment_method_objects;

            if (allPaymentMethods) {
                commit('setPaymentMethods', {
                    // Depending on payment service key is credit_cards/credit-cards
                    paymentMethods: allPaymentMethods.credit_cards || allPaymentMethods['credit-cards'],
                    accountId,
                });
            }
        },
        toggleAdditionalSidebarState({ commit }, { sectionId, value }) {
            commit('toggleAdditionalSidebarState', { sectionId, value });
        },
        resetAdditionalSidebarState({ commit }) {
            commit('resetAdditionalSidebarState');
        },
    },
    getters: {
        getAccountInfoById: state => accountId => state.accountInfo[accountId] || {},
        getSubscriberInfosById: state => accountId => state.subscriberInfos[accountId] || [],
        getAccountTransactionsById: state => accountId => state.accountTransactionHistories[accountId] || [],
        getAccountTransactionsApiResponseById: state => accountId =>
            state.accountTransactionHistoriesApiResponse[accountId],
        getTransactionsHistoriesById: state => accountId => state.transactionHistoriesData[accountId] || [],
        getTransactionsHistoriesApiResponseById: state => accountId => state.transactionHistoriesApiResponse[accountId],
        getStateHistoryById: state => accountId => state.stateHistory[accountId] || [],
        getPaymentMethodsById: state => accountId => state.paymentMethods[accountId] || [],
        getStateHistoryByIdAscending: state => accountId =>
            state.stateHistory[accountId]?.map(a => ({ ...a }))?.sort((a, b) => a.change_time - b.change_time) || [],
        isSidebarOpen: state => state.isSidebarOpen,
        getAdditionalSidebarStateBySectionKey: state => sectionKey => state.additionalSidebarState[sectionKey] || false,
    },
};
