import { PC_CONDITION_TYPES } from '@/__new__/services/dno/charging/common/chargingSpecificationHelper';
import { MAP_DATA_BALANCE_OPERATOR_VALUES } from '@/__new__/services/dno/charging/common/dataBalanceCondition';

export default (id, pcObject) => {
    const operator = MAP_DATA_BALANCE_OPERATOR_VALUES.filter(option => option.id === pcObject.operator);
    return {
        data: {
            operator: operator?.[0] || null,
            value: pcObject.value,
        },
        uuid: id,
        type: PC_CONDITION_TYPES.DATA_BALANCE,
    };
};
