import Vue from 'vue';
import userManagementHTTP from '@/__new__/services/dno/user/http/user-management';
import {
    USER_MANAGER_HIERARCHY,
    getUMIdStateFromText,
} from '@/__new__/features/customerCare/common/customerCareHelper';

export default {
    namespaced: true,
    // Objects in state are list
    // key is subscriber id
    // value is info
    state: {
        // Array<Object>
        // String state
        // Int change_time
        accessList: [],
        // Array<Object>
        // String role_name
        // String user_id
        // String email
        stateHistory: {},
        // Array<Object>
        // String type
        // String action
        suspendedService: {},
        // All keys are defined in Subscriber.js model
        subscriberInfo: {},
        subscriberInfoApiResponse: {},
    },
    mutations: {
        setAccessList: (state, { accessList, subscriberId }) => {
            Vue.set(state.accessList, subscriberId, accessList);
        },
        setStateHistory: (state, { stateHistory, subscriberId }) => {
            Vue.set(state.stateHistory, subscriberId, stateHistory);
        },
        setSubscriberInfo: (state, { subscriberInfo, subscriberId }) => {
            Vue.set(state.subscriberInfo, subscriberId, subscriberInfo);
        },
        setSubscriberInfoApiResponse: (state, { response, subscriberId }) => {
            Vue.set(state.subscriberInfoApiResponse, subscriberId, response);
        },
        SET_SUSPENDED_SERVICE: (state, { suspendedService, subscriberId }) => {
            Vue.set(state.suspendedService, subscriberId, suspendedService);
        },
    },
    actions: {
        async getAccessList({ commit }, subscriberId) {
            const response = await userManagementHTTP.getAccessList(subscriberId, USER_MANAGER_HIERARCHY.SUBSCRIBER);

            commit('setAccessList', {
                accessList: response.data.access_list,
                subscriberId,
            });
        },
        getStateHistory: async ({ commit }, subscriberId) => {
            const response = await userManagementHTTP.getStateHistory(subscriberId, USER_MANAGER_HIERARCHY.SUBSCRIBER);

            commit('setStateHistory', {
                stateHistory: response.data.state_history.map(state => ({
                    ...state,
                    state: getUMIdStateFromText(state.state),
                })),
                subscriberId,
            });
        },
        async getSubscriberInfo({ commit }, subscriberId) {
            let subscriberInfoResponse;
            try {
                subscriberInfoResponse = await userManagementHTTP.getSubscriber(
                    subscriberId,
                    USER_MANAGER_HIERARCHY.SUBSCRIBER,
                );

                commit('setSubscriberInfo', {
                    subscriberInfo: subscriberInfoResponse.data.subscriber_info,
                    subscriberId,
                });
                commit('setSubscriberInfoApiResponse', {
                    response: subscriberInfoResponse,
                    subscriberId,
                });
            } catch (error) {
                commit('setSubscriberInfoApiResponse', {
                    response: error.response,
                    subscriberId,
                });
                throw error;
            }
        },
        setSuspendedService({ commit }, { subscriberId, suspendedService }) {
            commit('SET_SUSPENDED_SERVICE', {
                suspendedService,
                subscriberId,
            });
        },
    },
    getters: {
        getSubscriberInfoById: state => subscriberId => state.subscriberInfo[subscriberId] || {},
        getSubscriberInfoApiResponseById: state => subscriberId => state.subscriberInfoApiResponse[subscriberId],
        getStateHistoryById: state => subscriberId => state.stateHistory[subscriberId] || [],
        getStateHistoryByIdAscending: state => subscriberId =>
            state.stateHistory[subscriberId]?.map(a => ({ ...a }))?.sort((a, b) => a.change_time - b.change_time) || [],
        getAccessListById: state => subscriberId => state.accessList[subscriberId] || [],
        getSuspendedServiceById: state => subscriberId => state.suspendedService[subscriberId] || {},
    },
};
