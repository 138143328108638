import Vue from 'vue';
import { debounce, keyBy } from 'lodash';
import Segment from '@/__new__/services/dno/segments/models/Segment';
import {
    getFilterDefinitions,
    getCohortExpressionCount,
    getRegisteredIdsCount,
    getSegments,
    deleteSegment,
    updateOperationalStatus,
    getSegment,
    getSegmentData,
} from '@/__new__/services/dno/segments/http/segments';
import Actions, { Getters, Mutations, State } from '@/store/mutation-types';
import SegmentStatistics from '@/__new__/services/dno/segments/models/SegmentStatistics';
import { HOUR } from '@/common/cepHelper';
import * as Sentry from '@sentry/vue';
import { LIST_ALLOW_TYPES_FOR_GET_REGISTERED_IDS_COUNT } from '@/common/segments';
import { SEGMENT_ID_TYPES_REVERSE } from '@/common/StaticFilter';
import { groupSegments } from '@/common/cohortExpressionHelper';

const state = {
    [State.CACHED_SEGMENTS]: [],
    [State.RAW_SEGMENTS]: [],
    [State.SEGMENTS_STATISTICS]: {},
    [State.OPERATOR_STATS]: SegmentStatistics.empty(),
    [State.TOTAL_USERS]: null,
    [State.FILTER_DEFINITIONS_BY_ID]: {},
};

// To have opportunity to call debounced functions with unique timers
// that fetches statistics of each segment, stored by segmentId
const SEGMENT_COHORT_COUNT_DEBOUNCED_FUNCTIONS = {};
const SEGMENT_REGISTERED_COUNT_DEBOUNCED_FUNCTIONS = {};

const mutations = {
    // segments
    [Mutations.SET_CACHED_SEGMENTS]: (_state, segments) => {
        _state[State.CACHED_SEGMENTS] = segments;
    },
    [Mutations.SET_RAW_SEGMENTS]: (_state, segmentsArray) => {
        _state[State.RAW_SEGMENTS] = segmentsArray;
    },
    [Mutations.SET_OPERATOR_STATS]: (_state, operatorStats) => {
        _state[State.OPERATOR_STATS] = operatorStats;
    },
    [Mutations.SET_TOTAL_USERS]: (_state, totalUsers) => {
        _state[State.TOTAL_USERS] = totalUsers;
    },
    [Mutations.SET_SEGMENT_STATISTICS]: (_state, { segmentId, statistics }) => {
        Vue.set(_state[State.SEGMENTS_STATISTICS], segmentId, statistics);
    },
    [Mutations.SET_FILTER_DEFINITIONS_BY_ID]: (_state, definitionsById) => {
        _state[State.FILTER_DEFINITIONS_BY_ID] = definitionsById;
    },
};

const actions = {
    // http // returns debounced promise that resolves with filter definitions
    [Actions.LOAD_FILTER_DEFINITIONS]: ({ commit }) =>
        new Promise((resolve, reject) => {
            getFilterDefinitions()
                .then(response => {
                    const newFilterDefinitionsById = keyBy(response.data, def => def.id);
                    commit(Mutations.SET_FILTER_DEFINITIONS_BY_ID, newFilterDefinitionsById);
                    resolve(newFilterDefinitionsById);
                })
                .catch(error => reject(error));
        }),
    [Actions.FETCH_SEGMENT_STATISTICS]: (context, { segmentId, segmentIdType, flush = false }) =>
        new Promise((resolve, reject) => {
            const segmentType = SEGMENT_ID_TYPES_REVERSE[segmentIdType];

            let cohortCountDebounceFunction = SEGMENT_COHORT_COUNT_DEBOUNCED_FUNCTIONS[segmentId];

            // creating and storing debounced function with for specific segmentId
            if (!cohortCountDebounceFunction) {
                cohortCountDebounceFunction = debounce(getCohortExpressionCount, HOUR, {
                    leading: true,
                    trailing: true,
                    maxWait: HOUR,
                });

                SEGMENT_COHORT_COUNT_DEBOUNCED_FUNCTIONS[segmentId] = cohortCountDebounceFunction;
            }

            let registeredCountDebounceFunction = SEGMENT_REGISTERED_COUNT_DEBOUNCED_FUNCTIONS[segmentType];

            if (!registeredCountDebounceFunction) {
                registeredCountDebounceFunction = debounce(getRegisteredIdsCount, HOUR, {
                    leading: true,
                    trailing: true,
                    maxWait: HOUR,
                });

                SEGMENT_REGISTERED_COUNT_DEBOUNCED_FUNCTIONS[segmentType] = registeredCountDebounceFunction;
            }

            const cohortExpression = {
                whitelist: {
                    logical_operator: 'AND',
                    [segmentType]: [segmentId],
                },
                blacklist: {
                    logical_operator: 'AND',
                },
            };

            const promises = [];
            promises.push(
                flush
                    ? // first call is needed to init debounced func if it's not yet
                      cohortCountDebounceFunction(cohortExpression) && cohortCountDebounceFunction.flush()
                    : cohortCountDebounceFunction(cohortExpression),
            );

            if (LIST_ALLOW_TYPES_FOR_GET_REGISTERED_IDS_COUNT.includes(segmentIdType)) {
                promises.push(registeredCountDebounceFunction(segmentIdType));
            }

            Promise.all(promises)
                .then(responses => {
                    const statistics = SegmentStatistics.fromCohortCountJson(responses[0].data).setTotal(
                        responses[1]?.data.count,
                    );
                    context.commit(Mutations.SET_SEGMENT_STATISTICS, { segmentId, statistics });
                    resolve(statistics);
                })
                .catch(e => {
                    if (
                        Object.hasOwnProperty.call(e, 'response') &&
                        e?.response?.status === 400 &&
                        e?.response?.data?.status === 1005
                    ) {
                        // This error can happen in certain cases. e.g. duplicate filters.
                        // Nothing we can do from portal side. Ignoring the error.
                    } else {
                        Sentry.captureException(e);
                        reject(e);
                    }
                });
        }),
    [Actions.FETCH_SEGMENTS]: ({ commit }) =>
        new Promise((resolve, reject) => {
            getSegments()
                .then(response => {
                    const segments = response.data.entities
                        ?.map(Segment.fromJson)
                        ?.filter(s => !s.name.startsWith('internal_lf_e2e_'));
                    commit(Mutations.SET_CACHED_SEGMENTS, segments);
                    commit(Mutations.SET_RAW_SEGMENTS, response);
                    resolve(segments);
                })
                .catch(err => {
                    commit(Mutations.SET_RAW_SEGMENTS, err.response);
                    reject(err);
                });
        }),
    [Actions.FETCH_SEGMENT]: ({ commit }, id) => {
        return getSegment(id).then(response => {
            return Segment.fromJson(response.data);
        });
    },
    async [Actions.DELETE_SEGMENT]({ dispatch, commit }, { id, version, segmentType }) {
        await deleteSegment({ id, version, segmentType });
        dispatch(Actions.FETCH_SEGMENTS);
    },
    async [Actions.UPDATE_SEGMENT_OPERATIONAL_STATUS]({ dispatch, commit }, { id, operation }) {
        await updateOperationalStatus({ id, operation });
        dispatch(Actions.FETCH_SEGMENTS);
    },
    async [Actions.DOWNLOAD_SEGMENT_DATA]({ commit }, { id }) {
        return await getSegmentData(id);
    },
};

const getters = {
    [Getters.CACHED_SEGMENTS]: _state => _state[State.CACHED_SEGMENTS] || [],
    [Getters.CACHED_SEGMENTS_BY_ID]: _state => keyBy(_state[State.CACHED_SEGMENTS], ({ id }) => id) || {},
    [Getters.GROUPED_SEGMENTS_BY_ID_TYPE]: _state => groupSegments(_state[State.CACHED_SEGMENTS]),
    [Getters.GET_STATS_OF_SEGMENT]: _state => segmentId =>
        _state[State.SEGMENTS_STATISTICS][segmentId] || SegmentStatistics.empty(),
};

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions,
};
