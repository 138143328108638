
import Vue from 'vue';

// Store
import { mapGetters, mapActions } from 'vuex';
import Actions, { Getters } from '@/store/mutation-types';
import { Modules } from '@/store/store';

// Components
import AbstractListPageWrapper from '@/components/layout/AbstractListPageWrapper.vue';
import FilterTable from '@/components/partials/FilterTable.vue';
import TableFiltersTags from '@/components/filters/TableFiltersTags.vue';
import AppTable from '@/components/partials/AppTable.vue';
import EntityStatusIndicator from '@/components/partials/EntityStatusIndicator.vue';
import EntityOverview from '@/components/partials/entityOverview/EntityOverview.vue';
import OverviewHeader from '@/components/partials/entityOverview/OverviewHeader.vue';
import OverviewList from '@/components/partials/entityOverview/OverviewList.vue';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import { ICON_TYPES } from '@/common/iconHelper';
import ResponseModalButton from '@/components/partials/ResponseModalButton.vue';
import IconButton from '@/components/partials/IconButton.vue';

// Mixins
import CountControlMixin from '@/components/partials/CountControlMixin.vue';
import FilterTableMixin from '@/components/partials/FilterTableMixin.vue';
import DownloadDetailsMixin from '@/__new__/features/charging/DownloadDetailsMixin.vue';
import ChargingCommonActionsMixin from '@/__new__/features/charging/ChargingCommonActionsMixin.vue';

// Http
import { deleteContentTypeDraft, updateContentTypeState } from '@/__new__/services/dno/charging/http/contentType';

// Helpers
import ENTITY_TYPES from '@/common/entities/entityTypes';
import tableColumnType, { type TableColumn } from '@/common/filterTable';
import {
    getMultiLangFieldValueByLocale,
    getEntityConsumers,
    getAffectedEntities,
} from '@/common/entities/entityHelper';
import { languageMap } from '@/common/locale/language';
import RouteNames from '@/router/routeNames';
import { displayNaIfNotProvided } from '@/common/formatting';
import { ContentType, DetailsSections } from '@/__new__/services/dno/charging/models/ChargingInterfaces';
import permissionsService, { isUserAllowed } from '@/services/permissions/permissions.service';
import * as Sentry from '@sentry/vue';

export default Vue.extend({
    name: 'ContentType',
    components: {
        AbstractListPageWrapper,
        FilterTable,
        TableFiltersTags,
        AppTable,
        EntityStatusIndicator,
        EntityOverview,
        OverviewHeader,
        OverviewList,
        AppButton,
        ResponseModalButton,
        IconButton,
    },
    mixins: [CountControlMixin, FilterTableMixin, DownloadDetailsMixin, ChargingCommonActionsMixin],
    data() {
        return {
            ICON_TYPES,
            BUTTON_TYPES,
            ENTITY_TYPES,
            entityType: ENTITY_TYPES.CONTENT_TYPES as string,
            editorRoute: RouteNames.CHARGING_CONTENT_TYPE_EDITOR as string,
            isOverviewEnabled: false as boolean,
            searchQueryForTable: '' as string,
            selectedEntityId: null as string | null,
            selectedEntity: null as ContentType | null,
            isDataLoading: false as boolean,
            selectedLanguage: '' as string,
        };
    },
    computed: {
        ...mapGetters(Modules.chargingV2, [
            Getters.GET_APPROVED_CONTENT_TYPES,
            Getters.GET_CONTENT_TYPE_BY_ID,
            Getters.GET_CONTENT_TYPES_API_RESPONSE,
        ]),
        ...mapGetters('operators', [Getters.languageDefault, Getters.languagesWithoutDefault]),
        isEditEnabled(): boolean {
            return permissionsService.chargingContentTypesEnabled() && isUserAllowed('ContentTypesWrite');
        },
        payoutsApiResponse(): any {
            return this[Getters.GET_CONTENT_TYPES_API_RESPONSE];
        },
        contentTypes(): ContentType[] {
            return [...this[Getters.GET_APPROVED_CONTENT_TYPES]].sort((ct1: ContentType, ct2: ContentType) =>
                (ct1.name as any).localeCompare(ct2.name),
            );
        },
        tableColumnsData(): TableColumn[] {
            return [
                {
                    name: this.$i18n.t('generic.name'),
                    key: 'name',
                    field: 'name',
                    forbidHideColumn: true,
                    classes: ['font-weight-bold'],
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('charging.contentTypes.appName'),
                    key: 'appName',
                    field: 'appName',
                    forbidHideColumn: true,
                    classes: ['font-weight-bold'],
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('charging.contentTypes.isTransferable'),
                    key: 'isTransferableLabel',
                    field: 'isTransferableLabel',
                    forbidHideColumn: true,
                    classes: ['font-weight-bold'],
                    filterType: tableColumnType.TEXT_LIMITED_OPTIONS,
                    limitedOptions: [
                        ...new Set(this.sortedFilteredEntities.map((e: ContentType) => e.isTransferableLabel)),
                    ],
                },
                {
                    name: this.$i18n.t('generic.lastUpdatedTime'),
                    key: 'updateTime',
                    sortBy: (entity: ContentType) => entity.updateTime,
                    mapper: (entity: ContentType) =>
                        entity?.isDraft ? this.$localeLibrary.getFormattedDate(entity.update_time) : entity.updateTime,
                    field: 'update_time',
                    filterType: tableColumnType.DATE,
                },
                {
                    name: this.$i18n.t('generic.version'),
                    key: 'chargingVersion',
                    field: 'chargingVersion',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('generic.state'),
                    key: 'entityStatusCode',
                    field: 'entityStatusCodeLabel',
                    filterType: tableColumnType.TEXT_LIMITED_OPTIONS,
                    limitedOptions: [
                        ...new Set(this.sortedFilteredEntities.map((e: ContentType) => e.entityStatusCodeLabel)),
                    ],
                },
            ];
        },
        sortedFilteredEntities(): ContentType[] {
            return this.filteredEntitiesMixin(this.contentTypes);
        },
        detailsSections(): DetailsSections[] {
            if (!this.selectedEntityId) {
                return [];
            }

            return [
                {
                    name: this.$i18n.t('generic.general'),
                    properties: [
                        {
                            value: this.selectedEntity?.id,
                            label: this.$i18n.t('generic.id'),
                        },
                        {
                            value: displayNaIfNotProvided(this.selectedEntity?.name),
                            label: this.$i18n.t('generic.name'),
                        },
                        {
                            value: displayNaIfNotProvided(this.selectedEntity?.description),
                            label: this.$i18n.t('generic.description'),
                        },
                        {
                            value: this.selectedEntity?.appName,
                            label: this.$i18n.t('charging.contentTypes.appName'),
                        },
                        {
                            value: this.selectedEntity?.isTransferableLabel,
                            label: this.$i18n.t('charging.contentTypes.isTransferable'),
                        },
                        {
                            value: this.mapEnityUseByCs,
                            label: `${this.$i18n.t('generic.usedBy')} ${this.$i18n.t(
                                'charging.entities.plural.chargingSpecifications',
                            )}: `,
                        },
                    ],
                },
            ];
        },
        mapEnityUseByCs() {
            const affectedEntities = getAffectedEntities(this.selectedEntity?.id, ENTITY_TYPES.CONTENT_TYPES);
            const cs =
                affectedEntities?.[ENTITY_TYPES.CONTENT_TYPES].map((el: any) => {
                    return getMultiLangFieldValueByLocale(el.data.name);
                }) || [];
            return cs.join(', ');
        },
    },
    created() {
        this.initData();
    },
    methods: {
        ...mapActions(Modules.chargingV2, [Actions.REQUEST_CONTENT_TYPES]),
        ...mapActions(Modules.charging, [Actions.REQUEST_CHARGING_SPECIFICATIONS]),
        getMultiLangFieldValueByLocale,
        initData() {
            this.$withLoadingSpinner(
                async () => {
                    this.$Progress.start();
                    this.isDataLoading = true;

                    this.selectedLanguage = (this[Getters.languageDefault] || languageMap.en.key).toString();
                    await Promise.all([
                        this[Actions.REQUEST_CONTENT_TYPES](),
                        this[Actions.REQUEST_CHARGING_SPECIFICATIONS](),
                    ]);

                    this.$Progress.finish();
                    this.isDataLoading = false;
                },
                {
                    errorHandler: e => {
                        this.isDataLoading = false;
                        this.$Progress.fail();
                        Sentry.captureException(e);
                        this.$eventBus.$emit('showAlert', {
                            message: this.$i18n.t('alertMessage.failedToLoadNecessaryData'),
                        });
                    },
                },
            );
        },
        setSearchQuery(query: string): void {
            this.searchQueryForTable = query;
        },
        onEntitySelected(entityId: string): void {
            this.selectedEntityId = entityId;
            this.selectedEntity = this[Getters.GET_CONTENT_TYPE_BY_ID](entityId);
            this.isOverviewEnabled = true;
        },
        upperEntities(): object {
            return getEntityConsumers(this.selectedEntityId, this.entityType);
        },
        goToContentTypeEditor(): void {
            this.$router.push({
                name: RouteNames.CHARGING_CONTENT_TYPE_EDITOR,
                params: { companyId: this.$route.params.companyId },
            });
        },
        onEdit(entityId: string): void {
            this.onEditAction(entityId, this.editorRoute);
        },
        onClone(entityId: string): void {
            this.onCloneAction(entityId, this.editorRoute);
        },
        onDelete(entityId: string): void {
            const { state, version } = this[Getters.GET_CONTENT_TYPE_BY_ID](entityId);

            this.showDeleteAlert(entityId, false, state, version, [
                updateContentTypeState,
                deleteContentTypeDraft,
                this.initData,
            ]);
        },
        onReadOnly(entityId: string): void {
            this.onReadOnlyAction(entityId, this.editorRoute);
        },
    },
});
