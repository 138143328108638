var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"stepper"},[_c('ul',{staticClass:"stepper__list d-flex overflow-hidden"},_vm._l((_vm.steps),function(step,index){return _c('li',{key:step.title,staticClass:"stepper__item d-flex justify-content-center font-weight-bold position-relative",class:{
                'is-active': _vm.getActiveIndex(step, index) === _vm.activeStepIndex,
            },on:{"click":function($event){return _vm.onStepClick(index)}}},[_c('span',{staticClass:"stepper__item-text d-flex align-items-center",class:{
                    'is-active': _vm.getActiveIndex(step, index) === _vm.activeStepIndex,
                },attrs:{"data-testid":"stepperItem"}},[(_vm.shouldDisplayStepIcons)?_c('span',{staticClass:"stepper__item-icon mr-2",class:{
                        'stepper__item-icon_edit': _vm.getActiveIndex(step, index) === _vm.activeStepIndex,
                        'stepper__item-icon_complete': step.isCompleted,
                    }}):_vm._e(),(_vm.shouldDisplayStepIndex)?_c('span',{staticClass:"stepper__item-index"},[_vm._v(_vm._s(index + 1)+".")]):_vm._e(),_vm._v(" "+_vm._s(step.title)+" ")])])}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }