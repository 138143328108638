<template>
    <div class="duplicate-slot-wrapper">
        <div
            v-if="label"
            class="duplicate-slot-header col-11"
        >
            <div class="duplicate-slot-label">
                {{ label }}
            </div>
            <div
                v-if="optional"
                v-t="'generic.optional'"
                class="duplicate-slot-optional"
            />
        </div>
        <div
            v-if="explanation"
            class="explanation mb-2"
        >
            {{ explanation }}
        </div>
        <div v-if="numberOfSlots">
            <template v-for="slotNumber in numberOfSlots">
                <div
                    :key="slotNumber"
                    class="slot-fields position-relative row"
                    :class="{ 'mx-0': hidePadding }"
                >
                    <slot :slotNumber="slotNumber - 1" />

                    <img
                        v-show="showDeleteButton"
                        src="../../assets/icons/delete-trash.svg"
                        class="delete-btn col-1"
                        :class="{ 'one-input-delete': deleteIconWithoutOffset }"
                        @click="deleteSlot(slotNumber - 1)"
                    />
                </div>
            </template>

            <AppButton
                :label="addButtonLabel"
                :iconType="ICON_TYPES.PLUS"
                :disabled="!numberOfProperties || disabled"
                :isSmall="true"
                @click="addSlot"
            />
        </div>
    </div>
</template>

<script>
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import { ICON_TYPES } from '@/common/iconHelper';
import { clone, remove } from 'lodash';

export default {
    name: 'DuplicateSlot',

    components: {
        AppButton,
    },
    props: {
        value: {
            type: Array,
            default: () => [],
        },
        addButtonLabel: {
            type: String,
            default: '',
        },
        enableDeleteButton: {
            type: Boolean,
            default: false,
        },
        numberOfProperties: {
            type: Number,
            default: 1,
        },
        optional: {
            type: Boolean,
            default: false,
        },
        label: {
            type: String,
            default: '',
        },
        explanation: {
            type: String,
            default: '',
        },
        deleteIconWithoutOffset: {
            type: Boolean,
            default: false,
        },
        // prop specific for addresses in account editor
        accountEditor: {
            type: Boolean,
            default: false,
        },
        hidePadding: {
            type: Boolean,
            default: false,
        },
        addStringOnButtonClick: {
            type: Boolean,
            default: false,
        },
        customDataStructureOnAdd: {
            type: Object,
            default: null,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            ICON_TYPES,
            BUTTON_TYPES,
        };
    },

    computed: {
        numberOfSlots() {
            return this.value.length;
        },
        showDeleteButton() {
            return this.value.length > 1 && this.enableDeleteButton && !this.disabled;
        },
    },

    methods: {
        addSlot() {
            const newValue = clone(this.value);
            let newItem = {};

            if (this.customDataStructureOnAdd) {
                newItem = this.customDataStructureOnAdd;
            } else if (this.addStringOnButtonClick) {
                newItem = '';
            } else {
                Object.keys(newValue[0]).forEach(key => {
                    newItem[key] = '';
                });
            }

            newValue[newValue.length] = newItem;

            // toDo: refactor this!
            if (this.accountEditor) {
                // third parameter 1 if slot is added
                // 0  if slot is deleted
                const isAdded = 1;
                this.$emit('input', { newValue, isAdded });
            } else {
                this.$emit('input', newValue);
            }
        },
        deleteSlot(slotNumber) {
            const newValue = clone(this.value);

            remove(newValue, (val, valIndex) => valIndex === slotNumber);
            this.$emit('deletedSlot', slotNumber);

            // toDo: refactor this!
            if (this.accountEditor) {
                // third parameter 1 if slot is added
                // 0  if slot is deleted
                const isAdded = 0;
                this.$emit('input', { newValue, isAdded });
            } else {
                this.$emit('input', newValue);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/_palette.scss';
@import '~@/assets/scss/_layout.scss';

.duplicate-slot-wrapper {
    .duplicate-slot-header {
        display: flex;
        justify-content: space-between;
        padding: 0 0 $spacing-xxs;

        .duplicate-slot-label {
            font-size: 0.75rem;
            color: $gray90;
            font-weight: 600;
            line-height: 1.625rem;
        }

        .duplicate-slot-optional {
            font-size: 0.75rem;
            font-style: italic;
            color: $gray60;
        }
    }

    .delete-btn {
        cursor: pointer;
        top: 1rem;
        height: 1rem;
        transform: translateY(calc(50% + 0.5rem));
        opacity: 0;
    }

    .one-input-delete {
        top: 0rem !important;
    }

    .slot-fields {
        &:hover {
            .delete-btn {
                opacity: 1;
            }
        }
    }
}

.explanation {
    line-height: 20px;
    color: $gray60;
    font-size: 12px;
    font-weight: 500;
}
</style>
