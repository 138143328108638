<template>
    <RouteWrapper className="container-fluid h-100" />
</template>
<script>
import RouteWrapper from '@/components/layout/RouteWrapper.vue';

export default {
    name: 'Messages',
    components: {
        RouteWrapper,
    },
};
</script>

<style scoped></style>
