<template>
    <AppAditionalSidebar
        :visible="visibleSidebar"
        class="transaction-sidebar"
        @input="val => (isSidebarVisible = val)"
    >
        <template #header>
            <div class="d-flex align-items-center h-100">
                <p class="sidebar-header lf-table-title mb-2 text-uppercase">
                    {{ $i18n.t('customerCare.transactionHistory.transactionHistoryDetails') }}
                </p>
            </div>
        </template>
        <template #content>
            <div class="entity-view">
                <div class="row no-gutters">
                    <div
                        v-for="section in sidebarTabOptions"
                        :key="section.id"
                        :class="[
                            {
                                active: sidebarTabId === section.id,
                            },
                        ]"
                        class="col section-tab d-flex align-items-center text-uppercase"
                        @click="selectTab(section.id)"
                    >
                        {{ section.name }}
                    </div>
                </div>
            </div>
            <div
                v-if="isSidebarTabTransaction"
                class="mt-4"
            >
                <div class="modal-row d-flex align-items-center">
                    <div class="row-title">
                        {{ $i18n.t('customerCare.transactionHistory.transactionId') }}
                    </div>
                    <div class="row-value">
                        {{ displayNaIfNotProvided(selectedTransactionHistory.id) }}
                    </div>
                </div>

                <div class="modal-row d-flex align-items-center">
                    <div class="row-title">
                        {{ $i18n.t('customerCare.transactionHistory.fileNumber') }}
                    </div>
                    <div class="row-value">
                        {{ displayNaIfNotProvided(selectedTransactionHistory.transactionName) }}
                    </div>
                </div>

                <div class="modal-row d-flex align-items-center">
                    <div class="row-title">
                        {{ $i18n.t('generic.status') }}
                    </div>
                    <EntityStatusIndicator
                        :stateMap="CHARGE_STATES_TO_STATUS_NAME_MAP"
                        :stateMapColor="CHARGE_STATES_INDICATOR_MAP"
                        :status="selectedTransactionHistory.state"
                        class="row-value"
                    />
                </div>

                <div
                    v-if="selectedTransactionHistory.partiallyRefunded"
                    class="modal-row d-flex align-items-center"
                >
                    <div class="row-title">
                        {{ $i18n.t('customerCare.transactionHistory.remainingBalance') }}
                    </div>
                    <div class="row-value">
                        {{ displayNaIfNotProvided(selectedTransactionHistory.remainingBalance) }}
                    </div>
                </div>
            </div>
            <div
                v-if="isSidebarTabPayment"
                class="mt-4"
            >
                <div
                    v-for="(paymentAttempt, index) of selectedTransactionHistory.allPaymentAttempts"
                    :key="`payment-attempt-${index}`"
                >
                    <div class="modal-row d-flex align-items-center all-caps-text">
                        {{ `${$i18n.t('customerCare.transactionHistory.paymentAttempt')} ${index + 1}` }}
                    </div>
                    <div
                        v-if="selectedTransactionHistory.paymentTransactionType === PAYMENT_TRANSACTION_TYPE.REFUND"
                        class="modal-row d-flex align-items-center"
                    >
                        <div class="row-title">
                            {{ $i18n.t('customerCare.transactionHistory.originalTransactionId') }}
                        </div>
                        <div class="row-value">
                            {{ displayNaIfNotProvided(selectedTransactionHistory.originalTransactionId) }}
                        </div>
                    </div>
                    <div class="modal-row d-flex align-items-center">
                        <div class="row-title">
                            {{ $i18n.t('customerCare.transactionHistory.externalPaymentId') }}
                        </div>
                        <div class="row-value">
                            {{ displayNaIfNotProvided(paymentAttempt.externalPaymentId) }}
                        </div>
                    </div>
                    <div class="modal-row d-flex align-items-center">
                        <div class="row-title">
                            {{ $i18n.t('customerCare.transactionHistory.internalPaymentId') }}
                        </div>
                        <div class="row-value">
                            {{ displayNaIfNotProvided(paymentAttempt.internalPaymentId) }}
                        </div>
                    </div>
                    <div class="modal-row d-flex align-items-center">
                        <div class="row-title">
                            {{ $i18n.t('generic.date') }}
                        </div>
                        <div class="row-value">
                            {{
                                $localeLibrary.getFormattedDateAndTimeWithSecondsPrecision(
                                    paymentAttempt.paymentTimestamp,
                                )
                            }}
                        </div>
                    </div>
                    <div class="modal-row d-flex align-items-center">
                        <div class="row-title">
                            {{ $i18n.t('customerCare.transactionHistory.paymentTransactionType') }}
                        </div>
                        <div class="row-value">
                            {{
                                displayNaIfNotProvided(
                                    PAYMENT_TRANSACTION_TYPES_NAME_MAP[paymentAttempt.paymentTransactionType],
                                )
                            }}
                        </div>
                    </div>
                    <div class="modal-row d-flex align-items-center">
                        <div class="row-title">
                            {{ $i18n.t('customerCare.transactionHistory.method') }}
                        </div>
                        <div class="row-value">
                            {{ displayNaIfNotProvided(paymentAttempt.paymentMethod) }}
                        </div>
                    </div>
                    <div class="modal-row d-flex align-items-center">
                        <div class="row-title">
                            {{ $i18n.t('customerCare.transactionHistory.cardType') }}
                        </div>
                        <div class="row-value">
                            {{ displayNaIfNotProvided(paymentAttempt.cardType) }}
                        </div>
                    </div>
                    <div class="modal-row d-flex align-items-center">
                        <div class="row-title">
                            {{ $i18n.t('customerCare.transactionHistory.last4') }}
                        </div>
                        <div class="row-value">
                            {{ displayNaIfNotProvided(paymentAttempt.last4) }}
                        </div>
                    </div>
                    <div class="modal-row d-flex align-items-center">
                        <div class="row-title">
                            {{ $i18n.t('generic.status') }}
                        </div>
                        <div class="row-value">
                            {{ displayNaIfNotProvided(paymentAttempt.status) }}
                        </div>
                    </div>
                    <div
                        v-if="paymentAttempt.failureMessage"
                        class="modal-row d-flex align-items-center"
                    >
                        <div class="row-title">
                            {{ $i18n.t('customerCare.transactionHistory.failedReason') }}
                        </div>
                        <div class="row-value">
                            {{ displayNaIfNotProvided(paymentAttempt.failureMessage) }}
                        </div>
                    </div>
                </div>
            </div>
            <div
                v-if="isSidebarChargeLineItem"
                class="mt-4"
            >
                <div
                    v-for="(charge, index) of selectedTransactionHistory.chargesData"
                    :key="index"
                    class="mb-4"
                >
                    <div class="modal-row d-flex align-items-center all-caps-text">
                        {{ `${$i18n.t('customerCare.transactionHistory.lineItem')} ${index + 1}` }}
                    </div>
                    <div class="modal-row d-flex align-items-center">
                        <div class="row-title">
                            {{ $i18n.t('customerCare.transactionHistory.offerName') }}
                        </div>
                        <div class="row-value">
                            {{ displayNaIfNotProvided(charge.serviceDescription || charge.offerName) }}
                        </div>
                    </div>

                    <div class="modal-row d-flex align-items-center">
                        <div class="row-title">
                            {{ $i18n.t('customerCare.transactionHistory.offerDescription') }}
                        </div>
                        <div class="row-value">
                            {{ displayNaIfNotProvided(charge.offerDescription) }}
                        </div>
                    </div>

                    <div class="modal-row d-flex align-items-center">
                        <div class="row-title">
                            {{ $i18n.t('generic.chargeType') }}
                        </div>
                        <div class="row-value">
                            {{ displayNaIfNotProvided(charge.chargeType) }}
                        </div>
                    </div>

                    <div class="modal-row d-flex align-items-center">
                        <div class="row-title">
                            {{ $i18n.t('generic.chargeAmountWithoutTax') }}
                        </div>
                        <div class="row-value">
                            {{ formatPriceInfo(selectedTransactionHistory, `itemPrices[${index}].serviceAmount`) }}
                        </div>
                    </div>

                    <div class="modal-row d-flex align-items-center">
                        <div class="row-title">
                            {{ $i18n.t('generic.tax') }}
                        </div>
                        <div class="row-value">
                            {{ formatPriceInfo(selectedTransactionHistory, `itemPrices[${index}].totalTaxAmount`) }}
                        </div>
                    </div>

                    <div class="modal-row d-flex align-items-center">
                        <div class="row-title">
                            {{ $i18n.t('generic.chargeAmountWithTax') }}
                        </div>
                        <div class="row-value">
                            {{ formatPriceInfo(selectedTransactionHistory, `itemPrices[${index}].grossAmount`) }}
                        </div>
                    </div>

                    <div class="modal-row d-flex align-items-center">
                        <div class="row-title">
                            {{ $i18n.t('generic.discount') }}
                        </div>
                        <div class="row-value">
                            {{ formatPriceInfo(selectedTransactionHistory, `itemPrices[${index}].alterationsAmount`) }}
                        </div>
                    </div>

                    <div
                        v-if="charge.subscriberId"
                        class="modal-row d-flex align-items-center"
                    >
                        <div class="row-title">
                            {{ $i18n.t('customerCare.subscriberId') }}
                        </div>
                        <div class="row-value">
                            {{ charge.subscriberId }}
                        </div>
                    </div>

                    <template v-if="getPriceItemPromotion(index)">
                        <div
                            v-for="promo of getPriceItemPromotion(index)"
                            :key="promo.id"
                        >
                            <div class="modal-row d-flex align-items-center">
                                <div class="row-title">
                                    {{ $i18n.t('customerCare.promotions.promotionId') }}
                                </div>
                                <div class="row-value">
                                    {{ promo.id }}
                                </div>
                            </div>

                            <div class="modal-row d-flex align-items-center">
                                <div class="row-title">
                                    {{ $i18n.t('customerCare.promotions.promotionName') }}
                                </div>
                                <div class="row-value">
                                    {{ promo.name }}
                                </div>
                            </div>
                        </div>
                    </template>
                </div>
            </div>
        </template>
    </AppAditionalSidebar>
</template>

<script>
// COMPONENTS
import AppAditionalSidebar from '@/components/partials/AppAditionalSidebar.vue';

import EntityStatusIndicator from '@/components/partials/EntityStatusIndicator.vue';

// HELPERS
import {
    CHARGE_STATES_INDICATOR_MAP,
    CHARGE_STATES_TO_STATUS_NAME_MAP,
} from '@/common/userManager/transactionHistoryV4StatusHelper';
import {
    SIDEBAR_TABS_TRANSACTION_HISTORY_V4_ENUM,
    PAYMENT_TRANSACTION_TYPES_NAME_MAP,
} from '@/common/userManager/transactionHistoryV4SidebarHelper';
import { PAYMENT_TRANSACTION_TYPE } from '@/common/userManager/transactionHistoryV4Helper';
import { displayNaIfNotProvided } from '@/common/formatting';
import get from 'lodash/get';

export default {
    name: 'TransactionHistoryV4Sidebar',
    components: {
        AppAditionalSidebar,
        EntityStatusIndicator,
    },
    props: {
        selectedTransactionHistory: {
            required: true,
            type: Object,
            default: () => undefined,
        },
        showSidebar: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            sidebarTabOptions: [
                {
                    id: SIDEBAR_TABS_TRANSACTION_HISTORY_V4_ENUM.TRANSACTION,
                    name: this.$i18n.t('customerCare.transactionHistory.transaction'),
                },
                {
                    id: SIDEBAR_TABS_TRANSACTION_HISTORY_V4_ENUM.PAYMENT,
                    name: this.$i18n.t('customerCare.transactionHistory.payment'),
                },
                {
                    id: SIDEBAR_TABS_TRANSACTION_HISTORY_V4_ENUM.CHARGE_LINE_ITEM,
                    name: this.$i18n.t('customerCare.transactionHistory.lineItem'),
                },
            ],
            sidebarTabId: SIDEBAR_TABS_TRANSACTION_HISTORY_V4_ENUM.TRANSACTION,
            isSidebarVisible: false,

            SIDEBAR_TABS_TRANSACTION_HISTORY_V4_ENUM,
            CHARGE_STATES_INDICATOR_MAP,
            CHARGE_STATES_TO_STATUS_NAME_MAP,
            PAYMENT_TRANSACTION_TYPES_NAME_MAP,
            PAYMENT_TRANSACTION_TYPE,
        };
    },
    computed: {
        visibleSidebar() {
            return this.isSidebarVisible;
        },
        isSidebarTabTransaction() {
            return this.sidebarTabId === SIDEBAR_TABS_TRANSACTION_HISTORY_V4_ENUM.TRANSACTION;
        },
        isSidebarTabPayment() {
            return this.sidebarTabId === SIDEBAR_TABS_TRANSACTION_HISTORY_V4_ENUM.PAYMENT;
        },
        isSidebarChargeLineItem() {
            return this.sidebarTabId === SIDEBAR_TABS_TRANSACTION_HISTORY_V4_ENUM.CHARGE_LINE_ITEM;
        },
    },
    watch: {
        showSidebar: {
            handler() {
                this.isSidebarVisible = this.showSidebar;
            },
        },
        isSidebarVisible: {
            handler() {
                this.$emit('showSidebarChange', this.isSidebarVisible);
            },
        },
    },
    methods: {
        selectTab(id) {
            this.sidebarTabId = id;
        },
        formatPriceInfo(obj, path) {
            const data = get(obj, path, '');

            if (data !== '') {
                return parseFloat(data).toFixed(2);
            }
            return this.$i18n.t('generic.empty');
        },
        getPriceItemPromotion(index) {
            return this.selectedTransactionHistory?.itemPrices?.[index]?.promotions || [];
        },
        displayNaIfNotProvided,
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/palette';

.sidebar-header {
    color: rgba($blue, 0.5);
}

.entity-view {
    .section-tab {
        background-color: $white;
        border: 0.063rem solid $dirty-white;
        border-right: none;
        border-top: 0.188rem solid transparent;

        height: 2.5rem;

        font-size: 0.8125rem;
        font-weight: 600;
        text-transform: uppercase;
        color: $gray30;
        padding-left: 1rem;

        &.active {
            background-color: $white;
            border-top: 0.188rem solid $blue;
            border-bottom: 0.063rem solid transparent;
            color: $blue;
        }

        &:first-child {
            border-left: none;
        }

        &:last-child {
            border-right: none;
        }

        &:hover:not(.active) {
            background-color: $dirty-white;
            color: $blue;
            cursor: pointer;
        }
    }
}

.modal-row {
    margin-bottom: 0.875rem;

    .row-title {
        font-size: 0.75rem;
        font-weight: 600;
        width: 20rem;
    }

    .row-value {
        font-size: 0.875rem;
        width: 100%;
    }
}
</style>
