<template>
    <label :class="[makeInlineElement ? 'input-wrapper inner-layout wrap' : '']">
        <div class="label-wrapper">
            <div
                v-if="label"
                class="label d-flex align-items-center"
            >
                <span class="label-text">
                    {{ label }}
                </span>

                <AppTooltip
                    v-if="explanationText"
                    :offset="tooltipOffset"
                    :tooltipPosition="tooltipPosition"
                    class="ml-2"
                >
                    <template slot="label">
                        <div class="info-icon" />
                    </template>

                    <template slot="content">
                        <div class="tooltip-info">{{ explanationText }}</div>
                    </template>
                </AppTooltip>

                <slot name="additionalLabel" />
            </div>
            <span
                v-if="optional"
                class="textarea-optional align-self-center"
            >
                {{ $i18n.t('generic.optional') }}
            </span>
        </div>
        <div class="content">
            <textarea
                :id="id"
                :class="{
                    error: invalid,
                    filled: value !== '',
                    'error-focused': invalid && value !== '',
                    'resize-none': resizeNone,
                }"
                :rows="rows"
                :disabled="disabled"
                :value="value"
                :placeholder="placeholder"
                :title="title"
                :maxlength="maxlength"
                @input="onInput"
            />
        </div>
        <div
            v-if="enableCharacterCount"
            class="character-count small-gray-note"
        >
            {{ characterCountMessage }}
        </div>
        <div
            v-if="invalid && errorMessage"
            class="error-message"
        >
            {{ errorMessage }}
        </div>
    </label>
</template>

<script>
// COMPONENTS
import AppTooltip from '@/components/partials/AppTooltip.vue';
import { TOOLTIP_POSITION } from '@/common/tooltip';

export default {
    name: 'AppTextareaV3',
    components: {
        AppTooltip,
    },
    props: {
        rows: {
            type: Number,
            default: 3,
        },
        label: {
            type: String,
            default: '',
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        value: {
            type: String,
            default: null,
        },
        placeholder: {
            type: String,
            default: null,
        },
        optional: {
            type: Boolean,
            default: false,
        },
        title: {
            type: String,
            default: '',
        },
        invalid: {
            type: Boolean,
            default: false,
        },
        errorMessage: {
            type: String,
            default: '',
        },
        resizeNone: {
            type: Boolean,
            default: false,
        },
        tooltipOffset: {
            type: Number,
            default: 50,
        },
        id: {
            type: String,
            default: '',
        },
        explanationText: {
            type: String,
            required: false,
            default: '',
        },
        makeInlineElement: {
            type: Boolean,
            default: false,
        },
        tooltipPosition: {
            type: String,
            default: TOOLTIP_POSITION.top,
        },
        maxlength: {
            type: Number,
            default: null,
        },
        enableCharacterCount: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {};
    },
    computed: {
        characterCountMessage() {
            return this.$i18n.t('partials.appTextarea.characterCount', {
                count: this.value?.length || 0,
                max: this.maxlength,
            });
        },
    },
    methods: {
        onInput(e) {
            this.$emit('input', e.target.value);
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/_palette.scss';
@import '~@/assets/scss/_layout.scss';
@import '~@/assets/scss/_typographyV2.scss';

label {
    display: block;
}

.label-wrapper {
    display: flex;
    justify-content: space-between;
}

.label {
    font-size: $text-xs;
    color: $gray90;
    line-height: $label-line-height;
    font-weight: $medium-font-weight;
}

.label-text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.content {
    display: flex;

    textarea {
        width: 100%;
        border-radius: 0.5rem;
        padding: 0.3125rem 0.5rem;
        border: 1px solid $gray5;
        outline: none;
        line-height: $heading-line-height;
        font-size: $text-sm;
        color: $gray90;

        &:focus {
            border-color: $blue;
            color: $gray90;
        }

        &:disabled {
            background-color: $input-background-disabled;
            border: 1px solid $gray10;
            color: $gray30;
        }

        &.error {
            border-color: $red;
        }

        &.error-focused {
            border: 1px solid $red;
        }
    }
}

.character-count {
    text-align: right;
    margin-top: $spacing-xxs;
}

.error-message {
    margin-top: $spacing-xxs;
    font-size: $text-xs;
    line-height: $heading-line-height;
    height: 1.25rem;
    color: $red;
}

.textarea-optional {
    font-weight: $medium-font-weight;
    line-height: $label-line-height;
    font-size: $text-xs;
    font-style: italic;
    color: $gray60;
}

::placeholder {
    font-style: italic;
    color: $gray30;
}

.resize-none {
    resize: none;
}

.tooltip-info {
    white-space: pre;
    font-size: 0.75rem;
    font-weight: normal;
    text-transform: none;
    line-height: normal;
    color: $gray90;
    min-width: 10rem;
}
</style>
