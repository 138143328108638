
// HTTP
import userManagementHTTP from '@/__new__/services/dno/user/http/user-management';

// Helpers
import { ALERT_TYPES } from '@/common/alerts/Alert';
import { USER_MANAGER_HIERARCHY } from '@/__new__/features/customerCare/common/customerCareHelper';
import Actions, { Getters } from '@/store/mutation-types';
import { mapActions, mapGetters } from 'vuex';
import { Modules } from '@/store/store';

export default {
    name: 'PortingInfo',
    props: {
        targetId: {
            default: '',
            type: String,
        },
    },
    data() {
        return {
            mnppin: '',
            mnppinExpiryTimestamp: 0,
        };
    },
    computed: {
        ...mapGetters(Modules.customerCareSuite, [Getters.GET_ASSOCIATED_UM_ENTITY_BY_TYPE_AND_ID]),
        subscriberData() {
            return this[Getters.GET_ASSOCIATED_UM_ENTITY_BY_TYPE_AND_ID]({
                targetType: USER_MANAGER_HIERARCHY.SUBSCRIBER,
                targetId: this.targetId,
            });
        },
        accountData() {
            return this[Getters.GET_ASSOCIATED_UM_ENTITY_BY_TYPE_AND_ID]({
                targetType: USER_MANAGER_HIERARCHY.ACCOUNT,
                targetId: this?.subscriberData?.accountId,
            });
        },
        subscriberMSISDN() {
            return this.subscriberData?.msisdn || '';
        },
        tmoAccountId() {
            return this.accountData?.tmoAccountId || '';
        },
        expiryDate() {
            return this.mnppinExpiryTimestamp
                ? this.$localeLibrary.getFormattedDateAndTime(this.mnppinExpiryTimestamp)
                : '';
        },
    },
    async created() {
        await this.$withProgressBar(
            async () => {
                await this[Actions.LOAD_ASSOCIATED_UM_ENTITIES]({
                    targetType: USER_MANAGER_HIERARCHY.SUBSCRIBER,
                    targetId: this.targetId,
                });
                const res = await userManagementHTTP.issueTemporaryMPNPIN(this.targetId);
                this.mnppin = res?.data?.mnp_pin || '';
                this.mnppinExpiryTimestamp = res?.data?.mnp_pin_expiry_timestamp || '';
            },
            {
                errorHandler: () =>
                    this.$alert(this.$i18n.t('alertMessage.somethingWentWrongFetchingNecessaryData'), {
                        type: ALERT_TYPES.error,
                    }),
            },
        );
    },
    methods: {
        ...mapActions(Modules.customerCareSuite, [Actions.LOAD_ASSOCIATED_UM_ENTITIES]),
        close() {
            this.$emit('close');
        },
    },
};
