
import Vue from 'vue';

// Components
import SubscriberStatusIndicator from '@/__new__/features/customerCare/subscriber/SubscriberStatusIndicator.vue';
import IconButton from '@/components/partials/IconButton.vue';

// Helpers
import RouteNames from '@/router/routeNames';
import { ICON_TYPES } from '@/common/iconHelper';
import isEmpty from 'lodash/isEmpty';
import { copyToClipboard } from '@/common/utils';
import { USER_MANAGER_HIERARCHY } from '@/__new__/features/customerCare/common/customerCareHelper';

// Vuex
import { mapGetters } from 'vuex';
import { Modules } from '@/store/store';
import { Getters } from '@/store/mutation-types';

export default Vue.extend({
    name: 'AssociatedEntitiesV2Tile',
    components: {
        SubscriberStatusIndicator,
        IconButton,
    },
    data() {
        return {
            ICON_TYPES,
            RouteNames,
            copyToClipboard,
        };
    },
    computed: {
        ...mapGetters(Modules.customerCareSuite, [Getters.GET_UM_ENTITY_HIERARCHY_BY_TYPE_AND_ID]),
        id() {
            return this.$route.params.id || '';
        },
        hierarchy() {
            return this[Getters.GET_UM_ENTITY_HIERARCHY_BY_TYPE_AND_ID]({
                targetId: this.id,
                targetType: USER_MANAGER_HIERARCHY.USER,
            });
        },
        showHierarchy() {
            return !isEmpty(this.hierarchy);
        },
    },
    created() {
        this.$emit('isDataLoadingUpd', false);
    },
});
