/* eslint-disable */
import Vue from 'vue';
import lodash from 'lodash';
import Actions, { Mutations, State, Getters } from '../mutation-types';
import {
    getSubscriberStateText,
    getFormattedStateHistory,
    formatSubscriberData,
} from '@/__new__/features/customerCare/common/customerCareHelper';

export default {
    namespaced: true,
    state: {
        // keeping account info in format returned from API
        [State.ACCOUNT_INFO]: {},
        // storing subscriber with such structure: { subId: subData, ... }
        [State.SUBSCRIBERS_INFO]: {},
        [State.CURRENT_SUBSCRIBER_ID]: null,
        [State.SUBSCRIBERS_DETAILED_INFO]: {}, // subscriberId: subscriberDetailedInfo
    },
    mutations: {
        [Mutations.SET_ACCOUNT_INFO]: (state, accountInfo) => {
            Vue.set(state, State.ACCOUNT_INFO, accountInfo);
        },
        [Mutations.SET_SUBSCRIBER_INFO]: (state, { subscriberId, subscriberInfo }) => {
            Vue.set(state[State.SUBSCRIBERS_INFO], subscriberId, subscriberInfo);
        },
        [Mutations.UPDATE_SUBSCRIBER_INFO]: (state, { subId, subscriberInfo }) => {
            Vue.set(state[State.SUBSCRIBERS_INFO], subId, subscriberInfo);
        },
        [Mutations.UPDATE_ACCOUNT_INFO]: (state, accountInfo) => {
            Vue.set(state, State.ACCOUNT_INFO, accountInfo);
        },
        [Mutations.CLEAR_SUBSCRIBERS_INFO]: state => {
            Vue.set(state, State.SUBSCRIBERS_INFO, {});
        },
        [Mutations.SET_SUBSCRIBER_INTERVAL_ID](_state, { subscriberId, intervalId }) {
            Vue.set(_state[State.SUBSCRIBERS_INTERVAL_IDS], subscriberId, intervalId);
        },
        [Mutations.SET_CURRENT_SUBSCRIBER_ID]: (state, subscriberId) => {
            Vue.set(state, State.CURRENT_SUBSCRIBER_ID, subscriberId);
        },
        [Mutations.SET_SUBSCRIBER_DETAILED_INFO]: (state, { subscriberId, subscriberDetailedInfo }) => {
            Vue.set(state[State.SUBSCRIBERS_DETAILED_INFO], subscriberId, subscriberDetailedInfo);
        },
        [Mutations.setSubscriberStateHistory]: (state, { subscriberId, stateStatus }) => {
            Vue.set(state[State.SUBSCRIBERS_INFO], subscriberId, {
                ...state[State.SUBSCRIBERS_INFO][subscriberId],
                ...stateStatus,
            });
        },
        setFullSubscriberDataInfo: (state, subscriberData) => {
            state.subscriberInfo = subscriberData;
        },
    },
    actions: {
        [Actions.SET_CURRENT_SUBSCRIBER_ID]({ commit }, subscriberId) {
            commit(Mutations.SET_CURRENT_SUBSCRIBER_ID, subscriberId);
        },
        [Actions.UPDATE_SUBSCRIBER_EMAIL]({ commit, state }, actionParams) {
            const subId = actionParams.subscriberId;
            let subscriberInfo = { ...state[State.SUBSCRIBERS_INFO][subId] };
            Vue.set(subscriberInfo, 'email', actionParams.newEmail);
            commit(Mutations.UPDATE_SUBSCRIBER_INFO, { subId, subscriberInfo });
        },
        [Actions.UPDATE_ACCOUNT_EMAIL]({ commit, state }, newEmail) {
            let accountInfo = { ...state[State.ACCOUNT_INFO] };
            Vue.set(accountInfo, 'email', newEmail);
            commit(Mutations.UPDATE_ACCOUNT_INFO, accountInfo);
        },

        // New stuff a.k.a v1.5 or in-between the re-design
        setAccountData({ commit, state }, accountData) {
            if (accountData.account_id !== state[State.ACCOUNT_INFO].account_id) {
                commit(Mutations.CLEAR_SUBSCRIBERS_INFO);
            }
            commit(Mutations.SET_ACCOUNT_INFO, accountData);
        },

        setSubscriberData({ commit }, subscriberData) {
            commit('setFullSubscriberDataInfo', subscriberData);
        },

        setSubscriberStateHistoryV2({ commit }, params) {
            commit(Mutations.setSubscriberStateHistory, {
                subscriberId: params.id,
                stateStatus: getFormattedStateHistory(params.history),
            });
        },
    },
    getters: {
        [Getters.SUBSCRIBERS_LIST](state) {
            // all subscribers info goes here with proper formatting
            const subscribersList = Object.values(state[State.SUBSCRIBERS_INFO]);
            if (subscribersList.length) {
                return subscribersList.map(formatSubscriberData);
            }
            return [];
        },
        [Getters.SUBSCRIBERS_SUMMARY](state) {
            const subscribersList = Object.values(state[State.SUBSCRIBERS_INFO]);

            if (subscribersList.length) {
                // some basic subscriber properties returned here
                return subscribersList.map(subscriber => ({
                    id: subscriber.subscriber_id,
                    status: getSubscriberStateText(subscriber.state),
                    state: subscriber.state,
                    creationDate: subscriber.created,
                    msisdn: subscriber.msisdn,
                    isBlocked: subscriber.is_blocked,
                    subscriptionType: subscriber.subscription_type,
                }));
            }
            return [];
        },
        [Getters.ACCOUNT_INFO](state) {
            // a little bit of formatting added here
            return {
                ...state[State.ACCOUNT_INFO],
                status: getSubscriberStateText(state[State.ACCOUNT_INFO].state),
            };
        },
        [Getters.GET_CURRENT_SUBSCRIBER_INFO](state) {
            if (state[State.SUBSCRIBERS_EXTERNAL_INFO] && state[State.CURRENT_SUBSCRIBER_ID]) {
                return state[State.SUBSCRIBERS_EXTERNAL_INFO][state[State.CURRENT_SUBSCRIBER_ID]];
            }
            return null;
        },
        [Getters.GET_SUBSCRIBER_WALLET_INFO]: getters => {
            if (lodash.has(getters[Getters.GET_CURRENT_SUBSCRIBER_INFO], 'serviceBalanceList')) {
                const { serviceBalanceList } = getters[Getters.GET_CURRENT_SUBSCRIBER_INFO].serviceBalanceList;
                const serviceBalanceListValidityPeriod = serviceBalanceList['validity-period'] || {};
                if (serviceBalanceList) {
                    return {
                        acctStatus: serviceBalanceList.status || {},
                        firstCallDate: serviceBalanceList.created,
                        mainAccBalance:
                            serviceBalanceList.details && serviceBalanceList.details.amount
                                ? serviceBalanceList.details.amount
                                : '',
                        mainAccExpiryDt: serviceBalanceListValidityPeriod['from-date'],
                        mainAccGraceEndDt: serviceBalanceListValidityPeriod['to-date'],
                    };
                }
            }
            return {};
        },
        [Getters.GET_CURRENT_SUBSCRIBER_ID](state) {
            if (state[State.CURRENT_SUBSCRIBER_ID]) {
                return state[State.CURRENT_SUBSCRIBER_ID];
            }
            return null;
        },
    },
};
