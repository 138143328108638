
// Vue components
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import AppDialogV2 from '@/components/partials/AppDialogV2.vue';
import AppInputV3 from '@/components/partials/inputs/AppInputV3.vue';

// Helpers & Misc
import RouteNames from '@/router/routeNames';
import luaErrorCodes from '@/common/luaErrors';
import { isValidUuid } from '@/common/uuidHelper';
import { ICON_TYPES } from '@/common/iconHelper';
import { ALERT_TYPES } from '@/common/alerts/Alert';
import permissionsService, { isUserAllowed } from '@/services/permissions/permissions.service';

// HTTP
import { getExecution } from '@/__new__/services/dno/orchestrationengine/http/orchestration-engine';

export default {
    name: 'SearchExecutionModal',

    components: {
        AppButton,
        AppDialogV2,
        AppInputV3,
    },

    props: {
        visible: {
            required: true,
            type: Boolean,
        },
        refreshPageOnSearch: {
            type: Boolean,
            default: false,
        },
    },

    validations: {
        executionID: {
            valid: id => isValidUuid(id),
        },
    },

    data() {
        return {
            // Proxy imports
            BUTTON_TYPES,
            ICON_TYPES,

            executionID: '' as string,
            goToExecutionButtonClicked: false as boolean,
        };
    },

    computed: {
        writeEnabled(): boolean {
            return isUserAllowed('OrchestrationPlansWrite') && permissionsService.orchestrationEnginePlansEnabled();
        },
    },

    methods: {
        async goToExecution(): Promise<any> {
            this.$v.$touch();

            if (this.$v.$error) {
                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('orchestrationEngine.alerts.goToExecutionValidation'),
                    type: ALERT_TYPES.warning,
                });

                return;
            }

            try {
                this.$Progress.start();

                this.goToExecutionButtonClicked = true;

                const response = await getExecution(this.executionID);

                const { plan_id: planId } = response.data.execution;

                this.$Progress.finish();

                this.$router.push({
                    name: RouteNames.ORCHESTRATION_ENGINE_EXECUTION_DETAILS,
                    params: {
                        id: planId,
                        execId: this.executionID,
                        companyId: this.$route.params.companyId,
                    },
                });

                // Refresh page in case we need it
                if (this.refreshPageOnSearch) {
                    this.$router.go(0);
                }
            } catch (e: any) {
                this.$Progress.fail();
                const errorMsg =
                    e?.response?.data?.code === luaErrorCodes.ORCHESTRATION.PLAN_EXECUTION_MISSING.code
                        ? this.$i18n.t('orchestrationEngine.alerts.executionDoesNotExist')
                        : this.$i18n.t('alertMessage.somethingWentWrong');

                this.$eventBus.$emit('showAlert', {
                    message: errorMsg,
                });
            } finally {
                this.goToExecutionButtonClicked = false;
            }
        },
        onCloseModal(): void {
            this.$emit('close');
            this.executionID = '';
        },
    },
};
