
// Vue
import Vue from 'vue';

// components
import { ICON_TYPES } from '@/common/iconHelper';
import AbstractListPageWrapper from '@/components/layout/AbstractListPageWrapper.vue';
import AppTable from '@/components/partials/AppTable.vue';
import FilterTable from '@/components/partials/FilterTable.vue';
import FilterTableMixin from '@/components/partials/FilterTableMixin.vue';
import IconButton from '@/components/partials/IconButton.vue';
import SearchBox from '@/components/partials/inputs/SearchBox.vue';
import TableFiltersTags from '@/components/filters/TableFiltersTags.vue';
import DLQUploadDialog from '@/__new__/features/sinkConfigs/dlq/DLQUploadDialog.vue';

// helpers
import tableColumnType, { type TableColumn } from '@/common/filterTable';
import { isUserAllowed } from '@/services/permissions/permissions.service';
import { formatDataAmount, unitTypes } from '@/common/formatting';
import { downloadFile } from '@/common/downloadHelper';
import { DLQ_INGESTION_TABLE_ACTIONS, DlqFileResponse } from '@/__new__/features/sinkConfigs/common/dlqHelper';

// http
import pyperHTTP from '@/__new__/services/dno/pyper/dlq';

export default Vue.extend({
    name: 'DLQDashboard',
    components: {
        AbstractListPageWrapper,
        AppTable,
        DLQUploadDialog,
        FilterTable,
        IconButton,
        SearchBox,
        TableFiltersTags,
    },
    mixins: [FilterTableMixin],
    data() {
        return {
            ICON_TYPES,
            DLQ_INGESTION_TABLE_ACTIONS,
            apiResponse: {} as Record<string, any>,
            defaultSort: {
                key: 'lastModified',
                type: 'desc',
            } as Record<string, string>,
            entities: [] as DlqFileResponse[],
            searchQuery: '' as string,
            tableFilters: [] as string[],
            isDataLoading: false as boolean,
            isIngestEnabled: isUserAllowed('DlqDashboardIngest') as boolean,
            // Replace
            replaceEntity: undefined as DlqFileResponse | undefined,
            isUploadDialogOpen: false as boolean,
        };
    },

    computed: {
        tableColumnsData(): TableColumn[] {
            return [
                {
                    name: this.$t('generic.name'),
                    key: 'fileName',
                    field: 'fileName',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$t('generic.tableName'),
                    key: 'tableName',
                    field: 'tableName',
                    filterType: tableColumnType.TEXT_LIMITED_OPTIONS,
                    limitedOptions: Array.from(new Set(this.entities.map(e => e.tableName))),
                },
                {
                    name: this.$t('generic.fileSize'),
                    key: 'size',
                    field: 'size',
                    mapper: entity => formatDataAmount(entity.size),
                    filterType: tableColumnType.NUMBER_UNITS,
                    unitType: unitTypes.DATA,
                },
                {
                    name: this.$t('generic.updatedAt'),
                    key: 'lastModified',
                    field: 'lastModified',
                    mapper: entity => this.$localeLibrary.getFormattedDateAndTime(entity.lastModified),
                    filterType: tableColumnType.DATETIME,
                },
            ];
        },
        filteredEntities() {
            return this.filteredEntitiesMixin(this.entities);
        },
    },
    async created() {
        await this.fetchEntities();
    },
    methods: {
        isActionEnabled(entity: DlqFileResponse, action: DLQ_INGESTION_TABLE_ACTIONS): boolean {
            return entity.actions.includes(action);
        },
        async fetchEntities(): Promise<void> {
            await this.$withProgressBar(
                async () => {
                    this.isDataLoading = true;
                    this.apiResponse = await pyperHTTP.getDlqFiles();
                    this.entities = this.apiResponse?.data?.data || [];
                    this.isDataLoading = false;
                },
                {
                    errorHandler: () => {
                        this.isDataLoading = false;
                        this.$alert(this.$t('dlq.fetchDataFail'));
                    },
                },
            );
        },
        async downloadDlq({ fileEntryID }: DlqFileResponse): Promise<void> {
            await this.$withProgressBar(
                async () => {
                    this.isDataLoading = true;
                    // eslint-disable-next-line prettier/prettier
                    const { data: { data: { signed_url: downloadUrl } } } = await pyperHTTP.getDownloadUrl(fileEntryID);

                    downloadFile(downloadUrl);
                    this.$showSuccessAlert({ message: this.$t('dlq.downloadSuccess') });

                    this.isDataLoading = false;
                },
                {
                    errorHandler: () => {
                        this.isDataLoading = false;
                        this.$alert(this.$t('dlq.downloadFail'));
                    },
                },
            );
        },
        async redriveDlq({ fileEntryID }: DlqFileResponse): Promise<void> {
            await this.$withProgressBar(
                async () => {
                    this.isDataLoading = true;

                    await pyperHTTP.redriveDlq(fileEntryID);

                    this.$showSuccessAlert({ message: this.$t('dlq.redriveSuccess') });
                    await this.fetchEntities();
                },
                {
                    errorHandler: () => {
                        this.isDataLoading = false;
                        this.$alert(this.$t('dlq.redriveFail'));
                    },
                },
            );
        },
        toggleUploadDialog(entity: DlqFileResponse): void {
            this.isUploadDialogOpen = !!entity;
            this.replaceEntity = entity;
        },
    },
});
