import http from '@/http';

export function fetchReportsDefinitionsQrd() {
    return http('/v3reports/definitionsqrd').then(resp => resp.data.data);
}

export function fetchReportsDefinitionsOrd() {
    return http('/v3reports/definitionsord').then(resp => resp.data.data);
}

export function fetchQrdReports(definitionId, startDate, endDate, size = 100, pageToken = '') {
    const params = {
        definitionId,
        startDate,
        endDate,
        timezone: 'Etc/UTC',
        size,
    };

    if (pageToken) {
        params['page-token'] = pageToken;
    }

    return http('/v3reports/reportsqrd', {
        params,
    }).then(resp => resp.data);
}

export function fetchOrdReports(definitionId, startDate, endDate, size = 100, pageToken = '') {
    const params = {
        definitionId,
        startDate,
        endDate,
        timezone: 'Etc/UTC',
        size,
    };

    if (pageToken) {
        params['page-token'] = pageToken;
    }

    return http('/v3reports/reportsord', {
        params,
    }).then(resp => resp.data);
}

export function generateQrdReport({ definitionId, startDate, endDate, timezone }) {
    return http.post('/v3reports/generateqrd', {
        definitionId,
        startDate,
        endDate,
        timezone,
    });
}

export function generateOrdReport({ definitionId, startDate, endDate, timezone }) {
    return http.post('/v3reports/generateord', {
        definitionId,
        startDate,
        endDate,
        timezone,
    });
}

export function fetchQrdReport({ definitionId, startDate, endDate, timezone }) {
    return http('/v3reports/fetchqrd', {
        params: {
            definitionId,
            startDate,
            endDate,
            timezone,
        },
    }).then(resp => resp.data.data);
}

export function fetchOrdReport({ definitionId, startDate, endDate, timezone }) {
    return http('/v3reports/fetchord', {
        params: {
            definitionId,
            startDate,
            endDate,
            timezone,
        },
    }).then(resp => resp.data.data);
}

export function publishQrdReport({ definitionId, startDate, endDate, timezone }) {
    return http.post('/v3reports/publishqrd', {
        definitionId,
        startDate,
        endDate,
        timezone,
    });
}

export function publishOrdReport({ definitionId, startDate, endDate, timezone }) {
    return http.post('/v3reports/publishord', {
        definitionId,
        startDate,
        endDate,
        timezone,
    });
}

export function previewQrdReport({ definitionId, startDate, endDate, timezone }) {
    return http.post('/v3reports/previewqrd', {
        definitionId,
        startDate,
        endDate,
        timezone,
    });
}

export function previewOrdReport({ definitionId, startDate, endDate, timezone }) {
    return http.post('/v3reports/previeword', {
        definitionId,
        startDate,
        endDate,
        timezone,
    });
}

export function replaceReport({ definitionId, fileEntryId, uploadId }) {
    return http({
        method: 'POST',
        url: '/v3reports/replace',
        headers: { 'Content-type': 'application/json' },
        params: { report_definition_id: definitionId, file_entry_id: fileEntryId },
        data: { upload_id: uploadId },
    });
}

export function replaceReportSignedUrl({ definitionId, fileEntryId }) {
    return http({
        method: 'POST',
        url: '/v3reports/signedurl',
        headers: { 'Content-type': 'application/json' },
        params: { report_definition_id: definitionId, file_entry_id: fileEntryId },
    }).then(
        ({
            data: {
                data: { signed_url: signedUrl, upload_id: uploadId },
            },
        }) => ({ signedUrl, uploadId }),
    );
}

export default {
    fetchReportsDefinitionsQrd,
    fetchReportsDefinitionsOrd,
    fetchQrdReports,
    fetchOrdReports,
    generateQrdReport,
    generateOrdReport,
    fetchQrdReport,
    fetchOrdReport,
    publishQrdReport,
    publishOrdReport,
    previewQrdReport,
    previewOrdReport,
    replaceReport,
    replaceReportSignedUrl,
};
