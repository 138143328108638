import { AxiosResponse } from 'axios';
import {
    createPricingRule as createDnoPricingRule,
    getPricingRules as getDnoPricingRules,
    getPricingRuleById as getDnoPricingRuleById,
    updatePricingRule as updateDnoPricingRule,
    PricingRulesResponse,
    updatePricingRuleState,
} from '@/__new__/services/dno/pricing/http/pricingRules';
import {
    LineItemType,
    DISCOUNT_TARGET,
    PricingRuleEntity,
    SimplePricingRule as SimplePricingRulePortal,
} from '@/__new__/services/dno/pricing/pricingRulesPortal';
import { Entity, EntityState } from '@/http';
import {
    Adjustment,
    AMOUNT_CALCULATION_METHOD,
    ComparisonArgs,
    COMPARISON_OP,
    Condition,
    TARGET,
    LogicalArgs,
    PricingRule,
    PricingRuleAction,
    SCHEMA_TYPE,
    Selector,
    SimplePricingRule as SimplePricingRuleDno,
    SimpleViewData,
    MULTIPLIER,
} from '@/__new__/services/dno/pricing/models/pricingDno';
import { getEntityStateName } from '@/common/commonEntityStateMapper';
import {
    AdjustmentPortal,
    ComparisonArgsPortal,
    ConditionPortal,
    LogicalArgsPortal,
    PricingRuleActionPortal,
    SelectorPortal,
} from './models/pricingPortal';
import i18n from '@/i18n';
import ENTITY_TYPES from '@/common/entities/entityTypes';
import * as Sentry from '@sentry/vue';
import {
    cohortExpressionsFromJson,
    cohortExpressionsToJson,
    isNonEmptyCohortExpression,
} from '@/common/cohortExpressionHelper';

/**
 * Pricing Rule data structure which contains:
 * - list of Pricing Rule Portal Entities
 * - The original response from the DNO
 */
export type PricingRulesData = {
    response: AxiosResponse<PricingRulesResponse>;
    pricingRules: PricingRuleEntity[];
};

/**
 * Fetches *all* PricingRules from the DNO and converts them to Portal entities.
 * Converts localized strings to plain strings based on given `languageKey`.
 * @param languageKey
 * @param arrayCohortsAll
 * @returns
 */
export const getPricingRules = async (languageKey = 'en', arrayCohortsAll = []): Promise<PricingRulesData> => {
    // Get DNO Pricing Rules
    const response = await getDnoPricingRules();

    // Build Portal consumable entities
    const dnoPricingRules = response.data.pricing_rule_by_id ?? {};
    const dnoPricingRuleIds = Object.keys(dnoPricingRules);
    const pricingRules = dnoPricingRuleIds
        .map(id => {
            try {
                return convertPricingRuleFromDnoToPortal(dnoPricingRules[id], languageKey, arrayCohortsAll);
            } catch (error: any) {
                Sentry.captureException(
                    new Error(`Portal not convert pricing rule "${id}". Cause: "${error.message}"`),
                );
            }
        })
        .filter(e => e) as PricingRuleEntity[];

    return {
        response,
        pricingRules,
    };
};

/**
 * Fetches a single Pricing Rule with given `id`.
 * Converts localized strings to plain strings based on given `languageKey`.
 * @param id
 * @param languageKey
 * @param arrayCohortsAll
 * @returns
 */
export const getPricingRuleById = async (
    id: string,
    languageKey = 'en',
    arrayCohortsAll = [],
): Promise<PricingRuleEntity> => {
    const response = await getDnoPricingRuleById(id);

    // Build Portal consumable entities
    const dnoPricingRules = Object.values(response.data.pricing_rule_by_id ?? {});
    const pricingRules = dnoPricingRules.map(dnoPricingRule =>
        convertPricingRuleFromDnoToPortal(dnoPricingRule, languageKey, arrayCohortsAll),
    );

    // Throw error if we're unable to locate desired entity in payload
    if (pricingRules.length && pricingRules[0].id === id) {
        return pricingRules[0];
    } else {
        throw new Error(`Error fetching pricing rule (${id}) from DNO`);
    }
};

export const updatePricingRule = async (entity: PricingRuleEntity, languageKey = 'en'): Promise<void> => {
    await updateDnoPricingRule(entity.id, entity.version, convertPricingRuleFromPortalToDno(entity, languageKey));
};

export const createPricingRule = async (entity: PricingRuleEntity, languageKey = 'en'): Promise<void> => {
    const dnoPricingRule = convertPricingRuleFromPortalToDno(entity, languageKey);
    await createDnoPricingRule(dnoPricingRule);
};

export const deletePricingRule = async (entity: PricingRuleEntity): Promise<void> => {
    await updatePricingRuleState(entity.id, entity.version, EntityState.DELETED);
};

export const approvePricingRule = async (entity: PricingRuleEntity): Promise<void> => {
    await updatePricingRuleState(entity.id, entity.version, EntityState.APPROVED);
};

const convertConditionArgsFromDnoToPortal = (
    args: LogicalArgs | ComparisonArgs,
): LogicalArgsPortal | ComparisonArgsPortal => {
    if ('nested_conditions' in args) {
        return {
            nestedConditions: args.nested_conditions.map(c => convertConditionFromDnoToPortal(c)),
        };
    } else {
        return {
            dataType: args.data_type,
            key: args.key,
            value: args.value,
        };
    }
};

const convertConditionArgsFromPortalToDno = (
    args: LogicalArgsPortal | ComparisonArgsPortal,
): LogicalArgs | ComparisonArgs => {
    if ('nestedConditions' in args) {
        return {
            nested_conditions: args.nestedConditions.map(c => convertConditionFromPortalToDno(c)),
        };
    } else {
        return {
            data_type: args.dataType,
            key: args.key,
            value: args.value,
        };
    }
};

const convertConditionFromDnoToPortal = (condition: Condition): ConditionPortal => {
    return {
        type: condition.type,
        op: condition.op,
        args: convertConditionArgsFromDnoToPortal(condition.args),
    };
};

const convertConditionFromPortalToDno = (condition: ConditionPortal): Condition => {
    return {
        type: condition.type,
        op: condition.op,
        args: convertConditionArgsFromPortalToDno(condition.args),
    } as Condition;
};

const convertSelectorFromDnoToPortal = (selector: Selector): SelectorPortal => {
    return {
        variableName: selector.variable_name,
        variableType: selector.variable_type,
        condition: selector.condition,
    };
};

const convertSelectorFromPortalToDno = (selector: SelectorPortal): Selector => {
    return {
        variable_name: selector.variableName,
        variable_type: selector.variableType,
        condition: selector.condition,
    };
};

const convertActionAdjustmentFromDnoToPortal = (adjustment: Adjustment): AdjustmentPortal => {
    return {
        actionType: adjustment.action_type,
        componentType: adjustment.component_type,
        componentSubType: adjustment.component_subtype,
        expression: adjustment.expression,
        expressionType: adjustment.expression_type,
    };
};

const convertActionAdjustmentFromPortalToDno = (adjustment: AdjustmentPortal): Adjustment => {
    return {
        action_type: adjustment.actionType,
        component_type: adjustment.componentType,
        component_subtype: adjustment.componentSubType,
        expression: adjustment.expression,
        expression_type: adjustment.expressionType,
    };
};

export const convertPricingRuleActionFromDnoToPortal = (action: PricingRuleAction): PricingRuleActionPortal => {
    const res: PricingRuleActionPortal = {
        adjustLevel: action.adjust_level,
        adjustment: convertActionAdjustmentFromDnoToPortal(action.adjustment),
    };
    if (action.action_selector) {
        res.actionSelector = convertSelectorFromDnoToPortal(action.action_selector);
    }
    return res;
};

export const convertPricingRuleActionFromPortalToDno = (action: PricingRuleActionPortal): PricingRuleAction => {
    const res: PricingRuleAction = {
        adjust_level: action.adjustLevel,
        adjustment: convertActionAdjustmentFromPortalToDno(action.adjustment),
    };
    if (action.actionSelector) {
        res.action_selector = convertSelectorFromPortalToDno(action.actionSelector);
    }
    return res;
};

/**
 * Converts a dno pricing rule `Entity<PricingRule>` to a portal type `PricingRuleEntity`
 * @param dnoPricingRule
 * @param languageKey
 * @param arrayCohortsAll
 * @returns
 */
const convertPricingRuleFromDnoToPortal = (
    dnoPricingRule: Entity<PricingRule>,
    languageKey = 'en',
    arrayCohortsAll = [],
): PricingRuleEntity => {
    const isSimplePricingRule = dnoPricingRule.data.schema_type === SCHEMA_TYPE.SIMPLE;
    return {
        id: dnoPricingRule.id,
        name: dnoPricingRule.data.name[languageKey] ?? '',
        description: dnoPricingRule.data.description[languageKey] ?? '',
        priority: dnoPricingRule.data.priority,
        misc: dnoPricingRule.data.misc,
        state: dnoPricingRule.state,
        stateLabel: getEntityStateName(dnoPricingRule.state),
        updateTimeEpoch: dnoPricingRule.update_time,
        conditions: dnoPricingRule.data.conditions,
        actions: dnoPricingRule.data.actions.map(convertPricingRuleActionFromDnoToPortal),
        version: dnoPricingRule.version,
        nameByLanguage: dnoPricingRule.data.name,
        descriptionByLanguage: dnoPricingRule.data.description,
        isSimplePricingRule,
        simplePricingRule: convertSimplePricingRuleFromDnoToPortal(
            dnoPricingRule.data.schema_type,
            dnoPricingRule.data.simple_view,
        ),
        viewLabel: (isSimplePricingRule ? i18n.t('generic.simple') : i18n.t('generic.advanced')).toString(),
        cohortsObj: dnoPricingRule.data.cohort_expression
            ? cohortExpressionsFromJson(dnoPricingRule.data.cohort_expression, arrayCohortsAll)
            : undefined,
        cohortExpression: dnoPricingRule.data.cohort_expression,
    };
};

const convertPricingRuleFromPortalToDno = (
    entity: PricingRuleEntity,
    languageKey = 'en',
): PricingRule | SimplePricingRuleDno => {
    // Build properties common between simple and complex pricing rules
    const name = {
        // Combine with map to preserve existing data
        ...entity.nameByLanguage,
        [languageKey]: entity.name,
    };
    const description = {
        // Combine with map to preserve existing data
        ...entity.descriptionByLanguage,
        [languageKey]: entity.description,
    };
    const misc = entity.misc;
    const priority = Number(entity.priority);

    // Simple Pricing Rule: Build and Return
    if (entity.isSimplePricingRule) {
        if (entity.simplePricingRule == null) {
            throw new Error(
                'Simple pricing rule data undefined.  Simple pricing rule data must be provided if pricing rule is simple.',
            );
        }
        return {
            name,
            description,
            priority,
            misc,
            schema_type: SCHEMA_TYPE.SIMPLE,
            simple_view: convertSimplePricingRuleFromPortalToDno(entity.simplePricingRule),
            cohort_expression: isNonEmptyCohortExpression(entity.cohortsObj)
                ? cohortExpressionsToJson(entity.cohortsObj)
                : undefined,
        };
    }

    // Complex Pricing Rule: Build and Return
    return {
        name,
        description,
        priority,
        misc,
        schema_type: SCHEMA_TYPE.NORMAL,
        actions: entity.actions.map(convertPricingRuleActionFromPortalToDno),
        conditions: entity.conditions,
        cohort_expression: isNonEmptyCohortExpression(entity.cohortsObj)
            ? cohortExpressionsToJson(entity.cohortsObj)
            : undefined,
    };
};

/**
 * Convert simple pricing rule from Portal representation to backend representation
 */
const convertSimplePricingRuleFromPortalToDno = (simplePricingRule: SimplePricingRulePortal): SimpleViewData => {
    // Build base Simple View Data
    const simpleViewData = {
        line_items: {
            entity_type: simplePricingRule.lineItemType,
            entity_ids: simplePricingRule.lineItems,
            all_entity_ids_required: simplePricingRule.allLineItemsMandatoryConditionEnabled,
        },
        discount: {
            target: convertDiscountTargetPortalToDno(simplePricingRule.discountTarget),
            amount: Number(simplePricingRule.discountAmount),
            amount_type: simplePricingRule.discountAmountType,
        },
    } as SimpleViewData;

    // Add incremental discount data
    if (simplePricingRule.incrementalDiscountAmount != null) {
        simpleViewData.discount.inc_amount = Number(simplePricingRule.incrementalDiscountAmount);
    }
    if (simplePricingRule.incrementalDiscountAmountType != null) {
        simpleViewData.discount.inc_amount_type = simplePricingRule.incrementalDiscountAmountType;
    }
    if (simplePricingRule.incrementalDiscountMultiplier != null) {
        simpleViewData.discount.multiplier = simplePricingRule.incrementalDiscountMultiplier;
    }

    // Add in line item quantity (if enabled)
    if (simplePricingRule.lineItemQuantityConditionEnabled) {
        if (simplePricingRule.lineItemQuantity == null) {
            throw new Error('Line item quantity condition enabled but quantity not specified!');
        }
        if (simplePricingRule.lineItemQuantityOperator == null) {
            throw new Error('Line item quantity condition enabled but quantity operator not specified!');
        }
        simpleViewData.line_items.quantity = Number(simplePricingRule.lineItemQuantity);
        simpleViewData.line_items.quantity_op = simplePricingRule.lineItemQuantityOperator;
    }

    // Add in minimum line item and existing item quantity (if enabled)
    if (simplePricingRule.minLineItemAndSubscriptionQuantityConditionEnabled) {
        simpleViewData.minimum_number_of_line_items_and_existing_items = Number(
            simplePricingRule.minLineItemAndSubscriptionQuantity,
        );
    }

    // Add in spending condition (if enabled):
    if (simplePricingRule.spendingConditionEnabled) {
        if (simplePricingRule.spendingAmount == null) {
            throw new Error('Spending amount is not defined');
        }
        if (simplePricingRule.spendingAmountOperator == null) {
            throw new Error('Spending amount operator is not defined');
        }
        if (simplePricingRule.spendingAmountTarget == null) {
            throw new Error('Spending amount target is not defined');
        }
        simpleViewData.spending_condition = {
            amount: Number(simplePricingRule.spendingAmount),
            op: simplePricingRule.spendingAmountOperator,
            target: simplePricingRule.spendingAmountTarget,
        };
    }

    // Add in discount target type and ids (if target isn't total)
    if (simplePricingRule.discountTarget === DISCOUNT_TARGET.LINE_ITEMS_BEFORE_TAX) {
        simpleViewData.discount.target_entity_type = undefined;
        simpleViewData.discount.target_entity_ids = undefined;
    } else if (simplePricingRule.discountTarget === DISCOUNT_TARGET.SPECIFIC_LINE_ITEM_BEFORE_TAX) {
        if (simplePricingRule.discountTargetEntityType == null) {
            throw new Error('Discount target was specific line items, however target entity type was not specified');
        }
        if (simplePricingRule.discountTargetEntityIds == null) {
            throw new Error('Discount target was specific line items, however target ids type were not specified');
        }
        simpleViewData.discount.target_entity_type = simplePricingRule.discountTargetEntityType;
        simpleViewData.discount.target_entity_ids = simplePricingRule.discountTargetEntityIds;
    }

    return simpleViewData;
};

/**
 * Attempts to build Portal representation of a SimplePricingRule based on the type of the pricing rule and simple view data
 * @param schemaType
 * @param simpleViewData
 * @returns `undefined` if pricing rule is not simple, returns `SimplePricingRulePortal` otherwise
 */
const convertSimplePricingRuleFromDnoToPortal = (
    schemaType: SCHEMA_TYPE,
    simpleViewData?: SimpleViewData,
): SimplePricingRulePortal | undefined => {
    if (schemaType !== SCHEMA_TYPE.SIMPLE) {
        return undefined;
    }
    // If for some strange reason our simple pricing rule has no data associated with it
    // just carry on. The editor won't show anything so the user can add data if necessary.
    if (!simpleViewData) {
        return undefined;
    }
    return {
        lineItemType: simpleViewData.line_items.entity_type,
        lineItems: simpleViewData.line_items.entity_ids,
        spendingConditionEnabled: Boolean(simpleViewData.spending_condition),
        spendingAmountOperator: simpleViewData.spending_condition?.op,
        spendingAmount: simpleViewData.spending_condition?.amount,
        spendingAmountTarget: simpleViewData.spending_condition?.target,
        allLineItemsMandatoryConditionEnabled: simpleViewData.line_items.all_entity_ids_required,
        lineItemQuantityConditionEnabled: simpleViewData.line_items.quantity != null,
        lineItemQuantityOperator: simpleViewData.line_items.quantity_op,
        lineItemQuantity: simpleViewData.line_items.quantity,
        minLineItemAndSubscriptionQuantityConditionEnabled:
            simpleViewData.minimum_number_of_line_items_and_existing_items != null,
        minLineItemAndSubscriptionQuantity:
            simpleViewData.minimum_number_of_line_items_and_existing_items != null
                ? simpleViewData.minimum_number_of_line_items_and_existing_items
                : undefined,
        discountTarget: convertDiscountTargetDnoToPortal(
            simpleViewData.discount.target,
            simpleViewData.discount.target_entity_ids,
        ),
        discountTargetEntityType: simpleViewData.discount.target_entity_type,
        discountTargetEntityIds: simpleViewData.discount.target_entity_ids,
        discountAmount: simpleViewData.discount.amount,
        discountAmountType: simpleViewData.discount.amount_type,
        incrementalDiscountAmount: simpleViewData.discount.inc_amount,
        incrementalDiscountAmountType: simpleViewData.discount.inc_amount_type,
        incrementalDiscountMultiplier: simpleViewData.discount.multiplier,
    };
};

const convertDiscountTargetPortalToDno = (discountTarget: DISCOUNT_TARGET): TARGET => {
    switch (discountTarget) {
        case DISCOUNT_TARGET.TOTAL_BEFORE_TAX:
            return TARGET.TOTAL_BEFORE_TAX;
        case DISCOUNT_TARGET.LINE_ITEMS_BEFORE_TAX:
        case DISCOUNT_TARGET.SPECIFIC_LINE_ITEM_BEFORE_TAX:
            return TARGET.LINE_ITEMS_BEFORE_TAX;
        default:
            throw new Error(`Unsupported discount target: ${discountTarget}`);
    }
};

const convertDiscountTargetDnoToPortal = (discountTarget: TARGET, entityIds: string[] | undefined): DISCOUNT_TARGET => {
    switch (discountTarget) {
        case TARGET.TOTAL_BEFORE_TAX:
            return DISCOUNT_TARGET.TOTAL_BEFORE_TAX;
        case TARGET.LINE_ITEMS_BEFORE_TAX:
            return entityIds ? DISCOUNT_TARGET.SPECIFIC_LINE_ITEM_BEFORE_TAX : DISCOUNT_TARGET.LINE_ITEMS_BEFORE_TAX;
        default:
            throw new Error(`Unsupported discount target from dno: ${discountTarget}`);
    }
};

/**
 * Generate a summary for a Simple Pricing Rule
 */
export const summary = (
    pricingRule: SimplePricingRulePortal | undefined,
    lineItemNames: string[],
    targetItemNames: string[],
): string => {
    // Add quotes to all the names
    lineItemNames = [...lineItemNames].map(name => `"${name}"`);
    targetItemNames = [...targetItemNames].map(name => `"${name}"`);

    if (!pricingRule) {
        return i18n.t('pricingAndFees.pleaseFillOutFormToDisplaySummary').toString();
    }

    // Create action string
    const actionStr = [
        'Save',
        getDiscountAmountStr(pricingRule),
        getDiscountTargetStr(
            pricingRule.discountTarget,
            lineItemNames,
            targetItemNames,
            pricingRule.lineItemType,
            pricingRule.discountTargetEntityType,
        ),
    ]
        .filter(s => s)
        .join(' ');

    // Create list of condition strings
    let conditionStrList: string[] = [];

    if (pricingRule.spendingConditionEnabled) {
        conditionStrList.push(
            getSpendingConditionStr(
                pricingRule.spendingAmount,
                pricingRule.spendingAmountOperator,
                pricingRule.spendingAmountTarget,
            ),
        );
    }

    if (pricingRule.lineItemQuantityConditionEnabled) {
        conditionStrList.push(
            getLineItemQuantityConditionStr(pricingRule.lineItemQuantity, pricingRule.lineItemQuantityOperator),
        );
    }

    if (pricingRule.minLineItemAndSubscriptionQuantityConditionEnabled) {
        conditionStrList.push(getMinLineAndSubscriptionConditionStr(pricingRule.minLineItemAndSubscriptionQuantity));
    }

    conditionStrList.push(
        getLineItemsPurchasedStr(
            lineItemNames,
            pricingRule.lineItemType,
            pricingRule.allLineItemsMandatoryConditionEnabled,
        ),
    );

    conditionStrList = conditionStrList.filter(s => s);

    // Create condition string
    let conditionStr = '';
    if (conditionStrList.length) {
        conditionStr =
            `${i18n.t('generic.when')} ` +
            conditionStrList.join(` ${i18n.t('generic.and')} ${i18n.t('generic.when')} `);
    }

    // Add them together
    return [actionStr, conditionStr].filter(s => s).join(' ') + '.';
};

/**
 * Returns human readable string indicating the discount amount
 * Eg:
 *      `Save 50,`
 *      `Save 50 + 1% for each purchase and subscription,`
 *      `Save 1% for each purchase,`
 * @param pricingRule
 * @returns
 */
const getDiscountAmountStr = (pricingRule: SimplePricingRulePortal): string => {
    const baseDiscountStr = getAmountStr(pricingRule.discountAmount, pricingRule.discountAmountType);
    const incDiscountStr = getIncrementalDiscountStr(
        pricingRule.incrementalDiscountAmount,
        pricingRule.incrementalDiscountAmountType,
        pricingRule.incrementalDiscountMultiplier,
    );
    return [baseDiscountStr, incDiscountStr].filter(s => s).join(' + ') + ',';
};

/**
 * Returns string describing incremental discount.
 * Returns empty string if any input parameters are undefined.
 * Eg:
 *      '2 for each purchase and subscription`
 *      `5% for each purchase`
 * @param amount
 * @param amountType
 * @param multiplier
 * @returns
 */
const getIncrementalDiscountStr = (
    amount: number | undefined,
    amountType: AMOUNT_CALCULATION_METHOD | undefined,
    multiplier: MULTIPLIER | undefined,
): string => {
    const amountStr = getAmountStr(amount, amountType);
    if (!amountStr) {
        return '';
    }
    const multiplierStr = getMultiplierStr(multiplier);
    if (!multiplierStr) {
        return '';
    }
    return `${amountStr} ${multiplierStr}`;
};

/**
 * Returns a human readable version of the multiplier
 */
const getMultiplierStr = (multiplier: MULTIPLIER | undefined): string => {
    switch (multiplier) {
        case MULTIPLIER.NUMBER_OF_LINE_ITEMS:
            return i18n.t('pricingAndFees.forEachPurchase').toString();
        case MULTIPLIER.NUMBER_OF_EXISTING_ITEMS:
            return i18n.t('pricingAndFees.forEachSubscription').toString();
        case MULTIPLIER.NUMBER_OF_LINE_AND_EXISTING_ITEMS:
            return i18n.t('pricingAndFees.forEachPurchaseAndSubscription').toString();
        case MULTIPLIER.NUMBER_OF_ADDITIONAL_LINE_ITEMS:
            return i18n.t('pricingAndFees.forEachAdditionalPurchase').toString();
        case MULTIPLIER.NUMBER_OF_ADDITIONAL_EXISTING_ITEMS:
            return i18n.t('pricingAndFees.forEachAdditionalSubscription').toString();
        case MULTIPLIER.NUMBER_OF_ADDITIONAL_LINE_AND_EXISTING_ITEMS:
            return i18n.t('pricingAndFees.forEachAdditionalPurchaseAndSubscription').toString();
        default:
            return '';
    }
};
/**
 * Converts amount and type to a human readable string.
 * Note no currency symbols currently supported. Fixed amounts such as "$10" will be displayed without the currecny symbol as 10.
 * Eg:
 *      10%
 *      10
 */
const getAmountStr = (amount: number | undefined, amountType: AMOUNT_CALCULATION_METHOD | undefined): string => {
    if (!amount) {
        return '';
    }
    switch (amountType) {
        case AMOUNT_CALCULATION_METHOD.PERCENT:
            return `${amount}%`;
        case AMOUNT_CALCULATION_METHOD.FIXED:
            return `${amount}`;
        default:
            return '';
    }
};

/**
 * Returns a human readable string describing the discount target:
 * Eg:
 *  `on offers "A", "B", and "C"
 *   on category "D"
 *   on categories "A", "B", and "C"
 */
const getDiscountTargetStr = (
    target: DISCOUNT_TARGET,
    lineItemNames: string[],
    targetItemNames: string[],
    lineItemType: LineItemType | undefined,
    targetItemType: LineItemType | undefined,
): string => {
    switch (target) {
        case DISCOUNT_TARGET.LINE_ITEMS_BEFORE_TAX:
            return `${i18n.t('generic.on')} ${getLineItemTypeStr(
                lineItemType,
                lineItemNames.length,
            )} ${getItemNamesFormattedStr(lineItemNames, true)}`;
        case DISCOUNT_TARGET.SPECIFIC_LINE_ITEM_BEFORE_TAX:
            return `${i18n.t('generic.on')} ${getLineItemTypeStr(
                targetItemType,
                targetItemNames.length,
            )} ${getItemNamesFormattedStr(targetItemNames, true)}`;
        case DISCOUNT_TARGET.TOTAL_BEFORE_TAX:
            return i18n.t('pricingAndFees.offOfTotal').toString();
        default:
            return '';
    }
};

/**
 * Given a list of line item names and whether they are all required returns a human readable string
 * `allRequired` parameter will prefix the last name with 'and'.
 * When it is false the prefix of the last name will be 'or'
 * Eg:
 *      `"A", "B", and "C"` //allRequired = true
 *      `"A"`
 *      `"A" or "B"` //allRequired = false
 */
const getItemNamesFormattedStr = (names: string[], allRequired: boolean): string => {
    names = [...names];
    if (names.length > 1) {
        names[names.length - 1] =
            (allRequired ? i18n.t('generic.and') : i18n.t('generic.or')) + ' ' + names[names.length - 1];
    }
    return names.join(', ');
};

/**
 * Returns a human readable spending condition string
 * If the operator or target are undefined then we return "" to avoid any confusion
 * Eg:
 *      `more than 100 is spent on line items before tax`
 *      `atleast 50 is spent in total before tax`
 */
const getSpendingConditionStr = (
    amount: number | undefined,
    operator: COMPARISON_OP | undefined,
    target: TARGET | undefined,
): string => {
    if (operator == null || target == null) {
        return '';
    }
    const operatorStr = comparisonOpToStr(operator);
    const amountStr = amount ? String(amount) : 'X';
    const targetStr = spendingTargetToStr(target);
    return [operatorStr, amountStr, i18n.t('pricingAndFees.isSpent').toString(), targetStr].join(' ');
};

/**
 * Returns a human readable version of the comparison operator
 */
const comparisonOpToStr = (operator: COMPARISON_OP | undefined): string => {
    switch (operator) {
        case COMPARISON_OP.GT:
            return i18n.t('generic.moreThan').toString().toLowerCase();
        case COMPARISON_OP.GT_OR_EQ:
            return i18n.t('generic.atLeast').toString().toLowerCase();
        case COMPARISON_OP.LT:
            return i18n.t('generic.lessThan').toString();
        case COMPARISON_OP.LT_OR_EQ:
            return i18n.t('generic.atMost').toString();
        case COMPARISON_OP.EQ:
            return i18n.t('generic.exactly').toString();
        default:
            return '';
    }
};

/**
 * Returns a human readable version of the spending target
 */
const spendingTargetToStr = (target: TARGET | undefined): string => {
    switch (target) {
        case TARGET.LINE_ITEMS_BEFORE_TAX:
            return `${i18n.t('generic.on')} ${i18n.t('pricingAndFees.lineItemsBeforeTax')}`;
        case TARGET.TOTAL_BEFORE_TAX:
            return `${i18n.t('generic.in')} ${i18n.t('pricingAndFees.totalBeforeTax')}`;
        default:
            return '';
    }
};
/**
 * Returns a human readable version of the line item type.
 * Will pluralize based on number of items being considered
 * Eg: `offer`, `offers`, `category` or `categories`
 */
const getLineItemTypeStr = (lineItemType: LineItemType | undefined, numberOfItems: number): string => {
    if (!lineItemType) {
        return '';
    }
    switch (lineItemType) {
        case ENTITY_TYPES.OFFER:
            return i18n.tc('productCatalog.entities.offer', numberOfItems).toString().toLocaleLowerCase();
        case ENTITY_TYPES.CATEGORY:
            return (
                numberOfItems > 1
                    ? i18n.t('productCatalog.entities.plural.categories')
                    : i18n.t('productCatalog.entities.category')
            )
                .toString()
                .toLocaleLowerCase();
        default:
            return '';
    }
};

/**
 * Returns a human readable string indicating which line items need to be purchased for the pricing rule to execute
 * Returns empty string if no line items are given.
 */
const getLineItemsPurchasedStr = (
    lineItemNames: string[],
    lineItemType: LineItemType | undefined,
    purchaseAllLineItems: boolean,
): string => {
    const numLineItems = lineItemNames.length;
    if (numLineItems === 0) {
        return '';
    } else if (numLineItems === 1) {
        return `${i18n.t('pricingAndFees.purchasing')} ${getLineItemTypeStr(lineItemType, numLineItems)} ${
            lineItemNames[0]
        }`;
    }
    const anyOrAllStr = purchaseAllLineItems ? i18n.t('generic.all').toString().toLowerCase() : i18n.t('generic.any');
    return `${i18n.t('pricingAndFees.purchasing')} ${anyOrAllStr} ${i18n.t(
        'pricingAndFees.ofTheFollowing',
    )} ${getLineItemTypeStr(lineItemType, numLineItems)}: ${getItemNamesFormattedStr(
        lineItemNames,
        purchaseAllLineItems,
    )}`;
};

/**
 * Returns human readable string of the line item quantity condition
 * Returns an empty string if operator is undefined to avoid generating a partially filled (and confusing string)
 */
const getLineItemQuantityConditionStr = (quantity: number | undefined, operator: COMPARISON_OP | undefined): string => {
    if (operator == null) {
        return '';
    }
    const quantityStr = quantity ? String(quantity) : 'X';
    return [
        i18n.t('pricingAndFees.purchasing'),
        comparisonOpToStr(operator),
        quantityStr,
        i18n.t('pricingAndFees.lineItems').toString().toLocaleLowerCase(),
    ].join(' ');
};

const getMinLineAndSubscriptionConditionStr = (quantity: number | undefined): string => {
    if (quantity == null) {
        return '';
    }
    return `${i18n.t('pricingAndFees.numberOfLineAndExistingItemsIsAtleast')} ${quantity}`;
};
