
import Vue from 'vue';

import { CUSTOM_CONDITION_OPERATOR_TO_LABEL_MAP } from '@/__new__/services/dno/charging/common/customConditionHelper';

export default Vue.extend({
    name: 'CustomConditionValue',
    props: {
        value: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            CUSTOM_CONDITION_OPERATOR_TO_LABEL_MAP,
        };
    },
    methods: {},
});
