<template>
    <div class="list p-1">
        <div
            v-for="(section, index) in lists"
            :key="index"
            class="section container-fluid"
        >
            <ListDropdownSection
                :section="section"
                :capitalizedTitles="capitalizedTitles"
                :alwaysExpanded="alwaysExpanded"
                :useBigRowGaps="useBigRowGaps"
                :collapse="collapse"
                :isNewDesignSelected="isNewDesignSelected"
                @showDialog="emitShowDialog"
            />
        </div>
    </div>
</template>

<script>
import ListDropdownSection from './ListDropdownSection.vue';

export default {
    name: 'OverviewList',
    components: {
        ListDropdownSection,
    },
    props: {
        lists: {
            type: Array,
            required: true,
        },
        collapse: {
            type: Boolean,
            default: false,
        },
        isNewDesignSelected: {
            type: Boolean,
            default: false,
        },
        alwaysExpanded: {
            type: Boolean,
            default: false,
        },
        useBigRowGaps: {
            type: Boolean,
            default: false,
        },
        capitalizedTitles: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        emitShowDialog(type) {
            this.$emit('showDialog', type);
        },
    },
};
</script>

<style lang="scss" scoped>
.list {
    margin-bottom: 16px;
}

.section {
    padding-left: 0;
}
</style>
