import { EntityStateMapping, EntityActions } from '@/common/commonEntityStateMapper';

export { EntityStateMapping, entityStateReverseMap } from '@/common/commonEntityStateMapper';

export const StateActionsMapping = {
    [EntityStateMapping.UNAPPROVED]: [
        EntityActions.APPROVE,
        EntityActions.EDIT,
        EntityActions.DELETE,
        EntityActions.CLONE,
    ],
    [EntityStateMapping.APPROVED]: [EntityActions.DELETE, EntityActions.CLONE, EntityActions.EDIT],
    [EntityStateMapping.PAUSED]: [],
    [EntityStateMapping.DELETED]: [EntityActions.CLONE],
};
