<template>
    <div
        ref="table"
        :class="[{ 'no-box-shadow': disableBoxShadow }, { 'round-border': tableTabs }, { 'h-100': useFullWidth }]"
        class="app-table"
        :style="cssProps"
    >
        <div
            v-if="(isSearchEnabled && !isInnerSearchQueryPassed) || tableTitle"
            class="d-flex justify-content-between table-upper-content-wrapper"
        >
            <div class="table-title lf-subtitle">
                {{ tableTitle }}
            </div>
            <SearchBox
                v-if="isSearchEnabled && !isInnerSearchQueryPassed"
                v-model="searchQuery"
                class="table-search-box"
            />
        </div>
        <div
            v-if="multiselectEnabled"
            class="d-flex justify-content-between table-second-upper-content-wrapper"
        >
            <div class="lf-table-title select-all-title">
                {{ $i18n.t('generic.rowsSelected') }} ({{ selectedRows.length }})
            </div>
            <div v-if="selectedRows.length > 0">
                <AppButton
                    v-for="option in multiselectActions"
                    :key="option.label"
                    :ref="option.refName"
                    :buttonType="BUTTON_TYPES.PRIMARY"
                    :iconType="option.buttonIcon"
                    :label="option.label"
                    class="title-button ml-3"
                    @click="emitClickEvent(option)"
                />
            </div>
        </div>
        <table
            :class="{ bordered: enableBorder }"
            class="table-wrapper d-flex flex-column position-relative"
        >
            <div
                v-if="canSelectColumns && isSelectColumnDropdownOpened"
                v-click-outside="closeSelectColumnDropdownOpened"
                class="select-column-dropdown"
            >
                <template v-if="columnsDataAvailableToHide.length > 0">
                    <AppCheckbox
                        v-for="(option, index) in columnsDataAvailableToHide"
                        :key="index"
                        :labelLeft="formatColumnName(option.name)"
                        :value="selectedColumns.includes(option.name)"
                        :justifyCenter="true"
                        class="filter-checkbox"
                        @input="changeSelectedColumns(option.name, $event)"
                    />
                </template>
                <template v-else>
                    <div class="empty-list-label">
                        {{ $i18n.t('table.dropdownEmpty') }}
                    </div>
                </template>
            </div>
            <AppSpinner :isVisible="isDataLoading" />
            <div
                ref="scroll-wrapper"
                :class="[
                    'table-scroll-wrapper',
                    {
                        'show-border-fade': displayBorderFade,
                        'has-entities': entitiesToDisplay.length > 0,
                    },
                ]"
            >
                <div class="scroll-content-wrapper">
                    <div
                        class="scroll-content"
                        :style="{
                            'min-width': `${estimatedMinColumnWidth * columnsDataFiltered.length}px`,
                        }"
                    >
                        <div
                            v-if="isDraggingHandle"
                            :style="{ left: offsetForVerticalLine + 3 + 'px' }"
                            class="vertical-line"
                        />
                        <thead
                            ref="tableHeader"
                            class="row no-gutters w-100 table-header transparent-bg"
                            :class="{ 'multiselect-enabled': multiselectEnabled }"
                        >
                            <td v-if="multiselectEnabled">
                                <AppCheckbox
                                    class="select-all-check"
                                    :small="true"
                                    @input="onSelectAllRows"
                                />
                            </td>
                            <Draggable
                                v-model="columnsDataFiltered"
                                :move="canReorder"
                                handle=".column-title"
                                class="draggable-wrap"
                            >
                                <td
                                    v-for="(column, index) in columnsDataFiltered"
                                    :key="column.name"
                                    :class="[{ 'pr-5': index + 1 === columnsDataFiltered.length }]"
                                    :style="getStyles(column.width)"
                                    class="table-header-cell d-flex align-items-center all-caps-text justify-content-between truncate-text pl-4 position-relative"
                                    @mouseover="setHoveredColumn(index)"
                                    @mouseleave="setHoveredColumn(null)"
                                >
                                    <div
                                        v-if="column.tooltipText"
                                        class="info-icon cursor-pointer mr-2"
                                        :title="column.tooltipText"
                                    />
                                    <div
                                        :class="{ draggable: enableDragging }"
                                        class="column-title truncate-text"
                                    >
                                        {{ formatColumnName(column.name) }}
                                    </div>
                                    <div>
                                        <TableSortButton
                                            v-if="shouldDisplaySortButton(column, index)"
                                            :class="{
                                                'margin-right': index + 1 === columnsDataFiltered.length,
                                            }"
                                            :initSortType="getColumnSortType(column.key)"
                                            @sortEntities="onSortEntities(column, $event)"
                                        />
                                        <div
                                            draggable="true"
                                            class="resize-handle"
                                            @dragend="handleDragEnd($event, column)"
                                            @drag="handleDrag"
                                        />
                                    </div>
                                </td>
                            </Draggable>
                            <AppIcon
                                v-if="canSelectColumns"
                                :type="ICON_TYPES.PLUS"
                                :color="ICON_COLORS.BLUE"
                                class="cursor-pointer select-filters"
                                @click="toggleSelectColumnDropdownOpened"
                            />
                        </thead>
                        <tbody
                            v-if="!isUpdatingContent"
                            class="table-content-container"
                            :style="{ paddingBottom: entitiesToDisplay.length > 0 ? '1.25rem' : '0rem' }"
                        >
                            <AppRow
                                v-for="entity in entitiesToDisplay"
                                :key="entity[keyName]"
                                :entity="entity"
                                :columnsData="columnsDataFiltered"
                                :selectedEntity="selectedEntityId === entity[keyName]"
                                :isDefaultHoverEnabled="isDefaultHoverEnabled"
                                :showDetailsByDefault="showDetailsByDefault"
                                :controlsOffset="controlsOffset"
                                class="table-content"
                                :isCheckboxEnabled="multiselectEnabled"
                                :isChecked="isSelectedRow(entity)"
                                :nestedRows="nestedRows"
                                :alwaysShowRowButtons="alwaysShowRowButtons"
                                @edited="editEntity(entity)"
                                @deleted="deleteEntity(entity)"
                                @clicked="selectEntity(entity)"
                                @mouseOverColumn="setHoveredColumn"
                                @rowSelected="e => onRowSelected(entity[idColumn])"
                                @toggleShowDetails="
                                    (entity, detailVisibility) => $emit('toggleShowDetails', entity, detailVisibility)
                                "
                            >
                                <template
                                    v-for="column in columnsDataFiltered"
                                    :slot="column.key"
                                >
                                    <div
                                        v-if="$scopedSlots[column.key]"
                                        :key="column.key"
                                    >
                                        <slot
                                            :name="column.key"
                                            :entity="entity"
                                        />
                                    </div>
                                </template>
                                <template #customRowButtons>
                                    <RowStateControls
                                        v-if="enableRowStateControls"
                                        :entity="entity"
                                        :entityType="entityType"
                                        :emitEntityActions="emitEntityActions"
                                        :allowedActionsExternal="entity.allowedActionsExternal"
                                        :entityActionToHrefFunction="entityActionToHrefFunction"
                                        @edit="$emit('edit', $event)"
                                        @delete="$emit('delete', $event)"
                                        @stop="$emit('stop', $event)"
                                        @start="$emit('start', $event)"
                                        @clone="$emit('clone', $event)"
                                        @editOrder="$emit('editOrder', $event)"
                                        @cancelEditOrder="$emit('cancelEditOrder')"
                                        @approve="$emit('approve', $event)"
                                        @pause="$emit('pause', $event)"
                                        @details="$emit('details', $event)"
                                        @sendEvent="$emit('sendEvent', $event)"
                                        @readonly="$emit('readonly', $event)"
                                        @download="$emit('download', $event)"
                                    />
                                    <slot
                                        :entity="entity"
                                        name="customRowButtons"
                                    />
                                </template>
                                <template
                                    v-if="$scopedSlots.entityDetailContent"
                                    slot="details"
                                >
                                    <slot
                                        :entity="entity"
                                        name="entityDetailContent"
                                    />
                                </template>
                            </AppRow>
                        </tbody>
                    </div>
                </div>
            </div>
            <div class="table-content">
                <template v-if="!isDataLoading || hasEntities">
                    <div
                        v-if="!hasEntities || !hasEntitiesToDisplay"
                        class="empty-table-row d-flex flex-row justify-content-center align-items-center"
                    >
                        <img
                            src="@/assets/icons/empty-table.svg"
                            class="empty-table-row-icon"
                        />
                        <div
                            v-if="!hasEntities"
                            class="empty-table-row-text"
                        >
                            {{ noEntitiesMockText || $i18n.t('table.empty') }}
                        </div>
                        <div
                            v-else-if="!hasEntitiesToDisplay"
                            class="empty-table-row-text"
                        >
                            {{ $i18n.t('table.noMatch') }}
                        </div>
                    </div>
                </template>
            </div>
            <template v-if="displayPagination">
                <slot
                    name="customPaginationComponent"
                    :pagination="pagination"
                    :entitiesAfterSearchApplied="entitiesAfterSearchApplied"
                >
                    <AppPaginationV3
                        v-model="pagination.currentPage"
                        :entitiesAmount="entitiesAfterSearchApplied.length"
                        :entitiesPerPage="pagination.entitiesPerPage.value"
                        :amountOptions="pagination.showPerPageOptions"
                        class="w-100"
                        @changeAmountPerPage="onEntitiesPerPageChange"
                        @paginated="$emit('paginated', pagination)"
                        @reachedLastPage="val => $emit('reachedLastPage', val)"
                    />
                </slot>
            </template>

            <div
                v-if="$slots.tableFooter"
                class="table-footer mt-3 pb-3 pr-3"
            >
                <slot name="tableFooter" />
            </div>
        </table>
    </div>
</template>

<script>
// libraries

import Draggable from 'vuedraggable';
import { orderBy } from 'lodash';
import Fuse from 'fuse.js';
// components

import AppPaginationV3 from '@/components/partials/AppPaginationV3.vue';
import SearchBox from '@/components/partials/inputs/SearchBox.vue';
import AppRow from '@/components/partials/AppRow.vue';
import AppIcon from '@/components/partials/icon/AppIcon.vue';
import AppCheckbox from '@/components/partials/inputs/AppCheckbox.vue';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';

import AppSpinner from '@/components/partials/AppSpinner.vue';
import TableSortButton from '@/components/partials/table/TableSortButton.vue';
import RowStateControls from '@/components/partials/table/RowStateControls.vue';
// helpers

import LocalStorageHelper from '@/common/LocalStorageHelper';
import vClickOutside from 'v-click-outside';
import { formatValueForDisplaying, displayNaIfNotProvided } from '@/common/formatting';
import { getDeepKeys, onlyFirstLetterUppercase } from '@/common/utils';
import { ICON_TYPES, ICON_COLORS } from '@/common/iconHelper';

export const tableSizes = [
    {
        label: 'Show 5',
        value: 5,
    },
    {
        label: 'Show 10',
        value: 10,
    },
    {
        label: 'Show 25',
        value: 25,
    },
    {
        label: 'Show 50',
        value: 50,
    },
    {
        label: 'Show 100',
        value: 100,
    },
];

export default {
    name: 'AppTable',
    components: {
        Draggable,
        SearchBox,
        AppCheckbox,
        AppButton,
        AppRow,
        AppIcon,
        AppSpinner,
        TableSortButton,
        AppPaginationV3,
        RowStateControls,
    },
    directives: {
        clickOutside: vClickOutside.directive,
    },
    props: {
        /**
         * See documentation for property `entityActionToHrefFunction` in the RowStateControls component
         */
        entityActionToHrefFunction: {
            type: Object,
            required: false,
            default: () => ({}),
        },
        showDetailsByDefault: {
            type: Boolean,
            default: false,
        },
        useExternalPagination: {
            type: Boolean,
            default: false,
        },
        /**
         * Array<Object>
         * @param name string column label,
         * @param key string column key for reading value from entities,
         * @param mapper function for mapping value,
         * @param formatter function for formatting `entity[key]`,
         * @param type String for sorting entities,
         * @param classes Array<String> for adding classes to column cells,
         * @param width String to set up column width,
         * @param forbidHideColumn Boolean,
         * @param sortBy String for sorting entities by different key,
         * @param initiallyHidden Boolean to indicate that column shouldn't be visible initially,
         * but will be still available under + icon dropdown
         * FYI: columns are ordered on the UI the same way u order them fot this prop
         */
        columnsData: {
            type: Array,
            default: () => [],
        },
        entities: {
            type: [Array, Object],
            default: () => [],
        },
        selectedEntityId: {
            type: null,
            required: false,
            default: null,
        },
        keyName: {
            type: String,
            default: 'id',
        },
        /**
         * This key is used to help store any user modifications
         * to the table (hiding columns, re-arranging) in Local Storage
         * Isn't required but definitely is recommended for new tables
         */
        tableKey: {
            type: String,
            default: '',
        },
        isSearchEnabled: {
            type: Boolean,
            default: false,
        },
        // passing the range for pagination
        rangeAmount: {
            type: Object,
            default: () => ({
                start: 1,
                end: 10,
            }),
        },
        tableTitle: {
            type: String,
            default: '',
        },
        isPaginationEnabled: {
            type: Boolean,
            default: false,
        },
        tableTabs: {
            type: Boolean,
            default: false,
        },
        /**
         * Specify which properties of the input entities
         * will be searched on. If not provided will default
         * to searching on `entityKeys`.
         */
        searchKeys: {
            type: Array,
            default: null,
        },
        /**
         * Indicates which depth of keys will be used for searching
         * given a list of entities.
         * For example given an entity:
         * {
         *      "foo": "bar",
         *      "baz": {
         *          "foo": "bar",
         *          "bar": "baz"
         *      }
         * }
         *
         * A depth of 1 will produce:
         *      ["foo", "baz"]
         * A search on these keys will not search on the child elements of "baz"
         *
         * A depth of 2 will produce:
         *      ["foo", "baz.foo", "baz.bar"]
         * A search with these keys will search on "baz.foo" and "baz.bar".
         */
        entityKeyDepth: {
            type: Number,
            default: 1,
        },
        innerSearchQuery: {
            type: String,
            default: null,
        },
        disableBoxShadow: {
            type: Boolean,
            default: false,
        },
        enableBorder: {
            type: Boolean,
            default: false,
        },
        isDefaultHoverEnabled: {
            type: Boolean,
            default: true,
        },
        /**
         * If set to true, loading indicator is displayed and table content is blocked
         * Set this value to true when data is being fetched or loaded and revert
         * it back to false once you're done.
         * */
        isDataLoading: {
            type: Boolean,
            default: false,
        },
        canSelectColumns: {
            type: Boolean,
            default: true,
        },
        canSort: {
            type: Boolean,
            default: true,
        },
        columnsSort: {
            type: Boolean,
            default: true,
        },
        /**
         * Object
         * @param key - column key,
         * @param sortBy - value key or function to form value used to sort by,
         * @param type - sorting type: 'asc' or 'desc',
         *
         * @example
         * {
         *     key: 'key',
         *     sortBy: entity => entity.sortByKey,
         *     type: 'desc'
         * }
         */
        defaultSort: {
            type: [Object, Array],
            default: null,
        },
        requestEntities: {
            type: Boolean,
            default: false,
        },
        rowHovered: {
            type: Boolean,
            default: false,
        },
        /**
         * Value from ENTITY_TYPES enum which indicates the type of the entity
         * Used primarily to determine behavior for row controls
         */
        entityType: {
            type: String,
            default: '',
        },
        /**
         * Determines if row state controls are enabled
         * Row state controls are buttons that appear at
         * the right most side of a row when a user hovers
         * over the row
         */
        enableRowStateControls: {
            type: Boolean,
            default: false,
        },
        enableDragging: {
            type: Boolean,
            default: true,
        },
        estimatedMinColumnWidth: {
            type: Number,
            default: 150,
        },
        noEntitiesMockText: {
            type: String,
            default: '',
        },
        tableWrapperHeight: {
            type: String,
            default: '100%',
        },
        // temporary solution in order to support 2 PC pages for each entity type
        emitEntityActions: {
            type: Boolean,
            default: false,
        },
        useFullWidth: {
            type: Boolean,
            default: true,
        },
        alwaysShowPagination: {
            type: Boolean,
            default: false,
        },
        multiselectEnabled: {
            type: Boolean,
            default: false,
        },
        idColumn: {
            type: String,
            default: null,
        },
        multiselectActions: {
            type: Array,
            default: () => [],
        },
        /**
         * If enabled, it will render nested data.
         * Specifically this means a table cell for one row can have nested rows within it.
         *
         * If this flag is enabled the following conditions must be met:
         * 1. Your entity needs to have a nestedRows key.
         *    Eg: entity: {
         *        ...
         *        nestedRows: [
         *            { firstName: 'foo', lastName: 'bar' },
         *            { firstName: 'baz', lastName: 'qux' },
         *        ]
         *    }
         * 2. Your columnsData for the AppTable must define columns for the data in nestedRows.
         *    Eg: columnsData: [
         *        ...
         *        { key: 'firstName', label: 'First Name' },
         *        { key: 'lastName', label: 'Last Name' },
         *    ]
         *
         * Result: In the table cell, nested rows will be displayed similar to:
         * |------------|------------|------------|
         * | id         | First Name | Last Name  |
         * |------------|------------|------------|
         * | 1          | foo        | bar        |
         * |            | ---------- | ---------- |
         * |            | baz        | qux        |
         * |------------|------------|------------|
         * | 2          | ...        | ...        |
         * |            | ---------- | ---------- |
         * |            | ...        | ...        |
         * |------------|------------|------------|
         */
        nestedRows: {
            type: Boolean,
            default: false,
        },
        // used as helper when we already have content in table to remove current content
        // in order to show spinner while fetching more data from BE
        isUpdatingContent: {
            type: Boolean,
            default: false,
        },
        alwaysShowRowButtons: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            searchQuery: '',
            hoveredColumnIndex: null,
            hoveredRowIndex: null,
            selectedColumns: [],
            pagination: {
                currentPage: 1,
                entitiesPerPage: tableSizes[1],
                showPerPageOptions: tableSizes,
            },
            sortingConfig: [],
            distance: 0,
            isDraggingHandle: false,
            offsetForVerticalLine: 0,
            columnsDataFormatted: this.columnsData,
            displayBorderFade: false,
            isSelectColumnDropdownOpened: false,
            selectedRows: [],
            controlsOffset: 0,

            ICON_TYPES,
            ICON_COLORS,
            BUTTON_TYPES,
        };
    },
    computed: {
        entitiesAfterSearchApplied() {
            if (this.isSearchEnabled && this.searchQuery) {
                const filtered = this.fuseSearch(this.fuse);

                return !this.nestedRows ? filtered : this.filterNestedRows(filtered);
            }
            return this.entitiesMappedAndFormatted;
        },
        entitiesKeyDepth() {
            return !this.nestedRows ? this.entityKeyDepth : 2;
        },
        fuseSearchOptions() {
            return {
                keys: this.fuseSearchKeys,
                threshold: 0.3,
                ignoreLocation: true,
            };
        },
        fuse() {
            return new Fuse(this.entitiesMappedAndFormatted, this.fuseSearchOptions);
        },
        fuseSearchKeys() {
            if (this.colonSearchQuery) {
                return this.entityKeys;
            }
            if (this.searchKeys) {
                return this.searchKeys;
            }
            return this.entityKeys;
        },
        /**
         * If `searchQuery` is in the form of `<key>:<value>` expressions seperated by spaces
         * `colonSearchQuery` will be a fusejs search pattern containing the aforementioned
         * expressions.
         * Eg: `searchQuery` = 'color:red shape:circle'
         *      =>
         *      `colonSearchQuery` = `{$and: {'color': 'red'}, {'shape': 'circle'}}`
         *
         * If the `searchQuery` isn't in the colon search format, then `colonSearchQuery` is null.
         *
         * Notes:
         *      1. `key` and `value`s in expression must be quotation(") wrapped
         *          if they contain whitespace characters.
         *      2. Each successive expression is joined logically with AND.
         *         See: https://fusejs.io/api/query.html#and
         */
        colonSearchQuery() {
            // Find matches with colon syntax:
            /**
             *  Regex breakdown:
             *   \S+     one or more non-whitespace characters (eg: properties.dimensions.width)
             *   "(.*?)" capture any sequence of characters within quotes
             *           () capture is within quotes so that the quotes aren't matched
             *           ?  non-greedy to prevent incorrect matches with quotes
             *              incorrect match example: "color":"red" "shape":square can capture
             *              'color\":\"red" \"shape' and 'square'
             */
            const regex = /("(.*?)"|\S+):("(.*?)"|\S+)/g;
            const matches = [...this.searchQuery.matchAll(regex)];

            // No matches return null
            if (matches.length === 0) {
                return null;
            }

            // Compute expressions
            const expressions = matches.map(match => {
                // Prioritize matches within quotations
                // quotation matches are represented by match[2] and match[4]
                const key = match[2] || match[1];
                const value = match[4] || match[3];
                return { [key]: value };
            });

            return { $and: expressions };
        },
        /**
         * Gets all keys on our entity list at the depth of `entityKeyDepth`.
         * For example given an entity list `[{a: 1, b: 2, c: 3}, {d: {e: 1, f: 2}}]`
         * and an `entityKeyDepth` of 2, `entityKeys` will be
         * `['a', 'b', 'c', 'd.e', 'd.f']`
         * If nestedRows is enabled, entityKeyDepth should be set to 2.
         */
        entityKeys() {
            return this.entitiesMappedAndFormatted.reduce((sum, entity) => {
                getDeepKeys(entity, this.entitiesKeyDepth).forEach(key => {
                    if (!sum.includes(key)) {
                        sum.push(key);
                    }
                });
                return sum;
            }, []);
        },
        entitiesToDisplay() {
            let resultArray = this.sortedEntitiesToDisplay;
            if (this.useExternalPagination) {
                resultArray = resultArray.slice(this.rangeAmount.start - 1, this.rangeAmount.end);
            }
            if (this.isPaginationEnabled) {
                const pageFirstElementIndex = this.pagination.entitiesPerPage.value * (this.pagination.currentPage - 1);
                const pageLastElementIndex = pageFirstElementIndex + this.pagination.entitiesPerPage.value;
                resultArray = resultArray.slice(pageFirstElementIndex, pageLastElementIndex);
            }
            return resultArray;
        },
        entitiesMappedAndFormatted() {
            return Object.values(this.entities).map(entity => {
                const formattedEntity = { ...entity };
                this.columnsDataFormatted.forEach(column => {
                    if (!this.$scopedSlots[column.key] && column.key !== 'nestedRows') {
                        formattedEntity[column.key] = this.formatEntityCell(formattedEntity, column);
                    }
                });

                return formattedEntity;
            });
        },
        entitiesAmount() {
            return Object.values(this.entities).length;
        },
        hasEntities() {
            return !!this.entitiesAmount;
        },
        hasEntitiesToDisplay() {
            return !!this.entitiesToDisplay.length;
        },
        isInnerSearchQueryPassed() {
            // we have to consider that '' is equal to false in js,
            // but it's valid prop value for us
            return this.innerSearchQuery === '' || this.innerSearchQuery;
        },
        columnsDataFiltered: {
            get() {
                if (this.canSelectColumns) {
                    return this.columnsDataFormatted.filter(key => this.selectedColumns.includes(key.name));
                }
                return this.columnsDataFormatted;
            },
            set(value) {
                const columnNames = value.map(col => col.name);
                LocalStorageHelper.set(`table-${this.tableKey}`, JSON.stringify(columnNames));
                this.columnsDataFormatted = value;
            },
        },
        columnNames() {
            return this.columnsDataFormatted.map(key => key.name);
        },
        columnsDataAvailableToHide() {
            return this.columnsDataFormatted.filter(key => !key.forbidHideColumn);
        },
        sortedEntitiesToDisplay() {
            const sortBys = this.sortingConfig.map(item => item.sortBy);
            const types = this.sortingConfig.map(item => item.type); // asc or desc

            if (this.nestedRows) {
                if (!types.length && !this.searchQuery) {
                    return this.entities;
                }

                this.entitiesAfterSearchApplied.forEach(row => {
                    if (row.nestedRows) {
                        row.nestedRows = orderBy(row.nestedRows, sortBys, types);
                    }
                });
            }

            return orderBy(this.entitiesAfterSearchApplied, sortBys, types);
        },
        cssProps() {
            return {
                '--table-wrapper-height': this.tableWrapperHeight,
            };
        },
        displayPagination() {
            return (
                this.hasEntitiesToDisplay &&
                this.isPaginationEnabled &&
                !this.useExternalPagination &&
                (this.entitiesAfterSearchApplied.length > this.pagination.showPerPageOptions[0].value ||
                    this.alwaysShowPagination)
            );
        },
    },
    watch: {
        sortedEntitiesToDisplay() {
            if (this.isPaginationEnabled && !this.useExternalPagination) {
                // reset pagination to first page if currentPage > total number of pages
                const perPage = this.pagination.entitiesPerPage.value;
                const pagesCount = Math.ceil(this.entitiesAfterSearchApplied.length / perPage);
                if (this.pagination.currentPage > pagesCount) {
                    this.pagination.currentPage = 1;
                }
            }
        },
        innerSearchQuery: {
            handler(newVal) {
                if (this.isSearchEnabled) {
                    this.searchQuery = newVal;
                }
            },
            immediate: true,
        },
        requestEntities() {
            const cleanEntities = this.sortedEntitiesToDisplay.map(entity => {
                const newEntity = {};
                this.columnsDataFiltered.forEach(column => {
                    newEntity[column.name] = displayNaIfNotProvided(entity[column.key]);
                });
                return newEntity;
            });
            this.$emit('returnCleanEntities', cleanEntities);
        },
        entitiesToDisplay() {
            this.$emit('input', this.entitiesAfterSearchApplied);
        },
        defaultSort: {
            immediate: true,
            handler(val) {
                if (val) {
                    if (Array.isArray(val)) {
                        for (let i = 0; i < val.length; i += 1) {
                            this.onSortEntities({ key: val[i].key, sortBy: val[i].sortBy }, val[i].type);
                        }
                    } else {
                        this.onSortEntities({ key: val.key, sortBy: val.sortBy }, val.type);
                    }
                }
            },
        },
    },
    created() {
        const tableStorageData = LocalStorageHelper.get(`table-${this.tableKey}`);
        if (tableStorageData) {
            this.selectedColumns = JSON.parse(tableStorageData);
            if (this.columnsSort) {
                this.columnsDataFormatted = this.columnsData.sort(
                    (a, b) => tableStorageData.indexOf(a.name) - tableStorageData.indexOf(b.name),
                );
            }
        } else {
            this.selectedColumns = this.columnsDataFormatted
                .filter(column => !column?.initiallyHidden)
                .map(col => col.name);
        }
        const tableColumnWidths = LocalStorageHelper.get(`table-column-widths-${this.tableKey}`);
        if (tableColumnWidths) {
            const columnWidths = JSON.parse(tableColumnWidths);
            for (const key in columnWidths) {
                if (columnWidths[key]) {
                    const column = this.columnsDataFormatted.find(col => col.key === key);
                    if (column) {
                        column.width = columnWidths[key];
                    }
                }
            }
        }
        // MAKE SURE THE TABLE COLUMNS HAVE WIDTHS AND THEY ADD UP TO 100%
        this.columnsDataFormatted.forEach(col => {
            if (!col.width) {
                col.width = Math.round(100 / this.columnsDataFormatted.length);
            }
        });
        const totalWidth = this.columnsDataFiltered.reduce((acc, col) => acc + col.width, 0);

        this.columnsDataFiltered.forEach(col => {
            col.width = (col.width * 100) / totalWidth;
        });

        setInterval(() => {
            if (!this.isDataLoading && this.$el) {
                const tableElement = this.$el.getElementsByClassName('scroll-content-wrapper')[0];
                if (tableElement.scrollWidth > tableElement.scrollLeft + tableElement.clientWidth) {
                    this.displayBorderFade = true;
                } else {
                    this.displayBorderFade = false;
                }
            }
        }, 100);

        // Proper offset for RowStateControlsCalculation
        setInterval(() => {
            const scrollWrapperEl = this.$refs['scroll-wrapper'];
            this.controlsOffset = scrollWrapperEl && scrollWrapperEl.scrollLeft ? -scrollWrapperEl.scrollLeft : 0;
        }, 16);
    },
    methods: {
        shouldDisplaySortButton(column, index) {
            const isCurrentlySorting = this.sortingConfig.findIndex(cfg => cfg.key === column.key);
            return this.canSort && (this.hoveredColumnIndex === index || isCurrentlySorting === 0);
        },
        setHoveredColumn(index) {
            if (this.hoveredColumnIndex === index) {
                this.hoveredColumnIndex = null;
            }
            this.hoveredColumnIndex = index;
        },
        selectEntity(entity) {
            this.$emit('selectEntity', entity[this.keyName]);
            // Extending this because there is no reason to not respond with whole entity and not just ID
            this.$emit('selectedEntityModel', entity);
        },
        onSortEntities(column, type) {
            let sortBy;
            // TO MAKE SORT NOT CASE SENSITIVE FOR GENERAL_TEXT TYPE
            if (column.filterType && column.filterType.sortBy && !column.sortBy) {
                sortBy = column.filterType.sortBy(column.field ? column.field : column.key);
            } else {
                sortBy = column.sortBy || column.key;
            }
            this.sortingConfig = this.sortingConfig.filter(c => c.key !== column.key);
            if (type) {
                this.sortingConfig.unshift({ key: column.key, sortBy, type });
            }
        },
        formatEntityCell(entity, column) {
            if (column.formatter) {
                if (column.mapper) {
                    return formatValueForDisplaying(column.formatter(column.mapper(entity)));
                }
                return formatValueForDisplaying(column.formatter(entity[column.key], entity));
            }
            if (column.mapper) {
                return formatValueForDisplaying(column.mapper(entity));
            }
            return formatValueForDisplaying(entity[column.key]);
        },
        fuseSearch(fuseInstance) {
            const searchQuery = this.colonSearchQuery || this.searchQuery;
            return fuseInstance.search(searchQuery).map(({ item }) => item);
        },
        filterNestedRows(entities) {
            return entities.map(item => {
                if (item.nestedRows?.length) {
                    const nestedFuse = new Fuse(item.nestedRows, this.fuseSearchOptions);
                    const nestedRows = this.fuseSearch(nestedFuse);

                    return {
                        ...item,
                        nestedRows,
                    };
                }
                return item;
            });
        },
        onEntitiesPerPageChange(newVal) {
            if (newVal.value !== this.pagination.value) {
                this.pagination.currentPage = 1;
            }
            this.pagination.entitiesPerPage = newVal;
            this.$emit('updatePageSize', this.pagination.entitiesPerPage.value);
        },
        getStyles(width) {
            const currentWidth = width || 100 / this.columnsDataFiltered.length;
            // return `flex-basis: ${currentWidth};max-width: ${currentWidth};`;
            return `width: ${currentWidth}%`;
        },
        getColumnSortType(sortKey) {
            if (!this.sortingConfig.length || this.sortingConfig[0].key !== sortKey) {
                return '';
            }
            return this.sortingConfig[0].type;
        },
        formatColumnName(name) {
            return name ? onlyFirstLetterUppercase(name) : '';
        },
        handleDrag(event) {
            const { clientX, offsetX } = event;
            this.offsetForVerticalLine = clientX - this.$refs.table.getClientRects()[0].left;
            this.distance = (offsetX / this.$refs.tableHeader.clientWidth) * 100;
            this.isDraggingHandle = true;
        },
        handleDragEnd(event, column) {
            const { clientX, offsetX } = event;
            this.offsetForVerticalLine = clientX - this.$refs.table.getClientRects()[0].left;
            this.distance = (offsetX / this.$refs.tableHeader.clientWidth) * 100;

            const { left, right, top, bottom } = this.$refs.table.getClientRects()[0];

            const X = event.clientX;
            const Y = event.clientY;

            const isInsideTable = X > left && X < right && Y > top && Y < bottom;
            if (isInsideTable) {
                this.updateWidths(column, this.distance);
                this.distance = 0;
            }

            this.isDraggingHandle = false;
            this.offsetForVerticalLine = null;
        },
        changeSelectedColumns(columnName, checked) {
            const optionIndexInSelected = this.selectedColumns.indexOf(columnName);
            const column = this.columnsDataFormatted.find(col => col.name === columnName);
            const w = column.width;
            if (checked) {
                // rescale other columns so that new column can be inserted
                this.columnsDataFiltered.forEach(col => {
                    col.width *= 1 - w / 100;
                });
                this.selectedColumns.push(columnName);
            } else {
                // Rebalance the width of a column being removed
                // so it doesn't get inserted as a huge column later
                const factor = this.columnsDataFiltered.length / this.columnsDataFormatted.length;
                column.width = w * factor;
                this.selectedColumns.splice(optionIndexInSelected, 1);
                // rescale other columns to fit screen
                this.columnsDataFiltered.forEach(col => {
                    col.width = (col.width * 100) / (100 - w);
                });
            }
            LocalStorageHelper.set(`table-${this.tableKey}`, JSON.stringify(this.selectedColumns));
            this.saveColumnWidths();
        },
        canReorder() {
            // Disable column reordering if nestedRows are present
            // due to complexity of the displayed data.
            return !this.nestedRows;
        },
        updateWidths(column, diff) {
            let widthPercentageDiff = diff;
            const columnWidth = column.width;
            let widthAfter = columnWidth + widthPercentageDiff;

            const { columnsDataFiltered } = this;
            const colIndex = columnsDataFiltered.indexOf(column);

            let numOfColsToTheRight = 0;
            // calculating number of columns to the right of the changed one
            columnsDataFiltered.forEach(col => {
                if (columnsDataFiltered.indexOf(col) > colIndex) {
                    numOfColsToTheRight += 1;
                }
            });
            // make sure the width of the column is at least 5% after resize
            if (widthAfter <= 5) {
                widthPercentageDiff = widthPercentageDiff > 0 ? columnWidth - 5 : 5 - columnWidth;
            }
            // make sure width of each other column is at least 5% after resize
            let oppositeWidthDiff = -widthPercentageDiff;
            let diffPerColumn = oppositeWidthDiff / numOfColsToTheRight;
            columnsDataFiltered.forEach((col, index) => {
                if (index > colIndex) {
                    const currColWidth = col.width;
                    widthAfter = currColWidth + diffPerColumn;
                    if (widthAfter <= 5) {
                        widthPercentageDiff = widthPercentageDiff > 0 ? currColWidth - 5 : 5 - currColWidth;
                    }
                }
            });

            oppositeWidthDiff = -widthPercentageDiff;
            diffPerColumn = oppositeWidthDiff / numOfColsToTheRight;
            this.columnsDataFiltered.forEach((col, index) => {
                if (col === column) {
                    col.width += widthPercentageDiff;
                } else if (index > colIndex) {
                    col.width += diffPerColumn;
                }
            });

            this.saveColumnWidths();
        },
        saveColumnWidths() {
            const columnWidths = {};
            this.columnsDataFormatted.forEach(col => {
                columnWidths[col.key] = col.width;
            });

            LocalStorageHelper.set(`table-column-widths-${this.tableKey}`, JSON.stringify(columnWidths));
        },
        toggleSelectColumnDropdownOpened() {
            this.isSelectColumnDropdownOpened = !this.isSelectColumnDropdownOpened;
        },
        closeSelectColumnDropdownOpened() {
            this.isSelectColumnDropdownOpened = false;
        },
        isSelectedRow(entity) {
            return this.selectedRows.indexOf(entity[this.idColumn]) > -1;
        },
        onRowSelected(idColumn) {
            const existingItem = this.selectedRows.indexOf(idColumn);
            if (existingItem > -1) {
                this.selectedRows.splice(existingItem, 1);
            } else {
                this.selectedRows.push(idColumn);
            }
        },
        onSelectAllRows(isChecked) {
            if (isChecked) {
                this.selectedRows = this.entitiesToDisplay.map(x => x[this.idColumn]);
            } else {
                this.selectedRows = [];
            }
        },
        emitClickEvent(action) {
            this.$emit('multiselectActionClick', { action, selectedRows: this.selectedRows });
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/palette';
@import '~@/assets/scss/base';
@import '~@/assets/scss/icons';
@import '~@/assets/scss/animations';
@import '~@/assets/scss/typographyV2';
@import '~@/assets/scss/z-indexes';

$icon-path: '~@/assets/icons/';

.info-icon {
    width: 1rem;
    height: 1rem;
    content: url($icon-path + $info-grey);

    &:hover {
        content: url($icon-path + $info-blue);
    }
}

.no-box-shadow {
    box-shadow: none;
}

.table-wrapper {
    padding-bottom: 16px;
    min-height: 100px;
    height: var(--table-wrapper-height);

    &.bordered {
        border: 1px solid $dirty-white;
    }
}

.table-header {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: $overlap-smth-z-index;
    white-space: nowrap;
    // JUST HEIGHT DON'T WORK IN SAFARI
    min-height: 42px;

    border-bottom: solid 1px #f1f1f1;
    border-top: solid 1px #f1f1f1;
    background-color: transparent;
}

.table-header-cell {
    transition: width 0.1s ease 0s;
    padding-right: 10px;
}

.table-header-cell:hover {
    border-right: solid 1px #f1f1f1;
    border-left: solid 1px #f1f1f1;
}

.table-upper-content-wrapper {
    background: $white;
    padding: 6px 1rem;
}
.table-second-upper-content-wrapper {
    background: $white;
    padding: 6px 1rem;
}
.table-title {
    color: $gray60;
}

.table-search-box {
    width: 15rem;
}

.table-footer {
    padding-left: 24px;

    .footer-button {
        margin-right: 12px;

        &:last-child {
            margin-right: 0;
        }
    }
}

.table-content {
    .empty-table-row {
        text-align: center;
        background-color: $white;
        border-bottom: 1px solid $dirty-white;
        padding-top: 3rem;
        padding-bottom: 3rem;
    }
}

th {
    font-weight: inherit;
}

thead {
    position: relative;

    th:last-child .col-12 {
        padding-right: 40px;
    }
}

.select-filters {
    position: absolute;
    top: 30%;
    right: 0.75rem;
    width: 1.75rem;
    height: 1.75rem;
}

.select-column-dropdown {
    position: absolute;
    top: 2.625rem;
    right: 0;
    border-radius: 0.25rem;
    border: none;
    box-shadow: 0 0.25rem 0.5rem 0 rgba(51, 81, 149, 0.3);
    background-color: $white;
    z-index: $icon-button-z-index;
    max-height: 25rem;
    overflow: auto;

    .filter-checkbox {
        padding: 0.625rem 0.875rem 0.625rem 1.5rem;

        &:hover {
            background-color: $dirty-white;
        }
    }

    .empty-list-label {
        padding: 0.625rem 1.5rem;
        font-size: $text-sm;
    }
}

.cursor-pointer {
    cursor: pointer;
}

.column-title {
    max-width: 100%;

    &.draggable {
        cursor: move;
    }
}

.resize-handle {
    background-color: transparent;
    width: 4px;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
}

.resize-handle:hover {
    cursor: col-resize;
    border-right: 2px solid $blue;
}

.resize-handle:active {
    opacity: 0;
}

.vertical-line {
    position: absolute;
    top: 0px;
    bottom: 0px;
    width: 2px;
    background-color: #f1f1f1;
    z-index: $overlap-smth-z-index;
}

.vertical-line:hover {
    cursor: col-resize;
}

.margin-right {
    margin-right: 18px;
}

.row-hovered:hover {
    background-color: $dirty-white;
}

.draggable-wrap {
    display: flex;
    width: 100%;
}

.multiselect-enabled {
    .draggable-wrap {
        width: calc(100% - 2rem);
    }
}

.app-table {
    display: flex;
    flex-direction: column;

    .table-header {
        background: $white;
    }

    .table-content:last-of-type ::v-deep .entity-row {
        border-radius: 0 0 0.5rem 0.5rem;
    }

    .round-border {
        .table-header {
            border-radius: 0.5rem 0.5rem 0 0;
        }
    }

    .empty-table-row-text {
        opacity: 0.5;
        font-size: 1rem;
        color: $blue;
        width: fit-content;
        font-weight: 600;
    }

    .empty-table-row-icon {
        margin-right: 2rem;
        width: 100px;
        height: 100px;
    }
}
.select-all-check {
    padding: 0.4rem 0.313rem 0.313rem;
}
.select-all-title {
    padding-bottom: 10px;
    padding-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.table-scroll-wrapper {
    position: relative;
    overflow: auto;

    &.show-border-fade::after {
        content: '';
        position: sticky;
        top: 0;
        right: 0;
        width: 50px;
        height: calc(100% - 1.875rem);
        background: linear-gradient(to left, $white 75%, transparent);
    }

    &.has-entities {
        &.show-border-fade::after {
            height: calc(100% - 3.125rem);
        }

        .table-content-container {
            height: 100%;
            overflow: auto;
            padding-bottom: 1.25rem;
        }
    }

    .scroll-content-wrapper {
        height: 100%;

        .scroll-content {
            display: flex;
            flex-direction: column;
        }

        &::-webkit-scrollbar {
            height: 30px;
            width: 30px;
        }

        &::-webkit-scrollbar-track {
            border-radius: 100px;
            box-shadow: inset 0 0 4px 4px #eaedf0;
            border: solid 13px transparent;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 100px;
            box-shadow: inset 0 0 4px 4px #cdd0d7;
            border: solid 13px transparent;
        }

        &::-webkit-scrollbar-corner {
            background-color: transparent;
        }
    }
}
</style>
