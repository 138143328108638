<template>
    <AbstractListPageWrapper
        :pageTitle="$i18n.t('generic.events')"
        :isOverviewEnabled="isOverviewEnabled"
        :entitiesCount="formattedTableEntitiesLength"
        class="page-wrapper overflow-hidden"
        @searchQueryChanged="setSearchQuery"
    >
        <template slot="button">
            <div class="d-flex">
                <ResponseModalButton
                    :response="eventsApiResponse"
                    :title="$i18n.t('generic.events')"
                />
                <ImportEntitiesModalButton
                    v-if="isUserAllowed('EventsWrite')"
                    :entityType="entityType"
                    @finishImport="loadData"
                />
            </div>
        </template>

        <template slot="filterTable">
            <FilterTable
                :columns="tableColumnsData"
                :multiselectWidth="{ width: '15rem' }"
                @filterAdded="onFilterAdded"
            />
        </template>

        <template slot="headerButtons">
            <AppButton
                v-if="isUserAllowed('EventsWrite')"
                :buttonType="BUTTON_TYPES.PRIMARY"
                :iconType="ICON_TYPES.PLUS"
                :label="$i18n.t('generic.addNew')"
                data-test-id="events-dash-add-new"
                @click="navigateToCreateEvent"
            />
        </template>

        <template slot="allFilters">
            <TableFiltersTags
                :filterTableMixin="filterTableMixin"
                class="my-3 ml-2"
                @removeFilter="removeFilter"
                @removeAllFilters="removeAllFilters"
            />
        </template>

        <template slot="table">
            <EventsEmitter
                v-if="isSendEventOpen"
                :event="selectedEntity"
                @close="closeAll"
            />
            <AppTable
                v-else
                data-test="events-table"
                :entities="formattedFilteredEntities"
                :selectedEntityId="selectedEntityId"
                :columnsData="tableColumnsData"
                :canSelectColumns="false"
                :enableRowStateControls="isUserAllowed('EventsWrite')"
                :canSort="true"
                :isDataLoading="isDataLoading"
                tableKey="events"
                :defaultSort="defaultSort"
                :isSearchEnabled="true"
                :innerSearchQuery="searchQueryForTable"
                :entityType="entityType"
                @selectEntity="openEntityOverview"
                @delete="confirmEventDeletion"
                @edit="goToEditPage"
                @details="id => $refs.DetailsJsonModal.display(eventsById[id])"
                @sendEvent="openSendEvent"
                @input="setTableEntitiesLength"
            >
                <!-- TODO to change state to {entity} and to change status to entity.state after BE will send it -->
                <template #state="{}">
                    <EventStatusIndicator :status="eventStatuses.PUBLISHED" />
                </template>
            </AppTable>
            <DetailsJsonModal ref="DetailsJsonModal" />
        </template>

        <template slot="overview">
            <EventOverview
                :event="selectedEntity"
                @closeOverview="closeAll"
            />
        </template>
    </AbstractListPageWrapper>
</template>

<script>
// components
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import AbstractListPageWrapper from '@/components/layout/AbstractListPageWrapper.vue';
import AppTable from '@/components/partials/AppTable.vue';
import FilterTable from '@/components/partials/FilterTable.vue';
import FilterTableMixin from '@/components/partials/FilterTableMixin.vue';
import Button from '@/common/button/Button';
import EventOverview from '@/__new__/features/events/EventOverview.vue';
import EventsEmitter from '@/__new__/features/events/EventsEmitter.vue';
import EventStatusIndicator from '@/components/partials/EventStatusIndicator.vue';
import TableFiltersTags from '@/components/filters/TableFiltersTags.vue';
import ResponseModalButton from '@/components/partials/ResponseModalButton.vue';
import ImportEntitiesModalButton from '@/components/partials/ImportEntitiesModalButton.vue';
import DetailsJsonModal from '@/components/partials/DetailsJsonModal.vue';

// helpers
import { ALERT_TYPES } from '@/common/alerts/Alert';
import RouteNames from '@/router/routeNames';
import ENTITY_TYPES from '@/common/entities/entityTypes';
import tableColumnType from '@/common/filterTable';
import { createNamespacedHelpers } from 'vuex';
import Actions, { Getters } from '@/store/mutation-types';
import * as Sentry from '@sentry/vue';
import { eventStatuses, getAllDataFromEventField } from '@/common/events/eventsHelper';
import { keyBy } from 'lodash';
import { ICON_TYPES } from '@/common/iconHelper';
import { isUserAllowed } from '@/services/permissions/permissions.service';
import { PLURALIZATION } from '@/common/locale/labelSingularOrPlural';

const { mapGetters, mapActions } = createNamespacedHelpers('events');

export default {
    name: 'EventsDashboard',
    components: {
        DetailsJsonModal,
        AbstractListPageWrapper,
        AppButton,
        AppTable,
        FilterTable,
        EventOverview,
        EventStatusIndicator,
        TableFiltersTags,
        ResponseModalButton,
        ImportEntitiesModalButton,
        EventsEmitter,
    },
    mixins: [FilterTableMixin],
    data() {
        return {
            ICON_TYPES,
            BUTTON_TYPES,
            searchQueryForTable: '',
            entityType: ENTITY_TYPES.EVENT,
            selectedEntityId: null,
            eventStatuses,
            isOverviewEnabled: false,
            isSendEventOpen: false,
            isDataLoading: false,
            formattedTableEntitiesLength: 0,
        };
    },
    computed: {
        ...mapGetters({
            events: Getters.GET_MAPPED_EVENTS,
            eventsApiResponse: Getters.GET_ALL_EVENTS_API_RESPONSE,
        }),
        tableColumnsData() {
            return [
                {
                    name: this.$i18n.t('generic.name'),
                    key: 'name',
                    field: 'name',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('generic.type'),
                    key: 'type',
                    field: 'type',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('customerCare.products'),
                    key: 'producerProducts',
                    field: 'producerProducts',
                    filterType: tableColumnType.TEXT_LIMITED_OPTIONS_CONTAINS,
                    limitedOptions: getAllDataFromEventField(this.events, 'producerProducts'),
                },
                {
                    name: this.$tc('generic.label', PLURALIZATION.PLURAL),
                    key: 'labels',
                    field: 'labels',
                    filterType: tableColumnType.TEXT_LIMITED_OPTIONS_CONTAINS,
                    limitedOptions: getAllDataFromEventField(this.events, 'labels'),
                },
            ];
        },
        formattedFilteredEntities() {
            return this.filteredEntitiesMixin(this.events);
        },
        defaultSort() {
            return {
                sortBy: entity => entity.updateTime,
                type: 'desc',
            };
        },
        eventsById() {
            return keyBy(this.events, entity => entity.id);
        },
        selectedEntity() {
            return this.eventsById[this.selectedEntityId];
        },
    },

    created() {
        this.loadData();
    },

    methods: {
        setTableEntitiesLength({ length }) {
            this.formattedTableEntitiesLength = length;
        },
        ...mapActions([
            Actions.LOAD_ALL_EVENTS,
            Actions.LOAD_EVENT_DATA_SOURCES,
            Actions.LOAD_EVENT_REQUIRED_PROPERTIES,
        ]),
        openEntityOverview(id) {
            this.selectedEntityId = id;
            this.isOverviewEnabled = true;
        },
        openSendEvent(id) {
            this.selectedEntityId = id;
            this.isOverviewEnabled = true;
            this.isSendEventOpen = true;
        },
        closeAll() {
            this.selectedEntityId = null;
            this.isOverviewEnabled = false;
            this.isSendEventOpen = false;
        },
        goToEditPage(id) {
            this.$router.push({
                name: RouteNames.EVENTS_EDITOR,
                params: { id, companyId: this.$route.params.companyId },
            });
        },

        async loadData() {
            try {
                this.isDataLoading = true;
                this.$Progress.start();
                const promises = [
                    this[Actions.LOAD_EVENT_DATA_SOURCES](),
                    this[Actions.LOAD_EVENT_REQUIRED_PROPERTIES](),
                ];
                await Promise.all(promises);
                await this[Actions.LOAD_ALL_EVENTS]();

                if (this.$route.params?.id) {
                    this.openEntityOverview(this.$route.params.id);
                }
            } catch (e) {
                this.$alert(this.$i18n.t('events.alerts.failedToLoadAllEvents'));
                this.$Progress.fail();
                Sentry.captureException(e);
            }
            this.isDataLoading = false;
            this.$Progress.finish();
        },
        navigateToCreateEvent() {
            this.$router.push({ name: RouteNames.EVENTS_EDITOR, params: { companyId: this.$route.params.companyId } });
        },
        setSearchQuery(query) {
            this.searchQueryForTable = query;
        },
        confirmEventDeletion(id) {
            const entityName = this.eventsById[id]?.name || this.$i18n.t('generic.entity');
            const version = this.eventsById[id]?.version;
            const eventType = this.eventsById[id]?.eventType;
            this.$alert(this.$i18n.t('alerts.areYouSureDeleteEntity', { entityName }), {
                type: ALERT_TYPES.warning,
                buttons: [
                    new Button({
                        label: this.$i18n.t('generic.delete'),
                        alertType: ALERT_TYPES.warning,
                        handler: () => this.deleteEvent(id, eventType, version),
                    }),
                ],
            });
        },
        async deleteEvent(id, eventType, version) {
            try {
                this.$Progress.start();
                await this.$store.dispatch(`events/${Actions.DELETE_EVENT}`, { id, event_type: eventType, version });
                this.$alert(
                    this.$i18n.t('alertMessage.successMessageWithoutRedirect', {
                        entityName: this.$i18n.t('productCatalog.services.periodTypes.event'),
                        action: this.$i18n.t('generic.stateMap.deleted').toLowerCase(),
                    }),
                    { type: ALERT_TYPES.success },
                );
                this.$Progress.finish();
            } catch (err) {
                Sentry.captureException(err);
                this.$Progress.fail();
                this.$alert(this.$i18n.t('alertMessage.cep.errorDeletingEvent'));
            }
        },
        isUserAllowed,
    },
};
</script>

<style lang="scss" scoped>
// Fix double scrollbars from AbstractListPageWrapper
.page-wrapper {
    height: 100vh !important;
}
</style>
