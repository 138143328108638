import type DataflowSectionModel from '@/__new__/services/dno/dataflows/models/DataflowSectionModel';
import type DataflowElement from '@/__new__/services/dno/dataflows/models/DataflowElement';

export function createDataflowApiPayloadData(
    sections: DataflowSectionModel[],
    initSectionId?: string,
): Record<string, string | number> {
    let payloadData: Record<string, string | number> = {};
    function getObjectPath(sectionId: string, entity: DataflowSectionModel | DataflowElement): string {
        return `${sectionId}.${entity.id}`;
    }

    sections.forEach((section: DataflowSectionModel) => {
        const sectionId = initSectionId ? getObjectPath(initSectionId, section) : section.id;

        if (section.enabled) {
            section.elements.forEach(data => {
                if (data.isSection) {
                    payloadData = {
                        ...payloadData,
                        ...createDataflowApiPayloadData([data as DataflowSectionModel], sectionId),
                    };
                } else {
                    const elData = (data as DataflowElement).getValue;

                    if (elData) {
                        payloadData[getObjectPath(sectionId, data)] = elData;
                    }
                }
            });
        }
    });

    return payloadData;
}

export function convertDataflowNameOfId(name: string): string {
    return name.replace(/\s+/g, '_').toLowerCase();
}
