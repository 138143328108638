<template>
    <div>
        <template v-for="(field, index) in fields">
            <div
                :key="`${field.key}-wrapper`"
                :class="{ 'd-flex': enablePropertiesSelection }"
            >
                <AppCheckbox
                    v-if="enablePropertiesSelection"
                    :key="`${field.key}-checkbox`"
                    :value="checkIfFieldIsSelected(field.id)"
                    class="mr-4"
                    @input="$emit('propertySelected', field.id)"
                />
                <component
                    :is="getComponentByType(field.type)"
                    v-if="getComponentByType(field.type)"
                    :key="field.key"
                    v-model="valuesFormatted[field.key]"
                    v-bind="{
                        ...field.componentProps,
                        ...getStaticComponentProps(field.type),
                        [getInvalidPropKey(field.type)]: getIfFieldIsInvalid(field),
                        ...(disabled ? { disabled } : {}),
                    }"
                    :showValidation="showValidation"
                    :class="{ 'custom-component-width': enablePropertiesSelection }"
                    data-test-id="generator-component"
                    @input="[validateFields(), emitValues()]"
                />
            </div>

            <slot
                v-if="index + 1 !== fields.length"
                name="divider"
            />
        </template>
    </div>
</template>

<script>
import { FORM_BUILDER_TYPES } from '@/formBuilderConfig';
import AppCheckbox from '@/components/partials/inputs/AppCheckbox.vue';

export default {
    name: 'Generator',

    components: {
        AppCheckbox,
    },

    props: {
        showValidation: {
            type: Boolean,
            default: true,
        },
        fields: {
            type: Array,
            required: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        enablePropertiesSelection: {
            type: Boolean,
            default: false,
        },
        selectedPropertiesIds: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            valuesFormatted: {},
            fieldInvalidList: {},
            invalidFieldKeys: [],
        };
    },
    watch: {
        fields() {
            this.init();
        },
    },
    created() {
        this.init();
    },
    methods: {
        init() {
            this.initValues();
            this.validateFields();
            this.emitValues();
        },
        emitValues() {
            this.$emit('input', {
                value: { ...this.valuesFormatted },
                invalidKeys: [...this.invalidFieldKeys],
            });
        },
        getComponentByType(type) {
            return this.$lfFormBuilder.config[type]?.component;
        },
        getStaticComponentProps(type) {
            return this.$lfFormBuilder.config[type]?.staticComponentProps;
        },
        getInvalidPropKey(type) {
            return this.$lfFormBuilder.config[type]?.invalidKey;
        },
        getDefaultValueByType(type) {
            return this.$lfFormBuilder.config[type]?.defaultValue;
        },
        initValues() {
            this.valuesFormatted = this.fields.reduce((acc, field) => {
                let defaultValues = field.defaultValue;
                if (field.type === FORM_BUILDER_TYPES.REFERENCE && field.defaultValue) {
                    const values = field.defaultValue;
                    const option = field.componentProps.options;
                    defaultValues = option.filter(o => values.includes(o.id));
                }

                acc[field.key] =
                    Object.prototype.hasOwnProperty.call(field, 'defaultValue') && field.defaultValue !== undefined
                        ? defaultValues
                        : this.getDefaultValueByType(field.type);

                return acc;
            }, {});
        },
        validateFields() {
            const fieldInvalidList = {};
            const invalidFieldKeys = [];

            this.fields.forEach(({ validation, key }) => {
                const isValueValid = validation ? validation(this.valuesFormatted[key], this.valuesFormatted) : true;

                fieldInvalidList[key] = !isValueValid;

                if (!isValueValid) {
                    invalidFieldKeys.push(key);
                }
            });

            this.invalidFieldKeys = invalidFieldKeys;
            this.fieldInvalidList = fieldInvalidList;
        },
        checkIfFieldIsSelected(id) {
            return this.selectedPropertiesIds.includes(id);
        },
        getIfFieldIsInvalid(field) {
            return this.enablePropertiesSelection
                ? this.checkIfFieldIsSelected(field.id) && this.showValidation && this.fieldInvalidList[field.key]
                : this.showValidation && this.fieldInvalidList[field.key];
        },
    },
};
</script>

<style lang="scss" scoped>
.custom-component-width {
    width: calc(80% - 5rem);
}
</style>
