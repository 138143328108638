<template>
    <AppAditionalSidebar
        :visible="showSidebar"
        @input="val => hideSidebar(val)"
    >
        <template #header>
            <Breadcrumbs
                :breadcrumbList="breadcrumbList"
                :routeOnClick="false"
                data-test-id="additional-sidebar-order-entities-breadcrumbs"
            />
        </template>
        <template #content>
            <template>
                <div
                    v-if="selectedParentDelivery"
                    class="order-wrap"
                >
                    <template>
                        <div
                            v-if="selectedParentDelivery.recipientFullName"
                            class="modal-row d-flex align-items-center"
                        >
                            <div class="row-title">
                                {{ $i18n.t('customerCare.deliveries.recipientFullName') }}
                            </div>

                            <div class="row-value">
                                {{ selectedParentDelivery.recipientFullName }}
                            </div>
                        </div>

                        <div
                            v-if="selectedParentDelivery.recipientEmail"
                            class="modal-row d-flex align-items-center"
                        >
                            <div class="row-title">
                                {{ $i18n.t('customerCare.deliveries.recipientEmail') }}
                            </div>

                            <div class="row-value">
                                {{ selectedParentDelivery.recipientEmail }}
                            </div>
                        </div>

                        <div
                            v-if="selectedParentDelivery.recipientPhone"
                            class="modal-row d-flex align-items-center"
                        >
                            <div class="row-title">
                                {{ $i18n.t('customerCare.deliveries.recipientPhone') }}
                            </div>

                            <div class="row-value">
                                {{ selectedParentDelivery.recipientPhone }}
                            </div>
                        </div>

                        <div
                            v-if="selectedParentDelivery.reservationId"
                            class="modal-row d-flex align-items-center"
                        >
                            <div class="row-title">
                                {{ $i18n.t('customerCare.deliveries.reservationId') }}
                            </div>

                            <div class="row-value">
                                {{ selectedParentDelivery.reservationId }}
                            </div>
                        </div>

                        <div
                            v-if="selectedParentDelivery.address"
                            class="modal-row d-flex align-items-center"
                        >
                            <div class="row-title address-type">
                                {{ formattedAddress.name }}
                            </div>

                            <div class="row-value">
                                <div
                                    v-for="(addressLine, index) in formattedAddress.value"
                                    :key="index"
                                    class="address"
                                >
                                    {{ addressLine }}
                                </div>
                            </div>
                        </div>

                        <div class="modal-row d-flex align-items-center">
                            <div class="row-title">
                                {{ $i18n.t('generic.status') }}
                            </div>
                            <EntityStatusIndicator
                                v-if="!isUndefined(selectedParentDelivery.status)"
                                :stateMap="DELIVERY_STATES_TO_STATUS_NAME_MAP"
                                :stateMapColor="DELIVERY_STATUS_INDICATOR_MAP"
                                :status="selectedParentDelivery.status"
                                :isInTable="false"
                                class="row-value"
                            />
                        </div>
                    </template>
                    <template v-if="displayDeliveryPartnerResponse">
                        <div class="modal-row d-flex align-items-center mt-5">
                            <div class="all-caps-text">
                                {{ $i18n.t('customerCare.deliveries.deliveryPartnerResponse') }}
                            </div>
                        </div>
                        <div
                            v-for="(key, index) in Object.keys(selectedParentDelivery.deliveryPartnerResponse)"
                            :key="index"
                        >
                            <div
                                v-if="
                                    !isObject(selectedParentDelivery.deliveryPartnerResponse[key]) &&
                                    selectedParentDelivery.deliveryPartnerResponse[key]
                                "
                                class="modal-row d-flex align-items-center mt-4"
                            >
                                <div class="row-title">
                                    {{ formatDeliveryPartnersResponseLabel(key) }}
                                </div>

                                <div class="row-value">
                                    {{ selectedParentDelivery.deliveryPartnerResponse[key] }}
                                </div>
                            </div>
                            <div v-else-if="!isEmpty(selectedParentDelivery.deliveryPartnerResponse[key])">
                                <div class="modal-row d-flex align-items-center mt-4">
                                    <div class="all-caps-text">
                                        {{ formatDeliveryPartnersResponseLabel(key) }}
                                    </div>
                                </div>
                                <div
                                    v-for="(item, ind) in Object.keys(
                                        selectedParentDelivery.deliveryPartnerResponse[key],
                                    )"
                                    :key="ind"
                                    class="modal-row d-flex align-items-center"
                                >
                                    <div class="row-title">
                                        {{ formatDeliveryPartnersResponseLabel(item).toUpperCase() }}
                                    </div>

                                    <div class="row-value">
                                        {{ selectedParentDelivery.deliveryPartnerResponse[key][item] }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                </div>
            </template>
        </template>
    </AppAditionalSidebar>
</template>
<script>
// COMPONENTS
import AppAditionalSidebar from '@/components/partials/AppAditionalSidebar.vue';
import Breadcrumbs from '@/components/partials/Breadcrumbs.vue';
import EntityStatusIndicator from '@/components/partials/EntityStatusIndicator.vue';

// HELPERS
import {
    DELIVERY_STATES_TO_STATUS_NAME_MAP,
    DELIVERY_STATUS_INDICATOR_MAP,
} from '@/__new__/features/customerCare/account/common/deliveryStateHelper';
import { isUndefined, isEmpty } from 'lodash';
import { PLURALIZATION } from '@/common/locale/labelSingularOrPlural';
import { snakeCaseToCommonString } from '@/common/formatting';

export default {
    name: 'AdditionalSidebarDeliveries',
    components: {
        AppAditionalSidebar,
        Breadcrumbs,
        EntityStatusIndicator,
    },
    props: {
        selectedParentDelivery: {
            type: Object,
            default: null,
        },
        showAdditionalSidebar: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            DELIVERY_STATES_TO_STATUS_NAME_MAP,
            DELIVERY_STATUS_INDICATOR_MAP,
            breadcrumbListIds: {
                delivery: 1,
                deliveryPartner: 2,
            },
            isSidebarVisible: false,
        };
    },
    computed: {
        showSidebar() {
            return this.isSidebarVisible;
        },
        breadcrumbList() {
            return [
                {
                    name: this.$i18n.tc('customerCare.deliveries.delivery', PLURALIZATION.SINGULAR),
                    id: this.breadcrumbListIds.delivery,
                },
            ];
        },
        displayDeliveryPartnerResponse() {
            return (
                this.selectedParentDelivery.deliveryPartnerResponse &&
                !isEmpty(this.selectedParentDelivery.deliveryPartnerResponse)
            );
        },
        formattedAddress() {
            const addressInfo = {
                name: this.selectedParentDelivery.address.type,
                value: [],
            };

            const { unitNumber, address, houseNumber, city, state, zip, post, postCode } =
                this.selectedParentDelivery.address;

            const addressFirstLine = this.formatAddressLine([unitNumber, address, houseNumber]);

            if (addressFirstLine) {
                addressInfo.value.push(addressFirstLine);
            }

            const addressSecondLine = this.formatAddressLine([city, state, zip, post, postCode]);

            if (addressSecondLine) {
                addressInfo.value.push(addressSecondLine);
            }

            return addressInfo;
        },
    },
    watch: {
        // copy value of prop showAdditionalSidebar to isSidebarVisible data to avoid mutation prop error in console
        showAdditionalSidebar: {
            handler() {
                this.isSidebarVisible = this.showAdditionalSidebar;
            },
        },
    },
    methods: {
        isUndefined,
        isEmpty,
        hideSidebar(val) {
            this.isSidebarVisible = val;
            this.$emit('closeSidebar', this.isSidebarVisible);
        },
        formatDeliveryPartnersResponseLabel(label) {
            return snakeCaseToCommonString(label);
        },
        formatAddressLine(fieldsArray) {
            let line = '';
            fieldsArray.forEach(field => {
                if (field) {
                    if (line) {
                        line = `${line}, ${field}`;
                    } else {
                        line += field;
                    }
                }
            });
            return line;
        },
        isObject(object) {
            return typeof object === 'object' && object !== null;
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/palette';
@import '~@/assets/scss/base';

.auto-height {
    height: auto !important;
}

.order-wrap {
    margin-bottom: 1.75rem;
}

.modal-row {
    margin-bottom: 0.875rem;
}

.row-title {
    font-size: 0.75rem;
    font-weight: 600;
    width: 14rem;
}

.row-value {
    font-size: 0.875rem;
    width: 100%;
}

.address-type {
    height: 2.5rem;
}
</style>
