import { AxiosPromise } from 'axios';
import http from '@/http/index';

export interface ManualProxyRequest {
    body: ManualProxyRequestBody;
}

export interface ManualProxyRequestBody {
    servicePath: string;
    methodType: string;
    bodyContent: string;
    queryParams: string;
    requestClaims: string;
    useServiceToken: boolean;
}

export interface ManualProxyRequestResponse {
    proxyBody: string;
    proxyStatus: number;
    proxyHeaders: string;
}

export function manualProxyRequest(data: ManualProxyRequest): AxiosPromise<ManualProxyRequestResponse> {
    return http({
        method: 'POST',
        url: '/manualproxy/request',
        data,
    });
}
