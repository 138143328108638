var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',_vm._g({staticClass:"icon-button-container",class:{ disabled: _vm.disabled }},_vm.$listeners),[_c('a',{class:[
            `icon-button icon-button-${_vm.icon}`,
            {
                disabled: _vm.disabled,
                'active-icon': _vm.manualTriggerActiveIcon,
            },
        ],attrs:{"href":_vm.href},on:{"click":function($event){$event.preventDefault();return _vm.emitIconButtonClicked.apply(null, arguments)},"mouseenter":function($event){_vm.isHovered = true},"mouseleave":function($event){_vm.isHovered = false}}},[_c('AppIcon',{attrs:{"type":_vm.icon,"size":_vm.iconSize}})],1),_c('transition',{attrs:{"name":"slide-fade-in"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isHovered && _vm.label),expression:"isHovered && label"}],staticClass:"icon-tooltip lf-labels"},[_vm._v(" "+_vm._s(_vm.label)+" ")])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }