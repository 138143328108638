import isNumber from 'lodash/isNumber';
import { formatNumberForDisplay } from '@/common/utils';
import {
    DATA_DEFINITIONS,
    DATA_DEFINITION_IDS,
    DURATION_DEFINITIONS_SECONDS_MINUTES,
    DURATION_DEFINITION_IDS,
    MESSAGE_DEFINITIONS,
    UnitDefinition,
} from '@/common/formatting';

export enum TRANSACTION_SERVICE_CODES {
    DATA = 21,
    VOICE = 22,
    SMS = 23,
    MMS = 24,
}

export function formatUsageAmount(amount?: null | number, definition?: UnitDefinition): string {
    if (!isNumber(amount)) return '';
    if (!definition) return formatNumberForDisplay(amount);
    // round to 2 decimal points and convert back to Number to remove .00
    const calculatedAmount = Number((amount / definition.multiplier).toFixed(2));
    const label = calculatedAmount === 1 && definition.field ? definition.field : definition.label;
    return `${formatNumberForDisplay(calculatedAmount)} ${label}`;
}

type FormattedUsageAmountInput = {
    entity: any;
    rounded?: boolean;
    withLabel?: boolean;
};

type FormattedUsageAmount = null | number | string;

export function getFormattedUsageAmount({
    entity,
    rounded = false,
    withLabel = true,
}: FormattedUsageAmountInput): FormattedUsageAmount {
    if (!entity || !entity.service_code) return '';

    let value;
    let definition;

    switch (entity.service_code) {
        case TRANSACTION_SERVICE_CODES.DATA: {
            value = rounded ? entity.total_rounded_volume : entity.total_volume;
            definition = DATA_DEFINITIONS.find(def => def.id === DATA_DEFINITION_IDS.MEGABYTES);
            break;
        }
        case TRANSACTION_SERVICE_CODES.VOICE: {
            value = rounded ? entity.rounded_duration : entity.total_duration;
            definition = DURATION_DEFINITIONS_SECONDS_MINUTES.find(def => def.id === DURATION_DEFINITION_IDS.SECONDS);
            break;
        }
        case TRANSACTION_SERVICE_CODES.SMS:
        case TRANSACTION_SERVICE_CODES.MMS: {
            value = entity.total_units;
            [definition] = MESSAGE_DEFINITIONS;
            break;
        }
        default:
            break;
    }
    if (!withLabel) {
        return value;
    }
    return formatUsageAmount(value, definition);
}

export function getUsageUnitLabel(serviceCode: TRANSACTION_SERVICE_CODES): string {
    switch (serviceCode) {
        case TRANSACTION_SERVICE_CODES.DATA:
            return DATA_DEFINITIONS.find(def => def.id === DATA_DEFINITION_IDS.BYTES)?.label ?? '';
        case TRANSACTION_SERVICE_CODES.VOICE:
            return (
                DURATION_DEFINITIONS_SECONDS_MINUTES.find(def => def.id === DURATION_DEFINITION_IDS.SECONDS)?.label ??
                ''
            );
        case TRANSACTION_SERVICE_CODES.SMS:
        case TRANSACTION_SERVICE_CODES.MMS:
            return MESSAGE_DEFINITIONS[0].label;
        default:
            return '';
    }
}

type TransactionHistoryUsageTotals = {
    total_usage: FormattedUsageAmount;
    total_rounded_usage: FormattedUsageAmount;
    total_usage_amount: FormattedUsageAmount;
    total_rounded_usage_amount: FormattedUsageAmount;
    total_usage_type: string;
};

export function getUsageTotals(entity: any): TransactionHistoryUsageTotals {
    return {
        total_usage: getFormattedUsageAmount({ entity }),
        total_rounded_usage: getFormattedUsageAmount({ entity, rounded: true }),
        total_usage_amount: getFormattedUsageAmount({ entity, withLabel: false }),
        total_rounded_usage_amount: getFormattedUsageAmount({ entity, rounded: true, withLabel: false }),
        total_usage_type: getUsageUnitLabel(entity.service_code),
    };
}

export enum NAME_OF_TRANSACTION_HISTORY_TABLE {
    PAYMENT = 'payment',
    BILLING = 'billing',
}
