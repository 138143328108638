import { AxiosPromise } from 'axios';
import http, { DnoResponseBase } from '@/http/index';
import { WalletCounter } from '@/__new__/services/dno/charging/models/ChargingInterfaces';

export interface WalletCounterApiResponse extends DnoResponseBase {
    wallet_counter_by_id: Record<string, any>;
    usage_counter_by_id?: Record<string, any>;
}

/**
 * Get wallet counters.
 *
 * @returns {AxiosPromise}
 */
export function getWalletCounters(
    ids: string[] = [],
    returnChargingVersion = true,
    includeReferencedEntities = false,
    includePortalId = true,
    chargingVersion?: string,
): AxiosPromise<WalletCounterApiResponse> {
    return http({
        method: 'POST',
        url: '/v3charging/getwalletcounters',
        data: {
            ids,
            return_charging_version: returnChargingVersion,
            include_referenced_entities: includeReferencedEntities,
            charging_version: chargingVersion,
            include_portal_id: includePortalId,
        },
    });
}

/**
 * Add wallet counter.
 *
 * @returns {AxiosPromise}
 */
export function addWalletCounter(data: any): AxiosPromise<any> {
    return http({
        method: 'POST',
        url: '/v3chargingwrite/addwalletcounter',
        data,
    });
}

/**
 * Update wallet counter.
 *
 * @returns {AxiosPromise}
 */
export function updateWalletCounter(id: string, version: number, data: WalletCounter): AxiosPromise<WalletCounter> {
    return http({
        method: 'POST',
        url: '/v3chargingwrite/updatewalletcounter',
        data: {
            id,
            version,
            data,
        },
    });
}

/**
 * Update wallet counter state.
 *
 * @returns {AxiosPromise}
 */
export function updateWalletCounterState(id: string, version: number, state: number): AxiosPromise<WalletCounter> {
    return http({
        method: 'POST',
        url: '/v3chargingwrite/updatestatewalletcounter',
        data: {
            id,
            version,
            state,
        },
    });
}

interface WalletCountersDraftListApiResponse extends DnoResponseBase {
    data: any;
}

/**
 * Get wallet counter draft list.
 *
 * @returns {AxiosPromise}
 */
export function getWalletCountersDraftList(): AxiosPromise<WalletCountersDraftListApiResponse> {
    return http({
        method: 'POST',
        url: '/v3charging/getwalletcountersdraftlist',
    });
}

/**
 * Get wallet counter entity draft.
 *
 * @returns {AxiosPromise}
 */
export function getWalletCounterEntityDraft(id: string): AxiosPromise<DnoResponseBase> {
    return http({
        method: 'POST',
        url: '/v3charging/getwalletcounterdraft',
        data: {
            id,
        },
    });
}

/**
 * Set wallet counter draft.
 *
 * @returns {AxiosPromise}
 */
type WalletCounterDraftPayload = {
    id?: string;
    data: object;
};

export function setWalletCounterDraft(data: WalletCounterDraftPayload): AxiosPromise<DnoResponseBase> {
    return http({
        method: 'POST',
        url: '/v3charging/setwalletcounterdraft',
        data,
    });
}

/**
 * Delete wallet counter draft.
 *
 * @returns {AxiosPromise}
 */
export function deleteWalletCounterDraft(id: string): AxiosPromise<DnoResponseBase> {
    return http({
        method: 'POST',
        url: '/v3charging/deletewalletcounterdraft',
        data: {
            id,
        },
    });
}

export default {
    getWalletCounters,
    addWalletCounter,
    updateWalletCounter,
    updateWalletCounterState,
    getWalletCountersDraftList,
    getWalletCounterEntityDraft,
    setWalletCounterDraft,
    deleteWalletCounterDraft,
};
