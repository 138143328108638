<template>
    <div class="d-flex flex-column">
        <div class="d-flex flex-row">
            <div class="tabs-header row no-gutters flex-grow-1">
                <div
                    v-for="tabIndex in tabsCount"
                    :key="tabIndex"
                    :class="[
                        'tab-header px-3',
                        {
                            selected: selectedTabIndex === tabIndex,
                            disabled: disabledTabIndexes.includes(tabIndex),
                            col: isHeaderFullWidth,
                        },
                    ]"
                    @click="selectTab(tabIndex)"
                >
                    <slot :name="`tabHeader${tabIndex}`" />
                </div>
            </div>
            <div class="d-flex flex-row justify-content-end align-items-center">
                <slot :name="`tabHeaderButtons${selectedTabIndex}`" />
            </div>
        </div>
        <div class="tab-content flex-grow-1 d-flex flex-column">
            <div
                v-for="tabIndex in tabsCount"
                v-show="selectedTabIndex === tabIndex"
                :key="tabIndex"
                class="tab flex-grow-1 flex-column"
            >
                <div class="d-flex flex-column flex-grow-1 overview-container">
                    <div
                        :class="{ 'px-3': !disableSectionPaddings }"
                        class="sections-container d-flex flex-column flex-grow-1"
                    >
                        <div
                            v-for="sectionIndex in sectionsCount"
                            :key="sectionIndex"
                            :class="{ 'mt-4': !disableSectionPaddings }"
                            class="section"
                        >
                            <div
                                :class="{ 'mb-2': enableHeaderMargin }"
                                class="section-header font-weight-bold text-uppercase"
                            >
                                <slot :name="`tab${tabIndex}_sectionHeader${sectionIndex}`" />
                            </div>

                            <div class="d-flex section-content">
                                <slot :name="`tab${tabIndex}_sectionContent${sectionIndex}`" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'AppTabsV2',
    props: {
        sectionsCount: { type: Number, default: 0 },
        tabsCount: { type: Number, default: null },
        disableSectionPaddings: { type: Boolean, default: false },
        isHeaderFullWidth: { type: Boolean, default: false },
        disabledTabIndexes: { type: Array, default: () => [] },
        statisticsComponents: { type: Array, default: () => [] },
        relyOnInnerTabIndex: { type: Boolean, default: false },
        innerTabIndex: { type: Number, default: 1 },
        enableHeaderMargin: { type: Boolean, default: true },
    },
    data() {
        return {
            selectedTabIndex: 1, // 1-based index
        };
    },
    watch: {
        innerTabIndex: {
            immediate: true,
            handler(newVal) {
                // tab indexes start from 1, so we decline everything lees
                // then 1 and falsy values
                if (this.relyOnInnerTabIndex && Number(newVal) > 0) {
                    this.selectedTabIndex = newVal;
                }
            },
        },
    },
    methods: {
        selectTab(index) {
            if (!this.disabledTabIndexes.includes(index) && !this.relyOnInnerTabIndex) {
                this.selectedTabIndex = index;
            }
            this.$emit('tabSelected', index);
        },
    },
};
</script>

<style lang="scss" scoped>
@import '../../assets/scss/base';
@import '../../assets/scss/icons';
@import '../../assets/scss/palette';

$icons-path: '../../assets/icons/';

.tabs-header {
    .tab-header {
        height: 2.625rem;
        display: flex;
        align-items: center;
        justify-content: center;
        text-transform: uppercase;
        font-size: 0.75rem;
        font-weight: 600;
        line-height: 2.17;
        color: $gray30;
        cursor: pointer;

        &:hover {
            color: $gray-blue;
        }

        &.disabled {
            color: $gray5;

            span {
                opacity: 0.4;
            }
        }

        &.selected {
            position: relative;
            background-color: $white;
            color: $gray-blue;
            border-radius: 0.5rem 0.5rem 0 0;
        }

        span {
            height: 22px;
            width: 22px;
            margin-right: 5px;
        }
    }
}

.tab-content {
    .tab {
        display: flex;
        flex-grow: 1;

        .chart-placeholder:before {
            content: url($icons-path + $chart);
        }

        .placeholder {
            width: 30%;
            min-width: 125px;
            color: $gray5;
            font-size: 14px;
            text-align: center;
        }
    }
}

.overview-container {
    .section-header {
        color: $gray5;
        font-size: 13px;
    }
}
</style>
