
import Vue from 'vue';
import { TranslateResult } from 'vue-i18n';

// vuex
import { mapGetters, mapActions } from 'vuex';
import Actions, { Getters } from '@/store/mutation-types';
import { Modules } from '@/store/store';

// Components
import AbstractEditPageWrapper from '@/components/layout/AbstractEditPageWrapper.vue';
import AppInputV3 from '@/components/partials/inputs/AppInputV3.vue';
import AppTextareaV3 from '@/components/partials/inputs/AppTextareaV3.vue';
import AppHeader from '@/components/layout/AppHeader.vue';
import AppToggle from '@/components/partials/inputs/AppToggle.vue';
import AppTooltip from '@/components/partials/AppTooltip.vue';
import { TOOLTIP_POSITION } from '@/common/tooltip';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import { ICON_TYPES } from '@/common/iconHelper';
import AppMultiselectV3 from '@/components/partials/inputs/AppMultiselectV3.vue';
import Thresholds from '@/__new__/features/charging/Thresholds.vue';
import EditorButtons from '@/components/layout/EditorButtons.vue';
import AppLabel from '@/components/partials/AppLabel.vue';
import Button from '@/common/button/Button';
import AppIcon from '@/components/partials/icon/AppIcon.vue';
import ChargingTagLabel from '@/__new__/features/charging/ChargingTagLabel.vue';

// Mixins
import entityEditorMixin from '@/common/entityEditorMixin';
import mutationDialogMixin from '@/components/partials/mutations/mutationDialogMixin.vue';
import ChargingCommonActionsMixin from '@/__new__/features/charging/ChargingCommonActionsMixin.vue';

// http
import { getPolicyCounterEntityDraft } from '@/__new__/services/dno/charging/http/policyCounters';
import { getUserNameById } from '@/__new__/services/portal/profile/http/profile';
import { PolicyCounterDraft } from '@/__new__/services/dno/charging/models/PolicyCounter';

// validations
import { validationMixin } from 'vuelidate';
import { required, requiredIf } from 'vuelidate/lib/validators';

// helpers
import { ALERT_TYPES } from '@/common/alerts/Alert';
import ENTITY_TYPES from '@/common/entities/entityTypes';
import * as Sentry from '@sentry/vue';
import { getProperlyFormattedMultilangFieldValue, EDITOR_MODE } from '@/common/entities/entityHelper';
import RouteNames from '@/router/routeNames';
import { unitTypes } from '@/common/formatting';
import { thresholdTypes, TYPES_OPTIONS, emptyType } from '@/__new__/services/dno/charging/common/policyCounterHelper';
import { languageMap } from '@/common/locale/language';
import {
    PolicyCounter,
    UsageCounter,
    ChargingEntityVersion,
} from '@/__new__/services/dno/charging/models/ChargingInterfaces';
import permissionsService, { isUserAllowed } from '@/services/permissions/permissions.service';
import { LABEL_COLOR } from '@/common/labelsHelper';
import { STATUS_CODES } from '@/common/commonHelper';
import cloneDeep from 'lodash/cloneDeep';
import {
    loadVersionHistory,
    CHARGING_ENTITY_TYPES,
    getLowerEntitiesVersions,
} from '@/__new__/services/dno/charging/common/versioningHelper';
import { PLURALIZATION } from '@/common/locale/labelSingularOrPlural';

interface MultiselectOption {
    id: string;
    name: string;
}

interface ThresholdTypeOption {
    label: TranslateResult;
    value: string;
}

export default Vue.extend({
    name: 'PolicyCounterEditor',
    components: {
        AbstractEditPageWrapper,
        AppHeader,
        AppInputV3,
        AppTextareaV3,
        AppTooltip,
        AppToggle,
        AppMultiselectV3,
        AppButton,
        Thresholds,
        EditorButtons,
        AppLabel,
        AppIcon,
        ChargingTagLabel,
    },
    mixins: [validationMixin, entityEditorMixin, mutationDialogMixin, ChargingCommonActionsMixin],
    data() {
        return {
            ICON_TYPES,
            BUTTON_TYPES,
            emptyType,
            unitTypes,
            TOOLTIP_POSITION,
            PLURALIZATION,
            selectedThresholdType: emptyType,
            description: {} as string | Record<string, string>,
            approveOnCreate: false as boolean,
            requiredErrors: {
                multiselectError: false as boolean,
                nameError: false as boolean,
                statusZeroError: false as boolean,
                statusOneError: false as boolean,
                statusesError: [] as number[],
                thresholdError: false as boolean,
                thresholdsError: [] as number[],
                usageCounterError: false as boolean,
            },
            statuses: [] as string[],
            thresholds: [] as number[],
            disabledStatuses: [] as number[],
            data: {},
            id: null as string | null,
            name: '' as string | Record<string, string>,
            remark: '' as string,
            version: null as number | null,
            entityType: ENTITY_TYPES.POLICY_COUNTER as string,
            selectedLanguage: '' as string,
            isUsingUsageConuter: false as boolean,
            usageCounter: null as UsageCounter | null,
            updateName: this.$i18n.t('generic.N/A'),
            entityId: null as string | null,
            entityDraft: null as any,
            isUnpublished: false as boolean,
            LABEL_COLOR,
            STATUS_CODES,
            EDITOR_MODE,
            isOnlyDraft: false as boolean,
            readonly: false as boolean,
            updateTime: '' as string,
            revertConfirmationButton: new Button({
                label: this.$i18n.t('productCatalog.editors.revert'),
                alertType: ALERT_TYPES.warning,
                handler: () => this.initData(true),
            }),
            versionsHistory: [] as ChargingEntityVersion[],
            currentVersion: null as string | null,
            lowerEntitiesVersions: null,
            CHARGING_ENTITY_TYPES,
            getLowerEntitiesVersions,
            RouteNames,
        };
    },
    validations() {
        return {
            selectedThresholdType: {
                value: {
                    required,
                },
            },
            name: {
                required,
            },
            usageCounter: {
                name: {
                    required: requiredIf(() => this.isUsingUsageConuter),
                },
            },
        };
    },
    computed: {
        ...mapGetters(Modules.chargingV2, [
            Getters.GET_POLICY_COUNTERS,
            Getters.GET_POLICY_COUNTER_BY_VERSION,
            Getters.GET_POLICY_COUNTER_BY_ID,
            Getters.GET_APPROVED_USAGE_COUNTERS,
            Getters.GET_USAGE_COUNTER_BY_ID,
        ]),
        ...mapGetters('operators', [Getters.languageDefault]),
        policyCounters(): PolicyCounter[] {
            return this[Getters.GET_POLICY_COUNTERS];
        },
        policyCounterByVersion(): PolicyCounter | null {
            return this[Getters.GET_POLICY_COUNTER_BY_VERSION];
        },
        usageCountersOptions(): MultiselectOption[] {
            return this[Getters.GET_APPROVED_USAGE_COUNTERS].map((uc: UsageCounter) => ({
                id: uc.id,
                name: uc.name,
            }));
        },
        thresholdTypesOptions(): ThresholdTypeOption[] {
            return Object.values(thresholdTypes).map((type: any) => ({
                ...type,
                label: type.label,
            }));
        },
        policyCounterData(): PolicyCounter {
            return this[Getters.GET_POLICY_COUNTER_BY_ID](this.entityId) || {};
        },
        isEmpty(): boolean {
            return this.selectedThresholdType.value === this.emptyType.value;
        },
        isNewElement(): boolean {
            return !this.entityId || this.$route.params.clone;
        },
        alreadyUsedName(): boolean {
            const dataForUser = this.policyCounters;

            if (this.entityId && !this.$route.params.clone) {
                if (this.name !== this.policyCounterData?.name) {
                    return Object.values(dataForUser).some((pc: PolicyCounter) => pc.name === this.name);
                }
                return false;
            }
            return Object.values(dataForUser).some((pc: PolicyCounter) => pc.name === this.name);
        },
        isDraft() {
            return this.entityId && !this.readonly && this.entityDraft;
        },
        isPublished() {
            return this.entityId && this.readonly;
        },
        allowEditDraftBtn() {
            return this.isPublished && !this.isOnlyDraft;
        },
        isRevertAvailable() {
            return this.isEditing && !this.readonly && !this.isOnlyDraft;
        },
        allowViewPublishedBtn() {
            return this.entityId && !this.readonly && !this.isOnlyDraft;
        },
        pageTitle(): string {
            if (this.readonly) {
                return this.$i18n.t('charging.policyCounters.namePc');
            }
            if (this.entityId) {
                return this.$i18n.t('charging.policyCounters.editPc');
            }
            return this.$i18n.t('charging.policyCounters.addnewPc');
        },
    },
    created() {
        this.$withLoadingSpinner(
            async () => {
                const { id, readonly, mode, clone, chargingVersion } = this.$route.params;
                this.entityId = id;

                if (this.entityId && readonly) {
                    this.readonly = readonly;
                }

                if (this.entityId && mode) {
                    this.readonly = mode === EDITOR_MODE.VIEW;
                }

                if (chargingVersion) {
                    this.currentVersion = chargingVersion;
                }

                this.selectedLanguage = (this[Getters.languageDefault] || languageMap.en.key).toString();
                const promises = [this[Actions.REQUEST_POLICY_COUNTERS]()];

                if (permissionsService.chargingUsageCountersEnabled() && isUserAllowed('UsageCountersReadOnly')) {
                    promises.push(this[Actions.REQUEST_USAGE_COUNTERS]());
                }

                if (!clone) {
                    promises.push(this.loadEntityDraft());
                }

                await Promise.all(promises);

                if (id) {
                    this.initData(false, !this.currentVersion);
                    if (this.readonly) {
                        this.loadVersionHistory(CHARGING_ENTITY_TYPES.POLICY_COUNTER, this.entityId, chargingVersion);
                    }
                }
            },
            {
                errorHandler: () => {
                    this.$eventBus.$emit('showAlert', {
                        message: this.$i18n.t('alertMessage.failedToLoadNecessaryData'),
                    });
                },
            },
        );
    },
    methods: {
        ...mapActions(Modules.chargingV2, [
            Actions.REQUEST_POLICY_COUNTERS,
            Actions.REQUEST_POLICY_COUNTER_BY_VERSION,
            Actions.REQUEST_USAGE_COUNTERS,
        ]),
        onSelectThresholdSelection(): void {
            this.thresholds = [];
            this.statuses = [];
            this.requiredErrors = {
                multiselectError: false,
                statusZeroError: false,
                statusOneError: false,
                statusesError: [],
                thresholdError: false,
                thresholdsError: [],
            };
        },
        onSave(isPublish = true): void {
            if (isPublish) {
                if (!this.checkDataBeforeSave()) {
                    return;
                }
            }

            this.saveData(isPublish);
        },
        async saveData(isPublish = true) {
            this.data = {
                name: this.name,
                description: this.description,
                remark: this.remark,
                statuses: [...this.statuses],
            };

            if (this.selectedThresholdType?.value === TYPES_OPTIONS.QUOTA) {
                this.data.thresholds = [...this.thresholds];
            } else {
                this.data.thresholds_percentage = [...this.thresholds];
            }

            if (this.isUsingUsageConuter) {
                this.data.usage_counter_id = this.usageCounter?.id || null;
            }

            await this.saveEntityData(this.isOnlyDraft, [], isPublish);
        },
        async initData(forceInitPublished = false, initVersion = true): Promise<void> {
            const pc = this.policyCounterData;
            let csData;

            if (initVersion) {
                this.currentVersion = pc?.chargingVersion || null;
            }

            if (this.entityDraft && !forceInitPublished) {
                const res = cloneDeep(this.entityDraft);
                const draftData = new PolicyCounterDraft({
                    ...res,
                    ...res.data,
                });

                draftData.version = pc.version;
                csData = draftData;
                this.isOnlyDraft = pc?.state === STATUS_CODES.NA;

                if (pc?.update_time <= csData?.update_time) {
                    this.isUnpublished = true;
                }
            } else if (this.currentVersion) {
                await this.loadHistoryEntity(this.entityId, this.currentVersion);
                csData = cloneDeep(this.policyCounterByVersion);
            } else {
                csData = cloneDeep(pc);
            }

            const {
                name,
                description,
                remark,
                version,
                statuses = [],
                thresholds,
                availableStatuses,
                usageCounterId = null,
                thresholdsPercentage,
                lowerEntitiesVersions,
            } = csData;

            this.name = name;
            if (this.$route.params.clone) {
                this.name += ' (cloned)';
            }

            this.description = getProperlyFormattedMultilangFieldValue(description);
            this.remark = remark;
            this.version = version;
            this.updateTime = csData.update_time;
            this.getUpdateUserName(csData?.updatePortalId);
            this.statuses = [...statuses];
            this.thresholds = thresholdsPercentage ? [...thresholdsPercentage] : [...thresholds];
            this.lowerEntitiesVersions = lowerEntitiesVersions || null;
            this.selectedThresholdType = thresholdsPercentage
                ? thresholdTypes[TYPES_OPTIONS.PERCENTAGE]
                : thresholdTypes[TYPES_OPTIONS.QUOTA];

            if (availableStatuses) {
                this.disableUsedStatuses([...Object.values(availableStatuses)]);
            }

            if (usageCounterId) {
                this.isUsingUsageConuter = true;
                const selectedUsageCounter = this[Getters.GET_USAGE_COUNTER_BY_ID](usageCounterId);
                if (selectedUsageCounter) {
                    this.usageCounter = {
                        id: selectedUsageCounter.id,
                        name: selectedUsageCounter.name,
                    };
                }
            }
        },
        disableUsedStatuses(availableStatuses: string[]): void {
            this.statuses.forEach((status: string) => {
                if (availableStatuses.includes(status)) {
                    this.disabledStatuses.push(status);
                }
            });
        },
        validatedStatuses(): boolean {
            if (this.statuses.length <= 0) {
                this.$set(this.requiredErrors, 'statusZeroError', true);
                this.$set(this.requiredErrors, 'statusOneError', true);
                return true;
            }
            return this.statuses.some((status: string, i: number) => {
                if (status === '') {
                    if (i === 0) {
                        this.$set(this.requiredErrors, 'statusZeroError', true);
                    } else if (i === 1) {
                        this.requiredErrors.statusOneError = true;
                        this.$set(this.requiredErrors, 'statusOneError', true);
                    } else {
                        this.$set(this.requiredErrors.statusesError, i - 2, true);
                    }

                    return true;
                }

                return false;
            });
        },
        validatedThresholds(): boolean {
            if (this.thresholds.length <= 0) {
                this.$set(this.requiredErrors, 'thresholdError', true);
                return true;
            }
            return this.thresholds.some((threshold: number, i: number) => {
                if (threshold === 0) {
                    if (i === 0) {
                        this.$set(this.requiredErrors, 'thresholdError', true);
                    } else {
                        this.$set(this.requiredErrors.thresholdsError, i - 1, true);
                    }

                    return true;
                }

                return false;
            });
        },
        checkDataBeforeSave(): boolean {
            if (this.$v.name.$invalid) {
                this.requiredErrors.nameError = true;
                return false;
            }
            if (this.alreadyUsedName) {
                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('alertMessage.nameOfPolicyCounterMustBeUnique'),
                });
                return false;
            }
            if (this.$v.selectedThresholdType.value.$invalid) {
                this.requiredErrors.multiselectError = true;
                return false;
            }
            if (this.validatedStatuses()) {
                return false;
            }
            if (this.validatedThresholds()) {
                return false;
            }
            if (this.$v.usageCounter.name.$invalid && this.isUsingUsageConuter) {
                this.requiredErrors.usageCounterError = true;
                return false;
            }

            return true;
        },
        updateThresholds({ statuses, thresholds }: any): void {
            this.statuses = statuses;
            this.thresholds = thresholds;
        },
        updateThresholdErrors(errors: any): void {
            this.requiredErrors = {
                ...this.requiredErrors,
                ...errors,
            };
        },
        async loadEntityDraft() {
            if (this.entityId) {
                try {
                    if (!this.readonly) {
                        const result = await getPolicyCounterEntityDraft(this.entityId);
                        this.entityDraft = result?.data?.data?.[this.entityId] || null;
                    }
                } catch (e) {
                    this.$alert(this.$i18n.t('alertMessage.failedToLoadNecessaryData'), { type: ALERT_TYPES.error });
                }
            }
        },
        reloadEditor(mode: string) {
            const { id } = this.$route.params;
            // Use push to list page because router don`t want ot reload same page
            this.$router
                .push({
                    name: RouteNames.CHARGING_POLICY_COUNTERS,
                    params: { companyId: this.$route.params.companyId },
                })
                .then(() => {
                    this.$router.push({
                        name: RouteNames.CHARGING_POLICY_COUNTER_EDITOR,
                        params: {
                            id,
                            mode,
                            companyId: this.$route.params.companyId,
                        },
                    });
                });
        },
        async getUpdateUserName(id: number): Promise<void> {
            try {
                if (id) {
                    const response = await getUserNameById(Number(id));
                    if (response?.data) {
                        this.updateName = response.data;
                    }
                }
            } catch (e) {
                Sentry.captureException(e);
            }
        },
        resetToPublished(): void {
            this.$alert(this.$i18n.t('productCatalog.editors.revertWarning'), {
                type: ALERT_TYPES.warning,
                buttons: [this.revertConfirmationButton],
            });
        },
        async loadVersionHistory(
            entityType: CHARGING_ENTITY_TYPES,
            id: string,
            initChargingVersion?: string,
        ): Promise<void> {
            this.versionsHistory = await loadVersionHistory(entityType, id);

            if (initChargingVersion) {
                this.currentVersion = initChargingVersion;
            } else {
                const [latestVersion] = this.versionsHistory;
                this.currentVersion = latestVersion?.chargingVersion || null;
            }
        },
        selectVersion(entry: ChargingEntityVersion) {
            if (this.currentVersion !== entry?.chargingVersion) {
                this.currentVersion = entry?.chargingVersion;
                this.initData(false, false);
            }
        },
        async loadHistoryEntity(id: string, version: string): Promise<void> {
            if (id) {
                await this.$withProgressBar(
                    async () => {
                        await this[Actions.REQUEST_POLICY_COUNTER_BY_VERSION]({
                            id,
                            version,
                        });
                    },
                    {
                        errorHandler: () => {
                            this.$alert(this.$i18n.t('alertMessage.failedToLoadNecessaryData'), {
                                type: ALERT_TYPES.error,
                            });
                        },
                    },
                );
            }
        },
        openLowerEntitiData(entry: any, routeName: string): void {
            this.$router.push({
                name: routeName,
                params: {
                    companyId: this.$route.params.companyId,
                    id: entry.id,
                    readonly: true,
                    chargingVersion: entry.chargingVersion,
                },
            });
        },
    },
});
