
// Vue components
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import { ICON_TYPES } from '@/common/iconHelper';
import AppDialogV2 from '@/components/partials/AppDialogV2.vue';
import IconButton from '@/components/partials/IconButton.vue';
import AppMultiselectV3 from '@/components/partials/inputs/AppMultiselectV3.vue';
import AppToggleV2 from '@/components/partials/inputs/AppToggleV2.vue';
import AppTable from '@/components/partials/AppTable.vue';

// 3rd party components
import VueJsonPretty from 'vue-json-pretty';
import { ALERT_TYPES } from '@/common/alerts/Alert';

// Helpers
import {
    STATE_HISTORY_KEY_OPTIONS,
    RETRY_TYPE_ENUM,
    RETRY_TYPE_OPTIONS,
} from '@/__new__/features/orchestrationengine/common/orchestrationExecutionDetailsPageHelper';
import _ from 'lodash';
import { PropType } from 'vue';
import tableColumnType from '@/common/filterTable';

interface StateHistoryObject {
    iteration?: number;
    retry?: number;
}

export default {
    name: 'StateHistoryModal',
    components: {
        AppTable,
        AppButton,
        AppMultiselectV3,
        AppDialogV2,
        AppToggleV2,
        IconButton,
        VueJsonPretty,
    },
    props: {
        visible: {
            required: true,
            type: Boolean,
        },
        selectedExecution: {
            required: true,
            type: Object as PropType<Record<string, any>>,
        },
        statesHistory: {
            required: true,
            type: Array as PropType<Array<Record<string, any>>>,
        },
    },
    data() {
        return {
            // Proxy imports
            BUTTON_TYPES,
            ICON_TYPES,
            RETRY_TYPE_ENUM,
            RETRY_TYPE_OPTIONS,

            selectedIteration: 1 as number,
            selectedRetry: 0 as number,
            stateHistoryData: [] as StateHistoryObject[],
            stateHistoryDataDisplayed: {} as Record<string, any> | Record<string, any>[],
            stateHistoryDataAfterSearch: {} as Record<string, any> | Record<string, any>[],
            stateHistoryKeyOptions: [] as { id: string; label: string }[],
            selectedStateHistoryKey: STATE_HISTORY_KEY_OPTIONS.REQUEST as string,
            isObjectSelectedWithinStateHistory: false as boolean,
            stateHistoryPath: '' as string,
            resultOfStateHistoryText: '' as string,
            selectedPathFromStateHistory: '' as string,
            displayRawJSON: false as boolean,
            stateHistoryRawJSONData: {} as Record<string, any>,

            retryOptions: [] as { id: number }[],
            selectedRetryTypeOption: 1 as number,
            retryRangeData: [] as { retryId: number; label: string }[],
            selectedRetryRange: { from: 0, to: 0 } as Record<string, number>,
            retryRangeColumnData: [] as Record<string, any>[],
        };
    },
    computed: {
        iterationOptions(): { id: number }[] {
            const uniqueIterations = this.stateHistoryData
                ? [...new Set(this.stateHistoryData.map((obj: StateHistoryObject) => obj.iteration))]
                : [];

            return uniqueIterations.map((iteration: any) => ({
                id: iteration,
            }));
        },
        stateHistoryDataForCopy() {
            return this.displayRawJSON ? this.stateHistoryRawJSONData : this.stateHistoryDataDisplayed;
        },
        multipleResultsForStateHistory(): boolean {
            return Boolean(this.stateHistoryDataAfterSearch?.length > 1);
        },
        isSelectButtonVisible(): boolean {
            return (
                this.multipleResultsForStateHistory && this.isObjectSelectedWithinStateHistory && !this.displayRawJSON
            );
        },
    },
    created() {
        this.retryRangeColumnData = [
            {
                name: this.$i18n.t('orchestrationEngine.retryTypeOptions.retryOption'),
                key: 'label',
                field: 'label',
                filterType: tableColumnType.GENERAL_TEXT,
            },
        ];

        // Tab options
        this.stateHistoryKeyOptions = Object.values(STATE_HISTORY_KEY_OPTIONS).map(key => {
            const label = key === STATE_HISTORY_KEY_OPTIONS.RESULT ? this.$i18n.t('generic.response') : key;
            return { id: key, label };
        });

        // Raw state history data displayed on toggle switch
        this.stateHistoryRawJSONData = this.statesHistory.filter(el => el.state === this.selectedExecution.state);

        // Main state history data
        this.stateHistoryData = this.formattedStateHistory(this.selectedExecution.state);
    },
    mounted() {
        // Set retry data for default retry option
        this.onSelectRetryTypeOption();

        // Set selected iteration as max number from options (last iteration)
        this.selectedIteration = Math.max(...this.iterationOptions.map((option: { id: number }) => option.id)) || 1;

        // Set selected retry as max number from retry options
        const retryOptionNumbers = this.retryOptions.map(option => option.id);
        this.selectedRetry = Math.max(...retryOptionNumbers);

        // Load first object
        this.onStateHistorySearch();
    },
    methods: {
        formattedStateHistory(state: any): any {
            const groupedArrays: any[] = [];
            const filteredStateHistories = this.statesHistory.filter(el => el.state === state);

            if (Array.isArray(filteredStateHistories) && filteredStateHistories.length) {
                // Grouping objects with same value for iteration and retry in order to create objects for all pairs of those values
                filteredStateHistories.forEach((currentObject, index) => {
                    if (index === 0) {
                        groupedArrays.push([currentObject]);
                    } else {
                        const previousObject = filteredStateHistories[index - 1];

                        if (
                            currentObject.iteration === previousObject.iteration &&
                            currentObject.retry === previousObject.retry
                        ) {
                            // If previous and current objects have same value for iteration and retry group them in array
                            groupedArrays[groupedArrays.length - 1].push(currentObject);
                        } else {
                            // If they don't have same value then create new array just with current object
                            groupedArrays.push([currentObject]);
                        }
                    }
                });

                return groupedArrays.map(currentArray => {
                    let result: any = {};

                    if (currentArray.length > 1) {
                        // All keys from all objects of one group
                        const allObjectKeysFromGroup: any[] = [...new Set(currentArray.flatMap(Object.keys))];

                        // For all keys get value from first object which has value for that key
                        allObjectKeysFromGroup.forEach(key => {
                            currentArray.some((object: any) => {
                                const value = object[key];

                                if (
                                    (typeof value === 'object' && Object.keys(value).length !== 0) ||
                                    (typeof value !== 'object' && value)
                                ) {
                                    result[key] = value;
                                    return true;
                                }

                                return false;
                            });

                            // If value for given key is some falsy value in all objects, from above logic udnefined will be returned for that key
                            // but anyway we need to display something so we will display value from first object which contains that key
                            if (result[key] === undefined) {
                                result[key] = currentArray.reduce((res: any, obj: any) => {
                                    if (obj[key] !== undefined) {
                                        return obj[key];
                                    }

                                    return res;
                                }, undefined);
                            }
                        });
                    } else {
                        // If in array is only one object then no need for concating it with anything, so just add it
                        [result] = currentArray;
                    }

                    return result;
                });
            }

            return filteredStateHistories;
        },
        onSelectRetryOptionFromTable(id: number): void {
            this.selectedRetry = id;

            // Update state history data
            this.onStateHistorySearch();
        },
        onSelectRetryTypeOption(): void {
            // All retries by selected iteration
            const retriesByIteration = this.stateHistoryData.filter(obj => obj.iteration === this.selectedIteration);

            // Unique retries by selected iteration sorted in descending way
            const uniqueRetriesByIterationDescending = this.stateHistoryData
                ? [...new Set(retriesByIteration.map((obj: StateHistoryObject) => obj.retry))]
                : [];

            // Unique retries by selected iteration sorted in ascending way
            const uniqueRetriesByIterationAscending = uniqueRetriesByIterationDescending.slice().sort((a, b) => a - b);

            if (this.selectedRetryTypeOption === RETRY_TYPE_ENUM.EXACT_NUMBER) {
                this.retryOptions = uniqueRetriesByIterationDescending.map((retry: any) => ({
                    id: retry,
                }));
            } else if (this.selectedRetryTypeOption === RETRY_TYPE_ENUM.RANGE) {
                this.retryOptions = uniqueRetriesByIterationAscending.map((retry: any) => ({
                    id: retry,
                }));

                // Update retry options within table
                this.onSelectRetryRange();
            } else if (this.selectedRetryTypeOption === RETRY_TYPE_ENUM.ALL_OPTIONS) {
                this.retryRangeData = uniqueRetriesByIterationDescending.map(retry => ({
                    retryId: retry,
                    label: `Retry ${retry}`,
                }));

                // Trigger select first option from range in order to change displayed state history
                this.onSelectRetryOptionFromTable(uniqueRetriesByIterationDescending?.[0] || 0);
            }
        },
        onSelectRetryRange(): void {
            const { from, to } = this.selectedRetryRange;

            const retryRange = [];
            if (from > to) {
                for (let i = from; i >= to; i -= 1) {
                    retryRange.push(i);
                }
            } else if (from < to) {
                for (let i = from; i <= to; i += 1) {
                    retryRange.push(i);
                }
            } else if (from === to) {
                retryRange.push(from);
            }

            this.retryRangeData = retryRange.map(retry => ({
                retryId: retry,
                label: `${this.$i18n.t('generic.retry')} ${retry}`,
            }));

            // Trigger select first option from range in order to change displayed state history
            const firstOption = from > to ? from : to;
            this.onSelectRetryOptionFromTable(firstOption);
        },
        onStateHistorySearch(): void {
            this.resultOfStateHistoryText = this.$i18n.t('orchestrationEngine.stateHistorySearchResult', {
                selectedIteration: this.selectedIteration,
                selectedRetry: this.selectedRetry,
            });

            // Get result of search for values of iteration and retry
            this.stateHistoryDataAfterSearch = this.stateHistoryData.filter(
                obj => obj.iteration === this.selectedIteration && obj.retry === this.selectedRetry,
            );

            // Set data which is displayed
            this.stateHistoryDataDisplayed = this.stateHistoryDataAfterSearch;

            // Activate tab to display proper data
            if (this.stateHistoryDataAfterSearch.length === 1) {
                this.selectStateHistoryKey(this.selectedStateHistoryKey);
            }
        },
        selectStateHistoryKey(id: string): void {
            this.selectedStateHistoryKey = id;

            // Reset path
            this.selectedPathFromStateHistory = '';

            // Display value of selected key
            if (Array.isArray(this.stateHistoryDataAfterSearch)) {
                this.stateHistoryDataDisplayed = this.stateHistoryDataAfterSearch[0]?.[id] || '';
            }
        },
        onClickWithinStateHistory(index: string): void {
            if (index.startsWith('root[')) {
                this.isObjectSelectedWithinStateHistory = true;
                this.stateHistoryPath = index;
            } else {
                this.isObjectSelectedWithinStateHistory = false;
                this.stateHistoryPath = '';
            }
        },
        onSelectOneStateHistory(): void {
            if (this.stateHistoryPath.startsWith('root[')) {
                // Set data based on selected object from multiple results
                if (Array.isArray(this.stateHistoryDataAfterSearch)) {
                    this.stateHistoryDataAfterSearch = [
                        this.stateHistoryDataAfterSearch[Number(this.stateHistoryPath[5])],
                    ];
                }

                this.stateHistoryDataDisplayed = this.stateHistoryDataAfterSearch;

                this.selectStateHistoryKey(STATE_HISTORY_KEY_OPTIONS.REQUEST);
            }
        },
        onCloseStateHistoryModal(): void {
            this.$emit('close');

            // Reset modal data
            this.selectedIteration = 1;
            this.selectedRetry = 0;
            this.stateHistoryData = [];
            this.stateHistoryDataDisplayed = {};
            this.stateHistoryDataAfterSearch = {};
            this.isObjectSelectedWithinStateHistory = false;
            this.stateHistoryPath = '';
            this.resultOfStateHistoryText = '';
            this.displayRawJSON = false;
        },
        copySelectedExecutionDataToClipboard(data: any): void {
            try {
                if (this.selectedPathFromStateHistory) {
                    let jsonValueForCopy = data;
                    let path = this.selectedPathFromStateHistory;

                    if (path !== 'root') {
                        // doing this since whenever key from json is selected
                        // it returns path with root start which refers to object
                        // but for lodash we don't need it to be passed

                        if (path.startsWith('root[')) {
                            path = path.replace('root', '');
                        } else if (path.startsWith('root.')) {
                            path = path.replace('root.', '');
                        }

                        jsonValueForCopy = _.get(jsonValueForCopy, path);
                    }

                    navigator.clipboard.writeText(JSON.stringify(jsonValueForCopy));
                } else {
                    navigator.clipboard.writeText(JSON.stringify(data));
                }
                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('alertMessage.copySuccess'),
                    type: ALERT_TYPES.success,
                });
            } catch (e: any) {
                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('alertMessage.copyFail'),
                });
            }
        },
    },
};
