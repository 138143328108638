import Delivery from '@/__new__/services/dno/orders/models/Delivery';
import { DELIVERY_STATES } from '@/__new__/features/customerCare/account/common/deliveryStateHelper';

// Properties taken from swagger and /api/v3/delivery/get_delivery_details response combined
// TODO: Move to Delivery model after converting Delivery model to TS
export interface DeliveryPackage {
    tracking_url?: string;
    product_type: string;
    subscription_id: string;
    item_number: string;
    tracking_number?: string;
    sku: string;
    product_sub_type: string;
    specifications: { name: string; value: string }[];
}

export interface DeliveryBE {
    created_at: number;
    delivery_id: string;
    delivery_status: number;
    order_id: string;
    operator_name: string;
    address_id: string;
    delivery_info: {
        shipping_plant: string;
        recipient_email: string;
        process_type: string;
        iccids?: string[];
        shipping_method: string;
        packages: Record<string, DeliveryPackage>;
    };
    delivery_status_update_time_sec: number;
    delivery_status_history?: Array<{
        status: DELIVERY_STATES;
        timestamp: number;
    }>;
    address_info: {
        zip_4: string;
        address_1: string;
        address_2: string;
        zip: string;
        city: string;
        state: string;
    };
    target_type: number;
    target_id: string;
}

export function remapDeliveriesFromBe(deliveries: DeliveryBE[]): { [key: string]: Delivery } {
    if (!deliveries.length) {
        return {};
    }
    return Object.assign(
        {},
        ...deliveries.map(delivery => ({
            [delivery.delivery_id]: new Delivery(Delivery.remapDeliveryFromBe(delivery)),
        })),
    );
}
