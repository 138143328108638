import HTTP from '@/http';
import { EntityToService, EntityToServiceUrl, EntityType } from '@/components/entityTracker/entityTracker';

export function getAllOwnersOfEntities(entityType) {
    return HTTP.post(
        `ceapi/${EntityToServiceUrl[entityType]}/api/v3/${EntityToService[entityType]}/get_all_owners_of_entities_by_type`,
        {
            used_entity_type: entityType,
        },
    );
}

export default {
    getAllOwnersOfEntities,
};
