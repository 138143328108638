export function sendPushNotification({
    title,
    options,
    fallbackFn,
}: {
    title: string;
    options: Record<string, any>;
    fallbackFn?: () => void;
}): void {
    if (typeof Notification === 'undefined') {
        console.warn('Notifcations API: not supported');
        return;
    }

    const showNotification = () => {
        const notification = new Notification(title, options);

        notification.onclick = () => {
            notification.close();
            window.parent.focus();
        };
    };

    switch (Notification.permission) {
        case 'granted':
            showNotification();
            break;

        case 'default':
            Notification.requestPermission(perm => perm === 'granted' && showNotification());
            break;

        default:
            console.warn('Notifcations API: blocked by user');
            if (fallbackFn) {
                fallbackFn();
            }
            break;
    }
}
