import { render, staticRenderFns } from "./TagsInput.vue?vue&type=template&id=07102b61&scoped=true"
import script from "./TagsInput.vue?vue&type=script&lang=js"
export * from "./TagsInput.vue?vue&type=script&lang=js"
import style0 from "./TagsInput.vue?vue&type=style&index=0&id=07102b61&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "07102b61",
  null
  
)

export default component.exports