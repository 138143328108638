<script>
export default {
    name: 'SubSidebarMixin',
    data() {
        return {
            selectedRouteName: '',

            // This should be defined in another mixin
            // SubSidebarSections: {},
            // SubSidebarDefaultSection: null,
        };
    },
    computed: {
        // Filter all off the settings to show user only the ones he has access to based on his permissions
        permissionFilteredSections() {
            return Object.values(this.SubSidebarSections).filter(section => section.permission());
        },
    },
    watch: {
        $route: {
            immediate: true,
            handler(newVal) {
                // updates route name for the case when used on 1 page instead of each section page
                // currently only User Guide pages working this way
                const newRouteName = newVal?.name;
                if (newRouteName) {
                    this.selectedRouteName = newRouteName;
                }
            },
        },
    },
    created() {
        this.checkForPreselectedRoute();
    },
    methods: {
        // Method always accept just a valid route name
        redirectToRouteByName(routeName) {
            if (routeName !== this.selectedRouteName) {
                this.$router.push({
                    name: routeName,
                    params: {
                        companyId: this.$route.params.companyId,
                    },
                });
            }
        },

        changeActiveSection(section) {
            this.redirectToRouteByName(section.routeName);
        },

        checkForPreselectedRoute() {
            // Check if route was set. All settings sections need to have name attached to them.
            const routeName = this.$route?.name;

            const relatedRouteConfig =
                this.SubSidebarSections[routeName] ||
                Object.values(this.SubSidebarSections).find(sectionConf => sectionConf.subRoutes.includes(routeName));

            // if route exists and check if it is valid if so set preselect to it. Otherwise default route.
            if (routeName && relatedRouteConfig) {
                this.selectedRouteName = routeName;
                this.redirectToRouteByName(routeName);

                return;
            }

            this.redirectToRouteByName(this.SubSidebarDefaultSection.routeName);
        },
    },
};
</script>
