import httpClient from '@/http';
import { AxiosPromise } from 'axios';

/**
 * Login API call
 * @param email
 * @param password
 * @param otpCode
 * @return {AxiosPromise}
 */
export function login(email: string, password: string) {
    return httpClient({
        method: 'POST',
        url: '/session/v3login',
        data: {
            email,
            password,
        },
    });
}

export function logout(isDestroySession: boolean) {
    return httpClient({
        method: 'POST',
        url: '/session/logout',
        data: {
            isDestroySession,
        },
    });
}

export function azurePopUplogin(data: any) {
    return httpClient({
        method: 'POST',
        url: '/oauth/popup',
        data,
        headers: {
            Authorization: `Bearer ${data.idToken}`,
        },
    });
}

export function authStrategy() {
    return httpClient({
        method: 'GET',
        url: '/session/authstrategy',
    });
}

export interface AuthStrategyState {
    strategy: string;
    enabled: boolean;
}

/**
 * Returns a list of `AuthStrategyState` objects
 */
export function getAuthStrategyState(): AxiosPromise<AuthStrategyState[]> {
    return httpClient({
        method: 'GET',
        url: '/auth/strategy-state',
    });
}

/**
 * Sets the state for a given auth strategy
 * @param strategy indicates the strategy that whose state will be updated (eg: 'okta' or 'azuread')
 * @param enabled value of true enables the strategy whereas false disables it
 * @returns
 */
export function setAuthStrategyState(strategy: string, enabled: boolean): AxiosPromise<null> {
    return httpClient({
        method: 'POST',
        url: '/auth/strategy-state',
        data: {
            strategy,
            enabled,
        },
    });
}

export function getAzureClientConfig(): AxiosPromise<unknown> {
    return httpClient({
        method: 'GET',
        url: '/session/getazureclientconfig',
    });
}

export default {
    login,
};
