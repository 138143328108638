<template>
    <div class="container-fluid wrapper-container p-0">
        <AppHeader />

        <div class="banner">
            <div class="banner-text">
                <h2 class="lf-title m-0">
                    {{ getWelcomeText }}
                </h2>
            </div>
        </div>
    </div>
</template>

<script>
// Components
import AppHeader from '@/components/layout/AppHeader.vue';

// Helpers
import Account from '@/models/Account';

export default {
    name: 'Home',
    components: {
        AppHeader,
    },
    computed: {
        getWelcomeText() {
            if (Account.authenticated()) {
                return this.$i18n.t('login.welcomeName', { name: Account.storedInstance.name });
            }
            return this.$i18n.t('login.welcome');
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/palette';
@import '~@/assets/scss/layout';
@import '~@/assets/scss/typographyV2';

.wrapper-container {
    background-color: $dirty-white;
    min-height: 100vh;
    height: initial !important;

    .banner {
        position: relative;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        min-height: 6.25rem;
        width: calc(100% - 3rem);
        margin: $spacing-m $spacing-xl;
        padding-left: $spacing-xxl;
        border-radius: 0.5rem;
        background-color: #d6dcea;

        &:after {
            content: '';
            position: absolute;
            bottom: 0;
            right: -2.5rem;
            margin-right: $spacing-xxl;
            /* Sizes in pixels to match svg picture*/
            width: 517px;
            height: 110px;
            background-image: url('../../../assets/images/home-illustration-1.svg');
            background-size: contain;
            background-repeat: no-repeat;
        }
    }

    .iframe-test {
        display: block;
        margin: 0 auto;
    }
}
</style>
