<template>
    <div class="page-background">
        <AppHeader :pageTitle="$i18n.t('customerCare.eSIM.eSIM')" />
        <div class="content-container">
            <SearchBanner :title="$i18n.t('customerCare.eSIM.searchForEsim')" />

            <div class="d-flex align-items-start justify-content-between flex-wrap">
                <div class="search-wrapper">
                    <div class="search-with-wrapper">
                        <div class="inline-text">
                            {{ $i18n.t('customerCare.eSIM.findEsimWith') }}
                        </div>
                        <AppMultiselectV3
                            v-model="selectedSearchOption"
                            label="label"
                            trackBy="key"
                            :options="searchOptions"
                            :allowEmpty="false"
                            :preselectFirst="true"
                            :blueArrow="true"
                            :borderNone="true"
                            :disabled="true"
                            :transparentBg="true"
                        />
                    </div>
                    <div class="search-query-input-wrapper">
                        <AppInputV3
                            id="search-query-input"
                            :value="$v.searchValue.$model"
                            :placeholder="selectedSearchOption.placeholder"
                            :invalid="$v.searchValue.$error"
                            :errorMessage="selectedSearchOption.validationErrorMessage"
                            @input="onSearchValueInput"
                        />
                    </div>
                </div>
                <div>
                    <AppButton
                        id="search-button"
                        :buttonType="BUTTON_TYPES.PRIMARY"
                        :disabled="$v.$anyError"
                        label="Search"
                        :iconType="ICON_TYPES.SEARCH"
                        @click="onSearch"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// Vue components
import AppHeader from '@/components/layout/AppHeader.vue';
import AppInputV3 from '@/components/partials/inputs/AppInputV3.vue';
import AppMultiselectV3 from '@/components/partials/inputs/AppMultiselectV3.vue';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import { ICON_TYPES } from '@/common/iconHelper';
import SearchBanner from '@/components/partials/SearchBanner.vue';

// helpers
import RouteNames from '@/router/routeNames';
import { ESIM_SEARCH_OPTIONS_LIST } from '@/__new__/features/customerCare/esim/common/eSimHelper';
import * as Sentry from '@sentry/vue';

import Actions from '@/store/mutation-types';
import { mapActions } from 'vuex';
import supportButtonMixin from '@/components/alerts/supportButtonMixin';
import { ALERT_TYPES } from '@/common/alerts/Alert';

export default {
    name: 'ESimSearch',

    components: {
        AppButton,
        AppMultiselectV3,
        AppInputV3,
        AppHeader,
        SearchBanner,
    },

    mixins: [supportButtonMixin],

    data() {
        return {
            RouteNames,
            ICON_TYPES,
            BUTTON_TYPES,
            searchValue: '',
            // temporarily using first option here since only it is supported by the API right now
            selectedSearchOption: ESIM_SEARCH_OPTIONS_LIST[0],
            searchOptions: ESIM_SEARCH_OPTIONS_LIST,
        };
    },

    validations() {
        return {
            searchValue: {
                ...this.selectedSearchOption.validationObject,
            },
        };
    },

    methods: {
        ...mapActions('esim', {
            requestEsimData: Actions.REQUEST_ESIM_DATA,
        }),
        onSearchValueInput(value) {
            this.$v.$touch();
            this.searchValue = value;
        },
        async onSearch() {
            this.$v.$touch();
            // this if is just a handlig for the case when user tries to click search with an empty searchValue
            // all other cases are covered by disabling the search button with searchValue is invalid
            if (!this.$v.searchValue.$invalid) {
                this.$Progress.start();

                try {
                    const iccid = await this.requestEsimData(this.searchValue);
                    this.$Progress.finish();
                    setTimeout(() => {
                        this.$router.push({
                            name: RouteNames.ESIM_DETAILS,
                            params: { iccid, companyId: this.$route.params.companyId },
                        });
                    }, 500);
                } catch (error) {
                    Sentry.captureException(error);
                    this.$Progress.fail();
                    this.showSupportAlert(this.$i18n.t('alertMessage.somethingWentWrong'), ALERT_TYPES.error);
                }
            } else {
                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('alertMessage.pleaseFixValidation'),
                });
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/palette';
@import '~@/assets/scss/layout';

.page-background {
    background-color: $dirty-white;
    height: 100%;
}

.content-container {
    width: 70%;
    margin: 0 15%;
}

.search-wrapper {
    width: 60%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding-top: 3px;
}

.search-with-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 50%;
}

.search-options-dropdown {
    width: 50%;
}

.search-query-input-wrapper {
    width: 50%;
    min-width: 200px;
}

.inline-text {
    font-size: 0.75rem;
    text-transform: uppercase;
    font-weight: bold;
    color: $gray-blue;
    margin-right: 0.75rem;
}
</style>
