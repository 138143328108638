import { AxiosPromise } from 'axios';
import http, { DnoResponseBase } from '@/http/index';

export interface UsageCountersApiResponse extends DnoResponseBase {
    usage_counter_by_id: Record<string, any>;
    condition_parameter_by_id?: Record<string, any>;
}

/**
 * Get usage counters.
 *
 * @returns {AxiosPromise}
 */
export function getUsageCounters(
    ids: string[] = [],
    returnChargingVersion = true,
    includeReferencedEntities = false,
    includePortalId = true,
    chargingVersion?: string,
): AxiosPromise<UsageCountersApiResponse> {
    return http({
        method: 'POST',
        url: '/v3charging/getusagecounters',
        data: {
            ids,
            return_charging_version: returnChargingVersion,
            include_referenced_entities: includeReferencedEntities,
            charging_version: chargingVersion,
            include_portal_id: includePortalId,
        },
    });
}

/**
 * Add usage counter.
 *
 * @returns {AxiosPromise}
 */
export function addUsageCounter(data: any): AxiosPromise<DnoResponseBase> {
    return http({
        method: 'POST',
        url: '/v3chargingwrite/addusagecounter',
        data,
    });
}

/**
 * Update usage counter.
 *
 * @returns {AxiosPromise}
 */
export function updateUsageCounter(id: string, version: number, data: any): AxiosPromise<DnoResponseBase> {
    return http({
        method: 'POST',
        url: '/v3chargingwrite/updateusagecounter',
        data: {
            id,
            version,
            data,
        },
    });
}

/**
 * Update usage counter state.
 *
 * @returns {AxiosPromise}
 */
export function updateUsageCounterState(id: string, version: number, state: number): AxiosPromise<DnoResponseBase> {
    return http({
        method: 'POST',
        url: '/v3chargingwrite/updatestateusagecounter',
        data: {
            id,
            version,
            state,
        },
    });
}

interface UsageCountersDraftListApiResponse extends DnoResponseBase {
    data: any;
}

/**
 * Get usage counter draft list.
 *
 * @returns {AxiosPromise}
 */
export function getUsageCountersDraftList(): AxiosPromise<UsageCountersDraftListApiResponse> {
    return http({
        method: 'POST',
        url: '/v3charging/getusagecountersdraftlist',
    });
}

/**
 * Get usage counter entity draft.
 *
 * @returns {AxiosPromise}
 */
export function getUsageCounteEntityDraft(id: string): AxiosPromise<DnoResponseBase> {
    return http({
        method: 'POST',
        url: '/v3charging/getusagecounterdraft',
        data: {
            id,
        },
    });
}

/**
 * Set usage counter draft.
 *
 * @returns {AxiosPromise}
 */
type UsageCounteDraftPayload = {
    id?: string;
    data: object;
};

export function setUsageCounteDraft(data: UsageCounteDraftPayload): AxiosPromise<DnoResponseBase> {
    return http({
        method: 'POST',
        url: '/v3charging/setusagecounterdraft',
        data,
    });
}

/**
 * Delete usage counter draft.
 *
 * @returns {AxiosPromise}
 */
export function deleteUsageCounteDraft(id: string): AxiosPromise<DnoResponseBase> {
    return http({
        method: 'POST',
        url: '/v3charging/deleteusagecounterdraft',
        data: {
            id,
        },
    });
}

export default {
    getUsageCounters,
    addUsageCounter,
    updateUsageCounter,
    updateUsageCounterState,

    getUsageCountersDraftList,
    getUsageCounteEntityDraft,
    setUsageCounteDraft,
    deleteUsageCounteDraft,
};
