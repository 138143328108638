import { TranslateResult } from 'vue-i18n';
import i18n from '@/i18n';

const ngx: Record<string, number> = {
    HTTP_CONTINUE: 100,
    HTTP_SWITCHING_PROTOCOLS: 101,
    HTTP_OK: 200,
    HTTP_CREATED: 201,
    HTTP_ACCEPTED: 202,
    HTTP_NO_CONTENT: 204,
    HTTP_PARTIAL_CONTENT: 206,
    HTTP_SPECIAL_RESPONSE: 300,
    HTTP_MOVED_PERMANENTLY: 301,
    HTTP_MOVED_TEMPORARILY: 302,
    HTTP_SEE_OTHER: 303,
    HTTP_NOT_MODIFIED: 304,
    HTTP_TEMPORARY_REDIRECT: 307,
    HTTP_BAD_REQUEST: 400,
    HTTP_UNAUTHORIZED: 401,
    HTTP_PAYMENT_REQUIRED: 402,
    HTTP_FORBIDDEN: 403,
    HTTP_NOT_FOUND: 404,
    HTTP_NOT_ALLOWED: 405,
    HTTP_NOT_ACCEPTABLE: 406,
    HTTP_REQUEST_TIMEOUT: 408,
    HTTP_CONFLICT: 409,
    HTTP_GONE: 410,
    HTTP_UNPROCESSABLE_ENTITY: 422,
    HTTP_UPGRADE_REQUIRED: 426,
    HTTP_TOO_MANY_REQUESTS: 429,
    HTTP_CLOSE: 444,
    HTTP_ILLEGAL: 451,
    HTTP_INTERNAL_SERVER_ERROR: 500,
    HTTP_METHOD_NOT_IMPLEMENTED: 501,
    HTTP_BAD_GATEWAY: 502,
    HTTP_SERVICE_UNAVAILABLE: 503,
    HTTP_GATEWAY_TIMEOUT: 504,
    HTTP_VERSION_NOT_SUPPORTED: 505,
    HTTP_INSUFFICIENT_STORAGE: 507,
};

const jcmErrorMap: Record<
    string,
    {
        response_code?: number;
        code: number;
        errorMessage?: TranslateResult;
    }
> = {
    DuplicatedJobId: {
        response_code: ngx.HTTP_UNPROCESSABLE_ENTITY,
        code: 1,
        errorMessage: i18n.t('jcm.alerts.DuplicatedJobId'),
    },

    'InvalidJobId.empty': {
        response_code: ngx.HTTP_UNPROCESSABLE_ENTITY,
        code: 20,
        errorMessage: i18n.t('jcm.alerts.InvalidJobId.empty'),
    },
    'InvalidJobId.containsAppName': {
        response_code: ngx.HTTP_UNPROCESSABLE_ENTITY,
        code: 21,
        errorMessage: i18n.t('jcm.alerts.InvalidJobId.containsAppName'),
    },
    'InvalidJobId.containsOperatorName': {
        response_code: ngx.HTTP_UNPROCESSABLE_ENTITY,
        code: 22,
        errorMessage: i18n.t('jcm.alerts.InvalidJobId.containsOperatorName'),
    },
    'InvalidJobId.containsForbiddenCharacters': {
        response_code: ngx.HTTP_UNPROCESSABLE_ENTITY,
        code: 23,
        errorMessage: i18n.t('jcm.alerts.InvalidJobId.containsForbiddenCharacters'),
    },
    'InvalidJobId.containsForbiddenDelimiter': {
        response_code: ngx.HTTP_UNPROCESSABLE_ENTITY,
        code: 24,
        errorMessage: i18n.t('jcm.alerts.InvalidJobId.containsForbiddenDelimiter'),
    },
    'InvalidJobId.containsInvalidPrefix': {
        response_code: ngx.HTTP_UNPROCESSABLE_ENTITY,
        code: 25,
        errorMessage: i18n.t('jcm.alerts.InvalidJobId.containsInvalidPrefix'),
    },

    'InvalidOperatorName.empty': {
        response_code: ngx.HTTP_UNPROCESSABLE_ENTITY,
        code: 30,
        errorMessage: i18n.t('jcm.alerts.InvalidOperatorName.empty'),
    },
    'InvalidOperatorName.containsUppercaseSymbols': {
        response_code: ngx.HTTP_UNPROCESSABLE_ENTITY,
        code: 31,
        errorMessage: i18n.t('jcm.alerts.InvalidOperatorName.containsUppercaseSymbols'),
    },

    EmptyJobName: {
        response_code: ngx.HTTP_UNPROCESSABLE_ENTITY,
        code: 5,
        errorMessage: i18n.t('jcm.alerts.EmptyJobName'),
    },

    'InvalidTopic.empty': {
        response_code: ngx.HTTP_UNPROCESSABLE_ENTITY,
        code: 60,
        errorMessage: i18n.t('jcm.alerts.InvalidTopic.empty'),
    },
    'InvalidTopic.containsOperatorName': {
        response_code: ngx.HTTP_UNPROCESSABLE_ENTITY,
        code: 61,
        errorMessage: i18n.t('jcm.alerts.InvalidTopic.containsOperatorName'),
    },
    'InvalidTopic.containsForbiddenCharacters': {
        response_code: ngx.HTTP_UNPROCESSABLE_ENTITY,
        code: 62,
        errorMessage: i18n.t('jcm.alerts.InvalidTopic.containsForbiddenCharacters'),
    },
    'InvalidTopic.containsForbiddenDelimiter': {
        response_code: ngx.HTTP_UNPROCESSABLE_ENTITY,
        code: 63,
        errorMessage: i18n.t('jcm.alerts.InvalidTopic.containsForbiddenDelimiter'),
    },
    'InvalidTopic.containsInvalidPrefix': {
        response_code: ngx.HTTP_UNPROCESSABLE_ENTITY,
        code: 64,
        errorMessage: i18n.t('jcm.alerts.InvalidTopic.containsInvalidPrefix'),
    },
    'InvalidTopic.exceededLengthBoundaries': {
        response_code: ngx.HTTP_UNPROCESSABLE_ENTITY,
        code: 65,
        errorMessage: i18n.t('jcm.alerts.InvalidTopic.exceededLengthBoundaries'),
    },

    QuotaLimitExceeded: {
        response_code: ngx.HTTP_UNPROCESSABLE_ENTITY,
        code: 7,
        errorMessage: i18n.t('jcm.alerts.QuotaLimitExceeded'),
    },
    ProhibitedClusterName: {
        response_code: ngx.HTTP_UNPROCESSABLE_ENTITY,
        code: 8,
        errorMessage: i18n.t('jcm.alerts.ProhibitedClusterName'),
    },
    InvalidJobConfigState: {
        response_code: ngx.HTTP_UNPROCESSABLE_ENTITY,
        code: 9,
        errorMessage: i18n.t('jcm.alerts.InvalidJobConfigState'),
    },
    ForbiddenPropertyModification: {
        response_code: ngx.HTTP_UNPROCESSABLE_ENTITY,
        code: 10,
        errorMessage: i18n.t('jcm.alerts.ForbiddenPropertyModification'),
    },
    ProhibitedRevisionNumber: {
        response_code: ngx.HTTP_UNPROCESSABLE_ENTITY,
        code: 11,
        errorMessage: i18n.t('jcm.alerts.ProhibitedRevisionNumber'),
    },

    'InvalidSinkEntityProperties.invalidSinkEntityProperties': {
        response_code: ngx.HTTP_UNPROCESSABLE_ENTITY,
        code: 16,
        errorMessage: i18n.t('jcm.alerts.InvalidSinkEntityProperties.invalidSinkEntityProperties'),
    },
    'InvalidSinkEntityProperties.validatorIsUnreachable': {
        response_code: ngx.HTTP_UNPROCESSABLE_ENTITY,
        code: 17,
        errorMessage: i18n.t('jcm.alerts.InvalidSinkEntityProperties.validatorIsUnreachable'),
    },
};

const errorMap: Record<
    string,
    Record<
        string,
        {
            response_code?: number;
            code: number;
            errorMessage?: TranslateResult;
        }
    >
> = {
    'scala.api-invoker.job-config-management.validation.base': jcmErrorMap,
    'scala.operational-reports-daemon.job-config-management.validation.base': jcmErrorMap,
    'scala.api-invoker.job-config-management.validation': {
        InvalidUriRequestParams: {
            response_code: ngx.HTTP_UNPROCESSABLE_ENTITY,
            code: 5,
            errorMessage: i18n.t('apii.alerts.InvalidUriRequestParams'),
        },
        InvalidAuthRequestParams: {
            response_code: ngx.HTTP_UNPROCESSABLE_ENTITY,
            code: 6,
            errorMessage: i18n.t('apii.alerts.InvalidAuthRequestParams'),
        },
        InvalidHeadersRequestParams: {
            response_code: ngx.HTTP_UNPROCESSABLE_ENTITY,
            code: 7,
            errorMessage: i18n.t('apii.alerts.InvalidHeadersRequestParams'),
        },
        DuplicatedAuthRequestParams: {
            response_code: ngx.HTTP_UNPROCESSABLE_ENTITY,
            code: 8,
            errorMessage: i18n.t('apii.alerts.DuplicatedAuthRequestParams'),
        },
        InvalidDiscardedEventCategoryRule: {
            response_code: ngx.HTTP_UNPROCESSABLE_ENTITY,
            code: 9,
            errorMessage: i18n.t('apii.alerts.InvalidDiscardedEventCategoryRule'),
        },
        InvalidDiscardedEventCategoryName: {
            response_code: ngx.HTTP_UNPROCESSABLE_ENTITY,
            code: 10,
            errorMessage: i18n.t('apii.alerts.InvalidDiscardedEventCategoryName'),
        },
        InvalidMatchingRule: {
            response_code: ngx.HTTP_UNPROCESSABLE_ENTITY,
            code: 11,
            errorMessage: i18n.t('apii.alerts.InvalidMatchingRule'),
        },
        InvalidRetryRule: {
            response_code: ngx.HTTP_UNPROCESSABLE_ENTITY,
            code: 11,
            errorMessage: i18n.t('apii.alerts.InvalidRetryRule'),
        },
        InvalidIgnoreRule: {
            response_code: ngx.HTTP_UNPROCESSABLE_ENTITY,
            code: 11,
            errorMessage: i18n.t('apii.alerts.InvalidIgnoreRule'),
        },
    },
    'scala.operational-reports-daemon.job-config-management.validation': {
        'InvalidInterval.exceededBoundaries': {
            response_code: ngx.HTTP_UNPROCESSABLE_ENTITY,
            code: 10,
            errorMessage: i18n.t('ord.alerts.validation.InvalidInterval.exceededBoundaries'),
        },
        'InvalidInterval.nonMutuallyExclusive': {
            response_code: ngx.HTTP_UNPROCESSABLE_ENTITY,
            code: 11,
            errorMessage: i18n.t('ord.alerts.validation.InvalidInterval.nonMutuallyExclusive'),
        },
        'InvalidInterval.incompatibleWithFrequency': {
            response_code: ngx.HTTP_UNPROCESSABLE_ENTITY,
            code: 12,
            errorMessage: i18n.t('ord.alerts.validation.InvalidInterval.incompatibleWithFrequency'),
        },

        'InvalidPrimaryLocation.duplicatedInSecondary': {
            response_code: ngx.HTTP_UNPROCESSABLE_ENTITY,
            code: 20,
            errorMessage: i18n.t('ord.alerts.validation.InvalidPrimaryLocation.duplicatedInSecondary'),
        },
        'InvalidPrimaryLocation.duplicatedInOtherJob': {
            response_code: ngx.HTTP_UNPROCESSABLE_ENTITY,
            code: 21,
            errorMessage: i18n.t('ord.alerts.validation.InvalidPrimaryLocation.duplicatedInOtherJob'),
        },

        InvalidCronExpression: {
            response_code: ngx.HTTP_UNPROCESSABLE_ENTITY,
            code: 3,
            errorMessage: i18n.t('ord.alerts.validation.InvalidCronExpression'),
        },

        TopicInvalidPrefix: {
            response_code: ngx.HTTP_UNPROCESSABLE_ENTITY,
            code: 4,
            errorMessage: i18n.t('ord.alerts.validation.TopicInvalidPrefix'),
        },
    },
    'scala.operational-reports-daemon': {
        ReportNotFound: {
            response_code: ngx.HTTP_NOT_FOUND,
            code: 2,
            errorMessage: i18n.t('ord.alerts.error.ReportNotFound'),
        },
        ReportConfigError: {
            response_code: ngx.HTTP_NOT_FOUND,
            code: 3,
            errorMessage: i18n.t('ord.alerts.error.ReportConfigError'),
        },
        ReportsNotFound: {
            response_code: ngx.HTTP_NOT_FOUND,
            code: 4,
            errorMessage: i18n.t('ord.alerts.error.ReportsNotFound'),
        },
        ReportsTaskFailed: {
            response_code: ngx.HTTP_NOT_FOUND,
            code: 5,
            errorMessage: i18n.t('ord.alerts.error.ReportsTaskFailed'),
        },
        ReportsTimeRangeNotExist: {
            response_code: ngx.HTTP_NOT_FOUND,
            code: 6,
            errorMessage: i18n.t('ord.alerts.error.ReportsTimeRangeNotExist'),
        },

        'InvalidReportDate.cannotBeInFuture': {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 50,
            errorMessage: i18n.t('ord.alerts.error.InvalidReportDate.cannotBeInFuture'),
        },
        'InvalidReportDate.startDateAfterEndDate': {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 51,
            errorMessage: i18n.t('ord.alerts.error.InvalidReportDate.startDateAfterEndDate'),
        },

        'PreviewNotSupported.cannotCalculateInterval': {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 100,
            errorMessage: i18n.t('ord.alerts.error.PreviewNotSupported.cannotCalculateInterval'),
        },
        'PreviewNotSupported.smallInterval': {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 101,
            errorMessage: i18n.t('ord.alerts.error.PreviewNotSupported.smallInterval'),
        },
    },
    'scala.document-engine': {
        TemplateNotFound: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 4,
            errorMessage: i18n.t('documentEngine.alerts.validation.TemplateNotFound'),
        },
        DocumentNotFound: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 5,
            errorMessage: i18n.t('documentEngine.alerts.validation.DocumentNotFound'),
        },
        AssetNotFound: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 6,
            errorMessage: i18n.t('documentEngine.alerts.validation.AssetNotFound'),
        },
        RenderNotAllowed: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 7,
            errorMessage: i18n.t('documentEngine.alerts.validation.RenderNotAllowed'),
        },
        EnrichTemplatesError: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 10,
            errorMessage: i18n.t('documentEngine.alerts.validation.EnrichTemplatesError'),
        },
        InvalidElementPath: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 11,
            errorMessage: i18n.t('documentEngine.alerts.validation.InvalidElementPath'),
        },
        EngineParsingError: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 13,
            errorMessage: i18n.t('documentEngine.alerts.validation.EngineParsingError'),
        },
        ParametersNotFound: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 14,
            errorMessage: i18n.t('documentEngine.alerts.validation.ParametersNotFound'),
        },
        AssetUndefined: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 15,
            errorMessage: i18n.t('documentEngine.alerts.validation.AssetUndefined'),
        },
        HtmlSyntaxError: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 16,
            errorMessage: i18n.t('documentEngine.alerts.validation.HtmlSyntaxError'),
        },
        UnescapedPlaceholder: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 17,
            errorMessage: i18n.t('documentEngine.alerts.validation.UnescapedPlaceholder'),
        },
        AssetFetch: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 18,
            errorMessage: i18n.t('documentEngine.alerts.validation.AssetFetch'),
        },
        EngineSyntaxError: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 20,
            errorMessage: i18n.t('documentEngine.alerts.validation.EngineSyntaxError'),
        },
        HelpersNotFound: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 22,
            errorMessage: i18n.t('documentEngine.alerts.validation.HelpersNotFound'),
        },
        TemplateOutdated: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 36,
            errorMessage: i18n.t('documentEngine.alerts.validation.TemplateOutdated'),
        },
        TemplateLock: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 37,
            errorMessage: i18n.t('documentEngine.alerts.validation.TemplateLock'),
        },
    },
    OTP: {
        INVALID_CHALLENGEID: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 3,
        },
        MISMATCH_CHALLENGE_TYPE: {
            response_code: ngx.HTTP_CONFLICT,
            code: 14,
        },
        CASSANDRA_ERROR: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 6,
        },
        MISSING_PARAMS_IN_QUERY_STRING: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 18,
        },
        CHALLENGE_GENERATE_LIMIT: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 19,
        },
        MISMATCH_CHALLENGE_CONTEXT_TYPE: {
            response_code: ngx.HTTP_CONFLICT,
            code: 15,
        },
        WRONG_IDENTIFIER: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 9,
        },
        INVALID_OTP_CODE: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 11,
        },
        CHALLENGE_NOT_MATCH: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 2,
        },
        INVALID_CHALLENGE_CONTEXT_TYPE: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 13,
        },
        INVALID_CHALLENGE_TYPE: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 12,
        },
        IDENTIFIER_NOT_SET: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 17,
        },
        SUCCESS: {
            response_code: ngx.HTTP_OK,
            code: 0,
        },
        CHALLENGEID_VERIFIED: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 23,
        },
        CHALLENGEID_EXPIRED: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 25,
        },
    },
    OSA_PROJECT8_EDA: {
        ACTIVATION_LINE_FAILURE: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 4,
        },
        EXTERNAL_REQUEST_FAILURE: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 2,
        },
        MISSING_BAN: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 1,
        },
        GATEWAY_TIMEOUT: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 3,
        },
        ASYNC_SUCCESS: {
            response_code: ngx.HTTP_ACCEPTED,
            code: 0,
        },
        FANOUT_INTERNAL_ERROR: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 6,
        },
        UPDATE_FEATURES_FAILURE: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 5,
        },
    },
    TRANSACTIONS: {
        INVALID: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 1,
        },
        SUCCESS: {
            response_code: ngx.HTTP_OK,
            code: 0,
        },
        IOERROR: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 2,
        },
    },
    ORDEROE: {
        BASE_PLAN_ALREADY_PROVISIONED: {
            response_code: ngx.HTTP_CONFLICT,
            code: 7,
        },
        CANCEL_SERVICE_REQUESTED: {
            response_code: ngx.HTTP_CONFLICT,
            code: 18,
        },
        PRODUCT_NOT_FOUND: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 21,
        },
        EXTERNAL_API_FAILURE: {
            response_code: ngx.HTTP_BAD_GATEWAY,
            code: 9,
        },
        CHANGE_TYPE_NOT_ALLOWED: {
            response_code: ngx.HTTP_CONFLICT,
            code: 16,
        },
        RECURRENCE_LIMIT_REACHED: {
            response_code: ngx.HTTP_CONFLICT,
            code: 17,
        },
        INVALID_ORDER_STATE: {
            response_code: ngx.HTTP_CONFLICT,
            code: 13,
        },
        MISSING_SERVICE_ADDRESS: {
            response_code: ngx.HTTP_CONFLICT,
            code: 4,
        },
        ADDON_PURCHASE_FORBIDDEN: {
            response_code: ngx.HTTP_CONFLICT,
            code: 8,
        },
        ENTITY_NOT_FOUND: {
            response_code: ngx.HTTP_NOT_FOUND,
            code: 5,
        },
        ORDER_NOT_FOUND: {
            response_code: ngx.HTTP_NOT_FOUND,
            code: 3,
        },
        CHARGED_TO_BILL_FORBIDDEN: {
            response_code: ngx.HTTP_UNAUTHORIZED,
            code: 26,
        },
        INVALID: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 1,
        },
        RECURRENCE_NOT_ALLOWED: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 25,
        },
        INVALID_PRODUCT: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 23,
        },
        BAD_ACCOUNT_STATE: {
            response_code: ngx.HTTP_FORBIDDEN,
            code: 2,
            errorMessage: i18n.t('alerts.youLackNecessaryPermission'),
        },
        REPLACE_ORDER_FORBIDDEN: {
            response_code: ngx.HTTP_CONFLICT,
            code: 10,
        },
        AMOUNT_MISSING_FOR_WALLET_TYPE: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 24,
        },
        INVALID_ACTION: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 22,
        },
        ALREADY_REPLACED: {
            response_code: ngx.HTTP_CONFLICT,
            code: 14,
        },
        INVALID_EXTERNAL_PRODUCT: {
            response_code: ngx.HTTP_CONFLICT,
            code: 19,
        },
        INVALID_RECURRENCE_STATE: {
            response_code: ngx.HTTP_CONFLICT,
            code: 12,
        },
        RECURRENCE_NOT_FOUND: {
            response_code: ngx.HTTP_NOT_FOUND,
            code: 6,
        },
        TIME_CONSTRAINTS_NOT_SATISFIED: {
            response_code: ngx.HTTP_CONFLICT,
            code: 15,
        },
        CANCEL_REPLACE_ORDER_FORBIDDEN: {
            response_code: ngx.HTTP_CONFLICT,
            code: 11,
        },
        SUCCESS: {
            response_code: ngx.HTTP_OK,
            code: 0,
        },
        NOT_SUPPORTED_CONFIGURATION: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 20,
        },
        TERMINATION_ALREADY_REQUESTED: {
            response_code: ngx.HTTP_CONFLICT,
            code: 49,
        },
        PORT_IN_ALREADY_INITIATED: {
            response_code: ngx.HTTP_CONFLICT,
            code: 70,
        },
        QR_CODE_NOT_FOUND: {
            response_code: ngx.HTTP_FORBIDDEN,
            code: 83,
            errorMessage: i18n.t('customerCare.guestOrder.qrCodeNotFound'),
        },
    },
    OSS_GOMO: {
        QR_CODE_MEET_LIMITATION: {
            response_code: ngx.HTTP_FORBIDDEN,
            code: 82,
            errorMessage: i18n.t('customerCare.guestOrder.meetLimitation'),
        },
    },
    INVENTORY: {
        RESOURCE_NOT_FOUND: {
            response_code: ngx.HTTP_FORBIDDEN,
            code: 1,
            errorMessage: i18n.t('operator.resourceNotFound'),
        },
    },
    WAITLIST: {
        CHECK_ADDRESS_ID_NOT_EXIST: {
            response_code: ngx.HTTP_NOT_FOUND,
            code: 2,
        },
        SUCCESS: {
            response_code: ngx.HTTP_OK,
            code: 0,
        },
        CAPTCHA_API_VERIFY_MISSED: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 4,
        },
        CAPTCHA_API_VERIFY_FAILED: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 5,
        },
        ADDRESS_DETAILS_INVALID: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 3,
        },
        ADDRESS_NOT_COMPLETE: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 1,
        },
    },
    REFERRAL: {
        IO_ERROR: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 1,
        },
        INVALID_PARAMETER: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 2,
        },
        INVALID_MSISDN: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 7,
        },
        REFEREE_NOT_EXIST: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 9,
        },
        INVALID_REFERRER: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 4,
        },
        CODE_GENERATOR_ERROR: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 11,
        },
        SUCCESS: {
            response_code: ngx.HTTP_OK,
            code: 0,
        },
        REFERRAL_LINKING_NOT_ALLOWED: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 6,
        },
        REFERRAL_CODE_TYPE_NOT_ALLOWED: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 10,
        },
        SELF_REFERRAL: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 8,
        },
        ALREADY_REFERRED: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 5,
        },
        INVALID_REFERRAL_CODE: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 3,
        },
    },
    OSA_PAL_CATALOG: {
        LF_CATALOG_BAD_ENTITY: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 3,
        },
        SUCCESS: {
            response_code: ngx.HTTP_OK,
            code: 0,
        },
        POLL_EXTERNAL_TIMEOUT: {
            code: 1,
        },
        EXTERNAL_CATALOG_BAD_ENTITY: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 4,
        },
        LF_CATALOG_SYNC_FAILURE: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 2,
        },
    },
    OSA_TAPP_DIGIPAY: {
        AUTH_FAILED: {
            response_code: ngx.HTTP_SERVICE_UNAVAILABLE,
            code: 7,
        },
        PAYMENT_AMOUNT_INVALID: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 12,
        },
        UNKNOWN_PAYMENT_TYPE: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 15,
        },
        CONFLICT_CALLBACK_BODY_PARAMS: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 6,
        },
        INVALID_EXTERNAL_RESPONSE_BODY: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 4,
        },
        CONFLICT_REQUEST_PARAMS: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 8,
        },
        INVALID_EXTERNAL_RESPONSE: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 3,
        },
        PAYMENT_AMOUNT_CONFLICT: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 13,
        },
        INVALID_REQUEST_PARAM_TYPE: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 10,
        },
        EXTERNAL_REQUEST_FAIL: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 1,
        },
        SUCCESS: {
            response_code: ngx.HTTP_OK,
            code: 0,
        },
        PAYMENT_AMOUNT_EXCEEDED: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 11,
        },
        INVALID_REQUEST_PARAM: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 9,
        },
        UNKNOWN_EXTERNAL_RESPONSE: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 2,
        },
        PAYMENT_UNAUTHORIZED: {
            response_code: ngx.HTTP_UNAUTHORIZED,
            code: 14,
        },
        INVALID_EXTERNAL_REQUEST_BODY: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 5,
        },
    },
    CDR: {
        IO_ERROR: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 3,
        },
        INVALID: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 1,
        },
        SUCCESS: {
            response_code: ngx.HTTP_OK,
            code: 0,
        },
        MISSING_ACCOUNT_INFO: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 7,
        },
        INVALID_AGGREGATION_COLUMN: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 5,
        },
        INVALID_AGGREGATION_DIMENSION: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 6,
        },
        CASSANDRA_ERROR: {
            response_code: ngx.HTTP_SERVICE_UNAVAILABLE,
            code: 4,
        },
        MISSING_ID: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 2,
        },
    },
    AWS: {
        INVALID_IAM_ROLE: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 2,
        },
        LIBRARY_LOAD_FAILURE: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 5,
        },
        UNKNOWN: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 3,
        },
        ISSUE_WITH_METADATA_URI: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 1,
        },
        OTHER_CREDS_FAILURE: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 4,
        },
        SERVICE_PARAMETERS_DONT_EXIST: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 7,
        },
        PARAMETER_READ_BY_PATH_FAILURE: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 6,
        },
    },
    REWARDV4: {
        IO_ERROR: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 1,
        },
        PAYOUT_BULK_PROCESS_MISMATCH: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 3,
        },
        DLM_FAIL: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 2,
        },
        INVALID_PLAN_BODY: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 9,
        },
        PAYOUT_LIMIT_INVALID_TARGET: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 4,
        },
        CONDITION_INTERNAL_ERROR: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 6,
        },
        SUCCESS: {
            response_code: ngx.HTTP_OK,
            code: 0,
        },
        MISSING_REFERRER: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 11,
        },
        EXECUTION_FAILURE: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 7,
        },
        INVALID_PAYOUT_TYPE: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 10,
        },
        REPORT_VALIDATION_UNDEFINED: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 8,
        },
        MISSING_ID_IN_EVENT: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 5,
        },
    },
    VOUCHERS: {
        IO_ERROR: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 1,
        },
        INVALID: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 3,
        },
        VOUCHER_SET_EXPIRED: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 10,
        },
        INVALID_PARAMETER: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 2,
        },
        CANT_ADD_VOUCHER_CODE: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 4,
        },
        USER_INFO_MISSING: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 14,
        },
        CANT_ASSIGN_VOUCHER_CODE: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 13,
        },
        CONFIG_NO_EMAIL: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 11,
        },
        VOUCHER_CODE_ALREADY_USED: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 9,
        },
        SUCCESS: {
            response_code: ngx.HTTP_OK,
            code: 0,
        },
        NO_AVAILABLE_VOUCHERS: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 5,
        },
        TRANSACTION_ALREADY_EXISTS: {
            response_code: ngx.HTTP_CONFLICT,
            code: 12,
        },
        CANT_USE_VOUCHER_CODE: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 8,
        },
        VOUCHER_ALREADY_ASSIGNED: {
            response_code: ngx.HTTP_CONFLICT,
            code: 6,
        },
        INVALID_VOUCHER_CODE_POINTER: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 7,
        },
        DUPLICATE_VOUCHER_CODE: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 41,
            errorMessage: i18n.t('vouchers.alerts.duplicateVoucherCodeNotAdded'),
        },
        INVALID_VOUCHER_CODE_NOT_ADDED: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 63,
            errorMessage: i18n.t('vouchers.alerts.invalidVoucherCodeNotAdded'),
        },
    },
    CPQ: {
        INVALID_RELATIONSHIP_MAPPING: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 18,
        },
        INVALID_STATE_TRANSITION: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 29,
        },
        NO_AVAILABLE_DOCUMENTS: {
            response_code: ngx.HTTP_NOT_FOUND,
            code: 15,
        },
        EMPTY_QUOTE_UPDATE_ATTEMPT: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 28,
        },
        INVALID_UPDATE_STATE: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 24,
        },
        ALL_NODES_HAS_BEEN_COLORED: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 13,
        },
        ILLEGAL_KEY_VALUE_PAIR: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 6,
        },
        INVALID_QUERY: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 1,
        },
        DECISION_TREE_NOT_EXIST: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 7,
        },
        CONVERT_DECISION_ERROR: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 21,
        },
        INVALID_PRIMARY_OFFER: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 23,
        },
        VERSION_MISMATCH: {
            response_code: ngx.HTTP_CONFLICT,
            code: 25,
        },
        ES_QUERY_ERROR: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 3,
        },
        STB_ID_NOT_EXIST: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 14,
        },
        INVALID_INDEX: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 2,
        },
        MISSING_QUOTE: {
            response_code: ngx.HTTP_NOT_FOUND,
            code: 27,
        },
        INVALID_ES_CONFIG: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 5,
        },
        PRIMARY_OFFER_MISSED: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 22,
        },
        DLM_FAIL: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 26,
        },
        NO_SEARCH_RESULT: {
            response_code: ngx.HTTP_NOT_FOUND,
            code: 4,
        },
        SPEC_NOT_FOUND: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 11,
        },
        OPERATOR_NOT_ALLOWED_FOR_V3_MAPPING: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 19,
        },
        DECISION_VALUES_NOT_FOUND: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 9,
        },
        STB_NOT_FOUND: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 12,
        },
        SUCCESS: {
            response_code: ngx.HTTP_OK,
            code: 0,
        },
        CARDINALITY_EXCEEDED: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 8,
        },
        V3_FIELD_MAPPING_NOT_FOUND: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 16,
        },
        INVALID_MAPPED_DATA: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 17,
        },
        DECISION_RESOURCE_NOT_FOUND: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 10,
        },
        BAD_CONFIG_OF_MAPPING_SCHEMA: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 20,
        },
    },
    PAL: {
        UNHANDLED_PAL_API_ERROR: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 7,
        },
        NOT_FOUND: {
            response_code: ngx.HTTP_NOT_FOUND,
            code: 15,
        },
        SERVICE_UNAVAILABLE: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 14,
        },
        TIMEOUT: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 4,
        },
        SUBSCRIPTION_NOT_FOUND: {
            response_code: ngx.HTTP_NOT_FOUND,
            code: 13,
        },
        FORBIDDEN: {
            response_code: ngx.HTTP_FORBIDDEN,
            code: 6,
            errorMessage: i18n.t('alerts.youLackNecessaryPermission'),
        },
        MISSING_ERROR_CODE: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 12,
        },
        REQUEST_REJECTED: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 1,
        },
        BAD_REQUEST: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 9,
        },
        UNAUTHORIZED: {
            response_code: ngx.HTTP_UNAUTHORIZED,
            code: 10,
        },
        SUCCESS: {
            response_code: ngx.HTTP_OK,
            code: 0,
        },
        REQUEST_FAILED: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 2,
        },
        SUBSCRIPTION_CONFLICT: {
            response_code: ngx.HTTP_CONFLICT,
            code: 5,
        },
        STATUS_ID_NOT_FOUND: {
            response_code: ngx.HTTP_NOT_FOUND,
            code: 3,
        },
        INTERNAL_ERROR: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 11,
        },
        UNHANDLED_ERROR: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 8,
        },
    },
    OSA_DIGICEL_PANAMA_AUTH: {
        INVALID_EXTERNAL_ID_FORMAT: {
            response_code: ngx.HTTP_MOVED_TEMPORARILY,
            code: 14,
        },
        GET_ACCOUNT_REQUEST_FAILED: {
            response_code: ngx.HTTP_MOVED_TEMPORARILY,
            code: 7,
        },
        MISSING_STATE: {
            response_code: ngx.HTTP_MOVED_TEMPORARILY,
            code: 15,
        },
        MISSING_AUTH_TOKEN: {
            response_code: ngx.HTTP_MOVED_TEMPORARILY,
            code: 4,
        },
        REDIRECT: {
            response_code: ngx.HTTP_MOVED_TEMPORARILY,
            code: 1,
        },
        REFRESH_TOKEN_MISSING: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 21,
        },
        AUTH_SERVER_ERROR: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 3,
        },
        MISSING_CLIENT_VERSION: {
            response_code: ngx.HTTP_MOVED_TEMPORARILY,
            code: 5,
        },
        MISSING_EXTERNAL_SERVER_CONFIG: {
            response_code: ngx.HTTP_MOVED_TEMPORARILY,
            code: 12,
        },
        LF_TOKEN_GENERATION_FAILED: {
            response_code: ngx.HTTP_MOVED_TEMPORARILY,
            code: 10,
        },
        REFRESHED_ACCESS_TOKEN_MISSING: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 23,
        },
        MSISDN_LOOKUP_FAILED: {
            response_code: ngx.HTTP_MOVED_TEMPORARILY,
            code: 8,
        },
        MISSING_APPLICATION_ID: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 2,
        },
        REFRESH_TOKEN_REQUEST_FAILED: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 22,
        },
        ACCESS_TOKEN_REQUEST_FAILED: {
            response_code: ngx.HTTP_MOVED_TEMPORARILY,
            code: 6,
        },
        USER_REGISTRATION_FAILED: {
            response_code: ngx.HTTP_MOVED_TEMPORARILY,
            code: 9,
        },
        MISSING_DIGICEL_ACCOUNT_PROPERTIES: {
            response_code: ngx.HTTP_MOVED_TEMPORARILY,
            code: 13,
        },
        MISSING_AUTH_CODE: {
            response_code: ngx.HTTP_MOVED_TEMPORARILY,
            code: 11,
        },
        REFRESH_TOKEN_SUCCESS: {
            response_code: ngx.HTTP_OK,
            code: 20,
        },
    },
    PORT_OUT: {
        PORT_OUT_MSISDN_MISMATCH: {
            response_code: ngx.HTTP_FORBIDDEN,
            code: 3,
            errorMessage: i18n.t('alerts.youLackNecessaryPermission'),
        },
        ACKNOWLEDGE: {
            response_code: ngx.HTTP_OK,
            code: 1,
        },
        INVALID_PARAMETER: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 2,
        },
        PORT_REQUEST_ID_EXISTS: {
            response_code: ngx.HTTP_CONFLICT,
            code: 4,
        },
        PIN_ALREADY_CONSUMED: {
            response_code: ngx.HTTP_CONFLICT,
            code: 5,
        },
        SUCCESS: {
            response_code: ngx.HTTP_OK,
            code: 0,
        },
    },
    AUTH: {
        BLOCKED: {
            response_code: ngx.HTTP_FORBIDDEN,
            code: 14,
            errorMessage: i18n.t('alerts.youLackNecessaryPermission'),
        },
        IP_NOT_WHITELISTED: {
            response_code: ngx.HTTP_FORBIDDEN,
            code: 33,
            errorMessage: i18n.t('alerts.youLackNecessaryPermission'),
        },
        EXPIRED: {
            response_code: ngx.HTTP_FORBIDDEN,
            code: 3,
            errorMessage: i18n.t('alerts.youLackNecessaryPermission'),
        },
        INVALID_ACCOUNT_STATE: {
            response_code: ngx.HTTP_FORBIDDEN,
            code: 31,
            errorMessage: i18n.t('alerts.youLackNecessaryPermission'),
        },
        INVALID_NONCE: {
            response_code: ngx.HTTP_FORBIDDEN,
            code: 20,
            errorMessage: i18n.t('alerts.youLackNecessaryPermission'),
        },
        INVALID_CLIENTTOKEN_VERSION: {
            response_code: ngx.HTTP_FORBIDDEN,
            code: 7,
            errorMessage: i18n.t('alerts.youLackNecessaryPermission'),
        },
        INVALID_TARGET_TYPE: {
            response_code: ngx.HTTP_FORBIDDEN,
            code: 30,
            errorMessage: i18n.t('alerts.youLackNecessaryPermission'),
        },
        UNKNOWN_SOCIAL_NETWORK: {
            response_code: ngx.HTTP_FORBIDDEN,
            code: 23,
            errorMessage: i18n.t('alerts.youLackNecessaryPermission'),
        },
        INVALID_TARGET_ID: {
            response_code: ngx.HTTP_FORBIDDEN,
            code: 36,
            errorMessage: i18n.t('alerts.youLackNecessaryPermission'),
        },
        UNKNOWN: {
            response_code: ngx.HTTP_FORBIDDEN,
            code: 21,
            errorMessage: i18n.t('alerts.youLackNecessaryPermission'),
        },
        JWT_SIGNATURE_INVALID: {
            response_code: ngx.HTTP_FORBIDDEN,
            code: 26,
            errorMessage: i18n.t('alerts.youLackNecessaryPermission'),
        },
        JWT_LOAD_ERROR: {
            response_code: ngx.HTTP_FORBIDDEN,
            code: 25,
            errorMessage: i18n.t('alerts.youLackNecessaryPermission'),
        },
        BAD_SIGNATURE: {
            response_code: ngx.HTTP_FORBIDDEN,
            code: 4,
            errorMessage: i18n.t('alerts.youLackNecessaryPermission'),
        },
        INVALID_PATH: {
            response_code: ngx.HTTP_FORBIDDEN,
            code: 9,
            errorMessage: i18n.t('alerts.youLackNecessaryPermission'),
        },
        SUCCESS: {
            response_code: ngx.HTTP_OK,
            code: 0,
        },
        INVALID_ISS: {
            response_code: ngx.HTTP_FORBIDDEN,
            code: 37,
            errorMessage: i18n.t('alerts.youLackNecessaryPermission'),
        },
        INVALID_SOCIAL_RESPONSE: {
            response_code: ngx.HTTP_FORBIDDEN,
            code: 24,
            errorMessage: i18n.t('alerts.youLackNecessaryPermission'),
        },
        INVALID_VERSION: {
            response_code: ngx.HTTP_FORBIDDEN,
            code: 10,
            errorMessage: i18n.t('alerts.youLackNecessaryPermission'),
        },
        MISSING_IDENTIFIER: {
            response_code: ngx.HTTP_FORBIDDEN,
            code: 12,
            errorMessage: i18n.t('alerts.youLackNecessaryPermission'),
        },
        TOO_MANY_IDENTIFIERS: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 15,
        },
        CLIENT_EPOCH_TOO_OLD: {
            response_code: ngx.HTTP_FORBIDDEN,
            code: 6,
            errorMessage: i18n.t('alerts.youLackNecessaryPermission'),
        },
        INVALID_SUBSCRIBER_STATE: {
            response_code: ngx.HTTP_FORBIDDEN,
            code: 13,
            errorMessage: i18n.t('alerts.youLackNecessaryPermission'),
        },
        INVALID_CONTEXT_OPTIONS: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 38,
        },
        MISSING: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 11,
        },
        INVALID: {
            response_code: ngx.HTTP_FORBIDDEN,
            code: 1,
            errorMessage: i18n.t('alerts.youLackNecessaryPermission'),
        },
        INVALID_KEY_ID: {
            response_code: ngx.HTTP_FORBIDDEN,
            code: 2,
            errorMessage: i18n.t('alerts.youLackNecessaryPermission'),
        },
        JWT_SIGNING_KEY_NOT_FOUND: {
            response_code: ngx.HTTP_FORBIDDEN,
            code: 35,
            errorMessage: i18n.t('alerts.youLackNecessaryPermission'),
        },
        JWT_AUDIENCE_INVALID: {
            response_code: ngx.HTTP_FORBIDDEN,
            code: 27,
            errorMessage: i18n.t('alerts.youLackNecessaryPermission'),
        },
        TOKEN_BLOCKER: {
            response_code: ngx.HTTP_FORBIDDEN,
            code: 8,
            errorMessage: i18n.t('alerts.youLackNecessaryPermission'),
        },
        INVALID_OPERATOR_NAME: {
            response_code: ngx.HTTP_FORBIDDEN,
            code: 17,
            errorMessage: i18n.t('alerts.youLackNecessaryPermission'),
        },
        JWT_ALGORITHM_INVALID: {
            response_code: ngx.HTTP_FORBIDDEN,
            code: 28,
            errorMessage: i18n.t('alerts.youLackNecessaryPermission'),
        },
        WRONG_IDENTIFIER: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 16,
        },
        INVALID_SUB_TENANT: {
            response_code: ngx.HTTP_FORBIDDEN,
            code: 22,
            errorMessage: i18n.t('alerts.youLackNecessaryPermission'),
        },
        INVALID_APPLICATION_ID: {
            response_code: ngx.HTTP_FORBIDDEN,
            code: 29,
            errorMessage: i18n.t('alerts.youLackNecessaryPermission'),
        },
        KEY_ID_MISSING: {
            response_code: ngx.HTTP_FORBIDDEN,
            code: 18,
            errorMessage: i18n.t('alerts.youLackNecessaryPermission'),
        },
        INVALID_TOKEN_TYPE: {
            response_code: ngx.HTTP_FORBIDDEN,
            code: 19,
            errorMessage: i18n.t('alerts.youLackNecessaryPermission'),
        },
        BAD_ROUTE_PERMISSION: {
            response_code: ngx.HTTP_FORBIDDEN,
            code: 5,
            errorMessage: i18n.t('alerts.youLackNecessaryPermission'),
        },
        JWT_INVALID: {
            response_code: ngx.HTTP_FORBIDDEN,
            code: 34,
            errorMessage: i18n.t('alerts.youLackNecessaryPermission'),
        },
        INVALID_PASSWORD_HASHING_ALG: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 32,
        },
    },
    SES: {
        SES_FAILURE: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 5,
        },
        BAD_SOURCE: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 1,
        },
        BAD_BCC: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 6,
        },
        BAD_MESSAGE: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 3,
        },
        BAD_DESTINATION: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 2,
        },
        BAD_SUBJECT: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 4,
        },
    },
    OSA: {
        AUTH_FAILED: {
            response_code: ngx.HTTP_FORBIDDEN,
            code: 4,
            errorMessage: i18n.t('alerts.youLackNecessaryPermission'),
        },
        INVALID_INPUT: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 1,
        },
        CACHE_FAILED: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 5,
        },
        MISSING_TOKEN_GENERATOR: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 15,
        },
        CASSANDRA_ERROR: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 7,
        },
        TOKEN_REQUEST_FAILED: {
            response_code: ngx.HTTP_SERVICE_UNAVAILABLE,
            code: 8,
        },
        EXTERNAL_API_FAIL: {
            response_code: ngx.HTTP_BAD_GATEWAY,
            code: 3,
        },
        NO_EXTERNAL_RESOURCES: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 10,
        },
        SUCCESS: {
            response_code: ngx.HTTP_OK,
            code: 0,
        },
        INVALID_TARGET_ID: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 12,
        },
        CONFIG_MISSING: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 6,
        },
        INVALID_TARGET_TYPE: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 11,
        },
        INVALID_ID_S3_URL: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 14,
        },
        INVALID_EXTERNAL_RESPONSE: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 2,
        },
    },
    OSA_CACHE: {
        INIT_ERROR: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 4,
        },
        INTERNAL_ERROR: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 3,
        },
        SUCCESS: {
            response_code: ngx.HTTP_OK,
            code: 0,
        },
        CASSANDRA_ERROR: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 1,
        },
    },
    COMMON: {
        UNKNOWN_CHANNEL: {
            response_code: ngx.HTTP_FORBIDDEN,
            code: 5,
            errorMessage: i18n.t('alerts.youLackNecessaryPermission'),
        },
        IOERROR: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 6,
        },
        BAD_CONFIG: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 9,
        },
        NOT_IMPLEMENTED: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 3,
        },
        DISABLED: {
            response_code: ngx.HTTP_SERVICE_UNAVAILABLE,
            code: 13,
        },
        CALLER_ERROR: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 8,
        },
        INVALID: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 2,
        },
        UNKNOWN: {
            response_code: ngx.HTTP_FORBIDDEN,
            code: 1,
            errorMessage: i18n.t('alerts.youLackNecessaryPermission'),
        },
        INVALID_MSISDN_FORMAT: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 15,
        },
        INVALID_OPERATOR_NAME: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 4,
        },
        CALLER_ACCOUNT_UNKNOWN: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 10,
        },
        SUCCESS: {
            response_code: ngx.HTTP_OK,
            code: 0,
        },
        PROXY_TOKEN_GEN_FAILURE: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 12,
        },
        USER_NOT_ACTIVATED: {
            response_code: ngx.HTTP_CONFLICT,
            code: 16,
        },
        NOT_FOUND: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 7,
        },
        FORBIDDEN_BY_RULE: {
            response_code: ngx.HTTP_FORBIDDEN,
            code: 11,
            errorMessage: i18n.t('alerts.youLackNecessaryPermission'),
        },
        PARSER_ERROR: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 14,
        },
    },
    ESIM: {
        ICCID_ALREADY_LINKED: {
            response_code: ngx.HTTP_CONFLICT,
            code: 8,
        },
        INVALID_PARAMETER: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 9,
        },
        NEW_PROFILE_LIMIT_REACHED: {
            response_code: ngx.HTTP_CONFLICT,
            code: 2,
        },
        MORE_THAN_ONE_ACTIVE_ICCID: {
            response_code: ngx.HTTP_CONFLICT,
            code: 18,
        },
        ICCID_NOT_ASSIGNED: {
            response_code: ngx.HTTP_CONFLICT,
            code: 10,
        },
        OSA_INVALID_EXTERNAL_RESPONSE: {
            response_code: ngx.HTTP_BAD_GATEWAY,
            code: 13,
        },
        SUCCESS: {
            response_code: ngx.HTTP_OK,
            code: 0,
        },
        MISSING_PARAMETER: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 17,
        },
        IDENTIFIERS_VALIDATION_ERROR: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 15,
        },
        ACCEPTED: {
            response_code: ngx.HTTP_ACCEPTED,
            code: 1,
        },
        INTERNAL_ERROR: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 12,
        },
        BAD_PROFILE_STATE: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 11,
        },
    },
    BRAINTREE: {
        MISSING_PAYMENT_METHOD: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 2,
        },
        MISSING_GATEWAY_ID: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 13,
        },
        INVALID_CVV: {
            response_code: ngx.HTTP_CONFLICT,
            code: 10,
        },
        MISSING_PAYMENT_NONCE: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 3,
        },
        INVALID_CREDIT_CARD_NUMBER: {
            response_code: ngx.HTTP_CONFLICT,
            code: 9,
        },
        NON_US_CARD_UNSUPPORTED: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 28,
        },
        EXPIRED_CARD: {
            response_code: ngx.HTTP_CONFLICT,
            code: 8,
        },
        INSUFFICIENT_FUNDS: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 7,
        },
        RESOURCE_NOT_FOUND: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 14,
        },
        MISSING_INTERNAL_TRANSACTION_ID: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 18,
        },
        INVALID_EXPIRATION_DATE: {
            response_code: ngx.HTTP_CONFLICT,
            code: 20,
        },
        PREPAID_CARD_UNSUPPORTED: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 27,
        },
        PROCESSOR_ERROR: {
            response_code: ngx.HTTP_CONFLICT,
            code: 19,
        },
        UNKNOWN_PAYMENT_INSTRUMENT_TYPE: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 25,
        },
        BT_CUSTOMER_MISSING: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 12,
        },
        VOID_FORBIDDEN: {
            response_code: ngx.HTTP_CONFLICT,
            code: 24,
        },
        EXTERNAL_API_ERROR: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 6,
        },
        TRANSACTION_ALREADY_FULLY_REFUNDED: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 23,
        },
        VALIDATION_ERROR: {
            response_code: ngx.HTTP_CONFLICT,
            code: 11,
        },
        NONCE_NOT_VALID: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 5,
        },
        REFUND_AMOUNT_TOO_LARGE: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 22,
        },
        INVALID_POSTAL_CODE: {
            response_code: ngx.HTTP_CONFLICT,
            code: 16,
        },
        INVALID_BRAITREE_RESPONSE_FORMAT: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 21,
        },
        MISSING_DEVICE_DATA: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 26,
        },
        PAYMENT_METHOD_VERIFICATION_ERROR: {
            response_code: ngx.HTTP_CONFLICT,
            code: 15,
        },
        INVALID_XML_FORMAT: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 1,
        },
        DEBIT_CARD_UNSUPPORTED: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 17,
        },
    },
    CDP: {
        INVALID: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 5,
        },
        NOT_FOUND: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 13,
        },
        API_KEY_MISSING: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 9,
        },
        FILTER_INSTANCE_VALIDATION_ERROR: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 15,
        },
        COULD_NOT_PARSE: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 12,
        },
        MISSING_EVENT_VALIDATOR: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 11,
        },
        VALIDATION_ERROR: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 14,
        },
        CARDINALITY_MISSING: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 10,
        },
        INIT_ERROR: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 6,
        },
        SUCCESS: {
            response_code: ngx.HTTP_OK,
            code: 0,
        },
        IO_ERROR_KAFKA: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 3,
        },
        IO_ERROR_CASSANDRA: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 1,
        },
        GCM_CONFIG_MISSING: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 8,
        },
        NO_CARRIER_APP_ID: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 7,
        },
        IO_ERROR_REDIS: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 2,
        },
    },
    CONDITION: {
        CONDITION_NOT_VALIDATED: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 1,
        },
        SUCCESS: {
            response_code: ngx.HTTP_OK,
            code: 0,
        },
        CONDITION_INTERNAL_ERROR: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 2,
        },
    },
    SEGMENT_MANAGER: {
        SUCCESS: {
            response_code: ngx.HTTP_OK,
            code: 0,
        },
        INVALID: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 1,
        },
        INTERNAL_ERROR: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 2,
        },
        INIT_ERROR: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 3,
        },
        EXTERNAL_DATA_FETCH_FAILURE: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 5,
        },
        TOO_MANY_SEGMENT_ENTRY_TYPES_IN_COHORT_EXPRESSION: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 9,
            errorMessage: i18n.t('segments.alerts.toManyEntryTypesInCohortExpression'),
        },
        DIFFERENT_SEGMENT_ENTRY_TYPES_IN_COHORT_EXPRESSION: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 10,
            errorMessage: i18n.t('segments.alerts.differentEntryTypesInCohortExpression'),
        },
        UNDEFINED_ENTRY_TYPE_IN_COHORT_EXPRESSION: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 11,
            errorMessage: i18n.t('segments.alerts.unknownEntryType'),
        },
        ID_VALUES_MAP_VALIDATION_FAILURE: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 15,
            errorMessage: i18n.t('segments.alerts.invalidIdValuesMap'),
        },
        UNSUPPORTED_ID_VALUES_MAP_TYPE: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 16,
            errorMessage: i18n.t('segments.alerts.unknownEntryType'),
        },
        UNSUPPORTED_COHORT_EXPRESSION_ENTRY_TYPE: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 17,
            errorMessage: i18n.t('segments.alerts.unknownEntryType'),
        },
        CORRUPTED_COHORT_EXPRESSION_VALUE: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 18,
            errorMessage: i18n.t('segments.alerts.invalidIdValuesMap'),
        },
        ID_VALUES_MAP_CARDINALITY_EXPECTED: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 24,
            errorMessage: i18n.t('segments.alerts.numberIsExpectedForCardinalityBasedEntryType'),
        },
        USER_CARDINALITY_NOT_FOUND: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 25,
            errorMessage: i18n.t('segments.alerts.userNotFound'),
        },
        SAME_ENTRIES_FORBIDDEN: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 26,
            errorMessage: i18n.t('segments.alerts.sameEntriesAddedAndDeleted'),
        },
        SEGMENT_CONFIG_NOT_FOUND: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 27,
            errorMessage: i18n.t('segments.alerts.segmentNotFound'),
        },
    },
    EVENT_JSON_SCHEMA: {
        SUCCESS: {
            response_code: ngx.HTTP_OK,
            code: 0,
        },
        FIELD_MISSING: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 4,
            errorMessage: i18n.t('avroSchema.alerts.fieldMissing'),
        },
        WRONG_FIELD_TYPE: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 7,
        },
        CONVERSION_ERROR: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 10,
        },
        INCOMPATIBLE_UPDATE_IS_FORBIDDEN: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 11,
            errorMessage: i18n.t('avroSchema.alerts.incompatibleUpdateIsForbidden'),
        },
        BATCH_ERRORS: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 12,
            errorMessage: i18n.t('avroSchema.alerts.batchErrors'),
        },
        UPDATE_SCHEMA_NAME_FORBIDDEN: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 14,
            errorMessage: i18n.t('avroSchema.alerts.updateSchemaNameForbidden'),
        },
        OPTIONAL_FIELD_WRONG_TYPE: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 16,
            errorMessage: i18n.t('avroSchema.alerts.optionalFieldWrongType'),
        },
        NEW_FIELDS_SHOULD_BE_OPTIONAL: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 17,
            errorMessage: i18n.t('avroSchema.alerts.newFieldsShouldBeOptional'),
        },
        REQUIRED_FIELD_NOT_CONFIGURED: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 18,
            errorMessage: i18n.t('avroSchema.alerts.requiredFieldNotConfigured'),
        },
    },
    TEMPLATE: {
        IO_ERROR: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 3,
        },
        INVALID: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 2,
        },
        UNKNOWN: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 1,
        },
        TEMPLATE_NOT_FOUND: {
            response_code: ngx.HTTP_NOT_FOUND,
            code: 5,
        },
        INVALID_REQUEST: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 6,
        },
        INVALID_TEMPLATE_DATA: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 7,
        },
        TEMPLATE_SCHEMA_NOT_EXIST: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 4,
        },
        SUCCESS: {
            response_code: ngx.HTTP_OK,
            code: 0,
        },
        INVALID_EXTERNAL_ID: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 10,
        },
        TEMPLATE_WAS_USED: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 9,
            errorMessage: i18n.t('productCatalog.templates.alerts.templateWasUsed'),
        },
        STATE_TRANSFER_NOT_ALLOWED: {
            response_code: ngx.HTTP_CONFLICT,
            code: 8,
        },
        INVALID_DEFAULT_VALUE: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 11,
            errorMessage: i18n.t('productCatalog.templates.alerts.invalidDefaultValue'),
        },
        EMPTY_PROPERTY_DEFINITIONS: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 30,
            errorMessage: i18n.t('productCatalog.templates.alerts.emptyPropertyDefinitions'),
        },
    },
    DPP: {
        IO_ERROR: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 1,
        },
        INVALID: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 2,
        },
        INVALID_PACKAGE_NAME: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 4,
        },
        MISSING_DATA: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 10,
        },
        INVALID_OPERATOR_NAME: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 3,
        },
        CONFLICT: {
            response_code: ngx.HTTP_CONFLICT,
            code: 7,
        },
        THIRD_PARTY_NETWORK_ERROR: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 9,
        },
        SUCCESS: {
            response_code: ngx.HTTP_OK,
            code: 0,
        },
        CONFIG_MISSING: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 8,
        },
        EXTERNAL_PROVISIONING_REQUEST_FAILED: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 5,
        },
        EXTERNAL_PROVISIONING_FAILED: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 6,
        },
    },
    REMOTE_CONFIG: {
        IO_ERROR: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 2,
        },
        INVALID_DECODE: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 7,
        },
        INVALID_VISIBILITY: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 3,
        },
        INVALID_VALUE: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 4,
        },
        INVALID_KEY: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 1,
        },
        MISSING_PORTAL_ID: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 6,
        },
        INVALID_KEYS: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 5,
        },
        SUCCESS: {
            response_code: ngx.HTTP_OK,
            code: 0,
        },
    },
    SEGMENT_CONFIG: {
        IO_ERROR: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 1,
        },
        SUCCESS: {
            response_code: ngx.HTTP_OK,
            code: 0,
        },
        NOT_FOUND: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 2,
            errorMessage: i18n.t('segments.filters.alerts.segmentNotFound'),
        },
        NAME_ALREADY_EXISTS: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 4,
            errorMessage: i18n.t('segments.filters.alerts.segmentNameAlreadyExists'),
        },
        NAME_IS_EMPTY: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 3,
            errorMessage: i18n.t('segments.filters.alerts.segmentNameIsMissing'),
        },
        ENTITY_IN_USE: {
            response_code: ngx.HTTP_CONFLICT,
            code: 5,
            errorMessage: i18n.t('segments.filters.alerts.entityInUse'),
        },
        RANGE_VALIDATION_ERROR: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 6,
            errorMessage: i18n.t('segments.filters.alerts.rangeValidationError'),
        },
        MISSING_ENTRY_TYPE_IN_FILTER_ID: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 7,
            errorMessage: i18n.t('segments.filters.alerts.entryTypeIsMissing'),
        },
        STATIC_FILTER_NOT_FOUND: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 8,
            errorMessage: i18n.t('segments.filters.alerts.segmentNotFound'),
        },
        DWHCONFIG_NOT_FOUND: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 10,
            errorMessage: i18n.t('segments.filters.alerts.dwhconfigNotFound'),
        },
        DWHCONFIG_FIELD_NOT_FOUND: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 11,
            errorMessage: i18n.t('segments.filters.alerts.dwhconfigFieldNotFound'),
        },
        DELETE_INTERNAL_SEGMENT_RESTRICTED: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 12,
            errorMessage: i18n.t('segments.filters.alerts.deleteInternalSegmentRestricted'),
        },
        UPDATE_INTERNAL_SEGMENT_RESTRICTED: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 12,
            errorMessage: i18n.t('segments.filters.alerts.updateInternalSegmentRestricted'),
        },
    },
    ASSET: {
        INVALID: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 3,
        },
        SUCCESS: {
            response_code: ngx.HTTP_OK,
            code: 0,
        },
        INVALID_ASSET_TYPE: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 2,
        },
        ASSET_ALREADY_EXISTS: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 6,
        },
        IOERROR: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 1,
        },
        CANT_UPDATE_ASSET: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 5,
        },
        ASSET_NOT_FOUND: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 4,
        },
    },
    BATCH_EXECUTOR: {
        SUCCESS: {
            response_code: ngx.HTTP_OK,
            code: 0,
        },
        CASSANDRA_ERROR: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 1,
        },
    },
    ENTITY_USAGE_TRACKER: {
        EMPTY_CONSUMER_ID: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 4,
        },
        ENTITY_WAS_DELETED: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 3,
        },
        NOT_FOUND: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 2,
        },
        SUCCESS: {
            response_code: ngx.HTTP_OK,
            code: 0,
        },
        IO_CASSANDRA_ERROR: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 1,
        },
    },
    GOOGLE_PLACES_API: {
        UNKNOWN_ERROR: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 1,
        },
        SUCCESS: {
            response_code: ngx.HTTP_OK,
            code: 0,
        },
        UNKNOWN_RESPONSE_STATUS: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 6,
        },
        OVER_QUERY_LIMIT: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 3,
        },
        NOT_FOUND: {
            response_code: ngx.HTTP_NOT_FOUND,
            code: 5,
        },
        REQUEST_DENIED: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 4,
        },
        ZERO_RESULTS: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 2,
        },
    },
    CATALOG: {
        V4_ADAPTOR_ERROR: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 26,
        },
        DELETING_REFERENCED_ENTITY: {
            response_code: ngx.HTTP_CONFLICT,
            code: 6,
            errorMessage: i18n.t('productCatalog.entities.alerts.deletingReferencedEntity'),
        },
        INVALID_ENTITY_TYPE: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 7,
        },
        AMEND_NON_EXIST_ENTITY: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 17,
        },
        MISSING_PAYOUT_PRODUCTS: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 10,
        },
        CONFLICT_AMENDMENT: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 16,
        },
        INVALID_PLAN_TYPE: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 21,
        },
        MISSING_SUBTYPE: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 25,
        },
        EMPTY_AMENDMENT_BODY: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 18,
        },
        UNKNOWN: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 11,
        },
        UNAMENDABLE_FIELD: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 15,
        },
        HTTP_NOT_MODIFIED: {
            response_code: ngx.HTTP_NOT_MODIFIED,
            code: 23,
        },
        INVALID: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 1,
        },
        MISSING_REFERENCED_ENTITY: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 8,
        },
        WRONG_VERSION: {
            response_code: ngx.HTTP_CONFLICT,
            code: 3,
        },
        INVALID_PLAN: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 22,
        },
        INDEX_CONFLICT: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 20,
            errorMessage: i18n.t('productCatalog.entities.alerts.indexConflict'),
        },
        NOT_FOUND: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 5,
        },
        WRONG_STATE: {
            response_code: ngx.HTTP_CONFLICT,
            code: 4,
        },
        DUPLICATE_PAYOUT_PRODUCTS: {
            response_code: ngx.HTTP_CONFLICT,
            code: 12,
        },
        IOERROR: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 2,
        },
        DUPLICATE_RATING_GROUP: {
            response_code: ngx.HTTP_CONFLICT,
            code: 9,
        },
        AMENDMENT_MISSING_RELATED_FIELD: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 19,
        },
        CANCEL_ACTIVATED_AMENDMENT: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 14,
        },
        AMENDMENT_ACTIVATION_DELAY_TOO_SHORT: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 13,
        },
        UNALLOWED_LANGUAGE_CODE: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 24,
        },
        SUCCESS: {
            response_code: ngx.HTTP_OK,
            code: 0,
        },
        ENTITY_ALREADY_EXISTS: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 47,
            errorMessage: i18n.t('productCatalog.entities.alerts.entityAlreadyExists'),
        },
        MISMATCHED_CURRENCY_TYPE: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 50,
            errorMessage: i18n.t('productCatalog.offers.alerts.mismatchedCurrencyType'),
        },
        FORBID_TO_ADD_PRESET_HAS_REQUIRED_FIELD: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 53,
            errorMessage: i18n.t('productCatalog.templates.alerts.forbidToAddPresetHasRequiredField'),
        },
    },
    ACQUISITION: {
        CAMPAIGN_NOT_EXIST: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 9,
        },
        INVALID_ID: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 1,
        },
        INVALID_CONFIGURATION: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 12,
        },
        ENTITY_ID_ALREADY_CONSUMED: {
            response_code: ngx.HTTP_CONFLICT,
            code: 10,
        },
        NOT_ELIGIBLE: {
            response_code: ngx.HTTP_CONFLICT,
            code: 11,
        },
        TOO_MANY_ANSWERS: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 8,
        },
        INVALID_ID_FORMAT: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 5,
        },
        SUCCESS: {
            response_code: ngx.HTTP_OK,
            code: 0,
        },
        INVALID_PROPERTIES: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 4,
        },
        PROPERTIES_MISSING: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 3,
        },
        ENTITY_ID_ALREADY_EXISTS: {
            response_code: ngx.HTTP_CONFLICT,
            code: 6,
        },
        INVALID_TYPE: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 2,
        },
        MISSING_ENTITY_ID: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 7,
        },
    },
    ORD_CONFIG: {
        AUTH_ERROR: {
            response_code: ngx.HTTP_UNAUTHORIZED,
            code: 7,
        },
        INVALID_LOCATION: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 6,
        },
        SUCCESS: {
            response_code: ngx.HTTP_OK,
            code: 0,
        },
        EXTERNAL_ERROR: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 2,
        },
        REPORT_DEFINITION_ID_ALREADY_EXISTS: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 5,
        },
        INVALID_KAFKA_TOPIC_NAME: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 3,
        },
        INTERNAL_ERROR: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 1,
        },
        INVALID_INTERVAL: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 4,
        },
    },
    OSS_DEVEDGE: {
        SUCCESS: {
            response_code: ngx.HTTP_OK,
            code: 0,
        },
        IO_ERROR: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 1,
        },
        APP_NOT_FOUND_IN_REVERSE_INDEX_TABLE: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 94,
        },
    },
    CHARGING: {
        FORCED_COMMIT: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 36,
        },
        UNKNOWN_POLICY_COUNTERS: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 40,
        },
        SERVICE_NOT_AVAILABLE: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 10,
        },
        BLACKLISTED_NUMBER: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 37,
        },
        TRANSACTION_ID_ALREADY_USED: {
            response_code: ngx.HTTP_CONFLICT,
            code: 27,
        },
        MAX_TOP_UP_REACHED: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 35,
        },
        RATE_NOT_FOUND: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 9,
        },
        DLM_FAIL: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 14,
        },
        INVALID_FLOW_OVERCOMMIT: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 19,
        },
        ORDER_ALREADY_USED: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 21,
        },
        TOP_UP_NOT_FOUND: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 24,
        },
        INTERNAL_ERROR: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 26,
        },
        OFFLINE_REQUEST_ALREADY_PROCESSED: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 34,
        },
        REQUEST_ALREADY_PROCESSED: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 32,
        },
        INVALID_SERVICE_TYPE: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 15,
        },
        FAILED_TO_NOTIFY_USER: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 16,
        },
        IOERROR: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 3,
        },
        NO_AVAILABLE_POLICY_COUNTERS: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 39,
        },
        INVALID_ORDER_DURATION: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 30,
        },
        UNSUPPORTED_WALLET_TYPE: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 25,
        },
        RECURRING_ORDER_NOT_FOUND: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 38,
        },
        SUCCESS: {
            response_code: ngx.HTTP_OK,
            code: 0,
        },
        INVALID_RATING_GROUP: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 13,
        },
        MAXIMUM_WALLET_AMOUNT_REACHED: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 29,
        },
        NO_BUCKET_METADATA: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 17,
        },
        SUBSCRIPTION_NOT_FOUND: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 41,
        },
        INVALID: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 2,
        },
        WALLET_NOT_ACTIVE: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 18,
        },
        PROXY_FAILED: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 4,
        },
        ORDER_NOT_FOUND: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 22,
        },
        INVALID_FLOW_COMMIT_MISSING: {
            response_code: ngx.HTTP_CONFLICT,
            code: 12,
        },
        UNKNOWN: {
            response_code: ngx.HTTP_FORBIDDEN,
            code: 1,
            errorMessage: i18n.t('alerts.youLackNecessaryPermission'),
        },
        RESERVED_AGAIN: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 33,
        },
        MSISDN_MISSING: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 28,
        },
        SUBSCRIBER_IS_BLOCKED: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 20,
        },
        INVALID_RECURRENCE_STATE: {
            response_code: ngx.HTTP_CONFLICT,
            code: 8,
        },
        PROXY_TOKEN_GEN_FAILURE: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 5,
        },
        TOP_UP_ALREADY_USED: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 23,
        },
        NO_RESERVATION_AVAILABLE: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 11,
        },
        INSUFFICIENT_FUNDS: {
            response_code: ngx.HTTP_UNPROCESSABLE_ENTITY,
            code: 6,
        },
        SUBSCRIBER_NOT_ACTIVE: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 7,
        },
    },
    DISTRIBUTED_LOCK_MANAGER: {
        INTERNAL_ERROR: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 3,
        },
        NOT_APPLIED: {
            response_code: ngx.HTTP_SERVICE_UNAVAILABLE,
            code: 2,
        },
        SUCCESS: {
            response_code: ngx.HTTP_OK,
            code: 0,
        },
        DATA_ACCESS_ERROR: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 1,
        },
    },
    PORT_IN: {
        PORT_ATTEMPTS_NUMBER_EXCEEDED: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 5,
        },
        FAILURE_NO_DATA_FOUND: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 8,
        },
        FAILURE_INVALID_FIELD: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 12,
        },
        REQUEST_EXIST: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 6,
        },
        FAILURE_ACMS_UNRECOVERABLE: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 15,
        },
        NUMBER_NOT_ELIGIBLE: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 4,
        },
        ACKNOWLEDGE: {
            response_code: ngx.HTTP_ACCEPTED,
            code: 1,
        },
        FAILURE_ACMS_INTERNAL_ERROR: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 16,
        },
        FAILURE_UNKNOWN: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 13,
        },
        INVALID_PARAMETER: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 2,
        },
        SERVICE_PROVIDER_NOT_FOUND: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 3,
        },
        INVALID_FORCE_RESUME_STATE: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 14,
        },
    },
    TRANSACTION_MANAGER: {
        MUTATE_NOT_ALLOWED: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 3,
        },
        INIT_TRANSACTION_FAIL: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 2,
        },
        SUCCESS: {
            response_code: ngx.HTTP_OK,
            code: 0,
        },
        TRANSACTION_NOT_EXISTS: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 4,
        },
        CASSANDRA_ERROR: {
            response_code: ngx.HTTP_SERVICE_UNAVAILABLE,
            code: 1,
        },
    },
    EVENT: {
        SUCCESS: {
            response_code: ngx.HTTP_OK,
            code: 0,
        },
        KAFKA_ERROR: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 1,
        },
        INVALID_BODY: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 2,
        },
        EVENT_TARGET_AUTH_MISMATCH: {
            response_code: ngx.HTTP_FORBIDDEN,
            code: 3,
            errorMessage: i18n.t('alerts.youLackNecessaryPermission'),
        },
        UNREGISTERED_ID_TYPE_FORBIDDEN: {
            response_code: ngx.HTTP_FORBIDDEN,
            code: 4,
            errorMessage: i18n.t('alerts.youLackNecessaryPermission'),
        },
        APPLICATION_ID_DOES_NOT_MATCH: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 5,
        },
        VALIDATION_ERROR: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 6,
        },
        SCHEMA_NOT_FOUND: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 14,
            errorMessage: i18n.t('events.alerts.eventSchemaNotFound'),
        },
        EVENT_IS_NOT_ALLOWED: {
            response_code: ngx.HTTP_UNAUTHORIZED,
            code: 20,
            errorMessage: i18n.t('events.alerts.eventAuthTypeIsNotAllowed'),
        },
        FIELD_MISSING: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 21,
            errorMessage: i18n.t('events.alerts.requiredFieldIsMissing'),
        },
        WRONG_FIELD_TYPE: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 22,
            errorMessage: i18n.t('events.alerts.requiredFieldWrongType'),
        },
        ID_TYPE_IS_NOT_ALLOWED: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 25,
            errorMessage: i18n.t('events.alerts.idTypeIsNotAllowed'),
        },
        TRANSACTION_ID_MISMATCH: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 31,
            errorMessage: i18n.t('events.alerts.transactionIdMismatch'),
        },
        OPERATOR_NAME_MISMATCH: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 32,
            errorMessage: i18n.t('events.alerts.operatorNameMismatch'),
        },
        EVENT_WITH_TARGET_ID: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 35,
            errorMessage: i18n.t('events.alerts.recievedTargetIdInUnregisteredEvent'),
        },
        FILTER_PROPERTIES_FIELDS_ABSENT_FOR_EVENT: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 43,
            errorMessage: i18n.t('events.alerts.missingEventFieldsUsedInFilterProperties'),
        },
        DATA_TYPE_LIMITS_REACHED: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 44,
            errorMessage: i18n.t('events.alerts.fieldValueIsOutOfRange'),
        },
        WRONG_SINK_ENTITY_TYPE: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 46,
            errorMessage: i18n.t('events.alerts.wrongSinkEntityType'),
        },
        SINK_ENTITY_NOT_FOUND: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 47,
            errorMessage: i18n.t('events.alerts.sinkEntityNotFound'),
        },
        SUSPICIOUS_EVENT: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 48,
            errorMessage: i18n.t('events.alerts.suspiciousEvent'),
        },
        FAILED_TO_CAST_EVENT_FIELD: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 49,
            errorMessage: i18n.t('events.alerts.failedToCastEventField'),
        },
    },
    EMAIL: {
        INVALID_EMAIL: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 1,
        },
        TEMPLATES_MISSING: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 3,
        },
        TEMPLATE_ARGS_MISSING: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 4,
        },
        SUCCESS: {
            response_code: ngx.HTTP_OK,
            code: 0,
        },
        UNKNOWN_TYPE: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 2,
        },
    },
    SMS: {
        MISSING_SMSGW_PAYLOAD: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 4,
        },
        KAFKA_ERROR: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 1,
        },
        SUCCESS: {
            response_code: ngx.HTTP_OK,
            code: 0,
        },
        MISSING_SENDER: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 5,
        },
        FAILED_TO_SEND: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 3,
        },
        OPERATOR_IMPLEMENTATION_MISSING: {
            response_code: ngx.HTTP_METHOD_NOT_IMPLEMENTED,
            code: 2,
        },
    },
    CRYPTO: {
        SUCCESS: {
            response_code: ngx.HTTP_OK,
            code: 0,
        },
        RSA_DECRYPTION_FAILED: {
            response_code: ngx.HTTP_FORBIDDEN,
            code: 3,
            errorMessage: i18n.t('alerts.youLackNecessaryPermission'),
        },
        RSA_KEY_INVALID: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 4,
        },
        RSA_KEY_CREATION_FAILED: {
            response_code: ngx.HTTP_FORBIDDEN,
            code: 1,
            errorMessage: i18n.t('alerts.youLackNecessaryPermission'),
        },
        RSA_ENCRYPTION_FAILED: {
            response_code: ngx.HTTP_FORBIDDEN,
            code: 2,
            errorMessage: i18n.t('alerts.youLackNecessaryPermission'),
        },
    },
    CAMPAIGN: {
        IO_ERROR: {
            code: 1,
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
        },
        NOT_FOUND: {
            code: 2,
            response_code: ngx.HTTP_BAD_REQUEST,
            errorMessage: i18n.t('campaigns.alerts.notFound'),
        },
        NAME_IS_EMPTY: {
            code: 3,
            response_code: ngx.HTTP_BAD_REQUEST,
            errorMessage: i18n.t('campaigns.alerts.nameIsEmpty'),
        },
        NAME_ALREADY_EXISTS: {
            code: 4,
            response_code: ngx.HTTP_BAD_REQUEST,
            errorMessage: i18n.t('campaigns.alerts.nameAlreadyExists'),
        },
        NOT_ONE_MESSAGE: {
            code: 5,
            response_code: ngx.HTTP_BAD_REQUEST,
            errorMessage: i18n.t('campaigns.alerts.notOneMessage'),
        },
        SENDER_NAME_EMPTY: {
            code: 6,
            response_code: ngx.HTTP_BAD_REQUEST,
            errorMessage: i18n.t('campaigns.alerts.senderNameEmpty'),
        },
        SENDER_NAME_NOT_VERIFIED: {
            code: 7,
            response_code: ngx.HTTP_BAD_REQUEST,
            errorMessage: i18n.t('campaigns.alerts.senderNameNotVerified'),
        },
        UNSUPPORTED_TARGETING_OPTIONS: {
            code: 8,
            response_code: ngx.HTTP_BAD_REQUEST,
            errorMessage: i18n.t('campaigns.alerts.unsupportedTargetingOptions'),
        },
        UNSUPPORTED_CHANNEL: {
            code: 9,
            response_code: ngx.HTTP_BAD_REQUEST,
            errorMessage: i18n.t('campaigns.alerts.unsupportedChannel'),
        },
        UNSUPPORTED_DELIVERY_TYPE: {
            code: 10,
            response_code: ngx.HTTP_BAD_REQUEST,
            errorMessage: i18n.t('campaigns.alerts.unsupportedDeliveryType'),
        },
        UNSUPPORTED_TARGET_TYPE: {
            code: 11,
            response_code: ngx.HTTP_BAD_REQUEST,
            errorMessage: i18n.t('campaigns.alerts.unsupportedTargetType'),
        },
        EMPTY_WHITELIST: {
            code: 12,
            response_code: ngx.HTTP_BAD_REQUEST,
            errorMessage: i18n.t('campaigns.alerts.emptyWhitelist'),
        },
        UNSUPPORTED_COMBINATION: {
            code: 13,
            response_code: ngx.HTTP_BAD_REQUEST,
            errorMessage: i18n.t('campaigns.alerts.unsupportedSegmentsCombination'),
        },
        TIME_LESS_THEN_ZERO: {
            code: 14,
            response_code: ngx.HTTP_BAD_REQUEST,
            errorMessage: i18n.t('campaigns.alerts.timeLessThenZero'),
        },
        END_TIME_IN_PAST: {
            code: 15,
            response_code: ngx.HTTP_BAD_REQUEST,
            errorMessage: i18n.t('campaigns.alerts.endTimeInPast'),
        },
        START_TIME_GREATER_THEN_END_TIME: {
            code: 16,
            response_code: ngx.HTTP_BAD_REQUEST,
            errorMessage: i18n.t('campaigns.alerts.startTimeGreaterThenEndTime'),
        },
        NOT_SUPPORTED_STATUS_AND_TIME_COMBINATION: {
            code: 17,
            response_code: ngx.HTTP_BAD_REQUEST,
            errorMessage: i18n.t('campaigns.alerts.unsupportedStatusAndTimeCombination'),
        },
        CAMPAIGN_UPDATE_NOT_ALLOWED: {
            code: 18,
            response_code: ngx.HTTP_BAD_REQUEST,
            errorMessage: i18n.t('campaigns.alerts.updateNotAllowed'),
        },
        SPECIFIC_FIELDS_UPDATE_NOT_ALLOWED: {
            code: 19,
            response_code: ngx.HTTP_BAD_REQUEST,
            errorMessage: i18n.t('campaigns.alerts.specificFieldsUpdateNotAllowed'),
        },
        DELETED_CAMPAIGN_UPDATE_NOT_ALLOWED: {
            code: 20,
            response_code: ngx.HTTP_BAD_REQUEST,
            errorMessage: i18n.t('campaigns.alerts.deletedCampaignUpdateNotAllowed'),
        },
        SEGMENT_DOES_NOT_EXIST: {
            code: 21,
            response_code: ngx.HTTP_BAD_REQUEST,
            errorMessage: i18n.t('campaigns.alerts.segmentDoesntExist'),
        },
        SEGMENT_ENTRY_TYPE_IS_INCORRECT: {
            code: 22,
            response_code: ngx.HTTP_BAD_REQUEST,
            errorMessage: i18n.t('campaigns.alerts.segmentEntryTypeIsNotCorrect'),
        },
        EVENT_SCHEMA_NOT_FOUND: {
            code: 23,
            response_code: ngx.HTTP_BAD_REQUEST,
            errorMessage: i18n.t('campaigns.alerts.eventSchemaNotFound'),
        },
        TEMPLATE_FIELD_MISSING_IN_EVENT: {
            code: 24,
            response_code: ngx.HTTP_BAD_REQUEST,
            errorMessage: i18n.t('campaigns.alerts.templateFieldMissing'),
        },
        START_TIME_EQUALS_END_TIME: {
            code: 25,
            response_code: ngx.HTTP_BAD_REQUEST,
            errorMessage: i18n.t('campaigns.alerts.startTimeEqualsEndTime'),
        },
        ALREADY_STARTED: {
            code: 26,
            response_code: ngx.HTTP_BAD_REQUEST,
            errorMessage: i18n.t('campaigns.alerts.alreadyStarted'),
        },
        REDIS_SCHEDULING_FAILURE: {
            code: 27,
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            errorMessage: i18n.t('campaigns.alerts.redisSchedulingFailure'),
        },
        IO_ERROR_REDIS: {
            code: 28,
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            errorMessage: i18n.t('campaigns.alerts.redisIO'),
        },
        WRONG_STATUS_TO_STOP: {
            code: 29,
            response_code: ngx.HTTP_BAD_REQUEST,
            errorMessage: i18n.t('campaigns.alerts.wrongStatusToStop'),
        },
        MSG_TEXT_IS_EMPTY: {
            code: 30,
            response_code: ngx.HTTP_BAD_REQUEST,
            errorMessage: i18n.t('campaigns.alerts.msgTextIsEmpty'),
        },
        WRONG_STATUS_FOR_DELETING: {
            code: 31,
            response_code: ngx.HTTP_BAD_REQUEST,
            errorMessage: i18n.t('campaigns.alerts.wrongStatusForDeleting'),
        },
        CAN_NOT_START_DRAFT: {
            code: 32,
            response_code: ngx.HTTP_BAD_REQUEST,
            errorMessage: i18n.t('campaigns.alerts.cantStopDraftCampaign'),
        },
        MSG_TITLE_IS_EMPTY: {
            code: 33,
            response_code: ngx.HTTP_BAD_REQUEST,
            errorMessage: i18n.t('campaigns.alerts.msgTitleIsEmpty'),
        },
        CAMPAIGN_NOT_FOUND: {
            code: 34,
            response_code: ngx.HTTP_BAD_REQUEST,
        },
        CAMPAIGN_IS_NOT_RUNNING: {
            code: 35,
            response_code: ngx.HTTP_BAD_REQUEST,
        },
        SCHEDULED_PROCESSING_IDS_DO_NOT_MATCH: {
            code: 36,
            response_code: ngx.HTTP_BAD_REQUEST,
        },
    },
    CAMPAIGN_SERVICE: {
        SUCCESS: {
            response_code: ngx.HTTP_OK,
            code: 0,
        },
        EXTERNAL_DATA_FETCH_FAILURE: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 1,
        },
        INIT_ERROR: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 4,
        },
        CACHE_ENTRY_MISSING: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 2,
        },
        CACHE_WRITE_FAILURE: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 3,
        },
    },
    SIMPLE_DLM: {
        LOCK_BUSY: {
            response_code: ngx.HTTP_SERVICE_UNAVAILABLE,
            code: 2,
        },
        INTERNAL_ERROR: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 3,
        },
        SUCCESS: {
            response_code: ngx.HTTP_OK,
            code: 0,
        },
        DATA_ACCESS_ERROR: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 1,
        },
    },
    CDP_SERVICE_NOTIFICATIONS: {
        TARGET_TYPE_IS_WRONG: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 3,
        },
        TARGET_ID_IS_WRONG: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 2,
        },
        SUCCESS: {
            response_code: ngx.HTTP_OK,
            code: 0,
        },
        NOTIFICATION_FIELD_MISSING: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 1,
        },
        TEMPLATE_ARG_WRONG_OR_MISSING: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 4,
        },
    },
    FAC: {
        VALIDATION_FAILURE_ERROR_REASON: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 2,
        },
        SUCCESS: {
            response_code: ngx.HTTP_OK,
            code: 0,
        },
        VALIDATION_FAILURE_DECLINED_REASON: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 1,
        },
    },
    SIM: {
        MORE_THAN_ONE_ACTIVE_ICCID: {
            response_code: ngx.HTTP_CONFLICT,
            code: 11,
        },
        INVALID_SIM_DATA: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 27,
        },
        MISSING_PROPERTY_FIELD: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 15,
        },
        MISSING_HEADER: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 8,
        },
        IOERROR: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 5,
        },
        IMSI_ALREADY_ASSIGNED: {
            response_code: ngx.HTTP_CONFLICT,
            code: 9,
        },
        FORCE_ACTIVATION_MISS_IMSI: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 17,
        },
        FORCE_ACTIVATION_MISS_REF: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 19,
        },
        INVALID_SUBSCRIBER: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 25,
        },
        EXTERNAL_REQUEST_FAILURE: {
            response_code: ngx.HTTP_BAD_GATEWAY,
            code: 24,
        },
        SIM_ACTIVATION_NOT_ALLOWED: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 23,
            errorMessage: i18n.t('customerCare.subscriberTab.simSwap.errorSimActivationNotAllowed'),
        },
        INVALID_ICCID: {
            response_code: ngx.HTTP_NOT_FOUND,
            code: 26,
            errorMessage: i18n.t('customerCare.subscriberTab.simSwap.errorInvalidIccid'),
        },
        FORCE_ACTIVATION_PROCESS_ERR: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 21,
        },
        INVALID: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 1,
        },
        FORCE_ACTIVATION_MISS_SUB: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 16,
        },
        FORCE_ACTIVATION_VALIDATE_ERR: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 20,
        },
        MISSING_USER_ID: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 14,
        },
        IMSI_NOT_MATCH: {
            response_code: ngx.HTTP_CONFLICT,
            code: 12,
        },
        INVALID_BRAND_MAPPING_TABLE: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 22,
        },
        FORCE_ACTIVATION_MISS_MSISDN: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 18,
        },
        SUCCESS: {
            response_code: ngx.HTTP_OK,
            code: 0,
        },
        IMSI_NOT_FOUND: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 13,
        },
        INTERNAL_ERROR: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 7,
        },
        ACTIVATION_ID_ALREADY_USED: {
            response_code: ngx.HTTP_CONFLICT,
            code: 6,
        },
        CONFIG_MISSING: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 2,
        },
        EXTERNAL_AUTHORIZATION_FAILURE: {
            response_code: ngx.HTTP_SERVICE_UNAVAILABLE,
            code: 3,
        },
        INVALID_PARAMETER: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 4,
        },
        EXTERNAL_DEACTIVATION_FAILURE: {
            response_code: ngx.HTTP_SERVICE_UNAVAILABLE,
            code: 10,
        },
    },
    PROVIDER: {
        AMOUNT_NOT_FOUND: {
            response_code: ngx.HTTP_NOT_FOUND,
            code: 11,
        },
        UNIT_NUMBER_NOT_FOUND: {
            response_code: ngx.HTTP_NOT_FOUND,
            code: 9,
        },
        PILOT_SERVICE_ID_NOT_FOUND: {
            response_code: ngx.HTTP_NOT_FOUND,
            code: 12,
        },
        PROVIDER_API_FUNCTION_NOT_FOUND: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 5,
        },
        PROVIDER_API_ID_NOT_FOUND: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 6,
        },
        SERVER_ERROR: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 1,
        },
        PLAN_ID_NOT_FOUND: {
            response_code: ngx.HTTP_NOT_FOUND,
            code: 13,
        },
        SUCCESS: {
            response_code: ngx.HTTP_OK,
            code: 0,
        },
        EXTERNAL_ADDRESS_ID_NOT_FOUND: {
            response_code: ngx.HTTP_NOT_FOUND,
            code: 8,
        },
        PROVIDER_NOT_FOUND: {
            response_code: ngx.HTTP_NOT_FOUND,
            code: 2,
        },
        PROVIDER_API_NOT_FOUND: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 4,
        },
        PROVIDER_ALREADY_EXISTS: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 3,
        },
        CREATE_RESOURCE_ARGS_NOT_FOUND: {
            response_code: ngx.HTTP_NOT_FOUND,
            code: 7,
        },
    },
    SMTP: {
        FAILED_MAILER: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 1,
        },
        SUCCESS: {
            response_code: ngx.HTTP_OK,
            code: 0,
        },
        REQUEST_NOT_VALID: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 3,
        },
        CONFIG_MISSING: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 2,
        },
        FAILED_TO_SEND: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 4,
        },
    },
    PORTING: {
        SUBSCRIBER_NOT_FOUND: {
            response_code: ngx.HTTP_OK,
            code: 6,
        },
        SUCCESS: {
            response_code: ngx.HTTP_OK,
            code: 0,
        },
        ACCEPTED: {
            response_code: ngx.HTTP_ACCEPTED,
            code: 2,
        },
        MISSING_IMSI: {
            response_code: ngx.HTTP_CONFLICT,
            code: 5,
        },
        INVALID_REQUEST: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 3,
        },
        INVALID_STATE: {
            response_code: ngx.HTTP_CONFLICT,
            code: 4,
        },
        UPDATE_NOT_ALLOWED: {
            code: 12,
            response_code: ngx.HTTP_CONFLICT,
        },
        PORT_IN_REQ_NOT_FOUND: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 15,
        },
    },
    DELIVERY: {
        UNKNOWN_ADDRESS: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 3,
        },
        DUPLICATE_DELIVERY_RECORD: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 15,
        },
        UNABLE_TO_MODIFY_SHIPMENT: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 21,
        },
        IOERROR: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 2,
        },
        DELIVERY_ID_ALREADY_USED: {
            response_code: ngx.HTTP_CONFLICT,
            code: 8,
        },
        INVALID_PROVIDER: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 7,
        },
        INVALID_REQUEST_TIME: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 13,
        },
        INVALID_DELIVERY_DATE: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 22,
        },
        TOKEN_ACCESS_FAILED: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 25,
        },
        INVALID_DELIVERY_INFO: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 14,
        },
        REMOTE_CONFIG_ERROR: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 12,
        },
        INVALID: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 1,
        },
        INVALID_ICCID: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 23,
        },
        INVALID_ADDRESS_FORMAT: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 9,
        },
        JOBID_NOT_MATCH: {
            response_code: ngx.HTTP_NOT_FOUND,
            code: 17,
        },
        ALREADY_CANCELLED_ORDER: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 20,
        },
        INVALID_DELIVERY_STATUS: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 24,
        },
        INVALID_TRACKING_NUMBER: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 19,
        },
        CANCEL_FAILURE: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 18,
        },
        RECIPIENT_NAME_TOO_SHORT: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 16,
        },
        SUCCESS: {
            response_code: ngx.HTTP_OK,
            code: 0,
        },
        UNMATCHED_RECIPIENT_DETAILS: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 11,
        },
        CONFIG_MISSING: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 6,
        },
        INVALID_VALIDATION_CODE: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 4,
        },
        PROVIDER_FAILURE: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 5,
        },
        UNMATCHED_SECRET_CODE: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 10,
        },
    },
    OMDEVEDGE: {
        EXECUTION_PAUSED: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 6,
        },
        MISSING_REFERENCED_ENTITY: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 2,
        },
        BAD_ACCOUNT_STATE: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 3,
        },
        AUTO_PAY_NOT_ALLOWED: {
            response_code: ngx.HTTP_FORBIDDEN,
            code: 8,
            errorMessage: i18n.t('alerts.youLackNecessaryPermission'),
        },
        ALREADY_PROVISIONED: {
            response_code: ngx.HTTP_CONFLICT,
            code: 5,
        },
        SUCCESS: {
            response_code: ngx.HTTP_OK,
            code: 0,
        },
        PROVISIONING_PENDING: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 7,
        },
        RESTRICTED_ERROR: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 1,
        },
        BAD_BILLING_ID: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 4,
        },
    },
    CHF: {
        SUBSCRIPTION_NOT_FOUND: {
            response_code: ngx.HTTP_NOT_FOUND,
            code: 6,
        },
        CREATED: {
            response_code: ngx.HTTP_CREATED,
            code: 1,
        },
        SUCCESS: {
            response_code: ngx.HTTP_OK,
            code: 0,
        },
        SYSTEM_FAILURE: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 4,
        },
        NO_CONTENT: {
            response_code: ngx.HTTP_NO_CONTENT,
            code: 2,
        },
        IOERROR: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 3,
        },
        VALIDATION_ERROR: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 5,
        },
    },
    PROVISIONING: {
        INVALID_NOTIFICATION_TYPE: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 4,
        },
        INVALID: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 2,
        },
        ERROR_IN_CORE_FUNCTION: {
            code: 14,
        },
        MISSING_RESTRICTION: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 8,
        },
        INVALID_PARAMETERS_PASSED: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 13,
        },
        INVALID_MESSAGE_ID: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 3,
        },
        RESTRICTION_ALREADY_ACTIVE: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 7,
        },
        PARTIAL_SUCCESS: {
            response_code: ngx.HTTP_ACCEPTED,
            code: 12,
        },
        ACKNOWLEDGE: {
            response_code: ngx.HTTP_ACCEPTED,
            code: 1,
        },
        CALLBACK_VALIDATION_ERROR: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 11,
        },
        SUCCESS: {
            response_code: ngx.HTTP_OK,
            code: 0,
        },
        RETRYABLE_FAILURE: {
            response_code: ngx.HTTP_ACCEPTED,
            code: 9,
        },
        NON_RETRYABLE_FAILURE: {
            response_code: ngx.HTTP_ACCEPTED,
            code: 10,
        },
        IO_ERROR: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 6,
        },
    },
    USER: {
        UNKNOWN_CHANNEL: {
            response_code: ngx.HTTP_FORBIDDEN,
            code: 84,
            errorMessage: i18n.t('alerts.youLackNecessaryPermission'),
        },
        ADDRESS_CRITERIA_NOT_MET: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 116,
        },
        WRONG_SECRET: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 9,
        },
        ADDRESS_PROVIDER_ID_MISSING: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 1,
        },
        SUBSCRIBER_INVALID_STATE: {
            response_code: ngx.HTTP_CONFLICT,
            code: 59,
        },
        ORGANIZATION_ID_MISSING: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 64,
        },
        ACCOUNT_NOT_FOUND: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 93,
        },
        MFA_REQUIRED: {
            response_code: ngx.HTTP_CREATED,
            code: 128,
        },
        SUBSCRIBER_e911_ADDRESS_ALREADY_EXIST: {
            response_code: ngx.HTTP_CONFLICT,
            code: 123,
        },
        BAD_SOCIAL_TOKEN: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 25,
        },
        USER_NOT_FOUND: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 117,
        },
        SUCCESS: {
            response_code: ngx.HTTP_OK,
            code: 0,
        },
        INVALID_SUBSCRIBER: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 35,
        },
        ADDRESS_NOT_FOUND: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 91,
        },
        SOCIAL_ID_MISSING_IN_CREDS: {
            response_code: ngx.HTTP_FORBIDDEN,
            code: 121,
            errorMessage: i18n.t('alerts.youLackNecessaryPermission'),
        },
        USER_ALREADY_HAS_ORGANIZATION: {
            response_code: ngx.HTTP_CONFLICT,
            code: 71,
        },
        SERVICE_ADDRESS_NOT_REMOVABLE: {
            response_code: ngx.HTTP_FORBIDDEN,
            code: 104,
            errorMessage: i18n.t('alerts.youLackNecessaryPermission'),
        },
        USER_IS_BLOCKED: {
            response_code: ngx.HTTP_CONFLICT,
            code: 110,
        },
        BAD_SUBSCRIBER_STATE: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 28,
        },
        SUBSCRIBER_NOT_FOUND: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 99,
        },
        DATA_NOT_EXIST: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 54,
        },
        UPDATE_FLAGS_FORBIDDEN: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 118,
        },
        INSUFFICIENT_PRIVILEGE: {
            response_code: ngx.HTTP_CONFLICT,
            code: 69,
        },
        ACCESS_NOT_ALLOWED: {
            response_code: ngx.HTTP_FORBIDDEN,
            code: 114,
            errorMessage: i18n.t('alerts.youLackNecessaryPermission'),
        },
        ROLE_NOT_FOUND: {
            response_code: ngx.HTTP_CONFLICT,
            code: 115,
        },
        RECOVERY_NOT_CONFIGURED: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 127,
        },
        INVALID_PRIMARY_EXTERNAL_ID: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 8,
        },
        USER_ALREADY_HAS_ACCOUNT: {
            response_code: ngx.HTTP_CONFLICT,
            code: 129,
        },
        USER_NOT_EXIST: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 31,
        },
        INVALID_ADDRESS_HIERARCHY: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 68,
        },
        SIGN_IN_BLOCKED: {
            response_code: ngx.HTTP_FORBIDDEN,
            code: 102,
            errorMessage: i18n.t('alerts.youLackNecessaryPermission'),
        },
        ACCOUNT_ALREADY_ASSIGNED: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 72,
        },
        TERMINATION_CANNOT_BE_CANCELLED: {
            response_code: ngx.HTTP_CONFLICT,
            code: 97,
        },
        EXTERNAL_ID_LINKED_ALREADY: {
            response_code: ngx.HTTP_CONFLICT,
            code: 11,
        },
        INVALID_STATE_ACCOUNT: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 62,
        },
        NOT_FOUND: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 131,
        },
        ACCESS_REQUEST_NOT_FOUND: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 130,
        },
        ACCOUNT_INVALID_STATE: {
            response_code: ngx.HTTP_CONFLICT,
            code: 61,
        },
        INVALID_EMAIL: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 15,
        },
        BAD_EMAIL: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 33,
        },
        SECRET_SAME_AS_OLD: {
            response_code: ngx.HTTP_CONFLICT,
            code: 30,
        },
        BASE_PLAN_NOT_ACTIVE: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 75,
        },
        REGISTRATION_TYPE_NOT_SUPPORTED: {
            response_code: ngx.HTTP_FORBIDDEN,
            code: 124,
            errorMessage: i18n.t('alerts.youLackNecessaryPermission'),
        },
        TRANSACTION_ID_ALREADY_USED: {
            response_code: ngx.HTTP_CONFLICT,
            code: 122,
        },
        REDIS_ERROR: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 100,
        },
        INVALID_HIERARCHY: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 101,
        },
        WRONG_ADDRESS_HIERARCHY: {
            response_code: ngx.HTTP_CONFLICT,
            code: 70,
        },
        EMAIL_EXIST: {
            response_code: ngx.HTTP_CONFLICT,
            code: 17,
        },
        INVALID_CREDS: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 108,
        },
        VALIDATE_PARAMETER_FAILED: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 29,
        },
        ACTION_FORBIDDEN: {
            response_code: ngx.HTTP_FORBIDDEN,
            code: 105,
            errorMessage: i18n.t('alerts.youLackNecessaryPermission'),
        },
        IMSI_ALREADY_ASSIGNED: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 63,
        },
        LIMIT_REACHED: {
            response_code: ngx.HTTP_CONFLICT,
            code: 120,
        },
        ORGANIZATION_ELEM_TYPE_MISMATCH: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 73,
        },
        INDEX_CONFLICT: {
            response_code: ngx.HTTP_CONFLICT,
            code: 119,
        },
        TARGET_GROUP_EMAIL_MISSING: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 82,
        },
        INVALID_PARAMETER: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 90,
        },
        ACCOUNT_SERVICE_ADDRESS_ALREADY_EXIST: {
            response_code: ngx.HTTP_CONFLICT,
            code: 94,
        },
        CONFLICT: {
            response_code: ngx.HTTP_CONFLICT,
            code: 2,
        },
        ORGANIZATION_TYPE_MISMATCH: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 74,
        },
        EMAIL_SECRET_CREDS_EXISTS: {
            response_code: ngx.HTTP_CONFLICT,
            code: 125,
        },
        SUBSCRIBER_IS_BLOCKED: {
            response_code: ngx.HTTP_CONFLICT,
            code: 111,
        },
        MISSING_ADDRESS_PARTS: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 103,
        },
        ORGANIZATION_NOT_FOUND: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 65,
        },
        ACCOUNT_IS_BLOCKED: {
            response_code: ngx.HTTP_CONFLICT,
            code: 60,
        },
        INVALID_MSISDN: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 47,
        },
        SUB_NOT_ELIGIBLE_FOR_NOTIFICATION: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 66,
        },
        CASSANDRA_ERROR: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 44,
        },
        INVITE_TO_ACCOUNT_CONFLICT: {
            response_code: ngx.HTTP_CONFLICT,
            code: 67,
        },
        SOCIAL_ID_REGISTERED: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 24,
        },
        INVALID_FLAG: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 113,
        },
        INVALID_TOKEN_TYPE_STATE_TRANSITION: {
            response_code: ngx.HTTP_CONFLICT,
            code: 96,
        },
        INVALID_END_OF_CYCLE_STATE: {
            response_code: ngx.HTTP_CONFLICT,
            code: 98,
        },
        BAD_USERNAME: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 32,
        },
        SUBSCRIBERS_NUMBER_LIMIT_REACHED: {
            response_code: ngx.HTTP_FORBIDDEN,
            code: 106,
            errorMessage: i18n.t('alerts.youLackNecessaryPermission'),
        },
        INVALID_PROPERTY: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 107,
        },
        PROPERTY_OVERRIDE_NOT_ALLOWED: {
            response_code: ngx.HTTP_CONFLICT,
            code: 92,
        },
        NOT_ALL_DATA_PRESENT: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 134,
        },
    },
    PORTAL_ANALYTICS: {
        IO_ERROR: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 2,
        },
        INVALID_INPUT: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 1,
        },
        SUCCESS: {
            response_code: ngx.HTTP_OK,
            code: 0,
        },
    },
    NUMBER_MANAGEMENT: {
        INVALID_STATUS: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 9,
        },
        INVALID: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 1,
        },
        RELEASE_BAN_MSISDN_FORBIDDEN: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 11,
        },
        REDIS_WATCH_FAIL: {
            response_code: ngx.HTTP_SERVICE_UNAVAILABLE,
            code: 15,
        },
        RESERVATION_ID_MISSING: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 7,
        },
        IOERROR: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 2,
        },
        RESERVATION_ID_MISMATCH: {
            response_code: ngx.HTTP_CONFLICT,
            code: 6,
        },
        POSTAL_CODE_NOT_AVAILABLE: {
            response_code: ngx.HTTP_CONFLICT,
            code: 4,
        },
        MSISDN_MISSING: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 8,
        },
        SUCCESS: {
            response_code: ngx.HTTP_OK,
            code: 0,
        },
        NUMBER_EXHAUSTED: {
            response_code: ngx.HTTP_CONFLICT,
            code: 5,
        },
        EXPIRED_RESERVATION_PRUNE_FAILURE: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 13,
        },
        DISABLED: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 10,
        },
        RESERVATION_RENEW_FAILURE: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 12,
        },
        CONFLICT: {
            response_code: ngx.HTTP_CONFLICT,
            code: 3,
        },
        REDIS_ERROR: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 14,
        },
    },
    REPORTS: {
        INVALID_REPORT: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 2,
        },
        SUCCESS: {
            response_code: ngx.HTTP_OK,
            code: 0,
        },
        ENVIRONMENT_CONFIG_MISSING: {
            response_code: ngx.HTTP_NOT_FOUND,
            code: 6,
        },
        OPERATOR_CONFIG_MISSING: {
            response_code: ngx.HTTP_NOT_FOUND,
            code: 5,
        },
        INVALID_EVENT: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 3,
        },
        INVALID_BODY: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 1,
        },
        NO_EVENT_SCHEMA: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 4,
        },
    },
    SERVICE_BLOCKER: {
        CASSANDRA_ERROR: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 1,
        },
        SUCCESS: {
            response_code: ngx.HTTP_OK,
            code: 0,
        },
        BLOCKED: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 2,
        },
    },
    BILLING: {
        INVALID: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 2,
        },
        PAYMENT_DETAILS_NOT_FOUND: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 9,
        },
        BILL_STATEMENT_PROCESS_TIMEOUT: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 4,
        },
        ORDER_BASE_PLAN_MISSING: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 7,
        },
        WAITING_FOR_PROCESSING: {
            response_code: ngx.HTTP_ACCEPTED,
            code: 1,
        },
        INVALID_TARGET_TYPE: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 3,
        },
        ORDER_CATALOG_INFO_MISSING: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 5,
        },
        ORDER_SERVICE_INFO_MISSING: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 6,
        },
        BILL_STATEMENT_NOT_SETTLED: {
            response_code: ngx.HTTP_ACCEPTED,
            code: 8,
        },
    },
    BILLING_V4: {
        INVALID_REFUND_AMOUNT: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 6,
        },
        FILE_NOT_FOUND_ON_S3: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 17,
        },
        CHARGE_ALREADY_REFUNDED: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 26,
        },
    },
    CDP_TRIGGERS: {
        SUCCESS: {
            response_code: ngx.HTTP_OK,
            code: 0,
        },
        EXTERNAL_DATA_FETCH_FAILURE: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 1,
        },
        INIT_ERROR: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 4,
        },
        CACHE_ENTRY_MISSING: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 2,
        },
        CACHE_WRITE_FAILURE: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 3,
        },
    },
    PROMO: {
        INVALID: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 1,
        },
        BENEFIT_INVALID_STATE: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 8,
        },
        BENEFIT_ALREADY_RESERVED: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 4,
        },
        GRANT_TYPE_MISMATCH: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 7,
        },
        BENEFIT_INVALID_COLUMN: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 9,
        },
        MISSING_PROMO_RULE: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 6,
        },
        SUCCESS: {
            response_code: ngx.HTTP_OK,
            code: 0,
        },
        BENEFIT_ALREADY_CONSUMED: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 3,
        },
        INVALID_CODE: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 5,
        },
        BENEFIT_NOT_EXIST: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 2,
        },
        USER_INFO_MISSING: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 10,
        },
    },
    CDP_CACHE: {
        SUCCESS: {
            response_code: ngx.HTTP_OK,
            code: 0,
        },
        EXTERNAL_DATA_FETCH_FAILURE: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 1,
        },
        INIT_ERROR: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 4,
        },
        CACHE_ENTRY_MISSING: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 2,
        },
        CACHE_WRITE_FAILURE: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 3,
        },
    },
    AUTHORIZATION: {
        ROLE_NOT_FOUND: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 1,
        },
        SUCCESS: {
            response_code: ngx.HTTP_OK,
            code: 0,
        },
        PERMISSION_ROLE_NOT_EXISTS: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 2,
        },
        SESSION_NOT_EXIST: {
            response_code: ngx.HTTP_FORBIDDEN,
            code: 3,
            errorMessage: i18n.t('alerts.youLackNecessaryPermission'),
        },
        INVALIDATED_SESSION: {
            response_code: ngx.HTTP_FORBIDDEN,
            code: 4,
            errorMessage: i18n.t('alerts.youLackNecessaryPermission'),
        },
    },
    ORDER: {
        BASE_PLAN_ALREADY_PROVISIONED: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 15,
        },
        SELLBACK_WEIGHT_NOT_DEFINED: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 24,
        },
        INSUFFICIENT_FUNDS: {
            response_code: ngx.HTTP_UNPROCESSABLE_ENTITY,
            code: 4,
        },
        INVALID_CHARGED_TO: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 39,
        },
        RECURRENCE_TIME_INVALID: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 48,
        },
        BASE_PLAN_CANCELLATION_NOT_ALLOWED: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 13,
        },
        ORDER_NOT_TRIAL: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 52,
        },
        MISSING_UNIT_NUMBER: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 64,
        },
        REPLACING_NON_RECURRING_ORDER_FORBIDDEN: {
            response_code: ngx.HTTP_UNAUTHORIZED,
            code: 26,
        },
        SUCCESS: {
            response_code: ngx.HTTP_OK,
            code: 0,
        },
        EXCEED_MAX_SELECTABLE: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 21,
        },
        BASE_PLAN_DEACTIVATION_NOT_ALLOWED: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 29,
        },
        IO_ERROR: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 2,
        },
        MISSING_PAYMENT_METHOD: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 68,
        },
        ORDER_INVALID_STATE: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 37,
        },
        ORDER_STATE_RESTORE_INVALID: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 55,
        },
        RECURRENCE_NOT_ALLOWED: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 34,
        },
        ADDON_PURCHASE_FORBIDDEN: {
            response_code: ngx.HTTP_CONFLICT,
            code: 72,
        },
        ORDER_STATE_TRANSITION_FORBIDDEN: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 36,
        },
        INVALID: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 1,
        },
        TOP_UP_FORBIDDEN: {
            response_code: ngx.HTTP_UNAUTHORIZED,
            code: 18,
        },
        BAD_ACCOUNT_STATE: {
            response_code: ngx.HTTP_CONFLICT,
            code: 69,
        },
        MISSING_GOOGLE_PLACE_ID_MAPPING: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 66,
        },
        INVALID_STATE_FOR_DEACTIVATION: {
            response_code: ngx.HTTP_CONFLICT,
            code: 28,
        },
        INVALID_ACTION: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 74,
        },
        NON_RETRIGGERABLE_CONTEXT: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 59,
        },
        MISSING_PARAMETERS: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 30,
        },
        ORDER_NOT_PENDING: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 9,
        },
        MISSING_RECURRENCE_EVENT: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 17,
        },
        INVALID_PRODUCT: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 46,
        },
        PRODUCT_NOT_FOUND: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 43,
        },
        INVALID_ENTITY_TYPE: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 32,
        },
        NO_CURRENT_BILLING_CYCLE: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 61,
        },
        MISSING_GOOGLE_PLACE_ID: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 65,
        },
        INVALID_ORDER_STATE: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 16,
        },
        TRANSACTION_ID_ALREADY_USED: {
            response_code: ngx.HTTP_CONFLICT,
            code: 19,
        },
        INVALID_ORDER_SCHEDULE_TYPE: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 38,
        },
        REPLACE_ORDER_FORBIDDEN: {
            response_code: ngx.HTTP_UNAUTHORIZED,
            code: 25,
        },
        EXPIRY_NOT_FOUND: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 50,
        },
        BASE_PLAN_NOT_FOUND: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 31,
        },
        ACCOUNT_ALREADY_HAVE_ORDER: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 63,
        },
        FRAUDULENT_SUBSCRIBERS: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 60,
        },
        PRERECURRENCE_VALIDITY_CHECK_PERFORMED: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 75,
        },
        FANOUT_INTERNAL_ERROR: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 58,
        },
        WALLET_NOT_ACTIVE: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 57,
        },
        INVALID_PARAMETERS_PASSED: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 73,
        },
        STOP_OR_RESUME_RECURRENCE_NOT_ALLOWED: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 62,
        },
        ORDER_NOT_FOUND: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 8,
        },
        CONFLICT: {
            response_code: ngx.HTTP_CONFLICT,
            code: 3,
        },
        ENTITY_TYPE_NOT_ALLOWED: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 33,
        },
        ADDON_CHECK_LIMIT_REACHED: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 70,
        },
        TOP_UP_NOT_ACTIVATED: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 6,
        },
        MULTIPLE_BASE_PLANS_INSIDE_OFFER: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 14,
        },
        MISSING_ADDRESS_EXTERNAL_ID: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 67,
        },
        REPLACE_ORDER_NOT_ACTIVATED: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 7,
        },
        INVALID_OPERATION: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 51,
        },
        PROVISIONING_FAILED: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 22,
        },
        START_TIME_INVALID: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 47,
        },
        INVALID_STATE_FOR_CANCELLATION: {
            response_code: ngx.HTTP_CONFLICT,
            code: 10,
        },
        CHARGING_BLOCKED: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 56,
        },
        CHARGED_TO_BILL_FORBIDDEN: {
            response_code: ngx.HTTP_UNAUTHORIZED,
            code: 23,
        },
        ENTITY_NOT_ACTIVE: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 11,
        },
        ORDER_NOT_CANCELABLE: {
            response_code: ngx.HTTP_CONFLICT,
            code: 20,
        },
        INVALID_WALLET_TYPE: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 40,
        },
        MISSING_INTEGRATION_PARAMS: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 45,
        },
        AMOUNT_MISSING_FOR_WALLET_TYPE: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 41,
        },
        MISSING_SERVICE_ADDRESS: {
            response_code: ngx.HTTP_CONFLICT,
            code: 54,
        },
        ADDON_REINSERTED_IN_QUEUE: {
            response_code: ngx.HTTP_ACCEPTED,
            code: 71,
        },
        INVALID_RECURRENCE_STATE: {
            response_code: ngx.HTTP_CONFLICT,
            code: 12,
        },
        TOP_UP_NON_POSITIVE: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 42,
        },
        CFSIDS_ALREADY_PROVISIONED: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 44,
        },
        INVALID_TIME_RANGE: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 49,
        },
        REACTIVATION_NOT_ALLOWED: {
            response_code: ngx.HTTP_CONFLICT,
            code: 53,
        },
        DLM_ERROR: {
            response_code: ngx.HTTP_CONFLICT,
            code: 35,
        },
    },
    PERMISSION: {
        PERMISSION_ROLE_NOT_EXISTS: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 2,
        },
        ROLE_NOT_FOUND: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 1,
        },
        SUCCESS: {
            response_code: ngx.HTTP_OK,
            code: 0,
        },
    },
    REDIS_BACKED_CACHE: {
        SUCCESS: {
            response_code: ngx.HTTP_OK,
            code: 0,
        },
        IO_ERROR: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 1,
        },
    },
    PRIZE: {
        INVALID_PRIZE_IDS: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 3,
        },
        PRIZE_ENGINE_NOT_FOUND_UNFILTERED: {
            response_code: ngx.HTTP_NOT_FOUND,
            code: 7,
        },
        SUCCESS: {
            response_code: ngx.HTTP_OK,
            code: 0,
        },
        INVALID_PARAMETER: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 1,
        },
        INVALID_PRIZE_SELECTION_STATE: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 6,
        },
        INVALID_PRIZE_ENGINE_VERSION: {
            response_code: ngx.HTTP_FORBIDDEN,
            code: 4,
            errorMessage: i18n.t('alerts.youLackNecessaryPermission'),
        },
        INVALID_CURRENCY_AMOUNT_FORMAT: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 5,
        },
        PRIZE_ENGINE_NOT_FOUND: {
            response_code: ngx.HTTP_NOT_FOUND,
            code: 2,
        },
    },
    ADDRESSBOOK: {
        IO_ERROR: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 1,
        },
        ADDRESS_NOT_FOUND: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 2,
        },
        INVALID_UNIT_NUMBER: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 3,
        },
        INVALID_PHONE_NUMBER: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 5,
        },
        INVALID_EXTERNAL_LOCATION_TYPE: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 6,
        },
        SUCCESS: {
            response_code: ngx.HTTP_OK,
            code: 0,
        },
        HINT_INVALID_RESPONSE: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 8,
        },
        ADDRESS_NOT_COMPLETE: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 4,
        },
        PROVIDER_ID_NOT_SPECIFIED: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 7,
        },
    },
    APPOINTMENTS: {
        ADDRESS_NOT_FOUND: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 5,
        },
        UNEXPECTED_EXTERNAL_RESPONSE: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 4,
        },
        SERVICE_IMPL_NOT_FOUND: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 12,
        },
        RESERVATION_EXPIRED: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 6,
        },
        MISSING_APPOINTMENT: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 9,
        },
        SFS_TOKEN_REQUEST_ERROR: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 16,
        },
        SERVICE_NAME_NOT_FOUND: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 11,
        },
        TIME_SLOT_NOT_FOUND: {
            response_code: ngx.HTTP_NOT_FOUND,
            code: 3,
        },
        TIME_PARSING_ERROR: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 8,
        },
        APPOINTMENT_NOT_FOUND: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 10,
        },
        APPOINTMENT_IS_NOT_ALLOWED: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 7,
        },
        REGION_ID_NOT_FOUND: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 13,
        },
        INVALID_TIME_SLOT: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 15,
        },
        BAD_SERVER_CONFIG: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 1,
        },
        SUCCESS: {
            response_code: ngx.HTTP_OK,
            code: 0,
        },
        RESOURCE_ID_NOT_FOUND: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 14,
        },
        INVALID_EXTERNAL_REQUEST: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 2,
        },
        APPOINTMENT_TYPE_NOT_SUPPORTED: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 19,
            errorMessage: i18n.t('appointments.alerts.failedLoadingAppointmentTimeSlotsTypeNotSupported'),
        },
        APPOINTMENT_NOT_YET_AVAILABLE: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 29,
            errorMessage: i18n.t('appointments.alerts.failedLoadingAppointmentTimeSlotsNotYetAvailable'),
        },
        CANCEL_APPOINTMENT_NOT_ALLOWED: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 106,
            errorMessage: i18n.t('appointments.errors.appointmentCouldNotBeCancelled'),
        },
    },
    EKYC: {
        IS_VALID_ERROR: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 2,
        },
        LIVENESS_DETECTED_ERROR: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 4,
        },
        VERIFICATION_FLAG_ERROR: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 7,
        },
        ID_TYPE_INVALID: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 6,
        },
        SUCCESS: {
            response_code: ngx.HTTP_OK,
            code: 0,
        },
        VALID_CHECK_DIGITS_ERROR: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 5,
        },
        CONFIG_MISSING: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 8,
        },
        CONFIDENCE_ERROR: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 3,
        },
        ID_FRAUD_DETECTED_ERROR: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 1,
        },
    },
    DWH_CONFIG: {
        SUCCESS: {
            response_code: ngx.HTTP_OK,
            code: 0,
        },
        TABLE_MANIPULATION_REQUEST_FAIL: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 8,
        },
        INVALID_ENTITY_ID: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 9,
            errorMessage: i18n.t('sinkConfigs.alerts.invalidEntityId'),
        },
        CANNOT_DELETE_INTERNAL_DWHCONFIG: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 11,
            errorMessage: i18n.t('sinkConfigs.alerts.cannotDeleteInternalEntity'),
        },
        ATTEMPT_TO_DELETE_USED_ENTITY: {
            response_code: ngx.HTTP_CONFLICT,
            code: 13,
            errorMessage: i18n.t('sinkConfigs.alerts.attemptToDeleteUsedEntity'),
        },
        TABLE_NAME_CHANGE_FORBIDDEN: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 14,
            errorMessage: i18n.t('sinkConfigs.alerts.nameChangeForbidden'),
        },
        NAME_ALREADY_EXISTS: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 15,
            errorMessage: i18n.t('sinkConfigs.alerts.nameAlreadyExists'),
        },
        TABLE_NAME_ALREADY_EXISTS: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 16,
            errorMessage: i18n.t('sinkConfigs.alerts.tableNameAlreadyExists'),
        },
        INTERNAL_ENTITY_FORBIDDEN_TO_UPDATE: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 17,
            errorMessage: i18n.t('sinkConfigs.alerts.internalEntityIsForbiddenToUpdate'),
        },
    },
    TIERS: {
        INVALID_TIERS_PROGRAM: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 3,
        },
        INVALID_PARAMETER: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 1,
        },
        SUCCESS: {
            response_code: ngx.HTTP_OK,
            code: 0,
        },
        TIERS_NOT_ENABLED: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 2,
        },
        IO_ERROR: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 4,
        },
        TIER_LEVEL_INFO_NOT_FOUND: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 7,
        },
        INVALID_TIER_LEVEL_ORDINAL: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 6,
        },
        INVALID_TIER_LEVEL_POINTS: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 5,
        },
    },
    PRICING: {
        SUCCESS: {
            response_code: ngx.HTTP_OK,
            code: 0,
        },
        INVALID: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 1,
        },
    },
    CDP_SINK_CONFIG: {
        AUTH_ERROR: {
            response_code: ngx.HTTP_UNAUTHORIZED,
            code: 7,
        },
        INVALID_LOCATION: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 6,
        },
        SUCCESS: {
            response_code: ngx.HTTP_OK,
            code: 0,
        },
        EXTERNAL_ERROR: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 2,
        },
        REPORT_DEFINITION_ID_ALREADY_EXISTS: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 5,
        },
        INVALID_KAFKA_TOPIC_NAME: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 3,
        },
        INTERNAL_ERROR: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 1,
        },
        INVALID_INTERVAL: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 4,
        },
    },
    ENTITY_STORE: {
        SUCCESS: {
            response_code: ngx.HTTP_OK,
            code: 0,
        },
    },
    COUNT_MANAGER: {
        IO_ERROR: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 2,
        },
        SUCCESS: {
            response_code: ngx.HTTP_OK,
            code: 0,
        },
        COUNT_DOES_NOT_EXIST: {
            response_code: ngx.HTTP_NOT_FOUND,
            code: 1,
        },
        LIMIT_REACHED: {
            response_code: ngx.HTTP_FORBIDDEN,
            code: 8,
            errorMessage: i18n.t('alerts.youLackNecessaryPermission'),
        },
    },
    GROWTH: {
        INVALID_METRIC: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 4,
        },
        TOO_MANY_DATA_POINTS: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 2,
        },
        LOCK_NOT_ACQUIRED: {
            response_code: ngx.HTTP_SERVICE_UNAVAILABLE,
            code: 5,
        },
        NO_DATAPOINTS_COMPUTED: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 12,
        },
        DATA_ACCESS_ERROR: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 7,
        },
        INVALID_COHORT_NAME: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 11,
        },
        LOCK_NOT_RELEASED: {
            response_code: ngx.HTTP_SERVICE_UNAVAILABLE,
            code: 6,
        },
        SUCCESS: {
            response_code: ngx.HTTP_OK,
            code: 0,
        },
        KEY_NOT_FOUND: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 9,
        },
        INIT_ERROR: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 8,
        },
        INVALID_DATE_RANGE: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 1,
        },
        INTERNAL_ERROR: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 10,
        },
        INVALID_PARAMETER: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 3,
        },
    },
    TIER_MANAGEMENT: {
        IO_ERROR: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 2,
        },
        INVALID: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 3,
        },
        SUCCESS: {
            response_code: ngx.HTTP_OK,
            code: 0,
        },
        CONFIG_MISSING: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 1,
        },
        CONFLICT: {
            response_code: ngx.HTTP_CONFLICT,
            code: 4,
        },
    },
    OSA_DIGICEL_SELF_CARE_SERVICE: {
        INVALID_EXTERNAL_API: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 2,
        },
        SUCCESS: {
            response_code: ngx.HTTP_OK,
            code: 0,
        },
        REQUEST_FAILED: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 1,
        },
        INVALID_EXTERNAL_REQUEST: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 4,
        },
        INVALID_EXTERNAL_RESPONSE: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 3,
        },
        INVALID_SERVER_CONFIG: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 5,
        },
    },
    OSA_THALES: {
        INVALID_STATUS_INDICATOR: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 1,
        },
        INVALID_HEADER_EXECUTION_STATUS: {
            response_code: ngx.HTTP_BAD_GATEWAY,
            code: 2,
        },
    },
    ELIGIBILITY: {
        SERVER_ERROR: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 1,
        },
        SUCCESS: {
            response_code: ngx.HTTP_OK,
            code: 0,
        },
        LOCATION_ALREADY_EXISTS: {
            response_code: ngx.HTTP_CONFLICT,
            code: 2,
        },
        LOCATION_NOT_FOUND: {
            response_code: ngx.HTTP_NOT_FOUND,
            code: 3,
        },
        LOCATION_STATE_NOT_FOUND: {
            response_code: ngx.HTTP_NOT_FOUND,
            code: 5,
        },
        PROVIDER_ID_NOT_SPECIFIED: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 4,
        },
    },
    OSA_PROJECT8_EMP: {
        CONFIRM_ORDER_SM_DS_ERROR: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 18,
        },
        INVALID_PARAMETER: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 16,
        },
        RELEASE_NUMBER_FAILURE: {
            response_code: ngx.HTTP_BAD_GATEWAY,
            code: 15,
        },
        GET_QR_INFO_FAILURE: {
            response_code: ngx.HTTP_BAD_GATEWAY,
            code: 8,
        },
        DOWNLOAD_ORDER_FAILURE: {
            response_code: ngx.HTTP_BAD_GATEWAY,
            code: 2,
        },
        CONFIRM_ORDER_FAILURE: {
            response_code: ngx.HTTP_BAD_GATEWAY,
            code: 3,
        },
        GET_NUMBERS_FAILURE: {
            response_code: ngx.HTTP_BAD_GATEWAY,
            code: 10,
        },
        GET_NUMBERS_EMPTY: {
            response_code: ngx.HTTP_BAD_GATEWAY,
            code: 11,
        },
        ACMS_NO_DATA_FOUND: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 19,
        },
        DEACTIVATE_NUMBER_FAILURE: {
            response_code: ngx.HTTP_BAD_GATEWAY,
            code: 14,
        },
        RESERVE_NUMBER_FAILURE: {
            response_code: ngx.HTTP_BAD_GATEWAY,
            code: 12,
        },
        DOWNLOAD_ORDER_PROFILE_UNAVAILABLE: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 17,
        },
        GET_PROFILE_INFO_FAILURE: {
            response_code: ngx.HTTP_BAD_GATEWAY,
            code: 6,
        },
        RELEASE_ORDER_FAILURE: {
            response_code: ngx.HTTP_BAD_GATEWAY,
            code: 4,
        },
        ACTIVATE_NUMBER_FAILURE: {
            response_code: ngx.HTTP_BAD_GATEWAY,
            code: 13,
        },
        GET_IMSI_FAILURE: {
            response_code: ngx.HTTP_BAD_GATEWAY,
            code: 9,
        },
        ERROR_PROFILE_STATE: {
            response_code: ngx.HTTP_BAD_GATEWAY,
            code: 7,
        },
        SUCCESS: {
            response_code: ngx.HTTP_OK,
            code: 0,
        },
        CANCEL_ORDER_FAILURE: {
            response_code: ngx.HTTP_BAD_GATEWAY,
            code: 5,
        },
        INVALID_EXTERNAL_RESPONSE: {
            response_code: ngx.HTTP_BAD_GATEWAY,
            code: 1,
        },
    },
    TOP_UP: {
        IO_ERROR: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 2,
        },
        INVALID: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 1,
        },
        RECHARGE_CARD_ALREADY_EXIST: {
            response_code: ngx.HTTP_CONFLICT,
            code: 7,
        },
        CONFLICT: {
            response_code: ngx.HTTP_CONFLICT,
            code: 3,
        },
        VOUCHER_CODE_ALREADY_USED: {
            response_code: ngx.HTTP_CONFLICT,
            code: 4,
        },
        SUCCESS: {
            response_code: ngx.HTTP_OK,
            code: 0,
        },
        RECHARGE_CARD_INVALID: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 9,
        },
        MISSING_PAYMENT_RECORD: {
            response_code: ngx.HTTP_NOT_FOUND,
            code: 6,
        },
        NOT_ENABLED: {
            response_code: ngx.HTTP_FORBIDDEN,
            code: 10,
            errorMessage: i18n.t('alerts.youLackNecessaryPermission'),
        },
        SUBSCRIBER_IS_BLOCKED: {
            response_code: ngx.HTTP_UNAUTHORIZED,
            code: 8,
        },
        TRANSACTION_ID_ALREADY_USED: {
            response_code: ngx.HTTP_CONFLICT,
            code: 5,
        },
    },
    OSA_DIGICEL_AIR_SERVICE: {
        UNKNOWN_RESPONSE: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 5,
        },
        BAD_RESPONSE_CODE: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 3,
        },
        REQUEST_FAILED: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 1,
        },
        INSUFFICIENT_FUNDS: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 6,
        },
        FAULT: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 4,
        },
        SUCCESS: {
            response_code: ngx.HTTP_OK,
            code: 0,
        },
        INVALID_XML_FORMAT: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 2,
        },
    },
    DATARETRIEVER: {
        GOOGLE_BIGQUERY_TABLE_NOT_SET: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 2,
        },
        RETRIEVAL_DESTINATION_NOT_FOUND: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 3,
        },
        SUCCESS: {
            response_code: ngx.HTTP_OK,
            code: 0,
        },
        INVALID_ACCESS_TOKEN_REQUEST_ERROR: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 7,
        },
        MISSING_INPUT: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 1,
        },
        NO_LATEST_START_TIME_IN_DATA: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 6,
        },
        NO_LATEST_START_TIME: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 4,
        },
        LATEST_START_TIME_EXISTS: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 5,
        },
    },
    CRM: {
        IO_ERROR: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 3,
        },
        INVALID: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 1,
        },
        SUCCESS: {
            response_code: ngx.HTTP_OK,
            code: 0,
        },
        EXTERNAL_CRM_REQUEST_FAILED: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 4,
        },
        MISSING_ID: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 2,
        },
    },
    ORCHESTRATION: {
        SUCCESS: {
            response_code: ngx.HTTP_OK,
            code: 0,
        },
        IO_ERROR: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 1,
        },
        PLAN_MISSING: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 3,
        },
        WAITING_FOR_RESUME: {
            response_code: ngx.HTTP_OK,
            code: 5,
        },
        PLAN_EXECUTION_MISSING: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 7,
        },
        ERROR_DURING_EXECUTION: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 8,
        },
        ENTITY_NOT_FOUND: {
            response_code: ngx.HTTP_NOT_FOUND,
            code: 9,
        },
        INVALID_RESERVATION: {
            response_code: ngx.HTTP_NOT_FOUND,
            code: 11,
        },
        RESERVATION_ALREADY_USED: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 12,
        },
        INVALID_STATUS: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 13,
        },
        PENDING: {
            response_code: ngx.HTTP_ACCEPTED,
            code: 14,
        },
        INVALID_PARAM_FORMAT: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 15,
        },
        WAITING_FOR_CALLBACK: {
            response_code: ngx.HTTP_OK,
            code: 16,
        },
        EXPIRED_RESERVATION: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 17,
        },
        EXCEED_MAX_ATTEMPT: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 18,
        },
        RETRY_STATE_EXECUTION: {
            response_code: ngx.HTTP_OK,
            code: 19,
        },
        INVALID_STATE_TRANSITION: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 20,
        },
        STATE_NOT_RESTARTABLE: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 21,
        },
        INTERNAL_ERROR: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 22,
        },
        PLAN_NOT_DEFINED: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 23,
        },
        PLAN_DEFINITION_MISMATCH: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 24,
        },
        BAD_CRON_EXPRESSION: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 25,
        },
        CRONJOB_MISSING: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 26,
        },
        LOCK_RETRY_EXCEEDED: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 27,
        },
        WAITING_FOR_RESTART: {
            response_code: ngx.HTTP_OK,
            code: 28,
        },
        STATE_NOT_DEFINED: {
            response_code: ngx.HTTP_CONFLICT,
            code: 30,
        },
        RESERVATION_NOT_FOUND: {
            response_code: ngx.HTTP_NOT_FOUND,
            code: 35,
        },
    },
    ORCHESTRATIONPLAN: {
        ENTITY_ALREADY_EXISTS: {
            response_code: ngx.HTTP_CONFLICT,
            code: 4,
        },
        TEMPLATE_NOT_RESOLVABLE: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 6,
        },
        BAD_ENTITY_STATE: {
            response_code: ngx.HTTP_CONFLICT,
            code: 7,
        },
        PLAN_LINTING_ERROR: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 8,
        },
    },
    CDP_CUSTOM_EVENTS: {
        SUCCESS: {
            response_code: ngx.HTTP_OK,
            code: 0,
        },
        IO_ERROR: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 1,
        },
        NOT_FOUND: {
            response_code: ngx.HTTP_NOT_FOUND,
            code: 2,
        },
        NAME_ALREADY_EXISTS: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 3,
            errorMessage: i18n.t('events.alerts.eventNameAlreadyExists'),
        },
        FIELD_MISSING: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 4,
            errorMessage: i18n.t('events.alerts.fieldMissing'),
        },
        REPEATED_FIELD: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 6,
            errorMessage: i18n.t('events.alerts.repeatedField'),
        },
        WRONG_FIELD_TYPE: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 7,
            errorMessage: i18n.t('events.alerts.wrongFieldType'),
        },
        RESERVED_FIELD_NAME: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 9,
            errorMessage: i18n.t('events.alerts.reservedFieldName'),
        },
        FIELD_VALIDATION_ERROR: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 19,
            errorMessage: i18n.t('events.alerts.fieldValidationError'),
        },
        AUTH_TYPE_MISSING: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 21,
            errorMessage: i18n.t('events.alerts.authTypeMissing'),
        },
        EVENT_IS_IN_USE: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 22,
            errorMessage: i18n.t('events.alerts.eventIsInUse'),
        },
        API_CONTROL_FIELD_VALIDATION_ERROR: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 23,
            errorMessage: i18n.t('events.alerts.apiControlFieldValidationFailed'),
        },
        EVENT_TYPE_ALREADY_EXISTS: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 28,
            errorMessage: i18n.t('events.alerts.eventTypeAlreadyExists'),
        },
        EDIT_FORBIDDEN_FOR_EVENT_SOURCE: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 29,
            errorMessage: i18n.t('events.alerts.editForbiddenForEventSource'),
        },
        WRONG_DATASOURCE: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 30,
            errorMessage: i18n.t('events.alerts.wrongDatasource'),
        },
        FIELDS_CONFIGURATION_VALIDATION_ERROR: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 31,
            errorMessage: i18n.t('events.alerts.fieldsConfigurationValidationError'),
        },
        FIELDS_CONFIG_MISSING_REQUIRED_FIELDS: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 32,
            errorMessage: i18n.t('events.alerts.fieldsConfigurationMissingRequiredFields'),
        },
        FIELDS_CONFIG_MISSING_SOURCE: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 33,
            errorMessage: i18n.t('events.alerts.fieldsConfigurationMissingSource'),
        },
        FIELD_CAN_NOT_BE_FORMATTED: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 34,
            errorMessage: i18n.t('events.alerts.fieldCanNotBeFormatted'),
        },
        NON_SUPPORTED_FORMATTER: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 35,
            errorMessage: i18n.t('events.alerts.nonSupportedFormatter'),
        },
        INCORRECT_FINAL_FIELD_TYPE: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 36,
            errorMessage: i18n.t('events.alerts.incorrectFinalFieldType'),
        },
        NOT_AVAILABLE_PARAMETER_FOR_FORMATTER: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 37,
            errorMessage: i18n.t('events.alerts.notAvailableParameterForFormatter'),
        },
        NO_EVENT_FIELD_TO_FORMAT: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 38,
            errorMessage: i18n.t('events.alerts.noEventFieldToFormat'),
        },
        FIELDS_CONFIGURATION_EMPTY: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 39,
            errorMessage: i18n.t('events.alerts.fieldsConfigurationEmpty'),
        },
        FIELDS_CONFIG_REORDERING_FORBIDDEN: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 40,
            errorMessage: i18n.t('events.alerts.fieldsConfigurationReorderingForbidden'),
        },
        FIELD_CONFIG_TYPE_CHANGE_FORBIDDEN: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 41,
            errorMessage: i18n.t('events.alerts.fieldsConfigurationTypeChangeForbidden'),
        },
        FIELD_CONFIG_ADDITION_FORBIDDEN: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 42,
            errorMessage: i18n.t('events.alerts.fieldsConfigurationAdditionForbidden'),
        },
        FIELD_CONFIG_REMOVAL_FORBIDDEN: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 43,
            errorMessage: i18n.t('events.alerts.fieldsConfigurationRemovalForbidden'),
        },
        FIELD_CONFIG_CONTENT_CHANGE_FORBIDDEN: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 44,
            errorMessage: i18n.t('events.alerts.fieldsConfigurationContentChangeForbidden'),
        },
        FIELDS_CONFIGURATION_SHOULD_BE_EMPTY: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 46,
            errorMessage: i18n.t('events.alerts.fieldsConfigurationShouldBeEmpty'),
        },
        SINK_ENTITY_PROPERTIES_UNUSED_EVENTS: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 50,
            errorMessage: i18n.t('events.alerts.sinkEntityPropertiesUnusedEvents'),
        },
        FIELDS_CONFIGURATION_TYPE_MISMATCH: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 52,
            errorMessage: i18n.t('events.alerts.fieldsConfigurationTypeMismatch'),
        },
        FIELDS_CONFIGURATION_WRONG_FIELD_TYPE: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 54,
            errorMessage: i18n.t('events.alerts.fieldsConfigurationWrongFieldType'),
        },
        FIELDS_CONFIGURATION_WRONG_DATASOURCE: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 55,
            errorMessage: i18n.t('events.alerts.fieldsConfigurationWrongDatasource'),
        },
        FIELDS_CONFIG_MISSING_EVENT_FIELD: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 56,
            errorMessage: i18n.t('events.alerts.fieldsConfigurationMissingEventField'),
        },
        EVENT_FIELDS_VALIDATION_ERROR: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 57,
            errorMessage: i18n.t('events.alerts.eventFieldsValidationError'),
        },
        INVALID_HUMAN_READABLE_NAME: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 58,
            errorMessage: i18n.t('events.alerts.eventNameIsInvalid'),
        },
        INTERNAL_EVENT_API_CONTROL_UPDATE_FORBIDDEN: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 59,
            errorMessage: i18n.t('events.alerts.internalEventApiControlUpdateForbiden'),
        },
        INTERNAL_EVENT_SCHEMA_UPDATE_FORBIDDEN: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 60,
            errorMessage: i18n.t('events.alerts.internalEventSchemaUpdateForbidden'),
        },
        FIELDS_CONFIG_SUM_DEFINITION_FORBIDDEN_FOR_NON_NUMBER_TYPE: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 62,
            errorMessage: i18n.t('events.alerts.fieldsConfigurationSumDefinitionForbiddenForNonNumberType'),
        },
        EVENT_FIELD_DESCRIPTION_IS_REQUIRED: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 63,
            errorMessage: i18n.t('events.alerts.eventFieldDescriptionIsRequired'),
        },
        REQUIRED_EVENT_FIELD_DESCRIPTION_DOES_NOT_MATCH: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 64,
            errorMessage: i18n.t('events.alerts.requiredEventFieldDescriptionDoesNotMatch'),
        },
        INVALID_ALLOWED_AUTH_TYPES: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 67,
            errorMessage: i18n.t('events.alerts.invalidAllowedAuthTypes'),
        },
        CHANGED_FIELDS_CONFIGURATION_IS_IN_USE: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 68,
            errorMessage: i18n.t('events.alerts.changedFieldsConfigurationIsInUse'),
        },
        LF_JSON_SCHEMA_VALIDATION_FAILED: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 69,
            errorMessage: i18n.t('events.alerts.eventSchemaRouteValidationFailure'),
        },
        FIELD_SHOULD_HAVE_CASTED_TYPE: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 70,
            errorMessage: i18n.t('events.alerts.casterTypeNeeded'),
        },
        MISSED_REQUIRED_FIELD: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 72,
            errorMessage: i18n.t('events.alerts.requiredFieldShouldBePresent'),
        },
        INCOMPATIBLE_WITH_CASTER_FIELD_TYPE: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 73,
            errorMessage: i18n.t('events.alerts.incompatibleCasting'),
        },
        SCHEMA_REF_INVALID_PATH: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 74,
            errorMessage: i18n.t('events.alerts.schemaRefHasInvalidPath'),
        },
        SCHEMA_REF_CIRCULAR_DEPENDENCY_DETECTED: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 75,
            errorMessage: i18n.t('events.alerts.refCircularDependency'),
        },
        CASTING_SETTINGS_MUST_BE_PRESENT: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 76,
            errorMessage: i18n.t('events.alerts.castersMustBePresent'),
        },
        CASTER_DOES_NOT_EXIST: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 77,
            errorMessage: i18n.t('events.alerts.nonExistingCasterName'),
        },
    },
    TRIGGER_CONDITION_VALIDATOR: {
        FILTER_PROPERTIES_NOT_FOUND: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 2,
            errorMessage: i18n.t('triggerConditionValidator.alerts.filterPropertiesNotFound'),
        },
        NOT_FOUND: {
            response_code: ngx.HTTP_NOT_FOUND,
            code: 3,
        },
        MUTUALLY_EXCLUSIVE_CONDITIONS_FOUND: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 4,
            errorMessage: i18n.t('triggerConditionValidator.alerts.mutuallyExclusiveConditionsFound'),
        },
        FILTER_SHOULD_HAVE_AT_LEAST_ONE_NAME: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 5,
            errorMessage: i18n.t('triggerConditionValidator.alerts.filterInstanceDoesNotHaveName'),
        },
        FILTER_SHOULD_HAVE_ONE_UNIQUE_NAME: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 6,
            errorMessage: i18n.t('triggerConditionValidator.alerts.filterShouldHaveOneUniqueName'),
        },
    },
    CXS: {
        INVALID_PARAMETER: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 1,
        },
        REQUEST_PARAMETER_INVALID: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 2,
        },
        MANDATORY_FILED_MISSING: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 3,
        },
        PAYLOAD_IS_UNSUPPORTED: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 4,
        },
        VALIDATION_FAILED: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 5,
        },
        REQUEST_ALREADY_PROCESSED_OR_IN_PROGRESS: {
            response_code: ngx.HTTP_CONFLICT,
            code: 6,
        },
        PAYMENT_ID_NOT_FOUND: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 7,
        },
    },
    GGASH: {
        UNKNOWN_EXCEPTION: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 1,
        },
        REQUEST_TRAFFIC_EXCEED_LIMIT: {
            response_code: ngx.HTTP_TOO_MANY_REQUESTS,
            code: 2,
        },
        REPEAT_REQ_INCONSISTENT: {
            response_code: ngx.HTTP_TOO_MANY_REQUESTS,
            code: 3,
        },
        PROCESS_FAIL: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 4,
        },
        PARAM_ILLEGAL: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 5,
        },
        ACCESS_DENIED: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 6,
        },
        PAYMENT_AMOUNT_EXCEED_LIMIT: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 7,
        },
        PARTNER_NOT_EXIST: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 8,
        },
        PARTNER_STATUS_ABNORMAL: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 9,
        },
        RISK_REJECT: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 10,
        },
        CURRENCY_NOT_SUPPORT: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 11,
        },
        USER_AMOUNT_EXCEED_LIMIT: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 12,
        },
    },
    PAYMENT: {
        MISSING_PAYMENT_METHOD_DATA: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 29,
        },
        EXTERNAL_API_FAILURE: {
            response_code: ngx.HTTP_BAD_GATEWAY,
            code: 1,
        },
        BILL_STATEMENT_PROCESS_TIMEOUT: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 22,
        },
        PAYMENT_METHODS_MISMATCH: {
            response_code: ngx.HTTP_CONFLICT,
            code: 27,
        },
        WAITING_FOR_PROCESSING: {
            response_code: ngx.HTTP_ACCEPTED,
            code: 13,
        },
        INVALID_TARGET_TYPE: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 21,
        },
        ORDER_CATALOG_INFO_MISSING: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 23,
        },
        RESERVATION_ALREADY_EXISTS: {
            response_code: ngx.HTTP_CONFLICT,
            code: 6,
        },
        TRANSACTION_NOT_FOUND: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 26,
        },
        INVALID_TRANSACTION_STATE: {
            response_code: ngx.HTTP_CONFLICT,
            code: 34,
        },
        EXTERNAL_API_FIELD_MISMATCH: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 16,
        },
        IO_ERROR: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 11,
        },
        INVALID_DATE_SELECTION: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 19,
        },
        PAYMENT_SOURCE_NOT_ALLOWED: {
            response_code: ngx.HTTP_UNAUTHORIZED,
            code: 3,
        },
        CANNOT_CANCEL_TRANSACTION: {
            response_code: ngx.HTTP_CONFLICT,
            code: 14,
        },
        BILL_STATEMENT_NOT_SETTLED: {
            response_code: ngx.HTTP_ACCEPTED,
            code: 28,
        },
        MISSING_TAX_REQUEST_RECORD: {
            response_code: ngx.HTTP_NOT_FOUND,
            code: 9,
        },
        INSUFFICIENT_FUNDS: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 33,
        },
        ORDER_NOT_FOUND: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 31,
        },
        MISSING_RESERVATION_ID: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 8,
        },
        VALIDITY_CHECK_FAILURE: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 12,
        },
        ERROR_IN_CORE_FUNCTION: {
            code: 32,
        },
        PENDING: {
            response_code: ngx.HTTP_CONFLICT,
            code: 18,
        },
        MISSING_TRANSACTION_ID: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 7,
        },
        PAYMENT_RETURNS_FINISHED: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 15,
        },
        MANAGE_PAYMENTS_FAILURE: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 5,
        },
        SUCCESS: {
            response_code: ngx.HTTP_OK,
            code: 0,
        },
        ORDER_BASE_PLAN_MISSING: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 25,
        },
        MISSING_PARAMETERS: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 2,
        },
        INVALID_PAYMENT_ACTION: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 4,
        },
        PARSE_ERROR: {
            response_code: ngx.HTTP_INTERNAL_SERVER_ERROR,
            code: 10,
        },
        ORDER_SERVICE_INFO_MISSING: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 24,
        },
        EXTERNAL_API_PERFORMED: {
            response_code: ngx.HTTP_OK,
            code: 17,
        },
        MISSING_BASEPLAN_PAYMENT_RECORD: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 20,
        },
        INVALID_TAX_RULE_ID: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 30,
        },
        CALLBACK_TIMEOUT: {
            response_code: ngx.HTTP_GATEWAY_TIMEOUT,
            code: 49,
        },
    },
    END_USER_AUTHORIZATION: {
        NOT_VALID_PIN: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 183,
        },
        PIN_RESET_REQUIRED: {
            response_code: ngx.HTTP_FORBIDDEN,
            code: 182,
        },
    },
    CCS_USER_FORGET_ME_FLOW: {
        CONFLICT: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 160,
        },
    },
    EVENT_COMPOSITION_CONFIG: {
        INVALID: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 1,
        },
        IOERROR: {
            response_code: ngx.INTERNAL_SERVER_ERROR,
            code: 2,
        },
        INVALID_COMPOSITION_KEY: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 3,
            errorMessage: i18n.t('sinkConfigs.eventComposition.alerts.invalidCompositionKey'),
        },
        NAME_ALREADY_EXISTS: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 4,
            errorMessage: i18n.t('sinkConfigs.eventComposition.alerts.nameAlreadyExists'),
        },
        // INTERNAL_ENTITY_VALIDATION_ERRORS - code: 5 - only for internal validation
        INVALID_INPUT_OUTPUT_EVENT: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 6,
            errorMessage: i18n.t('sinkConfigs.eventComposition.alerts.invalidInputOutputEvent'),
        },
        OUTPUT_EVENT_USED_IN_COMPOSITION_KEY: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 7,
            errorMessage: i18n.t('sinkConfigs.eventComposition.alerts.outputEventUsedInCompositionKey'),
        },
        // INVALID_ENTITY_ID: code: 8 - only for internal validation
        EVENT_SCHEMA_NOT_FOUND: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 9,
            errorMessage: i18n.t('sinkConfigs.eventComposition.alerts.eventSchemaNotFound'),
        },
        INVALID_SINK_EVENT_FIELD_TYPE: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 10,
            errorMessage: i18n.t('sinkConfigs.eventComposition.alerts.invalidSinkEventFieldType'),
        },
        MISSED_SINK_EVENT_REQUIRED_FIELD: {
            response_code: ngx.HTTP_BAD_REQUEST,
            code: 11,
            errorMessage: i18n.t('sinkConfigs.eventComposition.alerts.missedSinkEventRequiredField'),
        },
    },
};

export const modules: Record<string, string> = Object.fromEntries(Object.keys(errorMap).map(key => [key, key]));

export default errorMap;
