import http from '@/http/index';

/**
 * Endpoint for retrieving all numbers by different statuses.
 * Page size is hardcoded as it really does not impact user at all and is mainly used to optimize API calls.
 * @param status
 * @return {AxiosPromise}
 */
export function downloadNumbers(status) {
    return http({
        method: 'POST',
        url: '/v3numbermanager/download',
        headers: {
            'Content-type': 'application/json',
        },
        data: {
            status,
            page_size: 100,
        },
    });
}

/**
 * @param query
 * @return {AxiosPromise}
 */
export function requestNumbers(query) {
    return http({
        method: 'POST',
        url: '/v3numbermanager/request',
        headers: {
            'Content-type': 'application/json',
        },
        data: query,
    });
}

/**
 * @param batch
 * @return {AxiosPromise}
 */
export function addBatchNumbers(batch) {
    return http({
        method: 'POST',
        url: '/v3numbermanagerwrite/addbatch',
        headers: {
            'Content-type': 'application/json',
        },
        data: {
            batch,
        },
    });
}

/**
 * @param number
 * @return {AxiosPromise}
 */
export function removeNumbers(number) {
    return http({
        method: 'POST',
        url: '/v3numbermanagerwrite/remove',
        headers: {
            'Content-type': 'application/json',
        },
        data: {
            number,
        },
    });
}

/**
 * @returns {AxiosPromise}
 */
export function getSignedURL(params) {
    return http({
        method: 'GET',
        url: '/v3numbermanager/signedurl',
        headers: {
            'Content-type': 'application/json',
        },
        params,
    });
}

/**
 * @param page
 * @param size
 * @returns {AxiosPromise}
 */
export function getRegisteredEntities(page, size) {
    return http({
        method: 'GET',
        url: '/v3numbermanagerwrite/registeredentities',
        headers: {
            'Content-type': 'application/json',
        },
        params: { page, size },
    });
}

/**
 * @param name
 * @returns {AxiosPromise}
 */
export function getEntityDetailsByName(name) {
    return http({
        method: 'GET',
        url: '/v3numbermanagerwrite/entitydetailsbyname',
        headers: {
            'Content-type': 'application/json',
        },
        params: { name },
    });
}

/**
 * @param name
 * @param category
 * @param page
 * @param size
 * @returns {AxiosPromise}
 */
export function getEntityDetailsByCategory(name, category, page, size) {
    return http({
        method: 'GET',
        url: '/v3numbermanagerwrite/entitydetailsbycategory',
        headers: {
            'Content-type': 'application/json',
        },
        params: {
            name,
            category,
            page,
            size,
        },
    });
}

/**
 * @return {AxiosPromise}
 */
export function getMsisdnStatistics() {
    return http({
        method: 'POST',
        url: '/v3numbermanager/getmsisdnstatistics',
    });
}

/**
 * @param msisdn
 * @returns {AxiosPromise}
 */
export function queryNumber(msisdn) {
    return http({
        method: 'POST',
        url: '/v3numbermanager/querynumber',
        data: {
            msisdn,
        },
    });
}

export default {};
