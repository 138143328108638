import Actions, { Getters, Mutations, State } from '@/store/mutation-types';

import { isEmpty } from 'lodash';
import { getQuietHours } from '@/__new__/services/dno/quietHours/http/quietHours';
import {
    mapQHFromResponse,
    QuietHoursConfig,
    QuietHoursConfigBE,
} from '@/__new__/features/settings/quietHours/quietHoursHelper';
import { Module } from 'vuex';

interface QuietHoursState {
    [State.QUIET_HOURS]?: QuietHoursConfigBE | Record<string, any>;
}
const store: Module<QuietHoursState, any> = {
    namespaced: true,
    state: {
        [State.QUIET_HOURS]: {},
    },
    mutations: {
        [Mutations.SET_QUIET_HOURS]: (_state: any, qh: QuietHoursConfigBE) => {
            _state[State.QUIET_HOURS] = qh;
        },
    },
    actions: {
        async [Actions.LOAD_QUIET_HOURS]({ state, commit }: any) {
            if (!isEmpty(state[State.QUIET_HOURS])) return;
            const response = await getQuietHours();
            commit(Mutations.SET_QUIET_HOURS, response);
        },
    },
    getters: {
        [Getters.GET_MAPPED_QUIET_HOURS]: (state: any) => {
            return mapQHFromResponse(state) as QuietHoursConfig[];
        },
    },
};
export default store;
