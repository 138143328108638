<template>
    <div class="h-100">
        <AbstractListPageWrapper
            :pageTitle="$i18n.t('charging.entities.plural.chargingSpecificationsGroups')"
            :isOverviewEnabled="isOverviewEnabled"
            :entitiesCount="sortedChargingSpecificationsGroups.length"
            @searchQueryChanged="setSearchQuery"
        >
            <template slot="filterTable">
                <FilterTable
                    :columns="tableColumnsData"
                    @filterAdded="onFilterAdded"
                />
            </template>
            <template slot="button">
                <div class="d-flex align-items-center">
                    <ResponseModalButton
                        :response="payoutsApiResponse"
                        :title="$i18n.t('charging.entities.plural.chargingSpecificationsGroups')"
                        class="mr-3"
                        data-test-id="response-modal-button"
                    />
                    <IconButton
                        :label="$i18n.t('productCatalog.downloadDetails')"
                        :icon="ICON_TYPES.DOWNLOAD"
                        data-test-id="btn-download-csv"
                        @iconClick="triggerDownloadingCSV"
                    />
                </div>
            </template>
            <template slot="headerButtons">
                <AppButton
                    v-if="isEditEnabled"
                    :buttonType="BUTTON_TYPES.PRIMARY"
                    :iconType="ICON_TYPES.PLUS"
                    :label="$i18n.t('home.createNew')"
                    data-test-id="new-csg"
                    @click="onCreate"
                />
            </template>
            <template slot="allFilters">
                <TableFiltersTags
                    :filterTableMixin="filterTableMixin"
                    class="my-3 ml-2"
                    @removeFilter="removeFilter"
                    @removeAllFilters="removeAllFilters"
                />
            </template>
            <template slot="table">
                <AppTable
                    :entities="sortedFilteredChargingSpecificationsGroups"
                    :selectedEntityId="selectedEntityId"
                    :innerSearchQuery="searchQueryForTable"
                    :isSearchEnabled="true"
                    :columnsData="tableColumnsData"
                    :isDataLoading="isDataLoading"
                    :entityType="entityType"
                    :enableRowStateControls="isEditEnabled"
                    :requestEntities="requestEntities"
                    :emitEntityActions="true"
                    tableKey="charging/charging-specifications-groups"
                    data-test-id="csg-table"
                    @returnCleanEntities="downloadOffersCSV"
                    @selectEntity="onSelect"
                    @input="ent => setNumberOfItemsAfterSearch(ent.length, sortedChargingSpecificationsGroups.length)"
                    @edit="onEdit"
                    @clone="onClone"
                    @delete="onDelete"
                    @readonly="onReadOnly"
                >
                    <template #entityVersion="{ entity }">
                        <EntityStatusIndicator :status="entity.entityVersion" />
                    </template>
                </AppTable>
            </template>
            <template slot="overview">
                <MutationDialog
                    v-model="isMutationModalVisible"
                    :entities="getAffectedEntities(mutationDialogDataType)"
                    @input="clearMutationDialogDataType"
                />
                <EntityOverview
                    :entityType="entityType"
                    :entity="selectedEntity"
                    @closeOverview="isOverviewEnabled = false"
                >
                    <div :slot="`section-1-header`">
                        <OverviewHeader
                            v-if="selectedEntityId"
                            :entityName="entityName(selectedEntity.data.name)"
                            :entityType="entityType"
                        />
                        <LanguageSwitcher v-model="selectedLanguage" />
                    </div>

                    <div :slot="`section-1-content`">
                        <OverviewList
                            :lists="detailsSections"
                            @showDialog="showMutationDialog"
                        />
                    </div>

                    <div :slot="`section-2-header`">
                        <OverviewHeader
                            v-if="selectedEntityId"
                            :entityName="entityName(selectedEntity.data.name)"
                            :entityType="entityType"
                        />
                    </div>

                    <div :slot="`section-2-content`">
                        <OverviewList :lists="chargingSpecificationsSection" />
                    </div>
                </EntityOverview>
            </template>
        </AbstractListPageWrapper>
    </div>
</template>

<script>
// Vuex
import { mapActions, mapGetters } from 'vuex';
import Actions, { Getters, State } from '@/store/mutation-types';

// Http
import { getServices as getProductCatalogServices } from '@/__new__/services/dno/pc/http/service';
import {
    updateChargingSpecificationsGroupsState,
    deleteChargingSpecificationsGroupDraft,
} from '@/__new__/services/dno/charging/http/chargingSpecificationsGroups';

// Generic Vue Components
import AppTable from '@/components/partials/AppTable.vue';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import { ICON_TYPES } from '@/common/iconHelper';
import AbstractListPageWrapper from '@/components/layout/AbstractListPageWrapper.vue';
import EntityOverview from '@/components/partials/entityOverview/EntityOverview.vue';
import OverviewHeader from '@/components/partials/entityOverview/OverviewHeader.vue';
import OverviewList from '@/components/partials/entityOverview/OverviewList.vue';
import MutationDialog from '@/components/partials/MutationDialog.vue';
import EntityStatusIndicator from '@/components/partials/EntityStatusIndicator.vue';
import FilterTable from '@/components/partials/FilterTable.vue';
import LanguageSwitcher from '@/components/partials/inputs/LanguageSwitcher.vue';
import TableFiltersTags from '@/components/filters/TableFiltersTags.vue';
import ResponseModalButton from '@/components/partials/ResponseModalButton.vue';
import IconButton from '@/components/partials/IconButton.vue';

// Mixins
import CountControlMixin from '@/components/partials/CountControlMixin.vue';
import FilterTableMixin from '@/components/partials/FilterTableMixin.vue';
import DownloadDetailsMixin from '@/__new__/features/charging/DownloadDetailsMixin.vue';
import ChargingCommonActionsMixin from '@/__new__/features/charging/ChargingCommonActionsMixin.vue';

// Helpers
import RouteNames from '@/router/routeNames';
import permissionsService, { isUserAllowed, isViewEnabled } from '@/services/permissions/permissions.service';
import ENTITY_TYPES from '@/common/entities/entityTypes';
import { formatChargingSpecificationDataForOverview } from '@/__new__/services/dno/charging/common/chargingSpecificationHelper';
import { onlyFirstLetterUppercase } from '@/common/utils';
import { entityStateReverseMap, StateActionsMapping } from '@/common/entityStateMapper';
import {
    getMultiLangFieldValueByLocale,
    getProperlyFormattedMultilangFieldValue,
    getEntityConsumers,
    getMutationDialogTitleKey,
    formatMutationDialogEntities,
} from '@/common/entities/entityHelper';
import upperFirst from 'lodash/upperFirst';
import toUpper from 'lodash/toUpper';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import localeLibrary from '@/common/locale/localeLibrary';
import tableColumnType from '@/common/filterTable';
import { languageMap } from '@/common/locale/language';
import { STATUS_CODE_TO_STATUS_NAME_MAP, STATUS_CODES } from '@/common/commonHelper';
import { EntityActions } from '@/common/baseStatesHelper';
import * as Sentry from '@sentry/vue';

export default {
    name: 'ChargingSpecificationsGroups',
    components: {
        AbstractListPageWrapper,
        AppTable,
        AppButton,
        EntityOverview,
        OverviewHeader,
        OverviewList,
        MutationDialog,
        EntityStatusIndicator,
        FilterTable,
        LanguageSwitcher,
        TableFiltersTags,
        ResponseModalButton,
        IconButton,
    },
    mixins: [CountControlMixin, FilterTableMixin, DownloadDetailsMixin, ChargingCommonActionsMixin],
    data() {
        return {
            columns: [],
            entityType: ENTITY_TYPES.CHARGING_SPECIFICATIONS_GROUP,
            editorRoute: RouteNames.CHARGING_SPECIFICATIONS_GROUP_EDITOR,
            selectedEntityId: null,
            selectedEntity: null,
            searchQueryForTable: '',
            isDataLoading: false,
            isMutationModalVisible: false,
            mutationDialogDataType: '',
            selectedLanguage: '',
            permissionsService,
            ICON_TYPES,
            BUTTON_TYPES,
            ENTITY_TYPES,
            isOverviewEnabled: false,
            productCatalogServices: [],
            allowDeleteEntities: isUserAllowed('ChargingDeleteEntities'),
        };
    },
    computed: {
        ...mapGetters('operators', [Getters.languageDefault, Getters.languagesWithoutDefault]),
        ...mapGetters('charging', [
            Getters.GET_CHARGING_SPECIFICATIONS_GROUPS,
            Getters.GET_CHARGING_SPECIFICATIONS_GROUPS_API_RESPONSE,
            Getters.GET_CHARGING_SPECIFICATIONS_BY_IDS,
            Getters.GET_CHARGING_SPECIFICATIONS_GROUP_BY_ID,
        ]),
        ...mapGetters('productcatalog', [Getters.PC_GET_ENTITIES_BY_TYPE_NOT_DELETED]),
        isEditEnabled() {
            return (
                this.permissionsService.chargingChargingSpecificationsGroupsEnabled() &&
                isUserAllowed('ChargingSpecificationGroupsReadWrite')
            );
        },
        areProductCatalogServicesEnabled() {
            return permissionsService.productCatalogServicesEnabled() && isUserAllowed('ServicesRead');
        },
        languages() {
            return this.$store.state.operators[State.Languages];
        },
        isOfferToCsgMappingEnabled() {
            return isViewEnabled('OfferToCSGMapping');
        },
        payoutsApiResponse() {
            return this[Getters.GET_CHARGING_SPECIFICATIONS_GROUPS_API_RESPONSE];
        },
        getchargingSpecificationsGroupsApiPayload() {
            return this[Getters.GET_CHARGING_SPECIFICATIONS_GROUPS] || [];
        },
        chargingSpecificationsGroups() {
            return this.getchargingSpecificationsGroupsApiPayload.map(csg => ({
                ...csg,
                priority: csg.data.priority,
                isBasePlanText: csg.data.is_base_plan ? this.$i18n.t('generic.yes') : this.$i18n.t('generic.no'),
                entityVersionLabel: STATUS_CODE_TO_STATUS_NAME_MAP.get(csg.entityVersion),
                allowedActionsExternal:
                    csg.state !== STATUS_CODES.NA
                        ? [
                              EntityActions.CLONE,
                              ...(this.allowDeleteEntities ? [EntityActions.DELETE] : []),
                              EntityActions.EDIT,
                              EntityActions.READ_ONLY,
                          ]
                        : [EntityActions.DELETE, EntityActions.EDIT],
            }));
        },
        sortedFilteredChargingSpecificationsGroups() {
            return this.filteredEntitiesMixin(this.sortedChargingSpecificationsGroups);
        },
        sortedChargingSpecificationsGroups() {
            if (this.chargingSpecificationsGroups.length) {
                return [...this.chargingSpecificationsGroups]
                    .map(chargingSpecificationsGroup => ({
                        ...chargingSpecificationsGroup,
                        data: {
                            ...chargingSpecificationsGroup.data,
                            name: getMultiLangFieldValueByLocale(
                                chargingSpecificationsGroup.data.name,
                                this.languageDefault,
                            ),
                        },
                        status: this.entityState(chargingSpecificationsGroup.state),
                        name: getMultiLangFieldValueByLocale(
                            chargingSpecificationsGroup.data.name,
                            this.languageDefault,
                        ),
                        updatedTime: Object.prototype.hasOwnProperty.call(chargingSpecificationsGroup, 'update_time')
                            ? moment.unix(chargingSpecificationsGroup.update_time)
                            : null,
                    }))
                    .sort((group1, group2) => group1?.data?.name.localeCompare(group2?.data?.name));
            }
            return [];
        },
        allowedActions() {
            return StateActionsMapping[this.selectedEntity.state];
        },
        detailsSections() {
            if (!this.selectedEntityId) {
                return [];
            }
            return [this.generalSection, this.propertiesDetails, this.upperLevelEntities];
        },
        generalSection() {
            const selectedEntityDescription = getProperlyFormattedMultilangFieldValue(
                this.selectedEntity.data.description,
                this[Getters.languageDefault],
            );

            // all available descriptions are calculated here
            const descProperties = isEmpty(selectedEntityDescription)
                ? [
                      {
                          value: this.$i18n.t('generic.noDescription'),
                          label: `${this.$i18n.t('generic.description')}`,
                      },
                  ]
                : [
                      {
                          value: selectedEntityDescription[this.selectedLanguage],
                          label: `${this.$i18n.t('generic.description')}`,
                      },
                  ];

            return {
                name: toUpper(this.$i18n.t('generic.general')),
                properties: [
                    {
                        value: this.selectedEntity.id,
                        label: this.$i18n.t('generic.id'),
                    },
                    ...descProperties,
                ],
            };
        },
        // toDo add this section and check when BE is ready
        eligibilitySection() {
            const whitelist = this.selectedEntity.data.segment_expression.whitelist.segment_ids.map(id =>
                this.segments[id] && this.segments[id].name ? this.segments[id].name : id,
            );
            const blacklist = this.selectedEntity.data.segment_expression.blacklist.segment_ids.map(id =>
                this.segments[id] && this.segments[id].name ? this.segments[id].name : id,
            );
            return {
                name: this.$i18n.t('rewards.editor.eligibility.heading'),
                properties: [
                    {
                        value: whitelist.join(', '),
                        label: this.$i18n.t('rewards.editor.eligibility.availableTo'),
                    },
                    {
                        value: blacklist.join(', '),
                        label: this.$i18n.t('rewards.editor.eligibility.notAvailableTo'),
                    },
                    {
                        value: moment(this.selectedEntity.data.start_time).format(localeLibrary.getDateFormat()),
                        label: this.$i18n.t('rewards.editor.eligibility.startTime'),
                    },
                    {
                        value: moment(this.selectedEntity.data.end_time).format(localeLibrary.getDateFormat()),
                        label: this.$i18n.t('rewards.editor.eligibility.endTime'),
                    },
                ],
            };
        },
        propertiesDetails() {
            const selectedEntityData = this.selectedEntity.data;
            const selectedEntityName = getProperlyFormattedMultilangFieldValue(selectedEntityData.name);
            const selectedEntityDescription = getProperlyFormattedMultilangFieldValue(selectedEntityData.description);

            // names without default language name are calculated here
            const nameProperties = this[Getters.languagesWithoutDefault]
                .filter(language => !!selectedEntityName[language])
                .map(language => ({
                    value: selectedEntityName[language],
                    label: `Offer Name (${language})`,
                }));

            // all available descriptions are calculated here
            const descProperties = this.languages
                .filter(language => !!selectedEntityDescription[language])
                .map(language => ({
                    value: selectedEntityDescription[language],
                    label: `${this.$i18n.t('generic.description')} (${language})`,
                }));

            const priorityProperties = [
                {
                    value: this.selectedEntity.data.priority,
                    label: `${this.$i18n.t('generic.priority')}`,
                },
            ];

            const isBasePlanProperties = [
                {
                    value: this.selectedEntity.data.is_base_plan
                        ? this.$i18n.t('generic.yes')
                        : this.$i18n.t('generic.no'),
                    label: `${this.$i18n.t('charging.CSG.isBasePlan')}`,
                },
            ];

            const detailsSections = {
                name: 'Properties',
                properties: [...nameProperties, ...descProperties, ...priorityProperties, ...isBasePlanProperties],
            };

            if (this.chargingSpecificationsList.length) {
                detailsSections.properties.push({
                    value: this.chargingSpecificationsList
                        .filter(el => {
                            if (!el) {
                                this.$eventBus.$emit('showAlert', {
                                    message: this.$i18n.t('charging.CSG.errors.chargingSpecificationMissing'),
                                });
                            }
                            return Boolean(el);
                        })
                        .map(service => getMultiLangFieldValueByLocale(service.data?.name))
                        .join(', '),
                    label: this.$i18n.t('charging.entities.plural.chargingSpecifications'),
                });
            }

            return detailsSections;
        },
        upperLevelEntities() {
            const upperEntityType = this.isOfferToCsgMappingEnabled ? ENTITY_TYPES.OFFER : ENTITY_TYPES.SERVICE;
            const consumers = this.upperEntities()?.[upperEntityType] || [];

            if (!consumers.length) {
                return {};
            }

            return {
                name: `${upperFirst(this.$i18n.t('charging.entities.chargingSpecificationsGroup'))} ${this.$i18n.t(
                    'generic.usedBy',
                )}`,
                properties: [
                    {
                        label: `${consumers.length} ${
                            this.isOfferToCsgMappingEnabled
                                ? this.$i18n.t('productCatalog.entities.plural.offers')
                                : this.$i18n.t('productCatalog.entities.plural.services')
                        }`,
                        value: consumers.length,
                        linkLabel: this.$i18n.t('generic.seeDetails'),
                        formatter: 'link',
                        type: upperEntityType,
                    },
                ],
            };
        },
        chargingSpecificationsSection() {
            if (!this.selectedEntityId) {
                return [];
            }
            return this.chargingSpecificationsList
                .filter(el => {
                    if (!el) {
                        this.$eventBus.$emit('showAlert', {
                            message: this.$i18n.t('charging.CSG.errors.serviceMissing'),
                        });
                    }
                    return Boolean(el);
                })
                .map(service => formatChargingSpecificationDataForOverview(service));
        },
        chargingSpecificationsList() {
            return this[Getters.GET_CHARGING_SPECIFICATIONS_BY_IDS](this.selectedEntity.data.charging_specs);
        },
        tableColumnsData() {
            return [
                {
                    name: this.$i18n.t('generic.name'),
                    key: 'name',
                    mapper: entity => entity.data.name,
                    classes: ['data-name'],
                    forbidHideColumn: true,
                    field: 'name',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('generic.priority'),
                    key: 'priority',
                    field: 'priority',
                    filterType: tableColumnType.NUMBER,
                },
                {
                    name: this.$i18n.t('charging.CSG.isBasePlan'),
                    key: 'isBasePlanText',
                    field: 'isBasePlanText',
                    filterType: tableColumnType.TEXT_LIMITED_OPTIONS,
                    limitedOptions: [this.$i18n.t('generic.yes'), this.$i18n.t('generic.no')],
                },
                {
                    name: this.$i18n.t('generic.lastUpdatedTime'),
                    key: 'updateTime',
                    mapper: entity => this.$localeLibrary.getFormattedDate(entity.update_time),
                    sortBy: entity => entity.update_time,
                    field: 'update_time',
                    filterType: tableColumnType.DATE,
                },
                {
                    name: this.$i18n.t('generic.state'),
                    key: 'entityVersion',
                    field: 'entityVersionLabel',
                    filterType: tableColumnType.TEXT_LIMITED_OPTIONS,
                    limitedOptions: [
                        ...new Set(this.sortedFilteredChargingSpecificationsGroups.map(e => e?.entityVersionLabel)),
                    ],
                },
            ];
        },
    },
    created() {
        this.initData();
    },
    methods: {
        ...mapActions('productcatalog', [Actions.PC_REQUEST_ENTITIES_BY_TYPE_AND_IDS]),
        ...mapActions('charging', [
            Actions.REQUEST_CHARGING_SPECIFICATIONS,
            Actions.REQUEST_CHARGING_SPECIFICATIONS_GROUPS,
        ]),
        initData() {
            this.$withLoadingSpinner(
                async () => {
                    this.$Progress.start();
                    this.isDataLoading = true;
                    this.selectedLanguage = (this[Getters.languageDefault] || languageMap.en.key).toString();
                    const promises = [
                        this[Actions.REQUEST_CHARGING_SPECIFICATIONS](),
                        this[Actions.REQUEST_CHARGING_SPECIFICATIONS_GROUPS](),
                        this[Actions.PC_REQUEST_ENTITIES_BY_TYPE_AND_IDS]({ entityType: ENTITY_TYPES.OFFER }),
                        this[Actions.PC_REQUEST_ENTITIES_BY_TYPE_AND_IDS]({ entityType: ENTITY_TYPES.CATEGORY }),
                    ];
                    if (this.areProductCatalogServicesEnabled) {
                        promises.push(getProductCatalogServices());
                    }
                    const data = await Promise.all(promises);

                    if (this.areProductCatalogServicesEnabled) {
                        /* eslint-disable camelcase */
                        this.productCatalogServices = Object.values(data?.[4]?.data?.service_by_id || {}) || [];
                    }
                    this.$Progress.finish();
                    this.isDataLoading = false;
                },
                {
                    errorHandler: e => {
                        let message;
                        if (
                            e?.message === 'Request failed with status code 403' &&
                            [
                                '/v3productcatalog/getoffers',
                                '/v3productcatalog/getcategories',
                                '/v3productcatalog/getservices',
                            ].includes(e?.config?.url)
                        ) {
                            message = this.$i18n.t('charging.CSG.errors.roleDoesNotHavePermissionForPC');
                        } else {
                            message = this.$i18n.t('alertMessage.failedToLoadNecessaryData');
                        }

                        this.isDataLoading = false;
                        this.$Progress.fail();
                        Sentry.captureException(e);
                        this.$eventBus.$emit('showAlert', {
                            message,
                        });
                    },
                },
            );
        },
        onSelect(productId) {
            this.selectedEntity = this[Getters.GET_CHARGING_SPECIFICATIONS_GROUP_BY_ID](productId);
            this.selectedEntityId = productId;
            this.isOverviewEnabled = true;
        },
        onCreate() {
            this.$router.push({
                name: RouteNames.CHARGING_SPECIFICATIONS_GROUP_EDITOR,
                params: { companyId: this.$route.params.companyId },
            });
        },
        entityState(s) {
            return s
                ? this.$i18n.t(`finalStateMapper.${onlyFirstLetterUppercase(entityStateReverseMap(s))}`)
                : this.$i18n.t('generic.N/A');
        },
        setSearchQuery(query) {
            this.searchQueryForTable = query;
        },
        entityName(name) {
            return getMultiLangFieldValueByLocale(name, this.selectedLanguage);
        },
        showMutationDialog(type) {
            this.isMutationModalVisible = true;
            this.mutationDialogDataType = type;
        },
        clearMutationDialogDataType(value) {
            if (!value) {
                this.mutationDialogDataType = '';
            }
        },
        getAffectedEntities(mutationDialogDataType) {
            return formatMutationDialogEntities(this.upperEntities(), mutationDialogDataType);
        },
        getMutationDialogTitleKey,
        upperEntities(entityId = this.selectedEntityId) {
            const potentialConsumers = this.isOfferToCsgMappingEnabled
                ? this[Getters.PC_GET_ENTITIES_BY_TYPE_NOT_DELETED](ENTITY_TYPES.OFFER)
                : this.productCatalogServices;
            return getEntityConsumers(entityId, this.entityType, potentialConsumers);
        },
        onEdit(entityId) {
            this.onEditAction(entityId, this.editorRoute);
        },
        onClone(entityId) {
            this.onCloneAction(entityId, this.editorRoute);
        },
        onDelete(entityId) {
            const { state, version } = this[Getters.GET_CHARGING_SPECIFICATIONS_GROUP_BY_ID](entityId);
            const { [ENTITY_TYPES.SERVICE]: inUseByPCservices = [] } = this.upperEntities(entityId);

            this.showDeleteAlert(entityId, inUseByPCservices.length, state, version, [
                updateChargingSpecificationsGroupsState,
                deleteChargingSpecificationsGroupDraft,
                this.initData,
            ]);
        },
        onReadOnly(entityId) {
            this.onReadOnlyAction(entityId, this.editorRoute);
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/_colors';
@import '~@/assets/scss/_palette.scss';

.card-container {
    margin: 0 1.5rem 0.625rem 1.5rem;
    padding: 0.625rem 1rem;
    border-radius: 0.5rem;
    background-color: rgba($color: $blue, $alpha: 0.15);

    .card-title {
        color: $blue;
        font-size: 0.75rem;
        font-weight: 600;
    }
    .card-list {
        .card-content {
            display: flex;
            flex-direction: row;
            font-size: 0.875rem;
            color: $gray90;
        }
    }
}

.footer-button {
    margin-right: 0.75rem;
}

.data-name {
    font-weight: bold;
}
</style>
