<template>
    <div>
        <div class="section-content">
            {{ $i18n.t('charging.chargingSpecifications.editor.apn') }}
        </div>
        <TagsMultiselect
            v-model="apnData.data"
            :options="apnOptions"
            :placeholder="$i18n.t('charging.chargingSpecifications.editor.chooseApn')"
            :error="apnData.dataEmpty"
            :errorMessage="$i18n.t('generic.validations.fieldIsRequired')"
            data-test-id="apn-condition-multiselect"
            @input="onDataInput"
        />
    </div>
</template>

<script>
import TagsMultiselect from '@/components/partials/inputs/TagsMultiselect.vue';
import ApnCondition from '@/__new__/services/dno/charging/models/chargingSpecificationConditions/APNcondition';

export default {
    name: 'Apn',
    components: {
        TagsMultiselect,
    },
    props: {
        value: {
            type: Object,
            default: () => undefined,
        },
    },
    data() {
        return {
            apnOptions: [
                'lotusflare',
                'pruebas3.imptacion',
                'pruebas3.imptacion.mnc130.mcc732.gprs',
                'lte.avantel.com.co',
                'lte.avantel.com.co-CC-2',
                'lte.avantel.com.co.mnc130.mcc732.gprs',
                'lte.avantel.com.co.mnc130.mcc732.gprs-CC-2',
                'SA4',
            ],
            apnData: new ApnCondition(this.value),
        };
    },
    watch: {
        value: {
            handler() {
                this.apnData = new ApnCondition(this.value);
            },
        },
    },
    methods: {
        onDataInput() {
            this.apnData.validate();
            this.$emit('input', this.apnData);
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/_palette.scss';

.section-content {
    font-size: 0.75rem;
    font-weight: 600;
    margin-bottom: 1rem;
    margin-right: 1rem;
    color: $gray90;
}
</style>
