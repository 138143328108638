var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"dropdown",class:{ small: _vm.small }},[_c('div',{staticClass:"dropdown-header d-flex align-items-center",class:[
            !_vm.disableHeaderPadding && !_vm.small ? 'p-4' : 'p-0 pb-1',
            { 'justify-content-between flex-row-reverse': _vm.arrowRight },
        ],on:{"click":_vm.handleClick}},[_c('AppIcon',{class:['dropdown-arrow', { expanded: !_vm.isCollapsed }],attrs:{"type":"arrow-down-new"}}),(_vm.$slots.header)?_c('div',{staticClass:"w-100"},[_vm._t("header")],2):_c('div',{staticClass:"dropdown-summary",class:[
                { 'd-flex align-items-center justify-content-between w-100 has-labels': _vm.additionalLabels.length },
            ]},[_c('h2',{staticClass:"dropdown-title"},[_vm._v(" "+_vm._s(_vm.title)+" ")]),(_vm.additionalLabels.length)?_c('div',{staticClass:"dropdown-labels d-inline-flex flex-wrap align-items-center"},_vm._l((_vm.additionalLabels),function(label,i){return _c('AppLabel',{key:i,attrs:{"title":label.title,"color":label.color,"bgColor":label.bgColor}})}),1):_vm._e()])],1),_c('transition',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isCollapsed),expression:"!isCollapsed"}],ref:"dropdownContent",staticClass:"dropdown-content",class:[
                { 'start-expanded': _vm.startExpanded },
                { 'px-4 pb-4': !_vm.disableContentPadding && !_vm.small },
                { 'pt-1 px-2 pb-2': !_vm.disableContentPadding && _vm.small },
            ]},[_vm._t("default")],2)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }