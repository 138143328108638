// Generic
import { Module, ActionContext } from 'vuex';
import Actions, { State, Getters, Mutations } from '@/store/mutation-types';

// Http
import {
    getDataflowTemplates,
    getDataflowTemplateUiModel,
    getDataflows,
} from '@/__new__/services/dno/dataflows/http/dataflows';

// Models
import type {
    DataflowTemplate,
    DataflowUISectionResponse,
} from '@/__new__/features/dataflows/common/DataflowInterfaces';
import Dataflow from '@/__new__/services/dno/dataflows/models/Dataflow';

export const DATAFLOW_FORBIDDEN_BLUEPRINT_ID = {
    CUSTOM: 'custom',
    UNKNOWN: 'unknown',
};

interface DataflowsState {
    [State.DATAFLOWS]: Dataflow[];
    [State.DATAFLOW_TEMPLATES]: DataflowTemplate[];
    [State.DATAFLOW_TEMPLATE_UI_MODEL]: DataflowUISectionResponse[];
}

const store: Module<DataflowsState, unknown> = {
    namespaced: true,
    state: {
        [State.DATAFLOWS]: [],
        [State.DATAFLOW_TEMPLATES]: [],
        [State.DATAFLOW_TEMPLATE_UI_MODEL]: [],
    },
    mutations: {
        [Mutations.SET_DATAFLOWS]: (state: DataflowsState, dataflows: Dataflow[]): void => {
            state[State.DATAFLOWS] = dataflows;
        },
        [Mutations.SET_DATAFLOW_TEMPLATES]: (state: DataflowsState, response: DataflowTemplate[]): void => {
            state[State.DATAFLOW_TEMPLATES] = response;
        },
        [Mutations.SET_DATAFLOW_TEMPLATE_UI_MODEL]: (
            state: DataflowsState,
            models: DataflowUISectionResponse[],
        ): void => {
            state[State.DATAFLOW_TEMPLATE_UI_MODEL] = models;
        },
    },
    actions: {
        async [Actions.FETCH_DATAFLOWS](context: ActionContext<DataflowsState, unknown>): Promise<void> {
            try {
                const { data } = await getDataflows();
                const dataflows = data?.data?.dataflows
                    .map(Dataflow.mapFromResponse)
                    .filter(
                        d =>
                            !(
                                d.templates.length === 1 &&
                                Object.values(DATAFLOW_FORBIDDEN_BLUEPRINT_ID).includes(d.templates[0])
                            ),
                    );

                context.commit(Mutations.SET_DATAFLOWS, dataflows);
            } catch (e: any) {
                throw e;
            }
        },
        async [Actions.REQUEST_DATAFLOW_TEMPLATES](context: ActionContext<DataflowsState, unknown>): Promise<void> {
            try {
                const response = await getDataflowTemplates();
                context.commit(Mutations.SET_DATAFLOW_TEMPLATES, response.data.data);
            } catch (e: any) {
                throw e;
            }
        },
        async [Actions.REQUEST_DATAFLOW_TEMPLATE_UI_MODEL](
            context: ActionContext<DataflowsState, unknown>,
            dataflowTemplateId: string,
        ): Promise<void> {
            try {
                const response = await getDataflowTemplateUiModel(dataflowTemplateId);
                const { ui_model: uiModel = [] } = response.data?.data;

                context.commit(Mutations.SET_DATAFLOW_TEMPLATE_UI_MODEL, uiModel);
            } catch (e: any) {
                throw e;
            }
        },
    },
    getters: {
        [Getters.GET_DATAFLOWS]: (state: DataflowsState): Dataflow[] => state[State.DATAFLOWS] || [],
        [Getters.GET_DATAFLOW_TEMPLATES]: (state: DataflowsState): DataflowTemplate[] =>
            state[State.DATAFLOW_TEMPLATES] || [],
        [Getters.GET_DATAFLOW_TEMPLATE_UI_MODEL]: (state: DataflowsState): DataflowUISectionResponse[] =>
            state[State.DATAFLOW_TEMPLATE_UI_MODEL] || [],
    },
};

export default store;
