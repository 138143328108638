import HTTP from '@/http';

export default {};

export function cohortSearchEntry(cohort_id: string, cohort_entry_type: string, entry: string) {
    return HTTP.post('/cepdew/segment/api/v3/segments/search_entry_in_cohort', {
        cohort_id,
        cohort_entry_type,
        entry,
    });
}
