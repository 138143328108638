<template>
    <AppOverviewBlock
        :items="groupedOwnersByType"
        :popupTitle="popupTitle"
        :title="title"
        class="mt-4"
        @routerIconClick="navigateToConsumer"
    />
</template>

<script>
// components
import AppOverviewBlock from '@/components/partials/AppOverviewBlock.vue';
// helpers
import {
    EntityType,
    EntityTypeLabel,
    EntityTypePermission,
    EntityTypeRouteName,
} from '@/components/entityTracker/entityTracker';
import { groupBy } from 'lodash';
import { ICON_TYPES, ICON_COLORS } from '@/common/iconHelper';
import { mapActions, mapGetters } from 'vuex';
import Actions, { Getters } from '@/store/mutation-types';
import { Modules } from '@/store/store';
import { isUserAllowed } from '@/services/permissions/permissions.service';

export default {
    name: 'EntityTracker',
    components: { AppOverviewBlock },
    props: {
        entityType: { type: String, default: null },
        popupTitle: { type: String, default: null },
        entityId: { type: String, default: null },
    },
    data() {
        return {
            ICON_TYPES,
            ICON_COLORS,
            EntityTypeRouteName,
        };
    },
    computed: {
        ...mapGetters(Modules.events, [Getters.GET_ENTITY_TRACKER_DATA]),
        allOwnersOfEntities() {
            return this[Getters.GET_ENTITY_TRACKER_DATA][this.mappedEntityType];
        },
        // temporary hack made in order to remove fallbacks on BE side. Will be removed in CEP-4714
        mappedEntityType() {
            return this.entityType === EntityType.Segment || this.entityType === EntityType.StaticFilter
                ? EntityType.SegmentConfig
                : this.entityType;
        },
        title() {
            return `${EntityTypeLabel[this.mappedEntityType]} usage`;
        },
        groupedOwnersByType() {
            if (!this.allOwnersOfEntities || !this.entityId || !this.mappedEntityType) {
                return [];
            }

            const owners = Object.values(this.allOwnersOfEntities[this.entityId] || {});

            return Object.entries(groupBy(owners, owner => owner.entity_type)).map(([entityType, singleTypeOwners]) => {
                const shouldShowNavigateIcon = Array.isArray(EntityTypePermission[entityType])
                    ? isUserAllowed(...EntityTypePermission[entityType])
                    : isUserAllowed(EntityTypePermission[entityType]);

                return {
                    isCollapsed: false,
                    name: EntityTypeLabel[entityType],
                    label: null,
                    rows: singleTypeOwners.map(owner => ({
                        name: owner.name,
                        ...(shouldShowNavigateIcon && { navigateToId: owner.id }),
                    })),
                    entityType,
                };
            });
        },
    },
    created() {
        this[Actions.LOAD_ENTITY_TRACKER_DATA](this.mappedEntityType);
    },
    methods: {
        ...mapActions(Modules.events, [Actions.LOAD_ENTITY_TRACKER_DATA]),
        navigateToConsumer({ entityType, id }) {
            this.$router.push({
                name: EntityTypeRouteName[entityType],
                params: { id, companyId: this.$route.params.companyId },
            });
        },
    },
};
</script>

<style scoped lang="scss"></style>
