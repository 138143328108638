
import Vue from 'vue';

// vuex
import { mapGetters, mapActions } from 'vuex';
import Actions, { Getters } from '@/store/mutation-types';
import { Modules } from '@/store/store';

// components
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import AbstractListPageWrapper from '@/components/layout/AbstractListPageWrapper.vue';
import AppTable from '@/components/partials/AppTable.vue';
import FilterTable from '@/components/partials/FilterTable.vue';
import FilterTableMixin from '@/components/partials/FilterTableMixin.vue';
import TableFiltersTags from '@/components/filters/TableFiltersTags.vue';
import IconButton from '@/components/partials/IconButton.vue';
import DataflowsConfigDialog from '@/__new__/features/dataflows/DataflowsConfigDialog.vue';
import DataflowsSidebar from '@/__new__/features/dataflows/DataflowsSidebar.vue';

// http
import { getJobStaticExportById } from '@/__new__/services/dno/pipelines/http/pipelines';

// models
import Dataflow from '@/__new__/services/dno/dataflows/models/Dataflow';
import DataflowJob from '@/__new__/services/dno/dataflows/models/DataflowJob';

// helpers
import { ICON_TYPES } from '@/common/iconHelper';
import RouteNames from '@/router/routeNames';
import { isUserAllowed } from '@/services/permissions/permissions.service';
import tableColumnType, { type TableSortFilter, type TableColumn } from '@/common/filterTable';
import type { Tab } from '@/__new__/features/resources/BulkUploadTabs.vue';
import { PLURALIZATION } from '@/common/locale/labelSingularOrPlural';

export default Vue.extend({
    name: 'Dataflows',
    components: {
        AbstractListPageWrapper,
        AppButton,
        AppTable,
        FilterTable,
        TableFiltersTags,
        IconButton,
        DataflowsConfigDialog,
        DataflowsSidebar,
    },
    mixins: [FilterTableMixin],

    data() {
        return {
            BUTTON_TYPES,
            ICON_TYPES,
            PLURALIZATION,
            entityType: 'dataflows',
            selectedEntityId: '',
            tableSearchQuery: '',
            defaultSort: {
                key: 'name',
                type: 'asc',
            } as TableSortFilter,
            isCreateNewEnabled: isUserAllowed('DataflowWrite'),
            isTableActionEnabled: isUserAllowed('DataflowWrite'),
            isDataLoading: false,
            isOverviewEnabled: false,
            // export
            exportTabSelected: false as DataflowJob['id'] | boolean,
            exportTabs: [] as Tab[],
            exportConfigs: new Map() as Map<DataflowJob['id'], string>,
            exportConfig: '',
        };
    },

    computed: {
        ...mapGetters(Modules.dataflows, {
            entities: Getters.GET_DATAFLOWS,
            templates: Getters.GET_DATAFLOW_TEMPLATES,
        }),
        tableColumnsData(): TableColumn[] {
            return [
                {
                    name: this.$t('generic.id'),
                    key: 'id',
                    field: 'id',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$t('generic.name'),
                    key: 'name',
                    field: 'name',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$tc('orchestrationEngine.templates.template', PLURALIZATION.PLURAL),
                    key: 'templateNames',
                    field: 'templates',
                    mapper: e => e.templates,
                    filterType: tableColumnType.TEXT_LIMITED_OPTIONS_CONTAINS,
                    limitedOptions: Array.from(new Set(this.entities.map(e => e.templates).flat(1))),
                },
            ];
        },
        filteredEntities(): Dataflow[] {
            return this.filteredEntitiesMixin(this.entities) as Dataflow[];
        },
        selectedEntity(): Dataflow | undefined {
            return this.filteredEntities.find(({ id }) => id === this.selectedEntityId);
        },
    },

    watch: {
        exportTabSelected(tabId: DataflowJob['id']) {
            this.updateExportConfig(tabId);
        },
    },

    created() {
        this.fetchData();
    },

    methods: {
        ...mapActions(Modules.dataflows, [Actions.FETCH_DATAFLOWS, Actions.REQUEST_DATAFLOW_TEMPLATES]),
        goToEditPage(): void {
            this.$router.push({
                name: RouteNames.DATAFLOWS_EDITOR,
                params: {
                    companyId: this.$route.params?.companyId,
                },
            });
        },
        onSelectEntity(id: Dataflow['id']): void {
            this.selectedEntityId = id;
            this.isOverviewEnabled = true;
        },
        onExportDialogOpen(entity: Dataflow): void {
            this.selectedEntityId = entity.id;
            this.exportTabs = entity.jobs.map(({ id }) => ({
                id,
                label: id,
            }));
            this.exportTabSelected = this.exportTabs[0].id as string;
        },
        onExportDialogInput(tabId?: DataflowJob['id']): void {
            if (tabId) {
                Promise.all(this.selectedEntity?.jobs.map(this.getJobAsYaml) || []);
            }
        },
        updateExportConfig(tabId: DataflowJob['id']) {
            this.exportConfig = this.exportConfigs.get(tabId) || '';
        },
        fetchData(): Promise<void> {
            return this.$withProgressBar(
                async () => {
                    this.isDataLoading = true;

                    await this[Actions.REQUEST_DATAFLOW_TEMPLATES]();
                    await this[Actions.FETCH_DATAFLOWS]();
                    this.isDataLoading = false;
                },
                {
                    errorHandler: () => {
                        this.isDataLoading = false;
                        this.$t('alertMessage.somethingWentWrong');
                    },
                },
            );
        },
        getJobAsYaml(job: DataflowJob): Promise<void> {
            return this.$withProgressBar(
                async () => {
                    if (this.exportConfigs.has(job.id)) {
                        return;
                    }

                    const { data: exportConfig } = await getJobStaticExportById(job.app, job.job);
                    this.exportConfigs.set(job.id, exportConfig?.data?.toString());

                    if (job.id === this.exportTabSelected) {
                        this.updateExportConfig(job.id);
                    }
                },
                {
                    errorHandler: () => {
                        this.exportConfigs.set(job.id, this.$t('generic.error'));
                        this.$alert(this.$t('alertMessage.somethingWentWrong'));
                    },
                },
            );
        },
    },
});
