
import Vue from 'vue';
// components
import AppLoader from '@/components/partials/AppLoader.vue';
import AppIcon from '@/components/partials/icon/AppIcon.vue';

// helpers
import MonacoLoader from '@monaco-editor/loader';
import type { editor } from 'monaco-editor/esm/vs/editor/editor.api';
import { ICON_COLORS, ICON_TYPES } from '@/common/iconHelper';

export default Vue.extend({
    name: 'AppYAML',
    components: {
        AppLoader,
        AppIcon,
    },
    props: {
        value: {
            type: String,
            default: '',
        },
        userGuideUrl: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            ICON_TYPES,
            editor: null as editor.IStandaloneCodeEditor | null,
            options: {
                language: 'javascript',
                automaticLayout: true,
                fontSize: 14,
                padding: { top: 5, bottom: 5 },
                domReadOnly: true,
                readOnly: true,
                scrollBeyondLastLine: false,
                showDeprecated: false,
                value: this.value,
            },
            userGuideIcon: {
                type: ICON_TYPES.DOCUMENT,
                color: ICON_COLORS.GRAY,
                size: '1.25rem',
                class: 'mr-1',
            },
        };
    },
    watch: {
        value(val) {
            this.editor?.setValue(val);
        },
    },
    created() {
        this.loadYamlEditor();
    },
    beforeDestroy() {
        this.editor?.dispose();
    },
    methods: {
        async loadYamlEditor(): Promise<void> {
            try {
                const monaco = await MonacoLoader.init();
                this.editor = monaco.editor.create(this.$refs.yamlEditor as HTMLElement, this.options);
            } catch (e) {
                // continue on error as MonacoLoader may fail.
                // -- As error is handled it will trigger re-render
                console.warn(e); // eslint-disable-line no-console
            }
        },
    },
});
