import { actionTypesOptionsMapper } from '@/__new__/services/dno/charging/common/chargingActionsHelper';

export default class ActionFlag {
    type;

    name;

    flagValue;

    constructor({ action_type: type = null, flag_name: name = null, flag_value: flagValue = false } = {}) {
        this.type = actionTypesOptionsMapper[type];
        this.name = name;
        this.flagValue = flagValue;
    }
}
