<template>
    <div>
        <div class="section-content">
            {{ $i18n.t('productCatalog.ratingGroups.ratingGroup') }}
        </div>
        <TagsMultiselect
            v-model="ratingGroupData.data"
            :options="options"
            :placeholder="$i18n.t('productCatalog.ratingGroups.chooseRatingGroups')"
            :labelFormatter="rgTagLabelFormatter"
            :error="ratingGroupData.dataEmpty"
            :errorMessage="$i18n.t('generic.validations.fieldIsRequired')"
            label="name"
            trackBy="id"
            data-test-id="rating-group-condition-multiselect"
            @input="onDataInput"
        />
    </div>
</template>

<script>
// vuex
import { mapActions, mapGetters } from 'vuex';
import Actions, { Getters } from '@/store/mutation-types';
import { Modules } from '@/store/store';

// components
import TagsMultiselect from '@/components/partials/inputs/TagsMultiselect.vue';

// helpers
import { rgTagLabelFormatter } from '@/__new__/services/dno/charging/common/ratingGroupHelper';
import RatingGroupCondition from '@/__new__/services/dno/charging/models/chargingSpecificationConditions/RatingGroupCondition';

export default {
    name: 'RatingGroup',
    components: {
        TagsMultiselect,
    },
    props: {
        value: {
            type: Object,
            default: () => undefined,
        },
    },
    data() {
        return {
            options: [],
            showError: true,
            ratingGroupData: new RatingGroupCondition(this.value),
        };
    },
    computed: {
        ...mapGetters(Modules.chargingV2, [Getters.GET_APPROVED_RATING_GROUPS]),
        getOptions() {
            return this[Getters.GET_APPROVED_RATING_GROUPS].map(r => ({
                id: r.id,
                name: r.name,
                number: r.number,
            }));
        },
    },
    watch: {
        value: {
            handler() {
                this.ratingGroupData = new RatingGroupCondition(this.value);
            },
        },
    },
    created() {
        this.$withLoadingSpinner(
            async () => {
                await this.fetchApprovedRatingGroups();
            },
            {
                errorHandler: () => {
                    this.$eventBus.$emit('showAlert', {
                        message: this.$i18n.t('productCatalog.ratingGroups.failedToLoadRatingGroups'),
                    });
                },
            },
        );
    },
    methods: {
        ...mapActions(Modules.chargingV2, [Actions.REQUEST_RATING_GROUPS]),
        async fetchApprovedRatingGroups() {
            await this[Actions.REQUEST_RATING_GROUPS]();
            this.options = this.getOptions;

            this.ratingGroupData.data = this.options.filter(option =>
                this.ratingGroupData.data.includes(option.number),
            );

            this.$emit('input', this.ratingGroupData);
        },
        onDataInput() {
            this.ratingGroupData.validate();
            this.$emit('input', this.ratingGroupData);
        },
        rgTagLabelFormatter,
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/_palette.scss';

.section-content {
    font-size: 0.75rem;
    font-weight: 600;
    margin-bottom: 1rem;
    margin-right: 1rem;
    color: $gray90;
}
</style>
