<script>
import Button from '@/common/button/Button';
import {
    getAffectedEntities,
    getAffectedByPolicyCounter,
    entityTypeToEntityLabel,
} from '@/common/entities/entityHelper';
import { ALERT_TYPES } from '@/common/alerts/Alert';
import ENTITY_TYPES from '@/common/entities/entityTypes';
import { deleteEntity } from '@/__new__/services/dno/pc/entityState';
import { updateRatingGroup } from '@/__new__/services/dno/charging/http/ratingGroups';
import { updatePolicyCounters } from '@/__new__/services/dno/charging/http/policyCounters';
import { updateChargingSpecifications } from '@/__new__/services/dno/charging/http/chargingSpecifications';
import { updateChargingSpecificationsGroups } from '@/__new__/services/dno/charging/http/chargingSpecificationsGroups';

// Vuex
import Actions from '@/store/mutation-types';

import { updateVoucherSet } from '@/modules/rewards/http/vouchers';
import { updateCategory } from '@/__new__/services/dno/pc/http/categories';
import { updateUsageCounter } from '@/__new__/services/dno/charging/http/usageCounters';
import { updateConditionParameter } from '@/__new__/services/dno/charging/http/conditionParameters';
import { updatePolicyRules } from '@/__new__/services/dno/charging/http/policyRules';
import { updateWalletCounter } from '@/__new__/services/dno/charging/http/walletCounters';
import { updateContentType } from '@/__new__/services/dno/charging/http/contentType';
import { updateTariffSpec } from '@/__new__/services/dno/charging/http/tariffSpecification';
import { updateTariffSpecGroup } from '@/__new__/services/dno/charging/http/tariffSpecificationGroups';

export default {
    name: 'MutationDialogMixin',

    data() {
        return {
            alertButtons: {},
            showModal: false,
            showType: '',
        };
    },

    created() {
        this.alertButtons.details = new Button({
            label: this.$i18n.t('generic.seeDetails'),
            alertType: ALERT_TYPES.warning,
        });
        this.alertButtons.confirmButton = new Button({
            label: this.$i18n.t('generic.saveChanges'),
            alertType: ALERT_TYPES.warning,
        });
        this.alertButtons.deleteButton = new Button({
            label: this.$i18n.t('generic.confirm'),
            alertType: ALERT_TYPES.warning,
        });
    },
    methods: {
        // this alert represents the alert which allows you do delete or showing the affected entities
        showDeleteAlert() {
            if (this.entityType === ENTITY_TYPES.POLICY_COUNTER) {
                this.affectedEntities = getAffectedByPolicyCounter(this.selectedEntity.id);
            } else {
                this.affectedEntities = getAffectedEntities(this.selectedEntity.id, this.entityType);
            }

            if (this.hasAffectedEntities(this.affectedEntities)) {
                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('alerts.impactedEntity'),
                    type: ALERT_TYPES.warning,
                    buttons: [this.alertButtons.details],
                });
                this.$eventBus.$once('buttonClicked', buttonId => {
                    if (buttonId === this.alertButtons.details.id) {
                        this.showModal = true;
                    }
                });
            } else {
                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('alerts.areYouSure'),
                    type: ALERT_TYPES.warning,
                    buttons: [this.alertButtons.deleteButton],
                });
                this.$eventBus.$once('buttonClicked', buttonId => {
                    if (buttonId === this.alertButtons.deleteButton.id) {
                        this.deleteEntity();
                    }
                });
            }
        },
        async saveAfterConfirm(blockRedirect) {
            try {
                this.$Progress.start();
                switch (this.entityType) {
                    // Charging
                    case ENTITY_TYPES.CHARGING_SPECIFICATION:
                        await updateChargingSpecifications(this.$route.params.id, this.version, this.data);
                        break;
                    case ENTITY_TYPES.CHARGING_SPECIFICATIONS_GROUP:
                        await updateChargingSpecificationsGroups(this.$route.params.id, this.version, this.data);
                        break;
                    case ENTITY_TYPES.POLICY_COUNTER:
                        await updatePolicyCounters(this.$route.params.id, this.version, this.data);
                        break;
                    case ENTITY_TYPES.POLICY_RULE:
                        await updatePolicyRules(this.$route.params.id, this.version, this.data);
                        break;
                    case ENTITY_TYPES.RATING_GROUP:
                        await updateRatingGroup(this.$route.params.id, this.version, this.data);
                        break;
                    case ENTITY_TYPES.USAGE_COUNTER:
                        await updateUsageCounter(this.$route.params.id, this.version, this.data);
                        break;
                    case ENTITY_TYPES.CONDITION_PARAMETERS:
                        await updateConditionParameter(this.$route.params.id, this.version, this.data);
                        break;
                    case ENTITY_TYPES.WALLET_COUNTERS:
                        await updateWalletCounter(this.$route.params.id, this.version, this.data);
                        break;
                    case ENTITY_TYPES.CONTENT_TYPES:
                        await updateContentType(this.$route.params.id, this.version, this.data);
                        break;
                    case ENTITY_TYPES.TARIFF_SPECIFICATION:
                    case ENTITY_TYPES.TARIFF_SPECIFICATION_ZERO_RATE:
                        await updateTariffSpec(this.$route.params.id, this.version, this.data);
                        break;
                    case ENTITY_TYPES.TARIFF_SPECIFICATION_GROUP:
                        await updateTariffSpecGroup(this.$route.params.id, this.version, this.data);
                        break;

                    // Rewards
                    case ENTITY_TYPES.VOUCHER_SET:
                        await updateVoucherSet(this.$route.params.id, this.version, this.data);
                        break;

                    // PC
                    case ENTITY_TYPES.CATEGORY:
                        await updateCategory(this.$route.params.id, this.version, this.data);
                        break;
                    default:
                        break;
                }

                this.$Progress.finish();
                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('alertMessage.successActionMessage', {
                        entityName: 'entity',
                        action: 'updated',
                    }),
                    type: ALERT_TYPES.success,
                });
                this.entityEditorMixin.successfullySaved = true;

                if (!blockRedirect) {
                    setTimeout(() => this.$router.go(-1), 1000);
                }
            } catch (error) {
                this.isSaveButtonClicked = false;
                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('alertMessage.errorDoingSmthTryAgain', {
                        action: 'saving',
                        entityName: 'entity',
                    }),
                });
            }
        },
        hasAffectedEntities(entities) {
            return Object.values(entities).some(e => e.length);
        },
        async deleteEntity() {
            try {
                this.$Progress.start();

                await deleteEntity(this.entityType, this.selectedEntity.id, this.selectedEntity.version);
                await Promise.all([
                    this.$store.dispatch(`chargingV2/${Actions.REQUEST_POLICY_COUNTERS}`),
                    this.$store.dispatch(`chargingV2/${Actions.REQUEST_RATING_GROUPS}`),
                ]);
                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('alertMessage.successMessageWithoutRedirect', {
                        entityName: entityTypeToEntityLabel[this.entityType],
                        action: 'deleted',
                    }),
                    type: ALERT_TYPES.success,
                });
                this.showModal = false;
                this.$Progress.finish();
            } catch (e) {
                this.$Progress.fail();
                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('alertMessage.errorDoingSmthTryAgain', {
                        action: 'deleting',
                        entityName: entityTypeToEntityLabel[this.entityType],
                    }),
                });
            }
        },
        showMutationDialog(type) {
            this.showType = type;
        },
        closeUsedByModal() {
            this.showType = '';
        },
    },
};
</script>
