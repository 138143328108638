<template>
    <div>
        <div class="label-wrapper">
            <div
                v-if="additionalLabel"
                :class="{ small: small }"
                class="label d-flex align-items-center"
            >
                <span class="label-text">
                    {{ additionalLabel }}
                </span>

                <AppTooltip
                    v-if="explanationText"
                    :offset="tooltipOffset"
                    :tooltipPosition="tooltipPosition"
                    class="ml-2"
                >
                    <template slot="label">
                        <div class="info-icon" />
                    </template>

                    <template slot="content">
                        <div class="tooltip-info">{{ explanationText }}</div>
                    </template>
                </AppTooltip>
                <slot name="additionalLabelSlot" />
            </div>
            <span
                v-if="optional"
                class="input-optional"
            >
                {{ $i18n.t('generic.optional') }}
            </span>
        </div>
        <Multiselect
            data-test-id="multiselect-appmultiselectv3"
            v-bind="customProps"
            :value="valueFormatted"
            :class="{
                error,
                small,
                disabled,
                verified,
                'border-none': borderNone,
                'blue-arrow': blueArrow,
                'transparent-background': transparentBg,
                'single-label': isCustomLabel,
            }"
            :tagPlaceholder="tagPlaceholder"
            :placeholder="finalPlaceholder"
            :style="widthStyleObject"
            @select="
                (selectedOption, id) => [
                    emitEvent('input', selectedOption, id),
                    emitEvent('select', selectedOption, id),
                ]
            "
            @search-change="(searchQuery, id) => emitEvent('search-change', searchQuery, id)"
            @tag="(searchQuery, id) => emitEvent('tag', searchQuery, id)"
            @open="emitEvent('open', $event)"
            @close="(value, id) => emitEvent('close', value, id)"
            @remove="removeItem"
        >
            <template
                v-for="key in slotKeys"
                :slot="key"
            >
                <slot :name="key" />
            </template>

            <template
                v-for="key in scopedSlotKeys"
                :slot="key"
                slot-scope="{ option, tag, search, remove, toggle }"
            >
                <slot
                    :name="key"
                    :option="option"
                    :tag="tag"
                    :search="search"
                    :remove="remove"
                    :toggle="toggle"
                />
            </template>
        </Multiselect>
        <div
            v-if="error && errorMessage"
            class="error-message"
        >
            {{ errorMessage }}
        </div>
    </div>
</template>

<script>
// COMPONENTS
import AppTooltip from '@/components/partials/AppTooltip.vue';
import { TOOLTIP_POSITION } from '@/common/tooltip';
import Multiselect from 'vue-multiselect';

// HELPERS
import { pickBy, size, toUpper } from 'lodash';

export default {
    name: 'AppMultiselectV3',
    components: {
        Multiselect,
        AppTooltip,
    },
    props: {
        small: {
            type: Boolean,
            default: false,
        },
        additionalLabel: {
            type: String,
            default: '',
        },
        error: {
            type: Boolean,
            default: false,
        },
        errorMessage: {
            type: String,
            default: '',
        },
        optional: {
            type: Boolean,
            default: false,
        },
        options: {
            type: Array,
            required: true,
        },
        borderNone: {
            type: Boolean,
            default: false,
        },
        transparentBg: {
            type: Boolean,
            default: false,
        },
        tooltipOffset: {
            type: Number,
            default: 50,
        },
        blueArrow: {
            type: Boolean,
            default: false,
        },
        optionId: {
            type: String,
            default: null,
        },
        searchable: {
            type: Boolean,
            default: true,
        },
        clearOnSelect: {
            type: Boolean,
            default: true,
        },
        closeOnSelect: {
            type: Boolean,
            default: true,
        },
        internalSearch: {
            type: Boolean,
            default: true,
        },
        showNoResults: {
            type: Boolean,
            default: true,
        },
        showPointer: {
            type: Boolean,
            default: true,
        },
        showLabels: {
            type: Boolean,
            default: false,
        },
        allowEmpty: {
            type: Boolean,
            default: true,
        },
        isCustomLabel: {
            type: Boolean,
            default: false,
        },
        id: {
            type: [Number, String],
            default: null,
        },
        value: {
            type: [Array, Object, String, Number],
            default: null,
        },
        multiple: {
            type: Boolean,
            default: false,
        },
        trackBy: {
            type: String,
            default: null,
        },
        label: {
            type: String,
            default: null,
        },
        placeholder: {
            type: String,
            default: null,
        },
        resetAfter: {
            type: Boolean,
            default: false,
        },
        customLabel: {
            type: Function,
            default: null,
        },
        taggable: {
            type: Boolean,
            default: false,
        },
        tagPlaceholder: {
            type: String,
            default: '',
        },
        tagPosition: {
            type: String,
            default: null,
        },
        max: {
            type: Number,
            default: null,
        },
        optionsLimit: {
            type: Number,
            default: null,
        },
        groupValues: {
            type: String,
            default: null,
        },
        groupLabel: {
            type: String,
            default: null,
        },
        groupSelect: {
            type: Boolean,
            default: false,
        },
        blockKeys: {
            type: Array,
            default: null,
        },
        preserveSearch: {
            type: Boolean,
            default: false,
        },
        preselectFirst: {
            type: Boolean,
            default: false,
        },
        name: {
            type: String,
            default: null,
        },
        selectLabel: {
            type: String,
            default: null,
        },
        selectGroupLabel: {
            type: String,
            default: null,
        },
        selectedLabel: {
            type: String,
            default: null,
        },
        deselectLabel: {
            type: String,
            default: null,
        },
        deselectGroupLabel: {
            type: String,
            default: null,
        },
        limit: {
            type: Number,
            default: null,
        },
        limitText: {
            type: Function,
            default: null,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        maxHeight: {
            type: Number,
            default: null,
        },
        openDirection: {
            type: String,
            default: null,
        },
        tabIndex: {
            type: Number,
            default: null,
        },
        optionHeight: {
            type: Number,
            default: null,
        },
        placeHolderChosenOption: {
            type: Boolean,
            default: false,
        },
        widthStyleObject: {
            type: Object,
            default: () => undefined,
        },
        verified: {
            type: Boolean,
            default: false,
        },
        explanationText: {
            type: String,
            required: false,
            default: '',
        },
        tooltipPosition: {
            type: String,
            required: false,
            default: TOOLTIP_POSITION.top,
        },
    },
    computed: {
        customProps() {
            return pickBy(this.$props, p => typeof p !== 'undefined' && p !== null);
        },
        slotKeys() {
            return Object.keys(this.$slots);
        },
        scopedSlotKeys() {
            return Object.keys(this.$scopedSlots);
        },
        valueFormatted() {
            if (Array.isArray(this.value)) {
                return this.optionId && (this.value || this.value === 0)
                    ? this.options.filter(option => this.value.includes(option[this.optionId]))
                    : this.value;
            }

            return this.optionId && (this.value || this.value === 0)
                ? this.options.filter(option => this.value === option[this.optionId])
                : this.value;
        },
        finalPlaceholder() {
            if (this.loading) {
                return this.$i18n.t('components.appmultiselect.placeholders.loading');
            }
            if (this.placeholder != null) {
                return this.placeholder;
            }
            if (size(this.valueFormatted) < 1) {
                return this.$i18n.t('components.appmultiselect.placeholders.empty');
            }
            if (this.placeHolderChosenOption) {
                return toUpper(this.$i18n.t(this.valueFormatted.i18nLabel));
            }
            return this.$i18n.t('components.appmultiselect.placeholders.chooseOption');
        },
    },
    methods: {
        emitEvent(emitName, arg1, arg2) {
            // TODO: temporary decision. Need to fix multiple bug in vue-multiselect in the future
            const value = this.multiple && !this.valueFormatted ? [] : this.valueFormatted;
            let options = this.multiple && emitName === 'input' ? value.concat(arg1) : arg1;
            if (this.optionId && arg1) {
                if (Array.isArray(options)) {
                    options = options.map(option => (typeof option === 'object' ? option[this.optionId] : option));
                } else {
                    options = typeof arg1 === 'object' ? arg1[this.optionId] : arg1;
                }
            }
            this.$emit(emitName, options, arg2);
        },
        removeItem(item) {
            if (Array.isArray(this.valueFormatted)) {
                // IF WE HAVE ARRAY OF OBJECTS WE NEED TO COMPARE ID, IN OTHERS CASE PRIMITIVES
                let options = this.valueFormatted.filter(val => (this.id ? val.id !== item.id : val !== item));
                if (this.optionId) {
                    options = options.map(option => option[this.optionId]);
                }
                if (!this.multiple && !options.length) {
                    this.$emit('input', null);
                } else {
                    this.$emit('input', options);
                }
                this.$emit('remove', item);
            } else if (this.valueFormatted === item) {
                this.$emit('input', null);
                this.$emit('remove', item);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/_palette.scss';
@import '~@/assets/scss/_consistency';
@import '~@/assets/scss/_palette.scss';
@import '~@/assets/scss/_layout.scss';

.label {
    display: flex;
    align-items: center;

    // TODO: Replace with typography font class
    font-size: 14px;
    font-weight: 600;
    color: $gray90;
    line-height: 26px;
    margin-bottom: 4px;

    &.small {
        font-size: 0.75rem;
    }
}

.label-text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.label-wrapper {
    display: flex;
    justify-content: space-between;
}

.input-optional {
    font-weight: 600;
    font-size: 0.75rem;
    font-style: italic;
    align-items: center;
    color: $gray60;
}

.border-none {
    ::v-deep .multiselect__tags {
        border: none !important;
    }
}

.transparent-background {
    ::v-deep .multiselect__single {
        background-color: transparent !important;
    }

    ::v-deep .multiselect__tags {
        background-color: transparent !important;
    }

    ::v-deep .multiselect__input {
        background-color: transparent !important;
    }
}

.transparent-background.multiselect--disabled {
    background-color: transparent !important;
}

.blue-arrow {
    ::v-deep .multiselect__select {
        &:before {
            border-color: $gray-blue transparent transparent !important;
        }
    }
}

.error-message {
    padding-top: $spacing-xs;
    font-size: 0.75rem;
    color: $red;
}

::v-deep .multiselect__content-wrapper {
    margin-top: 0.25rem;
    border-radius: 0.25rem;
    border: none;
    box-shadow: 0 0.25rem 0.5rem 0 rgba(51, 81, 149, 0.3);
}

::v-deep .multiselect {
    min-height: 32px;
}

::v-deep .multiselect__tags {
    min-height: 32px;
}

.tooltip-info {
    white-space: pre;
    font-size: 0.75rem;
    font-weight: normal;
    text-transform: none;
    line-height: normal;
    color: $gray90;
    min-width: 10rem;
}

.verified {
    &:after {
        content: '';
        position: absolute;
        top: $spacing-xs;
        right: $spacing-xs;
        width: 1rem;
        height: 1rem;
        background: url('../../../assets/icons/input-verified.svg');
    }

    ::v-deep .multiselect__single {
        background-color: transparent !important;
    }
}
::v-deep .multiselect__option--highlight::after {
    font-weight: bold;
    line-height: 2rem;
    font-family: 'Open Sans', serif;
    background-color: transparent;
    color: $blue-400;
}
</style>
