import httpClient, { DnoResponseBase, Entity, EntityState } from '@/http/index';
import { AxiosPromise } from 'axios';
import { PricingRule, SimplePricingRule } from '@/__new__/services/dno/pricing/models/pricingDno';

export interface PricingRulesResponse extends DnoResponseBase {
    pricing_rule_by_id: Record<string, Entity<PricingRule>>;
}

export const createPricingRule = (data: PricingRule | SimplePricingRule): AxiosPromise<PricingRulesResponse> => {
    return httpClient({
        method: 'POST',
        url: '/v3pricing/createpricingrule',
        data: {
            data,
        },
    });
};

export const getPricingRules = (): AxiosPromise<PricingRulesResponse> => {
    return httpClient({
        method: 'GET',
        url: '/v3pricing/getpricingrules',
    });
};

export const getPricingRuleById = (id: string): AxiosPromise<PricingRulesResponse> => {
    return httpClient({
        method: 'GET',
        url: '/v3pricing/getpricingrule',
        params: {
            id,
        },
    });
};

export const updatePricingRule = (
    id: string,
    version: number,
    data: PricingRule | SimplePricingRule,
): AxiosPromise<PricingRulesResponse> => {
    return httpClient({
        method: 'PUT',
        url: '/v3pricing/updatepricingrule',
        params: {
            id,
        },
        data: {
            data,
            version,
        },
    });
};

export const updatePricingRuleState = (id: string, version: number, state: EntityState) => {
    return httpClient({
        method: 'PATCH',
        url: '/v3pricing/updatepricingrulestate',
        data: {
            id,
            version,
            state,
        },
    });
};
