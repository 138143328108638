<template>
    <div class="d-flex justify-content-between">
        <div class="d-flex">
            <AppMultiselectV3
                v-model="flagData.data.inverted"
                :allowEmpty="false"
                :options="Object.values(options)"
                :searchable="false"
                :showLabels="false"
                :borderNone="true"
                :blueArrow="true"
                class="multiselect small condition-options"
                label="label"
                trackBy="id"
            />
            <AppInputV3
                v-model="flagData.data.value"
                :placeholder="$i18n.t('generic.flag')"
                :invalid="flagData.dataEmpty"
                :errorMessage="$i18n.t('generic.validations.fieldIsRequired')"
                data-test-id="flag-condition-input"
                @input="onDataInput"
            />
        </div>
    </div>
</template>

<script>
// Components
import AppInputV3 from '@/components/partials/inputs/AppInputV3.vue';
import AppMultiselectV3 from '@/components/partials/inputs/AppMultiselectV3.vue';

// Helpers
import FlagCondition from '@/__new__/services/dno/charging/models/chargingSpecificationConditions/FlagCondition';
import { csConditionZoneOptions } from '@/__new__/services/dno/charging/common/zone/zones';

export default {
    name: 'Flag',
    components: {
        AppInputV3,
        AppMultiselectV3,
    },
    props: {
        value: {
            type: Object,
            default: () => undefined,
        },
    },
    data() {
        return {
            options: csConditionZoneOptions,
            flagData: new FlagCondition(this.value),
        };
    },
    watch: {
        value: {
            handler() {
                this.flagData = new FlagCondition(this.value);
            },
        },
    },
    methods: {
        onDataInput() {
            this.flagData.validate();
            this.$emit('input', this.flagData);
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/_palette.scss';

.condition-options {
    min-width: 80px;
    width: max-content;
}
</style>
