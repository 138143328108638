<template>
    <SinkConfigDashboard
        :entityType="ENTITY_TYPES.REDSHIFT_CONFIG"
        :pageTitle="$i18n.t('analytics.tableConfig')"
        :entities="redshiftConfigsFormatted"
        :entitiesApiResponse="redshiftConfigsApiResponse"
        :isDataLoading="isDataLoading"
        :editRouteName="RouteNames.REDSHIFT_CONFIG_EDITOR"
        :uiParams="redshiftUIParams"
        :writePermission="'TableConfigurationsWrite'"
        :updateTimeFormatterFn="secondsToDate"
        @fetchConfigs="fetchConfigs"
        @deleteConfig="config => confirmConfigDeletion(config, Actions.DELETE_REDSHIFT_CONFIG)"
    />
</template>

<script>
// components
import ENTITY_TYPES from '@/common/entities/entityTypes';
import SinkConfigDashboard from '@/__new__/features/sinkConfigs/SinkConfigDashboard.vue';
// helpers
import Actions, { Getters } from '@/store/mutation-types';
import { mapActions, mapGetters } from 'vuex';
import { Modules } from '@/store/store';
import RouteNames from '@/router/routeNames';
import { mapRedshiftConfigActions } from '@/__new__/services/dno/sinkConfigs/models/SinkConfigStatus';
import SinkConfigEditorMixin from '@/__new__/features/sinkConfigs/common/SinkConfigEditorMixin';
import { secondsToDate } from '@/common/formatting';
import * as Sentry from '@sentry/vue';

export default {
    name: 'RedshiftConfigDashboard',
    components: { SinkConfigDashboard },
    mixins: [SinkConfigEditorMixin],

    data() {
        return {
            secondsToDate,
            Actions,
            ENTITY_TYPES,
            RouteNames,
            isDataLoading: false,
        };
    },
    computed: {
        ...mapGetters(Modules.sinkConfigs, {
            redshiftConfigs: Getters.GET_REDSHIFT_CONFIGS,
            redshiftConfigsApiResponse: Getters.GET_REDSHIFT_CONFIGS_API_RESPONSE,
            redshiftUIParams: Getters.GET_REDSHIFT_CONFIGS_SETTINGS,
        }),
        ...mapGetters(Modules.segments, {
            segments: Getters.CACHED_SEGMENTS,
        }),

        redshiftConfigsFormatted() {
            if (this.redshiftConfigs.length) {
                return this.redshiftConfigs.map(rc => ({
                    ...rc,
                    allowedActionsExternal: mapRedshiftConfigActions(rc),
                }));
            }
            return [];
        },
    },

    created() {
        this.isDataLoading = true;
        this.$Progress.start();
        const mainPromises = [
            this.fetchEvents(),
            this[Actions.LOAD_REDSHIFT_CONFIGS_SETTINGS](),
            this[Actions.LOAD_REDSHIFT_FORMATTERS](),
            this[Actions.LOAD_REDSHIFT_REQUIRED_PROPERTIES](),
            this.fetchTriggers(),
        ];

        if (!this.segments.length) {
            mainPromises.push(this.fetchSegments());
        }

        Promise.all(mainPromises)
            .then(() => {
                this.fetchConfigs().then(() => {
                    this.$Progress.finish();
                    this.isDataLoading = false;
                });
            })
            .catch(error => {
                this.$alert(this.$i18n.t('events.alerts.failedToLoadConfigs'));
                this.$Progress.fail();
                Sentry.captureException(error);
                this.isDataLoading = false;
            });
    },
    methods: {
        ...mapActions(Modules.sinkConfigs, [
            Actions.LOAD_REDSHIFT_CONFIGS,
            Actions.LOAD_REDSHIFT_CONFIGS_SETTINGS,
            Actions.LOAD_REDSHIFT_FORMATTERS,
            Actions.LOAD_REDSHIFT_REQUIRED_PROPERTIES,
            Actions.DELETE_REDSHIFT_CONFIG,
        ]),
        fetchConfigs() {
            return this[Actions.LOAD_REDSHIFT_CONFIGS]();
        },
    },
};
</script>
