<template>
    <div class="topup-container">
        <div
            v-if="showTitle"
            class="topup-title"
        >
            {{ $i18n.t('customerCare.transactionHistory.topUp') }}
        </div>
        <div class="topup-text">
            <AppInputV3
                v-model="topUpAmount"
                :placeholder="WALLET_TYPES_PLACEHOLDER.get(selectedWalletType)"
                :label="$i18n.t('customerCare.transactionHistory.topUpAmount')"
                :type="'number'"
                :min="0"
                :step="0.01"
                :roundNumber="true"
                :invalid="isTopUpAmountValid"
                :errorMessage="
                    $i18n.t('rewards.walletHelper.walletTopUpMaxAmountError', { amount: maxWalletAmountAfterTopUp })
                "
                data-test-id="input-wallet-balance-amount"
                class="col-12 w-100 mb-3"
            />
            <div class="d-flex align-items-end col-12 mb-5">
                <AppInputV3
                    v-model="voucherCode"
                    :label="$i18n.t('customerCare.transactionHistory.voucherCode')"
                    :optional="true"
                    class="w-100"
                />
            </div>
            <div
                v-if="showButtons"
                class="topup-buttons"
            >
                <AppButton
                    :label="$i18n.t('generic.cancel')"
                    data-test-id="btn-close"
                    @click="close"
                />
                <AppButton
                    :buttonType="BUTTON_TYPES.PRIMARY"
                    :label="$i18n.t('generic.update')"
                    :disabled="disableUpdateButton"
                    class="ml-5"
                    data-test-id="btn-top-up-amount"
                    @click="updateTopUpAmount"
                />
            </div>
        </div>
    </div>
</template>
<script>
// Helpers
import { ALERT_TYPES } from '@/common/alerts/Alert';
import topUpHTTP from '@/http/topUp';
import { uuidV4 } from '@/common/utils';
import * as Sentry from '@sentry/vue';
import { getOperatorMaxWalletAmountAfterTopUp } from '@/services/permissions/permissions.service';
import { WALLET_TYPES, WALLET_TYPES_PLACEHOLDER } from '@/__new__/features/customerCare/common/ordersHelper';
import { USER_MANAGER_HIERARCHY as userManagerHierarchyType } from '@/__new__/features/customerCare/common/customerCareHelper';

// Components
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import AppInputV3 from '@/components/partials/inputs/AppInputV3.vue';

export default {
    name: 'TopupForm',
    components: {
        AppButton,
        AppInputV3,
    },
    props: {
        userManagerHierarchy: {
            required: true,
            type: Number,
        },
        selectedWalletType: {
            required: true,
            type: String,
        },
        placeholder: {
            default: '',
            type: String,
        },
        currentWalletBalanceAmount: {
            required: true,
            type: Number,
        },
        showTitle: {
            default: true,
            type: Boolean,
        },
        showButtons: {
            default: true,
            type: Boolean,
        },
    },
    data() {
        return {
            topUpAmount: null,
            voucherCode: '',
            WALLET_TYPES,
            WALLET_TYPES_PLACEHOLDER,
            maxWalletAmountAfterTopUp: getOperatorMaxWalletAmountAfterTopUp(),
            BUTTON_TYPES,
        };
    },
    computed: {
        disableUpdateButton() {
            return !this.topUpAmount || this.isTopUpAmountValid;
        },
        isTopUpAmountValid() {
            // There will not be validation of max amount what user can submit if there is
            // not maxWalletAmountAfterTopUp set. BE only has validation for A1 operator.
            return this.maxWalletAmountAfterTopUp
                ? this.topUpAmount + this.currentWalletBalanceAmount > this.maxWalletAmountAfterTopUp
                : false;
        },
    },
    methods: {
        uuidV4,
        close() {
            this.topUpAmount = null;
            this.voucherCode = '';
            this.$emit('close');
        },
        async updateTopUpAmount() {
            try {
                if (!this.voucherCode) {
                    this.voucherCode = uuidV4();
                }

                const { id } = this.$route.params;

                this.$Progress.start();

                if (this.userManagerHierarchy === userManagerHierarchyType.SUBSCRIBER) {
                    await topUpHTTP.topUpAmount(
                        this.topUpAmount.toString(),
                        id,
                        this.userManagerHierarchy,
                        this.voucherCode,
                        this.selectedWalletType,
                    );
                } else {
                    await topUpHTTP.topUpAmountSharedWallet(
                        this.topUpAmount.toString(),
                        id,
                        this.userManagerHierarchy,
                        this.voucherCode,
                        this.selectedWalletType,
                    );
                }

                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('messages.walletUpdated'),
                    type: ALERT_TYPES.success,
                });
                this.$Progress.finish();
                this.$emit('refreshData');
            } catch (e) {
                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('messages.updatingWalletFailed'),
                });
                Sentry.captureException(e);
                this.$Progress.fail();
            } finally {
                this.close();
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/_palette';

.topup-container {
    padding: 6% 10% 4% 10%;
}

.topup-title {
    font-size: 1.375rem;
    font-weight: 600;
    color: $gray90;
    margin-bottom: 4%;
}

.topup-text {
    font-size: 0.875rem;
    color: $gray90;
}

.topup-buttons {
    display: flex;
    justify-content: flex-end;
}
</style>
