import { DnoResponseBase } from '@/http/index';
import type { SharedLocationResponse } from '@/__new__/services/dno/dataflows/models/SharedLocation';

export type DataflowTemplate = {
    id: string;
    name: string;
    description: string;
};

export interface DataflowTemplatesApiResponse extends DnoResponseBase {
    data: DataflowTemplate[];
}

export interface DataflowValidationApiResponse extends DnoResponseBase {
    data: {
        title: string;
        actions: string[];
    };
}

export interface DataflowExportResponse extends DnoResponseBase {
    data: {
        entities: Array<{
            app_name: string;
            yaml: string;
        }>;
    };
}

export interface GetSharedLocationsResponse extends DnoResponseBase {
    data: SharedLocationResponse[];
}

// UI Elements
export interface DataflowTemplateUiModelApiResponse extends DnoResponseBase {
    data: DataflowTemplateUiModelResponse;
    data_format: string;
}

export enum SECTION_TYPE {
    SELECTION_INPUT = 'SelectionInput',
    SECTION = 'Section',
    TEXT_INPUT = 'TextInput',
    NUMBER_INPUT = 'NumberInput',
}

export type DataflowBaseData = {
    id: string;
    required: boolean;
    label: string;
    description?: string;
};

export interface DataflowNumberInputResponse extends DataflowBaseData {
    type: SECTION_TYPE.NUMBER_INPUT;
    value: number;
    step: number;
    min?: number;
    max?: number;
}

export type ElementRegex = {
    expression: string;
    message: string;
};

export interface DataflowTextInputResponse extends DataflowBaseData {
    type: SECTION_TYPE.TEXT_INPUT;
    value: string;
    regex?: ElementRegex;
}

export type ElementOption = {
    label: string;
    value: string;
};

export interface DataflowSelectResponse extends DataflowBaseData {
    type: SECTION_TYPE.SELECTION_INPUT;
    value?: ElementOption;
    values?: ElementOption[];
}

export interface DataflowUISectionResponse extends DataflowBaseData {
    type?: SECTION_TYPE.SECTION;
    elements?: Array<DataflowUISectionResponse | DataflowElementResponse>;
}

export type DataflowElementResponse = DataflowNumberInputResponse | DataflowTextInputResponse | DataflowSelectResponse;

export type DataflowTemplateUiModelResponse = {
    name: string;
    ui_model: DataflowUISectionResponse[];
};
