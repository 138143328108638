<template>
    <AppDialogV2
        :title="$i18n.t('authentication.newExternalUserToRole')"
        :visible="visible"
        @close="onCloseModal()"
        @submit="onSave()"
    >
        <div class="form-content">
            <AppInputV3
                v-model="mapping.externalUserId"
                :placeholder="$i18n.t('authentication.externalUserId')"
                :label="$i18n.t('authentication.externalUserId')"
                :invalid="$v.mapping.externalUserId.$error"
            />
            <AppMultiselectV3
                v-model="mapping.selectedRole"
                :options="roles"
                label="name"
                :error="$v.mapping.selectedRole.$error"
                :errorMessage="$i18n.t('authentication.youMustSelectRole')"
                trackBy="id"
                :additionalLabel="$i18n.t('generic.role')"
                :small="true"
                class="mt-3"
            />
        </div>
    </AppDialogV2>
</template>

<script>
// Vue components
import AppDialogV2 from '@/components/partials/AppDialogV2.vue';
import AppInputV3 from '@/components/partials/inputs/AppInputV3.vue';
import AppMultiselectV3 from '@/components/partials/inputs/AppMultiselectV3.vue';
import { ALERT_TYPES } from '@/common/alerts/Alert';

// Validation
import { required } from 'vuelidate/lib/validators';

// Helpers & Misc
import * as Sentry from '@sentry/vue';
import { clone } from 'lodash';
import supportButtonMixin from '@/components/alerts/supportButtonMixin';

// HTTP
import { addMapping, updateMapping } from '@/__new__/services/portal/auth/http/oauth';

// VUEX
import { mapGetters } from 'vuex';
import { Getters } from '@/store/mutation-types';

export default {
    name: 'ExternalUserMappingModal',

    components: {
        AppDialogV2,
        AppInputV3,
        AppMultiselectV3,
    },

    mixins: [supportButtonMixin],

    /**
     * key and value props are reserved so adding
     * config in front of them
     */
    props: {
        visible: {
            required: true,
            type: Boolean,
        },
        mappingToEdit: {
            type: Object,
            default: null,
        },
    },

    data() {
        return {
            mapping: {
                externalUserId: null,
                selectedRole: null,
            },
        };
    },

    validations: {
        mapping: {
            externalUserId: {
                required,
            },
            selectedRole: {
                required,
            },
        },
    },

    computed: {
        ...mapGetters('roles', [Getters.GET_ROLES]),
        roles() {
            return this[Getters.GET_ROLES];
        },
    },

    created() {
        this.initData();
    },

    methods: {
        initData() {
            const { mappingToEdit } = this;
            if (mappingToEdit) {
                const selectedRole = this.roles.find(r => r.id === mappingToEdit.roleId);
                this.mapping = {
                    ...clone(mappingToEdit),
                    selectedRole: clone(selectedRole),
                };
            }
        },

        onCloseModal() {
            this.$emit('close');
        },

        async onSave() {
            // Validate model
            this.validate();

            if (!this.$v.$invalid) {
                try {
                    this.$Progress.start();

                    if (this.mappingToEdit) {
                        const { externalUserId } = this.mapping;
                        const roleId = this.mapping.selectedRole.id;
                        const { id } = this.mappingToEdit;
                        await updateMapping(id, externalUserId, roleId);

                        this.$eventBus.$emit('showAlert', {
                            message: this.$i18n.t('authentication.updateExternalUserToRoleSuccess'),
                            type: ALERT_TYPES.success,
                        });
                    } else {
                        const { externalUserId } = this.mapping;
                        const roleId = this.mapping.selectedRole.id;

                        await addMapping(externalUserId, roleId);

                        this.$eventBus.$emit('showAlert', {
                            message: this.$i18n.t('authentication.newExternalUserToRoleSuccess'),
                            type: ALERT_TYPES.success,
                        });
                    }

                    setTimeout(() => {
                        this.$emit('update');
                        this.onCloseModal();
                    }, 1000);

                    // Handle response
                    this.$Progress.finish();
                } catch (error) {
                    Sentry.captureException(error);
                    this.$Progress.fail();
                    this.showSupportAlert(this.$i18n.t('alertMessage.reports.errorMessage'), ALERT_TYPES.error);
                } finally {
                    this.$Progress.finish();
                }
            }
        },

        validate() {
            this.$v.$touch();
            if (this.$v.$invalid) {
                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('alertMessage.pleaseFixValidation'),
                });
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.form-content {
    min-height: 23rem;
}
</style>
