<script>
import RouteNames from '@/router/routeNames';
import i18n from '@/i18n';
import store from '@/store/store';
import permissionsService, { isUserAllowed, isUserInternal } from '@/services/permissions/permissions.service';
import { USER_MODES } from '@/common/commonHelper';

const SECTIONS = {
    [RouteNames.TENANTS]: {
        label: i18n.t('settings.tenantPage'),
        key: 'tenants',
        routeName: RouteNames.TENANTS,
        subRoutes: [RouteNames.TENANT_EDITOR],
        mode: USER_MODES.POWER,
        permission: () => isUserInternal() && isUserAllowed('TenantManagement'),
    },
    [RouteNames.OPERATOR_LOCALIZATION]: {
        label: i18n.t('settings.companySettings'),
        key: 'tenantSettings',
        routeName: RouteNames.OPERATOR_LOCALIZATION,
        subRoutes: [],
        mode: USER_MODES.REGULAR,
        permission: () => permissionsService.isUserAdmin() && isUserAllowed('CompanySettings'),
    },
    [RouteNames.USER_SETTINGS]: {
        label: i18n.t('settings.userSettings'),
        key: 'user-settings',
        routeName: RouteNames.USER_SETTINGS,
        subRoutes: [],
        mode: USER_MODES.REGULAR,
        permission: () => isUserAllowed('UserSettings'),
    },
    [RouteNames.REMOTE_CONFIG]: {
        label: i18n.t('settings.remoteConfiguration'),
        key: 'remoteConfig',
        routeName: RouteNames.REMOTE_CONFIG,
        subRoutes: [],
        mode: USER_MODES.REGULAR,
        permission: () => permissionsService.isUserAdmin() && isUserAllowed('RemoteConfiguration'),
    },
    [RouteNames.USER]: {
        label: i18n.t('users.user'),
        key: 'users',
        routeName: RouteNames.USER,
        subRoutes: [RouteNames.USER_EDITOR],
        mode: USER_MODES.REGULAR,
        permission: () => permissionsService.isUserAdmin() && isUserAllowed('UserManagement'),
    },
    [RouteNames.ROLES]: {
        label: i18n.t('rolesAndPermissions.rolesPage'),
        key: 'roles',
        routeName: RouteNames.ROLES,
        subRoutes: [RouteNames.ROLE_EDITOR],
        mode: USER_MODES.REGULAR,
        permission: () => permissionsService.isUserAdmin() && isUserAllowed('RoleManagement'),
    },
    [RouteNames.QUIET_HOURS]: {
        label: i18n.t('settings.quietHours'),
        key: 'quiet',
        routeName: RouteNames.QUIET_HOURS,
        subRoutes: [RouteNames.QUIET_HOURS_EDITOR],
        mode: USER_MODES.REGULAR,
        permission: () => permissionsService.quietHoursEnabled() && isUserAllowed('QuietHours'),
    },
    [RouteNames.CAMPAIGN_CATEGORIES]: {
        label: 'Campaign categories',
        key: 'campaign-categories',
        routeName: RouteNames.CAMPAIGN_CATEGORIES,
        subRoutes: [RouteNames.CAMPAIGN_CATEGORIES_EDITOR],
        mode: USER_MODES.REGULAR,
        permission: () =>
            isUserAllowed(
                'ServiceCampaignsRead',
                'MarketingCampaignsRead',
                'ServiceCampaignsWrite',
                'MarketingCampaignsWrite',
            ),
    },
    [RouteNames.APPLICATION_MANAGER]: {
        label: i18n.t('pipelines.applicationManager.title'),
        key: 'application-manager',
        routeName: RouteNames.APPLICATION_MANAGER,
        subRoutes: [],
        mode: USER_MODES.REGULAR,
        permission: () => isUserInternal() && isUserAllowed('ApplicationManager'),
    },
    [RouteNames.MANUAL_PROXY_REQUEST]: {
        label: i18n.t('settings.manualProxyRequest'),
        key: 'manualProxy',
        routeName: RouteNames.MANUAL_PROXY_REQUEST,
        subRoutes: [],
        mode: USER_MODES.EXPERIMENTAL,
        permission: () => isUserInternal() && isUserAllowed('ManualRequestProxy'),
    },
    [RouteNames.THIRD_PARTY_APPLICATIONS]: {
        label: i18n.t('settings.thirdPartyApplications'),
        key: 'thirdPartyApplications',
        routeName: RouteNames.THIRD_PARTY_APPLICATIONS,
        subRoutes: [],
        mode: USER_MODES.EXPERIMENTAL,
        permission: () => isUserInternal() && isUserAllowed('ThirdPartyApplications'),
    },
};

export default {
    name: 'SettingsSubSidebarMixin',
    data() {
        return {
            SubSidebarDefaultSection: SECTIONS[RouteNames.USER_SETTINGS],
        };
    },
    computed: {
        SubSidebarSections() {
            return Object.fromEntries(Object.entries(SECTIONS).filter(el => el[1]?.mode === this.mode));
        },
        mode() {
            return store.state.currentMode;
        },
    },
};
</script>
