
import Vue from 'vue';

// Vuex
import { mapGetters } from 'vuex';
import { Getters } from '@/store/mutation-types';

// COMPONENTS
import AppHeader from '@/components/layout/AppHeader.vue';
import AppMultiselectV3 from '@/components/partials/inputs/AppMultiselectV3.vue';
import AppLoader from '@/components/partials/AppLoader.vue';
import DateTimePicker from '@/components/partials/inputs/DateTimePicker.vue';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import AppTable from '@/components/partials/AppTable.vue';
import IconButton from '@/components/partials/IconButton.vue';
import { ICON_TYPES } from '@/common/iconHelper';
import AppAditionalSidebar from '@/components/partials/AppAditionalSidebar.vue';
import OverviewHeaderV2 from '@/components/partials/entityOverview/OverviewHeaderV2.vue';
import AppOverviewBlock from '@/components/partials/AppOverviewBlock.vue';
import AppPaginationLoadMore from '@/components/partials/AppPaginationLoadMore.vue';
import ReportsArchiveDialog from '@/__new__/features/reports/ReportsArchiveDialog.vue';
import ReportsErrorDetailsDialog from '@/__new__/features/reports/ReportsErrorDetailsDialog.vue';
import ReportsReplaceDialog from '@/__new__/features/reports/ReportsReplaceDialog.vue';

// MIXINS
import ReportsArchiveDownloadMixin from '@/__new__/features/reports/ReportsArchiveDownloadMixin.vue';
import FilterTableMixin from '@/components/partials/FilterTableMixin.vue';

// MODELS
import Button from '@/common/button/Button';
import Report, { REPORT_ENGINE, REPORT_TABLE_ACTION } from '@/__new__/services/dno/reports/models/Report';

// HELPERS
import * as Sentry from '@sentry/vue';
import ENTITY_TYPES from '@/common/entities/entityTypes';
import {
    SEARCH_OPTIONS,
    REPORT_GENERATION_STATUS,
    REPORT_FREQUENCY_TYPES,
    formatReportDataForOverview,
    getReportTimeframeOptions,
    getDatePickerOptions,
    type BuiltReportRequestParams,
    buildRequestFromReports,
    SEARCH_OPTION,
} from '@/__new__/features/reports/common/reportsStateHelper';
import { orderBy, isEmpty } from 'lodash';
import tableColumnType, { type TableColumn } from '@/common/filterTable';
import { required } from 'vuelidate/lib/validators';
import { isUserInternal } from '@/services/permissions/permissions.service';
import moment from 'moment';
import type {
    ReportDefinitionResponse,
    ReportResponse,
    GeneratedReportResponse,
} from '@/__new__/services/dno/reports/common/reportsHelper';

// HTTP
import {
    fetchReports,
    fetchReport,
    generateReport,
    publishReport,
    previewReport,
} from '@/__new__/services/dno/reports/http/reports';
import { fetchReportsDefinitionsOrd } from '@/__new__/services/dno/reports/http/ordReports';
import { fetchReportsDefinitionsQrd } from '@/__new__/services/dno/reports/http/qrdReports';

export default Vue.extend({
    name: 'Reports',
    components: {
        AppHeader,
        AppMultiselectV3,
        AppLoader,
        DateTimePicker,
        AppButton,
        AppTable,
        IconButton,
        AppAditionalSidebar,
        OverviewHeaderV2,
        AppOverviewBlock,
        AppPaginationLoadMore,
        ReportsArchiveDialog,
        ReportsErrorDetailsDialog,
        ReportsReplaceDialog,
    },
    mixins: [FilterTableMixin, ReportsArchiveDownloadMixin],
    data() {
        return {
            ENTITY_TYPES,
            SEARCH_OPTIONS,
            REPORT_TABLE_ACTION,
            selectedReport: null as ReportDefinitionResponse | null,
            reportData: null as ReportDefinitionResponse | null,
            allReports: [] as ReportDefinitionResponse[],
            selectedSearchOption: null as (typeof SEARCH_OPTIONS)[keyof typeof SEARCH_OPTIONS] | null,
            datePickerOptions: {} as ReturnType<typeof getDatePickerOptions>,
            selectedReportId: null as Report['definitionId'] | null,
            tableReports: [] as Report[],
            actionLoading: false,
            ICON_TYPES,
            BUTTON_TYPES,
            rebuildLayout: false,
            rebuildLayoutToggle: false,
            reportTimeframeOptions: {},
            reportPollers: {} as Record<Report['definitionId'], ReturnType<typeof setTimeout>>,
            reportsPerPage: 20,
            pagingStateIndex: 0,
            pagingStates: new Map(),
            replaceEntity: undefined as Report | undefined,
            isEmpty,
            isUserInternal,
            isArchiveDialogOpen: false,
            isDataLoading: false,
            isSaveButtonClicked: false,
            isSidebarVisible: false,
            isReplaceDialogOpen: false,
            isErrorDetailsDialogOpen: false,
            reportErrorDetails: null,
            errorDetailsBtn: new Button({
                label: this.$t('generic.details'),
                handler: () => {
                    this.isErrorDetailsDialogOpen = true;
                },
            }),
        };
    },
    validations() {
        return {
            selectedReport: {
                required,
            },
            selectedSearchOption: {
                key: {
                    required,
                },
            },
            datePickerOptions: {
                dateValue: {
                    required,
                },
            },
        };
    },
    computed: {
        ...mapGetters([Getters.GET_IS_COMPACT_SIDEBAR]),
        disableDatePicker(): boolean {
            return isEmpty(this.selectedSearchOption) || this.datePickerOptions.disabled;
        },
        reportInfoSection(): ReturnType<typeof formatReportDataForOverview> {
            if (!this.reportData) {
                return [];
            }
            return formatReportDataForOverview(this.reportData);
        },
        sortedFilteredReports(): ReturnType<typeof FilterTableMixin.filteredEntitiesMixin> {
            return this.filteredEntitiesMixin(this.sortedReports);
        },
        sortedReports(): Report[] {
            return [...this.tableReports].sort((group1, group2) => group2.startTime - group1.startTime);
        },
        tableColumnsData(): TableColumn[] {
            return [
                {
                    name: this.$t('generic.name'),
                    key: 'reportName',
                    field: 'reportName',
                    forbidHideColumn: true,
                    classes: ['data-name'],
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$t('generic.date'),
                    key: 'date',
                    field: 'date',
                    mapper: entity => this.displayDatesForTable(entity as Report),
                    classes: ['data-report-date'],
                    forbidHideColumn: true,
                    filterType: tableColumnType.DATE,
                },
            ];
        },
        setDateTimePickerValue():
            | ReturnType<typeof getDatePickerOptions>['dateValue']
            | Array<ReturnType<typeof getDatePickerOptions>['dateValue' | 'endDateValue']> {
            return this.datePickerOptions.isRangePicker
                ? [this.datePickerOptions.dateValue, this.datePickerOptions.endDateValue]
                : this.datePickerOptions.dateValue;
        },
        buildRequestFromReports(): ReturnType<typeof buildRequestFromReports> {
            return buildRequestFromReports(
                this.selectedSearchOption?.key as SEARCH_OPTION,
                this.selectedReport as ReportDefinitionResponse,
                this.datePickerOptions,
            );
        },
        calcWithForRebuildLayout(): string {
            const isCompactSidebar = this[Getters.GET_IS_COMPACT_SIDEBAR];
            if (this.rebuildLayout) {
                return `calc(100vw - 40rem - 272px ${isCompactSidebar ? ' + 250px' : ''})`;
            }
            if (this.rebuildLayoutToggle) {
                return `calc(100vw - 64px - 272px ${isCompactSidebar ? ' + 250px' : ''})`;
            }
            return '';
        },
        getPageToken(): string | undefined {
            return this.pagingStates.get(this.pagingStateIndex);
        },
        loadedReportsAreLessThanRequested(): boolean {
            return this.sortedFilteredReports.length < this.reportsPerPage;
        },
        disabledTablePagination(): boolean {
            return !this.sortedFilteredReports.length || this.loadedReportsAreLessThanRequested;
        },
        isDownloadAllEnabled(): boolean {
            return this.tableReports.length > 1 && !this.isSelectedReportEngineType(REPORT_ENGINE.QRD);
        },
    },
    mounted() {
        this.fetchAllReportsDefinitions();
    },
    methods: {
        onSelectProperty(timeframeType: SEARCH_OPTION): void {
            this.datePickerOptions = getDatePickerOptions(timeframeType, this.reportData as ReportDefinitionResponse);
            this.tableReports = [];
            this.clearPaginationState();
        },
        onDateSelected(date: Date): void {
            this.tableReports = [];
            this.datePickerOptions.dateValue = date;
        },
        rangeDateSelected(startDate: Date, endDate: Date): void {
            this.tableReports = [];
            this.datePickerOptions.dateValue = startDate;
            this.datePickerOptions.endDateValue = endDate;
            this.clearPaginationState();
        },
        onSelectReport(report: ReportDefinitionResponse): void {
            this.$v.$reset();
            this.reportData = report;
            this.selectedReportId = report.definitionId;
            this.isSidebarVisible = true;
            this.tableReports = [];
            this.clearPaginationState();
            this.selectedSearchOption = null;
            this.datePickerOptions = getDatePickerOptions(null, report);
            this.reportTimeframeOptions = getReportTimeframeOptions(report);
        },
        doesReportHaveTableAction(entity: Report, action: REPORT_TABLE_ACTION): boolean {
            return entity.actions.includes(action);
        },
        getReports(pageToken?: string): void {
            if (this.isSaveButtonClicked) {
                return;
            }

            this.tableReports = [];
            this.isSaveButtonClicked = true;

            this.$v.$touch();
            if (this.$v.$invalid) {
                this.$alert(this.$t('alertMessage.pleaseFixValidation'));
                this.isSaveButtonClicked = false;
            } else {
                this.fetchReports(this.buildRequestFromReports, pageToken);
            }
        },
        isSelectedReportEngineType(type: REPORT_ENGINE): boolean {
            return this.selectedReport?.reportEngine === type;
        },
        fetchAllReportsDefinitions() {
            return this.$withProgressBar(
                async () => {
                    const reports: ReportDefinitionResponse[] = [];
                    const reqs = await Promise.allSettled([fetchReportsDefinitionsQrd(), fetchReportsDefinitionsOrd()]);

                    reqs.forEach(result => {
                        if (result.status === 'fulfilled') {
                            reports.push(...result.value);
                        }
                    });

                    this.allReports = orderBy(reports, ['reportName'], ['asc']).filter(
                        ({ visibleOnPortal }) => visibleOnPortal,
                    );
                },
                {
                    errorHandler: () => this.$alert('generic.somethingWentWrong'),
                },
            );
        },
        async fetchReports(params: BuiltReportRequestParams, pageToken?: string) {
            this.$showInfoAlert({ message: this.$t('analytics.reportsPage.gettingReports') });

            this.$Progress.start();
            this.isDataLoading = true;
            this.actionLoading = false;

            try {
                const res = await fetchReports({
                    ...params,
                    size: this.reportsPerPage,
                    pageToken,
                });

                this.$Progress.finish();
                this.$eventBus.$emit('closeAllAlerts');

                this.tableReports = this.filterPreviewOutOfRange(res.data).map(r => new Report(r));
                if (res.next_page_token) {
                    this.pagingStates.set(this.pagingStateIndex + 1, res.next_page_token);
                }
            } catch (error: any) {
                Sentry.captureException(error);
                this.$Progress.fail();
                this.$eventBus.$emit('closeAllAlerts');
                this.$alert(error?.response?.data?.message || this.$t('alertMessage.reports.gettingReports'));
            } finally {
                this.isDataLoading = false;
                this.isSaveButtonClicked = false;
            }
        },
        toggleReplaceDialog(entity: Report) {
            this.isReplaceDialogOpen = !!entity;
            this.replaceEntity = entity;
        },
        displayDatesForTable({ actions, startTime, endTime }: Report) {
            const { formatDateToUTC, getFormattedDateAndTime } = this.$localeLibrary;

            if (this.selectedReport?.reportFrequency === REPORT_FREQUENCY_TYPES.INTRA_DAY) {
                // Preview reports are formatted in timezone of the report.
                // Applicable only when there is timepresent, otherwise it's at 00:00(midnight).
                const formatDateTime = actions.includes(REPORT_TABLE_ACTION.PREVIEW)
                    ? getFormattedDateAndTime
                    : this.formatDateToUtcDateTime;

                return `${formatDateTime(startTime)} - ${formatDateTime(endTime)}`;
            }

            if (
                this.selectedReport?.reportFrequency === REPORT_FREQUENCY_TYPES.DAILY &&
                this.selectedReport?.reportEngine === REPORT_ENGINE.ORD
            ) {
                return formatDateToUTC(startTime);
            }

            return this.selectedReport?.reportFrequency === REPORT_FREQUENCY_TYPES.MONTHLY ||
                this.selectedSearchOption?.key !== SEARCH_OPTIONS.asof.key
                ? `${formatDateToUTC(startTime)} - ${formatDateToUTC(endTime)}`
                : formatDateToUTC(startTime);
        },
        disabledBeforeTodayAndAfterAWeek(date: Date) {
            const { from, to } = this.datePickerOptions.restrictReportDates;
            return (to && date < to) || date > from;
        },
        // IMPORTANT: Using setTimeout as setInterval has issue with triggering multiple times after being cleared.
        clearReportInterval(definitionId: Report['definitionId']) {
            clearTimeout(this.reportPollers[definitionId]);
            this.$delete(this.reportPollers, definitionId);
            this.$eventBus.$emit('closeAllAlerts');
            this.isSaveButtonClicked = false;
            this.actionLoading = false;
        },
        showErrorDetailsAlert(error: any) {
            const message = error?.response?.data?.message || this.$t('analytics.reportsPage.reportGenerationFail');
            this.reportErrorDetails = error?.response?.data?.details;
            this.$alert(
                message,
                // Details btn visible only for admin users.
                isUserInternal() && {
                    buttons: [this.errorDetailsBtn],
                },
            );
        },
        fetchAction(entity: Report) {
            if (entity?.url) {
                this.reportGenerationSucceeded(entity);
                return;
            }

            this.generateReport(entity);
        },
        async publishAction(entity: Report) {
            this.$showInfoAlert({ message: this.$t('analytics.reportsPage.reportPublish') });

            this.$Progress.start();
            this.actionLoading = true;

            try {
                const res = await publishReport(this.buildRequestFromReports);

                if (res.status === 202) {
                    this.fetchReport(entity);
                } else {
                    this.showErrorDetailsAlert({ response: res });
                }
            } catch (error) {
                Sentry.captureException(error);
                this.$Progress.fail();

                this.clearReportInterval(this.buildRequestFromReports.definitionId);
                this.showErrorDetailsAlert(error);
            }
        },
        generateReport(): Promise<void> {
            this.$showInfoAlert({ message: this.$t('analytics.reportsPage.reportRegenerated') });
            this.actionLoading = true;

            return this.$withProgressBar(
                async () => {
                    const res = await generateReport(this.buildRequestFromReports);

                    if (res.status === 202) {
                        this.fetchReport();
                    } else {
                        this.showErrorDetailsAlert({ response: res });
                    }
                },
                {
                    errorHandler: (error: any) => {
                        this.clearReportInterval(this.buildRequestFromReports.definitionId);
                        this.showErrorDetailsAlert(error);
                    },
                },
            );
        },
        fetchReport(): Promise<void> {
            const { definitionId } = this.buildRequestFromReports;

            return this.$withProgressBar(
                async () => {
                    const res = await fetchReport(this.buildRequestFromReports);

                    if (
                        [REPORT_GENERATION_STATUS.REPORT_GENERATED, REPORT_GENERATION_STATUS.AVAILABLE].includes(
                            res?.status,
                        )
                    ) {
                        this.clearReportInterval(definitionId);
                        this.reportGenerationSucceeded((res as GeneratedReportResponse).report);
                    } else {
                        this.reportPollers[definitionId] = setTimeout(() => this.fetchReport(), 2000);
                    }
                },
                {
                    errorHandler: (error: any) => {
                        this.isDataLoading = false;
                        this.clearReportInterval(definitionId);
                        this.showErrorDetailsAlert(error);
                    },
                },
            );
        },
        reportGenerationSucceeded(report: ReportResponse) {
            const index = this.tableReports.findIndex(({ definitionId }) => definitionId === report.definitionId);

            if (report.url) {
                this.tableReports[index].url = report.url;
                window.location.href = report.url;
                this.actionLoading = true;
                return;
            }

            if (report.actions.includes(REPORT_TABLE_ACTION.FETCH_ARCHIVE)) {
                this.tableReports[index].actions = report.actions;
                this.$Progress.start();
                this.$showInfoAlert({ message: this.$t('analytics.reportsPage.reportSizeToLarge') });
                this.fetchReportArchive(report);
            }
        },
        fetchPreview(): Promise<void> {
            const { definitionId } = this.buildRequestFromReports;

            if (!this.reportPollers[definitionId]) {
                this.$showInfoAlert({ message: this.$t('analytics.reportsPage.reportPreview') });
            }

            return this.$withProgressBar(
                async () => {
                    this.actionLoading = true;
                    const res = await previewReport(this.buildRequestFromReports);
                    if (
                        [REPORT_GENERATION_STATUS.REPORT_GENERATED, REPORT_GENERATION_STATUS.AVAILABLE].includes(
                            res?.data?.data?.status,
                        )
                    ) {
                        this.clearReportInterval(definitionId);
                        this.reportGenerationSucceeded((res.data.data as GeneratedReportResponse).report);
                    } else {
                        this.reportPollers[definitionId] = setTimeout(() => this.fetchPreview(), 2000);
                    }
                },
                {
                    errorHandler: (error: any) => {
                        this.isDataLoading = false;
                        this.clearReportInterval(definitionId);
                        this.showErrorDetailsAlert(error);
                    },
                },
            );
        },
        async fetchReportArchive(report: Report): Promise<void> {
            this.actionLoading = true;
            if (report.url) {
                this.reportGenerationSucceeded(report);
                return;
            }

            await this.generateReportArchive();
            this.isArchiveDialogOpen = true;
            this.actionLoading = true;
        },
        formatDateToUtcDateTime(date: Date | number) {
            const { getDateFormat, timeFormatMap, getTimeFormat } = this.$localeLibrary;
            return moment.tz(date, 'Etc/UTC').format(`${getDateFormat()} ${timeFormatMap.get(getTimeFormat())}`);
        },
        changeStateIndex(next: boolean) {
            this.pagingStateIndex += next ? 1 : -1;
        },
        getNextReports(next = true) {
            this.changeStateIndex(next);
            const token = this.getPageToken;
            this.getReports(token);
        },
        clearPaginationState() {
            this.pagingStateIndex = 0;
            this.pagingStates.clear();
        },
        // Remove after BE implements DATAP-2516
        filterPreviewOutOfRange(reports: ReportResponse[]) {
            if (!this.datePickerOptions.endDateValue) {
                return reports;
            }

            return reports.filter(
                r =>
                    !r.actions.includes(REPORT_TABLE_ACTION.PREVIEW) ||
                    new Date(r.startTime).getDate() <= this.datePickerOptions.endDateValue.getDate(),
            );
        },
    },
});
