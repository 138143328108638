// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import i18n from '@/i18n';
import {
    CDP_SUPPORTED_API_CONTROL_AUTH_TYPES,
    CDP_SUPPORTED_API_CONTROL_EVENT_SOURCE_BY_VALUES,
    EVENT_SOURCE_NAME_VALUES,
    eventStatuses,
} from '@/common/events/eventsHelper';
import { getEventActions } from '@/common/events/eventsActions';

import { isEmpty } from 'lodash';
import { TranslateResult } from 'vue-i18n';
import { EventAPIAuth } from '@/common/events/eventsTypes';
import EventProp, {
    eventPropFromJson,
    getMandatoryProps,
    modifyPropertiesBeforeSend,
} from '@/__new__/services/dno/events/models/EventProp';
import store, { Modules } from '@/store/store';
import { Getters } from '@/store/mutation-types';
import { PRIORITY_LABELS, PRIORITY_LABELS_INVERT } from '@/common/cepHelper';

export type BackendEvent = {
    data: {
        json_schema: {
            humanReadableName: string;
            description: string;
            properties: EventProp[];
            title: string;
            type: 'object';
            required: string[];
        };
        api_control: EventAPIAuth;
        producer_info: {
            producer_products?: string[] | Record<string, never> | ',';
            producer_services?: string[];
        };
        labels: string[] | Record<string, never>;
        priority: number;
    };
    id: string;
    update_time: number;
    version: number;
};
export class Event {
    name: string | TranslateResult;
    type: string | TranslateResult;
    id: string;
    description: string;
    state: string | TranslateResult;
    properties: any[];
    clientApiControl: boolean;
    serverApiControl: boolean;
    unregisteredApiControl: boolean;
    eventType: string;
    producerProducts: string;
    producerServices: string;
    labels: any[];
    updateTime: null;
    apiControl: null;
    allowedActionsExternal: any[];
    version: null | number;
    schemaDefs: any;
    priority: number;

    constructor(
        name = i18n.t('productCatalog.services.periodTypes.event'),
        type = '',
        id = '',
        description = '',
        state = '',
        properties = [],
        clientApiControl = false,
        serverApiControl = false,
        unregisteredApiControl = false,
        eventType = '',
        producerProducts = '',
        producerServices = '',
        labels = [],
        updateTime = null,
        apiControl = null,
        allowedActionsExternal = [],
        version = null,
        schemaDefs = {},
        priority = 2, // high priority by default
    ) {
        this.name = name;
        this.type = type;
        this.id = id;
        this.description = description;
        this.state = state;
        this.properties = properties;
        this.clientApiControl = clientApiControl;
        this.serverApiControl = serverApiControl;
        this.unregisteredApiControl = unregisteredApiControl;
        this.eventType = eventType;
        this.producerProducts = producerProducts;
        this.producerServices = producerServices;
        this.labels = labels;
        this.updateTime = updateTime;
        this.apiControl = apiControl;
        this.allowedActionsExternal = allowedActionsExternal;
        this.version = version;
        this.schemaDefs = schemaDefs;
        this.priority = priority;
    }

    // uses to create event and send data to backend
    toJson(editEventId: string, isInternal: boolean) {
        const dataSources = store.getters[`${Modules.events}/${Getters.GET_EVENT_DATA_SOURCES}`] || [];
        const authData = {
            [CDP_SUPPORTED_API_CONTROL_AUTH_TYPES.CLIENT]: this.clientApiControl,
            [CDP_SUPPORTED_API_CONTROL_AUTH_TYPES.SERVER]: this.serverApiControl,
            [CDP_SUPPORTED_API_CONTROL_AUTH_TYPES.UNREGISTERED]: this.unregisteredApiControl,
        };

        return {
            id: editEventId || undefined,
            name: editEventId ? this.name : undefined,
            version: editEventId ? this.version : undefined,
            data: {
                json_schema: {
                    description: this.description,
                    properties: modifyPropertiesBeforeSend(this.properties, dataSources),
                    title: this.eventType,
                    humanReadableName: this.name,
                    type: 'object',
                    required: getMandatoryProps(this.properties),
                    ...(!isEmpty(this.schemaDefs) && { $defs: this.schemaDefs }),
                },
                labels: this.labels,
                auth: isInternal ? { service: true } : authData,
                priority: Number(PRIORITY_LABELS_INVERT[this.priority]),
            },
        };
    }
}

export function eventToExportFormat(event: any): BackendEvent {
    return {
        data: {
            json_schema: event.data.json_schema,
            labels: event.data.labels,
            api_control: { auth: event.data.auth },
            priority: event.data.priority,
        },
    };
}

export function eventFromJson(event: BackendEvent, dataSources: any = [], LFRequiredPropNames: string[] = []) {
    return new Event(
        event.data.json_schema.humanReadableName,
        event.data.api_control
            ? i18n.t(
                  `events.sources.${
                      EVENT_SOURCE_NAME_VALUES[
                          CDP_SUPPORTED_API_CONTROL_EVENT_SOURCE_BY_VALUES[event.data.api_control.event_source]
                      ]
                  }`,
              )
            : i18n.t('generic.custom'),
        event.data.json_schema.title,
        event.data.json_schema.description,
        eventStatuses.PUBLISHED, // state
        eventPropFromJson(event.data.json_schema, dataSources, LFRequiredPropNames),
        false,
        false,
        false,
        event.data.json_schema.title,
        Array.isArray(event.data.producer_info?.producer_products) ? event.data.producer_info?.producer_products : [],
        event.data.producer_info?.producer_services || [],
        isEmpty(event.data.labels) ? [] : event.data.labels,
        event.update_time,
        event.data.api_control,
        getEventActions(),
        event.version,
        event.data.json_schema['$defs'],
        PRIORITY_LABELS[event.data.priority] || PRIORITY_LABELS[3],
    );
}
export default {
    Event,
};
