//
import trimEnd from 'lodash/trimEnd';

export const ChronoUnit = {
    // momentjs compatible
    hour: 'hours',
    day: 'days',
    minute: 'minutes',
};

export const ChronoUnitLabels = {
    [ChronoUnit.hour]: 'hour',
    [ChronoUnit.day]: 'day',
    [ChronoUnit.minute]: 'minute',
};

const ChronoUnitServerMapping = {
    [ChronoUnit.hour]: 0,
    [ChronoUnit.day]: 1,
    [ChronoUnit.minute]: 2,
};

const ChronoUnitToSeconds = {
    [ChronoUnit.minute]: 60,
    [ChronoUnit.hour]: 60 * 60,
    [ChronoUnit.day]: 60 * 60 * 24,
};

const ChronoUnitFromServerMapping = {
    [ChronoUnitServerMapping[ChronoUnit.hour]]: ChronoUnit.hour,
    [ChronoUnitServerMapping[ChronoUnit.day]]: ChronoUnit.day,
    [ChronoUnitServerMapping[ChronoUnit.minute]]: ChronoUnit.minute,
};

const ChronoUnitFromAnyMapping = {
    ...ChronoUnitFromServerMapping,
    [ChronoUnit.minute]: ChronoUnit.minute,
    [ChronoUnit.hour]: ChronoUnit.hour,
    [ChronoUnit.day]: ChronoUnit.day,
};

export interface CampaignDelay {
    amount: number;
    unit: keyof typeof ChronoUnitFromServerMapping;
}

export default class Delay {
    amount;

    unit;

    constructor(amount: string, unit: keyof typeof ChronoUnit) {
        this.amount = parseInt(amount, 10);
        this.unit = unit;
    }

    toSeconds(): number {
        return this.amount * ChronoUnitToSeconds[this.unit];
    }

    buildJson() {
        return {
            amount: this.amount,
            unit: ChronoUnitServerMapping[this.unit],
        };
    }

    toString(): string {
        return `${this.amount} ${this.amount !== 1 ? this.unit : trimEnd(this.unit, 's')}`;
    }

    static fromJson(json: Record<string, any>): Delay {
        return new Delay(json.amount, ChronoUnitFromAnyMapping[json.unit as keyof typeof ChronoUnitFromAnyMapping]);
    }
}
