import http from '@/http/index';

const readBaseUrl = '/v3catalog/';
const writeBaseUrl = '/v3catalogwrite/';

export function getTemplates(entityType, templatesIds) {
    let url = `${readBaseUrl}gettemplates`;
    return http({
        method: 'POST',
        url: `${url}`,
        data: {
            template_type: entityType,
            // should be empty array in order to get full list of the spicified type
            template_ids: templatesIds || [],
        },
    });
}

export function getAllTemplates(types) {
    const data = {};

    if (types.length) {
        data.types = types;
    }
    let url = `${readBaseUrl}getalltemplates`;

    return http({
        method: 'POST',
        url: `${url}`,
        headers: {
            'Content-Type': 'application/json',
        },
        data,
    });
}

export function addTemplate(entityType, templateData) {
    let url = `${writeBaseUrl}addtemplate`;

    return http({
        method: 'POST',
        url: `${url}`,
        data: {
            template_type: entityType,
            ...templateData,
        },
    });
}

export function updateTemplate(id, entityType, templateData, updateTime) {
    let url = `${writeBaseUrl}updatetemplate`;

    return http({
        method: 'POST',
        url: `${url}`,
        data: {
            template_id: id,
            template_type: entityType,
            ...templateData,
            update_time: updateTime,
        },
    });
}

export function updateTemplateState(templateId, entityType, state, updateTime) {
    let url = `${writeBaseUrl}updatetemplatestate`;

    return http({
        method: 'POST',
        url: `${url}`,
        data: {
            template_id: templateId,
            template_type: entityType,
            state,
            update_time: updateTime,
        },
    });
}

export default {
    addTemplate,
    getTemplates,
    getAllTemplates,
    updateTemplate,
    updateTemplateState,
};
