
// Generic
import Vue from 'vue';

// Vuex
// import { mapGetters, mapActions } from 'vuex';
// import { Modules } from '@/store/store';
// import Actions, { Getters } from '@/store/mutation-types';

// Components
import LayoutPage from '@/__new__/features/customerCareSuite/common/LayoutPage.vue';

// Helpers
// import { USER_MANAGER_HIERARCHY } from '@/__new__/features/customerCare/common/customerCareHelper';
import { LAYOUT_PAGE_KEYS } from '@/__new__/features/customerCareSuite/common/layoutSectionHelper';

export default Vue.extend({
    name: 'BillingAccountPageView',

    components: {
        LayoutPage,
    },

    props: {
        id: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            layoutPageKey: LAYOUT_PAGE_KEYS.BILLING_ACCOUNT,
            selectedTabIndex: 1,
        };
    },

    // computed: {
    //     ...mapGetters(Modules.customerCareSuite, [Getters.GET_ASSOCIATED_UM_ENTITY_BY_TYPE_AND_ID]),
    //     accountData() {
    //         return this[Getters.GET_ASSOCIATED_UM_ENTITY_BY_TYPE_AND_ID]({
    //             targetType: USER_MANAGER_HIERARCHY.USER,
    //             targetId: this.id,
    //         });
    //     },
    // },

    // created() {
    //     this.$withProgressBar(
    //         async () => {
    //             const payload = {
    //                 targetType: USER_MANAGER_HIERARCHY.USER,
    //                 targetId: this.id,
    //             };
    //             await this[Actions.LOAD_ASSOCIATED_UM_ENTITIES](payload);
    //         },
    //         {
    //             errorHandler: () =>
    //                 this.$showErrorAlert({
    //                     message: this.$i18n.t('alertMessage.failedToLoadNecessaryData'),
    //                 }),
    //         },
    //     );
    // },

    // methods: {
    //     ...mapActions(Modules.customerCareSuite, [Actions.LOAD_ASSOCIATED_UM_ENTITIES]),
    // },
    methods: {
        selectTab(index) {
            this.selectedTabIndex = index;
            // if (!this.disabledTabIndexes.includes(index) && !this.relyOnInnerTabIndex) {
            //     this.selectedTabIndex = index;
            // }
            // this.$emit('tabSelected', index);
        },
    },
});
