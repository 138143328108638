/* eslint-disable camelcase */

export default class MSISDNStatistics {
    totalNumber;

    totalOwnedNumber;

    availableNumber;

    reserveNumber;

    assignedOwnedNumber;

    assignedNumberPortIn;

    assignedNumberPortOut;

    coolDownNumber;

    unavailableNumber;

    constructor(counters) {
        this.totalNumber = counters?.total_number || 0;
        this.totalOwnedNumber = counters?.total_owned_number || 0;
        this.availableNumber = counters?.available_number || 0;
        this.reserveNumber = counters?.reserve_number || 0;
        this.assignedOwnedNumber = counters?.assigned_owned_number || 0;
        this.assignedNumberPortIn = counters?.assigned_number_port_in || 0;
        this.assignedNumberPortOut = counters?.assigned_number_port_out || 0;
        this.coolDownNumber = counters?.cool_down_number || 0;
        this.unavailableNumber = counters?.unavailable_number || 0;
    }
}

/* eslint-enable camelcase */
