//
import _isNumber from 'lodash/isNumber';
import numeral from 'numeral';
import moment from 'moment';
import { abbreviate, formatPercents, formatTimeString } from './formatting';

// clearly mutation numeral object to get proper formatting
// please keep it here until we implement proper localization
numeral.localeData().delimiters.thousands = ' ';
numeral.nullFormat('');

/** Add brackets if value is not null. (42%) */
function brackets<T>(value: T | null): string | T | null {
    return value ? `(${value})` : value;
}

/**
 * Function receives value and returns same value with ' ' as a thousands delimiter
 * @param value
 * @returns {String}
 */
export function spacedNumber(value: any): string {
    return _isNumber(value) ? numeral(value).format('0,0') : value;
}

/** ~7K or ... if undefined */
function abbreviateNumber(value: any): string {
    return abbreviate(value);
}

/** Displays content if != null */
function ifDefined(value: any): any {
    return value != null ? value : '';
}

/** Adds 's' ending if count != 1 */
function plural(value: string, count: number): string {
    return `${value}${count !== 1 ? 's' : ''}`;
}

/** Returns number along with plural word if it's not 1. day, 2 days */
function pluralFull(value: string, count: number): string {
    return `${count === 1 ? '' : count} ${plural(value, count)}`;
}

/** Formatting time string/date */
function time(value: number): string {
    return value ? formatTimeString(value) : '';
}

/**
 * Sets local timezone for UTC (+0) date.
 */
function asLocal(date: moment.Moment) {
    return date.subtract(date.utcOffset(), 'm');
}

/** Formatting time string/date */
function timeWithOffsets(value: number) {
    return value && formatTimeString(asLocal(moment(value * 1000)));
}

/** Adds ordinal suffix. 1st, 2nd, 3rd */
function ordinal(value: number) {
    return value && numeral(value).format('0o');
}

/** Returns only ordinal suffix. st, nd, rd */
function ordinalSuffix(value: number) {
    if (!value) return '';
    const ordinalStr = ordinal(value);
    return ordinalStr && ordinalStr.substr(ordinalStr.length - 2);
}

/** Truncates a string. 12345... */
function truncate(value: string, length = 10) {
    return value && value.length > length ? `${value.slice(0, length)}...` : value;
}

/** Formatting currency values to look like 10,000.00 (this is 1ten ) */
function formatNumber(number: number) {
    return numeral(number).format('0,0.00');
}

const filters = {
    formatPercents,
    brackets,
    spacedNumber,
    abbreviateNumber,
    ifDefined,
    plural,
    pluralFull,
    time,
    asLocal,
    timeWithOffsets,
    ordinal,
    ordinalSuffix,
    truncate,
    formatNumber,
};

export default filters;
