import i18n from '@/i18n';
import UM_HIERARCHY from '@/common/userManagerHierarchy';

// types
import { TranslateResult } from 'vue-i18n';

export const HIERARCHY_OPTIONS = [
    {
        key: UM_HIERARCHY.SUBSCRIBER,
        value: i18n.t('generic.subscriber'),
    },
    {
        key: UM_HIERARCHY.ACCOUNT,
        value: i18n.t('generic.account'),
    },
    {
        key: UM_HIERARCHY.ORGANIZATION,
        value: i18n.t('generic.organization'),
    },
    {
        key: UM_HIERARCHY.UNREGISTERED_EMAIL,
        value: i18n.t('generic.email'),
    },
    {
        key: UM_HIERARCHY.UNREGISTERED_MSISDN,
        value: i18n.t('generic.unregisteredMSISDN'),
    },
];

export type HierarchyOption = {
    key: UM_HIERARCHY;
    value: TranslateResult;
};

// Subscription Limit specific

const SUBSCRIPTION_LIMIT_AVAILABLE_UM_HIEARARCHY_OPTIONS = [
    UM_HIERARCHY.SUBSCRIBER,
    UM_HIERARCHY.ACCOUNT,
    UM_HIERARCHY.ORGANIZATION,
];

export const SUBSCRIPTION_LIMIT_HIERARCHY_OPTIONS = HIERARCHY_OPTIONS.filter(option =>
    SUBSCRIPTION_LIMIT_AVAILABLE_UM_HIEARARCHY_OPTIONS.includes(option.key),
);

// Purchase Limit specific

// according to duration time enum
// https://github.com/lotusflare/lua/blob/1531ba4e8bf4ab5ee05bcafcd40d95315a34588c/services_root/libs/util/Time.lua#L89C7-L89C15
export const enum DURATION_ENUM {
    LIFETIME = 0,
    DAY = 1,
    WEEK = 2,
    MONTH = 3,
    MINUTE = 4,
}

export type TimeOption = {
    key: DURATION_ENUM;
    value: TranslateResult;
};

export const TIME_FRAME_OPTIONS_WITHOUT_LIFETIME = [
    {
        key: DURATION_ENUM.DAY,
        value: i18n.t('generic.timeRanges.days'),
    },
    {
        key: DURATION_ENUM.WEEK,
        value: i18n.t('generic.timeRanges.weeks'),
    },
    {
        key: DURATION_ENUM.MONTH,
        value: i18n.t('generic.timeRanges.months'),
    },
];

export const TIME_FRAME_OPTIONS_WITH_LIFETIME = [
    ...TIME_FRAME_OPTIONS_WITHOUT_LIFETIME,
    {
        key: DURATION_ENUM.LIFETIME,
        value: i18n.t('generic.lifetime'),
    },
] as TimeOption[];

const PURCHASE_LIMIT_AVAILABKE_UM_HIERARCHY_OPTIONS = [
    UM_HIERARCHY.SUBSCRIBER,
    UM_HIERARCHY.ACCOUNT,
    UM_HIERARCHY.ORGANIZATION,
    UM_HIERARCHY.UNREGISTERED_EMAIL,
    UM_HIERARCHY.UNREGISTERED_MSISDN,
];

export const PURCHASE_LIMIT_HIERARCHY_OPTIONS = HIERARCHY_OPTIONS.filter(option =>
    PURCHASE_LIMIT_AVAILABKE_UM_HIERARCHY_OPTIONS.includes(option.key),
);

export default {
    HIERARCHY_OPTIONS,
};
