import { AxiosPromise } from 'axios';

import httpClient from './index';
import Locale from '@/models/Locale';

/**
 * GET user localization.
 * @returns {Promise<void>}
 */
export function getUserLocale(): AxiosPromise<Locale> {
    return httpClient({
        method: 'GET',
        url: '/profile/getlocaleuser',
    });
}

/**
 * POST user localization.
 * @returns {Promise<void>}
 * @param data
 */
export function setUserLocale(locale: Locale): AxiosPromise<void> {
    return httpClient({
        method: 'PUT',
        url: '/profile/setlocale',
        data: locale,
    });
}

/**
 * GET operator localization.
 * @returns {Promise<void>}
 */
export function getTenantLocale(): AxiosPromise<Locale> {
    return httpClient({
        method: 'GET',
        url: '/profile/getlocaletenant',
    });
}

/**
 * PUT operator localization.
 * @returns {Promise<void>}
 * @param data
 */
export function setTenantLocale(locale: Locale): AxiosPromise<void> {
    return httpClient({
        method: 'PUT',
        url: '/admin/setlocale',
        data: locale,
    });
}

export default {
    getUserLocale,
    setUserLocale,
    getTenantLocale,
    setTenantLocale,
};
