<template>
    <AbstractEditPageWrapper>
        <template #header>
            <AppHeader :pageTitle="pageTitle" />
        </template>

        <template #content>
            <div class="mx-auto pt-5 filters-editor-page">
                <AppInputV3
                    id="name"
                    v-model="name"
                    data-test-id="user-group-name"
                    :invalid="$v.name.$error"
                    :label="$t('segments.staticSegmentName')"
                    :placeholder="$t('segments.staticSegmentName')"
                    :errorMessage="$t('segments.filters.alerts.segmentNameIsMissing')"
                />

                <AppTextareaV3
                    id="'segmentDescription'"
                    v-model="description"
                    :label="$i18n.t('generic.description')"
                    :placeholder="$i18n.t('generic.addDescription')"
                    data-test-id="segment-description"
                    class="mt-4"
                />

                <AppMultiselectV3
                    id="userFilterType"
                    data-test-id="user-filter-type"
                    :options="entryTypeOptions"
                    :value="selectedEntryType"
                    :placeholder="$t('generic.filter')"
                    :additionalLabel="$t('segments.filterUsersBy')"
                    :error="$v.entryType.$error"
                    :errorMessage="$t('segments.filters.alerts.filterUsersBy')"
                    :disabled="isEditing"
                    small
                    trackBy="id"
                    label="label"
                    class="mt-4"
                    @input="setEntryType($event.id)"
                />

                <AppToggle
                    v-model="produceEventsEnterLeave"
                    :label="$i18n.t('segments.produceEventsEnterLeave')"
                    :small="true"
                    class="mt-4"
                    data-test-id="produceEventsEnterLeaveToggle"
                />

                <CardListRadioInput
                    v-if="entryType === SEGMENT_ID_TYPES.MSISDN"
                    :value="{ id: inputMethod }"
                    :cardsValues="inputMethodOptions"
                    :label="$t('generic.chooseInputMethod')"
                    :disabled="isEditing"
                    class="mt-4"
                    data-test-id="input-method-select"
                    @input="setInputMethod($event.id)"
                />

                <FromToInputs
                    v-if="isRangeInputMethod"
                    v-model="rangeInputValues"
                    data-test-id="range-input"
                    :initInputValues="initRangeInputValues"
                    :label="$t('generic.range')"
                    :addButtonLabel="$t('generic.addRange')"
                    :firstInputPlaceholder="rangesInputsPlaceholder"
                    :secondInputPlaceholder="rangesInputsPlaceholder"
                    :isAllInvalid="!$v.rangeInputValues.requiredRange && $v.rangeInputValues.$anyDirty"
                    showInputErrors
                    class="mt-3"
                />

                <section
                    v-else
                    class="mt-3 pb-5"
                >
                    <StaticSegmentEntriesList
                        v-show="addEntryView === ADD_ENTRY_VIEW.NONE"
                        :entries="entries"
                        :segment="editable"
                        :entriesPerPage="entriesPerPage"
                        @removeEntry="updateFilterEntries([], [$event])"
                        @entriesLoaded="entries = $event"
                    />

                    <StaticSegmentAddEntry
                        :entries="entries"
                        :addEntryView="addEntryView"
                        :entryType="selectedEntryType ? selectedEntryType.label : ''"
                        data-test-id="static-segment-add-entry"
                        @toggle="addEntryView = $event"
                        @addEntry="updateFilterEntries([$event])"
                        @removeEntry="updateFilterEntries([], [$event])"
                    />

                    <!-- ToDo: Move file uploader to StaticFilterAddEntry -->
                    <DragDropFileUploader
                        v-show="addEntryView === ADD_ENTRY_VIEW.FILE"
                        acceptType=".csv"
                        data-test-id="drag-drop-file-uploader-fe"
                        :configFileUploader="configFileUploader"
                        :triggerUploadFiles="triggerUploadFiles"
                        :triggerClearPollers="triggerClearPollers"
                        @updateUploadStatus="isUploadFilesInProgress = $event"
                        @updateAmountFilesUpload="isFilesUploadAmount = !!$event"
                        @error="displayFailedLines"
                    />
                </section>
            </div>
        </template>

        <template #controls>
            <AppButton
                data-test-id="cancel-filter-btn"
                :buttonType="BUTTON_TYPES.SECONDARY"
                :label="$i18n.t('generic.back')"
                class="mr-5"
                @click="onCancel"
            />
            <AppButton
                data-test-id="save-filter-btn"
                :buttonType="BUTTON_TYPES.PRIMARY"
                :iconType="ICON_TYPES.CHECK"
                :disabled="isUploadButtonDisabled"
                :label="$t(`generic.${isUploadFromFileInputMethod ? 'saveAndUpload' : 'save'}`)"
                @click="onSave"
            />
        </template>
    </AbstractEditPageWrapper>
</template>

<script>
// store
import { Modules } from '@/store/store';
import Actions from '@/store/mutation-types';
// components
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import { ICON_TYPES } from '@/common/iconHelper';
import AbstractEditPageWrapper from '@/components/layout/AbstractEditPageWrapper.vue';
import AppHeader from '@/components/layout/AppHeader.vue';
import AppInputV3 from '@/components/partials/inputs/AppInputV3.vue';
import AppMultiselectV3 from '@/components/partials/inputs/AppMultiselectV3.vue';
import FromToInputs from '@/components/partials/inputs/FromToInputs.vue';
import DragDropFileUploader from '@/components/partials/fileUploader/DragDropFileUploader.vue';
import StaticSegmentEntriesList from '@/__new__/features/segments/static/StaticSegmentEntriesList.vue';
import StaticSegmentAddEntry from '@/__new__/features/segments/static/StaticSegmentAddEntry.vue';
import CardListRadioInput from '@/components/partials/cards/CardListRadioInput.vue';
import AppTextareaV3 from '@/components/partials/inputs/AppTextareaV3.vue';
// Helpers
import { differenceWith, fromPairs, intersection, isEqual, without } from 'lodash';
import { required } from 'vuelidate/lib/validators';
import RouteNames from '@/router/routeNames';
import entityEditorMixin from '@/common/entityEditorMixin';
import Button from '@/common/button/Button';
import {
    SEGMENT_ID_TYPES,
    StaticFilterLabels,
    STATIC_SEGMENT_INPUT_METHODS,
    ADD_ENTRY_VIEW,
} from '@/common/StaticFilter';
import {
    getSignedURL,
    getRegisteredEntities,
    getEntityDetailsByName,
    getEntityDetailsByCategory,
    streamStaticFilterEntries,
} from '@/__new__/services/dno/segments/http/staticFilter';
import permissionsService, { isViewEnabled } from '@/services/permissions/permissions.service';
import ENTITY_TYPES from '@/common/entities/entityTypes';
import { entityTypeToEntityLabel } from '@/common/entities/entityHelper';
import { GENERATION_TYPES_BY_KEYS } from '@/common/segments';
import Segment from '@/__new__/services/dno/segments/models/Segment';
import { createSegment, updateSegment } from '@/__new__/services/dno/segments/http/segments';
import { mapActions } from 'vuex';
import AppToggle from '@/components/partials/inputs/AppToggle.vue';

export default {
    name: 'StaticSegmentEditor',

    components: {
        AppToggle,
        AppButton,
        AppHeader,
        AbstractEditPageWrapper,
        AppInputV3,
        AppTextareaV3,
        AppMultiselectV3,
        FromToInputs,
        DragDropFileUploader,
        StaticSegmentEntriesList,
        StaticSegmentAddEntry,
        CardListRadioInput,
    },

    mixins: [entityEditorMixin],

    data() {
        return {
            entityType: ENTITY_TYPES.STATIC_FILTER,
            ICON_TYPES,
            BUTTON_TYPES,
            ADD_ENTRY_VIEW,
            SEGMENT_ID_TYPES,
            inputMethodOptions: [
                {
                    label: this.$t('generic.inputMethods.identifiers'),
                    id: STATIC_SEGMENT_INPUT_METHODS.IDS,
                },
                {
                    label: this.$t('generic.inputMethods.ranges'),
                    id: STATIC_SEGMENT_INPUT_METHODS.RANGES,
                },
            ],
            segmentId: null,
            name: '',
            produceEventsEnterLeave: false,
            description: '',
            entryType: null,
            inputMethod: STATIC_SEGMENT_INPUT_METHODS.IDS,
            entries: [],
            bufferAddEntries: [],
            bufferDeleteEntries: [],
            rangeInputValues: null,
            initRangeInputValues: [],
            failedLinesErrorMessage: [],
            triggerUploadFiles: false,
            triggerClearPollers: false,
            isUploadFilesInProgress: false,
            isFilesUploadAmount: false,
            addEntryView: ADD_ENTRY_VIEW.SINGLE,
            editable: null,
            entriesPerPage: 250,
            configFileUploader: {
                getSignedURL,
                getEntityDetailsByCategory,
                getEntityDetailsByName,
                getRegisteredEntities,
                getSignedURLParams: params => ({
                    uuid: params.uuid,
                    staticFilterId: params.staticFilterId,
                    fileName: params.fileName,
                    entryType: String(this.entryType),
                }),
                customData: {
                    staticFilterId: null,
                },
                successfulUploadClb: this.handleSuccessfulUpload,
                partiallySuccessfulUploadClb() {
                    this.$alert(this.$t('partials.fileUploader.invalidFileTypeText'));
                },
                failedUploadClb() {
                    this.$alert(this.$t('partials.fileUploader.failedUpload'));
                },
            },
        };
    },

    validations: {
        name: {
            required,
        },
        entryType: {
            required,
        },
        rangeInputValues: {
            requiredRange(value) {
                return !this.isRangeInputMethod || !!value?.data?.length;
            },
        },
    },

    computed: {
        rangesInputsPlaceholder() {
            return `${this.$t('generic.enter')} ${this.selectedEntryType?.label}`;
        },
        entryTypeOptions() {
            const viewConfigEnabledTypes = Object.keys(SEGMENT_ID_TYPES)
                .filter(type => isViewEnabled(`StaticSegmentsType${type}`))
                .map(type => SEGMENT_ID_TYPES[type]);
            const finalTypes = intersection(permissionsService.availableStaticSegmentTypes(), viewConfigEnabledTypes);
            return finalTypes.map(id => ({ id, label: StaticFilterLabels[id] }));
        },
        isRangeInputMethod() {
            return this.inputMethod === STATIC_SEGMENT_INPUT_METHODS.RANGES;
        },
        isManuallyInputMethod() {
            return this.inputMethod === STATIC_SEGMENT_INPUT_METHODS.IDS && this.addEntryView !== ADD_ENTRY_VIEW.FILE;
        },
        isUploadFromFileInputMethod() {
            return this.inputMethod === STATIC_SEGMENT_INPUT_METHODS.IDS && this.addEntryView === ADD_ENTRY_VIEW.FILE;
        },
        selectedEntryType() {
            return this.entryTypeOptions.find(({ id }) => id === this.entryType);
        },
        isUploadButtonDisabled() {
            return (
                this.isUploadFilesInProgress ||
                (this.$Progress.get() > 0 && this.$Progress.get() < 100) ||
                (this.isUploadFromFileInputMethod &&
                    (!this.isFilesUploadAmount || this.entityEditorMixin.successfullySaved))
            );
        },
        segmentConfig() {
            return new Segment(
                this.segmentId,
                this.name,
                Date.now(),
                null,
                this.description,
                this.entryType,
                GENERATION_TYPES_BY_KEYS.STATIC_FILTER,
                null,
                null,
                null,
                null,
                {
                    ...fromPairs(this.inputMethodOptions.map(({ id }) => [id, id === this.inputMethod])),
                    upload_from_file: false, // BE don't accept object without this value
                },
                this.rangeInputValues?.data,
            );
        },
    },

    created() {
        this.entryType = this.entryTypeOptions[0]?.id || null;
        if (this.isEditing) {
            this.initializeEditFilterPage();
        }
    },

    methods: {
        ...mapActions(Modules.segments, [Actions.FETCH_SEGMENT]),
        initializeEditFilterPage() {
            this.$withLoadingSpinner(
                () => this[Actions.FETCH_SEGMENT](this.$route.params.id).then(this.populateSegment),
                {
                    errorHandler: () => {
                        this.$alert(this.$t('segments.filters.alerts.failedToLoadSegment'));
                        this.navigateToListPage({ timeout: 2000 });
                    },
                },
            );
        },
        populateSegment(editable) {
            this.editable = { ...editable };

            this.segmentId = editable.id;
            this.name = editable.name;
            this.description = editable.description || '';
            this.entryType = editable.segmentIdType;
            this.produceEventsEnterLeave = editable.produceEventsEnterLeave;

            streamStaticFilterEntries(editable.id, this.entriesPerPage).then(({ data }) => {
                if (editable.inputMethods?.range) {
                    this.setInputMethod(STATIC_SEGMENT_INPUT_METHODS.RANGES);
                    this.initRangeInputValues = (data || []).flat().map(JSON.parse);
                } else {
                    this.entries = [...(data || []).flat()];
                    this.addEntryView = ADD_ENTRY_VIEW.NONE;
                }
            });
        },
        onCancel() {
            if (this.isUploadFromFileInputMethod && this.isUploadFilesInProgress) {
                const t = this.$t('segments.filters.uploadInProgress');
                this.$alert(t.heading, {
                    description: t.message.join('\n'),
                    type: this.$ALERT_TYPES.warning,
                    buttons: [
                        new Button({
                            label: t.actionLabel,
                            handler: () => this.navigateToListPage({ timeout: 0 }),
                        }),
                    ],
                });
            } else {
                this.showDontLeaveAlert(() => {
                    this.triggerClearPollers = !this.triggerClearPollers;
                    this.navigateToListPage({ timeout: 0 });
                });
            }
        },
        async onSave() {
            this.$v.$touch();

            if (this.$v.$invalid) {
                switch (true) {
                    case !this.$v.rangeInputValues.requiredRange:
                        this.$alert(this.$t('segments.filters.alerts.rangeRequired'));
                        break;
                    case this.$v.$anyError:
                        this.$alert(this.$t('alertMessage.pleaseFixValidation'));
                        break;
                    default:
                }
                return;
            }

            this.$Progress.start();

            try {
                await (this.isEditing ? this.updateFilter : this.createFilter)();
                if (!this.isUploadFromFileInputMethod) this.handleSuccessfulUpload();
            } catch (error) {
                this.$alert(`Error: ${error.message}`);
                this.$Progress.fail();
            }
        },
        createFilter() {
            const staticSegment = this.segmentConfig;
            staticSegment.entryValues = this.entries;
            staticSegment.produceEventsEnterLeave = this.produceEventsEnterLeave;
            return createSegment(staticSegment)
                .then(({ data: { id } }) => {
                    if (this.isUploadFromFileInputMethod) {
                        this.uploadFilesToSegment(id);
                    }
                })
                .catch(error => {
                    throw new Error(error.response?.data?.msg || error.message);
                });
        },
        updateFilter() {
            const staticSegment = this.segmentConfig;
            staticSegment.version = this.editable.version;
            staticSegment.produceEventsEnterLeave = this.produceEventsEnterLeave;
            staticSegment.entriesDifferenceData = {
                add_ranges: differenceWith(this.rangeInputValues?.data, this.initRangeInputValues, isEqual),
                delete_ranges: differenceWith(this.initRangeInputValues, this.rangeInputValues?.data, isEqual),
                add_entries: this.bufferAddEntries,
                delete_entries: this.bufferDeleteEntries,
            };
            return updateSegment(staticSegment)
                .then(() => {
                    if (this.isUploadFromFileInputMethod) {
                        this.uploadFilesToSegment(this.segmentId);
                    }
                })
                .catch(error => {
                    throw new Error(error.response?.data?.msg || error.message);
                });
        },
        updateFilterEntries(addEntries = [], deleteEntries = []) {
            this.entries = without([...addEntries, ...this.entries], ...deleteEntries);

            this.bufferAddEntries = without([...this.bufferAddEntries, ...addEntries], ...deleteEntries);
            this.bufferDeleteEntries = without([...this.bufferDeleteEntries, ...deleteEntries], ...addEntries);
        },
        uploadFilesToSegment(id) {
            this.configFileUploader.customData.staticFilterId = id;
            this.triggerUploadFiles = !this.triggerUploadFiles;
        },
        navigateToListPage({ timeout = 0 }) {
            setTimeout(() => {
                this.$router.push({
                    name: RouteNames.SEGMENTS_LIST,
                    params: { companyId: this.$route.params.companyId },
                });
            }, timeout);
        },
        handleSuccessfulUpload() {
            this.entityEditorMixin.successfullySaved = true;
            this.$Progress.finish();
            this.$alert(
                this.$t('alertMessage.successActionMessage', {
                    entityName: entityTypeToEntityLabel[this.entityType],
                    action: this.$route.params.id ? 'updated' : 'created',
                }),
                { type: this.$ALERT_TYPES.success },
            );
            this.navigateToListPage({ timeout: 2000 });
        },
        alertEntriesAdded(entries) {
            if (entries.length < 1) return;
            this.$alert(
                this.$t('segments.entrySuccessfullyAdded', {
                    entry: this.selectedEntryType.label,
                    id: entries.join(', '),
                }),
                { type: this.$ALERT_TYPES.success },
            );
        },
        setEntryType(entryType) {
            this.entryType = entryType;
            if (entryType !== SEGMENT_ID_TYPES.MSISDN && this.isRangeInputMethod) {
                this.setInputMethod(STATIC_SEGMENT_INPUT_METHODS.IDS);
            }
            this.clearInputsValues();
        },
        setInputMethod(id) {
            this.inputMethod = id;
            this.clearInputsValues();
        },
        clearInputsValues() {
            this.entries = [];
            this.rangeInputValues = null;
            this.$v.rangeInputValues.$reset();
        },
        displayFailedLines(lines) {
            const tc = 'segments.filters.error.failedLinesMessage';
            this.$alert(this.$t('segments.filters.error.heading'), {
                description: this.$tc(tc, lines.length, { n: lines.join(', ') }),
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.filters-editor-page {
    width: 100%;
    max-width: 35rem;
}
</style>
