import { CommonTemplateEntityTemplate } from '@/__new__/services/dno/template/models/CommonTemplateEntityTemplate';
import { TEMPLATE_TYPES_MAP } from '@/__new__/services/dno/template/models/templateTypes';
import { getUserNameById } from '@/__new__/services/portal/profile/http/profile';
import { FORM_BUILDER_TYPES, WIDGETS } from '@/formBuilderConfig';
import { ALERT_TYPES } from '@/common/alerts/Alert';
import * as Sentry from '@sentry/vue';
import { cloneDeep } from 'lodash';
import { isEmpty } from 'lodash';

export default {
    methods: {
        async initData(forceInitPublished = false) {
            let entityData;
            if (this.entityDraft && !forceInitPublished) {
                entityData = cloneDeep(this.entityDraft);
                entityData.version = this.getEntity(this.entityType, this.$route.params.id).version;
                const publishedEntity = this.getEntity(this.entityType, this.$route.params.id);
                this.isOnlyDraft = publishedEntity?.state === 24;
            } else if (this.currentVersion) {
                await this.loadHistoryEntity(this.entityType, this.$route.params.id);
                entityData = cloneDeep(this.historyEntity);
            } else {
                entityData = cloneDeep(this.getEntity(this.entityType, this.$route.params.id));
            }
            this.isTemplateIdSet = Boolean(this?.entityDraft?.templateId);
            this.getUpdateUserName(entityData.updateUser);
            this.entityData = {
                ...entityData,
                // setting this to null in order to let component load all data necessary
                // for rendering referrence type props. Template id is set correctly later.
                templateId: null,
            };

            if (entityData.childEntities && entityData.childEntities.length) {
                if (this.isMultipleChildEntitiesTypes) {
                    this.selectedChildEntitiesType = this.childEntitiesTypesOptions.find(
                        option => option.type === entityData.childEntities[0].type,
                    );
                }
                this.entityData.childEntities = entityData.childEntities.map(childEntity => {
                    const entity = this.getEntity(childEntity.type, childEntity.id);
                    return {
                        ...cloneDeep(entity),
                        displayName: `${entity.name} - ${entity.id}`,
                        type: childEntity.type,
                    };
                });
            }
            // setting watcher like this to prevent removing childEntities in component "edit mode
            this.$watch('selectedChildEntitiesType', () => {
                if (this.isMultipleChildEntitiesTypes) {
                    this.entityData.childEntities = [];
                }
            });

            // clone case data manipulations
            const { name } = entityData;
            const externalID = entityData.externalId || '';
            if (this.$route.params.clone) {
                this.entityData.name = `${name} (cloned)`;
                this.entityData.externalId = `${externalID}_cloned`;
            }

            if (this.processEntitySpecificData && typeof this.processEntitySpecificData === 'function') {
                this.processEntitySpecificData(entityData);
            }

            // setting data to form builder
            await this.buildDataForFormBuilder(entityData.templateId, true);
            if (forceInitPublished && !this.isOutdatedDraft) {
                this.$alert(this.$i18n.t('productCatalog.editors.revertSuccess'), { type: ALERT_TYPES.success });
            }
        },
        async loadHistoryEntity(entityType, id) {
            if (id && this.currentVersion.version) {
                await this.$withProgressBar(
                    async () => {
                        await this.getHistoryVersion(id, entityType, this.currentVersion.version);
                    },
                    {
                        errorHandler: () => {
                            this.$alert(this.$i18n.t('alertMessage.failedToLoadNecessaryData'), {
                                type: ALERT_TYPES.error,
                            });
                        },
                    },
                );
            }
        },
        async getUpdateUserName(id) {
            try {
                if (id) {
                    const response = await getUserNameById(Number(id));
                    if (response?.data) {
                        this.updateName = response.data;
                    }
                }
            } catch (e) {
                Sentry.captureException(e);
            }
        },
        async buildDataForFormBuilder(id) {
            this.templateRenderError = false;
            const template = this.getTemplate(this.entityType, id);
            if (!this.isDraft || !isEmpty(template)) {
                const data = this.entityData;
                const presetTemplates =
                    template?.presetTemplates?.reduce((acc, presetTemplate) => {
                        const presetTemplateData = this.getTemplate(TEMPLATE_TYPES_MAP.PRESET, presetTemplate.id);
                        const widgetIds = Object.keys(WIDGETS);
                        if (widgetIds.includes(presetTemplateData.externalId)) {
                            const widgetData = WIDGETS[presetTemplateData.externalId];
                            acc.push({
                                groups: presetTemplateData.groups,
                                properties: [
                                    {
                                        id: presetTemplateData.externalId,
                                        name: widgetData.name,
                                        dataType: widgetData.type,
                                        groupId: presetTemplateData.groups[0].id,
                                        defaultValue: widgetData.getDefaultValue(data),
                                    },
                                ],
                            });
                        } else {
                            acc.push(presetTemplateData);
                        }
                        return acc;
                    }, []) || [];
                const presetTemplatesProperties = presetTemplates.length
                    ? presetTemplates.flatMap(pt => pt.properties)
                    : [];
                const presetTemplatesGroups = presetTemplates.length ? presetTemplates.flatMap(pt => pt.groups) : [];
                let properties = presetTemplates.length
                    ? [...(template?.properties || []), ...presetTemplatesProperties]
                    : template?.properties || [];

                // TODO: refactor cycle by sortWeight with offsets for presets after BE implementation
                const [maxSortWeight] = template.groups?.map(({ sortWeight }) => sortWeight) || [0];
                const totalLength = (template.groups?.length || 0) + presetTemplatesGroups.length;
                const offsetMaxSortWeight = totalLength === maxSortWeight ? 0 : totalLength - maxSortWeight;
                const groups = [];
                for (let index = totalLength, addedPresets = 0; index > 0; index -= 1) {
                    const sortWeightIteration = index - offsetMaxSortWeight;
                    let group = template.groups.find(({ sortWeight }) => sortWeight === sortWeightIteration);
                    if (!group) {
                        group = presetTemplatesGroups[addedPresets];

                        addedPresets += 1;
                    }
                    groups.push(group);
                }
                this.$set(this.entityData, 'templateData', null);
                properties = properties.map(prop => {
                    const propFormatted = {
                        ...prop,
                    };
                    if (!propFormatted?.componentProps) {
                        propFormatted.componentProps = {};
                    }
                    // these types not available for now
                    if (this.isDropdownType(prop.dataType)) {
                        if (propFormatted.dataType === FORM_BUILDER_TYPES.DROPDOWN_MULTIPLE) {
                            propFormatted.componentProps.labelFormatter = entity => entity.name;
                        }
                    }
                    return propFormatted;
                });
                if (data) {
                    properties = properties.map(prop => {
                        const defaultValue = data?.properties?.[prop.id];
                        if (defaultValue !== undefined) {
                            return {
                                ...prop,
                                defaultValue,
                            };
                        }
                        return prop;
                    });
                }
                /**
                 * Format template data for form builder.
                 * If this operation fails then do not show template section.
                 */
                let formattedData;
                try {
                    formattedData = await CommonTemplateEntityTemplate.formattedDataForFormBuilder(groups, properties);
                } catch (error) {
                    this.$alert(this.$i18n.t('alertMessage.pc.errorRenderingTemplate'));
                    this.templateRenderError = true;
                }
                this.$set(this.entityData, 'formattedDataForFormBuilder', formattedData);
                this.entityData.templateId = id;
            } else if (!this.isDraftTemplateIdSet) {
                this.suggestDeleteEntity();
            }
        },
        isDropdownType(dataType) {
            return FORM_BUILDER_TYPES.DROPDOWN === dataType || FORM_BUILDER_TYPES.DROPDOWN_MULTIPLE === dataType;
        },
        suggestDeleteEntity() {
            this.$alert(this.$i18n.t('alertMessage.pc.templateNotAvailable'), {
                type: ALERT_TYPES.warning,
                buttons: [this.deleteConfirmationButton],
            });
        },
    },
};
