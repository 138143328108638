
import Vue from '@/common/typedVue';

// Store
import { mapGetters, mapActions, mapMutations } from 'vuex';
import Actions, { Getters, Mutations } from '@/store/mutation-types';
import { Modules } from '@/store/store';

// components
import AbstractEditPageWrapper from '@/components/layout/AbstractEditPageWrapper.vue';
import AppHeader from '@/components/layout/AppHeader.vue';
import AppStepper from '@/components/partials/AppStepper.vue';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import AppInputV3 from '@/components/partials/inputs/AppInputV3.vue';
import CardListRadioInput from '@/components/partials/cards/CardListRadioInput.vue';
import AppMultiselectV3 from '@/components/partials/inputs/AppMultiselectV3.vue';

// sensitive operation components
import ChangeMSISDN from '@/__new__/features/customerCareSuite/components/ChangeMSISDN.vue';
import SimSwapModalMVNE from '@/__new__/features/customerCareSuite/components/SimSwapModalMVNE.vue';
import PortingInfo from '@/__new__/features/customerCareSuite/components/PortingInfo.vue';
import FlagsChange from '@/__new__/features/customerCareSuite/components/FlagsChange.vue';
import RequestSimReplacement from '@/__new__/features/customerCareSuite/components/RequestSimReplacement.vue';

// helpers
import { TranslateResult } from 'vue-i18n';
import { requiredIf, minLength, maxLength } from 'vuelidate/lib/validators';
import {
    AUTHORIZATION_STEPS,
    AuthorizationStepValues,
    OTP_OPTIONS,
    TargetDataType,
    SENSITIVE_OPERATION_TYPES,
    SENSITIVE_OPERATION_TYPES_TO_NAME_MAP,
    SENSITIVE_OPERATION_TYPES_TO_TRANSACTION_TYPE,
} from '@/__new__/features/customerCareSuite/common/endUserAuthorizationHelper';
import luaErrors from '@/common/luaErrors';
import { ICON_COLORS, ICON_TYPES } from '@/common/iconHelper';
import { CHALLENGE_TYPE } from '@/__new__/features/customerCare/common/customerCareHelper';
import moment from 'moment';
import { isUserAllowed, isViewEnabled } from '@/services/permissions/permissions.service';
import { TARGET_TYPE } from '@/__new__/services/dno/user/models/targetTuple';
import { getConnectInstanceId } from '@/__new__/services/portal/awsconnect/common/awsHelpers';
import * as Sentry from '@sentry/vue';

// Mixins
import EndUserAuthorizationMixin from '@/__new__/features/customerCareSuite/components/EndUserAuthorizationMixin.vue';

// Http
import {
    verifyAccountPin,
    createChallenge,
    verifyChallengeEndUserAuthorization,
} from '@/__new__/services/dno/endUserAuthorization/http/endUserAuthorization';
import { setTokenOnContactAttributes } from '@/__new__/services/portal/awsconnect/http/contact';
import { CONTACT_ATTRIBUTES_TOKEN_KEY } from '@/__new__/services/portal/awsconnect/common/constants';

export default Vue.extend({
    name: 'EndUserAuthorization',
    components: {
        AbstractEditPageWrapper,
        AppHeader,
        AppStepper,
        AppButton,
        AppInputV3,
        CardListRadioInput,
        AppMultiselectV3,
        ChangeMSISDN,
        SimSwapModalMVNE,
        PortingInfo,
        FlagsChange,
        RequestSimReplacement,
    },
    mixins: [EndUserAuthorizationMixin],
    data() {
        return {
            BUTTON_TYPES,
            AUTHORIZATION_STEPS,
            OTP_OPTIONS,
            ICON_TYPES,
            ICON_COLORS,
            SENSITIVE_OPERATION_TYPES,

            activeStepIndex: AUTHORIZATION_STEPS.accountPin as AuthorizationStepValues,
            isAccountPinVerified: false,
            isOtpSend: false,
            accountPin: null as number | null,
            accountPinIvalid: false,
            accountPinErrorMsg: null as string | null,
            otpErrorMsg: null as string | null,
            otpVerificationErrorMsg: null as string | null,
            selectedOtpOption: OTP_OPTIONS[0],
            sentOtpTo: null as { id: string; label: string } | null,
            otpCode: null as number | null,
            otpExpirationTime: '' as string,
            challengeId: '' as string,
            accountLocked: false,
            sensitiveOperationCompleted: false,
            otpVerificationCompleted: false,
            numOfAttempsForAccountPin: 10,
            numOfAttempsForOtp: 10,
            isFormValid: true,
        };
    },
    validations: {
        accountPin: {
            // eslint-disable-next-line func-names
            required: requiredIf(function () {
                return this.isCurrentStepIndexActive(AUTHORIZATION_STEPS.accountPin);
            }),
            minLength: minLength(6),
            maxLength: maxLength(15),
        },
        sentOtpTo: {
            // eslint-disable-next-line func-names
            required: requiredIf(function () {
                return (
                    isViewEnabled('UMuseOtpforUserAuthorization') &&
                    this.isCurrentStepIndexActive(AUTHORIZATION_STEPS.otpGeneration)
                );
            }),
        },
        otpCode: {
            // eslint-disable-next-line func-names
            required: requiredIf(function () {
                return (
                    isViewEnabled('UMuseOtpforUserAuthorization') &&
                    this.isCurrentStepIndexActive(AUTHORIZATION_STEPS.otpVerification) &&
                    this.$v.otpCode.$dirty
                );
            }),
        },
    },
    computed: {
        ...mapGetters(Modules.endUserAuthorization, [
            Getters.GET_AUTH_TARGET_EMAILS,
            Getters.GET_AUTH_TARGET_MSISDNS,
            Getters.GET_AUTH_TOKEN_1FA,
            Getters.GET_AUTH_USER_ID_FOR_AUTH_STATE_MAPPING,
            Getters.GET_AUTH_AWS_CONNECT_CONTACT_ID,
        ]),
        steps(): { title: TranslateResult; isCompleted: boolean }[] {
            const steps = [];

            if (!this.getSensitiveOperationType) {
                steps.push({
                    title: this.$t('authorizationPage.accountPin'),
                    isCompleted: this.isAccountPinVerified,
                    indexKey: AUTHORIZATION_STEPS.accountPin,
                });
            }

            if (
                isViewEnabled('UMuseOtpforUserAuthorization') &&
                !isUserAllowed('UMSensitiveTransactionAuthorizationOverride')
            ) {
                steps.push(
                    {
                        title: this.$t('authorizationPage.otpGeneration'),
                        isCompleted: this.isOtpSend,
                        indexKey: AUTHORIZATION_STEPS.otpGeneration,
                    },
                    {
                        title: this.$t('authorizationPage.otpVerification'),
                        isCompleted: this.otpVerificationCompleted,
                        indexKey: AUTHORIZATION_STEPS.otpVerification,
                    },
                );
            }

            if (this.getSensitiveOperationType) {
                steps.push({
                    title: this.sensitiveOperationTypeLabel,
                    isCompleted: this.sensitiveOperationCompleted,
                    indexKey: AUTHORIZATION_STEPS.sensitiveOperation,
                });
            }

            return steps;
        },
        nextStepBtnLable(): string {
            switch (this.activeStepIndex) {
                case AUTHORIZATION_STEPS.accountPin:
                    return this.$t('authorizationPage.verifyPin');
                case AUTHORIZATION_STEPS.otpGeneration:
                    return this.$t('authorizationPage.sendOTP');
                case AUTHORIZATION_STEPS.otpVerification:
                    return this.$t('authorizationPage.verifyOTP');
                case AUTHORIZATION_STEPS.sensitiveOperation:
                    return this.sensitiveOperationTypeLabel;
                default:
                    return this.$t('authorizationPage.verifyPin');
            }
        },
        accountPinErrorMessage(): string {
            if (!this.$v?.accountPin?.minLength) {
                return this.$t('authorizationPage.accountPinMinLengthError');
            }

            if (!this.$v?.accountPin?.maxLength) {
                return this.$t('authorizationPage.accountPinMaxLengthError');
            }

            return this.accountPinErrorMsg ? this.accountPinErrorMsg : this.$t('generic.validations.fieldIsRequired');
        },
        otpVerificationCodeErrorMessage(): string {
            return this.otpVerificationErrorMsg
                ? this.otpVerificationErrorMsg
                : this.$t('generic.validations.fieldIsRequired');
        },
        getTargetEmails(): string[] {
            return this[Getters.GET_AUTH_TARGET_EMAILS] || [];
        },
        getTargetMsisdns(): string[] {
            return this[Getters.GET_AUTH_TARGET_MSISDNS] || [];
        },
        disableButton(): boolean {
            if (this.isCurrentStepIndexActive(AUTHORIZATION_STEPS.accountPin) && this.numOfAttempsForAccountPin === 0) {
                return true;
            }

            if (
                this.accountLocked &&
                (this.isCurrentStepIndexActive(AUTHORIZATION_STEPS.otpGeneration) ||
                    this.isCurrentStepIndexActive(AUTHORIZATION_STEPS.otpVerification))
            ) {
                return true;
            }

            if (this.isCurrentStepIndexActive(AUTHORIZATION_STEPS.otpVerification) && this.numOfAttempsForOtp === 0) {
                return true;
            }

            return !this.isFormValid;
        },
        isTargetDataTypeMsisdnSelected(): boolean {
            return this.selectedOtpOption?.id === TargetDataType.msisdn;
        },
        sentOtpToOptions(): { id: string; label: string }[] {
            const data = this.isTargetDataTypeMsisdnSelected
                ? this[Getters.GET_AUTH_TARGET_MSISDNS]
                : this[Getters.GET_AUTH_TARGET_EMAILS];
            return data.map((el: string) => ({
                id: el,
                label: el,
            }));
        },
        sentOtpToLabel(): string {
            return this.isTargetDataTypeMsisdnSelected ? this.$t('customerCare.msisdn') : this.$t('generic.email');
        },
        sentOtpToPlaceholder(): string {
            return this.isTargetDataTypeMsisdnSelected
                ? this.$t('authorizationPage.chooseMsisdn')
                : this.$t('authorizationPage.chooseEmail');
        },
        getTargetId(): string {
            const { targetId } = this.$route.params;
            return targetId;
        },
        getOperationTargetId(): string {
            return this.$route?.params?.operationTargetId || '';
        },
        getOperationTargetType(): string {
            return this.$route?.params?.operationTargetType || '';
        },
        getTargetType(): TARGET_TYPE {
            const { targetType } = this.$route.params;
            return Number(targetType);
        },
        getSensitiveOperationType(): SENSITIVE_OPERATION_TYPES | null {
            const { operationType } = this.$route.params;
            return (operationType as SENSITIVE_OPERATION_TYPES) || null;
        },
        isSensitiveOperation(): boolean {
            return this.getSensitiveOperationType !== null;
        },
        sensitiveOperationTypeLabel(): string {
            return this.getSensitiveOperationType
                ? SENSITIVE_OPERATION_TYPES_TO_NAME_MAP.get(this.getSensitiveOperationType) || ''
                : '';
        },
        showConfirmButton(): boolean {
            return !(
                this.isCurrentStepIndexActive(AUTHORIZATION_STEPS.sensitiveOperation) &&
                this.getSensitiveOperationType === SENSITIVE_OPERATION_TYPES.ISSUE_TEMPORARY_MNP_PIN
            );
        },
    },
    created() {
        this.$withLoadingSpinner(
            async () => {
                await this.getUserIdFromNonSensitiveInfo();
                this[Actions.SET_AUTH_2FA_SUCCESSFUL_STATE]();

                if (Boolean(this[Getters.GET_AUTH_TOKEN_1FA]) && !isViewEnabled('UMuseOtpforUserAuthorization')) {
                    this[Mutations.UPDATE_AUTH_2FA_SUCCESSFUL_STATUS]({
                        targetId: this[Getters.GET_AUTH_USER_ID_FOR_AUTH_STATE_MAPPING],
                        authState: true,
                    });
                    this.$router.go(-1);
                }

                if (this[Getters.GET_AUTH_TOKEN_1FA]) {
                    this.isAccountPinVerified = true;
                    this.activeStepIndex = AUTHORIZATION_STEPS.otpGeneration;
                }

                if (
                    isViewEnabled('UMuseOtpforUserAuthorization') &&
                    this.getSensitiveOperationType &&
                    !isUserAllowed('UMSensitiveTransactionAuthorizationOverride')
                ) {
                    this.activeStepIndex = AUTHORIZATION_STEPS.otpGeneration;
                    this.isAccountPinVerified = true;
                }

                if (
                    (!isViewEnabled('UMuseOtpforUserAuthorization') && this.getSensitiveOperationType) ||
                    isUserAllowed('UMSensitiveTransactionAuthorizationOverride')
                ) {
                    this.activeStepIndex = AUTHORIZATION_STEPS.sensitiveOperation;
                }

                if (this.isCurrentStepIndexActive(AUTHORIZATION_STEPS.otpGeneration)) {
                    await this[Actions.REQUEST_MEMBERS_NOTIFICATION_IDENTIFIERS]({
                        targetId: this.getTargetId,
                        targetType: this.getTargetType,
                        adminsOnly: this.isSensitiveOperation,
                    });
                }
            },
            {
                errorHandler: () => {
                    this.$eventBus.$emit('showAlert', {
                        message: this.$t('alertMessage.failedToLoadNecessaryData'),
                    });
                },
            },
        );
    },
    methods: {
        ...mapMutations(Modules.endUserAuthorization, [Mutations.UPDATE_AUTH_2FA_SUCCESSFUL_STATUS]),
        ...mapActions(Modules.endUserAuthorization, [
            Actions.REQUEST_MEMBERS_NOTIFICATION_IDENTIFIERS,
            Actions.SET_AUTH_2FA_SUCCESSFUL_STATE,
        ]),
        isCurrentStepIndexActive(authStep: AuthorizationStepValues): boolean {
            return this.activeStepIndex === authStep;
        },
        showCurrentSensitiveOperation(operationType: SENSITIVE_OPERATION_TYPES): boolean {
            return this.getSensitiveOperationType === operationType;
        },
        handleStepVerification(): void {
            this.$v.$touch();
            if (this.$v.$invalid) {
                return;
            }

            switch (this.activeStepIndex) {
                case AUTHORIZATION_STEPS.accountPin:
                    this.verifyAccountPin();
                    break;
                case AUTHORIZATION_STEPS.otpGeneration:
                    this.$v.otpCode.$reset();
                    this.generateOtp();
                    break;
                case AUTHORIZATION_STEPS.otpVerification:
                    this.otpVerification();
                    break;
                case AUTHORIZATION_STEPS.sensitiveOperation:
                    this.sensitiveOperationAction();
                    break;
                default:
                    break;
            }
        },
        onAccountPinChange(): void {
            this.$v.accountPin.$touch();
            this.accountPin = this.accountPin?.replace(/[^\d]+/g, '');
            this.accountPinErrorMsg = null;
        },
        verifyAccountPin() {
            if (this.accountPin) {
                this.$withLoadingSpinner(
                    async () => {
                        await verifyAccountPin(
                            this.getTargetId,
                            this.getTargetType,
                            (this.accountPin || '').toString(),
                        );
                        this.isAccountPinVerified = true;
                        this.accountLocked = false;

                        if (this[Getters.GET_AUTH_AWS_CONNECT_CONTACT_ID]) {
                            try {
                                await setTokenOnContactAttributes(
                                    CONTACT_ATTRIBUTES_TOKEN_KEY.TOKEN_1FA,
                                    this[Getters.GET_AUTH_AWS_CONNECT_CONTACT_ID],
                                    await getConnectInstanceId(),
                                );
                            } catch (ex) {
                                Sentry.captureException(ex);
                            }
                        }

                        if (isViewEnabled('UMuseOtpforUserAuthorization')) {
                            await this[Actions.REQUEST_MEMBERS_NOTIFICATION_IDENTIFIERS]({
                                targetId: this.getTargetId,
                                targetType: this.getTargetType,
                                adminsOnly: this.isSensitiveOperation,
                            });
                            this.activeStepIndex = AUTHORIZATION_STEPS.otpGeneration;
                        } else {
                            this[Mutations.UPDATE_AUTH_2FA_SUCCESSFUL_STATUS]({
                                targetId: this[Getters.GET_AUTH_USER_ID_FOR_AUTH_STATE_MAPPING],
                                authState: true,
                            });
                            this.$router.go(-1);
                        }
                    },
                    {
                        errorHandler: (error: any) => {
                            if (error.response?.data.code === luaErrors.END_USER_AUTHORIZATION.NOT_VALID_PIN.code) {
                                this.numOfAttempsForAccountPin = error.response?.data?.remaining_retries || 0;
                                this.accountPinErrorMsg = this.$t('authorizationPage.accountPinIvalid', {
                                    attempts: this.numOfAttempsForAccountPin,
                                });
                            } else if (
                                error.response?.data.code === luaErrors.END_USER_AUTHORIZATION.PIN_RESET_REQUIRED.code
                            ) {
                                this.accountPinErrorMsg = this.$t('authorizationPage.maxNumberOfPinAttemptsError');
                            } else {
                                this.accountPinErrorMsg = this.$t('authorizationPage.verifyAccountPinError');
                            }
                        },
                    },
                );
            }
        },
        onOtpOptionChange(): void {
            this.sentOtpTo = null;
        },
        accountLockedFor(ms: number): void {
            this.accountLocked = true;
            setTimeout(() => {
                this.accountLocked = false;
            }, ms);
        },
        generateOtp(): void {
            this.$withLoadingSpinner(
                async () => {
                    const { data } = await createChallenge(
                        this.getTargetId,
                        this.getTargetType,
                        this.isTargetDataTypeMsisdnSelected ? CHALLENGE_TYPE.SMS : CHALLENGE_TYPE.EMAIL_CODE,
                        this.isTargetDataTypeMsisdnSelected
                            ? { msisdn: this.sentOtpTo?.id }
                            : { email: this.sentOtpTo?.id },
                        this.getSensitiveOperationType
                            ? SENSITIVE_OPERATION_TYPES_TO_TRANSACTION_TYPE.get(this.getSensitiveOperationType)
                            : undefined,
                    );

                    this.otpExpirationTime = moment().add(data.expiration, 's').format('h:mm:ss a');
                    this.challengeId = data.challenge_id;
                    this.isOtpSend = true;
                    this.activeStepIndex = AUTHORIZATION_STEPS.otpVerification;
                },
                {
                    errorHandler: (error: any) => {
                        if (error.response?.data.code === luaErrors.OTP.CHALLENGE_GENERATE_LIMIT.code) {
                            const remainingTimeMs = error.response?.data.remaining_time_ms || 0;
                            this.otpErrorMsg = this.$t('authorizationPage.challengeGenerateLimit', {
                                time: moment().add(remainingTimeMs, 'ms').format('HH:mm:ss'),
                            });
                            this.accountLockedFor(remainingTimeMs);
                        }
                    },
                },
            );
        },
        otpVerification(): void {
            if (!this.isOtpSend || (!this.isAccountPinVerified && !isUserAllowed('UMEndUserFullDataAccess'))) {
                return;
            }
            this.$withLoadingSpinner(
                async () => {
                    await Promise.all([
                        verifyChallengeEndUserAuthorization(
                            this.getTargetId,
                            this.getTargetType,
                            this.isTargetDataTypeMsisdnSelected ? CHALLENGE_TYPE.SMS : CHALLENGE_TYPE.EMAIL_CODE,
                            this.challengeId,
                            (this.otpCode || '').toString(),
                        ),
                    ]);

                    this.otpVerificationCompleted = true;

                    if (this[Getters.GET_AUTH_AWS_CONNECT_CONTACT_ID]) {
                        try {
                            await setTokenOnContactAttributes(
                                CONTACT_ATTRIBUTES_TOKEN_KEY.TOKEN_2FA,
                                this[Getters.GET_AUTH_AWS_CONNECT_CONTACT_ID],
                                await getConnectInstanceId(),
                            );
                        } catch (ex) {
                            Sentry.captureException(ex);
                        }
                    }

                    if (this.getSensitiveOperationType) {
                        this.activeStepIndex = AUTHORIZATION_STEPS.sensitiveOperation;
                    } else {
                        this[Mutations.UPDATE_AUTH_2FA_SUCCESSFUL_STATUS]({
                            targetId: this[Getters.GET_AUTH_USER_ID_FOR_AUTH_STATE_MAPPING],
                            authState: true,
                        });
                        this.$router.go(-1);
                    }
                },
                {
                    errorHandler: ({ response: { data: error } }: any) => {
                        if (error?.module === 'OTP') {
                            if (error?.code === luaErrors.OTP.CHALLENGEID_VERIFIED.code) {
                                this.otpErrorMsg = this.$t('authorizationPage.accountVerifiedError');
                            } else if (error?.code === luaErrors.OTP.CHALLENGEID_EXPIRED.code) {
                                this.otpErrorMsg = this.$t('authorizationPage.accountExpiredError');
                            } else if (
                                [
                                    luaErrors.OTP.INVALID_CHALLENGEID.code,
                                    luaErrors.OTP.CHALLENGE_NOT_MATCH.code,
                                ].includes(error?.code)
                            ) {
                                this.numOfAttempsForOtp = error?.remaining_retries || 0;
                                this.otpVerificationErrorMsg = this.$t('authorizationPage.otpCodeIvalid', {
                                    attempts: this.numOfAttempsForOtp,
                                });
                            }
                        } else if (error?.module === 'SERVICE_BLOCKER') {
                            if (error?.code === luaErrors.SERVICE_BLOCKER.BLOCKED.code) {
                                const remainingTimeMs = error?.remaining_time_ms || 0;
                                this.otpVerificationErrorMsg = this.$t('authorizationPage.otpCodeIvalidUserBlocked', {
                                    time: moment().add(remainingTimeMs, 'ms').format('HH:mm:ss'),
                                });
                                this.accountLockedFor(remainingTimeMs);
                            }
                        } else {
                            this.otpVerificationErrorMsg = this.$t('authorizationPage.verifyOtpCodeError');
                        }
                    },
                },
            );
        },
        backToOtpGeneration(): void {
            this.otpExpirationTime = '';
            this.challengeId = '';
            this.isOtpSend = false;
            this.activeStepIndex = AUTHORIZATION_STEPS.otpGeneration;
        },
        sensitiveOperationAction(): void {
            switch (this.getSensitiveOperationType) {
                case SENSITIVE_OPERATION_TYPES.CHANGE_MSISDN:
                    this.$refs.changeMsisdn.onSubmit();
                    break;
                case SENSITIVE_OPERATION_TYPES.SIM_SWAP:
                    this.$refs.simSwap.onSave();
                    break;
                case SENSITIVE_OPERATION_TYPES.REQUEST_REPLACEMENT_PSIM:
                    this.$refs.replacementPsim.requestReplacement();
                    break;
                case SENSITIVE_OPERATION_TYPES.FLAG_CHANGE:
                    this.$refs.flagChange.updateFlags();
                    break;
                default:
                    break;
            }
        },
        isSensitiveOperationCompleted(isComplited: boolean): void {
            if (isComplited) {
                this.$router.go(-1);
            }
        },
    },
});
