import i18n from '@/i18n';

export enum OWNERSHIP {
    REGULAR = 1,
    PORT_IN = 2,
    PORT_OUT = 3,
}

export const STATE_TO_I18N_KEY = new Map([
    [OWNERSHIP.REGULAR, 'operator.numberManagement.regular'],
    [OWNERSHIP.PORT_IN, 'operator.numberManagement.portIn'],
    [OWNERSHIP.PORT_OUT, 'operator.numberManagement.portOut'],
]);

export function formatOwnership(ownership: OWNERSHIP) {
    return i18n.t(STATE_TO_I18N_KEY.get(ownership) || 'generic.unknown');
}
