import httpClient from '@/http/index';

export function getOperators() {
    return httpClient({
        method: 'GET',
        url: '/operators/getoperators',
    });
}

export function getOperatorConfig(operatorId?: number) {
    return httpClient({
        method: 'POST',
        url: '/operators/getoperatorconfig',
        data: {
            operatorId,
        },
    });
}

export function setOperatorConfig(operatorId: number, value: any) {
    return httpClient({
        method: 'POST',
        url: '/operators/setoperatorconfig',
        data: {
            operatorId,
            value,
        },
    });
}

export default {};
