import i18n from '@/i18n';

export interface DurationOption {
    id: string;
    label: string;
}
export interface PausedExecutionBE {
    execution_id: string;
    last_state: string;
    plan_owner: string;
    operator_name: string;
    paused_till: number;
    plan_id: string;
    scheduled_at?: number;
    scheduled_for?: number;
    started_at?: number;
}

export const DURATION_OPTIONS: DurationOption[] = [
    { id: 'one_hour', label: i18n.t('orchestrationEngine.expandPauseDurationOptions.oneHour') as string },
    { id: 'two_hour', label: i18n.t('orchestrationEngine.expandPauseDurationOptions.twoHours') as string },
    { id: 'four_hour', label: i18n.t('orchestrationEngine.expandPauseDurationOptions.fourHours') as string },
    { id: 'twelve_hour', label: i18n.t('orchestrationEngine.expandPauseDurationOptions.twelveHours') as string },
    { id: 'one_day', label: i18n.t('orchestrationEngine.expandPauseDurationOptions.oneDay') as string },
    { id: 'two_day', label: i18n.t('orchestrationEngine.expandPauseDurationOptions.twoDays') as string },
    { id: 'five_day', label: i18n.t('orchestrationEngine.expandPauseDurationOptions.fiveDays') as string },
    { id: 'one_week', label: i18n.t('orchestrationEngine.expandPauseDurationOptions.oneWeek') as string },
    { id: 'four_week', label: i18n.t('orchestrationEngine.expandPauseDurationOptions.fourWeeks') as string },
];

export default { DURATION_OPTIONS };
