var render = function render(){var _vm=this,_c=_vm._self._c;return _c('TableFiltersRenderless',{attrs:{"entities":_vm.oeMappingsList},scopedSlots:_vm._u([{key:"default",fn:function({
            // state
            allFilters,
            filteredEntities,
            // methods
            onFilterAdded,
            removeFilter,
            removeAllFilters,
        }){return [_c('div',[_c('div',{staticClass:"d-flex align-items-center justify-content-between mb-2"},[(_vm.titleIsEnabled)?_c('div',{staticClass:"d-flex component-title-um"},[_vm._v(" "+_vm._s(_vm.$i18n.t('orchestrationEngine.oeWorkflow'))+" ")]):_vm._e(),(!_vm.titleIsEnabled)?_c('div',[_vm._t('additionalContent')],2):_vm._e(),_c('div',{staticClass:"d-flex"},[_c('SearchBox',{staticClass:"table-search-box",attrs:{"smallSearchIcon":true},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}}),_c('ResponseModalButton',{attrs:{"response":_vm.oeMappingsApiResponse,"title":_vm.$i18n.t('orchestrationEngine.oeWorkflow')}}),_c('div',{staticClass:"filters-control position-relative"},[_c('FilterTable',{attrs:{"columns":_vm.oeColumnsData,"multiselectWidth":{ width: '12rem' }},on:{"filterAdded":onFilterAdded},scopedSlots:_vm._u([{key:"button",fn:function(slotProps){return [_c('IconButton',{attrs:{"label":_vm.$i18n.t('generic.filter'),"icon":_vm.ICON_TYPES.FILTER},on:{"iconClick":slotProps.clickHandler}})]}}],null,true)})],1)],1)]),_c('TableFiltersTags',{staticClass:"mt-1 mb-3",attrs:{"filterTableMixin":{ allFilters }},on:{"removeFilter":removeFilter,"removeAllFilters":removeAllFilters}}),_c('AppTable',{staticClass:"auto-height",attrs:{"entities":filteredEntities,"isDefaultHoverEnabled":false,"forbidHideColumn":true,"disableBoxShadow":true,"newDesign":true,"canSelectColumns":false,"isDataLoading":_vm.isOeLoading,"columnsData":_vm.oeColumnsData,"tableKey":"user/account-oe","keyName":"executionId","innerSearchQuery":_vm.searchQuery,"isSearchEnabled":true,"enableRowStateControls":false,"isPaginationEnabled":true},scopedSlots:_vm._u([{key:"planId",fn:function({ entity }){return [(entity.planId)?_c('div',{staticClass:"truncate-text"},[_c('router-link',{attrs:{"to":{
                                name: _vm.RouteNames.ORCHESTRATION_ENGINE_EXECUTION_DETAILS,
                                params: {
                                    id: entity.planId,
                                    execId: entity.executionId,
                                },
                            },"target":"_blank"}},[_vm._v(" "+_vm._s(entity.planId)+" ")])],1):_vm._e()]}},{key:"status",fn:function({ entity }){return [(entity.status)?_c('ExecutionStatusIndicator',{attrs:{"status":entity.status}}):_vm._e()]}}],null,true)})],1)]}}],null,true)})
}
var staticRenderFns = []

export { render, staticRenderFns }