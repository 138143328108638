<template>
    <div class="d-flex">
        <span
            class="tag-element heading-xs"
            :class="{ disabled, 'overview-mode': overviewMode }"
            @click="emitClick"
        >
            {{ label }}
        </span>
        <span
            v-if="!overviewMode"
            data-test-id="delete-tag-icon"
            class="close-icon-wrap"
            :class="{ disabled }"
            @mouseover="closeIconHovered = true"
            @mouseleave="closeIconHovered = false"
            @click="emitCloseIconClick"
        >
            <AppIcon
                size=".75rem"
                :type="ICON_TYPES.CLOSE"
                :color="closeIconColor"
            />
        </span>
    </div>
</template>

<script>
// components
import AppIcon from '@/components/partials/icon/AppIcon.vue';
// helpers
import { ICON_COLORS, ICON_TYPES } from '@/common/iconHelper';

export default {
    name: 'Tag',
    components: {
        AppIcon,
    },
    props: {
        label: {
            type: [String, Number],
            required: true,
            default: '',
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        // is used just for displaying labels without delete icon
        overviewMode: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            ICON_TYPES,
            ICON_COLORS,
            closeIconHovered: false,
        };
    },
    computed: {
        closeIconColor() {
            if (this.disabled) return ICON_COLORS.GRAY;
            return this.closeIconHovered ? ICON_COLORS.RED : ICON_COLORS.BLUE_LIGHT;
        },
        shouldBlockEmits() {
            return this.disabled || this.overviewMode;
        },
    },
    methods: {
        emitClick(e) {
            if (!this.shouldBlockEmits) {
                this.$emit('click', e);
            }
        },
        emitCloseIconClick(e) {
            if (!this.shouldBlockEmits) {
                this.$emit('closeIconClick', e);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/_consistency';

.close-icon-wrap {
    vertical-align: center;
    background: $gray-200;
    padding: $spacing-xxs $spacing-xs;
    display: flex;
    align-items: center;
    border-top-right-radius: $spacing-xxs;
    border-bottom-right-radius: $spacing-xxs;
    cursor: pointer;
    &.disabled {
        pointer-events: none;
        background-color: $gray-100;
    }
    &:hover {
        background: $red-200;
    }
}
.tag-element {
    display: inline-flex;
    align-items: center;
    color: $blue-600;
    background: $gray-200;
    padding: $spacing-xxs $spacing-xxs $spacing-xxs $spacing-xs;
    border-top-left-radius: $spacing-xxs;
    border-bottom-left-radius: $spacing-xxs;
    &.overview-mode {
        border-radius: $spacing-xxs;
        padding: $spacing-xxs $spacing-xs;
    }
    &.disabled {
        color: $gray-300;
        background-color: $gray-100;
        pointer-events: none;
        padding: $spacing-xxs $spacing-xs;
    }
}
</style>
