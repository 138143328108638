var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"stepper d-flex row"},_vm._l((_vm.steps),function(step,index){return _c('div',{key:step.key,staticClass:"step d-flex flex-column align-items-center col"},[_c('div',{class:[
                'circle text-center',
                {
                    orange: index === _vm.currentStepIndex,
                    gray: index > _vm.maxStepAvailableIndex,
                    navy: index <= _vm.maxStepAvailableIndex && index !== _vm.currentStepIndex,
                },
            ],on:{"click":function($event){return _vm.clickOnStep(index)}}}),_c('div',{staticClass:"stepper-line line-left"}),_c('div',{staticClass:"stepper-line line-right"}),_c('div',{class:[
                'title',
                {
                    navy: index <= _vm.maxStepAvailableIndex && index !== _vm.currentStepIndex,
                    current: index === _vm.currentStepIndex,
                },
            ],on:{"click":function($event){return _vm.clickOnStep(index)}}},[_vm._v(" "+_vm._s(step.title)+" ")])])}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }