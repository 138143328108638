<template>
    <div class="f-text-input d-flex align-items-center">
        <Dropdown
            :items="operationLabels"
            :colorSchema="Styles.White"
            :selected="[value && ObjectOperationsToLabels[value.op]]"
            caption="Select operation"
            :isFilterOperation="true"
            :widthDependsOnContent="true"
            @selected="opLabel => onChange({ op: ObjectOperationLabelsToType[opLabel] })"
        />
    </div>
</template>

<script>
import Dropdown, { Styles } from '@/components/partials/Dropdown.vue';
import {
    ObjectOperationLabels,
    ObjectOperationLabelsToType,
    ObjectOperationsToLabels,
    NoArgOperations,
} from '@/common/filters/InputOperations';
import { mapGetters } from 'vuex';
import { Modules } from '@/store/store';
import { Getters } from '@/store/mutation-types';
import {
    CDP_PROPERTY_COMPLEX_TYPES,
    CDP_PROPERTY_JSON_PATH_TYPES,
    CDP_PROPERTY_TYPES,
} from '@/__new__/services/dno/events/models/EventProp';
import { ICON_TYPES } from '@/common/iconHelper';

export default {
    name: 'FObjectInput',
    components: {
        Dropdown,
    },
    props: {
        changeCallback: { type: Function, default: null },
        initialValue: { type: Object, default: null },
        rowData: { type: Object, default: null },
        shouldAddLabels: {
            type: Boolean,
            required: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            ICON_TYPES,
            CDP_PROPERTY_TYPES,
            CDP_PROPERTY_JSON_PATH_TYPES,
            CDP_PROPERTY_COMPLEX_TYPES,
            Styles,
            ObjectOperationsToLabels,
            ObjectOperationLabelsToType,
            NoArgOperations,
            value: this.initialValue, // override
        };
    },
    computed: {
        ...mapGetters(Modules.sinkConfigs, {
            allJsonPathOptions: Getters.GET_JSON_PATH_OPTIONS,
        }),
        isValid() {
            return true;
        },
        operationLabels() {
            return ObjectOperationLabels;
        },
    },
    methods: {
        onChange(partialValue) {
            this.onValueChange(partialValue);
            if (this.changeCallback) this.changeCallback();
        },
        onValueChange(partialValue = {}) {
            return partialValue;
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/mixins';
@import '~@/assets/scss/_consistency';

.dropdown {
    @include custom-height-dropdown(32px);
    @include custom-width-dropdown(180px);
}

.bordered {
    padding-bottom: 1rem;
    border-bottom: 1px solid $gray4;
}

.json-path-dropdown {
    .json-path-type {
        border: 1px solid $gray-200;
        padding: $spacing-xxs;
        border-radius: 5px;
        background: $white;
    }
}
</style>
