import VueCookie from 'vue-cookie';

export const LF_TELECOM_TENANT_NAME = 'LotusFlareTelecom';

export default class Tenant {
    id: number;
    name: string;
    dnoIdentifier: string;
    operatorId: number;

    static tenantCookieName = 'tenantData';

    constructor({ id = 0, name = 'Tenant', dnoIdentifier = 'tenant', operatorId = 0 }) {
        this.id = id;
        this.name = name;
        this.dnoIdentifier = dnoIdentifier;
        this.operatorId = operatorId;
    }

    /**
     * Stores tenant information in store (cookie) that can be accessed from all portal instances.
     * Important note is that there is also other code in V3 (app_events.js file in V1) that stores this data when user
     * is in V1. If there are structure changes they need to be done there as well.
     * Also not passing this and instead creating custom object because _this_ would include tenantCookieName property.
     */
    store() {
        VueCookie.set(
            Tenant.tenantCookieName,
            JSON.stringify({
                id: this.id,
                name: this.name,
                dnoIdentifier: this.dnoIdentifier,
                operatorId: this.operatorId,
            }),
            {
                domain: window.location.hostname,
            },
        );
    }

    /**
     * Returns full current tenant information for store that is used to keep tenant data.
     */
    static get storedInstance() {
        return new Tenant(JSON.parse(VueCookie.get(Tenant.tenantCookieName)) || {});
    }

    /**
     * Applies necessary changes to switch current session to new tenant/company.
     */
    switchTo() {
        this.store();
        VueCookie.set('CID', this.id, {
            domain: window.location.hostname,
            expires: '30m',
        });
    }
}
