<template>
    <div class="h-100">
        <AbstractListPageWrapper
            :pageTitle="$i18n.t('charging.entities.plural.conditionParameters')"
            :isOverviewEnabled="isOverviewEnabled"
            :entitiesCount="conditionParameters.length"
            @searchQueryChanged="setSearchQuery"
        >
            <template #filterTable>
                <FilterTable
                    :columns="tableColumnsData"
                    @filterAdded="onFilterAdded"
                />
            </template>
            <template #button>
                <div class="d-flex align-items-center">
                    <ResponseModalButton
                        :response="payoutsApiResponse"
                        :title="$i18n.t('charging.entities.plural.conditionParameters')"
                        class="mr-3"
                        data-test-id="response-modal-button"
                    />
                    <IconButton
                        :label="$i18n.t('productCatalog.downloadDetails')"
                        :icon="ICON_TYPES.DOWNLOAD"
                        data-test-id="btn-download-csv"
                        @iconClick="triggerDownloadingCSV"
                    />
                </div>
            </template>
            <template #headerButtons>
                <AppButton
                    v-if="isEditEnabled"
                    data-test-id="new-condition-parameter"
                    :buttonType="BUTTON_TYPES.PRIMARY"
                    :iconType="ICON_TYPES.PLUS"
                    :label="$i18n.t('home.createNew')"
                    @click="newConditionParem"
                />
            </template>
            <template #allFilters>
                <TableFiltersTags
                    :filterTableMixin="filterTableMixin"
                    class="my-3 ml-2"
                    @removeFilter="removeFilter"
                    @removeAllFilters="removeAllFilters"
                />
            </template>
            <template #table>
                <AppTable
                    :entities="sortedFilteredEntities"
                    :selectedEntityId="selectedEntityId"
                    :isSearchEnabled="true"
                    :innerSearchQuery="searchQueryForTable"
                    :columnsData="tableColumnsData"
                    :isDataLoading="isDataLoading"
                    :entityType="entityType"
                    :enableRowStateControls="isEditEnabled"
                    :requestEntities="requestEntities"
                    :emitEntityActions="true"
                    tableKey="charging/condition-parameters"
                    @selectEntity="onEntitySelected"
                    @input="ent => setNumberOfItemsAfterSearch(ent.length, conditionParameters.length)"
                    @returnCleanEntities="downloadOffersCSV"
                    @edit="onEdit"
                    @clone="onClone"
                    @delete="onDelete"
                    @readonly="onReadOnly"
                >
                    <template #entityStatusCode="{ entity }">
                        <EntityStatusIndicator :status="entity.entityStatusCode" />
                    </template>
                </AppTable>
            </template>
            <template #overview>
                <MutationDialog
                    v-model="isMutationModalVisible"
                    :entities="getAffectedEntities(mutationDialogDataType)"
                    @input="clearMutationDialogDataType"
                />
                <EntityOverview
                    :entityType="entityType"
                    :entity="selectedEntity"
                    @closeOverview="isOverviewEnabled = false"
                >
                    <template #section-1-header>
                        <div>
                            <OverviewHeader
                                v-if="selectedEntityId"
                                :entityName="getMultiLangFieldValueByLocale(selectedEntity.name, selectedLanguage)"
                                :entityType="entityType"
                            />
                            <LanguageSwitcher v-model="selectedLanguage" />
                        </div>
                    </template>
                    <template #section-1-content>
                        <div>
                            <OverviewList
                                :lists="detailsSections"
                                @showDialog="showMutationDialog"
                            />
                            <div class="conditions-count">
                                {{ $i18n.t('generic.conditionApplied') }}
                            </div>
                            <ConditionValue
                                :value="condition"
                                class="ml-2 mt-2"
                            />
                        </div>
                    </template>
                </EntityOverview>
            </template>
        </AbstractListPageWrapper>
    </div>
</template>
<script>
// Store
import { mapActions, mapGetters } from 'vuex';
import Actions, { Getters } from '@/store/mutation-types';
import { Modules } from '@/store/store';

// Components
import AbstractListPageWrapper from '@/components/layout/AbstractListPageWrapper.vue';
import FilterTable from '@/components/partials/FilterTable.vue';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import { ICON_TYPES } from '@/common/iconHelper';
import TableFiltersTags from '@/components/filters/TableFiltersTags.vue';
import AppTable from '@/components/partials/AppTable.vue';
import EntityStatusIndicator from '@/components/partials/EntityStatusIndicator.vue';
import MutationDialog from '@/components/partials/MutationDialog.vue';
import EntityOverview from '@/components/partials/entityOverview/EntityOverview.vue';
import OverviewHeader from '@/components/partials/entityOverview/OverviewHeader.vue';
import LanguageSwitcher from '@/components/partials/inputs/LanguageSwitcher.vue';
import OverviewList from '@/components/partials/entityOverview/OverviewList.vue';
import ConditionValue from '@/__new__/features/charging/conditionParameters/conditionsValues/ConditionValue.vue';
import ResponseModalButton from '@/components/partials/ResponseModalButton.vue';
import permissionsService, { isUserAllowed } from '@/services/permissions/permissions.service';
import IconButton from '@/components/partials/IconButton.vue';

// Mixins
import CountControlMixin from '@/components/partials/CountControlMixin.vue';
import FilterTableMixin from '@/components/partials/FilterTableMixin.vue';
import DownloadDetailsMixin from '@/__new__/features/charging/DownloadDetailsMixin.vue';
import ChargingCommonActionsMixin from '@/__new__/features/charging/ChargingCommonActionsMixin.vue';

// Http
import {
    deleteConditionParameterDraft,
    updateStateConditionParameter,
} from '@/__new__/services/dno/charging/http/conditionParameters';

// Helpers
import ENTITY_TYPES from '@/common/entities/entityTypes';
import tableColumnType from '@/common/filterTable';
import {
    formatMutationDialogEntities,
    getMultiLangFieldValueByLocale,
    getEntityConsumers,
} from '@/common/entities/entityHelper';
import { languageMap } from '@/common/locale/language';
import RouteNames from '@/router/routeNames';
import { allConditionOptions } from '@/__new__/services/dno/charging/common/chargingSpecificationHelper';
import { upperFirst } from 'lodash';
import { PLURALIZATION } from '@/common/locale/labelSingularOrPlural';
import * as Sentry from '@sentry/vue';

export default {
    name: 'ConditionParameters',
    components: {
        AbstractListPageWrapper,
        FilterTable,
        AppButton,
        TableFiltersTags,
        AppTable,
        EntityStatusIndicator,
        MutationDialog,
        EntityOverview,
        OverviewHeader,
        LanguageSwitcher,
        OverviewList,
        ConditionValue,
        ResponseModalButton,
        IconButton,
    },
    mixins: [CountControlMixin, FilterTableMixin, DownloadDetailsMixin, ChargingCommonActionsMixin],
    data() {
        return {
            ENTITY_TYPES,
            PLURALIZATION,
            entityType: ENTITY_TYPES.CONDITION_PARAMETERS,
            editorRoute: RouteNames.CHARGING_CONDITION_PARAMETERS_EDITOR,
            ICON_TYPES,
            BUTTON_TYPES,
            isOverviewEnabled: false,
            searchQueryForTable: '',
            selectedEntityId: null,
            selectedEntity: null,
            isDataLoading: false,
            isMutationModalVisible: false,
            mutationDialogDataType: '',
            selectedLanguage: '',
        };
    },
    computed: {
        ...mapGetters(Modules.charging, [
            Getters.GET_CONDITION_PARAMETERS,
            Getters.GET_CONDITION_PARAMETERS_API_RESPONSE,
            Getters.GET_CONDITION_PARAMETER_BY_ID,
        ]),
        ...mapGetters('operators', [Getters.languageDefault, Getters.languagesWithoutDefault]),
        isEditEnabled() {
            return (
                permissionsService.chargingConditionParametersEnabled() && isUserAllowed('ConditionParametersReadWrite')
            );
        },
        payoutsApiResponse() {
            return this[Getters.GET_CONDITION_PARAMETERS_API_RESPONSE];
        },
        conditionParameters() {
            const data = [...this[Getters.GET_CONDITION_PARAMETERS]];
            return data.sort((entity1, entity2) => entity1.name.localeCompare(entity2.name));
        },
        tableColumnsData() {
            return [
                {
                    name: this.$i18n.t('generic.name'),
                    key: 'name',
                    classes: ['font-weight-bold'],
                    forbidHideColumn: true,
                    field: 'name',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('generic.description'),
                    key: 'description',
                    field: 'description',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('generic.type'),
                    key: 'conditionType',
                    mapper: entity => allConditionOptions?.[entity.conditionType]?.label,
                    sortBy: entity => entity.conditionType,
                    field: 'conditionType',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('generic.lastUpdatedTime'),
                    key: 'formattedDate',
                    field: 'update_time',
                    sortBy: entity => entity.update_time,
                    filterType: tableColumnType.DATE,
                },
                {
                    name: this.$i18n.t('generic.version'),
                    key: 'chargingVersion',
                    field: 'chargingVersion',
                    filterType: tableColumnType.GENERAL_TEXT,
                },

                {
                    name: this.$i18n.t('generic.state'),
                    key: 'entityStatusCode',
                    field: 'entityStatusCodeLabel',
                    filterType: tableColumnType.TEXT_LIMITED_OPTIONS,
                    limitedOptions: [...new Set(this.conditionParameters.map(e => e.entityStatusCodeLabel))],
                },
            ];
        },
        sortedFilteredEntities() {
            return this.filteredEntitiesMixin(this.conditionParameters);
        },
        detailsSections() {
            if (!this.selectedEntityId) {
                return [];
            }

            return [
                {
                    name: this.$i18n.t('generic.general'),
                    properties: [
                        {
                            value: this.selectedEntity.id,
                            label: this.$i18n.t('generic.id'),
                        },
                        {
                            value: this.selectedEntity.name || this.$i18n.t('generic.N/A'),
                            label: this.$i18n.t('generic.name'),
                        },
                        {
                            value: this.selectedEntity?.description
                                ? this.selectedEntity.description
                                : this.$i18n.t('generic.N/A'),
                            label: this.$i18n.t('generic.description'),
                        },
                    ],
                },
                this.upperLevelEntities,
            ];
        },
        condition() {
            return this.selectedEntity?.conditionParameter ? this.selectedEntity.conditionParameter : {};
        },
        upperLevelEntities() {
            const {
                [ENTITY_TYPES.CHARGING_SPECIFICATION]: inUseByCs = [],
                [ENTITY_TYPES.USAGE_COUNTER]: inUseByUc = [],
                [ENTITY_TYPES.TARIFF_SPECIFICATION]: inUseByTs = [],
            } = this.upperEntities();

            if (!inUseByCs.length && !inUseByUc.length) {
                return {};
            }

            const data = {
                name: `${upperFirst(this.$i18n.t('charging.entities.plural.conditionParameters'))} ${this.$i18n.t(
                    'generic.usedBy',
                )}`,
                properties: [],
            };

            if (inUseByCs.length) {
                data.properties.push({
                    label: `${inUseByCs.length} ${this.$i18n.t('charging.entities.plural.chargingSpecifications')}`,
                    value: inUseByCs.length,
                    linkLabel: this.$i18n.t('generic.seeDetails'),
                    formatter: 'link',
                    type: ENTITY_TYPES.CHARGING_SPECIFICATION,
                });
            }

            if (inUseByUc.length) {
                data.properties.push({
                    label: `${inUseByUc.length} ${this.$i18n.tc('charging.usageCounters.name', PLURALIZATION.PLURAL)}`,
                    value: inUseByUc.length,
                    linkLabel: this.$i18n.t('generic.seeDetails'),
                    formatter: 'link',
                    type: ENTITY_TYPES.USAGE_COUNTER,
                });
            }

            if (inUseByTs.length) {
                data.properties.push({
                    label: `${inUseByTs.length} ${this.$i18n.tc('charging.tariffSpec.name', PLURALIZATION.PLURAL)}`,
                    value: inUseByTs.length,
                    linkLabel: this.$i18n.t('generic.seeDetails'),
                    formatter: 'link',
                    type: ENTITY_TYPES.TARIFF_SPECIFICATION,
                });
            }

            return data;
        },
    },
    created() {
        this.initData();
    },
    methods: {
        getMultiLangFieldValueByLocale,
        ...mapActions(Modules.charging, [
            Actions.REQUEST_CONDITION_PARAMETERS,
            Actions.REQUEST_CHARGING_SPECIFICATIONS,
        ]),
        ...mapActions(Modules.chargingV2, [Actions.REQUEST_TARIFF_SPECIFICATIONS, Actions.REQUEST_USAGE_COUNTERS]),
        initData() {
            this.$withLoadingSpinner(
                async () => {
                    this.$Progress.start();
                    this.isDataLoading = true;

                    const promises = [
                        this[Actions.REQUEST_CONDITION_PARAMETERS](),
                        this[Actions.REQUEST_USAGE_COUNTERS](),
                        this[Actions.REQUEST_CHARGING_SPECIFICATIONS](),
                        this[Actions.REQUEST_TARIFF_SPECIFICATIONS](),
                    ];

                    await Promise.all(promises);
                    this.selectedLanguage = (this[Getters.languageDefault] || languageMap.en.key).toString();

                    this.$Progress.finish();
                    this.isDataLoading = false;
                },
                {
                    errorHandler: e => {
                        this.isDataLoading = false;
                        this.$Progress.fail();

                        Sentry.captureException(e);
                        this.$eventBus.$emit('showAlert', {
                            message: e.message,
                        });
                    },
                },
            );
        },
        setSearchQuery(query) {
            this.searchQueryForTable = query;
        },
        onEntitySelected(entityId) {
            this.selectedEntityId = entityId;
            this.selectedEntity = this[Getters.GET_CONDITION_PARAMETER_BY_ID](entityId);
            this.isOverviewEnabled = true;
        },
        upperEntities(entityId = this.selectedEntityId) {
            return getEntityConsumers(entityId, this.entityType);
        },
        getAffectedEntities(mutationDialogDataType) {
            return formatMutationDialogEntities(this.upperEntities(), mutationDialogDataType);
        },
        clearMutationDialogDataType(value) {
            if (!value) {
                this.mutationDialogDataType = '';
            }
        },
        showMutationDialog(type) {
            this.isMutationModalVisible = true;
            this.mutationDialogDataType = type;
        },
        newConditionParem() {
            this.$router.push({
                name: RouteNames.CHARGING_CONDITION_PARAMETERS_EDITOR,
                params: { companyId: this.$route.params.companyId },
            });
        },
        onEdit(entityId) {
            this.onEditAction(entityId, this.editorRoute);
        },
        onClone(entityId) {
            this.onCloneAction(entityId, this.editorRoute);
        },
        onDelete(entityId) {
            const { state, version } = this[Getters.GET_CONDITION_PARAMETER_BY_ID](entityId);

            const {
                [ENTITY_TYPES.CHARGING_SPECIFICATION]: inUseByCs = [],
                [ENTITY_TYPES.USAGE_COUNTER]: inUseByUc = [],
                [ENTITY_TYPES.TARIFF_SPECIFICATION]: inUseByTs = [],
            } = this.upperEntities(entityId);

            this.showDeleteAlert(
                entityId,
                Boolean(inUseByCs.length || inUseByUc.length || inUseByTs.length),
                state,
                version,
                [updateStateConditionParameter, deleteConditionParameterDraft, this.initData],
            );
        },
        onReadOnly(entityId) {
            this.onReadOnlyAction(entityId, this.editorRoute);
        },
    },
};
</script>
<style lang="scss" scoped>
@import '../~@/assets/scss/palette';

.conditions-count {
    color: $gray30;
    font-size: 1rem;
    font-weight: normal;
    text-transform: uppercase;
}
</style>
