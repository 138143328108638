import i18n from '@/i18n';
import { LABEL_COLOR } from '@/common/labelsHelper';
import { capitalize } from 'lodash';
import { EVENT_CATEGORY, type EventCategoryCounters } from '@/__new__/services/dno/ossdevedge/models/QodMsisdnDno';
import type { EntityDetailsByName } from '@/__new__/services/dno/ossdevedge/models/QodMsisdnDno';

export enum FILE_UPLOAD_STATUS {
    SUCCESS = 1,
    PARTIAL_SUCCESS = 2,
    IN_PROGRESS = 3,
    FAILED = 4, // none of records succeeded
    UNKNOWN = 5, // we couldn't retrieve details from the DNO
    ERROR = 6, // indicates an error on the frontend or backend
}

export interface UploadedFileDetails {
    entityName: string;
    createdAt: number;
    lastUpdated: number;
    fileName?: string;
    bulkUploadId?: string;
    createdBy?: string;
    createdByPortalId?: number;
    recordCount?: number;
    fileUploadStatus?: FILE_UPLOAD_STATUS;
    fileUploadStatusStr?: string;
    eventCategoryCounters?: Record<string, number>;
    metadata: EntityDetailsByName['data']['metadata'];
}

export interface SignedUrlForDownloadEntity {
    signed_entity: {
        url: string;
        entity_id: string;
        required_request_headers?: object;
    };
}

export const fileUploadStatusToStr = (status: FILE_UPLOAD_STATUS): string => {
    switch (status) {
        case FILE_UPLOAD_STATUS.IN_PROGRESS:
            return i18n.t('generic.stateMap.inProgress').toString();
        case FILE_UPLOAD_STATUS.SUCCESS:
            return i18n.t('generic.stateMap.success').toString();
        case FILE_UPLOAD_STATUS.PARTIAL_SUCCESS:
            return i18n.t('generic.stateMap.partialSuccess').toString();
        case FILE_UPLOAD_STATUS.FAILED:
            return i18n.t('generic.stateMap.failed').toString();
        case FILE_UPLOAD_STATUS.UNKNOWN:
            return i18n.t('generic.stateMap.unknown').toString();
        case FILE_UPLOAD_STATUS.ERROR:
            return i18n.t('generic.stateMap.error').toString();
        default:
            return '';
    }
};

export const getAllFileUploadStatusStrings = (): string[] => {
    return [
        FILE_UPLOAD_STATUS.IN_PROGRESS,
        FILE_UPLOAD_STATUS.SUCCESS,
        FILE_UPLOAD_STATUS.PARTIAL_SUCCESS,
        FILE_UPLOAD_STATUS.FAILED,
        FILE_UPLOAD_STATUS.UNKNOWN,
        FILE_UPLOAD_STATUS.ERROR,
    ].map(status => fileUploadStatusToStr(status));
};

export const FILE_UPLOAD_STATUS_TO_COLOR_MAP: Map<string, string | undefined> = new Map([
    [fileUploadStatusToStr(FILE_UPLOAD_STATUS.IN_PROGRESS), LABEL_COLOR.blue],
    [fileUploadStatusToStr(FILE_UPLOAD_STATUS.SUCCESS), LABEL_COLOR.green],
    [fileUploadStatusToStr(FILE_UPLOAD_STATUS.PARTIAL_SUCCESS), LABEL_COLOR.yellow],
    [fileUploadStatusToStr(FILE_UPLOAD_STATUS.FAILED), LABEL_COLOR.red],
    [fileUploadStatusToStr(FILE_UPLOAD_STATUS.UNKNOWN), LABEL_COLOR.gray],
    [fileUploadStatusToStr(FILE_UPLOAD_STATUS.ERROR), LABEL_COLOR.red],
]);

/**
 * Determines a generalized status for the entire file based on the individual event counters
 * @param recordCount total number of records in the file
 * @param eventCategoryCounters counts for each individual event
 * @returns
 *      FILE_UPLOAD_STATUS.IN_PROGRESS (file is still being processed) if sum of all counts (in eventCategoryCounters) is less than recordCount
 *      FILE_UPLOAD_STATUS.SUCCESS if all the records were successfully processed
 *      FILE_UPLOAD_STATUS.PARTIAL_SUCCESS if there's atleast 1 success and the file is not currently being processed
 *      FILE_UPLOAD_STATUS.FAILED if there are no successful records and the file is not currently being processed
 */
export const getFileUploadStatus = (
    recordCount: number,
    eventCategoryCounters: EventCategoryCounters,
): FILE_UPLOAD_STATUS => {
    const successCount = eventCategoryCounters[EVENT_CATEGORY.SUCCESSFUL] ?? 0;
    const sumOfCounts = Object.values(eventCategoryCounters).reduce((sum, count) => sum + count, 0);

    // Check if in progress
    if (sumOfCounts < recordCount) {
        return FILE_UPLOAD_STATUS.IN_PROGRESS;
    } else if (sumOfCounts > recordCount) {
        return FILE_UPLOAD_STATUS.ERROR; // this shouldn't be possible and indicates a bug
    }

    // Check for success or partial success
    if (successCount === sumOfCounts) {
        return FILE_UPLOAD_STATUS.SUCCESS;
    } else if (successCount > 0 && successCount < sumOfCounts) {
        return FILE_UPLOAD_STATUS.PARTIAL_SUCCESS;
    }

    // Success count equals 0
    return FILE_UPLOAD_STATUS.FAILED;
};

export const formatEventCategory = (eventCategory: string): string => {
    // Look up event category in i18n file
    const key = `qodNumberManagement.eventCategory.${eventCategory}`;
    const value = i18n.t(key)?.toString();
    if (value && value !== key) {
        return value;
    }
    // Entry for `key` didn't exist in i18n file
    // Fallback to default formatting
    const failedPrefixRemoved = eventCategory.replace(/^failed_/g, '');
    const underscoresRemoved = failedPrefixRemoved.replace(/_/g, ' ');
    return capitalize(underscoresRemoved);
};
