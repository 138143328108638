import VoucherCode from '@/models/VoucherCode';

export default class VoucherCodeResponse {
    voucherCodes: VoucherCode[];
    pagingState: string | null;

    constructor({ voucherCodes = [], pagingState = null } = {}) {
        this.voucherCodes = voucherCodes;
        this.pagingState = pagingState;
    }
}
