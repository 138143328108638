const DaysOfWeekLabels = {
    1: 'Su',
    2: 'Mo',
    3: 'Tu',
    4: 'We',
    5: 'Th',
    6: 'Fr',
    7: 'Sa',
};

export const DaysOfWeekFromUI = {
    [DaysOfWeekLabels[1]]: 1,
    [DaysOfWeekLabels[2]]: 2,
    [DaysOfWeekLabels[3]]: 3,
    [DaysOfWeekLabels[4]]: 4,
    [DaysOfWeekLabels[5]]: 5,
    [DaysOfWeekLabels[6]]: 6,
    [DaysOfWeekLabels[7]]: 7,
};

export const DaysOfWeekNames = {
    1: 'Sunday',
    2: 'Monday',
    3: 'Tuesday',
    4: 'Wednesday',
    5: 'Thursday',
    6: 'Friday',
    7: 'Saturday',
};

export default DaysOfWeekLabels;
