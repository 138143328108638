import moment from 'moment';
import { cloneDeep } from 'lodash';
import UIComponentDefinition from '@/models/filters/uiComponents/UIComponentDefinition';
import { DateOperations, NumberOperations, PeriodOperations } from '@/common/filters/InputOperations';
import { PeriodUnitLabelToValues, PeriodUnits, PeriodUnitsLabels } from '@/common/filters/Units';
import { AbstractNumberInput } from '@/models/filters/uiComponents/InputDefinition';

export const ServerPeriodClasses = {
    Month: 'InMonth',
    Date: 'DateProperty',
    Period: 'TimeWithUnitAgo',
};

export default class PeriodInputDefinition extends UIComponentDefinition {
    defaultValue() {
        return {
            value: [1, 2],
            op: PeriodOperations.GreaterThan,
            unit: PeriodUnitsLabels[PeriodUnits.Day],

            // separate value fields for different input types
            dateValue: [new Date(), new Date()],
            monthValue: 0, // 0-based index of a month (0-11)
        };
    }

    valueFromJson(json) {
        if (!json) return null;

        let periodClass = json.value.property_type;
        const isNewFormat = !periodClass;

        if (isNewFormat) {
            periodClass = Object.keys(json.value)[0];
        }
        const resultedValue = isNewFormat ? json.value[periodClass] : json.value;

        // deserialize properly depending on class (date, period with unit, month index)
        if (periodClass === ServerPeriodClasses.Period) {
            const { unit } = resultedValue;

            const numberValueFromJson = new AbstractNumberInput().valueFromJson(resultedValue);

            return {
                ...this.defaultValue(),
                unit: PeriodUnitsLabels[unit],
                ...numberValueFromJson,
            };
        }
        if (periodClass === ServerPeriodClasses.Date) {
            const dateValueFromJson = PeriodInputDefinition.dateValueFromJson({
                value: resultedValue,
            });

            return {
                ...this.defaultValue(),
                op: dateValueFromJson.op,
                dateValue: dateValueFromJson.value,
            };
        }
        if (periodClass === ServerPeriodClasses.Month) {
            return {
                ...this.defaultValue(),
                op: PeriodOperations.InMonth,
                monthValue: resultedValue.value - 1, // - 1 because 1-based index on server
            };
        }

        // shouldn't happen
        return null;
    }

    buildJson(value) {
        if (!value) return null;

        const [periodClass, jsonValue] = this.getValueJson(value);

        return { [periodClass]: jsonValue };
    }

    /**
     * Converts 'value' to proper format depending on it's type
     * (date, period with unit, month index)
     * @return {[string, Object]} periodClass, jsonValue
     */
    getValueJson(value) {
        const periodClass = PeriodInputDefinition.getPeriodType(value);

        if (periodClass === ServerPeriodClasses.Period) {
            const numberJsonValue = new AbstractNumberInput().buildJson(value);
            const jsonValue = {
                value: numberJsonValue,
                unit: PeriodUnitLabelToValues[value.unit],
            };

            return [ServerPeriodClasses.Period, jsonValue];
        }
        if (periodClass === ServerPeriodClasses.Date) {
            const jsonValue = PeriodInputDefinition.dateBuildJson({
                ...value,
                value: value.dateValue,
            });

            return [ServerPeriodClasses.Date, jsonValue];
        }
        if (periodClass === ServerPeriodClasses.Month) {
            const jsonValue = { value: value.monthValue + 1 }; // + 1 because 1-based index on server

            return [ServerPeriodClasses.Month, jsonValue];
        }

        // shouldn't happen
        return ['', {}];
    }

    /** Returns period type depending on selected value (one of `ServerPeriodClasses`) */
    static getPeriodType(value) {
        if (Object.values(NumberOperations).includes(value.op)) {
            return ServerPeriodClasses.Period;
        }
        if (Object.values(DateOperations).includes(value.op)) {
            return ServerPeriodClasses.Date;
        }
        if (PeriodOperations.InMonth === value.op) {
            return ServerPeriodClasses.Month;
        }

        // shouldn't happen
        return '';
    }

    static dateValueFromJson(jsonValue) {
        const jsonValueCopy = cloneDeep(jsonValue) && cloneDeep(jsonValue.value);
        let operation = DateOperations.After;
        if (jsonValueCopy.value[0] && jsonValueCopy.value[1]) {
            operation = DateOperations.Between;
        } else if (!jsonValueCopy.value[0] && jsonValueCopy.value[1]) {
            // reverse if Before: [null, date1] => [date1, null]
            jsonValueCopy.value.reverse();
            operation = DateOperations.Before;
        }

        const firstTimestamp = moment.unix(jsonValueCopy.value[0]).toDate();
        const secondTimestamp = jsonValueCopy.value[1]
            ? moment.unix(jsonValueCopy.value[1]).toDate()
            : moment(firstTimestamp).toDate();
        return {
            value: [firstTimestamp, secondTimestamp],
            op: operation,
        };
    }

    static dateBuildJson(rawValue) {
        const unixTimestamps = rawValue.value.map(date => date && moment(date).unix());

        // reverse if Before: [date1, null] => [null, date1]
        if (rawValue.op === DateOperations.Before) {
            unixTimestamps.reverse();
            unixTimestamps[0] = null;
        } else if (rawValue.op === DateOperations.After) {
            unixTimestamps[1] = null;
        }

        return { value: unixTimestamps };
    }
}
