import { DnoResponseBase } from '@/http/index';

export type DataflowTemplate = {
    id: string;
    name: string;
    description: string;
};

export interface DataflowTemplatesApiResponse extends DnoResponseBase {
    data: DataflowTemplate[];
}

export interface DataflowValidationApiResponse extends DnoResponseBase {
    data: {
        title: string;
        actions: string[];
    };
}

export type DataflowTemplateUiModel = {
    name: string;
    ui_model: DataflowSection[];
};

export interface DataflowTemplateUiModelApiResponse extends DnoResponseBase {
    data: DataflowTemplateUiModel;
}

export type DataflowBaseData = {
    id: string;
    required: boolean;
    label: string;
    description?: string;
};

export interface DataflowSection extends DataflowBaseData {
    elements: SectionElement[];
    type: SECTION_TYPES;
    error: boolean;
    collapsed?: boolean;
}

export enum SECTION_TYPES {
    SELECTION_INPUT = 'SelectionInput',
    SECTION = 'Section',
    TEXT_INPUT = 'TextInput',
    NUMBER_INPUT = 'NumberInput',
}

export type ElementValue = {
    label: string;
    value: string;
};

export type ElementRegex = {
    expression: string;
    message: string;
};

export type Element = {
    id: string;
    required: boolean;
    label: string;
    description?: string;
    type: SECTION_TYPES;
    value?: string | ElementValue;
    values?: ElementValue[];
    regex?: ElementRegex;
    error: boolean;
};

export interface NestedSectionElement extends DataflowBaseData {
    elements: Element[];
    type: SECTION_TYPES;
}

export interface SectionElement extends DataflowBaseData {
    type: SECTION_TYPES;
    values?: ElementValue[];
    value?: string | ElementValue;
    elements: Element[] | NestedSectionElement[];
    error: boolean;
}
