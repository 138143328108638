<template>
    <div class="d-flex flex-grow-1 delivery-campaign-timeline flex-column">
        <div class="header message w-100 flex-column align-items-start">
            <div class="font-weight-bold d-flex justify-content-between align-items-center w-100 header-label">
                <span>CAMPAIGN LAUNCH</span>
                <span>
                    {{ formatDate(campaignStartTime) }}
                    {{ campaignEndTime ? ` - ${formatDate(campaignEndTime)}` : '' }}
                </span>
            </div>
            <div class="delivery-type d-flex align-items-center justify-content-between w-100">
                <!-- eslint-disable vue/no-v-html -->
                <span
                    v-if="delivery"
                    :key="'delivery'"
                    v-html="delivery"
                />
                <span
                    v-else
                    :key="'no-delivery'"
                >
                    No delivery type selected
                </span>
                <span v-show="campaignStartTime">Starts at {{ campaignStartTime | timeWithOffsets }}</span>
            </div>
        </div>

        <div
            v-for="(message, index) in messagesByDelay"
            :key="message.uniqueId"
            class="w-100 mt-2 message-container"
        >
            <div :class="['message', { expanded: expandState[message.uniqueId] }]">
                <div class="d-flex justify-content-between">
                    <div class="d-flex flex-column">
                        <div class="flex-grow-1 d-flex flex-column overflow-hidden pr-3">
                            <div
                                class="delay d-none"
                                @click="expand(message.uniqueId)"
                            >
                                {{ formatDelayTextForMessage(message) }}
                            </div>
                        </div>

                        <div class="d-flex types-container">
                            <span
                                v-for="(type, i) in selectedMessageTypes[index]"
                                :key="i"
                                :class="['icon mr-1 d-inline-block', `${type}-gray`]"
                            />
                            <div :class="['name', { placeholder: !message.name }]">
                                {{ message.name || `${$i18n.t('campaigns.message')} ${index + 1}` }}
                            </div>
                        </div>
                    </div>

                    <div class="d-flex h-100 placeholder pl-3 text-center align-items-center">
                        {{ messageDeliveryTimes[index] }}
                    </div>
                </div>

                <template v-if="message.title">
                    <span class="ml-4 pl-1">{{ $i18n.t('generic.title') }}</span>
                    <pre class="mb-3 message-text">{{ message.title }}</pre>
                </template>

                <span class="ml-4 pl-1">{{ $i18n.t('campaigns.message') }}</span>
                <pre class="mb-3 message-text">{{ message.text }}</pre>

                <template v-if="message.enhancedViewTitle || message.enhancedViewMessage">
                    <span class="ml-4 pl-1">{{ $i18n.t('campaigns.inboxTitle') }}</span>
                    <pre class="mb-3 message-text">{{ message.enhancedViewTitle }}</pre>
                    <span class="ml-4 pl-1">{{ $i18n.t('campaigns.inboxMessage') }}</span>
                    <pre class="message-text">{{ message.enhancedViewMessage }}</pre>
                </template>
                <template v-if="message.enhancedViewMessageOtt">
                    <span class="ml-4 pl-1">{{ $i18n.t('campaigns.inboxMessage') }}</span>
                    <pre class="mb-3 message-text">{{ message.enhancedViewMessageOtt }}</pre>
                    <span class="ml-4 pl-1">{{ $i18n.t('campaigns.inboxMessageExpiry') }}</span>
                    <pre class="message-text"
                        >{{
                            message.enhancedOttExpiryTimeDays === null
                                ? ExpiryLabel.NoExpiry
                                : message.enhancedOttExpiryTimeDays + ' ' + $i18n.t('generic.days')
                        }}
                    </pre>
                </template>
            </div>

            <div
                v-show="expandState[message.uniqueId]"
                class="delay-panel align-items-center justify-content-between"
            >
                <div class="d-flex align-items-center">
                    Delay
                    <CustomInput
                        :value="messageDelays[message.uniqueId] && messageDelays[message.uniqueId].amount"
                        class="mx-2 day-input"
                        @input="amount => updateMessageDelayAmount(message.uniqueId, amount)"
                    />

                    <Dropdown
                        :items="delayChronoUnits"
                        :selected="messageDelays[message.uniqueId] && [messageDelays[message.uniqueId].unit]"
                        class="d-inline-block day-period-dropdown dropdown mr-2"
                        @selected="unit => updateMessageDelayUnit(message.uniqueId, unit)"
                    />

                    <AppButton
                        :buttonType="BUTTON_TYPES.SECONDARY"
                        label="SET"
                        class="add-delay"
                        @click="addDelay(message.uniqueId)"
                    />
                </div>

                <div
                    class="close-delay"
                    @click="collapse(message.uniqueId)"
                />
            </div>
        </div>
    </div>
</template>

<script>
import Vue from 'vue';
import moment from 'moment';
import mapValues from 'lodash/mapValues';
import sortBy from 'lodash/sortBy';
import keys from 'lodash/keys';
import keyBy from 'lodash/keyBy';
import CustomInput from '@/__new__/features/campaigns/CustomInput.vue';
import Dropdown from '@/components/partials/Dropdown.vue';
import Delay, { ChronoUnit, ChronoUnitLabels } from '@/__new__/services/dno/campaigns/models/Delay';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import { DeliveryTypeNames } from '@/__new__/services/dno/campaigns/models/Campaign';
import { RepeatTypes, RepeatTypesUnit } from '@/__new__/services/dno/campaigns/models/Repeat';
import { constructTimeString } from '@/common/formatting';
import filters from '@/common/filters';
import { DaysOfWeekNames } from '@/__new__/features/campaigns/common/DaysOfWeek';
import localeLibrary from '@/common/locale/localeLibrary';
import { ExpiryLabel } from '@/__new__/services/dno/campaigns/models/Message';

export default {
    name: 'DeliveryCampaignTimeline',
    components: {
        AppButton,
        Dropdown,
        CustomInput,
    },
    filters,
    props: {
        messages: { type: Array, default: () => [] }, // Array<Array<Message>>
        delivery: { type: String, default: '' },
        campaignStartTime: { type: Number, default: 0 },
        campaignEndTime: { type: Number, default: 0 },
        editableDelay: { type: Boolean, default: true },
        campaign: { type: Object, default: () => ({}) },
    },
    data() {
        return {
            ChronoUnit,
            ExpiryLabel,
            BUTTON_TYPES,
        };
    },
    computed: {
        // flatten messages array by using first one,
        // because msgs have common fields except type
        firstMessages() {
            return this.messages.length ? this.messages.map(msgs => msgs[0]) : [];
        },
        selectedMessageTypes() {
            return this.messages.map(msgs => msgs.map(msg => msg.type));
        },
        messagesByDelay() {
            return sortBy(this.firstMessages, [msg => msg.delaySeconds()]);
        },
        delayChronoUnits() {
            return [ChronoUnit.day, ChronoUnit.hour];
        },
        messageDelays() {
            return mapValues(
                keyBy(this.firstMessages, msg => msg.uniqueId),
                msg => msg.delay || {},
            );
        },
        expandState() {
            return mapValues(
                keyBy(this.firstMessages, msg => msg.uniqueId),
                () => false,
            );
        },
        messageDeliveryTimes() {
            if (!this.campaign) return [];

            return this.firstMessages.map(() => {
                if (this.campaign.deliveryType.type === DeliveryTypeNames.Scheduled) {
                    if (this.campaign.deliveryType.repeat) {
                        // scheduled recurring
                        let every =
                            this.campaign.deliveryType.repeat.every != null
                                ? this.campaign.deliveryType.repeat.every
                                : '';

                        const unit = RepeatTypesUnit[this.campaign.deliveryType.repeat.type];
                        const everyUnit = filters.plural(unit, every || 1);

                        const atString = `at ${constructTimeString(
                            this.campaign.deliveryType.repeat.hours,
                            this.campaign.deliveryType.repeat.minutes,
                        )}`;

                        if (this.campaign.deliveryType.repeat.type === RepeatTypes.weekly) {
                            every = `${DaysOfWeekNames[this.campaign.deliveryType.repeat.dayOfWeek]} every ${every}`;
                        } else if (this.campaign.deliveryType.repeat.type === RepeatTypes.monthly) {
                            every = `${filters.ordinal(this.campaign.deliveryType.repeat.dayOfMonth)} of the`;
                        }

                        return `Every ${every} ${everyUnit} ${atString}`;
                    }
                } else if (this.campaign.deliveryType.type === DeliveryTypeNames.ActionBased) {
                    if (this.campaign.deliveryType.delay) {
                        // action based delayed
                        const unit = ChronoUnitLabels[this.campaign.deliveryType.delay.unit];
                        const unitString = filters.plural(unit, this.campaign.deliveryType.delay.amount);

                        return `${this.campaign.deliveryType.delay.amount} ${unitString} after trigger is produced`;
                    }
                    if (this.campaign.deliveryType.immediate) {
                        // action based immediately
                        return 'Immediately when trigger is produced';
                    }
                }

                return null;
            });
        },
    },
    methods: {
        expand(uniqueId) {
            // TODO delay is not available for now
            // eslint-disable-next-line no-constant-condition
            if (0 && this.editableDelay) {
                // collapse all
                keys(this.expandState).forEach(msgUniqueId => {
                    Vue.set(this.expandState, msgUniqueId, false);
                });
                Vue.set(this.expandState, uniqueId, true);
            }
        },
        formatDelayTextForMessage(message) {
            return (
                (message.delay && message.delay.toString()) ||
                ((this.expandState[message.uniqueId] || !this.editableDelay) && '-') ||
                this.$i18n.t('campaigns.addDelay')
            );
        },
        collapse(uniqueId) {
            Vue.set(this.expandState, uniqueId, false);
        },
        formatDate(date) {
            return date ? filters.asLocal(moment(date * 1000)).format(localeLibrary.getDateFormat()) : '';
        },
        updateMessageDelayAmount(uniqueId, amount) {
            const delay = this.messageDelays[uniqueId];
            Vue.set(
                this.messageDelays,
                uniqueId,
                new Delay(parseInt(amount, 10), (delay && delay.unit) || ChronoUnit.day),
            );
        },
        updateMessageDelayUnit(uniqueId, unit) {
            const delay = this.messageDelays[uniqueId];
            Vue.set(this.messageDelays, uniqueId, new Delay((delay && delay.amount) || 1, unit));
        },
        addDelay(uniqueId) {
            this.collapse(uniqueId);
            this.$emit('updateMessageDelay', uniqueId, this.messageDelays[uniqueId]);
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/_icons';
@import '~@/assets/scss/_palette';
@import '~@/assets/scss/_mixins';
@import '~@/assets/scss/_z-indexes';

$icon-path: '~@/assets/icons/';

.delivery-campaign-timeline {
    z-index: $zero-z-index;
    position: relative;
    color: $gray60;
    flex: 0 0 100%;

    .message {
        padding: 0.5rem;

        background-color: $white;
        border-radius: 4px;
        border: 1px solid $gray5;

        @include messageIcons($icon-path, 24px);

        &.header {
            height: auto;
            padding-left: 19px;
            border-color: $blue15;
            background-color: $blue15;

            .header-label {
                font-size: 14px;
                line-height: 19px;
            }

            .delivery-type {
                font-size: 12px;
            }
        }

        &.expanded {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        }

        .types-container {
            height: 24px;

            span.icon {
                width: 24px;
                height: 24px;
            }
        }

        .name.placeholder {
            color: $gray30;
        }

        .message-text {
            margin: 0 0 0 29px;
            max-height: 114px;
            white-space: pre-wrap;
            overflow-wrap: anywhere;
            font-size: 13px;
            color: $gray30;
        }

        .delay {
            cursor: pointer;
            font-size: 12px;
            color: $orange;
        }

        .placeholder {
            color: $gray30;
        }
    }

    .delay-panel {
        display: flex;
        height: 60px;
        padding: 0 16px;
        z-index: $overlap-smth-z-index;

        background-color: $white;
        border: 1px solid $gray5;
        border-top: 0;
        border-radius: 0 0 4px 4px;

        .day-input {
            width: 48px;
        }

        .add-delay {
            padding-left: 10px;
            padding-right: 10px;
        }

        .close-delay {
            content: url($icon-path + $icon-close);
            cursor: pointer;
        }

        .dropdown {
            @include custom-width-dropdown(140px);
            @include custom-height-dropdown(32px);
        }
    }
}
</style>
