import UserManagerBaseModel from '@/__new__/services/dno/user/models/UserManagerBaseModel';
import { isEmpty, isUndefined } from 'lodash';
import i18n from '@/i18n';

export default class Delivery extends UserManagerBaseModel {
    deliveryId;
    addressId;
    status;
    orderId;
    trackingId;
    deliveryPartnerResponse;
    recipientEmail;
    recipientFullName;
    recipientPhone;
    address;
    accessCodes;
    reservationId;
    createdAt;

    constructor({
        deliveryId,
        addressId,
        status,
        orderId,
        trackingId,
        deliveryPartnerResponse,
        recipientEmail,
        recipientFullName,
        recipientPhone,
        address,
        accessCodes,
        reservationId,
        createdAt,
    }) {
        // Call constructor of UserManagerBaseModel class for creating new object with unique invalidKeys Map
        super();
        this.deliveryId = this.validateString('deliveryId', deliveryId) ? deliveryId : '';
        this.addressId = this.validateString('addressId', addressId) ? addressId : '';
        this.orderId = this.validateString('orderId', orderId) ? orderId : '';
        this.trackingId = this.validateString('trackingId', trackingId) ? trackingId : '';
        this.recipientEmail = this.validateString('recipientEmail', recipientEmail) ? recipientEmail : '';
        this.recipientFullName = this.validateString('recipientFullName', recipientFullName) ? recipientFullName : '';
        this.recipientPhone = this.validateString('recipientPhone', recipientPhone) ? recipientPhone : '';
        this.reservationId = this.validateString('reservationId', reservationId) ? reservationId : '';
        this.deliveryPartnerResponse = deliveryPartnerResponse || {};
        this.address = address || {};
        this.accessCodes = accessCodes || {};
        this.status = this.validateNumberInteger('status', status) ? status : '';
        this.createdAt = createdAt || 0;
    }

    static addressTypeMap(type) {
        const mapping = {
            1: i18n.t('generic.address'),
            2: i18n.t('customerCare.addressMapper.billing'),
            3: i18n.t('customerCare.addressMapper.shipping'),
            4: i18n.t('customerCare.addressMapper.service'),
            5: i18n.t('customerCare.addressMapper.permanent'),
            6: i18n.t('customerCare.addressMapper.mailing'),
        };
        return mapping[type];
    }

    static mapAddresses(address) {
        if (address && !isEmpty(address)) {
            return {
                /* eslint-disable camelcase */
                address: address?.address || '',
                addressLineTwo: address?.address_line_2 || '',
                zip: address?.zip || '',
                postalCode: address?.postal_code || '',
                city: address?.city || '',
                taxAreaId: address?.tax_area_id || '',
                county: address?.county || '',
                state: address?.state || '',
                type: address?.type ? Delivery.addressTypeMap(address.type) : '',
                latitude: address?.latitude || '',
                longitude: address?.longitude || '',
                houseNumber: Object.prototype.hasOwnProperty.call(address, 'house_number') ? address.house_number : '',
                postCode: Object.prototype.hasOwnProperty.call(address, 'post_code') ? address.post_code : '',
                firstName: Object.prototype.hasOwnProperty.call(address, 'first_name') ? address.first_name : '',
                lastName: Object.prototype.hasOwnProperty.call(address, 'last_name') ? address.last_name : '',
                phoneNumber: Object.prototype.hasOwnProperty.call(address, 'phone_number') ? address.phone_number : '',
                post: Object.prototype.hasOwnProperty.call(address, 'post') ? address.post : '',
            };
        }
        return {};
    }

    static remapDeliveryFromBe(delivery) {
        return {
            /* eslint-disable camelcase */
            deliveryId: delivery?.delivery_id || '',
            addressId: delivery?.address_id || '',
            trackingId: delivery?.tracking_id || '',
            orderId: delivery?.order_id || '',
            deliveryPartnerResponse: delivery?.delivery_partner_response || {},
            status: !isUndefined(delivery.delivery_status) ? delivery.delivery_status : null,
            recipientEmail: delivery?.delivery_info?.recipient_email || '',
            recipientFullName: delivery?.delivery_info?.recipient_fullname || '',
            recipientPhone: delivery?.delivery_info?.recipient_phone || '',
            address: delivery?.address ? Delivery.mapAddresses(delivery.address) : {},
            accessCodes: delivery?.access_codes || {},
            reservationId: delivery?.reservation_id || '',
            createdAt: delivery?.created_at || 0,
        };
    }
}
