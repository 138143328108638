import type { EntityUploadStatus } from '@/__new__/services/dno/progressTracker/models/entity';
import type { EventCategoryCounters } from '@/__new__/services/dno/ossdevedge/models/QodMsisdnDno';

export enum DATAFLOW_API_TYPE {
    NUMBER_BULK = 'number_bulk_import',
    SIM_BULK = 'sim_profile_bulk_import',
    WPS_BULK = 'wps_bulk_import',
    // DeveloperLineAuthorization.vue
    QOD = 'qod_msisdn_bulk_import_flow',
    DEVICE_STATUS = 'device_status_bulk_import_flow',
    SIM_SWAP = 'sim_swap_bulk_import_flow',
    LOCATION_VERIFICATION = 'location_verification_bulk_import_flow',
    SD_WAN = 'sd_wan_bulk_import_flow',
    NETWORK_AUTHENTICATION = 'network_authentication_bulk_import_flow',
    CONSENT_PROFILE_BULK = 'privacy_consent_bulk_import',
    BULK_PRE_ACTIVATION_INPUT_FILE = 'bpa_phase_i_input_file',
    BULK_PRE_ACTIVATION_GOOD_FILE = 'bpa_phase_ii_good_file',
    BULK_PRE_ACTIVATION_WHITELIST_OFFER = 'bpa_phase_iii_whitelist_offer',
    MANUAL_CREATE_ORDER_UPLOAD = 'manual_seed_import',
}

/**
 * Payload returned by `getRegisteredEntities`
 */
export interface RegisteredEntities {
    data: {
        total_pages: number;
        total_elements: number;
        page_number: number;
        size: number;
        items: RegisteredEntityItem[];
    };
}

/**
 * Structure of single item inside payload from `getRegisteredEntities`
 */
export interface RegisteredEntityItem {
    entity_name: string;
    created_at: number;
    last_updated: number;
}

/**
 * Payload returned by `getEntityDetailsByName`
 */
export interface EntityDetailsByName {
    data: {
        expected_count: number;
        started_processing_at: number;
        last_updated_at: number;
        event_category_counters: EventCategoryCounters;
        status: EntityUploadStatus;
        metadata?: {
            uploaded_by: string | null;
            principal_id: string | null;
            registered_file_location: string | null;
            processed_file_location: string | null;
            context_metadata: Record<string, any> | null;
        };
    };
}
