import { render, staticRenderFns } from "./GrantPermissionModal.vue?vue&type=template&id=9241d442&scoped=true"
import script from "./GrantPermissionModal.vue?vue&type=script&lang=ts"
export * from "./GrantPermissionModal.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9241d442",
  null
  
)

export default component.exports