<template>
    <DuplicateSlot
        v-model="inputValues"
        :addButtonLabel="addButtonLabel"
        :enableDeleteButton="true"
        :addStringOnButtonClick="true"
        deleteIconWithoutOffset
        :optional="optional"
        :label="label"
        :customDataStructureOnAdd="customDataStructureOnAdd"
        :disabled="disabled"
        :explanation="explanation"
    >
        <template slot-scope="{ slotNumber }">
            <div
                :class="['mb-2', 'row', 'input-margin', { 'input-width': shouldSetWidth }, { 'mb-4': shouldSetMargin }]"
            >
                <AppInputV3
                    v-if="!isObjectValues"
                    v-model.trim="inputValues[slotNumber]"
                    :placeholder="placeholder"
                    :invalid="isInvalid(slotNumber)"
                    :type="types"
                    :min="minimums"
                    :max="maximums"
                    :class="{ width: shouldSetWidth }"
                    :disablePadding="true"
                    :disabled="disabled"
                />
                <template v-if="isObjectValues">
                    <AppInputV3
                        v-model.trim="inputValues[slotNumber].key"
                        :placeholder="keyPlaceholder"
                        :invalid="isInvalid(slotNumber)"
                        :class="{ width: shouldSetWidth }"
                        :disablePadding="true"
                        :disabled="disabled"
                        class="ml-2"
                    />
                    <AppInputV3
                        v-model.trim="inputValues[slotNumber].value"
                        :placeholder="valuePlaceholder"
                        :invalid="isInvalid(slotNumber)"
                        :type="types"
                        :min="minimums"
                        :max="maximums"
                        :class="{ width: shouldSetWidth }"
                        :disablePadding="true"
                        :disabled="disabled"
                        class="ml-2"
                    />
                </template>
            </div>
        </template>
    </DuplicateSlot>
</template>

<script>
import AppInputV3 from '@/components/partials/inputs/AppInputV3.vue';
import DuplicateSlot from '@/components/partials/DuplicateSlot.vue';
import { validateUniqValues } from '@/common/formatting';

export default {
    name: 'ValueInputs',

    components: {
        AppInputV3,
        DuplicateSlot,
    },

    props: {
        value: {
            type: Array,
            default: () => [],
        },
        initInputValues: {
            type: [Array, Object],
            default: () => ({ key: '', value: '' }),
        },
        addButtonLabel: {
            type: String,
            default: '',
        },
        placeholder: {
            type: String,
            default: '',
        },
        showInputErrors: {
            type: Boolean,
            default: false,
        },
        isCopyInitInputValues: {
            type: Boolean,
            default: false,
        },
        initInvalidIndices: {
            type: Array,
            default: () => [],
        },
        isAllInvalid: {
            type: Boolean,
            default: false,
        },
        optional: {
            type: Boolean,
            default: false,
        },
        label: {
            type: String,
            default: '',
        },
        types: {
            type: String,
            default: 'text',
        },
        minimums: {
            type: Number,
            default: 0,
        },
        maximums: {
            type: Number,
            default: 100000000,
        },
        shouldSetWidth: {
            type: Boolean,
            default: false,
        },
        shouldSetMargin: {
            type: Boolean,
            default: false,
        },
        allowEmptyValues: {
            type: Boolean,
            default: false,
        },
        isObjectValues: {
            type: Boolean,
            default: false,
        },
        keyPlaceholder: {
            type: String,
            default: '',
        },
        valuePlaceholder: {
            type: String,
            default: '',
        },
        customDataStructureOnAdd: {
            type: Object,
            default: null,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        explanation: {
            type: String,
            default: '',
        },
    },

    data() {
        return {
            inputValues: [],
            invalidIndices: [],
            showErrors: this.showInputErrors,
        };
    },

    watch: {
        inputValues: {
            handler() {
                this.validateInputs();
            },
            // deep: true,
            // immediate: true,
        },
        initInputValues: {
            handler(newVal, oldVal) {
                if (newVal && newVal.length) {
                    this.inputValues = this.isCopyInitInputValues ? [...newVal] : newVal;
                } else if (!oldVal) {
                    this.inputValues = [this.customDataStructureOnAdd] || [''];
                }
            },
            immediate: true,
        },
        showInputErrors(newVal) {
            this.showErrors = newVal;
        },
    },
    methods: {
        isInvalid(slotNum) {
            return this.showErrors && (this.isAllInvalid || this.initInvalidIndices.includes(slotNum));
        },
        validateInputs() {
            // In the case that we only have a single row where both the key and value are
            // falsy values, we consider it to be empty.

            const lastRow = this.inputValues[this.inputValues.length - 1];
            const result = this.inputValues.length === 1 && !lastRow ? [] : this.inputValues;

            const invalidIndices = [];
            this.invalidIndices = validateUniqValues(result, invalidIndices, this.allowEmptyValues);

            // Validate that keys are valid identifiers. It's possible that an index will
            // show up twice in `this.invalidIndex`, but that should be ok.
            //
            result.forEach((kvp, index) => {
                if (!/^\w+$/.test(kvp.key)) {
                    this.invalidIndices.push(index);
                }
            });

            this.$emit('input', result);
        },
    },
};
</script>

<style lang="scss" scoped>
.width {
    width: 100%;
}

.input-margin {
    margin-left: 4px;
}

.input-width {
    width: 90%;
}
</style>
