
import Vue, { type PropType } from 'vue';
// components
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import AppIcon from '@/components/partials/icon/AppIcon.vue';
import AppInputV3 from '@/components/partials/inputs/AppInputV3.vue';
import AppMultiselectV3 from '@/components/partials/inputs/AppMultiselectV3.vue';
import AppTextareaV3 from '@/components/partials/inputs/AppTextareaV3.vue';
import AppToggle from '@/components/partials/inputs/AppToggle.vue';
import AppTooltip from '@/components/partials/AppTooltip.vue';
import SinkConfigColumnRow from '@/__new__/features/sinkConfigs/SinkConfigColumnRow.vue';
// helpers
import { TOOLTIP_POSITION } from '@/common/tooltip';
import entityEditorMixin from '@/common/entityEditorMixin';
import { ICON_COLORS, ICON_TYPES } from '@/common/iconHelper';
import { CDP_PROPERTY_TYPE } from '@/__new__/services/dno/events/models/EventProp';
// models
import {
    calculateColumnType,
    configColumnPropertiesHaveTheSameType,
    SinkConfigCol,
    SinkConfigRowValue,
} from '@/__new__/services/dno/sinkConfigs/models/SinkConfigCol';
import { SinkFormatter } from '@/__new__/services/dno/sinkConfigs/models/SinkFormatter';
import { Event } from '@/__new__/services/dno/events/models/Event';
import { SinkTypeCaster } from '@/__new__/services/dno/sinkConfigs/models/SinkTypeCasters';
import { isEmpty, last, uniq, isEqual } from 'lodash';

export default Vue.extend({
    name: 'SinkConfigColumn',
    components: {
        AppButton,
        AppIcon,
        AppInputV3,
        AppMultiselectV3,
        AppToggle,
        AppTooltip,
        AppTextareaV3,
        SinkConfigColumnRow,
    },
    mixins: [entityEditorMixin],
    model: {
        prop: 'columnData',
    },
    props: {
        availableTypeCasters: {
            type: [Array, null] as PropType<SinkTypeCaster[] | null>,
            default: null,
        },
        showSumLatest: {
            type: Boolean,
            required: false,
            default: false,
        },
        columnData: {
            type: Object as PropType<SinkConfigCol>,
            default: () => new SinkConfigCol(),
        },
        eventOptions: {
            type: Array as PropType<Event[]>,
            default: () => [],
        },
        timezoneLabel: {
            type: String,
            default: '',
        },
        defaultValueTypes: {
            type: Array,
            default: () => [],
        },
        availableFormatterNames: {
            type: Array,
            default: () => [],
        },
        fieldNameFormattingFunction: {
            type: Function,
            default: (str: string) => str,
        },
        uiLabels: {
            type: Object,
            default: () => undefined,
        },
        isDraftState: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            ICON_COLORS,
            ICON_TYPES,
            BUTTON_TYPES,
            TOOLTIP_POSITION,
        };
    },

    computed: {
        shouldDisableAddPropertyBtn(): boolean {
            const eventNames = this.eventOptions.map(eo => eo.name).sort();
            const selectedEvents = uniq(this.columnData.rowValues?.map(rw => rw.eventName)).sort();
            const allEventsAreUsed = isEqual(eventNames, selectedEvents);

            return (this.eventOptions.length === 1 && !!this.columnData.rowValues.length) || allEventsAreUsed;
        },
        shouldHideTypeCasters() {
            return !this.availableTypeCasters;
        },
        shouldShowTypeCasterToggleBtn() {
            if (isEmpty(this.availableTypeCasters)) return false;
            return !this.availableTypeCasters[0].isRequired;
        },
        typeCastOptions() {
            return this.availableTypeCasters || [];
        },
        shouldShowSumToggler(): boolean {
            const columnType = this.columnData.formatter?.conversionType || this.columnData.defaultValueType;
            return (
                this.showSumLatest &&
                [
                    CDP_PROPERTY_TYPE.INTEGER,
                    CDP_PROPERTY_TYPE.NUMBER,
                    CDP_PROPERTY_TYPE.INT4,
                    CDP_PROPERTY_TYPE.INT8,
                    CDP_PROPERTY_TYPE.FLOAT4,
                    CDP_PROPERTY_TYPE.FLOAT8,
                ].includes(columnType)
            );
        },
        shouldDisableNameFieldEdit(): boolean {
            if (this.isDraftState || this.isCloning) return false;
            return this.columnData.isRequiredField || this.columnData.forbidFieldNameChange;
        },
        columnValueConfigurationTitle(): string {
            return this.uiLabels.secondaryTitle || this.$t('sinkConfigs.columnValueConfiguration');
        },
        shouldShowTimezoneLabel(): boolean {
            const isZoneFormatter = last(this.columnData.formatter?.value) === 'Z';
            return !!this.timezoneLabel && isZoneFormatter;
        },
        shouldShowFormattersSection(): boolean {
            if (!configColumnPropertiesHaveTheSameType(this.columnData)) {
                return false;
            }
            const formatterIsAvailable =
                this.availableFormatterNames.length &&
                this.columnData.rowValues.length &&
                !this.columnData.isRequiredField;
            return formatterIsAvailable || !!this.columnData.formatter;
        },

        shouldShowConversionWarningMsg(): boolean {
            return (
                !!this.columnData.typeCaster ||
                (!!this.columnData.formatter?.conversionType &&
                    this.columnData.defaultValueType !== this.columnData.formatter.conversionType)
            );
        },
        eventNameOptions(): Array<Event['name']> {
            return this.eventOptions.map((event: Event) => event.name);
        },

        chosenFormatterName(): SinkFormatter['name'] | undefined {
            return this.columnData?.formatter?.name;
        },
    },

    methods: {
        calculateColumnType,
        isEmpty,
        shouldDisableDefaultTypeDropdown(columnData: SinkConfigCol): boolean {
            const rowLabels = columnData.rowValues.map(row => row.jsonPath?.type);
            const rowsHaveTheSameType = new Set(rowLabels).size === 1;
            return !columnData.isDefaultValueEnabled || rowsHaveTheSameType;
        },
        eventPropertiesOptions(row: SinkConfigRowValue): Event['properties'] | never[] {
            const selectedEvent = this.eventOptions.find(event => event.name === row.eventName);
            return selectedEvent?.properties || [];
        },
        onRowDelete(index: number): void {
            this.$emit('deleteRow', index);
        },
        onjsonPathToggle(index: number): void {
            this.$emit('jsonPathToggle', index);
        },
        addRow(): void {
            this.$emit('addRow');
        },
    },
});
