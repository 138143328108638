import Vue from 'vue';
import keyBy from 'lodash/keyBy';
import Actions, { Mutations, State, Getters } from '@/store/mutation-types';
import { getTemplates, getAllTemplates } from '@/__new__/services/dno/privacyConsent/http/templates';
import { getEntities, getEntityDrafts } from '@/__new__/services/dno/privacyConsent/entities';
import { CommonTemplateEntityTemplate } from '@/__new__/services/dno/template/models/CommonTemplateEntityTemplate';
import { TEMPLATE_STATES_NUMERIC } from '@/__new__/services/dno/template/models/templateStates';
import { isEmpty } from 'lodash';
import { TEMPLATE_TYPES_MAP } from '@/__new__/services/dno/template/models/templateTypes';
import { ENTITIES_TYPES_TO_MODEL_MAP } from '@/__new__/services/dno/privacyConsent/entitiesTypesToModel';
import { EntityStateMapping } from '@/common/commonEntityStateMapper';
import { checkForUnpublishedChanges, STATUS_CODES } from '@/common/commonHelper';
import { CommonTemplateEntityBaseModel } from '@/__new__/services/dno/template/models/CommonTemplateEntityBaseModel';

const privacyStore = {
    namespaced: true,
    state: () => ({
        // map, where key is Template ID and value is TEMPLATE data
        [State.PRIVACY_TEMPLATES_BY_IDS]: {},
        // map, where key is entity type and value is TEMPLATES Map whih key is entity ID and value is TEMPLATE data
        [State.PRIVACY_TEMPLATES_BY_TYPE]: {},
        [State.PRIVACY_TEMPLATES_API_RESPONSE_BY_TYPE]: {},
        [State.PRIVACY_ALL_TEMPLATES_API_RESPONSE]: {},

        // map, where key is entity type and value is ENTITIES Map whih key is entity ID and value is ENTITY data
        [State.PRIVACY_ENTITIES_BY_TYPE]: {},
        [State.PRIVACY_ENTITIES_API_RESPONSE_BY_TYPE]: {},
    }),
    mutations: {
        // TEMPLATES
        [Mutations.SET_PRIVACY_TEMPLATES_BY_IDS]: (state, { templatesMap }) => {
            Vue.set(state, State.PRIVACY_TEMPLATES_BY_IDS, templatesMap);
        },
        [Mutations.SET_PRIVACY_TEMPLATES_BY_TYPE]: (state, { entityType, templatesMap }) => {
            Vue.set(state[State.PRIVACY_TEMPLATES_BY_TYPE], entityType, templatesMap);
        },
        [Mutations.SET_PRIVACY_TEMPLATES_API_RESPONSE_BY_TYPE]: (state, { entityType, apiResponse }) => {
            Vue.set(state[State.PRIVACY_TEMPLATES_API_RESPONSE_BY_TYPE], entityType, apiResponse);
        },
        [Mutations.SET_PRIVACY_ALL_TEMPLATES_API_RESPONSE]: (state, apiResponse) => {
            Vue.set(state, State.PRIVACY_ALL_TEMPLATES_API_RESPONSE, apiResponse);
        },
        // ENTITIES
        [Mutations.SET_PRIVACY_ENTITIES]: (state, { entityType, entitiesMap }) => {
            Vue.set(state[State.PRIVACY_ENTITIES_BY_TYPE], entityType, entitiesMap);
        },
        [Mutations.SET_PRIVACY_ENTITIES_API_RESPONSE]: (state, { entityType, apiResponse }) => {
            Vue.set(state[State.PRIVACY_ENTITIES_API_RESPONSE_BY_TYPE], entityType, apiResponse);
        },
    },
    actions: {
        // TEMPLATES
        async [Actions.PRIVACY_REQUEST_TEMPLATES_BY_TYPE_AND_IDS]({ commit }, config) {
            // Fetch data
            let response;
            try {
                response = await getTemplates(config.entityType, config?.templatesIds || null);
                commit(Mutations.SET_PRIVACY_TEMPLATES_API_RESPONSE_BY_TYPE, {
                    entityType: config.entityType,
                    apiResponse: response,
                });
            } catch (error) {
                commit(Mutations.SET_PRIVACY_TEMPLATES_API_RESPONSE_BY_TYPE, {
                    entityType: config.entityType,
                    apiResponse: error.response,
                });
                throw error; // rethrow error as existing code expects an error on http call failure
            }
            let templatesArr =
                response?.data?.data.map(templateJson => {
                    return new CommonTemplateEntityTemplate(templateJson, false);
                }) || [];
            const templateList = {};
            templatesArr.forEach(template => {
                if (!templateList[template.entityType]) {
                    templateList[template.entityType] = [];
                }

                templateList[template.entityType].push(template);
            });
            Object.entries(templateList).forEach(([key, value]) => {
                commit(Mutations.SET_PRIVACY_TEMPLATES_BY_TYPE, { entityType: key, templatesMap: keyBy(value, 'id') });
            });
            commit(Mutations.SET_PRIVACY_TEMPLATES_BY_IDS, { templatesMap: keyBy(templatesArr, 'id') });
        },
        // ALL TEMPLATES
        async [Actions.PRIVACY_REQUEST_ALL_TEMPLATES]({ commit }, { types = [] } = {}) {
            // Fetch data
            let response;
            try {
                response = await getAllTemplates(types);
                commit(Mutations.SET_PRIVACY_ALL_TEMPLATES_API_RESPONSE, response);
            } catch (error) {
                commit(Mutations.SET_PRIVACY_ALL_TEMPLATES_API_RESPONSE, error.response);
                throw error; // rethrow error as existing code expects an error on http call failure
            }
            const templatesArr = response?.data?.data.map(
                templateJson => new CommonTemplateEntityTemplate(templateJson),
            );
            const templateList = {};

            templatesArr.forEach(template => {
                if (!templateList[template.entityType]) {
                    templateList[template.entityType] = [];
                }

                templateList[template.entityType].push(template);
            });

            Object.entries(templateList).forEach(([key, value]) => {
                commit(Mutations.SET_PRIVACY_TEMPLATES_BY_TYPE, { entityType: key, templatesMap: keyBy(value, 'id') });
            });

            commit(Mutations.SET_PRIVACY_TEMPLATES_BY_IDS, { templatesMap: keyBy(templatesArr, 'id') });
        },
        // ENTITIES
        async [Actions.PRIVACY_REQUEST_ENTITIES_BY_TYPE_AND_IDS](
            { commit },
            { entityType, entitiesIds, includeDrafts = true },
        ) {
            // Fetch data
            let response;
            let draftResponse;
            try {
                response = await getEntities(entityType, entitiesIds);
                commit(Mutations.SET_PRIVACY_ENTITIES_API_RESPONSE, { entityType, apiResponse: response });
                if (includeDrafts) {
                    draftResponse = await getEntityDrafts(entityType);
                }
            } catch (error) {
                commit(Mutations.SET_PRIVACY_ENTITIES_API_RESPONSE, { entityType, apiResponse: error.response });
                throw error; // rethrow error as existing code expects an error on http call failure
            }
            const entities = response?.data?.[`${entityType}_by_id`] || {};
            const draftEntities = draftResponse?.data?.data || {};
            let entitiesList = [];
            let draftEntitiesList = [];
            const EntityTypeModel = ENTITIES_TYPES_TO_MODEL_MAP.get(entityType);
            if (EntityTypeModel) {
                draftEntitiesList = Object.values(draftEntities).map(
                    entityJson =>
                        new EntityTypeModel({
                            ...entityJson,
                            entityType,
                            isDraft: true,
                            state: STATUS_CODES.NA,
                            entityVersion: STATUS_CODES.DRAFT,
                        }),
                );
                entitiesList = Object.values(entities).map(
                    entityJson =>
                        new EntityTypeModel({
                            ...entityJson,
                            entityType,
                            entityVersion: checkForUnpublishedChanges(entityJson, draftEntitiesList),
                        }),
                );
            } else {
                draftEntitiesList = Object.values(draftEntities).map(
                    entityJson =>
                        new CommonTemplateEntityBaseModel({
                            ...entityJson,
                            entityType,
                            isDraft: true,
                            state: STATUS_CODES.NA,
                            entityVersion: STATUS_CODES.DRAFT,
                        }),
                );
                entitiesList = Object.values(entities).map(
                    entityJson =>
                        new CommonTemplateEntityBaseModel({
                            ...entityJson,
                            entityType,
                            entityVersion: checkForUnpublishedChanges(entityJson, draftEntitiesList),
                        }),
                );
            }

            commit(Mutations.SET_PRIVACY_ENTITIES, {
                entityType,
                entitiesMap: keyBy([...draftEntitiesList, ...entitiesList], 'id'),
            });
        },
    },
    getters: {
        // TEMPLATES
        [Getters.GET_PRIVACY_ALL_TEMPLATES]: state => Object.values(state[State.PRIVACY_TEMPLATES_BY_IDS]),
        [Getters.GET_PRIVACY_NOT_PRESET_TEMPLATES]: (state, getters) =>
            getters[Getters.GET_PRIVACY_ALL_TEMPLATES].filter(
                template => template.entityType !== TEMPLATE_TYPES_MAP.PRESET,
            ),
        [Getters.GET_PRIVACY_TEMPLATES_BY_TYPE]: state => type => state[State.PRIVACY_TEMPLATES_BY_TYPE][type] || [],
        [Getters.GET_PRIVACY_TEMPLATES_API_RESPONSE_BY_TYPE]: state => type =>
            state[State.PRIVACY_TEMPLATES_API_RESPONSE_BY_TYPE][type],
        [Getters.GET_PRIVACY_ALL_TEMPLATES_API_RESPONSE]: state => state[State.PRIVACY_ALL_TEMPLATES_API_RESPONSE],
        [Getters.GET_PRIVACY_ALL_TEMPLATES_NOT_DELETED]: (state, getters) => {
            const allTemplates = getters[Getters.GET_PRIVACY_ALL_TEMPLATES];
            return (
                Object.values(allTemplates)?.filter(template => template.state !== TEMPLATE_STATES_NUMERIC.ARCHIVED) ||
                []
            );
        },
        [Getters.GET_PRIVACY_TEMPLATES_BY_TYPE_NOT_DELETED]: (state, getters) => type => {
            const templatesByType = getters[Getters.GET_PRIVACY_TEMPLATES_BY_TYPE](type);
            return (
                Object.values(templatesByType)?.filter(
                    template => template.state !== TEMPLATE_STATES_NUMERIC.ARCHIVED,
                ) || []
            );
        },
        [Getters.GET_PRIVACY_TEMPLATES_BY_TYPE_APPROVED]: (state, getters) => type => {
            const templatesByType = getters[Getters.GET_PRIVACY_TEMPLATES_BY_TYPE](type);
            return (
                Object.values(templatesByType)?.filter(
                    template => template.state === TEMPLATE_STATES_NUMERIC.APPROVED,
                ) || []
            );
        },
        [Getters.GET_PRIVACY_TEMPLATE_BY_ID]: state => id => state[State.PRIVACY_TEMPLATES_BY_IDS][id] || {},
        [Getters.GET_PRIVACY_TEMPLATE_BY_TYPE_AND_ID]: (state, getters) => (type, id) => {
            const templatesByType = getters[Getters.GET_PRIVACY_TEMPLATES_BY_TYPE](type);
            // TODO: there might be better return value then just {}
            if (!isEmpty(templatesByType)) {
                return templatesByType[id] || {};
            }
            return {};
        },
        // ENTITIES
        [Getters.GET_PRIVACY_ENTITIES_BY_TYPE]: state => type => state[State.PRIVACY_ENTITIES_BY_TYPE][type] || {},
        [Getters.GET_PRIVACY_ENTITIES_API_RESPONSE_BY_TYPE]: state => type =>
            state[State.PRIVACY_ENTITIES_API_RESPONSE_BY_TYPE][type],
        [Getters.GET_PRIVACY_ENTITIES_BY_TYPE_NOT_DELETED]: (state, getters) => type => {
            const entitiesByType = getters[Getters.GET_PRIVACY_ENTITIES_BY_TYPE](type);
            return Object.values(entitiesByType)?.filter(entity => entity.state !== EntityStateMapping.DELETED) || [];
        },
        [Getters.GET_PRIVACY_ENTITIES_BY_TYPE_APPROVED]: (state, getters) => type => {
            const entitiesByType = getters[Getters.GET_PRIVACY_ENTITIES_BY_TYPE](type);
            return Object.values(entitiesByType)?.filter(entity => entity.state === EntityStateMapping.APPROVED) || [];
        },
        [Getters.GET_PRIVACY_ENTITY_BY_TYPE_AND_ID]: (state, getters) => (type, id) => {
            const entitiesByType = getters[Getters.GET_PRIVACY_ENTITIES_BY_TYPE](type);
            if (entitiesByType) {
                return entitiesByType[id] || {};
            }
            return {};
        },
    },
};

export default privacyStore;
