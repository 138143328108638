<template>
    <AbstractSubSidebarPageWrapper
        :pageTitle="$i18n.t('settings.settings')"
        :entitiesCount="filteredEntities.length"
    >
        <template slot="filterTable">
            <FilterTable
                :columns="tableColumnsData"
                @filterAdded="onFilterAdded"
            />
        </template>

        <template slot="headerButtons">
            <AppButton
                :buttonType="BUTTON_TYPES.PRIMARY"
                :label="$i18n.t('users.newUser')"
                :iconType="ICON_TYPES.PLUS"
                @click="addUser"
            />
        </template>

        <template slot="subSidebar">
            <SubSidebar
                v-model="selectedRouteName"
                :sections="permissionFilteredSections"
                class="sidebar"
                @change="changeActiveSection"
            />
        </template>

        <template slot="content">
            <AbstractListPageWrapper :isOverviewEnabled="isOverviewEnabled">
                <template slot="allFilters">
                    <TableFiltersTags
                        :filterTableMixin="filterTableMixin"
                        class="my-3 ml-2"
                        @removeFilter="removeFilter"
                        @removeAllFilters="removeAllFilters"
                    />
                </template>
                <template slot="table">
                    <AppTable
                        :entities="filteredEntities"
                        :selectedEntityId="selectedEntityId"
                        :isSearchEnabled="true"
                        :innerSearchQuery="searchQueryForTable"
                        :searchBy="['name']"
                        :columnsData="tableColumnsData"
                        :canSelectColumns="true"
                        :isDataLoading="isDataLoading"
                        :tableWrapperHeight="tableHeight"
                        tableKey="user"
                        @selectEntity="onSelectEntity"
                    >
                        <template #customRowButtons="{ entity }">
                            <!-- reset password disabled for now
                            <IconButton
                                :label="$i18n.t('customerCare.userInformation.resetPassword')"
                                :icon="ICON_TYPES.REFERSH"
                                @iconClick="resetPasswordAlert(entity.id)"
                            />
                            -->
                            <IconButton
                                :label="$i18n.t('generic.edit')"
                                :icon="ICON_TYPES.EDIT"
                                @iconClick="editUser(entity.id)"
                            />
                            <IconButton
                                :label="$i18n.t('generic.delete')"
                                :icon="ICON_TYPES.DELETE"
                                @iconClick="onDeleteUser(entity.id)"
                            />
                        </template>
                    </AppTable>
                </template>
                <template slot="overview">
                    <EntityOverview
                        :entityType="ENTITY_TYPES.USER"
                        :entity="selectedEntity"
                        @closeOverview="isOverviewEnabled = false"
                    >
                        <div :slot="`section-1-header`">
                            <OverviewHeader
                                v-if="selectedEntity"
                                :entityName="selectedEntity.name"
                                :entityType="ENTITY_TYPES.USER"
                            />
                        </div>
                        <div :slot="`section-1-content`">
                            <OverviewList :lists="detailsSections" />
                        </div>
                    </EntityOverview>
                </template>
            </AbstractListPageWrapper>
        </template>
    </AbstractSubSidebarPageWrapper>
</template>

<script>
// Vuex
import { mapGetters, mapActions } from 'vuex';
import Actions, { Getters } from '@/store/mutation-types';
// Components
import AbstractListPageWrapper from '@/components/layout/AbstractListPageWrapper.vue';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import { ICON_TYPES } from '@/common/iconHelper';
import AppTable from '@/components/partials/AppTable.vue';
import EntityOverview from '@/components/partials/entityOverview/EntityOverview.vue';
import OverviewList from '@/components/partials/entityOverview/OverviewList.vue';
import OverviewHeader from '@/components/partials/entityOverview/OverviewHeader.vue';
import IconButton from '@/components/partials/IconButton.vue';
import FilterTableMixin from '@/components/partials/FilterTableMixin.vue';
import FilterTable from '@/components/partials/FilterTable.vue';
import SubSidebarMixin from '@/components/partials/SubSidebarMixin.vue';
import SettingsSubSidebarMixin from '@/__new__/features/settings/SettingsSubSidebarMixin.vue';
import AbstractSubSidebarPageWrapper from '@/components/layout/AbstractSubSidebarPageWrapper.vue';
import SubSidebar from '@/components/layout/SubSidebar.vue';
import TableFiltersTags from '@/components/filters/TableFiltersTags.vue';

// Helpers
import Button from '@/common/button/Button';
import RouteNames from '@/router/routeNames';
import ENTITY_TYPES from '@/common/entities/entityTypes';
import { deleteUser, resetPassword } from '@/__new__/services/portal/admin/http/account';
import { ALERT_TYPES } from '@/common/alerts/Alert';
import * as Sentry from '@sentry/vue';
import tableColumnType from '@/common/filterTable';

export const customTableHeight = '93%';

export default {
    name: 'Users',
    components: {
        AbstractListPageWrapper,
        AppTable,
        AppButton,
        EntityOverview,
        OverviewList,
        OverviewHeader,
        IconButton,
        FilterTable,
        AbstractSubSidebarPageWrapper,
        SubSidebar,
        TableFiltersTags,
    },
    mixins: [FilterTableMixin, SubSidebarMixin, SettingsSubSidebarMixin],
    data() {
        return {
            searchQueryForTable: '',
            ICON_TYPES,
            BUTTON_TYPES,
            ENTITY_TYPES,
            ALERT_TYPES,
            alertButtons: {},
            selectedEntity: null,
            selectedEntityId: null,
            isDataLoading: false,
            tableHeight: customTableHeight,
            isOverviewEnabled: false,
        };
    },
    computed: {
        ...mapGetters('users', {
            entities: Getters.GET_LIST_OF_USERS,
        }),
        detailsSections() {
            if (!this.selectedEntity) {
                return [];
            }
            return [
                {
                    name: this.$i18n.t('generic.userInfo'),
                    properties: [
                        {
                            value: this.selectedEntity.id,
                            label: this.$i18n.t('generic.portalId'),
                        },
                        {
                            value: this.selectedEntity.name,
                            label: this.$i18n.t('generic.name'),
                        },
                        {
                            value: this.selectedEntity.email,
                            label: this.$i18n.t('generic.email'),
                        },
                        {
                            value: this.selectedEntity.role,
                            label: this.$i18n.t('generic.role'),
                        },
                    ],
                },
            ];
        },
        filteredEntities() {
            return this.filteredEntitiesMixin(this.entities);
        },
        tableColumnsData() {
            return [
                {
                    name: this.$i18n.t('generic.portalId'),
                    key: 'id',
                    field: 'id',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('generic.name'),
                    key: 'name',
                    field: 'name',
                    forbidHideColumn: true,
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('generic.email'),
                    key: 'email',
                    field: 'email',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('generic.role'),
                    key: 'role',
                    field: 'role',
                    filterType: tableColumnType.TEXT_LIMITED_OPTIONS,
                    limitedOptions: [...new Set(this.entities.map(entity => entity.role))],
                },
            ];
        },
    },
    created() {
        this.loadUsers();
    },
    methods: {
        ...mapActions('users', [Actions.LOAD_ALL_USERS]),
        async loadUsers() {
            try {
                await this[Actions.LOAD_ALL_USERS]();
            } catch (error) {
                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('alertMessage.failedToLoadNecessaryData'),
                });
            }
        },
        onSelectEntity(id) {
            this.selectedEntityId = id;
            this.selectedEntity = this.entities.find(e => e.id === id);
            this.isOverviewEnabled = true;
        },
        addUser() {
            this.$router.push({
                name: RouteNames.USER_EDITOR,
                params: { companyId: this.$route.params.companyId },
            });
        },
        editUser(id) {
            this.$router.push({
                name: RouteNames.USER_EDITOR,
                params: { id, companyId: this.$route.params.companyId },
            });
        },
        onDeleteUser(id) {
            this.alertButtons.deleteButton = new Button({
                label: this.$i18n.t('generic.confirm'),
                alertType: ALERT_TYPES.warning,
            });
            this.$eventBus.$emit('showAlert', {
                message: this.$i18n.t('alerts.areYouSureDeleteUser'),
                type: ALERT_TYPES.warning,
                buttons: [this.alertButtons.deleteButton],
            });
            this.$eventBus.$once('buttonClicked', buttonId => {
                if (buttonId === this.alertButtons.deleteButton.id) {
                    this.isDataLoading = true;
                    this.removeUser(id);
                }
            });
        },
        async removeUser(id) {
            try {
                this.$Progress.start();
                const user = {
                    id: Number(id),
                    csrfValue: this.$cookie.get('sessionToken'),
                    csrfKey: this.$cookie.get('sessionTokenKey'),
                };
                await deleteUser(user);
                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('users.alerts.successfullyDeleted'),
                    type: ALERT_TYPES.success,
                });
                this.selectedEntity = null;
            } catch (e) {
                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('alertMessage.errorDoingSmthTryAgain', {
                        action: 'deleting',
                        entityName: 'user',
                    }),
                });
            } finally {
                this[Actions.LOAD_ALL_USERS]();
                this.isDataLoading = false;
                this.$Progress.finish();
            }
        },
        resetPasswordAlert(id) {
            const deleteBtn = new Button({
                label: this.$i18n.t('generic.confirm'),
                alertType: ALERT_TYPES.warning,
            });
            this.$eventBus.$emit('showAlert', {
                message: this.$i18n.t('alerts.areYouSureResetPW'),
                type: ALERT_TYPES.warning,
                buttons: [deleteBtn],
            });
            this.$eventBus.$once('buttonClicked', buttonId => {
                if (buttonId === deleteBtn.id) {
                    this.resetPasswordCall(id);
                }
            });
        },
        async resetPasswordCall(id) {
            try {
                this.$Progress.start();
                await resetPassword({
                    userId: id,
                });
                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('alerts.passwordHasBeenReset'),
                    type: ALERT_TYPES.success,
                });
                this[Actions.LOAD_ALL_USERS]();
                this.$Progress.finish();
            } catch (e) {
                this.$eventBus.$emit('showAlert', {
                    message: e.response.data.error,
                });
                Sentry.captureException(e);
                this.$Progress.fail();
            }
        },
    },
};
</script>
