<template>
    <div class="display-flex align-items-center">
        <Dropdown
            :items="operationLabels"
            :colorSchema="Styles.White"
            :selected="[value && DropdownOperationToLabels[value.op]]"
            caption="Select operation"
            :isFilterOperation="true"
            :widthDependsOnContent="true"
            @selected="opLabel => onChange({ op: DropdownOperationLabelsToType[opLabel] })"
        />
        <Dropdown
            v-show="!isNoArgOperation"
            :items="items"
            :caption="'Select Value'"
            :displayProperty="displayProperty"
            :searchable="true"
            :colorSchema="Styles.White"
            :selected="[value]"
            :invalid="!isValid"
            :isVisible="isVisible"
            class="f-dropdown ml-3"
            @selected="onChange"
        />
    </div>
</template>

<script>
import { values } from 'lodash';
import Dropdown, { Styles } from '@/components/partials/Dropdown.vue';
import {
    DropdownOperationLabelsToType,
    DropdownOperationToLabels,
    NoArgOperations,
} from '@/common/filters/InputOperations';

export default {
    components: { Dropdown },
    props: {
        changeCallback: { type: Function, default: null },
    },
    data() {
        return {
            Styles,
            DropdownOperationToLabels,
            DropdownOperationLabelsToType,
            value: null,
            items: () => [], // override
        };
    },
    computed: {
        displayProperty() {
            return null;
        }, // override
        isValid() {
            return true;
        }, // override
        isVisible() {
            return true;
        }, // override
        operationLabels() {
            return values(DropdownOperationToLabels);
        },
        isNoArgOperation() {
            return this.value && NoArgOperations[this.value.op];
        },
    },
    methods: {
        onSelect(index) {
            this.onValueChange(index);
            if (this.changeCallback) this.changeCallback();
        },
        onValueChange(item) {
            return item;
        }, // override
        onChange(partialValue) {
            this.onValueChange(partialValue);
            if (this.changeCallback) this.changeCallback();
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/mixins';

.dropdown {
    @include custom-height-dropdown(32px);
    @include custom-width-dropdown(180px);
}
</style>
