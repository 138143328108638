<template>
    <div class="pb-4">
        <AppInputV3
            v-model.number="rolloversData.maxRolloverCount"
            type="number"
            :min="1"
            :label="$i18n.t('charging.CSG.editor.recurrencesNum')"
            :disablePadding="true"
            :invalid="errors && errors.maxRolloverCount.$error"
            :disabled="disabled"
            placeholder="1"
            class="editor-input-smallest mb-3"
            data-test-id="input-recurrences-num"
        />
        <div
            v-for="(rollover, index) in rolloversData.data"
            :key="index"
        >
            <div class="d-flex justify-content-between">
                <div class="d-flex align-items-center">
                    <div class="rollover-label">
                        {{ $i18n.t('generic.rollover') }}
                        {{ index + 1 }}
                    </div>
                </div>
                <div
                    v-if="index !== 0"
                    :class="['remove-rollover', { 'disabled-area': disabled }]"
                    :data-test-id="`remove-rollover-${index}`"
                    @click="removeRollover(index)"
                >
                    {{ $i18n.t('generic.remove') }}
                </div>
            </div>
            <div class="rollover-wrapper">
                <AppMultiselectV3
                    v-model="rollover.chargingSpecification"
                    :additionalLabel="$i18n.t('charging.entities.plural.chargingSpecifications')"
                    :allowEmpty="false"
                    :small="true"
                    :showLabels="false"
                    :options="chargingSpecifications"
                    :placeholder="$i18n.t('charging.CSG.editor.chooseChargingSpecifications')"
                    :error="errors && errors.data.$each[index] && errors.data.$each[index].chargingSpecification.$error"
                    :disabled="disabled"
                    label="name"
                    trackBy="id"
                    class="editor-input-largest mb-3 mt-2"
                    :data-test-id="`multi-charging-specifications-${index}`"
                />
                <AppMultiselectV3
                    v-model="rollover.type"
                    :additionalLabel="$i18n.t('charging.CSG.editor.rolloverType')"
                    :placeholder="$i18n.t('charging.CSG.editor.selectRolloverType')"
                    :options="rolloverTypeOptions"
                    :showLabels="false"
                    :small="true"
                    :allowEmpty="false"
                    :error="errors && errors.data.$each[index] && errors.data.$each[index].type.$error"
                    :disabled="disabled"
                    label="label"
                    trackBy="value"
                    :data-test-id="`multiselect-rollover-type-${index}`"
                    class="editor-input-large mb-3"
                />
                <div v-if="rollover.type">
                    <UnitPicker
                        v-if="isRolloverTypesQuota(rollover)"
                        v-model.number="rollover.maxRolloverUnits"
                        :label="$i18n.t('charging.CSG.editor.maxRolledover')"
                        :min="0"
                        :definitionsWidth="5"
                        :type="getFormulaCalculated(rollover)"
                        :isEditorDesign="true"
                        :error="errors && errors.data.$each[index] && errors.data.$each[index].maxRolloverUnits.$error"
                        :disabled="disabled"
                        placeholder="123"
                        :data-test-id="`unit-max-rollover-units-${index}`"
                        class="mb-3"
                    />
                    <div
                        v-else
                        class="d-flex align-items-center mb-3"
                    >
                        <AppInputV3
                            v-model.number="rollover.maxRolloverPercentage"
                            type="number"
                            :label="$i18n.t('charging.CSG.editor.maxRolledover')"
                            :min="0"
                            :disablePadding="true"
                            :error="
                                errors &&
                                errors.data.$each[index] &&
                                errors.data.$each[index].maxRolloverPercentage.$error
                            "
                            :disabled="disabled"
                            class="editor-input-smallest"
                            :data-test-id="`input-max-rollover-value-${index}`"
                        />
                        <div class="inline-text">%</div>
                    </div>
                </div>
                <AppMultiselectV3
                    v-model="rollover.firstUse"
                    :additionalLabel="$i18n.t('charging.CSG.editor.firstUse')"
                    :placeholder="$i18n.t('charging.CSG.editor.selectFirstUse')"
                    :small="true"
                    :showLabels="false"
                    :options="rolloversFirstUseOptions"
                    :allowEmpty="false"
                    :error="errors && errors.data.$each[index] && errors.data.$each[index].firstUse.$error"
                    :disabled="disabled"
                    label="label"
                    trackBy="id"
                    class="editor-input-large mb-3"
                    :data-test-id="`multi-first-use-${index}`"
                />
            </div>
        </div>
        <AppButton
            class="my-1 p-0"
            :label="$i18n.t('charging.CSG.editor.addRollover')"
            :iconType="ICON_TYPES.PLUS"
            :isSmall="true"
            :disabled="disabled"
            data-test-id="button-add-rollover"
            @click="addRollover"
        />
    </div>
</template>

<script>
// Components
import AppMultiselectV3 from '@/components/partials/inputs/AppMultiselectV3.vue';
import AppInputV3 from '@/components/partials/inputs/AppInputV3.vue';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import { ICON_TYPES } from '@/common/iconHelper';
import UnitPicker from '@/components/partials/inputs/UnitPicker.vue';

// Helpers
import {
    CSG_ROLLOVERS_FIRST_USE_OPTIONS,
    CHARGING_SPEC_TYPE,
} from '@/__new__/services/dno/charging/common/chargingSpecificationHelper';
import {
    thresholdTypes as rolloverTypes,
    TYPES_OPTIONS,
} from '@/__new__/services/dno/charging/common/policyCounterHelper';
import { unitTypes } from '@/common/formatting';

export default {
    name: 'Rollovers',
    components: {
        AppMultiselectV3,
        AppInputV3,
        AppButton,
        UnitPicker,
    },
    props: {
        rollovers: {
            type: Object,
            default: () => undefined,
        },
        chargingSpecifications: {
            type: Array,
            default: () => [],
        },
        errors: {
            type: Object,
            default: () => undefined,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            ICON_TYPES,
            BUTTON_TYPES,
            rolloversFirstUseOptions: Object.values(CSG_ROLLOVERS_FIRST_USE_OPTIONS),
            rolloversData: null,
        };
    },
    computed: {
        rolloverTypeOptions() {
            return Object.values(rolloverTypes);
        },
    },
    watch: {
        rollovers: {
            deep: true,
            immediate: true,
            handler(newVal) {
                this.rolloversData = newVal;
            },
        },
        chargingSpecifications: {
            handler(newVal) {
                const csIdsInUse = newVal.map(el => el?.id);
                this.rolloversData.data = this.rolloversData.data?.filter(el =>
                    csIdsInUse.includes(el?.chargingSpecification?.id),
                );

                if (csIdsInUse.length === 0) {
                    this.rolloversData = {
                        enabled: false,
                        data: [],
                    };
                }
            },
        },
        rolloversData(newVal) {
            this.$emit('updateRolloversData', newVal);
        },
    },
    methods: {
        addRollover() {
            this.rolloversData.data.push({
                chargingSpecification: null,
                type: null,
                maxRolloverUnits: null,
                maxRolloverPercentage: null,
                firstUse: null,
            });
        },
        removeRollover(index) {
            this.rolloversData.data.splice(index, 1);
        },
        getFormulaCalculated(rollover) {
            return rollover?.chargingSpecification?.serviceTypes?.id === CHARGING_SPEC_TYPE.voice.id
                ? unitTypes.DURATION
                : unitTypes.DATA;
        },
        isRolloverTypesQuota(rollovers) {
            return rollovers?.type?.value === TYPES_OPTIONS.QUOTA;
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/typographyV2';
@import '~@/assets/scss/palette';
@import '~@/assets/scss/layout';

.inline-text {
    font-size: 0.75rem;
    text-transform: uppercase;
    font-weight: bold;
    color: $gray-blue;
    margin-top: 1.9rem;
    margin-left: 1rem;
}

.rollover-label {
    text-transform: uppercase;
    font-size: 0.625rem;
    font-weight: bold;
    line-height: 2;
    color: $steel;
    margin-right: 0.75rem;
}

.rollover-wrapper {
    margin-top: 0.5rem;
    margin-bottom: 1.3rem;
    border-top: solid 0.1rem $blue15;
    border-bottom: solid 0.1rem $blue15;
}

.remove-rollover {
    font-size: 0.75rem;
    font-weight: 600;
    line-height: 1.86;
    color: $blue;
    cursor: pointer;
}
</style>
