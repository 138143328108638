
// Components
import AppHeader from '@/components/layout/AppHeader.vue';
import Breadcrumbs from '@/components/partials/Breadcrumbs.vue';
import MultipleTablesTabs from '@/components/partials/MultipleTablesTabs.vue';
import ExecutionStatusIndicator from '@/__new__/features/orchestrationengine/ExecutionStatusIndicator.vue';
import { EXECUTION_STATES } from '@/__new__/features/orchestrationengine/common/executionStatusIndicatorHelper';
import AppTable from '@/components/partials/AppTable.vue';
import SearchBox from '@/components/partials/inputs/SearchBox.vue';
import FilterTable from '@/components/partials/FilterTable.vue';
import TableFiltersRenderless from '@/components/filters/TableFiltersRenderless.vue';
import TableFiltersTags from '@/components/filters/TableFiltersTags.vue';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import IconButton from '@/components/partials/IconButton.vue';
import { ICON_TYPES } from '@/common/iconHelper';
import AppAditionalSidebar from '@/components/partials/AppAditionalSidebar.vue';
import PlanDefinition from '@/__new__/features/orchestrationengine/PlanDefinition.vue';
import AppToggle from '@/components/partials/inputs/AppToggle.vue';
import EntityStatusIndicator from '@/components/partials/EntityStatusIndicator.vue';
import RetryExecutionModal from '@/__new__/features/orchestrationengine/RetryExecutionModal.vue';
import ResumeExecutionModal from '@/__new__/features/orchestrationengine/ResumeExecutionModal.vue';
import CallbackExecutionModal from '@/__new__/features/orchestrationengine/CallbackExecutionModal.vue';
import StopExecutionModal from '@/__new__/features/orchestrationengine/StopExecutionModal.vue';
import Button from '@/common/button/Button';
import AppInfoBlock from '@/components/partials/AppInfoBlock.vue';
import ResponseModalOrchestrationEngine from '@/components/partials/ResponseModalOrchestrationEngine.vue';
import SearchExecutionModal from '@/__new__/features/orchestrationengine/SearchExecutionModal.vue';
import ExecutionNotes from '@/__new__/features/orchestrationengine/ExecutionNotes.vue';
import UpdateScheduledTimeModal from '@/__new__/features/orchestrationengine/UpdateScheduledTimeModal.vue';
import StateHistoryModal from '@/__new__/features/orchestrationengine/StateHistoryModal.vue';

// 3rd party components
import VueJsonPretty from 'vue-json-pretty';

// Http
import {
    getExecutionHistory,
    getPlanDetails,
    getStateHistory,
    executePlanImmediately,
} from '@/__new__/services/dno/orchestrationengine/http/orchestration-engine';
import httpNotes from '@/__new__/services/dno/crm/http/crm';

// Error handling
import * as Sentry from '@sentry/vue';

// Helpers
import tableColumnType from '@/common/filterTable';
import { ALERT_TYPES } from '@/common/alerts/Alert';
import moment from 'moment';
import importMonacoHelper from '@/common/importMonacoHelper';
import orchestrationEngineVisualizerHelper from '@/__new__/features/orchestrationengine/common/orchestrationEngineVisualizerHelper';
import {
    tabSelection,
    EXECUTION_TYPES,
    IS_EXECUTED_STATES,
    IS_EXECUTED_STATE_INDICATOR_MAP,
    IS_EXECUTED_STATES_TO_STATUS_NAME_MAP,
    VERSION_OPTIONS,
    EXECUTION_HISTORY_TYPES,
    NOTES_ID_TYPE,
} from '@/__new__/features/orchestrationengine/common/orchestrationExecutionDetailsPageHelper';
import RouteNames from '@/router/routeNames';
import { SEGMENT_ID_TYPES } from '@/common/StaticFilter';

import { TranslateResult } from 'vue-i18n';
import permissionsService, { isUserAllowed, getOperatorConfigValue } from '@/services/permissions/permissions.service';

// Vuex
import { mapGetters, mapActions } from 'vuex';
import Actions, { Getters } from '@/store/mutation-types';

const CANVAS_ID = 'workflowVisualizerID';

const MASTER_PLAN_EXECUTION_UNSET = 'unset';

// helper for error "Property 'monaco' does not exist on type 'Window & typeof globalThis'"
declare const window: any;

interface GeneralTabInfoData {
    title: TranslateResult;
    value: { key: string; name: TranslateResult; value?: any }[];
}

interface RemappedExecution {
    id: number;
    type: string;
    state: string;
    data: object;
    timestamp: number;
    timestampFormatted: string;
    timestampMoment: any;
    isStateExecuted: string;
    durationOfExecutionPerState: any;
}

interface SidebarRelatedExecutionColumnData {
    name: TranslateResult;
    key: string;
    field: string;
    filterType: any;
}

interface ExecutionsColumnData {
    name: TranslateResult;
    key: string;
    field: string;
    filterType: any;
    sortBy?: any;
    limitedOptions?: any;
}

/* eslint-disable camelcase */
interface Note {
    crm_user_id?: string;
    epoch?: number;
    id?: string;
    id_type?: number;
    note?: object;
    operator_name?: string;
}
/* eslint-enable camelcase */

/* eslint-disable camelcase */
interface FirstPlanVersion {
    name: string;
    plan_id: string;
    plan_owner: string;
    start_at: string;
    states: object;
}
/* eslint-enable camelcase */

export default {
    name: 'OrchestrationEngineExecutionDetailsPage',
    components: {
        AppHeader,
        Breadcrumbs,
        MultipleTablesTabs,
        ExecutionStatusIndicator,
        AppTable,
        SearchBox,
        FilterTable,
        TableFiltersRenderless,
        TableFiltersTags,
        IconButton,
        AppAditionalSidebar,
        PlanDefinition,
        VueJsonPretty,
        AppToggle,
        AppButton,
        EntityStatusIndicator,
        RetryExecutionModal,
        ResumeExecutionModal,
        StopExecutionModal,
        AppInfoBlock,
        ResponseModalOrchestrationEngine,
        SearchExecutionModal,
        ExecutionNotes,
        UpdateScheduledTimeModal,
        CallbackExecutionModal,
        StateHistoryModal,
    },
    props: {
        id: {
            type: String,
            required: true,
        },
        execId: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            execDetails: {} as object,
            execHistory: [] as any,
            execHistoriesRemapped: [] as any[],
            executionApiResponse: {} as object,
            statesHistory: [] as any[],
            planDefinition: null as any,
            firstPlanVersion: {} as FirstPlanVersion,
            canvas: null as any,
            executionInputEditor: null as any,
            executionOutputEditor: null as any,
            executionInputData: {} as object,
            activeTableIndex: 0 as number,
            tablesNames: [] as TranslateResult[],
            searchQuery: '' as string,
            selectedExecutionId: null,
            selectedExecution: {} as object,
            selectedEventInSidebar: {} as object,
            isSidebarVisible: false as boolean,
            isSidebarForTriggeredEventVisible: false as boolean,
            isDiagramRepresentation: false as boolean,
            isRelatedExecutionClicked: false as boolean,
            resumeModalIsVisible: false as boolean,
            responseModalVisible: false as boolean,
            retryExecutionModalVisible: false as boolean,
            selectedPathFromStateHistory: null as any,
            isVisibleExecutionSearchModal: false as boolean,
            stopExecutionModalVisible: false as boolean,
            notesLoading: false as boolean,
            executionNotes: [] as Note[],
            executionNotesApiResponse: {} as any,
            isUpdateScheduledTimeSidebarVisible: false as boolean,
            callbackExecutionModalIsVisible: false as boolean,
            callbackExecutionButtonVisible: false as boolean,
            stateHistoryModalVisible: false as boolean,
            currentExecutionInput: {} as Record<string, any>,
            currentExecutionInputEditor: null as any,

            tabSelection: tabSelection as any,
            EXECUTION_TYPES: EXECUTION_TYPES as any,
            ICON_TYPES: ICON_TYPES as any,
            BUTTON_TYPES: BUTTON_TYPES as any,
            CANVAS_ID: CANVAS_ID as any,
            MASTER_PLAN_EXECUTION_UNSET: MASTER_PLAN_EXECUTION_UNSET as any,
            EXECUTION_STATES: EXECUTION_STATES as any,
            IS_EXECUTED_STATES: IS_EXECUTED_STATES as any,
            IS_EXECUTED_STATE_INDICATOR_MAP: IS_EXECUTED_STATE_INDICATOR_MAP as any,
            IS_EXECUTED_STATES_TO_STATUS_NAME_MAP: IS_EXECUTED_STATES_TO_STATUS_NAME_MAP as any,
            VERSION_OPTIONS: VERSION_OPTIONS as any,
            EXECUTION_HISTORY_TYPES: EXECUTION_HISTORY_TYPES as any,
        };
    },
    computed: {
        ...mapGetters('orchestrationengine', {
            getExecution: Getters.GET_OE_EXECUTION,
            getExecutionApiResponse: Getters.GET_OE_EXECUTION_API_RESPONSE,
        }),
        generalTabInfoData(): GeneralTabInfoData[] {
            return [
                {
                    title: this.$i18n.t('generic.general'),
                    value: [
                        {
                            key: 'executionId',
                            name: this.$i18n.t('orchestrationEngine.execId'),
                            value: this.execDetails.execution_id,
                        },
                        {
                            key: 'stateField',
                            name: this.$i18n.t('orchestrationEngine.status'),
                        },
                        {
                            key: 'masterPlan',
                            name: this.$i18n.t('orchestrationEngine.masterPlanExecution'),
                        },
                    ],
                },
                {
                    title: this.$i18n.t('orchestrationEngine.timestamps'),
                    value: [
                        {
                            key: 'scheduledAt',
                            name: this.$i18n.t('orchestrationEngine.schedulingTimestamp'),
                            value: this.$localeLibrary.getFormattedDateAndTimeWithMillisecondPrecisions(
                                this.execDetails.scheduled_at,
                            ),
                        },
                        {
                            key: 'scheduledFor',
                            name: this.$i18n.t('orchestrationEngine.scheduledTimestamp'),
                            value: this.$localeLibrary.getFormattedDateAndTimeWithMillisecondPrecisions(
                                this.execDetails.scheduled_for,
                            ),
                        },
                        {
                            key: 'startedAt',
                            name: this.$i18n.t('orchestrationEngine.startTimestamp'),
                            value: this.$localeLibrary.getFormattedDateAndTimeWithMillisecondPrecisions(
                                this.execDetails.started_at,
                            ),
                        },
                        {
                            key: 'finishedAt',
                            name: this.$i18n.t('orchestrationEngine.endTimestamp'),
                            value: this.$localeLibrary.getFormattedDateAndTimeWithMillisecondPrecisions(
                                this.execDetails.finished_at,
                            ),
                        },
                    ],
                },
            ];
        },
        breadcrumbList(): { name: string | TranslateResult; link?: string }[] {
            return [
                {
                    name: this.$i18n.t('orchestrationEngine.orchestrationEngine'),
                    link: '/orchestration-engine/plans',
                },
                {
                    name: `${this.id}`,
                    link: `/orchestration-engine/editor/${this.id}`,
                },
                {
                    name: `${this.execId}`,
                },
            ];
        },
        executions(): RemappedExecution[] {
            return this.remapExecutions(this.execHistory);
        },
        listPageTableExecutions(): RemappedExecution[] {
            let executionsToDisplay = [];

            // get unique values of states from this.execHistory
            const uniqueStates = new Set(this.execHistory.map(execution => execution.state));

            // filter executions per state and then get first execution with that state
            // this is done because we need to display one row per state in table
            // so we get first one (when execution starts)

            uniqueStates.forEach(state => {
                const filteredExecutionsByStateFromAllExecutions = this.execHistory.filter(
                    exec => exec.state === state,
                );
                let firstExecution = filteredExecutionsByStateFromAllExecutions[0];
                const isStateExecuted = filteredExecutionsByStateFromAllExecutions.filter(
                    execByState => execByState.type === 'StateExecuted',
                );
                const durationOfExecutionPerState = this.durationOfExecution(
                    filteredExecutionsByStateFromAllExecutions,
                    isStateExecuted,
                );
                firstExecution = { ...firstExecution, isStateExecuted, durationOfExecutionPerState };
                executionsToDisplay = [...executionsToDisplay, firstExecution];
            });

            return this.remapExecutions(executionsToDisplay);
        },
        sidebarTriggeredEvents(): RemappedExecution[] {
            const filteredExecutions = this.execHistory.filter(
                exec =>
                    exec.type === EXECUTION_TYPES.CATCH_TRIGGERED ||
                    exec.type === EXECUTION_TYPES.RETRY_TRIGGERED ||
                    exec.type === EXECUTION_TYPES.EXECUTION_PAUSED ||
                    exec.type === EXECUTION_TYPES.RETRY_MAX_ATTEMPT,
            );

            const executionsByState = filteredExecutions.filter(
                execution => execution.state === this.selectedExecution.state,
            );

            if (executionsByState.length > 0) {
                return this.remapExecutions(executionsByState);
            }
            return [];
        },
        executionsColumnsData(): ExecutionsColumnData[] {
            return [
                {
                    name: this.$i18n.t('generic.id'),
                    key: 'id',
                    field: 'id',
                    filterType: tableColumnType.NUMBER,
                },
                {
                    name: this.$i18n.t('generic.state'),
                    key: 'state',
                    field: 'state',
                    filterType: tableColumnType.TEXT_LIMITED_OPTIONS,
                    limitedOptions: Array.from(new Set(this.executions.map(entity => entity.state))),
                },
                {
                    name: this.$i18n.t('orchestrationEngine.isExecuted'),
                    key: 'isStateExecuted',
                    field: 'isStateExecuted',
                    filterType: tableColumnType.TEXT_LIMITED_OPTIONS,
                },
                {
                    name: this.$i18n.t('generic.duration'),
                    key: 'durationOfExecutionPerState',
                    sortBy: entity => entity.timestamp,
                    field: 'durationOfExecutionPerState',
                    filterType: tableColumnType.NUMBER,
                },
            ];
        },
        sidebarTriggeredEventsColumnsData(): any[] {
            const columns = this.executionsColumnsData.filter(d => d.key !== 'state' && d.key !== 'isStateExecuted');
            return [
                ...columns,
                {
                    name: this.$i18n.t('generic.type'),
                    key: 'type',
                    field: 'type',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
            ];
        },
        sidebarRelatedExecutionsColumnsData(): SidebarRelatedExecutionColumnData[] {
            return [
                {
                    name: this.$i18n.t('orchestrationEngine.execId'),
                    key: 'executionId',
                    field: 'executionId',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('generic.type'),
                    key: 'typeOfRelation',
                    field: 'typeOfRelation',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
            ];
        },
        masterPlanExecutionDisplayed(): any {
            // eslint-disable-next-line camelcase
            return (
                this.execDetails?.master_plan_execution !== MASTER_PLAN_EXECUTION_UNSET &&
                this.execDetails.master_plan_execution
            );
        },
        sidebarRelatedExecutions(): any[] {
            let executionsToDisplay = [];
            const childrenIds = [];
            // filtering children to have only with selected state
            // eslint-disable-next-line camelcase
            if (this.execDetails.children_executions?.length) {
                // filtering children to have only with selected state
                const children = this.execDetails.children_executions.filter(
                    child => child.state === this.selectedExecution.state,
                );
                if (children.length) {
                    // get IDs from all children
                    children.forEach(oneChild => {
                        oneChild.execution_ids.forEach(id => childrenIds.push(id));
                    });
                    // formatting data to display
                    executionsToDisplay = childrenIds.map(oneId => ({
                        executionId: oneId,
                        typeOfRelation: this.$i18n.t('generic.children'),
                    }));
                }
            }
            return executionsToDisplay;
        },
        // As we are getting the list of execution states we want to display inputs in phase ExecutionStarted
        executionInput(): any {
            let input = {};

            // case when execution is scheduled
            if (
                this.execHistoriesRemapped.length === 1 &&
                this.execHistoriesRemapped[0]?.type === EXECUTION_HISTORY_TYPES.EXECUTION_SCHEDULED &&
                this.execDetails.status === EXECUTION_STATES.PENDING
            ) {
                input = this.execHistoriesRemapped[0]?.reservationData?.input || {};
            } else if (this.execDetails.status === EXECUTION_STATES.WAITINGFORRESTART) {
                if (this.execHistoriesRemapped?.length) {
                    // case when the execution is paused after it has already begun
                    const { length } = this.execHistoriesRemapped;

                    // first execution history that happened is last one in array
                    const firstExecHistory = this.execHistoriesRemapped[length - 1];

                    if (firstExecHistory.type === EXECUTION_HISTORY_TYPES.EXECUTION_SCHEDULED) {
                        input = firstExecHistory?.reservationData?.input || {};
                    } else if (firstExecHistory.type === EXECUTION_HISTORY_TYPES.EXECUTION_STARTED) {
                        input = firstExecHistory?.executionInput || {};
                    }
                }
            } else {
                input =
                    this.execHistoriesRemapped.find(({ type }) => type === EXECUTION_HISTORY_TYPES.EXECUTION_STARTED)
                        ?.executionInput || {};
            }

            return input;
        },
        // As we are getting the list of execution states we want to display outputs in phase ExecutionFinished
        executionOutput(): any {
            return (
                this.execHistoriesRemapped.find(({ type }) => type === EXECUTION_HISTORY_TYPES.EXECUTION_FINISHED)
                    ?.executionOutput?.output || {}
            );
        },
        isCustomerCareRelatedPageButtonVisible(): boolean {
            // eslint-disable-next-line camelcase
            if (this.executionInputData?.target_type && this.executionInputData?.target_id) {
                return (
                    this.executionInputData.target_id &&
                    [SEGMENT_ID_TYPES.ACCOUNT, SEGMENT_ID_TYPES.SUBSCRIBER, SEGMENT_ID_TYPES.USER].includes(
                        this.executionInputData.target_type,
                    )
                );
            }

            return false;
        },
        customerCareRelatedPageLabel(): string {
            if (this.isCustomerCareRelatedPageButtonVisible) {
                const { target_type: targetType } = this.executionInput;

                if (targetType === SEGMENT_ID_TYPES.ACCOUNT) {
                    return `${this.$i18n.t('generic.account')} ${this.$i18n.t('generic.page')}`;
                }

                if (targetType === SEGMENT_ID_TYPES.SUBSCRIBER) {
                    return `${this.$i18n.t('generic.subscriber')} ${this.$i18n.t('generic.page')}`;
                }

                return `${this.$i18n.t('generic.user')} ${this.$i18n.t('generic.page')}`;
            }
            return '';
        },
        isResumeButtonVisible(): boolean {
            return (
                this.execDetails.status === EXECUTION_STATES.WAITINGFORRESTART ||
                this.execDetails.status === EXECUTION_STATES.FAILED
            );
        },
        isRetryButtonVisible(): boolean {
            return (
                this.execDetails.status === EXECUTION_STATES.SUCCESS ||
                this.execDetails.status === EXECUTION_STATES.FAILED ||
                this.execDetails.status === EXECUTION_STATES.STOPPED
            );
        },
        isStopExecutionButtonVisible(): boolean {
            return (
                this.execDetails.status &&
                this.execDetails.status !== EXECUTION_STATES.FAILED &&
                this.execDetails.status !== EXECUTION_STATES.STOPPED &&
                this.execDetails.status !== EXECUTION_STATES.SUCCESS
            );
        },
        isExecutionInPendingState(): boolean {
            return this.execDetails.status === EXECUTION_STATES.PENDING;
        },
        writeEnabled(): boolean {
            return isUserAllowed('OrchestrationPlansWrite') && permissionsService.orchestrationEnginePlansEnabled();
        },
    },
    watch: {
        activeTableIndex(value: number) {
            if (value === tabSelection.EXECUTION_INPUT) {
                this.renderEditor('executionInputEditor', this.executionInput, 'executionInput');
            }
            if (value === tabSelection.CURRENT_EXECUTION_INPUT) {
                this.renderEditor('currentExecutionInputEditor', this.currentExecutionInput, 'currentExecutionInput');
            }
            if (value === tabSelection.EXECUTION_OUTPUT) {
                this.renderEditor('executionOutputEditor', this.executionOutput, 'executionOutput');
            }
        },
        isDiagramRepresentation(value: boolean) {
            if (value) {
                this.renderWorkflowDiagram();
            }
        },
    },
    created() {
        this.$withLoadingSpinner(
            async () => {
                this.tablesNames = [
                    this.$i18n.t('orchestrationEngine.general'),
                    this.$i18n.t('orchestrationEngine.initialExecutionInput'),
                    this.$i18n.t('orchestrationEngine.currentExecutionInput'),
                    this.$i18n.t('orchestrationEngine.executionOutput'),
                    this.$i18n.t('orchestrationEngine.definition'),
                ];

                // Get execution details
                await this.requestExecution(this.execId);
                this.execDetails = this.getExecution.data.execution;

                // Current execution input
                const { current_execution_input: currentExecutionInput = {} } = this.getExecution.data;
                this.currentExecutionInput = currentExecutionInput;

                // Get state history details
                const statesHistoryResponse = await getStateHistory(this.execId);
                this.statesHistory = statesHistoryResponse?.data?.history || [];

                // Get execution api response
                this.executionApiResponse = this.getExecutionApiResponse;

                // Get execution history
                await this.fetchExecutionHistory();
                this.executionInputData = this.executionInput;

                // Get plan details from first plan version for plan definition
                const firstPlan = await getPlanDetails({
                    id: this.id,
                    timestamp: Math.floor(this.execDetails.started_at / 1000),
                });

                this.firstPlanVersion = firstPlan?.data?.orchestration_plan_by_id?.[this.id]?.data || {};

                // Get current plan version details
                const getPlanDetailsResponse = await getPlanDetails({ id: this.id });

                // eslint-disable-next-line camelcase
                this.planDefinition = getPlanDetailsResponse?.data?.orchestration_plan_by_id?.[this.id]?.data || {};
            },
            {
                errorHandler: (error: any) => {
                    Sentry.captureException(error);
                    this.$eventBus.$emit('showAlert', {
                        message: this.$i18n.t('alertMessage.failedToLoadNecessaryData'),
                    });
                },
            },
        );
    },
    async mounted() {
        try {
            // Import monaco editor
            await importMonacoHelper.importMonaco();

            // Set value in editor for initial execution input
            await this.renderEditor('executionInputEditor', this.executionInput, 'executionInput');

            // Set value in editor for current execution input
            await this.renderEditor('currentExecutionInputEditor', this.currentExecutionInput, 'currentExecutionInput');

            // Set value in editor for execution output
            await this.renderEditor('executionOutputEditor', this.executionOutput, 'executionOutput');
        } catch {
            // do nothing
        }
    },
    methods: {
        ...mapActions('orchestrationengine', {
            requestExecution: Actions.REQUEST_OE_EXECUTION,
        }),
        remapExecutions(executionsArray: any): any[] {
            return executionsArray.reduce(
                (currentArr, val, index, initialArray) => [
                    {
                        id: initialArray.length - index,
                        type: val.type,
                        state: val.state,
                        data: val.data,
                        timestamp: this.$localeLibrary.normalizeTimestamp(val.created_at),
                        timestampFormatted: this.$localeLibrary.getFormattedDateAndTime(val.created_at),
                        timestampMoment: moment(this.$localeLibrary.normalizeTimestamp(val.created_at)),
                        isStateExecuted: val?.isStateExecuted?.length ? IS_EXECUTED_STATES.YES : IS_EXECUTED_STATES.NO,
                        durationOfExecutionPerState: val.durationOfExecutionPerState,
                    },
                    ...currentArr,
                ],
                [],
            );
        },
        durationOfExecution(states: any[], isExecuted: any[]): string | TranslateResult {
            if (isExecuted.length) {
                // States are returned in opposite way (first in array is last event happened, last in array is first event happened)
                const firstEventForState = new Date(states[states.length - 1].created_at);
                const lastEventForState = new Date(states[0].created_at);

                const diffTimeInMilliseconds: any = Math.abs(
                    lastEventForState.getTime() - firstEventForState.getTime(),
                );

                const diffTimeInSeconds = diffTimeInMilliseconds / 1000;
                const diffTimeInMinutes = diffTimeInMilliseconds / 1000 / 60;

                let minutes = 0;
                let seconds = 0;
                let milliseconds = diffTimeInMilliseconds;
                let textToDisplay = `${parseInt(milliseconds, 10)} ms`;

                if (diffTimeInMinutes > 1) {
                    const remainingMilliseconds =
                        diffTimeInMilliseconds - parseInt(diffTimeInMinutes.toString(), 10) * 1000 * 60;
                    milliseconds = remainingMilliseconds;

                    if (remainingMilliseconds > 1000) {
                        seconds = remainingMilliseconds / 1000;
                        milliseconds = remainingMilliseconds % 1000;
                    }

                    minutes = diffTimeInMinutes;

                    textToDisplay = `${parseInt(minutes.toString(), 10)} min ${parseInt(
                        seconds.toString(),
                        10,
                    )} sec ${parseInt(milliseconds, 10)} ms`;
                } else if (diffTimeInSeconds > 1) {
                    const remainingMilliseconds =
                        diffTimeInMilliseconds - parseInt(diffTimeInSeconds.toString(), 10) * 1000;
                    seconds = diffTimeInSeconds;
                    milliseconds = remainingMilliseconds;
                    textToDisplay = `${parseInt(seconds.toString(), 10)} sec ${parseInt(milliseconds, 10)} ms`;
                }

                return textToDisplay;
            }
            return this.$i18n.t('generic.empty');
        },
        async fetchExecutionNotes(): Promise<any> {
            try {
                this.notesLoading = true;

                this.executionNotesApiResponse = await httpNotes.getNotes(this.execId, NOTES_ID_TYPE.OE_EXECUTION);
                this.executionNotes = this.executionNotesApiResponse.data.flat(Infinity);

                this.notesLoading = false;
            } catch (error: any) {
                Sentry.captureException(error);
                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('alertMessage.somethingWentWrongFetchingNecessaryData'),
                });
                this.executionNotesApiResponse = error.response;
            }
        },
        async fetchExecutionHistory(): Promise<any> {
            try {
                this.isExecutionHistoryLoading = true;
                const executionHistory = await getExecutionHistory(this.execId);
                this.execHistory = executionHistory.data.history;
                this.execHistoriesRemapped =
                    this.execHistory.map(oneExecutionHistory => {
                        const {
                            reservation_data: reservationData = null,
                            execution_input: executionInput = null,
                            execution_result: executionOutput = null,
                        } = oneExecutionHistory.data;

                        const oneExecutionHistoryResult = {
                            state: oneExecutionHistory.state,
                            type: oneExecutionHistory.type,
                            ...(reservationData && { reservationData }),
                            ...(executionInput && { executionInput }),
                            ...(executionOutput && { executionOutput }),
                        };

                        return oneExecutionHistoryResult;
                    }) || [];
            } catch (error: any) {
                Sentry.captureException(error);
                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('alertMessage.failedToLoadNecessaryData'),
                });
            } finally {
                this.isExecutionHistoryLoading = false;
            }
        },
        onExecuteExecutionButtonClick(): void {
            const confirmButton = new Button({
                label: this.$i18n.t('generic.yes'),
                alertType: ALERT_TYPES.warning,
            });

            this.$eventBus.$emit('showAlert', {
                message: this.$i18n.t('alerts.areYouSureStartExecution'),
                type: ALERT_TYPES.warning,
                buttons: [confirmButton],
            });

            this.$eventBus.$off('buttonClicked');

            this.$eventBus.$once('buttonClicked', buttonId => {
                if (buttonId === confirmButton.id) {
                    this.executeExecution();
                }
            });
        },
        async executeExecution(): Promise<any> {
            try {
                this.$Progress.start();

                await executePlanImmediately({ execution_id: this.execId });

                this.$showSuccessAlert({
                    message: this.$i18n.t('orchestrationEngine.startExecutionSuccess'),
                });
                this.$Progress.finish();
                this.$router.go(0);
            } catch (error: any) {
                this.$Progress.fail();
                Sentry.captureException(error);
                this.$showErrorAlert('showAlert', {
                    message: this.$i18n.t('orchestrationEngine.startExecutionFail'),
                });
            }
        },
        async stopExecution(): Promise<any> {
            try {
                this.$Progress.start();

                // Hide modal
                this.stopExecutionModalVisible = false;

                // Get latest data of execution
                await this.requestExecution(this.execId);

                // Set latest status
                this.execDetails.status = this.getExecution.data.execution?.status;

                // Fetch latest notes
                await this.fetchExecutionNotes();

                this.$Progress.finish();
            } catch (error: any) {
                this.$Progress.fail();
                Sentry.captureException(error);
                this.$showErrorAlert('showAlert', {
                    message: this.$i18n.t(
                        'orchestrationEngine.executionsSearch.somethingWentWrongFetchingExecutionsData',
                    ),
                });
            }
        },
        async updateScheduledTime() {
            this.isUpdateScheduledTimeSidebarVisible = false;

            // Reuqest newest version of execution
            await this.requestExecution(this.execId);

            // Clone data from store
            const updatedExecDetails = { ...this.getExecution.data.execution };

            // Set data for execution with updated scheduled time
            this.execDetails = {
                ...this.execDetails,
                scheduled_for: this.$localeLibrary.getFormattedDateAndTimeWithMillisecondPrecisions(
                    updatedExecDetails.scheduled_for,
                ),
            };
        },
        async onCallbackExecution() {
            this.callbackExecutionModalIsVisible = false;

            // Reuqest newest version of execution
            await this.requestExecution(this.execId);

            // Clone data from store
            const updatedExecDetails = { ...this.getExecution.data.execution };

            // Set data for execution with updated scheduled time
            this.execDetails = {
                ...this.execDetails,
                status: updatedExecDetails.status,
            };
        },
        openCustomerCareRelatedPage(): void {
            let routeName = '';
            const tenetHasNewCcs = getOperatorConfigValue('cssDefault');

            if (this.executionInput.target_type === SEGMENT_ID_TYPES.SUBSCRIBER) {
                routeName = tenetHasNewCcs ? RouteNames.CCS_SUBSCRIBER_PAGE : RouteNames.USER_MANAGEMENT_SUBSCRIBER_V2;
            }

            if (this.executionInput.target_type === SEGMENT_ID_TYPES.ACCOUNT) {
                routeName = tenetHasNewCcs ? RouteNames.CCS_ACCOUNT_PAGE : RouteNames.USER_MANAGEMENT_ACCOUNT_V2;
            }

            if (this.executionInput.target_type === SEGMENT_ID_TYPES.USER) {
                routeName = tenetHasNewCcs ? RouteNames.CCS_USER_PAGE : RouteNames.USER_MANAGEMENT_USER_V2;
            }

            const routeData = this.$router.resolve({
                name: routeName,
                params: {
                    id: this.executionInput.target_id,
                },
            });

            window.open(routeData.href, '_blank');
        },
        openExecutionHistorySidebar(execution: any): void {
            this.isSidebarVisible = true;
            this.selectedExecution = execution;
        },
        selectTriggeredEvent(id: any): void {
            this.isSidebarForTriggeredEventVisible = true;
            this.selectedEventInSidebar = this.sidebarTriggeredEvents.find(execution => execution.id === id);
        },
        async selectSidebarRelatedExecution(id: any): Promise<any> {
            if (this.isRelatedExecutionClicked) {
                return;
            }
            try {
                this.isRelatedExecutionClicked = true;
                await this.requestExecution(id);
                const response = this.getExecution;
                // eslint-disable-next-line camelcase
                const planId = response.data.execution.plan_id;
                const routeData = this.$router.resolve({
                    name: RouteNames.ORCHESTRATION_ENGINE_EXECUTION_DETAILS,
                    params: {
                        id: planId,
                        execId: id,
                    },
                });
                window.open(routeData.href, '_blank');
            } catch (e: any) {
                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('alertMessage.somethingWentWrong'),
                });
            } finally {
                this.isRelatedExecutionClicked = false;
            }
        },
        renderEditor(editorkey: any, data: any = {}, holderKey: any): void {
            if (!this[editorkey]) {
                this[editorkey] = window.monaco.editor.create(document.getElementById(holderKey), {
                    value: JSON.stringify(data, null, '  '),
                    language: 'json',
                    readOnly: true,
                    automaticLayout: true,
                });
            } else {
                this[editorkey].setValue(JSON.stringify(data, null, '  '));
            }
        },
        handleSidebarState(state: any): void {
            if (!state) {
                this.selectedExecutionId = null;
            }
        },
        renderWorkflowDiagram(): void {
            this.$nextTick(() => {
                if (this.planDefinition) {
                    this.canvas = orchestrationEngineVisualizerHelper.drawPlanDiagram(
                        CANVAS_ID,
                        this.canvas,
                        this.planDefinition.states,
                        this.planDefinition.start_at,
                        {
                            ...orchestrationEngineVisualizerHelper.defaultSetup,
                            allowHighlightClick: false,
                        },
                        this.handleCanvasClick,
                    );
                    this.highlightWorkflowPath(this.canvas);
                }
            });
        },
        handleCanvasClick(figure: any): void {
            if (
                figure !== null &&
                (figure?.userData?.figureType === orchestrationEngineVisualizerHelper.FIGURE_TYPES.Block ||
                    figure?.userData?.figureType === orchestrationEngineVisualizerHelper.FIGURE_TYPES.Label)
            ) {
                const state = figure?.userData?.key;
                this.isSidebarVisible = true;
                this.selectedExecutionId = null;
                this.selectedExecution = this.executions.find(execution => execution.state === state);
            }
        },
        highlightWorkflowPath(canvas: any): void {
            if (canvas) {
                // Get list of keys in path
                const stateKeys = [
                    ...this.executions.reduce((arr, val) => {
                        arr.add(val.state);
                        return arr;
                    }, new Set()),
                ];

                // Get list for blocks on diagram
                const figures = canvas.getFigures().data.reduce((obj, val) => {
                    obj[val.userData.key] = val;
                    return obj;
                }, {});

                // Get list of blocks in path
                const figureArr = [];
                for (const key of stateKeys) {
                    figureArr.push(figures[key]);
                }

                // Highlight path
                orchestrationEngineVisualizerHelper.highlightPathToFigure(figureArr[0], figureArr, this.getColorSet());
            }
        },
        openStateHistoryModal(id: number): void {
            this.selectedExecution = this.listPageTableExecutions.find(execution => execution.id === id);

            this.stateHistoryModalVisible = true;
        },
        getColorSet(): { blockBorder: string; blockBackground: string; link: string } {
            if (this.execDetails.status === 'SUCCESS') {
                return orchestrationEngineVisualizerHelper.COLOR_SETS.green;
            }
            return orchestrationEngineVisualizerHelper.COLOR_SETS.red;
        },
        onZoomIn(): void {
            if (this.canvas) orchestrationEngineVisualizerHelper.canvasZoomIn(this.canvas);
        },
        onZoomOut(): void {
            if (this.canvas) orchestrationEngineVisualizerHelper.canvasZoomOut(this.canvas);
        },
    },
};
