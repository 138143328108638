import i18n from '@/i18n';
import { groupBy, map } from 'lodash';
import localeLibrary from '@/common/locale/localeLibrary';
import { LABEL_COLOR } from '@/common/labelsHelper';
import OutageInfo from '@/__new__/services/dno/osstmofiber/models/OutageInfo';
import VueI18n, { type TranslateResult } from 'vue-i18n';

export enum OUTAGE_STATUS {
    CANCELLED = 'Cancelled',
    IN_PROGRESS = 'In Progress',
    OPEN = 'Open',
    RESOLVED = 'Resolved',
}

export const OUTAGE_STATUS_TO_COLOR_MAP: Map<OUTAGE_STATUS, string | undefined> = new Map([
    [OUTAGE_STATUS.CANCELLED, LABEL_COLOR.red],
    [OUTAGE_STATUS.OPEN, LABEL_COLOR.red],
    [OUTAGE_STATUS.IN_PROGRESS, LABEL_COLOR.yellow],
    [OUTAGE_STATUS.RESOLVED, LABEL_COLOR.green],
]);

export const OUTAGE_STATUS_TO_LABEL: Map<OUTAGE_STATUS, TranslateResult> = new Map([
    [OUTAGE_STATUS.CANCELLED, i18n.t('generic.stateMap.canceled')],
    [OUTAGE_STATUS.OPEN, i18n.t('generic.stateMap.open')],
    [OUTAGE_STATUS.IN_PROGRESS, i18n.t('generic.stateMap.inProgress')],
    [OUTAGE_STATUS.RESOLVED, i18n.t('generic.stateMap.resolved')],
]);

export enum OUTAGE_TYPE {
    UNPLANNED = 1,
    PLANNED = 2,
}

export const outageTypeToLabelMap = new Map<OUTAGE_TYPE, VueI18n.Path>([
    [OUTAGE_TYPE.UNPLANNED, 'generic.outageTypeMap.unplanned'],
    [OUTAGE_TYPE.PLANNED, 'generic.outageTypeMap.planned'],
]);

type OutageAffectedResource = {
    id: string;
    type: 'account_id' | 'external_service_id' | 'ont_id';
};

export type OutageInfoResponse = {
    affected_resources?: OutageAffectedResource[];
    affected_area_id?: string;
    created_date_time: number;
    estimated_hours_to_resolution?: number;
    is_sent?: boolean;
    operator_name: string;
    outage_end_time_epoch?: number;
    outage_description: string;
    outage_id: string;
    outage_reason?: string;
    outage_start_time_epoch: number;
    outage_status: OUTAGE_STATUS;
    provider_id?: string;
    target_id: string;
    target_type: number;
    outage_type: OUTAGE_TYPE;
};

export function groupOutagesByOutageId(outageHistory: OutageInfoResponse[]) {
    const outages = outageHistory.map(o => new OutageInfo(OutageInfo.mapOutageFromBe(o)));
    const grouppedOutages = map(groupBy(outages, 'id'), arr => ({
        ...arr[0],
        isActive: arr[0].isActive,
        nestedRows: arr.map(
            ({ createdTime, endTime, description, reason, estimatedResolution, status }: Partial<OutageInfo>) => ({
                rowCreatedTime: createdTime ? localeLibrary.getFormattedDateAndTime(createdTime) : createdTime,
                rowEndTime: endTime ? localeLibrary.getFormattedDateAndTime(endTime) : endTime,
                rowDescription: description,
                rowReason: reason,
                rowResolution: estimatedResolution ? `${estimatedResolution}h` : '',
                rowStatus: status,
            }),
        ),
    }));
    return grouppedOutages;
}

export const getOutageTypeLabel = (source: OUTAGE_TYPE) =>
    (outageTypeToLabelMap.has(source) ? outageTypeToLabelMap.get(source) : 'generic.N/A') as VueI18n.Path;
