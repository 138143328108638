import * as Sentry from '@sentry/vue';

const ProgressBar = {
    install(Vue: any) {
        Vue.prototype.$withProgressBar = async (
            asyncFunc: () => Promise<void>,
            { errorHandler, successHandler }: any,
        ) => {
            try {
                Vue.prototype.$Progress.start();
                await asyncFunc();
                if (successHandler) {
                    await successHandler();
                }
                Vue.prototype.$Progress.finish();
            } catch (e) {
                Vue.prototype.$Progress.fail();

                Sentry.captureException(e);

                if (errorHandler) {
                    errorHandler(e);
                }
            }
        };
    },
};

export default ProgressBar;
