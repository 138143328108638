<template>
    <TableFiltersRenderless :entities="voucherCodes">
        <template
            #default="{
                // state
                allFilters,
                filteredEntities,
                // methods
                onFilterAdded,
                removeFilter,
                removeAllFilters,
            }"
        >
            <div>
                <div class="d-flex align-items-center justify-content-end position-relative">
                    <ResponseModalButton
                        :response="voucherCodesApiResponse"
                        :title="$i18n.t('customerCare.voucherHistory.voucherHistory')"
                    />
                    <FilterTable
                        :columns="tableColumnsData"
                        :multiselectWidth="{ width: '12rem' }"
                        @filterAdded="onFilterAdded"
                    >
                        <template #button="slotProps">
                            <IconButton
                                :label="$i18n.t('generic.filter')"
                                :icon="ICON_TYPES.FILTER"
                                @iconClick="slotProps.clickHandler"
                            />
                        </template>
                    </FilterTable>
                </div>

                <TableFiltersTags
                    :filterTableMixin="{ allFilters }"
                    class="mt-1 mb-3"
                    @removeFilter="removeFilter"
                    @removeAllFilters="removeAllFilters"
                />

                <AppTable
                    :entities="filteredEntities"
                    :isDefaultHoverEnabled="false"
                    :isCustomButtonPassed="true"
                    :disableBoxShadow="true"
                    :isPaginationEnabled="true"
                    :canSelectColumns="true"
                    :newDesign="true"
                    :columnsData="tableColumnsData"
                    tableKey="user/voucher-history"
                />
            </div>
        </template>
    </TableFiltersRenderless>
</template>

<script>
// PARTIAL COMPONENTS
import AppTable from '@/components/partials/AppTable.vue';
import TableFiltersRenderless from '@/components/filters/TableFiltersRenderless.vue';
import TableFiltersTags from '@/components/filters/TableFiltersTags.vue';
import FilterTable from '@/components/partials/FilterTable.vue';
import IconButton from '@/components/partials/IconButton.vue';
import { ICON_TYPES } from '@/common/iconHelper';
import ResponseModalButton from '@/components/partials/ResponseModalButton.vue';

// HELPERS
import * as Sentry from '@sentry/vue';
import { getBeautifulBoolean } from '@/common/formatting';
import { getMultiLangFieldValueByLocale } from '@/common/entities/entityHelper';
import tableColumnType from '@/common/filterTable';
import moment from 'moment';

// HTTP
import { getUserVoucherCodes } from '@/modules/rewards/http/vouchers';
import supportButtonMixin from '@/components/alerts/supportButtonMixin';
import { ALERT_TYPES } from '@/common/alerts/Alert';

export default {
    name: 'VoucherHistory',
    components: {
        AppTable,
        TableFiltersRenderless,
        TableFiltersTags,
        FilterTable,
        IconButton,
        ResponseModalButton,
    },
    mixins: [supportButtonMixin],
    props: {
        targetId: {
            type: String,
            required: true,
        },
        targetType: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            voucherCodes: [],
            voucherCodesApiResponse: null,
            ICON_TYPES,
        };
    },
    computed: {
        unusableReasons() {
            return new Map([
                [1, this.$i18n.t('generic.used')],
                [2, this.$i18n.t('generic.notActive')],
                [3, this.$i18n.t('generic.expired')],
            ]);
        },
        tableColumnsData() {
            return [
                {
                    name: this.$i18n.t('generic.name'),
                    key: 'voucherNameLabel',
                    field: 'voucherNameLabel',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('customerCare.voucherHistory.voucherCode'),
                    key: 'voucher_code',
                    field: 'voucher_code',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('customerCare.voucherHistory.voucherSetId'),
                    key: 'voucher_set_id',
                    field: 'voucher_set_id',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('generic.type'),
                    key: 'voucher_type',
                    field: 'voucher_type',
                    filterType: tableColumnType.TEXT_LIMITED_OPTIONS,
                    limitedOptions: [...new Set(this.voucherCodes.map(entity => entity.voucher_type))],
                },
                {
                    name: this.$i18n.t('generic.usableStartDate'),
                    key: 'usability_start_time',
                    formatter: this.$localeLibrary.getFormattedDate,
                    field: 'usabilityStartTimeMoment',
                    filterType: tableColumnType.DATE,
                },
                {
                    name: this.$i18n.t('generic.usable'),
                    key: 'usableLabel',
                    field: 'usableLabel',
                    filterType: tableColumnType.TEXT_LIMITED_OPTIONS,
                    limitedOptions: [...new Set(this.voucherCodes.map(entity => entity.usableLabel))],
                },
                {
                    name: this.$i18n.t('generic.unusableReason'),
                    key: 'unusableReasonLabel',
                    field: 'unusableReasonLabel',
                    filterType: tableColumnType.TEXT_LIMITED_OPTIONS,
                    limitedOptions: [...new Set(this.voucherCodes.map(entity => entity.unusableReasonLabel))],
                },
            ];
        },
    },
    async created() {
        this.$Progress.start();
        try {
            const voucherCodesResponse = await getUserVoucherCodes(this.targetId, this.targetType);
            this.voucherCodesApiResponse = voucherCodesResponse;
            if (voucherCodesResponse && Array.isArray(voucherCodesResponse)) {
                this.voucherCodes = voucherCodesResponse.map(el => ({
                    ...el,
                    voucherNameLabel: getMultiLangFieldValueByLocale(el.voucher_name),
                    usabilityStartTimeMoment: moment(el.usability_start_time * 1000),
                    usableLabel: getBeautifulBoolean(el.usable),
                    unusableReasonLabel: this.unusableReasons.get(el.unusable_reason),
                }));
            } else {
                this.voucherCodes = [];
            }
            this.$Progress.finish();
        } catch (e) {
            Sentry.captureException(e);
            this.$Progress.fail();
            this.showSupportAlert(this.$i18n.t('alertMessage.somethingWentWrong'), ALERT_TYPES.error);
            this.voucherCodesApiResponse = e.response;
        }
    },
};
</script>
