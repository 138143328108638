import UserManagerBaseModel from '@/__new__/services/dno/user/models/UserManagerBaseModel';
import {
    USER_MANAGER_HIERARCHY,
    getUserManagerEntityTypeById,
} from '@/__new__/features/customerCare/common/customerCareHelper';
import { TranslateResult } from 'vue-i18n';

type AccessRoleResponse = {
    caller_id: string;
    role: string;
    email: string;
};

export default class AccessRole extends UserManagerBaseModel {
    targetId: string;
    targetType: TranslateResult | string;
    targetTypeId: USER_MANAGER_HIERARCHY;
    roleName: string;
    email: string;

    constructor({ targetId, targetTypeId, roleName, email }: AccessRole) {
        super();
        this.targetId = this.validateString('targetId', targetId) ? targetId : '';
        this.targetType = this.validateNumberInteger('targetType', targetTypeId)
            ? getUserManagerEntityTypeById(targetTypeId)
            : '';
        this.targetTypeId = targetTypeId;
        this.roleName = roleName || '';
        this.email = this.validateString('email', email) ? email : '';
    }

    static remapFromAccessList(
        { caller_id: uid, role, email }: AccessRoleResponse,
        targetTypeId: USER_MANAGER_HIERARCHY = USER_MANAGER_HIERARCHY.USER,
    ) {
        return {
            targetId: uid,
            targetTypeId,
            roleName: role,
            email,
        };
    }
}
