<template>
    <div>
        <div
            :class="{ error: error }"
            :disabled="disabled"
            class="name-input-wrapper pb-3"
        >
            <input
                :placeholder="placeholder"
                :value="value"
                :disabled="disabled"
                type="text"
                class="border-0 no-outline w-100"
                @input="e => $emit('input', e.target.value)"
            />
            <div class="input-holder d-inline-flex">
                {{ value || placeholder }}
            </div>
        </div>
        <div
            v-show="invalidMessage"
            class="invalid-text mt-2"
        >
            {{ invalidMessage }}
        </div>
    </div>
</template>

<script>
export default {
    name: 'NameInput',
    props: {
        placeholder: {
            type: String,
            default: '',
        },
        value: {
            type: String,
            default: '',
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        invalidMessage: {
            type: String,
            default: null,
        },
        error: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/palette';
@import '../../../assets/scss/icons';

$icon-path: '../../../assets/icons/';

.name-input-wrapper {
    position: relative;
    min-width: 184px;
    font-size: 18px;
    font-weight: bold;

    border-bottom: 1px solid $gray5;

    &.error {
        border-bottom-color: $red;
    }

    &:hover:not([disabled='disabled']) {
        .input-holder:after {
            visibility: visible;
        }
    }

    // hack for input's dynamic width
    .input-holder {
        visibility: hidden;
        max-width: 100%;
        overflow: hidden;

        white-space: pre;
        font-size: inherit;
        font-weight: inherit;

        &:before {
            content: ' ';
        }

        &:after {
            visibility: hidden;
            margin-left: 10px;
            content: url($icon-path + $icon-edit);
            padding-top: 4px;
            height: 24px;
            vertical-align: middle;
        }
    }

    input {
        position: absolute;
        left: 0;
        top: 0;
        padding-left: 8px;

        color: $navy;

        font-size: inherit;
        font-weight: inherit;
        background: none;

        &::placeholder {
            color: $gray30;
        }

        &:placeholder-shown + .input-holder:after {
            visibility: visible;
        }

        &:focus + .input-holder:after {
            visibility: hidden;
        }
    }
}

.invalid-text {
    display: block;

    font-size: 12px;
    color: $red;
    border-top: 2px solid $red;
}
</style>
