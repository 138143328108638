import { render, staticRenderFns } from "./Tenants.vue?vue&type=template&id=da43e22a&scoped=true"
import script from "./Tenants.vue?vue&type=script&lang=js"
export * from "./Tenants.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "da43e22a",
  null
  
)

export default component.exports