<template>
    <TemplateEditorBase :isPresetEditor="true" />
</template>

<script>
import TemplateEditorBase from '@/__new__/features/pc/templates/TemplateEditorBase.vue';

export default {
    name: 'PresetTemplateEditor',

    components: {
        TemplateEditorBase,
    },
};
</script>
