<template>
    <div>
        <div
            v-for="(rule, ruleIndex) in rules"
            :key="ruleIndex"
        >
            <div v-if="rule.type === QuietHoursTypes.daily.field">
                <div class="card-container">
                    <div
                        v-if="title"
                        class="quiet-hours-card-title mb-2"
                    >
                        {{ title }}
                    </div>
                    <div class="quiet-hours-card-title">
                        {{ rule.name }}
                    </div>
                    <div class="card-list">
                        <ul>
                            <li
                                v-for="(dailyRule, index) in getDailyRules(ruleIndex)"
                                :key="index"
                                class="card-content list"
                            >
                                {{ dailyRule }}
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div v-else>
                <div class="card-container">
                    <div class="quiet-hours-card-title">
                        {{ rule.name }}
                    </div>
                    <div class="card-list">
                        <ul>
                            <li
                                v-for="(holidayRule, index) in getHolidayRules(ruleIndex)"
                                :key="index"
                                class="card-content list"
                            >
                                {{ holidayRule }}
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment';
import { dayRangeTypes, holidayRangeTypes, QuietHoursTypes, dayOfWeek } from './quietHoursHelper';

export default {
    name: 'QuietHoursDisplayRules',
    props: {
        title: {
            type: String,
            default: '',
        },
        rules: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            QuietHoursTypes,
        };
    },
    methods: {
        getDailyRules(index) {
            return this.rules[index].days.map(day => {
                const dailyRule = `${this.$i18n.t(dayOfWeek[day.day_of_week])}`;
                const ranges =
                    day.time_frame.type === dayRangeTypes.allDay.field
                        ? ` ${this.$i18n.t('quietHours.allDay')}`
                        : day.time_frame.ranges.map(range => ` ${range.value[0]} - ${range.value[1]}`);
                return `${dailyRule}${ranges}`;
            });
        },
        getHolidayRules(index) {
            return this.rules[index].holidays
                .filter(holiday => holiday.enabled)
                .map(holiday => {
                    const date =
                        holiday.date.type === holidayRangeTypes.single.field
                            ? `${this.$i18n.t('generic.on')} ${this.formatDate(holiday.date.value)}`
                            : `${this.$i18n.t('generic.from')} ${this.formatDate(holiday.date.value[0])} ${this.$i18n.t(
                                  'generic.to',
                              )} ${this.formatDate(holiday.date.value[1])}`;
                    return `${holiday.name} ${date}`;
                });
        },
        formatDate(date) {
            return moment(date, 'DD:MM:YYYY').format('ddd, D MMM YYYY');
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/_palette.scss';
@import '~@/assets/scss/_icons';

$icon-path: '~@/assets/icons/';

.card-container {
    margin-bottom: 0.625rem;
    padding: 0.625rem 1rem;
    border-radius: 0.5rem;
    background-color: rgba($color: $blue, $alpha: 0.15);

    .quiet-hours-card-title {
        color: $blue;
        font-size: 0.75rem;
        font-weight: 600;
    }
    .card-list {
        .card-content {
            display: flex;
            flex-direction: row;
            font-size: 0.875rem;
            color: $gray90;
        }

        li {
            display: flex;
        }

        ul {
            margin-bottom: 0;
        }
    }
}
</style>
