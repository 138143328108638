import { TranslateResult } from 'vue-i18n';
import { uniq, cloneDeep } from 'lodash';
import { getFormattedAmount, secondsToDate } from '@/common/formatting';
import i18n from '@/i18n';

export enum BUCKET_TYPE {
    UNLIMITED = 1,
    NON_MONETARY = 2,
    MONETARY = 3,
}

// move to chargingSpecificationHelper once migrated to TS.
export enum CS_SERVICE_TYPE {
    DATA = 1,
    VOICE = 2,
    SMS = 3,
    DPP = 4,
    MMS = 5,
    API = 6,
}

export type CharginSpecGroupId = string;
export type BucketId = string;
export type Bucket = {
    amount_used: number;
    amount_available?: number;
    amount_initial?: number;
    charging_spec_id: string;
    end_time?: number;
    is_active: boolean;
    limit?: number;
    name: string;
    offer_id: string;
    offer_name: string;
    order_id: string;
    cs_group_id: CharginSpecGroupId;
    cs_group_name: string;
    cs_group_parent: {
        id?: string;
        name?: string;
        type?: string;
        parent?: Record<string, never>;
    };
    rate?: string;
    rate_period: string;
    rate_tiers?: {
        thresholds: number[];
        rates: string[];
    };
    service_type: CS_SERVICE_TYPE;
    service_types?: CS_SERVICE_TYPE[];
    should_group_balance: boolean;
    start_time: number;
    transaction_id: string;
    type?: BUCKET_TYPE;
    cs_group_priority?: number;
    is_non_expiring?: boolean;
    is_shareable?: boolean;
    is_shared?: boolean;
    owned?: boolean;
    owner_id?: string;
    usage_counters: {
        bucket_counters: any;
        policy_counters: any;
    };
    will_recur: boolean;
};

export type BucketFormatted = Bucket & {
    amountUsedFormatted?: ReturnType<typeof getFormattedAmount>;
    bucketId: BucketId;
    bucketType: BUCKET_TYPE;
    expiryDate?: ReturnType<typeof getBucketExpiryValue>;
    productName?: string;
    serviceTypeFirst: CS_SERVICE_TYPE;
};

export function findBucketType(bucket: Bucket, chargingSpecs: any[]) {
    const bucketCsData = chargingSpecs.find(cs => cs.id === bucket.charging_spec_id);
    if (
        Object.prototype.hasOwnProperty.call(bucketCsData, 'rate') &&
        (bucketCsData.rate === 0 || bucketCsData.rate === '0')
    ) {
        return BUCKET_TYPE.UNLIMITED;
    }
    if (Object.prototype.hasOwnProperty.call(bucketCsData, 'unit_amount')) {
        return BUCKET_TYPE.NON_MONETARY;
    }
    if (
        !Object.prototype.hasOwnProperty.call(bucketCsData, 'unit_amount') &&
        Object.prototype.hasOwnProperty.call(bucketCsData, 'rate') &&
        bucketCsData.rate !== 0
    ) {
        return BUCKET_TYPE.MONETARY;
    }
    return null;
}

const getBucketExpiryValue = ({
    end_time,
    is_active,
    is_non_expiring,
    will_recur,
}: Bucket): ReturnType<typeof secondsToDate> | TranslateResult => {
    if (is_active && (is_non_expiring || will_recur)) {
        return is_non_expiring ? i18n.t('generic.unlimitedCapital') : i18n.t('generic.recurring');
    }
    if (end_time) {
        return secondsToDate(end_time);
    }

    return i18n.t('generic.N/A');
};

export function mapBuckets(buckets: Record<BucketId, Bucket>, chargingSpecs: any) {
    const bucketsPerBucketType: Record<BUCKET_TYPE, Array<Bucket & { bucketId: BucketId; bucketType: BUCKET_TYPE }>> = {
        [BUCKET_TYPE.NON_MONETARY]: [],
        [BUCKET_TYPE.MONETARY]: [],
        [BUCKET_TYPE.UNLIMITED]: [],
    };

    // populating bucketsPerBucketType with proper buckets
    if (buckets) {
        Object.entries(buckets).forEach(([bucketId, bucketData]) => {
            const bucketType = bucketData.type || findBucketType(bucketData, chargingSpecs);

            if (bucketType) {
                bucketsPerBucketType[bucketType].push({
                    ...bucketData,
                    bucketId,
                    bucketType,
                });
            }
        });
    }

    const formattedBuckets: Record<BUCKET_TYPE, Record<CharginSpecGroupId, BucketFormatted[]>> = {
        [BUCKET_TYPE.NON_MONETARY]: {},
        [BUCKET_TYPE.MONETARY]: {},
        [BUCKET_TYPE.UNLIMITED]: {},
    };

    Object.values(bucketsPerBucketType)
        .filter(buckets => buckets.length)
        .forEach(bucketList => {
            const bucketType = bucketList[0].bucketType;
            const uniqueCSGroups: CharginSpecGroupId[] = uniq(bucketList.map(bucket => bucket.cs_group_id));

            uniqueCSGroups.forEach(uniqueCSGroupsId => {
                const bucketsForProductIds = bucketList.filter(bucket => bucket.cs_group_id === uniqueCSGroupsId);

                formattedBuckets[bucketType][uniqueCSGroupsId] = bucketsForProductIds.map(bucket => {
                    const serviceType = bucket.service_types?.[0] || bucket.service_type;

                    if (bucketType === BUCKET_TYPE.UNLIMITED || bucketType === BUCKET_TYPE.MONETARY) {
                        return {
                            ...bucket,
                            amountUsedFormatted: getFormattedAmount(serviceType, bucket.amount_used),
                            expiryDate: getBucketExpiryValue(bucket),
                            serviceTypeFirst: serviceType,
                        };
                    }

                    return {
                        ...bucket,
                        productName: bucket.cs_group_name,
                        serviceTypeFirst: serviceType,
                    };
                });
            });
        });

    return formattedBuckets;
}

export function filterActive(mappedBalances: any) {
    const mappedBalancesCopy = cloneDeep(mappedBalances);
    Object.entries(mappedBalances).forEach(([bucketType, bucketsByType = []]: any[]) => {
        Object.values(bucketsByType).forEach((bucketsList: any) => {
            const csGroupId = bucketsList[0].cs_group_id;
            const activeBuckets = bucketsList.filter((bucket: any) => bucket.is_active);
            if (activeBuckets.length === 0) {
                delete mappedBalancesCopy[bucketType][csGroupId];
            } else {
                mappedBalancesCopy[bucketType][csGroupId] = activeBuckets;
            }
        });
    });

    return mappedBalancesCopy;
}

export default {};
