<template>
    <div class="page-header-wrap">
        <div class="page-header">
            <div class="d-flex align-items-center">
                <h1>
                    {{ headerTitle }}
                </h1>
                <span
                    v-if="shouldDisplayEntitiesCount"
                    class="body-sm gray-400 pl-1"
                >
                    ({{ entitiesCount }})
                </span>
            </div>

            <div class="d-flex align-items-center">
                <SearchBox
                    v-if="isSearchEnabled"
                    class="header-searchbox mr-3"
                    @input="query => $emit('input', query)"
                />

                <div
                    v-if="$slots.button"
                    class="mr-3"
                >
                    <slot name="button" />
                </div>

                <div
                    v-if="$slots.filterTable"
                    class="mr-3"
                >
                    <slot name="filterTable" />
                </div>

                <div
                    v-if="$slots.headerButtons"
                    class="d-flex"
                >
                    <slot name="headerButtons" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// Vue components
import SearchBox from '@/components/partials/inputs/SearchBox.vue';

export default {
    name: 'AppHeader',

    components: {
        SearchBox,
    },

    props: {
        pageTitle: {
            type: String,
            default: null,
        },
        isSearchEnabled: {
            type: Boolean,
            default: false,
        },
        entitiesCount: {
            type: Number,
            default: NaN,
        },
    },

    computed: {
        headerTitle() {
            return this.pageTitle ? this.pageTitle : this.$route.name;
        },
        shouldDisplayEntitiesCount() {
            return Boolean(this.entitiesCount) || this.entitiesCount === 0;
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/palette';
@import '~@/assets/scss/sidebar';
@import '~@/assets/scss/z-indexes';
@import '~@/assets/scss/layout';
@import '~@/assets/scss/consistency-spacing';
@import '~@/assets/scss/consistency-typography';

.page-content {
    // class in AuthorizedContainer.vue that wraps all pages content
    .page-header {
        position: fixed;
        top: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        width: calc(100% - #{$sidebarFullWidth});
        padding: $spacing-m $spacing-xxl;
        box-shadow: $box-shadow-default;
        background-color: $white;
        line-height: 1;
        z-index: $page-layout-item-z-index;
        min-height: 4.25rem;
        height: $header-height;

        &-wrap {
            min-height: $header-height;
        }
    }

    &.compact-sidebar {
        .page-header {
            width: calc(100% - #{$sidebarCompactWidth});
        }
    }

    &.disable-sidebar {
        .page-header {
            width: 100%;
        }
    }
}

.header-searchbox {
    width: 19rem;
}
</style>
