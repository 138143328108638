import http from '@/http';
import type { AxiosPromise } from 'axios';

import type {
    ReportRequestParams,
    ReportDefinitionResponse,
    FetchReportsResponse,
    FetchReportResponse,
    ArchiveReportResponse,
    GetArchiveReportStatusResponse,
} from '@/__new__/services/dno/reports/common/reportsHelper';

export function fetchReportsDefinitionsQrd(): Promise<ReportDefinitionResponse[]> {
    return http('/v3reports/definitionsqrd').then(resp => resp.data.data);
}

export function fetchQrdReports({
    definitionId,
    startDate,
    endDate,
    size = 100,
    pageToken,
}: ReportRequestParams & { size?: number; pageToken?: string }): Promise<FetchReportsResponse> {
    const params: Record<string, any> = {
        definitionId,
        startDate,
        endDate,
        timezone: 'Etc/UTC',
        size,
    };

    if (pageToken) {
        params['page-token'] = pageToken;
    }

    return http('/v3reports/reportsqrd', {
        params,
    }).then(resp => resp.data);
}

export function generateQrdReport({ definitionId, startDate, endDate, timezone }: ReportRequestParams) {
    return http.post('/v3reports/generateqrd', {
        definitionId,
        startDate,
        endDate,
        timezone,
    });
}

export function fetchQrdReport({
    definitionId,
    startDate,
    endDate,
    timezone,
}: ReportRequestParams): Promise<FetchReportResponse['data']> {
    return http('/v3reports/fetchqrd', {
        params: {
            definitionId,
            startDate,
            endDate,
            timezone,
        },
    }).then(resp => resp.data.data);
}

export function publishQrdReport({ definitionId, startDate, endDate, timezone }: ReportRequestParams) {
    return http.post('/v3reports/publishqrd', {
        definitionId,
        startDate,
        endDate,
        timezone,
    });
}

export function previewQrdReport({
    definitionId,
    startDate,
    endDate,
    timezone,
}: ReportRequestParams): AxiosPromise<FetchReportResponse> {
    return http.post('/v3reports/previewqrd', {
        definitionId,
        startDate,
        endDate,
        timezone,
    });
}

export function startQrdArchiveCreation(data: ReportRequestParams): AxiosPromise<ArchiveReportResponse> {
    return http({
        method: 'POST',
        url: '/v3reports/archiveqrd',
        data,
    });
}

export function getQrdArchiveReportStatus(taskId: string): AxiosPromise<GetArchiveReportStatusResponse | string> {
    return http({
        url: `/v3reports/archivestatusqrd`,
        params: {
            taskId,
        },
    });
}

export default {
    fetchReportsDefinitionsQrd,
    fetchQrdReports,
    generateQrdReport,
    fetchQrdReport,
    publishQrdReport,
    previewQrdReport,
    startQrdArchiveCreation,
    getQrdArchiveReportStatus,
};
