import { LocalizedString } from '@/http';

export enum TIME_REFERENCE {
    BILL_AVAIABLE_IN = 1,
    BILL_DUE_IN = 2,
    BILL_PAST_DUE = 3,
}

export enum TIME_UNIT {
    DAY = 1,
}

export enum ACTION_TYPE {
    SEND_EMAIL = 1,
    RETRY_PAYMENT = 2,
    SOFT_SUSPENSION = 3,
    HARD_SUSPENSION = 4,
}

export interface ScheduleEntry {
    time_reference: TIME_REFERENCE;
    time_unit: TIME_UNIT;
    time_amount: number;
    actions: any[];
}

export interface DunningProfile {
    description: LocalizedString;
    name: LocalizedString;
    schedule: ScheduleEntry[];
}
