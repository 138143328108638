<template>
    <div class="display-flex align-items-center number f-number">
        <Dropdown
            v-show="isOperationVisible"
            :items="operationLabels"
            :colorSchema="Styles.White"
            :selected="[value && typesToLabels[value.op]]"
            class="mr-3"
            :isFilterOperation="true"
            :widthDependsOnContent="true"
            @selected="opLabel => onChange({ op: labelsToTypes[opLabel] })"
        />

        <input
            v-if="!isDropdownInput && !isNoArgOperation"
            :class="['no-outline', { invalid: !isValid }]"
            :value="value && value.value && value.value[0]"
            type="number"
            title="Number"
            step="0.01"
            @change="event => onChange({ value: [event.target.value, value.value[1]] })"
        />

        <Dropdown
            v-else-if="!isNoArgOperation"
            :items="predefinedValues"
            :colorSchema="Styles.White"
            :selected="[value && value.value && value.value[0]]"
            @selected="selectedValue => onChange({ value: [selectedValue, value.value[1]] })"
        />

        <template v-if="displayInput2 && !isNoArgOperation">
            <span class="pl-3">and</span>
            <input
                v-if="!isDropdownInput"
                :class="['no-outline ml-3', { invalid: !isValid }]"
                :value="value && value.value && value.value[1]"
                type="number"
                title="Number"
                step="0.01"
                @change="event => onChange({ value: [value.value[0], event.target.value] })"
            />

            <Dropdown
                v-else
                :selected="[value && value.value && value.value[1]]"
                :items="predefinedValues"
                :colorSchema="Styles.White"
                class="ml-3"
                @selected="selectedValue => onChange({ value: [value.value[0], selectedValue] })"
            />
        </template>

        <Dropdown
            v-if="displayUnits && !isNoArgOperation"
            :selected="[value && value.unit]"
            :items="units"
            :colorSchema="Styles.White"
            class="ml-3"
            @selected="selectedValue => onChange({ unit: selectedValue })"
        />

        <template v-else-if="displayPeriodUnits && !isNoArgOperation">
            <Dropdown
                :selected="[value && value.unit]"
                :items="periodUnits"
                :colorSchema="Styles.White"
                class="mx-3"
                @selected="selectedValue => onChange({ unit: selectedValue })"
            />
            ago
        </template>
    </div>
</template>

<script>
import Dropdown, { Styles } from '@/components/partials/Dropdown.vue';
import {
    NumberOperations,
    PropFilterNumberOperationLabels,
    PropFilterNumberOperationLabelToType,
    PropFilterNumberOperationToLabel,
    NoArgOperations,
} from '@/common/filters/InputOperations';

export default {
    name: 'FNumberInput',
    components: { Dropdown },
    props: {
        changeCallback: { type: Function, default: null },
        displayOperation: { type: Boolean, default: true },
        displayPeriod: { type: Boolean, default: false },
        periodUnitLabels: { type: Array, default: null },
        initialValue: { type: Object, default: null },
    },
    data() {
        return {
            Styles,
            value: this.initialValue, // override
        };
    },
    computed: {
        isValid() {
            // override
            return true;
        },
        displayInput2() {
            return this.value && this.value.op === NumberOperations.Between;
        },
        isDouble() {
            // override
            return false;
        },
        isOperationVisible() {
            // override
            return this.displayOperation;
        },
        isDropdownInput() {
            // override
            return false;
        },
        predefinedValues() {
            // override
            return null;
        },
        displayUnits() {
            // override
            return false;
        },
        units() {
            // override
            return null;
        },
        displayPeriodUnits() {
            // override
            return this.displayPeriod;
        },
        periodUnits() {
            // override
            return this.periodUnitLabels;
        },
        typesToLabels() {
            return PropFilterNumberOperationToLabel;
        },
        labelsToTypes() {
            return PropFilterNumberOperationLabelToType;
        },
        operationLabels() {
            return PropFilterNumberOperationLabels;
        },
        isNoArgOperation() {
            return this.value && NoArgOperations[this.value.op];
        },
    },
    methods: {
        onChange(partialValue) {
            this.onValueChange(partialValue);
            this.$emit('change', partialValue);
            if (this.changeCallback) this.changeCallback();
        },
        onValueChange(partialValue = {}) {
            return partialValue;
        }, // override
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/palette';
@import '~@/assets/scss/mixins';

.number {
    input {
        height: 32px;
        padding: 6px 12px;
        width: 120px;

        border: 1px solid $gray5;
        border-radius: 0.5rem;

        &.invalid {
            border-color: $red;
        }
    }

    // hide spinbox
    input[type='number']::-webkit-inner-spin-button,
    input[type='number']::-webkit-outer-spin-button {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        margin: 0;
    }
    input[type='number'] {
        -moz-appearance: textfield;
    }

    .dropdown {
        @include custom-height-dropdown(32px);
        @include custom-width-dropdown(180px);
    }
}
</style>
