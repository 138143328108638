import { scheduleInitValue } from '@/__new__/services/dno/charging/common/chargingSpecificationHelper';
import cloneDeep from 'lodash/cloneDeep';

// see example data in the test pcCondiitonParser.spec.js
export default (id, pcObject) => {
    const portalConditionModel = cloneDeep(scheduleInitValue);
    Object.values(portalConditionModel.days).forEach(dayOfWeek => {
        const pcDayValue = pcObject.schedule[dayOfWeek.apiKey];
        // select the days
        const dayOfWeekSelected = pcDayValue.length > 0;
        if (dayOfWeekSelected) {
            dayOfWeek.active = true;
        }
        // get time slot data, it's the same value for
        // every day
        if (dayOfWeekSelected) {
            portalConditionModel.timeSlots = [...pcDayValue];
        }
    });
    return {
        type: 'schedule',
        data: portalConditionModel,
        uuid: id,
    };
};
