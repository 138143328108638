
import Vue from 'vue';

// Helpers
import { USER_MANAGER_HIERARCHY } from '@/__new__/features/customerCare/common/customerCareHelper';
import * as Sentry from '@sentry/vue';
import RouteNames from '@/router/routeNames';

// HTTP
import customerCareHTTP from '@/__new__/services/dno/user/http/customer-care';

export default Vue.extend({
    name: 'ReferralsInformationTile',
    components: {},
    data() {
        return {
            referralCode: '',
            referrersAccount: '',
            referralsSubmitted: '',

            RouteNames,
        };
    },
    created() {
        this.fetchTileData();
    },
    methods: {
        fetchTileData() {
            this.$withProgressBar(
                async () => {
                    this.$emit('isDataLoadingUpd', true);
                    await Promise.all([
                        this.fetchReferralCode(),
                        this.fetchReferrerInfo(),
                        this.fetchReferralHistory(),
                    ]);
                    this.$emit('isDataLoadingUpd', false);
                },
                {
                    errorHandler: () => {
                        this.$alert(this.$i18n.t('alertMessage.somethingWentWrongFetchingNecessaryData'));
                        this.$emit('isDataLoadingUpd', false);
                    },
                },
            );
        },
        async fetchReferralCode() {
            try {
                const {
                    data: { referral_code: refCode },
                } = await customerCareHTTP.getReferralCode(this.$route.params.id, USER_MANAGER_HIERARCHY.ACCOUNT);
                this.referralCode = refCode;
            } catch (error) {
                this.$alert(this.$i18n.t('alertMessage.somethingWentWrongFetchingNecessaryData'));
                Sentry.captureException(error);
            }
        },
        async fetchReferrerInfo() {
            try {
                const response = await customerCareHTTP.getReferrerInfo(
                    this.$route.params.id,
                    USER_MANAGER_HIERARCHY.ACCOUNT,
                );
                this.referrersAccount = response?.data?.referrers?.[0]?.referrer_target_id || '';
            } catch (error) {
                this.$alert(this.$i18n.t('alertMessage.somethingWentWrongFetchingNecessaryData'));
                Sentry.captureException(error);
            }
        },
        async fetchReferralHistory() {
            try {
                const response = await customerCareHTTP.getReferralHistory(
                    this.$route.params.id,
                    USER_MANAGER_HIERARCHY.ACCOUNT,
                );
                this.referralsSubmitted = response?.data?.referral_history?.length || '';
            } catch (error) {
                this.$alert(this.$i18n.t('alertMessage.somethingWentWrongFetchingNecessaryData'));
                Sentry.captureException(error);
            }
        },
    },
});
