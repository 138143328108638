import { DnoResponseBase } from '@/http/index';
import { TranslateResult } from 'vue-i18n';
import i18n from '@/i18n';
import { CONDITION_TYPES } from '@/__new__/services/dno/charging/common/chargingSpecificationHelper';

export interface CustomConditionDnoResponce extends DnoResponseBase {
    charging_request_fields: Record<CHARGING_REQUEST_FIELDS, Record<string, DnoCustomCondition>>;
}

export enum CHARGING_REQUEST_FIELDS {
    DATA = 'data',
    VOICE = 'voice',
    SMS = 'sms',
    MMS = 'mms',
    API = 'api',
}

export type DnoCustomCondition = {
    name: string;
    description: string;
    operator: CUSTOM_CONDITION_OPERATOR[];
    type: CUSTOM_CONDITION_TYPE;
    allowed_values: Record<string, any>;
};

export type CustomCondition = {
    id: string;
    name: string;
    description: string;
    type: CUSTOM_CONDITION_TYPE;
    inputType: string;
    operator: { id: CUSTOM_CONDITION_OPERATOR; label: TranslateResult }[];
    selectedOperator: { id: CUSTOM_CONDITION_OPERATOR; label: string } | null;
    allowMultipleValues: boolean;
    allowedValues?: CoditionOption<string>[];
    selectedAllowedValues?: CoditionOption<string>[];
    selectedAllowedValue: CoditionOption<string> | null;
    value: string | number | boolean | null;
    values: string[] | number[];
};

export enum CUSTOM_CONDITION_KEYS {
    SELECTED_ALLOWED_VALUES = 'selectedAllowedValues',
    SELECTED_ALLOWED_VALUE = 'selectedAllowedValue',
    VALUES = 'values',
    VALUE = 'value',
}

export type CoditionOption<T> = {
    id: T;
    label: string;
};

export type CustomConditionDnoPyalod = {
    type: string;
    field: string;
    operation?: CUSTOM_CONDITION_OPERATOR;
    inverted: boolean;
    service_type: string;
    value?: any;
    values?: any[];
};

export enum CUSTOM_CONDITION_OPERATOR {
    CONTAINS = 'contains',
    NOT_CONTAINS = 'not_contains',
    STARTS_WITH = 'starts_with',
    ENDS_WITH = 'ends_with',
    REGEX = 'regex',
    GREATER_THAN = 'greater_than',
    LESS_THAN = 'less_than',
    GREATER_THAN_EQUAL = 'greater_than_equal',
    LESS_THAN_EQUAL = 'less_than_equal',
    EQUAL = 'equal',
    NOT_EQUAL = 'not_equal',
}

export enum CUSTOM_CONDITION_TYPE {
    BOOLEAN = 'boolean',
    INTEGER = 'integer',
    STRING = 'string',
}

export const createRequestFieldsData = (data: Record<string, DnoCustomCondition>): CustomCondition[] => {
    return Object.keys(data)
        .map((id: string) => ({
            id,
            name: data[id].name,
            description: data[id].description,
            type: data[id].type,
            inputType: CUSTOM_CONDITION_TYPE_TO_INPUT_TYPE.get(data[id].type) || '',
            operator: makeCoditionOperatorsOptions(data[id].operator),
            selectedOperator: null,
            allowMultipleValues: false,
            allowedValues: data[id].allowed_values ? makeAllowedValuesOptions(data[id].allowed_values) : undefined,
            selectedAllowedValues: [],
            values: [],
            selectedAllowedValue: null,
            value: null,
        }))
        .sort((entity1, entity2) => entity1.name.localeCompare(entity2.name));
};

export const makeCoditionOperatorsOptions = (
    data: CUSTOM_CONDITION_OPERATOR[],
): CoditionOption<CUSTOM_CONDITION_OPERATOR>[] => {
    return data
        .map(id => ({
            id,
            label: CUSTOM_CONDITION_OPERATOR_TO_LABEL_MAP.get(id)?.toString() || '',
        }))
        .sort((entity1, entity2) => (entity1.label as string).localeCompare(entity2.label as string));
};

export const CUSTOM_CONDITION_TYPE_TO_INPUT_TYPE: Map<CUSTOM_CONDITION_TYPE, string> = new Map([
    [CUSTOM_CONDITION_TYPE.INTEGER, 'number'],
    [CUSTOM_CONDITION_TYPE.STRING, 'text'],
]);

export const CUSTOM_CONDITION_OPERATOR_TO_LABEL_MAP: Map<CUSTOM_CONDITION_OPERATOR, TranslateResult> = new Map([
    [CUSTOM_CONDITION_OPERATOR.GREATER_THAN, i18n.t('conditionsTree.operation.greaterThan')],
    [CUSTOM_CONDITION_OPERATOR.LESS_THAN, i18n.t('conditionsTree.operation.lessThan')],
    [CUSTOM_CONDITION_OPERATOR.GREATER_THAN_EQUAL, i18n.t('conditionsTree.operation.greaterThanEqual')],
    [CUSTOM_CONDITION_OPERATOR.LESS_THAN_EQUAL, i18n.t('conditionsTree.operation.lessThanEqual')],
    [CUSTOM_CONDITION_OPERATOR.CONTAINS, i18n.t('conditionsTree.operation.contains')],
    [CUSTOM_CONDITION_OPERATOR.NOT_CONTAINS, i18n.t('conditionsTree.operation.notContains')],
    [CUSTOM_CONDITION_OPERATOR.EQUAL, i18n.t('conditionsTree.operation.equal')],
    [CUSTOM_CONDITION_OPERATOR.NOT_EQUAL, i18n.t('conditionsTree.operation.notEqual')],
    [CUSTOM_CONDITION_OPERATOR.STARTS_WITH, i18n.t('conditionsTree.operation.startsWith')],
    [CUSTOM_CONDITION_OPERATOR.ENDS_WITH, i18n.t('conditionsTree.operation.endsWith')],
    [CUSTOM_CONDITION_OPERATOR.REGEX, i18n.t('conditionsTree.operation.regex')],
]);

export const makeAllowedValuesOptions = (data: Record<string, any>): CoditionOption<CUSTOM_CONDITION_OPERATOR>[] =>
    Object.keys(data).map((id: string) => ({
        id: data[id],
        label: id,
    }));

export const allowMultipleValues = (selectedOperator: CUSTOM_CONDITION_OPERATOR): boolean => {
    return [
        CUSTOM_CONDITION_OPERATOR.CONTAINS,
        CUSTOM_CONDITION_OPERATOR.NOT_EQUAL,
        CUSTOM_CONDITION_OPERATOR.STARTS_WITH,
        CUSTOM_CONDITION_OPERATOR.ENDS_WITH,
        CUSTOM_CONDITION_OPERATOR.REGEX,
    ].includes(selectedOperator);
};

export const preparePayloadBasedOnOptions = (data: CustomCondition, serviceType: string) => {
    const payload: CustomConditionDnoPyalod = {
        type: CONDITION_TYPES.CUSTOM_CONDITION,
        field: data.id,
        operation: data?.selectedOperator?.id,
        inverted: false,
        service_type: serviceType,
    };

    if (data.type === CUSTOM_CONDITION_TYPE.BOOLEAN) {
        payload.value = data.value;
    } else if (data.allowMultipleValues) {
        payload.values = data?.allowedValues
            ? data?.selectedAllowedValues?.map((el: CoditionOption<string>) => el.id)
            : data.values;
    } else {
        payload.value = data?.allowedValues ? data?.selectedAllowedValue?.id : data.value;
    }

    return payload;
};
