
import Vue from 'vue';

// Store
import { mapGetters, mapActions } from 'vuex';
import Actions, { Getters } from '@/store/mutation-types';
import { Modules } from '@/store/store';

// Components
import AbstractListPageWrapper from '@/components/layout/AbstractListPageWrapper.vue';
import FilterTable from '@/components/partials/FilterTable.vue';
import TableFiltersTags from '@/components/filters/TableFiltersTags.vue';
import AppTable from '@/components/partials/AppTable.vue';
import EntityStatusIndicator from '@/components/partials/EntityStatusIndicator.vue';
import EntityOverview from '@/components/partials/entityOverview/EntityOverview.vue';
import OverviewHeader from '@/components/partials/entityOverview/OverviewHeader.vue';
import LanguageSwitcher from '@/components/partials/inputs/LanguageSwitcher.vue';
import OverviewList from '@/components/partials/entityOverview/OverviewList.vue';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import { ICON_TYPES } from '@/common/iconHelper';
import ResponseModalButton from '@/components/partials/ResponseModalButton.vue';
import IconButton from '@/components/partials/IconButton.vue';

// Mixins
import CountControlMixin from '@/components/partials/CountControlMixin.vue';
import FilterTableMixin from '@/components/partials/FilterTableMixin.vue';
import DownloadDetailsMixin from '@/__new__/features/charging/DownloadDetailsMixin.vue';
import ChargingCommonActionsMixin from '@/__new__/features/charging/ChargingCommonActionsMixin.vue';

// Http
import {
    deleteWalletCounterDraft,
    updateWalletCounterState,
} from '@/__new__/services/dno/charging/http/walletCounters';

// Helpers
import ENTITY_TYPES from '@/common/entities/entityTypes';
import tableColumnType, { type TableColumn } from '@/common/filterTable';
import { getMultiLangFieldValueByLocale, getEntityConsumers } from '@/common/entities/entityHelper';
import { languageMap } from '@/common/locale/language';
import RouteNames from '@/router/routeNames';
import { displayNaIfNotProvided } from '@/common/formatting';
import {
    WalletCounter,
    DetailsSections,
    UsageCounter,
} from '@/__new__/services/dno/charging/models/ChargingInterfaces';
import permissionsService, { isUserAllowed } from '@/services/permissions/permissions.service';
import { PLURALIZATION } from '@/common/locale/labelSingularOrPlural';
import * as Sentry from '@sentry/vue';

export default Vue.extend({
    name: 'WalletCounters',
    components: {
        AbstractListPageWrapper,
        FilterTable,
        TableFiltersTags,
        AppTable,
        EntityStatusIndicator,
        EntityOverview,
        OverviewHeader,
        LanguageSwitcher,
        OverviewList,
        AppButton,
        ResponseModalButton,
        IconButton,
    },
    mixins: [CountControlMixin, FilterTableMixin, DownloadDetailsMixin, ChargingCommonActionsMixin],
    data() {
        return {
            ICON_TYPES,
            BUTTON_TYPES,
            ENTITY_TYPES,
            PLURALIZATION,
            entityType: ENTITY_TYPES.WALLET_COUNTERS as string,
            editorRoute: RouteNames.CHARGING_WALLET_COUNTER_EDITOR as string,
            isOverviewEnabled: false as boolean,
            searchQueryForTable: '' as string,
            selectedEntityId: null as string | null,
            selectedEntity: null as WalletCounter | null,
            isDataLoading: false as boolean,
            selectedLanguage: '' as string,
        };
    },
    computed: {
        ...mapGetters(Modules.chargingV2, [
            Getters.GET_APPROVED_WALLET_COUNTERS,
            Getters.GET_WALLET_COUNTER_BY_ID,
            Getters.GET_WALLET_COUNTERS_API_RESPONSE,
            Getters.GET_USAGE_COUNTER_BY_ID,
        ]),
        ...mapGetters('operators', [Getters.languageDefault, Getters.languagesWithoutDefault]),
        isEditEnabled(): boolean {
            return permissionsService.chargingWalletCountersEnabled() && isUserAllowed('WalletCountersReadWrite');
        },
        payoutsApiResponse(): any {
            return this[Getters.GET_WALLET_COUNTERS_API_RESPONSE];
        },
        walletCounters(): WalletCounter[] {
            if (!this[Getters.GET_APPROVED_WALLET_COUNTERS]) return [];
            const data = [...this[Getters.GET_APPROVED_WALLET_COUNTERS]].map((el: WalletCounter) => ({
                ...el,
                usageCounterLabel: this.getUsageCounterDataById(el.usageCounterId)?.name || '',
            }));
            return data.sort((wc1: WalletCounter, wc2: WalletCounter) => (wc1.name as any).localeCompare(wc2.name));
        },
        tableColumnsData(): TableColumn[] {
            return [
                {
                    name: this.$i18n.t('generic.name'),
                    key: 'name',
                    field: 'name',
                    forbidHideColumn: true,
                    classes: ['font-weight-bold'],
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('generic.limit'),
                    key: 'limit',
                    field: 'limit',
                    filterType: tableColumnType.NUMBER,
                },
                {
                    name: this.$i18n.t('charging.walletCounters.activateWalletLimit'),
                    key: 'isWalletLimitLabel',
                    field: 'isWalletLimitLabel',
                    classes: ['font-weight-bold'],
                    filterType: tableColumnType.TEXT_LIMITED_OPTIONS,
                    limitedOptions: [
                        ...new Set(this.sortedFilteredEntities.map((e: WalletCounter) => e.isWalletLimitLabel)),
                    ],
                },
                {
                    name: this.$i18n.t('generic.disabled'),
                    key: 'disabledLabel',
                    field: 'disabledLabel',
                    classes: ['font-weight-bold'],
                    filterType: tableColumnType.TEXT_LIMITED_OPTIONS,
                    limitedOptions: [
                        ...new Set(this.sortedFilteredEntities.map((e: WalletCounter) => e.disabledLabel)),
                    ],
                },
                {
                    name: this.$i18n.tc('charging.usageCounters.name', PLURALIZATION.SINGULAR),
                    key: 'usageCounterLabel',
                    field: 'usageCounterLabel',
                    filterType: tableColumnType.TEXT_LIMITED_OPTIONS,
                    limitedOptions: [
                        ...new Set(this.sortedFilteredEntities.map((e: WalletCounter) => e.usageCounterLabel)),
                    ],
                },
                {
                    name: this.$i18n.t('generic.lastUpdatedTime'),
                    key: 'updateTime',
                    sortBy: (entity: WalletCounter) => entity.updateTime,
                    mapper: (entity: WalletCounter) =>
                        entity?.isDraft ? this.$localeLibrary.getFormattedDate(entity.update_time) : entity.updateTime,
                    field: 'update_time',
                    filterType: tableColumnType.DATE,
                },
                {
                    name: this.$i18n.t('generic.version'),
                    key: 'chargingVersion',
                    field: 'chargingVersion',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('generic.state'),
                    key: 'entityStatusCode',
                    field: 'entityStatusCodeLabel',
                    filterType: tableColumnType.TEXT_LIMITED_OPTIONS,
                    limitedOptions: [
                        ...new Set(this.sortedFilteredEntities.map((e: WalletCounter) => e.entityStatusCodeLabel)),
                    ],
                },
            ];
        },
        sortedFilteredEntities(): WalletCounter[] {
            return this.filteredEntitiesMixin(this.walletCounters);
        },
        detailsSections(): DetailsSections[] {
            if (!this.selectedEntityId) {
                return [];
            }

            return [
                {
                    name: this.$i18n.t('generic.general'),
                    properties: [
                        {
                            value: this.selectedEntity.id,
                            label: this.$i18n.t('generic.id'),
                        },
                        {
                            value: displayNaIfNotProvided(this.selectedEntity?.name),
                            label: this.$i18n.t('generic.name'),
                        },
                        {
                            value: displayNaIfNotProvided(this.selectedEntity?.description?.[this.selectedLanguage]),
                            label: this.$i18n.t('generic.description'),
                        },
                    ],
                },
            ];
        },
    },
    created() {
        this.initData();
    },
    methods: {
        ...mapActions(Modules.chargingV2, [Actions.REQUEST_USAGE_COUNTERS, Actions.REQUEST_WALLET_COUNTERS]),
        getMultiLangFieldValueByLocale,
        initData() {
            this.$withLoadingSpinner(
                async () => {
                    this.$Progress.start();
                    this.isDataLoading = true;

                    this.selectedLanguage = (this[Getters.languageDefault] || languageMap.en.key).toString();
                    await Promise.all([
                        this[Actions.REQUEST_WALLET_COUNTERS](),
                        this[Actions.REQUEST_USAGE_COUNTERS](),
                    ]);

                    this.$Progress.finish();
                    this.isDataLoading = false;
                },
                {
                    errorHandler: (e: any) => {
                        this.isDataLoading = false;
                        this.$Progress.fail();

                        Sentry.captureException(e);
                        this.$eventBus.$emit('showAlert', {
                            message: e.message,
                        });
                    },
                },
            );
        },
        setSearchQuery(query: string): void {
            this.searchQueryForTable = query;
        },
        onEntitySelected(entityId: string): void {
            this.selectedEntityId = entityId;
            this.selectedEntity = this[Getters.GET_WALLET_COUNTER_BY_ID](entityId);
            this.isOverviewEnabled = true;
        },
        upperEntities(entityId: string = this.selectedEntityId): object {
            return getEntityConsumers(entityId, this.entityType);
        },
        newWalletCounter(): void {
            this.$router.push({
                name: RouteNames.CHARGING_WALLET_COUNTER_EDITOR,
                params: { companyId: this.$route.params.companyId },
            });
        },
        getUsageCounterDataById(ucId: string): UsageCounter | null {
            return this[Getters.GET_USAGE_COUNTER_BY_ID](ucId) || null;
        },
        onEdit(entityId: string): void {
            this.onEditAction(entityId, this.editorRoute);
        },
        onClone(entityId: string): void {
            this.onCloneAction(entityId, this.editorRoute);
        },
        onDelete(entityId: string): void {
            const { state, version } = this[Getters.GET_WALLET_COUNTER_BY_ID](entityId);

            this.showDeleteAlert(entityId, false, state, version, [
                updateWalletCounterState,
                deleteWalletCounterDraft,
                this.initData,
            ]);
        },
        onReadOnly(entityId: string): void {
            this.onReadOnlyAction(entityId, this.editorRoute);
        },
    },
});
