
declare interface AppLoadingData {
    loading: boolean;
}

export default {
    name: 'AppLoading',
    data(): AppLoadingData {
        return {
            loading: false,
        };
    },
    computed: {
        compact(): boolean {
            return this.$store.state.compactSidebar;
        },
    },
    watch: {
        $route(): void {
            this.setLoadingFlag(false);
        },
    },
    created() {
        this.setLoadingFlag(false);
    },
    beforeCreate() {
        this.$eventBus.$on('setLoadingFlag', flag => {
            this.setLoadingFlag(flag);
        });
    },
    methods: {
        setLoadingFlag(isLoading: boolean): void {
            this.loading = isLoading;
            this.$eventBus.isDataLoading = isLoading;
        },
    },
};
