
// vuex stuff
import { mapGetters, mapActions } from 'vuex';
import Actions, { Getters } from '@/store/mutation-types';

// components

import FilterTableMixin from '@/components/partials/FilterTableMixin.vue';
import OrchestrationListPageLayout from '@/__new__/features/orchestrationengine/OrchestrationListPageLayout.vue';

// helpers
import ENTITY_TYPES from '@/common/entities/entityTypes';
import RouteNames from '@/router/routeNames';
import tableColumnType from '@/common/filterTable';
import { PLURALIZATION } from '@/common/locale/labelSingularOrPlural';
import permissionsService, { isUserAllowed } from '@/services/permissions/permissions.service';

// error handling
import * as Sentry from '@sentry/vue';

export default {
    name: 'OrchestrationEngineTemplatesListPage',
    components: {
        OrchestrationListPageLayout,
    },
    mixins: [FilterTableMixin],
    data() {
        return {
            pageTitle: this.$i18n.tc('orchestrationEngine.templates.template', PLURALIZATION.PLURAL) as string,
            entityType: ENTITY_TYPES.ORCHESTRATION_ENGINE_TEMPLATES as any,
            addNewRouteName: RouteNames.ORCHESTRATION_ENGINE_TEMPLATES_EDITOR as any,
            editRouteName: RouteNames.ORCHESTRATION_ENGINE_TEMPLATES_EDITOR as any,
            isDataLoading: false as boolean,
            keyName: 'templateName' as string,
        };
    },
    computed: {
        ...mapGetters('orchestrationengine', {
            templates: Getters.GET_OE_TEMPLATES,
            templatesApiResponse: Getters.GET_OE_TEMPLATES_API_RESPONSE,
        }),
        ...mapGetters('operators', [Getters.languageDefault]),
        formattedEntities(): any[] {
            return this.templates.map(template => ({
                id: template?.id || template?.data?.name,
                templateName: template?.data?.name,
                // eslint-disable-next-line camelcase
                updateTime: this.$localeLibrary.getFormattedDateAndTime(template?.metadata?.update_time),
                references: template?.metadata?.references?.length || 0,
                // eslint-disable-next-line camelcase
                referencedBy: template?.metadata?.referenced_by?.length || 0,
                version: template?.version,
            }));
        },
        tableColumnsData(): any[] {
            return [
                {
                    name: this.$i18n.t('generic.name'),
                    key: 'templateName',
                    field: 'templateName',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('generic.updateTime'),
                    key: 'updateTime',
                    sortBy: entity => entity.updateTime,
                    field: 'updateTime',
                    filterType: tableColumnType.DATETIME,
                },
                {
                    name: this.$i18n.t('orchestrationEngine.references'),
                    key: 'references',
                    field: 'references',
                    filterType: tableColumnType.NUMBER,
                },
                {
                    name: this.$i18n.t('orchestrationEngine.referencedBy'),
                    key: 'referencedBy',
                    field: 'referencedBy',
                    filterType: tableColumnType.NUMBER,
                },
                {
                    name: this.$i18n.t('generic.version'),
                    key: 'version',
                    field: 'version',
                    filterType: tableColumnType.NUMBER,
                },
            ];
        },
        writeEnabled(): boolean {
            return (
                isUserAllowed('OrchestrationTemplatesWrite') && permissionsService.orchestrationEngineTemplatesEnabled()
            );
        },
    },
    async created() {
        // fetching templates
        this.isDataLoading = true;
        await this.fetchTemplates();
        this.isDataLoading = false;
    },
    methods: {
        ...mapActions('orchestrationengine', {
            requestTemplates: Actions.REQUEST_OE_TEMPLATES,
        }),
        async fetchTemplates(): Promise<any> {
            try {
                this.$Progress.start();
                await this.requestTemplates();
                this.$Progress.finish();
            } catch (error: any) {
                this.$Progress.fail();
                Sentry.captureException(error);
                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('alertMessage.failedToLoadNecessaryData'),
                });
            }
        },
    },
};
