
import Vue from 'vue';

// Components
import UserPageAnchor from '@/__new__/features/customerCareSuite/components/UserPageAnchor.vue';
import AccountPageAnchor from '@/__new__/features/customerCareSuite/components/AccountPageAnchor.vue';
import SubscriberPageAnchor from '@/__new__/features/customerCareSuite/components/SubscriberPageAnchor.vue';
import OrganizationPageAnchor from '@/__new__/features/customerCareSuite/components/OrganizationPageAnchor.vue';
import BillingAccountPageAnchor from '@/__new__/features/customerCareSuite/components/BillingAccountPageAnchor.vue';

// Helpers
import { LAYOUT_PAGE_KEYS } from '@/__new__/features/customerCareSuite/common/layoutSectionHelper';

export default Vue.extend({
    name: 'LayoutAnchor',

    components: {
        UserPageAnchor,
        AccountPageAnchor,
        SubscriberPageAnchor,
        OrganizationPageAnchor,
        BillingAccountPageAnchor,
    },

    props: {
        page: {
            type: String,
            default: LAYOUT_PAGE_KEYS.USER,
        },
    },
    data() {
        return {};
    },
    computed: {
        isUserPageAnchor() {
            return this.page === LAYOUT_PAGE_KEYS.USER;
        },
        isAccountAnchor() {
            return this.page === LAYOUT_PAGE_KEYS.ACCOUNT;
        },
        isSubscriberAnchor() {
            return this.page === LAYOUT_PAGE_KEYS.SUBSCRIBER;
        },
        isOrganizationAnchor() {
            return this.page === LAYOUT_PAGE_KEYS.ORGANIZATION;
        },
        isBillingAccountAnchor() {
            return this.page === LAYOUT_PAGE_KEYS.BILLING_ACCOUNT;
        },
        pageTitle() {
            // TODO: make dynamic based on the page (name/msisdn/id/etc)
            return `${this.page}`;
        },
    },
});
