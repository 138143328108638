import { DOCUMENT_JOB } from '@/__new__/services/dno/documents/models/DocumentInterfaces';
import { updateApiInvokerConfig, getApiInvokerJob } from '@/__new__/services/dno/sinkConfigs/http/sinkConfigs';

export async function getDocumentTriggerJob(templateId: string): ReturnType<typeof getApiInvokerJob> {
    return getApiInvokerJob(`${DOCUMENT_JOB.TRIGGER}_${templateId}`);
}

export async function getDocumentGenerationJob(templateId: string): ReturnType<typeof getApiInvokerJob> {
    return getApiInvokerJob(`${DOCUMENT_JOB.CALLBACK}_${templateId}`);
}

export async function createDocumentTriggerJob(
    data: {
        documentId: string;
        templateId: string;
        eventId: string;
        version?: number;
    },
    validate: boolean,
): ReturnType<typeof updateApiInvokerConfig> {
    return updateApiInvokerConfig(
        {
            job_definition: {
                id: `${DOCUMENT_JOB.TRIGGER}_${data.templateId}`,
                name: `Render ${data.templateId}`,
                description: `API Invoker trigger for the document generation based on ${data.templateId}`,
                request_params: {
                    method: 'POST',
                    drop_envelope: true,
                    override_transaction_id: false,
                    uri: {
                        value: {
                            path: `$.concat("http://lf-document-engine.lotusflare.svc.cluster.local/api/operators/", $.operator_name, "/templates/${data.templateId}/documents/", $.data.${data.documentId})`,
                        },
                    },
                },
                sink_entity_properties: {
                    fields_configuration: [],
                    should_send_raw_event: true,
                    trigger_condition: {
                        filters: [
                            {
                                values: [
                                    {
                                        [data.eventId]: {
                                            p: [],
                                        },
                                    },
                                ],
                            },
                        ],
                    },
                },
            },
            revision_number: data.version,
        },
        { 'dry-run': validate || undefined },
    );
}

export async function createDocumentGenerationJob(
    data: {
        templateId: string;
        callbackUrl: string;
        version?: number;
    },
    validate: boolean,
): ReturnType<typeof updateApiInvokerConfig> {
    return updateApiInvokerConfig(
        {
            job_definition: {
                id: `${DOCUMENT_JOB.CALLBACK}_${data.templateId}`,
                name: `Post Process ${data.templateId}`,
                description: `API Invoker web-hooks for the document generation results of ${data.templateId}`,
                cluster_name: 'default',
                request_params: {
                    uri: {
                        value: data.callbackUrl,
                    },
                },
                sink_entity_properties: {
                    trigger_condition: {
                        id_type: null,
                        filters: [
                            {
                                values: [
                                    {
                                        document_generated: {
                                            p: [
                                                {
                                                    template_id: {
                                                        value: {
                                                            value: data.templateId,
                                                            op: {
                                                                Equals: {},
                                                            },
                                                        },
                                                        type: 'string',
                                                    },
                                                },
                                            ],
                                        },
                                    },
                                ],
                            },
                            {
                                values: [
                                    {
                                        document_generation_failed: {
                                            p: [
                                                {
                                                    template_id: {
                                                        value: {
                                                            value: data.templateId,
                                                            op: {
                                                                Equals: {},
                                                            },
                                                        },
                                                        type: 'string',
                                                    },
                                                },
                                            ],
                                        },
                                    },
                                ],
                            },
                        ],
                    },
                    fields_configuration: [],
                    should_send_raw_event: true,
                },
            },
            revision_number: data.version,
        },
        { 'dry-run': validate || undefined },
    );
}
