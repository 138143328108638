<template>
    <div>
        <h3 class="mb-3">
            <b>{{ articleTitle }}</b> {{ $i18n.t('generic.article') }}
        </h3>
        <template v-if="what">
            <div v-dompurify-html="what" />
            <hr />
        </template>
        <template v-if="who">
            <div v-dompurify-html="who" />
            <hr />
        </template>
        <template v-if="when">
            <div v-dompurify-html="when" />
            <hr />
        </template>
        <template v-if="why">
            <div v-dompurify-html="why" />
            <hr />
        </template>
        <template v-if="where">
            <div v-dompurify-html="where" />
            <hr />
        </template>
        <template v-if="how">
            <div v-dompurify-html="how" />
            <hr />
        </template>
        <template v-if="other">
            <div v-dompurify-html="other" />
            <hr />
        </template>
        <h4>{{ $i18n.t('userGuide.supportInformation') }}</h4>
        {{ supportInformation }}
        <table>
            <tbody>
                <tr>
                    <td>{{ $i18n.t('userGuide.dnoProductModule') }}</td>
                    <td class="pl-2">
                        {{ dnoProductModuleName }}
                    </td>
                </tr>
                <tr>
                    <td>{{ $i18n.t('userGuide.dnoProductPage') }}</td>
                    <td class="pl-2">
                        {{ dnoProductPageName }}
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
// helpers
import { getArticles } from '@/modules/userGuide/http/strapiHTTP';

export default {
    name: 'ArticlePage',
    data() {
        return {
            articleData: null,
            articleTitle: '',
            what: '',
            who: '',
            when: '',
            where: '',
            why: '',
            how: '',
            other: '',
            supportInformation: null,
            dnoProductModuleName: null,
            dnoProductPageName: null,
        };
    },
    mounted() {
        this.fetchData();
    },
    methods: {
        async fetchData() {
            const response = await getArticles(this.$route.params.id);
            this.articleData = response?.data?.data;
            this.articleTitle = this.articleData?.attributes?.title;
            this.what = this.articleData?.attributes?.user_guide_what;
            this.who = this.articleData?.attributes?.user_guide_who;
            this.when = this.articleData?.attributes?.user_guide_when;
            this.where = this.articleData?.attributes?.user_guide_where;
            this.why = this.articleData?.attributes?.user_guide_why;
            this.how = this.articleData?.attributes?.user_guide_how;
            this.other = this.articleData?.attributes?.user_guide_other;
            this.dnoProductModuleName =
                this.articleData?.attributes?.dnoProductModules?.data[0]?.attributes?.name || '';
            this.dnoProductPageName = this.articleData?.attributes?.dnoProductPage?.data?.attributes?.name || '';
        },
    },
};
</script>

<style lang="scss" scoped></style>
