<template>
    <div>
        <div
            v-t="'productCatalog.services.editor.networkSliceIdentifier'"
            class="table-title"
        />
        <div class="primary-text">
            <div
                v-for="(NSI, index) in buildNSIdata"
                :key="index"
            >
                <strong>{{ NSI.sst }}: </strong>
                <span
                    v-for="(sd, i) in NSI.sd"
                    :key="i"
                >
                    {{ sd }}
                    <span v-if="showComma(i, NSI.sd.length)">, </span>
                </span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'NSIValue',
    props: {
        value: {
            type: Object,
            required: true,
        },
    },
    computed: {
        buildNSIdata() {
            const valueKeys = Object.keys(this.value.values);
            return valueKeys.map(key => {
                const data = {
                    sst: key,
                    sd: this.value.values[key],
                };
                return data;
            });
        },
    },
    methods: {
        showComma(index, arrLength) {
            return index !== arrLength - 1;
        },
    },
};
</script>
