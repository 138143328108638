<template>
    <AppAditionalSidebar
        v-if="isViewEnabled('CharterDemoBillsTile') && selectedBill"
        :visible="isVisible"
        @input="$emit('closeSidebar')"
    >
        <template #header>
            <h3 class="lf-table-title text-uppercase d-flex align-items-center h-100">
                {{ $i18n.t('charterDemo.billDetails') }}
            </h3>
        </template>

        <template #content>
            <OverviewHeaderV2
                :entityId="selectedBill.id"
                :entityName="selectedBill.billNo"
            />
            <AppOverviewBlock
                :isRowTitleCounterVisible="false"
                :items="data"
                :maxItemRows="maxItemRows"
            />
            <CharterDemoViewBillModal
                :bill="selectedBill"
                :visible="showBillModal"
                @close="showBillModal = false"
            />
        </template>
        <template #actions>
            <div class="d-flex">
                <IconButton
                    :icon="ICON_TYPES.EYE_SHOW"
                    :label="$t('charterDemo.viewBill')"
                    @iconClick="onView"
                />
                <IconButton
                    :icon="ICON_TYPES.DOWNLOAD"
                    :label="$t('charterDemo.downloadBill')"
                    @iconClick="onDownload"
                />
                <IconButton
                    :icon="ICON_TYPES.SEND"
                    :label="$t('charterDemo.resendBill')"
                    @iconClick="onResendBill"
                />
            </div>
        </template>
    </AppAditionalSidebar>
</template>

<script>
// COMPONENTS
import AppAditionalSidebar from '@/components/partials/AppAditionalSidebar.vue';
import AppOverviewBlock from '@/components/partials/AppOverviewBlock.vue';
import IconButton from '@/components/partials/IconButton.vue';
import OverviewHeaderV2 from '@/components/partials/entityOverview/OverviewHeaderV2.vue';
import { isViewEnabled } from '@/services/permissions/permissions.service';

import { ALERT_TYPES } from '@/common/alerts/Alert';
import { ICON_TYPES } from '@/common/iconHelper';
import CharterDemoViewBillModal from '@/components/partials/CharterDemoViewBillModal.vue';
import { getBillAttachment, getBillDeliveryTargets } from '@/__new__/features/charterDemo/charterHttp';
import download from 'downloadjs';
import * as Sentry from '@sentry/vue';
import Button from '@/common/button/Button';

export default {
    name: 'CharterDemoBillSidebar',
    components: {
        AppAditionalSidebar,
        AppOverviewBlock,
        CharterDemoViewBillModal,
        IconButton,
        OverviewHeaderV2,
    },
    props: {
        selectedBill: {
            type: Object,
            default: () => ({}),
        },
        isVisible: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            ICON_TYPES,
            maxItemRows: 10,
            showBillModal: false,
            confirmResendBillButton: new Button({
                label: this.$i18n.t('charterDemo.resend'),
                handler: () => this.onResendBillConfirmed(),
            }),
        };
    },
    computed: {
        data() {
            return [
                {
                    name: this.$t('generic.general'),
                    isCollapsed: false,
                    rows: [
                        {
                            name: this.$t('charterDemo.billNumber'),
                            value: this.selectedBill?.billNo ?? '',
                        },
                        {
                            name: this.$t('charterDemo.billId'),
                            value: this.selectedBill?.id ?? '',
                        },
                        {
                            name: this.$t('charterDemo.billDate'),
                            value: this.selectedBill?.billDateStr ?? '',
                        },
                        {
                            name: this.$t('charterDemo.dueDate'),
                            value: this.selectedBill?.paymentDueDateStr ?? '',
                        },
                        {
                            name: this.$t('charterDemo.overdue'),
                            value: this.selectedBill?.overdueStr ?? '',
                        },
                        {
                            name: this.$t('charterDemo.amountDue'),
                            value: this.selectedBill?.amountDueStr ?? '',
                        },
                        {
                            name: this.$t('charterDemo.state'),
                            value: this.selectedBill?.stateStr ?? '',
                        },
                    ],
                },
            ];
        },
    },
    methods: {
        isViewEnabled,
        onView() {
            this.showBillModal = true;
        },
        async onDownload() {
            try {
                // Short circuit if no bill selected
                if (!this.selectedBill) {
                    return;
                }

                this.$Progress.start();

                // Get Signed URL
                const billAttachmentRes = await getBillAttachment(this.selectedBill.id);
                const signedUrl = billAttachmentRes?.data?.url;
                if (!signedUrl) {
                    throw new Error(`Could not determined signed url to download bill`);
                }

                // Fetch file
                const fileRes = await fetch(signedUrl);
                if (!fileRes.ok) {
                    throw new Error(`Fetch to ${signedUrl} failed`);
                }

                // Trigger download from browser
                const file = await fileRes.blob();
                const filename = `Bill-${this.selectedBill.billNo}.pdf`;
                download(file, filename, 'application/pdf');

                this.$Progress.finish();
            } catch (error) {
                this.$alert(this.$t('charterDemo.errorOccurredWhileDownloadingBill'));
                Sentry.captureException(error);
                this.$Progress.fail();
            }
        },
        async onResendBill() {
            try {
                // Short circuit if no bill selected
                if (!this.selectedBill) {
                    return;
                }

                this.$Progress.start();

                // Determine contact emails
                const deliveryTargetsRes = await getBillDeliveryTargets(this.selectedBill.id);
                const deliveryTargets = deliveryTargetsRes.data;
                const emails = deliveryTargets
                    .map(target => target.contactMedium?.emailAddress)
                    .filter(emailAddress => Boolean(emailAddress));

                // Determine description for confirmation alert:
                let description = '';
                if (emails.length > 1) {
                    const emailPrefix = `\n- `;
                    description = `${this.$t('charterDemo.billWillBeResentTo')}:${emailPrefix}${emails.join(
                        emailPrefix,
                    )}`;
                } else if (emails.length === 1) {
                    description = `${this.$t('charterDemo.billWillBeResentTo')} ${emails[0]}`;
                }

                // Raise confirmation alert
                this.$alert(this.$t('charterDemo.pleaseConfirmResendingBill'), {
                    description,
                    type: ALERT_TYPES.warning,
                    buttons: [this.confirmResendBillButton],
                });

                this.$Progress.finish();
            } catch (error) {
                this.$alert(this.$t('charterDemo.errorOccurredWhileResendingBill'));
                Sentry.captureException(error);
                this.$Progress.fail();
            }
        },
        // Note: This part has been stubbed out for now for demo purposes:
        onResendBillConfirmed() {
            this.$alert(this.$t('charterDemo.billHasBeenResent'), {
                type: ALERT_TYPES.success,
            });
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/consistency';

.lf-table-title {
    color: rgba($blue-400, 0.5);
}

dl {
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: baseline;
    gap: 0.875rem $spacing-m;
    margin-top: $spacing-xl;
}
</style>
