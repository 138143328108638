<template>
    <div>
        <div class="container-fluid h-100">
            <AppHeader />

            <div class="row no-gutters page-with-controls-content-wrapper panel">
                <div class="col-12">
                    <div class="account-page-image d-flex justify-content-center align-items-center mb-2">
                        <div class="account-page-lock" />
                    </div>

                    <div class="account-personal-details">
                        <div class="title fullwidth-gray-bar">
                            {{ $i18n.t('account.personalDetails') }}
                        </div>
                        <div class="row no-gutters">
                            <form class="form py-5 col-6">
                                <AppInputV3
                                    v-if="isViasatDemo && viasatAgentId"
                                    id="agentId"
                                    v-model="viasatAgentId"
                                    :label="'Agent ID'"
                                    :disabled="true"
                                    class="mb-3"
                                />
                                <AppInputV3
                                    id="name"
                                    v-model="account.name"
                                    :invalid="$v.account.name.$error"
                                    :label="$i18n.t('account.fullName')"
                                    :disabled="!isUsernamePasswordLogin"
                                    class="mb-3"
                                />
                                <AppInputV3
                                    id="email"
                                    v-model="account.email"
                                    :invalid="$v.account.email.$error"
                                    :label="$i18n.t('generic.email')"
                                    :disabled="!isUsernamePasswordLogin"
                                    class="mb-3"
                                />
                                <!-- Credentials -->
                                <div
                                    v-if="isUsernamePasswordLogin"
                                    id="credentials"
                                >
                                    <AppInputV3
                                        id="currentPassword"
                                        v-model="account.passwordCurrent"
                                        :invalid="$v.account.passwordCurrent.$error"
                                        :label="$i18n.t('account.currentPassword')"
                                        type="password"
                                        class="mb-3"
                                    />

                                    <AppInputV3
                                        id="newPassword"
                                        v-model="account.password"
                                        v-tooltip.right="$i18n.t('account.passwordHint')"
                                        :label="$i18n.t('account.newPassword')"
                                        type="password"
                                        class="mb-3"
                                    />
                                    <AppInputV3
                                        id="confirmPassword"
                                        v-model="account.passwordConfirmation"
                                        :invalid="$v.account.passwordConfirmation.$error"
                                        :label="$i18n.t('account.confirmNewPassword')"
                                        type="password"
                                    />
                                </div>
                            </form>
                        </div>
                        <div class="title fullwidth-gray-bar">
                            {{ $i18n.t('account.authenticationDetails') }}
                        </div>
                        <div class="authentication-details">
                            <!-- Login Method -->
                            <div>
                                <div class="form-subtitle">
                                    {{ $i18n.t('account.loginMethod') }}
                                </div>
                                <div v-if="account.authStrategy">
                                    {{ authStrategyStr }}
                                </div>
                                <div
                                    v-else
                                    class="small-gray-note"
                                >
                                    {{ $i18n.t('generic.unknown') }}
                                </div>
                            </div>
                            <!-- Roles -->
                            <div>
                                <div v-if="Object.keys(account.companyNameToRoleNames).length > 0">
                                    <table id="roles-table">
                                        <tr>
                                            <td class="lf-labels">{{ $i18n.t('account.company') }}</td>
                                            <td class="lf-labels role-names">{{ $i18n.t('account.roles') }}</td>
                                        </tr>
                                        <tr
                                            v-for="(roleNames, companyName) in account.companyNameToRoleNames"
                                            :key="companyName"
                                        >
                                            <td>
                                                {{ companyName }}
                                            </td>
                                            <td class="role-names">
                                                {{ roleNames.join(', ') }}
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                                <div
                                    v-else
                                    id="roles-table-unknown"
                                >
                                    <div class="form-subtitle">
                                        {{ $i18n.t('account.roles') }}
                                    </div>
                                    <p class="small-gray-note">
                                        {{ $i18n.t('generic.unknown') }}
                                    </p>
                                </div>
                            </div>
                            <!-- Groups -->
                            <div v-if="!isUsernamePasswordLogin">
                                <div class="form-subtitle">
                                    {{ $i18n.t('account.groups') }}
                                </div>
                                <div
                                    v-if="account.groups && account.groups.length > 0"
                                    id="groups"
                                >
                                    <div
                                        v-for="(group, index) in account.groups"
                                        :key="index"
                                    >
                                        {{ group }}
                                    </div>
                                </div>
                                <div
                                    v-else
                                    id="groups"
                                    class="small-gray-note"
                                >
                                    {{ $i18n.t('account.noGroupsOnAccount') }}
                                </div>
                            </div>
                            <!-- External User Id-->
                            <div v-if="!isUsernamePasswordLogin">
                                <div class="form-subtitle">
                                    {{ $i18n.t('account.externalUserId') }}
                                </div>
                                <div
                                    v-if="account.externalUserId"
                                    id="externalUserId"
                                >
                                    {{ account.externalUserId }}
                                </div>
                                <div
                                    v-else
                                    id="externalUserId"
                                    class="small-gray-note"
                                >
                                    {{ $i18n.t('account.noExternalUserIdOnAccount') }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="page-controls justify-content-between">
                <AppButton
                    :buttonType="BUTTON_TYPES.SECONDARY"
                    :label="$i18n.t('generic.back')"
                    @click="$router.go(-1)"
                />
                <AppButton
                    v-if="isUsernamePasswordLogin"
                    id="save-button"
                    :buttonType="BUTTON_TYPES.PRIMARY"
                    :iconType="ICON_TYPES.CHECK"
                    :label="$i18n.t('generic.save')"
                    @click="onSave"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { required, requiredIf, sameAs } from 'vuelidate/lib/validators';
import AppHeader from '@/components/layout/AppHeader.vue';
import AppInputV3 from '@/components/partials/inputs/AppInputV3.vue';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import { ICON_TYPES } from '@/common/iconHelper';
import AccountNew from '@/models/Account';
import { ALERT_TYPES } from '@/common/alerts/Alert';
import profile from '@/__new__/services/portal/profile/http/profile';
import RouteNames from '@/router/routeNames';
import supportButtonMixin from '@/components/alerts/supportButtonMixin';
import { getOperatorConfigValue } from '@/services/permissions/permissions.service';
import { AUTH_STRATEGY } from '@/common/auth/strategies';

export default {
    name: 'AccountPage',
    components: {
        AppHeader,
        AppInputV3,
        AppButton,
    },
    mixins: [validationMixin, supportButtonMixin],
    data() {
        return {
            accountData: AccountNew.storedInstance,
            account: {
                name: '',
                email: '',
                passwordCurrent: '',
                password: '',
                passwordConfirmation: '',
                groups: [],
                externalUserId: '',
                authStrategy: null,
                companyNameToRoleNames: {},
            },
            ICON_TYPES,
            BUTTON_TYPES,
            isViasatDemo: getOperatorConfigValue('isViasatDemo'),
            viasatAgentId: getOperatorConfigValue('viasatAgentId'),
        };
    },
    computed: {
        /**
         * Converts `account.authStrategy` enum into a human readable string
         */
        authStrategyStr() {
            switch (this.account.authStrategy) {
                case AUTH_STRATEGY.LOCAL:
                    return this.$i18n.t('account.portalUsernameAndPassword');
                case AUTH_STRATEGY.AZUREAD:
                    return this.$i18n.t('account.azuread');
                case AUTH_STRATEGY.OKTA:
                    return this.$i18n.t('account.okta');
                default:
                    return this.$i18n.t('generic.unknown');
            }
        },
        /**
         * Whether or not the user used username and password to authenticate
         */
        isUsernamePasswordLogin() {
            return this.account.authStrategy === AUTH_STRATEGY.LOCAL;
        },
    },
    created() {
        this.account.name = this.accountData.name;
        this.account.email = this.accountData.email;
        this.account.groups = this.accountData.groups;
        this.account.externalUserId = this.accountData.externalUserId;
        this.account.authStrategy = this.accountData.authStrategy;
        this.account.companyNameToRoleNames = this.accountData.companyNameToRoleNames;
    },
    validations() {
        return {
            account: {
                name: {
                    required,
                },
                email: {
                    required,
                },
                passwordCurrent: {
                    required,
                },
                passwordConfirmation: {
                    required: requiredIf(() => this.account.password),
                    sameAsNewPassword: sameAs('password'),
                },
            },
        };
    },
    methods: {
        validateInput() {
            this.$v.$touch();
            if (this.$v.$anyError) {
                if (this.$v.account.passwordCurrent.$error) {
                    this.$eventBus.$emit('showAlert', {
                        message: this.$i18n.t('alertMessage.account.passwordIsRequired'),
                        type: ALERT_TYPES.warning,
                    });
                } else if (this.$v.account.name.$error) {
                    this.$eventBus.$emit('showAlert', {
                        message: this.$i18n.t('alertMessage.account.nameIsRequiredField'),
                        type: ALERT_TYPES.warning,
                    });
                } else if (this.$v.account.email.$error) {
                    this.$eventBus.$emit('showAlert', {
                        message: this.$i18n.t('alertMessage.account.emailIsRequiredField'),
                        type: ALERT_TYPES.warning,
                    });
                } else if (this.account.password && this.$v.account.passwordConfirmation.$error) {
                    this.$eventBus.$emit('showAlert', {
                        message: this.$i18n.t('alertMessage.account.passwordConfirmationDoesNotMatch'),
                        type: ALERT_TYPES.warning,
                    });
                }
            }
        },
        async onSave() {
            this.validateInput();
            if (!this.$v.$anyError) {
                try {
                    this.$Progress.start();
                    this.getAccountDataForUpdateRequest();
                    const response = await profile.updateProfile(this.getAccountDataForUpdateRequest());
                    this.accountData.name = response.data.name;
                    this.accountData.email = response.data.email;
                    const authorizedAccount = new AccountNew(response.data);
                    authorizedAccount.store();
                    this.$Progress.finish();
                    this.$router.push({
                        name: RouteNames.HOME_VIEW,
                        params: { companyId: this.$route.params.companyId },
                    });
                } catch (error) {
                    this.$Progress.fail();
                    if (error && error.response.data && error.response.data.error) {
                        this.$eventBus.$emit('showAlert', {
                            message: error.response.data.error,
                        });
                    } else {
                        this.showSupportAlert(this.$i18n.t('alertMessage.account.requestFailed'), ALERT_TYPES.error);
                    }
                }
            }
        },
        getAccountDataForUpdateRequest() {
            return {
                name: this.account.name,
                email: this.account.email,
                password: this.account.password,
                passwordCurrent: this.account.passwordCurrent,
                passwordConfirmation: this.account.passwordConfirmation,
            };
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/icons';
@import '~@/assets/scss/palette';
@import '~@/assets/scss/forms';
@import '~@/assets/scss/typography';
@import '~@/assets/scss/consistency-spacing';
@import '~@/assets/scss/typographyV2';

$icon-path: '../../../../assets/icons/';

.authentication-details {
    padding: $spacing-xxl;
    padding-left: 64px;
}
.authentication-details > * {
    margin-bottom: $spacing-m;
}
.account-page-image {
    height: 240px;
    background: $dirty-white url($icon-path + $account-page-rays);
    background-size: cover;

    .account-page-lock {
        width: 178px;
        height: 178px;
        background: url($icon-path + $account-page-lock);
    }
}

.account-personal-details {
    .title {
        font-weight: 600;
        color: $navy;
    }

    .form {
        padding-left: 64px;
    }
}

.role-names {
    padding-left: $spacing-m;
}
</style>
