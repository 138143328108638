import { type DnoResponseBase } from '@/http/index';

export enum SHARED_LOCATION_GATEWAY {
    SFTP = 'SftpGwDestination',
    EXTERNAL_SFTP_GATEWAY = 'ExtSftpDestination',
    S3 = 'S3Destination',
}

export const SHARED_LOCATION_GATEWAY_I18N_MAP = new Map([
    [SHARED_LOCATION_GATEWAY.SFTP, 'pipelines.gateway.sftp'],
    [SHARED_LOCATION_GATEWAY.EXTERNAL_SFTP_GATEWAY, 'pipelines.gateway.externalSftp'],
    [SHARED_LOCATION_GATEWAY.S3, 'pipelines.gateway.s3'],
]);

export type SharedLocationResponse = {
    id: string;
    name: string;
    last_update: number;
    source: {
        producer: string;
        job_id: string;
    };
    destination: {
        auth?: string;
        bucket?: string;
        filename: string;
        host?: string;
        key_prefix?: string;
        path?: string;
        port?: number;
        type: SHARED_LOCATION_GATEWAY;
    };
    encryption?: {
        public_key: string;
    };
};

export interface GetSharedLocationsResponse extends DnoResponseBase {
    data: SharedLocationResponse[];
}

export default class SharedLocation {
    id: string;
    name: string;
    type: SHARED_LOCATION_GATEWAY;
    lastUpdated?: number;
    source?: Record<string, any>;
    destination?: Record<string, any>;
    encryption?: Record<string, any>;
    host?: string;
    directory?: string;
    port: number;

    constructor(data: Partial<SharedLocation> = {}) {
        this.id = data.id || '';
        this.name = data.name || '';
        this.type = data.type as SHARED_LOCATION_GATEWAY;
        this.lastUpdated = data.lastUpdated;
        this.source = data.source;
        this.destination = data.destination;
        this.encryption = data.encryption;
        this.host = data.host;
        this.directory = data.directory;
        this.port = data.port as number;
    }

    get fullPath() {
        const prefix = this.type === SHARED_LOCATION_GATEWAY.S3 ? 'https:/' : 'sftp:/';
        return [prefix, this.host, this.directory].filter(Boolean).join('/');
    }

    static mapFromSharedLocation({
        id,
        name,
        source,
        destination,
        encryption,
        last_update: lastUpdated,
    }: SharedLocationResponse): SharedLocation {
        return new SharedLocation({
            id,
            name,
            type: destination?.type,
            source,
            destination,
            encryption,
            lastUpdated,
            host: destination.host || destination.bucket,
            directory: destination.key_prefix || destination.path,
            port: destination.port || 22,
        });
    }
}
