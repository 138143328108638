
import Vue from 'vue';
import { isEmpty, cloneDeep } from 'lodash';

// Vuex
import Actions, { Getters } from '@/store/mutation-types';
import { mapGetters, mapActions } from 'vuex';
import { Modules } from '@/store/store';

// Components
import AppHeader from '@/components/layout/AppHeader.vue';
import BalanceOverview from '@/__new__/features/customerCare/BalanceOverview.vue';
import BalanceInfoCard from '@/__new__/features/customerCare/BalanceInfoCard.vue';
import AppDialogV2 from '@/components/partials/AppDialogV2.vue';
import TopupForm from '@/__new__/features/customerCare/TopupForm.vue';
import DeductBalanceForm from '@/__new__/features/customerCare/DeductBalanceForm.vue';
import AppCustomerHeader from '@/components/partials/AppCustomerHeader.vue';
import AppToggleV2 from '@/components/partials/inputs/AppToggleV2.vue';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import Button from '@/common/button/Button';
import BucketData from '@/__new__/features/charging/bucketWizard/BucketData.vue';
import OrdersData from '@/__new__/features/charging/bucketWizard/OrdersData.vue';
import BucketWizardSessions from '@/__new__/features/charging/bucketWizard/BucketWizardSessions.vue';
import AppMultiselectV3 from '@/components/partials/inputs/AppMultiselectV3.vue';

// Http
import {
    getChargingBucketWizardBuckets,
    referBucketMetadata,
    clearBucketMetadata,
    setFlags,
    upsertResourceState,
} from '@/__new__/services/dno/charging/http/bucketWizard';
import { Wallet, Order, FLAG_TYPES } from '@/__new__/services/dno/charging/common/bucketWizardHelper';

// Helpers
import {
    USER_MANAGER_HIERARCHY,
    SUBSCRIBER_STATE,
    getSubscriberStateText,
    STATE_TO_KEY_MAP,
} from '@/__new__/features/customerCare/common/customerCareHelper';
import { BucketId, Bucket, BUCKET_TYPE, mapBuckets } from '@/__new__/features/customerCare/common/balanceHelper';
import { WALLET_TYPES } from '@/__new__/features/customerCare/common/ordersHelper';
import { MODAL_CONTENT } from '@/__new__/features/customerCare/common/userInfoHelper';
import { ALERT_TYPES } from '@/common/alerts/Alert';
import moment from 'moment';
import { TranslateResult } from 'vue-i18n';

type ConditionParameterOption = {
    conditionUUID: string;
    label: string;
    type: string;
};

type TargetState = {
    id: SUBSCRIBER_STATE;
    label: string;
};

export default Vue.extend({
    name: 'BucketWizard',
    components: {
        AppHeader,
        BalanceOverview,
        BalanceInfoCard,
        AppDialogV2,
        TopupForm,
        DeductBalanceForm,
        AppCustomerHeader,
        AppToggleV2,
        AppButton,
        BucketData,
        OrdersData,
        BucketWizardSessions,
        AppMultiselectV3,
    },
    data() {
        return {
            BUTTON_TYPES,
            MODAL_CONTENT,
            WALLET_TYPES,
            FLAG_TYPES,
            selectedWalletType: WALLET_TYPES.PRIMARY,
            modalVisible: false as boolean,
            editingContent: MODAL_CONTENT.DEFAULT as MODAL_CONTENT,
            alertButtons: {
                referBucket: new Button({
                    label: this.$i18n.t('charging.bucketWizard.refer'),
                    alertType: ALERT_TYPES.warning,
                }),
                clearBucket: new Button({
                    label: this.$i18n.t('generic.clear'),
                    alertType: ALERT_TYPES.warning,
                }),
                targetState: new Button({
                    label: this.$i18n.t('generic.change'),
                    alertType: ALERT_TYPES.warning,
                }),
            } as Record<string, Button>,

            targetType: USER_MANAGER_HIERARCHY.ACCOUNT as USER_MANAGER_HIERARCHY,
            targetId: '' as string,
            targetState: {
                id: SUBSCRIBER_STATE.ACTIVE,
                label: getSubscriberStateText(SUBSCRIBER_STATE.ACTIVE),
            } as TargetState,
            buckets: {} as Record<BucketId, Bucket>,
            wallet: {} as Wallet,
            flags: {
                lastInvalidateCall: '' as TranslateResult,
                isLocallyTiedFlag: false as boolean,
                accountWalletEnabled: false as boolean,
            },
            lastInvalidateTimestamp: '' as TranslateResult,
            orders: {} as Record<string, Order>,
            activeSessions: {},
            maxDateTimestamp: 86400000000000,
        };
    },
    computed: {
        ...mapGetters(Modules.charging, [
            Getters.GET_CHARGING_SPECIFICATIONS,
            Getters.GET_APPROVED_CONDITION_PARAMETERS,
        ]),
        isBalancesDataLoaded(): boolean {
            return Boolean(this[Getters.GET_CHARGING_SPECIFICATIONS].length && this.buckets);
        },
        mappedBalances(): object {
            if (this.isBalancesDataLoaded) {
                return mapBuckets(this.buckets, this[Getters.GET_CHARGING_SPECIFICATIONS]);
            }
            return {};
        },
        activeMappedBalances() {
            if (!isEmpty(this.mappedBalances)) {
                return this.filterActive(this.mappedBalances);
            }
            return {};
        },
        conditionParameters(): ConditionParameterOption[] {
            if (!this[Getters.GET_APPROVED_CONDITION_PARAMETERS]) return [];
            const cpData = cloneDeep(this[Getters.GET_APPROVED_CONDITION_PARAMETERS]);
            return cpData
                .sort((entity1: any, entity2: any) => entity1.name.localeCompare(entity2.name))
                .map((condition: any) => ({
                    conditionUUID: condition.id,
                    label: condition.name,
                    type: condition.conditionParameter.type,
                }));
        },
        walletInfo(): { activeEpoch: string; graceEpoch: string; amountWithCurrency: string; amount: number } {
            if (this.wallet?.primary) {
                const { active_epoch: activeEpoch, grace_epoch: graceEpoch, amount } = this.wallet.primary;
                return {
                    activeEpoch: activeEpoch ? this.$localeLibrary.getFormattedDate(activeEpoch) : '',
                    graceEpoch: graceEpoch ? this.$localeLibrary.getFormattedDate(graceEpoch) : '',
                    amountWithCurrency: this.$localeLibrary.getFormattedAmount(amount),
                    amount: Number(amount),
                };
            }
            return {
                activeEpoch: '',
                graceEpoch: '',
                amountWithCurrency: '',
                amount: 0,
            };
        },
        infoData() {
            return [
                {
                    title: this.$i18n.t('customerCare.basicInfo'),
                    initiallyExpanded: true,
                    value: [
                        {
                            name: this.$i18n.t('generic.status'),
                            key: 'targetState',
                        },
                        {
                            name:
                                this.targetType === USER_MANAGER_HIERARCHY.SUBSCRIBER
                                    ? this.$i18n.t('customerCare.subscriberTab.subscriberID')
                                    : this.$i18n.t('customerCare.accountId'),
                            value: this.targetId,
                        },
                        {
                            name: this.$i18n.t('charging.bucketWizard.lastInvalidateTimestamp'),
                            value: this.lastInvalidateTimestamp,
                        },
                        {
                            name: this.$i18n.t('charging.bucketWizard.referInvaliadateBucketMetadata'),
                            key: 'referInvaliadateBucketMetadata',
                        },
                        {
                            name: this.$i18n.t('charging.bucketWizard.clearBucketMetadata'),
                            key: 'clearBucketMetadataAction',
                        },
                    ],
                },
                {
                    title: this.$i18n.t('customerCare.account.flags'),
                    initiallyExpanded: true,
                    value: [
                        {
                            name: this.$i18n.t('charging.bucketWizard.lastInvCall'),
                            value: this.flags.lastInvalidateCall,
                        },
                        {
                            name: this.$i18n.t('charging.bucketWizard.isLocallyTiedFlag'),
                            key: 'isLocallyTiedFlag',
                        },
                        {
                            name: this.$i18n.t('charging.bucketWizard.accountWalletEnabled'),
                            key: 'accountWalletEnabled',
                        },
                    ],
                },
            ];
        },
        getFurthestTimestampPosible(): number {
            return moment(new Date(this.maxDateTimestamp)).unix();
        },
        targetStateOptions(): TargetState[] {
            return Object.values(SUBSCRIBER_STATE)
                .filter(e => typeof e === 'number')
                .map(e => ({
                    id: e,
                    label: this.$i18n.t(STATE_TO_KEY_MAP.get(e)),
                }));
        },
        getDialogTitle(): TranslateResult {
            return this.editingContent === MODAL_CONTENT.TOP_UP
                ? this.$i18n.t('customerCare.transactionHistory.topUp')
                : this.$i18n.t('rewards.walletHelper.deductBalance');
        },
    },
    created() {
        this.$withLoadingSpinner(
            async () => {
                const { id, userType } = this.$route.params;

                if (id && userType) {
                    this.targetId = id;
                    this.targetType = Number(userType);

                    await Promise.all([
                        this[Actions.REQUEST_CHARGING_SPECIFICATIONS_GROUPS](),
                        this[Actions.REQUEST_CHARGING_SPECIFICATIONS](),
                        this[Actions.REQUEST_CONDITION_PARAMETERS](),
                        this.fetchBuckets(),
                    ]);
                }
            },
            {
                errorHandler: () => {
                    this.$eventBus.$emit('showAlert', {
                        message: this.$i18n.t('alertMessage.failedToLoadNecessaryData'),
                    });
                },
            },
        );
    },
    methods: {
        ...mapActions(Modules.charging, [
            Actions.REQUEST_CHARGING_SPECIFICATIONS_GROUPS,
            Actions.REQUEST_CHARGING_SPECIFICATIONS,
            Actions.REQUEST_CONDITION_PARAMETERS,
        ]),
        async fetchBuckets(): Promise<void> {
            const { getFormattedDateAndTime } = this.$localeLibrary;
            const res = await getChargingBucketWizardBuckets(this.targetType, this.targetId);
            const {
                buckets,
                wallets,
                flags,
                last_invalidate_timestamp: lastInvalidateTimestamp,
                orders,
                state,
                active_sessions: activeSessions,
            } = res.data;

            this.buckets = buckets || {};
            this.wallet = wallets || {};
            this.flags.isLocallyTiedFlag = flags.is_locally_tied_flag;
            this.flags.accountWalletEnabled = flags?.account_wallet_enabled || false;
            this.flags.lastInvalidateCall = getFormattedDateAndTime(flags?.subscriber_info?.invalidate_timestamp);
            this.lastInvalidateTimestamp = getFormattedDateAndTime(lastInvalidateTimestamp);
            this.orders = Object.keys(orders).reduce((data: Record<string, Order>, current: string) => {
                data[current] = {
                    ...orders[current],
                    end_time: this.isMaxEndTime(orders[current].end_time)
                        ? this.getFurthestTimestampPosible
                        : orders[current].end_time,
                };
                return data;
            }, {});
            this.targetState = {
                id: state,
                label: this.$i18n.t(STATE_TO_KEY_MAP.get(state)),
            };
            this.activeSessions = activeSessions;
        },
        filterActive(
            mappedBalances: Record<BUCKET_TYPE, Array<Bucket & { bucketId: BucketId; bucketType: BUCKET_TYPE }>>,
        ) {
            const mappedBalancesCopy = cloneDeep(mappedBalances);
            Object.entries(mappedBalances).forEach(([bucketType, bucketsByType]) => {
                Object.values(bucketsByType).forEach(bucketsList => {
                    const csGroupId = bucketsList[0].cs_group_id;
                    const activeBuckets = bucketsList.filter((bucket: Bucket) => bucket.is_active === true);
                    if (activeBuckets.length === 0) {
                        delete mappedBalancesCopy[bucketType][csGroupId];
                    } else {
                        mappedBalancesCopy[bucketType][csGroupId] = activeBuckets;
                    }
                });
            });

            return mappedBalancesCopy;
        },
        onDeductAmount(walletType: WALLET_TYPES): void {
            this.selectedWalletType = walletType;
            this.modalVisible = true;
            this.editingContent = MODAL_CONTENT.DEDUCT;
        },
        onTopUpAmount(walletType: WALLET_TYPES): void {
            this.selectedWalletType = walletType;
            this.modalVisible = true;
            this.editingContent = MODAL_CONTENT.TOP_UP;
        },
        closeModal(): void {
            this.modalVisible = false;
            this.editingContent = MODAL_CONTENT.DEFAULT;
        },
        referBucketMetadataAction(): void {
            this.$eventBus.$emit('closeAllAlerts');
            this.$eventBus.$emit('showAlert', {
                message: this.$i18n.t('charging.bucketWizard.referInvaliadateBucketMetadataConfirm'),
                type: ALERT_TYPES.warning,
                buttons: [this.alertButtons.referBucket],
            });
            this.$eventBus.$once('buttonClicked', (buttonId: string) => {
                if (buttonId === this.alertButtons.referBucket.id) {
                    this.$withLoadingSpinner(
                        async () => {
                            await Promise.all([
                                referBucketMetadata(this.targetType, this.targetId),
                                this.fetchBuckets(),
                            ]);
                        },
                        {
                            errorHandler: () => {
                                this.$eventBus.$emit('showAlert', {
                                    message: this.$i18n.t('alertMessage.failedToLoadNecessaryData'),
                                });
                            },
                        },
                    );
                }
            });
        },
        clearBucketMetadataAction(): void {
            this.$eventBus.$emit('showAlert', {
                message: this.$i18n.t('charging.bucketWizard.clearBucketMetadataConfirm'),
                type: ALERT_TYPES.warning,
                buttons: [this.alertButtons.clearBucket],
            });
            this.$eventBus.$once('buttonClicked', (buttonId: string) => {
                if (buttonId === this.alertButtons.clearBucket.id) {
                    this.$withLoadingSpinner(
                        async () => {
                            await Promise.all([
                                clearBucketMetadata(this.targetType, this.targetId),
                                this.fetchBuckets(),
                            ]);
                        },
                        {
                            errorHandler: () => {
                                this.$eventBus.$emit('showAlert', {
                                    message: this.$i18n.t('alertMessage.failedToLoadNecessaryData'),
                                });
                            },
                        },
                    );
                }
            });
        },
        isMaxEndTime(endTime: number): boolean {
            return endTime >= Number.MAX_SAFE_INTEGER;
        },
        updateFlag(flagType: FLAG_TYPES, val: boolean): void {
            this.$withLoadingSpinner(async () => {
                await setFlags(this.targetId, this.targetType, {
                    [flagType]: val,
                });
            });
        },
        onTargetStateChange(newVal: TargetState) {
            this.$eventBus.$emit('closeAllAlerts');
            this.$eventBus.$emit('showAlert', {
                message: this.$i18n.t('charging.bucketWizard.targetStateChange'),
                type: ALERT_TYPES.warning,
                buttons: [this.alertButtons.targetState],
            });
            this.$eventBus.$once('buttonClicked', (buttonId: string) => {
                if (buttonId === this.alertButtons.targetState.id) {
                    this.$withLoadingSpinner(async () => {
                        this.targetState = newVal;
                        await Promise.all([
                            upsertResourceState(this.targetId, this.targetType, this.targetState.id),
                            this.fetchBuckets(),
                        ]);
                    });
                }
            });
        },
        onSubmitDialog(): void {
            if (this.editingContent === MODAL_CONTENT.TOP_UP) {
                this.$refs.topupForm.updateTopUpAmount();
            } else {
                this.$refs.deductBalanceForm.deductAmount();
            }
        },
    },
});
