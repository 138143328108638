<template>
    <div class="collapsible-item">
        <div
            class="d-flex justify-content-between align-items-center px-3 py-1"
            :class="{ 'has-border-bottom': !isItemCollapsed }"
        >
            <div class="heading-sm collapsible-title">
                <h4>{{ title }}</h4>
                <span
                    v-if="subTitle"
                    class="lf-secondary-text"
                >
                    {{ subTitle }}
                </span>
            </div>
            <slot name="icon">
                <IconButton
                    data-test-id="collapseBtn"
                    :icon="ICON_TYPES.ARROW_DOWN"
                    :class="{ 'rotate-180': !isItemCollapsed }"
                    @iconClick="toggleCollapse"
                />
            </slot>
        </div>
        <div
            v-if="!isItemCollapsed"
            class="collapsible-content"
        >
            <slot />
        </div>
    </div>
</template>
<script>
// components
import IconButton from '@/components/partials/IconButton.vue';
// helpers
import { ICON_TYPES } from '@/common/iconHelper';

export default {
    name: 'CollapsibleItem',
    components: {
        IconButton,
    },
    props: {
        value: {
            type: Boolean,
            default: false,
        },
        title: {
            type: String,
            default: '',
        },
        subTitle: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            ICON_TYPES,
            isItemCollapsed: this.value,
        };
    },
    methods: {
        toggleCollapse() {
            this.isItemCollapsed = !this.isItemCollapsed;
            this.$emit('change', this.isItemCollapsed);
        },
    },
};
</script>
<style lang="scss" scoped>
@import '@/assets/scss/_consistency.scss';
.collapsible-item {
    border: 1px solid $gray-200;
    border-radius: $spacing-xs;
    background: $white;
}
.collapsible-title {
    flex-grow: 1;
}
.rotate-180 {
    transform: rotate(-180deg);
}
.has-border-bottom {
    border-bottom: 1px solid $gray-200;
}
</style>
