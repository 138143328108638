<template>
    <div class="row">
        <div class="col-sm-8">
            <h2
                v-t="'generic.general'"
                class="subtitle mb-3"
            />
            <AppInputV3
                id="segmentName"
                data-test-id="segmentName"
                :invalid="$v.name.$error"
                :errorMessage="errorMessage"
                :placeholder="$i18n.t('segments.addSegmentsName')"
                :value="name"
                :label="$i18n.t('generic.name')"
                @input="$emit('onNameChanged', $event)"
                @blur="$v.name.$touch()"
            />
        </div>
        <div class="col-sm-8 mt-4">
            <AppTextareaV3
                id="'segmentDescription'"
                data-test-id="segmentDescription"
                :value="description"
                :label="$i18n.t('generic.description')"
                :placeholder="$i18n.t('generic.addDescription')"
                class="w-100"
                @input="$emit('onDescriptionChanged', $event)"
            />
        </div>
        <div class="col-sm-8 mt-4">
            <AppMultiselectV3
                id="segmentIdType"
                :options="idTypeOptions"
                :disabled="idTypeOptions.length <= 1"
                :value="idType"
                :placeholder="$i18n.t('generic.type')"
                :additionalLabel="$i18n.t('generic.type')"
                small
                data-test-id="segmentIdType"
                @input="$emit('onIdTypeChanged', $event)"
            />
        </div>
    </div>
</template>

<script>
// components
import AppInputV3 from '@/components/partials/inputs/AppInputV3.vue';
import AppTextareaV3 from '@/components/partials/inputs/AppTextareaV3.vue';
import AppMultiselectV3 from '@/components/partials/inputs/AppMultiselectV3.vue';
// helpers
import { minLength, required, maxLength } from 'vuelidate/lib/validators';

export default {
    name: 'SegmentDescription',
    components: {
        AppInputV3,
        AppTextareaV3,
        AppMultiselectV3,
    },
    props: {
        name: { type: String, default: '' },
        description: { type: String, default: '' },
        idType: { type: String, default: '' },
        idTypeOptions: { type: Array, default: () => [] },
    },
    validations: {
        name: {
            required,
            minLength: minLength(3),
            maxLength: maxLength(50),
        },
    },

    computed: {
        errorMessage() {
            if (!this.$v.name.required) {
                return this.$i18n.t('segments.segmentNameIsMissing');
            }
            if (!this.$v.name.minLength || !this.$v.name.maxLength) {
                return this.$i18n.t('generic.validations.lengthBetween3and50');
            }
            return '';
        },
    },
};
</script>
