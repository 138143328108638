import { map, groupBy, mapValues, pick, sortBy, reduce, omit, get } from 'lodash';
import { LOGICAL_OPERATORS } from '@/common/segments';
import { SEGMENT_ID_TYPES_REVERSE } from '@/common/StaticFilter';
import i18n from '@/i18n';
import { CohortList } from '@/__new__/services/dno/sinkConfigs/models/CohortExpression';

export const cohortExpressionsToJson = cohortsObj => {
    const applicable = cohortsObj.applicableCohorts;
    const notApplicable = cohortsObj.notApplicableCohorts;
    const everyApplicable = cohortsObj.everyApplicableCohort;
    const everyNotApplicable = cohortsObj.everyNotApplicableCohort;
    const applicableGroups = mapValues(groupBy(applicable, 'groupType'), group => group.map(item => item.id));
    const notApplicableGroups = mapValues(groupBy(notApplicable, 'groupType'), group => group.map(item => item.id));
    return {
        whitelist: {
            logical_operator: everyApplicable ? LOGICAL_OPERATORS.and : LOGICAL_OPERATORS.or,
            ...applicableGroups,
        },
        blacklist: {
            logical_operator: everyNotApplicable ? LOGICAL_OPERATORS.and : LOGICAL_OPERATORS.or,
            ...notApplicableGroups,
        },
    };
};

export const simpleCohortExpressionFromJson = cohortExpression => {
    const result = {};
    const arrayCohortsAllType = Object.values(SEGMENT_ID_TYPES_REVERSE);

    // initing as empty array for fallback
    const applicableCohorts = [];
    const notApplicableCohorts = [];

    // picking applicable entities from the API response
    const applicableEntries = Object.entries(pick(cohortExpression.whitelist, arrayCohortsAllType));

    // parsing and putting them into flag that is used in app
    applicableEntries.forEach(([groupType, ids]) => {
        ids.forEach(id => {
            applicableCohorts.push({
                groupType,
                id,
            });
        });
    });

    // picking not applicable entities from the API response
    const notApplicableEntries = Object.entries(pick(cohortExpression.blacklist, arrayCohortsAllType));

    // parsing and putting them into flag that is used in app
    notApplicableEntries.forEach(([groupType, ids]) => {
        ids.forEach(id => {
            notApplicableCohorts.push({
                groupType,
                id,
            });
        });
    });

    result.applicableCohorts = applicableCohorts;

    result.notApplicableCohorts = notApplicableCohorts;

    /* eslint-disable camelcase */
    result.everyApplicableCohort = cohortExpression?.whitelist?.logical_operator === LOGICAL_OPERATORS.and || false;

    result.everyNotApplicableCohort = cohortExpression?.blacklist?.logical_operator === LOGICAL_OPERATORS.and || false;

    return result;
};

const mapCohortsForDropdown = (cohortSimplified, arrayCohortsAll) =>
    arrayCohortsAll.find(cohort => cohort.id === cohortSimplified.id) || {
        id: cohortSimplified.id,
        name: `${cohortSimplified.id} ${i18n.t('generic.deleted')}`,
        groupType: cohortSimplified.groupType,
    };

export const mapCohortExpressionsFromJson = (cohortsObj, arrayCohortsAll) => {
    cohortsObj.applicableCohorts = cohortsObj.applicableCohorts.map(cohortSimplified =>
        mapCohortsForDropdown(cohortSimplified, arrayCohortsAll),
    );
    cohortsObj.notApplicableCohorts = cohortsObj.notApplicableCohorts.map(cohortSimplified =>
        mapCohortsForDropdown(cohortSimplified, arrayCohortsAll),
    );
};

export const cohortExpressionsFromJson = (cohortExpression, arrayCohortsAll) => {
    const cohortsObj = simpleCohortExpressionFromJson(cohortExpression);
    mapCohortExpressionsFromJson(cohortsObj, arrayCohortsAll);
    return cohortsObj;
};

function createEmptyCohortExpressionObject() {
    return {
        applicableCohorts: [],
        notApplicableCohorts: [],
        everyApplicableCohort: false,
        everyNotApplicableCohort: false,
    };
}

export function createEmptyCohortExpressionForSink() {
    return {
        cohortsObj: createEmptyCohortExpressionObject(),
        entryType: {
            id: '',
            label: '',
        },
        identifierField: 'id_value',
    };
}

export function createEmptyCohortExpressionsForSink(eventIds) {
    return reduce(
        eventIds,
        (acc, id) => {
            acc[id] = createEmptyCohortExpressionForSink();
            return acc;
        },
        {},
    );
}

export const groupSegments = segments => {
    const groups = groupBy(segments, segment => segment.segmentIdType);
    return map(groups, (array, segmentIdType) => ({
        groupName: `${SEGMENT_ID_TYPES_REVERSE[segmentIdType]} Segments`,
        groupValues: sortBy(
            array.map(segment => ({ ...segment, groupType: SEGMENT_ID_TYPES_REVERSE[segmentIdType] })),
            item => item.name,
        ),
    }));
};

export const isNonEmptyCohortExpression = cohortsObj =>
    cohortsObj &&
    ((cohortsObj.applicableCohorts && cohortsObj.applicableCohorts.length > 0) ||
        (cohortsObj.notApplicableCohorts && cohortsObj.notApplicableCohorts.length > 0));

export function isUserInEveryCohort(rawCohortExpression, listType) {
    return get(rawCohortExpression[listType], 'logical_operator', '') === LOGICAL_OPERATORS.and;
}

export function getCohortListSegments(rawCohortExpression, listType) {
    return Object.values(omit(rawCohortExpression[listType], 'logical_operator')).flat();
}
/**
 * Converts a raw cohort expression from json to a cohort expression overview group suitable for
 * CohortExpressionOverview component.
 */
export function rawCohortExpressionToCohortExpressionOverviewGroup(rawCohortExpression, segmentsById) {
    return [
        getCohortListSegments(rawCohortExpression, CohortList.WHITELIST),
        getCohortListSegments(rawCohortExpression, CohortList.BLACKLIST),
    ].map((group, index) => {
        let subtitle = '';
        let rowsCombinator = LOGICAL_OPERATORS.or;

        if (
            (index === 0 && isUserInEveryCohort(rawCohortExpression, CohortList.WHITELIST)) ||
            (index === 1 && isUserInEveryCohort(rawCohortExpression, CohortList.BLACKLIST))
        ) {
            subtitle = i18n.t('editors.identifiersInEverySegment');
            rowsCombinator = LOGICAL_OPERATORS.and;
        }
        return {
            title: i18n.t(index === 0 ? 'editors.applicableTo' : 'editors.notApplicableTo'),
            subtitle,
            rowsCombinator,
            rows: group.map(cohortId => ({
                name: segmentsById[cohortId]?.name,
                id: cohortId,
            })),
        };
    });
}

export function shouldShowCohortOverviewBlock(rawCohortExpression) {
    if (rawCohortExpression) {
        return (
            !!getCohortListSegments(rawCohortExpression, CohortList.WHITELIST).length ||
            !!getCohortListSegments(rawCohortExpression, CohortList.BLACKLIST).length
        );
    }
    return false;
}
