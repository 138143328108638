import {
    DataflowSection,
    SECTION_TYPES,
    NestedSectionElement,
    SectionElement,
} from '@/__new__/features/dataflows/common/DataflowInterfaces';
import DataflowElement from '@/__new__/services/dno/dataflows/models/DataflowElement';

export default class DataflowSectionModel {
    id: string;

    required: boolean;

    label: string;

    description?: string;

    elements: Element[] | NestedSectionElement[];

    error: boolean;

    collapsed: boolean;

    type?: SECTION_TYPES;

    constructor(data: DataflowSection) {
        this.id = data.id;
        this.required = data.required;
        this.label = data.label;
        this.description = data.description || '';
        this.error = false;
        this.collapsed = this.isSectionCollapsed(data);
        this.elements = this.mapSectionElemets(data.elements);
        this.type = data?.type || null;
    }

    isSectionCollapsed(data: DataflowSection): boolean {
        return !(this.required && data.elements.some(el => el.required));
    }

    mapSectionElemets(elements: SectionElement[]): any[] {
        return elements?.map((e: any) =>
            e.type === SECTION_TYPES.SECTION ? new DataflowSectionModel(e) : new DataflowElement(e),
        );
    }
}
