<template>
    <div class="page-container">
        <AppHeader
            :pageTitle="pageTitle"
            :isSearchEnabled="isSearchEnabled"
            :entitiesCount="entitiesCount"
            class="header"
            @input="query => $emit('searchQueryChanged', query)"
        >
            <slot
                slot="headerButtons"
                name="headerButtons"
            />
        </AppHeader>
        <div class="sidebar">
            <slot name="subSidebar" />
        </div>
        <div class="content">
            <slot name="content" />
        </div>
    </div>
</template>

<script>
import AppHeader from '@/components/layout/AppHeader.vue';

export default {
    name: 'AbstractSubSidebarPageWrapper',
    components: {
        AppHeader,
    },
    props: {
        pageTitle: {
            type: String,
            required: false,
            default: '',
        },
        isSearchEnabled: {
            type: Boolean,
            default: false,
        },
        entitiesCount: {
            type: Number,
            default: NaN,
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/mixins';
@import '~@/assets/scss/palette';
@import '~@/assets/scss/layout';
@import '~@/assets/scss/sidebar';
@import '~@/assets/scss/z-indexes';

.page-container {
    display: grid;
    grid-template-rows: $header-height; // equal to AppHeader height
    grid-template-columns: 15rem 1fr;
    height: 100vh;
    padding: 0;

    .header {
        grid-row: 1;
        grid-column: 1 / 3;
    }

    .sidebar {
        grid-column: 1 / 2;
    }

    .content {
        grid-row: 2 / 3;
        grid-column: 2 / 3;
        overflow: scroll;
    }
}
</style>
