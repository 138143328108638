<template>
    <div class="condition-args">
        <div
            v-for="(value, key) in displayedArgs"
            :key="key"
            :disabled="disabled"
            class="condition-args__item"
        >
            <span class="condition-args__key"> {{ camelCaseToText(key) }}: </span>
            <ConditionArgSelect
                v-if="matchComponent(key, ARGUMENT_COMPONENTS.SELECT_DATA_TYPE)"
                class="condition-args__val"
                data-testid="args-data-type-select"
                :value="selectedDataTypeOption"
                :options="computedDataTypeOptions"
                :placeholder="$t('generic.selectDataType')"
                :displayError="displayError"
                :disabled="disabled"
                @invalid="val => setInvalid(key, val)"
                @input="updateDataTypeAndValue"
            />
            <ConditionArgSelect
                v-if="matchComponent(key, ARGUMENT_COMPONENTS.SELECT)"
                class="condition-args__val"
                data-testid="args-select"
                :value="getDropdownValue(key)"
                :options="getArgOptionsFromConfig(key)"
                label="name"
                :placeholder="$t('generic.selectEntity')"
                :config="config[key] || {}"
                :displayError="displayError"
                :disabled="disabled"
                @invalid="val => setInvalid(key, val)"
                @input="val => emitUpdate(key, val)"
            />
            <ConditionArgTagsInput
                v-if="matchComponent(key, ARGUMENT_COMPONENTS.INPUT_TAGS)"
                class="condition-args__val"
                data-testid="args-tags-input"
                :value="value"
                :placeholder="$t('generic.addTags')"
                :displayError="displayError"
                :disabled="disabled"
                @invalid="val => setInvalid(key, val)"
                @input="val => emitUpdate(key, val)"
            />
            <ConditionArgInput
                v-if="matchComponent(key, ARGUMENT_COMPONENTS.INPUT)"
                class="condition-args__val"
                data-testid="args-input"
                :value="value"
                :config="config[key] || {}"
                :displayError="displayError"
                :disabled="disabled"
                @invalid="val => setInvalid(key, val)"
                @input="val => emitUpdate(key, val)"
            />
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { Getters } from '@/store/mutation-types';
import ConditionArgInput from '@/modules/rewards/components/conditions-tree/arguments/ConditionArgInput.vue';
import ConditionArgSelect from '@/modules/rewards/components/conditions-tree/arguments/ConditionArgSelect.vue';
import ConditionArgTagsInput from '@/modules/rewards/components/conditions-tree/arguments/ConditionArgTagsInput.vue';
import { getMultiLangFieldValueByLocale } from '@/common/entities/entityHelper';
import ENTITY_TYPES from '@/common/entities/entityTypes';
import { DATA_TYPES, DATA_TYPE_OPTIONS, ARGUMENT_COMPONENTS, getArgumentComponent } from '@/common/conditions-tree';
import { camelCaseToText } from '@/common/utils';

export default {
    name: 'ConditionArguments',
    components: {
        ConditionArgInput,
        ConditionArgSelect,
        ConditionArgTagsInput,
    },
    props: {
        args: {
            type: Object,
            required: true,
        },
        config: {
            type: Object,
            default: () => ({}),
        },
        displayError: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        pricingDemo: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            ARGUMENT_COMPONENTS,
            DATA_TYPE_OPTIONS,
            errorState: {},
        };
    },
    computed: {
        ...mapGetters('productcatalog', [Getters.PC_GET_ENTITIES_BY_TYPE_NOT_DELETED]),
        ...mapGetters('rewards', [Getters.GET_NOT_DELETED_REWARDS_ENTITIES_BY_TYPE]),
        offers() {
            return this[Getters.PC_GET_ENTITIES_BY_TYPE_NOT_DELETED](ENTITY_TYPES.OFFER);
        },
        products() {
            return this[Getters.PC_GET_ENTITIES_BY_TYPE_NOT_DELETED](ENTITY_TYPES.PRODUCT);
        },
        voucherSets() {
            return this[Getters.GET_NOT_DELETED_REWARDS_ENTITIES_BY_TYPE](ENTITY_TYPES.VOUCHER_SET).map(voucherSet => ({
                ...voucherSet,
                name: getMultiLangFieldValueByLocale(voucherSet.data?.name),
            }));
        },
        computedDataTypeOptions() {
            const dataTypeOptionsToDisplay = DATA_TYPE_OPTIONS.map(op => ({
                ...op,
                label: this.$t(op.i18nLabel),
            }));
            // TODO: revert this change after demo
            return this.pricingDemo ? dataTypeOptionsToDisplay.slice(2) : dataTypeOptionsToDisplay;
        },
        selectedDataTypeOption() {
            const options = DATA_TYPE_OPTIONS.map(op => ({
                ...op,
                label: this.$t(op.i18nLabel),
            }));
            let option = options.find(({ id }) => this.args.dataType === id);
            if (!option) {
                this.emitUpdate('dataType', DATA_TYPES.NATIVE);
                option = options.find(({ id }) => DATA_TYPES.NATIVE === id);
            }
            return option;
        },
        displayedArgs() {
            return this.pricingDemo ? { dataType: this.args.dataType, value: this.args.value } : this.args;
        },
    },
    watch: {
        errorState: {
            immediate: true,
            deep: true,
            handler(errorState) {
                this.$emit(
                    'updateErrorState',
                    Object.values(errorState).some(v => v),
                );
            },
        },
    },
    methods: {
        camelCaseToText,
        matchComponent(argsKey, expectedComponent) {
            const dataType = this.args.dataType ?? this.config[argsKey]?.dataType;
            return getArgumentComponent(argsKey, dataType, this.config) === expectedComponent;
        },
        async updateDataTypeAndValue(value) {
            this.emitUpdate('dataType', value);
            await this.$nextTick();
            this.emitUpdate('value', this.config.value?.multiple ? [] : null);
        },
        emitUpdate(key, value) {
            this.$emit('update', { key, value });
        },
        setInvalid(key, value) {
            this.$set(this.errorState, key, value);
        },
        getDropdownValue(argsKey) {
            const optionsMap = this.getArgOptionsFromConfigMap(argsKey);
            const argsValue = this.args[argsKey];
            return Array.isArray(argsValue) ? argsValue.map(val => optionsMap[val]) : optionsMap[argsValue];
        },
        getArgOptionsFromConfig(argsKey) {
            const entitiesMap = {
                [DATA_TYPES.OFFER]: this.offers,
                [DATA_TYPES.PRODUCT]: this.products,
                [DATA_TYPES.VOUCHER_SET]: this.voucherSets,
            };
            const dataType = this.args.dataType ?? this.config[argsKey]?.dataType;
            return entitiesMap[dataType] || [];
        },
        getArgOptionsFromConfigMap(argsKey) {
            const optionsList = this.getArgOptionsFromConfig(argsKey);
            return optionsList.reduce((acc, op) => {
                acc[op.id] = op;
                return acc;
            }, {});
        },
    },
};
</script>

<style lang="scss" scoped>
.condition-args {
    &__label {
        margin-bottom: 0.625rem;
        font-weight: 700;
    }

    &__item {
        margin-top: 0.3125rem;
        display: flex;
        align-items: center;
    }

    &__key {
        width: 6.25rem;
        flex-shrink: 0;
    }

    &__val {
        width: 100%;
    }
}
</style>
