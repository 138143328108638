<template>
    <div class="tags-container">
        <Tag
            v-for="(item, index) in value"
            :key="constructKey(item, index)"
            :label="labelFormatter(item)"
            :disabled="disabled"
            class="tag-margins"
            @click="emitClick($event, item, index)"
            @closeIconClick="removeTag($event, item, index)"
        />
    </div>
</template>

<script>
import Tag from '@/components/partials/inputs/Tag.vue';

export default {
    name: 'Tags',
    components: {
        Tag,
    },
    props: {
        value: {
            type: Array,
            required: true,
        },
        labelFormatter: {
            type: Function,
            default: entity => entity,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        emitClick(event, item, index) {
            this.$emit('click', { event, item, index });
        },
        removeTag(event, item, index) {
            const updatedValue = [...this.value.slice(0, index), ...this.value.slice(index + 1)];
            this.$emit('input', updatedValue);
        },
        constructKey(item, index) {
            return `${this.labelFormatter(item)}-${index}`;
        },
    },
};
</script>
<style scoped lang="scss">
@import '~@/assets/scss/consistency';

.tags-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.tag-margins {
    margin-right: $spacing-xs;
    margin-bottom: $spacing-xs;
}
</style>
