import {
    SINK_CONFIG_STATUS_SERVER_CODE_MAP,
    SINK_CONFIG_STATUS,
} from '@/__new__/services/dno/sinkConfigs/models/SinkConfigStatus';
import EventProp, { CDP_PROPERTY_TYPE } from '@/__new__/services/dno/events/models/EventProp';
import { cloneDeep, compact, isEmpty, omit } from 'lodash';
import i18n from '@/i18n';
import { TranslateResult } from 'vue-i18n';

import {
    changeConfigDefaultValueType,
    configColumnRowsToJson,
    sinkConfigColumnTypes,
    SinkConfigJsonPath,
} from '@/__new__/services/dno/sinkConfigs/models/SinkConfig';
import SinkFormatter from '@/__new__/services/dno/sinkConfigs/models/SinkFormatter';
import { CollapsibleListItem } from '@/common/AppCollapsibleListHelper';
import { uuidV4 } from '@/common/utils';
import SinkTypeCaster from '@/__new__/services/dno/sinkConfigs/models/SinkTypeCasters';
import { capitalizeFirstLetter } from '@/common/formatting';

export class SinkConfigCol {
    id: string;
    name: string;
    doc: string;
    isDefaultValueEnabled: boolean;
    defaultValue: string;
    defaultValueType: CDP_PROPERTY_TYPE | string;
    forbidFieldRemoval: boolean;
    rowValues: SinkConfigRowValue[];
    formatter: SinkFormatter | null;
    forbidFieldNameChange: boolean;
    isRequiredField: boolean;
    usedAsSum: boolean;
    usedAsLatest: boolean;
    typeCaster: SinkTypeCaster | null | undefined;
    constructor(data: Partial<SinkConfigCol> = {}) {
        this.id = data.id || uuidV4();
        this.name = data.name || '';
        this.doc = data.doc || '';
        this.isDefaultValueEnabled = !!data.isDefaultValueEnabled;
        this.defaultValue = data.defaultValue || '';
        this.defaultValueType = data.defaultValueType || '';
        this.forbidFieldRemoval = !!data.forbidFieldRemoval;
        this.rowValues = data.rowValues || [];
        this.formatter = data.formatter || null;
        this.forbidFieldNameChange = !!data.forbidFieldNameChange;
        this.isRequiredField = !!data.isRequiredField;
        this.usedAsSum = !!data.usedAsSum;
        this.usedAsLatest = !!data.usedAsLatest;
        this.typeCaster = data.typeCaster;
    }
}

export class SinkConfigRowValue {
    eventName?: string;
    eventType: string; // @todo Rename to event ID
    isJsonPathEnabled: boolean;
    jsonPath: {
        jsonPath?: string;
        type?: CDP_PROPERTY_TYPE;
        id?: string;
    };
    jsonPathType: CDP_PROPERTY_TYPE;
    property: EventProp;

    constructor(data: Partial<SinkConfigRowValue>) {
        this.eventName = data.eventName;
        this.eventType = data.eventType || '';
        this.isJsonPathEnabled = data.isJsonPathEnabled || false;
        this.jsonPath = data.jsonPath || {};
        this.jsonPathType = data.jsonPathType as CDP_PROPERTY_TYPE;
        this.property = data.property as EventProp;
    }
}

export function sinkConfigColFromJson(
    columns: any[],
    state: string | number | undefined,
    events: any[],
    formatters: SinkFormatter[] = [],
    uiSettings: any,
): SinkConfigCol[] {
    return columns.map(column => {
        const rowValues: SinkConfigRowValue[] = [];

        for (const eventType in column.source) {
            const relatedEvent = events.find(event => event.eventType === eventType);
            const fieldJsonPath = column.source[String(eventType)].custom_event_field_jsonpath;
            const fieldJsonPathType = column.source[String(eventType)].field_jsonpath_type;
            const isComplexType = !!fieldJsonPath;
            const name = column.source[String(eventType)].custom_event_field_name;

            rowValues.push(
                new SinkConfigRowValue({
                    eventName: relatedEvent?.name,
                    eventType,
                    isJsonPathEnabled: isComplexType,
                    jsonPath: {
                        jsonPath: fieldJsonPath,
                        type: fieldJsonPathType,
                    },
                    jsonPathType: fieldJsonPathType,
                    property: new EventProp({
                        id: name + Date.now(),
                        name,
                        type: column.sink_event_field_type,
                        mandatory: true,
                        forbiddenToEdit: [
                            SINK_CONFIG_STATUS_SERVER_CODE_MAP[SINK_CONFIG_STATUS.APPROVED],
                            SINK_CONFIG_STATUS.ENABLED,
                        ].includes(state as any),
                        sourceType: relatedEvent?.properties.find((p: EventProp) => p.name === name)?.sourceType,
                    }),
                }),
            );
        }
        let relatedFormatter = null;
        if (formatters.length) {
            relatedFormatter = cloneDeep(
                formatters.find(formatter => column.formatting_settings?.name === formatter.name),
            );
            if (relatedFormatter) {
                // eslint-disable-next-line prefer-destructuring
                relatedFormatter.value = column.formatting_settings.params[0];
                relatedFormatter.conversionType = column.formatting_settings.final_field_type;
                relatedFormatter.columnType = column.formatting_settings.final_field_type;
            }
        }

        const isJsonPathEnabled: boolean = rowValues[0]?.isJsonPathEnabled || false;
        // if json path enabled - take type from json path,
        // otherwise take type from source and fall back to sink_event_field_type
        const defaultValueType: CDP_PROPERTY_TYPE = isJsonPathEnabled
            ? (rowValues[0]?.jsonPath.type as CDP_PROPERTY_TYPE)
            : rowValues[0]?.property?.sourceType || column.sink_event_field_type;

        return new SinkConfigCol({
            id: column.sink_event_field_name + Date.now(),
            name: column.sink_event_field_name,
            doc: column.doc,
            isDefaultValueEnabled: !!column.default_value,
            // default value should be transformed to the string format since it can be boolean or number
            defaultValue: column.default_value && String(column.default_value),
            defaultValueType,
            // Allow editing if it's in Draft/Unapproved state.
            forbidFieldRemoval: [SINK_CONFIG_STATUS.DRAFT, SINK_CONFIG_STATUS.UNAPPROVED].includes(state as any)
                ? false
                : uiSettings.fieldsEditRules?.forbidFieldRemoval,
            rowValues,
            formatter: relatedFormatter,
            forbidFieldNameChange: uiSettings.fieldsEditRules?.forbidFieldNameChange,
            usedAsSum: column.definition_options?.sum,
            usedAsLatest: column.definition_options?.latest,
            typeCaster: isEmpty(column.casting_settings)
                ? null
                : new SinkTypeCaster({
                      label: `to ${capitalizeFirstLetter(column.casting_settings.name || '')}`,
                      toType: column.casting_settings.final_field_type,
                  }),
        });
    });
}
export function configColumnsToJson(columns: SinkConfigCol[] | undefined, showSumLatest = false) {
    return columns
        ? columns.map(column => {
              const columnType: CDP_PROPERTY_TYPE = calculateColumnType(column);
              return {
                  sink_event_field_name: column.name,
                  sink_event_field_type: columnType,
                  doc: column.doc,
                  ...(column.isDefaultValueEnabled &&
                      column.defaultValue && {
                          default_value: changeConfigDefaultValueType(column.defaultValue, column.defaultValueType),
                      }),
                  ...(column.rowValues.length && { source: configColumnRowsToJson(column.rowValues) }),
                  ...(column.formatter && {
                      formatting_settings: {
                          name: column.formatter.name,
                          params: [column.formatter.value],
                          final_field_type: column.formatter.conversionType,
                      },
                  }),
                  ...(!isEmpty(column.typeCaster) && {
                      casting_settings: {
                          name: column.typeCaster?.toType,
                          final_field_type: column.typeCaster?.toType,
                      },
                  }),
                  ...(showSumLatest && {
                      definition_options: {
                          sum: [CDP_PROPERTY_TYPE.INTEGER, CDP_PROPERTY_TYPE.NUMBER].includes(columnType)
                              ? column.usedAsSum
                              : false,
                          latest: column.usedAsLatest,
                      },
                  }),
              };
          })
        : [];
}
export function configColumnHasNoRelatedEvent(column: SinkConfigCol): boolean {
    // function is used in edit mode
    return !column.rowValues.every(row => !!row.eventName);
}
export function configColumnHasDifferentEventSources(column: SinkConfigCol): boolean {
    // column should not contain more than 1 properties from one event
    const columnEventNames = column.rowValues.map(row => row.eventName);
    return columnEventNames.length !== new Set(columnEventNames).size;
}
export function configColumnPropertiesHaveTheSameType(column: SinkConfigCol): boolean {
    if (column.rowValues.length === 0) return true;
    const mappedRowProperties = column.rowValues.map(row =>
        // Use sourceType for checks as type can be changed with: [formatter, typeCaster]
        row.isJsonPathEnabled ? row.jsonPathType : row.property.sourceType,
    );
    return new Set(mappedRowProperties).size === 1;
}
export function isMixedColumnType(column: SinkConfigCol): boolean {
    return column.defaultValueType === CDP_PROPERTY_TYPE.MIXED;
}
export function isMixedColumnTypeWithoutJsonPathValue(column: SinkConfigCol): boolean {
    return column.rowValues.some(row => row.isJsonPathEnabled && !row.jsonPath);
}
export function configColumnHasCorrectDefaultValue(column: SinkConfigCol): boolean {
    if (column.rowValues.length === 0 && !column.isDefaultValueEnabled) return false;
    if (column.isDefaultValueEnabled) {
        // check if default value exists
        return Boolean(column.defaultValue);
    }
    return true;
}
export function configColumnDefaultTypeIsValid(column: SinkConfigCol): TranslateResult | bigint {
    const columnType = column.rowValues[0]?.jsonPathType || column.defaultValueType;
    let validationResultMessage: TranslateResult | bigint = '';

    if (columnType === sinkConfigColumnTypes.BOOLEAN) {
        validationResultMessage =
            column.defaultValue === 'true' || column.defaultValue === 'false'
                ? ''
                : i18n.t('sinkConfigs.alerts.columnDefaultValueShouldBeBooleanType');
    }
    if (columnType === sinkConfigColumnTypes.INT) {
        const isInteger = Number.isInteger(Number(column.defaultValue));
        validationResultMessage = isInteger ? '' : i18n.t('sinkConfigs.alerts.columnDefaultValueShouldBeIntType');
    }
    if (columnType === sinkConfigColumnTypes.LONG) {
        try {
            // eslint-disable-next-line no-undef
            BigInt(column.defaultValue);
        } catch (err) {
            validationResultMessage = i18n.t('sinkConfigs.alerts.columnDefaultValueShouldBeLongType');
        }
    }
    if (columnType === sinkConfigColumnTypes.DOUBLE) {
        const isDouble = Number.isNaN(Number(column.defaultValue));
        validationResultMessage = isDouble ? i18n.t('sinkConfigs.alerts.columnDefaultValueShouldBeDoubleType') : '';
    }
    if (columnType === sinkConfigColumnTypes.DATE) {
        const pattern = new RegExp('^\\d{4}-\\d{1,2}-\\d{1,2}$');
        validationResultMessage = pattern.test(column.defaultValue)
            ? ''
            : i18n.t('sinkConfigs.alerts.columnDefaultValueShouldBeDateType');
    }
    if (columnType === sinkConfigColumnTypes.TIMESTAMP) {
        const pattern = new RegExp('^\\d{4}-\\d{1,2}-\\d{1,2} \\d{1,2}:\\d{1,2}:\\d{1,2}$');
        validationResultMessage = pattern.test(column.defaultValue)
            ? ''
            : i18n.t('sinkConfigs.alerts.columnDefaultValueShouldBeTimestampType');
    }
    return validationResultMessage;
}

export function calculateColumnType(col: SinkConfigCol): CDP_PROPERTY_TYPE {
    const jsonPathType: CDP_PROPERTY_TYPE | undefined = col.rowValues.find(c => c.isJsonPathEnabled)?.jsonPathType;
    let columnType: CDP_PROPERTY_TYPE = jsonPathType
        ? (jsonPathType as CDP_PROPERTY_TYPE)
        : col.formatter?.conversionType || (col.defaultValueType as CDP_PROPERTY_TYPE);
    // type caster has the biggest priority in types transformation
    if (col.typeCaster) {
        columnType = col.typeCaster.toType as CDP_PROPERTY_TYPE;
    }

    return columnType;
}

// used for map data for AppCollapsibleList.vue
export function mapColumnsForOverview(columns: SinkConfigCol[]): CollapsibleListItem[] {
    return columns.map(col => {
        const columnType: CDP_PROPERTY_TYPE = calculateColumnType(col);
        const label = String(columnType);
        const result = {
            isCollapsed: true,
            label,
            name: col.name,
            rows: compact([
                ...col.rowValues?.map(row => ({
                    name: row.eventName,
                    value: row.jsonPath?.jsonPath || row.property.name,
                })),
                (col.doc || '').length
                    ? {
                          name: i18n.t('generic.description'),
                          value: col.doc,
                          maxHeight: 35,
                      }
                    : null,
                col.usedAsSum
                    ? {
                          name: i18n.t('sinkConfigs.usedAsSum'),
                          value: col.usedAsSum,
                      }
                    : null,
                col.usedAsLatest
                    ? {
                          name: i18n.t('sinkConfigs.usedAsLatest'),
                          value: col.usedAsLatest,
                      }
                    : null,
                !!col.formatter
                    ? {
                          name: i18n.t('sinkConfigs.formatters.appliedFormatter'),
                          value: `${col.formatter.name} - ${col.formatter.value}`,
                      }
                    : null,
                !!col.typeCaster
                    ? {
                          name: i18n.t('sinkConfigs.typeCasters.appliedTypeCaster'),
                          value: col.typeCaster.label,
                      }
                    : null,
            ]),
        };
        if (col.isDefaultValueEnabled && Boolean(col.defaultValue)) {
            const defaultValueRow = {
                name: i18n.t('generic.defaultValue').toString(),
                value: col.defaultValue,
            };
            result.rows.push(defaultValueRow);
        }
        return result;
    });
}

export function createJsonPathOptions(jsonPathsArr: SinkConfigJsonPath[]) {
    const result = {
        [jsonPathsArr[0].jsonPath]: {
            groupName: jsonPathsArr[0].jsonPath,
            groupValues: jsonPathsArr.filter(el => el.jsonPath === jsonPathsArr[0].jsonPath),
        },
    };
    jsonPathsArr.forEach(el => {
        const isArrayType = el.jsonPath.includes('[*]');
        const withoutLastNestedLevel = isArrayType
            ? el.jsonPath.split('.').join('.')
            : el.jsonPath.split('.').slice(0, -1).join('.');
        if (!result[withoutLastNestedLevel]) {
            result[withoutLastNestedLevel] = {
                groupName: withoutLastNestedLevel,
                groupValues: [el],
            };
        } else {
            result[withoutLastNestedLevel].groupValues.push(el);
        }
    });
    return Object.entries(omit(result, '$')).map(entries => entries[1]);
}
export default { SinkConfigCol };
