export enum SUPPORT_ACTION {
    CANCEL_DEVICE_LOCATION = 'cancelDeviceLocation',
    NETWORK_COVERAGE = 'networkCoverage',
    CLIENT_STORAGE = 'clientStorage',
    VIEW_SUBSCRIBER_SERVICES = 'viewSubscriberServices',
    REPLACEMENT_PSIM = 'replacementPsim',
}

export type SupportActionMenuItem = {
    label: string;
    value: SUPPORT_ACTION;
    visible: boolean;
};
