import { render, staticRenderFns } from "./ServiceBalanceTile.vue?vue&type=template&id=28fa5cf8&scoped=true"
import script from "./ServiceBalanceTile.vue?vue&type=script&lang=ts"
export * from "./ServiceBalanceTile.vue?vue&type=script&lang=ts"
import style0 from "./ServiceBalanceTile.vue?vue&type=style&index=0&id=28fa5cf8&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "28fa5cf8",
  null
  
)

export default component.exports