import http, { type DnoResponseBase } from '@/http/index';
import type { AxiosPromise } from 'axios';
import type {
    ReportRequestParams,
    ReportDefinitionResponse,
    FetchReportsResponse,
    FetchReportResponse,
    ArchiveReportResponse,
    GetArchiveReportStatusResponse,
} from '@/__new__/services/dno/reports/common/reportsHelper';
export function fetchReportsDefinitionsOrd(): Promise<ReportDefinitionResponse[]> {
    return http('/v3reports/definitionsord').then(resp => resp.data.data);
}

export function fetchOrdReports({
    definitionId,
    startDate,
    endDate,
    size = 100,
    pageToken,
}: ReportRequestParams & { size?: number; pageToken?: string }): Promise<FetchReportsResponse> {
    const params: Record<string, any> = {
        definitionId,
        startDate,
        endDate,
        timezone: 'Etc/UTC',
        size,
    };

    if (pageToken) {
        params['page-token'] = pageToken;
    }

    return http('/v3reports/reportsord', {
        params,
    }).then(resp => resp.data);
}

export function generateOrdReport({
    definitionId,
    startDate,
    endDate,
    timezone,
}: ReportRequestParams): AxiosPromise<DnoResponseBase> {
    return http.post('/v3reports/generateord', {
        definitionId,
        startDate,
        endDate,
        timezone,
    });
}

export function fetchOrdReport({
    definitionId,
    startDate,
    endDate,
    timezone,
}: ReportRequestParams): Promise<FetchReportResponse['data']> {
    return http('/v3reports/fetchord', {
        params: {
            definitionId,
            startDate,
            endDate,
            timezone,
        },
    }).then(resp => resp.data.data);
}

export function publishOrdReport({
    definitionId,
    startDate,
    endDate,
    timezone,
}: ReportRequestParams): AxiosPromise<DnoResponseBase> {
    return http.post('/v3reports/publishord', {
        definitionId,
        startDate,
        endDate,
        timezone,
    });
}

export function previewOrdReport({
    definitionId,
    startDate,
    endDate,
    timezone,
}: ReportRequestParams): AxiosPromise<FetchReportResponse> {
    return http.post('/v3reports/previeword', {
        definitionId,
        startDate,
        endDate,
        timezone,
    });
}

export function startOrdArchiveCreation(data: ReportRequestParams): AxiosPromise<ArchiveReportResponse> {
    return http({
        method: 'POST',
        url: '/v3reports/archiveord',
        data,
    });
}

export function getOrdArchiveReportStatus(taskId: string): AxiosPromise<GetArchiveReportStatusResponse | string> {
    return http({
        url: '/v3reports/archivestatusord',
        params: {
            taskId,
        },
    });
}

export default {
    fetchReportsDefinitionsOrd,
    fetchOrdReports,
    generateOrdReport,
    fetchOrdReport,
    publishOrdReport,
    previewOrdReport,
    startOrdArchiveCreation,
    getOrdArchiveReportStatus,
};
