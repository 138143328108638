import Account from '@/__new__/services/dno/user/models/Account';
import customerCareHTTP from '@/__new__/services/dno/user/http/customer-care';
import { USER_MANAGER_HIERARCHY } from '@/__new__/features/customerCare/common/customerCareHelper';
import * as Sentry from '@sentry/vue';

export const getAccount = async (id: string): Promise<Account | null> => {
    try {
        const res = await customerCareHTTP.getAccount(id, USER_MANAGER_HIERARCHY.ACCOUNT);
        return new Account(Account.remapAccountFromBe(res.data?.account_info));
    } catch (err) {
        Sentry.captureException(err);
        return null;
    }
};
