export type ItemDataBe = {
    entity_type: string;
    entity_id: string;
    target_id: string;
    target_type: number;
    subscription_id?: string;
};

export type ItemData = {
    entityType: string;
    entityId: string;
    targetId: string;
    targetType: number;
    subscriptionId?: string;
};

const ORDER_ITEM_TYPES = {
    ADD: 'ADD',
    MODIFY: 'MODIFY',
    REMOVE: 'REMOVE',
};

export type OrderDataBe = {
    id: string;
    target_id: string;
    target_type: string;
    items: Record<string, ItemDataBe[]>;
    is_preview: boolean;
    state: number;
    create_timestamp: number;
    friendly_order_id: string;
    billing_payment_ref: {
        billing_account_id: string;
        billing_payment_id: string;
    };
};

export default class OrderData {
    id: string;
    targetId: string;
    targetType: string;
    items: {
        add: ItemData[];
        modify: ItemData[];
        remove: ItemData[];
    };
    isPreview: boolean;
    state: number;
    createTimestamp: number;
    billingPaymentRef: {
        billingAccountId: string;
        billingPaymentId: string;
    };
    friendlyOrderId: string;

    constructor(data: OrderData | Record<string, any> = {}) {
        this.id = data?.id || '';
        this.targetId = data?.targetId || '';
        this.targetType = data?.targetType || '';
        this.items = data?.items || {};
        this.isPreview = data?.isPreview || false;
        this.state = data?.state || 0;
        this.createTimestamp = data?.createTimestamp || 0;
        this.billingPaymentRef = data?.billingPaymentRef || {};
        this.friendlyOrderId = data?.friendlyOrderId || '';
    }

    static mapOrdersFromBE(app: OrderDataBe) {
        const items: Record<string, ItemData[]> = {};
        Object.values(ORDER_ITEM_TYPES).forEach((type: string) => {
            if (app.items[type]) {
                items[type] = OrderData.mapItemsFromBE(app.items[type]);
            }
        });
        return {
            id: app.id,
            targetId: app.target_id,
            targetType: app.target_type,
            items,
            isPreview: app?.is_preview || false,
            state: app.state,
            createTimestamp: app.create_timestamp,
            billingPaymentRef: {
                billingAccountId: app.billing_payment_ref?.billing_account_id,
                billingPaymentId: app.billing_payment_ref?.billing_payment_id,
            },
            friendlyOrderId: app.friendly_order_id,
        };
    }

    static mapItemsFromBE(items: ItemDataBe[]) {
        return items.map(el => ({
            entityType: el.entity_type,
            entityId: el.entity_id,
            targetId: el.target_id,
            targetType: el.target_type,
            subscriptionId: el.subscription_id,
        }));
    }
}
