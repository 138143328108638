<template>
    <div>
        <h3>{{ heading }}</h3>
        <p>{{ content }}</p>
        <h4 @click="onTagsClick">Tags</h4>
    </div>
</template>

<script>
// helpers
import RouteNames from '@/router/routeNames';
import { getHomePage, getTags } from '@/modules/userGuide/http/strapiHTTP';

export default {
    name: 'UserGuideHome',
    data() {
        return {
            pageTitle: this.$i18n.t('userGuide.userGuide'),
            heading: '',
            content: '',
            tags: [],
        };
    },
    mounted() {
        this.fetchData();
    },
    methods: {
        async fetchData() {
            const response = await getHomePage();
            const responseData = response?.data?.data?.attributes;
            this.heading = responseData?.heading;
            this.content = responseData?.content;
            const tagsResponse = await getTags();
            this.tags = tagsResponse?.data?.data;
        },
        onTagsClick(id) {
            this.$router.push({
                name: RouteNames.USER_GUIDE_TAGS,
                params: { id, companyId: this.$route.params.companyId },
            });
        },
    },
};
</script>

<style lang="scss" scoped></style>
