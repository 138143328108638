<template>
    <label
        :class="[{ disabled }, { error: hasError }]"
        class="d-flex align-items-center"
    >
        <span :class="{ 'mr-2': labelLeft }">
            {{ labelLeft }}
        </span>
        <input
            :checked="isRadioChecked"
            :value="inputValue"
            :name="name"
            :disabled="disabled"
            type="radio"
            class="no-outline"
            @change="emitInput(inputValue)"
        />
        <span
            :class="{ 'pr-2': labelLeft || labelRight }"
            class="d-flex align-items-center icon"
        >
            <span :class="{ 'ml-2': labelRight }">
                {{ labelRight }}
            </span>
        </span>
        <!-- Slot is for case if string label isn't enough -->
        <slot />
    </label>
</template>

<script>
export default {
    name: 'AppRadioButton',
    props: {
        value: { type: null, default: false },
        inputValue: { type: null, required: true },
        name: { type: String, default: null, required: true }, // HTML name attr, to define radiobutton group
        checked: { type: Boolean, default: null },
        labelLeft: { type: String, default: null },
        labelRight: { type: String, default: null },
        hasError: { type: Boolean, default: false },
        disabled: { type: Boolean, default: false },
    },
    data() {
        return {
            isRadioChecked: this.checked,
        };
    },
    watch: {
        value: {
            handler(newVal) {
                if (newVal !== undefined) {
                    this.isRadioChecked = this.inputValue === newVal;
                }
            },
            immediate: true,
        },
    },
    methods: {
        emitInput(value) {
            this.$emit('input', value);
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/animations';
@import '~@/assets/scss/mixins';
@import '~@/assets/scss/palette';

$icon-border-width: 0.125rem;
$icon-border-width-checked: 0.1875rem;

label {
    position: relative;
    color: $gray60;
    font-size: 14px;
    cursor: pointer;

    .icon {
        &::before,
        &::after {
            content: '';
            display: block;
            border-radius: 50%;
            transition: $fast-animation-time ease-in-out;
        }
        &::before {
            flex-shrink: 0;
            width: 1rem;
            height: 1rem;
            color: $gray4;
            box-shadow: inset 0 0 0 $icon-border-width currentColor;
            transition-property: color;
        }
        &::after {
            position: absolute;
            margin: $icon-border-width-checked;
            width: 0.625rem;
            height: 0.625rem;
            opacity: 0;
            background-color: $blue;
            transition-property: opacity;
        }
    }

    input[type='radio'] {
        @include visuallyHidden;

        &:hover + .icon::before {
            color: $blue30;
        }

        &:checked {
            & + .icon {
                &::before {
                    color: rgba($blue, 0.4);
                    box-shadow: inset 0 0 0 $icon-border-width-checked currentColor;
                }
                &::after {
                    opacity: 1;
                }
            }

            &:hover {
                & + .icon {
                    &::before {
                        color: $blue30;
                    }
                    &::after {
                        background-color: $blue50;
                    }
                }
            }
        }
    }

    &.disabled {
        cursor: auto;

        input[type='radio'] {
            & + .icon::before {
                color: $gray4;
                background-color: $white1;
            }

            &:checked {
                & + .icon {
                    &::before {
                        color: $white1;
                    }
                    &::after {
                        background-color: $gray4;
                    }
                }
            }
        }
    }

    &.error {
        input[type='radio'] {
            & + .icon::before {
                color: $red15;
            }

            &:checked {
                & + .icon {
                    &::before {
                        color: $red15;
                    }
                    &::after {
                        background-color: $red;
                    }
                }
            }
        }
    }
}
</style>
