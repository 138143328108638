import { InboxHistoryMessage } from '@/__new__/services/dno/campaigns/http/campaigns';
import { MessageTypes, MessageTypeLabels, MessageTypesHistoryIntMapping } from '@/common/CampaignMessage';

export const MESSAGE_HISTORY_STATUS = {
    IN_PROGRESS: 'in progress',
    PROCESSED: 'processed',
};

export const channelTabs = [
    {
        id: MessageTypes.PUSH,
        title: MessageTypeLabels[MessageTypes.PUSH],
    },
    {
        id: MessageTypes.SMS,
        title: MessageTypeLabels[MessageTypes.SMS],
    },
    {
        id: MessageTypes.EMAIL,
        title: MessageTypeLabels[MessageTypes.EMAIL],
    },
    {
        id: MessageTypes.OTT,
        title: MessageTypeLabels[MessageTypes.OTT],
    },
];

export function parseMessage(message: InboxHistoryMessage) {
    let parsedFields: any = {};

    try {
        parsedFields = JSON.parse(message?.message);
    } catch (error) {
        return parsedFields;
    }

    switch (message.channel_type) {
        case MessageTypesHistoryIntMapping[MessageTypes.PUSH]:
        case MessageTypesHistoryIntMapping[MessageTypes.OTT]:
            return new PushMessage(PushMessage.mapDataFromMessage(message));

        case MessageTypesHistoryIntMapping[MessageTypes.EMAIL]:
            return new EmailMessage(EmailMessage.mapDataFromMessage(message));

        case MessageTypesHistoryIntMapping[MessageTypes.SMS]:
            return new SmsMessage(SmsMessage.mapDataFromMessage(message));

        default:
            return parsedFields;
    }
}

export function parseMessageHtml(htmlString?: string) {
    const html = htmlString?.toLowerCase().includes('<!doctype html>') ? htmlString : '';
    let text = htmlString ?? '';
    if (html) {
        const parser = new DOMParser();
        const parsedHtml = parser.parseFromString(html, 'text/html');
        text = parsedHtml.body?.innerText ?? parsedHtml.body?.textContent ?? '';
    }
    return {
        html,
        text: text.trim(),
    };
}

export class UserMessage {
    type: (typeof MessageTypesHistoryIntMapping)[keyof typeof MessageTypesHistoryIntMapping];
    title?: string;
    text?: string;

    constructor(data: UserMessage) {
        this.type = data.type;
        this.title = data.title;
        this.text = data.text;
    }

    static parseMessage(message: InboxHistoryMessage): Record<string, any> {
        let parsedFields: any = {};

        try {
            parsedFields = JSON.parse(message?.message);
            return parsedFields;
        } catch (error) {
            return parsedFields;
        }
    }
}

export class PushMessage extends UserMessage {
    html?: string;

    constructor(data: PushMessage) {
        super(data);
        this.html = data.html;
    }

    static mapDataFromMessage(message: InboxHistoryMessage) {
        const parsedFields = super.parseMessage(message);
        const parsedHtml = parseMessageHtml(parsedFields.inboxMessage?.view?.message);
        const data: Record<string, any> = {
            ...parsedFields,
            ...parsedHtml,
        };

        return {
            type: message.channel_type,
            title: data.title ?? data.inboxMessage?.preview?.title,
            text: data.summary ?? data.message ?? data.text,
            html: data.html,
        };
    }
}

export class EmailMessage extends UserMessage {
    html: string;
    bcc?: string;
    subject?: string;
    sender?: string;

    constructor(data: EmailMessage) {
        super(data);
        this.html = data.html;
        this.bcc = data.bcc;
        this.subject = data.subject;
        this.sender = data.sender;
    }

    static mapDataFromMessage(message: InboxHistoryMessage) {
        const parsedFields = super.parseMessage(message);
        const parsedHtml = parseMessageHtml(parsedFields.body);
        const data: Record<string, any> = {
            ...parsedFields,
            ...parsedHtml,
        };

        return {
            type: MessageTypesHistoryIntMapping[MessageTypes.EMAIL],
            title: data.title ?? data.inboxMessage?.preview?.title,
            text: data.text,
            html: data.html,
            subject: data.subject,
            sender: data.source_name ? `${data.source_name} <${data.source_address}>` : data.source_address,
            bcc: data.bcc_emails?.join(', '),
        };
    }
}

export class SmsMessage extends UserMessage {
    sender?: string;

    constructor(data: SmsMessage) {
        super(data);
        this.sender = data.sender;
    }

    static mapDataFromMessage(message: InboxHistoryMessage) {
        const data = super.parseMessage(message);

        return {
            type: MessageTypesHistoryIntMapping[MessageTypes.SMS],
            title: data.title || data.inboxMessage?.preview?.title,
            text: data.message,
            sender: data.source_address,
        };
    }
}
