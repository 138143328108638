enum ENTITY_TYPES {
    // Product Catalog
    OFFER = 'offer',
    PRODUCT = 'product',
    CATEGORY = 'category',
    SERVICE = 'service',
    RESOURCE = 'resource',
    TEMPLATE = 'template',
    PRESET_TEMPLATE = 'preset_template',
    FACET = 'facet',
    VALIDATION_RULE = 'validation_rule',
    // Pricing and Fees
    FEE_RULE = 'fee_rule',
    PRICING_RULE = 'pricing_rule',
    // Billing
    DUNNING_PROFILE = 'dunning_profile',
    CYCLE_SPECIFICATION = 'cycle_specification',
    // Rewards
    REWARD_RULE = 'reward_rule_v4',
    PRIZE = 'prize',
    PRIZE_ENGINE = 'prize_engine',
    VOUCHER_SET = 'voucher_set',
    REWARD_PAYOUT = 'reward_payout_v4',
    LOTTERY = 'lottery',
    // Charging
    RATING_GROUP = 'rating_group',
    TAX_AREA_ID = 'taid',
    PROMOTION = 'promotion',
    REMOTE_CONFIG = 'remote_config',
    TENANT = 'tenant',
    CHARGING = 'charging',
    POLICY_COUNTER = 'policy_counter',
    POLICY_RULE = 'policy_rule',
    USAGE_COUNTER = 'usage_counter',
    CHARGING_SPECIFICATION = 'charging_specification',
    CHARGING_SPECIFICATIONS_GROUP = 'charging_specifications_group',
    CONDITION_PARAMETERS = 'condition_parameters',
    WALLET_COUNTERS = 'wallet_counters',
    CONTENT_TYPES = 'content_types',
    TARIFF_SPECIFICATION = 'tariff_specification',
    TARIFF_SPECIFICATION_ZERO_RATE = 'tariff_specification_zero_rate',
    TARIFF_SPECIFICATION_GROUP = 'tariff_specification_group',
    // CDP
    CAMPAIGN = 'campaign',
    CAMPAIGN_CATEGORY = 'campaign_category',
    EVENT = 'event',
    SEGMENT = 'segment',
    STATIC_FILTER = 'static_filter',
    REPORT_CONFIG = 'report_config',
    ORD_CONFIG = 'operational_report_config',
    REDSHIFT_CONFIG = 'redshift_config',
    KAFKA_SINK_CONFIG = 'kafka_sink_config',
    API_INVOKER_SINK_CONFIG = 'api_invoker_sink_config',
    QUIET_HOUR = 'quiet_hour',
    // Pipelines
    OBJECT_DISTRIBUTOR_CONFIG = 'object_distributor_config',
    // ORCHESTRATION
    ORCHESTRATION_ENGINE_PLAN = 'orchestration_engine_plan',
    ORCHESTRATION_ENGINE_TEMPLATES = 'orchestration_engine_templates',
    ORCHESTRATION_ENGINE_PAUSED_EXECUTIONS = 'orchestration_engine_paused_executions',
    ORCHESTRATION_ENGINE_BATCH_ACTIONS = 'orchestration_engine_batch_actions',
    // Others
    ORDER = 'order',
    REPORT = 'report',
    USER = 'user',
    ROLE = 'role',
    CONTENT_HOSTING_ITEM = 'content_hosting_item',
    // UM
    USER_MANAGEMENT_SUBSCRIBER = 'subscriber_details',
    DOCUMENT_TEMPLATES = 'document_templates',
    DOCUMENT_ASSETS = 'document_assets',
    // Operations
    UPLOAD_HISTORY = 'upload_history',
    // Operate APIs
    CHANNEL_PARTNER = 'channel_partner',
}

export default ENTITY_TYPES;
