
import Vue, { type PropType } from 'vue';

// Components
import AppIcon from '@/components/partials/icon/AppIcon.vue';
import AppTable from '@/components/partials/AppTable.vue';
import EntityStatusIndicator from '@/components/partials/EntityStatusIndicator.vue';
import IconButton from '@/components/partials/IconButton.vue';

// Helpers
import { ICON_COLORS, ICON_TYPES } from '@/common/iconHelper';
import type { TableColumn, TableSortFilter } from '@/common/filterTable';
import {
    FILE_UPLOAD_STATUS,
    FILE_UPLOAD_STATUS_TO_COLOR_MAP,
    type UploadedFileDetails,
} from '@/common/fileUploadHelper';

// Http
import { downloadSignedUrlFile } from '@/__new__/services/dno/progressTracker/progressTracker';

export default Vue.extend({
    name: 'BulkUploadHistoryV2',

    components: {
        AppIcon,
        AppTable,
        EntityStatusIndicator,
        IconButton,
    },

    props: {
        dataflowId: {
            type: String,
            default: '',
        },
        entities: {
            type: Array as PropType<UploadedFileDetails[]>,
            required: true,
        },
        tableColumns: {
            type: Array as PropType<TableColumn[]>,
            required: true,
        },
        tableId: {
            type: [String, Number],
            required: true,
        },
        searchQuery: {
            type: String,
            default: '',
        },
        sortBy: {
            type: Object as PropType<TableSortFilter | undefined>,
            default: undefined,
        },
        canSelectColumns: {
            type: Boolean,
            default: true,
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            FILE_UPLOAD_STATUS_TO_COLOR_MAP,
            ICON_COLORS,
            ICON_TYPES,
            selectedEntityId: '',
            isDownloading: false,
        };
    },

    methods: {
        onEntitySelect(entity: UploadedFileDetails): void {
            this.selectedEntityId = entity.entityName;
            this.$emit('rowSelect', entity);
        },
        isEntityLoaded({ fileUploadStatusStr }: UploadedFileDetails): boolean {
            return !!fileUploadStatusStr && fileUploadStatusStr !== this.$t('generic.empty');
        },
        isFileDownloadable({ fileUploadStatus }: UploadedFileDetails): boolean {
            return (
                !!this.dataflowId &&
                [FILE_UPLOAD_STATUS.SUCCESS, FILE_UPLOAD_STATUS.PARTIAL_SUCCESS, FILE_UPLOAD_STATUS.FAILED].includes(
                    fileUploadStatus as FILE_UPLOAD_STATUS,
                )
            );
        },
        downloadFile({ entityName }: UploadedFileDetails): Promise<void> {
            return this.$withProgressBar(
                async () => {
                    this.isDownloading = true;
                    await downloadSignedUrlFile(entityName, this.dataflowId);
                    this.isDownloading = false;
                },
                {
                    errorHandler: () => {
                        this.isDownloading = false;
                        this.$alert(this.$t('operations.alerts.failedToDownloadFile'));
                    },
                },
            );
        },
    },
});
