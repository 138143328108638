<template>
    <div>
        <!-- show tooltip above the name if name length is more than {{entityNameLength}} chars-->
        <QuickFilterTooltip
            :text="entityName.length > entityNameLength ? entityName : ''"
            class="max-width-100"
        >
            <div class="d-flex align-items-start">
                <h2 class="truncate-text flex-grow-1">
                    {{ entityName }}
                </h2>
                <div class="ml-2 mt-1 d-flex justify-content-end">
                    <slot name="statusIndicator" />
                </div>
            </div>
        </QuickFilterTooltip>

        <div
            v-if="entityId"
            class="d-flex align-items-center"
        >
            <span class="body-xs">{{ entityId }}</span>
            <IconButton
                :icon="ICON_TYPES.CLONE"
                @iconClick="copyToClipboard(entityId)"
            />
        </div>
    </div>
</template>

<script>
// components
import QuickFilterTooltip from '@/components/partials/fileUploader/QuickFilterTooltip.vue';
// helpers
import { copyToClipboard } from '@/common/utils';
import IconButton from '@/components/partials/IconButton.vue';
import { ICON_TYPES } from '@/common/iconHelper';

export default {
    name: 'OverviewHeaderV2',
    components: { IconButton, QuickFilterTooltip },
    props: {
        entityName: {
            required: true,
            type: String,
        },
        entityId: {
            type: String,
            default: '',
        },
        entityNameLength: {
            type: Number,
            default: 45,
        },
    },
    data() {
        return {
            ICON_TYPES,
        };
    },
    methods: {
        copyToClipboard,
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/_consistency';

.max-width-100 {
    max-width: 100%;
}
.flex-grow-1 {
    flex-grow: 1;
}
</style>
