
import Vue from 'vue';
import { mapGetters } from 'vuex';
import { Getters } from '@/store/mutation-types';

// Vue components
import AbstractEditPageWrapper from '@/components/layout/AbstractEditPageWrapper.vue';
import AppHeader from '@/components/layout/AppHeader.vue';
import AppInputV3 from '@/components/partials/inputs/AppInputV3.vue';
import AppTextareaV3 from '@/components/partials/inputs/AppTextareaV3.vue';
import EditorButtons from '@/components/layout/EditorButtons.vue';
import AppMultiselectV3 from '@/components/partials/inputs/AppMultiselectV3.vue';
import CardListRadioInput from '@/components/partials/cards/CardListRadioInput.vue';

// Mixins
import { validationMixin } from 'vuelidate';

// Misc
import RouteNames from '@/router/routeNames';
import { EDITOR_MODE } from '@/common/entities/entityHelper';
import { ICON_TYPES } from '@/common/iconHelper';

// Permissions
import * as permissionsService from '@/services/permissions/permissions.service';
import {
    createCycleSpecification,
    getCycleSpecificationById,
    updateCycleSpecification,
} from '@/__new__/services/dno/billing/cycleSpecificationService';

import { CycleSpecificationEntity } from '@/__new__/services/dno/billing/models/cycleSpecificationPortal';

import {
    CYCLE_TYPE,
    DAY,
    FIXED_CYCLE_PERIODS,
    SERVICE_ACTIVATION_PERIODS,
} from '@/__new__/services/dno/billing/models/cycleSpecificationDno';

export default Vue.extend({
    name: 'CycleSpecificationEditor',
    components: {
        AbstractEditPageWrapper,
        AppHeader,
        AppInputV3,
        AppMultiselectV3,
        AppTextareaV3,
        CardListRadioInput,
        EditorButtons,
    },
    mixins: [validationMixin],
    data() {
        return {
            ICON_TYPES,
            CYCLE_TYPE,
            FIXED_CYCLE_PERIODS,
            isExistingEntity: false,
            editorMode: EDITOR_MODE.VIEW,
            entity: {
                type: undefined,
                fixedCyclePeriod: undefined,
                dayOfWeek: undefined,
                dayOfMonth: undefined,
                serviceActivationPeriod: undefined,
                serviceActivationPeriodMultiplier: 1,
            } as CycleSpecificationEntity,
            showValidation: false,
            readonly: false,
            selectedTypeOption: null,
            cycleTypeOptions: [
                {
                    id: CYCLE_TYPE.FIXED_DATE,
                    label: this.$i18n.t('billing.fixedDate'),
                    description: this.$i18n.t('billing.repeatsOnFixedDate'),
                },
                {
                    id: CYCLE_TYPE.SERVICE_ACTIVATTION_DATE,
                    label: this.$i18n.t('billing.serviceActivation'),
                    description: this.$i18n.t('billing.repeatsOnServiceActivation'),
                },
            ],
            fixedCyclePeriodOptions: [
                {
                    id: FIXED_CYCLE_PERIODS.MONTHLY,
                    name: this.$i18n.t('billing.monthly'),
                },
                {
                    id: FIXED_CYCLE_PERIODS.BIMONTHLY,
                    name: this.$i18n.t('billing.bimonthly'),
                },
                {
                    id: FIXED_CYCLE_PERIODS.WEEK,
                    name: this.$i18n.t('billing.weekly'),
                },
            ],
            dayOfWeekOptions: [
                {
                    id: DAY.SUNDAY,
                    name: this.$i18n.t('generic.weekDays.sunday'),
                },
                {
                    id: DAY.MONDAY,
                    name: this.$i18n.t('generic.weekDays.monday'),
                },
                {
                    id: DAY.TUESDAY,
                    name: this.$i18n.t('generic.weekDays.tuesday'),
                },
                {
                    id: DAY.WEDNESDAY,
                    name: this.$i18n.t('generic.weekDays.wednesday'),
                },
                {
                    id: DAY.THURSDAY,
                    name: this.$i18n.t('generic.weekDays.thursday'),
                },
                {
                    id: DAY.FRIDAY,
                    name: this.$i18n.t('generic.weekDays.friday'),
                },
                {
                    id: DAY.SATURDAY,
                    name: this.$i18n.t('generic.weekDays.saturday'),
                },
            ],
            minDayOfMonth: 1,
            maxDayOfMonth: 31,
            minPeriodMultiplier: 1,
        };
    },
    computed: {
        ...mapGetters('operators', {
            selectedLanguage: Getters.languageDefault,
        }),
        pageTitle(): string {
            if (this.readonly) {
                return this.$i18n.t('billing.viewCycleSpecification');
            }
            return this.isExistingEntity
                ? this.$i18n.t('billing.editCycleSpecification')
                : this.$i18n.t('billing.newCycleSpecification');
        },
        serviceActivationPeriodOptions() {
            return [
                {
                    id: SERVICE_ACTIVATION_PERIODS.MONTH,
                    name: this.$i18n.tc('billing.month', this.entity.serviceActivationPeriodMultiplier),
                },
                {
                    id: SERVICE_ACTIVATION_PERIODS.DAY,
                    name: this.$i18n.tc('billing.day', this.entity.serviceActivationPeriodMultiplier),
                },
                {
                    id: SERVICE_ACTIVATION_PERIODS.WEEK,
                    name: this.$i18n.tc('generic.week', this.entity.serviceActivationPeriodMultiplier),
                },
            ];
        },
        maxPeriodMultiplier(): number {
            switch (this.entity.serviceActivationPeriod) {
                case SERVICE_ACTIVATION_PERIODS.MONTH:
                    return 12;
                case SERVICE_ACTIVATION_PERIODS.WEEK:
                    return 52;
                case SERVICE_ACTIVATION_PERIODS.DAY:
                default:
                    return 31;
            }
        },
    },
    async mounted() {
        // Route params:
        const { id, clone, mode } = this.$route.params;

        // Determine if we're looking at an existing pricing rule
        this.isExistingEntity = Boolean(id) && !clone;

        // Set editor mode
        this.editorMode = mode;

        // Determine if page is readonly
        this.readonly = this.editorMode === EDITOR_MODE.VIEW || !this.userHasWriteAccess();

        // Initialize form data
        if (id) {
            await this.loadEditor(id, clone);
        }
    },
    methods: {
        /**
         * Loads up the editors fields.
         * Contents determined by entity of given `id`.
         * Append ('cloned') to name if we're cloning from given `id`.
         */
        async loadEditor(id: string, clone: boolean) {
            await this.$withLoadingSpinner(
                async () => {
                    this.entity = await getCycleSpecificationById(id, this.selectedLanguage);
                    if (this.entity.type) {
                        this.selectedTypeOption = this.cycleTypeOptions.find(option => option.id === this.entity.type);
                    }
                    if (clone) {
                        this.entity.name += ' (cloned)';
                    }
                },
                {
                    errorHandler: () => {
                        this.$alert(this.$t('billing.failedToLoadCycleSpecification'));
                        this.routeToListPage();
                    },
                },
            );
        },
        async onSave() {
            if (this.isExistingEntity) {
                await this.saveExistingEntity();
            } else {
                await this.saveNewEntity();
            }
        },
        async saveNewEntity() {
            await this.$withLoadingSpinner(
                async () => {
                    await createCycleSpecification(this.entity, this.selectedLanguage);
                    this.$alert(this.$t('billing.successfullyCreatedCycleSpecification'));
                    this.routeToListPage();
                },
                {
                    errorHandler: () => {
                        this.$alert(this.$t('billing.failedTocreateCycleSpecification'));
                    },
                },
            );
        },
        async saveExistingEntity() {
            await this.$withLoadingSpinner(
                async () => {
                    await updateCycleSpecification(this.entity, this.selectedLanguage);
                    this.$alert(this.$t('billing.successfullyUpdatedCycleSpecification'));
                    this.routeToListPage();
                },
                {
                    errorHandler: () => {
                        this.$alert(this.$t('billing.failedToUpdateCycleSpecification'));
                    },
                },
            );
        },
        routeToListPage() {
            this.$router.push({
                name: RouteNames.CYCLE_SPECIFICATION,
                params: { companyId: this.$route.params.companyId },
            });
        },
        userHasWriteAccess(): boolean {
            return permissionsService.isFeatureFlagEnabled('postpaid-demo');
        },
        onTypeChange(option) {
            this.selectedTypeOption = option;
            this.entity.type = option.id;
            // Clear other props
            this.entity.fixedCyclePeriod = undefined;
            this.entity.dayOfWeek = undefined;
            this.entity.dayOfMonth = undefined;
            this.entity.serviceActivationPeriod = undefined;
            this.entity.serviceActivationPeriodMultiplier = 1;
        },
        onFixedCyclePeriodChange(cyclePeriod) {
            this.entity.fixedCyclePeriod = cyclePeriod;
            this.entity.dayOfWeek = undefined;
            this.entity.dayOfMonth = undefined;
        },
    },
});
