import httpClient from '@/http/index';
import {
    USER_MANAGER_HIERARCHY,
    getDnoClaimsHeader,
    CONTEXT_TYPE,
    CHALLENGE_TYPE,
    SUBSCRIBER_SUSPEND_SERVICES_REASON_CODE,
    SUBSCRIBER_SUSPEND_SERVICES_SUBSCRIPTION_TYPE,
    SUBSCRIBER_PORTING_ACTIONS,
    SUBSCRIBER_PORT_IN_MAP,
} from '@/__new__/features/customerCare/common/customerCareHelper';

const getUser = (id, targetType) =>
    httpClient({
        method: 'POST',
        url: '/v3usermanager/getuser',
        headers: {
            'Dno-Claims': JSON.stringify(getDnoClaimsHeader(id, targetType)),
        },
        data: {
            target_id: id,
            target_type: targetType,
        },
    });

/**
 * params argument is an object which has one property: { [option]: value },
 * where option can be any of the SEARCH_QUERY_OPTION params, and value any user input value
 * @param params
 * @returns {AxiosPromise}
 */
const getUserByParams = params =>
    httpClient({
        method: 'POST',
        url: '/v3usermanager/getuser',
        headers: {
            'Dno-Claims': JSON.stringify(params),
        },
        data: {},
    });

/**
 *
 * @param {string} id
 * @param {number} type
 * @param {boolean=} history
 * @returns {AxiosPromise} AxiosPromise
 */
const getUserByLoginId = (id, type, history = false) =>
    httpClient({
        method: 'POST',
        url: '/v3usermanager/getuserbyloginid',
        data: {
            login_id: id,
            login_type: type,
            get_user_property_history: history,
        },
    });

/**
 *
 * @param {import('@/__new__/features/customerCare/common/userInfoHelper').SearchUsersByReverseIndexRequest} data
 * @returns {AxiosPromise} AxiosPromise<any>
 */
const getUsersByReverseIndex = ({ filters, sort, pageNum = 0, pageSize = 20 }) =>
    httpClient({
        method: 'POST',
        url: '/v3usermanager/searchexternalreverseindex',
        data: {
            filters,
            sort,
            page_num: pageNum,
            page_size: pageSize,
        },
    });

const resetPasswordCreateChallenge = (id, targetType, email) =>
    httpClient({
        method: 'POST',
        url: '/v3usermanagerwrite/createchallenge',
        headers: {
            'Dno-Claims': JSON.stringify(getDnoClaimsHeader(id, targetType)),
        },
        data: {
            application_id: 'bfe6ea22ffae4212a6f566a92be86395',
            email,
            challenge_type: CHALLENGE_TYPE.EMAIL_LINK,
            context_type: CONTEXT_TYPE.RESET_SECRET,
        },
    });

const updateEmailCreateChallenge = (id, targetType, email) =>
    httpClient({
        method: 'POST',
        url: '/v3usermanagerwrite/createchallenge',
        headers: {
            'Dno-Claims': JSON.stringify(getDnoClaimsHeader(id, targetType)),
        },
        data: {
            // hardcoded pilotfiber app id since this is only used for
            // pilotfiber at this moment
            // new_secret: this.hashPassword(this.password),
            application_id: 'bfe6ea22ffae4212a6f566a92be86395',
            email,
            challenge_type: CHALLENGE_TYPE.EMAIL_LINK,
            context_type: CONTEXT_TYPE.UPDATE_EMAIL,
        },
    });

const verifyEmailCreateChallenge = (id, targetType, email) =>
    httpClient({
        method: 'POST',
        url: '/v3usermanagerwrite/createchallenge',
        headers: {
            'Dno-Claims': JSON.stringify(getDnoClaimsHeader(id, targetType)),
        },
        data: {
            application_id: 'bfe6ea22ffae4212a6f566a92be86395',
            email,
            challenge_type: CHALLENGE_TYPE.EMAIL_LINK,
            context_type: CONTEXT_TYPE.VERIFY_EMAIL,
        },
    });

const getSubscriber = (id, targetType) =>
    httpClient({
        method: 'POST',
        url: '/v3usermanager/getsubscriber',
        headers: {
            'Dno-Claims': JSON.stringify(getDnoClaimsHeader(id, targetType)),
        },
        data: {
            target_id: id,
            target_type: targetType,
        },
    });

/**
 * params argument is an object which has one property: { [option]: value },
 * where option can be any of the SEARCH_QUERY_OPTION params, and value any user input value
 * @param params
 * @returns {AxiosPromise}
 */
const getSubscriberByParams = params =>
    httpClient({
        method: 'POST',
        url: '/v3usermanager/getsubscriber',
        headers: {
            'Dno-Claims': JSON.stringify(params),
        },
        data: {},
    });

const getSessionsInfo = (id, targetType) =>
    httpClient({
        method: 'POST',
        url: '/v3usermanager/getsessions',
        headers: {
            'Dno-Claims': JSON.stringify(getDnoClaimsHeader(id, targetType)),
        },
        data: {
            target_id: id,
            target_type: targetType,
        },
    });

const getCredentialsChangeHistory = (id, targetType) =>
    httpClient({
        method: 'POST',
        url: '/v3usermanager/getcredentialshistory',
        headers: {
            'Dno-Claims': JSON.stringify(getDnoClaimsHeader(id, targetType)),
        },
        data: {
            target_id: id,
            target_type: targetType,
        },
    });

const getAccessList = (id, targetType) =>
    httpClient({
        method: 'POST',
        url: '/v3usermanager/getaccesslist',
        headers: {
            'Dno-Claims': JSON.stringify(getDnoClaimsHeader(id, targetType)),
        },
        data: {
            target_id: id,
            target_type: targetType,
        },
    });

const getAccount = (id, targetType) =>
    httpClient({
        method: 'POST',
        url: '/v3usermanager/getaccount', // check if is valid router
        headers: {
            'Dno-Claims': JSON.stringify(getDnoClaimsHeader(id, targetType)),
        },
        data: {
            target_id: id,
            target_type: targetType,
        },
    });

/**
 * Async API route
 *
 * @param {string} id
 * @returns AxiosPromise<DnoResponseBase>
 */
const deleteAccount = id =>
    httpClient({
        method: 'POST',
        url: '/v3usermanager/deleteaccount',
        headers: {
            'Dno-Claims': JSON.stringify(getDnoClaimsHeader(id, USER_MANAGER_HIERARCHY.ACCOUNT)),
        },
        data: {
            target_id: id,
            target_type: USER_MANAGER_HIERARCHY.ACCOUNT,
        },
    });

const getRoles = () =>
    httpClient({
        method: 'POST',
        url: '/v3usermanager/getroles',
        data: {
            // Temporary fix for required parameter.
            // Update when BE finishes implementation.
            target_type: 0,
        },
    });

const getStateHistory = (id, targetType) =>
    httpClient({
        method: 'POST',
        url: '/v3usermanager/getstatehistory',
        headers: {
            'Dno-Claims': JSON.stringify(getDnoClaimsHeader(id, targetType)),
        },
        data: {
            target_id: id,
            target_type: targetType,
        },
    });

const getEkycStatus = (id, targetType) =>
    httpClient({
        method: 'POST',
        url: '/v3usermanager/getekycstatus',
        headers: {
            'Dno-Claims': JSON.stringify(getDnoClaimsHeader(id, targetType)),
        },
        data: {
            target_id: id,
            target_type: targetType,
        },
    });

/**
 *
 * @param {String} id
 * @param {Number} targetType
 * @param {String=} sessionId
 * @returns {AxiosPromise} AxiosPromise
 */
const logout = (id, targetType, sessionId) => {
    const data = {
        target_id: id,
        target_type: targetType,
    };

    if (sessionId) {
        data.session_id = sessionId;
    }

    return httpClient({
        method: 'POST',
        url: '/v3usermanagerwrite/logout',
        headers: {
            'Dno-Claims': JSON.stringify(getDnoClaimsHeader(id, targetType)),
        },
        data,
    });
};

const updateEkycRequest = (id, targetType, value) =>
    httpClient({
        method: 'POST',
        url: '/v3usermanagerwrite/updateekycrequest',
        headers: {
            'Dno-Claims': JSON.stringify(getDnoClaimsHeader(id, targetType)),
        },
        data: {
            target_id: id,
            target_type: targetType,
            ...value,
        },
    });

const setRole = (id, targetType, userId, roleName) =>
    httpClient({
        method: 'POST',
        url: '/v3usermanagergrantwrite/setrole',
        headers: {
            'Dno-Claims': JSON.stringify(getDnoClaimsHeader(userId, USER_MANAGER_HIERARCHY.USER)),
        },
        data: {
            user_id: userId,
            // DEV: FOR TESTING ON DEV USE 'OBSERVER' ROLE NAME
            role_name: roleName,
            target_id: id,
            target_type: targetType,
        },
    });

const updateFlags = (id, targetType, flags) =>
    httpClient({
        method: 'POST',
        url: '/v3usermanagerwrite/updateflags',
        headers: {
            'Dno-Claims': JSON.stringify(getDnoClaimsHeader(id, targetType)),
        },
        data: {
            target_id: id,
            target_type: targetType,
            flags,
        },
    });

/**
 * Updates only key-value pairs sent through value param
 * @param id
 * @param targetType
 * @param value
 */
const updateInfo = (id, targetType, value) =>
    httpClient({
        method: 'POST',
        url: '/v3usermanagerwrite/updateinfo',
        headers: {
            'Dno-Claims': JSON.stringify(getDnoClaimsHeader(id, targetType)),
        },
        data: {
            target_id: id,
            target_type: targetType,
            ...value,
        },
    });

/**
 * Add address on targetType level
 *
 * @returns {AxiosPromise}
 * @param targetId ID of the user/account address belongs to
 * @param targetType Different types are account/subscriber/user
 * @param type Type of the address (shipping/billing/etc...)
 * @param parts
 * @param isCleansed just send it as true
 *
 * @todo Refactor the "parts" section to use some more secure/stable/resilient interface
 *       so someone does not accidentally remove or forget some part when calling this function.
 *
 */
const addAddress = (targetId, targetType, type, parts, isCleansed = true) =>
    httpClient({
        method: 'POST',
        url: '/v3usermanagerwrite/addaddress',
        headers: {
            'Dno-Claims': JSON.stringify(getDnoClaimsHeader(targetId, targetType)),
        },
        data: {
            target_id: targetId,
            target_type: targetType,
            type,
            is_cleansed: isCleansed,
            parts,
        },
    });

/**
 * Removes address on hierarchy level
 *
 * @returns {AxiosPromise}
 * @param id
 * @param targetType
 * @param addressId
 */
const removeAddress = (id, targetType, addressId) =>
    httpClient({
        method: 'POST',
        url: '/v3usermanagerwrite/removeaddress',
        headers: {
            'Dno-Claims': JSON.stringify(getDnoClaimsHeader(id, targetType)),
        },
        data: {
            id: addressId,
            target_id: id,
            target_type: targetType,
            hierarchy: targetType,
        },
    });

/**
 * Updates address on hierarchy level
 *
 * @returns {AxiosPromise}
 * @param id entity/account ID
 * @param targetType
 * @param parts
 * @param addressId
 * @param addressType
 */
const updateAddress = (id, targetType, addressId, addressType, parts) =>
    httpClient({
        method: 'POST',
        url: '/v3usermanagerwrite/updateaddress',
        headers: {
            'Dno-Claims': JSON.stringify(getDnoClaimsHeader(id, targetType)),
        },
        data: {
            id: addressId,
            type: addressType,
            hierarchy: targetType,
            parts,
            target_id: id,
            target_type: targetType,
        },
    });

/**
 * Gets all the consent sets for a given target_tuple
 * @param {string} id
 * @param {number} targetType
 *
 * @returns {AxiosPromise} - AxiosPromise<GetConsentsResponse>
 */
const getConsents = (id, targetType) =>
    httpClient({
        method: 'POST',
        url: '/v3usermanager/getconsent',
        headers: {
            'Dno-Claims': JSON.stringify(getDnoClaimsHeader(id, targetType)),
        },
        data: {
            target_id: id,
            target_type: targetType,
        },
    });

/**
 * Updates only key-value pairs sent through value param
 * @param id
 * @param targetType
 * @param value
 */
const suspendServices = (id, targetType, value = {}) =>
    httpClient({
        method: 'POST',
        url: '/v3provisioningwrite/updatesuspension',
        headers: {
            'Dno-Claims': JSON.stringify(getDnoClaimsHeader(id, targetType)),
        },
        data: {
            target_id: id,
            target_type: targetType,
            reason_code: SUBSCRIBER_SUSPEND_SERVICES_REASON_CODE.FRAUD,
            subscription_type: SUBSCRIBER_SUSPEND_SERVICES_SUBSCRIPTION_TYPE.PREPAID,
            ...value,
        },
    });

/**
 * Proxy call to /api/v3/orderoe/resume_order
 * @param targetId
 * @param targetType
 * @param subscriberId
 */
const resumeServices = ({ targetId, targetType, subscriberId }) =>
    httpClient({
        method: 'POST',
        url: '/v3ordermanagerwrite/oeresumeorder',
        headers: {
            'Dno-Claims': JSON.stringify(getDnoClaimsHeader(targetId, targetType)),
        },
        data: {
            target_id: targetId,
            target_type: targetType,
            ...(subscriberId && { subscriber_id: subscriberId }),
        },
    });

/**
 * Proxy call to /api/v3/orderoe/cancel_order
 * @param targetId
 * @param targetType
 * @param subscriberId
 */
const cancelServices = ({ targetId, targetType, subscriberId, cancelReason }) =>
    httpClient({
        method: 'POST',
        url: '/v3ordermanagerwrite/oecancelorder',
        headers: {
            'Dno-Claims': JSON.stringify(getDnoClaimsHeader(targetId, targetType)),
        },
        data: {
            target_id: targetId,
            target_type: targetType,
            ...(subscriberId && { subscriber_id: subscriberId }),
            ...(cancelReason && { cancel_reason: cancelReason }),
        },
    });
/**
 *
 * @param {string} id - SubscriberId
 * @param {(0|1)} portingAction - { portIn: 0, portOut: 1}
 * @returns {AxiosPromise} AxiosPromise
 */
const getSubscriberPortingRequest = (id, portingAction) =>
    httpClient({
        method: 'POST',
        url: '/v3usermanager/getsubscriberportingrequest',
        headers: {
            'Dno-Claims': JSON.stringify(getDnoClaimsHeader(id, USER_MANAGER_HIERARCHY.SUBSCRIBER)),
        },
        data: {
            target_id: id,
            target_type: USER_MANAGER_HIERARCHY.SUBSCRIBER,
            porting_scenario: portingAction,
        },
    });

/**
 *
 * @param {string} id - SubscriberId
 * @returns {AxiosPromise} AxiosPromise
 */
const getSubscriberPortInHistory = target_id =>
    httpClient({
        method: 'POST',
        url: '/v3usermanager/getSubscriberPortInHistory',
        headers: {
            'Dno-Claims': JSON.stringify(getDnoClaimsHeader(target_id, USER_MANAGER_HIERARCHY.SUBSCRIBER)),
        },
        data: {
            target_id,
            target_type: USER_MANAGER_HIERARCHY.SUBSCRIBER,
        },
    });

/**
 *
 * @param {string} id - SubscriberId
 * @returns {AxiosPromise} AxiosPromise
 */
const getSubscriberPortOutHistory = target_id =>
    httpClient({
        method: 'POST',
        url: '/v3usermanager/getSubscriberPortOutHistory',
        headers: {
            'Dno-Claims': JSON.stringify(getDnoClaimsHeader(target_id, USER_MANAGER_HIERARCHY.SUBSCRIBER)),
        },
        data: {
            target_id,
            target_type: USER_MANAGER_HIERARCHY.SUBSCRIBER,
        },
    });

/**
 *
 * @param {string} id - SubscriberId
 * @param {object} portInData - Port in data
 * @returns {AxiosPromise} AxiosPromise
 */
const initiatePortIn = (id, portInData) =>
    httpClient({
        method: 'POST',
        url: '/v3usermanager/initiateportin',
        headers: {
            'Dno-Claims': JSON.stringify(getDnoClaimsHeader(id, USER_MANAGER_HIERARCHY.SUBSCRIBER)),
        },
        data: {
            target_id: id,
            target_type: USER_MANAGER_HIERARCHY.SUBSCRIBER,
            port_in_data: portInData,
        },
    });

/**
 *
 * @param {string} id - Subscriber ID
 * @param {string} portInId - Port In ID
 * @returns {AxiosPromise} AxiosPromise
 */
const cancelPortIn = (id, portInId) =>
    httpClient({
        method: 'POST',
        url: '/v3usermanager/cancelportin',
        headers: {
            'Dno-Claims': JSON.stringify(getDnoClaimsHeader(id, USER_MANAGER_HIERARCHY.SUBSCRIBER)),
        },
        data: {
            port_in_request_id: portInId,
        },
    });

/**
 * Update porting state for portIn/portOut requests
 *
 * @param {Object} porting - Porting request data
 * @param {string} porting.id - subscriber Id
 * @param {string} porting.msisdn - Number that is being ported-in/ported-out
 * @param {(0|1)} porting.action - { portIn: 0, portOut: 1}
 * @param {(4|5|6|8|9|10)} porting.state - { portOut: [4,5,6], portIn: [8,9,10]]}
 * @param {number} porting.portInDate - Timestamp of scheduled portIn date
 * @returns {AxiosPromise} AxiosPromise
 */
const updatePortingState = porting => {
    const data = {
        target_id: porting.id,
        target_type: USER_MANAGER_HIERARCHY.SUBSCRIBER,
        msisdn: porting.msisdn,
        porting_scenario: porting.action,
        state: porting.state,
    };

    if (
        porting.action === SUBSCRIBER_PORTING_ACTIONS.PORT_IN &&
        porting.state === SUBSCRIBER_PORT_IN_MAP.MANUALLY_APPROVED
    ) {
        Object.assign(data, {
            additional_data: {
                port_in_date: porting.portInDate,
            },
        });
    }

    return httpClient({
        method: 'POST',
        url: '/v3usermanagerwrite/updateportingstate',
        headers: {
            'Dno-Claims': JSON.stringify(getDnoClaimsHeader(porting.id, USER_MANAGER_HIERARCHY.SUBSCRIBER)),
        },
        data,
    });
};

/**
 * @returns AxiosPromise<DnoResponseBase>
 */
const verifyIdentifier = ({ userId, identifierType, identifierValue, isVerified }) =>
    httpClient({
        method: 'POST',
        url: '/v3usermanagerwrite/verifyidentifier',
        headers: {
            'Dno-Claims': JSON.stringify(getDnoClaimsHeader(userId, USER_MANAGER_HIERARCHY.USER)),
        },
        data: {
            target_id: userId,
            target_type: USER_MANAGER_HIERARCHY.USER,
            notification_identifier_type: identifierType,
            notification_identifier: identifierValue,
            verified: isVerified,
        },
    });

/**
 * @returns AxiosPromise<DnoResponseBase>
 */
const issueTemporaryMPNPIN = targetId =>
    httpClient({
        method: 'POST',
        url: '/v3usermanagerwrite/issuetemporarymnppin',
        headers: {
            'Dno-Claims': JSON.stringify(getDnoClaimsHeader(targetId, USER_MANAGER_HIERARCHY.SUBSCRIBER)),
        },
        data: {
            target_id: targetId,
            target_type: USER_MANAGER_HIERARCHY.SUBSCRIBER,
        },
    });

/**
 * @returns AxiosPromise<DnoResponseBase>
 */
const verifyToken = (token, uri = '/api/v3/authorization/route') =>
    httpClient({
        method: 'POST',
        url: '/v3usermanager/verifytoken',
        data: {
            token,
            uri,
        },
    });

/**
 * @returns AxiosPromise<DnoResponseBase>
 */
const userForgetMe = (targetId, targetType) =>
    httpClient({
        method: 'POST',
        url: '/v3usermanager/userforgetme',
        headers: {
            'Dno-Claims': JSON.stringify(getDnoClaimsHeader(targetId, targetType)),
        },
        data: {
            target_id: targetId,
            target_type: targetType,
        },
    });

/**
 * @returns AxiosPromise<DnoResponseBase>
 */
const getResource = data =>
    httpClient({
        method: 'POST',
        url: '/v3odysseyResource/getResource',
        data,
    });

export default {
    getUser,
    getUserByParams,
    getUserByLoginId,
    getUsersByReverseIndex,
    getSubscriber,
    getSubscriberByParams,
    getSessionsInfo,
    getAccessList,
    getRoles,
    getStateHistory,
    setRole,
    logout,
    updateFlags,
    updateInfo,
    updateAddress,
    removeAddress,
    addAddress,
    getCredentialsChangeHistory,
    updateEmailCreateChallenge,
    resetPasswordCreateChallenge,
    verifyEmailCreateChallenge,
    getAccount,
    deleteAccount,
    getEkycStatus,
    updateEkycRequest,
    getConsents,
    suspendServices,
    resumeServices,
    cancelServices,
    getSubscriberPortingRequest,
    getSubscriberPortInHistory,
    getSubscriberPortOutHistory,
    updatePortingState,
    verifyIdentifier,
    initiatePortIn,
    cancelPortIn,
    issueTemporaryMPNPIN,
    verifyToken,
    userForgetMe,
    getResource,
};
