<template>
    <div>
        <div class="lf-subtitle pl-3 pt-4">
            {{ title }}
            <span class="font-weight-normal">({{ entities.length }})</span>
        </div>
        <div class="lf-secondary-text pl-3 mt-1">
            {{ subtitle }}
        </div>
        <div class="sticky-block py-3">
            <div
                v-if="allowEditing"
                class="d-flex justify-content-between pl-3 mt-2 mb-2"
            >
                <AppButton
                    :label="addBtnLabel"
                    :iconType="ICON_TYPES.PLUS"
                    class="mr-2"
                    data-test-id="addBtn"
                    @click="addBtnClick"
                />
                <AppButton
                    v-if="showAddAllEntitiesBtn"
                    :label="addAllBtnLabel"
                    :iconType="ICON_TYPES.PLUS"
                    data-test-id="addAllBtn"
                    @click="addAllBtnClick"
                />
            </div>
            <AppMultiselectV3
                v-if="entities.length && searchEnabled"
                data-test-id="search"
                class="pl-3"
                label="name"
                :placeholder="$i18n.t('generic.search')"
                :options="groupSinkColumnsByName"
                groupLabel="groupName"
                groupValues="groupValues"
                @input="val => changeActiveEntityId(val.id)"
            >
                <template slot="caret">
                    <AppIcon
                        class="custom-multiselect-carret"
                        :type="ICON_TYPES.SEARCH"
                        :color="ICON_COLORS.GRAY"
                    />
                </template>
            </AppMultiselectV3>
        </div>
        <Draggable
            :list="entities"
            :disabled="!allowDragging"
            :move="isMoveAllowed"
            handle=".dnd-icon"
        >
            <div
                v-for="entity in entities"
                :key="entity.id"
                :ref="entity.id"
                data-test-id="entityItem"
                @click="changeActiveEntityId(entity.id)"
            >
                <div
                    class="dnd-item mb-1 d-flex justify-content-between align-items-center"
                    :class="{ 'is-active': isActiveEntity(entity.id), 'is-invalid': isInvalidEntity(entity.id) }"
                >
                    <div class="d-flex align-items-center">
                        <div
                            v-if="allowDragging && !entity.forbidFieldRemoval"
                            class="dnd-icon mr-2"
                        />

                        <div v-if="entity.name">
                            {{ entity.name }}
                            <AppIcon
                                v-if="entity.formatter"
                                class="ml-1 d-inline-block"
                                :type="ICON_TYPES.FORMATTER"
                                :color="ICON_COLORS.BLUE_LIGHT"
                            />
                            <AppIcon
                                v-if="entity.typeCaster"
                                class="ml-1 d-inline-block"
                                :type="ICON_TYPES.FONT_ZOOM_RESET"
                                :color="ICON_COLORS.BLUE_LIGHT"
                            />
                            <div
                                v-if="getEntityUniqEventsLength(entity) > 1"
                                class="lf-secondary-text"
                            >
                                {{
                                    $i18n.t('sinkConfigs.groupedFieldsText', {
                                        number: getEntityUniqEventsLength(entity),
                                    })
                                }}
                            </div>
                            <div class="lf-secondary-text">
                                <!--events-->
                                <span v-if="shouldShowEventPropType(entity)">{{
                                    getPropLabelFromType(entity.type)
                                }}</span>
                                <!--sink configs-->
                                <span v-if="shouldShowSinkColType(entity)">{{
                                    getPropLabelFromType(calculateColumnType(entity))
                                }}</span>
                            </div>
                        </div>
                        <i v-else>Not specified</i>
                    </div>
                    <div
                        v-if="shouldShowDeleteBtn(entity)"
                        class="action-icons d-flex"
                    >
                        <div
                            class="trash-icon mr-1"
                            role="button"
                            data-test-id="trashBtn"
                            @click="confirmDelete(entity.id)"
                        />
                    </div>
                </div>
            </div>
        </Draggable>
    </div>
</template>
<script>
import Draggable from 'vuedraggable';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import AppIcon from '@/components/partials/icon/AppIcon.vue';
import AppMultiselectV3 from '@/components/partials/inputs/AppMultiselectV3.vue';
import i18n from '@/i18n';
import { groupBy, uniqBy } from 'lodash';
import entityEditorMixin from '@/common/entityEditorMixin';
import { ICON_TYPES, ICON_COLORS } from '@/common/iconHelper';
import { CDP_PROPERTY_TYPES, getPropLabelFromType } from '@/__new__/services/dno/events/models/EventProp';
import { calculateColumnType } from '@/__new__/services/dno/sinkConfigs/models/SinkConfigCol';

export default {
    name: 'AppEntitiesManagementBlock',
    components: {
        Draggable,
        AppButton,
        AppIcon,
        AppMultiselectV3,
    },
    mixins: [entityEditorMixin],
    props: {
        title: {
            type: String,
            required: true,
            default: i18n.t('events.eventProperties'),
        },
        subtitle: {
            type: String,
            default: '',
        },
        isDraggable: {
            type: Boolean,
            default: true,
        },
        activeEntityId: {
            required: true,
            type: [String, Number],
        },
        invalidEntitiesIds: {
            type: Array,
            default: () => [],
        },
        entities: {
            required: true,
            type: Array,
        },
        addBtnLabel: {
            type: String,
            default: i18n.t('events.addField'),
        },
        addAllBtnLabel: {
            type: String,
            default: i18n.t('events.addAllFields'),
        },
        showAddAllEntitiesBtn: {
            type: Boolean,
            default: false,
        },
        allowEditing: {
            type: Boolean,
            default: true,
        },
        searchEnabled: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            ICON_TYPES,
            ICON_COLORS,
            BUTTON_TYPES,
        };
    },
    computed: {
        CDP_PROPERTY_TYPES() {
            return CDP_PROPERTY_TYPES;
        },
        allowDragging() {
            return this.isDraggable && this.allowEditing;
        },
        groupSinkColumnsByName() {
            const groupedPropertiesByColName = Object.entries(groupBy(this.entities, 'name')).map(([colName, col]) => ({
                groupName: colName,
                groupValues: col[0].rowValues?.map(row => ({
                    ...row.property,
                    id: col[0].id,
                })),
            }));
            const allColumns = {
                groupName: this.$i18n.t('generic.columns'),
                groupValues: this.entities?.map(ent => ({ name: ent.name, id: ent.id })),
            };
            return [allColumns, ...groupedPropertiesByColName];
        },
    },
    methods: {
        getPropLabelFromType,
        shouldShowEventPropType(entity) {
            return Object.keys(entity).includes('rawSchema');
        },
        shouldShowSinkColType(entity) {
            return !!entity.rowValues;
        },
        calculateColumnType,
        isSinkConfig(entity) {
            return !!entity?.rowValues;
        },
        shouldShowDeleteBtn(entity) {
            if (this.isSinkConfig(entity)) {
                // Forbid removal of existing fields on edit.
                return this.allowEditing && !entity.isRequiredField && !entity.forbidFieldRemoval;
            }
            // handle event entity
            return this.allowEditing && !entity.forbiddenToEdit;
        },
        getEntityUniqEventsLength(entity) {
            return uniqBy(entity.rowValues, rowValue => rowValue.eventName).length;
        },
        isInvalidEntity(id) {
            return this.invalidEntitiesIds.includes(id);
        },
        isActiveEntity(id) {
            return id === this.activeEntityId;
        },
        changeActiveEntityId(id) {
            this.$emit('activeEntityChange', id);
            this.$refs[id][0].scrollIntoView({ block: 'center' });
        },
        addBtnClick() {
            this.$emit('addEntity');
        },
        addAllBtnClick() {
            this.$emit('addAllEntities');
        },
        confirmDelete(id) {
            this.$emit('deleteEntity', id);
        },
        /**
         * Prevent dragging over existing/saved columns
         *
         * @param {Object} e - SortableJS move event object
         * @param {Object} e.draggedContext
         * @param {number} e.draggedContext.index
         * @param {number} e.draggedContext.futureIndex
         * @param {Object} e.draggedContext.element
         * @param {Object} e.relatedContext
         * @param {number} e.relatedContext.index
         * @param {Object} e.relatedContext.element
         * @param {Object} e.relatedContext.component
         * @param {Object[]=} e.relatedContext.list
         */
        isMoveAllowed(e) {
            const { element: entity } = e?.relatedContext;

            if (this.isSinkConfig(entity)) {
                return this.allowDragging && !entity.forbidFieldRemoval;
            }

            return this.allowDragging && !entity.forbiddenToEdit;
        },
    },
};
</script>
<style lang="scss" scoped>
@import '~@/assets/scss/typographyV2';
@import '~@/assets/scss/palette';
@import '~@/assets/scss/icons';
@import '~@/assets/scss/animations';
@import '~@/assets/scss/_z-indexes.scss';

$icon-path: '~@/assets/icons/';

.dnd-item {
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    padding: 0.6rem 1rem;
    overflow: hidden;
    font-size: $text-sm;
    font-weight: $bold-font-weight;
    min-height: 3.75rem;
    cursor: pointer;
    &:hover,
    &.is-active {
        background-color: $blue15;
        .action-icons {
            transform: translateX(0);
        }
    }
    &.is-invalid {
        color: $red;
    }
}
.action-icons {
    transform: translateX(180%);
    transition: $medium-animation-time;
}

.dnd-icon {
    width: 1rem;
    height: 1rem;
    background-image: url($icon-path + $dnd-handle-gray);
    cursor: grab;
}

.trash-icon {
    width: 1.125rem;
    height: 1.125rem;
    background-image: url($icon-path + $delete-icon);
    &:hover {
        background-image: url($icon-path + $delete-icon-hover);
    }
}
.custom-multiselect-carret {
    position: absolute;
    bottom: 0.5rem;
    right: 0.5rem;
}
.sticky-block {
    position: sticky;
    top: 0;
    left: 0;
    z-index: $overlap-smth-z-index;
    background: $white;
}
</style>
