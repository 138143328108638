import cidrRegex from '@/__new__/features/settings/thirdPartyApplications/common/cidrRegex';
import { type DnoResponseBase } from '@/http';

export type RegisterPartnerPubKeysRequest = {
    partner_name: string;
    key_info: {
        scope: string;
        acl: string[];
        pub_key: string;
    };
};

export interface RegisterPartnerPubKeysResponse extends DnoResponseBase {
    existing_kids: string[];
    added_kids: string;
}

export type PartnerPubKey = {
    key_id: string;
    scope: string;
    acl: string[];
    alg_type: string;
    absolute_time: number;
};

export interface FetchPartnerPubKeysResponse extends DnoResponseBase {
    keys: PartnerPubKey[];
}

export const emptyPartnerPubKeysRequest: RegisterPartnerPubKeysRequest = {
    partner_name: '',
    key_info: {
        scope: '',
        acl: [''],
        pub_key: '',
    },
};

export const placeholderPartnerPubKeysRequest: RegisterPartnerPubKeysRequest = {
    partner_name: 'nemo',
    key_info: {
        scope: 'api/v3/cdp/events/schema/check_compatibility',
        acl: ['10.0.0.0/16'],
        pub_key: '-----BEGIN PUBLIC KEY-----\nMIgCQQCo9+BpMQ/dL3DS\n-----END PUBLIC KEY-----',
    },
};

export function cidrValidator(value: string) {
    return cidrRegex.test(value);
}

export function pubKeyValidator(value: string) {
    return (
        (value.includes('-----BEGIN PUBLIC KEY-----') && value.includes('-----END PUBLIC KEY-----')) ||
        (value.includes('-----BEGIN RSA PUBLIC KEY-----') && value.includes('-----END RSA PUBLIC KEY-----'))
    );
}
