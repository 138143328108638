
// Components
import AppProgressBar from '@/components/partials/AppProgressBar.vue';

// Helpers

export default {
    name: 'ServiceUsageTile',
    components: {
        AppProgressBar,
    },
    props: {
        serviceNumber: {
            type: Number,
            default: 4,
        },
    },
    data() {
        return {
            unlimitedLabel: `${this.$i18n.t('generic.unlimitedCapital')} / ${this.$i18n.t('generic.unlimitedCapital')}`,
        };
    },
    computed: {
        dataAmount() {
            return Math.round((this.serviceNumber * 23.56 - 2) * 100) / 100;
        },
        countPercentage() {
            return this.dataAmount / 100;
        },
    },
    created() {
        this.fetchTileData();
    },
    methods: {
        fetchTileData() {
            this.$emit('isDataLoadingUpd', true);
            if (this.serviceNumber === 1) {
                this.currentUsageVal = 15.3;
            }

            if (this.serviceNumber === 2) {
                this.currentUsageVal = 45.2;
            }
            this.currentUsageVal = 2.1;
            // TODO: remove timeout when we use real data
            setTimeout(() => {
                this.$emit('isDataLoadingUpd', false);
            }, 500);
        },
    },
};
