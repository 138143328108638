import invert from 'lodash/invert';

// Order is used to display as display order as well so to not sort them every time
// sorting them here.
export const EntityActions = {
    UNAPPROVE: 1,
    APPROVE: 2,
    EDIT: 3,
    DELETE: 4,
    CLONE: 5,
    PAUSE: 6,
    UNPAUSE: 7,
    DETAILS: 8,
    STOP: 9,
    START: 10,
    SEND_EVENT: 11,
    READ_ONLY: 12,
    DOWNLOAD: 13,
    actionList() {
        return [
            'APPROVE',
            'PAUSE',
            'UNPAUSE',
            'CLONE',
            'SEND_EVENT',
            'DELETE',
            'EDIT',
            'DETAILS',
            'STOP',
            'START',
            'READ_ONLY',
            'DOWNLOAD',
        ];
    },
};

export const EntityStateMapping: Record<string, number> = {
    UNAPPROVED: 1,
    APPROVED: 2,
    PAUSED: 3,
    DELETED: 4,
    DRAFT: 24,
};

export enum DraftStateMapping {
    UNPUBLISHED = 0,
    PUBLISHED = 1,
}

export const stateNamesMap = new Map([
    [1, 'UNAPPROVED'],
    [2, 'APPROVED'],
    [3, 'PAUSED'],
    [4, 'DELETED'],
    [5, 'PENDING'],
    [6, 'REJECTED'],
    [7, 'EXPIRED'],
]);

export function entityStateReverseMap(v: number): string {
    const ReverseEntityStateMapping = invert(EntityStateMapping);
    return ReverseEntityStateMapping[v] || 'N/A';
}

export function getEntityStateId(state: string) {
    return EntityStateMapping[state] || 'N/A';
}

export function getEntityStateName(numericState: number) {
    const state = stateNamesMap.get(numericState);
    if (state && Object.prototype.hasOwnProperty.call(EntityStateMapping, state)) {
        return state;
    }
    return 'N/A';
}
