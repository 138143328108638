
import Vue, { type PropType } from 'vue';
// Components
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import AppInputV3 from '@/components/partials/inputs/AppInputV3.vue';
import AppMultiselectV3 from '@/components/partials/inputs/AppMultiselectV3.vue';
import AppTable from '@/components/partials/AppTable.vue';
import FilterTable from '@/components/partials/FilterTable.vue';
import IconButton from '@/components/partials/IconButton.vue';
import ResponseModalButton from '@/components/partials/ResponseModalButton.vue';
import SearchBox from '@/components/partials/inputs/SearchBox.vue';
import TableFiltersRenderless from '@/components/filters/TableFiltersRenderless.vue';
import TableFiltersTags from '@/components/filters/TableFiltersTags.vue';
import { ICON_TYPES } from '@/common/iconHelper';

// Mixins
import DownloadDetailsMixin from '@/__new__/features/charging/DownloadDetailsMixin.vue';

import { getUserNameById } from '@/__new__/services/portal/profile/http/profile';

// MISC
import { DEVICE_LINE_AUTH_API_TYPE } from '@/__new__/services/dno/ossdevedge/models/DeveloperLineAuthorizationDno';
import {
    CHANNEL_INDEX,
    COMMERCIAL_OFFER_NAME_INDEX,
    MSISDN_INDEX,
    SEARCH_INDEX,
} from '@/__new__/services/dno/ossdevedge/models/QodMsisdnDno';
import {
    getAppTableColumnsData,
    getCustomerLines,
} from '@/__new__/services/dno/developerLineAuthorization/developerLineAuthorizationService';
import { type CustomerLine } from '@/__new__/services/dno/ossdevedge/models/QodMsisdnPortal';

export default Vue.extend({
    name: 'DeveloperLineSearch',
    components: {
        AppButton,
        AppInputV3,
        AppMultiselectV3,
        AppTable,
        FilterTable,
        IconButton,
        ResponseModalButton,
        SearchBox,
        TableFiltersRenderless,
        TableFiltersTags,
    },
    mixins: [DownloadDetailsMixin],
    props: {
        apiType: {
            type: Number as PropType<DEVICE_LINE_AUTH_API_TYPE>,
            required: true,
        },
    },
    data() {
        return {
            getAppTableColumnsData,
            BUTTON_TYPES,
            ICON_TYPES,
            searchResults: [] as CustomerLine[],
            msisdnSearchIndex: null as SEARCH_INDEX | null,
            msisdnSearchIndexOptions: [
                {
                    id: MSISDN_INDEX,
                    name: this.$t('qodNumberManagement.msisdn'),
                },
                {
                    id: CHANNEL_INDEX,
                    name: this.$t('qodNumberManagement.channel'),
                },
                {
                    id: COMMERCIAL_OFFER_NAME_INDEX,
                    name: this.$t('qodNumberManagement.commercialOfferName'),
                },
            ],
            msisdnSearchString: '',
            showSearchStringError: false,
            tableSearchQuery: '',
            searchInProgress: false,
            searchApiResponse: {},
            showCSVFormatModal: false,
        };
    },
    methods: {
        async onMsisdnSearch() {
            // Validate fields
            this.showSearchStringError = false;
            if (!this.msisdnSearchString) {
                this.showSearchStringError = true;
                return;
            }
            // Submit search request
            await this.$withLoadingSpinner(
                async () => {
                    this.tableSearchQuery = '';
                    this.searchResults = [];
                    this.searchInProgress = true;
                    const { entities, response } = await getCustomerLines(
                        this.apiType,
                        this.msisdnSearchIndex,
                        this.msisdnSearchString,
                    );
                    this.searchResults = entities;
                    this.searchApiResponse = response;
                    this.searchInProgress = false;
                },
                {
                    errorHandler: () => {
                        this.searchInProgress = false;
                        this.$alert(this.$t('qodNumberManagement.searchFailed'));
                    },
                },
            );
        },
        async onToggleShowDetails(entity: CustomerLine, detailVisibility: boolean) {
            // Exit if we're not showing details
            if (!detailVisibility) {
                return;
            }
            // Determine name of user that last updated entity IFF it's unknown
            if (entity.lastUpdatedByName) {
                return;
            }
            await this.$withLoadingSpinner(
                async () => {
                    const response = await getUserNameById(Number(entity.lastUpdatedBy));
                    this.$set(entity, 'lastUpdatedByName', response.data);
                },
                {
                    errorHandler: () => {
                        this.$set(entity, 'lastUpdatedByName', this.$t('generic.unknown'));
                    },
                },
            );
        },
    },
});
