<template>
    <div class="ops-graphs">
        <div class="container-fluid">
            <AppHeader />

            <div class="row no-gutters align-items-center graph-select-row dropshadow py-2 pl-4">
                <div>
                    {{ $i18n.t('operations.graphs.selectMetrics') }}
                </div>

                <div class="graph-dropdown">
                    <AppMultiselect
                        v-model="metricsToAdd"
                        :options="metricsList"
                        :multiple="false"
                        :showLabels="false"
                        label="displayName"
                        trackBy="displayName"
                        class="select"
                    />
                </div>

                <div>
                    <AppButton
                        :buttonType="BUTTON_TYPES.PRIMARY"
                        :label="$i18n.t('operations.graphs.addGraph')"
                        :iconType="ICON_TYPES.PLUS"
                        @click="addGraph"
                    />
                </div>
            </div>

            <!-- List of graphs -->
            <div
                v-for="(graph, index) in graphs"
                :key="graph.uuid"
                class="graph-list p-3 my-2 white-bg dropshadow"
            >
                <div class="graph-title">
                    <div class="d-flex pb-3">
                        <h4 class="w-75 text-uppercase">
                            {{ graph.name }}
                        </h4>
                        <div class="close-button w-25 text-right">
                            <img
                                alt="close"
                                src="../../../assets/icons/close.svg"
                                class="h-cursor-pointer"
                                @click="removeGraph(index)"
                            />
                        </div>
                    </div>
                    <div class="graph">
                        <LineGraph :columns="graph.columns" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// Models
import Graph from '@/__new__/services/portal/operationalGraphs/models/Graph';

// Services
import graphsService from '@/__new__/services/portal/operationalGraphs/graphService';

// Components
import AppHeader from '@/components/layout/AppHeader.vue';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import { ICON_TYPES } from '@/common/iconHelper';
import LineGraph from './LineGraph.vue';
import { ALERT_TYPES } from '@/common/alerts/Alert';

// 3rd Party components
import AppMultiselect from '@/components/partials/inputs/AppMultiselect.vue';

// Vuex
import { mapState, mapGetters } from 'vuex';

export default {
    name: 'Graphs',

    components: {
        AppButton,
        AppHeader,
        LineGraph,
        AppMultiselect,
    },

    data() {
        return {
            metricsToAdd: [],
            graphs: [],
            ICON_TYPES,
            BUTTON_TYPES,
        };
    },

    computed: {
        ...mapState('graphs', ['metrics']),
        ...mapGetters('graphs', ['metricsList']),
    },

    created() {
        graphsService.getMetricsList();
    },

    methods: {
        async addGraph() {
            this.$eventBus.$emit('closeAlertIfError');

            try {
                this.$Progress.start();
                const graphData = await graphsService.fetchAndBuildGraph(this.metricsToAdd);
                this.graphs.push(new Graph(this.metricsToAdd.displayName, graphData));
                this.$Progress.finish();
            } catch (e) {
                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('operations.graphs.errors.unableToFetch'),
                    type: ALERT_TYPES.error,
                });
            }
        },
        removeGraph(index) {
            this.graphs.splice(index, 1);
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/palette';

.graph-dropdown {
    min-width: 500px;
}

.graph-select-row {
    background-color: $white;

    > div {
        margin-right: 2rem;
    }
}
</style>
