<template>
    <div class="position-relative">
        <div class="d-flex justify-content-between mb-3 component-title-um align-items-center">
            {{ $i18n.t('customerCare.stateHistory') }}
        </div>
        <div
            v-if="showArrows"
            class="arrow-wrapper d-flex justify-content-center mr-2 left-arrow"
            :class="{ 'left-arrow-enabled': leftArrowEnabled }"
        >
            <img
                v-if="leftArrowEnabled"
                src="../../../../assets/icons/icon-arrow-left.svg"
                alt="arrow-back"
                @click="onLeftArrowClick()"
            />
        </div>
        <div
            v-if="showArrows"
            class="arrow-wrapper d-flex justify-content-center right-arrow"
            :class="{ 'right-arrow-enabled': rightArrowEnabled }"
        >
            <img
                v-if="rightArrowEnabled"
                src="../../../../assets/icons/icon-arrow-right.svg"
                alt="arrow-forward"
                @click="onRightArrowClick()"
            />
        </div>
        <div
            ref="draggable"
            v-dragscroll.x
            class="overflow-hidden position-relative"
            :class="{ draggable: showArrows }"
        >
            <div
                id="fade"
                :style="`left: ${fadePosition}px;`"
                :class="linearGradient"
            />
            <div
                ref="content"
                class="d-flex position-relative content"
            >
                <div
                    v-for="(state, index) in valueFormatted"
                    :key="`${state.state}${index}`"
                    :class="{ 'left-margin-date-box': index }"
                    class="width-fit-content content-item"
                >
                    <div ref="timelineElement">
                        <SubscriberStatusIndicator
                            ref="status"
                            :status="state.state"
                            class="mb-3 fit-content"
                        />
                        <div class="small-gray-date-label">
                            <div>
                                {{ state.date }}
                            </div>
                            <div>
                                {{ state.time }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                class="underline"
                :style="`width: ${timelineWidth}px`"
                :class="{ fixed: !value.length }"
            >
                <div
                    v-for="(offset, index) in arrowOffsets"
                    :key="`${offset}-${index}`"
                    :style="`left: ${offset}px`"
                    class="pointer"
                />
            </div>
        </div>
    </div>
</template>

<script>
import SubscriberStatusIndicator from '@/__new__/features/customerCare/subscriber/SubscriberStatusIndicator.vue';
import StateHistoryMixin from '@/__new__/features/customerCare/mixins/StateHistoryMixin.vue';

export default {
    name: 'SubscriberStateHistory',
    components: {
        SubscriberStatusIndicator,
    },
    mixins: [StateHistoryMixin],
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/palette';
@import '~@/assets/scss/animations';
@import '~@/assets/scss/typography';
@import '~@/assets/scss/z-indexes';

.underline {
    border-bottom: solid 1px rgba(51, 81, 149, 0.16);
    position: relative;
    top: -2.8rem;

    &.fixed {
        top: 0;
    }
}

.left-margin-date-box {
    margin-left: 5rem;
}

.width-fit-content {
    min-width: fit-content;
}

.draggable {
    position: relative;
    cursor: -webkit-grab;
    cursor: -moz-grab;
    cursor: grab;

    scroll-behavior: smooth;
    &:active {
        cursor: -webkit-grabbing;
        cursor: -moz-grabbing;
        cursor: grabbing;

        scroll-behavior: auto;
    }
}

#fade {
    position: absolute;
    z-index: $overlap-smth-z-index;
    height: 100%;
    width: 100%;
    /* so the text is still selectable */
    pointer-events: none;
}

.pointer {
    background: $dirty-white;
    position: absolute;
    border-top: solid 1px rgba(51, 81, 149, 0.15);
    border-left: solid 1px rgba(51, 81, 149, 0.15);
    height: 10px;
    width: 10px;
    transform: translate(-50%, -50%) rotate(45deg);
}

.arrow-wrapper {
    height: 2rem;
    width: 2rem;
    display: flex;
    align-items: center;

    img {
        height: 1rem;
    }

    &.left-arrow-enabled:hover {
        transition: 0.25s ease-in-out;
        cursor: pointer;
        border-radius: 1rem;
        padding: 1rem;
        background-color: $blue15;
    }
    &.right-arrow-enabled:hover {
        transition: 0.25s ease-in-out;
        cursor: pointer;
        border-radius: 1rem;
        background-color: $blue15;
    }

    &.right-arrow {
        position: absolute;
        top: 30%;
        right: 0;
        z-index: $two-z-index;
    }

    &.left-arrow {
        position: absolute;
        top: 30%;
        left: 0;
        z-index: $two-z-index;
    }
}

.fit-content {
    width: fit-content;
}
.leftArrowEnabledGradient {
    background: linear-gradient(to right, rgba(246, 246, 246) 0.5%, rgba(246, 246, 246, 0) 5%);
}

.rightArrowEnabledGradient {
    background: linear-gradient(to left, rgba(246, 246, 246) 0.5%, rgba(246, 246, 246, 0) 5%);
}
.bothArrowsEnabledGradient {
    background: linear-gradient(to right, rgba(246, 246, 246) 0.5%, rgba(246, 246, 246, 0) 5%),
        linear-gradient(to left, rgba(246, 246, 246) 0.5%, rgba(246, 246, 246, 0) 5%);
}
.content-item:last-of-type {
    padding-right: 1rem;
}
</style>
