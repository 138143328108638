
import Vue from 'vue';

// Components
import AbstractTableTile from '@/__new__/features/customerCareSuite/components/AbstractTableTile.vue';
import IconButton from '@/components/partials/IconButton.vue';

// Helpers
import * as Sentry from '@sentry/vue';
import luaErrors from '@/common/luaErrors';
import tableColumnType from '@/common/filterTable';
import sortBy from 'lodash/sortBy';
import { ICON_TYPES } from '@/common/iconHelper';
import { ALERT_TYPES } from '@/common/alerts/Alert';
import BillingStatementCycle from '@/__new__/services/dno/orders/models/BillingStatementCycle';
import { USER_MANAGER_HIERARCHY } from '@/__new__/features/customerCare/common/customerCareHelper';

// HTTP
import customerCareHTTP from '@/__new__/services/dno/user/http/customer-care';
import ordersAPI from '@/__new__/services/dno/orders/http/orders';

export default Vue.extend({
    name: 'BillingStatementsTile',
    components: {
        AbstractTableTile,
        IconButton,
    },
    props: {
        tableKey: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            targetUser: { id: this.$route.params.id, type: USER_MANAGER_HIERARCHY.ACCOUNT },
            apiResponse: {},
            billingCycles: [],
            ICON_TYPES,
        };
    },
    computed: {
        billingStatementCyclesForDisplay() {
            const sortedBillingCyles = sortBy(this.billingCycles, ['start', 'end']);
            const filteredBillingCycles = sortedBillingCyles.filter(
                // TODO: use proper state mapping here instead of hardcode
                billingCycle =>
                    ![200, 201, 400, 600, 601].includes(billingCycle?.state) &&
                    billingCycle?.isRefund !== true &&
                    billingCycle?.start,
            );

            return filteredBillingCycles.map(bc => ({
                ...bc,
                startLabel: this.$localeLibrary.getFormattedDate(bc.start),
                endLabel: this.$localeLibrary.getFormattedDate(bc.end),
            }));
        },
        columnsData() {
            return [
                {
                    name: this.$i18n.t('generic.from'),
                    key: 'startLabel',
                    sortBy: cycle => cycle.start,
                    field: 'start',
                    filterType: tableColumnType.DATE,
                },
                {
                    name: this.$i18n.t('generic.to'),
                    key: 'endLabel',
                    sortBy: cycle => cycle.end,
                    field: 'end',
                    filterType: tableColumnType.DATE,
                },
            ];
        },
    },
    created() {
        this.fetchTileData();
    },
    methods: {
        async fetchTileData() {
            this.$emit('isDataLoadingUpd', true);
            await this.$withProgressBar(
                async () => {
                    const { id, type } = this.targetUser;
                    const res = await ordersAPI.getTransactions(id, type);

                    this.apiResponse = res;
                    this.billingCycles = BillingStatementCycle.remapTransactionsFromBe(res?.data?.transactions || []);
                    this.$emit('isDataLoadingUpd', false);
                },
                {
                    errorHandler: e => {
                        this.apiResponse = e.response;
                        this.$alert(this.$i18n.t('customerCare.billingStatementsFetchError'));
                        this.$emit('isDataLoadingUpd', false);
                    },
                },
            );
        },
        async onDownloadButtonClicked({ id }) {
            await this.$withProgressBar(
                async () => {
                    const {
                        data: { pdf_link: pdfLink = null },
                    } = await customerCareHTTP.downloadInvoice(this.targetUser.id, this.targetUser.type, id);

                    if (pdfLink) {
                        window.open(pdfLink, '_blank');
                    }
                },
                {
                    errorHandler: error => {
                        if (error.response?.data.code === luaErrors.BILLING_V4.FILE_NOT_FOUND_ON_S3.code) {
                            this.$alert(this.$i18n.t('customerCare.billingStatementDownloadNotFoundInvoice'), {
                                type: ALERT_TYPES.warning,
                            });
                        } else {
                            this.$alert(this.$i18n.t('customerCare.billingStatementDownloadError'));
                            Sentry.captureException(error);
                        }
                    },
                },
            );
        },
        async onGenerateInvoiceButtonClicked({ id }) {
            await this.$withProgressBar(
                async () => {
                    await customerCareHTTP.generateFile(this.targetUser.id, this.targetUser.type, id);

                    this.$alert(this.$i18n.t('customerCare.generateInvoiceSuccessMessage'), {
                        type: ALERT_TYPES.success,
                    });
                },
                {
                    errorHandler: error => {
                        this.$alert(this.$i18n.t('customerCare.generateInvoiceFailedMessage'));
                        Sentry.captureException(error);
                    },
                },
            );
        },
    },
});
