<template>
    <div class="zone-block flexbox-column">
        <div class="input-fields">
            <div class="d-flex justify-content-between">
                <div class="grow col-12">
                    <div class="d-flex row">
                        <div class="col-12">
                            <div class="zone-title">
                                {{ $t('charging.chargingSpecifications.editor.conditionText.subscriberLocation') }}
                            </div>
                        </div>
                    </div>
                    <div>
                        <div
                            :class="{ 'drag-on': isDragOn }"
                            class="upload-container mb-3"
                            @drag="preventAndStop"
                            @dragover="preventAndStop"
                            @dragstart="preventAndStop"
                            @dragend="preventAndStop"
                            @dragenter="[addDragOnClass(), e => preventAndStop(e)]"
                            @dragleave="[deleteDragOnClass(), e => preventAndStop(e)]"
                            @drop="onDrop"
                        >
                            <input
                                id="file-dragdrop"
                                ref="nativeFileInput"
                                :accept="acceptType"
                                type="file"
                                class="input-file-dragdrop"
                                @change="onSelect"
                            />
                            <div class="w-100 d-flex flex-row align-items-center">
                                <img
                                    class="upload-img"
                                    src="@/assets/icons/file-upload.svg"
                                />
                                <div class="upload-text-wrapper">
                                    <div
                                        class="upload-text d-flex justify-content-center"
                                        :class="{ 'drag-on': isDragOn }"
                                    >
                                        {{ $t('partials.fileUploader.dragAndDropText') }}
                                        <label
                                            for="file-dragdrop"
                                            class="upload-link ml-1"
                                            :class="{ 'drag-on': isDragOn }"
                                        >
                                            {{ $t('partials.fileUploader.browseText') }}
                                        </label>
                                        <AppTooltip class="ml-1">
                                            <template #label>
                                                <div class="info-icon" />
                                            </template>
                                            <template #content>
                                                {{ $t('charging.chargingSpecifications.subLocationFileInfo') }}
                                                <ul class="list-basic">
                                                    <li>
                                                        {{ $t('charging.chargingSpecifications.subLocFileHeader') }}
                                                    </li>
                                                    <li>
                                                        {{ $t('charging.chargingSpecifications.subLocFileInfoEx1') }}
                                                    </li>
                                                    <li>
                                                        {{ $t('charging.chargingSpecifications.subLocFileInfoEx2') }}
                                                    </li>
                                                </ul>
                                            </template>
                                        </AppTooltip>
                                    </div>
                                    <div
                                        class="text-center upload-subtext"
                                        :class="{ 'drag-on': isDragOn }"
                                    >
                                        {{ $t('partials.fileUploader.youCanUpload') + acceptType }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex row">
                        <div class="col-6">
                            <AppMultiselectV3
                                v-model="subscriberLocation.inverted"
                                :allowEmpty="false"
                                :options="options"
                                :searchable="false"
                                :showLabels="false"
                                :borderNone="true"
                                :blueArrow="true"
                                data-test-id="inverted-multiselect"
                                class="multiselect small condition-options"
                                label="label"
                                trackBy="id"
                            />
                        </div>
                        <div class="col-6">
                            <AppMultiselectV3
                                v-model="subscriberLocation.zoneCodeType"
                                :allowEmpty="false"
                                :options="ZONE_CODE_TYPES_OPTIONS"
                                :searchable="false"
                                :showLabels="false"
                                :borderNone="true"
                                :blueArrow="true"
                                :widthStyleObject="{ width: '9rem' }"
                                data-test-id="zone-code-type-multiselect"
                                class="multiselect small condition-options"
                                label="label"
                                trackBy="id"
                            />
                        </div>
                    </div>
                    <div>
                        <div
                            v-for="(data, index) in subscriberLocation.sources"
                            :key="index"
                            class="flex-container"
                            :data-test-id="`subscriber-location-condition-row-${index}`"
                        >
                            <div class="input-block">
                                <SubscriberLocationConditionRow
                                    :value="data"
                                    :keyLabel="keyLabel"
                                    :valuesLabel="valuesLabel"
                                />
                            </div>
                            <img
                                v-if="subscriberLocation.sources.length > 1"
                                src="~@/assets/icons/delete-trash.svg"
                                alt="delete-button"
                                class="delete-btn col-1"
                                :data-test-id="`delete-threshold-${index}`"
                                @click="deleteSlot(index)"
                            />
                        </div>
                        <AppButton
                            :label="$t('generic.add')"
                            :isSmall="true"
                            :iconType="ICON_TYPES.PLUS"
                            :iconColor="ICON_COLORS.BLUE"
                            class="mt-4"
                            data-test-id="btn-add-slot"
                            @click="addSlot"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// libs
import i18n from '@/i18n';

// Components
import AppMultiselectV3 from '@/components/partials/inputs/AppMultiselectV3.vue';
import AppButton from '@/components/partials/inputs/AppButton.vue';
import AppTooltip from '@/components/partials/AppTooltip.vue';
import { TOOLTIP_POSITION } from '@/common/tooltip';
import SubscriberLocationConditionRow from '@/__new__/features/charging/conditionParameters/conditions/SubscriberLocationConditionRow.vue';

// Helpers
import {
    CONDITION_TO_INVERTED_MAP,
    IS_IS_NOT_OPTIONS,
    BOOLEAN_TO_CONDITION,
} from '@/__new__/services/dno/charging/common/conditionsHelper';
import SubscriberLocationConditionModel from '@/__new__/services/dno/charging/models/chargingSpecificationConditions/SubscriberLocationConditionModel';
import { ALERT_TYPES } from '@/common/alerts/Alert';
import { ICON_TYPES, ICON_COLORS } from '@/common/iconHelper';
import isEmpty from 'lodash/isEmpty';

export const ZONE_CODE_TYPES = {
    CC_NDC: 'CC_NDC',
    MCC_MNC: 'MCC_MNC',
};

export const ZONE_CODE_TYPES_OPTIONS = [
    {
        id: ZONE_CODE_TYPES.CC_NDC,
        label: i18n.t('charging.chargingSpecifications.editor.conditions.subscriberLocation.CC_NDC'),
    },
    {
        id: ZONE_CODE_TYPES.MCC_MNC,
        label: i18n.t('charging.chargingSpecifications.editor.conditions.subscriberLocation.MCC_MNC'),
    },
];

export const ZONE_TYPE_TO_ENUM = {
    [ZONE_CODE_TYPES.MCC_MNC]: 1,
    [ZONE_CODE_TYPES.CC_NDC]: 2,
};

export const ENUM_TO_ZONE_TYPE = {
    1: ZONE_CODE_TYPES.MCC_MNC,
    2: ZONE_CODE_TYPES.CC_NDC,
};

export default {
    name: 'SubscriberLocationCondition',
    components: {
        AppMultiselectV3,
        AppButton,
        AppTooltip,
        SubscriberLocationConditionRow,
    },
    props: {
        value: {
            type: Object,
            default: () => undefined,
        },
    },
    data() {
        return {
            ICON_TYPES,
            ICON_COLORS,
            ZONE_CODE_TYPES_OPTIONS,
            TOOLTIP_POSITION,
            subscriberLocation: {
                sources: [
                    {
                        key: [],
                        values: [],
                    },
                ],
                inverted: IS_IS_NOT_OPTIONS[0],
                zoneCodeType: ZONE_CODE_TYPES_OPTIONS[0],
            },
            subscriberLocationFormatted: new SubscriberLocationConditionModel(this.value),
            options: IS_IS_NOT_OPTIONS,
            isDragOn: false,
            acceptType: '.csv',
        };
    },
    computed: {
        isCCNDCSelected() {
            return (
                this.subscriberLocation?.zoneCodeType?.id &&
                this.subscriberLocation.zoneCodeType.id === ZONE_CODE_TYPES.CC_NDC
            );
        },
        keyLabel() {
            return this.isCCNDCSelected
                ? this.$i18n.t('charging.chargingSpecifications.editor.conditions.subscriberLocation.CC')
                : this.$i18n.t('charging.chargingSpecifications.editor.conditions.subscriberLocation.MCC');
        },
        valuesLabel() {
            return this.isCCNDCSelected
                ? this.$i18n.tc('charging.chargingSpecifications.editor.conditions.subscriberLocation.NDC', 2)
                : this.$i18n.tc('charging.chargingSpecifications.editor.conditions.subscriberLocation.MNC', 2);
        },
    },
    watch: {
        value: {
            handler(val) {
                if (val !== this.subscriberLocationFormatted) {
                    this.subscriberLocationFormatted = new SubscriberLocationConditionModel(this.value);
                    this.createSubscriberLocationObj();
                }
            },
        },
        subscriberLocation: {
            handler(newVal) {
                let zoneCodeType;
                let inverted;
                const src = {};

                if (newVal?.inverted?.id) {
                    inverted = CONDITION_TO_INVERTED_MAP.get(newVal.inverted.id);
                }
                if (newVal?.zoneCodeType?.id) {
                    zoneCodeType = ZONE_TYPE_TO_ENUM[newVal.zoneCodeType.id];
                }
                if (newVal?.sources.length && newVal?.sources[0].key) {
                    newVal.sources.forEach(source => {
                        src[source.key] = source.values.map(val => val.toString());
                    });
                }

                this.subscriberLocationFormatted.data = {
                    zoneCodeType,
                    inverted,
                    src,
                };

                this.subscriberLocationFormatted.dataEmpty = false;
                this.emitData();
            },
            deep: true,
        },
    },
    created() {
        this.createSubscriberLocationObj();
    },
    methods: {
        emitData() {
            this.subscriberLocationFormatted.isDataValid();
            this.$emit('input', this.subscriberLocationFormatted);
        },
        addSlot({ key = [], values = [] }) {
            this.subscriberLocation.sources.push({
                key,
                values,
            });
        },
        deleteSlot(index) {
            this.subscriberLocation.sources.splice(index, 1);
        },
        addDragOnClass() {
            if (!this.isDragOn) {
                this.isDragOn = true;
            }
        },
        deleteDragOnClass() {
            if (this.isDragOn) {
                this.isDragOn = false;
            }
        },
        isAppropriateFileType(name) {
            const types = this.acceptType
                .trim()
                .split(',')
                .map(extension => extension.trim());
            return types.some(acceptedType => name.endsWith(acceptedType));
        },
        preventAndStop(e) {
            e.preventDefault();
            e.stopPropagation();
        },
        onSelect(e) {
            const files = e.target.files || e.dataTransfer.files;
            this.addFilesToList(files);
        },
        onDrop(e) {
            this.preventAndStop(e);
            this.deleteDragOnClass();

            const appropriateFileType = !Object.values(e.dataTransfer.files).some(
                ({ name }) => !this.isAppropriateFileType(name),
            );

            if (appropriateFileType) {
                this.addFilesToList(e.dataTransfer.files);
            } else {
                this.$eventBus.$emit('showAlert', {
                    message: `${this.$i18n.t('partials.fileUploader.invalidFileTypeText')} ${this.$i18n.t(
                        'partials.fileUploader.youCanUpload',
                    )} ${this.acceptType}.`,
                    type: ALERT_TYPES.warning,
                });
            }
        },
        addFilesToList(files) {
            if (!files.length) {
                return;
            }

            this.$Progress.start();
            const reader = new FileReader();
            reader.readAsText(files[0]);
            reader.onload = res => {
                const fileData = res.target.result;
                const csvData = this.csvToArray(fileData);
                this.addCsvData(csvData);
            };
            reader.onerror = err => {
                this.$Progress.fail();
                this.$eventBus.$emit('showAlert', {
                    message: err,
                    type: ALERT_TYPES.warning,
                });
            };
        },
        csvToArray(str) {
            const rows = str.slice(str.indexOf('\n') + 1).split('\n');
            let dataInvalid = false;
            const csvData = [];

            rows.forEach((el, index) => {
                if (el !== '') {
                    // array starts with 0 and first row are headers
                    const lineNumber = index + 2;

                    if (!el.includes(',')) {
                        this.$eventBus.$emit('showAlert', {
                            message: this.$i18n.t('charging.conditionParameters.fileNotValid', {
                                line: lineNumber,
                            }),
                            type: ALERT_TYPES.warning,
                        });
                        dataInvalid = true;
                        this.$Progress.fail();
                        return;
                    }

                    const [elKey, elValues] = el.split(',');
                    const elValuesArr = elValues !== '' ? elValues.split(' ').filter(data => Boolean(data)) : [];

                    if (elValuesArr.length && elValuesArr.every(value => !/^\d+$/.test(value.trim()))) {
                        this.$eventBus.$emit('showAlert', {
                            message: this.$i18n.t('charging.conditionParameters.fileValueNotValid', {
                                line: lineNumber,
                            }),
                            type: ALERT_TYPES.warning,
                        });
                        dataInvalid = true;
                        this.$Progress.fail();
                    } else {
                        csvData.push({
                            key: elKey.trim(),
                            values: elValuesArr,
                        });
                    }
                }
            });

            return dataInvalid ? [] : csvData;
        },
        addCsvData(dataArr) {
            dataArr.forEach(({ key, values }) => {
                if (key) {
                    const findIndex = this.subscriberLocation.sources.findIndex(el => el.key.includes(key));
                    const { key: firstKey, values: firstVal } = this.subscriberLocation.sources[0];

                    if (findIndex > -1) {
                        this.addToRow(findIndex, {
                            values,
                        });
                    } else if (isEmpty(firstKey) && isEmpty(firstVal)) {
                        // if first row is empty add to first row else add to array
                        this.addToRow(0, {
                            key: [key],
                            values,
                        });
                    } else {
                        this.addSlot({
                            key: [key],
                            values,
                        });
                    }
                }
            });
            this.$Progress.finish();
        },
        addToRow(rowIndex, { key = [], values = [] }) {
            const { key: rowKey, values: rowValues } = this.subscriberLocation.sources[rowIndex];
            this.subscriberLocation.sources[rowIndex] = {
                key: [...new Set([...rowKey, ...key])],
                values: [...new Set([...rowValues, ...values])],
            };
        },
        createSubscriberLocationObj() {
            if (!isEmpty(this.value?.data)) {
                this.subscriberLocation.inverted = IS_IS_NOT_OPTIONS.find(
                    option => option.id === BOOLEAN_TO_CONDITION.get(this.value.data.inverted),
                );

                this.subscriberLocation.zoneCodeType = ZONE_CODE_TYPES_OPTIONS.find(
                    option => option.id === ENUM_TO_ZONE_TYPE[this.value.data.zoneCodeType],
                );

                this.subscriberLocation.sources = Object.entries(this.value.data.src).map(([key, values]) => ({
                    key: [key],
                    values,
                }));
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/palette';
@import '~@/assets/scss/z-indexes';

.input-file-dragdrop {
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: $hide-smth-z-index;
}

.upload-container {
    border-radius: 0.5rem;
    border: dashed 0.0625rem rgba($gray5, 0.5);
    padding: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 11rem;

    &.drag-on {
        background: $blue5;
        opacity: unset;
        border-color: $blue;
    }
}

.upload-img {
    align-self: center;
    width: 3.5rem;
    margin-left: 2rem;
}

.upload-text-wrapper {
    width: calc(100% - 9.5rem);
}

.upload-text {
    font-size: 0.875rem;
    font-weight: 600;
    color: rgba($gray90, 0.5);
    margin-bottom: 0.25rem;

    &.drag-on {
        color: $gray90;
    }
}

.list-basic {
    list-style: none;
}

.upload-link {
    color: rgba($blue, 0.5);
    cursor: pointer;

    &:hover {
        text-decoration: underline;
    }

    &.drag-on {
        color: $blue;
    }
}

.upload-subtext {
    color: rgba($gray60, 0.5);
    font-size: 0.75rem;

    &.drag-on {
        color: $gray60;
    }
}

.grow {
    flex-grow: 1;
}

.zone-title {
    font-size: 0.75rem;
    font-weight: 600;
    line-height: 2.17;
    color: $gray90;
    margin-bottom: 0.25rem;
}

.condition-options {
    min-width: 80px;
    width: max-content;
}

.flex-container {
    display: flex;
    flex-direction: row;

    &:hover {
        .delete-btn {
            opacity: 1;
        }
    }
}

.input-block {
    position: relative;
    width: 90%;
}

.delete-btn {
    cursor: pointer;
    top: 2rem;
    height: 1rem;
    transform: translateY(-50%);
    opacity: 0;
}
</style>
