<template>
    <label
        :class="[{ small, disabled, 'fit-content': fitContent }]"
        class="toggle-wrapper"
    >
        <span
            v-if="label"
            class="toggle-label"
            :class="{ 'with-margin': !explanationText }"
        >
            {{ label }}
        </span>
        <AppTooltip
            v-if="explanationText"
            :offset="tooltipOffset"
            :tooltipPosition="tooltipPosition"
            class="ml-2 mr-3"
        >
            <template #label>
                <div class="info-icon" />
            </template>

            <template #content>
                <div class="tooltip-info">{{ explanationText }}</div>
            </template>
        </AppTooltip>
    </label>
</template>
<script>
import AppTooltip from '@/components/partials/AppTooltip.vue';
import { TOOLTIP_POSITION } from '@/common/tooltip';

export default {
    name: 'AppTooltipInline',
    components: {
        AppTooltip,
    },
    props: {
        small: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        label: {
            type: String,
            default: '',
        },
        tooltipOffset: {
            type: Number,
            default: 50,
        },
        tooltipPosition: {
            type: String,
            default: TOOLTIP_POSITION.top,
            validator: str => !!TOOLTIP_POSITION[str],
        },
        explanationText: {
            type: String,
            default: '',
        },
        fitContent: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            TOOLTIP_POSITION,
        };
    },
};
</script>
<style lang="scss" scoped>
@import '~@/assets/scss/_consistency.scss';
@import '~@/assets/scss/layout';

.toggle-wrapper {
    display: flex;
    align-items: center;
    font-size: 1rem;
    line-height: 1.5rem;
}

.toggle-wrapper .toggle-label.with-margin {
    margin-right: $spacing-l;
}

.toggle-wrapper.fit-content {
    width: fit-content;
}

.toggle-wrapper.small .toggle-label {
    cursor: pointer;
    color: $gray-800;
    font-size: 12px;
    font-weight: 600;
}

.toggle-wrapper.small .toggle-label.with-margin {
    margin-right: $spacing-m;
}

.toggle-wrapper .tooltip-info {
    white-space: break-spaces;
    font-size: 0.75rem;
    font-weight: normal;
    text-transform: none;
    line-height: normal;
    color: $gray-800;
    min-width: 10rem;
}
</style>
