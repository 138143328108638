<template>
    <AbstractSubSidebarPageWrapper :pageTitle="$i18n.t('settings.settings')">
        <template slot="subSidebar">
            <SubSidebar
                v-model="selectedRouteName"
                :sections="permissionFilteredSections"
                class="sidebar"
                @change="changeActiveSection"
            />
        </template>
        <template slot="content">
            <div class="px-4 remote-config-content">
                <h3 class="lf-title mt-4 mb-4">
                    {{ $i18n.t('settings.remoteConfiguration') }}
                </h3>
                <p class="mb-3">
                    {{ $t('settings.remoteConfig.description') }}
                </p>
                <AppButton
                    :label="'New Configuration'"
                    :buttonType="BUTTON_TYPES.PRIMARY"
                    :iconType="ICON_TYPES.PLUS"
                    @click="onNewConfiguration"
                />

                <!-- List of current configs -->
                <h5 class="mt-5 mb-2 font-weight-bold">
                    {{ $t('settings.remoteConfig.currentConfiguration') }}
                </h5>
                <div class="table-wrap">
                    <AppTable
                        :entities="remoteConfigurations"
                        :columnsData="tableColumnConfig"
                        :entityType="ENTITY_TYPES.REMOTE_CONFIG"
                        :isDataLoading="loadingData"
                        :canSelectColumns="true"
                        :isSearchEnabled="true"
                        tableKey="remoteConfig"
                        :selectedEntityId="selectedEntityId"
                    >
                        <template #customRowButtons="{ entity }">
                            <IconButton
                                :label="$i18n.t('generic.edit')"
                                :icon="ICON_TYPES.EDIT"
                                class="mr-2"
                                @iconClick="onEditButtonClicked(entity)"
                            />
                            <IconButton
                                :label="$i18n.t('generic.delete')"
                                :icon="ICON_TYPES.DELETE"
                                class="mr-2"
                                @iconClick="onDeleteButtonClicked(entity)"
                            />
                        </template>
                    </AppTable>
                </div>
                <!-- Using v-if to destroy and recreate the component between changes of entity -->
                <RemoteConfigEditModal
                    v-if="configModelEditorVisible"
                    :visible="configModelEditorVisible"
                    :configData="selectedConfiguration"
                    @close="closeEditModal"
                    @update="fetchData"
                />
            </div>
        </template>
    </AbstractSubSidebarPageWrapper>
</template>

<script>
// Vue components
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import AppTable from '@/components/partials/AppTable.vue';
import IconButton from '@/components/partials/IconButton.vue';
import RemoteConfigEditModal from '@/__new__/features/settings/remoteConfig/RemoteConfigEditModal.vue';
import Button from '@/common/button/Button';
import AbstractSubSidebarPageWrapper from '@/components/layout/AbstractSubSidebarPageWrapper.vue';
import SubSidebarMixin from '@/components/partials/SubSidebarMixin.vue';
import SettingsSubSidebarMixin from '@/__new__/features/settings/SettingsSubSidebarMixin.vue';
import SubSidebar from '@/components/layout/SubSidebar.vue';

// helpers
import tableColumnType from '@/common/filterTable';
import { VISIBILITY_OPTIONS } from './remoteConfigHelper';
import * as Sentry from '@sentry/vue';
import supportButtonMixin from '@/components/alerts/supportButtonMixin';
import { ALERT_TYPES } from '@/common/alerts/Alert';
import { ICON_TYPES } from '@/common/iconHelper';

// TODO: Move entity types from PC into more generic place
import ENTITY_TYPES from '@/common/entities/entityTypes';

// HTTP
import remoteConfigHttp from '@/__new__/services/dno/remoteConfig/http/remoteConfig';

export default {
    name: 'RemoteConfig',

    components: {
        AppButton,
        AppTable,
        IconButton,
        RemoteConfigEditModal,
        SubSidebar,
        AbstractSubSidebarPageWrapper,
    },

    mixins: [SubSidebarMixin, SettingsSubSidebarMixin, supportButtonMixin],

    data() {
        return {
            // Proxy imports
            ICON_TYPES,
            BUTTON_TYPES,
            ENTITY_TYPES,
            VISIBILITY_OPTIONS,
            alertButtons: {
                deleteButton: new Button({
                    label: this.$i18n.t('generic.delete'),
                    alertType: ALERT_TYPES.warning,
                }),
            },
            // end of proxy imports

            // State
            configModelEditorVisible: false,
            loadingData: false,
            selectedConfiguration: {
                key: '',
                value: '',
                visibility: VISIBILITY_OPTIONS.PRIVATE,
            },

            remoteConfigurations: [],

            tableColumnConfig: [
                {
                    name: 'key',
                    key: 'key',
                    field: 'key',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: 'value',
                    key: 'value',
                    field: 'value',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: 'visibility',
                    key: 'visibility',
                    field: 'visibility',
                    formatter: this.visibilityMapper,
                    filterType: tableColumnType.GENERAL_TEXT,
                },
            ],
        };
    },

    computed: {
        selectedEntityId() {
            return this.selectedConfiguration ? this.selectedConfiguration.id : null;
        },
    },

    created() {
        // Retrieve the remote config values from backend
        this.fetchData();
    },

    methods: {
        onEditButtonClicked(config) {
            // Triggering reactivity due to the fact that objects are not automatically triggering prop updates
            this.$set(this.selectedConfiguration, 'key', config.key);
            this.$set(this.selectedConfiguration, 'value', config.value);
            this.$set(this.selectedConfiguration, 'visibility', this.visibilityLabelToFullConfig(config.visibility));

            if (config.validationSchema) {
                this.$set(this.selectedConfiguration, 'validationInput', config.validationSchema);
            }

            // Toggle modal
            this.configModelEditorVisible = true;
        },
        onDeleteButtonClicked(config) {
            this.$eventBus.$emit('showAlert', {
                message: this.$i18n.t('alerts.areYouSure'),
                type: this.$ALERT_TYPES.warning,
                buttons: [this.alertButtons.deleteButton],
            });
            this.$eventBus.$once('buttonClicked', async buttonId => {
                if (buttonId === this.alertButtons.deleteButton.id) {
                    this.$Progress.start();
                    try {
                        await remoteConfigHttp.updateItemRequest({
                            key: config.key,
                            value: config.value,
                            visibility: VISIBILITY_OPTIONS.DELETED.value,
                        });

                        this.$showSuccessAlert({
                            message: this.$i18n.t('settings.remoteConfig.successDeleteRC'),
                            type: this.$ALERT_TYPES.success,
                        });
                        this.fetchData();
                    } catch (error) {
                        Sentry.captureException(error);
                        this.$Progress.fail();
                        this.showSupportAlert(this.$i18n.t('alertMessage.reports.errorMessage'), ALERT_TYPES.error);
                    } finally {
                        this.$Progress.finish();
                    }
                }
            });
        },
        onNewConfiguration() {
            // Reset modal editor state props
            this.selectedConfiguration = {
                key: '',
                value: '',
                visibility: VISIBILITY_OPTIONS.PRIVATE,
            };
            // Toggle modal
            this.configModelEditorVisible = true;
        },
        closeEditModal() {
            this.configModelEditorVisible = false;
        },
        visibilityMapper(visibility) {
            return Object.values(VISIBILITY_OPTIONS).find(item => visibility === item.value)?.label ?? 'Undefined';
        },
        async fetchData() {
            try {
                this.$Progress.start();
                this.loadingData = true;

                const response = await remoteConfigHttp.getRemoteConfig();

                this.remoteConfigurations = Object.entries(response.data.data)
                    .map(([key, { value, visibility, validation_schema: validationSchema = '' }]) => ({
                        key,
                        value,
                        visibility,
                        validationSchema,
                    }))
                    .filter(rc => rc.visibility !== VISIBILITY_OPTIONS.DELETED.value);

                this.$Progress.finish();
            } catch (error) {
                Sentry.captureException(error);
                this.$Progress.fail();
                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('alertMessage.failedToLoadNecessaryData'),
                });
            } finally {
                this.loadingData = false;
            }
        },
        visibilityLabelToFullConfig(visibilityLabel) {
            // Defaulting to private in case no visibility state is found
            return (
                Object.values(VISIBILITY_OPTIONS).find(item => visibilityLabel === item.label) ??
                VISIBILITY_OPTIONS.PRIVATE
            );
        },
    },
};
</script>

<style lang="scss" scoped>
.remote-config-content {
    height: 100%;

    .table-wrap {
        max-width: 65vw;
        height: 65%;
    }
}
</style>
