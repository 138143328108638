var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
        content: _vm.unavailable ? '' : _vm.tooltipText,
        classes: ['dark'],
        placement: 'bottom',
        trigger: 'hover click',
        autoHide: false,
    }),expression:"{\n        content: unavailable ? '' : tooltipText,\n        classes: ['dark'],\n        placement: 'bottom',\n        trigger: 'hover click',\n        autoHide: false,\n    }"}],staticClass:"app-progress-bar",class:{ 'with-title': _vm.isTitleSlotPassed }},[_c('div',{staticClass:"bar"},[_c('div',{staticClass:"value",class:{ unavailable: _vm.unavailable || _vm.widthInPercent === 0 },style:({ width: `${_vm.widthInPercent}%`, background: _vm.color })})]),_c('div',{staticClass:"info d-flex"},[_c('div',{staticClass:"title"},[_vm._t("title")],2),(_vm.unavailable)?_c('div',{staticClass:"counter"},[_vm._v(" N/A ")]):(_vm.counter)?_c('div',{staticClass:"counter"},[_vm._v(" "+_vm._s(_vm.counter)+" ")]):_c('div',{staticClass:"counter"},[_vm._v(" "+_vm._s(_vm.result)+" ")])])])
}
var staticRenderFns = []

export { render, staticRenderFns }