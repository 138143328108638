
import Vue from 'vue';

// Vuex
import Actions from '@/store/mutation-types';
import { mapGetters, mapActions } from 'vuex';

// Components
import { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import AppInputV3 from '@/components/partials/inputs/AppInputV3.vue';

// http
import ossmvneHTTP from '@/__new__/services/dno/ossmvne/http/ossmvne';

// Helpers
import { maxLength, minLength, numeric, required } from 'vuelidate/lib/validators';
import { USER_MANAGER_HIERARCHY } from '@/__new__/features/customerCare/common/customerCareHelper';
import { ALERT_TYPES } from '@/common/alerts/Alert';
import Button from '@/common/button/Button';
import * as Sentry from '@sentry/vue';
import Subscriber from '@/__new__/services/dno/user/models/Subscriber';
import { TranslateResult } from 'vue-i18n';

// http
import HTTPCustomerCare from '@/__new__/services/dno/user/http/customer-care';

export default Vue.extend({
    name: 'ChangeMSISDN',
    components: {
        AppInputV3,
    },
    props: {
        targetId: {
            required: true,
            type: String,
        },
    },
    data() {
        return {
            BUTTON_TYPES,
            iccid: '' as string,
            marketZip: '' as string,
            subscriber: new Subscriber({}),
        };
    },
    computed: {
        ...mapGetters('userManagementSubscriber', ['getSubscriberInfoById']),
        isMarketZipInvalid(): boolean {
            return this.$v?.marketZip?.$error;
        },
        formTitle(): TranslateResult {
            return this.$t('customerCare.subscriberTab.changeMSISDN');
        },
        subscriberInfo() {
            return this.getSubscriberInfoById(this.targetId) || {};
        },
        msisdn(): string {
            return this.subscriber?.msisdn || '';
        },
    },
    validations() {
        return {
            marketZip: {
                required,
                numeric,
                minLength: minLength(5),
                maxLength: maxLength(5),
            },
        };
    },
    async created() {
        await Promise.all([this.getSubscriberInfo(this.targetId), this.fetchAndSetICCID()]);
        this.setSubscriber();
    },
    methods: {
        ...mapActions('customerCare', [Actions.SET_CURRENT_SUBSCRIBER_ID]),
        ...mapActions('userManagementSubscriber', ['getSubscriberInfo']),
        async onSubmit(): Promise<void> {
            this.$v.$touch();

            if (this.$v.$invalid) {
                this.$emit('isSensitiveOperationCompleted', false);
                return;
            }

            await this.$withProgressBar(
                async () => {
                    const result = await ossmvneHTTP.referrenceNGPInquiry(this.marketZip);

                    if (result?.data?.ngpInfo?.length > 0) {
                        const button = new Button({
                            label: this.$i18n.t('generic.confirm'),
                            alertType: ALERT_TYPES.warning,
                            handler: async () => {
                                await this.changeMSISDN();
                            },
                        });
                        this.$alert(this.$i18n.t('customerCare.subscriberTab.confirmChangeMSISDN'), {
                            type: ALERT_TYPES.warning,
                            buttons: [button],
                        });
                    } else {
                        this.$alert(this.$i18n.t('customerCare.subscriberTab.failedToFindNGP'), {
                            type: ALERT_TYPES.error,
                        });
                        this.$emit('isSensitiveOperationCompleted', false);
                    }
                },
                {
                    errorHandler: () => {
                        this.$alert(this.$i18n.t('customerCare.subscriberTab.failedToFindNGP'), {
                            type: ALERT_TYPES.error,
                        });
                        this.$emit('isSensitiveOperationCompleted', false);
                    },
                },
            );
        },
        async changeMSISDN(): Promise<void> {
            await ossmvneHTTP.changeMSISDN(
                this.targetId,
                USER_MANAGER_HIERARCHY.SUBSCRIBER,
                this.marketZip,
                this.msisdn,
                this.iccid,
            );
            this.$alert(this.$i18n.t('customerCare.subscriberTab.successfullyInitiatedMSISDNChange'), {
                type: ALERT_TYPES.success,
            });
            this.$emit('isSensitiveOperationCompleted', true);
        },
        async fetchAndSetICCID(): Promise<void> {
            try {
                this.$Progress.start();
                const {
                    data: { profile_data: profileData },
                } = await HTTPCustomerCare.getProfileInfo({
                    targetId: this.targetId,
                    targetType: USER_MANAGER_HIERARCHY.SUBSCRIBER,
                });
                this.iccid = profileData?.iccid;
                this.$Progress.finish();
            } catch (e) {
                this.$Progress.fail();
                this.showSupportAlert(
                    this.$i18n.t('alertMessage.somethingWentWrongFetchingNecessaryData'),
                    ALERT_TYPES.error,
                );
                Sentry.captureException(e);
            }
        },
        setSubscriber(): void {
            this.subscriber = new Subscriber(Subscriber.remapUserFromBe(this.subscriberInfo));
            this.marketZip = this.subscriber?.marketZip || '';
        },
    },
});
