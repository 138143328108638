const mapExpressionObjects = arr =>
    arr.map(({ label, conditionUUID }) => ({
        key: +label.replace(/[^0-9.]/g, ''),
        conditionUUID,
    }));

export default function sortPrStatusesByExpression(expressions) {
    const exprValues = expressions
        .reduce((data, current) => {
            let value = [];
            if (current.multipleRowsExpression) {
                value = current.value.reduce(
                    (multiData, expression) => [...new Set([...multiData, ...mapExpressionObjects(expression.value)])],
                    [],
                );
            } else {
                value = mapExpressionObjects(current.value);
            }

            return [...new Set([...data, ...value])];
        }, [])
        .sort((a, b) => a.key - b.key)
        .map(el => el.conditionUUID);

    return [...new Set(exprValues)];
}
