<template>
    <div>
        <table class="table-content">
            <tr class="entity-row text--sm no-gutters align-items-center row w-100 position-relative default-hover">
                <th
                    v-for="header in headerOptions"
                    :key="header"
                    class="table-cell truncate-text-all pl-4 lf-table-title"
                >
                    {{ header }}
                </th>
            </tr>
            <tr
                v-for="(option, index) in updatedOptionsArray"
                :key="index"
                class="entity-row text--sm no-gutters align-items-center row w-100 position-relative default-hover"
            >
                <td class="table-cell truncate-text-all pl-4 lf-table-title">
                    <input
                        :id="`min-field-${index}`"
                        v-model="updatedOptionsArray[index].range_start"
                        :placeholder="$i18n.t('generic.min')"
                        :disabled="disabled"
                        data-test-id="range-start-test"
                        @input="onChangeMinValue(index)"
                    />
                </td>
                <td class="table-cell truncate-text-all pl-4 lf-table-title">
                    <input
                        :id="`max-field-${index}`"
                        v-model="updatedOptionsArray[index].max"
                        :placeholder="$i18n.t('generic.max')"
                        disabled="true"
                        data-test-id="max-field-test"
                    />
                </td>
                <td class="table-cell truncate-text-all pl-4 lf-table-title last-td-flex">
                    <input
                        :id="`amount-field-${index}`"
                        v-model="updatedOptionsArray[index].amount"
                        data-test-id="amount-field-test"
                        :step="step"
                        :class="{ 'amount-error': isInvalidAmount(index) }"
                        :placeholder="$i18n.t('generic.price')"
                        :disabled="disabled"
                    />
                </td>

                <AppIcon
                    v-show="showDeleteButton && !disabled"
                    :id="`delete-button-${index}`"
                    role="button"
                    :type="ICON_TYPES.DELETE"
                    class="delete-button"
                    @click="deleteSlot(index)"
                />
            </tr>
        </table>

        <AppButton
            :label="addButtonLabel"
            :iconType="ICON_TYPES.PLUS"
            :isSmall="true"
            :disabled="disabled"
            class="my-3"
            data-test-id="add-slot"
            @click="addSlot"
        />
    </div>
</template>
<script>
// Components
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import AppIcon from '@/components/partials/icon/AppIcon.vue';

// Helpers
import i18n from '@/i18n';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import { ICON_TYPES } from '@/common/iconHelper';

export default {
    name: 'PriceTable',
    components: {
        AppButton,
        AppIcon,
    },
    props: {
        disabled: {
            type: Boolean,
            default: false,
        },
        headerOptions: {
            type: Array,
            default: () => [],
        },
        optionsArray: {
            type: Array,
            default: () => [],
        },
        widthPriceColumn: {
            type: String,
            default: '50',
        },
        addButtonLabel: {
            type: String,
            default: i18n.t('generic.addNew'),
        },
        enableDeleteButton: {
            type: Boolean,
            default: false,
        },
        indexesOfPriceToShowError: {
            type: Array,
            default: () => [],
        },
        step: {
            type: Number,
            default: 0.01,
        },
    },
    data() {
        return {
            updatedOptionsArray: cloneDeep(this.optionsArray),

            // Proxy imports
            ICON_TYPES,
            BUTTON_TYPES,
        };
    },
    computed: {
        showDeleteButton() {
            return this.updatedOptionsArray.length > 1 && this.enableDeleteButton;
        },
    },
    watch: {
        updatedOptionsArray: {
            immediate: true,
            handler() {
                this.$emit('updateArrayOptions', this.updatedOptionsArray);
            },
            deep: true,
        },
        optionsArray: {
            handler() {
                if (!isEqual(this.updatedOptionsArray, this.optionsArray)) {
                    this.updatedOptionsArray = cloneDeep(this.optionsArray);
                }
            },
        },
    },
    methods: {
        getStyles() {
            // it s -10 because that will be the space for delete button
            const currentWidth = (100 - Number(this.widthPriceColumn) - 10) / (this.headerOptions.length - 1);
            return `width: ${currentWidth}%;`;
        },
        isInvalidAmount(index) {
            return this.indexesOfPriceToShowError.includes(index);
        },
        onChangeMinValue(index) {
            if (this.updatedOptionsArray[index - 1]) {
                this.updatedOptionsArray[index - 1].max = Number(this.updatedOptionsArray[index].range_start) - 1;
            }
        },
        addSlot() {
            const newItem = {};
            Object.keys(this.updatedOptionsArray[0]).forEach(key => {
                newItem[key] = null;
            });
            this.updatedOptionsArray.push(newItem);
        },
        deleteSlot(index) {
            this.updatedOptionsArray.splice(index, 1);
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/palette';
@import '~@/assets/scss/base';
@import '~@/assets/scss/icons';
@import '~@/assets/scss/animations';
@import '~@/assets/scss/typographyV2';
@import '~@/assets/scss/z-indexes';

$icon-path: '~@/assets/icons/';

.table-content {
    width: 60%;

    tr .table-cell {
        width: 70px;

        &:last-of-type {
            width: calc(100% - 250px);
        }
    }

    .entity-row {
        position: relative;
        border-bottom: 1px solid $dirty-white;

        color: $gray90;
        background-color: $white;
        overflow: visible;

        transition-property: background-color;
        transition-duration: 0.25s;

        & > * {
            line-height: 40px;
        }

        input {
            border: none;
            background-color: $white;
        }

        .last-td-flex {
            display: flex;
        }

        .amount-error {
            border: 1px solid red;
            border-radius: 0.5rem;
        }
    }
}

.delete-button {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(-50%, -50%);
}
</style>
