<script>
import { dragscroll } from 'vue-dragscroll';
import { isUndefined } from 'lodash';

export default {
    name: 'StateHistoryMixin',
    directives: {
        dragscroll,
    },
    props: {
        value: {
            type: Array,
            required: true,
        },
        initialScrollRight: {
            type: Boolean,
            required: false,
        },
    },
    data() {
        return {
            timelineWidth: 0,
            arrowOffsets: [],
            leftArrowEnabled: false,
            rightArrowEnabled: this.value.length,
            fadePosition: 0,
            arrowReference: false,
            contentReferenceWidth: false,
        };
    },
    computed: {
        valueFormatted() {
            return this.value && this.value.length ? this.value : [{ state: -1 }];
        },
        linearGradient() {
            if (this.leftArrowEnabled && this.rightArrowEnabled) {
                return 'bothArrowsEnabledGradient';
            }
            if (this.rightArrowEnabled) {
                return 'rightArrowEnabledGradient';
            }
            if (this.leftArrowEnabled) {
                return 'leftArrowEnabledGradient';
            }
            return '';
        },
        showArrows() {
            if (this.arrowReference && this.contentReferenceWidth) {
                const totalLenghtOfStatus = this.arrowReference.reduce(
                    (totalLengh, elem) => totalLengh + elem.clientWidth + 80,
                    0,
                );
                return totalLenghtOfStatus > this.contentReferenceWidth;
            }
            return false;
        },
    },
    watch: {
        value() {
            // wait for updating DOM after updating value
            this.$nextTick(() => {
                if (this?.$refs?.draggable?.scrollWidth) {
                    this.$set(this, 'timelineWidth', this.$refs.draggable.scrollWidth);
                    this.updateArrowPositions();
                }
                // scroll right if needed
                if (this.value.length && this.initialScrollRight && !this.isLastStateVisible()) {
                    this.$refs.draggable.scrollLeft = this.timelineWidth;
                    this.$emit('scrolledRight');
                }
            });
        },
        timelineWidth() {
            this.updateScrollPosition();
        },
    },
    mounted() {
        if (this.$refs.draggable) {
            this.$set(this, 'timelineWidth', this.$refs.draggable.scrollWidth);
            this.$refs.draggable.addEventListener('scroll', this.updateScrollPosition);

            // when page is refreshed, method `updateArrowPositions();` called from this hook
            // is not calculating offsets properly so we need this event listener
            // to trigger function after mounted hook, when leftOffsets in
            // this.$refs.timelineElement[index] are properly calculated
            window.addEventListener('load', () => this.updateArrowPositions);

            window.addEventListener('resize', () => {
                this.contentReferenceWidth = window.innerWidth - 362;
                if (this.$refs.content) {
                    this.$set(this, 'timelineWidth', this.$refs.content.scrollWidth);
                }
            });

            // when page is first loaded, event listener on Load won't be triggered
            // so we need to call method manually to calculate positions
            // first time page is loaded leftOffsets in this.$refs.timelineElement[index]
            // are properly calculated even in this hook
            this.updateArrowPositions();
        }
        if (this.$refs.timelineElement) {
            this.arrowReference = this.$refs.timelineElement;
        }

        if (this.$refs.content.clientWidth) {
            this.contentReferenceWidth = this.$refs.content.clientWidth;
        }
    },
    beforeDestroy() {
        if (this.$refs.draggable) {
            this.$refs.draggable.removeEventListener('scroll', this.updateScrollPosition);
            window.removeEventListener('load', this.updateArrowPositions);
        }
    },
    methods: {
        isUndefined,
        updateScrollPosition() {
            this.fadePosition = this.$refs.draggable.scrollLeft;

            this.rightArrowEnabled =
                this.$refs.draggable.getBoundingClientRect().width + this.$refs.draggable.scrollLeft + 1 <=
                this.$refs.draggable.scrollWidth;
            this.leftArrowEnabled = !!this.$refs.draggable.scrollLeft;
        },
        onLeftArrowClick() {
            this.$refs.draggable.scrollLeft -= this.$refs.draggable.offsetWidth / 2;
        },
        onRightArrowClick() {
            this.$refs.draggable.scrollLeft += this.$refs.draggable.offsetWidth / 2;
        },
        updateArrowPositions() {
            if (this.$refs.timelineElement) {
                this.arrowOffsets = this.$refs.timelineElement.map(
                    (elem, index) => elem.offsetLeft + this.$refs.status[index].$el.children[0].offsetWidth / 2,
                );
            }
        },
        isLastStateVisible() {
            const el = this.$refs.timelineElement[this.$refs.timelineElement.length - 1];
            const { right: elRight } = el.getBoundingClientRect();
            const { right: containerRight } = this.$refs.draggable.getBoundingClientRect();
            return elRight < containerRight;
        },
    },
};
</script>
