/* eslint-disable camelcase */

export class ProductCatalogBulkProgressModel {
    expectedCount;

    eventCategoryCounters;

    entityIdsByCategory;

    status;

    constructor(entityJson) {
        const entityData = entityJson.data;
        // properties from entityJson root
        this.expectedCount = entityData.expected_count ?? 0;
        this.eventCategoryCounters = entityData.event_category_counters;
        this.entityIdsByCategory = entityData.entity_ids_by_category;
        this.status = entityData.status;
    }

    // to parse data for the API call
    static toJson(entityData) {
        const entityDataFormatted = {};

        if (entityData.expectedCount) {
            entityDataFormatted.expected_count = entityData.expectedCount;
        }

        if (entityData.eventCategoryCounters) {
            entityDataFormatted.event_category_counters = entityData.eventCategoryCounters;
        }

        if (entityData.entityIdsByCategory) {
            entityDataFormatted.entity_ids_by_category = entityData.entityIdsByCategory;
        }

        if (entityData.status) {
            entityDataFormatted.status = entityData.status;
        }

        return entityDataFormatted;
    }
}

export default ProductCatalogBulkProgressModel;
