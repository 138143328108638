import store from '@/store/store';
import { Getters } from '@/store/mutation-types';
import { LOGICAL_OPERATORS } from '@/common/segments';
import isEmpty from 'lodash/isEmpty';
import { Modules } from '@/store/store';
import i18n from '@/i18n';

export const CONDITION_OBJECT_TYPE = {
    CONDITION_PARAMETER: 'conditionParameter',
    POLICY_COUNTER: 'policyCounter',
};

/**
 * This function takes array of conditions built by Condition Expression builder and converts
 * it into format accepted by Product Catalog and Condition Parser.
 * @param expressions
 * @returns {*}
 */
export function parseExpressionToTree(expressions) {
    const result = [{ [LOGICAL_OPERATORS.and.toLowerCase()]: [] }];
    expressions
        .reduce((data, current) => {
            let groupExpr;

            if (current.multipleRowsExpression) {
                groupExpr = parseExpressionToTree(current.value);
            } else if (current?.groupRelation) {
                groupExpr = {
                    [current.groupRelation.toLowerCase()]: current.value
                        .filter(val => val.conditionUUID !== null)
                        .map(val => val.conditionUUID),
                };
            } else {
                groupExpr = {
                    [LOGICAL_OPERATORS.and.toLowerCase()]: [current.value[0].conditionUUID],
                };
            }

            current.groupExpr = groupExpr;
            data.push(current);
            return data;
        }, [])
        .forEach(expression => {
            result[result.length - 1][LOGICAL_OPERATORS.and.toLowerCase()].push(expression.groupExpr);
            if (expression.relation === LOGICAL_OPERATORS.or) {
                result.push({ [LOGICAL_OPERATORS.and.toLowerCase()]: [] });
            }
        });

    return result.length
        ? {
              [LOGICAL_OPERATORS.or.toLowerCase()]: result,
          }
        : {};
}

function getGroupRelation(expression) {
    return Object.keys(expression).length === 1
        ? Object.keys(expression)[0]?.toUpperCase() || LOGICAL_OPERATORS.and
        : LOGICAL_OPERATORS.and;
}

function getConditionParameters(data) {
    const ids = Object.values(data)[0];
    return store.getters[`${Modules.charging}/${Getters.GET_CONDITION_PARAMETERS_BY_IDS}`](ids) || {};
}

function getPolicyCounter(data) {
    const ids = Object.values(data)[0];
    return store.getters[`${Modules.chargingV2}/${Getters.GET_POLICY_COUNTER_BY_IDS}`](ids) || {};
}

function getEntityData(data, type) {
    switch (type) {
        case CONDITION_OBJECT_TYPE.CONDITION_PARAMETER:
            return getConditionParameters(data);
        case CONDITION_OBJECT_TYPE.POLICY_COUNTER:
            return getPolicyCounter(data);
        default:
            return [];
    }
}

function mapConditionObj(conditions, type = CONDITION_OBJECT_TYPE.CONDITION_PARAMETER, extraData = null) {
    return conditions?.map(condition => ({
        label: createConditionLabel(condition, type, extraData),
        conditionUUID: condition.id,
        type: condition?.conditionParameter?.type || CONDITION_OBJECT_TYPE.POLICY_COUNTER,
    }));
}

function createConditionLabel(condition, type, extraData = null) {
    switch (type) {
        case CONDITION_OBJECT_TYPE.CONDITION_PARAMETER:
            return condition.name;
        case CONDITION_OBJECT_TYPE.POLICY_COUNTER:
            const [pcObj] = Object.values(extraData).filter(
                ({ policy_counter_id: policyCounterId }) => policyCounterId === condition.id,
            );

            return `${i18n.t('charging.policyCounters.policyCounter')}: ${condition?.name} - ${
                pcObj?.policy_counter_status || ''
            }`;
        default:
            return condition.name;
    }
}

// detect nested OR statement for N conditions in on row
function detectNestedOrStatement(expression) {
    return (
        Object.hasOwnProperty.call(expression, 'or') &&
        Object.values(expression).every(arr =>
            arr.every(el => typeof el === 'object' && !Array.isArray(el) && el !== null),
        )
    );
}

export function parseExpressionsFromBe(
    expressions,
    type = CONDITION_OBJECT_TYPE.CONDITION_PARAMETER,
    extraData = null,
) {
    if (expressions?.length === 0 || isEmpty(expressions) || !Object.hasOwnProperty.call(expressions, 'or')) {
        return [];
    }

    const result = [];
    expressions.or.forEach((expression, i) => {
        expression?.and?.forEach((el, j) => {
            const isLastEL = expression.and.length - 1 === j;
            const relation = isLastEL && expressions.or[i + 1] ? LOGICAL_OPERATORS.or : LOGICAL_OPERATORS.and;

            if (detectNestedOrStatement(el)) {
                const conditionObj = {
                    value: parseExpressionsFromBe(el, type, extraData),
                    multipleRowsExpression: true,
                    relation,
                    isCollapsed: false,
                };

                result.push(conditionObj);
            } else {
                const conditionObj = {
                    value: mapConditionObj(getEntityData(el, type), type, extraData),
                    groupRelation: getGroupRelation(el),
                    relation,
                    multipleRowsExpression: false,
                    isCollapsed: false,
                };

                result.push(conditionObj);
            }
        });
    });

    return result;
}

export function getAllConditionParametersIds(expression, dataParsed = false) {
    const data = dataParsed ? expression : parseExpressionsFromBe(expression);
    return data.reduce((data, current) => {
        const uuids = [];
        current.value.forEach(el =>
            el?.value?.length ? el.value.map(i => uuids.push(i.conditionUUID)) : uuids.push(el.conditionUUID),
        );
        return [...new Set([...data, ...uuids])];
    }, []);
}

export default {};
