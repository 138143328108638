<template>
    <div data-id="fiber-order-status">
        <TableFiltersRenderless :entities="fiberOrderData">
            <template #default="{ filteredEntities }">
                <div>
                    <div class="d-flex justify-content-between align-items-center position-relative">
                        <div class="table-title">
                            {{ $i18n.t('customerCare.userInformation.fiberStatus') }}
                        </div>
                    </div>

                    <AppTable
                        :entities="filteredEntities"
                        :isDefaultHoverEnabled="false"
                        :isPaginationEnabled="true"
                        :canSelectColumns="true"
                        :newDesign="true"
                        :columnsData="columnsData"
                        :isSearchEnabled="true"
                        :innerSearchQuery="searchQuery"
                        tableKey="customer-care-new/associated-roles"
                    >
                        <template #createTimestamp="{ entity }">
                            {{ $localeLibrary.getFormattedDateAndTimeWithSecondsPrecision(entity.createTimestamp) }}
                        </template>

                        <template #expireDate="{ entity }">
                            <div class="d-flex align-items-center">
                                <div>
                                    {{ $localeLibrary.getFormattedDateAndTimeWithSecondsPrecision(entity.expireDate) }}
                                </div>
                                <div class="ml-3">
                                    <AppLabel
                                        v-if="entity.orderId === currentOrderId"
                                        :title="$i18n.t('customerCare.account.fiberStatus.inUse')"
                                        :color="LABEL_COLOR.green"
                                    ></AppLabel>
                                </div>
                            </div>
                        </template>

                        <template #fiberSpeed="{ entity }">
                            {{ `${entity.fiberSpeed}Mbps` }}
                        </template>

                        <template #state="{ entity }">
                            <EntityStatusIndicator
                                v-if="!isUndefined(entity.state)"
                                :stateMap="ORDER_ENTITY_STATES_TO_STATUS_NAME_MAP"
                                :stateMapColor="ORDER_STATUS_INDICATOR_MAP"
                                :status="entity.state"
                                :isInTable="true"
                            />
                            <i v-else>{{ $i18n.t('generic.empty') }}</i>
                        </template>
                    </AppTable>
                </div>
            </template>
        </TableFiltersRenderless>
    </div>
</template>

<script>
// components
import AppTable from '@/components/partials/AppTable.vue';
import TableFiltersRenderless from '@/components/filters/TableFiltersRenderless.vue';
import EntityStatusIndicator from '@/components/partials/EntityStatusIndicator.vue';
import { isUndefined } from 'lodash';
import { USER_MANAGER_HIERARCHY } from '@/__new__/features/customerCare/common/customerCareHelper';
import {
    ORDER_ENTITY_STATES_TO_STATUS_NAME_MAP,
    ORDER_STATUS_INDICATOR_MAP,
} from '@/__new__/features/customerCare/common/orderStateHelper';

import userManagementHTTP from '@/__new__/services/dno/user/http/user-management';
import AppLabel from '@/components/partials/AppLabel.vue';
import { LABEL_COLOR } from '@/common/labelsHelper';

// helpers
import tableColumnType from '@/common/filterTable';

export default {
    name: 'FiberStatus',
    components: {
        AppTable,
        TableFiltersRenderless,
        EntityStatusIndicator,
        AppLabel,
    },
    props: {
        fiberOrders: {
            type: Array,
            default: () => [],
        },
        currentOrderId: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            searchQuery: '',
            ORDER_ENTITY_STATES_TO_STATUS_NAME_MAP,
            ORDER_STATUS_INDICATOR_MAP,
            address: null,
            LABEL_COLOR,
        };
    },
    computed: {
        columnsData() {
            const cols = [
                {
                    name: this.$i18n.t('generic.friendlyOrderId'),
                    key: 'friendlyOrderId',
                    field: 'friendlyOrderId',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('customerCare.account.fiberStatus.orderId'),
                    key: 'orderId',
                    field: 'orderId',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('customerCare.account.fiberStatus.orderDate'),
                    key: 'createTimestamp',
                    field: 'createTimestamp',
                    filterType: tableColumnType.DATETIME,
                },
                {
                    name: this.$i18n.t('customerCare.account.fiberStatus.expireDate'),
                    key: 'expireDate',
                    field: 'expireDate',
                    filterType: tableColumnType.DATETIME,
                },
                {
                    name: this.$i18n.t('customerCare.account.fiberStatus.status'),
                    key: 'state',
                    field: 'state',
                    filterType: tableColumnType.TEXT_LIMITED_OPTIONS,
                },
                {
                    name: this.$i18n.t('customerCare.account.fiberStatus.fiberSpeed'),
                    key: 'fiberSpeed',
                    field: 'fiberSpeed',
                    filterType: tableColumnType.NUMBER_UNITS,
                },
                {
                    name: this.$i18n.t('customerCare.account.fiberStatus.address'),
                    key: 'address',
                    field: 'address',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
            ];
            return cols;
        },
        fiberOrderData() {
            const address = this.address ? this.assembleAddress(this.address) : '';
            return (this.fiberOrders || []).map(order => {
                if (order.isFiberAcquisition) {
                    order.address = address;
                }
                return order;
            });
        },
    },
    watch: {
        fiberOrders(fiberOrders) {
            if (fiberOrders.length && fiberOrders[0]?.resourceId) {
                this.fetchResourceAddress(fiberOrders[0].resourceId);
            }
        },
    },
    methods: {
        isUndefined,
        async fetchResourceAddress(resourceId) {
            const { data } = await userManagementHTTP.getResource({
                target_id: this.$route.params.id,
                target_type: USER_MANAGER_HIERARCHY.ACCOUNT,
                resource_ids: [resourceId],
            });
            if (data?.resources?.length) {
                this.address = data.resources[0].properties?.address;
            }
        },
        assembleAddress(address) {
            let addressArr = [];
            if (address.house_number) {
                addressArr = [
                    address.house_number,
                    address.street,
                    address.village,
                    address.province,
                    address.city,
                    address.barangay,
                    address.zip_code,
                ];
            } else {
                addressArr = [
                    address.floor,
                    address.unit_no,
                    address.building_name,
                    address.street,
                    address.province,
                    address.city,
                    address.barangay,
                    address.zip_code,
                ];
            }
            return addressArr.join(', ');
        },
    },
};
</script>

<style lang="scss" scoped>
.table-title {
    font-size: 1rem;
    font-weight: 600;
    line-height: 2.5;
    height: 2.5rem;
}
</style>
