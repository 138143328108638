import i18n from '@/i18n';

export enum PAYMENT_TRANSACTION_TYPE_CODES {
    ORDER_PAYMENT = 1,
    MICROCHARGING_PAYMENT = 2,
    MANUAL_PAYMENT = 3,
    VALIDITY_CHECK = 4,
    PAYMENT_RETURN = 5,
    TRANSFER_DATA_SEND = 6,
    TRANSFER_DATA_RECEIVE = 7,
}

export function getPaymentTransactionTypeInString(id: PAYMENT_TRANSACTION_TYPE_CODES) {
    switch (id) {
        case PAYMENT_TRANSACTION_TYPE_CODES.ORDER_PAYMENT: {
            return i18n.t('customerCare.paymentStatuses.regular');
        }
        case PAYMENT_TRANSACTION_TYPE_CODES.MICROCHARGING_PAYMENT: {
            return i18n.t('customerCare.paymentStatuses.userBased');
        }
        case PAYMENT_TRANSACTION_TYPE_CODES.MANUAL_PAYMENT: {
            return i18n.t('customerCare.paymentStatuses.manualPayment');
        }
        case PAYMENT_TRANSACTION_TYPE_CODES.VALIDITY_CHECK: {
            return i18n.t('customerCare.paymentStatuses.validityCheck');
        }
        case PAYMENT_TRANSACTION_TYPE_CODES.PAYMENT_RETURN: {
            return i18n.t('customerCare.paymentStatuses.refund');
        }
        default:
            return i18n.t('generic.empty');
    }
}

export enum BILLING_TRX_PAYMENT_TYPES {
    DIRECT_BILL_PAYMENT = 1,
    IMMEDIATE_PAYMENT = 2,
    REFUND_PAYMENT = 3,
}

export const BILLING_TRX_TYPE_TO_LABEL = new Map([
    [BILLING_TRX_PAYMENT_TYPES.DIRECT_BILL_PAYMENT, i18n.t('customerCareSuite.billingTrxNextGenTile.directBill')],
    [BILLING_TRX_PAYMENT_TYPES.IMMEDIATE_PAYMENT, i18n.t('customerCareSuite.billingTrxNextGenTile.immediatePayment')],
    [BILLING_TRX_PAYMENT_TYPES.REFUND_PAYMENT, i18n.t('customerCareSuite.billingTrxNextGenTile.refund')],
]);

export default {
    getPaymentTransactionTypeInString,
    PAYMENT_TRANSACTION_TYPE_CODES,
    BILLING_TRX_PAYMENT_TYPES,
    BILLING_TRX_TYPE_TO_LABEL,
};
