<template>
    <div class="d-flex">
        <div
            :style="{ width: nameWidth }"
            class="field-name body-sm bold"
        >
            {{ name }}
        </div>
        <div
            class="field-value-wrapper"
            :class="{ 'custom-value-content': customValueContent }"
            :style="{ width: `calc(100% - ${nameWidth})` }"
        >
            <slot>
                <p
                    v-tooltip="{
                        enabled: tooltipEnabled,
                        content: tooltipTextFormatted,
                        classes: ['dark'],
                        placement: 'bottom',
                        trigger: 'hover click',
                        autoHide: false,
                    }"
                    :class="{ link: isLink }"
                    class="field-value-text mb-0 body-sm truncate-text"
                    @click="linkClicked"
                >
                    {{ value | displayNaIfNotProvided }}
                </p>
            </slot>
        </div>
    </div>
</template>
<script>
// Components
import CommonFilters from '@/components/partials/CommonFilters.vue';

export default {
    name: 'AppField',
    mixins: [CommonFilters],
    props: {
        name: {
            type: null,
            required: true,
        },
        value: {
            type: null,
            default: '',
        },
        nameWidth: {
            type: String,
            default: '140px',
        },
        tooltipText: {
            type: String,
            default: '',
        },
        showValueAsTooltip: {
            type: Boolean,
            default: false,
        },
        isLink: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {};
    },
    computed: {
        tooltipTextFormatted() {
            return this.tooltipText || this.value;
        },
        tooltipEnabled() {
            return this.showValueAsTooltip || this.tooltipText.length > 0;
        },
        customValueContent() {
            return Boolean(this.$slots.default);
        },
    },
    methods: {
        linkClicked() {
            if (this.isLink) {
                this.$emit('linkClicked');
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/consistency-typography';
@import '~@/assets/scss/mixins';

.field-wrapper {
    height: 1.5rem;
    line-height: 1.5rem;
}

.custom-value-content {
    display: flex;
    justify-content: flex-end;
}

.field-value-text {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: end;
}

.custom-value-content-wrapper {
    height: 100%;
}

.link {
    @include linkStyle(underline);
    cursor: pointer;
}
</style>
