import Vue from 'vue';
import Vuex from 'vuex';
import VueCookie from 'vue-cookie';
import Actions, { Mutations, State, Getters } from '@/store/mutation-types';
import { USER_MODES } from '@/common/commonHelper';

// Vuex Modules
import config from '@/store/modules/config.store';
import operators from '@/store/modules/operators.store';
import customerCare from '@/store/modules/customer-care.store';
import orders from '@/store/modules/orders.store';
import entitymutationStore from '@/store/modules/entityMutation.store';
import segments from '@/store/modules/segments.store';
import charging from '@/store/modules/charging.store';
import chargingV2 from '@/store/modules/chargingV2.store';
import campaigns from '@/store/modules/campaigns.store';
import triggers from '@/store/modules/triggers.store';
import staticFilters from '@/store/modules/staticFilters.store';
import templateHelpers from '@/store/modules/templateHelpers.store';
import graphs from '@/store/modules/graphs.store';
import users from '@/store/modules/users.store';
import roles from '@/store/modules/roles.store';
import userManagementSubscriber from '@/store/modules/user-management-subscriber.store';
import userManagementUser from '@/store/modules/user-management-user.store';
import userManagementAccount from '@/store/modules/user-management-account.store';
import userManagementOrganization from '@/store/modules/user-management-organization.store';
import esim from '@/store/modules/esim.store';
import promotionsStore from '@/store/modules/promotions.store';
import events from '@/store/modules/events.store';
import sinkConfigs from '@/store/modules/sinkConfigs.store';
import conditionsTree from '@/store/modules/conditions-tree.store';
import orchestrationengineStore from '@/store/modules/orchestrationengine.store';
import rewardsStore from '@/store/modules/rewards.store';
import productcatalogStore from '@/store/modules/productcatalog.store';
import documents from '@/store/modules/documents.store';
import customerCareSuite from '@/__new__/stores/customerCareSuite/customer-care-suite.store';
import dataflows from '@/store/modules/dataflows.store';
import endUserAuthorization from '@/store/modules/endUserAuthorization.store';
import sharedFileLocations from '@/store/modules/sharedFileLocations.store';
import quietHours from '@/store/modules/quietHours.store';

// Vuex modules
export const Modules = {
    config: 'config',
    productcatalog: 'productcatalog',
    rewards: 'rewards',
    orchestrationengine: 'orchestrationengine',
    charging: 'charging',
    chargingV2: 'chargingV2',
    entitymutation: 'entitymutation',
    promotions: 'promotions',
    orders: 'orders',
    // CEP related modules
    segments: 'segments',
    campaigns: 'campaigns',
    staticFilters: 'staticFilters',
    triggers: 'triggers',
    events: 'events',
    sinkConfigs: 'sinkConfigs',
    templateHelpers: 'templateHelpers',
    documents: 'documents',
    customerCareSuite: 'customerCareSuite',
    dataflows: 'dataflows',
    endUserAuthorization: 'endUserAuthorization',
    sharedFileLocations: 'sharedFileLocations',
    userManagementSubscriber: 'userManagementSubscriber',
    quietHours: 'quietHours',
};

Vue.use(Vuex);

const FeaturesCookieName = 'F';

const modules = {
    [Modules.config]: config,
    customerCare,
    operators,
    graphs,
    charging,
    users,
    roles,
    [Modules.userManagementSubscriber]: userManagementSubscriber,
    userManagementUser,
    userManagementAccount,
    userManagementOrganization,
    esim,
    conditionsTree,
    [Modules.orders]: orders,
    [Modules.promotions]: promotionsStore,
    [Modules.entitymutation]: entitymutationStore,
    [Modules.charging]: charging,
    [Modules.chargingV2]: chargingV2,
    [Modules.orchestrationengine]: orchestrationengineStore,
    [Modules.rewards]: rewardsStore,
    [Modules.productcatalog]: productcatalogStore,
    // CEP modules
    [Modules.segments]: segments,
    [Modules.campaigns]: campaigns,
    [Modules.staticFilters]: staticFilters,
    [Modules.triggers]: triggers,
    [Modules.events]: events,
    [Modules.sinkConfigs]: sinkConfigs,
    [Modules.templateHelpers]: templateHelpers,
    [Modules.documents]: documents,
    [Modules.customerCareSuite]: customerCareSuite,
    [Modules.dataflows]: dataflows,
    [Modules.endUserAuthorization]: endUserAuthorization,
    [Modules.sharedFileLocations]: sharedFileLocations,
    [Modules.quietHours]: quietHours,
};

export default new Vuex.Store({
    strict: process.env.NODE_ENV !== 'production',

    modules,

    state: {
        // Default state of sidebar is expanded.
        [State.COMPACT_SIDEBAR]: localStorage.getItem(State.COMPACT_SIDEBAR) === 'true',
        [State.CURRENT_MODE]: USER_MODES.REGULAR,

        [State.FEATURES]: VueCookie.get(FeaturesCookieName) || '',
    },

    mutations: {
        [Mutations.TOGGLE_COMPACT_SIDEBAR]: state => {
            state[State.COMPACT_SIDEBAR] = !state[State.COMPACT_SIDEBAR];
            localStorage.setItem(State.COMPACT_SIDEBAR, state[State.COMPACT_SIDEBAR]);
        },
        [Mutations.CHANGE_MODE]: (state, { mode }) => {
            state[State.CURRENT_MODE] = mode;
        },
    },

    actions: {
        [Actions.TOGGLE_COMPACT_SIDEBAR]: ({ commit }) => {
            commit(Mutations.TOGGLE_COMPACT_SIDEBAR);
        },
        [Actions.CHANGE_MODE]: ({ commit }, { mode }) => {
            commit(Mutations.CHANGE_MODE, { mode });
        },
    },
    getters: {
        [Getters.GET_MODE]: state => state[State.CURRENT_MODE] || USER_MODES.REGULAR,
        [Getters.GET_IS_COMPACT_SIDEBAR]: state => state[State.COMPACT_SIDEBAR] || false,
    },
});
