<template>
    <div class="stepper d-flex row">
        <div
            v-for="(step, index) in steps"
            :key="step.key"
            class="step d-flex flex-column align-items-center col"
        >
            <div
                :class="[
                    'circle text-center',
                    {
                        orange: index === currentStepIndex,
                        gray: index > maxStepAvailableIndex,
                        navy: index <= maxStepAvailableIndex && index !== currentStepIndex,
                    },
                ]"
                @click="clickOnStep(index)"
            />
            <div class="stepper-line line-left" />
            <div class="stepper-line line-right" />
            <div
                :class="[
                    'title',
                    {
                        navy: index <= maxStepAvailableIndex && index !== currentStepIndex,
                        current: index === currentStepIndex,
                    },
                ]"
                @click="clickOnStep(index)"
            >
                {{ step.title }}
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Stepper',
    props: {
        steps: { type: Array, required: true },
        currentStepKey: { type: String, default: '' },
        maxStepAvailableIndex: { type: Number, default: 0 },
    },
    computed: {
        currentStepIndex() {
            return this.steps.findIndex(step => step.key === this.currentStepKey);
        },
    },
    methods: {
        clickOnStep(stepIndex) {
            if (stepIndex <= this.maxStepAvailableIndex && stepIndex !== this.currentStepIndex) {
                this.$emit('back', stepIndex);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/_palette';

.stepper {
    .circle {
        line-height: 0;

        &:before {
            display: inline-block;
            content: '';
            height: 12px;
            width: 12px;
            border-radius: 100%;
        }

        &.gray:before {
            background-color: $gray30;
        }
        &.orange:before {
            background-color: $orange;
        }
        &.navy:before {
            background-color: $navy;
        }
        &.blue:before {
            background-color: $blue;
        }
        &.green:before {
            background-color: $green;
        }
        &.yellow:before {
            background-color: $yellow;
        }
    }

    .step {
        position: relative;

        &:first-child .line-left {
            display: none;
        }

        &:last-child .line-right {
            display: none;
        }

        .circle.navy {
            cursor: pointer;
        }

        .stepper-line {
            position: absolute;
            top: 6px;

            border-top: 1px solid $dirty-white;

            &.line-left {
                left: 0;
                right: calc(50% + 8px);
            }

            &.line-right {
                left: calc(50% + 8px);
                right: 0;
            }
        }

        .title {
            padding-top: 4px;

            text-transform: uppercase;
            color: $gray30;
            font-size: 12px;

            &.current {
                color: $orange;
                font-weight: bold;
            }

            &.navy {
                color: $navy;
                cursor: pointer;
            }
        }
    }
}
</style>
