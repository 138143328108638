<template>
    <div class="page-wrap">
        <SinkConfigEditor
            v-if="shouldShowContent"
            :entityType="ENTITY_TYPES.REDSHIFT_CONFIG"
            :uiSettings="redshiftConfigsSettings"
            :createFunction="createRedshiftConfig"
            :updateFunction="updateRedshiftConfig"
            :saveDraftFunction="createRedshiftDraft"
            :typeCasters="redshiftCasters"
            :dashboardPageName="RouteNames.REDSHIFT_CONFIG_DASHBOARD"
            :editableConfig="editableConfig"
            :triggerDefinitions="triggerDefinitions"
            :events="events"
            :formatters="getSinkFormatters"
            :sinkRequiredColumns="requiredFields"
        />
    </div>
</template>

<script>
import {
    createRedshiftConfig,
    updateRedshiftConfig,
    createRedshiftDraft,
} from '@/__new__/services/dno/sinkConfigs/http/sinkConfigs';
import { mapGetters, mapActions } from 'vuex';
import { Modules } from '@/store/store';
import Actions, { Getters } from '@/store/mutation-types';
import { ALERT_TYPES } from '@/common/alerts/Alert';
import SinkConfigEditorMixin from '@/__new__/features/sinkConfigs/common/SinkConfigEditorMixin';
import isEmpty from 'lodash/isEmpty';
import { cloneDeep } from 'lodash';

export default {
    name: 'RedshiftConfigsEditor',
    mixins: [SinkConfigEditorMixin],
    data() {
        return {
            createRedshiftConfig,
            updateRedshiftConfig,
            createRedshiftDraft,
        };
    },
    computed: {
        ...mapGetters(Modules.sinkConfigs, {
            redshiftConfigsSettings: Getters.GET_REDSHIFT_CONFIGS_SETTINGS,
            allRedshiftConfigs: Getters.GET_REDSHIFT_CONFIGS,
            getConfigById: Getters.GET_REDSHIFT_CONFIG_BY_ID,
            getSinkFormatters: Getters.GET_REDSHIFT_FORMATTERS,
            redshiftCasters: Getters.GET_REDSHIFT_SINK_TYPE_CASTERS,
            requiredFields: Getters.GET_REDSHIFT_REQUIRED_PROPERTIES,
        }),
        ...mapGetters(Modules.segments, {
            segments: Getters.CACHED_SEGMENTS,
        }),

        editableConfig() {
            const config = cloneDeep(this.getConfigById(this.$route.params.id)) || null;
            const requiredColumnNames = this.requiredFields.map(property => property.sink_event_field_name);
            if (config) {
                config.fieldsConfiguration.forEach(field => {
                    if (requiredColumnNames.includes(field.name)) {
                        field.isRequiredField = true;
                        field.forbidFieldRemoval = true;
                    }
                });
            }
            return config;
        },
        shouldShowContent() {
            if (this.$route.params.id) {
                return !!this.editableConfig && this.events.length && Object.keys(this.triggerDefinitions).length;
            }
            return !isEmpty(this.redshiftConfigsSettings) && this.triggerDefinitions && this.events;
        },
    },
    created() {
        this.$withLoadingSpinner(
            async () => {
                const promises = [
                    this[Actions.LOAD_REDSHIFT_CONFIGS_SETTINGS](),
                    this.fetchTriggers(),
                    this.fetchEvents(),
                    this[Actions.LOAD_REDSHIFT_REQUIRED_PROPERTIES](),
                    this[Actions.LOAD_REDSHIFT_FORMATTERS](),
                    this[Actions.LOAD_REDSHIFT_SINK_TYPE_CASTERS](),
                ];

                if (!this.segments.length) {
                    promises.push(this.fetchSegments());
                }

                await Promise.all(promises);
                if (this.$route.params.id && this.allRedshiftConfigs.length === 0) {
                    await this[Actions.LOAD_REDSHIFT_CONFIGS]();
                }
            },
            {
                errorHandler: () => {
                    this.$eventBus.$emit('showAlert', {
                        message: this.$i18n.t('events.alerts.failedToLoadTableConfig'),
                        type: ALERT_TYPES.alert,
                    });
                },
            },
        );
    },

    methods: {
        ...mapActions(Modules.sinkConfigs, [
            Actions.LOAD_REDSHIFT_CONFIGS_SETTINGS,
            Actions.LOAD_REDSHIFT_CONFIGS,
            Actions.LOAD_REDSHIFT_REQUIRED_PROPERTIES,
            Actions.LOAD_REDSHIFT_FORMATTERS,
            Actions.LOAD_REDSHIFT_SINK_TYPE_CASTERS,
        ]),
    },
};
</script>
