<template>
    <div class="form">
        <h2>{{ formTitle }}</h2>

        <AppToggle
            :value="true"
            :label="allServicesLabel"
            :small="true"
            :disabled="true"
            class="mb-3"
        />

        <div v-if="!isSubscriberBarred">
            <div class="reason mb-3">
                <h3 class="text--xs mb-1">
                    {{ $i18n.t('customerCare.suspendServices.suspensionReason') }}
                </h3>

                <AppMultiselectV3
                    v-model="reason"
                    :options="reasonOptions"
                    :error="$v.reason.$error"
                    :errorMessage="$i18n.t('generic.validations.fieldIsRequired')"
                    label="label"
                />
            </div>

            <div class="mb-5">
                <h3 class="text--xs mb-1">
                    {{ $i18n.t('generic.notes') }}
                </h3>

                <CustomTextarea
                    v-model="notesTxt"
                    :placeholder="$i18n.t('generic.addNotes')"
                    :invalid="$v.notesTxt.$error"
                    class="input"
                />

                <p
                    v-show="$v.notesTxt.$error"
                    class="error-message mt-2"
                >
                    {{ $i18n.t('generic.validations.fieldIsRequired') }}
                </p>
            </div>
        </div>

        <div class="d-flex justify-content-end">
            <AppButton
                :label="$i18n.t('generic.cancel')"
                data-test-id="btn-close"
                @click="close"
            />
            <AppButton
                :buttonType="BUTTON_TYPES.PRIMARY"
                :label="formTitle"
                class="ml-3"
                data-test-id="btn-submit"
                @click="onSubmit"
            />
        </div>
    </div>
</template>

<script>
// Components
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import AppMultiselectV3 from '@/components/partials/inputs/AppMultiselectV3.vue';
import AppToggle from '@/components/partials/inputs/AppToggle.vue';
import CustomTextarea from '@/__new__/features/customerCare/subscriber/CustomTextarea.vue';

// http
import userManagementHTTP from '@/__new__/services/dno/user/http/user-management';
import notesHTTP from '@/__new__/services/dno/crm/http/crm';

// Helpers
import {
    USER_MANAGER_HIERARCHY,
    SUBSCRIBER_SUSPEND_SERVICES_ACTIONS,
    SUBSCRIBER_SUSPEND_SERVICES_REASON_CODE,
} from '@/__new__/features/customerCare/common/customerCareHelper';
import { required } from 'vuelidate/lib/validators';
import { mapActions } from 'vuex';
import { dateToEpoch } from '@/common/formatting';

export default {
    name: 'SubscriberSuspendServicesForm',
    components: {
        AppButton,
        AppMultiselectV3,
        AppToggle,
        CustomTextarea,
    },
    props: {
        action: {
            required: true,
            type: String,
        },
        accountId: {
            required: true,
            type: String,
        },
        subscriberId: {
            default: '',
            type: String,
        },
    },
    data() {
        return {
            allServices: true,
            reason: '',
            notesTxt: '',
            BUTTON_TYPES,
        };
    },
    computed: {
        isSubscriberBarred() {
            return this.action === SUBSCRIBER_SUSPEND_SERVICES_ACTIONS.UNBAR;
        },
        reasonOptions() {
            return [
                {
                    id: SUBSCRIBER_SUSPEND_SERVICES_REASON_CODE.FRAUD,
                    label: this.$i18n.t('customerCare.subscriberTab.isFraud'),
                },
            ];
        },
        formTitle() {
            return this.isSubscriberBarred
                ? this.$i18n.t('customerCare.suspendServices.resumeServices')
                : this.$i18n.t('customerCare.suspendServices.suspendServices');
        },
        allServicesLabel() {
            return this.isSubscriberBarred
                ? this.$i18n.t('customerCare.suspendServices.resumeAllServices')
                : this.$i18n.t('customerCare.suspendServices.suspendAllServices');
        },
        suspendAlertMessage() {
            if (this.isSubscriberBarred) {
                return this.allServices
                    ? this.$i18n.t('customerCare.suspendServices.alerts.allServicesResumedForSubsriber')
                    : this.$i18n.t('customerCare.suspendServices.alerts.selectedServicesResumedForSubsriber');
            }
            return this.allServices
                ? this.$i18n.t('customerCare.suspendServices.alerts.allServicesSuspendedForSubsriber')
                : this.$i18n.t('customerCare.suspendServices.alerts.selectedServicesSuspendedForSubsriber');
        },
    },
    validations() {
        return {
            reason: {
                required: this.isSubscriberBarred ? false : required,
            },
            notesTxt: {
                required: this.isSubscriberBarred ? false : required,
            },
        };
    },
    methods: {
        ...mapActions('userManagementSubscriber', ['setSuspendedService']),
        close() {
            this.$emit('close');
            // Reset the values used for redirection
            this.setSuspendedService({
                subscriberId: this.subscriberId,
                suspendedService: {},
            });
        },
        onSubmit() {
            this.$v.$touch();

            if (this.$v.$invalid) {
                return;
            }

            this.close();
            this.submit();
        },
        async submit() {
            if (this.isSubscriberBarred) {
                await this.restoreServices();
            } else {
                await this.suspendServices().then(this.setAgentNote());
            }

            this.$eventBus.$emit('updateEntity');
        },
        /**
         * Sets agent notes that will be used by the NotesSection component.
         */
        async setAgentNote() {
            await this.$withProgressBar(
                () =>
                    notesHTTP.addNote({
                        // Notice: Update id/idType when subscriber get 'NotesSection'
                        id: this.accountId,
                        idType: USER_MANAGER_HIERARCHY.ACCOUNT,
                        epoch: dateToEpoch(Date.now()),
                        noteText: this.notesTxt,
                        tags: [this.$i18n.t('generic.stateMap.suspended'), this.$i18n.t('generic.fraud')],
                    }),
                {
                    errorHandler: () =>
                        this.$showErrorAlert({
                            message: this.$i18n.t('alertMessage.somethingWentWrongSavingAgentNotes'),
                        }),
                },
            );
        },
        async suspendServices() {
            await this.$withProgressBar(
                async () => {
                    await userManagementHTTP.suspendServices(this.subscriberId, USER_MANAGER_HIERARCHY.SUBSCRIBER, {
                        action: this.action,
                    });
                    this.$showSuccessAlert({ message: this.suspendAlertMessage });
                },
                {
                    errorHandler: () =>
                        this.$showErrorAlert({
                            message: this.$i18n.t('customerCare.suspendServices.alerts.serviceSuspensionFailed'),
                        }),
                },
            );
        },
        async restoreServices() {
            await this.$withProgressBar(
                async () => {
                    await userManagementHTTP.suspendServices(this.subscriberId, USER_MANAGER_HIERARCHY.SUBSCRIBER, {
                        action: this.action,
                    });
                    this.$showSuccessAlert({ message: this.suspendAlertMessage });
                },
                {
                    errorHandler: () =>
                        this.$showErrorAlert({
                            message: this.$i18n.t('customerCare.suspendServices.alerts.serviceRestorationFailed'),
                        }),
                },
            );
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/palette';
@import '~@/assets/scss/layout';
@import '~@/assets/scss/typographyV2';

.form {
    padding: 2.5rem;
}

h2 {
    margin-bottom: 2.5rem;
}

h3 {
    font-weight: $bold-font-weight;
    line-height: $label-line-height;
}

.error-message {
    color: $red;
}

.radios ::v-deep label {
    color: $black;
}

#app .reason ::v-deep :is(.multiselect, .multiselect__input, .multiselect__single) {
    font-size: $text-sm;
}

#app .reason ::v-deep .multiselect__tags {
    padding-left: $spacing-xs;
}

.form ::v-deep textarea {
    width: 100%;
    padding-inline: $spacing-xs;
    border-radius: 0.5rem;
    font-size: $text-sm;

    &::placeholder {
        font-style: italic;
        opacity: 1;
        font-size: inherit;
    }
}
</style>
