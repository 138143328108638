import UserManagerBaseModel, {
    PROPERTY_TYPE_BE_MAP,
    PROPERTY_TYPE_VALIDATE_FN_MAP,
} from '@/__new__/services/dno/user/models/UserManagerBaseModel';
import Flag, { FLAG_MAP, FLAG_TYPE, type Flags } from '@/__new__/services/dno/user/models/Flag';
import i18n from '@/i18n';
import { get, isEmpty } from 'lodash';
import { getOperatorConfigValue } from '@/services/permissions/permissions.service';
import { type TranslateResult } from 'vue-i18n';
import localeLibrary from '@/common/locale/localeLibrary';
import { ADDRESS_TYPE, USER_MANAGER_HIERARCHY } from '@/__new__/features/customerCare/common/customerCareHelper';
import { ADDRESS_ELIGIBILITY } from '@/common/addressbookHelper';

interface AccountConstructorArgs {
    accountName: string;
    name: string;
    surname: string;
    fullName: string;
    accountId: string;
    id: string;
    billingDay: number;
    documentType: string;
    documentSrc: string;
    documentNumber: string;
    tcAcceptanceTimestamp: number | null;
    isTcGlobalAcceptedCode: number;
    tcGlobalAccepted: string;
    isBusinessAccountCode: number;
    businessAccount: string;
    flags: Flags<number>;
    phone: string;
    addresses: AccountAddressResponse;
    userId: string;
    organizationId: string;
    state: number;
    subscribers: Array<string>;
    // fiber specific fields
    providerId: string;
    macAddress: string;
    deviceType: string;
    deviceModel: string;
    ontId: string;
    pilotServiceId: string;
    tmoAccountId: string;
    routerIds: string[];
    cvlan: number;
    svlan: number;
    cancelServiceReason: string;
    creationDate: number;
    cardinality: number;
    brandCode?: string;
    isMsisdnDiscountEligible?: string;
    lineStatus?: string;
    friendlyAccountId?: string;
    samsonBan?: string;
}

type AccountProperty = {
    responsePath?: string;
    fallbackType?: keyof typeof PROPERTY_TYPE_VALIDATE_FN_MAP;
    defaultValue?: any;
    mapFn?(response: any): any;
    computeFn?(args: Partial<AccountConstructorArgs>): any;
};

export interface AccountAddress {
    addressId: string;
    addressbookId: string;
    unitNumber: string;
    unitNo: string;
    unit: string;
    address: string;
    zip: string;
    city: string;
    taxAreaId: string;
    country: string;
    cityCode: string;
    email: string;
    addressLine2: string;
    streetName: string;
    province: string;
    provinceCode: string;
    barangay: string;
    barangayCode: string;
    houseNumber: string;
    postCode: string;
    postalCode: string;
    firstName: string;
    lastName: string;
    phoneNumber: string;
    post: string;
    state: string;
    number: string;
    area: string;
    village: string;
    alternatePhone: string;
    deliveryInstructions: string;
    lat: string;
    lng: string;
    googlePlaceId: string;
    type: number;
    stringAddressType: TranslateResult;
    latitude: string;
    longitude: string;
    provider: string;
    marketId: string;
    // gpfiber specific(atm)
    buildingName: string;
    floor: string;
    street: string;
    zipCode: string;
    eligibilityStatus?: TranslateResult;
    eligibilityStatusString: TranslateResult;
    eligibility?: number;
    locationId?: string;
}

interface AccountAddressLF {
    addressbook_id: string;
    unit_number: string;
    unit_no: string;
    unit: string;
    address: string;
    zip: string;
    city: string;
    tax_area_id: string;
    country: string;
    city_code: string;
    email: string;
    address_line_2: string;
    street_name: string;
    province: string;
    province_code: string;
    barangay: string;
    barangay_code: string;
    house_number: string;
    post_code: string;
    postal_code: string;
    first_name: string;
    last_name: string;
    phone_number: string;
    post: string;
    state: string;
    number: string;
    area: string;
    village: string;
    alternate_phone: string;
    delivery_instructions: string;
    lat: string;
    lng: string;
    google_place_id: string;
    type: number;
    stringAddressType: TranslateResult;
    latitude: string;
    longitude: string;
    provider_id: string;
    market_id: string;
    // gpfiber specific(atm)
    building_name: string;
    floor: string;
    street: string;
    zip_code: string;
    eligibility: number;
    external_params?: {
        external_id?: string;
    };
}

interface AccountAddressMVNE {
    address_1: string;
    address_2: string;
    city: string;
    hierarchy: string;
    state: string;
    type: number;
    zip: string;
    zip_4: string;
}

type AccountAddressResponse = {
    [key: string]: AccountAddressLF | AccountAddressMVNE;
};

const ACCOUNT_PROPERTIES: Record<keyof AccountConstructorArgs, AccountProperty> = {
    accountName: {
        responsePath: 'properties.account_name',
        fallbackType: 'string',
        defaultValue: '',
    },
    name: {
        responsePath: 'properties.first_name',
        fallbackType: 'string',
        defaultValue: '',
    },
    surname: {
        responsePath: 'properties.last_name',
        fallbackType: 'string',
        defaultValue: '',
    },
    fullName: {
        computeFn: function (this: any, args) {
            let fullName: string | undefined = '';
            if (this.validateString('fullName', args?.name) && this.validateString('fullName', args?.surname)) {
                fullName = `${args?.name} ${args?.surname}`;
            }
            if (!fullName && this.validateString('accountName', args?.accountName)) {
                fullName = args?.accountName;
            }
            return fullName;
        },
        fallbackType: 'string',
        defaultValue: '',
    },
    accountId: {
        responsePath: 'account_id',
        fallbackType: 'string',
        defaultValue: '',
    },
    id: {
        responsePath: 'account_id',
        fallbackType: 'string',
        defaultValue: '',
    },
    documentType: {
        responsePath: 'properties.document_type',
        fallbackType: 'string',
        defaultValue: '',
    },
    documentSrc: {
        responsePath: 'properties.document_pic_url',
        fallbackType: 'string',
        defaultValue: '',
    },
    documentNumber: {
        responsePath: 'properties.document_number',
        fallbackType: 'string',
        defaultValue: '',
    },
    tcAcceptanceTimestamp: {
        responsePath: 'properties.auto_payment_last_updated_timestamp',
        fallbackType: 'integer',
        defaultValue: null,
    },
    isTcGlobalAcceptedCode: {
        responsePath: 'flags.auto_payment',
    },
    tcGlobalAccepted: {
        computeFn: (args: any) => {
            return Flag.getValueLabel(FLAG_TYPE.TC_GLOBAL_ACCEPTED, args?.isTcGlobalAcceptedCode);
        },
        fallbackType: 'string',
        defaultValue: '',
    },
    isBusinessAccountCode: {
        responsePath: 'flags.is_enterprise',
    },
    businessAccount: {
        computeFn: (args: any) => {
            return Flag.getValueLabel(FLAG_TYPE.IS_ENTERPRISE, args?.isBusinessAccountCode);
        },
        fallbackType: 'string',
        defaultValue: '',
    },
    billingDay: {
        responsePath: 'billing_day_of_month',
        fallbackType: 'day',
    },
    phone: {
        responsePath: 'properties.ban_msisdn',
        fallbackType: 'string',
        defaultValue: '',
    },
    flags: {
        responsePath: 'flags',
        mapFn: response => {
            const flags = response?.flags ?? {};
            // add is_test
            if (!Object.hasOwnProperty.call(flags, FLAG_TYPE.IS_TEST)) {
                flags[FLAG_TYPE.IS_TEST] = FLAG_MAP.FALSE;
            }

            // add is block port out
            if (!Object.hasOwnProperty.call(flags, FLAG_TYPE.BLOCK_PORT_OUT)) {
                flags[FLAG_TYPE.BLOCK_PORT_OUT] = FLAG_MAP.FALSE;
            }

            // add is block sim swap
            if (!Object.hasOwnProperty.call(flags, FLAG_TYPE.IS_SIM_SWAP_BLOCKED)) {
                flags[FLAG_TYPE.IS_SIM_SWAP_BLOCKED] = FLAG_MAP.FALSE;
            }

            // add tax_exemption
            if (!Object.hasOwnProperty.call(flags, FLAG_TYPE.TAX_EXEMPTION)) {
                flags[FLAG_TYPE.TAX_EXEMPTION] = undefined;
            }
            return flags;
        },
        computeFn: (args: any) => {
            return Flag.mapAccountFlags(args?.flags);
        },
    },
    userId: {
        responsePath: 'user_owner',
        fallbackType: 'string',
        defaultValue: '',
    },
    organizationId: {
        responsePath: 'organization_id',
        fallbackType: 'string',
        defaultValue: '',
    },
    state: {
        responsePath: 'state',
        fallbackType: 'integer',
        defaultValue: null,
    },
    subscribers: {
        responsePath: 'subscribers',
        fallbackType: 'array',
        defaultValue: [],
    },
    addresses: {
        responsePath: 'addresses',
        computeFn: function (this: any, args: any) {
            return this.mapAddresses(args?.addresses);
        },
    },
    // fiber and gpfiber specific fields
    providerId: {
        responsePath: 'properties.provider_id',
        fallbackType: 'string',
        defaultValue: '',
    },
    macAddress: {
        responsePath: 'properties.mac_address',
        fallbackType: 'string',
        defaultValue: '',
    },
    deviceType: {
        responsePath: 'properties.device_type',
        fallbackType: 'string',
        defaultValue: '',
    },
    deviceModel: {
        responsePath: 'properties.device_model',
        fallbackType: 'string',
        defaultValue: '',
    },
    // fiber specific fields
    ontId: {
        responsePath: 'properties.ont_id',
        fallbackType: 'string',
        defaultValue: '',
    },
    pilotServiceId: {
        responsePath: 'properties.external_service_id',
        fallbackType: 'string',
        defaultValue: '',
    },
    tmoAccountId: {
        responsePath: 'properties.external_account_id',
        fallbackType: 'string',
        defaultValue: '',
    },
    routerIds: {
        responsePath: 'properties.router_ids',
        mapFn: response => {
            const ids = response?.properties?.router_ids;
            if (ids && !isEmpty(ids)) {
                return ids;
            }
            return null;
        },
        fallbackType: 'array',
        defaultValue: null,
    },
    cvlan: {
        responsePath: 'properties.cvlan',
        fallbackType: 'integer',
        defaultValue: '',
    },
    svlan: {
        responsePath: 'properties.svlan',
        fallbackType: 'integer',
        defaultValue: '',
    },
    cancelServiceReason: {
        responsePath: 'properties.cancel_reason',
        fallbackType: 'string',
        defaultValue: '',
    },
    creationDate: {
        responsePath: 'created',
        fallbackType: 'string',
        defaultValue: '',
        computeFn: ({ creationDate }) => creationDate && localeLibrary.getFormattedDateAndTime(creationDate),
    },
    cardinality: {
        responsePath: 'cardinality',
        fallbackType: 'integer',
        defaultValue: '',
    },
    brandCode: {
        responsePath: 'properties.brand_code',
        fallbackType: 'string',
        defaultValue: '',
    },
    isMsisdnDiscountEligible: {
        responsePath: 'properties.is_msisdn_discount_eligible',
        fallbackType: 'string',
        defaultValue: '',
    },
    lineStatus: {
        responsePath: 'properties.line_status',
        fallbackType: 'string',
        defaultValue: '',
    },
    friendlyAccountId: {
        responsePath: 'properties.friendly_account_id',
        fallbackType: 'string',
        defaultValue: '',
    },
    samsonBan: {
        responsePath: 'properties.samson_ban',
        fallbackType: 'string',
        defaultValue: '',
    },
};

enum AddressFormat {
    LF = 'lf',
    MVNE = 'mvne',
}

export default class Account extends UserManagerBaseModel {
    accountName?: string;
    fullName?: string;
    accountId?: string;
    id!: string;
    billingDay?: number;
    documentType?: string;
    documentSrc?: string;
    documentNumber?: string;
    tcAcceptanceTimestamp?: number | null;
    tcGlobalAccepted?: string;
    businessAccount?: string;
    flags?: Flags;
    phone?: string;
    addresses?: AccountAddress[];
    userId?: string;
    organizationId?: string;
    state?: number;
    subscribers?: Array<string>;
    umEntityType?: USER_MANAGER_HIERARCHY.ACCOUNT;
    // fiber specific fields
    providerId?: string;
    macAddress?: string;
    deviceType?: string;
    deviceModel?: string;
    ontId?: string;
    pilotServiceId?: string;
    tmoAccountId?: string;
    routerIds?: string[];
    cvlan?: number | '';
    svlan?: number | '';
    cancelServiceReason?: string;
    creationDate?: string;
    brandCode?: string;
    isMsisdnDiscountEligible?: string;
    lineStatus?: string;
    friendlyAccountId?: string;
    cardinality?: number;
    samsonBan?: string;

    constructor(args: AccountConstructorArgs | Record<string, any>) {
        // Call constructor of UserManagerBaseModel class for creating new object with unique invalidKeys Map
        super();
        for (const [key, props] of Object.entries(ACCOUNT_PROPERTIES)) {
            let value: any = args?.[key as keyof Partial<AccountConstructorArgs>];
            if (props?.computeFn instanceof Function) {
                value = props.computeFn.call(this, args);
            }
            (<any>this)[key] = this.validateProperty(key, value, props);
        }
        this.umEntityType = USER_MANAGER_HIERARCHY.ACCOUNT;
    }

    validateProperty(key: string, value: any, props: AccountProperty) {
        // validate property value
        let propertyType: PROPERTY_TYPE_BE_MAP | keyof typeof PROPERTY_TYPE_VALIDATE_FN_MAP | undefined =
            props?.fallbackType;
        const propertyKeyBe = props?.responsePath?.split('.').pop();
        if (propertyKeyBe) {
            // get property type from BE config
            const propertyTypeBe: string = getOperatorConfigValue(
                `service_config.lf-user.properties.account_properties.${propertyKeyBe}`,
            );

            propertyType = (<any>PROPERTY_TYPE_BE_MAP)[propertyTypeBe];
        }
        const defaultValue = props?.defaultValue;
        // apply validation
        let validatedValue = value;
        if (propertyType && !this.validateByType(key, value, propertyType)) {
            validatedValue = defaultValue;
        }
        return validatedValue ?? defaultValue;
    }

    mapAddresses(addresses: AccountAddressResponse): AccountAddress[] {
        const guessAddressFormat = (address: any): AddressFormat => {
            if (address.zip_4) {
                return AddressFormat.MVNE;
            }
            return AddressFormat.LF;
        };

        const addressMappers: Record<AddressFormat, (address: any) => AccountAddress> = {
            [AddressFormat.LF]: (address: AccountAddressLF) => ({
                addressId: '',
                addressbookId: address.addressbook_id || '',
                unitNumber: address.unit_number || '',
                unitNo: address.unit_no || '',
                unit: address.unit || '',
                address: address.address,
                zip: address.zip,
                city: address.city,
                taxAreaId: address.tax_area_id,
                country: address.country || '',
                cityCode: address.city_code || '',
                email: address.email || '',
                addressLine2: address.address_line_2 || '',
                streetName: address.street_name || '',
                province: address.province || '',
                provinceCode: address.province_code || '',
                barangay: address.barangay || '',
                barangayCode: address.barangay_code || '',
                houseNumber: address.house_number || '',
                postCode: address.post_code || '',
                postalCode: address.postal_code || '',
                firstName: address.first_name || '',
                lastName: address.last_name || '',
                phoneNumber: address.phone_number || '',
                post: address.post || '',
                state: address.state || '',
                number: address.number || '',
                area: address.area || '',
                village: address.village || '',
                alternatePhone: address.alternate_phone || '',
                deliveryInstructions: address.delivery_instructions || '',
                lat: address.lat || '',
                lng: address.lng || '',
                googlePlaceId: address.google_place_id || '',
                type: address.type,
                stringAddressType: Account.addressTypeMap(address.type),
                eligibilityStatusString: this.getEligibilityStatusString(),
                eligibilityStatus: Account.eligibilityStatusMap(address.eligibility),
                eligibility: address.eligibility,
                latitude: address.latitude || '',
                longitude: address.longitude || '',
                provider: address.provider_id || '',
                marketId: address.market_id || '',
                buildingName: address.building_name || '',
                floor: address.floor || '',
                street: address.street || '',
                zipCode: address.zip_code || '',
                locationId: address.external_params?.external_id || '',
            }),
            [AddressFormat.MVNE]: (address: AccountAddressMVNE) => ({
                addressId: '',
                addressbookId: address.address_1 || '',
                unitNumber: '',
                unitNo: '',
                unit: '',
                address: address.address_1,
                zip: `${address.zip}${address.zip_4 ? `-${address.zip_4}` : ''}`,
                city: address.city,
                taxAreaId: '',
                country: '',
                cityCode: '',
                email: '',
                addressLine2: address.address_2 || '',
                streetName: '',
                province: '',
                provinceCode: '',
                barangay: '',
                barangayCode: '',
                houseNumber: '',
                postCode: '',
                postalCode: '',
                firstName: '',
                lastName: '',
                phoneNumber: '',
                post: '',
                state: address.state || '',
                number: '',
                area: '',
                village: '',
                alternatePhone: '',
                deliveryInstructions: '',
                lat: '',
                lng: '',
                googlePlaceId: '',
                type: address.type,
                stringAddressType: Account.addressTypeMap(address.type),
                eligibilityStatusString: this.getEligibilityStatusString(),
                eligibilityStatus: undefined,
                eligibility: undefined,
                latitude: '',
                longitude: '',
                provider: '',
                marketId: '',
                buildingName: '',
                floor: '',
                street: '',
                zipCode: '',
                locationId: '',
            }),
        };

        return addresses && !isEmpty(addresses)
            ? Object.entries(addresses)
                  .map(([addressId, address]) => {
                      const addressFormat = guessAddressFormat(address);
                      return {
                          ...addressMappers[addressFormat](address),
                          addressId,
                      };
                  })
                  .filter(Boolean)
            : [];
    }

    static addressTypeMap(type: ADDRESS_TYPE) {
        const mapping: Record<ADDRESS_TYPE, TranslateResult> = {
            [ADDRESS_TYPE.UNKNOWN]: i18n.t('generic.address'),
            [ADDRESS_TYPE.BILLING]: i18n.t('customerCare.addressMapper.billing'),
            [ADDRESS_TYPE.SHIPPING]: i18n.t('customerCare.addressMapper.shipping'),
            [ADDRESS_TYPE.SERVICE]: i18n.t('customerCare.addressMapper.service'),
            [ADDRESS_TYPE.PERMANENT]: i18n.t('customerCare.addressMapper.permanent'),
            [ADDRESS_TYPE.MAILING]: i18n.t('customerCare.addressMapper.mailing'),
            [ADDRESS_TYPE.e911]: i18n.t('customerCare.addressMapper.e911'),
            [ADDRESS_TYPE.PPU]: i18n.t('customerCare.addressMapper.ppu'),
        };
        return mapping[type] || i18n.t('generic.address');
    }

    getEligibilityStatusString(): TranslateResult {
        return i18n.t('customerCare.account.eligibilityStatus');
    }

    static eligibilityStatusMap(
        eligibility?: ADDRESS_ELIGIBILITY.ELIGIBLE | ADDRESS_ELIGIBILITY.PRE_ORDER,
    ): TranslateResult | undefined {
        const mapping: Record<ADDRESS_ELIGIBILITY.ELIGIBLE | ADDRESS_ELIGIBILITY.PRE_ORDER, TranslateResult> = {
            [ADDRESS_ELIGIBILITY.ELIGIBLE]: i18n.t('customerCare.account.eligible'),
            [ADDRESS_ELIGIBILITY.PRE_ORDER]: i18n.t('customerCare.account.preOrderEligible'),
        };
        return eligibility ? mapping[eligibility] : undefined;
    }

    static remapAccountFromBe(response: any) {
        const mappedAccountObj: Record<string, any> = {};
        for (const [key, props] of Object.entries(ACCOUNT_PROPERTIES)) {
            const path = props?.responsePath;
            if (props?.mapFn) {
                mappedAccountObj[key] = props.mapFn(response);
            } else if (path) {
                mappedAccountObj[key] = get(response, path);
            }
        }
        return mappedAccountObj;
    }
}
