
// Generic
import Vue from 'vue';

// Vuex
import { mapGetters, mapActions } from 'vuex';
import { Modules } from '@/store/store';
import Actions, { Getters } from '@/store/mutation-types';

// Components
import LayoutPage from '@/__new__/features/customerCareSuite/common/LayoutPage.vue';

// Helpers
import { USER_MANAGER_HIERARCHY } from '@/__new__/features/customerCare/common/customerCareHelper';
import { LAYOUT_PAGE_KEYS } from '@/__new__/features/customerCareSuite/common/layoutSectionHelper';

export default Vue.extend({
    name: 'OrganizationPageView',

    components: {
        LayoutPage,
    },

    props: {
        id: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            layoutPageKey: LAYOUT_PAGE_KEYS.ORGANIZATION,
        };
    },

    computed: {
        ...mapGetters(Modules.customerCareSuite, [Getters.GET_ASSOCIATED_UM_ENTITY_BY_TYPE_AND_ID]),
    },

    created() {
        this.$withProgressBar(
            async () => {
                await this[Actions.LOAD_ASSOCIATED_UM_ENTITIES]({
                    targetType: USER_MANAGER_HIERARCHY.ORGANIZATION,
                    targetId: this.id,
                });
            },
            {
                errorHandler: () =>
                    this.$showErrorAlert({
                        message: this.$i18n.t('alertMessage.failedToLoadNecessaryData'),
                    }),
            },
        );
    },
    methods: {
        ...mapActions(Modules.customerCareSuite, [Actions.LOAD_ASSOCIATED_UM_ENTITIES]),
    },
});
