export default {
    namespaced: true,
    state: {
        metrics: {},
    },
    mutations: {
        updateMetricsList: (state, metrics) => {
            state.metrics = { ...state.metrics, ...metrics.metrics };
        },
    },
    actions: {
        updateMetricsList: ({ commit }, metrics) => {
            commit('updateMetricsList', metrics);
        },
    },
    getters: {
        metricsList: state => Object.values(state.metrics),
    },
};
