import { AxiosPromise } from 'axios';
import http, { DnoResponseBase } from '@/http/index';

export interface RatingGroupsApiResponse extends DnoResponseBase {
    rating_group_by_id: Record<string, any>;
    condition_parameter_by_id?: Record<string, any>;
}

/**
 * Get rating groups.
 *
 * @returns {AxiosPromise}
 */
export function getRatingGroups(
    ids: string[] = [],
    returnChargingVersion = true,
    includeReferencedEntities = false,
    includePortalId = true,
    chargingVersion?: string,
): AxiosPromise<RatingGroupsApiResponse> {
    return http({
        method: 'POST',
        url: '/v3charging/getratinggroups',
        data: {
            ids,
            return_charging_version: returnChargingVersion,
            include_referenced_entities: includeReferencedEntities,
            charging_version: chargingVersion,
            include_portal_id: includePortalId,
        },
    });
}

/**
 * Add rating group.
 *
 * @returns {AxiosPromise}
 */
export function addRatingGroup(data: any): AxiosPromise<DnoResponseBase> {
    return http({
        method: 'POST',
        url: '/v3chargingwrite/addratinggroup',
        data,
    });
}

/**
 * Update rating group.
 *
 * @returns {AxiosPromise}
 */
export function updateRatingGroup(id: string, version: number, data: any): AxiosPromise<DnoResponseBase> {
    return http({
        method: 'POST',
        url: '/v3chargingwrite/updateratinggroup',
        data: {
            id,
            version,
            data,
        },
    });
}

/**
 * Update rating group state.
 *
 * @returns {AxiosPromise}
 */
export function updateRatingGroupState(id: string, version: number, state: number): AxiosPromise<DnoResponseBase> {
    return http({
        method: 'POST',
        url: '/v3chargingwrite/updatestateratinggroup',
        data: {
            id,
            version,
            state,
        },
    });
}

interface RatingGroupsDraftListApiResponse extends DnoResponseBase {
    data: any;
}

/**
 * Get rating group draft list.
 *
 * @returns {AxiosPromise}
 */
export function getRatingGroupsDraftList(): AxiosPromise<RatingGroupsDraftListApiResponse> {
    return http({
        method: 'POST',
        url: '/v3charging/getratinggroupsdraftlist',
    });
}

/**
 * Get rating group entity draft.
 *
 * @returns {AxiosPromise}
 */
export function getRatingGroupEntityDraft(id: string): AxiosPromise<DnoResponseBase> {
    return http({
        method: 'POST',
        url: '/v3charging/getratinggroupdraft',
        data: {
            id,
        },
    });
}

/**
 * Set rating group draft.
 *
 * @returns {AxiosPromise}
 */
type RatingGroupDraftPayload = {
    id?: string;
    data: object;
};

export function setRatingGroupDraft(data: RatingGroupDraftPayload): AxiosPromise<DnoResponseBase> {
    return http({
        method: 'POST',
        url: '/v3charging/setratinggroupdraft',
        data,
    });
}

/**
 * Delete rating group draft.
 *
 * @returns {AxiosPromise}
 */
export function deleteRatingGroupDraft(id: string): AxiosPromise<DnoResponseBase> {
    return http({
        method: 'POST',
        url: '/v3charging/deleteratinggroupdraft',
        data: {
            id,
        },
    });
}

export default {
    getRatingGroups,
    addRatingGroup,
    updateRatingGroup,
    updateRatingGroupState,
    getRatingGroupsDraftList,
    getRatingGroupEntityDraft,
    setRatingGroupDraft,
    deleteRatingGroupDraft,
};
