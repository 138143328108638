import i18n from '@/i18n';

export enum PAYMENT_TRANSACTION_TYPE_CODES {
    ORDER_PAYMENT = 1,
    MICROCHARGING_PAYMENT = 2,
    MANUAL_PAYMENT = 3,
    VALIDITY_CHECK = 4,
    PAYMENT_RETURN = 5,
    TRANSFER_DATA_SEND = 6,
    TRANSFER_DATA_RECEIVE = 7,
}

export function getPaymentTransactionTypeInString(id: PAYMENT_TRANSACTION_TYPE_CODES) {
    switch (id) {
        case PAYMENT_TRANSACTION_TYPE_CODES.ORDER_PAYMENT: {
            return i18n.t('customerCare.paymentStatuses.regular');
        }
        case PAYMENT_TRANSACTION_TYPE_CODES.MICROCHARGING_PAYMENT: {
            return i18n.t('customerCare.paymentStatuses.userBased');
        }
        case PAYMENT_TRANSACTION_TYPE_CODES.MANUAL_PAYMENT: {
            return i18n.t('customerCare.paymentStatuses.manualPayment');
        }
        case PAYMENT_TRANSACTION_TYPE_CODES.VALIDITY_CHECK: {
            return i18n.t('customerCare.paymentStatuses.validityCheck');
        }
        case PAYMENT_TRANSACTION_TYPE_CODES.PAYMENT_RETURN: {
            return i18n.t('customerCare.paymentStatuses.refund');
        }
        default:
            return i18n.t('generic.empty');
    }
}

export enum BILLING_TRX_PAYMENT_TYPES {
    DIRECT_BILL_PAYMENT = 1,
    IMMEDIATE_PAYMENT = 2,
    REFUND_PAYMENT = 3,
}

export const BILLING_TRX_TYPE_TO_LABEL = new Map([
    [BILLING_TRX_PAYMENT_TYPES.DIRECT_BILL_PAYMENT, i18n.t('customerCareSuite.billingTrxNextGenTile.directBill')],
    [BILLING_TRX_PAYMENT_TYPES.IMMEDIATE_PAYMENT, i18n.t('customerCareSuite.billingTrxNextGenTile.immediatePayment')],
    [BILLING_TRX_PAYMENT_TYPES.REFUND_PAYMENT, i18n.t('customerCareSuite.billingTrxNextGenTile.refund')],
]);

export enum PAYMENT_STATE {
    OUTSTANDING = 1,
    PARTIALLY_PAID = 2,
    PAID = 3,
}

export const PAYMENT_STATE_TO_LABEL = new Map<PAYMENT_STATE, string>([
    [PAYMENT_STATE.OUTSTANDING, i18n.t('customerCareSuite.paymentState.outstanding') as string],
    [PAYMENT_STATE.PARTIALLY_PAID, i18n.t('customerCareSuite.paymentState.partiallyPaid') as string],
    [PAYMENT_STATE.PAID, i18n.t('customerCareSuite.paymentState.paid') as string],
]);

export default {
    getPaymentTransactionTypeInString,
    PAYMENT_TRANSACTION_TYPE_CODES,
    BILLING_TRX_PAYMENT_TYPES,
    BILLING_TRX_TYPE_TO_LABEL,
    PAYMENT_STATE,
    PAYMENT_STATE_TO_LABEL,
};

export enum TRANSACTION_TYPE {
    SINGLE_PASS = 1,
    RESERVE = 2,
    REFUND = 5,
    COMMIT = 6,
    UNRESERVE = 7,
}

export const TRANSACTION_TYPE_TO_LABEL = {
    [TRANSACTION_TYPE.SINGLE_PASS]: i18n.t('billing.commit'), // Single pass is treated as commit transaction
    [TRANSACTION_TYPE.RESERVE]: i18n.t('billing.reserve'),
    [TRANSACTION_TYPE.REFUND]: i18n.t('billing.refund'),
    [TRANSACTION_TYPE.COMMIT]: i18n.t('billing.commit'),
    [TRANSACTION_TYPE.UNRESERVE]: i18n.t('billing.unreserve'),
};

export function getTransactionTypeLabel(type: TRANSACTION_TYPE) {
    return TRANSACTION_TYPE_TO_LABEL[type] || i18n.t('generic.N/A');
}
