<template>
    <AppAditionalSidebar
        :visible="showSidebar"
        @input="val => hideSidebar(val)"
    >
        <template #header>
            <div class="modal-row d-flex align-items-center">
                <div class="all-caps-text">
                    {{ $i18n.t('customerCare.orderDetails') }}
                </div>
            </div>
        </template>
        <template #content>
            <section>
                <AppTable
                    :entities="items"
                    :isDefaultHoverEnabled="false"
                    :forbidHideColumn="true"
                    :disableBoxShadow="true"
                    :newDesign="true"
                    :canSelectColumns="false"
                    :columnsData="itemsColumnsData"
                    tableKey="customer-care-suite/orders-sidebar"
                    keyName="executionId"
                    class="auto-height"
                >
                    <template #planId="{ entity }">
                        <div
                            v-if="entity.planId"
                            class="truncate-text"
                        >
                            <router-link
                                :to="{
                                    name: RouteNames.ORCHESTRATION_ENGINE_EXECUTION_DETAILS,
                                    params: {
                                        id: entity.planId,
                                        execId: entity.executionId,
                                    },
                                }"
                                target="_blank"
                            >
                                {{ entity.planId }}
                            </router-link>
                        </div>
                    </template>
                    <template #status="{ entity }">
                        <ExecutionStatusIndicator :status="entity.status" />
                    </template>
                </AppTable>
            </section>
            <template v-if="isOePlansVisible">
                <section>
                    <div class="modal-row d-flex align-items-center">
                        <div class="all-caps-text">
                            {{ $i18n.t('orchestrationEngine.oeWorkflow') }}
                        </div>
                    </div>
                    <AppTable
                        :entities="oeMappingsList"
                        :isDefaultHoverEnabled="false"
                        :forbidHideColumn="true"
                        :disableBoxShadow="true"
                        :newDesign="true"
                        :canSelectColumns="false"
                        :isDataLoading="isOeLoading"
                        :columnsData="oeColumnsData"
                        tableKey="customer-care-suite/orders-sidebar"
                        keyName="executionId"
                        class="auto-height"
                    >
                        <template #planId="{ entity }">
                            <div
                                v-if="entity.planId"
                                class="truncate-text"
                            >
                                <router-link
                                    :to="{
                                        name: RouteNames.ORCHESTRATION_ENGINE_EXECUTION_DETAILS,
                                        params: {
                                            id: entity.planId,
                                            execId: entity.executionId,
                                        },
                                    }"
                                    target="_blank"
                                >
                                    {{ entity.planId }}
                                </router-link>
                            </div>
                        </template>
                        <template #status="{ entity }">
                            <ExecutionStatusIndicator :status="entity.status" />
                        </template>
                    </AppTable>
                </section>
            </template>
        </template>
    </AppAditionalSidebar>
</template>
<script>
// COMPONENTS
import AppAditionalSidebar from '@/components/partials/AppAditionalSidebar.vue';
import ExecutionStatusIndicator from '@/__new__/features/orchestrationengine/ExecutionStatusIndicator.vue';
import AppTable from '@/components/partials/AppTable.vue';

// HELPERS
import tableColumnType from '@/common/filterTable';
import RouteNames from '@/router/routeNames';
import permissionsService from '@/services/permissions/permissions.service';
// HTTP
import OePlansMixin from '@/__new__/features/customerCare/mixins/OePlansMixin.vue';

export default {
    name: 'AdditionalSidebarForOrdersNextGen',
    components: {
        AppAditionalSidebar,
        ExecutionStatusIndicator,
        AppTable,
    },
    mixins: [OePlansMixin],
    props: {
        selectedOrder: {
            type: Object,
            default: null,
        },
        showAdditionalSidebar: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            isOeLoading: false,
            isSidebarVisible: false,
            oeMappingsList: [],
            RouteNames,
        };
    },
    computed: {
        showSidebar() {
            return this.isSidebarVisible;
        },
        items() {
            const itemsArray = [];
            if (this.selectedOrder?.items) {
                for (const [key, value] of Object.entries(this.selectedOrder.items)) {
                    for (const item of value) {
                        itemsArray.push({
                            action: key,
                            offerId: item.entityId,
                            subscriptionId: item.subscriptionId,
                            subscriberId: item.targetType === 1 ? item.targetId : '',
                        });
                    }
                }
            }
            return itemsArray;
        },
        itemsColumnsData() {
            return [
                {
                    name: this.$i18n.t('generic.action'),
                    key: 'action',
                    field: 'action',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('customerCareSuite.OrderDetailsTileNextGen.offerId'),
                    key: 'offerId',
                    field: 'offerId',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('customerCareSuite.OrderDetailsTileNextGen.subscriptionId'),
                    key: 'subscriptionId',
                    field: 'subscriptionId',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('customerCareSuite.OrderDetailsTileNextGen.subscriberId'),
                    key: 'subscriberId',
                    field: 'subscriberId',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
            ];
        },
        isOePlansVisible() {
            return this.oePlansEnabled && permissionsService.isUserLotusFlareAdmin();
        },
    },
    watch: {
        showAdditionalSidebar: {
            handler() {
                this.isSidebarVisible = this.showAdditionalSidebar;
            },
        },
        selectedOrder: {
            async handler() {
                this.oeMappingsList = [];
                if (this.oePlansEnabled && this.selectedOrder) {
                    await this.fetchExecutions({
                        mapper_ids: [this.selectedOrder.id],
                    });
                }
            },
        },
    },
    methods: {
        hideSidebar(val) {
            this.isSidebarVisible = val;
            this.$emit('closeSidebar', this.isSidebarVisible);
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/palette';
@import '~@/assets/scss/base';

.auto-height {
    height: auto !important;
}

.order-wrap {
    margin-bottom: 1.75rem;
}

.modal-row {
    margin-bottom: 0.875rem;
}

.row-title {
    font-size: 0.75rem;
    font-weight: 600;
    width: 7.875rem;
}

.row-value {
    font-size: 0.875rem;
    width: 100%;
}
</style>
