
// http
import osstmofiberHTTP from '@/__new__/services/dno/osstmofiber/http/osstmofiber';
// helpers
import { mapActions, mapGetters } from 'vuex';
import { Modules } from '@/store/store';
import { Getters } from '@/store/mutation-types';
import { USER_MANAGER_HIERARCHY } from '@/__new__/features/customerCare/common/customerCareHelper';
import { IS_OTP_VALIDATED } from '@/__new__/services/dno/user/models/User';
import { ALERT_TYPES } from '@/common/alerts/Alert';
import { isUserAllowed } from '@/services/permissions/permissions.service';
import Button from '@/common/button/Button';

export default {
    name: 'ValidationMethodChecker',
    props: {
        id: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            last4Digits: [],
            isUserAllowed,
        };
    },
    computed: {
        ...mapGetters(Modules.customerCareSuite, [Getters.GET_ASSOCIATED_UM_ENTITY_BY_TYPE_AND_ID]),
        ...mapGetters('userManagementAccount', ['getPaymentMethodsById']),
        userInfo() {
            return this[Getters.GET_ASSOCIATED_UM_ENTITY_BY_TYPE_AND_ID]({
                targetType: USER_MANAGER_HIERARCHY.USER,
                targetId: this.id,
            });
        },
        accountData() {
            return this[Getters.GET_ASSOCIATED_UM_ENTITY_BY_TYPE_AND_ID]({
                targetType: USER_MANAGER_HIERARCHY.ACCOUNT,
                targetId: this.accountId,
            });
        },
        accountId() {
            const account = this.userInfo?.permissions?.find(el => el.targetTypeId === USER_MANAGER_HIERARCHY.ACCOUNT);
            return account?.targetId;
        },
        isValidationMethodOtp() {
            return this.userInfo?.flags?.is_otp_validated?.originalValue === IS_OTP_VALIDATED.OTP;
        },
        validationMethod() {
            return this.isValidationMethodOtp
                ? this.$i18n.t('customerCareSuite.otp')
                : this.$i18n.t('customerCareSuite.pin');
        },
        reSetPinLabel() {
            return this.isValidationMethodOtp
                ? this.$i18n.t('alertMessage.userManagement.setToDefaultPIN')
                : this.$i18n.t('alertMessage.userManagement.resetToDefaultPIN');
        },
        alertMessage() {
            return this.isValidationMethodOtp
                ? this.$i18n.t('alertMessage.userManagement.validationMethodWillBeChanged')
                : this.$i18n.t('alertMessage.userManagement.validationMethodWillBeReSet');
        },
        paymentMethods() {
            return this.accountId ? this.getPaymentMethodsById(this.accountId) : [];
        },
    },
    created() {
        this.fetchPaymentMethods();
    },
    methods: {
        ...mapActions('userManagementAccount', ['getPaymentMethods']),
        reSetPinHandler() {
            const button = new Button({
                label: this.$i18n.t('generic.confirm'),
                alertType: ALERT_TYPES.warning,
                handler: () => {
                    this.reSetPin();
                },
            });

            this.$alert(this.alertMessage, {
                type: ALERT_TYPES.warning,
                buttons: [button],
            });
        },
        async reSetPin() {
            await this.$withProgressBar(
                async () => {
                    await osstmofiberHTTP.updateLoginFlow(
                        this.id,
                        USER_MANAGER_HIERARCHY.USER,
                        `${this.accountData?.addresses?.[0]?.zip.substring(0, 5)}${this.last4Digits[0]}`,
                    );
                },
                {
                    errorHandler: () => {
                        this.$showErrorAlert({
                            message: this.$i18n.t('alertMessage.userManagement.somethingWentWrongGettingOntStatus'),
                        });
                    },
                },
            );
        },
        fetchPaymentMethods() {
            this.$withProgressBar(
                async () => {
                    if (this.accountId) {
                        await this.getPaymentMethods(this.accountId);
                        if (this.paymentMethods.length) {
                            this.mapLast4Digits();
                        }
                    }
                },
                {
                    errorHandler: () => {
                        this.$showErrorAlert({
                            message: this.$i18n.t('alertMessage.somethingWentWrongFetchingNecessaryData'),
                        });
                    },
                },
            );
        },
        mapLast4Digits() {
            this.last4Digits = [...this.paymentMethods]
                .sort((a, b) => b.default - a.default)
                .map(({ default: isDefault, last_4: last4Digits }) => {
                    if (this.paymentMethods.length > 1 && isDefault) {
                        return `${last4Digits} (${this.$i18n.t('generic.default')})`;
                    }
                    return last4Digits;
                });
        },
    },
};
