/**
 * This file is used for creating draw2d custom elements,
 * which cover specific cases and can be used in
 * addition to default set of elements.
 */
import draw2d from 'draw2d';

const customs = {};

/**
 * @class
 *
 * Repositions a Figure attached to a Connection when the
 * Connection is moved. Provides for alignment at the start
 * (source), middle, or end (target) of the Connection.
 *
 * @extend draw2d.layout.locator.PortLocator
 */
customs.TopInputPortLocator = draw2d.layout.locator.PortLocator.extend(
    /** @lends draw2d.layout.locator.InputPortLocator.prototype */
    {
        NAME: 'draw2d.layout.locator.InputPortLocator',

        /**
         * Default constructor for a Locator which can layout a port in context of a
         * {@link draw2d.shape.node.Node}
         *
         */
        init: function init(attr, setter, getter) {
            // eslint-disable-next-line no-underscore-dangle
            this._super(attr, setter, getter);
        },

        /**
         *
         * Controls the location of an {@link draw2d.Figure}
         *
         * @param {Number} index port index of the figure
         * @param {draw2d.Figure} figure the figure to control
         *
         * @template
         * */
        relocate: function relocate(index, figure) {
            const node = figure.getParent();

            let dividerFactor = 1;
            const thisNAME = this.NAME;
            let portIndex = 1;
            node.getPorts().each((i, p) => {
                portIndex = p === figure ? dividerFactor : portIndex;
                dividerFactor += p.getLocator().NAME === thisNAME ? 1 : 0;
            });
            this.applyConsiderRotation(figure, (node.getWidth() / dividerFactor) * portIndex, 0);
        },
    },
);

window.customs = customs;
