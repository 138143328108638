<template>
    <CommonTemplateEditorWrapper
        :reversedKeys="reversedKeys"
        :writePermission="writePermission"
        :entityTypesOptions="entityTypesOptions"
        :isPresetEditor="false"
        :getTemplateById="getTemplateByIdObjects"
        :getApprovedTemplatesByType="getApprovedTemplatesByTypeObjects"
        :allTemplates="allTemplatesObjects"
        :listRouteName="listRouteName"
        :externalIdShowError="externalIdShowError"
        @update="onUpdate"
        @add="onAdd"
    />
</template>

<script>
// components
import CommonTemplateEditorWrapper from '@/__new__/features/template/CommonTemplateEditorWrapper.vue';
import { TEMPLATE_TYPES_MAP } from '@/__new__/services/dno/template/models/templateTypes';
import { policySpecificationReservedKeys } from '@/__new__/services/dno/template/models/entitiesReservedKeys';
import { TEMPLATE_STATES_NUMERIC } from '@/__new__/services/dno/template/models/templateStates';
import RouteNames from '@/router/routeNames';
import { ALERT_TYPES } from '@/common/alerts/Alert';
// vuex
import Actions, { Getters } from '@/store/mutation-types';
import { mapActions, mapGetters } from 'vuex';
// http
import { addTemplate, updateTemplate } from '@/__new__/services/dno/privacyConsent/http/templates';

export default {
    name: 'ConsentTemplateEditor',
    components: {
        CommonTemplateEditorWrapper,
    },
    data() {
        return {
            reversedKeys: policySpecificationReservedKeys,
            writePermission: 'PartyPrivacyProfileSpecificationWrite',
            entityTypesOptions: [
                {
                    label: this.$i18n.tc('consent.partyPrivacyProfileSpecification'),
                    key: TEMPLATE_TYPES_MAP.POLICY_SPECIFICATION,
                },
            ],
            listRouteName: RouteNames.CONSENT_TEMPLATE_LIST,
            externalIdShowError: false,
        };
    },
    computed: {
        getTemplateByIdObjects() {
            return (
                this.allTemplatesWithDeleted()?.reduce((acc, item) => {
                    acc[item.id] = item;
                    return acc;
                }, {}) || {}
            );
        },
        getApprovedTemplatesByTypeObjects() {
            return (
                this.allTemplatesWithDeleted()
                    ?.filter(template => template.state === TEMPLATE_STATES_NUMERIC.APPROVED)
                    ?.reduce((acc, item) => {
                        const { entityType } = item;
                        if (!acc[entityType]) {
                            acc[entityType] = [];
                        }
                        acc[entityType].push(item);
                        return acc;
                    }, {}) || {}
            );
        },
        allTemplatesObjects() {
            return (
                this.allTemplatesWithDeleted()?.filter(
                    template => template.state !== TEMPLATE_STATES_NUMERIC.ARCHIVED,
                ) || []
            );
        },
    },
    created() {
        this.$withLoadingSpinner(
            async () => {
                await this.requestAllTemplates();
            },
            {
                errorHandler: () => {
                    this.$eventBus.$emit('showAlert', {
                        message: this.$i18n.t('alertMessage.failedToLoadNecessaryData'),
                    });
                },
            },
        );
    },
    methods: {
        ...mapActions('privacy', {
            requestAllTemplates: Actions.PRIVACY_REQUEST_ALL_TEMPLATES,
        }),
        ...mapGetters('privacy', {
            allTemplatesWithDeleted: Getters.GET_PRIVACY_ALL_TEMPLATES,
        }),
        async onUpdate(id, entityType, templateData, updateTime) {
            await this.$withProgressBar(
                async () => {
                    await updateTemplate(id, entityType, templateData, updateTime);
                    this.$eventBus.$emit('showAlert', {
                        message: this.$i18n.t('alertMessage.successActionMessageWithRedirect', {
                            entityName: this.$i18n.tc('productCatalog.entities.template'),
                            action: this.$i18n.t('generic.updated'),
                            entityNameRedirect: this.$i18n.tc('productCatalog.entities.template').toLowerCase(),
                        }),
                        type: ALERT_TYPES.success,
                    });
                    setTimeout(() => {
                        this.$router.push({
                            name: this.listRouteName,
                            params: { companyId: this.$route.params.companyId },
                        });
                    }, 1000);
                },
                {
                    errorHandler: this.handleSaveError,
                },
            );
        },
        handleSaveError(error) {
            if (
                error?.response?.status === 400 &&
                error?.response?.data?.module === 'TEMPLATE' &&
                error?.response?.data?.code === 26
            ) {
                this.externalIdShowError = true;
                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('alertMessage.pc.externalIdUnique'),
                });
            } else {
                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('alertMessage.errorDoingSmthTryAgain', {
                        action: this.$i18n.t('generic.saving'),
                        entityName: this.$i18n.tc('productCatalog.entities.template').toLowerCase(),
                    }),
                });
            }
        },
        async onAdd(entityType, templateData) {
            await this.$withProgressBar(
                async () => {
                    await addTemplate(entityType, templateData);
                    this.$eventBus.$emit('showAlert', {
                        message: this.$i18n.t('alertMessage.successActionMessageWithRedirect', {
                            entityName: this.$i18n.tc('productCatalog.entities.template'),
                            action: this.$i18n.t('generic.created'),
                            entityNameRedirect: this.$i18n.tc('productCatalog.entities.template').toLowerCase(),
                        }),
                        type: ALERT_TYPES.success,
                    });
                    setTimeout(() => {
                        this.$router.push({
                            name: this.listRouteName,
                            params: { companyId: this.$route.params.companyId },
                        });
                    }, 1000);
                },
                {
                    errorHandler: this.handleSaveError,
                },
            );
        },
    },
};
</script>
