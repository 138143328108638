
// components
import AppInputV3 from '@/components/partials/inputs/AppInputV3.vue';
import AppMultiselectV3 from '@/components/partials/inputs/AppMultiselectV3.vue';
import AppToggle from '@/components/partials/inputs/AppToggle.vue';
import AppTextareaV3 from '@/components/partials/inputs/AppTextareaV3.vue';
// helpers
import isEmpty from 'lodash/isEmpty';
import { stringToSnakeCase } from '@/common/formatting';
import { maxLength, required } from 'vuelidate/lib/validators';
import { validateName } from '@/common/events/eventsHelper';
import EventProp, {
    CDP_PROPERTY_TYPES,
    CDP_PROPERTY_TYPES_INVERTED,
} from '@/__new__/services/dno/events/models/EventProp';
import { TOOLTIP_POSITION } from '@/common/tooltip';
import EventDataSource from '@/__new__/services/dno/events/models/EventDataSource';
import { TranslateResult } from 'vue-i18n';

export default {
    name: 'EventProperty',
    components: {
        AppInputV3,
        AppMultiselectV3,
        AppToggle,
        AppTextareaV3,
    },
    props: {
        property: {
            type: Object,
            required: true,
        },
        propertyTypes: {
            type: Array,
            required: true,
        },
        dataSources: {
            type: Array,
            required: true,
        },
    },

    data() {
        return {
            TOOLTIP_POSITION,
            CDP_PROPERTY_TYPES,
            CDP_PROPERTY_TYPES_INVERTED,
            propertyCopy: {},
            propertyTypesOptions: [],
        };
    },

    validations: {
        propertyCopy: {
            name: {
                required,
                validateName,
            },
            doc: {
                maxLength: maxLength(300),
            },
        },
    },

    computed: {
        propertyId(): string {
            return this.propertyCopy.id;
        },
        shouldDisableNullableToggler(): boolean {
            return (
                this.propertyCopy.forbiddenToEdit ||
                // nullable toggler is enabled only for primitive types like string\number\boolean, etc
                !CDP_PROPERTY_TYPES_INVERTED[this.propertyCopy.type] ||
                this.propertyCopy.type === CDP_PROPERTY_TYPES.MIXED
            );
        },
        isPropertyTypeDisabled(): boolean {
            return (
                this.propertyCopy.forbiddenToEdit ||
                !this.propertyCopy.type ||
                this.propertyTypesOptions.length === 1 ||
                this.propertyCopy.dataSource !== this.$i18n.t('generic.none')
            );
        },
        dataSourcesOptions(): string[] {
            return [this.$i18n.t('generic.none'), ...this.dataSources.map((ds: EventDataSource) => ds.label)];
        },
        nameErrorMessage(): TranslateResult | undefined {
            if (!this.$v.propertyCopy.name.validateName) {
                return this.$i18n.t('events.alerts.failedNameValidation');
            }
            if (!this.$v.propertyCopy.name.required) {
                return this.$i18n.t('generic.validations.fieldIsRequired');
            }
            return undefined;
        },
    },

    watch: {
        property: {
            immediate: true,
            handler(newValue: EventProp) {
                this.propertyCopy = { ...newValue };
            },
        },
        propertyId: {
            immediate: true,
            handler() {
                // update propertyTypes dropdown options,
                // when new event property is passed to component
                this.onDataSourceChange(this.propertyCopy.dataSource);
            },
        },
    },

    methods: {
        stringToSnakeCase,
        onDataSourceChange(dataSourceValue: string): void {
            this.propertyCopy.dataSource = dataSourceValue;
            const selectedDataSourceObj = this.dataSources.find((ds: EventDataSource) => ds.label === dataSourceValue);
            if (isEmpty(selectedDataSourceObj)) {
                this.propertyTypesOptions = this.propertyTypes;
            } else {
                this.propertyCopy.type = selectedDataSourceObj.fieldType;
                this.propertyTypesOptions = [selectedDataSourceObj.fieldType];
            }
            this.emitUpdateProperty();
        },
        onChange(prop: EventProp, value: string): void {
            this.$v.propertyCopy[prop].$touch();
            this.propertyCopy[prop] = value;
            this.emitUpdateProperty();
            // emit index if error (needs for highlighting tab)
            this.$emit('propertyHasError', {
                id: this.property.id,
                hasError: this.$v.$invalid,
            });
        },
        emitUpdateProperty(): void {
            this.$emit('updateProperty', this.propertyCopy);
        },
    },
};
