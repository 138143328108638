import { invert, uniqueId } from 'lodash';
import { TranslateResult } from 'vue-i18n';
import i18n from '@/i18n';
import Delay, { CampaignDelay } from '@/__new__/services/dno/campaigns/models/Delay';
import { MessageTypes } from '@/common/CampaignMessage';
import { CampaignType } from '@/__new__/services/dno/campaigns/models/Campaign';
import permissionsService from '@/services/permissions/permissions.service';

export const MessagesStates = {
    SENT: 0,
    RECEIVED: 1,
    DISPLAYED: 2,
    ACCEPTED: 3,
    IGNORED: 4,
    PROVISIONING_ACCEPTED: 5,
};

export const MessagesStatesToTranslateKeyMapping = {
    [MessagesStates.SENT]: 'sent',
    [MessagesStates.RECEIVED]: 'received',
    [MessagesStates.DISPLAYED]: 'displayed',
    [MessagesStates.ACCEPTED]: 'accepted',
    [MessagesStates.IGNORED]: 'ignored',
    [MessagesStates.PROVISIONING_ACCEPTED]: 'provisioningAccepted',
};

export const NotificationButtonActions = {
    Close: 'close',
    OpenLink: 'open_deep_link',
    OpenApp: 'open_app',
    OpenInbox: 'open_inbox',
};

export const NotificationButtonActionsToUI = {
    [NotificationButtonActions.Close]: 'Close popup',
    [NotificationButtonActions.OpenLink]: 'Open deep link / Open web link',
    [NotificationButtonActions.OpenApp]: 'Start app / Open app on playstore',
    [NotificationButtonActions.OpenInbox]: 'Open Inbox',
};

export const NotificationButtonActionsFromUI = invert(NotificationButtonActionsToUI);

const NotificationButtonActionsServerMapping = {
    [NotificationButtonActions.OpenLink]: 0,
    [NotificationButtonActions.Close]: 1,
    [NotificationButtonActions.OpenApp]: 2,
    [NotificationButtonActions.OpenInbox]: 3,
};

const NotificationButtonActionsFromAnyMapping = {
    ...invert(NotificationButtonActionsServerMapping),
    [NotificationButtonActions.OpenLink]: NotificationButtonActions.OpenLink,
    [NotificationButtonActions.Close]: NotificationButtonActions.Close,
    [NotificationButtonActions.OpenApp]: NotificationButtonActions.OpenApp,
    [NotificationButtonActions.OpenInbox]: NotificationButtonActions.OpenInbox,
};

export const EMAIL_SENDER_OPTIONS_MAPPING = {
    SENDER_ADDRESS_AND_NAME: 'email_sender_address_and_name',
    SENDER_ADDRESS: 'email_sender_address',
    SENDER_NAME: 'email_sender_name',
};

const MessageTypesServerMapping = {
    [MessageTypes.PUSH]: 'Push',
    [MessageTypes.SMS]: 'Sms',
    [MessageTypes.EMAIL]: 'Email',
    [MessageTypes.OTT]: 'Ott',
};

const MessageTypesFromServerMapping = invert(MessageTypesServerMapping);

export const MessageTypesWithRequiredTitle = [MessageTypes.PUSH, MessageTypes.EMAIL];

export const MessageTypesWithRequiredText = [MessageTypes.SMS, MessageTypes.OTT];

export const ExpiryOptions = {
    Days: 'Days',
};

export const ExpiryLabel = {
    NoExpiry: 'No expiry',
    Relative: 'Relative',
};

export type CampaignMessage = {
    name: string;
    notificationType: Record<string, any>;
    title: string;
    text: string;
    delay: CampaignDelay | null;
    openAction: string | null;
    deepLink: string | null;
    sender: string | null;
    emailSenderName: string | null;
    bccEmails: string[];
    enhancedPushView: string | null;
    enhancedOttView: string | null;
    ottExpiryTimeDays: number | null;
    backOfficeTargets: string[];
};

export default class Message {
    uniqueId: string;

    name: string;

    type: string;

    title: string;

    text: string;

    delay: Delay | null;

    // - push
    openAction: string | null;

    deepLink: string | null;

    // - sms
    smsSenderText: string | null;

    // - email
    sender: string | null;

    emailSenderName: string | null;

    bccEmails: string[] | null;

    // - end email

    enhancedViewTitle: string | null;
    enhancedViewMessage: string | null;
    enhancedViewMessageOtt: string | null;
    enhancedOttExpiryTimeDays: number | null;

    backOfficeTargets: string[] | null;

    emailQRProperty: string | null;
    enhancedViewMessagePushSummary: string | null;

    constructor(
        name = '',
        type: string = permissionsService.messageTypesAvailable()[0] || MessageTypes.PUSH,
        title = '',
        text = '',
        delay: Delay | null = null,
        openAction: string | null = null,
        deepLink: string | null = null,
        smsSenderText: string | null = null,
        sender: string | null = null,
        emailSenderName: string | null = null,
        bccEmails: string[] | null = null,
        enhancedViewTitle: string | null = null,
        enhancedViewMessage: string | null = null,
        enhancedViewMessageOtt: string | null = null,
        enhancedOttExpiryTimeDays: number | null = null,
        backOfficeTargets: string[] | null = null,
        emailQRProperty: string | null = null,
        enhancedViewMessagePushSummary: string | null = null,
    ) {
        this.uniqueId = uniqueId('message_'); // id for front-end purposes only
        this.name = name;
        this.title = title;
        this.type = type;
        this.text = text;
        this.delay = delay;
        this.openAction = openAction;
        this.deepLink = deepLink;
        this.smsSenderText = smsSenderText;
        this.sender = sender;
        this.emailSenderName = emailSenderName;
        this.bccEmails = bccEmails;
        this.enhancedViewTitle = enhancedViewTitle;
        this.enhancedViewMessage = enhancedViewMessage;
        this.enhancedViewMessageOtt = enhancedViewMessageOtt;
        this.enhancedOttExpiryTimeDays = enhancedOttExpiryTimeDays;
        this.backOfficeTargets = backOfficeTargets;
        this.emailQRProperty = emailQRProperty;
        this.enhancedViewMessagePushSummary = enhancedViewMessagePushSummary;
    }

    delaySeconds() {
        return this.delay ? this.delay.toSeconds() : 0;
    }

    buildJson() {
        const enhancedPushView = this.enhancedViewMessage
            ? {
                  title: this.enhancedViewTitle,
                  message: this.enhancedViewMessage,
                  summary: this.enhancedViewMessagePushSummary,
              }
            : null;
        const enhancedOttView = this.enhancedViewMessageOtt
            ? {
                  message: this.enhancedViewMessageOtt,
              }
            : null;

        const ottExpiryTimeDays = this.enhancedOttExpiryTimeDays;
        return {
            name: this.name,
            notificationType: { [MessageTypesServerMapping[this.type]]: {} },
            title: this.title,
            text: this.text,
            delay: this.delay ? this.delay.buildJson() : null,
            openAction: this.openAction ? NotificationButtonActionsServerMapping[this.openAction] : null,
            deepLink: this.deepLink,
            sender: this.getMessageSender(),
            emailSenderName: this.emailSenderName,
            bccEmails: this.bccEmails || {},
            enhancedPushView,
            enhancedOttView,
            ottExpiryTimeDays: ottExpiryTimeDays === undefined ? null : ottExpiryTimeDays,
            backOfficeTargets: this.backOfficeTargets || {},
            ...(!!this.emailQRProperty && {
                attachmentDataSource: {
                    field_name: this.emailQRProperty,
                },
            }),
        };
    }

    newUniqueId() {
        this.uniqueId = uniqueId('message_');
    }

    cloneWith(modifier: object) {
        return Object.assign(new Message(), { ...this }, modifier);
    }

    getMessageSender() {
        if (this.type === MessageTypes.EMAIL) {
            return this.sender;
        }
        return this.smsSenderText;
    }

    withName(name: string) {
        return this.cloneWith({ name });
    }

    withType(type: string) {
        return this.cloneWith({ type });
    }

    withTitle(title: string) {
        return this.cloneWith({ title });
    }

    withEmailSender(sender: string) {
        return this.cloneWith({ sender });
    }

    withEmailSenderName(emailSenderName: string) {
        return this.cloneWith({ emailSenderName });
    }

    withBccEmails(bccEmails: unknown) {
        return this.cloneWith({ bccEmails });
    }

    withOpenAction(openAction: unknown) {
        return this.cloneWith({ openAction });
    }

    withText(text: unknown) {
        return this.cloneWith({ text });
    }
    withEnhancedViewMessagePushSummary(enhancedViewMessagePushSummary: string) {
        return this.cloneWith({ enhancedViewMessagePushSummary });
    }

    withDeepLink(deepLink: unknown) {
        return this.cloneWith({ deepLink });
    }

    withDelay(delay: unknown) {
        return this.cloneWith({ delay });
    }

    withSMSSenderText(smsSenderText: unknown) {
        return this.cloneWith({ smsSenderText });
    }

    withEnhancedViewTitle(enhancedViewTitle: unknown) {
        return this.cloneWith({ enhancedViewTitle });
    }

    withEnhancedViewMessage(enhancedViewMessage: unknown) {
        return this.cloneWith({ enhancedViewMessage });
    }

    withEnhancedViewMessageOtt(enhancedViewMessageOtt: unknown) {
        return this.cloneWith({ enhancedViewMessageOtt });
    }

    withEnhancedOttExpiryTimeDays(enhancedOttExpiryTimeDays: unknown) {
        return this.cloneWith({ enhancedOttExpiryTimeDays });
    }

    withBackOfficeTargets(backOfficeTargets: unknown) {
        return this.cloneWith({ backOfficeTargets });
    }
    withEmailQRProperty(emailQRProperty: unknown) {
        return this.cloneWith({ emailQRProperty });
    }
    static contentTypeFromJson(json: any) {
        return Object.keys(json)[0];
    }

    static fromJson(json: Record<string, any>): Message {
        const messageType = json.type ?? MessageTypesFromServerMapping[Object.keys(json.notificationType)[0]];
        return new Message(
            json.name,
            messageType,
            json.title,
            json.text,
            json.delay ? Delay.fromJson(json.delay) : null,
            NotificationButtonActionsFromAnyMapping[json.openAction],
            json.deepLink,
            json.sender,
            json.sender,
            json.emailSenderName,
            json.bccEmails,
            json.enhancedPushView?.title ?? json.enhancedViewTitle,
            json.enhancedPushView?.message ?? json.enhancedViewMessage,
            json.enhancedOttView?.message ?? json.enhancedViewMessageOtt,
            json.ottExpiryTimeDays ?? json.enhancedOttExpiryTimeDays,
            json.backOfficeTargets,
            json.attachmentDataSource?.field_name || null,
            json.enhancedPushView?.summary || null,
        );
    }

    /**
     * @param message to validate
     * @param campaignType type of campaign this message belongs to
     * @returns validation result as an object with invalid field to alert message text.
     *  { [invalidField]: alertMessageText }
     *  May contain multiple fields
     *  Returns empty object {} if message is valid
     */
    static validateMessage(message: Message, campaignType: number): Record<string, TranslateResult> {
        const smsValidation: Record<string, TranslateResult> =
            message.type === MessageTypes.SMS ? Message.validateSMS(message, campaignType) : {};

        const emailValidation: Record<string, TranslateResult> =
            message.type === MessageTypes.EMAIL ? Message.validateEmail(message, campaignType) : {};

        const pushValidation: Record<string, TranslateResult> =
            message.type === MessageTypes.PUSH ? Message.validatePush(message) : {};

        const ottValidation: Record<string, TranslateResult> =
            message.type === MessageTypes.OTT ? Message.validateOtt(message) : {};

        // todo add rest of the types here
        return {
            ...smsValidation,
            ...emailValidation,
            ...pushValidation,
            ...ottValidation,
        };
    }

    static validatePush(message: Message): Record<string, TranslateResult> {
        const enhancedViewTitleValidation: Record<string, TranslateResult> =
            message.enhancedViewTitle === '' ? { enhancedViewTitle: i18n.t('campaigns.enhancedViewTitleInvalid') } : {};

        const enhancedViewMessageValidation: Record<string, TranslateResult> =
            message.enhancedViewMessage === ''
                ? { enhancedViewMessage: i18n.t('campaigns.enhancedViewMessageInvalid') }
                : {};

        return {
            ...enhancedViewTitleValidation,
            ...enhancedViewMessageValidation,
        };
    }

    static validateOtt(message: Message): Record<string, TranslateResult> {
        const enhancedViewMessageValidationOtt: Record<string, TranslateResult> =
            message.enhancedViewMessageOtt === ''
                ? { enhancedViewMessageOtt: i18n.t('campaigns.enhancedViewMessageInvalid') }
                : {};

        const enhancedViewOttExpiryTimeDaysValidation: Record<string, TranslateResult> =
            message.enhancedViewMessageOtt && message.enhancedOttExpiryTimeDays === undefined
                ? { enhancedOttExpiryTimeDays: i18n.t('campaigns.enhancedViewOttExpiryTimeDaysInvalid') }
                : {};

        return {
            ...enhancedViewMessageValidationOtt,
            ...enhancedViewOttExpiryTimeDaysValidation,
        };
    }

    static validateEmail(
        { bccEmails, backOfficeTargets }: Message,
        campaignType: number,
    ): Record<string, TranslateResult> {
        const emailRegExp = new RegExp(/^.+@.+\..+$/);
        const bccEmailValidation: Record<string, TranslateResult> = (bccEmails || []).find(
            email => !emailRegExp.test(email),
        )
            ? { bccEmails: i18n.t('campaigns.bccValidationMsg') }
            : {};

        const backOfficeTargetsValidation: Record<string, TranslateResult> =
            campaignType === CampaignType.BackOfficeCampaign &&
            ((backOfficeTargets || []).length === 0 ||
                (backOfficeTargets || []).find(target => !emailRegExp.test(target)))
                ? { backOfficeTargets: i18n.t('campaigns.backofficeTargetsValidationMsg') }
                : {};

        return {
            ...bccEmailValidation,
            ...backOfficeTargetsValidation,
        };
    }

    static validateSMS(
        { smsSenderText, backOfficeTargets }: Message,
        campaignType: number,
    ): Record<string, TranslateResult> {
        const smsSenderTextValidation: Record<string, TranslateResult> = !smsSenderText
            ? {
                  smsSenderText: i18n.t('campaigns.smsSenderTextInvalid'),
              }
            : {};

        const isEmptyBackofficeTargets = (backOfficeTargets || []).length === 0;
        const isNonValidBackofficeTargets = (backOfficeTargets || []).find(target => isNaN(Number(target)));

        const backofficeTargetsValidation: Record<string, TranslateResult> =
            campaignType === CampaignType.BackOfficeCampaign &&
            (isEmptyBackofficeTargets || isNonValidBackofficeTargets)
                ? { backOfficeTargets: i18n.t('campaigns.backofficeTargetsValidationMsg') }
                : {};

        return {
            ...smsSenderTextValidation,
            ...backofficeTargetsValidation,
        };
    }
}
