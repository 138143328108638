import parseConditions from '@/__new__/services/dno/charging/common/conditionsParser';
import * as Utils from '@/common/utils';
import { EntityBaseModel, EntityBaseDraftModel } from '@/__new__/services/dno/charging/models/EntityBaseModel';

export class ConditionParametersModel extends EntityBaseModel {
    id = Utils.uuidV4();
    conditionParameter = {};
    conditionType = '';
    availableStatuses = [];

    constructor(conditionParam, draftEntities = []) {
        super(conditionParam, draftEntities);
        this.id = conditionParam.id;
        this.conditionParameter = conditionParam.condition_parameter;
        this.conditionType = conditionParam.condition_parameter?.type;
        this.availableStatuses = conditionParam.available_statuses || [];
    }

    parseConditionsData(conditionModel) {
        const conditions = {
            parameters: {},
            original_conditions: [],
        };

        const conditionModelArr = !Array.isArray(conditionModel) ? [conditionModel] : conditionModel;

        if (conditionModelArr?.length) {
            conditions.original_conditions = conditionModelArr;
            conditions.parameters = parseConditions(conditionModelArr);
        }

        return conditions;
    }

    makeConditionParameterObj(data) {
        if (data?.parameters) {
            const conditionArr = Object.values(data?.parameters);
            return conditionArr.length > 0 ? conditionArr[0] : {};
        }

        return {};
    }
}

export class ConditionParametersDraft extends EntityBaseDraftModel {
    id = Utils.uuidV4();
    conditionParameter = {};
    conditionType = '';
    availableStatuses = [];

    constructor(conditionParam) {
        super(conditionParam);
        this.id = conditionParam.id;
        this.conditionParameter = conditionParam.condition_parameter;
        this.conditionType = conditionParam.condition_parameter?.type;
        this.availableStatuses = conditionParam.available_statuses || [];
    }
}

export default ConditionParametersModel;
