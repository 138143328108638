var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"deliveries-tile"},[(_vm.isTileForUserHierarchy)?_c('AssociatedUMEntityPicker',{attrs:{"requesterTargetType":_vm.USER_MANAGER_HIERARCHY.USER,"requesterTargetId":_vm.$route.params.id},model:{value:(_vm.selectedAssociatedAccount),callback:function ($$v) {_vm.selectedAssociatedAccount=$$v},expression:"selectedAssociatedAccount"}}):_vm._e(),_c('AbstractTableTile',{attrs:{"entities":_vm.deliveriesFormatted,"columnsData":_vm.columnsData,"apiResponse":_vm.apiResponse,"entityKey":"deliveryId"},on:{"rowSelected":el => [_vm.selectDelivery(el), _vm.toggleModal(true)]},scopedSlots:_vm._u([{key:"state",fn:function({ entity }){return [(!entity.status !== undefined)?_c('EntityStatusIndicator',{attrs:{"stateMap":_vm.DELIVERY_STATES_TO_STATUS_NAME_MAP,"stateMapColor":_vm.DELIVERY_STATUS_INDICATOR_MAP,"status":entity.status,"isInTable":true}}):_c('i',[_vm._v(_vm._s(_vm.$i18n.t('generic.empty')))])]}},{key:"customRowButtons",fn:function({ entity }){return [(_vm.isUserAllowed('UMAccountRequestReshipment'))?_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                    content: _vm.reshipmentButtonLabel(entity),
                    classes: ['dark'],
                    placement: _vm.TOOLTIP_POSITION.top,
                    trigger: 'hover',
                    autoHide: false,
                }),expression:"{\n                    content: reshipmentButtonLabel(entity),\n                    classes: ['dark'],\n                    placement: TOOLTIP_POSITION.top,\n                    trigger: 'hover',\n                    autoHide: false,\n                }"}]},[_c('IconButton',{attrs:{"label":_vm.$t('reshipment.pageTitle'),"icon":_vm.ICON_TYPES.REGENERATE,"disabled":!_vm.reshipmentAvailable(entity).result},on:{"iconClick":function($event){return _vm.createReshipment(entity.deliveryId)}}})],1):_vm._e()]}}])}),_c('AdditionalSidebarDeliveries',{attrs:{"selectedParentDelivery":_vm.selectedDelivery,"showAdditionalSidebar":_vm.showAdditionalSidebar},on:{"closeSidebar":function($event){return _vm.toggleModal(false)}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }