var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex flex-column"},[_c('div',{staticClass:"d-flex flex-row"},[_c('div',{staticClass:"tabs-header row no-gutters flex-grow-1"},_vm._l((_vm.tabsCount),function(tabIndex){return _c('div',{key:tabIndex,class:[
                    'tab-header px-3',
                    {
                        selected: _vm.selectedTabIndex === tabIndex,
                        disabled: _vm.disabledTabIndexes.includes(tabIndex),
                        col: _vm.isHeaderFullWidth,
                    },
                ],on:{"click":function($event){return _vm.selectTab(tabIndex)}}},[_vm._t(`tabHeader${tabIndex}`)],2)}),0),_c('div',{staticClass:"d-flex flex-row justify-content-end align-items-center"},[_vm._t(`tabHeaderButtons${_vm.selectedTabIndex}`)],2)]),_c('div',{staticClass:"tab-content flex-grow-1 d-flex flex-column"},_vm._l((_vm.tabsCount),function(tabIndex){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.selectedTabIndex === tabIndex),expression:"selectedTabIndex === tabIndex"}],key:tabIndex,staticClass:"tab flex-grow-1 flex-column"},[_c('div',{staticClass:"d-flex flex-column flex-grow-1 overview-container"},[_c('div',{staticClass:"sections-container d-flex flex-column flex-grow-1",class:{ 'px-3': !_vm.disableSectionPaddings }},_vm._l((_vm.sectionsCount),function(sectionIndex){return _c('div',{key:sectionIndex,staticClass:"section",class:{ 'mt-4': !_vm.disableSectionPaddings }},[_c('div',{staticClass:"section-header font-weight-bold text-uppercase",class:{ 'mb-2': _vm.enableHeaderMargin }},[_vm._t(`tab${tabIndex}_sectionHeader${sectionIndex}`)],2),_c('div',{staticClass:"d-flex section-content"},[_vm._t(`tab${tabIndex}_sectionContent${sectionIndex}`)],2)])}),0)])])}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }