import i18n from '@/i18n';
import {
    ConditionOption,
    ConditionConditionDataObject,
} from '@/__new__/services/dno/charging/common/conditionCommonHelper';

export enum DATA_BALANCE_OPERATOR {
    BIGGER_THAN = '>',
    BIGGER_OR_EQUALL_THAN = '>=',
    LESS_THAN = '<',
    LESS_OR_EQUALL_THAN = '<=',
}

export type DataBalanceOperatorOptions = ConditionOption<DATA_BALANCE_OPERATOR>;

export const MAP_DATA_BALANCE_OPERATOR_VALUES: Readonly<DataBalanceOperatorOptions[]> = [
    {
        id: DATA_BALANCE_OPERATOR.BIGGER_THAN,
        label: i18n.t('charging.chargingSpecifications.editor.conditions.dataBalance.biggerThan'),
    },
    {
        id: DATA_BALANCE_OPERATOR.BIGGER_OR_EQUALL_THAN,
        label: i18n.t('charging.chargingSpecifications.editor.conditions.dataBalance.biggerEquallThan'),
    },
    {
        id: DATA_BALANCE_OPERATOR.LESS_THAN,
        label: i18n.t('charging.chargingSpecifications.editor.conditions.dataBalance.lessThan'),
    },
    {
        id: DATA_BALANCE_OPERATOR.LESS_OR_EQUALL_THAN,
        label: i18n.t('charging.chargingSpecifications.editor.conditions.dataBalance.lessEquallThan'),
    },
];

export type DataBalanceCondition = {
    operator: DataBalanceOperatorOptions;
    value: number;
};

export type DataBalanceConditionArg = ConditionConditionDataObject<DataBalanceCondition>;
