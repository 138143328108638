
import Vue from 'vue';

// components
import AppIcon from '@/components/partials/icon/AppIcon.vue';

// helpers
import { ICON_COLORS, ICON_TYPES } from '@/common/iconHelper';

export default Vue.extend({
    name: 'AppLoader',
    components: { AppIcon },
    data() {
        return {
            icon: {
                type: ICON_TYPES.LOADER,
                color: ICON_COLORS.BLUE,
            },
        };
    },
});
