import { AxiosPromise } from 'axios';
import http, { DnoResponseBase, Entity } from '@/http/index';
import isArray from 'lodash/isArray';
import { getDnoClaimsHeader } from '@/__new__/features/customerCare/common/customerCareHelper';

type GetSignedUrlData = {
    fileName: string;
    uuid: string;
    voucherSetId: string;
};

type SetDraftData = {
    id?: string;
    data: object;
    template_id: string;
};

type VoucherSetData = {
    name: string | Record<string, string>;
    voucher_type: string;
    voucher_settings: object;
} & Record<string, any>;

type VoucherCodeData = {
    voucher_code: string;
    created_time?: string;
    assigned_timestamp?: string;
    usage_timestamp?: string;
} & Record<string, any>;

export type VoucherSet = Entity<VoucherSetData>;

type getVoucherSetsResponseBody = DnoResponseBase & {
    voucher_set_by_id: Record<string, VoucherSet>;
};

type getVoucherCodesResponseBody = DnoResponseBase & {
    voucher_codes: VoucherCodeData[];
    paging_state?: string;
};

/**
 * Proxy call to /api/v3/vouchers/get_user_voucher_codes
 * @param targetId
 * @param targetType
 */
export const getUserVoucherCodes = async (targetId: string, targetType: number, getFullResponse = false) => {
    const response = await http({
        method: 'POST',
        url: '/v3vouchers/getuservouchercodes',
        headers: {
            'Dno-Claims': JSON.stringify(getDnoClaimsHeader(targetId, targetType)),
        },
        data: {
            target_id: targetId,
            target_type: targetType,
        },
    });

    return getFullResponse ? response : response?.data?.voucher_codes;
};

/**
 * Get voucher sets.
 *
 * @returns {AxiosPromise}
 */
export function getVoucherSets(ids: string[] = []): AxiosPromise<getVoucherSetsResponseBody> {
    let data = {};
    if (ids.length && isArray(ids)) {
        data = {
            ids,
        };
    }
    return http({
        method: 'POST',
        url: '/v3vouchers/getvouchersets',
        data,
    });
}

/**
 * Add voucher set.
 *
 * @returns {AxiosPromise}
 */
export function addVoucherSet(data: VoucherSetData): AxiosPromise<DnoResponseBase> {
    return http({
        method: 'POST',
        url: '/v3voucherswrite/addvoucherset',
        data,
    });
}

export function generateVoucherCodes(
    voucher_set_id: string,
    voucher_codes_number: number,
): AxiosPromise<DnoResponseBase> {
    return http({
        method: 'POST',
        url: '/v3voucherswrite/generatevouchercodes',
        data: {
            voucher_set_id,
            voucher_codes_number,
        },
    });
}

export function getVoucherCodes(
    voucher_set_id: string,
    page_size: number,
    paging_state?: string,
): AxiosPromise<getVoucherCodesResponseBody> {
    const data: {
        voucher_set_id: string;
        page_size: number;
        paging_state?: string;
    } = {
        voucher_set_id,
        page_size,
    };
    if (paging_state) {
        data.paging_state = paging_state;
    }

    return http({
        method: 'POST',
        url: '/v3vouchers/getvouchercodes',
        data: data,
    });
}

/**
 * Update voucher set.
 *
 * @returns {AxiosPromise}
 */
export function updateVoucherSet(id: string, version: number, data: VoucherSetData): AxiosPromise<DnoResponseBase> {
    return http({
        method: 'POST',
        url: '/v3voucherswrite/updatevoucherset',
        data: {
            id,
            version,
            data,
        },
    });
}

/**
 * Update voucher set state.
 *
 * @returns {AxiosPromise}
 */
export function updatestateVoucherSet(id: string, version: number, state: number): AxiosPromise<DnoResponseBase> {
    return http({
        method: 'POST',
        url: '/v3voucherswrite/updatestatevoucherset',
        data: {
            id,
            version,
            state,
        },
    });
}

/**
 * @data uuid
 * @data voucherSetId
 * @data fileName
 */
export function getSignedURL(data: GetSignedUrlData): AxiosPromise<any> {
    return http({
        method: 'POST',
        url: '/v3voucherswrite/signedurl',
        headers: {
            'Content-type': 'application/json',
        },
        data,
    });
}

/**
 * @param page
 * @param size
 * @returns {AxiosPromise}
 */
export function getRegisteredEntities(page: number, size: number): AxiosPromise<any> {
    return http({
        method: 'GET',
        url: '/v3voucherswrite/registeredentities',
        headers: {
            'Content-type': 'application/json',
        },
        params: { page, size },
    });
}

/**
 * @param name
 * @returns {AxiosPromise}
 */
export function getEntityDetailsByName(name: string): AxiosPromise<any> {
    return http({
        method: 'GET',
        url: '/v3voucherswrite/entitydetailsbyname',
        headers: {
            'Content-type': 'application/json',
        },
        params: { name },
    });
}

/**
 * @param name
 * @param category
 * @param page
 * @param size
 * @returns {AxiosPromise}
 */
export function getEntityDetailsByCategory(
    name: string,
    category: string,
    page: number,
    size: number,
): AxiosPromise<any> {
    return http({
        method: 'GET',
        url: '/v3voucherswrite/entitydetailsbycategory',
        headers: {
            'Content-type': 'application/json',
        },
        params: {
            name,
            category,
            page,
            size,
        },
    });
}

/**
 * @returns {AxiosPromise}
 */
export function getAllNamespaces(): AxiosPromise<DnoResponseBase> {
    return http({
        method: 'POST',
        url: '/v3voucherswrite/getallnamespaces',
        headers: {
            'Content-type': 'application/json',
        },
        data: {},
    });
}

export function createNamespace(namespace_id: string, name: string): AxiosPromise<DnoResponseBase> {
    return http({
        method: 'POST',
        url: '/v3voucherswrite/createnamespace',
        headers: {
            'Content-type': 'application/json',
        },
        data: {
            namespace_id,
            name,
        },
    });
}

/**
 * Get Voucher Set list.
 *
 * @returns {AxiosPromise}
 */
export function getVoucherSetDraftList(): AxiosPromise<DnoResponseBase> {
    return http({
        method: 'POST',
        url: '/v3vouchers/getvouchersetdraftlist',
    });
}

/**
 * Delete Voucher Set draft.
 *
 * @returns {AxiosPromise}
 */
export function deleteVoucherSetDraft(id: string): AxiosPromise<DnoResponseBase> {
    return http({
        method: 'POST',
        url: '/v3voucherswrite/deletevouchersetdraft',
        data: {
            id,
        },
    });
}

/**
 * Get Voucher Set draft.
 *
 * @returns {AxiosPromise}
 */
export function getVoucherSetDraft(id: string): AxiosPromise<DnoResponseBase> {
    return http({
        method: 'POST',
        url: '/v3vouchers/getvouchersetdraft',
        data: {
            id,
        },
    });
}

/**
 * Set Voucher Set draft.
 *
 * @returns {AxiosPromise}
 */
export function setVoucherSetDraft(data: SetDraftData): AxiosPromise<DnoResponseBase> {
    return http({
        method: 'POST',
        url: '/v3voucherswrite/setvouchersetdraft',
        data,
    });
}

/**
 * Get Voucher Categories
 *
 * @returns {AxiosPromise}
 */
export function getVoucherCategories(): AxiosPromise<DnoResponseBase> {
    return http({
        method: 'POST',
        url: '/v3vouchers/getvouchercategories',
    });
}

/**
 * Add Voucher Category
 *
 * @returns {AxiosPromise}
 */
export function addCategory(data: unknown): AxiosPromise<DnoResponseBase> {
    return http({
        method: 'POST',
        url: '/v3vouchers/addvouchercategory',
        data,
    });
}

export default {
    getUserVoucherCodes,
};
