
// Vue components
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import AppDialogV2 from '@/components/partials/AppDialogV2.vue';
import AppTextareaV3 from '@/components/partials/inputs/AppTextareaV3.vue';

// Helpers & Misc
import * as Sentry from '@sentry/vue';
import { ICON_TYPES } from '@/common/iconHelper';
import { ALERT_TYPES } from '@/common/alerts/Alert';

// Http
import { callbackExecution } from '@/__new__/services/dno/orchestrationengine/http/orchestration-engine';

// Validation
import { required } from 'vuelidate/lib/validators';

export default {
    name: 'CallbackExecutionModal',

    components: {
        AppButton,
        AppDialogV2,
        AppTextareaV3,
    },

    props: {
        visible: {
            required: true,
            type: Boolean,
        },
        writeEnabled: {
            type: Boolean,
            default: false,
        },
        executionId: {
            type: String,
            default: '',
        },
    },

    data() {
        return {
            // Proxy imports
            ICON_TYPES,
            BUTTON_TYPES,

            input: '' as string,
            callbackButtonClicked: false as boolean,
        };
    },

    validations: {
        input: {
            isJsonValid: value => {
                try {
                    if (value) {
                        JSON.parse(value);
                    }
                    return true;
                } catch (e) {
                    return false;
                }
            },
            required,
        },
    },

    methods: {
        async onCallbackExecution(): Promise<any> {
            this.$v.$touch();

            if (!this.$v.$invalid) {
                try {
                    this.$Progress.start();
                    this.callbackButtonClicked = true;

                    let data = { execution_id: this.executionId };

                    if (this.input) {
                        const input = JSON.parse(this.input);

                        if (Object.keys(input).length) {
                            data = { ...data, ...input };
                        }
                    }

                    await callbackExecution(data);

                    this.$Progress.finish();

                    this.$eventBus.$emit('showAlert', {
                        message: this.$i18n.t('orchestrationEngine.callbackExecutionSuccess'),
                        type: ALERT_TYPES.success,
                    });

                    this.$emit('onCallbackExecution');
                } catch (error) {
                    Sentry.captureException(error);
                    this.$Progress.fail();

                    this.$eventBus.$emit('showAlert', {
                        message: this.$i18n.t('orchestrationEngine.callbackExecutionFail'),
                    });
                } finally {
                    this.callbackButtonClicked = false;
                }
            }
        },
    },
};
