<template>
    <div class="cohort-expression">
        <AppMultiselectV3
            v-model="cohortsObj.applicableCohorts"
            data-test-id="cohort-whitelist-select"
            :options="whiteCohortGroupsOptions"
            groupLabel="groupName"
            groupValues="groupValues"
            :showLabels="false"
            :multiple="true"
            :additionalLabel="$i18n.t('editors.applicableTo')"
            :placeholder="$i18n.t('generic.cohorts.chooseCohortGroup')"
            :small="true"
            :disabled="disabled"
            label="name"
            trackBy="id"
            class="mb-2"
            :style="{ width: `${inputsWidth}%` }"
            @input="onInput"
        />

        <AppToggle
            v-model="cohortsObj.everyApplicableCohort"
            data-test-id="cohort-whitelist-logical"
            :label="$i18n.t('editors.identifiersInEverySegment')"
            :small="true"
            :disabled="disabled"
            class="mb-3"
            @input="onInput"
        />

        <AppMultiselectV3
            v-model="cohortsObj.notApplicableCohorts"
            data-test-id="cohort-blacklist-select"
            :options="blackCohortGroupsOptions"
            groupLabel="groupName"
            groupValues="groupValues"
            :showLabels="false"
            :multiple="true"
            :additionalLabel="$i18n.t('editors.notApplicableTo')"
            :placeholder="$i18n.t('generic.cohorts.chooseCohortGroup')"
            :small="true"
            :disabled="disabled"
            label="name"
            trackBy="id"
            class="mb-2"
            :style="{ width: `${inputsWidth}%` }"
            @input="onInput"
        />

        <AppToggle
            v-model="cohortsObj.everyNotApplicableCohort"
            data-test-id="cohort-blacklist-logical"
            :label="$i18n.t('editors.identifiersInEverySegment')"
            :explanationText="`${$i18n.t('editors.subscriberHasBothSegments')}
                    ${$i18n.tc('productCatalog.entities.offer').toLowerCase()}.`"
            :small="true"
            :tooltipOffset="tooltipOffset"
            :disabled="disabled"
            class="mb-3"
            @input="onInput"
        />
    </div>
</template>

<script>
import AppMultiselectV3 from '@/components/partials/inputs/AppMultiselectV3.vue';
import AppToggle from '@/components/partials/inputs/AppToggle.vue';
import { Modules } from '@/store/store';
import { mapGetters } from 'vuex';
import { Getters } from '@/store/mutation-types';
import { cloneDeep } from 'lodash';
import { STATUS_CODES_OPERATIONS } from '@/common/commonHelper';

export default {
    name: 'CohortExpression',
    components: {
        AppMultiselectV3,
        AppToggle,
    },
    props: {
        value: {
            type: Object,
            default: null,
        },
        customCohortGroupsOptions: {
            type: Array,
            default: () => [],
        },
        customCohortGroupsOptionsBL: {
            type: Array,
            default: () => [],
        },
        inputsWidth: {
            type: Number,
            default: 60,
            validator: val => val >= 0 && val <= 100,
        },
        tooltipOffset: {
            type: Number,
            default: 20,
            validator: val => val >= 0 && val <= 100,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            cohortsObj: {
                applicableCohorts: [],
                notApplicableCohorts: [],
                everyApplicableCohort: false,
                everyNotApplicableCohort: false,
            },
        };
    },
    computed: {
        ...mapGetters(Modules.segments, {
            segments: Getters.CACHED_SEGMENTS,
            groupedSegments: Getters.GROUPED_SEGMENTS_BY_ID_TYPE,
        }),
        whiteCohortGroupsOptions() {
            return this.customCohortGroupsOptions?.length
                ? this.filterGroupValues(this.customCohortGroupsOptions)
                : this.filterGroupValues(this.groupedSegments);
        },
        blackCohortGroupsOptions() {
            return this.customCohortGroupsOptionsBL?.length
                ? this.filterGroupValues(this.customCohortGroupsOptionsBL)
                : this.whiteCohortGroupsOptions;
        },
    },
    watch: {
        value: {
            immediate: true,
            handler() {
                if (this.value) {
                    this.cohortsObj = cloneDeep(this.value);
                }
            },
        },
    },
    methods: {
        onInput() {
            this.$emit('input', this.cohortsObj);
        },
        filterGroupValues(options) {
            return options.map(option => ({
                ...option,
                groupValues: (option.groupValues || []).filter(
                    ({ operation }) => operation !== STATUS_CODES_OPERATIONS.STOPPED,
                ),
            }));
        },
    },
};
</script>
