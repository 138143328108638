// from:
// "cd7d212f-9ce4-4b86-ad8b-15009479c89f": {
//     "values": [
//         "4G"
//     ],
//     "type": "bearer"
// },
// to:
// {
//     "data": [
//         "4G"
//     ],
//     "uuid": "cd7d212f-9ce4-4b86-ad8b-15009479c89f",
//     "type": "bearer"
// },

export default (id, pcObject) => ({
    type: 'bearer',
    data: [...pcObject.values],
    uuid: id,
});
