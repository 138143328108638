// See Swagger Yaml for documentation:
// https://github.com/lotusflare/lua/blob/master/services_root/services/pricing/resources/swagger/PricingManagement.yaml

import ENTITY_TYPES from '@/common/entities/entityTypes';
import { LocalizedString } from '@/http';

/** Condition Types Start */
export type Condition = LogicalCondition | ComparisonCondition | PredefinedFeeActionCondition;

export interface LogicalCondition {
    type: LOGICAL_TYPE;
    op: LOGICAL_OP;
    args: LogicalArgs;
}

export enum LOGICAL_TYPE {
    LOGICAL = 'LOGICAL',
}

export enum LOGICAL_OP {
    AND = 'AND',
    OR = 'OR',
    NOT = 'NOT',
}

export interface LogicalArgs {
    nested_conditions: Condition[];
}

export interface ComparisonCondition {
    type: COMPARISON_TYPE;
    op: COMPARISON_OP;
    args: ComparisonArgs;
}

export enum COMPARISON_TYPE {
    COMPARISON = 'COMPARISON',
}

export enum COMPARISON_OP {
    EQ = 'EQ',
    NOT_EQ = 'NOT_EQ',
    LT = 'LT',
    LT_OR_EQ = 'LT_OR_EQ',
    GT = 'GT',
    GT_OR_EQ = 'GT_OR_EQ',
    MATCHES = 'MATCHES',
    ARE_DISJOINT = 'ARE_DISJOINT',
    INTERSECTS = 'INTERSECTS',
    IS_SUBSET_OF = 'IS_SUBSET_OF',
    IS_SUPERSET_OF = 'IS_SUPERSET_OF',
}

export interface ComparisonArgs {
    data_type: COMPARISON_DATA_TYPE;
    key: string;
    value: any;
}

export enum COMPARISON_DATA_TYPE {
    NATIVE = 0,
    OFFER = 2,
}

/**
 * ComparisonCondition example:
    {
        "type": "COMPARISON",
        "args": {
            "data_type": 0,
            "key": "item_context.recurrence_counter",
            "value": 1
        },
        "op": "GT"
    },
 */

export interface PredefinedFeeActionCondition {
    type: COMPARISON_TYPE.COMPARISON;
    op: COMPARISON_OP.IS_SUBSET_OF;
    args: {
        key: 'item_context.offer_id';
        value: Array<string>;
        data_type: COMPARISON_DATA_TYPE.OFFER;
    };
}

/**
 * PredefinedFeeActionCondition example:
    {
        "type": "COMPARISON",
        "op": "IS_SUBSET_OF",
        "args": {
            "data_type": 2,
            "key": "item_context.offer_id",
            "value": [ "f76b61033bf44df8b5c361c42691e951" ]
        }
    }
 */

/** Condition Types End */

/** Action Types Start */

export interface ActionNative {
    action_configuration_type: ACTION_CONFIGURATION_TYPE.NATIVE;
    action_type: ACTION_TYPE;
    component_description?: string;
    component_type: COMPONENT_TYPE;
    component_subtype: string;
    expression: string;
}

export enum ACTION_TYPE {
    APPEND_COMPONENT = 1,
    UPDATE_COMPONENT = 2,
}

export enum COMPONENT_TYPE {
    FEE = 'fee',
}

/**
 * ActionNative example:
    {
        "component_description": "Custom Early Termination Fee",
        "action_configuration_type": 1,
        "action_type": 1,
        "expression": "300",
        "component_type": "fee",
        "component_subtype": "fee_subtype"
    }
 */

export interface ActionPredefinedFee {
    component_description: string;
    action_configuration_type: ACTION_CONFIGURATION_TYPE;
    predefined_rule_params: PredefinedRuleParams;
}

export enum ACTION_CONFIGURATION_TYPE {
    NATIVE = 1,
    LATE_PAYMENT = 2,
    EARLY_TERMINATION = 3,
}

export interface PredefinedRuleParams {
    proration_method: PRORATION_METHOD;
    proration_unit?: PRORATION_UNIT;
    amount_calculation_method: AMOUNT_CALCULATION_METHOD;
    amount: string;
}

export enum PRORATION_METHOD {
    NONE = 1,
    DURATION = 2,
}

export enum PRORATION_UNIT {
    DAY = 1,
    WEEK = 2,
    MONTH = 3,
    YEAR = 4,
}

export enum AMOUNT_CALCULATION_METHOD {
    FIXED = 1,
    PERCENT = 2,
}

/**
 * ActionPredefinedFee example:
    {
        "component_description": "Late Payment Fee",
        "action_configuration_type": 2,
        "predefined_rule_params": {
        "proration_method": 2,
        "proration_unit": 3,
        "amount_calculation_method": 2,
        "amount": "5.00"
        }
    }
 */

/** Action Types End */

export const enum EXECUTE_PHASE_TYPE {
    ORIGIN = 'origin',
    DISCOUNT = 'discount',
    PRORATION = 'proration',
    FEE = 'fee',
    TAX = 'tax',
    TOTAL = 'total',
}

export enum EXPRESSION_TYPE {
    FIXED_VALUE = 1,
    EXPRESSION = 2,
}

export interface Selector {
    variable_name: string;
    variable_type: string;
    condition: Condition;
}

export interface Preprocessor {
    variable_name: string;
    variable_type: string;
    expression: string;
    expression_type: EXPRESSION_TYPE;
}

export interface FeeRule {
    actions: Array<ActionNative | ActionPredefinedFee>;
    conditions?: Condition;
    description: LocalizedString;
    name: LocalizedString;
    scope: Scope;
}

export type Scope = TotalScope | LineItemScope;

export interface TotalScope {
    level: SCOPE_LEVEL.TOTAL_LEVEL;
}

interface LineItemScope {
    level: SCOPE_LEVEL.LINE_ITEM_LEVEL;
    applicable_line_item_offer_ids: string[];
}

export enum SCOPE_LEVEL {
    LINE_ITEM_LEVEL = 1,
    TOTAL_LEVEL = 2,
}

/**
 * Pricing Rule Structure
 */

export interface PricingRule {
    description: LocalizedString;
    name: LocalizedString;
    schema_type: SCHEMA_TYPE;
    misc?: object;
    execute_phase_type?: EXECUTE_PHASE_TYPE;
    conditions: PricingRuleCondition;
    actions: PricingRuleAction[];
    simple_view?: SimpleViewData;
    priority: number;
    cohort_expression?: object;
}

export interface SimplePricingRule {
    description: LocalizedString;
    name: LocalizedString;
    schema_type: SCHEMA_TYPE.SIMPLE;
    simple_view: SimpleViewData;
    misc?: object;
    priority: number;
    cohort_expression?: object;
}

export enum SCHEMA_TYPE {
    SIMPLE = 1,
    NORMAL = 2,
}

export interface SimpleViewData {
    line_items: {
        entity_type: ENTITY_TYPES.OFFER | ENTITY_TYPES.CATEGORY;
        entity_ids: string[];
        all_entity_ids_required: boolean;
        quantity?: number;
        quantity_op?: COMPARISON_OP;
    };
    minimum_number_of_line_items_and_existing_items?: number;
    spending_condition?: {
        amount: number;
        op: COMPARISON_OP;
        target: TARGET;
    };
    discount: {
        target: TARGET;
        target_entity_type?: ENTITY_TYPES.OFFER | ENTITY_TYPES.CATEGORY;
        target_entity_ids?: string[];
        amount: number;
        amount_type: AMOUNT_CALCULATION_METHOD;
        inc_amount?: number;
        inc_amount_type?: AMOUNT_CALCULATION_METHOD;
        multiplier?: MULTIPLIER;
    };
}

export enum TARGET {
    TOTAL_BEFORE_TAX = 'TOTAL_PRICE_BEFORE_TAX',
    LINE_ITEMS_BEFORE_TAX = 'LINE_ITEM_PRICE_BEFORE_TAX',
}

export enum MULTIPLIER {
    NUMBER_OF_LINE_ITEMS = 'NUMBER_OF_LINE_ITEMS',
    NUMBER_OF_EXISTING_ITEMS = 'NUMBER_OF_EXISTING_ITEMS',
    NUMBER_OF_LINE_AND_EXISTING_ITEMS = 'NUMBER_OF_LINE_AND_EXISTING_ITEMS',
    NUMBER_OF_ADDITIONAL_LINE_ITEMS = 'NUMBER_OF_ADDITIONAL_LINE_ITEMS',
    NUMBER_OF_ADDITIONAL_EXISTING_ITEMS = 'NUMBER_OF_ADDITIONAL_EXISTING_ITEMS',
    NUMBER_OF_ADDITIONAL_LINE_AND_EXISTING_ITEMS = 'NUMBER_OF_ADDITIONAL_LINE_AND_EXISTING_ITEMS',
    NUMBER_OF_N_AMOUNT_SPENT = 'NUMBER_OF_N_AMOUNT_SPENT',
    NUMBER_OF_LINE_ITEMS_LESS_EXISTING_ITEMS = 'NUMBER_OF_LINE_ITEMS_LESS_EXISTING_ITEMS',
}

export interface PricingRuleCondition {
    selectors: Selector[];
    preprocessors: Preprocessor[];
    condition: Condition;
}

export interface PricingRuleAction {
    adjust_level: SCOPE_LEVEL;
    action_selector?: Selector;
    adjustment: Adjustment;
}

export interface Adjustment {
    expression_type: EXPRESSION_TYPE;
    expression: string;
    action_type: ACTION_TYPE;
    component_type: PRICING_COMPONENT_TYPE;
    component_subtype: string;
}

export enum PRICING_COMPONENT_TYPE {
    ORIGIN = 'origin',
    FEE = 'fee',
    TAX = 'tax',
    DISCOUNT = 'discount',
    PRORATION = 'proration',
}
