<template>
    <div class="h-100">
        <AbstractListPageWrapper
            :pageTitle="$i18n.t('rewards.lottery.lottery')"
            :isOverviewEnabled="isOverviewEnabled"
            :entitiesCount="sortFilterLotteries.length"
            @searchQueryChanged="setSearchQuery"
        >
            <template #modal>
                <LotteryWinnersModal
                    v-if="isWinnersModalVisible"
                    :lottery="selectedEntity"
                    @close="onCloseWinnersModal"
                    @cancel="onCancelWinnersModal"
                />
            </template>

            <template #button>
                <ResponseModalButton
                    :response="lotteriesApiResponse"
                    :title="$i18n.t('rewards.lottery.lottery')"
                />
            </template>

            <template #filterTable>
                <FilterTable
                    :columns="tableColumnsData"
                    @filterAdded="onFilterAdded"
                />
            </template>

            <template #headerButtons>
                <AppButton
                    v-if="permissionsService.lotteryEnabled"
                    :buttonType="BUTTON_TYPES.PRIMARY"
                    :iconType="ICON_TYPES.PLUS"
                    :label="$i18n.t('rewards.lottery.newLottery')"
                    :disabled="!writeEnabled"
                    data-test-id="create-btn"
                    @click="onCreate"
                />
            </template>

            <template #allFilters>
                <TableFiltersTags
                    :filterTableMixin="filterTableMixin"
                    class="my-3 ml-2"
                    @removeFilter="removeFilter"
                    @removeAllFilters="removeAllFilters"
                />
            </template>

            <template #table>
                <AppTable
                    :entities="sortFilterLotteries"
                    :selectedEntityId="selectedEntityId"
                    :innerSearchQuery="searchQueryForTable"
                    :isSearchEnabled="true"
                    :columnsData="tableColumnsData"
                    :isDataLoading="isDataLoading"
                    :entityType="ENTITY_TYPES.LOTTERY"
                    :enableRowStateControls="writeEnabled"
                    tableKey="lottery/lotteries"
                    data-test-id="app-table"
                    @selectEntity="onSelect"
                >
                    <template #customRowButtons="{ entity }">
                        <IconButton
                            v-if="drawButtonVisibility(entity)"
                            :label="$i18n.t('rewards.lottery.drawLottery')"
                            :icon="ICON_TYPES.START"
                            data-test-id="draw-btn"
                            @iconClick="onStart(entity.id)"
                        />
                    </template>
                    <template #state="{ entity }">
                        <EntityStatusIndicator :status="entity.state" />
                    </template>
                    <template #entityVersion="{ entity }">
                        <EntityStatusIndicator :status="entity.entityVersion" />
                    </template>
                </AppTable>
            </template>

            <template #overview>
                <EntityOverview
                    :entity="selectedEntity"
                    :entityType="ENTITY_TYPES.LOTTERY"
                    @closeOverview="isOverviewEnabled = false"
                >
                    <template #section-1-content>
                        <div>
                            <div
                                v-if="selectedEntityId"
                                class="description-highlight"
                            >
                                <div class="heading-sm mb-3">
                                    {{ $i18n.t('generic.description') }}
                                </div>
                                <div class="description-info body-sm">
                                    {{ descriptionAvailable }}
                                </div>
                            </div>
                            <OverviewList
                                :lists="detailsSection"
                                :isNewDesignSelected="true"
                            />
                            <AppButton
                                v-if="winnersVisibility"
                                :iconType="ICON_TYPES.PLUS"
                                :label="$i18n.t('rewards.lottery.setWinners')"
                                class="pl-0"
                                data-test-id="set-winners-btn"
                                @click="onEditWinners"
                            />
                        </div>
                    </template>
                </EntityOverview>
            </template>
        </AbstractListPageWrapper>
    </div>
</template>

<script>
// Vuex
import { mapActions, mapGetters } from 'vuex';
import Actions, { Getters } from '@/store/mutation-types';

// Generic Vue Components
import AppTable from '@/components/partials/AppTable.vue';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import { ICON_TYPES } from '@/common/iconHelper';
import AbstractListPageWrapper from '@/components/layout/AbstractListPageWrapper.vue';
import EntityOverview from '@/components/partials/entityOverview/EntityOverview.vue';
import OverviewList from '@/components/partials/entityOverview/OverviewList.vue';
import EntityStatusIndicator from '@/components/partials/EntityStatusIndicator.vue';
import FilterTable from '@/components/partials/FilterTable.vue';
import FilterTableMixin from '@/components/partials/FilterTableMixin.vue';
import TableFiltersTags from '@/components/filters/TableFiltersTags.vue';
import ResponseModalButton from '@/components/partials/ResponseModalButton.vue';
import LotteryWinnersModal from '@/modules/rewards/views/LotteryWinnersModal.vue';
import IconButton from '@/components/partials/IconButton.vue';
import Button from '@/common/button/Button';

// Helpers
import RouteNames from '@/router/routeNames';
import ENTITY_TYPES from '@/common/entities/entityTypes';
import { onlyFirstLetterUppercase } from '@/common/utils';
import { entityStateReverseMap, StateActionsMapping } from '@/modules/rewards/common/entityStateMapper';
import tableColumnType from '@/common/filterTable';
import permissionsService, { isUserAllowed } from '@/services/permissions/permissions.service';
import { STATUS_CODES } from '@/common/commonHelper';
import * as Sentry from '@sentry/vue';

import { drawLottery } from '@/__new__/services/dno/rewards/http/rewards';
import { LOTTERY_PHASE } from '@/__new__/services/dno/rewards/models/LotteryModelHelper';
import { ALERT_TYPES } from '@/common/alerts/Alert';

export default {
    name: 'Lotteries',

    components: {
        AbstractListPageWrapper,
        AppTable,
        AppButton,
        EntityOverview,
        OverviewList,
        EntityStatusIndicator,
        FilterTable,
        TableFiltersTags,
        ResponseModalButton,
        LotteryWinnersModal,
        IconButton,
    },

    mixins: [FilterTableMixin],

    data() {
        return {
            columns: [],
            selectedEntityId: null,
            selectedEntity: null,
            searchQueryForTable: '',
            isDataLoading: false,
            selectedLanguage: '',
            permissionsService,
            ICON_TYPES,
            BUTTON_TYPES,
            ENTITY_TYPES,
            isOverviewEnabled: false,
            isRewardsDraftEnabled: permissionsService.isRewardsDraftEnabled(),
            isWinnersModalVisible: false,
            alertButtons: {
                areYouSureButton: new Button({
                    label: this.$i18n.t('generic.continue'),
                    alertType: ALERT_TYPES.warning,
                    id: 1,
                }),
            },
        };
    },

    computed: {
        ...mapGetters('operators', [Getters.languageDefault]),
        ...mapGetters('rewards', [
            Getters.GET_NOT_DELETED_REWARDS_ENTITIES_BY_TYPE,
            Getters.GET_REWARDS_ENTITY_BY_TYPE_AND_ID,
            Getters.GET_REWARDS_ENTITIES_LIST_BY_TYPE,
            Getters.GET_REWARDS_ENTITIES_API_RESPONSE_BY_TYPE,
        ]),
        lotteries() {
            return this[Getters.GET_NOT_DELETED_REWARDS_ENTITIES_BY_TYPE](ENTITY_TYPES.LOTTERY);
        },
        lotteriesApiResponse() {
            return this[Getters.GET_REWARDS_ENTITIES_API_RESPONSE_BY_TYPE](ENTITY_TYPES.LOTTERY);
        },
        sortFilterLotteries() {
            return this.filteredEntitiesMixin(this.sortedLotteries);
        },
        sortedLotteries() {
            if (this.lotteries.length) {
                return [...this.lotteries].sort((group1, group2) => group1.name < group2.name);
            }
            return [];
        },
        allowedActions() {
            return StateActionsMapping[this.selectedEntity.state];
        },
        detailsSection() {
            if (!this.selectedEntityId) {
                return [];
            }
            return [this.generalDetails];
        },
        generalDetails() {
            const endTime = this.selectedEntity.data.end_time
                ? this.$localeLibrary.getFormattedDate(this.selectedEntity.data.end_time)
                : this.$i18n.t('generic.N/A');
            const startTime = this.selectedEntity.data.start_time
                ? this.$localeLibrary.getFormattedDate(this.selectedEntity.data.start_time)
                : this.$i18n.t('generic.N/A');
            return {
                name: this.$i18n.t('rewards.lottery.lottery'),
                properties: [
                    {
                        value: this.selectedEntity.id,
                        label: this.$i18n.t('generic.id'),
                    },
                    {
                        value: startTime || this.$i18n.t('generic.N/A'),
                        label: this.$i18n.t('generic.startTime'),
                    },
                    {
                        value: endTime || this.$i18n.t('generic.N/A'),
                        label: this.$i18n.t('generic.endTime'),
                    },
                    {
                        // eslint-disable-next-line camelcase
                        value: this.selectedEntity?.data?.max_purchases || this.$i18n.t('generic.N/A'),
                        label: this.$i18n.t('rewards.maxPurchases'),
                    },
                    {
                        // eslint-disable-next-line camelcase
                        value: this.selectedEntity?.data?.max_purchases_per_user || this.$i18n.t('generic.N/A'),
                        label: this.$i18n.t('rewards.maxPurchasesPerCustomer'),
                    },
                    {
                        // eslint-disable-next-line camelcase
                        value: this.getFormattedPurchases(this.selectedEntity?.data?.max_purchases_per_duration),
                        label: this.$i18n.t('rewards.maxPurchasesPerDuration'),
                    },
                    {
                        // eslint-disable-next-line camelcase
                        value: this.selectedEntity?.data?.metadata?.entry_count || 0,
                        label: this.$i18n.t('rewards.lottery.entryCount'),
                    },
                ],
            };
        },
        tableColumnsData() {
            const columns = [
                {
                    name: this.$i18n.t('generic.name'),
                    key: 'name',
                    mapper: entity => entity.name,
                    classes: ['font-weight-bold'],
                    forbidHideColumn: true,
                    field: 'name',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('generic.updateTime'),
                    key: 'updatedTime',
                    mapper: entity => entity.updateTimeFormatted,
                    sortBy: entity => entity.updateTime,
                    field: 'updatedTime',
                    filterType: tableColumnType.DATE,
                },
                {
                    name: this.$i18n.t('generic.state'),
                    key: 'state',
                    mapper: entity => this.entityState(entity.state),
                    filterType: tableColumnType.TEXT_LIMITED_OPTIONS,
                    field: 'status',
                    limitedOptions: Array.from(new Set(this.sortFilterLotteries.map(entity => entity.status))),
                },
                {
                    name: this.$i18n.t('rewards.lottery.phase'),
                    key: 'lotteryPhase',
                    mapper: entity => {
                        const lotteryPhase = this.lotteries
                            .find(l => l.id === entity.id)
                            .getPhase()
                            .toString();
                        return lotteryPhase.charAt(0).toUpperCase() + lotteryPhase.substring(1).toLowerCase();
                    },
                    filterType: tableColumnType.TEXT_LIMITED_OPTIONS,
                    field: 'lotteryPhase',
                    limitedOptions: Array.from(LOTTERY_PHASE),
                },
                {
                    name: this.$i18n.t('rewards.lottery.entryCount'),
                    key: 'entryCount',
                    mapper: entity => entity.entryCount,
                    filterType: tableColumnType.NUMBER,
                    field: 'entryCount',
                },
            ];

            if (this.isRewardsDraftEnabled) {
                columns.push({
                    name: this.$i18n.t('generic.version'),
                    key: 'entityVersion',
                    field: 'entityVersionLabel',
                    filterType: tableColumnType.TEXT_LIMITED_OPTIONS,
                    limitedOptions: [...new Set(this.sortFilterLotteries.map(entity => entity.entityVersionLabel))],
                });
            }

            return columns;
        },
        writeEnabled() {
            return isUserAllowed('LotteriesWrite') && permissionsService.rewardsEnabled();
        },
        descriptionAvailable() {
            const resultDesc = this.selectedEntity.description;
            return resultDesc || this.$i18n.t('generic.N/A');
        },
        winnersVisibility() {
            if (this.selectedEntity === null) {
                return false;
            }
            const lottery = this.lotteries.find(l => l.id === this.selectedEntity.id);
            return lottery.getPhase() !== LOTTERY_PHASE.PENDING;
        },
    },

    async created() {
        await this.$withProgressBar(
            async () => {
                this.isDataLoading = true;
                this.selectedLanguage = this[Getters.languageDefault];
                await Promise.all([this[Actions.REQUEST_REWARDS_ENTITIES_BY_TYPE](ENTITY_TYPES.LOTTERY)]);
                this.isDataLoading = false;
            },
            {
                errorHandler: error => {
                    this.isDataLoading = false;
                    Sentry.captureException(error);
                    this.$eventBus.$emit('showAlert', {
                        message: this.$i18n.t('alertMessage.failedToLoadNecessaryData'),
                    });
                },
            },
        );
    },

    methods: {
        ...mapActions('rewards', [Actions.REQUEST_REWARDS_ENTITIES_BY_TYPE]),

        onSelect(lotteryId) {
            this.selectedEntity = this[Getters.GET_REWARDS_ENTITY_BY_TYPE_AND_ID](ENTITY_TYPES.LOTTERY, lotteryId);
            this.selectedEntityId = lotteryId;
            this.isOverviewEnabled = true;
        },
        onCreate() {
            this.$router.push({
                name: RouteNames.LOTTERY_EDITOR,
                params: { companyId: this.$route.params.companyId },
            });
        },
        entityState(s) {
            return s && s !== STATUS_CODES.NA
                ? this.$i18n.t(`finalStateMapper.${onlyFirstLetterUppercase(entityStateReverseMap(s))}`)
                : this.$i18n.t('generic.N/A');
        },
        setSearchQuery(query) {
            this.searchQueryForTable = query;
        },
        getFormattedPurchases(data) {
            const getResultStr = item => `${this.$i18n.t('rewards.formattedPurchaseDetails', item)}.`;
            let result = '';
            if (data?.overall) {
                result = `${this.$i18n.t('generic.overall')}: ${getResultStr(data.overall)}`;
            }
            // eslint-disable-next-line camelcase
            if (data?.overall && data?.per_subscriber) {
                result += ' ';
            }
            // eslint-disable-next-line camelcase
            if (data?.per_subscriber) {
                result += `${this.$i18n.t('generic.perUser')}: ${getResultStr(data.per_subscriber)}`;
            }
            return result || this.$i18n.t('generic.N/A');
        },
        drawButtonVisibility(entity) {
            if (this.entity === null) {
                return false;
            }
            const lottery = this.lotteries.find(l => l.id === entity.id);
            return lottery.getPhase() === LOTTERY_PHASE.DRAWABLE;
        },
        onStart(lotteryId) {
            if (this.isDrawClicked) {
                return;
            }
            this.$eventBus.$emit('showAlert', {
                message: this.$i18n.t('rewards.lottery.areYouSureDraw'),
                type: ALERT_TYPES.warning,
                buttons: [this.alertButtons.areYouSureButton],
            });
            this.$eventBus.$once('buttonClicked', async buttonId => {
                if (buttonId === this.alertButtons.areYouSureButton.id) {
                    await this.$withProgressBar(
                        async () => {
                            this.isDrawClicked = true;
                            await drawLottery(lotteryId);
                            this.$eventBus.$emit('showAlert', {
                                message: this.$i18n.t('rewards.lottery.successDraw'),
                                type: ALERT_TYPES.success,
                            });
                            this.isDrawClicked = false;
                        },
                        {
                            errorHandler: error => {
                                this.isDrawClicked = false;
                                Sentry.captureException(error);
                                this.$eventBus.$emit('showAlert', {
                                    message: this.$i18n.t('rewards.lottery.failDraw'),
                                });
                            },
                        },
                    );
                }
            });
        },
        onEditWinners() {
            this.isWinnersModalVisible = true;
        },
        onCloseWinnersModal() {
            this.isWinnersModalVisible = false;
        },
        onCancelWinnersModal() {
            this.isWinnersModalVisible = this.$event;
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/palette';
@import '~@/assets/scss/consistency-colors';
@import '~@/assets/scss/consistency-spacing';
@import '~@/assets/scss/consistency-typography';

.description-highlight {
    padding: $spacing-xl 0;
    margin: 0 auto;

    .description-info {
        padding: $spacing-s $spacing-l;
        background-color: $dirty-white;
    }
}
</style>
