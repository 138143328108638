<template>
    <div
        class="sort-icon-wrap d-flex align-items-center justify-content-center"
        @click="onClick"
    >
        <div
            :class="sort"
            class="sort-icon"
        />
    </div>
</template>
<script>
export default {
    name: 'TableSortButton',
    props: {
        initSortType: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            sort: this.initSortType, // keeps the class that is necessary for proper icon render
        };
    },
    watch: {
        initSortType(val) {
            this.sort = val;
        },
    },
    methods: {
        onClick() {
            if (!this.sort) {
                this.sort = 'asc';
            } else if (this.sort === 'asc') {
                this.sort = 'desc';
            } else {
                this.sort = '';
            }
            this.$emit('sortEntities', this.sort);
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/palette';
@import '~@/assets/scss/icons';

$icon-path: '~@/assets/icons/';

.sort-icon-wrap {
    height: 32px;
    width: 32px;
    border-radius: 50%;
    cursor: pointer;

    &:hover {
        background: rgba($color: $blue, $alpha: 0.15);

        .sort-icon {
            content: url($icon-path + $icon-sort-no-sorting-hover);
        }

        .asc {
            content: url($icon-path + $icon-sort-up-hover);
        }

        .desc {
            content: url($icon-path + $icon-sort-down-hover);
        }
    }
}

.sort-icon {
    max-height: 16px;
    max-width: 16px;

    content: url($icon-path + $icon-sort-no-sorting-standard);
}

.asc {
    content: url($icon-path + $icon-sort-up-standard);
}

.desc {
    content: url($icon-path + $icon-sort-down-standard);
}
</style>
