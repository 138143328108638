// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import Vue from 'vue';
import { TranslateResult } from 'vue-i18n';
import { ALERT_TYPES } from '@/common/alerts/Alert';
import Button from './button/Button';
import ENTITY_TYPES from '@/common/entities/entityTypes';

export default Vue.extend({
    name: 'entityEditorMixin',
    data() {
        return {
            entityEditorMixin: {
                dirtyFlag: false,
                alertButtons: {} as { [key: string]: Button },
                successfullySaved: false,
                watcherAdded: false,
            },
            isSaveButtonClicked: false,
        };
    },
    computed: {
        pageTitle(): string {
            return this.isEditing ? `Edit ${this.capitilizedEntityType}` : `Add New ${this.capitilizedEntityType}`;
        },
        buttonLabel(): TranslateResult {
            return this.isEditing ? this.$i18n.t('generic.update') : this.$i18n.t('generic.save');
        },
        successSaveMessage(): TranslateResult {
            if (this.$route.params.id) {
                return this.$i18n.t('alertMessage.successActionMessage', {
                    entityName: this.capitilizedEntityType,
                    action: 'updated',
                });
            }
            return this.$i18n.t('alertMessage.successActionMessage', {
                entityName: this.capitilizedEntityType,
                action: 'created',
            });
        },
        capitilizedEntityType(): string {
            const splitEntityType = this.entityType.split('_');
            splitEntityType.forEach((word: string, index: number) => {
                splitEntityType[index] = word.charAt(0).toUpperCase() + word.slice(1);
            });
            return splitEntityType.join(' ');
        },
        isEditing(): boolean {
            return this.$route.params.id && !this.isCloning;
        },
        isCloning(): boolean {
            return !!this.$route.params.clone;
        },
    },
    methods: {
        onCancel(): void {
            this.showDontLeaveAlert(() => this.$router.go(-1));
        },
        addWatcher(watchedData: any): void {
            this.entityEditorMixin.watcherAdded = true;
            this.$nextTick(() => {
                const unwatchFunc = this.$watch(
                    watchedData,
                    () => {
                        this.entityEditorMixin.dirtyFlag = true;
                        unwatchFunc();
                    },
                    {
                        deep: true,
                    },
                );
            });
        },
        addAlertWatcher(watchedData: any): void {
            this.$nextTick(() => {
                const unwatchFunc = this.$watch(
                    watchedData,
                    () => {
                        this.$eventBus.$emit('showAlert', {});
                        unwatchFunc();
                    },
                    {
                        deep: true,
                    },
                );
            });
        },
        showDateInPastAlert(actionAfterConfirm: () => void, entityType: ENTITY_TYPES): void {
            const confirmButton = new Button({
                label: this.$i18n.t('generic.proceed'),
                alertType: ALERT_TYPES.warning,
            });
            this.$eventBus.$emit('showAlert', {
                message: this.$i18n.t('alertMessage.startDateInThePast', {
                    name: entityType,
                }),
                type: ALERT_TYPES.warning,
                buttons: {
                    confirmButton,
                },
            });
            this.$eventBus.$once('buttonClicked', id => {
                if (id === confirmButton.id) {
                    actionAfterConfirm();
                }
            });
        },
        showDontLeaveAlert(callback: () => void): void {
            if (!this.entityEditorMixin.successfullySaved && this.entityEditorMixin.dirtyFlag) {
                this.entityEditorMixin.alertButtons.leavePage = new Button({
                    label: this.$i18n.t('generic.leavePageButton'),
                    alertType: ALERT_TYPES.warning,
                });
                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('productCatalog.leavePage'),
                    type: ALERT_TYPES.warning,
                    buttons: [this.entityEditorMixin.alertButtons.leavePage],
                });
                this.$eventBus.$off('buttonClicked');
                this.$eventBus.$once('buttonClicked', (id: string) => {
                    if (id === this.entityEditorMixin.alertButtons.leavePage.id) {
                        this.entityEditorMixin.dirtyFlag = false;
                        callback();
                    }
                });
            } else {
                this.isSaveButtonClicked = false;
                callback();
            }
        },
    },

    beforeRouteLeave(to, from, next): void {
        this.showDontLeaveAlert(next);
    },
});
