<template>
    <div>
        <TableFiltersRenderless :entities="transactionHistoriesFormatted">
            <template
                #default="{
                    // state
                    allFilters,
                    filteredEntities,
                    // methods
                    onFilterAdded,
                    removeFilter,
                    removeAllFilters,
                }"
            >
                <div>
                    <div class="row mb-2 align-items-center table-header-row justify-content-between">
                        <div class="component-title-um">
                            {{ $i18n.t('customerCare.transactionHistory.billingTransactionHistory') }}
                        </div>
                        <div class="display-flex">
                            <ViasatMakePayment
                                v-if="isViasatDemo"
                                class="mr-3"
                            />
                            <ViasatPaymentMethod v-if="isViasatDemo" />
                            <ResponseModalButton
                                :response="transactionHistoriesApiResponse"
                                :title="$i18n.t('customerCare.transactionHistory.billingTransactionHistory')"
                            />
                            <div class="filters-control position-relative">
                                <FilterTable
                                    :columns="columnsData"
                                    :multiselectWidth="{ width: '12rem' }"
                                    @filterAdded="onFilterAdded"
                                >
                                    <template #button="slotProps">
                                        <IconButton
                                            :label="$i18n.t('generic.filter')"
                                            :icon="ICON_TYPES.FILTER"
                                            @iconClick="slotProps.clickHandler"
                                        />
                                    </template>
                                </FilterTable>
                            </div>
                        </div>
                    </div>

                    <TableFiltersTags
                        :filterTableMixin="{ allFilters }"
                        class="mt-1 mb-3"
                        @removeFilter="removeFilter"
                        @removeAllFilters="removeAllFilters"
                    />

                    <AppTable
                        :entities="filteredEntities"
                        :selectedEntityId="selectedEntityId"
                        :isDefaultHoverEnabled="true"
                        :isCustomButtonPassed="true"
                        :disableBoxShadow="true"
                        :canSelectColumns="true"
                        :newDesign="true"
                        :columnsData="columnsData"
                        tableKey="account/billing-transaction-history"
                        class="billing-transaction-history"
                        @selectEntity="e => [selectTransaction(e), showModal()]"
                    >
                        <template #state="{ entity }">
                            <EntityStatusIndicator
                                :stateMap="CHARGE_STATES_TO_STATUS_NAME_MAP"
                                :stateMapColor="CHARGE_STATES_INDICATOR_MAP"
                                :status="entity.state"
                                class="row-value"
                            />
                        </template>
                        <template #customRowButtons="{ entity }">
                            <template v-if="areInvoiceButtonsVisible(entity)">
                                <IconButton
                                    :label="$i18n.t('generic.generateInvoice')"
                                    :icon="ICON_TYPES.PLUS"
                                    data-test-id="billing-tx-history-generate-invoice-btn"
                                    @iconClick="onGenerateInvoiceButtonClicked(entity)"
                                />
                                <IconButton
                                    :label="$i18n.t('generic.download')"
                                    :icon="ICON_TYPES.DOWNLOAD"
                                    data-test-id="billing-tx-history-download-btn"
                                    @iconClick="onDownloadButtonClicked(entity)"
                                />
                            </template>
                            <div
                                v-if="isRefundAllowed && entity.refundAction"
                                class="d-flex align-items-center"
                            >
                                <AppTooltip
                                    v-if="!isEditAllowed || !entity.canRefund"
                                    :tooltipPosition="refundTooltipPosition"
                                >
                                    <template #label>
                                        <div class="info-icon ml-3" />
                                    </template>
                                    <template #content>
                                        {{ refundNotAllowedLabel }}
                                    </template>
                                </AppTooltip>

                                <IconButton
                                    :label="$i18n.t('customerCare.paymentStatuses.refund')"
                                    :icon="ICON_TYPES.REFUND"
                                    :disabled="!isEditAllowed || !entity.canRefund"
                                    data-test-id="billing-tx-history-refund-btn"
                                    @iconClick="refundPayment(entity)"
                                />
                            </div>
                        </template>
                    </AppTable>
                </div>
            </template>
        </TableFiltersRenderless>
        <TransactionHistoryV4Sidebar
            :selectedTransactionHistory="selectedTransactionHistory"
            :showSidebar="showAditionalSidebar"
            @showSidebarChange="showAditionalSidebar = $event"
        />
    </div>
</template>

<script>
// COMPONENTS
import AppTable from '@/components/partials/AppTable.vue';
import AppTooltip from '@/components/partials/AppTooltip.vue';
import { TOOLTIP_POSITION } from '@/common/tooltip';
import IconButton from '@/components/partials/IconButton.vue';
import { ICON_TYPES } from '@/common/iconHelper';
import TableFiltersRenderless from '@/components/filters/TableFiltersRenderless.vue';
import TableFiltersTags from '@/components/filters/TableFiltersTags.vue';
import FilterTable from '@/components/partials/FilterTable.vue';
import EntityStatusIndicator from '@/components/partials/EntityStatusIndicator.vue';
import TransactionHistoryV4Sidebar from '@/__new__/features/customerCare/account/TransactionHistoryV4Sidebar.vue';
import { ALERT_TYPES } from '@/common/alerts/Alert';
import ResponseModalButton from '@/components/partials/ResponseModalButton.vue';
import ViasatMakePayment from '@/__new__/features/customerCare/viasat/ViasatMakePayment.vue';
import ViasatPaymentMethod from '@/__new__/features/customerCare/viasat/ViasatPaymentMethod.vue';

// HTTP
import HTTPCustomerCare from '@/__new__/services/dno/user/http/customer-care';

// HELPERS
import get from 'lodash/get';
import tableColumnType from '@/common/filterTable';
import permissionsService, {
    getOperatorConfigValue,
    hideTransactionsUnpaidCanceled,
    isUserAllowed,
    isViewEnabled,
} from '@/services/permissions/permissions.service';
import {
    CHARGE_STATES,
    CHARGE_STATES_INDICATOR_MAP,
    CHARGE_STATES_TO_STATUS_NAME_MAP,
} from '@/common/userManager/transactionHistoryV4StatusHelper';
import { USER_MANAGER_HIERARCHY } from '@/__new__/features/customerCare/common/customerCareHelper';
import luaErrors from '@/common/luaErrors';
import { PAYMENT_TRANSACTION_TYPE } from '@/common/userManager/transactionHistoryV4Helper';
import { formatAmountBasedOnCurrency } from '@/common/formatting';

export default {
    name: 'BillingTransactionHistoryTable',
    components: {
        AppTable,
        AppTooltip,
        IconButton,
        ResponseModalButton,
        TableFiltersRenderless,
        TableFiltersTags,
        FilterTable,
        EntityStatusIndicator,
        TransactionHistoryV4Sidebar,
        ViasatMakePayment,
        ViasatPaymentMethod,
    },
    props: {
        transactionHistories: {
            required: true,
            type: Array,
        },
        transactionHistoriesApiResponse: {
            type: Object,
            default: null,
        },
        accountId: {
            type: String,
            default: '',
        },
        isEditAllowed: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            ICON_TYPES,
            selectedTransactionHistory: {},
            showAditionalSidebar: false,
            CHARGE_STATES,
            CHARGE_STATES_INDICATOR_MAP,
            CHARGE_STATES_TO_STATUS_NAME_MAP,
            USER_MANAGER_HIERARCHY,
            refundTooltipPosition: TOOLTIP_POSITION.left,
            isLFAdmin: permissionsService.isUserLotusFlareAdmin(),
            isDownloadAllowed:
                permissionsService.billingTransactionInvoicesEnabled() &&
                isUserAllowed('UMAccountBillingTransactionHistoryDownloadInvoice'),
            isRefundAllowed:
                permissionsService.billingTransactionRefundEnabled() &&
                isUserAllowed('UMAccountBillingTransactionHistoryIssueRefund'),
            isRefundTransactionVisible:
                permissionsService.refundTransactionsVisibleEnabled() &&
                isViewEnabled('UMAccountBillingTransactionHistoryRefundVisible'),
        };
    },
    computed: {
        transactionHistoriesFormatted() {
            let transactionsArray = [];
            if (this.transactionHistories?.length) {
                const filteredTransactionHistories = this.transactionHistories.filter(
                    transaction =>
                        transaction.paymentTransactionType === PAYMENT_TRANSACTION_TYPE.RESERVE ||
                        transaction.paymentTransactionType === PAYMENT_TRANSACTION_TYPE.DIRECT_DEBIT ||
                        (transaction.paymentTransactionType === PAYMENT_TRANSACTION_TYPE.REFUND &&
                            this.isRefundTransactionVisible),
                );

                transactionsArray = filteredTransactionHistories.map(transaction => ({
                    ...transaction,
                    refundAction: true,
                    amountFormatted: formatAmountBasedOnCurrency(transaction.amount, transaction.currency),
                    remainingBalance: formatAmountBasedOnCurrency(transaction.remainingBalance, transaction.currency),
                    state: transaction.partiallyRefunded ? CHARGE_STATES.PARTIALLY_REFUNDED : transaction.state,
                }));

                if (
                    !this.isLFAdmin &&
                    !isUserAllowed('UMAccountBillingTransactionHistoryUnpaidCancelled') &&
                    hideTransactionsUnpaidCanceled()
                ) {
                    transactionsArray = transactionsArray.filter(t => t.state !== CHARGE_STATES.UNPAID_CANCELED);
                }
            }
            return transactionsArray;
        },
        columnsData() {
            return [
                {
                    name: this.$i18n.t('customerCare.transactionHistory.transactionId'),
                    key: 'id',
                    field: 'id',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('customerCare.transactionHistory.fileNumber'),
                    key: 'fileNumber',
                    field: 'fileNumber',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('customerCare.transactionHistory.timestamp'),
                    key: 'timestamp',
                    mapper: entity => {
                        if (entity) {
                            return this.$localeLibrary.getFormattedDateAndTimeWithSecondsPrecision(entity.timestamp);
                        }

                        return '';
                    },
                    sortBy: entity => entity.create_timestamp,
                    field: 'timestamp',
                    filterType: tableColumnType.DATE,
                },
                {
                    name: this.$i18n.t('customerCare.transactionHistory.amount'),
                    key: 'amountFormatted',
                    sortBy: entity => entity.amount,
                    field: 'amount',
                    filterType: tableColumnType.NUMBER,
                },
                {
                    name: this.$i18n.t('generic.status'),
                    key: 'state',
                    field: 'state',
                    filterType: tableColumnType.TEXT_LIMITED_OPTIONS,
                    limitedOptions: Array.from(new Set(this.transactionHistoriesFormatted.map(entity => entity.state))),
                },
            ];
        },
        selectedEntityId() {
            return this.selectedTransactionHistory?.id || '';
        },
        isViasatDemo() {
            return getOperatorConfigValue('isViasatDemo');
        },
        refundNotAllowedLabel() {
            return !this.isEditAllowed
                ? this.$i18n.t('customerCare.refundNotAllowedVerifyUser')
                : this.$i18n.t('customerCare.refundNotAllowedForTransaction');
        },
    },
    methods: {
        get,
        areInvoiceButtonsVisible(entity) {
            return this.isDownloadAllowed && entity.transactionHasInvoiceButtons;
        },
        async onDownloadButtonClicked(entity) {
            const { id } = entity;
            this.$Progress.start();
            try {
                const response = await HTTPCustomerCare.downloadInvoice(
                    this.accountId,
                    USER_MANAGER_HIERARCHY.ACCOUNT,
                    id,
                );

                if (response.data.pdf_link) {
                    window.open(response.data.pdf_link, '_blank');
                }

                this.$Progress.finish();
            } catch (error) {
                if (error.response?.data.code === luaErrors.BILLING_V4.FILE_NOT_FOUND_ON_S3.code) {
                    this.$eventBus.$emit('showAlert', {
                        message: this.$i18n.t('customerCare.billingStatementDownloadNotFoundInvoice'),
                        type: ALERT_TYPES.warning,
                    });
                } else {
                    this.$eventBus.$emit('showAlert', {
                        message: this.$i18n.t('customerCare.billingStatementDownloadError'),
                    });
                }
                this.$Progress.fail();
            }
        },
        async onGenerateInvoiceButtonClicked(entity) {
            const { id } = entity;
            this.$Progress.start();
            try {
                await HTTPCustomerCare.generateFile(this.accountId, USER_MANAGER_HIERARCHY.ACCOUNT, id);

                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('customerCare.generateInvoiceSuccessMessage'),
                    type: ALERT_TYPES.success,
                });

                this.$Progress.finish();
            } catch (error) {
                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('customerCare.generateInvoiceFailedMessage'),
                });
                this.$Progress.fail();
            }
        },
        refundPayment(entity) {
            if (this.isEditAllowed && entity.canRefund) {
                this.$emit('refundPayment', entity);
            }
        },
        showModal() {
            this.showAditionalSidebar = true;
        },
        selectTransaction(id) {
            this.selectedTransactionHistory = this.transactionHistoriesFormatted.find(
                transaction => transaction.id === id,
            );
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/filter-table';
@import '~@/assets/scss/palette';

.row-value {
    font-size: 0.875rem;
    width: 100%;
}

.billing-transaction-history::v-deep .table-scroll-wrapper {
    overflow: visible !important;
}
</style>
