import { AxiosPromise } from 'axios';
import http, { DnoResponseBase } from '@/http/index';

export interface PolicyCountersApiResponse extends DnoResponseBase {
    policy_counter_by_id: Record<string, any>;
    usage_counter_by_id?: Record<string, any>;
}

/**
 * Get policy counters.
 *
 * @returns {AxiosPromise}
 */
export function getPolicyCounters(
    ids: string[] = [],
    returnChargingVersion = true,
    includeReferencedEntities = false,
    includePortalId = true,
    chargingVersion?: string,
): AxiosPromise<PolicyCountersApiResponse> {
    return http({
        method: 'POST',
        url: '/v3charging/getpolicycounters',
        data: {
            ids,
            return_charging_version: returnChargingVersion,
            include_referenced_entities: includeReferencedEntities,
            charging_version: chargingVersion,
            include_portal_id: includePortalId,
        },
    });
}

/**
 * Add policy counters.
 *
 * @returns {AxiosPromise}
 */
export function addPolicyCounters(data: any): AxiosPromise<DnoResponseBase> {
    return http({
        method: 'POST',
        url: '/v3chargingwrite/addpolicycounters',
        data,
    });
}

/**
 * Update policy counters.
 *
 * @returns {AxiosPromise}
 */
export function updatePolicyCounters(id: string, version: number, data: any): AxiosPromise<DnoResponseBase> {
    return http({
        method: 'POST',
        url: '/v3chargingwrite/updatepolicycounters',
        data: {
            id,
            version,
            data,
        },
    });
}

/**
 * Update policy counters state.
 *
 * @returns {AxiosPromise}
 */
export function updatePolicyCountersState(id: string, version: number, state: number): AxiosPromise<DnoResponseBase> {
    return http({
        method: 'POST',
        url: '/v3chargingwrite/updatestatepolicycounters',
        data: {
            id,
            version,
            state,
        },
    });
}

interface PolicyCountersDraftListApiResponse extends DnoResponseBase {
    data: any;
}

/**
 * Get policy counters draft list.
 *
 * @returns {AxiosPromise}
 */
export function getPolicyCountersDraftList(): AxiosPromise<PolicyCountersDraftListApiResponse> {
    return http({
        method: 'POST',
        url: '/v3charging/getpolicycountersdraftlist',
    });
}

/**
 * Get policy counter entity draft.
 *
 * @returns {AxiosPromise}
 */
export function getPolicyCounterEntityDraft(id: string): AxiosPromise<DnoResponseBase> {
    return http({
        method: 'POST',
        url: '/v3charging/getpolicycounterdraft',
        data: {
            id,
        },
    });
}

/**
 * Set policy counter draft.
 *
 * @returns {AxiosPromise}
 */
type PolicyCounterDraftPayload = {
    id?: string;
    data: object;
};

export function setPolicyCounterDraft(data: PolicyCounterDraftPayload): AxiosPromise<DnoResponseBase> {
    return http({
        method: 'POST',
        url: '/v3charging/setpolicycounterdraft',
        data,
    });
}

/**
 * Delete policy counter draft.
 *
 * @returns {AxiosPromise}
 */
export function deletePolicyCounterDraft(id: string): AxiosPromise<DnoResponseBase> {
    return http({
        method: 'POST',
        url: '/v3charging/deletepolicycounterdraft',
        data: {
            id,
        },
    });
}

export default {
    getPolicyCounters,
    addPolicyCounters,
    updatePolicyCounters,
    updatePolicyCountersState,
    getPolicyCountersDraftList,
    getPolicyCounterEntityDraft,
    setPolicyCounterDraft,
    deletePolicyCounterDraft,
};
