import { TranslateResult } from 'vue-i18n';
import i18n from '@/i18n';
import { upperCase } from 'lodash';

export type Proration = {
    roundingPreference: RoundingPreferenceOption;
    roundingPrecision: number;
    minimumUnits: number;
};

export enum ROUNDING_PREFERENCE {
    UP = 1,
    DOWN = 2,
}

export type RoundingPreferenceOption = {
    id: ROUNDING_PREFERENCE;
    label: TranslateResult;
};

export const ROUNDING_PREFERENCE_OPTIONS: Readonly<RoundingPreferenceOption[]> = [
    {
        id: ROUNDING_PREFERENCE.UP,
        label: upperCase(i18n.t('generic.up').toString()),
    },
    {
        id: ROUNDING_PREFERENCE.DOWN,
        label: upperCase(i18n.t('generic.down').toString()),
    },
];
