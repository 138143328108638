<template>
    <AppAditionalSidebar
        :visible="showSidebar"
        @input="val => hideSidebar(val)"
    >
        <template #header>
            <Breadcrumbs
                :breadcrumbList="breadcrumbList"
                :routeOnClick="false"
                @breadcrumbClicked="onBreadcrumbClicked"
            />
        </template>
        <template #content>
            <template v-if="selectedParentOrder && isStateHistoryNotEmpty">
                <section class="order-wrap mb-5">
                    <div class="modal-row d-flex align-items-center">
                        <div class="all-caps-text">
                            {{ $i18n.t('customerCare.stateHistory') }}
                        </div>
                    </div>
                    <OrderStateHistory v-model="selectedParentOrder.orderStateHistory" />
                </section>
            </template>
            <template v-if="suborderTabIsDisplayed">
                <div class="order-wrap">
                    <AppMultiselectV3
                        v-model="selectedSubOrder"
                        :options="subOrders"
                        :isLabelColorLight="true"
                        :showLabels="false"
                        :isSmall="true"
                        :allowEmpty="false"
                        :additionalLabel="$i18n.t('productCatalog.entities.plural.subOrders')"
                        label="entity_name"
                        trackBy="entity_id"
                        class="mb-4"
                    />
                    <template v-if="selectedSubOrder">
                        <div class="modal-row d-flex align-items-center">
                            <div class="all-caps-text">
                                {{ selectedSubOrder.entity_name }}
                            </div>
                        </div>
                        <div class="modal-row d-flex align-items-center">
                            <div class="row-title">
                                {{ $i18n.t('generic.id') }}
                            </div>

                            <div class="row-value">
                                {{ subOrderId }}
                            </div>
                        </div>

                        <div class="modal-row d-flex align-items-center">
                            <div class="row-title">
                                {{ $i18n.t('generic.originalPrice') }}
                            </div>

                            <div class="row-value">
                                {{ $localeLibrary.getFormattedAmount(selectedSubOrder.original_price) }}
                            </div>
                        </div>

                        <div class="modal-row d-flex align-items-center">
                            <div class="row-title">
                                {{ $i18n.t('generic.status') }}
                            </div>
                            <EntityStatusIndicator
                                v-if="!isUndefined(selectedSubOrder.state)"
                                :stateMap="ORDER_ENTITY_STATES_TO_STATUS_NAME_MAP"
                                :stateMapColor="ORDER_STATUS_INDICATOR_MAP"
                                :status="selectedSubOrder.state"
                                :isInTable="false"
                                class="row-value"
                            />
                        </div>
                    </template>
                </div>
            </template>
            <template v-if="isOePlansVisible">
                <section>
                    <div class="modal-row d-flex align-items-center">
                        <div class="all-caps-text">
                            {{ $i18n.t('orchestrationEngine.oeWorkflow') }}
                        </div>
                    </div>
                    <AppTable
                        :entities="oeMappingsList"
                        :isDefaultHoverEnabled="false"
                        :forbidHideColumn="true"
                        :disableBoxShadow="true"
                        :newDesign="true"
                        :canSelectColumns="false"
                        :isDataLoading="isOeLoading"
                        :columnsData="oeColumnsData"
                        tableKey="user/account-oe"
                        keyName="executionId"
                        class="auto-height"
                    >
                        <template #planId="{ entity }">
                            <div
                                v-if="entity.planId"
                                class="truncate-text"
                            >
                                <router-link
                                    :to="{
                                        name: RouteNames.ORCHESTRATION_ENGINE_EXECUTION_DETAILS,
                                        params: {
                                            id: entity.planId,
                                            execId: entity.executionId,
                                        },
                                    }"
                                    target="_blank"
                                >
                                    {{ entity.planId }}
                                </router-link>
                            </div>
                        </template>
                        <template #status="{ entity }">
                            <ExecutionStatusIndicator :status="entity.status" />
                        </template>
                    </AppTable>
                </section>
            </template>
        </template>
    </AppAditionalSidebar>
</template>
<script>
// COMPONENTS
import AppAditionalSidebar from '@/components/partials/AppAditionalSidebar.vue';
import ExecutionStatusIndicator from '@/__new__/features/orchestrationengine/ExecutionStatusIndicator.vue';
import Breadcrumbs from '@/components/partials/Breadcrumbs.vue';
import AppMultiselectV3 from '@/components/partials/inputs/AppMultiselectV3.vue';
import EntityStatusIndicator from '@/components/partials/EntityStatusIndicator.vue';
import AppTable from '@/components/partials/AppTable.vue';
import OrderStateHistory from '@/__new__/features/customerCare/OrderStateHistory.vue';

// HELPERS
import tableColumnType from '@/common/filterTable';
import {
    ORDER_ENTITY_STATES_TO_STATUS_NAME_MAP,
    ORDER_STATUS_INDICATOR_MAP,
} from '@/__new__/features/customerCare/common/orderStateHelper';
import { isUndefined } from 'lodash';
import RouteNames from '@/router/routeNames';
import permissionsService from '@/services/permissions/permissions.service';

// HTTP
import OePlansMixin from '@/__new__/features/customerCare/mixins/OePlansMixin.vue';

export default {
    name: 'OrderDetails',
    components: {
        AppAditionalSidebar,
        ExecutionStatusIndicator,
        Breadcrumbs,
        AppMultiselectV3,
        EntityStatusIndicator,
        AppTable,
        OrderStateHistory,
    },
    mixins: [OePlansMixin],
    props: {
        selectedParentOrder: {
            type: Object,
            default: null,
        },
        showAdditionalSidebar: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            breadcrumbListIds: {
                order: 1,
                suborder: 2,
            },
            selectedSubOrder: null,
            isOeLoading: false,
            isSidebarVisible: false,
            oeMappingsList: [],
            ORDER_ENTITY_STATES_TO_STATUS_NAME_MAP,
            ORDER_STATUS_INDICATOR_MAP,
            RouteNames,
        };
    },
    computed: {
        showSidebar() {
            return this.isSidebarVisible;
        },
        suborderTabIsDisplayed() {
            return this.selectedParentOrder;
        },
        breadcrumbList() {
            if (this.selectedSubOrder) {
                return [
                    // links are truthy for breadcrumb items to be clickable
                    { name: this.$i18n.t('customerCare.orderDetails'), link: ' ', id: this.breadcrumbListIds.order },
                    {
                        name: this.$i18n.t('productCatalog.entities.plural.subOrders'),
                        id: this.breadcrumbListIds.suborder,
                    },
                ];
            }
            return [{ name: this.$i18n.t('customerCare.orderDetails'), id: this.breadcrumbListIds.order }];
        },
        subOrderId() {
            let subOrder = '';
            if (this.selectedSubOrder.sub_order_id) {
                subOrder = this.selectedSubOrder.sub_order_id;
            }
            if (this.selectedSubOrder.suborder_id) {
                subOrder = this.selectedSubOrder.suborder_id;
            }
            return subOrder;
        },
        subOrders() {
            return this.selectedParentOrder && this.selectedParentOrder.subOrders
                ? Object.values(this.selectedParentOrder?.subOrders)
                : [];
        },
        benefitColumnsData() {
            return [
                {
                    name: this.$i18n.t('promotions.benefitDescription'),
                    key: 'benefit_short_description',
                    forbidHideColumn: true,
                    field: 'benefit_short_description',
                    filterType: tableColumnType.GENERAL_TEXT,
                    mapper: entity => {
                        if (entity.short_descriptions.length) {
                            return entity.short_descriptions[0].benefit_short_description;
                        }

                        return '';
                    },
                },
                {
                    name: this.$i18n.t('promotions.promoDescription'),
                    key: 'promotion_short_description',
                    forbidHideColumn: true,
                    field: 'promotion_short_description',
                    filterType: tableColumnType.GENERAL_TEXT,
                    mapper: entity => {
                        if (entity.short_descriptions.length) {
                            return entity.short_descriptions[0].promotion_short_description;
                        }

                        return '';
                    },
                },
            ];
        },
        isStateHistoryNotEmpty() {
            return this.selectedParentOrder.orderStateHistory && this.selectedParentOrder.orderStateHistory.length;
        },
        isOePlansVisible() {
            return this.oePlansEnabled && permissionsService.isUserLotusFlareAdmin();
        },
    },
    watch: {
        // copy value of prop showAdditionalSidebar to isSidebarVisible data to avoid mutation prop error in console
        showAdditionalSidebar: {
            handler() {
                this.isSidebarVisible = this.showAdditionalSidebar;
            },
        },
        selectedParentOrder: {
            async handler() {
                this.resetSidebarData();
                this.oeMappingsList = [];
                if (this.oePlansEnabled && this.selectedParentOrder) {
                    await this.fetchExecutions({
                        mapper_ids: [this.selectedParentOrder.orderId],
                    });
                }
            },
        },
        isSidebarVisible: {
            handler() {
                // unselect subOrder everytime sidebar is closed
                if (!this.isSidebarVisible) {
                    this.resetSidebarData();
                }
            },
        },
    },
    methods: {
        isUndefined,
        resetSidebarData() {
            this.selectedSubOrder = null;
        },
        hideSidebar(val) {
            this.isSidebarVisible = val;
            this.$emit('closeSidebar', this.isSidebarVisible);
        },
        onBreadcrumbClicked(nav) {
            if (nav.id === this.breadcrumbListIds.order) {
                this.selectedSubOrder = null;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/palette';
@import '~@/assets/scss/base';

.auto-height {
    height: auto !important;
}

.order-wrap {
    margin-bottom: 1.75rem;
}

.modal-row {
    margin-bottom: 0.875rem;
}

.row-title {
    font-size: 0.75rem;
    font-weight: 600;
    width: 7.875rem;
}

.row-value {
    font-size: 0.875rem;
    width: 100%;
}
</style>
