<template>
    <div class="d-flex align-items-center">
        <div
            v-for="(day, index) in DaysOfWeek"
            :key="index"
            :class="['day', { selected: value === day }]"
            @click="$emit('input', day)"
        >
            {{ day }}
        </div>
    </div>
</template>

<script>
import DaysOfWeek from '@/__new__/features/campaigns/common/DaysOfWeek';

export default {
    name: 'DayOfWeekSelector',
    props: {
        value: { type: String, default: '' },
    },
    data() {
        return {
            DaysOfWeek,
        };
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/_palette';

.day {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 100%;

    font-weight: 600;
    color: $gray30;

    border: 1px solid $gray5;
    border-left-width: 0;
    cursor: pointer;

    &:first-child {
        border-left-width: 1px;
    }

    &:hover {
        color: $gray60;
    }

    &.selected {
        background-color: $orange;
        border-color: $orange;
        color: $white;
    }
}
</style>
