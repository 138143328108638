import { EntityStateMapping } from '@/common/commonEntityStateMapper';

export { EntityStateMapping, entityStateReverseMap } from '@/common/commonEntityStateMapper';

// Order is used to display as display order as well so to not sort them every time
// sorting them here.
export enum EntityActions {
    UNAPPROVE = 1,
    APPROVE = 2,
    EDIT = 3,
    DELETE = 4,
    CLONE = 5,
    PAUSE = 6,
    UNPAUSE = 7,
    DETAILS = 8,
    STOP = 9,
    START = 10,
    SEND_EVENT = 11,
    READ_ONLY = 12,
    DOWNLOAD = 13,
}

export function entityActionList(): ReadonlyArray<keyof typeof EntityActions> {
    return [
        'APPROVE',
        'PAUSE',
        'UNPAUSE',
        'CLONE',
        'SEND_EVENT',
        'DELETE',
        'EDIT',
        'DETAILS',
        'STOP',
        'START',
        'READ_ONLY',
        'DOWNLOAD',
    ];
}

export const StateActionsMapping = {
    [EntityStateMapping.UNAPPROVED]: [
        EntityActions.APPROVE,
        EntityActions.EDIT,
        EntityActions.DELETE,
        EntityActions.CLONE,
        EntityActions.READ_ONLY,
    ],
    [EntityStateMapping.APPROVED]: [
        EntityActions.DELETE,
        EntityActions.CLONE,
        EntityActions.EDIT,
        EntityActions.READ_ONLY,
    ],
    [EntityStateMapping.PAUSED]: [],
    [EntityStateMapping.DELETED]: [EntityActions.CLONE],
    [EntityStateMapping.DRAFT]: [EntityActions.EDIT, EntityActions.DELETE],
};

export const EntityStateActionMapping = {
    allowedActions(state: number, startTime: number, endTime: number) {
        const now = Math.round(new Date().getTime() / 1000);

        if (state === EntityStateMapping.APPROVED) {
            if (!startTime && !endTime) {
                return [
                    EntityActions.PAUSE,
                    EntityActions.CLONE,
                    EntityActions.DELETE,
                    EntityActions.EDIT,
                    EntityActions.READ_ONLY,
                ];
            }
            if (startTime < now && (!endTime || endTime > now)) {
                // Currently Running
                return [EntityActions.CLONE, EntityActions.PAUSE, EntityActions.EDIT, EntityActions.READ_ONLY];
            }
            if (startTime > now && endTime > now) {
                // Did not yet start
                return [EntityActions.CLONE, EntityActions.EDIT, EntityActions.DELETE, EntityActions.READ_ONLY];
            }
            if (startTime < now && endTime < now) {
                // Has expired
                return [EntityActions.CLONE, EntityActions.EDIT, EntityActions.DELETE, EntityActions.READ_ONLY];
            }
        } else if (state === EntityStateMapping.PAUSED) {
            return [EntityActions.UNPAUSE, EntityActions.DELETE, EntityActions.EDIT, EntityActions.READ_ONLY];
        } else if (state === EntityStateMapping.UNAPPROVED) {
            return [
                EntityActions.APPROVE,
                EntityActions.EDIT,
                EntityActions.DELETE,
                EntityActions.CLONE,
                EntityActions.READ_ONLY,
            ];
        } else if (state === EntityStateMapping.DELETED) {
            return [EntityActions.CLONE, EntityActions.EDIT, EntityActions.DELETE, EntityActions.READ_ONLY];
        } else if (state === EntityStateMapping.DRAFT) {
            return [EntityActions.EDIT, EntityActions.DELETE];
        }
        return [EntityActions.CLONE, EntityActions.EDIT];
    },
};
