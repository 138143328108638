
import Vue from 'vue';

// Vue components
import AppMultiselectV3 from '@/components/partials/inputs/AppMultiselectV3.vue';

export default Vue.extend({
    name: 'OESkipStatesMixin',

    components: {
        AppMultiselectV3,
    },

    props: {
        currentPlan: {
            type: Object,
            default: () => undefined,
        },
    },

    data() {
        return {
            inputJsonString: '' as string,
            skipStates: [] as string[],
        };
    },

    computed: {
        skipStatesOptions(): { label: string }[] {
            if (this?.currentPlan?.states) {
                const { states } = this.currentPlan;
                const stateKeys = Object.keys(states);

                if (stateKeys?.length) {
                    return stateKeys.map(key => ({
                        label: key,
                    }));
                }
            }

            return [];
        },
    },

    watch: {
        skipStates: {
            handler(value) {
                const skippedStates = value.map((state: { label: string }) => state.label) || [];
                const inputJSON = Object.keys(this.inputJsonString).length ? JSON.parse(this.inputJsonString) : {};

                if (skippedStates.length) {
                    // eslint-disable-next-line no-underscore-dangle
                    inputJSON._skip_states = skippedStates;
                } else {
                    // eslint-disable-next-line no-underscore-dangle
                    delete inputJSON._skip_states;
                }

                this.inputJsonString = JSON.stringify(inputJSON);
            },
        },
    },
});
