import { JOB_TYPE } from '@/__new__/services/dno/sinkConfigs/models/sinkConfigHelper';

export const JOB_EXPORT_DOC_URL =
    'https://lotusflare.atlassian.net/wiki/spaces/PT/pages/5622071323/Export+Job+config+from+Portal+as+static';

export type AplicationObject = {
    id: string;
    name: string;
};

export type ClusterResponse = {
    cluster_name: string;
    enabled: boolean;
};

export interface JobPausedResponse {
    cluster_name: string;
    is_paused: boolean;
    job_id: string;
    operator_name: string;
}

export type JobResponse = {
    job_definition: JobDefinition;
    revision_number: number;
    state: string;
    created_at: number;
    last_updated: number;
};

export type JobDefinition = {
    id: string;
    name: string;
    group: string;
    operator_name: string;
    owner: string;
    topic: string;
    batch_size: number;
    parallelism: number;
    retries_before_discard: number;
    discarded_event_categories: Array<{
        category: string;
        rule: string;
    }>;
    predef_labels: Record<string, string>;
    retry_rule: string;
    fail_threshold: number;
    message_expiration: string;
    pause_time: string;
    deduplication: true;
    cluster_name: string;
    job_type: JOB_TYPE;
    // Can be anything, no schema
    [key: string]: any;
};

export interface ClusterJob extends JobPausedResponse {
    id: Job['id'];
    job: Job;
    name?: Job['name'];
}

export const PRESELECTED_CLUSTER = 'default';

export enum CLUSTER_JOB_ACTION {
    DELETE = 'delete',
    EDIT = 'edit',
    EXPORT = 'export',
}

export class Job {
    id: string;
    name: string;
    owner: string;
    data: Omit<JobResponse, 'created_at' | 'last_updated'> | null;

    constructor(job: Partial<Job> = {}) {
        this.id = job.id || '';
        this.name = job.name || '';
        this.owner = job.owner || '';
        this.data = job.data || null;
    }

    get isGlobal() {
        return this.data?.job_definition?.job_type === JOB_TYPE.STATIC;
    }

    static mapFromBE(data: JobResponse) {
        return new Job({
            id: data?.job_definition?.id,
            name: data?.job_definition?.name,
            owner: data?.job_definition?.owner,
            // Notice: Key names are required parameters for Job update API.
            data: {
                job_definition: data?.job_definition,
                revision_number: data?.revision_number,
                state: data?.state,
            },
        });
    }
}
