var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.entity.enabled)?_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
        content: _vm.entity.name,
        show: _vm.showTooltip,
        trigger: 'manual',
        placement: 'right',
        classes: ['navigation-tooltip'],
    }),expression:"{\n        content: entity.name,\n        show: showTooltip,\n        trigger: 'manual',\n        placement: 'right',\n        classes: ['navigation-tooltip'],\n    }"}]},[_c('div',{staticClass:"menu-item",class:{ compact: _vm.compact },on:{"mouseenter":_vm.handleMenuItemHover,"mouseleave":_vm.handleMenuItemBlur}},[(_vm.entity.link && !_vm.entity.subItems)?_c('span',{on:{"click":_vm.handleParentClick}},[_c(_vm.getHtmlTag(_vm.entity),_vm._b({tag:"component",staticClass:"no-dropdown-wrapper",class:{ compact: _vm.compact },attrs:{"target":_vm.entity.external ? '_blank' : '_self'}},"component",_vm._d({},[_vm.getLinkAttr(_vm.entity),_vm.getLink(_vm.entity.link, _vm.entity.external)])),[_c('div',{staticClass:"img-wrapper",class:{ compact: _vm.compact }},[_c('AppIcon',{staticClass:"menu-item-icon",attrs:{"type":_vm.entity.icon}})],1),_c('div',{staticClass:"no-dropdown",on:{"click":_vm.setActiveParentItem}},[_c('span',{staticClass:"hover-indicator",class:{ active: _vm.isEntityActive, compact: _vm.compact }}),_c('span',{staticClass:"header-without-dropdown",class:{ active: _vm.isEntityActive, visible: _vm.isEntityVisible, compact: _vm.compact }},[_vm._v(" "+_vm._s(_vm.entity.name)+" ")])])])],1):_vm._e(),(_vm.entity.subItems)?_c('div',{staticClass:"dropdown-wrapper"},[_c('span',{staticClass:"hover-indicator",class:{ 'active-compact': _vm.isEntityActive && _vm.compact }}),_c('div',{staticClass:"img-wrapper",class:{ compact: _vm.compact },on:{"click":_vm.handleParentClick}},[_c('AppIcon',{staticClass:"menu-icon",attrs:{"type":_vm.entity.icon}})],1),_c('div',{staticClass:"dropdown",class:{ compact: _vm.compact, visible: _vm.isEntityVisible && !_vm.tenantDropdownVisible }},[_c('span',{key:_vm.entity.name,staticClass:"dropdown-header",class:{
                        compact: _vm.compact,
                        active: _vm.isEntityVisible,
                        'active-parent': _vm.isEntityActive,
                    },on:{"click":_vm.handleParentClick}},[_c('div',[_c('span',{class:{ 'active-header': _vm.isEntityActive && !_vm.compact, 'hover-indicator': !_vm.compact }}),_vm._v(" "+_vm._s(_vm.entity.name)+" ")]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(!_vm.compact),expression:"!compact"}],staticClass:"menu-dropdown-toggle"},[_c('img',{staticClass:"dropdown-arrow",attrs:{"src":require("../../assets/icons/arrow-down.svg"),"alt":"dropdown-arrow"}})])]),_vm._l((_vm.entity.subItems),function(subItem,index){return _c('span',{key:index,on:{"click":function($event){return _vm.handleSubItemClick(subItem)}}},[(subItem.enabled && _vm.isEntityVisible && !_vm.tenantDropdownVisible)?_c(_vm.getHtmlTag(subItem),_vm._b({tag:"component",staticClass:"sub-item",class:{ compact: _vm.compact, active: _vm.subActive === subItem.name },attrs:{"target":subItem.external ? '_blank' : '_self'}},"component",_vm._d({},[_vm.getLinkAttr(subItem),_vm.getLink(subItem.link, subItem.external)])),[_c('span',{staticClass:"hover-sub-indicator",class:{ 'active-sub-indicator': _vm.subActive === subItem.name }}),_vm._v(" "+_vm._s(subItem.name)+" ")]):_vm._e()],1)})],2)]):_vm._e()])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }