
// Global
import Vue from '@/common/typedVue';

// Components
import AppMultiselectV3 from '@/components/partials/inputs/AppMultiselectV3.vue';
import UnitPicker from '@/components/partials/inputs/UnitPicker.vue';

// Helpers
import { Proration, ROUNDING_PREFERENCE_OPTIONS } from '@/__new__/services/dno/charging/common/cSprorationHelper';
import { CHARGING_SPEC_TYPE } from '@/__new__/services/dno/charging/common/chargingSpecificationHelper';
import { unitTypes } from '@/common/formatting';

export default Vue.extend({
    name: 'ChargingSpecificationsProration',
    components: {
        AppMultiselectV3,
        UnitPicker,
    },
    props: {
        value: {
            type: Object,
            default: null,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        selectedCSType: {
            type: Object,
            default: () => undefined,
        },
        errors: {
            type: Object,
            default: () => ({
                roundingPreference: false,
                roundingPrecision: false,
                minimumUnits: false,
            }),
        },
    },
    data() {
        return {
            ROUNDING_PREFERENCE_OPTIONS,
            prorationData: {
                roundingPreference: ROUNDING_PREFERENCE_OPTIONS[0],
                roundingPrecision: 0,
                minimumUnits: 0,
            } as Proration,
        };
    },
    computed: {
        getFormulaCalculated() {
            switch (this.selectedCSType?.id) {
                case CHARGING_SPEC_TYPE.data.id:
                    return unitTypes.DATA;
                case CHARGING_SPEC_TYPE.voice.id:
                    return unitTypes.DURATIONSecondsMinutes;
                case CHARGING_SPEC_TYPE.sms.id:
                    return unitTypes.SMS;
                case CHARGING_SPEC_TYPE.mms.id:
                    return unitTypes.MMS;
                case CHARGING_SPEC_TYPE.api.id:
                    return unitTypes.API;
                default:
                    return unitTypes.DATA;
            }
        },
    },
    watch: {
        selectedCSType: {
            immediate: true,
            handler() {
                this.prorationData.roundingPrecision = 0;
                this.prorationData.minimumUnits = 0;
            },
        },
    },
    created() {
        this.prorationData = {
            ...this.prorationData,
            ...this.value,
        };
    },
    methods: {
        dataChanged(): void {
            this.$emit('change', this.prorationData);
        },
    },
});
