
import Vue from 'vue';
import AbstractTableTile from '@/__new__/features/customerCareSuite/components/AbstractTableTile.vue';
import AppLabel from '@/components/partials/AppLabel.vue';
import tableColumnType, { TableColumn } from '@/common/filterTable';
import { BillState } from '@/__new__/features/charterDemo/charterModels';
import { getBillingAccount, getBills } from '@/__new__/features/charterDemo/charterHttp';
import { startCase } from 'lodash';
import { LABEL_COLOR } from '@/common/labelsHelper';
import CharterDemoBillSidebar from '@/__new__/features/customerCare/account/CharterDemoBillSidebar.vue';

export default Vue.extend({
    name: 'CharterDemoBillsTile',
    components: {
        AbstractTableTile,
        AppLabel,
        CharterDemoBillSidebar,
    },
    data() {
        return {
            LABEL_COLOR,
            entities: [] as any[],
            apiResponse: {} as object,
            selectedEntity: null as any,
            showSidebar: false,
            columnsData: [
                {
                    name: this.$i18n.t('charterDemo.billNumber'),
                    key: 'billNo',
                    field: 'billNo',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('charterDemo.billId'),
                    key: 'id',
                    field: 'id',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('charterDemo.billDate'),
                    mapper: (entity: any) => {
                        const { billDate } = entity;
                        const date = new Date(billDate);
                        const time = date.getTime();
                        const str = this.$localeLibrary.getFormattedDate(time);
                        return str;
                    },
                    key: 'billDateStr',
                    field: 'billDate',
                    filterType: tableColumnType.DATE,
                },
                {
                    name: this.$i18n.t('charterDemo.dueDate'),
                    key: 'paymentDueDate',
                    field: 'paymentDueDate',
                    mapper: (entity: any) => {
                        const { paymentDueDate } = entity;
                        const date = new Date(paymentDueDate);
                        const time = date.getTime();
                        const str = this.$localeLibrary.getFormattedDate(time);
                        return str;
                    },
                    filterType: tableColumnType.DATE,
                },
                {
                    name: this.$i18n.t('charterDemo.overdue'),
                    key: 'overdueStr',
                    field: 'overdueStr',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('charterDemo.amountDue'),
                    key: 'amountDueStr',
                    field: 'amountDueStr',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('charterDemo.state'),
                    key: 'state',
                    field: 'state',
                    filterType: tableColumnType.TEXT_LIMITED_OPTIONS,
                    limitedOptions: [
                        BillState.PartiallySettled,
                        BillState.Cancelled,
                        BillState.OnHold,
                        BillState.Settled,
                        BillState.Sent,
                        BillState.New,
                        BillState.Validated,
                    ],
                },
            ] as TableColumn[],
            STATE_TO_COLOR_MAP: new Map([
                [BillState.PartiallySettled, LABEL_COLOR.gray],
                [BillState.Cancelled, LABEL_COLOR.gray],
                [BillState.OnHold, LABEL_COLOR.gray],
                [BillState.Settled, LABEL_COLOR.gray],
                [BillState.Sent, LABEL_COLOR.gray],
                [BillState.New, LABEL_COLOR.gray],
                [BillState.Validated, LABEL_COLOR.gray],
            ]),
        };
    },
    computed: {
        id() {
            return this.$route.params.id || '';
        },
    },
    watch: {},
    async created() {
        await this.fetchTileData();
    },
    methods: {
        startCase,
        async fetchTileData() {
            await this.$withProgressBar(
                async () => {
                    this.$emit('isDataLoadingUpd', true);
                    const billingAccountResponse = await getBillingAccount(this.id);
                    const billingAccountId = billingAccountResponse.data?.[0]?.id;
                    if (!billingAccountId) {
                        this.$alert(
                            this.$t('charterDemo.unableToFindBillingAccount', {
                                id: this.id,
                            }),
                        );
                        this.$emit('isDataLoadingUpd', false);
                        return;
                    }

                    this.apiResponse = await getBills(billingAccountId);
                    this.entities = (this.apiResponse?.data ?? []).map(bill => ({
                        ...bill,
                        amountDueStr: `${bill.amountDue.value} ${bill.amountDue.unit}`,
                        // todo add in condition to determine if overdue
                        overdueStr: 'No',
                        billDateTimestamp: new Date(bill.billDate),
                        paymentDueDateTimestamp: new Date(bill.paymentDueDate),
                        billDateStr: this.$localeLibrary.getFormattedDate(new Date(bill.billDate).getTime()),
                        paymentDueDateStr: this.$localeLibrary.getFormattedDate(
                            new Date(bill.paymentDueDate).getTime(),
                        ),
                        stateStr: startCase(bill.state),
                    }));
                    this.$emit('isDataLoadingUpd', false);
                },
                {
                    errorHandler: error => {
                        this.apiResponse = error;
                        this.$emit('dataFetchingError');
                        this.$emit('isDataLoadingUpd', false);
                    },
                },
            );
        },
        entitySelected(entity: any) {
            this.selectedEntity = entity;
            this.showSidebar = true;
        },
    },
});
