
import Vue from 'vue';

// store
import { mapGetters, mapActions } from 'vuex';
import Actions, { Getters, State } from '@/store/mutation-types';

// validaytion
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import { Modules } from '@/store/store';

// Components
import AbstractEditPageWrapper from '@/components/layout/AbstractEditPageWrapper.vue';
import { ALERT_TYPES } from '@/common/alerts/Alert';
import AppTextareaV3 from '@/components/partials/inputs/AppTextareaV3.vue';
import AppToggle from '@/components/partials/inputs/AppToggle.vue';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import { ICON_TYPES } from '@/common/iconHelper';
import AppMultiselectV3 from '@/components/partials/inputs/AppMultiselectV3.vue';
import AppHeader from '@/components/layout/AppHeader.vue';
import AppInputV3 from '@/components/partials/inputs/AppInputV3.vue';
import ConditionsExpressionEditor from '@/__new__/features/charging/ConditionsExpressionEditor.vue';
import MutationDialog from '@/components/partials/MutationDialog.vue';
import AppLabel from '@/components/partials/AppLabel.vue';
import EditorButtons from '@/components/layout/EditorButtons.vue';
import AppIcon from '@/components/partials/icon/AppIcon.vue';

// Mixin
import entityEditorMixin from '@/common/entityEditorMixin';
import mutationDialogMixin from '@/components/partials/mutations/mutationDialogMixin.vue';
import ChargingCommonActionsMixin from '@/__new__/features/charging/ChargingCommonActionsMixin.vue';

// http
import { getPolicyRuleEntityDraft } from '@/__new__/services/dno/charging/http/policyRules';
import { getUserNameById } from '@/__new__/services/portal/profile/http/profile';

// Routers
import RouteNames from '@/router/routeNames';

// helpers
import {
    getProperlyFormattedMultilangFieldValue,
    getAffectedEntities,
    formatMutationDialogEntities,
    EDITOR_MODE,
} from '@/common/entities/entityHelper';
import permissionsService, { isUserAllowed } from '@/services/permissions/permissions.service';
import * as Sentry from '@sentry/vue';
import ENTITY_TYPES from '@/common/entities/entityTypes';
import {
    parseExpressionToTree,
    parseExpressionsFromBe,
    CONDITION_OBJECT_TYPE,
} from '@/__new__/services/dno/charging/common/expression';
import sortPrStatusesByExpression from '@/__new__/services/dno/charging/common/sortPrStatusesByExpression';
import cloneDeep from 'lodash/cloneDeep';
import { languageMap } from '@/common/locale/language';
import isEmpty from 'lodash/isEmpty';
import { PolicyRule, ChargingEntityVersion } from '@/__new__/services/dno/charging/models/ChargingInterfaces';
import Button from '@/common/button/Button';
import { LABEL_COLOR } from '@/common/labelsHelper';
import { STATUS_CODES } from '@/common/commonHelper';
import { PolicyRuleDraft } from '@/__new__/services/dno/charging/models/PolicyRule';
import { loadVersionHistory, CHARGING_ENTITY_TYPES } from '@/__new__/services/dno/charging/common/versioningHelper';

export default Vue.extend({
    name: 'PolicyRuleEditor',
    components: {
        AbstractEditPageWrapper,
        AppHeader,
        AppInputV3,
        AppTextareaV3,
        AppToggle,
        AppMultiselectV3,
        AppButton,
        ConditionsExpressionEditor,
        MutationDialog,
        AppLabel,
        EditorButtons,
        AppIcon,
    },
    mixins: [validationMixin, entityEditorMixin, mutationDialogMixin, ChargingCommonActionsMixin],
    data() {
        return {
            LABEL_COLOR,
            ICON_TYPES,
            BUTTON_TYPES,
            EDITOR_MODE,
            STATUS_CODES,
            entityId: null as string | null,
            name: '' as string | Record<string, string>,
            description: {} as string | Record<string, string>,
            remark: '' as string,
            approveOnCreate: false as boolean,
            id: null as string | null,
            version: null as number | null,
            entityType: ENTITY_TYPES.POLICY_RULE as string,
            selectedLanguage: '' as string,
            expression: [] as any[],
            pcModel: [
                {
                    pc: {},
                    status: '',
                    statuses: [],
                    conditionUUID: null,
                    label: `${this.$i18n.t('charging.policyCounters.policyCounter')}: `,
                    inverted: false,
                    isConditionNew: true,
                },
            ] as any[],
            selectedContent: false as boolean,
            data: {} as any,
            readonly: false as boolean,
            updateName: this.$i18n.t('generic.N/A') as string,
            entityDraft: null as any | null,
            isUnpublished: false as boolean,
            isOnlyDraft: false as boolean,
            updateTime: null as string | null,
            revertConfirmationButton: new Button({
                label: this.$i18n.t('productCatalog.editors.revert'),
                alertType: ALERT_TYPES.warning,
                handler: () => this.initData(true),
            }),
            versionsHistory: [] as ChargingEntityVersion[],
            currentVersion: null as ChargingEntityVersion | null,
        };
    },
    validations: {
        name: {
            required,
        },
        pcModel: {
            $each: {
                pc: {
                    required,
                },
                status: {
                    required,
                },
            },
        },
    },
    computed: {
        ...mapGetters('operators', [Getters.languageDefault]),
        ...mapGetters(Modules.chargingV2, [
            Getters.GET_POLICY_RULES,
            Getters.GET_POLICY_RULE_BY_ID,
            Getters.GET_POLICY_RULE_BY_VERSION,
            Getters.GET_POLICY_COUNTER_BY_ID,
            Getters.GET_APPROVED_POLICY_COUNTERS,
        ]),
        policyRules(): PolicyRule[] {
            return this[Getters.GET_POLICY_RULES] || [];
        },
        policyCounterOptions(): { id: string; name: string }[] {
            const policyCounters = this[Getters.GET_APPROVED_POLICY_COUNTERS] || [];
            return policyCounters
                .filter((pr: PolicyRule) => pr?.statuses?.length)
                .sort((entity1: PolicyRule, entity2: PolicyRule) => entity1.name.localeCompare(entity2.name))
                .map((pr: PolicyRule) => ({
                    id: pr.id,
                    name: pr.name,
                    statuses: pr.statuses,
                }));
        },
        policyRuleData(): PolicyRule {
            return this[Getters.GET_POLICY_RULE_BY_ID](this.entityId) || {};
        },
        policyRuleByVersion(): PolicyRule | null {
            return this[Getters.GET_POLICY_RULE_BY_VERSION];
        },
        alreadyUsedName(): boolean {
            const dataForUser = this.policyRules;
            if (this.isEditingExistingEntity) {
                if (this.name !== this.policyRuleData?.name) {
                    return Object.values(dataForUser).some((pr: PolicyRule) => pr.name === this.name);
                }
                return false;
            }
            return Object.values(dataForUser).some((pr: PolicyRule) => pr.name === this.name);
        },
        languages(): string {
            return this.$store.state.operators[State.Languages];
        },
        isEditingExistingEntity(): boolean {
            return this.entityId && !this.$route.params.clone;
        },
        affectedEntities(): object {
            return getAffectedEntities(this.$attrs.id, this.entityType);
        },
        getStatusesInUse(): any[] {
            return this.expression.reduce((data: any, current: any) => {
                let uuids = [];
                if (current?.multipleRowsExpression) {
                    uuids = current.value.reduce(
                        (multiData: any, expression: any) => [
                            ...new Set([...multiData, ...expression.value.map((el: any) => el.conditionUUID)]),
                        ],
                        [],
                    );
                } else {
                    uuids = current.value.map((el: any) => el.conditionUUID);
                }

                return [...new Set([...data, ...uuids])];
            }, []);
        },
        isDraft() {
            return this.entityId && !this.readonly && this.entityDraft;
        },
        isPublished() {
            return this.entityId && this.readonly;
        },
        allowEditDraftBtn() {
            return this.isPublished && !this.isOnlyDraft;
        },
        isRevertAvailable() {
            return this.isEditing && !this.readonly && !this.isOnlyDraft;
        },
        allowViewPublishedBtn() {
            return this.entityId && !this.readonly && !this.isOnlyDraft;
        },
        getCurrentVersion(): string {
            return this.currentVersion?.chargingVersion || '';
        },
        pageTitle(): string {
            if (this.readonly) {
                return this.$i18n.t('charging.policyRules.name');
            }
            if (this.entityId) {
                return this.$i18n.t('charging.policyRules.editPr');
            }
            return this.$i18n.t('charging.policyRules.addnewPr');
        },
    },
    created() {
        this.$withLoadingSpinner(
            async () => {
                const { id, readonly, mode, clone } = this.$route.params;

                this.entityId = id;
                if (id && readonly) {
                    this.readonly = readonly;
                }

                if (this.entityId && mode) {
                    this.readonly = mode === EDITOR_MODE.VIEW;
                }

                const promises = [this[Actions.REQUEST_POLICY_RULES]()];

                if (permissionsService.chargingPolicyCountersEnabled() && isUserAllowed('PolicyCountersReadOnly')) {
                    promises.push(this[Actions.REQUEST_POLICY_COUNTERS]());
                }

                if (!clone) {
                    promises.push(this.loadEntityDraft());
                }

                await Promise.all(promises);
                this.selectedLanguage = (this[Getters.languageDefault] || languageMap.en.key).toString();

                if (id) {
                    this.initData();
                    if (this.readonly) {
                        this.loadVersionHistory(CHARGING_ENTITY_TYPES.POLICY_RULE, this.entityId);
                    }
                }
            },
            {
                errorHandler: () => {
                    this.$eventBus.$emit('showAlert', {
                        message: this.$i18n.t('alertMessage.failedToLoadNecessaryData'),
                    });
                },
            },
        );
    },
    methods: {
        ...mapActions(Modules.chargingV2, [
            Actions.REQUEST_POLICY_RULES,
            Actions.REQUEST_POLICY_RULE_BY_VERSION,
            Actions.REQUEST_POLICY_COUNTERS,
        ]),
        isEmpty,
        onPolicyCounterSelect(index: number): void {
            this.pcModel[index].pc = {
                ...this.pcModel[index].pc,
                label: this.pcModel[index].pc?.name,
            };

            if (!this.pcModel[index].isConditionNew) {
                this.expression = [];
            }

            this.pcModel[index].conditionUUID = this.pcModel[index].pc.id;
            this.pcModel[index].status = '';
            this.pcModel[index].label = this.getPcModalLabel(index);
            this.pcModel[index].statuses = this.pcModel?.[index].pc?.statuses || [];
            this.pcModel[index].isConditionNew = false;
        },
        populateFields(): object {
            if (this.expression.length === 0 && this.pcModel.length) {
                this.createExpression();
            }

            return {
                conditions: {
                    parameters: this.pcModel.reduce((data: any, current: any) => {
                        data[current.conditionUUID] = {
                            type: 'policy_counter_status',
                            policy_counter_id: current.pc.id,
                            policy_counter_status: current.status,
                            inverted: current.inverted,
                        };
                        return data;
                    }, {}),
                    expr: parseExpressionToTree(this.expression) || [],
                },
            };
        },
        onSave(isPublish = true): void {
            if (isPublish) {
                this.$v.$touch();
                if (this.$v.$invalid || !this.checkDataBeforeSave()) {
                    return;
                }
            }

            this.saveData(isPublish);
        },
        async saveData(isPublish = true): Promise<void> {
            this.data = {
                name: this.name,
                description: this.description,
                remark: this.remark,
                ...this.populateFields(),
            };

            await this.saveEntityData(this.isOnlyDraft, this.affectedEntities.policy_rule || [], isPublish);
        },
        async initData(forceInitPublished = false): Promise<void> {
            const pr = this.policyRuleData;
            let prData: any;

            if (this.entityDraft && !forceInitPublished) {
                const res = cloneDeep(this.entityDraft);
                const draftData = new PolicyRuleDraft({
                    ...res,
                    ...res.data,
                });

                draftData.version = pr.version;
                prData = draftData;
                this.isOnlyDraft = pr?.state === STATUS_CODES.NA;

                if (pr?.update_time <= prData?.update_time) {
                    this.isUnpublished = true;
                }
            } else if (this.currentVersion?.chargingVersion) {
                await this.loadHistoryEntity(this.entityId, this.currentVersion?.chargingVersion);
                prData = cloneDeep(this.policyRuleByVersion);
            } else {
                prData = cloneDeep(pr);
            }

            const { name, description, version } = prData;

            this.name = name;
            if (this.$route.params.clone) {
                this.name += ' (cloned)';
            }

            this.description = getProperlyFormattedMultilangFieldValue(description);
            this.version = version;
            this.updateTime = prData.update_time;
            this.getUpdateUserName(prData?.updatePortalId);
            this.loadConditionsData(prData);
        },
        loadConditionsData(policyRuleData: any): void {
            const { expr, parameters } = cloneDeep(policyRuleData.conditions);
            this.expression = parseExpressionsFromBe(expr, CONDITION_OBJECT_TYPE.POLICY_COUNTER, parameters) || [];

            if (!expr) {
                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('charging.CSG.editor.conditionsExpressionError'),
                    type: ALERT_TYPES.error,
                });
            }

            const sortedParameters = this.expression.length
                ? sortPrStatusesByExpression(this.expression)
                : Object.keys(parameters);

            this.pcModel = sortedParameters?.reduce((data, current) => {
                const [pcObj] = Object.values(parameters).filter(
                    ({ policy_counter_id: policyCounterId }) => policyCounterId === current,
                );

                if (pcObj) {
                    const { policy_counter_status: pcStatus, policy_counter_id: pcId, inverted = false } = pcObj;
                    const pcData = this.getPolicyCounterData(pcId);

                    data.push({
                        conditionUUID: current,
                        label: `${this.$i18n.t('charging.policyCounters.policyCounter')}: ${
                            pcData?.name
                        } - ${pcStatus}`,
                        pc: {
                            ...pcData,
                            label: pcData?.name || '',
                        },
                        statuses: pcData?.statuses || [],
                        status: pcStatus,
                        inverted,
                        isConditionNew: false,
                    });
                }

                return data;
            }, []);
        },
        checkDataBeforeSave(): boolean {
            if (this.alreadyUsedName) {
                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('alertMessage.nameOfPolicyRuleMustBeUnique'),
                });
                return false;
            }
            if (!this.validateStatusesExpressions()) {
                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('charging.policyRules.statusExpressionsError'),
                    type: ALERT_TYPES.error,
                });
                return false;
            }

            return true;
        },
        validateStatusesExpressions(): boolean {
            const getList = this.pcModel.map((el: any) => el.conditionUUID);
            return this.pcModel.length > 1 ? getList.every((el: any) => this.getStatusesInUse.includes(el)) : true;
        },
        removeCounter(index: number): void {
            if (this.pcModel.length > 1 && this.getStatusesInUse.includes(this.pcModel?.[index]?.conditionUUID)) {
                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('charging.policyRules.statusInUse'),
                    type: ALERT_TYPES.error,
                });

                return;
            }

            this.pcModel.splice(index, 1);
            this.expression = [];
        },
        createNewContent(): void {
            this.pcModel.push({
                pc: {},
                status: null,
                statuses: [],
                conditionUUID: null,
                label: this.getPcModalLabel(),
                inverted: false,
                isConditionNew: true,
            });

            if (this.pcModel.length === 1) {
                this.createExpression();
            }
        },
        createExpression(): void {
            this.expression.push({
                value: [this.pcModel[0]],
                relation: null,
                groupRelation: null,
            });
        },
        getPolicyCounterData(id: string): object {
            return this[Getters.GET_POLICY_COUNTER_BY_ID](id) || {};
        },
        getAffectedEntities(): object {
            return formatMutationDialogEntities(this.affectedEntities);
        },
        reloadEditor(mode: string) {
            const { id, companyId } = this.$route.params;
            // Use push to list page because router don`t want ot reload same page
            this.$router
                .push({
                    name: RouteNames.CHARGING_POLICY_RULES,
                    params: { companyId },
                })
                .then(() => {
                    this.$router.push({
                        name: RouteNames.CHARGING_POLICY_RULE_EDITOR,
                        params: {
                            id,
                            mode,
                            companyId,
                        },
                    });
                });
        },
        resetToPublished(): void {
            this.$alert(this.$i18n.t('productCatalog.editors.revertWarning'), {
                type: ALERT_TYPES.warning,
                buttons: [this.revertConfirmationButton],
            });
        },
        async loadEntityDraft() {
            if (this.entityId) {
                try {
                    if (!this.readonly) {
                        const result = await getPolicyRuleEntityDraft(this.entityId);
                        this.entityDraft = result?.data?.data?.[this.entityId] || null;
                    }
                } catch (e) {
                    this.$alert(this.$i18n.t('alertMessage.failedToLoadNecessaryData'), { type: ALERT_TYPES.error });
                }
            }
        },
        async getUpdateUserName(id: number): Promise<void> {
            try {
                if (id) {
                    const response = await getUserNameById(Number(id));
                    if (response?.data) {
                        this.updateName = response.data;
                    }
                }
            } catch (e) {
                Sentry.captureException(e);
            }
        },
        async loadVersionHistory(entityType: CHARGING_ENTITY_TYPES, id: string): Promise<void> {
            this.versionsHistory = await loadVersionHistory(entityType, id);
            [this.currentVersion] = this.versionsHistory;
        },
        selectVersion(entry: ChargingEntityVersion) {
            this.currentVersion = entry;
            this.initData();
        },
        async loadHistoryEntity(id: string, version: string): Promise<void> {
            if (id && this.currentVersion?.chargingVersion) {
                await this.$withProgressBar(
                    async () => {
                        await this[Actions.REQUEST_POLICY_RULE_BY_VERSION]({
                            id,
                            version,
                        });
                    },
                    {
                        errorHandler: () => {
                            this.$alert(this.$i18n.t('alertMessage.failedToLoadNecessaryData'), {
                                type: ALERT_TYPES.error,
                            });
                        },
                    },
                );
            }
        },
        getPcModalLabel(index: number): string {
            let status = `${this.$i18n.t('charging.policyCounters.policyCounter')}: `;

            if (index !== undefined) {
                status += `${this.pcModel?.[index]?.pc?.name} - ${this.pcModel?.[index]?.status}`;
            }

            return status;
        },
        setPcModalLabel(index: number): void {
            this.pcModel[index].label = this.getPcModalLabel(index);
        },
    },
});
