
// VueX
import { mapGetters } from 'vuex';
import { Modules } from '@/store/store';
import { Getters } from '@/store/mutation-types';

// components
import AppButtonDropdown from '@/components/partials/inputs/AppButtonDropdown.vue';
import CancelDeviceLocationModal from './CancelDeviceLocationModal.vue';
import NetworkCoverageModal from './NetworkCoverageModal.vue';

// Helpers
import { isUserAllowed } from '@/services/permissions/permissions.service';
import { ICON_TYPES } from '@/common/iconHelper';
import { USER_MANAGER_HIERARCHY } from '@/__new__/features/customerCare/common/customerCareHelper';
import Subscriber from '@/__new__/services/dno/user/models/Subscriber';
import {
    SUPPORT_ACTION,
    SupportActionMenuItem,
} from '@/__new__/features/customerCareSuite/common/supportActionsHelper';

export default {
    name: 'SubscriberSupportActions',
    components: {
        AppButtonDropdown,
        CancelDeviceLocationModal,
        NetworkCoverageModal,
    },
    props: {
        subscriberId: {
            default: '',
            type: String,
        },
    },
    data() {
        return {
            actions: [
                {
                    label: this.$t('customerCare.cancelDeviceLocation.modalTitle'),
                    value: SUPPORT_ACTION.CANCEL_DEVICE_LOCATION,
                    visible: isUserAllowed('UMSubscriberCancelDeviceLocation'),
                },
                {
                    label: this.$t('customerCare.networkCoverage.modalTitle'),
                    value: SUPPORT_ACTION.NETWORK_COVERAGE,
                    visible: isUserAllowed('UMSubscriberNetworkCoverage'),
                },
            ] as SupportActionMenuItem[],
            modalVisible: {
                [SUPPORT_ACTION.CANCEL_DEVICE_LOCATION]: false,
                [SUPPORT_ACTION.NETWORK_COVERAGE]: false,
            },

            SUPPORT_ACTION,
            ICON_TYPES,
        };
    },
    computed: {
        ...mapGetters(Modules.customerCareSuite, [Getters.GET_ASSOCIATED_UM_ENTITY_BY_TYPE_AND_ID]),
        subscriber(): Subscriber {
            return this[Getters.GET_ASSOCIATED_UM_ENTITY_BY_TYPE_AND_ID]({
                targetType: USER_MANAGER_HIERARCHY.SUBSCRIBER,
                targetId: this.subscriberId,
            });
        },
        visibleActions(): SupportActionMenuItem[] {
            return this.actions.filter(({ visible }) => visible);
        },
    },
    methods: {
        selectSupportAction(action: SupportActionMenuItem) {
            this.modalVisible[action.value] = true;
        },
        closeActionModal(action: SUPPORT_ACTION) {
            this.modalVisible[action] = false;
        },
    },
};
