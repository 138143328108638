
import Vue from 'vue';

// Components
import AssociatedUMEntityPicker from '@/__new__/features/customerCareSuite/components/AssociatedUMEntityPicker.vue';
import AppField from '@/components/partials/AppField.vue';

// Helpers
import { USER_MANAGER_HIERARCHY } from '@/__new__/features/customerCare/common/customerCareHelper';
import { TransactionV4 } from '@/__new__/services/dno/orders/models/TransactionV4';
import { formatAmountBasedOnCurrency } from '@/common/formatting';
import { getPaymentTransactionTypeInString } from '@/__new__/features/customerCareSuite/common/transactionHelper';
import localeLibrary from '@/common/locale/localeLibrary';

// HTTP
import paymentHTTP from '@/http/payment';
import ordersHTTP from '@/__new__/services/dno/orders/http/orders';

export default Vue.extend({
    name: 'LatestTransactionTile',
    components: {
        AssociatedUMEntityPicker,
        AppField,
    },
    data() {
        return {
            selectedAssociatedAccount: null,
            transactionHistories: [],
            billingTransactionHistorySorted: [],

            // proxy
            USER_MANAGER_HIERARCHY,
        };
    },
    computed: {
        latestTransactionItem() {
            return this.billingTransactionHistorySorted.length ? this.billingTransactionHistorySorted[0] : {};
        },
        chargeAmountFormatted() {
            return formatAmountBasedOnCurrency(this.latestTransactionItem.amount) || this.$i18n.t('generic.N/A');
        },
        chargeTypeFormatted() {
            return (
                getPaymentTransactionTypeInString(this.latestTransactionItem.paymentTransactionType) ||
                this.$i18n.t('generic.N/A')
            );
        },
        chargeFor() {
            return this.latestTransactionItem?.chargesData?.[0]?.offerName || this.$i18n.t('generic.N/A');
        },
        chargeTimestamp() {
            return (
                (this.latestTransactionItem?.timestamp &&
                    localeLibrary.getFormattedDateAndTime(this.latestTransactionItem.timestamp)) ||
                this.$i18n.t('generic.N/A')
            );
        },
        mainDataFormatted() {
            return [
                {
                    name: this.$i18n.t('generic.chargeAmount'),
                    value: this.chargeAmountFormatted,
                },
                {
                    name: this.$i18n.t('generic.chargeType'),
                    value: this.chargeTypeFormatted,
                },
                {
                    name: this.$i18n.t('customerCare.transactionHistory.chargeFor'),
                    value: this.chargeFor,
                },
                {
                    name: this.$i18n.t('generic.timestamp'),
                    value: this.chargeTimestamp,
                },
            ];
        },
    },
    watch: {
        selectedAssociatedAccount: {
            handler(newVal) {
                if (newVal) {
                    this.fetchTileData();
                }
            },
            immediate: true,
            deep: true,
        },
    },
    methods: {
        fetchTileData() {
            this.$emit('isDataLoadingUpd', true);
            this.$withProgressBar(
                async () => {
                    const rawResponse = await paymentHTTP.getTransactionHistory({
                        target_id: this.selectedAssociatedAccount.id,
                        target_type: USER_MANAGER_HIERARCHY.ACCOUNT,
                        include_tax: true,
                    });
                    this.transactionHistories = rawResponse?.data?.transactions;
                    const billingResponse = await ordersHTTP.getTransactions(
                        this.selectedAssociatedAccount.id,
                        USER_MANAGER_HIERARCHY.ACCOUNT,
                        'DESC',
                    );
                    this.billingTransactionHistorySorted = billingResponse?.data?.transactions
                        .map(transactionJSON => new TransactionV4(transactionJSON))
                        .sort((a, b) => a.timestamp - b.timestamp);

                    this.$emit('isDataLoadingUpd', false);
                },
                {
                    errorHandler: () => {
                        this.$emit('dataFetchingError');
                        this.$emit('isDataLoadingUpd', false);
                    },
                },
            );
        },
    },
});
