
import Vue from 'vue';

import { TranslateResult } from 'vue-i18n';
import {
    DATA_BALANCE_OPERATOR,
    MAP_DATA_BALANCE_OPERATOR_VALUES,
} from '@/__new__/services/dno/charging/common/dataBalanceCondition';

import { formatDataAmount } from '@/common/formatting';

export default Vue.extend({
    name: 'DataBalanceValue',
    props: {
        value: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            formatDataAmount,
        };
    },
    methods: {
        getValue(val: DATA_BALANCE_OPERATOR): TranslateResult {
            const data = MAP_DATA_BALANCE_OPERATOR_VALUES.filter(el => el.id === val);
            return data?.[0]?.label || '';
        },
    },
});
