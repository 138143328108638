<template>
    <div>
        <div
            v-t="'charging.chargingSpecifications.editor.conditionText.subscriberLocation'"
            class="table-title"
        />
        <div class="d-flex">
            <div class="mr-2">
                {{ invertedFormatted }}
            </div>
            <div>{{ formattedZoneCodeType }}</div>
        </div>
        <div
            v-for="source in sourcesFormatted"
            :key="source.key"
            class="d-flex"
        >
            <div class="mr-2">{{ source.key }}:</div>
            <div>{{ source.value }}</div>
        </div>
    </div>
</template>

<script>
import { BOOLEAN_TO_CONDITION } from '@/__new__/services/dno/charging/common/conditionsHelper';
import { ENUM_TO_ZONE_TYPE } from '@/__new__/features/charging/conditionParameters/conditions/SubscriberLocationCondition.vue';

export default {
    name: 'SubscriberLocationValue',
    props: {
        value: {
            type: Object,
            required: true,
        },
    },
    computed: {
        invertedFormatted() {
            return BOOLEAN_TO_CONDITION.get(this.value.inverted).toUpperCase();
        },
        formattedZoneCodeType() {
            return ENUM_TO_ZONE_TYPE[this.value.zone_code_type];
        },
        sourcesFormatted() {
            return Object.entries(this.value.src).map(([key, values]) => ({
                key,
                value: values.join(', '),
            }));
        },
    },
};
</script>
