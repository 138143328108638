import moment from 'moment';
import { TranslateResult } from 'vue-i18n';
import localeLibrary from '@/common/locale/localeLibrary';
import { EXECUTION_TO_LABEL } from '@/__new__/features/orchestrationengine/common/executionStatusIndicatorHelper';

export interface DisplayedExecution {
    id: string;
    planId: string;
    startDate: number;
    endDate: number;
    scheduledDate: number;
    schedulingDate: number;
    startDateFormatted: string | TranslateResult;
    endDateFormatted: string | TranslateResult;
    scheduledDateFormatted: string | TranslateResult;
    schedulingDateFormatted: string | TranslateResult;
    startDateMoment: moment.Moment;
    endDateMoment: moment.Moment;
    scheduledDateMoment: moment.Moment;
    schedulingDateMoment: moment.Moment;
    status: string;
    statusFilterFormatted: string;
}

interface TransactionJSON {
    execution_id: string;
    plan_id: string;
    started_at: number;
    finished_at: number;
    scheduled_for: number;
    scheduled_at: number;
    status: string;
}

/* eslint-disable camelcase */
export default class Execution {
    id: string;
    planId: string;
    startDate: number;
    endDate: number;
    scheduledDate: number;
    schedulingDate: number;
    startDateFormatted: string | TranslateResult;
    endDateFormatted: string | TranslateResult;
    scheduledDateFormatted: string | TranslateResult;
    schedulingDateFormatted: string | TranslateResult;
    startDateMoment: moment.Moment;
    endDateMoment: moment.Moment;
    scheduledDateMoment: moment.Moment;
    schedulingDateMoment: moment.Moment;
    status: string;
    statusFilterFormatted: string | TranslateResult;

    constructor(transactionJSON: TransactionJSON) {
        this.id = transactionJSON?.execution_id;
        this.planId = transactionJSON?.plan_id || '';
        this.startDate = transactionJSON?.started_at;
        this.endDate = transactionJSON?.finished_at;
        this.scheduledDate = transactionJSON?.scheduled_for;
        this.schedulingDate = transactionJSON?.scheduled_at;
        this.startDateFormatted = localeLibrary.getFormattedDateAndTime(transactionJSON?.started_at);
        this.endDateFormatted = localeLibrary.getFormattedDateAndTime(transactionJSON?.finished_at);
        this.scheduledDateFormatted = localeLibrary.getFormattedDateAndTime(transactionJSON?.scheduled_for);
        this.schedulingDateFormatted = localeLibrary.getFormattedDateAndTime(transactionJSON?.scheduled_at);
        this.startDateMoment = moment(localeLibrary.normalizeTimestamp(transactionJSON?.started_at));
        this.endDateMoment = moment(localeLibrary.normalizeTimestamp(transactionJSON?.finished_at));
        this.scheduledDateMoment = moment(localeLibrary.normalizeTimestamp(transactionJSON?.scheduled_for));
        this.schedulingDateMoment = moment(localeLibrary.normalizeTimestamp(transactionJSON?.scheduled_at));
        this.status = transactionJSON?.status || '';
        this.statusFilterFormatted = this.status ? EXECUTION_TO_LABEL[this.status] : '';
    }
}
