import i18n from '@/i18n';

export const SIDEBAR_TABS_TRANSACTION_HISTORY_V4_ENUM = {
    TRANSACTION: 'transaction',
    PAYMENT: 'payment',
    CHARGE_LINE_ITEM: 'chargeLineItem',
};

export const PAYMENT_TRANSACTION_TYPES = {
    AUTO: 1,
    MANUAL: 3,
    REFUND: 5,
};

export const PAYMENT_TRANSACTION_TYPES_NAME_MAP = {
    [PAYMENT_TRANSACTION_TYPES.AUTO]: i18n.t('customerCare.transactionHistory.paymentTransactionTypes.auto'),
    [PAYMENT_TRANSACTION_TYPES.MANUAL]: i18n.t('customerCare.transactionHistory.paymentTransactionTypes.manual'),
    [PAYMENT_TRANSACTION_TYPES.REFUND]: i18n.t('customerCare.transactionHistory.paymentTransactionTypes.refund'),
};

export default {};
