import {
    createTreeNode,
    OPERATION_TYPE_TO_OPERATION_MAP,
    OPERATION,
    OPERATION_TYPE,
    CUSTOM_TYPE,
    DATA_TYPES,
    getOptionsFromConfig,
} from '@/common/conditions-tree';
import { camelToSnakeCase } from '@/common/utils';

export const convertToClientTree = (root, optionsConfig) => {
    const nodesToUpdate = [];

    const createTree = (rawNode, parentNodeId, optionsConfigPath, currentSchema, currentField) => {
        if (!rawNode) {
            return;
        }
        let args = null;
        let selectedOp = OPERATION_TYPE_TO_OPERATION_MAP[rawNode.op];
        let extraAndOp = null;

        if (optionsConfig) {
            // Overwrite with options config operation (with path)
            let options = getOptionsFromConfig(optionsConfig, optionsConfigPath)
                .map(g => g.operations)
                .flat();
            selectedOp = options.find(o => o.op === rawNode.op);
            const schemaFields = options.filter(o => o.op === CUSTOM_TYPE.SCHEMA).map(o => o.config.schemaField);

            // Try to find "FIELD" type. When node is inside schema and all nested comparison nodes have the same key (field name)
            // Try to find an "SCHEMA" type. Look for AND conditions with an EQUAL nested condition with "event_type"
            // Try to find options config path for every node

            // returns the field name if all "keys" in the tree are the same
            const getFieldForTree = (node, field) => {
                if (field && node.args.key && field !== node.args.key) {
                    return false;
                }
                if (!field && node.args.key) {
                    return node.args.key;
                }
                let failed = false;
                (node.args.nested_conditions ?? []).forEach(c => {
                    const nestedResult = getFieldForTree(c, field);
                    if (!nestedResult) {
                        failed = true; // fail if nested result is null/false
                    }
                    if (!field) {
                        const fieldOp = options.find(o => o.config.path.split('.').pop() === nestedResult);
                        if (!fieldOp) {
                            failed = true; // if not an options in dropdown, return false
                        }
                        field = nestedResult;
                    } else if (nestedResult !== field) {
                        // if a nested result doesn't match, return false
                        failed = true;
                    }
                });
                return failed ? false : field;
            };
            if (currentSchema && !currentField) {
                const fieldName = getFieldForTree(rawNode);
                if (fieldName) {
                    const fieldOp = options.find(o => o.config.path.split('.').pop() === fieldName);
                    if (fieldOp) {
                        currentField = fieldName;
                        options = getOptionsFromConfig(optionsConfig, fieldOp.config.path)
                            .map(g => g.operations)
                            .flat();
                        selectedOp = options.find(o => o.op === rawNode.op);
                        // create a new field node
                        const fieldNode = createTreeNode({
                            parentId: parentNodeId,
                            operation: fieldOp.op,
                            config: fieldOp.config,
                            args,
                        });
                        nodesToUpdate.push(fieldNode);
                        parentNodeId = fieldNode.id; // overwrite parentId so rawNode gets added as a child of this new field node
                    }
                }
            }

            const couldBeSchema = rawNode.op === OPERATION.AND && !currentSchema;
            if (couldBeSchema) {
                let schemaField, schemaName;
                for (let field of schemaFields) {
                    schemaName = rawNode.args.nested_conditions.find(c => c.args.key === field)?.args.value;
                    if (schemaName) {
                        schemaField = field;
                        break;
                    }
                }
                if (schemaName) {
                    const schemaOp = options.find(o => o.config.path.split('.').pop() === schemaName);
                    if (schemaOp) {
                        selectedOp = schemaOp;
                        // update the operation to an SCHEMA operation instead of an AND operation
                        currentSchema = schemaName;
                        // remove the event_type == event_name condition from nested
                        rawNode.args.nested_conditions = rawNode.args.nested_conditions.filter(
                            c => c.args.key !== schemaField,
                        );
                        // need to add extra AND node to event type if more than one
                        if (schemaName && rawNode.args.nested_conditions.length > 1) {
                            let subOptions = getOptionsFromConfig(optionsConfig, schemaOp.config.path)
                                .map(g => g.operations)
                                .flat();
                            extraAndOp = subOptions.find(o => o.config.path.split('.').pop() === OPERATION.AND);
                        }
                    }
                }
            }
        }

        if (selectedOp.args) {
            args = {};
            Object.keys(selectedOp.args).forEach(key => {
                if (currentField && rawNode.args[camelToSnakeCase(key)] === currentField) {
                    // ignore key = field_name since we'll be converting it into a FIELD operation
                    return;
                }
                args[key] = rawNode.args[camelToSnakeCase(key)];
            });
        }
        let resultNode = createTreeNode({
            parentId: parentNodeId,
            operation: selectedOp.op,
            config: selectedOp.config,
            args,
        });
        nodesToUpdate.push(resultNode);

        // needed for legacy rules event_type in AND with 3+ children
        if (extraAndOp) {
            const extraAndNode = createTreeNode({
                parentId: resultNode.id,
                operation: OPERATION.AND,
                config: extraAndOp.config,
                args,
            });
            nodesToUpdate.push(extraAndNode);
            resultNode = extraAndNode; // overwrite so this is the new parent
        }

        // iterate througn nested nodes
        // eslint-disable-next-line camelcase
        if (rawNode.args?.nested_conditions?.length) {
            rawNode.args.nested_conditions.forEach(condition =>
                createTree(condition, resultNode.id, resultNode.config.path, currentSchema, currentField),
            );
        }
    };

    createTree(root);

    return nodesToUpdate;
};

export const convertToServerTree = root => {
    const createTree = (node, fieldKey) => {
        const args = {};
        let operation = OPERATION_TYPE_TO_OPERATION_MAP[node.operation];
        let type = operation.type;
        let op = operation.op;
        if (type === CUSTOM_TYPE.SCHEMA) {
            // convert the condition into an AND that includes
            // an EQUAL statement where "event_type" == <event_name>
            args.nested_conditions = [];
            type = OPERATION_TYPE.LOGICAL;
            op = OPERATION.AND;
            const equalOperation = OPERATION_TYPE_TO_OPERATION_MAP[OPERATION.EQ];
            const item = {
                args: {
                    dataType: DATA_TYPES.NATIVE,
                    key: node.config.schemaField,
                    value: node.config.path.split('.').pop(),
                },
                config: equalOperation.config,
                id: null,
                nested: null,
                operation: OPERATION.EQ,
                parentId: node.id,
            };
            args.nested_conditions.push(createTree(item));
        } else if (type == CUSTOM_TYPE.FIELD) {
            const fieldKey = node.config.path.split('.').pop();
            return createTree(Object.values(node.nested)[0], fieldKey);
        }

        if (node.nested) {
            args.nested_conditions = args.nested_conditions ?? [];
            Object.values(node.nested).forEach(item => {
                args.nested_conditions.push(createTree(item, fieldKey));
            });
        } else {
            Object.entries(node.args).forEach(([key, value]) => {
                args[camelToSnakeCase(key)] = value;
            });
            if (fieldKey) {
                args.key = fieldKey;
            }
            if (
                node.operation === OPERATION.LT ||
                node.operation === OPERATION.LT_OR_EQ ||
                node.operation === OPERATION.GT ||
                node.operation === OPERATION.GT_OR_EQ
            ) {
                args.data_type = DATA_TYPES.NATIVE;
            }
        }
        return {
            type: type,
            op: op,
            args,
        };
    };
    return createTree(root);
};
