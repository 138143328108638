import { AxiosPromise } from 'axios';
import http from '@/http';
import { type CheckEligibilityResponse, type AddressbookEligibilityParams } from '@/common/addressbookHelper';

const checkEligibility = ({
    provider,
    addressbookId,
    unitNumber,
}: AddressbookEligibilityParams): AxiosPromise<CheckEligibilityResponse> =>
    http({
        method: 'POST',
        url: '/v3addressbook/checkeligibility',
        data: {
            criteria: {
                addressbook_params: {
                    provider_id: provider,
                    addressbook_id: addressbookId,
                    ...(unitNumber && { unit_number: unitNumber }),
                },
            },
        },
    });

export default {
    checkEligibility,
};
