import UserManagerBaseModel from '@/__new__/services/dno/user/models/UserManagerBaseModel';

type AccInfoInOrgResponse = {
    account_id: string;
    account_name: string;
    state: number;
};

export type AccInfoInOrgInstance = {
    id: string;
    fullName: string;
    state: number;
};

type OrganizationResponse = {
    properties: {
        domain: string;
        organization_name: string;
    };
    organization_id: string;
    user_owner: string;
    elements_ids: string[];
    elements: Array<AccInfoInOrgResponse>;
};

type RemappedOrgResponse = {
    name: string;
    organizationId: string;
    userOwnerId: string;
    accountIds: Array<string>;
    accounts: Array<AccInfoInOrgInstance>;
    domain: string;
};

export default class Organization extends UserManagerBaseModel {
    name: string;
    organizationId: string;
    userOwnerId: string;
    accountIds: string[];
    accounts: Array<AccInfoInOrgInstance>;
    domain: string;

    constructor({ name, organizationId, accountIds, accounts, userOwnerId, domain }: RemappedOrgResponse) {
        // Call constructor of UserManagerBaseModel class for creating new object with unique invalidKeys Map
        super();
        this.name = this.validateString('name', name) ? name : '';
        this.organizationId = this.validateString('organizationId', organizationId) ? organizationId : '';
        this.userOwnerId = this.validateString('userOwnerId', userOwnerId) ? userOwnerId : '';
        this.accountIds = this.validateArray('accountIds', accountIds) ? accountIds : [];
        this.accounts = this.validateArray('accounts', accounts) ? accounts : [];
        this.domain = this.validateString('domain', domain) ? domain : '';
    }

    static remapOrganizationFromBe(response: OrganizationResponse): RemappedOrgResponse {
        return {
            /* eslint-disable camelcase */
            name: response?.properties?.organization_name,
            organizationId: response?.organization_id,
            userOwnerId: response?.user_owner,
            accountIds: response?.elements_ids,
            accounts: response?.elements.map((acc: AccInfoInOrgResponse) => ({
                id: acc.account_id,
                fullName: acc.account_name,
                state: acc.state,
            })),
            domain: response?.properties?.domain,
        };
    }
}
