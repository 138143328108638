import HTTP from '@/http';
import { AxiosPromise } from 'axios';
import { TriggerDefinition } from '@/__new__/services/dno/triggers/models/TriggerDefinition';

const triggersRoutePrefix = '/ceapi/cep-site-backend/triggers/event';

export function getTriggerDefinitions(): AxiosPromise<TriggerDefinition[]> {
    return HTTP.get(`${triggersRoutePrefix}/getDefinitions`);
}

export default { getTriggerDefinitions };
