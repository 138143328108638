import UserManagerBaseModel from '@/__new__/services/dno/user/models/UserManagerBaseModel';
import RewardRule from '@/__new__/services/dno/rewards/models/RewardRule';
import localeLibrary from '@/common/locale/localeLibrary';

export default class PayoutRecord extends UserManagerBaseModel {
    id;

    name;

    type;

    state;

    isReferral;

    time;

    executionId;

    executionPlan;

    rewardPayoutId;

    rewardRuleId;

    rewardRule;

    constructor({
        id,
        name,
        type,
        state,
        time,
        isReferral,
        executionId,
        executionPlan,
        rewardPayoutId,
        rewardRuleId,
        rewardRule,
        props,
    }) {
        super();
        this.id = this.validateString('id', id) ? id : '';
        this.name = this.validateString('name', name) ? name : '';
        this.type = this.validateNumberInteger('type', type) ? type : '';
        this.state = this.validateNumberInteger('state', state) ? state : '';
        this.time = this.validateNumberInteger('state', time) ? localeLibrary.getFormattedDate(time) : '';
        this.isReferral = isReferral;
        this.executionId = this.validateString('executionId', executionId) ? executionId : '';
        this.executionPlan = executionPlan || null;
        this.rewardPayoutId = this.validateString('rewardPayoutId', rewardPayoutId) ? rewardPayoutId : '';
        this.rewardRuleId = this.validateString('rewardRuleId', rewardRuleId) ? rewardRuleId : '';
        this.rewardRule = rewardRule || new RewardRule({ id: this.rewardRuleId });
        this.props = props;
    }

    static remapPayoutfromBe(payout) {
        return {
            id: payout?.payout_id || '',
            name: payout?.name || '',
            type: payout?.payout_type || '',
            state: payout?.state || '',
            time: payout?.created_time || payout?.updated_at || '',
            isReferral: payout?.props?.referral_payout || false,
            executionId: payout?.orchestration_execution_id || '',
            rewardPayoutId: payout?.reward_payout_id || '',
            rewardRuleId: payout?.reward_rule_id || '',
            props: payout?.props || null,
        };
    }
}
