<template>
    <nav class="sub-sidebar-container sidebar-text">
        <ul class="options">
            <li
                v-for="section in sections"
                :key="section.key"
                class="section"
                :class="{ active: isActive(section) }"
                @click="changeSection(section)"
            >
                {{ section.label }}
                <img
                    v-if="section.lfInternal"
                    class="sidebar-logo ml-2"
                    src="@/assets/icons/logo-sign-small.svg"
                    alt="LF-Logo"
                />
            </li>
        </ul>
    </nav>
</template>

<script>
export default {
    name: 'SubSidebar',

    props: {
        // Initial state (basically RouteName)
        value: {
            type: String,
            required: true,
        },
        /**
         * List of sections for sidebar to display
         *
         * Structure should be an array of objects with following structure:
         *
         * options: [
         *  {
         *      label: 'stringValueUsedToDisplayItem',
         *      key: 'identifier1',
         *  },
         *  {
         *      label: 'stringValueUsedToDisplayItem',
         *      key: 'identifier2',
         *  },
         * ]
         */
        sections: {
            type: Array,
            required: true,
        },
    },

    data() {
        return {};
    },

    computed: {
        selectedSection() {
            return this.value
                ? this.sections.find(
                      section => section.routeName === this.value || section?.subRoutes.includes(this.value),
                  )
                : {};
        },
    },

    methods: {
        changeSection(section) {
            this.$emit('change', section);
        },
        isActive(section) {
            return section.key === this.selectedSection?.key;
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/mixins';
@import '~@/assets/scss/palette.scss';
@import '~@/assets/scss/sidebar';
@import '~@/assets/scss/layout';
@import '~@/assets/scss/typography';

.sub-sidebar-container {
    height: 100%;
    width: 100%;
    background: $dirty-white;
    padding-top: $spacing-xl;

    .section {
        display: flex;
        width: calc(15rem - #{$spacing-l});
        color: $gray90;
        padding: $spacing-xs $spacing-l;
        cursor: pointer;

        &.active {
            border-radius: 0 3.125rem 3.125rem 0;
            background-color: $blue10;
            color: $blue;
            font-weight: 600;
        }
    }
}

.options {
    position: fixed;
}
</style>
