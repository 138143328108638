<template>
    <Generator
        :fields="fieldsMutated"
        :showValidation="showValidation"
        :disabled="disabled"
        @input="emitValues"
    >
        <template
            v-for="key in slotKeys"
            :slot="key"
        >
            <slot :name="key" />
        </template>
    </Generator>
</template>

<script>
// COMPONENTS
import Generator from './Generator.vue';

export default {
    name: 'PropsFormBuilder',
    components: {
        Generator,
    },
    props: {
        disabled: {
            type: Boolean,
            default: false,
        },
        type: {
            required: true,
            type: String,
        },
        mutationFields: {
            type: Object,
            default: () => ({}),
        },
        showValidation: {
            type: Boolean,
            default: true,
        },
    },
    computed: {
        fields() {
            return this.$lfFormBuilder.config[this.type]?.fields || [];
        },
        fieldsMutated() {
            return this.fields.map(field => {
                const mutatioField = this.mutationFields[field?.key];

                if (mutatioField) {
                    return {
                        ...field,
                        ...mutatioField,
                        componentProps: {
                            ...(field?.componentProps || {}),
                            ...(mutatioField?.componentProps || {}),
                        },
                    };
                }

                return field;
            });
        },
        slotKeys() {
            return Object.keys(this.$slots);
        },
    },
    methods: {
        emitValues(val) {
            this.$emit('input', val);
        },
    },
};
</script>

<style lang="scss" scoped></style>
