<template>
    <AbstractEditPageWrapper>
        <template #header>
            <AppHeader :pageTitle="pageTitle" />
        </template>

        <template #content>
            <div class="editor-section">
                <!-- General -->
                <div class="section">
                    <div class="d-flex align-items-center mb-3">
                        <div class="lf-subtitle mb-0">
                            {{ $i18n.t('generic.general') }}
                            <p
                                v-show="inEditMode && isRewardsDraftEnabled"
                                class="lf-secondary-text mb-0"
                            >
                                {{ $i18n.t('generic.id') }}: {{ $route.params.id }}
                            </p>
                            <p
                                v-show="inEditMode && isRewardsDraftEnabled"
                                class="lf-secondary-text mb-0"
                            >
                                {{ $i18n.t('generic.updateTime') }}:
                                {{ $localeLibrary.getFormattedDateAndTime(updateTime) }}
                            </p>
                            <p
                                v-show="inEditMode && isRewardsDraftEnabled"
                                class="lf-secondary-text mb-0"
                            >
                                {{ $i18n.t('generic.updateUser') }}: {{ updateName }}
                            </p>
                        </div>
                        <LanguageSwitcher
                            v-model="selectedLanguage"
                            :tooltipEnabled="true"
                        />
                        <AppLabel
                            v-if="isDraft"
                            :title="$i18n.t('generic.stateMap.draft')"
                            :color="LABEL_COLOR.gray"
                            class="justify-content-center state-label ml-auto"
                        />
                        <AppLabel
                            v-if="isPublished"
                            :title="$i18n.t('generic.stateMap.published')"
                            :color="LABEL_COLOR.green"
                            class="justify-content-center state-label ml-auto"
                        />
                        <AppLabel
                            v-if="isUnpublished"
                            :title="$i18n.t('generic.stateMap.unpublishedChanges')"
                            :color="LABEL_COLOR.gray"
                            class="justify-content-center state-label ml-3"
                        />
                        <AppButton
                            v-if="allowEditDraftBtn"
                            :buttonType="BUTTON_TYPES.SECONDARY"
                            :label="$i18n.t('generic.editDraft')"
                            :class="['ml-3', { 'ml-auto': noLabels }]"
                            @click="reloadEditor(false)"
                        />
                        <AppButton
                            v-if="allowViewPublishedBtn"
                            :buttonType="BUTTON_TYPES.SECONDARY"
                            :label="$i18n.t('generic.viewPublished')"
                            :class="['ml-3', { 'ml-auto': noLabels }]"
                            @click="reloadEditor(true)"
                        />
                    </div>
                    <AppInputV3
                        v-model="payout.name[selectedLanguage]"
                        class="editor-input-largest mb-3"
                        data-testid="name-input"
                        :label="$i18n.t('generic.name')"
                        :placeholder="$i18n.t('generic.addName')"
                        :invalid="$v.payout.name[selectedLanguage].$error"
                        :disabled="readonly"
                        type="text"
                    />
                    <AppTextareaV3
                        v-model.trim="payout.description[selectedLanguage]"
                        class="editor-input-largest"
                        :label="$i18n.t('generic.description')"
                        :placeholder="$i18n.t('partials.appTextarea.description')"
                        :disabled="readonly"
                    />
                    <div
                        v-if="!isEditing"
                        class="approve-on-create d-flex"
                    >
                        <span class="label">
                            {{ $i18n.t('rewards.editor.general.approveOnCreation') }}
                        </span>
                        <AppToggle
                            v-model="approveOnCreate"
                            class="approve-checkbox"
                            :small="true"
                            :disabled="readonly"
                        />
                    </div>
                </div>
                <!-- Payout details -->
                <div class="section mt-3">
                    <div
                        v-t="'generic.payout'"
                        class="heading"
                    />
                    <div
                        v-t="'rewards.editor.payoutsConditions'"
                        class="sub-heading mb-3"
                    />
                    <AppMultiselectV3
                        v-model="selectedPayoutType"
                        class="editor-input-largest mb-3"
                        data-testid="payout-type-select"
                        :small="true"
                        :additionalLabel="$i18n.t('generic.type')"
                        :options="payoutTypeOptions"
                        :error="$v.selectedPayoutType.$error"
                        :disabled="readonly"
                        label="label"
                        trackBy="id"
                    />
                    <template v-if="isTypeTopUp">
                        <AppMultiselectV3
                            v-model="walletType"
                            class="editor-input-largest mb-3"
                            data-testid="wallet-type-select"
                            :small="true"
                            :additionalLabel="$i18n.t('rewards.walletType')"
                            :options="walletTypeOptions"
                            :disabled="readonly"
                            :error="$v.walletType.$error"
                            label="label"
                        />
                        <AppInputV3
                            v-model="amount"
                            type="number"
                            class="editor-input-largest"
                            data-testid="amount-input"
                            :label="$i18n.t('rewards.topUpAmount')"
                            :disabled="readonly"
                            :invalid="$v.amount.$error"
                            :min="0"
                        />
                    </template>
                    <template v-else>
                        <AppMultiselectV3
                            v-model="selectedReference"
                            class="editor-input-largest mb-3"
                            data-testid="reference-select"
                            :small="true"
                            :additionalLabel="$i18n.t('generic.referenceId')"
                            :options="referenceOptions"
                            :disabled="isReferenceDisabled || readonly"
                            :error="$v.selectedReference.$error"
                            label="label"
                            trackBy="id"
                        />
                        <template v-if="isTypeTicket">
                            <AppInputV3
                                v-model="quantity"
                                type="number"
                                class="editor-input-largest"
                                data-testid="quantity-input"
                                :label="$i18n.t('rewards.quantity')"
                                :disabled="readonly"
                                :invalid="$v.quantity.$error"
                                :min="0"
                            />
                        </template>
                    </template>
                </div>
            </div>
        </template>
        <!-- Footer section -->
        <template #controls>
            <EditorButtons
                :showSaveDraft="isRewardsDraftEnabled && !readonly"
                :showPublish="isRewardsDraftEnabled && !readonly"
                :showSave="!isRewardsDraftEnabled"
                :disableSave="readonly"
                @cancel="onCancel"
                @saveDraft="onSave(false)"
                @publish="onSave(true)"
                @save="onSave(true)"
            />
        </template>
    </AbstractEditPageWrapper>
</template>

<script>
import AbstractEditPageWrapper from '@/components/layout/AbstractEditPageWrapper.vue';
import AppHeader from '@/components/layout/AppHeader.vue';
import AppInputV3 from '@/components/partials/inputs/AppInputV3.vue';
import AppTextareaV3 from '@/components/partials/inputs/AppTextareaV3.vue';
import AppMultiselectV3 from '@/components/partials/inputs/AppMultiselectV3.vue';
import AppToggle from '@/components/partials/inputs/AppToggle.vue';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import { ICON_TYPES } from '@/common/iconHelper';
import { validationMixin } from 'vuelidate';
import entityEditorMixin from '@/common/entityEditorMixin';
import { required, requiredIf } from 'vuelidate/lib/validators';
import { PAYOUT_TYPE_CODES, WALLET_TYPES, payoutTypeMap, walletTypeMap } from '@/modules/rewards/common/payoutsHelper';
import ENTITY_TYPES from '@/common/entities/entityTypes';
import * as Sentry from '@sentry/vue';
import RouteNames from '@/router/routeNames';
import { ALERT_TYPES } from '@/common/alerts/Alert';
import { promotionStateMap } from '@/modules/promotions/common/promotionsEnum';
import { getMultiLangFieldValueByLocale, entityTypeToEntityLabel } from '@/common/entities/entityHelper';
import EditorButtons from '@/components/layout/EditorButtons.vue';
import { manageEntityAdd, manageEntityUpdate } from '@/common/EntityLoadHelper';
import {
    addRewardPayout,
    updateRewardPayout,
    setRewardPayoutDraft,
    getRewardPayoutDraft,
} from '@/__new__/services/dno/rewards/http/rewards';
import { cloneDeep } from 'lodash';
import { getUserNameById } from '@/__new__/services/portal/profile/http/profile';
import { LABEL_COLOR } from '@/common/labelsHelper';
import AppLabel from '@/components/partials/AppLabel.vue';
import { STATUS_CODES } from '@/common/commonHelper';
import permissionsService, { isUserAllowed } from '@/services/permissions/permissions.service';
import LanguageSwitcher from '@/components/partials/inputs/LanguageSwitcher.vue';
import { languageMap } from '@/common/locale/language';

// Vuex
import { mapGetters, mapActions } from 'vuex';
import Actions, { Getters } from '@/store/mutation-types';

export default {
    name: 'PayoutEditor',
    components: {
        AbstractEditPageWrapper,
        AppHeader,
        AppInputV3,
        AppTextareaV3,
        AppToggle,
        AppMultiselectV3,
        AppButton,
        AppLabel,
        EditorButtons,
        LanguageSwitcher,
    },
    mixins: [validationMixin, entityEditorMixin],
    data() {
        return {
            ICON_TYPES,
            BUTTON_TYPES,
            LABEL_COLOR,
            entityType: 'payout', // entityType is used in mixin. However, we need to use another value for BE communication
            selectedLanguage: languageMap.en.key,
            payout: {
                name: {},
                description: {},
            },
            approveOnCreate: false,
            selectedPayoutType: null,
            selectedReference: null,
            walletType: null,
            amount: null,
            quantity: null,
            initialData: null,
            entityDraft: undefined,
            publishedEntity: undefined,
            updateName: this.$i18n.t('generic.N/A'),
            updateTime: null,
            readonly: false,
            isUnpublished: false,
            isOnlyDraft: false,
            isRewardsDraftEnabled: permissionsService.isRewardsDraftEnabled(),
            saveButtonClicked: false,
            draftSavedId: null,
        };
    },

    validations() {
        return {
            payout: {
                name: {
                    [this.selectedLanguage]: {
                        required,
                    },
                },
            },
            selectedPayoutType: {
                required,
            },
            selectedReference: {
                required: requiredIf(() => !this.isReferenceDisabled),
            },
            walletType: {
                required: requiredIf(() => this.isTypeTopUp),
            },
            amount: {
                required: requiredIf(() => this.isTypeTopUp),
            },
            quantity: {
                required: requiredIf(() => this.isTypeTicket),
            },
        };
    },

    computed: {
        ...mapGetters('productcatalog', [Getters.PC_GET_ENTITIES_BY_TYPE_APPROVED]),
        ...mapGetters('rewards', {
            getApprovedEntities: Getters.GET_APPROVED_REWARDS_ENTITIES_BY_TYPE,
            getEntity: Getters.GET_REWARDS_ENTITY_BY_TYPE_AND_ID,
            getEntities: Getters.GET_REWARDS_ENTITIES_LIST_BY_TYPE,
        }),
        ...mapGetters('promotions', [Getters.PROMOTIONS_LIST]),
        topUpProducts() {
            return this[Getters.PC_GET_ENTITIES_BY_TYPE_APPROVED](ENTITY_TYPES.PRODUCT)
                .filter(product => product.type === 'top_up')
                .map(product => ({
                    ...product,
                    label: product.name,
                }));
        },
        vouchers() {
            return this.getApprovedEntities(ENTITY_TYPES.VOUCHER_SET).map(voucherSet => ({
                ...voucherSet,
                label: getMultiLangFieldValueByLocale(voucherSet.data?.name),
            }));
        },
        lotteries() {
            return this.getApprovedEntities(ENTITY_TYPES.LOTTERY).map(lottery => ({
                ...lottery,
                label: getMultiLangFieldValueByLocale(lottery.data?.name),
            }));
        },
        offers() {
            return this[Getters.PC_GET_ENTITIES_BY_TYPE_APPROVED](ENTITY_TYPES.OFFER).map(offer => ({
                ...offer,
                label: offer.name,
            }));
        },
        promotions() {
            return this[Getters.PROMOTIONS_LIST]
                .filter(promotion => {
                    if (promotion.state !== promotionStateMap.APPROVED) {
                        return false;
                    }
                    if (promotion.data.grant_type === 2) {
                        return false;
                    }
                    const currentTime = Math.floor(Date.now() / 1000);
                    const startTime = promotion.data.start_time;
                    const endTime = promotion.data.end_time;
                    if (startTime && endTime) {
                        return startTime <= currentTime && currentTime <= endTime;
                    }
                    if (startTime) {
                        return startTime <= currentTime;
                    }
                    if (endTime) {
                        return currentTime <= endTime;
                    }
                    return true;
                })
                .map(promotion => ({
                    ...promotion,
                    label: getMultiLangFieldValueByLocale(promotion.data?.name),
                }));
        },
        isTypeNone() {
            return this.selectedPayoutType?.value === PAYOUT_TYPE_CODES.NONE;
        },
        isTypeTopUp() {
            return this.selectedPayoutType?.value === PAYOUT_TYPE_CODES.TOP_UP;
        },
        isTypeTicket() {
            return this.selectedPayoutType?.value === PAYOUT_TYPE_CODES.TICKET;
        },
        isReferenceDisabled() {
            return this.isTypeNone || this.isTypeTopUp || !this.selectedPayoutType;
        },
        payoutTypeOptions() {
            return Object.values(PAYOUT_TYPE_CODES)
                .filter(
                    c =>
                        c !== PAYOUT_TYPE_CODES.TICKET ||
                        (permissionsService.lotteryEnabled() && isUserAllowed('LotteriesRead', 'LotteriesWrite')),
                ) // filters out lottery if not enabled
                .map(code => ({
                    id: code,
                    value: code,
                    label: this.$i18n.t(payoutTypeMap.get(code)),
                }));
        },
        walletTypeOptions() {
            return Object.values(WALLET_TYPES).map(code => ({
                id: code,
                value: code,
                label: this.$i18n.t(walletTypeMap.get(code)),
            }));
        },
        payoutTypeOptionsMap() {
            return this.payoutTypeOptions.reduce((acc, option) => {
                acc[option.value] = option;
                return acc;
            }, {});
        },
        referenceOptions() {
            const entitiesMap = {
                [PAYOUT_TYPE_CODES.NONE]: [],
                [PAYOUT_TYPE_CODES.TOP_UP]: this.topUpProducts,
                [PAYOUT_TYPE_CODES.BENEFIT]: this.promotions,
                [PAYOUT_TYPE_CODES.VOUCHER]: this.vouchers,
                [PAYOUT_TYPE_CODES.OFFER]: this.offers,
            };
            if (permissionsService.lotteryEnabled() && isUserAllowed('LotteriesRead', 'LotteriesWrite')) {
                entitiesMap[PAYOUT_TYPE_CODES.TICKET] = this.lotteries;
            }
            return entitiesMap[this.selectedPayoutType?.value] || [];
        },
        referenceOptionsMap() {
            return this.referenceOptions.reduce((acc, op) => {
                acc[op.id] = op;
                return acc;
            }, {});
        },
        walletTypeOptionsMap() {
            return Object.fromEntries(this.walletTypeOptions.map(option => [option.id, option]));
        },
        inEditMode() {
            return Boolean(this.$route.params.id) && !this.$route.params.clone;
        },
        isDraft() {
            return (
                this.$route.params.id && !this.$route.params.readonly && this.entityDraft && this.isRewardsDraftEnabled
            );
        },
        isPublished() {
            return this.$route.params.id && this.$route.params.readonly && this.isRewardsDraftEnabled;
        },
        noLabels() {
            return !this.isDraft && !this.isPublished;
        },
        allowViewPublishedBtn() {
            return (
                this.$route.params.id && !this.$route.params.readonly && !this.isOnlyDraft && this.isRewardsDraftEnabled
            );
        },
        allowEditDraftBtn() {
            return (
                this.$route.params.id && this.$route.params.readonly && !this.isOnlyDraft && this.isRewardsDraftEnabled
            );
        },
        pageTitle() {
            const label = entityTypeToEntityLabel[ENTITY_TYPES.REWARD_PAYOUT];
            if (this.$route.params.readonly) {
                return label;
            }
            if (this.inEditMode) {
                return `${this.$i18n.t('generic.edit')} ${label} ${
                    this.isDraft ? this.$i18n.t('generic.stateMap.draft') : ''
                }`;
            }
            return `${this.$i18n.t('generic.addNew')} ${label}`;
        },
    },

    watch: {
        selectedPayoutType() {
            this.$v.selectedReference.$reset();
            this.selectedReference = null;
        },
    },

    created() {
        this.$withLoadingSpinner(async () => {
            if (this.$route.params.readonly) {
                this.readonly = this.$route.params.readonly;
            }
            const promises = [
                this[Actions.REQUEST_PROMOTIONS](),
                this[Actions.PC_REQUEST_ENTITIES_BY_TYPE_AND_IDS]({ entityType: ENTITY_TYPES.OFFER }),
                this[Actions.PC_REQUEST_ENTITIES_BY_TYPE_AND_IDS]({ entityType: ENTITY_TYPES.PRODUCT }),
                this[Actions.REQUEST_REWARDS_ENTITIES_BY_TYPE](ENTITY_TYPES.VOUCHER_SET),
                this[Actions.REQUEST_REWARDS_ENTITIES_BY_TYPE](ENTITY_TYPES.REWARD_PAYOUT),
            ];
            if (permissionsService.lotteryEnabled() && isUserAllowed('LotteriesRead', 'LotteriesWrite')) {
                promises.push(this[Actions.REQUEST_REWARDS_ENTITIES_BY_TYPE](ENTITY_TYPES.LOTTERY));
            }
            await Promise.all(promises);
            if (this.$route.params.id) {
                if (this.isRewardsDraftEnabled && !this.$route.params.readonly) {
                    const { id } = this.$route.params;
                    const result = await getRewardPayoutDraft(id);
                    this.entityDraft = result?.data?.data ? result.data.data[id] : null;
                    if (this.entityDraft) this.entityDraft.state = STATUS_CODES.NA;
                }
                await this.setupEditPageData();
            }
        });
    },

    methods: {
        ...mapActions('productcatalog', [Actions.PC_REQUEST_ENTITIES_BY_TYPE_AND_IDS]),
        ...mapActions('rewards', [Actions.REQUEST_REWARDS_ENTITIES_BY_TYPE]),
        ...mapActions('promotions', [Actions.REQUEST_PROMOTIONS]),
        async setupEditPageData() {
            this.publishedEntity = this.getEntity(ENTITY_TYPES.REWARD_PAYOUT, this.$route.params.id);
            if (this.entityDraft && !(this.$route.params.clone && this.publishedEntity)) {
                this.initialData = cloneDeep(this.entityDraft);
                this.initialData.version = this.publishedEntity.version;
                this.isOnlyDraft = !this.publishedEntity || this.publishedEntity.state === STATUS_CODES.NA;
                if (this.publishedEntity.update_time <= this.initialData.update_time) {
                    this.isUnpublished = true;
                }
            } else {
                this.initialData = cloneDeep(this.publishedEntity);
            }

            this.updateTime = this.initialData.update_time;
            this.getUpdateUserName(this.initialData.update_portal_id || this.initialData.portal_id || null);

            this.payout.name[this.selectedLanguage] = this.$route.params.clone
                ? `${this.initialData.data.name[this.selectedLanguage]} (cloned)`
                : this.initialData.data.name[this.selectedLanguage];
            this.payout.description[this.selectedLanguage] = this.initialData.data.description[this.selectedLanguage];
            this.selectedPayoutType = this.payoutTypeOptionsMap[this.initialData.data.payout_type];
            // Select payout type will refresh selected reference so need next tick to set up reference properly.
            await this.$nextTick();
            this.selectedReference = this.referenceOptionsMap[this.initialData.data.reference_id];
            this.walletType = this.walletTypeOptionsMap[this.initialData.data.wallet_type];
            this.amount = this.initialData.data.amount;
            this.quantity = this.initialData.data.quantity;
        },
        onCancel() {
            this.showDontLeaveAlert(this.goToPayoutsListPage);
        },
        async onSave(isPublish) {
            if (this.saveButtonClicked) {
                return;
            }
            this.saveButtonClicked = true;

            if (isPublish) {
                this.$v.$touch();
                if (this.$v.$invalid) {
                    this.$eventBus.$emit('showAlert', {
                        message: this.$i18n.t('alertMessage.pleaseFixValidation'),
                    });
                } else {
                    await this.submit(true);
                }
            } else {
                await this.submit(false);
            }

            this.saveButtonClicked = false;
        },
        async submit(isPublish) {
            try {
                this.$Progress.start();
                const submissionData = {
                    name: this.payout.name,
                    description: this.payout.description,
                    payout_type: this.selectedPayoutType?.value,
                };
                if (!this.isReferenceDisabled) {
                    submissionData.reference_id = this.selectedReference?.id;
                }
                if (this.isTypeTopUp) {
                    submissionData.wallet_type = this.walletType?.id;
                    submissionData.amount = String(this.amount);
                }
                if (this.isTypeTicket) {
                    submissionData.quantity = this.quantity;
                }
                const addDraftAction = async () => {
                    if (!this.isRewardsDraftEnabled) return null;
                    const resp = await setRewardPayoutDraft({ data: submissionData });
                    this.draftSavedId = resp?.data?.id;
                    return resp;
                };
                const addEntityAction = id =>
                    addRewardPayout({
                        data: submissionData,
                        approve_on_create: this.approveOnCreate,
                        id,
                    });
                const updateDraftAction = () =>
                    this.isRewardsDraftEnabled
                        ? setRewardPayoutDraft({
                              data: submissionData,
                              id: this.$route.params.id ?? this.draftSavedId,
                          })
                        : null;
                const updateEntityAction = () =>
                    updateRewardPayout(this.$route.params.id, this.initialData.version, submissionData);
                const hasStoredDraft = this.draftSavedId || (this.entityDraft && !this.$route.params.clone);
                const hasStoredEntity =
                    this.publishedEntity && this.publishedEntity.state !== STATUS_CODES.NA && !this.$route.params.clone;
                if (!hasStoredEntity) {
                    await manageEntityAdd(
                        hasStoredDraft ? updateDraftAction : addDraftAction,
                        addEntityAction,
                        isPublish,
                    );
                } else {
                    await manageEntityUpdate(updateDraftAction, updateEntityAction, isPublish);
                }
                this.$eventBus.$emit('showAlert', {
                    message: this.successSaveMessage,
                    type: ALERT_TYPES.success,
                });
                this.entityEditorMixin.successfullySaved = true;
                await this.getEntities(ENTITY_TYPES.REWARD_PAYOUT);
                setTimeout(this.goToPayoutsListPage, 1000);
            } catch (error) {
                Sentry.captureException(error);
                this.$Progress.fail();
                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('alertMessage.errorDoingSmthTryAgain', {
                        action: 'saving',
                        entityName: 'payout',
                    }),
                });
            }
        },
        goToPayoutsListPage() {
            this.$router.push({ name: RouteNames.PAYOUTS, params: { companyId: this.$route.params.companyId } });
        },
        async getUpdateUserName(id) {
            try {
                if (id) {
                    const response = await getUserNameById(Number(id));
                    if (response?.data) {
                        this.updateName = response.data;
                    }
                }
            } catch (e) {
                Sentry.captureException(e);
            }
        },
        reloadEditor(isReadonly) {
            const { id } = this.$route.params;
            // Use push to list page because router don`t want to reload same page
            this.$router
                .push({ name: RouteNames.PAYOUTS, params: { companyId: this.$route.params.companyId } })
                .then(() => {
                    this.$router.push({
                        name: RouteNames.REWARD_PAYOUT_EDITOR,
                        params: {
                            entityType: ENTITY_TYPES.REWARD_PAYOUT,
                            id,
                            readonly: isReadonly,
                            companyId: this.$route.params.companyId,
                        },
                    });
                });
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/palette';

.editor {
    background-color: $white;
}

.section {
    .heading {
        margin: 0;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.63;
        font-size: 1rem;
        color: $gray90;
    }

    .sub-heading {
        font-size: 0.812rem;
        line-height: 2.15;
        color: $gray60;
    }
}

.approve-on-create {
    padding-top: 0.75rem;

    .label {
        font-size: 0.75rem;
        font-weight: 700;
        color: black;
        flex: 0 1 9rem;
    }

    & > .approve-checkbox {
        margin-left: -0.25rem;
    }
}

.error {
    border: 0.0625rem solid $red;
}
</style>
