<template>
    <AbstractSubSidebarPageWrapper :pageTitle="$t('settings.settings')">
        <template slot="subSidebar">
            <SubSidebar
                v-model="selectedRouteName"
                :sections="permissionFilteredSections"
                class="sidebar"
                @change="changeActiveSection"
            />
        </template>
        <template slot="content">
            <div class="editor position-relative">
                <AppSpinner :isVisible="isDataLoading" />
                <template v-if="!isDataLoading">
                    <div class="editor-container flex-grow-1">
                        <div class="mb-3">
                            <div class="section-title mb-1">
                                {{ $t('quietHours.editor.setQuietHours') }}
                            </div>
                            <span class="explanation">
                                {{ $t('quietHours.editor.quietHoursExplanation') }}
                            </span>
                        </div>
                        <AppInputV3
                            :value="ruleName"
                            :label="$t('generic.name')"
                            class="editor-input-largest mb-4"
                            :placeholder="$t('generic.name')"
                            :invalid="$v.ruleName.$error"
                            :errorMessage="$t('alertMessage.account.nameIsRequiredField')"
                            @input="onRuleNameInput"
                        />
                        <!-- Day and Holiday Rule editors -->
                        <div
                            v-for="(chosenRule, index) in chosenRules"
                            :key="index"
                        >
                            <!-- Title -->
                            <div class="row no-gutters justify-content-between mb-3 mt-1">
                                <div>
                                    <span class="label mr-3"> {{ toUpper($t('generic.rule')) }} {{ index + 1 }} </span>
                                    <span class="subsection-title">
                                        {{ $t(chosenRule.title) }}
                                    </span>
                                </div>
                                <span
                                    class="clickable-label"
                                    @click="removeSection(chosenRule, index)"
                                >
                                    {{ $t('generic.remove') }}
                                </span>
                            </div>

                            <!-- Holiday Content -->
                            <div
                                v-if="chosenRule.id === QuietHoursTypes.holiday.id"
                                class="mt-3 mb-3 section-border"
                            >
                                <!--TITLE-->
                                <div class="label mt-3">
                                    {{ toUpper($t('quietHours.editor.notNotifiedOn')) }}
                                </div>

                                <!--HOLIDAY LIST-->
                                <div
                                    v-if="holidayRules.length"
                                    class="mt-3"
                                >
                                    <div
                                        v-for="(holiday, i) in holidayRules"
                                        :key="i"
                                        class="mb-1 row no-gutters"
                                        @mouseover="hoveredHoliday = i"
                                        @mouseleave="hoveredHoliday = null"
                                    >
                                        <div
                                            :class="{ 'holiday-enabled': holiday.enabled }"
                                            class="col-6 holiday-label holiday-rule pl-2"
                                        >
                                            <AppCheckbox
                                                v-model="holiday.enabled"
                                                :labelRight="holiday.labelName"
                                                :newDesign="holiday.enabled"
                                                :class="{ 'holiday-enabled': holiday.enabled }"
                                                class="width-fit-content"
                                            />
                                        </div>
                                        <img
                                            v-if="hoveredHoliday === i"
                                            src="../../../../assets/icons/delete-trash.svg"
                                            alt="remove"
                                            class="ml-3 trash-icon"
                                            @click="removeHoliday(i)"
                                        />
                                    </div>
                                </div>

                                <!--ADDING NEW HOLIDAY-->
                                <div
                                    v-show="fillingUpHoliday"
                                    class="row no-gutters align-items-center mt-3"
                                >
                                    <AppInputV3
                                        v-model="holidayRule.name"
                                        :placeholder="$t('quietHours.editor.enterHolidayName')"
                                        class="col-3 min-width"
                                    />
                                    <span class="mr-2 label ml-2">
                                        {{ toUpper($t('quietHours.editor.isOn')) }}
                                    </span>
                                    <AppMultiselectV3
                                        v-model="holidayRule.dateType"
                                        :options="holidayRangeLabels"
                                        :small="true"
                                        :borderNone="true"
                                        :blueArrow="true"
                                        :preselectFirst="true"
                                        :allowEmpty="false"
                                        :placeHolderChosenOption="true"
                                        :widthStyleObject="{ width: '130px' }"
                                        label="label"
                                        trackBy="id"
                                        class="col-2 max-width multiselect-font"
                                        @input="deleteDate"
                                    />
                                    <DateTimePicker
                                        :value="dateTimePickerValue"
                                        :range="rangeSelected"
                                        :error="invalidDate"
                                        type="date"
                                        @input="dateSelected"
                                        @range-selected="rangeDateSelected"
                                    />
                                    <span
                                        :class="{ disabled: disabledAddHolidayInList }"
                                        class="min-width ml-4 clickable-label"
                                        @click="addHolidayInList"
                                    >
                                        {{ $t('generic.add') }}
                                    </span>
                                </div>
                                <span
                                    v-if="invalidDate"
                                    v-t="'quietHours.editor.dateErrorText'"
                                    class="offset-5 small-red-note"
                                />

                                <div
                                    :class="{ disabled: fillingUpHoliday }"
                                    class="clickable-label plus ml-3 mb-2 mt-3"
                                    @click="addNewHoliday"
                                >
                                    {{ $t('quietHours.editor.addHoliday') }}
                                </div>
                            </div>

                            <!-- Day Content -->
                            <div
                                v-else
                                class="mt-3 mb-3 pb-3 section-border"
                            >
                                <span class="subsection-title d-flex mt-2">
                                    {{ $t('quietHours.editor.day') }}
                                </span>
                                <div class="d-flex daily-section-content">
                                    <!-- Daily quiet hours header buttons-->
                                    <div class="d-flex daily-section-creating">
                                        <div class="row no-gutters mt-1">
                                            <div
                                                v-for="(day, iter) in i18nShortDayOfWeek"
                                                :key="iter"
                                                :class="{
                                                    'selected-card': selectedDay === iter,
                                                    'chosen-card': chosenDays[iter],
                                                    invalid: isInvalidFrame(iter),
                                                }"
                                                class="d-flex day-card mr-2 mb-2 align-items-center"
                                                @click.self="onDayCardSelected(iter)"
                                            >
                                                <AppCheckbox
                                                    :value="chosenDays[iter]"
                                                    :newDesign="true"
                                                    :iconType="'no-margin-check'"
                                                    class=""
                                                    @input="onDaySelected(iter)"
                                                />
                                                <span
                                                    :class="{ 'selected-label': selectedDay === iter }"
                                                    class="day-card-label"
                                                    @click.self="onDayCardSelected(iter)"
                                                >
                                                    {{ $t(day) }}
                                                </span>
                                            </div>
                                        </div>

                                        <!-- Creating daily quiet hours -->
                                        <div
                                            v-if="selectedDay || selectedDay === 0"
                                            class="mt-4"
                                        >
                                            <div class="py-4">
                                                <div class="d-flex align-items-center">
                                                    <AppToggle
                                                        :value="timeFrameAllDay"
                                                        :label="toUpper($t('quietHours.allDay'))"
                                                        :small="true"
                                                        class="mr-3 mb-2 all-day-toggle"
                                                        @input="onAllDayToggleSelect"
                                                    />

                                                    <div class="time-range-min-width">
                                                        <div
                                                            v-for="(timeRange, n) in timeframes[selectedDay].timeRanges"
                                                            :key="n"
                                                            @mouseover="hoveredTimeframe = n"
                                                            @mouseleave="hoveredTimeframe = null"
                                                        >
                                                            <div
                                                                :class="{ 'mb-2': !invalidDays[selectedDay][n] }"
                                                                class="timeframe-row col-6"
                                                            >
                                                                <span class="label mr-2">
                                                                    {{ toUpper($t('generic.from')) }}
                                                                </span>
                                                                <AppInputV3
                                                                    v-model="timeRange.start"
                                                                    :step="60"
                                                                    :invalid="invalidDays[selectedDay][n]"
                                                                    type="time"
                                                                    :disabled="timeFrameAllDay"
                                                                    class="col-5 mr-2 time-max-width"
                                                                    @change="onTimeValueChanged(selectedDay, n)"
                                                                />
                                                                <span class="label mr-2">
                                                                    {{ toUpper($t('generic.to')) }}
                                                                </span>
                                                                <AppInputV3
                                                                    v-model="timeRange.end"
                                                                    :step="60"
                                                                    :invalid="invalidDays[selectedDay][n]"
                                                                    type="time"
                                                                    :disabled="timeFrameAllDay"
                                                                    class="col-5 time-max-width"
                                                                    @change="onTimeValueChanged(selectedDay, n)"
                                                                />
                                                                <img
                                                                    v-if="hoveredTimeframe === n"
                                                                    src="../../../../assets/icons/delete-trash.svg"
                                                                    alt="remove"
                                                                    class="ml-3 trash-icon"
                                                                    @click="removeTimeframe(n)"
                                                                />
                                                            </div>
                                                            <p
                                                                v-if="invalidDays[selectedDay][n]"
                                                                v-t="'quietHours.editor.timeRangeErrorText'"
                                                                class="small-red-note mt-2"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                                <p class="explanation my-2">
                                                    {{ $t('quietHours.editor.timeRangeExplanation') }}
                                                </p>
                                                <div>
                                                    <span
                                                        :class="{
                                                            disabled: !lastRangeFilled,
                                                        }"
                                                        class="clickable-label plus"
                                                        @click="addTimeFrame"
                                                    >
                                                        {{ $t('quietHours.editor.addTimeframe') }}
                                                    </span>
                                                    <span
                                                        class="clickable-label apply-to-all"
                                                        @click="applyToAll"
                                                    >
                                                        {{ $t('quietHours.editor.applyToAllSelected') }}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <!-- Listing daily quiet hours -->
                                    <div class="daily-section-listing">
                                        <div
                                            v-for="(listedDay, i) in chosenDays"
                                            :key="i"
                                            class="d-flex"
                                        >
                                            <div
                                                :class="{ 'hide-element': !listedDay }"
                                                class="listed-day-wrapper clickable-label row mb-1 pl-3 pr-3 justify-content-between align-items-center"
                                            >
                                                <span class="col-2">
                                                    {{ $t(i18nShortDayOfWeek[i]) }}
                                                </span>
                                                <div
                                                    v-if="listedDay"
                                                    class="col-9 text-sm-right listed-ranges-font"
                                                >
                                                    <div
                                                        v-if="timeframes[i].type.id === dailyTimeframeTypes.allDay.id"
                                                        id="listedDaysAllDay"
                                                    >
                                                        {{ $t(dailyTimeframeTypes.allDay.i18nLabel) }}
                                                    </div>
                                                    <div v-else>
                                                        <span
                                                            v-for="(ranges, timeRangeIndex) in formattedRanges(i)"
                                                            :key="timeRangeIndex"
                                                        >
                                                            {{ timeRangeIndex ? `, ${ranges}` : ranges }}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <template v-if="addRuleLabelClicked">
                            <div class="row align-items-center pt-1">
                                <span class="col-12 sub-section-title">
                                    {{ $t('quietHours.editor.defineRule') }}
                                </span>
                                <span class="label mr-2 ml-1">
                                    {{ toUpper($t('generic.for')) }}
                                </span>
                                <AppMultiselectV3
                                    v-model="ruleType"
                                    :options="ruleTypeOptions"
                                    :placeholder="$t('generic.type')"
                                    :small="true"
                                    label="label"
                                    trackBy="id"
                                    class="col-3 min-width"
                                    @input="chooseRule"
                                />
                            </div>
                        </template>
                        <template v-else>
                            <div
                                :class="{ disabled: noMoreRuleTypes }"
                                class="clickable-label plus width-fit-content pt-1 ml-0"
                                @click="addOptions"
                            >
                                {{ $t('quietHours.editor.addRuleLabel') }}
                            </div>
                        </template>
                    </div>

                    <!-- Action buttons -->
                    <div class="editor-controls mr-4 mb-4 mt-3">
                        <AppButton
                            :label="$t('generic.cancel')"
                            class="mr-5"
                            @click="onCancel"
                        />
                        <AppButton
                            :buttonType="BUTTON_TYPES.PRIMARY"
                            :label="$t('generic.save')"
                            :iconType="ICON_TYPES.CHECK"
                            :disabled="saveDisabled"
                            @click="onSave"
                        />
                    </div>
                </template>
            </div>
        </template>
    </AbstractSubSidebarPageWrapper>
</template>

<script>
// Components
import AppMultiselectV3 from '@/components/partials/inputs/AppMultiselectV3.vue';
import AppInputV3 from '@/components/partials/inputs/AppInputV3.vue';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import { ICON_TYPES } from '@/common/iconHelper';
import AppCheckbox from '@/components/partials/inputs/AppCheckbox.vue';
import AppSpinner from '@/components/partials/AppSpinner.vue';
import SubSidebarMixin from '@/components/partials/SubSidebarMixin.vue';
import SettingsSubSidebarMixin from '@/__new__/features/settings/SettingsSubSidebarMixin.vue';
import AbstractSubSidebarPageWrapper from '@/components/layout/AbstractSubSidebarPageWrapper.vue';
import SubSidebar from '@/components/layout/SubSidebar.vue';
import DateTimePicker from '@/components/partials/inputs/DateTimePicker.vue';
import AppToggle from '@/components/partials/inputs/AppToggle.vue';

// HTTP
import { getQuietHours, addQuietHours, updateQuietHour } from '@/__new__/services/dno/quietHours/http/quietHours';

// Helpers
import toUpper from 'lodash/toUpper';
import cloneDeep from 'lodash/cloneDeep';
import {
    QuietHoursTypes,
    holidayRangeTypes,
    i18nShortDayOfWeek,
    dailyTimeframeTypes,
    dayRangeTypes,
} from '@/__new__/features/settings/quietHours/quietHoursHelper';
import moment from 'moment';
import Button from '@/common/button/Button';
import { ALERT_TYPES } from '@/common/alerts/Alert';
import entityEditorMixin from '@/common/entityEditorMixin';
import RouteNames from '@/router/routeNames';
import * as Sentry from '@sentry/vue';
import { required } from 'vuelidate/lib/validators';

export default {
    name: 'QuietHoursEditor',
    components: {
        AppToggle,
        AppButton,
        AppCheckbox,
        AppInputV3,
        AppSpinner,
        AppMultiselectV3,
        SubSidebar,
        AbstractSubSidebarPageWrapper,
        DateTimePicker,
    },

    mixins: [entityEditorMixin, SubSidebarMixin, SettingsSubSidebarMixin],

    data() {
        return {
            toUpper,
            ICON_TYPES,
            BUTTON_TYPES,
            QuietHoursTypes,
            dailyTimeframeTypes,
            i18nShortDayOfWeek,
            quietHoursAvailableTypes: Object.values(QuietHoursTypes),
            holidayRangeTypes: Object.values(holidayRangeTypes),
            dailyTimeframeType: Object.values(dailyTimeframeTypes),
            isSaveButtonClicked: false,
            isDataLoading: false,
            noMoreRuleTypes: false,
            ruleName: '',
            ruleType: null,
            addRuleLabelClicked: false,
            chosenRules: [],
            fillingUpHoliday: true,
            rangeSelected: false,
            invalidDate: false,
            holidayRules: [],
            holidayRule: {
                name: '',
                dateType: '',
                dateValue: '',
                endDateValue: '',
                enabled: false,
            },
            hoveredHoliday: null,
            selectedDay: null,
            chosenDays: [],
            timeframes: [],
            hoveredTimeframe: null,
            invalidDays: [],
        };
    },
    computed: {
        pageTitle() {
            return this.$t('quietHours.editor.quietHoursEditorPage');
        },
        ruleTypeOptions() {
            return this.quietHoursAvailableTypes.map(opt => ({
                ...opt,
                label: this.$t(opt.i18nLabel),
            }));
        },
        holidayRangeLabels() {
            return this.holidayRangeTypes.map(type => ({
                ...type,
                label: this.$t(type.i18nLabel),
            }));
        },
        dailyTimeframeTypeLabels() {
            return this.dailyTimeframeType.map(type => ({
                ...type,
                label: this.$t(type.i18nLabel),
            }));
        },
        disabledAddHolidayInList() {
            return this.invalidDate || !this.holidayRule.name || !this.holidayRule.dateValue;
        },
        lastRangeFilled() {
            const lastIndex = this.timeframes[this.selectedDay].timeRanges.length - 1;
            return (
                this.timeframes[this.selectedDay].timeRanges[lastIndex].start !== '' &&
                this.timeframes[this.selectedDay].timeRanges[lastIndex].end !== ''
            );
        },
        timeFrameAllDay() {
            return this.timeframes[this.selectedDay].type.id === dailyTimeframeTypes.allDay.id;
        },
        saveDisabled() {
            return !!this.invalidDays.find(
                (day, index) =>
                    this.chosenDays[index] &&
                    this.timeframes[index].type.id !== dailyTimeframeTypes.allDay.id &&
                    day.find(timeframe => timeframe),
            );
        },
        dateTimePickerValue() {
            return this.rangeSelected
                ? [this.holidayRule.dateValue, this.holidayRule.endDateValue]
                : this.holidayRule.dateValue;
        },
    },
    watch: {
        holidayRule: {
            handler(val) {
                this.rangeSelected = val.dateType.id === holidayRangeTypes.range.id;
            },
            deep: true,
        },
        // eslint-disable-next-line func-names
        'holidayRule.dateType': function () {
            this.invalidDate = false;
        },
    },
    mounted() {
        this.rangeSelected = this.holidayRule.dateType.id === holidayRangeTypes.range.id;
        this.addWatcher('$data');
    },
    async created() {
        this.initDailyRulesData();

        if (this.$route?.params?.id) {
            try {
                this.$Progress.start();
                this.isDataLoading = true;

                const qh = await getQuietHours();
                const mappedData = qh.data.map(qhr => ({
                    ...qhr,
                    name: qhr.profile_name || 'Default Quiet Hours',
                }));
                const relatedQHR = mappedData.find(qhr => qhr.id === this.$route.params.id);
                this.initData(relatedQHR);

                this.isDataLoading = false;
                this.$Progress.finish();
            } catch (error) {
                Sentry.captureException(error);
                this.$Progress.fail();
                this.$eventBus.$emit('showAlert', {
                    message: this.$t('alertMessage.failedToLoadNecessaryData'),
                });
            } finally {
                this.isDataLoading = false;
            }
        }
    },
    methods: {
        onAllDayToggleSelect(val) {
            this.timeframes[this.selectedDay].type.id = val
                ? dailyTimeframeTypes.allDay.id
                : dailyTimeframeTypes.timeRange.id;
            if (val) {
                this.timeframes[this.selectedDay].timeRanges = [{ start: '00:00', end: '23:59' }];
            }

            this.invalidDays[this.selectedDay] = [false];
        },
        onRuleNameInput(name) {
            this.ruleName = name;
            this.$v.ruleName.$reset();
        },
        initDailyRulesData() {
            const empty = {
                type: this.dailyTimeframeTypeLabels[0],
                timeRanges: [
                    {
                        start: '',
                        end: '',
                    },
                ],
            };
            for (let i = 0; i < 7; i += 1) {
                this.$set(this.timeframes, i, cloneDeep(empty));
                this.$set(this.invalidDays, i, [false]);
                this.$set(this.chosenDays, i, false);
            }
        },
        chooseRule(item) {
            this.chosenRules.push(item);
            this.quietHoursAvailableTypes.splice(this.ruleTypeOptions.indexOf(item), 1);
            this.addRuleLabelClicked = false;
            this.ruleType = null;
            this.noMoreRuleTypes = !this.quietHoursAvailableTypes.length;
        },
        addOptions() {
            if (this.quietHoursAvailableTypes.length) {
                this.addRuleLabelClicked = true;
            }
        },
        removeSection(item, index) {
            const confirmButton = new Button({
                label: this.$t('generic.confirm'),
                alertType: ALERT_TYPES.warning,
            });
            const cancelButton = new Button({
                label: this.$t('generic.cancel'),
                alertType: ALERT_TYPES.warning,
            });

            this.$eventBus.$emit('showAlert', {
                message: this.$t('alertMessage.quietHoursRemoveSection', {
                    section: this.$t(item.title),
                }),
                type: ALERT_TYPES.warning,
                buttons: [confirmButton, cancelButton],
            });
            this.$eventBus.$once('buttonClicked', id => {
                if (id === confirmButton.id) {
                    this.chosenRules.splice(index, 1);
                    this.quietHoursAvailableTypes.push(item);
                    this.noMoreRuleTypes = false;

                    // delete rules data
                    if (item.id === 'holiday') {
                        this.holidayRules = [];
                        this.holidayRule.name = '';
                        this.holidayRule.dateType = '';
                        this.holidayRule.dateValue = '';
                        this.holidayRule.endDateValue = '';
                    } else if (item.id === 'daily') {
                        this.selectedDay = null;
                        this.initDailyRulesData();
                    }
                }
            });
        },
        addNewHoliday() {
            [this.holidayRule.dateType] = this.holidayRangeLabels;
            this.fillingUpHoliday = true;
        },
        addHolidayInList() {
            if (!this.disabledAddHolidayInList) {
                const date =
                    this.holidayRule.dateType.id === holidayRangeTypes.single.id
                        ? `${this.$t('generic.on')} ${this.formatDate(this.holidayRule.dateValue)}`
                        : `${this.$t('generic.from')} ${this.formatDate(this.holidayRule.dateValue)} ${this.$t(
                              'generic.to',
                          )} ${this.formatDate(this.holidayRule.endDateValue)}`;
                const formattedName = `${this.holidayRule.name} ${date}`;

                const holiday = { labelName: formattedName };
                Object.assign(holiday, this.holidayRule);
                this.holidayRules.push(holiday);

                this.fillingUpHoliday = false;
                this.holidayRule.name = '';
                this.holidayRule.dateType = '';
                this.holidayRule.dateValue = '';
                this.holidayRule.endDateValue = '';
            }
        },
        formatDate(date) {
            return moment(date).format('ddd, D MMM YYYY');
        },
        deleteDate() {
            this.holidayRule.dateValue = '';
        },
        removeHoliday(index) {
            this.holidayRules.splice(index, 1);
        },
        rangeDateSelected(startDate, endDate) {
            this.holidayRule.dateValue = startDate;
            this.holidayRule.endDateValue = endDate;
            this.invalidDate = !!this.holidayRules.find(holiday =>
                holiday.dateType.id === holidayRangeTypes.single.id
                    ? moment(holiday.dateValue).isBetween(moment(startDate), moment(endDate), null, '[]')
                    : (moment(startDate).isSameOrBefore(moment(holiday.dateValue)) &&
                          moment(endDate).isSameOrAfter(moment(holiday.dateValue))) ||
                      (moment(endDate).isSameOrAfter(moment(holiday.endDateValue)) &&
                          moment(startDate).isSameOrBefore(moment(holiday.endDateValue))) ||
                      (moment(startDate).isSameOrAfter(moment(holiday.dateValue)) &&
                          moment(endDate).isSameOrBefore(moment(holiday.endDateValue))),
            );
        },
        dateSelected(date) {
            this.holidayRule.dateValue = date;
            this.invalidDate = !!this.holidayRules.find(holiday =>
                holiday.dateType.id === holidayRangeTypes.single.id
                    ? moment(date).isSame(moment(holiday.dateValue))
                    : moment(date).isBetween(moment(holiday.dateValue), moment(holiday.endDateValue), null, '[]'),
            );
        },
        formatPlaceholderDate() {
            if (this.rangeSelected && this.holidayRule.dateValue && this.holidayRule.endDateValue) {
                return `${moment(this.holidayRule.dateValue, 'YYYY-MM-DD').format('D. MMM, YYYY')} - ${moment(
                    this.holidayRule.endDateValue,
                    'YYYY-MM-DD',
                ).format('D. MMM, YYYY')}`;
            }
            if (this.holidayRule.dateValue) {
                return `${moment(this.holidayRule.dateValue, 'YYYY-MM-DD').format('D. MMMM, YYYY')}`;
            }
            return '';
        },
        onCancel() {
            this.$router.go(-1);
        },
        initData({ rules, name }) {
            this.ruleName = name;
            rules.forEach(rule => {
                if (rule.type === QuietHoursTypes.holiday.field) {
                    const item = this.ruleTypeOptions.find(option => option.id === QuietHoursTypes.holiday.id);
                    this.chosenRules.push(item);
                    this.quietHoursAvailableTypes.splice(
                        this.quietHoursAvailableTypes.indexOf(QuietHoursTypes.holiday),
                        1,
                    );
                    this.fillingUpHoliday = false;

                    this.holidayRules = rule.holidays.map(holiday => {
                        const holidayRule = {
                            name: '',
                            labelName: '',
                            dateType: '',
                            dateValue: '',
                            endDateValue: '',
                            enabled: false,
                        };
                        holidayRule.name = holiday.name;
                        holidayRule.enabled = holiday.enabled;
                        holidayRule.dateType = this.holidayRangeLabels.find(type => type.field === holiday.date.type);

                        if (holiday.date.type === holidayRangeTypes.single.field) {
                            holidayRule.dateValue = moment(holiday.date.value, 'DD.MM.YYYY').format();
                            holidayRule.labelName = `${holiday.name} ${this.$t('generic.on')} ${this.formatDate(
                                holidayRule.dateValue,
                            )}`;
                        } else {
                            holidayRule.dateValue = moment(holiday.date.value[0], 'DD.MM.YYYY').format();
                            holidayRule.endDateValue = moment(holiday.date.value[1], 'DD.MM.YYYY').format();
                            holidayRule.labelName = `${holiday.name} ${this.$t('generic.from')} ${this.formatDate(
                                holidayRule.dateValue,
                            )} ${this.$t('generic.to')} ${this.formatDate(holidayRule.endDateValue)}`;
                        }
                        return holidayRule;
                    });
                } else {
                    const item = this.ruleTypeOptions.find(option => option.id === QuietHoursTypes.daily.id);
                    this.chosenRules.push(item);
                    this.quietHoursAvailableTypes.splice(
                        this.quietHoursAvailableTypes.indexOf(QuietHoursTypes.daily),
                        1,
                    );

                    rule.days.forEach(day => {
                        this.chosenDays[day.day_of_week - 1] = true;
                        this.timeframes[day.day_of_week - 1].type = this.dailyTimeframeTypeLabels.find(
                            elem => elem.id === day.time_frame.type,
                        );

                        if (day.time_frame.type === dailyTimeframeTypes.timeRange.id) {
                            this.timeframes[day.day_of_week - 1].timeRanges = day.time_frame.ranges.map(range => ({
                                start: range.value[0],
                                end: range.value[1],
                            }));
                        }
                        if (day.time_frame.type === dailyTimeframeTypes.allDay.id) {
                            this.timeframes[day.day_of_week - 1].timeRanges = [{ start: '00:00', end: '23:59' }];
                        }
                    });
                }
            });
            this.noMoreRuleTypes = !this.quietHoursAvailableTypes.length;
        },
        async onSave() {
            this.$v.ruleName.$touch();

            if (this.isSaveButtonClicked || this.$v.ruleName.$error) {
                return;
            }
            this.isSaveButtonClicked = true;
            this.$Progress.start();
            const data = {
                profile_name: this.ruleName,
                rules: [],
            };
            try {
                if (this.chosenRules.find(elem => elem.id === QuietHoursTypes.daily.id)) {
                    const formattedDailyData = this.formatDailyQuietHoursData();
                    if (formattedDailyData.days.length) {
                        data.rules.push(formattedDailyData);
                    }
                }
                if (this.chosenRules.find(elem => elem.id === QuietHoursTypes.holiday.id)) {
                    const formattedHolidayData = this.formatHolidayQuietHoursData();
                    if (formattedHolidayData) {
                        data.rules.push(formattedHolidayData);
                    }
                }

                if (!data.rules.length) {
                    const e = new Error(this.$t('quietHours.editor.canNotSaveEmpty'));
                    e.name = 'EmptyRule';
                    throw e;
                }

                if (this.$route?.params?.id) {
                    await updateQuietHour(data, this.$route.params.id);
                    this.$eventBus.$emit('showAlert', {
                        message: this.$t('alertMessage.successActionMessage', {
                            entityName: this.$t('quietHours.quietHoursPage'),
                            action: this.$t('generic.updated'),
                        }),
                        type: ALERT_TYPES.success,
                    });
                } else {
                    await addQuietHours(data);
                    this.$eventBus.$emit('showAlert', {
                        message: this.$t('alertMessage.successActionMessage', {
                            entityName: this.$t('quietHours.quietHoursPage'),
                            action: this.$t('generic.added'),
                        }),
                        type: ALERT_TYPES.success,
                    });
                }
                this.$Progress.finish();
                this.entityEditorMixin.successfullySaved = true;
                setTimeout(
                    () =>
                        this.$router.push({
                            name: RouteNames.QUIET_HOURS,
                            params: { companyId: this.$route.params.companyId },
                        }),
                    1000,
                );
            } catch (e) {
                if (e.name === 'EmptyRule') {
                    this.$eventBus.$emit('showAlert', {
                        message: e.message,
                    });
                } else {
                    Sentry.captureException(e);
                    this.$eventBus.$emit('showAlert', {
                        message: this.$t('alertMessage.errorDoingSmthTryAgain', {
                            action: this.$t('generic.saving'),
                            entityName: this.$t('quietHours.quietHourRules'),
                        }),
                    });
                }
                this.$Progress.fail();
                this.isSaveButtonClicked = false;
            }
        },
        formatDailyQuietHoursData() {
            const dailyData = {
                type: QuietHoursTypes.daily.field,
                name: 'Daily Quiet Hours',
                days: [],
            };
            for (let i = 0; i < 7; i += 1) {
                if (this.chosenDays[i]) {
                    const day = {
                        day_of_week: i + 1,
                        time_frame: {
                            type: this.timeframes[i].type.id,
                        },
                    };
                    if (this.timeframes[i].type.id === dailyTimeframeTypes.allDay.id) {
                        dailyData.days.push(day);
                    } else if (this.timeframes[i].type.id === dailyTimeframeTypes.timeRange.id) {
                        const ranges = this.timeframes[i].timeRanges
                            .filter(elem => elem.start && elem.end)
                            .map(range => ({ value: [range.start, range.end] }));

                        if (ranges.length) {
                            Object.assign(day.time_frame, { ranges });
                            dailyData.days.push(day);
                        }
                    }
                }
            }
            return dailyData;
        },
        formatHolidayQuietHoursData() {
            if (this.holidayRules.length) {
                const holidayData = {
                    type: QuietHoursTypes.holiday.field,
                    name: 'Holiday Quiet Hours',
                    time_frame: {
                        type: dayRangeTypes.allDay.field,
                    },
                };
                const holidaysFormatted = this.holidayRules.map(elem => {
                    const holidayObj = {
                        name: elem.name,
                        enabled: elem.enabled,
                        date: {
                            type: elem.dateType.field,
                        },
                    };
                    const value =
                        elem.dateType.id === holidayRangeTypes.single.id
                            ? moment(elem.dateValue).format('DD.MM.YYYY')
                            : [
                                  moment(elem.dateValue).format('DD.MM.YYYY'),
                                  moment(elem.endDateValue).format('DD.MM.YYYY'),
                              ];

                    Object.assign(holidayObj.date, { value });
                    return holidayObj;
                });
                return Object.assign(holidayData, { holidays: holidaysFormatted });
            }
            return null;
        },
        onDaySelected(index) {
            if (index === this.selectedDay) {
                this.selectedDay = null;
            }
            this.$set(this.chosenDays, index, !this.chosenDays[index]);
        },
        onDayCardSelected(index) {
            if (this.selectedDay === index) {
                this.selectedDay = null;
            } else {
                this.selectedDay = index;
                this.$set(this.chosenDays, index, true);
            }
        },
        addTimeFrame() {
            if (this.lastRangeFilled) {
                this.$set(
                    this.timeframes[this.selectedDay].timeRanges,
                    this.timeframes[this.selectedDay].timeRanges.length,
                    { start: '', end: '' },
                );
                this.invalidDays[this.selectedDay].push(false);
            }
        },
        removeTimeframe(index) {
            if (this.timeframes[this.selectedDay].timeRanges.length === 1) {
                this.timeframes[this.selectedDay].timeRanges[0].start = '';
                this.timeframes[this.selectedDay].timeRanges[0].end = '';
                this.invalidDays[this.selectedDay][0] = false;
            } else {
                this.timeframes[this.selectedDay].timeRanges.splice(index, 1);
                this.invalidDays[this.selectedDay].splice(index, 1);
            }
        },
        formattedRanges(index) {
            if (this.timeframes[index].type.id === dailyTimeframeTypes.allDay.id) {
                return this.$t(dailyTimeframeTypes.allDay.i18nLabel);
            }
            return this.timeframes[index].timeRanges
                .filter(elem => elem.start && elem.end)
                .map(range => `${range.start} - ${range.end}`);
        },
        applyToAll() {
            this.timeframes.forEach((elem, index) => {
                if (this.chosenDays[index]) {
                    if (this.timeframes[this.selectedDay].type.id === dailyTimeframeTypes.allDay.id) {
                        this.timeframes[index].type = { ...this.timeframes[this.selectedDay].type };
                        this.timeframes[index].timeRanges = [{ start: '00:00', end: '23:59' }];
                    } else {
                        const newObj = JSON.parse(JSON.stringify(this.timeframes[this.selectedDay]));
                        this.$set(this.timeframes, index, newObj);
                    }
                }
            });
        },
        onTimeValueChanged(day, index) {
            const editedStart = moment(this.timeframes[day].timeRanges[index].start, 'HH:mm');
            const editedEnd = moment(this.timeframes[day].timeRanges[index].end, 'HH:mm');

            if (editedStart.isValid() && editedEnd.isValid()) {
                if (editedStart.isSameOrAfter(editedEnd) || this.checkTimeFrames(editedStart, editedEnd, day, index)) {
                    this.$set(this.invalidDays[day], index, true);
                } else {
                    this.$set(this.invalidDays[day], index, false);
                }
            } else {
                this.$set(this.invalidDays[day], index, false);
            }
        },
        checkTimeFrames(editedStart, editedEnd, day, index) {
            return this.timeframes[day].timeRanges.find((timeRange, rangesIndex) => {
                if (rangesIndex === index) return false;
                const timeRangeStart = moment(timeRange.start, 'HH:mm');
                const timeRangeEnd = moment(timeRange.end, 'HH:mm');

                return (
                    timeRangeStart.isSame(editedStart) ||
                    timeRangeEnd.isSame(editedEnd) ||
                    (editedStart.isBefore(timeRangeStart) && editedEnd.isSameOrAfter(timeRangeStart)) ||
                    (editedEnd.isAfter(timeRangeEnd) && editedStart.isSameOrBefore(timeRangeEnd)) ||
                    (editedStart.isAfter(timeRangeStart) && editedEnd.isBefore(timeRangeEnd))
                );
            });
        },
        isInvalidFrame(index) {
            return (
                this.timeframes[index].type.id !== dailyTimeframeTypes.allDay.id &&
                this.chosenDays[index] &&
                this.invalidDays[index].find(elem => elem)
            );
        },
    },
    validations: {
        ruleName: {
            required,
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/_colors';
@import '~@/assets/scss/_palette';
@import '~@/assets/scss/_icons';
@import '~@/assets/scss/_animations';

$icon-path: '~@/assets/icons/';

.editor {
    background-color: $white;
    display: flex;
    flex-flow: column;
    height: 100%;
}

.editor-container {
    padding: 1.875rem 1.5rem 0 1.5rem;
}

.editor-controls {
    display: flex;
    justify-content: flex-end;
}

.section-title {
    font-size: 1.125rem;
    color: $gray90;
    font-weight: 600;
}

.sub-section-title {
    font-size: 0.75rem;
    font-weight: 600;
    color: $gray90;
    line-height: 1.625rem;
    margin-bottom: 0.25rem;
}

.explanation {
    line-height: 1.25rem;
    color: $gray60;
    font-size: 0.75rem;
    font-weight: 500;
}

.min-width {
    min-width: fit-content;
}

.max-width {
    max-width: fit-content;
}

.clickable-label {
    width: fit-content;
    line-height: 1.625rem;
    font-size: 0.875rem;
    color: $blue;
    font-weight: 600;
    cursor: pointer;

    &.plus {
        margin-left: 0.75rem;
    }

    &.plus::before {
        content: url($icon-path + $icon-plus-blue);
        margin-right: 0.25rem;
    }

    &.plus.disabled::before {
        content: url($icon-path + $icon-plus-blue-disabled);
    }

    &.disabled {
        cursor: default;
        color: $gray-blue;
    }

    .listed-ranges-font {
        font-size: 0.75rem;
    }
}

.label {
    color: $gray-blue;
    font-size: 0.75rem;
    font-weight: 700;
}

.subsection-title {
    line-height: 1.625rem;
    font-size: 0.75rem;
    color: $gray90;
    font-weight: 600;
}

.section-border {
    border-bottom: solid 1px rgba(51, 81, 149, 0.15);
    border-top: solid 1px rgba(51, 81, 149, 0.15);
}

.width-fit-content {
    width: fit-content;
}

.holiday-rule {
    height: 2.5rem;
    border-radius: 8px;

    &.holiday-enabled {
        background-color: rgba($color: $blue, $alpha: 0.15);
    }
}

.holiday-label {
    height: inherit;
}

.trash-icon {
    cursor: pointer;
    align-self: center;
    height: 1.25rem;
    width: 1.25rem;
}

.date-min-width {
    min-width: 14.3rem;
}

.daily-section-content {
    @media (max-width: 1275px) {
        flex-direction: column;
    }
}

.daily-section-creating {
    flex-direction: column;

    @media (min-width: 1275px) {
        width: 60%;
    }
}

.daily-section-listing {
    max-width: 36.25rem;

    @media (max-width: 1275px) {
        margin-top: 1.5rem;
    }
    @media (min-width: 1275px) {
        width: 40%;
    }
}

.day-card {
    cursor: pointer;
    padding: 0.5rem 0.75rem 0.5rem;
    border: solid 1px rgba(51, 81, 149, 0.15);
    border-radius: 0.5rem;
    height: 2rem;

    &.chosen-card {
        background: $blue15;
    }

    &.selected-card {
        background: $blue;
    }

    &.invalid {
        border-color: $red;
    }

    .day-card-label {
        margin-left: 0.75rem;
        line-height: 1.625rem;
        font-size: 0.75rem;
        color: $blue;
        font-weight: 600;

        &.selected-label {
            color: white;
        }
    }
}

.time-range-min-width {
    min-width: 21.75rem;
}

.time-max-width {
    max-width: 7.5rem;
}

.listed-day-wrapper {
    background: $blue15;
    border-radius: 0.5rem;
    width: 100%;
    min-height: 2.5rem;
}

.hide-element {
    display: none;
}

.apply-to-all {
    margin-left: 12.5rem;
}

.timeframe-row {
    flex-direction: row;
    display: flex;
    align-items: center;
}

.multiselect-font {
    ::v-deep .multiselect__single {
        line-height: 1.625rem !important;
    }

    ::v-deep .multiselect__input {
        line-height: 1.625rem !important;
        font-weight: 700 !important;
    }
}
.all-day-toggle {
    min-width: 6rem;
}
</style>
