<template>
    <div class="no-charging-spec mt-5">
        <div class="tag-line">
            No <span class="entity-name">{{ name }}</span> Selected
        </div>
        <div class="message">Please select {{ name }} from the list.</div>
    </div>
</template>

<script>
export default {
    name: 'EmptyDetails',
    props: {
        name: {
            type: String,
            required: true,
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/palette';

.no-charging-spec {
    display: flex;
    color: $gray30;
    flex-direction: column;
    text-align: center;

    .tag-line {
        font-size: 1.4em;
        font-weight: 600;
        .entity-name {
            text-transform: capitalize;
        }
    }

    .message {
        font-size: 1em;
    }
}
</style>
