
// Vue components
import AppHeader from '@/components/layout/AppHeader.vue';
import AppInputV3 from '@/components/partials/inputs/AppInputV3.vue';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import TagsMultiselect from '@/components/partials/inputs/TagsMultiselect.vue';
import SearchBanner from '@/components/partials/SearchBanner.vue';

// Helpers and libraries
import { ALERT_TYPES } from '@/common/alerts/Alert';
import OePlansMixin from '@/__new__/features/customerCare/mixins/OePlansMixin.vue';
import RouteNames from '@/router/routeNames';
import { ICON_TYPES } from '@/common/iconHelper';
import permissionsService, { isUserAllowed } from '@/services/permissions/permissions.service';

// 3rd party libs
import * as Sentry from '@sentry/vue';

// vuex
import { mapActions, mapGetters } from 'vuex';
import Actions, { Getters } from '@/store/mutation-types';

interface selectedPlan {
    id: string;
    label: string;
}

export default {
    name: 'OEExecutionsSearch',

    components: {
        AppHeader,
        AppButton,
        AppInputV3,
        TagsMultiselect,
        SearchBanner,
    },
    mixins: [OePlansMixin],

    data() {
        return {
            isSearchButtonClicked: false as boolean,
            mappedPlanIds: [] as string[] | [],
            searchValue: '' as string,
            selectedPlans: [] as selectedPlan[] | [],

            ICON_TYPES: ICON_TYPES as any,
            BUTTON_TYPES: BUTTON_TYPES as any,
        };
    },

    computed: {
        ...mapGetters('orchestrationengine', {
            getOEPlans: Getters.GET_OE_PLANS,
        }),
        plansOptions(): void {
            // eslint-disable-next-line camelcase
            return this.getOEPlans.map(plan => ({ id: plan?.plan_id, label: plan?.plan_id }));
        },
        writeEnabled(): boolean {
            return (
                isUserAllowed('MappedExecutionSearch') &&
                permissionsService.orchestrationEngineMappedExecutionsSearchEnabled()
            );
        },
    },
    async created() {
        try {
            this.$Progress.start();
            await this.requestPlans();
            this.$Progress.finish();
        } catch (error: any) {
            this.$Progress.fail();
            Sentry.captureException(error);

            this.$eventBus.$emit('showAlert', {
                message: this.$i18n.t('alertMessage.failedToLoadNecessaryData'),
            });
        }
    },
    methods: {
        ...mapActions('orchestrationengine', {
            requestPlans: Actions.REQUEST_OE_PLANS,
        }),
        async onSearch(): Promise<any> {
            if (this.searchValue) {
                this.isSearchButtonClicked = true;

                this.mappedPlanIds = this.selectedPlans.map(plan => plan.id);

                const data = {
                    mapper_ids: [this.searchValue],
                    ...(this.mappedPlanIds.length && { plan_ids: this.mappedPlanIds }),
                };

                try {
                    await this.fetchExecutions(data);
                    const result = this.oeMappingsList;

                    if (result.length) {
                        this.$router.push({
                            name: RouteNames.ORCHESTRATION_ENGINE_MAPPED_EXECUTIONS_SEARCH_RESULT,
                            params: {
                                searchData: data,
                                companyId: this.$route.params.companyId,
                            },
                        });
                    } else {
                        this.$eventBus.$emit('showAlert', {
                            message: this.$i18n.t('orchestrationEngine.executionsSearch.executionsDoNotExist'),
                            type: ALERT_TYPES.error,
                        });
                    }
                } catch (error: any) {
                    this.$eventBus.$emit('showAlert', {
                        message: this.$i18n.t(
                            'orchestrationEngine.executionsSearch.somethingWentWrongFetchingExecutionsData',
                        ),
                        type: ALERT_TYPES.error,
                    });
                } finally {
                    this.isSearchButtonClicked = false;
                }
            } else {
                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('orchestrationEngine.executionsSearch.mapperIdFieldIsRequired'),
                    type: ALERT_TYPES.warning,
                });
            }
        },
    },
};
