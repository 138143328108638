import { render, staticRenderFns } from "./EndUserAuthorizationBanner.vue?vue&type=template&id=b210d53c&scoped=true"
import script from "./EndUserAuthorizationBanner.vue?vue&type=script&lang=ts"
export * from "./EndUserAuthorizationBanner.vue?vue&type=script&lang=ts"
import style0 from "./EndUserAuthorizationBanner.vue?vue&type=style&index=0&id=b210d53c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b210d53c",
  null
  
)

export default component.exports