import { DnoResponseBase } from '@/http/index';
import i18n from '@/i18n';
import { TranslateResult } from 'vue-i18n';
import { TRANSACTION_TYPE } from '@/__new__/features/customerCare/common/customerCareHelper';

export const AUTHORIZATION_STEPS = {
    accountPin: 'accountPin',
    otpGeneration: 'otpGeneration',
    otpVerification: 'otpVerification',
    sensitiveOperation: 'sensitiveOperation',
} as const;

type AuthorizationSteps = typeof AUTHORIZATION_STEPS;
export type AuthorizationStepValues = AuthorizationSteps[keyof AuthorizationSteps];

export enum TargetDataType {
    msisdn = 'msisdn',
    email = 'email',
}

export type Identifier = {
    email?: string[];
    msisdn?: string[];
};

export interface MembersNotificationIdentifiersApiResponse extends DnoResponseBase {
    account_members_info: Identifier[];
}

export interface CreateChallengeApiResponse extends DnoResponseBase {
    challenge_id: string;
    expiration: number;
}

export const OTP_OPTIONS = [
    {
        id: TargetDataType.msisdn,
        label: i18n.t('customerCare.msisdn'),
    },
    {
        id: TargetDataType.email,
        label: i18n.t('generic.email'),
    },
];

export function getTargetIdDataByType(dataArr: Identifier[], type: TargetDataType) {
    return dataArr?.reduce((data: string[], current: Identifier) => {
        const currentData = current[type] || [];
        return [...new Set([...data, ...currentData])];
    }, []);
}

export enum SENSITIVE_OPERATION_TYPES {
    CHANGE_MSISDN = 'change-msisdn',
    SIM_SWAP = 'sim-swap',
    ISSUE_TEMPORARY_MNP_PIN = 'issue-temporary-mnp-pin',
    FLAG_CHANGE = 'flag-change',
}

export const SENSITIVE_OPERATION_TYPES_TO_NAME_MAP: Map<SENSITIVE_OPERATION_TYPES, TranslateResult> = new Map([
    [SENSITIVE_OPERATION_TYPES.CHANGE_MSISDN, i18n.t('authorizationPage.sensitiveOperation.changeMsisdn')],
    [SENSITIVE_OPERATION_TYPES.SIM_SWAP, i18n.t('authorizationPage.sensitiveOperation.simSwap')],
    [
        SENSITIVE_OPERATION_TYPES.ISSUE_TEMPORARY_MNP_PIN,
        i18n.t('authorizationPage.sensitiveOperation.temporaryMNPPINInfo'),
    ],
    [SENSITIVE_OPERATION_TYPES.FLAG_CHANGE, i18n.t('authorizationPage.sensitiveOperation.flagChange')],
]);

export const SENSITIVE_OPERATION_TYPES_TO_TRANSACTION_TYPE: Map<SENSITIVE_OPERATION_TYPES, TRANSACTION_TYPE> = new Map([
    [SENSITIVE_OPERATION_TYPES.CHANGE_MSISDN, TRANSACTION_TYPE.CHANGE_MSISDN],
    [SENSITIVE_OPERATION_TYPES.SIM_SWAP, TRANSACTION_TYPE.SIM_SWAP],
    [SENSITIVE_OPERATION_TYPES.ISSUE_TEMPORARY_MNP_PIN, TRANSACTION_TYPE.PORT_OUT_PIN],
    [SENSITIVE_OPERATION_TYPES.FLAG_CHANGE, TRANSACTION_TYPE.UPDATE_SIM_SWAP_AND_PORT_OUT_FLAG],
]);
