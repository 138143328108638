//

/**
 * Vue configs
 */
import Vue from 'vue';
/**
 * Global event bus
 */
const eventBus = new Vue();
export default eventBus;
