<template>
    <label
        :class="{ 'optional-no-label-wrapper': optionalNoLabel }"
        class="input-wrapper"
    >
        <div
            v-if="label || optional"
            :class="['label-wrapper', { 'optional-no-label mb-1': optionalNoLabel }]"
        >
            <div
                v-if="label"
                class="label d-flex align-items-center"
            >
                <span class="label-text">
                    {{ label }}
                </span>

                <AppTooltip
                    v-if="explanationText"
                    :offset="tooltipOffset"
                    :tooltipPosition="tooltipPosition"
                    class="ml-2"
                >
                    <template #label>
                        <div class="info-icon" />
                    </template>

                    <template #content>
                        <div class="tooltip-info">{{ explanationText }}</div>
                    </template>
                </AppTooltip>
            </div>
            <span
                v-if="optional"
                class="optional align-self-center"
            >
                {{ $i18n.t('generic.optional') }}
            </span>
        </div>
        <div
            :class="{ 'optional-no-label-content': optionalNoLabel, verified }"
            class="content"
        >
            <input
                :id="id"
                ref="inputField"
                :data-test-id="`is-input-focused-${focused}`"
                :value="value"
                :class="{
                    error: invalid || editedOutsideBounds,
                    'error-focused': invalid && value !== '',
                    'selected-focused': !invalid && value !== '',
                }"
                :disabled="disabled || verified"
                :placeholder="placeholder"
                :type="inputType"
                :min="min"
                :max="max"
                :step="step"
                @input="onInput"
                @change="onChange"
                @keyup="onKeyup"
                @blur="$emit('blur')"
            />
            <button
                v-if="showInlineButton"
                class="inline-button"
                tabindex="-1"
                @click.prevent="togglePassword"
            >
                {{ inlineButtonText }}
            </button>
        </div>
        <div
            v-if="invalid && errorMessage"
            class="error-message"
        >
            {{ errorMessage }}
        </div>
    </label>
</template>

<script>
// COMPONENTS
import AppTooltip from '@/components/partials/AppTooltip.vue';
import { TOOLTIP_POSITION } from '@/common/tooltip';

const INPUT_TYPES = {
    TEXT: 'text',
    PASSWORD: 'password',
    NUMBER: 'number',
};

export default {
    name: 'AppInputV3',
    components: {
        AppTooltip,
    },
    props: {
        value: {
            type: [String, Number],
            default: '',
        },
        label: {
            type: String,
            default: '',
        },
        placeholder: {
            type: String,
            default: '',
        },
        type: {
            type: String,
            default: 'text',
        },
        optional: {
            type: Boolean,
            default: false,
        },
        invalid: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        min: {
            type: Number,
            default: 0,
        },
        max: {
            type: Number,
            default: 100000000,
        },
        tooltipOffset: {
            type: Number,
            default: 50,
        },
        step: {
            type: Number,
            default: 1,
        },
        roundNumber: {
            type: Boolean,
            default: false,
        },
        notAllowZero: {
            type: Boolean,
            default: false,
        },
        allowEmptyValueAsNull: {
            type: Boolean,
            default: false,
        },
        disablePadding: {
            type: Boolean,
            default: false,
        },
        id: {
            type: String,
            default: '',
        },
        optionalNoLabel: {
            type: Boolean,
            default: false,
        },
        errorMessage: {
            type: String,
            required: false,
            default: '',
        },
        verified: {
            type: Boolean,
            default: false,
        },
        explanationText: {
            type: String,
            required: false,
            default: '',
        },
        formatter: {
            type: Function,
            required: false,
            default: null,
        },
        tooltipPosition: {
            type: String,
            default: TOOLTIP_POSITION.top,
        },
        focused: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            inlineButtonText: this.$i18n.t('generic.show'),
            inputType: this.type,
            TOOLTIP_POSITION,
            edited: false,
        };
    },
    computed: {
        allowDecimal() {
            return this.type === INPUT_TYPES.NUMBER && this.step < 1;
        },
        showInlineButton() {
            return this.type === INPUT_TYPES.PASSWORD;
        },
        editedOutsideBounds() {
            return (
                this.edited &&
                this.type === INPUT_TYPES.NUMBER &&
                this.value !== null &&
                (this.value < this.min || this.value > this.max)
            );
        },
    },
    mounted() {
        if (this.focused) {
            this.$refs.inputField.focus();
        }
    },
    methods: {
        onInput(e) {
            let value = this.formatter ? this.formatter(e.target.value) : e.target.value;
            const oldValue = this.value;

            if (this.type === INPUT_TYPES.NUMBER) {
                if (this.roundNumber) {
                    const divider = 1 / this.step;
                    value = Math.round(value * divider) / divider;
                } else if (this.notAllowZero && (value === '' || value === '0')) {
                    value = oldValue;
                } else if (this.allowEmptyValueAsNull) {
                    value = `${value}`.length ? +value : null;
                } else {
                    value = +value;
                }
            }

            // Update UI's input value.
            // Fixes a bug for decimal when '.' is not present in event value
            // if no number comes after it.
            if (!this.allowDecimal || (this.allowDecimal && value.toString().includes('.'))) {
                e.target.value = value;
            }

            this.$emit('input', value);
        },
        onChange(event) {
            this.$emit('change', event.target.value);
            this.edited = true;
        },
        onKeyup(event) {
            this.$emit('keyup', event);
        },
        togglePassword() {
            if (this.inputType === INPUT_TYPES.TEXT) {
                this.inputType = INPUT_TYPES.PASSWORD;
                this.inlineButtonText = this.$i18n.t('generic.show');
            } else {
                this.inputType = INPUT_TYPES.TEXT;
                this.inlineButtonText = this.$i18n.t('generic.hide');
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/_palette.scss';
@import '~@/assets/scss/_layout.scss';
@import '~@/assets/scss/_typographyV2';

.inline-button {
    position: absolute;
    right: 1rem;
    border: none;
    background: transparent;
    color: $blue;
}

.input-wrapper {
    display: block;
    align-items: center;
    color: $gray90;

    .label-wrapper {
        display: flex;
        justify-content: space-between;
        padding-bottom: $spacing-xxs;
    }

    .label {
        font-size: $text-xs;
        color: $gray90;
        font-weight: 600;
        line-height: 1.625rem;
    }

    .label-text {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .content {
        position: relative;
        display: flex;
        align-items: center;
        flex: 0 1 80%;

        input {
            position: relative;
            width: 100%;
            height: 2rem;
            border-radius: 0.5rem;
            padding: 0.25rem 0.5rem 0.375rem;
            outline: none;
            border: 1px solid $gray5;
            line-height: 20px;
            font-size: 0.875rem;
            color: $gray90;

            &:focus {
                border-color: $blue;
            }

            &:disabled {
                background-color: $input-background-disabled;
                border: 1px solid $gray5;
                color: $gray30;
                cursor: not-allowed;
            }

            &.error {
                border-color: $red;
            }
        }

        &.verified {
            &:after {
                content: '';
                position: absolute;
                top: $spacing-xs;
                right: $spacing-xs;
                width: 1rem;
                height: 1rem;
                background: url('../../../assets/icons/input-verified.svg');
            }

            input {
                color: $gray90;
                padding-right: $spacing-xl;
                background-color: $green10;
                border-color: $green15;
            }
        }
    }

    .optional {
        font-weight: 600;
        font-size: 0.75rem;
        line-height: 1.625rem;
        font-style: italic;
        color: $gray60;
    }

    .optional-no-label {
        justify-content: flex-end;
        align-self: flex-end;
    }

    .error-message {
        padding-top: $spacing-xxs;
        font-size: 0.75rem;
        line-height: 1.25rem;
        color: $red;
    }
}

.optional-no-label-wrapper {
    display: flex;
    flex-direction: column;
    top: -0.75rem;
}

.optional-no-label-content {
    align-self: flex-end;
    width: inherit;
}

::placeholder {
    font-style: italic;
    color: $gray30;
}

::v-deep .multiselect__placeholder {
    margin-bottom: 0;
}

.tooltip-info {
    white-space: pre;
    font-size: 0.75rem;
    font-weight: normal;
    text-transform: none;
    line-height: normal;
    color: $gray90;
    min-width: 10rem;
}
</style>
