var render = function render(){var _vm=this,_c=_vm._self._c;return _c('label',{staticClass:"wrap",class:{
        disabled: _vm.disabled,
        checked: _vm.value,
        'full-width': _vm.fullWidth,
        'is-view': _vm.isView,
        error: _vm.invalid,
    }},[_c('input',{staticClass:"check",attrs:{"disabled":_vm.disabled || _vm.isView,"type":"checkbox"},domProps:{"checked":_vm.value,"value":_vm.value},on:{"change":e => _vm.$emit('input', e.target.checked)}}),_c('span',{staticClass:"icon"},[_c('div',{staticClass:"d-flex flex-column ml-3"},[_c('div',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.label)+" ")]),_c('div',{staticClass:"description"},[_vm._v(" "+_vm._s(_vm.description)+" ")])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }