<template>
    <div class="d-flex-inline flex-column align-items-center">
        <AppLabel
            :title="statusConverter"
            :color="colorConverter"
        />
    </div>
</template>
<script>
// COMPONENTS
import AppLabel from '@/components/partials/AppLabel.vue';

// HELPERS
import {
    subscriberStateToText,
    subscriberStateToColor,
} from '@/__new__/features/customerCare/common/customerCareHelper';
import * as Sentry from '@sentry/vue';

export default {
    name: 'SubscriberStatusIndicator',
    components: {
        AppLabel,
    },
    props: {
        status: {
            required: true,
            type: [String, Number],
        },
    },
    computed: {
        statusConverter() {
            try {
                const castStatus = Number(this.status);
                if (!Number.isNaN(castStatus)) {
                    if (castStatus !== 0) {
                        return subscriberStateToText(castStatus);
                    }
                    throw new Error(this.$i18n.t('alertMessage.stateHistory.unmappedStatus'));
                }

                return this.status;
            } catch (error) {
                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('alertMessage.stateHistory.unmappedStatus'),
                });
                Sentry.captureException(error);
                return this.$i18n.t('generic.stateMap.unknownState');
            }
        },
        colorConverter() {
            return subscriberStateToColor(this.status);
        },
    },
};
</script>

<style lang="scss" scoped></style>
