
import Vue, { type PropType } from 'vue';

// Components
import AppTable from '@/components/partials/AppTable.vue';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import IconButton from '@/components/partials/IconButton.vue';
import SearchBox from '@/components/partials/inputs/SearchBox.vue';
import TableFiltersRenderless from '@/components/filters/TableFiltersRenderless.vue';
import TableFiltersTags from '@/components/filters/TableFiltersTags.vue';
import FilterTable from '@/components/partials/FilterTable.vue';
import AppAditionalSidebar from '@/components/partials/AppAditionalSidebar.vue';
import ResponseModalButton from '@/components/partials/ResponseModalButton.vue';

// Helpers
import httpNotes from '@/__new__/services/dno/crm/http/crm';
import AgentNote, { type AgentNoteBe } from '@/__new__/services/dno/crm/models/AgentNote';
import { ALERT_TYPES } from '@/common/alerts/Alert';
import * as Sentry from '@sentry/vue';
import { USER_MANAGER_HIERARCHY } from '@/__new__/features/customerCare/common/customerCareHelper';
import tableColumnType from '@/common/filterTable';
import supportButtonMixin from '@/components/alerts/supportButtonMixin';
import RouteNames from '@/router/routeNames';
import { getDocumentType } from '@/common/documentTypeHelper';
import download from 'downloadjs';
import { ICON_TYPES } from '@/common/iconHelper';
import { isUserAllowed } from '@/services/permissions/permissions.service';

type Note = { noteKey: number } & AgentNote;

export default Vue.extend({
    name: 'NotesSection',
    components: {
        AppTable,
        AppButton,
        IconButton,
        SearchBox,
        TableFiltersRenderless,
        TableFiltersTags,
        FilterTable,
        AppAditionalSidebar,
        ResponseModalButton,
    },
    mixins: [supportButtonMixin],
    props: {
        idType: {
            type: Number,
            default: USER_MANAGER_HIERARCHY.ACCOUNT,
        },
        entities: {
            type: Object as PropType<Record<string, USER_MANAGER_HIERARCHY>>,
            default: () => ({}),
        },
    },
    data() {
        return {
            notesSearchQuery: '',
            notes: [] as AgentNote[],
            notesApiResponse: null as unknown as Record<string, any>,
            selectedNote: {} as Note | undefined,
            ICON_TYPES,
            BUTTON_TYPES,
            isSearchOpened: false,
            isSidebarVisible: false,
            isLoading: false,
        };
    },
    computed: {
        isAddNoteAllowed(): boolean {
            switch (this.idType) {
                case USER_MANAGER_HIERARCHY.USER:
                    return isUserAllowed('UMUserAgentNotesAdd');

                case USER_MANAGER_HIERARCHY.ACCOUNT:
                    return isUserAllowed('UMAccountAgentNotesAdd');

                case USER_MANAGER_HIERARCHY.SUBSCRIBER:
                    return isUserAllowed('UMSubscriberAgentNotesAdd');

                default:
                    return false;
            }
        },
        isUser(): boolean {
            return this.idType === USER_MANAGER_HIERARCHY.USER;
        },
        formattedNotes(): Note[] {
            return this.notes.map((agentNote, index: number) => ({
                ...agentNote,
                noteKey: index,
            }));
        },
        notesColumnsData(): Record<string, any> {
            const columns = [
                {
                    name: this.$i18n.t('customerCare.notesSection.timestamp'),
                    key: 'epoch',
                    field: 'epoch',
                    filterType: tableColumnType.DATE,
                },
                {
                    name: this.$i18n.t('customerCare.notesSection.agentName'),
                    key: 'creatorName',
                    field: 'creatorName',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('customerCare.notesSection.note'),
                    key: 'noteText',
                    field: 'noteText',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('customerCare.notesSection.tags'),
                    key: 'tags',
                    field: 'tags',
                    filterType: tableColumnType.TEXT_LIMITED_OPTIONS_CONTAINS,
                    limitedOptions: Array.from(new Set(this.notes.map(note => note.tags).flat())),
                },
            ];

            if (this.isUser) {
                columns.unshift(
                    {
                        name: this.$i18n.t('generic.id'),
                        key: 'targetId',
                        field: 'targetId',
                        filterType: tableColumnType.GENERAL_TEXT,
                    },
                    {
                        name: this.$i18n.t('generic.type'),
                        key: 'targetType',
                        field: 'targetType',
                        filterType: tableColumnType.TEXT_LIMITED_OPTIONS,
                        limitedOptions: Array.from(new Set(this.notes.map(note => note.targetType))),
                    },
                );
            }

            return columns;
        },
        displayTags(): boolean {
            return Boolean(this.selectedNote?.tags?.length);
        },
        selectedNoteId(): number | string {
            return this.selectedNote?.noteKey ?? '';
        },
    },
    async created() {
        await this.fetchNotes();
    },
    methods: {
        onDownloadButtonClicked(entity: Note) {
            Object.entries(entity.documentUrls || {}).forEach(docURL => this.downloadFile(...docURL));
        },
        async downloadFile(name: string, url: string) {
            try {
                const res = await fetch(url);
                const mimeType = res.headers.get('Content-Type') || '';
                const blob = await res.blob();
                const docType = getDocumentType(mimeType);

                download(blob, `${name}${docType}`, docType);
            } catch (e) {
                Sentry.captureException(e);
                this.showSupportAlert(
                    `${this.$i18n.t(
                        'customerCare.notesSection.errors.somethingWentWrongWhileDownloadingFile',
                    )} ${name}`,
                    ALERT_TYPES.error,
                );
            }
        },
        addNewNote() {
            this.$router.push({
                name: RouteNames.AGENT_NOTES_NEW_NOTE,
                params: {
                    id: this.$route.params.id,
                    idType: this.idType,
                    companyId: this.$route.params.companyId,
                },
            });
        },
        fetchNotes() {
            this.$withProgressBar(
                async () => {
                    this.isLoading = true;

                    await (this.isUser ? this.fetchUserNotes() : this.fetchEntityNotes());

                    this.isLoading = false;
                },
                {
                    errorHandler: e => {
                        this.isLoading = false;
                        this.notesApiResponse = e.response;
                        this.showSupportAlert(
                            this.$i18n.t('alertMessage.somethingWentWrongFetchingAgentNotes'),
                            ALERT_TYPES.error,
                        );
                    },
                },
            );
        },
        async fetchEntityNotes() {
            this.notesApiResponse = await httpNotes.getNotes(this.$route.params.id, this.idType);

            if (Array.isArray(this.notesApiResponse?.data)) {
                this.notes = this.mapNotes(this.notesApiResponse.data.flat(Infinity));
            }
        },
        async fetchUserNotes() {
            const ids = [[this.$route.params.id, this.idType], ...Object.entries(this.entities || {})].map(
                ([id, type]) => ({
                    id,
                    id_type: type,
                }),
            );

            this.notesApiResponse = await httpNotes.getNotesByIds(ids);

            if (Array.isArray(this.notesApiResponse?.data?.notes)) {
                this.notes = this.mapNotes(this.notesApiResponse.data.notes);
            }
        },
        mapNotes(notes: AgentNoteBe[]) {
            return notes.map(note => new AgentNote(AgentNote.remapNoteFromBe(note)));
        },
        showSidebar() {
            this.isSidebarVisible = true;
        },
        selectedRow(id: number) {
            this.selectedNote = this.formattedNotes.find(note => note.noteKey === id);
        },
    },
});
