
import Vue from 'vue';

// Components
import AppMultiselectV3 from '@/components/partials/inputs/AppMultiselectV3.vue';
import UnitPicker from '@/components/partials/inputs/UnitPicker.vue';

// Helpers
import DataBalanceCondition from '@/__new__/services/dno/charging/models/chargingSpecificationConditions/DataBalance';
import { MAP_DATA_BALANCE_OPERATOR_VALUES } from '@/__new__/services/dno/charging/common/dataBalanceCondition';

export default Vue.extend({
    name: 'DataBalance',
    components: {
        AppMultiselectV3,
        UnitPicker,
    },
    props: {
        value: {
            type: Object,
            default: () => undefined,
        },
    },
    data() {
        return {
            dataBalance: new DataBalanceCondition(this.value),
            options: MAP_DATA_BALANCE_OPERATOR_VALUES,
        };
    },
    watch: {
        value: {
            handler() {
                this.dataBalance = new DataBalanceCondition(this.value);
            },
        },
    },
    methods: {
        onDataInput(): void {
            this.dataBalance.validate();
            this.$emit('input', this.dataBalance);
        },
    },
});
