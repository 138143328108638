<template>
    <InfoCard class="card-wrapper">
        <template #headerTitle>
            <span>
                {{ title }}
            </span>
        </template>
        <template #headerAdditionalInfo>
            <div v-show="activeEpoch">
                {{ `${$i18n.t('customerCare.subscriberTab.expire')} ${activeEpoch}` }}
            </div>
            <div v-show="graceEpoch">
                {{ `${$i18n.t('customerCare.subscriberTab.grace')} ${graceEpoch}` }}
            </div>
        </template>
        <template #content>
            <span>
                {{ amount }}
            </span>
        </template>
        <template #footerButton>
            <div class="d-flex align-items-center">
                <AppButton
                    v-if="isDeductEnabled"
                    :label="$i18n.t('customerCare.subscriberTab.deductBalance')"
                    :disabled="!isEditAllowed"
                    class="deduct mr-4"
                    @click="$emit('onDeductAmount')"
                />
                <AppButton
                    v-if="isTopUpEnabled"
                    :buttonType="BUTTON_TYPES.SECONDARY"
                    :label="$i18n.t('customerCare.subscriberTab.top-up')"
                    :disabled="!isEditAllowed"
                    @click="$emit('onTopUpAmount')"
                />
            </div>
        </template>
    </InfoCard>
</template>

<script>
import InfoCard from '@/components/partials/cards/InfoCard.vue';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';

export default {
    name: 'BalanceInfoCard',
    components: {
        InfoCard,
        AppButton,
    },
    props: {
        title: {
            type: String,
            default: '',
        },
        activeEpoch: {
            type: String,
            default: '',
        },
        graceEpoch: {
            type: String,
            default: '',
        },
        amount: {
            type: String,
            default: '',
        },
        isDeductEnabled: {
            type: Boolean,
            default: true,
        },
        isTopUpEnabled: {
            type: Boolean,
            default: true,
        },
        isEditAllowed: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            BUTTON_TYPES,
        };
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/consistency-colors';

.card-wrapper {
    width: 17.5rem;
    max-width: 100%;
    margin-right: 1.5rem;
    background: $blue-400;
}

.deduct:not(:hover) {
    color: $white;
}
</style>
