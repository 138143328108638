<template>
    <TagsInput
        :value="tags"
        :placeholder="placeholder"
        :invalid="invalid && displayError"
        :disabled="disabled"
        @input="emitUpdate"
    />
</template>

<script>
import TagsInput from '@/components/partials/inputs/TagsInput.vue';

export default {
    name: 'ConditionArgTagsInput',
    components: {
        TagsInput,
    },
    props: {
        value: {
            type: Array,
            default: () => [],
        },
        placeholder: {
            type: String,
            default: '',
        },
        displayError: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        invalid() {
            return !this.value.length;
        },
        tags() {
            return this.value.map(v => ({ text: v }));
        },
    },
    watch: {
        invalid: {
            immediate: true,
            handler(value) {
                this.$emit('invalid', value);
            },
        },
    },
    methods: {
        emitUpdate(tags) {
            this.$emit(
                'input',
                tags.map(({ text }) => text),
            );
        },
    },
};
</script>
