import { render, staticRenderFns } from "./BucketData.vue?vue&type=template&id=c1146600&scoped=true"
import script from "./BucketData.vue?vue&type=script&lang=ts"
export * from "./BucketData.vue?vue&type=script&lang=ts"
import style0 from "./BucketData.vue?vue&type=style&index=0&id=c1146600&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c1146600",
  null
  
)

export default component.exports