import http from '@/http/index';
import ENTITY_TYPES from '@/common/entities/entityTypes';
import { STATUS_CODES } from '@/common/commonHelper';

/**
 * Get bulk progress.
 *
 * @returns {AxiosPromise}
 */
export function getBulkProgress(jobId: string) {
    const data = {
        job_id: jobId,
    };

    return http({
        method: 'POST',
        url: '/v3productcatalog/getbulkprogress',
        data,
    });
}

/**
 * Get bulk detail.
 *
 * @returns {AxiosPromise}
 */
export function getBulkDetail(jobId: string, eventCategory: string, entityId: string) {
    const data = {
        job_id: jobId,
        event_category: eventCategory,
        entity_id: entityId,
    };

    return http({
        method: 'POST',
        url: '/v3productcatalog/getbulkdetail',
        data,
    });
}

type bulkChangeData = {
    entity_type: ENTITY_TYPES;
    entity_ids: string[];
    state: STATUS_CODES;
    data: unknown;
};

/**
 * Submit bulk change.
 *
 * @returns {AxiosPromise}
 */
export function submitBulkChange(data: bulkChangeData) {
    return http({
        method: 'POST',
        url: '/v3productcatalogwrite/submitbulkchange',
        data,
    });
}

export default {
    submitBulkChange,
    getBulkProgress,
    getBulkDetail,
};
