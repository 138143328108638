import i18n from '@/i18n';
import { EntityStateMapping } from '@/common/commonEntityStateMapper';
import ENTITY_TYPES from '@/common/entities/entityTypes';
import { LABEL_COLOR } from '@/common/labelsHelper';

/**
 * This function is used for filtering out entities that have are deleted
 */
export function filterOutDeletedEntities(entityList: any[]): object[] {
    return entityList.filter(({ state }) => state !== EntityStateMapping.DELETED);
}

export enum STATUS_CODES {
    UNAPPROVED = 1,
    APPROVED = 2,
    PAUSED = 3,
    DELETED = 4,
    PENDING = 5,
    REJECTED = 6,
    ACTIVE = 100,
    CANCELED = 200,
    REPLACED = 300,
    EXPIRED = 400,
    DEACTIVATED = 500,
    EXTERNAL_FAILED = 601,
    // these doesn't exist on the backend and they're all are more clarified Approved states
    RUNNING = 21,
    UPCOMING = 22,
    FINISHED = 23,
    NA = 24,
    DRAFT = 25,
    PUBLISHED = 26,
    UNPUBLISHEDCHANGES = 27,
}

export const STATUS_INDICATOR_MAP = new Map([
    [STATUS_CODES.UNAPPROVED, LABEL_COLOR.red],
    [STATUS_CODES.APPROVED, LABEL_COLOR.green],
    [STATUS_CODES.PAUSED, LABEL_COLOR.yellow],
    [STATUS_CODES.DELETED, LABEL_COLOR.red],
    [STATUS_CODES.PENDING, LABEL_COLOR.yellow],
    [STATUS_CODES.REJECTED, LABEL_COLOR.red],
    [STATUS_CODES.ACTIVE, LABEL_COLOR.green],
    [STATUS_CODES.CANCELED, LABEL_COLOR.red],
    [STATUS_CODES.REPLACED, LABEL_COLOR.blue],
    [STATUS_CODES.EXPIRED, LABEL_COLOR.red],
    [STATUS_CODES.DEACTIVATED, LABEL_COLOR.red],
    [STATUS_CODES.EXTERNAL_FAILED, LABEL_COLOR.red],
    [STATUS_CODES.RUNNING, LABEL_COLOR.green],
    [STATUS_CODES.UPCOMING, LABEL_COLOR.blue],
    [STATUS_CODES.FINISHED, LABEL_COLOR.gray],
    [STATUS_CODES.NA, LABEL_COLOR.gray],
    [STATUS_CODES.DRAFT, LABEL_COLOR.gray],
    [STATUS_CODES.PUBLISHED, LABEL_COLOR.green],
    [STATUS_CODES.UNPUBLISHEDCHANGES, LABEL_COLOR.yellow],
]);

export const STATUS_CODE_TO_STATUS_NAME_MAP = new Map([
    [STATUS_CODES.UNAPPROVED, i18n.t('generic.stateMap.unapproved')],
    [STATUS_CODES.APPROVED, i18n.t('generic.stateMap.approved')],
    [STATUS_CODES.PAUSED, i18n.t('generic.stateMap.paused')],
    [STATUS_CODES.DELETED, i18n.t('generic.stateMap.deleted')],
    [STATUS_CODES.PENDING, i18n.t('generic.stateMap.pending')],
    [STATUS_CODES.REJECTED, i18n.t('generic.stateMap.rejected')],
    [STATUS_CODES.ACTIVE, i18n.t('generic.stateMap.active')],
    [STATUS_CODES.CANCELED, i18n.t('generic.stateMap.canceled')],
    [STATUS_CODES.REPLACED, i18n.t('generic.stateMap.replaced')],
    [STATUS_CODES.EXPIRED, i18n.t('generic.stateMap.expired')],
    [STATUS_CODES.DEACTIVATED, i18n.t('generic.stateMap.deactivated')],
    [STATUS_CODES.EXTERNAL_FAILED, i18n.t('generic.stateMap.externalProvisionFailed')],
    [STATUS_CODES.RUNNING, i18n.t('generic.stateMap.running')],
    [STATUS_CODES.UPCOMING, i18n.t('generic.stateMap.upcoming')],
    [STATUS_CODES.FINISHED, i18n.t('generic.stateMap.finished')],
    [STATUS_CODES.NA, i18n.t('generic.N/A')],
    [STATUS_CODES.DRAFT, i18n.t('generic.stateMap.draft')],
    [STATUS_CODES.PUBLISHED, i18n.t('generic.stateMap.published')],
    [STATUS_CODES.UNPUBLISHEDCHANGES, i18n.t('generic.stateMap.unpublishedChanges')],
]);

export enum FILE_UPLOAD_STATES {
    SUCCESSFUL_COMPLETED = 'Successful completed',
    PARTIAL_COMPLETED = 'Partial successful',
    FAILED_COMPLETED = 'Fail to complete',
    PENDING = 'Pending files',
    PROCESSING = 'Processing files',
    UPLOADING = 'Uploading files',
    UPLOADING_FAILED = 'Uploading failed',
}

export const FILE_UPLOAD_STATES_TO_STATUS_NAME_MAP = new Map([
    [FILE_UPLOAD_STATES.PENDING, i18n.t('generic.stateMap.pending')],
    [FILE_UPLOAD_STATES.UPLOADING, i18n.t('generic.stateMap.uploading')],
    [FILE_UPLOAD_STATES.PROCESSING, i18n.t('generic.stateMap.processing')],
    [FILE_UPLOAD_STATES.SUCCESSFUL_COMPLETED, i18n.t('generic.stateMap.processingFinished')],
    [FILE_UPLOAD_STATES.PARTIAL_COMPLETED, i18n.t('generic.stateMap.processingFinished')],
    [FILE_UPLOAD_STATES.FAILED_COMPLETED, i18n.t('generic.stateMap.processingFinished')],
    [FILE_UPLOAD_STATES.UPLOADING_FAILED, i18n.t('generic.stateMap.uploading')],
]);

export const FILE_UPLOAD_INDICATOR_MAP = new Map([
    [FILE_UPLOAD_STATES_TO_STATUS_NAME_MAP.get(FILE_UPLOAD_STATES.PENDING), LABEL_COLOR.yellow],
    [FILE_UPLOAD_STATES_TO_STATUS_NAME_MAP.get(FILE_UPLOAD_STATES.UPLOADING), LABEL_COLOR.blue],
    [FILE_UPLOAD_STATES_TO_STATUS_NAME_MAP.get(FILE_UPLOAD_STATES.PROCESSING), LABEL_COLOR.blue],
    [FILE_UPLOAD_STATES_TO_STATUS_NAME_MAP.get(FILE_UPLOAD_STATES.PARTIAL_COMPLETED), LABEL_COLOR.green],
    [FILE_UPLOAD_STATES_TO_STATUS_NAME_MAP.get(FILE_UPLOAD_STATES.FAILED_COMPLETED), LABEL_COLOR.green],
    [FILE_UPLOAD_STATES_TO_STATUS_NAME_MAP.get(FILE_UPLOAD_STATES.SUCCESSFUL_COMPLETED), LABEL_COLOR.green],
]);

export enum STATUS_CODES_OPERATIONS {
    NA = -1,
    RUNNING = 0,
    PENDING = 1,
    EXPIRED = 2,
    STOPPED = 3,
}

export const STATUS_OPERATIONS_INDICATOR_MAP = new Map([
    [STATUS_CODES_OPERATIONS.NA, LABEL_COLOR.gray],
    [STATUS_CODES_OPERATIONS.PENDING, LABEL_COLOR.yellow],
    [STATUS_CODES_OPERATIONS.RUNNING, LABEL_COLOR.green],
    [STATUS_CODES_OPERATIONS.EXPIRED, LABEL_COLOR.red],
    [STATUS_CODES_OPERATIONS.STOPPED, LABEL_COLOR.gray],
]);

export const ENTITY_TYPE_TO_AVAILABLE_BE_STATES_MAP = new Map([
    [ENTITY_TYPES.OFFER, [STATUS_CODES.UNAPPROVED, STATUS_CODES.APPROVED, STATUS_CODES.PAUSED, STATUS_CODES.DELETED]],
    [ENTITY_TYPES.PRODUCT, [STATUS_CODES.UNAPPROVED, STATUS_CODES.APPROVED, STATUS_CODES.DELETED]],
    [ENTITY_TYPES.SERVICE, [STATUS_CODES.UNAPPROVED, STATUS_CODES.APPROVED, STATUS_CODES.DELETED]],
    [ENTITY_TYPES.RESOURCE, [STATUS_CODES.UNAPPROVED, STATUS_CODES.APPROVED, STATUS_CODES.DELETED]],
    [ENTITY_TYPES.FACET, [STATUS_CODES.UNAPPROVED, STATUS_CODES.APPROVED, STATUS_CODES.DELETED]],
]);

export enum USER_MODES {
    REGULAR = 'regular',
    POWER = 'power',
    EXPERIMENTAL = 'experimental',
}

export function checkForUnpublishedChanges(entity: any, draftEntities: any) {
    const draftEntity = draftEntities.find((elem: any) => elem.id === entity.id);
    if (draftEntity) {
        if (!('updateTime' in draftEntity)) {
            throw new Error(`Draft entity: ${draftEntity.id} doesn't contain property 'updateTime'`);
        }

        if (!('update_time' in entity)) {
            throw new Error(`Entity: ${entity.id} doesn't contain property 'update_time'`);
        }

        return entity.update_time >= draftEntity.updateTime ? STATUS_CODES.PUBLISHED : STATUS_CODES.UNPUBLISHEDCHANGES;
    } else {
        return STATUS_CODES.PUBLISHED;
    }
}
