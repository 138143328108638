<template>
    <AbstractSubSidebarPageWrapper :pageTitle="$i18n.t('settings.settings')">
        <template #subSidebar>
            <SubSidebar
                v-model="selectedRouteName"
                :sections="permissionFilteredSections"
                class="sidebar"
                @change="changeActiveSection"
            />
        </template>
        <template #content>
            <div class="tenant-section">
                <div>
                    <div class="editor-section">
                        <section>
                            <h3 class="lf-title my-3">
                                {{ $i18n.t('settings.generalConfiguration') }}
                            </h3>
                            <AppInputV3
                                key="name"
                                v-model="tenant.caption"
                                :label="$i18n.t('tenants.tenantName')"
                                :invalid="$v.tenant.caption.$error"
                                type="text"
                                class="editor-input-largest mb-3"
                            />
                            <AppTextareaV3
                                v-model="tenant.description"
                                :label="$i18n.t('generic.description')"
                                class="editor-input-largest mb-3"
                            />
                            <AppTextareaV3
                                v-model="tenant.notes"
                                :label="$i18n.t('generic.notes')"
                                class="editor-input-largest mb-3"
                            />
                            <AppToggle
                                v-if="!hasTenantId"
                                v-model="tenant.isGenerateKeys"
                                :label="$t('settings.generateOperatorSecretKey')"
                                :small="true"
                                class="mb-3"
                                data-test-id="generate-key"
                            />
                            <!-- in next phase we need to remove this part or move it to dno config -->
                            <AppInputV3
                                key="dnoIdentifier"
                                v-model="tenant.dnoIdentifier"
                                :label="$i18n.t('tenants.operatorName')"
                                :invalid="$v.tenant.dnoIdentifier.$error"
                                type="text"
                                class="editor-input-largest mb-1"
                            />
                            <AppButton
                                :label="$i18n.t('tenants.useTenantName')"
                                class="use-tenant-name mb-3"
                                @click="useTenantName"
                            />
                            <AppInputV3
                                key="hostname"
                                v-model="tenant.firstSubdomain"
                                :label="$i18n.t('authentication.hostnameFirstSubdomain')"
                                :explanationText="$i18n.t('authentication.firstSubdomainInfo')"
                                :optional="true"
                                :tooltipPosition="TOOLTIP_POSITION.right"
                                type="text"
                                class="editor-input-largest mb-1"
                            />
                        </section>
                        <section
                            v-if="tenantsList.length > 0"
                            class="editor-input-largest"
                        >
                            <h3 class="lf-title mt-5 mb-3">
                                {{ $i18n.t('tenants.accessibleTenants') }}
                            </h3>
                            <div class="d-flex justify-content-between table-header">
                                <span>{{ $i18n.t('generic.name') }}</span>
                                <span>{{ $i18n.t('tenants.isAccessible') }}</span>
                            </div>
                            <div
                                v-for="accessibleTenant in accessibleTenantsData"
                                :key="accessibleTenant.companyId"
                            >
                                <AppCheckbox
                                    v-model="accessibleTenant.isAccessible"
                                    :labelLeft="accessibleTenant.name"
                                    :justifyCenter="true"
                                    data-test-id="is-accessible"
                                />
                            </div>
                        </section>
                        <section v-if="!isViewConfig">
                            <h3 class="lf-title my-3">
                                {{ $i18n.t('settings.operatorFeatures') }}
                            </h3>
                            <div class="table-header row">
                                <div class="col-9">
                                    {{ $i18n.t('generic.name') }}
                                </div>
                                <div class="col-3">
                                    {{ $i18n.t('generic.state') }}
                                </div>
                            </div>
                            <FeaturesTree
                                v-for="(value, key, index) in features"
                                :key="`${index}-${key}`"
                                :feature="value"
                                class="root"
                                @change="featuresTreeChange"
                            />
                        </section>
                        <section v-if="isViewConfig">
                            <h3 class="lf-title my-3">
                                {{ $i18n.t('settings.operatorViews') }}
                            </h3>
                            <div class="table-header row">
                                <div class="col-6">
                                    {{ $i18n.t('generic.name') }}
                                </div>
                                <div class="col-5">
                                    {{ $i18n.t('generic.description') }}
                                </div>
                                <div class="col-1">
                                    {{ $i18n.t('generic.state') }}
                                </div>
                            </div>
                            <ViewsTree
                                v-for="(value, key, index) in views"
                                :key="`${index}-${key}`"
                                :view="value"
                                class="root"
                                @change="viewsTreeChange"
                            />
                        </section>
                        <section>
                            <h3 class="lf-title my-3">
                                {{ $i18n.t('settings.operatorConfiguration') }}
                            </h3>
                            <AppJSON
                                v-model="operatorConfig"
                                textareaHeight="20rem"
                            />
                        </section>
                    </div>
                </div>
                <div class="d-flex justify-content-between">
                    <AppButton
                        :label="$i18n.t('generic.cancel')"
                        class="cancel-btn mr-3"
                        @click="onCancel"
                    />
                    <AppButton
                        :buttonType="BUTTON_TYPES.PRIMARY"
                        :label="$i18n.t('generic.save')"
                        :iconType="ICON_TYPES.CHECK"
                        class="save-btn"
                        @click="onSave"
                    />
                </div>
            </div>
        </template>
    </AbstractSubSidebarPageWrapper>
</template>

<script>
// Components
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import { ICON_TYPES } from '@/common/iconHelper';
import AppInputV3 from '@/components/partials/inputs/AppInputV3.vue';
import AppTextareaV3 from '@/components/partials/inputs/AppTextareaV3.vue';
import AppToggle from '@/components/partials/inputs/AppToggle.vue';
import AbstractSubSidebarPageWrapper from '@/components/layout/AbstractSubSidebarPageWrapper.vue';
import SubSidebarMixin from '@/components/partials/SubSidebarMixin.vue';
import SettingsSubSidebarMixin from '@/__new__/features/settings/SettingsSubSidebarMixin.vue';
import SubSidebar from '@/components/layout/SubSidebar.vue';
import FeaturesTree from './FeaturesTree.vue';
import ViewsTree from './ViewsTree.vue';
import AppJSON from '@/components/partials/AppJSON.vue';

// Helpers
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import { ALERT_TYPES } from '@/common/alerts/Alert';
import RouteNames from '@/router/routeNames';
import { cloneDeep } from 'lodash';
import * as Sentry from '@sentry/vue';
import eventBus from '@/eventBus';
import i18n from '@/i18n';
import supportButtonMixin from '@/components/alerts/supportButtonMixin';
import { mapFeaturesTemplate, mapDataFromAPI } from '@/common/featuresHelper';
import { mapViewsTemplate, mapViewsTreeToArray, getViewsByCompanyId } from '@/common/viewsHelper';
import { setOperatorConfig, getOperatorConfig } from '@/__new__/services/portal/operators/http/operators';
import { isViewConfig } from '@/services/permissions/permissions.service';
import Features from '@/models/Features';
import { TOOLTIP_POSITION } from '@/common/tooltip';

// Http
import {
    addTenant,
    getAccessibleTenants,
    getAllTenants,
    updateAccessibleTenants,
    updateTenant,
} from '@/http/config/tenant';
import { genereateOperatorSecretKey } from '@/__new__/services/dno/authorization/http/authorization';
import {
    getFeatures,
    getViews,
    updateOperatorFeatures,
    getCompanyFeatures,
    getCompanyViews,
} from '@/__new__/services/portal/auth/http/login';
import AppCheckbox from '@/components/partials/inputs/AppCheckbox.vue';

export default {
    name: 'TenantEditor',
    components: {
        AppButton,
        AppInputV3,
        AppTextareaV3,
        AppToggle,
        AbstractSubSidebarPageWrapper,
        SubSidebar,
        FeaturesTree,
        ViewsTree,
        AppJSON,
        AppCheckbox,
    },
    mixins: [validationMixin, SubSidebarMixin, SettingsSubSidebarMixin, supportButtonMixin],
    data() {
        return {
            isSaveButtonClicked: false,
            tenant: {
                caption: '',
                description: '',
                dnoIdentifier: '',
                notes: '',
                isGenerateKeys: false,
                firstSubdomain: '',
            },
            ICON_TYPES,
            BUTTON_TYPES,
            TOOLTIP_POSITION,
            features: {},
            featuresToAdd: [],
            featuresToRemove: [],
            views: {},
            operatorConfig: {},
            tenantsList: [],
            accessibleTenantsData: [],
        };
    },
    validations() {
        return {
            tenant: {
                caption: {
                    required,
                },
                dnoIdentifier: {
                    required,
                },
            },
        };
    },
    computed: {
        hasTenantId() {
            return !!this.$route?.params?.id;
        },
        isViewConfig() {
            return isViewConfig();
        },
    },
    async created() {
        // Locals declared above so that different try/catch blocks for new and edit behavior can appropriately access
        // Variables for company specific information will remain empty when passed into mapping functions
        let companyFeatures;
        let companyViews;
        let currentCompanyViews;
        let featuresResponse;
        let viewsResponse;
        let tenantsResponse;
        try {
            [featuresResponse, viewsResponse, tenantsResponse] = await Promise.all([
                getFeatures(),
                getViews(),
                getAllTenants(),
            ]);
            this.tenantsList = tenantsResponse.data;
        } catch (error) {
            eventBus.$emit('showAlert', {
                message: i18n.t('alertMessage.errorDoingSmthTryAgain', {
                    action: 'fetching',
                    entityName: 'features',
                }),
            });
        }

        if (this.hasTenantId) {
            this.tenant = cloneDeep(this.$store.state.operators.availableTenants[this.$route.params.id]);
            // Because the Vuex version of this lookup that was previously leveraged only contains current
            // operator config, we should instead look this up for the purpose of editing.
            // Also ensures we're always editing the current config instead of what was stored when app
            // was loaded. This prevents collsions with other users potentially modifying config.
            const currentOperatorConfig = await getOperatorConfig(this.tenant.operatorId);
            this.operatorConfig = currentOperatorConfig.data;

            // Only make external calls to lookup current information if we are editing a Tenant
            const [companyFeaturesResponse, companyViewsResponse] = await Promise.all([
                getCompanyFeatures(this.tenant.companyId),
                getCompanyViews(this.tenant.companyId),
            ]);

            companyFeatures = new Features(mapDataFromAPI(featuresResponse?.data, companyFeaturesResponse?.data));
            companyViews = getViewsByCompanyId(viewsResponse?.data, companyViewsResponse?.data);
            if (companyViews[this.tenant.companyId]) {
                currentCompanyViews = companyViews[this.tenant.companyId];
            }
        }

        // Build accessible tenant data
        if (this.hasTenantId) {
            // Editor branch
            try {
                const accessibleTenantsResponse = await getAccessibleTenants(this.$route.params.id);
                this.accessibleTenantsData = this.tenantsList
                    .filter(tenant => tenant.companyId !== this.$route.params.id) // skip self
                    .map(tenant => ({
                        companyId: tenant.companyId,
                        name: tenant.name,
                        isAccessible: accessibleTenantsResponse.data.includes(tenant.companyId),
                    }));
            } catch (error) {
                this.$alert(this.$i18n.t('tenants.failedToGetAccessibleTenantData'));
                Sentry.captureException(error);
            }
        } else {
            // New tenant branch
            this.accessibleTenantsData = this.tenantsList.map(tenant => ({
                companyId: tenant.companyId,
                name: tenant.name,
                isAccessible: false,
            }));
        }

        try {
            this.features = mapFeaturesTemplate(
                featuresResponse?.data,
                companyFeatures?.features[this.tenant.companyId],
            );
            this.views = mapViewsTemplate(viewsResponse?.data, currentCompanyViews, [], [], false);
        } catch (error) {
            eventBus.$emit('showAlert', {
                message: i18n.t('alertMessage.errorDoingSmthTryAgain', {
                    action: 'fetching',
                    entityName: 'features',
                }),
            });
        }
    },
    methods: {
        useTenantName() {
            this.tenant.dnoIdentifier = this.tenant.caption;
        },
        onCancel() {
            this.$router.push({
                name: RouteNames.TENANTS,
                params: { companyId: this.$route.params.companyId },
            });
        },
        async onSave() {
            if (this.isSaveButtonClicked) {
                return;
            }
            this.$v.$touch();

            try {
                this.isSaveButtonClicked = true;
                let returnViews = null;

                if (this.isViewConfig) {
                    returnViews = mapViewsTreeToArray(this.views);
                }
                const tenantData = {
                    name: this.tenant.caption,
                    data: this.tenant.description,
                    operatorName: this.tenant.dnoIdentifier,
                    notes: this.tenant.notes,
                    isGenerateKeys: this.tenant.isGenerateKeys,
                    operatorViews: returnViews,
                    domain: this.tenant.firstSubdomain,
                };

                let addedTenantData;
                if (!this.$v.$invalid) {
                    this.$Progress.start();
                    if (this.hasTenantId) {
                        tenantData.companyId = Number(this.$route.params.id);
                        await updateTenant(tenantData);
                        this.$eventBus.$emit('showAlert', {
                            message: this.$i18n.t('alertMessage.successMessageWithoutRedirect', {
                                action: 'updated',
                                entityName: 'tenant',
                            }),
                            type: ALERT_TYPES.success,
                        });
                    } else {
                        addedTenantData = await addTenant(tenantData);
                        this.$eventBus.$emit('showAlert', {
                            message: this.$i18n.t('alertMessage.successMessageWithoutRedirect', {
                                action: 'created',
                                entityName: 'tenant',
                            }),
                            type: ALERT_TYPES.success,
                        });
                        await this.generateOperatorSecret();
                    }
                    await updateOperatorFeatures(
                        this.tenant.companyId || addedTenantData?.data?.id,
                        this.featuresToAdd,
                        this.featuresToRemove,
                    );
                    await setOperatorConfig(
                        this.tenant.operatorId || addedTenantData?.data?.operatorid,
                        this.operatorConfig,
                    );

                    // Update accessible tenants
                    const accessibleCompanyIds = this.accessibleTenantsData
                        .filter(tenant => tenant.isAccessible)
                        .map(tenant => tenant.companyId);
                    const tenantId = this.tenant.companyId || addedTenantData?.data?.id;
                    await updateAccessibleTenants(tenantId, accessibleCompanyIds);

                    this.$Progress.finish();
                    this.$router.push({
                        name: RouteNames.TENANTS,
                        params: { companyId: this.$route.params.companyId },
                    });
                    window.location.reload();
                } else {
                    this.$eventBus.$emit('showAlert', {
                        message: this.$i18n.t('alertMessage.pleaseFixValidation'),
                    });
                }
            } catch (error) {
                Sentry.captureException(error);
                this.$Progress.fail();
                this.showSupportAlert(this.$i18n.t('alertMessage.somethingWentWrong'), ALERT_TYPES.error);
            } finally {
                this.isSaveButtonClicked = false;
            }
        },
        async generateOperatorSecret() {
            await this.$withProgressBar(
                async () => {
                    await genereateOperatorSecretKey(this.tenant.dnoIdentifier);
                    this.$showSuccessAlert({
                        message: this.$t('alerts.tenantSecretKeyGenerationSuccessfull'),
                    });
                },
                {
                    errorHandler: () =>
                        this.showSupportAlert(this.$t('alerts.tenantSecretKeyGenerationFailed'), ALERT_TYPES.error),
                },
            );
        },
        featuresTreeChange(event) {
            this.updateFeaturesTree(event);
            if (event.value) {
                if (this.featuresToRemove.includes(event.featureId)) {
                    this.featuresToRemove = this.featuresToRemove.filter(item => item !== event.featureId);
                } else {
                    this.featuresToAdd.push(event.featureId);
                }
                // Enforce parent enablement
                if (event.keys.length > 1) {
                    this.enforceParentEnable(event);
                }
            } else {
                if (this.featuresToAdd.includes(event.featureId)) {
                    this.featuresToAdd = this.featuresToAdd.filter(item => item !== event.featureId);
                } else {
                    this.featuresToRemove.push(event.featureId);
                }
                // Enforce child disablement
                if (event.keys.length < 3) {
                    this.enforceChildDisable(event);
                }
            }
        },
        updateFeaturesTree(event) {
            switch (event.keys.length) {
                case 1:
                    this.features[event.keys[0].featureId].enabled = event.value;
                    break;
                case 2:
                    this.features[event.keys[0].featureId].features[event.keys[1].featureId].enabled = event.value;
                    break;
                case 3:
                    this.features[event.keys[0].featureId].features[event.keys[1].featureId].features[
                        event.keys[2].featureId
                    ].enabled = event.value;
                    break;
                default:
                    break;
            }
        },
        enforceParentEnable(event) {
            const newKeys = event.keys.slice(0, -1);
            let parentValue = false;
            if (newKeys.length === 1) {
                parentValue = this.features[newKeys[0].featureId].enabled;
            }
            if (newKeys.length === 2) {
                parentValue = this.features[newKeys[0].featureId].features[newKeys[1].featureId].enabled;
            }
            // Call update for parent feature in tree if it is disabled
            if (!parentValue) {
                this.featuresTreeChange({
                    keys: newKeys,
                    value: true,
                    featureId: newKeys[newKeys.length - 1].featureId,
                });
            }
        },
        enforceChildDisable(event) {
            let features = {};
            if (event.keys.length === 1) {
                features = this.features[event.keys[0].featureId].features;
            }
            if (event.keys.length === 2) {
                features = this.features[event.keys[0].featureId].features[event.keys[1].featureId].features;
            }
            // Call update for every feature in tree if it is enabled
            for (const feature in features) {
                if (Object.prototype.hasOwnProperty.call(features, feature) && features[feature].enabled) {
                    this.featuresTreeChange({
                        keys: [
                            ...event.keys,
                            {
                                featureId: feature,
                            },
                        ],
                        value: false,
                        featureId: feature,
                    });
                }
            }
        },
        /*
                View Tree Change logic similar to what is happening with legacy FeaturesTree component.
                Will be replaced when generic Tree component is created.
                viewsTreeChange is triggered when the change event is emitted from the ViewsTree component.
                This triggers the updateViewsTree method which sets the enabled property for the view that was checked.
                This is needed because of issues with model mapping for checkboxes within Vue 2.
                This then triggers parent/child methods to ensure that the proper parent view
                checkbox is checked if a child node is checked or the appropriate child nodes are unchecked
                if the parent is disabled
            */
        viewsTreeChange(event) {
            this.updateViewsTree(event);
            if (event.value && event.keys.length > 1) {
                // Enforce parent enablement
                this.enforceViewsParentEnable(event);
            } else if (!event.value && event.keys.length < 3) {
                // Enforce child disablement
                this.enforceViewsChildDisable(event);
            }
        },
        updateViewsTree(event) {
            switch (event.keys.length) {
                case 1:
                    this.views[event.keys[0].viewId].enabled = event.value;
                    break;
                case 2:
                    this.views[event.keys[0].viewId].views[event.keys[1].viewId].enabled = event.value;
                    break;
                case 3:
                    this.views[event.keys[0].viewId].views[event.keys[1].viewId].views[event.keys[2].viewId].enabled =
                        event.value;
                    break;
                default:
                    break;
            }
        },
        enforceViewsParentEnable(event) {
            const newKeys = event.keys.slice(0, -1);
            let parentValue = false;
            if (newKeys.length === 1) {
                parentValue = this.views[newKeys[0].viewId].enabled;
            }
            if (newKeys.length === 2) {
                parentValue = this.views[newKeys[0].viewId].views[newKeys[1].viewId].enabled;
            }
            // Call update for parent feature in tree if it is disabled
            if (!parentValue) {
                this.viewsTreeChange({
                    keys: newKeys,
                    value: true,
                    viewId: newKeys[newKeys.length - 1].viewId,
                });
            }
        },
        enforceViewsChildDisable(event) {
            let views = {};
            if (event.keys.length === 1) {
                views = this.views[event.keys[0].viewId].views;
            }
            if (event.keys.length === 2) {
                views = this.views[event.keys[0].viewId].views[event.keys[1].viewId].views;
            }
            // Call update for every view in tree if it is enabled
            for (const view in views) {
                if (Object.prototype.hasOwnProperty.call(views, view) && views[view].enabled) {
                    this.viewsTreeChange({
                        keys: [
                            ...event.keys,
                            {
                                viewId: view,
                            },
                        ],
                        value: false,
                        viewId: view,
                    });
                }
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/_palette.scss';
@import '~@/assets/scss/_typographyV2';

.tenant-section {
    padding: 2rem 4rem;
    height: 100%;
    position: relative;
    overflow: scroll;
}
.use-tenant-name {
    padding: 0;
}
</style>
