import RouteNames from '@/router/routeNames';

export const EUT_TYPES = {
    SEGMENT: 'segment',
    CAMPAIGN: 'campaign',
};
export default {
    EUT_TYPES,
};

/** Source is services_root/libs/client/catalog/Enums.lua:11 ENTITY_TYPES */
export const EntityType = {
    SegmentConfig: 'segment_config',
    Campaign: 'campaign',
    StaticFilter: 'static_filter',
    DWHConfig: 'dwh_config',
    CustomEvent: 'custom_event',
    ApiInvoker: 'api_invoker',
    Ord: 'ord',
    KafkaSink: 'kafka_sink_config',
    RewardRuleV4: 'reward_rule_v4',
    Offer: 'offer',
};
export const EntityTypePermission = {
    // is used in isUserAllowed function for displaying navigate icons
    [EntityType.DWHConfig]: 'TableConfigurationsRead',
    [EntityType.SegmentConfig]: 'SegmentsRead',
    [EntityType.Campaign]: ['BackofficeCampaignsRead', 'MarketingCampaignsRead', 'ServiceCampaignsRead'],
    [EntityType.CustomEvent]: 'EventsRead',
    [EntityType.ApiInvoker]: 'APIDestinationsRead',
    [EntityType.Ord]: 'ReportConfigurationsRead',
    [EntityType.KafkaSink]: 'KafkaConfigurationsRead',
    [EntityType.RewardRuleV4]: 'RewardRulesRead',
    [EntityType.Offer]: 'OffersRead',
};

export const EntityTypeLabel = {
    [EntityType.SegmentConfig]: 'Segment config',
    [EntityType.Campaign]: 'Campaign',
    [EntityType.StaticFilter]: 'Static Filter',
    [EntityType.DWHConfig]: 'Table',
    [EntityType.CustomEvent]: 'Event',
    [EntityType.ApiInvoker]: 'API Invoker',
    [EntityType.Ord]: 'Operational Report',
    [EntityType.KafkaSink]: 'Kafka sink',
    [EntityType.RewardRuleV4]: 'Reward Rule',
    [EntityType.Offer]: 'Offer',
};

export const EntityTypeRouteName = {
    [EntityType.SegmentConfig]: RouteNames.SEGMENTS_LIST,
    [EntityType.Campaign]: RouteNames.CAMPAIGNS_VIEW,
    [EntityType.StaticFilter]: RouteNames.SEGMENTS_LIST,
    [EntityType.DWHConfig]: RouteNames.REDSHIFT_CONFIG_DASHBOARD,
    [EntityType.CustomEvent]: RouteNames.EVENTS_DASHBOARD,
    [EntityType.ApiInvoker]: RouteNames.API_INVOKER_CONFIG_DASHBOARD,
    [EntityType.Ord]: RouteNames.ORD_CONFIG_DASHBOARD,
    [EntityType.KafkaSink]: RouteNames.KAFKA_SINK_CONFIG_DASHBOARD,
    [EntityType.RewardRuleV4]: RouteNames.REWARDS_VIEW,
    [EntityType.Offer]: RouteNames.OFFERS_LIST_NEW,
};

export const EntityToService = {
    [EntityType.SegmentConfig]: 'segmentconfig',
    [EntityType.Campaign]: 'cep-site-backend',
    [EntityType.StaticFilter]: 'staticfilter',
    [EntityType.DWHConfig]: 'dwhconfig',
    [EntityType.CustomEvent]: 'customevent',
    [EntityType.ApiInvoker]: 'api-invoker',
    [EntityType.Ord]: 'operational-report-daemon',
    [EntityType.KafkaSink]: 'kafkasinkconfig',
    [EntityType.RewardRuleV4]: 'rewardv4',
};
export const EntityToServiceUrl = {
    [EntityType.SegmentConfig]: 'staticfilter',
    [EntityType.Campaign]: 'cep-site-backend',
    [EntityType.StaticFilter]: 'staticfilter',
    [EntityType.DWHConfig]: 'dwhconfig',
    [EntityType.CustomEvent]: 'customevent',
    [EntityType.ApiInvoker]: 'api-invoker',
    [EntityType.Ord]: 'operational-report-daemon',
    [EntityType.KafkaSink]: 'kafkasinkconfig',
    [EntityType.RewardRuleV4]: 'rewardv4',
};
