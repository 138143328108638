var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.isOrganizationIdUrlParamPresent)?_c('div',[_c('div',{staticClass:"container-fluid wrapper-container background-color h-100"},[_c('AppHeader',{staticClass:"mb-2",attrs:{"pageTitle":_vm.$i18n.t('generic.organization'),"isSearchEnabled":false},scopedSlots:_vm._u([(_vm.isNewCustomerCareEnabled)?{key:"headerButtons",fn:function(){return [_c('AppButton',{staticClass:"mx-2",attrs:{"buttonType":_vm.BUTTON_TYPES.PRIMARY,"iconType":_vm.ICON_TYPES.DATA,"label":_vm.$i18n.t('customerCareSuite.tryNewCustomerCare')},on:{"click":_vm.newCustomerCare}})]},proxy:true}:null],null,true)}),_c('Breadcrumbs',{staticClass:"ml-4",attrs:{"breadcrumbList":_vm.breadcrumbList}}),_c('AppCustomerHeader',{attrs:{"infoData":_vm.organizationData}}),_c('div',{staticClass:"section-layout"},[_c('TableFiltersRenderless',{attrs:{"entities":_vm.filteredAccounts},scopedSlots:_vm._u([{key:"default",fn:function({
                        // state
                        allFilters,
                        filteredEntities,
                        // methods
                        onFilterAdded,
                        removeFilter,
                        removeAllFilters,
                        filteredEntitiesMixin,
                    }){return [_c('div',[_c('div',{staticClass:"row mb-2 align-items-center table-header-row justify-content-between"},[_c('div',{staticClass:"component-title-um"},[_vm._v(" "+_vm._s(_vm.$i18n.t('customerCare.accounts'))+" ")]),_c('div',{staticClass:"display-flex"},[_c('SearchBox',{staticClass:"table-search-box",attrs:{"smallSearchIcon":true},model:{value:(_vm.accountsSearchQuery),callback:function ($$v) {_vm.accountsSearchQuery=$$v},expression:"accountsSearchQuery"}}),_c('div',{staticClass:"filters-control position-relative"},[_c('FilterTable',{attrs:{"columns":_vm.accountsTableColumnsData,"multiselectWidth":{ width: '12rem' }},on:{"filterAdded":onFilterAdded},scopedSlots:_vm._u([{key:"button",fn:function(slotProps){return [_c('IconButton',{attrs:{"label":_vm.$i18n.t('generic.filter'),"icon":_vm.ICON_TYPES.FILTER},on:{"iconClick":slotProps.clickHandler}})]}}],null,true)})],1)],1)]),_c('div',[_c('TableFiltersTags',{staticClass:"mt-1 mb-3",attrs:{"filterTableMixin":{ allFilters }},on:{"removeFilter":removeFilter,"removeAllFilters":removeAllFilters}}),_c('AppTable',{attrs:{"entities":filteredEntities,"innerSearchQuery":_vm.accountsSearchQuery,"isDefaultHoverEnabled":false,"isPaginationEnabled":true,"isSearchEnabled":true,"newDesign":true,"columnsData":_vm.accountsTableColumnsData,"canSelectColumns":true,"tableKey":"customer-care-new/organization/accounts"},scopedSlots:_vm._u([{key:"status",fn:function({ entity }){return [_c('EntityStatusIndicator',{attrs:{"status":entity.state}})]}},{key:"account_id",fn:function({ entity }){return [(entity.account_id)?_c('div',{staticClass:"link pointer",on:{"click":function($event){_vm.goToRoute(
                                                _vm.getBreadcrumbPath(
                                                    _vm.USER_MANAGER_HIERARCHY.ACCOUNT,
                                                    entity.account_id,
                                                ),
                                            )}}},[_vm._v(" "+_vm._s(entity.account_id)+" ")]):_c('i',[_vm._v(" "+_vm._s(_vm.$i18n.t('generic.empty'))+" ")])]}}],null,true)})],1)])]}}],null,false,1402316992)})],1),_c('div',{staticClass:"section-layout"},[_c('TableFiltersRenderless',{attrs:{"entities":_vm.filteredRoles},scopedSlots:_vm._u([{key:"default",fn:function({
                        // state
                        allFilters,
                        filteredEntities,
                        // methods
                        onFilterAdded,
                        removeFilter,
                        removeAllFilters,
                        filteredEntitiesMixin,
                    }){return [_c('div',[_c('div',{staticClass:"row mb-2 align-items-center table-header-row justify-content-between"},[_c('div',{staticClass:"component-title-um"},[_vm._v(" "+_vm._s(_vm.$i18n.t('rolesAndPermissions.rolesPage'))+" ")]),_c('div',{staticClass:"display-flex"},[_c('SearchBox',{staticClass:"table-search-box",attrs:{"smallSearchIcon":true},model:{value:(_vm.rolesSearchQuery),callback:function ($$v) {_vm.rolesSearchQuery=$$v},expression:"rolesSearchQuery"}}),_c('div',{staticClass:"filters-control position-relative"},[_c('FilterTable',{attrs:{"columns":_vm.rolesTableColumnsData,"multiselectWidth":{ width: '12rem' }},on:{"filterAdded":onFilterAdded},scopedSlots:_vm._u([{key:"button",fn:function(slotProps){return [_c('IconButton',{attrs:{"label":_vm.$i18n.t('generic.filter'),"icon":_vm.ICON_TYPES.FILTER},on:{"iconClick":slotProps.clickHandler}})]}}],null,true)})],1)],1)]),_c('div',[_c('TableFiltersTags',{staticClass:"mt-1 mb-3",attrs:{"filterTableMixin":{ allFilters }},on:{"removeFilter":removeFilter,"removeAllFilters":removeAllFilters}}),_c('AppTable',{attrs:{"entities":filteredEntities,"innerSearchQuery":_vm.rolesSearchQuery,"isSearchEnabled":true,"isDefaultHoverEnabled":false,"isPaginationEnabled":true,"newDesign":true,"columnsData":_vm.rolesTableColumnsData,"canSelectColumns":true,"tableKey":"customer-care-new/account/roles"},scopedSlots:_vm._u([{key:"user_id",fn:function({ entity }){return [(entity.user_id)?_c('div',{staticClass:"link pointer",on:{"click":function($event){_vm.goToRoute(
                                                _vm.getBreadcrumbPath(_vm.USER_MANAGER_HIERARCHY.USER, entity.user_id),
                                            )}}},[_vm._v(" "+_vm._s(entity.user_id)+" ")]):_c('i',[_vm._v(" "+_vm._s(_vm.$i18n.t('generic.empty'))+" ")])]}}],null,true)})],1)])]}}],null,false,1563950890)})],1),(_vm.isLFAdmin)?_c('div',{staticClass:"section-layout"},[_c('AssociatedRoles',{attrs:{"id":_vm.$route.params.id,"idType":_vm.USER_MANAGER_HIERARCHY.ORGANIZATION,"showEmail":true}})],1):_vm._e()],1)]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }