<template>
    <div>
        <h2 class="component-title-um mb-2">
            {{ $i18n.t('customerCare.promotions.sectionHeader') }}
        </h2>
        <MultipleTablesTabs
            v-model="promotionsActiveTabId"
            :isComplexTab="true"
            :tabs="promotionsTabs"
            class="mb-2"
        />
        <BenefitsTable
            v-show="promotionsActiveTabId === BENEFITS_TYPES.AVAILABLE"
            :benefitsType="BENEFITS_TYPES.AVAILABLE"
            :isEditAllowed="isEditAllowed"
            :transactionsData="transactionsData"
        />
        <BenefitsTable
            v-show="promotionsActiveTabId === BENEFITS_TYPES.HISTORICAL"
            :benefitsType="BENEFITS_TYPES.HISTORICAL"
            :transactionsData="transactionsData"
        />
    </div>
</template>

<script>
// components
import BenefitsTable from '@/__new__/features/customerCare/account/BenefitsTable.vue';
import MultipleTablesTabs from '@/components/partials/MultipleTablesTabs.vue';
// helpers
import { BENEFITS_TYPES } from '@/http/benefits';
import ENTITY_TYPES from '@/common/entities/entityTypes';
// Vuex
import store from '@/store/store';
import Actions from '@/store/mutation-types';
import { mapGetters } from 'vuex';
import permissionsService, { isViewEnabled } from '@/services/permissions/permissions.service';

export default {
    name: 'AccountPromotions',
    components: {
        BenefitsTable,
        MultipleTablesTabs,
    },
    props: {
        isEditAllowed: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            BENEFITS_TYPES,
            promotionsActiveTabId: BENEFITS_TYPES.AVAILABLE,
            isSubscriberEnabled: permissionsService.subscriberLevelEnabled() && isViewEnabled('UMSubscriber'),
        };
    },
    computed: {
        ...mapGetters('userManagementAccount', ['getTransactionsHistoriesById']),
        promotionsTabs() {
            return [
                {
                    id: BENEFITS_TYPES.AVAILABLE,
                    title: this.$i18n.t('customerCare.promotions.availableTabLabel'),
                },
                {
                    id: BENEFITS_TYPES.HISTORICAL,
                    title: this.$i18n.t('customerCare.promotions.historicalTabLabel'),
                },
            ];
        },
        transactionsData() {
            return this.$route.params.id ? this.getTransactionsHistoriesById(this.$route.params.id) : [];
        },
    },
    async created() {
        try {
            // data that are shared between 2 tables
            const promises = [
                store.dispatch(`productcatalog/${Actions.PC_REQUEST_ENTITIES_BY_TYPE_AND_IDS}`, {
                    entityType: ENTITY_TYPES.PRODUCT,
                }),
                store.dispatch(`productcatalog/${Actions.PC_REQUEST_ENTITIES_BY_TYPE_AND_IDS}`, {
                    entityType: ENTITY_TYPES.OFFER,
                }),
                store.dispatch(`promotions/${Actions.REQUEST_PROMOTIONS}`),
            ];

            if (this.isSubscriberEnabled && !this.transactionsData.length) {
                promises.push(
                    store.dispatch('userManagementAccount/getTransactionHistoryTableData', {
                        accountId: this.$route.params.id,
                        sortType: 'DESC',
                    }),
                );
            }

            await Promise.all(promises);
        } catch (error) {
            this.$eventBus.$emit('showAlert', {
                message: this.$t('alertMessage.errorDoingSmthTryAgain', {
                    action: this.$t('generic.fetching'),
                    entityName: `${this.$t('customerCare.promotions.sectionHeader')}`.toLowerCase(),
                }),
            });
        }
    },
};
</script>
