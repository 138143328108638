<template>
    <div v-click-outside="closeFilter">
        <slot
            name="button"
            :clickHandler="toggleFilterVisibility"
        >
            <IconButton
                :label="$i18n.t('customerCare.transactionHistory.walletType')"
                :icon="ICON_TYPES.WALLET"
                :allowClickPropagation="true"
                :manualTriggerActiveIcon="isFiltered"
                @iconClick="toggleFilterVisibility"
            />
        </slot>
        <div
            v-if="isFilterVisible"
            class="d-flex align-items-center filter-component pr-4 pl-1"
        >
            <AppMultiselectV3
                v-model="selectedTransactionWalletType"
                :options="transactionsWalletTypes"
                :placeholder="$i18n.t('generic.columnName')"
                :allowEmpty="false"
                :small="true"
                :newDesign="true"
                :widthStyleObject="{ width: '12rem' }"
                class="mr-2 multiselect-picker"
                label="label"
                trackBy="key"
            />
            <span
                v-t="'generic.add'"
                :class="{ disabled: false }"
                class="add-button ml-4"
                @click="onApplyClick"
            />
        </div>
    </div>
</template>
<script>
import i18n from '@/i18n';
import AppMultiselectV3 from '@/components/partials/inputs/AppMultiselectV3.vue';
import IconButton from '@/components/partials/IconButton.vue';
import { ICON_TYPES } from '@/common/iconHelper';
import vClickOutside from 'v-click-outside';

export const transactionsWalletTypes = [
    {
        label: i18n.t('rewards.wallet.all'),
        key: 'all',
    },
    {
        label: i18n.t('rewards.wallet.monetary'),
        key: 'Monetary wallet',
    },
    {
        label: i18n.t('rewards.wallet.bill'),
        key: 'Bill',
    },
];

export default {
    name: 'TransactionTypeFilter',
    components: {
        IconButton,
        AppMultiselectV3,
    },
    directives: {
        clickOutside: vClickOutside.directive,
    },
    props: {
        subscriptionType: {
            type: Number,
            required: true,
        },
        multiselectWidth: {
            type: Object,
            default: () => undefined,
        },
    },
    data() {
        return {
            isFilterVisible: false,
            isFiltered: false,
            ICON_TYPES,
            walletTypeMonitored: '',
            transactionsWalletTypes,
            selectedTransactionWalletType: null,
        };
    },
    methods: {
        toggleFilterVisibility() {
            this.isFilterVisible = !this.isFilterVisible;
        },
        closeFilter() {
            this.isFilterVisible = false;
        },
        onApplyClick() {
            this.isFiltered = !(
                !this.selectedTransactionWalletType ||
                this.selectedTransactionWalletType.key === transactionsWalletTypes[0].key
            );
            this.$emit('walletTypeChanged', {
                walletType: this.selectedTransactionWalletType.key,
            });
            this.closeFilter();
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/palette';
@import '~@/assets/scss/mixins';
@import '~@/assets/scss/z-indexes';

.filter-component {
    height: 2.5rem;
    width: fit-content;
    position: absolute;
    right: 2rem;
    top: 3.75rem;
    box-shadow: 0 2px 6px 0 rgba(51, 81, 149, 0.3);
    border-radius: 0.5rem;
    background-color: $white;
    z-index: $icon-button-z-index;
}

.add-button {
    color: $blue;
    cursor: pointer;
    font-weight: 700;
    font-size: 0.875rem;
    line-height: 1.625rem;

    &.disabled {
        cursor: default;
        color: $gray10;
    }
}
</style>
