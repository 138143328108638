// Store
import store, { Modules } from '@/store/store';
import { Getters } from '@/store/mutation-types';

// Models
import { SinkConfig, type SinkSpecificConfig } from '@/__new__/services/dno/sinkConfigs/models/SinkConfig';
import { replaceIdByEventTypeInTriggerCondition } from '@/__new__/services/dno/triggers/TriggerCondition';

// Helpers
import { get, isEmpty } from 'lodash';
import { SINK_CONFIG_STATUS } from '@/__new__/services/dno/sinkConfigs/models/SinkConfigStatus';
import { sinkConfigColFromJson } from '@/__new__/services/dno/sinkConfigs/models/SinkConfigCol';
import { type JobDefinitionItem } from '@/store/modules/sinkConfigs.store';
import { configCohortsDataFromJson } from '@/__new__/services/dno/sinkConfigs/models/CohortConfig';

export type ApiInvokerJobDefinition = {
    id: string;
    name: string;
    description: string;
    cluster_name: string;
    revision_number: string;
    sink_entity_properties: Record<string, any>;
    topic: string;
    request_params: {
        uri?: {
            value: string;
        };
    };
};

interface ApiInvokerSpecificConfig extends SinkSpecificConfig {
    id: string;
    cluster_name: string;
    description: string;
    delimiter: string;
    extension: string;
    file_name: string;
    header: string;
    secondary_file_destination: string;
    type: SinkSpecificConfig['type'] & 'CronExpressionInput';
    visible_on_portal: string;
}

export default class ApiInvokerSinkConfig extends SinkConfig {
    // Type definition difference from SinkConfig.
    declare specificConfig: ApiInvokerSpecificConfig;

    static mapFromApiConfigResponse({
        job_definition: { name, description, id, sink_entity_properties, topic, cluster_name, request_params },
        last_updated: updateTime,
        revision_number: version,
    }: JobDefinitionItem<ApiInvokerJobDefinition>) {
        const events = store.getters[`${Modules.events}/${Getters.GET_MAPPED_EVENTS}`];
        const formatters = store.getters[`${Modules.sinkConfigs}/${Getters.GET_SINK_FORMATTERS}`];
        const uiSettings = store.getters[`${Modules.sinkConfigs}/${Getters.GET_ORD_CONFIGS_SETTINGS}`];
        const filters = replaceIdByEventTypeInTriggerCondition(sink_entity_properties?.trigger_condition)?.filters;
        const segments = store.getters[`${Modules.segments}/${Getters.GROUPED_SEGMENTS_BY_ID_TYPE}`]
            .map((group: any) => group.groupValues)
            .flat();

        return new ApiInvokerSinkConfig({
            name,
            id,
            filters,
            // todo enrich from sink_ui_params or rework APII entity, hardcoded for now
            specificConfig: {
                name,
                description,
                id,
                topic,
                'request_params.uri.value': get(request_params, 'uri.value'),
                cluster_name,
                should_send_raw_event: sink_entity_properties?.should_send_raw_event,
            } as unknown as ApiInvokerSpecificConfig,
            version,
            state: SINK_CONFIG_STATUS.APPROVED, // state is not supported, always 2 Approved
            fieldsConfiguration: sinkConfigColFromJson(
                sink_entity_properties?.fields_configuration ?? [],
                undefined,
                events,
                formatters,
                uiSettings,
            ),
            updateTime,
            cohortsData: isEmpty(sink_entity_properties?.cohorts_configuration)
                ? undefined
                : configCohortsDataFromJson(sink_entity_properties?.cohorts_configuration, filters, segments),
        });
    }
}
