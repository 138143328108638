<template>
    <div>
        <div
            v-for="(group, index) in groups"
            :key="group.id"
            data-test-id="group-wrap"
        >
            <slot
                name="header"
                :group="group"
            />
            <Generator
                :fields="generateFields(group)"
                :showValidation="showValidation"
                :disabled="disabled"
                :enablePropertiesSelection="enablePropertiesSelection"
                :selectedPropertiesIds="selectedPropertiesIds"
                @input="val => emitValues(val, group)"
                @propertySelected="onPropertySelect"
            >
                <template
                    v-for="key in slotKeys"
                    #[key]
                >
                    <slot :name="key" />
                </template>
            </Generator>

            <slot
                v-if="index + 1 !== groups.length"
                name="dividerGroup"
            />
        </div>
    </div>
</template>
<script>
// COMPONENTS
import Generator from './Generator.vue';

export default {
    name: 'GroupGenerator',
    components: {
        Generator,
    },
    props: {
        // Props for using v-model and init values for generator
        value: {
            required: false,
            type: Object,
            default: () => ({}),
        },
        groups: {
            required: true,
            type: Array,
        },
        showValidation: {
            type: Boolean,
            default: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        enablePropertiesSelection: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            valuesFormatted: {},
            selectedPropertiesIds: [],
        };
    },
    computed: {
        slotKeys() {
            return Object.keys(this.$slots);
        },
    },
    watch: {
        groups() {
            this.cleanValues();
        },
    },
    methods: {
        cleanValues() {
            this.valuesFormatted = {};
        },
        emitValues(val, group) {
            this.valuesFormatted[group.id] = {
                ...group,
                groupValue: val,
            };

            this.$emit('input', { ...this.valuesFormatted });
        },
        generateFields(group) {
            group.fields.forEach(field => {
                const val = this.value?.[field.groupId]?.groupValue?.value?.[field.key];
                if (val && field.defaultValue !== val) {
                    field.defaultValue = val;
                }
            });
            return group.fields;
        },
        onPropertySelect(propId) {
            const propIdIndex = this.selectedPropertiesIds.findIndex(el => el === propId);

            if (propIdIndex === -1) {
                this.selectedPropertiesIds.push(propId);
            } else {
                this.selectedPropertiesIds.splice(propIdIndex, 1);
            }

            this.$emit('onPropertySelection', this.selectedPropertiesIds);
        },
    },
};
</script>

<style lang="scss" scoped></style>
