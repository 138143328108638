<template>
    <AppAditionalSidebar
        :visible="showSidebar"
        @input="hideSidebar"
    >
        <template #header>
            <div class="modal-row d-flex align-items-center">
                <div class="all-caps-text">
                    {{ $i18n.t('customerCareSuite.subscriptionDetails') }}
                </div>
            </div>
        </template>
        <template #content>
            <section v-if="selectedSubscription">
                <!-- Characteristics-->
                <AppOverviewBlock
                    v-if="subscriptionCharacteristics"
                    :isRowTitleCounterVisible="false"
                    :items="subscriptionCharacteristics"
                    data-test-id="characteristics"
                    class="my-3"
                />
                <AppTable
                    :entities="orders"
                    :isDefaultHoverEnabled="false"
                    :forbidHideColumn="true"
                    :disableBoxShadow="true"
                    :newDesign="true"
                    :canSelectColumns="false"
                    :columnsData="ordersColumnsData"
                    :isDataLoading="isDataLoading"
                    tableKey="customer-care-suite/subscription-sidebar"
                    keyName="executionId"
                    class="auto-height"
                >
                    <template #state="{ entity }">
                        <EntityStatusIndicator
                            :stateMap="ORDER_NEXTGEN_ENTITY_STATES_TO_STATUS_NAME_MAP"
                            :stateMapColor="ORDER_NEXTGEN_STATUS_INDICATOR_MAP"
                            :status="entity.state"
                            :isInTable="true"
                        />
                    </template>
                </AppTable>
                <AppCollapsibleList
                    v-if="suspensionReasonsFormatted"
                    :items="suspensionReasonsFormatted"
                    :isTitleCounterVisible="true"
                    :isRowTitleCounterVisible="true"
                />
                <AppCollapsibleList :items="offerDataFormatted" />
            </section>
        </template>
    </AppAditionalSidebar>
</template>

<script>
// COMPONENTS
import AppAditionalSidebar from '@/components/partials/AppAditionalSidebar.vue';
import AppTable from '@/components/partials/AppTable.vue';
import EntityStatusIndicator from '@/components/partials/EntityStatusIndicator.vue';
import AppCollapsibleList from '@/components/partials/AppCollapsibleList.vue';
import AppOverviewBlock from '@/components/partials/AppOverviewBlock.vue';

// HELPERS
import tableColumnType from '@/common/filterTable';
import RouteNames from '@/router/routeNames';
import permissionsService from '@/services/permissions/permissions.service';
import {
    ORDER_NEXTGEN_STATUS_INDICATOR_MAP,
    ORDER_NEXTGEN_ENTITY_STATES_TO_STATUS_NAME_MAP,
} from '@/__new__/features/customerCareSuite/common/orderNextGenStateHelper';

// HTTP
import coreOMHTTP from '@/__new__/services/dno/coreom/http/coreom';

export default {
    name: 'AdditionalSidebarForSubscriptions',
    components: {
        AppAditionalSidebar,
        AppOverviewBlock,
        EntityStatusIndicator,
        AppTable,
        AppCollapsibleList,
    },
    props: {
        selectedSubscription: {
            type: Object,
            default: null,
        },
        showAdditionalSidebar: {
            type: Boolean,
            default: false,
        },
        userManagerHierarchy: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            isSidebarVisible: false,
            isDataLoading: false,
            RouteNames,
            orderStates: {},

            // proxy
            ORDER_NEXTGEN_ENTITY_STATES_TO_STATUS_NAME_MAP,
            ORDER_NEXTGEN_STATUS_INDICATOR_MAP,
        };
    },
    computed: {
        showSidebar() {
            return this.isSidebarVisible;
        },
        orders() {
            return (this.selectedSubscription?.orderRefs || []).map(order => ({
                ...order,
                state: this.orderStates[order.orderId],
            }));
        },
        ordersColumnsData() {
            return [
                {
                    name: this.$i18n.t('customerCare.orderHistory.orderId'),
                    key: 'orderId',
                    field: 'orderId',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('generic.state'),
                    key: 'state',
                    classes: ['overflow-visible-all'],
                    field: 'state',
                    filterType: tableColumnType.TEXT_LIMITED_OPTIONS,
                    limitedOptions: Array.from(new Set(this.orders.map(entity => entity.state))),
                },
            ];
        },
        isOePlansVisible() {
            return this.oePlansEnabled && permissionsService.isUserLotusFlareAdmin();
        },
        suspensionReasonsFormatted() {
            return this.selectedSubscription?.suspensionReasons
                ? [
                      {
                          isCollapsed: false,
                          name: this.$i18n.t('customerCareSuite.subscriptionsTile.suspensionReasons'),
                          label: '',
                          rows: this.selectedSubscription.suspensionReasons.map(suspensionReason => ({
                              name: suspensionReason.reason,
                              value: suspensionReason.timeFormatted,
                          })),
                      },
                  ]
                : null;
        },
        subscriptionCharacteristics() {
            const characteristics = this.selectedSubscription?.characteristics || {};
            if (Object.keys(characteristics).length === 0) {
                return null;
            }
            return [
                {
                    name: this.$i18n.t('customerCareSuite.characteristics'),
                    rows: Object.entries(characteristics).map(([name, value]) => {
                        if (name === 'expiration_timestamp') {
                            const date = new Date(value * 1000);
                            return {
                                name,
                                value: date.toLocaleString(),
                            };
                        }
                        return { name, value };
                    }),
                },
            ];
        },
        offerDataFormatted() {
            return [
                {
                    isCollapsed: false,
                    name: this.$i18n.t('customerCareSuite.subscriptionsTile.offerData'),
                    label: '',
                    rows: [
                        {
                            name: this.$i18n.t('customerCareSuite.subscriptionsTile.offerId'),
                            value: this.selectedSubscription.offerId,
                        },
                        {
                            name: this.$i18n.t('customerCareSuite.subscriptionsTile.offerDescription'),
                            value: this.selectedSubscription.offerDescription,
                        },
                    ],
                },
            ];
        },
    },
    watch: {
        showAdditionalSidebar: {
            handler() {
                this.isSidebarVisible = this.showAdditionalSidebar;
            },
        },
        selectedSubscription: {
            handler() {
                this.fetchOrders();
            },
        },
    },
    methods: {
        hideSidebar(val) {
            this.isSidebarVisible = val;
            this.$emit('closeSidebar', this.isSidebarVisible);
        },
        async fetchOrders() {
            if ((this.selectedSubscription?.orderRefs?.length || 0) < 1) return;

            this.isDataLoading = true;
            const response = await coreOMHTTP.getOrdersByIds(
                this.$route.params.id,
                this.userManagerHierarchy,
                this.selectedSubscription.orderRefs.map(({ orderId }) => orderId),
            );

            this.orderStates = Object.fromEntries(
                Object.values(response?.data?.orders || {}).map(el => [el.id, el.state]),
            );
            this.isDataLoading = false;
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/palette';
@import '~@/assets/scss/base';

.auto-height {
    height: auto !important;
}

.order-wrap {
    margin-bottom: 1.75rem;
}

.modal-row {
    margin-bottom: 0.875rem;
}

.row-title {
    font-size: 0.75rem;
    font-weight: 600;
    width: 7.875rem;
}

.row-value {
    font-size: 0.875rem;
    width: 100%;
}
</style>
