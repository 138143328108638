import i18n from '@/i18n';
import localeLibrary from '@/common/locale/localeLibrary';

export const PROMOTION_TYPES = {
    PriceOverride: {
        key: 'PriceOverride',
        label: i18n.t('promotions.priceOverride'),
        id: 3,
    },
    FlatDiscount: {
        key: 'FlatDiscount',
        label: i18n.t('promotions.flatDiscount'),
        id: 2,
    },
    PercentageDiscount: {
        key: 'PercentageDiscount',
        label: i18n.t('promotions.percentageDiscount'),
        id: 1,
    },
};

export const GRANT_TYPES = {
    Voucher: {
        key: 'voucher',
        label: i18n.t('promotions.voucher'),
        id: 1,
    },
    Manual: {
        key: 'manual',
        label: i18n.t('promotions.manual'),
        id: 2,
    },
};

export const RECURRENCE_OPTIONS = {
    all: {
        key: 'all',
        label: i18n.t('promotions.allRecurrences').toUpperCase(),
    },
    specificRecurrences: {
        key: 'specificRecurrences',
        label: i18n.t('promotions.nextRecurrences').toUpperCase(),
    },
};

export const PROMOTION_ENUM_TO_KEY_MAP = {
    [PROMOTION_TYPES.PercentageDiscount.id]: PROMOTION_TYPES.PercentageDiscount.key,
    [PROMOTION_TYPES.FlatDiscount.id]: PROMOTION_TYPES.FlatDiscount.key,
    [PROMOTION_TYPES.PriceOverride.id]: PROMOTION_TYPES.PriceOverride.key,
};

export const PROMOTION_ENUM_TO_LABEL_MAP = {
    [PROMOTION_TYPES.PercentageDiscount.id]: PROMOTION_TYPES.PercentageDiscount.label,
    [PROMOTION_TYPES.FlatDiscount.id]: PROMOTION_TYPES.FlatDiscount.label,
    [PROMOTION_TYPES.PriceOverride.id]: PROMOTION_TYPES.PriceOverride.label,
};

export const PROMOTION_KEY_TO_ENUM_MAP = {
    [PROMOTION_TYPES.PercentageDiscount.key]: 1,
    [PROMOTION_TYPES.FlatDiscount.key]: 2,
    [PROMOTION_TYPES.PriceOverride.key]: 3,
};

export function amountByTypeFormatting(amount, promotionTypeEnum) {
    if (
        PROMOTION_ENUM_TO_KEY_MAP[promotionTypeEnum] === PROMOTION_TYPES.PriceOverride.key ||
        PROMOTION_ENUM_TO_KEY_MAP[promotionTypeEnum] === PROMOTION_TYPES.FlatDiscount.key
    ) {
        return localeLibrary.getFormattedAmount(amount);
    }
    if (PROMOTION_ENUM_TO_KEY_MAP[promotionTypeEnum] === PROMOTION_TYPES.PercentageDiscount.key) {
        return `${amount}%`;
    }
    return `${amount}`;
}

export const GRANT_TYPE_ENUM_TO_OBJECT = {
    [GRANT_TYPES.Voucher.id]: GRANT_TYPES.Voucher,
    [GRANT_TYPES.Manual.id]: GRANT_TYPES.Manual,
};

export const GRANT_TYPE_KEY_TO_ENUM_MAP = {
    [GRANT_TYPES.Voucher.key]: GRANT_TYPES.Voucher.id,
    [GRANT_TYPES.Manual.key]: GRANT_TYPES.Manual.id,
};
