import { LABEL_COLOR } from '@/common/labelsHelper';

export enum BULK_EDIT_STATES {
    COMPLETED = 'generic.completed',
    IN_PROGRESS = 'generic.inProgress',
    STARTED = 'generic.started',
    TIMED_OUT = 'generic.timedOut',
    REGISTERED = 'generic.registered',
    DISCARDED = 'generic.discarded',
    UNKNOWN = 'generic.unknown',
    ERROR = 'generic.error',
}

export const BULK_EDIT_INDICATOR_MAP = new Map([
    [BULK_EDIT_STATES.IN_PROGRESS, LABEL_COLOR.yellow],
    [BULK_EDIT_STATES.COMPLETED, LABEL_COLOR.green],
    [BULK_EDIT_STATES.ERROR, LABEL_COLOR.red],
]);
