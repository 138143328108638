<template>
    <div>
        <div class="section-content">
            {{ $i18n.t('charging.chargingSpecifications.editor.trafficType') }}
        </div>
        <TagsMultiselect
            v-model="trafficTypeData.data"
            :options="trafficTypeOptions"
            :placeholder="$i18n.t('charging.chargingSpecifications.editor.chooseTrafficType')"
            :labelFormatter="c => c.label"
            :error="trafficTypeData.dataEmpty"
            :errorMessage="$i18n.t('generic.validations.fieldIsRequired')"
            label="label"
            data-test-id="traffic-type-condition-multiselect"
            @input="onDataInput"
        />
    </div>
</template>

<script>
import TagsMultiselect from '@/components/partials/inputs/TagsMultiselect.vue';
import TrafficTypeCondition from '@/__new__/services/dno/charging/models/chargingSpecificationConditions/TrafficTypeCondition';
import { trafficTypeConditionOptions } from '@/__new__/services/dno/charging/common/chargingSpecificationHelper';

export default {
    name: 'TrafficType',
    components: {
        TagsMultiselect,
    },
    props: {
        value: {
            type: Object,
            default: () => undefined,
        },
    },
    data() {
        return {
            // this data is returned from the backend and we cannot rely on i18n for it atm
            // TODO: refactor later to map source data labels to appropriate i18n labels in the UI
            trafficTypeOptions: trafficTypeConditionOptions,
            trafficTypeData: new TrafficTypeCondition(this.value),
        };
    },
    watch: {
        value: {
            handler() {
                this.trafficTypeData = new TrafficTypeCondition(this.value);
            },
        },
    },
    methods: {
        onDataInput() {
            this.trafficTypeData.validate();
            this.$emit('input', this.trafficTypeData);
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/_palette.scss';

.section-content {
    font-size: 0.75rem;
    font-weight: 600;
    margin-bottom: 1rem;
    margin-right: 1rem;
    color: $gray90;
}
</style>
