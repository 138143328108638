import { FeeRulesResponse } from './http/feeRules';
import {
    addFeeRule as addDnoFeeRule,
    getFeeRules as getDnoFeeRules,
    updateFeeRule as updateDnoFeeRule,
    updateFeeRuleState,
} from '@/__new__/services/dno/pricing/http/feeRules';
import { FeeRule as FeeRulePortal, FeeRuleEntity } from '@/__new__/services/dno/pricing/feeRulesPortal';
import {
    ACTION_CONFIGURATION_TYPE,
    ActionPredefinedFee,
    AMOUNT_CALCULATION_METHOD,
    COMPARISON_DATA_TYPE,
    COMPARISON_OP,
    COMPARISON_TYPE,
    FeeRule as FeeRuleDno,
    PredefinedFeeActionCondition,
    PRORATION_METHOD,
    PRORATION_UNIT,
    Scope,
    SCOPE_LEVEL,
} from '@/__new__/services/dno/pricing/models/pricingDno';
import { AxiosResponse } from 'axios';
import { EntityState } from '@/http';

export type FeeRulesData = {
    response: AxiosResponse<FeeRulesResponse>;
    feeRules: FeeRuleEntity[];
};

export const getFeeRules = async (ids?: string[]): Promise<FeeRulesData> => {
    // Get DNO Fee Rules
    const response = await getDnoFeeRules(ids);

    // Build Portal consumable entities
    const dnoFeeRules = Object.values(response.data.fee_rule_by_id);
    const feeRules = dnoFeeRules.map(dnoFeeRule => {
        const portalFeeRule = new FeeRulePortal(dnoFeeRule);
        return {
            id: portalFeeRule.id,
            name: portalFeeRule.name,
            description: portalFeeRule.description,
            type: portalFeeRule.type,
            scope: portalFeeRule.scope,
            offers: portalFeeRule.offers,
            prorationMethod: portalFeeRule.prorationMethod,
            prorationUnit: portalFeeRule.prorationUnit,
            amount: portalFeeRule.amount,
            amountCalculationMethod: portalFeeRule.amountCalculationMethod,
            version: portalFeeRule.version,
            state: portalFeeRule.state,
        };
    });

    return {
        response,
        feeRules,
    };
};

/**
 * FeeRule descriptor (helper type) used for FeeRule creation and updates
 */
export type FeeRuleDescriptor = {
    name: string;
    description: string;
    type: ACTION_CONFIGURATION_TYPE;
    scope: SCOPE_LEVEL;
    offers: string[];
    prorationMethod: PRORATION_METHOD;
    prorationUnit?: PRORATION_UNIT;
    amountCalculationMethod: AMOUNT_CALCULATION_METHOD;
    amount: string;
};

export const addFeeRule = async (feeRule: FeeRuleDescriptor, language: string) => {
    const data = buildDnoFeeRule(feeRule, language);
    await addDnoFeeRule(data);
};

export const updateFeeRule = async (id: string, version: number, feeRule: FeeRuleDescriptor, language: string) => {
    const data = buildDnoFeeRule(feeRule, language);
    await updateDnoFeeRule(id, version, data);
};

export const deleteFeeRule = async (feeRule: FeeRuleEntity) => {
    await updateFeeRuleState(feeRule.id, feeRule.version, EntityState.DELETED);
};

/**
 * Converts a `FeeRuleDescriptor` and `language` into a DNO accepted FeeRule.
 */
const buildDnoFeeRule = (feeRule: FeeRuleDescriptor, language: string): FeeRuleDno => {
    // Determine Name & Description
    const name = { [language]: feeRule.name };
    const description = { [language]: feeRule.description };

    // Determine scope
    let scope: Scope;
    if (feeRule.scope === SCOPE_LEVEL.LINE_ITEM_LEVEL) {
        scope = {
            level: SCOPE_LEVEL.LINE_ITEM_LEVEL,
            applicable_line_item_offer_ids: feeRule.offers,
        };
    } else if (feeRule.scope === SCOPE_LEVEL.TOTAL_LEVEL) {
        scope = {
            level: SCOPE_LEVEL.TOTAL_LEVEL,
        };
    } else {
        throw new Error('Could not determine Scope');
    }

    // Determine Component Description
    let componentDescription = '';
    switch (feeRule.type) {
        case ACTION_CONFIGURATION_TYPE.EARLY_TERMINATION:
            componentDescription = 'Early Termination Fee';
            break;
        case ACTION_CONFIGURATION_TYPE.LATE_PAYMENT:
            componentDescription = 'Late Payment Fee';
            break;
        default:
            throw new Error('Unhandled Fee Type');
    }

    // Determine actions
    const action: ActionPredefinedFee = {
        action_configuration_type: feeRule.type,
        component_description: componentDescription,
        predefined_rule_params: {
            proration_method: feeRule.prorationMethod,
            amount_calculation_method: feeRule.amountCalculationMethod,
            amount: feeRule.amount,
        },
    };

    // (Conditionally) Add proration unit info to action
    if (feeRule.prorationMethod === PRORATION_METHOD.DURATION) {
        // Proration unit must be defined if proration method is duration!
        if (feeRule.prorationUnit === null) {
            throw new Error('Proration unit cannot be null when the proration method is periodic');
        } else {
            action.predefined_rule_params.proration_unit = feeRule.prorationUnit;
        }
    }

    // Determine conditions
    let condition: PredefinedFeeActionCondition | undefined;
    if (feeRule.scope === SCOPE_LEVEL.LINE_ITEM_LEVEL) {
        condition = {
            type: COMPARISON_TYPE.COMPARISON,
            op: COMPARISON_OP.IS_SUBSET_OF,
            args: {
                data_type: COMPARISON_DATA_TYPE.OFFER,
                key: 'item_context.offer_id',
                value: feeRule.offers,
            },
        };
    }

    // Build FeeRule Data
    const data: FeeRuleDno = {
        name,
        description,
        scope,
        actions: [action],
    };
    if (condition) {
        data.conditions = condition;
    }

    return data;
};
