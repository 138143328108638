import http from '@/http/index';

const readBaseUrl = '/v3productcatalog/';
const writeBaseUrl = '/v3productcatalogwrite/';

/**
 * Get facets.
 *
 * @returns {AxiosPromise}
 */
export function getFacets() {
    const data = {};
    return http({
        method: 'POST',
        url: `${readBaseUrl}getfacets`,
        data,
    });
}

/**
 * Get all facets.
 *
 * @returns {AxiosPromise}
 */
export function getAllFacets() {
    const data = {};

    return http({
        method: 'POST',
        url: `${readBaseUrl}getallfacets`,
        headers: {
            'Content-Type': 'application/json',
        },
        data,
    });
}

/**
 * Add facet.
 *
 * @returns {AxiosPromise}
 */
export function addFacet(facetData) {
    return http({
        method: 'POST',
        url: `${writeBaseUrl}addfacet`,
        data: {
            ...facetData,
        },
    });
}

/**
 * Update facet.
 *
 * @returns {AxiosPromise}
 */
export function updateFacet(id, facetData, version) {
    return http({
        method: 'POST',
        url: `${writeBaseUrl}updatefacet`,
        data: {
            id,
            data: facetData,
            version,
        },
    });
}

export default {
    addFacet,
    getFacets,
    getAllFacets,
    updateFacet,
};
