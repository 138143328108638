<template>
    <div>
        <div
            v-for="(condition, index) in timeInCallData.data"
            :key="`time-in-call-condition-${index}`"
            class="time-slot d-flex mb-3"
        >
            <div class="d-flex align-items-center">
                <span class="time-frame-label">{{ $t('generic.from') }}</span>
                <AppInputV3
                    :id="`time-in-call-start-${index}`"
                    v-model="condition.start_offset"
                    placeholder="00:00:00"
                    :invalid="isTimeSlotStringInvalid(index).s"
                    :errorMessage="$i18n.t('generic.validations.fieldIsRequired')"
                    class="time-slot-value"
                    data-test-id="time-in-call-condition-start-offset"
                    @input="updateTime()"
                />
            </div>
            <div class="ml-2 mr-4 d-flex align-items-center">
                <span class="time-frame-label">{{ $t('generic.to') }}</span>
                <AppInputV3
                    :id="`time-in-call-end-${index}`"
                    v-model="condition.end_offset"
                    placeholder="23:59:59"
                    :invalid="isTimeSlotStringInvalid(index).e"
                    :errorMessage="$i18n.t('generic.validations.fieldIsRequired')"
                    class="time-slot-value"
                    data-test-id="time-in-call-condition-end-offset"
                    @input="updateTime()"
                />
            </div>
        </div>
    </div>
</template>

<script>
// Vue components
import AppInputV3 from '@/components/partials/inputs/AppInputV3.vue';
import TimeInCallCondition from '@/__new__/services/dno/charging/models/chargingSpecificationConditions/TimeInCallCondition';
import isEmpty from 'lodash/isEmpty';

export default {
    name: 'TimeInCall',
    components: {
        AppInputV3,
    },
    props: {
        value: {
            type: Object,
            default: () => undefined,
        },
    },
    data() {
        return {
            timeInCallData: new TimeInCallCondition(this.value),
        };
    },
    watch: {
        value: {
            handler() {
                this.timeInCallData = new TimeInCallCondition(this.value);
            },
        },
    },
    created() {
        if (isEmpty(this.timeInCallData.data)) {
            this.timeInCallData.data.push({
                start_offset: '',
                end_offset: '',
            });
            this.timeInCallData.isDataValid();
        }
    },
    methods: {
        isTimeValid(time) {
            if (!time || time === '') {
                return true;
            }
            if (!time.includes(':')) {
                return true;
            }
            const hoursMinutesAndSeconds = time.split(':');
            const hasColonSymbol = hoursMinutesAndSeconds.length === 3;
            if (!hasColonSymbol) {
                return true;
            }
            const hours = Number(hoursMinutesAndSeconds[0]);
            if (hours < 0 || hours > 23) {
                return true;
            }
            const minutes = Number(hoursMinutesAndSeconds[1]);
            if (minutes < 0 || minutes > 59) {
                return true;
            }
            const seconds = Number(hoursMinutesAndSeconds[2]);
            if (seconds < 0 || seconds > 59) {
                return true;
            }
            return false;
        },
        isTimeSlotStringInvalid(index) {
            return {
                s: this.isTimeValid(this.timeInCallData.data[index].start_offset),
                e: this.isTimeValid(this.timeInCallData.data[index].end_offset),
            };
        },
        updateTime() {
            this.timeInCallData.validate();
            this.$emit('input', this.timeInCallData);
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/_palette.scss';
.invalid-input {
    position: relative;
    top: 0.8125rem;
    margin-top: 0.625rem;
    margin-bottom: 0.625rem;
}
.time-frame-label {
    font-weight: bold;
    font-size: 0.75rem;
    margin-right: 0.5rem;
    text-transform: uppercase;
    color: $steel;
}
</style>
