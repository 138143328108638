
import Vue, { type PropType } from 'vue';

// components
import AppCountCard from '@/components/partials/cards/AppCountCard.vue';

export type BulkUploadCounter = {
    label: string;
    value: string;
    icon?: {
        type: string;
        color?: string;
    };
};

export default Vue.extend({
    name: 'BulkUploadCounts',
    components: {
        AppCountCard,
    },
    props: {
        counts: {
            type: Array as PropType<BulkUploadCounter[]>,
            required: true,
        },
    },
});
