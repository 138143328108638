
import Vue from 'vue';
import { getBillingAccount, getBills } from '@/__new__/features/charterDemo/charterHttp';
import AppInfoBlock from '@/components/partials/AppInfoBlock.vue';

export default Vue.extend({
    name: 'CharterDemoBillingAccountGeneralTile',
    components: {
        AppInfoBlock,
    },
    data() {
        return {
            apiResponse: {} as object,
            billingAccount: {} as object,
            bills: [] as Array<any>,
        };
    },
    computed: {
        id() {
            return this.$route.params.id || '';
        },
        payableBalanceAmount(): string {
            const lastAccountBalance = this.billingAccount?.accountBalance?.[0];
            if (!lastAccountBalance) {
                return '-';
            }
            return this.formatAmount(lastAccountBalance.amount);
        },
        outstandingBalanceAmount(): string {
            const remainingAmount = this.bills?.[0]?.remainingAmount;
            if (!remainingAmount) {
                return '-';
            }
            return this.formatAmount(remainingAmount);
        },
        fields() {
            return [
                {
                    name: this.$t('charterDemo.financialAccount'),
                    value: '-',
                },
                {
                    name: this.$t('charterDemo.ratingType'),
                    value: '-',
                },
                {
                    name: this.$t('charterDemo.creditLimit'),
                    value: '-',
                },
                {
                    name: this.$t('charterDemo.payableBalanceAmount'),
                    value: this.payableBalanceAmount,
                },
                {
                    name: this.$t('charterDemo.outstandingBalance'),
                    value: this.outstandingBalanceAmount,
                },
            ];
        },
    },
    watch: {},
    async created() {
        await this.$withProgressBar(
            async () => {
                this.$emit('isDataLoadingUpd', true);
                let res = await getBillingAccount(this.id);
                this.billingAccount = { ...res.data[0] };
                res = await getBills(this.billingAccount.id, 1);
                this.bills = res.data;
                this.$emit('isDataLoadingUpd', false);
            },
            {
                errorHandler: error => {
                    this.apiResponse = error;
                    this.$alert(this.$i18n.t('alertMessage.somethingWentWrongFetchingNecessaryData'));
                    this.$emit('isDataLoadingUpd', false);
                },
            },
        );
    },
    methods: {
        formatAmount(amount: any): string {
            return `${Number(amount.value).toFixed(2)} ${amount.unit}`;
        },
    },
});
