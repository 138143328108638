<template>
    <ListPageLayout
        :pageTitle="pageTitle"
        :addButtonLabel="addButtonLabel"
        :editRouteName="editRouteName"
        :entityType="entityType"
        :entities="entities"
        :entitiesApiResponse="presetTemplateApiResponse"
        :entityKeyDepth="2"
        :listViewColumns="listViewColumns"
        :overviewContentBuilder="overviewContentBuilder"
        :searchKeys="['id', 'name', 'description']"
        writePermission="PresetTemplatesWrite"
        @delete="onDelete"
        @refetchData="fetchTemplates()"
    >
        <template #statusIndicator="{ entity }">
            <PCTemplatesStatusIndicator :status="entity.state" />
        </template>
    </ListPageLayout>
</template>

<script>
import Actions, { Getters } from '@/store/mutation-types';
import { mapActions, mapGetters } from 'vuex';

// components
import ListPageLayout from '@/__new__/features/pc/ListPageLayout.vue';
import ListPageMixin from '@/__new__/features/pc/ListPageMixin.vue';
import { ALERT_TYPES } from '@/common/alerts/Alert';
import FilterTableMixin from '@/components/partials/FilterTableMixin.vue';
import PCTemplatesStatusIndicator from './PCTemplatesStatusIndicator.vue';

// helpers
import ENTITY_TYPES from '@/common/entities/entityTypes';
import RouteNames from '@/router/routeNames';
import { overviewContentBuilder } from './common/templateHelper';

// import tableColumnType from '@/common/filterTable';
import { ENTITIES_TYPES_MAP } from '@/__new__/features/pc/common/entitiesTypes';
import { TEMPLATE_STATES_NUMERIC } from '@/__new__/services/dno/pc/models/templateStates';
import { PLURALIZATION } from '@/common/locale/labelSingularOrPlural';
import * as Sentry from '@sentry/vue';

// http
import { updateTemplateState } from '@/__new__/services/dno/pc/http/templates';

export default {
    name: 'PresetTemplatesListPage',
    components: {
        ListPageLayout,
        PCTemplatesStatusIndicator,
    },
    mixins: [FilterTableMixin, ListPageMixin],
    data() {
        return {
            entityType: ENTITY_TYPES.TEMPLATE,
            pageTitle: this.$i18n.tc('productCatalog.entities.presetTemplate', PLURALIZATION.PLURAL),
            addButtonLabel: this.$i18n.t('productCatalog.presetTemplates.newPresetTemplate'),
            editRouteName: RouteNames.PRESET_TEMPLATES_EDITOR,
            overviewContentBuilder,
        };
    },
    computed: {
        ...mapGetters('productcatalog', {
            allTemplates: Getters.PC_GET_ALL_TEMPLATES_NOT_DELETED,
            getTemplatesApiResponseByType: Getters.PC_GET_TEMPLATES_API_RESPONSE_BY_TYPE,
        }),
        entities() {
            return Object.values(this.allTemplates).map(template => ({
                ...template,
                entityTypeName: this.$i18n.tc(ENTITIES_TYPES_MAP[template.entityType]),
            }));
        },
        presetTemplateApiResponse() {
            return this.getTemplatesApiResponseByType('preset');
        },
        listViewColumns() {
            // TODO: clarify everything from swagger
            return [
                // {
                //     name: this.$i18n.t('productCatalog.presetTemplates.presetTemplateUse'),
                //     key: 'goal',
                //     field: 'goal',
                //     filterType: tableColumnType.GENERAL_TEXT,
                // },
                // {
                //     name: this.$i18n.t('benefits.creationDate'),
                //     key: 'creationDate',
                //     field: 'creationDate',
                //     filterType: tableColumnType.DATE,
                // },
                // {
                //     name: this.$i18n.t('productCatalog.presetTemplates.numberOfTemplates'),
                //     key: 'numberOfTemplates',
                //     field: 'numberOfTemplates',
                //     filterType: tableColumnType.NUMBER,
                // },
                // {
                //     name: this.$i18n.t('productCatalog.presetTemplates.numberOfFields'),
                //     key: 'numberOfFields',
                //     field: 'numberOfFields',
                //     filterType: tableColumnType.NUMBER,
                // },
            ];
        },
    },
    mounted() {
        this.fetchTemplates();
    },
    methods: {
        ...mapActions('productcatalog', {
            requestTemplatesByType: Actions.PC_REQUEST_TEMPLATES_BY_TYPE_AND_IDS,
        }),
        onDelete(template) {
            this.archiveTemplate(template);
        },
        async archiveTemplate(template) {
            try {
                this.$Progress.start();
                await updateTemplateState(
                    template.id,
                    template.entityType,
                    TEMPLATE_STATES_NUMERIC.ARCHIVED,
                    template.updateTime,
                );
                this.$Progress.finish();

                this.$eventBus.$emit('showAlert', {
                    type: ALERT_TYPES.success,
                    message: this.$i18n.t('alertMessage.successMessageWithoutRedirect', {
                        action: this.$i18n.t('generic.deleted'),
                        entityName: template.name,
                    }),
                });

                // refetching templates to have latest data
                this.fetchTemplates();
            } catch (e) {
                this.handleEntityDeletionError(e, template);
            }
        },
        async fetchTemplates() {
            try {
                this.$Progress.start();
                await this.requestTemplatesByType({ entityType: 'preset' });
                this.$Progress.finish();
            } catch (error) {
                this.$Progress.fail();
                Sentry.captureException(error);
                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('alertMessage.failedToLoadNecessaryData'),
                });
            }
        },
    },
};
</script>
