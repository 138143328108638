import {
    StringOperations,
    NumberOperations,
    NoArgOperations,
    ObjectOperations,
    ArrayOperations,
    JsonPathOperations,
} from '@/common/filters/InputOperations';
import UIComponentDefinition from '@/models/filters/uiComponents/UIComponentDefinition';
import { SelectableParam } from '@/models/filters/uiComponents/SelectableDefinition';

/**
 * Parent class for input UI params
 */
export class Input extends UIComponentDefinition {
    predefinedValues;

    constructor(json) {
        super();
        this.name = json && json.name;
        if (json && json.predefinedValues) {
            this.predefinedValues = json.predefinedValues.map(predefValJson => Object.values(predefValJson)[0]);
        }
    }
}

export class AbstractNumberInput extends Input {
    defaultValue() {
        const predefinedValue1 = this.predefinedValues && this.predefinedValues[0];
        const predefinedValue2 = (this.predefinedValues && this.predefinedValues[1]) || predefinedValue1;

        return {
            value: [predefinedValue1 || 1, predefinedValue2 || 7],
            op: NumberOperations.LessThan,
        };
    }

    buildJson(rawValue) {
        return {
            value: [
                rawValue.value[0],
                // set second number null if operation is not Between
                rawValue.op === NumberOperations.Between ? rawValue.value[1] : null,
            ],
            op: {
                [rawValue.op]: {},
            },
        };
    }

    valueFromJson(jsonValue) {
        /* eslint-disable-next-line no-param-reassign */
        jsonValue = jsonValue && jsonValue.value;
        return (
            jsonValue && {
                ...jsonValue,
                op: Object.keys(jsonValue.op)[0],
            }
        );
    }
}

export class DoubleInput extends AbstractNumberInput {}
export class QuantityInput extends AbstractNumberInput {}

export class ObjectInput extends Input {
    defaultValue() {
        return {
            op: ObjectOperations.IsEmptyObject,
        };
    }

    buildJson(rawValue) {
        return {
            value: ObjectOperations[rawValue.op] ? '' : rawValue.value,
            op: {
                [rawValue.op]: {},
            },
        };
    }

    valueFromJson(jsonValue) {
        /* eslint-disable-next-line no-param-reassign */
        jsonValue = jsonValue && jsonValue.value;
        return (
            jsonValue && {
                ...jsonValue,
                op: Object.keys(jsonValue.op)[0],
            }
        );
    }
}

export class ArrayInput extends Input {
    defaultValue() {
        return {
            op: ArrayOperations.IsEmptyArray,
        };
    }

    buildJson(rawValue) {
        return {
            value: ArrayOperations[rawValue.op] ? '' : rawValue.value,
            op: {
                [rawValue.op]: {},
            },
        };
    }

    valueFromJson(jsonValue) {
        /* eslint-disable-next-line no-param-reassign */
        jsonValue = jsonValue && jsonValue.value;
        return (
            jsonValue && {
                ...jsonValue,
                op: Object.keys(jsonValue.op)[0],
            }
        );
    }
}

export class JsonPathInput extends Input {
    defaultValue() {
        return {
            op: JsonPathOperations.Absent,
        };
    }

    buildJson(rawValue) {
        return {
            value: JsonPathOperations[rawValue.op] ? '' : rawValue.value,
            op: {
                [rawValue.op]: {},
            },
        };
    }

    valueFromJson(jsonValue) {
        /* eslint-disable-next-line no-param-reassign */
        jsonValue = jsonValue && jsonValue.value;
        return (
            jsonValue && {
                ...jsonValue,
                op: Object.keys(jsonValue.op)[0],
            }
        );
    }
}

export class StringInput extends Input {
    constructor(json) {
        super();
        if (json && json.predefinedValues) {
            this.predefinedValues = json.predefinedValues.map(predefValJson => {
                const actualValue = Object.values(predefValJson)[0];
                return new SelectableParam(actualValue);
            });
        }
    }

    defaultValue() {
        return {
            value: this.predefinedValues && this.predefinedValues[0] && this.predefinedValues[0].value,
            op: StringOperations.Equals,
        };
    }

    valueFromJson(jsonValue) {
        /* eslint-disable-next-line no-param-reassign */
        jsonValue = jsonValue && jsonValue.value;
        return (
            jsonValue && {
                ...jsonValue,
                op: Object.keys(jsonValue.op)[0],
            }
        );
    }

    buildJson(rawValue) {
        return {
            value: NoArgOperations[rawValue.op] ? '' : rawValue.value,
            op: {
                [rawValue.op]: {},
            },
        };
    }
}
