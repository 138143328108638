<template>
    <div>
        <div
            v-t="'charging.chargingSpecifications.editor.serviceType'"
            class="table-title"
        />
        <div class="primary-text">
            <span>
                {{ getValue(value.service_type) }}
            </span>
        </div>
    </div>
</template>

<script>
import { serviceTypeOptions } from '@/__new__/services/dno/charging/common/chargingSpecificationHelper';

export default {
    name: 'ServiceTypeValue',
    props: {
        value: {
            type: Object,
            required: true,
        },
    },
    methods: {
        getValue(val) {
            const data = Object.values(serviceTypeOptions).filter(el => el.key === val);
            return data.length > 0 ? this.$i18n.t(data[0].i18nLabel) : '';
        },
    },
};
</script>
