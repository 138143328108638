import { LABEL_COLOR } from '@/common/labelsHelper';
import i18n from '@/i18n';

export const oeNoteTag = 'Orchestration execution comment';

export enum tabSelection {
    GENERAL = 0,
    EXECUTION_INPUT = 1,
    CURRENT_EXECUTION_INPUT = 2,
    EXECUTION_OUTPUT = 3,
    DEFINITION = 4,
}

export enum EXECUTION_TYPES {
    CATCH_TRIGGERED = 'CatchTriggered',
    RETRY_TRIGGERED = 'RetryTriggered',
    EXECUTION_PAUSED = 'ExecutionPaused',
    RETRY_MAX_ATTEMPT = 'RetryMaxAttempt',
}

export enum STATE_HISTORY_KEY_OPTIONS {
    REQUEST = 'request',
    RESULT = 'result',
    INPUT = 'input',
    CALLBACK = 'callback',
    ERROR = 'error',
    OUTPUT = 'output',
    CONTEXT = 'context',
}
export enum IS_EXECUTED_STATES {
    YES = 'Yes',
    NO = 'No',
}

export const IS_EXECUTED_STATE_INDICATOR_MAP = new Map([
    [IS_EXECUTED_STATES.YES, LABEL_COLOR.green],
    [IS_EXECUTED_STATES.NO, LABEL_COLOR.yellow],
]);

export const IS_EXECUTED_STATES_TO_STATUS_NAME_MAP = new Map([
    [IS_EXECUTED_STATES.YES, i18n.t('generic.yes')],
    [IS_EXECUTED_STATES.NO, i18n.t('generic.no')],
]);

export enum VERSION_OPTIONS {
    VERSION_IT_STARTED = 1,
    NEWEST_VERSION = 2,
}

export enum EXECUTION_HISTORY_TYPES {
    EXECUTION_STARTED = 'ExecutionStarted',
    EXECUTION_FINISHED = 'ExecutionFinished',
    EXECUTION_SCHEDULED = 'ExecutionScheduled',
    EXECUTION_PAUSED = 'ExecutionPaused',
}

export enum NOTES_ID_TYPE {
    OE_EXECUTION = 200,
}

export enum RETRY_TYPE_ENUM {
    EXACT_NUMBER = 1,
    RANGE = 2,
    ALL_OPTIONS = 3,
}

export const RETRY_TYPE_OPTIONS: {
    id: RETRY_TYPE_ENUM;
    label: string;
}[] = [
    {
        id: RETRY_TYPE_ENUM.EXACT_NUMBER,
        label: i18n.t('orchestrationEngine.retryTypeOptions.exactNumber') as string,
    },
    {
        id: RETRY_TYPE_ENUM.RANGE,
        label: i18n.t('orchestrationEngine.retryTypeOptions.range') as string,
    },
    {
        id: RETRY_TYPE_ENUM.ALL_OPTIONS,
        label: i18n.t('orchestrationEngine.retryTypeOptions.allRetries') as string,
    },
];

export default {
    tabSelection,
    EXECUTION_TYPES,
    STATE_HISTORY_KEY_OPTIONS,
    IS_EXECUTED_STATES,
    IS_EXECUTED_STATE_INDICATOR_MAP,
    IS_EXECUTED_STATES_TO_STATUS_NAME_MAP,
    VERSION_OPTIONS,
    EXECUTION_HISTORY_TYPES,
    RETRY_TYPE_ENUM,
    RETRY_TYPE_OPTIONS,
};
