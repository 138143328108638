<template>
    <div class="new-note-wrapper position-relative h-100">
        <div class="container-fluid wrapper-container pl-0">
            <AppHeader
                :pageTitle="$i18n.t('customerCare.notesSection.newNote')"
                :isSearchEnabled="false"
                class="mb-2"
            />
        </div>
        <div class="new-note mb-auto h-100">
            <section>
                <div class="content">
                    <AppMultiselectV3
                        v-model="selectedTags"
                        :additionalLabel="$i18n.t('customerCare.notesSection.noteTags')"
                        :placeholder="$i18n.t('customerCare.notesSection.noteTagsPlaceholder')"
                        :options="Object.values(noteTags)"
                        :small="true"
                        :multiple="true"
                        class="selector col-7 mb-5"
                    />
                    <p class="lf-labels mb-1 col-7">
                        {{ $i18n.t('customerCare.notesSection.agentNotesLabel') }}
                    </p>
                    <AppTextareaV3
                        v-model="noteText"
                        :placeholder="$i18n.t('customerCare.notesSection.agentNotesInputPlaceholder')"
                        class="selector col-7 mb-5"
                        :rows="4"
                    />
                </div>
            </section>
            <section>
                <div class="content pt-0 inner-layout">
                    <AgentNotesDragDropUploader
                        :acceptType="acceptType"
                        :triggerUploadFiles="uploadInProgress"
                        :uploadSuccessIndex="uploadSuccessIndex"
                        :triggerUploadFailed="uploadFailed"
                        class="col-7"
                        data-test-id="drag-drop-file-uploader-nm"
                        @updateFiles="updateFiles"
                    />
                </div>
            </section>
        </div>
        <div class="custom-footer d-flex justify-content-end">
            <AppButton
                :label="$i18n.t('generic.back')"
                class="cancel-btn mr-3"
                @click="onCancel"
            />
            <AppButton
                :label="$i18n.t('operator.numberManagement.uploadFile')"
                :buttonType="BUTTON_TYPES.PRIMARY"
                :iconType="ICON_TYPES.CHECK"
                :disabled="isUploadButtonDisabled"
                class="save-btn"
                @click="onSave"
            />
        </div>
        <FileUploaderModal
            v-show="showWarningModal"
            :heading="$i18n.t('operator.numberManagement.uploadInProgress.heading')"
            :actionButtonLabel="$i18n.t('operator.numberManagement.uploadInProgress.actionLabel')"
            :paragraphList="$i18n.t('operator.numberManagement.uploadInProgress.message')"
            @close="showWarningModal = false"
            @action="
                showWarningModal = false;
                $router.go(-1);
            "
        />
    </div>
</template>

<script>
// COMPONENTS
import AppHeader from '@/components/layout/AppHeader.vue';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import { ICON_TYPES } from '@/common/iconHelper';
import AppMultiselectV3 from '@/components/partials/inputs/AppMultiselectV3.vue';
import AppTextareaV3 from '@/components/partials/inputs/AppTextareaV3.vue';
import AgentNotesDragDropUploader from '@/__new__/features/customerCare/AgentNotesDragDropUploader.vue';
import { ALERT_TYPES } from '@/common/alerts/Alert';
import FileUploaderModal from '@/components/partials/fileUploader/FileUploaderModal.vue';

// HELPERS
import httpNotes from '@/__new__/services/dno/crm/http/crm';
import * as Sentry from '@sentry/vue';
import { uploadToSignedURLNoProgress } from '@/http/fileUploader';
import { documentTypes, getMimeType } from '@/common/documentTypeHelper';
import { dateToEpoch } from '@/common/formatting';

export const noteTags = {
    INTERACTION: 'Interaction',
    DOCUMENT: 'Document',
    REFUND: 'Refund',
    COMPLAINT: 'Complaint',
};

export default {
    name: 'NewNote',
    components: {
        AppHeader,
        AppButton,
        AppMultiselectV3,
        AppTextareaV3,
        AgentNotesDragDropUploader,
        FileUploaderModal,
    },

    data() {
        return {
            ICON_TYPES,
            BUTTON_TYPES,
            noteTags,
            selectedTags: [],
            noteEpoch: 0,
            noteText: '',
            noteDocuments: [],
            noteDocumentIds: [],
            uploadedDocsIds: [],
            uploadInProgress: false,
            uploadFinished: false,
            uploadSuccessIndex: null,
            uploadFailed: false,
            id: '',
            idType: '',
            showWarningModal: false,
            redirectTimeoutId: null,
        };
    },
    computed: {
        isUploadButtonDisabled() {
            return (!this.noteText && !this.noteDocuments.length) || this.uploadInProgress || this.uploadFinished;
        },
        acceptType() {
            return [
                documentTypes.PDF,
                documentTypes.PNG,
                documentTypes.JPG,
                documentTypes.DOC,
                documentTypes.DOCX,
            ].join(', ');
        },
    },
    mounted() {
        this.id = this.$route?.params?.id;
        this.idType = this.$route?.params?.idType;
    },
    beforeDestroy() {
        if (this.redirectTimeoutId) {
            clearTimeout(this.redirectTimeoutId);
        }
    },
    methods: {
        updateDocumentTag(filesLength) {
            if (filesLength) {
                if (!this.selectedTags?.includes(noteTags.DOCUMENT)) {
                    this.selectedTags.push(noteTags.DOCUMENT);
                }
            } else if (this.selectedTags?.includes(noteTags.DOCUMENT)) {
                this.selectedTags = this.selectedTags.filter(tag => tag !== noteTags.DOCUMENT);
            }
        },
        updateFiles(files) {
            // If the note has a document attached, the note should automatically be tagged with the “document” tag.
            this.updateDocumentTag(files?.length);
            this.noteDocuments = files;
        },
        onCancel() {
            if (this.uploadInProgress) {
                this.showWarningModal = true;
            } else {
                this.$router.go(-1);
            }
        },
        onSave() {
            // If the note does not have any selected tags,
            // the agent should be prompted to select at least one tag for the note before saving.
            if (!this.selectedTags?.length) {
                this.showMessage(this.$i18n.t('customerCare.notesSection.noTagsWarning'), ALERT_TYPES.warning);
            } else {
                this.addNotes();
            }
        },
        async addNotes() {
            try {
                this.$Progress.start();
                this.uploadInProgress = true;

                // Set epoch. Must be same for add/update note.
                this.noteEpoch = dateToEpoch(Date.now());

                // Get document IDs for all pending files if they exist
                if (this.noteDocuments?.length) {
                    this.noteDocumentIds = this.noteDocuments.map(doc => doc.id);
                }

                // Add note with tags and get URLs for document upload
                const { data } = await httpNotes.addNote({
                    id: this.id,
                    idType: this.idType,
                    epoch: this.noteEpoch,
                    noteText: this.noteText,
                    tags: this.selectedTags.map(tag => tag.toLowerCase()),
                    documentIds: this.noteDocumentIds?.length ? this.noteDocumentIds : null,
                });

                // Upload files
                /* eslint-disable camelcase */
                if (Object.keys(data?.document_urls)?.length) {
                    await this.uploadFiles(data?.document_urls);
                    await this.updateNotes();
                }

                await this.showMessage(
                    this.$i18n.t('customerCare.notesSection.alerts.successfullyCreated'),
                    ALERT_TYPES.success,
                );
                this.$Progress.finish();
            } catch (e) {
                this.uploadFailed = true;
                this.uploadInProgress = false;
                Sentry.captureException(e);
                this.$Progress.fail();
                this.showMessage(this.$i18n.t('customerCare.notesSection.errors.unableToCreate'), ALERT_TYPES.error);
            } finally {
                this.redirectTimeoutId = setTimeout(() => this.$router.go(-1), 5000);
                if (!this.noteDocuments?.length) {
                    this.uploadInProgress = false;
                    this.uploadFinished = true;
                }
            }
        },
        async updateNotes() {
            if (!this.uploadedDocsIds.length) return;

            try {
                await httpNotes.updateNote({
                    id: this.id,
                    idType: this.idType,
                    epoch: this.noteEpoch,
                    documentIds: this.uploadedDocsIds,
                });
            } catch (e) {
                this.$Progress.fail();
                Sentry.captureException(e);
                this.showMessage(
                    this.$i18n.t('customerCare.notesSection.errors.somethingWentWrongWhileAddingFiles'),
                    ALERT_TYPES.error,
                );
            }
        },
        async uploadFiles(docURLs) {
            const promises = this.noteDocuments.map((doc, index) => {
                doc.url = docURLs[doc.id];
                const newFile = new File([doc.file], doc.id);
                doc.blob = new Blob([newFile], { type: 'binary' });

                return this.uploadFile(doc, index);
            });
            await Promise.all(promises);
        },
        async uploadFile({ id, blob, url, name }, index) {
            try {
                await uploadToSignedURLNoProgress(blob, url, getMimeType(name));
                this.uploadSuccessIndex = index;
                this.uploadedDocsIds.push(id);
                this.showMessage(
                    this.$i18n.t('customerCare.notesSection.alerts.successfullyUploaded'),
                    ALERT_TYPES.success,
                );
            } catch (e) {
                this.uploadFailed = true;
                Sentry.captureException(e);
                this.showMessage(
                    this.$i18n.t('customerCare.notesSection.errors.unableToUploadFile'),
                    ALERT_TYPES.error,
                );
            }
        },
        showMessage(message, type) {
            this.$eventBus.$emit('showAlert', { type, message });
        },
    },
};
</script>

<style lang="scss" scoped>
@import 'src/assets/scss/colors';
@import 'src/assets/scss/palette';
@import 'src/assets/scss/typographyV2';
@import 'src/assets/scss/layout';

.new-note-wrapper {
    display: flex;
    flex-direction: column;

    .new-note {
        padding: 2rem 10rem 4rem;
    }
}

section {
    .content {
        padding: 2rem 0;

        p {
            margin: 0;
            color: $gray90;
        }
    }
}

.custom-footer {
    padding: 1.5rem 9.5rem;
    border-top: solid 0.0625rem rgba($color: $blue, $alpha: 0.15);
}
.background-color {
    background-color: $dirty-white;
}

.section-layout {
    margin: 0 1.5rem 1.5rem 1.5rem;
}
</style>
