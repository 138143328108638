export type ReportArchive = {
    id: string;
    reportId: string;
    range: string;
    status: string;
    url?: string;
    created: number;
};

export enum REPORT_ARCHIVE_STATUS {
    COMPLETED = 'completed',
    FAILED = 'failed',
    PENDING = 'pending',
    RUNNING = 'running',
}
