<template>
    <div>
        <form @submit.prevent="changePassowrd">
            <div class="heading">
                <div>
                    <h1 class="font-weight-bold lf-welcome">
                        {{ $t('login.welcome') }}
                    </h1>
                    <span class="lf-subtitle-blue">{{ $t('login.newPassword') }}</span>
                </div>
            </div>
            <!-- Form inputs -->
            <div class="login-form">
                <AppInputV3
                    v-model="password"
                    :placeholder="$t('generic.password')"
                    :invalid="$v.password.$error"
                    type="password"
                    class="form-inputs"
                    :errorMessage="passwordErrorMessage"
                />
                <AppInputV3
                    v-model="confirmPassword"
                    :placeholder="$t('generic.confirmPassword')"
                    :invalid="$v.password.$error"
                    type="password"
                    class="form-inputs"
                    :errorMessage="passwordConfirmationErrorMessage"
                />
                <div class="button-wrapper">
                    <AppButton
                        :label="$t('generic.submit')"
                        :buttonType="loginButtonsType"
                        class="login-button"
                    />
                </div>
            </div>
        </form>
    </div>
</template>
<script>
// Vue components
import AppInputV3 from '@/components/partials/inputs/AppInputV3.vue';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';

// HTTP layer
import { changePassword } from '@/__new__/services/portal/auth/http/login';

// Helpers
import { ALERT_TYPES } from '@/common/alerts/Alert';

// Validation
import { validationMixin } from 'vuelidate';
import { required, sameAs } from 'vuelidate/lib/validators';

export default {
    name: 'ChangePassword',
    components: {
        AppButton,
        AppInputV3,
    },
    mixins: [validationMixin],
    props: {
        loginButtonsType: {
            type: String,
            default: BUTTON_TYPES.PRIMARY,
        },
    },
    data() {
        return {
            password: '',
            confirmPassword: '',
            passwordErrorMessage: '',
            passwordConfirmationErrorMessage: '',
            BUTTON_TYPES,
        };
    },

    validations() {
        return {
            password: {
                required,
                goodPassword: password => {
                    if (password) {
                        return (
                            password.length >= 9 &&
                            /[a-z]/.test(password) &&
                            /[A-Z]/.test(password) &&
                            /[0-9]/.test(password)
                        );
                    }
                    return true;
                },
            },
            confirmPassword: {
                required,
                sameAsNewPassword: sameAs('password'),
            },
        };
    },
    watch: {
        password() {
            this.passwordErrorMessage = '';
        },
        confirmPassword() {
            this.passwordConfirmationErrorMessage = '';
        },
    },
    methods: {
        async changePassowrd() {
            this.$v.$touch();
            if (!this.$v.$invalid) {
                this.$Progress.start();
                try {
                    await changePassword(
                        decodeURIComponent(this.$route.query.email),
                        this.password,
                        this.$route.query.token,
                    );
                    this.$Progress.finish();
                    this.$eventBus.$emit('showAlert', {
                        message: this.$i18n.t('alertMessage.login.passwordChanged'),
                        type: ALERT_TYPES.success,
                    });
                    setTimeout(() => this.$emit('passwordUpdated'), 3000);
                } catch (e) {
                    this.$eventBus.$emit('showAlert', {
                        message: e.response.data.error,
                    });
                    this.$Progress.fail();
                }
            } else {
                this.generateErrorMessage();
            }
        },
        generateErrorMessage() {
            if (!this.$v.password.required) {
                this.passwordErrorMessage = this.$i18n.t('alertMessage.login.passwordRequired');
            }
            if (!this.$v.confirmPassword.required) {
                this.passwordConfirmationErrorMessage = this.$i18n.t('alertMessage.login.passwordConfirmationRequired');
            }
            if (!this.$v.password.goodPassword) {
                this.passwordErrorMessage = this.$i18n.t('alertMessage.login.notMeetCriteria');
            }
            if (!this.$v.confirmPassword.sameAsNewPassword) {
                this.passwordConfirmationErrorMessage = this.$i18n.t('alertMessage.login.notMatch');
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/palette';
@import '~@/assets/scss/_typographyV2.scss';
@import '~@/assets/scss/_layout';

h1 {
    margin: 0;
}

.lf-welcome {
    font-family: $font-primary;
    font-size: $text-xxl;
    font-weight: $bold-font-weight;
    line-height: $normal-line-height;
    letter-spacing: normal;
    color: $blue;
    display: flex;

    @media screen and (prefers-color-scheme: dark) {
        color: #fff;
    }

    @media screen and (max-width: 1024px) {
        font-size: 2rem;
    }
}

.lf-subtitle-blue {
    display: flex;
    margin-left: 0.5rem;

    @media screen and (prefers-color-scheme: dark) {
        color: #fff;
    }
}

.form-inputs {
    padding-bottom: $spacing-m;
    max-width: 400px;
    width: 100%;

    @media screen and (max-width: 1024px) {
        max-width: 100%;
    }
}

.heading {
    margin-bottom: 5rem;
    @media screen and (max-width: 680px) {
        text-align: center;
    }
}

.login-form {
    display: flex;
    flex-direction: column;
    width: 100%;

    @media screen and (max-width: 680px) {
        justify-content: center;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

.login-button {
    margin-top: 10px;
    width: 100%;
    margin-bottom: 57px;
}

.button-wrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 400px;

    @media screen and (max-width: 1024px) {
        width: 100%;
        max-width: 100%;
    }
}

.text-button {
    display: flex;
    color: $white;
    text-decoration: underline;
    cursor: pointer;
}
</style>
