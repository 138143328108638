import http, { type DnoResponseBase } from '@/http';
import { getDnoClaimsHeader, USER_MANAGER_HIERARCHY } from '@/__new__/features/customerCare/common/customerCareHelper';
import { type AxiosPromise } from 'axios';
import { type AgentNoteBe } from '@/__new__/services/dno/crm/models/AgentNote';

interface GetNotesByIdResponse extends DnoResponseBase {
    notes: AgentNoteBe[];
}

type NoteData = {
    id: string;
    idType: number;
    epoch: number;
    noteText?: string;
    tags?: string[];
    documentIds?: string[];
};

type NoteTargetTuple = {
    id: string;
    id_type: USER_MANAGER_HIERARCHY;
};

/**
 * Get notes
 * https://raw.githubusercontent.com/lotusflare/lua/master/services_root/services/crm/resources/swagger/CRM.yaml?token=AFVEKJHTZ7AOCQ7HXO5HNRLAVYVKG
 * @param {string} id - Unique ID of the target on whom this note is being added.
 * @param {number} idType - Id type defines the type of the ID against which we want to perform certain action
 * @param {number} pageSize - Page size default 50.
 * @param {string} maxResults - Max result default 200.
 * @returns {AxiosPromise}
 */
function getNotes(
    id: string,
    idType: number,
    pageSize = 50,
    maxResults = 200,
): AxiosPromise<Array<Array<Array<AgentNoteBe>>> | Record<string, never>> {
    return http({
        method: 'POST',
        url: '/v3crm/getnotes',
        headers: {
            'Dno-Claims': JSON.stringify(getDnoClaimsHeader(id, idType)),
        },
        data: {
            data: {
                id,
                id_type: idType,
                page_size: pageSize,
                max_results: maxResults,
            },
        },
    });
}

function getNotesByIds(ids: NoteTargetTuple[]): AxiosPromise<GetNotesByIdResponse> {
    return http({
        method: 'POST',
        url: '/v3crm/getnotesbyids',
        data: {
            ids,
        },
    });
}

/**
 * Add note
 *
 *  1 - Subscriber type
 *  2 - Account type
 *  3 - User type
 * @returns {AxiosPromise}
 * @param data
 */
function addNote(data: NoteData): AxiosPromise<DnoResponseBase> {
    return http({
        method: 'POST',
        url: '/v3crmwrite/addnote',
        headers: {
            'Dno-Claims': JSON.stringify(getDnoClaimsHeader(data.id, data.idType)),
        },
        data: {
            data: {
                id: data.id,
                id_type: data.idType,
                epoch: data.epoch,
                note: {
                    en: data.noteText,
                },
                ...(data.tags && { tags: data.tags }),
                ...(data.documentIds && { document_ids: data.documentIds }),
            },
        },
    });
}

/**
 *
 * @param {Object} data
 * @param {string} data.id
 * @param {number} data.idType
 * @param {number} data.epoch - timestap in seconds
 * @param {Array} data.documentIds
 * @returns {AxiosPromise} AxiosPromise
 */
function updateNote(data: Required<Omit<NoteData, 'noteText' | 'tags'>>): AxiosPromise<DnoResponseBase> {
    return http({
        method: 'POST',
        url: '/v3crmwrite/updatenote',
        headers: {
            'Dno-Claims': JSON.stringify(getDnoClaimsHeader(data.id, data.idType)),
        },
        data: {
            id: data.id,
            id_type: data.idType,
            epoch: data.epoch,
            columns_to_update: {
                document_ids: data.documentIds,
            },
        },
    });
}

export default {
    getNotes,
    getNotesByIds,
    addNote,
    updateNote,
};
