import { DnoResponseBase } from '@/http/index';

import { TranslateResult } from 'vue-i18n';
import i18n from '@/i18n';

export interface BatchRescheduledexEcutionsDno extends DnoResponseBase {
    rescheduled_executions: RescheduledExecutionDno[];
}

export type RescheduledExecutionDno = {
    operator_name: string;
    operation_type: OPERATION_TYPE;
    create_time: number;
    name: string;
    description: string;
    executions_affected: string[];
    batch_id: string;
};

export type HaltExecutions = {
    halt_succeeded?: boolean;
    execution_id: string;
    halt_error?: {
        module: string;
        code: number;
    };
};

export type BatchHaltExecutionsDno = {
    halt_executions: HaltExecutions[];
};

export enum OPERATION_TYPE {
    BATCH_RESCHEDULE = 'batch_reschedule',
    BATCH_HALT = 'batch_halt',
}

export type UpdateScheduledExecutionTimeData = {
    execution_ids: string[];
    shift_by?: number;
    schedule_to_timestamp?: number;
    name: string;
    description: string;
};

export type BatchActions = {
    id: string;
    operatorName: string;
    operationType: OPERATION_TYPE;
    createTime: number;
    createTimeLabel: TranslateResult;
    name: string;
    description: string;
    executionsAffected: string[];
    batchId: string;
};

export enum BATCH_ACTION_TYPE {
    RUN_EXECUTION = 'runExecution',
    SCHEDULE_EXECUTION = 'scheduleExecution',
    POSTPONE_EXECUTION = 'postponeExecution',
    HALT_EXECUTION = 'haltExecution',
    RESUME_EXECUTION = 'resumeExecution',
    GET_EXECUTION_DETAILS = 'getExecutionDetails',
}

export const BATCH_ACTIONS = {
    // temporarily comment out run execution functionality
    // [BATCH_ACTION_TYPE.RUN_EXECUTION]: {
    //     id: BATCH_ACTION_TYPE.RUN_EXECUTION,
    //     name: i18n.t('orchestrationEngine.batchActions.runExecution'),
    // },
    [BATCH_ACTION_TYPE.SCHEDULE_EXECUTION]: {
        id: BATCH_ACTION_TYPE.SCHEDULE_EXECUTION,
        name: i18n.t('orchestrationEngine.batchActions.scheduleExecution'),
    },
    [BATCH_ACTION_TYPE.POSTPONE_EXECUTION]: {
        id: BATCH_ACTION_TYPE.POSTPONE_EXECUTION,
        name: i18n.t('orchestrationEngine.batchActions.postponeExecution'),
    },
    [BATCH_ACTION_TYPE.HALT_EXECUTION]: {
        id: BATCH_ACTION_TYPE.HALT_EXECUTION,
        name: i18n.t('orchestrationEngine.batchActions.haltExecution'),
    },
    [BATCH_ACTION_TYPE.RESUME_EXECUTION]: {
        id: BATCH_ACTION_TYPE.RESUME_EXECUTION,
        name: i18n.t('orchestrationEngine.batchActions.resumeExecution'),
    },
    [BATCH_ACTION_TYPE.GET_EXECUTION_DETAILS]: {
        id: BATCH_ACTION_TYPE.GET_EXECUTION_DETAILS,
        name: i18n.t('orchestrationEngine.batchActions.getExecutionDetails'),
    },
};

export type ScheduleMultiExecution = {
    plan_id: string;
    inputs: any;
    period_s: number;
};

export type GetExecutionDetailsSearchParameters = {
    state: string;
    parameters: string[];
    iteration: number;
    retryNum: number;
};

export type GetExecutionDetails = {
    value: boolean;
    title: string;
    executionId: string;
    searchParameters: GetExecutionDetailsSearchParameters;
    error: boolean;
};

export type GetExecutionDetailsApiPayload = {
    execution_id: string;
    search_parameters: {
        state: string;
        iteration: number;
        retry_num: number;
        parameters: string[];
    }[];
};

export const mapDataToBePayloadForGetDetailsFromExecutions = (
    data: GetExecutionDetails[],
): GetExecutionDetailsApiPayload[] => {
    return data.map(el => ({
        execution_id: el.executionId,
        search_parameters: [
            {
                state: el.searchParameters.state,
                iteration: el.searchParameters.iteration,
                retry_num: el.searchParameters.retryNum,
                parameters: el.searchParameters.parameters,
            },
        ],
    }));
};

export default {};
