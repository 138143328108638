<template>
    <AbstractListPageWrapper
        :pageTitle="$t('customFilters.customFilters')"
        :isOverviewEnabled="isOverviewEnabled"
        :entitiesCount="formattedTableEntitiesLength"
        class="page-wrapper overflow-hidden"
        @searchQueryChanged="setSearchQuery"
    >
        <template #filterTable>
            <FilterTable
                :columns="tableColumnsData"
                :multiselectWidth="{ width: '15rem' }"
                @filterAdded="onFilterAdded"
            />
        </template>

        <template #headerButtons>
            <AppButton
                v-if="true"
                :buttonType="BUTTON_TYPES.PRIMARY"
                :iconType="ICON_TYPES.PLUS"
                :label="$i18n.t('generic.addNew')"
                @click="navigateToCreateCustomFilter"
            />
        </template>

        <template #allFilters>
            <TableFiltersTags
                :filterTableMixin="filterTableMixin"
                class="my-3 ml-2"
                @removeFilter="removeFilter"
                @removeAllFilters="removeAllFilters"
            />
        </template>

        <template #table>
            <AppTable
                data-test="custom-filters-table"
                idColumn="id"
                :entities="[]"
                :columnsData="tableColumnsData"
                :canSelectColumns="false"
                :enableRowStateControls="true"
                :canSort="true"
                :isDataLoading="isDataLoading"
                tableKey="events"
                :isSearchEnabled="true"
                :innerSearchQuery="searchQueryForTable"
                :entityType="entityType"
                @selectEntity="openEntityOverview"
                @edit="goToEditPage"
                @input="setTableEntitiesLength"
            >
                <template #state="{}" />
            </AppTable>
        </template>

        <template #overview>
            <div>overview text</div>
        </template>
    </AbstractListPageWrapper>
</template>

<script>
// components
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import AbstractListPageWrapper from '@/components/layout/AbstractListPageWrapper.vue';
import AppTable from '@/components/partials/AppTable.vue';
import FilterTable from '@/components/partials/FilterTable.vue';
import FilterTableMixin from '@/components/partials/FilterTableMixin.vue';
import TableFiltersTags from '@/components/filters/TableFiltersTags.vue';

// helpers
import RouteNames from '@/router/routeNames';
import ENTITY_TYPES from '@/common/entities/entityTypes';
import tableColumnType from '@/common/filterTable';
import * as Sentry from '@sentry/vue';
import { ICON_TYPES } from '@/common/iconHelper';
import { isUserAllowed } from '@/services/permissions/permissions.service';

export default {
    name: 'CustomFiltersDashboard',
    components: {
        AbstractListPageWrapper,
        AppButton,
        AppTable,
        FilterTable,
        TableFiltersTags,
    },
    mixins: [FilterTableMixin],
    data() {
        return {
            ICON_TYPES,
            BUTTON_TYPES,
            searchQueryForTable: '',
            entityType: ENTITY_TYPES.CUSTOM_FILTER,
            selectedEntityId: null,
            isOverviewEnabled: false,
            isDataLoading: false,
            formattedTableEntitiesLength: 0,
            multipleActions: [
                {
                    label: this.$t('generic.showDetails'),
                    buttonIcon: ICON_TYPES.DETAILS,
                    refName: 'bulk-details-button',
                },
            ],
        };
    },
    computed: {
        tableColumnsData() {
            return [
                {
                    name: this.$i18n.t('generic.name'),
                    key: 'name',
                    field: 'name',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
            ];
        },
        formattedFilteredEntities() {
            return this.filteredEntitiesMixin(this.events);
        },
        defaultSort() {
            return {
                sortBy: entity => entity.updateTime,
                type: 'desc',
            };
        },
        selectedEntity() {
            return this.eventsById[this.selectedEntityId];
        },
    },

    created() {
        this.loadData();
    },

    methods: {
        setTableEntitiesLength({ length }) {
            this.formattedTableEntitiesLength = length;
        },
        openEntityOverview(id) {
            this.selectedEntityId = id;
            this.isOverviewEnabled = true;
        },

        goToEditPage(id) {
            this.$router.push({
                name: RouteNames.EVENTS_EDITOR,
                params: { id, companyId: this.$route.params.companyId },
            });
        },

        loadData() {
            try {
                this.isDataLoading = true;
                this.$Progress.start();
            } catch (e) {
                this.$alert(this.$i18n.t('events.alerts.failedToLoadAllEvents'));
                this.$Progress.fail();
                Sentry.captureException(e);
            }
            this.isDataLoading = false;
            this.$Progress.finish();
        },
        navigateToCreateCustomFilter() {
            this.$router.push({ name: RouteNames.EVENTS_EDITOR, params: { companyId: this.$route.params.companyId } });
        },
        setSearchQuery(query) {
            this.searchQueryForTable = query;
        },
        isUserAllowed,
    },
};
</script>

<style lang="scss" scoped>
// Fix double scrollbars from AbstractListPageWrapper
.page-wrapper {
    height: 100vh !important;
}
</style>
