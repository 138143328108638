import httpClient from './index';

/**
 * Retrieve asset for subscriber
 * @param accountId
 * @param assetType
 * @return {AxiosPromise}
 */
export default function getAssetUrl(accountId: string, assetType: number) {
    return httpClient({
        method: 'POST',
        url: '/v3usermanager/getasset',
        headers: {
            'content-type': 'application/json',
            'Dno-Claims': JSON.stringify({
                account_id: accountId,
            }),
        },
        data: {
            asset_type: assetType,
        },
    });
}
