<template>
    <div>
        <div class="d-flex position-relative align-items-baseline">
            <h3 class="lf-subtitle">
                {{ $i18n.t('customerCare.userAuthentication.userAuthentication') }}
            </h3>
            <div class="d-flex control-bar mb-3">
                <AppButton
                    :buttonType="BUTTON_TYPES.TERTIARY"
                    :label="$i18n.t('customerCare.userAuthentication.generateCode')"
                    class="otp-button mr-1"
                    data-test-id="otp-open-modal-generate"
                    @click="toggleGenerateModal(true)"
                />
                <AppButton
                    :buttonType="BUTTON_TYPES.TERTIARY"
                    :disabled="!codeGenerated"
                    :label="$i18n.t('customerCare.userAuthentication.verifyUser')"
                    class="otp-button"
                    data-test-id="otp-open-modal-verify"
                    @click="toggleVerifyModal(true)"
                />
            </div>
        </div>

        <div
            v-if="!entities.length"
            class="no-entities-wrapper"
        >
            <span class="no-entities ml-4">
                {{ $i18n.t('customerCare.userAuthentication.noEntitiesMockText') }}
            </span>
        </div>
        <AppTable
            v-else
            :entities="entities"
            :isSearchEnabled="false"
            :isPaginationEnabled="true"
            :isDefaultHoverEnabled="false"
            :canSelectColumns="false"
            :isDataLoading="isLoading"
            :newDesign="true"
            :columnsData="columnsData"
            :tableTabs="true"
            :noEntitiesMockText="$i18n.t('customerCare.userAuthentication.noEntitiesMockText')"
            tableKey="user/otp"
            class="mb-3"
        />

        <AppDialog
            :visible="isGenerateModalVisible"
            maxWidth="47.5rem"
            maxHeight="100%"
        >
            <div class="modal-form">
                <h2 class="lf-title otp-modal-header">
                    {{ $i18n.t('customerCare.userAuthentication.modal.generateTitle', { num: numberOfDigits }) }}
                </h2>
                <AppMultiselectV3
                    v-model="challengeType"
                    :additionalLabel="$i18n.t('customerCare.userAuthentication.modal.chooseChannel')"
                    :small="true"
                    :options="challengeTypeOptions"
                    :placeholder="$i18n.t('customerCare.userAuthentication.modal.chooseChannel')"
                    class="otp-modal-header"
                />
                <div class="modal-footer d-flex justify-content-end">
                    <AppButton
                        :buttonType="BUTTON_TYPES.SECONDARY"
                        :label="$i18n.t('generic.cancel')"
                        class="mr-3 notes-modal-cancel-btn"
                        @click="toggleGenerateModal(false)"
                    />
                    <AppButton
                        :buttonType="BUTTON_TYPES.PRIMARY"
                        :label="$i18n.t('customerCare.userAuthentication.generate')"
                        :disabled="!challengeType"
                        class="notes-modal-save-btn"
                        data-test-id="generate-code-button"
                        @click="generateCode()"
                    />
                </div>
            </div>
        </AppDialog>

        <AppDialog
            :visible="isVerifyModalVisible"
            maxWidth="47.5rem"
            maxHeight="100%"
        >
            <div class="modal-form">
                <h2 class="lf-title otp-modal-header">
                    {{ $i18n.t('customerCare.userAuthentication.modal.enterDigits', { num: numberOfDigits }) }}
                </h2>
                <AppOtpCodeInput
                    :numberOfDigits="numberOfDigits"
                    @update="otp = $event"
                />
                <div class="modal-footer d-flex justify-content-end">
                    <AppButton
                        :label="$i18n.t('generic.cancel')"
                        :buttonType="BUTTON_TYPES.SECONDARY"
                        class="mr-3 notes-modal-cancel-btn"
                        @click="toggleVerifyModal(false)"
                    />
                    <AppButton
                        :label="$i18n.t('customerCare.userAuthentication.verify')"
                        :buttonType="BUTTON_TYPES.PRIMARY"
                        :disabled="otp.length < numberOfDigits"
                        class="notes-modal-save-btn"
                        data-test-id="verify-code-button"
                        @click="verifyCode()"
                    />
                </div>
            </div>
        </AppDialog>
    </div>
</template>

<script>
// COMPONENTS
import AppTable from '@/components/partials/AppTable.vue';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import AppDialog from '@/components/partials/AppDialog.vue';
import AppMultiselectV3 from '@/components/partials/inputs/AppMultiselectV3.vue';
import AppOtpCodeInput from '@/components/partials/inputs/AppOtpCodeInput.vue';
import { ALERT_TYPES } from '@/common/alerts/Alert';

// HELPERS
import {
    USER_MANAGER_HIERARCHY,
    CHALLENGE_TYPES,
    CHALLENGE_CONTEXT_TYPE,
} from '@/__new__/features/customerCare/common/customerCareHelper';
import HTTPCustomerCare from '@/__new__/services/dno/user/http/customer-care';
import { getOperatorConfigValue } from '@/services/permissions/permissions.service';
import * as Sentry from '@sentry/vue';
import moment from 'moment';
import Vue from 'vue';
import { mapMutations } from 'vuex';

export default {
    name: 'UserAuthentication',
    components: {
        AppTable,
        AppButton,
        AppDialog,
        AppMultiselectV3,
        AppOtpCodeInput,
    },
    data() {
        return {
            isGenerateModalVisible: false,
            isVerifyModalVisible: false,
            entities: [],
            isLoading: false,
            ALERT_TYPES,
            BUTTON_TYPES,
            CHALLENGE_TYPES,
            codeGenerated: false,
            challengeId: '',
            otp: '',
            challengeType: null,
            numberOfDigits: getOperatorConfigValue('service_config.lf-user.otp.otp_digits', 4),
        };
    },
    computed: {
        contextType() {
            return CHALLENGE_CONTEXT_TYPE.GENERIC;
        },
        columnsData() {
            return [
                {
                    name: this.$i18n.t('customerCare.userAuthentication.dateAndTime'),
                    field: 'dateAndTime',
                    key: 'dateAndTime',
                },
                {
                    name: this.$i18n.t('customerCare.userAuthentication.channel'),
                    key: 'channel',
                    field: 'channel',
                },
                {
                    name: this.$i18n.t('customerCare.userAuthentication.status'),
                    key: 'status',
                    field: 'status',
                },
            ];
        },
        challengeTypeOptions() {
            return Object.keys(CHALLENGE_TYPES).filter(challenge => Number.isNaN(Number(challenge)));
        },
    },
    created() {
        this.$eventBus.$on('openAuthModal', () => {
            if (this.codeGenerated) {
                this.toggleVerifyModal(true);
            } else {
                this.toggleGenerateModal(true);
            }
        });
    },
    methods: {
        ...mapMutations('userManagementUser', ['setUserVerificationStatus']),
        toggleGenerateModal(value) {
            this.isGenerateModalVisible = value;
        },
        toggleVerifyModal(value) {
            this.isVerifyModalVisible = value;
            this.otp = '';
        },
        populateEntity() {
            // @todo add entity to store ?
            const entity = {
                dateAndTime: moment().format('YYYY-MM-DD HH:mm'),
                channel: this.challengeType,
                status: this.codeGenerated
                    ? this.$i18n.t('customerCare.userAuthentication.sent')
                    : this.$i18n.t('customerCare.userAuthentication.verified'),
            };

            Vue.set(this.entities, 0, entity);
        },
        async generateCode() {
            try {
                this.$Progress.start();
                const { data } = await HTTPCustomerCare.sendOTP(
                    this.$route.params.id,
                    USER_MANAGER_HIERARCHY.USER,
                    this.contextType,
                    CHALLENGE_TYPES[this.challengeType],
                );
                this.challengeId = data.challenge_id;
                this.codeGenerated = true;
                this.populateEntity();
                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('customerCare.userAuthentication.successMessages.generate'),
                    type: ALERT_TYPES.success,
                });
                this.$Progress.finish();
            } catch (e) {
                Sentry.captureException(e);
                this.$Progress.fail();
                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('customerCare.userAuthentication.errorMessages.generate'),
                    type: ALERT_TYPES.error,
                });
            } finally {
                this.toggleGenerateModal(false);
                this.toggleVerifyModal(true);
            }
        },
        async verifyCode() {
            let verifyStatus;
            try {
                this.$Progress.start();
                await HTTPCustomerCare.verifyOTP(
                    this.challengeId,
                    this.otp,
                    this.contextType,
                    CHALLENGE_TYPES[this.challengeType],
                );
                this.codeGenerated = false;
                this.populateEntity();
                verifyStatus = true;
                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('customerCare.userAuthentication.successMessages.verify'),
                    type: ALERT_TYPES.success,
                });
                this.$Progress.finish();
            } catch (e) {
                Sentry.captureException(e);
                verifyStatus = false;
                this.$Progress.fail();
                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('customerCare.userAuthentication.errorMessages.verify'),
                    type: ALERT_TYPES.error,
                });
            } finally {
                this.toggleVerifyModal(false);
                this.setUserVerificationStatus({
                    userId: this.$route.params.id,
                    status: verifyStatus,
                });
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/filter-table';
@import '~@/assets/scss/typographyV2';
@import '~@/assets/scss/layout';
@import '~@/assets/scss/palette';

.control-bar {
    margin-left: auto;

    .otp-button {
        width: 8.313rem;
    }
}

.no-entities-wrapper {
    height: 5.625rem;
    background: $white;
    border-radius: 0.25rem;
    display: flex;
    align-items: center;

    .no-entities {
        font-weight: 700;
        font-size: 0.75rem;
        line-height: 1.625rem;
        text-transform: uppercase;
        color: $blue60;
    }
}

.modal-form {
    .otp-modal-header {
        margin: $spacing-xxl $spacing-xxxl 0;
    }

    .modal-footer {
        margin-top: 4rem;
        padding: $spacing-l $spacing-xxl;
        border-top: none;
    }
}
</style>
