import UserManagerBaseModel, {
    PROPERTY_TYPE_BE_MAP,
    PROPERTY_TYPE_VALIDATE_FN_MAP,
} from '@/__new__/services/dno/user/models/UserManagerBaseModel';
import Flag, { Flags, FLAG_MAP } from '@/__new__/services/dno/user/models/Flag';
import { get, isEmpty } from 'lodash';
import { UM_SUBSCRIPTION_TYPES_MAP } from '@/__new__/features/customerCare/common/userInfoHelper';
import localeLibrary from '@/common/locale/localeLibrary';
import { getOperatorConfigValue } from '@/services/permissions/permissions.service';
import { USER_MANAGER_HIERARCHY } from '@/__new__/features/customerCare/common/customerCareHelper';
import { TranslateResult } from 'vue-i18n';
import i18n from '@/i18n';
import { ADDRESS_ELIGIBILITY } from '@/common/addressbookHelper';

interface Address {
    address_1: string;
    address_2: string;
    city: string;
    state: string;
    zip: string;
    zip_4: string;
    hierarchy: number;
    type: number;
}
interface SubscriberConstructorArgs {
    id: string;
    name: string;
    surname: string;
    fullName: string;
    state: number;
    activationDate: number;
    creationDate: number;
    subscriptionType: number;
    subscriptionTypeString: string;
    msisdn: string;
    imsi: [];
    iccid: [];
    username: string;
    email: string;
    phone: string;
    flags: Flags<number>;
    accountId: string;
    userId: string;
    updateReason: string;
    cardinality: number;
    marketZip: string;
    addresses: Record<string, Address>;
    addressesMapped: AccountAddressResponse;
    documentType: string;
    documentSrc: string;
    documentNumber: string;
    providerId?: string;
    pilotServiceId: string;
    cvlan: number;
    svlan: number;
    macAddress: string;
    deviceType: string;
    deviceModel: string;
}

interface SubscriberProperty {
    responsePath?: string;
    fallbackType?: keyof typeof PROPERTY_TYPE_VALIDATE_FN_MAP;
    defaultValue?: any;
    mapFn?(response: any): any;
    computeFn?(args: Partial<SubscriberConstructorArgs>): any;
}

interface SubscriberProperties {
    [key: string]: SubscriberProperty;
}

export interface SubscriberAddress {
    addressId: string;
    addressbookId: string;
    unitNumber: string;
    unitNo: string;
    unit: string;
    address: string;
    zip: string;
    city: string;
    taxAreaId: string;
    country: string;
    cityCode: string;
    email: string;
    addressLine2: string;
    streetName: string;
    province: string;
    provinceCode: string;
    barangay: string;
    barangayCode: string;
    houseNumber: string;
    postCode: string;
    postalCode: string;
    firstName: string;
    lastName: string;
    phoneNumber: string;
    post: string;
    state: string;
    number: string;
    area: string;
    village: string;
    alternatePhone: string;
    deliveryInstructions: string;
    lat: string;
    lng: string;
    googlePlaceId: string;
    type: number;
    stringAddressType: TranslateResult;
    latitude: string;
    longitude: string;
    provider: string;
    marketId: string;
    // gpfiber specific(atm)
    buildingName: string;
    floor: string;
    street: string;
    zipCode: string;
    eligibilityStatus?: TranslateResult;
    eligibilityStatusString: TranslateResult;
    eligibility?: number;
    locationId?: string;
}

interface AccountAddressResponse {
    [key: string]: {
        addressbook_id: string;
        unit_number: string;
        unit_no: string;
        unit: string;
        address: string;
        zip: string;
        city: string;
        tax_area_id: string;
        country: string;
        city_code: string;
        email: string;
        address_line_2: string;
        street_name: string;
        province: string;
        province_code: string;
        barangay: string;
        barangay_code: string;
        house_number: string;
        post_code: string;
        postal_code: string;
        first_name: string;
        last_name: string;
        phone_number: string;
        post: string;
        state: string;
        number: string;
        area: string;
        village: string;
        alternate_phone: string;
        delivery_instructions: string;
        lat: string;
        lng: string;
        google_place_id: string;
        type: number;
        stringAddressType: TranslateResult;
        latitude: string;
        longitude: string;
        provider_id: string;
        market_id: string;
        // gpfiber specific(atm)
        building_name: string;
        floor: string;
        street: string;
        zip_code: string;
        eligibility: number;
        external_params?: {
            external_id?: string;
        };
    };
}

const SUBSCRIBER_PROPERTIES: SubscriberProperties = {
    id: {
        responsePath: 'subscriber_id',
        fallbackType: 'string',
        defaultValue: '',
    },
    name: {
        responsePath: 'properties.first_name',
        fallbackType: 'string',
        defaultValue: null,
    },
    surname: {
        responsePath: 'properties.last_name',
        fallbackType: 'string',
        defaultValue: null,
    },
    fullName: {
        computeFn: function (args) {
            return (<any>this).validateString('fullName', args?.name) &&
                (<any>this).validateString('fullName', args?.surname)
                ? `${args?.name} ${args?.surname}`
                : '';
        },
    },
    state: {
        responsePath: 'state',
        fallbackType: 'integer',
        defaultValue: null,
    },
    activationDate: {
        responsePath: 'first_activation',
        computeFn: function (args) {
            return (<any>this).validateNumberInteger('activationDate', args?.activationDate)
                ? (localeLibrary.getFormattedDate(args?.activationDate || 0) as string)
                : '';
        },
        fallbackType: 'string',
        defaultValue: '',
    },
    creationDate: {
        responsePath: 'created',
        computeFn: ({ creationDate }) => creationDate && localeLibrary.getFormattedDateAndTime(creationDate),
        fallbackType: 'string',
        defaultValue: '',
    },
    subscriptionType: {
        responsePath: 'subscription_type',
        fallbackType: 'integer',
        defaultValue: 0,
    },
    subscriptionTypeString: {
        computeFn: args => {
            return Subscriber.mapSubscriptionType(args?.subscriptionType) as string;
        },
        fallbackType: 'string',
        defaultValue: '',
    },
    msisdn: {
        responsePath: 'msisdn',
        fallbackType: 'string',
        defaultValue: '',
    },
    imsi: {
        responsePath: 'imsis',
        computeFn: function (args) {
            return (<any>this).validateArray('imsi', args?.imsi) && args?.imsi?.length ? args?.imsi.join(', ') : '';
        },
        fallbackType: 'string',
        defaultValue: '',
    },
    iccid: {
        responsePath: 'iccids',
        computeFn: function (args) {
            return (<any>this).validateArray('iccid', args?.iccid) && args?.iccid?.length ? args?.iccid.join(', ') : '';
        },
        fallbackType: 'string',
        defaultValue: '',
    },
    username: {
        responsePath: 'properties.alias',
        fallbackType: 'string',
        defaultValue: '',
    },
    email: {
        responsePath: 'subscriber_email',
        fallbackType: 'string',
        defaultValue: '',
    },
    phone: {
        responsePath: 'alternative_phone',
        fallbackType: 'string',
        defaultValue: '',
    },
    flags: {
        responsePath: 'flags',
        mapFn: response => {
            return {
                ...response.flags,
                is_blocked: response.is_blocked ? FLAG_MAP.TRUE : FLAG_MAP.FALSE,
            };
        },
        computeFn: args => {
            return Flag.mapSubscriberFlags(args?.flags);
        },
    },
    accountId: {
        responsePath: 'primary_account_id',
        fallbackType: 'string',
        defaultValue: '',
    },
    userId: {
        responsePath: 'user_owner',
        fallbackType: 'string',
        defaultValue: '',
    },
    updateReason: {
        responsePath: 'properties.state_update_reason',
        fallbackType: 'string',
        defaultValue: '',
    },
    wps: {
        responsePath: 'properties.wps',
        fallbackType: 'string',
        defaultValue: '',
    },
    marketZip: {
        responsePath: 'market_zip',
        fallbackType: 'string',
        defaultValue: '',
    },
    cardinality: {
        responsePath: 'cardinality',
        fallbackType: 'integer',
        defaultValue: '',
    },
    addresses: {
        responsePath: 'addresses',
        mapFn: response => response.addresses || {},
    },
    addressesMapped: {
        responsePath: 'addresses',
        computeFn: function (this: any, args: any) {
            return this.mapAddresses(args?.addresses);
        },
    },
    documentType: {
        responsePath: 'properties.document_type',
        fallbackType: 'string',
        defaultValue: '',
    },
    documentSrc: {
        responsePath: 'properties.document_pic_url',
        fallbackType: 'string',
        defaultValue: '',
    },
    documentNumber: {
        responsePath: 'properties.document_number',
        fallbackType: 'string',
        defaultValue: '',
    },
    providerId: {
        responsePath: 'properties.provider_id',
        fallbackType: 'string',
        defaultValue: '',
    },
    pilotServiceId: {
        responsePath: 'properties.external_service_id',
        fallbackType: 'string',
        defaultValue: '',
    },
    cvlan: {
        responsePath: 'properties.cvlan',
        fallbackType: 'integer',
        defaultValue: '',
    },
    svlan: {
        responsePath: 'properties.svlan',
        fallbackType: 'integer',
        defaultValue: '',
    },
    macAddress: {
        responsePath: 'properties.mac_address',
        fallbackType: 'string',
        defaultValue: '',
    },
    deviceType: {
        responsePath: 'properties.device_type',
        fallbackType: 'string',
        defaultValue: '',
    },
    deviceModel: {
        responsePath: 'properties.device_model',
        fallbackType: 'string',
        defaultValue: '',
    },
};

export default class Subscriber extends UserManagerBaseModel {
    id!: string;
    fullName?: string;
    state?: number;
    activationDate?: string;
    creationDate?: string;
    cardinality?: number;
    subscriptionType?: number;
    subscriptionTypeString?: string;
    msisdn?: string;
    imsi?: string;
    iccid?: string;
    username?: string;
    email?: string;
    phone?: string;
    flags?: Flags;
    accountId?: string;
    userId?: string;
    updateReason?: string;
    umEntityType: USER_MANAGER_HIERARCHY.SUBSCRIBER;
    wps?: string; // mvne operator specific field
    marketZip?: string;
    addresses?: Record<string, Address>;
    addressesMapped?: SubscriberAddress[];
    documentType?: string;
    documentSrc?: string;
    documentNumber?: string;
    providerId?: string;
    pilotServiceId?: string;
    cvlan?: number | '';
    svlan?: number | '';
    macAddress?: string;
    deviceType?: string;
    deviceModel?: string;

    constructor(args: Partial<SubscriberConstructorArgs>) {
        // Call constructor of UserManagerBaseModel class for creating new object with unique invalidKeys Map
        super();
        for (const [key, props] of Object.entries(SUBSCRIBER_PROPERTIES)) {
            let value = args?.[key as keyof SubscriberConstructorArgs];
            if (props?.computeFn instanceof Function) {
                value = props.computeFn.call(this, args);
            }
            (<any>this)[key] = this.validateProperty(key, value, props);
        }
        this.umEntityType = USER_MANAGER_HIERARCHY.SUBSCRIBER;
    }

    validateProperty(key: string, value: any, props: SubscriberProperty) {
        // validate property value
        let propertyType = props?.fallbackType;
        const propertyKeyBe = props?.responsePath?.split('.').pop();
        if (propertyKeyBe) {
            // get property type from BE config
            const propertyTypeBe = getOperatorConfigValue(
                `service_config.lf-user.properties.subscriber_properties.${propertyKeyBe}`,
            );
            if (PROPERTY_TYPE_BE_MAP[propertyTypeBe as keyof typeof PROPERTY_TYPE_BE_MAP]) {
                propertyType = PROPERTY_TYPE_BE_MAP[propertyTypeBe as keyof typeof PROPERTY_TYPE_BE_MAP];
            }
        }
        const defaultValue = props?.defaultValue;
        // apply validation
        let validatedValue = value;
        if (propertyType && !this.validateByType(key, value, propertyType)) {
            validatedValue = defaultValue;
        }
        return validatedValue ?? defaultValue;
    }

    static mapSubscriptionType(type: number | undefined) {
        if (type === undefined || !UM_SUBSCRIPTION_TYPES_MAP.has(type)) {
            return '';
        }

        return UM_SUBSCRIPTION_TYPES_MAP.get(type);
    }

    static remapUserFromBe(response: any) {
        const mappedAccountObj: Record<string, any> = {};
        for (const [key, props] of Object.entries(SUBSCRIBER_PROPERTIES)) {
            const path = props?.responsePath;
            if (props?.mapFn) {
                mappedAccountObj[key] = props.mapFn(response);
            } else if (path) {
                mappedAccountObj[key] = get(response, path);
            }
        }
        return mappedAccountObj;
    }

    // @todo Move to separate model
    mapAddresses(addresses: AccountAddressResponse) {
        const mappedAddresses: SubscriberAddress[] = [];
        if (addresses && !isEmpty(addresses)) {
            for (const [addressId, address] of Object.entries(addresses)) {
                mappedAddresses.push({
                    addressId,
                    addressbookId: address.addressbook_id || '',
                    unitNumber: Object.prototype.hasOwnProperty.call(address, 'unit_number') ? address.unit_number : '',
                    unitNo: Object.prototype.hasOwnProperty.call(address, 'unit_no') ? address.unit_no : '',
                    unit: Object.prototype.hasOwnProperty.call(address, 'unit') ? address.unit : '',
                    address: address.address,
                    zip: address.zip,
                    city: address.city,
                    taxAreaId: address.tax_area_id,
                    country: Object.prototype.hasOwnProperty.call(address, 'country') ? address.country : '',
                    cityCode: Object.prototype.hasOwnProperty.call(address, 'city_code') ? address.city_code : '',
                    email: Object.prototype.hasOwnProperty.call(address, 'email') ? address.email : '',
                    addressLine2: Object.prototype.hasOwnProperty.call(address, 'address_line_2')
                        ? address.address_line_2
                        : '',
                    streetName: Object.prototype.hasOwnProperty.call(address, 'street_name') ? address.street_name : '',
                    province: Object.prototype.hasOwnProperty.call(address, 'province') ? address.province : '',
                    provinceCode: Object.prototype.hasOwnProperty.call(address, 'province_code')
                        ? address.province_code
                        : '',
                    barangay: Object.prototype.hasOwnProperty.call(address, 'barangay') ? address.barangay : '',
                    barangayCode: Object.prototype.hasOwnProperty.call(address, 'barangay_code')
                        ? address.barangay_code
                        : '',
                    houseNumber: Object.prototype.hasOwnProperty.call(address, 'house_number')
                        ? address.house_number
                        : '',
                    postCode: Object.prototype.hasOwnProperty.call(address, 'post_code') ? address.post_code : '',
                    postalCode: Object.prototype.hasOwnProperty.call(address, 'postal_code') ? address.postal_code : '',
                    firstName: Object.prototype.hasOwnProperty.call(address, 'first_name') ? address.first_name : '',
                    lastName: Object.prototype.hasOwnProperty.call(address, 'last_name') ? address.last_name : '',
                    phoneNumber: Object.prototype.hasOwnProperty.call(address, 'phone_number')
                        ? address.phone_number
                        : '',
                    post: Object.prototype.hasOwnProperty.call(address, 'post') ? address.post : '',
                    state: Object.prototype.hasOwnProperty.call(address, 'state') ? address.state : '',
                    number: Object.prototype.hasOwnProperty.call(address, 'number') ? address.number : '',
                    area: Object.prototype.hasOwnProperty.call(address, 'area') ? address.area : '',
                    village: Object.prototype.hasOwnProperty.call(address, 'village') ? address.village : '',
                    alternatePhone: Object.prototype.hasOwnProperty.call(address, 'alternate_phone')
                        ? address.alternate_phone
                        : '',
                    deliveryInstructions: Object.prototype.hasOwnProperty.call(address, 'delivery_instructions')
                        ? address.delivery_instructions
                        : '',
                    lat: Object.prototype.hasOwnProperty.call(address, 'lat') ? address.lat : '',
                    lng: Object.prototype.hasOwnProperty.call(address, 'lng') ? address.lng : '',
                    googlePlaceId: Object.prototype.hasOwnProperty.call(address, 'google_place_id')
                        ? address.google_place_id
                        : '',
                    type: address.type,
                    stringAddressType: Subscriber.addressTypeMap(address.type),
                    eligibilityStatusString: this.getEligibilityStatusString(),
                    eligibilityStatus: Subscriber.eligibilityStatusMap(address.eligibility),
                    eligibility: address.eligibility,
                    latitude: Object.prototype.hasOwnProperty.call(address, 'latitude') ? address.latitude : '',
                    longitude: Object.prototype.hasOwnProperty.call(address, 'longitude') ? address.longitude : '',
                    provider: Object.prototype.hasOwnProperty.call(address, 'provider_id') ? address.provider_id : '',
                    marketId: Object.prototype.hasOwnProperty.call(address, 'market_id') ? address.market_id : '',
                    // gpfiber specific(atm)
                    buildingName: Object.prototype.hasOwnProperty.call(address, 'building_name')
                        ? address.building_name
                        : '',
                    floor: Object.prototype.hasOwnProperty.call(address, 'floor') ? address.floor : '',
                    street: Object.prototype.hasOwnProperty.call(address, 'street') ? address.street : '',
                    zipCode: Object.prototype.hasOwnProperty.call(address, 'zip_code') ? address.zip_code : '',
                    locationId: Object.prototype.hasOwnProperty.call(address, 'external_params')
                        ? address?.external_params?.external_id
                        : '',
                });
            }
        }

        return mappedAddresses;
    }

    getEligibilityStatusString(): TranslateResult {
        return i18n.t('customerCare.account.eligibilityStatus');
    }

    static addressTypeMap(type: number) {
        const mapping: Record<number, TranslateResult> = {
            1: i18n.t('generic.address'),
            2: i18n.t('customerCare.addressMapper.billing'),
            3: i18n.t('customerCare.addressMapper.shipping'),
            4: i18n.t('customerCare.addressMapper.service'),
            5: i18n.t('customerCare.addressMapper.permanent'),
            6: i18n.t('customerCare.addressMapper.mailing'),
        };
        return mapping[type];
    }

    static eligibilityStatusMap(
        eligibility?: ADDRESS_ELIGIBILITY.ELIGIBLE | ADDRESS_ELIGIBILITY.PRE_ORDER,
    ): TranslateResult | undefined {
        const mapping: Record<ADDRESS_ELIGIBILITY.ELIGIBLE | ADDRESS_ELIGIBILITY.PRE_ORDER, TranslateResult> = {
            [ADDRESS_ELIGIBILITY.ELIGIBLE]: i18n.t('customerCare.account.eligible'),
            [ADDRESS_ELIGIBILITY.PRE_ORDER]: i18n.t('customerCare.account.preOrderEligible'),
        };
        return eligibility ? mapping[eligibility] : undefined;
    }
}
