
// Types
import { PropType } from 'vue';

// Components
import AppMultiselectV3 from '@/components/partials/inputs/AppMultiselectV3.vue';

// Helpers
import { TOOLTIP_POSITION } from '@/common/tooltip';

// HTTP
import { getPartyRoles } from '@/__new__/services/dno/partyRolesPermissionsManagement/http/partyRolesPermissionsManagement';

// Vuex
import { mapGetters, mapActions } from 'vuex';
import Actions, { Getters } from '@/store/mutation-types';

interface ChannelPartnerToCsgMapperPropsData {
    csgId: string;
    channelPartnerId: string;
}

export default {
    name: 'ChannelPartnerToChargingSpecGroupMapper',
    components: {
        AppMultiselectV3,
    },
    props: {
        value: {
            type: Object as PropType<ChannelPartnerToCsgMapperPropsData>,
            default: () => ({
                csgId: '',
                channelPartnerId: '',
            }),
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            TOOLTIP_POSITION,
            channelPartnerOptions: [],
            selectedChannelPartner: '',
            selectedCSG: '',
        };
    },
    computed: {
        ...mapGetters('charging', [Getters.GET_CHARGING_SPECIFICATIONS_GROUPS]),
        csgsListToDisplay() {
            return this[Getters.GET_CHARGING_SPECIFICATIONS_GROUPS]
                .filter((csg: any) => csg.channel_partner_id === this.selectedChannelPartner)
                .map((csg: any) => ({
                    id: csg.id,
                    name: csg.data.name.en,
                }));
        },
    },
    created() {
        Promise.all([this.fetchChannelPartners(), this[Actions.REQUEST_CHARGING_SPECIFICATIONS_GROUPS]()]);
    },
    methods: {
        ...mapActions('charging', [Actions.REQUEST_CHARGING_SPECIFICATIONS_GROUPS]),
        async fetchChannelPartners() {
            await this.$withLoadingSpinner(
                async () => {
                    const response = await getPartyRoles('ChannelPartner');

                    this.channelPartnerOptions = response?.data?.map((channelPartner: any) => ({
                        id: channelPartner?.id || '',
                        name: channelPartner?.name || '',
                        channelPartnerId: channelPartner?.channel_partner_id || '',
                    }));

                    [{ id: this.selectedChannelPartner }] = this.channelPartnerOptions;
                },
                {
                    errorHandler: () => {
                        this.$alert(this.$i18n.t('alertMessage.somethingWentWrongFetchingNecessaryData'));
                    },
                },
            );
        },
        emitValue() {
            this.$emit('input', {
                channel_partner_id: this.selectedChannelPartner,
                charging_spec_group_id: this.selectedCSG,
            });
        },
    },
};
