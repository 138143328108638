import { AccountAddress } from '@/__new__/services/dno/user/models/Account';
import { getOperatorConfigValue } from '@/services/permissions/permissions.service';
import { DnoResponseBase } from '@/http';

export enum ADDRESS_ELIGIBILITY {
    INELIGIBLE,
    ELIGIBLE,
    COMING_SOON,
    INSUFFICIENT_CRITERIA,
    SKIPPED,
    PRE_ORDER,
}

export enum ADDRESS_EXTERNAL_LOCATION_TYPE {
    READABLE,
    GOOGLE_PLACE_ID,
    ADDRESS_COMPONENTS,
    ETSI,
}

export enum ADDRESS_UNIT_TYPE {
    // Single Family Unit
    SFU = 1,
    // Multiple Dwelling Unit
    MDU = 2,
}

export const getOperatorEligibilityIds = (): string[] | undefined =>
    getOperatorConfigValue('service_config.lf-addressbook.eligibility_check.eligibility_types');

export interface SingleFamilyUnit {
    eligibility: ADDRESS_ELIGIBILITY;
    provider_id: string;
    addressbook_id: string;
    address_type: ADDRESS_UNIT_TYPE;
    address_details: any;
}

export interface MultipleDwelingUnit extends SingleFamilyUnit {
    unit_numbers: {
        [key: string]: {
            eligibility: ADDRESS_ELIGIBILITY;
        };
    };
}

export interface CheckEligibilityResponse extends DnoResponseBase {
    eligibility_check_id: string;
    eligibility_results: {
        [key: string]: SingleFamilyUnit & MultipleDwelingUnit;
    };
}

export function isAddressEligible(results: CheckEligibilityResponse, unitNo?: string): boolean {
    try {
        const eligibilityIds = getOperatorEligibilityIds();
        if (!eligibilityIds) {
            return false;
        }

        return eligibilityIds.some(eligibilityId => {
            const data = results.eligibility_results?.[eligibilityId];

            if (!data) {
                return false;
            }

            const { address_type: addressType, eligibility, unit_numbers: unitNumbers } = data;

            const addressEligibility: ADDRESS_ELIGIBILITY =
                addressType === ADDRESS_UNIT_TYPE.MDU && unitNo != null
                    ? unitNumbers[unitNo]?.eligibility
                    : eligibility;

            return addressEligibility === ADDRESS_ELIGIBILITY.ELIGIBLE;
        });
    } catch (error) {
        throw error;
    }
}

export type AddressbookEligibilityParams = {
    provider: string;
    addressbookId: string;
    unitNumber?: string;
};

export function getAddressbookParams({
    provider,
    addressbookId,
    unitNumber,
    unitNo,
    unit,
}: AccountAddress): AddressbookEligibilityParams {
    return {
        provider,
        addressbookId,
        unitNumber: unitNumber || unitNo || unit,
    };
}
