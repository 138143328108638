import http from '@/http/index';
import { type AxiosPromise } from 'axios';

/**
 * @returns {AxiosPromise}
 */
export function getPartyRoles(role: string): AxiosPromise<any> {
    return http({
        method: 'GET',
        url: '/v3partyrolespermissionsmanagement/getpartyroles',
        params: {
            role,
        },
    });
}
