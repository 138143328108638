<template>
    <div>
        <AbstractTableTile
            :entities="formattedEntities"
            :columnsData="columnsData"
            :apiResponse="rawResponse"
            :entityKey="'id'"
            @rowSelected="id => selectItem(id)"
        >
            <template #state="{ entity }">
                <EntityStatusIndicator
                    :stateMap="CHARGE_STATES_TO_STATUS_NAME_MAP"
                    :stateMapColor="CHARGE_STATES_INDICATOR_MAP"
                    :status="entity.state"
                    class="status-label"
                />
            </template>
            <template #customRowButtons="{ entity }">
                <template v-if="areInvoiceButtonsVisible(entity)">
                    <IconButton
                        :label="$i18n.t('generic.generateInvoice')"
                        :icon="ICON_TYPES.PLUS"
                        data-test-id="billing-tx-history-generate-invoice-btn"
                        @iconClick="onGenerateInvoiceButtonClicked(entity)"
                    />
                    <IconButton
                        :label="$i18n.t('generic.download')"
                        :icon="ICON_TYPES.DOWNLOAD"
                        data-test-id="billing-tx-history-download-btn"
                        @iconClick="onDownloadButtonClicked(entity)"
                    />
                </template>
                <div
                    v-if="isRefundButtonVisible(entity)"
                    class="d-flex align-items-center"
                >
                    <AppTooltip
                        v-if="!entity.canRefund"
                        :tooltipPosition="TOOLTIP_POSITION.left"
                    >
                        <template #label>
                            <div class="info-icon ml-3" />
                        </template>
                        <template #content>
                            {{ $i18n.t('customerCare.refundNotAllowedForTransaction') }}
                        </template>
                    </AppTooltip>

                    <IconButton
                        :label="$i18n.t('customerCare.paymentStatuses.refund')"
                        :icon="ICON_TYPES.REFUND"
                        :disabled="!entity.canRefund"
                        data-test-id="billing-tx-history-refund-btn"
                        @iconClick="showRefundEditor(entity)"
                    />
                </div>
            </template>
        </AbstractTableTile>
        <TransactionHistoryV4Sidebar
            :selectedTransactionHistory="selectedEntity"
            :showSidebar="showAdditionalSidebar"
            @showSidebarChange="showAdditionalSidebar = $event"
        />
        <RefundDialog
            v-if="modalVisible"
            :isVisible="modalVisible"
            :initTransactionData="transactionData"
            :nameOfTableForRefund="nameOfTableForRefund"
            @close="onCloseModal"
            @refetchTileData="fetchTileData"
        />
    </div>
</template>

<script>
// Components
import AbstractTableTile from '@/__new__/features/customerCareSuite/components/AbstractTableTile.vue';
import RefundDialog from '@/__new__/features/customerCareSuite/components/RefundDialog.vue';
import TransactionHistoryV4Sidebar from '@/__new__/features/customerCare/account/TransactionHistoryV4Sidebar.vue';
import EntityStatusIndicator from '@/components/partials/EntityStatusIndicator.vue';
import IconButton from '@/components/partials/IconButton.vue';
import AppTooltip from '@/components/partials/AppTooltip.vue';

// Helpers
import * as Sentry from '@sentry/vue';
import { ALERT_TYPES } from '@/common/alerts/Alert';
import supportButtonMixin from '@/components/alerts/supportButtonMixin';
import tableColumnType from '@/common/filterTable';
import { PAYMENT_TRANSACTION_TYPE } from '@/common/userManager/transactionHistoryV4Helper';
import { formatAmountBasedOnCurrency } from '@/common/formatting';
import {
    CHARGE_STATES,
    CHARGE_STATES_INDICATOR_MAP,
    CHARGE_STATES_TO_STATUS_NAME_MAP,
} from '@/common/userManager/transactionHistoryV4StatusHelper';
import { TransactionV4 } from '@/__new__/services/dno/orders/models/TransactionV4';
import { ICON_TYPES } from '@/common/iconHelper';
import luaErrors from '@/common/luaErrors';
import { TOOLTIP_POSITION } from '@/common/tooltip';
import { NAME_OF_TRANSACTION_HISTORY_TABLE } from '@/__new__/features/customerCare/common/transactionHistoryHelper';
import permissionsService, {
    isUserAllowed,
    isViewEnabled,
    hideTransactionsUnpaidCanceled,
} from '@/services/permissions/permissions.service';

// HTTP
import ordersHTTP from '@/__new__/services/dno/orders/http/orders';
import customerCareHTTP from '@/__new__/services/dno/user/http/customer-care';

export default {
    name: 'BillingTransactionTile',
    components: {
        AbstractTableTile,
        TransactionHistoryV4Sidebar,
        EntityStatusIndicator,
        IconButton,
        AppTooltip,
        RefundDialog,
    },
    mixins: [supportButtonMixin],
    props: {
        userManagerHierarchy: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            transactionHistories: [],
            rawResponse: {},
            selectedEntity: {},
            showAdditionalSidebar: false,
            modalVisible: false,
            transactionData: {},
            nameOfTableForRefund: NAME_OF_TRANSACTION_HISTORY_TABLE.BILLING,
            refundAmount: null,
            amountForRefund: 0,
            refundAllIsChecked: false,

            // permissions related stuff
            isRefundTransactionVisible:
                permissionsService.refundTransactionsVisibleEnabled() &&
                isViewEnabled('UMAccountBillingTransactionHistoryRefundVisible'),

            ICON_TYPES,
            CHARGE_STATES_INDICATOR_MAP,
            CHARGE_STATES_TO_STATUS_NAME_MAP,
            TOOLTIP_POSITION,
        };
    },
    computed: {
        formattedEntities() {
            let transactionsArray = [];
            if (this.transactionHistories?.length) {
                const filteredTransactionHistories = this.transactionHistories.filter(
                    transaction =>
                        transaction.paymentTransactionType === PAYMENT_TRANSACTION_TYPE.RESERVE ||
                        transaction.paymentTransactionType === PAYMENT_TRANSACTION_TYPE.DIRECT_DEBIT ||
                        (transaction.paymentTransactionType === PAYMENT_TRANSACTION_TYPE.REFUND &&
                            this.isRefundTransactionVisible),
                );

                transactionsArray = filteredTransactionHistories.map(transaction => ({
                    ...transaction,
                    refundAction: true,
                    amountFormatted: formatAmountBasedOnCurrency(transaction.amount, transaction.currency),
                    remainingBalance: formatAmountBasedOnCurrency(transaction.remainingBalance, transaction.currency),
                    state: transaction.partiallyRefunded ? CHARGE_STATES.PARTIALLY_REFUNDED : transaction.state,
                }));

                if (
                    !isUserAllowed('UMAccountBillingTransactionHistoryUnpaidCancelled') &&
                    hideTransactionsUnpaidCanceled()
                ) {
                    // filtering out UNPAID CANCELLED transactions
                    transactionsArray = transactionsArray.filter(t => t.state !== CHARGE_STATES.UNPAID_CANCELED);
                }
            }
            return transactionsArray;
        },
        columnsData() {
            return [
                {
                    name: this.$i18n.t('customerCare.transactionHistory.transactionId'),
                    key: 'id',
                    field: 'id',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('customerCare.transactionHistory.fileNumber'),
                    key: 'fileNumber',
                    field: 'fileNumber',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('customerCare.transactionHistory.timestamp'),
                    key: 'timestamp',
                    mapper: entity => {
                        if (entity) {
                            return this.$localeLibrary.getFormattedDateAndTimeWithSecondsPrecision(entity.timestamp);
                        }

                        return '';
                    },
                    sortBy: entity => entity.create_timestamp,
                    field: 'timestamp',
                    filterType: tableColumnType.DATE,
                },
                {
                    name: this.$i18n.t('customerCare.transactionHistory.amount'),
                    key: 'amountFormatted',
                    sortBy: entity => entity.amount,
                    field: 'amount',
                    filterType: tableColumnType.NUMBER,
                },
                {
                    name: this.$i18n.t('generic.status'),
                    key: 'state',
                    field: 'state',
                    filterType: tableColumnType.TEXT_LIMITED_OPTIONS,
                    limitedOptions: Array.from(new Set(this.formattedEntities.map(entity => entity.state))),
                },
            ];
        },
    },
    async created() {
        await this.fetchTileData();
    },
    methods: {
        async fetchTileData() {
            try {
                this.$Progress.start();
                this.$emit('isDataLoadingUpd', true);
                this.rawResponse = await ordersHTTP.getTransactions(
                    this.$route.params.id,
                    this.userManagerHierarchy,
                    'DESC',
                );
                this.transactionHistories = this.rawResponse?.data?.transactions.map(
                    transactionJSON => new TransactionV4(transactionJSON),
                );
                this.$Progress.finish();
            } catch (error) {
                Sentry.captureException(error);
                this.$Progress.fail();
                this.$emit('dataFetchingError');
            } finally {
                this.$emit('isDataLoadingUpd', false);
            }
        },
        async onDownloadButtonClicked(entity) {
            const { id } = entity;
            this.$Progress.start();
            try {
                const response = await customerCareHTTP.downloadInvoice(
                    this.$route.params.id,
                    this.userManagerHierarchy,
                    id,
                );

                if (response.data.pdf_link) {
                    window.open(response.data.pdf_link, '_blank');
                }

                this.$Progress.finish();
            } catch (error) {
                if (error.response?.data.code === luaErrors.BILLING_V4.FILE_NOT_FOUND_ON_S3.code) {
                    this.$eventBus.$emit('showAlert', {
                        message: this.$i18n.t('customerCare.billingStatementDownloadNotFoundInvoice'),
                        type: ALERT_TYPES.warning,
                    });
                } else {
                    this.$eventBus.$emit('showAlert', {
                        message: this.$i18n.t('customerCare.billingStatementDownloadError'),
                    });
                }
                this.$Progress.fail();
            }
        },
        async onGenerateInvoiceButtonClicked(entity) {
            const { id } = entity;
            this.$Progress.start();
            try {
                await customerCareHTTP.generateFile(this.$route.params.id, this.userManagerHierarchy, id);

                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('customerCare.generateInvoiceSuccessMessage'),
                    type: ALERT_TYPES.success,
                });

                this.$Progress.finish();
            } catch (error) {
                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('customerCare.generateInvoiceFailedMessage'),
                });
                this.$Progress.fail();
            }
        },
        selectItem(id) {
            this.selectedEntity = this.formattedEntities.find(el => el.id === id);
            this.showAdditionalSidebar = true;
        },
        showRefundEditor(data) {
            this.modalVisible = true;
            this.transactionData = data;
        },
        onCloseModal() {
            this.modalVisible = false;
        },
        // permissions related stuff
        areInvoiceButtonsVisible(entity) {
            return (
                permissionsService.billingTransactionInvoicesEnabled() &&
                isUserAllowed('UMAccountBillingTransactionHistoryDownloadInvoice') &&
                entity.transactionHasInvoiceButtons
            );
        },
        isRefundButtonVisible(entity) {
            return (
                permissionsService.billingTransactionRefundEnabled() &&
                isUserAllowed('UMAccountBillingTransactionHistoryIssueRefund') &&
                entity.refundAction
            );
        },
    },
};
</script>

<style lang="scss" scoped>
@import 'src/assets/scss/colors';
@import 'src/assets/scss/palette';
@import 'src/assets/scss/typographyV2';
@import 'src/assets/scss/layout';

.new-note-btn {
    width: 6.875rem;
}

.new-note-wrapper {
    display: flex;
    flex-direction: column;

    .new-note {
        padding: 2rem 10rem 4rem;
    }
}

section {
    .content {
        padding: 2rem 0;

        p {
            margin: 0;
            color: $gray90;
        }
    }
}

.custom-footer {
    padding: 1.5rem 9.5rem;
    border-top: solid 0.0625rem rgba($color: $blue, $alpha: 0.15);
}
.background-color {
    background-color: $dirty-white;
}

.section-layout {
    margin: 0 1.5rem 1.5rem 1.5rem;
}

.modal-title {
    font-size: 1.375rem;
    font-weight: 600;
    margin-bottom: 2.1875rem;
}

.modal-row {
    margin-bottom: 0.875rem;
    font-size: 0.875rem;
}

.row-title {
    font-weight: 600;
    flex: 0 0 7.875rem;
}

.row-value {
    flex: 1 1 auto;
}

.status-label {
    font-size: 0.875rem;
    width: 100%;
}
</style>
