import moment from 'moment';

import graphsHttp from './http/graph';
import store from '@/store/store';
import localeLibrary from '@/common/locale/localeLibrary';

// lodash library
import groupBy from 'lodash/groupBy';
import keyBy from 'lodash/keyBy';

export default {
    async getMetricsList() {
        const res = await graphsHttp.fetchMetricsList();
        // TODO: Error handling

        // Mapping data from prometheus to our data formats.
        const mappedMetrics = keyBy(res.data, 'metric');

        // Storing them in Vuex
        store.dispatch('graphs/updateMetricsList', { metrics: mappedMetrics });

        return mappedMetrics;
    },

    /**
     *
     * Here we are performing simple array transponding and switching changing array structure to format that C3 needs.
     *
     * Input:
     * [
     *   {
     *     "metric": {
     *       "name": "fetch_customer",
     *       "status": "success"
     *     },
     *     "values": [
     *       [ 1650384669, 0 ],
     *       [ 1650388269, 1 ]
     *     ]
     *   },
     *   {
     *     "metric": {
     *       "name": "fetch_payment_nonce",
     *       "status": "success"
     *     },
     *     "values": [
     *       [ 1650384669, 0 ],
     *       [ 1650388269, 1 ]
     *     ]
     *   }
     * ]
     *
     * Output:
     * [
     *   ['x', timestamp1, timestamp2, timestamp3, timestamp4],
     *   ['fetch_customer', 'value123', 'value123', 'value123', 'value123' ],
     *   ['fetch_payment_nonce', 'value123', 'value123', 'value123', 'value123' ],
     * ];
     *
     */
    mapPrometheusToC3(rawData) {
        // Merge all datapoints tuples from all metrics into a single array.
        // We'll subsequently group them by timestamp. This is done to avoid
        // duplicate timestamps when we construct the parallel arrays.
        //
        const dataPoints = rawData
            .map(({ metric, values }) => {
                const { name, status } = metric;
                return values.map(([timestamp, value]) => ({
                    name,
                    status,
                    timestamp,
                    value,
                }));
            })
            .flat();

        const dataPointsGroupedByTimestamp = groupBy(dataPoints, dp => dp.timestamp);

        // Get the ordered list of timestamps.
        //
        const timestamps = Object.keys(dataPointsGroupedByTimestamp).sort();

        // Produce the timestamp column. The 'x' label is required as this is used to
        // configure the X axies in LineGraph.vue.
        //
        const columns = {
            x: ['x', ...timestamps.map(x => moment.unix(x).utc().format(`${localeLibrary.getDateFormat()} HH:mm:ss`))],
        };

        // Produce our parallel arrays -- one array per series.
        //
        const metrics = rawData.map(x => x.metric);
        for (const timestamp of timestamps) {
            for (const metric of metrics) {
                const metricLabel = Object.values(metric).join(' - ');
                if (!columns[metricLabel]) {
                    columns[metricLabel] = [metricLabel];
                }

                const dataPoint = dataPointsGroupedByTimestamp[timestamp].find(
                    dp => dp.name === metric.name && dp.status === metric.status,
                );

                if (dataPoint) {
                    columns[metricLabel].push(dataPoint.value);
                } else {
                    columns[metricLabel].push(null);
                }
            }
        }

        return Object.values(columns);
    },

    async fetchAndBuildGraph(metric) {
        const response = await graphsHttp.fetchMetric(metric.metric);
        return this.mapPrometheusToC3(response.data.data.result);
    },
};
