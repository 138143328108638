<template>
    <div
        v-click-outside="closeDropdown"
        class="dropdown-menu-wrapper"
    >
        <AppButton
            :buttonType="BUTTON_TYPES.PRIMARY"
            :label="label"
            :iconType="iconType"
            @click="displayDropdown = !displayDropdown"
        />

        <div
            v-if="displayDropdown"
            class="dropdown-menu m-0 p-0 rounded-0"
        >
            <span
                v-for="(item, index) in items"
                :key="index"
                class="dropdown-item"
                :data-test-id="`app-button-dropdown-item-${index}`"
                @mousedown="selectItem(item)"
            >
                <slot
                    :item="{ item: item, label: getItemLabel(item) }"
                    name="item"
                    class="item-slot"
                    :data-test-id="`app-button-dropdown-item-${index}-slot`"
                >
                    {{ getItemLabel(item) }}
                </slot>
            </span>
        </div>
    </div>
</template>

<script>
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import { ICON_TYPES } from '@/common/iconHelper';
import vClickOutside from 'v-click-outside';

export default {
    name: 'AppButtonV2Dropdown',
    components: {
        AppButton,
    },
    directives: {
        clickOutside: vClickOutside.directive,
    },
    props: {
        label: {
            type: String,
            required: false,
            default: '',
        },
        iconType: {
            type: String,
            required: false,
            default: ICON_TYPES.PLUS,
        },
        items: {
            type: Array,
            default: () => [],
        },
        displayProperty: {
            type: String,
            default: 'label',
        },
    },
    data() {
        return {
            displayDropdown: false,
            BUTTON_TYPES,
        };
    },
    methods: {
        closeDropdown() {
            this.displayDropdown = false;
        },
        selectItem(item) {
            this.closeDropdown();
            this.$emit('selected', item);
        },
        getItemLabel(item) {
            return this.displayProperty ? item[this.displayProperty] : item;
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/palette';
@import '~@/assets/scss/icons';
@import '~@/assets/scss/layout';
@import '~@/assets/scss/z-indexes';

.dropdown-menu-wrapper {
    position: relative;

    .dropdown-menu {
        display: block;
        z-index: $header-dropdowns-z-index;
        position: absolute;
        right: 0;
        top: 3rem;
        min-width: fit-content;
        width: 100%;
        border: none;
        box-shadow: 0 0.0625rem 0.125rem 0 $gray30;

        max-height: 15rem;
        overflow-x: hidden;

        // scroll bar styling only works in webkit
        &::-webkit-scrollbar {
            width: 0.625rem;
            background-color: $dirty-white;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 0.3125rem;
            background-color: $gray30;
        }

        .dropdown-item {
            padding: 0 2rem 0 1rem;
            height: 2.5rem;
            width: 100%;
            line-height: 2.5rem;

            background: right 0.375rem center no-repeat;
            overflow: hidden;
            text-overflow: ellipsis;

            cursor: pointer;
            font-size: 0.875rem;
            color: $navy;

            &:hover {
                background-color: $gray5;
            }
        }
    }
}
</style>
