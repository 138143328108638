import http, { DnoResponseBase, Entity } from '@/http/index';
import { AxiosPromise } from 'axios';

type PolicySpecificationData = {
    name: string;
    description: string;
} & Record<string, any>;

interface GetPolicySpecificationsResponseBody extends DnoResponseBase {
    policy_specification_by_id: Record<string, Entity<PolicySpecificationData>>;
}

/**
 * get policySpecifications
 *
 * @returns {AxiosPromise}
 */
export function getPolicySpecifications(ids: string[] = []): AxiosPromise<GetPolicySpecificationsResponseBody> {
    return http({
        method: 'POST',
        url: '/privacy/getPolicySpecifications',
        headers: {
            'X-Requested-With': 'XMLHttpRequest',
        },
        data: {
            ids,
        },
    });
}

export type AddPolicySpecificationRequestBody = {
    data: PolicySpecificationData;
    template_id: string;
    entity_id?: string;
};

/**
 * Add policySpecification.
 *
 * @returns {AxiosPromise}
 */
export function addPolicySpecification(data: AddPolicySpecificationRequestBody): AxiosPromise<DnoResponseBase> {
    return http({
        method: 'POST',
        url: '/privacy/addPolicySpecification',
        headers: {
            'X-Requested-With': 'XMLHttpRequest',
        },
        data,
    });
}

/**
 * Update policySpecification state.
 *
 * @returns {AxiosPromise}
 */
export function updatePolicySpecificationState(
    id: string,
    version: number,
    state: number,
): AxiosPromise<DnoResponseBase> {
    return http({
        method: 'POST',
        url: '/privacy/updatePolicySpecificationState',
        headers: {
            'X-Requested-With': 'XMLHttpRequest',
        },
        data: {
            id,
            version,
            state,
        },
    });
}

/**
 * Update policySpecification.
 *
 * @returns {AxiosPromise}
 */
export function updatePolicySpecification(
    id: string,
    version: number,
    data: PolicySpecificationData,
): AxiosPromise<DnoResponseBase> {
    return http({
        method: 'POST',
        url: '/privacy/updatePolicySpecification',
        headers: {
            'X-Requested-With': 'XMLHttpRequest',
        },
        data: {
            id,
            version,
            data,
        },
    });
}

/**
 * Get policySpecification draft list.
 *
 * @returns {AxiosPromise}
 */
export function getPolicySpecificationDraftList() {
    return http({
        method: 'POST',
        url: '/privacy/getPolicySpecificationDraftList',
    });
}

/**
 * Delete policySpecification draft.
 *
 * @returns {AxiosPromise}
 */
export function deletePolicySpecificationDraft(id: string) {
    return http({
        method: 'POST',
        url: '/privacy/policySpecificationDraftDelete',
        data: {
            id,
        },
    });
}

/**
 * Get policySpecification draft.
 *
 * @returns {AxiosPromise}
 */
export function getPolicySpecificationDraft(id: string) {
    return http({
        method: 'POST',
        url: '/privacy/getPolicySpecificationDraft',
        data: {
            id,
        },
    });
}

/**
 * Set policySpecification draft.
 *
 * @returns {AxiosPromise}
 */
type PolicySpecificationDraftPayload = {
    id?: string;
    data: object;
    template_id: string;
};

export function setPolicySpecificationDraft(data: PolicySpecificationDraftPayload) {
    return http({
        method: 'POST',
        url: '/privacy/setPolicySpecificationDraft',
        data,
    });
}

export default {
    getPolicySpecifications,
    addPolicySpecification,
    updatePolicySpecificationState,
    updatePolicySpecification,
    getPolicySpecificationDraftList,
    deletePolicySpecificationDraft,
    getPolicySpecificationDraft,
    setPolicySpecificationDraft,
};
