<template>
    <div class="editor-wrapper">
        <div class="editor-header">
            <slot name="header" />
        </div>
        <div
            v-if="$slots.stepper"
            class="stepper-wrap"
        >
            <slot name="stepper" />
        </div>

        <div
            class="editor-content-wrapper"
            :class="{
                'sidebar-position-left': sidebarEnabled && sidebarPosition === EDITOR_PAGE_SIDEBAR_POSITION.LEFT,
                'stepper-enabled': $slots.stepper,
                'no-sidebar-layout': !sidebarEnabled,
            }"
        >
            <div
                class="form-content-wrapper"
                :class="{ 'sidebar-enabled': sidebarEnabled }"
            >
                <div class="form-content">
                    <slot name="content" />
                </div>
            </div>
            <div
                v-if="sidebarEnabled"
                class="sidebar-content"
                :style="{ width: sidebarWidth + '%' }"
            >
                <slot name="sideBar" />
            </div>
        </div>

        <!-- slot for page controls (cancel, save buttons, etc) -->
        <div
            v-if="$slots.controls"
            class="editor-controls-wrapper"
            :class="{ 'sidebar-enabled': sidebarEnabled && !$slots.stepper }"
        >
            <div class="editor-controls-content">
                <slot name="controls" />
            </div>
        </div>

        <!-- slot for any modal you want on the page -->
        <div v-if="$slots.modal">
            <slot name="modal" />
        </div>

        <div
            v-if="userGuideArticleId"
            class="user-guide-icon-wrapper"
        >
            <IconButton
                :label="$i18n.t('userGuide.userGuide')"
                :icon="ICON_TYPES.INFO"
                :iconSize="'24px'"
                @iconClick="goToUserGuideArticle"
            />
        </div>
    </div>
</template>

<script>
// components
import IconButton from '@/components/partials/IconButton.vue';

// helpers
import RouteNames from '@/router/routeNames';
import { ICON_TYPES } from '@/common/iconHelper';

export const EDITOR_PAGE_SIDEBAR_POSITION = {
    LEFT: 'left',
    RIGHT: 'right',
};

export default {
    name: 'AbstractEditPageWrapper',
    components: {
        IconButton,
    },
    props: {
        shouldDisplaySidebar: {
            default: true,
            type: Boolean,
        },
        sidebarPosition: {
            default: EDITOR_PAGE_SIDEBAR_POSITION.RIGHT,
            type: String,
        },
        sidebarWidth: {
            type: Number,
            default: 30,
            validator: val => val > 0 && val < 100,
        },
        userGuideArticleId: {
            type: Number,
            default: null,
        },
    },
    data() {
        return {
            EDITOR_PAGE_SIDEBAR_POSITION,
            ICON_TYPES,
        };
    },
    computed: {
        sidebarEnabled() {
            return Boolean(this.$slots.sideBar) && this.shouldDisplaySidebar;
        },
    },
    methods: {
        goToUserGuideArticle() {
            this.$router.push({
                name: RouteNames.USER_GUIDE_ARTICLE,
                params: { id: this.userGuideArticleId, companyId: this.$route.params.companyId },
            });
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/_palette';
@import '~@/assets/scss/_layout';
@import '~@/assets/scss/_sidebar.scss';
@import '~@/assets/scss/_animations.scss';
@import '~@/assets/scss/z-indexes';

.editor-wrapper {
    position: relative;
    padding: 0;
    height: 100vh;
}

.editor-content-wrapper {
    display: flex;
    justify-content: center;
    height: calc(100% - 158px);

    &.sidebar-position-left {
        flex-direction: row-reverse;
    }

    &.stepper-enabled {
        padding-top: calc(50px + 1.5rem);
    }

    &.no-sidebar-layout {
        display: block;

        .form-content-wrapper {
            width: 100%;
            padding-left: 15%;
            padding-right: 15%;
        }
    }
}

.editor-controls-wrapper {
    position: sticky;
    z-index: $page-layout-item-z-index;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    height: 5.56rem;
    padding: 1.5rem 0;
    border-top: 1px solid $blue15;
    background-color: $white;

    transform: translateZ(0);

    &.sidebar-enabled {
        justify-content: flex-start;

        .editor-controls-content {
            padding-right: $spacing-l;
        }
    }

    .editor-controls-content {
        display: flex;
        justify-content: flex-end;
        overflow: visible;
    }
}

.form-content-wrapper,
.editor-controls-content {
    width: 70%;
    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
}

.form-content-wrapper {
    display: flex;
    justify-content: center;
    padding-top: 2.2rem;
    margin-bottom: 1.5rem;

    &.sidebar-enabled {
        padding-left: $spacing-l;
        padding-right: $spacing-l;
    }

    .form-content {
        width: 100%;
    }
}

.sidebar-content {
    height: 100%;
    overflow-y: auto;
    box-shadow: $box-shadow-default;
    order: 1;
}
.stepper-wrap {
    padding: 0.5rem 0;
    top: 4.25rem;
    position: fixed;
    background: #eceef1;
    width: calc(100% - #{$sidebarFullWidth});
    transition: width $medium-animation-time;
    z-index: $page-layout-item-z-index;
}
// global class which indicates that sidebar is collapsed
.page-content.compact-sidebar {
    .stepper-wrap {
        width: calc(100% - #{$sidebarCompactWidth});
    }
}

.user-guide-icon-wrapper {
    position: absolute;
    bottom: 1.5rem;
    right: 1.5rem;
    z-index: $tooltip-z-index;
}
</style>
