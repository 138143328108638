<template>
    <AbstractListPageWrapper
        :isOverviewEnabled="isOverviewEnabled"
        :pageTitle="pageTitle"
        :entitiesCount="formattedFilteredEntities.length"
        @searchQueryChanged="setSearchQuery"
    >
        <template #button>
            <ResponseModalButton
                :response="partyPrivacyProfilesApiResponse"
                :title="pageTitle"
            />
        </template>

        <template #filterTable>
            <FilterTable
                :columns="tableColumnsData"
                :multiselectWidth="{ width: '15rem' }"
                @filterAdded="onFilterAdded"
            />
        </template>

        <template #allFilters>
            <TableFiltersTags
                :filterTableMixin="filterTableMixin"
                class="my-3 ml-2"
                @removeFilter="removeFilter"
                @removeAllFilters="removeAllFilters"
            />
        </template>

        <template #table>
            <AppTable
                data-testid="app-table"
                :entities="formattedFilteredEntities"
                :canSelectColumns="true"
                :canSort="true"
                :selectedEntityId="selectedEntityId"
                :innerSearchQuery="searchQueryForTable"
                :isSearchEnabled="true"
                :isInnerSearchQueryPassed="false"
                :enableRowStateControls="false"
                :entityType="entityType"
                :columnsData="tableColumnsData"
                :isDataLoading="isDataLoading"
                tableKey="partyPrivacyProfile"
                @selectEntity="onEntitySelected"
            >
                <template #status="{ entity }">
                    <div class="d-flex">
                        <AppLabel
                            :title="entity.status"
                            :color="colorConverter(entity.status)"
                        />
                    </div>
                </template>
                <template #entityVersion="{ entity }">
                    <EntityStatusIndicator :status="entity.entityVersion" />
                </template>

                <template
                    v-if="writeEnabled"
                    #customRowButtons="{ entity }"
                >
                    <IconButton
                        v-if="canRevoke(entity)"
                        :label="$i18n.t('consent.partyPrivacyProfileLocale.revoke')"
                        :icon="ICON_TYPES.REDO"
                        @iconClick="revokeProfile(entity)"
                    />
                </template>
            </AppTable>
        </template>

        <template #overview>
            <EntityOverview
                :entity="selectedEntity"
                :entityType="entityType"
                @closeOverview="isOverviewEnabled = false"
            >
                <template #section-1-content>
                    <div style="margin-bottom: 1rem">
                        <AppOverviewBlock
                            :items="detailsSection"
                            :isRowTitleCounterVisible="false"
                            :maxItems="7"
                        />
                    </div>
                    <div style="margin-bottom: 1rem">
                        <AppOverviewBlock
                            :title="$t('consent.predicates')"
                            :items="predicatesSection"
                            :isRowTitleCounterVisible="false"
                        />
                    </div>
                </template>
            </EntityOverview>
        </template>
    </AbstractListPageWrapper>
</template>

<script>
import { getPartyPrivacyProfile, revokePartyPrivacyProfile } from '@/__new__/services/dno/privacyConsent/http/consent';
import { ALERT_TYPES } from '@/common/alerts/Alert';
import Button from '@/common/button/Button';
import { getMultiLangFieldValueByLocale } from '@/common/entities/entityHelper';
import ENTITY_TYPES from '@/common/entities/entityTypes';
import tableColumnType from '@/common/filterTable';
import { ICON_TYPES } from '@/common/iconHelper';
import { LABEL_COLOR } from '@/common/labelsHelper';
import TableFiltersTags from '@/components/filters/TableFiltersTags.vue';
import AbstractListPageWrapper from '@/components/layout/AbstractListPageWrapper.vue';
import AppLabel from '@/components/partials/AppLabel.vue';
import AppOverviewBlock from '@/components/partials/AppOverviewBlock.vue';
import AppTable from '@/components/partials/AppTable.vue';
import EntityStatusIndicator from '@/components/partials/EntityStatusIndicator.vue';
import FilterTable from '@/components/partials/FilterTable.vue';
import FilterTableMixin from '@/components/partials/FilterTableMixin.vue';
import IconButton from '@/components/partials/IconButton.vue';
import ResponseModalButton from '@/components/partials/ResponseModalButton.vue';
import EntityOverview from '@/components/partials/entityOverview/EntityOverview.vue';
import { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import { isUserAllowed } from '@/services/permissions/permissions.service';
import { Getters } from '@/store/mutation-types';
import { mapGetters } from 'vuex';
import { PartyPrivacyProfileStatus } from './enum';

const STATUS_COLOR_MAP = new Map([
    [PartyPrivacyProfileStatus.AGREED, LABEL_COLOR.green],
    [PartyPrivacyProfileStatus.REVOKED, LABEL_COLOR.red],
]);

export default {
    name: 'PartyPrivacyProfile',
    components: {
        AbstractListPageWrapper,
        AppTable,
        EntityOverview,
        AppLabel,
        FilterTable,
        TableFiltersTags,
        ResponseModalButton,
        EntityStatusIndicator,
        IconButton,
        AppOverviewBlock,
    },
    mixins: [FilterTableMixin],
    data() {
        return {
            pageTitle: this.$t('consent.partyPrivacyProfileLocale.pageTitle'),
            searchQueryForTable: '',
            selectedLanguage: '',
            selectedEntityId: null,
            selectedEntity: null,
            entityType: ENTITY_TYPES.PARTY_PRIVACY_PROFILE,
            ICON_TYPES,
            BUTTON_TYPES,
            consentPartyPrivacyProfilesApiResponse: {},
            consentPartyPrivacyProfiles: [],
            isDataLoading: false,
            getMultiLangFieldValueByLocale,
            isOverviewEnabled: false,
            alertButtons: {
                confirmButton: new Button({
                    label: this.$t('generic.confirm'),
                    alertType: ALERT_TYPES.warning,
                }),
            },
        };
    },
    computed: {
        ...mapGetters('operators', [Getters.languageDefault]),
        colorConverter() {
            return status => STATUS_COLOR_MAP.get(status);
        },
        partyPrivacyProfiles() {
            return this.consentPartyPrivacyProfiles;
        },
        partyPrivacyProfilesApiResponse() {
            return this.consentPartyPrivacyProfilesApiResponse;
        },
        formattedEntities() {
            return this.partyPrivacyProfiles.map(entity => {
                return {
                    ...entity,
                    status: entity.status ? entity.status.charAt(0).toUpperCase() + entity.status.slice(1) : '',
                    partyPrivacyProfileSpecificationName: entity.partyPrivacyProfileSpecification?.name,
                    agreedByPartyName: entity.agreedByParty?.partyOrPartyRole?.name,
                    applicationId: entity.applicableForParty?.partyOrPartyRole?.id,
                    applicationName: entity.applicableForParty?.partyOrPartyRole?.name,
                    createOn: new Date(entity.createOn).getTime(),
                    lastUpdate: new Date(entity.lastUpdate).getTime(),
                };
            });
        },
        formattedEntitiesMap() {
            return this.formattedEntities.reduce((acc, item) => {
                acc[item.id] = {
                    ...item,
                    partyPrivacyProfileSpecificationName: item.partyPrivacyProfileSpecification?.name,
                    agreedByPartyName: item.agreedByParty?.partyOrPartyRole?.name,
                };
                return acc;
            }, {});
        },
        formattedFilteredEntities() {
            return this.filteredEntitiesMixin(this.formattedEntities);
        },
        generalDetails() {
            const detailsArray = [
                {
                    name: this.$t('generic.general'),
                    isCollapsed: false,
                    rows: [
                        {
                            name: this.$t('generic.id'),
                            value: this.selectedEntity?.id,
                        },
                        {
                            name: this.$t('generic.status'),
                            value: this.selectedEntity?.status,
                        },
                        {
                            name: this.$t('consent.agreedOn'),
                            value: this.$localeLibrary.getFormattedDateAndTime(this.selectedEntity?.creationDate),
                        },
                        {
                            name: this.$t('consent.partyPrivacyProfileLocale.channel'),
                            value: this.selectedEntity?.channel,
                        },
                        {
                            name: this.$t('consent.partyPrivacyProfileLocale.clientIp'),
                            value: this.selectedEntity?.clientIp,
                        },
                        {
                            name: this.$t('consent.createOn'),
                            value: this.$localeLibrary.getFormattedDateAndTime(this.selectedEntity?.createOn),
                        },
                        {
                            name: this.$t('consent.lastUpdate'),
                            value: this.$localeLibrary.getFormattedDateAndTime(this.selectedEntity?.lastUpdate),
                        },
                    ],
                },
                {
                    name: this.$t('consent.partyPrivacyProfileLocale.validFor'),
                    isCollapsed: false,
                    rows: [
                        {
                            name: 'startDateTime',
                            value: this.$localeLibrary.getFormattedDateAndTime(
                                new Date(this.selectedEntity?.validFor?.startDateTime).getTime(),
                            ),
                        },
                        {
                            name: 'endDateTime',
                            value: this.$localeLibrary.getFormattedDateAndTime(
                                new Date(this.selectedEntity?.validFor?.endDateTime).getTime(),
                            ),
                        },
                    ],
                },
                {
                    name: this.$t('consent.partyPrivacyProfileLocale.agreedByParty'),
                    isCollapsed: false,
                    rows: [
                        {
                            name: this.$t('generic.id'),
                            value: this.selectedEntity?.agreedByParty?.partyOrPartyRole?.id,
                        },
                        {
                            name: this.$t('generic.name'),
                            value: this.selectedEntity?.agreedByParty?.partyOrPartyRole?.name,
                        },
                        {
                            name: this.$t('generic.role'),
                            value: this.selectedEntity?.agreedByParty?.role,
                        },
                    ],
                },
                {
                    name: this.$t('consent.partyPrivacyProfileLocale.profileSpecification'),
                    isCollapsed: false,
                    rows: [
                        {
                            name: this.$t('generic.id'),
                            value: this.selectedEntity?.partyPrivacyProfileSpecification?.id,
                        },
                        {
                            name: this.$t('generic.name'),
                            value: this.selectedEntity?.partyPrivacyProfileSpecification?.name,
                        },
                        {
                            name: this.$t('consent.version'),
                            value: this.selectedEntity?.partyPrivacyProfileSpecification?.version,
                        },
                    ],
                },
                {
                    name: this.$t('consent.application'),
                    isCollapsed: false,
                    rows: [
                        {
                            name: this.$t('generic.id'),
                            value: this.selectedEntity?.applicableForParty?.partyOrPartyRole?.id,
                        },
                        {
                            name: this.$t('generic.name'),
                            value: this.selectedEntity?.applicableForParty?.partyOrPartyRole?.name,
                        },
                    ],
                },
            ];
            if (this.selectedEntity?.requestHeaders) {
                const requestHeadersDetail = {
                    name: this.$t('consent.partyPrivacyProfileLocale.requestHeaders'),
                    isCollapsed: false,
                    rows: [],
                };
                const sortedRequestHeaders = Object.entries(this.selectedEntity?.requestHeaders)
                    .map(([key, value]) => ({ [key]: value }))
                    .sort((a, b) => Object.values(a)[0].length - Object.values(b)[0].length);
                sortedRequestHeaders.forEach(requestHeader => {
                    requestHeadersDetail.rows.push({
                        name: Object.keys(requestHeader)[0],
                        value: Object.values(requestHeader)[0],
                    });
                });
                detailsArray.push(requestHeadersDetail);
            }
            if (this.selectedEntity?.customAttributes) {
                const customAttributesDetail = {
                    name: this.$t('consent.partyPrivacyProfileLocale.customAttributes'),
                    isCollapsed: false,
                    rows: [],
                };
                const sortedCustomAttributes = Object.entries(this.selectedEntity?.customAttributes)
                    .sort(([, valueA], [, valueB]) => {
                        const lengthA =
                            typeof valueA === 'object' ? JSON.stringify(valueA).length : String(valueA).length;
                        const lengthB =
                            typeof valueB === 'object' ? JSON.stringify(valueB).length : String(valueB).length;
                        return lengthA - lengthB;
                    })
                    .map(([key, value]) => ({ [key]: value }));
                sortedCustomAttributes.forEach(customAttribute => {
                    customAttributesDetail.rows.push({
                        name: Object.keys(customAttribute)[0],
                        value: Object.values(customAttribute)[0],
                    });
                });
                detailsArray.push(customAttributesDetail);
            }
            return detailsArray;
        },
        predicates() {
            return this.selectedEntity?.predicates && this.selectedEntity?.predicates.length > 0
                ? this.selectedEntity?.predicates.map(predicate => {
                      return {
                          name: predicate?.name ? predicate.name : '',
                          isCollapsed: false,
                          rows: [
                              {
                                  name: this.$t('consent.purpose'),
                                  value: predicate?.purpose ? predicate.purpose.join(',') : '',
                              },
                              {
                                  name: this.$t('consent.scope'),
                                  value: predicate?.scope ? predicate.scope.join(',') : '',
                              },
                              {
                                  name: this.$t('consent.personalData'),
                                  value: predicate?.pd ? predicate.pd.join(',') : '',
                              },
                              {
                                  name: this.$t('consent.legalBasis'),
                                  value: predicate?.legalBasis ? predicate.legalBasis.join(',') : '',
                              },
                              {
                                  name: this.$t('consent.consentCheck'),
                                  value: predicate?.consentCheck,
                              },
                              {
                                  name: this.$t('consent.optoutCheck'),
                                  value: predicate?.optoutCheck,
                              },
                              {
                                  name: this.$t('consent.partyPrivacyProfileLocale.optionOut'),
                                  value: predicate?.optout,
                              },
                          ],
                      };
                  })
                : [];
        },

        detailsSection() {
            return this.selectedEntityId ? this.generalDetails : [];
        },
        predicatesSection() {
            return this.selectedEntityId ? this.predicates : [];
        },
        tableColumnsData() {
            const columns = [
                {
                    name: this.$t('consent.partyPrivacyProfileLocale.partyPrivacyProfileSpecification'),
                    key: 'partyPrivacyProfileSpecificationName',
                    field: 'partyPrivacyProfileSpecificationName',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$t('consent.partyPrivacyProfileLocale.agreedByParty'),
                    key: 'agreedByPartyName',
                    field: 'agreedByPartyName',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$t('consent.partyPrivacyProfileLocale.applicationName'),
                    key: 'applicationName',
                    field: 'applicationName',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$t('consent.partyPrivacyProfileLocale.channel'),
                    key: 'channel',
                    field: 'channel',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$t('consent.partyPrivacyProfileLocale.status'),
                    key: 'status',
                    field: 'status',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$t('consent.createOn'),
                    key: 'createOn',
                    mapper: entity => this.$localeLibrary.getFormattedDateAndTime(entity.createOn),
                    sortBy: entity => entity.createOn,
                    field: 'createOn',
                    filterType: tableColumnType.DATE,
                },
                {
                    name: this.$t('consent.lastUpdate'),
                    key: 'lastUpdate',
                    mapper: entity => this.$localeLibrary.getFormattedDateAndTime(entity.lastUpdate),
                    sortBy: entity => entity.lastUpdate,
                    field: 'lastUpdate',
                    filterType: tableColumnType.DATE,
                },
            ];
            return columns;
        },
        writeEnabled() {
            return isUserAllowed('PartyPrivacyProfileWrite');
        },
    },
    async created() {
        this.isDataLoading = true;
        await this.$withProgressBar(
            async () => {
                await this.fetchData();
                this.selectedLanguage = this[Getters.languageDefault];
                this.isDataLoading = false;
            },
            {
                errorHandler: () => {
                    this.isDataLoading = false;
                    this.$alert(this.$t('alertMessage.failedToLoadNecessaryData'), {
                        type: ALERT_TYPES.error,
                    });
                },
            },
        );
    },
    methods: {
        async fetchData() {
            this.consentPartyPrivacyProfilesApiResponse = (await getPartyPrivacyProfile()) || {};
            this.consentPartyPrivacyProfiles = this.consentPartyPrivacyProfilesApiResponse?.data || [];
        },
        onEntitySelected(id) {
            this.selectedEntity = this.formattedEntitiesMap[id];
            this.selectedEntityId = id;
            this.isOverviewEnabled = true;
        },
        revokeProfile(entity) {
            this.$alert(this.$t('alerts.areYouSureRevoke'), {
                type: this.$ALERT_TYPES.warning,
                buttons: [this.alertButtons.confirmButton],
            });
            this.$eventBus.$once('buttonClicked', async buttonId => {
                if (buttonId === this.alertButtons.confirmButton.id) {
                    this.isDataLoading = true;
                    await this.$withProgressBar(
                        async () => {
                            await revokePartyPrivacyProfile(entity.id);
                            await this.fetchData();
                            this.selectedLanguage = this[Getters.languageDefault];
                            this.isDataLoading = false;
                            this.$showSuccessAlert({
                                message: this.$t('consent.partyPrivacyProfileLocale.revokeSuccessfully'),
                            });
                        },
                        {
                            errorHandler: () => {
                                this.isDataLoading = false;
                                this.$alert(this.$t('entityStatesControl.couldNotRevoke'), {
                                    type: ALERT_TYPES.error,
                                });
                            },
                        },
                    );
                }
            });
        },
        setSearchQuery(query) {
            this.searchQueryForTable = query;
        },
        canRevoke(entity) {
            return !entity.canNotBeRevoked && entity.status !== PartyPrivacyProfileStatus.REVOKED;
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep table .icon-button-container:last-child {
    margin-right: 0.5rem;
}
</style>
