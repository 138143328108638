import i18n from '@/i18n';

export const CONDITIONS_ENUM = {
    IS: 'is',
    IS_NOT: 'isNot',
    IN: 'in',
    NOT_IN: 'notIn',
};

export const CONDITIONS_MAP = {
    [CONDITIONS_ENUM.IS]: {
        id: CONDITIONS_ENUM.IS,
        label: i18n.t('charging.chargingSpecifications.editor.IS'),
    },
    [CONDITIONS_ENUM.IS_NOT]: {
        id: CONDITIONS_ENUM.IS_NOT,
        label: i18n.t('charging.chargingSpecifications.editor.ISNOT'),
    },
    [CONDITIONS_ENUM.IN]: {
        id: CONDITIONS_ENUM.IN,
        label: i18n.t('charging.chargingSpecifications.editor.IN'),
    },
    [CONDITIONS_ENUM.NOT_IN]: {
        id: CONDITIONS_ENUM.NOT_IN,
        label: i18n.t('charging.chargingSpecifications.editor.NOTIN'),
    },
};

export const IS_IS_NOT_OPTIONS = [CONDITIONS_MAP[CONDITIONS_ENUM.IS], CONDITIONS_MAP[CONDITIONS_ENUM.IS_NOT]];

export const CONDITIONS_OPTIONS = Object.values(CONDITIONS_MAP);

export const CONDITION_TO_INVERTED_MAP = new Map([
    [CONDITIONS_ENUM.IS, false],
    [CONDITIONS_ENUM.IS_NOT, true],
    [CONDITIONS_ENUM.IN, false],
    [CONDITIONS_ENUM.NOT_IN, true],
]);

export const BOOLEAN_TO_CONDITION = new Map([
    [false, CONDITIONS_ENUM.IS],
    [true, CONDITIONS_ENUM.IS_NOT],
]);

export default {
    CONDITIONS_ENUM,
    CONDITIONS_MAP,
    CONDITIONS_OPTIONS,
    IS_IS_NOT_OPTIONS,
    CONDITION_TO_INVERTED_MAP,
    BOOLEAN_TO_CONDITION,
};
