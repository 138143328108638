<template>
    <AbstractEditPageWrapper>
        <template #header>
            <AppHeader :pageTitle="pageTitle" />
        </template>

        <template #content>
            <div class="pb-5 px-2">
                <SegmentDescription
                    ref="segmentDescription"
                    :name="segmentName"
                    :description="segmentDescription"
                    :idType="segmentIdType"
                    :idTypeOptions="segmentIdTypeOptions"
                    data-test-id="segment-description"
                    @onNameChanged="segmentName = $event"
                    @onDescriptionChanged="segmentDescription = $event"
                    @onIdTypeChanged="onIdTypeChanged"
                />

                <AppToggle
                    v-model="produceEventsEnterLeave"
                    :label="$i18n.t('segments.produceEventsEnterLeave')"
                    :small="true"
                    class="mt-4"
                    data-test-id="produceEventsEnterLeaveToggle"
                />

                <div v-if="isDynamicGenerationType">
                    <Filters
                        class="mt-4"
                        :filtersIcon="filtersIcon"
                        :stats="stats"
                        :selectFilterInvalid="selectFilterInvalid"
                        :conditionInstancesJson="initialFiltersJson"
                        :conditionDefinitionsById="filterConditionDefinitionsById"
                        @updatedConditionInstancesJson="updatedConditionInstancesJson"
                        @onConditionsValidationError="onConditionsValidationError"
                    />

                    <SegmentSummary
                        :filters="updatedFiltersJson"
                        :stats="getSegmentStats"
                        :applyFilters="applyFilters"
                        @click:apply="updateStats"
                    />
                </div>

                <div v-else-if="isTriggerBasedGenerationType">
                    <SegmentTriggerBasedFiltersComponent
                        class="mt-4"
                        :title="$i18n.t('segments.segmentAddTo')"
                        :selectFilterInvalid="selectFilterInvalid"
                        :conditionInstancesJson="initialAddConditionsJson"
                        :conditionDefinitionsById="triggerConditionDefinitionsById"
                        @updatedConditionInstancesJson="updatedAddConditionInstancesJson"
                        @onConditionsValidationError="onAddConditionsValidationError"
                    />

                    <SegmentTriggerBasedFiltersComponent
                        class="mt-4"
                        :title="$i18n.t('segments.segmentRemoveFrom')"
                        :conditionInstancesJson="initialRemoveConditionsJson"
                        :conditionDefinitionsById="triggerConditionDefinitionsById"
                        @updatedConditionInstancesJson="updatedRemoveConditionInstancesJson"
                        @onConditionsValidationError="onRemoveConditionsValidationError"
                    />
                </div>

                <div
                    v-if="isShowStatsBadge"
                    class="segment-statistics"
                >
                    <SegmentsBadge
                        :label="statsTile.label"
                        :icon="statsTile.largeIcon"
                        :value="statsTile.stats.value"
                    />
                </div>
            </div>
        </template>

        <template #controls>
            <AppButton
                :label="$i18n.t('generic.cancel')"
                class="mr-5"
                @click="onCancel"
            />
            <div class="save-btn">
                <AppButton
                    :buttonType="BUTTON_TYPES.PRIMARY"
                    :label="$i18n.t('generic.save')"
                    :iconType="ICON_TYPES.CHECK"
                    @click="saveSegment"
                />
            </div>
        </template>
    </AbstractEditPageWrapper>
</template>

<script>
/* helpers */
import { createNamespacedHelpers } from 'vuex';
import { last, clone, intersection, omit } from 'lodash';
import ENTITY_TYPES from '@/common/entities/entityTypes';
import { ICON_TYPES } from '@/common/iconHelper';
import { Modules } from '@/store/store';
import Actions, { State } from '@/store/mutation-types';
import entityEditorMixin from '@/common/entityEditorMixin';
import permissionsService, { isViewEnabled } from '@/services/permissions/permissions.service';
import RouteNames from '@/router/routeNames';
import * as Sentry from '@sentry/vue';

/* models */
import {
    SEGMENT_SUPPORTED_ID_TYPES_BY_VALUES,
    ID_TYPES_ICONS,
    SEGMENT_SUPPORTED_ID_TYPES_BY_KEYS,
    GENERATION_TYPES_BY_KEYS,
    LIST_ALLOW_TYPES_FOR_GET_REGISTERED_IDS_COUNT,
} from '@/common/segments';
import { SEGMENT_ID_TYPES } from '@/common/StaticFilter';
import SegmentStatistics from '@/__new__/services/dno/segments/models/SegmentStatistics';
import { debouncedPreapplyFilters, getRegisteredIdsCount } from '@/__new__/services/dno/segments/http/segments';
/* components */
import AppHeader from '@/components/layout/AppHeader.vue';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import AbstractEditPageWrapper from '@/components/layout/AbstractEditPageWrapper.vue';
import SegmentSummary from '@/__new__/features/segments/SegmentSummary.vue';
import SegmentDescription from '@/__new__/features/segments/dynamic/SegmentDescription.vue';
import Filters from '@/components/partials/filters/Filters.vue';
import SegmentTriggerBasedFiltersComponent from '@/__new__/features/segments/SegmentTriggerBasedFiltersComponent.vue';
import SegmentsBadge from '../SegmentsBadge.vue';
import AppToggle from '@/components/partials/inputs/AppToggle.vue';

const triggersHelpers = createNamespacedHelpers(Modules.triggers);
const { mapState, mapActions } = createNamespacedHelpers(Modules.segments);

export default {
    name: 'AbstractSegmentPage',
    components: {
        AppToggle,
        SegmentTriggerBasedFiltersComponent,
        AppHeader,
        AppButton,
        Filters,
        SegmentSummary,
        AbstractEditPageWrapper,
        SegmentDescription,
        SegmentsBadge,
    },
    mixins: [entityEditorMixin],
    data() {
        return {
            ICON_TYPES,
            BUTTON_TYPES,
            produceEventsEnterLeave: false,
            segmentName: null, // override
            segmentDescription: null,
            generationType: null,
            segmentIdType: null,
            stats: [],
            operatorStats: SegmentStatistics.empty(),
            selectFilterInvalid: false,
            entityType: ENTITY_TYPES.SEGMENT,
            isSegmentValid: true,
            saveInProcess: false,
            initialFiltersJson: [],
            updatedFiltersJson: [],
            initialAddConditionsJson: [],
            updatedAddConditionsJson: [],
            initialRemoveConditionsJson: [],
            updatedRemoveConditionsJson: [],
            conditionsValidationError: null,
            addConditionsValidationError: null,
            removeConditionsValidationError: null,
            applyFilters: {
                visible: false,
                progress: false,
            },
        };
    },
    computed: {
        ...mapState([State.CACHED_SEGMENTS, State.FILTER_DEFINITIONS_BY_ID]),
        ...triggersHelpers.mapState([State.TRIGGER_DEFINITIONS_BY_ID]),
        triggerConditionDefinitionsById() {
            return this[State.TRIGGER_DEFINITIONS_BY_ID];
        },
        filterConditionDefinitionsById() {
            return this[State.FILTER_DEFINITIONS_BY_ID];
        },
        filtersIcon() {
            return ID_TYPES_ICONS[this.segmentIdType]?.smallIcon;
        },
        segmentIdTypeOptions() {
            const segmentsTypesEnabled = Object.keys(SEGMENT_ID_TYPES)
                .filter(type => {
                    const viewName = this.isDynamicGenerationType
                        ? `DynamicSegmentsType${type}`
                        : `TriggerBasedSegmentsType${type}`;
                    return isViewEnabled(viewName);
                })
                .map(type => SEGMENT_ID_TYPES[type]);
            return intersection(permissionsService.getSegmentIdTypes(), segmentsTypesEnabled).map(
                idType => SEGMENT_SUPPORTED_ID_TYPES_BY_VALUES[idType],
            );
        },
        // overall segment stats
        getSegmentStats() {
            return this.stats.length ? clone(last(this.stats)) : this.operatorStats;
        },
        statsTile() {
            return {
                ...ID_TYPES_ICONS[this.segmentIdType],
                stats: last(this.getSegmentStats.buildStaticsComponents()),
            };
        },
        emptyStats() {
            return SegmentStatistics.empty();
        },
        isTriggerBasedGenerationType() {
            return this.generationType === GENERATION_TYPES_BY_KEYS.TRIGGER_BASED;
        },
        isDynamicGenerationType() {
            return this.generationType === GENERATION_TYPES_BY_KEYS.DYNAMIC;
        },
        isAllowGetRegisteredIdsCount() {
            const segmentType = SEGMENT_SUPPORTED_ID_TYPES_BY_KEYS[this.segmentIdType];

            return LIST_ALLOW_TYPES_FOR_GET_REGISTERED_IDS_COUNT.includes(segmentType);
        },
        isShowStatsBadge() {
            return (
                this.isTriggerBasedGenerationType ||
                this.isAllowGetRegisteredIdsCount ||
                !!this.updatedFiltersJson.length
            );
        },
    },
    methods: {
        ...mapActions([Actions.LOAD_FILTER_DEFINITIONS, Actions.FETCH_SEGMENT_STATISTICS]),
        ...triggersHelpers.mapActions([Actions.LOAD_TRIGGER_DEFINITIONS]),
        validateSegment(segment) {
            try {
                segment.validate();
                const error = this.validateSegments();
                if (error) throw new Error(error);
                this.isSegmentValid = true;
            } catch (e) {
                this.$Progress.fail();
                this.$eventBus.$emit('showAlert', {
                    message: e.message,
                });
                if (e.message === this.$i18n.t('segments.alerts.chooseAtLeastOneFilter')) {
                    this.selectFilterInvalid = true;
                }
                this.isSegmentValid = false;
            }
        },
        onIdTypeChanged(type) {
            if (type === null) return;
            this.segmentIdType = type;
            this.fetchAndSetOperatorStats();
            this.updateStats();
        },
        saveSegment() {
            throw new Error('Not implemented');
        }, // override5
        updateStats(immediately = false) {
            this.selectFilterInvalid = false;

            const validationError = this.validateSegments();
            if (!validationError) {
                this.$eventBus.$emit('closeAlertIfError');
                this.stats = [];
                this.applyFilters.progress = true;
                debouncedPreapplyFilters(
                    this.updatedFiltersJson.map(f => omit(f, 'filterDefinitionId')),
                    this.setStatsFromResponse,
                    err => {
                        Sentry.captureException(err);
                        this.$alert(this.$t('segments.alerts.failedToLoadPreapply'));
                    },
                    SEGMENT_SUPPORTED_ID_TYPES_BY_KEYS[this.segmentIdType],
                );
                if (immediately) debouncedPreapplyFilters.flush();
            } else {
                this.stats = [];
                this.$alert(validationError);
            }
        },
        setStatsFromResponse(response) {
            this.applyFilters.progress = false;
            this.applyFilters.visible = false;
            this.stats = response.data.Stepwise
                ? response.data.Stepwise.values.map(statsJson =>
                      SegmentStatistics.fromJson(statsJson[1]).setTotal(this.operatorStats.filtered),
                  )
                : [new SegmentStatistics(response.data.Total.count.filtered)];
        },
        validateSegments() {
            let validationError = '';

            if (this.isDynamicGenerationType) {
                validationError = this.conditionsValidationError;
                // todo enable
                // if (checkFilterBlocksDuplicates(this.filtersV1AndV2)) {
                //     validationError = this.$i18n.t('alertMessage.cep.errorDuplicateFilterBlocks');
                // }
            } else if (this.isTriggerBasedGenerationType) {
                const triggersAddToSegmentErrors = this.addConditionsValidationError;
                const triggersRemoveFromSegmentErrors = this.removeConditionsValidationError;

                validationError = [triggersAddToSegmentErrors, triggersRemoveFromSegmentErrors].find(e => e);

                // todo enable
                // if (checkFilterBlocksDuplicates(this.triggersAddToSegment)) {
                //     validationError = this.$i18n.t('alertMessage.cep.errorDuplicateFilterBlocks');
                // }
                // if (checkFilterBlocksDuplicates(this.triggersRemoveFromSegment)) {
                //     validationError = this.$i18n.t('alertMessage.cep.errorDuplicateFilterBlocks');
                // }
            }

            return validationError;
        },
        async fetchAndSetOperatorStats() {
            this.operatorStats = SegmentStatistics.empty();
            this.stats = [];

            let idsCount;

            if (this.isAllowGetRegisteredIdsCount) {
                const resp = await getRegisteredIdsCount(SEGMENT_SUPPORTED_ID_TYPES_BY_KEYS[this.segmentIdType]);

                idsCount = resp.data.count;
            }

            const cohortCount = this.isTriggerBasedGenerationType ? 0 : idsCount;

            this.operatorStats = SegmentStatistics.fromCohortCountJson({ count: cohortCount }).setTotal(idsCount);
        },
        navigateToSegmentsListPageWithTimeout() {
            setTimeout(() => {
                this.saveInProcess = false;
                this.$router.push({
                    name: RouteNames.SEGMENTS_LIST,
                    params: { companyId: this.$route.params.companyId },
                });
            }, 2000);
        },
        updatedConditionInstancesJson(conditionInstancesJson) {
            this.updatedFiltersJson = conditionInstancesJson;
            this.applyFilters.visible = true;
            this.applyFilters.progress = false;
        },
        updatedAddConditionInstancesJson(conditionInstancesJson) {
            this.updatedAddConditionsJson = conditionInstancesJson;
        },
        updatedRemoveConditionInstancesJson(conditionInstancesJson) {
            this.updatedRemoveConditionsJson = conditionInstancesJson;
        },
        onConditionsValidationError(conditionsValidationError) {
            this.conditionsValidationError = conditionsValidationError;
        },
        onAddConditionsValidationError(conditionsValidationError) {
            this.addConditionsValidationError = conditionsValidationError;
        },
        onRemoveConditionsValidationError(conditionsValidationError) {
            this.removeConditionsValidationError = conditionsValidationError;
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/_consistency.scss';
.segment-statistics {
    margin-top: $spacing-m;
    &__icons {
        margin-top: 3rem;
    }
    &__togglers {
        margin-top: $spacing-xxxl;
    }
}
</style>
