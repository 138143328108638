<template>
    <div
        data-test-id="account-order-details"
        :class="{ 'shrink-width': sidebarState }"
    >
        <TableFiltersRenderless :entities="ordersList">
            <template
                #default="{
                    // state
                    allFilters,
                    filteredEntities,
                    // methods
                    onFilterAdded,
                    removeFilter,
                    removeAllFilters,
                }"
            >
                <div>
                    <div class="d-flex align-items-center justify-content-between mb-2">
                        <div
                            v-show="title"
                            class="d-flex component-title-um"
                        >
                            {{ title }}
                        </div>
                        <div class="d-flex">
                            <SearchBox
                                v-model="searchQuery"
                                class="table-search-box"
                                :smallSearchIcon="true"
                            />
                            <ResponseModalButton
                                :response="apiResponse"
                                :title="title"
                            />
                            <div class="filters-control position-relative">
                                <FilterTable
                                    :columns="omdeOrdersColumnsData"
                                    :multiselectWidth="{ width: '12rem' }"
                                    @filterAdded="onFilterAdded"
                                >
                                    <template #button="slotProps">
                                        <IconButton
                                            :label="$i18n.t('generic.filter')"
                                            :icon="ICON_TYPES.FILTER"
                                            @iconClick="slotProps.clickHandler"
                                        />
                                    </template>
                                </FilterTable>
                            </div>

                            <div class="d-flex align-items-center">
                                <div class="section-toggle-label mr-3">
                                    {{ $i18n.t('customerCare.userInformation.activeRecords') }}
                                </div>
                                <AppToggle
                                    v-model="showOnlyActiveOrders"
                                    :small="true"
                                    customId="order-active-toggle"
                                />
                            </div>
                        </div>
                    </div>

                    <TableFiltersTags
                        :filterTableMixin="{ allFilters }"
                        class="mt-1 mb-3"
                        @removeFilter="removeFilter"
                        @removeAllFilters="removeAllFilters"
                    />

                    <AppTable
                        :entities="filteredEntities"
                        :innerSearchQuery="searchQuery"
                        :isSearchEnabled="true"
                        :isDefaultHoverEnabled="true"
                        :isCustomButtonPassed="true"
                        :disableBoxShadow="true"
                        :defaultSort="defaultSort"
                        :canSelectColumns="true"
                        :newDesign="true"
                        :enableRowStateControls="false"
                        :columnsData="omdeOrdersColumnsData"
                        :entityType="ENTITY_TYPES.ORDER"
                        tableKey="user/account-order"
                        keyName="orderId"
                        @selectedEntityModel="o => [selectOrder(o), toggleModal(true, sectionKeys.ORDER_DETAILS)]"
                    >
                        <template #status="{ entity }">
                            <EntityStatusIndicator
                                v-if="!isUndefined(entity.state)"
                                :stateMap="ORDER_ENTITY_STATES_TO_STATUS_NAME_MAP"
                                :stateMapColor="ORDER_STATUS_INDICATOR_MAP"
                                :status="getOrderStatus(entity)"
                                :isInTable="true"
                            />

                            <i v-else>{{ $i18n.t('generic.empty') }}</i>
                        </template>
                        <template #createTimestamp="{ entity }">
                            {{ $localeLibrary.getFormattedDate(entity.createTimestamp) }}
                        </template>
                    </AppTable>
                </div>
            </template>
        </TableFiltersRenderless>
        <AdditionalSidebarOrdersEntities
            :selectedParentOrder="selectedParentOrder"
            :showAdditionalSidebar="showAdditionalSidebar"
            @closeSidebar="toggleModal(false, sectionKeys.ORDER_DETAILS)"
        />
    </div>
</template>

<script>
// COMPONENTS
import AppTable from '@/components/partials/AppTable.vue';
import EntityStatusIndicator from '@/components/partials/EntityStatusIndicator.vue';
import AppToggle from '@/components/partials/inputs/AppToggle.vue';
import SearchBox from '@/components/partials/inputs/SearchBox.vue';
import IconButton from '@/components/partials/IconButton.vue';
import { ICON_TYPES } from '@/common/iconHelper';
import TableFiltersRenderless from '@/components/filters/TableFiltersRenderless.vue';
import TableFiltersTags from '@/components/filters/TableFiltersTags.vue';
import FilterTable from '@/components/partials/FilterTable.vue';
import AdditionalSidebarOrdersEntities from '@/__new__/features/customerCare/AdditionalSidebarOrdersEntities.vue';
import ResponseModalButton from '@/components/partials/ResponseModalButton.vue';

// HELPERS
import {
    ORDER_ENTITY_STATES_TO_STATUS_NAME_MAP,
    ORDER_STATUS_INDICATOR_MAP,
    ORDER_ENTITY_STATES,
} from '@/__new__/features/customerCare/common/orderStateHelper';
import { isUndefined } from 'lodash';
import { getMultiLangFieldValueByLocale } from '@/common/entities/entityHelper';
import tableColumnType from '@/common/filterTable';
import RouteNames from '@/router/routeNames';
import ENTITY_TYPES from '@/common/entities/entityTypes';
import AccountSidebarMixin, { sectionKeys } from '@/__new__/features/customerCare/mixins/AccountSidebarMixin.vue';

export default {
    name: 'OrdersWrapperOMDE',
    components: {
        AppTable,
        EntityStatusIndicator,
        AppToggle,
        SearchBox,
        IconButton,
        TableFiltersRenderless,
        TableFiltersTags,
        FilterTable,
        AdditionalSidebarOrdersEntities,
        ResponseModalButton,
    },

    mixins: [AccountSidebarMixin],

    props: {
        orders: {
            type: Object,
            required: true,
        },
        apiResponse: {
            type: Object,
            default: null,
        },
        title: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            ORDER_ENTITY_STATES_TO_STATUS_NAME_MAP,
            ORDER_STATUS_INDICATOR_MAP,
            ORDER_ENTITY_STATES,
            showOnlyActiveOrders: false,
            searchQuery: '',
            ICON_TYPES,
            RouteNames,
            ENTITY_TYPES,
            selectedParentOrder: null,
            defaultSort: {
                key: 'createTimestamp',
                sortBy: entity => entity.createTimestamp,
                type: 'desc',
            },
        };
    },

    computed: {
        showAdditionalSidebar() {
            return this.getAdditionalSidebarStateBySectionKey(sectionKeys.ORDER_DETAILS);
        },
        omdeOrdersColumnsData() {
            return [
                {
                    name: this.$i18n.t('customerCare.orderHistory.orderId'),
                    key: 'orderId',
                    forbidHideColumn: true,
                    field: 'orderId',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('customerCare.orderHistory.offer'),
                    key: 'offer',
                    forbidHideColumn: true,
                    field: 'offer',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('customerCare.orderHistory.orderDate'),
                    key: 'createTimestamp',
                    field: 'createTimestamp',
                    filterType: tableColumnType.DATE,
                },
                {
                    name: this.$i18n.t('customerCare.imei'),
                    key: 'imei',
                    field: 'imei',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('customerCare.iccid'),
                    key: 'iccid',
                    field: 'iccid',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('generic.status'),
                    key: 'status',
                    classes: ['overflow-visible-all'],
                    field: 'status',
                    filterType: tableColumnType.TEXT_LIMITED_OPTIONS,
                    limitedOptions: Array.from(new Set(this.ordersList.map(entity => entity.status))),
                },
            ];
        },
        ordersList() {
            if (Object.values(this.orders).length) {
                return Object.values(this.orders).filter(formattedOrder => {
                    if (this.showOnlyActiveOrders) {
                        return formattedOrder.state === ORDER_ENTITY_STATES.ACTIVE;
                    }

                    return true;
                });
            }
            return [];
        },
    },
    methods: {
        getMultiLangFieldValueByLocale,
        isUndefined,
        selectOrder(order) {
            this.selectedParentOrder = this.ordersList.find(entity => entity.orderId === order.orderId);
        },
        getOrderStatus(order) {
            return order.replaceInfo && order.state === ORDER_ENTITY_STATES.ACTIVE
                ? ORDER_ENTITY_STATES.ACTIVE_PLAN_CHANGE
                : order.state;
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/base';
@import '~@/assets/scss/palette';

.table-search-box {
    width: 15rem;
}
</style>
