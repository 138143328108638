import { USER_MANAGER_HIERARCHY } from '@/__new__/features/customerCare/common/customerCareHelper';
import Account from '@/__new__/services/dno/user/models/Account';
import User from '@/__new__/services/dno/user/models/User';
import Subscriber from '@/__new__/services/dno/user/models/Subscriber';
import { AccInfoInOrgInstance } from '@/__new__/services/dno/user/models/Organization';
import { formatAddressLines } from '@/common/formatting';

export interface HierarchyItem {
    id: string;
    type: number;
    children: HierarchyItem[];
    name: string;
    state: number | null;
    msisdn: string | null;
    email: string | null;
    address: string | null | undefined;
    parentId: string | null;
}

export function mapUMEntityToHierarhyItem(
    umEntity: User | Account | Subscriber | AccInfoInOrgInstance,
    hierarchyEnum: USER_MANAGER_HIERARCHY,
    parentId: string | null,
): HierarchyItem {
    const id = umEntity.id || '';
    return {
        id: id,
        type: hierarchyEnum,
        children: [],
        name: umEntity?.fullName || id,
        state: umEntity instanceof User || !umEntity?.state ? null : umEntity.state,
        msisdn: umEntity instanceof Subscriber && umEntity?.msisdn ? umEntity.msisdn : null,
        email: umEntity instanceof User && umEntity?.notificationEmail ? umEntity?.notificationEmail : null,
        address:
            umEntity instanceof Subscriber && umEntity?.addressesMapped
                ? formatAddressLines(umEntity?.addressesMapped[0])?.lines?.join(', ')
                : null,
        parentId,
    };
}

export default {
    mapUMEntityToHierarhyItem,
};
