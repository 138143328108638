<template>
    <OperateApisListPageLayout
        :pageTitle="$i18n.tc('operateAPIs.applicationOwners', PLURALIZATION.PLURAL)"
        :entityType="OPERATE_APIS_TYPES.APPLICATION_OWNER"
        :entityResponse="applicationOwnersApiResponse"
        :entityData="entityData"
        :addButtonLabel="$i18n.t('operateAPIs.addNewApplicationOwner')"
        :writePermission="isUserAllowed('OperateAPIsApplicationOwnersWrite')"
        :addNewRouteName="addNewRouteName"
        @onSelectEntity="onSelectEntity"
        @onViewEntity="onViewEntity"
        @onEditEntity="onEditEntity"
        @onSelectPartyRole="onSelectPartyRole"
    >
        <template #sidebarOverview>
            <ApplicationOwnerOverview :applicationOwner="selectedApiOwner" />
        </template>
    </OperateApisListPageLayout>
</template>

<script>
// Components
import OperateApisListPageLayout from '@/__new__/features/operateAPIs/OperateApisListPageLayout.vue';
import ApplicationOwnerOverview from '@/__new__/features/operateAPIs/applicationOwners/ApplicationOwnerOverview.vue';
// Http
import { getApplicationOwners, updateApplicationOwner } from '@/__new__/services/dno/operateAPIs/http/operateAPIs';

// Helpers
import { isUserAllowed } from '@/services/permissions/permissions.service';
import RouteNames from '@/router/routeNames';
import { PLURALIZATION } from '@/common/locale/labelSingularOrPlural';
import { OPERATE_APIS_TYPES } from '@/__new__/features/operateAPIs/common/operateAPIsHelper';
import { mapApplicationOwnerToFeData } from '@/__new__/features/operateAPIs/common/ApplicationOwner';

export default {
    name: 'ApplicationOwnersListPage',

    components: {
        ApplicationOwnerOverview,
        OperateApisListPageLayout,
    },

    data() {
        return {
            selectedChannelPartnerId: '',
            applicationOwnersApiResponse: {},
            entityData: [],
            selectedApiOwner: '',
            addNewRouteName: RouteNames.OPERATE_APIS_APPLICATION_OWNER_EDITOR,
            PLURALIZATION,
            OPERATE_APIS_TYPES,
        };
    },

    computed: {
        selectedEntityId() {
            return this.selectedApiOwner ? this.selectedApiOwner.id : null;
        },
    },

    methods: {
        isUserAllowed,
        onSelectEntity(entity) {
            [this.selectedApiOwner] = this.entityData.filter(data => data.id === entity);
        },
        async onEditEntity(entity) {
            await this.$withProgressBar(
                async () => {
                    await updateApplicationOwner({
                        id: entity.id,
                        payload: {
                            approvalStatus: entity.selectedApprovalOption,
                            ...(entity.approvalStatusReason && { approvalStatusReason: entity.approvalStatusReason }),
                        },
                        channelPartnerId: this.selectedChannelPartnerId,
                    });

                    this.$showSuccessAlert({
                        message: this.$i18n.t('operateAPIs.successfullyUpdatedApplicationOwner'),
                    });

                    this.$router.go();
                },
                {
                    errorHandler: () => {
                        this.$alert(this.$i18n.t('operateAPIs.somethingWentWrongUpdatedApplicationOwner'));
                    },
                },
            );
        },
        onViewEntity(entity) {
            this.$router.push({
                name: RouteNames.OPERATE_APIS_APPLICATION_OWNER_VIEW_PAGE,
                params: {
                    id: entity.id,
                    channelPartnerId: this.selectedChannelPartnerId,
                },
            });
        },
        onSelectPartyRole(id) {
            this.$withLoadingSpinner(
                async () => {
                    await this.fetchApplicationOwners(id);
                    this.selectedChannelPartnerId = id;
                },
                {
                    errorHandler: () => {
                        this.$alert(this.$t('operateAPIs.somethingWentWrongFetchingApplicationOwners'));
                    },
                },
            );
        },
        async fetchApplicationOwners(partyRoleId) {
            const response = await getApplicationOwners(partyRoleId);
            this.applicationOwnersApiResponse = response;
            this.entityData = response.data.map(mapApplicationOwnerToFeData);
        },
    },
};
</script>
