
import Vue from 'vue';
import { mapGetters } from 'vuex';
import { Getters } from '@/store/mutation-types';

// Vue components
import AbstractEditPageWrapper from '@/components/layout/AbstractEditPageWrapper.vue';
import AppHeader from '@/components/layout/AppHeader.vue';
import AppInputV3 from '@/components/partials/inputs/AppInputV3.vue';
import AppMultiselectV3 from '@/components/partials/inputs/AppMultiselectV3.vue';
import AppTextareaV3 from '@/components/partials/inputs/AppTextareaV3.vue';
import CardListRadioInput from '@/components/partials/cards/CardListRadioInput.vue';
import EditorButtons from '@/components/layout/EditorButtons.vue';

// Fee Rule
import {
    ACTION_CONFIGURATION_TYPE as DnoFeeRuleType,
    AMOUNT_CALCULATION_METHOD,
    PRORATION_METHOD,
    PRORATION_UNIT,
    SCOPE_LEVEL,
} from '@/__new__/services/dno/pricing/models/pricingDno';
import {
    addFeeRule,
    FeeRuleDescriptor,
    getFeeRules,
    updateFeeRule,
} from '@/__new__/services/dno/pricing/feeRulesService';
import { amountCalculationMethodToString, prorationMethodSuffixString } from './feeRulesUtils';

// validations
import { validationMixin } from 'vuelidate';
import { required, requiredIf } from 'vuelidate/lib/validators';

// Misc
import { getOffers, Offer } from '@/__new__/services/dno/pc/http/offer';
import { CardListRadioInputOption, MultiSelectOption } from '@/components/partials/inputs/index';
import RouteNames from '@/router/routeNames';
import * as Sentry from '@sentry/vue';
import { TOOLTIP_POSITION } from '@/common/tooltip';

// Permissions
import permissionsService, { isUserAllowed } from '@/services/permissions/permissions.service';

export default Vue.extend({
    name: 'FeeRulesEditor',
    components: {
        AbstractEditPageWrapper,
        AppHeader,
        AppInputV3,
        AppMultiselectV3,
        AppTextareaV3,
        CardListRadioInput,
        EditorButtons,
    },
    mixins: [validationMixin],
    data() {
        return {
            amountCalculationMethodToString,
            amountCalcOptions: [
                {
                    id: AMOUNT_CALCULATION_METHOD.FIXED,
                    label: this.$i18n.t('pricingAndFees.amountCalculationMethodToString.fixed'),
                    value: AMOUNT_CALCULATION_METHOD.FIXED,
                },
                {
                    id: AMOUNT_CALCULATION_METHOD.PERCENT,
                    label: this.$i18n.t('pricingAndFees.amountCalculationMethodToString.percent'),
                    value: AMOUNT_CALCULATION_METHOD.PERCENT,
                },
            ],
            AMOUNT_CALCULATION_METHOD,
            feeRuleTypeOptions: [
                {
                    id: DnoFeeRuleType.LATE_PAYMENT,
                    label: this.$i18n.t('pricingAndFees.feeRulesTypesToString.latePayment'),
                    value: DnoFeeRuleType.LATE_PAYMENT,
                },
                {
                    id: DnoFeeRuleType.EARLY_TERMINATION,
                    label: this.$i18n.t('pricingAndFees.feeRulesTypesToString.earlyTermination'),
                    value: DnoFeeRuleType.EARLY_TERMINATION,
                },
            ],
            isEditing: false,
            prorationMethodSuffixString,
            prorationUnitOptions: [
                {
                    id: PRORATION_UNIT.DAY,
                    label: this.$i18n.t('generic.day'),
                    value: PRORATION_UNIT.DAY,
                },
                {
                    id: PRORATION_UNIT.WEEK,
                    label: this.$i18n.t('generic.week'),
                    value: PRORATION_UNIT.WEEK,
                },
                {
                    id: PRORATION_UNIT.MONTH,
                    label: this.$i18n.t('generic.month'),
                    value: PRORATION_UNIT.MONTH,
                },
                {
                    id: PRORATION_UNIT.YEAR,
                    label: this.$i18n.t('generic.year'),
                    value: PRORATION_UNIT.YEAR,
                },
            ],
            DnoFeeRuleType,
            feeRuleData: {
                id: null as string | null,
                version: null as number | null,
                name: '',
                description: '',
                type: null as DnoFeeRuleType | null,
                scope: null as SCOPE_LEVEL | null,
                offers: [] as string[],
                prorationMethod: null as CardListRadioInputOption | null,
                prorationUnit: null as PRORATION_UNIT | null,
                amount: '',
                amountCalcMethod: null as AMOUNT_CALCULATION_METHOD | null,
            },
            loadingOffers: false,
            PRORATION_METHOD,
            offersOptions: [] as MultiSelectOption[],
            readonly: !this.userHasFeeRuleWriteAccess(),
            SCOPE_LEVEL,
            scopeOptions: [
                {
                    id: SCOPE_LEVEL.LINE_ITEM_LEVEL,
                    label: this.$i18n.t('pricingAndFees.label.scopeLineItem'),
                    value: SCOPE_LEVEL.LINE_ITEM_LEVEL,
                },
                {
                    id: SCOPE_LEVEL.TOTAL_LEVEL,
                    label: this.$i18n.t('pricingAndFees.label.scopeTotal'),
                    value: SCOPE_LEVEL.TOTAL_LEVEL,
                },
            ],
            TOOLTIP_POSITION,
        };
    },
    computed: {
        ...mapGetters('operators', {
            selectedLanguage: Getters.languageDefault,
        }),
        amountValidationMessage(): string {
            if (!this.$v.feeRuleData.amount.$error) {
                return '';
            }
            if (!this.$v.feeRuleData.amount.required) {
                return this.$i18n.t('generic.validations.fieldIsRequired');
            }
            if (!this.$v.feeRuleData.amount.validNumber) {
                return this.$i18n.t('generic.validations.valueShouldBeNumber');
            }
            throw new Error('Unhandled amount validation error');
        },
        pageTitle(): string {
            if (this.readonly) {
                return this.$i18n.t('pricingAndFees.viewFeeRule');
            }
            return this.isEditing
                ? this.$i18n.t('pricingAndFees.editFeeRuleTitle')
                : this.$i18n.t('pricingAndFees.newFeeRulePageTitle');
        },
        prorationMethodOptions() {
            // Determine supporting text based on FeeRule type:
            let fixedDescription;
            let periodicDescription;
            switch (this.feeRuleData.type) {
                case DnoFeeRuleType.EARLY_TERMINATION:
                    fixedDescription = this.$i18n.t('pricingAndFees.label.chargeFixedAmountEarlyTermination');
                    periodicDescription = this.$i18n.t('pricingAndFees.label.chargePeriodicEarlyTermination');
                    break;
                case DnoFeeRuleType.LATE_PAYMENT:
                    fixedDescription = this.$i18n.t('pricingAndFees.label.chargeFixedAmountLatePayment');
                    periodicDescription = this.$i18n.t('pricingAndFees.label.chargePeriodicLatePayment');
                    break;
                default:
                    fixedDescription = '';
                    periodicDescription = '';
            }
            // Build options:
            return [
                {
                    id: PRORATION_METHOD.NONE,
                    label: this.$i18n.t('pricingAndFees.label.fixed'),
                    description: fixedDescription,
                },
                {
                    id: PRORATION_METHOD.DURATION,
                    label: this.$i18n.t('pricingAndFees.label.periodic'),
                    description: periodicDescription,
                },
            ];
        },
    },
    async created() {
        try {
            this.$Progress.start();

            // Load Offers
            this.loadingOffers = true;
            const response = await getOffers();
            const offers = Object.values(response.data.offer_by_id);
            this.offersOptions = offers.map((offer: Offer) => {
                let label;
                const { name } = offer.data;
                switch (typeof name) {
                    case 'string':
                        label = name;
                        break;
                    case 'object':
                        label = name[this.selectedLanguage] ?? Object.values(name)[0];
                        break;
                    default:
                        label = offer.id;
                }
                return {
                    id: offer.id,
                    label,
                    value: offer.id,
                } as MultiSelectOption;
            });
            this.loadingOffers = false;

            this.$Progress.finish();
        } catch (error) {
            this.$alert(this.$i18n.t('alertMessage.pricingAndFees.failedToGetOffers'));
            this.$Progress.fail();
            Sentry.captureException(error);
            this.loadingOffers = false;
        }
    },
    async mounted() {
        // Route params:
        const { id } = this.$route.params;

        // Determine if we're editing a FeeRule
        this.isEditing = Boolean(id);

        // Initialize form data
        if (this.isEditing) {
            await this.loadEditor(id);
        }
    },
    methods: {
        /**
         * Attempts to load editor form with data of FeeRule with `id`
         */
        async loadEditor(id: string) {
            await this.$withLoadingSpinner(async () => {
                const response = await getFeeRules([id]);
                const entity = response.feeRules[0];
                this.feeRuleData = {
                    id: entity.id,
                    version: entity.version,
                    name: entity.name,
                    description: entity.description,
                    type: entity.type,
                    scope: entity.scope,
                    offers: entity.offers,
                    prorationMethod:
                        this.prorationMethodOptions.find(option => option.id === entity.prorationMethod) ?? null,
                    prorationUnit: entity.prorationUnit ?? null,
                    amount: entity.amount,
                    amountCalcMethod: entity.amountCalculationMethod,
                };
            });
        },
        onTypeChange(option: DnoFeeRuleType | null) {
            switch (option) {
                case DnoFeeRuleType.EARLY_TERMINATION:
                    this.$set(this.feeRuleData, 'scope', SCOPE_LEVEL.LINE_ITEM_LEVEL);
                    this.$set(this.feeRuleData, 'amountCalcMethod', AMOUNT_CALCULATION_METHOD.FIXED);
                    break;
                case DnoFeeRuleType.LATE_PAYMENT:
                default:
                    break;
            }
        },
        onScopeChange(scope: SCOPE_LEVEL | null) {
            switch (scope) {
                case SCOPE_LEVEL.LINE_ITEM_LEVEL:
                    break;
                case SCOPE_LEVEL.TOTAL_LEVEL:
                default:
                    // Empty out offer selection when we change to a scope
                    // that's not LINE_ITEM_LEVEL. We hide the offers, so
                    // it's better to not have residual data lying around
                    this.feeRuleData.offers = [];
                    break;
            }
        },
        routeToListPage() {
            this.$router.push({ name: RouteNames.FEE_RULES, params: { companyId: this.$route.params.companyId } });
        },
        async saveFeeRule() {
            try {
                // Validation
                this.$v.$touch();
                if (this.$v.$invalid) {
                    return;
                }
                // Note: This block should never get hit due to the validations above
                // added in to avoid use of non-null-assertions
                if (
                    this.feeRuleData.type === null ||
                    this.feeRuleData.scope === null ||
                    this.feeRuleData.prorationMethod === null ||
                    this.feeRuleData.amountCalcMethod === null
                ) {
                    return;
                }

                // Build FeeRule description for service
                const feeRule: FeeRuleDescriptor = {
                    name: this.feeRuleData.name,
                    description: this.feeRuleData.description,
                    type: this.feeRuleData.type,
                    scope: this.feeRuleData.scope,
                    offers: this.feeRuleData.offers,
                    prorationMethod: this.feeRuleData.prorationMethod.id,
                    prorationUnit: this.feeRuleData.prorationUnit ?? undefined,
                    amountCalculationMethod: this.feeRuleData.amountCalcMethod,
                    amount: this.feeRuleData.amount,
                };

                // Save
                this.$Progress.start();
                if (this.isEditing) {
                    if (this.feeRuleData.id === null || this.feeRuleData.version === null) {
                        throw new Error('Id and/or version information unknown for FeeRule in edit mode.');
                    }
                    await updateFeeRule(this.feeRuleData.id, this.feeRuleData.version, feeRule, this.selectedLanguage);
                } else {
                    await addFeeRule(feeRule, this.selectedLanguage);
                }
                this.$Progress.finish();

                // Route back to list page
                this.routeToListPage();
            } catch (error) {
                this.$Progress.fail();
                this.$alert(this.$i18n.t('alertMessage.pricingAndFees.failedToSaveFeeRule'));
                Sentry.captureException(error);
            }
        },
        // Note: Once new permissions are in this function can be removed and usages
        // can be replaced with `isUserAllowed(...)`
        userHasFeeRuleWriteAccess(): boolean {
            return permissionsService.feeRulesEditorEnabled() && isUserAllowed('FeeRulesWrite');
        },
    },
    validations() {
        return {
            feeRuleData: {
                amount: {
                    required,
                    validNumber: value => !Number.isNaN(Number(value)),
                },
                amountCalcMethod: {
                    required,
                },
                name: {
                    required,
                },
                prorationMethod: {
                    required,
                },
                prorationUnit: {
                    required: requiredIf(() => this.feeRuleData.prorationMethod?.id === PRORATION_METHOD.DURATION),
                },
                scope: {
                    required,
                },
                type: {
                    required,
                },
            },
        };
    },
});
