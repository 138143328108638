<template>
    <div class="zone-block flexbox-column">
        <div class="input-fields">
            <div class="d-flex justify-content-between">
                <div class="grow">
                    <div class="d-flex row">
                        <div class="col-12">
                            <div>
                                <div class="zone-title">
                                    {{
                                        $i18n.t('charging.chargingSpecifications.editor.conditionText.otherPartyRNFull')
                                    }}
                                </div>
                                <TagsMultiselect
                                    v-model="otherPartyRN.values"
                                    :options="[]"
                                    :placeholder="$i18n.t('charging.chargingSpecifications.editor.inputNumbers')"
                                    :labelFormatter="e => e"
                                    :wrapHeader="false"
                                    :explanation="$i18n.t('messages.otherPartyRNDescription')"
                                    :taggable="true"
                                    :data-test-id="'other-party-rn-condition-multiselect'"
                                >
                                    <template #beforeMultiselect>
                                        <AppMultiselectV3
                                            v-model="otherPartyRN.condition"
                                            :allowEmpty="false"
                                            :options="options"
                                            :searchable="false"
                                            :showLabels="false"
                                            :borderNone="true"
                                            :blueArrow="true"
                                            class="multiselect small condition-options"
                                            label="label"
                                            trackBy="id"
                                        />
                                    </template>
                                </TagsMultiselect>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// Components
import AppMultiselectV3 from '@/components/partials/inputs/AppMultiselectV3.vue';
import TagsMultiselect from '@/components/partials/inputs/TagsMultiselect.vue';

// Helpers
import {
    CONDITIONS_ENUM,
    CONDITIONS_OPTIONS,
    CONDITION_TO_INVERTED_MAP,
} from '@/__new__/services/dno/charging/common/conditionsHelper';
import OtherPartyRoutingNumberCondition from '@/__new__/services/dno/charging/models/chargingSpecificationConditions/OtherPartyRoutingNumberCondition';

const OPERATOR_VALUES = {
    STARTS_WITH: 'starts_with',
    IS: 'is',
};

const CONDITION_TO_OPERATOR_MAP = new Map([
    [CONDITIONS_ENUM.IS, OPERATOR_VALUES.IS],
    [CONDITIONS_ENUM.IS_NOT, OPERATOR_VALUES.IS],
    [CONDITIONS_ENUM.IN, OPERATOR_VALUES.STARTS_WITH],
    [CONDITIONS_ENUM.NOT_IN, OPERATOR_VALUES.STARTS_WITH],
]);

export function getConditionByOperatorAndInverted(operator, inverted) {
    if (operator === OPERATOR_VALUES.IS) {
        return inverted ? CONDITIONS_ENUM.IS_NOT : CONDITIONS_ENUM.IS;
    }
    if (operator === OPERATOR_VALUES.STARTS_WITH) {
        return inverted ? CONDITIONS_ENUM.NOT_IN : CONDITIONS_ENUM.IN;
    }
    return null;
}

export default {
    name: 'OtherPartyRNCondition',
    components: {
        AppMultiselectV3,
        TagsMultiselect,
    },
    props: {
        value: {
            type: Object,
            default: null,
        },
    },
    data() {
        return {
            otherPartyRN: {
                values: [],
                condition: CONDITIONS_OPTIONS.isNot,
            },
            otherPartyRNDataFormatted: new OtherPartyRoutingNumberCondition(this.value),
            options: CONDITIONS_OPTIONS,
        };
    },
    watch: {
        otherPartyRN: {
            handler(newVal) {
                let operator;
                let inverted;
                if (newVal?.condition?.id) {
                    operator = CONDITION_TO_OPERATOR_MAP.get(newVal.condition.id);
                    inverted = CONDITION_TO_INVERTED_MAP.get(newVal.condition.id);
                }

                this.otherPartyRNDataFormatted.data = {
                    operator,
                    inverted,
                    values: newVal.values,
                };

                this.otherPartyRNDataFormatted.dataEmpty = false;
                this.emitData();
            },
            deep: true,
        },
    },
    created() {
        if (this.value?.data) {
            this.otherPartyRN.condition = CONDITIONS_OPTIONS.find(
                option =>
                    option.id === getConditionByOperatorAndInverted(this.value.data.operator, this.value.data.inverted),
            );
            this.otherPartyRN.values = this.value.data.values;
        }
    },
    methods: {
        emitData() {
            this.otherPartyRNDataFormatted.isDataValid();
            this.$emit('input', this.otherPartyRNDataFormatted);
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/_palette.scss';

.grow {
    flex-grow: 1;
}

.zone-title {
    font-size: 0.75rem;
    font-weight: 600;
    line-height: 2.17;
    color: $gray90;
    margin-bottom: 0.25rem;
}

.condition-options {
    min-width: 80px;
    width: max-content;
}
</style>
