import { uuidV4 } from '@/common/utils';
// Import of a file that exposes jquery globally because it`s required by draw2d library
import '@/__new__/features/orchestrationengine/common/import-jquery';
// eslint-disable-next-line import/extensions
import 'jquery-ui-dist/jquery-ui.js';
import draw2d from 'draw2d';
// Import of a file that exposes globally draw2d custom elements
import '@/__new__/features/orchestrationengine/common/customDraw2dElements';

// Helpers
import {
    FLAG_VALUE_OPTIONS,
    IS_NOT_ENUMS,
} from '@/__new__/features/orchestrationengine/common/orchestrationDiagramUpdateHelper';

window.draw2d = draw2d;

const maxZoomBoundary = 0.2;
const minZoomBoundary = 5;
const zoomStep = 1;

export const toBeOverriddenTemplateString = '_TO_BE_OVERRIDDEN_';

/* Interfaces for typescript */
type SetupForDiagram = {
    blockWidth: number;
    blockHeight: number;
    xCoefficient: number;
    yCoefficient: number;
    xPadding: number;
    yPadding: number;
    rowSize: number;
    bufferZoneSize: number;
    allowHighlightClick: boolean;
};

type ColorSet = {
    blockBorder?: string;
    blockBackground?: string;
    link?: string;
};

type ColorSets = {
    [key: string]: ColorSet;
};

type Grid = Record<string, number>;

type RenderItem = {
    key: string;
    maxChilds: number;
    parentsNodes?: string[];
    linkedTo?: { type: string; key: string }[];
    proceed: number;
    row: number;
    column: number;
    x: number;
    y: number;
};

type RenderList = {
    [key: string]: RenderItem;
};

type State = {
    type?: string;
    next?: string;
    catch?: { next: string }[];
    choices?: { next: string }[];
    default?: string;
    execution_plans?: { [key: string]: any };
    looping_states?: { [key: string]: { end?: boolean; next: string } };
    start_at?: string;
};

type Port = {
    type?: string;
    id?: string;
    width?: number;
    height?: number;
    selectable?: boolean;
    draggable?: boolean;
    visible?: boolean;
    visibility?: boolean;
    bgColor?: string;
    color?: string;
    name?: string;
    port?: string;
    locator?: string;
    userData?: {
        figureType?: string;
    };
};

type BlockData = {
    key: string;
    linkedTo?: any[];
    parentsNodes?: any[];
    x?: number;
    y?: number;
};

type TaskBlockConfig = {
    [key: string]: {
        type?: string;
        id?: string;
        x?: number;
        y?: number;
        width?: number;
        height?: number;
        selectable?: boolean;
        draggable?: boolean;
        stroke?: number;
        radius?: number;
        bgColor?: string;
        color?: string;
        userData?: {
            figureType?: string;
            key?: string;
        };
        ports?: Port[];
    };
};

type SourceTarget = {
    node?: string;
    port?: string;
};

type LinkConfig = {
    type?: string;
    id?: string;
    policy?: string;
    router?: string;
    selectable?: boolean;
    stroke?: number;
    color?: string;
    source?: SourceTarget;
    target?: SourceTarget;
    userData?: {
        figureType?: string;
    };
    vertex?: any[];
};

type DataFromDiagram = {
    figuresFromDiagram: any;
    arrowsFromDiagram: any;
    figuresNames: any;
    numberOfArrowsFromEachState: any;
    numberOfArrowsToEachState: any;
};
/* Interfaces for typescript */

const defaultSetup: SetupForDiagram = {
    blockWidth: 130,
    blockHeight: 50,

    xCoefficient: 180,
    yCoefficient: 100,

    xPadding: 40,
    yPadding: 20,

    rowSize: 20,

    bufferZoneSize: 300,

    allowHighlightClick: true,
};

export enum STATE_TYPES {
    Parallel = 'parallel',
    Choice = 'choice',
    Loop = 'loop',
    Catch = 'catch',
    LoopV2 = 'loop_v2',
}

export enum FIGURE_TYPES {
    Port = 'port',
    Connection = 'connection',
    Block = 'block',
    Label = 'label',
}

enum PORT_TYPES {
    MainOutput = 'output',
    SideLeftOutput = 'sideLeftOutput',
    SideRightOutput = 'sideRightOutput',
}

export enum TEMPLATE_TYPES {
    State = 'state',
    Other = 'other',
}

const COLOR_SETS: ColorSets = {
    green: {
        blockBorder: '#37C9AA',
        blockBackground: '#E4F7F3',
        link: '#37C9AA',
    },
    gray: {
        blockBorder: '#BBBBBB',
        blockBackground: '#FFFFFF',
        link: '#DDDDDD',
    },
    red: {
        blockBorder: '#E8504C',
        blockBackground: '#FEE5E5',
        link: '#E8504C',
    },
};

export const ACTION_SIDEBAR_TABS = {
    TEMPLATES: 1,
    ACTIONS: 2,
};

export const SIDEBAR_WIDTHS = {
    CLOSED_SIDEBAR: '3rem',
    ACTIONS_SIDEBAR_COLLAPSED: '20rem',
    DEFINITION_SIDEBAR_COLLAPSED: '30rem',
};

/**
 * Function for converting object of objects to array of objects
 * with additional key which is name
 * @param obj
 * @returns {Array}
 */
function convertObjectOfObjectsToArrayOfObjects(obj: any): { name: string; [key: string]: any }[] {
    return Object.keys(obj).map(key => ({ name: key, ...obj[key] }));
}

/**
 * Function for converting array of objects to array of objects
 * with ability to delete name key from object
 * @param arr
 * @param deleteNameKey
 * @returns {Object}
 */
function convertArrayOfObjectsToObjectOfObjects(arr: any[], deleteNameKey = false): Record<string, any> {
    const res: Record<string, any> = {};
    for (let i = 0; i < arr.length; i += 1) {
        const key = arr[i].name;
        res[key] = arr[i];
        if (deleteNameKey) {
            delete res[key].name;
        }
    }
    return res;
}

/**
 * Function for sorting array based on the other array by key
 * @param firstArray
 * @param secondArray
 * @param key
 * @returns {Array}
 */
function sortArrayBasedOnAnotherArray(firstArray: any[], secondArray: any[], key: string): any[] {
    firstArray.sort((a: any, b: any) => {
        if (secondArray.indexOf(a[key]) > secondArray.indexOf(b[key])) {
            return 1;
        }
        return -1;
    });

    return firstArray;
}

// helper function for finding index and deleting state from array
function deleteStateIfExistsFromArray(stateName: string, array: any[]): void {
    const indexOfState = array.findIndex(state => state.name === stateName);
    if (indexOfState > -1) {
        array.splice(indexOfState, 1);
    }
}

// Reusable helper function to map conditions
function mapConditions(statesWithConditions: any): any[] {
    return statesWithConditions.map((state: { flagValue: any; conditions: any; name: string }) => {
        const { flagValue, conditions, name } = state;

        const allConditionsForOneState = conditions.map(
            ({ selectedOperator, operatorValue, variable, isNotValue }: any) => {
                const oneCondition = {
                    variable,
                    [selectedOperator]: operatorValue,
                };

                // adding 'not' as a key if user has selected 'not'
                return isNotValue === IS_NOT_ENUMS.IS_NOT ? { not: oneCondition } : oneCondition;
            },
        );

        // Creating one state with conditions
        let oneStateWithConditions = {};

        if (flagValue === FLAG_VALUE_OPTIONS.SIMPLE) {
            // [0] because when simple is selected we have only one condition and it needs to be displayed as object
            oneStateWithConditions = { ...allConditionsForOneState[0], next: name };
        } else {
            oneStateWithConditions = {
                [flagValue]: allConditionsForOneState,
                next: name,
            };
        }

        return oneStateWithConditions;
    });
}

/* Helper functions for getting things from diagram */

// get array of all lines with line id, source port id, target port id
function getLinesFromDiagram(canvas: any): { id: string; source: string; target: string }[] {
    const lines = canvas.getLines().data;

    return lines.map((line: any) => ({
        id: line.id,
        source: line.getSource().id,
        taget: line.getTarget().id,
    }));
}

// get all figures
function getFiguresFromDiagram(canvas: any): { name: any; x: any; y: any }[] {
    const figures = canvas.getFigures().data;

    return figures.map((figure: any) => ({
        name: figure.getUserData().key,
        x: figure.getX(),
        y: figure.getY(),
    }));
}

// get all ports that blocks have
function getPortsOfFiguresFromDiagram(canvas: any): { id: string; blockName: string; type: string }[] {
    const listOfPorts: { id: string; blockName: string; type: string }[] = [];
    const figures = canvas.getFigures().data;

    figures.forEach((figure: any) => {
        const figureName = figure.getUserData().key;
        const outputPorts = figure.getOutputPorts().data;
        const inputPorts = figure.getInputPorts().data;

        outputPorts.forEach((outputPort: { id: string }) => {
            listOfPorts.push({
                id: outputPort.id,
                blockName: figureName,
                type: 'output',
            });
        });

        inputPorts.forEach((inputPort: { id: string }) => {
            listOfPorts.push({
                id: inputPort.id,
                blockName: figureName,
                type: 'input',
            });
        });
    });

    return listOfPorts;
}

// create list of arrows based on lines and ports with
// id of arrow (line id), name of source block and name of target block
function getArrowsListPageFromDiagramData(
    listOfLines: any[],
    ports: any[],
): { id: any; sourceName: any; targetName: any }[] {
    return listOfLines.map(line => {
        const sourceName = ports.filter(port => port.id === line.source)[0].blockName;
        const targetName = ports.filter(port => port.id === line.taget)[0].blockName;

        return {
            id: line.id,
            sourceName,
            targetName,
        };
    });
}

// helper function for getting data from diagram based on canvas
function getDataFromDiagram(canvas: any): DataFromDiagram {
    const linesFromDiagram = getLinesFromDiagram(canvas);
    const figuresFromDiagram = getFiguresFromDiagram(canvas);
    const portsFromDiagram = getPortsOfFiguresFromDiagram(canvas);
    const arrowsFromDiagram = getArrowsListPageFromDiagramData(linesFromDiagram, portsFromDiagram);

    const figuresNames = figuresFromDiagram.map((figure: { name: string }) => figure.name);

    // get name of each state and number of arrows for which given state is source
    const numberOfArrowsFromEachState = figuresNames.map((figureName: any) => {
        const numberOfArrowsPerState = arrowsFromDiagram.filter(arrow => arrow.sourceName === figureName);

        return {
            name: figureName,
            number: numberOfArrowsPerState.length,
        };
    });

    // get name of each state and number of arrows for which given state is target
    const numberOfArrowsToEachState = figuresNames.map((figureName: any) => {
        const numberOfArrowsPerState = arrowsFromDiagram.filter(arrow => arrow.targetName === figureName);

        return {
            name: figureName,
            number: numberOfArrowsPerState.length,
        };
    });

    return {
        figuresFromDiagram,
        arrowsFromDiagram,
        figuresNames,
        numberOfArrowsFromEachState,
        numberOfArrowsToEachState,
    };
}

/* Helper functions for getting things from diagram */

/* Grid helper functions */
function incrementRowGridHelper(grid: Grid, row: number, maxChilds: number | undefined): void {
    if (!grid[`${row}:${'wholeRow'}`]) {
        grid[`${row}:${'wholeRow'}`] = 0;
    }
    if (maxChilds) {
        grid[`${row}:${'wholeRow'}`] = grid[`${row}:${'wholeRow'}`] + maxChilds - 1;
    } else {
        grid[`${row}:${'wholeRow'}`] = grid[`${row}:${'wholeRow'}`] + 1;
    }
}

function writeToGridHelper(grid: Grid, val: number, row: number, column: number): void {
    grid[`${row}:${column}`] = val;
}

function readFromGridHelper(grid: Grid, row: number, column: string | number = 'wholeRow'): number {
    return grid[`${row}:${column}`];
}
/* Grid helper functions */

/* Map positioning functions */
function recursiveKeyRemap(renderList: RenderList, currentKey: string, traceStack: string[]): string[] {
    const localKeyArr = [currentKey];
    if (traceStack.includes(currentKey)) {
        return localKeyArr;
    }
    if (renderList[currentKey].linkedTo) {
        for (const nextKeyObj of renderList[currentKey].linkedTo || []) {
            localKeyArr.push(...recursiveKeyRemap(renderList, nextKeyObj.key, [...traceStack, currentKey]));
        }
    }
    return localKeyArr;
}

function recursiveAlign(setup: SetupForDiagram, renderList: RenderList, currentKey: string): void {
    const allKeys = [...new Set(recursiveKeyRemap(renderList, currentKey, []))];
    for (const key of allKeys) {
        renderList[key].x += setup.xCoefficient / 2;
    }
}

// Helper to track names of remaining states for render
const listOfStatesForRender: string[] = [];

function recursiveRemap(
    setup: SetupForDiagram,
    renderList: RenderList,
    states: { [key: string]: State },
    stateKey: string,
    lastLevelNum: number,
    columnHelper: { [key: string]: number },
    traceStack: string[],
    helperForLoop: { [key: string]: { currentState: string; lastLoopState: string } } = {},
): RenderItem {
    let currentStateKey = stateKey;
    const statesArrayOfObjects = convertObjectOfObjectsToArrayOfObjects(states);

    // Remove first state from list of states for render because this function call is for rendering that state
    const indexOfCurrentState = listOfStatesForRender.indexOf(currentStateKey);
    if (indexOfCurrentState >= 0) {
        listOfStatesForRender.splice(indexOfCurrentState, 1);
    }

    // For first attempt if we don't have stateKey we get it from first one of states array
    if (!stateKey && statesArrayOfObjects[0].name !== undefined) {
        currentStateKey = statesArrayOfObjects[0].name;
    }

    if (!renderList[currentStateKey]) {
        renderList[currentStateKey] = {
            key: currentStateKey,
            maxChilds: 0,
            column: 0,
            proceed: 0,
            row: 0,
            x: 0,
            y: 0,
        };
    }

    if (!renderList[currentStateKey].parentsNodes) {
        renderList[currentStateKey].parentsNodes = [];
    }

    if (
        traceStack &&
        Array.isArray(traceStack) &&
        traceStack.length > 0 &&
        !renderList?.[currentStateKey]?.parentsNodes?.includes(traceStack[traceStack.length - 1])
    ) {
        renderList?.[currentStateKey]?.parentsNodes?.push(traceStack[traceStack.length - 1]);
    }

    // Infinite recursion guard
    if (traceStack.includes(currentStateKey)) {
        return {} as RenderItem;
    }

    const nextStates: any = [];

    if (states[currentStateKey]?.type && states[currentStateKey].type === STATE_TYPES.Parallel) {
        if (states[currentStateKey] && states[currentStateKey].execution_plans && states[currentStateKey].next) {
            // eslint-disable-next-line guard-for-in
            for (const prop in states[currentStateKey].execution_plans) {
                nextStates.push({
                    type: PORT_TYPES.MainOutput,
                    key: prop,
                });
                states[prop] = {
                    type: 'task',
                    next: states[currentStateKey].next,
                };
            }
        }

        renderList[currentStateKey].linkedTo = nextStates;
    } else if (states[currentStateKey]?.type && states[currentStateKey].type === STATE_TYPES.LoopV2) {
        if (states[currentStateKey] && states[currentStateKey].next) {
            nextStates.push({
                type: PORT_TYPES.MainOutput,
                key: states[currentStateKey].next,
            });
        }

        if (states[currentStateKey] && states[currentStateKey].start_at) {
            const loopStates = [];
            let currentState: any = currentStateKey;

            while (currentState) {
                loopStates.push(currentState);

                if (states[currentState] && (states[currentState].next || states[currentState].start_at)) {
                    if (states[currentState].start_at) {
                        currentState = states[currentState].start_at;
                    } else {
                        currentState = states[currentState].next;
                    }

                    // Check if the current state is already in loopStates
                    if (loopStates.includes(currentState)) {
                        break;
                    }
                } else {
                    break;
                }
            }

            if (loopStates.length > 1) {
                const lastLoopState = loopStates[loopStates.length - 1];

                // Set helper for loop which we use later in order to render last loop connection correct
                helperForLoop[lastLoopState] = { currentState, lastLoopState };
            }

            nextStates.push({
                type: PORT_TYPES.MainOutput,
                key: states[currentStateKey].start_at,
            });
        }

        renderList[currentStateKey].linkedTo = nextStates;
    } else if (states[currentStateKey]?.type && states[currentStateKey].type === STATE_TYPES.Choice) {
        if (
            states[currentStateKey] &&
            states[currentStateKey].choices &&
            Array.isArray(states[currentStateKey].choices)
        ) {
            const state =
                states[currentStateKey].choices?.reduce((res: any, val: any) => {
                    return [
                        ...res,
                        {
                            type: PORT_TYPES.MainOutput,
                            key: val.next,
                        },
                    ];
                }, []) || [];

            nextStates.push(...state);
        }

        if (states[currentStateKey] && states[currentStateKey].default) {
            if (!nextStates.find(({ key }: any) => key === states[currentStateKey].default)) {
                nextStates.push({
                    type: PORT_TYPES.SideRightOutput,
                    key: states[currentStateKey].default,
                });
            }
        }

        renderList[currentStateKey].linkedTo = nextStates;
    } else {
        if (states?.[currentStateKey]?.next && states[currentStateKey].next !== toBeOverriddenTemplateString) {
            let type = PORT_TYPES.MainOutput;
            let key = states[currentStateKey].next;

            // If state is last state from loop then we need arrow to start from the right side of block
            if (helperForLoop?.[currentStateKey]?.currentState && helperForLoop?.[currentStateKey]?.lastLoopState) {
                const { currentState, lastLoopState } = helperForLoop[currentStateKey];

                if (states[currentStateKey].next === currentState && currentStateKey === lastLoopState) {
                    type = PORT_TYPES.SideRightOutput;
                    key = currentState;
                }
            }

            nextStates.push({
                type,
                key,
            });
        }

        if (states[currentStateKey] && states[currentStateKey].catch) {
            for (const catchItem of states[currentStateKey].catch || []) {
                if (!nextStates.find(({ key }: any) => key === catchItem.next)) {
                    nextStates.push({
                        type: PORT_TYPES.SideRightOutput,
                        key: catchItem.next,
                    });
                }
            }
        }

        if (
            (!states[currentStateKey].next || states[currentStateKey].next === toBeOverriddenTemplateString) &&
            !states[currentStateKey]?.catch
        ) {
            renderList[currentStateKey].linkedTo = [];
        } else {
            renderList[currentStateKey].linkedTo = nextStates;
        }
    }

    // Push next states in list of states for render
    if (nextStates.length) {
        const nextStatesNames = nextStates.map((state: { key: any }) => state.key);

        for (const name of nextStatesNames) {
            listOfStatesForRender.push(name);
        }
    }

    if (nextStates.length > 0) {
        for (const nodeKeyObj of nextStates) {
            const recursionRes = recursiveRemap(
                setup,
                renderList,
                states,
                nodeKeyObj.key,
                lastLevelNum + 1,
                columnHelper,
                [...traceStack, currentStateKey],
                helperForLoop,
            );

            if (renderList[nodeKeyObj.key].proceed === 1) {
                renderList[currentStateKey].maxChilds += recursionRes.maxChilds;
            }
        }
        if (!renderList[currentStateKey].maxChilds) {
            renderList[currentStateKey].maxChilds = 1;
        }
    } else {
        renderList[currentStateKey].maxChilds = 1;
    }

    // Logic to render unrendered states (which are not rendered through previous logic with next states)
    if (listOfStatesForRender.length === 0) {
        // Get names list of already rendered blocks
        const renderListArrayOfObjects = Object.values(renderList);
        let renderedBlocksNames = renderListArrayOfObjects.map(state => state.key);
        renderedBlocksNames = [...renderedBlocksNames, currentStateKey];

        // Get names of blocks that are not rendered
        const unrenderedStates = statesArrayOfObjects.filter(state => !renderedBlocksNames.includes(state.name));
        const unrenderedStatesNames = unrenderedStates.map(state => state.name);

        if (unrenderedStatesNames.length) {
            let stateToRender = '';
            traceStack = [];
            helperForLoop = {};

            const statesWithStartAtKey = unrenderedStates.filter(state => state.start_at);
            const statesWithChoicesKey = unrenderedStates.filter(state => state.choices);
            const statesWithCatchKey = unrenderedStates.filter(state => state.catch);
            const statesWithNextKey = unrenderedStates.filter(
                state => state.next && state.next !== toBeOverriddenTemplateString,
            );

            if (statesWithStartAtKey.length) {
                stateToRender = statesWithStartAtKey[0].name;
            } else if (statesWithChoicesKey.length) {
                stateToRender = statesWithChoicesKey[0].name;
            } else if (statesWithCatchKey.length) {
                stateToRender = statesWithCatchKey[0].name;
            } else if (statesWithNextKey.length) {
                stateToRender = statesWithNextKey[0].name;
            } else {
                stateToRender = unrenderedStates[0].name;
            }

            listOfStatesForRender.push(stateToRender);

            const recursionRes = recursiveRemap(
                setup,
                renderList,
                states,
                stateToRender,
                lastLevelNum + 1,
                columnHelper,
                traceStack,
                helperForLoop,
            );

            if (renderList[stateToRender].proceed === 1) {
                renderList[currentStateKey].maxChilds += recursionRes.maxChilds;
            }

            if (!renderList[currentStateKey].maxChilds) {
                renderList[currentStateKey].maxChilds = 1;
            }
        }
    }

    const filteredNodes = nextStates.filter(
        (node: { key: any }) => !traceStack.includes(node.key) && renderList[node.key].proceed === 1,
    );

    if (!renderList[currentStateKey].row) {
        renderList[currentStateKey].row = lastLevelNum;
    }

    if (!renderList[currentStateKey].column) {
        incrementRowGridHelper(columnHelper, renderList[currentStateKey].row, undefined);
        renderList[currentStateKey].column = readFromGridHelper(columnHelper, renderList[currentStateKey].row);
        incrementRowGridHelper(columnHelper, renderList[currentStateKey].row, renderList[currentStateKey].maxChilds);
        for (
            let i = renderList[currentStateKey].column;
            i < renderList[currentStateKey].column + renderList[currentStateKey].maxChilds;
            i += 1
        ) {
            writeToGridHelper(columnHelper, renderList[currentStateKey].maxChilds, renderList[currentStateKey].row, i);
        }
        if (renderList[currentStateKey].maxChilds === 1 && filteredNodes.length === 0) {
            for (let i = renderList[currentStateKey].row + 1; i < 100; i += 1) {
                incrementRowGridHelper(columnHelper, i, undefined);
            }
        }
    }

    // To change render axis replace row by column and vice-versa
    if (!renderList[currentStateKey].x) {
        if (filteredNodes.length > 0) {
            renderList[currentStateKey].x =
                (renderList[filteredNodes[filteredNodes.length - 1].key].x - renderList[filteredNodes[0].key].x) / 2 +
                renderList[filteredNodes[0].key].x;
        } else {
            renderList[currentStateKey].x =
                (renderList[currentStateKey].column - 1) * setup.xCoefficient + setup.xPadding;
        }
        // Parallel type align hack. It is messy but I didn`t find better solution yet.
        if (states[traceStack[traceStack.length - 1]]?.type === STATE_TYPES.Parallel) {
            const parallelArray = Object.keys(states[traceStack[traceStack.length - 1]].execution_plans || {});
            const firstParallelChildKey = parallelArray[0];
            const firstParallelChildX = renderList[firstParallelChildKey].x;
            if (firstParallelChildX && currentStateKey !== firstParallelChildKey) {
                renderList[currentStateKey].x =
                    firstParallelChildX + setup.xCoefficient * parallelArray.indexOf(currentStateKey);
                recursiveAlign(setup, renderList, renderList?.[currentStateKey]?.linkedTo?.[0]?.key || '');
            }
        }
    }
    if (!renderList[currentStateKey].y) {
        renderList[currentStateKey].y = (renderList[currentStateKey].row - 1) * setup.yCoefficient + setup.yPadding;
    }

    if (!renderList[currentStateKey].proceed) {
        renderList[currentStateKey].proceed = 0;
    }
    renderList[currentStateKey].proceed += 1;

    return renderList[currentStateKey];
}
/* Map positioning functions */

/* Draw2d render functions */
function getTaskBlockConfig(setup: SetupForDiagram, blockData: BlockData): TaskBlockConfig {
    // Always show 3 output ports no matter if we have linkedTo or not
    const ports: any = [
        {
            type: 'draw2d.OutputPort',
            id: uuidV4(),
            width: 9,
            height: 9,
            selectable: false,
            draggable: true,
            visible: false,
            bgColor: '#DDDDDD',
            color: '#DDDDDD',
            name: PORT_TYPES.MainOutput,
            port: 'draw2d.OutputPort',
            locator: 'draw2d.layout.locator.BottomLocator',
            userData: {
                figureType: FIGURE_TYPES.Port,
            },
        },
        {
            type: 'draw2d.OutputPort',
            id: uuidV4(),
            width: 9,
            height: 9,
            selectable: false,
            draggable: true,
            visible: false,
            bgColor: '#DDDDDD',
            color: '#DDDDDD',
            name: PORT_TYPES.SideLeftOutput,
            port: 'draw2d.OutputPort',
            locator: 'draw2d.layout.locator.LeftLocator',
            userData: {
                figureType: FIGURE_TYPES.Port,
            },
        },
        {
            type: 'draw2d.OutputPort',
            id: uuidV4(),
            width: 9,
            height: 9,
            selectable: false,
            draggable: true,
            visible: false,
            bgColor: '#DDDDDD',
            color: '#DDDDDD',
            name: PORT_TYPES.SideRightOutput,
            port: 'draw2d.OutputPort',
            locator: 'draw2d.layout.locator.RightLocator',
            userData: {
                figureType: FIGURE_TYPES.Port,
            },
        },
    ];

    // Always show minimum 1 input port in order to connect blocks
    let numberOfInputPorts = blockData?.parentsNodes?.length ?? 0;
    if (!blockData?.parentsNodes?.length) {
        numberOfInputPorts = 1;
    }

    for (let i = 0; i < numberOfInputPorts; i += 1) {
        ports.push({
            type: 'draw2d.InputPort',
            id: uuidV4(),
            width: 9,
            height: 9,
            selectable: false,
            draggable: false,
            visibility: false,
            bgColor: '#DDDDDD',
            color: '#DDDDDD',
            name: `input${i}`,
            port: 'draw2d.InputPort',
            locator: 'customs.TopInputPortLocator',
            userData: {
                figureType: FIGURE_TYPES.Port,
            },
        });
    }

    return {
        [blockData.key]: {
            type: 'draw2d.shape.basic.Rectangle',
            id: uuidV4(),
            x: blockData.x,
            y: blockData.y,
            width: setup.blockWidth,
            height: setup.blockHeight,
            selectable: false,
            draggable: true,
            stroke: 1.5,
            radius: 8,
            bgColor: '#FFFFFF',
            color: '#BBBBBB',
            userData: {
                figureType: FIGURE_TYPES.Block,
                key: blockData.key,
            },
            ports,
        },
    };
}

function getLinkConfig(fromId: string, toId: string, outputPort: string, inputPort: string): LinkConfig {
    return {
        type: 'draw2d.Connection',
        id: uuidV4(),
        policy: 'draw2d.policy.line.LineSelectionFeedbackPolicy',
        router: 'draw2d.layout.connection.ManhattanBridgedConnectionRouter',
        selectable: false,
        stroke: 2,
        color: '#DDDDDD',
        source: {
            node: fromId,
            port: outputPort,
        },
        target: {
            node: toId,
            port: inputPort,
        },
        userData: {
            figureType: FIGURE_TYPES.Connection,
        },
        vertex: [],
    };
}

function decorateArrows(canvas: any): void {
    const lines = canvas.getLines().data;
    for (const line of lines) {
        const decorator = new draw2d.decoration.connection.ArrowDecorator(8, 8);
        decorator.setBackgroundColor('#DDDDDD');
        line.setTargetDecorator(decorator);
        line.setColor('#DDDDDD');
        line.repaint();
    }
}

function breakLineInText(setup: SetupForDiagram, text: string): string {
    if (text.length <= setup.rowSize) {
        return text;
    }

    const delimiter = '_';
    let formattedText = '';
    let prevIndex = 0;
    let i = setup.rowSize - 1;

    while (i < text.length) {
        const lastDelimiterIndex = text.lastIndexOf(delimiter, i);
        const lastCamelCaseIndex = text.slice(prevIndex, i + 1).search(/[A-Z]/);

        let breakIndex = -1;
        if (lastDelimiterIndex > prevIndex) {
            breakIndex = lastDelimiterIndex;
        } else if (lastCamelCaseIndex > 0) {
            breakIndex = prevIndex + lastCamelCaseIndex;
        }

        if (breakIndex > prevIndex) {
            formattedText += `${text.substring(prevIndex, breakIndex)}\n`;
            prevIndex = breakIndex;
            i = prevIndex + setup.rowSize;
        } else {
            formattedText += `${text.substring(prevIndex, i + 1)}\n`;
            prevIndex = i + 1;
            i = prevIndex + setup.rowSize;
        }
    }

    formattedText += text.substring(prevIndex);
    return formattedText;
}

function decorateLabels(setup: SetupForDiagram, canvas: any, blocks: any): void {
    for (const key of Object.keys(blocks)) {
        const figure = new draw2d.shape.basic.Text();

        figure.attr({
            type: 'draw2d.shape.basic.Text',
            id: uuidV4(),
            x: blocks[key].x,
            y: blocks[key].y,
            width: setup.blockWidth - 8,
            height: setup.blockHeight,
            selectable: false,
            draggable: false,
            cssClass: 'draw2d_shape_basic_Text',
            bgColor: 'rgba(0,0,0,0)',
            color: 'rgba(0,0,0,0)',
            text: breakLineInText(setup, key),
            outlineColor: 'rgba(0,0,0,0)',
            fontSize: 12,
            fontColor: '#333333',
            userData: {
                figureType: FIGURE_TYPES.Label,
                key,
            },
        });

        const locator = new draw2d.layout.locator.CenterLocator();

        canvas.getFigure(blocks[key].id).add(figure, locator);
    }
}

function mapDataToDraw2dJSON(setup: SetupForDiagram, remappedData: any): object {
    let blocksJSONObject: { [key: string]: { [key: string]: any } } = {};
    const remappedDataValues: any = Object.values(remappedData);

    for (const element of remappedDataValues) {
        blocksJSONObject = {
            ...blocksJSONObject,
            ...getTaskBlockConfig(setup, element),
        };
    }

    const linksJsonArray = [];
    const linksHelper: { [key: string]: number } = {};
    for (const element of remappedDataValues) {
        for (const linkKeyObj of element.linkedTo) {
            if (!Object.hasOwnProperty.call(linksHelper, linkKeyObj.key)) {
                linksHelper[linkKeyObj.key] = 0;
            } else {
                linksHelper[linkKeyObj.key] += 1;
            }
            linksJsonArray.push(
                getLinkConfig(
                    blocksJSONObject[element.key].id,
                    blocksJSONObject[linkKeyObj.key].id,
                    linkKeyObj.type,
                    `input${linksHelper[linkKeyObj.key]}`,
                ),
            );
        }
    }

    return {
        blocksJSONObject,
        draw2dJSON: [...Object.values(blocksJSONObject), ...linksJsonArray],
    };
}

function highlightFigure(figure: any, connections: any, colorSet: any): void {
    if (figure) {
        figure.setColor(colorSet.blockBorder);
        figure.setBackgroundColor(colorSet.blockBackground);
    }

    const highlightConnection = (connection: any) => {
        connection.setColor(colorSet.link);

        const decorator = new draw2d.decoration.connection.ArrowDecorator(8, 8);
        decorator.setBackgroundColor(colorSet.link);
        connection.setTargetDecorator(decorator);
    };
    if (!connections) {
        for (const inputPort of figure?.getInputPorts()?.data) {
            if (inputPort?.getConnections()?.data?.length) {
                const connection = inputPort.getConnections().data[0];
                highlightConnection(connection);
            }
        }
    } else {
        for (const connection of connections) {
            highlightConnection(connection);
        }
    }
}

function highlightRecursively(figure: any, traceStack: any, colorSet: any): any {
    if (traceStack.includes(figure.id)) return {};

    const figuresIds: { [key: string]: string } = {};
    highlightFigure(figure, undefined, colorSet);
    let recursionResults: Record<string, any> = {};
    figuresIds[figure.id] = FIGURE_TYPES.Block;

    for (const inputPort of figure.getInputPorts().data) {
        if (inputPort?.getConnections()?.data?.length) {
            const connection = inputPort.getConnections().data[0];
            figuresIds[connection.id] = FIGURE_TYPES.Connection;
            recursionResults = {
                ...recursionResults,
                ...highlightRecursively(connection.getSource().parent, [...traceStack, figure.id], colorSet),
            };
        }
    }
    return {
        ...figuresIds,
        ...recursionResults,
    };
}

function highlightPath(path: any, colorSet: any): any {
    const figuresIds: { [key: string]: string } = {};

    for (let i = 0; i < path.length; i += 1) {
        figuresIds[path[i].id] = FIGURE_TYPES.Block;
        const connections = [];

        if (path[i]?.getOutputPorts()?.data) {
            for (const port of path[i].getOutputPorts().data) {
                if (port?.getConnections()?.data) {
                    for (const connection of port.getConnections().data) {
                        if (
                            path[i + 1]?.userData?.key &&
                            path[i + 1].userData.key === connection.getTarget().parent.userData.key
                        ) {
                            connections.push(connection);
                            figuresIds[connection.id] = FIGURE_TYPES.Connection;
                        }
                    }
                }
            }
        }

        highlightFigure(path[i], connections, colorSet);
    }

    return figuresIds;
}

function resetHighlight(canvas: any, ids: any): void {
    for (const idsKey in ids) {
        if (ids[idsKey] === 'block') {
            canvas.getFigure(idsKey).setColor(COLOR_SETS.gray.blockBorder);
            canvas.getFigure(idsKey).setBackgroundColor(COLOR_SETS.gray.blockBackground);
        } else if (ids[idsKey] === 'connection') {
            canvas.getLine(idsKey).setColor(COLOR_SETS.gray.link);
            const decorator = new draw2d.decoration.connection.ArrowDecorator(8, 8);
            decorator.setBackgroundColor(COLOR_SETS.gray.link);
            canvas.getLine(idsKey).setTargetDecorator(decorator);
        }
    }
}

function highlightPathToFigure(figure: any, path: any, colorSet: any): any {
    if (path) {
        return highlightPath(path, colorSet);
    }
    return highlightRecursively(figure, [], colorSet);
}

function handleCanvasClick(canvas: any, figure: any, highlightedElementsIds: any): any {
    resetHighlight(canvas, highlightedElementsIds);
    let newHighlightedElementsIds = {};
    if (figure != null) {
        if (figure?.userData?.figureType === FIGURE_TYPES.Block) {
            newHighlightedElementsIds = highlightPathToFigure(figure, undefined, COLOR_SETS.green);
        }
        if (figure?.userData?.figureType === FIGURE_TYPES.Label) {
            newHighlightedElementsIds = highlightPathToFigure(figure.parent, undefined, COLOR_SETS.green);
        }
    }
    return newHighlightedElementsIds;
}

function canvasZoomIn(canvas: any): void {
    const currentZoom = canvas.getZoom();
    if (currentZoom >= maxZoomBoundary) canvas.setZoom((Math.round(currentZoom * 10) - zoomStep) / 10, true);
}

function canvasZoomOut(canvas: any): void {
    const currentZoom = canvas.getZoom();
    if (currentZoom < minZoomBoundary) canvas.setZoom((Math.round(currentZoom * 10) + zoomStep) / 10, true);
}

function updateJsonBasedOnModalResult({
    canvas,
    planDefinition,
    modalStateName,
    selectedTypeFromModal,
    resultOfModal,
}: any) {
    const { states, start_at: startAtFromPlanDefinition } = planDefinition;
    const { arrowsFromDiagram } = getDataFromDiagram(canvas);

    let startAt = startAtFromPlanDefinition;
    const statesConverted = convertObjectOfObjectsToArrayOfObjects(states);

    // Set startAt as source of first arrow from diagram
    if (!startAt && arrowsFromDiagram.length > 0) {
        startAt = arrowsFromDiagram[0].sourceName;
    }

    // Changing style for lines on diagram
    decorateArrows(canvas);

    if (selectedTypeFromModal === STATE_TYPES.Choice) {
        const {
            nextStatesFromModal: selectedChoicesFromModal,
            defaultStateFromModal,
            statesWithConditionsFromModal,
            addingNewStateWithinChoices,
        } = resultOfModal;

        // Creating choices array which contains objects of states from choice with their conditions
        const choices = mapConditions(statesWithConditionsFromModal);

        if (addingNewStateWithinChoices) {
            // find state within states which has choices key in order to add new state into choices
            const index = statesConverted.findIndex(state => state.name === modalStateName);

            // add new state with conditions within choices array
            statesConverted[index].choices.push(choices[0]);
        } else {
            // Creating state object which is type of choice
            const stateChoiceType = {
                name: modalStateName,
                default: defaultStateFromModal,
                type: STATE_TYPES.Choice,
                choices,
            };

            // delete previous object with the same name as stateChoiceType because stateChoiceType is created
            deleteStateIfExistsFromArray(modalStateName, statesConverted);

            // pushing new created object to states
            statesConverted.push(stateChoiceType);
        }

        // add object of state within states for each selected choice
        selectedChoicesFromModal.forEach((choiceName: string) => {
            let oneStateOfChoice: any = statesConverted.find(state => state.name === choiceName);
            const resultForResultPath = choiceName === defaultStateFromModal ? '$.default' : `$.${choiceName}`;

            oneStateOfChoice = {
                ...oneStateOfChoice,
                result_path: resultForResultPath,
            };

            deleteStateIfExistsFromArray(choiceName, statesConverted);
            statesConverted.push(oneStateOfChoice);
        });
    } else if (selectedTypeFromModal === STATE_TYPES.Catch) {
        const { statesWithConditionsFromModal } = resultOfModal;

        // Creating catch array which contains objects of states with their conditions
        const catches = mapConditions(statesWithConditionsFromModal);

        // Creating state object which has catch
        let stateWithCatch = statesConverted.find(state => state.name === modalStateName);

        stateWithCatch = {
            ...stateWithCatch,
            name: modalStateName,
            [STATE_TYPES.Catch]: catches,
        };

        // delete previous object with the same name as stateWithCatch because stateWithCatch is created
        deleteStateIfExistsFromArray(modalStateName, statesConverted);

        // pushing new created object to states
        statesConverted.push(stateWithCatch);
    } else if (selectedTypeFromModal === STATE_TYPES.LoopV2) {
        const { loopStates } = resultOfModal;
        const loopStateIndex = statesConverted.findIndex(state => state.name === modalStateName);

        // Create state with type of Loop
        let loopState = statesConverted[loopStateIndex];
        loopState = {
            ...loopState,
            start_at: loopStates[0],
            type: STATE_TYPES.LoopV2,
        };

        if (loopState?.next === toBeOverriddenTemplateString || loopState?.next === '') {
            delete loopState.next;
        }

        // Delete previous version of that state and push new created one
        deleteStateIfExistsFromArray(modalStateName, statesConverted);

        statesConverted.push(loopState);

        // Update states which are included within loop
        for (const [index, stateName] of loopStates.entries()) {
            const stateIndex = statesConverted.findIndex(state => state.name === stateName);
            let state = statesConverted[stateIndex];

            const next = index !== loopStates.length - 1 ? loopStates[index + 1] : modalStateName;

            state = {
                ...state,
                next,
            };

            deleteStateIfExistsFromArray(stateName, statesConverted);

            statesConverted.push(state);
        }
    }

    const statesToDisplay = convertArrayOfObjectsToObjectOfObjects(statesConverted, true);

    let plan = planDefinition;
    plan = { ...plan, states: statesToDisplay, start_at: startAt };
    return { content: plan };
}

function updateJsonBasedOnDiagramChange({ canvas, planDefinition }: { canvas: any; planDefinition: any }) {
    const { states, start_at: startAtFromPlanDefinition } = planDefinition;
    const { figuresFromDiagram, arrowsFromDiagram, numberOfArrowsFromEachState } = getDataFromDiagram(canvas);

    let startAt = startAtFromPlanDefinition;
    const statesConverted = convertObjectOfObjectsToArrayOfObjects(states);

    const blocksSortedByYAxis = figuresFromDiagram.sort((a: any, b: any) => a.y - b.y);
    const blockNames = blocksSortedByYAxis.map((block: { name: string }) => block.name);

    // Set startAt as source of first arrow from diagram
    if (!startAt && arrowsFromDiagram.length > 0) {
        startAt = arrowsFromDiagram[0].sourceName;
    }

    // Changing style for lines on diagram
    decorateArrows(canvas);

    const statesWhichAreNotSourceOfAnyArrow = numberOfArrowsFromEachState.filter(
        (arrow: { number: number }) => arrow.number === 0,
    );

    // If only one block remains which is not source for any arrow then that block is end block
    if (statesWhichAreNotSourceOfAnyArrow.length === 1) {
        const endBlockName = statesWhichAreNotSourceOfAnyArrow[0].name;
        const indexOfEndBlock = statesConverted.findIndex(state => state.name === endBlockName);

        if (indexOfEndBlock > -1) {
            let endBlock = statesConverted[indexOfEndBlock];
            endBlock = { ...endBlock, end: true, type: 'success' };

            if (endBlock.next !== undefined) {
                delete endBlock.next;
            }

            statesConverted.splice(indexOfEndBlock, 1, endBlock);
        }
    }

    // Logic when user add new state within states, update arrows between states and state which was end state
    // now become state without arrows from or to state
    const isSomeStateEndState = statesConverted.filter(state => state.end);

    if (isSomeStateEndState.length > 0) {
        isSomeStateEndState.forEach(endState => {
            // check is the end state just a block without arrows (it is not source or target of any arrow)
            const doesEndStateHaveSomeArrow = arrowsFromDiagram.filter(
                (arrow: any) => arrow.targetName === endState.name || arrow.sourceName === endState.name,
            );

            // if the end state is just block without arrows then we need to update it
            // (we don't want state to have 'end: true', etc if state doesn't have arrows)
            if (doesEndStateHaveSomeArrow.length === 0) {
                const updatedState = {
                    type: 'task',
                    headers: {},
                    body: {},
                    next: '',
                    name: endState.name,
                };

                const indexOfEndState = statesConverted.findIndex(state => state.name === endState.name);
                statesConverted.splice(indexOfEndState, 1, updatedState);
            }
        });
    }

    // Update every state that is used as source of some arrow
    const uniqueNamesOfArrowSources = [...new Set(arrowsFromDiagram.map((arrow: any) => arrow.sourceName))];

    for (const sourceName of uniqueNamesOfArrowSources) {
        let updatingState = statesConverted.filter(state => state.name === sourceName)[0];
        const indexOfUpdatingState = statesConverted.findIndex(state => state.name === sourceName);
        const arrowsFromUpdatingState = arrowsFromDiagram.filter((arrow: any) => arrow.sourceName === sourceName);

        // update 'next' if state doesn't include choices, catch or loop
        if (
            arrowsFromUpdatingState.length === 1 &&
            !updatingState?.catch &&
            !updatingState?.choice &&
            !updatingState?.start_at
        ) {
            updatingState = { ...updatingState, next: arrowsFromUpdatingState[0].targetName };

            if (updatingState.end !== undefined) {
                delete updatingState.end;
            }
            if (updatingState.type === 'success') {
                updatingState.type = 'task';
            }
            // update 'next' for state which is type loop (contains start_at)
        } else if (updatingState?.start_at && arrowsFromUpdatingState.length > 1) {
            if (!updatingState?.next) {
                const targetState = arrowsFromUpdatingState.filter(
                    (arrow: any) => arrow.targetName !== updatingState.start_at,
                )[0].targetName;

                updatingState = { ...updatingState, next: targetState };
            } else {
                const targetState = arrowsFromUpdatingState.filter(
                    (arrow: any) =>
                        arrow.targetName !== updatingState.start_at && arrow.targetName !== updatingState.next,
                )?.[0]?.targetName;

                if (targetState) {
                    updatingState = { ...updatingState, next: targetState };
                }
            }
            // update 'next' for state which has catch
        } else if (updatingState?.catch && arrowsFromUpdatingState.length > 1) {
            const catches = updatingState.catch.map((cx: { next: any }) => cx.next);

            if (!updatingState?.next) {
                const targetState = arrowsFromUpdatingState.filter(
                    (arrow: any) => !catches.includes(arrow.targetName),
                )[0].targetName;

                updatingState = { ...updatingState, next: targetState };
            } else {
                const targetState = arrowsFromUpdatingState.filter(
                    (arrow: any) => !catches.includes(arrow.targetName) && arrow.targetName !== updatingState.next,
                )?.[0]?.targetName;

                if (targetState) {
                    updatingState = { ...updatingState, next: targetState };
                }
            }
        }

        statesConverted.splice(indexOfUpdatingState, 1, updatingState);
    }

    // Sort states by x axios
    const sortedStates = sortArrayBasedOnAnotherArray(statesConverted, blockNames, 'name');
    const statesToDisplay = convertArrayOfObjectsToObjectOfObjects(sortedStates, true);

    let plan = planDefinition;
    plan = { ...plan, states: statesToDisplay, start_at: startAt };
    return { content: plan };
}

function adjustCanvasDimensions(setup: SetupForDiagram, canvas: any): void {
    let xMax = 0;
    let yMax = 0;
    canvas.getFigures().each((i: any, f: any) => {
        xMax = Math.max(f.x, xMax);
        yMax = Math.max(f.y, yMax);
    });
    const calculatedCanvasWidth = xMax + setup.blockWidth + setup.bufferZoneSize;
    const calculatedCanvasHeight = yMax + setup.blockHeight + setup.bufferZoneSize;
    const canvasWrapperWidth = canvas.getWidth();
    const canvasWrapperHeight = canvas.getHeight();

    canvas.setDimension(
        Math.max(calculatedCanvasWidth, canvasWrapperWidth),
        Math.max(calculatedCanvasHeight, canvasWrapperHeight),
    );
    canvas.getFigures().each((i: any, f: any) => {
        f.installEditPolicy(
            new draw2d.policy.figure.RegionEditPolicy(
                setup.xPadding,
                setup.yPadding,
                Math.max(calculatedCanvasWidth, canvasWrapperWidth) - setup.xPadding - 40,
                Math.max(calculatedCanvasHeight, canvasWrapperHeight) - setup.yPadding - 40,
            ),
        );
    });
}

function renderDiagramElements(
    setup: SetupForDiagram,
    canvasID: string,
    draw2dCanvas: any,
    remappedData: any,
    onClickCallbackFunc: (figure: any) => void,
    planDefinition: any,
): any {
    // Get config for draw2d from remapped data
    const jsonDocument: any = mapDataToDraw2dJSON(setup, remappedData);

    let canvas: any;
    if (!draw2dCanvas) {
        // Get canvas object
        canvas = new draw2d.Canvas(canvasID);
    } else {
        canvas = draw2dCanvas;
        canvas.clear();
    }

    // Render elements from config into canvas
    const reader = new draw2d.io.json.Reader();
    reader.unmarshal(canvas, jsonDocument.draw2dJSON);

    adjustCanvasDimensions(setup, canvas);

    // Add label to blocks separately to be able to put them in block center using locators
    decorateLabels(setup, canvas, jsonDocument.blocksJSONObject);

    // Add decoration to arrows
    decorateArrows(canvas);

    // Need list of arrows after every change on diagram,
    // so calling callback function here just to parse current arrows to vue component
    const { numberOfArrowsFromEachState, arrowsFromDiagram } = getDataFromDiagram(canvas);
    if (typeof onClickCallbackFunc === 'function') {
        onClickCallbackFunc({ arrowsPerState: numberOfArrowsFromEachState, allArrows: arrowsFromDiagram });
    }

    // Add CanvasPolicy to catch the onClick method
    let highlightedElementsIds = {};
    canvas.installEditPolicy(
        new draw2d.policy.canvas.CanvasPolicy({
            onClick(figure: any) {
                const updatedJSON = updateJsonBasedOnDiagramChange({ canvas, planDefinition });

                if (typeof onClickCallbackFunc === 'function') {
                    onClickCallbackFunc({
                        figure,
                        updatedJSON,
                        canvas,
                    });
                }

                if (setup.allowHighlightClick) {
                    highlightedElementsIds = handleCanvasClick(canvas, figure, highlightedElementsIds);
                }
            },
            onMouseUp(figure: any) {
                adjustCanvasDimensions(setup, canvas);
                const updatedJSON = updateJsonBasedOnDiagramChange({ canvas, planDefinition });

                if (typeof onClickCallbackFunc === 'function') {
                    onClickCallbackFunc({
                        figure,
                        updatedJSON,
                        canvas,
                    });
                }
            },
        }),
    );

    return canvas;
}
/* Draw2d render functions */

// Draw call function that draw all the objects and update stage
function drawPlanDiagram(
    canvasID: string,
    draw2dCanvas: any,
    planDefinition: any,
    startKey: any,
    setup: SetupForDiagram,
    onClickCallbackFunc: () => void,
): any {
    const { states } = planDefinition;
    if (!states) {
        if (draw2dCanvas) {
            draw2dCanvas.clear();
        }
        return null;
    }

    const localSetup = setup || { ...defaultSetup };

    const renderList = {};
    // Remap data to get blocks to render, their links and coordinates
    recursiveRemap(localSetup, renderList, states, startKey, 1, {}, []);

    // Render diagram using remapped data
    return renderDiagramElements(localSetup, canvasID, draw2dCanvas, renderList, onClickCallbackFunc, planDefinition);
}

export default {
    ACTION_SIDEBAR_TABS,
    FIGURE_TYPES,
    COLOR_SETS,
    SIDEBAR_WIDTHS,
    drawPlanDiagram,
    canvasZoomIn,
    canvasZoomOut,
    renderDiagramElements,
    recursiveRemap,
    defaultSetup,
    handleCanvasClick,
    highlightPathToFigure,
    updateJsonBasedOnModalResult,
    getDataFromDiagram,
};
