<template>
    <div class="row-state-controls">
        <!-- row reverse flexbox here to make them right-aligned -->
        <IconButton
            v-for="action in formattedActions"
            :key="action"
            :label="formatInputLabel(action)"
            :icon="getIconForAction(action)"
            :href="entityActionToHrefFunction ? getHrefFromAction(action) : null"
            @iconClick="stateChangeAction(action)"
        />
        <OfferImpactModal
            v-if="showPauseOfferDialog"
            :offer="entity"
            @continue="onPauseOfferConfirmed"
            @cancel="onPauseOfferCanceled"
        />
    </div>
</template>

<script>
// vuex
import { mapMutations, mapActions, mapGetters } from 'vuex';
import Actions, { Getters, Mutations } from '@/store/mutation-types';

// http
import { approveEntity, deleteEntity, pauseEntity, unapproveEntity } from '@/__new__/services/dno/pc/entityState';
import {
    updatePrizeEngineState,
    updateRewardRuleStateV4,
    updateRewardPayoutState,
    updatePrizeState,
    updateLotteryState,
    deleteRewardPayoutDraft,
    deletePrizeDraft,
    deletePrizeEngineDraft,
    deleteLotteryDraft,
} from '@/__new__/services/dno/rewards/http/rewards';
import { deleteOETemplate } from '@/__new__/services/dno/orchestrationengine/http/orchestration-engine';
import { deleteEntityDraft } from '@/__new__/services/dno/pc/entities';
import { updatestatePromotion } from '@/modules/promotions/http/promotion';
import { updatestateVoucherSet, deleteVoucherSetDraft } from '@/modules/rewards/http/vouchers';

// helpers
import {
    EntityStateMapping,
    EntityActions,
    entityActionList,
    EntityStateActionMapping,
    StateActionsMapping,
} from '@/common/baseStatesHelper';
import { STATUS_CODES } from '@/common/commonHelper';
import { entityTypeDisplayName, onlyFirstLetterUppercase } from '@/common/utils';
import RouteNames from '@/router/routeNames';
import ENTITY_TYPES from '@/common/entities/entityTypes';
import { getAffectedEntities, entityTypeToEntityLabel } from '@/common/entities/entityHelper';
import { ALERT_TYPES } from '@/common/alerts/Alert';
import i18n from '@/i18n';
import * as Sentry from '@sentry/vue';
import { ORDER_ENTITY_STATES } from '@/__new__/features/customerCare/common/orderStateHelper';
import { templatesReservedKeys } from '@/__new__/features/orchestrationengine/common/orchestrationReservedKeys';
import { ICON_TYPES } from '@/common/iconHelper';
import { shouldEntityImportResetVersion } from '@/common/importEntitiesHelper';

// models
import Button from '@/common/button/Button';

// components
import IconButton from '@/components/partials/IconButton.vue';
import OfferImpactModal from '@/components/partials/table/OfferImpactModal.vue';

const ENTITIES_WITH_EMIT_LOGIC = [
    ENTITY_TYPES.SEGMENT,
    ENTITY_TYPES.STATIC_FILTER,
    ENTITY_TYPES.EVENT,
    ENTITY_TYPES.CAMPAIGN,
    ENTITY_TYPES.REDSHIFT_CONFIG,
    ENTITY_TYPES.KAFKA_SINK_CONFIG,
    ENTITY_TYPES.API_INVOKER_SINK_CONFIG,
    ENTITY_TYPES.ORD_CONFIG,
    ENTITY_TYPES.TEMPLATE,
    ENTITY_TYPES.VOUCHER_SET,

    // Charging
    ENTITY_TYPES.CHARGING_SPECIFICATION,
    ENTITY_TYPES.CHARGING_SPECIFICATIONS_GROUP,
    ENTITY_TYPES.POLICY_COUNTER,
    ENTITY_TYPES.POLICY_RULE,
    ENTITY_TYPES.RATING_GROUP,
    ENTITY_TYPES.USAGE_COUNTER,
    ENTITY_TYPES.CONDITION_PARAMETERS,
    ENTITY_TYPES.WALLET_COUNTERS,
    ENTITY_TYPES.CONTENT_TYPES,
    ENTITY_TYPES.TARIFF_SPECIFICATION,
    ENTITY_TYPES.TARIFF_SPECIFICATION_GROUP,
];

export default {
    name: 'RowStateControls',
    components: {
        IconButton,
        OfferImpactModal,
    },

    props: {
        entity: {
            type: Object,
            required: true,
        },
        entityType: {
            type: String,
            required: true,
        },
        forbidDeleteAutomatically: {
            type: Boolean,
            default: true,
        },
        emitEntityActions: {
            type: Boolean,
            default: false,
        },
        allowedActionsExternal: {
            type: Array,
            default: () => [],
        },
        /**
         * Summary:
         * This property is used to determine links for our icon button,
         * so we can use browser supported navigation operations (eg: 'Open in New Tab')
         *
         * It's needed because the icons are not aware of links and merely emit events
         * which are propagated upwards.
         *
         * Details:
         * Map from string representation of an entity action (eg: 'EDIT')
         * to a function can be used to build a link for that entity.
         *
         * The function must accept the entity as a parameter and return an href string.
         * Simplified example:
         *      entityActionToHrefFunction = {
         *          'EDIT': (entity) => `/offer/${entity.id}/edit`,
         *          'READ_ONLY': (entity) => `/offer/${entity.id}/view`,
         *      }
         */
        entityActionToHrefFunction: {
            type: Object,
            required: false,
            default: () => ({}),
        },
    },

    data() {
        return {
            showPauseOfferDialog: false,
            hoveredButton: {
                type: String,
                default: null,
            },
            alertButtons: {
                details: new Button({
                    label: this.$i18n.t('generic.seeDetails'),
                    alertType: ALERT_TYPES.warning,
                }),
                confirmButton: new Button({
                    label: this.$i18n.t('generic.confirm'),
                    alertType: ALERT_TYPES.warning,
                }),
                deleteButton: new Button({
                    label: this.$i18n.t('generic.delete'),
                    alertType: ALERT_TYPES.warning,
                }),
                pauseButton: new Button({
                    label: this.$i18n.t('generic.pause'),
                    alertType: ALERT_TYPES.warning,
                }),
                approveButton: new Button({
                    label: this.$i18n.t('generic.approve'),
                    alertType: ALERT_TYPES.warning,
                    id: 1,
                }),
                cloneButton: new Button({
                    label: this.$i18n.t('generic.clone'),
                    alertType: ALERT_TYPES.warning,
                }),
            },
            ICON_TYPES,
        };
    },

    computed: {
        ...mapGetters('entitymutation', [Getters.GET_AFFECTED_ENTITIES]),
        formattedActions() {
            // TODO: remove once we deprecate OrderDetails component in old Customer Care
            if (
                this.entityType === ENTITY_TYPES.ORDER &&
                (this.entity.state === ORDER_ENTITY_STATES.PENDING || this.entity.state === ORDER_ENTITY_STATES.ACTIVE)
            ) {
                if (this.entity.replaceInfo) {
                    return ['CANCEL'];
                }
                return ['EDIT'];
            }
            return entityActionList().filter(
                action => this.allowedActions() && this.allowedActions().includes(EntityActions[action]),
            );
        },
        entityDisplayName() {
            return entityTypeDisplayName(this.entityType);
        },
    },

    methods: {
        ...mapMutations('entitymutation', [Mutations.SET_AFFECTED_ENTITIES, Mutations.TOGGLE_SHOW_MODAL]),
        ...mapActions('productcatalog', [Actions.PC_REQUEST_ENTITIES_BY_TYPE_AND_IDS]),
        ...mapActions('rewards', [Actions.REQUEST_REWARDS_ENTITIES_BY_TYPE]),
        ...mapActions('promotions', [Actions.REQUEST_PROMOTIONS]),
        allowedActions() {
            if (this.allowedActionsExternal.length) {
                return this.allowedActionsExternal;
            }

            if (this.entityType === ENTITY_TYPES.SERVICE) {
                return [
                    this.entity.state === EntityStateMapping.UNAPPROVED && EntityActions.APPROVE,
                    EntityActions.CLONE,
                    EntityActions.EDIT,
                    EntityActions.DELETE,
                    EntityActions.READ_ONLY,
                ];
            }

            if (this.entityType === ENTITY_TYPES.OFFER) {
                return EntityStateActionMapping.allowedActions(
                    this.entity.state ? this.entity.state : EntityStateMapping.APPROVED,
                    this.entity.start_time,
                    this.entity.end_time,
                );
            }

            if (this.entityType === ENTITY_TYPES.ORCHESTRATION_ENGINE_PLAN) {
                // special handling for the OE plans considering it's not a PC entity
                return [EntityActions.DETAILS];
            }

            if (this.entityType === ENTITY_TYPES.ORCHESTRATION_ENGINE_TEMPLATES) {
                if (!templatesReservedKeys.includes(this.entity.templateName)) {
                    return [EntityActions.DELETE, EntityActions.EDIT];
                }
                return [];
            }

            if (this.entityType === ENTITY_TYPES.DOCUMENT_TEMPLATES) {
                return [EntityActions.CLONE, EntityActions.EDIT];
            }

            if (this.entityType === ENTITY_TYPES.DOCUMENT_ASSETS) {
                return [EntityActions.DOWNLOAD];
            }

            if (this.entityType === ENTITY_TYPES.TEMPLATE && this.entity.isInternal) {
                return [EntityActions.CLONE, EntityActions.READ_ONLY];
            }

            return StateActionsMapping[this.entity.state];
        },
        async dispatchReloadingAction() {
            switch (this.entityType) {
                case ENTITY_TYPES.OFFER:
                case ENTITY_TYPES.PRODUCT:
                case ENTITY_TYPES.SERVICE:
                case ENTITY_TYPES.RESOURCE:
                    await this[Actions.PC_REQUEST_ENTITIES_BY_TYPE_AND_IDS]({ entityType: this.entityType });
                    break;
                case ENTITY_TYPES.CATEGORY:
                    await Promise.all([
                        this[Actions.PC_REQUEST_ENTITIES_BY_TYPE_AND_IDS]({ entityType: ENTITY_TYPES.OFFER }),
                        this[Actions.PC_REQUEST_ENTITIES_BY_TYPE_AND_IDS]({ entityType: ENTITY_TYPES.CATEGORY }),
                        this[Actions.PC_REQUEST_ENTITIES_BY_TYPE_AND_IDS]({ entityType: ENTITY_TYPES.SERVICE }),
                    ]);
                    break;
                case ENTITY_TYPES.REWARD_RULE:
                    await this[Actions.REQUEST_REWARDS_ENTITIES_BY_TYPE](ENTITY_TYPES.REWARD_RULE);
                    break;
                case ENTITY_TYPES.REWARD_PAYOUT:
                    await this[Actions.REQUEST_REWARDS_ENTITIES_BY_TYPE](ENTITY_TYPES.REWARD_PAYOUT);
                    break;
                case ENTITY_TYPES.PRIZE:
                    await Promise.all([
                        this[Actions.REQUEST_REWARDS_ENTITIES_BY_TYPE](ENTITY_TYPES.PRIZE),
                        this[Actions.PC_REQUEST_ENTITIES_BY_TYPE_AND_IDS]({ entityType: ENTITY_TYPES.PRODUCT }),
                    ]);
                    break;
                case ENTITY_TYPES.PRIZE_ENGINE:
                    await Promise.all([
                        this[Actions.REQUEST_REWARDS_ENTITIES_BY_TYPE](ENTITY_TYPES.PRIZE_ENGINE),
                        this[Actions.REQUEST_REWARDS_ENTITIES_BY_TYPE](ENTITY_TYPES.PRIZE),
                    ]);
                    break;
                case ENTITY_TYPES.LOTTERY:
                    await this[Actions.REQUEST_REWARDS_ENTITIES_BY_TYPE](ENTITY_TYPES.LOTTERY);
                    break;
                case ENTITY_TYPES.VOUCHER_SET:
                    await this[Actions.REQUEST_REWARDS_ENTITIES_BY_TYPE](ENTITY_TYPES.VOUCHER_SET);
                    break;
                case ENTITY_TYPES.PROMOTION:
                    await this.$store.dispatch(`promotions/${Actions.REQUEST_PROMOTIONS}`);
                    break;

                // orchestration
                case ENTITY_TYPES.ORCHESTRATION_ENGINE_PLAN:
                    await this.$store.dispatch(`orchestrationengine/${Actions.REQUEST_OE_PLANS}`);
                    break;
                case ENTITY_TYPES.ORCHESTRATION_ENGINE_TEMPLATES:
                    await this.$store.dispatch(`orchestrationengine/${Actions.REQUEST_OE_TEMPLATES}`);
                    break;

                // documents
                case ENTITY_TYPES.DOCUMENT_TEMPLATES:
                    await this[Actions.REQUEST_DOCUMENT_TEMPLATES]();
                    break;
                default:
            }
        },
        mapActionToRoute() {
            switch (this.entityType) {
                case ENTITY_TYPES.OFFER:
                    return RouteNames.PRODUCT_CATALOG_OFFERS_EDITOR;
                case ENTITY_TYPES.PRODUCT:
                    return RouteNames.PRODUCT_CATALOG_PRODUCTS_EDITOR;
                case ENTITY_TYPES.SERVICE:
                    return RouteNames.PRODUCT_CATALOG_SERVICE_EDITOR_V2;
                case ENTITY_TYPES.CATEGORY:
                    return RouteNames.PRODUCT_CATALOG_CATEGORY_EDITOR;
                case ENTITY_TYPES.REWARD_RULE:
                    return RouteNames.REWARD_EDITOR;
                case ENTITY_TYPES.REWARD_PAYOUT:
                    return RouteNames.REWARD_PAYOUT_EDITOR;
                case ENTITY_TYPES.PRIZE:
                    return RouteNames.PRIZE_EDITOR;
                case ENTITY_TYPES.PRIZE_ENGINE:
                    return RouteNames.PRIZE_ENGINE_EDITOR;
                case ENTITY_TYPES.VOUCHER_SET:
                    return RouteNames.VOUCHER_SET_EDITOR;
                case ENTITY_TYPES.CONTENT_HOSTING_ITEM:
                    return RouteNames.CONTENT_HOSTING_EDITOR;
                case ENTITY_TYPES.PROMOTION:
                    return RouteNames.PROMOTIONS_EDITOR;
                case ENTITY_TYPES.LOTTERY:
                    return RouteNames.LOTTERY_EDITOR;

                // orchestration
                case ENTITY_TYPES.ORCHESTRATION_ENGINE_PLAN:
                    return RouteNames.ORCHESTRATION_ENGINE_EDITOR;
                case ENTITY_TYPES.ORCHESTRATION_ENGINE_TEMPLATES:
                    return RouteNames.ORCHESTRATION_ENGINE_TEMPLATES_EDITOR;

                // documents
                case ENTITY_TYPES.DOCUMENT_TEMPLATES:
                    return RouteNames.DOCUMENT_TEMPLATES_EDITOR;
                default:
            }
            return null;
        },
        async deleteAction() {
            if (ENTITIES_WITH_EMIT_LOGIC.includes(this.entityType)) {
                this.$emit('delete', this.entity.id);
                return;
            }

            try {
                this.$Progress.start();
                if (this.forbidDeleteAutomatically) {
                    this.showDeleteAlert();
                } else if (this.entity.state !== STATUS_CODES.NA) {
                    await deleteEntity(this.entityType, this.entity.id, this.entity.version);
                } else {
                    await deleteEntityDraft(this.entityType, this.entity.id);
                    if (this.entityType === ENTITY_TYPES.REWARD_PAYOUT) {
                        await deleteRewardPayoutDraft(this.entity.id);
                    } else if (this.entityType === ENTITY_TYPES.VOUCHER_SET) {
                        await deleteVoucherSetDraft(this.entity.id);
                    } else if (this.entityType === ENTITY_TYPES.PRIZE) {
                        await deletePrizeDraft(this.entity.id);
                    } else if (this.entityType === ENTITY_TYPES.PRIZE_ENGINE) {
                        await deletePrizeEngineDraft(this.entity.id);
                    } else if (this.entityType === ENTITY_TYPES.LOTTERY) {
                        await deleteLotteryDraft(this.entity.id);
                    }
                }
            } catch (e) {
                Sentry.captureException(e);
                this.$Progress.fail();
                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('alertMessage.errorDoingSmthTryAgain', {
                        action: 'deleting',
                        entityName: 'entity',
                    }),
                });
            } finally {
                if (!this.forbidDeleteAutomatically) {
                    await this.dispatchReloadingAction();
                }
                this.$Progress.finish();
            }
        },
        async approveEntity() {
            try {
                await approveEntity(this.entityType, this.entity.id, this.entity.version);
            } catch (e) {
                Sentry.captureException(e);
                this.$Progress.fail();
                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('alertMessage.errorDoingSmthTryAgain', {
                        action: this.$i18n.t('generic.approving'),
                        entityName: entityTypeToEntityLabel[this.entityType],
                    }),
                });
            }
        },
        async deleteEntity() {
            try {
                this.$Progress.start();

                // ToDo: refactor this whole function in terms of POR-3128
                // it should just emit an event to up to the parent page-component which should do the handling

                if (this.entityType === ENTITY_TYPES.PROMOTION) {
                    // special handling for promotions considering that it's not a PC entity
                    await updatestatePromotion(this.entity.id, this.entity.version, EntityStateMapping.DELETED);
                } else if (this.entityType === ENTITY_TYPES.VOUCHER_SET) {
                    if (this.entity.state !== STATUS_CODES.NA) {
                        await updatestateVoucherSet(this.entity.id, this.entity.version, EntityStateMapping.DELETED);
                    }
                    await deleteVoucherSetDraft(this.entity.id);
                } else if (this.entityType === ENTITY_TYPES.REWARD_PAYOUT) {
                    if (this.entity.state !== STATUS_CODES.NA) {
                        await deleteEntity(this.entityType, this.entity.id, this.entity?.version, this.entity);
                    }
                    await deleteRewardPayoutDraft(this.entity.id);
                } else if (this.entityType === ENTITY_TYPES.REWARD_RULE) {
                    await deleteEntity(this.entityType, this.entity.id, this.entity?.version, this.entity);
                } else if (this.entityType === ENTITY_TYPES.PRIZE) {
                    if (this.entity.state !== STATUS_CODES.NA) {
                        await deleteEntity(this.entityType, this.entity.id, this.entity?.version, this.entity);
                    }
                    await deletePrizeDraft(this.entity.id);
                } else if (this.entityType === ENTITY_TYPES.PRIZE_ENGINE) {
                    if (this.entity.state !== STATUS_CODES.NA) {
                        await deleteEntity(this.entityType, this.entity.id, this.entity?.version, this.entity);
                    }
                    await deletePrizeEngineDraft(this.entity.id);
                } else if (this.entityType === ENTITY_TYPES.ORCHESTRATION_ENGINE_TEMPLATES) {
                    await deleteOETemplate(this.entity.templateName);
                } else if (this.entity.state !== STATUS_CODES.NA) {
                    await deleteEntity(this.entityType, this.entity.id, this.entity?.version, this.entity);
                } else {
                    await deleteEntityDraft(this.entityType, this.entity.id);
                }
                await this.dispatchReloadingAction();
                this.$Progress.finish();
                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('alertMessage.successMessageWithoutRedirect', {
                        entityName: entityTypeToEntityLabel[this.entityType],
                        action: this.$i18n.t('generic.stateMap.deleted').toLowerCase(),
                    }),
                    type: ALERT_TYPES.success,
                });
            } catch (e) {
                Sentry.captureException(e);
                this.$Progress.fail();
                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('alertMessage.errorDoingSmthTryAgain', {
                        action: 'deleting',
                        entityName: entityTypeToEntityLabel[this.entityType],
                    }),
                });
            }
        },
        async pauseEntities(entities) {
            try {
                this.$Progress.start();
                await Promise.all(entities.map(entity => pauseEntity(entity.entityType, entity.id, entity.version)));
                await this.dispatchReloadingAction();
                this.$Progress.finish();
            } catch (e) {
                Sentry.captureException(e);
                this.$Progress.fail();
                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('alertMessage.errorDoingSmthTryAgain', {
                        action: 'pausing',
                        entityName: entityTypeToEntityLabel[this.entityType],
                    }),
                });
            }
        },
        async unapproveEntity() {
            try {
                this.$Progress.start();
                await unapproveEntity(this.entityType, this.entity.id, this.entity.version);
                await this.dispatchReloadingAction();
                this.$Progress.finish();
            } catch (e) {
                Sentry.captureException(e);
                this.$Progress.fail();
                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('alertMessage.errorDoingSmthTryAgain', {
                        action: 'unapproving',
                        entityName: entityTypeToEntityLabel[this.entityType],
                    }),
                });
            }
        },
        stopAction() {
            this.$emit('stop', this.entity.id);
        },
        startAction() {
            this.$emit('start', this.entity.id);
        },

        approveAction() {
            if (ENTITIES_WITH_EMIT_LOGIC.includes(this.entityType)) {
                this.$emit('approve', { id: this.entity.id, data: this.entity });
                return;
            }

            this.$eventBus.$emit('showAlert', {
                message: this.$i18n.t('alerts.areYouSureApprove'),
                type: ALERT_TYPES.warning,
                buttons: [this.alertButtons.approveButton],
            });

            this.$eventBus.$once('buttonClicked', async buttonId => {
                if (buttonId === this.alertButtons.approveButton.id) {
                    try {
                        this.$Progress.start();
                        if (this.entityType === ENTITY_TYPES.PROMOTION) {
                            // special handling for promotions considering that it's not a PC entity
                            await updatestatePromotion(
                                this.entity.id,
                                this.entity.version,
                                EntityStateMapping.APPROVED,
                            );
                        } else if (this.entityType === ENTITY_TYPES.REWARD_RULE) {
                            await updateRewardRuleStateV4(
                                this.entity.id,
                                this.entity.version,
                                EntityStateMapping.APPROVED,
                            );
                        } else if (this.entityType === ENTITY_TYPES.REWARD_PAYOUT) {
                            await updateRewardPayoutState(
                                this.entity.id,
                                this.entity.version,
                                EntityStateMapping.APPROVED,
                            );
                        } else if (this.entityType === ENTITY_TYPES.PRIZE) {
                            await updatePrizeState(this.entity.id, this.entity.version, EntityStateMapping.APPROVED);
                        } else if (this.entityType === ENTITY_TYPES.PRIZE_ENGINE) {
                            await updatePrizeEngineState(
                                this.entity.id,
                                this.entity.version,
                                EntityStateMapping.APPROVED,
                            );
                        } else if (this.entityType === ENTITY_TYPES.LOTTERY) {
                            await updateLotteryState(this.entity.id, this.entity.version, EntityStateMapping.APPROVED);
                        } else if (this.entityType === ENTITY_TYPES.VOUCHER_SET) {
                            await updatestateVoucherSet(
                                this.entity.id,
                                this.entity.version,
                                EntityStateMapping.APPROVED,
                            );
                        } else {
                            // Handling for PC V3 enities types
                            await this.approveEntity();
                        }
                        await this.dispatchReloadingAction();
                        this.$Progress.finish();
                        this.$eventBus.$emit('showAlert', {
                            message: this.$i18n.t('alertMessage.successMessageWithoutRedirect', {
                                entityName: entityTypeToEntityLabel[this.entityType],
                                action: 'approved',
                            }),
                            type: ALERT_TYPES.success,
                        });
                    } catch (e) {
                        this.$Progress.fail();
                        Sentry.captureException(e);
                        this.$eventBus.$emit('showAlert', {
                            // this.$i18n has value of null at moment of execution
                            // if someone debugs this bug please be so kind and explain
                            // to team what's going on
                            message: i18n.t('entityStatesControl.couldNotApprove'),
                            type: ALERT_TYPES.error,
                        });
                    }
                }
            });
        },
        pauseAction() {
            if (ENTITIES_WITH_EMIT_LOGIC.includes(this.entityType)) {
                this.$emit('pause', { id: this.entity.id, version: this.entity.version });
                return;
            }

            if (this.entityType === ENTITY_TYPES.OFFER) {
                this.showPauseOfferDialog = true;
            } else {
                this.showPauseAlert();
            }
        },
        showPauseAlert() {
            this.$eventBus.$emit('showAlert', {
                message: this.$i18n.t('alerts.areYouSurePause'),
                type: ALERT_TYPES.warning,
                buttons: [this.alertButtons.pauseButton],
            });
            this.$eventBus.$once('buttonClicked', async buttonId => {
                if (buttonId === this.alertButtons.pauseButton.id) {
                    try {
                        await this.pauseEntities([this.entity]);
                        await this.dispatchReloadingAction();
                        this.$eventBus.$emit('showAlert', {
                            message: this.$i18n.t('alertMessage.successMessageWithoutRedirect', {
                                entityName: entityTypeToEntityLabel[this.entityType],
                                action: 'paused',
                            }),
                            type: ALERT_TYPES.success,
                        });
                    } catch (e) {
                        Sentry.captureException(e);
                        this.$eventBus.$emit('showAlert', {
                            // this.$i18n has value of null at moment of execution
                            // if someone debugs this bug please be so kind and explain
                            // to team what's going on
                            message: i18n.t('entityStatesControl.couldNotPause'),
                            type: ALERT_TYPES.error,
                        });
                    }
                }
            });
        },
        onPauseOfferConfirmed(offersToPause) {
            this.showPauseOfferDialog = false;
            this.pauseEntities(offersToPause);
        },
        onPauseOfferCanceled() {
            this.showPauseOfferDialog = false;
        },
        unapproveAction() {
            this.$eventBus.$emit('showAlert', {
                message: this.$i18n.t('alerts.areYouSureUnapprove'),
                type: ALERT_TYPES.warning,
                buttons: [this.alertButtons.confirmButton],
            });
            this.$eventBus.$once('buttonClicked', async buttonId => {
                if (buttonId === this.alertButtons.confirmButton.id) {
                    try {
                        this.$Progress.start();
                        await this.unapproveEntity();
                        this.$eventBus.$emit('showAlert', {
                            message: this.$i18n.t('alertMessage.successMessageWithoutRedirect', {
                                entityName: entityTypeToEntityLabel[this.entityType],
                                action: 'pause',
                            }),
                            type: ALERT_TYPES.success,
                        });
                    } catch (e) {
                        Sentry.captureException(e);
                        this.$eventBus.$emit('showAlert', {
                            // this.$i18n has value of null at moment of execution
                            // if someone debugs this bug please be so kind and explain
                            // to team what's going on
                            message: i18n.t('entityStatesControl.couldNotUnapprove'),
                            type: ALERT_TYPES.error,
                        });
                    }
                }
            });
        },
        editAction() {
            // TODO: remove once we deprecate OrderDetails component in old Customer Care
            if (this.entityType === ENTITY_TYPES.ORDER) {
                if (this.entity.replaceInfo) {
                    this.$emit('cancelEditOrder');
                    return;
                }
                this.$emit('editOrder', this.entity);
                return;
            }
            const { id } = this.entity;
            if (ENTITIES_WITH_EMIT_LOGIC.includes(this.entityType) || this.emitEntityActions) {
                this.$emit('edit', id);
                return;
            }

            this.$router.push({
                name: this.mapActionToRoute(),
                params: { id, companyId: this.$route.params.companyId },
            });
        },
        detailsAction() {
            let { id } = this.entity;

            if (this.entityType === ENTITY_TYPES.ORCHESTRATION_ENGINE_PLAN) {
                id = this.entity.planId;
            }

            if (ENTITIES_WITH_EMIT_LOGIC.includes(this.entityType) || this.emitEntityActions) {
                this.$emit('details', id);
                return;
            }

            this.$router.push({
                name: this.mapActionToRoute(),
                params: { id, companyId: this.$route.params.companyId },
            });
        },
        sendEventAction() {
            this.$emit('sendEvent', this.entity.id);
        },
        cloneAction() {
            const { id } = this.entity;
            if (ENTITIES_WITH_EMIT_LOGIC.includes(this.entityType) || this.emitEntityActions) {
                this.$emit('clone', id);
                return;
            }

            this.$eventBus.$emit('showAlert', {
                message: this.$i18n.t('alerts.areYouSureClone'),
                type: ALERT_TYPES.warning,
                buttons: [this.alertButtons.cloneButton],
            });

            this.$eventBus.$once('buttonClicked', buttonId => {
                if (buttonId === this.alertButtons.cloneButton.id) {
                    const clone = true;

                    this.$router.push({
                        name: this.mapActionToRoute(),
                        params: {
                            id,
                            clone,
                            companyId: this.$route.params.companyId,
                        },
                    });
                }
            });
        },
        readOnlyAction() {
            const { id } = this.entity;
            if (ENTITIES_WITH_EMIT_LOGIC.includes(this.entityType) || this.emitEntityActions) {
                this.$emit('readonly', id);
                return;
            }

            this.$router.push({
                name: this.mapActionToRoute(),
                params: {
                    id,
                    readonly: true,
                    companyId: this.$route.params.companyId,
                },
            });
        },
        downloadAction() {
            const { id } = this.entity;
            return this.$emit('download', id);
        },
        stateChangeAction(a) {
            switch (a) {
                case 'APPROVE':
                    return this.approveAction();
                case 'UNAPPROVE':
                    return this.unapproveAction();
                case 'CLONE':
                    return this.cloneAction();
                case 'EDIT':
                    return this.editAction();
                case 'CANCEL':
                    return this.editAction();
                case 'DELETE':
                    return this.deleteAction();
                case 'PAUSE':
                    return this.pauseAction();
                case 'UNPAUSE':
                    return this.approveAction();
                case 'DETAILS':
                    return this.detailsAction();
                case 'STOP':
                    return this.stopAction();
                case 'START':
                    return this.startAction();
                case 'SEND_EVENT':
                    return this.sendEventAction();
                case 'READ_ONLY':
                    return this.readOnlyAction();
                case 'DOWNLOAD':
                    return this.downloadAction();
                default:
            }
            return null;
        },
        isDisabledState(s) {
            return this.allowedActions().includes(EntityStateMapping[s]);
        },
        formatInputLabel(a) {
            if (a === 'READ_ONLY') {
                return this.$i18n.t('generic.view');
            }
            return onlyFirstLetterUppercase(a);
        },
        getIconForAction(action) {
            switch (action) {
                case 'APPROVE':
                    return ICON_TYPES.CHECK;
                case 'UNAPPROVE':
                    return ICON_TYPES.CLOSE;
                case 'CLONE':
                    return ICON_TYPES.CLONE;
                case 'EDIT':
                    return ICON_TYPES.EDIT;
                case 'CANCEL':
                    return ICON_TYPES.EDIT;
                case 'DELETE':
                    return ICON_TYPES.DELETE;
                case 'PAUSE':
                    return ICON_TYPES.PAUSE;
                case 'UNPAUSE':
                    return ICON_TYPES.START;
                case 'DETAILS':
                    if (shouldEntityImportResetVersion(this.entityType)) {
                        return ICON_TYPES.EYE_SHOW;
                    }
                    return ICON_TYPES.DETAILS;
                case 'STOP':
                    return ICON_TYPES.STOP;
                case 'START':
                    return ICON_TYPES.START;
                case 'SEND_EVENT':
                    return ICON_TYPES.SEND;
                case 'READ_ONLY':
                    return ICON_TYPES.INFO;
                case 'DOWNLOAD':
                    return ICON_TYPES.DOWNLOAD;
                default:
            }
            return null;
        },
        setHovered(button) {
            this.hoveredButton = button;
        },
        removeHovered() {
            this.hoveredButton = null;
        },
        isHovered(button) {
            return this.hoveredButton === button;
        },
        // this alert represents the alert which allows you do delete or showing the affected entities
        showDeleteAlert() {
            this.$store.commit(`entitymutation/${Mutations.SET_AFFECTED_ENTITIES}`, {
                affectedEntities: getAffectedEntities(this.entity.id, this.entityType),
            });
            if (this.hasAffectedEntities(this[Getters.GET_AFFECTED_ENTITIES])) {
                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('alerts.impactedEntity'),
                    type: ALERT_TYPES.warning,
                });
            } else if (
                this.entityType !== ENTITY_TYPES.OFFER &&
                this.hasAffectedEntities(this[Getters.GET_AFFECTED_ENTITIES])
            ) {
                // We do not have entity above offers that is dependent on lower level entities
                // Categories are used just for visually grouping stuff in Portal.
                this.$eventBus.$once('buttonClicked', buttonId => {
                    if (buttonId === this.alertButtons.details.id) {
                        this[Mutations.TOGGLE_SHOW_MODAL]({ toState: true });
                    }
                });
            } else if (this.entityType === ENTITY_TYPES.ORCHESTRATION_ENGINE_TEMPLATES) {
                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('orchestrationEngine.alerts.deleteTemplateFromList', {
                        numberOfReferencedBy: this.entity.referencedBy,
                    }),
                    type: ALERT_TYPES.warning,
                    buttons: [this.alertButtons.deleteButton],
                });
                this.$eventBus.$once('buttonClicked', buttonId => {
                    if (buttonId === this.alertButtons.deleteButton.id) {
                        this.deleteEntity();
                    }
                });
            } else {
                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('alerts.areYouSure'),
                    type: ALERT_TYPES.warning,
                    buttons: [this.alertButtons.deleteButton],
                });

                this.$eventBus.$once('buttonClicked', buttonId => {
                    if (buttonId === this.alertButtons.deleteButton.id) {
                        this.deleteEntity();
                    }
                });
            }
        },
        hasAffectedEntities(entities) {
            return Object.values(entities).some(e => e.length);
        },
        /**
         * Builds link for entity based on action.
         * If no function exists in `entityActionToHrefFunction` for `action`,
         * then this function returns `null`.
         * @param {*} action string representation of key in EntityActions
         */
        getHrefFromAction(action) {
            const func = this.entityActionToHrefFunction?.[action];
            if (!func || typeof func !== 'function') {
                return null;
            }
            try {
                return func(this.entity);
            } catch {
                return null;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/palette';
@import '~@/assets/scss/icons';

$icon-path: '~@/assets/icons/';

.row-state-controls {
    height: 40px;
    display: flex !important;
    padding-right: 0.5rem;
}
</style>
