<template>
    <div>
        <div class="container-fluid wrapper-container background-color">
            <AppHeader :pageTitle="$i18n.t('customerCare.GORPaymentStatus')" />
            <Breadcrumbs
                :breadcrumbList="breadcrumbList"
                class="ml-4"
            />
            <AppCustomerHeader :infoData="infoData" />

            <div class="section-layout">
                <TableFiltersRenderless :entities="paymentList">
                    <template #default="{ filteredEntities }">
                        <div>
                            <div class="d-flex justify-content-between align-items-center position-relative">
                                <div class="table-title">
                                    {{ $i18n.t('customerCare.GORPaymentHistory') }}
                                </div>
                                <div class="d-flex align-items-center">
                                    <SearchBox
                                        v-model="searchQueryHasAccessTable"
                                        class="search-box"
                                        :smallSearchIcon="true"
                                    />
                                    <ResponseModalButton
                                        :response="paymentListAPIResponse"
                                        :title="$i18n.t('customerCare.userInformation.loggedInDevices')"
                                    />
                                </div>
                            </div>
                            <AppTable
                                :entities="filteredEntities"
                                :isDefaultHoverEnabled="false"
                                :isPaginationEnabled="true"
                                :canSelectColumns="true"
                                :newDesign="true"
                                :columnsData="tablePaymentListColumnsData"
                                :isSearchEnabled="true"
                                :innerSearchQuery="searchQueryHasAccessTable"
                                :isDataLoading="isDataLoading"
                            >
                                <template #amount="{ entity }"> ₱{{ entity.amount }} </template>

                                <template #transaction_status="{ entity }">
                                    <PaymentStatusIndicator :status="entity.transaction_status" />
                                </template>

                                <template #payment_method="{ entity }">
                                    {{ getPaymentMethod(entity.payment_method) }}
                                </template>

                                <template #create_timestamp="{ entity }">
                                    {{
                                        $localeLibrary.getFormattedDateAndTimeWithSecondsPrecision(
                                            entity.create_timestamp,
                                        )
                                    }}
                                </template>
                            </AppTable>
                        </div>
                    </template>
                </TableFiltersRenderless>
            </div>
        </div>
    </div>
</template>

<script>
// COMPONENTS
import AppHeader from '@/components/layout/AppHeader.vue';
import Breadcrumbs from '@/components/partials/Breadcrumbs.vue';

import AppTable from '@/components/partials/AppTable.vue';
import AppCustomerHeader from '@/components/partials/AppCustomerHeader.vue';
import TableFiltersRenderless from '@/components/filters/TableFiltersRenderless.vue';
import SearchBox from '@/components/partials/inputs/SearchBox.vue';
import ResponseModalButton from '@/components/partials/ResponseModalButton.vue';
import PaymentStatusIndicator from '@/__new__/features/customerCare/gorPayment/PaymentStatusIndicator.vue';
import User from '@/__new__/services/dno/user/models/User';
import { GOR_METHOD_MAP } from '@/__new__/services/dno/user/models/GORPayment';

import tableColumnType from '@/common/filterTable';
import ossgpfiberService from '@/__new__/services/dno/ossgpfiber/http/ossgpfiber';
import userInfoHelper, {
    SEARCH_QUERY_OPTION,
    SEARCH_QUERY_FILTER_OPERATOR,
} from '@/__new__/features/customerCare/common/userInfoHelper';
import UM_HIERARCHY from '@/common/userManagerHierarchy';

export default {
    name: 'GORPaymentInfo',
    components: {
        AppHeader,
        Breadcrumbs,
        AppTable,
        AppCustomerHeader,
        TableFiltersRenderless,
        SearchBox,
        ResponseModalButton,
        PaymentStatusIndicator,
    },
    data() {
        return {
            paymentList: [],
            paymentListAPIResponse: null,
            user: new User({}),
            searchQueryHasAccessTable: '',
            isDataLoading: true,
        };
    },
    computed: {
        breadcrumbList() {
            return [
                {
                    name: this.$i18n.t('customerCare.breadcrumbs.search'),
                    link: '/customer-care/gor-payment-usersearch',
                },
                {
                    name: this.$i18n.t('customerCare.GORPaymentStatus'),
                },
            ];
        },
        tablePaymentListColumnsData() {
            return [
                {
                    name: this.$i18n.t('customerCare.gorPaymentHistory.paymentReferenceId'),
                    key: 'payment_session_id',
                    field: 'payment_session_id',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('customerCare.gorPaymentHistory.timestamp'),
                    key: 'create_timestamp',
                    field: 'create_timestamp',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('customerCare.gorPaymentHistory.gorOrderId'),
                    key: 'gor_order_id',
                    field: 'gor_order_id',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('customerCare.gorPaymentHistory.orderId'),
                    key: 'internal_order_id',
                    field: 'internal_order_id',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('customerCare.gorPaymentHistory.amount'),
                    key: 'amount',
                    field: 'amount',
                },
                {
                    name: this.$i18n.t('customerCare.gorPaymentHistory.method'),
                    key: 'payment_method',
                    field: 'payment_method',
                },
                {
                    name: this.$i18n.t('generic.status'),
                    key: 'transaction_status',
                    field: 'transaction_status',
                },
            ];
        },

        infoData() {
            const infoData = [
                {
                    title: this.$i18n.t('customerCare.basicInfo'),
                    value: this.basicInfo,
                    initiallyExpanded: true,
                },
            ];
            return infoData;
        },
        basicInfo() {
            const data = [];
            data.push({
                name: this.$i18n.t('customerCare.msisdn'),
                value: this.user?.msisdn,
            });
            data.push({
                name: this.$i18n.t('customerCare.userInformation.email'),
                value: this.user?.emailSecret,
            });

            data.push({
                name: this.$i18n.t('customerCare.userInformation.firstName'),
                value: this.user?.name,
            });

            data.push({
                name: this.$i18n.t('customerCare.userInformation.middleName'),
                value: this.user?.middleName,
            });

            data.push({
                name: this.$i18n.t('customerCare.userInformation.lastName'),
                value: this.user?.surname,
            });

            data.push({
                name: this.$i18n.t('customerCare.userInformation.creationDate'),
                value: this.user?.creationDate,
            });
            return data;
        },
    },
    async created() {
        const { msisdn } = this.$route.params;
        const dataFetchesPromises = [this.getPaymentList(msisdn), this.getUserInfoByMsisdn(msisdn)];
        await this.$withProgressBar(
            async () => {
                const [paymentList, userInfo] = await Promise.allSettled(dataFetchesPromises);
                this.isDataLoading = false;
                this.paymentListAPIResponse = paymentList;

                if (userInfo.value) {
                    this.setUser(userInfo.value.data.user_info);
                } else {
                    this.setUser({
                        notification_info: {
                            [msisdn]: 'msisdn',
                        },
                    });
                }

                if (paymentList?.value?.data?.transactions?.length) {
                    this.paymentList = this.mapApiDataToDisplayTable(paymentList.value.data.transactions);
                } else {
                    this.$alert(this.$i18n.t('alertMessage.userManagement.somethingWentWrongFetchingUserProfileData'));
                    setTimeout(() => {
                        const path = `/${this.$route.params.companyId}/customer-care/gor-payment-usersearch`;
                        this.$router.push(path);
                    }, 2000);
                }
            },
            {
                errorHandler: () => {
                    this.$i18n.t('alertMessage.userManagement.somethingWentWrongFetchingUserProfileData');
                },
            },
        );
    },
    methods: {
        formatTimeStamp(timestamp) {
            const date = new Date(timestamp);
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0');
            return `${year}-${month}`;
        },
        getPaymentMethod(paymentMethod) {
            return GOR_METHOD_MAP[paymentMethod];
        },
        setUser(userInfo) {
            this.user = new User(User.remapUserFromBe(userInfo));
        },
        getPaymentList(msisdn) {
            return ossgpfiberService.getPaymentTransaction({
                target_id: msisdn,
                target_type: UM_HIERARCHY.UNREGISTERED_MSISDN,
            });
        },
        getUserInfoByMsisdn(msisdn) {
            return userInfoHelper.searchAndLoadAccount(
                SEARCH_QUERY_OPTION.USERNAME,
                msisdn,
                SEARCH_QUERY_FILTER_OPERATOR.MATCH,
            );
        },
        mapApiDataToDisplayTable(paymentList) {
            return paymentList.map(payment => {
                const data = {};
                data.payment_session_id = payment.payment_session_id;
                data.gor_order_id = payment.properties.gor_order_id;
                data.internal_order_id = payment.properties.internal_order_id;
                data.amount = payment.amount;
                data.transaction_status = payment.transaction_status;
                data.payment_method = payment.payment_method;
                data.create_timestamp = payment.create_timestamp;
                return data;
            });
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/palette';
@import '~@/assets/scss/layout';

.search-box {
    width: 15rem;
}

.background-color {
    background-color: $dirty-white;
}

.section-layout {
    margin: 0 1.5rem 1.5rem 1.5rem;
}

.section-subtitle {
    font-size: 0.75rem;
    font-weight: 600;
    color: $gray90;
}

.section-title {
    font-size: 1rem;
    font-weight: 600;
    color: $gray90;
}

.table-title {
    font-size: 1rem;
    font-weight: 600;
}

.link:hover {
    color: $blue;
    text-decoration: underline;
}
</style>
