import {
    ADDRESS_TYPE,
    USER_MANAGER_HIERARCHY as TARGET,
} from '@/__new__/features/customerCare/common/customerCareHelper';

/**
 * Defines structure of target to address type mappings *expected* by the DNO.
 */
export interface TaxAddressTypePerTargetDNO {
    [key: string]: { tax_address_type: number };
}

/**
 * Defines how target to address type mappings are stored on the front end
 */
export interface TargetAddressPair {
    target: TARGET;
    addressType: ADDRESS_TYPE;
}

/**
 * Utility function used to convert from front end ()`TargetAddressPair[]`) to back end `TaxAddressTypePerTargetDNO`
 * @param targetAddressPairList
 * @returns
 */
export const convertTargetToAddressTypeFeToBe = (
    targetAddressPairList: Partial<TargetAddressPair>[],
): TaxAddressTypePerTargetDNO => {
    const targetToAddressTypeDNO: TaxAddressTypePerTargetDNO = {};
    for (const targetAddressPair of targetAddressPairList) {
        if (!targetAddressPair.target || !targetAddressPair.addressType) {
            continue;
        }
        targetToAddressTypeDNO[targetAddressPair.target] = {
            tax_address_type: targetAddressPair.addressType,
        };
    }
    return targetToAddressTypeDNO;
};

/**
 * Utility function used to convert from back end `TaxAddressTypePerTargetDNO` to front end ()`TargetAddressPair[]`)
 * @param targetToAddressTypeDNO
 * @returns
 */
export const convertTargetToAddressTypeBeToFe = (
    targetToAddressTypeDNO: TaxAddressTypePerTargetDNO,
): TargetAddressPair[] => {
    const targetAddressPairList: TargetAddressPair[] = [];

    Object.entries(targetToAddressTypeDNO).forEach(([key, value]) => {
        const target = Number(key);
        const targetData = value;
        const addressType = targetData?.tax_address_type;
        targetAddressPairList.push({
            target,
            addressType,
        });
    });

    return targetAddressPairList;
};
