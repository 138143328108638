<template>
    <fieldset>
        <legend hidden>Price</legend>

        <AppToggle
            v-model="isPriceList"
            :small="true"
            :label="`Source: ${isPriceList ? 'Pricing sheet' : 'Manual'}`"
            :fitContent="true"
            class="mb-3"
        />

        <template v-if="isPriceList">
            <AppMultiselectV3
                v-model="priceList"
                :options="priceListOptions"
                :additionalLabel="'Pricing sheet'"
                :placeholder="'Choose price sheet'"
                :small="true"
                :explanationText="'Prices from selected sheet will be applied'"
                :tooltipOffset="20"
                label="name"
                trackBy="name"
                class="editor-input-largest mb-3"
                @select="updateStorage"
            />

            <dl
                v-if="offerListPrices.length"
                class="mb-0"
            >
                <dt><h4>Target audience</h4></dt>
                <dd><h4>Price</h4></dd>

                <template v-for="(offerPrice, i) in offerListPrices">
                    <dt :key="`persona-${i}`">{{ offerPrice.persona }}</dt>
                    <dd :key="`price-${i}`">
                        <AppInputV3
                            :key="offerPrice.id"
                            :value="offerPrice.price"
                            :placeholder="$i18n.t('generic.price')"
                            :step="0.01"
                            :disabled="true"
                            type="number"
                            data-test-id="price-field"
                            class="editor-input-largest"
                        />
                    </dd>
                </template>
            </dl>
        </template>
        <AppInputV3
            v-else
            v-model="priceSingle"
            :placeholder="$i18n.t('generic.price')"
            :label="$i18n.t('generic.price')"
            :invalid="validationModifier(!validatePrice(priceSingle), CORE_PROPERTIES_FE.PRICE)"
            :step="0.01"
            :disabled="disabled"
            type="number"
            data-test-id="price-field"
            class="editor-input-largest mb-3"
            @input="e => $emit('input', e)"
        />
    </fieldset>
</template>

<script>
// components
import AppInputV3 from '@/components/partials/inputs/AppInputV3.vue';
import AppMultiselectV3 from '@/components/partials/inputs/AppMultiselectV3.vue';
import AppToggle from '@/components/partials/inputs/AppToggle.vue';

// helpers
import { getOperatorConfigValue } from '@/services/permissions/permissions.service';
import CORE_PROPERTIES_FE from '@/__new__/features/pc/offers/common/offerCorePropertiesFE';
import LocalStorageHelper from '@/common/LocalStorageHelper';

// models
import { ItotOfferPriceList } from '@/__new__/features/pc/atiot/AtiotOfferPriceListPage.vue';

export default {
    name: 'AtiotOfferPriceListSelect',
    components: {
        AppToggle,
        AppMultiselectV3,
        AppInputV3,
    },
    props: {
        disabled: {
            type: Boolean,
            default: false,
        },
        validationModifier: {
            type: Function,
            default: () => false,
        },
        validatePrice: {
            type: Function,
            default: () => true,
        },
    },
    data() {
        return {
            CORE_PROPERTIES_FE,
            isPriceList: false,
            priceSingle: 0,
            priceList: new ItotOfferPriceList(),
            priceListOptions:
                JSON.parse(LocalStorageHelper.get('atiotOfferPriceLists')) ||
                getOperatorConfigValue('atiotOfferPriceLists', []),
            storedOfferPrices: JSON.parse(LocalStorageHelper.get('atiotOfferPrices')) || {},
        };
    },
    computed: {
        offerListPrices() {
            return this.priceList.prices.filter(item => item.id === this.$route.params.id);
        },
        storedOfferPriceList() {
            return this.storedOfferPrices?.[this.$route.params.id];
        },
    },
    created() {
        if (this.storedOfferPriceList) {
            this.isPriceList = true;
            this.priceList = this.priceListOptions.find(l => l.name === this.storedOfferPriceList.name);
        }
    },
    methods: {
        updateStorage() {
            this.storedOfferPrices[this.$route.params.id] = {
                name: this.priceList.name,
                prices: this.offerListPrices,
            };
            LocalStorageHelper.set('atiotOfferPrices', JSON.stringify(this.storedOfferPrices));
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/consistency';

dl {
    display: grid;
    grid-template-columns: max-content 10rem;
    align-items: center;
    gap: $spacing-xs;
}
</style>
