
// COMPONENTS
import AppHeader from '@/components/layout/AppHeader.vue';
import Breadcrumbs from '@/components/partials/Breadcrumbs.vue';

import AppTable from '@/components/partials/AppTable.vue';
import IconButton from '@/components/partials/IconButton.vue';
import TableFiltersRenderless from '@/components/filters/TableFiltersRenderless.vue';
import { ICON_TYPES } from '@/common/iconHelper';
import AppDialogV2 from '@/components/partials/AppDialogV2.vue';
import { ALERT_TYPES } from '@/common/alerts/Alert';
import { getGuestOrder, fetchResendQRCode } from '@/__new__/services/dno/resendQRCode/http/resendQRcode';
import { GuestOrder } from '@/__new__/services/dno/resendQRCode/models/guestOrder';
import { TranslateResult } from 'vue-i18n';
import tableColumnType, { type TableColumn } from '@/common/filterTable';
import UM_HIERARCHY from '@/common/userManagerHierarchy';
import { ORDER_ENTITY_STATES } from '@/__new__/features/customerCare/common/orderStateHelper';

export default {
    name: 'ResendQRCode',
    components: {
        AppHeader,
        Breadcrumbs,
        AppTable,
        IconButton,
        TableFiltersRenderless,
        AppDialogV2,
    },
    data() {
        return {
            guestOrders: [] as GuestOrder[],
            isDataLoading: true,
            searchQueryHasAccessTable: '',
            enableRowStateControls: true,
            showDialog: false,
            selectedOrder: {} as GuestOrder,
            ICON_TYPES,
        };
    },
    computed: {
        pageTitle(): TranslateResult {
            return this.$i18n.t('customerCare.guestPurchase');
        },
        breadcrumbList(): Array<{
            name: TranslateResult;
            link?: string;
        }> {
            const breadcrumbList = [
                {
                    name: this.$i18n.t('customerCare.breadcrumbs.search'),
                    link: '/customer-care/resend-qrcode/user/search',
                },
            ];
            breadcrumbList.push({ name: this.$i18n.t('customerCare.guestPurchase') });
            return breadcrumbList;
        },
        tableOrderColumnsData(): TableColumn[] {
            return [
                {
                    name: this.$i18n.t('customerCare.guestOrderColumns.friendlyOrderId'),
                    key: 'friendlyOrderId',
                    field: 'friendlyOrderId',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('customerCare.guestOrderColumns.orderId'),
                    key: 'orderId',
                    field: 'orderId',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('customerCare.guestOrderColumns.orderDate'),
                    key: 'orderDate',
                    field: 'orderDate',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('customerCare.guestOrderColumns.msisdn'),
                    key: 'msisdn',
                    field: 'msisdn',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('customerCare.guestOrderColumns.totalAmount'),
                    key: 'totalAmount',
                    field: 'totalAmount',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('customerCare.guestOrderColumns.firstName'),
                    key: 'firstName',
                    field: 'firstName',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('customerCare.guestOrderColumns.lastName'),
                    key: 'lastName',
                    field: 'lastName',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
            ];
        },
        esimOrders(): Array<GuestOrder> {
            return this.guestOrders.filter(order => order.isEsimPurchase);
        },
    },
    created() {
        this.fetchGuestUserData();
    },
    methods: {
        onCancelButtonClicked(): void {
            this.showDialog = false;
        },
        displayDialog(entity): void {
            this.showDialog = true;
            this.selectedOrder = entity;
        },
        async fetchGuestUserData(): void {
            const { email } = this.$route.params;
            const searchUserResponse = await getGuestOrder({
                guest_id: email,
                guest_type: UM_HIERARCHY.UNREGISTERED_EMAIL,
            });
            this.isDataLoading = false;
            const ordersMap = searchUserResponse.data.orders;
            const filteredOrders = Object.values(ordersMap).filter(order => order.state === ORDER_ENTITY_STATES.ACTIVE);
            this.guestOrders = filteredOrders.map(order => {
                const orderData = order?.order_data;
                return {
                    orderId: order.order_id,
                    firstName: orderData.delivery_user_info.first_name,
                    lastName: orderData.delivery_user_info.last_name,
                    msisdn: orderData?.esim_msisdn || '',
                    totalAmount: this.$localeLibrary.getFormattedAmount(orderData.total_amount),
                    orderDate: orderData.order_creation_time,
                    friendlyOrderId: orderData.friendly_order_id,
                    isEsimPurchase: orderData.is_esim_purchase,
                };
            });
        },
        async resendQRCode(entity): void {
            this.showDialog = false;
            this.isDataLoading = true;
            await this.$withProgressBar(
                async () => {
                    await fetchResendQRCode({
                        order_id: entity.orderId,
                        email: this.$route.params.email,
                    });
                    this.isDataLoading = false;
                    this.$alert(this.$i18n.t('customerCare.guestOrder.resendingCode'), {
                        type: ALERT_TYPES.success,
                    });
                },
                {
                    errorHandler: () => {
                        this.isDataLoading = false;
                    },
                },
            );
        },
        onContinueButtonClicked(): void {
            this.resendQRCode(this.selectedOrder);
        },
    },
};
