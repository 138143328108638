import { AxiosPromise } from 'axios';
import http from '@/http';
import {
    OPERATION_TYPE,
    BatchRescheduledexEcutionsDno,
    UpdateScheduledExecutionTimeData,
    BatchHaltExecutionsDno,
} from '@/__new__/services/dno/orchestrationengine/batchActions';

/**
 * Get Batchre Scheduled executions.
 * @returns {AxiosPromise}
 */
export function getBatchreScheduledExecutions(
    operationType: OPERATION_TYPE,
): AxiosPromise<BatchRescheduledexEcutionsDno> {
    return http({
        method: 'POST',
        url: '/v3orchestration/getbatchrescheduledexecutions',
        data: {
            operation_type: operationType,
        },
    });
}

/**
 * Update Batch Scheduled Execution Time
 * @returns {AxiosPromise}
 */
export function batchUpdateScheduledExecutionTime(data: UpdateScheduledExecutionTimeData): AxiosPromise {
    return http({
        method: 'POST',
        url: '/v3orchestration/batchupdatescheduledexecutiontime',
        data,
    });
}

/**
 * create batch halt executions
 * @returns {AxiosPromise}
 */
export function batchHaltExecutions(executionIds: string[], name: string): AxiosPromise<BatchHaltExecutionsDno> {
    return http({
        method: 'POST',
        url: '/v3orchestration/batchhaltexecutions',
        data: {
            execution_ids: executionIds,
            name,
        },
    });
}

export default {
    getBatchreScheduledExecutions,
    batchUpdateScheduledExecutionTime,
};
