/**
 * Contains utility functions for FeeRules that are shared amongst front-end Vue components
 */

import {
    ACTION_CONFIGURATION_TYPE as FEE_RULE_TYPE,
    AMOUNT_CALCULATION_METHOD,
    PRORATION_METHOD,
    PRORATION_UNIT,
    SCOPE_LEVEL,
} from '@/__new__/services/dno/pricing/models/pricingDno';
import i18n from '@/i18n';
import { FeeRuleEntity } from '@/__new__/services/dno/pricing/feeRulesPortal';

export const prorationUnitToString = (prorationUnit: PRORATION_UNIT | null): string => {
    switch (prorationUnit) {
        case PRORATION_UNIT.DAY:
            return i18n.t('generic.day').toString();
        case PRORATION_UNIT.WEEK:
            return i18n.t('generic.week').toString();
        case PRORATION_UNIT.MONTH:
            return i18n.t('generic.month').toString();
        case PRORATION_UNIT.YEAR:
            return i18n.t('generic.year').toString();
        default:
            return '';
    }
};

export const typeToString = (type: FEE_RULE_TYPE): string => {
    switch (type) {
        case FEE_RULE_TYPE.LATE_PAYMENT:
            return i18n.t('pricingAndFees.feeRulesTypesToString.latePayment').toString();
        case FEE_RULE_TYPE.EARLY_TERMINATION:
            return i18n.t('pricingAndFees.feeRulesTypesToString.earlyTermination').toString();
        default:
            return i18n.t('generic.unknown').toString();
    }
};

export const scopeToString = (scope: SCOPE_LEVEL): string => {
    switch (scope) {
        case SCOPE_LEVEL.LINE_ITEM_LEVEL:
            return i18n.t('pricingAndFees.label.scopeLineItem').toString();
        case SCOPE_LEVEL.TOTAL_LEVEL:
            return i18n.t('pricingAndFees.label.scopeTotal').toString();
        default:
            return i18n.t('generic.unknown').toString();
    }
};

export const amountCalculationMethodToString = (
    calcMethod: AMOUNT_CALCULATION_METHOD | null,
    scope: SCOPE_LEVEL | null,
): string => {
    if (calcMethod === AMOUNT_CALCULATION_METHOD.PERCENT) {
        switch (scope) {
            case SCOPE_LEVEL.TOTAL_LEVEL:
                return i18n.t('pricingAndFees.ofTotalPayment').toString();
            case SCOPE_LEVEL.LINE_ITEM_LEVEL:
                return i18n.t('pricingAndFees.ofTheOfferPrice').toString();
            default:
                return '';
        }
    }
    return '';
};

/**
 * Returns a human readable string based on the fee rule type, proration method and proration unit
 */
export const prorationMethodSuffixString = (type: FEE_RULE_TYPE | null, method: PRORATION_METHOD | null): string => {
    // We can't determine a description without knowing the type and method
    // Native FEE_RULE_TYPE is not supported
    if (type === null || type === FEE_RULE_TYPE.NATIVE || method === null) {
        return '';
    }
    if (method === PRORATION_METHOD.DURATION) {
        if (type === FEE_RULE_TYPE.EARLY_TERMINATION) {
            return i18n.t('pricingAndFees.remainingInTheContract').toString();
        } else if (type === FEE_RULE_TYPE.LATE_PAYMENT) {
            return i18n.t('pricingAndFees.paymentIsLate').toString();
        }
    } else if (method === PRORATION_METHOD.NONE) {
        if (type === FEE_RULE_TYPE.EARLY_TERMINATION) {
            return i18n.t('pricingAndFees.onEarlyTerminationOfContract').toString();
        } else if (type === FEE_RULE_TYPE.LATE_PAYMENT) {
            return i18n.t('pricingAndFees.whenPaymentDueDateIsMissed').toString();
        }
    }
    return '';
};

/**
 * Generate a human readable summary of the fee rule
 */
export const summary = (entity: FeeRuleEntity | null): string => {
    // Can't form summary without an entity:
    if (entity === null) {
        return '';
    }

    // The summary is determined by
    // { amount calculation method + scope } x { type } x { proration method }
    // There are 12 combinations which are mapped in the i18n resource file (eg: en.json)
    // Below we use the entity information to determine each id to determine the final
    // resource identifer
    // Any missing information results in the summary being an empty string.
    let amountCalcId = '';
    if (entity.amountCalculationMethod === AMOUNT_CALCULATION_METHOD.FIXED) {
        amountCalcId = 'fixedAmount';
    } else if (entity.amountCalculationMethod === AMOUNT_CALCULATION_METHOD.PERCENT) {
        if (entity.scope === SCOPE_LEVEL.LINE_ITEM_LEVEL) {
            amountCalcId = 'percentOffer';
        } else if (entity.scope === SCOPE_LEVEL.TOTAL_LEVEL) {
            amountCalcId = 'percentTotal';
        } else {
            return '';
        }
    } else {
        return '';
    }

    let typeId = '';
    if (entity.type === FEE_RULE_TYPE.EARLY_TERMINATION) {
        typeId = 'earlyTermination';
    } else if (entity.type === FEE_RULE_TYPE.LATE_PAYMENT) {
        typeId = 'latePayment';
    } else {
        return '';
    }

    let prorationMethodId = '';
    if (entity.prorationMethod === PRORATION_METHOD.NONE) {
        prorationMethodId = 'oneTime';
    } else if (entity.prorationMethod === PRORATION_METHOD.DURATION) {
        prorationMethodId = 'periodic';
    }

    return i18n
        .t(`pricingAndFees.summary.${amountCalcId}.${typeId}.${prorationMethodId}`, {
            amount: entity.amount,
            unit: prorationUnitToString(entity.prorationUnit),
        })
        .toString();
};
