import { DnoResponseBase } from '@/http/index';
import { BucketId, Bucket } from '@/__new__/features/customerCare/common/balanceHelper';
import { SUBSCRIBER_STATE } from '@/__new__/features/customerCare/common/customerCareHelper';

/* eslint-disable camelcase */
export type Wallet = {
    primary: {
        is_primary: boolean;
        amount: string;
        active_epoch: number;
        grace_epoch: number;
    };
};

export type FLags = {
    subscriber_info: {
        invalidate_timestamp: number;
    };
    account_wallet_enabled: boolean;
    is_locally_tied_flag: boolean;
};

export enum FLAG_TYPES {
    isLocallyTiedFlag = 'is_locally_tied_flag',
    accountWalletEnabled = 'account_wallet_enabled',
}

export type Order = {
    offer_id: string;
    offer_name: string;
    start_time: number;
    end_time: number;
};

export type Session = Record<
    string,
    {
        session_expiry?: number;
        total_units_used?: number;
        waiting_for_sna?: boolean;
        snr_trigger_time?: number;
        reservations?: SessionReservations;
    }
>;

export type SessionReservations = Record<
    string,
    {
        rating_group_name?: number;
        expiry: number;
        reserved_amount: number;
    }
>;

export enum SESSION_TYPES {
    SESSIONS = 'sessions',
    SESSIONS_GX = 'sessions_gx',
    SESSIONS_SY = 'sessions_sy',
}

export type ActiveSessions = {
    [SESSION_TYPES.SESSIONS]: Record<string, Session>;
    [SESSION_TYPES.SESSIONS_GX]: Record<string, Session>;
    [SESSION_TYPES.SESSIONS_SY]: Record<string, Session>;
};

export interface ChargingBucketWizardApiResponse extends DnoResponseBase {
    buckets: Record<BucketId, Bucket>;
    wallets: Wallet;
    flags: FLags;
    last_invalidate_timestamp: number;
    orders: Record<string, Order>;
    active_sessions: ActiveSessions;
    state: SUBSCRIBER_STATE;
}

export type BucketWizardBucket = {
    amount_available: number;
    condition_expr?: any;
    policy_counters?: {
        policy_counter_id: string;
        thresholds_percentage?: number[];
        thresholds?: number[];
        statuses: string[];
    }[];
    usage_counters?: {
        bucket_counters: Record<
            string,
            {
                amount_used: number;
                reset_period: string;
                expiration_timestamp: string;
            }
        >;
    };
    surcharges?: {
        surcharge_enabled: boolean;
        rate_period: number;
        rate: string;
        condition_expr: any;
    };
};
