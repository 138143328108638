import {
    getRegisteredEntities,
    getEntityDetailsByName,
} from '@/__new__/services/dno/progressTracker/http/progressTracker';
import {
    DATAFLOW_API_TYPE,
    type EntityDetailsByName,
    type RegisteredEntityItem,
} from '@/__new__/services/dno/progressTracker/progressTrackerHelper';
import {
    FILE_UPLOAD_STATUS,
    fileUploadStatusToStr,
    getFileUploadStatus,
    type UploadedFileDetails,
} from '@/common/fileUploadHelper';
import { getOperatorConfigValue } from '@/services/permissions/permissions.service';

export const getUploadHistory = async (dataflowId: DATAFLOW_API_TYPE): Promise<UploadedFileDetails[]> => {
    // eslint-disable-next-line prettier/prettier
    const { data: { data: { items }}} = await getRegisteredEntities(dataflowId);
    const uploadHistory = await Promise.all(
        (items as RegisteredEntityItem[]).map(entity => uploadFileDetailsFromEntity(entity, dataflowId)),
    );

    // Throttle requests for file details
    throttleFileDetailsRequests(uploadHistory, dataflowId);

    return uploadHistory;
};

/**
 * Determine file upload status
 * Attempt to load this async as we need an additional request
 */
const getFileDetails = (fileDetails: UploadedFileDetails, dataflowId: DATAFLOW_API_TYPE) =>
    getEntityDetailsByName(fileDetails.entityName, dataflowId)
        .then((details: { data: EntityDetailsByName }) => {
            const recordCount = details.data.data.expected_count;
            const eventCategoryCounters = details.data.data.event_category_counters;
            fileDetails.recordCount = recordCount;
            fileDetails.eventCategoryCounters = eventCategoryCounters;
            fileDetails.fileUploadStatus = getFileUploadStatus(recordCount, eventCategoryCounters);
            fileDetails.fileUploadStatusStr = fileUploadStatusToStr(fileDetails.fileUploadStatus);
            fileDetails.metadata = details.data.data.metadata;
            fileDetails.createdBy = details.data.data.metadata?.uploaded_by || '';
            fileDetails.createdByPortalId = Number(details.data.data.metadata?.principal_id) || undefined;

            if (fileDetails.metadata?.context_metadata) {
                fileDetails.fileName = fileDetails.metadata?.context_metadata.fileName;
                fileDetails.bulkUploadId = fileDetails.metadata?.context_metadata.uuid;
            }

            return fileDetails;
        })
        .catch(() => {
            fileDetails.recordCount = 0;
            fileDetails.fileUploadStatus = FILE_UPLOAD_STATUS.UNKNOWN;
            fileDetails.fileUploadStatusStr = fileUploadStatusToStr(fileDetails.fileUploadStatus);
            return fileDetails;
        });

/**
 * Transforms backend `RegisteredEntityItem` into Portal friendly `UploadedFileDetails`
 */
const uploadFileDetailsFromEntity = async (
    entity: RegisteredEntityItem,
    dataflowId: DATAFLOW_API_TYPE,
): Promise<UploadedFileDetails> => {
    /**
     * Build details object
     * Explicitly leaving fields undefined since:
     *  - they will be updated asynchronously
     *  - they need to be reactive
     */
    const fileDetails: UploadedFileDetails = {
        entityName: entity.entity_name,
        createdAt: entity.created_at,
        lastUpdated: entity.last_updated,
        fileName: entity.entity_name,
        bulkUploadId: undefined,
        createdBy: undefined,
        recordCount: undefined,
        metadata: undefined,
    };

    // All data is needed in order to group values before rendering
    if (dataflowId === DATAFLOW_API_TYPE.SIM_BULK) {
        return await getFileDetails(fileDetails, dataflowId);
    }

    return fileDetails;
};

const throttleFileDetailsRequests = (uploadedFileDetails: UploadedFileDetails[], dataflowId: DATAFLOW_API_TYPE) => {
    // For SIM_BULK, we don't need to throttle requests since it was already fetched
    if (dataflowId === DATAFLOW_API_TYPE.SIM_BULK) {
        return;
    }

    // Set constants for throttling
    const MAX_CONCURRENT_REQUESTS = getOperatorConfigValue('developerLineAuthorizationMaxConcurrentRequests', 10);
    const POLL_INTERVAL_MS = getOperatorConfigValue('developerLineAuthorizationPollInterval', 1000);

    // Sort by created time descending
    const sortedUploadedFileDetails = uploadedFileDetails.sort((a, b) => b.createdAt - a.createdAt);

    // Throttle getFileDetails requests until completed
    let fileIndex = 0;
    const numFiles = sortedUploadedFileDetails.length;
    const pollerInterval = setInterval(() => {
        if (fileIndex >= numFiles) {
            clearInterval(pollerInterval);
            return;
        }
        for (let count = 0; fileIndex < numFiles && count < MAX_CONCURRENT_REQUESTS; count++, fileIndex++) {
            getFileDetails(sortedUploadedFileDetails[fileIndex], dataflowId);
        }
    }, POLL_INTERVAL_MS);
};
