<template>
    <div class="h-100">
        <AbstractListPageWrapper
            :pageTitle="'Offer price lists'"
            :entitiesCount="offerPrices.length"
            :isOverviewEnabled="false"
            @searchQueryChanged="setSearchQuery"
        >
            <template slot="filterTable">
                <FilterTable
                    :columns="columnsData"
                    @filterAdded="onFilterAdded"
                />
            </template>

            <template slot="headerButtons">
                <AppButton
                    :buttonType="BUTTON_TYPES.SECONDARY"
                    :iconType="ICON_TYPES.PLUS"
                    :label="'Import CSV'"
                    class="mr-3"
                    @click="isImportModalOpen = true"
                />
                <AppButton
                    :buttonType="BUTTON_TYPES.PRIMARY"
                    :iconType="ICON_TYPES.PLUS"
                    :label="'New Price List'"
                    @click="openNewPartner"
                />
            </template>

            <template slot="allFilters">
                <TableFiltersTags
                    :filterTableMixin="filterTableMixin"
                    class="my-3 ml-2"
                    @removeFilter="removeFilter"
                    @removeAllFilters="removeAllFilters"
                />
            </template>

            <template slot="table">
                <AppTable
                    :entities="sortedFilteredEntities"
                    :isSearchEnabled="true"
                    :isDefaultHoverEnabled="false"
                    :innerSearchQuery="searchQuery"
                    :columnsData="columnsData"
                    tableKey="viasat/offerPrices"
                >
                    <template #customRowButtons="{ entity }">
                        <IconButton
                            :label="$i18n.t('generic.edit')"
                            :icon="ICON_TYPES.EDIT"
                            class="mr-1"
                            @iconClick="openEditPriceList(entity)"
                        />
                        <IconButton
                            :label="$i18n.t('generic.delete')"
                            :icon="ICON_TYPES.DELETE"
                            class="mr-3"
                            @iconClick="confirmDeletePartner(entity)"
                        />
                    </template>

                    <template #dateCreated="{ entity }">
                        {{ $localeLibrary.getFormattedDate(entity.dateCreated) }}
                    </template>
                </AppTable>
            </template>
        </AbstractListPageWrapper>

        <AppDialogV2
            :visible="isModalOpen"
            :title="modalTitle"
            :disableDefaultSaveBtn="true"
            @close="closeModal"
        >
            <div class="modal-wrapper d-flex flex-column">
                <div class="mb-2">
                    <AppInputV3
                        v-model="newOfferPriceList.name"
                        :label="'Name'"
                    />
                </div>

                <AppMultiselectV3
                    v-if="isNewPriceListModal"
                    v-model="newOfferPriceList.persona"
                    :additionalLabel="'Target audience'"
                    :small="true"
                    :options="segmentsFiltered"
                    class="mb-2"
                />

                <AgentNotesDragDropUploader
                    v-if="isImportModalOpen"
                    :acceptType="'.csv'"
                    :triggerUploadFiles="uploadInProgress"
                    :uploadSuccessIndex="uploadSuccessIndex"
                    :triggerUploadFailed="uploadFailed"
                    class="mt-4"
                    @updateFiles="updateFiles"
                />

                <dl
                    v-if="isNewPriceListModal || isEditPriceListModal"
                    class="price-list mt-4 mb-2"
                    :class="{
                        'edit-form': isEditPriceListModal,
                    }"
                >
                    <dt class="mb-2"><h4>Offer name</h4></dt>
                    <dd
                        v-if="isEditPriceListModal"
                        class="mb-2"
                    >
                        <h4>Target audience</h4>
                    </dd>
                    <dd class="mb-2"><h4>Price</h4></dd>

                    <template v-for="(offer, offerId) in newOfferPriceList.prices">
                        <dt :key="`offer-${offerId}`">{{ offer.name }}</dt>

                        <dd
                            v-if="isEditPriceListModal"
                            :key="`persona-${offerId}`"
                        >
                            <AppMultiselectV3
                                v-model="newOfferPriceList.prices[offerId].persona"
                                :small="true"
                                :options="segmentsFiltered"
                            />
                        </dd>
                        <dd :key="`price-${offerId}`">
                            <AppInputV3
                                v-model.number="newOfferPriceList.prices[offerId].price"
                                :min="0"
                                :step="0.01"
                                :roundNumber="true"
                                type="number"
                            />
                        </dd>
                    </template>
                </dl>

                <div class="button-wrapper d-flex justify-content-end mt-4">
                    <AppButton
                        v-if="isImportModalOpen"
                        :buttonType="BUTTON_TYPES.PRIMARY"
                        :label="'Import'"
                        @click="savePriceList"
                    />
                    <AppButton
                        v-if="isNewPriceListModal || isEditPriceListModal"
                        :buttonType="BUTTON_TYPES.PRIMARY"
                        :label="'Save'"
                        @click="savePriceList"
                    />
                </div>
            </div>
        </AppDialogV2>
    </div>
</template>

<script>
// Vuex
import { mapGetters } from 'vuex';
import { Getters } from '@/store/mutation-types';
import { Modules } from '@/store/store';

// components
import AbstractListPageWrapper from '@/components/layout/AbstractListPageWrapper.vue';
import AgentNotesDragDropUploader from '@/__new__/features/customerCare/AgentNotesDragDropUploader.vue';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import AppDialogV2 from '@/components/partials/AppDialogV2.vue';
import AppInputV3 from '@/components/partials/inputs/AppInputV3.vue';
import AppMultiselectV3 from '@/components/partials/inputs/AppMultiselectV3.vue';
import AppTable from '@/components/partials/AppTable.vue';
import EntityFetcherMixin from '@/__new__/features/pc/EntityFetcherMixin.vue';
import FilterTable from '@/components/partials/FilterTable.vue';
import FilterTableMixin from '@/components/partials/FilterTableMixin.vue';
import IconButton from '@/components/partials/IconButton.vue';
import TableFiltersTags from '@/components/filters/TableFiltersTags.vue';

// helpers
import { sortBy } from 'lodash';
import currencyTypes from '@/__new__/features/pc/common/currencyTypes';
import ENTITY_TYPES from '@/common/entities/entityTypes';
import { ICON_TYPES } from '@/common/iconHelper';
import { uuidV4 } from '@/common/utils';
import tableColumnType from '@/common/filterTable';
import { getOperatorConfigValue } from '@/services/permissions/permissions.service';
import LocalStorageHelper from '@/common/LocalStorageHelper';

// models
import Button from '@/common/button/Button';

export class OfferPrice {
    constructor({ id, name, persona, price } = {}) {
        this.id = id;
        this.name = name || '';
        this.persona = persona || '';
        this.price = price || 0;
    }
}

export class ItotOfferPriceList {
    constructor({ name, persona, prices } = {}) {
        this.id = uuidV4();
        this.name = name || '';
        this.persona = persona || '';
        // @type {Array<OfferPrice>;}
        this.prices = prices || [];
        this.dateCreated = Date.now();
    }
}

export default {
    name: 'AtiotOfferPriceListPage',
    components: {
        AbstractListPageWrapper,
        AgentNotesDragDropUploader,
        AppButton,
        AppDialogV2,
        AppInputV3,
        AppMultiselectV3,
        AppTable,
        FilterTable,
        IconButton,
        TableFiltersTags,
    },
    mixins: [FilterTableMixin, EntityFetcherMixin],

    data() {
        return {
            ICON_TYPES,
            BUTTON_TYPES,
            currencyTypes,
            offerPrices: JSON.parse(LocalStorageHelper.get('atiotOfferPriceLists')) || [],
            isImportModalOpen: false,
            isNewPriceListModal: false,
            isEditPriceListModal: false,
            columnsData: [
                {
                    name: 'ID',
                    key: 'id',
                    field: 'id',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: 'Name',
                    key: 'name',
                    field: 'name',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: 'Persona',
                    key: 'persona',
                    field: 'persona',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: 'Date created',
                    key: 'dateCreated',
                    field: 'dateCreated',
                    filterType: tableColumnType.DATE,
                },
            ],
            searchQuery: '',
            uploadedDocsIds: [],
            uploadInProgress: false,
            uploadFinished: false,
            uploadSuccessIndex: null,
            uploadFailed: false,
            newOfferPriceList: new ItotOfferPriceList(),
            deleteConfirmationButton: new Button({
                label: this.$i18n.t('generic.delete'),
            }),
        };
    },
    computed: {
        ...mapGetters(Modules.productcatalog, [Getters.PC_GET_ENTITIES_BY_TYPE_APPROVED]),
        ...mapGetters(Modules.segments, { segments: Getters.CACHED_SEGMENTS }),
        offers() {
            return (
                this[Getters.PC_GET_ENTITIES_BY_TYPE_APPROVED](ENTITY_TYPES.OFFER).filter(o =>
                    o.name.startsWith('AT&T'),
                ) || []
            );
        },
        offerNames() {
            return this.offers.reduce((obj, offer) => ({ ...obj, [offer.name]: offer.id }), {});
        },
        segmentsFiltered() {
            return this.segments.filter(s => s.name.startsWith('AT&T')).map(({ name }) => name);
        },
        sortedFilteredEntities() {
            return this.filteredEntitiesMixin(this.offerPrices);
        },
        modalTitle() {
            if (this.isImportModalOpen) {
                return 'Import Price list from CSV';
            }

            if (this.isEditPriceListModal) {
                return 'Edit Price list';
            }

            return 'New Price list';
        },
        isModalOpen() {
            return this.isImportModalOpen || this.isNewPriceListModal || this.isEditPriceListModal;
        },
    },
    created() {
        if (!this.offerPrices.length) {
            this.offerPrices = getOperatorConfigValue('atiotOfferPriceLists', []);
            this.saveToStore();
        }
        // Maybe just fetch offers instead of offers + templates + segment_config
        this.$withLoadingSpinner(() => this.fetchEntities(ENTITY_TYPES.OFFER));
    },
    methods: {
        setSearchQuery(query) {
            this.searchQuery = query;
        },
        closeModal() {
            this.isImportModalOpen = false;
            this.isNewPriceListModal = false;
            this.isEditPriceListModal = false;
            this.newOfferPriceList = new ItotOfferPriceList();
        },
        updateFiles(files) {
            if (!files.length) {
                return;
            }

            this.$Progress.start();
            const reader = new FileReader();

            // Should we support multiple files a.k.a. loop ?
            reader.readAsText(files[0].file);
            reader.onload = res => {
                this.newOfferPriceList.prices = sortBy(this.csvToArray(res.target.result), ['name']);
            };
            reader.onerror = err => {
                this.$Progress.fail();
                this.$showWarningAlert({ message: err.message });
            };
        },
        csvToArray(str) {
            const rows = str.slice(str.indexOf('\n') + 1).split('\n');
            const csvData = [];
            let dataInvalid = false;

            rows.forEach((el, index) => {
                if (el !== '') {
                    // array starts with 0 and first row are headers
                    const lineNumber = index + 2;

                    if (!el.includes(',')) {
                        this.$showWarningAlert({
                            message: this.$i18n.t('charging.conditionParameters.fileNotValid', {
                                line: lineNumber,
                            }),
                        });
                        dataInvalid = true;
                        return;
                    }

                    const [name, persona, priceValue] = el.split(',').filter(n => n);
                    const price = Number(priceValue.trim());

                    if (!/^\d*\.?\d+$/.test(price)) {
                        this.$showWarningAlert({
                            message: this.$i18n.t('charging.conditionParameters.fileValueNotValid', {
                                line: lineNumber,
                            }),
                        });
                        dataInvalid = true;
                        return;
                    }

                    const id = this.offerNames[name];

                    if (!id) {
                        return;
                    }

                    csvData.push(new OfferPrice({ id, name, price, persona }));
                }
            });

            if (dataInvalid) {
                this.$Progress.fail();
            } else {
                this.$Progress.finish();
            }

            return dataInvalid ? null : csvData;
        },
        savePriceList() {
            this.addNewPriceList();
            this.saveToStore();
            this.closeModal();
        },
        addNewPriceList() {
            const existing = this.offerPrices.find(op => op.name === this.newOfferPriceList.name);

            if (!existing) {
                if (this.isNewPriceListModal) {
                    this.newOfferPriceList.prices.forEach(p => {
                        p.persona = this.newOfferPriceList.persona;
                    });
                }

                this.offerPrices.push(new ItotOfferPriceList(this.newOfferPriceList));
            }
        },
        saveToStore() {
            LocalStorageHelper.set('atiotOfferPriceLists', JSON.stringify(this.offerPrices));
        },
        openNewPartner() {
            this.newOfferPriceList = new ItotOfferPriceList({
                prices: Object.entries(this.offerNames).reduce(
                    (arr, [name, id]) => [...arr, { price: 0, name, id, persona: this.newOfferPriceList.persona }],
                    [],
                ),
            });
            this.isNewPriceListModal = true;
        },
        openEditPriceList({ id }) {
            this.newOfferPriceList = this.offerPrices.find(p => p.id === id);
            this.isEditPriceListModal = true;
        },
        confirmDeletePartner(entity) {
            this.$showWarningAlert({
                message: this.$i18n.t('alerts.areYouSureDeleteEntity', { entityName: entity.name }),
                buttons: [this.deleteConfirmationButton],
            });

            this.$eventBus.$once('buttonClicked', id => {
                if (id === this.deleteConfirmationButton.id) {
                    this.deletePriceList(entity.id);
                }
            });
        },
        deletePriceList(id) {
            this.offerPrices = this.offerPrices.filter(p => p.id !== id);
            this.saveToStore();
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/consistency';

.modal-wrapper {
    width: max-content;
    min-width: 50vw;
    max-width: 75vw;
    padding: $spacing-xxl;
}

.price-list {
    display: grid;
    grid-template-columns: max-content 10rem;
    align-items: center;
    gap: $spacing-xs;

    &.edit-form {
        grid-template-columns: repeat(2, max-content) 10rem;
    }
}
</style>
