<script>
import RouteNames from '@/router/routeNames';
import i18n from '@/i18n';
import permissionsService from '@/services/permissions/permissions.service';

const SECTIONS = {
    [RouteNames.USER_GUIDE_HOME]: {
        label: i18n.t('userGuide.userGuide'),
        key: 'user-guide-home',
        routeName: RouteNames.USER_GUIDE_HOME,
        subRoutes: [],
        lfInternal: true,
        permission: () => true,
    },
    [RouteNames.USER_GUIDE_TAGS]: {
        label: i18n.t('generic.tags'),
        key: 'user-guide-tags',
        routeName: RouteNames.USER_GUIDE_TAGS,
        subRoutes: [RouteNames.USER_GUIDE_TAG],
        permission: () => true,
    },
    [RouteNames.USER_GUIDE_ARTICLES]: {
        label: i18n.t('generic.articles'),
        key: 'user-guide-articles',
        routeName: RouteNames.USER_GUIDE_ARTICLES,
        subRoutes: [RouteNames.USER_GUIDE_ARTICLE],
        permission: () => permissionsService.isUserAdmin(),
    },
};

export default {
    name: 'UserGuideSubSidebarMixin',
    data() {
        return {
            SubSidebarSections: SECTIONS,
            SubSidebarDefaultSection: SECTIONS[RouteNames.USER_GUIDE_HOME],
        };
    },
};
</script>
