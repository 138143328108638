import { uuidV4 } from '@/common/utils';
// Import of a file that exposes jquery globally because it`s required by draw2d library
import '@/__new__/features/orchestrationengine/common/import-jquery';
// eslint-disable-next-line import/extensions
import 'jquery-ui-dist/jquery-ui.js';
import draw2d from 'draw2d';
// Import of a file that exposes globally draw2d custom elements
import '@/__new__/features/orchestrationengine/common/customDraw2dElements';

const maxZoomBoundary = 0.2;
const minZoomBoundary = 5;
const zoomStep = 1;

/* Interfaces for typescript */
type SetupForDiagram = {
    blockWidth: number;
    blockHeight: number;
    xCoefficient: number;
    yCoefficient: number;
    xPadding: number;
    yPadding: number;
    rowSize: number;
    bufferZoneSize: number;
    allowHighlightClick: boolean;
};

type ColorSet = {
    blockBorder?: string;
    blockBackground?: string;
    link?: string;
};

type ColorSets = {
    [key: string]: ColorSet;
};

type Grid = Record<string, number>;

type RenderItem = {
    key: string;
    maxChilds: number;
    parentsNodes?: string[];
    linkedTo?: { type: string; key: string }[];
    proceed: number;
    row: number;
    column: number;
    x: number;
    y: number;
};

type RenderList = {
    [key: string]: RenderItem;
};

type State = {
    type?: string;
    next?: string;
    catch?: { next: string }[];
    choices?: { next: string }[];
    default?: string;
    execution_plans?: { [key: string]: any };
    looping_states?: { [key: string]: { end?: boolean; next: string } };
    start_at?: string;
};

type Port = {
    type?: string;
    id?: string;
    width?: number;
    height?: number;
    selectable?: boolean;
    draggable?: boolean;
    visible?: boolean;
    visibility?: boolean;
    bgColor?: string;
    color?: string;
    name?: string;
    port?: string;
    locator?: string;
    userData?: {
        figureType?: string;
    };
};

type BlockData = {
    key: string;
    linkedTo?: any[];
    parentsNodes?: any[];
    x?: number;
    y?: number;
};

type TaskBlockConfig = {
    [key: string]: {
        type?: string;
        id?: string;
        x?: number;
        y?: number;
        width?: number;
        height?: number;
        selectable?: boolean;
        draggable?: boolean;
        stroke?: number;
        radius?: number;
        bgColor?: string;
        color?: string;
        userData?: {
            figureType?: string;
            key?: string;
        };
        ports?: Port[];
    };
};

type SourceTarget = {
    node?: string;
    port?: string;
};

type LinkConfig = {
    type?: string;
    id?: string;
    policy?: string;
    router?: string;
    selectable?: boolean;
    stroke?: number;
    color?: string;
    source?: SourceTarget;
    target?: SourceTarget;
    userData?: {
        figureType?: string;
    };
    vertex?: any[];
};
/* Interfaces for typescript */

const defaultSetup: SetupForDiagram = {
    blockWidth: 120,
    blockHeight: 50,

    xCoefficient: 180,
    yCoefficient: 100,

    xPadding: 40,
    yPadding: 20,

    rowSize: 20,

    bufferZoneSize: 300,

    allowHighlightClick: true,
};

enum STATE_TYPES {
    Parallel = 'parallel',
    Choice = 'choice',
    Loop = 'loop',
}

enum FIGURE_TYPES {
    Port = 'port',
    Connection = 'connection',
    Block = 'block',
    Label = 'label',
}

enum PORT_TYPES {
    MainOutput = 'output',
    SideLeftOutput = 'sideLeftOutput',
    SideRightOutput = 'sideRightOutput',
}

const COLOR_SETS: ColorSets = {
    green: {
        blockBorder: '#37C9AA',
        blockBackground: '#E4F7F3',
        link: '#37C9AA',
    },
    gray: {
        blockBorder: '#BBBBBB',
        blockBackground: '#FFFFFF',
        link: '#DDDDDD',
    },
    red: {
        blockBorder: '#E8504C',
        blockBackground: '#FEE5E5',
        link: '#E8504C',
    },
};

/* Grid helper functions */
function incrementRowGridHelper(grid: Grid, row: number, maxChilds: number | undefined): void {
    if (!grid[`${row}:${'wholeRow'}`]) {
        grid[`${row}:${'wholeRow'}`] = 0;
    }
    if (maxChilds) {
        grid[`${row}:${'wholeRow'}`] = grid[`${row}:${'wholeRow'}`] + maxChilds - 1;
    } else {
        grid[`${row}:${'wholeRow'}`] = grid[`${row}:${'wholeRow'}`] + 1;
    }
}

function writeToGridHelper(grid: Grid, val: number, row: number, column: number): void {
    grid[`${row}:${column}`] = val;
}

function readFromGridHelper(grid: Grid, row: number, column: string | number = 'wholeRow'): number {
    return grid[`${row}:${column}`];
}
/* Grid helper functions */

/* Map positioning functions */
function recursiveKeyRemap(renderList: RenderList, currentKey: string, traceStack: string[]): string[] {
    const localKeyArr = [currentKey];
    if (traceStack.includes(currentKey)) {
        return localKeyArr;
    }
    if (renderList[currentKey].linkedTo) {
        for (const nextKeyObj of renderList[currentKey].linkedTo || []) {
            localKeyArr.push(...recursiveKeyRemap(renderList, nextKeyObj.key, [...traceStack, currentKey]));
        }
    }
    return localKeyArr;
}

function recursiveAlign(setup: SetupForDiagram, renderList: RenderList, currentKey: string): void {
    const allKeys = [...new Set(recursiveKeyRemap(renderList, currentKey, []))];
    for (const key of allKeys) {
        renderList[key].x += setup.xCoefficient / 2;
    }
}

function recursiveRemap(
    setup: SetupForDiagram,
    renderList: RenderList,
    states: { [key: string]: State },
    currentStateKey: string,
    lastLevelNum: number,
    columnHelper: { [key: string]: number },
    traceStack: string[],
): RenderItem {
    if (!renderList[currentStateKey]) {
        renderList[currentStateKey] = {
            key: currentStateKey,
            maxChilds: 0,
            column: 0,
            proceed: 0,
            row: 0,
            x: 0,
            y: 0,
        };
    }

    if (!renderList[currentStateKey].parentsNodes) {
        renderList[currentStateKey].parentsNodes = [];
    }
    if (
        traceStack &&
        Array.isArray(traceStack) &&
        traceStack.length > 0 &&
        !renderList?.[currentStateKey]?.parentsNodes?.includes(traceStack[traceStack.length - 1])
    ) {
        renderList?.[currentStateKey]?.parentsNodes?.push(traceStack[traceStack.length - 1]);
    }

    // Infinite recursion guard
    if (traceStack.includes(currentStateKey)) {
        return {} as RenderItem;
    }

    const nextStates = [];

    if (states[currentStateKey]?.type && states[currentStateKey].type === STATE_TYPES.Parallel) {
        if (states[currentStateKey] && states[currentStateKey].execution_plans && states[currentStateKey].next) {
            // eslint-disable-next-line guard-for-in
            for (const prop in states[currentStateKey].execution_plans) {
                nextStates.push({
                    type: PORT_TYPES.MainOutput,
                    key: prop,
                });
                states[prop] = {
                    type: 'task',
                    next: states[currentStateKey].next,
                };
            }
        }
    } else if (states[currentStateKey]?.type && states[currentStateKey].type === STATE_TYPES.Loop) {
        if (states[currentStateKey] && states[currentStateKey].next) {
            nextStates.push({
                type: PORT_TYPES.MainOutput,
                key: states[currentStateKey].next,
            });
        }
        if (states[currentStateKey] && states[currentStateKey].start_at) {
            nextStates.push({
                type: PORT_TYPES.MainOutput,
                key: states[currentStateKey].start_at,
            });
        }
        if (states[currentStateKey] && states[currentStateKey].looping_states) {
            const loopingStates = states[currentStateKey].looping_states;
            for (const key in loopingStates) {
                if (loopingStates[key].end) {
                    states[key].catch = [{ next: currentStateKey }];
                    delete states[key].next;
                } else {
                    states[key].next = loopingStates[key].next;
                }
            }
        }
    } else if (states[currentStateKey]?.type && states[currentStateKey].type === STATE_TYPES.Choice) {
        if (
            states[currentStateKey] &&
            states[currentStateKey].choices &&
            Array.isArray(states[currentStateKey].choices)
        ) {
            const state =
                states[currentStateKey].choices?.reduce((res: any, val) => {
                    return [
                        ...res,
                        {
                            type: PORT_TYPES.MainOutput,
                            key: val.next,
                        },
                    ];
                }, []) || [];
            nextStates.push(...state);
        }
        if (states[currentStateKey] && states[currentStateKey].default) {
            if (!nextStates.find(({ key }) => key === states[currentStateKey].default)) {
                nextStates.push({
                    type: PORT_TYPES.SideRightOutput,
                    key: states[currentStateKey].default,
                });
            }
        }
    } else {
        if (states[currentStateKey] && states[currentStateKey].next) {
            nextStates.push({
                type: PORT_TYPES.MainOutput,
                key: states[currentStateKey].next,
            });
        }
        if (states[currentStateKey] && states[currentStateKey].catch) {
            for (const catchItem of states[currentStateKey].catch || []) {
                if (!nextStates.find(({ key }) => key === catchItem.next)) {
                    nextStates.push({
                        type: PORT_TYPES.SideRightOutput,
                        key: catchItem.next,
                    });
                }
            }
        }
    }

    renderList[currentStateKey].linkedTo = nextStates;

    if (nextStates.length > 0) {
        for (const nodeKeyObj of nextStates) {
            const recursionRes = recursiveRemap(
                setup,
                renderList,
                states,
                nodeKeyObj.key,
                lastLevelNum + 1,
                columnHelper,
                [...traceStack, currentStateKey],
            );

            if (renderList[nodeKeyObj.key].proceed === 1) {
                renderList[currentStateKey].maxChilds += recursionRes.maxChilds;
            }
        }
        if (!renderList[currentStateKey].maxChilds) {
            renderList[currentStateKey].maxChilds = 1;
        }
    } else {
        renderList[currentStateKey].maxChilds = 1;
    }

    const filteredNodes = nextStates.filter(
        node => !traceStack.includes(node.key) && renderList[node.key].proceed === 1,
    );

    if (!renderList[currentStateKey].row) {
        renderList[currentStateKey].row = lastLevelNum;
    }
    if (!renderList[currentStateKey].column) {
        incrementRowGridHelper(columnHelper, renderList[currentStateKey].row, undefined);
        renderList[currentStateKey].column = readFromGridHelper(columnHelper, renderList[currentStateKey].row);
        incrementRowGridHelper(columnHelper, renderList[currentStateKey].row, renderList[currentStateKey].maxChilds);
        for (
            let i = renderList[currentStateKey].column;
            i < renderList[currentStateKey].column + renderList[currentStateKey].maxChilds;
            i += 1
        ) {
            writeToGridHelper(columnHelper, renderList[currentStateKey].maxChilds, renderList[currentStateKey].row, i);
        }
        if (renderList[currentStateKey].maxChilds === 1 && filteredNodes.length === 0) {
            for (let i = renderList[currentStateKey].row + 1; i < 100; i += 1) {
                incrementRowGridHelper(columnHelper, i, undefined);
            }
        }
    }
    // To change render axis replace row by column and vice-versa
    if (!renderList[currentStateKey].x) {
        if (filteredNodes.length > 0) {
            renderList[currentStateKey].x =
                (renderList[filteredNodes[filteredNodes.length - 1].key].x - renderList[filteredNodes[0].key].x) / 2 +
                renderList[filteredNodes[0].key].x;
        } else {
            renderList[currentStateKey].x =
                (renderList[currentStateKey].column - 1) * setup.xCoefficient + setup.xPadding;
        }
        // Parallel type align hack. It is messy but I didn`t find better solution yet.
        if (states[traceStack[traceStack.length - 1]]?.type === STATE_TYPES.Parallel) {
            const parallelArray = Object.keys(states[traceStack[traceStack.length - 1]].execution_plans || {});
            const firstParallelChildKey = parallelArray[0];
            const firstParallelChildX = renderList[firstParallelChildKey].x;
            if (firstParallelChildX && currentStateKey !== firstParallelChildKey) {
                renderList[currentStateKey].x =
                    firstParallelChildX + setup.xCoefficient * parallelArray.indexOf(currentStateKey);
                recursiveAlign(setup, renderList, renderList?.[currentStateKey]?.linkedTo?.[0]?.key || '');
            }
        }
    }
    if (!renderList[currentStateKey].y) {
        renderList[currentStateKey].y = (renderList[currentStateKey].row - 1) * setup.yCoefficient + setup.yPadding;
    }

    if (!renderList[currentStateKey].proceed) {
        renderList[currentStateKey].proceed = 0;
    }
    renderList[currentStateKey].proceed += 1;

    return renderList[currentStateKey];
}
/* Map positioning functions */

/* Draw2d render functions */
function getTaskBlockConfig(setup: SetupForDiagram, blockData: BlockData): TaskBlockConfig {
    const ports: Port[] = [];
    if (blockData.linkedTo && blockData.linkedTo.length > 0) {
        ports.push(
            {
                type: 'draw2d.OutputPort',
                id: uuidV4(),
                width: 6,
                height: 6,
                selectable: false,
                draggable: false,
                visible: false,
                bgColor: '#DDDDDD',
                color: '#DDDDDD',
                name: PORT_TYPES.MainOutput,
                port: 'draw2d.OutputPort',
                locator: 'draw2d.layout.locator.BottomLocator',
                userData: {
                    figureType: FIGURE_TYPES.Port,
                },
            },
            {
                type: 'draw2d.OutputPort',
                id: uuidV4(),
                width: 6,
                height: 6,
                selectable: false,
                draggable: false,
                visible: false,
                bgColor: '#DDDDDD',
                color: '#DDDDDD',
                name: PORT_TYPES.SideLeftOutput,
                port: 'draw2d.OutputPort',
                locator: 'draw2d.layout.locator.LeftLocator',
                userData: {
                    figureType: FIGURE_TYPES.Port,
                },
            },
            {
                type: 'draw2d.OutputPort',
                id: uuidV4(),
                width: 6,
                height: 6,
                selectable: false,
                draggable: false,
                visible: false,
                bgColor: '#DDDDDD',
                color: '#DDDDDD',
                name: PORT_TYPES.SideRightOutput,
                port: 'draw2d.OutputPort',
                locator: 'draw2d.layout.locator.RightLocator',
                userData: {
                    figureType: FIGURE_TYPES.Port,
                },
            },
        );
    }
    if (blockData?.parentsNodes && blockData.parentsNodes.length > 0) {
        for (let i = 0; i < blockData.parentsNodes.length; i += 1) {
            ports.push({
                type: 'draw2d.InputPort',
                id: uuidV4(),
                width: 6,
                height: 6,
                selectable: false,
                draggable: false,
                visibility: false,
                bgColor: '#DDDDDD',
                color: '#DDDDDD',
                name: `input${i}`,
                port: 'draw2d.InputPort',
                locator: 'customs.TopInputPortLocator',
                userData: {
                    figureType: FIGURE_TYPES.Port,
                },
            });
        }
    }
    return {
        [blockData.key]: {
            type: 'draw2d.shape.basic.Rectangle',
            id: uuidV4(),
            x: blockData.x,
            y: blockData.y,
            width: setup.blockWidth,
            height: setup.blockHeight,
            selectable: false,
            draggable: true,
            stroke: 1.5,
            radius: 8,
            bgColor: '#FFFFFF',
            color: '#BBBBBB',
            userData: {
                figureType: FIGURE_TYPES.Block,
                key: blockData.key,
            },
            ports,
        },
    };
}

function getLinkConfig(fromId: string, toId: string, outputPort: string, inputPort: string): LinkConfig {
    return {
        type: 'draw2d.Connection',
        id: uuidV4(),
        policy: 'draw2d.policy.line.LineSelectionFeedbackPolicy',
        router: 'draw2d.layout.connection.ManhattanBridgedConnectionRouter',
        selectable: false,
        stroke: 2,
        color: '#DDDDDD',
        source: {
            node: fromId,
            port: outputPort,
        },
        target: {
            node: toId,
            port: inputPort,
        },
        userData: {
            figureType: FIGURE_TYPES.Connection,
        },
        vertex: [],
    };
}

function decorateArrows(canvas: any): void {
    const lines = canvas.getLines().data;
    for (const line of lines) {
        const decorator = new draw2d.decoration.connection.ArrowDecorator(8, 8);
        decorator.setBackgroundColor('#DDDDDD');
        line.setTargetDecorator(decorator);
        line.repaint();
    }
}

function breakLineInText(setup: SetupForDiagram, text: string): string {
    if (text.length <= setup.rowSize) {
        return text;
    }

    const delimiter = '_';
    let formattedText = '';
    let prevIndex = 0;
    let i = setup.rowSize - 1;

    while (i < text.length) {
        const lastDelimiterIndex = text.lastIndexOf(delimiter, i);
        const lastCamelCaseIndex = text.slice(prevIndex, i + 1).search(/[A-Z]/);

        let breakIndex = -1;
        if (lastDelimiterIndex > prevIndex) {
            breakIndex = lastDelimiterIndex;
        } else if (lastCamelCaseIndex > 0) {
            breakIndex = prevIndex + lastCamelCaseIndex;
        }

        if (breakIndex > prevIndex) {
            formattedText += `${text.substring(prevIndex, breakIndex)}\n`;
            prevIndex = breakIndex;
            i = prevIndex + setup.rowSize;
        } else {
            formattedText += `${text.substring(prevIndex, i + 1)}\n`;
            prevIndex = i + 1;
            i = prevIndex + setup.rowSize;
        }
    }

    formattedText += text.substring(prevIndex);
    return formattedText;
}

function decorateLabels(setup: SetupForDiagram, canvas: any, blocks: any): void {
    for (const key of Object.keys(blocks)) {
        const figure = new draw2d.shape.basic.Text();

        figure.attr({
            type: 'draw2d.shape.basic.Text',
            id: uuidV4(),
            x: blocks[key].x,
            y: blocks[key].y,
            width: setup.blockWidth - 8,
            height: setup.blockHeight,
            selectable: false,
            draggable: false,
            cssClass: 'draw2d_shape_basic_Text',
            bgColor: 'rgba(0,0,0,0)',
            color: 'rgba(0,0,0,0)',
            text: breakLineInText(setup, key),
            outlineColor: 'rgba(0,0,0,0)',
            fontSize: 12,
            fontColor: '#333333',
            userData: {
                figureType: FIGURE_TYPES.Label,
                key,
            },
        });

        const locator = new draw2d.layout.locator.CenterLocator();

        canvas.getFigure(blocks[key].id).add(figure, locator);
    }
}

function mapDataToDraw2dJSON(setup: SetupForDiagram, remappedData: any): object {
    let blocksJSONObject: { [key: string]: { [key: string]: any } } = {};
    const remappedDataValues: any = Object.values(remappedData);

    for (const element of remappedDataValues) {
        blocksJSONObject = {
            ...blocksJSONObject,
            ...getTaskBlockConfig(setup, element),
        };
    }

    const linksJsonArray = [];
    const linksHelper: { [key: string]: number } = {};
    for (const element of remappedDataValues) {
        for (const linkKeyObj of element.linkedTo) {
            if (!Object.hasOwnProperty.call(linksHelper, linkKeyObj.key)) {
                linksHelper[linkKeyObj.key] = 0;
            } else {
                linksHelper[linkKeyObj.key] += 1;
            }
            linksJsonArray.push(
                getLinkConfig(
                    blocksJSONObject[element.key].id,
                    blocksJSONObject[linkKeyObj.key].id,
                    linkKeyObj.type,
                    `input${linksHelper[linkKeyObj.key]}`,
                ),
            );
        }
    }

    return {
        blocksJSONObject,
        draw2dJSON: [...Object.values(blocksJSONObject), ...linksJsonArray],
    };
}

function highlightFigure(figure: any, connections: any, colorSet: any): void {
    figure.setColor(colorSet.blockBorder);
    figure.setBackgroundColor(colorSet.blockBackground);
    const highlightConnection = (connection: any) => {
        connection.setColor(colorSet.link);

        const decorator = new draw2d.decoration.connection.ArrowDecorator(8, 8);
        decorator.setBackgroundColor(colorSet.link);
        connection.setTargetDecorator(decorator);
    };
    if (!connections) {
        for (const inputPort of figure.getInputPorts().data) {
            const connection = inputPort?.getConnections()?.data?.[0];
            highlightConnection(connection);
        }
    } else {
        for (const connection of connections) {
            highlightConnection(connection);
        }
    }
}

function highlightRecursively(figure: any, traceStack: any, colorSet: any): any {
    if (traceStack.includes(figure.id)) return {};

    const figuresIds: { [key: string]: string } = {};
    highlightFigure(figure, undefined, colorSet);
    let recursionResults = {};
    figuresIds[figure.id] = FIGURE_TYPES.Block;
    for (const inputPort of figure.getInputPorts().data) {
        const connection = inputPort?.getConnections()?.data?.[0];

        figuresIds[connection.id] = FIGURE_TYPES.Connection;
        recursionResults = {
            ...recursionResults,
            ...highlightRecursively(connection.getSource().parent, [...traceStack, figure.id], colorSet),
        };
    }
    return {
        ...figuresIds,
        ...recursionResults,
    };
}

function highlightPath(figure: any, path: any, colorSet: any): any {
    const figuresIds: { [key: string]: string } = {};
    for (let i = 0; i < path.length; i += 1) {
        figuresIds[path[i].id] = FIGURE_TYPES.Block;
        const connections = [];
        if (path[i]?.getOutputPorts()?.data) {
            for (const port of path[i].getOutputPorts().data) {
                if (port?.getConnections()?.data) {
                    for (const connection of port.getConnections().data) {
                        if (
                            path[i + 1]?.userData?.key &&
                            path[i + 1].userData.key === connection.getTarget().parent.userData.key
                        ) {
                            connections.push(connection);
                            figuresIds[connection.id] = FIGURE_TYPES.Connection;
                        }
                    }
                }
            }
        }
        highlightFigure(path[i], connections, colorSet);
    }
    return figuresIds;
}

function resetHighlight(canvas: any, ids: any): void {
    for (const idsKey in ids) {
        if (ids[idsKey] === 'block') {
            canvas.getFigure(idsKey).setColor(COLOR_SETS.gray.blockBorder);
            canvas.getFigure(idsKey).setBackgroundColor(COLOR_SETS.gray.blockBackground);
        } else if (ids[idsKey] === 'connection') {
            canvas.getLine(idsKey).setColor(COLOR_SETS.gray.link);
            const decorator = new draw2d.decoration.connection.ArrowDecorator(8, 8);
            decorator.setBackgroundColor(COLOR_SETS.gray.link);
            canvas.getLine(idsKey).setTargetDecorator(decorator);
        }
    }
}

function highlightPathToFigure(figure: any, path: any, colorSet: any): any {
    if (path) {
        return highlightPath(figure, path, colorSet);
    }
    return highlightRecursively(figure, [], colorSet);
}

function handleCanvasClick(canvas: any, figure: any, highlightedElementsIds: any): any {
    resetHighlight(canvas, highlightedElementsIds);
    let newHighlightedElementsIds = {};
    if (figure != null) {
        if (figure?.userData?.figureType === FIGURE_TYPES.Block) {
            newHighlightedElementsIds = highlightPathToFigure(figure, undefined, COLOR_SETS.green);
        }
        if (figure?.userData?.figureType === FIGURE_TYPES.Label) {
            newHighlightedElementsIds = highlightPathToFigure(figure.parent, undefined, COLOR_SETS.green);
        }
    }
    return newHighlightedElementsIds;
}

function canvasZoomIn(canvas: any): void {
    const currentZoom = canvas.getZoom();
    if (currentZoom >= maxZoomBoundary) canvas.setZoom((Math.round(currentZoom * 10) - zoomStep) / 10, true);
}

function canvasZoomOut(canvas: any): void {
    const currentZoom = canvas.getZoom();
    if (currentZoom < minZoomBoundary) canvas.setZoom((Math.round(currentZoom * 10) + zoomStep) / 10, true);
}

function adjustCanvasDimensions(setup: SetupForDiagram, canvas: any): void {
    let xMax = 0;
    let yMax = 0;
    canvas.getFigures().each((i: any, f: any) => {
        xMax = Math.max(f.x, xMax);
        yMax = Math.max(f.y, yMax);
    });
    const calculatedCanvasWidth = xMax + setup.blockWidth + setup.bufferZoneSize;
    const calculatedCanvasHeight = yMax + setup.blockHeight + setup.bufferZoneSize;
    const canvasWrapperWidth = canvas.getWidth();
    const canvasWrapperHeight = canvas.getHeight();

    canvas.setDimension(
        Math.max(calculatedCanvasWidth, canvasWrapperWidth),
        Math.max(calculatedCanvasHeight, canvasWrapperHeight),
    );
    canvas.getFigures().each((i: any, f: any) => {
        f.installEditPolicy(
            new draw2d.policy.figure.RegionEditPolicy(
                setup.xPadding,
                setup.yPadding,
                Math.max(calculatedCanvasWidth, canvasWrapperWidth) - setup.xPadding - 40,
                Math.max(calculatedCanvasHeight, canvasWrapperHeight) - setup.yPadding - 40,
            ),
        );
    });
}

function renderDiagramElements(
    setup: SetupForDiagram,
    canvasID: string,
    draw2dCanvas: any,
    remappedData: any,
    onClickCallbackFunc: (figure: any) => void,
): any {
    // Get config for draw2d from remapped data
    const jsonDocument: any = mapDataToDraw2dJSON(setup, remappedData);

    let canvas: any;
    if (!draw2dCanvas) {
        // Get canvas object
        canvas = new draw2d.Canvas(canvasID);
    } else {
        canvas = draw2dCanvas;
        canvas.clear();
    }

    // Render elements from config into canvas
    const reader = new draw2d.io.json.Reader();
    reader.unmarshal(canvas, jsonDocument.draw2dJSON);

    adjustCanvasDimensions(setup, canvas);

    // Add label to blocks separately to be able to put them in block center using locators
    decorateLabels(setup, canvas, jsonDocument.blocksJSONObject);

    // Add decoration to arrows
    decorateArrows(canvas);

    // Hide ports on blocks
    canvas.getFigures().each((i: any, f: any) => {
        f.getPorts().each((j: any, port: any) => {
            port.setVisible(false);
        });
    });

    // Add CanvasPolicy to catch the onClick method
    let highlightedElementsIds = {};
    canvas.installEditPolicy(
        new draw2d.policy.canvas.CanvasPolicy({
            onClick(figure: any) {
                if (setup.allowHighlightClick) {
                    highlightedElementsIds = handleCanvasClick(canvas, figure, highlightedElementsIds);
                }
                if (typeof onClickCallbackFunc === 'function') {
                    onClickCallbackFunc(figure);
                }
            },
            onMouseUp() {
                adjustCanvasDimensions(setup, canvas);
            },
        }),
    );

    return canvas;
}
/* Draw2d render functions */

// Draw call function that draw all the objects and update stage
function drawPlanDiagram(
    canvasID: string,
    draw2dCanvas: any,
    states: any,
    startKey: any,
    setup: SetupForDiagram,
    onClickCallbackFunc: () => void,
): any {
    if (!states || !startKey) {
        if (draw2dCanvas) {
            draw2dCanvas.clear();
        }
        return null;
    }

    const localSetup = setup || { ...defaultSetup };

    const renderList = {};
    // Remap data to get blocks to render, their links and coordinates
    recursiveRemap(localSetup, renderList, states, startKey, 1, {}, []);

    // Render diagram using remapped data
    return renderDiagramElements(localSetup, canvasID, draw2dCanvas, renderList, onClickCallbackFunc);
}

export default {
    FIGURE_TYPES,
    COLOR_SETS,
    drawPlanDiagram,
    canvasZoomIn,
    canvasZoomOut,
    renderDiagramElements,
    recursiveRemap,
    defaultSetup,
    handleCanvasClick,
    highlightPathToFigure,
};
