import http from '@/http/index';

type quietHoursData = {
    rules: unknown[];
};

export function getQuietHours() {
    return http({
        method: 'GET',
        url: '/ceapi/cep-site-backend/quiethours',
        headers: {
            'content-type': 'application/json',
        },
    });
}

export function addQuietHours(data: quietHoursData) {
    return http({
        method: 'POST',
        url: '/ceapi/cep-site-backend/quiethours',
        headers: {
            'content-type': 'application/json',
        },
        data,
    });
}

export function updateQuietHour(data: quietHoursData, id: string) {
    return http({
        method: 'PUT',
        url: `/ceapi/cep-site-backend/quiethours/${id}`,
        headers: {
            'content-type': 'application/json',
        },
        data,
    });
}
