<template>
    <AbstractSubSidebarPageWrapper :pageTitle="$i18n.t('settings.settings')">
        <template #subSidebar>
            <SubSidebar
                v-model="selectedRouteName"
                :sections="permissionFilteredSections"
                class="sidebar"
                @change="changeActiveSection"
            />
        </template>
        <template #content>
            <div class="pr-4">
                <div class="header">
                    <NameInput
                        :key="`name`"
                        v-model="role.name"
                        :placeholder="$i18n.t('rolesAndPermissions.roleName')"
                        :error="$v.role.name.$error"
                        class="mb-3"
                    />
                </div>
                <div
                    v-if="isViewConfig"
                    id="current-permissions-list"
                    class="list"
                >
                    <ViewPermissionsTree
                        v-for="(value, key, index) in viewPermissions"
                        :key="`${index}-${key}`"
                        :view="value"
                        class="root"
                        @change="viewsTreeChange"
                    />
                </div>
                <div />
                <div
                    v-if="!isViewConfig"
                    id="permissions-list"
                    class="list"
                >
                    <AppTable
                        :entities="rolesToShow"
                        :isDefaultHoverEnabled="false"
                        :canSelectColumns="false"
                        :disableBoxShadow="true"
                        :isDataLoading="isDataLoading"
                        :columnsData="tableColumnsData"
                        :canSort="false"
                    >
                        <template #readSlot="{ entity }">
                            <AppCheckbox
                                v-model="entity.read.state"
                                @input="seReadPermission(entity)"
                            />
                        </template>
                        <template #writeSlot="{ entity }">
                            <AppCheckbox
                                v-model="entity.write.state"
                                @input="setWritePermission(entity)"
                            />
                        </template>
                    </AppTable>
                </div>
                <div class="button-wrapper">
                    <AppButton
                        id="experiment-save-button"
                        :buttonType="BUTTON_TYPES.PRIMARY"
                        :label="$i18n.t('generic.save')"
                        :iconType="ICON_TYPES.CHECK"
                        @click="onSave"
                    />
                </div>
            </div>
        </template>
    </AbstractSubSidebarPageWrapper>
</template>

<script>
// Vuex
import { mapGetters, mapActions } from 'vuex';
import Actions, { Getters } from '@/store/mutation-types';

// helpers
import { ALERT_TYPES } from '@/common/alerts/Alert';
import { permissionGroups } from '@/common/roles/roleHelper';
import RouteNames from '@/router/routeNames';

// Libraries
import { required } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';
import { uniq, without } from 'lodash';

// Components
import NameInput from '@/components/partials/inputs/NameInput.vue';
import AppTable from '@/components/partials/AppTable.vue';
import AppCheckbox from '@/components/partials/inputs/AppCheckbox.vue';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import { ICON_TYPES } from '@/common/iconHelper';
import SubSidebarMixin from '@/components/partials/SubSidebarMixin.vue';
import SettingsSubSidebarMixin from '@/__new__/features/settings/SettingsSubSidebarMixin.vue';
import AbstractSubSidebarPageWrapper from '@/components/layout/AbstractSubSidebarPageWrapper.vue';
import SubSidebar from '@/components/layout/SubSidebar.vue';
import ViewPermissionsTree from './ViewPermissionsTree.vue';
import { mapViewsTemplate } from '@/common/viewsHelper';

// HTTP
import { addRole, updateRole } from '@/__new__/services/portal/admin/http/role';
import Account from '@/models/Account';
import { getCompanyPermissions, getViews } from '@/__new__/services/portal/auth/http/login';
import { Modules } from '@/store/store';
import { isViewConfig } from '@/services/permissions/permissions.service';

export default {
    name: 'RoleEditor',
    components: {
        AppButton,
        NameInput,
        AppTable,
        AppCheckbox,
        SubSidebar,
        AbstractSubSidebarPageWrapper,
        ViewPermissionsTree,
    },
    mixins: [validationMixin, SubSidebarMixin, SettingsSubSidebarMixin],
    data() {
        return {
            pageName: this.$i18n.t('rolesAndPermissions.roleEditor.roleEditor'),
            isDataLoading: false,
            isSaveButtonClicked: false,
            permissionGroups,
            availableRoles: Account.storedInstance.permissions,
            ICON_TYPES,
            BUTTON_TYPES,
            companyPermissions: [],
            viewPermissions: {},
            viewRolePermissions: [],
            role: {
                name: '',
                homepage: 'landing',
                // description: '',
                permissions: [],
            },
            tableColumnsData: [
                {
                    name: this.$i18n.t('rolesAndPermissions.roleEditor.permission'),
                    key: 'label',
                    width: 80,
                },
                // { name: this.$i18n.t('generic.description'), key: 'description', width: 40 },
                {
                    name: this.$i18n.t('generic.read'),
                    key: 'readSlot',
                    width: 10,
                },
                {
                    name: this.$i18n.t('generic.write'),
                    key: 'writeSlot',
                    width: 10,
                },
            ],
            permissionsTableColumnsData: [
                {
                    name: this.$i18n.t('rolesAndPermissions.roleEditor.permission'),
                    key: 'displayName',
                    width: 80,
                },
                // { name: this.$i18n.t('generic.description'), key: 'description', width: 40 },
                {
                    name: this.$i18n.t('generic.value'),
                    key: 'valueSlot',
                    width: 10,
                },
            ],
        };
    },
    validations() {
        return {
            role: {
                name: {
                    required,
                },
            },
        };
    },
    computed: {
        ...mapGetters('roles', [Getters.GET_ROLE_BY_ID]),
        rolesToShow() {
            const accountPermissions = Account.storedInstance.permissions;
            const arr = Object.values(this.permissionGroups).filter(
                perm =>
                    accountPermissions.includes(perm.read.key) ||
                    accountPermissions.includes(perm.write.key) ||
                    accountPermissions.includes('LF_Admin'),
            );

            return Array.from(new Set(arr));
        },
        isViewConfig() {
            return isViewConfig();
        },
    },
    // Reason of using destroyed hook its problem with reactivity
    // since permissionGroups are imported from helper function, they are caching checkbox values
    destroyed() {
        Object.values(this.permissionGroups).forEach(el => {
            el.read.state = false;
            el.write.state = false;
        });
    },
    async created() {
        this.isDataLoading = true;
        this.$Progress.start();
        let editingRole;

        try {
            if (this.$route.params.id) {
                await this[Actions.LOAD_ROLE]();
                editingRole = this[Getters.GET_ROLE_BY_ID](this.$route.params.id);
                this.initData(editingRole);
            }

            if (this.isViewConfig) {
                const [companyPermissionsResponse, viewsResponse] = await Promise.all([
                    getCompanyPermissions(),
                    getViews(),
                ]);
                this.companyPermissions = companyPermissionsResponse.data;
                if (!Account.storedInstance.internal) {
                    this.companyPermissions = this.companyPermissions.filter(item => !item.internal);
                }
                this.viewPermissions = mapViewsTemplate(
                    viewsResponse.data,
                    this.$store.getters[`${Modules.config}/getViews`][this.$route.params.companyId],
                    this.companyPermissions,
                    editingRole?.permissions || [],
                    true,
                );
            }

            this.$Progress.finish();
        } catch (error) {
            this.$eventBus.$emit('showAlert', {
                message: this.$i18n.t('alertMessage.failedToLoadNecessaryData'),
            });
            this.$Progress.fail();
        } finally {
            this.isDataLoading = false;
        }
    },
    methods: {
        ...mapActions('roles', [Actions.LOAD_ROLE]),
        async onSave() {
            if (this.isSaveButtonClicked) {
                return;
            }
            this.$v.$touch();
            this.role.permissions = [];

            if (!this.$v.$invalid) {
                if (this.isViewConfig) {
                    this.role.permissions = this.viewRolePermissions;
                } else {
                    const legacyPermissions = [];
                    Object.values(this.permissionGroups).forEach(el => {
                        if (el.write.state) {
                            this.role.permissions.push(el.write.key);
                            this.role.permissions.push(el.read.key);
                        } else if (el.read.state) {
                            this.role.permissions.push(el.read.key);
                        }
                        legacyPermissions.push(el.read.key, el.write.key);
                    });

                    // Persist view permissions so they aren't overwritten by legacy permissions
                    const viewPermissions = this.viewRolePermissions.filter(p => !legacyPermissions.includes(p));
                    this.role.permissions.push(...viewPermissions);
                }
                if (this.role.permissions.length < 1) {
                    this.$Progress.finish();
                    this.$eventBus.$emit('showAlert', {
                        message: this.$i18n.t('alerts.permissionFailed'),
                    });
                    return;
                }

                this.$Progress.start();
                if (this.$route.params.id) {
                    const roleUpdateResult = await updateRole({
                        id: Number(this.$route.params.id),
                        name: this.role.name,
                        permissions: this.role.permissions,
                    });
                    if (roleUpdateResult?.data && roleUpdateResult?.data?.length > 0) {
                        this.$eventBus.$emit('showAlert', {
                            message: this.$i18n.t('alerts.rolePartiallyUpdated'),
                        });
                    } else {
                        this.$eventBus.$emit('showAlert', {
                            message: this.$i18n.t('alerts.roleUpdated'),
                            type: ALERT_TYPES.success,
                        });
                    }
                } else {
                    const addRoleResult = await addRole(this.role);
                    if (addRoleResult?.data && addRoleResult?.data?.length > 0) {
                        this.$eventBus.$emit('showAlert', {
                            message: this.$i18n.t('alerts.rolePartiallyAdded'),
                        });
                    } else {
                        this.$eventBus.$emit('showAlert', {
                            message: this.$i18n.t('alerts.roleAdded'),
                            type: ALERT_TYPES.success,
                        });
                    }
                }

                this.isSaveButtonClicked = false;
                this.$Progress.finish();
                setTimeout(
                    () =>
                        this.$router.push({
                            name: RouteNames.ROLES,
                            params: { companyId: this.$route.params.companyId },
                        }),
                    2000,
                );
            } else {
                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('alertMessage.pleaseFixValidation'),
                });
                this.isSaveButtonClicked = false;
            }
        },
        initData(data) {
            this.$set(this.role, 'name', data.name);
            const permissionList = Object.values(data.permissions).reduce((acc, perm) => {
                acc[perm] = true;
                return acc;
            }, {});

            Object.values(this.permissionGroups).forEach(el => {
                if (permissionList[el.read.key]) {
                    this.$set(el.read, 'state', true);
                }
                if (permissionList[el.write.key]) {
                    this.$set(el.write, 'state', true);
                }
            });
            this.viewRolePermissions = [...data.permissions];
        },
        setWritePermission(permission) {
            if (permission.write.state) {
                permission.read.state = true;
            }
        },
        seReadPermission(permission) {
            if (permission.write.state && !permission.read.state) {
                permission.write.state = false;
            }
        },
        viewsTreeChange(event) {
            this.updateRolePermissions(event.permissionKey, event.value);
            const modifiedPermission = this.companyPermissions.find(item => item.key === event.permissionKey);

            if (modifiedPermission && modifiedPermission.forceEnable?.length > 0 && event.value) {
                for (const forcePermission of modifiedPermission.forceEnable) {
                    this.updateRolePermissions(forcePermission, event.value);
                }
            }

            if (modifiedPermission && modifiedPermission.forceDisable?.length > 0 && !event.value) {
                for (const forcePermission of modifiedPermission.forceDisable) {
                    this.updateRolePermissions(forcePermission, event.value);
                }
            }

            this.updateViewPermissionsState(event.view);
        },
        updateRolePermissions(permissionKey, enabled) {
            this.viewRolePermissions = enabled
                ? uniq([...this.viewRolePermissions, permissionKey])
                : without(this.viewRolePermissions, permissionKey);
        },
        updateViewPermissionsState(view) {
            view.permissions = (view.permissions || []).map(permission => ({
                ...permission,
                enabled: this.viewRolePermissions.indexOf(permission.key) > -1,
            }));

            Object.values(view?.views || {}).forEach(this.updateViewPermissionsState);
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/palette';
@import '~@/assets/scss/icons';

$icon-path: '~@/assets/icons/';
$image-path: '~@/assets/images/';

.header {
    margin: 1.5rem 0.875rem 0.875rem;
}

.list {
    max-height: calc(100vh - 16rem);
    overflow-y: auto;
    margin: 1.5rem 0.875rem 0.875rem;
}

.label {
    font-size: 0.875rem;
    color: $gray60;
    width: 200px;
}

.dropdown {
    width: 584px;
}

.button-wrapper {
    display: flex;
    justify-content: flex-end;
}
</style>
