<template>
    <div class="card-layout">
        <div class="d-flex justify-content-between">
            <div class="title lf-subtitle mb-3">
                <slot name="headerTitle" />
            </div>
            <div class="additional-info lf-labels">
                <slot name="headerAdditionalInfo" />
            </div>
        </div>
        <div class="content lf-title mb-4">
            <slot name="content" />
        </div>
        <div class="row justify-content-between align-items-center">
            <div
                v-if="$slots.footerLabel"
                class="footer-label lf-table-title"
            >
                <slot name="footerLabel" />
            </div>
            <slot name="footerButton" />
        </div>
    </div>
</template>

<script>
export default {
    name: 'InfoCard',
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/_palette.scss';
@import '~@/assets/scss/typographyV2';

.card-layout {
    padding: 1rem 1.5rem 1.5rem;
    border-radius: 0.5rem;
    width: 17.125rem;
    box-shadow: 0 0.25rem 0.5rem 0 rgba(51, 81, 149, 0.4);
}

.title {
    color: $white;
}

.content {
    color: $white;
}

.footer-label {
    color: $white;
}

.additional-info {
    color: $dirty-white;
    height: 2.125rem;
}
</style>
