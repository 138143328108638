<template>
    <RouteWrapper className="h-100" />
</template>

<script>
import RouteWrapper from '@/components/layout/RouteWrapper.vue';

export default {
    name: 'CustomerCare',
    components: {
        RouteWrapper,
    },
};
</script>

<style lang="scss" scoped></style>
