
// vuex stuff
import { mapGetters, mapActions } from 'vuex';
import Actions, { Getters } from '@/store/mutation-types';

// components
import FilterTableMixin from '@/components/partials/FilterTableMixin.vue';
import OrchestrationListPageLayout from '@/__new__/features/orchestrationengine/OrchestrationListPageLayout.vue';
import BatchResumeExecutionsModal from '@/__new__/features/orchestrationengine/BatchResumeExecutionsModal.vue';
import PausedExecutionsDataModal from '@/__new__/features/orchestrationengine/PausedExecutionsDataModal.vue';

// helpers
import ENTITY_TYPES from '@/common/entities/entityTypes';
import tableColumnType from '@/common/filterTable';
import localeLibrary from '@/common/locale/localeLibrary';
import { PausedExecutionBE } from '@/__new__/features/orchestrationengine/common/orchestrationPausedExecutionsHelper';
import { ICON_TYPES } from '@/common/iconHelper';
import permissionsService, { isUserAllowed } from '@/services/permissions/permissions.service';

// error handling
import * as Sentry from '@sentry/vue';
import { TranslateResult } from 'vue-i18n';

interface MultiselectRowAction {
    label: TranslateResult;
    buttonIcon: string;
    refName: string;
}

export default {
    name: 'OrchestrationEnginePausedExecutions',
    components: {
        OrchestrationListPageLayout,
        BatchResumeExecutionsModal,
        PausedExecutionsDataModal,
    },
    mixins: [FilterTableMixin],
    data() {
        return {
            pageTitle: this.$i18n.t('orchestrationEngine.executionsSearch.pausedExecutions') as string,
            entityType: ENTITY_TYPES.ORCHESTRATION_ENGINE_PAUSED_EXECUTIONS as any,
            pausedExecutions: [] as PausedExecutionBE[] | [],
            isDataLoading: false as boolean,
            maxSelectedPageNumber: 1 as number,
            multiselectRowActions: [] as MultiselectRowAction[],
            isBatchResumeModalVisible: false as boolean,
            selectedPausedExecutionsForResume: [] as any[],
            multiselectRowsEnabled: true as boolean,
            multiselectIdColumn: 'executionId' as string,
            isPausedExecutionsDataModalVisible: false,
            pausedExecutionsCodeData: [] as any[],
        };
    },
    computed: {
        ...mapGetters('orchestrationengine', {
            getPausedExecutionsResponse: Getters.GET_OE_PAUSED_EXECUTIONS,
            getPausedExecutionsApiResponse: Getters.GET_OE_PAUSED_EXECUTIONS_API_RESPONSE,
        }),
        ...mapGetters('operators', [Getters.languageDefault]),
        formattedEntities(): any[] {
            if (this.pausedExecutions.length) {
                return this.pausedExecutions.map(execution => ({
                    executionId: execution?.execution_id || '',
                    planId: execution?.plan_id || '',
                    lastState: execution?.last_state || '',
                    planOwner: execution?.plan_owner || '',
                    startedAt: execution?.started_at ? localeLibrary.getFormattedDateAndTime(execution.started_at) : '',
                    scheduledAt: execution?.scheduled_at
                        ? localeLibrary.getFormattedDateAndTime(execution.scheduled_at)
                        : '',
                    scheduledFor: execution?.scheduled_for
                        ? localeLibrary.getFormattedDateAndTime(execution.scheduled_for)
                        : '',
                    pausedTill: execution?.paused_till
                        ? localeLibrary.getFormattedDateAndTime(execution.paused_till)
                        : '',
                }));
            }
            return [];
        },
        tableColumnsData(): any[] {
            return [
                {
                    name: this.$i18n.t('generic.id'),
                    key: 'executionId',
                    field: 'executionId',
                    fieldNameOnBE: 'execution_id',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('customerCare.orderHistory.planId'),
                    key: 'planId',
                    field: 'planId',
                    fieldNameOnBE: 'plan_id',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('orchestrationEngine.planOwner'),
                    key: 'planOwner',
                    field: 'planOwner',
                    fieldNameOnBE: 'plan_owner',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('orchestrationEngine.lastState'),
                    key: 'lastState',
                    field: 'lastState',
                    fieldNameOnBE: 'last_state',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('orchestrationEngine.pausedTill'),
                    key: 'pausedTill',
                    field: 'pausedTill',
                    fieldNameOnBE: 'paused_till',
                    sortBy: entity => entity.pausedTill,
                    filterType: tableColumnType.DATETIME,
                },
                {
                    name: this.$i18n.t('orchestrationEngine.startTimestamp'),
                    key: 'startedAt',
                    field: 'startedAt',
                    fieldNameOnBE: 'started_at',
                    sortBy: entity => entity.startedAt,
                    filterType: tableColumnType.DATETIME,
                },
                {
                    name: this.$i18n.t('orchestrationEngine.scheduledAt'),
                    key: 'scheduledAt',
                    field: 'scheduledAt',
                    fieldNameOnBE: 'scheduled_at',
                    sortBy: entity => entity.scheduledAt,
                    filterType: tableColumnType.DATETIME,
                    tooltipText: this.$i18n.t('orchestrationEngine.schedulingTimestampTooltip'),
                },
                {
                    name: this.$i18n.t('orchestrationEngine.scheduledFor'),
                    key: 'scheduledFor',
                    field: 'scheduledFor',
                    fieldNameOnBE: 'scheduled_for',
                    sortBy: entity => entity.scheduledFor,
                    filterType: tableColumnType.DATETIME,
                    tooltipText: this.$i18n.t('orchestrationEngine.scheduledTimestampTooltip'),
                },
            ];
        },
        writeEnabled(): boolean {
            return (
                isUserAllowed('PausedExecutionsWrite') &&
                permissionsService.orchestrationEnginePausedExecutionsEnabled()
            );
        },
    },
    async created() {
        this.isDataLoading = true;
        this.multiselectRowActions = [
            {
                label: this.$i18n.t('orchestrationEngine.batchResume'),
                buttonIcon: ICON_TYPES.START,
                refName: 'batch-resume-button',
            },
        ];
        await this.fetchPausedExecutions();

        // eslint-disable-next-line camelcase
        this.pausedExecutions = this.getPausedExecutionsResponse?.paused_executions?.length
            ? this.getPausedExecutionsResponse.paused_executions
            : [];

        this.isDataLoading = false;
    },
    methods: {
        ...mapActions('orchestrationengine', {
            requestPausedExecutions: Actions.REQUEST_OE_PAUSED_EXECUTIONS,
        }),
        async fetchPausedExecutions(pagingState: string | null = null): Promise<any> {
            try {
                const data = {
                    page_size: 1000,
                    ...(pagingState && { paging_state: pagingState }),
                };

                this.$Progress.start();
                await this.requestPausedExecutions(data);
                this.$Progress.finish();
            } catch (error: any) {
                this.$Progress.fail();
                Sentry.captureException(error);
                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('alertMessage.failedToLoadNecessaryData'),
                });
            }
        },
        onPauseExpanded(): void {
            this.$router.go(0);
        },
        async onReachedLastPage({ isReached, pageNumber }: { isReached: boolean; pageNumber: number }): Promise<any> {
            if (isReached && pageNumber > this.maxSelectedPageNumber) {
                const { has_more_pages: hasMorePages = false, paging_state: pagingState = '' } =
                    this.getPausedExecutionsResponse;
                this.maxSelectedPageNumber = pageNumber;

                if (hasMorePages && pagingState) {
                    await this.fetchPausedExecutions(pagingState);

                    // eslint-disable-next-line camelcase
                    if (this.getPausedExecutionsResponse?.paused_executions?.length) {
                        const newArray = this.pausedExecutions.concat(
                            this.getPausedExecutionsResponse.paused_executions,
                        );
                        this.pausedExecutions = newArray;
                    }
                }
            }
        },
        onMultiselectActionClick(data: { action: any; selectedRows: any }): void {
            this.isBatchResumeModalVisible = true;

            // Get additional info of executions instead of just IDs
            const filteredEntities = this.formattedEntities.filter(entity =>
                data.selectedRows.includes(entity.executionId),
            );

            this.selectedPausedExecutionsForResume = filteredEntities;
        },
        onBatchResumed(): void {
            this.isPausedExecutionsDataModalVisible = false;
            this.isBatchResumeModalVisible = false;

            this.$Progress.start();
            this.fetchPausedExecutions();
            this.$Progress.finish();
        },
        onCloseBatchResumeExecutionsModal(): void {
            this.isBatchResumeModalVisible = false;
            this.selectedPausedExecutionsForResume = [];
        },
    },
};
