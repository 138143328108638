import UserManagerBaseModel from '@/__new__/services/dno/user/models/UserManagerBaseModel';
import i18n from '@/i18n';
import { TranslateResult } from 'vue-i18n';

export enum DEVICE_STATE {
    INACTIVE = 1,
    ACTIVE = 2,
}

type SessionResponse = {
    device_id: string;
    user_id: string;
    expiration_counter: number;
    state: DEVICE_STATE;
};

export default class Session extends UserManagerBaseModel {
    id: string;
    userId: string;
    lastValidVersion: number | string;
    state: DEVICE_STATE;
    stateString?: TranslateResult | string;

    constructor({ id, userId, lastValidVersion, state }: Session) {
        // Call constructor of UserManagerBaseModel class for creating new object with unique invalidKeys Map
        super();
        this.id = this.validateString('id', id) ? id : '';
        this.userId = this.validateString('userId', userId) ? userId : '';
        this.lastValidVersion = this.validateNumberInteger('lastValidVersion', lastValidVersion)
            ? lastValidVersion
            : '';
        this.state = state;
        const stateString = Session.mapState(state);
        this.stateString = this.validateString('stateString', stateString) ? stateString : '';
    }

    static mapState(state: DEVICE_STATE) {
        if (state === DEVICE_STATE.ACTIVE) {
            return i18n.t('generic.stateMap.active');
        }
        if (state === DEVICE_STATE.INACTIVE) {
            return i18n.t('generic.inactive');
        }
    }

    static remapSessionFromBe(response: SessionResponse) {
        return {
            id: response.device_id,
            userId: response.user_id,
            lastValidVersion: response.expiration_counter,
            state: response.state,
        };
    }
}
