import Vue from 'vue';
import { Module } from 'vuex';
import Actions, { Mutations, State } from '@/store/mutation-types';
import { getCohortExpressionCount } from '@/__new__/services/dno/segments/http/segments';

interface ModuleState {
    [State.STATIC_FILTERS_COUNT_STATS]: { [key: string]: number };
}

const store: Module<ModuleState, unknown> = {
    namespaced: true,

    state: {
        [State.STATIC_FILTERS_COUNT_STATS]: {}, // by id
    },

    mutations: {
        [Mutations.SET_STATIC_FILTERS_COUNT_STATS]: (state, { id, count }) => {
            Vue.set(state[State.STATIC_FILTERS_COUNT_STATS], id, count);
        },
    },

    actions: {
        async [Actions.FETCH_STATIC_FILTER_COUNT_STATS]({ commit, state }, data) {
            const statAlreadyExists = Object.prototype.hasOwnProperty.call(
                state[State.STATIC_FILTERS_COUNT_STATS],
                data.filterId,
            );
            if (statAlreadyExists) return;

            const cohortExpression = {
                whitelist: {
                    logical_operator: 'AND',
                    [data.filterType]: [data.filterId],
                },
                blacklist: {
                    logical_operator: 'AND',
                },
            };

            const response = await getCohortExpressionCount(cohortExpression);
            const newStat = {
                id: data.filterId,
                count: response.data.count,
            };
            commit(Mutations.SET_STATIC_FILTERS_COUNT_STATS, newStat);
        },
    },
};

export default store;
