import { AxiosPromise } from 'axios';
import http, { DnoResponseBase } from '@/http';
import { ONT_STATUS } from '@/common/ontStateHelper';
import { APPT_STATE, APPT_TYPE } from '@/__new__/features/customerCare/common/appointmentsHelper';

interface OsaServiceStatusResponse extends DnoResponseBase {
    operational_status: ONT_STATUS;
}

type Provider = {
    state: APPT_STATE;
    name: string;
    integration_params: {
        time_zone_key: string;
        appointments: {
            service_name: string;
            service_territory_name: string;
            supported_appointment_types: APPT_TYPE[];
        };
    };
    provider_id: string;
    operator_name: string;
};

interface GetProvidersResponse extends DnoResponseBase {
    providers: Provider[];
}

const osaGetServiceStatus = (id: string, targetType: number): AxiosPromise<OsaServiceStatusResponse> =>
    http({
        method: 'POST',
        url: '/v3provider/osagetservicestatus',
        data: {
            target_id: id,
            target_type: targetType,
        },
    });

const getProviders = (providerIds: string[] = []): AxiosPromise<GetProvidersResponse> =>
    http({
        method: 'POST',
        url: '/v3provider/getprovider',
        data: {
            provider_ids: providerIds,
        },
    });

export default {
    osaGetServiceStatus,
    getProviders,
};
