<template>
    <AppTabsV2
        :sectionsCount="1"
        :tabsCount="2"
        :disableSectionPaddings="true"
        :enableHeaderMargin="false"
        class="porting-table"
        :class="{ 'overflow-visible': hasOverflowVisible }"
        @tabSelected="changeSelectedIndex"
    >
        <!-- Port In -->
        <template #tabHeader1>
            {{ $i18n.t('customerCare.userInformation.portIn') }}
        </template>

        <template #tabHeaderButtons1>
            <ResponseModalButton
                :response="portInStatusApiResponse"
                :title="$i18n.t('customerCare.userInformation.portIn')"
            />
        </template>

        <template #tab1_sectionContent1>
            <div class="w-100">
                <TableFiltersRenderless
                    ref="portInTableFilterWrapper"
                    :entities="portInData"
                    :isSearchEnabled="true"
                    @allFiltersUpdate="allFilters = $event"
                >
                    <template
                        #default="{
                            // state
                            filteredEntities,
                        }"
                    >
                        <AppTable
                            :entities="filteredEntities"
                            :isDefaultHoverEnabled="false"
                            :isPaginationEnabled="true"
                            :newDesign="true"
                            :canSelectColumns="true"
                            :columnsData="portInColumns"
                            tableKey="subscriberPortingStatus/portIn"
                            data-test-id="portin-table"
                        >
                            <template #status>
                                <div class="porting-select-wrap">
                                    <AppMultiselectV3
                                        v-model="portInStatus"
                                        :options="portingSelectOptions"
                                        :small="true"
                                        :disabled="!hasPortInData || portInEditDisabled"
                                        label="label"
                                        class="portin-select"
                                        @input="onPortInStatusUpdate"
                                        @open="onPortingStatusFocus"
                                    />
                                </div>
                            </template>

                            <template #scheduledDate>
                                <DateTimePicker
                                    v-model="porting.portIn.scheduledDate"
                                    type="date"
                                    :disabled="portInEditDisabled"
                                    :disabledDates="portInDisabledScheduledDates"
                                    class="portin-datepicker"
                                />
                            </template>

                            <template #latestStatus>
                                <AppLabel
                                    v-if="hasPortInData"
                                    :title="portInLatestStatus.title"
                                    :color="portInLatestStatus.color"
                                    class="justify-content-center"
                                />
                            </template>
                        </AppTable>
                    </template>
                </TableFiltersRenderless>
            </div>
        </template>

        <!-- Port Out -->
        <template #tabHeader2>
            {{ $i18n.t('customerCare.userInformation.portOut') }}
        </template>
        <template #tabHeaderButtons2>
            <ResponseModalButton
                :response="portOutStatusApiResponse"
                :title="$i18n.t('customerCare.userInformation.portOut')"
            />
            <AppButton
                :iconType="ICON_TYPES.LOGOUT"
                :disabled="loading || hasPortOutData"
                :label="$i18n.t('customerCare.userInformation.portOutInitiate')"
                :isSmall="true"
                data-test-id="portout-init"
                @click="toggleModal"
            />
        </template>
        <template #tab2_sectionContent1>
            <div class="w-100">
                <AppDialog
                    :visible="isPortOutModalVisible"
                    maxWidth="47.5rem"
                    maxHeight="100%"
                >
                    <div class="p-3">
                        <h2 class="lf-title">
                            {{ $i18n.t('customerCare.userInformation.portOutDialog.title') }}
                        </h2>
                        <p>{{ $i18n.t('customerCare.userInformation.portOutDialog.text') }}</p>
                        <div class="modal-footer d-flex justify-content-end pt-2">
                            <AppButton
                                :label="$i18n.t('generic.cancel')"
                                class="mr-3"
                                @click="toggleModal"
                            />
                            <AppButton
                                :label="$i18n.t('generic.confirm')"
                                :buttonType="BUTTON_TYPES.PRIMARY"
                                data-test-id="portout-confirm"
                                @click="initiatePortOut"
                            />
                        </div>
                    </div>
                </AppDialog>
                <TableFiltersRenderless
                    ref="portOutTableFilterWrapper"
                    :entities="portOutData"
                    @allFiltersUpdate="allFilters = $event"
                >
                    <template
                        #default="{
                            // state
                            filteredEntities,
                        }"
                    >
                        <div class="position-relative">
                            <AppTable
                                :entities="filteredEntities"
                                :isDefaultHoverEnabled="false"
                                :isPaginationEnabled="true"
                                :newDesign="true"
                                :canSelectColumns="true"
                                :columnsData="portOutColumns"
                                tableKey="subscriberPortingStatus/portOut"
                                data-test-id="portout-table"
                            >
                                <template #status>
                                    <div class="porting-select-wrap">
                                        <AppMultiselectV3
                                            v-model="portOutStatus"
                                            :options="portingSelectOptions"
                                            :small="true"
                                            :disabled="!hasPortOutData || portOutEditDisabled"
                                            label="label"
                                            class="portin-select"
                                            @input="onPortOutStatusUpdate"
                                            @open="onPortingStatusFocus"
                                        />
                                    </div>
                                </template>

                                <template
                                    v-if="!portOutEditDisabled"
                                    #completedDate
                                >
                                    {{ portOutCompletedDate }}
                                </template>

                                <template #latestStatus>
                                    <AppLabel
                                        v-if="hasPortOutData"
                                        :title="portOutLatestStatus.title"
                                        :color="portOutLatestStatus.color"
                                        class="justify-content-center"
                                    />
                                </template>
                            </AppTable>
                        </div>
                    </template>
                </TableFiltersRenderless>
            </div>
        </template>
    </AppTabsV2>
</template>

<script>
// COMPONENTS
import AppTabsV2 from '@/components/partials/AppTabsV2.vue';
import TableFiltersRenderless from '@/components/filters/TableFiltersRenderless.vue';
import AppTable from '@/components/partials/AppTable.vue';
import AppLabel from '@/components/partials/AppLabel.vue';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import AppMultiselectV3 from '@/components/partials/inputs/AppMultiselectV3.vue';
import AppDialog from '@/components/partials/AppDialog.vue';
import DateTimePicker from '@/components/partials/inputs/DateTimePicker.vue';
import supportButtonMixin from '@/components/alerts/supportButtonMixin';
import ResponseModalButton from '@/components/partials/ResponseModalButton.vue';

// HELPERS
import moment from 'moment';
import Subscriber from '@/__new__/services/dno/user/models/Subscriber';
import Porting from '@/__new__/services/dno/user/models/Porting';

import {
    SUBSCRIBER_PORT_IN_LABEL_MAP,
    SUBSCRIBER_PORT_IN_COLOR_MAP,
    SUBSCRIBER_PORTING_ACTIONS,
    SUBSCRIBER_PORT_IN_MAP,
    SUBSCRIBER_PORT_OUT_MAP,
    SUBSCRIBER_PORT_OUT_LABEL_MAP,
    SUBSCRIBER_PORT_OUT_COLOR_MAP,
} from '@/__new__/features/customerCare/common/customerCareHelper';
import permissionsService, { isUserAllowed } from '@/services/permissions/permissions.service';
import { ALERT_TYPES } from '@/common/alerts/Alert';
import { ICON_TYPES } from '@/common/iconHelper';
import * as Sentry from '@sentry/vue';
import luaErrors from '@/common/luaErrors';

// HTTP
import userManagementHTTP from '@/__new__/services/dno/user/http/user-management';
import { stringTimeToDefaultFormat } from '@/common/formatting';

export const portingTabs = {
    portIn: {
        id: 1,
        key: 'portInTableFilterWrapper',
    },
    portOut: {
        id: 2,
        key: 'portOutTableFilterWrapper',
    },
};

export const PORTING_STATES = {
    ACCEPTED: 'accepted',
    REJECTED: 'rejected',
};

export default {
    name: 'SubscriberPortingTable',
    components: {
        AppTabsV2,
        TableFiltersRenderless,
        AppTable,
        AppLabel,
        AppMultiselectV3,
        AppButton,
        AppDialog,
        DateTimePicker,
        ResponseModalButton,
    },
    mixins: [supportButtonMixin],
    props: {
        subscriber: {
            required: true,
            type: Subscriber,
        },
        porting: {
            required: true,
            type: Object,
        },
        loading: {
            required: true,
            type: Boolean,
        },
        portInStatusApiResponse: {
            type: Object,
            default: null,
        },
        portOutStatusApiResponse: {
            type: Object,
            default: null,
        },
    },
    data() {
        return {
            ICON_TYPES,
            BUTTON_TYPES,
            allFilters: [],
            selectedTabIndex: 1,
            portInStatus: { label: this.$i18n.t('components.appmultiselect.placeholders.chooseOption') },
            portOutStatus: { label: this.$i18n.t('components.appmultiselect.placeholders.chooseOption') },
            hasOverflowVisible: false,
            isPortInEnabled:
                permissionsService.userManagementEditEnabled() && isUserAllowed('UMSubscriberPortingTablePortIn'),
            isPortOutEnabled:
                permissionsService.userManagementEditEnabled() && isUserAllowed('UMSubscriberPortingTablePortOut'),
            isPortOutModalVisible: false,
        };
    },
    computed: {
        getSelectedTabKey() {
            return this.selectedTabIndex === portingTabs.portOut.id ? portingTabs.portOut.key : portingTabs.portIn.key;
        },
        portingSelectOptions() {
            return Object.keys(PORTING_STATES).map(status => ({
                id: PORTING_STATES[status],
                label: this.$i18n.t(`generic.stateMap.${PORTING_STATES[status]}`),
            }));
        },
        // PORT IN
        portInColumns() {
            return [
                {
                    key: 'msisdn',
                    name: this.$i18n.t('customerCare.msisdn'),
                },
                {
                    key: 'msisdnTemp',
                    name: this.$i18n.t('customerCare.userInformation.portingColumns.msisdnTemp'),
                },
                {
                    key: 'requestedDate',
                    name: this.$i18n.t('customerCare.userInformation.portingColumns.dateRequest'),
                },
                {
                    key: 'status',
                    name: this.$i18n.t('customerCare.userInformation.portingColumns.statusSelection'),
                },
                {
                    key: 'scheduledDate',
                    name: this.$i18n.t('customerCare.userInformation.portingColumns.dateScheduled'),
                },
                {
                    key: 'completedDate',
                    name: this.$i18n.t('customerCare.userInformation.portingColumns.dateCompletion'),
                },
                {
                    key: 'latestStatus',
                    name: this.$i18n.t('customerCare.userInformation.portingColumns.latestStatus'),
                },
            ];
        },
        hasPortInData() {
            return Boolean(this.porting.portIn.msisdn);
        },
        portInData() {
            if (!this.hasPortInData) return [];
            return [
                {
                    msisdn: this.porting.portIn.msisdn || '',
                    msisdnTemp: this.porting.portIn.tempMsisdn || '',
                    requestedDate: this.porting.portIn.requestDate
                        ? stringTimeToDefaultFormat(this.porting.portIn.requestDate)
                        : '',
                    completedDate: this.porting.portIn.completeDate
                        ? stringTimeToDefaultFormat(this.porting.portIn.completeDate)
                        : '',
                },
            ];
        },
        portInLatestStatus() {
            return {
                title: this.$i18n.t(`generic.stateMap.${SUBSCRIBER_PORT_IN_LABEL_MAP[this.porting.portIn.status]}`),
                color: SUBSCRIBER_PORT_IN_COLOR_MAP.get(this.porting.portIn.status),
            };
        },
        portInMaxScheduledDate() {
            return moment(Porting.maxAllowedDate(this.porting.portIn.requestDate)).toDate();
        },
        portInEditDisabled() {
            return (
                !this.isPortOutEnabled ||
                !this.porting.portIn.status ||
                this.porting.portIn.status === SUBSCRIBER_PORT_IN_MAP.PROVISIONED
            );
        },
        // PORT OUT
        portOutColumns() {
            return [
                {
                    key: 'msisdn',
                    name: this.$i18n.t('customerCare.msisdn'),
                },
                {
                    key: 'msisdnTemp',
                    name: this.$i18n.t('customerCare.userInformation.portingColumns.msisdnTemp'),
                },
                {
                    key: 'requestedDate',
                    name: this.$i18n.t('customerCare.userInformation.portingColumns.dateRequest'),
                },
                {
                    key: 'status',
                    name: this.$i18n.t('customerCare.userInformation.portingColumns.statusSelection'),
                },
                {
                    key: 'completeDate',
                    name: this.$i18n.t('customerCare.userInformation.portingColumns.dateCompletion'),
                },
                {
                    key: 'latestStatus',
                    name: this.$i18n.t('customerCare.userInformation.portingColumns.latestStatus'),
                },
            ];
        },
        hasPortOutData() {
            return Boolean(this.porting.portOut.msisdn);
        },
        portOutEditDisabled() {
            return (
                !this.isPortInEnabled ||
                !this.porting.portOut.status ||
                this.porting.portOut.status === SUBSCRIBER_PORT_OUT_MAP.PORT_OUT_SUCCESS
            );
        },
        portOutData() {
            if (!this.hasPortOutData) return [];
            return [
                {
                    msisdn: this.porting.portOut.msisdn || '',
                    msisdnTemp: this.hasPortOutData ? this.subscriber.msisdn : '',
                    requestedDate: this.porting.portOut.requestDate
                        ? stringTimeToDefaultFormat(this.porting.portOut.requestDate)
                        : '',
                    completeDate: this.porting.portOut.completeDate
                        ? stringTimeToDefaultFormat(this.porting.portOut.completeDate)
                        : '',
                },
            ];
        },
        portOutCompletedDate() {
            return this.porting.portOut.completeDate
                ? stringTimeToDefaultFormat(this.porting.portOut.completeDate)
                : '';
        },
        portOutLatestStatus() {
            return {
                title: this.$i18n.t(`generic.stateMap.${SUBSCRIBER_PORT_OUT_LABEL_MAP[this.porting.portOut.status]}`),
                color: SUBSCRIBER_PORT_OUT_COLOR_MAP.get(this.porting.portOut.status),
            };
        },
    },
    methods: {
        changeSelectedIndex(index) {
            this.removeAllFilters();
            this.selectedTabIndex = index;
        },
        removeAllFilters() {
            this.$refs[this.getSelectedTabKey].removeAllFilters();
        },
        toggleModal() {
            this.isPortOutModalVisible = !this.isPortOutModalVisible;
        },
        portInDisabledScheduledDates(date) {
            return date < moment().toDate() || date > this.portInMaxScheduledDate;
        },
        onPortingStatusFocus() {
            this.hasOverflowVisible = true;
        },
        onPortInStatusUpdate(status) {
            let state;
            let portInDate;

            this.hasOverflowVisible = false;

            if (status?.id === PORTING_STATES.ACCEPTED) {
                state = SUBSCRIBER_PORT_IN_MAP.MANUALLY_APPROVED;
                portInDate = moment(this.porting.portIn.scheduledDate || this.portInMaxScheduledDate)
                    .hour(12)
                    .unix();
            } else {
                state = SUBSCRIBER_PORT_IN_MAP.MANUALLY_REJECTED;
            }

            this.updatePortingState(this.porting.portIn.msisdn, SUBSCRIBER_PORTING_ACTIONS.PORT_IN, state, portInDate);
        },
        onPortOutStatusUpdate(status) {
            const state =
                status?.id === PORTING_STATES.ACCEPTED
                    ? SUBSCRIBER_PORT_OUT_MAP.MANUALLY_APPROVED
                    : SUBSCRIBER_PORT_OUT_MAP.MANUALLY_REJECTED;

            this.hasOverflowVisible = false;

            this.updatePortingState(this.porting.portOut.msisdn, SUBSCRIBER_PORTING_ACTIONS.PORT_OUT, state);
        },
        initiatePortOut() {
            this.updatePortingState(
                this.subscriber.msisdn,
                SUBSCRIBER_PORTING_ACTIONS.PORT_OUT,
                SUBSCRIBER_PORT_OUT_MAP.MANUALLY_APPROVED,
            ).finally(() => this.toggleModal());
        },
        async updatePortingState(msisdn, action, state, portInDate) {
            try {
                this.$Progress.start();
                await userManagementHTTP.updatePortingState({
                    id: this.subscriber.id,
                    msisdn,
                    action,
                    state,
                    portInDate,
                });

                // Progress is finished in 'portingStatusUpdate' handler.
                this.$emit('portingStatusUpdate', action);
            } catch (e) {
                this.$Progress.fail();

                if (e.response.data.code === luaErrors.PORTING.UPDATE_NOT_ALLOWED.code) {
                    this.$eventBus.$emit('showAlert', {
                        message: this.$i18n.t('customerCare.userInformation.portingNotAllowed'),
                        type: ALERT_TYPES.warning,
                    });
                } else {
                    Sentry.captureException(e);
                    this.showSupportAlert(this.$i18n.t('alertMessage.somethingWentWrong'), ALERT_TYPES.error);
                }
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/layout';

.porting-table.overflow-visible::v-deep :is(.table-scroll-wrapper, tbody, td) {
    overflow: visible !important;
}

.porting-select-wrap {
    line-height: 1;
}

.portin-datepicker::v-deep .mx-datepicker {
    width: 100%;
}

.portout-contol-bar {
    right: 0;
    transform: translateY(calc(-100% - #{$spacing-xxs}));
}
</style>
