
import { defineComponent } from 'vue';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import AppInputV3 from '@/components/partials/inputs/AppInputV3.vue';
import { ICON_TYPES } from '@/common/iconHelper';
import { ALERT_TYPES } from '@/common/alerts/Alert';
import Button from '@/common/button/Button';

export default defineComponent({
    name: 'AppApprovalBlock',
    components: {
        AppButton,
        AppInputV3,
    },
    props: {
        disabled: {
            type: Boolean,
            default: false,
        },
        description: {
            type: String,
            default: '',
        },
        reasonVisible: {
            type: Boolean,
            default: true,
        },
        approvalReasonRequired: {
            type: Boolean,
            default: false,
        },
        rejectionReasonRequired: {
            type: Boolean,
            default: true,
        },
        confirmApproval: {
            type: Boolean,
            default: false,
        },
        confirmRejection: {
            type: Boolean,
            default: true,
        },
        confirmRejectionMessage: {
            type: String,
            default: '',
        },
        confirmApprovalMessage: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            BUTTON_TYPES,
            ICON_TYPES,
            reason: '',
            approveClicked: false,
            rejectClicked: false,
            defaultConfirmRejectionMessage: this.$i18n.t('generic.areYouSureYouWantToProceedWithTheRejection'),
            defaultConfirmApprovalMessage: this.$i18n.t('generic.areYouSureYouWantToProceedWithTheApproval'),
            confirmRejectionButton: new Button({
                label: this.$i18n.t('generic.yes'),
                handler: () => {
                    this.$emit('reject', this.reason);
                },
            }),
            confirmApprovalButton: new Button({
                label: this.$i18n.t('generic.yes'),
                handler: () => {
                    this.$emit('approve', this.reason);
                },
            }),
        };
    },
    computed: {
        isReasonInvalid() {
            return (
                (this.approveClicked && this.approvalReasonRequired && !this.reason) ||
                (this.rejectClicked && this.rejectionReasonRequired && !this.reason)
            );
        },
    },
    methods: {
        onApprove() {
            this.rejectClicked = false;
            this.approveClicked = true;
            if (this.approvalReasonRequired && !this.reason) {
                this.$alert(this.$i18n.t('generic.pleaseEnterReasonForApproval'), {
                    type: ALERT_TYPES.error,
                });
                return;
            }
            if (this.confirmApproval) {
                this.$alert(this.confirmApprovalMessage || this.defaultConfirmApprovalMessage, {
                    type: ALERT_TYPES.warning,
                    buttons: [this.confirmApprovalButton],
                });
                return;
            }
            this.$emit('approve', this.reason);
        },
        onReject() {
            this.approveClicked = false;
            this.rejectClicked = true;
            if (this.rejectionReasonRequired && !this.reason) {
                this.$alert(this.$i18n.t('generic.pleaseEnterReasonForRejection'), {
                    type: ALERT_TYPES.error,
                });
                return;
            }
            if (this.confirmRejection) {
                this.$alert(this.confirmRejectionMessage || this.defaultConfirmRejectionMessage, {
                    type: ALERT_TYPES.warning,
                    buttons: [this.confirmRejectionButton],
                });
                return;
            }
            this.$emit('reject', this.reason);
        },
    },
});
