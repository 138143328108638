<template>
    <div class="position-relative">
        <div
            v-if="showArrows"
            class="arrow-wrapper d-flex justify-content-center mr-2 left-arrow"
            :class="{ 'left-arrow-enabled': leftArrowEnabled }"
        >
            <IconButton
                v-if="leftArrowEnabled"
                :icon="ICON_TYPES.ARRROW_LEFT"
                @iconClick="onLeftArrowClick()"
            />
        </div>
        <div
            v-if="showArrows"
            class="arrow-wrapper d-flex justify-content-center right-arrow"
            :class="{ 'right-arrow-enabled': rightArrowEnabled }"
        >
            <IconButton
                v-if="rightArrowEnabled"
                :icon="ICON_TYPES.ARROW_RIGHT"
                @iconClick="onRightArrowClick()"
            />
        </div>
        <div
            ref="draggable"
            v-dragscroll.x
            class="overflow-hidden position-relative"
            :class="{ draggable: showArrows }"
        >
            <div
                id="fade"
                :style="`left: ${fadePosition}px;`"
                :class="linearGradient"
            />
            <div
                ref="content"
                class="d-flex position-relative content"
            >
                <div
                    v-for="(state, index) in valueFormatted"
                    :key="`${state.state}${index}`"
                    :class="{ 'left-margin-date-box': index }"
                    class="width-fit-content"
                >
                    <div ref="timelineElement">
                        <EntityStatusIndicator
                            v-if="!isUndefined(state.state)"
                            ref="status"
                            :stateMap="ORDER_ENTITY_STATES_TO_STATUS_NAME_MAP"
                            :stateMapColor="ORDER_STATUS_INDICATOR_MAP"
                            :status="state.state"
                            class="mb-3 fit-content"
                        />
                        <div class="small-gray-date-label">
                            <div>
                                {{ state.date }}
                            </div>
                            <div>
                                {{ state.time }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                class="underline"
                :style="`width: ${timelineWidth + value.length * 2}px`"
                :class="{ fixed: !value.length }"
            >
                <div
                    v-for="(offset, index) in arrowOffsets"
                    :key="`${offset}-${index}`"
                    :style="`left: ${offset}px`"
                    class="pointer"
                />
            </div>
        </div>
    </div>
</template>

<script>
import EntityStatusIndicator from '@/components/partials/EntityStatusIndicator.vue';
import StateHistoryMixin from '@/__new__/features/customerCare/mixins/StateHistoryMixin.vue';
import IconButton from '@/components/partials/IconButton.vue';
import { ICON_TYPES } from '@/common/iconHelper';
import {
    ORDER_ENTITY_STATES,
    ORDER_ENTITY_STATES_TO_STATUS_NAME_MAP,
    ORDER_STATUS_INDICATOR_MAP,
} from '@/__new__/features/customerCare/common/orderStateHelper';

export default {
    name: 'OrderStateHistory',
    components: {
        EntityStatusIndicator,
        IconButton,
    },
    mixins: [StateHistoryMixin],
    data() {
        return {
            ICON_TYPES,
            ORDER_ENTITY_STATES,
            ORDER_ENTITY_STATES_TO_STATUS_NAME_MAP,
            ORDER_STATUS_INDICATOR_MAP,
        };
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/palette';
@import '~@/assets/scss/animations';
@import '~@/assets/scss/typography';
@import '~@/assets/scss/z-indexes';

.underline {
    border-bottom: solid 1px rgba(51, 81, 149, 0.16);
    position: relative;
    top: -2.8rem;

    &.fixed {
        top: 0;
    }
}

.left-margin-date-box {
    margin-left: 5rem;
}

.width-fit-content {
    min-width: fit-content;
}

.draggable {
    position: relative;
    cursor: -webkit-grab;
    cursor: -moz-grab;
    cursor: grab;

    scroll-behavior: smooth;
    &:active {
        cursor: -webkit-grabbing;
        cursor: -moz-grabbing;
        cursor: grabbing;

        scroll-behavior: auto;
    }
}

#fade {
    position: absolute;
    z-index: $overlap-smth-z-index;
    height: 100%;
    width: 100%;
    /* so the text is still selectable */
    pointer-events: none;
}

.pointer {
    background: $dirty-white;
    position: absolute;
    border-top: solid 1px rgba(51, 81, 149, 0.15);
    border-left: solid 1px rgba(51, 81, 149, 0.15);
    height: 10px;
    width: 10px;
    transform: translate(-50%, -50%) rotate(45deg);
}

.arrow-wrapper {
    height: 2rem;
    width: 2rem;
    display: flex;
    align-items: center;

    img {
        height: 1rem;
    }

    &.right-arrow {
        position: absolute;
        top: 30%;
        right: 0;
        z-index: $two-z-index;
    }

    &.left-arrow {
        position: absolute;
        top: 30%;
        left: 0;
        z-index: $two-z-index;
    }
}

.fit-content {
    width: fit-content;
}

.rotate90 {
    transform: rotate(90deg);
    height: 1rem;
}
.leftArrowEnabledGradient {
    background: linear-gradient(to right, rgba(246, 246, 246) 0.5%, rgba(246, 246, 246, 0) 5%);
}

.rightArrowEnabledGradient {
    background: linear-gradient(to left, rgba(246, 246, 246) 0.5%, rgba(246, 246, 246, 0) 5%);
}

.bothArrowsEnabledGradient {
    background: linear-gradient(to right, rgba(246, 246, 246) 0.5%, rgba(246, 246, 246, 0) 5%),
        linear-gradient(to left, rgba(246, 246, 246) 0.5%, rgba(246, 246, 246, 0) 5%);
}
</style>
