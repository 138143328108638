import i18n from '@/i18n';
import { required, numeric, minLength, maxLength } from 'vuelidate/lib/validators';

export enum ESIM_SEARCH_OPTIONS_KEYS {
    ICCID = 'iccid',
}

export const ESIM_SEARCH_OPTIONS = {
    [ESIM_SEARCH_OPTIONS_KEYS.ICCID]: {
        key: ESIM_SEARCH_OPTIONS_KEYS.ICCID,
        label: i18n.t('customerCare.iccid'),
        validationObject: {
            required,
            numeric,
            minLength: minLength(18),
            maxLength: maxLength(20),
        },
        validationErrorMessage: i18n.t('customerCare.eSIM.iccidValidationErrorMessage'),
        placeholder: i18n.t('customerCare.eSIM.iccidNumber'),
    },
};

export const ESIM_SEARCH_OPTIONS_LIST = Object.values(ESIM_SEARCH_OPTIONS);

export default {
    ESIM_SEARCH_OPTIONS_KEYS,
    ESIM_SEARCH_OPTIONS,
    ESIM_SEARCH_OPTIONS_LIST,
};
