
import Vue from 'vue';

// components
import AppMultiselectV3 from '@/components/partials/inputs/AppMultiselectV3.vue';
import BulkUploadCounts, { type BulkUploadCounter } from '@/__new__/features/resources/BulkUploadCounts.vue';
import BulkUploadPageLayout from '@/__new__/features/resources/BulkUploadPageLayout.vue';
import SimProfileUtilities from '@/__new__/features/resources/sim/SimProfileUtilities.vue';

// http
import { getCounts } from '@/__new__/services/dno/sim/http/inventory';
import { getUploadHistory } from '@/__new__/services/dno/sim/http/uploadHistory';
import { getRemoteConfig } from '@/__new__/services/dno/remoteConfig/http/remoteConfig';

// models
import Counts from '@/__new__/services/dno/sim/models/counts';

// helpers
import tableColumnType, { type TableColumn } from '@/common/filterTable';
import {
    formatEventCategory,
    getAllFileUploadStatusStrings,
    type UploadedFileDetails,
} from '@/common/fileUploadHelper';
import type { CollapsibleListItem } from '@/common/AppCollapsibleListHelper';
import { DATAFLOW_API_TYPE } from '@/__new__/services/dno/ossmvne/ossmvneHelper';
import { SIM_TYPE, REMOTE_CONFIG_KEYS } from '@/__new__/features/resources/common/sim';
import { SIM_TYPES_MAP } from '@/__new__/features/customerCare/subscriber/common/simTypes';
import groupBy from 'lodash/groupBy';
import { ICON_TYPES } from '@/common/iconHelper';
import { isUserAllowed, isViewEnabled } from '@/services/permissions/permissions.service';
import { SEGMENT_ID_TYPES, StaticFilterLabels } from '@/common/StaticFilter';

type SimEntities = Record<SIM_TYPES_MAP, UploadedFileDetails[]>;

type SimResourceUpload = {
    brand?: string;
    channel?: string;
    resourceType?: {
        id: SIM_TYPES_MAP;
        name: string;
    };
};

export default Vue.extend({
    name: 'SimProfileManagement',

    components: {
        AppMultiselectV3,
        BulkUploadCounts,
        BulkUploadPageLayout,
        SimProfileUtilities,
    },

    data() {
        return {
            SIM_TYPES_MAP,
            dataflow: DATAFLOW_API_TYPE.SIM_BULK,
            tab: SIM_TYPES_MAP.PSIM as SIM_TYPES_MAP,
            // search
            utilityTypes: [
                {
                    id: SEGMENT_ID_TYPES.ICCID,
                    label: StaticFilterLabels[SEGMENT_ID_TYPES.ICCID],
                    i18n: 'customerCare.iccid',
                },
            ],
            // upload form
            brands: [] as string[],
            channels: { [SIM_TYPES_MAP.PSIM]: [], [SIM_TYPES_MAP.ESIM]: [] } as Record<SIM_TYPES_MAP, string[]>,
            counters: {
                [SIM_TYPES_MAP.PSIM]: [],
                [SIM_TYPES_MAP.ESIM]: [],
            } as Record<SIM_TYPES_MAP, BulkUploadCounter[]>,
            upload: {
                resourceType: undefined,
                brand: undefined,
                channel: undefined,
            } as SimResourceUpload,
            uploadColumns: [
                {
                    index: 1,
                    name: this.$t('generic.msisdn'),
                },
            ],
            // table data
            entities: {
                [SIM_TYPES_MAP.PSIM]: [],
                [SIM_TYPES_MAP.ESIM]: [],
            } as SimEntities,
            uploadHistoryColumnsData: [
                {
                    name: this.$t('generic.uploadTime'),
                    key: 'createdAtStr',
                    mapper: entity => this.$localeLibrary.getFormattedDateAndTime(entity.createdAt),
                    sortBy: (entity: UploadedFileDetails) => entity.createdAt,
                    field: 'createdAt',
                    filterType: tableColumnType.DATE,
                },
                {
                    name: this.$t('generic.filename'),
                    key: 'fileName',
                    field: 'fileName',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$t('qodNumberManagement.recordCount'),
                    key: 'recordCount',
                    field: 'recordCount',
                    filterType: tableColumnType.NUMBER,
                },
                {
                    name: this.$t('generic.status'),
                    key: 'fileUploadStatusStr',
                    field: 'fileUploadStatusStr',
                    filterType: tableColumnType.TEXT_LIMITED_OPTIONS,
                    limitedOptions: getAllFileUploadStatusStrings(),
                },
            ] as TableColumn[],
            uploadHistoryDefaultSort: {
                key: 'createdAtStr',
                sortBy: (entity: UploadedFileDetails) => entity.createdAt,
                type: 'desc',
            },
            isLoadingConfig: false as boolean,
            isHistoryDataLoading: false as boolean,
            isMvne: isViewEnabled('EsimBrandsUpload'),
            isUploadEnabled: isUserAllowed('UploadSIMProfiles'),
        };
    },

    computed: {
        apiTypeOptions(): Array<{
            id: SIM_TYPES_MAP;
            label: string;
        }> {
            const options = [
                {
                    id: SIM_TYPES_MAP.PSIM,
                    label: 'p-SIM',
                },
                {
                    id: SIM_TYPES_MAP.ESIM,
                    label: 'e-SIM',
                },
            ];

            return options;
        },
        tabs(): any[] {
            return [...this.apiTypeOptions, { id: 'search', label: this.$t('generic.search') }];
        },
        title(): string {
            return `${this.$tc('operator.simProfile')} ${this.$t('operator.management')}`;
        },
        counts(): BulkUploadCounter[] {
            if ([SIM_TYPES_MAP.PSIM, SIM_TYPES_MAP.ESIM].includes(this.tab)) {
                return this.counters[this.tab] || [];
            }

            return [];
        },
        // Upload
        simType(): SIM_TYPES_MAP {
            return this.upload.resourceType?.id || this.tab;
        },
        isEsim(): boolean {
            return this.simType === SIM_TYPES_MAP.ESIM;
        },
        channelOptions(): string[] {
            return this.channels[this.simType] || [];
        },
        resourceTypes(): Array<SimResourceUpload['resourceType']> {
            return [
                { name: 'e-SIM', id: SIM_TYPES_MAP.ESIM },
                { name: 'p-SIM', id: SIM_TYPES_MAP.PSIM },
            ];
        },
        uploadMetadata(): Record<string, string | number> {
            return {
                resourceType: String(this.upload.resourceType?.id),
                esimBrandName: (this.isEsim && this.upload.brand) || 'NULL',
                channelTag: this.upload.channel || 'NULL',
            };
        },
    },

    async created() {
        this.counters[SIM_TYPES_MAP.PSIM] = this.mapCounts(SIM_TYPE.PSIM, {});
        this.counters[SIM_TYPES_MAP.ESIM] = this.mapCounts(SIM_TYPE.ESIM, {});

        const apis = [this.loadHistory()];

        if (isUserAllowed('ViewSIMProfileCounts')) {
            apis.push(this.loadCounts());
        }
        if (isViewEnabled('EsimBrandsUpload')) {
            apis.push(this.loadBrands());
        }

        await Promise.all(apis);
    },

    methods: {
        mapCounts(simType: SIM_TYPE, counters: Record<string, number>): BulkUploadCounter[] {
            const counts = new Counts(counters);
            const columns: Array<keyof Counts> = ['total', 'available', 'reserved', 'used'];

            return columns.map(column => ({
                label: `${this.$t(`operator.${column}`)} ${this.$tc(`operator.${simType}`, counts[column])}`,
                value: counts[column],
                icon: {
                    type: simType === SIM_TYPE.PSIM ? ICON_TYPES.PSIM : ICON_TYPES.ESIM,
                },
            }));
        },
        mapSidebarData(entity: any): CollapsibleListItem[] {
            return [
                {
                    name: this.$t('generic.general'),
                    isCollapsed: false,
                    rows: [
                        {
                            name: this.$t('generic.filename'),
                            value: entity?.fileName ?? '',
                        },
                        {
                            name: this.$t('qodNumberManagement.bulkUploadId'),
                            value: entity?.bulkUploadId ?? this.$t('generic.unknown'),
                        },
                        {
                            name: this.$t('qodNumberManagement.recordCount'),
                            value: entity?.recordCount ?? this.$t('generic.unknown'),
                        },
                        {
                            name: this.$t('generic.uploadedBy'),
                            value: entity?.createdBy ?? this.$t('generic.unknown'),
                        },
                        {
                            name: this.$t('generic.uploadTime'),
                            value: this.$localeLibrary.getFormattedDateAndTime(entity?.createdAt),
                        },
                    ],
                },
                {
                    name: this.$t('qodNumberManagement.provisionStatus'),
                    rows: Object.entries(entity?.eventCategoryCounters || {}).map(([status, count]) => ({
                        name: formatEventCategory(status),
                        value: `${count}/${entity?.recordCount}`,
                    })),
                    isCollapsed: false,
                },
            ];
        },
        loadBrands(): Promise<void> {
            return this.$withProgressBar(
                async () => {
                    this.isLoadingConfig = true;
                    const {
                        data: { data },
                    } = await getRemoteConfig();

                    if (data[REMOTE_CONFIG_KEYS.ESIM_BRANDS]) {
                        this.brands = JSON.parse(data[REMOTE_CONFIG_KEYS.ESIM_BRANDS]?.value);
                        [this.upload.brand] = this.brands;
                    }
                    if (data[REMOTE_CONFIG_KEYS.INVENTORY_TAGS]) {
                        const inventoryTags = JSON.parse(data[REMOTE_CONFIG_KEYS.INVENTORY_TAGS].value);
                        this.channels[SIM_TYPES_MAP.ESIM] = Object.keys(inventoryTags.esim_profile || {});
                        this.channels[SIM_TYPES_MAP.PSIM] = Object.keys(inventoryTags.psim_profile || {});
                    }
                    [this.upload.channel] = this.channelOptions;
                    this.isLoadingConfig = false;
                },
                {
                    errorHandler: () => {
                        this.isLoadingConfig = false;
                        this.$alert(this.$t('alertMessage.failedToLoadNecessaryData'));
                    },
                },
            );
        },
        loadCounts(): Promise<void> {
            return this.$withProgressBar(
                async () => {
                    const { data } = await getCounts();
                    this.counters[SIM_TYPES_MAP.PSIM] = this.mapCounts(SIM_TYPE.PSIM, data?.counts?.psim_profile);
                    this.counters[SIM_TYPES_MAP.ESIM] = this.mapCounts(SIM_TYPE.ESIM, data?.counts?.esim_profile);
                },
                {
                    errorHandler: () => {
                        this.$alert(this.$t('alertMessage.failedToLoadNecessaryData'));
                    },
                },
            );
        },
        loadHistory(): Promise<void> {
            return this.$withProgressBar(
                async () => {
                    this.isHistoryDataLoading = true;

                    const data = await getUploadHistory();

                    const getSimTypeLegacy = (entity: UploadedFileDetails) =>
                        entity.entityName.split('_')[0] === String(SIM_TYPES_MAP.ESIM)
                            ? SIM_TYPES_MAP.ESIM
                            : SIM_TYPES_MAP.PSIM;
                    const getSimType = (entity: UploadedFileDetails) => {
                        return entity.metadata?.context_metadata?.resourceType || getSimTypeLegacy(entity);
                    };

                    this.entities = groupBy(data, getSimType) as SimEntities;

                    this.isHistoryDataLoading = false;
                },
                {
                    errorHandler: () => {
                        this.isHistoryDataLoading = false;
                        this.$alert(this.$t('qodNumberManagement.loadingBulkUploadHistoryFailed'));
                    },
                },
            );
        },
        onResourceTypeSelect(): void {
            [this.upload.brand] = this.brands;
            [this.upload.channel] = this.channelOptions;
        },
        onTabSelect(tabId: SIM_TYPES_MAP) {
            this.tab = tabId;
            this.upload.resourceType = this.resourceTypes.find(r => r?.id === tabId);
        },
    },
});
