import http, { DnoResponseBase } from '@/http/index';
import { AxiosPromise } from 'axios';

interface HistoryMetadataResponse extends DnoResponseBase {
    entity_version_metadata: {
        version: number;
        portal_id: string;
        update_time: number;
        state?: string;
        data?: object;
    }[];
}

interface HistoryHistoryVersion extends DnoResponseBase {
    specific_entity_version: unknown;
}

/**
 * Get draft history metadata.
 * @param properties: comma separated string of additional properties to request
 * @returns {AxiosPromise}
 */
export function getHistoryMetadata(
    id: string,
    entityType: string,
    properties: string,
): AxiosPromise<HistoryMetadataResponse> {
    return http({
        method: 'get',
        url: '/privacy/getPolicySpecificationHistoryMetadata',
        params: {
            id,
            entity_type: entityType,
            properties,
        },
    });
}

/**
 * Get draft history version.
 *
 * @returns {AxiosPromise}
 */
export function getHistoryVersion(
    id: string,
    entityType: string,
    version: string,
): AxiosPromise<HistoryHistoryVersion> {
    return http({
        method: 'get',
        url: '/privacy/getPolicySpecificationHistoryVersion',
        params: {
            id,
            entity_type: entityType,
            version,
        },
    });
}

export default {
    getHistoryMetadata,
    getHistoryVersion,
};
