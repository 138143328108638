import { AxiosPromise } from 'axios';
import http, { DnoResponseBase, Entity } from '@/http/index';
import isArray from 'lodash/isArray';

type RewardRuleData = {
    name: string | Record<string, string>;
    condition: object;
    payout_target: number;
    payouts: string[];
} & Record<string, any>;

type RewardPayoutData = {
    name: string | Record<string, string>;
    payout_type: number;
} & Record<string, any>;

type PrizeData = {
    name: string | Record<string, string>;
    payout_id: string;
} & Record<string, any>;

type PrizeEngineData = {
    name: string | Record<string, string>;
    prizes: object[];
    prize_engine_type: string;
    prize_selection_cost_amount: object;
} & Record<string, any>;

type LotteryData = {
    name: string | Record<string, string>;
    win_categories: object[];
    start_time: number;
    end_time: number;
    draw_type: boolean;
    ticket_settings: object;
};

export type RewardRule = Entity<RewardRuleData>;
export type RewardPayout = Entity<RewardPayoutData>;
export type Prize = Entity<PrizeData>;
export type PrizeEngine = Entity<PrizeEngineData>;
export type Lottery = Entity<LotteryData>;

type getRewardRulesResponseBody = DnoResponseBase & {
    reward_rule_v4_by_id: Record<string, RewardRule>;
};
type getRewardPayoutsResponseBody = DnoResponseBase & {
    reward_payout_v4_by_id: Record<string, RewardPayout>;
};
type getPrizesResponseBody = DnoResponseBase & {
    prize_by_id: Record<string, Prize>;
};
type getPrizeEnginesResponseBody = DnoResponseBase & {
    prize_engine_by_id: Record<string, PrizeEngine>;
};
type getLotteriesResponseBody = DnoResponseBase & {
    lottery_by_id: Record<string, Lottery>;
};

type SetDraftData = {
    id?: string;
    data: object;
    template_id: string;
};

type WinnerData = {
    target_id: string;
    target_type: number;
    ticket_id: string;
    category: string;
    spot: number;
};

/**
 * Get reward rules V4.
 *
 * @returns {AxiosPromise}
 */
export function getRewardRulesV4(ids: string[] = []): AxiosPromise<getRewardRulesResponseBody> {
    let data = {};
    if (ids.length && isArray(ids)) {
        data = {
            ids,
        };
    }
    return http({
        method: 'POST',
        url: '/v3reward/getrewardrulev4',
        data,
    });
}

/**
 * Add reward rule V4.
 *
 * @returns {AxiosPromise}
 */
export function addRewardRuleV4(data: RewardRuleData): AxiosPromise<DnoResponseBase> {
    return http({
        method: 'POST',
        url: '/v3rewardwrite/addrewardrulev4',
        data,
    });
}

/**
 * Update reward rule V4.
 *
 * @returns {AxiosPromise}
 */
export function updateRewardRuleV4(id: string, version: number, data: RewardRuleData): AxiosPromise<DnoResponseBase> {
    return http({
        method: 'POST',
        url: '/v3rewardwrite/updaterewardrulev4',
        data: {
            id,
            version,
            data,
        },
    });
}

/**
 * Update reward rule state V4.
 *
 * @returns {AxiosPromise}
 */
export function updateRewardRuleStateV4(id: string, version: number, state: number): AxiosPromise<DnoResponseBase> {
    return http({
        method: 'POST',
        url: '/v3rewardwrite/updatestaterewardrulev4',
        data: {
            id,
            version,
            state,
        },
    });
}

/**
 * Get prize.
 *
 * @returns {AxiosPromise}
 */
export function getPrizes(ids: string[] = []): AxiosPromise<getPrizesResponseBody> {
    let data = {};
    if (ids.length && isArray(ids)) {
        data = {
            ids,
        };
    }
    return http({
        method: 'POST',
        url: '/v3reward/getprize',
        data,
    });
}

/**
 * Add prize.
 *
 * @returns {AxiosPromise}
 */
export function addPrize(data: PrizeData): AxiosPromise<DnoResponseBase> {
    return http({
        method: 'POST',
        url: '/v3rewardwrite/addprize',
        data,
    });
}

/**
 * Update prize.
 *
 * @returns {AxiosPromise}
 */
export function updatePrize(id: string, version: number, data: PrizeData): AxiosPromise<DnoResponseBase> {
    return http({
        method: 'POST',
        url: '/v3rewardwrite/updateprize',
        data: {
            id,
            version,
            data,
        },
    });
}

/**
 * Update prize state.
 *
 * @returns {AxiosPromise}
 */
export function updatePrizeState(id: string, version: number, state: number): AxiosPromise<DnoResponseBase> {
    return http({
        method: 'POST',
        url: '/v3rewardwrite/updatestateprize',
        data: {
            id,
            version,
            state,
        },
    });
}

/**
 * Get prize engine.
 *
 * @returns {AxiosPromise}
 */
export function getPrizeEngine(ids: string[] = []): AxiosPromise<getPrizeEnginesResponseBody> {
    let data = {};
    if (ids.length && isArray(ids)) {
        data = {
            ids,
        };
    }
    return http({
        method: 'POST',
        url: '/v3reward/getprizeengine',
        data,
    });
}

/**
 * Add prize engine.
 *
 * @returns {AxiosPromise}
 */
export function addPrizeEngine(data: PrizeEngineData): AxiosPromise<DnoResponseBase> {
    return http({
        method: 'POST',
        url: '/v3rewardwrite/addprizeengine',
        data,
    });
}

/**
 * Update prize engine.
 *
 * @returns {AxiosPromise}
 */
export function updatePrizeEngine(id: string, version: number, data: PrizeEngineData): AxiosPromise<DnoResponseBase> {
    return http({
        method: 'POST',
        url: '/v3rewardwrite/updateprizeengine',
        data: {
            id,
            version,
            data,
        },
    });
}

/**
 * Update prize engine state.
 *
 * @returns {AxiosPromise}
 */
export function updatePrizeEngineState(id: string, version: number, state: number): AxiosPromise<DnoResponseBase> {
    return http({
        method: 'POST',
        url: '/v3rewardwrite/updatestateprizeengine',
        data: {
            id,
            version,
            state,
        },
    });
}

/**
 * Get lottery.
 *
 * @returns {AxiosPromise}
 */
export function getLotteries(ids: string[] = []): AxiosPromise<getLotteriesResponseBody> {
    let data = {};
    if (ids.length && isArray(ids)) {
        data = {
            ids,
        };
    }
    return http({
        method: 'POST',
        url: '/v3reward/getlottery',
        data,
    });
}

/**
 * Add lottery.
 *
 * @returns {AxiosPromise}
 */
export function addLottery(data: LotteryData): AxiosPromise<DnoResponseBase> {
    return http({
        method: 'POST',
        url: '/v3rewardwrite/addlottery',
        data,
    });
}

/**
 * Update lottery.
 *
 * @returns {AxiosPromise}
 */
export function updateLottery(id: string, version: number, data: LotteryData): AxiosPromise<DnoResponseBase> {
    return http({
        method: 'POST',
        url: '/v3rewardwrite/updatelottery',
        data: {
            id,
            version,
            data,
        },
    });
}

/**
 * Update lottery state.
 *
 * @returns {AxiosPromise}
 */
export function updateLotteryState(id: string, version: number, state: number): AxiosPromise<DnoResponseBase> {
    return http({
        method: 'POST',
        url: '/v3rewardwrite/updatelotterystate',
        data: {
            id,
            version,
            state,
        },
    });
}

/**
 * Get reward payouts.
 *
 * @returns {AxiosPromise}
 */
export function getRewardPayouts(ids: string[] = []): AxiosPromise<getRewardPayoutsResponseBody> {
    let data = {};
    if (ids.length && isArray(ids)) {
        data = {
            ids,
        };
    }
    return http({
        method: 'POST',
        url: '/v3reward/getrewardpayout',
        data,
    });
}

/**
 * Add payout.
 *
 * @returns {AxiosPromise}
 */
export function addRewardPayout(data: RewardPayoutData): AxiosPromise<DnoResponseBase> {
    return http({
        method: 'POST',
        url: '/v3rewardwrite/addrewardpayout',
        data,
    });
}

/**
 * Update reward payout.
 *
 * @returns {AxiosPromise}
 */
export function updateRewardPayout(id: string, version: number, data: RewardPayoutData): AxiosPromise<DnoResponseBase> {
    return http({
        method: 'POST',
        url: '/v3rewardwrite/updaterewardpayout',
        data: {
            id,
            version,
            data,
        },
    });
}

/**
 * Update reward payout state.
 *
 * @returns {AxiosPromise}
 */
export function updateRewardPayoutState(id: string, version: number, state: number): AxiosPromise<DnoResponseBase> {
    return http({
        method: 'POST',
        url: '/v3rewardwrite/updaterewardpayoutstate',
        data: {
            id,
            version,
            state,
        },
    });
}

/**
 * Get Reward Rule list.
 *
 * @returns {AxiosPromise}
 */
export function getRewardRuleDraftList(): AxiosPromise<DnoResponseBase> {
    return http({
        method: 'POST',
        url: '/v3reward/getrewardruledraftlist',
    });
}

/**
 * Delete Reward Rule draft.
 *
 * @returns {AxiosPromise}
 */
export function deleteRewardRuleDraft(id: string): AxiosPromise<DnoResponseBase> {
    return http({
        method: 'POST',
        url: '/v3rewardwrite/deleterewardruledraft',
        data: {
            id,
        },
    });
}

/**
 * Get Reward Rule draft.
 *
 * @returns {AxiosPromise}
 */
export function getRewardRuleDraft(id: string): AxiosPromise<DnoResponseBase> {
    return http({
        method: 'POST',
        url: '/v3reward/getrewardruledraft',
        data: {
            id,
        },
    });
}

/**
 * Set Reward Rule draft.
 *
 * @returns {AxiosPromise}
 */
export function setRewardRuleDraft(data: SetDraftData): AxiosPromise<DnoResponseBase> {
    return http({
        method: 'POST',
        url: '/v3rewardwrite/setrewardruledraft',
        data,
    });
}

/**
 * Get Reward Payout list.
 *
 * @returns {AxiosPromise}
 */
export function getRewardPayoutDraftList(): AxiosPromise<DnoResponseBase> {
    return http({
        method: 'POST',
        url: '/v3reward/getrewardpayoutdraftlist',
    });
}

/**
 * Delete Reward Payout draft.
 *
 * @returns {AxiosPromise}
 */
export function deleteRewardPayoutDraft(id: string): AxiosPromise<DnoResponseBase> {
    return http({
        method: 'POST',
        url: '/v3rewardwrite/deleterewardpayoutdraft',
        data: {
            id,
        },
    });
}

/**
 * Get Reward Payout draft.
 *
 * @returns {AxiosPromise}
 */
export function getRewardPayoutDraft(id: string): AxiosPromise<DnoResponseBase> {
    return http({
        method: 'POST',
        url: '/v3reward/getrewardpayoutdraft',
        data: {
            id,
        },
    });
}

/**
 * Set Reward Payout draft.
 *
 * @returns {AxiosPromise}
 */
export function setRewardPayoutDraft(data: SetDraftData): AxiosPromise<DnoResponseBase> {
    return http({
        method: 'POST',
        url: '/v3rewardwrite/setrewardpayoutdraft',
        data,
    });
}

/**
 * Get Prize list.
 *
 * @returns {AxiosPromise}
 */
export function getPrizeDraftList(): AxiosPromise<DnoResponseBase> {
    return http({
        method: 'POST',
        url: '/v3reward/getprizedraftlist',
    });
}

/**
 * Delete Prize draft.
 *
 * @returns {AxiosPromise}
 */
export function deletePrizeDraft(id: string): AxiosPromise<DnoResponseBase> {
    return http({
        method: 'POST',
        url: '/v3rewardwrite/deleteprizedraft',
        data: {
            id,
        },
    });
}

/**
 * Get Prize draft.
 *
 * @returns {AxiosPromise}
 */
export function getPrizeDraft(id: string): AxiosPromise<DnoResponseBase> {
    return http({
        method: 'POST',
        url: '/v3reward/getprizedraft',
        data: {
            id,
        },
    });
}

/**
 * Set Prize draft.
 *
 * @returns {AxiosPromise}
 */
export function setPrizeDraft(data: SetDraftData): AxiosPromise<DnoResponseBase> {
    return http({
        method: 'POST',
        url: '/v3rewardwrite/setprizedraft',
        data,
    });
}

/**
 * Get PrizeEngine list.
 *
 * @returns {AxiosPromise}
 */
export function getPrizeEngineDraftList(): AxiosPromise<DnoResponseBase> {
    return http({
        method: 'POST',
        url: '/v3reward/getprizeenginedraftlist',
    });
}

/**
 * Delete PrizeEngine draft.
 *
 * @returns {AxiosPromise}
 */
export function deletePrizeEngineDraft(id: string): AxiosPromise<DnoResponseBase> {
    return http({
        method: 'POST',
        url: '/v3rewardwrite/deleteprizeenginedraft',
        data: {
            id,
        },
    });
}

/**
 * Get PrizeEngine draft.
 *
 * @returns {AxiosPromise}
 */
export function getPrizeEngineDraft(id: string): AxiosPromise<DnoResponseBase> {
    return http({
        method: 'POST',
        url: '/v3reward/getprizeenginedraft',
        data: {
            id,
        },
    });
}

/**
 * Set PrizeEngine draft.
 *
 * @returns {AxiosPromise}
 */
export function setPrizeEngineDraft(data: SetDraftData): AxiosPromise<DnoResponseBase> {
    return http({
        method: 'POST',
        url: '/v3rewardwrite/setprizeenginedraft',
        data,
    });
}

//

/**
 * Get Lottery draft list.
 *
 * @returns {AxiosPromise}
 */
export function getLotteryDraftList(): AxiosPromise<DnoResponseBase> {
    return http({
        method: 'POST',
        url: '/v3reward/getlotterydraftlist',
    });
}

/**
 * Delete Lottery draft.
 *
 * @returns {AxiosPromise}
 */
export function deleteLotteryDraft(id: string): AxiosPromise<DnoResponseBase> {
    return http({
        method: 'POST',
        url: '/v3rewardwrite/deletelotterydraft',
        data: {
            id,
        },
    });
}

/**
 * Get Lottery draft.
 *
 * @returns {AxiosPromise}
 */
export function getLotteryDraft(id: string): AxiosPromise<DnoResponseBase> {
    return http({
        method: 'POST',
        url: '/v3reward/getlotterydraft',
        data: {
            id,
        },
    });
}

/**
 * Set Lottery draft.
 *
 * @returns {AxiosPromise}
 */
export function setLotteryDraft(data: SetDraftData): AxiosPromise<DnoResponseBase> {
    return http({
        method: 'POST',
        url: '/v3rewardwrite/setlotterydraft',
        data,
    });
}

/**
 * Get reward payout history V4.
 * @param {Object} data
 * @param {string} data.targetId
 * @param {number} data.targetType
 * @param {number=} data.pageSize
 * @param {string=} data.pageState
 * @returns {AxiosPromise}
 */
export function getRewardHistoryV4({
    targetId,
    targetType,
    pageSize,
    pageState,
}: {
    targetId: string;
    targetType: number;
    pageSize: number;
    pageState: string;
}): AxiosPromise<DnoResponseBase> {
    return http({
        method: 'POST',
        url: '/v3reward/getrewardpayouthistoryv4',
        data: {
            target_id: targetId,
            target_type: targetType,
            page_size: pageSize,
            page_state: pageState,
        },
    });
}

export function getLotteryWinners(lotteryId: string, recurrenceNum: number): AxiosPromise<DnoResponseBase> {
    return http({
        method: 'POST',
        url: '/v3reward/getLotteryWinners',
        data: {
            lottery_id: lotteryId,
            recurrence_num: recurrenceNum,
        },
    });
}

export function setLotteryWinners(lotteryId: string, winnersArray: Array<WinnerData>): AxiosPromise<DnoResponseBase> {
    return http({
        method: 'POST',
        url: '/v3rewardwrite/setLotteryWinners',
        data: {
            lottery_id: lotteryId,
            winners_array: winnersArray,
        },
    });
}

export function drawLottery(id: string): AxiosPromise<DnoResponseBase> {
    return http({
        method: 'POST',
        url: '/v3rewardwrite/drawLottery',
        data: {
            lottery_id: id,
        },
    });
}

export function getOptionsConfig(): AxiosPromise<DnoResponseBase> {
    return http({
        method: 'POST',
        url: '/v3reward/getoptionsconfig',
    });
}

export default {};
