
// components
import AppAditionalSidebar from '@/components/partials/AppAditionalSidebar.vue';
import AbstractTableTile from '@/__new__/features/customerCareSuite/components/AbstractTableTile.vue';
import AppMultiselectV3 from '@/components/partials/inputs/AppMultiselectV3.vue';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import { ICON_TYPES } from '@/common/iconHelper';

// helpers
import * as Sentry from '@sentry/vue';
import { USER_MANAGER_HIERARCHY } from '@/__new__/features/customerCare/common/customerCareHelper';
import tableColumnType from '@/common/filterTable';
import { required } from 'vuelidate/lib/validators';
import { isValidUuid } from '@/common/uuidHelper';
import { mapGetters } from 'vuex';
import { Modules } from '@/store/store';
import { Getters } from '@/store/mutation-types';

// HTTP
import osstmofiberHTTP from '@/__new__/services/dno/osstmofiber/http/osstmofiber';

export default {
    name: 'SalesforceMessageHistoryTile',
    components: {
        AbstractTableTile,
        AppAditionalSidebar,
        AppMultiselectV3,
        AppButton,
    },
    data() {
        return {
            entities: [],
            columnsData: [
                {
                    name: this.$i18n.t('campaigns.campaignName'),
                    key: 'campaignName',
                    field: 'campaignName',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('campaigns.messages.messageSent'),
                    key: 'messageSent',
                    field: 'messageSent',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('campaigns.subject'),
                    key: 'subject',
                    field: 'subject',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
            ],
            selectedEntity: null,
            showAditionalSidebar: false,
            rawResponse: {},
            searchQuery: '',

            BUTTON_TYPES,
            ICON_TYPES,
        };
    },
    validations() {
        return {
            searchQuery: {
                required,
                validSearchQuery: searchQuery => isValidUuid(searchQuery),
            },
        };
    },
    computed: {
        ...mapGetters(Modules.customerCareSuite, [Getters.GET_ASSOCIATED_UM_ENTITY_BY_TYPE_AND_ID]),
        userData() {
            return this[Getters.GET_ASSOCIATED_UM_ENTITY_BY_TYPE_AND_ID]({
                targetType: USER_MANAGER_HIERARCHY.USER,
                targetId: this.$route.params.id,
            });
        },
        relatedAccountId() {
            const ownedResources = this.userData?.ownedResources || {};
            return Object.keys(ownedResources).find(key => ownedResources[key] === USER_MANAGER_HIERARCHY.ACCOUNT);
        },
        relatedAccountIds() {
            const ownedResources = this.userData?.ownedResources || {};
            return Object.keys(ownedResources).filter(key => ownedResources[key] === USER_MANAGER_HIERARCHY.ACCOUNT);
        },
    },
    async created() {
        await this.fetchTileData();
    },
    methods: {
        async fetchTileData() {
            await this.getMessageHistory(this.relatedAccountId);
        },
        async getMessageHistory(id) {
            this.$emit('isDataLoadingUpd', true);
            this.entities = [];
            try {
                this.$Progress.start();
                this.rawResponse = await osstmofiberHTTP.getExternalEmails(id, USER_MANAGER_HIERARCHY.ACCOUNT);
                const emailsResponse = this.rawResponse?.data?.emails || [];
                this.entities = emailsResponse.map((el, index) => {
                    return {
                        id: index,
                        campaignName: el.campaign_name,
                        messageSent: el.message_sent,
                        sender: el.sender,
                        subject: el.subject,
                        message: el.message,
                    };
                });
                this.$Progress.finish();
            } catch (error) {
                this.rawRespons = error?.response;
                this.$alert(
                    this.$i18n.t('alertMessage.errorDoingSmthTryAgain', {
                        action: this.$i18n.t('generic.fetching'),
                        entityName: this.$i18n.t('campaigns.userMessagesHistory'),
                    }),
                );
                this.$Progress.fail();
                Sentry.captureException(error);
            } finally {
                this.$emit('isDataLoadingUpd', false);
            }
        },
        selectMessage(id) {
            this.selectedEntity = this.entities.find(m => m.id === id);
            this.showAditionalSidebar = true;
        },
        onSearch() {
            this.$v.$touch();
            if (this.$v.$anyError) {
                this.$alert(this.$i18n.t('alertMessage.pleaseFixValidation'));
            } else {
                this.getMessageHistory(this.searchQuery);
            }
        },
    },
};
