import httpClient from '@/http/index';
import { AxiosPromise } from 'axios';

/**
 * get party privacy profiles
 * @returns AxiosPromise<Record<string, any>[]>
 */
export function getPartyPrivacyProfile(): AxiosPromise<Record<string, any>[]> {
    return httpClient({
        method: 'GET',
        url: '/consent/getPartyPrivacyProfile',
        params: {
            sort: '-lastUpdate',
        },
    });
}

/**
 * revoke party privacy profile
 * @returns AxiosPromise<void>
 */
export function revokePartyPrivacyProfile(id: string): AxiosPromise<void> {
    return httpClient({
        method: 'POST',
        url: '/consent/revokePartyPrivacyProfile',
        data: {
            channel: 'Administrator',
            partyPrivacyProfileId: id,
        },
    });
}
/**
 * Get consent services.
 * @returns {AxiosPromise}
 */
export function getConsentServices(): AxiosPromise<Record<string, any>[]> {
    return httpClient({
        method: 'GET',
        url: '/consent/getConsentServices',
        params: {
            sort: '-lastUpdate',
        },
    });
}

/**
 * Get consent service by id.
 * @returns {AxiosPromise}
 */
export function getConsentServiceById(id: string): AxiosPromise<Record<string, any>> {
    return httpClient({
        method: 'GET',
        url: '/consent/getConsentServiceById',
        params: { id: id },
    });
}

/**
 * Post consent service.
 * @returns {AxiosPromise}
 */
export function postConsentService(data: Record<string, any>): AxiosPromise<Record<string, any>> {
    return httpClient({
        method: 'POST',
        url: '/consent/postConsentService',
        data: data,
    });
}

/**
 * Patch consent service.
 * @returns {AxiosPromise}
 */

export function patchConsentService(id: string, data: Record<string, any>): AxiosPromise<Record<string, any>> {
    return httpClient({
        method: 'PATCH',
        url: '/consent/patchConsentService',
        params: { id: id },
        data: data,
    });
}

/**
 * Delete consent service.
 * @returns {AxiosPromise}
 */
export function deleteConsentService(id: string): AxiosPromise<void> {
    return httpClient({
        method: 'DELETE',
        url: '/consent/deleteConsentService',
        params: { id: id },
    });
}

export default {};
