import i18n from '@/i18n';
import { epochToDate } from '@/common/formatting';
import { FORM_BUILDER_TYPES } from '@/formBuilderConfig';
import { TEMPLATE_STATE_TO_STRING_MAP } from './templateStates';
import { TEMPLATE_TYPES_MAP } from '@/__new__/services/dno/pc/models/templateTypes';
import store from '@/store/store';
import Actions, { Getters } from '@/store/mutation-types';
import { EntityStateMapping } from '@/common/commonEntityStateMapper';
import ENTITY_TYPES from '@/common/entities/entityTypes';
import localeLibrary from '@/common/locale/localeLibrary';
import { getPartyRoles } from '@/__new__/services/dno/partyRolesPermissionsManagement/http/partyRolesPermissionsManagement';

export const REFERENCED_TYPES = {
    CSG: 'csg',
    TSG: 'tsg',
    VOUCHER: 'voucher',
    LOTTERY: 'lottery',
    CHANNEL_PARTNER: 'channel_partner',
};

/* eslint-disable camelcase */
export class ProductCatalogEntityTemplate {
    id;

    name;

    externalId;

    description;

    state;

    stateLabel;

    entityType;

    createTime;

    updateTime;

    updateTimeFormatted;

    properties;

    groups;

    isRegularTemplateReferenced;

    referencedEntityIds;

    /**
     * Flag indicating that the template was created *internally* by the DNO and is therefore immutable
     */
    isInternal;

    constructor(templateJson, isInternal = false) {
        this.id = templateJson.id;
        this.name = templateJson.name || '';
        this.externalId = templateJson.external_id || '';
        this.description = templateJson.description || '';
        this.state = templateJson.state;
        this.stateLabel = TEMPLATE_STATE_TO_STRING_MAP.get(templateJson.state);
        this.createTime = templateJson.create_time ? epochToDate(templateJson.create_time) : '';
        this.updateTime = templateJson.update_time;
        this.updateTimeFormatted = localeLibrary.getFormattedDateAndTime(templateJson.update_time);
        this.entityType = templateJson.type;
        this.presetTemplates =
            templateJson?.referenced_templates.filter(template => template.type === TEMPLATE_TYPES_MAP.PRESET) || [];
        this.properties = Object.entries(templateJson.property_definitions).map(([key, value]) =>
            this.fromJsonProperty(key, value),
        );
        this.groups = Object.entries(templateJson.groups)
            .map(([groupId, groupData]) => this.fromJsonGroup(groupId, groupData))
            .sort((a, b) => b.sortWeight - a.sortWeight);
        this.isRegularTemplateReferenced = Boolean(templateJson?.entity_refs_by?.length) || false;
        this.referencedEntityIds = templateJson?.entity_refs_by || [];
        this.referencedTemplate = templateJson?.template_refs_by || [];
        this.isInternal = isInternal;
        // TODO: version when implemented + states mapping
    }

    fromJsonGroup(groupId, groupData) {
        return {
            id: groupId,
            name: groupData.name,
            description: groupData.description,
            sortWeight: groupData.sort_weight,
            parentGroupId: groupData.parent_group_id,
        };
    }

    fromJsonProperty(id, property) {
        const dataType = property.schema.type;
        const propertyFormatted = {
            id,
            name: property.name || id,
            description: property.description,
            placeholder: property?.ui_properties?.placeholder,
            isTextArea: property?.ui_properties?.is_text_area,
            referencedType: property?.ui_properties?.referenced_type,
            dataType: property.schema.type,
            groupId: property.group_id,
            isRequired: property.is_required,
            step: property?.ui_properties?.step,
            sortWeight: property.sort_weight,
            schema: property.schema,
            enumMapping: property.enum_mapping,
        };

        if (Object.hasOwnProperty.call(property, 'default_value')) {
            propertyFormatted.defaultValue = property.default_value;
        }

        if (dataType === FORM_BUILDER_TYPES.INPUT && propertyFormatted?.referencedType) {
            propertyFormatted.dataType = FORM_BUILDER_TYPES.REFERENCE;
        }

        // TODO: remove integer part once BE updates to number
        if (dataType === FORM_BUILDER_TYPES.NUMBER || dataType === 'integer') {
            propertyFormatted.dataType = FORM_BUILDER_TYPES.NUMERIC;
        }

        // if (dataType === FORM_BUILDER_TYPES.INPUT) {
        //     propertyFormatted.dataType = FORM_BUILDER_TYPES.INPUT;
        // }
        if (dataType === FORM_BUILDER_TYPES.INPUT && property?.ui_properties?.is_text_area) {
            propertyFormatted.dataType = FORM_BUILDER_TYPES.TEXTAREA;
        }

        if (dataType === 'char_spec') {
            propertyFormatted.dataType = FORM_BUILDER_TYPES.DROPDOWN;
            propertyFormatted.enum = property.char_spec_id;
        }

        if (dataType === 'array_char_spec') {
            propertyFormatted.dataType = FORM_BUILDER_TYPES.DROPDOWN_MULTIPLE;
            propertyFormatted.enum = property.char_spec_id;
            propertyFormatted.defaultValue = propertyFormatted.defaultValue || [];
        }

        return propertyFormatted;
    }

    static toJsonProperty(property) {
        return {
            name: property.name,
            description: property.description || '',
            placeholder: property.placeholder || '',
            data_type:
                FORM_BUILDER_TYPES.TEXTAREA === property.dataType || FORM_BUILDER_TYPES.REFERENCE === property.dataType
                    ? FORM_BUILDER_TYPES.INPUT
                    : property.dataType,
            sort_weight: property.sortWeight,
            step: property?.step || 1,
            default_value: `${property.defaultValue}`,
            is_required: property.isRequired,
            is_textarea: FORM_BUILDER_TYPES.TEXTAREA === property.dataType,
            group_id: property.groupId,
        };
    }

    static toJsonGroup(group) {
        return {
            id: group.id,
            name: group.name,
            description: group.description,
            sort_weight: group.sortWeight,
            parent_group_id: group.parentGroupId,
        };
    }

    // to parse data for the API call
    static toJson(entityData) {
        return {
            name: entityData.name,
            description: entityData.description,
            template_id: entityData.entityTemplateId,
            state: entityData.state,
            referenced_templates: entityData.refferencedTemplates || [],
            groups: entityData.groups.reduce((acc, group) => {
                acc[group.id] = ProductCatalogEntityTemplate.toJsonGroup(group);
                return acc;
            }, {}),
            properties_definition: entityData.properties.reduce((acc, property) => {
                acc[property.id] = ProductCatalogEntityTemplate.toJsonProperty(property);
                return acc;
            }, {}),
            // TODO: version when implemented + states mapping
        };
    }

    // map from BE JSON to FormBuilder format
    static async formattedDataForFormBuilder(groups, properties) {
        const groupsFormatted = {};

        if (groups && groups.length) {
            groups.forEach(({ id, name, description }) => {
                groupsFormatted[id] = {
                    id,
                    name,
                    description,
                    fields: [],
                };
            });
        }

        if (properties && properties.length) {
            /* eslint-disable no-await-in-loop */
            for (const prop of properties) {
                const field = await ProductCatalogEntityTemplate.formatProperty(prop);
                groupsFormatted[prop.groupId].fields.push(field);
            }
            /* eslint-enable no-await-in-loop */
        }

        // sorting properties based on sort_weight
        return Object.values(groupsFormatted).map(groupFormatted => {
            groupFormatted.fields = groupFormatted.fields.sort((a, b) => a.sortWeight - b.sortWeight);
            return groupFormatted;
        });
    }

    static async formatProperty(prop) {
        const labelKey =
            prop.dataType === FORM_BUILDER_TYPES.DROPDOWN ||
            prop.dataType === FORM_BUILDER_TYPES.DROPDOWN_MULTIPLE ||
            prop.dataType === FORM_BUILDER_TYPES.REFERENCE
                ? 'additionalLabel'
                : 'label';

        const field = {
            id: prop.id,
            key: prop.id,
            name: prop.name || '',
            type: prop.dataType,
            description: prop.description || '',
            placeholder: prop.placeholder || '',
            defaultValue: Object.hasOwnProperty.call(prop, 'defaultValue') ? prop.defaultValue : null,
            isRequired: prop.isRequired,
            groupId: prop.groupId,
            sortWeight: prop.sortWeight,

            componentProps: {
                ...prop.componentProps,
                [labelKey]: prop.name,
                explanationText: `${i18n.t('generic.externalId')}: ${prop.id}
${i18n.t('generic.description')}: ${prop.description || i18n.t('generic.empty')}`,
                placeholder: prop.placeholder,
            },
        };

        if (prop.dataType === FORM_BUILDER_TYPES.DROPDOWN || prop.dataType === FORM_BUILDER_TYPES.DROPDOWN_MULTIPLE) {
            if (prop?.enumMapping) {
                const sortedEnumMapping = Object.fromEntries(
                    (Array.isArray(prop.schema.enum) ? prop.schema.enum : []).map(key => [key, prop.enumMapping[key]]),
                );
                field.componentProps.options = Object.entries(sortedEnumMapping).map(([key, value]) => ({
                    label: `${value} (key: ${key})`,
                    key,
                }));
                field.componentProps.trackBy = 'key';
                field.componentProps.label = 'label';
                field.componentProps.optionId = 'key';
            } else {
                field.componentProps.options = prop?.schema?.enum || [];
            }
            field.componentProps.tooltipOffset = 10;
        }

        // originally this is reference type, but we identify it by ui_properties.referenced_type in fromJson method
        // since in the end what we pass to BE is string even considering this is ID
        if (
            prop.dataType === FORM_BUILDER_TYPES.REFERENCE &&
            prop?.referencedType &&
            (prop?.referencedType === REFERENCED_TYPES.CSG ||
                prop?.referencedType === REFERENCED_TYPES.TSG ||
                prop?.referencedType === REFERENCED_TYPES.VOUCHER ||
                prop?.referencedType === REFERENCED_TYPES.LOTTERY)
        ) {
            let optionsFromGetters = [];
            if (prop?.referencedType === REFERENCED_TYPES.CSG) {
                await store.dispatch(`charging/${Actions.REQUEST_CHARGING_SPECIFICATIONS_GROUPS}`);
                optionsFromGetters = store.getters[`charging/${Getters.GET_CHARGING_SPECIFICATIONS_GROUPS}`];
            }

            if (prop?.referencedType === REFERENCED_TYPES.TSG) {
                await store.dispatch(`chargingV2/${Actions.REQUEST_TARIFF_SPECIFICATION_GROUPS}`);
                optionsFromGetters = store.getters[`chargingV2/${Getters.GET_TARIFF_SPECIFICATION_GROUPS}`];
            }

            if (prop?.referencedType === REFERENCED_TYPES.VOUCHER) {
                await store.dispatch(`rewards/${Actions.REQUEST_REWARDS_ENTITIES_BY_TYPE}`, ENTITY_TYPES.VOUCHER_SET);
                optionsFromGetters = store.getters[`rewards/${Getters.GET_APPROVED_REWARDS_ENTITIES_BY_TYPE}`](
                    ENTITY_TYPES.VOUCHER_SET,
                );
            }

            if (prop?.referencedType === REFERENCED_TYPES.LOTTERY) {
                await store.dispatch(`rewards/${Actions.REQUEST_REWARDS_ENTITIES_BY_TYPE}`, ENTITY_TYPES.LOTTERY);
                optionsFromGetters = store.getters[`rewards/${Getters.GET_APPROVED_REWARDS_ENTITIES_BY_TYPE}`](
                    ENTITY_TYPES.LOTTERY,
                );
            }

            field.componentProps.options = optionsFromGetters
                .filter(option => option.state === EntityStateMapping.APPROVED)
                .map(o => ({
                    id: o.id,
                    label: `${o.name ?? o.data.name.en} (${TEMPLATE_STATE_TO_STRING_MAP.get(o.state)}) (${o.id})`,
                }));

            // setting Multiselect component props to render necessary data for this case
            field.componentProps.trackBy = 'id';
            field.componentProps.label = 'label';
            field.componentProps.optionId = 'id';
        }

        if (
            prop.dataType === FORM_BUILDER_TYPES.REFERENCE &&
            prop?.referencedType &&
            prop?.referencedType === REFERENCED_TYPES.CHANNEL_PARTNER
        ) {
            let entitiesOptions = [];
            // TODO: store instead of pure API call
            try {
                const response = await getPartyRoles('ChannelPartner');
                entitiesOptions = response?.data?.map(partyRole => ({
                    id: partyRole?.id || '',
                    name: partyRole?.name || '',
                }));
            } catch (err) {
                console.log('Channel partner api call err:', err);
            }

            field.componentProps.options = entitiesOptions.map(o => ({
                id: o.id,
                label: o.name,
            }));

            // setting Multiselect component props to render necessary data for this case
            field.componentProps.trackBy = 'id';
            field.componentProps.label = 'label';
            field.componentProps.optionId = 'id';
        }

        if (Object.hasOwnProperty.call(prop, 'isRequired')) {
            field.componentProps.optional = !prop.isRequired;
        }
        if (prop.isRequired) {
            if (prop.dataType === FORM_BUILDER_TYPES.DROPDOWN_MULTIPLE) {
                field.validation = val => val.length;
            } else {
                field.validation = val => val;
            }
        }

        // TODO: uncomment if we will need it
        // Need to import it here to avoid empty permissions
        // eslint-disable-next-line import/no-dynamic-require,global-require
        // const externalIds = require('@/__new__/services/dno/pc/models/externalIds').default;
        // const externalId = externalIds[prop.id];
        // Function for custom validation for external id
        // if (externalId && externalId.validation) {
        // field.validation = externalId.validation;
        // }

        if (prop.step) {
            field.componentProps.step = prop.step;
        }

        return field;
    }
}

export default ProductCatalogEntityTemplate;
