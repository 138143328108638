import Vue from 'vue';
import * as Sentry from '@sentry/vue';
import keyBy from 'lodash/keyBy';
import Actions, { State, Getters, Mutations } from '@/store/mutation-types';

import {
    getChargingSpecifications,
    getChargingSpecificationsDraftList,
} from '@/__new__/services/dno/charging/http/chargingSpecifications';
import { EntityStateMapping } from '@/common/commonEntityStateMapper';
import { checkForUnpublishedChanges, STATUS_CODES } from '@/common/commonHelper';
import {
    getChargingSpecificationsGroups,
    getChargingSpecificationsGroupsDraftList,
} from '@/__new__/services/dno/charging/http/chargingSpecificationsGroups';
import {
    getConditionParameters,
    getConditionParametersDraftList,
} from '@/__new__/services/dno/charging/http/conditionParameters';

import {
    ConditionParametersModel,
    ConditionParametersDraft,
} from '@/__new__/services/dno/charging/models/ConditionParametersModel';
import {
    CHARGING_ENTITY_TYPES,
    mapLowerEntitiesVersions,
} from '@/__new__/services/dno/charging/common/versioningHelper';
import { getAllConditionParametersIds } from '@/__new__/services/dno/charging/common/expression';

const chargingStore = {
    namespaced: true,
    state: {
        [State.AFFECTED_BY_POLICY_COUNTER]: {},
        [State.CHARGING_SPECIFICATIONS]: {},
        [State.CHARGING_SPECIFICATIONS_API_RESPONSE]: {},
        [State.CHARGING_SPECIFICATION_BY_VERSION]: null,
        [State.CHARGING_SPECIFICATIONS_GROUPS]: {},
        [State.CHARGING_SPECIFICATIONS_GROUPS_API_RESPONSE]: {},
        [State.CONDITION_PARAMETERS]: {},
        [State.CONDITION_PARAMETERS_API_RESPONSE]: {},
        [State.CONDITION_PARAMETER_BY_VERSION]: null,
    },
    mutations: {
        [Mutations.SET_CHARGING_SPECIFICATIONS]: (state, chargingSpecifications) => {
            state[State.CHARGING_SPECIFICATIONS] = chargingSpecifications;
        },
        [Mutations.SET_CHARGING_SPECIFICATIONS_API_RESPONSE]: (state, chargingSpecifications) => {
            state[State.CHARGING_SPECIFICATIONS_API_RESPONSE] = chargingSpecifications;
        },
        [Mutations.SET_CHARGING_SPECIFICATION_BY_VERSION]: (state, chargingSpecification) => {
            state[State.CHARGING_SPECIFICATION_BY_VERSION] = chargingSpecification;
        },
        [Mutations.SET_CHARGING_SPECIFICATIONS_GROUPS]: (state, chargingSpecificationsGroups) => {
            state[State.CHARGING_SPECIFICATIONS_GROUPS] = chargingSpecificationsGroups;
        },
        [Mutations.SET_CHARGING_SPECIFICATIONS_GROUPS_API_RESPONSE]: (state, chargingSpecificationsGroups) => {
            state[State.CHARGING_SPECIFICATIONS_GROUPS_API_RESPONSE] = chargingSpecificationsGroups;
        },
        [Mutations.SET_AFFECTED_BY_POLICY_COUNTER]: (state, affectedEntities) => {
            Vue.set(state, [State.AFFECTED_BY_POLICY_COUNTER], affectedEntities);
        },
        [Mutations.SET_CONDITION_PARAMETERS]: (state, conditionParameters) => {
            state[State.CONDITION_PARAMETERS] = conditionParameters;
        },
        [Mutations.SET_CONDITION_PARAMETERS_API_RESPONSE]: (state, response) => {
            state[State.CONDITION_PARAMETERS_API_RESPONSE] = response;
        },
        [Mutations.SET_CONDITION_PARAMETER_BY_VERSION]: (state, response) => {
            state[State.CONDITION_PARAMETER_BY_VERSION] = response;
        },
    },
    actions: {
        async [Actions.REQUEST_CHARGING_SPECIFICATIONS]({ commit }) {
            try {
                const [response, draftResponse] = await Promise.all([
                    getChargingSpecifications(),
                    getChargingSpecificationsDraftList(),
                ]);

                const draftEntities =
                    Object.values(draftResponse?.data?.data || {}).map(e => ({
                        ...e,
                        isDraft: true,
                        state: STATUS_CODES.NA,
                        entityVersion: STATUS_CODES.DRAFT,
                        updateTime: e.update_time,
                    })) || [];

                const { charging_spec_by_id: chargingSpecById } = response.data;
                const chargingSpecifications =
                    Object.values(chargingSpecById).map(e => ({
                        ...e,
                        chargingVersion: e.charging_version,
                        pushedVersion: e.pushed_version || null,
                        entityVersion: checkForUnpublishedChanges(e, draftEntities),
                    })) || {};

                commit(
                    Mutations.SET_CHARGING_SPECIFICATIONS,
                    keyBy([...draftEntities, ...chargingSpecifications], 'id'),
                );
                commit(Mutations.SET_CHARGING_SPECIFICATIONS_API_RESPONSE, response);
            } catch (e) {
                commit(Mutations.SET_CHARGING_SPECIFICATIONS_API_RESPONSE, { response: e.response });
                Sentry.captureException(e);
                throw e;
            }
        },
        async [Actions.REQUEST_CHARGING_SPECIFICATION_BY_VERSION]({ commit }, data) {
            try {
                const [response, draftResponse] = await Promise.all([
                    getChargingSpecifications([data.id], true, true, true, data.version),
                    getChargingSpecificationsDraftList(),
                ]);

                const draftEntities =
                    Object.values(draftResponse?.data?.data || {}).map(e => ({
                        ...e,
                        isDraft: true,
                        state: STATUS_CODES.NA,
                        entityVersion: STATUS_CODES.DRAFT,
                        updateTime: e.update_time,
                    })) || [];

                const {
                    charging_spec_by_id: chargingSpecById,
                    policy_counter_by_id: policyCounterById = {},
                    usage_counter_by_id: usageCounterByiId = {},
                    condition_parameter_by_id: conditionParameterById = {},
                } = response.data;

                const chargingSpecData = chargingSpecById?.[data.id] || null;
                let chargingSpec = null;

                const lowerEntitiesVersions = {
                    [CHARGING_ENTITY_TYPES.POLICY_COUNTER]: mapLowerEntitiesVersions(
                        policyCounterById,
                        chargingSpecData?.data?.policy_counter_ids,
                    ),
                    [CHARGING_ENTITY_TYPES.USAGE_COUNTER]: mapLowerEntitiesVersions(
                        usageCounterByiId,
                        chargingSpecData?.data?.usage_counters,
                    ),
                    [CHARGING_ENTITY_TYPES.CONDITION_PARAMETER]: mapLowerEntitiesVersions(
                        conditionParameterById,
                        getAllConditionParametersIds(chargingSpecData?.data?.condition_expr) || [],
                    ),
                    [CHARGING_ENTITY_TYPES.CONDITION_PARAMETER_SURCHARGE]: mapLowerEntitiesVersions(
                        conditionParameterById,
                        getAllConditionParametersIds(chargingSpecData?.data?.surcharges?.condition_expr) || [],
                    ),
                };

                if (chargingSpecData) {
                    chargingSpec = {
                        ...chargingSpecData,
                        chargingVersion: chargingSpecData.charging_version,
                        entityVersion: checkForUnpublishedChanges(chargingSpecData, draftEntities),
                        lowerEntitiesVersions,
                    };
                }
                commit(Mutations.SET_CHARGING_SPECIFICATION_BY_VERSION, chargingSpec);
            } catch (e) {
                commit(Mutations.SET_CHARGING_SPECIFICATIONS_API_RESPONSE, { response: e.response });
                Sentry.captureException(e);
                throw e;
            }
        },
        async [Actions.REQUEST_CHARGING_SPECIFICATIONS_GROUPS]({ commit }) {
            try {
                const [response, draftResponse] = await Promise.all([
                    getChargingSpecificationsGroups(),
                    getChargingSpecificationsGroupsDraftList(),
                ]);

                const draftEntities =
                    Object.values(draftResponse?.data?.data || {}).map(e => ({
                        ...e,
                        isDraft: true,
                        state: STATUS_CODES.NA,
                        entityVersion: STATUS_CODES.DRAFT,
                        updateTime: e.update_time,
                    })) || [];

                const { charging_spec_group_by_id: chargingSpecGroupById } = response.data;
                const chargingSpecificationsGroups =
                    Object.values(chargingSpecGroupById).map(e => ({
                        ...e,
                        entityVersion: checkForUnpublishedChanges(e, draftEntities),
                    })) || {};

                commit(
                    Mutations.SET_CHARGING_SPECIFICATIONS_GROUPS,
                    keyBy([...draftEntities, ...chargingSpecificationsGroups], 'id'),
                );
                commit(Mutations.SET_CHARGING_SPECIFICATIONS_GROUPS_API_RESPONSE, response);
            } catch (e) {
                commit(Mutations.SET_CHARGING_SPECIFICATIONS_GROUPS_API_RESPONSE, { response: e.response });
                Sentry.captureException(e);
                throw e;
            }
        },
        [Actions.REQUEST_SETTING_AFFECTED_BY_POLICY_COUNTER]({ commit, affectedEntities }) {
            commit(Mutations.SET_AFFECTED_BY_POLICY_COUNTER, affectedEntities);
        },
        async [Actions.REQUEST_CONDITION_PARAMETERS]({ commit }) {
            try {
                const [response, draftResponse] = await Promise.all([
                    getConditionParameters(),
                    getConditionParametersDraftList(),
                ]);

                const draftEntities = Object.values(draftResponse?.data?.data || {}).map(
                    e =>
                        new ConditionParametersDraft({
                            ...e,
                            ...e.data,
                        }),
                );

                const { condition_parameter_by_id: conditionParameterById } = response.data;
                const cpList = Object.values(conditionParameterById).map(
                    cp =>
                        new ConditionParametersModel(
                            {
                                ...cp,
                                ...cp.data,
                            },
                            draftEntities,
                        ),
                );

                commit(
                    Mutations.SET_CONDITION_PARAMETERS,
                    keyBy([...draftEntities, ...cpList], ({ id }) => id),
                );
                commit(Mutations.SET_CONDITION_PARAMETERS_API_RESPONSE, response);
            } catch (e) {
                commit(Mutations.SET_CONDITION_PARAMETERS_API_RESPONSE, { response: e.response });
                Sentry.captureException(e);
                throw e;
            }
        },
        async [Actions.REQUEST_CONDITION_PARAMETER_BY_VERSION]({ commit }, data) {
            try {
                const [response, draftResponse] = await Promise.all([
                    getConditionParameters([data.id], true, true, true, data.version),
                    getConditionParametersDraftList(),
                ]);

                const draftEntities = Object.values(draftResponse?.data?.data || {}).map(
                    e =>
                        new ConditionParametersDraft({
                            ...e,
                            ...e.data,
                        }),
                );

                const { condition_parameter_by_id: conditionParameterById } = response.data;
                const conditionParameterData = conditionParameterById?.[data.id] || null;
                let conditionParamete = null;

                if (conditionParameterData) {
                    conditionParamete = new ConditionParametersModel(
                        {
                            ...conditionParameterData,
                            ...conditionParameterData.data,
                        },
                        draftEntities,
                    );
                }

                commit(Mutations.SET_CONDITION_PARAMETER_BY_VERSION, conditionParamete);
            } catch (e) {
                commit(Mutations.SET_CONDITION_PARAMETER_BY_VERSION, { response: e.response });
                Sentry.captureException(e);
                throw e;
            }
        },
    },
    getters: {
        [Getters.GET_CHARGING_SPECIFICATIONS]: state => Object.values(state[State.CHARGING_SPECIFICATIONS]) || [],
        [Getters.GET_ONLY_PUBLISHED_CHARGING_SPECIFICATIONS]: state =>
            Object.values(state[State.CHARGING_SPECIFICATIONS]).filter(
                data => data.state === EntityStateMapping.APPROVED,
            ),
        [Getters.GET_CHARGING_SPECIFICATIONS_API_RESPONSE]: state =>
            state[State.CHARGING_SPECIFICATIONS_API_RESPONSE] || {},
        [Getters.GET_CHARGING_SPECIFICATION_BY_ID]: state => id => state[State.CHARGING_SPECIFICATIONS][id] || {},
        [Getters.GET_CHARGING_SPECIFICATIONS_BY_IDS]: state => ids => {
            const chargingSpecs = [];
            ids.forEach(id => {
                if (state[State.CHARGING_SPECIFICATIONS] && state[State.CHARGING_SPECIFICATIONS][id]) {
                    chargingSpecs.push(state[State.CHARGING_SPECIFICATIONS][id]);
                }
            });
            return chargingSpecs;
        },
        [Getters.GET_CHARGING_SPECIFICATION_BY_VERSION]: state =>
            state[State.CHARGING_SPECIFICATION_BY_VERSION] || null,
        [Getters.GET_CHARGING_SPECIFICATIONS_GROUPS]: state =>
            Object.values(state[State.CHARGING_SPECIFICATIONS_GROUPS]) || [],
        [Getters.GET_CHARGING_SPECIFICATIONS_GROUPS_API_RESPONSE]: state =>
            state[State.CHARGING_SPECIFICATIONS_GROUPS_API_RESPONSE] || {},
        [Getters.GET_CHARGING_SPECIFICATIONS_GROUP_BY_ID]: state => id =>
            state[State.CHARGING_SPECIFICATIONS_GROUPS][id] || {},
        [Getters.GET_CONDITION_PARAMETERS]: state => Object.values(state[State.CONDITION_PARAMETERS]) || [],
        [Getters.GET_CONDITION_PARAMETERS_API_RESPONSE]: state => state[State.CONDITION_PARAMETERS_API_RESPONSE] || {},
        [Getters.GET_APPROVED_CONDITION_PARAMETERS]: state =>
            Object.values(state[State.CONDITION_PARAMETERS]).filter(rg => rg.state === EntityStateMapping.APPROVED),
        [Getters.GET_CONDITION_PARAMETER_BY_ID]: state => id => state[State.CONDITION_PARAMETERS][id] || {},
        [Getters.GET_CONDITION_PARAMETERS_BY_IDS]: state => ids => {
            const pcs = [];
            ids.forEach(id => {
                if (state[State.CONDITION_PARAMETERS] && state[State.CONDITION_PARAMETERS][id]) {
                    pcs.push(state[State.CONDITION_PARAMETERS][id]);
                }
            });
            return pcs;
        },
        [Getters.GET_CONDITION_PARAMETER_BY_VERSION]: state => state[State.CONDITION_PARAMETER_BY_VERSION] || null,
    },
};

export default chargingStore;
