import NcsBaseCondition from '@/__new__/services/dno/charging/models/chargingSpecificationConditions/NCSbaseCondition';

export default class TimeInCallCondition extends NcsBaseCondition {
    constructor({ data, uuid, type, dataEmpty = true, dataValid = false }) {
        super();
        this.data = data;
        this.type = type;
        this.uuid = uuid;
        this.dataEmpty = dataEmpty;
        this.dataValid = dataValid;
    }

    validationCondition() {
        const isTimeSlotsStartInvalid = this.data.every(el => el.start_offset !== '');
        const isTimeSlotsEndInvalid = this.data.every(el => el.end_offset !== '');
        return isTimeSlotsStartInvalid && isTimeSlotsEndInvalid;
    }

    isDataValid() {
        this.dataValid = this.validationCondition();
    }
}
