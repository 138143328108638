import { TaxAttributionData } from './taxAttribution';
import { TaxAttributionDataDno } from './taxAttributionDno';

/**
 * Converts dno tax data to Portal tax data.
 * Conversion from DNO type to Portal type.
 *
 * Makes the following assumptions:
 * - All amounts have the same distribution type
 * @param taxData
 * @returns front end consumable `TaxAttributionData`
 */
export const taxAttributionDnoToFe = (taxData: TaxAttributionDataDno): TaxAttributionData => {
    const taxAttributionList = taxData.tax_distribution.map(e => ({
        id: e.entity_id,
        amount: Number(e.distribution_amount),
    }));

    // Determine the rounding indicator index
    // If search fails default index to 0
    let roundingIndicatorIndex = taxData.tax_distribution.findIndex(d => d.rounding_indicator === true);
    if (roundingIndicatorIndex === -1) {
        roundingIndicatorIndex = 0;
    }

    // Determine type for the amount (%/$)
    const amountType = taxData.tax_distribution[0]?.distribution_type ?? null;

    return {
        amountType,
        roundingIndicatorIndex,
        taxAttributionList,
        taxInclusive: taxData.tax_inclusive,
    };
};

/**
 * Converts front end tax attribution data to a format accepted by the DNO.
 * Note: Returning any since it's possible we can be missing data at the time this function is called.
 *       EG: User may not have selected an amount type
 * @param taxData
 * @param childEntities
 * @returns
 */
export const taxAttributionFeToDno = (taxData: TaxAttributionData, childEntities: any[]): any => {
    const taxDistribution = taxData.taxAttributionList.map((taxAttribution, index) => ({
        entity_id: taxAttribution.id,
        entity_type: childEntities.find(e => e.id === taxAttribution.id)?.entityType,
        distribution_amount: String(taxAttribution.amount),
        distribution_type: taxData.amountType,
        rounding_indicator: index === taxData.roundingIndicatorIndex,
    }));
    return {
        tax_inclusive: taxData.taxInclusive,
        tax_distribution: taxDistribution,
    };
};
