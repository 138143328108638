<template>
    <div
        :class="[firstTabActive ? 'justify-content-end' : 'justify-content-between']"
        class="footer-nav d-flex"
    >
        <span
            :class="[firstTabActive ? 'd-none' : 'd-flex']"
            class="previous clickable align-items-center"
            @click="navigate(-1)"
        >
            {{ previousText }}
        </span>
        <span
            :class="[lastTabActive ? 'd-none' : 'd-flex']"
            class="next clickable align-items-center"
            @click="navigate(1)"
        >
            {{ nextText }}
        </span>
    </div>
</template>

<script>
export default {
    name: 'FooterNav',
    props: {
        value: {
            type: Number,
            default: 1,
        },
        numOfTabs: {
            type: Number,
            default: 8,
        },
        previousText: {
            type: String,
            default: 'Previous',
        },
        nextText: {
            type: String,
            default: 'Next',
        },
    },
    computed: {
        firstTabActive() {
            return this.value === 0;
        },
        lastTabActive() {
            return this.value === this.numOfTabs - 1;
        },
    },
    methods: {
        navigate(offset) {
            this.$emit('input', this.value + offset);
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/_palette';

.footer-nav {
    padding-top: 1.25rem;
    border-top: solid 1px $blue15;
}

.clickable {
    cursor: pointer;
    color: $blue;
    line-height: 26px;
    font-size: 0.875rem;
    font-weight: 600;
}

.previous {
    &::before {
        content: url('~@/assets/icons/arrow-left-round-border.svg');
        height: 24px;
        margin-right: 0.5rem;
    }
}

.next {
    &::after {
        content: url('~@/assets/icons/arrow-left-round-border.svg');
        transform: rotate(180deg);
        height: 24px;
        margin-left: 0.5rem;
    }
}
</style>
