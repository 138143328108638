
// generic
import Vue from 'vue';
import * as Sentry from '@sentry/vue';

// vuex
import { mapGetters, mapActions } from 'vuex';
import Actions, { Getters } from '@/store/mutation-types';
import { Modules } from '@/store/store';

// components
import AbstractEditPageWrapper from '@/components/layout/AbstractEditPageWrapper.vue';
import AppMultiselectV3 from '@/components/partials/inputs/AppMultiselectV3.vue';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import { ICON_TYPES } from '@/common/iconHelper';
import AppHeader from '@/components/layout/AppHeader.vue';
import AppInputV3 from '@/components/partials/inputs/AppInputV3.vue';
import AppToggle from '@/components/partials/inputs/AppToggle.vue';
import UnitPicker from '@/components/partials/inputs/UnitPicker.vue';
import { ALERT_TYPES } from '@/common/alerts/Alert';
import MutationDialog from '@/components/partials/MutationDialog.vue';
import EditorButtons from '@/components/layout/EditorButtons.vue';
import AppLabel from '@/components/partials/AppLabel.vue';
import Draggable from 'vuedraggable';
import ConditionsExpressionEditor from '@/__new__/features/charging/ConditionsExpressionEditor.vue';
import AppIcon from '@/components/partials/icon/AppIcon.vue';
import EntityStatusIndicator from '@/components/partials/EntityStatusIndicator.vue';
import AppTextareaV3 from '@/components/partials/inputs/AppTextareaV3.vue';
import ChargingTagLabel from '@/__new__/features/charging/ChargingTagLabel.vue';

// mixins
import entityEditorMixin from '@/common/entityEditorMixin';
import mutationDialogMixin from '@/components/partials/mutations/mutationDialogMixin.vue';
import { validationMixin } from 'vuelidate';
import ChargingCommonActionsMixin from '@/__new__/features/charging/ChargingCommonActionsMixin.vue';

// helpers
import { required, requiredIf, between, integer, minValue } from 'vuelidate/lib/validators';
import RouteNames from '@/router/routeNames';
import { formatMutationDialogEntities, getAffectedEntities, EDITOR_MODE } from '@/common/entities/entityHelper';
import ENTITY_TYPES from '@/common/entities/entityTypes';
import { languageMap } from '@/common/locale/language';
import { TOOLTIP_POSITION } from '@/common/tooltip';
import {
    RatingGroup,
    RatingGroupRule,
    ChargingEntityVersion,
} from '@/__new__/services/dno/charging/models/ChargingInterfaces';
import { payloadData, RatingGroupDraft } from '@/__new__/services/dno/charging/models/RatingGroup';
import Button from '@/common/button/Button';
import cloneDeep from 'lodash/cloneDeep';
import { STATUS_CODES } from '@/common/commonHelper';
import { LABEL_COLOR } from '@/common/labelsHelper';
import { CONDITION_TYPES } from '@/__new__/services/dno/charging/common/chargingSpecificationHelper';
import { PLURALIZATION } from '@/common/locale/labelSingularOrPlural';
import {
    loadVersionHistory,
    CHARGING_ENTITY_TYPES,
    LowerEntitiesVersions,
} from '@/__new__/services/dno/charging/common/versioningHelper';
import { EntityStateMapping } from '@/common/commonEntityStateMapper';

// http
import { getRatingGroupEntityDraft } from '@/__new__/services/dno/charging/http/ratingGroups';
import { getUserNameById } from '@/__new__/services/portal/profile/http/profile';

interface MapperObject {
    id: number;
    label: string;
}

type FINAL_UNIT_ACTIONS_KEYS = 'TERMINATE' | 'REDIRECTION';
// eslint-disable-next-line
export const FINAL_UNIT_ACTIONS_MAP: Readonly<{ [T in FINAL_UNIT_ACTIONS_KEYS]: MapperObject }> = {
    TERMINATE: {
        id: 1,
        label: 'Terminate',
    },
    REDIRECTION: {
        id: 2,
        label: 'Redirection',
    },
};

type FINAL_UNIT_REDIRECTION_KEYS = 'IPV4' | 'IPV6' | 'URL' | 'SIP_URI';
// eslint-disable-next-line
export const FINAL_UNIT_REDIRECTION_TYPES_MAP: Readonly<{ [T in FINAL_UNIT_REDIRECTION_KEYS]: MapperObject }> = {
    IPV4: {
        id: 0,
        label: 'IPv4',
    },
    IPV6: {
        id: 1,
        label: 'IPv6',
    },
    URL: {
        id: 2,
        label: 'URL',
    },
    SIP_URI: {
        id: 3,
        label: 'SIP URI',
    },
};

const MAX_QUOTA: Readonly<number> = 1024 * 1024 * 1024;

// Maximum allowed validity time in seconds. Currently the CM does not support values larger than 12 hours.
const MAX_DURATION_HOURS_VALIDITY_AND_HOLDING: Readonly<number> = 12;
const MAX_DURATION_SECONDS_VALIDITY_AND_HOLDING: Readonly<number> = MAX_DURATION_HOURS_VALIDITY_AND_HOLDING * 60 * 60;

export default Vue.extend({
    name: 'RatingGroupEditor',
    components: {
        AbstractEditPageWrapper,
        AppButton,
        AppHeader,
        AppInputV3,
        AppMultiselectV3,
        AppToggle,
        UnitPicker,
        MutationDialog,
        EditorButtons,
        AppLabel,
        Draggable,
        ConditionsExpressionEditor,
        AppIcon,
        EntityStatusIndicator,
        AppTextareaV3,
        ChargingTagLabel,
    },
    mixins: [validationMixin, entityEditorMixin, mutationDialogMixin, ChargingCommonActionsMixin],
    data() {
        return {
            PLURALIZATION,
            TOOLTIP_POSITION,
            ICON_TYPES,
            BUTTON_TYPES,
            LABEL_COLOR,
            EDITOR_MODE,
            STATUS_CODES,
            finalUnitActionsList: Object.values(FINAL_UNIT_ACTIONS_MAP) as MapperObject[],
            finalUnitRedirectionsList: Object.values(FINAL_UNIT_REDIRECTION_TYPES_MAP) as MapperObject[],
            MAX_DURATION_HOURS_VALIDITY_AND_HOLDING,
            MAX_DURATION_SECONDS_VALIDITY_AND_HOLDING,
            version: null as number | null,
            name: '' as string | Record<string, string>,
            remark: '' as string,
            number: null as number | null,
            priority: null as number | null,
            thresholdPercentage: null as number | null,
            quotaAmount: (1024 * 1024) as number,
            holdingTime: (60 * 60) as number,
            validityTime: (60 * 60) as number,
            finalUnitAction: 1 as number,
            redirectionType: FINAL_UNIT_REDIRECTION_TYPES_MAP.IPV4.id as number,
            redirectionAddr: '' as string,
            rules: [] as RatingGroupRule[],
            enableDynamicValidityTime: false as boolean,
            data: {} as any,
            approveOnCreate: false as boolean,
            entityType: ENTITY_TYPES.RATING_GROUP as string,
            selectedLanguage: '' as string,
            entityId: null as string | null,
            readonly: false as boolean,
            updateName: this.$i18n.t('generic.N/A') as string,
            entityDraft: null as any,
            isUnpublished: false as boolean,
            isOnlyDraft: false as boolean,
            updateTime: null as number | null,
            revertConfirmationButton: new Button({
                label: this.$i18n.t('productCatalog.editors.revert'),
                alertType: ALERT_TYPES.warning,
                handler: () => this.initData(true),
            }),
            versionsHistory: [] as ChargingEntityVersion[],
            currentVersion: null as string | null,
            state: EntityStateMapping.UNAPPROVED,
            CHARGING_ENTITY_TYPES,
            RouteNames,
        };
    },
    validations: {
        name: {
            required,
        },
        number: {
            required,
            integer,
            minValue: minValue(1),
        },
        quotaAmount: {
            required,
            integer,
            between: between(0, MAX_QUOTA),
        },
        thresholdPercentage: {
            required,
            integer,
            between: between(1, 100),
        },
        validityTime: {
            required,
            integer,
            between: between(0, MAX_DURATION_SECONDS_VALIDITY_AND_HOLDING),
        },
        holdingTime: {
            required,
            integer,
            between: between(0, MAX_DURATION_SECONDS_VALIDITY_AND_HOLDING),
        },
        redirectionType: {
            required: requiredIf(function isRequired() {
                return this.isFinalUnitActionRedirection;
            }),
        },
        redirectionAddr: {
            required: requiredIf(function isRequired() {
                return this.isFinalUnitActionRedirection;
            }),
        },
        rules: {
            $each: {
                quotaAmount: {
                    minValue: minValue(1),
                    required: requiredIf(function isRequired() {
                        return this.rules.length;
                    }),
                },
            },
        },
    },
    computed: {
        ...mapGetters(Modules.charging, [Getters.GET_APPROVED_CONDITION_PARAMETERS]),
        ...mapGetters(Modules.chargingV2, [
            Getters.GET_RATING_GROUP_BY_ID,
            Getters.GET_RATING_GROUP_BY_VERSION,
            Getters.GET_APPROVED_RATING_GROUPS,
        ]),
        ...mapGetters('operators', [Getters.languageDefault]),
        redirectionTypeData: {
            set(actionObj: RatingGroup): void {
                if (actionObj !== null) {
                    this.redirectionType = actionObj.id;
                }
            },
            get(): MapperObject {
                return this.finalUnitRedirectionsList.find((x: MapperObject) => x.id === this.redirectionType);
            },
        },
        affectedEntities(): object {
            return getAffectedEntities(this.$attrs.id, ENTITY_TYPES.RATING_GROUP);
        },
        isFinalUnitActionRedirection(): boolean {
            return this.finalUnitAction === FINAL_UNIT_ACTIONS_MAP.REDIRECTION.id;
        },
        isDraft() {
            return this.entityId && !this.readonly && this.entityDraft;
        },
        isPublished() {
            return this.entityId && this.readonly;
        },
        allowEditDraftBtn() {
            return this.isPublished && !this.isOnlyDraft;
        },
        isRevertAvailable() {
            return this.isEditing && !this.readonly && !this.isOnlyDraft;
        },
        allowViewPublishedBtn() {
            return this.entityId && !this.readonly && !this.isOnlyDraft;
        },
        ratingGroupData(): RatingGroup {
            return this[Getters.GET_RATING_GROUP_BY_ID](this.entityId) || {};
        },
        ratingGroupByVersion(): RatingGroup | null {
            return this[Getters.GET_RATING_GROUP_BY_VERSION];
        },
        ratingGroups(): RatingGroup[] {
            return this[Getters.GET_APPROVED_RATING_GROUPS] || [];
        },
        allowConditionParametersTypes() {
            return [
                CONDITION_TYPES.APN,
                CONDITION_TYPES.BEARER,
                CONDITION_TYPES.SUBSCRIBER_LOCATION,
                CONDITION_TYPES.BUCKET_TYPE,
                CONDITION_TYPES.DATA_BALANCE,
            ];
        },
        getConditionParametersByAllowedTypes() {
            return this[Getters.GET_APPROVED_CONDITION_PARAMETERS]
                .filter((condition: any) => this.allowConditionParametersTypes.includes(condition.conditionType))
                .sort((entity1: any, entity2: any) => entity1.name.localeCompare(entity2.name))
                .map((condition: any) => ({
                    conditionUUID: condition.id,
                    label: condition.name,
                    type: condition.conditionParameter.type,
                }));
        },
        pageTitle(): string {
            if (this.readonly) {
                return this.$i18n.t('charging.ratingGroups.ratingGroup');
            }
            if (this.entityId) {
                return this.$i18n.t('charging.ratingGroups.editNewRg');
            }
            return this.$i18n.t('charging.ratingGroups.addNewRg');
        },
        showStatusIndicator(): boolean {
            return Boolean(this.entityId && this.state !== STATUS_CODES.NA);
        },
        quotaAmountErrorMsg(): string {
            return this.quotaAmount === null
                ? this.$i18n.t('generic.validations.fieldIsRequired')
                : this.$i18n.t('productCatalog.ratingGroups.quotaAmountMaxInput');
        },
        isEditingEntityExisting(): boolean {
            return Boolean(this.entityId && !this.$route.params.clone);
        },
    },
    created(): void {
        this.$withLoadingSpinner(
            async () => {
                const { id, readonly, mode, clone } = this.$route.params;

                this.entityId = id;

                if (this.entityId && readonly) {
                    this.readonly = readonly;
                }

                if (this.entityId && mode) {
                    this.readonly = mode === EDITOR_MODE.VIEW;
                }

                const promises = [this[Actions.REQUEST_RATING_GROUPS](), this[Actions.REQUEST_CONDITION_PARAMETERS]()];

                if (!clone && !this.readonly) {
                    promises.push(this.loadEntityDraft());
                }

                await Promise.all(promises);
                this.selectedLanguage = (this[Getters.languageDefault] || languageMap.en.key).toString();
                this.initData();

                if (this.entityId && this.readonly) {
                    this.loadVersionHistory(CHARGING_ENTITY_TYPES.RATING_GROUP, this.entityId);
                }
            },
            {
                errorHandler: () => {
                    this.$eventBus.$emit('showAlert', {
                        message: this.$i18n.t('alertMessage.failedToLoadNecessaryData'),
                    });
                },
            },
        );
    },
    methods: {
        ...mapActions(Modules.charging, [Actions.REQUEST_CONDITION_PARAMETERS]),
        ...mapActions(Modules.chargingV2, [Actions.REQUEST_RATING_GROUPS, Actions.REQUEST_RATING_GROUP_BY_VERSION]),
        async onSave(isPublish = true) {
            if (isPublish && !this.validateInputs()) {
                return;
            }

            const data = cloneDeep({
                name: this.name,
                remark: this.remark,
                priority: this.priority,
                number: this.number,
                quotaAmount: this.quotaAmount,
                thresholdPercentage: this.thresholdPercentage,
                holdingTime: this.holdingTime,
                validityTime: this.validityTime,
                finalUnitAction: this.finalUnitAction,
                redirectionType: this.redirectionType,
                redirectionAddr: this.redirectionAddr,
                rules: this.rules || [],
                enableDynamicValidityTime: this.enableDynamicValidityTime,
            });

            this.data = payloadData(data);

            if (!this.isFinalUnitActionRedirection) {
                this.$delete(this.data, 'redirection_type');
                this.$delete(this.data, 'redirection_addr');
            }

            await this.saveEntityData(this.isOnlyDraft, [], isPublish);
        },
        async initData(forceInitPublished = false, initVersion = true): Promise<void> {
            if (this.entityId) {
                const rg = this.ratingGroupData;
                let rgData: any;

                if (initVersion) {
                    this.currentVersion = rg?.chargingVersion || null;
                }

                if (this.entityDraft && !forceInitPublished) {
                    const draftData = new RatingGroupDraft({
                        ...this.entityDraft,
                        ...this.entityDraft.data,
                    });

                    draftData.version = rg.version;
                    rgData = draftData;
                    this.isOnlyDraft = rg?.state === STATUS_CODES.NA;

                    if (rg?.update_time <= rgData?.update_time) {
                        this.isUnpublished = true;
                    }
                } else if (this.currentVersion) {
                    await this.loadHistoryEntity(this.entityId, this.currentVersion);
                    rgData = cloneDeep(this.ratingGroupByVersion);
                } else {
                    rgData = cloneDeep(rg);
                }

                const {
                    name,
                    number,
                    remark,
                    priority,
                    version,
                    quotaAmount,
                    thresholdPercentage,
                    holdingTime,
                    validityTime,
                    finalUnitAction,
                    redirectionType,
                    redirectionAddr,
                    updatePortalId,
                    update_time: updateTime,
                    rules,
                    state,
                    enableDynamicValidityTime,
                } = rgData;

                this.name = name;
                this.remark = remark;
                this.number = number;
                this.priority = priority;
                this.quotaAmount = quotaAmount;
                this.thresholdPercentage = thresholdPercentage;
                this.holdingTime = holdingTime;
                this.validityTime = validityTime;
                this.finalUnitAction = finalUnitAction;
                this.redirectionType = redirectionType;
                this.redirectionAddr = redirectionAddr;
                this.version = version;
                this.updateTime = updateTime;
                this.rules = rules;
                this.state = state;
                this.enableDynamicValidityTime = enableDynamicValidityTime;
                this.getUpdateUserName(updatePortalId);
            }

            if (this.$route.params.clone) {
                this.name += ' (cloned)';
            }
        },
        validateInputs(): boolean {
            this.$v.$touch();
            if (this.$v.$invalid) {
                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('alertMessage.pleaseFixValidation'),
                });
                this.addAlertWatcher('$data');
                return false;
            }

            if (this.dataAlreadyInUse('name')) {
                this.$showErrorAlert({
                    message: this.$i18n.t('charging.propertyMustBeUnique', {
                        property: this.$i18n.t('generic.name'),
                        entity: this.$i18n.t('charging.ratingGroups.ratingGroup'),
                    }),
                });

                return false;
            }

            if (this.dataAlreadyInUse('number')) {
                this.$showErrorAlert({
                    message: this.$i18n.t('charging.propertyMustBeUnique', {
                        property: this.$i18n.t('generic.number'),
                        entity: this.$i18n.t('charging.ratingGroups.ratingGroup'),
                    }),
                });
                return false;
            }

            return true;
        },
        getAffectedEntities(): object {
            return formatMutationDialogEntities(this.affectedEntities);
        },
        async loadEntityDraft() {
            if (this.entityId) {
                try {
                    if (!this.readonly) {
                        const result = await getRatingGroupEntityDraft(this.entityId);
                        this.entityDraft = result?.data?.data?.[this.entityId] || null;
                    }
                } catch (e) {
                    this.$alert(this.$i18n.t('alertMessage.failedToLoadNecessaryData'));
                }
            }
        },
        async getUpdateUserName(id: number): Promise<void> {
            try {
                if (id) {
                    const response = await getUserNameById(Number(id));
                    if (response?.data) {
                        this.updateName = response.data;
                    }
                }
            } catch (e) {
                Sentry.captureException(e);
            }
        },
        resetToPublished(): void {
            this.$alert(this.$i18n.t('productCatalog.editors.revertWarning'), {
                type: ALERT_TYPES.warning,
                buttons: [this.revertConfirmationButton],
            });
        },
        reloadEditor(mode: string) {
            const { id, companyId } = this.$route.params;
            // Use push to list page because router don`t want ot reload same page
            this.$router
                .push({
                    name: RouteNames.CHARGING_RATING_GROUPS,
                    params: { companyId },
                })
                .then(() => {
                    this.$router.push({
                        name: RouteNames.CHARGING_RATING_GROUP_EDITOR,
                        params: {
                            id,
                            mode,
                            companyId,
                        },
                    });
                });
        },
        removeRule(index: number): void {
            this.rules.splice(index, 1);
        },
        createNewRule() {
            const { max } = Math;
            const biggestIdentifier = this.rules.length
                ? max.apply(
                      Math,
                      this.rules.map(rule => rule?.identifier),
                  )
                : 0;
            this.rules.push({
                quotaAmount: 0,
                conditionExpression: [],
                identifier: biggestIdentifier + 1,
            });
        },
        ruleSectionLable(role: RatingGroupRule): string {
            return `${this.$i18n.tc('charging.ratingGroups.rule', PLURALIZATION.SINGULAR)} ${role.identifier}`;
        },
        doesRuleQuotaAmountHaveError(index: number): boolean {
            return this.$v?.rules?.$each?.[index]?.quotaAmount?.$error;
        },
        async loadVersionHistory(entityType: CHARGING_ENTITY_TYPES, id: string): Promise<void> {
            this.versionsHistory = await loadVersionHistory(entityType, id);
            const [latestVersion] = this.versionsHistory;
            this.currentVersion = latestVersion?.chargingVersion || null;
        },
        selectVersion(entry: ChargingEntityVersion) {
            if (this.currentVersion !== entry?.chargingVersion) {
                this.currentVersion = entry?.chargingVersion;
                this.initData(false, false);
            }
        },
        async loadHistoryEntity(id: string, version: string): Promise<void> {
            if (id) {
                await this.$withProgressBar(
                    async () => {
                        await this[Actions.REQUEST_RATING_GROUP_BY_VERSION]({
                            id,
                            version,
                        });
                    },
                    {
                        errorHandler: () => {
                            this.$alert(this.$i18n.t('alertMessage.failedToLoadNecessaryData'), {
                                type: ALERT_TYPES.error,
                            });
                        },
                    },
                );
            }
        },
        openLowerEntitiData(entry: LowerEntitiesVersions, routeName: string): void {
            this.$router.push({
                name: routeName,
                params: {
                    companyId: this.$route.params.companyId,
                    id: entry.id,
                    readonly: true,
                    chargingVersion: entry.chargingVersion,
                },
            });
        },
        dataAlreadyInUse(key: string): boolean {
            if (this.isEditingEntityExisting) {
                if (this?.[key] !== this.ratingGroupData?.[key]) {
                    return Object.values(this.ratingGroups).some((rg: any) => rg?.[key] === this?.[key]);
                }
                return false;
            }
            return Object.values(this.ratingGroups).some((rg: any) => rg?.[key] === this?.[key]);
        },
    },
});
