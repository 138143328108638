import HTTP from '@/http';
import { StaticFilterGetSignedUrlParams } from '@/__new__/services/dno/segments/models/StaticFilter';

export function getSignedURL(data: StaticFilterGetSignedUrlParams) {
    return HTTP({
        method: 'POST',
        url: '/v3staticfilterwrite/signedurl',
        headers: {
            'Content-type': 'application/json',
        },
        data,
    });
}

export function getRegisteredEntities(page: number, size: number) {
    return HTTP({
        method: 'GET',
        url: '/v3staticfilterwrite/registeredentities',
        headers: {
            'Content-type': 'application/json',
        },
        params: { page, size },
    });
}

export function getEntityDetailsByName(name: string) {
    return HTTP({
        method: 'GET',
        url: '/v3staticfilterwrite/entitydetailsbyname',
        headers: {
            'Content-type': 'application/json',
        },
        params: { name },
    });
}

export function getEntityDetailsByCategory(name: string, category: string, page: number, size: number) {
    return HTTP({
        method: 'GET',
        url: '/v3staticfilterwrite/entitydetailsbycategory',
        headers: {
            'Content-type': 'application/json',
        },
        params: {
            name,
            category,
            page,
            size,
        },
    });
}

export function streamStaticFilterEntries(id: string, max_results: number) {
    return HTTP.post('/cepdew/segment/api/v3/segments/stream_static_filter_entries', {
        data: {
            id,
            page_size: 250,
            max_results,
        },
    });
}

export default {
    getSignedURL,
    getRegisteredEntities,
    getEntityDetailsByName,
    getEntityDetailsByCategory,
    streamStaticFilterEntries,
};
