import DataflowJob, { type DataflowJobResponse } from '@/__new__/services/dno/dataflows/models/DataflowJob';
import type { DataflowTemplate } from '@/__new__/features/dataflows/common/DataflowInterfaces';
import type { DnoResponseBase } from '@/http';

type DataflowResponse = {
    id: string;
    name: string;
    entities: DataflowJobResponse[];
};

export interface GetDataflowsResponse extends DnoResponseBase {
    data: {
        last_updated_at: number;
        dataflows: DataflowResponse[];
    };
}

export default class Dataflow {
    id: string;
    name: string;
    jobs: DataflowJob[];
    data: Partial<DataflowResponse>;

    constructor(data: Partial<Dataflow> = {}) {
        this.id = data.id || '';
        this.name = data.name || '';
        this.jobs = data.jobs || [];
        this.data = data.data || {};
    }

    get templates(): Array<DataflowTemplate['name'] | DataflowTemplate['id']> {
        return Array.from(new Set(this.jobs.map(j => j.blueprint?.name || j.blueprintId)));
    }

    static mapFromResponse(data: DataflowResponse) {
        const { id, name, entities } = data;
        const jobs = entities.map(DataflowJob.mapFromResponse);

        return new Dataflow({
            id,
            name,
            jobs,
            data,
        });
    }
}
