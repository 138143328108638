
// Сomponents
import AppTable from '@/components/partials/AppTable.vue';
import IconButton from '@/components/partials/IconButton.vue';
import AppAditionalSidebar from '@/components/partials/AppAditionalSidebar.vue';
import ResponseModalButton from '@/components/partials/ResponseModalButton.vue';

// Рelpers
import { getAppointmentStateLabel } from '@/__new__/features/customerCare/common/appointmentsHelper';
import { ICON_TYPES } from '@/common/iconHelper';

/* eslint-disable camelcase */
export type AppointmentNote = {
    type: string;
    text?: string;
    created_on?: string;
    created_by?: string;
};

export type Appointment = {
    appointment_id: string;
    activity_type: string;
    closure_notes: string;
    time_slot: {
        start_time: string;
        end_time: string;
        time_slot_id?: string;
    };
    state: number;
    notes?: AppointmentNote[];
    properties?: any;
};
/* eslint-enable camelcase */

export default {
    name: 'AppointmentNotes',
    components: {
        AppTable,
        IconButton,
        AppAditionalSidebar,
        ResponseModalButton,
    },
    props: {
        accountId: {
            required: true,
            type: String,
        },
        appointmentsInfo: {
            type: Object,
            default: null,
        },
        apiResponse: {
            type: Object,
            default: null,
        },
    },
    data() {
        return {
            selectedNote: {},
            isSidebarVisible: false,
            isLoading: false,
            ICON_TYPES,
        };
    },
    computed: {
        columns() {
            return [
                {
                    name: this.$i18n.t('customerCare.appointmentsSection.appointmentType'),
                    key: 'activityType',
                },
                {
                    name: this.$i18n.t('generic.dateAndTime'),
                    key: 'datetime',
                },
                {
                    name: this.$i18n.t('generic.status'),
                    key: 'status',
                },
            ];
        },
        nestedColumns() {
            return [
                {
                    name: this.$i18n.t('appointments.noteType'),
                    key: 'type',
                },
                {
                    name: this.$i18n.t('appointments.noteText'),
                    key: 'text',
                },
                {
                    name: this.$i18n.t('appointments.noteDateAndTime'),
                    key: 'createdOn',
                },
                {
                    name: this.$i18n.t('appointments.noteCreatedBy'),
                    key: 'createdBy',
                },
            ];
        },
        columnsData() {
            return [...this.columns, ...this.nestedColumns];
        },
        appointments() {
            return Array.isArray(this.appointmentsInfo?.data?.appointments)
                ? this.formatAppointments(this.appointmentsInfo?.data?.appointments)
                : [];
        },
    },
    methods: {
        formatAppointments(appointments: Appointment[]) {
            this.isLoading = false;
            return appointments.map((item: Appointment): object => ({
                activityType: item.activity_type,
                datetime: this.$localeLibrary.getFormattedDateAndTime(item.time_slot.end_time),
                status: this.$i18n.t(getAppointmentStateLabel(item.state)),
                nestedRows: item.notes?.length ? this.mapNotes(item.notes) : [],
            }));
        },
        mapNotes(notes: AppointmentNote[]): {
            type: string;
            text?: string;
            createdOn?: string;
            createdBy?: string;
        }[] {
            return notes.map(({ type, text, created_on: createdOn, created_by: createdBy }) => ({
                type,
                text,
                createdOn: this.$localeLibrary.getFormattedDateAndTime(createdOn),
                createdBy,
            }));
        },
        fetchAppointments(): void {
            this.isLoading = true;
            this.$emit('fetchAppointments');
        },
        showSidebar(): void {
            this.isSidebarVisible = true;
        },
        selectedRow(entity: object): void {
            this.selectedNote = entity;
        },
    },
};
