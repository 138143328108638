import { AxiosPromise } from 'axios';
import http from '@/http/index';

export function getInvoicesForBillingAccount(billingAccountId: number, paidOnly?: boolean): AxiosPromise<any> {
    return http({
        method: 'GET',
        url: `/invoice?billingAccountId=${billingAccountId}&paidOnly=${paidOnly}`,
        headers: {
            'Content-type': 'application/json',
        },
    });
}

export function updateInvoice(id: number, payload: any): AxiosPromise<any> {
    return http({
        method: 'PATCH',
        url: `/invoice/${id}`,
        data: payload,
    });
}

export function getInvoiceById(invoiceId: number): AxiosPromise<any> {
    return http({
        method: 'GET',
        url: `/invoice/${invoiceId}`,
        headers: {
            'Content-type': 'application/json',
        },
    });
}
