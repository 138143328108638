import { AxiosPromise } from 'axios';
import http, { type DnoResponseBase } from '@/http/index';
import {
    DataflowTemplatesApiResponse,
    DataflowTemplateUiModelApiResponse,
    DataflowValidationApiResponse,
} from '@/__new__/features/dataflows/common/DataflowInterfaces';
import { type SharedLocationResponse } from '@/__new__/services/dno/dataflows/models/SharedLocation';

type DataflowDataPayload = {
    name: string;
    ui_input: Record<string, string>;
};

interface GetSharedLocationsResponse extends DnoResponseBase {
    data: SharedLocationResponse[];
}

/**
 * Get all dataflow templates
 *
 * @returns {AxiosPromise}
 */
export function getDataflowTemplates(): AxiosPromise<DataflowTemplatesApiResponse> {
    return http({
        method: 'GET',
        url: '/v3dataflow/dataflowtemplates',
    });
}

/**
 * Get dataflow template ui model
 *
 * @returns {AxiosPromise}
 */
export function getDataflowTemplateUiModel(blueprintId: string): AxiosPromise<DataflowTemplateUiModelApiResponse> {
    return http({
        method: 'GET',
        url: '/v3dataflow/dataflowtemplateuimodel',
        params: {
            blueprint_id: blueprintId,
        },
    });
}

export function getSharedLocations(): AxiosPromise<GetSharedLocationsResponse> {
    return http('/v3dataflow/getsharedlocations');
}

/**
 * Validate dataflow
 *
 * @returns {AxiosPromise}
 */
export function dataflowValidation(
    blueprintId: string,
    dataflowId: string,
    data: DataflowDataPayload,
): AxiosPromise<DataflowValidationApiResponse> {
    return http({
        method: 'POST',
        url: '/v3dataflow/validationdataflowtemplate',
        params: {
            blueprint_id: blueprintId,
            dataflow_id: dataflowId,
        },
        data,
    });
}

/**
 * Create dataflow
 *
 * @returns {AxiosPromise}
 */
export function createDataflow(
    blueprintId: string,
    dataflowId: string,
    data: Record<string, string>,
): AxiosPromise<DnoResponseBase> {
    return http({
        method: 'POST',
        url: '/v3dataflow/createdataflowtemplate',
        params: {
            blueprint_id: blueprintId,
            dataflow_id: dataflowId,
        },
        data,
    });
}

export default {
    getDataflowTemplates,
    getDataflowTemplateUiModel,
    getSharedLocations,
    dataflowValidation,
    createDataflow,
};
