
// components
import AbstractTableTile from '@/__new__/features/customerCareSuite/components/AbstractTableTile.vue';

// helpers
import { USER_MANAGER_HIERARCHY } from '@/__new__/features/customerCare/common/customerCareHelper';
import tableColumnType from '@/common/filterTable';

// HTTP
import customerCareHTTP from '@/__new__/services/dno/user/http/customer-care';

export default {
    name: 'RefereesTile',
    components: {
        AbstractTableTile,
    },
    data() {
        return {
            entities: [],
            columnsData: [
                {
                    name: this.$i18n.t('customerCare.userInformation.firstName'),
                    key: 'name',
                    field: 'name',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('generic.timestamp'),
                    key: 'referredTimestamp',
                    mapper: entity => this.$localeLibrary.getFormattedDateAndTime(entity.referred_timestamp),
                    sortBy: entity => entity.referred_timestamp,
                    field: 'referred_timestamp',
                    filterType: tableColumnType.DATE,
                },
            ],
            rawResponse: {},
        };
    },
    created() {
        this.fetchTileData();
    },
    methods: {
        fetchTileData() {
            this.$withProgressBar(
                async () => {
                    this.$emit('isDataLoadingUpd', true);
                    this.rawResponse = await customerCareHTTP.getReferralHistory(
                        this.$route.params.id,
                        USER_MANAGER_HIERARCHY.ACCOUNT,
                    );
                    this.entities = this.rawResponse?.data?.referral_history || [];
                    this.$emit('isDataLoadingUpd', false);
                },
                {
                    errorHandler: e => {
                        this.rawResponse = e;
                        this.$alert(this.$i18n.t('alertMessage.somethingWentWrongFetchingNecessaryData'));
                        this.$emit('isDataLoadingUpd', false);
                    },
                },
            );
        },
    },
};
