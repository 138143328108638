<script>
import { mapActions, mapGetters } from 'vuex';

import Actions, { Getters } from '@/store/mutation-types';
import ENTITY_TYPES from '@/common/entities/entityTypes';
import { Modules } from '@/store/store';
import { isEmpty } from 'lodash';

export default {
    name: 'EntityFetcherMixin',
    computed: {
        ...mapGetters(Modules.segments, {
            segmentsById: Getters.CACHED_SEGMENTS_BY_ID,
        }),
    },
    methods: {
        ...mapActions('productcatalog', {
            requestTemplatesByType: Actions.PC_REQUEST_TEMPLATES_BY_TYPE_AND_IDS,
            requestEntitiesByType: Actions.PC_REQUEST_ENTITIES_BY_TYPE_AND_IDS,
        }),
        ...mapActions(Modules.segments, [Actions.FETCH_SEGMENTS]),

        async fetchEntities(entityType) {
            const requests = [this.requestEntitiesByType({ entityType })];

            if (entityType !== ENTITY_TYPES.FACET) {
                requests.push(this.requestTemplatesByType({ entityType }));
            }
            if (entityType === ENTITY_TYPES.OFFER && isEmpty(this.segmentsById)) {
                requests.push(this[Actions.FETCH_SEGMENTS]());
            }

            await this.$withProgressBar(() => Promise.all(requests), {
                errorHandler: () => this.$alert(this.$i18n.t('alertMessage.failedToLoadNecessaryData')),
            });
        },
    },
};
</script>
