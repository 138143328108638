import { getUserNameById } from '@/__new__/services/portal/profile/http/profile';
import localeLibrary from '@/common/locale/localeLibrary';

export const EDITOR_MODE = {
    EDIT: 'edit',
    VIEW: 'view',
};

export default {
    methods: {
        loadData() {
            this.$withLoadingSpinner(
                async () => {
                    const { id, mode } = this.$route.params;
                    this.readonly = mode === EDITOR_MODE.VIEW;
                    // necessary API calls for all entity types
                    const promises = [];
                    const basePromises = this.collectBaseDataPromises();
                    promises.push(...basePromises);

                    if (!this.$route.params.clone) {
                        promises.push(this.loadEntityDraft(this.entityType, id));
                    }

                    // API call if children entities are available for current entity type
                    // excl same entity type to editor since it's already loaded
                    if (this.childEntitiesAvailable) {
                        const childPromises = this.collectChildEntitiesPromises();
                        promises.push(...childPromises);
                    }
                    await Promise.all(promises);
                    if (id) {
                        this.initData();
                        if (this.readonly) {
                            this.loadVersionHistory(this.entityType, id);
                        }
                    }
                },
                {
                    errorHandler: () => {
                        this.$alert(
                            this.$i18n.t('alertMessage.pc.redirectingToListPage', {
                                entityName: this.entityTypeString.toLowerCase(),
                            }),
                        );
                        setTimeout(this.routeToListPage, 2000);
                    },
                },
            );
        },
        async loadVersionHistory(entityType, id) {
            const defaultProperties = ['state'];
            this.versionsHistory = await this.loadVersionHistoryHelper(entityType, id, defaultProperties.join(','));
            [this.currentVersion] = this.versionsHistory;
        },
        async loadVersionHistoryHelper(entityType, id, properties, includeUsernames = true) {
            const result = await this.collectHistoryMetadataPromises(id, entityType, properties);
            const versionMetadata = result?.data?.entity_version_metadata ?? [];
            const userIds = [...new Set(versionMetadata.map(data => data.portal_id))];
            let userIdToName;
            if (includeUsernames) {
                userIdToName = await this.getUpdateUserNames(userIds);
            }
            return versionMetadata.map(el => {
                const id = el.portal_id;
                let authorUser = '';
                if (includeUsernames) {
                    authorUser = userIdToName[id];
                }
                const versionStr = `${this.$i18n.t('generic.version')} ${el.version}`;
                const authorStr = id ? authorUser : this.$i18n.t('generic.modifiedByApi');
                const dateStr = `${localeLibrary.getFormattedDateAndTime(el.update_time)}`;
                // Build label string:
                // Note: Filter will remove authorStr if it's undefined
                const labelContents = [versionStr, authorStr, dateStr].filter(str => str);
                const label = labelContents.join(' - ');
                return {
                    ...el,
                    label,
                    publishMessage: el.publish_message?.trim(),
                };
            });
        },
        async getUpdateUserNames(userIds) {
            const promises = userIds.map(id => getUserNameById(Number(id)));
            const names = (await Promise.allSettled(promises)).map(res => res.value?.data);
            return Object.fromEntries(userIds.map((id, index) => [id, names[index]]));
        },
    },
};
