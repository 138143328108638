import i18n from '@/i18n';
import store from '@/store/store';
import { State } from '@/store/mutation-types';

import orderService from '@/__new__/services/dno/orders/http/orders';

import localeLibrary from '@/common/locale/localeLibrary';
import { formatDataAmount } from '@/common/formatting';
import currencyTypes from '@/__new__/features/pc/common/currencyTypes';
import {
    getOverviewForGeneralProperties,
    getOverviewForRelationships,
    getOverviewForTemplateProperties,
    fetchTemplate,
    toDisplayValue,
} from '@/__new__/features/pc/common/entityOverviewUtils';
import { RULE_TYPES, RULE_EVAL_OPERATION } from '@/__new__/services/dno/pc/models/CompatibilityRuleModel';

function getOverviewForCompatibilityRules(entity) {
    if (entity.compatibilityRules?.length === 0) {
        return [];
    }

    const offersById = store.state.productcatalog[State.PC_ENTITIES_BY_TYPE].offer;

    const rulesByType = _.groupBy(entity.compatibilityRules, r => r.ruleType);
    const prereqRules = rulesByType[RULE_TYPES.REQUIRE] || [];
    const conflictRules = rulesByType[RULE_TYPES.CONFLICT] || [];

    const requireAllPrereqs =
        prereqRules.length > 0 ? prereqRules[0].ruleEvalOperation === RULE_EVAL_OPERATION.AND : true;
    const prereqOperationLabel = i18n.t(
        requireAllPrereqs
            ? 'productCatalog.compatibilityRules.needAll'
            : 'productCatalog.compatibilityRules.needAtLeastOne',
    );
    const prereqLabel = `${i18n.t('productCatalog.compatibilityRules.prerequisites')} (${prereqOperationLabel})`;

    return [
        {
            name: i18n.t('productCatalog.compatibilityRules.compatibilityRules'),
            isCollapsed: false,
            rows: [
                {
                    value: toDisplayValue(
                        prereqRules.map(r => offersById?.[r.targetId]?.name ?? r.targetId).join(', '),
                    ),
                    name: prereqLabel,
                },
                {
                    value: toDisplayValue(
                        conflictRules.map(r => offersById?.[r.targetId].name ?? r.targetId).join(', '),
                    ),
                    name: i18n.t('productCatalog.compatibilityRules.conflicts'),
                },
            ],
        },
    ];
}

async function getOverviewForOrderCounts(offerId) {
    try {
        const response = await orderService.getOrderCounts([offerId]);

        return [
            {
                name: i18n.t('productCatalog.operationalInformation'),
                isCollapsed: false,
                rows: [
                    {
                        value: response.data.counts[0]?.overall,
                        name: i18n.t('productCatalog.orderCount'),
                    },
                ],
            },
        ];
    } catch (e) {
        return [
            {
                name: i18n.t('productCatalog.operationalInformation'),
                isCollapsed: false,
                rows: [
                    {
                        value: i18n.t('productCatalog.orderCountError'),
                        name: i18n.t('generic.error'),
                        isError: true,
                    },
                ],
            },
        ];
    }
}

export async function overviewContentBuilder(entityType, offer) {
    const externalCalls = [
        fetchTemplate(entityType, offer.templateId),
        getOverviewForRelationships(offer),
        getOverviewForOrderCounts(offer.id),
    ];

    const [template, relationshipProps, orderCountsOverview] = await Promise.all(externalCalls);

    const generalProps = getOverviewForGeneralProperties(offer, template);
    generalProps.rows.push({
        value: i18n.t(`productCatalog.offers.editor.${offer.priceTypeString}`),
        name: i18n.t('generic.priceType'),
    });
    if (offer.price) {
        generalProps.rows.push({
            value:
                offer.currencyType === currencyTypes.DATA
                    ? formatDataAmount(Number(offer.price))
                    : localeLibrary.getFormattedAmount(offer.price),
            name: i18n.t('generic.price'),
        });
    }
    if (offer?.amountRange?.length) {
        offer.amountRange.forEach((amountOpt, i) => {
            generalProps.rows.push({
                value: `${amountOpt.range_start} - ${amountOpt.max ?? ''}:  ${amountOpt.amount}`,
                name: i ? '' : i18n.t('generic.price'),
            });
        });
    }
    generalProps.rows.push({
        value: offer.currencyType,
        name: i18n.t('generic.currency'),
    });

    const templateProps = getOverviewForTemplateProperties(entityType, offer, template);
    const compatibilityOverview = getOverviewForCompatibilityRules(offer);

    return [generalProps, ...relationshipProps, ...templateProps, ...compatibilityOverview, ...orderCountsOverview];
}
