<template>
    <div
        class="badge d-flex flex-column justify-content-center align-items-center"
        :class="{ disabled, usedInSegmentOverview }"
    >
        <!-- TODO: replace with IconButto w/o clicking -->
        <AppIcon
            :type="icon"
            :size="'24px'"
            class="badge__circle"
            :class="icon"
        />
        <div class="badge__number primary-text">
            {{ value }}
        </div>
        <div class="badge__text">
            {{ label }}
        </div>
    </div>
</template>

<script>
import AppIcon from '@/components/partials/icon/AppIcon.vue';

export default {
    name: 'SegmentsBadge',
    components: {
        AppIcon,
    },
    props: {
        label: { type: String, default: '' },
        value: { type: [String, Number], default: '' },
        icon: { type: String, default: '' },
        disabled: { type: Boolean, default: false },
        usedInSegmentOverview: { type: Boolean, default: false },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/_palette';
@import '~@/assets/scss/_layout';
@import '~@/assets/scss/_palette';

.badge {
    width: 12.5rem;
    height: 7.5rem;
    background: $blue15;
    border-radius: 0.5rem;

    &.disabled {
        pointer-events: none;
        opacity: 0.3;
    }
    &__circle {
        width: 2.5rem;
        height: 2.5rem;
        border-radius: 50%;
        position: relative;
        &:before {
            content: '';
            display: block;
            width: 2.5rem;
            height: 2.5rem;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }
    &__number {
        margin-top: $spacing-xs;
        margin-bottom: $spacing-xs;
    }
    &__text {
        font-size: 0.625rem;
        font-weight: bold;
        line-height: 1.5;
        text-align: center;
        color: $steel;
        text-transform: uppercase;
    }
}
.usedInSegmentOverview {
    .badge {
        &__circle {
            width: 2rem;
            height: 2rem;
        }
        &__number {
            margin-top: $spacing-xxs;
            margin-bottom: $spacing-xxs;
            font-size: 0.625rem;
        }
        &__text {
            font-size: 0.5rem;
        }
    }
}
</style>
