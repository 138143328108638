import isEmpty from 'lodash/isEmpty';

export default class NcsBaseCondition {
    constructor(data) {
        this.data = data;
    }

    isDataEmpty() {
        this.dataEmpty = isEmpty(this.data);
    }

    validate() {
        this.isDataValid();
        this.isDataEmpty();
    }
}
