// store
import { type ActionContext, type Module } from 'vuex';
import { type AxiosResponse } from 'axios';
import Actions, { Getters, Mutations, State } from '@/store/mutation-types';
// https
import { getSharedLocations } from '@/__new__/services/dno/dataflows/http/dataflows';
// models
import SharedLocation from '@/__new__/services/dno/dataflows/models/SharedLocation';
// helpers
import { isEmpty } from 'lodash';

export interface SharedFileLocationStoreState {
    [State.SHARED_FILE_LOCATIONS]: SharedLocation[];
    [State.SHARED_FILE_LOCATIONS_API_RESPONSE]: AxiosResponse | null;
}

const store: Module<SharedFileLocationStoreState, any> = {
    namespaced: true,
    state: {
        [State.SHARED_FILE_LOCATIONS]: [],
        [State.SHARED_FILE_LOCATIONS_API_RESPONSE]: null,
    },
    mutations: {
        [Mutations.SET_SHARED_FILE_LOCATIONS_API_RESPONSE]: (_state, response: AxiosResponse) => {
            _state[State.SHARED_FILE_LOCATIONS_API_RESPONSE] = response;
        },
        [Mutations.SET_SHARED_FILE_LOCATIONS]: (_state, sharedLocations) => {
            _state[State.SHARED_FILE_LOCATIONS] = sharedLocations;
        },
    },
    actions: {
        async [Actions.FETCH_SHARED_FILE_LOCATIONS]({
            commit,
            state,
        }: ActionContext<SharedFileLocationStoreState, any>): Promise<void> {
            if (!isEmpty(state[State.SHARED_FILE_LOCATIONS])) return;

            try {
                // API call will fail for instances that have SFTP proxy enabled
                // but are missing it's configuration.
                const response = await getSharedLocations();
                const sharedLocations = response.data?.data?.map(SharedLocation.mapFromSharedLocation) || [];

                commit(Mutations.SET_SHARED_FILE_LOCATIONS_API_RESPONSE, response);
                commit(Mutations.SET_SHARED_FILE_LOCATIONS, sharedLocations);
            } catch (e) {
                return;
            }
        },
    },
    getters: {
        [Getters.GET_SHARED_FILE_LOCATIONS]: state => state[State.SHARED_FILE_LOCATIONS] || [],
        [Getters.GET_SHARED_FILE_LOCATIONS_API_RESPONSE]: state => state[State.SHARED_FILE_LOCATIONS_API_RESPONSE],
        [Getters.GET_GET_SHARED_FILE_LOCATION_BY_ID]: state => (id: SharedLocation['id']) =>
            state[State.SHARED_FILE_LOCATIONS].find(sl => sl.id === id),
    },
};
export default store;
