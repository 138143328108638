import { STATUS_CODES } from '@/common/commonHelper';
import { TranslateResult } from 'vue-i18n';
import { DnoResponseBase } from '@/http/index';
import i18n from '@/i18n';

export type SensitiveTransactionHistory = {
    startTime: number;
    endTime: number;
    targetId: number;
    status: STATUS_CODES;
    statusLabel: TranslateResult;
};

export type SensitiveTransactionVerificationHistory = {
    time: number;
    channel: TranslateResult;
    method: string;
    status: string;
    id: string;
    userId: string;
    portalUserId: string;
};

export interface SensitiveTransactionHistoryApiResponse extends DnoResponseBase {
    data: {
        transactions: Array<{
            channel: CHANNEL;
            start_time: number;
            status: number;
            end_time: number;
            id: string;
            misc: {
                new_msisdn: string;
                previous_msisdn: string;
            };
        }>;
    };
}

export const enum CHANNEL {
    PORTAL = 1,
    CLIENT = 2,
}

export const CHANNEL_STATES_TO_STATUS_NAME_MAP: Map<CHANNEL, TranslateResult> = new Map([
    [CHANNEL.PORTAL, i18n.t('generic.portal')],
    [CHANNEL.CLIENT, i18n.t('generic.client')],
]);

export const enum SENSITIVE_TRANSACTIOS_USE_CASE {
    FULL_INFO = 'full_info',
    CHANGE_MSISDN = 'change_msisdn',
    SWAP_SIM = 'swap_sim',
    UM_FLAGS = 'um_flags',
    GENERATE_MNP_PIN = 'generate_mnp_pin',
}

export enum SERVICE_TYPE {
    SIGN_IN = 0,
    REFRESH_TOKEN = 1,
    ALL = 2,
    CHALLENGE = 3,
    CREATE_CHALLENGE = 4,
    VALIDATE_SECURITY_QUESTION = 6,
    END_USER_AUTHORIZATION = 19,
    VERIFY_PIN = 20,
}
