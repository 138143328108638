
import AppMultiselectV3 from '@/components/partials/inputs/AppMultiselectV3.vue';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import ordersHTTP from '@/__new__/services/dno/orders/http/orders';
import { USER_MANAGER_HIERARCHY } from '@/__new__/features/customerCare/common/customerCareHelper';
import userManagementHTTP from '@/__new__/services/dno/user/http/user-management';
import customerCareHTTP from '@/__new__/services/dno/user/http/customer-care';
import { formatAmountBasedOnCurrency } from '@/common/formatting';

export default {
    name: 'BillingTile',
    components: {
        AppMultiselectV3,
        AppButton,
    },
    props: {
        userManagerHierarchy: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            amount: 0,
            currentAccId: '',
            accounts: [],

            transaction: {},

            BUTTON_TYPES,
            USER_MANAGER_HIERARCHY,
        };
    },
    created() {
        this.fetchTileData();
    },
    methods: {
        async fetchTileData() {
            try {
                this.$emit('isDataLoadingUpd', true);
                this.$Progress.start();

                if (this.userManagerHierarchy === USER_MANAGER_HIERARCHY.USER) {
                    const userInfoResponse = await userManagementHTTP.getUser(
                        this.$route.params.id,
                        USER_MANAGER_HIERARCHY.USER,
                    );
                    this.accounts = userInfoResponse.data.user_info.account_ids;
                    [this.currentAccId] = this.accounts;
                } else {
                    this.currentAccId = [this.$route.params.id];
                }

                const rawResponse = await ordersHTTP.getUnpaidBills(this.currentAccId, USER_MANAGER_HIERARCHY.ACCOUNT);
                [this.transaction] = rawResponse?.data?.unpaid_bills;

                this.amount = formatAmountBasedOnCurrency(Number(this.transaction?.bill_amount));

                this.$Progress.finish();
            } catch (error) {
                this.$alert(this.$i18n.t('alertMessage.failedToLoadNecessaryData'));
                this.$Progress.fail();
            } finally {
                this.$emit('isDataLoadingUpd', false);
            }
        },
        async onDownloadButtonClicked() {
            const billingId = this.transaction?.bill_id || '';
            this.$Progress.start();
            try {
                const response = await customerCareHTTP.getInvoice(
                    this.currentAccId,
                    USER_MANAGER_HIERARCHY.ACCOUNT,
                    billingId,
                );

                if (response?.data?.url) {
                    window.open(response.data.url, '_blank');
                }

                this.$Progress.finish();
            } catch (error) {
                this.$alert(this.$i18n.t('customerCare.billingStatementDownloadError'));
                this.$Progress.fail();
            }
        },
    },
};
