import Vue from 'vue';
import Actions, { State, Getters, Mutations } from '../mutation-types';
import { getRoles } from '@/__new__/services/portal/admin/http/role';
import { showAvailablePermissions } from '@/common/roles/roleHelper';

export default {
    namespaced: true,
    state: {
        [State.ROLE_STATE]: [],
    },
    mutations: {
        [Mutations.SET_ROLE]: (state, role) => {
            Vue.set(state, State.ROLE_STATE, role);
        },
    },
    actions: {
        async [Actions.LOAD_ROLE]({ commit }) {
            const role = await getRoles();
            commit(Mutations.SET_ROLE, role.data);
        },
    },
    getters: {
        [Getters.GET_ROLES]: state =>
            state[State.ROLE_STATE].map(role => ({
                id: role.id,
                name: role.name,
                homepage: role.homepage,
                permissions: showAvailablePermissions(role.permissions),
            })),
        [Getters.GET_ROLE_BY_ID]: state => id => state[State.ROLE_STATE].find(role => id === role.id),
    },
};
