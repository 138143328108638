<template>
    <svg
        height="1rem"
        width="24px"
        class="horizontal-svg"
    >
        <marker
            :id="`circle-${uuid}`"
            markerWidth="8"
            markerHeight="8"
            refX="0"
            refY="4"
        >
            <circle
                cx="4"
                cy="4"
                r="4"
                stroke="none"
                fill="#f1f1f1"
            />
        </marker>
        <line
            :marker-end="`url(#circle-${uuid})`"
            x1="0"
            y1="0"
            x2="16px"
            y2="0"
            style="stroke: #f1f1f1; stroke-width: 1px"
            class="horizontal-service-line"
        />
    </svg>
</template>

<script>
export default {
    name: 'AppHorizontalLine',
    props: {
        uuid: {
            type: String,
            default: '',
        },
    },
};
</script>

<style lang="scss" scoped>
.horizontal-svg {
    margin-left: -4px;
}

.horizontal-service-line {
    transform: translateY(50%);
}
</style>
