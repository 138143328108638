var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"h-100 position-relative"},[_c('div',{class:[
            'table-scroll-wrapper',
            {
                'has-entities': _vm.oeMappingsList.length > 0,
            },
        ]},[_c('div',{staticClass:"table-wrapper",class:{ 'min-width-table': _vm.isDataLoading }},[_c('div',{staticClass:"scroll-content-wrapper"},[_c('div',{staticClass:"scroll-content",style:({
                        'min-width': `${210 * _vm.oeColumnsData.length}px`,
                    })},[_c('div',{staticClass:"row"},_vm._l((_vm.oeColumnsData),function(column,index){return _c('td',{key:column.key,staticClass:"d-flex align-items-center all-caps-text justify-content-between truncate-text position-relative",class:[{ 'pr-5': index + 1 === _vm.oeColumnsData.length }, { 'pl-4': index !== 0 }],style:(_vm.getStyles(column.width))},[_vm._v(" "+_vm._s(column.name)+" ")])}),0),(_vm.oeMappingsList.length)?_vm._l((_vm.oeMappingsList),function(execution){return _c('OeExecutionsHierarchyRow',{key:execution.executionId,staticClass:"root",attrs:{"keyName":'planId',"treeList":execution,"columnsData":_vm.oeColumnsData}})}):(!_vm.isDataLoading)?[_c('div',{staticClass:"empty-table-row d-flex flex-row justify-content-center align-items-center"},[_c('img',{staticClass:"empty-table-row-icon",attrs:{"src":require("@/assets/icons/empty-table.svg")}}),_c('div',{staticClass:"empty-table-row-text"},[_vm._v(" "+_vm._s(_vm.$i18n.t('table.empty'))+" ")])])]:_vm._e()],2)])]),_c('AppSpinner',{attrs:{"isVisible":_vm.isDataLoading}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }