<template>
    <div>
        <h3 class="mb-3">{{ $i18n.t('userGuide.availableTags') }}:</h3>
        <div class="editor-input-small">
            <AppDisplayList
                :items="mappedTags"
                :verticalAlignment="true"
                @click="id => onTagClick(id)"
            />
        </div>
    </div>
</template>

<script>
// components
import AppDisplayList from '@/components/partials/AppDisplayList.vue';

// helpers
import RouteNames from '@/router/routeNames';
import { getTags } from '@/modules/userGuide/http/strapiHTTP';

export default {
    name: 'TagsPage',
    components: {
        AppDisplayList,
    },
    data() {
        return {
            tags: [],
        };
    },
    computed: {
        mappedTags() {
            return this.tags.map(tag => ({
                id: tag.id,
                text: tag.attributes.tag_name,
            }));
        },
    },
    mounted() {
        this.fetchData();
    },
    methods: {
        async fetchData() {
            const tagsResponse = await getTags();
            this.tags = tagsResponse?.data?.data;
        },
        onTagClick(id) {
            this.$router.push({
                name: RouteNames.USER_GUIDE_TAG,
                params: { id, companyId: this.$route.params.companyId },
            });
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/layout.scss';
@import '~@/assets/scss/palette.scss';
</style>
