import { render, staticRenderFns } from "./AppLabel.vue?vue&type=template&id=f49c46e2&scoped=true"
import script from "./AppLabel.vue?vue&type=script&lang=ts"
export * from "./AppLabel.vue?vue&type=script&lang=ts"
import style0 from "./AppLabel.vue?vue&type=style&index=0&id=f49c46e2&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f49c46e2",
  null
  
)

export default component.exports