<template>
    <div>
        <AbstractTableTile
            :entities="formattedEntities"
            :columnsData="columnsData"
            :apiResponse="rawResponse"
            :entityKey="'id'"
            @rowSelected="id => selectItem(id)"
        >
            <template #state="{ entity }">
                <div class="d-table">
                    <AppLabel
                        :title="entity.stateLabel"
                        :color="entity.stateColor"
                    />
                </div>
            </template>
        </AbstractTableTile>
        <AppAditionalSidebar
            v-if="showAdditionalSidebar"
            :visible="showAdditionalSidebar"
            @input="showAdditionalSidebar = false"
        >
            <template #header>
                <h2 class="heading-sm gray-600 mb-2 text-uppercase">
                    {{ $i18n.t('generic.rewardHistoryDetails') }}
                </h2>
            </template>

            <template #content>
                <dl
                    v-if="selectedEntity"
                    class="sidebar-data"
                >
                    <dt>{{ $i18n.t('generic.rewardRuleId') }}</dt>
                    <dd class="mb-0">
                        <router-link
                            :to="{
                                name: RouteNames.REWARD_EDITOR,
                                params: {
                                    id: selectedEntity.rewardRule.id,
                                },
                            }"
                            target="_blank"
                        >
                            {{ selectedEntity.rewardRule.id }}
                        </router-link>
                    </dd>

                    <dt>{{ $i18n.t('generic.rewardRuleDetails') }}</dt>
                    <dd>
                        <AppJSON
                            v-if="selectedEntity.rewardRule.data"
                            :value="selectedEntity.rewardRule.data"
                            textareaHeight="20rem"
                        />
                        <template v-else>
                            {{ $i18n.t('generic.empty') }}
                        </template>
                    </dd>

                    <dt>{{ $i18n.t('generic.payoutProps') }}</dt>
                    <dd>
                        <AppJSON
                            :value="selectedEntity.props"
                            textareaHeight="20rem"
                        />
                    </dd>

                    <template>
                        <dt>{{ $i18n.t('generic.orchestrationExecId') }}</dt>
                        <dd>
                            <router-link
                                v-if="selectedEntity.executionId && selectedEntity.executionPlan"
                                :to="{
                                    name: RouteNames.ORCHESTRATION_ENGINE_EXECUTION_DETAILS,
                                    params: {
                                        id: selectedEntity.executionPlan,
                                        execId: selectedEntity.executionId,
                                    },
                                }"
                                target="_blank"
                            >
                                {{ selectedEntity.executionId }}
                            </router-link>
                            <template v-else>
                                {{ $i18n.t('generic.none') }}
                            </template>
                        </dd>
                    </template>
                </dl>
            </template>
        </AppAditionalSidebar>
    </div>
</template>

<script>
import Vue from 'vue';

// Components
import AbstractTableTile from '@/__new__/features/customerCareSuite/components/AbstractTableTile.vue';
import AppAditionalSidebar from '@/components/partials/AppAditionalSidebar.vue';
import AppJSON from '@/components/partials/AppJSON.vue';
import AppLabel from '@/components/partials/AppLabel.vue';

// Helpers
import tableColumnType from '@/common/filterTable';
import { ICON_TYPES } from '@/common/iconHelper';
import RouteNames from '@/router/routeNames';
import {
    payoutProvidedStateColorMap,
    payoutProvidedStateMap,
    payoutTypeMap,
} from '@/modules/rewards/common/payoutsHelper';
import { USER_MANAGER_HIERARCHY } from '@/__new__/features/customerCare/common/customerCareHelper';
import RewardRule from '@/__new__/services/dno/rewards/models/RewardRule';
import PayoutRecord from '@/__new__/services/dno/rewards/models/PayoutRecord';
import permissionsService, { isUserAllowed } from '@/services/permissions/permissions.service';

// HTTP
import { getRewardHistoryV4, getRewardRulesV4 } from '@/__new__/services/dno/rewards/http/rewards';
import { getExecution } from '@/__new__/services/dno/orchestrationengine/http/orchestration-engine';

export default Vue.extend({
    name: 'RewardHistoryTile',
    components: {
        AbstractTableTile,
        AppAditionalSidebar,
        AppJSON,
        AppLabel,
    },
    props: {
        userManagerHierarchy: {
            type: Number,
            default: USER_MANAGER_HIERARCHY.USER,
        },
    },
    data() {
        return {
            payouts: [],
            rawResponse: {},
            selectedEntity: {},
            showAdditionalSidebar: false,

            ICON_TYPES,
            RouteNames,
        };
    },
    computed: {
        isOrchestrationEngineEnabled() {
            return (
                permissionsService.orchestrationEngineEnabled() &&
                isUserAllowed('OrchestrationPlansRead', 'OrchestrationPlansWrite')
            );
        },
        formattedEntities() {
            return this.payouts.map(payout => ({
                ...payout,
                rewardRuleName: payout.rewardRule.name, // Used for table filtering.
                typeLabel: this.$i18n.t(payoutTypeMap.get(payout.type)),
                stateLabel: this.$i18n.t(payoutProvidedStateMap.get(payout.state)),
                stateColor: payoutProvidedStateColorMap.get(payout.state),
            }));
        },
        columnsData() {
            return [
                {
                    name: this.$i18n.t('generic.rewardRuleName'),
                    key: 'rewardRuleName',
                    field: 'rewardRuleName',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('generic.payoutName'),
                    key: 'name',
                    field: 'name',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('generic.payoutType'),
                    key: 'typeLabel',
                    field: 'typeLabel',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('generic.time'),
                    key: 'time',
                    field: 'time',
                    filterType: tableColumnType.DATE,
                },
                {
                    name: this.$i18n.t('generic.state'),
                    key: 'state',
                    field: 'stateLabel',
                    filterType: tableColumnType.TEXT_LIMITED_OPTIONS,
                    limitedOptions: [this.$i18n.t('generic.stateMap.success'), this.$i18n.t('generic.stateMap.failed')],
                },
            ];
        },
    },
    async created() {
        await this.fetchTileData();
    },
    methods: {
        async fetchTileData() {
            this.$emit('isDataLoadingUpd', true);
            await this.fetchPayoutsHistory();
            await Promise.all([this.fetchPayoutsRewardRuleData(), this.fetchPayoutsOEPlan()]);
            this.$emit('isDataLoadingUpd', false);
        },
        getFilterLabel(filter, computedFilterValue) {
            return `${filter.column.name} ${this.$i18n.t(filter.condition.i18nLabel)} ${computedFilterValue(filter)}`;
        },
        selectItem(id) {
            this.selectedEntity = this.formattedEntities.find(el => el.id === id);
            this.showAdditionalSidebar = true;
        },
        async fetchPayoutsRewardRuleData() {
            await Promise.all(
                this.payouts.map(async payout => {
                    if (payout.rewardRuleId) {
                        const reward = await this.fetchRewardRule(payout.rewardRuleId);
                        payout.rewardRule = new RewardRule(
                            (reward && RewardRule.remapRewardRuleFromBe(reward)) || { id: payout.rewardRuleId },
                        );
                    }
                }),
            );
        },
        async fetchPayoutsOEPlan() {
            if (this.isOrchestrationEngineEnabled) {
                await Promise.all(
                    this.payouts.map(async payout => {
                        if (payout.executionId) {
                            payout.executionPlan = await this.fetchOEPlanId(payout.executionId);
                        }
                    }),
                );
            }
        },
        async fetchPayoutsHistory() {
            await this.$withProgressBar(
                async () => {
                    this.rawResponse = await getRewardHistoryV4({
                        targetId: this.$route.params.id,
                        targetType: this.userManagerHierarchy,
                    });
                    if (Array.isArray(this.rawResponse?.data?.payouts)) {
                        this.payouts = this.rawResponse.data.payouts.map(
                            payout => new PayoutRecord(PayoutRecord.remapPayoutfromBe(payout)),
                        );
                    }
                },
                {
                    errorHandler: error => {
                        this.$showErrorAlert({
                            message: this.$i18n.t(
                                'alertMessage.userManagement.somethingWentWrongFetchingRewardsHistoryData',
                            ),
                        });
                        this.rawResponse = error.response;
                    },
                },
            );
        },
        async fetchRewardRule(ruleId) {
            let rewardRule;

            await this.$withProgressBar(
                async () => {
                    const response = await getRewardRulesV4([ruleId]);
                    const { reward_rule_v4_by_id: rewardRules } = { ...response?.data };
                    rewardRule = rewardRules?.[ruleId];
                },
                {
                    errorHandler: () =>
                        this.$showErrorAlert({
                            message: this.$i18n.t(
                                'alertMessage.userManagement.somethingWentWrongFetchingRewardsHistoryRelatedData',
                            ),
                        }),
                },
            );

            return rewardRule;
        },
        async fetchOEPlanId(executionId) {
            let id;

            await this.$withProgressBar(
                async () => {
                    const response = await getExecution(executionId);
                    const { plan_id: pId } = { ...response?.data?.execution };
                    id = pId;
                },
                {
                    errorHandler: () =>
                        this.$showErrorAlert({
                            message: this.$i18n.t(
                                'alertMessage.userManagement.somethingWentWrongFetchingRewardsHistoryRelatedData',
                            ),
                        }),
                },
            );

            return id;
        },
    },
});
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/consistency-spacing';
@import '~@/assets/scss/consistency-colors';
@import '~@/assets/scss/consistency-typography';

.lf-table-title {
    color: $blue-400;
}

.sidebar-data {
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: baseline;
    gap: 0.875rem $spacing-m;
}

dt {
    font-weight: 600;
    white-space: nowrap;
}

dd {
    margin-bottom: 0;
    font-size: 0.875rem;
}
</style>
