
import { ICON_TYPES } from '@/common/iconHelper';
import { ADD_ENTRY_VIEW, validateEntry } from '@/common/StaticFilter';

import AppInputV3 from '@/components/partials/inputs/AppInputV3.vue';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import CardListRadioInput from '@/components/partials/cards/CardListRadioInput.vue';

export default {
    name: 'StaticSegmentAddEntry',
    components: {
        AppInputV3,
        AppButton,
        CardListRadioInput,
    },
    props: {
        entries: { type: Array, required: true },
        addEntryView: { type: String, required: true },
        entryType: { type: String, required: true },
    },

    data() {
        return {
            BUTTON_TYPES,
            ICON_TYPES,
            ADD_ENTRY_VIEW,
            entry: '',
        };
    },

    validations: {
        entry: {
            valid: (value: string, { entryType }: any) => validateEntry(entryType, value),
            unique: (value: string, { entries }: any) => !entries.includes(value),
        },
    },

    computed: {
        isAddViewVisible(): boolean {
            return this.addEntryView !== ADD_ENTRY_VIEW.NONE;
        },
        addButtonLabel(): string {
            return `${this.$t('generic.add')} ${this.entryType}`;
        },
        inputPlaceholder(): string {
            return `${this.$t('generic.enter')} ${this.entryType}`;
        },
        inputErrorMessage(): string {
            switch (false) {
                case this.$v.entry.valid:
                    return this.$t('segments.invalidEntry', { entry: this.entryType });
                case this.$v.entry.unique:
                    return this.$t('segments.entryAlreadyExists', { entry: this.entryType });
                default:
                    return '';
            }
        },
        addEntryInProgress() {
            return this.$Progress.get() > 0 && this.$Progress.get() < 100;
        },
    },

    methods: {
        addNewEntry() {
            this.$v.$touch();
            if (this.$v.$error || this.addEntryInProgress) return;

            this.$emit('addEntry', this.entry);
            this.entry = '';
            this.$v.$reset();
        },
    },
};
