import { isTypeString } from '@/common/utils';

export enum PROPERTY_TYPE_BE_MAP {
    string = 'string',
    number = 'integer',
    table = 'object',
}

export enum PROPERTY_TYPE_VALIDATE_FN_MAP {
    string = 'validateString',
    boolean = 'validateBoolean',
    integer = 'validateNumberInteger',
    day = 'validateDay',
    array = 'validateArray',
    object = 'validateObject',
}

export default class UserManagerBaseModel {
    invalidKeys: Map<string, any> = new Map();

    validateString(key: string, value: any) {
        const isTypeStringValid = isTypeString(value);

        if (!isTypeStringValid) {
            this.invalidKeys.set(key, value);
        }

        return isTypeStringValid;
    }

    validateBoolean(key: string, value: any) {
        const isTypeBooleanValid = typeof value == 'boolean';

        if (!isTypeBooleanValid) {
            this.invalidKeys.set(key, value);
        }

        return isTypeBooleanValid;
    }

    validateNumberInteger(key: string, value: any) {
        const isTypeInteger = Number.isInteger(value);

        if (!isTypeInteger) {
            this.invalidKeys.set(key, value);
        }

        return isTypeInteger;
    }

    validateDay(key: string, value: any) {
        const dayValid = Number.isInteger(value);

        if (!dayValid) {
            this.invalidKeys.set(key, value);
        }

        return dayValid;
    }

    validateArray(key: string, value: any) {
        const isTypeArrayValid = Array.isArray(value);

        if (!isTypeArrayValid) {
            this.invalidKeys.set(key, value);
        }

        return isTypeArrayValid;
    }

    validateObject(key: string, value: any) {
        const isTypeObjectValid = value instanceof Object;

        if (!isTypeObjectValid) {
            this.invalidKeys.set(key, value);
        }

        return isTypeObjectValid;
    }

    validateByEnum(key: string, value: any, enumerable: Record<string, number | string>) {
        let values = Object.values(enumerable);

        // If enum has numeric values
        if (values.some(Number.isInteger)) {
            values = values.filter(Number.isInteger);
        }

        const isTypeEnumValid = values.includes(value);

        if (!isTypeEnumValid) {
            this.invalidKeys.set(key, value);
        }

        return isTypeEnumValid;
    }

    validateByType(this: any, key: string, value: any, type: keyof typeof PROPERTY_TYPE_VALIDATE_FN_MAP) {
        const validateFn: string = PROPERTY_TYPE_VALIDATE_FN_MAP[type];

        const isValid = this[validateFn] instanceof Function && this[validateFn](key, value);

        return isValid;
    }
}
