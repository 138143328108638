<template>
    <div class="balance-container">
        <div
            v-if="showTitle"
            class="balance-title"
        >
            {{ $i18n.t('rewards.walletHelper.deductBalance') }}
        </div>
        <div class="balance-text">
            <div class="row">
                <AppInputV3
                    v-model="walletBalanceAmount"
                    :placeholder="WALLET_TYPES_PLACEHOLDER.get(selectedWalletType)"
                    :type="'number'"
                    :label="$i18n.t('customerCare.transactionHistory.deductAmount')"
                    :min="0"
                    :max="currentWalletBalanceAmount"
                    :step="0.01"
                    :roundNumber="true"
                    :invalid="walletBalanceAmountValidation"
                    :errorMessage="$i18n.t('rewards.walletHelper.deductBalanceAmountError')"
                    data-test-id="input-wallet-balance-amount"
                    class="col-12 section-content mb-3"
                />
            </div>
            <div
                v-if="showButtons"
                class="balance-buttons"
            >
                <AppButton
                    :label="$i18n.t('generic.cancel')"
                    data-test-id="btn-close"
                    @click="close"
                />
                <AppButton
                    :buttonType="BUTTON_TYPES.PRIMARY"
                    :label="$i18n.t('generic.update')"
                    :disabled="disabledDeductAmountBtn"
                    class="ml-5"
                    data-test-id="btn-deduct-amount"
                    @click="deductAmount"
                />
            </div>
        </div>
    </div>
</template>
<script>
// Helpers
import { ALERT_TYPES } from '@/common/alerts/Alert';
import topUpHTTP from '@/http/topUp';
import { uuidV4 } from '@/common/utils';
import * as Sentry from '@sentry/vue';
import { WALLET_TYPES, WALLET_TYPES_PLACEHOLDER } from '@/__new__/features/customerCare/common/ordersHelper';
import permissionsService from '@/services/permissions/permissions.service';
import { USER_MANAGER_HIERARCHY as userManagerHierarchyType } from '@/__new__/features/customerCare/common/customerCareHelper';

// Components
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import AppInputV3 from '@/components/partials/inputs/AppInputV3.vue';

export default {
    name: 'DeductBalanceForm',
    components: {
        AppButton,
        AppInputV3,
    },
    props: {
        selectedWalletType: {
            required: true,
            type: String,
        },
        userManagerHierarchy: {
            required: true,
            type: Number,
        },
        currentWalletBalanceAmount: {
            required: true,
            type: Number,
        },
        showTitle: {
            default: true,
            type: Boolean,
        },
        showButtons: {
            default: true,
            type: Boolean,
        },
    },
    data() {
        return {
            walletBalanceAmount: null,
            WALLET_TYPES,
            WALLET_TYPES_PLACEHOLDER,
            permissionsService,
            BUTTON_TYPES,
        };
    },
    computed: {
        walletBalanceAmountValidation() {
            return this.walletBalanceAmount > this.currentWalletBalanceAmount;
        },
        disabledDeductAmountBtn() {
            return !this.walletBalanceAmount || this.walletBalanceAmountValidation;
        },
    },
    methods: {
        uuidV4,
        close() {
            this.walletBalanceAmount = null;
            this.$emit('close');
        },
        async deductAmount() {
            try {
                this.$Progress.start();

                const { id } = this.$route.params;

                if (this.userManagerHierarchy === userManagerHierarchyType.SUBSCRIBER) {
                    await topUpHTTP.deductAmount(
                        this.walletBalanceAmount.toString(),
                        this.userManagerHierarchy,
                        id,
                        this.selectedWalletType,
                    );
                } else {
                    await topUpHTTP.deductBalanceSharedWallet(
                        this.walletBalanceAmount.toString(),
                        this.userManagerHierarchy,
                        id,
                        this.selectedWalletType,
                    );
                }

                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('messages.walletUpdated'),
                    type: ALERT_TYPES.success,
                });
                this.$Progress.finish();
                this.$emit('refreshData');
            } catch (e) {
                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('messages.updatingWalletFailed'),
                });
                Sentry.captureException(e);
                this.$Progress.fail();
            } finally {
                this.close();
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/_palette';

.balance-container {
    padding: 6% 4% 4% 4%;
}

.balance-title {
    font-size: 1.375rem;
    font-weight: 600;
    color: $gray90;
    margin-bottom: 4%;
}

.balance-text {
    font-size: 0.875rem;
    color: $gray90;
}

.balance-buttons {
    display: flex;
    justify-content: flex-end;
}
</style>
