import http from '@/http';
import { AxiosPromise } from 'axios';

type GetUploadUrlParams = {
    fileName: File['name'];
    dataflow: string;
    metadata?: {
        [key: string]: any;
    };
};

type GetUploadUrlResponse = {
    data: {
        entity_id: string;
        upload_url: string;
        required_request_headers?: Record<string, string>;
    };
};

/**
 * Gets signed url for uploading a file
 */
export function getUploadURL({
    fileName,
    dataflow,
    metadata = {},
}: GetUploadUrlParams): AxiosPromise<GetUploadUrlResponse> {
    return http({
        method: 'POST',
        url: '/v3operations/signedurl/upload',
        headers: {
            'Content-type': 'application/json',
        },
        params: {
            dataflow_id: dataflow,
        },
        data: {
            ...metadata,
            fileName,
        },
    });
}
