
import Vue from 'vue';

// Components
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import AppDialogV2 from '@/components/partials/AppDialogV2.vue';
// import AppMultiselectV3 from '@/components/partials/inputs/AppMultiselectV3.vue';
import AppToggle from '@/components/partials/inputs/AppToggle.vue';
import AppSpinner from '@/components/partials/AppSpinner.vue';

// HTTP

import {
    getBillingAccountById,
    generateHotBillForBillingAccount,
} from '@/__new__/services/portal/postpaid/billingAccount/billingAccount';

import { updateInvoice } from '@/__new__/services/portal/postpaid/invoices/invoices';

import { getTemplate } from '@/__new__/services/dno/documents/http/templates';
import { previewDocument } from '@/__new__/services/dno/documents/http/documents';

// Helpers
import { LAYOUT_PAGE_KEYS } from '@/__new__/features/customerCareSuite/common/layoutSectionHelper';

import isEmpty from 'lodash/isEmpty';

import { DOCUMENT_GENERATE_TYPES } from '@/__new__/services/dno/documents/models/DocumentInterfaces';

import { ALERT_TYPES } from '@/common/alerts/Alert';

const MAX_NUMBER_OF_ITERATIONS = 12;
const currentDate = new Date();

export default Vue.extend({
    name: 'BillingAccountHotBillDialog',

    components: {
        AppButton,
        AppDialogV2,
        AppToggle,
        AppSpinner,
    },
    props: {
        isBillingAccountHotBillModalVisible: {
            type: Boolean,
            default: false,
        },
        // billingAccount: {
        //     type: Object,
        //     default: null,
        // },
    },
    data() {
        return {
            LAYOUT_PAGE_KEYS,
            BUTTON_TYPES,
            ALERT_TYPES,
            DOCUMENT_GENERATE_TYPES,
            runHotBillTitle: this.$i18n.t('billingAccount.runHotBill'),
            billingAccount: {},
            startDate: new Date(currentDate.getFullYear(), currentDate.getMonth(), 1).toISOString().substring(0, 10),
            endDate: currentDate.toISOString().substring(0, 10),
            processItems: false,
            template: {},
            templateName: 'Invoice',
            billJSON: '',
            generatedInvoiceId: 0,
            documenttPollers: {} as any,
            isBillAvailable: false,
            billUrl: '',
            isDataLoading: false,
        };
    },
    async created() {
        const response = await getBillingAccountById(this.$route.params.id);
        this.billingAccount = response.data;

        // Invoice

        const templateResponse = await getTemplate(this.templateName);
        const { template_definition: templateDefinition = '' } = templateResponse?.data?.data;
        this.template = templateDefinition;
    },
    methods: {
        onSave() {
            this.$withProgressBar(
                async () => {
                    const payload = {
                        billingAccountId: this.billingAccount.id,
                        startDate: new Date(this.startDate),
                        endDate: new Date(this.endDate),
                        processItems: this.processItems,
                    };
                    const response = await generateHotBillForBillingAccount(payload);

                    if (response.data?.billJSON) {
                        this.generatedInvoiceId = response.data?.invoiceId;
                        this.billJSON = response.data?.billJSON;

                        this.$eventBus.$emit('showAlert', {
                            message: this.$i18n.t('documents.documentGenerated'),
                            type: ALERT_TYPES.info,
                        });

                        this.$Progress.start();
                        this.isDataLoading = true;

                        try {
                            this.numberOfIterations += 1;
                            const responce = await previewDocument({
                                payload: JSON.parse(this.billJSON),
                                fileName: `${this.templateName}-${this.generatedInvoiceId}`,
                                templateDefinition: this.template,
                            });

                            const { status, url_data: urlData = {} } = responce.data.data;

                            if (status !== DOCUMENT_GENERATE_TYPES.COMPLETED && isEmpty(urlData)) {
                                const poller = setInterval(() => {
                                    if (this.numberOfIterations >= MAX_NUMBER_OF_ITERATIONS) {
                                        this.clearDocumentInterval();
                                        this.$eventBus.$emit('showAlert', {
                                            message: this.$i18n.t('documents.maxNumberOfIterations'),
                                            type: ALERT_TYPES.error,
                                        });
                                        return;
                                    }

                                    this.checkAvailabilityOfDocument();
                                }, 5000);
                                this.$set(this.documenttPollers, this.templateName, poller);
                            } else {
                                const invoicePayload = {
                                    url: urlData.signed_url,
                                };
                                this.billUrl = urlData.signed_url;
                                await updateInvoice(this.generatedInvoiceId, invoicePayload);
                                this.documentGenerationSucceeded();
                            }
                        } catch (e: any) {
                            this.clearDocumentInterval();
                            this.$Progress.fail();
                            this.$eventBus.$emit('showAlert', {
                                message: e?.response?.data?.message ?? this.$i18n.t('documents.documentGeneratedError'),
                                type: ALERT_TYPES.error,
                            });
                        }
                    }
                },
                {
                    errorHandler: () =>
                        this.$showErrorAlert({
                            message: this.$i18n.t('alertMessage.failedToLoadNecessaryData'),
                        }),
                },
            );
        },
        async checkAvailabilityOfDocument(): Promise<void> {
            try {
                this.numberOfIterations += 1;
                const responce = await previewDocument({
                    payload: JSON.parse(this.billJSON),
                    fileName: `${this.templateName}-${this.generatedInvoiceId}}`,
                    templateDefinition: this.template,
                });

                const { status, url_data: urlData = {} } = responce.data.data;

                if (status === DOCUMENT_GENERATE_TYPES.COMPLETED && !isEmpty(urlData)) {
                    const payload = {
                        url: urlData.signed_url,
                    };
                    this.billUrl = urlData.signed_url;
                    await updateInvoice(this.generatedInvoiceId, payload);
                    this.documentGenerationSucceeded();
                }
            } catch (e: any) {
                this.clearDocumentInterval();
                this.$Progress.fail();
                this.$eventBus.$emit('showAlert', {
                    message: e?.response?.data?.message ?? this.$i18n.t('documents.documentGeneratedError'),
                    type: ALERT_TYPES.error,
                });
            }
        },
        clearDocumentInterval(): void {
            this.$eventBus.$emit('closeAllAlerts');
            clearInterval(this.documenttPollers[this.templateName]);
            this.$delete(this.documenttPollers, this.templateName);
            this.isDataLoading = false;
            this.numberOfIterations = 0;
        },
        documentGenerationSucceeded(): void {
            this.clearDocumentInterval();
            this.isBillAvailable = true;
        },
        onCloseGenerateHotBillModal() {
            this.isBillAvailable = false;

            this.$emit('closeUpdateBillingAccountModal');
        },
        viewBill() {
            window.open(this.billUrl, '_blank');
        },
    },
});
