
import Vue from 'vue';

// Components
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';

// HTTP
import ordersHTTP from '@/__new__/services/dno/orders/http/orders';
import { callbackExecution } from '@/__new__/services/dno/orchestrationengine/http/orchestration-engine';

// Helpers
import { ICON_TYPES } from '@/common/iconHelper';
import { LAYOUT_PAGE_KEYS } from '@/__new__/features/customerCareSuite/common/layoutSectionHelper';
import Actions, { Getters } from '@/store/mutation-types';
import {
    handleUserNotFoundError,
    SUBSCRIBER_STATE,
    USER_MANAGER_HIERARCHY,
} from '@/__new__/features/customerCare/common/customerCareHelper';
import * as Sentry from '@sentry/vue';
import permissionsService, {
    BILLING_VERSIONS,
    getBillingVersion,
    isUserAllowed,
    isUserInternal,
    isViewEnabled,
} from '@/services/permissions/permissions.service';
import { mapActions, mapGetters } from 'vuex';
import customerCareHTTP from '@/__new__/services/dno/user/http/customer-care';
import { ALERT_TYPES } from '@/common/alerts/Alert';
import Account from '@/__new__/services/dno/user/models/Account';
import { ORDER_ENTITY_STATES } from '@/__new__/features/customerCare/common/orderStateHelper';
import supportButtonMixin from '@/components/alerts/supportButtonMixin';
import OePlansMixin from '@/__new__/features/customerCare/mixins/OePlansMixin.vue';

export default Vue.extend({
    name: 'LayoutPage',

    components: {
        AppButton,
    },
    mixins: [supportButtonMixin, OePlansMixin],
    data() {
        return {
            account: {},
            activateServiceLoading: false,

            LAYOUT_PAGE_KEYS,
            BUTTON_TYPES,
            ICON_TYPES,

            userAuthenticationEnabled:
                permissionsService.userManagementUserAuthenticationEnabled() &&
                isViewEnabled('UMUserUserAuthentication'),
            isOrderDetailsEnabled:
                permissionsService.accountOrdersEnabled() && isUserAllowed('UMAccountOrderDetailsRead'),
            isLFAdmin: permissionsService.isUserLotusFlareAdmin() && isUserInternal(),
        };
    },
    computed: {
        ...mapGetters('userManagementUser', ['getUserVerificationStatusById']),
        ...mapGetters('orders', {
            getOrders: Getters.GET_ORDERS,
            getPrimaryOrders: Getters.GET_PRIMARY_ORDERS,
        }),
        orders() {
            return getBillingVersion() === BILLING_VERSIONS.v4 ? this.getOrders : this.getPrimaryOrders;
        },
        isUserVerified() {
            return this.account?.userId ? this.getUserVerificationStatusById(this.account.userId) : false;
        },
        isEditAllowed() {
            return !this.userAuthenticationEnabled || this.isUserVerified;
        },
        pendingOrder() {
            return Object.values(this.orders).find(o => o.state === ORDER_ENTITY_STATES.PENDING);
        },
        pendingActivationOrder() {
            return Object.values(this.orders).find(o => o.state === ORDER_ENTITY_STATES.PENDING_ACTIVATION);
        },
        pendingOrderMvneExecutionId() {
            return this.oeMappingsList.find(e => e.planId === 'mvne_provisioning_plan')?.executionId;
        },
        isActivateServiceEnabled() {
            return (
                isUserAllowed('UMAccountActivateService') &&
                permissionsService.userManagerActivateServiceEnabled() &&
                this.account?.state !== SUBSCRIBER_STATE.ACTIVE &&
                ((this.pendingOrder && this.pendingOrderMvneExecutionId) || this.pendingActivationOrder)
            );
        },
    },
    watch: {
        pendingOrder: {
            async handler() {
                if (this.pendingOrder?.orderId) {
                    await this.fetchExecutions({ mapper_ids: [this.pendingOrder.orderId] });
                }
            },
            deep: true,
        },
    },
    async created() {
        await this.fetchOrderData();
        if (this.isActivateServiceEnabled) {
            await this.fetchAccountData();
        }
    },
    methods: {
        ...mapActions('orders', [Actions.LOAD_ALL_OEORDERS_ACCOUNT]),
        async fetchAccountData() {
            try {
                this.$Progress.start();
                const accountApiResponse = await customerCareHTTP.getAccount(
                    this.$route.params.id,
                    USER_MANAGER_HIERARCHY.ACCOUNT,
                );
                const accountInfo = accountApiResponse.data?.account_info;
                this.account = new Account(Account.remapAccountFromBe(accountInfo));
                this.$Progress.finish();
            } catch (error) {
                this.$Progress.fail();
                Sentry.captureException(error);
                if (handleUserNotFoundError(error)) {
                    this.$alert(this.$i18n.t('alertMessage.userManagement.accountNotFound'));
                } else {
                    this.showSupportAlert(
                        this.$i18n.t('alertMessage.somethingWentWrongFetchingNecessaryData'),
                        ALERT_TYPES.error,
                    );
                }
            }
        },
        async fetchOrderData() {
            try {
                if (this.isOrderDetailsEnabled) {
                    await this[Actions.LOAD_ALL_OEORDERS_ACCOUNT](this.$route.params.id);
                }
            } catch (e) {
                Sentry.captureException(e);
                this.$Progress.fail();
                this.showSupportAlert(
                    this.$i18n.t('alertMessage.somethingWentWrongFetchingNecessaryData'),
                    ALERT_TYPES.error,
                );
            }
        },
        async activateService() {
            this.$Progress.start();
            this.activateServiceLoading = true;
            try {
                const targetType = USER_MANAGER_HIERARCHY.ACCOUNT;
                const targetId = this.account.accountId;

                if (this.pendingOrder && this.pendingOrderMvneExecutionId) {
                    const { orderId } = this.pendingOrder;
                    await callbackExecution({ execution_id: this.pendingOrderMvneExecutionId });
                    await new Promise(resolve => setTimeout(resolve, 3000));
                    await ordersHTTP.oeActivateOrder({ targetId, targetType, orderId });
                } else if (this.pendingActivationOrder) {
                    const { orderId } = this.pendingActivationOrder;
                    await ordersHTTP.oeActivateOrder({ targetId, targetType, orderId });
                }
                this.$Progress.finish();
                await this.fetchOrderData();
                this.$showSuccessAlert({
                    message: this.$t('customerCare.account.activateServiceSuccess'),
                });
            } catch (e) {
                Sentry.captureException(e);
                this.$Progress.fail();
                this.$alert(this.$i18n.t('alertMessage.userManagement.orderActivateFailed'));
            } finally {
                this.activateServiceLoading = false;
            }
        },
    },
});
