
import Vue from 'vue';
import { TranslateResult } from 'vue-i18n';

// Store
import { mapGetters, mapActions } from 'vuex';
import Actions, { Getters } from '@/store/mutation-types';
import { Modules } from '@/store/store';

// Validations
import { validationMixin } from 'vuelidate';
import { required, requiredIf, minValue } from 'vuelidate/lib/validators';

// Generic Vue Components
import AbstractEditPageWrapper from '@/components/layout/AbstractEditPageWrapper.vue';
import AppHeader from '@/components/layout/AppHeader.vue';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import AppInputV3 from '@/components/partials/inputs/AppInputV3.vue';
import AppTextareaV3 from '@/components/partials/inputs/AppTextareaV3.vue';
import CardListWrapper from '@/components/partials/cards/CardListWrapper.vue';
import AppIcon from '@/components/partials/icon/AppIcon.vue';
import UnitPicker from '@/components/partials/inputs/UnitPicker.vue';
import ConditionsExpressionEditor from '@/__new__/features/charging/ConditionsExpressionEditor.vue';
import Draggable from 'vuedraggable';
import EditorButtons from '@/components/layout/EditorButtons.vue';
import EntityStatusIndicator from '@/components/partials/EntityStatusIndicator.vue';
import Button from '@/common/button/Button';
import AppLabel from '@/components/partials/AppLabel.vue';
import ChargingTagLabel from '@/__new__/features/charging/ChargingTagLabel.vue';
import AppToggle from '@/components/partials/inputs/AppToggle.vue';
import AppMultiselectV3 from '@/components/partials/inputs/AppMultiselectV3.vue';

// Mixins
import mutationDialogMixin from '@/components/partials/mutations/mutationDialogMixin.vue';
import entityEditorMixin from '@/common/entityEditorMixin';
import ChargingCommonActionsMixin from '@/__new__/features/charging/ChargingCommonActionsMixin.vue';

// HTTP
import { getTariffSpecEntityDraft } from '@/__new__/services/dno/charging/http/tariffSpecification';
import { getUserNameById } from '@/__new__/services/portal/profile/http/profile';

// Helpers
import { languageMap } from '@/common/locale/language';
import { ICON_COLORS, ICON_TYPES } from '@/common/iconHelper';
import ENTITY_TYPES from '@/common/entities/entityTypes';
import { PLURALIZATION } from '@/common/locale/labelSingularOrPlural';
import {
    TariffSpecChargingRate,
    ConditionParameter,
    ConditionParameterOption,
    ConditionOption,
    TariffSpecification,
    ChargingEntityVersion,
} from '@/__new__/services/dno/charging/models/ChargingInterfaces';
import {
    CHARGING_SPEC_TYPE,
    CONDITION_TYPES,
    allConditionOptions,
} from '@/__new__/services/dno/charging/common/chargingSpecificationHelper';
import { unitTypes } from '@/common/formatting';
import * as Sentry from '@sentry/vue';
import RouteNames from '@/router/routeNames';
import { ALERT_TYPES } from '@/common/alerts/Alert';
import { parseExpressionToTree } from '@/__new__/services/dno/charging/common/expression';
import {
    loadVersionHistory,
    CHARGING_ENTITY_TYPES,
    getLowerEntitiesVersions,
    LowerEntitiesVersions,
} from '@/__new__/services/dno/charging/common/versioningHelper';
import cloneDeep from 'lodash/cloneDeep';
import { TariffSpecificationDraft, PeriodType } from '@/__new__/services/dno/charging/models/TariffSpecification';
import { STATUS_CODES } from '@/common/commonHelper';
import { getProperlyFormattedMultilangFieldValue, EDITOR_MODE } from '@/common/entities/entityHelper';
import { EntityStateMapping } from '@/common/commonEntityStateMapper';
import { LABEL_COLOR } from '@/common/labelsHelper';
import {
    TARIFF_SPEC_ZERO_RATE_STRING,
    ZERO_RATE_PRIORITY_OPTION,
    ZERO_RATE_PRIORITY,
} from '@/__new__/services/dno/charging/common/tariffSpecHelper';

type ServiceType = {
    id: string;
    key: number;
};

export default Vue.extend({
    name: 'TariffSpecificationEdit',
    components: {
        AbstractEditPageWrapper,
        AppHeader,
        AppButton,
        AppInputV3,
        AppTextareaV3,
        CardListWrapper,
        AppIcon,
        UnitPicker,
        ConditionsExpressionEditor,
        Draggable,
        EditorButtons,
        EntityStatusIndicator,
        AppLabel,
        ChargingTagLabel,
        AppToggle,
        AppMultiselectV3,
    },
    mixins: [validationMixin, entityEditorMixin, mutationDialogMixin, ChargingCommonActionsMixin],
    data() {
        return {
            // global
            PLURALIZATION,
            BUTTON_TYPES,
            ICON_COLORS,
            ICON_TYPES,
            STATUS_CODES,
            LABEL_COLOR,
            EDITOR_MODE,
            selectedLanguage: '' as string,
            RouteNames,

            // entity
            entityId: null as string | null,
            name: {} as Record<string, string>,
            description: {} as Record<string, string>,
            serviceType: null as ServiceType | null,
            chargingRates: [
                {
                    rate: 0,
                    ratePeriod: 0,
                    conditionExpr: [],
                    priority: 1,
                    useEvent: false,
                    periodType: PeriodType.CHARG,
                },
            ] as TariffSpecChargingRate[],
            remark: '' as string,
            approveOnCreate: false as boolean,
            data: {},
            isZeroRate: false as boolean,
            ZERO_RATE_PRIORITY_OPTION,
            zeroRatePriority: ZERO_RATE_PRIORITY_OPTION[ZERO_RATE_PRIORITY.MINIMUM],

            // draft
            version: null as string | null,
            updateTime: null as string | null,
            entityType: ENTITY_TYPES.TARIFF_SPECIFICATION,
            readonly: false as boolean,
            isOnlyDraft: false as boolean,
            updateName: this.$i18n.t('generic.N/A') as string,
            currentVersion: null as string | null,
            entityDraft: null as any,
            isUnpublished: false as boolean,
            state: EntityStateMapping.UNAPPROVED,
            revertConfirmationButton: new Button({
                label: this.$i18n.t('productCatalog.editors.revert'),
                alertType: ALERT_TYPES.warning,
                handler: () => this.initData(true),
            }),

            // versioning
            versionsHistory: [] as ChargingEntityVersion[],
            getLowerEntitiesVersions,
            CHARGING_ENTITY_TYPES,
        };
    },
    validations() {
        return {
            name: {
                required,
            },
            serviceType: {
                required,
            },
            chargingRates: {
                $each: {
                    rate: {
                        required,
                        minValue: this.showRatePeriod ? minValue(0) : minValue(0.0001),
                    },
                    ratePeriod: {
                        required: requiredIf(val => !this.showRatePeriod || !val.useEvent),
                        minValue: this.showRatePeriod ? minValue(0) : minValue(0.0001),
                    },
                },
            },
        };
    },
    computed: {
        ...mapGetters('operators', [Getters.languageDefault]),
        ...mapGetters(Modules.charging, [Getters.GET_APPROVED_CONDITION_PARAMETERS]),
        ...mapGetters(Modules.chargingV2, [
            Getters.GET_TARIFF_SPECIFICATION_BY_ID,
            Getters.GET_APPROVED_TARIFF_SPECIFICATIONS,
            Getters.GET_TARIFF_SPECIFICATION_BY_VERSION,
        ]),
        serviceTypeOptions() {
            return [CHARGING_SPEC_TYPE.data, CHARGING_SPEC_TYPE.voice, CHARGING_SPEC_TYPE.sms, CHARGING_SPEC_TYPE.mms];
        },
        getConditionParameters(): ConditionParameterOption[] {
            return this[Getters.GET_APPROVED_CONDITION_PARAMETERS].map((condition: ConditionParameter) => ({
                conditionUUID: condition.id,
                label: condition.name,
                type: condition.conditionParameter.type,
            }));
        },
        tariffSpecifications(): TariffSpecification[] {
            return [...this[Getters.GET_APPROVED_TARIFF_SPECIFICATIONS]] || [];
        },
        tariffSpecificationById(): TariffSpecification | null {
            return this[Getters.GET_TARIFF_SPECIFICATION_BY_ID](this.$route.params.id) || null;
        },
        tariffSpecByVersion(): TariffSpecification | null {
            return this[Getters.GET_TARIFF_SPECIFICATION_BY_VERSION] || null;
        },
        conditionList(): ConditionParameterOption[] {
            if (this.serviceType === null) {
                return [];
            }
            const conditionOptionsKeys = this.conditionOptions().map((el: ConditionOption) => el.type);
            return this.getConditionParameters.filter((condition: ConditionParameterOption) =>
                conditionOptionsKeys.includes(condition.type),
            );
        },
        getRatePeriodType(): string {
            switch (this.serviceType?.id) {
                case CHARGING_SPEC_TYPE.data.id:
                    return unitTypes.DATA;
                case CHARGING_SPEC_TYPE.voice.id:
                    return unitTypes.DURATIONSecondsMinutes;
                default:
                    return unitTypes.DATA;
            }
        },
        showRatePeriod(): boolean {
            return (
                this.isSelectedTypeEqualTo(CHARGING_SPEC_TYPE.sms.id) ||
                this.isSelectedTypeEqualTo(CHARGING_SPEC_TYPE.mms.id) ||
                this.isZeroRate
            );
        },
        displayValidLabelForSmsMms(): TranslateResult {
            return this.isSelectedTypeEqualTo(CHARGING_SPEC_TYPE.sms.id)
                ? this.$i18n.t('charging.chargingSpecifications.editor.perSMS')
                : this.$i18n.t('charging.chargingSpecifications.editor.perMMS');
        },
        isEditingEntityExisting(): boolean {
            return this.$route.params.id && !this.$route.params.clone;
        },
        alreadyUsedName(): boolean {
            if (this.isEditingEntityExisting) {
                if (this.name.en !== this.tariffSpecificationById?.name) {
                    return Object.values(this.tariffSpecifications).some(
                        (entity: TariffSpecification) => entity.name === this.name.en,
                    );
                }
                return false;
            }
            return Object.values(this.tariffSpecifications).some(
                (entity: TariffSpecification) => entity.name === this.name.en,
            );
        },
        isDraft() {
            return this.entityId && !this.readonly && this.entityDraft;
        },
        isPublished() {
            return this.entityId && this.readonly;
        },
        allowEditDraftBtn() {
            return this.entityId && this.readonly && !this.isOnlyDraft;
        },
        isRevertAvailable() {
            return this.isEditing && !this.readonly && !this.isOnlyDraft;
        },
        allowViewPublishedBtn() {
            return this.entityId && !this.readonly && !this.isOnlyDraft;
        },
        pageTitle(): string {
            if (this.readonly) {
                return this.isZeroRate
                    ? this.$i18n.tc('charging.tariffSpec.zeroRateName', PLURALIZATION.SINGULAR)
                    : this.$i18n.tc('charging.tariffSpec.name', PLURALIZATION.SINGULAR);
            }
            if (this.entityId) {
                return this.isZeroRate
                    ? this.$i18n.t('charging.tariffSpec.editZeroRateTs')
                    : this.$i18n.t('charging.tariffSpec.editTs');
            }
            return this.isZeroRate
                ? this.$i18n.t('charging.tariffSpec.addNewZeroRateTs')
                : this.$i18n.t('charging.tariffSpec.addnewTs');
        },
        showStatusIndicator(): boolean {
            return Boolean(this.entityId && this.state !== STATUS_CODES.NA);
        },
        dontShowOnPublishedAndDraft(): boolean {
            return !this.isDraft && !this.isPublished;
        },
        chargingRatesLabel(): string {
            return this.$i18n.tc(
                'charging.tariffSpec.chargingRates',
                this.isZeroRate ? PLURALIZATION.SINGULAR : PLURALIZATION.PLURAL,
            );
        },
        tariffSpecDetailsLabel(): string {
            return this.isZeroRate
                ? this.$i18n.t('charging.tariffSpec.zerpRateTariffSpecDetails')
                : this.$i18n.t('charging.tariffSpec.tariffSpecDetails');
        },
        showSaveDraftBtn(): boolean {
            if (this.isZeroRate) {
                return false;
            }
            return !this.readonly;
        },
    },
    created() {
        this.$withLoadingSpinner(
            async () => {
                const { id, readonly, mode, clone, chargingVersion, type } = this.$route.params;
                this.entityId = id;
                this.isZeroRate = type === TARIFF_SPEC_ZERO_RATE_STRING;
                this.entityType = this.isZeroRate
                    ? ENTITY_TYPES.TARIFF_SPECIFICATION_ZERO_RATE
                    : ENTITY_TYPES.TARIFF_SPECIFICATION;

                if (this.entityId && readonly) {
                    this.readonly = readonly;
                }

                if (this.entityId && mode) {
                    this.readonly = mode === EDITOR_MODE.VIEW;
                }

                if (chargingVersion) {
                    this.currentVersion = chargingVersion;
                }

                const promises = [
                    this[Actions.REQUEST_TARIFF_SPECIFICATIONS](this.isZeroRate),
                    this[Actions.REQUEST_CONDITION_PARAMETERS](),
                ];

                if (!clone) {
                    promises.push(this.loadEntityDraft());
                }

                await Promise.all(promises);
                this.selectedLanguage = (this[Getters.languageDefault] || languageMap.en.key).toString();

                if (this.entityId) {
                    this.initData(false, !this.currentVersion);
                    if (this.readonly && !this.isZeroRate) {
                        this.loadVersionHistory(
                            CHARGING_ENTITY_TYPES.TARIFF_SPECIFICATION,
                            this.entityId,
                            chargingVersion,
                        );
                    }
                }
            },
            {
                errorHandler: () => {
                    this.$eventBus.$emit('showAlert', {
                        message: this.$i18n.t('alertMessage.failedToLoadNecessaryData'),
                    });
                },
            },
        );
    },
    methods: {
        ...mapActions(Modules.chargingV2, [
            Actions.REQUEST_TARIFF_SPECIFICATIONS,
            Actions.REQUEST_TARIFF_SPECIFICATION_BY_VERSION,
        ]),
        ...mapActions(Modules.charging, [Actions.REQUEST_CONDITION_PARAMETERS]),
        removeChargingRate(index: number): void {
            this.chargingRates.splice(index, 1);
        },
        createChargingRate(): void {
            const { max } = Math;
            const biggestIdentifier = this.chargingRates.length
                ? max.apply(
                      Math,
                      this.chargingRates.map(rate => rate?.priority),
                  )
                : 0;
            this.chargingRates.push({
                rate: 0,
                ratePeriod: 0,
                conditionExpr: [],
                priority: biggestIdentifier + 1,
                periodType: PeriodType.CHARG,
                useEvent: false,
            });
        },
        isSelectedTypeEqualTo(type: string): boolean {
            return this.serviceType?.id === type;
        },
        conditionOptions(): ConditionOption[] {
            const options = [];

            options.push(
                allConditionOptions[CONDITION_TYPES.SUBSCRIBER_FLAG],
                allConditionOptions[CONDITION_TYPES.BUCKET_FLAG],
                allConditionOptions[CONDITION_TYPES.VLR_ID],
                allConditionOptions[CONDITION_TYPES.SERVICE_ID],
                allConditionOptions[CONDITION_TYPES.SCHEDULE],
                allConditionOptions[CONDITION_TYPES.ZONE],
                allConditionOptions[CONDITION_TYPES.SUBSCRIBER_LOCATION],
            );

            if (this.isSelectedTypeEqualTo(CHARGING_SPEC_TYPE.data.id)) {
                options.push(
                    allConditionOptions[CONDITION_TYPES.BEARER],
                    allConditionOptions[CONDITION_TYPES.NETWORK_SLICE_IDENTIFIER],
                    allConditionOptions[CONDITION_TYPES.APN],
                    allConditionOptions[CONDITION_TYPES.RATING_GROUP],
                );
            } else if (this.isSelectedTypeEqualTo(CHARGING_SPEC_TYPE.voice.id)) {
                options.push(
                    allConditionOptions[CONDITION_TYPES.BEARER],
                    allConditionOptions[CONDITION_TYPES.TRAFFIC_TYPE],
                    allConditionOptions[CONDITION_TYPES.SPECIAL_NUMBER],
                    allConditionOptions[CONDITION_TYPES.OTHER_PARTY_RN],
                    allConditionOptions[CONDITION_TYPES.OTHER_PARTY_NUMBER_LENGTH],
                    allConditionOptions[CONDITION_TYPES.OTHER_PARTY_NUMBER],
                    allConditionOptions[CONDITION_TYPES.TIME_IN_CALL],
                );
            } else if (this.isSelectedTypeEqualTo(CHARGING_SPEC_TYPE.sms.id)) {
                options.push(
                    allConditionOptions[CONDITION_TYPES.BEARER],
                    allConditionOptions[CONDITION_TYPES.SPECIAL_NUMBER],
                    allConditionOptions[CONDITION_TYPES.OTHER_PARTY_RN],
                    allConditionOptions[CONDITION_TYPES.OTHER_PARTY_NUMBER_LENGTH],
                    allConditionOptions[CONDITION_TYPES.OTHER_PARTY_NUMBER],
                );
            } else if (this.isSelectedTypeEqualTo(CHARGING_SPEC_TYPE.mms.id)) {
                options.push(
                    allConditionOptions[CONDITION_TYPES.SPECIAL_NUMBER],
                    allConditionOptions[CONDITION_TYPES.OTHER_PARTY_RN],
                    allConditionOptions[CONDITION_TYPES.OTHER_PARTY_NUMBER_LENGTH],
                    allConditionOptions[CONDITION_TYPES.OTHER_PARTY_NUMBER],
                );
            }

            return options.sort((option1, option2) => option1.type.localeCompare(option2.type));
        },
        rateSectionLable(rate: TariffSpecChargingRate): string {
            return `${this.$i18n.tc('charging.tariffSpec.chargingRates', PLURALIZATION.SINGULAR)} ${rate.priority}`;
        },
        doesChargingRatesHaveError(index: number, key: string): boolean {
            return this.$v?.chargingRates?.$each?.[index]?.[key]?.$error;
        },
        async initData(forceInitPublished = false, initVersion = true): Promise<void> {
            if (this.entityId) {
                const ts = this.tariffSpecificationById;
                let tsData: any;

                if (initVersion) {
                    this.currentVersion = ts?.chargingVersion || null;
                }

                if (this.entityDraft && !forceInitPublished) {
                    const draftData = new TariffSpecificationDraft({
                        ...this.entityDraft,
                        ...this.entityDraft.data,
                    });

                    draftData.version = ts.version;
                    tsData = draftData;
                    this.isOnlyDraft = ts?.state === STATUS_CODES.NA;

                    if (ts?.update_time <= tsData?.update_time) {
                        this.isUnpublished = true;
                    }
                } else if (this.currentVersion) {
                    await this.loadHistoryEntity(this.entityId, this.currentVersion);
                    tsData = cloneDeep(this.tariffSpecByVersion);
                } else {
                    tsData = cloneDeep(ts);
                }

                const {
                    name,
                    description,
                    remark,
                    chargingRates,
                    serviceTypeObj,
                    zeroRatePriority,

                    update_time: updateTime,
                    version,
                    state,
                    updatePortalId,
                } = tsData;

                const properlyFormattedName = getProperlyFormattedMultilangFieldValue(name);
                if (this.$route.params.clone) {
                    Object.entries(properlyFormattedName).forEach(([localeName, localizedName]) => {
                        properlyFormattedName[localeName] = `${localizedName} (cloned)`;
                    });
                }

                this.name = properlyFormattedName;
                this.description = getProperlyFormattedMultilangFieldValue(description);
                this.remark = remark;
                this.serviceType = serviceTypeObj;
                this.chargingRates = chargingRates;
                this.zeroRatePriority = zeroRatePriority;

                this.version = version;
                this.updateTime = updateTime;
                this.state = state;
                await this.getUpdateUserName(updatePortalId);
            }
        },
        checkDataBeforeSave(): boolean {
            this.$v.$touch();
            if (this.$v.$invalid) {
                return false;
            }
            if (this.alreadyUsedName) {
                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('charging.nameMustBeUnique', {
                        entity: RouteNames.CHARGING_TARIFF_SPECIFICATIONS,
                    }),
                });
                return false;
            }

            const chargingRateExprErrror = this.chargingRates.filter(
                (el: TariffSpecChargingRate) => !el.conditionExpr.length,
            );
            if (chargingRateExprErrror.length) {
                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('charging.tariffSpec.chargingRateExprErrror', {
                        index: chargingRateExprErrror?.[0]?.priority,
                    }),
                });
                return false;
            }

            return true;
        },
        async onSave(isPublish = true) {
            if (isPublish && !this.checkDataBeforeSave()) {
                return;
            }

            this.data = {
                name: this.name || null,
                description: this.description || null,
                remark: this.remark || '',
                service_type: this.serviceType?.key || null,
                charging_rates: this.chargingRates?.map((el: TariffSpecChargingRate, index: number) => {
                    let ratePeriod;
                    if (!el.useEvent) {
                        ratePeriod = this.showRatePeriod ? 1 : el.ratePeriod || null;
                    }

                    return {
                        rate: this.isZeroRate ? '0' : el.rate?.toString() || '',
                        rate_period: this.isZeroRate ? 1 : ratePeriod,
                        condition_expr: parseExpressionToTree(el.conditionExpr) || {},
                        priority: index + 1,
                        period_type: el.periodType,
                    };
                }),
            };

            if (this.isZeroRate) {
                this.data = {
                    ...this.data,
                    is_zero_rate: true,
                    zero_rate_priority: this.zeroRatePriority.id,
                };
            }

            await this.saveEntityData(this.isOnlyDraft, [], isPublish);
        },
        async getUpdateUserName(id: number): Promise<void> {
            try {
                if (id) {
                    const response = await getUserNameById(Number(id));
                    if (response?.data) {
                        this.updateName = response.data;
                    }
                }
            } catch (e) {
                Sentry.captureException(e);
            }
        },
        async loadEntityDraft() {
            if (this.entityId) {
                try {
                    if (!this.readonly) {
                        const result = await getTariffSpecEntityDraft(this.entityId);
                        this.entityDraft = result?.data?.data?.[this.entityId] || null;
                    }
                } catch (e) {
                    this.$alert(this.$i18n.t('alertMessage.failedToLoadNecessaryData'));
                }
            }
        },
        async loadVersionHistory(
            entityType: CHARGING_ENTITY_TYPES,
            id: string,
            initChargingVersion?: string,
        ): Promise<void> {
            this.versionsHistory = await loadVersionHistory(entityType, id);

            if (initChargingVersion) {
                this.currentVersion = initChargingVersion;
            } else {
                const [latestVersion] = this.versionsHistory;
                this.currentVersion = latestVersion?.chargingVersion || null;
            }
        },
        async loadHistoryEntity(id: string, version: string): Promise<void> {
            if (id) {
                await this.$withProgressBar(
                    async () => {
                        await this[Actions.REQUEST_TARIFF_SPECIFICATION_BY_VERSION]({
                            id,
                            version,
                        });
                    },
                    {
                        errorHandler: () => {
                            this.$alert(this.$i18n.t('alertMessage.failedToLoadNecessaryData'), {
                                type: ALERT_TYPES.error,
                            });
                        },
                    },
                );
            }
        },
        resetToPublished(): void {
            this.$alert(this.$i18n.t('productCatalog.editors.revertWarning'), {
                type: ALERT_TYPES.warning,
                buttons: [this.revertConfirmationButton],
            });
        },
        reloadEditor(mode: string) {
            const { id, companyId } = this.$route.params;
            // Use push to list page because router don`t want ot reload same page
            this.$router
                .push({
                    name: RouteNames.CHARGING_TARIFF_SPECIFICATIONS,
                    params: { companyId },
                })
                .then(() => {
                    this.$router.push({
                        name: RouteNames.CHARGING_TARIFF_SPECIFICATION_EDITOR,
                        params: {
                            id,
                            mode,
                            companyId,
                        },
                    });
                });
        },
        selectVersion(entry: ChargingEntityVersion) {
            if (this.currentVersion !== entry?.chargingVersion) {
                this.currentVersion = entry?.chargingVersion;
                this.initData(false, false);
            }
        },
        openLowerEntitiData(entry: LowerEntitiesVersions, routeName: string): void {
            this.$router.push({
                name: routeName,
                params: {
                    companyId: this.$route.params.companyId,
                    id: entry.id,
                    readonly: true,
                    chargingVersion: entry.chargingVersion,
                },
            });
        },
        onChargePerEventChange(event: boolean, index: number): void {
            this.chargingRates[index].periodType = event ? PeriodType.EVENT : PeriodType.CHARG;
        },
    },
});
