
import Vue from 'vue';
import AppDialog from '@/components/partials/AppDialog.vue';
import EntityNotes from '@/components/partials/inputs/EntityNotes.vue';
import AppButton from '@/components/partials/inputs/AppButton.vue';

export default Vue.extend({
    name: 'EntityDeleteConfirmationModal',
    components: {
        AppDialog,
        EntityNotes,
        AppButton,
    },
    props: {
        entityType: {
            type: String,
            default: '',
        },
        entity: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            entityNotes: '',
        };
    },
    methods: {
        onCancelButtonClicked() {
            this.$emit('cancel');
        },
        onContinueButtonClicked() {
            this.$emit('continue', this.entityNotes);
        },
    },
});
