<!-- 
    Purpose: This is a modal that can be used to add/edit actions to a dunning profile schedule entry.
-->
<template>
    <AppDialogV2
        visible
        :title="title"
        @close="$emit('close')"
    >
        <AppMultiselectV3
            v-model="value.actionType"
            :additionalLabel="$i18n.t('billing.actionType')"
            :options="actionTypeOptions"
            :small="true"
            :showLabels="false"
            :disabled="disabled"
            placeholder=" "
            label="name"
            optionId="id"
            class="editor-input-largest action-type"
            data-test-id="action-type"
        />
        <template #modalFooter>
            <AppButton
                :buttonType="BUTTON_TYPES.PRIMARY"
                :label="$t('generic.save')"
                class="mr-3"
                @click="$emit('save')"
            />
        </template>
    </AppDialogV2>
</template>

<script>
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import AppDialogV2 from '@/components/partials/AppDialogV2.vue';
import AppMultiselectV3 from '@/components/partials/inputs/AppMultiselectV3.vue';
import { ACTION_TYPE } from '@/__new__/services/dno/billing/models/dunningProfilesDno';

export default {
    name: 'ScheduleEntryActionModal',
    components: { AppButton, AppDialogV2, AppMultiselectV3 },
    props: {
        title: {
            type: String,
            default: '',
        },
        value: {
            type: Object,
            default: () => ({}),
        },
        disabled: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            BUTTON_TYPES,
            actionTypeOptions: [
                {
                    id: ACTION_TYPE.SEND_EMAIL,
                    name: this.$i18n.t('billing.sendEmail'),
                },
                {
                    id: ACTION_TYPE.RETRY_PAYMENT,
                    name: this.$i18n.t('billing.retryPayment'),
                },
                {
                    id: ACTION_TYPE.SOFT_SUSPENSION,
                    name: this.$i18n.t('billing.softSuspension'),
                },
                {
                    id: ACTION_TYPE.HARD_SUSPENSION,
                    name: this.$i18n.t('billing.hardSuspension'),
                },
            ],
        };
    },
    methods: {},
};
</script>

<style lang="scss" scoped>
.action-type {
    padding-bottom: 120px;
}
</style>
