import ENTITY_TYPES from '@/common/entities/entityTypes';
import ProductCatalogOfferModel from '@/__new__/services/dno/pc/models/ProductCatalogOfferModel';
import ProductCatalogProductModel from '@/__new__/services/dno/pc/models/ProductCatalogProductModel';
import ProductCatalogEntityBase from '@/__new__/services/dno/pc/models/ProductCatalogEntityBase';
import ProductCatalogServiceModel from '@/__new__/services/dno/pc/models/ProductCatalogServiceModel';
import ProductCatalogCategoryModel from '@/__new__/services/dno/pc/models/ProductCatalogCategoryModel';

export const ENTITIES_TYPES_TO_MODEL_MAP = new Map([
    [ENTITY_TYPES.OFFER, ProductCatalogOfferModel],
    [ENTITY_TYPES.PRODUCT, ProductCatalogProductModel],
    [ENTITY_TYPES.RESOURCE, ProductCatalogEntityBase],
    [ENTITY_TYPES.SERVICE, ProductCatalogServiceModel],
    [ENTITY_TYPES.CATEGORY, ProductCatalogCategoryModel],
]);

export default ENTITIES_TYPES_TO_MODEL_MAP;
