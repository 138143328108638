
import Vue from 'vue';

// Components
import AbstractTableTile from '@/__new__/features/customerCareSuite/components/AbstractTableTile.vue';

// Helpers
import tableColumnType from '@/common/filterTable';
import { getBeautifulBoolean } from '@/common/formatting';
import { getMultiLangFieldValueByLocale } from '@/common/entities/entityHelper';
import { USER_MANAGER_HIERARCHY } from '@/__new__/features/customerCare/common/customerCareHelper';
import moment from 'moment';

// HTTP
import vouchersHTTP from '@/modules/rewards/http/vouchers';

export default Vue.extend({
    name: 'VoucherHistoryTile',
    components: {
        AbstractTableTile,
    },
    props: {
        userManagerHierarchy: {
            type: Number as USER_MANAGER_HIERARCHY,
            required: true,
        },
    },
    data() {
        return {
            selectedAssociatedAccount: null,

            voucherCodes: [],
            apiResponse: {},
        };
    },
    computed: {
        unusableReasonsEnumToLabel() {
            return new Map([
                [1, this.$i18n.t('generic.used')],
                [2, this.$i18n.t('generic.notActive')],
                [3, this.$i18n.t('generic.expired')],
            ]);
        },
        voucherCodesFormatted() {
            return this.voucherCodes.length
                ? this.voucherCodes.map(el => ({
                      ...el,
                      voucherNameLabel: getMultiLangFieldValueByLocale(el.voucher_name),
                      usabilityStartTimeMoment: moment(el.usability_start_time * 1000),
                      usableLabel: getBeautifulBoolean(el.usable),
                      unusableReasonLabel: this.unusableReasonsEnumToLabel.get(el.unusable_reason),
                  }))
                : [];
        },
        columnsData() {
            return [
                {
                    name: this.$i18n.t('generic.name'),
                    key: 'voucherNameLabel',
                    field: 'voucherNameLabel',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('customerCare.voucherHistory.voucherCode'),
                    key: 'voucher_code',
                    field: 'voucher_code',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('customerCare.voucherHistory.voucherSetId'),
                    key: 'voucher_set_id',
                    field: 'voucher_set_id',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('generic.type'),
                    key: 'voucher_type',
                    field: 'voucher_type',
                    filterType: tableColumnType.TEXT_LIMITED_OPTIONS,
                    limitedOptions: [...new Set(this.voucherCodes.map(entity => entity.voucher_type))],
                },
                {
                    name: this.$i18n.t('generic.usableStartDate'),
                    key: 'usability_start_time',
                    formatter: this.$localeLibrary.getFormattedDate,
                    field: 'usabilityStartTimeMoment',
                    filterType: tableColumnType.DATE,
                },
                {
                    name: this.$i18n.t('generic.usable'),
                    key: 'usableLabel',
                    field: 'usableLabel',
                    filterType: tableColumnType.TEXT_LIMITED_OPTIONS,
                    limitedOptions: [...new Set(this.voucherCodes.map(entity => entity.usableLabel))],
                },
                {
                    name: this.$i18n.t('generic.unusableReason'),
                    key: 'unusableReasonLabel',
                    field: 'unusableReasonLabel',
                    filterType: tableColumnType.TEXT_LIMITED_OPTIONS,
                    limitedOptions: [...new Set(this.voucherCodes.map(entity => entity.unusableReasonLabel))],
                },
            ];
        },
    },
    mounted() {
        this.fetchTileData();
    },
    methods: {
        fetchTileData() {
            this.$emit('isDataLoadingUpd', true);
            this.$withProgressBar(
                async () => {
                    const voucherCodesResponse = await vouchersHTTP.getUserVoucherCodes(
                        this.$route.params.id,
                        this.userManagerHierarchy,
                        true,
                    );
                    this.apiResponse = voucherCodesResponse;
                    this.voucherCodes = voucherCodesResponse?.data?.voucher_codes;
                    this.$emit('isDataLoadingUpd', false);
                },
                {
                    errorHandler: (e: any) => {
                        this.$showErrorAlert({
                            message: this.$i18n.t('alertMessage.somethingWentWrongFetchingNecessaryData'),
                        });
                        this.apiResponse = e.response;
                        this.$emit('isDataLoadingUpd', false);
                    },
                },
            );
        },
    },
});
