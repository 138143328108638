import i18n from '@/i18n';
import { LABEL_COLOR } from '@/common/labelsHelper';

export enum CHARGE_STATES {
    SUCCESS = 100,
    UNPAID = 200,
    UNPAID_CANCELED = 201,
    FAILED = 400,
    CALLBACK_TIMEOUT = 401,
    REFUNDED = 500,
    PARTIALLY_REFUNDED = 599,
    RESERVED = 600,
    RESERVE_CANCELED = 601,
    PAYMENT_COMMIT_REQUESTED = 700,
    PAYMENT_RESERVE_REQUESTED = 701,
    PAYMENT_SINGLE_PASS_REQUESTED = 702,
    PAYMENT_REFUND_REQUESTED = 703,
}

export enum CHARGE_STATES_NEXT_GEN {
    FAILED = 0,
    SUCCESS = 1,
    PENDING = 2,
    CANCELED = 3,
}

export const CHARGE_STATES_INDICATOR_MAP = new Map([
    [CHARGE_STATES.SUCCESS, LABEL_COLOR.green],
    [CHARGE_STATES.UNPAID, LABEL_COLOR.yellow],
    [CHARGE_STATES.UNPAID_CANCELED, LABEL_COLOR.red],
    [CHARGE_STATES.FAILED, LABEL_COLOR.red],
    [CHARGE_STATES.CALLBACK_TIMEOUT, LABEL_COLOR.red],
    [CHARGE_STATES.REFUNDED, LABEL_COLOR.gray],
    [CHARGE_STATES.PARTIALLY_REFUNDED, LABEL_COLOR.gray],
    [CHARGE_STATES.RESERVED, LABEL_COLOR.yellow],
    [CHARGE_STATES.RESERVE_CANCELED, LABEL_COLOR.red],
    [CHARGE_STATES.PAYMENT_COMMIT_REQUESTED, LABEL_COLOR.gray],
    [CHARGE_STATES.PAYMENT_RESERVE_REQUESTED, LABEL_COLOR.gray],
    [CHARGE_STATES.PAYMENT_SINGLE_PASS_REQUESTED, LABEL_COLOR.gray],
    [CHARGE_STATES.PAYMENT_REFUND_REQUESTED, LABEL_COLOR.gray],
]);

export const CHARGE_STATES_TO_STATUS_NAME_MAP = new Map([
    [CHARGE_STATES.SUCCESS, i18n.t('generic.stateMap.success')],
    [CHARGE_STATES.UNPAID, i18n.t('generic.stateMap.unpaid')],
    [CHARGE_STATES.UNPAID_CANCELED, i18n.t('generic.stateMap.unpaidCanceled')],
    [CHARGE_STATES.FAILED, i18n.t('generic.stateMap.failed')],
    [CHARGE_STATES.CALLBACK_TIMEOUT, i18n.t('generic.stateMap.callbackTimeout')],
    [CHARGE_STATES.REFUNDED, i18n.t('generic.stateMap.refunded')],
    [CHARGE_STATES.PARTIALLY_REFUNDED, i18n.t('generic.stateMap.partiallyRefunded')],
    [CHARGE_STATES.RESERVED, i18n.t('generic.stateMap.reserved')],
    [CHARGE_STATES.RESERVE_CANCELED, i18n.t('generic.stateMap.reserveCanceled')],
    [CHARGE_STATES.PAYMENT_COMMIT_REQUESTED, i18n.t('generic.stateMap.paymentCommitRequested')],
    [CHARGE_STATES.PAYMENT_RESERVE_REQUESTED, i18n.t('generic.stateMap.paymentReserveRequested')],
    [CHARGE_STATES.PAYMENT_SINGLE_PASS_REQUESTED, i18n.t('generic.stateMap.paymentSinglePassRequested')],
    [CHARGE_STATES.PAYMENT_REFUND_REQUESTED, i18n.t('generic.stateMap.paymentRefundRequested')],
]);

export const CHARGE_STATES_INDICATOR_MAP_NEXT_GEN = new Map([
    [CHARGE_STATES_NEXT_GEN.FAILED, LABEL_COLOR.red],
    [CHARGE_STATES_NEXT_GEN.SUCCESS, LABEL_COLOR.green],
    [CHARGE_STATES_NEXT_GEN.PENDING, LABEL_COLOR.yellow],
    [CHARGE_STATES_NEXT_GEN.CANCELED, LABEL_COLOR.gray],
]);

export const CHARGE_STATES_TO_STATUS_NAME_MAP_NEXT_GEN = new Map([
    [CHARGE_STATES_NEXT_GEN.FAILED, i18n.t('generic.stateMap.failed')],
    [CHARGE_STATES_NEXT_GEN.SUCCESS, i18n.t('generic.stateMap.success')],
    [CHARGE_STATES_NEXT_GEN.PENDING, i18n.t('generic.stateMap.pending')],
    [CHARGE_STATES_NEXT_GEN.CANCELED, i18n.t('generic.stateMap.canceled')],
]);

export default {};
