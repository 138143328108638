import i18n from '@/i18n';
import {
    ConditionOption,
    ConditionConditionDataObject,
} from '@/__new__/services/dno/charging/common/conditionCommonHelper';

export enum BUCKET_TYPE {
    UNLIMITED = 1,
    VOLUME = 2,
    MONETARY = 3,
}

export type BucketTypeOptions = ConditionOption<BUCKET_TYPE>;

export const MAP_BUCKET_TYPE_TO_OPTION_VALUES: Readonly<BucketTypeOptions[]> = [
    {
        id: BUCKET_TYPE.UNLIMITED,
        label: i18n.t('charging.chargingSpecifications.editor.conditions.bucketType.unlimited'),
    },
    {
        id: BUCKET_TYPE.VOLUME,
        label: i18n.t('charging.chargingSpecifications.editor.conditions.bucketType.volume'),
    },
    {
        id: BUCKET_TYPE.MONETARY,
        label: i18n.t('charging.chargingSpecifications.editor.conditions.bucketType.monetary'),
    },
];

export type BucketTypeConditionArg = ConditionConditionDataObject<BucketTypeOptions[] | BUCKET_TYPE[]>;
