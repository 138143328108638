<!--
    Purpose: Clicking this button will open a modal which displays
             API response data represented by by input property `response`.

    Behavior:
             If response is okay (status code 200-300) then only the response data is displayed.
             Otherwise the entire response is displayed.
-->
<template>
    <div
        v-click-outside="closeDropdown"
        class="dropdown-menu-wrapper"
    >
        <IconButton
            :label="$i18n.t('generic.data')"
            :icon="ICON_TYPES.CURLY_BRACKETS"
            data-test-id="response-btn"
            @iconClick="handleResponseBtnClick"
        />
        <DetailsJsonModal ref="DetailsJsonModal" />

        <!-- Render the dropdown with entities names-->
        <div
            v-if="isDropdownVisible"
            class="dropdown-menu m-0 p-0 rounded-0"
        >
            <span
                v-for="entity in entities"
                :key="entity.title"
                class="dropdown-item"
                @mousedown="handleDropdownSelect(entity)"
            >
                {{ entity.title }}
            </span>
        </div>
    </div>
</template>

<script>
// Components
import DetailsJsonModal from '@/components/partials/DetailsJsonModal.vue';
import IconButton from '@/components/partials/IconButton.vue';

// Helpers
import vClickOutside from 'v-click-outside';
import { ICON_TYPES } from '@/common/iconHelper';

export default {
    name: 'ResponseModalButton',
    components: { DetailsJsonModal, IconButton },
    directives: {
        clickOutside: vClickOutside.directive,
    },
    props: {
        /**
         * Response which is displayed.
         * Can be either a single HTTP response or a list of HTTP responses.
         */
        response: {
            type: [Object, Array],
            default: null,
        },
        /**
         * Is used for displaying multiple responses
         * Expects Array of objects like:
         * {
         *     title: '',
         *     response: [] || {}
         * }
         */
        entities: {
            type: Array,
            default: () => [],
        },
        title: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            ICON_TYPES,
            isDropdownVisible: false,
        };
    },
    computed: {
        data() {
            if (this.response == null) {
                return null;
            }
            if (Array.isArray(this.response)) {
                return this.response.map(r => this.formatResponse(r));
            }
            return this.formatResponse(this.response);
        },
    },
    methods: {
        closeDropdown() {
            this.isDropdownVisible = false;
        },
        openDropdown() {
            this.isDropdownVisible = true;
        },
        handleResponseBtnClick() {
            if (!this.entities.length) {
                this.$refs.DetailsJsonModal.display(this.response, this.title);
            } else {
                this.openDropdown();
            }
        },
        handleDropdownSelect(item) {
            this.closeDropdown();
            this.$refs.DetailsJsonModal.display(item.response, item.title);
        },
        formatResponse(response) {
            if (response.status >= 200 && response.status < 300) {
                return response.data;
            }
            return response;
        },
    },
};
</script>
<style lang="scss" scoped>
@import '~@/assets/scss/palette';
@import '~@/assets/scss/icons';
@import '~@/assets/scss/layout';
@import '~@/assets/scss/z-indexes';

.dropdown-menu-wrapper {
    position: relative;

    .dropdown-menu {
        display: block;
        z-index: $header-dropdowns-z-index;
        position: absolute;
        right: 0;
        top: 3rem;
        min-width: fit-content;
        width: 100%;
        border: none;
        box-shadow: 0 0.0625rem 0.125rem 0 $gray30;
        max-height: 15rem;
        overflow-x: hidden;

        // scroll bar styling only works in webkit
        &::-webkit-scrollbar {
            width: 0.625rem;
            background-color: $dirty-white;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 0.3125rem;
            background-color: $gray30;
        }

        .dropdown-item {
            padding: 0 2rem 0 1rem;
            height: 2.5rem;
            width: 100%;
            line-height: 2.5rem;
            background: right 0.375rem center no-repeat;
            overflow: hidden;
            text-overflow: ellipsis;
            cursor: pointer;
            font-size: 0.875rem;
            color: $navy;
            &:hover {
                background-color: $gray5;
            }
        }
    }
}
</style>
