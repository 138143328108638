<template>
    <AppDialog
        :visible="true"
        maxWidth="47.5rem"
        maxHeight="100%"
    >
        <div class="image-wrapper">
            <img
                v-if="imageUrl"
                :src="imageUrl"
                :alt="$i18n.t('generic.profilePhoto')"
                class="id-image"
            />
        </div>
        <AppButton
            :label="$i18n.t('generic.cancel')"
            class="m-2"
            @click="onCloseModal"
        />
    </AppDialog>
</template>

<script>
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import AppDialog from '@/components/partials/AppDialog.vue';

export default {
    name: 'AccountDocModal',
    components: {
        AppButton,
        AppDialog,
    },
    props: {
        imageUrl: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            BUTTON_TYPES,
        };
    },
    methods: {
        onCloseModal() {
            this.$emit('close');
        },
    },
};
</script>

<style lang="scss" scoped>
.image-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 2rem 2.5rem;

    .id-image {
        max-width: 100%;
        max-height: 100%;
    }
}
</style>
