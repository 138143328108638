<template>
    <EntityListPageLayout
        :entityType="entityType"
        :listViewColumns="listViewColumns"
        writePermission="ProductsWrite"
    />
</template>

<script>
import { mapGetters } from 'vuex';
import { Getters } from '@/store/mutation-types';

import ENTITY_TYPES from '@/common/entities/entityTypes';
import tableColumnType from '@/common/filterTable';

import EntityListPageLayout from '@/__new__/features/pc/EntityListPageLayout.vue';

export default {
    name: 'ProductListPage',
    components: {
        EntityListPageLayout,
    },
    data() {
        return {
            entityType: ENTITY_TYPES.PRODUCT,
        };
    },
    computed: {
        ...mapGetters('productcatalog', {
            getEntitiesByType: Getters.PC_GET_ENTITIES_BY_TYPE_NOT_DELETED,
        }),
        entities() {
            return this.getEntitiesByType(this.entityType);
        },
        listViewColumns() {
            return [
                {
                    name: this.$i18n.t('productCatalog.templates.templateName'),
                    key: 'templateName',
                    field: 'templateName',
                    filterType: tableColumnType.TEXT_LIMITED_OPTIONS,
                    limitedOptions: [],
                },
                {
                    name: this.$i18n.t('productCatalog.templates.templateExternalId'),
                    key: 'templateExternalId',
                    field: 'templateExternalId',
                    filterType: tableColumnType.TEXT_LIMITED_OPTIONS,
                    limitedOptions: [],
                },
            ];
        },
    },
};
</script>
