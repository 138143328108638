
import Vue from 'vue';

// COMPONENTS
import BulkUploadPageLayout from '@/__new__/features/resources/BulkUploadPageLayout.vue';

// HELPERS
import type { UploadColumnLegend } from '@/__new__/features/resources/BulkUploadDialog.vue';
import type { Tab } from '@/__new__/features/resources/BulkUploadTabs.vue';
import { DATAFLOW_API_TYPE } from '@/__new__/services/dno/progressTracker/progressTrackerHelper';
import { type CollapsibleListItem } from '@/common/AppCollapsibleListHelper';
import {
    formatEventCategory,
    getAllFileUploadStatusStrings,
    type UploadedFileDetails,
} from '@/common/fileUploadHelper';
import tableColumnType, { type TableColumn, type TableSortFilter } from '@/common/filterTable';

// HTTP
import { getUploadHistory } from '@/__new__/services/dno/progressTracker/http/progressTrackerService';

export default Vue.extend({
    name: 'ProfileBulkUpload',
    components: {
        BulkUploadPageLayout,
    },
    data() {
        return {
            tab: 'upload' as Tab['id'],
            dataflow: DATAFLOW_API_TYPE.CONSENT_PROFILE_BULK,
            uploadHistoryColumnsData: [
                {
                    name: this.$i18n.t('generic.uploadTime'),
                    key: 'createdAtStr',
                    mapper: entity => this.$localeLibrary.getFormattedDateAndTime(entity.createdAt),
                    sortBy: entity => entity.createdAt,
                    field: 'createdAt',
                    filterType: tableColumnType.DATE,
                },
                {
                    name: this.$i18n.t('generic.filename'),
                    key: 'fileName',
                    field: 'fileName',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('generic.uploadedBy'),
                    key: 'createdBy',
                    field: 'createdBy',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('qodNumberManagement.recordCount'),
                    key: 'recordCount',
                    field: 'recordCount',
                    filterType: tableColumnType.NUMBER,
                },
                {
                    name: this.$i18n.t('generic.status'),
                    key: 'fileUploadStatusStr',
                    field: 'fileUploadStatusStr',
                    filterType: tableColumnType.TEXT_LIMITED_OPTIONS,
                    limitedOptions: getAllFileUploadStatusStrings(),
                },
            ] as TableColumn[],
            entities: [] as UploadedFileDetails[],
            isHistoryDataLoading: false,
            defaultSortBy: {
                key: 'createdAtStr',
                type: 'desc',
            } as TableSortFilter,
            exampleColumns: [
                {
                    label: this.$i18n.t('consent.action'),
                    description: this.$i18n.t('consent.csvColumnDescriptions.action'),
                    key: 'action',
                },
                {
                    label: this.$i18n.t('consent.specificationId'),
                    description: this.$i18n.t('consent.csvColumnDescriptions.specificationId'),
                    key: 'specificationId',
                },
                {
                    label: this.$i18n.t('consent.individualId'),
                    description: this.$i18n.t('consent.csvColumnDescriptions.individualId'),
                    key: 'individualId',
                },
                {
                    label: this.$i18n.t('consent.individualName'),
                    description: this.$i18n.t('consent.individualName'),
                    key: 'individualName',
                },
                {
                    label: this.$i18n.t('consent.applicationId'),
                    description: this.$i18n.t('consent.csvColumnDescriptions.applicationId'),
                    key: 'applicationId',
                },
                {
                    label: this.$i18n.t('consent.agreedOn'),
                    description: this.$i18n.t('consent.csvColumnDescriptions.agreedOn'),
                    key: 'agreedOn',
                },
                {
                    label: this.$i18n.t('consent.otherInformation'),
                    description: this.$i18n.t('consent.csvColumnDescriptions.otherInformation'),
                    key: 'otherInformation',
                },
            ],
        };
    },
    computed: {
        title(): string {
            return this.$i18n.t('consent.profileBulkUpload');
        },
        legendColumns(): UploadColumnLegend[] {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const cols = this.exampleColumns.map((it, i) => ({
                ...it,
                index: i + 1,
                name: it.label,
            }));
            return cols;
        },
    },
    created() {
        const apis = [this.loadHistory()];
        Promise.all(apis);
    },
    methods: {
        mapSidebarData(entity: any): CollapsibleListItem[] {
            return [
                {
                    name: this.$i18n.t('generic.general'),
                    isCollapsed: false,
                    rows: [
                        {
                            name: this.$i18n.t('generic.filename'),
                            value: entity?.fileName ?? '',
                        },
                        {
                            name: this.$i18n.t('qodNumberManagement.bulkUploadId'),
                            value: entity?.bulkUploadId ?? this.$i18n.t('generic.unknown'),
                        },
                        {
                            name: this.$i18n.t('qodNumberManagement.recordCount'),
                            value: entity?.recordCount ?? this.$i18n.t('generic.unknown'),
                        },
                        {
                            name: this.$i18n.t('generic.uploadedBy'),
                            value: entity?.createdBy ?? this.$i18n.t('generic.unknown'),
                        },
                        {
                            name: this.$i18n.t('generic.uploadTime'),
                            value: this.$localeLibrary.getFormattedDateAndTime(entity?.createdAt),
                        },
                    ],
                },
                {
                    name: this.$i18n.t('qodNumberManagement.provisionStatus'),
                    isCollapsed: false,
                    rows: Object.entries(entity?.eventCategoryCounters || {}).length
                        ? Object.entries(entity?.eventCategoryCounters || {}).map(([status, count]) => ({
                              name: formatEventCategory(status),
                              value: `${count}/${entity?.recordCount}`,
                          }))
                        : [{ name: this.$i18n.t('generic.unknown') }],
                },
            ];
        },
        loadHistory(): Promise<void> {
            this.isHistoryDataLoading = true;
            return this.$withProgressBar(
                async () => {
                    this.entities = await getUploadHistory(this.dataflow);
                    this.isHistoryDataLoading = false;
                },
                {
                    errorHandler: () => {
                        this.isHistoryDataLoading = false;
                        this.$alert(this.$i18n.t('qodNumberManagement.loadingBulkUploadHistoryFailed'));
                    },
                },
            );
        },
    },
});
