
import Vue from 'vue';

// Components
import AppMultiselectV3 from '@/components/partials/inputs/AppMultiselectV3.vue';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import AppInputV3 from '@/components/partials/inputs/AppInputV3.vue';
import BulkUploadHistoryV2 from '@/__new__/features/resources/BulkUploadHistoryV2.vue';
import EntityStatusIndicator from '@/components/partials/EntityStatusIndicator.vue';

// Http
import { fetchResource } from '@/__new__/services/dno/sim/http/inventory';

// Helpers
import { ICON_TYPES } from '@/common/iconHelper';
import { SIM_TYPES_MAP, SIM_TYPES_NAME_MAP } from '@/__new__/features/customerCare/subscriber/common/simTypes';
import { SIM_STATE_NAME_MAP, SIMStateColorMap } from '@/__new__/features/resources/sim/simState';
import { formatOwnership } from '@/__new__/services/dno/number/models/numberManagementDno';
import { SEGMENT_ID_TYPE } from '@/common/StaticFilter';

type SimUtilityType = {
    id: SEGMENT_ID_TYPE;
    label: string;
    i18n?: string;
};

export default Vue.extend({
    name: 'SimProfileUtilities',

    components: {
        AppMultiselectV3,
        AppButton,
        AppInputV3,
        BulkUploadHistoryV2,
        EntityStatusIndicator,
    },
    props: {
        utilityTypes: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            BUTTON_TYPES,
            ICON_TYPES,
            SIM_STATE_NAME_MAP,
            SIMStateColorMap,
            utilities: {
                type: null,
                value: '',
            } as { type: SimUtilityType | null; value: string },
            simType: null as Record<'id' | 'label', string> | null,
            searchResult: [] as Array<{
                resourceType: string;
                imsi: string;
                state: string;
            }>,
            tableColumnsData: [
                {
                    name: this.$t('generic.type'),
                    key: 'resourceType',
                    field: 'resourceType',
                },
                {
                    name: this.$t('customerCare.imsi'),
                    key: 'imsi',
                    field: 'imsi',
                    formatter: formatOwnership,
                },
                {
                    name: this.$t('generic.state'),
                    key: 'state',
                    field: 'state',
                },
            ],
            simTypeOptions: [
                {
                    id: SIM_TYPES_MAP.PSIM,
                    label: SIM_TYPES_NAME_MAP[SIM_TYPES_MAP.PSIM],
                },
                {
                    id: SIM_TYPES_MAP.ESIM,
                    label: SIM_TYPES_NAME_MAP[SIM_TYPES_MAP.ESIM],
                },
            ],
        };
    },
    computed: {
        entityType(): string {
            return this.utilities?.type?.i18n ? this.$t(this.utilities.type.i18n) : '';
        },
        utilityValuePlaceholder(): string {
            return this.$t('operator.addEntity', { entityType: this.entityType });
        },
        utilityDescription(): string {
            return this.$t('operator.getInfoForEntity', { entityType: this.entityType });
        },
    },
    methods: {
        searchUtility(): Promise<void> {
            return this.$withProgressBar(
                async () => {
                    const {
                        data: { resource: simProfile },
                    } = await fetchResource({
                        resource_id: this.utilities.value,
                        resource_type: this.simType?.id,
                        skip_validation: true,
                    });
                    this.searchResult = [
                        {
                            resourceType: SIM_TYPES_NAME_MAP[simProfile.resource_type],
                            imsi: simProfile?.imsi,
                            state: simProfile?.state,
                        },
                    ];
                },
                {
                    errorHandler: () => {
                        this.searchResult = [];
                    },
                },
            );
        },
    },
});
