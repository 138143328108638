<template>
    <AbstractSubSidebarPageWrapper
        :pageTitle="$i18n.t('settings.settings')"
        :entitiesCount="roles.length"
    >
        <template #headerButtons>
            <AppButton
                :buttonType="BUTTON_TYPES.PRIMARY"
                :label="$i18n.t('rolesAndPermissions.newRole')"
                :iconType="ICON_TYPES.PLUS"
                @click="addRole"
            />
        </template>

        <template #subSidebar>
            <SubSidebar
                v-model="selectedRouteName"
                :sections="permissionFilteredSections"
                class="sidebar"
                @change="changeActiveSection"
            />
        </template>

        <template #content>
            <AbstractListPageWrapper :isOverviewEnabled="isOverviewEnabled">
                <template #table>
                    <AppTable
                        :entities="roles"
                        :selectedEntityId="selectedEntityId"
                        :isSearchEnabled="true"
                        :innerSearchQuery="searchQueryForTable"
                        :searchBy="['name']"
                        :columnsData="tableColumnsData"
                        :canSelectColumns="true"
                        :isDataLoading="isDataLoading"
                        tableKey="roles"
                        @selectEntity="onSelectEntity"
                    >
                        <template #customRowButtons="{ entity }">
                            <IconButton
                                :label="$i18n.t('generic.edit')"
                                :icon="ICON_TYPES.EDIT"
                                @iconClick="editRole(entity.id)"
                            />
                            <IconButton
                                :label="$i18n.t('generic.delete')"
                                :icon="ICON_TYPES.DELETE"
                                @iconClick="showConfirmAlert(entity.id)"
                            />
                        </template>
                    </AppTable>
                </template>
                <template #overview>
                    <EntityOverview
                        :entityType="ENTITY_TYPES.ROLE"
                        :entity="selectedEntity"
                        @closeOverview="isOverviewEnabled = false"
                    >
                        <template #section-1-header>
                            <div>
                                <OverviewHeader
                                    v-if="selectedEntity"
                                    :entityName="selectedEntity.name"
                                    :entityType="ENTITY_TYPES.ROLE"
                                />
                            </div>
                        </template>
                        <div class="row no-gutters font-weight-bold align-items-center header-bar pl-4">
                            <span class="col-12"> {{ $i18n.t('rolesAndPermissions.roles') }} </span>
                        </div>
                        <template #section-1-content>
                            <div>
                                <OverviewList :lists="detailsSections" />
                            </div>
                        </template>
                    </EntityOverview>
                </template>
            </AbstractListPageWrapper>
        </template>
    </AbstractSubSidebarPageWrapper>
</template>

<script>
// Vuex
import { mapGetters, mapActions } from 'vuex';
import Actions, { Getters } from '@/store/mutation-types';
// Components
import AbstractListPageWrapper from '@/components/layout/AbstractListPageWrapper.vue';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import { ICON_TYPES } from '@/common/iconHelper';
import AppTable from '@/components/partials/AppTable.vue';
import EntityOverview from '@/components/partials/entityOverview/EntityOverview.vue';
import OverviewList from '@/components/partials/entityOverview/OverviewList.vue';
import OverviewHeader from '@/components/partials/entityOverview/OverviewHeader.vue';
import IconButton from '@/components/partials/IconButton.vue';
import SubSidebarMixin from '@/components/partials/SubSidebarMixin.vue';
import SettingsSubSidebarMixin from '@/__new__/features/settings/SettingsSubSidebarMixin.vue';
import AbstractSubSidebarPageWrapper from '@/components/layout/AbstractSubSidebarPageWrapper.vue';
import SubSidebar from '@/components/layout/SubSidebar.vue';
// Helpers
import permissionsService from '@/services/permissions/permissions.service';
import { ALERT_TYPES } from '@/common/alerts/Alert';
import ENTITY_TYPES from '@/common/entities/entityTypes';
import { roleMapper } from '@/common/roles/roleHelper';
import { deleteRole } from '@/__new__/services/portal/admin/http/role';
import Button from '@/common/button/Button';
import supportButtonMixin from '@/components/alerts/supportButtonMixin';
// Router
import RouteNames from '@/router/routeNames';

export default {
    name: 'Roles',
    components: {
        AbstractListPageWrapper,
        AppTable,
        AppButton,
        EntityOverview,
        OverviewList,
        OverviewHeader,
        IconButton,
        AbstractSubSidebarPageWrapper,
        SubSidebar,
    },
    mixins: [SubSidebarMixin, SettingsSubSidebarMixin, supportButtonMixin],
    data() {
        return {
            searchQueryForTable: '',
            ICON_TYPES,
            BUTTON_TYPES,
            roleMapper,
            ENTITY_TYPES,
            alertButtons: {},
            selectedEntity: null,
            selectedEntityId: null,
            isDataLoading: false,
            isOverviewEnabled: false,
            tableColumnsData: [
                { name: this.$i18n.t('generic.name'), key: 'name' },
                // { name: this.$i18n.t('rolesAndPermissions.numberOfUsers'), key: 'numberOfUsers', },
                // Disabled due to role refactor
                /* {
                    name: this.$i18n.t('rolesAndPermissions.permissions'),
                    key: 'permissions',
                    formatter: this.displayComponents },
                    */
            ],
        };
    },
    computed: {
        ...mapGetters('roles', [Getters.GET_ROLES]),
        roles() {
            return this[Getters.GET_ROLES];
        },
        detailsSections() {
            if (!this.selectedEntity) {
                return [];
            }
            const roleDetails = {
                name: this.$i18n.t('rolesAndPermissions.roleDetails'),
                properties: [
                    {
                        value: this.selectedEntity.name,
                        label: this.$i18n.t('generic.name'),
                    },
                    {
                        value: this.selectedEntity.homepage,
                        label: this.$i18n.t('rolesAndPermissions.homePage'),
                    },
                ],
            };
            // Disabled due to role refactor
            // const permissionDetails = {
            //     name: this.$i18n.t('rolesAndPermissions.permissions'),
            //     properties: [
            //         ...this.componentProperties(),
            //     ],
            // };
            const detailSection = [];
            if (permissionsService.isUserLotusFlareAdmin()) {
                detailSection.unshift(roleDetails);
            }
            return detailSection;
        },
    },
    async created() {
        try {
            this.isDataLoading = true;
            await this[Actions.LOAD_ROLE]();
        } catch (error) {
            this.$eventBus.$emit('showAlert', {
                message: this.$i18n.t('alertMessage.failedToLoadNecessaryData'),
            });
        } finally {
            this.isDataLoading = false;
        }
    },
    methods: {
        ...mapActions('roles', [Actions.LOAD_ROLE]),
        componentProperties() {
            if (this.selectedEntity.permissions) {
                return this.selectedEntity.permissions.map((e, index) => ({
                    label: roleMapper.get(this.selectedEntity.permissions[index]),
                }));
            }
            return [
                {
                    label: 'N/A',
                },
            ];
        },
        displayComponents(arrComponents) {
            if (arrComponents.length > 1) {
                return `${arrComponents.length} ${this.$i18n.t('rolesAndPermissions.permissions')}`;
            }
            return roleMapper.get(arrComponents[0]);
        },
        onSelectEntity(selectedEntityName) {
            this.selectedEntityId = selectedEntityName;
            this.selectedEntity = this.roles.find(e => e.id === selectedEntityName);
            this.isOverviewEnabled = true;
        },
        addRole() {
            this.$router.push({
                name: RouteNames.ROLE_EDITOR,
                params: { companyId: this.$route.params.companyId },
            });
        },
        editRole(id) {
            this.$router.push({
                name: RouteNames.ROLE_EDITOR,
                params: { id, companyId: this.$route.params.companyId },
            });
        },
        showConfirmAlert(id) {
            this.alertButtons.deleteButton = new Button({
                label: this.$i18n.t('generic.confirm'),
                alertType: ALERT_TYPES.warning,
            });
            this.$eventBus.$emit('showAlert', {
                message: this.$i18n.t('alerts.areYouSure'),
                type: ALERT_TYPES.warning,
                buttons: [this.alertButtons.deleteButton],
            });
            this.$eventBus.$once('buttonClicked', buttonId => {
                if (buttonId === this.alertButtons.deleteButton.id) {
                    this.deleteRole(id);
                }
            });
        },
        async deleteRole(id) {
            this.$Progress.start();
            try {
                await deleteRole(id);
                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('alerts.roleDeleted'),
                    type: ALERT_TYPES.success,
                });
                this[Actions.LOAD_ROLE]();
            } catch (e) {
                this.showSupportAlert(this.$i18n.t('alertMessage.somethingWentWrong'), ALERT_TYPES.error);
            } finally {
                this.$Progress.finish();
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/icons';
@import '~@/assets/scss/palette';

$icon-path: '~@/assets/icons/';

.header-bar {
    font-weight: 600;
    background-color: $gray5;
    color: $gray60;

    & > * {
        line-height: 40px;
    }
}
</style>
