/**
 * Parent class for ui components
 */
export default class UIComponentDefinition {
    name; // PropertyDefinition may have a name

    selectables;

    defaultValue() {
        return null;
    } // override

    valueFromJson(jsonValue) {
        return jsonValue && jsonValue.value;
    } // override

    buildJson(rawValue) {
        return rawValue;
    } // override
}
