<template>
    <AbstractListPageWrapper
        :isOverviewEnabled="isOverviewEnabled"
        :pageTitle="pageTitle"
        :entitiesCount="formattedFilteredEntities.length"
        @searchQueryChanged="setSearchQuery"
    >
        <template #button>
            <ResponseModalButton
                :response="servicesApiResponse"
                :title="pageTitle"
            />
        </template>

        <template #filterTable>
            <FilterTable
                :columns="tableColumnsData"
                :multiselectWidth="{ width: '15rem' }"
                @filterAdded="onFilterAdded"
            />
        </template>

        <template #headerButtons>
            <AppButton
                data-testid="create-btn"
                :buttonType="BUTTON_TYPES.PRIMARY"
                :iconType="ICON_TYPES.PLUS"
                :label="$i18n.t('consent.serviceLocale.newEntity')"
                :disabled="!writeEnabled"
                @click="createService"
            />
        </template>

        <template #allFilters>
            <TableFiltersTags
                :filterTableMixin="filterTableMixin"
                class="my-3 ml-2"
                @removeFilter="removeFilter"
                @removeAllFilters="removeAllFilters"
            />
        </template>

        <template #table>
            <AppTable
                data-testid="app-table"
                :entities="formattedFilteredEntities"
                :canSelectColumns="true"
                :canSort="true"
                :selectedEntityId="selectedEntityId"
                :innerSearchQuery="searchQueryForTable"
                :isSearchEnabled="true"
                :isInnerSearchQueryPassed="false"
                :enableRowStateControls="false"
                :entityType="entityType"
                :columnsData="tableColumnsData"
                :isDataLoading="isDataLoading"
                tableKey="services"
                @selectEntity="onEntitySelected"
            >
                <template #status="{ entity }">
                    <div class="d-flex">
                        <AppLabel
                            :title="entity.status"
                            :color="entity.statusColor"
                        />
                    </div>
                </template>
                <template #entityVersion="{ entity }">
                    <EntityStatusIndicator :status="entity.entityVersion" />
                </template>
                <template
                    v-if="writeEnabled"
                    #customRowButtons="{ entity }"
                >
                    <IconButton
                        :label="$i18n.t('generic.edit')"
                        :icon="ICON_TYPES.EDIT"
                        @iconClick="editService(entity)"
                    />
                    <IconButton
                        :label="$i18n.t('generic.delete')"
                        :icon="ICON_TYPES.DELETE"
                        @iconClick="deleteService(entity)"
                    />
                </template>
            </AppTable>
        </template>

        <template #overview>
            <EntityOverview
                :entity="selectedEntity"
                :entityType="entityType"
                @closeOverview="isOverviewEnabled = false"
            >
                <template #section-1-content>
                    <div style="margin-bottom: 1rem">
                        <AppOverviewBlock
                            :items="detailsSection"
                            :isRowTitleCounterVisible="false"
                        />
                    </div>
                    <div
                        v-if="selectedEntity && selectedEntity.type === 'api'"
                        style="margin-bottom: 1rem"
                    >
                        <AppOverviewBlock
                            :title="$t('consent.scope')"
                            :items="generateScopes"
                            :isRowTitleCounterVisible="false"
                        />
                    </div>
                    <div style="margin-bottom: 1rem">
                        <AppOverviewBlock
                            :title="$t('consent.purpose')"
                            :items="generatePurpose"
                            :isRowTitleCounterVisible="false"
                        />
                    </div>
                    <div style="margin-bottom: 1rem">
                        <AppOverviewBlock
                            :title="$t('consent.personalData')"
                            :items="generatePersonalData"
                            :isRowTitleCounterVisible="false"
                        />
                    </div>
                    <div>
                        <AppOverviewBlock
                            :title="$t('consent.legalBasis')"
                            :items="generateLegalBasic"
                            :isRowTitleCounterVisible="false"
                        />
                    </div>
                </template>
            </EntityOverview>
        </template>
    </AbstractListPageWrapper>
</template>

<script>
import { deleteConsentService, getConsentServices } from '@/__new__/services/dno/privacyConsent/http/consent';
import { ALERT_TYPES } from '@/common/alerts/Alert';
import Button from '@/common/button/Button';
import { getMultiLangFieldValueByLocale } from '@/common/entities/entityHelper';
import ENTITY_TYPES from '@/common/entities/entityTypes';
import tableColumnType from '@/common/filterTable';
import { ICON_TYPES } from '@/common/iconHelper';
import TableFiltersTags from '@/components/filters/TableFiltersTags.vue';
import AbstractListPageWrapper from '@/components/layout/AbstractListPageWrapper.vue';
import AppLabel from '@/components/partials/AppLabel.vue';
import AppOverviewBlock from '@/components/partials/AppOverviewBlock.vue';
import AppTable from '@/components/partials/AppTable.vue';
import EntityStatusIndicator from '@/components/partials/EntityStatusIndicator.vue';
import FilterTable from '@/components/partials/FilterTable.vue';
import FilterTableMixin from '@/components/partials/FilterTableMixin.vue';
import IconButton from '@/components/partials/IconButton.vue';
import ResponseModalButton from '@/components/partials/ResponseModalButton.vue';
import EntityOverview from '@/components/partials/entityOverview/EntityOverview.vue';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import RouteNames from '@/router/routeNames';
import { isUserAllowed } from '@/services/permissions/permissions.service';
import { Getters } from '@/store/mutation-types';
import { mapGetters } from 'vuex';

export default {
    name: 'ConsentServiceListPage',
    components: {
        AbstractListPageWrapper,
        AppButton,
        AppTable,
        EntityOverview,
        AppLabel,
        FilterTable,
        TableFiltersTags,
        ResponseModalButton,
        EntityStatusIndicator,
        IconButton,
        AppOverviewBlock,
    },
    mixins: [FilterTableMixin],
    data() {
        return {
            pageTitle: this.$i18n.t('consent.serviceLocale.pageTitle'),
            searchQueryForTable: '',
            selectedLanguage: '',
            selectedEntityId: null,
            selectedEntity: null,
            entityType: ENTITY_TYPES.CONSENT_SERVICE,
            ICON_TYPES,
            BUTTON_TYPES,
            isDataLoading: false,
            consentServices: [],
            consentApiResponses: {},
            getMultiLangFieldValueByLocale,
            isOverviewEnabled: false,
            alertButtons: {
                confirmButton: new Button({
                    label: this.$i18n.t('generic.confirm'),
                    alertType: ALERT_TYPES.warning,
                }),
            },
            apiType: 'api',
            types: [
                {
                    key: 'api',
                    description: 'API',
                },
                {
                    key: 'general',
                    description: 'General',
                },
            ],
            apiTypes: [
                {
                    key: 'internal',
                    description: 'Internal',
                },
                {
                    key: 'partner',
                    description: 'Partner',
                },
            ],
        };
    },
    computed: {
        ...mapGetters('operators', [Getters.languageDefault]),
        services() {
            return this.consentServices || [];
        },
        servicesApiResponse() {
            return this.consentApiResponses || {};
        },
        formattedEntities() {
            return this.services.map(entity => {
                return {
                    ...entity,
                    typeDescription: entity?.type ? this.types.filter(x => x.key === entity.type)[0]?.description : '',
                    createOn: new Date(entity.createOn).getTime(),
                    lastUpdate: new Date(entity.lastUpdate).getTime(),
                };
            });
        },
        formattedEntitiesMap() {
            return this.formattedEntities.reduce((acc, item) => {
                acc[item.id] = {
                    ...item,
                };
                return acc;
            }, {});
        },
        formattedFilteredEntities() {
            return this.filteredEntitiesMixin(this.formattedEntities);
        },
        generalDetails() {
            const rows = [
                {
                    name: this.$t('generic.id'),
                    value: this.selectedEntity?.id,
                },
                {
                    name: this.$t('generic.name'),
                    value: this.selectedEntity?.name,
                },
                {
                    name: this.$t('consent.type'),
                    value: this.selectedEntity?.typeDescription,
                },
                {
                    name: this.$t('consent.createOn'),
                    value: this.$localeLibrary.getFormattedDateAndTime(this.selectedEntity?.createOn),
                },
                {
                    name: this.$t('consent.lastUpdate'),
                    value: this.$localeLibrary.getFormattedDateAndTime(this.selectedEntity?.lastUpdate),
                },
            ];

            // Add API details if type is API
            if (this.selectedEntity?.type === this.apiType) {
                rows.push(
                    {
                        name: this.$t('consent.serviceLocale.apiType'),
                        value: this.selectedEntity?.apiType
                            ? this.apiTypes.filter(x => x.key === this.selectedEntity.apiType)[0]?.description
                            : '',
                    },
                    {
                        name: this.$t('consent.serviceLocale.apiVersion'),
                        value: this.selectedEntity?.apiVersion,
                    },
                    {
                        name: this.$t('consent.serviceLocale.apiPath'),
                        value: this.selectedEntity?.apiPath,
                    },
                );
            }

            return [
                {
                    name: this.$t('generic.general'),
                    isCollapsed: false,
                    rows,
                },
            ];
        },
        generateScopes() {
            return this.selectedEntity?.scope?.map(scope => {
                return {
                    name: scope.key,
                    isCollapsed: false,
                    rows: [
                        {
                            name: this.$t('generic.key'),
                            value: scope.key,
                        },
                        {
                            name: this.$t('generic.description'),
                            value: scope.description,
                        },
                    ],
                };
            });
        },
        generatePurpose() {
            return this.selectedEntity?.validPurpose?.map(purpose => {
                return {
                    name: purpose.key,
                    isCollapsed: false,
                    rows: [
                        {
                            name: this.$t('generic.key'),
                            value: purpose.key,
                        },
                        {
                            name: this.$t('generic.description'),
                            value: purpose.description,
                        },
                    ],
                };
            });
        },
        generatePersonalData() {
            return this.selectedEntity?.personalDataCategories?.map(personalData => {
                return {
                    name: personalData.key,
                    isCollapsed: false,
                    rows: [
                        {
                            name: this.$t('generic.key'),
                            value: personalData.key,
                        },
                        {
                            name: this.$t('generic.description'),
                            value: personalData.description,
                        },
                    ],
                };
            });
        },
        generateLegalBasic() {
            return this.selectedEntity?.legalBasis?.map(legalBasis => {
                return {
                    name: legalBasis.key,
                    isCollapsed: false,
                    rows: [
                        {
                            name: this.$t('generic.key'),
                            value: legalBasis.key,
                        },
                        {
                            name: this.$t('generic.description'),
                            value: legalBasis.description,
                        },
                    ],
                };
            });
        },
        detailsSection() {
            return this.selectedEntityId ? this.generalDetails : [];
        },
        tableColumnsData() {
            const columns = [
                {
                    name: this.$i18n.t('generic.name'),
                    key: 'name',
                    field: 'name',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('consent.type'),
                    key: 'typeDescription',
                    field: 'typeDescription',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('consent.createOn'),
                    key: 'createOn',
                    mapper: entity => this.$localeLibrary.getFormattedDateAndTime(entity.createOn),
                    sortBy: entity => entity.createOn,
                    field: 'createOn',
                    filterType: tableColumnType.DATE,
                },
                {
                    name: this.$i18n.t('consent.lastUpdate'),
                    key: 'lastUpdate',
                    mapper: entity => this.$localeLibrary.getFormattedDateAndTime(entity.lastUpdate),
                    sortBy: entity => entity.lastUpdate,
                    field: 'lastUpdate',
                    filterType: tableColumnType.DATE,
                },
            ];
            return columns;
        },
        writeEnabled() {
            return isUserAllowed('ConsentServiceWrite');
        },
    },
    async created() {
        this.isDataLoading = true;
        await this.$withProgressBar(
            async () => {
                await this.fetchData();
                this.selectedLanguage = this[Getters.languageDefault];
                this.isDataLoading = false;
            },
            {
                errorHandler: () => {
                    this.isDataLoading = false;
                    this.$alert(this.$i18n.t('alertMessage.failedToLoadNecessaryData'), {
                        type: ALERT_TYPES.error,
                    });
                },
            },
        );
    },
    methods: {
        async fetchData() {
            this.consentApiResponses = (await getConsentServices()) || {};
            this.consentServices = this.consentApiResponses?.data || [];
        },
        createService() {
            this.$router.push({
                name: RouteNames.CONSENT_SERVICE_EDITOR,
                params: { companyId: this.$route.params.companyId },
            });
        },
        editService(entity) {
            this.$router.push({
                name: RouteNames.CONSENT_SERVICE_EDITOR,
                params: {
                    id: entity.id,
                    companyId: this.$route.params.companyId,
                },
            });
        },
        deleteService(entity) {
            this.$alert(this.$t('alerts.areYouSure'), {
                type: this.$ALERT_TYPES.warning,
                buttons: [this.alertButtons.confirmButton],
            });
            this.$eventBus.$once('buttonClicked', async buttonId => {
                if (buttonId === this.alertButtons.confirmButton.id) {
                    this.isDataLoading = true;
                    await this.$withProgressBar(
                        async () => {
                            await deleteConsentService(entity.id);
                            await this.fetchData();
                            this.isDataLoading = false;
                        },
                        {
                            errorHandler: error => {
                                this.isDataLoading = false;
                                if (error?.response?.status === 400 && error?.response?.data?.code === 'PPM-012') {
                                    this.$eventBus.$emit('showAlert', {
                                        message: this.$i18n.t('consent.alerts.serviceForbidDelete'),
                                    });
                                } else {
                                    this.$alert(this.$i18n.t('entityStatesControl.couldNotDelete'), {
                                        type: ALERT_TYPES.error,
                                    });
                                }
                            },
                        },
                    );
                }
            });
        },
        onEntitySelected(id) {
            this.selectedEntity = this.formattedEntitiesMap[id];
            this.selectedEntityId = id;
            this.isOverviewEnabled = true;
        },
        setSearchQuery(query) {
            this.searchQueryForTable = query;
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep table .icon-button-container:last-child {
    margin-right: 0.5rem;
}
</style>
