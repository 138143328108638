<template>
    <div :class="{ 'customer-care-suite-style': customerCareSuiteStyling }">
        <ul class="d-flex breadcrumb-navigation heading-sm blue-400">
            <li
                v-for="(breadcrumb, index) in breadcrumbList"
                :key="breadcrumb.name"
                :class="{ clickable: isBreadcrumbClickable(breadcrumb) }"
                @click="routeTo(index)"
            >
                {{ breadcrumb.name }}
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    name: 'Breadcrumbs',
    props: {
        /**
         * Array<Object>
         * @param name - name to render
         * @param link - when passed -> breadcrumb is clickabke and vise versa
         */
        breadcrumbList: {
            required: true,
            type: Array,
        },
        routeOnClick: {
            required: false,
            type: Boolean,
            default: true,
        },
        customerCareSuiteStyling: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        routeTo(breadcrumbIndex) {
            const clickedBreadcrumb = this.breadcrumbList[breadcrumbIndex];
            if (clickedBreadcrumb.link) {
                if (this.routeOnClick) {
                    this.$router.push({
                        path: `/${this.$route.params.companyId}${clickedBreadcrumb.link}`,
                    });
                } else {
                    this.$emit('breadcrumbClicked', clickedBreadcrumb);
                }
            }
        },
        isBreadcrumbClickable(breadcrumb) {
            return Boolean(breadcrumb.link);
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/_consistency';
@import '~@/assets/scss/icons';

$icon-path: '~@/assets/icons/';

.breadcrumb-navigation {
    margin: $spacing-xl 0;

    .clickable {
        color: $blue-400;
        cursor: pointer;

        &:hover {
            text-decoration: underline;
        }

        &::after {
            content: url($icon-path + $arrow-breadcrumbs);
            height: 24px;
            width: 24px;
        }
    }
}

.customer-care-suite-style {
    background-color: $gray-100;
    padding: $spacing-xl $spacing-xl 0;

    .breadcrumb-navigation {
        background-color: $gray-100;
        margin: 0;
    }
}
</style>
