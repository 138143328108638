<template>
    <nav
        :class="{ compact }"
        class="sidebar"
    >
        <!-- Logo -->
        <div class="lf-logo-container">
            <router-link
                :to="{ name: homeRoute, params: { companyId: $route.params.companyId } }"
                class="lf-logo"
            >
                <transition name="slide-fade">
                    <img
                        v-show="!compact"
                        class=""
                        src="../../assets/icons/logo-and-name.svg"
                        alt="LF-Logo"
                    />
                </transition>

                <transition name="slide-fade">
                    <img
                        v-show="compact"
                        class="sidebar-logo"
                        src="../../assets/icons/logo-sign.svg"
                        alt="LF-Logo"
                    />
                </transition>
            </router-link>
        </div>

        <div
            :class="{ compact }"
            class="sidebar-main-menu"
        >
            <div class="item-group">
                <div
                    v-for="(menuItem, index) in navigationOptions"
                    :key="`${menuItem.name}${index}`"
                >
                    <SidebarMenuItem
                        :ref="`mainMenuItem${index}`"
                        :entity="menuItem"
                        :compact="compact"
                        :isActive="activeItem"
                        :subActive="activeSubItem"
                        :dropdownVisible="currentDropdownVisible"
                        :tenantDropdownVisible="tenantDropdownVisible"
                        @parentActive="setActiveParent"
                        @toggleDropdown="toggleMenuDropdown"
                        @subActive="setActiveSubItem"
                    />
                </div>
            </div>

            <div class="item-group">
                <template v-if="isModeSwitcherEnabled">
                    <ModeSwitcher :compact="compact" />
                </template>
                <div
                    v-for="(menuItem, index) in accountOptions"
                    :key="index"
                >
                    <SidebarMenuItem
                        :ref="`footerMenuItem${index}`"
                        :entity="menuItem"
                        :compact="compact"
                        :isActive="activeItem"
                        :subActive="activeSubItem"
                        :dropdownVisible="currentDropdownVisible"
                        :tenantDropdownVisible="tenantDropdownVisible"
                        @parentActive="setActiveParent"
                        @toggleDropdown="toggleMenuDropdown"
                        @subActive="setActiveSubItem"
                    />
                </div>
                <template v-if="accessToTenantSwitcher">
                    <TenantSwitcher
                        :compact="compact"
                        :isActive="activeItem"
                        :showDropdown="tenantDropdownVisible"
                        @tenantDropdownVisible="handleTenantDropdown"
                    />
                </template>
            </div>
        </div>

        <div class="sidebar-footer">
            <div
                class="toggler"
                @click="toggleSidebar"
            >
                <div
                    :class="{ compact: compact }"
                    class="toggler-icon"
                >
                    <img
                        src="../../assets/icons/sidebar/arrow-right.svg"
                        alt="arrow-right"
                    />
                </div>
                <span
                    v-show="!compact"
                    class="ml-3"
                    >{{ envLabel }}
                </span>
            </div>
        </div>
    </nav>
</template>

<script>
// Libraries, services and helpers
import localeLibrary from '@/common/locale/localeLibrary';
import RouteNames from '@/router/routeNames';
import permissionsService, {
    getOperatorConfigValue,
    isUserAllowed,
    isUserInternal,
    isViewConfig,
    isViewEnabled,
} from '@/services/permissions/permissions.service';

// Vue components
import ModeSwitcher from '@/components/sidebar/ModeSwitcher.vue';
import SidebarMenuItem from '@/components/sidebar/SidebarMenuItem.vue';
import TenantSwitcher from '@/components/sidebar/TenantSwitcher.vue';

// helpers
import { USER_MODES } from '@/common/commonHelper';
import { ICON_TYPES } from '@/common/iconHelper';
import { PLURALIZATION } from '@/common/locale/labelSingularOrPlural';

// Vuex
import { ALL_LINE_AUTH_PERMISSIONS } from '@/__new__/services/dno/ossdevedge/models/QodMsisdnPortal';
import Actions from '@/store/mutation-types';
import { mapGetters } from 'vuex';

export default {
    name: 'Sidebar',

    components: {
        SidebarMenuItem,
        TenantSwitcher,
        ModeSwitcher,
    },

    data() {
        return {
            homeRoute: RouteNames.HOME_VIEW,
            activeItem: null,
            activeSubItem: null,
            currentDropdownVisible: '',
            tenantDropdownVisible: false,
            reloadNavigationOptions: 0,
            isModeSwitcherEnabled: permissionsService.isUserLotusFlareAdmin() && isUserInternal(),
        };
    },

    computed: {
        ...mapGetters({
            envLabel: 'config/getEnvLabel',
        }),
        navigationOptions() {
            let customerCareMenuSubItem = {
                name: this.$i18n.t('customerCare.userManager'),
                link: '/customer-care/user-management',
                mode: USER_MODES.REGULAR,
                enabled:
                    permissionsService.userManagementEnabled() &&
                    isUserAllowed(
                        'UMUserRead',
                        'UMUserGrantPermission',
                        'UMUserEditUserUpdateFields',
                        'UMUserEditUserToggleFlag',
                    ),
            };

            if (getOperatorConfigValue('cssDefault')) {
                customerCareMenuSubItem = {
                    name: this.$i18n.t('customerCare.userManager'),
                    icon: ICON_TYPES.SIDEBAR_USER_MANAGEMENT,
                    link: '/customer-care-suite',
                    mode: USER_MODES.REGULAR,
                    enabled: isUserAllowed('CCSAccess'),
                };
            }

            const sidebarOptions = [
                {
                    name: this.$i18n.t('home.home'),
                    link: '/',
                    mode: USER_MODES.REGULAR,
                    enabled: true,
                    icon: ICON_TYPES.SIDEBAR_HOME,
                },
                {
                    name: this.$i18n.t('productCatalog.productCatalog'),
                    icon: ICON_TYPES.SIDEBAR_PRODUCT_CATALOG,
                    enabled:
                        permissionsService.productCatalogEnabled() &&
                        (isUserAllowed(
                            'TemplatesRead',
                            'TemplatesWrite',
                            'PresetTemplatesRead',
                            'PresetTemplatesWrite',
                            'CategoriesRead',
                            'CategoriesWrite',
                            'FacetsRead',
                            'FacetsWrite',
                            'OffersRead',
                            'OffersWrite',
                            'ProductsRead',
                            'ProductsWrite',
                            'ServicesRead',
                            'ServicesWrite',
                            'ResourcesRead',
                            'ResourcesWrite',
                        ) ||
                            permissionsService.isFeatureFlagEnabled('validation-rules') ||
                            getOperatorConfigValue('isAtiotDemo')),
                    subItems: [
                        {
                            name: this.$i18n.tc('productCatalog.entities.template', PLURALIZATION.PLURAL),
                            link: '/pc/templates',
                            mode: USER_MODES.REGULAR,
                            enabled: isUserAllowed('TemplatesRead') || isUserAllowed('TemplatesWrite'),
                        },
                        {
                            name: this.$i18n.tc('productCatalog.entities.presetTemplate', PLURALIZATION.PLURAL),
                            link: '/pc/preset-templates',
                            mode: USER_MODES.REGULAR,
                            enabled: isUserAllowed('PresetTemplatesRead') || isUserAllowed('PresetTemplatesWrite'),
                        },
                        {
                            name: this.$i18n.t('productCatalog.entities.plural.categories'),
                            link: '/pc/categories',
                            mode: USER_MODES.REGULAR,
                            enabled: isUserAllowed('CategoriesRead') || isUserAllowed('CategoriesWrite'),
                        },
                        {
                            name: this.$i18n.tc('productCatalog.entities.facet', PLURALIZATION.PLURAL),
                            link: '/pc/facets',
                            mode: USER_MODES.REGULAR,
                            enabled: isUserAllowed('FacetsRead') || isUserAllowed('FacetsWrite'),
                        },
                        {
                            name: this.$i18n.tc('productCatalog.entities.offer', PLURALIZATION.PLURAL),
                            link: '/pc/offers',
                            mode: USER_MODES.REGULAR,
                            enabled: isUserAllowed('OffersRead') || isUserAllowed('OffersWrite'),
                        },
                        {
                            name: this.$i18n.tc('productCatalog.entities.offerPrice', PLURALIZATION.PLURAL),
                            link: '/pc/offer-prices',
                            mode: USER_MODES.REGULAR,
                            enabled: getOperatorConfigValue('isAtiotDemo'),
                        },
                        {
                            name: this.$i18n.tc('productCatalog.entities.product', PLURALIZATION.PLURAL),
                            link: '/pc/products',
                            mode: USER_MODES.REGULAR,
                            enabled: isUserAllowed('ProductsRead') || isUserAllowed('ProductsWrite'),
                        },
                        {
                            name: this.$i18n.tc('productCatalog.entities.service', PLURALIZATION.PLURAL),
                            link: '/pc/services',
                            mode: USER_MODES.REGULAR,
                            enabled: isUserAllowed('ServicesRead') || isUserAllowed('ServicesWrite'),
                        },
                        {
                            name: this.$i18n.tc('productCatalog.entities.resource', PLURALIZATION.PLURAL),
                            link: '/pc/resources',
                            mode: USER_MODES.REGULAR,
                            enabled: isUserAllowed('ResourcesRead') || isUserAllowed('ResourcesWrite'),
                        },
                        {
                            name: this.$i18n.tc('productCatalog.entities.validationRule', PLURALIZATION.PLURAL),
                            link: '/pc/validation-rules',
                            mode: USER_MODES.REGULAR,
                            enabled: isUserAllowed('ValidationRulesRead', 'ValidationRulesWrite'),
                        },
                        /* TODO: enable once ENUMs are enabled
                            {
                                name: this.$i18n.tc('productCatalog.entities.template'),
                                icon: 'product-catalog-sidebar',
                                enabled: isUserInternal(),
                                subItems: [
                                    {
                                        name: this.$i18n.tc('productCatalog.entities.enums', PLURALIZATION.PLURAL),
                                        link: '/pc/enums',
                                        enabled: productCatalogEnumsEnabled,
                                    },
                                ],
                            },
                            */
                    ],
                },
                {
                    name: this.$i18n.t('consent.consent'),
                    icon: ICON_TYPES.SIDEBAR_ORCHESTRATION,
                    enabled: isUserAllowed(
                        'ConsentServiceRead',
                        'ConsentServiceWrite',
                        'PartyPrivacyProfileRead',
                        'PartyPrivacyProfileReadWrite',
                        'PartyPrivacyProfileSpecificationRead',
                        'PartyPrivacyProfileSpecificationWrite',
                    ),
                    subItems: [
                        {
                            name: this.$i18n.tc('consent.template', PLURALIZATION.PLURAL),
                            link: '/consent/templates',
                            mode: USER_MODES.REGULAR,
                            enabled: isUserAllowed(
                                'PartyPrivacyProfileSpecificationRead',
                                'PartyPrivacyProfileSpecificationWrite',
                            ),
                        },
                        {
                            name: this.$i18n.tc('consent.partyPrivacyProfileSpecification', PLURALIZATION.PLURAL),
                            link: '/consent/partyPrivacyProfileSpecification',
                            mode: USER_MODES.REGULAR,
                            enabled: isUserAllowed(
                                'PartyPrivacyProfileSpecificationRead',
                                'PartyPrivacyProfileSpecificationWrite',
                            ),
                        },
                        {
                            name: this.$i18n.tc('consent.service', PLURALIZATION.PLURAL),
                            link: '/consent/service',
                            mode: USER_MODES.REGULAR,
                            enabled: isUserAllowed('ConsentServiceRead', 'ConsentServiceWrite'),
                        },
                        {
                            name: this.$i18n.tc('consent.partyPrivacyProfile', PLURALIZATION.PLURAL),
                            link: '/consent/partyPrivacyProfile',
                            mode: USER_MODES.REGULAR,
                            enabled: isUserAllowed('PartyPrivacyProfileRead', 'PartyPrivacyProfileReadWrite'),
                        },
                        {
                            name: this.$i18n.t('consent.profileBulkUpload', PLURALIZATION.PLURAL),
                            link: '/consent/profileBulkUpload',
                            mode: USER_MODES.REGULAR,
                            enabled: isUserAllowed('PartyPrivacyProfileRead', 'PartyPrivacyProfileReadWrite'),
                        },
                    ],
                },
                {
                    name: this.$i18n.t('operateAPIs.operateAPIs'),
                    icon: ICON_TYPES.SIDEBAR_ORCHESTRATION,
                    enabled: isUserAllowed(
                        'OperateAPIsApplicationOwnersRead',
                        'OperateAPIsApplicationsRead',
                        'OperateAPIsProductsRead',
                        'OperateAPIsProductOrdersRead',
                    ),
                    subItems: [
                        {
                            name: this.$i18n.tc('operateAPIs.applicationOwners', PLURALIZATION.PLURAL),
                            link: '/operate-apis/application-owners',
                            mode: USER_MODES.REGULAR,
                            enabled:
                                isUserAllowed('OperateAPIsApplicationOwnersRead') ||
                                isUserAllowed('OperateAPIsApplicationOwnersWrite'),
                        },
                        {
                            name: this.$i18n.t('operateAPIs.applications'),
                            link: '/operate-apis/applications',
                            mode: USER_MODES.REGULAR,
                            enabled:
                                isUserAllowed('OperateAPIsApplicationsRead') ||
                                isUserAllowed('OperateAPIsApplicationsWrite'),
                        },
                        {
                            name: this.$i18n.t('operateAPIs.apiProducts'),
                            link: '/operate-apis/api-products',
                            mode: USER_MODES.REGULAR,
                            enabled:
                                isUserAllowed('OperateAPIsProductsRead') || isUserAllowed('OperateAPIsProductsWrite'),
                        },
                        {
                            name: this.$i18n.t('operateAPIs.apiProductOrders'),
                            link: '/operate-apis/api-product-orders',
                            mode: USER_MODES.REGULAR,
                            enabled:
                                isUserAllowed('OperateAPIsProductOrdersRead') ||
                                isUserAllowed('OperateAPIsProductOrdersWrite'),
                        },
                        {
                            name: this.$i18n.t('operateAPIs.channelPartners'),
                            link: '/operate-apis/channel-partners',
                            mode: USER_MODES.REGULAR,
                            enabled: isUserAllowed('OperateAPIsChannelPartnersRead') && isUserInternal(),
                        },
                    ],
                },
                {
                    name: this.$i18n.t('pricingAndFees.pricingAndFees'),
                    icon: ICON_TYPES.SIDEBAR_PRICING_AND_FEES,
                    enabled:
                        permissionsService.pricingAndFeesEnabled() &&
                        isUserAllowed('FeeRulesRead', 'FeeRulesWrite', 'PricingRulesRead', 'PricingRulesWrite'),
                    subItems: [
                        {
                            name: this.$i18n.t('pricingAndFees.feeRules'),
                            link: '/pricing-and-fees/fee-rules',
                            mode: USER_MODES.EXPERIMENTAL,
                            enabled:
                                permissionsService.feeRulesEnabled() &&
                                (isUserAllowed('FeeRulesRead') || isUserAllowed('FeeRulesWrite')),
                        },
                        {
                            name: this.$i18n.t('pricing.pricingRules'),
                            link: '/pricing-and-fees/pricing-rules',
                            mode: USER_MODES.POWER,
                            enabled:
                                isViewConfig() &&
                                isViewEnabled('PricingRules') &&
                                (isUserAllowed('PricingRulesRead') || isUserAllowed('PricingRulesWrite')),
                        },
                    ],
                },
                {
                    name: this.$i18n.t('charging.charging'),
                    icon: ICON_TYPES.SIDEBAR_CHARGING,
                    enabled:
                        permissionsService.chargingEnabled() &&
                        isUserAllowed(
                            'ChargingSpecificationsReadOnly',
                            'ChargingSpecificationsReadWrite',
                            'ChargingSpecificationGroupsReadOnly',
                            'ChargingSpecificationGroupsReadWrite',
                            'PolicyCountersReadOnly',
                            'PolicyCountersReadWrite',
                            'PolicyRulesReadOnly',
                            'PolicyRulesReadWrite',
                            'RatingGroupsReadOnly',
                            'RatingGroupsReadWrite',
                            'UsageCountersReadOnly',
                            'UsageCountersReadWrite',
                            'ConditionParametersReadOnly',
                            'ConditionParametersReadWrite',
                            'WalletCountersReadOnly',
                            'WalletCountersReadWrite',
                            'ContentTypesReadOnly',
                            'ContentTypesWrite',
                            'TariffSpecificationsRead',
                            'TariffSpecificationsWrite',
                            'TariffSpecificationsZeroRateRead',
                            'TariffSpecificationsZeroRateWrite',
                            'TariffSpecificationGroupsRead',
                            'TariffSpecificationGroupsWrite',
                            'ChargingBucketWizard',
                        ),
                    subItems: [
                        {
                            name: this.$i18n.t('charging.entities.plural.chargingSpecifications'),
                            link: '/charging/charging-specifications',
                            mode: USER_MODES.REGULAR,
                            enabled:
                                permissionsService.chargingChargingSpecificationsEnabled() &&
                                (isUserAllowed('ChargingSpecificationsReadOnly') ||
                                    isUserAllowed('ChargingSpecificationsReadWrite')),
                        },
                        {
                            name: this.$i18n.t('charging.entities.plural.chargingSpecificationsGroups'),
                            link: '/charging/charging-specifications-groups',
                            mode: USER_MODES.REGULAR,
                            enabled:
                                permissionsService.chargingChargingSpecificationsGroupsEnabled() &&
                                (isUserAllowed('ChargingSpecificationGroupsReadOnly') ||
                                    isUserAllowed('ChargingSpecificationGroupsReadWrite')),
                        },
                        {
                            name: this.$i18n.t('charging.entities.plural.policyCounters'),
                            link: '/charging/policy-counters',
                            mode: USER_MODES.REGULAR,
                            enabled:
                                permissionsService.chargingPolicyCountersEnabled() &&
                                (isUserAllowed('PolicyCountersReadOnly') || isUserAllowed('PolicyCountersReadWrite')),
                        },
                        {
                            name: this.$i18n.t('charging.entities.plural.policyRules'),
                            link: '/charging/policy-rules',
                            mode: USER_MODES.REGULAR,
                            enabled:
                                permissionsService.chargingPolicyRulesEnabled() &&
                                (isUserAllowed('PolicyRulesReadOnly') || isUserAllowed('PolicyRulesReadWrite')),
                        },
                        {
                            name: this.$i18n.t('productCatalog.entities.plural.ratingGroups'),
                            link: '/charging/rating-groups',
                            mode: USER_MODES.REGULAR,
                            enabled:
                                permissionsService.chargingRatingGroupsEnabled() &&
                                (isUserAllowed('RatingGroupsReadOnly') || isUserAllowed('RatingGroupsReadWrite')),
                        },
                        {
                            name: this.$i18n.tc('charging.usageCounters.name', PLURALIZATION.PLURAL),
                            link: '/charging/usage-counters',
                            mode: USER_MODES.REGULAR,
                            enabled:
                                permissionsService.chargingUsageCountersEnabled() &&
                                (isUserAllowed('UsageCountersReadOnly') || isUserAllowed('UsageCountersReadWrite')),
                        },
                        {
                            name: this.$i18n.t('charging.entities.plural.conditionParameters'),
                            link: '/charging/condition-parameters',
                            mode: USER_MODES.REGULAR,
                            enabled:
                                permissionsService.chargingConditionParametersEnabled() &&
                                (isUserAllowed('ConditionParametersReadOnly') ||
                                    isUserAllowed('ConditionParametersReadWrite')),
                        },
                        {
                            name: this.$i18n.t('charging.entities.plural.walletCounters'),
                            link: '/charging/wallet-counters',
                            mode: USER_MODES.REGULAR,
                            enabled:
                                permissionsService.chargingWalletCountersEnabled() &&
                                (isUserAllowed('WalletCountersReadOnly') || isUserAllowed('WalletCountersReadWrite')),
                        },
                        {
                            name: this.$i18n.t('charging.contentTypes.name'),
                            link: '/charging/content-types',
                            mode: USER_MODES.REGULAR,
                            enabled:
                                permissionsService.chargingContentTypesEnabled() &&
                                isUserAllowed('ContentTypesReadOnly', 'ContentTypesWrite'),
                        },
                        {
                            name: this.$i18n.tc('charging.tariffSpec.name', PLURALIZATION.PLURAL),
                            link: '/charging/tariff-specifications',
                            mode: USER_MODES.REGULAR,
                            enabled:
                                permissionsService.chargingTarifSpecificationsEnabled() &&
                                isUserAllowed('TariffSpecificationsRead', 'TariffSpecificationsWrite'),
                        },
                        {
                            name: this.$i18n.tc('charging.tariffSpec.zeroRateName', PLURALIZATION.PLURAL),
                            link: '/charging/tariff-specifications/zero-rate',
                            mode: USER_MODES.REGULAR,
                            enabled:
                                permissionsService.chargingTarifSpecificationsZeroRateEnabled() &&
                                isUserAllowed('TariffSpecificationsZeroRateRead', 'TariffSpecificationsZeroRateWrite'),
                        },
                        {
                            name: this.$i18n.tc('charging.tariffSpecGroup.name', PLURALIZATION.PLURAL),
                            link: '/charging/tariff-specification-groups',
                            mode: USER_MODES.REGULAR,
                            enabled:
                                permissionsService.chargingTarifSpecificationGroupsEnabled() &&
                                isUserAllowed('TariffSpecificationGroupsRead', 'TariffSpecificationGroupsWrite'),
                        },
                        {
                            name: this.$i18n.t('charging.bucketWizard.name'),
                            link: '/charging/bucket-wizard-search',
                            mode: USER_MODES.POWER,
                            enabled: isUserInternal() && isUserAllowed('ChargingBucketWizard'),
                        },
                    ],
                },
                {
                    name: this.$i18n.t('promotions.promotions'),
                    link: '/promotions',
                    mode: USER_MODES.REGULAR,
                    enabled:
                        permissionsService.promotionsEnabled() &&
                        isUserAllowed('PromotionRuleRead', 'PromotionRulesWrite'),
                    icon: ICON_TYPES.SIDEBAR_PROMOTIONS,
                },
                {
                    name: this.$i18n.t('rewards.rewardsTitle'),
                    icon: ICON_TYPES.SIDEBAR_REWARDS,
                    enabled:
                        permissionsService.rewardsEnabled() &&
                        isUserAllowed(
                            'RewardRulesRead',
                            'RewardRulesWrite',
                            'PayoutsRead',
                            'PayoutsWrite',
                            'VouchersSetsRead',
                            'VouchersSetsWrite',
                            'PrizesRead',
                            'PrizesWrite',
                            'PrizeEngineRead',
                            'PrizeEngineWrite',
                            'LotteriesRead',
                            'LotteriesWrite',
                        ),
                    subItems: [
                        {
                            name: this.$i18n.t('rewards.rewardRules'),
                            link: '/rewards',
                            mode: USER_MODES.REGULAR,
                            enabled:
                                permissionsService.rewardsEnabled() &&
                                (isUserAllowed('RewardRulesRead') || isUserAllowed('RewardRulesWrite')),
                        },
                        {
                            name: this.$i18n.t('generic.payouts'),
                            link: '/payouts',
                            mode: USER_MODES.REGULAR,
                            enabled:
                                permissionsService.payoutEnabled() &&
                                (isUserAllowed('PayoutsRead') || isUserAllowed('PayoutsWrite')),
                        },
                        {
                            name: this.$i18n.t('productCatalog.entities.plural.voucherSets'),
                            link: '/vouchers',
                            mode: USER_MODES.REGULAR,
                            enabled:
                                permissionsService.voucherEnabled() &&
                                (isUserAllowed('VouchersSetsRead') || isUserAllowed('VouchersSetsWrite')),
                        },
                        {
                            name: this.$i18n.t('rewards.prizes'),
                            link: '/prizes',
                            mode: USER_MODES.REGULAR,
                            enabled:
                                permissionsService.prizeEnabled() &&
                                (isUserAllowed('PrizesRead') || isUserAllowed('PrizesWrite')),
                        },
                        {
                            name: this.$i18n.t('rewards.prizeEngine'),
                            link: '/prize-engines',
                            mode: USER_MODES.REGULAR,
                            enabled:
                                permissionsService.prizeEngineEnabled() &&
                                (isUserAllowed('PrizeEngineRead') || isUserAllowed('PrizeEngineWrite')),
                        },
                        {
                            name: this.$i18n.t('rewards.lottery.lottery'),
                            link: '/lotteries',
                            mode: USER_MODES.REGULAR,
                            enabled:
                                permissionsService.lotteryEnabled() && isUserAllowed('LotteriesRead', 'LotteriesWrite'),
                        },
                    ],
                },
                {
                    name: this.$i18n.t('productCatalog.offers.editor.pricing'),
                    icon: ICON_TYPES.WALLET,
                    enabled: getOperatorConfigValue('isKuiperDemo'),
                    subItems: [
                        {
                            name: this.$i18n.t('pricing.pricingRules'),
                            link: '/pricing-rules-kuiper-demo',
                            mode: USER_MODES.REGULAR,
                            enabled: getOperatorConfigValue('isKuiperDemo'),
                        },
                    ],
                },
                {
                    name: this.$i18n.t('billing.billing'),
                    icon: ICON_TYPES.RECEIPT,
                    enabled: permissionsService.isFeatureFlagEnabled('postpaid-demo'),
                    subItems: [
                        {
                            name: this.$i18n.t('billing.dunningProfiles'),
                            link: '/billing/dunning-profiles',
                            mode: USER_MODES.REGULAR,
                            enabled: permissionsService.isFeatureFlagEnabled('postpaid-demo'),
                        },
                        {
                            name: this.$i18n.t('billing.cycleSpecification'),
                            link: '/billing/cycle',
                            mode: USER_MODES.REGULAR,
                            enabled: permissionsService.isFeatureFlagEnabled('postpaid-demo'),
                        },
                        {
                            name: this.$i18n.t('billingAccount.billingAccount'),
                            icon: ICON_TYPES.SIDEBAR_USER_MANAGEMENT,
                            link: '/customer-care-suite',
                            mode: USER_MODES.REGULAR,
                            enabled: permissionsService.isFeatureFlagEnabled('postpaid-demo'),
                        },
                    ],
                },
                {
                    name: this.$i18n.t('messagesSection.messages'),
                    icon: ICON_TYPES.SIDEBAR_CAMPAIGNS,
                    enabled:
                        permissionsService.cepEnabled() &&
                        isUserAllowed(
                            'ServiceCampaignsRead',
                            'ServiceCampaignsWrite',
                            'MarketingCampaignsRead',
                            'MarketingCampaignsWrite',
                        ),
                    subItems: [
                        {
                            name: this.$i18n.t('messagesSection.campaigns'),
                            link: '/messages/campaigns',
                            mode: USER_MODES.REGULAR,
                            enabled:
                                permissionsService.cepCampaignsEnabled() &&
                                isUserAllowed(
                                    'ServiceCampaignsRead',
                                    'ServiceCampaignsWrite',
                                    'MarketingCampaignsRead',
                                    'MarketingCampaignsWrite',
                                ),
                        },
                        {
                            name: this.$i18n.t('campaigns.templateTester'),
                            link: '/messages/message_template_tester',
                            mode: USER_MODES.REGULAR,
                            enabled: isUserAllowed('TemplateTester'),
                        },
                    ],
                },
                {
                    name: this.$i18n.t('customer.customer360'),
                    icon: ICON_TYPES.SIDEBAR_USER_MANAGEMENT,
                    link: '/bop/customer-management/business-customer',
                    mode: USER_MODES.REGULAR,
                    enabled: isUserAllowed('BOPCustomerRead'),
                },
                {
                    name: this.$i18n.t('segments.segments'),
                    icon: ICON_TYPES.SIDEBAR_SEGMENTS,
                    link: '/segments/manage-segments',
                    mode: USER_MODES.REGULAR,
                    enabled: permissionsService.segmentsEnabled() && isUserAllowed('SegmentsRead', 'SegmentsWrite'),
                },
                {
                    name: this.$i18n.t('sidebar.dataHub'),
                    icon: ICON_TYPES.SIDEBAR_DATA_HUB,
                    enabled:
                        isUserAllowed(
                            'EventsRead',
                            'ReportConfigurationsRead',
                            'TableConfigurationsRead',
                            'JsonPathTesterRead',
                        ) ||
                        (isUserInternal() &&
                            (isUserAllowed(
                                'KafkaConfigurationsRead',
                                'APIDestinationsRead',
                                'SharedFileLocationsRead',
                                'DataflowWrite',
                                'DlqDashboardRead',
                            ) ||
                                isViewEnabled('DataHubChatbot'))),
                    subItems: [
                        {
                            name: this.$i18n.t('generic.events'),
                            link: '/data-hub/events',
                            mode: USER_MODES.REGULAR,
                            enabled: isUserAllowed('EventsRead'),
                        },
                        {
                            name: this.$t('customFilters.customFilters'),
                            link: '/data-hub/custom-filters',
                            mode: USER_MODES.REGULAR,
                            enabled: false,
                        },
                        {
                            name: this.$i18n.t('analytics.reportsConfig'),
                            link: '/data-hub/reports-configuration',
                            mode: USER_MODES.REGULAR,
                            enabled: isUserAllowed('ReportConfigurationsRead'),
                        },
                        {
                            name: this.$i18n.t('analytics.tableConfig'),
                            link: '/data-hub/table-configuration',
                            mode: USER_MODES.REGULAR,
                            enabled: isUserAllowed('TableConfigurationsRead'),
                        },
                        {
                            name: this.$i18n.t('generic.kafkaConfiguration'),
                            link: '/data-hub/kafka-configuration',
                            mode: USER_MODES.REGULAR,
                            enabled: isUserInternal() && isUserAllowed('KafkaConfigurationsRead'),
                        },
                        {
                            name: this.$i18n.t('generic.apiDestinations'),
                            link: '/data-hub/api-invoker-configuration',
                            mode: USER_MODES.REGULAR,
                            enabled: isUserInternal() && isUserAllowed('APIDestinationsRead'),
                        },
                        {
                            name: this.$t('sinkConfigs.eventComposition.eventComposition'),
                            link: '/data-hub/event-composition-dashboard',
                            mode: USER_MODES.REGULAR,
                            enabled: isUserInternal() && isUserAllowed('EventCompositionConfigurationsRead'),
                        },
                        {
                            name: this.$i18n.t('pipelines.sharedFileLocations.title'),
                            link: '/data-hub/shared-file-locations',
                            mode: USER_MODES.REGULAR,
                            enabled: isUserInternal() && isUserAllowed('SharedFileLocationsRead'),
                        },
                        {
                            name: this.$i18n.t('events.jsonpathTester'),
                            link: '/data-hub/jsonpath-tester',
                            mode: USER_MODES.REGULAR,
                            enabled: isUserAllowed('JsonPathTesterRead'),
                        },
                        {
                            name: this.$i18n.t('dlq.pageTitle'),
                            link: '/data-hub/redshift-ingestion-recovery',
                            mode: USER_MODES.REGULAR,
                            enabled: isUserInternal() && isUserAllowed('DlqDashboardRead'),
                        },
                        {
                            name: this.$i18n.tc('dataflows.name', PLURALIZATION.PLURAL),
                            link: '/data-hub/dataflows',
                            mode: USER_MODES.REGULAR,
                            enabled: isUserInternal() && isUserAllowed('DataflowRead'),
                        },
                        {
                            name: this.$t('pipelines.analyticsAssitant'),
                            link: '/data-hub/analytics-assitant',
                            mode: USER_MODES.REGULAR,
                            enabled: isUserInternal() && isViewEnabled('AnalyticsAssitant'),
                        },
                    ],
                },
                {
                    name: this.$i18n.t('customerCare.customerCare'),
                    icon: ICON_TYPES.SIDEBAR_USER_MANAGEMENT,
                    enabled:
                        (permissionsService.userManagementEnabled() || permissionsService.eSIMEnabled()) &&
                        (isUserAllowed(
                            'UMUserRead',
                            'UMUserGrantPermission',
                            'UMUserEditUserUpdateFields',
                            'UMUserEditUserToggleFlag',
                            'UMSimRead',
                            'ServiceCampaignsRead',
                            'ServiceCampaignsWrite',
                            'MarketingCampaignsRead',
                            'MarketingCampaignsWrite',
                            'UMUserGrantPermission',
                            'UMUserEditUserUpdateFields',
                            'UMUserEditUserToggleFlag',
                        ) ||
                            getOperatorConfigValue('isViasatDemo')),
                    subItems: [
                        customerCareMenuSubItem,
                        {
                            name: this.$i18n.t('customerCare.eSIM.eSIM'),
                            link: '/customer-care/esim',
                            mode: USER_MODES.REGULAR,
                            enabled: permissionsService.eSIMEnabled() && isUserAllowed('UMSimRead'),
                        },
                        {
                            name: this.$i18n.t('customerCare.messageHistory'),
                            link: '/customer-care/message-history',
                            mode: USER_MODES.REGULAR,
                            enabled: isUserAllowed('CustomerCareMessageHistory'),
                        },
                        // viasat demo
                        {
                            name: this.$i18n.t('customerCare.leads'),
                            link: '/customer-care/leads',
                            mode: USER_MODES.REGULAR,
                            enabled:
                                permissionsService.userManagementEnabled() && getOperatorConfigValue('isViasatDemo'),
                        },
                        {
                            name: this.$i18n.t('customerCare.partnerManager'),
                            link: '/customer-care/partner-management',
                            mode: USER_MODES.REGULAR,
                            enabled:
                                permissionsService.userManagementEnabled() && getOperatorConfigValue('isViasatDemo'),
                        },
                        {
                            name: this.$i18n.t('customerCare.GORPaymentStatus'),
                            link: '/customer-care/gor-payment-usersearch',
                            mode: USER_MODES.REGULAR,
                            enabled: isViewEnabled('GORPaymentStatus'),
                        },
                        {
                            name: this.$i18n.t('customerCare.guestPurchase'),
                            link: '/customer-care/resend-qrcode/user/search',
                            mode: USER_MODES.REGULAR,
                            enabled: isViewEnabled('UMGuestOrdersResendQRCode'),
                        },
                    ],
                },
                {
                    name: this.$i18n.t('customerCareSuite.customerCareSuite'),
                    icon: ICON_TYPES.SIDEBAR_USER_MANAGEMENT,
                    link: '/customer-care-suite',
                    mode: USER_MODES.EXPERIMENTAL,
                    enabled: isViewEnabled('CustomerCareSuite'),
                },
                {
                    name: this.$i18n.t('segments.segments'),
                    icon: ICON_TYPES.SIDEBAR_SEGMENTS,
                    link: '/segments/manage-segments',
                    mode: USER_MODES.EXPERIMENTAL,
                    enabled: permissionsService.segmentsEnabled() && isUserAllowed('SegmentsRead', 'SegmentsWrite'),
                },
                {
                    name: this.$i18n.t('analytics.reports'),
                    icon: ICON_TYPES.SIDEBAR_ANALYTICS,
                    link: '/reports',
                    mode: USER_MODES.REGULAR,
                    enabled: permissionsService.analyticsReportsEnabled() && isUserAllowed('ReportsRead'),
                },
                {
                    name: this.$i18n.t('orchestrationEngine.orchestrationEngine'),
                    icon: ICON_TYPES.SIDEBAR_ORCHESTRATION,
                    enabled:
                        permissionsService.orchestrationEngineEnabled() &&
                        isUserAllowed(
                            'OrchestrationPlansRead',
                            'OrchestrationPlansWrite',
                            'OrchestrationTemplatesRead',
                            'OrchestrationTemplatesWrite',
                            'MappedExecutionSearch',
                            'PausedExecutionsRead',
                            'PausedExecutionsWrite',
                            'BatchActionsRead',
                            'BatchActionsWrite',
                        ),
                    subItems: [
                        {
                            name: this.$i18n.tc('generic.plan', PLURALIZATION.PLURAL),
                            link: '/orchestration-engine/plans',
                            mode: USER_MODES.REGULAR,
                            enabled:
                                permissionsService.orchestrationEnginePlansEnabled() &&
                                (isUserAllowed('OrchestrationPlansRead') || isUserAllowed('OrchestrationPlansWrite')),
                        },
                        {
                            name: this.$i18n.tc('orchestrationEngine.templates.template', PLURALIZATION.PLURAL),
                            link: '/orchestration-engine/templates',
                            mode: USER_MODES.REGULAR,
                            enabled:
                                permissionsService.orchestrationEngineTemplatesEnabled() &&
                                (isUserAllowed('OrchestrationTemplatesRead') ||
                                    isUserAllowed('OrchestrationTemplatesWrite')),
                        },
                        {
                            name: this.$i18n.tc('orchestrationEngine.executionsSearch.mappedExecutionsSearch'),
                            link: '/orchestration-engine/executions-search',
                            mode: USER_MODES.REGULAR,
                            enabled:
                                permissionsService.orchestrationEngineMappedExecutionsSearchEnabled() &&
                                isUserAllowed('MappedExecutionSearch'),
                        },
                        {
                            name: this.$i18n.tc('orchestrationEngine.executionsSearch.pausedExecutions'),
                            link: '/orchestration-engine/paused-executions',
                            mode: USER_MODES.REGULAR,
                            enabled:
                                permissionsService.orchestrationEnginePausedExecutionsEnabled() &&
                                (isUserAllowed('PausedExecutionsRead') || isUserAllowed('PausedExecutionsWrite')),
                        },
                        {
                            name: this.$i18n.tc('orchestrationEngine.batchActions.name', PLURALIZATION.PLURAL),
                            link: '/orchestration-engine/batch-actions',
                            mode: USER_MODES.REGULAR,
                            enabled: isUserAllowed('BatchActionsRead', 'BatchActionsWrite'),
                        },
                    ],
                },
                {
                    name: this.$i18n.t('orderResume.manualOrderResume'),
                    icon: ICON_TYPES.SIDEBAR_ORCHESTRATION,
                    link: '/manual-order-resume',
                    mode: USER_MODES.REGULAR,
                    enabled: isUserAllowed('ManualOrderResumeRead'),
                },
                {
                    name: this.$i18n.t('operations.operations'),
                    icon: ICON_TYPES.SIDEBAR_OPERATIONS,
                    // No suitable equivalent for "Operations" in new permissions structure.
                    // Also, Number management is nested under Resource Management
                    enabled:
                        permissionsService.operationsEnabled() &&
                        isUserAllowed(
                            'ViewGraphs',
                            'ViewNumberCounts',
                            'UploadNumbers',
                            'SearchNumbers',
                            'ViewSIMProfileCounts',
                            'UploadSIMProfiles',
                            'SearchSIMProfiles',
                            ...ALL_LINE_AUTH_PERMISSIONS,
                            'UploadWpsRead',
                            'UploadWpsWrite',
                            'DevedgeApplicationsRead',
                        ),
                    subItems: [
                        {
                            name: this.$i18n.t('operations.graphs.graphs'),
                            link: '/ops/graphs',
                            mode: USER_MODES.REGULAR,
                            enabled: permissionsService.opsGraphsEnabled() && isUserAllowed('ViewGraphs'),
                        },
                        {
                            name: this.$i18n.t('operations.numberManagement.numberManagement'),
                            link: '/ops/number-management',
                            mode: USER_MODES.REGULAR,
                            enabled:
                                permissionsService.numberManagementEnabled() &&
                                (isUserAllowed('ViewNumberCounts') ||
                                    isUserAllowed('UploadNumbers') ||
                                    isUserAllowed('SearchNumbers')),
                        },
                        {
                            name: this.$i18n.t('simProfileManagement.simProfileManagement'),
                            link: '/ops/sim-profile-management',
                            mode: USER_MODES.REGULAR,
                            enabled:
                                permissionsService.simUploadEnabled() &&
                                (isUserAllowed('ViewSIMProfileCounts') ||
                                    isUserAllowed('UploadSIMProfiles') ||
                                    isUserAllowed('SearchSIMProfiles')),
                        },
                        {
                            name: this.$i18n.t('qodNumberManagement.developerLineAuthorization'),
                            link: '/ops/developer-line-authorization',
                            mode: USER_MODES.REGULAR,
                            enabled:
                                isViewEnabled('DeveloperLineAuthorization') &&
                                isUserAllowed(
                                    'QoDNumberManagementRead',
                                    'QoDNumberManagementWrite',
                                    'DeviceStatusNumberManagementRead',
                                    'DeviceStatusNumberManagementWrite',
                                    'LocationVerificationNumberManagementRead',
                                    'LocationVerificationNumberManagementWrite',
                                    'SDWANNumberManagementRead',
                                    'SDWANNumberManagementWrite',
                                    'SIMSwapNumberManagementRead',
                                    'SIMSwapNumberManagementWrite',
                                    'NetworkAuthenticationNumberManagementRead',
                                    'NetworkAuthenticationNumberManagementWrite',
                                ),
                        },
                        {
                            name: this.$i18n.t('wps.updateWps'),
                            link: '/ops/upload-wps',
                            mode: USER_MODES.REGULAR,
                            enabled: isUserAllowed('UploadWpsRead', 'UploadWpsWrite'),
                        },
                        {
                            name: this.$i18n.t('devedgeApplications.applications'),
                            link: '/ops/application',
                            mode: USER_MODES.REGULAR,
                            enabled: isUserAllowed('DevedgeApplicationsRead'),
                        },
                        {
                            name: this.$i18n.t('batchSimFileProcess.batchSimFileProcess'),
                            link: '/ops/batch-sim-file-process',
                            mode: USER_MODES.REGULAR,
                            enabled: isUserAllowed('BatchSimFileUpload'),
                        },
                        {
                            name: this.$i18n.t('manualSeedManagement.manualSeedUploadManagement'),
                            link: '/ops/manualSeed-upload-management',
                            mode: USER_MODES.REGULAR,
                            enabled: isUserAllowed('ManualSeedingUpload'),
                        },
                    ],
                },
                {
                    name: this.$i18n.t('sidebar.documents'),
                    icon: ICON_TYPES.SIDEBAR_CHARGING,
                    enabled:
                        isUserInternal() &&
                        isUserAllowed(
                            'DocumentTemplatesReadOnly',
                            'DocumentTemplatesReadWrite',
                            'DocumentAssetsRead',
                            'DocumentReadWrite',
                        ),
                    subItems: [
                        {
                            name: this.$i18n.t('documents.templates'),
                            link: '/document/templates',
                            mode: USER_MODES.REGULAR,
                            enabled:
                                isUserInternal() &&
                                isUserAllowed('DocumentTemplatesReadOnly', 'DocumentTemplatesReadWrite'),
                        },
                        {
                            name: this.$i18n.t('documents.assets.name'),
                            link: '/document/assets',
                            mode: USER_MODES.REGULAR,
                            enabled: isUserInternal() && isUserAllowed('DocumentAssetsRead', 'DocumentReadWrite'),
                        },
                    ],
                },
                {
                    name: this.$i18n.t('sidebar.authentication'),
                    icon: ICON_TYPES.SIDEBAR_SETTINGS,
                    enabled:
                        permissionsService.isUserAdmin() &&
                        isUserAllowed('IDPConfiguration', 'IDPUserRoleMapping', 'IDPGroupRoleMapping'),
                    subItems: [
                        {
                            name: this.$i18n.t('authentication.authenticationSettings'),
                            link: '/authentication',
                            mode: USER_MODES.POWER,
                            enabled: permissionsService.isUserAdmin() && isUserAllowed('IDPConfiguration'),
                        },
                        {
                            name: this.$i18n.t('authentication.authenticationExternalUserToRoleMapper'),
                            link: '/authentication/userrolemapping',
                            mode: USER_MODES.POWER,
                            enabled: permissionsService.isUserAdmin() && isUserAllowed('IDPUserRoleMapping'),
                        },
                        {
                            name: this.$i18n.t('authentication.authenticationRolesToGroupsMapper'),
                            link: '/authentication/grouprolemapping',
                            mode: USER_MODES.POWER,
                            enabled: permissionsService.isUserAdmin() && isUserAllowed('IDPGroupRoleMapping'),
                        },
                    ],
                },
            ];
            return sidebarOptions.reduce((accum, elem) => {
                if (!elem?.link || elem?.mode === this.mode) {
                    accum.push(elem);
                    if (elem.subItems) {
                        elem.subItems = elem.subItems.filter(el => el?.mode === this.mode);
                    }
                    if (!elem.subItems?.length) {
                        delete elem.subItems;
                    }
                }
                return accum;
            }, []);
        },
        accountOptions() {
            return [
                {
                    // timezoneOffset is not reactive. Use localization data from Vuex once it is available POR-5262
                    name: `${this.$i18n.t('settings.settings')} ${localeLibrary.getCurrentTimezoneOffset()}`,
                    link: '/settings/user-settings',
                    icon: ICON_TYPES.SIDEBAR_SETTINGS,
                    enabled:
                        isUserAllowed(
                            'UserSettings',
                            'QuietHours',
                            'CampaignCategoriesRead',
                            'CampaignCategoriesWrite',
                        ) ||
                        (isUserInternal() &&
                            isUserAllowed(
                                'TenantManagement',
                                'CompanySettings',
                                'RemoteConfiguration',
                                'UserManagement',
                                'RoleManagement',
                                'ApplicationManager',
                                'ManualRequestProxy',
                                'ThirdPartyApplications',
                            )),
                },
                {
                    name: this.$i18n.t('sidebar.supportFeedback'),
                    icon: ICON_TYPES.SIDEBAR_SUPPORT,
                    enabled: true,
                    subItems: [
                        {
                            name: this.$i18n.t('sidebar.serviceDesk'),
                            link: 'https://lotusflare.atlassian.net/servicedesk/customer/portals',
                            external: true,
                            enabled: true,
                        },
                        // Feature flag gated until it is completed. After completion it will visible to all users.
                        {
                            name: this.$i18n.t('userGuide.userGuide'),
                            link: '/user-guide/',
                            enabled: permissionsService.isFeatureFlagEnabled('user-guide'),
                        },
                    ],
                },
                {
                    name: this.$i18n.t('sidebar.myAccount'),
                    icon: ICON_TYPES.SIDEBAR_MY_ACCOUNT,
                    enabled: true, // Note: enabling for all users
                    subItems: [
                        {
                            name: this.$i18n.t('sidebar.manageYourAccount'),
                            link: '/account',
                            enabled: true,
                        },
                        {
                            name: this.$i18n.t('sidebar.logout'),
                            link: '',
                            enabled: true,
                            logout: true,
                        },
                    ],
                },
            ];
        },
        accessToTenantSwitcher() {
            return Object.values(this.$store.state.operators.availableOperators || {}).length > 1;
        },
        compact() {
            return this.$store.state.compactSidebar;
        },
        mode() {
            return this.$store.state.currentMode;
        },
    },

    watch: {
        $route: {
            immediate: true,
            handler() {
                // active sidebar elements initialization
                const lastActiveSubItem = this.activeSubItem;
                this.activeItem =
                    [...this.navigationOptions, ...this.accountOptions].find(nav => {
                        if (nav.subItems?.length) {
                            this.activeSubItem = nav.subItems.find(
                                sub => sub.link !== '' && this.$route.path.endsWith(sub.link),
                            )?.name;
                            if (this.activeSubItem) {
                                return true;
                            }
                        }
                        return nav.link === this.$route.path;
                    })?.name || this.activeItem;
                this.activeSubItem = this.activeSubItem || lastActiveSubItem;
                // open dropdown for active item if closed (ignore in compact mode)
                if (!this.compact && this.currentDropdownVisible !== this.activeItem) {
                    this.toggleMenuDropdown(this.activeItem);
                }
            },
        },
    },
    methods: {
        toggleSidebar() {
            this.$store.dispatch(Actions.TOGGLE_COMPACT_SIDEBAR);
            if (this.compact) {
                this.currentDropdownVisible = '';
                this.tenantDropdownVisible = false;
            }
        },
        getSidebarIcon(icon) {
            // eslint-disable-next-line import/no-dynamic-require,global-require
            return icon ? require(`../../assets/icons/sidebar/${icon}`) : '#';
        },
        handleTenantDropdown(newValue) {
            this.tenantDropdownVisible = newValue;
        },
        toggleMenuDropdown(newValue) {
            if (this.tenantDropdownVisible) {
                this.tenantDropdownVisible = false;
            }

            if (this.currentDropdownVisible === newValue) {
                this.currentDropdownVisible = '';
            } else {
                this.currentDropdownVisible = newValue;
            }
        },
        setActiveSubItem(newValue) {
            this.tenantDropdownVisible = false;
            if (this.activeItem !== newValue) {
                this.activeSubItem = newValue;
            }
        },
        setActiveParent(newValue) {
            this.tenantDropdownVisible = false;
            this.activeItem = newValue;
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/mixins';
@import '~@/assets/scss/consistency-colors';
@import '~@/assets/scss/sidebar';
@import '~@/assets/scss/z-indexes';

.sidebar {
    // TODO: replace once design is finalized
    background: #14203b;
    transition: width $transitionTime;
    width: $sidebarFullWidth;
    font-size: 0.875rem;
    color: $white;
    display: flex;
    flex-direction: column;

    &.compact {
        width: $sidebarCompactWidth;
        overflow-y: visible;
    }
}

.lf-logo-container {
    margin: 24px 16px;

    .lf-logo {
        display: flex;
    }

    .lf-name {
        margin-left: 10px;
    }
}

.sidebar-logo {
    width: 28px;
    height: 28px;
}

.sidebar-main-menu {
    flex-grow: 1;
    overflow-x: hidden;
    overflow-y: auto;
}

.toggler {
    position: relative;
    padding: 24px;
    padding-left: 20px;
    display: flex;
    justify-content: flex-start;
    z-index: $sidebar-stuff-z-index;
    cursor: pointer;

    .toggler-icon {
        transition: transform $transitionTime;

        &.compact {
            transform: rotateY(180deg);
        }
    }
}

.item-group:not(:first-child) {
    padding-top: 2rem;
}

// iframe case exception
.app-in-iframe {
    .sidebar-footer {
        position: absolute;
        bottom: 0;
    }
}
</style>
