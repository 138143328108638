import { csConditionZoneOptions } from '@/__new__/services/dno/charging/common/zone/zones';

export default (id, pcObject) => ({
    type: pcObject.type,
    data: {
        value: pcObject.flag,
        inverted: pcObject.inverted ? csConditionZoneOptions.isNot : csConditionZoneOptions.is,
    },
    uuid: id,
});
