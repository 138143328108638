<template>
    <div class="container-fluid wrapper-container p-0">
        <AppHeader />

        <div class="banner">
            <div class="banner-text">
                <h2 class="lf-title m-0">
                    {{ getWelcomeText }}
                </h2>
            </div>
        </div>

        <section class="create-new-section d-flex flex-column">
            <h2 class="lf-subtitle">{{ $i18n.t('home.createNew') }} section</h2>
            <draggable
                :list="newWidgetList"
                :group="{ name: 'widget', pull: true, put: true }"
                :sort="true"
                class="h-100"
            >
                <transition-group
                    name="cards"
                    tag="div"
                    class="card-wrapper d-flex flex-wrap"
                >
                    <div
                        v-for="item in newWidgetList"
                        :key="`${item.name}-card`"
                        class="card"
                    >
                        <div class="d-flex align-items-center">
                            <h3 :class="['card-heading', 'lf-subtitle-blue', 'mb-0', item.colorScheme.textColorClass]">
                                {{ item.name }}
                            </h3>
                            <span
                                :class="[
                                    'card-new-btn',
                                    item.colorScheme.bgColorClass,
                                    item.colorScheme.textColorClass,
                                ]"
                            >
                                <AppIcon
                                    :type="ICON_TYPES.PLUS"
                                    class="card-new-btn-icon"
                                />
                            </span>
                        </div>
                    </div>
                </transition-group>
            </draggable>
        </section>
        <!-- @todo Remove after Viasat demo -->
        <aside>
            <h2 class="lf-subtitle">Widgets</h2>
            <draggable
                :list="createNewListFiltered"
                :group="{ name: 'widget', pull: true, put: true }"
                class="h-100"
            >
                <transition-group
                    name="widgets"
                    tag="div"
                    class="widgets d-flex flex-column h-100"
                >
                    <div
                        v-for="item in createNewListFiltered"
                        :key="`${item.name}-card`"
                        class="card"
                    >
                        <div class="d-flex align-items-center">
                            <h3 :class="['card-heading', 'lf-subtitle-blue', 'mb-0', item.colorScheme.textColorClass]">
                                {{ item.name }}
                            </h3>
                            <span
                                :class="[
                                    'card-new-btn',
                                    item.colorScheme.bgColorClass,
                                    item.colorScheme.textColorClass,
                                ]"
                            >
                                <AppIcon
                                    :type="ICON_TYPES.PLUS"
                                    class="card-new-btn-icon"
                                />
                            </span>
                        </div>
                    </div>
                </transition-group>
            </draggable>
            <AppButton
                :buttonType="BUTTON_TYPES.PRIMARY"
                :iconType="ICON_TYPES.CHECK"
                :label="$i18n.t('generic.save')"
                class="btn-save"
                @click="onSave"
            />
        </aside>
    </div>
</template>

<script>
// Components
import AppHeader from '@/components/layout/AppHeader.vue';
import AppIcon from '@/components/partials/icon/AppIcon.vue';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';

// Helpers
import Account from '@/models/Account';
import RouteNames from '@/router/routeNames';
import permissionsService, { isUserAllowed } from '@/services/permissions/permissions.service';
import { ICON_COLORS, ICON_TYPES } from '@/common/iconHelper';
import draggable from 'vuedraggable';
import LocalStorageHelper from '@/common/LocalStorageHelper';

const colorSchemes = {
    BLUE: {
        textColorClass: 'blue-text-color',
        bgColorClass: 'blue-background-color',
        plusIcon: 'plus-rounded-blue',
    },
    GREEN: {
        textColorClass: 'green-text-color',
        bgColorClass: 'green-background-color',
        plusIcon: 'plus-rounded-green',
    },
    YELLOW: {
        textColorClass: 'yellow-text-color',
        bgColorClass: 'yellow-background-color',
        plusIcon: 'plus-rounded-yellow',
    },
    RED: {
        textColorClass: 'red-text-color',
        bgColorClass: 'red-background-color',
        plusIcon: 'plus-rounded-red',
    },
    VIOLET: {
        textColorClass: 'violet-text-color',
        bgColorClass: 'violet-background-color',
        plusIcon: 'plus-rounded-violet',
    },
};

// @todo Remove or do a proper implementation after Viasat demo.
export default {
    name: 'HomeConfig',
    components: {
        AppButton,
        AppHeader,
        AppIcon,
        draggable,
    },
    data() {
        return {
            newWidgetList: [],
            widgetList: [
                {
                    name: this.$i18n.tc('productCatalog.entities.offer'),
                    createNewRouteName: RouteNames.PRODUCT_CATALOG_OFFERS_EDITOR,
                    colorScheme: colorSchemes.BLUE,
                    permission: permissionsService.productCatalogOffersEnabled,
                },
                {
                    name: this.$i18n.tc('productCatalog.entities.product'),
                    createNewRouteName: RouteNames.PRODUCT_CATALOG_PRODUCTS_EDITOR,
                    colorScheme: colorSchemes.BLUE,
                    permission: permissionsService.productCatalogProductsEnabled,
                },
                {
                    name: this.$i18n.tc('productCatalog.entities.service'),
                    createNewRouteName: RouteNames.PRODUCT_CATALOG_SERVICE_EDITOR_V2,
                    colorScheme: colorSchemes.BLUE,
                    permission: permissionsService.productCatalogServicesEnabled,
                },
                {
                    name: this.$i18n.t('home.promotion'),
                    createNewRouteName: RouteNames.PROMOTIONS_EDITOR,
                    colorScheme: colorSchemes.GREEN,
                    permission: permissionsService.promotionsEnabled() && isUserAllowed('PromotionRulesWrite'),
                },
                {
                    name: this.$i18n.t('home.rewardRule'),
                    createNewRouteName: RouteNames.REWARD_EDITOR,
                    colorScheme: colorSchemes.YELLOW,
                    permission: permissionsService.rewardsEnabled,
                },
                {
                    name: this.$i18n.t('prize.prize'),
                    createNewRouteName: RouteNames.PRIZE_EDITOR,
                    colorScheme: colorSchemes.YELLOW,
                    permission: permissionsService.prizeEnabled,
                },
                {
                    name: this.$i18n.t('rewards.prizeEngine'),
                    createNewRouteName: RouteNames.PRIZE_ENGINE_EDITOR,
                    colorScheme: colorSchemes.YELLOW,
                    permission: permissionsService.prizeEngineEnabled,
                },
                {
                    name: this.$i18n.t('rewards.lottery.lottery'),
                    createNewRouteName: RouteNames.LOTTERY_EDITOR,
                    colorScheme: colorSchemes.YELLOW,
                    permission: permissionsService.lotteryEnabled,
                },
                {
                    name: this.$i18n.t('productCatalog.entities.plural.voucherSets'),
                    createNewRouteName: RouteNames.VOUCHER_SET_EDITOR,
                    colorScheme: colorSchemes.YELLOW,
                    permission: permissionsService.voucherEnabled,
                },
                {
                    name: this.$i18n.t('campaigns.campaign'),
                    createNewRouteName: RouteNames.CAMPAIGNS_ADD,
                    colorScheme: colorSchemes.RED,
                    permission:
                        permissionsService.cepCampaignsEnabled &&
                        isUserAllowed('ServiceCampaignsWrite', 'MarketingCampaignsWrite'),
                },
                {
                    name: this.$i18n.t('segments.segment'),
                    createNewRouteName: RouteNames.SEGMENTS_ADD,
                    colorScheme: colorSchemes.VIOLET,
                    permission: () =>
                        permissionsService.segmentsEnabled() && isUserAllowed('SegmentsRead', 'SegmentsWrite'),
                },
                {
                    name: 'Customer Interactions',
                    createNewRouteName: RouteNames.SEGMENTS_ADD,
                    colorScheme: colorSchemes.BLUE,
                    permission: true,
                },
                {
                    name: 'CPQ',
                    createNewRouteName: RouteNames.SEGMENTS_ADD,
                    colorScheme: colorSchemes.YELLOW,
                    permission: true,
                },
                {
                    name: 'KYC',
                    createNewRouteName: RouteNames.SEGMENTS_ADD,
                    colorScheme: colorSchemes.GREEN,
                    permission: true,
                },
                {
                    name: 'CSAT',
                    createNewRouteName: RouteNames.SEGMENTS_ADD,
                    colorScheme: colorSchemes.VIOLET,
                    permission: true,
                },
            ],

            // Map imported data
            BUTTON_TYPES,
            permissionsService,
            ICON_COLORS,
            ICON_TYPES,
        };
    },
    computed: {
        getWelcomeText() {
            if (Account.authenticated()) {
                return this.$i18n.t('login.welcomeName', { name: Account.storedInstance.name });
            }
            return this.$i18n.t('login.welcome');
        },
        createNewListFiltered() {
            return this.widgetList.filter(el => {
                if (typeof el.permission === 'function') {
                    return el.permission();
                }
                return el.permission;
            });
        },
    },
    created() {
        const store = LocalStorageHelper.get('view-homepage');

        if (store) {
            this.newWidgetList = JSON.parse(store);
            this.widgetList = this.widgetList.filter(el => !this.newWidgetList.some(item => item.name === el.name));
        }
    },
    methods: {
        onSave() {
            LocalStorageHelper.set('view-homepage', JSON.stringify(this.newWidgetList));
            this.$router.push({ name: RouteNames.HOME_VIEW, params: { companyId: this.$route.params.companyId } });
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/palette';
@import '~@/assets/scss/layout';
@import '~@/assets/scss/typographyV2';

.banner {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    min-height: 6.25rem;
    width: calc(100% - 3rem);
    margin: $spacing-m $spacing-xl;
    padding-left: $spacing-xxl;
    border-radius: 0.5rem;
    background-color: #d6dcea;

    &:after {
        content: '';
        position: absolute;
        bottom: 0;
        right: -2.5rem;
        margin-right: $spacing-xxl;
        /* Sizes in pixels to match svg picture*/
        width: 517px;
        height: 110px;
        background-image: url('../../../assets/images/home-illustration-1.svg');
        background-size: contain;
        background-repeat: no-repeat;
    }
}

.stats-section {
    display: flex;
    padding: $spacing-s;
    flex-wrap: wrap;

    .year-to-date-users-card {
        margin: $spacing-s;
        padding: $spacing-m $spacing-xl;
        width: 17.25rem;
        height: 10rem;
        border-radius: 0.5rem;
        background-color: $blue;
        box-shadow: 0 0.25rem 0.5rem 0 rgb(51 81 149 / 40%);

        .label {
            color: $white;
        }

        .users-number {
            color: $white;
            margin-bottom: $spacing-xs;
        }

        .description {
            color: $gray-blue;
        }
    }

    .active-users-block {
        display: flex;
        flex-direction: column;
        margin: $spacing-s 0;
        height: fit-content;
    }

    .active-users-block-heading {
        margin: 0 $spacing-s;
    }

    .active-users-cards-wrapper {
        display: flex;
        flex-wrap: wrap;
        height: 100%;
    }

    .active-users-card {
        width: 16.875rem;
        margin: $spacing-s;
        padding: $spacing-xl 0 0 $spacing-xl;
        height: calc(100% - 1.5rem);
        border-radius: 0.5rem;
        background-color: $white;

        .users-number {
            margin-bottom: $spacing-xs;
        }
    }
}

// card
.card {
    position: relative;
    width: 13.5rem;
    height: 5.5rem;
    margin: $spacing-s;
    padding: $spacing-xl;
    border: 0;
    cursor: grab;
    background-color: white;
    transition: 0.05s ease-in-out;

    * {
        pointer-events: none;
        user-select: none;
    }
}

.card-heading {
    font-weight: $medium-font-weight;
    margin-right: $spacing-xs;
}

.card-new-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    margin-left: auto;
    cursor: pointer;

    &:active .card-new-btn-icon {
        /* To make icon white on click */
        filter: brightness(1000%);
    }
}

// utility
.blue-text-color {
    color: $blue;
}

.yellow-text-color {
    color: $yellow;
}

.violet-text-color {
    color: $violet;
}

.green-text-color {
    color: $green;
}

.red-text-color {
    color: $red;
}

.blue-background-color {
    background-color: $blue15;

    &:hover {
        background-color: rgba($blue, 0.25);
    }

    &:active {
        background-color: $blue;
    }
}

.green-background-color {
    background-color: $green15;

    &:hover {
        background-color: rgba($green, 0.25);
    }

    &:active {
        background-color: $green;
    }
}

.yellow-background-color {
    background-color: $yellow15;

    &:hover {
        background-color: rgba($yellow, 0.25);
    }

    &:active {
        background-color: $yellow;
    }
}

.red-background-color {
    background-color: $red15;

    &:hover {
        background-color: rgba($red, 0.25);
    }

    &:active {
        background-color: $red;
    }
}

.violet-background-color {
    background-color: $violet15;

    &:hover {
        background-color: rgba($violet, 0.25);
    }

    &:active {
        background-color: $violet;
    }
}

// layout
.wrapper-container {
    display: grid;
    grid-auto-rows: min-content;
    grid-template-columns: 1fr min-content;
    background-color: $dirty-white;

    :not(section, aside) {
        grid-column: 1 / 3;
    }
}

.create-new-section {
    padding: 0 $spacing-s;

    h2 {
        margin: $spacing-s;
    }
}

.card-wrapper {
    min-height: 6rem;
}

aside {
    h2 {
        margin: 0.75rem !important;
    }

    .card {
        margin: 0;
        transform: scale(0.9);
    }
}

.widgets {
    max-height: 38rem;
    min-width: 14.5rem;
    margin-bottom: 2rem;
    padding-top: 0.5rem;
    overflow: hidden auto;
}

.btn-save {
    position: fixed;
    right: 2rem;
    bottom: 1rem;
}
</style>
