<template>
    <div :class="{ 'disabled-area': disabled }">
        <div class="conditions">
            <div
                v-for="(conditionObj, index) in expression"
                :key="`condition-obj-${index}`"
            >
                <div
                    v-if="conditionObj.multipleRowsExpression"
                    class="expression-row-container"
                >
                    <div
                        :class="['col-12 d-flex justify-content-between py-2', { 'mb-3': !conditionObj.isCollapsed }]"
                        :data-test-id="`multi-rows-expression-collapse-${index}`"
                        @click="conditionObj.isCollapsed = !conditionObj.isCollapsed"
                    >
                        <span>
                            {{ $i18n.t('productCatalog.services.editor.conditionExpressionRow') }}: {{ index + 1 }}
                        </span>
                        <AppButton
                            :isSmall="true"
                            :iconType="ICON_TYPES.ARROW_DOWN"
                            :iconColor="ICON_COLORS.BLUE"
                            :class="{ collapsed: conditionObj.isCollapsed }"
                            alt="arrow"
                            class="arrow"
                        />
                    </div>
                    <div v-show="!conditionObj.isCollapsed">
                        <div
                            v-for="(multipleRowsExpression, multipleRowsExpressionIndex) in conditionObj.value"
                            :key="`multiple-rows-expression-${multipleRowsExpressionIndex}`"
                            :data-test-id="`multiple-rows-expression-${multipleRowsExpressionIndex}`"
                        >
                            <div
                                v-for="(
                                    multipleRowsCondition, multipleRowsConditionIndex
                                ) in multipleRowsExpression.value"
                                :key="`multiple-rows-condition-${multipleRowsConditionIndex}`"
                            >
                                <div class="condition-row">
                                    <AppMultiselectV3
                                        :value="multipleRowsCondition"
                                        :options="conditionsList"
                                        :placeholder="$i18n.t('generic.conditions')"
                                        :trackBy="trackBy"
                                        :allowEmpty="false"
                                        :small="true"
                                        label="label"
                                        class="condition-block"
                                        :data-test-id="`multiple-container-${index}`"
                                        @input="
                                            updateMultipleRowsCondition({
                                                value: $event,
                                                index,
                                                multipleRowsExpressionIndex,
                                                multipleRowsConditionIndex,
                                            })
                                        "
                                    />
                                    <AppIcon
                                        :data-test-id="`close-icon-container-${index}`"
                                        class="close-icon-container"
                                        :type="ICON_TYPES.CLOSE"
                                        :color="ICON_COLORS.BLUE"
                                        @click="
                                            removeMultipleRowsCondition({
                                                index,
                                                multipleRowsExpressionIndex,
                                                multipleRowsConditionIndex,
                                            })
                                        "
                                    />
                                </div>
                                <AppMultiselectV3
                                    v-if="
                                        isNotLastRelation(multipleRowsExpression.value, multipleRowsConditionIndex) &&
                                        multipleRowsExpression.value.length > 1
                                    "
                                    :value="multipleRowsExpression.groupRelation"
                                    :options="logicalOperators"
                                    :borderNone="true"
                                    :transparentBg="true"
                                    :blueArrow="true"
                                    :allowEmpty="false"
                                    :small="true"
                                    :searchable="false"
                                    :widthStyleObject="{ width: '5rem' }"
                                    class="ml-1"
                                    @input="
                                        updateMultipleRowsConditionGroupRelation({
                                            value: $event,
                                            index,
                                            multipleRowsExpressionIndex,
                                        })
                                    "
                                />
                                <AppMultiselectV3
                                    v-else-if="isNotLastRelation(conditionObj.value, multipleRowsExpressionIndex)"
                                    :value="multipleRowsExpression.relation"
                                    :options="logicalOperators"
                                    :borderNone="true"
                                    :transparentBg="true"
                                    :blueArrow="true"
                                    :allowEmpty="false"
                                    :small="true"
                                    :searchable="false"
                                    :widthStyleObject="{ width: '5rem' }"
                                    class="ml-1"
                                    @input="
                                        updateMultipleRowsConditionRelation({
                                            value: $event,
                                            index,
                                            multipleRowsExpressionIndex,
                                        })
                                    "
                                />
                            </div>
                        </div>
                        <div class="expression-actions">
                            <div
                                class="add-icon-container"
                                :data-test-id="`make-condittion-row-${index}`"
                                @click="addConditionToMultipleRowsExpression({ index })"
                            >
                                <div class="plus-blue-icon-button" />
                                <p class="ml-2 table-title">
                                    {{ $i18n.t('charging.chargingSpecifications.editor.addCondition') }}
                                </p>
                            </div>
                            <div
                                :id="`delete-condittion-row-${index}`"
                                class="remove-all"
                                @click="deleteCondittionRow({ index })"
                            >
                                <p class="table-title">
                                    {{ $i18n.t('generic.removeAll') }}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else>
                    <div class="expression-row-container">
                        <div
                            :class="[
                                'col-12 d-flex justify-content-between py-2',
                                { 'mb-3': !conditionObj.isCollapsed },
                            ]"
                            :data-test-id="`expression-collapse-${index}`"
                            @click="conditionObj.isCollapsed = !conditionObj.isCollapsed"
                        >
                            <span>
                                {{ $i18n.t('productCatalog.services.editor.conditionExpressionRow') }}: {{ index + 1 }}
                            </span>
                            <AppButton
                                :isSmall="true"
                                :iconType="ICON_TYPES.ARROW_DOWN"
                                :iconColor="ICON_COLORS.BLUE"
                                :class="{ collapsed: conditionObj.isCollapsed }"
                                alt="arrow"
                                class="arrow"
                            />
                        </div>
                        <div v-show="!conditionObj.isCollapsed">
                            <div
                                v-for="(condition, conditionIndex) in conditionObj.value"
                                :key="`condition-${conditionIndex}`"
                            >
                                <div class="condition-row">
                                    <AppMultiselectV3
                                        :value="condition"
                                        :options="conditionsList"
                                        :placeholder="$i18n.t('generic.conditions')"
                                        :trackBy="trackBy"
                                        :allowEmpty="false"
                                        :small="true"
                                        label="label"
                                        class="condition-block"
                                        :data-test-id="`container-${index}`"
                                        @input="updateCondition({ index, conditionIndex, value: $event })"
                                    />
                                    <AppIcon
                                        :data-test-id="`close-icon-container-${index}`"
                                        class="close-icon-container"
                                        :type="ICON_TYPES.CLOSE"
                                        :color="ICON_COLORS.BLUE"
                                        @click="removeCondition({ index, conditionIndex })"
                                    />
                                </div>
                                <AppMultiselectV3
                                    v-if="isNotLastRelation(conditionObj.value, conditionIndex)"
                                    :value="conditionObj.groupRelation"
                                    :options="logicalOperators"
                                    :borderNone="true"
                                    :transparentBg="true"
                                    :blueArrow="true"
                                    :allowEmpty="false"
                                    :small="true"
                                    :searchable="false"
                                    :widthStyleObject="{ width: '5rem' }"
                                    class="ml-1"
                                    @input="updateGroupRelation({ index, conditionIndex, value: $event })"
                                />
                            </div>
                            <div class="expression-actions">
                                <div
                                    class="add-icon-container"
                                    :data-test-id="`make-condittion-row-${index}`"
                                    @click="makeConditionGroup({ index })"
                                >
                                    <div class="plus-blue-icon-button" />
                                    <p class="ml-2 table-title">
                                        {{ $i18n.t('charging.chargingSpecifications.editor.addCondition') }}
                                    </p>
                                </div>
                                <div
                                    :id="`delete-condittion-row-${index}`"
                                    class="remove-all"
                                    @click="deleteCondittionRow({ index })"
                                >
                                    <p class="table-title">
                                        {{ $i18n.t('generic.removeAll') }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <AppMultiselectV3
                    v-if="isNotLastRelation(expression, index)"
                    :value="conditionObj.relation"
                    :options="logicalOperators"
                    :borderNone="true"
                    :transparentBg="true"
                    :blueArrow="true"
                    :allowEmpty="false"
                    :small="true"
                    :searchable="false"
                    :widthStyleObject="{ width: '5rem' }"
                    class="mb-1"
                    @input="updateRelation({ index, value: $event })"
                />
            </div>
        </div>
        <AppButton
            :id="'add-slot-expression'"
            :label="$i18n.t('productCatalog.services.editor.addExpression')"
            :iconType="ICON_TYPES.PLUS"
            :isSmall="true"
            class="px-0 ml-1"
            @click="addSlotToExpression"
        />
    </div>
</template>

<script>
// Components
import AppMultiselectV3 from '@/components/partials/inputs/AppMultiselectV3.vue';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import AppIcon from '@/components/partials/icon/AppIcon.vue';

// Helpers
import { LOGICAL_OPERATORS } from '@/__new__/services/dno/charging/common/chargingSpecificationHelper';
import { cloneDeep } from 'lodash';
import { ICON_COLORS, ICON_TYPES } from '@/common/iconHelper';

export default {
    name: 'ConditionsExpressionEditor',
    components: {
        AppButton,
        AppMultiselectV3,
        AppIcon,
    },
    model: {
        prop: 'expression',
        event: 'updateExpression',
    },
    props: {
        expression: {
            type: Array,
            default: () => [],
        },
        conditionsList: {
            type: Array,
            default: () => [],
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        trackBy: {
            type: String,
            default: 'conditionUUID',
        },
    },
    data() {
        return {
            ICON_COLORS,
            ICON_TYPES,
            BUTTON_TYPES,
        };
    },
    computed: {
        logicalOperators() {
            return Object.values(LOGICAL_OPERATORS);
        },
    },
    methods: {
        deleteCondittionRow({ index }) {
            const clonedExpressions = cloneDeep(this.expression);
            clonedExpressions.splice(index, 1);
            if (clonedExpressions.length) {
                clonedExpressions[clonedExpressions.length - 1].relation = LOGICAL_OPERATORS.and;
            }
            this.$emit('updateExpression', clonedExpressions);
        },
        addSlotToExpression() {
            const clonedExpressions = cloneDeep(this.expression);
            const { length } = clonedExpressions;
            if (length > 0) {
                clonedExpressions[length - 1].relation = LOGICAL_OPERATORS.and;
            }
            clonedExpressions.push({
                value: [this.conditionsList[0]],
                relation: LOGICAL_OPERATORS.and,
                groupRelation: LOGICAL_OPERATORS.and,
                multipleRowsExpression: false,
                isCollapsed: false,
            });
            this.$emit('updateExpression', clonedExpressions);
        },
        makeConditionGroup({ index }) {
            const clonedExpressions = cloneDeep(this.expression);
            if (clonedExpressions[index].value.length < 2) {
                clonedExpressions[index].groupRelation = LOGICAL_OPERATORS.and;
                clonedExpressions[index].value.push(this.conditionsList[0]);
                this.$emit('updateExpression', clonedExpressions);
                return;
            }

            if (clonedExpressions[index].multipleRowsExpression === false) {
                const multipleRowsExpressionObj = {
                    multipleRowsExpression: true,
                    relation: LOGICAL_OPERATORS.and,
                    value: [clonedExpressions[index]],
                    isCollapsed: false,
                };
                multipleRowsExpressionObj.value.push({
                    value: [this.conditionsList[0]],
                    relation: LOGICAL_OPERATORS.and,
                    groupRelation: LOGICAL_OPERATORS.and,
                    multipleRowsExpression: false,
                    isCollapsed: false,
                });
                clonedExpressions[index] = multipleRowsExpressionObj;
                this.$emit('updateExpression', clonedExpressions);
            }
        },
        addConditionToMultipleRowsExpression({ index }) {
            const clonedExpressions = cloneDeep(this.expression);
            const { length } = clonedExpressions[index].value;

            if (clonedExpressions?.[index]?.value?.[length - 1]?.value?.length < 2) {
                if (length > 0) {
                    clonedExpressions[index].value[length - 1].relation = LOGICAL_OPERATORS.and;
                }
                clonedExpressions[index].value[length - 1].value.push(this.conditionsList[0]);
            } else {
                clonedExpressions[index].value.push({
                    value: [this.conditionsList[0]],
                    relation: LOGICAL_OPERATORS.and,
                    groupRelation: LOGICAL_OPERATORS.and,
                });
            }

            this.$emit('updateExpression', clonedExpressions);
        },
        removeCondition({ index, conditionIndex }) {
            const clonedExpressions = cloneDeep(this.expression);
            if (this.expression[index].value.length >= 2) {
                clonedExpressions[index].value.splice(conditionIndex, 1);
                clonedExpressions[index].groupRelation = LOGICAL_OPERATORS.and;
                this.$emit('updateExpression', clonedExpressions);
                return;
            }

            clonedExpressions.splice(index, 1);
            if (clonedExpressions.length) {
                clonedExpressions[clonedExpressions.length - 1].relation = LOGICAL_OPERATORS.and;
            }
            this.$emit('updateExpression', clonedExpressions);
        },
        removeMultipleRowsCondition({ index, multipleRowsExpressionIndex, multipleRowsConditionIndex }) {
            const clonedExpressions = cloneDeep(this.expression);

            if (this.expression?.[index]?.value?.[multipleRowsExpressionIndex]?.value?.length > 1) {
                clonedExpressions[index].value[multipleRowsExpressionIndex].value.splice(multipleRowsConditionIndex, 1);
                clonedExpressions[index].value[multipleRowsExpressionIndex].groupRelation = LOGICAL_OPERATORS.and;
            } else {
                clonedExpressions[index].value.splice(multipleRowsExpressionIndex, 1);
                if (clonedExpressions[index].value.length) {
                    clonedExpressions[index].value[clonedExpressions[index].value.length - 1].relation =
                        LOGICAL_OPERATORS.and;
                }
            }

            if (clonedExpressions?.[index]?.value?.length === 0) {
                clonedExpressions.splice(index, 1);
                if (clonedExpressions.length) {
                    clonedExpressions[clonedExpressions.length - 1].relation = LOGICAL_OPERATORS.and;
                }
                this.$emit('updateExpression', clonedExpressions);
                return;
            }

            this.$emit('updateExpression', clonedExpressions);
        },
        updateCondition({ index, conditionIndex, value }) {
            const clonedExpressions = cloneDeep(this.expression);
            if (this.expression[index].value.length) {
                clonedExpressions[index].value[conditionIndex] = value;
                this.$emit('updateExpression', clonedExpressions);
                return;
            }
            clonedExpressions[index].value = [value];
            this.$emit('updateExpression', clonedExpressions);
        },
        updateMultipleRowsCondition({ value, index, multipleRowsExpressionIndex, multipleRowsConditionIndex }) {
            const clonedExpressions = cloneDeep(this.expression);
            if (this.expression?.[index]?.value?.[multipleRowsExpressionIndex]?.value?.[multipleRowsConditionIndex]) {
                clonedExpressions[index].value[multipleRowsExpressionIndex].value[multipleRowsConditionIndex] = value;
                this.$emit('updateExpression', clonedExpressions);
                return;
            }
            clonedExpressions[index].value[multipleRowsExpressionIndex].value[multipleRowsConditionIndex] = [value];
            this.$emit('updateExpression', clonedExpressions);
        },
        updateRelation({ index, value }) {
            const clonedExpressions = cloneDeep(this.expression);
            clonedExpressions[index].relation = value;
            this.$emit('updateExpression', clonedExpressions);
        },
        updateMultipleRowsConditionRelation({ value, index, multipleRowsExpressionIndex }) {
            const clonedExpressions = cloneDeep(this.expression);
            clonedExpressions[index].value[multipleRowsExpressionIndex].relation = value;
            this.$emit('updateExpression', clonedExpressions);
        },
        updateGroupRelation({ index, value }) {
            const clonedExpressions = cloneDeep(this.expression);
            clonedExpressions[index].groupRelation = value;
            this.$emit('updateExpression', clonedExpressions);
        },
        updateMultipleRowsConditionGroupRelation({ value, index, multipleRowsExpressionIndex }) {
            const clonedExpressions = cloneDeep(this.expression);
            clonedExpressions[index].value[multipleRowsExpressionIndex].groupRelation = value;
            this.$emit('updateExpression', clonedExpressions);
        },
        isNotLastRelation(arr, index) {
            return index !== arr.length - 1;
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/base.scss';
@import '~@/assets/scss/palette';
@import '~@/assets/scss/layout.scss';
@import '~@/assets/scss/icons.scss';
@import '~@/assets/scss/animations';

$icon-path: '~@/assets/icons/';

.conditions {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;

    .expression-row-container {
        width: 100%;
        align-items: center;
        justify-content: flex-start;
        border-radius: 0.4rem;
        background-color: $blue15;
        padding: 1rem;
        margin-bottom: 1rem;

        ::v-deep .icon-left-button .icon {
            margin: 0;
        }

        .arrow {
            transition: $fast-animation-time ease-in-out;

            &.collapsed {
                transform: rotate(180deg);
            }
        }

        .condition-row {
            display: flex;
            flex-direction: row;

            .condition-block {
                width: 100%;
            }

            .close-icon-container {
                height: 0.9rem;
                width: 0.9rem;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-left: 0.8rem;
                margin-top: 0.5rem;
                z-index: 1;
                background-clip: padding-box;
                cursor: pointer;
                height: 2rem;
            }
        }

        .expression-actions {
            display: flex;
            justify-content: space-between;
            padding-top: 1rem;

            .add-icon-container {
                display: flex;
                align-items: center;
                cursor: pointer;
                width: 100%;

                p {
                    color: $blue;
                }
            }

            .plus-blue-icon-button {
                width: 0.8rem;
                content: url($icon-path + $icon-plus-blue);
            }

            .remove-all {
                display: flex;
                align-items: flex-end;
                justify-content: right;
                width: 100%;
                cursor: pointer;

                p {
                    color: $blue;
                }
            }
        }
    }
}
</style>
