
import Vue from 'vue';
import AbstractTableTile from '@/__new__/features/customerCareSuite/components/AbstractTableTile.vue';
import EntityStatusIndicator from '@/components/partials/EntityStatusIndicator.vue';
import consentHTTP from '@/__new__/services/dno/consent/http/consent';
import * as Sentry from '@sentry/vue';
import { AxiosError } from 'axios';
import tableColumnType, { type TableColumn } from '@/common/filterTable';
import {
    CONSENT_CONTEXT_TYPE,
    getConsentsContextTypeI18nKey,
    CONSENT_STATUS_TO_LABEL_MAP,
    CONSENT_STATUS_INDICATOR_MAP,
    GetConsentsResponse,
    getConsentStatusKey,
} from '@/__new__/features/customerCare/common/consentsHelper';

export default Vue.extend({
    name: 'ConsentHistoryTile',
    components: { AbstractTableTile, EntityStatusIndicator },
    props: {
        userManagerHierarchy: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            rawResponse: {} as GetConsentsResponse,
            CONSENT_STATUS_TO_LABEL_MAP,
            CONSENT_STATUS_INDICATOR_MAP,
            getConsentStatusKey,
        };
    },
    computed: {
        columnsData(): TableColumn[] {
            return [
                {
                    name: this.$i18n.t('customerCare.consent.dateAndTime'),
                    key: 'epoch',
                    field: 'epoch',
                    filterType: tableColumnType.DATE,
                    formatter: this.$localeLibrary.getFormattedDateAndTime,
                },
                {
                    name: this.$i18n.t('customerCare.consent.contextType'),
                    key: 'context_type',
                    field: 'context_type',
                    filterType: tableColumnType.GENERAL_TEXT,
                    formatter: this.getContextType,
                },
                {
                    name: this.$i18n.t('customerCare.consent.status'),
                    key: 'is_set',
                    field: 'is_set',
                    filterType: tableColumnType.BOOLEAN,
                },
            ];
        },
    },
    created() {
        this.fetchTileData();
    },
    methods: {
        async fetchTileData() {
            await this.$withProgressBar(
                async () => {
                    this.$emit('isDataLoadingUpd', true);
                    const consentResponse = await consentHTTP.getConsents(
                        this.$route.params.id,
                        this.userManagerHierarchy,
                    );
                    this.rawResponse = consentResponse?.data || {};
                    this.$emit('isDataLoadingUpd', false);
                },
                {
                    errorHandler: error => {
                        this.$emit('dataFetchingError');
                        this.rawResponse = (error as AxiosError).response || {};
                        Sentry.captureException(error);
                        this.$emit('isDataLoadingUpd', false);
                    },
                },
            );
        },
        getContextType(contextType: CONSENT_CONTEXT_TYPE) {
            return this.$t(getConsentsContextTypeI18nKey(contextType));
        },
    },
});
