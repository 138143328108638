<template>
    <EditorManagementLayout
        :entityLabelKey="'operator.number'"
        :dragDropEntityLabelKey="'operator.numberManagement.MSISDNs'"
        :utilityTypes="utilityTypes"
        :configFileUploader="configFileUploader"
        :counts="counts"
        :searchEnabled="isUserAllowed('SearchNumbers')"
        :uploaderEnabled="isUserAllowed('UploadNumbers')"
    />
</template>

<script>
// COMPONENTS
import EditorManagementLayout from '@/__new__/features/resources/EditorManagementLayout.vue';

// HELPERS
import { SEGMENT_ID_TYPES, StaticFilterLabels } from '@/common/StaticFilter';
import * as Sentry from '@sentry/vue';
import MSISDNStatistics from '@/__new__/services/dno/number/models/msisdnStatistics';
import { isUserAllowed } from '@/services/permissions/permissions.service';

// HTTP
import {
    getSignedURL,
    getRegisteredEntities,
    getEntityDetailsByName,
    getEntityDetailsByCategory,
    getMsisdnStatistics,
} from '@/__new__/services/dno/number/http/numberManagement';

export default {
    name: 'NumberManagement',
    components: {
        EditorManagementLayout,
    },
    data() {
        return {
            utilityTypes: [
                {
                    id: SEGMENT_ID_TYPES.MSISDN,
                    label: StaticFilterLabels[SEGMENT_ID_TYPES.MSISDN],
                    i18n: 'customerCare.msisdn',
                },
            ],
            configFileUploader: {
                getSignedURL,
                getEntityDetailsByCategory,
                getEntityDetailsByName,
                getRegisteredEntities,
                getSignedURLParams: params => ({ name: params.blobName }),
                customData: {},
            },
            counts: [],
        };
    },
    created() {
        if (this.isUserAllowed('ViewNumberCounts')) {
            this.loadMsisdnStatistics();
        }
    },
    methods: {
        async loadMsisdnStatistics() {
            try {
                this.$Progress.start();
                const result = await getMsisdnStatistics();
                const statistics = result?.data?.result;
                if (statistics) {
                    this.setData(statistics);
                }
                this.$Progress.finish();
            } catch (e) {
                Sentry.captureException(e);
                this.$Progress.fail();
                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('alertMessage.failedToLoadNecessaryData'),
                });
            }
        },
        setData(statistics) {
            const msisdnStatistics = new MSISDNStatistics(statistics);
            this.counts = [
                {
                    title: `${this.$i18n.tc('operator.number')} ${this.$i18n.t('operator.counts')}`,
                    fields: [
                        {
                            label: `${this.$i18n.t('operations.numberManagement.totalNumbers')}`,
                            value: msisdnStatistics.totalNumber,
                        },
                        {
                            label: `${this.$i18n.t('operations.numberManagement.totalOwnedNumbers')}`,
                            value: msisdnStatistics.totalOwnedNumber,
                        },
                        {
                            label: `${this.$i18n.t('operations.numberManagement.totalAvailableNumbers')}`,
                            value: msisdnStatistics.availableNumber,
                        },
                        {
                            label: `${this.$i18n.t('operations.numberManagement.totalReservedNumbers')}`,
                            value: msisdnStatistics.reserveNumber,
                        },
                        {
                            label: `${this.$i18n.t('operations.numberManagement.totalAssignedNumbersOwned')}`,
                            value: msisdnStatistics.assignedOwnedNumber,
                        },
                        {
                            label: `${this.$i18n.t('operations.numberManagement.totalAssignedNumbersPortIn')}`,
                            value: msisdnStatistics.assignedNumberPortIn,
                        },
                        {
                            label: `${this.$i18n.t('operations.numberManagement.totalAssignedNumbersPortOut')}`,
                            value: msisdnStatistics.assignedNumberPortOut,
                        },
                        {
                            label: `${this.$i18n.t('operations.numberManagement.cooldownNumbers')}`,
                            value: msisdnStatistics.coolDownNumber,
                        },
                        {
                            label: `${this.$i18n.t('operations.numberManagement.unavailableNumbers')}`,
                            value: msisdnStatistics.unavailableNumber,
                        },
                    ],
                },
            ];
        },
        isUserAllowed,
    },
};
</script>
