<template>
    <OperateApisListPageLayout
        :pageTitle="$i18n.t('operateAPIs.apiProducts')"
        :entityType="OPERATE_APIS_TYPES.API_PRODUCT"
        :entityResponse="apiProductsApiResponse"
        :entityData="entityData"
        :tabs="tabs"
        :defaultTabId="defaultTabId"
        @onSelectEntity="onSelectEntity"
        @onSelectPartyRole="onSelectPartyRole"
        @onTabChange="selectedTabId = $event"
    >
        <template #sidebarHeader>
            <OverviewHeaderV2
                v-if="selectedApiProduct"
                :entityName="selectedApiProduct.name"
                :entityId="selectedApiProduct.id"
            >
                <template #statusIndicator>
                    <EntityStatusIndicator
                        :status="selectedApiProduct.status"
                        :stateMap="PRODUCT_STATUS_TO_LABEL_MAP"
                        :stateMapColor="PRODUCT_STATUS_TO_COLOR_MAP"
                    />
                </template>
            </OverviewHeaderV2>
        </template>
        <template #sidebarOverview>
            <div v-if="selectedTabId === 'general'">
                <AppOverviewBlock
                    :items="generalDetails"
                    :isRowTitleCounterVisible="false"
                    :maxItemRows="10"
                    class="mb-3"
                    data-test-id="general-details"
                />
                <AppOverviewBlock
                    :items="datesAndTimes"
                    :isRowTitleCounterVisible="false"
                    class="mb-3"
                    data-test-id="dates-and-times"
                />
                <AppOverviewBlock
                    :items="productOfferingDetails"
                    :isRowTitleCounterVisible="false"
                    class="mb-3"
                    data-test-id="product-offering-details"
                />
                <AppOverviewBlock
                    :items="productSpecificationDetails"
                    :isRowTitleCounterVisible="false"
                    class="mb-3"
                    data-test-id="product-specification-details"
                />
                <AppOverviewBlock
                    :title="$i18n.tc('operateAPIs.agreementItem', 2)"
                    :items="agreementItemDetails"
                    :isRowTitleCounterVisible="false"
                    class="mb-3"
                    data-test-id="agreement-item-details"
                />
            </div>
        </template>
    </OperateApisListPageLayout>
</template>

<script>
// Components
import OperateApisListPageLayout from '@/__new__/features/operateAPIs/OperateApisListPageLayout.vue';
import AppOverviewBlock from '@/components/partials/AppOverviewBlock.vue';
import OverviewHeaderV2 from '@/components/partials/entityOverview/OverviewHeaderV2.vue';
import EntityStatusIndicator from '@/components/partials/EntityStatusIndicator.vue';

// HTTP
import { getApiProducts } from '@/__new__/services/dno/operateAPIs/http/operateAPIs';

// Helpers
import { isUserAllowed } from '@/services/permissions/permissions.service';
import { OPERATE_APIS_TYPES } from '@/__new__/features/operateAPIs/common/operateAPIsHelper';
import {
    PRODUCT_STATUS_TO_LABEL_MAP,
    PRODUCT_STATUS_TO_COLOR_MAP,
} from '@/__new__/features/operateAPIs/common/ApiProduct';

export default {
    name: 'ApiProductsListPage',

    components: {
        AppOverviewBlock,
        OperateApisListPageLayout,
        OverviewHeaderV2,
        EntityStatusIndicator,
    },

    data() {
        return {
            apiProductsApiResponse: {},
            entityData: [],
            selectedApiProduct: null,
            defaultTabId: 'general',
            selectedTabId: 'general',
            tabs: [
                {
                    id: 'general',
                    label: this.$i18n.t('generic.general'),
                },
                {
                    id: 'json',
                    label: this.$i18n.t('generic.json'),
                },
            ],
            OPERATE_APIS_TYPES,
            PRODUCT_STATUS_TO_LABEL_MAP,
            PRODUCT_STATUS_TO_COLOR_MAP,
        };
    },

    computed: {
        selectedEntityId() {
            return this.selectedApiProduct ? this.selectedApiProduct.id : null;
        },
        generalDetails() {
            return [
                {
                    isCollapsed: false,
                    name: this.$i18n.t('generic.general'),
                    rows: [
                        {
                            name: this.$i18n.t('generic.id'),
                            value: this.selectedApiProduct?.id || this.$i18n.t('generic.empty'),
                        },
                        {
                            name: this.$i18n.t('generic.name'),
                            value: this.selectedApiProduct?.name || this.$i18n.t('generic.empty'),
                        },
                        {
                            name: this.$i18n.t('generic.description'),
                            value: this.selectedApiProduct?.description || this.$i18n.t('generic.empty'),
                        },
                        {
                            name: this.$i18n.t('operateAPIs.APIName'),
                            value: this.selectedApiProduct?.apiName || this.$i18n.t('generic.empty'),
                        },
                        {
                            name: this.$i18n.t('customerCareSuite.applicationTile.purpose'),
                            value: this.selectedApiProduct?.purpose || this.$i18n.t('generic.empty'),
                        },
                        {
                            name: this.$i18n.t('generic.link'),
                            value: this.selectedApiProduct?.href || this.$i18n.t('generic.empty'),
                        },
                        {
                            name: this.$i18n.t('generic.status'),
                            value: this.selectedApiProduct?.status || this.$i18n.t('generic.empty'),
                        },
                        {
                            name: this.$i18n.t('operateAPIs.channelPartner'),
                            value: this.selectedApiProduct?.channelPartner?.id || this.$i18n.t('generic.empty'),
                        },
                    ],
                },
            ];
        },
        datesAndTimes() {
            return [
                {
                    isCollapsed: false,
                    name: this.$i18n.t('generic.datesAndTimes'),
                    rows: [
                        {
                            name: this.$i18n.t('generic.updateTime'),
                            value: this.selectedApiProduct?.lastUpdateStr || this.$i18n.t('generic.empty'),
                        },
                        {
                            name: this.$i18n.t('generic.creationDate'),
                            value: this.selectedApiProduct?.creationDateStr || this.$i18n.t('generic.empty'),
                        },
                        {
                            name: this.$i18n.t('generic.orderDate'),
                            value: this.selectedApiProduct?.orderDateStr || this.$i18n.t('generic.empty'),
                        },
                        {
                            name: this.$i18n.t('generic.startDate'),
                            value: this.selectedApiProduct?.startDateStr || this.$i18n.t('generic.empty'),
                        },
                        {
                            name: this.$i18n.t('generic.terminationDate'),
                            value: this.selectedApiProduct?.terminationDateStr || this.$i18n.t('generic.empty'),
                        },
                    ],
                },
            ];
        },
        productOfferingDetails() {
            return [
                {
                    isCollapsed: false,
                    name: this.$i18n.t('operateAPIs.productOffering'),
                    rows: [
                        {
                            name: this.$i18n.t('generic.id'),
                            value: this.selectedApiProduct?.productOffering?.id || this.$i18n.t('generic.empty'),
                        },
                        {
                            name: this.$i18n.t('generic.name'),
                            value: this.selectedApiProduct?.productOffering?.name || this.$i18n.t('generic.empty'),
                        },
                        {
                            name: this.$i18n.t('generic.link'),
                            value: this.selectedApiProduct?.productOffering?.href || this.$i18n.t('generic.empty'),
                        },
                    ],
                },
            ];
        },
        productSpecificationDetails() {
            return [
                {
                    isCollapsed: false,
                    name: this.$i18n.t('operateAPIs.productSpecification'),
                    rows: [
                        {
                            name: this.$i18n.t('generic.id'),
                            value: this.selectedApiProduct?.productSpecification?.id || this.$i18n.t('generic.empty'),
                        },
                        {
                            name: this.$i18n.t('generic.name'),
                            value: this.selectedApiProduct?.productSpecification?.name || this.$i18n.t('generic.empty'),
                        },
                        {
                            name: this.$i18n.t('generic.link'),
                            value: this.selectedApiProduct?.productSpecification?.href || this.$i18n.t('generic.empty'),
                        },
                        {
                            name: this.$i18n.t('generic.version'),
                            value:
                                this.selectedApiProduct?.productSpecification?.version || this.$i18n.t('generic.empty'),
                        },
                    ],
                },
            ];
        },
        agreementItemDetails() {
            return (
                this.selectedApiProduct?.agreementItem?.map?.(item => ({
                    isCollapsed: true,
                    name: item.agreementName || this.$i18n.tc('operateAPIs.agreementItem', 1),
                    rows: [
                        {
                            name: this.$i18n.t('generic.id'),
                            value: item.agreementId || this.$i18n.t('generic.empty'),
                        },
                        {
                            name: this.$i18n.t('operateAPIs.itemId'),
                            value: item.agreementItemId || this.$i18n.t('generic.empty'),
                        },
                        {
                            name: this.$i18n.t('generic.name'),
                            value: item.agreementName || this.$i18n.t('generic.empty'),
                        },
                        {
                            name: this.$i18n.t('generic.link'),
                            value: item.agreementHref || this.$i18n.t('generic.empty'),
                        },
                    ],
                })) ?? []
            );
        },
    },

    methods: {
        isUserAllowed,
        onSelectEntity(entity) {
            [this.selectedApiProduct] = this.entityData.filter(data => data.id === entity);
        },
        onSelectPartyRole(id) {
            this.$withLoadingSpinner(
                async () => {
                    await this.fetchApiProducts(id);
                },
                {
                    errorHandler: () => {
                        this.$alert(this.$t('operateAPIs.somethingWentWrongFetchingApiProducts'));
                    },
                },
            );
        },
        async fetchApiProducts(partyRoleId) {
            const response = await getApiProducts(partyRoleId);
            this.apiProductsApiResponse = response;

            this.entityData = response.data.map(apiProduct => {
                return {
                    id: apiProduct?.id || '',
                    name: apiProduct?.name || '',
                    description: apiProduct?.description || '',
                    createOn: apiProduct?.createOn || '',
                    lastUpdate: apiProduct?.lastUpdate,
                    startDate: apiProduct?.startDate || '',
                    creationDate: apiProduct?.creationDate || '',
                    orderDate: apiProduct?.orderDate || '',
                    terminationDate: apiProduct?.terminationDate || '',
                    lastUpdateStr: this.$localeLibrary.iso8601ToFormattedDateAndTime(apiProduct?.lastUpdate),
                    startDateStr: this.$localeLibrary.iso8601ToFormattedDateAndTime(apiProduct?.startDate),
                    creationDateStr: this.$localeLibrary.iso8601ToFormattedDateAndTime(apiProduct?.creationDate),
                    orderDateStr: this.$localeLibrary.iso8601ToFormattedDateAndTime(apiProduct?.orderDate),
                    terminationDateStr: this.$localeLibrary.iso8601ToFormattedDateAndTime(apiProduct?.terminationDate),
                    status: apiProduct?.status || '',
                    apiName: apiProduct?.apiName || '',
                    purpose: apiProduct?.purpose || '',
                    href: apiProduct?.href || '',
                    channelPartner: apiProduct?.channelPartner,
                    productOffering: apiProduct?.productOffering,
                    productSpecification: apiProduct?.productSpecification,
                    agreementItem: apiProduct?.agreementItem,
                };
            });
        },
    },
};
</script>
