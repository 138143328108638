const genericReservedKeys = [
    'id',
    'name',
    'description',
    'data',
    'relationships',
    'template_id',
    'entityType',
    'state',
    'version',
    'update_time',
    'external_id',
];

export const resourceReservedKeys = [...genericReservedKeys, 'type'];

export const policySpecificationReservedKeys = [
    ...genericReservedKeys,
    'type',
    'spec_upgrade',
    'lifecycle_status',
    'validity_control',
    'validity_duration_unit',
    'validity_duration_amount',
    'validity_query_count',
    'data_element',
    'service_id',
    'service_type',
    'legal_text',
    'validity_start_date',
    'validity_end_date',
];
