
// Components
import AppIcon from '@/components/partials/icon/AppIcon.vue';

// Helpers
import { ALERT_TYPES } from '@/common/alerts/Alert';
import { ICON_COLORS, ICON_TYPES } from '@/common/iconHelper';

// explanation on the types usage can be found here:
// https://www.figma.com/file/guLrapQ5tJniFiI55RlRAs/Portal?node-id=14494%3A459271&mode=dev
export const BUTTON_TYPES = {
    PRIMARY: 'primary',
    SECONDARY: 'secondary',
    TERTIARY: 'tertiary',
    TERMINATION: 'termination',
    ALERT: 'alert',
};
const BUTTON_SPINNERS = {
    [BUTTON_TYPES.PRIMARY]: {
        icon: ICON_TYPES.LOADER,
        color: ICON_COLORS.BLUE,
    },
    [BUTTON_TYPES.SECONDARY]: {
        icon: ICON_TYPES.LOADER,
        color: ICON_COLORS.BLUE,
    },
    [BUTTON_TYPES.TERTIARY]: {
        icon: ICON_TYPES.SPINNER,
        color: ICON_COLORS.GRAY,
    },
    [BUTTON_TYPES.TERMINATION]: {
        icon: ICON_TYPES.LOADER,
        color: ICON_COLORS.BLUE,
    },
    [BUTTON_TYPES.ALERT]: {
        icon: ICON_TYPES.LOADER,
        color: ICON_COLORS.BLUE,
    },
};

export default {
    name: 'AppButton',
    components: {
        AppIcon,
    },
    props: {
        buttonType: {
            type: String,
            default: BUTTON_TYPES.TERTIARY,
            validator(value: string) {
                return Object.values(BUTTON_TYPES).includes(value);
            },
        },
        label: {
            type: String,
            required: false,
            default: '',
        },
        iconType: {
            type: String,
            required: false,
            default: '',
            validator(value: string) {
                return value ? Object.values(ICON_TYPES).includes(value) : true;
            },
        },
        iconColor: {
            type: String,
            required: false,
            default: '',
            validator(value: string) {
                return value ? Object.values(ICON_COLORS).includes(value) : true;
            },
        },
        isIconRight: {
            type: Boolean,
            required: false,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        tooltip: {
            type: String,
            default: '',
        },
        alertType: {
            type: String,
            required: false,
            default: '',
        },
        isSmall: {
            type: Boolean,
            default: false,
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
        clicked: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            ALERT_TYPES,
            ICON_TYPES,
            ICON_COLORS,
            BUTTON_SPINNERS,
        };
    },
    computed: {
        isLoadingStateWithoutIcon() {
            return this.isLoading && !this.iconType;
        },
    },
    methods: {
        onClick(event: any) {
            if (!this.disabled && !this.isLoading) {
                this.$emit('click', event);
            }
        },
    },
};
