
import Vue, { PropType } from 'vue';
import AppIcon from '@/components/partials/icon/AppIcon.vue';
import { ICON_COLORS, ICON_TYPES } from '@/common/iconHelper';

export default Vue.extend({
    name: 'IconButton',
    components: {
        AppIcon,
    },
    props: {
        label: {
            type: String,
            default: '',
            required: false,
        },
        icon: {
            type: String as PropType<ICON_TYPES>,
            required: true,
            validator(value) {
                // The value must match one of iconTypes values
                return Object.values(ICON_TYPES).includes(value);
            },
        },
        iconSize: {
            type: String,
            default: '18px',
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        allowClickPropagation: {
            type: Boolean,
            default: false,
        },
        manualTriggerActiveIcon: {
            type: Boolean,
            default: false,
        },
        /**
         * Link which the icon button is associated to.
         * When this property is used as a value for an anchor tag
         * it allows the icon button to be right clicked, and to show
         * a 'Open in New Tab' option.
         *
         * If `null` icon will not behave as a link.
         */
        href: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            isHovered: false,
            ICON_COLORS,
            ICON_TYPES,
        };
    },
    methods: {
        emitIconButtonClicked(event: Event): void {
            if (!this.allowClickPropagation) {
                event.stopPropagation();
            }
            if (!this.disabled) {
                this.$emit('iconClick');
                this.isHovered = false;
            }
        },
    },
});
