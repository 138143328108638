
import Vue from 'vue';
import { mapGetters } from 'vuex';
import { Getters } from '@/store/mutation-types';

// Layout
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import FilterTable from '@/components/partials/FilterTable.vue';
import FilterTableMixin from '@/components/partials/FilterTableMixin.vue';
import AbstractListPageWrapper from '@/components/layout/AbstractListPageWrapper.vue';
import AppTable from '@/components/partials/AppTable.vue';
import TableFiltersTags from '@/components/filters/TableFiltersTags.vue';
import tableColumnType from '@/common/filterTable';
import ResponseModalButton from '@/components/partials/ResponseModalButton.vue';
import IconButton from '@/components/partials/IconButton.vue';
import Button from '@/common/button/Button';
import EntityOverview from '@/components/partials/entityOverview/EntityOverview.vue';
import OverviewList from '@/components/partials/entityOverview/OverviewList.vue';
import OverviewHeader from '@/components/partials/entityOverview/OverviewHeader.vue';
import LanguageSwitcher from '@/components/partials/inputs/LanguageSwitcher.vue';

// Types
import { ALERT_TYPES } from '@/common/alerts/Alert';
import ENTITY_TYPES from '@/common/entities/entityTypes';
import { ICON_TYPES } from '@/common/iconHelper';
import { EntityState } from '@/http';

// Fee Rules
import { getFeeRules, deleteFeeRule } from '@/__new__/services/dno/pricing/feeRulesService';
import { FeeRuleEntity } from '@/__new__/services/dno/pricing/feeRulesPortal';
import { SCOPE_LEVEL } from '@/__new__/services/dno/pricing/models/pricingDno';
import { typeToString, scopeToString, summary as feeRuleSummary } from './feeRulesUtils';

// Permissions
import permissionsService, { isUserAllowed } from '@/services/permissions/permissions.service';

// Misc
import * as Sentry from '@sentry/vue';
import { uniq } from 'lodash';
import RouteNames from '@/router/routeNames';
import { getOffers, Offer } from '@/__new__/services/dno/pc/http/offer';

type DisplayFeeRule = FeeRuleEntity & {
    typeStr: string;
};

export default Vue.extend({
    name: 'FeeRulesListPage',
    components: {
        AbstractListPageWrapper,
        AppButton,
        AppTable,
        FilterTable,
        IconButton,
        TableFiltersTags,
        ResponseModalButton,
        EntityOverview,
        OverviewList,
        OverviewHeader,
        LanguageSwitcher,
    },
    mixins: [FilterTableMixin],
    data() {
        return {
            ALERT_TYPES,
            BUTTON_TYPES,
            ICON_TYPES,
            ENTITY_TYPES,
            feeRules: [] as FeeRuleEntity[],
            feeRulesResponse: {},
            searchQueryForTable: '',
            isDataLoading: false,
            selectedEntity: null as DisplayFeeRule | null,
            isOverviewEnabled: false,
            offerById: {} as Record<string, Offer>,
        };
    },
    computed: {
        ...mapGetters('operators', {
            selectedLanguage: Getters.languageDefault,
        }),
        // Transform FeeRuleEntity into display format
        displayFeeRules(): DisplayFeeRule[] {
            // Omit deleted Fee Rules
            const nonDeletedFeeRules = this.feeRules.filter(e => e.state !== EntityState.DELETED);
            // Transform into display format
            return nonDeletedFeeRules.map(feeRule => ({
                ...feeRule,
                typeStr: typeToString(feeRule.type),
            }));
        },
        entityOverviewDetails() {
            if (!this.selectedEntity) {
                return [];
            }

            // Build Condition Section:
            const conditionSection = {
                name: this.$i18n.t('generic.condition'),
                properties: [
                    {
                        label: this.$i18n.t('generic.type'),
                        value: this.selectedEntity.type,
                        formatter: typeToString,
                    },
                    {
                        label: this.$i18n.t('pricingAndFees.label.scope'),
                        value: this.selectedEntity.scope,
                        formatter: scopeToString,
                    },
                ],
            };
            // Add a row for offers only if our scope is line item level:
            if (this.selectedEntity.scope === SCOPE_LEVEL.LINE_ITEM_LEVEL) {
                const offersRow: any = {
                    label: this.$i18n.t('pricingAndFees.appliesTo'),
                    value: '',
                };
                if (this.selectedEntity.offers.length === 0) {
                    offersRow.value = this.$i18n.t('pricingAndFees.allOffers');
                } else {
                    offersRow.value = this.getOfferNames(this.selectedEntity).join(', ');
                }
                conditionSection.properties.push(offersRow);
            }

            return [
                {
                    name: this.$i18n.t('generic.summary'),
                    properties: [
                        {
                            label: this.$i18n.t('pricingAndFees.fee'),
                            value: feeRuleSummary(this.selectedEntity),
                        },
                    ],
                },
                {
                    name: this.$i18n.t('generic.details'),
                    properties: [
                        {
                            label: this.$i18n.t('generic.id'),
                            value: this.selectedEntity.id,
                        },
                        {
                            label: this.$i18n.t('generic.name'),
                            value: this.selectedEntity.name,
                        },
                        {
                            label: this.$i18n.t('generic.description'),
                            value: this.selectedEntity.description,
                        },
                    ],
                },
                conditionSection,
            ];
        },
        filteredFeeRules() {
            return this.filteredEntitiesMixin(this.displayFeeRules);
        },
        tableColumnsData() {
            return [
                {
                    name: this.$i18n.t('generic.name'),
                    key: 'name',
                    field: 'name',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('generic.type'),
                    key: 'typeStr',
                    field: 'typeStr',
                    filterType: tableColumnType.TEXT_LIMITED_OPTIONS,
                    limitedOptions: uniq(this.displayFeeRules.map(e => e.typeStr)),
                },
                {
                    name: this.$i18n.t('generic.description'),
                    key: 'description',
                    field: 'description',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
            ].filter(column => column);
        },
    },
    async created() {
        await this.loadFeeRules();
        await this.loadOffers();
    },
    methods: {
        // Get a list of names of offers applicable to the given entity
        getOfferNames(entity: FeeRuleEntity): string[] {
            return entity.offers.map(id => {
                const offer = this.offerById[id];
                if (offer === undefined) {
                    return id;
                }
                const { name } = offer.data;
                switch (typeof name) {
                    case 'string':
                        return name;
                    case 'object':
                        return name[this.selectedLanguage] ?? Object.values(name)[0];
                    default:
                        return id;
                }
            });
        },
        goToFeeRuleEditor() {
            this.$router.push({
                name: RouteNames.FEE_RULES_EDITOR,
                params: { companyId: this.$route.params.companyId },
            });
        },
        async loadFeeRules() {
            try {
                // Loading + Progress
                this.isDataLoading = true;
                this.$Progress.start();

                // Get Fee Rules
                const data = await getFeeRules();
                this.feeRulesResponse = data.response;
                this.feeRules = data.feeRules;

                // Finish loading + progress
                this.isDataLoading = false;
                this.$Progress.finish();
            } catch (error) {
                this.$alert(this.$i18n.t('alertMessage.pricingAndFees.failedToGetFeeRules'));
                Sentry.captureException(error);
                this.isDataLoading = false;
                this.$Progress.fail();
            }
        },
        async loadOffers() {
            try {
                const response = await getOffers();
                this.offerById = response.data.offer_by_id;
            } catch (error) {
                this.$alert(this.$i18n.t('alertMessage.pricingAndFees.failedToGetOffers'));
                Sentry.captureException(error);
            }
        },
        /**
         * Creates an alert which confirms if the FeeRule should be deleted.
         */
        confirmDeleteFeeRule(entity: FeeRuleEntity) {
            // Create delete button
            const button = new Button({
                label: this.$i18n.t('generic.delete'),
                alertType: ALERT_TYPES.warning,
                handler: () => {
                    this.deleteFeeRule(entity);
                },
            });

            // Deletion Confirmation Alert
            this.$alert(
                this.$i18n.t('alerts.areYouSureDeleteEntity', {
                    entityName: entity.name,
                }),
                {
                    type: ALERT_TYPES.warning,
                    buttons: [button],
                },
            );
        },
        /**
         * Sends HTTP request to delete FeeRule on the DNO
         */
        async deleteFeeRule(entity: FeeRuleEntity) {
            try {
                // Send request
                this.$Progress.start();
                await deleteFeeRule(entity);
                this.$Progress.finish();

                // Show success alert
                this.$alert(this.$i18n.t('alertMessage.pricingAndFees.successfullyDeletedFeeRule'), {
                    type: ALERT_TYPES.success,
                });

                // Reload fee rules
                await this.loadFeeRules();
            } catch (error) {
                this.$alert(this.$i18n.t('alertMessage.pricingAndFees.failedToDeleteFeeRule'), {
                    type: ALERT_TYPES.error,
                });
                this.$Progress.fail();
                Sentry.captureException(error);
            }
        },
        openEditor(entity: FeeRuleEntity) {
            this.$router.push({
                name: RouteNames.FEE_RULES_EDITOR,
                params: {
                    id: entity.id,
                    entity,
                    companyId: this.$route.params.companyId,
                },
            });
        },
        onEntitySelected(entity: DisplayFeeRule) {
            this.selectedEntity = entity;
            this.isOverviewEnabled = true;
        },
        // Note: Once new permissions are in this function can be removed and usages
        // can be replaced with `isUserAllowed(...)`
        userHasFeeRuleWriteAccess(): boolean {
            return permissionsService.feeRulesEditorEnabled() && isUserAllowed('FeeRulesWrite');
        },
        setSearchQuery(query) {
            this.searchQueryForTable = query;
        },
    },
});
