
import Vue from 'vue';
// Vue components
import AppButton from '@/components/partials/inputs/AppButton.vue';
import AppInputV3 from '@/components/partials/inputs/AppInputV3.vue';
import AppMultiselectV3 from '@/components/partials/inputs/AppMultiselectV3.vue';
import AppJSON from '@/components/partials/AppJSON.vue';

// Mixins
import { validationMixin } from 'vuelidate';

import {
    ACTION_TYPE,
    PRICING_COMPONENT_TYPE,
    SCOPE_LEVEL,
    EXPRESSION_TYPE,
    PricingRuleAction,
} from '@/__new__/services/dno/pricing/models/pricingDno';

// Helpers
import { TOOLTIP_POSITION } from '@/common/tooltip';
import { ICON_TYPES } from '@/common/iconHelper';
import { ALERT_TYPES } from '@/common/alerts/Alert';
import Button from '@/common/button/Button';
import PricingRuleActionSelector from '@/__new__/features/pricingAndFees/pricingRules/PricingRuleActionSelector.vue';
import AppToggle from '@/components/partials/inputs/AppToggle.vue';
import { RecursivePartial } from '@/common/utils';
import { PricingRuleActionPortal } from '@/__new__/services/dno/pricing/models/pricingPortal';
import {
    convertPricingRuleActionFromDnoToPortal,
    convertPricingRuleActionFromPortalToDno,
} from '@/__new__/services/dno/pricing/pricingRulesService';

export default Vue.extend({
    name: 'PricingRulesActions',
    components: {
        AppButton,
        AppInputV3,
        AppMultiselectV3,
        AppJSON,
        PricingRuleActionSelector,
        AppToggle,
    },
    mixins: [validationMixin],
    props: {
        value: {
            // Using RecursivePartial specifically so that PricingRuleAction.adjustment can be partially specified.
            // Otherwise Partial<PricingRuleAction> would suffice.
            type: Array<RecursivePartial<PricingRuleActionPortal>>,
            default: () => [],
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            ICON_TYPES,
            SCOPE_LEVEL,
            TOOLTIP_POSITION,
            showJson: false,
            actionOptions: [
                {
                    id: ACTION_TYPE.APPEND_COMPONENT,
                    label: this.$i18n.t('generic.append'),
                },
                {
                    id: ACTION_TYPE.UPDATE_COMPONENT,
                    label: this.$i18n.t('generic.update'),
                },
            ],
            scopeOptions: [
                {
                    id: SCOPE_LEVEL.LINE_ITEM_LEVEL,
                    label: this.$i18n.t('pricingAndFees.label.scopeLineItem'),
                },
                {
                    id: SCOPE_LEVEL.TOTAL_LEVEL,
                    label: this.$i18n.t('pricingAndFees.label.scopeTotal'),
                },
            ],
            typeOptions: [
                {
                    id: PRICING_COMPONENT_TYPE.ORIGIN,
                    label: this.$i18n.t('pricingAndFees.componentType.origin'),
                },
                {
                    id: PRICING_COMPONENT_TYPE.DISCOUNT,
                    label: this.$i18n.t('pricingAndFees.componentType.discount'),
                },
                {
                    id: PRICING_COMPONENT_TYPE.FEE,
                    label: this.$i18n.t('pricingAndFees.componentType.fee'),
                },
                {
                    id: PRICING_COMPONENT_TYPE.TAX,
                    label: this.$i18n.t('pricingAndFees.componentType.tax'),
                },
                {
                    id: PRICING_COMPONENT_TYPE.PRORATION,
                    label: this.$i18n.t('pricingAndFees.componentType.proration'),
                },
            ],
        };
    },
    computed: {
        pricingRuleActionsDno() {
            return this.value.map(action => convertPricingRuleActionFromPortalToDno(action as PricingRuleActionPortal));
        },
    },
    methods: {
        addAction() {
            this.value.push({
                adjustLevel: undefined,
                actionSelector: undefined,
                adjustment: {
                    expressionType: EXPRESSION_TYPE.EXPRESSION,
                    componentSubtype: 'pricing_rule',
                },
            } as RecursivePartial<PricingRuleActionPortal>);
            this.$emit('input', this.value);
        },
        // Emit any AppJSON changes to parent components
        onJsonChange(json: PricingRuleAction[]) {
            try {
                const res = json.map(action => convertPricingRuleActionFromDnoToPortal(action));
                this.$emit('input', res);
            } catch (error) {
                // do nothing, you don't have valid action data to emit up
            }
        },
        onScopeChange(scope: SCOPE_LEVEL | undefined, index: number) {
            /**
             * Clear selector on scope change.
             * Since the selector is only visible for offer scope, not clearing it
             * can lead to weird side effects.
             */
            this.value[index].actionSelector = undefined;
            this.$emit('input', this.value);
        },
        deleteAction(index: number) {
            this.value.splice(index, 1);
            this.$emit('input', this.value);
        },
        showDeleteActionConfirmationAlert(index: number) {
            // Create delete button
            const button = new Button({
                label: this.$i18n.t('generic.delete'),
                alertType: ALERT_TYPES.warning,
                handler: () => this.deleteAction(index),
            });
            // Deletion Confirmation Alert
            this.$alert(this.$i18n.t('alerts.areYouSureDeleteAction'), {
                type: ALERT_TYPES.warning,
                buttons: [button],
            });
        },
    },
});
