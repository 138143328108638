
import Vue from 'vue';

// Components
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';

// HTTP
import ordersHTTP from '@/__new__/services/dno/orders/http/orders';
import { callbackExecution } from '@/__new__/services/dno/orchestrationengine/http/orchestration-engine';

// Helpers
import { ICON_TYPES } from '@/common/iconHelper';
import { LAYOUT_PAGE_KEYS } from '@/__new__/features/customerCareSuite/common/layoutSectionHelper';
import Actions, { Getters } from '@/store/mutation-types';
import { SUBSCRIBER_STATE, USER_MANAGER_HIERARCHY } from '@/__new__/features/customerCare/common/customerCareHelper';
import * as Sentry from '@sentry/vue';
import permissionsService, {
    BILLING_VERSIONS,
    getBillingVersion,
    isUserAllowed,
    isUserInternal,
    isViewEnabled,
} from '@/services/permissions/permissions.service';
import { mapActions, mapGetters } from 'vuex';
import { ALERT_TYPES } from '@/common/alerts/Alert';
import { ORDER_ENTITY_STATES } from '@/__new__/features/customerCare/common/orderStateHelper';
import supportButtonMixin from '@/components/alerts/supportButtonMixin';
import OePlansMixin from '@/__new__/features/customerCare/mixins/OePlansMixin.vue';
import { Modules } from '@/store/store';

export default Vue.extend({
    name: 'LayoutPage',

    components: {
        AppButton,
    },
    mixins: [supportButtonMixin, OePlansMixin],
    props: {
        userManagerHierarchy: {
            type: Number,
            default: USER_MANAGER_HIERARCHY.ACCOUNT,
        },
    },
    data() {
        return {
            activateServiceLoading: false,

            LAYOUT_PAGE_KEYS,
            BUTTON_TYPES,
            ICON_TYPES,

            userAuthenticationEnabled:
                permissionsService.userManagementUserAuthenticationEnabled() &&
                isViewEnabled('UMUserUserAuthentication'),
            isOrderDetailsEnabled:
                permissionsService.accountOrdersEnabled() && isUserAllowed('UMAccountOrderDetailsRead'),
            isLFAdmin: permissionsService.isUserLotusFlareAdmin() && isUserInternal(),
        };
    },
    computed: {
        ...mapGetters(Modules.customerCareSuite, [Getters.GET_ASSOCIATED_UM_ENTITY_BY_TYPE_AND_ID]),
        ...mapGetters('userManagementUser', ['getUserVerificationStatusById']),
        ...mapGetters('orders', {
            getOrders: Getters.GET_ORDERS,
            getPrimaryOrders: Getters.GET_PRIMARY_ORDERS,
        }),
        entity() {
            return this[Getters.GET_ASSOCIATED_UM_ENTITY_BY_TYPE_AND_ID]({
                targetType: this.userManagerHierarchy,
                targetId: this.$route.params.id,
            });
        },
        orders() {
            return getBillingVersion() === BILLING_VERSIONS.v4 ? this.getOrders : this.getPrimaryOrders;
        },
        isUserVerified() {
            return this.entity?.userId ? this.getUserVerificationStatusById(this.entity.userId) : false;
        },
        isEditAllowed() {
            return !this.userAuthenticationEnabled || this.isUserVerified;
        },
        pendingOrder() {
            return Object.values(this.orders).find(o => o.state === ORDER_ENTITY_STATES.PENDING);
        },
        pendingActivationOrder() {
            return Object.values(this.orders).find(o => o.state === ORDER_ENTITY_STATES.PENDING_ACTIVATION);
        },
        pendingOrderMvneExecutionId() {
            return this.oeMappingsList.find(e => e.planId === 'mvne_provisioning_plan')?.executionId;
        },
        isActivateServiceEnabled() {
            return (
                isUserAllowed('UMAccountActivateService') &&
                permissionsService.userManagerActivateServiceEnabled() &&
                this.entity?.state !== SUBSCRIBER_STATE.ACTIVE &&
                ((this.pendingOrder && this.pendingOrderMvneExecutionId) || this.pendingActivationOrder)
            );
        },
    },
    watch: {
        pendingOrder: {
            async handler() {
                if (this.pendingOrder?.orderId) {
                    await this.fetchExecutions({ mapper_ids: [this.pendingOrder.orderId] });
                }
            },
            deep: true,
        },
    },
    async created() {
        await this.fetchOrderData();
    },
    methods: {
        ...mapActions('orders', [Actions.LOAD_ALL_OEORDERS_ACCOUNT, Actions.LOAD_ALL_ORDERS]),
        async fetchOrderData() {
            try {
                if (this.isOrderDetailsEnabled) {
                    if (this.userManagerHierarchy === USER_MANAGER_HIERARCHY.ACCOUNT) {
                        await this[Actions.LOAD_ALL_OEORDERS_ACCOUNT](this.$route.params.id);
                    } else if (this.userManagerHierarchy === USER_MANAGER_HIERARCHY.SUBSCRIBER) {
                        await this[Actions.LOAD_ALL_ORDERS](this.$route.params.id);
                    }
                }
            } catch (e) {
                Sentry.captureException(e);
                this.$Progress.fail();
                this.showSupportAlert(
                    this.$i18n.t('alertMessage.somethingWentWrongFetchingNecessaryData'),
                    ALERT_TYPES.error,
                );
            }
        },
        async activateService() {
            this.$Progress.start();
            this.activateServiceLoading = true;
            try {
                const targetType = this.userManagerHierarchy;
                const targetId = this.$route.params.id;

                if (this.pendingOrder && this.pendingOrderMvneExecutionId) {
                    const { orderId } = this.pendingOrder;
                    await callbackExecution({ execution_id: this.pendingOrderMvneExecutionId });
                    await new Promise(resolve => setTimeout(resolve, 3000));
                    await ordersHTTP.oeActivateOrder({ targetId, targetType, orderId });
                } else if (this.pendingActivationOrder) {
                    const { orderId } = this.pendingActivationOrder;
                    await ordersHTTP.oeActivateOrder({ targetId, targetType, orderId });
                }
                this.$Progress.finish();
                await this.fetchOrderData();
                this.$showSuccessAlert({
                    message: this.$t('customerCare.account.activateServiceSuccess'),
                });
            } catch (e) {
                Sentry.captureException(e);
                this.$Progress.fail();
                this.$alert(this.$i18n.t('alertMessage.userManagement.orderActivateFailed'));
            } finally {
                this.activateServiceLoading = false;
            }
        },
    },
});
