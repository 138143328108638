/* eslint-disable camelcase */
import { policySpecificationReservedKeys } from '@/__new__/services/dno/template/models/entitiesReservedKeys';
import CommonTemplateEntityBaseModel from '@/__new__/services/dno/template/models/CommonTemplateEntityBaseModel';

class PolicySpecificationModel extends CommonTemplateEntityBaseModel {
    properties;
    type;
    specUpgrade;
    lifecycleStatus;
    validityControl;
    validityDurationUnit;
    validityDurationAmount;
    validityQueryCount;
    dataElement;
    serviceId;
    serviceType;
    legalText;
    validityStartDate;
    validityEndDate;

    constructor(entityJson) {
        super(entityJson);
        this.type = entityJson.data.type;
        // seting template engine properties data here
        this.properties = Object.keys(entityJson.data)
            .filter(key => !policySpecificationReservedKeys.includes(key))
            .reduce((result, key) => {
                result[key] = entityJson.data[key];
                return result;
            }, {});
        this.specUpgrade = entityJson.data.spec_upgrade;
        this.lifecycleStatus = entityJson.data.lifecycle_status;
        this.validityControl = entityJson.data.validity_control;
        this.validityStartDate = entityJson.data.validity_start_date;
        this.validityEndDate = entityJson.data.validity_end_date;
        this.validityDurationUnit = entityJson.data.validity_duration_unit;
        this.validityDurationAmount = entityJson.data.validity_duration_amount;
        this.validityQueryCount = entityJson.data.validity_query_count;
        this.dataElement = entityJson.data.data_element;
        this.serviceId = entityJson.data.service_id;
        this.serviceType = entityJson.data.service_type;
        this.legalText = entityJson.data.legal_text;
    }

    // to parse data for the API call
    static toJson(entityData) {
        const genericDataFromatted = super.toJson(entityData);
        let result = {
            ...genericDataFromatted,
        };
        result.spec_upgrade = entityData.specUpgrade;
        result.lifecycle_status = entityData.lifecycleStatus;
        result.validity_control = entityData.validityControl;
        result.validity_start_date = entityData.validityStartDate;
        result.validity_end_date = entityData.validityEndDate;
        result.validity_duration_unit = entityData.validityDurationUnit;
        result.validity_duration_amount = entityData.validityDurationAmount;
        result.validity_query_count = entityData.validityQueryCount;
        result.data_element = entityData.dataElement;
        result.service_id = entityData.serviceId;
        result.service_type = entityData.serviceType;
        result.legal_text = entityData.legalText;
        return result;
    }
}

export default PolicySpecificationModel;
