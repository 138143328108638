import moment from 'moment';
import i18n from '@/i18n';
import localeLibrary from '@/common/locale/localeLibrary';
import { REPORT_ENGINE } from '@/__new__/services/dno/reports/models/Report';
import type {
    ReportDefinitionResponse,
    ReportRequestParams,
} from '@/__new__/services/dno/reports/common/reportsHelper';

export const SEARCH_OPTIONS = {
    asof: {
        key: 'asof',
        label: 'AS OF',
    },
    between: {
        key: 'between',
        label: 'BETWEEN',
    },
    since: {
        key: 'since',
        label: 'SINCE',
    },
    allTime: {
        key: 'all_time',
        label: 'ALL TIME',
    },
} as const;

export type SEARCH_OPTION = (typeof SEARCH_OPTIONS)[keyof typeof SEARCH_OPTIONS]['key'];

export const DATEPICKER_REPORT_FREQUENCY = {
    DAILY: {
        type: 'date',
    },
    INTRA_DAY: {
        type: 'date',
    },
    MONTHLY: {
        type: 'month',
    },
    WEEKLY: {
        type: 'week',
    },
};

export const REPORT_FREQUENCY_TYPES = {
    DAILY: 'DAILY',
    INTRA_DAY: 'INTRA_DAY',
    MONTHLY: 'MONTHLY',
    WEEKLY: 'WEEKLY',
};

const REPORT_FREQUENCY_LABEL = {
    [REPORT_FREQUENCY_TYPES.DAILY]: i18n.t('analytics.reportsPage.daily'),
    [REPORT_FREQUENCY_TYPES.INTRA_DAY]: i18n.t('analytics.reportsPage.intraDay'),
    [REPORT_FREQUENCY_TYPES.MONTHLY]: i18n.t('analytics.reportsPage.monthly'),
    [REPORT_FREQUENCY_TYPES.WEEKLY]: i18n.t('analytics.reportsPage.weekly'),
};

export const MAP_REPORT_ENGINE_TYPES = {
    ORD: i18n.t('analytics.reportsPage.preqenerated'),
    QRD: i18n.t('analytics.reportsPage.onDemand'),
};

const MAP_REPORT_ENGINE_TYPES_DESC = {
    ORD: i18n.t('analytics.reportsPage.ordDesc'),
    QRD: i18n.t('analytics.reportsPage.qrdDesc'),
};

export enum REPORT_GENERATION_STATUS {
    STARTED_GENERATION = 'STARTED_GENERATION',
    GENERATION_IN_PROGRESS = 'GENERATION_IN_PROGRESS',
    REPORT_GENERATED = 'REPORT_GENERATED',
    AVAILABLE = 'AVAILABLE',
}
export function formatReportDataForOverview(report: ReportDefinitionResponse) {
    return [
        {
            name: i18n.t('generic.general'),
            rows: [
                {
                    name: i18n.t('generic.id'),
                    value: report.definitionId,
                },
                {
                    name: i18n.t('generic.description'),
                    value: report.reportDescription ? report.reportDescription : i18n.t('generic.N/A'),
                },
                {
                    name: i18n.t('generic.type'),
                    value: `${MAP_REPORT_ENGINE_TYPES[report.reportEngine]} - ${
                        MAP_REPORT_ENGINE_TYPES_DESC[report.reportEngine]
                    }`,
                },
                {
                    name: i18n.t('generic.frequency'),
                    value: REPORT_FREQUENCY_LABEL[report.reportFrequency],
                },
            ],
        },
    ];
}

/**
 * Used to apply current time to `endDate` since it's time is 00:00
 * and when timezone transformation(moment.utc) is applied,
 * it will return invalid time offseted by a day.
 */
const addCurrentTime = (date: Date): Date => {
    const now = new Date();
    // (minutes) * 60s * 1000ms
    const currentTimeMillis = (now.getHours() * 60 + now.getMinutes()) * 60000;

    return new Date(date.valueOf() + currentTimeMillis);
};

/**
 *  Date to specifc format before sending to API.
 */
export function formatDate(date: Date, format = 'YYYY-MM-DD'): string {
    return moment(date).format(format);
}

/**
 * Format to UTC
 */
const formatEndDate = (endDate: Date, restrictDateFrom: Date): Date => {
    const date = addCurrentTime(endDate);
    return restrictDateFrom < date ? moment(date).utc().toDate() : date;
};

export interface BuiltReportRequestParams extends ReportRequestParams {
    reportEngine: REPORT_ENGINE;
}
export function buildRequestFromReports(
    timeframeType: SEARCH_OPTION,
    { reportEngine, definitionId, reportFrequency }: ReportDefinitionResponse,
    { dateValue, endDateValue, restrictReportDates }: ReturnType<typeof getDatePickerOptions>,
    timezone?: string,
): BuiltReportRequestParams {
    let startDate, endDate;

    if (timeframeType === SEARCH_OPTIONS.asof.key) {
        startDate = dateValue;
        endDate = dateValue;
    }
    if (timeframeType === SEARCH_OPTIONS.since.key) {
        startDate = dateValue;
        endDate = moment().toDate();
    }
    if (timeframeType === SEARCH_OPTIONS.allTime.key) {
        startDate = endDateValue;
        endDate = dateValue;
    }
    if (timeframeType === SEARCH_OPTIONS.between.key) {
        startDate = dateValue;
        endDate = endDateValue;
    }

    if (reportFrequency === REPORT_FREQUENCY_TYPES.MONTHLY) {
        if (timeframeType === SEARCH_OPTIONS.asof.key) {
            startDate = moment(dateValue).startOf('month');
            endDate = moment(dateValue).endOf('month');
        }
        if (timeframeType === SEARCH_OPTIONS.since.key) {
            startDate = moment(dateValue).startOf('month');
            endDate = moment().endOf('month');
        }
        if (timeframeType === SEARCH_OPTIONS.between.key) {
            startDate = moment(dateValue).startOf('month');
            endDate = moment(endDateValue).endOf('month');
        }
    }

    startDate = startDate ? formatDate(startDate as Date) : '';
    endDate = endDate ? formatDate(formatEndDate(endDate as Date, restrictReportDates.from)) : '';

    return {
        reportEngine,
        definitionId,
        timezone,
        startDate,
        endDate,
    };
}

export function getReportTimeframeOptions({ reportEngine, rangeLimitInDays }: ReportDefinitionResponse) {
    if (reportEngine === REPORT_ENGINE.ORD || rangeLimitInDays !== -1) {
        return {
            asof: SEARCH_OPTIONS.asof,
            between: SEARCH_OPTIONS.between,
            since: SEARCH_OPTIONS.since,
        };
    }

    return SEARCH_OPTIONS;
}

export function getDatePickerOptions(
    timeframeType: SEARCH_OPTION | null,
    { reportEngine, reportFrequency, rangeLimitInDays }: ReportDefinitionResponse,
) {
    const defaultOptions = {
        // @todo Should this be undefined by default ???
        dateValue: '' as Date | string,
        endDateValue: '' as Date | string,
        restrictReportDates: {
            from: moment().toDate(),
            to:
                rangeLimitInDays && rangeLimitInDays !== -1
                    ? moment().subtract(rangeLimitInDays, 'days').toDate()
                    : null,
        },
        disabled: false,
        isRangePicker: false,
        type: DATEPICKER_REPORT_FREQUENCY[reportFrequency].type,
        rangeLimitInDays,
    };

    // @todo Is this even valid ?
    if (timeframeType === null) {
        return defaultOptions;
    }

    if (timeframeType === SEARCH_OPTIONS.between.key) {
        defaultOptions.isRangePicker = true;
    }

    if (timeframeType === SEARCH_OPTIONS.allTime.key && reportEngine === REPORT_ENGINE.QRD) {
        defaultOptions.disabled = true;
        defaultOptions.dateValue = moment().toDate();
        defaultOptions.endDateValue = moment('1970-01-01').toDate();
    }

    if (reportFrequency === REPORT_FREQUENCY_TYPES.MONTHLY) {
        defaultOptions.restrictReportDates.from = moment().toDate();
    }

    if (reportEngine === REPORT_ENGINE.QRD) {
        if (rangeLimitInDays === -1 && timeframeType === SEARCH_OPTIONS.allTime.key) {
            defaultOptions.disabled = true;
        }
    }

    const { from } = defaultOptions.restrictReportDates;
    defaultOptions.restrictReportDates.from = normalizeDateToPortalTimezone(from);

    return defaultOptions;
}

export function normalizeDateToPortalTimezone(date: Date): Date {
    if (date && date instanceof Date) {
        const browserOffset = date.getTimezoneOffset() * 60000;
        const portalOffset = localeLibrary.userConfiguredTimezoneOffset(date) * 60000;

        if (date.getHours() + browserOffset + portalOffset >= 24) {
            return new Date(date.getTime());
        }

        return new Date(date.getTime() + browserOffset + portalOffset);
    }

    return date;
}

export default {};
