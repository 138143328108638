import http, { DnoResponseBase } from '@/http';
import { AxiosPromise } from 'axios';
import type {
    RawValidationRule,
    ServerValidationRule,
    ValidationRule,
} from '@/__new__/services/dno/pc/models/ValidationRule';
import { ValidationRuleState } from '@/__new__/features/pc/validationRules/common/validationRule';

interface ValidationRuleResponse extends DnoResponseBase {
    timestamp: number;
    validation_rule_by_id: Record<string, RawValidationRule>;
}

export async function getValidationRules(): AxiosPromise<ValidationRuleResponse> {
    return http.post('/v3productcatalog/getvalidationrules');
}

export async function fetchOneValidationRule(id: string): AxiosPromise<ValidationRuleResponse> {
    return http.post('/v3productcatalog/getvalidationrules', { ids: [id] });
}

export function addValidationRule(rule: ServerValidationRule): AxiosPromise<DnoResponseBase> {
    return http.post('/v3productcatalogwrite/addvalidationrule', rule);
}

export function updateValidationRule(rule: ServerValidationRule): AxiosPromise<DnoResponseBase> {
    return http.post('/v3productcatalogwrite/updatevalidationrule', rule);
}

export function updateValidationRuleState(
    { id, version }: ValidationRule,
    state: ValidationRuleState,
): AxiosPromise<DnoResponseBase> {
    return http.post('/v3productcatalogwrite/updatevalidationrulestate', {
        id,
        version,
        state,
    });
}

export function pauseValidationRule(rule: ValidationRule): AxiosPromise<DnoResponseBase> {
    return updateValidationRuleState(rule, ValidationRuleState.PAUSED);
}

export function approveValidationRule(rule: ValidationRule): AxiosPromise<DnoResponseBase> {
    return updateValidationRuleState(rule, ValidationRuleState.APPROVED);
}

export function deleteValidationRule(rule: ValidationRule): AxiosPromise<DnoResponseBase> {
    return updateValidationRuleState(rule, ValidationRuleState.DELETED);
}

export const ValidationRulesService = {
    get: getValidationRules,
    fetchOne: fetchOneValidationRule,
    add: addValidationRule,
    update: updateValidationRule,
    updateState: updateValidationRuleState,
    delete: deleteValidationRule,
    pause: pauseValidationRule,
    approve: approveValidationRule,
};
