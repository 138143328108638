
import Vue, { PropType } from 'vue';
import AppDialogV2 from '@/components/partials/AppDialogV2.vue';
import AbstractTableTile from '@/__new__/features/customerCareSuite/components/AbstractTableTile.vue';
import { startCase } from 'lodash';
import tableColumnType from '@/common/filterTable';

export default Vue.extend({
    name: 'CharterDemoViewBillModal',
    components: { AbstractTableTile, AppDialogV2 },
    props: {
        bill: {
            type: Object as PropType<any>,
            default: () => ({}),
        },
        visible: {
            type: Boolean,
            required: true,
        },
    },
    data() {
        return {
            columnsData: [
                {
                    name: this.$t('charterDemo.productName'),
                    key: 'productName',
                    field: 'productName',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$t('charterDemo.chargeType'),
                    key: 'chargeType',
                    field: 'chargeType',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$t('charterDemo.subTotal'),
                    key: 'subTotal',
                    field: 'subTotal',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$t('charterDemo.taxes'),
                    key: 'taxes',
                    field: 'taxes',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$t('charterDemo.total'),
                    key: 'total',
                    field: 'total',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
            ],
        };
    },
    computed: {
        tableEntries() {
            const billItems = this.bill?.customerBillItems ?? [];

            // Assume that the currency is consistent across monetary values in the bill
            const currency = this.bill?.amountDue?.unit || '';

            // Transform each of the bill items to be used by Portal:
            const entries = billItems.map(item => {
                const total = item.taxIncludedAmount?.value ?? 0;
                const subTotal = item.taxExcludedAmount?.value ?? 0;
                const taxes = total - subTotal;
                return {
                    productName: item.product?.name ?? 'Unknown',
                    chargeType: startCase(item.customerBillItemType) ?? 'Unknown',
                    subTotal: `${Number(subTotal).toFixed(2)} ${currency}`,
                    taxes: `${Number(taxes).toFixed(2)} ${currency}`,
                    total: `${Number(total).toFixed(2)} ${currency}`,
                };
            });

            // Find sums for each of our charge types (eg recurring / one time)
            const billItemTypeSums: Record<string, any> = {};
            billItems.forEach(item => {
                const type = item.customerBillItemType;
                billItemTypeSums[type] = billItemTypeSums[type] || {
                    total: 0,
                    subTotal: 0,
                    taxes: 0,
                };

                const total = item.taxIncludedAmount?.value || 0;
                const subTotal = item.taxExcludedAmount?.value || 0;
                const taxes = total - subTotal;

                billItemTypeSums[type].total += total;
                billItemTypeSums[type].subTotal += subTotal;
                billItemTypeSums[type].taxes += taxes;
            });

            // Add totals to our summary (last entries)
            const chargeTypesToShowInSummary = [
                'appliedBillingChargeProductOneTimeCharge',
                'appliedBillingChargeProductRecurringCharge',
            ];
            chargeTypesToShowInSummary.forEach(chargeType => {
                const sums = billItemTypeSums[chargeType];
                entries.push({
                    productName: this.$t(`charterDemo.chargeTypeToTotalString.${chargeType}`),
                    chargeType: '-',
                    total: sums ? `${Number(sums.total).toFixed(2)} ${currency}` : `0.00 ${currency}`,
                    subTotal: sums ? `${Number(sums.subTotal).toFixed(2)} ${currency}` : `0.00 ${currency}`,
                    taxes: sums ? `${Number(sums.taxes).toFixed(2)} ${currency}` : `0.00 ${currency}`,
                });
            });
            return entries;
        },
    },
});
