<template>
    <div class="billing-statement-container">
        <TableFiltersRenderless :entities="billingStatementCyclesForDisplay">
            <template
                #default="{
                    // state
                    allFilters,
                    filteredEntities,
                    // methods
                    onFilterAdded,
                    removeFilter,
                    removeAllFilters,
                }"
            >
                <div>
                    <div class="row mb-2 align-items-center table-header-row justify-content-between">
                        <div class="component-title-um">
                            {{ $i18n.t('customerCare.billingStatements') }}
                        </div>
                        <div class="display-flex">
                            <ResponseModalButton
                                :response="billingCyclesApiResponse"
                                :title="$i18n.t('customerCare.billingStatements')"
                            />
                            <div class="filters-control position-relative">
                                <FilterTable
                                    :columns="billingStatementCyclesColumnsData"
                                    :multiselectWidth="{ width: '12rem' }"
                                    @filterAdded="onFilterAdded"
                                >
                                    <template #button="slotProps">
                                        <IconButton
                                            :label="$i18n.t('generic.filter')"
                                            :icon="ICON_TYPES.FILTER"
                                            @iconClick="slotProps.clickHandler"
                                        />
                                    </template>
                                </FilterTable>
                            </div>
                        </div>
                    </div>

                    <TableFiltersTags
                        :filterTableMixin="{ allFilters }"
                        class="mt-1 mb-3"
                        @removeFilter="removeFilter"
                        @removeAllFilters="removeAllFilters"
                    />

                    <AppTable
                        :entities="filteredEntities"
                        :isPaginationEnabled="true"
                        :isDefaultHoverEnabled="false"
                        :isSearchEnabled="true"
                        :canSelectColumns="true"
                        :innerSearchQuery="searchQuery"
                        :isDataLoading="isDataLoading"
                        :newDesign="true"
                        :columnsData="billingStatementCyclesColumnsData"
                        :tableTabs="true"
                        tableKey="user/transaction-usage"
                        class="mb-3 transaction-usage-table"
                    >
                        <template #customRowButtons="{ entity }">
                            <IconButton
                                :label="$i18n.t('generic.generateInvoice')"
                                :icon="ICON_TYPES.PLUS"
                                @iconClick="onGenerateInvoiceButtonClicked(entity)"
                            />
                            <IconButton
                                :label="$i18n.t('generic.download')"
                                :icon="ICON_TYPES.DOWNLOAD"
                                class="mr-5"
                                @iconClick="onDownloadButtonClicked(entity)"
                            />
                        </template>
                    </AppTable>
                </div>
            </template>
        </TableFiltersRenderless>
    </div>
</template>

<script>
// components
import AppTable from '@/components/partials/AppTable.vue';
import IconButton from '@/components/partials/IconButton.vue';
import { ALERT_TYPES } from '@/common/alerts/Alert';
import { ICON_TYPES } from '@/common/iconHelper';
import BillingStatementCycle from '@/__new__/services/dno/orders/models/BillingStatementCycle';
import TableFiltersRenderless from '@/components/filters/TableFiltersRenderless.vue';
import TableFiltersTags from '@/components/filters/TableFiltersTags.vue';
import FilterTable from '@/components/partials/FilterTable.vue';
import Button from '@/common/button/Button';
import ResponseModalButton from '@/components/partials/ResponseModalButton.vue';
// http
import ordersAPI from '@/__new__/services/dno/orders/http/orders';
import customerCareHTTP from '@/__new__/services/dno/user/http/customer-care';

import permissionsService from '@/services/permissions/permissions.service';
// helpers
import * as Sentry from '@sentry/vue';
import sortBy from 'lodash/sortBy';
import tableColumnType from '@/common/filterTable';
import luaErrors from '@/common/luaErrors';

export default {
    name: 'BillingStatements',
    components: {
        AppTable,
        IconButton,
        ResponseModalButton,
        TableFiltersRenderless,
        TableFiltersTags,
        FilterTable,
    },
    props: {
        targetUser: {
            required: true,
            type: Object,
        },
    },
    data() {
        return {
            ICON_TYPES,
            isDataLoading: true,
            searchQuery: '',
            billingCyclesApiResponse: {},
            billingCycles: [],
            billingStatementCyclesColumnsData: [
                {
                    name: this.$i18n.t('generic.from'),
                    key: 'startLabel',
                    sortBy: cycle => cycle.start,
                    field: 'start',
                    filterType: tableColumnType.DATE,
                },
                {
                    name: this.$i18n.t('generic.to'),
                    key: 'endLabel',
                    sortBy: cycle => cycle.end,
                    field: 'end',
                    filterType: tableColumnType.DATE,
                },
            ],
            alertButtons: {
                downloadButton: new Button({
                    label: this.$i18n.t('generic.download'),
                    alertType: ALERT_TYPES.warning,
                }),
            },
            permissionsService,
        };
    },
    computed: {
        billingStatementCyclesForDisplay() {
            const sortedBillingCyles = sortBy(this.billingCycles, ['start', 'end']);
            const filteredBillingCycles = sortedBillingCyles.filter(
                billingCycle =>
                    ![200, 201, 400, 600, 601].includes(billingCycle?.state) &&
                    billingCycle?.isRefund !== true &&
                    billingCycle?.start,
            );

            return filteredBillingCycles.map(bc => ({
                ...bc,
                startLabel: this.$localeLibrary.getFormattedDate(bc.start),
                endLabel: this.$localeLibrary.getFormattedDate(bc.end),
            }));
        },
    },
    async created() {
        await this.fetchBillingCycles();
    },
    methods: {
        async fetchBillingCycles() {
            await this.$withProgressBar(
                async () => {
                    const { id, type } = this.targetUser;
                    this.isDataLoading = true;

                    const res = await ordersAPI.getTransactions(id, type);

                    this.billingCyclesApiResponse = res;
                    this.billingCycles = BillingStatementCycle.remapTransactionsFromBe(res?.data?.transactions || []);
                    this.isDataLoading = false;
                },
                {
                    errorHandler: e => {
                        this.billingCyclesApiResponse = e.response;
                        this.$alert(this.$i18n.t('customerCare.billingStatementsFetchError'));
                        this.isDataLoading = false;
                    },
                },
            );
        },
        async onDownloadButtonClicked({ id }) {
            try {
                this.$Progress.start();

                const {
                    data: { pdf_link: pdfLink = null },
                } = await customerCareHTTP.downloadInvoice(this.targetUser.id, this.targetUser.type, id);

                if (pdfLink) {
                    window.open(pdfLink, '_blank');
                }

                this.$Progress.finish();
            } catch (error) {
                if (error.response?.data.code === luaErrors.BILLING_V4.FILE_NOT_FOUND_ON_S3.code) {
                    this.$eventBus.$emit('showAlert', {
                        message: this.$i18n.t('customerCare.billingStatementDownloadNotFoundInvoice'),
                        type: ALERT_TYPES.warning,
                    });
                } else {
                    this.$eventBus.$emit('showAlert', {
                        message: this.$i18n.t('customerCare.billingStatementDownloadError'),
                    });
                    Sentry.captureException(error);
                }
                this.$Progress.fail();
            }
        },
        async onGenerateInvoiceButtonClicked({ id }) {
            await this.$withProgressBar(
                async () => {
                    await customerCareHTTP.generateFile(this.targetUser.id, this.targetUser.type, id);

                    this.$eventBus.$emit('showAlert', {
                        message: this.$i18n.t('customerCare.generateInvoiceSuccessMessage'),
                        type: ALERT_TYPES.success,
                    });
                },
                {
                    errorHandler: () => {
                        this.$alert(this.$i18n.t('customerCare.generateInvoiceFailedMessage'));
                    },
                },
            );
        },
        showConfirmationAlert(billingCycle) {
            this.$eventBus.$emit('showAlert', {
                message: this.$i18n.t('customerCare.billingStatementProcessingMessage'),
                type: this.$ALERT_TYPES.warning,
                buttons: [this.alertButtons.downloadButton],
            });
            this.$eventBus.$once('buttonClicked', () => {
                this.onDownloadButtonClicked(billingCycle);
            });
        },
    },
};
</script>
