export enum REPORT_TABLE_ACTION {
    FETCH = 'Fetch',
    REGENERATE = 'Regenerate',
    REPLACE = 'Replace',
    PUBLISH = 'Publish',
    PREVIEW = 'Preview',
    // QRD only
    FETCH_ARCHIVE = 'FetchArchive',
}

export enum REPORT_ENGINE {
    ORD = 'ORD',
    QRD = 'QRD',
}

export const SEARCH_OPTIONS_TYPED = {
    asof: {
        key: 'asof',
        label: 'AS OF',
    },
    between: {
        key: 'between',
        label: 'BETWEEN',
    },
    since: {
        key: 'since',
        label: 'SINCE',
    },
    allTime: {
        key: 'all_time',
        label: 'ALL TIME',
    },
} as const;

export default class Report {
    id: string;
    url: string;
    definitionId: string;
    reportName: string;
    actions: REPORT_TABLE_ACTION[];
    startTime: number;
    endTime: number;
    size: number;
    reportEngine: REPORT_ENGINE;
    reportEntryId: string;
    fileEntryId: string;
    fileName: string;

    constructor(data: Report) {
        this.id = data.id;
        this.url = data.url;
        this.definitionId = data.definitionId;
        this.reportName = data.reportName;
        this.actions = data.actions;
        this.startTime = data.startTime;
        this.endTime = data.endTime;
        this.size = data.size;
        this.reportEngine = data.reportEngine;
        this.reportEntryId = data.reportEntryId;
        this.fileEntryId = data.fileEntryId;
        this.fileName = data.fileName;
    }
}
