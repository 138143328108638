//
import UIComponentDefinition from '@/models/filters/uiComponents/UIComponentDefinition';
import { SelectableDefinitionsFactory, SelectableParam } from './SelectableDefinition';
import { StringOperations } from '@/common/filters/InputOperations';

export default class DropdownDefinition
    // $FlowFixMe
    extends UIComponentDefinition
{
    selectables = [];

    constructor(dropdownDefinition) {
        super();
        this.selectables = SelectableDefinitionsFactory(dropdownDefinition.values || []);
    }

    defaultValue() {
        return {
            op: StringOperations.Equals,
            ...this.selectables[0],
        };
    }

    valueFromJson(jsonValue) {
        if (jsonValue && jsonValue.value && jsonValue.value.value != null) {
            const selectableFromJson =
                this.selectables.find(selectable => jsonValue && selectable.isValueOfThisSelectable(jsonValue.value)) ||
                // construct selectable from raw value because
                // it may be not present in fetched selectables but be self-defined (contain label, etc)
                new SelectableParam(jsonValue.value);

            return {
                ...selectableFromJson,
                op: Object.keys(jsonValue.value.op)[0],
            };
        }

        return null;
    }

    buildJson(rawValue) {
        let value = rawValue && rawValue.value;
        // maybe conversion to boolean
        if (value === 'true') {
            value = true;
        } else if (value === 'false') {
            value = false;
        }

        // find selected value definition to retrieve label
        const selectedEntity = this.selectables.find(s => s.value === value);

        return {
            label: (selectedEntity && selectedEntity.label) || (rawValue && rawValue.label),
            value,
            op: {
                [rawValue.op]: {},
            },
        };
    }
}
