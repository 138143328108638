<template>
    <AbstractListPageWrapper
        :pageTitle="$i18n.t('segments.segments')"
        :isOverviewEnabled="isOverviewEnabled"
        :entitiesCount="filteredEntities.length"
        @searchQueryChanged="setSearchQuery"
    >
        <template slot="button">
            <div class="d-flex">
                <ResponseModalButton :response="rawEntitiesData" />
                <ImportEntitiesModalButton
                    :entityType="ENTITY_TYPES.SEGMENT"
                    @finishImport="fetchSegments"
                />
            </div>
        </template>

        <template slot="headerButtons">
            <AppButtonDropdown
                :iconType="ICON_TYPES.PLUS"
                :items="addNewSegmentButtonGenerationTypes"
                :label="$i18n.t('generic.addNew')"
                data-test="add-button"
                @selected="item => navigateToAddNewSegment(item.value)"
            />
        </template>

        <template slot="table">
            <AppTable
                data-test="segments-table"
                :entities="filteredEntities"
                :enableRowStateControls="true"
                :entityType="ENTITY_TYPES.SEGMENT"
                :selectedEntityId="selectedSegmId"
                :isSearchEnabled="true"
                :defaultSort="defaultSort"
                :columnsData="columnsData"
                :canSelectColumns="true"
                :innerSearchQuery="searchQueryForTable"
                :isDataLoading="isDataLoading"
                @selectEntity="selectSegment"
                @stop="handleStop"
                @start="handleStart"
                @edit="goToEditPage"
                @delete="handleDelete"
                @details="id => $refs.DetailsJsonModal.display(segmentsById[id])"
                @download="handleDownload"
            >
                <template #operations="{ entity }">
                    <EntityStatusIndicator
                        :status="entity.operation"
                        :stateMap="statusesOperation"
                        :stateMapColor="STATUS_OPERATIONS_INDICATOR_MAP"
                    />
                </template>

                <template #state="{ entity }">
                    <EntityStatusIndicator :status="entity.state" />
                </template>
            </AppTable>
            <DetailsJsonModal ref="DetailsJsonModal" />
        </template>
        <template slot="overview">
            <SegmentOverview
                :segment="selectedEntity"
                :stats="selectedEntityStats"
                :conditionDefinitionsById="conditionDefinitionsForSelectedSegment"
                @closeOverview="isOverviewEnabled = false"
            />
        </template>
    </AbstractListPageWrapper>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import { isEmpty, isNumber, isObject } from 'lodash';
import RouteNames from '@/router/routeNames';
import store, { Modules } from '@/store/store';
import { ICON_TYPES } from '@/common/iconHelper';
import SegmentOverview from '@/__new__/features/segments/SegmentOverview.vue';
import Segment, { mapSegmentOperationToEntityActions } from '@/__new__/services/dno/segments/models/Segment';
import { GENERATION_TYPES_BY_KEYS, GENERATION_TYPES_BY_VALUE } from '@/common/segments';
import { generationTypeLabelMap } from '@/__new__/features/segments/common/segmentHelper';
import Actions, { State, Getters } from '@/store/mutation-types';
import * as Sentry from '@sentry/vue';
import AbstractListPageWrapper from '@/components/layout/AbstractListPageWrapper.vue';
import AppTable from '@/components/partials/AppTable.vue';
import ENTITY_TYPES from '@/common/entities/entityTypes';
import tableColumnType from '@/common/filterTable';
import FilterTableMixin from '@/components/partials/FilterTableMixin.vue';
import { ALERT_TYPES } from '@/common/alerts/Alert';
import Button from '@/common/button/Button';
import AppButtonDropdown from '@/components/partials/inputs/AppButtonDropdown.vue';
import permissionsService, { isUserAllowed } from '@/services/permissions/permissions.service';
import { SEGMENT_ID_TYPES_REVERSE } from '@/common/StaticFilter';
import DetailsJsonModal from '@/components/partials/DetailsJsonModal.vue';
import ImportEntitiesModalButton from '@/components/partials/ImportEntitiesModalButton.vue';
import ResponseModalButton from '@/components/partials/ResponseModalButton.vue';
import { STATUS_OPERATIONS_INDICATOR_MAP, STATUS_CODES_OPERATIONS, USER_MODES } from '@/common/commonHelper';
import EntityStatusIndicator from '@/components/partials/EntityStatusIndicator.vue';

const { mapActions, mapState, mapGetters } = createNamespacedHelpers(Modules.segments);
const triggerHelpers = createNamespacedHelpers(Modules.triggers);
const staticFiltersHelpers = createNamespacedHelpers(Modules.staticFilters);

export default {
    name: 'Segments',
    components: {
        DetailsJsonModal,
        SegmentOverview,
        AbstractListPageWrapper,
        AppTable,
        AppButtonDropdown,
        ImportEntitiesModalButton,
        ResponseModalButton,
        EntityStatusIndicator,
    },
    mixins: [FilterTableMixin],
    data() {
        return {
            isDataLoading: false,
            isCsvDownloading: false,
            ENTITY_TYPES,
            ICON_TYPES,
            selectedSegmId: null,
            searchQueryForTable: '',
            isOverviewEnabled: false,
            STATUS_OPERATIONS_INDICATOR_MAP,
        };
    },
    computed: {
        ...mapState([
            State.SEGMENTS_STATISTICS,
            State.CACHED_SEGMENTS,
            State.TOTAL_USERS,
            State.FILTER_DEFINITIONS_BY_ID,
            State.RAW_SEGMENTS,
        ]),
        ...staticFiltersHelpers.mapState({
            usersCount: State.STATIC_FILTERS_COUNT_STATS,
        }),
        ...mapGetters({
            segments: Getters.CACHED_SEGMENTS,
            segmentsById: Getters.CACHED_SEGMENTS_BY_ID,
        }),
        ...mapGetters([Getters.GET_STATS_OF_SEGMENT]),
        ...triggerHelpers.mapState([State.TRIGGER_DEFINITIONS_BY_ID]),
        defaultSort() {
            return {
                sortBy: entity => entity.creationDate,
                type: 'desc',
            };
        },
        statusesOperation() {
            return new Map([
                [STATUS_CODES_OPERATIONS.NA, this.$i18n.t('generic.N/A')],
                [STATUS_CODES_OPERATIONS.PENDING, this.$i18n.t('generic.stateMap.generating')],
                [STATUS_CODES_OPERATIONS.RUNNING, this.$i18n.t('generic.stateMap.running')],
                [STATUS_CODES_OPERATIONS.STOPPED, this.$i18n.t('generic.stateMap.stopped')],
            ]);
        },
        userMode() {
            return store.getters[Getters.GET_MODE];
        },
        columnsData() {
            const columns = [
                {
                    name: this.$i18n.t('generic.name'),
                    key: 'name',
                    field: 'name',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('generic.type'),
                    key: 'generationType',
                    field: 'generationType',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('generic.createdBy'),
                    key: 'createdBy',
                    field: 'createdBy',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('generic.idType'),
                    key: 'segmentIdTypeLabel',
                    field: 'segmentIdTypeLabel',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
            ];

            if (this.userMode === USER_MODES.EXPERIMENTAL) {
                columns.push(
                    {
                        name: this.$i18n.t('generic.publishedState'),
                        key: 'state',
                        field: 'stateLabel',
                        filterType: tableColumnType.TEXT_LIMITED_OPTIONS,
                    },
                    {
                        name: this.$i18n.t('operations.operations'),
                        key: 'operations',
                        field: 'operationsLabel',
                        additionalFormatter: entity => this.statusesOperation.get(entity.operation),
                        filterType: tableColumnType.TEXT_LIMITED_OPTIONS,
                    },
                    {
                        name: this.$i18n.t('segments.generatedTime'),
                        key: 'latestSnapshotUpdateTimestampFormatted',
                        field: 'latestSnapshotUpdateTimestampFormatted',
                        filterType: tableColumnType.DATE,
                        sortBy: entity => entity.updateTime,
                    },
                );
            }

            return columns;
        },
        rawEntitiesData() {
            return this[State.RAW_SEGMENTS];
        },

        conditionDefinitionsForSelectedSegment() {
            if (this.selectedEntity.generationType === generationTypeLabelMap(GENERATION_TYPES_BY_KEYS.DYNAMIC)) {
                return this.filterConditionDefinitionsById;
            }

            if (this.selectedEntity.generationType === generationTypeLabelMap(GENERATION_TYPES_BY_KEYS.TRIGGER_BASED)) {
                return this.triggerConditionDefinitionsById;
            }

            return {};
        },
        filterConditionDefinitionsById() {
            return this[State.FILTER_DEFINITIONS_BY_ID];
        },
        triggerConditionDefinitionsById() {
            return this[State.TRIGGER_DEFINITIONS_BY_ID];
        },
        filteredEntities() {
            const formatterColumns = this.columnsData.filter(({ additionalFormatter }) => additionalFormatter);
            return this.filteredEntitiesMixin(
                this.segments.map(segment => {
                    formatterColumns.forEach(col => {
                        segment[col.field] = col.additionalFormatter(segment);
                    });
                    return {
                        ...segment,
                        allowedActionsExternal: mapSegmentOperationToEntityActions(segment, this.userMode),
                    };
                }),
            );
        },
        selectedEntityStats() {
            if (this.selectedEntity.segmentType !== GENERATION_TYPES_BY_KEYS.STATIC_FILTER) {
                const stats = this[Getters.GET_STATS_OF_SEGMENT](this.selectedSegmId);
                return stats.filtered ?? '...';
            }
            return this.usersCount[this.selectedSegmId] ?? '...';
        },
        selectedEntity() {
            return this.segmentsById[this.selectedSegmId] || Segment.empty();
        },
        addNewSegmentButtonGenerationTypes() {
            const arrTypes = [
                {
                    label: generationTypeLabelMap(GENERATION_TYPES_BY_KEYS.DYNAMIC),
                    value: GENERATION_TYPES_BY_KEYS.DYNAMIC,
                    permission: permissionsService.dynamicSegmentsEnabled() && isUserAllowed('DynamicSegmentsWrite'),
                },
                {
                    label: generationTypeLabelMap(GENERATION_TYPES_BY_KEYS.TRIGGER_BASED),
                    value: GENERATION_TYPES_BY_KEYS.TRIGGER_BASED,
                    permission:
                        permissionsService.realTimeSegmentsEnabled() && isUserAllowed('TriggerBasedSegmentsWrite'),
                },
                {
                    label: generationTypeLabelMap(GENERATION_TYPES_BY_KEYS.STATIC_FILTER),
                    value: GENERATION_TYPES_BY_KEYS.STATIC_FILTER,
                    permission: permissionsService.userGroupsEnabled() && isUserAllowed('StaticSegmentsWrite'),
                },
            ];

            return arrTypes.filter(type => type.permission);
        },
    },
    created() {
        this.fetchSegments().then(() => {
            if (this.$route.params?.id) {
                this.selectSegment(this.$route.params.id);
            }
        });
    },
    methods: {
        ...mapActions([
            Actions.LOAD_FILTER_DEFINITIONS,
            Actions.FETCH_SEGMENT_STATISTICS,
            Actions.FETCH_SEGMENTS,
            Actions.DELETE_SEGMENT,
            Actions.DOWNLOAD_SEGMENT_DATA,
        ]),
        ...triggerHelpers.mapActions([Actions.LOAD_TRIGGER_DEFINITIONS]),
        ...staticFiltersHelpers.mapActions([Actions.FETCH_STATIC_FILTER_COUNT_STATS]),
        fetchFilterStats(filterId) {
            const filterType = SEGMENT_ID_TYPES_REVERSE[this.selectedEntity.segmentIdType];
            try {
                this[Actions.FETCH_STATIC_FILTER_COUNT_STATS]({
                    filterId,
                    filterType,
                });
            } catch (err) {
                Sentry.captureException(err);
                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('alertMessage.cep.failedToLoadStaticFilterCount'),
                });
            }
        },
        handleFetchError(error) {
            this.isDataLoading = false;
            this.$Progress.fail();
            Sentry.captureException(error);
            this.$eventBus.$emit('showAlert', {
                message: this.$i18n.t('alertMessage.failedToLoadNecessaryData'),
            });
        },

        async fetchSegments() {
            try {
                this.isDataLoading = true;
                this.$Progress.start();
                await Promise.all([this[Actions.LOAD_FILTER_DEFINITIONS](), this[Actions.LOAD_TRIGGER_DEFINITIONS]()]);
                await this[Actions.FETCH_SEGMENTS]();

                this.isDataLoading = false;
                this.$Progress.finish();
            } catch (error) {
                this.handleFetchError(error);
            }
        },
        navigateToAddNewSegment(selectedGenerationType) {
            if (selectedGenerationType === GENERATION_TYPES_BY_KEYS.STATIC_FILTER) {
                this.$router.push({
                    name: RouteNames.SEGMENTS_STATIC_FILTERS_ADD,
                    params: { companyId: this.$route.params.companyId },
                });
                return;
            }
            const generationType = selectedGenerationType ?? this.addNewSegmentButtonGenerationTypes[0].value;
            this.$router.push({
                name: RouteNames.SEGMENTS_ADD,
                params: {
                    generationType: GENERATION_TYPES_BY_VALUE[generationType].toLowerCase(),
                    companyId: this.$route.params.companyId,
                },
            });
        },

        selectSegment(segmentId) {
            if (segmentId !== this.selectedSegmId) {
                this.selectedSegmId = segmentId;
                if (this.selectedEntity.segmentType === GENERATION_TYPES_BY_KEYS.STATIC_FILTER) {
                    this.fetchFilterStats(segmentId);
                } else {
                    this.preapplySegment();
                }
            }
            this.isOverviewEnabled = true;
        },

        async preapplySegment() {
            if (!this.selectedEntity.id) return;

            const { id: segmentId, segmentIdType, name } = this.selectedEntity;

            try {
                await this[Actions.FETCH_SEGMENT_STATISTICS]({ segmentId, segmentIdType });
            } catch (error) {
                Sentry.captureException(error);
                this.$alert(this.$t('alertMessage.cep.errorFetchingSegmentStatistics', { name }));
            }
        },
        setSearchQuery(query) {
            this.searchQueryForTable = query;
        },

        goToEditPage(id) {
            const entityToEdit = this.segmentsById[id];
            const page =
                entityToEdit?.segmentType === GENERATION_TYPES_BY_KEYS.STATIC_FILTER
                    ? RouteNames.SEGMENTS_STATIC_FILTERS_EDIT
                    : RouteNames.SEGMENTS_EDIT;
            this.$router.push({
                name: page,
                params: { id, companyId: this.$route.params.companyId },
            });
        },
        handleDelete(id) {
            const { version, generationType } = this.segmentsById[id];
            this.confirmSegmentDeletion(id, version, generationType);
        },
        confirmSegmentDeletion(segmentId, version, generationType) {
            const segment = this[State.CACHED_SEGMENTS].find(s => s.id === segmentId);
            const entityName = segment?.name || this.$i18n.t('generic.entity');

            this.$alert(this.$t('alerts.areYouSureDeleteEntity', { entityName }), {
                type: ALERT_TYPES.warning,
                buttons: [
                    new Button({
                        label: this.$i18n.t('generic.delete'),
                        handler: () => {
                            this.deleteSegment(segmentId, version, generationType);
                        },
                    }),
                ],
            });
        },
        handleStop(id) {
            this.confirmSegmentOperationalStatusUpdate({
                segmentId: id,
                operation: STATUS_CODES_OPERATIONS.STOPPED,
                areYouSureButtonMsgPath: 'generic.stop',
                areYouSureAlertMsgPath: 'segments.alerts.areYouSureStopSegment',
                successActionMsgPath: 'generic.stateMap.stopped',
                errorMsgPath: 'alertMessage.cep.errorStoppingSegment',
            });
        },
        handleStart(id) {
            this.confirmSegmentOperationalStatusUpdate({
                segmentId: id,
                operation: STATUS_CODES_OPERATIONS.PENDING,
                areYouSureButtonMsgPath: 'generic.start',
                areYouSureAlertMsgPath: 'segments.alerts.areYouSureStartSegment',
                successActionMsgPath: 'generic.stateMap.generating',
                errorMsgPath: 'alertMessage.cep.errorStartingSegment',
            });
        },
        confirmSegmentOperationalStatusUpdate({
            segmentId,
            operation,
            areYouSureButtonMsgPath,
            areYouSureAlertMsgPath,
            successActionMsgPath,
            errorMsgPath,
        }) {
            const { name } = this.segmentsById[segmentId];
            const entityName = name || this.$i18n.t('generic.entity');

            this.$alert(this.$t(areYouSureAlertMsgPath, { entityName }), {
                type: ALERT_TYPES.warning,
                buttons: [
                    new Button({
                        label: this.$i18n.t(areYouSureButtonMsgPath),
                        handler: () => {
                            this.updateSegmentOperationalStatus(
                                segmentId,
                                operation,
                                successActionMsgPath,
                                errorMsgPath,
                            );
                        },
                    }),
                ],
            });
        },
        async deleteSegment(id, version, segmentType) {
            try {
                this.$Progress.start();
                await this.$store.dispatch(`segments/${Actions.DELETE_SEGMENT}`, { id, version, segmentType });
                this.$Progress.finish();
                this.isOverviewEnabled = false;
                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('alertMessage.successMessageWithoutRedirect', {
                        entityName: this.$i18n.t('segments.segment'),
                        action: this.$i18n.t('generic.stateMap.deleted').toLowerCase(),
                    }),
                    type: ALERT_TYPES.success,
                });
            } catch (e) {
                Sentry.captureException(e);
                this.$Progress.fail();
                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('alertMessage.cep.errorDeletingSegment'),
                });
            }
        },

        async handleDownload(id) {
            if (this.isCsvDownloading) {
                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('alertMessage.cep.previousProcessDownloadStillInProgress'),
                    type: ALERT_TYPES.warning,
                });
                return;
            }

            this.isCsvDownloading = true;

            const { inputMethods } = this.segmentsById[id];
            const isRange = inputMethods?.range ?? false; // false by default
            await this.downloadSegmentData(id, isRange);
        },

        async downloadSegmentData(id, isRange) {
            try {
                this.$Progress.start();
                const segment = await this.$store.dispatch(`segments/${Actions.DOWNLOAD_SEGMENT_DATA}`, { id });
                const csvContent = this.generateCSV(segment.data, isRange);
                this.downloadCSV(id, csvContent);
                this.$Progress.finish();
                this.isOverviewEnabled = false;
                this.isCsvDownloading = false;
            } catch (e) {
                Sentry.captureException(e);
                this.isCsvDownloading = false;
                this.$Progress.fail();
                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('alertMessage.cep.errorDownloadSegmentData', { id }),
                });
            }
        },

        generateCSV(data, isRange) {
            if (isEmpty(data)) {
                return '';
            }

            // generate csv for cardinality data
            if (data.every(element => isNumber(element))) {
                return data.map(item => item.toString()).join('\n');
            }

            // generate csv for range data
            if (isRange) {
                const formatRange = range => {
                    return `{from:${range.from} to:${range.to}}`;
                };

                return data
                    .map(row => {
                        return row.map(value => (isObject(value) ? formatRange(value) : value)).join('\n');
                    })
                    .join('\n');
            }

            // other type of data
            return data.map(row => Object.values(row).join('\n')).join('\n');
        },

        downloadCSV(id, csvContent) {
            const blob = new Blob([csvContent], { type: 'text/csv' });
            const url = URL.createObjectURL(blob);

            const link = document.createElement('a');
            link.href = url;
            link.download = `segment_${id}.csv`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            URL.revokeObjectURL(url);
        },
        async updateSegmentOperationalStatus(id, operation, successActionMsgPath, errorMsgPath) {
            try {
                this.$Progress.start();
                await this.$store.dispatch(`segments/${Actions.UPDATE_SEGMENT_OPERATIONAL_STATUS}`, { id, operation });
                this.$Progress.finish();
                this.isOverviewEnabled = false;
                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('alertMessage.successMessageWithoutRedirect', {
                        entityName: this.$i18n.t('segments.segment'),
                        action: this.$i18n.t(successActionMsgPath).toLowerCase(),
                    }),
                    type: ALERT_TYPES.success,
                });
            } catch (e) {
                Sentry.captureException(e);
                this.$Progress.fail();
                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t(errorMsgPath),
                });
            }
        },
    },
};
</script>

<style lang="scss" scoped></style>
