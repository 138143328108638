import http, { DnoResponseBase } from '@/http/index';
import { AxiosPromise } from 'axios';

/**
 * Get Charging Specifications.
 *
 * @returns {AxiosPromise}
 */
export function getChargingSpecifications(
    ids: string[] = [],
    returnChargingVersion = true,
    includeReferencedEntities = false,
    includePortalId = true,
    chargingVersion?: string,
): AxiosPromise<DnoResponseBase> {
    return http({
        method: 'POST',
        url: '/v3charging/getchargingspecifications',
        data: {
            ids,
            return_charging_version: returnChargingVersion,
            include_referenced_entities: includeReferencedEntities,
            charging_version: chargingVersion,
            include_portal_id: includePortalId,
        },
    });
}

/**
 * contains object of entity versions as a key
 * and version count in use as value
 */
type GetChargingSpecCount = {
    data: {
        event_category_counters: Record<`version_${number}.${number}.${number}.${number}`, number>;
    };
};

/**
 * Get Charging Specification count in use.
 * @param chargingSpec {String}: format `<entity id>_<epoch week number>`
 * @returns {AxiosPromise}
 */
export function getChargingSpecCount(chargingSpec: `${string}_${number}`): AxiosPromise<GetChargingSpecCount> {
    return http({
        method: 'GET',
        url: '/v3charging/getchargingspeccount',
        params: {
            charging_spec: chargingSpec,
        },
    });
}

/**
 * Add Charging Specifications.
 *
 * @returns {AxiosPromise}
 */
export function addChargingSpecifications(data: any): AxiosPromise<DnoResponseBase> {
    return http({
        method: 'POST',
        url: '/v3chargingwrite/addchargingspecifications',
        data,
    });
}

/**
 * Update Charging Specifications.
 *
 * @returns {AxiosPromise}
 */
export function updateChargingSpecifications(id: string, version: number, data: any): AxiosPromise<DnoResponseBase> {
    return http({
        method: 'POST',
        url: '/v3chargingwrite/updatechargingspecifications',
        data: {
            id,
            version,
            data,
        },
    });
}

/**
 * Update Charging Specifications state.
 *
 * @returns {AxiosPromise}
 */
export function updateChargingSpecificationsState(
    id: string,
    version: number,
    state: number,
): AxiosPromise<DnoResponseBase> {
    return http({
        method: 'POST',
        url: '/v3chargingwrite/updatestatechargingspecifications',
        data: {
            id,
            version,
            state,
        },
    });
}

/**
 * Get Charging Specifications draft list.
 *
 * @returns {AxiosPromise}
 */
export function getChargingSpecificationsDraftList(): AxiosPromise<DnoResponseBase> {
    return http({
        method: 'POST',
        url: '/v3charging/getchargingspecificationsdraftlist',
    });
}

/**
 * Get Charging Specifications entity draft.
 *
 * @returns {AxiosPromise}
 */
export function getChargingSpecificationEntityDraft(id: string): AxiosPromise<DnoResponseBase> {
    return http({
        method: 'POST',
        url: '/v3charging/getchargingspecificationdraft',
        data: {
            id,
        },
    });
}

/**
 * Set charging specification draft.
 *
 * @returns {AxiosPromise}
 */
type ChargingSpecificationDraftPayload = {
    id?: string;
    data: object;
};

export function setChargingSpecificationDraft(data: ChargingSpecificationDraftPayload): AxiosPromise<DnoResponseBase> {
    return http({
        method: 'POST',
        url: '/v3charging/setchargingspecificationdraft',
        data,
    });
}

/**
 * Delete charging specification draft.
 *
 * @returns {AxiosPromise}
 */
export function deleteChargingSpecificationDraft(id: string): AxiosPromise<DnoResponseBase> {
    return http({
        method: 'POST',
        url: '/v3charging/chargingspecificationdraftdelete',
        data: {
            id,
        },
    });
}

type CsPushVersionData = {
    id: string;
    charging_version: string;
};

export function chargingSpecPushVersion(data: CsPushVersionData[]): AxiosPromise<DnoResponseBase> {
    return http({
        method: 'POST',
        url: '/v3charging/chargingspecpushversion',
        data: {
            charging_specs: data,
        },
    });
}

export default {
    getChargingSpecifications,
    getChargingSpecCount,
    addChargingSpecifications,
    updateChargingSpecifications,
    updateChargingSpecificationsState,
    getChargingSpecificationsDraftList,
    getChargingSpecificationEntityDraft,
    setChargingSpecificationDraft,
    deleteChargingSpecificationDraft,
    chargingSpecPushVersion,
};
