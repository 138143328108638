
import Vue from '@/common/typedVue';

import { getApplicationOwnerById } from '@/__new__/services/dno/operateAPIs/http/operateAPIs';
import { mapApplicationOwnerToFeData, ApplicationOwner } from '@/__new__/features/operateAPIs/common/ApplicationOwner';

// Components
import AbstractEditPageWrapper from '@/components/layout/AbstractEditPageWrapper.vue';
import ApplicationOwnerOverview from '@/__new__/features/operateAPIs/applicationOwners/ApplicationOwnerOverview.vue';
import AppHeader from '@/components/layout/AppHeader.vue';

export default Vue.extend({
    name: 'ApplicationOwnerViewPage',
    components: {
        AbstractEditPageWrapper,
        AppHeader,
        ApplicationOwnerOverview,
    },
    data() {
        return {
            id: '',
            channelPartnerId: '',
            applicationOwner: null as ApplicationOwner | null,
            pageTitle: this.$i18n.tc('operateAPIs.applicationOwner', 1),
        };
    },
    async created() {
        await this.$withLoadingSpinner(
            async () => {
                this.id = this.$route.params.id;
                this.channelPartnerId = this.$route.params.channelPartnerId;
                const response = await getApplicationOwnerById(this.id, this.channelPartnerId);
                this.applicationOwner = mapApplicationOwnerToFeData(response.data);
            },
            {
                errorHandler: () => {
                    this.$alert(this.$t('operateAPIs.failedToLoadApplicationOwner'));
                },
            },
        );
    },
});
