import { render, staticRenderFns } from "./SegmentDescription.vue?vue&type=template&id=03c918b0"
import script from "./SegmentDescription.vue?vue&type=script&lang=js"
export * from "./SegmentDescription.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports