<template>
    <EntityListPageLayout
        :entityType="entityType"
        :listViewColumns="listViewColumns"
        :overviewContentBuilder="overviewContentBuilder"
        writePermission="OffersWrite"
    >
        <template #modals="{ selectedEntity, entities }">
            <CompatibilityModal
                v-if="isCompatibilityModalVisible"
                :currentOffer="selectedEntity"
                :offers="entities"
                @close="onCloseCompatibilityModal"
                @cancel="onCancelCompatibilityModal"
            />
        </template>

        <template #operations="{ entity }">
            <EntityStatusIndicator
                :status="entity.operation"
                :stateMap="statusesOperation"
                :stateMapColor="STATUS_OPERATIONS_INDICATOR_MAP"
            />
        </template>

        <template #overviewButtons="{ selectedEntity }">
            <AppButton
                v-if="
                    permissionsService.productCatalogWrite() &&
                    isUserAllowed('OffersWrite') &&
                    !isDraft(selectedEntity) &&
                    !permissionsService.isFeatureFlagEnabled('validation-rules')
                "
                data-test-id="compatibilityModalBtn"
                :iconType="ICON_TYPES.PLUS"
                :label="$i18n.t('productCatalog.compatibilityRules.editCompatibilityRules')"
                class="pl-0"
                @click="onEditCompatibilityRulesButtonClicked"
            />
        </template>
    </EntityListPageLayout>
</template>

<script>
// Vuex
import Actions, { Getters } from '@/store/mutation-types';
import { mapGetters, mapActions } from 'vuex';

// components
import EntityFetcherMixin from '@/__new__/features/pc/EntityFetcherMixin.vue';
import CompatibilityModal from '@/__new__/features/pc/offers/CompatibilityModal.vue';
import EntityListPageLayout from '@/__new__/features/pc/EntityListPageLayout.vue';

// helpers
import { unitTypes } from '@/common/formatting';
import tableColumnType from '@/common/filterTable';
import permissionsService, { isUserAllowed, getOperatorConfigValue } from '@/services/permissions/permissions.service';
import { overviewContentBuilder } from './common/overviewContentBuilder';
import * as Sentry from '@sentry/vue';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import { ICON_TYPES } from '@/common/iconHelper';
import EntityStatusIndicator from '@/components/partials/EntityStatusIndicator.vue';
import ENTITY_TYPES from '@/common/entities/entityTypes';
import { STATUS_OPERATIONS_INDICATOR_MAP, STATUS_CODES_OPERATIONS } from '@/common/commonHelper';

// http
import { getPartyRoles } from '@/__new__/services/dno/partyRolesPermissionsManagement/http/partyRolesPermissionsManagement';

export default {
    name: 'OfferListPage',
    components: {
        AppButton,
        CompatibilityModal,
        EntityListPageLayout,
        EntityStatusIndicator,
    },
    mixins: [EntityFetcherMixin],
    data() {
        return {
            ICON_TYPES,
            BUTTON_TYPES,
            permissionsService,
            overviewContentBuilder,
            partyRoleOptions: [],

            entityType: ENTITY_TYPES.OFFER,

            isCompatibilityModalVisible: false,

            STATUS_OPERATIONS_INDICATOR_MAP,
        };
    },
    computed: {
        ...mapGetters('productcatalog', [Getters.PC_GET_ENTITIES_BY_TYPE_NOT_DELETED]),
        areChannelPartnersEnabled() {
            return isUserAllowed('OperateAPIsChannelPartnersRead');
        },
        notDeletedOffers() {
            return this[Getters.PC_GET_ENTITIES_BY_TYPE_NOT_DELETED](ENTITY_TYPES.OFFER);
        },
        statusesOperation() {
            return new Map([
                [STATUS_CODES_OPERATIONS.NA, this.$t('generic.N/A')],
                [STATUS_CODES_OPERATIONS.PENDING, this.$t('generic.stateMap.pending')],
                [STATUS_CODES_OPERATIONS.RUNNING, this.$t('generic.stateMap.running')],
                [STATUS_CODES_OPERATIONS.EXPIRED, this.$t('generic.stateMap.expired')],
            ]);
        },
        listViewColumns() {
            const baseColumns = [
                {
                    name: this.$t('operations.operations'),
                    key: 'operations',
                    field: 'operationsLabel',
                    additionalFormatter: entity => this.statusesOperation.get(entity.operation),
                    filterType: tableColumnType.TEXT_LIMITED_OPTIONS,
                },
                {
                    name: this.$t('generic.priceType'),
                    key: 'priceTypeString',
                    field: 'priceTypeString',
                    filterType: tableColumnType.TEXT_LIMITED_OPTIONS,
                    limitedOptions: [],
                },
                {
                    name: this.$t('generic.price'),
                    key: 'priceFormatted',
                    field: 'price',
                    filterType: tableColumnType.NUMBER_UNITS,
                    unitType: unitTypes.DATA_AND_MONETARY,
                },
                {
                    name: this.$t('generic.currency'),
                    key: 'currencyType',
                    field: 'currencyType',
                    filterType: tableColumnType.TEXT_LIMITED_OPTIONS,
                    limitedOptions: [],
                },
                {
                    name: this.$t('generic.startTime'),
                    key: 'startTimeFormatted',
                    field: 'startTime',
                    filterType: tableColumnType.DATE,
                    sortBy: entity => entity.startTime,
                },
                {
                    name: this.$t('generic.endTime'),
                    key: 'endTimeFormatted',
                    field: 'endTime',
                    filterType: tableColumnType.DATE,
                    sortBy: entity => entity.endTime,
                },
                {
                    name: this.$t('productCatalog.entities.plural.categories'),
                    key: 'categoriesDisplay',
                    field: 'categoriesDisplay',
                    filterType: tableColumnType.TEXT_LIMITED_OPTIONS_CONTAINS,
                    limitedOptions: [],
                },
                {
                    name: this.$t('generic.id'),
                    key: 'id',
                    field: 'id',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$t('productCatalog.templates.templateName'),
                    key: 'templateName',
                    field: 'templateName',
                    filterType: tableColumnType.TEXT_LIMITED_OPTIONS,
                    limitedOptions: [],
                },
                {
                    name: this.$t('productCatalog.templates.templateExternalId'),
                    key: 'templateExternalId',
                    field: 'templateExternalId',
                    filterType: tableColumnType.TEXT_LIMITED_OPTIONS,
                    limitedOptions: [],
                },
            ];

            const permissionRelatedColumns = [];

            if (this.areChannelPartnersEnabled) {
                permissionRelatedColumns.push({
                    name: this.$i18n.t('operateAPIs.channelPartner'),
                    key: 'channelPartnerId',
                    field: 'channelPartnerId',
                    filterType: tableColumnType.TEXT_LIMITED_OPTIONS,
                    // Actually don't want to return anything if the field is not present since it is not required
                    // eslint-disable-next-line consistent-return
                    additionalFormatter: entity => {
                        const result = this.partyRoleOptions.find(
                            partyRoleOption => partyRoleOption.id === entity.channelPartnerId,
                        )?.name;

                        if (result) {
                            return result;
                        }
                    },
                    limitedOptions: [],
                });
            }

            return [...baseColumns, ...permissionRelatedColumns];
        },
    },
    mounted() {
        this.fetchCategories(this.entityType);
        if (this.areChannelPartnersEnabled) {
            this.getChannelPartners();
        }
    },
    methods: {
        ...mapActions('productcatalog', [Actions.PC_REQUEST_ENTITIES_BY_TYPE_AND_IDS]),
        onEditCompatibilityRulesButtonClicked() {
            this.isCompatibilityModalVisible = true;
        },
        async onCloseCompatibilityModal() {
            this.isCompatibilityModalVisible = false;
            await this.fetchEntities(ENTITY_TYPES.OFFER);
        },
        onCancelCompatibilityModal() {
            this.isCompatibilityModalVisible = this.$event;
        },
        async fetchCategories() {
            try {
                this.$Progress.start();
                await this[Actions.PC_REQUEST_ENTITIES_BY_TYPE_AND_IDS]({
                    entityType: ENTITY_TYPES.CATEGORY,
                });
                this.$Progress.finish();
            } catch (error) {
                this.$Progress.fail();
                Sentry.captureException(error);
                this.$eventBus.$emit('showAlert', {
                    message: this.$t('alertMessage.errorDoingSmthTryAgain', {
                        action: 'fetching',
                        entityName: 'categories',
                    }),
                });
            }
        },
        isDraft(entity) {
            return entity?.isDraft;
        },
        async getChannelPartners() {
            try {
                const response = await getPartyRoles('ChannelPartner');

                this.partyRoleOptions = response?.data?.map(partyRole => ({
                    id: partyRole?.id || '',
                    name: partyRole?.name || '',
                }));
            } catch (error) {
                this.$eventBus.$emit('showAlert', {
                    message: this.$t('alertMessage.errorDoingSmthTryAgain', {
                        action: 'fetching',
                        entityName: 'channel partners',
                    }),
                });
            }
        },
        getOperatorConfigValue,
        isUserAllowed,
    },
};
</script>
