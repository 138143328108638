import http from '@/http/index';
import isArray from 'lodash/isArray';
import { USER_MANAGER_HIERARCHY } from '@/__new__/features/customerCare/common/customerCareHelper';

/**
 * calls the
 * @param ids {Array}
 * @returns {AxiosPromise}
 */
export const getPromotions = ids => {
    let data = {};

    if (ids && isArray(ids)) {
        data = {
            ids,
        };
    } else {
        data = {};
    }

    return http({
        method: 'POST',
        url: '/v3promotion/get',
        headers: {
            'Content-Type': 'application/json',
        },
        data,
    });
};

/**
 * calls the dew /api/v3/promo_rule/add route
 * @param promotion {Object}
 * @returns {AxiosPromise}
 */
export function addPromotion(promotion) {
    return http({
        method: 'POST',
        url: '/v3promotionwrite/add',
        headers: {
            'Content-Type': 'application/json',
        },
        data: {
            data: promotion,
            // TODO: resolve this as some point to either use get_entities for fethcing promotions
            // or keep approving on create so they appear through promotions get api route
            approve_on_create: true,
        },
    });
}

/**
 * calls the dew /api/v3/promo_rule/update route
 * @param id {String}
 * @param version {Number}
 * @param data {Object}
 * @returns {AxiosPromise}
 */
export function updatePromotion(id, version, data) {
    return http({
        method: 'POST',
        url: '/v3promotionwrite/update',
        headers: {
            'Content-Type': 'application/json',
        },
        data: {
            id,
            version,
            data,
        },
    });
}

/**
 * calls the dew /api/v3/promo_rule/updatestate route
 * @param id {String}
 * @param version {Number}
 * @param state {Number}
 * @returns {AxiosPromise}
 */
export function updatestatePromotion(id, version, state) {
    return http({
        method: 'POST',
        url: '/v3promotionwrite/updatestate',
        headers: {
            'Content-Type': 'application/json',
        },
        data: {
            id,
            version,
            state,
        },
    });
}

/**
 * calls the dew /api/v3/promotion/preview route
 * @param offerId {String}
 * @param voucherCode {String}
 * @param accountId {String}
 * @returns {AxiosPromise}
 */
export function promotionPreview(offerId, voucherCode, accountId) {
    return http({
        method: 'POST',
        url: '/v3promotion/preview',
        headers: {
            'Content-Type': 'application/json',
        },
        data: {
            target_id: accountId,
            target_type: USER_MANAGER_HIERARCHY.ACCOUNT,
            entities: [
                {
                    type: 'offer',
                    id: offerId,
                },
            ],
            voucher_codes: voucherCode,
        },
    });
}
