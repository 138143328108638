<template>
    <div
        class="unit-picker-wrapper"
        :class="{ 'editor-design': isEditorDesign }"
    >
        <div
            v-if="label"
            class="unit-picker-label"
        >
            {{ label }}
        </div>
        <div class="d-flex align-items-center">
            <AppInputV3
                v-if="enableValueInput"
                v-model.number="inputValue"
                :type="'number'"
                :placeholder="placeholder"
                :invalid="error"
                :disabled="disabled"
                :width="inputWidth"
                :min="min"
                class="mr-2 input-picker"
                @input="onValueChanged"
            />
            <AppMultiselectV3
                v-model="selectedUnitDefinition"
                :allowEmpty="false"
                :showLabels="false"
                :options="unitDefinitions"
                :placeholder="multiselectPlaceHolder"
                :disabled="disabled"
                :small="small"
                :borderNone="true"
                :blueArrow="true"
                :newDesign="true"
                :searchable="searchable"
                :style="multiselectPickercssProps"
                data-test-id="definition"
                class="mr-2 multiselect-picker"
                label="label"
                trackBy="id"
                @input="onValueChanged"
            />
        </div>
        <div
            v-show="error && errorMessage"
            class="error-message"
        >
            {{ errorMessage }}
        </div>
    </div>
</template>

<script>
import AppMultiselectV3 from '@/components/partials/inputs/AppMultiselectV3.vue';
import { getBestUnit, getUnitDefinitions } from '@/common/formatting';
import AppInputV3 from '@/components/partials/inputs/AppInputV3.vue';
import i18n from '@/i18n';

export default {
    name: 'UnitPicker',
    components: {
        AppInputV3,
        AppMultiselectV3,
    },
    props: {
        value: {
            type: [Number, Object],
            default: null,
        },
        type: {
            type: String,
            default: null,
        },
        defaultUnitId: {
            type: String,
            default: null,
        },
        increaseWidth: {
            type: Boolean,
            default: false,
        },
        placeholder: {
            type: String,
            default: null,
        },
        error: {
            type: Boolean,
            default: false,
        },
        errorMessage: {
            type: String,
            default: '',
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        large: {
            type: Boolean,
            default: false,
        },
        multiselectPlaceHolder: {
            type: String,
            default: i18n.t('generic.pickUnit'),
        },
        inputWidth: {
            type: String,
            default: '',
        },
        small: {
            type: Boolean,
            default: true,
        },
        label: {
            type: String,
            default: null,
        },
        emitObject: {
            type: Boolean,
            default: false,
        },
        isWidthStatic: {
            type: Boolean,
            default: false,
        },
        // should be true whenever Unit Picker is a part of Editor page
        // prop makes it more look like regular input than a custon 1 line thing
        isEditorDesign: {
            type: Boolean,
            default: false,
        },
        searchable: {
            type: Boolean,
            default: true,
        },
        min: {
            type: Number,
            default: 1,
        },
        definitionsWidth: {
            type: Number,
            default: 4.5,
        },
        enableValueInput: {
            type: Boolean,
            default: true,
        },
        hiddenOptionIds: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            inputValue: null,
            unitDefinitions: [],
            selectedUnitDefinition: null,
        };
    },
    computed: {
        multiselectPickercssProps() {
            let width = this.definitionsWidth;
            if (this.isWidthStatic) {
                width = 4.5;
            }
            if (this.increaseWidth) {
                width = 12.5;
            }

            return {
                '--multiselect-picker-width': `${width}rem`,
            };
        },
    },
    watch: {
        type() {
            this.setPickerData();
        },
        value: {
            handler() {
                this.setPickerData();
            },
            immediate: true,
        },
    },
    created() {
        this.setPickerData();
    },
    methods: {
        onValueChanged() {
            if (this.emitObject) {
                this.$emit('input', {
                    definition: this.selectedUnitDefinition,
                    value: this.inputValue,
                });
            } else {
                this.$emit('input', (this?.selectedUnitDefinition?.multiplier ?? 1) * this.inputValue);
            }
        },
        getDefaultUnit() {
            const unitDefinitions = getUnitDefinitions(this.type);
            if (this.defaultUnitId) {
                return unitDefinitions.find(definition => definition.id === this.defaultUnitId);
            }
            return unitDefinitions[0];
        },
        setPickerData() {
            if (typeof this.value === 'number') {
                this.unitDefinitions = getUnitDefinitions(this.type);
                const value = this.emitObject ? this.value.value : this.value;
                this.selectedUnitDefinition = value ? getBestUnit(this.unitDefinitions, value) : this.getDefaultUnit();

                this.inputValue = value !== null ? value / this.selectedUnitDefinition.multiplier : this.min;
            } else {
                this.unitDefinitions = getUnitDefinitions(this.type);
                this.selectedUnitDefinition =
                    this.value && this.value.definition
                        ? this.unitDefinitions.find(unit => unit.id === this.value.definition.id)
                        : this.getDefaultUnit();
                this.inputValue = this.value?.value ?? this.min;
            }

            if (this.hiddenOptionIds.length) {
                this.unitDefinitions = this.unitDefinitions.filter(({ id }) => !this.hiddenOptionIds.includes(id));
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/palette.scss';
@import '~@/assets/scss/layout.scss';

.unit-picker-wrapper {
    display: flex;
    align-items: center;

    .unit-picker-label {
        font-size: 0.75rem;
        text-transform: uppercase;
        font-weight: bold;
        color: $gray-blue;
        margin-right: 0.75rem;
    }

    .input-picker {
        width: 9rem;
    }

    .multiselect-picker {
        min-width: var(--multiselect-picker-width);
    }

    &.editor-design {
        flex-direction: column;
        align-items: flex-start;

        .unit-picker-label {
            color: $gray90;
            text-transform: initial;
            font-weight: 600;
            line-height: 1.625rem;
            padding-bottom: $spacing-xxs;
        }

        .multiselect-picker {
            min-width: 6rem;
        }

        .error-message {
            padding-top: $spacing-xxs;
            font-size: 0.75rem;
            line-height: 1.25rem;
            color: $red;
        }
    }
}
</style>
