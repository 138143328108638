var render = function render(){var _vm=this,_c=_vm._self._c;return _c('TableFiltersRenderless',{attrs:{"entities":_vm.voucherCodes},scopedSlots:_vm._u([{key:"default",fn:function({
            // state
            allFilters,
            filteredEntities,
            // methods
            onFilterAdded,
            removeFilter,
            removeAllFilters,
        }){return [_c('div',[_c('div',{staticClass:"d-flex align-items-center justify-content-end position-relative"},[_c('ResponseModalButton',{attrs:{"response":_vm.voucherCodesApiResponse,"title":_vm.$i18n.t('customerCare.voucherHistory.voucherHistory')}}),_c('FilterTable',{attrs:{"columns":_vm.tableColumnsData,"multiselectWidth":{ width: '12rem' }},on:{"filterAdded":onFilterAdded},scopedSlots:_vm._u([{key:"button",fn:function(slotProps){return [_c('IconButton',{attrs:{"label":_vm.$i18n.t('generic.filter'),"icon":_vm.ICON_TYPES.FILTER},on:{"iconClick":slotProps.clickHandler}})]}}],null,true)})],1),_c('TableFiltersTags',{staticClass:"mt-1 mb-3",attrs:{"filterTableMixin":{ allFilters }},on:{"removeFilter":removeFilter,"removeAllFilters":removeAllFilters}}),_c('AppTable',{attrs:{"entities":filteredEntities,"isDefaultHoverEnabled":false,"isCustomButtonPassed":true,"disableBoxShadow":true,"isPaginationEnabled":true,"canSelectColumns":true,"newDesign":true,"columnsData":_vm.tableColumnsData,"tableKey":"user/voucher-history"}})],1)]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }