import { invert } from 'lodash';
import { LABEL_COLOR } from '@/common/labelsHelper';

export enum SIM_STATE_MAP {
    AVAILABLE = 1,
    RESERVED = 5,
    ASSIGNED = 10,
    RELEASED = 100,
}

export const SIM_STATE_NAME = invert(SIM_STATE_MAP);

export const SIM_STATE_NAME_MAP = new Map<number, string>(
    Object.entries(SIM_STATE_NAME).map(([key, value]) => [Number(key), value]),
);

export const SIMStateColorMap = new Map([
    [SIM_STATE_MAP.AVAILABLE, LABEL_COLOR.green],
    [SIM_STATE_MAP.RESERVED, LABEL_COLOR.yellow],
    [SIM_STATE_MAP.ASSIGNED, LABEL_COLOR.yellow],
    [SIM_STATE_MAP.RELEASED, LABEL_COLOR.yellow],
]);
