
// components
import EntityOverview from '@/components/partials/entityOverview/EntityOverview.vue';
import OverviewHeaderV2 from '@/components/partials/entityOverview/OverviewHeaderV2.vue';
import EventStatusIndicator from '@/components/partials/EventStatusIndicator.vue';
import AppCheckboxCardViewList, { APIControlItem } from '@/components/partials/AppCheckboxCardViewList.vue';
import AppOverviewBlock from '@/components/partials/AppOverviewBlock.vue';
import TagsOverview, { TagsOverviewItems } from '@/components/partials/TagsOverview.vue';
import EntityTracker from '@/components/entityTracker/EntityTracker.vue';
import EventPropertiesOverview from '@/__new__/features/events/EventPropertiesOverview.vue';

// helpers
import ENTITY_TYPES from '@/common/entities/entityTypes';
import {
    CDP_SUPPORTED_API_CONTROL_AUTH_TYPES,
    eventStatuses,
    getAPIControlItemsList,
} from '@/common/events/eventsHelper';
import filters from '@/common/filters';
import { EntityType } from '@/components/entityTracker/entityTracker';
import { ICON_TYPES } from '@/common/iconHelper';
import { CollapsibleListItem } from '@/common/AppCollapsibleListHelper';
import { isEmpty } from 'lodash';

export default {
    name: 'EventOverview',
    components: {
        EventPropertiesOverview,
        TagsOverview,
        EntityTracker,
        EventStatusIndicator,
        EntityOverview,
        OverviewHeaderV2,
        AppCheckboxCardViewList,
        AppOverviewBlock,
    },
    props: {
        event: {
            type: Object,
            default: null,
        },
    },
    data() {
        return {
            ICON_TYPES,
            entityType: ENTITY_TYPES.EVENT,
            eventStatuses,
            filters,
            EntityType,
            isSchemaPopupShown: false,
        };
    },

    computed: {
        generalOverviewItems(): CollapsibleListItem[] {
            const { description } = this.event;
            const rows = [
                {
                    name: this.$t('events.eventType'),
                    value: this.event.eventType,
                },
                {
                    name: this.$t('events.eventPriority'),
                    value: this.event.priority,
                },
            ];

            if (description) {
                rows.push({
                    name: this.$t('generic.description'),
                    value: description,
                });
            }

            return [
                {
                    name: this.$t('generic.general'),
                    isCollapsed: false,
                    rows,
                },
            ];
        },
        tagOverviewItems(): TagsOverviewItems[] {
            const items = [
                {
                    title: this.$t('customerCare.products'),
                    tags: this.event.producerProducts,
                },
                {
                    title: this.$t('events.producerServices'),
                    tags: this.event.producerServices,
                },
            ];
            const { labels } = this.event;
            if (!isEmpty(labels)) {
                items.push({
                    title: this.$tc('generic.label', labels.length),
                    tags: labels,
                });
            }
            return items;
        },
        shouldShowProducerInfoBlock(): boolean {
            return this.shouldDisplayEventProducts || this.shouldDisplayEventServices;
        },
        shouldDisplayEventProducts(): boolean {
            return !!this.event.producerProducts.length;
        },
        shouldDisplayEventServices(): boolean {
            return !!this.event.producerServices.length;
        },
        apiControlItemsList(): APIControlItem[] {
            const apiControl = this.event.apiControl || null;
            const apiAuth = apiControl.auth || {};
            return getAPIControlItemsList(
                Boolean(apiAuth[CDP_SUPPORTED_API_CONTROL_AUTH_TYPES.SERVICE]),
                Boolean(apiAuth[CDP_SUPPORTED_API_CONTROL_AUTH_TYPES.CLIENT]),
                Boolean(apiAuth[CDP_SUPPORTED_API_CONTROL_AUTH_TYPES.SERVER]),
                Boolean(apiAuth[CDP_SUPPORTED_API_CONTROL_AUTH_TYPES.UNREGISTERED]),
            ).filter(item => item.value);
        },

        description(): string {
            return this.event.description;
        },
    },
};
