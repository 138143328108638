import { ALERT_TYPES, ALERT_COLORS } from '@/common/alerts/Alert';
import Button from '@/common/button/Button';

const ALERT_TYPE_TO_BUTTON_COLOR = {
    [ALERT_TYPES.error]: ALERT_COLORS.red,
    [ALERT_TYPES.info]: ALERT_COLORS.blue,
    [ALERT_TYPES.success]: ALERT_COLORS.green,
    [ALERT_TYPES.warning]: ALERT_COLORS.yellow,
};

export default {
    name: 'supportButtonMixin',
    data() {
        return {
            alertButtons: {},
        };
    },
    methods: {
        showSupportAlert(message, type, description) {
            if (!this.alertButtons?.supportButton) {
                this.alertButtons.supportButton = new Button({
                    label: this.$i18n.t('generic.support'),
                    colorScheme: ALERT_TYPE_TO_BUTTON_COLOR[type],
                });
            }
            this.$eventBus.$emit('showAlert', {
                message,
                type,
                buttons: this.alertButtons,
                ...(description && { description }),
            });
            this.$eventBus.$once('buttonClicked', id => {
                if (id === this.alertButtons.supportButton.id) {
                    this.redirectToServiceDesk();
                }
            });
        },

        redirectToServiceDesk() {
            window.open('https://lotusflare.atlassian.net/servicedesk/customer/portals', '_blank');
        },
    },
};
