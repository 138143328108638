
import Vue, { PropType } from 'vue';

// components
import AppTable from '@/components/partials/AppTable.vue';
import AppDialogV2 from '@/components/partials/AppDialogV2.vue';

// helpers
import CustomerCareHTTP from '@/__new__/services/dno/user/http/customer-care';
import { TargetTuple } from '@/__new__/services/dno/user/models/targetTuple';
import tableColumnType from '@/common/filterTable';

export default Vue.extend({
    name: 'ViewSubscriberServicesModal',
    components: {
        AppTable,
        AppDialogV2,
    },
    props: {
        visible: {
            required: true,
            type: Boolean,
        },
        entity: {
            default: null,
            type: Object as PropType<TargetTuple>,
        },
    },
    data() {
        return {
            rawResponse: {},
        };
    },
    computed: {
        subscriberService() {
            return (
                Array.isArray(this.rawResponse?.data?.services_data) ? this.rawResponse?.data?.services_data : []
            ).map(service => {
                return {
                    name: service?.name,
                    date: new Date(service?.activation_time),
                    description: service?.description,
                    type: service?.type,
                };
            });
        },
        tableColumnsData() {
            return [
                {
                    name: this.$i18n.t('generic.name'),
                    key: 'name',
                    field: 'name',
                    forbidHideColumn: true,
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('generic.date'),
                    key: 'date',
                    field: 'date',
                    forbidHideColumn: true,
                    filterType: tableColumnType.DATE,
                },
                {
                    name: this.$i18n.t('generic.description'),
                    key: 'description',
                    field: 'description',
                    forbidHideColumn: true,
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('generic.type'),
                    key: 'type',
                    field: 'type',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
            ];
        },
    },
    watch: {
        visible(value) {
            if (value) {
                this.fetchSubscriberServices();
            }
        },
    },
    methods: {
        fetchSubscriberServices() {
            return this.$withProgressBar(
                async () => {
                    this.rawResponse = await CustomerCareHTTP.getSubscriberServices(
                        this.entity.targetId,
                        this.entity.targetType,
                    );
                },
                {
                    errorHandler: () => {
                        this.$alert(this.$t('alertMessage.somethingWentWrongFetchingNecessaryData'));
                    },
                },
            );
        },
    },
});
