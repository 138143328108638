var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(!_vm.isEmpty)?_c('div',{staticClass:"flex-container"},[_c('div',{staticClass:"input-block"},[_c('div',{staticClass:"d-flex align-items-center"},[(_vm.showCurrency)?_c('div',{staticClass:"inline-text mt-5"},[_vm._v(" $ ")]):_vm._e(),_c('AppInputV3',{staticClass:"input-content",attrs:{"label":_vm.setStatusLabel,"explanationText":_vm.statusZeroExplanationText,"placeholder":_vm.setStatusLabel,"invalid":_vm.requiredErrorsData.statusZeroError,"errorMessage":_vm.$i18n.t('generic.validations.fieldIsRequired'),"disabled":_vm.disabledStatus(_vm.statusZero),"type":_vm.statusInputType,"step":_vm.step,"data-test-id":"statusZero"},on:{"change":function($event){_vm.onchange();
                        _vm.onStatusZeroInput();}},model:{value:(_vm.statusZero),callback:function ($$v) {_vm.statusZero=$$v},expression:"statusZero"}})],1)]),_c('div',{staticClass:"input-block"},[(_vm.isQuota)?[_c('div',{staticClass:"input-title"},[_vm._v(" "+_vm._s(_vm.$i18n.t('charging.policyCounters.threshold'))+" ")]),_c('UnitPicker',{staticClass:"input-content",attrs:{"searchable":false,"error":_vm.requiredErrorsData.thresholdError,"errorMessage":_vm.$i18n.t('generic.validations.fieldIsRequired'),"isEditorDesign":true,"type":_vm.definitions,"disabled":_vm.disabled,"data-test-id":"threshold-quota"},on:{"input":function($event){_vm.onchange();
                        _vm.onThresholdInput($event);}},model:{value:(_vm.threshold),callback:function ($$v) {_vm.threshold=$$v},expression:"threshold"}})]:_vm._e(),(_vm.isPercentage)?[_c('div',{staticClass:"flex-container"},[_c('AppInputV3',{staticClass:"input-content",attrs:{"explanationText":_vm.$i18n.t('charging.policyCounters.lessThan100'),"placeholder":_vm.$i18n.t('charging.policyCounters.addTP'),"label":_vm.$i18n.t('charging.policyCounters.thresholdPercentage'),"type":'number',"invalid":_vm.requiredErrorsData.thresholdError,"errorMessage":_vm.$i18n.t('charging.policyCounters.reqAndLim'),"disabled":_vm.disabled,"data-test-id":"threshold-percentage"},on:{"change":function($event){_vm.onchange();
                            _vm.onThresholdInput($event);}},model:{value:(_vm.threshold),callback:function ($$v) {_vm.threshold=$$v},expression:"threshold"}})],1)]:_vm._e()],2),_c('div',{staticClass:"input-block"},[_c('div',{staticClass:"d-flex align-items-center ml-5"},[(_vm.showCurrency)?_c('div',{staticClass:"inline-text mt-5"},[_vm._v(" $ ")]):_vm._e(),_c('AppInputV3',{staticClass:"input-content",class:[{ 'status-one': _vm.isQuota }, { 'show-currency': _vm.showCurrency }],attrs:{"explanationText":_vm.disabledStatusExplanationText(_vm.statusOne),"label":_vm.setStatusLabel,"placeholder":_vm.setStatusLabel,"invalid":_vm.requiredErrorsData.statusOneError,"errorMessage":_vm.$i18n.t('generic.validations.fieldIsRequired'),"disabled":_vm.disabledStatus(_vm.statusOne),"type":_vm.statusInputType,"step":_vm.step,"data-test-id":"statusOne"},on:{"change":function($event){_vm.onchange();
                        _vm.onStatusOneInput();}},model:{value:(_vm.statusOne),callback:function ($$v) {_vm.statusOne=$$v},expression:"statusOne"}})],1)])]):_vm._e(),_vm._l((_vm.thresholdsData),function(el,index){return _c('div',{key:index},[_c('div',{staticClass:"flex-container"},[_c('div',{staticClass:"input-block mt-2"},[(_vm.isQuota)?[_c('div',{staticClass:"input-title"},[_vm._v(" "+_vm._s(_vm.$i18n.t('charging.policyCounters.threshold'))+" ")]),_c('UnitPicker',{staticClass:"input-content",attrs:{"searchable":false,"error":_vm.requiredErrorsData.thresholdsError[index],"errorMessage":_vm.$i18n.t('charging.policyCounters.reqAndBig'),"isEditorDesign":true,"type":_vm.definitions,"disabled":_vm.disabled,"data-test-id":`threshold-quota-${index}`},on:{"input":function($event){_vm.onchange();
                            _vm.onThresholdsInput(index);}},model:{value:(_vm.thresholdsData[index]),callback:function ($$v) {_vm.$set(_vm.thresholdsData, index, $$v)},expression:"thresholdsData[index]"}})]:_vm._e(),(_vm.isPercentage)?[_c('div',{staticClass:"flex-container"},[_c('AppInputV3',{staticClass:"input-content",attrs:{"explanationText":_vm.$i18n.t('charging.policyCounters.lessThan100'),"placeholder":_vm.$i18n.t('charging.policyCounters.addTP'),"label":_vm.$i18n.t('charging.policyCounters.thresholdPercentage'),"type":'number',"invalid":_vm.requiredErrorsData.thresholdsError[index],"errorMessage":_vm.$i18n.t('charging.policyCounters.reqAndBig'),"disabled":_vm.disabled,"data-test-id":`threshold-percentage-${index}`},on:{"change":function($event){_vm.onchange();
                                _vm.onThresholdsInput(index);}},model:{value:(_vm.thresholdsData[index]),callback:function ($$v) {_vm.$set(_vm.thresholdsData, index, $$v)},expression:"thresholdsData[index]"}})],1)]:_vm._e()],2),_c('div',{staticClass:"input-block mt-2"},[_c('div',{staticClass:"d-flex align-items-center"},[(_vm.showCurrency)?_c('div',{staticClass:"inline-text ml-5 mt-5"},[_vm._v(" $ ")]):_vm._e(),_c('AppInputV3',{staticClass:"input-content",class:[{ 'status-one': _vm.isQuota }, { 'show-currency': _vm.showCurrency }],attrs:{"explanationText":_vm.disabledStatusExplanationText(_vm.statusesData[index]),"label":_vm.setStatusLabel,"placeholder":_vm.setStatusLabel,"invalid":_vm.requiredErrorsData.statusesError[index],"errorMessage":_vm.$i18n.t('generic.validations.fieldIsRequired'),"disabled":_vm.disabledStatus(_vm.statusesData[index]),"type":_vm.statusInputType,"step":_vm.step,"data-test-id":`status-${index}`},on:{"change":function($event){_vm.onchange();
                            _vm.onStatusesInput(index);}},model:{value:(_vm.statusesData[index]),callback:function ($$v) {_vm.$set(_vm.statusesData, index, $$v)},expression:"statusesData[index]"}})],1)]),(!_vm.disabled)?_c('IconButton',{staticClass:"delete-btn",attrs:{"label":_vm.$i18n.t('generic.delete'),"icon":_vm.ICON_TYPES.DELETE,"data-test-id":`delete-threshold-${index}`},on:{"iconClick":function($event){return _vm.deleteInput(_vm.entity)}}}):_vm._e()],1)])}),(!_vm.isEmpty)?_c('AppButton',{staticClass:"ml-2 mt-3",attrs:{"label":_vm.$i18n.t('charging.policyCounters.newThreshold'),"iconType":_vm.ICON_TYPES.PLUS,"disabled":_vm.disableAdd || _vm.disabled,"isSmall":true,"data-test-id":"new-threshold"},on:{"click":_vm.addInput}}):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }