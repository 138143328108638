<script>
import { mapActions, mapGetters } from 'vuex';

export const sectionKeys = {
    DELIVERIES: 'deliveries',
    ORDER_DETAILS: 'orderDetails',
};

export default {
    name: 'AccountSidebarMixin',
    data() {
        return {
            sectionKeys,
        };
    },
    computed: {
        ...mapGetters('userManagementAccount', ['isSidebarOpen', 'getAdditionalSidebarStateBySectionKey']),
        sidebarState() {
            return this.isSidebarOpen;
        },
    },
    beforeDestroy() {
        this.resetAdditionalSidebarState();
    },
    methods: {
        ...mapActions('userManagementAccount', ['toggleAdditionalSidebarState', 'resetAdditionalSidebarState']),
        getAdditionalSidebarState(sectionId) {
            return this.getAdditionalSidebarStateBySectionKey(sectionId);
        },
        async toggleModal(value, sectionId) {
            await this.toggleAdditionalSidebarState({ sectionId, value });
        },
    },
};
</script>

<style lang="scss">
.shrink-width {
    width: calc(100% - 40rem);
    transition: ease-in-out;
}
</style>
