import { render, staticRenderFns } from "./PurchaseLimit.vue?vue&type=template&id=4d151cd9&scoped=true"
import script from "./PurchaseLimit.vue?vue&type=script&lang=ts"
export * from "./PurchaseLimit.vue?vue&type=script&lang=ts"
import style0 from "./PurchaseLimit.vue?vue&type=style&index=0&id=4d151cd9&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4d151cd9",
  null
  
)

export default component.exports