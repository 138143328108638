import { DnoResponseBase } from '@/http';
import { AxiosPromise } from 'axios';

export async function manageEntityAdd(
    saveEntityDraft: () => AxiosPromise<any>,
    publishEntity: (id: string) => AxiosPromise<DnoResponseBase>,
    isPublishing: boolean,
): Promise<void> {
    if (isPublishing) {
        const res = await saveEntityDraft();
        await publishEntity(res?.data?.id);
    } else {
        await saveEntityDraft();
    }
}
export async function manageEntityUpdate(
    saveEntityDraft: () => AxiosPromise<unknown>,
    publishEntity: () => AxiosPromise<unknown>,
    isPublishing: boolean,
): Promise<void> {
    if (isPublishing) {
        await saveEntityDraft();
        await publishEntity();
    } else {
        await saveEntityDraft();
    }
}

export default {
    manageEntityAdd,
    manageEntityUpdate,
};
