/**
 * The API types currently supported by Device Line Authorization.
 * Enum values here are recognized by the DNO.
 */
export enum DEVICE_LINE_AUTH_API_TYPE {
    QOD = 1,
    DEVICE_STATUS = 2,
    SIM_SWAP = 3,
    LOCATION_VERIFICATION = 4,
    SD_WAN = 5,
}
