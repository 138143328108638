import { thresholdCalculationTypesMapper } from '@/__new__/services/dno/charging/common/chargingActionsHelper';

export default class ActionTrigger {
    triggerType;

    ucId;

    ucThreshold;

    thresholdCalcType;

    notificationPercentage;

    type;

    constructor({
        trigger_type: triggerType = null,
        usage_counter_id: ucId = null,
        total_threshold: ucThreshold = 0,
        usage_counter_threshold_percentage: notificationPercentage = 100,
    } = {}) {
        this.triggerType = triggerType;
        this.ucId = ucId;
        this.ucThreshold = ucThreshold;
        this.notificationPercentage = notificationPercentage;
        this.type = null;
        this.thresholdCalcType = thresholdCalculationTypesMapper[triggerType];
    }
}
