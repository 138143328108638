
import Vue from 'vue';
import moment from 'moment';

import DateTimePicker from '@/components/partials/inputs/DateTimePicker.vue';

export default Vue.extend({
    name: 'OrdersEndTime',
    components: {
        DateTimePicker,
    },
    props: {
        value: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            endTime: null as Date | null,
        };
    },
    created() {
        this.endTime = moment(this.value * 1000).toDate();
    },
    methods: {
        onEndTimeChange(endTime: Date): void {
            this.$emit('input', moment(endTime).unix());
        },
    },
});
