import { AxiosPromise } from 'axios';
import http, { DnoResponseBase, Entity } from '@/http/index';

type ProductData = {
    name: string;
    description: string;
    relationships?: any[];
} & Record<string, any>;

type GetProductsResponseBody = DnoResponseBase & {
    product_by_id: Record<string, Entity<ProductData>>;
};

/**
 * Get products.
 *
 * @returns {AxiosPromise}
 */
export function getProducts(ids: string[] = []): AxiosPromise<GetProductsResponseBody> {
    let data = {
        ids: [] as string[],
    };
    if (ids.length) {
        data = {
            ids,
        };
    }
    return http({
        method: 'POST',
        url: '/v3productcatalog/getproducts',
        data,
    });
}

export type AddProductRequestBody = {
    data: ProductData;
    template_id: string;
    entity_id?: string;
};

/**
 * Add product.
 *
 * @returns {AxiosPromise}
 */
export function addProduct(data: AddProductRequestBody): AxiosPromise<DnoResponseBase> {
    return http({
        method: 'POST',
        url: '/v3productcatalogwrite/addproduct',
        data,
    });
}

/**
 * Update product.
 *
 * @returns {AxiosPromise}
 */
export function updateProduct(id: string, version: number, data: ProductData): AxiosPromise<DnoResponseBase> {
    return http({
        method: 'POST',
        url: '/v3productcatalogwrite/updateproduct',
        data: {
            id,
            version,
            data,
        },
    });
}

/**
 * Update product state.
 *
 * @returns {AxiosPromise}
 */
export function updateProductState(id: string, version: number, state: number): AxiosPromise<DnoResponseBase> {
    return http({
        method: 'POST',
        url: '/v3productcatalogwrite/updateproductstate',
        data: {
            id,
            version,
            state,
        },
    });
}

/**
 * Get product draft list.
 *
 * @returns {AxiosPromise}
 */
export function getProductDraftList() {
    return http({
        method: 'POST',
        url: '/v3productcatalog/getproductdraftlist',
    });
}

/**
 * Delete product draft.
 *
 * @returns {AxiosPromise}
 */
export function deleteProductDraft(id: string) {
    return http({
        method: 'POST',
        url: '/v3productcatalog/productdraftdelete',
        data: {
            id,
        },
    });
}

/**
 * Get product draft.
 *
 * @returns {AxiosPromise}
 */
export function getProductDraft(id: string) {
    return http({
        method: 'POST',
        url: '/v3productcatalog/getproductdraft',
        data: {
            id,
        },
    });
}

/**
 * Set product draft.
 *
 * @returns {AxiosPromise}
 */
type ProductDraftPayload = {
    id?: string;
    data: object;
    template_id: string;
};

export function setProductDraft(data: ProductDraftPayload) {
    return http({
        method: 'POST',
        url: '/v3productcatalogwrite/setproductdraft',
        data,
    });
}

export default {
    getProducts,
};
