<template>
    <div
        v-if="filterTableMixin.allFilters.length"
        class="filters-tags"
    >
        <Tag
            v-for="(filter, index) in filterTableMixin.allFilters"
            :key="index"
            :label="`${filter.column.name} ` + `${$t(filter.condition.i18nLabel)} ` + `${computedFilterValue(filter)}`"
            @closeIconClick="removeFilter(index)"
        />
        <span
            v-if="filterTableMixin.allFilters.length > 1"
            v-t="'generic.clearAll'"
            class="clear-all-filters ml-4"
            @click="removeAllFilters"
        />
    </div>
</template>

<script>
import Tag from '@/components/partials/inputs/Tag.vue';

export default {
    name: 'TableFiltersTags',
    components: {
        Tag,
    },
    props: {
        filterTableMixin: {
            type: Object,
            default: () => ({
                allFilters: [],
            }),
        },
    },
    methods: {
        removeFilter(index) {
            this.$emit('removeFilter', index);
        },
        removeAllFilters() {
            this.$emit('removeAllFilters');
        },
        computedFilterValue(filter) {
            return filter.condition.getDisplayValue
                ? filter.condition.getDisplayValue(filter.values)
                : filter.values.value;
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/filter-table';
</style>
