<template>
    <div data-test-id="group-wrap">
        <div class="mb-3">
            <AppTooltipInline
                :small="true"
                :tooltipOffset="30"
                :label="text"
                :explanationText="tipsText"
            />
            <div class="underline-blue">
                <div
                    v-if="showPointer"
                    class="pointer"
                    style="background: white"
                ></div>
            </div>
        </div>
    </div>
</template>

<script>
import AppTooltipInline from '@/components/partials/inputs/AppTooltipInline.vue';

export default {
    name: 'Divider',
    components: {
        AppTooltipInline,
    },
    props: {
        text: {
            type: String,
            default: '',
        },
        showPointer: {
            type: Boolean,
            default: true,
        },
        tipsText: {
            type: String,
            default: '',
        },
    },
};
</script>

<style lang="scss" scoped>
.underline-blue {
    position: relative;
    border-bottom: solid 0.0625rem rgba(51, 81, 149, 0.15);
}

.pointer {
    position: absolute;
    bottom: -5px;
    left: 1.875rem;
    border-top: solid 0.0625rem rgba(51, 81, 149, 0.15);
    border-left: solid 0.0625rem rgba(51, 81, 149, 0.15);
    border-radius: 0.125rem 0 100% 0;
    height: 0.625rem;
    width: 0.625rem;
    transform: rotate(45deg);
}
</style>
