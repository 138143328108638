
import Vue, { PropType } from 'vue';

// Components
import AppButton from '@/components/partials/inputs/AppButton.vue';
import Thresholds from '@/__new__/features/charging/Thresholds.vue';
import AppCustomerHeader from '@/components/partials/AppCustomerHeader.vue';

// Helpers
import { ICON_TYPES, ICON_COLORS } from '@/common/iconHelper';
import { PolicyCounterConstructorArgs } from '@/__new__/services/dno/charging/models/PolicyCounter';
import { thresholdTypes, TYPES_OPTIONS } from '@/__new__/services/dno/charging/common/policyCounterHelper';
import { unitTypes } from '@/common/formatting';

export default Vue.extend({
    name: 'BucketPolicyCountersData',
    components: {
        AppButton,
        Thresholds,
        AppCustomerHeader,
    },
    props: {
        /* eslint-disable camelcase */
        value: {
            type: Object as PropType<
                PolicyCounterConstructorArgs & {
                    policy_counter_id: string;
                    current_status: string;
                }
            >,
            required: true,
        },
    },
    data() {
        return {
            ICON_TYPES,
            ICON_COLORS,
            unitTypes,
            collapseSection: true,
            statuses: [] as string[],
            thresholds: [] as number[],
            requiredErrors: {
                statusZeroError: false as boolean,
                statusOneError: false as boolean,
                statusesError: [] as number[],
                thresholdError: false as boolean,
                thresholdsError: [] as number[],
                usageCounterError: false as boolean,
            },
        };
    },
    computed: {
        getPolicyCounterThresholdType() {
            return this.value.thresholds_percentage
                ? thresholdTypes[TYPES_OPTIONS.PERCENTAGE].value
                : thresholdTypes[TYPES_OPTIONS.QUOTA].value;
        },
        infoData() {
            return [
                {
                    title: this.$i18n.t('customerCare.basicInfo'),
                    initiallyExpanded: true,
                    value: [
                        {
                            name: this.$i18n.t('charging.policyRules.pc_id'),
                            value: this.value.policy_counter_id,
                        },
                        {
                            name: this.$i18n.t('charging.policyRules.pc_name'),
                            value: this.value.name,
                        },
                        {
                            name: this.$i18n.t('generic.status'),
                            value: this.value.current_status,
                        },
                    ],
                },
            ];
        },
        isThresholdPercentage(): boolean {
            return Boolean(this.value?.thresholds_percentage);
        },
        pcHasError(): boolean {
            return this.validatedStatuses() || this.validatedThresholds();
        },
    },
    created() {
        this.statuses = this.value.statuses;
        this.thresholds = this.value.thresholds_percentage
            ? [...this.value.thresholds_percentage]
            : [...this.value.thresholds];
    },
    methods: {
        updateThresholds({ statuses, thresholds }: any): void {
            this.statuses = statuses;
            this.thresholds = thresholds;
            this.emitOnChange();
        },
        updateThresholdErrors(errors: any): void {
            this.requiredErrors = {
                ...this.requiredErrors,
                ...errors,
            };
        },
        validatedStatuses(): boolean {
            if (this.statuses.length <= 0) {
                this.$set(this.requiredErrors, 'statusZeroError', true);
                this.$set(this.requiredErrors, 'statusOneError', true);
                return true;
            }
            return this.statuses.some((status: string, i: number) => {
                if (status === '') {
                    if (i === 0) {
                        this.$set(this.requiredErrors, 'statusZeroError', true);
                    } else if (i === 1) {
                        this.requiredErrors.statusOneError = true;
                        this.$set(this.requiredErrors, 'statusOneError', true);
                    } else {
                        this.$set(this.requiredErrors.statusesError, i - 2, true);
                    }

                    return true;
                }

                return false;
            });
        },
        validatedThresholds(): boolean {
            if (this.thresholds.length <= 0) {
                this.$set(this.requiredErrors, 'thresholdError', true);
                return true;
            }
            return this.thresholds.some((threshold: number, i: number) => {
                if (threshold === 0) {
                    if (i === 0) {
                        this.$set(this.requiredErrors, 'thresholdError', true);
                    } else {
                        this.$set(this.requiredErrors.thresholdsError, i - 1, true);
                    }

                    return true;
                }

                return false;
            });
        },
        emitOnChange(): void {
            if (this.pcHasError) {
                this.$emit('change', null);
                return;
            }

            const thresholdKey = this.isThresholdPercentage ? 'thresholds_percentage' : 'thresholds';
            this.$emit('change', {
                policy_counter_id: this.value.policy_counter_id,
                statuses: this.statuses,
                [thresholdKey]: this.thresholds,
            });
        },
        toggleCollapseSection(): void {
            this.collapseSection = !this.collapseSection;
        },
    },
});
