
import AppProgressBar from '@/components/partials/AppProgressBar.vue';

export default {
    name: 'ViasatMonthlyUsage',
    components: {
        AppProgressBar,
    },
    props: {
        currentPlan: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            unlimitedLabel: `${this.$i18n.t('generic.unlimitedCapital')} / ${this.$i18n.t('generic.unlimitedCapital')}`,
        };
    },
    computed: {
        dataAmount() {
            const planValue = this.currentPlan?.entity_name?.match(/(\d*)\sGB/)?.[1];
            return planValue ? Number(planValue) : 60;
        },
    },
};
