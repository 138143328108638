<template>
    <div class="cohort-expression">
        <AppMultiselectV3
            v-model="cohortsObj.applicableCohorts"
            data-test-id="cohort-whitelist-select"
            :options="whiteCohortGroupsOptions"
            groupLabel="groupName"
            groupValues="groupValues"
            :showLabels="false"
            :multiple="true"
            :additionalLabel="$i18n.t('editors.applicableTo')"
            :placeholder="$i18n.t('generic.cohorts.chooseCohortGroup')"
            :small="true"
            label="name"
            trackBy="id"
            class="mb-2"
            :style="{ width: `${inputsWidth}%` }"
            :disabled="disabled"
            @input="onInput"
        />

        <AppToggle
            v-model="cohortsObj.everyApplicableCohort"
            data-test-id="cohort-whitelist-logical"
            :label="$i18n.t('editors.identifiersInEverySegment')"
            :small="true"
            class="mb-3"
            :disabled="disabled"
            @input="onInput"
        />

        <AppMultiselectV3
            v-model="cohortsObj.notApplicableCohorts"
            data-test-id="cohort-blacklist-select"
            :options="blackCohortGroupsOptions"
            groupLabel="groupName"
            groupValues="groupValues"
            :showLabels="false"
            :multiple="true"
            :additionalLabel="$i18n.t('editors.notApplicableTo')"
            :placeholder="$i18n.t('generic.cohorts.chooseCohortGroup')"
            :small="true"
            label="name"
            trackBy="id"
            class="mb-2"
            :style="{ width: `${inputsWidth}%` }"
            :disabled="disabled"
            @input="onInput"
        />

        <AppToggle
            v-model="cohortsObj.everyNotApplicableCohort"
            data-test-id="cohort-blacklist-logical"
            :label="$i18n.t('editors.identifiersInEverySegment')"
            :explanationText="`${$i18n.t('editors.subscriberHasBothSegments')}
                    ${$i18n.tc('productCatalog.entities.offer').toLowerCase()}.`"
            :small="true"
            :tooltipOffset="tooltipOffset"
            :disabled="disabled"
            class="mb-3"
            @input="onInput"
        />
    </div>
</template>

<script>
// Components
import AppMultiselectV3 from '@/components/partials/inputs/AppMultiselectV3.vue';
import AppToggle from '@/components/partials/inputs/AppToggle.vue';

// Vuex
import { Modules } from '@/store/store';
import { Getters } from '@/store/mutation-types';

// Helpers
import { MessageTypes } from '@/common/CampaignMessage';
import { cohortExpressionsToJson, cohortExpressionsFromJson } from '@/common/cohortExpressionHelper';

export default {
    name: 'CohortExpressionForFormBuilder',
    components: {
        AppMultiselectV3,
        AppToggle,
    },
    props: {
        disabled: {
            type: Boolean,
            default: false,
        },
        value: {
            type: Object,
            default: null,
        },
        inputsWidth: {
            type: Number,
            default: 60,
            validator: val => val >= 0 && val <= 100,
        },
        tooltipOffset: {
            type: Number,
            default: 20,
            validator: val => val >= 0 && val <= 100,
        },
    },
    data() {
        return {
            MessageTypes,
            cohortsObj: {
                applicableCohorts: [],
                notApplicableCohorts: [],
                everyApplicableCohort: false,
                everyNotApplicableCohort: false,
            },
        };
    },
    computed: {
        segments() {
            return this.$store.getters[`${Modules.segments}/${Getters.CACHED_SEGMENTS}`];
        },
        whiteCohortGroupsOptions() {
            return this.$store.getters[`${Modules.segments}/${Getters.GROUPED_SEGMENTS_BY_ID_TYPE}`];
        },
        blackCohortGroupsOptions() {
            return this.whiteCohortGroupsOptions;
        },
    },
    created() {
        if (this.value) {
            this.cohortsObj = cohortExpressionsFromJson(
                this.value,
                this.whiteCohortGroupsOptions.map(group => group.groupValues).flat(),
            );
        }
    },
    methods: {
        onInput() {
            if (this.cohortsObj.applicableCohorts.length || this.cohortsObj.notApplicableCohorts.length) {
                this.$emit('input', cohortExpressionsToJson(this.cohortsObj));
            } else {
                this.$emit('input', null);
            }
        },
    },
};
</script>
