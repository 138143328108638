
import Vue, { PropType } from 'vue';

// Components
import AppInputV3 from '@/components/partials/inputs/AppInputV3.vue';
import Tags from '@/components/partials/inputs/Tags.vue';

// Helppers
import { GetExecutionDetailsSearchParameters } from '@/__new__/services/dno/orchestrationengine/batchActions';

export default Vue.extend({
    name: 'GetExecutionDetails',
    components: {
        AppInputV3,
        Tags,
    },
    props: {
        value: {
            type: Object as PropType<GetExecutionDetailsSearchParameters>,
            default: () => ({}),
        },
        validation: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {
            currentParameter: '' as string,
        };
    },
    methods: {
        updateCurrentParameter(): void {
            this.value.parameters.push(this.currentParameter);
            this.currentParameter = '';
        },
    },
});
