import { CDP_PROPERTY_TYPE } from '@/__new__/services/dno/events/models/EventProp';

export class SinkFormatter {
    name: string;
    options: string[];
    conversionType: CDP_PROPERTY_TYPE;
    value: string;
    columnType: CDP_PROPERTY_TYPE;

    constructor(data: Partial<SinkFormatter> = {}) {
        this.name = data.name || '';
        this.options = data.options || [];
        this.value = data.value || '';
        this.conversionType = data.conversionType as CDP_PROPERTY_TYPE;
        this.columnType = data.columnType as CDP_PROPERTY_TYPE;
    }
}
export function sinkFormattersFromJson(response: any): SinkFormatter[] {
    const { formatters } = response.data;
    return (Object.keys(formatters) as CDP_PROPERTY_TYPE[])
        .map(formatterType =>
            formatters[formatterType].map(
                (formatter: any) =>
                    new SinkFormatter({
                        name: formatter.name,
                        options: formatter.params,
                        conversionType: formatter.final_field_type,
                        value: formatter.params?.[0],
                        columnType: formatterType,
                    }),
            ),
        )
        .flat();
}
export default SinkFormatter;
