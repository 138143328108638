import Vue from 'vue';
import keyBy from 'lodash/keyBy';
import Actions, { Mutations, State, Getters } from '@/store/mutation-types';
import { getTemplates, getAllTemplates } from '@/__new__/services/dno/pc/http/templates';
import { getEntities, getEntityDrafts } from '@/__new__/services/dno/pc/entities';

import { ProductCatalogEntityTemplate } from '@/__new__/services/dno/pc/models/ProductCatalogEntityTemplate';
import { ProductCatalogEntityBaseModel } from '@/__new__/services/dno/pc/models/ProductCatalogEntityBase';
import { ENTITIES_TYPES_TO_MODEL_MAP } from '@/__new__/features/pc/common/entitiesTypesToModel';
import { EntityStateMapping } from '@/common/commonEntityStateMapper';
import { TEMPLATE_STATES_NUMERIC } from '@/__new__/services/dno/pc/models/templateStates';
import { checkForUnpublishedChanges, STATUS_CODES } from '@/common/commonHelper';

import { isEmpty } from 'lodash';
import { TEMPLATE_TYPES_MAP } from '@/__new__/services/dno/pc/models/templateTypes';
import permissionsService from '@/services/permissions/permissions.service';
import { getConfig } from '@/__new__/services/dno/pc/http/catalog';

const productCatalogStore = {
    namespaced: true,
    state: () => ({
        // map, where key is Template ID and value is TEMPLATE data
        [State.PC_TEMPLATES_BY_IDS]: {},
        // map, where key is entity type and value is TEMPLATES Map whih key is entity ID and value is TEMPLATE data
        [State.PC_TEMPLATES_BY_TYPE]: {},
        [State.PC_TEMPLATES_API_RESPONSE_BY_TYPE]: {},
        [State.PC_ALL_TEMPLATES_API_RESPONSE]: {},

        // map, where key is entity type and value is ENTITIES Map whih key is entity ID and value is ENTITY data
        [State.PC_ENTITIES_BY_TYPE]: {},
        [State.PC_ENTITIES_API_RESPONSE_BY_TYPE]: {},
        [State.PC_BULK_EDIT_ENTITY_IDS_BY_TYPE]: {},
        [State.PC_BULK_EDIT_CHANGE_DATA]: {},
        [State.PC_BULK_EDIT_CHANGE_COMPLETED_STATUS]: {},
    }),
    mutations: {
        // TEMPLATES
        [Mutations.PC_SET_TEMPLATES]: (state, { entityType, templatesMap }) => {
            Vue.set(state[State.PC_TEMPLATES_BY_TYPE], entityType, templatesMap);
        },
        [Mutations.PC_SET_TEMPLATES_API_RESPONSE]: (state, { entityType, apiResponse }) => {
            Vue.set(state[State.PC_TEMPLATES_API_RESPONSE_BY_TYPE], entityType, apiResponse);
        },
        [Mutations.PC_SET_ALL_TEMPLATES_API_RESPONSE]: (state, apiResponse) => {
            Vue.set(state, State.PC_ALL_TEMPLATES_API_RESPONSE, apiResponse);
        },
        [Mutations.PC_SET_TEMPLATES_BY_IDS]: (state, { templatesMap }) => {
            Vue.set(state, State.PC_TEMPLATES_BY_IDS, templatesMap);
        },
        // ENTITIES
        [Mutations.PC_SET_ENTITIES]: (state, { entityType, entitiesMap }) => {
            Vue.set(state[State.PC_ENTITIES_BY_TYPE], entityType, entitiesMap);
        },
        [Mutations.PC_SET_ENTITIES_API_RESPONSE]: (state, { entityType, apiResponse }) => {
            Vue.set(state[State.PC_ENTITIES_API_RESPONSE_BY_TYPE], entityType, apiResponse);
        },
        [Mutations.PC_SET_BULK_ENTITY_IDS]: (state, { entityType, entityIds }) => {
            Vue.set(state[State.PC_BULK_EDIT_ENTITY_IDS_BY_TYPE], entityType, entityIds);
        },
        [Mutations.PC_SET_BULK_EDIT_CHANGE_DATA]: (state, { entityType, changeData }) => {
            Vue.set(state[State.PC_BULK_EDIT_CHANGE_DATA], entityType, changeData);
        },
        [Mutations.PC_SET_BULK_EDIT_CHANGE_COMPLETED_STATUS]: (state, { entityType, changeStatusData }) => {
            Vue.set(state[State.PC_BULK_EDIT_CHANGE_COMPLETED_STATUS], entityType, changeStatusData);
        },
    },
    actions: {
        // TEMPLATES
        async [Actions.PC_REQUEST_TEMPLATES_BY_TYPE_AND_IDS]({ commit }, config) {
            // Fetch data
            let response;
            try {
                response = await getTemplates(config.entityType, config?.templatesIds || null);
                commit(Mutations.PC_SET_TEMPLATES_API_RESPONSE, {
                    entityType: config.entityType,
                    apiResponse: response,
                });
            } catch (error) {
                commit(Mutations.PC_SET_TEMPLATES_API_RESPONSE, {
                    entityType: config.entityType,
                    apiResponse: error.response,
                });
                throw error; // rethrow error as existing code expects an error on http call failure
            }

            let templatesArr = [];
            // Get external ids of internal preset templates
            const configResponse = await getConfig();
            const externalIdsOfInternalTemplates = configResponse?.data?.catalog_config?.preset_template_index ?? [];

            templatesArr = response?.data?.data.map(templateJson => {
                // Determine if template is internal
                // Right now only preset templates can be internal, and their external_id must be in the list provided by PC config
                const isInternal =
                    templateJson.type === TEMPLATE_TYPES_MAP.PRESET &&
                    externalIdsOfInternalTemplates.includes(templateJson.external_id);
                return new ProductCatalogEntityTemplate(templateJson, isInternal);
            });
            const templateList = {};

            templatesArr.forEach(template => {
                if (!templateList[template.entityType]) {
                    templateList[template.entityType] = [];
                }

                templateList[template.entityType].push(template);
            });

            Object.entries(templateList).forEach(([key, value]) => {
                commit(Mutations.PC_SET_TEMPLATES, { entityType: key, templatesMap: keyBy(value, 'id') });
            });

            commit(Mutations.PC_SET_TEMPLATES_BY_IDS, { templatesMap: keyBy(templatesArr, 'id') });
        },
        // ALL TEMPLATES
        async [Actions.PC_REQUEST_ALL_TEMPLATES]({ commit }, types = []) {
            // Fetch data
            let response;
            try {
                response = await getAllTemplates(types);
                commit(Mutations.PC_SET_ALL_TEMPLATES_API_RESPONSE, response);
            } catch (error) {
                commit(Mutations.PC_SET_ALL_TEMPLATES_API_RESPONSE, error.response);
                throw error; // rethrow error as existing code expects an error on http call failure
            }
            const templatesArr = response?.data?.data.map(
                templateJson => new ProductCatalogEntityTemplate(templateJson),
            );
            const templateList = {};

            templatesArr.forEach(template => {
                if (!templateList[template.entityType]) {
                    templateList[template.entityType] = [];
                }

                templateList[template.entityType].push(template);
            });

            Object.entries(templateList).forEach(([key, value]) => {
                commit(Mutations.PC_SET_TEMPLATES, { entityType: key, templatesMap: keyBy(value, 'id') });
            });

            commit(Mutations.PC_SET_TEMPLATES_BY_IDS, { templatesMap: keyBy(templatesArr, 'id') });
        },
        // ENTITIES
        async [Actions.PC_REQUEST_ENTITIES_BY_TYPE_AND_IDS](
            { commit },
            { entityType, entitiesIds, includeDrafts = true },
        ) {
            // Fetch data
            let response;
            let draftResponse;
            try {
                response = await getEntities(entityType, entitiesIds);
                commit(Mutations.PC_SET_ENTITIES_API_RESPONSE, { entityType, apiResponse: response });
                if (includeDrafts) {
                    draftResponse = await getEntityDrafts(entityType);
                }
            } catch (error) {
                commit(Mutations.PC_SET_ENTITIES_API_RESPONSE, { entityType, apiResponse: error.response });
                throw error; // rethrow error as existing code expects an error on http call failure
            }

            const entities = response?.data?.[`${entityType}_by_id`] || {};
            const draftentities = draftResponse?.data?.data || {};
            let entitiesList = [];
            let draftentitiesList = [];
            // TODO: separate handling for Categories
            const EntityTypeModel = ENTITIES_TYPES_TO_MODEL_MAP.get(entityType);
            if (EntityTypeModel) {
                draftentitiesList = Object.values(draftentities).map(
                    entityJson =>
                        new EntityTypeModel({
                            ...entityJson,
                            entityType,
                            isDraft: true,
                            state: STATUS_CODES.NA,
                            entityVersion: STATUS_CODES.DRAFT,
                        }),
                );
                entitiesList = Object.values(entities).map(
                    entityJson =>
                        new EntityTypeModel({
                            ...entityJson,
                            entityType,
                            entityVersion: checkForUnpublishedChanges(entityJson, draftentitiesList),
                        }),
                );
            } else {
                draftentitiesList = Object.values(draftentities).map(
                    entityJson =>
                        new ProductCatalogEntityBaseModel({
                            ...entityJson,
                            entityType,
                            isDraft: true,
                            state: STATUS_CODES.NA,
                            entityVersion: STATUS_CODES.DRAFT,
                        }),
                );
                entitiesList = Object.values(entities).map(
                    entityJson =>
                        new ProductCatalogEntityBaseModel({
                            ...entityJson,
                            entityType,
                            entityVersion: checkForUnpublishedChanges(entityJson, draftentitiesList),
                        }),
                );
            }

            commit(Mutations.PC_SET_ENTITIES, {
                entityType,
                entitiesMap: keyBy([...draftentitiesList, ...entitiesList], 'id'),
            });
        },
    },
    getters: {
        // TEMPLATES
        [Getters.PC_GET_ALL_TEMPLATES]: state => Object.values(state[State.PC_TEMPLATES_BY_IDS]),
        [Getters.PC_GET_NOT_PRESET_TEMPLATES]: (state, getters) =>
            getters[Getters.PC_GET_ALL_TEMPLATES].filter(template => template.entityType !== TEMPLATE_TYPES_MAP.PRESET),
        [Getters.PC_GET_TEMPLATES_BY_TYPE]: state => type => state[State.PC_TEMPLATES_BY_TYPE][type] || [],
        [Getters.PC_GET_TEMPLATES_API_RESPONSE_BY_TYPE]: state => type =>
            state[State.PC_TEMPLATES_API_RESPONSE_BY_TYPE][type],
        [Getters.PC_GET_ALL_TEMPLATES_API_RESPONSE]: state => state[State.PC_ALL_TEMPLATES_API_RESPONSE],
        [Getters.PC_GET_ALL_TEMPLATES_NOT_DELETED]: (state, getters) => {
            const allTemplates = getters[Getters.PC_GET_ALL_TEMPLATES];
            return (
                Object.values(allTemplates)?.filter(template => template.state !== TEMPLATE_STATES_NUMERIC.ARCHIVED) ||
                []
            );
        },
        [Getters.PC_GET_TEMPLATES_BY_TYPE_NOT_DELETED]: (state, getters) => type => {
            const templatesByType = getters[Getters.PC_GET_TEMPLATES_BY_TYPE](type);

            return (
                Object.values(templatesByType)?.filter(
                    template => template.state !== TEMPLATE_STATES_NUMERIC.ARCHIVED,
                ) || []
            );
        },
        [Getters.PC_GET_TEMPLATES_BY_TYPE_APPROVED]: (state, getters) => type => {
            const templatesByType = getters[Getters.PC_GET_TEMPLATES_BY_TYPE](type);

            return (
                Object.values(templatesByType)?.filter(
                    template => template.state === TEMPLATE_STATES_NUMERIC.APPROVED,
                ) || []
            );
        },
        [Getters.PC_GET_TEMPLATE_BY_ID]: state => id => state[State.PC_TEMPLATES_BY_IDS][id] || {},
        [Getters.PC_GET_TEMPLATE_BY_TYPE_AND_ID]: (state, getters) => (type, id) => {
            const templatesByType = getters[Getters.PC_GET_TEMPLATES_BY_TYPE](type);

            // TODO: there might be better return value then just {}
            if (!isEmpty(templatesByType)) {
                return templatesByType[id] || {};
            }
            return {};
        },
        // ENTITIES
        [Getters.PC_GET_ENTITIES_BY_TYPE]: state => type => state[State.PC_ENTITIES_BY_TYPE][type] || {},
        [Getters.PC_GET_ENTITIES_API_RESPONSE_BY_TYPE]: state => type =>
            state[State.PC_ENTITIES_API_RESPONSE_BY_TYPE][type],
        [Getters.PC_GET_ENTITIES_BY_TYPE_NOT_DELETED]: (state, getters) => type => {
            const entitiesByType = getters[Getters.PC_GET_ENTITIES_BY_TYPE](type);

            return Object.values(entitiesByType)?.filter(entity => entity.state !== EntityStateMapping.DELETED) || [];
        },
        [Getters.PC_GET_ENTITIES_BY_TYPE_APPROVED]: (state, getters) => type => {
            const entitiesByType = getters[Getters.PC_GET_ENTITIES_BY_TYPE](type);

            return Object.values(entitiesByType)?.filter(entity => entity.state === EntityStateMapping.APPROVED) || [];
        },
        [Getters.PC_GET_ENTITY_BY_TYPE_AND_ID]: (state, getters) => (type, id) => {
            const entitiesByType = getters[Getters.PC_GET_ENTITIES_BY_TYPE](type);
            // TODO: there might be better return value then just {}
            if (entitiesByType) {
                return entitiesByType[id] || {};
            }
            return {};
        },
        [Getters.PC_GET_BULK_EDIT_ENTITY_IDS_BY_TYPE]: state => type =>
            state[State.PC_BULK_EDIT_ENTITY_IDS_BY_TYPE][type] || [],
        [Getters.PC_GET_BULK_EDIT_CHANGE_DATA]: state => type => state[State.PC_BULK_EDIT_CHANGE_DATA][type] || [],
        [Getters.PC_GET_BULK_EDIT_CHANGE_COMPLETED_STATUS]: state => type =>
            state[State.PC_BULK_EDIT_CHANGE_COMPLETED_STATUS][type] || [],
    },
};

export default productCatalogStore;
