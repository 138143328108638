import i18n from '@/i18n';
import { TranslateResult } from 'vue-i18n';

export enum TRIGGER_TYPES {
    US_THRESHOLD = 1,
    THRESHOLD_PERCENTAGE = 3,
}

export interface TriggerOptionsObject {
    id: TRIGGER_TYPES;
    value: string;
    label: TranslateResult;
}

export type TriggerOptions = {
    [T in TRIGGER_TYPES]: TriggerOptionsObject;
};

export const triggerOptions: Readonly<TriggerOptions> = {
    [TRIGGER_TYPES.US_THRESHOLD]: {
        id: TRIGGER_TYPES.US_THRESHOLD,
        value: 'usageCounterThreshold',
        label: i18n.t('charging.chargingSpecifications.editor.usageCounterThreshold'),
    },
    [TRIGGER_TYPES.THRESHOLD_PERCENTAGE]: {
        id: TRIGGER_TYPES.THRESHOLD_PERCENTAGE,
        value: 'thresholdPercentage',
        label: i18n.t('charging.chargingSpecifications.editor.thresholdPercentage'),
    },
};

export enum ACTION_TYPES_OPTIONS {
    BUCKET_FLAG = 1,
    SET_SUBSCRIBER_LEVEL_FLAG = 2,
    THRESHOLD_CROSSED_NOTIFICATION = 3,
    LIMIT_REACHED_NOTIFICATION = 4,
    NOTIFICATION = 5,
}

export interface ActionTypesOptionsObject {
    id: ACTION_TYPES_OPTIONS;
    value: string;
    label: TranslateResult;
}

export type ActionTypesOptionsMapper = {
    [T in ACTION_TYPES_OPTIONS]: ActionTypesOptionsObject;
};

export const actionTypesOptionsMapper: Readonly<ActionTypesOptionsMapper> = {
    [ACTION_TYPES_OPTIONS.BUCKET_FLAG]: {
        id: ACTION_TYPES_OPTIONS.BUCKET_FLAG,
        value: 'bucketFlag',
        label: i18n.t('charging.chargingSpecifications.editor.setCsFlag'),
    },
    [ACTION_TYPES_OPTIONS.SET_SUBSCRIBER_LEVEL_FLAG]: {
        id: ACTION_TYPES_OPTIONS.SET_SUBSCRIBER_LEVEL_FLAG,
        value: 'setSubscriberLevelFlag',
        label: i18n.t('charging.chargingSpecifications.editor.setSubscriberLevelFlag'),
    },
    [ACTION_TYPES_OPTIONS.THRESHOLD_CROSSED_NOTIFICATION]: {
        id: ACTION_TYPES_OPTIONS.THRESHOLD_CROSSED_NOTIFICATION,
        value: 'thresholdCrossedNotification',
        label: i18n.t('charging.chargingSpecifications.editor.thresholdCrossedNotification'),
    },
    [ACTION_TYPES_OPTIONS.LIMIT_REACHED_NOTIFICATION]: {
        id: ACTION_TYPES_OPTIONS.LIMIT_REACHED_NOTIFICATION,
        value: 'limitReachedNotification',
        label: i18n.t('charging.chargingSpecifications.editor.limitReachedNotification'),
    },
    [ACTION_TYPES_OPTIONS.NOTIFICATION]: {
        id: ACTION_TYPES_OPTIONS.NOTIFICATION,
        value: 'notification',
        label: i18n.t('charging.chargingSpecifications.editor.notification'),
    },
};

export enum THRESHOLD_CALCULATION_TYPES {
    SET_THRESHOLD_MANUALLY = 1,
    FORMULA_CALC_THRESHOLD = 2,
}

export interface ThresholdCalculationTypeObject {
    id: THRESHOLD_CALCULATION_TYPES;
    label: TranslateResult;
}

export type ThresholdCalculationTypesMapper = {
    [T in THRESHOLD_CALCULATION_TYPES]: ThresholdCalculationTypeObject;
};

export const thresholdCalculationTypesMapper: Readonly<ThresholdCalculationTypesMapper> = {
    [THRESHOLD_CALCULATION_TYPES.SET_THRESHOLD_MANUALLY]: {
        id: THRESHOLD_CALCULATION_TYPES.SET_THRESHOLD_MANUALLY,
        label: i18n.t('charging.chargingSpecifications.editor.setThresholdManually'),
    },
    [THRESHOLD_CALCULATION_TYPES.FORMULA_CALC_THRESHOLD]: {
        id: THRESHOLD_CALCULATION_TYPES.FORMULA_CALC_THRESHOLD,
        label: i18n.t('charging.chargingSpecifications.editor.formulaCalcThreshold'),
    },
};

export default {};
