<template>
    <div class="pb-4">
        <AppButton
            class="p-0"
            :label="$i18n.t('generic.addNew')"
            :iconType="ICON_TYPES.PLUS"
            :isSmall="true"
            :disabled="disabled"
            @click="addPolicyNotification"
        />
        <AppTabsV2
            v-if="policyNotifications.length > 0"
            :sectionsCount="1"
            :tabsCount="policyNotifications.length"
            :disableSectionPaddings="true"
            :enableHeaderMargin="false"
            :relyOnInnerTabIndex="true"
            :innerTabIndex="tabIndex"
            :disabled="disabled"
            @tabSelected="tabIndex = $event"
        >
            <template
                v-for="(policyNotification, index) in policyNotifications"
                #[`tabHeader${index+1}`]
            >
                <div
                    :key="`tab-header-${index + 1}`"
                    :class="{ 'tab-header-error': isTabValid(index) }"
                >
                    {{ $i18n.t('charging.CSG.editor.notification') }} {{ index + 1 }}
                </div>
            </template>

            <template
                v-for="(policyNotification, index) in policyNotifications"
                #[`tab${index+1}_sectionContent1`]
            >
                <div
                    :key="`app_tab_${index + 1}`"
                    class="w-100"
                >
                    <div v-if="!isValuesSet(policyNotification)">
                        <AppMultiselectV3
                            v-model="policyNotification.chargingSpec"
                            class="mb-3"
                            :options="chargingSpecifications"
                            :allowEmpty="false"
                            :showLabels="false"
                            :isSmall="true"
                            :placeholder="$i18n.t('charging.chargingSpecifications.editor.selectChargingSpecification')"
                            :additionalLabel="$i18n.t('charging.chargingSpecifications.chargingSpecification')"
                            :error="errors && errors.$each[index] && errors.$each[index].chargingSpec.$error"
                            :disabled="disabled"
                            label="name"
                            trackBy="id"
                        />
                        <AppMultiselectV3
                            v-model="policyNotification.policyCounter"
                            class="mb-3"
                            :options="policyCountersFiltered(policyNotification)"
                            :allowEmpty="false"
                            :showLabels="false"
                            :isSmall="true"
                            :placeholder="$i18n.t('charging.chargingSpecifications.editor.selectPolicyCounter')"
                            :additionalLabel="$i18n.t('charging.policyCounters.policyCounter')"
                            :error="errors && errors.$each[index] && errors.$each[index].policyCounter.$error"
                            :disabled="disabled"
                            label="label"
                            trackBy="id"
                        />
                        <AppMultiselectV3
                            v-model="policyNotification.policyCounterThreshold"
                            :options="policyCounterThresholds(policyNotification)"
                            :allowEmpty="false"
                            :showLabels="false"
                            :isSmall="true"
                            :placeholder="
                                $i18n.t('charging.chargingSpecifications.editor.selectPolicyCounterThreshold')
                            "
                            :additionalLabel="$i18n.t('charging.policyCounters.policyCounterThreshold')"
                            :error="errors && errors.$each[index] && errors.$each[index].policyCounterThreshold.$error"
                            :disabled="disabled"
                            label="label"
                            trackBy="value"
                        />
                    </div>
                    <div
                        v-if="isValuesSet(policyNotification)"
                        class="policy-notification-summary my-1"
                    >
                        <hr class="horizontal-line" />
                        <div class="policy-notification-summary-data my-2">
                            <p class="lf-primary-text">
                                {{ policyNotification.chargingSpec.name }},
                                {{ policyNotification.policyCounter.label }},
                                {{ policyNotification.policyCounterThreshold.label }}
                            </p>
                            <AppButton
                                class="ml-auto"
                                :label="$i18n.t('generic.remove')"
                                :isSmall="true"
                                :disabled="disabled"
                                @click="removeNotification(index)"
                            />
                        </div>
                        <hr class="horizontal-line" />
                    </div>
                    <div v-if="isValuesSet(policyNotification)">
                        <div
                            v-for="(threshold, j) in policyNotification.thresholds"
                            :key="`threshold_select-card_${index}_${j}`"
                            class="threshold-select-card mt-3"
                        >
                            <p class="lf-labels mb-2">
                                {{ $i18n.t('charging.CSG.editor.thresholdNotifyWhen') }}
                            </p>
                            <div class="threshold-select">
                                <AppInputV3
                                    v-model="threshold.value"
                                    :invalid="isThresholdValid(index, j)"
                                    :disabled="disabled"
                                    type="number"
                                    class="threshold-value"
                                />
                                <p class="lf-all-caps ml-3">
                                    {{ $i18n.t('charging.CSG.editor.usageReached') }}
                                </p>
                            </div>
                        </div>
                        <AppButton
                            class="my-1 p-0"
                            :label="$i18n.t('charging.policyCounters.addThreshold')"
                            :iconType="ICON_TYPES.PLUS"
                            :isSmall="true"
                            :disabled="disabled"
                            @click="addThreshold(policyNotification.thresholds)"
                        />
                    </div>
                </div>
            </template>
        </AppTabsV2>
    </div>
</template>

<script>
// Components
import AppTabsV2 from '@/components/partials/AppTabsV2.vue';
import AppMultiselectV3 from '@/components/partials/inputs/AppMultiselectV3.vue';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import { ICON_TYPES } from '@/common/iconHelper';
import AppInputV3 from '@/components/partials/inputs/AppInputV3.vue';

// Helpers
import { getScaledAmount, DATA_DEFINITIONS } from '@/common/formatting';

export default {
    name: 'PolicyNotifications',
    components: {
        AppTabsV2,
        AppMultiselectV3,
        AppButton,
        AppInputV3,
    },
    props: {
        initValue: {
            type: Array,
            default: () => [],
        },
        chargingSpecifications: {
            type: Array,
            default: () => [],
        },
        policyCounters: {
            type: Array,
            default: () => [],
        },
        errors: {
            type: Object,
            default: () => undefined,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            policyNotifications: this.initValue,
            tabIndex: 1,
            ICON_TYPES,
            BUTTON_TYPES,
        };
    },
    computed: {
        disablePolicyNotificationsToggle() {
            return this.chargingSpecifications.length < 1;
        },
    },
    watch: {
        initValue(newVal) {
            this.policyNotifications = newVal;
        },
        policyNotifications(newVal) {
            this.$emit('updatePolicyNotifications', newVal);
        },
        chargingSpecifications(newVal) {
            this.policyNotifications = this.policyNotifications.filter(el =>
                newVal.some(val => val.id === el.chargingSpec.id),
            );
            if (this.tabIndex > this.policyNotifications.length) {
                this.tabIndex = this.policyNotifications.length;
            }
        },
    },
    methods: {
        addPolicyNotification() {
            this.policyNotifications.push({
                chargingSpec: undefined,
                policyCounter: undefined,
                policyCounterThreshold: undefined,
                thresholds: [],
            });
        },
        addThreshold(thresholds) {
            thresholds.push({
                value: 0,
            });
        },
        isValuesSet(policyNotification) {
            return (
                policyNotification.chargingSpec &&
                policyNotification.policyCounter &&
                policyNotification.policyCounterThreshold
            );
        },
        removeNotification(index) {
            this.policyNotifications.splice(index, 1);
        },
        policyCountersFiltered(policyNotification) {
            if (this.policyCounters && policyNotification?.chargingSpec?.policyCounters) {
                return this.policyCounters.filter(el => policyNotification.chargingSpec.policyCounters.includes(el.id));
            }
            return [];
        },
        policyCounterThresholds(policyNotification) {
            if (policyNotification?.policyCounter?.thresholds) {
                return policyNotification.policyCounter.thresholds.map(el => ({
                    value: el,
                    label: `${getScaledAmount(DATA_DEFINITIONS[0], el)}${DATA_DEFINITIONS[0].label}`,
                }));
            }
            return [];
        },
        isThresholdValid(index, thresholdIndex) {
            return this.errors?.$each?.[index]?.thresholds?.$each?.[thresholdIndex]?.$error;
        },
        isTabValid(index) {
            return this.errors?.$each?.[index]?.$error;
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/typographyV2';
@import '~@/assets/scss/palette';
@import '~@/assets/scss/layout';

.policy-notification-summary {
    display: flex;
    flex-direction: column;

    .policy-notification-summary-data {
        display: flex;
        align-items: center;
    }
}

.threshold-select-card {
    padding: $spacing-m;
    background-color: rgba($gray30, 0.15);
    border-radius: $spacing-xs;

    .threshold-select {
        display: flex;
        align-items: center;

        .threshold-value {
            width: 6rem;
        }
    }
}

.horizontal-line {
    width: 100%;
    border-bottom: 1px solid $blue15;
}

.tab-header-error {
    color: $red;
}
</style>
