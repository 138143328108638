<template>
    <div
        ref="monaco_container"
        :class="['monaco-container', { invalid, loading: !isLoaded }]"
    >
        <AppSpinner
            :isVisible="!isLoaded"
            class="spinner"
        />
    </div>
</template>

<script>
import { endsWith } from 'lodash';
import languages from './languages';
import AppSpinner from '@/components/partials/AppSpinner.vue';
import MonacoLoader from '@monaco-editor/loader';
import { mapActions, mapGetters } from 'vuex';
import { Modules } from '@/store/store';
import Actions, { Getters } from '@/store/mutation-types';

export default {
    name: 'MustacheAutocomplete',
    components: {
        AppSpinner,
    },
    props: {
        language: { type: String, default: 'mustache-text' },
        value: { type: String, default: '' },
        invalid: { type: Boolean, default: false },
        eventsIDs: { type: Array, default: () => [] },
    },
    data() {
        return {
            editor: false,
            languages,
            isLoaded: false,
            providers: [],
            options: {
                formatOnPaste: false,
                minimap: {
                    enabled: false,
                },
                lineNumbers: false,
                autoClosingBrackets: false,
                wordWrap: 'on',
                fontSize: 14,
                fontFamily: 'Open Sans',
                layoutInfo: {
                    lineNumbersWidth: 0,
                },
                suggestFontSize: 14,
                scrollbar: {
                    vertical: 'hidden',
                },
                renderLineHighlight: 'none',
                renderLineHighlightOnlyWhenFocus: false,
                scrollBeyondLastLine: false,
                padding: { top: 5, bottom: 5 },
            },
        };
    },
    computed: {
        ...mapGetters(Modules.templateHelpers, [Getters.GET_TEMPLATE_HELPERS_AUTOCOMPLETE]),
    },
    watch: {
        value(value) {
            if (value !== this.editor.getValue()) {
                this.editor.setValue(value);
            }
        },
        language(language) {
            this.editor.setModelLanguage(this.editor.getModel(), language);
        },
    },
    created() {
        MonacoLoader.init().then(async monaco => {
            await this[Actions.FETCH_TEMPLATE_HELPERS]();
            this.addCustomLanguages(monaco);

            this.editor = monaco.editor.create(this.$refs.monaco_container, {
                ...this.options,
                language: this.language,
                value: this.value,
            });

            this.editor.onDidChangeModelContent(() => {
                this.$emit('input', this.editor.getValue());
            });
            this.isLoaded = true;
        });
    },
    beforeDestroy() {
        if (this.editor) {
            this.providers.forEach(provider => provider.dispose());
            this.providers = [];
            this.editor.dispose();
        }
    },
    methods: {
        ...mapActions(Modules.templateHelpers, [Actions.FETCH_TEMPLATE_HELPERS]),

        addCustomLanguages(monaco) {
            const { register, getLanguages, setMonarchTokensProvider } = monaco.languages;
            this.languages.forEach(({ id, tokens }) => {
                if (!getLanguages().find(lang => lang.id === id)) {
                    register({ id });
                    setMonarchTokensProvider(id, tokens);
                }

                this.addCompletionItems(monaco, id);
            });
        },

        addCompletionItems(monaco, language) {
            const options = this[Getters.GET_TEMPLATE_HELPERS_AUTOCOMPLETE](this.eventsIDs);
            const { CompletionItemKind, CompletionItemInsertTextRule, registerCompletionItemProvider } =
                monaco.languages;
            const provider = registerCompletionItemProvider(language, {
                triggerCharacters: ['{'],
                provideCompletionItems: (model, position) => {
                    const range = this.getCompletionRange(model, position);
                    const text = this.getCurrentLine(model, position);
                    const isValidTrigger = endsWith(text, '{{');

                    const suggestions = isValidTrigger
                        ? options.map(option => ({
                              ...option,
                              range,
                              kind: CompletionItemKind[option.kind],
                              insertTextRules: CompletionItemInsertTextRule.InsertAsSnippet,
                          }))
                        : [];

                    return { suggestions };
                },
            });
            this.providers.push(provider);
        },

        getCompletionRange(model, position) {
            const word = model.getWordUntilPosition(position);
            return {
                startLineNumber: position.lineNumber,
                endLineNumber: position.lineNumber,
                startColumn: word.startColumn,
                endColumn: word.endColumn,
            };
        },

        getCurrentLine(model, position) {
            return model.getValueInRange({
                startLineNumber: position.lineNumber,
                startColumn: 1,
                endLineNumber: position.lineNumber,
                endColumn: position.column,
            });
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/_palette';

.monaco-container {
    border: 0.063rem solid $gray5;
    height: 12rem;
    position: relative;

    &.loading {
        pointer-events: none;
    }

    .spinner {
        transform: translateY(-2rem);
    }

    &.invalid {
        border-color: $red;
    }
}
</style>
