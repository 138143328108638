import Zones from '@/__new__/services/dno/charging/common/zone/zones';
import uniqBy from 'lodash/uniqBy';

const parseNetworks = (pcObject, srcOrDst) => {
    const mccs = Object.keys(pcObject[srcOrDst]);
    const networks = [];
    mccs.forEach(mcc => {
        if (mcc !== 'inverted') {
            const zoneForMnc = Zones.find(zone => {
                const mncs = pcObject[srcOrDst][mcc];
                // Lua sometimes creates empty {} instead of empty arrays
                if (Array.isArray(mncs)) {
                    return mncs.some(mnc => mnc === zone.mnc && mcc === zone.mcc);
                }
                return false;
            });

            if (zoneForMnc) {
                networks.push({
                    mnc: zoneForMnc.mnc,
                    network: zoneForMnc.network,
                    mcc: zoneForMnc.mcc,
                    iso: zoneForMnc.iso,
                });
            }
        }
    });
    return networks;
};

const parseCountries = (pcObject, srcOrDst) => {
    const mccZones = Zones.filter(zone => pcObject[srcOrDst][zone.mcc]).map(
        ({
            // eslint-disable-next-line camelcase
            mcc,
            iso,
            country_code,
            country,
        }) => ({
            mcc,
            iso,
            country_code,
            country,
        }),
    );
    // there are multiple zones for each mcc
    // because of different mnc's
    return uniqBy(mccZones, 'mcc');
};

export default (id, pcObject) => ({
    type: 'zone',
    data: {
        srcCountries: parseCountries(pcObject, 'src'),
        srcNetworks: parseNetworks(pcObject, 'src'),
        srcInverted: !!pcObject.src.inverted,
        dstCountries: parseCountries(pcObject, 'dst'),
        dstNetworks: parseNetworks(pcObject, 'dst'),
        dstInverted: !!pcObject.dst.inverted,
    },
    uuid: id,
});
