<template>
    <div>
        <AppHeader />
        <div class="editor-wrapper pb-4">
            <!-- General section -->
            <div>
                <h2 class="section-title d-flex align-items-center">
                    {{ $i18n.t('generic.general') }}
                    <LanguageSwitcher
                        v-model="selectedLanguage"
                        :tooltipEnabled="true"
                        class="mt-1"
                    />
                </h2>

                <AppMultiselectV3
                    :value="grantType"
                    trackBy="key"
                    :options="Object.values(GRANT_TYPES)"
                    label="label"
                    data-test-id="grant-type"
                    :disabled="promoInUse"
                    :small="true"
                    :additionalLabel="$i18n.t('promotions.promotionRuleType')"
                    :placeholder="$i18n.t('promotions.chooseType')"
                    class="input-width mb-3"
                    @input="onGrantTypeChange"
                />

                <AppInputV3
                    v-model="name[selectedLanguage]"
                    :label="$i18n.t('generic.name')"
                    class="input-max-width"
                    :invalid="invalidFieldsMap.name"
                    :errorMessage="$i18n.t('generic.validations.fieldIsRequired')"
                    :placeholder="$i18n.t('promotions.addPromotionName')"
                    @input="setInvalidFalseByKey('name')"
                />

                <AppTextareaV3
                    v-model="description[selectedLanguage]"
                    :label="$i18n.t('generic.description')"
                    :invalid="invalidFieldsMap.description"
                    :errorMessage="$i18n.t('generic.validations.fieldIsRequired')"
                    :placeholder="$i18n.t('promotions.addPromotionDescription')"
                    class="input-max-width mt-2"
                    @input="setInvalidFalseByKey('description')"
                />

                <div class="input-label display-flex align-items-center mt-3">
                    {{ $i18n.t('promotions.promotionShortDescription') }}
                    <AppTooltip
                        :tooltipPosition="TOOLTIP_POSITION.right"
                        class="tooltip-container ml-2"
                    >
                        <template slot="label">
                            <span class="info-icon mr-2" />
                        </template>

                        <template slot="content">
                            <div class="tooltip-inner-content ml-3 pb-3">
                                <p class="primary-text font-weight-normal mb-2">
                                    {{ $i18n.t('promotions.promotionShortDescriptionTooltip') }}
                                </p>
                                <p class="primary-text font-weight-normal mb-0">
                                    {{ $i18n.t('promotions.promotionShortDescriptionTooltipEx') }}
                                </p>
                            </div>
                        </template>
                    </AppTooltip>
                </div>
                <AppInputV3
                    v-model="promoShortDescription"
                    label=""
                    class="input-max-width mt-2"
                    :invalid="invalidFieldsMap.promoShortDescription"
                    :errorMessage="$i18n.t('generic.validations.fieldIsRequired')"
                    :placeholder="$i18n.t('promotions.promotionShortDescriptionInfo')"
                    @input="setInvalidFalseByKey('promoShortDescription')"
                />

                <div class="input-label display-flex align-items-center mt-3">
                    {{ $i18n.t('promotions.benefitShortDescription') }}
                    <AppTooltip
                        :tooltipPosition="TOOLTIP_POSITION.right"
                        class="tooltip-container ml-2"
                    >
                        <template slot="label">
                            <span class="info-icon mr-2" />
                        </template>

                        <template slot="content">
                            <div class="tooltip-inner-content ml-3 pb-3">
                                <p class="primary-text font-weight-normal mb-2">
                                    {{ $i18n.t('promotions.benefitShortDescriptionTooltip') }}
                                </p>
                                <p class="primary-text font-weight-normal mb-0">
                                    {{ $i18n.t('promotions.benefitShortDescriptionTooltipEx') }}
                                </p>
                            </div>
                        </template>
                    </AppTooltip>
                </div>
                <AppInputV3
                    v-model="benefitShortDescription"
                    label=""
                    class="input-max-width mt-2"
                    :invalid="invalidFieldsMap.benefitShortDescription"
                    :errorMessage="$i18n.t('generic.validations.fieldIsRequired')"
                    :placeholder="$i18n.t('promotions.benefitShortDescriptionInfo')"
                    @input="setInvalidFalseByKey('benefitShortDescription')"
                />

                <AppTextareaV3
                    v-model="termsAndConditions"
                    :label="$i18n.t('promotions.termsAndConditions')"
                    :invalid="invalidFieldsMap.termsAndConditions"
                    :errorMessage="$i18n.t('generic.validations.fieldIsRequired')"
                    :placeholder="$i18n.t('promotions.termsAndConditionsPlaceholder')"
                    class="input-max-width mt-2"
                    :explanationText="$i18n.t('promotions.termsAndConditionsInfo')"
                    @input="setInvalidFalseByKey('termsAndConditions')"
                />
            </div>
            <!-- Promotion Benefit Section -->
            <div>
                <h2 class="section-title">
                    {{ $i18n.t('promotions.promotionBenefit') }}
                </h2>

                <div class="input-label display-flex align-items-center mb-2 mt-3">
                    {{ $i18n.t('promotions.benefitType') }}
                    <AppTooltip
                        :tooltipPosition="TOOLTIP_POSITION.right"
                        class="tooltip-container ml-2"
                    >
                        <template slot="label">
                            <span class="info-icon mr-2" />
                        </template>

                        <template slot="content">
                            <div class="tooltip-inner-content ml-3 pb-3">
                                <p class="primary-text font-weight-normal mb-2">
                                    {{ $i18n.t('promotions.benefitTypeInfoTitle') }}
                                </p>
                                <p class="primary-text font-weight-normal mb-0">
                                    - {{ $i18n.t('promotions.promotionTypeInfo1') }}
                                </p>
                                <p class="primary-text font-weight-normal mb-0">
                                    - {{ $i18n.t('promotions.promotionTypeInfo2') }}
                                </p>
                                <p class="primary-text font-weight-normal mb-0">
                                    - {{ $i18n.t('promotions.promotionTypeInfo3') }}
                                </p>
                                <p class="primary-text font-weight-normal mb-0">
                                    - {{ $i18n.t('promotions.promotionTypeInfo4') }}
                                </p>
                            </div>
                        </template>
                    </AppTooltip>
                </div>

                <AppMultiselectV3
                    v-model="promotionType"
                    trackBy="key"
                    :options="Object.values(PROMOTION_TYPES)"
                    label="label"
                    :small="true"
                    :error="invalidFieldsMap.promotionType"
                    :errorMessage="$i18n.t('generic.validations.fieldIsRequired')"
                    :placeholder="$i18n.t('promotions.chooseType')"
                    class="input-width mb-3"
                    @input="setInvalidFalseByKey('promotionType')"
                />

                <div
                    v-if="promotionType"
                    class="input-label mb-1"
                >
                    {{ $i18n.t('promotions.discountAmount') }}
                </div>
                <div
                    v-if="promotionType"
                    class="d-flex mb-3"
                >
                    <AppInputV3
                        v-model="amount"
                        type="number"
                        :min="0"
                        :step="0.01"
                        :invalid="invalidFieldsMap.amount"
                        :errorMessage="getAmountErrorMsg"
                        :placeholder="getAmountPlaceholder"
                        class="input-width"
                        @input="[setInvalidFalseByKey('amount'), formatAmountInput($event)]"
                    />
                    <span class="amount-label all-caps-text ml-2">{{ getAmountLabel }}</span>
                </div>

                <div class="input-label display-flex align-items-center mb-2 mt-3">
                    {{ $i18n.t('promotions.applyTo') }}
                    <AppTooltip
                        :tooltipPosition="TOOLTIP_POSITION.right"
                        class="tooltip-container ml-2"
                    >
                        <template slot="label">
                            <span class="info-icon mr-2" />
                        </template>

                        <template slot="content">
                            <div class="tooltip-inner-content ml-3 pb-3">
                                <p class="primary-text font-weight-normal mb-2">
                                    {{ $i18n.t('promotions.promotionApplyInfo') }}
                                </p>
                                <p class="primary-text font-weight-normal mb-0">
                                    {{ $i18n.t('promotions.promotionApplyNoteInfo') }}
                                </p>
                            </div>
                        </template>
                    </AppTooltip>
                </div>

                <div class="d-flex mb-2">
                    <AppMultiselectV3
                        v-model="recurrence"
                        trackBy="key"
                        :options="Object.values(RECURRENCE_OPTIONS)"
                        :blueArrow="true"
                        :borderNone="true"
                        label="label"
                        class="recurrence-dropdown"
                        :preselectFirst="true"
                    />
                    <div
                        v-if="recurrence && recurrence.key === RECURRENCE_OPTIONS.specificRecurrences.key"
                        class="d-flex align-items-center"
                    >
                        <AppInputV3
                            v-model="recurrenceCount"
                            type="number"
                            class="recurrence-value-input"
                        />
                    </div>
                </div>

                <div class="input-label display-flex align-items-center mb-2 mt-3">
                    {{ $i18n.t('promotions.startFromRecurrence') }}
                    <AppTooltip
                        :tooltipPosition="TOOLTIP_POSITION.right"
                        class="tooltip-container ml-2"
                    >
                        <template slot="label">
                            <span class="info-icon mr-2" />
                        </template>

                        <template slot="content">
                            <div class="tooltip-inner-content ml-3 pb-3">
                                <p class="primary-text font-weight-normal mb-2">
                                    {{ $i18n.t('promotions.promotionStartInfo') }}
                                </p>
                                <p class="primary-text font-weight-normal mb-0">
                                    {{ $i18n.t('promotions.promotionStartNoteInfo') }}
                                </p>
                            </div>
                        </template>
                    </AppTooltip>
                </div>

                <div class="d-flex align-items-center">
                    <AppInputV3
                        v-model="startRecurrenceNumber"
                        :min="1"
                        :step="1"
                        :invalid="invalidFieldsMap.startRecurrenceNumber"
                        :errorMessage="getStartRecurrenceNumberErrorMsg"
                        type="number"
                        class="recurrence-value-input"
                        @input="setInvalidFalseByKey('startRecurrenceNumber')"
                    />
                </div>
            </div>

            <!-- Promotion Parameters section -->
            <div>
                <h2 class="section-title">
                    {{ $i18n.t('promotions.promotionParameters') }}
                </h2>
                <DateTimePicker
                    v-if="isManualGrantType"
                    v-model="startTime"
                    class="mb-3"
                    data-test-id="start-time"
                    :additionalLabel="$i18n.t('generic.startTime')"
                    :error="invalidFieldsMap.startTime"
                    type="datetime"
                    @input="setInvalidFalseByKey('startTime')"
                />
                <DateTimePicker
                    v-if="isManualGrantType"
                    v-model="endTime"
                    :additionalLabel="$i18n.t('generic.endTime')"
                    :error="invalidFieldsMap.endTime"
                    type="datetime"
                    data-test-id="end-time"
                    @input="setInvalidFalseByKey('endTime')"
                />
                <p
                    v-if="!isManualGrantType"
                    class="small-gray-date-label mt-2"
                >
                    {{ $i18n.t('promotions.timezoneExplanation') }}
                </p>

                <AppToggle
                    v-model="stackablePromotions"
                    :small="true"
                    :disabled="true"
                    class="mt-4"
                    :label="$i18n.t('promotions.promotionStackable')"
                />
                <!-- TODO: delete after launch -->
                <p class="small-gray-date-label mb-3">
                    {{ $i18n.t('promotions.promotionStackableWarn') }}
                </p>

                <!-- TODO: uncomment after launch -->
                <!-- <p class="small-gray-date-label mb-3">
                    {{ $i18n.t('promotions.promotionStackableInfo') }}
                </p> -->

                <TagsMultiselect
                    v-model="offersForDiscount"
                    :options="availableOffersForDiscount"
                    :additionalLabel="$i18n.t('promotions.applicableTo')"
                    :small="true"
                    :multiple="true"
                    :label="'label'"
                    :labelFormatter="offer => offer.label"
                    class="input-max-width mb-2"
                    :explanation="$i18n.t('promotions.selectOfferExplanation')"
                    :placeholder="$i18n.t('promotions.chooseOffers')"
                />

                <AppToggle
                    v-model="isNotMigratable"
                    :small="true"
                    :explanationText="$i18n.t('promotions.nonTransferableTooltip')"
                    :label="$i18n.t('promotions.nonTransferable')"
                    data-test-id="not-migratable-toggle"
                    class="mt-4"
                />
                <p class="small-gray-date-label mb-3">
                    {{ $i18n.t('promotions.nonTransferableExplanation') }}
                </p>
            </div>

            <!-- Conditions -->
            <div class="section position-relative mb-4">
                <div
                    v-t="'generic.conditions'"
                    class="lf-subtitle"
                />
                <Conditions
                    v-model="condition"
                    :newConditionPreset="conditionPreset"
                    :displayError="displayConditionError"
                    @error="conditionHasError = $event"
                />
            </div>
        </div>
        <div class="editor-controls-wrapper">
            <div class="editor-controls-content">
                <AppButton
                    :label="$t('generic.cancel')"
                    class="mr-5"
                    @click="onCancel"
                />
                <AppButton
                    :buttonType="BUTTON_TYPES.PRIMARY"
                    :label="$t('generic.save')"
                    :iconType="ICON_TYPES.CHECK"
                    @click="onSave"
                />
            </div>
        </div>
    </div>
</template>

<script>
// Vue components
import AppHeader from '@/components/layout/AppHeader.vue';
import AppInputV3 from '@/components/partials/inputs/AppInputV3.vue';
import AppTextareaV3 from '@/components/partials/inputs/AppTextareaV3.vue';
import AppMultiselectV3 from '@/components/partials/inputs/AppMultiselectV3.vue';
import AppToggle from '@/components/partials/inputs/AppToggle.vue';
import TagsMultiselect from '@/components/partials/inputs/TagsMultiselect.vue';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import { ICON_TYPES } from '@/common/iconHelper';
import AppTooltip from '@/components/partials/AppTooltip.vue';
import { TOOLTIP_POSITION } from '@/common/tooltip';
import LanguageSwitcher from '@/components/partials/inputs/LanguageSwitcher.vue';
import DateTimePicker from '@/components/partials/inputs/DateTimePicker.vue';
import Conditions from '@/components/partials/Conditions.vue';

// Helpers
import moment from 'moment';
import ENTITY_TYPES from '@/common/entities/entityTypes';
import cloneDeep from 'lodash/cloneDeep';
import { dateToEpoch } from '@/common/formatting';
import RouteNames from '@/router/routeNames';
import {
    PROMOTION_TYPES,
    GRANT_TYPES,
    RECURRENCE_OPTIONS,
    PROMOTION_ENUM_TO_KEY_MAP,
    PROMOTION_KEY_TO_ENUM_MAP,
    GRANT_TYPE_KEY_TO_ENUM_MAP,
    GRANT_TYPE_ENUM_TO_OBJECT,
} from '@/modules/promotions/common/promotionsHelper';
import i18n from '@/i18n';
import { getMultiLangFieldValueByLocale } from '@/common/entities/entityHelper';

// http
import { addPromotion, updatePromotion } from '@/modules/promotions/http/promotion';

// vuex
import { mapGetters } from 'vuex';
import Actions, { Getters } from '@/store/mutation-types';

// misc
import { OPERATION, OPERATION_TYPE, DATA_TYPES } from '@/common/conditions-tree';
import _ from 'lodash';

function FieldException(message, key) {
    this.type = 'fieldException';
    this.message = message;
    this.fieldKey = key;
}

export default {
    name: 'PromotionsEditor',

    components: {
        AppButton,
        Conditions,
        TagsMultiselect,
        AppToggle,
        AppMultiselectV3,
        AppTextareaV3,
        AppInputV3,
        AppHeader,
        AppTooltip,
        LanguageSwitcher,
        DateTimePicker,
    },
    data() {
        return {
            originalPromotionValue: null,
            grantType: GRANT_TYPES.Voucher,
            name: {},
            description: {},
            promoShortDescription: '',
            benefitShortDescription: '',
            condition: null,
            conditionPreset: {
                op: OPERATION.IS_SUBSET_OF,
                type: OPERATION_TYPE.COMPARISON,
                args: {
                    data_type: DATA_TYPES.OFFER,
                    key: 'offer_id',
                    value: [],
                },
            },
            conditionHasError: false,
            displayConditionError: false,
            termsAndConditions: '',
            promotionType: '',
            amount: null,
            recurrence: undefined,
            recurrenceCount: 0,
            startRecurrenceNumber: 1,
            startTime: null,
            endTime: null,
            stackablePromotions: false,
            offersForDiscount: [],
            isNotMigratable: false,
            invalidFieldsMap: {
                name: false,
                description: false,
                promoShortDescription: false,
                benefitShortDescription: false,
                termsAndConditions: false,
                promotionType: false,
                amount: false,
                startTime: false,
                endTime: false,
                startRecurrenceNumber: false,
            },

            // Proxy imports
            PROMOTION_TYPES,
            GRANT_TYPES,
            RECURRENCE_OPTIONS,
            TOOLTIP_POSITION,
            ICON_TYPES,
            BUTTON_TYPES,
        };
    },

    computed: {
        ...mapGetters('productcatalog', [Getters.PC_GET_ENTITIES_BY_TYPE_APPROVED]),
        ...mapGetters('rewards', [Getters.GET_APPROVED_REWARDS_ENTITIES_BY_TYPE]),
        ...mapGetters('operators', {
            selectedLanguage: Getters.languageDefault,
        }),
        isManualGrantType() {
            return this.grantType === GRANT_TYPES.Manual;
        },
        getAmountLabel() {
            if (this.promotionType && this.promotionType.key === PROMOTION_TYPES.PriceOverride.key) {
                return this.$i18n.t('promotions.amountPriceLabel');
            }
            return this.$i18n.t('promotions.amountOffLabel');
        },
        getAmountErrorMsg() {
            if (!this.amount) {
                return this.$i18n.t('generic.validations.fieldIsRequired');
            }
            if (
                this.promotionType.key !== PROMOTION_TYPES.PriceOverride.key &&
                (this.amount < 0 || this.amount > 100)
            ) {
                return this.$i18n.t('promotions.amountRangeError');
            }
            return '';
        },
        getAmountPlaceholder() {
            if (this.promotionType && this.promotionType.key === PROMOTION_TYPES.PercentageDiscount.key) {
                return '10%';
            }
            return `${this.$localeLibrary.getFormattedAmount(10)}`;
        },
        availableOffersForDiscount() {
            return this[Getters.PC_GET_ENTITIES_BY_TYPE_APPROVED](ENTITY_TYPES.OFFER).map(offer => ({
                ...offer,
                label: offer.name,
            }));
        },
        getStartRecurrenceNumberErrorMsg() {
            if (typeof this.startRecurrenceNumber !== 'number' || this.startRecurrenceNumber < 1) {
                return this.$i18n.t('generic.validations.valueShouldBeMoreThanZero');
            }
            return '';
        },
        /**
         * Computed property which determines if the promotion is currently in use
         */
        promoInUse() {
            return this.$route.params.id && !this.$route.params.clone;
        },
    },
    created() {
        this.$withLoadingSpinner(
            async () => {
                const promises = [
                    this.$store.dispatch(
                        `rewards/${Actions.REQUEST_REWARDS_ENTITIES_BY_TYPE}`,
                        ENTITY_TYPES.VOUCHER_SET,
                    ),
                    this.$store.dispatch(`productcatalog/${Actions.PC_REQUEST_ENTITIES_BY_TYPE_AND_IDS}`, {
                        entityType: ENTITY_TYPES.OFFER,
                    }),
                    this.$store.dispatch(`promotions/${Actions.REQUEST_PROMOTIONS}`),
                ];
                await Promise.all(promises);

                if (this.$route.params.id) {
                    const promotion = this.$store.getters[`promotions/${Getters.PROMOTION_BY_ID}`](
                        this.$route.params.id,
                    );
                    this.originalPromotionValue = promotion;
                    this.initializeEditorState();
                }
            },
            {
                errorHandler: () => {
                    this.$eventBus.$emit('showAlert', {
                        message: i18n.t('alertMessage.errorDoingSmthTryAgain', {
                            action: 'fetching',
                            entityName: 'data',
                        }),
                    });
                },
            },
        );
    },

    methods: {
        initializeEditorState() {
            const originalPromotionData = this.originalPromotionValue.data;

            this.grantType = GRANT_TYPE_ENUM_TO_OBJECT[originalPromotionData.grant_type] || GRANT_TYPES.Voucher;

            this.name = originalPromotionData.name;
            this.description = originalPromotionData.description;

            // the ui only has two fields so we'll take the first object from this array
            const shortDescriptions = originalPromotionData.short_descriptions;
            if (shortDescriptions && shortDescriptions.length > 0) {
                const firstShortDesc = shortDescriptions[0];
                this.promoShortDescription = firstShortDesc.promotion_short_description;
                this.benefitShortDescription = firstShortDesc.benefit_short_description;
            }

            this.termsAndConditions =
                originalPromotionData.terms_and_conditions && originalPromotionData.terms_and_conditions.en;

            // start and end time
            if (originalPromotionData.start_time && originalPromotionData.start_time !== 0) {
                this.startTime = new Date(originalPromotionData.start_time * 1000);
            }
            if (originalPromotionData.end_time) {
                this.endTime = new Date(originalPromotionData.end_time * 1000);
            }

            // promotion type
            const promoRuleTypeEnum = originalPromotionData.promo_rule_type;
            const promotionTypeKey = PROMOTION_ENUM_TO_KEY_MAP[promoRuleTypeEnum];
            this.promotionType = PROMOTION_TYPES[promotionTypeKey];

            // Amounts for now just primary
            this.amount = Number(originalPromotionData.amounts && originalPromotionData.amounts.primary);

            // recurrence
            if (originalPromotionData.recurrence_limit > 0) {
                this.recurrenceCount = originalPromotionData.recurrence_limit;
                this.recurrence = RECURRENCE_OPTIONS.specificRecurrences;
            }

            this.startRecurrenceNumber = originalPromotionData.start_recurrence_number;

            // offers
            const selectedOffersIds = originalPromotionData.applicable_offers || [];
            this.offersForDiscount = this.availableOffersForDiscount.filter(offer =>
                selectedOffersIds.some(offerId => offerId === offer.id),
            );

            this.isNotMigratable = originalPromotionData.is_not_migratable || false;

            // conditions
            this.condition = originalPromotionData.condition;
        },
        makeRequestDTO() {
            const originalPromotionData = this.originalPromotionValue ? this.originalPromotionValue.data : {};

            const newPromotionsData = cloneDeep(originalPromotionData);

            newPromotionsData.grant_type = GRANT_TYPE_KEY_TO_ENUM_MAP[this.grantType.key];

            newPromotionsData.name = {
                ...this.name,
            };
            if (this.description) {
                newPromotionsData.description = {
                    ...this.description,
                };
            }

            // short  descriptions
            if (this.promoShortDescription || this.benefitShortDescription) {
                newPromotionsData.short_descriptions = [
                    {
                        promotion_short_description: this.promoShortDescription,
                        benefit_short_description: this.benefitShortDescription,
                    },
                ];
            }

            if (this.termsAndConditions) {
                newPromotionsData.terms_and_conditions = {
                    en: this.termsAndConditions,
                };
            }

            // start and end time
            if (this.startTime) {
                newPromotionsData.start_time = dateToEpoch(this.startTime);
            } else {
                newPromotionsData.start_time = dateToEpoch(new Date());
            }

            if (this.endTime) {
                newPromotionsData.end_time = dateToEpoch(this.endTime);
            }

            // promotion type
            newPromotionsData.promo_rule_type = PROMOTION_KEY_TO_ENUM_MAP[this.promotionType.key];

            // amount
            // Amounts for now just primary
            newPromotionsData.amounts = {
                primary: `${this.amount}`,
            };

            // recurrence
            if (this.recurrence === RECURRENCE_OPTIONS.specificRecurrences && this.recurrenceCount > 0) {
                newPromotionsData.recurrence_limit = Number(this.recurrenceCount);
            }

            newPromotionsData.start_recurrence_number = Number(this.startRecurrenceNumber);

            // offers
            if (this.offersForDiscount.length > 0) {
                newPromotionsData.applicable_offers = this.offersForDiscount.map(offer => offer.id);
            } else {
                delete newPromotionsData.applicable_offers;
            }

            newPromotionsData.is_not_migratable = this.isNotMigratable;

            if (this.condition) {
                newPromotionsData.condition = this.condition;
            }

            // saveOrUpdate newPromotionsData
            return newPromotionsData;
        },
        onCancel() {
            this.$router.go(-1);
        },
        onSave() {
            if (this.validatePromotionForm()) {
                const promotionDTO = this.makeRequestDTO();
                if (!this.$route.params.id || this.$route.params.clone) {
                    this.savePromotion(promotionDTO);
                } else {
                    const { id, version } = this.originalPromotionValue;
                    this.updatePromotion(id, version, promotionDTO);
                }
            }
        },
        validatePromotionForm() {
            try {
                if (!this.name) {
                    throw new FieldException(this.$i18n.t('alertMessage.promotionValidation.nameRequired'), 'name');
                }
                if (!this.description) {
                    throw new FieldException(
                        this.$i18n.t('alertMessage.promotionValidation.descriptionRequired'),
                        'description',
                    );
                }
                if (!this.promoShortDescription) {
                    throw new FieldException(
                        this.$i18n.t('alertMessage.promotionValidation.promoShortDescriptionRequired'),
                        'promoShortDescription',
                    );
                }
                if (!this.benefitShortDescription) {
                    throw new FieldException(
                        this.$i18n.t('alertMessage.promotionValidation.benefitShortDescriptionRequired'),
                        'benefitShortDescription',
                    );
                }
                if (!this.termsAndConditions) {
                    throw new FieldException(
                        this.$i18n.t('alertMessage.promotionValidation.termsAndConditionsRequired'),
                        'termsAndConditions',
                    );
                }
                if (!this.promotionType) {
                    throw new FieldException(
                        this.$i18n.t('alertMessage.promotionValidation.typeRequired'),
                        'promotionType',
                    );
                }
                if (
                    !(typeof this.amount === 'number') ||
                    (this.promotionType.key === PROMOTION_TYPES.PercentageDiscount.key &&
                        (this.amount < 0 || this.amount > 100))
                ) {
                    throw new FieldException(this.$i18n.t('alertMessage.promotionValidation.amountInvalid'), 'amount');
                }
                if (this.startTime && this.endTime) {
                    const startTimeMoment = moment(this.startTime);
                    const endTimeMoment = moment(this.endTime);
                    if (startTimeMoment.isAfter(endTimeMoment)) {
                        throw new FieldException(
                            this.$i18n.t('alertMessage.promotionValidation.startTimeAfterError'),
                            'startTime',
                        );
                    }
                    if (startTimeMoment.isSame(endTimeMoment)) {
                        throw new FieldException(
                            this.$i18n.t('alertMessage.promotionValidation.startTimeEqualError'),
                            'startTime',
                        );
                    }
                }
                if (typeof this.startRecurrenceNumber !== 'number' || this.startRecurrenceNumber < 1) {
                    throw new FieldException(
                        this.$i18n.t('alertMessage.promotionValidation.startRecurrenceNumberError'),
                        'startRecurrenceNumber',
                    );
                }

                // Validate conditions
                if (this.condition) {
                    // Check if there are generic errors reported by the Condition component
                    if (this.conditionHasError) {
                        this.displayConditionError = true;
                        throw new FieldException(this.$i18n.t('alertMessage.condition.conditionHasError'));
                    }
                    // Ensure our condition offer ids match up with `Applicable to` offers.
                    // This is temporary: when `Applicable to` input is removed, we can remove this check as well.
                    const offerIds = this.offersForDiscount.map(offer => offer.id).sort();
                    let conditionOfferIds = [];
                    if (Array.isArray(this.condition.args?.value)) {
                        conditionOfferIds = this.condition.args.value.sort();
                    }
                    if (!_.isEqual(offerIds, conditionOfferIds)) {
                        throw new FieldException(
                            this.$i18n.t('alertMessage.condition.applicableOffersAndConditionOffersMismatch'),
                        );
                    }
                }
                return true;
            } catch (e) {
                this.$Progress.finish();
                if (e && e.type === 'fieldException') {
                    this.invalidFieldsMap[e.fieldKey] = true;
                }
                this.$eventBus.$emit('showAlert', {
                    message: e.message,
                });
                return false;
            }
        },
        setInvalidFalseByKey(key) {
            this.invalidFieldsMap[key] = false;
        },
        formatAmountInput(amount) {
            if (typeof amount === 'number') {
                this.$nextTick(() => {
                    if (this.promotionType.key !== PROMOTION_TYPES.PercentageDiscount.key) {
                        this.amount = Math.floor(amount * 100) / 100;
                    } else {
                        this.amount = Math.floor(amount);
                    }
                });
            }
        },
        getLabelMap(entities) {
            return entities.map(entity => ({
                ...entity,
                label: getMultiLangFieldValueByLocale(
                    entity.data.name,
                    this.selectedLanguage || 'en',
                    entity.data.name ? entity.data.name : 'N/A',
                ),
            }));
        },
        async savePromotion(promotion) {
            this.$Progress.start();
            try {
                await addPromotion(promotion);
                this.$showSuccessAlert({
                    message: this.$i18n.t('promotions.alerts.successSavePromotion'),
                });
                setTimeout(
                    () =>
                        this.$router.push({
                            name: RouteNames.PROMOTIONS,
                            params: { companyId: this.$route.params.companyId },
                        }),
                    2000,
                );
                this.$Progress.finish();
            } catch (e) {
                this.$showErrorAlert({
                    message: this.$i18n.t('promotions.alerts.failedSavePromotion'),
                });
                this.$Progress.fail();
            }
        },
        async updatePromotion(id, version, data) {
            this.$Progress.start();
            try {
                await updatePromotion(id, version, data);
                this.$showSuccessAlert({
                    message: this.$i18n.t('promotions.alerts.successUpdatePromotion'),
                });
                setTimeout(
                    () =>
                        this.$router.push({
                            name: RouteNames.PROMOTIONS,
                            params: { companyId: this.$route.params.companyId },
                        }),
                    2000,
                );
                this.$Progress.finish();
            } catch (e) {
                this.$showErrorAlert({
                    message: this.$i18n.t('promotions.alerts.failedUpdatePromotion'),
                });
                this.$Progress.fail();
            }
        },
        onGrantTypeChange(grantType) {
            this.grantType = grantType;

            // Clear time data
            this.startTime = null;
            this.endTime = null;
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/palette';
@import '~@/assets/scss/icons';

$icon-path: '~@/assets/icons/';

.info-icon {
    width: 1rem;
    height: 1rem;
    content: url($icon-path + $info-grey);
}

.tooltip-container {
    height: 1rem;
}

.tooltip-inner-content {
    width: 26.25rem;
}

.editor-wrapper {
    max-width: 880px;
    margin: 0 auto;
}

.input-max-width {
    max-width: 567px;
}

.input-width {
    min-width: 17.6875rem;
    width: 30%;
}

.section-title {
    font-size: 1rem;
    font-weight: bold;

    color: $gray90;

    margin-top: 2rem;
    margin-bottom: 1rem;
}

.discounts-types-dropdown {
    max-width: 120px;
    width: 100%;
}

.input-label {
    font-weight: bold;

    font-size: 0.75rem;

    color: $gray90;
}

.amount-label {
    display: flex;
    align-items: center;
    height: 2.125rem;
}

.value-input {
    max-width: 60px;
}

.value-input-section {
    span {
        font-size: 0.75rem;
        font-weight: bold;
        line-height: 1.75;
        color: $gray-blue;
    }
}

.recurrence-dropdown {
    min-width: 200px;
}

.recurrence-label {
    font-size: 0.75rem;
    font-weight: bold;
    color: $gray-blue;
}

.recurrence-value-input {
    width: 80px;
}

.card-slot-content {
    display: flex;
    align-items: center;
    padding-top: 20px;
    margin-left: 52px;

    .input-wrapper {
        width: 4rem;
        margin: 0 8px;
    }
}

.editor-controls-wrapper {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    height: 5.56rem;
    margin-top: 3rem;
    padding: 1.5rem 11.25rem;
    border-top: 1px solid $blue15;
    background-color: $white;

    .editor-controls-content {
        display: flex;
        justify-content: flex-end;
    }
}
</style>
