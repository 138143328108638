import i18n from '@/i18n';
import { TranslateResult } from 'vue-i18n';
import { LABEL_COLOR } from '@/common/labelsHelper';

export { STATUS_CODES } from '@/common/commonHelper';

export enum ORDER_ENTITY_STATES {
    // Negative states are not present on BE enums!
    // Custom state, used for FE to display when status is active(100)
    // and order.replace_info is present;
    ACTIVE_PLAN_CHANGE = -1,
    // BE enums
    PENDING = 1,
    PENDING_ACTIVATION = 2,
    PAYMENT_FAILED = 3,
    WAITING_APPOINTMENT = 4,
    WAITING_EKYC = 5,
    INITIAL_PAYMENT_FAILED = 6,
    ACTIVE = 100,
    ACTIVE_CANCELLATION = 101,
    PENDING_DEACTIVATION = 102,
    PENDING_EXPIRATION = 103,
    PENDING_FULLFILLMENT = 104,
    PENDING_REPLACEMENT = 105,
    PENDING_ACTIVATION_FULFILLMENT = 106,
    CREATED = 107,
    QUEUED = 108,
    CANCELED = 200,
    SYSTEM_CANCELED = 202,
    REPLACED = 300,
    REPLACED_PENDING = 301,
    EXPIRED = 400,
    DEACTIVATED = 500,
    DEACTIVATED_PENDING = 501,
    REACTIVATED = 502,
    EXTERNAL_PROVISION_FAILED = 600,
    ACTIVATION_FAILED = 601,
    BILLING_FAILED = 602,
    APPOINTMENT_FAILED = 603,
    PRICING_FAILED = 604,
    REPLACEMENT_FAILED = 605,
    OE_SUSPENDED = 700,
    DONE = 800,
}

export const ORDER_STATUS_INDICATOR_MAP: Map<ORDER_ENTITY_STATES, string | undefined> = new Map([
    [ORDER_ENTITY_STATES.PENDING, LABEL_COLOR.yellow],
    [ORDER_ENTITY_STATES.PENDING_ACTIVATION, LABEL_COLOR.yellow],
    [ORDER_ENTITY_STATES.PAYMENT_FAILED, LABEL_COLOR.red],
    [ORDER_ENTITY_STATES.INITIAL_PAYMENT_FAILED, LABEL_COLOR.red],
    [ORDER_ENTITY_STATES.WAITING_APPOINTMENT, LABEL_COLOR.yellow],
    [ORDER_ENTITY_STATES.WAITING_EKYC, LABEL_COLOR.yellow],
    [ORDER_ENTITY_STATES.ACTIVE, LABEL_COLOR.green],
    [ORDER_ENTITY_STATES.ACTIVE_CANCELLATION, LABEL_COLOR.green],
    [ORDER_ENTITY_STATES.PENDING_DEACTIVATION, LABEL_COLOR.yellow],
    [ORDER_ENTITY_STATES.PENDING_EXPIRATION, LABEL_COLOR.yellow],
    [ORDER_ENTITY_STATES.PENDING_FULLFILLMENT, LABEL_COLOR.yellow],
    [ORDER_ENTITY_STATES.PENDING_REPLACEMENT, LABEL_COLOR.yellow],
    [ORDER_ENTITY_STATES.PENDING_ACTIVATION_FULFILLMENT, LABEL_COLOR.yellow],
    [ORDER_ENTITY_STATES.CREATED, LABEL_COLOR.yellow],
    [ORDER_ENTITY_STATES.ACTIVE_PLAN_CHANGE, LABEL_COLOR.green],
    [ORDER_ENTITY_STATES.CANCELED, LABEL_COLOR.red],
    [ORDER_ENTITY_STATES.SYSTEM_CANCELED, LABEL_COLOR.gray],
    [ORDER_ENTITY_STATES.REPLACED, LABEL_COLOR.blue],
    [ORDER_ENTITY_STATES.REPLACED_PENDING, LABEL_COLOR.yellow],
    [ORDER_ENTITY_STATES.EXPIRED, LABEL_COLOR.red],
    [ORDER_ENTITY_STATES.DEACTIVATED, LABEL_COLOR.red],
    [ORDER_ENTITY_STATES.DEACTIVATED_PENDING, LABEL_COLOR.yellow],
    [ORDER_ENTITY_STATES.REACTIVATED, LABEL_COLOR.red],
    [ORDER_ENTITY_STATES.EXTERNAL_PROVISION_FAILED, LABEL_COLOR.red],
    [ORDER_ENTITY_STATES.ACTIVATION_FAILED, LABEL_COLOR.red],
    [ORDER_ENTITY_STATES.BILLING_FAILED, LABEL_COLOR.red],
    [ORDER_ENTITY_STATES.APPOINTMENT_FAILED, LABEL_COLOR.red],
    [ORDER_ENTITY_STATES.PRICING_FAILED, LABEL_COLOR.red],
    [ORDER_ENTITY_STATES.REPLACEMENT_FAILED, LABEL_COLOR.red],
    [ORDER_ENTITY_STATES.OE_SUSPENDED, LABEL_COLOR.red],
    [ORDER_ENTITY_STATES.DONE, LABEL_COLOR.green],
]);

export const ORDER_ENTITY_STATES_TO_STATUS_NAME_MAP: Map<ORDER_ENTITY_STATES, TranslateResult> = new Map([
    [ORDER_ENTITY_STATES.PENDING, i18n.t('generic.stateMap.pending')],
    [ORDER_ENTITY_STATES.PENDING_ACTIVATION, i18n.t('generic.stateMap.pendingActivation')],
    [ORDER_ENTITY_STATES.PAYMENT_FAILED, i18n.t('generic.stateMap.paymentFailed')],
    [ORDER_ENTITY_STATES.INITIAL_PAYMENT_FAILED, i18n.t('generic.stateMap.initialPaymentFailed')],
    [ORDER_ENTITY_STATES.WAITING_APPOINTMENT, i18n.t('generic.stateMap.waitingAppointment')],
    [ORDER_ENTITY_STATES.WAITING_EKYC, i18n.t('generic.stateMap.waitingEKYC')],
    [ORDER_ENTITY_STATES.ACTIVE, i18n.t('generic.stateMap.active')],
    [ORDER_ENTITY_STATES.ACTIVE_CANCELLATION, i18n.t('generic.stateMap.pendingCancellation')],
    [ORDER_ENTITY_STATES.PENDING_DEACTIVATION, i18n.t('generic.stateMap.pendingDeactivation')],
    [ORDER_ENTITY_STATES.PENDING_EXPIRATION, i18n.t('generic.stateMap.pendingExpiration')],
    [ORDER_ENTITY_STATES.PENDING_FULLFILLMENT, i18n.t('generic.stateMap.pendingFullfillment')],
    [ORDER_ENTITY_STATES.PENDING_REPLACEMENT, i18n.t('generic.stateMap.pendingReplacement')],
    [ORDER_ENTITY_STATES.PENDING_ACTIVATION_FULFILLMENT, i18n.t('generic.stateMap.pendingActivationFulfillment')],
    [ORDER_ENTITY_STATES.CREATED, i18n.t('generic.stateMap.created')],
    [ORDER_ENTITY_STATES.SYSTEM_CANCELED, i18n.t('generic.stateMap.systemCanceled')],
    [ORDER_ENTITY_STATES.ACTIVE_PLAN_CHANGE, i18n.t('generic.stateMap.activePlanChange')],
    [ORDER_ENTITY_STATES.CANCELED, i18n.t('generic.stateMap.canceled')],
    [ORDER_ENTITY_STATES.REPLACED, i18n.t('generic.stateMap.replaced')],
    [ORDER_ENTITY_STATES.REPLACED_PENDING, i18n.t('generic.stateMap.replaced')],
    [ORDER_ENTITY_STATES.EXPIRED, i18n.t('generic.stateMap.expired')],
    [ORDER_ENTITY_STATES.DEACTIVATED, i18n.t('generic.stateMap.deactivated')],
    [ORDER_ENTITY_STATES.DEACTIVATED_PENDING, i18n.t('generic.stateMap.deactivatedPending')],
    [ORDER_ENTITY_STATES.REACTIVATED, i18n.t('generic.stateMap.reactivated')],
    [ORDER_ENTITY_STATES.EXTERNAL_PROVISION_FAILED, i18n.t('generic.stateMap.externalProvisionFailed')],
    [ORDER_ENTITY_STATES.ACTIVATION_FAILED, i18n.t('generic.stateMap.activationFailed')],
    [ORDER_ENTITY_STATES.BILLING_FAILED, i18n.t('generic.stateMap.billingFailed')],
    [ORDER_ENTITY_STATES.APPOINTMENT_FAILED, i18n.t('generic.stateMap.appointmentFailed')],
    [ORDER_ENTITY_STATES.PRICING_FAILED, i18n.t('generic.stateMap.pricingFailed')],
    [ORDER_ENTITY_STATES.REPLACEMENT_FAILED, i18n.t('generic.stateMap.replacementFailed')],
    [ORDER_ENTITY_STATES.OE_SUSPENDED, i18n.t('generic.stateMap.suspended')],
    [ORDER_ENTITY_STATES.DONE, i18n.t('generic.stateMap.done')],
    [ORDER_ENTITY_STATES.QUEUED, i18n.t('generic.stateMap.queue')],
]);
