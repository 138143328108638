<template>
    <DuplicateSlot
        v-model="inputValues"
        :addButtonLabel="addButtonLabel"
        :enableDeleteButton="true"
        :optional="optional"
        :label="label"
        deleteIconWithoutOffset
        class="w-100 from-to-pairs"
    >
        <template slot-scope="{ slotNumber }">
            <div class="d-flex mb-4 row col-11">
                <AppInputV3
                    v-model.trim="inputValues[slotNumber].from"
                    :placeholder="firstInputPlaceholder"
                    :invalid="isInvalid(slotNumber)"
                    :type="types"
                    :min="minimums"
                    :max="maximums"
                    :disablePadding="true"
                    class="col-12 col-lg-5 mb-1 mb-lg-0 w-100"
                />

                <div class="label-between-inputs d-flex align-self-end mx-3">
                    {{ $i18n.t('generic.to').toUpperCase() }}
                </div>

                <AppInputV3
                    v-model="inputValues[slotNumber].to"
                    :placeholder="secondInputPlaceholder"
                    :invalid="isInvalid(slotNumber)"
                    :type="types"
                    :min="minimums"
                    :max="maximums"
                    :disablePadding="true"
                    class="col-12 col-lg-5 mt-1 mt-lg-0 w-100"
                />
            </div>
        </template>
    </DuplicateSlot>
</template>

<script>
import AppInputV3 from '@/components/partials/inputs/AppInputV3.vue';
import DuplicateSlot from '@/components/partials/DuplicateSlot.vue';

export default {
    name: 'FromToInputs',

    components: {
        AppInputV3,
        DuplicateSlot,
    },

    props: {
        value: {
            type: Object,
            default: () => undefined,
        },
        initInputValues: {
            type: Array,
            default: () => [],
        },
        isAllInvalid: {
            type: Boolean,
            default: false,
        },
        addButtonLabel: {
            type: String,
            default: '',
        },
        firstInputPlaceholder: {
            type: String,
            default: '',
        },
        secondInputPlaceholder: {
            type: String,
            default: '',
        },
        showInputErrors: {
            type: Boolean,
            default: false,
        },
        optional: {
            type: Boolean,
            default: false,
        },
        label: {
            type: String,
            default: '',
        },
        types: {
            type: String,
            default: 'number',
        },
        minimums: {
            type: Number,
            default: 0,
        },
        maximums: {
            type: Number,
            default: 1000000000000,
        },
    },

    data() {
        return {
            inputValues: [
                {
                    from: '',
                    to: '',
                },
            ],
            invalidIndices: [],
            showErrors: this.showInputErrors,
        };
    },

    watch: {
        inputValues: {
            handler() {
                this.validateInputs();
            },
            deep: true,
            immediate: true,
        },
        initInputValues: {
            handler(newVal) {
                if (newVal && newVal.length) {
                    this.inputValues = JSON.parse(JSON.stringify(newVal));
                }
            },
            immediate: true,
        },
        showInputErrors(newVal) {
            this.showErrors = newVal;
        },
    },
    methods: {
        isInvalid(slotNum) {
            return this.showErrors && (this.isAllInvalid || this.invalidIndices.includes(slotNum));
        },
        validateInputs() {
            // In the case that we only have a single row where both the from and to are
            // falsy values, we consider it to be empty.
            //
            const lastRow = this.inputValues[this.inputValues.length - 1];
            const result = this.inputValues.length === 1 && !lastRow.from && !lastRow.to ? [] : this.inputValues;

            this.invalidIndices = this.validateUniqRangeValues(result);

            this.$emit('input', {
                data: result.map(item => ({
                    from: item.from.toString(),
                    to: item.to.toString(),
                })),
                isValid: this.invalidIndices.length === 0,
            });
        },
        validateUniqRangeValues(arrayWithObjsInputValues) {
            const invalidIndices = [];

            if (arrayWithObjsInputValues.length === 0) {
                return [];
            }
            if (arrayWithObjsInputValues.length >= 1) {
                arrayWithObjsInputValues.forEach((obj, index) => {
                    // Check for duplicate
                    if (
                        arrayWithObjsInputValues
                            .filter((item, i) => i !== index)
                            .some(
                                item =>
                                    item.from.toString() === obj.from.toString() &&
                                    item.to.toString() === obj.to.toString(),
                            )
                    ) {
                        invalidIndices.push(index);
                    }
                    // Check for 'from' greater than 'to' and for the same number of symbols
                    if (
                        obj.from &&
                        obj.to &&
                        (Number(obj.to) <= Number(obj.from) || obj.to.toString().length !== obj.from.toString().length)
                    ) {
                        invalidIndices.push(index);
                    }
                    // Check for empty 'from' or empty 'to'
                    if ((!obj.from || !obj.to) && invalidIndices.indexOf(index) === -1) {
                        invalidIndices.push(index);
                    }
                });
            }

            return invalidIndices;
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/_palette';
@import '~@/assets/scss/_typographyV2.scss';

.from-to-pairs {
    max-width: 36.5rem;
}

.label-between-inputs {
    color: $blue60;
    font-weight: $bold-font-weight;
}
</style>
