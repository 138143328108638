
import Vue, { PropType } from 'vue';

// Components
import AppButton from '@/components/partials/inputs/AppButton.vue';
import AppMultiselectV3 from '@/components/partials/inputs/AppMultiselectV3.vue';
import AppTooltip from '@/components/partials/AppTooltip.vue';
import IconButton from '@/components/partials/IconButton.vue';

// Validations
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';

// // Helpers
import { ICON_TYPES } from '@/common/iconHelper';
import { TOOLTIP_POSITION } from '@/common/tooltip';
import { TargetAddressPair } from '@/__new__/features/pc/products/common/productTaxAttribute';
import {
    ADDRESS_TYPE_TO_LABEL_MAP,
    getUserManagerEntityTypeById,
    USER_MANAGER_HIERARCHY as TARGET,
} from '@/__new__/features/customerCare/common/customerCareHelper';

export default Vue.extend({
    name: 'TargetToAddressTypeTable',
    components: {
        AppButton,
        AppMultiselectV3,
        AppTooltip,
        IconButton,
    },
    mixins: [validationMixin],
    props: {
        /**
         * A list of `TargetAddressPair` objects, which contain a single target and address type.
         */
        value: {
            type: Array as PropType<Partial<TargetAddressPair>[]>,
            default: () => [],
        },
        /**
         * Disables all child components. Used for read only mode.
         */
        disabled: {
            type: Boolean,
            default: false,
        },
        /**
         * Indicates if errors will be shown. Used for validation purposes.
         */
        showValidation: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            ICON_TYPES,
            TOOLTIP_POSITION,
            allowedTargets: [TARGET.SUBSCRIBER, TARGET.ACCOUNT], // targets currently allowed by this component
        };
    },
    computed: {
        addressTypeOptions() {
            return Array.from(ADDRESS_TYPE_TO_LABEL_MAP, ([id, displayStr]) => ({ id, displayStr }));
        },
        targetOptions() {
            return this.allowedTargets.map(target => ({
                id: target,
                displayStr: getUserManagerEntityTypeById(target),
            }));
        },
    },
    methods: {
        addTableRow() {
            this.value.push({});
            this.onTableChange();
        },
        deleteTableRow(index: number) {
            this.value.splice(index, 1);
            this.onTableChange();
        },
        onTableChange() {
            this.$v.$touch();
            this.$emit('error', this.$v.$error);
            this.$emit('input', this.value);
        },
    },
    validations: {
        value: {
            $each: {
                target: {
                    required,
                    // Note: Since we're mapping from target to address type, we can't have duplicate entries for target
                    targetUnique: function targetUnique(target) {
                        const targetCount = this.value.filter(obj => obj.target === target).length;
                        return targetCount === 1;
                    },
                },
                addressType: {
                    required,
                },
            },
        },
    },
});
