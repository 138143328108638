<template>
    <div>
        <div
            :class="[
                'row d-flex align-items-center tree-item',
                { selected: treeList.id === selectedId, root: hasRootChildren, opened: !isRootOpened },
            ]"
            @click="selectItem(treeList, depth)"
            @mouseover="isRowHovered = true"
            @mouseleave="isRowHovered = false"
        >
            <div class="col-2">
                <img
                    v-if="treeList.icon"
                    :src="treeList.icon"
                    :alt="label"
                />
                <div
                    v-else
                    class="icon"
                />
            </div>
            <div
                :style="{ paddingLeft: paddingLeft }"
                :class="['col-4', { 'empty-root': isRootOpened }]"
                class="content"
            >
                <template>
                    <AppVerticalLine
                        v-if="showVerticalLine"
                        :firstInLine="isRootOpened || (depth === 2 && firstChild)"
                        :uuid="treeList.id"
                        :height="verticalLineHeight"
                    />

                    <!-- Horizontal Line -->
                    <AppHorizontalLine
                        v-if="depth > 1"
                        :uuid="treeList.id"
                    />
                </template>
                <span
                    v-highlight="highlightConfig"
                    :alt="label"
                    class="label"
                >
                    {{ label }}
                </span>
            </div>
            <div class="col-2">
                <template v-if="treeList.offers.length || treeList.children.length">
                    {{ usedIn }}
                </template>
            </div>
            <div class="col-2">
                <EntityStatusIndicator :status="treeList.state" />
            </div>
            <div class="col-2">
                <EntityStatusIndicator :status="treeList.entityVersion" />
            </div>
            <div
                v-if="isRowHovered && hoverButtons && Object.values(hoverButtons).length > 0"
                class="hover-row-buttons pl-1 pr-3"
            >
                <IconButton
                    v-for="button in filterHoverButtons(treeList)"
                    :key="button.key"
                    :label="button.label"
                    :icon="button.icon"
                    @iconClick="handleHoverBtnClick(button, treeList)"
                />
            </div>
        </div>
        <template v-for="(treeItem, index) in sortedChildren">
            <NodeTreeItem
                v-show="isOpenFirst"
                :key="treeItem.id"
                :treeList="treeItem"
                :depth="depth + 1"
                :mapper="mapper"
                :keyName="keyName"
                :firstChild="index === 0"
                :selectedId="selectedId"
                :searchQuery="searchQuery"
                :hoverButtons="hoverButtons"
                @selectedItem="selectItem"
                @hoverBtnClick="$emit('hoverBtnClick', $event)"
            />
        </template>
    </div>
</template>

<script>
import Highlight from 'vue-highlight-text/public/directive.min';
import NodeTreeItem from './NodeTreeItem.vue';
import AppVerticalLine from '@/components/partials/AppVerticalLine.vue';
import EntityStatusIndicator from '@/components/partials/EntityStatusIndicator.vue';
import AppHorizontalLine from '@/components/partials/AppHorizontalLine.vue';
import IconButton from '@/components/partials/IconButton.vue';

export default {
    name: 'NodeTree',
    components: {
        IconButton,
        NodeTreeItem,
        AppVerticalLine,
        AppHorizontalLine,
        EntityStatusIndicator,
    },
    directives: {
        highlight: Highlight,
    },
    props: {
        keyName: {
            type: String,
            default: 'id',
        },
        treeList: {
            type: Object,
            default: () => undefined,
            required: true,
        },
        depth: {
            type: Number,
            default: 1,
        },
        mapper: {
            type: Function,
            default: null,
        },
        firstChild: {
            type: Boolean,
            default: false,
        },
        selectedId: {
            type: String,
            default: null,
        },
        searchQuery: {
            type: String,
            default: '',
        },
        hoverButtons: {
            type: Object,
            default: () => undefined,
        },
    },
    data() {
        return {
            isOpen: false,
            isRowHovered: false,
        };
    },
    computed: {
        label() {
            return this.mapper ? this.mapper(this.treeList) : this.treeList[this.keyName];
        },
        sortedChildren() {
            return this.treeList.children.slice().sort((prev, next) => prev.children.length - next.children.length);
        },
        paddingLeft() {
            return this.depth > 2 ? `${(this.depth - 2) * 24}px` : '';
        },
        hasRootChildren() {
            return this.depth === 1 && this.treeList.children.length;
        },
        isRootEmpty() {
            return this.depth === 1 && !this.treeList.children.length;
        },
        isRootOpened() {
            return this.isRootEmpty || !this.isOpenFirst;
        },
        verticalLineHeight() {
            let height;

            if (this.isRootEmpty || !this.isOpenFirst) {
                height = '8px';
            } else if (this.depth > 2) {
                height = '48px';
            } else {
                height = '52px';
            }

            return height;
        },
        showVerticalLine() {
            return this.depth !== 1 || !this.treeList.children.length || !this.isOpenFirst;
        },
        highlightConfig() {
            return { keyword: this.searchQuery, sensitive: false };
        },
        usedIn() {
            let usedIn = '';

            if (this.treeList.children.length) {
                usedIn = `${this.treeList.children.length} ${
                    this.treeList.children.length === 1 ? 'category' : 'categories'
                }`;
            }

            if (this.treeList.offers.length) {
                if (usedIn.length) {
                    usedIn += ', ';
                }

                usedIn += `${this.treeList.offers.length} ${this.treeList.offers.length === 1 ? 'offer' : 'offers'}`;
            }

            return usedIn;
        },
        isOpenFirst() {
            return this.isOpen || this.depth > 1;
        },
    },
    methods: {
        selectItem(entity, depth) {
            if (depth === 1) {
                this.isOpen = !this.isOpen;
            }

            this.$emit('selectedItem', entity, depth);
        },
        filterHoverButtons(item) {
            if (this.hoverButtons) {
                return Object.values(this.hoverButtons).filter(elem => elem.isVisible(item));
            }
            return [];
        },
        handleHoverBtnClick(button, item) {
            this.$emit('hoverBtnClick', { key: button.key, value: item });
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/palette';
@import '~@/assets/scss/icons';
@import '~@/assets/scss/animations';
@import '~@/assets/scss/z-indexes';

$icon-path: '~@/assets/icons/';

.icon {
    height: 32px;

    &::before {
        content: url($icon-path + $camera);
    }
}

.row {
    height: 48px;
    padding-left: 50px;
    position: relative;

    &:hover {
        background: $blue5;
    }

    &.selected {
        background: $blue15;
    }

    &.root {
        &::before {
            content: url($icon-path + $arrow-up-gray);
            position: absolute;
            left: 17px;
            top: 50%;
            transform: translateY(-50%) rotate(180deg);
            transition: transform $fast-animation-time;
        }

        &.opened {
            .label {
                padding-left: 24px !important;
            }
            &:before {
                transform: translateY(-50%);
            }
        }
    }
}

.hover-row-buttons {
    display: flex;
    align-items: center;
    position: absolute;
    top: 0;
    right: 0;
    z-index: $overlap-smth-z-index;
    height: 100%;
    background-color: $dirty-white;
}

.content {
    max-height: 48px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.label {
    padding-left: 16px;
}

.empty-root {
    svg {
        transform: none;
    }
}
</style>
