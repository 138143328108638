import i18n from '@/i18n';
import { LABEL_COLOR } from '@/common/labelsHelper';

export const OPERATE_APIS_TYPES = {
    APPLICATION: 'application',
    APPLICATION_OWNER: 'application_owner',
    API_PRODUCT_ORDER: 'api_product_order',
    API_PRODUCT: 'api_product',
};

export enum APPROVAL_HISTORY_STATUS {
    PENDING_APPROVAL = 'pendingApproval',
    APPROVED = 'approved',
    REJECTED = 'rejected',
}

export const OPERATE_API_TYPES_TO_LABEL_MAP = new Map([
    [OPERATE_APIS_TYPES.APPLICATION, i18n.t('operateAPIs.application')],
    [OPERATE_APIS_TYPES.APPLICATION_OWNER, i18n.t('operateAPIs.applicationOwner')],
    [OPERATE_APIS_TYPES.API_PRODUCT_ORDER, i18n.t('operateAPIs.apiProductOrder')],
    [OPERATE_APIS_TYPES.API_PRODUCT, i18n.t('operateAPIs.apiProduct')],
]);

export const APPROVAL_HISTORY_STATUS_TO_STATUS_NAME_MAP = new Map([
    [APPROVAL_HISTORY_STATUS.PENDING_APPROVAL, i18n.t('generic.stateMap.pendingApproval')],
    [APPROVAL_HISTORY_STATUS.APPROVED, i18n.t('generic.stateMap.approved')],
    [APPROVAL_HISTORY_STATUS.REJECTED, i18n.t('generic.stateMap.rejected')],
    ['', i18n.t('generic.stateMap.unknown')],
]);

export const APPROVAL_HISTORY_STATUS_INDICATOR_MAP = new Map([
    [APPROVAL_HISTORY_STATUS.PENDING_APPROVAL, LABEL_COLOR.yellow],
    [APPROVAL_HISTORY_STATUS.APPROVED, LABEL_COLOR.green],
    [APPROVAL_HISTORY_STATUS.REJECTED, LABEL_COLOR.red],
]);

export enum API_PRODUCT_ORDER_STATE {
    ACKNOWLEDGED = 'acknowledged',
    REJECTED = 'rejected',
    PENDING = 'pending',
    IN_PROGRESS = 'inProgress',
    HELD = 'held',
    ASSESSING_CANCELLATION = 'assessingCancellation',
    PENDING_CANCELLATION = 'pendingCancellation',
    CANCELLED = 'cancelled',
    COMPLETED = 'completed',
    FAILED = 'failed',
    PARTIAL = 'partial',
}

export const API_PRODUCT_ORDER_STATE_TO_LABEL_MAP = new Map([
    [API_PRODUCT_ORDER_STATE.ACKNOWLEDGED, i18n.t('generic.stateMap.acknowledged')],
    [API_PRODUCT_ORDER_STATE.REJECTED, i18n.t('generic.stateMap.rejected')],
    [API_PRODUCT_ORDER_STATE.PENDING, i18n.t('generic.stateMap.pending')],
    [API_PRODUCT_ORDER_STATE.IN_PROGRESS, i18n.t('generic.stateMap.inProgress')],
    [API_PRODUCT_ORDER_STATE.HELD, i18n.t('generic.stateMap.held')],
    [API_PRODUCT_ORDER_STATE.ASSESSING_CANCELLATION, i18n.t('generic.stateMap.assessingCancellation')],
    [API_PRODUCT_ORDER_STATE.PENDING_CANCELLATION, i18n.t('generic.stateMap.pendingCancellation')],
    [API_PRODUCT_ORDER_STATE.CANCELLED, i18n.t('generic.stateMap.canceled')],
    [API_PRODUCT_ORDER_STATE.COMPLETED, i18n.t('generic.stateMap.completed')],
    [API_PRODUCT_ORDER_STATE.FAILED, i18n.t('generic.stateMap.failed')],
    [API_PRODUCT_ORDER_STATE.PARTIAL, i18n.t('generic.stateMap.partial')],
    ['', i18n.t('generic.stateMap.unknown')],
]);

export const API_PRODUCT_ORDER_STATE_TO_COLOR_MAP = new Map([
    [API_PRODUCT_ORDER_STATE.ACKNOWLEDGED, LABEL_COLOR.yellow],
    [API_PRODUCT_ORDER_STATE.REJECTED, LABEL_COLOR.red],
    [API_PRODUCT_ORDER_STATE.PENDING, LABEL_COLOR.yellow],
    [API_PRODUCT_ORDER_STATE.IN_PROGRESS, LABEL_COLOR.yellow],
    [API_PRODUCT_ORDER_STATE.HELD, LABEL_COLOR.yellow],
    [API_PRODUCT_ORDER_STATE.ASSESSING_CANCELLATION, LABEL_COLOR.yellow],
    [API_PRODUCT_ORDER_STATE.PENDING_CANCELLATION, LABEL_COLOR.yellow],
    [API_PRODUCT_ORDER_STATE.CANCELLED, LABEL_COLOR.red],
    [API_PRODUCT_ORDER_STATE.COMPLETED, LABEL_COLOR.green],
    [API_PRODUCT_ORDER_STATE.FAILED, LABEL_COLOR.red],
    [API_PRODUCT_ORDER_STATE.PARTIAL, LABEL_COLOR.yellow],
]);

export default { OPERATE_APIS_TYPES };
