import { DnoResponseBase } from '@/http/index';
import i18n from '@/i18n';
import { LocaleMessage } from 'vue-i18n';

export enum UM_GROUP_TYPES {
    MY_DEVICES = 1,
    FRIENDS_AND_FAMILY = 2,
}

// TODO: proper mapping for labels once we have more types

function getUMGroupTypeLabel(type: UM_GROUP_TYPES) {
    return type === UM_GROUP_TYPES.MY_DEVICES
        ? i18n.t('customerCareSuite.groupTile.myDevices')
        : i18n.t('customerCareSuite.groupTile.friendsAndFamily');
}

interface UserGroupData {
    notification_info: Record<string, Array<string>>;
    owned_resources: Record<string, Array<string>>;
    properties: {
        nick_name: 'Sandy';
        first_name: 'Sandy';
        birthday: -212918400000;
        last_name: 'Niki';
    };
}

export interface GroupResponse extends DnoResponseBase {
    data: {
        group_info: {
            group_id: string;
            operator_name: string;
            user_owner: string;
            page_size: number;
            group_type: UM_GROUP_TYPES;
            users: Record<string, UserGroupData>;
            properties: {
                group_name: string;
                group_description: string;
            };
        };
    };
}

export class Group {
    constructor(groupResponse: GroupResponse) {
        const groupInfo = groupResponse?.data?.group_info;

        this.id = groupInfo.group_id;
        this.name = groupInfo.properties?.group_name || '';
        this.type = groupInfo.group_type;
        this.typeLabel = getUMGroupTypeLabel(groupInfo.group_type);
        // TODO: mapping can be 1 to many for operators other then GlobeGOMO, would need a slight update
        this.members = Object.entries(groupInfo.users).map(([userId, userGroupData]) => ({
            userId,
            msisdn: userGroupData.notification_info.msisdn[0],
        }));
    }

    id: string;

    name: string;

    type: number;

    typeLabel: LocaleMessage;

    members: Array<Record<string, string>>;
}

export default {
    Group,
};
