<template>
    <div>
        <OeExecutionsHierarchyRow
            :key="treeList.executionId"
            :treeList="treeList"
            :depth="depth"
            :keyName="keyName"
            :firstChild="firstChild"
            :columnsData="columnsData"
        />
    </div>
</template>

<script>
const OeExecutionsHierarchyRow = () => import('./OeExecutionsHierarchyRow.vue');

export default {
    name: 'OeExecutionsHierarchyItem',
    components: {
        OeExecutionsHierarchyRow,
    },
    props: {
        keyName: {
            type: String,
            default: 'id',
            required: true,
        },
        treeList: {
            type: Object,
            default: () => undefined,
            required: true,
        },
        columnsData: {
            type: Array,
            default: () => [],
            required: true,
        },
        depth: {
            type: Number,
            default: 1,
        },
        firstChild: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        selectItem(entity) {
            this.$emit('selectedItem', entity);
        },
    },
};
</script>
