import { TIME_FREQUENCY } from '@/common/timeFrequencyHelper';

export default class CronExpression {
    minute: string;
    hour: string;
    monthDay: string;
    weekDay: string;

    constructor(data: Partial<CronExpression> = {}) {
        this.minute = data.minute || '0';
        this.hour = data.hour || '0';
        this.monthDay = data.monthDay || '*';
        this.weekDay = data.weekDay || '*';
    }

    get value() {
        return [this.minute, this.hour, this.monthDay, '*', this.weekDay].join(' ');
    }

    setMinuteFrequency(frequency: number) {
        const freq = frequency / 60;

        this.monthDay = '*';
        this.weekDay = '*';

        if (Number.isInteger(freq)) {
            this.minute = '0';
            this.hour = `*/${freq}`;
        } else if (freq < 1) {
            this.minute = `*/${Math.round(freq * 60)}`;
            this.hour = `*`;
        } else {
            this.minute = `${Math.round((freq % 1) * 60)}`;
            this.hour = `*/${Math.trunc(freq)}`;
        }
    }

    static getArgumentsFromString(cron: string): Omit<CronExpression, 'value' | 'setMinuteFrequency'> {
        const [minute, hour, monthDay, , weekDay] = cron.split(' ');
        return { minute, hour, monthDay, weekDay };
    }

    static createFromString(cron: string) {
        return new CronExpression(CronExpression.getArgumentsFromString(cron));
    }

    static getTimeFreqFromCronValue(cron: string): TIME_FREQUENCY {
        const { minute, hour, monthDay, weekDay } = CronExpression.getArgumentsFromString(cron);
        const hasTimeInterval = [minute, hour].some(i => i?.includes('*'));
        const hasTimeFixed = [Number(minute), Number(hour)].every(Number.isInteger);
        const hasWeekDay = weekDay !== '*';
        const hasMonthDay = monthDay !== '*';

        switch (true) {
            case hasTimeInterval && !hasWeekDay && !hasMonthDay:
                return TIME_FREQUENCY.MINUTES;

            case hasTimeFixed && !hasWeekDay && !hasMonthDay:
                return TIME_FREQUENCY.DAILY;

            case hasWeekDay && !hasMonthDay && hasTimeFixed:
                return TIME_FREQUENCY.WEEKLY;

            case hasMonthDay && !hasWeekDay && hasTimeFixed:
                return TIME_FREQUENCY.MONTHLY;

            default:
                return TIME_FREQUENCY.CRON;
        }
    }
}
