<template>
    <AppDialogV2
        :title="formTitle"
        :visible="value"
        class="cancel-deactivate-modal"
        @close="close()"
        @submit="onSubmit()"
    >
        <div class="form-content">
            <h3 class="body-sm bold blue mb-3">
                {{ $i18n.t('customerCare.cancelDeactivateServiceModal.informAboutDeactivation') }}
            </h3>
            <div class="reason mb-3">
                <h3 class="heading-xs blue mb-2">
                    {{ $i18n.t('customerCare.cancelDeactivateServiceModal.serviceCancellationReason') }}
                    <span :title="$i18n.t('generic.validations.fieldIsRequired')">*</span>
                </h3>

                <AppMultiselectV3
                    v-model="reason"
                    :options="reasonOptions"
                    :error="$v.reason.$error"
                    :small="true"
                    :errorMessage="$i18n.t('customerCare.cancelDeactivateServiceModal.pleaseSelectReasonCode')"
                />
            </div>

            <div class="mb-3">
                <h3 class="heading-xs blue mb-2">
                    {{ $i18n.t('generic.notes') }}
                    <span
                        v-if="reason === $i18n.t('customerCare.cancelDeactivateServiceModal.other')"
                        :title="$i18n.t('generic.validations.fieldIsRequired')"
                        >*</span
                    >
                </h3>

                <CustomTextarea
                    v-model="notesTxt"
                    :placeholder="$i18n.t('generic.addNotes')"
                    :invalid="isNotesInvalid"
                    class="input"
                />

                <p
                    v-show="isNotesInvalid"
                    class="error-message mt-2"
                >
                    {{ $i18n.t('customerCare.cancelDeactivateServiceModal.pleaseAddAnAgentNote') }}
                </p>
            </div>
        </div>
    </AppDialogV2>
</template>

<script>
// Components
import AppDialogV2 from '@/components/partials/AppDialogV2.vue';
import { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import CustomTextarea from '@/__new__/features/customerCare/subscriber/CustomTextarea.vue';
import AppMultiselectV3 from '@/components/partials/inputs/AppMultiselectV3.vue';
// http
import userManagementHTTP from '@/__new__/services/dno/user/http/user-management';
// Helpers
import { USER_MANAGER_HIERARCHY } from '@/__new__/features/customerCare/common/customerCareHelper';
import { required } from 'vuelidate/lib/validators';
import Actions from '@/store/mutation-types';
import { mapActions } from 'vuex';
import { Modules } from '@/store/store';
import notesHTTP from '@/__new__/services/dno/crm/http/crm';
import { dateToEpoch } from '@/common/formatting';

export default {
    name: 'CancelDeactivateService',
    components: {
        AppDialogV2,
        CustomTextarea,
        AppMultiselectV3,
    },
    props: {
        value: {
            required: true,
            type: Boolean,
        },
        accountId: {
            default: '',
            type: String,
        },
        isOrderActive: {
            default: false,
            type: Boolean,
        },
    },
    data() {
        return {
            reason: '',
            notesTxt: '',
            shouldUnblockIMEI: false,
            BUTTON_TYPES,
        };
    },
    computed: {
        isNotesInvalid() {
            return this?.$v?.notesTxt?.$error;
        },
        reasonOptions() {
            return [
                this.$i18n.t('customerCare.cancelDeactivateServiceModal.poorCustomerExperience'),
                this.$i18n.t('customerCare.cancelDeactivateServiceModal.billingPromotionalOfferIssue'),
                this.$i18n.t('customerCare.cancelDeactivateServiceModal.slowSpeedUnreliableConnection'),
                this.$i18n.t('customerCare.cancelDeactivateServiceModal.unresolvedServiceIssue'),
                this.$i18n.t('customerCare.cancelDeactivateServiceModal.receivedBetterOfferElsewhere'),
                this.$i18n.t('customerCare.cancelDeactivateServiceModal.tooExpensivePriceWentUp'),
                this.$i18n.t('customerCare.cancelDeactivateServiceModal.movedMoving'),
                this.$i18n.t('customerCare.cancelDeactivateServiceModal.duplicateAccount'),
                this.$i18n.t('customerCare.cancelDeactivateServiceModal.addressAndOrEligibilityIssue'),
                this.$i18n.t('customerCare.cancelDeactivateServiceModal.other'),
            ];
        },
        formTitle() {
            return this.$i18n.t('customerCare.cancelTerminate.cancelTerminateSubscription');
        },
    },
    validations() {
        return {
            reason: {
                required,
            },
            notesTxt: {
                required:
                    this.reason === this.$i18n.t('customerCare.cancelDeactivateServiceModal.other') ? required : false,
            },
        };
    },
    methods: {
        ...mapActions(Modules.customerCareSuite, [Actions.LOAD_ASSOCIATED_UM_ENTITIES]),
        close() {
            this.$emit('close');
        },
        onSubmit() {
            this.$v.$touch();
            if (this.$v.$invalid) {
                return;
            }

            this.submit();
            this.close();
        },
        async submit() {
            await this.cancelService();
        },
        async cancelService() {
            await this.$withProgressBar(
                async () => {
                    if (this.isOrderActive) {
                        await userManagementHTTP.updateFlags(this.accountId, USER_MANAGER_HIERARCHY.ACCOUNT, {
                            cancel_requested: true,
                        });
                        await userManagementHTTP.updateInfo(this.accountId, USER_MANAGER_HIERARCHY.ACCOUNT, {
                            cancel_reason: this.reason,
                        });
                    } else {
                        await userManagementHTTP.cancelServices({
                            targetId: this.accountId,
                            targetType: USER_MANAGER_HIERARCHY.ACCOUNT,
                            cancelReason: this.reason,
                        });
                    }

                    await notesHTTP.addNote({
                        id: this.accountId,
                        idType: USER_MANAGER_HIERARCHY.ACCOUNT,
                        epoch: dateToEpoch(Date.now()),
                        noteText: this.reason ? `${this.reason} : ${this.notesTxt}` : this.notesTxt,
                        tags: [this.$i18n.t('generic.stateMap.canceled')],
                    });
                    await this[Actions.LOAD_ASSOCIATED_UM_ENTITIES]({
                        targetType: USER_MANAGER_HIERARCHY.ACCOUNT,
                        targetId: this.accountId,
                    });
                    this.close();
                },
                {
                    errorHandler: () =>
                        this.$showErrorAlert({
                            message: this.$i18n.t('customerCare.cancelRequest.alerts.cancelRequestFailed'),
                        }),
                },
            );
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/_consistency';

.form-content {
    padding: $spacing-m $spacing-xxxl;
}

.cancel-deactivate-modal {
    width: 100%;
    height: 100%;
}

.cancel-deactivate-modal ::v-deep .content {
    max-height: 100% !important;
}

.error-message {
    color: $red;
}

#app .reason ::v-deep .multiselect__tags {
    padding-left: $spacing-xs;
}

.form-content ::v-deep textarea {
    width: 100%;
    padding-inline: $spacing-xs;
    border-radius: 0.5rem;

    &::placeholder {
        font-style: italic;
        opacity: 1;
        font-size: inherit;
    }
}
</style>
