
import Vue from 'vue';
import AppMultiselectV3 from '@/components/partials/inputs/AppMultiselectV3.vue';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
// http
import ordersHTTP from '@/__new__/services/dno/orders/http/orders';
// helpers
import Order from '@/__new__/services/dno/orders/models/Order';
import {
    ORDER_ENTITY_STATES,
    ORDER_ENTITY_STATES_TO_STATUS_NAME_MAP,
} from '@/__new__/features/customerCare/common/orderStateHelper';

type OrderStateOption = {
    id: ORDER_ENTITY_STATES;
    label: typeof ORDER_ENTITY_STATES_TO_STATUS_NAME_MAP extends Map<any, infer T> ? T : never;
    $isDisabled?: boolean;
};

const orderEntityStatesToOptions: OrderStateOption[] = Object.values(ORDER_ENTITY_STATES).reduce(
    (acc: any[], state: ORDER_ENTITY_STATES | string) => {
        if (Number.isInteger(state) && state !== ORDER_ENTITY_STATES.ACTIVE_PLAN_CHANGE) {
            acc.push({
                id: state,
                label: ORDER_ENTITY_STATES_TO_STATUS_NAME_MAP.get(state as ORDER_ENTITY_STATES),
            });
        }
        return acc;
    },
    [] as OrderStateOption[],
);

export default Vue.extend({
    name: 'ChangeOrderStatusForm',
    components: { AppButton, AppMultiselectV3 },
    props: {
        targetTypeId: {
            type: String,
            required: true,
        },
        targetType: {
            type: Number,
            required: true,
        },
        order: {
            type: Order,
            required: true,
        },
    },
    data() {
        return {
            BUTTON_TYPES,
            editOrderState: null as OrderStateOption | null,
            isSubmitting: false,
            orderStateOptions: [...orderEntityStatesToOptions],
        };
    },
    created() {
        this.setCurrentOrderStatus();
    },
    methods: {
        setCurrentOrderStatus() {
            this.editOrderState = this.orderStateOptions.find(({ id }) => id === this.order.state) || null;
            if (this.editOrderState) {
                this.editOrderState.$isDisabled = true;
            }
        },
        resetOrderStatusData() {
            this.editOrderState = null;
            this.orderStateOptions.forEach(opt => {
                if (opt.$isDisabled) {
                    opt.$isDisabled = false;
                }
            });
            this.$emit('close');
        },
        updateOrderStatus() {
            this.$withProgressBar(
                async () => {
                    if (!this.editOrderState) {
                        return;
                    }

                    this.isSubmitting = true;
                    await ordersHTTP.upsertOrderState({
                        targetId: this.targetTypeId,
                        targetType: this.targetType,
                        orderId: this.order.orderId,
                        orderState: this.editOrderState.id,
                    });
                    this.isSubmitting = false;
                    this.$emit('refreshData');
                    this.resetOrderStatusData();
                },
                {
                    errorHandler: () => {
                        this.isSubmitting = false;
                        this.$alert(this.$i18n.t('alertMessage.userManagement.somethingWentWrongUpdatingOrderState'));
                    },
                },
            );
        },
    },
});
