/* eslint-disable camelcase */
import { getMultiLangFieldValueByLocale } from '@/common/entities/entityHelper';
import { resourceReservedKeys } from '@/__new__/features/pc/common/entitiesReservedKeys';
import { STATUS_CODE_TO_STATUS_NAME_MAP } from '@/common/commonHelper';
import localeLibrary from '@/common/locale/localeLibrary';

export class ProductCatalogEntityBaseModel {
    id;

    name;

    description;

    state;

    stateLabel;

    entityType;

    templateId;

    externalId;

    properties;

    version;

    entityVersion;

    entityVersionLabel;

    updateTime;

    updateTimeFormatted;

    updateUser;

    childEntities;

    isDraft;

    constructor(entityJson) {
        const entityData = entityJson.data;
        // properties from entityJson root
        this.id = entityJson.id;
        this.templateId = entityJson.template_id || '';
        this.entityType = entityJson.entityType;
        this.state = entityJson.state;
        this.stateLabel = STATUS_CODE_TO_STATUS_NAME_MAP.get(entityJson.state);
        this.version = entityJson.version;
        this.updateTime = entityJson.update_time || null;
        this.updateTimeFormatted = localeLibrary.getFormattedDateAndTime(entityJson.update_time);
        this.updateUser = entityJson.update_portal_id || entityJson.portal_id || null;
        this.externalId = entityData.external_id || '';
        this.isDraft = entityJson.isDraft || false;
        this.entityVersion = entityJson.entityVersion || null;
        this.entityVersionLabel = STATUS_CODE_TO_STATUS_NAME_MAP.get(entityJson.entityVersion);

        // properties from entityJson data property
        this.name = getMultiLangFieldValueByLocale(entityData.name);
        this.description = getMultiLangFieldValueByLocale(entityData.description);

        this.childEntities = entityData.relationships || [];
        // this.parentEntities = entityJson.active_referenced_by || [];

        this.properties = Object.keys(entityJson.data)
            .filter(key => !resourceReservedKeys.includes(key))
            .reduce((result, key) => {
                result[key] = entityJson.data[key];
                return result;
            }, {});
    }

    // to parse data for the API call
    static toJson(entityData) {
        const entityDataFormatted = {
            name: entityData.name,
            description: entityData.description && entityData.description !== 'N/A' ? entityData.description : '',
        };

        if (entityData.externalId) {
            entityDataFormatted.external_id = entityData.externalId;
        }

        if (entityData.childEntities) {
            entityDataFormatted.relationships = entityData.childEntities.map(relationship => ({
                id: relationship.id,
                type: relationship.entityType,
            }));
        }

        return entityDataFormatted;
    }
}

export default ProductCatalogEntityBaseModel;
