import i18n from '@/i18n';
import { toDisplayValue } from '@/__new__/features/pc/common/entityOverviewUtils';

export function overviewContentBuilder(entityType, facet) {
    const facetEnumValues = facet?.properties.schema.enum.join(', ');
    const overviewProps = {
        name: i18n.t('generic.general'),
        isCollapsed: false,
        rows: [
            {
                value: toDisplayValue(facet.id),
                name: i18n.t('generic.id'),
            },
            {
                value: toDisplayValue(facet?.properties['reference_preset_template_id']),
                name: i18n.t('productCatalog.facets.presetTemplateId'),
            },
            {
                value: toDisplayValue(facet?.properties['reference_external_id']),
                name: i18n.t('productCatalog.facets.propExternalId'),
            },
            {
                value: toDisplayValue(facetEnumValues),
                name: i18n.t('productCatalog.facets.facetValues'),
            },
        ],
    };

    return [overviewProps];
}
