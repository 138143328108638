
import Vue from 'vue';

// vuex
import { mapGetters, mapActions } from 'vuex';
import Actions, { Getters } from '@/store/mutation-types';
import { Modules } from '@/store/store';

// Components
import AbstractEditPageWrapper from '@/components/layout/AbstractEditPageWrapper.vue';
import AppHeader from '@/components/layout/AppHeader.vue';
import AppInputV3 from '@/components/partials/inputs/AppInputV3.vue';
import AppMultiselectV3 from '@/components/partials/inputs/AppMultiselectV3.vue';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import DataflowModelSection from '@/__new__/features/dataflows/DataflowModelSection.vue';
import DataflowsConfigDialog from '@/__new__/features/dataflows/DataflowsConfigDialog.vue';

// Http
import {
    dataflowValidation,
    createDataflow,
    exportDataflow,
    type DataflowRequestParams,
} from '@/__new__/services/dno/dataflows/http/dataflows';

// Validations
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';

// Models
import DataflowSectionModel from '@/__new__/services/dno/dataflows/models/DataflowSectionModel';
import type { Tab } from '@/__new__/features/resources/BulkUploadTabs.vue';
import type DataflowJob from '@/__new__/services/dno/dataflows/models/DataflowJob';

// Helpers
import type { DataflowTemplate } from '@/__new__/features/dataflows/common/DataflowInterfaces';
import {
    createDataflowApiPayloadData,
    convertDataflowNameOfId,
} from '@/__new__/features/dataflows/common/dataflowHelper';
import * as Sentry from '@sentry/vue';
import RouteNames from '@/router/routeNames';
import { ICON_TYPES } from '@/common/iconHelper';

interface ExportTab extends Tab {
    id: string;
    yaml?: string;
}
export default Vue.extend({
    name: 'DataflowsEditor',
    components: {
        AbstractEditPageWrapper,
        AppHeader,
        AppInputV3,
        AppMultiselectV3,
        AppButton,
        DataflowModelSection,
        DataflowsConfigDialog,
    },
    mixins: [validationMixin],
    data() {
        return {
            BUTTON_TYPES,
            ICON_TYPES,
            dataflowName: '' as string,
            dataflowId: '' as string,
            selectedTemplate: null as DataflowTemplate | null,
            dataflowTree: {} as Record<DataflowTemplate['id'], DataflowSectionModel[]>,
            data: {} as ReturnType<typeof createDataflowApiPayloadData>,
            dataflowActions: [] as string[],
            isDataflowValidated: false,
            // export
            exportTabSelected: false as DataflowJob['app'] | boolean,
            exportTabs: [] as ExportTab[],
            exportConfig: '',
        };
    },
    validations: {
        dataflowName: {
            required,
        },
        selectedTemplate: {
            id: {
                required,
            },
        },
    },
    computed: {
        ...mapGetters(Modules.dataflows, [Getters.GET_DATAFLOW_TEMPLATES, Getters.GET_DATAFLOW_TEMPLATE_UI_MODEL]),
        getAllTemplates(): DataflowTemplate[] {
            return this[Getters.GET_DATAFLOW_TEMPLATES] || [];
        },
        getDataflowUiModel(): DataflowSectionModel[] {
            return this[Getters.GET_DATAFLOW_TEMPLATE_UI_MODEL] || [];
        },
        getSelectedTemplateDesc(): string {
            return this.selectedTemplate?.description || '';
        },
        getSelectedTemplateId(): DataflowTemplate['id'] {
            return this.selectedTemplate?.id || '';
        },
        getSelectedDataflowTreeValues(): DataflowSectionModel[] {
            return this.dataflowTree?.[this.getSelectedTemplateId] || [];
        },
        requestData(): DataflowRequestParams {
            return {
                blueprintId: this.getSelectedTemplateId,
                dataflowId: this.dataflowId,
                data: {
                    name: this.dataflowName,
                    ui_input: this.data,
                },
            };
        },
    },
    watch: {
        getSelectedDataflowTreeValues() {
            this.dataflowActions = [];
        },
        exportTabs(tabs: ExportTab[]) {
            this.exportTabSelected = tabs[0]?.id;
        },
        exportTabSelected(tabId: ExportTab['id'] | boolean) {
            this.exportConfig = this.exportTabs.find(({ id }) => id === tabId)?.yaml || '';
        },
    },
    created() {
        this.$withLoadingSpinner(
            async () => {
                await this[Actions.REQUEST_DATAFLOW_TEMPLATES]();
            },
            {
                errorHandler: () => {
                    this.$alert(this.$t('alertMessage.failedToLoadNecessaryData'));
                },
            },
        );
    },
    methods: {
        ...mapActions(Modules.dataflows, [
            Actions.REQUEST_DATAFLOW_TEMPLATES,
            Actions.REQUEST_DATAFLOW_TEMPLATE_UI_MODEL,
        ]),
        createDataflowId(name: string): void {
            this.dataflowName = name.trim();
            this.dataflowId = convertDataflowNameOfId(this.dataflowName);
        },
        templateSelected(template: DataflowTemplate): void {
            this.$withLoadingSpinner(
                async () => {
                    if (!this.dataflowTree?.[template.id]) {
                        this.isDataflowValidated = false;
                        await this[Actions.REQUEST_DATAFLOW_TEMPLATE_UI_MODEL](template.id);
                        const data = this.getDataflowUiModel?.map(e => new DataflowSectionModel(e)) || [];

                        Vue.set(this.dataflowTree, template.id, data);
                    }
                },
                {
                    errorHandler: (e: any) => {
                        this.$alert(e?.response?.data?.message || this.$t('alertMessage.failedToLoadNecessaryData'));
                    },
                },
            );
        },
        validateDataflow(): void {
            if (!this.checkDataBeforeSave()) {
                return;
            }

            this.$withLoadingSpinner(
                async () => {
                    this.data = createDataflowApiPayloadData(this.getSelectedDataflowTreeValues);
                    const res = await dataflowValidation(this.requestData);

                    this.dataflowActions = res?.data?.data.actions;
                    this.isDataflowValidated = true;
                },
                {
                    errorHandler: (e: any) => {
                        this.isDataflowValidated = false;
                        this.$eventBus.$emit('showAlert', {
                            message: e?.response?.data?.message || this.$t('alertMessage.failedToLoadNecessaryData'),
                        });
                    },
                },
            );
        },
        checkDataBeforeSave(): boolean {
            this.$v.$touch();

            if (this.$v.$invalid || this.validateSelectedDataflowTree(this.getSelectedDataflowTreeValues)) {
                this.$alert(this.$t('alertMessage.helpers.invalidStringPassed'));
                return false;
            }

            return true;
        },
        validateSelectedDataflowTree(data: DataflowSectionModel[]): boolean {
            let error = false;

            data?.forEach(section => {
                if (section.enabled) {
                    section.elements.forEach(element => {
                        if (element.isSection) {
                            error = this.validateSelectedDataflowTree(section.elements);
                        } else if (element.required && element.value === null) {
                            element.error = true;
                            error = true;
                        }
                    });
                }
            });

            return error;
        },
        createDataflow(): void {
            this.$withLoadingSpinner(
                async () => {
                    this.$Progress.start();
                    this.data = createDataflowApiPayloadData(this.getSelectedDataflowTreeValues);
                    await createDataflow(this.requestData);

                    this.$Progress.finish();
                    this.$showSuccessAlert({
                        message: this.$t('dataflows.dataflowCreated'),
                    });

                    setTimeout(
                        () =>
                            this.$router.push({
                                name: RouteNames.DATAFLOWS,
                                params: { companyId: this.$route.params.companyId },
                            }),
                        1001,
                    );
                },
                {
                    errorHandler: (e: any) => {
                        this.$Progress.fail();
                        this.isDataflowValidated = false;
                        Sentry.captureException(e);
                        this.$alert(e?.response?.data?.message || this.$t('alertMessage.failedToLoadNecessaryData'));
                    },
                },
            );
        },
        onExportDialogOpen() {
            const id = this.$t('generic.loading');
            this.exportTabs = [{ id, label: `${id}...` }];
            this.exportDataflow();
        },
        exportDataflow(): Promise<void> {
            return this.$withProgressBar(
                async () => {
                    const res = await exportDataflow(this.requestData);
                    this.exportTabs = res?.data?.data?.entities.map(({ app_name: id, yaml }) => ({
                        id,
                        label: id,
                        yaml,
                    }));
                },
                {
                    errorHandler: (e: any) => {
                        this.exportConfig = this.$t('generic.error');
                        this.$alert(e?.response?.data || this.$t('alertMessage.failedToLoadNecessaryData'));
                    },
                },
            );
        },
    },
});
