import { AxiosPromise } from 'axios';
import http, { DnoResponseBase } from '@/http/index';

interface PolicyRulesApiResponse extends DnoResponseBase {
    policy_rule_by_id: Record<string, any>;
}

/**
 * Get policy rules.
 * @returns {AxiosPromise}
 */
export function getPolicyRules(
    ids: string[] = [],
    returnChargingVersion = true,
    includeReferencedEntities = false,
    includePortalId = true,
    chargingVersion?: string,
): AxiosPromise<PolicyRulesApiResponse> {
    return http({
        method: 'POST',
        url: '/v3charging/getpolicyrules',
        data: {
            ids,
            return_charging_version: returnChargingVersion,
            include_referenced_entities: includeReferencedEntities,
            charging_version: chargingVersion,
            include_portal_id: includePortalId,
        },
    });
}

/**
 * Add policy rules.
 * @returns {AxiosPromise}
 */
export function addPolicyRules(data: any): AxiosPromise<DnoResponseBase> {
    return http({
        method: 'POST',
        url: '/v3chargingwrite/addpolicyrules',
        data,
    });
}

/**
 * Update policy rules.
 * @returns {AxiosPromise}
 */
export function updatePolicyRules(id: string, version: number, data: any): AxiosPromise<DnoResponseBase> {
    return http({
        method: 'POST',
        url: '/v3chargingwrite/updatepolicyrules',
        data: {
            id,
            version,
            data,
        },
    });
}

/**
 * Update policy rules state.
 * @returns {AxiosPromise}
 */
export function updatePolicyRulesState(id: string, version: number, state: number): AxiosPromise<DnoResponseBase> {
    return http({
        method: 'POST',
        url: '/v3chargingwrite/updatestatepolicyrules',
        data: {
            id,
            version,
            state,
        },
    });
}

interface PolicyRulesDraftListApiResponse extends DnoResponseBase {
    data: any;
}

/**
 * Get policy rules draft list.
 *
 * @returns {AxiosPromise}
 */
export function getPolicyRulesDraftList(): AxiosPromise<PolicyRulesDraftListApiResponse> {
    return http({
        method: 'POST',
        url: '/v3charging/getpolicyrulesdraftlist',
    });
}

/**
 * Get policy rule entity draft.
 *
 * @returns {AxiosPromise}
 */
export function getPolicyRuleEntityDraft(id: string): AxiosPromise<DnoResponseBase> {
    return http({
        method: 'POST',
        url: '/v3charging/getpolicyrulerdraft',
        data: {
            id,
        },
    });
}

/**
 * Set policy rule draft.
 *
 * @returns {AxiosPromise}
 */
type PolicyRuleDraftPayload = {
    id?: string;
    data: object;
};

export function setPolicyRuleDraft(data: PolicyRuleDraftPayload): AxiosPromise<DnoResponseBase> {
    return http({
        method: 'POST',
        url: '/v3charging/setpolicyrulerdraft',
        data,
    });
}

/**
 * Delete policy rule draft.
 *
 * @returns {AxiosPromise}
 */
export function deletePolicyRuleDraft(id: string): AxiosPromise<DnoResponseBase> {
    return http({
        method: 'POST',
        url: '/v3charging/deletepolicyrulerdraft',
        data: {
            id,
        },
    });
}

export default {
    getPolicyRules,
    addPolicyRules,
    updatePolicyRules,
    updatePolicyRulesState,
    getPolicyRulesDraftList,
    getPolicyRuleEntityDraft,
    setPolicyRuleDraft,
    deletePolicyRuleDraft,
};
