<template>
    <AppDialogV2
        :visible="visible"
        :title="title"
        @close="onCloseModal"
        @submit="onSave"
    >
        <template v-if="stateType === STATE_TYPES.CHOICE">
            <template v-if="!addingNewStateWithinChoices">
                <div class="body-sm bold mb-2">
                    {{ $t('orchestrationEngine.updateDiagramModal.selectNextStates') }}
                </div>
                <AppMultiselectV3
                    v-model="selectedChoiceStates"
                    :options="optionsForNextStates"
                    :multiple="true"
                    :error="$v.selectedChoiceStates.$error"
                    :placeholder="$i18n.t('orchestrationEngine.updateDiagramModal.nextStates')"
                    class="mb-2"
                    @select="onSelectChoiceState"
                    @remove="onRemoveChoiceState"
                />
                <div class="form-content mb-4">
                    <div class="body-sm bold mb-2">
                        {{ $t('orchestrationEngine.updateDiagramModal.defaultState') }}
                    </div>
                    <AppMultiselectV3
                        v-model="defaultState"
                        :options="selectedChoiceStates"
                        :disabled="selectedChoiceStates.length < 2"
                        :error="Boolean(selectedChoiceStates.length) && $v.defaultState.$error"
                        :placeholder="$t('orchestrationEngine.updateDiagramModal.selectDefaultState')"
                    />
                </div>
            </template>
            <div
                v-if="areConditionsVisible"
                class="form-content mb-2"
            >
                <div
                    v-if="!addingNewStateWithinChoices"
                    class="body-sm bold mb-2"
                >
                    {{ $t('orchestrationEngine.updateDiagramModal.conditions.conditions') }}
                </div>
                <OECondition
                    v-for="(state, index) in selectedChoiceStatesToDisplay"
                    :key="`condition-model-${index}`"
                    :stateWithConditions="state"
                    :index="index"
                    :stateName="state.name"
                    :validationResult="validationResult"
                    @updatedCondition="updateCondition($event)"
                />
            </div>
        </template>

        <template v-if="stateType === STATE_TYPES.CATCH">
            <div class="heading-md mb-2">
                {{ $t('orchestrationEngine.updateDiagramModal.statesOfCatch') }}
            </div>
            <div
                v-for="(oneCatchState, index) in catches"
                :key="`catch-number-${index}`"
                class="border-bottom"
                data-test-id="one-catch-state-container"
            >
                <div
                    class="d-flex justify-content-between align-items-center"
                    :class="{ 'mt-3': index > 0 }"
                >
                    <div class="state-label">
                        {{ `${$t('orchestrationEngine.updateDiagramModal.stateNumber')} ${index + 1}` }}
                    </div>
                    <img
                        v-if="catches.length > 1"
                        src="@/assets/icons/close.svg"
                        class="delete-btn col-1"
                        @click="deleteStateFromCatch(index)"
                    />
                </div>
                <AppMultiselectV3
                    v-model="oneCatchState.name"
                    :options="optionsForNextStates"
                    :placeholder="$i18n.t('orchestrationEngine.updateDiagramModal.selectState')"
                    class="mt-2"
                    :class="{ 'mb-2': !oneCatchState.name.length }"
                />
                <OECondition
                    v-if="oneCatchState.name"
                    :key="`condition-model-${index}`"
                    :stateWithConditions="oneCatchState"
                    :index="index"
                    :validationResult="{}"
                    @updatedCondition="updateCondition($event)"
                />
            </div>
            <AppButton
                :label="$i18n.t('orchestrationEngine.updateDiagramModal.addState')"
                :iconType="ICON_TYPES.PLUS"
                :isSmall="true"
                class="px-0 ml-1 mt-2"
                @click="addNewCatchState"
            />
        </template>

        <template v-if="stateType === STATE_TYPES.LoopV2">
            <div class="heading-md mb-2 d-flex align-items-center">
                {{ $i18n.t('orchestrationEngine.updateDiagramModal.loopStates') }}
                <AppTooltip
                    class="ml-2"
                    :tooltipPosition="TOOLTIP_POSITION.right"
                >
                    <template slot="label">
                        <div class="info-icon" />
                    </template>

                    <template slot="content">
                        <div class="tooltip-info">
                            {{ $i18n.t('orchestrationEngine.updateDiagramModal.loopStatesExplanation') }}
                        </div>
                    </template>
                </AppTooltip>
            </div>
            <Draggable
                :value="loopStates"
                handle=".draggable-handle"
                @input="changeFormattedLoopStates"
            >
                <div
                    v-for="(oneLoopState, index) in loopStates"
                    :key="`loop-state-number-${index}`"
                >
                    <div
                        class="d-flex justify-content-between align-items-center"
                        :class="{ 'mt-3': index > 0 }"
                    >
                        <div class="state-label">
                            {{ `${$t('orchestrationEngine.updateDiagramModal.stateNumber')} ${index + 1}` }}
                        </div>
                        <img
                            v-if="loopStates.length > 1"
                            src="@/assets/icons/close.svg"
                            class="delete-btn col-1"
                            @click="deleteStateFromLoop(index, oneLoopState)"
                        />
                    </div>
                    <div class="draggable-wrapper">
                        <div class="draggable-handle" />

                        <AppMultiselectV3
                            v-model="oneLoopState.name"
                            :options="optionsForLoopStates"
                            :placeholder="$i18n.t('orchestrationEngine.updateDiagramModal.selectState')"
                            class="mt-2 mb-2 loop-multiselect"
                            :error="$v.loopStates.$each[index].name.$error"
                            @select="selectLoopState"
                        />
                    </div>
                </div>
                <AppButton
                    :label="$i18n.t('orchestrationEngine.updateDiagramModal.addState')"
                    :iconType="ICON_TYPES.PLUS"
                    :isSmall="true"
                    :disabled="!optionsForLoopStates.length"
                    class="px-0 ml-1 mt-4"
                    @click="addNewLoopState"
                />
            </Draggable>
        </template>
    </AppDialogV2>
</template>

<script>
// Components
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import { ICON_TYPES } from '@/common/iconHelper';
import AppDialogV2 from '@/components/partials/AppDialogV2.vue';
import AppMultiselectV3 from '@/components/partials/inputs/AppMultiselectV3.vue';
import OECondition from '@/__new__/features/orchestrationengine/OECondition.vue';
import Draggable from 'vuedraggable';
import AppTooltip from '@/components/partials/AppTooltip.vue';

// Helpers
import {
    STATE_TYPES,
    operatorOptionsForDiagramUpdate,
    formatValueBasedOnSelectedOperator,
    FLAG_VALUE_OPTIONS,
    IS_NOT_ENUMS,
    formatValueBasedOnOperator,
} from '@/__new__/features/orchestrationengine/common/orchestrationDiagramUpdateHelper';
import { validationMixin } from 'vuelidate';
import { required, requiredIf } from 'vuelidate/lib/validators';
import { TOOLTIP_POSITION } from '@/common/tooltip';

export default {
    name: 'DiagramUpdateModal',
    components: {
        AppTooltip,
        Draggable,
        AppButton,
        AppDialogV2,
        AppMultiselectV3,
        OECondition,
    },
    mixins: [validationMixin],
    props: {
        visible: {
            required: true,
            type: Boolean,
        },
        stateForUpdate: {
            type: String,
            default: '',
        },
        optionsForNextStates: {
            type: Array,
            default: () => [],
        },
        connectedToStates: {
            type: Array,
            default: () => [],
        },
        addingNewStateWithinChoices: {
            type: Boolean,
            default: false,
        },
        stateType: {
            type: String,
            default: STATE_TYPES.CHOICE,
        },
        selectedLoopStates: {
            type: Array,
            default: () => [],
        },
        selectedStatesForEdit: {
            type: Array,
            default: () => [],
        },
        selectedDefaultStateForChoiceEdit: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            states: [],
            validationResult: {},

            // Catch
            catches: [],

            // Choice
            defaultState: null,
            selectedChoiceStates: [],
            unselectedFromNextStates: false,
            selectedDefaultStateBefore: null,
            selectedChoiceStatesToDisplay: [],

            // Loop
            loopStates: [],
            optionsForLoopStates: [],

            // Proxy imports
            ICON_TYPES,
            BUTTON_TYPES,
            STATE_TYPES,
            TOOLTIP_POSITION,
        };
    },
    validations: {
        states: {
            $each: {
                conditions: {
                    $each: {
                        variable: {
                            required,
                            validation: value => {
                                if (value) {
                                    return value.startsWith('$.') || value.startsWith('$$.');
                                }

                                return false;
                            },
                        },
                        operatorValue: {
                            required,
                            validation: (value, condition) => {
                                if (value) {
                                    const formattedValue = formatValueBasedOnSelectedOperator(value, condition);
                                    // for string type we don't need validation, in that case we just return true
                                    if (operatorOptionsForDiagramUpdate[condition.selectedOperator]?.validator) {
                                        return operatorOptionsForDiagramUpdate[condition.selectedOperator].validator(
                                            formattedValue,
                                        );
                                    }
                                    return true;
                                }

                                return false;
                            },
                        },
                    },
                },
            },
            immediate: true,
            deep: true,
        },
        selectedChoiceStates: {
            // eslint-disable-next-line func-names
            required: requiredIf(function () {
                return !this.addingNewStateWithinChoices && this.stateType === STATE_TYPES.CHOICE;
            }),
        },
        defaultState: {
            // eslint-disable-next-line func-names
            required: requiredIf(function () {
                return (
                    this.stateType === STATE_TYPES.CHOICE &&
                    this.selectedChoiceStates.length > 0 &&
                    !this.addingNewStateWithinChoices
                );
            }),
        },
        loopStates: {
            $each: {
                name: {
                    required,
                },
            },
        },
    },
    computed: {
        title() {
            if (this.stateType === STATE_TYPES.CATCH) {
                return `${this.$i18n.t('orchestrationEngine.updateDiagramModal.addCatchFor')} '${this.stateForUpdate}'`;
            }

            return this.addingNewStateWithinChoices
                ? `${this.$i18n.t('orchestrationEngine.updateDiagramModal.addConditionsFor')} '${
                      this.connectedToStates
                  }'`
                : `${this.$i18n.t('orchestrationEngine.updateDiagramModal.diagramUpdateFor')}  '${
                      this.stateForUpdate
                  }'`;
        },
        areConditionsVisible() {
            return this.selectedChoiceStatesToDisplay.length && (this.defaultState || this.addingNewStateWithinChoices);
        },
    },
    watch: {
        defaultState: {
            handler() {
                const { defaultState } = this;

                // Deleting conditions for default state since default state doesn't have conditions
                const indexOfDefaultState = this.selectedChoiceStatesToDisplay.findIndex(
                    state => state.name === defaultState,
                );
                const indexOfDefaultStateInStates = this.states.findIndex(state => state.name === defaultState);

                if (indexOfDefaultState > -1) {
                    this.selectedChoiceStatesToDisplay.splice(indexOfDefaultState, 1);
                }
                if (indexOfDefaultStateInStates > -1) {
                    this.states.splice(indexOfDefaultStateInStates, 1);
                }

                // Logic for adding conditions for state that was default state and now some other state is selected
                // as default or in case default state is unselected since that state is no logner default
                // and now it can have conditions
                if (
                    this.selectedDefaultStateBefore &&
                    ((defaultState && this.selectedDefaultStateBefore !== defaultState) ||
                        (!defaultState && !this.unselectedFromNextStates))
                ) {
                    this.addNewStateWithConditions(this.selectedChoiceStatesToDisplay, this.selectedDefaultStateBefore);
                }

                // Helper to track which state was selected before change of default state
                this.selectedDefaultStateBefore = defaultState;

                // Set helper as false after every change of default state
                this.unselectedFromNextStates = false;
            },
        },
    },
    created() {
        if (this.stateType === STATE_TYPES.CHOICE) {
            // Fill selected states field based on connected states from diagram
            this.selectedChoiceStates = this.connectedToStates;

            this.connectedToStates.forEach(stateName => {
                this.addNewStateWithConditions(this.selectedChoiceStatesToDisplay, stateName);
            });
        }

        if (this.stateType === STATE_TYPES.CATCH) {
            this.addNewCatchState();
        }

        if (this.stateType === STATE_TYPES.CHOICE || this.stateType === STATE_TYPES.CATCH) {
            // Logic for edit existing choice or catch
            this.formatModalDataForChoiceOrCatchEdit();
        }

        if (this.stateType === STATE_TYPES.LoopV2) {
            this.optionsForLoopStates = this.optionsForNextStates;
            if (this.selectedLoopStates.length) {
                this.loopStates = this.selectedLoopStates.map(name => ({ name }));
            } else {
                this.addNewLoopState();
            }
        }
    },
    methods: {
        formatModalDataForChoiceOrCatchEdit() {
            if (this.selectedStatesForEdit.length) {
                this.selectedChoiceStatesToDisplay = [];
                this.catches = [];
                const FLAG_VALUE_NOT = 'not';

                for (const state of this.selectedStatesForEdit) {
                    let flagValue = FLAG_VALUE_OPTIONS.SIMPLE;
                    const conditions = [];
                    const stateKeys = Object.keys(state);

                    if (
                        stateKeys.some(key =>
                            [FLAG_VALUE_OPTIONS.OR, FLAG_VALUE_OPTIONS.AND, FLAG_VALUE_NOT].includes(key),
                        )
                    ) {
                        const choiceKey = stateKeys.filter(key =>
                            [FLAG_VALUE_OPTIONS.OR, FLAG_VALUE_OPTIONS.AND, FLAG_VALUE_NOT].includes(key),
                        )[0];

                        if (choiceKey === FLAG_VALUE_OPTIONS.AND || choiceKey === FLAG_VALUE_OPTIONS.OR) {
                            flagValue = choiceKey;
                            for (const condition of state[choiceKey]) {
                                let oneCondition = {};

                                if (condition?.not) {
                                    const isNotObject = condition.not;
                                    const isNotObjectKeys = Object.keys(isNotObject);

                                    oneCondition = this.formatConditions(
                                        isNotObject,
                                        isNotObjectKeys,
                                        IS_NOT_ENUMS.IS_NOT,
                                    );
                                } else {
                                    const conditionKeys = Object.keys(condition);
                                    oneCondition = this.formatConditions(condition, conditionKeys, IS_NOT_ENUMS.EMPTY);
                                }

                                conditions.push(oneCondition);
                            }
                        } else {
                            let oneCondition = {};

                            const isNotObject = state.not;
                            const isNotObjectKeys = Object.keys(isNotObject);
                            oneCondition = this.formatConditions(isNotObject, isNotObjectKeys, IS_NOT_ENUMS.IS_NOT);

                            conditions.push(oneCondition);
                        }
                    } else {
                        const oneCondition = this.formatConditions(state, stateKeys, IS_NOT_ENUMS.EMPTY);

                        conditions.push(oneCondition);
                    }

                    const formattedState = {
                        name: state.next,
                        flagValue,
                        conditions,
                    };

                    if (this.stateType === STATE_TYPES.CHOICE) {
                        this.selectedChoiceStatesToDisplay.push(formattedState);
                    } else {
                        this.catches.push(formattedState);
                    }
                }

                if (this.stateType === STATE_TYPES.CHOICE) {
                    this.defaultState = this.selectedDefaultStateForChoiceEdit;
                }
            }
        },
        formatConditions(object, keys, isNotValue) {
            let operatorValue = '';
            let variable = '';

            const operatorOptionsKeys = Object.keys(operatorOptionsForDiagramUpdate);
            const operatorFilteredKeys = operatorOptionsKeys.filter(key => keys.includes(key));
            const selectedOperator = operatorFilteredKeys?.[0] || '';

            if (object?.variable) {
                variable = object.variable;
            }

            if (typeof object?.[selectedOperator] === 'boolean') {
                operatorValue = object[selectedOperator].toString();
            } else if (object?.[selectedOperator]) {
                operatorValue = object[selectedOperator];
            }

            return {
                selectedOperator,
                operatorValue,
                isNotValue,
                variable,
            };
        },
        addNewStateWithConditions(array, stateName) {
            array.push({
                name: stateName,
                flagValue: FLAG_VALUE_OPTIONS.SIMPLE,
                conditions: [
                    {
                        variable: null,
                        isNotValue: IS_NOT_ENUMS.EMPTY,
                        selectedOperator: Object.values(operatorOptionsForDiagramUpdate)[0].key,
                        operatorValue: '',
                    },
                ],
            });
        },
        updateCondition(value) {
            const { state, index } = value;

            // remove state object on specific index and add updated state at same time
            // i did this because of vue reactivity and update vuelidate array
            this.states.splice(index, 1, state);
        },
        addNewCatchState() {
            this.addNewStateWithConditions(this.catches, '');
        },
        deleteStateFromCatch(index) {
            this.catches.splice(index, 1);
            this.states.splice(index, 1);
        },
        onSelectChoiceState(name) {
            this.addNewStateWithConditions(this.selectedChoiceStatesToDisplay, name);
        },
        onRemoveChoiceState(name) {
            if (name === this.defaultState) {
                this.defaultState = null;
                this.unselectedFromNextStates = true;
            } else {
                const indexInSelectedStates = this.selectedChoiceStatesToDisplay.findIndex(
                    state => state.name === name,
                );
                const indexInStates = this.states.findIndex(state => state.name === name);

                if (indexInSelectedStates > -1) {
                    this.selectedChoiceStatesToDisplay.splice(indexInSelectedStates, 1);
                }

                if (indexInStates > -1) {
                    this.states.splice(indexInStates, 1);
                }
            }
        },
        addNewLoopState() {
            this.loopStates.push({
                name: '',
            });
        },
        changeFormattedLoopStates(val) {
            this.loopStates = val;
        },
        deleteStateFromLoop(index, loopState) {
            this.loopStates.splice(index, 1);
            this.optionsForLoopStates.push(loopState.name);
        },
        selectLoopState() {
            const loopStates = this.loopStates.map(state => state.name);
            this.optionsForLoopStates = this.optionsForNextStates.filter(item => !loopStates.includes(item));
        },
        resetModal() {
            this.selectedChoiceStates = [];
            this.defaultState = null;
            this.selectedChoiceStatesToDisplay = [];
            this.states = [];
            this.loopStates = [];
            this.optionsForLoopStates = [];
            this.catches = [];

            // reset validation
            this.$v.$reset();
        },
        onCloseModal() {
            this.resetModal();
            this.$emit('onCloseModal');
        },
        onSave() {
            this.$v.$touch();
            this.validationResult = this.$v;

            if (this.$v.$invalid) {
                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('alertMessage.pleaseFixValidation'),
                });
                return;
            }

            if (this.stateType !== STATE_TYPES.LoopV2) {
                // Logic for format operatorValue based on type of selectedOperator since validation is passed above
                this.states.forEach((state, index) => {
                    const formattedConditions = state.conditions.map(condition => {
                        const { operatorValue, selectedOperator } = condition;

                        return {
                            ...condition,
                            operatorValue: formatValueBasedOnOperator(
                                operatorValue,
                                operatorOptionsForDiagramUpdate[selectedOperator].type,
                            ),
                        };
                    });

                    this.states[index].conditions = formattedConditions;
                });
            }

            const { stateType, selectedChoiceStates, defaultState, states, addingNewStateWithinChoices } = this;

            const resultOfModal = {
                selectedTypeFromModal: stateType,

                ...(stateType !== STATE_TYPES.LoopV2 && {
                    statesWithConditionsFromModal: states,
                    addingNewStateWithinChoices,
                }),

                ...(stateType === STATE_TYPES.CHOICE && {
                    defaultStateFromModal: defaultState,
                    nextStatesFromModal: selectedChoiceStates,
                }),

                ...(stateType === STATE_TYPES.LoopV2 && {
                    loopStates: this.loopStates.map(state => state.name),
                }),
            };

            this.resetModal();
            this.$emit('onSave', resultOfModal);
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/_consistency';
@import '~@/assets/scss/layout';
@import '~@/assets/scss/icons';

$icon-path: '~@/assets/icons/';

.form-content {
    flex-grow: 1;
}

.border-bottom {
    border-bottom: solid 0.063rem rgba(51, 81, 149, 0.15);
}

.state-label {
    text-transform: uppercase;
    font-size: 0.625rem;
    font-weight: bold;
    line-height: 2;
    color: $blue-300;
}

.delete-btn {
    cursor: pointer;
    height: 0.9rem;
}

.tooltip-info {
    white-space: pre;
    font-size: $spacing-s;
    font-weight: normal;
    text-transform: none;
    line-height: normal;
    color: $gray-800;
    min-width: 10rem;
}

.draggable-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: $spacing-m;
    border-radius: 5px;
    background-color: $blue-200;
    padding: $spacing-xs $spacing-xl $spacing-xs $spacing-m;
    font-size: $spacing-m;
    font-weight: 600;
    color: $blue-400;

    .loop-multiselect {
        min-width: 20rem;
    }
}

.draggable-handle {
    width: $spacing-m;
    height: $spacing-m;
    margin-right: $spacing-xs;
    background-image: url($icon-path + $dnd-handle);
    cursor: grab;
}
</style>
