
import Vue from 'vue';

// Vuex
import { mapGetters, mapActions } from 'vuex';
import Actions, { Getters } from '@/store/mutation-types';
import { Modules } from '@/store/store';

// Components
import AbstractListPageWrapper from '@/components/layout/AbstractListPageWrapper.vue';
import AppTable from '@/components/partials/AppTable.vue';
import EntityOverview from '@/components/partials/entityOverview/EntityOverview.vue';
import OverviewHeader from '@/components/partials/entityOverview/OverviewHeader.vue';
import OverviewList from '@/components/partials/entityOverview/OverviewList.vue';
import FilterTable from '@/components/partials/FilterTable.vue';
import LanguageSwitcher from '@/components/partials/inputs/LanguageSwitcher.vue';
import EntityStatusIndicator from '@/components/partials/EntityStatusIndicator.vue';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import { ICON_TYPES } from '@/common/iconHelper';
import TableFiltersTags from '@/components/filters/TableFiltersTags.vue';
import ResponseModalButton from '@/components/partials/ResponseModalButton.vue';
import permissionsService, { isUserAllowed } from '@/services/permissions/permissions.service';
import IconButton from '@/components/partials/IconButton.vue';

// Mixins
import FilterTableMixin from '@/components/partials/FilterTableMixin.vue';
import DownloadDetailsMixin from '@/__new__/features/charging/DownloadDetailsMixin.vue';
import ChargingCommonActionsMixin from '@/__new__/features/charging/ChargingCommonActionsMixin.vue';

// Http
import { deletePolicyRuleDraft, updatePolicyRulesState } from '@/__new__/services/dno/charging/http/policyRules';

// Routers
import RouteNames from '@/router/routeNames';

// Entities
import { entityStateReverseMap } from '@/common/commonEntityStateMapper';
import ENTITY_TYPES from '@/common/entities/entityTypes';
import { getMultiLangFieldValueByLocale } from '@/common/entities/entityHelper';

// Helpers
import sortPrStatusesByExpression from '@/__new__/services/dno/charging/common/sortPrStatusesByExpression';
import { onlyFirstLetterUppercase } from '@/common/utils';
import tableColumnType, { type TableColumn } from '@/common/filterTable';
import * as Sentry from '@sentry/vue';
import { PolicyRule, DetailsSections } from '@/__new__/services/dno/charging/models/ChargingInterfaces';
import localeLibrary from '@/common/locale/localeLibrary';
import { languageMap } from '@/common/locale/language';
import { parseExpressionsFromBe, CONDITION_OBJECT_TYPE } from '@/__new__/services/dno/charging/common/expression';

export default Vue.extend({
    name: 'PolicyRules',
    components: {
        AbstractListPageWrapper,
        AppButton,
        AppTable,
        EntityOverview,
        OverviewHeader,
        OverviewList,
        EntityStatusIndicator,
        FilterTable,
        LanguageSwitcher,
        TableFiltersTags,
        ResponseModalButton,
        IconButton,
    },
    mixins: [FilterTableMixin, DownloadDetailsMixin, ChargingCommonActionsMixin],
    data() {
        return {
            ICON_TYPES,
            BUTTON_TYPES,
            entityType: ENTITY_TYPES.POLICY_RULE as string,
            editorRoute: RouteNames.CHARGING_POLICY_RULE_EDITOR as string,
            searchQueryForTable: '' as string,
            selectedLanguage: '' as string,
            isDataLoading: false as boolean,
            selectedEntity: null as PolicyRule | null,
            isOverviewEnabled: false as boolean,
        };
    },
    computed: {
        ...mapGetters('operators', [Getters.languageDefault]),
        ...mapGetters(Modules.charging, [Getters.GET_CHARGING_SPECIFICATION_BY_ID]),
        ...mapGetters(Modules.chargingV2, [
            Getters.GET_POLICY_RULES,
            Getters.GET_POLICY_RULE_BY_ID,
            Getters.GET_POLICY_RULES_API_RESPONSE,
            Getters.GET_POLICY_COUNTER_BY_ID,
        ]),
        isEditEnabled(): boolean {
            return permissionsService.chargingPolicyRulesEnabled() && isUserAllowed('PolicyRulesReadWrite');
        },
        payoutsApiResponse(): any {
            return this[Getters.GET_POLICY_RULES_API_RESPONSE];
        },
        policyRules(): PolicyRule[] {
            const data = [...this[Getters.GET_POLICY_RULES]];
            return data
                .sort((pr1: PolicyRule, pr2: PolicyRule) => (pr1.name as any).localeCompare(pr2.name))
                .map((pr: PolicyRule) => ({
                    ...pr,
                    chargingSpec: this.findChargingSpecName(pr),
                }));
        },
        selectedEntityId(): string | null {
            return this.selectedEntity ? this.selectedEntity.id : null;
        },
        detailsSections(): DetailsSections[] {
            if (!this.selectedEntityId) {
                return [];
            }
            const infoData: DetailsSections[] = [
                {
                    name: this.$i18n.t('generic.general'),
                    properties: [
                        {
                            value: this.selectedEntity?.id,
                            label: this.$i18n.t('generic.id'),
                        },
                        {
                            value: this.selectedEntity?.name,
                            label: this.$i18n.t('generic.name'),
                        },
                        {
                            value: this.selectedEntity?.description,
                            label: this.$i18n.t('generic.description'),
                        },
                        {
                            value: this.selectedEntity?.updateTime,
                            label: this.$i18n.t('charging.policyRules.updatedTime'),
                        },
                    ],
                },
            ];

            const { expr, parameters = {} } = this.selectedEntity?.conditions;
            if (expr && parameters) {
                const expression = parseExpressionsFromBe(expr, CONDITION_OBJECT_TYPE.POLICY_COUNTER, parameters) || [];

                const sortedParameters = expression.length
                    ? sortPrStatusesByExpression(expression)
                    : Object.keys(parameters);

                sortedParameters.forEach(el => {
                    const {
                        policy_counter_id: pcId,
                        policy_counter_status: pcStatus,
                        inverted = false,
                    } = parameters[el];

                    const pcData = this.getPolicyCounterData(pcId);
                    const data: DetailsSections = {
                        name: `${this.$i18n.t('charging.policyCounters.policyCounter')}: ${pcData?.name} - ${pcStatus}`,
                        properties: [
                            {
                                value: pcData.id,
                                label: this.$i18n.t('generic.id'),
                            },
                            {
                                value: getMultiLangFieldValueByLocale(pcData?.name),
                                label: this.$i18n.t('generic.name'),
                            },
                            {
                                value: pcStatus,
                                label: this.$i18n.t('charging.policyRules.pc_status'),
                            },
                            {
                                value: inverted,
                                label: this.$i18n.t('generic.inverted'),
                            },
                        ],
                    };

                    infoData.push(data);
                });
            }

            return infoData;
        },
        tableColumnsData(): TableColumn[] {
            return [
                {
                    name: this.$i18n.t('generic.name'),
                    key: 'name',
                    forbidHideColumn: true,
                    field: 'name',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('generic.description'),
                    key: 'description',
                    forbidHideColumn: true,
                    field: 'description',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('generic.lastUpdatedTime'),
                    key: 'updateTime',
                    field: 'update_time',
                    mapper: (entity: PolicyRule) => localeLibrary.getFormattedDateAndTime(entity.update_time),
                    sortBy: (entity: PolicyRule) => entity.update_time,
                    filterType: tableColumnType.DATE,
                },
                {
                    name: this.$i18n.t('generic.version'),
                    key: 'chargingVersion',
                    field: 'chargingVersion',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('generic.state'),
                    key: 'entityStatusCode',
                    field: 'entityStatusCodeLabel',
                    filterType: tableColumnType.TEXT_LIMITED_OPTIONS,
                    limitedOptions: [...new Set(this.policyRules.map((e: PolicyRule) => e.entityStatusCodeLabel))],
                },
            ];
        },
    },
    created() {
        this.initData();
    },
    methods: {
        ...mapActions(Modules.charging, [Actions.REQUEST_CHARGING_SPECIFICATIONS]),
        ...mapActions(Modules.chargingV2, [Actions.REQUEST_POLICY_RULES, Actions.REQUEST_POLICY_COUNTERS]),
        getMultiLangFieldValueByLocale,
        initData() {
            this.$withLoadingSpinner(
                async () => {
                    this.$Progress.start();
                    this.isDataLoading = true;
                    const promises = [this[Actions.REQUEST_POLICY_RULES]()];

                    if (permissionsService.chargingPolicyCountersEnabled() && isUserAllowed('PolicyCountersReadOnly')) {
                        promises.push(this[Actions.REQUEST_POLICY_COUNTERS]());
                    }

                    if (
                        permissionsService.chargingChargingSpecificationsEnabled() &&
                        isUserAllowed('ChargingSpecificationsReadOnly')
                    ) {
                        promises.push(this[Actions.REQUEST_CHARGING_SPECIFICATIONS]());
                    }

                    await Promise.all(promises);
                    this.selectedLanguage = this[Getters.languageDefault] || languageMap.en.key;
                    this.$Progress.finish();
                    this.isDataLoading = false;
                },
                {
                    errorHandler: (e: any) => {
                        this.isDataLoading = false;
                        this.$Progress.fail();

                        Sentry.captureException(e);
                        this.$eventBus.$emit('showAlert', {
                            message: this.$i18n.t('alertMessage.failedToLoadNecessaryData'),
                        });
                    },
                },
            );
        },
        onSelectEntity(id: string): void {
            this.selectedEntity = this.policyRules.find((pr: any) => pr.id === id);
            this.isOverviewEnabled = true;
        },
        onNewPolicyRule(): void {
            this.$router.push({
                name: RouteNames.CHARGING_POLICY_RULE_EDITOR,
                params: { companyId: this.$route.params.companyId },
            });
        },
        getStateName(s: number): string {
            return s
                ? this.$i18n.t(`finalStateMapper.${onlyFirstLetterUppercase(entityStateReverseMap(s))}`)
                : this.$i18n.t('generic.N/A');
        },
        setSearchQuery(query: string): void {
            this.searchQueryForTable = query;
        },
        findChargingSpecName(pr: any): string {
            if (pr.policyCounter && pr.policyCounter.data && pr.policyCounter.data.charging_spec_id) {
                const chargingSpec = this[Getters.GET_CHARGING_SPECIFICATION_BY_ID](
                    pr.policyCounter.data.charging_spec_id,
                );
                if (Object.values(chargingSpec).length) {
                    return chargingSpec.data.name[this.selectedLanguage];
                }
            }
            return this.$i18n.t('generic.N/A');
        },
        getPolicyCounterData(id: string): any {
            return this[Getters.GET_POLICY_COUNTER_BY_ID](id) || {};
        },
        onEdit(entityId: string): void {
            this.onEditAction(entityId, this.editorRoute);
        },
        onClone(entityId: string): void {
            this.onCloneAction(entityId, this.editorRoute);
        },
        onDelete(entityId: string): void {
            const { state, version } = this[Getters.GET_POLICY_RULE_BY_ID](entityId);

            this.showDeleteAlert(entityId, false, state, version, [
                updatePolicyRulesState,
                deletePolicyRuleDraft,
                this.initData,
            ]);
        },
        onReadOnly(entityId: string): void {
            this.onReadOnlyAction(entityId, this.editorRoute);
        },
    },
});
