import { AxiosPromise } from 'axios';
import http from '@/http/index';
import {
    DocumentAssetsResponse,
    DocumentAssetResponse,
} from '@/__new__/services/dno/documents/models/DocumentInterfaces';

/**
 * Get assets
 *
 * @returns {AxiosPromise}
 */
export function getAssets(): AxiosPromise<DocumentAssetsResponse<string[]>> {
    return http({
        method: 'GET',
        url: `/v3documents/getassets`,
    });
}

/**
 * Add asset.
 *
 * @returns {AxiosPromise}
 */
export function getAssetUploadUrl(assetId: string): AxiosPromise<DocumentAssetResponse> {
    return http({
        method: 'POST',
        url: `/v3documents/addasset?asset_id=${assetId}`,
    });
}

/**
 * Get asset.
 *
 * @returns {AxiosPromise}
 */
export function getAsset(assetId: string): AxiosPromise<DocumentAssetResponse> {
    return http({
        method: 'POST',
        url: `/v3documents/downloadasset?asset_id=${assetId}`,
    });
}

/**
 * Upload asset to s3
 *
 * @returns {AxiosPromise}
 */
export function uploadAssetToSignedURL(file: File, url: string): AxiosPromise<any> {
    return http({
        method: 'PUT',
        url,
        data: file,
    });
}

export default {
    getAssets,
    getAsset,
    getAssetUploadUrl,
    uploadAssetToSignedURL,
};
