
// Vue components
import AppHeader from '@/components/layout/AppHeader.vue';
import AppInputV3 from '@/components/partials/inputs/AppInputV3.vue';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import { ICON_TYPES } from '@/common/iconHelper';
import { getTenantUMSearchOptions } from '@/services/permissions/permissions.service';
import SearchBanner from '@/components/partials/SearchBanner.vue';
import { getGuestOrder } from '@/__new__/services/dno/resendQRCode/http/resendQRcode';
import { EmailField } from '@/__new__/services/dno/resendQRCode/models/guestOrder';
import { ALERT_TYPES } from '@/common/alerts/Alert';
import { TranslateResult } from 'vue-i18n';
import UM_HIERARCHY from '@/common/userManagerHierarchy';

export default {
    name: 'UserManagement',

    components: {
        AppHeader,
        AppButton,
        AppInputV3,
        SearchBanner,
    },

    data() {
        return {
            ICON_TYPES,
            BUTTON_TYPES,
            searchValue: '',
            searchEmail: '',
        };
    },

    computed: {
        emailMetaData(): EmailField {
            const searchOptions = getTenantUMSearchOptions();
            return searchOptions.Email;
        },
        pageTitle(): TranslateResult {
            return this.$i18n.t('customerCare.guestPurchase');
        },
    },

    methods: {
        async onSearch(): void {
            const disableValidator = false;
            if (
                typeof this.emailMetaData?.validator === 'function' &&
                this.emailMetaData.validator(this.searchValue, disableValidator)
            ) {
                await this.$withProgressBar(
                    async () => {
                        const searchUserResponse = await getGuestOrder({
                            guest_id: this.searchValue,
                            guest_type: UM_HIERARCHY.UNREGISTERED_EMAIL,
                        });
                        if (searchUserResponse.data.code === 0) {
                            const path = `/${this.$route.params.companyId}/customer-care/resend-qrcode/user/resend/${this.searchValue}`;
                            this.$router.push({ path });
                        }
                    },
                    {
                        errorHandler: () => {
                            this.searchEmail = this.searchValue;
                        },
                    },
                );
            } else {
                this.$eventBus.$emit('showAlert', {
                    message: `${this.emailMetaData.errorMessage}.`,
                    type: ALERT_TYPES.warning,
                });
            }
        },
    },
};
