
// Components
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import AppDialogV2 from '@/components/partials/AppDialogV2.vue';
import AppTextareaV3 from '@/components/partials/inputs/AppTextareaV3.vue';
import AppCheckbox from '@/components/partials/inputs/AppCheckbox.vue';

// Helpers
import { ALERT_TYPES } from '@/common/alerts/Alert';
import luaErrors from '@/common/luaErrors';
import Account from '@/models/Account';
import isEmpty from 'lodash/isEmpty';
import { formatAmountBasedOnCurrency, sumNumbers } from '@/common/formatting';
import { required, minLength } from 'vuelidate/lib/validators';
import { BILLING_TRX_PAYMENT_TYPES } from '@/__new__/features/customerCareSuite/common/transactionHelper';

// HTTP
import customerCareHTTP from '@/__new__/services/dno/user/http/customer-care';
import ordersHTTP from '@/__new__/services/dno/orders/http/orders';

export default {
    name: 'RefundDialogNextGen',
    components: {
        AppButton,
        AppDialogV2,
        AppTextareaV3,
        AppCheckbox,
    },
    props: {
        isDialogOpen: {
            required: true,
            type: Boolean,
        },
        transactionData: {
            required: true,
            type: Object,
        },
    },
    data() {
        return {
            chargesFromApi: [],
            selectedChargesIds: [] as Array<string>,
            refundReason: '',
            BUTTON_TYPES,
        };
    },
    validations() {
        return {
            refundReason: {
                required,
            },
            selectedChargesIds: {
                required,
                minLength: minLength(1),
            },
        };
    },
    computed: {
        chargesFormatted() {
            return this.chargesFromApi.length
                ? this.chargesFromApi.map(charge => {
                      const price = Number(charge?.item_price?.tax?.gross_amount);
                      const refunded = Boolean(charge?.refund_details || !isEmpty(charge?.refund_details));
                      const disabled = Boolean(refunded || !price);
                      return {
                          id: charge.charge_id,
                          disabled,
                          noPrice: Boolean(!price),
                          displayName: `${charge?.offer_data?.entity_name} ${formatAmountBasedOnCurrency(
                              charge?.item_price?.tax?.gross_amount,
                          )} ${refunded ? this.$i18n.t('generic.stateMap.refunded').toUpperCase() : ''}`,
                      };
                  })
                : [];
        },
        totalRefundAmount(): string {
            return this.selectedChargesIds.reduce((totalAmount: number, chargeId: string) => {
                const relatedCharge = this.chargesFromApi.find(charge => charge.charge_id === chargeId);
                if (relatedCharge) {
                    const chargeSubTotal = relatedCharge?.item_price?.tax?.gross_amount;
                    return sumNumbers(totalAmount, chargeSubTotal);
                }
                return totalAmount;
            }, 0);
        },
        totalRefundAmountFormatted() {
            return this.totalRefundAmount
                ? formatAmountBasedOnCurrency(this.totalRefundAmount)
                : formatAmountBasedOnCurrency(0);
        },
        areAllRefundableChargesSelected() {
            const notDisabledChargesLength = this.chargesFormatted.filter(
                chargeFormatted => !chargeFormatted.disabled,
            ).length;
            const selectedChargesLength = this.selectedChargesIds.length;
            return notDisabledChargesLength === selectedChargesLength;
        },
    },
    watch: {
        transactionData: {
            async handler(newVal) {
                if (newVal && !isEmpty(newVal)) {
                    const response = await this.fetchCharges(newVal);
                    this.chargesFromApi = response.data?.charge_data?.charges;
                }
            },
        },
    },
    methods: {
        onCloseModal() {
            this.$emit('close');
            this.$v.$reset();
            this.resetData();
        },
        resetData() {
            this.chargesFromApi = [];
            this.selectedChargesIds = [];
            this.refundReason = '';
        },
        onChargeSelect(chargeId: string) {
            const chargeIndex = this.selectedChargesIds.findIndex(selectedChargeId => selectedChargeId === chargeId);
            if (chargeIndex === -1) {
                this.selectedChargesIds.push(chargeId);
            } else {
                this.selectedChargesIds.splice(chargeIndex, 1);
            }
        },
        formatChargesForRefund(selectedChargesIds) {
            const result = [];
            selectedChargesIds.forEach(selectedChargeId => {
                const relatedCharge = this.chargesFromApi.find(
                    chargeFromApi => chargeFromApi.charge_id === selectedChargeId,
                );
                result.push({
                    ref_id:
                        this.transactionData.paymentType === BILLING_TRX_PAYMENT_TYPES.DIRECT_BILL_PAYMENT
                            ? this.transactionData.billId
                            : this.transactionData.orderId,
                    refund_amount: relatedCharge?.item_price?.tax?.gross_amount,
                    charge_id: selectedChargeId,
                    refund_reason: this.refundReason,
                });
            });
            return result;
        },
        async fetchCharges(transactionData) {
            try {
                this.$Progress.start();
                const chargesResponse = await ordersHTTP.getCharges({
                    target_id: transactionData.targetId,
                    target_type: transactionData.targetType,
                    billing_payment_id: transactionData.billingPaymentId,
                    payment_type: transactionData.paymentType,
                    filters:
                        transactionData.paymentType === BILLING_TRX_PAYMENT_TYPES.DIRECT_BILL_PAYMENT
                            ? {
                                  bill_id: transactionData.billId,
                                  // TODO: fill once updated
                                  // charge_aggregation_id: newVal.orderId,
                              }
                            : {
                                  order_id: transactionData.orderId,
                              },
                });
                this.$Progress.finish();
                return chargesResponse;
            } catch (err) {
                this.$Progress.fail();
                this.$alert(this.$i18n.t('alertMessage.somethingWentWrongFetchingNecessaryData'));
                return err;
            }
        },
        async refundPayment() {
            this.$v.$touch();
            if (!this.$v.$anyError) {
                await this.$withProgressBar(
                    async () => {
                        await customerCareHTTP.refundTransactionNextGen({
                            target_id: this.transactionData.targetId,
                            target_type: this.transactionData.targetType,
                            ref_type: this.transactionData.paymentType,
                            agent_id: Account.storedInstance?.email,
                            charges: this.areAllRefundableChargesSelected
                                ? [
                                      {
                                          ref_id:
                                              this.transactionData.paymentType ===
                                              BILLING_TRX_PAYMENT_TYPES.DIRECT_BILL_PAYMENT
                                                  ? this.transactionData.billId
                                                  : this.transactionData.orderId,
                                          refund_amount: this.totalRefundAmount,
                                          refund_reason: this.refundReason,
                                      },
                                  ]
                                : this.formatChargesForRefund(this.selectedChargesIds),
                        });
                        this.$alert(
                            this.$i18n.t('alertMessage.userManagement.refundSuccessefull', {
                                amount: this.totalRefundAmountFormatted,
                            }),
                            {
                                type: ALERT_TYPES.success,
                            },
                        );
                        this.onCloseModal();
                    },
                    {
                        errorHandler: error => {
                            if (error.response?.data.code === luaErrors.BILLING_V4.INVALID_REFUND_AMOUNT.code) {
                                this.$alert(
                                    this.$i18n.t('alertMessage.userManagement.alertMessages.invalidRefundAmount'),
                                    {
                                        type: ALERT_TYPES.error,
                                    },
                                );
                            } else if (
                                error.response?.data.code === luaErrors.BILLING_V4.CHARGE_ALREADY_REFUNDED.code
                            ) {
                                this.$alert(
                                    this.$i18n.t('alertMessage.userManagement.alertMessages.chargeIsAlreadyRefunded'),
                                    {
                                        type: ALERT_TYPES.error,
                                    },
                                );
                            } else {
                                this.$alert(this.$i18n.t('alertMessage.userManagement.refundError'), {
                                    type: ALERT_TYPES.error,
                                });
                            }
                        },
                    },
                );
            }
        },
    },
};
