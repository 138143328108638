<template>
    <div v-if="tagData">
        <h3 class="mb-3">
            <b>{{ tagName }}</b> {{ $i18n.t('userGuide.tagRelatedArticles') }}:
        </h3>
        <div class="editor-input-small">
            <AppDisplayList
                :items="mappedArticles"
                :verticalAlignment="true"
                @click="id => onArticleClick(id)"
            />
        </div>
    </div>
</template>

<script>
// components
import AppDisplayList from '@/components/partials/AppDisplayList.vue';

// helpers
import RouteNames from '@/router/routeNames';
import { getTags } from '@/modules/userGuide/http/strapiHTTP';

export default {
    name: 'TagPage',
    components: {
        AppDisplayList,
    },
    data() {
        return {
            responseTime: 0,
            pageTitle: this.$i18n.t('userGuide.userGuide'),
            tagData: null,
            tagName: '',
            articles: [],
        };
    },
    computed: {
        mappedArticles() {
            return this.articles.map(article => ({
                id: article.id,
                text: article.attributes.title,
            }));
        },
    },
    mounted() {
        this.fetchData();
    },
    methods: {
        async fetchData() {
            const tagsResponse = await getTags(this.$route.params.id);
            this.tagData = tagsResponse?.data?.data;
            this.tagName = this.tagData.attributes.tag_name;
            this.articles = this.tagData.attributes?.articles?.data || [];
        },
        onArticleClick(id) {
            this.$router.push({
                name: RouteNames.USER_GUIDE_ARTICLE,
                params: { id, companyId: this.$route.params.companyId },
            });
        },
    },
};
</script>

<style lang="scss" scoped></style>
