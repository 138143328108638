<template>
    <div class="draggable-wrapper">
        <div
            v-show="isLeftArrowVisible"
            class="arrow-left"
        >
            <div class="left-fade" />
            <AppIcon
                class="arrow-left-icon"
                :type="ICON_TYPES.ARROW_LEFT"
                :color="ICON_COLORS.BLUE"
                @click="onLeftArrowClick"
            />
        </div>
        <div
            v-show="isRightArrowVisible"
            class="arrow-right"
        >
            <div class="right-fade" />
            <AppIcon
                class="arrow-right-icon"
                :type="ICON_TYPES.ARROW_RIGHT"
                :color="ICON_COLORS.BLUE"
                @click="onRightArrowClick"
            />
        </div>
        <div
            ref="draggable"
            v-dragscroll.x
            class="draggable overflow-hidden"
        >
            <div
                ref="resizable"
                class="draggable-content"
            >
                <slot />
            </div>
        </div>
    </div>
</template>
<script>
// Components
import AppIcon from '@/components/partials/icon/AppIcon.vue';

// Libs
import { dragscroll } from 'vue-dragscroll';

// Helpers
import { ICON_COLORS, ICON_TYPES } from '@/common/iconHelper';

export default {
    name: 'AppSlider',
    directives: {
        dragscroll,
    },
    components: {
        AppIcon,
    },
    data() {
        return {
            isLeftArrowVisible: false,
            isRightArrowVisible: false,
            resizeObserver: undefined,

            // proxy imports
            ICON_COLORS,
            ICON_TYPES,
        };
    },
    mounted() {
        this.arrowsUpdate();
        if (this?.$refs?.draggable) {
            this.$refs.draggable.addEventListener('scroll', this.arrowsUpdate);
        }
        if (this?.$refs?.resizable) {
            this.resizeObserver = new ResizeObserver(this.arrowsUpdate);
            this.resizeObserver.observe(this.$refs.resizable);
        }
    },
    beforeDestroy() {
        if (this?.$refs?.draggable) {
            this.$refs.draggable.removeEventListener('scroll', this.arrowsUpdate);
        }
        if (this?.$refs?.resizable) {
            this.resizeObserver.unobserve(this.$refs.resizable);
        }
    },
    methods: {
        onLeftArrowClick() {
            this.$refs.draggable.scrollLeft -= this.$refs.draggable.offsetWidth / 2;
        },
        onRightArrowClick() {
            this.$refs.draggable.scrollLeft += this.$refs.draggable.offsetWidth / 2;
        },
        arrowsUpdate() {
            // Check if right arrow needs to be displayed
            this.isRightArrowVisible = !!(
                this?.$refs?.draggable &&
                this.$refs.draggable?.clientWidth < this.$refs.draggable?.scrollWidth &&
                this.$refs.draggable?.scrollLeft + this.$refs.draggable?.clientWidth !==
                    this.$refs.draggable?.scrollWidth
            );
            // Check if left arrow needs to be displayed
            this.isLeftArrowVisible = !!(this?.$refs?.draggable && this.$refs.draggable?.scrollLeft > 0);
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/palette';
@import '~@/assets/scss/z-indexes';

.draggable-wrapper {
    position: relative;

    .arrow-right,
    .arrow-left {
        position: absolute;
        z-index: $overlap-smth-z-index;
    }

    .arrow-right-icon,
    .arrow-left-icon {
        cursor: pointer;
        width: 2rem;
        height: 2rem;
        border-radius: 50%;

        &:hover {
            background-color: $blue15;
        }
    }

    .arrow-right-icon {
        padding: 7px 6px 7px 8px;
    }

    .arrow-left-icon {
        padding: 7px 8px 7px 6px;
    }

    .arrow-left,
    .arrow-right {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: 100%;

        .left-fade,
        .right-fade {
            position: absolute;
            width: 7.5rem;
            height: 100%;
            z-index: $hide-smth-z-index;
            pointer-events: none;
            background-image: linear-gradient(to right, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
        }
    }

    .arrow-left {
        left: 0;
    }

    .arrow-right {
        right: 0;
    }

    .draggable {
        position: relative;
        cursor: grab;
        scroll-behavior: smooth;

        &:active {
            cursor: grabbing;
            scroll-behavior: auto;
        }
    }

    .draggable-content {
        width: fit-content;
    }
}
</style>
