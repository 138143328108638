
// Components
import AbstractTableTile from '@/__new__/features/customerCareSuite/components/AbstractTableTile.vue';
import AppToggle from '@/components/partials/inputs/AppToggle.vue';

// Helpers
import { ALERT_TYPES } from '@/common/alerts/Alert';
import { cloneDeep } from 'lodash';
import { ICON_TYPES } from '@/common/iconHelper';
import { formatAmountBasedOnCurrency } from '@/common/formatting';

// HTTP
import { getChargesForBillingAccount } from '@/__new__/services/portal/postpaid/charges/charges';

export default {
    name: 'BillingAccountChargesTile',
    components: {
        AbstractTableTile,
        AppToggle,
    },
    data() {
        return {
            entities: [],
            rawResponse: {},
            selectedEntity: {},
            showAdditionalSidebar: false,
            account: null,
            showOnlyUnprocessedCharges: false,
            ICON_TYPES,
        };
    },
    computed: {
        formattedEntities() {
            const chargesInfoCopy = cloneDeep(this.rawResponse);
            const charges = chargesInfoCopy?.data;
            if (charges?.length) {
                return charges.map(item => ({
                    id: item.id,
                    date: this.$localeLibrary.getFormattedDateAndTime(item.date),
                    amount: formatAmountBasedOnCurrency(item.amount),
                    typeOfCharge: item.typeOfCharge,
                    description: item.description,
                    invoiceId: item.invoiceId ?? this.$i18n.t('billingAccount.unprocessed'),
                }));
            }
            return [];
        },
        columnsData() {
            return [
                {
                    name: this.$i18n.t('billingAccount.id'),
                    key: 'id',
                },
                {
                    name: this.$i18n.t('generic.date'),
                    key: 'date',
                },
                {
                    name: this.$i18n.t('generic.amount'),
                    key: 'amount',
                },
                {
                    name: this.$i18n.t('billingAccount.typeOfCharge'),
                    key: 'typeOfCharge',
                },
                {
                    name: this.$i18n.t('generic.description'),
                    key: 'description',
                },
                {
                    name: this.$i18n.t('billingAccount.invoiceId'),
                    key: 'invoiceId',
                },
            ];
        },
    },
    watch: {
        showOnlyUnprocessedCharges: {
            deep: true,
            handler() {
                this.fetchTileData();
            },
        },
    },
    async created() {
        await this.fetchTileData();
    },
    methods: {
        async fetchTileData() {
            await this.$withProgressBar(
                async () => {
                    this.$emit('isDataLoadingUpd', true);
                    this.rawResponse = await getChargesForBillingAccount(
                        this.$route.params.id,
                        this.showOnlyUnprocessedCharges,
                    );
                    this.$emit('isDataLoadingUpd', false);
                },
                {
                    errorHandler: () => {
                        this.$alert(this.$i18n.t('alertMessage.somethingWentWrongFetchingNecessaryData'), {
                            type: ALERT_TYPES.error,
                        });
                        this.$emit('isDataLoadingUpd', false);
                    },
                },
            );
        },
        selectedRow(entity: object): void {
            this.selectedEntity = entity;
            this.showAdditionalSidebar = true;
        },
    },
};
