import * as Sentry from '@sentry/vue';
import i18n from '../i18n';

const AppLoading = {
    install(Vue: any) {
        Vue.prototype.$withLoadingSpinner = async (asyncFunc: () => void, options: any) => {
            const { errorHandler } = options || {};
            try {
                Vue.prototype.$eventBus.$emit('setLoadingFlag', true);
                await asyncFunc();
            } catch (e) {
                Sentry.captureException(e);

                if (errorHandler) {
                    errorHandler(e);
                } else {
                    Vue.prototype.$eventBus.$emit('showAlert', {
                        message: i18n.t('alertMessage.failedToLoadNecessaryData'),
                    });
                }
            } finally {
                Vue.prototype.$eventBus.$emit('setLoadingFlag', false);
            }
        };
    },
};

export default AppLoading;
