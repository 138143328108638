
import Vue, { PropType } from '@/common/typedVue';
import { mapGetters } from 'vuex';
import { Modules } from '@/store/store';
import { Getters } from '@/store/mutation-types';

// components
import AppButtonDropdown from '@/components/partials/inputs/AppButtonDropdown.vue';
import CancelDeviceLocationModal from './CancelDeviceLocationModal.vue';
import NetworkCoverageModal from './NetworkCoverageModal.vue';
import ClientStorageModal from './ClientStorageModal.vue';
import ViewSubscriberServicesModal from './ViewSubscriberServicesModal.vue';

// Helpers
import routeNames from '@/router/routeNames';
import { isUserAllowed } from '@/services/permissions/permissions.service';
import { ICON_TYPES } from '@/common/iconHelper';
import {
    SUPPORT_ACTION,
    SupportActionMenuItem,
} from '@/__new__/features/customerCareSuite/common/supportActionsHelper';
import { TARGET_TYPE, TargetTuple } from '@/__new__/services/dno/user/models/targetTuple';
import { SENSITIVE_OPERATION_TYPES } from '@/__new__/features/customerCareSuite/common/endUserAuthorizationHelper';

export default Vue.extend({
    name: 'SupportActions',
    components: {
        AppButtonDropdown,
        CancelDeviceLocationModal,
        NetworkCoverageModal,
        ClientStorageModal,
        ViewSubscriberServicesModal,
    },
    props: {
        entity: {
            default: () => ({
                targetId: '',
                targetType: TARGET_TYPE.SUBSCRIBER,
            }),
            type: Object as PropType<TargetTuple>,
        },
    },
    data(): {
        actionsConfig: Record<TARGET_TYPE, SupportActionMenuItem[]>;
        modalVisible: Record<string, boolean>;
        SUPPORT_ACTION: typeof SUPPORT_ACTION;
        ICON_TYPES: typeof ICON_TYPES;
    } {
        return {
            actionsConfig: {
                [TARGET_TYPE.SUBSCRIBER]: [
                    {
                        label: this.$t('customerCare.cancelDeviceLocation.modalTitle'),
                        value: SUPPORT_ACTION.CANCEL_DEVICE_LOCATION,
                        visible: isUserAllowed('UMSubscriberCancelDeviceLocation'),
                    },
                    {
                        label: this.$t('customerCare.networkCoverage.modalTitle'),
                        value: SUPPORT_ACTION.NETWORK_COVERAGE,
                        visible: isUserAllowed('UMSubscriberNetworkCoverage'),
                    },
                    {
                        label: this.$t('customerCare.clientStorage.modalTitle'),
                        value: SUPPORT_ACTION.CLIENT_STORAGE,
                        visible: isUserAllowed('UMSupportActionsClientStorage'),
                    },
                    {
                        label: this.$t('customerCare.viewSubscriberServices.modalTitle'),
                        value: SUPPORT_ACTION.VIEW_SUBSCRIBER_SERVICES,
                        visible: isUserAllowed('UMSupportActionsViewSubscriberServices'),
                    },
                ],
                [TARGET_TYPE.ACCOUNT]: [
                    {
                        label: this.$t('customerCare.clientStorage.modalTitle'),
                        value: SUPPORT_ACTION.CLIENT_STORAGE,
                        visible: isUserAllowed('UMSupportActionsClientStorage'),
                    },
                    {
                        label: this.$t('customerCare.replacementPsim.pageTitle'),
                        value: SUPPORT_ACTION.REPLACEMENT_PSIM,
                        visible: isUserAllowed('UMSupportActionsReplacementPSIM'),
                    },
                ],
                [TARGET_TYPE.USER]: [
                    {
                        label: this.$t('customerCare.clientStorage.modalTitle'),
                        value: SUPPORT_ACTION.CLIENT_STORAGE,
                        visible: isUserAllowed('UMSupportActionsClientStorage'),
                    },
                ],
                [TARGET_TYPE.ORGANIZATION]: [],
            },
            modalVisible: {
                [SUPPORT_ACTION.CANCEL_DEVICE_LOCATION]: false,
                [SUPPORT_ACTION.NETWORK_COVERAGE]: false,
                [SUPPORT_ACTION.CLIENT_STORAGE]: false,
                [SUPPORT_ACTION.VIEW_SUBSCRIBER_SERVICES]: false,
            },

            SUPPORT_ACTION,
            ICON_TYPES,
        };
    },
    computed: {
        ...mapGetters(Modules.customerCareSuite, [Getters.GET_ASSOCIATED_UM_ENTITY_BY_TYPE_AND_ID]),
        umentity(): any {
            return this[Getters.GET_ASSOCIATED_UM_ENTITY_BY_TYPE_AND_ID](this.entity);
        },
        actions(): SupportActionMenuItem[] {
            return this.actionsConfig[this.entity.targetType] || [];
        },
        visibleActions(): SupportActionMenuItem[] {
            return this.actions.filter(({ visible }) => visible);
        },
    },
    methods: {
        selectSupportAction(action: SupportActionMenuItem) {
            if (action.value === SUPPORT_ACTION.REPLACEMENT_PSIM) {
                return this.navigateToReplacementSimPage();
            }
            this.modalVisible[action.value] = true;
            return true;
        },
        closeActionModal(action: SUPPORT_ACTION) {
            this.modalVisible[action] = false;
        },
        navigateToReplacementSimPage() {
            this.$router.push({
                name: routeNames.END_USER_AUTHORIZATION,
                params: {
                    targetId: this.entity.targetId,
                    targetType: this.entity.targetType as any as string,
                    operationType: SENSITIVE_OPERATION_TYPES.REQUEST_REPLACEMENT_PSIM,
                    operationTargetType: this.entity.targetType as any as string,
                    operationTargetId: this.entity.targetId,
                },
            });
        },
    },
});
