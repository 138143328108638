<template>
    <AbstractListPageWrapper
        :pageTitle="$i18n.t('authentication.authentication')"
        :isOverviewEnabled="false"
    >
        <template #table>
            <div class="default-page-content-wrapper main">
                <h3 class="modal-title">
                    {{ title }}
                </h3>
                <AppInputV3
                    v-model="config.clientId"
                    :label="$i18n.t('authentication.authenticationClientId')"
                />
                <AppInputV3
                    v-model="config.clientSecret"
                    :label="$i18n.t('authentication.authenticationClientSecret')"
                />
                <AppInputV3
                    v-model="config.urlAuthorize"
                    :label="$i18n.t('authentication.authenticationAuthorizationURL')"
                />
                <AppInputV3
                    v-if="isAzureBasedStrategy"
                    v-model="config.urlAccessToken"
                    :label="$i18n.t('authentication.authenticationAccessTokenURL')"
                />
                <AppInputV3
                    v-model="config.oauthScopes"
                    :label="$i18n.t('authentication.authenticationScopes')"
                    :explanationText="$i18n.t('authentication.authenticationOauthScopesDescription')"
                    :tooltipPosition="TOOLTIP_POSITION.right"
                />
                <AppButton
                    :label="$i18n.t('generic.save')"
                    class="mt-4"
                    :buttonType="BUTTON_TYPES.PRIMARY"
                    @click="onSave"
                />
            </div>
        </template>
    </AbstractListPageWrapper>
</template>

<script>
// Components
import AppInputV3 from '@/components/partials/inputs/AppInputV3.vue';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import AbstractListPageWrapper from '@/components/layout/AbstractListPageWrapper.vue';

// HTTP
import { getOAuthConfig, createOAuthConfig } from '@/__new__/services/portal/auth/http/oauth';

// Helpers
import RouteNames from '@/router/routeNames';
import { AUTH_STRATEGY } from '@/common/auth/strategies';
import { TOOLTIP_POSITION } from '@/common/tooltip';
import * as Sentry from '@sentry/vue';

export default {
    name: 'AuthenticationConfigurator',

    components: {
        AppButton,
        AppInputV3,
        AbstractListPageWrapper,
    },

    data() {
        return {
            providerType: null,
            config: {},
            AUTH_STRATEGY,
            BUTTON_TYPES,
            TOOLTIP_POSITION,
        };
    },
    computed: {
        title() {
            const prefix = this.providerType
                ? this.$i18n.t(`authentication.authenticationTypes.${this.providerType}`)
                : '';
            return `${prefix} ${this.$i18n.t('authentication.authenticationConfiguration')}`;
        },
        isAzureBasedStrategy() {
            return this.providerType === AUTH_STRATEGY.AZUREAD;
        },
    },

    async mounted() {
        // Determine provider
        if (this.$route.query?.provider == null) {
            this.$eventBus.$emit('showAlert', {
                message: this.$t('authentication.alerts.providerMissing'),
            });
            return;
        }
        this.providerType = this.$route.query.provider;

        // Set default values:
        switch (this.providerType) {
            case AUTH_STRATEGY.AZUREAD:
                this.config.oauthScopes = 'openid profile email';
                break;
            case AUTH_STRATEGY.OKTA:
                this.config.oauthScopes = 'openid profile email groups';
                break;
            default:
                break;
        }

        // Get OAuth Config
        try {
            const res = await getOAuthConfig(this.providerType);
            if (res.data) {
                this.config = res.data;
            }
        } catch (error) {
            this.$eventBus.$emit('showAlert', {
                message: this.$t('alerts.unableToLoadConfigs'),
            });
            Sentry.captureException(error);
        }
    },
    methods: {
        async onSave() {
            try {
                await createOAuthConfig({
                    type: this.providerType,
                    clientId: this.config.clientId,
                    clientSecret: this.config.clientSecret,
                    urlAuthorize: this.config.urlAuthorize,
                    urlAccessToken: this.config.urlAccessToken || '',
                    oauthScopes: this.config.oauthScopes,
                });
                this.$router.push({
                    name: RouteNames.AUTHENTICATION,
                    params: { companyId: this.$route.params.companyId },
                });
            } catch (e) {
                this.$eventBus.$emit('showAlert', {
                    message: e.response?.data?.error ?? this.$t('alerts.unableToSaveConfig'),
                });
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/_consistency-spacing.scss';

.main {
    padding: $spacing-xl;
}
</style>
