<template>
    <AppDialogV2
        :title="$i18n.t('generic.details')"
        :visible="value"
        @close="closeModal"
    >
        <TableFiltersRenderless
            v-for="(entitiesObj, index) in entitiesRemap"
            :key="index"
            :entities="entitiesObj.list"
        >
            <template #default="{ allFilters, filteredEntities, onFilterAdded, removeFilter, removeAllFilters }">
                <div>
                    <div class="display-flex justify-content-between align-items-center mb-2">
                        <h4>
                            {{ entitiesObj.title }}
                            <span>({{ filteredEntities.length }})</span>
                        </h4>
                        <div class="ml-auto">
                            <SearchBox
                                v-model="searchQuery[entitiesObj.key]"
                                class="table-search-box"
                            />
                        </div>
                        <FilterTable
                            :columns="tableColumnsData(entitiesObj.list)"
                            class="position-relative"
                            @filterAdded="onFilterAdded"
                        >
                            <template #button="slotProps">
                                <IconButton
                                    :label="$i18n.t('generic.filter')"
                                    :icon="ICON_TYPES.FILTER"
                                    @iconClick="slotProps.clickHandler"
                                />
                            </template>
                        </FilterTable>
                    </div>
                    <TableFiltersTags
                        :filterTableMixin="{ allFilters }"
                        class="mt-1 mb-3"
                        @removeFilter="removeFilter"
                        @removeAllFilters="removeAllFilters"
                    />
                    <div class="content-table">
                        <AppTable
                            :entities="filteredEntities"
                            :isSearchEnabled="true"
                            :innerSearchQuery="searchQuery[entitiesObj.key]"
                            :columnsData="tableColumnsData(entitiesObj.list)"
                        >
                            <template #stateId="{ entity }">
                                <EntityStatusIndicator :status="entity.stateId" />
                            </template>
                        </AppTable>
                    </div>
                </div>
            </template>
        </TableFiltersRenderless>
        <template #modalFooter>
            <AppButton
                v-if="saveButtonEnabled"
                :buttonType="BUTTON_TYPES.PRIMARY"
                :label="$i18n.t('generic.save')"
                @click="$emit('updateEntity')"
            />
            <!-- to prevent default save btn from rendering when saveButtonEnabled is false -->
            <div v-else />
        </template>
    </AppDialogV2>
</template>

<script>
// Libs, Helpers, Services
import ENTITY_TYPES from '@/common/entities/entityTypes';
import { onlyFirstLetterUppercase } from '@/common/utils';
import { ICON_TYPES } from '@/common/iconHelper';

// Vue Components
import AppTable from '@/components/partials/AppTable.vue';
import AppDialogV2 from '@/components/partials/AppDialogV2.vue';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import SearchBox from '@/components/partials/inputs/SearchBox.vue';
import EntityStatusIndicator from '@/components/partials/EntityStatusIndicator.vue';
import FilterTable from '@/components/partials/FilterTable.vue';
import IconButton from '@/components/partials/IconButton.vue';
import TableFiltersRenderless from '@/components/filters/TableFiltersRenderless.vue';
import TableFiltersTags from '@/components/filters/TableFiltersTags.vue';

import { getEntityStateId } from '@/common/commonEntityStateMapper';
import tableColumnType from '@/common/filterTable';

export default {
    name: 'MutationDialog',

    components: {
        AppTable,
        AppDialogV2,
        AppButton,
        SearchBox,
        EntityStatusIndicator,
        FilterTable,
        IconButton,
        TableFiltersRenderless,
        TableFiltersTags,
    },
    props: {
        value: {
            type: Boolean,
            default: false,
        },
        entities: {
            type: Object,
            default: () => undefined,
        },
        saveButtonEnabled: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            searchQuery: {},
            ENTITY_TYPES,
            ICON_TYPES,
            BUTTON_TYPES,
        };
    },
    computed: {
        entitiesRemap() {
            const remapedEntities = [];
            const tempSearchQuery = {};
            for (const entityType in this.entities) {
                if (
                    this.entities[entityType] &&
                    Array.isArray(this.entities[entityType].list) &&
                    this.entities[entityType].list.length > 0
                ) {
                    tempSearchQuery[entityType] = '';
                    remapedEntities.push({
                        key: entityType,
                        title: this.entities[entityType].title,
                        list: this.entities[entityType].list.map(entity => {
                            const getStatus =
                                typeof entity?.state === 'string' ? entity.state : getEntityStateId(entity.state);
                            return {
                                ...entity,
                                stateId: getEntityStateId(entity.state),
                                status: onlyFirstLetterUppercase(getStatus),
                            };
                        }),
                    });
                }
            }
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.searchQuery = { ...tempSearchQuery };
            return remapedEntities;
        },
    },
    methods: {
        tableColumnsData(entitiesList) {
            return [
                {
                    name: this.$i18n.t('generic.name'),
                    key: 'name',
                    forbidHideColumn: true,
                    field: 'name',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('generic.state'),
                    key: 'stateId',
                    forbidHideColumn: true,
                    field: 'status',
                    filterType: tableColumnType.TEXT_LIMITED_OPTIONS,
                    limitedOptions: [...new Set(entitiesList.map(entity => entity.status))],
                },
            ];
        },
        closeModal() {
            this.$emit('input', false);
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/consistency';

.table-search-box {
    width: 15rem;
}

.filters-tags {
    padding: 0 2.5rem;
}
</style>
