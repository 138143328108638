import http from '@/http';
import { type AxiosPromise } from 'axios';
import { type DnoResponseBase } from '@/http/index';
import { type REPORT_ARCHIVE_STATUS } from '@/__new__/features/reports/common/reportArchiveHelper';

type ReportArchiveData = {
    definitionId: string;
    startDate: string;
    endDate: string;
    timezone?: string;
};

interface ArchiveReportResponse extends DnoResponseBase {
    data: {
        status: REPORT_ARCHIVE_STATUS;
        report: {
            report_id?: string;
            execution_id: string;
            url?: string;
        };
    };
}

const startArchiveCreation = (data: ReportArchiveData): AxiosPromise<ArchiveReportResponse> => {
    return http({
        method: 'POST',
        url: '/v3reports/archive',
        data,
    });
};

interface GetArchiveReportStatusResponse extends DnoResponseBase {
    data: {
        status: REPORT_ARCHIVE_STATUS;
        task_id: string;
        url: string;
    };
}

const getArchiveReportStatus = (taskId: string): AxiosPromise<GetArchiveReportStatusResponse | string> => {
    return http({
        url: `/v3reports/archivestatus`,
        params: {
            taskId,
        },
    });
};

export default {
    startArchiveCreation,
    getArchiveReportStatus,
};
