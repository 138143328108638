<template>
    <div class="zone-block flexbox-column">
        <div class="input-fields">
            <div class="d-flex justify-content-between">
                <div class="grow">
                    <div class="d-flex row">
                        <div class="col-12">
                            <div class="zone-title">
                                {{ $i18n.t('charging.chargingSpecifications.editor.conditionText.otherPartyNumber') }}
                            </div>
                        </div>
                    </div>
                    <div class="d-flex row">
                        <div class="col-6">
                            <AppMultiselectV3
                                v-model="otherPartyNumber.inverted"
                                :allowEmpty="false"
                                :options="options"
                                :searchable="false"
                                :showLabels="false"
                                :borderNone="true"
                                :blueArrow="true"
                                data-test-id="inverted-multiselect"
                                class="multiselect small condition-options"
                                label="label"
                                trackBy="id"
                            />
                        </div>
                        <div class="col-6">
                            <AppMultiselectV3
                                v-model="otherPartyNumber.zoneCodeType"
                                :allowEmpty="false"
                                :options="ZONE_CODE_TYPES_OPTIONS"
                                :searchable="false"
                                :showLabels="false"
                                :borderNone="true"
                                :blueArrow="true"
                                data-test-id="zone-code-type-multiselect"
                                class="multiselect small condition-options"
                                label="label"
                                trackBy="id"
                            />
                        </div>
                    </div>
                    <div>
                        <DuplicateSlot
                            v-model="otherPartyNumber.destinations"
                            :addButtonLabel="'add'"
                            :enableDeleteButton="true"
                            :optional="false"
                            :customDataStructureOnAdd="{ key: [], values: [] }"
                            deleteIconWithoutOffset
                        >
                            <template slot-scope="{ slotNumber }">
                                <div class="col-11 mb-4">
                                    <div class="row">
                                        <div class="col-6">
                                            <AppInputV3
                                                v-model="inputTempOtherPartyNumberKey[slotNumber]"
                                                :label="keyLabel"
                                                :disabled="!!otherPartyNumber.destinations[slotNumber].key.length"
                                                :explanationText="
                                                    $i18n.t(
                                                        'charging.chargingSpecifications.editor.conditionText.pressEnter',
                                                    )
                                                "
                                                :tooltipPosition="TOOLTIP_POSITION.right"
                                                type="number"
                                                data-test-id="number-input"
                                                class="mr-3"
                                                @change="updateKey(slotNumber)"
                                            />
                                            <Tags
                                                v-if="otherPartyNumber.destinations[slotNumber].key.length"
                                                v-model="otherPartyNumber.destinations[slotNumber].key"
                                                class="mt-2"
                                                data-test-id="numbers-tag-key"
                                            />
                                        </div>
                                        <div class="col-6">
                                            <AppInputV3
                                                v-model="inputTempOtherPartyNumberValues[slotNumber]"
                                                :placeholder="
                                                    $i18n.t('charging.chargingSpecifications.editor.inputNumbers')
                                                "
                                                :label="valuesLabel"
                                                :explanationText="
                                                    $i18n.t(
                                                        'charging.chargingSpecifications.editor.conditionText.pressEnter',
                                                    )
                                                "
                                                :tooltipPosition="TOOLTIP_POSITION.right"
                                                type="number"
                                                data-test-id="numbers-tag-input"
                                                class="mr-3"
                                                @change="updateValues(slotNumber)"
                                            />
                                            <Tags
                                                v-if="otherPartyNumber.destinations[slotNumber].values.length"
                                                v-model="otherPartyNumber.destinations[slotNumber].values"
                                                class="mt-2"
                                                data-test-id="numbers-tag-values"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </DuplicateSlot>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// libs
import isEmpty from 'lodash/isEmpty';

// Components
import AppMultiselectV3 from '@/components/partials/inputs/AppMultiselectV3.vue';
import AppInputV3 from '@/components/partials/inputs/AppInputV3.vue';
import DuplicateSlot from '@/components/partials/DuplicateSlot.vue';
import Tags from '@/components/partials/inputs/Tags.vue';

// Helpers
import {
    CONDITION_TO_INVERTED_MAP,
    IS_IS_NOT_OPTIONS,
    BOOLEAN_TO_CONDITION,
} from '@/__new__/services/dno/charging/common/conditionsHelper';
import {
    ZONE_CODE_TYPES,
    ZONE_TYPE_TO_ENUM,
    ENUM_TO_ZONE_TYPE,
    ZONE_CODE_TYPES_OPTIONS,
} from '@/__new__/features/charging/conditionParameters/conditions/SubscriberLocationCondition.vue';
import OtherPartyNumberConditionModel from '@/__new__/services/dno/charging/models/chargingSpecificationConditions/OtherPartyNumberConditionModel';
import { TOOLTIP_POSITION } from '@/common/tooltip';

const defaultSourceItemValue = {
    key: [],
    values: [],
};

export default {
    name: 'OtherPartyNumberCondition',
    components: {
        AppMultiselectV3,
        AppInputV3,
        DuplicateSlot,
        Tags,
    },
    props: {
        value: {
            type: Object,
            default: () => undefined,
        },
    },
    data() {
        return {
            otherPartyNumber: {
                destinations: [
                    {
                        key: [],
                        values: [],
                    },
                ],
                inverted: IS_IS_NOT_OPTIONS[0],
                zoneCodeType: ZONE_CODE_TYPES_OPTIONS[0],
            },
            otherPartyNumberFormatted: new OtherPartyNumberConditionModel(this.value),
            options: IS_IS_NOT_OPTIONS,
            ZONE_CODE_TYPES_OPTIONS,
            defaultSourceItemValue,
            inputTempOtherPartyNumberKey: [],
            inputTempOtherPartyNumberValues: [],
            TOOLTIP_POSITION,
        };
    },
    computed: {
        isCCNDCSelected() {
            return (
                this.otherPartyNumber?.zoneCodeType?.id &&
                this.otherPartyNumber.zoneCodeType.id === ZONE_CODE_TYPES.CC_NDC
            );
        },
        keyLabel() {
            return this.isCCNDCSelected
                ? this.$i18n.t('charging.chargingSpecifications.editor.conditions.subscriberLocation.CC')
                : this.$i18n.t('charging.chargingSpecifications.editor.conditions.subscriberLocation.MCC');
        },
        valuesLabel() {
            return this.isCCNDCSelected
                ? this.$i18n.tc('charging.chargingSpecifications.editor.conditions.subscriberLocation.NDC', 2)
                : this.$i18n.tc('charging.chargingSpecifications.editor.conditions.subscriberLocation.MNC', 2);
        },
    },
    watch: {
        value: {
            handler(val) {
                if (val !== this.otherPartyNumberFormatted) {
                    this.otherPartyNumberFormatted = new OtherPartyNumberConditionModel(this.value);
                    this.createOtherPartyNumberObj();
                }
            },
        },
        otherPartyNumber: {
            handler(newVal) {
                let zoneCodeType;
                let inverted;
                const dst = {};

                if (newVal?.inverted?.id) {
                    inverted = CONDITION_TO_INVERTED_MAP.get(newVal.inverted.id);
                }
                if (newVal?.zoneCodeType?.id) {
                    zoneCodeType = ZONE_TYPE_TO_ENUM[newVal.zoneCodeType.id];
                }
                if (newVal?.destinations.length && newVal?.destinations[0].key) {
                    newVal.destinations.forEach(source => {
                        dst[source.key] = source.values.map(val => val.toString());
                    });
                }

                this.otherPartyNumberFormatted.data = {
                    zoneCodeType,
                    inverted,
                    dst,
                };

                this.otherPartyNumberFormatted.dataEmpty = false;
                this.emitData();
            },
            deep: true,
        },
    },
    created() {
        this.createOtherPartyNumberObj();
    },
    methods: {
        emitData() {
            this.otherPartyNumberFormatted.isDataValid();
            this.$emit('input', this.otherPartyNumberFormatted);
        },
        updateKey(index) {
            this.otherPartyNumber.destinations[index].key.push(this.inputTempOtherPartyNumberKey[index]);
            this.inputTempOtherPartyNumberKey[index] = null;
        },
        updateValues(index) {
            if (
                !this.otherPartyNumber.destinations[index].values.includes(
                    this.inputTempOtherPartyNumberValues[index],
                ) &&
                this.inputTempOtherPartyNumberValues[index]
            ) {
                this.otherPartyNumber.destinations[index].values.push(this.inputTempOtherPartyNumberValues[index]);
            }
            this.inputTempOtherPartyNumberValues[index] = null;
        },
        createOtherPartyNumberObj() {
            if (!isEmpty(this.value?.data)) {
                this.otherPartyNumber.inverted = IS_IS_NOT_OPTIONS.find(
                    option => option.id === BOOLEAN_TO_CONDITION.get(this.value.data.inverted),
                );

                this.otherPartyNumber.zoneCodeType = ZONE_CODE_TYPES_OPTIONS.find(
                    option => option.id === ENUM_TO_ZONE_TYPE[this.value.data.zoneCodeType],
                );

                this.otherPartyNumber.destinations = Object.entries(this.value.data.dst).map(([key, values]) => ({
                    key: [key],
                    values,
                }));
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/_palette.scss';

.grow {
    flex-grow: 1;
}

.zone-title {
    font-size: 0.75rem;
    font-weight: 600;
    line-height: 2.17;
    color: $gray90;
    margin-bottom: 0.25rem;
}

.condition-options {
    min-width: 80px;
    width: max-content;
}
</style>
