
import Vue from 'vue';

// http
import archiveReportsHTTP from '@/__new__/services/dno/reports/http/archiveReports';
// helpers
import { ALERT_TYPES } from '@/common/alerts/Alert';
import { downloadFile } from '@/common/downloadHelper';
import LocalStorageHelper from '@/common/LocalStorageHelper';
import { sendPushNotification } from '@/common/notificationApiHelper';
import {
    REPORT_ARCHIVE_STATUS,
    type ReportArchive,
    type ReportArchiveResponse,
} from '@/__new__/features/reports/common/reportArchiveHelper';

// models
import Button from '@/common/button/Button';
// workers
import ReportArchiveWorker from '@/workers/archiveReport.worker';

export default Vue.extend({
    name: 'ReportsArchiveDownloadMixin',
    data() {
        return {
            downloadAllConfirmBtn: new Button({
                label: this.$t('generic.confirm'),
                alertType: ALERT_TYPES.warning,
                handler: this.generateReportArchive.bind(this),
            }) as Button,
            isGeneratingReport: false as boolean,
            reportArchives: (JSON.parse(LocalStorageHelper.get('reportArchiveTaskIds')) || []) as ReportArchive[],
            worker: new ReportArchiveWorker() as Worker,
        };
    },
    watch: {
        reportArchives: {
            deep: true,
            handler(val) {
                LocalStorageHelper.set('reportArchiveTaskIds', JSON.stringify(val));
            },
        },
    },
    created() {
        this.filterOutStaleData();
        this.listenForPushMessage();
        this.sendDataToWorker();
    },
    methods: {
        filterOutStaleData() {
            const oneDayMillis = 24 * 60 * 60 * 1000;
            const yesterday = Date.now() - oneDayMillis;

            this.reportArchives = this.reportArchives.filter(a => a.created > yesterday);
        },
        downloadAllReports(): void {
            this.isArchiveDialogOpen = true;
            this.isGeneratingReport = true;
            this.$alert(this.$t('analytics.reportsPage.reportGenerationConfirmMsg'), {
                type: ALERT_TYPES.warning,
                buttons: [this.downloadAllConfirmBtn],
            });

            this.$eventBus.$once('alertClosed', () => {
                this.isGeneratingReport = false;
            });

            // Since for 'buttonClicked' event, 'alertClosed' event will also be
            // fired, we need to set isGeneratingReport to true which will be handled
            // in the corresponding API handler(@see generateReportArchive).
            this.$eventBus.$once('buttonClicked', () => {
                this.isGeneratingReport = true;
            });
        },
        async generateReportArchive(): Promise<void> {
            await this.$withProgressBar(
                async () => {
                    const { reportEngine, definitionId, startDate, endDate } = this.buildRequestFromReports;
                    const res = await archiveReportsHTTP.startArchiveCreation({
                        reportEngine,
                        definitionId,
                        startDate,
                        endDate,
                    });

                    if (res?.data?.data?.execution_id) {
                        this.reportArchives.unshift({
                            id: res.data.data.execution_id,
                            reportId: definitionId,
                            range: `${startDate} - ${endDate}`,
                            status: res?.data?.data?.status,
                            type: reportEngine,
                            created: Date.now(),
                        });
                        this.isGeneratingReport = false;
                        this.$showInfoAlert({ message: this.$t('analytics.reportsPage.reportGenerationStarted') });
                        this.sendDataToWorker();
                    }
                },
                {
                    errorHandler: (e: any) => {
                        this.isGeneratingReport = false;
                        // Display only when code is not defined as it's being handled by axios using luaErrors
                        if (!e?.response?.data?.code) {
                            this.$alert(this.$t('analytics.reportsPage.somethingWentWrongSendingAnArchiveReq'));
                        }
                    },
                },
            );
        },
        listenForPushMessage(): void {
            if (!window.Worker) {
                return;
            }

            this.worker.onmessage = ({
                data,
            }: {
                data: ReportArchiveResponse & Record<'error', boolean | undefined>;
            }) => {
                if (data?.error) {
                    this.$alert(this.$t('analytics.reportsPage.somethingWentWrongGeneratingReportArchive'));
                    return;
                }
                this.updateReportArchive(data as ReportArchiveResponse);
            };
        },
        sendDataToWorker(): void {
            // Send ALL always as archive.url needs to be updated with fresh auth token
            this.reportArchives.forEach(archive => {
                if (!archive?.id || !archive?.type) {
                    return;
                }

                const url = archiveReportsHTTP.getArchiveReportStatusUrl(archive);

                this.worker.postMessage({
                    ...archive,
                    api: `${window.location.origin}${url}`,
                });
            });
        },
        updateReportArchive(archive: ReportArchiveResponse): void {
            const index = this.reportArchives.findIndex(ra => ra.id === archive?.id);

            if (!Number.isInteger(index) || !archive?.url) {
                return;
            }
            // Always update URL as it contains auth token.
            this.reportArchives[index].url = archive.url;

            if (
                archive?.status === REPORT_ARCHIVE_STATUS.COMPLETED &&
                archive?.status !== this.reportArchives[index].status
            ) {
                const title = this.$t('analytics.reportsPage.requestedArchiveForRepID', {
                    reportId: this.reportArchives[index].reportId,
                });
                const body = this.$t('analytics.reportsPage.hasBeenGeneratedAndWillBeAutoDownloaded');

                this.$set(this.reportArchives, index, { ...this.reportArchives[index], status: archive.status });

                this.triggerReportArchiveDownload(archive.url);
                sendPushNotification({
                    title,
                    options: {
                        body,
                        icon: `${window.location.origin}/favicon.ico`,
                    },
                    fallbackFn: () => this.$showSuccessAlert({ message: `${title} ${body}` }),
                });
            }
        },
        triggerReportArchiveDownload(url: string) {
            downloadFile(url);
        },
    },
});
