<template>
    <AppCustomDialog
        :id="entity.accountId || $i18n.t('generic.empty')"
        :value="value"
        :title="$i18n.t('customerCare.account.editAccount')"
        :hasAdditionalBtn="
            displayCancelOrderButton() || displayCancelServiceButton() || displayTerminateAccountButton()
        "
        :canUploadPhoto="false"
        :additionalBtnLabel="accountOperationButtonLabel()"
        :titleForIdLabel="$i18n.t('customerCare.accountId')"
        :class="['account-editor-wrapper', { 'overflow-hidden': isCancelServiceModalVisible }]"
        data-test-id="account-editor-wrapper"
        @input="e => $emit('input', e)"
        @clickAdditionalBtn="onAccountOperationClick"
        @save="onSave"
        @cancel="onCancel"
    >
        <!-- DETAILS -->
        <div class="section-title section-margin">
            <div class="mb-4">
                {{ $i18n.t('generic.details') }}
            </div>
            <AppInputV3
                v-model="accountDataForEditing.billingDay"
                :label="$i18n.t('customerCare.account.billingDayEveryMonthOn')"
                class="col-lg-6 col-md-8 col-sm-10 mb-3"
                placeholder="Text"
                :disabled="true"
                @input="updateProperty('billing_day_of_month', parseInt(accountDataForEditing.billingDay))"
            />
            <AppInputV3
                v-if="!isMVNETenant"
                v-model="accountDataForEditing.documentType"
                :label="$i18n.t('customerCare.document')"
                :disabled="isUpdateFieldsDisabled"
                class="col-lg-6 col-md-8 col-sm-10 mb-3"
                placeholder="Text"
                @input="updateProperty('document_type', accountDataForEditing.documentType)"
            />
            <AppInputV3
                v-if="!isMVNETenant"
                v-model="accountDataForEditing.documentNumber"
                :label="$i18n.t('customerCare.userInformation.documentNumber')"
                :disabled="isUpdateFieldsDisabled"
                class="col-lg-6 col-md-8 col-sm-10"
                placeholder="Text"
                @input="updateProperty('document_number', accountDataForEditing.documentNumber)"
            />
        </div>

        <!-- CONTACT -->
        <div
            v-if="!isMVNETenant"
            class="section-title section-margin"
        >
            <div class="mb-4">
                {{ $i18n.t('customerCare.account.contact') }}
            </div>
        </div>

        <!-- ADDRESSES -->

        <!-- TODO: Move this section into its own component -->
        <div
            v-if="!isMVNETenant"
            class="addresses mb-3"
        >
            {{ $t('customerCare.account.accountAddresses') }}
            <AddressEditor
                v-for="(address, index) in addresses"
                :key="index"
                :targetEntityId="accountDataForEditing.accountId"
                :address="address"
                :taxAreaId="address.taxAreaId || null"
                :initialMode="address.initialMode ? address.initialMode : AddressEditorModes.View"
                @closeEmptyAddress="removeAddressFromList(index)"
                @removeDeletedAddress="removeAddressFromList(index)"
                @updatedData="refreshAddresses"
            />

            <hr />

            <AppButton
                v-if="!isUpdateFieldsDisabled"
                label="Add new address"
                :isSmall="true"
                class="mt-2"
                :iconType="ICON_TYPES.PLUS"
                data-test-id="add-new-address"
                @click="addNewAddressToList"
            />
        </div>

        <!-- FLAGS -->
        <div
            v-if="!isEmpty(accountFlagsForEditing) && isFlagSectionEnabled"
            class="section-margin"
            data-test-id="flags"
        >
            <div class="mb-4 section-title">
                {{ $i18n.t('customerCare.flags') }}
            </div>
            <div
                v-for="(flag, index) in accountFlagsForEditing"
                :key="`${flag.name}-${index}`"
            >
                <div
                    v-if="flag.value !== undefined && flag.field !== 'tax_exemption'"
                    class="row no-gutters mb-3"
                >
                    <span class="mr-4">
                        {{ flag.name }}
                    </span>
                    <AppToggle
                        v-model="flag.value"
                        :customId="`${flag.field}_flag`"
                        :disabled="disabledFlagToggle(flag)"
                        :small="true"
                        :data-test-id="flag.field"
                        @input="updateFlag(flag.field, flag.value)"
                    />
                    <span
                        v-if="flag.field === FLAG_TYPE.CANCEL_REQUESTED && flag.value"
                        class="ml-4"
                    >
                        {{ $i18n.t('customerCare.cancelDeactivateServiceModal.reasonCode') }}:
                        {{ cancelServiceReasonCode }}
                    </span>
                </div>
            </div>
        </div>

        <!-- TAX EXEMPTION -->
        <AccountEditorTaxExemption
            v-if="showTaxExemption && taxExemptionFlag"
            :entity="taxExemptionFlag"
            :accountId="entity.accountId"
        />

        <!-- CANCEL SERVICE -->
        <CancelDeactivateService
            :value="isCancelServiceModalVisible"
            :accountId="$route.params.id"
            :isOrderActive="isOrderActive"
            :style="`top: ${isCancelServiceModalOffsetTop}px`"
            @close="cancelDeactivateServiceClose"
        />
    </AppCustomDialog>
</template>

<script>
// Components
import AppToggle from '@/components/partials/inputs/AppToggle.vue';
import AppCustomDialog from '@/components/partials/AppCustomDialog.vue';
import AppInputV3 from '@/components/partials/inputs/AppInputV3.vue';
import { ALERT_TYPES } from '@/common/alerts/Alert';
import Button from '@/common/button/Button';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import { ICON_TYPES } from '@/common/iconHelper';
import AddressEditor, { modes as AddressEditorModes } from '@/__new__/features/customerCare/account/AddressEditor.vue';
import AccountEditorTaxExemption from '@/__new__/features/customerCare/account/AccountEditorTaxExemption.vue';
import CancelDeactivateService from '@/__new__/features/customerCareSuite/components/CancelDeactivateService.vue';

// HTTP
import userManagementHTTP from '@/__new__/services/dno/user/http/user-management';
import ordersHTTP from '@/__new__/services/dno/orders/http/orders';

// helper
import {
    USER_MANAGER_HIERARCHY,
    ADDRESS_TYPE,
    SUBSCRIBER_STATE,
    ADDRESS_TYPE_TO_LABEL_MAP,
    CANCEL_TERMINATE_ACTIONS,
} from '@/__new__/features/customerCare/common/customerCareHelper';
import { FLAG_MAP, FLAG_TYPE } from '@/__new__/services/dno/user/models/Flag';
import { ORDER_ENTITY_STATES } from '@/__new__/features/customerCare/common/orderStateHelper';
import { isEmpty, cloneDeep, pick } from 'lodash';
import permissionsService, {
    getAccountDetailFields,
    getOperatorConfigValue,
    isUserAllowed,
    isViewEnabled,
} from '@/services/permissions/permissions.service';
import { ACCOUNT_DETAIL_FIELD } from '@/__new__/features/customerCare/common/detailFieldsHelper';
import { MODAL_CONTENT } from '@/__new__/features/customerCare/common/userInfoHelper';

// sentry
import * as Sentry from '@sentry/vue';
import supportButtonMixin from '@/components/alerts/supportButtonMixin';

export default {
    name: 'AccountEditor',
    components: {
        AppButton,
        AddressEditor,
        AppInputV3,
        AppToggle,
        AppCustomDialog,
        AccountEditorTaxExemption,
        CancelDeactivateService,
    },
    mixins: [supportButtonMixin],
    props: {
        value: {
            type: Boolean,
            required: true,
        },
        entity: {
            type: Object,
            required: true,
        },
        orders: {
            type: Object,
            default: null,
        },
    },
    data() {
        return {
            isEmpty,
            AddressEditorModes,
            ORDER_ENTITY_STATES,
            ICON_TYPES,
            BUTTON_TYPES,

            ADDRESS_TYPE_TO_LABEL_MAP,
            ADDRESS_TYPE,

            FLAG_TYPE,
            flagsForUpdate: {},
            propertiesForUpdate: {},
            addressesForUpdate: [],
            addressesForAdding: [],
            addressesForDelete: [],
            accountDataForEditing: this.entity,
            orderId: '',
            isTerminationEnabled:
                permissionsService.userManagementAccountTerminationEnabled() &&
                isUserAllowed('UMAccountEditAccountTerminateUndoTermination'),
            isCancelSubscriptionEnabled:
                permissionsService.userManagementCancelSubscriptionEnabled() &&
                isUserAllowed('UMAccountEditAccountCancelUndoCancel'),
            isCancelSubscriptionFlagsEnabled:
                permissionsService.userManagementCancelSubscriptionUpdateFlagsEnabled() &&
                isViewEnabled('UMAccountCancelServicesFlag') &&
                isUserAllowed('UMAccountEditAccountCancelUndoCancel'),
            isCancelOrderEnabled:
                permissionsService.accountOEOrdersEnabled() && isUserAllowed('UMAccountEditAccountCancelOrder'),
            shippingAddresses: [
                {
                    address: '',
                    addressId: null,
                    zip: '',
                    city: '',
                    country: '',
                    state: '',
                    latitude: '',
                    longitude: '',
                    type: ADDRESS_TYPE.SHIPPING,
                },
            ],
            alertButtons: {},
            addresses: [],
            taxExemptionFlag: null,
            isCancelServiceModalVisible: false,
            isCancelServiceModalOffsetTop: 0,
            isUserAllowed,
        };
    },
    computed: {
        isFlagSectionEnabled() {
            // UMendUserAuthorization is a MVNE specific view that forces users to authenticate
            // prior to viewing user info UNLESS UMEndUserFullDataAccess is set.
            return isViewEnabled('UMendUserAuthorization') ? isUserAllowed('UMEndUserFullDataAccess') : true;
        },
        isMVNETenant() {
            return getOperatorConfigValue('isMVNETenant');
        },
        showTaxExemption() {
            const config = getAccountDetailFields();
            return config?.includes(ACCOUNT_DETAIL_FIELD.FLAG_TAX_EXEMPTION);
        },
        isAccountCanceled() {
            return this.entity.flags?.[FLAG_TYPE.CANCEL_REQUESTED]?.value;
        },
        isUpdateFieldsDisabled() {
            return !isUserAllowed('UMAccountEditAccountUpdateFields');
        },
        accountFlagsForEditing() {
            const accountFlagsConfig = getOperatorConfigValue('service_config.lf-user.flags.account_flags');
            const isFilteringPossible =
                Array.isArray(accountFlagsConfig) && !getOperatorConfigValue('isFlagFilteringDisabled');

            const accountFlags = isFilteringPossible
                ? pick(this.accountDataForEditing?.flags, accountFlagsConfig)
                : this.accountDataForEditing?.flags;

            return accountFlags;
        },
        cancelServiceReasonCode() {
            return this.entity?.cancelServiceReason || '';
        },
        isOrderActive() {
            return this.orders[this.orderId]?.state === ORDER_ENTITY_STATES.ACTIVE;
        },
    },
    watch: {
        entity(val) {
            this.accountDataForEditing = cloneDeep(val);
            if (this.accountDataForEditing.addresses.length > 0) {
                this.cloneAddresses(this.accountDataForEditing.addresses);
            }
            this.taxExemptionFlag =
                this.accountDataForEditing?.flags?.[FLAG_TYPE.TAX_EXEMPTION]?.originalValue !== undefined
                    ? this.accountDataForEditing.flags[FLAG_TYPE.TAX_EXEMPTION]
                    : null;
        },
        orders(val) {
            this.orderId = '';
            if (val) {
                for (const [orderId, order] of Object.entries(val)) {
                    if (
                        order.state === ORDER_ENTITY_STATES.PENDING ||
                        order.state === ORDER_ENTITY_STATES.PENDING_ACTIVATION ||
                        order.state === ORDER_ENTITY_STATES.ACTIVE
                    ) {
                        this.orderId = cloneDeep(orderId);
                    }
                }
            }
        },
    },
    methods: {
        // on delete address check if it has address id => add it in array for delete
        // on save check it has id, if it has, call update else call add
        resetData() {
            this.flagsForUpdate = {};
            this.propertiesForUpdate = {};
        },
        async onSave() {
            // delete values for update
            if (!isEmpty(this.flagsForUpdate) || !isEmpty(this.propertiesForUpdate)) {
                this.$Progress.start();
                try {
                    const promises = [];

                    // FLAGS
                    if (!isEmpty(this.flagsForUpdate)) {
                        promises.push(
                            userManagementHTTP.updateFlags(
                                this.entity.accountId,
                                USER_MANAGER_HIERARCHY.ACCOUNT,
                                this.flagsForUpdate,
                            ),
                        );
                    }

                    // PROPERTIES
                    if (!isEmpty(this.propertiesForUpdate)) {
                        promises.push(
                            userManagementHTTP.updateInfo(
                                this.entity.accountId,
                                USER_MANAGER_HIERARCHY.ACCOUNT,
                                this.propertiesForUpdate,
                            ),
                        );
                    }

                    await Promise.all(promises);
                    this.$eventBus.$emit('showAlert', {
                        message: this.$i18n.t('customerCare.account.editAccountSuccess'),
                        type: ALERT_TYPES.success,
                    });
                } catch (e) {
                    Sentry.captureException(e);
                    this.$Progress.fail();
                    this.showSupportAlert(
                        this.$i18n.t('alertMessage.somethingWentWrongFetchingNecessaryData'),
                        ALERT_TYPES.error,
                    );
                } finally {
                    this.$Progress.finish();
                    this.$emit('updatedData');
                }
            }

            this.resetData();

            this.$emit('input', false);
        },
        onCancel() {
            this.resetData();

            // reset data
            this.accountDataForEditing = cloneDeep(this.entity);
            this.$emit('input', false);
        },
        updateProperty(field, value) {
            this.propertiesForUpdate[field] = value;
        },
        updateFlag(field, value) {
            if (field === FLAG_TYPE.CANCEL_REQUESTED && value === true && !this.isMVNETenant) {
                this.isCancelServiceModalVisible = true;
            }
            this.flagsForUpdate[field] = value;
        },
        disabledFlagToggle({ originalValue, field }) {
            if (field === FLAG_TYPE.IS_TEST) {
                return !isUserAllowed('UMAccountEditAccountToggleIsTestFlag');
            }

            return (
                originalValue === FLAG_MAP.FALSE_PENDING ||
                originalValue === FLAG_MAP.TRUE_PENDING ||
                !isUserAllowed('UMAccountEditAccountToggleFlag')
            );
        },
        refreshAddresses() {
            this.$emit('updatedData');
        },
        /**
         * Adds new empty address to the list of addresses. So user can populate and save it.
         */
        addNewAddressToList() {
            this.addresses.push({
                initialMode: AddressEditorModes.Edit,
            });
        },
        onCancelOrderClick() {
            this.alertButtons.confirmButton = new Button({
                label: this.$i18n.t('generic.confirm'),
                alertType: ALERT_TYPES.warning,
            });
            this.$eventBus.$emit('showAlert', {
                message: this.$i18n.t('alerts.orderWillBeCanceled'),
                type: ALERT_TYPES.warning,
                buttons: this.alertButtons,
            });
            this.$eventBus.$once('buttonClicked', id => {
                if (id === this.alertButtons.confirmButton.id) {
                    this.cancelOrder();
                }
            });
        },
        cancelServicePilotFiber(cancel = true) {
            const message = cancel
                ? this.$i18n.t('alerts.prepareServiceCancelation')
                : this.$i18n.t('alerts.resumeService');

            this.alertButtons.confirmButton = new Button({
                label: this.$i18n.t('generic.confirm'),
                alertType: ALERT_TYPES.warning,
            });
            this.$eventBus.$emit('showAlert', {
                message,
                type: ALERT_TYPES.warning,
                buttons: this.alertButtons,
            });
            this.$eventBus.$once('buttonClicked', id => {
                if (id === this.alertButtons.confirmButton.id) {
                    this.setCancelServiceFlag({ cancel_requested: cancel });
                }
            });
        },
        async setCancelServiceFlag(cancelFlag) {
            try {
                this.$Progress.start();
                await userManagementHTTP.updateFlags(this.entity.accountId, USER_MANAGER_HIERARCHY.ACCOUNT, cancelFlag);
                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('alerts.setServiceCancelFlagSuccess'),
                    type: ALERT_TYPES.success,
                });
                this.$Progress.finish();
            } catch (e) {
                this.$Progress.fail();
                Sentry.captureException(e);
                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('alerts.setServiceCancelFlagFailed'),
                });
            } finally {
                this.$emit('updatedData');
            }
        },
        async cancelOrder() {
            try {
                this.$Progress.start();
                await ordersHTTP.oeCancelOrder({
                    targetId: this.entity.accountId,
                    targetType: USER_MANAGER_HIERARCHY.ACCOUNT,
                });
                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('alerts.orderSuccessfullyCanceled'),
                    type: ALERT_TYPES.success,
                });
            } catch (e) {
                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('alerts.unableToCancelOrder'),
                });
                Sentry.captureException(e);
                this.$Progress.fail();
            } finally {
                this.$Progress.finish();
                this.$emit('updatedData');
            }
        },
        getCancelTerminateAction() {
            // UI specific for pilotfiber only.
            if (this.isCancelSubscriptionFlagsEnabled) {
                if (this.isAccountCanceled) {
                    this.cancelServicePilotFiber(!this.isAccountCanceled);
                }
                return false;
            }

            if (this.displayCancelServiceButton()) {
                return !this.isAccountCanceled ? CANCEL_TERMINATE_ACTIONS.CANCEL : CANCEL_TERMINATE_ACTIONS.CANCEL_UNDO;
            }
            if (this.displayTerminateAccountButton()) {
                return CANCEL_TERMINATE_ACTIONS.TERMINATE;
            }

            return false;
        },
        onAccountOperationClick() {
            if (this.getCancelTerminateAction()) {
                this.$emit('input', false);
                this.$emit('suspendServices', {
                    type: MODAL_CONTENT.CANCEL_TERMINATE,
                    action: this.getCancelTerminateAction(),
                });
            } else if (this.displayCancelServiceButton() && !this.isAccountCanceled) {
                this.isCancelServiceModalVisible = true;
                this.isCancelServiceModalOffsetTop =
                    document.getElementsByClassName('account-editor-wrapper')[0]?.firstChild?.scrollTop;
            } else if (this.displayCancelOrderButton()) {
                return this.onCancelOrderClick();
            }

            return false; // button should not be displayed
        },
        displayCancelOrderButton() {
            return (
                this.isCancelOrderEnabled &&
                this.entity.state === SUBSCRIBER_STATE.PENDING &&
                [ORDER_ENTITY_STATES.PENDING, ORDER_ENTITY_STATES.PENDING_ACTIVATION].includes(
                    this.orders[this.orderId]?.state,
                )
            );
        },
        displayCancelServiceButton() {
            return (
                (this.isCancelSubscriptionEnabled || this.isCancelSubscriptionFlagsEnabled) &&
                this.entity.state === SUBSCRIBER_STATE.ACTIVE &&
                !this.isMVNETenant
            );
        },
        displayTerminateAccountButton() {
            return (
                this.isTerminationEnabled &&
                this.entity?.state !== SUBSCRIBER_STATE.TERMINATING &&
                this.entity?.state !== SUBSCRIBER_STATE.TERMINATED
            );
        },
        accountOperationButtonLabel() {
            if (this.displayCancelOrderButton()) {
                return this.$i18n.t('customerCare.account.cancelOrder');
            }

            if (this.displayCancelServiceButton()) {
                const cancelLabel = this.displayTerminateAccountButton()
                    ? this.$i18n.t('customerCare.cancelTerminate.cancelTerminateSubscription')
                    : this.$i18n.t('customerCare.account.cancelService');

                return !this.isAccountCanceled ? cancelLabel : this.$i18n.t('customerCare.account.resumeService');
            }
            if (this.displayTerminateAccountButton()) {
                return this.$i18n.t('customerCare.account.terminateAccount');
            }
            return null; // button should not be displayed
        },
        cloneAddresses(val) {
            this.addresses = cloneDeep(val);
        },
        removeAddressFromList(index) {
            this.addresses.splice(index, 1);
            this.refreshAddresses();
        },
        cancelDeactivateServiceClose() {
            this.isCancelServiceModalVisible = false;
            this.isCancelServiceModalOffsetTop = 0;
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/palette';
@import '~@/assets/scss/icons';

.section-title {
    font-size: 1rem;
    font-weight: 600;
    color: $gray90;
}

.account-editor-wrapper {
    &.overflow-hidden {
        ::v-deep .content {
            overflow: hidden !important;
        }
    }
}

.section-margin {
    margin-bottom: 2.8125rem;
}

.address-title {
    text-transform: uppercase;
    color: $gray-blue;
    font-size: 0.75rem;
    font-weight: bold;
    line-height: 2.17;
    letter-spacing: normal;
}

.address-input-slot {
    width: 90%;
}
</style>
