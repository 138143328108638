import { type AxiosPromise } from 'axios';
import http from '@/http';
import {
    type FetchPartnerPubKeysResponse,
    type RegisterPartnerPubKeysRequest,
    type RegisterPartnerPubKeysResponse,
} from '@/__new__/features/settings/thirdPartyApplications/common/ThirdPartyApplicationsHelper';
import { AssociatedPermissionItem } from '@/__new__/features/customerCareSuite/common/associatedUMEntitiesHelper';

export function fetchExternalPartnerPubKeys(): AxiosPromise<FetchPartnerPubKeysResponse> {
    return http({
        method: 'POST',
        url: '/v3authorization/fetchexternalpartnerpubkeys',
        data: null,
    });
}

export function registerExternalPartnerPubKeys(
    data: RegisterPartnerPubKeysRequest,
): AxiosPromise<RegisterPartnerPubKeysResponse> {
    return http({
        method: 'POST',
        url: '/v3authorization/registerexternalpartnerpubkeys',
        data,
    });
}

export function getPermissions(data: { caller_id: string }): AxiosPromise<AssociatedPermissionItem[]> {
    return http({
        method: 'POST',
        url: '/v3authorization/getpermissions',
        data,
    });
}
export function genereateOperatorSecretKey(operatorName: string): AxiosPromise<unknown> {
    return http({
        method: 'POST',
        url: '/v3authorization/generateoperatorsecretkeys',
        data: { operator_name: operatorName },
    });
}

export default {
    fetchExternalPartnerPubKeys,
    registerExternalPartnerPubKeys,
    genereateOperatorSecretKey,
    getPermissions,
};
