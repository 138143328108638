export enum LOTTERY_PHASE {
    PENDING = 'pending',
    DRAWABLE = 'drawable',
    DRAWN = 'drawn',
}

export enum DRAW_STATE {
    PENDING = 0,
    DRAWN = 1,
}
