
import Vue from 'vue';

// components
import AbstractTableTile from '@/__new__/features/customerCareSuite/components/AbstractTableTile.vue';

// http
import OssmvneHTTP, { OutageNotification } from '@/__new__/services/dno/ossmvne/http/ossmvne';

// helpers
import supportButtonMixin from '@/components/alerts/supportButtonMixin';
import tableColumnType, { type TableColumn } from '@/common/filterTable';
import { USER_MANAGER_HIERARCHY } from '@/__new__/features/customerCare/common/customerCareHelper';
import { AxiosError } from 'axios';
import uniq from 'lodash/uniq';

export default Vue.extend({
    name: 'OutageNotificationsTile',
    components: {
        AbstractTableTile,
    },
    mixins: [supportButtonMixin],
    data() {
        return {
            rawResponse: {} as any,
        };
    },
    computed: {
        notifications(): OutageNotification[] {
            return this.rawResponse.data || [];
        },
        columnsData(): TableColumn[] {
            return [
                {
                    name: this.$t('customerCareSuite.outageNotificationsTile.incidentId'),
                    key: 'incident_id',
                    field: 'incident_id',
                    filterType: tableColumnType.DATE,
                },
                {
                    name: this.$t('generic.status'),
                    key: 'status',
                    field: 'status',
                    filterType: tableColumnType.TEXT_LIMITED_OPTIONS,
                    limitedOptions: uniq(this.notifications.map(e => e.status)),
                },
                {
                    name: this.$t('customerCareSuite.outageNotificationsTile.outageType'),
                    key: 'outage_type',
                    field: 'outage_type',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$t('customerCareSuite.outageNotificationsTile.executiveSummary'),
                    key: 'executive_summary',
                    field: 'executive_summary',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$t('customerCareSuite.outageNotificationsTile.etr'),
                    key: 'etr',
                    field: 'etr',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$t('generic.startTime'),
                    key: 'cim_start_time',
                    mapper: entity => {
                        if (entity.cim_start_time) {
                            const unixTime = new Date(entity.cim_start_time).getTime();
                            return this.$localeLibrary.getFormattedDateAndTime(unixTime);
                        }
                        return '';
                    },
                    sortBy: entity => new Date(entity.cim_start_time).getTime(),
                    field: 'cim_start_time',
                    filterType: tableColumnType.DATE,
                },
                {
                    name: this.$t('generic.endTime'),
                    key: 'cim_end_time',
                    mapper: entity => {
                        if (entity.cim_end_time) {
                            const unixTime = new Date(entity.cim_end_time).getTime();
                            return this.$localeLibrary.getFormattedDateAndTime(unixTime);
                        }
                        return '';
                    },
                    sortBy: entity => new Date(entity.cim_end_time).getTime(),
                    field: 'cim_end_time',
                    filterType: tableColumnType.DATE,
                },
            ];
        },
    },
    created() {
        this.fetchTileData();
    },
    methods: {
        async fetchTileData() {
            this.$emit('isDataLoadingUpd', true);
            await this.$withProgressBar(
                async () => {
                    this.rawResponse = await OssmvneHTTP.getOutageNotifications({
                        target_id: this.$route.params.id,
                        target_type: USER_MANAGER_HIERARCHY.SUBSCRIBER,
                    });
                },
                {
                    errorHandler: error => {
                        this.$emit('dataFetchingError');
                        this.rawResponse = (error as AxiosError).response;
                    },
                },
            );
            this.$emit('isDataLoadingUpd', false);
        },
    },
});
