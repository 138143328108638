import invert from 'lodash/invert';

// values are mapped on server side
export const PeriodUnits = {
    Day: 'Days',
    Week: 'Weeks',
    Month: 'Months',
};

export const PeriodUnitsLabels = {
    [PeriodUnits.Day]: 'Days',
    [PeriodUnits.Month]: 'Months',
    [PeriodUnits.Week]: 'Weeks',
};

export const PeriodUnitLabelToValues = invert(PeriodUnitsLabels);

export default {};
