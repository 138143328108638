import http from '@/http';
import { GuestOrder } from '@/__new__/services/dno/resendQRCode/models/guestOrder';
import { AxiosPromise } from 'axios';
import { DnoResponseBase } from '@/http/index';

interface GuestOrderResponse extends DnoResponseBase {
    code: number;
    module: string;
    orders: {
        [key: string]: {
            state: number;
            order_data: GuestOrder;
        };
    };
}

export function getGuestOrder(data: { guest_id: string; guest_type: number }): AxiosPromise<GuestOrderResponse> {
    return http({
        method: 'POST',
        url: '/v3ordermanager/getguestorder',
        data,
    });
}

export function fetchResendQRCode(data: { guest_id: string; guest_type: number }): AxiosPromise<{
    module: string;
    server_time: number;
}> {
    return http({
        method: 'POST',
        url: '/v3ordermanager/resendQRCode',
        data,
    });
}

export default {
    getGuestOrder,
    fetchResendQRCode,
};
