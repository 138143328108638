<template>
    <AbstractEditPageWrapper>
        <template #header>
            <AppHeader :pageTitle="pageTitle" />
        </template>

        <template #content>
            <!-- General -->
            <div class="section">
                <div class="d-flex align-items-center mb-3">
                    <div class="lf-subtitle mb-0">
                        {{ $i18n.t('generic.general') }}
                        <p
                            v-show="inEditMode && isRewardsDraftEnabled"
                            class="lf-secondary-text mb-0"
                        >
                            {{ $i18n.t('generic.id') }}: {{ $route.params.id }}
                        </p>
                        <p
                            v-show="inEditMode && isRewardsDraftEnabled"
                            class="lf-secondary-text mb-0"
                        >
                            {{ $i18n.t('generic.updateTime') }}:
                            {{ $localeLibrary.getFormattedDateAndTime(updateTime) }}
                        </p>
                        <p
                            v-show="inEditMode && isRewardsDraftEnabled"
                            class="lf-secondary-text mb-0"
                        >
                            {{ $i18n.t('generic.updateUser') }}: {{ updateName }}
                        </p>
                    </div>
                    <LanguageSwitcher
                        v-model="selectedLanguage"
                        :tooltipEnabled="true"
                    />
                    <AppLabel
                        v-if="isDraft"
                        :title="$i18n.t('generic.stateMap.draft')"
                        :color="LABEL_COLOR.gray"
                        class="justify-content-center state-label ml-auto"
                    />
                    <AppLabel
                        v-if="isPublished"
                        :title="$i18n.t('generic.stateMap.published')"
                        :color="LABEL_COLOR.green"
                        class="justify-content-center state-label ml-auto"
                    />
                    <AppLabel
                        v-if="isUnpublished"
                        :title="$i18n.t('generic.stateMap.unpublishedChanges')"
                        :color="LABEL_COLOR.gray"
                        class="justify-content-center state-label ml-3"
                    />
                    <AppButton
                        v-if="allowEditDraftBtn"
                        :buttonType="BUTTON_TYPES.SECONDARY"
                        :label="$i18n.t('generic.editDraft')"
                        :class="['ml-3', { 'ml-auto': noLabels }]"
                        @click="reloadEditor(false)"
                    />
                    <AppButton
                        v-if="allowViewPublishedBtn"
                        :buttonType="BUTTON_TYPES.SECONDARY"
                        :label="$i18n.t('generic.viewPublished')"
                        :class="['ml-3', { 'ml-auto': noLabels }]"
                        @click="reloadEditor(true)"
                    />
                </div>

                <AppInputV3
                    v-model="reward.name[selectedLanguage]"
                    class="editor-input-largest mb-3"
                    data-test-id="name-input"
                    :label="$i18n.t('generic.name')"
                    :placeholder="$i18n.t('generic.addName')"
                    :invalid="$v.reward.name[selectedLanguage].$error"
                    :disabled="readonly"
                    type="text"
                />
                <AppTextareaV3
                    v-model.trim="reward.description[selectedLanguage]"
                    class="description-textarea editor-input-largest mb-3"
                    :label="$i18n.t('generic.description')"
                    :placeholder="$i18n.t('partials.appTextarea.description')"
                    :disabled="readonly"
                />
                <div
                    v-if="!inEditMode"
                    class="d-flex approve-on-create mb-3"
                >
                    <span class="label">
                        {{ $i18n.t('rewards.editor.general.approveOnCreation') }}
                    </span>
                    <AppToggle
                        v-model="approveOnCreate"
                        class="approve-checkbox"
                        :small="true"
                        :disabled="readonly"
                    />
                </div>
                <DateTimePicker
                    v-model="reward.startTime"
                    class="mb-3"
                    data-test-id="start-time-datepicker"
                    :additionalLabel="`${$i18n.t('generic.startTime')} (${userGMTString})`"
                    :error="$v.reward.startTime.$error"
                    :disabled="readonly"
                    type="datetime"
                />
                <DateTimePicker
                    v-model="reward.endTime"
                    data-test-id="end-time-datepicker"
                    :additionalLabel="`${$i18n.t('generic.endTime')} (${userGMTString})`"
                    :disabled="readonly || !hasEndTime"
                    :error="$v.reward.endTime.$error"
                    type="datetime"
                />
                <div class="row mb-3">
                    <AppCheckbox
                        v-model="hasEndTime"
                        data-test-id="has-end-time-checkbox"
                        :labelRight="$i18n.t('rewards.editor.rewardEndTime')"
                        :inputValue="true"
                        :disabled="readonly"
                        class="padding-l has-end-time"
                        name="hasEndTime"
                    />
                </div>
            </div>
            <!-- Conditions -->
            <div class="section position-relative mb-4">
                <div
                    v-t="'generic.conditions'"
                    class="lf-subtitle"
                />
                <AppButton
                    v-if="!gConditionsTree"
                    class="mb-2"
                    data-test-id="create-condition-btn"
                    :label="$t('rewards.editor.createCondition')"
                    :disabled="readonly"
                    isSmall
                    @click="createConditionsTreeRootNode"
                />
                <div class="conditions__btns">
                    <AppButton
                        v-if="gConditionsTree"
                        class="conditions__btn"
                        :label="conditionsTreeToggleBtnLabel"
                        :disabled="readonly"
                        isSmall
                        @click="conditionsTreeIsCollapsed = !conditionsTreeIsCollapsed"
                    />
                    <AppButton
                        v-if="gConditionsTree"
                        class="conditions__btn"
                        :label="showLegacyConditionsLabel"
                        :disabled="readonly"
                        isSmall
                        @click="showLegacyConditions = !showLegacyConditions"
                    />
                </div>
                <ConditionsTree
                    v-if="!!gConditionsTree"
                    class="editor-input-largest"
                    :node="gConditionsTree"
                    :isCollapsed="conditionsTreeIsCollapsed"
                    :displayError="conditionsTreeDisplayError"
                    :disabled="readonly"
                    :optionsConfig="optionsConfig"
                    :showLegacy="showLegacyConditions"
                    @updateErrorState="conditionsTreeHasError = $event"
                />
                <span
                    v-if="!gConditionsTree && $v.gConditionsTree.$error"
                    class="condition-error-message"
                >
                    {{ $t('rewards.editor.pleaseSetupCondition') }}
                </span>
            </div>
            <!-- Cohort expression -->
            <div class="section pb-3">
                <div
                    v-if="cohortsEnabled"
                    class="cohort mb-4"
                >
                    <div class="heading mb-3">
                        {{ $i18n.t('segments.cohortExpression') }}
                        <span class="text-gray"> ({{ $i18n.t('generic.optional') }}) </span>
                    </div>
                    <CohortExpression
                        v-model="reward.cohortsObj"
                        class="cohort-expression mb-4"
                        :disabled="readonly"
                        data-test-id="cohort-expression"
                        @input="reward.cohortsObj = $event"
                    />
                </div>
            </div>
            <!-- Referrer cohort expression -->
            <div class="section pb-3">
                <div
                    v-if="cohortsEnabled"
                    class="cohort mb-4"
                >
                    <div class="heading mb-3">
                        {{ $i18n.t('segments.referrerCohortExpression') }}
                        <span class="text-gray"> ({{ $i18n.t('generic.optional') }}) </span>
                    </div>
                    <CohortExpression
                        v-model="reward.referrerCohortsObj"
                        class="cohort-expression mb-4"
                        :disabled="readonly"
                        data-test-id="referrer-cohort-expression"
                        @input="reward.referrerCohortsObj = $event"
                    />
                </div>
            </div>
            <!-- Incompatible Rules -->
            <div class="section pb-3">
                <div class="heading mb-3">
                    {{ $i18n.t('rewards.editor.incompatibleRules') }}
                    <span class="text-gray"> ({{ $i18n.t('generic.optional') }}) </span>
                </div>
                <AppMultiselectV3
                    ref="conflictSelection"
                    v-model="reward.incompatibleRules"
                    data-test-id="incompatible-rules-input"
                    class="editor-input-largest"
                    :multiple="true"
                    :options="eligibleConflictRules"
                    :placeholder="$i18n.t('rewards.editor.chooseConflictingRules')"
                    :additionalLabel="$i18n.t('rewards.editor.conflicts')"
                    :explanationText="$i18n.t('rewards.editor.conflictExplanation')"
                    :tooltipOffset="-50"
                    :closeOnSelect="false"
                    :disabled="readonly"
                    label="name"
                    trackBy="id"
                    @input="reward.incompatibleRules = $event"
                >
                    <template
                        slot="option"
                        slot-scope="props"
                    >
                        <div>
                            <div
                                class="font-weight-bold mb-2"
                                data-test-id="conflict-selection-option-name"
                            >
                                {{ props.option.name }}
                            </div>
                            <div class="mb-2">{{ $i18n.t('generic.id') }}: {{ props.option.id }}</div>
                        </div>
                    </template>
                </AppMultiselectV3>
            </div>
            <!-- Payouts -->
            <div class="section mb-3">
                <div
                    v-t="'generic.payouts'"
                    class="heading"
                />
                <div
                    v-t="'rewards.editor.payoutsConditions'"
                    class="sub-heading mb-3"
                />
                <div class="input-label">
                    {{ $i18n.t('generic.receiver') }}
                </div>
                <div class="row">
                    <AppCheckbox
                        v-model="reward.payOutToReferral"
                        class="referral-checkbox"
                        :labelRight="$i18n.t('rewards.editor.payOutToReferrer')"
                        :disabled="readonly"
                    />
                </div>
                <div class="row mb-2">
                    <AppCheckbox
                        v-model="reward.countPayoutOnReferral"
                        class="referral-checkbox"
                        :labelRight="$i18n.t('rewards.editor.countAndLimitPayoutOnReferrer')"
                        :disabled="readonly"
                    />
                </div>
                <AppMultiselectV3
                    v-model="reward.payouts"
                    data-test-id="payouts-select"
                    class="editor-input-largest"
                    :additionalLabel="$i18n.t('generic.payouts')"
                    :options="payoutOptions"
                    :placeholder="$i18n.t('rewards.editor.choosePayouts')"
                    :multiple="true"
                    :small="true"
                    :showLabels="false"
                    :closeOnSelect="false"
                    :error="$v.reward.payouts.$error"
                    :disabled="readonly"
                    label="name"
                    trackBy="id"
                />
            </div>
            <!-- Payout Limits -->
            <div class="section pb-3">
                <div class="heading mb-3">
                    {{ $i18n.t('generic.payoutLimits') }}
                    <span class="text-gray"> ({{ $i18n.t('generic.optional') }}) </span>
                </div>
                <div class="d-flex align-items-center">
                    <span class="payout-limits-text">
                        {{ $i18n.t('rewards.editor.maxOf') }}
                    </span>
                    <AppInputV3
                        :value="reward.maxPurchases"
                        :invalid="$v.reward.maxPurchases.$error"
                        :disabled="readonly"
                        class="mx-3"
                        type="number"
                        placeholder="0"
                        @input="reward.maxPurchases = $event || null"
                    />
                    <span class="payout-limits-text">
                        {{ $i18n.t('rewards.editor.payoutsInTotal') }}
                    </span>
                </div>
                <div class="d-flex align-items-center mt-2">
                    <span class="payout-limits-text">
                        {{ $i18n.t('rewards.editor.maxOf') }}
                    </span>
                    <AppInputV3
                        :value="reward.maxPurchasesPerUser"
                        :invalid="$v.reward.maxPurchasesPerUser.$error"
                        :disabled="readonly"
                        class="mx-3"
                        type="number"
                        placeholder="0"
                        @input="reward.maxPurchasesPerUser = $event || null"
                    />
                    <span class="payout-limits-text">
                        {{ $i18n.t('rewards.editor.payoutsPerCustomerInLifetime') }}
                    </span>
                </div>
                <div class="d-flex align-items-center mt-2">
                    <span class="payout-limits-text">{{ $i18n.t('rewards.editor.maxOf') }}</span>
                    <AppInputV3
                        :value="reward.maxPurchasesPerDuration.overall.max"
                        :invalid="$v.reward.maxPurchasesPerDuration.overall.max.$error"
                        :disabled="readonly"
                        data-test-id="overall-max-input"
                        class="mx-3"
                        type="number"
                        placeholder="0"
                        @input="reward.maxPurchasesPerDuration.overall.max = $event || null"
                    />
                    <span class="payout-limits-text">
                        {{ $i18n.t('rewards.editor.payoutsOverallEvery') }}
                    </span>
                    <AppInputV3
                        :value="reward.maxPurchasesPerDuration.overall.numberOfDurations"
                        :invalid="$v.reward.maxPurchasesPerDuration.overall.numberOfDurations.$error"
                        :disabled="readonly || disableMaxPurchasesPerDurationOverall"
                        data-test-id="overall-number-of-durations-input"
                        class="mx-3"
                        type="number"
                        placeholder="0"
                        @input="reward.maxPurchasesPerDuration.overall.numberOfDurations = $event || null"
                    />
                    <AppMultiselectV3
                        v-model="reward.maxPurchasesPerDuration.overall.duration"
                        class="time-select"
                        :options="payoutLimitDurationOptions"
                        :showLabels="false"
                        :borderNone="true"
                        :small="true"
                        :allowEmpty="false"
                        :preselectFirst="true"
                        :disabled="readonly || disableMaxPurchasesPerDurationOverall"
                        label="label"
                        trackBy="id"
                    />
                </div>
                <div class="d-flex align-items-center mt-2">
                    <span class="payout-limits-text">
                        {{ $i18n.t('rewards.editor.maxOf') }}
                    </span>
                    <AppInputV3
                        :value="reward.maxPurchasesPerDuration.perSubscriber.max"
                        :invalid="$v.reward.maxPurchasesPerDuration.perSubscriber.max.$error"
                        :disabled="readonly"
                        data-test-id="per-subscriber-max-input"
                        class="mx-3"
                        type="number"
                        placeholder="0"
                        @input="reward.maxPurchasesPerDuration.perSubscriber.max = $event || null"
                    />
                    <span class="payout-limits-text">
                        {{ $i18n.t('rewards.editor.payoutsPerCustomerEvery') }}
                    </span>
                    <AppInputV3
                        :value="reward.maxPurchasesPerDuration.perSubscriber.numberOfDurations"
                        :invalid="$v.reward.maxPurchasesPerDuration.perSubscriber.numberOfDurations.$error"
                        :disabled="readonly || disableMaxPurchasesPerDurationPerSubscriber"
                        data-test-id="per-subscriber-number-of-durations-input"
                        class="mx-3"
                        type="number"
                        placeholder="0"
                        @input="reward.maxPurchasesPerDuration.perSubscriber.numberOfDurations = $event || null"
                    />
                    <AppMultiselectV3
                        v-model="reward.maxPurchasesPerDuration.perSubscriber.duration"
                        class="time-select"
                        :options="payoutLimitDurationOptions"
                        :showLabels="false"
                        :borderNone="true"
                        :small="true"
                        :allowEmpty="false"
                        :preselectFirst="true"
                        :disabled="readonly || disableMaxPurchasesPerDurationPerSubscriber"
                        label="label"
                        trackBy="id"
                    />
                </div>
            </div>
            <!-- Payout Timing (Thresholds) -->
            <div class="section pb-3">
                <div class="heading mb-3">
                    {{ $i18n.t('rewards.editor.payoutTiming') }}
                    <span class="text-gray"> ({{ $i18n.t('generic.optional') }}) </span>
                </div>
                <div class="d-flex align-items-center">
                    <span class="payout-limits-text">
                        {{ $i18n.t('rewards.editor.every') }}
                    </span>
                    <AppInputV3
                        :value="reward.intervalThreshold"
                        :invalid="$v.reward.intervalThreshold.$error"
                        :min="1"
                        :allowEmptyValueAsNull="true"
                        :disabled="readonly"
                        data-test-id="interval-threshold-input"
                        class="mx-3"
                        type="number"
                        placeholder="1"
                        @input="reward.intervalThreshold = $event"
                    />
                    <span class="payout-limits-text">
                        {{ $i18n.t('rewards.editor.timesRewardRuleIsSatisfied') }}
                    </span>
                </div>
                <div class="d-flex align-items-center mt-2">
                    <span class="payout-limits-text">
                        {{ $i18n.t('rewards.editor.specificallyOn') }}
                    </span>
                    <TagsInput
                        data-test-id="specific-thresholds-input"
                        class="mx-3"
                        :value="reward.specificThresholds.map(v => ({ text: v }))"
                        :invalid="$v.reward.specificThresholds.$error"
                        :validation="specificThresholdsValidation"
                        :disabled="readonly"
                        @input="reward.specificThresholds = ($event || []).map(t => t.text)"
                    />
                    <span class="payout-limits-text">
                        {{ $i18n.t('rewards.editor.timesRewardRuleIsSatisfied') }}
                    </span>
                </div>
            </div>
        </template>
        <!-- Footer section -->
        <template #controls>
            <EditorButtons
                :showSaveDraft="isRewardsDraftEnabled && !readonly"
                :showPublish="isRewardsDraftEnabled && !readonly"
                :showSave="!isRewardsDraftEnabled"
                :disableSave="readonly"
                @cancel="onCancel"
                @saveDraft="onSave(false)"
                @publish="onSave(true)"
                @save="onSave(true)"
            />
        </template>
    </AbstractEditPageWrapper>
</template>

<script>
// Vuex
import Actions, { Getters } from '@/store/mutation-types';
import { mapGetters, mapActions, mapMutations } from 'vuex';

import AppHeader from '@/components/layout/AppHeader.vue';
import AppInputV3 from '@/components/partials/inputs/AppInputV3.vue';
import AppTextareaV3 from '@/components/partials/inputs/AppTextareaV3.vue';
import AppMultiselectV3 from '@/components/partials/inputs/AppMultiselectV3.vue';
import AppToggle from '@/components/partials/inputs/AppToggle.vue';
import AppCheckbox from '@/components/partials/inputs/AppCheckbox.vue';
import AbstractEditPageWrapper from '@/components/layout/AbstractEditPageWrapper.vue';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import Button from '@/common/button/Button';
import { ICON_TYPES } from '@/common/iconHelper';
import ConditionsTree from '@/modules/rewards/components/conditions-tree/ConditionsTree.vue';
import CohortExpression from '@/components/cohortExpression/CohortExpression.vue';
import DateTimePicker from '@/components/partials/inputs/DateTimePicker.vue';
import entityEditorMixin from '@/common/entityEditorMixin';
import ENTITY_TYPES from '@/common/entities/entityTypes';
import LanguageSwitcher from '@/components/partials/inputs/LanguageSwitcher.vue';
import RouteNames from '@/router/routeNames';
import TagsInput from '@/components/partials/inputs/TagsInput.vue';
import { validationMixin } from 'vuelidate';
import { ALERT_TYPES } from '@/common/alerts/Alert';
import { payoutLimitDuration, rewardReceiver, getDuplicatesMessage } from '@/modules/rewards/common/rewardsRulesHelper';
import { getMultiLangFieldValueByLocale } from '@/common/entities/entityHelper';
import { getUserGMTString } from '@/common/utils';
import { createTreeNode } from '@/common/conditions-tree';
import * as Sentry from '@sentry/vue';
import { required, minLength, minValue, numeric, integer, requiredIf } from 'vuelidate/lib/validators';
import moment from 'moment';
import permissionsService, { isUserAllowed } from '@/services/permissions/permissions.service';
import { Modules } from '@/store/store';
import {
    cohortExpressionsFromJson,
    cohortExpressionsToJson,
    isNonEmptyCohortExpression,
} from '@/common/cohortExpressionHelper';
import EditorButtons from '@/components/layout/EditorButtons.vue';
import { manageEntityAdd, manageEntityUpdate } from '@/common/EntityLoadHelper';
import {
    addRewardRuleV4,
    updateRewardRuleV4,
    getRewardRuleDraft,
    setRewardRuleDraft,
    getOptionsConfig,
} from '@/__new__/services/dno/rewards/http/rewards';
import { cloneDeep } from 'lodash';
import { getUserNameById } from '@/__new__/services/portal/profile/http/profile';
import { LABEL_COLOR } from '@/common/labelsHelper';
import AppLabel from '@/components/partials/AppLabel.vue';
import { STATUS_CODES } from '@/common/commonHelper';
import { languageMap } from '@/common/locale/language';

export default {
    name: 'RewardEditor',
    components: {
        AppHeader,
        AppInputV3,
        AppTextareaV3,
        AppToggle,
        AppMultiselectV3,
        AppLabel,
        AppCheckbox,
        AppButton,
        AbstractEditPageWrapper,
        ConditionsTree,
        CohortExpression,
        DateTimePicker,
        LanguageSwitcher,
        TagsInput,
        EditorButtons,
    },
    mixins: [validationMixin, entityEditorMixin],
    data() {
        return {
            ICON_TYPES,
            BUTTON_TYPES,
            LABEL_COLOR,
            entityType: this.$i18n.t('generic.reward'),
            selectedLanguage: languageMap.en.key,
            reward: {
                name: {},
                description: {},
                startTime: new Date(),
                endTime: new Date(),
                payOutToReferral: false,
                countPayoutOnReferral: false,
                payouts: [],
                maxPurchases: null,
                maxPurchasesPerUser: null,
                maxPurchasesPerDuration: {
                    overall: {
                        max: null,
                        duration: null,
                        numberOfDurations: null,
                    },
                    perSubscriber: {
                        max: null,
                        duration: null,
                        numberOfDurations: null,
                    },
                },
                cohortsObj: {
                    applicableCohorts: [],
                    notApplicableCohorts: [],
                    everyApplicableCohort: false,
                    everyNotApplicableCohort: false,
                },
                referrerCohortsObj: {
                    applicableCohorts: [],
                    notApplicableCohorts: [],
                    everyApplicableCohort: false,
                    everyNotApplicableCohort: false,
                },
                intervalThreshold: null,
                specificThresholds: [],
                incompatibleRules: [],
            },
            hasEndTime: false,
            initialData: null,
            approveOnCreate: false,
            userGMTString: getUserGMTString(),
            conditionsTreeHasError: false,
            conditionsTreeDisplayError: false,
            conditionsTreeIsCollapsed: false,
            showLegacyConditions: false,
            rewardReceiver,
            refreshDateKey: 0,
            permissionsService,
            entityDraft: undefined,
            optionsConfig: undefined,
            publishedEntity: undefined,
            updateName: this.$i18n.t('generic.N/A'),
            updateTime: null,
            readonly: false,
            isUnpublished: false,
            isOnlyDraft: false,
            isRewardsDraftEnabled: permissionsService.isRewardsDraftEnabled(),
            saveButtonClicked: false,
            draftSavedId: null,
        };
    },

    validations() {
        return {
            gConditionsTree: {
                required,
                validCondition() {
                    return !this.conditionsTreeHasError;
                },
            },
            reward: {
                name: {
                    [this.selectedLanguage]: {
                        required,
                    },
                },
                startTime: {
                    required: requiredIf(() => this.hasEndTime),
                },
                endTime: {
                    required: requiredIf(() => this.hasEndTime),
                },
                payouts: {
                    required,
                    minLength: minLength(1),
                },
                maxPurchases: {
                    numeric,
                    minValue: minValue(1),
                },
                maxPurchasesPerUser: {
                    numeric,
                    minValue: minValue(1),
                },
                maxPurchasesPerDuration: {
                    overall: {
                        max: {
                            required: requiredIf(() => !!this.reward.maxPurchasesPerDuration.overall.numberOfDurations),
                            numeric,
                            minValue: minValue(1),
                        },
                        numberOfDurations: {
                            required: requiredIf(() => !!this.reward.maxPurchasesPerDuration.overall.max),
                            numeric,
                            minValue: minValue(1),
                        },
                    },
                    perSubscriber: {
                        max: {
                            required: requiredIf(
                                () => !!this.reward.maxPurchasesPerDuration.perSubscriber.numberOfDurations,
                            ),
                            numeric,
                            minValue: minValue(1),
                        },
                        numberOfDurations: {
                            required: requiredIf(() => !!this.reward.maxPurchasesPerDuration.perSubscriber.max),
                            numeric,
                            minValue: minValue(1),
                        },
                    },
                },
                intervalThreshold: {
                    integer,
                    minValue: minValue(1),
                },
                specificThresholds: {
                    $each: {
                        integer,
                        minValue: minValue(1),
                    },
                },
            },
        };
    },

    computed: {
        ...mapGetters('productcatalog', [Getters.PC_GET_ENTITY_BY_TYPE_AND_ID]),
        ...mapGetters('rewards', {
            getEntities: Getters.GET_REWARDS_ENTITIES_LIST_BY_TYPE,
            getEntity: Getters.GET_REWARDS_ENTITY_BY_TYPE_AND_ID,
            getNonDeletedEntities: Getters.GET_NOT_DELETED_REWARDS_ENTITIES_BY_TYPE,
            getApprovedEntities: Getters.GET_APPROVED_REWARDS_ENTITIES_BY_TYPE,
        }),
        ...mapGetters({
            gConditionsTree: 'conditionsTree/conditionsTree',
        }),
        ...mapGetters(Modules.segments, {
            segments: Getters.CACHED_SEGMENTS,
            groupedSegments: Getters.GROUPED_SEGMENTS_BY_ID_TYPE,
        }),
        showLegacyConditionsLabel() {
            return this.showLegacyConditions
                ? this.$t('rewards.editor.hideLegacy')
                : this.$t('rewards.editor.showLegacy');
        },
        conditionsTreeToggleBtnLabel() {
            return this.conditionsTreeIsCollapsed ? this.$t('generic.expandAll') : this.$t('generic.collapseAll');
        },
        payouts() {
            return this.getNonDeletedEntities(ENTITY_TYPES.REWARD_PAYOUT);
        },
        payoutOptions() {
            return this.payouts.map(entity => {
                const { name } = entity.data;
                return {
                    id: entity.id,
                    name: getMultiLangFieldValueByLocale(name),
                };
            });
        },
        payoutOptionsMap() {
            return this.payoutOptions.reduce((acc, payout) => {
                acc[payout.id] = payout;
                return acc;
            }, {});
        },
        payoutLimitDurationOptions() {
            return [
                {
                    id: 1,
                    label: this.$i18n.t('generic.days').toString().toUpperCase(),
                    value: payoutLimitDuration.DAYS,
                },
                {
                    id: 2,
                    label: this.$i18n.t('generic.weeks').toString().toUpperCase(),
                    value: payoutLimitDuration.WEEKS,
                },
                {
                    id: 3,
                    label: this.$i18n.t('generic.months').toString().toUpperCase(),
                    value: payoutLimitDuration.MONTHS,
                },
                {
                    id: 5,
                    label: this.$i18n.t('generic.years').toString().toUpperCase(),
                    value: payoutLimitDuration.YEARS,
                },
            ];
        },
        disabledDates() {
            return { to: new Date(this.reward.startTime) };
        },
        cohortsEnabled() {
            return (
                (permissionsService.segmentsEnabled() || permissionsService.userGroupsEnabled()) &&
                isUserAllowed('SegmentsRead', 'SegmentsWrite')
            );
        },
        arrayCohortsAll() {
            return this.groupedSegments.map(group => group.groupValues).flat();
        },
        specificThresholdsValidation() {
            return [
                {
                    classes: 'only-numbers',
                    rule: /^[1-9][0-9]*$/,
                    disableAdd: true,
                },
            ];
        },
        eligibleConflictRules() {
            return this.getNonDeletedEntities(ENTITY_TYPES.REWARD_RULE)
                .filter(o => o.id !== this.$route.params.id)
                .map(o => ({
                    name: getMultiLangFieldValueByLocale(o.data.name),
                    id: o.id,
                }))
                .sort((a, b) => a.name.localeCompare(b.name));
        },
        inEditMode() {
            return Boolean(this.$route.params.id) && !this.$route.params.clone;
        },
        isDraft() {
            return (
                this.$route.params.id && !this.$route.params.readonly && this.entityDraft && this.isRewardsDraftEnabled
            );
        },
        isPublished() {
            return this.$route.params.id && this.$route.params.readonly && this.isRewardsDraftEnabled;
        },
        noLabels() {
            return !this.isDraft && !this.isPublished;
        },
        allowViewPublishedBtn() {
            return (
                this.$route.params.id && !this.$route.params.readonly && !this.isOnlyDraft && this.isRewardsDraftEnabled
            );
        },
        allowEditDraftBtn() {
            return (
                this.$route.params.id && this.$route.params.readonly && !this.isOnlyDraft && this.isRewardsDraftEnabled
            );
        },
        pageTitle() {
            if (this.$route.params.readonly) {
                return `${this.entityType}`;
            }
            if (this.inEditMode) {
                return `${this.$i18n.t('generic.edit')} ${this.entityType} ${
                    this.isDraft ? this.$i18n.t('generic.stateMap.draft') : ''
                }`;
            }
            return `${this.$i18n.t('generic.addNew')} ${this.entityType}`;
        },
        // once a rule has been paid out, it is dangerous to allow editing of this field [SER-18296]
        disableMaxPurchasesPerDurationPerSubscriber() {
            return (
                (this.publishedEntity?.data?.metadata?.trigger_count ?? 0) > 0 &&
                this.publishedEntity?.data?.max_purchases_per_duration?.per_subscriber
            );
        },
        disableMaxPurchasesPerDurationOverall() {
            return (
                (this.publishedEntity?.data?.metadata?.trigger_count ?? 0) > 0 &&
                this.publishedEntity?.data?.max_purchases_per_duration?.overall
            );
        },
    },
    watch: {
        gConditionsTree(newVal, oldVal) {
            if (!newVal || !oldVal) {
                this.$v.gConditionsTree.$reset();
                this.conditionsTreeHasError = false;
                this.conditionsTreeIsCollapsed = false;
            }
        },
        'reward.startTime': {
            handler() {
                // to display initial value properly
                this.refreshDateKey += 1;
            },
        },
        'reward.endTime': {
            handler() {
                // to display initial value properly
                this.refreshDateKey += 1;
            },
        },
    },

    created() {
        this.$withLoadingSpinner(async () => {
            if (this.$route.params.readonly) {
                this.readonly = this.$route.params.readonly;
            }

            const promises = [
                this.getSetOptionsConfig(),
                this[Actions.REQUEST_REWARDS_ENTITIES_BY_TYPE](ENTITY_TYPES.REWARD_RULE),
                this[Actions.REQUEST_REWARDS_ENTITIES_BY_TYPE](ENTITY_TYPES.REWARD_PAYOUT),
                this[Actions.REQUEST_REWARDS_ENTITIES_BY_TYPE](ENTITY_TYPES.VOUCHER_SET),
                // loaded here since used in ConditionsTree -> ConditionArguments and those components are recurring
                this[Actions.PC_REQUEST_ENTITIES_BY_TYPE_AND_IDS]({ entityType: ENTITY_TYPES.PRODUCT }),
                this[Actions.PC_REQUEST_ENTITIES_BY_TYPE_AND_IDS]({ entityType: ENTITY_TYPES.OFFER }),
            ];
            if (permissionsService.segmentsEnabled() && isUserAllowed('SegmentsRead', 'SegmentsWrite')) {
                promises.push(this.requestSegments());
            }
            await Promise.all(promises);

            if (this.$route.params.id) {
                if (this.isRewardsDraftEnabled && !this.$route.params.readonly) {
                    const { id } = this.$route.params;
                    const result = await getRewardRuleDraft(id);
                    this.entityDraft = result?.data?.data ? result.data.data[id] : null;
                    if (this.entityDraft) this.entityDraft.state = STATUS_CODES.NA;
                }
                await this.setupEditPageData();
            }
        });
    },

    beforeDestroy() {
        this.mClearConditionsMap();
    },

    methods: {
        ...mapActions('productcatalog', [Actions.PC_REQUEST_ENTITIES_BY_TYPE_AND_IDS]),
        ...mapActions('rewards', [Actions.REQUEST_REWARDS_ENTITIES_BY_TYPE]),
        ...mapActions('conditionsTree', {
            aBuildClientConditionsTree: 'buildClientConditionsTree',
            aBuildServerConditionsTree: 'buildServerConditionsTree',
        }),
        ...mapMutations({
            mUpdateTree: 'conditionsTree/updateTree',
            mClearConditionsMap: 'conditionsTree/clearConditionsMap',
        }),
        ...mapActions(Modules.segments, {
            requestSegments: Actions.FETCH_SEGMENTS,
        }),
        async getSetOptionsConfig() {
            const result = await getOptionsConfig();
            this.optionsConfig = result?.data?.config;
        },
        async setupEditPageData() {
            this.publishedEntity = this.getEntity(ENTITY_TYPES.REWARD_RULE, this.$route.params.id);
            if (this.entityDraft && !(this.$route.params.clone && this.publishedEntity)) {
                this.initialData = cloneDeep(this.entityDraft);
                this.initialData.version = this.publishedEntity.version;
                this.isOnlyDraft = !this.publishedEntity || this.publishedEntity.state === STATUS_CODES.NA;
                if (this.publishedEntity.update_time <= this.initialData.update_time) {
                    this.isUnpublished = true;
                }
            } else {
                this.initialData = cloneDeep(this.publishedEntity);
            }

            if (!this.initialData?.data) {
                return;
            }

            this.updateTime = this.initialData.update_time;
            this.getUpdateUserName(this.initialData.update_portal_id || this.initialData.portal_id || null);

            this.reward.name[this.selectedLanguage] = this.$route.params.clone
                ? `${this.initialData.data.name[this.selectedLanguage]} (cloned)`
                : this.initialData.data.name[this.selectedLanguage];
            this.reward.description[this.selectedLanguage] = this.initialData.data.description[this.selectedLanguage];
            this.reward.startTime = moment(this.initialData.data.start_time * 1000).toDate();
            if (this.initialData.data.end_time) {
                this.reward.endTime = moment(this.initialData.data.end_time * 1000).toDate();
                this.hasEndTime = true;
            }
            await this.aBuildClientConditionsTree({
                root: this.initialData.data.condition,
                optionsConfig: this.optionsConfig,
            });
            this.reward.payOutToReferral = this.initialData.data.payout_target === rewardReceiver.REFERRER;
            this.reward.countPayoutOnReferral = this.initialData.data.count_payout_on_referral;
            this.initialData.data.payouts.forEach(payoutId => {
                this.reward.payouts.push(this.payoutOptionsMap[payoutId]);
            });
            this.setupEditPageMaxPurchases();
            if (this.initialData.data.cohort_expression) {
                const cohortExpression = this.initialData.data.cohort_expression;
                const cohortsObj = cohortExpressionsFromJson(cohortExpression, this.arrayCohortsAll);
                this.reward.cohortsObj = cohortsObj;
            }
            if (this.initialData.data.referrer_cohort_expression) {
                const referrerCohortExpression = this.initialData.data.referrer_cohort_expression;
                const referrerCohortsObj = cohortExpressionsFromJson(referrerCohortExpression, this.arrayCohortsAll);
                this.reward.referrerCohortsObj = referrerCohortsObj;
            }
            if (this.initialData.data.thresholds?.interval) {
                this.reward.intervalThreshold = this.initialData.data.thresholds.interval;
            }
            if (this.initialData.data.thresholds?.specific) {
                this.reward.specificThresholds = this.initialData.data.thresholds.specific.map(n => String(n));
            }
            if (this.initialData.data.incompatible_rules) {
                this.reward.incompatibleRules = this.initialData.data.incompatible_rules.map(id => {
                    let name = id;
                    let rule = this.getNonDeletedEntities(ENTITY_TYPES.REWARD_RULE).find(r => r.id === id);
                    if (rule) {
                        name = getMultiLangFieldValueByLocale(rule.data.name);
                    } else {
                        rule = this.getEntities(ENTITY_TYPES.REWARD_RULE).find(r => r.id === id);
                        if (rule) {
                            name = getMultiLangFieldValueByLocale(rule.data.name);
                        }
                        name += ` (${this.$i18n.t('finalStateMapper.Deleted')})`;
                    }
                    return {
                        name,
                        id,
                    };
                });
            }
        },
        setupEditPageMaxPurchases() {
            this.reward.maxPurchases = this.initialData.data.max_purchases;
            this.reward.maxPurchasesPerUser = this.initialData.data.max_purchases_per_user;
            /* eslint-disable */
            if (this.initialData.data.max_purchases_per_duration?.overall?.max) {
                this.reward.maxPurchasesPerDuration.overall.max =
                    this.initialData.data.max_purchases_per_duration?.overall?.max;
                this.reward.maxPurchasesPerDuration.overall.numberOfDurations =
                    this.initialData.data.max_purchases_per_duration?.overall?.number_of_durations;
                this.reward.maxPurchasesPerDuration.overall.duration = this.payoutLimitDurationOptions.find(
                    op => op.value === this.initialData.data.max_purchases_per_duration?.overall?.duration,
                );
            }
            if (this.initialData.data.max_purchases_per_duration?.per_subscriber?.max) {
                this.reward.maxPurchasesPerDuration.perSubscriber.max =
                    this.initialData.data.max_purchases_per_duration?.per_subscriber?.max;
                this.reward.maxPurchasesPerDuration.perSubscriber.numberOfDurations =
                    this.initialData.data.max_purchases_per_duration?.per_subscriber?.number_of_durations;
                this.reward.maxPurchasesPerDuration.perSubscriber.duration = this.payoutLimitDurationOptions.find(
                    op => op.value === this.initialData.data.max_purchases_per_duration?.per_subscriber?.duration,
                );
            }
            /* eslint-enable */
        },
        onCancel() {
            this.showDontLeaveAlert(this.goToRewardRulesListPage);
        },
        async trySaveDraft() {
            // for draft, check conditions: can save without tree
            if (!this.gConditionsTree) {
                await this.submit(false);
                return;
            }
            // but if tree exists it must be valid
            this.conditionsTreeDisplayError = false;
            this.$v.gConditionsTree.$reset();
            await this.$nextTick();
            this.$v.gConditionsTree.$touch();
            if (this.$v.gConditionsTree.$invalid) {
                this.conditionsTreeDisplayError = this.$v.gConditionsTree.$error;
                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('alertMessage.pleaseFixValidation'),
                });
                return;
            }
            // submit
            await this.submit(false);
        },
        async tryPublish() {
            // check if there are validation errors
            this.conditionsTreeDisplayError = false;
            this.$v.gConditionsTree.$reset();
            await this.$nextTick();
            this.$v.$touch();
            if (this.$v.$invalid) {
                this.conditionsTreeDisplayError = this.$v.gConditionsTree.$error;
                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('alertMessage.pleaseFixValidation'),
                });
                return;
            }
            // check if the start date is in the past
            if (moment(this.reward.startTime).isBefore(moment(new Date()))) {
                await this.showDateInPastAlert(
                    () => this.submit(true),
                    this.$i18n.t('productCatalog.entities.rewardRule'),
                );
                return;
            }
            // submit
            await this.submit(true);
        },
        async onSave(isPublish) {
            if (this.saveButtonClicked) {
                return;
            }
            this.saveButtonClicked = true;

            if (!isPublish) {
                await this.trySaveDraft();
            } else {
                await this.tryPublish();
            }

            this.saveButtonClicked = false;
        },
        async submit(isPublish) {
            const submissionData = await this.buildSubmissionData();
            if (!isPublish) {
                await this.addOrUpdate(submissionData, false);
                return;
            }
            // Check if there are duplicate rules
            const approvedRules = this.getApprovedEntities(ENTITY_TYPES.REWARD_RULE);
            const duplicatesMessage = getDuplicatesMessage(
                this.initialData?.id,
                submissionData.condition,
                this.$route.params.clone,
                approvedRules,
            );
            if (duplicatesMessage) {
                const proceedButton = new Button({
                    label: this.$i18n.t('generic.proceed'),
                    alertType: ALERT_TYPES.warning,
                });
                this.$eventBus.$emit('showAlert', {
                    message: duplicatesMessage,
                    type: ALERT_TYPES.warning,
                    buttons: [proceedButton],
                });
                this.$eventBus.$once('buttonClicked', async buttonId => {
                    if (buttonId === proceedButton.id) {
                        await this.addOrUpdate(submissionData, true);
                    }
                });
            } else {
                await this.addOrUpdate(submissionData, true);
            }
        },
        async buildSubmissionData() {
            const submissionData = {
                name: this.reward.name,
                description: this.reward.description,
                start_time: Date.parse(this.reward.startTime) / 1000,
                end_time: this.hasEndTime && this.reward.endTime ? Date.parse(this.reward.endTime) / 1000 : undefined,
                condition: this.gConditionsTree
                    ? await this.aBuildServerConditionsTree(this.gConditionsTree)
                    : undefined,
                payout_target: this.reward.payOutToReferral ? rewardReceiver.REFERRER : rewardReceiver.USER,
                count_payout_on_referral: this.reward.countPayoutOnReferral,
                payouts: this.reward.payouts.map(payout => payout.id),
                max_purchases: this.reward.maxPurchases ?? undefined,
                max_purchases_per_user: this.reward.maxPurchasesPerUser ?? undefined,
            };
            if (isNonEmptyCohortExpression(this.reward.cohortsObj)) {
                submissionData.cohort_expression = cohortExpressionsToJson(this.reward.cohortsObj);
            }
            if (isNonEmptyCohortExpression(this.reward.referrerCohortsObj)) {
                submissionData.referrer_cohort_expression = cohortExpressionsToJson(this.reward.referrerCohortsObj);
            }
            if (
                this.reward.maxPurchasesPerDuration.overall.max ||
                this.reward.maxPurchasesPerDuration.perSubscriber.max
            ) {
                submissionData.max_purchases_per_duration = {};
            }
            if (this.reward.maxPurchasesPerDuration.overall.max) {
                submissionData.max_purchases_per_duration.overall = {
                    max: this.reward.maxPurchasesPerDuration.overall.max,
                    duration: this.reward.maxPurchasesPerDuration.overall.duration.value,
                    number_of_durations: this.reward.maxPurchasesPerDuration.overall.numberOfDurations,
                };
            }
            if (this.reward.maxPurchasesPerDuration.perSubscriber.max) {
                submissionData.max_purchases_per_duration.per_subscriber = {
                    max: this.reward.maxPurchasesPerDuration.perSubscriber.max,
                    duration: this.reward.maxPurchasesPerDuration.perSubscriber.duration.value,
                    number_of_durations: this.reward.maxPurchasesPerDuration.perSubscriber.numberOfDurations,
                };
            }
            if ((this.reward.intervalThreshold || 0) > 1 || this.reward.specificThresholds.length > 0) {
                submissionData.thresholds = {};
                if (this.reward.intervalThreshold) {
                    submissionData.thresholds.interval = this.reward.intervalThreshold;
                }
                if (this.reward.specificThresholds.length > 0) {
                    submissionData.thresholds.specific = this.reward.specificThresholds.map(t => Number(t));
                }
            }
            if (this.reward.incompatibleRules.length > 0) {
                submissionData.incompatible_rules = this.reward.incompatibleRules.map(o => o.id);
            }
            return submissionData;
        },
        async addOrUpdate(submissionData, isPublish) {
            try {
                this.$Progress.start();
                const payload = {
                    data: submissionData,
                };
                const addDraftAction = async () => {
                    if (!this.isRewardsDraftEnabled) return null;
                    const resp = await setRewardRuleDraft(payload);
                    this.draftSavedId = resp?.data?.id;
                    return resp;
                };
                const addEntityAction = id =>
                    addRewardRuleV4({
                        ...payload,
                        approve_on_create: this.approveOnCreate,
                        id,
                    });
                const updateDraftAction = () =>
                    this.isRewardsDraftEnabled
                        ? setRewardRuleDraft({
                              ...payload,
                              id: this.$route.params.id ?? this.draftSavedId,
                          })
                        : null;
                const updateEntityAction = () =>
                    updateRewardRuleV4(this.$route.params.id, this.initialData.version, submissionData);
                const hasStoredDraft = this.draftSavedId || (this.entityDraft && !this.$route.params.clone);
                const hasStoredEntity =
                    this.publishedEntity && this.publishedEntity.state !== STATUS_CODES.NA && !this.$route.params.clone;
                if (!hasStoredEntity) {
                    await manageEntityAdd(
                        hasStoredDraft ? updateDraftAction : addDraftAction,
                        addEntityAction,
                        isPublish,
                    );
                } else {
                    await manageEntityUpdate(updateDraftAction, updateEntityAction, isPublish);
                }
                this.$eventBus.$emit('showAlert', {
                    message: this.successSaveMessage,
                    type: ALERT_TYPES.success,
                });
                this.entityEditorMixin.successfullySaved = true;
                await this.getEntities(ENTITY_TYPES.REWARD_RULE);
                setTimeout(this.goToRewardRulesListPage, 1000);
            } catch (error) {
                Sentry.captureException(error);
                this.$Progress.fail();
                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('alertMessage.errorDoingSmthTryAgain', {
                        action: 'saving',
                        entityName: 'reward',
                    }),
                });
            }
        },
        goToRewardRulesListPage() {
            this.$router.push({ name: RouteNames.REWARDS_VIEW, params: { companyId: this.$route.params.companyId } });
        },
        createConditionsTreeRootNode() {
            const node = createTreeNode({});
            this.mUpdateTree(node);
        },
        async getUpdateUserName(id) {
            try {
                if (id) {
                    const response = await getUserNameById(Number(id));
                    if (response?.data) {
                        this.updateName = response.data;
                    }
                }
            } catch (e) {
                Sentry.captureException(e);
            }
        },
        reloadEditor(isReadonly) {
            const { id } = this.$route.params;
            // Use push to list page because router don`t want to reload same page
            this.$router
                .push({ name: RouteNames.REWARDS_VIEW, params: { companyId: this.$route.params.companyId } })
                .then(() => {
                    this.$router.push({
                        name: RouteNames.REWARD_EDITOR,
                        params: {
                            id,
                            readonly: isReadonly,
                            companyId: this.$route.params.companyId,
                        },
                    });
                });
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/palette';

.section {
    .heading {
        margin: 0;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.63;
        font-size: 1rem;
        color: $gray90;
    }

    .sub-heading {
        font-size: 0.812rem;
        line-height: 2.15;
        color: $gray60;
    }

    .section-content {
        padding: 0.75rem 3rem;
    }
}

.approve-on-create {
    .label {
        font-size: 0.75rem;
        font-weight: 700;
        color: black;
        flex: 0 1 9rem;
    }

    & > .approve-checkbox {
        margin-left: -0.25rem;
    }
}

.input-label {
    font-weight: 600;
}

.referral-checkbox {
    &::v-deep {
        span {
            font-size: 0.75rem;
        }
    }
}

.text-gray {
    color: $gray30;
    font-weight: 400;
}

.description-textarea {
    &::v-deep {
        .label-text {
            line-height: 1.125rem;
        }

        textarea {
            margin-top: 0.5rem;
        }
    }
}

.payout-limits-text {
    color: $gray-blue;
    font-size: 0.75rem;
    font-weight: 700;
    text-transform: uppercase;
}

.condition-error-message {
    position: absolute;
    bottom: -1.5rem;
    left: 0;
    color: $red;
}

.cohort-expression {
    padding: 0.625rem;
    border: 0.0625rem solid $gray5;
    border-radius: 0.5rem;
    max-width: 45rem;
}

.conditions {
    margin: 0.625rem 0 0 0;

    &__btns {
        display: flex;
    }

    &__btn {
        margin-left: 0.625rem;
    }
}
</style>
