
// components
import AppDialogV2 from '@/components/partials/AppDialogV2.vue';
import AppCollapsibleList from '@/components/partials/AppCollapsibleList.vue';

// helpers
import { ICON_TYPES } from '@/common/iconHelper';
import { CollapsibleListItem } from '@/common/AppCollapsibleListHelper';

export default {
    name: 'AppOverviewBlock',
    components: {
        AppDialogV2,
        AppCollapsibleList,
    },
    props: {
        popupTitle: {
            type: String,
            default: '',
        },
        /**
         * The title of the AppOverviewBlock.
         * Note: If a title is provided then all items (collapsible lists) will be wrapped by block with the given title.
         * If no title is provided then the items (collapsible lists) will be rendered sequentially (with no surrounding block).
         */
        title: {
            type: String,
            default: '',
        },
        titleIcon: {
            type: String,
            default: '',
        },
        isTitleCounterVisible: {
            type: Boolean,
            default: true,
        },
        isRowTitleCounterVisible: {
            type: Boolean,
            default: true,
        },
        topLevelCombinator: {
            type: String,
            default: '',
        },
        /**
         * Each item will be rendered in a collapsible list with a title of `item.name`
         */
        items: {
            type: Array,
            default: () => [],
            required: true,
        },
        /**
         * The amount of items (collapsible lists) that will be shown
         * If this amount is exceeded, the popup trigger button will be displayed
         * */
        maxItems: {
            type: Number,
            default: 5,
        },
        // Max number of rows inside a collapsible list before a button to show all rows is shown
        maxItemRows: {
            type: Number,
            default: 5,
        },
    },
    data() {
        return {
            ICON_TYPES,
            popupData: '',
        };
    },
    computed: {
        modifiedItems(): CollapsibleListItem[] {
            // data in popup should be in expanded state initially
            return this.items.map(item => ({
                ...item,
                isCollapsed: false,
            }));
        },
        isPopUpVisible() {
            return Boolean(this.popupData);
        },
    },
    methods: {
        onSeeAllItemsClick(): void {
            this.popupData = this.modifiedItems;
        },
        onSeeAllRowsClick(itemIndex): void {
            this.popupData = [this.modifiedItems[itemIndex]];
        },
        closePopup(): void {
            this.popupData = null;
        },
    },
};
