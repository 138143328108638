import { render, staticRenderFns } from "./PricingRulesEditorKuiperDemo.vue?vue&type=template&id=c685d50e&scoped=true"
import script from "./PricingRulesEditorKuiperDemo.vue?vue&type=script&lang=js"
export * from "./PricingRulesEditorKuiperDemo.vue?vue&type=script&lang=js"
import style0 from "./PricingRulesEditorKuiperDemo.vue?vue&type=style&index=0&id=c685d50e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c685d50e",
  null
  
)

export default component.exports