
// components
import AppDialogV2 from '@/components/partials/AppDialogV2.vue';
import AppCollapsibleList from '@/components/partials/AppCollapsibleList.vue';

// helpers
import { ICON_TYPES } from '@/common/iconHelper';
import { CollapsibleListItem } from '@/common/AppCollapsibleListHelper';

export default {
    name: 'AppOverviewBlock',
    components: {
        AppDialogV2,
        AppCollapsibleList,
    },
    props: {
        popupTitle: {
            type: String,
            default: '',
        },
        title: {
            type: String,
            default: '',
        },
        titleIcon: {
            type: String,
            default: '',
        },
        isTitleCounterVisible: {
            type: Boolean,
            default: true,
        },
        isRowTitleCounterVisible: {
            type: Boolean,
            default: true,
        },
        topLevelCombinator: {
            type: String,
            default: '',
        },
        items: {
            type: Array,
            default: () => [],
            required: true,
        },
        /** the ammount of items that will be shown
            if this ammout exceeded, the popup trigger btn will be displayed
        * */
        maxItems: {
            type: Number,
            default: 5,
        },
        maxItemRows: {
            type: Number,
            default: 5,
        },
    },
    data() {
        return {
            ICON_TYPES,
            popupData: '',
        };
    },
    computed: {
        modifiedItems(): CollapsibleListItem[] {
            // data in popup should be in expanded state initially
            return this.items.map(item => ({
                ...item,
                isCollapsed: false,
            }));
        },
        isPopUpVisible() {
            return Boolean(this.popupData);
        },
    },
    methods: {
        onSeeAllItemsClick(): void {
            this.popupData = this.modifiedItems;
        },
        onSeeAllRowsClick(itemIndex): void {
            this.popupData = [this.modifiedItems[itemIndex]];
        },
        closePopup(): void {
            this.popupData = null;
        },
    },
};
