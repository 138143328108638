export default class Locale {
    timezone?: string;
    timeFormat?: string;
    dateFormat?: string;
    language?: string;
    currency?: string;

    constructor({
        timezone = undefined,
        dateFormat = undefined,
        timeFormat = undefined,
        currency = undefined,
        language = undefined,
    }) {
        this.timezone = timezone;
        this.dateFormat = dateFormat;
        this.timeFormat = timeFormat;
        this.currency = currency;
        this.language = language;
    }
}
