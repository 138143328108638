import { uuidV4 } from '@/common/utils';

export default class ViasatPartner {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    id: ReturnType<typeof uuidV4>;
    company: string;
    group: string;
    role: string;
    bankDetails: string;
    commissionStructure: string;
    status: string;
    dateCreated: number;
    tags: string[];

    constructor({
        firstName,
        lastName,
        email,
        phone,
        company,
        group,
        role,
        bankDetails,
        commissionStructure,
        status,
        dateCreated,
        tags,
    }: {
        firstName?: string;
        lastName?: string;
        email?: string;
        phone?: string;
        company?: string;
        group?: string;
        role?: string;
        bankDetails?: string;
        commissionStructure?: string;
        status?: string;
        dateCreated?: number;
        tags?: string[];
    } = {}) {
        this.firstName = firstName || '';
        this.lastName = lastName || '';
        this.email = email || '';
        this.phone = phone || '';
        this.id = uuidV4().substring(2, 18);
        this.company = company || '';
        this.group = group || '';
        this.role = role || '';
        this.bankDetails = bankDetails || '';
        this.commissionStructure = commissionStructure || '';
        this.status = status || '';
        this.dateCreated = dateCreated || new Date().getTime();
        this.tags = tags || [];
    }
}
