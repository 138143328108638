<template>
    <div>
        <div class="d-flex align-items-center justify-content-end position-relative">
            <SearchBox
                v-model="balanceSearchQuery"
                class="table-search-box"
                :smallSearchIcon="true"
            />
            <ResponseModalButton
                :response="apiResponse"
                :title="$i18n.t('customerCare.userInformation.activeBalances')"
            />
            <FilterTable
                :columns="getColumnDataForFilter"
                :multiselectWidth="{ width: '12rem' }"
                @filterAdded="onFilterAdd"
            >
                <template #button="slotProps">
                    <IconButton
                        :label="$i18n.t('generic.filter')"
                        :icon="ICON_TYPES.FILTER"
                        class="mr-2"
                        @iconClick="slotProps.clickHandler"
                    />
                </template>
            </FilterTable>

            <div class="d-flex align-items-center">
                <div class="section-toggle-label mr-3">
                    {{ $i18n.t('customerCare.userInformation.activeBalances') }}
                </div>
                <AppToggle
                    v-model="showOnlyActiveBalances"
                    customId="customer-care-balance-show-active"
                    :small="true"
                />
            </div>
        </div>

        <TableFiltersTags
            :filterTableMixin="{ allFilters }"
            class="mt-1 mb-3"
            @removeFilter="removeFilter"
            @removeAllFilters="removeAllFilters"
        />

        <AppTabsV2
            :sectionsCount="1"
            :tabsCount="3"
            :disableSectionPaddings="true"
            :enableHeaderMargin="false"
            @tabSelected="changeSelectedIndex"
        >
            <!-- eslint-disable vue/no-useless-template-attributes -->
            <template
                #tabHeader1
                class="text-uppercase"
            >
                {{ $i18n.t('customerCare.balance.nonMonetary') }}
            </template>

            <!-- Non-monetary balances -->
            <template #tab1_sectionContent1>
                <div class="w-100">
                    <TableFiltersRenderless
                        ref="nonMonetaryTableFilterWrapper"
                        :entities="nonMonetaryBalancesToDisplayForTable"
                        @allFiltersUpdate="allFilters = $event"
                    >
                        <template
                            #default="{
                                // state
                                filteredEntities,
                            }"
                        >
                            <AppTable
                                :entities="filteredEntities"
                                :isDefaultHoverEnabled="false"
                                :isPaginationEnabled="true"
                                :newDesign="true"
                                :canSelectColumns="true"
                                :columnsData="nonMonetaryBalancesColumsData"
                                tableKey="BalanceOverviewnonMonetary"
                            >
                                <template #name="{ entity }">
                                    <span
                                        v-tooltip="{
                                            content: entity.bucketId || entity.name,
                                            classes: ['dark'],
                                            placement: 'bottom',
                                            trigger: 'hover click',
                                            autoHide: false,
                                        }"
                                        class="font-weight-bold"
                                        :class="{ 'pl-3': !entity.isParent }"
                                    >
                                        {{ entity.name }}
                                    </span>
                                </template>

                                <template #voiceSlot="{ entity }">
                                    <AppProgressBar
                                        v-if="!entity.isParent"
                                        :unavailable="
                                            !(entity.serviceTypeFirst === CHARGING_SPECIFICATION_OPTIONS.Voice.key)
                                        "
                                        :currentValue="convertSecondsToMinutes(entity.amount_available)"
                                        :maximalValue="convertSecondsToMinutes(entity.amount_initial)"
                                        :tooltipText="`${entity.amount_available} ${$i18n.t('generic.seconds')}`"
                                        color="#335195"
                                        :units="$i18n.t('generic.mins')"
                                    />
                                </template>

                                <template #dataSlot="{ entity }">
                                    <AppProgressBar
                                        v-if="!entity.isParent"
                                        :unavailable="!isServiceTypeData(entity)"
                                        :currentValue="scaleBytes(entity.amount_available)"
                                        :maximalValue="scaleBytes(entity.amount_initial)"
                                        :tooltipText="`${entity.amount_available} ${$i18n
                                            .t('generic.bytes')
                                            .toLowerCase()}`"
                                        color="#37c9aa"
                                        :units="$i18n.t('generic.mb')"
                                    />
                                </template>

                                <template #smsSlot="{ entity }">
                                    <AppProgressBar
                                        v-if="!entity.isParent"
                                        :unavailable="
                                            !(entity.serviceTypeFirst === CHARGING_SPECIFICATION_OPTIONS.SMS.key)
                                        "
                                        :currentValue="entity.amount_available"
                                        :maximalValue="entity.amount_initial"
                                        :tooltipText="`${entity.amount_available} ${$i18n.t('generic.smss')}`"
                                        color="#ffad20"
                                        :units="$i18n.t('generic.smss')"
                                    />
                                </template>

                                <template #mmsSlot="{ entity }">
                                    <AppProgressBar
                                        v-if="!entity.isParent"
                                        :unavailable="
                                            !(entity.serviceTypeFirst === CHARGING_SPECIFICATION_OPTIONS.MMS.key)
                                        "
                                        :currentValue="entity.amount_available"
                                        :maximalValue="entity.amount_initial"
                                        :tooltipText="`${entity.amount_available} ${$i18n.t('generic.mmss')}`"
                                        color="#ffad20"
                                        :units="$i18n.t('generic.mmss')"
                                    />
                                </template>

                                <template #apiSlot="{ entity }">
                                    <AppProgressBar
                                        v-if="!entity.isParent"
                                        :unavailable="
                                            !(entity.serviceTypeFirst === CHARGING_SPECIFICATION_OPTIONS.API.key)
                                        "
                                        :currentValue="entity.amount_available"
                                        :maximalValue="entity.amount_initial"
                                        :tooltipText="`${entity.amount_available} ${$i18n.t('generic.apis')}`"
                                        color="#ffad20"
                                        :units="$i18n.t('generic.apis')"
                                    />
                                </template>

                                <template #state="{ entity }">
                                    <span v-if="!isUndefined(entity.is_active)">
                                        {{ getStateAsString(entity.is_active) }}
                                    </span>

                                    <i v-else-if="!entity.isParent">{{ $i18n.t('generic.empty') }}</i>
                                </template>
                            </AppTable>
                        </template>
                    </TableFiltersRenderless>
                </div>
            </template>

            <template
                #tabHeader2
                class="text-uppercase"
            >
                {{ $i18n.t('customerCare.balance.unlimited') }}
            </template>

            <!-- Unlimited Balances -->
            <template #tab2_sectionContent1>
                <div class="w-100">
                    <TableFiltersRenderless
                        ref="unlimitedTableFilterWrapper"
                        :entities="unlimitedBalancesToDisplayForTable"
                        @allFiltersUpdate="allFilters = $event"
                    >
                        <template
                            #default="{
                                // state
                                filteredEntities,
                            }"
                        >
                            <AppTable
                                :entities="filteredEntities"
                                :isDefaultHoverEnabled="false"
                                :isPaginationEnabled="true"
                                :newDesign="true"
                                :canSelectColumns="true"
                                :columnsData="unlimitedBalancesColumsData"
                                tableKey="BalanceOverviewunlimited"
                            >
                                <template #name="{ entity }">
                                    <span
                                        v-tooltip="{
                                            content: entity.bucketId || entity.name,
                                            classes: ['dark'],
                                            placement: 'bottom',
                                            trigger: 'hover click',
                                            autoHide: false,
                                        }"
                                        class="font-weight-bold"
                                        :class="{ 'pl-3': !entity.isParent }"
                                    >
                                        {{ entity.name }}
                                    </span>
                                </template>

                                <template #serviceType="{ entity }">
                                    {{
                                        entity.serviceTypeFirst
                                            ? getChargingSpecificationTypeAsString(entity.serviceTypeFirst)
                                            : ''
                                    }}
                                </template>

                                <template #amountUsed="{ entity }">
                                    <div v-if="isServiceTypeData(entity)">
                                        <div
                                            v-tooltip="{
                                                content: `${entity.amount_used} B`,
                                                classes: ['dark'],
                                                placement: 'bottom',
                                                trigger: 'hover click',
                                                autoHide: false,
                                            }"
                                            class="pb-2 w-100 truncate-text"
                                        >
                                            {{ entity.amountUsedFormatted }}
                                        </div>
                                    </div>
                                    <div
                                        v-else-if="entity.serviceTypeFirst === CHARGING_SPECIFICATION_OPTIONS.Voice.key"
                                    >
                                        <div
                                            v-tooltip="{
                                                content: `${entity.amount_used} s`,
                                                classes: ['dark'],
                                                placement: 'bottom',
                                                trigger: 'hover click',
                                                autoHide: false,
                                            }"
                                            class="pb-2 w-100 truncate-text"
                                        >
                                            {{ entity.amountUsedFormatted }}
                                        </div>
                                    </div>
                                    <div v-else-if="entity.serviceTypeFirst === CHARGING_SPECIFICATION_OPTIONS.SMS.key">
                                        {{ `${entity.amount_used} ${$i18n.t('generic.smss')}` }}
                                    </div>
                                    <div v-else-if="entity.serviceTypeFirst === CHARGING_SPECIFICATION_OPTIONS.MMS.key">
                                        {{ `${entity.amount_used} ${$i18n.t('generic.mmss')}` }}
                                    </div>
                                    <div v-else-if="entity.serviceTypeFirst === CHARGING_SPECIFICATION_OPTIONS.API.key">
                                        {{ `${entity.amount_used} ${$i18n.t('generic.apis')}` }}
                                    </div>
                                </template>

                                <template #bucketIdSlot="{ entity }">
                                    <div
                                        v-if="entity.bucketId"
                                        v-tooltip="{
                                            content: entity.bucketId,
                                            classes: ['dark'],
                                            placement: 'bottom',
                                            trigger: 'hover click',
                                            autoHide: false,
                                        }"
                                        class="id-field truncate-text"
                                    >
                                        {{ entity.bucketId }}
                                    </div>

                                    <i v-else-if="!entity.isParent">{{ $i18n.t('generic.empty') }}</i>
                                </template>

                                <template #state="{ entity }">
                                    <span v-if="!isUndefined(entity.is_active)">
                                        {{ getStateAsString(entity.is_active) }}
                                    </span>

                                    <i v-else-if="!entity.isParent">{{ $i18n.t('generic.empty') }}</i>
                                </template>
                            </AppTable>
                        </template>
                    </TableFiltersRenderless>
                </div>
            </template>

            <!-- Monetary Balances -->
            <template
                #tabHeader3
                class="text-uppercase"
            >
                {{ $i18n.t('customerCare.balance.monetary') }}
            </template>

            <template #tab3_sectionContent1>
                <div class="w-100">
                    <TableFiltersRenderless
                        ref="monetaryTableFilterWrapper"
                        :entities="monetaryBalancesToDisplayForTable"
                        @allFiltersUpdate="allFilters = $event"
                    >
                        <template
                            #default="{
                                // state
                                filteredEntities,
                            }"
                        >
                            <AppTable
                                :entities="filteredEntities"
                                :isDefaultHoverEnabled="false"
                                :isPaginationEnabled="true"
                                :newDesign="true"
                                :canSelectColumns="true"
                                :columnsData="monetaryBalancesColumsData"
                                tableKey="BalanceOverviewmonetary"
                            >
                                <template #name="{ entity }">
                                    <span
                                        v-tooltip="{
                                            content: entity.bucketId || entity.name,
                                            classes: ['dark'],
                                            placement: 'bottom',
                                            trigger: 'hover click',
                                            autoHide: false,
                                        }"
                                        class="font-weight-bold"
                                        :class="{ 'pl-3': !entity.isParent }"
                                    >
                                        {{ entity.name }}
                                    </span>
                                </template>

                                <template #serviceType="{ entity }">
                                    <div class="d-flex align-items-center">
                                        <span>
                                            {{
                                                entity.serviceTypeFirst
                                                    ? getChargingSpecificationTypeAsString(entity.serviceTypeFirst)
                                                    : ''
                                            }}
                                        </span>
                                    </div>
                                </template>

                                <template #amountUsedFormatted="{ entity }">
                                    <div v-if="entity.serviceTypeFirst === CHARGING_SPECIFICATION_OPTIONS.Data.key">
                                        <div
                                            v-tooltip="{
                                                content: `${entity.amount_used} B`,
                                                classes: ['dark'],
                                                placement: 'bottom',
                                                trigger: 'hover click',
                                                autoHide: false,
                                            }"
                                            class="pb-2 w-100 truncate-text"
                                        >
                                            {{ entity.amountUsedFormatted }}
                                        </div>
                                    </div>
                                    <div
                                        v-else-if="entity.serviceTypeFirst === CHARGING_SPECIFICATION_OPTIONS.Voice.key"
                                    >
                                        <div
                                            v-tooltip="{
                                                content: `${entity.amount_used} s`,
                                                classes: ['dark'],
                                                placement: 'bottom',
                                                trigger: 'hover click',
                                                autoHide: false,
                                            }"
                                            class="pb-2 w-100 truncate-text"
                                        >
                                            {{ entity.amountUsedFormatted }}
                                        </div>
                                    </div>
                                    <div v-else-if="entity.serviceTypeFirst === CHARGING_SPECIFICATION_OPTIONS.SMS.key">
                                        {{ `${entity.amount_used} ${$i18n.t('generic.smss')}` }}
                                    </div>
                                    <div v-else-if="entity.serviceTypeFirst === CHARGING_SPECIFICATION_OPTIONS.MMS.key">
                                        {{ `${entity.amount_used} ${$i18n.t('generic.mmss')}` }}
                                    </div>
                                    <div v-else-if="entity.serviceTypeFirst === CHARGING_SPECIFICATION_OPTIONS.API.key">
                                        {{ `${entity.amount_used} ${$i18n.t('generic.apis')}` }}
                                    </div>
                                </template>

                                <template #chargeText="{ entity }">
                                    <div
                                        v-if="!entity.isParent"
                                        v-tooltip="{
                                            content: getChargeText(entity),
                                            classes: ['dark'],
                                            placement: 'bottom',
                                            trigger: 'hover click',
                                            autoHide: false,
                                        }"
                                        class="truncate-text"
                                    >
                                        {{ getChargeText(entity) }}
                                    </div>
                                </template>

                                <template #state="{ entity }">
                                    <span v-if="!isUndefined(entity.is_active)">
                                        {{ getStateAsString(entity.is_active) }}
                                    </span>

                                    <i v-else-if="!entity.isParent">{{ $i18n.t('generic.empty') }}</i>
                                </template>
                            </AppTable>
                        </template>
                    </TableFiltersRenderless>
                </div>
            </template>
        </AppTabsV2>
    </div>
</template>

<script>
// COMPONENTS
import AppTabsV2 from '@/components/partials/AppTabsV2.vue';
import AppProgressBar from '@/components/partials/AppProgressBar.vue';
import AppToggle from '@/components/partials/inputs/AppToggle.vue';
import SearchBox from '@/components/partials/inputs/SearchBox.vue';
import AppTable from '@/components/partials/AppTable.vue';
import TableFiltersRenderless from '@/components/filters/TableFiltersRenderless.vue';
import TableFiltersTags from '@/components/filters/TableFiltersTags.vue';
import FilterTable from '@/components/partials/FilterTable.vue';
import IconButton from '@/components/partials/IconButton.vue';
import { ICON_TYPES } from '@/common/iconHelper';
import ResponseModalButton from '@/components/partials/ResponseModalButton.vue';

// HELPERS
import Fuse from 'fuse.js';
import { isEmpty, isUndefined } from 'lodash';
import { convertSecondsToMinutes, getDefaultUnitNameByServiceType } from '@/common/formatting';
import {
    getChargeText,
    getStateAsString,
    getChargingSpecificationTypeAsString,
} from '@/__new__/services/dno/charging/common/chargingSpecificationHelper';
import { CHARGING_SPECIFICATION_OPTIONS } from '@/common/chargingSpecification';
import { BUCKET_TYPE } from '@/__new__/features/customerCare/common/balanceHelper';
import tableColumnType from '@/common/filterTable';

const balancesTab = {
    nonMonetary: {
        key: 'nonMonetaryTableFilterWrapper',
        id: 1,
    },
    unlimited: {
        key: 'unlimitedTableFilterWrapper',
        id: 2,
    },
    monetary: {
        key: 'monetaryTableFilterWrapper',
        id: 3,
    },
};

export default {
    name: 'BalanceOverview',
    components: {
        AppTabsV2,
        AppProgressBar,
        AppToggle,
        SearchBox,
        AppTable,
        TableFiltersRenderless,
        TableFiltersTags,
        FilterTable,
        IconButton,
        ResponseModalButton,
    },
    props: {
        allMappedBalances: {
            required: true,
            type: Object,
        },
        activeMappedBalances: {
            required: true,
            type: Object,
        },
        apiResponse: {
            type: Object,
            default: null,
        },
    },
    data() {
        return {
            allFilters: [],
            selectedTabIndex: 1,
            CHARGING_SPECIFICATION_OPTIONS,
            showOnlyActiveBalances: true,
            balanceSearchQuery: '',
            searchOptions: {
                keys: ['product_name', 'name'],
                threshold: 0.1,
                ignoreLocation: true,
            },
            ICON_TYPES,
        };
    },
    computed: {
        nonMonetaryBalancesColumsData() {
            return [
                {
                    name: this.$i18n.t('productCatalog.entities.plural.products'),
                    key: 'name',
                },
                {
                    name: this.$i18n.t('generic.chargingSpecificationTypes.voice'),
                    key: 'voiceSlot',
                },
                {
                    name: this.$i18n.t('generic.chargingSpecificationTypes.data'),
                    key: 'dataSlot',
                },
                {
                    name: this.$i18n.t('generic.chargingSpecificationTypes.sms'),
                    key: 'smsSlot',
                },
                {
                    name: this.$i18n.t('generic.chargingSpecificationTypes.mms'),
                    key: 'mmsSlot',
                },
                {
                    name: this.$i18n.t('generic.chargingSpecificationTypes.api'),
                    key: 'apiSlot',
                },
                {
                    name: this.$i18n.t('generic.state'),
                    key: 'state',
                },
            ];
        },
        monetaryBalancesColumsData() {
            return [
                {
                    name: this.$i18n.t('productCatalog.entities.plural.products'),
                    key: 'name',
                    field: 'name',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('productCatalog.services.serviceType'),
                    key: 'serviceType',
                    field: 'serviceTypeFormatted',
                    filterType: tableColumnType.TEXT_LIMITED_OPTIONS,
                    // eslint-disable-next-line max-len
                    limitedOptions: [
                        ...new Set(
                            this.monetaryBalancesToDisplayForTable
                                .map(entity => entity.serviceTypeFormatted)
                                .filter(Boolean),
                        ),
                    ],
                },
                {
                    name: this.$i18n.t('generic.spent'),
                    key: 'amountUsedFormatted',
                    field: 'amountUsedFormatted',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('generic.rate'),
                    key: 'chargeText',
                    field: 'chargeTextFormatted',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('generic.expiryDate'),
                    key: 'expiryDate',
                    field: 'end_time',
                    sortBy: entity =>
                        entity.is_active && (entity.is_non_expiring || entity.will_recur) ? Infinity : entity.end_time,
                    filterType: tableColumnType.DATE,
                },
                {
                    name: this.$i18n.t('generic.state'),
                    key: 'state',
                    field: 'stateFormatted',
                    filterType: tableColumnType.TEXT_LIMITED_OPTIONS,
                    // eslint-disable-next-line max-len
                    limitedOptions: [
                        ...new Set(
                            this.monetaryBalancesToDisplayForTable.map(entity => entity.stateFormatted).filter(Boolean),
                        ),
                    ],
                },
            ];
        },
        unlimitedBalancesColumsData() {
            return [
                {
                    name: this.$i18n.t('productCatalog.entities.plural.products'),
                    key: 'name',
                    field: 'name',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('productCatalog.services.serviceType'),
                    key: 'serviceType',
                    field: 'serviceTypeFormatted',
                    filterType: tableColumnType.TEXT_LIMITED_OPTIONS,
                    // eslint-disable-next-line max-len
                    limitedOptions: [
                        ...new Set(
                            this.unlimitedBalancesToDisplayForTable
                                .map(entity => entity.serviceTypeFormatted)
                                .filter(Boolean),
                        ),
                    ],
                },
                {
                    name: this.$i18n.t('generic.used'),
                    key: 'amountUsed',
                    field: 'amount_used',
                    filterType: tableColumnType.NUMBER,
                },
                {
                    name: this.$i18n.t('customerCare.transactionHistory.bucketID'),
                    key: 'bucketIdSlot',
                    field: 'bucketId',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('generic.expiryDate'),
                    key: 'expiryDate',
                    field: 'end_time',
                    sortBy: entity =>
                        entity.is_active && (entity.is_non_expiring || entity.will_recur) ? Infinity : entity.end_time,
                    filterType: tableColumnType.DATE,
                },
                {
                    name: this.$i18n.t('generic.state'),
                    key: 'state',
                    field: 'stateFormatted',
                    filterType: tableColumnType.TEXT_LIMITED_OPTIONS,
                    // eslint-disable-next-line max-len
                    limitedOptions: [
                        ...new Set(
                            this.unlimitedBalancesToDisplayForTable
                                .map(entity => entity.stateFormatted)
                                .filter(Boolean),
                        ),
                    ],
                },
            ];
        },
        areBalancesEmpty() {
            return isEmpty(this.allMappedBalances) && isEmpty(this.activeMappedBalances);
        },
        nonMonetaryBalancesToDisplayForTable() {
            if (!this.areBalancesEmpty) {
                const values = this.showOnlyActiveBalances
                    ? Object.values(this.activeMappedBalances[BUCKET_TYPE.NON_MONETARY])
                    : Object.values(this.allMappedBalances[BUCKET_TYPE.NON_MONETARY]);

                const nonMonetaryBalances = this.getHierarchyForTable(this.searchEntities(values));
                return nonMonetaryBalances.map(el => {
                    if (el.isParent) {
                        return el;
                    }
                    return {
                        ...el,
                        stateFormatted: el.is_active ? getStateAsString(el.is_active) : this.$i18n.t('generic.empty'),
                    };
                });
            }
            return [];
        },
        monetaryBalancesToDisplayForTable() {
            if (!this.areBalancesEmpty) {
                const values = this.showOnlyActiveBalances
                    ? Object.values(this.activeMappedBalances[BUCKET_TYPE.MONETARY])
                    : Object.values(this.allMappedBalances[BUCKET_TYPE.MONETARY]);

                const monetaryBalances = this.getHierarchyForTable(this.searchEntities(values));
                return monetaryBalances.map(el => {
                    if (el.isParent) {
                        return el;
                    }
                    return {
                        ...el,
                        serviceTypeFormatted: el.serviceTypeFirst
                            ? getChargingSpecificationTypeAsString(el.serviceTypeFirst)
                            : '',
                        stateFormatted: el.is_active ? getStateAsString(el.is_active) : this.$i18n.t('generic.empty'),
                        chargeTextFormatted: getChargeText(el),
                    };
                });
            }
            return [];
        },
        unlimitedBalancesToDisplayForTable() {
            if (!this.areBalancesEmpty) {
                const values = this.showOnlyActiveBalances
                    ? Object.values(this.activeMappedBalances[BUCKET_TYPE.UNLIMITED])
                    : Object.values(this.allMappedBalances[BUCKET_TYPE.UNLIMITED]);
                const unlimitedBalances = this.getHierarchyForTable(this.searchEntities(values));
                return unlimitedBalances.map(el => {
                    if (el.isParent) {
                        return el;
                    }
                    return {
                        ...el,
                        serviceTypeFormatted: el.serviceTypeFirst
                            ? getChargingSpecificationTypeAsString(el.serviceTypeFirst)
                            : '',
                        stateFormatted: el.is_active ? getStateAsString(el.is_active) : this.$i18n.t('generic.empty'),
                    };
                });
            }
            return [];
        },
        getColumnDataForFilter() {
            if (this.selectedTabIndex === balancesTab.unlimited.id) {
                return this.unlimitedBalancesColumsData;
            }
            if (this.selectedTabIndex === balancesTab.monetary.id) {
                return this.monetaryBalancesColumsData;
            }
            return [
                {
                    name: this.$i18n.t('productCatalog.entities.plural.products'),
                    key: 'name',
                    field: 'name',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('generic.state'),
                    key: 'state',
                    field: 'stateFormatted',
                    filterType: tableColumnType.TEXT_LIMITED_OPTIONS,
                    // eslint-disable-next-line max-len
                    limitedOptions: [
                        ...new Set(
                            this.nonMonetaryBalancesToDisplayForTable
                                .map(entity => entity.stateFormatted)
                                .filter(Boolean),
                        ),
                    ],
                },
            ];
        },
        getSelectedTabKey() {
            if (this.selectedTabIndex === balancesTab.unlimited.id) {
                return balancesTab.unlimited.key;
            }
            if (this.selectedTabIndex === balancesTab.monetary.id) {
                return balancesTab.monetary.key;
            }
            return balancesTab.nonMonetary.key;
        },
    },
    methods: {
        getDefaultUnitNameByServiceType,
        getChargingSpecificationTypeAsString,
        getStateAsString,
        isUndefined,
        scaleBytes(bytes) {
            return Math.floor(bytes / (1024 * 1024));
        },
        convertSecondsToMinutes,
        getChargeText,
        isServiceTypeData(cs) {
            return cs.serviceTypeFirst === this.CHARGING_SPECIFICATION_OPTIONS.Data.key;
        },
        searchEntities(values) {
            if (this.balanceSearchQuery) {
                const valueFormatted = [];

                values.forEach(value => {
                    const fuse = new Fuse(value, this.searchOptions);
                    const searchValues = fuse.search(this.balanceSearchQuery).map(e => e.item);

                    if (searchValues.length) {
                        valueFormatted.push(searchValues);
                    }
                });

                return valueFormatted;
            }

            return values;
        },
        isBalancesExist(balances) {
            return balances && Object.keys(balances).length > 0;
        },
        getHierarchyForTable(values) {
            return values.reduce((acc, val) => {
                if (val[0].product_name) {
                    acc.push({
                        name: val[0].product_name,
                        isParent: true,
                    });
                }
                acc.push(...val);

                return acc;
            }, []);
        },
        changeSelectedIndex(index) {
            this.removeAllFilters();
            this.selectedTabIndex = index;
        },
        onFilterAdd(val) {
            this.$refs[this.getSelectedTabKey].onFilterAdded(val);
        },
        computedFilterValue(val) {
            return this.$refs[this.getSelectedTabKey].computedFilterValue(val);
        },
        removeAllFilters() {
            this.$refs[this.getSelectedTabKey].removeAllFilters();
        },
        removeFilter(val) {
            this.$refs[this.getSelectedTabKey].removeFilter(val);
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/palette';
@import '~@/assets/scss/typography';

.balances-table {
    .product-row {
        font-size: 0.875rem;
        padding: 0.75rem 1.5rem;
        background: $white;
    }

    .balance-table-header {
        font-size: 0.75rem;
        font-weight: 600;
        text-transform: uppercase;
        color: $gray-blue;
        padding: 0.1875rem 0 0.1875rem 1.5rem;
        background: $white;
        border: none;
        border-bottom: solid 0.0625rem $gray5;
    }

    .service {
        max-height: 1.5rem;
    }

    .no-balances {
        padding: 1rem;
        font-size: 1rem;
        justify-content: center;
        background: $white;
        border-bottom: solid 0.0625rem $gray5;
    }
}

.search-box-wrapper {
    position: relative;
    width: 15rem;
    height: 2.5rem;
}

.table-search-box {
    width: 15rem;
}
</style>
