import { AxiosPromise } from 'axios';
import http from '@/http/index';
import type {
    AddTemplatePayload,
    DocumentTemplatesApiResponse,
    GetDocumentTemplateResponse,
} from '@/__new__/services/dno/documents/models/DocumentInterfaces';

/**
 * Get templates.
 *
 * @returns {AxiosPromise}
 */
export function getTemplates(): AxiosPromise<DocumentTemplatesApiResponse<string[]>> {
    return http({
        method: 'GET',
        url: `/v3documents/gettemplates`,
    });
}

/**
 * Get template.
 *
 * @returns {AxiosPromise}
 */
export function getTemplate(templateId: string): AxiosPromise<GetDocumentTemplateResponse> {
    return http({
        method: 'GET',
        url: `/v3documents/gettemplate?template_id=${templateId}`,
    });
}

/**
 * Add template.
 *
 * @returns {AxiosPromise}
 */
export function addTemplate({
    templateId,
    templateDefinition,
    previewPayload,
    requestForm,
    version,
}: AddTemplatePayload): AxiosPromise<any> {
    return http({
        method: 'PUT',
        url: `/v3documents/addtemplate?template_id=${templateId}`,
        data: {
            template_definition: templateDefinition,
            properties: {
                preview_payload: previewPayload,
                request_form: requestForm,
            },
            version,
        },
    });
}

export default {
    getTemplates,
    getTemplate,
    addTemplate,
};
