import Zones, { csConditionZoneOptions } from '@/__new__/services/dno/charging/common/zone/zones';
import { parseZonesToMap } from '@/__new__/services/dno/charging/common/zone/zoneHelper';
import {
    PC_CONDITION_TYPES,
    CONDITION_TYPES,
} from '@/__new__/services/dno/charging/common/chargingSpecificationHelper';
import {
    ZONE_TYPE_TO_ENUM,
    ZONE_CODE_TYPES,
} from '@/__new__/features/charging/conditionParameters/conditions/SubscriberLocationCondition.vue';
import { preparePayloadBasedOnOptions } from '@/__new__/services/dno/charging/common/customConditionHelper';

function parseBearer(c) {
    return {
        [c.uuid]: {
            type: PC_CONDITION_TYPES.BEARER,
            values: c.data,
        },
    };
}

/**
 * This function accepts list of countries and networks selected and zone map.
 * It goes over every country and add it to list. Then it goes over every network and double checks
 * if it exist in that country and if it does it adds all its mnc to the list.
 * This assumes that user cannot select networks for more then one country.
 * If there are more countries selected we just provide empty array for networks.
 * @param countries
 * @param networks
 * @param zones
 */
function mapMccMnc(countries, networks, zones) {
    const res = {};

    countries.forEach(c => {
        res[zones[c.iso].mcc] = [];
        if (countries.length === 1) {
            if (networks.length) {
                networks.forEach(n => {
                    // Double checking if network belongs to operator so someone does not screw us over.
                    if (n.iso === c.iso) {
                        zones[c.iso].networks[n.network].mncs.forEach(mnc => res[zones[c.iso].mcc].push(mnc));
                    }
                });
            }
        }
    });

    return res;
}

/**
 * Generate list of mnc and mcc mappings for selected countries and operators.
 * How this works is it first checks if we have any country selected. If we do not have any country
 * selected for source or destination that field will have an empty object for its value.
 * If we have some country we will go through and parse country and operators names and map them
 * to mcc -> mnc mappings.
 * @param c
 * @returns {{}}
 */
function parseZone(c) {
    let src = {};
    let dst = {};

    // Caching it here to not call it two times.
    const zonesMap = parseZonesToMap(Zones);

    if (Array.isArray(c.data.srcCountries) && c.data.srcCountries.length) {
        src = mapMccMnc(c.data.srcCountries, c.data.srcNetworks, zonesMap);

        src.inverted = c.data.srcInverted.id === csConditionZoneOptions.isNot.id;
    }

    if (Array.isArray(c.data.dstCountries) && c.data.dstCountries.length) {
        dst = mapMccMnc(c.data.dstCountries, c.data.dstNetworks, zonesMap);

        dst.inverted = c.data.dstInverted.id === csConditionZoneOptions.isNot.id;
    }

    return {
        [c.uuid]: {
            type: PC_CONDITION_TYPES.ZONE,
            src,
            dst,
        },
    };
}

function parseSpecialNumber(c) {
    return {
        [c.uuid]: {
            type: PC_CONDITION_TYPES.SPECIAL_NUMBER,
            values: c.data,
        },
    };
}

function parseSchedule(c) {
    const schedule = {
        1: [],
        2: [],
        3: [],
        4: [],
        5: [],
        6: [],
        7: [],
    };
    Object.values(c.data.days).forEach(day => {
        if (day.active) {
            schedule[day.apiKey] = c.data.timeSlots;
        }
    });
    return {
        [c.uuid]: {
            type: PC_CONDITION_TYPES.SCHEDULE,
            schedule,
        },
    };
}

function parseApn(c) {
    return {
        [c.uuid]: {
            type: PC_CONDITION_TYPES.APN,
            values: c.data,
        },
    };
}

function parseRatingGroup(c) {
    return {
        [c.uuid]: {
            type: PC_CONDITION_TYPES.RATING_GROUP,
            rating_group_numbers: c.data.map(el => el.number),
        },
    };
}

function parseTimeForTimeInCallCondition(c) {
    const hoursMinutesAndSecondsStart = c.data[0].start_offset.split(':');
    let hours = Number(hoursMinutesAndSecondsStart[0]);
    let minutes = Number(hoursMinutesAndSecondsStart[1]);
    let seconds = Number(hoursMinutesAndSecondsStart[2]);
    const start = hours * 3600 + minutes * 60 + seconds;
    const hoursMinutesAndSecondsEnd = c.data[0].end_offset.split(':');
    hours = Number(hoursMinutesAndSecondsEnd[0]);
    minutes = Number(hoursMinutesAndSecondsEnd[1]);
    seconds = Number(hoursMinutesAndSecondsEnd[2]);
    const end = hours * 3600 + minutes * 60 + seconds;
    return {
        [c.uuid]: {
            type: PC_CONDITION_TYPES.TIME_IN_CALL,
            start_offset: start,
            end_offset: end,
        },
    };
}

const parseNetworkSliceIdentifier = c => {
    const obj = {};
    const nsi = c.data;
    for (const i in nsi) {
        if (Object.prototype.hasOwnProperty.call(nsi, i)) {
            if (nsi[i].sst.length > 1) {
                for (const j in nsi[i].sst) {
                    if (Object.prototype.hasOwnProperty.call(nsi[i].sst, j)) {
                        obj[nsi[i].sst[j]] = [];
                    }
                }
            } else if (nsi[i].sst.length === 1) {
                obj[nsi[i].sst] = nsi[i].sd;
            }
        }
    }
    return {
        [c.uuid]: {
            type: PC_CONDITION_TYPES.NETWORK_SLICE_IDENTIFIER,
            values: obj,
        },
    };
};

function parseTrafficType(c) {
    return {
        [c.uuid]: {
            type: PC_CONDITION_TYPES.TRAFFIC_TYPE,
            values: c.data.map(el => el.value),
        },
    };
}

function parseOtherPartyRoutingNumberConditionData(c) {
    return {
        [c.uuid]: {
            type: PC_CONDITION_TYPES.OTHER_PARTY_RN,
            operator: c.data.operator,
            inverted: c.data.inverted,
            values: c.data.values,
        },
    };
}

function parseServiceType(c) {
    return {
        [c.uuid]: {
            type: PC_CONDITION_TYPES.SERVICE_TYPE,
            service_type: c?.data?.key ? c.data.key : null,
        },
    };
}

function parseFlag(c) {
    return {
        [c.uuid]: {
            type: c.type,
            inverted: c.data?.inverted?.id === csConditionZoneOptions.isNot.id,
            flag: c.data.value,
        },
    };
}

function parseOtherPartyNumberLengthType(c) {
    return {
        [c.uuid]: {
            type: PC_CONDITION_TYPES.OTHER_PARTY_NUMBER_LENGTH,
            operator: c.data.operator,
            value: c.data.value,
        },
    };
}

function parseSubscriberLocationType(c) {
    return {
        [c.uuid]: {
            type: PC_CONDITION_TYPES.SUBSCRIBER_LOCATION,
            inverted: c.data.inverted,
            zone_code_type: c.data.zoneCodeType,
            src: c.data.src,
        },
    };
}

function parseOtherPartyNumber(c) {
    return {
        [c.uuid]: {
            type: PC_CONDITION_TYPES.OTHER_PARTY_NUMBER,
            inverted: c.data?.inverted || false,
            zone_code_type: c.data?.zoneCodeType || ZONE_TYPE_TO_ENUM[ZONE_CODE_TYPES.CC_NDC],
            dst: c.data?.dst || {},
        },
    };
}

function parseVlrId(c) {
    return {
        [c.uuid]: {
            type: CONDITION_TYPES.VLR_ID,
            inverted: c.data.inverted,
            values: c.data.src.reduce((data, { vlrIdType, values }) => {
                data[vlrIdType.id] = values.map(el => el.toString());
                return data;
            }, {}),
        },
    };
}

function parseServiceId(c) {
    return {
        [c.uuid]: {
            type: CONDITION_TYPES.SERVICE_ID,
            inverted: c.data.inverted,
            values: c.data.src.reduce((data, { serviceIdType, values }) => {
                data[serviceIdType.id] = values.map(el => el.toString());
                return data;
            }, {}),
        },
    };
}

function parseBucketType(c) {
    return {
        [c.uuid]: {
            type: PC_CONDITION_TYPES.BUCKET_TYPE,
            bucket_type: c.data?.id,
        },
    };
}

function parseDataBalance(c) {
    return {
        [c.uuid]: {
            type: PC_CONDITION_TYPES.DATA_BALANCE,
            operator: c.data?.operator?.id,
            value: c.data?.value,
        },
    };
}

function parseCustomCondition(c) {
    return {
        [c.uuid]: preparePayloadBasedOnOptions(c.data, c.serviceType),
    };
}

export default function parseConditions(conditions) {
    const result = {};
    conditions
        .map(condition => {
            switch (condition.type) {
                case CONDITION_TYPES.BEARER:
                    return parseBearer(condition);
                case CONDITION_TYPES.ZONE:
                    return parseZone(condition);
                case CONDITION_TYPES.SPECIAL_NUMBER:
                    return parseSpecialNumber(condition);
                case CONDITION_TYPES.SCHEDULE:
                    return parseSchedule(condition);
                case CONDITION_TYPES.APN:
                    return parseApn(condition);
                case CONDITION_TYPES.RATING_GROUP:
                    return parseRatingGroup(condition);
                case CONDITION_TYPES.TRAFFIC_TYPE:
                    return parseTrafficType(condition);
                case CONDITION_TYPES.TIME_IN_CALL:
                    return parseTimeForTimeInCallCondition(condition);
                case CONDITION_TYPES.NETWORK_SLICE_IDENTIFIER:
                    return parseNetworkSliceIdentifier(condition);
                case CONDITION_TYPES.SERVICE_TYPE:
                    return parseServiceType(condition);
                case CONDITION_TYPES.OTHER_PARTY_RN:
                    return parseOtherPartyRoutingNumberConditionData(condition);
                case CONDITION_TYPES.SUBSCRIBER_FLAG:
                case CONDITION_TYPES.BUCKET_FLAG:
                    return parseFlag(condition);
                case CONDITION_TYPES.OTHER_PARTY_NUMBER_LENGTH:
                    return parseOtherPartyNumberLengthType(condition);
                case CONDITION_TYPES.SUBSCRIBER_LOCATION:
                    return parseSubscriberLocationType(condition);
                case CONDITION_TYPES.OTHER_PARTY_NUMBER:
                    return parseOtherPartyNumber(condition);
                case CONDITION_TYPES.VLR_ID:
                    return parseVlrId(condition);
                case CONDITION_TYPES.SERVICE_ID:
                    return parseServiceId(condition);
                case CONDITION_TYPES.BUCKET_TYPE:
                    return parseBucketType(condition);
                case CONDITION_TYPES.DATA_BALANCE:
                    return parseDataBalance(condition);
                case CONDITION_TYPES.CUSTOM_CONDITION:
                    return parseCustomCondition(condition);
                default:
                    return condition.data;
            }
        })
        .forEach(el => {
            Object.entries(el).forEach(([key, value]) => {
                result[key] = value;
            });
        });

    return result;
}
