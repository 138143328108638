<template>
    <div class="condition-value-container">
        <BearerValue
            v-if="value.type === PC_CONDITION_TYPES.BEARER"
            :value="value"
        />
        <NSIValue
            v-if="value.type === PC_CONDITION_TYPES.NETWORK_SLICE_IDENTIFIER"
            :value="value"
        />
        <ZoneValue
            v-if="value.type === PC_CONDITION_TYPES.ZONE"
            :value="value"
        />
        <SpecialNumberValue
            v-if="value.type === PC_CONDITION_TYPES.SPECIAL_NUMBER"
            :value="value"
        />
        <ScheduleValue
            v-if="value.type === PC_CONDITION_TYPES.SCHEDULE"
            :value="value"
        />
        <ApnValue
            v-if="value.type === PC_CONDITION_TYPES.APN"
            :value="value"
        />
        <RatingGroupValue
            v-if="value.type === PC_CONDITION_TYPES.RATING_GROUP"
            :value="value"
        />
        <TrafficTypeValue
            v-if="value.type === PC_CONDITION_TYPES.TRAFFIC_TYPE"
            :value="value"
        />
        <ServiceTypeValue
            v-if="value.type === PC_CONDITION_TYPES.SERVICE_TYPE"
            :value="value"
        />
        <FlagValue
            v-if="value.type === PC_CONDITION_TYPES.SUBSCRIBER_FLAG || value.type === PC_CONDITION_TYPES.BUCKET_FLAG"
            :value="value"
        />
        <OtherPartyRNValue
            v-if="value.type === PC_CONDITION_TYPES.OTHER_PARTY_RN"
            :value="value"
        />
        <SubscriberLocationValue
            v-if="value.type === PC_CONDITION_TYPES.SUBSCRIBER_LOCATION"
            :value="value"
        />
        <OtherPartyNumberValue
            v-if="value.type === PC_CONDITION_TYPES.OTHER_PARTY_NUMBER"
            :value="value"
        />
        <VlrIdValue
            v-if="value.type === PC_CONDITION_TYPES.VLR_ID"
            :value="value"
        />
        <ServiceIdValue
            v-if="value.type === PC_CONDITION_TYPES.SERVICE_ID"
            :value="value"
        />
        <BucketTypeValue
            v-if="value.type === PC_CONDITION_TYPES.BUCKET_TYPE"
            :value="value"
        />
        <DataBalanceValue
            v-if="value.type === PC_CONDITION_TYPES.DATA_BALANCE"
            :value="value"
        />
        <CustomConditionValue
            v-if="value.type === PC_CONDITION_TYPES.CUSTOM_CONDITION"
            :value="value"
        />
    </div>
</template>

<script>
import BearerValue from '@/__new__/features/charging/conditionParameters/conditionsValues/BearerValue.vue';
import ZoneValue from '@/__new__/features/charging/conditionParameters/conditionsValues/ZoneValue.vue';
import SpecialNumberValue from '@/__new__/features/charging/conditionParameters/conditionsValues/SpecialNumberValue.vue';
import ScheduleValue from '@/__new__/features/charging/conditionParameters/conditionsValues/ScheduleValue.vue';
import ApnValue from '@/__new__/features/charging/conditionParameters/conditionsValues/ApnValue.vue';
import RatingGroupValue from '@/__new__/features/charging/conditionParameters/conditionsValues/RatingGroupValue.vue';
import TrafficTypeValue from '@/__new__/features/charging/conditionParameters/conditionsValues/TrafficTypeValue.vue';
import NSIValue from '@/__new__/features/charging/conditionParameters/conditionsValues/NetworkSliceIdentifierValue.vue';
import ServiceTypeValue from '@/__new__/features/charging/conditionParameters/conditionsValues/ServiceTypeValue.vue';
import FlagValue from '@/__new__/features/charging/conditionParameters/conditionsValues/FlagValue.vue';
import OtherPartyRNValue from '@/__new__/features/charging/conditionParameters/conditionsValues/OtherPartyRNValue.vue';
import SubscriberLocationValue from '@/__new__/features/charging/conditionParameters/conditionsValues/SubscriberLocationValue.vue';
import OtherPartyNumberValue from '@/__new__/features/charging/conditionParameters/conditionsValues/OtherPartyNumberValue.vue';
import VlrIdValue from '@/__new__/features/charging/conditionParameters/conditionsValues/VlrIdValue.vue';
import ServiceIdValue from '@/__new__/features/charging/conditionParameters/conditionsValues/ServiceIdValue.vue';
import BucketTypeValue from '@/__new__/features/charging/conditionParameters/conditionsValues/BucketTypeValue.vue';
import DataBalanceValue from '@/__new__/features/charging/conditionParameters/conditionsValues/DataBalanceValue.vue';
import CustomConditionValue from '@/__new__/features/charging/conditionParameters/conditionsValues/CustomConditionValue.vue';

import { PC_CONDITION_TYPES } from '@/__new__/services/dno/charging/common/chargingSpecificationHelper';

export default {
    name: 'ConditionValue',
    components: {
        BearerValue,
        ZoneValue,
        SpecialNumberValue,
        ScheduleValue,
        ApnValue,
        RatingGroupValue,
        TrafficTypeValue,
        NSIValue,
        ServiceTypeValue,
        FlagValue,
        OtherPartyRNValue,
        SubscriberLocationValue,
        OtherPartyNumberValue,
        VlrIdValue,
        ServiceIdValue,
        BucketTypeValue,
        DataBalanceValue,
        CustomConditionValue,
    },
    props: {
        value: {
            required: true,
            type: Object,
        },
    },
    data() {
        return {
            PC_CONDITION_TYPES,
        };
    },
};
</script>

<style></style>
