import { render, staticRenderFns } from "./AppTextareaV3.vue?vue&type=template&id=fc3f7b78&scoped=true"
import script from "./AppTextareaV3.vue?vue&type=script&lang=js"
export * from "./AppTextareaV3.vue?vue&type=script&lang=js"
import style0 from "./AppTextareaV3.vue?vue&type=style&index=0&id=fc3f7b78&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fc3f7b78",
  null
  
)

export default component.exports