<template>
    <AbstractEditPageWrapper>
        <template #header>
            <AppHeader :pageTitle="pageTitle" />
        </template>

        <template #content>
            <div class="d-flex lf-subtitle">
                <div>
                    {{ $i18n.t('generic.general') }}
                </div>
            </div>
            <AppInputV3
                id="template-name-input"
                v-model="name"
                :placeholder="$i18n.t('generic.name')"
                :label="$i18n.t('generic.name')"
                :disabled="readonly"
                :invalid="showValidation && !name"
                class="editor-input-largest mb-3"
                @input="hideValidation"
            />
            <AppInputV3
                v-model="externalId.value"
                :placeholder="$i18n.t('generic.externalId')"
                :label="$i18n.t('generic.externalId')"
                :explanationText="$i18n.t('productCatalog.offers.editor.externalIdInformation')"
                :tooltipOffset="20"
                :disabled="readonly"
                :invalid="externalId.invalid || externalId.showError"
                :errorMessage="
                    externalId.invalid ? $i18n.t('alertMessage.pc.externalIdWithUppercaseSupportInvalid') : ''
                "
                class="editor-input-largest mb-3"
                data-test-id="template-externalid"
                @input="cleanExternalIdError"
            />
            <AppTextareaV3
                id="template-description-input"
                v-model="description"
                :placeholder="$i18n.t('generic.addDescription')"
                :label="$i18n.t('generic.description')"
                :resizeNone="true"
                :disabled="readonly"
                class="editor-input-largest mb-3"
            />
            <AppMultiselectV3
                v-if="!isPresetEditor && isUserAllowed(writePermission)"
                v-model="entityType"
                :verified="inEditMode"
                :disabled="inEditMode || readonly"
                :small="true"
                :options="entityTypesOptions"
                :additionalLabel="$i18n.t('productCatalog.entityType')"
                :placeholder="$i18n.t('productCatalog.entityType')"
                :error="showValidation && !entityType"
                :errorMessage="$i18n.t('productCatalog.templates.alerts.pleaseSelectAnEntityType')"
                label="label"
                trackBy="key"
                optionId="key"
                data-test-id="entity-type-select"
                class="editor-input-largest mb-3"
            />

            <div class="section-title">
                {{ propertiesName }}
            </div>
            <GroupGenerator
                :groups="formattedDataForFormBuilder"
                :showValidation="false"
                :disabled="readonly"
                class="w-60 mb-5"
            >
                <template #header="{ group }">
                    <AppTextUnderline
                        :text="group.name"
                        class="mb-3"
                    />
                </template>

                <template #divider>
                    <div class="mb-3" />
                </template>

                <template #dividerGroup>
                    <div class="mb-5" />
                </template>
            </GroupGenerator>
        </template>

        <template #sideBar>
            <div class="pt-3 pl-3">
                <p
                    v-t="'productCatalog.templates.groupAndReorder'"
                    class="lf-subtitle"
                />
                <p
                    v-t="'productCatalog.templates.groupAndReorderText'"
                    class="lf-secondary-text"
                />
            </div>
            <div class="sidebar-dnd pl-2 mb-2">
                <Draggable
                    :value="formattedGroupsForDnd"
                    handle=".group-dnd-handle"
                    @input="changeFormattedGroupsForDnd"
                >
                    <div
                        v-for="group in formattedGroupsForDnd"
                        :key="group.id"
                    >
                        <div
                            class="dnd-group-wrapper"
                            :class="{ 'dnd-group-wrapper-light': group.type === GROUP_TYPES.PRESET_TEMPLATE }"
                        >
                            <div class="dnd-item-left-part">
                                <div class="group-dnd-handle" />
                                {{ group.name }}
                            </div>
                            <!-- Group Icons -->
                            <div
                                v-if="readonly"
                                class="dnd-item-right-part"
                            >
                                <IconButton
                                    :icon="ICON_TYPES.INFO"
                                    :label="$i18n.t('productCatalog.templates.viewGroup')"
                                    data-test-id="group-dnd-item-edit-group-button"
                                    class="mr-1"
                                    @iconClick="onEditGroup(group.id)"
                                />
                            </div>
                            <div
                                v-else
                                class="dnd-item-right-part"
                            >
                                <IconButton
                                    v-if="group.type === GROUP_TYPES.CUSTOM"
                                    :icon="ICON_TYPES.PLUS"
                                    :label="$i18n.t('productCatalog.templates.addProperty')"
                                    data-test-id="group-dnd-item-add-property-button"
                                    class="mr-1"
                                    @iconClick="[cleanPropertyModalData(null), onAddProperty(group.id)]"
                                />
                                <IconButton
                                    v-if="isTemplateBulkEditEnabled || !isTemplateReferenced || group.isGroupNotSaved"
                                    v-show="formattedGroupsForDnd.length > 1"
                                    :icon="ICON_TYPES.DELETE"
                                    :label="$i18n.t('productCatalog.templates.deleteGroup')"
                                    data-test-id="group-dnd-item-delete-group-button"
                                    class="mr-1"
                                    @iconClick="onDeleteGroup(group)"
                                />
                                <IconButton
                                    :icon="ICON_TYPES.EDIT"
                                    :label="$i18n.t('productCatalog.templates.editGroup')"
                                    data-test-id="group-dnd-item-edit-group-button"
                                    class="mr-1"
                                    @iconClick="onEditGroup(group.id)"
                                />
                            </div>
                        </div>
                        <Draggable
                            :list="group.fields"
                            :group="{ name: 'group' }"
                            handle=".property-dnd-handle"
                            class="dnd-properties-wrapper"
                        >
                            <div
                                v-for="property in group.fields"
                                :key="property.id"
                                class="dnd-property-wrapper"
                            >
                                <div class="dnd-item-left-part">
                                    <div class="property-dnd-handle" />
                                    <div>
                                        {{ property.name }}
                                    </div>
                                </div>
                                <!-- Property Icons -->
                                <div
                                    v-if="readonly"
                                    class="dnd-item-right-part"
                                >
                                    <IconButton
                                        :icon="ICON_TYPES.INFO"
                                        :label="$i18n.t('productCatalog.templates.viewProperty')"
                                        data-test-id="property-dnd-edit-button"
                                        class="mr-1"
                                        @iconClick="onEditProperty(property.id)"
                                    />
                                </div>
                                <div
                                    v-else
                                    class="dnd-item-right-part"
                                >
                                    <IconButton
                                        v-if="
                                            isTemplateBulkEditEnabled ||
                                            !isTemplateReferenced ||
                                            property.isPropNotSaved
                                        "
                                        :icon="ICON_TYPES.DELETE"
                                        :label="$i18n.t('productCatalog.templates.deleteProperty')"
                                        data-test-id="property-dnd-delete-button"
                                        class="mr-1"
                                        @iconClick="showDeletePropertyAlert(property.id, group.id)"
                                    />
                                    <IconButton
                                        :icon="ICON_TYPES.EDIT"
                                        :label="$i18n.t('productCatalog.templates.editProperty')"
                                        data-test-id="property-dnd-edit-button"
                                        class="mr-1"
                                        @iconClick="onEditProperty(property.id)"
                                    />
                                </div>
                            </div>
                        </Draggable>
                    </div>
                </Draggable>
            </div>
            <div class="pl-3">
                <AppButton
                    :label="$i18n.t('productCatalog.templates.addProperty')"
                    :iconType="ICON_TYPES.PLUS"
                    :disabled="readonly"
                    data-test-id="add-property-button"
                    class="mr-5"
                    @click="[cleanPropertyModalData(null), onAddProperty()]"
                />
                <AppButton
                    :label="$i18n.t(addGroupTitleKey)"
                    :iconType="ICON_TYPES.PLUS"
                    :disabled="readonly"
                    data-test-id="add-group-button"
                    class="mr-5"
                    @click="[showGroupModal(), cleanGroupModalData(null)]"
                />
            </div>
        </template>

        <template #controls>
            <EditorButtons
                :showSave="true"
                :disableSave="readonly"
                @cancel="onCancel"
                @save="onSave"
            />
        </template>

        <template #modal>
            <AppDialogV2
                :visible="isModalVisible"
                :disableDefaultSaveBtn="true"
                :title="currentModalTitle"
                class="dialog"
                @close="onCloseModal()"
            >
                <div class="property-editor-modal-wrapper">
                    <template v-if="modalType === modalTypes.PROPERTY">
                        <AppMultiselectV3
                            v-model="propertyModal.group"
                            :small="true"
                            :options="filteredGroupsForAddProperty"
                            :error="propertyModal.groupInvalid"
                            :additionalLabel="$i18n.t('generic.group')"
                            :placeholder="$i18n.t('productCatalog.templates.chooseGroup')"
                            :disabled="readonly"
                            data-test-id="modal-property-name-input"
                            label="name"
                            trackBy="id"
                            class="editor-input-largest mb-3"
                            @input="hidePropertyGroupValidation"
                        />
                        <AppInputV3
                            v-model="propertyModal.externalId"
                            :label="$i18n.t('generic.externalId')"
                            :explanationText="$i18n.t('productCatalog.offers.editor.externalIdInformation')"
                            :tooltipOffset="20"
                            :invalid="propertyModal.isExternalIdEmpty || propertyModal.externalIdInvalid"
                            :errorMessage="externalIdErrorMessage"
                            :placeholder="$i18n.t('generic.externalId')"
                            :verified="isExternalIdVerified && !isTemplateBulkEditEnabled"
                            :disabled="(isExternalIdVerified && !isTemplateBulkEditEnabled) || readonly"
                            data-test-id="propertyExternalid"
                            class="editor-input-largest mt-3"
                            @input="cleanExternalIdError()"
                        />
                        <AppMultiselectV3
                            v-model="propertyModal.dataType"
                            :small="true"
                            :options="availablePropertyTypes"
                            :additionalLabel="$i18n.t('generic.type')"
                            :placeholder="$i18n.t('productCatalog.templates.addPropertyType')"
                            :error="propertyModal.typeInvalid"
                            data-test-id="modal-property-data-type-select"
                            :verified="isDataTypeVerified"
                            :disabled="isDataTypeVerified || readonly"
                            :allowEmpty="false"
                            label="label"
                            trackBy="key"
                            optionId="key"
                            class="editor-input-largest my-3"
                            @input="val => [addMutationFieldsEnums(val), hidePropertyTypeValidation()]"
                        />
                        <PropsFormBuilder
                            :value="propertyModal.data"
                            :type="propertyType"
                            :showValidation="propertyModal.showValidation"
                            :mutationFields="propertyModal.mutationFields"
                            :disabled="readonly"
                            class="mb-3"
                            @input="val => [hidePropertyValidation(), addMutationFields(val)]"
                        >
                            <template #divider>
                                <div class="mb-3" />
                            </template>
                        </PropsFormBuilder>
                        <template v-if="isDropdownType(propertyModal.dataType)">
                            <ValueInputs
                                v-model="propertyModal.enum"
                                :initInputValues="propertyModal.enum"
                                :label="$i18n.tc('productCatalog.entities.enums', PLURALIZATION.PLURAL)"
                                :addButtonLabel="$i18n.t('productCatalog.enums.addEnum')"
                                :valuePlaceholder="$i18n.t('productCatalog.enums.addEnumValue')"
                                :keyPlaceholder="$i18n.t('productCatalog.enums.addEnumKey')"
                                :customDataStructureOnAdd="{ key: '', value: '' }"
                                :isObjectValues="true"
                                :showInputErrors="true"
                                :initInvalidIndices="propertyInvalidIndices"
                                :explanation="$i18n.t('productCatalog.enums.explanation')"
                                :disabled="readonly"
                                class="mb-3"
                            />
                        </template>
                        <template v-if="isReferenceType(propertyModal.dataType)">
                            <AppMultiselectV3
                                v-model="propertyModal.referencedEntityType"
                                :small="true"
                                :options="REFERENCED_ENTITY_TYPES"
                                :additionalLabel="$i18n.t('productCatalog.referencedEntityType')"
                                :disabled="readonly"
                                label="label"
                                trackBy="id"
                                class="editor-input-largest mb-3"
                            />
                        </template>
                        <AppToggle
                            v-model="propertyModal.isRequired"
                            v-tooltip="{
                                content:
                                    isTemplateReferenced && !isTemplateBulkEditEnabled
                                        ? $i18n.t('productCatalog.templates.disabledSinceTemplateReferenced')
                                        : '',
                                placement: 'top',
                                trigger: 'hover',
                                autoHide: true,
                            }"
                            :fitContent="true"
                            :disabled="(isTemplateReferenced && !isTemplateBulkEditEnabled) || readonly"
                            :label="$i18n.t('generic.mandatory')"
                            :small="true"
                            class="mb-3"
                        />
                        <!-- special handling of the default value for the dropdown types -->
                        <template v-if="isDropdownType(propertyModal.dataType) && propertyModal.isRequired">
                            <AppMultiselectV3
                                v-model="propertyModal.defaultValue"
                                :options="dropdownDefaultValueOptions"
                                :small="true"
                                :additionalLabel="$i18n.t('generic.defaultValue')"
                                :multiple="propertyModal.dataType === FORM_BUILDER_TYPES.DROPDOWN_MULTIPLE"
                                :allowEmpty="false"
                                :disabled="readonly"
                            />
                        </template>
                    </template>
                    <template v-if="modalType === modalTypes.GROUP">
                        <div
                            v-if="!isPresetEditor"
                            class="d-flex mb-3"
                        >
                            <AppRadioButton
                                v-model="groupModal.type"
                                :inputValue="GROUP_TYPES.CUSTOM"
                                name="group"
                                :labelRight="$i18n.t('productCatalog.templates.customGroup')"
                                :disabled="readonly"
                                class="mr-2"
                            />
                            <AppRadioButton
                                v-model="groupModal.type"
                                :inputValue="GROUP_TYPES.PRESET_TEMPLATE"
                                name="group"
                                :labelRight="$i18n.tc('productCatalog.presetTemplates.presetTemplates')"
                                :disabled="readonly"
                            />
                        </div>
                        <template v-if="groupModal.type === GROUP_TYPES.CUSTOM">
                            <AppInputV3
                                id="modal-group-name-input"
                                v-model="groupModal.name"
                                :placeholder="$i18n.t('productCatalog.templates.groupName')"
                                :label="$i18n.t('generic.name')"
                                :invalid="groupModal.groupInvalid"
                                :disabled="readonly"
                                class="editor-input-largest mb-3"
                                @input="hideGroupNameValidation"
                            />
                            <AppTextareaV3
                                id="modal-group-description-input"
                                v-model="groupModal.description"
                                :placeholder="$i18n.t('generic.addDescription')"
                                :label="$i18n.t('generic.description')"
                                :resizeNone="true"
                                :disabled="readonly"
                                class="editor-input-largest mb-3"
                            />
                        </template>
                        <div
                            v-else
                            style="min-height: 20rem"
                        >
                            <AppMultiselectV3
                                v-model="groupModal.preset"
                                v-tooltip="{
                                    content:
                                        isTemplateReferenced && !isTemplateBulkEditEnabled
                                            ? $i18n.t('productCatalog.templates.disabledSinceTemplateReferenced')
                                            : '',
                                    placement: 'top',
                                    trigger: 'hover',
                                    autoHide: true,
                                }"
                                :error="groupModal.groupPresetInvalid"
                                :options="presetTemplatesOptions"
                                :small="true"
                                :additionalLabel="$i18n.tc('productCatalog.presetTemplates.presetTemplates')"
                                :placeholder="$i18n.tc('productCatalog.presetTemplates.managePresetTemplates')"
                                :disabled="(isTemplateReferenced && !isTemplateBulkEditEnabled) || readonly"
                                data-test-id="preset-template-picker"
                                class="editor-input-largest"
                                label="name"
                                trackBy="id"
                                @input="clearPresetError"
                            />
                        </div>
                    </template>
                    <template v-if="modalType === modalTypes.DELETE_GROUP">
                        <AppToggle
                            v-model="deleteGroupModal.deleteAll"
                            :label="$i18n.t('productCatalog.templates.deleteAllProperties')"
                            :disabled="readonly"
                            :small="true"
                            data-test-id="delete-group-modal-delete-all-toggle"
                            class="mb-3"
                        />
                        <AppMultiselectV3
                            v-model="deleteGroupModal.groupMoveTo"
                            :small="true"
                            :options="filteredGroupsForDelete"
                            :disabled="deleteGroupModal.deleteAll || readonly"
                            :error="deleteGroupModal.invalid"
                            :additionalLabel="$i18n.t('productCatalog.templates.moveProperties')"
                            :placeholder="$i18n.t('productCatalog.templates.chooseGroup')"
                            data-test-id="delete-group-modal-move-to-select"
                            label="name"
                            trackBy="id"
                            class="editor-input-largest mb-3"
                            @input="hideDeleteGroupValidation"
                        />
                    </template>
                </div>
                <template #modalFooter>
                    <AppButton
                        :buttonType="BUTTON_TYPES.PRIMARY"
                        :label="$i18n.t('generic.save')"
                        :iconType="ICON_TYPES.CHECK"
                        :disabled="readonly"
                        data-test-id="modal-save-button"
                        @click="onModalSaveClick()"
                    />
                </template>
            </AppDialogV2>
        </template>
    </AbstractEditPageWrapper>
</template>

<script>
// Libraries
import Draggable from 'vuedraggable';

// Components
import AbstractEditPageWrapper from '@/components/layout/AbstractEditPageWrapper.vue';
import AppHeader from '@/components/layout/AppHeader.vue';
import AppDialogV2 from '@/components/partials/AppDialogV2.vue';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import AppInputV3 from '@/components/partials/inputs/AppInputV3.vue';
import AppTextareaV3 from '@/components/partials/inputs/AppTextareaV3.vue';
import AppMultiselectV3 from '@/components/partials/inputs/AppMultiselectV3.vue';
import AppToggle from '@/components/partials/inputs/AppToggle.vue';
import IconButton from '@/components/partials/IconButton.vue';
import { ICON_TYPES } from '@/common/iconHelper';
import GroupGenerator from '@/components/FormBuilder/GroupGenerator.vue';
import AppTextUnderline from '@/components/partials/AppTextUnderline.vue';
import PropsFormBuilder from '@/components/FormBuilder/PropsFormBuilder.vue';
import { FORM_BUILDER_TYPES, WIDGETS } from '@/formBuilderConfig';
import ValueInputs from '@/components/partials/inputs/ValueInputs.vue';
import TemplatesModalMixin, { MODAL_TYPES, GROUP_TYPES } from '@/__new__/features/template/common/TemplatesModalMixin';
import AppRadioButton from '@/components/partials/inputs/AppRadioButton.vue';
import EditorButtons from '@/components/layout/EditorButtons.vue';

// Helpers
import { CommonTemplateEntityTemplate } from '@/__new__/services/dno/template/models/CommonTemplateEntityTemplate';
import { ALERT_TYPES } from '@/common/alerts/Alert';
import Button from '@/common/button/Button';
import { textToLowerCase, validateAlphaNumericWithDashAndUnderscore } from '@/common/formatting';
import { PLURALIZATION } from '@/common/locale/labelSingularOrPlural';
import { uuidV4 } from '@/common/utils';
import { isUserAllowed } from '@/services/permissions/permissions.service';
import { TEMPLATE_TYPES_MAP } from '@/__new__/services/dno/template/models/templateTypes';
import { flatten, cloneDeep } from 'lodash';

// HTTP
import { EDITOR_MODE } from '@/common/entities/entityHelper';

export default {
    name: 'CommonTemplateEditorWrapper',
    components: {
        Draggable,
        AbstractEditPageWrapper,
        AppHeader,
        AppDialogV2,
        AppButton,
        AppInputV3,
        AppTextareaV3,
        AppMultiselectV3,
        AppToggle,
        IconButton,
        GroupGenerator,
        AppTextUnderline,
        PropsFormBuilder,
        ValueInputs,
        AppRadioButton,
        EditorButtons,
    },
    mixins: [TemplatesModalMixin],
    props: {
        isPresetEditor: {
            type: Boolean,
            default: false,
        },
        writePermission: {
            type: String,
            required: true,
        },
        entityTypesOptions: {
            type: Array,
            required: true,
        },
        reversedKeys: {
            type: Array,
            default: () => [],
            required: false,
        },
        getTemplateById: {
            type: Object,
            default: () => ({}),
            required: false,
        },
        getApprovedTemplatesByType: {
            type: Object,
            default: () => ({}),
            required: false,
        },
        allTemplates: {
            type: Array,
            default: () => [],
            required: false,
        },
        listRouteName: {
            type: String,
            required: true,
        },
        externalIdShowError: {
            type: Boolean,
            default: false,
            required: false,
        },
    },
    data() {
        return {
            isUserAllowed,
            // template data
            name: '',
            description: '',
            externalId: {
                value: '',
                invalid: false,
                showError: false,
            },
            entityType: '',
            state: '',
            updateTime: null,
            isRegularTemplateReferenced: false,
            referencedEntityIds: [],
            formattedDataForFormBuilder: [],

            // to store data right after properties/groups creation
            groups: {},
            properties: {},
            propertyInvalidIndices: [],

            // for dnd
            formattedGroupsForDnd: [],
            showValidation: false,
            isTimeInvalid: false,

            // generic stuff
            modalTypes: MODAL_TYPES,
            ICON_TYPES,
            BUTTON_TYPES,
            PLURALIZATION,
            isTemplateBulkEditEnabled: !this.isPresetEditor, // isPresetEditor = false, !this.isPresetEditor = true
            readonly: false,
            FORM_BUILDER_TYPES,

            originalProperties: {},
            yesConfirmationButton: new Button({
                label: this.$i18n.t('generic.yes'),
                alertType: ALERT_TYPES.warning,
            }),
            deleteConfirmButton: new Button({
                label: this.$i18n.t('generic.delete'),
                alertType: ALERT_TYPES.warning,
            }),
        };
    },
    computed: {
        addGroupTitleKey() {
            return this.isPresetEditor
                ? 'productCatalog.templates.addGroup'
                : 'productCatalog.templates.addGroupOrTemplate';
        },
        isTemplateReferenced() {
            let isPresetTemplateReferenced = false;
            if (this.inEditMode && this.isPresetEditor) {
                const templatesWherePresetIsUsed = [];
                // get current template
                const currentTemplate = this.getTemplateById[this.$route.params.id];
                // create array of strings with IDs of templates where this preset template is used
                if (currentTemplate?.referencedTemplate?.length) {
                    currentTemplate.referencedTemplate.forEach(ref => templatesWherePresetIsUsed.push(ref.id));
                }
                // filter templates within which this preset template is used from All templates
                const filteredTemplatesWherePresetIsUsed = this.allTemplates.filter(template =>
                    templatesWherePresetIsUsed.includes(template.id),
                );
                // checking if these templates are used by some entities
                isPresetTemplateReferenced = filteredTemplatesWherePresetIsUsed.some(
                    filter => filter.isRegularTemplateReferenced,
                );
            }
            return this.isRegularTemplateReferenced || isPresetTemplateReferenced;
        },
        isExternalIdVerified() {
            return this.inEditMode && this.propertyModal.isPropSaved;
        },
        isDataTypeVerified() {
            return this.inEditMode && this.propertyModal.isPropSaved;
        },
        filteredGroupsForAddProperty() {
            return this.formattedGroupsForDnd.filter(({ type }) => type !== GROUP_TYPES.PRESET_TEMPLATE);
        },
        filteredGroupsForDelete() {
            return this.formattedGroupsForDnd.filter(({ id }) => id !== this.deleteGroupModal?.id);
        },
        pageTitle() {
            const action = this.inEditMode ? this.$i18n.t('generic.edit') : this.$i18n.t('generic.addNew');
            const page = this.isPresetEditor
                ? this.$i18n.tc('productCatalog.entities.presetTemplate')
                : this.$i18n.tc('productCatalog.entities.template');
            return `${action} ${page}`;
        },
        propertiesName() {
            if (this.isPresetEditor) {
                return this.$i18n.tc('productCatalog.presetTemplates.presetTemplateProperty', PLURALIZATION.PLURAL);
            }
            return this.$i18n.tc('productCatalog.templates.templateProperty', PLURALIZATION.PLURAL);
        },
        presetTemplatesOptions() {
            const presetIds = this.formattedGroupsForDnd
                .filter(({ type }) => type === this.GROUP_TYPES.PRESET_TEMPLATE)
                .map(({ id }) => id);
            return (
                this.getApprovedTemplatesByType[TEMPLATE_TYPES_MAP.PRESET]?.filter(
                    ({ id }) => !presetIds.includes(id),
                ) || []
            );
        },
        availablePropertyTypes() {
            return this.$lfFormBuilder.configArr
                .filter(({ hidden }) => !hidden)
                .map(({ name, type }) => ({
                    label: name,
                    key: type,
                }));
        },
        propertyType() {
            return this.propertyModal?.dataType || '';
        },
        inEditMode() {
            return Boolean(this.$route.params.id) && !this.$route.params.clone;
        },
        externalIdErrorMessage() {
            if (this.propertyModal.externalIdInvalid) {
                if (this.reversedKeys.includes(this.propertyModal.externalId)) {
                    return this.$i18n.t('alertMessage.externalIdNotAllowedWords', {
                        externalId: this.propertyModal.externalId,
                        entityType: this.entityType,
                    });
                }
                return this.$i18n.t('alertMessage.pc.externalIdWithUppercaseSupportInvalid');
            }
            return '';
        },
        dropdownDefaultValueOptions() {
            return this.propertyModal?.dataType === FORM_BUILDER_TYPES.DROPDOWN ||
                this.propertyModal?.dataType === FORM_BUILDER_TYPES.DROPDOWN_MULTIPLE
                ? this.propertyModal?.enum.map(keyValPair => keyValPair.key)
                : [];
        },
        currentModalTitle() {
            if (this.modalType === MODAL_TYPES.PROPERTY && this.propertyModal.id) {
                return this.$i18n.t('productCatalog.templates.editProperty');
            }
            if (this.modalType === MODAL_TYPES.PROPERTY) {
                return this.$i18n.t('productCatalog.templates.addProperty');
            }
            if (this.modalType === MODAL_TYPES.GROUP) {
                return this.$i18n.t(this.addGroupTitleKey);
            }
            if (this.modalType === MODAL_TYPES.DELETE_GROUP) {
                return this.$i18n.t('productCatalog.templates.deleteGroup');
            }
            return '';
        },
    },
    watch: {
        formattedGroupsForDnd: {
            async handler(newVal) {
                const groupsFlat = await Promise.all(
                    newVal.map(async group => {
                        const groupData = this.groups[group.id];
                        if (group.type === this.GROUP_TYPES.CUSTOM) {
                            const mappedFields = await Promise.all(
                                group.fields.map(prop => {
                                    const propData = this.properties[prop.id];

                                    return CommonTemplateEntityTemplate.formatProperty(propData);
                                }),
                            );

                            return {
                                name: groupData.name,
                                description: groupData.description,
                                fields: mappedFields,
                            };
                        }
                        const presetGroups = await this.getPresetGroups(groupData);

                        return presetGroups;
                    }),
                );

                this.formattedDataForFormBuilder = flatten(groupsFlat);
            },
            deep: true,
        },
        'propertyModal.enum': {
            handler(newVal) {
                if (newVal && Array.isArray(newVal)) {
                    const tempPropertyInvalidIndices = [];
                    let index = 0;
                    for (const element of newVal) {
                        if (!element?.key || !element?.value) {
                            tempPropertyInvalidIndices.push(index);
                        }
                        index += 1;
                    }
                    this.propertyInvalidIndices = [...new Set(tempPropertyInvalidIndices)];
                }
            },
            deep: true,
        },
        getTemplateById: {
            handler(newVal) {
                if (newVal) {
                    const template = this.getTemplateById[this.$route.params.id];
                    if (!template || !Object.keys(template).length) {
                        return;
                    }
                    if (this.$route.params.mode) {
                        this.readonly = this.$route.params.mode === EDITOR_MODE.VIEW;
                    }
                    if (this.$route.params.clone) {
                        this.name += `${template.name} (cloned)`;
                        this.externalId.value = `${template.externalId}_cloned`;
                    } else {
                        this.name = template.name;
                        this.externalId.value = template.externalId;
                    }
                    this.description = template.description;
                    this.state = template.state;
                    this.entityType = template.entityType;
                    this.isRegularTemplateReferenced = template.isRegularTemplateReferenced;
                    this.updateTime = template.updateTime;
                    this.referencedEntityIds = template.referencedEntityIds;
                    const groupsFormatted = [];
                    // TODO: refactor cycle by sortWeight with offsets for presets after BE implementation
                    const [maxSortWeight] = template.groups.map(({ sortWeight }) => sortWeight);
                    const totalLength = template.groups.length + template.presetTemplates.length;
                    const offsetMaxSortWeight = totalLength === maxSortWeight ? 0 : totalLength - maxSortWeight;
                    this.groups = {};
                    for (let index = totalLength, addedPresets = 0; index > 0; index -= 1) {
                        const sortWeightIteration = index - offsetMaxSortWeight;
                        let group = template.groups.find(({ sortWeight }) => sortWeight === sortWeightIteration);
                        let type = this.GROUP_TYPES.CUSTOM;
                        if (!group) {
                            const { id } = template.presetTemplates[addedPresets];
                            group = cloneDeep(this.getTemplateById[id]);
                            type = this.GROUP_TYPES.PRESET_TEMPLATE;

                            group.properties.sort((a, b) => a.sortWeight - b.sortWeight);

                            addedPresets += 1;
                        }
                        groupsFormatted.push({
                            id: group.id,
                            name: group.name,
                            sortWeight: index,
                            type,
                            fields: [],
                        });
                        this.groups[group.id] = {
                            ...group,
                            type,
                            sortWeight: index,
                        };
                    }
                    this.properties = template.properties.reduce((acc, prop) => {
                        // Map for formattedGroupsForDnd
                        const groupForProp = groupsFormatted.find(({ id }) => id === prop.groupId.toString());
                        groupForProp.fields.push({
                            id: prop.id,
                            name: prop.name,
                            sortWeight: prop.sortWeight,
                            schema: prop.schema,
                        });
                        acc[prop.id] = {
                            ...prop,
                            isPropSaved: true,
                        };
                        if (this.isDropdownType(prop.dataType)) {
                            const options = acc[prop.id]?.schema?.enum;
                            acc[prop.id].componentProps = {
                                ...acc[prop.id].componentProps,
                                options,
                            };
                            if (prop.dataType === FORM_BUILDER_TYPES.DROPDOWN_MULTIPLE) {
                                acc[prop.id].componentProps.labelFormatter = entity => entity.name;
                            }
                        }
                        return acc;
                    }, {});
                    groupsFormatted.forEach(group => {
                        group.fields = group.fields.sort((a, b) => a.sortWeight - b.sortWeight);
                    });
                    this.formattedGroupsForDnd = Object.values(groupsFormatted);
                    this.originalProperties = this.buildDnoGroupsAndProperties(
                        this.formattedGroupsForDnd,
                        this.groups,
                        this.properties,
                    ).properties;
                }
            },
        },
        externalIdShowError: {
            handler(newVal) {
                if (newVal) {
                    this.externalId.showError = true;
                }
            },
        },
    },
    created() {
        const { id } = this.$route.params;
        if (id) {
            return;
        }
        this.addGroup(
            {
                name: 'Group 1',
                description: '',
                type: this.GROUP_TYPES.CUSTOM,
                fields: [],
            },
            false,
        );
    },
    methods: {
        async getPresetGroups(presetTemplate) {
            const result = [];

            for (const group of presetTemplate.groups) {
                let formattedFields;

                const widgetIds = Object.keys(WIDGETS);

                if (widgetIds.includes(presetTemplate.externalId)) {
                    const widgetData = WIDGETS[presetTemplate.externalId];

                    formattedFields = [
                        {
                            id: presetTemplate.externalId,
                            name: widgetData.name,
                            type: widgetData.type,
                            componentProps: {},
                        },
                    ];
                } else {
                    const fieldOfGroup = presetTemplate.properties.filter(property => property.groupId === group.id);
                    /* eslint-disable no-await-in-loop */
                    formattedFields = await Promise.all(
                        fieldOfGroup.map(ptProperty => CommonTemplateEntityTemplate.formatProperty(ptProperty)),
                    );
                }

                /* eslint-enable no-await-in-loop */
                result.push({
                    name: group.name,
                    description: group.description,
                    fields: formattedFields,
                });
            }

            return result;
        },
        changeFormattedGroupsForDnd(val) {
            const [{ type }] = val;

            if (type !== this.GROUP_TYPES.PRESET_TEMPLATE) {
                this.formattedGroupsForDnd = val;
            } else {
                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('alertMessage.failedToMoveGroup'),
                });
            }
        },
        textToLowerCase,
        cleanExternalIdError() {
            this.externalId.showError = false;
            this.propertyModal.isExternalIdEmpty = false;
            this.isInvalidExternalIdOnInput();
        },
        addGroup(group, showAlert = true) {
            const id = group.id || uuidV4();

            this.groups[id] = {
                ...group,
                id,
                type: group.type,
            };

            this.formattedGroupsForDnd.push({
                id,
                name: group.name,
                type: group.type,
                isGroupNotSaved: true,
                fields:
                    group?.fields?.map(field => ({
                        id: field.id,
                        name: field.name,
                    })) || [],
            });

            if (showAlert) {
                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('alertMessage.successMessageWithoutRedirect', {
                        entityName: `${this.$i18n.tc('productCatalog.templates.group')} '${group.name}'`,
                        action: this.$i18n.t('generic.created'),
                    }),
                    type: ALERT_TYPES.success,
                });
            }
        },
        moveProperty(propId, oldGroupId, newGroupId) {
            this.properties[propId].groupId = newGroupId;

            const oldGroup = this.formattedGroupsForDnd.find(({ id }) => id === oldGroupId);
            oldGroup.fields = oldGroup.fields.filter(({ id }) => id !== propId);

            this.addProperty(this.properties[propId], false);
        },
        addProperty(prop, isPropNotSaved) {
            this.properties[prop.id] = prop;

            this.formattedGroupsForDnd
                .find(({ id }) => id === prop.groupId)
                .fields.push({
                    id: prop.id,
                    name: prop.name,
                    isPropNotSaved,
                });

            this.$eventBus.$emit('showAlert', {
                message: this.$i18n.t('alertMessage.successMessageWithoutRedirect', {
                    entityName: `${this.$i18n.tc('generic.property')} '${prop.name}'`,
                    action: this.$i18n.t('generic.created'),
                }),
                type: ALERT_TYPES.success,
            });
        },
        rebuildFormBuilder() {
            // For rebuilding computed prop for GroupGenerator
            this.formattedGroupsForDnd = this.formattedGroupsForDnd.map(group => ({ ...group }));
        },
        hideValidation() {
            this.showValidation = false;
            this.isTimeInvalid = false;
        },
        getIsInvalid() {
            const hasInvalidMainVal =
                !this.name || !this.externalId.value || (!this.isPresetEditor && !this.entityType);

            this.showValidation = hasInvalidMainVal;
            this.externalId.showError = !this.externalId.value;

            return hasInvalidMainVal;
        },
        validateExternalId(externalId) {
            return externalId && !validateAlphaNumericWithDashAndUnderscore(this.externalId.value);
        },
        isInvalidExternalIdOnInput() {
            this.externalId.invalid = false;
            this.propertyModal.externalIdInvalid = false;
            if (!validateAlphaNumericWithDashAndUnderscore(this.externalId.value) && this.externalId.value) {
                this.externalId.invalid = true;
            }
            if (
                !validateAlphaNumericWithDashAndUnderscore(this.propertyModal.externalId) &&
                this.propertyModal.externalId
            ) {
                this.propertyModal.externalIdInvalid = true;
            }
        },
        onSave() {
            if (this.getIsInvalid()) {
                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('alertMessage.pleaseFixValidation'),
                    type: ALERT_TYPES.error,
                });
                return;
            }
            if (this.validateExternalId(this.externalId.value)) {
                return;
            }
            const groupList = this.formattedGroupsForDnd.filter(
                group => this.groups[group.id]?.type === this.GROUP_TYPES.CUSTOM,
            );
            const propertyList = groupList.map(group => group.fields);
            const emptyPropertyGroupExists = propertyList.some(properties => properties.length === 0);
            if (emptyPropertyGroupExists) {
                this.$Progress.fail();
                this.$alert(this.$i18n.t('productCatalog.templates.alerts.zeroProperty'), {
                    type: ALERT_TYPES.error,
                });
                return;
            }
            const { properties, groups } = this.buildDnoGroupsAndProperties(
                this.formattedGroupsForDnd,
                this.groups,
                this.properties,
            );
            const templateData = {
                name: this.name,
                description: this.description,
                external_id: this.externalId.value,
                groups,
                property_definitions: properties,
                referenced_templates: this.formattedGroupsForDnd
                    .filter(({ type }) => type === this.GROUP_TYPES.PRESET_TEMPLATE)
                    .map(pt => ({
                        id: pt.id,
                        type: TEMPLATE_TYPES_MAP.PRESET,
                    })),
            };
            const finalEntityType = this.isPresetEditor ? TEMPLATE_TYPES_MAP.PRESET : this.entityType;
            if (!this.inEditMode) {
                this.$emit('add', finalEntityType, templateData);
            } else {
                if (this.isTemplateBulkEditEnabled && this.isTemplateReferenced) {
                    this.$eventBus.$emit('showAlert', {
                        message: this.$i18n.t('productCatalog.templates.thisTemplateIsCurrentlyInUse'),
                        type: ALERT_TYPES.warning,
                        buttons: [this.yesConfirmationButton],
                    });
                    this.$eventBus.$once('buttonClicked', id => {
                        if (id === this.yesConfirmationButton.id) {
                            this.$emit('update', this.$route.params.id, finalEntityType, templateData, this.updateTime);
                        }
                    });
                    return;
                }
                this.$emit('update', this.$route.params.id, finalEntityType, templateData, this.updateTime);
            }
        },
        onCancel() {
            this.$router.push({ name: this.listRouteName, params: { companyId: this.$route.params.companyId } });
        },
        showDeleteGroupAlert(groupId) {
            const { name } = this.groups[groupId];
            this.$eventBus.$emit('showAlert', {
                message: this.$i18n.t('productCatalog.templates.deleteSpecificGroup', {
                    name,
                }),
                type: ALERT_TYPES.warning,
                buttons: [this.deleteConfirmButton],
            });
            this.$eventBus.$once('buttonClicked', id => {
                if (id === this.deleteConfirmButton.id) {
                    this.deleteGroupWithProperty(groupId);
                }
            });
        },
        /**
         * Builds groups and property objects in a format acceptable by the DNO
         */
        buildDnoGroupsAndProperties(formattedGroupsForDno, groupsById, propertiesById) {
            const properties = {};
            const groups = formattedGroupsForDno.reduce((acc, group, index) => {
                const groupData = groupsById[group.id];

                if (groupData.type === this.GROUP_TYPES.CUSTOM) {
                    acc[groupData.id] = {
                        name: groupData.name,
                        description: groupData?.description || '',
                        // disabled since API doesn't need it
                        // parent_group_id: null,
                        sort_weight: formattedGroupsForDno.length - index,
                    };

                    group.fields.forEach(({ id }, propertyIndex) => {
                        const propData = propertiesById[id];

                        // first option for edit, second is for add
                        let schema = propData.schema || {
                            type:
                                propData.dataType === FORM_BUILDER_TYPES.TEXTAREA ||
                                propData.dataType === FORM_BUILDER_TYPES.REFERENCE
                                    ? FORM_BUILDER_TYPES.INPUT
                                    : propData.dataType,
                        };

                        if (this.isDropdownType(propData.dataType) && !schema.enum) {
                            schema = {
                                enum: this.propertyModal?.enum,
                                type: propData.dataType,
                            };
                        }

                        properties[propData.id] = {
                            name: propData.name,
                            description: propData.description,
                            schema,
                            ...(propData?.enumMapping && { enum_mapping: propData.enumMapping }),
                            is_required: Object.hasOwnProperty.call(propData, 'isRequired')
                                ? propData.isRequired
                                : false,
                            group_id: groupData.id,
                            sort_weight: propertyIndex + 1,
                        };

                        // this one holding additional data for the inputs
                        if (
                            propData.placeholder ||
                            propData.step ||
                            propData.dataType === FORM_BUILDER_TYPES.TEXTAREA
                        ) {
                            properties[propData.id].ui_properties = {};
                        }

                        if (propData.defaultValue !== undefined && propData.defaultValue !== null) {
                            properties[propData.id].default_value = propData.defaultValue;
                        }

                        if (Object.hasOwnProperty.call(propData, 'placeholder') && Boolean(propData.placeholder)) {
                            properties[propData.id].ui_properties.placeholder = propData.placeholder;
                        }

                        if (propData.dataType === FORM_BUILDER_TYPES.TEXTAREA) {
                            properties[propData.id].data_type = FORM_BUILDER_TYPES.INPUT;
                            properties[propData.id].ui_properties.is_text_area = true;
                        } else if (propData.dataType === FORM_BUILDER_TYPES.NUMERIC) {
                            if (Object.hasOwnProperty.call(propData, 'step') && Boolean(propData.step)) {
                                properties[propData.id].ui_properties.step = propData.step;
                            }
                        } else if (propData.dataType === FORM_BUILDER_TYPES.DROPDOWN) {
                            properties[propData.id].data_type = 'char_spec';
                            properties[propData.id].char_spec_id = propData.enum;
                        } else if (propData.dataType === FORM_BUILDER_TYPES.DROPDOWN_MULTIPLE) {
                            properties[propData.id].data_type = 'array_char_spec';
                            properties[propData.id].char_spec_id = propData.enum;

                            if (!properties[propData.id].default_value?.length) {
                                delete properties[propData.id].default_value;
                            }
                        } else if (
                            propData.dataType === FORM_BUILDER_TYPES.JSON &&
                            !properties[propData.id].default_value
                        ) {
                            delete properties[propData.id].default_value;
                        } else if (propData.dataType === FORM_BUILDER_TYPES.REFERENCE) {
                            properties[propData.id].data_type = FORM_BUILDER_TYPES.INPUT;
                            properties[propData.id].ui_properties = {
                                referenced_type: propData.referencedType,
                            };
                            if (propData.placeholder) {
                                properties[propData.id].ui_properties.placeholder = propData.placeholder;
                            }
                        }
                    }, {});
                }

                return acc;
            }, {});
            return {
                properties,
                groups,
            };
        },
        deleteGroupWithProperty(groupId) {
            const { name } = this.groups[groupId];
            delete this.groups[groupId];

            this.formattedGroupsForDnd = this.formattedGroupsForDnd.filter(({ id }) => id !== groupId);

            this.$eventBus.$emit('showAlert', {
                message: this.$i18n.t('alertMessage.successMessageWithoutRedirect', {
                    entityName: `${this.$i18n.tc('productCatalog.templates.group')} '${name}'`,
                    action: this.$i18n.t('generic.deleted'),
                }),
                type: ALERT_TYPES.success,
            });
        },
        onDeleteProperty(propertyId, groupId) {
            const { name } = this.properties[propertyId];
            delete this.properties[propertyId];

            const group = this.formattedGroupsForDnd.find(({ id }) => id === groupId);
            group.fields = group.fields.filter(({ id }) => id !== propertyId);

            this.$eventBus.$emit('showAlert', {
                message: this.$i18n.t('alertMessage.successMessageWithoutRedirect', {
                    entityName: `${this.$i18n.tc('generic.property')} '${name}'`,
                    action: this.$i18n.t('generic.deleted'),
                }),
                type: ALERT_TYPES.success,
            });
        },
        showDeletePropertyAlert(propertyId, groupId) {
            const { name } = this.properties[propertyId];
            const confirmButton = new Button({
                label: this.$i18n.t('generic.delete'),
                alertType: ALERT_TYPES.warning,
            });
            this.$eventBus.$emit('showAlert', {
                message: this.$i18n.t('productCatalog.templates.deleteSpecificProperty', {
                    name,
                }),
                type: ALERT_TYPES.warning,
                buttons: [confirmButton],
            });
            this.$eventBus.$once('buttonClicked', id => {
                if (id === confirmButton.id) {
                    this.onDeleteProperty(propertyId, groupId);
                }
            });
        },
        isDropdownType(dataType) {
            return FORM_BUILDER_TYPES.DROPDOWN === dataType || FORM_BUILDER_TYPES.DROPDOWN_MULTIPLE === dataType;
        },
        isReferenceType(dataType) {
            return dataType === FORM_BUILDER_TYPES.REFERENCE;
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/editor-layout-v2';
@import '~@/assets/scss/typographyV2';
@import '~@/assets/scss/palette';
@import '~@/assets/scss/icons';
@import '~@/assets/scss/animations';

// TODO: delete stuff like this
$icon-path: '~@/assets/icons/';

.w-60 {
    width: 60%;
}

.end-line-content {
    height: 1rem;
}

.property-editor-modal-wrapper {
    padding: 2rem 2rem 1.5rem 2.5rem;
}

.dnd-properties-wrapper {
    padding-left: 2rem;
}

.dnd-group-wrapper,
.dnd-property-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    background-color: $blue15;
    padding: 0.5rem 1.5rem 0.5rem 1rem;
    font-size: $text-sm;
    font-weight: $medium-font-weight;
    color: $blue;

    &:hover .dnd-item-right-part {
        transform: translateX(0);
    }
}

.dnd-group-wrapper-light {
    background-color: $blue5;
}

.dnd-item-left-part,
.dnd-item-right-part {
    display: flex;
    align-items: flex-start;
    align-items: center;
    line-height: 1.86;
}

.dnd-item-right-part {
    transform: translateX(180%);
    transition: transform $medium-animation-time;
}

.group-dnd-handle,
.property-dnd-handle {
    width: 1rem;
    height: 1rem;
    margin-right: 0.5rem;
    background-image: url($icon-path + $dnd-handle);
    cursor: grab;
}

.sidebar-dnd {
    overflow-x: hidden;
}
</style>
