export const languageMap = {
    en: {
        key: 'en',
        label: 'English',
    },
    es: {
        key: 'es',
        label: 'Spanish',
    },
};

export default {};
