import Account from '@/models/Account';

export const permissionGroups = {
    productCatalog: {
        label: 'Product Catalog',
        read: {
            key: 'V3ProductCatalog',
            state: false,
        },
        write: {
            key: 'V3ProductCatalogWrite',
            state: false,
        },
    },
    vouchers: {
        label: 'Vouchers',
        read: {
            key: 'V3Vouchers',
            state: false,
        },
        write: {
            key: 'V3VouchersWrite',
            state: false,
        },
    },
    rewards: {
        label: 'Rewards',
        read: {
            key: 'V3Reward',
            state: false,
        },
        write: {
            key: 'V3RewardWrite',
            state: false,
        },
    },
    metrics: {
        label: 'Analytics',
        read: {
            key: 'Metrics',
            state: false,
        },
        write: {
            key: 'Metrics',
            state: false,
        },
    },
    numberManagement: {
        label: 'Number Management',
        read: {
            key: 'V3NumberManager',
            state: false,
        },
        write: {
            key: 'V3NumberManagerWrite',
            state: false,
        },
    },
    simUpload: {
        label: 'Sim Upload',
        read: {
            key: 'V3SimUpload',
            state: false,
        },
        write: {
            key: 'V3SimUploadWrite',
            state: false,
        },
    },
    userManager: {
        label: 'User Manager',
        read: {
            key: 'V3UserManager',
            state: false,
        },
        write: {
            key: 'V3UserManagerWrite',
            state: false,
        },
    },
    userManagerEditVerificationIdentifier: {
        label: 'User Manager Edit Verification Identifier',
        read: {
            key: 'V3UserManagerEditVerificationIdentifier',
            state: false,
        },
        write: {
            key: 'V3UserManagerEditVerificationIdentifierWrite',
            state: false,
        },
    },
    userManagerEditWithoutVerification: {
        label: 'User Manager Edit Without Verification',
        read: {
            key: 'V3UserManagerEditWithoutVerification',
            state: false,
        },
        write: {
            key: 'V3UserManagerEditWithoutVerificationWrite',
            state: false,
        },
    },
    benefits: {
        label: 'Benefits',
        read: {
            key: 'V3Benefits',
            state: false,
        },
        write: {
            key: 'V3BenefitsWrite',
            state: false,
        },
    },
    topUp: {
        label: 'Top Up',
        read: {
            key: 'V3Topup',
            state: false,
        },
        write: {
            key: 'V3TopupWrite',
            state: false,
        },
    },
    orderManagement: {
        label: 'Order Management',
        read: {
            key: 'V3OrderManager',
            state: false,
        },
        write: {
            key: 'V3OrderManagerWrite',
            state: false,
        },
    },
    chargingManager: {
        label: 'Charging Manager',
        read: {
            key: 'V3Charging',
            state: false,
        },
        write: {
            key: 'V3ChargingWrite',
            state: false,
        },
    },
    chargingTransaction: {
        label: 'Charging Transaction',
        read: {
            key: 'V3ChargingTransaction',
            state: false,
        },
        write: {
            key: 'V3ChargingTransactionWrite',
            state: false,
        },
    },
    chargingTransactionUnmasked: {
        label: 'Charging Transaction - Unmasked CDRs',
        read: {
            key: 'V3ChargingTransactionUnmasked',
            state: false,
        },
        write: {
            key: 'V3ChargingTransactionUnmaskedWrite',
            state: false,
        },
    },
    cep: {
        label: 'Contextual Engagement',
        read: {
            key: 'Ce',
            state: false,
        },
        write: {
            key: 'Ce',
            state: false,
        },
    },
    admin: {
        label: 'Admin',
        read: {
            key: 'Admin',
            state: false,
        },
        write: {
            key: 'Admin',
            state: false,
        },
    },
    remoteConfig: {
        label: 'Remote Config',
        read: {
            key: 'RemoteConfig',
            state: false,
        },
        write: {
            key: 'RemoteConfig',
            state: false,
        },
    },
    ops: {
        label: 'Operation',
        read: {
            key: 'Ops',
            state: false,
        },
        write: {
            key: 'Ops',
            state: false,
        },
    },
    cepCampaigns: {
        label: 'CEP Campaigns',
        read: {
            key: 'V3CepCampaigns',
            state: false,
        },
        write: {
            key: 'V3CepCampaignsWrite',
            state: false,
        },
    },
    payment: {
        label: 'Payments',
        read: {
            key: 'V3Payment',
            state: false,
        },
        write: {
            key: 'V3PaymentWrite',
            state: false,
        },
    },
    catalog: {
        label: 'Product Catalog - New',
        read: {
            key: 'V3Catalog',
            state: false,
        },
        write: {
            key: 'V3CatalogWrite',
            state: false,
        },
    },
    refund: {
        label: 'Refund',
        read: {
            key: 'V3Refund',
            state: false,
        },
        write: {
            key: 'V3RefundWrite',
            state: false,
        },
    },
    reports: {
        label: 'Reports',
        read: {
            key: 'V3Reports',
            state: false,
        },
        write: {
            key: 'V3ReportsWrite',
            state: false,
        },
    },
    billingStatements: {
        label: 'Billing Statement',
        read: {
            key: 'V3BillingStatement',
            state: false,
        },
        write: {
            key: 'V3BillingStatementWrite',
            state: false,
        },
    },
    oauth: {
        label: 'OAuth',
        read: {
            key: 'OAuth',
            state: false,
        },
        write: {
            key: 'OAuthWrite',
            state: false,
        },
    },
    tax: {
        label: 'Tax',
        read: {
            key: 'V3Tax',
            state: false,
        },
        write: {
            key: 'V3TaxWrite',
            state: false,
        },
    },
    userManagerGrant: {
        label: 'Grant',
        read: {
            key: 'V3UserManagerGrant',
            state: false,
        },
        write: {
            key: 'V3UserManagerGrantWrite',
            state: false,
        },
    },
    orchestration: {
        label: 'Orchestration',
        read: {
            key: 'V3Orchestration',
            state: false,
        },
        write: {
            key: 'V3OrchestrationWrite',
            state: false,
        },
    },
    oeExecutionsHierarchy: {
        label: 'OE Executions Hierarchy',
        read: {
            key: 'V3OrchestrationExecutionsHierarchyRead',
            state: false,
        },
        write: {
            key: 'V3OrchestrationExecutionsHierarchyWrite',
            state: false,
        },
    },
    esim: {
        label: 'ESIM',
        read: {
            key: 'V3Esim',
            state: false,
        },
        write: {
            key: 'V3EsimWrite',
            state: false,
        },
    },
    promotion: {
        label: 'Promotion',
        read: {
            key: 'V3Promotion',
            state: false,
        },
        write: {
            key: 'V3PromotionWrite',
            state: false,
        },
    },
    appointment: {
        label: 'Appointment',
        read: {
            key: 'V3Appointment',
            state: false,
        },
        write: {
            key: 'V3AppointmentWrite',
            state: false,
        },
    },
    // Temporary workaround 'till new permissions are done.
    appointmentCRUDInstall: {
        label: 'Appointment Create/Update/Delete Install (Always)',
        read: {
            key: 'V3AppointmentCRUDInstallAlways',
            state: false,
        },
        write: {
            key: 'V3AppointmentCRUDInstallAlwaysWrite',
            state: false,
        },
    },
    // Temporary workaround 'till new permissions are done.
    appointmentCRUDMaintenance: {
        label: 'Appointment Create/Update/Delete Maintenance (Always)',
        read: {
            key: 'V3AppointmentCRUDMaintenanceAlways',
            state: false,
        },
        write: {
            key: 'V3AppointmentCRUDMaintenanceAlwaysWrite',
            state: false,
        },
    },
    crm: {
        label: 'CRM',
        read: {
            key: 'V3Crm',
            state: false,
        },
        write: {
            key: 'V3CrmWrite',
            state: false,
        },
    },
    lfInternal: {
        label: 'LF Internal',
        read: {
            key: 'LfInternal',
            state: false,
        },
        write: {
            key: 'LfInternalWrite',
            state: false,
        },
    },
    waitList: {
        label: 'Waitlist',
        read: {
            key: 'V3Waitlist',
            state: false,
        },
        write: {
            key: 'V3WaitlistWrite',
            state: false,
        },
    },
    omdevedge: {
        label: 'OmDevEdge',
        read: {
            key: 'V3OmDevEdge',
            state: false,
        },
        write: {
            key: 'V3OmDevEdgeWrite',
            state: false,
        },
    },
    ossgpfiber: {
        label: 'OssGPFiber',
        read: {
            key: 'V3OssGPFiber',
            state: false,
        },
        write: {
            key: 'V3OssGPFiberWrite',
            state: false,
        },
    },
    osstmofiber: {
        label: 'OssTMOFiber',
        read: {
            key: 'V3OssTMOFiber',
            state: false,
        },
        write: {
            key: 'V3OssTMOFiberWrite',
            state: false,
        },
    },
    ossdevedge: {
        label: 'OssDevEdge',
        read: {
            key: 'V3OssDevEdge',
            state: false,
        },
        write: {
            key: 'V3OssDevEdgeWrite',
            state: false,
        },
    },
    coreom: {
        label: 'CoreOM',
        read: {
            key: 'V3CoreOM',
            state: false,
        },
        write: {
            key: 'V3CoreOMWrite',
            state: false,
        },
    },
    staticfilter: {
        label: 'Static Filter',
        read: {
            key: 'V3StaticFilter',
            state: false,
        },
        write: {
            key: 'V3StaticFilterWrite',
            state: false,
        },
    },
    provisioning: {
        label: 'Provisioning',
        read: {
            key: 'V3Provisioning',
            state: false,
        },
        write: {
            key: 'V3ProvisioningWrite',
            state: false,
        },
    },
    provider: {
        label: 'Provider',
        read: {
            key: 'V3Provider',
            state: false,
        },
        write: {
            key: 'V3ProviderWrite',
            state: false,
        },
    },
    addressbook: {
        label: 'Address Book',
        read: {
            key: 'V3AddressBook',
            state: false,
        },
        write: {
            key: 'V3AddressBookWrite',
            state: false,
        },
    },
    pricing: {
        label: 'Pricing and Fees',
        read: {
            key: 'V3Pricing',
            state: false,
        },
        write: {
            key: 'V3PricingWrite',
            state: false,
        },
    },
    accountActivateService: {
        label: 'UM Account Activate Service',
        read: {
            key: 'AccountActivateService',
            state: false,
        },
        write: {
            key: 'AccountActivateService',
            state: false,
        },
    },
};

export const roleMapper = new Map([
    ['V3ProductCatalog', 'Product Catalog - Read'],
    ['V3ProductCatalogWrite', 'Product Catalog - Write'],
    ['V3UserManager', 'User Manager - Read'],
    ['V3UserManagerWrite', 'User Manager - Write'],
    ['V3UserManagerEditVerificationIdentifier', 'User Manager Edit - Clear Verification Identifier'],
    ['V3UserManagerEditVerificationIdentifierWrite', 'User Manager Edit - Set Verification Identifier'],
    ['V3UserManagerEditWithoutVerification', 'User Manager Edit Without Verification'],
    ['V3UserManagerEditWithoutVerificationWrite', 'User Manager Edit Without Verification - Write'],
    ['V3Topup', 'Top Up - Read'],
    ['V3TopupWrite', 'Top Up - Write'],
    ['V3Reward', 'Rewards - Read'],
    ['V3RewardWrite', 'Rewards - Write'],
    ['Ce', 'Contextual Engagement'],
    ['V3Vouchers', 'Vouchers - Read'],
    ['V3VouchersWrite', 'Vouchers - Write'],
    ['V3Reward', 'Rewards - Read'],
    ['V3RewardWrite', 'Rewards - Write'],
    ['Metrics', 'Analytics'],
    ['V3NumberManager', 'Number Management - Read'],
    ['V3NumberManagerWrite', 'Number Management - Write'],
    ['V3SimUpload', 'Sim Upload - Read'],
    ['V3SimUploadWrite', 'Sim Upload - Write'],
    ['V3Topup', 'Top Up - Read'],
    ['V3TopupWrite', 'Top Up - Write'],
    ['V3Payment', 'Payment - Read'],
    ['V3PaymentWrite', 'Payment - Write'],
    ['V3Catalog', 'Product Catalog New - Read'],
    ['V3CatalogWrite', 'Product Catalog New - Write'],
    ['V3Refund', 'Refund - Read'],
    ['V3BillingStatement', 'Billing Statement'],
    ['V3BillingStatementWrite', 'Billing Statement - Write'],
    ['V3RefundWrite', 'Refund - Write'],
    ['V3Reports', 'Reports - Read'],
    ['V3ReportsWrite', 'Reports - Write'],
    ['V3OrderManager', 'Order Management - Read'],
    ['V3OrderManagerWrite', 'Order Management - Write'],
    ['V3Charging', 'Charging Manager - Read'],
    ['V3ChargingWrite', 'Charging Manager - Write'],
    ['V3ChargingTransaction', 'Charging Transaction - Read'],
    ['V3ChargingTransactionWrite', 'Charging Transaction - Write'],
    ['V3ChargingTransactionUnmasked', 'Charging Transaction Unmasked CDRs - Read'],
    ['V3ChargingTransactionUnmaskedWrite', 'Charging Transaction Unmasked CDRs - Write'],
    ['Ce', 'CEP'],
    ['Admin', 'Admin'],
    ['RemoteConfig', 'Remote Config'],
    ['External_API', 'API Integration'],
    ['Ops', 'Operation'],
    ['V3CepCampaigns', 'CEP Campaigns - Read'],
    ['V3CepCampaignsWrite', 'CEP Campaigns - Write'],
    ['OAuth', 'OAuth - Read'],
    ['OAuthWrite', 'OAuth - Write'],
    ['V3Tax', 'Tax - Read'],
    ['V3TaxWrite', 'Tax - Write'],
    ['V3UserManagerGrant', 'Grant - Read'],
    ['V3UserManagerGrantWrite', 'Grant - Write'],
    ['V3Orchestration', 'Orchestration - Read'],
    ['V3OrchestrationWrite', 'Orchestration - Write'],
    ['V3OrchestrationExecutionsHierarchyRead', 'OE Executions Hierarchy - Read'],
    ['V3OrchestrationExecutionsHierarchyWrite', 'OE Executions Hierarchy - Write'],
    ['V3Esim', 'eSIM - Read'],
    ['V3EsimWrite', 'eSIM - Write'],
    ['V3Promotion', 'Promotion'],
    ['V3PromotionWrite', 'Promotion - Write'],
    ['V3Appointment', 'Appointment'],
    ['V3AppointmentWrite', 'Appointment - Write'],
    ['V3AppointmentCRUDInstallAlways', 'Appointment - Create/Update/Delete Installation (Always)'],
    ['V3AppointmentCRUDInstallAlwaysWrite', 'Appointment - Create/Update/Delete Installation (Always) Write'],
    ['V3AppointmentCRUDMaintenanceAlways', 'Appointment - Create/Update/Delete Maintenance (Always)'],
    ['V3AppointmentCRUDMaintenanceAlwaysWrite', 'Appointment - Create/Update/Delete Maintenance (Always) Write'],
    ['V3Crm', 'CRM'],
    ['V3CrmWrite', 'CRM - Write'],
    ['LfInteral', 'LF Interal'],
    ['LfInteralWrite', 'LF Interal - Write'],
    ['V3Benefits', 'Benefits'],
    ['V3BenefitsWrite', 'Benefits - Write'],
    ['V3Waitlist', 'Waitlist - Read'],
    ['V3WaitlistWrite', 'Waitlist - Write'],
    ['V3ProductRestriction', 'Product Restriction - Read'],
    ['V3ProductRestrictionWrite', 'Product Restriction - Write'],
    ['V3OmDevEdge', 'OmDevEdge - Read'],
    ['V3OmDevEdgeWrite', 'OmDevEdge - Write'],
    ['V3OssGPFiber', 'OssGPFiber - Read'],
    ['V3OssGPFiberWrite', 'OssGPFiber - Write'],
    ['V3OssTMOFiber', 'OssTMOFiber - Read'],
    ['V3OssTMOFiberWrite', 'OssTMOFiber - Write'],
    ['V3OssDevEdge', 'OssDevEdge - Read'],
    ['V3OssDevEdgeWrite', 'OssDevEdge - Write'],
    ['V3CoreOM', 'CoreOM - Read'],
    ['V3CoreOMWrite', 'CoreOM - Write'],
    ['V3StaticFilter', 'Static Filter - Read'],
    ['V3StaticFilterWrite', 'Static Filter - Write'],
    ['V3Provisioning', 'Provisioning - Read'],
    ['V3ProvisioningWrite', 'Provisioning - Write'],
    ['V3Provider', 'Provider - Read'],
    ['V3ProviderWrite', 'Provider - Write'],
    ['V3AddressBook', 'Address Book - Read'],
    ['V3AddressBookWrite', 'Address Book - Write'],
    ['V3Pricing', 'Pricing and Fees - Read'],
    ['V3PricingWrite', 'Pricing and Fees - Write'],
    ['AccountActivateService', 'UM Account Activate Service'],
]);

export function showAvailablePermissions(permissions) {
    return Object.values(permissions).filter(element => roleMapper.has(element));
}

export default {};
