import i18n from '@/i18n';
import { TranslateResult } from 'vue-i18n';
export { REMOTE_CONFIG_KEYS } from '@/__new__/services/dno/remoteConfig/common/remoteConfigHelper';

export enum SIM_TYPE {
    PSIM = 'psimProfile',
    ESIM = 'esimProfile',
}

export const RESOURCE_TYPE = {
    [SIM_TYPE.ESIM]: 10,
    [SIM_TYPE.PSIM]: 20,
};

export enum SIM_SWAP_FLOW {
    ESIM_TO_ESIM = 'EsimToEsim',
    ESIM_TO_PSIM = 'EsimToPsim',
    PSIM_TO_ESIM = 'PsimToEsim',
    PSIM_TO_PSIM = 'PsimToPsim',
}

export function getResourceTypeLabel(resourceType: number): TranslateResult {
    const labels = {
        [RESOURCE_TYPE[SIM_TYPE.ESIM]]: i18n.t('generic.eSIM'),
        [RESOURCE_TYPE[SIM_TYPE.PSIM]]: i18n.t('generic.pSIM'),
    };
    return labels[resourceType] || i18n.t('generic.N/A');
}
