import i18n from '@/i18n';
import { TranslateResult } from 'vue-i18n';

export enum VLR_ID_TYPES {
    STARTS_WITH = 'starts_with',
    ENDS_WITH = 'ends_with',
    IS = 'is',
}

export interface VlrIdOptions {
    id: VLR_ID_TYPES;
    label: TranslateResult;
}

export type VlrIdTypeOptions = {
    [T in VLR_ID_TYPES]: VlrIdOptions;
};

export const MAP_VLR_ID_TYPE_TO_OPTION_VALUES: Readonly<VlrIdTypeOptions> = {
    [VLR_ID_TYPES.STARTS_WITH]: {
        id: VLR_ID_TYPES.STARTS_WITH,
        label: i18n.t('charging.chargingSpecifications.editor.conditions.vlrID.startsWith'),
    },
    [VLR_ID_TYPES.ENDS_WITH]: {
        id: VLR_ID_TYPES.ENDS_WITH,
        label: i18n.t('charging.chargingSpecifications.editor.conditions.vlrID.endsWith'),
    },
    [VLR_ID_TYPES.IS]: {
        id: VLR_ID_TYPES.IS,
        label: i18n.t('charging.chargingSpecifications.editor.conditions.vlrID.is'),
    },
};

export const MAP_VLR_ID_OPTION_TO_LABEL = {
    [VLR_ID_TYPES.STARTS_WITH]: i18n.t('charging.chargingSpecifications.editor.conditions.vlrID.startsWith'),
    [VLR_ID_TYPES.ENDS_WITH]: i18n.t('charging.chargingSpecifications.editor.conditions.vlrID.endsWith'),
    [VLR_ID_TYPES.IS]: i18n.t('charging.chargingSpecifications.editor.conditions.vlrID.is'),
};

export type VlrIdConditionValues = {
    vlrIdType: VlrIdOptions | null;
    values: string[];
};

export type VlrIdCondition = {
    inverted: boolean;
    src: VlrIdConditionValues[];
};

export type VlrIdConditionDataObject = {
    data: VlrIdCondition;
    uuid: string;
    type: string;
    dataEmpty: boolean | undefined;
    dataValid: boolean;
    isDataValid(): void;
};

export const DEFAULT_DATA_OBJECT: VlrIdCondition = {
    inverted: false,
    src: [
        {
            vlrIdType: null,
            values: [],
        },
    ],
};
