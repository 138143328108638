import httpClient from '@/http/index';

/**
 * Change Tax Area ID for a specific address
 * @param targetId Account/User/Entity ID
 * @param targetType  Entity type (account/user/etc...)
 * @param newTaid  New tax ID
 * @param addressId Address of which TAID is being changed
 * @return {AxiosPromise}
 */
const changeTaid = (targetId: string, targetType: number, newTaid: string, addressId: string) =>
    httpClient({
        method: 'POST',
        url: '/v3taxwrite/changetaid',
        data: {
            target_id: targetId,
            target_type: targetType,
            new_taid: newTaid,
            address_id: addressId,
        },
    });

export default {
    changeTaid,
};
