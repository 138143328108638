<template>
    <AbstractEditPageWrapper :sidebarPosition="EDITOR_PAGE_SIDEBAR_POSITION.LEFT">
        <template #header>
            <AppHeader :pageTitle="pageTitle" />
        </template>

        <template #sideBar>
            <div class="p-3 pt-4">
                <div class="lf-subtitle">
                    {{ $i18n.t('productCatalog.bulkEditor.bulkEditOptions') }}
                </div>
                <div class="lf-placeholder mb-5">
                    {{ $i18n.t('productCatalog.bulkEditor.bulkEditOptionsDescription') }}
                </div>
                <!-- Bulk Entity Parameters section -->
                <div class="editor-section">
                    <div v-if="isEditingOfferEntityType">
                        <AppRadioButton
                            v-model="editType"
                            :inputValue="EDIT_TYPES.CATEGORY"
                            :name="'editType'"
                            :labelRight="catRadioLabel"
                            data-test-id="category-edit-button"
                            class="font-weight-bold pb-2"
                        />
                    </div>
                    <div>
                        <AppRadioButton
                            v-model="editType"
                            :inputValue="EDIT_TYPES.STATE"
                            :name="'editType'"
                            :labelRight="stateRadioLabel"
                            data-test-id="state-edit-button"
                            class="font-weight-bold pb-2"
                        />
                    </div>
                    <AppRadioButton
                        v-model="editType"
                        :inputValue="EDIT_TYPES.PROPERTY"
                        :name="'editType'"
                        :labelRight="propertiesRadioLabel"
                        :disabled="!isSameTemplateForAllEntities"
                        data-test-id="property-edit-button"
                        class="font-weight-bold"
                    >
                        <AppTooltip
                            v-if="!isSameTemplateForAllEntities"
                            :offset="60"
                            :tooltipPosition="TOOLTIP_POSITION.bottom"
                            :contentWidth="'19rem'"
                            class="ml-2"
                        >
                            <template slot="label">
                                <div class="info-icon" />
                            </template>

                            <template slot="content">
                                <div class="tooltip-info">
                                    {{ $i18n.t('productCatalog.bulkEditor.disabledPropertyEditExplanation') }}
                                </div>
                            </template>
                        </AppTooltip>
                    </AppRadioButton>
                </div>
            </div>
        </template>

        <template #content>
            <div class="lf-subtitle">
                {{ $i18n.t('productCatalog.bulkEditor.selectedEntities') }}
            </div>
            <!-- Selected Entities Summary section -->
            <div class="summary-table">
                <AppTable
                    :entities="allEditEntities"
                    :canSelectColumns="true"
                    :canSort="true"
                    :columnsData="tableColumnsData"
                    :entityType="entityType"
                    :tableKey="`bulkEntityEditTable-${entityType}`"
                    :data-test-id="`bulkEditEntity-${entityType}-table`"
                >
                    <template #state="{ entity }">
                        <EntityStatusIndicator :status="entity.state" />
                    </template>

                    <template #customRowButtons="{ entity }">
                        <IconButton
                            v-if="entitiesDeletionEnabled"
                            :icon="ICON_TYPES.DELETE"
                            class="trash-btn mr-1"
                            @iconClick="onRemoveEntity(entity.id)"
                        />
                    </template>
                </AppTable>
            </div>
            <div class="lf-subtitle mb-4">
                {{ whatToEditTitle }}
            </div>
            <div v-if="editCategories">
                <AppMultiselectV3
                    v-model="entityData.categories"
                    :multiple="true"
                    :options="allCategories"
                    :showLabels="false"
                    :placeholder="categoryLabel"
                    :additionalLabel="categoryLabel"
                    label="name"
                    class="editor-input-largest mb-3"
                    data-test-id="selected-categories"
                    trackBy="id"
                    optionId="id"
                />
            </div>
            <div
                v-if="editState"
                class="multiselect-edit"
            >
                <AppMultiselectV3
                    v-model="entityData.state"
                    :multiple="false"
                    :options="availableStates"
                    :showLabels="false"
                    :placeholder="stateLabel"
                    :additionalLabel="stateLabel"
                    label="name"
                    class="editor-input-largest mb-3"
                    data-test-id="selected-state"
                    trackBy="value"
                    optionId="value"
                />
            </div>
            <div v-if="editProperties">
                <div v-if="isBulkeditCoreFieldsEnabled">
                    <div class="mb-4">
                        <div class="d-flex mb-3">
                            <AppCheckbox
                                :key="CORE_PROPERTIES_FE.NAME"
                                :value="findSelectedProperty(CORE_PROPERTIES_FE.NAME)"
                                class="mr-4"
                                @input="onCorePropertySelected(CORE_PROPERTIES_FE.NAME)"
                            />
                            <AppInputV3
                                v-model="entityData.name"
                                :placeholder="$i18n.t('generic.name')"
                                :label="$i18n.t('generic.name')"
                                class="editor-input-largest"
                                data-test-id="entity-name"
                            />
                        </div>
                        <div class="d-flex">
                            <AppCheckbox
                                :key="CORE_PROPERTIES_FE.DESCRIPTION"
                                :value="findSelectedProperty(CORE_PROPERTIES_FE.DESCRIPTION)"
                                class="mr-4"
                                @input="onCorePropertySelected(CORE_PROPERTIES_FE.DESCRIPTION)"
                            />
                            <AppTextareaV3
                                v-model="entityData.description"
                                :placeholder="$i18n.t('generic.addDescription')"
                                :label="$i18n.t('generic.description')"
                                :resizeNone="true"
                                class="editor-input-largest mb-3"
                            />
                        </div>
                    </div>

                    <template v-if="isEditingOfferEntityType">
                        <OfferCoreData
                            ref="offerCoreDataComponent"
                            v-model="offerCoreData"
                            :isInBulkEdit="true"
                            :selectedCoreProperties="selectedCoreProperties"
                            @corePropertySelected="propertyId => onCorePropertySelected(propertyId)"
                            @offerValidationResult="isValid => onCoreOfferDataValidated(isValid)"
                        />
                    </template>
                </div>
                <GroupGenerator
                    :groups="formmatedGroupsForDisaplay"
                    :enablePropertiesSelection="true"
                    class="w-60 mb-5"
                    data-test-id="template-form"
                    @input="onGroupGenInput"
                    @onPropertySelection="onPropertySelection"
                >
                    <template #header="{ group }">
                        <AppTextUnderline
                            :text="group.name"
                            class="mb-3"
                        />
                    </template>

                    <template #divider>
                        <div class="mb-3" />
                    </template>

                    <template #dividerGroup>
                        <div class="mb-5" />
                    </template>
                </GroupGenerator>
            </div>
        </template>

        <!-- Buttons section -->
        <template #controls>
            <AppButton
                :label="$t('generic.cancel')"
                class="mr-5"
                @click="onCancel"
            />
            <AppButton
                :buttonType="BUTTON_TYPES.PRIMARY"
                :label="buttonLabel"
                :iconType="ICON_TYPES.CHECK"
                data-test-id="submit-button"
                @click="onPreSave"
            />
        </template>
    </AbstractEditPageWrapper>
</template>

<script>
// Vuex
import Actions, { Getters, Mutations } from '@/store/mutation-types';
import { mapGetters, mapActions, mapMutations } from 'vuex';

// components
import AbstractEditPageWrapper, { EDITOR_PAGE_SIDEBAR_POSITION } from '@/components/layout/AbstractEditPageWrapper.vue';
import AppMultiselectV3 from '@/components/partials/inputs/AppMultiselectV3.vue';
import AppHeader from '@/components/layout/AppHeader.vue';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import AppCheckbox from '@/components/partials/inputs/AppCheckbox.vue';
import AppInputV3 from '@/components/partials/inputs/AppInputV3.vue';
import AppTextareaV3 from '@/components/partials/inputs/AppTextareaV3.vue';
import AppRadioButton from '@/components/partials/inputs/AppRadioButton.vue';
import AppTable from '@/components/partials/AppTable.vue';
import EntityStatusIndicator from '@/components/partials/EntityStatusIndicator.vue';
import EntityFetcherMixin from '@/__new__/features/pc/EntityFetcherMixin.vue';
import GroupGenerator from '@/components/FormBuilder/GroupGenerator.vue';
import AppTextUnderline from '@/components/partials/AppTextUnderline.vue';
import AppTooltip from '@/components/partials/AppTooltip.vue';
import { TOOLTIP_POSITION } from '@/common/tooltip';
import IconButton from '@/components/partials/IconButton.vue';
import { ICON_TYPES } from '@/common/iconHelper';
import OfferCoreData from '@/__new__/features/pc/offers/OfferCoreData.vue';

// helpers
import { STATUS_CODE_TO_STATUS_NAME_MAP, ENTITY_TYPE_TO_AVAILABLE_BE_STATES_MAP } from '@/common/commonHelper';
import ENTITY_TYPES from '@/common/entities/entityTypes';
import tableColumnType from '@/common/filterTable';
import RouteNames from '@/router/routeNames';
import { PLURALIZATION } from '@/common/locale/labelSingularOrPlural';
import * as Sentry from '@sentry/vue';
import entityEditorMixin from '@/common/entityEditorMixin';
import Button from '@/common/button/Button';
import { ALERT_TYPES } from '@/common/alerts/Alert';
import { FORM_BUILDER_TYPES, WIDGETS } from '@/formBuilderConfig';
import { ProductCatalogEntityTemplate } from '@/__new__/services/dno/pc/models/ProductCatalogEntityTemplate';
import ProductCatalogOfferModel from '@/__new__/services/dno/pc/models/ProductCatalogOfferModel';
import { TEMPLATE_TYPES_MAP } from '@/__new__/services/dno/pc/models/templateTypes';
import cloneDeep from 'lodash/cloneDeep';
import uniqBy from 'lodash/uniqBy';
import permissionsService from '@/services/permissions/permissions.service';
import CORE_PROPERTIES_FE from '@/__new__/features/pc/offers/common/offerCorePropertiesFE';

// http
import { submitBulkChange } from '@/__new__/services/dno/pc/http/bulkChange';

const EDIT_TYPES = {
    CATEGORY: 'category',
    STATE: 'state',
    PROPERTY: 'property',
    CORE_PROPERTY: 'core_propperty',
};

export default {
    name: 'BulkEntityEditor',
    components: {
        AbstractEditPageWrapper,
        AppHeader,
        AppButton,
        AppCheckbox,
        AppInputV3,
        AppTextareaV3,
        AppRadioButton,
        AppMultiselectV3,
        AppTable,
        EntityStatusIndicator,
        GroupGenerator,
        AppTextUnderline,
        AppTooltip,
        IconButton,
        OfferCoreData,
    },
    mixins: [EntityFetcherMixin, entityEditorMixin],
    data() {
        return {
            entityData: {
                categories: [],
                state: null,
            },
            offerCoreData: null,
            entityType: this.$route.params.entityType,
            editType: null,
            isPreSaveButtonClicked: false,
            formmatedGroupsForDisaplay: [],
            templateToEdit: null,
            updatedProperties: {},
            selectedPropertiesIds: [],
            selectedCoreProperties: [],
            ICON_TYPES,
            BUTTON_TYPES,
            PLURALIZATION,
            stateLabel: this.$i18n.t('generic.state'),
            propertiesLabel: this.$i18n.t('generic.properties'),
            categoryLabel: this.$i18n.t('productCatalog.entities.category'),
            catRadioLabel: this.$i18n.t('productCatalog.bulkEditor.bulkEditCategories'),
            stateRadioLabel: this.$i18n.t('productCatalog.bulkEditor.bulkEditState'),
            propertiesRadioLabel: this.$i18n.t('productCatalog.bulkEditor.bulkEditProperties'),
            tableColumnsData: [
                {
                    name: this.$i18n.t('generic.name'),
                    key: 'name',
                    field: 'name',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('rewards.history.entityId'),
                    key: 'id',
                    field: 'id',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('generic.description'),
                    key: 'description',
                    field: 'description',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('generic.state'),
                    key: 'state',
                    field: 'state',
                    filterType: tableColumnType.TEXT_LIMITED_OPTIONS,
                    limitedOptions: [],
                },
                {
                    name: this.$i18n.t('productCatalog.templates.templateName'),
                    key: 'templateName',
                    field: 'templateName',
                    filterType: tableColumnType.TEXT,
                    limitedOptions: [],
                },
                {
                    name: this.$i18n.t('productCatalog.templates.templateId'),
                    key: 'templateId',
                    field: 'templateId',
                    filterType: tableColumnType.TEXT_LIMITED_OPTIONS,
                    limitedOptions: [],
                },
                {
                    name: this.$i18n.t('productCatalog.templates.templateExternalId'),
                    key: 'templateExternalId',
                    field: 'templateExternalId',
                    filterType: tableColumnType.TEXT,
                    limitedOptions: [],
                },
                {
                    name: this.$i18n.t('productCatalog.entities.plural.categories'),
                    key: 'categoriesDisplay',
                    field: 'categoriesDisplay',
                    filterType: tableColumnType.TEXT_LIMITED_OPTIONS_CONTAINS,
                    limitedOptions: [],
                },
            ],
            EDITOR_PAGE_SIDEBAR_POSITION,
            EDIT_TYPES,
            TOOLTIP_POSITION,
            isBulkeditCoreFieldsEnabled: permissionsService.isBulkeditCoreFieldsEnabled(),
            CORE_PROPERTIES_FE,
        };
    },
    computed: {
        ...mapGetters('productcatalog', {
            getEntitiesApproved: Getters.PC_GET_ENTITIES_BY_TYPE_APPROVED,
            getEntitiesByType: Getters.PC_GET_ENTITIES_BY_TYPE,
            getBulkEntityIdsByType: Getters.PC_GET_BULK_EDIT_ENTITY_IDS_BY_TYPE,
            getTemplateByTypeAndId: Getters.PC_GET_TEMPLATE_BY_TYPE_AND_ID,
        }),
        allEditEntities() {
            const entitiesByType = this.getEntitiesByType(this.entityType);
            return (this.bulkEditIds || [])
                .map(id => entitiesByType[id])
                .map(entity => {
                    const template = this.getTemplateByTypeAndId(this.entityType, entity.templateId);
                    return {
                        ...entity,
                        templateName: template.name,
                        templateExternalId: template.externalId,
                        categoriesDisplay: entity.categories?.map(
                            catId => this.allCategories.find(x => x.id === catId)?.name || catId,
                        ),
                    };
                });
        },
        isSameTemplateForAllEntities() {
            if (this.allEditEntities.length) {
                return uniqBy(this.allEditEntities, 'templateId').length === 1;
            }
            return false;
        },
        amountOfEditingEntities() {
            return this.allEditEntities.length;
        },
        pageTitle() {
            return `${this.$i18n.t('productCatalog.bulkEditor.bulkEditing')} (${this.amountOfEditingEntities})`;
        },
        whatToEditTitle() {
            if (this.editState) {
                return this.$i18n.t('productCatalog.bulkEditor.bulkEditState');
            }
            if (this.editProperties) {
                return this.$i18n.t('productCatalog.bulkEditor.bulkEditProperties');
            }
            return '';
        },
        allCategories() {
            return this.getEntitiesApproved(ENTITY_TYPES.CATEGORY);
        },
        bulkEditIds() {
            return this.getBulkEntityIdsByType(this.entityType);
        },
        availableStates() {
            const statusCodes = ENTITY_TYPE_TO_AVAILABLE_BE_STATES_MAP.get(this.entityType);
            if (statusCodes && statusCodes.length) {
                return statusCodes.map(statusCode => ({
                    name: STATUS_CODE_TO_STATUS_NAME_MAP.get(statusCode),
                    value: statusCode,
                }));
            }

            return [];
        },
        editCategories() {
            return this.editType === EDIT_TYPES.CATEGORY;
        },
        editState() {
            return this.editType === EDIT_TYPES.STATE;
        },
        editProperties() {
            return this.editType === EDIT_TYPES.PROPERTY;
        },
        isEditingOfferEntityType() {
            return this.entityType === ENTITY_TYPES.OFFER;
        },
        entitiesDeletionEnabled() {
            return this.allEditEntities.length > 2;
        },
    },
    watch: {
        entityData: {
            handler() {
                this.entityEditorMixin.dirtyFlag = true;
            },
            deep: true,
        },
        allEditEntities: {
            async handler() {
                await this.setCommonTemplate();
                await this.setFormBuilderData();
            },
        },
    },
    async mounted() {
        this.fetchCategories();
        this.fetchEntities();
        await this.fetchTemplates();
        await this.setCommonTemplate();
        await this.setFormBuilderData();
    },
    methods: {
        ...mapActions('productcatalog', {
            requestEntitiesByType: Actions.PC_REQUEST_ENTITIES_BY_TYPE_AND_IDS,
            requestAllTemplates: Actions.PC_REQUEST_ALL_TEMPLATES,
            requestTemplatesByType: Actions.PC_REQUEST_TEMPLATES_BY_TYPE_AND_IDS,
        }),
        ...mapMutations('productcatalog', {
            setBulkEditChangeData: Mutations.PC_SET_BULK_EDIT_CHANGE_DATA,
            setBulkCompletedStatus: Mutations.PC_SET_BULK_EDIT_CHANGE_COMPLETED_STATUS,
            setBulkEntityIDs: Mutations.PC_SET_BULK_ENTITY_IDS,
        }),
        isDropdownType(dataType) {
            return FORM_BUILDER_TYPES.DROPDOWN === dataType || FORM_BUILDER_TYPES.DROPDOWN_MULTIPLE === dataType;
        },
        async fetchCategories() {
            try {
                this.$Progress.start();
                await this.requestEntitiesByType({ entityType: ENTITY_TYPES.CATEGORY });
                this.$Progress.finish();
            } catch (error) {
                this.$Progress.fail();
                Sentry.captureException(error);
                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('alertMessage.errorDoingSmthTryAgain', {
                        action: 'fetching',
                        entityName: ENTITY_TYPES.CATEGORY,
                    }),
                });
            }
        },
        async fetchEntities() {
            try {
                this.$Progress.start();
                await this.requestEntitiesByType({ entityType: this.entityType });
                this.$Progress.finish();
            } catch (error) {
                this.$Progress.fail();
                Sentry.captureException(error);
                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('alertMessage.errorDoingSmthTryAgain', {
                        action: 'fetching',
                        entityName: this.entityType,
                    }),
                });
            }
        },
        async fetchTemplates() {
            try {
                this.$Progress.start();
                await this.requestAllTemplates();
                await this.requestTemplatesByType({ entityType: TEMPLATE_TYPES_MAP.PRESET });
                this.$Progress.finish();
            } catch (error) {
                this.$Progress.fail();
                Sentry.captureException(error);
                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('alertMessage.errorDoingSmthTryAgain', {
                        action: 'fetching',
                        entityName: this.entityType,
                    }),
                });
            }
        },
        onPreSave() {
            // prevent sending requests until the first one is resolved
            if (this.isPreSaveButtonClicked) {
                return;
            }

            this.isPreSaveButtonClicked = true;

            if (this.editProperties && this.isEditingOfferEntityType && this.isBulkeditCoreFieldsEnabled) {
                // triggering validation within the OfferCoreData component
                this.$refs.offerCoreDataComponent.validateOfferData();
                if (!this.isOfferCoreDataValid) {
                    this.isPreSaveButtonClicked = false;
                    return;
                }
            }

            const confirmationButton = new Button({
                label: this.$i18n.t('productCatalog.bulkEditor.confirmBulkEdit'),
                dataTestId: 'confirm-bulk-edit-button',
                alertType: ALERT_TYPES.warning,
            });
            this.$eventBus.$emit('showAlert', {
                message: this.$i18n.t('productCatalog.bulkEditor.confirmSubmission'),
                type: ALERT_TYPES.warning,
                buttons: [confirmationButton],
            });
            this.$eventBus.$off('buttonClicked');
            this.$eventBus.$once('alertClosed', () => {
                this.isPreSaveButtonClicked = false;
            });
            this.$eventBus.$once('buttonClicked', () => {
                this.save();
            });
        },
        async save() {
            try {
                if (!this.editCategories && !this.editState && !this.editProperties) {
                    this.$eventBus.$emit('showAlert', {
                        message: this.$i18n.t('productCatalog.bulkEditor.mustSelectAction', {
                            action: 'saving',
                            entityName: 'bulk',
                        }),
                    });
                    return;
                }

                if (this.editState && this.entityData.state == null) {
                    this.$eventBus.$emit('showAlert', {
                        message: this.$i18n.t('productCatalog.bulkEditor.mustSelectState', {
                            action: 'saving',
                            entityName: 'bulk',
                        }),
                    });
                    return;
                }
            } finally {
                this.isPreSaveButtonClicked = false;
            }

            try {
                this.$Progress.start();

                let bulkChangeData = {
                    entity_type: this.entityType,
                    entity_ids: this.bulkEditIds,
                };

                if (this.editCategories) {
                    bulkChangeData = {
                        ...bulkChangeData,
                        data: {
                            categories: this.entityData.categories,
                        },
                    };
                }

                if (this.editState) {
                    bulkChangeData = {
                        ...bulkChangeData,
                        state: this.entityData.state,
                    };
                }

                if (this.editProperties) {
                    // template fields handling
                    let propertiesToSend = Object.entries(this.updatedProperties).reduce((accum, [key, value]) => {
                        if (this.selectedPropertiesIds.includes(key)) {
                            if (Array.isArray(value)) {
                                if (value[0].id) {
                                    // refference property type formatting
                                    accum[key] = value.map(val => val.id);
                                }
                                // multiple dropdown property type handling
                                accum[key] = value;
                            } else if (typeof value === 'object') {
                                // json property type formatting
                                accum[key] = value || null;
                            } else {
                                // other properties don't need formatting
                                accum[key] = value;
                            }
                        }
                        return accum;
                    }, {});

                    // SPECIAL HANDLING FOR WIDGETS

                    if (propertiesToSend[FORM_BUILDER_TYPES.PAYOUT_LIMIT]) {
                        const copyOfPayoutLimit = [...propertiesToSend[FORM_BUILDER_TYPES.PAYOUT_LIMITS]];
                        delete propertiesToSend[FORM_BUILDER_TYPES.PAYOUT_LIMITS];
                        // adding Payout Limit data to the payload
                        propertiesToSend = { ...propertiesToSend, ...copyOfPayoutLimit };
                    }

                    if (propertiesToSend[FORM_BUILDER_TYPES.PURCHASE_LIMIT]) {
                        const copyOfPurchaseLimitData = { ...propertiesToSend[FORM_BUILDER_TYPES.PURCHASE_LIMIT] };
                        delete propertiesToSend[FORM_BUILDER_TYPES.PURCHASE_LIMIT];
                        // adding all the PurchasLimit related data to the payload
                        propertiesToSend = { ...propertiesToSend, ...copyOfPurchaseLimitData };
                    }

                    if (propertiesToSend[FORM_BUILDER_TYPES.SUBSCRIPTION_LIMIT]) {
                        const copyOfSubLimit = { ...propertiesToSend[FORM_BUILDER_TYPES.SUBSCRIPTION_LIMIT] };
                        delete propertiesToSend[FORM_BUILDER_TYPES.SUBSCRIPTION_LIMIT];
                        // binding to a proper key accepted on the BE
                        propertiesToSend.max_subscription_per_target = copyOfSubLimit;
                    }

                    if (propertiesToSend[FORM_BUILDER_TYPES.COHORTS_EXPRESSION]) {
                        const copyOfCohort = { ...propertiesToSend[FORM_BUILDER_TYPES.COHORTS_EXPRESSION] };
                        delete propertiesToSend[FORM_BUILDER_TYPES.COHORTS_EXPRESSION];
                        // binding to a proper key accepted on the BE
                        propertiesToSend.cohort_expression = copyOfCohort;
                    }

                    /// END OF SPECIAL HANDLING FOR WIDGETS

                    // core fields handling
                    if (this.isBulkeditCoreFieldsEnabled) {
                        // formatting data which is send to the toJson method based
                        // on the selected ones
                        if (this.isEditingOfferEntityType) {
                            const dataForToJson = {};

                            if (this.selectedCoreProperties.includes(CORE_PROPERTIES_FE.TIME)) {
                                dataForToJson.startTime = this.offerCoreData.startTime;
                                dataForToJson.endTime = this.offerCoreData.endTime;
                                dataForToJson.noEndTime = this.offerCoreData.noEndTime;
                            }

                            if (this.selectedCoreProperties.includes(CORE_PROPERTIES_FE.PRICE)) {
                                dataForToJson.optionsArray = this.offerCoreData.optionsArray;
                                dataForToJson.price = this.offerCoreData.price;
                                dataForToJson.selectedCurrency = this.offerCoreData.selectedCurrency;
                                dataForToJson.selectedPriceType = this.offerCoreData.selectedPriceType;
                            }

                            propertiesToSend = {
                                ...propertiesToSend,
                                ...(this.offerCoreData && ProductCatalogOfferModel.toJson(dataForToJson, true)),
                            };
                        }

                        if (this.selectedCoreProperties.includes(CORE_PROPERTIES_FE.NAME)) {
                            propertiesToSend = {
                                ...propertiesToSend,
                                name: this.entityData.name,
                            };
                        } else {
                            // it's being filled in the toJson, so we needt to remove for bulk case
                            delete propertiesToSend.name;
                        }

                        if (this.selectedCoreProperties.includes(CORE_PROPERTIES_FE.DESCRIPTION)) {
                            propertiesToSend = {
                                ...propertiesToSend,
                                description: this.entityData.description,
                            };
                        } else {
                            // it's being filled in the toJson, so we needt to remove for bulk case
                            delete propertiesToSend.description;
                        }
                    }

                    bulkChangeData = {
                        ...bulkChangeData,
                        data: propertiesToSend,
                    };
                }

                const bulkChangeResponse = await submitBulkChange(bulkChangeData);
                bulkChangeData.jobId = bulkChangeResponse.data.job_id;

                this.setBulkEditChangeData({
                    entityType: this.entityType,
                    changeData: bulkChangeData,
                });

                this.setBulkCompletedStatus({
                    entityType: this.entityType,
                    changeData: null,
                });

                this.$Progress.finish();
                this.entityEditorMixin.successfullySaved = true;
                this.$router.push({
                    name: RouteNames.BULK_EDIT_FEEDBACK,
                    params: { companyId: this.$route.params.companyId },
                });
            } catch (e) {
                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('alertMessage.errorDoingSmthTryAgain', {
                        action: 'saving',
                        entityName: 'bulk',
                    }),
                });
                Sentry.captureException(e);
                this.$Progress.fail();
                this.isPreSaveButtonClicked = false;
            }
        },
        async setCommonTemplate() {
            this.templateToEdit = await cloneDeep(
                this.getTemplateByTypeAndId(this.entityType, this.allEditEntities[0]?.templateId),
            );
        },
        async setFormBuilderData() {
            if (this.templateToEdit?.groups?.length || this.templateToEdit?.presetTemplates?.length) {
                const presetAndRegularGroups = this.templateToEdit.groups;
                const presetAndRegularProperties = this.templateToEdit.properties;
                const groupsFormatted = [];

                if (this.templateToEdit?.presetTemplates.length) {
                    const presetTemplates = this.templateToEdit.presetTemplates.reduce((acc, presetTemplate) => {
                        const presetTemplateData = this.getTemplateByTypeAndId(
                            TEMPLATE_TYPES_MAP.PRESET,
                            presetTemplate.id,
                        );

                        const widgetIds = Object.keys(WIDGETS);

                        if (widgetIds.includes(presetTemplateData.externalId)) {
                            const widgetData = WIDGETS[presetTemplateData.externalId];

                            acc.push({
                                groups: presetTemplateData.groups,
                                properties: [
                                    {
                                        id: presetTemplateData.externalId,
                                        name: widgetData.name,
                                        dataType: widgetData.type,
                                        groupId: presetTemplateData.groups[0].id,
                                    },
                                ],
                            });
                        } else {
                            acc.push(presetTemplateData);
                        }

                        return acc;
                    }, []);

                    const presetTemplatesProperties = presetTemplates.length
                        ? presetTemplates.flatMap(pt => pt.properties)
                        : [];

                    const presetTemplatesGroups = presetTemplates.length
                        ? presetTemplates.flatMap(pt => pt.groups)
                        : [];

                    presetTemplatesGroups.forEach(presetGroup => {
                        presetAndRegularGroups.push(presetGroup);
                    });

                    presetTemplatesProperties.forEach(presetProperty => {
                        presetAndRegularProperties.push(presetProperty);
                    });
                }

                // mapping groups for render
                presetAndRegularGroups.forEach((group, index) => {
                    groupsFormatted.push({
                        id: group.id,
                        name: group.name,
                        sortWeight: index,
                        // type,
                        fields: [],
                    });
                });

                presetAndRegularProperties.reduce((acc, prop) => {
                    // Map for formattedGroupsForDnd
                    const groupForProp = groupsFormatted.find(({ id }) => id === prop.groupId.toString());
                    groupForProp.fields.push(prop);

                    acc[prop.id] = {
                        ...prop,
                        isPropSaved: true,
                    };

                    if (this.isDropdownType(prop.dataType)) {
                        const options = acc[prop.id]?.schema?.enum;

                        acc[prop.id].componentProps = {
                            ...acc[prop.id].componentProps,
                            options,
                        };

                        if (prop.dataType === FORM_BUILDER_TYPES.DROPDOWN_MULTIPLE) {
                            acc[prop.id].componentProps.labelFormatter = entity => entity.name;
                        }
                    }
                    return acc;
                }, {});

                for (const groupFormatted of groupsFormatted) {
                    // eslint-disable-next-line no-await-in-loop
                    groupFormatted.fields = await Promise.all(
                        groupFormatted.fields.map(field => ProductCatalogEntityTemplate.formatProperty(field)),
                    );
                }
                this.formmatedGroupsForDisaplay = groupsFormatted.sort((a, b) => a.sortWeight - b.sortWeight);
            } else {
                this.formmatedGroupsForDisaplay = [];
            }
        },
        onGroupGenInput(val) {
            Object.values(val).forEach(groupData => {
                const propIdToValue = groupData?.groupValue?.value;
                if (propIdToValue) {
                    this.updatedProperties = {
                        ...this.updatedProperties,
                        ...propIdToValue,
                    };
                }
            });
        },
        onPropertySelection(selectedPropertiesIds) {
            this.selectedPropertiesIds = selectedPropertiesIds;
        },
        onRemoveEntity(entityId) {
            const resultArrayOfIDs = this.bulkEditIds.filter(id => id !== entityId);
            this.setBulkEntityIDs({
                entityType: this.entityType,
                entityIds: resultArrayOfIDs,
            });
        },
        onCorePropertySelected(propertyId) {
            const correspondingIndex = this.selectedCoreProperties.findIndex(prop => prop === propertyId);
            if (correspondingIndex === -1) {
                this.selectedCoreProperties.push(propertyId);
            } else {
                this.selectedCoreProperties.splice(correspondingIndex, 1);
            }
        },
        findSelectedProperty(propertyId) {
            return Boolean(this.selectedCoreProperties.find(prop => prop === propertyId));
        },
        onCoreOfferDataValidated(isValid) {
            this.isOfferCoreDataValid = isValid;
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/base';
@import '~@/assets/scss/forms';
@import '~@/assets/scss/palette';
@import '~@/assets/scss/inputs';
@import '~@/assets/scss/animations';
@import '~@/assets/scss/editor-layout-v2';
@import '~@/assets/scss/typographyV2';

.summary-table {
    height: 40%;
}
</style>
