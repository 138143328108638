
import AppTable from '@/components/partials/AppTable.vue';
import TableFiltersRenderless from '@/components/filters/TableFiltersRenderless.vue';
import tableColumnType, { type TableColumn } from '@/common/filterTable';
import notesHTTP from '@/__new__/services/dno/crm/http/crm';
import { USER_MANAGER_HIERARCHY } from '@/__new__/features/customerCare/common/customerCareHelper';

export default {
    name: 'ManualSeedingNotes',
    components: {
        AppTable,
        TableFiltersRenderless,
    },
    data() {
        return {
            notes: [],
            isDataLoading: true,
            searchQueryHasAccessTable: '',
            enableRowStateControls: true,
        };
    },
    computed: {
        tableMandualSeedNotesColumnsData(): TableColumn[] {
            return [
                {
                    name: this.$i18n.t('generic.crmUserId'),
                    key: 'crmUserId',
                    field: 'crmUserId',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('generic.timestamp'),
                    key: 'timestamp',
                    field: 'timestamp',
                    filterType: tableColumnType.DATETIME,
                },
                {
                    name: this.$i18n.t('customerCare.account.manualSeed.reason'),
                    key: 'note',
                    field: 'note',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('customerCare.account.manualSeed.orderId'),
                    key: 'orderId',
                    field: 'orderId',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
            ];
        },
    },
    created() {
        this.fetchManualSeedNotes();
    },
    methods: {
        async fetchManualSeedNotes(): void {
            const noteId = `${this.$route.params.id}-manualseeding`;
            const result = await notesHTTP.getNotes(noteId, USER_MANAGER_HIERARCHY.ACCOUNT);
            this.isDataLoading = false;
            if (Array.isArray(result?.data)) {
                this.notes = result.data.flat(2).map(note => ({
                    crmUserId: note.crm_user_id,
                    timestamp: note.epoch,
                    note: note.note.en,
                    orderId: note.tags.toString(),
                }));
            }
        },
    },
};
