<!--TODO: add tests and TS to this file CEP-5815-->
<template>
    <div>
        <div
            v-t="$t('sinkConfigs.cohorts.title')"
            class="editor-section-heading"
        />
        <AppMultiselectV3
            :value="cohortsData.entryType"
            :options="entryTypeOptions"
            :additionalLabel="$t('segments.segmentType')"
            :placeholder="$t('segments.segmentType')"
            :small="true"
            trackBy="id"
            label="label"
            :allowEmpty="false"
            class="my-3"
            @input="entryTypeChange"
        />
        <CohortExpression
            :customCohortGroupsOptions="cohortOptions"
            :customCohortGroupsOptionsBL="cohortOptions"
            :value="cohortsData.cohortsObj"
            :disabled="isEmpty(cohortsData.entryType) || isEmpty(cohortOptions)"
            class="cohort-expression mb-4"
            data-test-id="cohort-expression"
            :inputsWidth="100"
            @input="cohortsObj => emitInput(cohortsData.entryType, cohortsData.identifierField, cohortsObj)"
        />
        <AppMultiselectV3
            :value="cohortsData.identifierField"
            :options="eventPropertiesNames"
            :additionalLabel="$t('sinkConfigs.cohorts.matchByField')"
            :placeholder="$t('sinkConfigs.cohorts.matchByField')"
            :explanationText="$t('sinkConfigs.cohorts.matchByFieldLabel')"
            :small="true"
            :tooltipOffset="30"
            class="my-3"
            @input="identifierField => emitInput(cohortsData.entryType, identifierField, cohortsData.cohortsObj)"
        />
    </div>
</template>
<script>
// components
import CohortExpression from '@/components/cohortExpression/CohortExpression.vue';
import AppMultiselectV3 from '@/components/partials/inputs/AppMultiselectV3.vue';
// helpers
import { SEGMENT_ID_TYPES, StaticFilterLabels } from '@/common/StaticFilter';
import { isViewEnabled } from '@/services/permissions/permissions.service';
import { intersection, isEmpty } from 'lodash';
import { mapGetters } from 'vuex';
import { Modules } from '@/store/store';
import { Getters } from '@/store/mutation-types';
import { groupSegments } from '@/common/cohortExpressionHelper';
import { CDP_PROPERTY_TYPES } from '@/__new__/services/dno/events/models/EventProp';

export default {
    components: { AppMultiselectV3, CohortExpression },
    props: {
        cohortsData: {
            type: Object,
            default() {
                return {};
            },
        },
        triggerRelatedEvent: {
            type: Object,
            default() {
                return {};
            },
        },
    },
    computed: {
        ...mapGetters(Modules.segments, {
            groupedSegments: Getters.GROUPED_SEGMENTS_BY_ID_TYPE,
            cachedSegments: Getters.CACHED_SEGMENTS,
        }),
        eventPropertiesNames() {
            return this.triggerRelatedEvent.properties
                ?.filter(p => p.type === CDP_PROPERTY_TYPES.STRING)
                .map(p => p.name);
        },
        entryTypeOptions() {
            const viewConfigEnabledTypes = Object.keys(SEGMENT_ID_TYPES)
                .filter(
                    type =>
                        isViewEnabled(`StaticSegmentsType${type}`) ||
                        isViewEnabled(`TriggerBasedSegmentsType${type}`) ||
                        isViewEnabled(`DynamicSegmentsType$${type}`),
                )
                .map(type => SEGMENT_ID_TYPES[type]);
            const finalTypes = intersection(intersection(Object.values(SEGMENT_ID_TYPES), viewConfigEnabledTypes));

            return finalTypes.map(id => ({ id, label: StaticFilterLabels[id] }));
        },
        cohortOptions() {
            const filteredSegmentsByEntryType = this.cachedSegments.filter(
                segment => this.cohortsData.entryType.id === segment.segmentIdType,
            );
            return filteredSegmentsByEntryType.length ? groupSegments(filteredSegmentsByEntryType) : null;
        },
    },
    methods: {
        isEmpty,
        entryTypeChange(entryType) {
            // reset w-list\b-list cohorts data on entry type change
            this.emitInput(entryType, this.cohortsData.identifierField, {
                applicableCohorts: [],
                notApplicableCohorts: [],
                everyApplicableCohort: false,
                everyNotApplicableCohort: false,
            });
        },
        emitInput(entryType, identifierField, cohortsObj) {
            this.$emit('cohortsDataChange', {
                entryType,
                identifierField,
                cohortsObj,
            });
        },
    },
};
</script>
