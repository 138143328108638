
import { ALERT_TYPES } from '@/common/alerts/Alert';
import * as Sentry from '@sentry/vue';
import supportButtonMixin from '@/components/alerts/supportButtonMixin';
import tableColumnType from '@/common/filterTable';
import permissionsService, { isUserAllowed } from '@/services/permissions/permissions.service';
import { mapActions, mapGetters } from 'vuex';
import Actions, { Getters } from '@/store/mutation-types';

/* eslint-disable camelcase */
interface MappedExecution {
    mapper_ids: string[];
    plan_ids?: string[];
}
/* eslint-enable camelcase */

export default {
    name: 'OePlansMixin',
    mixins: [supportButtonMixin],
    data() {
        return {
            oePlansEnabled: permissionsService.accountOEPlansEnabled() && isUserAllowed('UMAccountOrderDetailsOEPlans'),
            oeMappingsList: [],
            oeMappingsApiResponse: null,
            isOeLoading: false,
        };
    },
    computed: {
        ...mapGetters('orchestrationengine', {
            getOEMappedExecutions: Getters.GET_OE_MAPPED_EXECUTIONS,
            getOEMappedExecutionsApiResponse: Getters.GET_OE_MAPPED_EXECUTIONS_API_RESPONSE,
        }),
        oeColumnsData(): any[] {
            return [
                {
                    name: this.$i18n.t('customerCare.orderHistory.planId'),
                    key: 'planId',
                    forbidHideColumn: true,
                    field: 'planId',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('customerCare.orderHistory.executionId'),
                    key: 'executionId',
                    forbidHideColumn: true,
                    field: 'executionId',
                    filterType: tableColumnType.GENERAL_TEXT,
                    width: 20,
                },
                {
                    name: this.$i18n.t('orchestrationEngine.startTimestamp'),
                    key: 'startedAtFormatted',
                    forbidHideColumn: true,
                    sortBy: cycle => cycle.startedAt,
                    field: 'startedAt',
                    filterType: tableColumnType.DATE,
                },
                {
                    name: this.$i18n.t('orchestrationEngine.endTimestamp'),
                    key: 'finishedAtFormatted',
                    forbidHideColumn: true,
                    sortBy: cycle => cycle.finishedAt,
                    field: 'finishedAt',
                    filterType: tableColumnType.DATE,
                },
                {
                    name: this.$i18n.t('orchestrationEngine.scheduledAt'),
                    key: 'scheduledAtFormatted',
                    field: 'scheduledAt',
                    sortBy: entity => entity.scheduledAt,
                    filterType: tableColumnType.DATETIME,
                },
                {
                    name: this.$i18n.t('orchestrationEngine.scheduledFor'),
                    key: 'scheduledForFormatted',
                    field: 'scheduledFor',
                    sortBy: entity => entity.scheduledFor,
                    filterType: tableColumnType.DATETIME,
                },
                {
                    name: this.$i18n.t('generic.status'),
                    key: 'status',
                    forbidHideColumn: true,
                    field: 'status',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
            ];
        },
    },
    methods: {
        ...mapActions('orchestrationengine', {
            requestOEMappedExecutions: Actions.REQUEST_OE_MAPPED_EXECUTIONS,
        }),
        // expected format: { mapper_ids, plan_ids }
        async fetchExecutions(data: MappedExecution): Promise<any> {
            try {
                this.isOeLoading = true;
                await this.requestOEMappedExecutions(data);
                this.oeMappingsList = this.getOEMappedExecutions;
                this.oeMappingsApiResponse = this.getOEMappedExecutionsApiResponse;
            } catch (error: any) {
                this.showSupportAlert(this.$i18n.t('alertMessage.somethingWentWrong'), ALERT_TYPES.error);
                Sentry.captureException(error);
                this.oeMappingsApiResponse = error.response;
            } finally {
                this.isOeLoading = false;
            }
        },
    },
};
