import http from '@/http/index';
import { getDnoClaimsHeader } from '@/__new__/features/customerCare/common/customerCareHelper';

/**
 *
 * @param {string} id
 * @param {number} targetType - Account only!
 * @returns {AxiosPromise}
 */
const fetchAllPaymentMethods = (id: string, targetType: string) =>
    http({
        method: 'POST',
        url: '/v3payment/fetchallpaymentmethods',
        data: {
            target_id: id,
            target_type: targetType,
        },
    });

/**
 * @param data
 */
const getTransactionHistory = (data: {
    target_id: string;
    target_type: number;
    filters?: any[];
    include_payment_items?: boolean;
    include_tax?: boolean;
}) =>
    http({
        method: 'POST',
        url: '/v3payment/gettransactionhistory',
        headers: {
            'Dno-Claims': JSON.stringify(getDnoClaimsHeader(data.target_id, data.target_type)),
        },
        data,
    });

export default {
    fetchAllPaymentMethods,
    getTransactionHistory,
};
