import { DEVICE_INFO_TYPE } from '@/__new__/features/customerCare/common/deviceInfoHelper';

export type DeviceInfoFromBE = {
    device_type: DEVICE_INFO_TYPE;
    serial_number: string;
    mac_address?: string;
    model?: string;
    static_ip_addresses?: {
        protocol: string;
        ip_address: string;
    }[];
};

export default class DeviceInfo {
    deviceType: DEVICE_INFO_TYPE;
    serialNumber: string;
    macAddress?: string;
    model?: string;
    staticIpAddresses?: {
        protocol: string;
        ip_address: string;
    }[];

    constructor(deviceInfoData: DeviceInfoFromBE) {
        this.deviceType = deviceInfoData.device_type;
        this.serialNumber = deviceInfoData.serial_number;
        this.macAddress = deviceInfoData.mac_address;
        this.model = deviceInfoData.model;
        this.staticIpAddresses = deviceInfoData?.static_ip_addresses || [];
    }
}
