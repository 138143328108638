<template>
    <div class="trigger-based-segment-filters-container">
        <div class="form-subtitle">
            {{ title }}
        </div>

        <Filters
            data-test-id="filtersBlock"
            :stats="[]"
            :selectFilterInvalid="selectFilterInvalid"
            :showStats="false"
            topLevelCombinator="or"
            entityType="Trigger"
            :isDisabled="disabled"
            hideHeader
            :conditionInstancesJson="conditionInstancesJson"
            :conditionDefinitionsById="conditionDefinitionsById"
            @updatedConditionInstancesJson="updatedConditionInstancesJson"
            @onConditionsValidationError="onConditionsValidationError"
        />
    </div>
</template>

<script>
import Filters from '@/components/partials/filters/Filters.vue';

export default {
    name: 'SegmentRealtimeFiltersComponent',
    components: {
        Filters,
    },
    props: {
        title: {
            type: String,
            default: '',
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        selectFilterInvalid: { type: Boolean, default: false },
        conditionInstancesJson: { type: Array, default: () => [] },
        conditionDefinitionsById: { type: Object, default: () => ({}) },
    },
    methods: {
        updatedConditionInstancesJson(...args) {
            this.$emit('updatedConditionInstancesJson', ...args);
        },
        onConditionsValidationError(...args) {
            this.$emit('onConditionsValidationError', ...args);
        },
    },
};
</script>

<style scoped>
.trigger-based-segment-filters-container .form-subtitle {
    margin-bottom: -1em;
}
</style>
