// Generic
import * as Sentry from '@sentry/vue';
import { Module, ActionContext } from 'vuex';
import Actions, { State, Getters, Mutations } from '@/store/mutation-types';

// Http
import { getDataflowTemplates, getDataflowTemplateUiModel } from '@/__new__/services/dno/dataflows/http/dataflows';
import { DataflowTemplate, DataflowTemplateUiModel } from '@/__new__/features/dataflows/common/DataflowInterfaces';

interface DataflowsState {
    [State.DATAFLOW_TEMPLATES]: DataflowTemplate[];
    [State.DATAFLOW_TEMPLATE_UI_MODEL]: DataflowTemplateUiModel[];
}

const store: Module<DataflowsState, unknown> = {
    namespaced: true,
    state: {
        [State.DATAFLOW_TEMPLATES]: [],
        [State.DATAFLOW_TEMPLATE_UI_MODEL]: [],
    },
    mutations: {
        [Mutations.SET_DATAFLOW_TEMPLATES]: (state: DataflowsState, response: DataflowTemplate[]): void => {
            state[State.DATAFLOW_TEMPLATES] = response;
        },
        [Mutations.SET_DATAFLOW_TEMPLATE_UI_MODEL]: (
            state: DataflowsState,
            response: DataflowTemplateUiModel[],
        ): void => {
            state[State.DATAFLOW_TEMPLATE_UI_MODEL] = response;
        },
    },
    actions: {
        async [Actions.REQUEST_DATAFLOW_TEMPLATES](context: ActionContext<DataflowsState, unknown>): Promise<void> {
            try {
                const response = await getDataflowTemplates();
                context.commit(Mutations.SET_DATAFLOW_TEMPLATES, response.data.data);
            } catch (e: any) {
                Sentry.captureException(e);
                throw e;
            }
        },
        async [Actions.REQUEST_DATAFLOW_TEMPLATE_UI_MODEL](
            context: ActionContext<DataflowsState, unknown>,
            dataflowTemplateId: string,
        ): Promise<void> {
            try {
                const response = await getDataflowTemplateUiModel(dataflowTemplateId);
                const { ui_model: uiModel = [] }: DataflowTemplateUiModel = response.data?.data;
                context.commit(Mutations.SET_DATAFLOW_TEMPLATE_UI_MODEL, uiModel);
            } catch (e: any) {
                Sentry.captureException(e);
                throw e;
            }
        },
    },
    getters: {
        [Getters.GET_DATAFLOW_TEMPLATES]: (state: DataflowsState): DataflowTemplate[] =>
            state[State.DATAFLOW_TEMPLATES] || [],
        [Getters.GET_DATAFLOW_TEMPLATE_UI_MODEL]: (state: DataflowsState): DataflowTemplateUiModel[] =>
            state[State.DATAFLOW_TEMPLATE_UI_MODEL] || [],
    },
};

export default store;
