export enum GOR_PAYMENT_STATUS {
    PAYMENT_SUCCESS = 1,
    REFUNDING = 2,
    REFUND_FAILURE = 3,
    REFUND_TIMEOUT = 4,
    REFUNDED = 5,
}

export const GOR_PAYMENT_STATUS_MAP: Record<GOR_PAYMENT_STATUS, string> = {
    [GOR_PAYMENT_STATUS.PAYMENT_SUCCESS]: 'PAYMENT SUCCESS',
    [GOR_PAYMENT_STATUS.REFUNDING]: 'REFUNDING',
    [GOR_PAYMENT_STATUS.REFUND_FAILURE]: 'REFUND FAILURE',
    [GOR_PAYMENT_STATUS.REFUND_TIMEOUT]: 'REFUND TIMEOUT',
    [GOR_PAYMENT_STATUS.REFUNDED]: 'REFUNDED',
};

export const GOR_METHOD_MAP: {
    [key: number]: string;
} = {
    1: 'GCASH',
    2: 'DROPIN',
    3: 'GCASH_MINI',
    4: 'XENDIT_EWALLET_PAYMAYA',
    5: 'XENDIT_EWALLET_SHOPEEPAY',
    6: 'XENDIT_EWALLET_GRABPAY',
    7: 'XENDIT_CC_DC',
    8: 'XENDIT_DIRECT_DEBIT_BPI',
    9: 'XENDIT_DIRECT_DEBIT_UBP',
    10: 'XENDIT_DIRECT_DEBIT_RCBC',
};
