import http from './index';
import { getDnoClaimsHeader } from '@/__new__/features/customerCare/common/customerCareHelper';
/**
 * Refunds/Voids user's transaction for the specified amount
 *
 * @param id
 * @param targetType
 * @param transactionId
 * @param transactionType
 * @param amount
 * @returns {AxiosPromise}
 */
const refundOrCancel = (
    id: string,
    targetType: number,
    transactionId: string,
    transactionType: number,
    amount: string,
) =>
    http({
        method: 'POST',
        url: '/v3refundwrite/refundorcancel',
        headers: {
            'Dno-Claims': JSON.stringify(getDnoClaimsHeader(id, targetType)),
        },
        data: {
            target_id: id,
            target_type: targetType,
            transaction_id: transactionId,
            amount,
            transaction_type: transactionType,
        },
    });

export default {
    refundOrCancel,
};
