
import Vue, { type PropType } from 'vue';

// components
import AppAditionalSidebar from '@/components/partials/AppAditionalSidebar.vue';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import AppHeaderV2 from '@/components/layout/AppHeaderV2.vue';
import AppOverviewBlock from '@/components/partials/AppOverviewBlock.vue';
import BulkUploadDialog, { type UploadColumnLegend } from '@/__new__/features/resources/BulkUploadDialog.vue';
import BulkUploadHistory from '@/__new__/features/resources/BulkUploadHistory.vue';
import BulkUploadTabs, { type Tab } from '@/__new__/features/resources/BulkUploadTabs.vue';
import FilterTable from '@/components/partials/FilterTable.vue';
import TableFiltersTags from '@/components/filters/TableFiltersTags.vue';
import { type FileListItem } from '@/components/partials/inputs/FileUploaderV2.vue';

// mixin
import FilterTableMixin from '@/components/partials/FilterTableMixin.vue';

// helpers
import { ICON_TYPES } from '@/common/iconHelper';
import { isUserAllowed } from '@/services/permissions/permissions.service';
import type { TableColumn, TableSortFilter, Filter } from '@/common/filterTable';
import type { CollapsibleListItem } from '@/common/AppCollapsibleListHelper';

type Entity = Record<string, any>;
type BulkUploadTableColumns = TableColumn[] | Record<Tab['id'], TableColumn[]>;
type BulkUploadTableEntities = Entity[] | Record<Tab['id'], Entity[]>;

interface CustomFileValidation {
    (file: FileListItem, meta: { resourceType: string }): boolean;
}

export default Vue.extend({
    name: 'BulkUploadPageLayout',
    components: {
        AppAditionalSidebar,
        AppButton,
        AppHeaderV2,
        AppOverviewBlock,
        BulkUploadDialog,
        BulkUploadHistory,
        BulkUploadTabs,
        FilterTable,
        TableFiltersTags,
    },
    mixins: [FilterTableMixin],
    props: {
        pageTitle: {
            type: String,
            required: true,
        },
        // Used when all tabs have same dataflow_id or when single tab present(default)
        dataflow: {
            type: String,
            default: '',
        },
        tabs: {
            type: Array as PropType<Tab[]>,
            default: () => [],
        },
        // Table data
        tableColumns: {
            type: [Array, Object] as PropType<BulkUploadTableColumns>,
            default: () => [],
        },
        entities: {
            type: [Array, Object] as PropType<BulkUploadTableEntities>,
            default: () => [],
        },
        sortBy: {
            type: Object as PropType<TableSortFilter | undefined>,
            default: undefined,
        },
        mapSidebarData: {
            type: Function as PropType<(entity: any, tabId?: Tab['id']) => CollapsibleListItem[]>,
            default: undefined,
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
        searchEnabled: {
            type: Boolean,
            default: true,
        },
        // Upload
        uploadPermission: {
            type: [String, Array] as PropType<string | string[]>,
            default: '',
        },
        historyPermission: {
            type: [String, Array] as PropType<string | string[]>,
            default: '',
        },
        uploadBtnLabel: {
            type: String,
            default: '',
        },
        uploadColumns: {
            type: Array as PropType<UploadColumnLegend[]>,
            default: () => [],
        },
        uploadMetadata: {
            type: Object as PropType<Record<string, any> | undefined>,
            default: undefined,
        },
        uploadTitle: {
            type: String,
            default: '',
        },
        uploadDescription: {
            type: String,
            default: '',
        },
        customFileValidation: {
            type: Function as PropType<CustomFileValidation>,
            default: null,
        },
        multiple: {
            type: Boolean,
            default: true,
        },
        acceptType: {
            type: String,
            default: undefined,
        },
    },
    data() {
        return {
            BUTTON_TYPES,
            ICON_TYPES,
            currentSlot: 'default' as Tab['id'] | 'default',
            searchQueryForTable: '',
            selectedEntity: undefined as Entity | undefined,
            // tabs
            tabFilters: {} as Record<Tab['id'], Filter[]>,
            isDialogOpen: false,
            // sidebar
            isSidebarVisible: false,
        };
    },
    computed: {
        dataflowId(): string {
            return this.dataflow || this.tabs.find(({ id }) => id === this.currentSlot)?.dataflow || '';
        },
        columnsData(): TableColumn[] {
            return Array.isArray(this.tableColumns) ? this.tableColumns : this.tableColumns[this.currentSlot] || [];
        },
        entitiesData(): Entity[] {
            return Array.isArray(this.entities) ? this.entities : this.entities[this.currentSlot] || [];
        },
        filteredEntities(): Entity[] {
            return this.filteredEntitiesMixin(this.entitiesData);
        },
        sidebarData(): CollapsibleListItem[] {
            return !!this.mapSidebarData && this.selectedEntity ? this.mapSidebarData(this.selectedEntity) : [];
        },
        isTabsEnabled(): boolean {
            return !!this.tabs.length;
        },
        isUploadAllowed(): boolean {
            if (!this.dataflowId) {
                return false;
            }
            return Array.isArray(this.uploadPermission)
                ? isUserAllowed(...(this.uploadPermission as string[]))
                : isUserAllowed(this.uploadPermission);
        },

        isUploadHistoryAllowed(): boolean {
            if (!this.dataflowId) {
                return false;
            }
            return Array.isArray(this.historyPermission)
                ? isUserAllowed(...(this.historyPermission as string[]))
                : isUserAllowed(this.historyPermission);
        },

        isSidebarOpen(): boolean {
            return !!this.sidebarData.length && this.isSidebarVisible;
        },
    },
    methods: {
        onSearch(query: string): void {
            this.searchQueryForTable = query;
            this.$emit('searchQueryChanged', query);
        },
        onSelectEntity(entity: Entity): void {
            this.isSidebarVisible = true;
            this.selectedEntity = entity;
            this.$emit('rowSelected', entity);
        },
        onFilterAdd(filter: Filter): void {
            this.onFilterAdded(filter);
            if (this.isTabsEnabled) {
                this.tabFilters[this.currentSlot] = this.filterTableMixin?.allFilters || [];
            }
        },
        onTabSelect(id: Tab['id']): void {
            this.isSidebarVisible = false;
            this.filterTableMixin.allFilters = this.tabFilters[this.currentSlot] || [];
            this.$emit('tabSelect', id);
        },
        onSidebarClose(): void {
            this.isSidebarVisible = false;
            this.$emit('sidebarClose');
        },
        toggleUploadDialog(): void {
            if (this.isUploadAllowed) {
                this.isDialogOpen = !this.isDialogOpen;
            }
        },
        onFileUpload(): void {
            this.$emit('fileUpload');
        },
    },
});
