<template>
    <div>
        <div
            v-t="'charging.chargingSpecifications.editor.apn'"
            class="table-title"
        />
        <div class="primary-text">
            <span
                v-for="(apn, index) in value.values"
                :key="index"
            >
                {{ apn }}
            </span>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ApnValue',
    props: {
        value: {
            type: Object,
            required: true,
        },
    },
};
</script>
