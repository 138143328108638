<template>
    <div class="container-page p-0">
        <div>
            <AppHeader
                :pageTitle="$i18n.t('customerCare.breadcrumbs.searchResults')"
                :isSearchEnabled="false"
                class="mb-2"
            />
            <Breadcrumbs
                :breadcrumbList="breadcrumbList"
                class="ml-4"
            />
            <div class="section-layout">
                <AppTable
                    :entities="dataToDisplay"
                    :isDefaultHoverEnabled="true"
                    :isPaginationEnabled="true"
                    :newDesign="true"
                    :columnsData="rolesTableColumnsData"
                    :canSelectColumns="true"
                >
                    <template #userId="{ entity }">
                        <div
                            v-if="entity.userId"
                            class="link"
                            @click="goToRoute(getBreadcrumbPath(USER_MANAGER_HIERARCHY.USER, entity.userId))"
                        >
                            {{ entity.userId }}
                        </div>
                        <i v-else>
                            {{ $i18n.t('generic.empty') }}
                        </i>
                    </template>
                </AppTable>
            </div>
        </div>
        <div class="editor-controls-wrapper">
            <AppButton
                :buttonType="BUTTON_TYPES.PRIMARY"
                :label="$t('generic.cancel')"
                class="mr-4"
                data-test-id="cancel-button"
                @click="onCancel"
            />
        </div>
    </div>
</template>

<script>
// Vue components
import AppHeader from '@/components/layout/AppHeader.vue';
import Breadcrumbs from '@/components/partials/Breadcrumbs.vue';
import AppTable from '@/components/partials/AppTable.vue';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';

// helpers
import tableColumnType from '@/common/filterTable';

import customerCareAjax from '@/__new__/services/dno/user/http/customer-care';
import {
    USER_MANAGER_HIERARCHY,
    handleUserNotFoundError,
    getBreadcrumbPath,
} from '@/__new__/features/customerCare/common/customerCareHelper';

// HTTP
import RouteNames from '@/router/routeNames';

export default {
    name: 'PhonesTable',

    components: {
        AppHeader,
        Breadcrumbs,
        AppTable,
        AppButton,
    },

    data() {
        return {
            BUTTON_TYPES,
            usersPhone: [],
            dataToDisplay: [],
            USER_MANAGER_HIERARCHY,
            getBreadcrumbPath,
            rolesTableColumnsData: [
                {
                    name: this.$i18n.t('customerCare.userInformation.userId'),
                    key: 'userId',
                    field: 'userId',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('customerCare.userInformation.firstName'),
                    key: 'firstName',
                    field: 'firstName',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('customerCare.userInformation.lastName'),
                    key: 'lastName',
                    field: 'lastName',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('customerCare.userInformation.email'),
                    key: 'emailAcc',
                    field: 'emailAcc',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('customerCare.userInformation.phoneNumber'),
                    key: 'phoneNumber',
                    field: 'phoneNumber',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
            ],
        };
    },

    computed: {
        breadcrumbList() {
            return [
                {
                    name: this.$i18n.t('customerCare.breadcrumbs.search'),
                    link: '/customer-care/phone-table',
                },
                {
                    name: this.$i18n.t('customerCare.breadcrumbs.searchResults'),
                },
            ];
        },
    },

    async created() {
        try {
            this.usersPhone = (
                await customerCareAjax.getSearchByIndex(this.$route.params.msisdn, 'phone')
            ).data.search_result.map(user => ({
                ...user,
                userId: user.target_id,
                firstName: user.first_name,
                lastName: user.last_name,
                emailAcc: user.email ? user.email : this.$i18n.t('generic.empty'),
            }));
            this.dataToDisplay = [...this.usersPhone].map(v => ({ ...v, phoneNumber: this.$route.params.msisdn }));
        } catch (error) {
            if (handleUserNotFoundError(error)) {
                await this.$router.push({
                    name: RouteNames.CUSTOMER_CARE_USER_MANAGEMENT,
                    params: { companyId: this.$route.params.companyId },
                });
                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('alertMessage.userManagement.userProfileNotFound'),
                });
            } else {
                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('alertMessage.userManagement.somethingWentWrongFetchingUserProfileData'),
                });
            }
        }
    },

    methods: {
        goToRoute(breadcrumb) {
            this.$router.push({ path: `/${this.$route.params.companyId}${breadcrumb.link}` });
        },
        onCancel() {
            this.$router.push({
                name: RouteNames.CUSTOMER_CARE_USER_MANAGEMENT,
                params: { companyId: this.$route.params.companyId },
            });
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/palette';
@import '~@/assets/scss/layout';
@import '~@/assets/scss/typographyV2';
@import '~@/assets/scss/filter-table';
@import '~@/assets/scss/mixins';

.section-layout {
    padding: 0 1.5rem 1.5rem 1.5rem;
}

.container-page {
    background-color: $dirty-white;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.background-color {
    background-color: $dirty-white;
}

.editor-controls-wrapper {
    padding: 0 1.5rem 1.5rem 1.5rem;
}
</style>
