import { AxiosPromise } from 'axios';
import httpClient from '@/http/index';

/**
 * GET operator preferences.
 * @returns {Promise<void>}
 */
export function getAccountData(): AxiosPromise<object> {
    return httpClient({
        method: 'GET',
        url: '/admin/accountdata',
    });
}

export default {
    getAccountData,
};
