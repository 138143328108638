<template>
    <div class="h-100">
        <AbstractListPageWrapper
            :pageTitle="$i18n.t('customerCare.leads')"
            :entitiesCount="leads.length"
            :isOverviewEnabled="false"
            @searchQueryChanged="setSearchQuery"
        >
            <template slot="filterTable">
                <FilterTable
                    :columns="columnsData"
                    @filterAdded="onFilterAdded"
                />
            </template>

            <template slot="headerButtons">
                <AppButton
                    :buttonType="BUTTON_TYPES.SECONDARY"
                    :iconType="ICON_TYPES.PLUS"
                    :label="'Import Leads'"
                    class="mr-3"
                    @click="isImportDialogVisible = true"
                />
                <AppButton
                    :buttonType="BUTTON_TYPES.PRIMARY"
                    :iconType="ICON_TYPES.PLUS"
                    :label="'New Lead'"
                    @click="openNewLead"
                />
            </template>

            <template slot="allFilters">
                <TableFiltersTags
                    :filterTableMixin="filterTableMixin"
                    class="my-3 ml-2"
                    @removeFilter="removeFilter"
                    @removeAllFilters="removeAllFilters"
                />
            </template>

            <template slot="table">
                <AppTable
                    :entities="sortedFilteredEntities"
                    :isSearchEnabled="true"
                    :isDefaultHoverEnabled="false"
                    :innerSearchQuery="searchQuery"
                    :columnsData="columnsData"
                    tableKey="viasat/leads"
                >
                    <template #customRowButtons="{ entity }">
                        <IconButton
                            :label="$i18n.t('generic.edit')"
                            :icon="ICON_TYPES.EDIT"
                            class="mr-1"
                            @iconClick="openEditLead(entity)"
                        />
                        <IconButton
                            :label="$i18n.t('generic.delete')"
                            :icon="ICON_TYPES.DELETE"
                            class="mr-3"
                            @iconClick="confirmDeleteLead(entity)"
                        />
                    </template>
                </AppTable>
            </template>
        </AbstractListPageWrapper>
        <AppDialog
            :visible="isImportDialogVisible"
            maxWidth="50vw"
            maxHeight="100%"
        >
            <div class="modal-wrapper d-flex flex-column">
                <h2 class="mb-4">Import Leads</h2>
                <AgentNotesDragDropUploader
                    :acceptType="'.csv'"
                    :triggerUploadFiles="uploadInProgress"
                    :uploadSuccessIndex="uploadSuccessIndex"
                    :triggerUploadFailed="uploadFailed"
                />
                <div class="button-wrapper d-flex justify-content-end mt-2">
                    <AppButton
                        :buttonType="BUTTON_TYPES.SECONDARY"
                        :label="'Cancel'"
                        class="mr-3"
                        @click="closeModal"
                    />
                    <AppButton
                        :buttonType="BUTTON_TYPES.PRIMARY"
                        :label="'Import'"
                        @click="closeModal"
                    />
                </div>
            </div>
        </AppDialog>
        <AppDialog
            :visible="isLeadFormDialogVisible"
            maxWidth="50vw"
            maxHeight="100%"
        >
            <div class="modal-wrapper d-flex flex-column">
                <h2 class="mb-4">New Lead</h2>
                <div>
                    <div class="mb-2">
                        <AppInputV3
                            v-model="newLead.firstName"
                            :label="'First name'"
                        />
                    </div>
                    <div class="mb-2">
                        <AppInputV3
                            v-model="newLead.lastName"
                            :label="'Last name'"
                        />
                    </div>
                    <div class="mb-2">
                        <AppInputV3
                            v-model="newLead.email"
                            :label="'Email'"
                        />
                    </div>
                    <div class="mb-2">
                        <AppInputV3
                            v-model="newLead.phone"
                            :label="'Phone'"
                        />
                    </div>
                    <div class="mb-2">
                        <AppInputV3
                            v-model="newLead.stakeholder"
                            :label="'Stakeholder'"
                        />
                    </div>
                    <div class="mb-2">
                        <AppMultiselectV3
                            v-model="newLead.status"
                            :additionalLabel="'Status'"
                            :small="true"
                            :options="leadStatuses"
                        />
                    </div>
                    <div class="mb-2">
                        <AppMultiselectV3
                            v-model="newLead.source"
                            :additionalLabel="'Source'"
                            :small="true"
                            :options="leadSources"
                        />
                    </div>
                    <div class="mb-2">
                        <AppTextareaV3
                            v-model="newLead.notes"
                            :label="'Notes'"
                        />
                    </div>
                    <div class="mb-2">
                        <div class="mb-1">
                            <strong>Tags</strong>
                        </div>
                        <TagsInput
                            :value="newLead.tags.map(t => ({ text: t }))"
                            @input="updateTags"
                        />
                    </div>
                </div>
                <div class="button-wrapper d-flex justify-content-end mt-4">
                    <AppButton
                        :buttonType="BUTTON_TYPES.SECONDARY"
                        :label="'Cancel'"
                        class="mr-3"
                        @click="closeModal"
                    />
                    <AppButton
                        :buttonType="BUTTON_TYPES.PRIMARY"
                        :label="'Save'"
                        @click="saveLead"
                    />
                </div>
            </div>
        </AppDialog>
    </div>
</template>

<script>
// COMPONENTS
import AbstractListPageWrapper from '@/components/layout/AbstractListPageWrapper.vue';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import { ICON_TYPES } from '@/common/iconHelper';
import AgentNotesDragDropUploader from '@/__new__/features/customerCare/AgentNotesDragDropUploader.vue';
import AppTable from '@/components/partials/AppTable.vue';
import FilterTable from '@/components/partials/FilterTable.vue';
import TableFiltersTags from '@/components/filters/TableFiltersTags.vue';
import AppDialog from '@/components/partials/AppDialog.vue';
import AppInputV3 from '@/components/partials/inputs/AppInputV3.vue';
import AppTextareaV3 from '@/components/partials/inputs/AppTextareaV3.vue';
import AppMultiselectV3 from '@/components/partials/inputs/AppMultiselectV3.vue';
import TagsInput from '@/components/partials/inputs/TagsInput.vue';
import IconButton from '@/components/partials/IconButton.vue';

import tableColumnType from '@/common/filterTable';
import FilterTableMixin from '@/components/partials/FilterTableMixin.vue';
import { uuidV4 } from '@/common/utils';
import { getOperatorConfigValue } from '@/services/permissions/permissions.service';
import LocalStorageHelper from '@/common/LocalStorageHelper';
import Button from '@/common/button/Button';
import { ALERT_TYPES } from '@/common/alerts/Alert';

const emptyLead = {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    id: '',
    stakeholder: '',
    status: '',
    dateCreated: '',
    source: '',
    notes: '',
    tags: [],
};

export default {
    name: 'ViasatLeads',
    components: {
        AbstractListPageWrapper,
        AppTable,
        FilterTable,
        AppButton,
        AgentNotesDragDropUploader,
        TableFiltersTags,
        AppDialog,
        AppInputV3,
        AppTextareaV3,
        AppMultiselectV3,
        TagsInput,
        IconButton,
    },
    mixins: [FilterTableMixin],

    data() {
        return {
            ICON_TYPES,
            BUTTON_TYPES,
            defaultLeads: getOperatorConfigValue('viasatLeads', []),
            leads: JSON.parse(LocalStorageHelper.get('viasatLeads')) || [],
            isImportDialogVisible: false,
            isLeadFormDialogVisible: false,
            columnsData: [
                {
                    name: this.$i18n.t('customerCare.userInformation.firstName'),
                    key: 'firstName',
                    field: 'firstName',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('customerCare.userInformation.lastName'),
                    key: 'lastName',
                    field: 'lastName',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('customerCare.userInformation.email'),
                    key: 'email',
                    field: 'email',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: 'Phone',
                    key: 'phone',
                    field: 'phone',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: 'ID',
                    key: 'id',
                    field: 'id',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: 'Stakeholder',
                    key: 'stakeholder',
                    field: 'stakeholder',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: 'Status',
                    key: 'status',
                    field: 'status',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: 'Date created',
                    key: 'dateCreated',
                    field: 'dateCreated',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: 'Source',
                    key: 'source',
                    field: 'source',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: 'Notes',
                    key: 'notes',
                    field: 'notes',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: 'Tags',
                    key: 'tags',
                    field: 'tags',
                    filterType: tableColumnType.GENERAL_TEXT,
                    formatter: tags => tags?.join(', ') || '',
                },
            ],
            searchQuery: '',
            uploadedDocsIds: [],
            uploadInProgress: false,
            uploadFinished: false,
            uploadSuccessIndex: null,
            uploadFailed: false,
            newLead: emptyLead,
            leadStatuses: ['Suspect', 'Prospect', 'Approach', 'Negotiations', 'Close', 'Order'],
            leadSources: ['Website', 'Dealer', 'Field'],
            deleteConfirmationButton: new Button({
                label: this.$i18n.t('generic.delete'),
                alertType: ALERT_TYPES.warning,
            }),
        };
    },
    computed: {
        sortedFilteredEntities() {
            return this.filteredEntitiesMixin(this.leads);
        },
    },
    created() {
        if (this.leads.length === 0) {
            this.leads = this.defaultLeads;
            this.saveLeads();
        }
    },
    methods: {
        setSearchQuery(query) {
            this.searchQuery = query;
        },
        closeModal() {
            this.isImportDialogVisible = false;
            this.isLeadFormDialogVisible = false;
        },
        updateTags(tags) {
            this.newLead.tags = tags.map(t => t.text);
        },
        saveLead() {
            if (this.newLead.id) {
                // Todo: refactor to remove empty method
                // this.updateLead();
            } else {
                this.addLead();
            }

            this.saveLeads();

            this.closeModal();
            this.newLead = emptyLead;
        },
        // Todo: refactor to remove empty method
        // updateLead() {

        // },
        addLead() {
            this.newLead.id = uuidV4().substring(2, 18);
            this.newLead.dateCreated = new Date().toISOString().substring(0, 10);

            this.leads.push(this.newLead);
        },
        saveLeads() {
            LocalStorageHelper.set('viasatLeads', JSON.stringify(this.leads));
        },
        openNewLead() {
            this.newLead = emptyLead;
            this.isLeadFormDialogVisible = true;
        },
        openEditLead(lead) {
            const leadToEdit = this.leads.find(l => l.id === lead.id);
            this.newLead = leadToEdit;
            this.isLeadFormDialogVisible = true;
        },
        confirmDeleteLead(entity) {
            this.$eventBus.$emit('showAlert', {
                message: this.$i18n.t('alerts.areYouSureDeleteEntity', {
                    entityName: `${entity.firstName} ${entity.lastName}`,
                }),
                type: ALERT_TYPES.warning,
                buttons: [this.deleteConfirmationButton],
            });
            this.$eventBus.$once('buttonClicked', id => {
                if (id === this.deleteConfirmationButton.id) {
                    this.deleteLead(entity);
                }
            });
        },
        deleteLead(lead) {
            this.leads = this.leads.filter(l => l.id !== lead.id);
            this.saveLeads();
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/consistency-spacing';

.modal-wrapper {
    width: 50vw;
    padding: $spacing-xxl;
}
</style>
