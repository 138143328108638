<template>
    <AppDialogV2
        :visible="visible"
        :title="$i18n.t('orchestrationEngine.scheduleExecution')"
        @close="onCloseModal"
    >
        <p class="body-sm mb-1">
            {{ $t('orchestrationEngine.startExec') }}
        </p>
        <DateTimePicker
            v-model="startAtDateTime"
            :error="$v.startAtDateTime.$error"
            type="datetime"
            :popupStyle="{ zIndex: 10000 }"
        />
        <p
            v-if="$v.startAtDateTime.$error"
            class="body-sm mt-1 error-message"
        >
            {{ $t('orchestrationEngine.dateExceedLimits') }}
        </p>

        <p class="body-sm mb-1 mt-3">
            {{ $t('orchestrationEngine.input') }}
        </p>
        <AppTextareaV3
            v-model="inputJsonString"
            :rows="8"
            :invalid="$v.inputJsonString.$error"
            :placeholder="$t('orchestrationEngine.jsonInputPlaceholder')"
            :errorMessage="$t('orchestrationEngine.jsonInput')"
            data-test-id="input-execution-modal"
        />

        <p class="body-sm mb-1 mt-3">
            {{ $t('orchestrationEngine.statesToSkip') }}
        </p>
        <AppMultiselectV3
            v-model="skipStates"
            :options="skipStatesOptions"
            label="label"
            trackBy="label"
            :small="true"
            :multiple="true"
        />

        <template #modalFooter>
            <AppButton
                :buttonType="BUTTON_TYPES.PRIMARY"
                data-test-id="on-action-button"
                :label="$i18n.t('orchestrationEngine.schedule')"
                :disabled="isScheduleButtonClicked || !writeEnabled"
                @click="onScheduleExecution"
            />
        </template>
    </AppDialogV2>
</template>

<script>
// Vue components
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import AppDialogV2 from '@/components/partials/AppDialogV2.vue';
import AppTextareaV3 from '@/components/partials/inputs/AppTextareaV3.vue';
import { ALERT_TYPES } from '@/common/alerts/Alert';
import DateTimePicker from '@/components/partials/inputs/DateTimePicker.vue';

// Helpers & Misc
import * as Sentry from '@sentry/vue';
import { ICON_TYPES } from '@/common/iconHelper';
import permissionsService, { isUserAllowed } from '@/services/permissions/permissions.service';

// Mixins
import OESkipStatesMixin from '@/__new__/features/orchestrationengine/mixins/OESkipStatesMixin.vue';

// HTTP
import { scheduleExecution } from '@/__new__/services/dno/orchestrationengine/http/orchestration-engine';

export default {
    name: 'ScheduleExecutionModal',

    components: {
        AppButton,
        AppDialogV2,
        AppTextareaV3,
        DateTimePicker,
    },

    mixins: [OESkipStatesMixin],

    props: {
        visible: {
            required: true,
            type: Boolean,
        },
        planId: {
            required: true,
            type: String,
        },
    },

    data() {
        return {
            // Proxy imports
            ICON_TYPES,
            BUTTON_TYPES,

            startAtDateTime: null,
            isScheduleButtonClicked: false,
        };
    },

    computed: {
        writeEnabled() {
            return isUserAllowed('OrchestrationPlansWrite') && permissionsService.orchestrationEnginePlansEnabled();
        },
    },

    validations: {
        startAtDateTime: {
            validation: function validation(value) {
                if (value) {
                    const difference = value.getTime() - new Date().getTime();
                    const daysDiff = Math.ceil(difference / (1000 * 3600 * 24));

                    return daysDiff < 90;
                }

                return true;
            },
        },
        inputJsonString: {
            validation: function validation(value) {
                if (value) {
                    try {
                        JSON.parse(this.inputJsonString);

                        return true;
                    } catch (e) {
                        return false;
                    }
                }

                return false;
            },
        },
    },

    methods: {
        onCloseModal() {
            this.$emit('close');

            // Reset validation
            this.$v.$reset();

            // Reset modal data
            this.isScheduleButtonClicked = false;
            this.inputJsonString = '';
            this.startAtDateTime = null;
            this.skipStates = [];
        },
        async onScheduleExecution() {
            this.$v.$touch();

            if (!this.$v.$invalid) {
                try {
                    this.isScheduleButtonClicked = true;
                    this.$Progress.start();

                    const { planId, startAtDateTime, inputJsonString } = this;
                    let startAt;

                    if (startAtDateTime) {
                        startAt = startAtDateTime.valueOf() / 1000;
                    } else {
                        // start time is current time if time is not selected
                        startAt = new Date() / 1000;
                    }

                    const jsonAsObject = JSON.parse(inputJsonString);

                    await scheduleExecution(planId, jsonAsObject, { startAt });

                    this.$eventBus.$emit('showAlert', {
                        message: this.$i18n.t('orchestrationEngine.scheduleSuccess'),
                        type: ALERT_TYPES.success,
                    });

                    setTimeout(() => {
                        this.onCloseModal();
                    }, 1000);
                } catch (error) {
                    Sentry.captureException(error);
                    this.$Progress.fail();

                    this.$eventBus.$emit('showAlert', {
                        message: this.$i18n.t('orchestrationEngine.somethingWentWrongSchedule'),
                    });

                    this.isScheduleButtonClicked = false;
                } finally {
                    this.$Progress.finish();
                }
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/consistency';
@import '~@/assets/scss/layout';
@import '~@/assets/scss/icons';

.error-message {
    color: $red-400;
}
</style>
