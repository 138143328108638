<template>
    <div>
        <form @submit.prevent="forgotPassowrd">
            <div class="heading">
                <div>
                    <h1 class="font-weight-bold lf-welcome">
                        {{ $t('login.welcome') }}
                    </h1>
                    <span class="lf-subtitle-blue">{{ $t('login.enterEmailToReset') }}</span>
                </div>
            </div>
            <!-- Form inputs -->
            <div class="login-form">
                <AppInputV3
                    v-model="email"
                    :placeholder="$t('generic.email')"
                    :invalid="$v.email.$error"
                    type="email"
                    class="form-inputs"
                    :errorMessage="$t('login.email')"
                />
                <div class="button-wrapper">
                    <AppButton
                        :label="$t('login.sendEmail')"
                        :buttonType="loginButtonsType"
                        class="login-button"
                    />
                </div>
            </div>
        </form>
        <div class="auth-strategy">
            <div class="separator">
                <div class="line" />
                <span class="log-in-inline-text">{{ $t('login.orGoBack') }}</span>
                <div class="line" />
            </div>

            <div class="strategy-list">
                <AppButton
                    :label="$t('customerCare.userInformation.signIn')"
                    :buttonType="loginButtonsType"
                    :iconType="ICON_TYPES.ARROW_LEFT"
                    class="login-button"
                    @click="getBackToLoginPage"
                />
            </div>
        </div>
    </div>
</template>
<script>
// Vue components
import AppInputV3 from '@/components/partials/inputs/AppInputV3.vue';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import { ICON_TYPES } from '@/common/iconHelper';

// HTTP layer
import { sendUserLinkToChangePassword } from '@/__new__/services/portal/auth/http/login';

// Helpers
import { ALERT_TYPES } from '@/common/alerts/Alert';

// Validation
import { validationMixin } from 'vuelidate';
import { required, email } from 'vuelidate/lib/validators';

export default {
    name: 'ForgotPassword',
    components: {
        AppButton,
        AppInputV3,
    },
    mixins: [validationMixin],
    props: {
        loginButtonsType: {
            type: String,
            default: BUTTON_TYPES.PRIMARY,
        },
    },
    data() {
        return {
            email: '',
            ICON_TYPES,
            BUTTON_TYPES,
        };
    },

    validations() {
        return {
            email: {
                required,
                email,
            },
        };
    },

    methods: {
        async forgotPassowrd() {
            this.$Progress.start();

            try {
                await sendUserLinkToChangePassword(this.email);
                this.$Progress.finish();
                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('login.checkInbox', { email: this.email }),
                    type: ALERT_TYPES.success,
                });
            } catch (e) {
                this.$eventBus.$emit('showAlert', {
                    message: e.response.data.error,
                });
                this.$Progress.fail();
            }
        },
        getBackToLoginPage() {
            this.$emit('getBackToLoginPage');
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/palette';
@import '~@/assets/scss/typographyV2.scss';
@import '~@/assets/scss/layout';

h1 {
    margin: 0;
}

.lf-welcome {
    font-family: $font-primary;
    font-size: $text-xxl;
    font-weight: $bold-font-weight;
    line-height: $normal-line-height;
    letter-spacing: normal;
    color: $blue;
    display: flex;

    @media screen and (prefers-color-scheme: dark) {
        color: #fff;
    }

    @media screen and (max-width: 64rem) {
        font-size: 2rem;
    }
}

.lf-subtitle-blue {
    display: flex;
    margin-left: 0.5rem;

    @media screen and (prefers-color-scheme: dark) {
        color: #fff;
    }
}

.form-inputs {
    padding-bottom: $spacing-m;
    max-width: 25rem;
    width: 100%;

    @media screen and (max-width: 64rem) {
        max-width: 100%;
    }
}

.heading {
    margin-bottom: 5rem;
    @media screen and (max-width: 42.5rem) {
        text-align: center;
    }
}

.login-form {
    display: flex;
    flex-direction: column;
    width: 100%;

    @media screen and (max-width: 42.5rem) {
        justify-content: center;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

.login-button {
    margin-top: 0.625rem;
    width: 100%;
    margin-bottom: 3.563rem;
}

.button-wrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 25rem;

    @media screen and (max-width: 64rem) {
        width: 100%;
        max-width: 100%;
    }
}

.text-button {
    display: flex;
    color: $white;
    text-decoration: underline;
    cursor: pointer;
}

.auth-strategy {
    max-width: 25rem;
    width: 100%;

    @media screen and (max-width: 64rem) {
        max-width: 100%;
        margin: 0 auto;
    }
}

.separator {
    display: flex;
    align-items: center;
}

.separator .line {
    flex: 1;
    height: 1px;
    background-color: $gray5;

    &:first-child {
        margin-right: 0px;
    }
}

.log-in-inline-text {
    width: 5.125rem;
    height: 1.125rem;
    margin: 0 1.563rem 0 1.563rem;
    font-family: 'Open Sans';
    font-size: 0.813rem;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #aeaeae;
    margin-bottom: 1rem;
    display: flex;
    justify-content: center;
}
</style>
