<template>
    <div>
        <NodeTree
            :key="treeList.id"
            :treeList="treeList"
            :depth="depth"
            :mapper="mapper"
            :keyName="keyName"
            :firstChild="firstChild"
            :selectedId="selectedId"
            :searchQuery="searchQuery"
            :hoverButtons="hoverButtons"
            @selectedItem="selectItem"
            @hoverBtnClick="$emit('hoverBtnClick', $event)"
        />
    </div>
</template>

<script>
const NodeTree = () => import('./NodeTree.vue');

export default {
    name: 'NodeTreeItem',
    components: {
        NodeTree,
    },
    props: {
        keyName: {
            type: String,
            default: 'id',
        },
        treeList: {
            type: Object,
            default: () => undefined,
            required: true,
        },
        depth: {
            type: Number,
            default: 1,
        },
        mapper: {
            type: Function,
            default: null,
        },
        firstChild: {
            type: Boolean,
            default: false,
        },
        selectedId: {
            type: String,
            default: null,
        },
        searchQuery: {
            type: String,
            default: '',
        },
        hoverButtons: {
            type: Object,
            default: () => undefined,
        },
    },
    methods: {
        selectItem(entity) {
            this.$emit('selectedItem', entity);
        },
    },
};
</script>
