<template>
    <AbstractListPageWrapper
        :isOverviewEnabled="isOverviewEnabled"
        :pageTitle="pageTitle"
        :entitiesCount="formattedFilteredEntities.length"
        @searchQueryChanged="setSearchQuery"
    >
        <template #button>
            <ResponseModalButton
                :response="payoutsApiResponse"
                :title="pageTitle"
            />
        </template>

        <template #filterTable>
            <FilterTable
                :columns="tableColumnsData"
                :multiselectWidth="{ width: '15rem' }"
                @filterAdded="onFilterAdded"
            />
        </template>

        <template #headerButtons>
            <AppButton
                data-testid="create-btn"
                :buttonType="BUTTON_TYPES.PRIMARY"
                :iconType="ICON_TYPES.PLUS"
                :label="$i18n.t('rewards.newPayout')"
                :disabled="!writeEnabled"
                @click="createPayout"
            />
        </template>

        <template #allFilters>
            <TableFiltersTags
                :filterTableMixin="filterTableMixin"
                class="my-3 ml-2"
                @removeFilter="removeFilter"
                @removeAllFilters="removeAllFilters"
            />
        </template>

        <template #table>
            <AppTable
                data-testid="app-table"
                :entities="formattedFilteredEntities"
                :canSelectColumns="true"
                :canSort="true"
                :selectedEntityId="selectedEntityId"
                :innerSearchQuery="searchQueryForTable"
                :isSearchEnabled="true"
                :isInnerSearchQueryPassed="false"
                :enableRowStateControls="writeEnabled"
                :entityType="entityType"
                :columnsData="tableColumnsData"
                :isDataLoading="isDataLoading"
                tableKey="payouts"
                @selectEntity="onEntitySelected"
            >
                <template #status="{ entity }">
                    <div class="d-flex">
                        <AppLabel
                            :title="entity.status"
                            :color="entity.statusColor"
                        />
                    </div>
                </template>
                <template #entityVersion="{ entity }">
                    <EntityStatusIndicator :status="entity.entityVersion" />
                </template>
            </AppTable>
        </template>

        <template #overview>
            <EntityOverview
                :entity="selectedEntity"
                :entityType="entityType"
                @closeOverview="isOverviewEnabled = false"
            >
                <template #section-1-header>
                    <div>
                        <OverviewHeader
                            v-if="selectedEntityId"
                            :entityName="selectedEntity.name"
                            :entityType="entityType"
                        />
                        <LanguageSwitcher v-model="selectedLanguage" />
                    </div>
                </template>

                <template #section-1-content>
                    <div>
                        <div
                            v-if="selectedEntityId"
                            class="description-highlight"
                        >
                            <div class="description-h">
                                {{ $i18n.t('generic.description') }}
                            </div>
                            <div class="description-info">
                                {{ selectedEntity.description || $i18n.t('generic.N/A') }}
                            </div>
                        </div>
                        <OverviewList
                            :lists="detailsSection"
                            :isNewDesignSelected="true"
                        />
                    </div>
                </template>
            </EntityOverview>
        </template>
    </AbstractListPageWrapper>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Actions, { Getters } from '@/store/mutation-types';
import { getPayoutStatus, getPayoutStatusColor, getPayoutType } from '@/modules/rewards/common/payoutsHelper';
import RouteNames from '@/router/routeNames';
import AbstractListPageWrapper from '@/components/layout/AbstractListPageWrapper.vue';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import { ICON_TYPES } from '@/common/iconHelper';
import AppTable from '@/components/partials/AppTable.vue';
import EntityOverview from '@/components/partials/entityOverview/EntityOverview.vue';
import OverviewHeader from '@/components/partials/entityOverview/OverviewHeader.vue';
import OverviewList from '@/components/partials/entityOverview/OverviewList.vue';
import ENTITY_TYPES from '@/common/entities/entityTypes';
import FilterTable from '@/components/partials/FilterTable.vue';
import FilterTableMixin from '@/components/partials/FilterTableMixin.vue';
import AppLabel from '@/components/partials/AppLabel.vue';
import tableColumnType from '@/common/filterTable';
import { getMultiLangFieldValueByLocale } from '@/common/entities/entityHelper';
import LanguageSwitcher from '@/components/partials/inputs/LanguageSwitcher.vue';
import * as Sentry from '@sentry/vue';
import TableFiltersTags from '@/components/filters/TableFiltersTags.vue';
import ResponseModalButton from '@/components/partials/ResponseModalButton.vue';
import permissionsService, { isUserAllowed } from '@/services/permissions/permissions.service';
import EntityStatusIndicator from '@/components/partials/EntityStatusIndicator.vue';

export default {
    name: 'Payouts',
    components: {
        AbstractListPageWrapper,
        AppButton,
        AppTable,
        EntityOverview,
        OverviewHeader,
        OverviewList,
        AppLabel,
        FilterTable,
        LanguageSwitcher,
        TableFiltersTags,
        ResponseModalButton,
        EntityStatusIndicator,
    },
    mixins: [FilterTableMixin],
    data() {
        return {
            pageTitle: this.$i18n.t('generic.payouts'),
            searchQueryForTable: '',
            selectedLanguage: '',
            selectedEntityId: null,
            selectedEntity: null,
            entityType: ENTITY_TYPES.REWARD_PAYOUT,
            ICON_TYPES,
            BUTTON_TYPES,
            isDataLoading: false,
            getMultiLangFieldValueByLocale,
            isOverviewEnabled: false,
            isRewardsDraftEnabled: permissionsService.isRewardsDraftEnabled(),
        };
    },
    computed: {
        ...mapGetters('operators', [Getters.languageDefault]),
        ...mapGetters('rewards', [
            Getters.GET_NOT_DELETED_REWARDS_ENTITIES_BY_TYPE,
            Getters.GET_REWARDS_ENTITIES_API_RESPONSE_BY_TYPE,
        ]),
        payouts() {
            return this[Getters.GET_NOT_DELETED_REWARDS_ENTITIES_BY_TYPE](this.entityType);
        },
        payoutsApiResponse() {
            return this[Getters.GET_REWARDS_ENTITIES_API_RESPONSE_BY_TYPE](this.entityType);
        },
        formattedEntities() {
            return this.payouts.map(entity => {
                // eslint-disable-next-line
                const { name, description, payout_type, reference_id } = entity.data;
                return {
                    ...entity,
                    id: entity.id,
                    name: getMultiLangFieldValueByLocale(name),
                    description: getMultiLangFieldValueByLocale(description),
                    status: this.$i18n.t(getPayoutStatus(entity.state)),
                    statusColor: getPayoutStatusColor(entity.state),
                    payoutType: this.$i18n.t(getPayoutType(payout_type)),
                    referenceId: reference_id,
                };
            });
        },
        formattedEntitiesMap() {
            return this.formattedEntities.reduce((acc, item) => {
                acc[item.id] = item;
                return acc;
            }, {});
        },
        formattedFilteredEntities() {
            return this.filteredEntitiesMixin(this.formattedEntities);
        },
        generalDetails() {
            return {
                name: this.$i18n.t('generic.payout'),
                properties: [
                    {
                        label: this.$i18n.t('generic.id'),
                        value: this.selectedEntity?.id,
                    },
                    {
                        label: `${this.$i18n.t('generic.payoutType')}`,
                        value: this.selectedEntity?.payoutType,
                    },
                    {
                        label: `${this.$i18n.t('generic.referenceId')}`,
                        value: this.selectedEntity?.referenceId,
                    },
                ],
            };
        },
        detailsSection() {
            return this.selectedEntityId ? [this.generalDetails] : [];
        },
        tableColumnsData() {
            const columns = [
                {
                    name: this.$i18n.t('generic.name'),
                    key: 'name',
                    field: 'name',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('generic.status'),
                    key: 'status',
                    field: 'status',
                    filterType: tableColumnType.TEXT_LIMITED_OPTIONS,
                    limitedOptions: Array.from(new Set(this.formattedFilteredEntities.map(entity => entity.status))),
                },
                {
                    name: this.$i18n.t('rewards.payoutType'),
                    key: 'payoutType',
                    field: 'payoutType',
                    filterType: tableColumnType.TEXT_LIMITED_OPTIONS,
                    limitedOptions: Array.from(
                        new Set(this.formattedFilteredEntities.map(entity => entity.payoutType)),
                    ),
                },
            ];

            if (this.isRewardsDraftEnabled) {
                columns.push({
                    name: this.$i18n.t('generic.version'),
                    key: 'entityVersion',
                    field: 'entityVersionLabel',
                    filterType: tableColumnType.TEXT_LIMITED_OPTIONS,
                    limitedOptions: [
                        ...new Set(this.formattedFilteredEntities.map(entity => entity.entityVersionLabel)),
                    ],
                });
            }

            return columns;
        },
        writeEnabled() {
            return isUserAllowed('PayoutsWrite') && permissionsService.rewardsEnabled();
        },
    },
    async created() {
        this.$Progress.start();
        this.isDataLoading = true;
        try {
            await this[Actions.REQUEST_REWARDS_ENTITIES_BY_TYPE](ENTITY_TYPES.REWARD_PAYOUT);
            this.selectedLanguage = this[Getters.languageDefault];
            this.$Progress.finish();
        } catch (error) {
            Sentry.captureException(error);
            this.$Progress.fail();
            this.$eventBus.$emit('showAlert', {
                message: this.$i18n.t('alertMessage.failedToLoadNecessaryData'),
            });
        } finally {
            this.isDataLoading = false;
        }
    },
    methods: {
        ...mapActions('rewards', [Actions.REQUEST_REWARDS_ENTITIES_BY_TYPE]),
        createPayout() {
            this.$router.push({
                name: RouteNames.REWARD_PAYOUT_EDITOR,
                params: { companyId: this.$route.params.companyId },
            });
        },
        onEntitySelected(id) {
            this.selectedEntity = this.formattedEntitiesMap[id];
            this.selectedEntityId = id;
            this.isOverviewEnabled = true;
        },
        setSearchQuery(query) {
            this.searchQueryForTable = query;
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/palette';

.description-highlight {
    padding: 1.5rem 0;
    margin: 0 auto;

    .description-h {
        font-size: 0.812rem;
        font-weight: 600;
        line-height: 2;
        color: $gray90;
    }

    .description-info {
        padding: 0.75rem 1.25rem;
        background-color: $dirty-white;
        font-size: 0.812rem;
        font-weight: normal;
        color: $gray60;
    }
}
</style>
