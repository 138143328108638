<template>
    <div class="page-background">
        <AppHeader :pageTitle="pageTitle" />

        <div class="content-container">
            <SearchBanner
                :title="$i18n.t('customerCare.search.searchForGORPayments')"
                :primaryText="$i18n.t('customerCare.search.searchForGORPaymentsDescription')"
            />

            <div class="search-wrapper container">
                <form
                    class="d-flex align-items-center justify-content-center flex-wrap"
                    data-test-id="um-search-form"
                    @submit.prevent
                    @keydown.enter.prevent="onSearch"
                >
                    <span class="pt-1 pr-2 lf-all-caps">
                        {{ $i18n.t('customerCare.search.userWith') }}
                    </span>
                    <AppMultiselectV3
                        v-model="selectedSearchOption"
                        :options="Object.values(searchOptions)"
                        :allowEmpty="false"
                        :preselectFirst="true"
                        :small="true"
                        :borderNone="true"
                        :blueArrow="true"
                        :newDesign="true"
                        trackBy="key"
                        label="label"
                        class="search-options-dropdown pt-1"
                        @select="onOptionSelect"
                    />

                    <AppMultiselectV3
                        v-model="selectedSearchOperator"
                        :options="searchOperations"
                        :allowEmpty="false"
                        :preselectFirst="true"
                        :small="true"
                        :borderNone="true"
                        :blueArrow="true"
                        :newDesign="true"
                        trackBy="key"
                        label="label"
                        class="search-operator-dropdown mr-3 pt-1"
                    />

                    <div class="pr-3 value-input-container">
                        <AppInputV3
                            id="search-value"
                            v-model="searchValue"
                            :placeholder="selectedSearchOption.placeholder"
                            class="id-field"
                        />
                    </div>
                    <div class="search-button-wrapper">
                        <AppButton
                            :buttonType="BUTTON_TYPES.PRIMARY"
                            label="Search"
                            :iconType="ICON_TYPES.SEARCH"
                            @click="onSearch"
                        />
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import AppHeader from '@/components/layout/AppHeader.vue';
import AppInputV3 from '@/components/partials/inputs/AppInputV3.vue';
import AppMultiselectV3 from '@/components/partials/inputs/AppMultiselectV3.vue';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import { ICON_TYPES } from '@/common/iconHelper';
import { getTenantUMSearchOptions } from '@/services/permissions/permissions.service';
import SearchBanner from '@/components/partials/SearchBanner.vue';

// Helpers and libraries
import {
    SEARCH_QUERY_OPTION,
    SEARCH_QUERY_FILTER_OPERATOR,
    SEARCH_QUERY_FILTER_OPERATOR_LABEL_MAP,
} from '@/__new__/features/customerCare/common/userInfoHelper';
import ossgpfiberService from '@/__new__/services/dno/ossgpfiber/http/ossgpfiber';
import { ALERT_TYPES } from '@/common/alerts/Alert';
import UM_HIERARCHY from '@/common/userManagerHierarchy';

// 3rd party libs
import * as Sentry from '@sentry/vue';

export default {
    name: 'GorPaymentUserSearch',
    components: {
        AppHeader,
        AppButton,
        AppMultiselectV3,
        AppInputV3,
        SearchBanner,
    },
    data() {
        return {
            ICON_TYPES,
            BUTTON_TYPES,
            searchValue: '',
            selectedSearchOption: '',
            selectedSearchOperator: '',
        };
    },

    computed: {
        searchOptions() {
            const searchOptions = getTenantUMSearchOptions();
            const options = {};
            for (const key in searchOptions) {
                if (key === 'MSISDN') {
                    options[key] = searchOptions[key];
                }
            }
            return options;
        },
        searchOperations() {
            return Object.values(SEARCH_QUERY_FILTER_OPERATOR)
                .filter(key =>
                    [SEARCH_QUERY_FILTER_OPERATOR.MATCH, SEARCH_QUERY_FILTER_OPERATOR.STARTS_WITH].includes(key),
                )
                .map(key => ({
                    key,
                    label: SEARCH_QUERY_FILTER_OPERATOR_LABEL_MAP.get(key),
                    $isDisabled: !(this.selectedSearchOption?.supportedOperators || []).includes(key),
                }));
        },
        pageTitle() {
            return this.$i18n.t('customerCare.gorPaymentStatus');
        },
    },

    mounted() {
        this.setSelectedSearchOption();
    },

    methods: {
        async onSearch() {
            if (
                this.selectedSearchOption &&
                typeof this.selectedSearchOption?.validator === 'function' &&
                this.selectedSearchOption.validator(this.searchValue, false)
            ) {
                const searchParams = {
                    queryOption: SEARCH_QUERY_OPTION.USERNAME,
                    queryOperator: this.selectedSearchOperator.key,
                    id: this.searchValue,
                };
                await this.$withProgressBar(
                    async () => {
                        const response = await ossgpfiberService.getPaymentTransaction({
                            target_id: searchParams.id,
                            target_type: UM_HIERARCHY.UNREGISTERED_MSISDN,
                        });
                        if (response.data?.transactions?.length) {
                            const path = `/${this.$route.params.companyId}/customer-care/gor-payment-info/${searchParams.id}`;
                            this.$router.push(path);
                        } else {
                            this.$alert(this.$i18n.t('alertMessage.userManagement.GORPaymentHistoryNotExist'));
                        }
                    },
                    {
                        errorHandler: () => {
                            this.$alert(
                                this.$i18n.t('alertMessage.userManagement.somethingWentWrongFetchingUserProfileData'),
                            );
                        },
                    },
                );
            } else {
                this.$eventBus.$emit('showAlert', {
                    message: `${this.selectedSearchOption.errorMessage}.`,
                    type: ALERT_TYPES.warning,
                });
            }
        },
        setSelectedSearchOption() {
            try {
                const searchOptions = Object.values(getTenantUMSearchOptions());
                this.selectedSearchOption = searchOptions.find(el => el.key === 'msisdn');
            } catch (e) {
                Sentry.captureException(e);
                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('alertMessage.userManagement.somethingWentWrongFetchingUserProfileData'),
                });
            }
        },
        onOptionSelect(o) {
            if (o?.supportedOperators?.length === 1) {
                this.selectedSearchOperator = this.searchOperations.find(({ key }) => key === o.supportedOperators[0]);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/palette';
@import '~@/assets/scss/layout';
@import '~@/assets/scss/typographyV2';

.page-background {
    background-color: $white;
    height: 100%;
}

.content-container {
    width: 70%;
    margin: 0 15%;
}

.lf-all-caps {
    color: $gray-blue;
}

.search-options-dropdown,
.search-operator-dropdown {
    width: min-content;
    min-width: 4.75rem;
    white-space: nowrap;

    ::v-deep .multiselect__content-wrapper {
        min-width: 9rem;
    }
}

.value-input-container {
    width: 100%;
    max-width: 600px;
}

.search-button-wrapper {
    margin-left: auto;
}
</style>
