<template>
    <label
        :class="{
            disabled,
            checked: value,
            'full-width': fullWidth,
            'is-view': isView,
            error: invalid,
        }"
        class="wrap"
    >
        <input
            :checked="value"
            :value="value"
            :disabled="disabled || isView"
            type="checkbox"
            class="check"
            @change="e => $emit('input', e.target.checked)"
        />
        <span class="icon">
            <div class="d-flex flex-column ml-3">
                <div class="title">
                    {{ label }}
                </div>

                <div class="description">
                    {{ description }}
                </div>
            </div>
        </span>
    </label>
</template>

<script>
export default {
    name: 'AppCheckbox',
    props: {
        value: {
            type: Boolean,
            default: false,
        },
        label: {
            type: String,
            default: '',
        },
        description: {
            type: String,
            default: '',
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        small: {
            type: Boolean,
            default: false,
        },
        fullWidth: {
            type: Boolean,
            default: false,
        },
        isView: {
            type: Boolean,
            default: false,
        },
        invalid: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/palette';
@import '~@/assets/scss/icons';
@import '~@/assets/scss/typographyV2';

$icon-path: '~@/assets/icons/';

label {
    font-size: 0.875rem;
    cursor: pointer;

    span.icon {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    input[type='checkbox'] {
        -webkit-appearance: none;
        appearance: none;
        height: 0;
        width: 0;
        position: absolute;

        &.check {
            & + span.icon:before {
                content: url($icon-path + $checkbox-card-off);
            }

            &:checked {
                & + span.icon:before {
                    content: url($icon-path + $checkbox-card-on);
                }
            }
        }
    }

    &.small {
        font-size: 0.75rem;

        input.check + span.icon:before {
            margin-top: 0;
            height: 16px;
            width: 16px;
        }
    }

    &.disabled {
        border: solid 1px $gray5;
        background-color: rgba($color: $gray5, $alpha: 0.4);
        cursor: not-allowed;

        input[type='checkbox'] {
            &.check {
                & + span.icon:before {
                    content: url($icon-path + $checkbox-card-disabled);
                }

                &:checked {
                    & + span.icon:before {
                        content: url($icon-path + $checkbox-disable-on);
                    }
                }
            }
        }
    }

    &.full-width {
        display: block;

        span.icon {
            justify-content: flex-start;
        }
    }

    &.is-view {
        cursor: default;
        border: none;
        background-color: inherit;
        color: $gray10;

        input[type='checkbox'] {
            &.check {
                & + span.icon:before {
                    content: url($icon-path + $checkbox-view-off);
                }

                &:checked {
                    & + span.icon:before {
                        content: url($icon-path + $checkbox-view-on);
                    }
                }
            }
        }

        .title {
            color: $gray10;
            font-size: $text-sm;
        }
        .description {
            font-size: $text-xs;
            color: $gray10;
        }

        &.checked {
            background-color: inherit;
            .title {
                color: $gray90;
            }
            .description {
                color: $gray60;
            }
        }
    }
}

.title {
    font-size: 1rem;
    font-weight: 600;
    color: $gray90;
    height: 22px;
}

.description {
    font-size: 12px;
    color: $gray60;
}

.wrap {
    padding: 10px 18px;
    border-radius: 8px;
    border: solid 1px rgba(51, 81, 149, 0.15);

    &.checked {
        background: rgba(51, 81, 149, 0.15);

        .title {
            color: $blue;
        }
    }

    &.error {
        border-color: $red;
    }
}
</style>
