<template>
    <AbstractListPageWrapper
        :isOverviewEnabled="isOverviewEnabled"
        :pageTitle="pageTitle"
        :entitiesCount="formattedFilteredEntities.length"
        @searchQueryChanged="setSearchQuery"
    >
        <template slot="button">
            <ResponseModalButton
                :response="prizeEngineApiResponse"
                :title="pageTitle"
            />
        </template>

        <template slot="filterTable">
            <FilterTable
                :columns="tableColumnsData"
                :multiselectWidth="{ width: '13rem' }"
                @filterAdded="onFilterAdded"
            />
        </template>

        <template slot="headerButtons">
            <AppButton
                :buttonType="BUTTON_TYPES.PRIMARY"
                :iconType="ICON_TYPES.PLUS"
                :label="$i18n.t('rewards.newPrizeEngine')"
                :disabled="!writeEnabled"
                @click="createPrizeEngine"
            />
        </template>

        <template slot="allFilters">
            <TableFiltersTags
                :filterTableMixin="filterTableMixin"
                class="my-3 ml-2"
                @removeFilter="removeFilter"
                @removeAllFilters="removeAllFilters"
            />
        </template>

        <template slot="table">
            <AppTable
                :entities="formattedFilteredEntities"
                :entityType="ENTITY_TYPES.PRIZE_ENGINE"
                :canSelectColumns="true"
                :canSort="true"
                :selectedEntityId="selectedEntityId"
                :innerSearchQuery="searchQueryForTable"
                :isSearchEnabled="true"
                :isInnerSearchQueryPassed="false"
                :columnsData="tableColumnsData"
                :isDataLoading="isDataLoading"
                :enableRowStateControls="writeEnabled"
                tableKey="prizeEngine"
                @selectEntity="onEntitySelected"
            >
                <template #stateLabel="{ entity }">
                    <EntityStatusIndicator :status="entity.state" />
                </template>
                <template #entityVersion="{ entity }">
                    <EntityStatusIndicator :status="entity.entityVersion" />
                </template>
            </AppTable>
        </template>

        <template slot="overview">
            <EntityOverview
                :entity="selectedEntity"
                :entityType="ENTITY_TYPES.PRIZE_ENGINE"
                @closeOverview="isOverviewEnabled = false"
            >
                <div :slot="`section-1-header`">
                    <OverviewHeader
                        v-if="selectedEntityId"
                        :entityName="getMultiLangFieldValueByLocale(selectedEntity.data.name, selectedLanguage)"
                        :entityType="ENTITY_TYPES.PRIZE_ENGINE"
                        :isNewDesignSelected="true"
                    />
                    <LanguageSwitcher v-model="selectedLanguage" />
                </div>
                <div :slot="`section-1-content`">
                    <OverviewList :lists="detailsSection" />
                    <div class="section-header">
                        {{ $i18n.t('prizeEngine.prizeEnginePreview') }}
                    </div>
                    <div class="icon-wrap">
                        <img
                            src="../../../assets/icons/spin-icon.svg"
                            alt=""
                        />
                    </div>
                    <div class="section-header">
                        {{ $i18n.t('rewards.prizes') }}
                    </div>
                    <div
                        v-for="(prize, index) in formattedPrizesToDisplay"
                        :key="index"
                        class="card-container"
                    >
                        <div class="card-title">
                            {{ getPrizeType(prize) }}
                        </div>
                        <div class="card-list">
                            <div class="card-content">
                                <ul>
                                    <li v-show="prize.prize_id">
                                        {{ $i18n.t('rewards.prize') }}
                                    </li>
                                    <li>{{ $i18n.t('prizeEngine.prizePosition') }}</li>
                                    <li>{{ $i18n.t('prizeEngine.probability') }}</li>
                                </ul>
                                <div class="card-values">
                                    <div v-show="prize.prize_id">
                                        {{ getPrizeName(prize.prize_id) }}
                                    </div>
                                    <div v-show="prize.metadata">
                                        {{ prize.metadata.start_angle_degrees }} ° - {{ prize.metadata.size_degrees }} °
                                    </div>
                                    <div v-show="prize.probability_weight">
                                        {{ getPrizeProbability(prize.probability_weight) }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </EntityOverview>
        </template>
    </AbstractListPageWrapper>
</template>

<script>
import AbstractListPageWrapper from '@/components/layout/AbstractListPageWrapper.vue';
import AppTable from '@/components/partials/AppTable.vue';
import ENTITY_TYPES from '@/common/entities/entityTypes';
import FilterTable from '@/components/partials/FilterTable.vue';
import LanguageSwitcher from '@/components/partials/inputs/LanguageSwitcher.vue';
import TableFiltersTags from '@/components/filters/TableFiltersTags.vue';

import { mapGetters, mapActions } from 'vuex';
import Actions, { Getters, State } from '@/store/mutation-types';
import RouteNames from '@/router/routeNames';

import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import { ICON_TYPES } from '@/common/iconHelper';
import EntityOverview from '@/components/partials/entityOverview/EntityOverview.vue';
import OverviewHeader from '@/components/partials/entityOverview/OverviewHeader.vue';
import OverviewList from '@/components/partials/entityOverview/OverviewList.vue';
import EntityStatusIndicator from '@/components/partials/EntityStatusIndicator.vue';
import { entityStateReverseMap } from '@/common/commonEntityStateMapper';
import {
    getMultiLangFieldValueByLocale,
    getProperlyFormattedMultilangFieldValue,
} from '@/common/entities/entityHelper';
import { prizeEngineTypes } from '@/modules/rewards/common/RewardsHelper';
import moment from 'moment';
import { checkObjectPath, onlyFirstLetterUppercase } from '@/common/utils';
import FilterTableMixin from '@/components/partials/FilterTableMixin.vue';
import tableColumnType from '@/common/filterTable';
import ResponseModalButton from '@/components/partials/ResponseModalButton.vue';
import permissionsService, { isUserAllowed } from '@/services/permissions/permissions.service';
import { STATUS_CODES } from '@/common/commonHelper';
import { Modules } from '@/store/store';

export default {
    name: 'PrizeEngine',
    components: {
        AbstractListPageWrapper,
        AppTable,
        AppButton,
        EntityOverview,
        OverviewHeader,
        OverviewList,
        FilterTable,
        EntityStatusIndicator,
        LanguageSwitcher,
        TableFiltersTags,
        ResponseModalButton,
    },
    mixins: [FilterTableMixin],
    data() {
        return {
            selectedLanguage: '',
            pageTitle: this.$i18n.t('rewards.prizeEngine'),
            searchQueryForTable: '',
            isDataLoading: false,
            selectedEntity: null,
            selectedEntityId: null,
            ICON_TYPES,
            BUTTON_TYPES,
            ENTITY_TYPES,
            getMultiLangFieldValueByLocale,
            isOverviewEnabled: false,
            isRewardsDraftEnabled: permissionsService.isRewardsDraftEnabled(),
        };
    },
    computed: {
        ...mapGetters('rewards', {
            getEntitiesByTypeAndId: [Getters.GET_REWARDS_ENTITY_BY_TYPE_AND_ID],
            getEntitiesListByType: [Getters.GET_NOT_DELETED_REWARDS_ENTITIES_BY_TYPE],
            getEntitiesApiResponseByType: [Getters.GET_REWARDS_ENTITIES_API_RESPONSE_BY_TYPE],
        }),
        ...mapGetters('rewards', {
            segments: [Getters.CACHED_SEGMENTS_BY_ID],
        }),
        prizes() {
            return this.getEntitiesListByType(ENTITY_TYPES.PRIZE);
        },
        prizeEngineApiResponse() {
            return this.getEntitiesApiResponseByType(ENTITY_TYPES.PRIZE_ENGINE);
        },
        formattedPrizesToDisplay() {
            if (this.selectedEntity) {
                return this.selectedEntity.data.prizes;
            }
            return null;
        },
        formattedFilteredEntities() {
            return this.filteredEntitiesMixin(this.formattedEntities);
        },
        formattedEntities() {
            return this.getEntitiesListByType(ENTITY_TYPES.PRIZE_ENGINE).map(prize => ({
                ...prize.data,
                version: prize.version,
                id: prize.id,
                state: prize.state,
                stateLabel: this.entityState(prize.state),
                name: getMultiLangFieldValueByLocale(prize.data.name),
                numberOfPrizes: prize.data.prizes.length,
                startDateDisplay: Object.prototype.hasOwnProperty.call(prize.data, 'start_time')
                    ? this.$localeLibrary.getFormattedDate(prize.data.start_time)
                    : this.$i18n.t('generic.N/A'),
                startDate: Object.prototype.hasOwnProperty.call(prize.data, 'start_time')
                    ? moment.unix(prize.data.start_time)
                    : null,
                costOfSpins: Object.prototype.hasOwnProperty.call(prize.data.prize_selection_cost_amount, 'spins')
                    ? prize.data.prize_selection_cost_amount.spins
                    : null,
                entityVersion: prize.entityVersion,
                entityVersionLabel: prize.entityVersionLabel,
            }));
        },
        languageDefault() {
            return this.$store.getters[`operators/${Getters.languageDefault}`];
        },
        languages() {
            return this.$store.state.operators[State.Languages];
        },
        generalDetails() {
            const selectedEntityData = this.selectedEntity.data;

            const selectedEntityDescription = getProperlyFormattedMultilangFieldValue(selectedEntityData.description);

            const descProperties = this[State.Languages]
                .filter(language => !!selectedEntityDescription[language])
                .map(language => ({
                    value: selectedEntityDescription[language],
                    label: `${this.$i18n.t('generic.description')} (${language})`,
                }));

            const general = {
                name: this.$i18n.t('generic.general'),
                properties: [
                    {
                        value: this.selectedEntity.id,
                        label: this.$i18n.t('generic.id'),
                    },
                    {
                        value: this.selectedEntity.data.priority,
                        label: this.$i18n.t('rewards.priority'),
                    },
                    ...descProperties,
                    {
                        value: this.$i18n.t(prizeEngineTypes.get(this.selectedEntity.data.prize_engine_type)),
                        label: this.$i18n.t('generic.type'),
                    },
                    {
                        value: this.selectedEntity.data.prizes.length,
                        label: this.$i18n.t('prizeEngine.editor.numberOfPrizes'),
                    },
                ],
            };

            return general;
        },
        eligibilitySection() {
            const { data } = this.selectedEntity;

            const whitelist = checkObjectPath(data, 'segment_expression.whitelist.segment_ids')
                ? data.segment_expression.whitelist.segment_ids.map(id =>
                      this.segments[id] && this.segments[id].name ? this.segments[id].name : id,
                  )
                : [];
            const blacklist = checkObjectPath(data, 'segment_expression.blacklist.segment_ids')
                ? data.segment_expression.blacklist.segment_ids.map(id =>
                      this.segments[id] && this.segments[id].name ? this.segments[id].name : id,
                  )
                : [];

            const eligibility = {
                name: this.$i18n.t('rewards.editor.eligibility.heading'),
                properties: [
                    {
                        value: whitelist.join(', '),
                        label: this.$i18n.t('rewards.editor.eligibility.availableTo'),
                    },
                    {
                        value: blacklist.join(', '),
                        label: this.$i18n.t('rewards.editor.eligibility.notAvailableTo'),
                    },
                    {
                        value: this.$localeLibrary.getFormattedDate(this.selectedEntity.data.start_time),
                        label: this.$i18n.t('rewards.editor.eligibility.startTime'),
                    },
                    {
                        value: this.$localeLibrary.getFormattedDate(this.selectedEntity.data.end_time),
                        label: this.$i18n.t('rewards.editor.eligibility.endTime'),
                    },
                ],
            };

            return eligibility;
        },
        detailsSection() {
            if (!this.selectedEntityId || !this.formattedEntities.length) {
                return [];
            }
            return [this.generalDetails, this.eligibilitySection];
        },
        descriptionAvailable() {
            if (!this.selectedEntity.data.description[this.selectedLanguage]) {
                return this.$i18n.t('generic.N/A');
            }
            return this.selectedEntity.data.description[this.selectedLanguage];
        },
        tableColumnsData() {
            const columns = [
                {
                    name: this.$i18n.t('generic.name'),
                    key: 'name',
                    field: 'name',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('rewards.costOfSpin'),
                    key: 'prize_selection_cost_amount',
                    mapper: prize => prize.prize_selection_cost_amount.spins,
                    field: 'costOfSpins',
                    filterType: tableColumnType.NUMBER,
                },
                {
                    name: this.$i18n.t('rewards.prizes'),
                    key: 'numberOfPrizes',
                    field: 'numberOfPrizes',
                    filterType: tableColumnType.NUMBER,
                },
                {
                    name: this.$i18n.t('generic.startDate'),
                    key: 'startDateDisplay',
                    sortBy: entity => entity.startDate.toDate(),
                    field: 'startDate',
                    filterType: tableColumnType.DATE,
                },
                {
                    name: this.$i18n.t('generic.state'),
                    key: 'stateLabel',
                    field: 'stateLabel',
                    filterType: tableColumnType.TEXT_LIMITED_OPTIONS,
                    limitedOptions: Array.from(
                        new Set(this.formattedFilteredEntities.map(entity => entity.stateLabel)),
                    ),
                },
                {
                    name: this.$i18n.t('rewards.priority'),
                    key: 'priority',
                    field: 'priority',
                    filterType: tableColumnType.NUMBER,
                },
            ];

            if (this.isRewardsDraftEnabled) {
                columns.push({
                    name: this.$i18n.t('generic.version'),
                    key: 'entityVersion',
                    field: 'entityVersionLabel',
                    filterType: tableColumnType.TEXT_LIMITED_OPTIONS,
                    limitedOptions: [
                        ...new Set(this.formattedFilteredEntities.map(entity => entity.entityVersionLabel)),
                    ],
                });
            }

            return columns;
        },
        writeEnabled() {
            return isUserAllowed('PrizeEngineWrite') && permissionsService.rewardsEnabled();
        },
    },
    created() {
        this.selectedLanguage = this.languageDefault;
        this.fetchPrizeEngineData();
        this[Actions.FETCH_SEGMENTS]();
    },
    methods: {
        ...mapActions('rewards', [Actions.REQUEST_REWARDS_ENTITIES_BY_TYPE]),
        ...mapActions(Modules.segments, [Actions.FETCH_SEGMENTS]),
        async fetchPrizeEngineData() {
            this.$Progress.start();
            this.isDataLoading = true;
            try {
                await Promise.all([
                    this[Actions.REQUEST_REWARDS_ENTITIES_BY_TYPE](ENTITY_TYPES.PRIZE_ENGINE),
                    this[Actions.REQUEST_REWARDS_ENTITIES_BY_TYPE](ENTITY_TYPES.PRIZE),
                ]);
                this.$Progress.finish();
            } catch (error) {
                this.$Progress.fail();
                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('alertMessage.failedToLoadNecessaryData'),
                });
            } finally {
                this.isDataLoading = false;
            }
        },
        entityState(s) {
            return s && s !== STATUS_CODES.NA
                ? this.$i18n.t(`finalStateMapper.${onlyFirstLetterUppercase(entityStateReverseMap(s))}`)
                : this.$i18n.t('generic.N/A');
        },
        setSearchQuery(query) {
            this.searchQueryForTable = query;
        },
        getPrizeType(prize) {
            const isDefaultPrize = Object.hasOwnProperty.call(prize, 'is_default_prize')
                ? prize.is_default_prize
                : false;
            const hasPrizeId = Object.hasOwnProperty.call(prize, 'prize_id');

            if (isDefaultPrize && hasPrizeId) {
                return `${this.$i18n.t('prizeEngine.noWin')} - ${this.$i18n.t('prizeEngine.defaultPrize')}`;
            }
            if (isDefaultPrize) {
                return this.$i18n.t('prizeEngine.defaultPrize');
            }
            if (!hasPrizeId) {
                return this.$i18n.t('prizeEngine.noWin');
            }
            return this.$i18n.t('prizeEngine.commonPrize');
        },
        getPrizeName(id) {
            if (id) {
                const prize = this.prizes.find(p => p.id === id);
                return prize && prize.data
                    ? getMultiLangFieldValueByLocale(prize.data.name)
                    : this.$i18n.t('generic.N/A');
            }
            return '';
        },
        getPrizeProbability(probability) {
            if (probability) {
                return probability / 100;
            }
            return '';
        },
        onEntitySelected(id) {
            this.selectedEntity = this.getEntitiesByTypeAndId(ENTITY_TYPES.PRIZE_ENGINE, id);
            this.selectedEntityId = id;
            this.isOverviewEnabled = true;
        },
        createPrizeEngine() {
            this.$router.push({
                name: RouteNames.PRIZE_ENGINE_EDITOR,
                params: { companyId: this.$route.params.companyId },
            });
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/palette';

.card-container {
    margin: 0 1.5rem 0.625rem 1.5rem;
    padding: 0.625rem 1rem;
    border-radius: 0.5rem;
    background-color: rgba($color: $blue, $alpha: 0.15);

    .card-title {
        color: $blue;
        font-size: 0.75rem;
        font-weight: 600;
    }
    .card-list {
        .card-content {
            display: flex;
            flex-direction: row;
            font-size: 0.875rem;
            color: $gray90;

            .card-values {
                font-size: 0.875rem;
            }
        }
        li::before {
            content: '•';
            width: 4px;
            height: 4px;
            margin-right: 6px;
            color: rgba($color: $blue, $alpha: 0.5);
        }
        li {
            display: flex;
        }
        ul {
            font-size: 0.75rem;
            font-weight: 600;
            color: $gray90;
            margin-right: 61px;
        }
    }
}
.icon-wrap {
    display: flex;
    justify-content: center;
    margin: 24px;
}

.section-header {
    color: $gray90;
    font-weight: 600;
    font-size: 0.812rem;
    padding: 8px 16px;
}
</style>
