import i18n from '@/i18n';
import { TranslateResult } from 'vue-i18n';

export enum TYPES_OPTIONS {
    QUOTA = 'quota',
    PERCENTAGE = 'percentage',
}

type ThresholdTypesOptions = {
    [T in TYPES_OPTIONS]: {
        label: TranslateResult;
        value: TYPES_OPTIONS;
    };
};

export const thresholdTypes: Readonly<ThresholdTypesOptions> = {
    [TYPES_OPTIONS.QUOTA]: {
        label: i18n.t('charging.policyCounters.quota'),
        value: TYPES_OPTIONS.QUOTA,
    },
    [TYPES_OPTIONS.PERCENTAGE]: {
        label: i18n.t('charging.policyCounters.percentage'),
        value: TYPES_OPTIONS.PERCENTAGE,
    },
};

export const emptyType = {
    value: '',
};

export default {};
