import ActionModel from '@/__new__/services/dno/charging/models/ActionModel';
import {
    CHARGING_ENTITY_TYPES,
    mapLowerEntitiesVersions,
    LowerEntitiesVersionsObject,
} from '@/__new__/services/dno/charging/common/versioningHelper';
import { WalletCounterApiResponse } from '@/__new__/services/dno/charging/http/walletCounters';
import {
    EntityBaseConstructorArgs,
    EntityBaseModel,
    EntityBaseDraftModel,
} from '@/__new__/services/dno/charging/models/EntityBaseModel';
import i18n from '@/i18n';
import { TranslateResult } from 'vue-i18n';

interface WalletCounterConstructorArgs extends EntityBaseConstructorArgs {
    limit: number;
    is_wallet_limit: boolean;
    disabled: boolean;
    applicable_wallet_types?: string[];
    usage_counter_id: string;
    actions: any[];
    deactivated: boolean;
    available_statuses?: string[];
}

export interface WalletCounterDraftConstructorArgs extends WalletCounterConstructorArgs {
    operator_name: string;
    type: string;
    update_portal_id: string;
    data?: WalletCounterConstructorArgs;
}

export class WalletCounter extends EntityBaseModel {
    limit: number;
    isWalletLimit: boolean;
    isWalletLimitLabel: TranslateResult;
    disabled: boolean;
    disabledLabel: TranslateResult;
    applicableWalletTypes: string[];
    usageCounterId: string;
    actions: any[];
    deactivated: boolean;
    availableStatuses?: string[];
    lowerEntitiesVersions?: LowerEntitiesVersionsObject | null;

    constructor(
        wc: WalletCounterConstructorArgs,
        draftEntities: WalletCounterDraft[],
        responseData?: WalletCounterApiResponse,
    ) {
        super(wc, draftEntities);
        this.limit = wc.limit;
        this.isWalletLimit = wc.is_wallet_limit || false;
        this.isWalletLimitLabel = this.isWalletLimit ? i18n.t('generic.yes') : i18n.t('generic.no');
        this.disabled = wc.disabled || false;
        this.disabledLabel = this.disabled ? i18n.t('generic.yes') : i18n.t('generic.no');
        this.applicableWalletTypes = wc.applicable_wallet_types || ['primary'];
        this.usageCounterId = wc.usage_counter_id;
        this.actions = wc.actions.map(
            ({ action_trigger: actionTrigger, action: actionFlag }) => new ActionModel({ actionTrigger, actionFlag }),
        );
        this.deactivated = wc.deactivated || false;
        this.availableStatuses = wc.available_statuses || undefined;
        this.lowerEntitiesVersions =
            responseData?.usage_counter_by_id && this.usageCounterId
                ? this.getLowerEntitiesVersions(responseData?.usage_counter_by_id, this.usageCounterId)
                : null;
    }

    getLowerEntitiesVersions(data: Record<string, any>, usageCounterId: string): any {
        return {
            [CHARGING_ENTITY_TYPES.USAGE_COUNTER]: mapLowerEntitiesVersions(data, [usageCounterId]),
        };
    }
}

export class WalletCounterDraft extends EntityBaseDraftModel {
    limit: number;
    isWalletLimit: boolean;
    disabled: boolean;
    applicableWalletTypes: string[];
    usageCounterId: string;
    actions: any[];
    deactivated: boolean;

    constructor(wc: WalletCounterDraftConstructorArgs) {
        super(wc);
        this.limit = wc.limit;
        this.isWalletLimit = wc.is_wallet_limit || false;
        this.disabled = wc.disabled || false;
        this.applicableWalletTypes = wc.applicable_wallet_types || ['primary'];
        this.usageCounterId = wc.usage_counter_id;
        this.actions = wc.actions.map(
            ({ action_trigger: actionTrigger, action: actionFlag }) => new ActionModel({ actionTrigger, actionFlag }),
        );
        this.deactivated = wc.deactivated || false;
    }
}

export default WalletCounter;
