
import Vue from 'vue';

// Vuex
import { mapGetters, mapActions } from 'vuex';

import { Modules } from '@/store/store';
import Actions, { Getters } from '@/store/mutation-types';

// Components
import AppCustomerHeader from '@/components/partials/AppCustomerHeader.vue';
import DeviceInfoTile from '@/__new__/features/customerCareSuite/components/DeviceInfoTile.vue';

// Models
import Subscriber from '@/__new__/services/dno/user/models/Subscriber';
import Flag from '@/__new__/services/dno/user/models/Flag';

// HTTP

import customerCareHTTP from '@/__new__/services/dno/user/http/customer-care';
import ossmvneHTTP from '@/__new__/services/dno/ossmvne/http/ossmvne';

// Helpers

import {
    getOperatorConfigValue,
    getSubscriberDetailFields,
    isUserAllowed,
} from '@/services/permissions/permissions.service';
import { invert, isEmpty, pick } from 'lodash';
import { SIM_TYPES_MAP } from '@/__new__/features/customerCare/subscriber/common/simTypes';
import { AUTH_ENUM_TOKEN_TYPE } from '@/__new__/features/customerCare/subscriber/common/authHelper';
import { SIM_MUTATE_HISTORY } from '@/__new__/features/customerCare/subscriber/common/simMutateHistory';
import { SUBSCRIBER_DETAIL_FIELD } from '@/__new__/features/customerCare/common/detailFieldsHelper';
import {
    USER_MANAGER_HIERARCHY,
    handleUserNotFoundError,
    subscriberStateToText,
    subscriberStateToColor,
} from '@/__new__/features/customerCare/common/customerCareHelper';
import { ALERT_TYPES } from '@/common/alerts/Alert';
// TODO: should be renamed to UMEntity State History once old UM deprecated since not Subscriber specific impl
import SubscriberStateHistory from '@/__new__/features/customerCare/subscriber/SubscriberStateHistory.vue';
import RouteNames from '@/router/routeNames';
import luaErrorCodes from '@/common/luaErrors';
import * as Sentry from '@sentry/vue';
import supportButtonMixin from '@/components/alerts/supportButtonMixin';
import { formatAddressLines } from '@/common/formatting';
import { onlyFirstLetterUppercase } from '@/common/utils';
import { getOutageTypeLabel } from '@/__new__/features/customerCare/common/outageHelper';
import ossgpfiberHTTP from '@/__new__/services/dno/ossgpfiber/http/ossgpfiber';
import ExternalResource from '@/__new__/services/dno/ossgpfiber/models/ExternalResource';
import osstmofiberHTTP from '@/__new__/services/dno/osstmofiber/http/osstmofiber';
import OutageInfo from '@/__new__/services/dno/osstmofiber/models/OutageInfo';
import { findActiveOutage } from '@/__new__/features/customerCareSuite/common/outageHelper';

export default Vue.extend({
    name: 'SubscriberPageAnchor',
    components: {
        AppCustomerHeader,
        SubscriberStateHistory,
        DeviceInfoTile,
    },
    mixins: [supportButtonMixin],
    data() {
        return {
            subscriber: new Subscriber({}),
            pukIccidData: {} as { iccid?: string; puk?: string },
            simType: null as SIM_TYPES_MAP | null,
            referralCode: '',
            qrCodeInstallTime: null as number | null,
            esimExternalStatus: '',
            externalResource: new ExternalResource(),
            activeOutage: null,

            USER_MANAGER_HIERARCHY,
        };
    },
    computed: {
        ...mapGetters(Modules.customerCareSuite, [Getters.GET_ASSOCIATED_UM_ENTITY_BY_TYPE_AND_ID]),
        ...mapGetters('userManagementUser', ['getUserVerificationStatusById']),
        ...mapGetters('userManagementSubscriber', ['getSubscriberInfoById', 'getStateHistoryByIdAscending']),
        infoData() {
            let result = [];

            result = [
                {
                    title: this.$i18n.t('customerCare.basicInfo'),
                    value: this.detailsData,
                    additionalLabels: this.detailsStatusIndicators,
                    initiallyExpanded: true,
                },
            ];

            if (this.isDetailFieldEnabled(SUBSCRIBER_DETAIL_FIELD.ADDRESS)) {
                result.push({
                    title: this.$i18n.t('generic.address'),
                    value: this.addressInfo,
                    initiallyExpanded: true,
                });
            }

            if (this.isDetailFieldEnabled(SUBSCRIBER_DETAIL_FIELD.SIM_AND_NUMBER_SECTION)) {
                result.push({
                    title: this.$i18n.t('customerCare.userInformation.simAndNumber'),
                    value: this.simAndNumberData,
                });
            }

            if (this.networkInfo.length) {
                result.push({
                    title: this.$i18n.t('generic.network'),
                    value: this.networkInfo,
                    initiallyExpanded: true,
                });
            }

            if (this.isDetailFieldEnabled(SUBSCRIBER_DETAIL_FIELD.OUTAGE_INFO)) {
                result.push({
                    title: this.$i18n.t('customerCare.account.outageInfo'),
                    value: this.outageInfo,
                    initiallyExpanded: true,
                });
            }

            if (this.isDetailFieldEnabled(SUBSCRIBER_DETAIL_FIELD.FLAGS) && !isEmpty(this.subscriber.flags)) {
                const subscriberFlagsConfig = getOperatorConfigValue(
                    'service_config.lf-user.flags.subscriber_flags',
                    [],
                );
                const isFilteringPossible =
                    Array.isArray(subscriberFlagsConfig) && !getOperatorConfigValue('isFlagFilteringDisabled');

                const subscriberFlags = isFilteringPossible
                    ? pick(this.subscriber?.flags, subscriberFlagsConfig)
                    : this.subscriber?.flags;

                const flags = {
                    title: this.$i18n.t('customerCare.account.flags'),
                    value: Flag.mapFlagStatus(subscriberFlags),
                    additionalLabels: this.flagStatusIndicators,
                    initiallyExpanded: true,
                };

                if (flags.value.length) {
                    result.push(flags);
                }
            }

            if (this.isDetailFieldEnabled(SUBSCRIBER_DETAIL_FIELD.DEVICE_SECTION)) {
                result.push({
                    title: this.$i18n.t('customerCareSuite.deviceInfoSection'),
                    slotKey: 'device',
                    initiallyExpanded: true,
                });
            }

            return result;
        },
        addressInfo() {
            const data = [];

            if (!this.isDetailFieldEnabled(SUBSCRIBER_DETAIL_FIELD.ADDRESS)) {
                return data;
            }

            if (this.subscriber?.addressesMapped?.length) {
                this.subscriber.addressesMapped.forEach(address => {
                    const addressLines = formatAddressLines(address).lines;
                    const addressInfo = {
                        name: address.stringAddressType,
                        value: addressLines,
                    };

                    const eligibilityInfo = {
                        name: address.eligibilityStatusString,
                        value: address.eligibilityStatus,
                    };

                    data.push(addressInfo);
                    if (address.eligibilityStatus) {
                        data.push(eligibilityInfo);
                    }

                    if (this.isDetailFieldEnabled(SUBSCRIBER_DETAIL_FIELD.TAX_AREA_ID) && address.taxAreaId) {
                        data.push({
                            name: this.$i18n.t('customerCare.account.taxAreaID'),
                            value: address.taxAreaId,
                        });
                    }
                });
            }

            return data;
        },
        networkInfo() {
            const data = [];
            if (this.isDetailFieldEnabled(SUBSCRIBER_DETAIL_FIELD.DOCUMENT)) {
                data.push({
                    name: this.$i18n.t('customerCare.document'),
                    value: [
                        {
                            text: this.subscriber.documentType,
                            link: this.subscriber.documentSrc,
                        },
                    ],
                });
            }

            if (this.isDetailFieldEnabled(SUBSCRIBER_DETAIL_FIELD.DOCUMENT_NUMBER)) {
                data.push({
                    name: this.$i18n.t('customerCare.userInformation.documentNumber'),
                    value: this.subscriber.documentNumber,
                });
            }

            if (this.isDetailFieldEnabled(SUBSCRIBER_DETAIL_FIELD.PROVIDER)) {
                data.push({
                    name: this.$i18n.t('customerCare.account.provider'),
                    value: this.subscriber?.providerId ? onlyFirstLetterUppercase(this.subscriber?.providerId) : '',
                });
            }

            if (this.isDetailFieldEnabled(SUBSCRIBER_DETAIL_FIELD.LOCATION_ID)) {
                data.push({
                    name: this.$i18n.t('customerCare.account.locationId'),
                    value: this.subscriber?.addresses?.[0]?.locationId || '',
                });
            }

            if (this.isDetailFieldEnabled(SUBSCRIBER_DETAIL_FIELD.SERVICE_ID)) {
                data.push({
                    name: this.$i18n.t('customerCare.account.serviceId'),
                    value: this.subscriber?.pilotServiceId || '',
                });
            }

            if (this.isDetailFieldEnabled(SUBSCRIBER_DETAIL_FIELD.PROVIDER_PRODUCT)) {
                data.push({
                    name: this.$i18n.t('customerCare.account.providerProduct'),
                    value: this.subscriber?.providerProduct || '',
                });
            }

            if (this.isExternalDataEnabled) {
                if (this.isDetailFieldEnabled(SUBSCRIBER_DETAIL_FIELD.EXTERNAL_SERVICE_ID)) {
                    data.push({
                        name: this.$i18n.t('customerCare.account.serviceId'),
                        value: this.externalResource.serviceId,
                    });
                }
                if (this.isDetailFieldEnabled(SUBSCRIBER_DETAIL_FIELD.ORDER_ID)) {
                    data.push({
                        name: this.$i18n.t('customerCare.orderHistory.orderId'),
                        value: this.externalResource.orderId,
                    });
                }

                if (this.isDetailFieldEnabled(SUBSCRIBER_DETAIL_FIELD.ORDER_ACTION_ID)) {
                    data.push({
                        name: this.$i18n.t('customerCare.account.orderActionId'),
                        value: this.externalResource.orderActionId,
                    });
                }

                if (this.isDetailFieldEnabled(SUBSCRIBER_DETAIL_FIELD.RESERVATION_ID)) {
                    data.push({
                        name: this.$i18n.t('customerCare.account.reservationId'),
                        value: this.externalResource.reservationId,
                    });
                }

                if (this.isDetailFieldEnabled(SUBSCRIBER_DETAIL_FIELD.RESERVED_DP_NAP_ID)) {
                    data.push({
                        name: this.$i18n.t('customerCare.account.reservedDpNapNodeId'),
                        value: this.externalResource.reservedDpNapNodeId,
                    });
                }
            }

            if (this.isDetailFieldEnabled(SUBSCRIBER_DETAIL_FIELD.CVLAN)) {
                data.push({
                    name: this.$i18n.t('customerCare.account.cvlan'),
                    value: this.subscriber?.cvlan,
                });
            }

            if (this.isDetailFieldEnabled(SUBSCRIBER_DETAIL_FIELD.SVLAN)) {
                data.push({
                    name: this.$i18n.t('customerCare.account.svlan'),
                    value: this.subscriber?.svlan,
                });
            }

            if (this.isDetailFieldEnabled(SUBSCRIBER_DETAIL_FIELD.MAC_ADDRESS)) {
                data.push({
                    name: this.$i18n.t('customerCare.account.macAddress'),
                    value: this.subscriber?.macAddress || '',
                });
            }

            if (this.isDetailFieldEnabled(SUBSCRIBER_DETAIL_FIELD.DEVICE_TYPE)) {
                data.push({
                    name: this.$i18n.t('customerCare.account.deviceType'),
                    value: this.subscriber?.deviceType || '',
                });
            }

            if (this.isDetailFieldEnabled(SUBSCRIBER_DETAIL_FIELD.DEVICE_MODEL)) {
                data.push({
                    name: this.$i18n.t('customerCare.account.deviceModel'),
                    value: this.subscriber?.deviceModel || '',
                });
            }

            return data;
        },
        outageInfo() {
            if (!this.activeOutage) {
                return [
                    {
                        name: this.$i18n.t('generic.status'),
                        value: this.$i18n.t('customerCareSuite.outageInfo.noActiveOutage'),
                    },
                ];
            }
            return [
                {
                    name: this.$i18n.t('generic.status'),
                    value: this.activeOutage?.status,
                },
                {
                    name: this.$i18n.t('customerCare.userInformation.outageType'),
                    value: this.$i18n.t(getOutageTypeLabel(this.activeOutage?.outageType)),
                },
                {
                    name: this.$i18n.t('customerCare.userInformation.outageReason'),
                    value: this.activeOutage?.reason,
                },
                {
                    name: this.$i18n.t('generic.description'),
                    value: this.activeOutage?.description,
                },
                {
                    name: this.$i18n.t('customerCare.userInformation.estimatedResolution'),
                    value: this.activeOutage?.estimatedResolution ? `${this.activeOutage.estimatedResolution}h` : '',
                },
                {
                    name: this.$i18n.t('generic.startTime'),
                    value: this.activeOutage?.startTime
                        ? this.$localeLibrary.getFormattedDateAndTime(this.activeOutage.startTime)
                        : '',
                },
                {
                    name: this.$i18n.t('generic.endTime'),
                    value: this.activeOutage?.endTime
                        ? this.$localeLibrary.getFormattedDateAndTime(this.activeOutage.endTime)
                        : '',
                },
                {
                    name: this.$i18n.t('customerCare.userInformation.outageId'),
                    value: this.activeOutage?.id,
                },
            ];
        },
        detailsData() {
            const data = [];

            if (this.isDetailFieldEnabled(SUBSCRIBER_DETAIL_FIELD.SUBSCRIBER_ID)) {
                data.push({
                    name: this.$i18n.t('customerCare.subscriberTab.subscriberID'),
                    value: this.subscriber.id,
                });
            }

            if (this.isDetailFieldEnabled(SUBSCRIBER_DETAIL_FIELD.ACTIVATION_DATE)) {
                data.push({
                    name: this.$i18n.t('generic.activationDate'),
                    value: this.subscriber.activationDate,
                });
            }

            if (this.isDetailFieldEnabled(SUBSCRIBER_DETAIL_FIELD.CREATION_DATE)) {
                data.push({
                    name: this.$i18n.t('customerCare.userInformation.creationDate'),
                    value: this.subscriber.creationDate,
                });
            }

            if (this.isDetailFieldEnabled(SUBSCRIBER_DETAIL_FIELD.SUBSCRIPTION_TYPE)) {
                data.push({
                    name: this.$i18n.t('customerCare.userInformation.subscriptionType'),
                    value: this.subscriber.subscriptionTypeString,
                });
            }

            if (this.isDetailFieldEnabled(SUBSCRIBER_DETAIL_FIELD.MSISDN)) {
                const msisdnLabel = getOperatorConfigValue('msisdnLabel', 'customerCare.msisdn');
                data.push({
                    name: this.$i18n.t(msisdnLabel),
                    value: this.subscriber.msisdn,
                });
            }

            if (this.isDetailFieldEnabled(SUBSCRIBER_DETAIL_FIELD.REFERRAL_CODE)) {
                data.push({
                    name: this.$i18n.t('customerCare.userInformation.referralCode'),
                    value: this.referralCode ?? '',
                });
            }

            if (this.isDetailFieldEnabled(SUBSCRIBER_DETAIL_FIELD.CARDINALITY)) {
                data.push({
                    name: this.$i18n.t('customerCare.userInformation.cardinality'),
                    value: this.subscriber.cardinality,
                });
            }

            return data;
        },
        simAndNumberData() {
            const data = [];

            if (this.isDetailFieldEnabled(SUBSCRIBER_DETAIL_FIELD.MSISDN)) {
                data.push({
                    name: this.$i18n.t('customerCare.msisdn'),
                    value: this.subscriber.msisdn,
                });
            }

            if (this.isDetailFieldEnabled(SUBSCRIBER_DETAIL_FIELD.IMSI)) {
                data.push({
                    name: this.$i18n.t('customerCare.imsi'),
                    value: this.subscriber.imsi,
                });
            }

            if (
                this.isDetailFieldEnabled(SUBSCRIBER_DETAIL_FIELD.PUK_ICCID) ||
                this.isDetailFieldEnabled(SUBSCRIBER_DETAIL_FIELD.ICCID)
            ) {
                data.push({
                    name: this.isSIMTypeESIM
                        ? this.$i18n.t('customerCare.esimICCID')
                        : this.$i18n.t('customerCare.psimICCID'),
                    value: this.pukIccidData.iccid,
                });
            }

            if (
                this.isDetailFieldEnabled(SUBSCRIBER_DETAIL_FIELD.PUK_ICCID) ||
                this.isDetailFieldEnabled(SUBSCRIBER_DETAIL_FIELD.PUK)
            ) {
                data.push({
                    name: this.$i18n.t('customerCare.puk'),
                    value: this.pukIccidData.puk,
                });
            }

            if (this.isDetailFieldEnabled(SUBSCRIBER_DETAIL_FIELD.QR_CODE_INSTALL_TIME) && this.isSIMTypeESIM) {
                data.push({
                    name: this.$i18n.t('customerCare.eSIM.qrCodeInstallTime'),
                    value: this.qrCodeInstallTime
                        ? this.$localeLibrary.getFormattedDateAndTime(this.qrCodeInstallTime)
                        : this.$i18n.t('customerCare.eSIM.notInstalled'),
                });
            }

            if (this.isDetailFieldEnabled(SUBSCRIBER_DETAIL_FIELD.ESIM_EXTERNAL_STATUS) && this.isSIMTypeESIM) {
                data.push({
                    name: this.$i18n.t('customerCare.eSIM.externalStatus'),
                    value: this.esimExternalStatus,
                });
            }

            if (this.showSendQRButton) {
                data.push({
                    name: this.$i18n.t('customerCare.esimQRCode'),
                    value: this.$i18n.t('customerCare.eSIM.sendActivationQR'),
                    onClick: this.onESIMQRSend,
                });
            }

            return data;
        },
        // State History related part
        stateHistory() {
            return this.$route.params.id ? this.getStateHistoryByIdAscending(this.$route.params.id) : [];
        },
        stateHistoryFormatted() {
            return this.stateHistory.map(stateData => ({
                state: stateData.state,
                date: this.$localeLibrary.getFormattedDate(stateData.change_time),
                time: this.$localeLibrary.getFormattedTime(stateData.change_time),
            }));
        },
        subscriberDetailFields() {
            return getSubscriberDetailFields();
        },
        subscriberInfo() {
            return this.$route.params.id ? this.getSubscriberInfoById(this.$route.params.id) : {};
        },
        isUserVerified() {
            return this.subscriber.userId ? this.getUserVerificationStatusById(this.subscriber.userId) : false;
        },
        isEditAllowed() {
            return !this.userAuthenticationEnabled || this.isUserVerified;
        },
        isSIMTypeESIM() {
            return this.simType === SIM_TYPES_MAP.ESIM;
        },
        showSendQRButton() {
            return this.isSIMTypeESIM && isUserAllowed('UMSubscriberSendESIMActivationQR');
        },
        isExternalDataEnabled() {
            return [
                SUBSCRIBER_DETAIL_FIELD.EXTERNAL_SERVICE_ID,
                SUBSCRIBER_DETAIL_FIELD.ORDER_ID,
                SUBSCRIBER_DETAIL_FIELD.ORDER_ACTION_ID,
                SUBSCRIBER_DETAIL_FIELD.RESERVATION_ID,
                SUBSCRIBER_DETAIL_FIELD.RESERVED_DP_NAP_ID,
            ].some(this.isDetailFieldEnabled);
        },
        detailsStatusIndicators() {
            const additionalLabels = [];

            if (Number.isInteger(this.subscriber.state)) {
                additionalLabels.push({
                    title: subscriberStateToText(this.subscriber.state as number),
                    color: subscriberStateToColor(this.subscriber.state as number),
                });
            }

            return additionalLabels;
        },
    },
    async mounted() {
        try {
            const { id } = this.$route.params;

            if (!id) {
                await this.$router.push({
                    name: RouteNames.CCS_SEARCH,
                    params: { companyId: this.$route.params.companyId },
                });
                return;
            }

            if (!isEmpty(this.subscriberInfo)) {
                this.setSubscriber();
            }

            await this.fetchSubscriberById(id);

            const promises = id
                ? [this.fetchReferralCode(), this.fetchStateHistoryById(id), this.getSubscriberSimInfo(id)]
                : [];

            if (this.networkInfo.length) {
                promises.push(this.fetchExternalResource());
            }

            if (this.isDetailFieldEnabled(SUBSCRIBER_DETAIL_FIELD.OUTAGE_INFO)) {
                promises.push(this.fetchOutageData());
            }

            await Promise.all(promises);
        } catch (error) {
            if (handleUserNotFoundError(error)) {
                await this.$router.push({
                    name: RouteNames.CCS_SEARCH,
                    params: { companyId: this.$route.params.companyId },
                });
                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('alertMessage.userManagement.subscriberNotFound'),
                });
            } else {
                this.$showErrorAlert(
                    this.$i18n.t('alertMessage.somethingWentWrongFetchingNecessaryData'),
                    ALERT_TYPES.error,
                );
            }
        }
    },
    methods: {
        ...mapActions('userManagementSubscriber', ['getSubscriberInfo', 'getStateHistory']),
        ...mapActions('customerCare', [Actions.SET_CURRENT_SUBSCRIBER_ID]),
        fetchStateHistory() {
            this.$withProgressBar(
                async () => {
                    await this.getStateHistory(this.$route.params.id);
                },
                {
                    errorHandler: () => {
                        this.$showErrorAlert({
                            message: this.$i18n.t('alertMessage.somethingWentWrongFetchingNecessaryData'),
                        });
                    },
                },
            );
        },
        isDetailFieldEnabled(fieldName) {
            return this.subscriberDetailFields.includes(fieldName);
        },
        setSubscriber() {
            this.subscriber = new Subscriber(Subscriber.remapUserFromBe(this.subscriberInfo));
            this[Actions.SET_CURRENT_SUBSCRIBER_ID](this.subscriber.id);
        },
        async fetchSubscriberById(id) {
            try {
                this.$Progress.start();
                await this.getSubscriberInfo(id);
                this.setSubscriber();

                this.$Progress.finish();
            } catch (error) {
                this.$Progress.fail();
                throw error;
            }
        },
        async getSubscriberSimInfo(id) {
            if (
                ![
                    SUBSCRIBER_DETAIL_FIELD.PUK_ICCID,
                    SUBSCRIBER_DETAIL_FIELD.ICCID,
                    SUBSCRIBER_DETAIL_FIELD.PUK,
                    SUBSCRIBER_DETAIL_FIELD.QR_CODE_INSTALL_TIME,
                    SUBSCRIBER_DETAIL_FIELD.ESIM_EXTERNAL_STATUS,
                ].some(this.isDetailFieldEnabled) ||
                !this.subscriber.msisdn
            ) {
                return;
            }

            this.$Progress.start();
            try {
                const {
                    data: { profile_data: profileData },
                } = await customerCareHTTP.getProfileInfo({
                    targetId: id,
                    targetType: USER_MANAGER_HIERARCHY.SUBSCRIBER,
                    tokenType: AUTH_ENUM_TOKEN_TYPE.TENANT,
                });

                this.simType = profileData?.sim_type;
                this.pukIccidData = {
                    iccid: profileData?.iccid || '',
                    puk: profileData?.puk || '',
                };

                const mutateHistory = invert(profileData?.mutate_history) as Record<SIM_MUTATE_HISTORY, string>;
                this.qrCodeInstallTime = mutateHistory[SIM_MUTATE_HISTORY.INSTALLED]
                    ? parseInt(mutateHistory[SIM_MUTATE_HISTORY.INSTALLED], 10)
                    : null;

                await this.getESIMExternalStatus();
            } catch (e) {
                Sentry.captureException(e);
            }
            this.$Progress.finish();
        },
        async getESIMExternalStatus() {
            if (
                !this.isSIMTypeESIM ||
                !this.pukIccidData.iccid ||
                !this.isDetailFieldEnabled(SUBSCRIBER_DETAIL_FIELD.ESIM_EXTERNAL_STATUS)
            ) {
                return;
            }
            try {
                const { data } = await ossmvneHTTP.getEsimProfile(this.pukIccidData.iccid);
                this.esimExternalStatus = data?.profiles?.[0]?.profileState || '';
            } catch (e) {
                Sentry.captureException(e);
            }
        },
        async fetchReferralCode() {
            if (this.isDetailFieldEnabled(SUBSCRIBER_DETAIL_FIELD.REFERRAL_CODE)) {
                try {
                    this.$Progress.start();
                    const {
                        data: { referral_code: refCode },
                    } = await customerCareHTTP.getReferralCode(
                        this.$route.params.id,
                        USER_MANAGER_HIERARCHY.SUBSCRIBER,
                    );
                    this.referralCode = refCode;
                    this.$Progress.finish();
                } catch (error) {
                    this.$Progress.fail();
                    this.showSupportAlert(
                        this.$i18n.t('alertMessage.somethingWentWrongFetchingNecessaryData'),
                        ALERT_TYPES.error,
                    );
                    Sentry.captureException(error);
                }
            }
        },
        async fetchStateHistoryById(id) {
            try {
                this.$Progress.start();

                await this.getStateHistory(id);

                this.$Progress.finish();
            } catch (error) {
                this.$Progress.fail();
                this.showSupportAlert(
                    this.$i18n.t('alertMessage.somethingWentWrongFetchingNecessaryData'),
                    ALERT_TYPES.error,
                );
                Sentry.captureException(error);
            }
        },
        async onESIMQRSend() {
            try {
                this.$Progress.start();
                await customerCareHTTP.sendESIMActivationQR(this.$route.params.id);
                this.showSupportAlert(this.$i18n.t('customerCare.eSIM.QRSuccess'), ALERT_TYPES.success);
                this.$Progress.finish();
            } catch (err) {
                this.$Progress.fail();
                Sentry.captureException(err);
                let errorMessage = this.$i18n.t('generic.somethingWentWrong');
                const luaErrorCode = err?.response?.data?.code;
                const luaModule = err?.response?.data?.module;
                if (luaModule === 'SIM') {
                    if (luaErrorCode === luaErrorCodes.SIM.MORE_THAN_ONE_ACTIVE_ICCID.code) {
                        errorMessage = this.$i18n.t('customerCare.eSIM.moreThanOneICCID');
                    } else if (luaErrorCode === luaErrorCodes.SIM.INTERNAL_ERROR.code) {
                        errorMessage = this.$i18n.t('customerCare.eSIM.failedToSendEmailQR');
                    }
                } else if (luaModule === 'OSA' && luaErrorCode === luaErrorCodes.OSA.INVALID_EXTERNAL_RESPONSE.code) {
                    errorMessage = this.$i18n.t('customerCare.eSIM.failedToGenerateQR');
                }
                this.showSupportAlert(errorMessage, ALERT_TYPES.error);
            }
        },
        fetchExternalResource() {
            if (this.isExternalDataEnabled) {
                this.$withProgressBar(
                    async () => {
                        const res = await ossgpfiberHTTP.getResource(
                            this.$route.params.id,
                            USER_MANAGER_HIERARCHY.SUBSCRIBER,
                        );
                        this.externalResource = new ExternalResource(
                            ExternalResource.mapResourceFromBE(res?.data?.resource),
                        );
                    },
                    {
                        errorHandler: () => {
                            this.$showErrorAlert({
                                message: this.$i18n.t('alertMessage.somethingWentWrongFetchingNecessaryData'),
                            });
                        },
                    },
                );
            }
        },
        async fetchOutageData() {
            await this.$withProgressBar(
                async () => {
                    const outageHistoryApiResponse = await osstmofiberHTTP.getOutageHistory(
                        this.$route?.params?.id,
                        USER_MANAGER_HIERARCHY.SUBSCRIBER,
                    );
                    const outageHistory = outageHistoryApiResponse?.data?.outage_history;
                    if (outageHistory && Array.isArray(outageHistory)) {
                        const outageHistoryRemapped = outageHistory.map(
                            o => new OutageInfo(OutageInfo.mapOutageFromBe(o)),
                        );
                        this.activeOutage = findActiveOutage(outageHistoryRemapped);
                    }
                    if (this.activeOutage) {
                        this.$alert(this.$i18n.t('alertMessage.userManagement.outageIsOngoing'), {
                            type: ALERT_TYPES.warning,
                        });
                    }
                },
                {
                    errorHandler: () => {
                        this.$alert(this.$i18n.t('alertMessage.userManagement.somethingWentWrongFetchingOutageData'));
                    },
                },
            );
        },
    },
});
