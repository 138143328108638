import http from '@/http/index';
import { AxiosPromise } from 'axios';
import { TARGET_TYPE, TargetTuple } from '@/__new__/services/dno/user/models/targetTuple';
import { getDnoClaimsHeader, USER_MANAGER_HIERARCHY } from '@/__new__/features/customerCare/common/customerCareHelper';
import { DnoResponseBase } from '@/http/index';
import { OrderBE, OrderId } from '@/__new__/features/customerCare/common/ordersHelper';
import { DeliveryBE } from '@/__new__/features/customerCare/common/deliveriesHelper';
import { SIM_TYPES_MAP } from '@/__new__/features/customerCare/subscriber/common/simTypes';

interface CancelReplaceOrderData {
    accountId: string;
}

interface TerminateAccountData extends TargetTuple {
    reasonCode?: string;
    reasonTxt?: string;
    subscriberId?: string;
}

interface OeGetOrdersData extends TargetTuple {
    entityType: string;
    subordersDisabled: boolean;
}

interface OeCancelOrderData extends TargetTuple {
    orderId?: string;
}

interface ReplaceOrderData extends TargetTuple {
    offerId: string;
    entityType: string;
    changeType: number;
}

/**
 * Used for getting rid of everything from the response data except entities
 * @returns {{}}
 * @param responseData
 */
function getEntitiesFromResponseData(responseData: any): any {
    delete responseData.code;
    delete responseData.module;
    delete responseData.server_time;
    return responseData;
}

/**
 * Proxy call to /api/v3/orderoe/get_order_entities
 * @param subscriberId
 * @param entityType
 */
function oeGetOrders({
    targetId,
    entityType,
    targetType = TARGET_TYPE.ACCOUNT,
    subordersDisabled = false,
}: OeGetOrdersData): AxiosPromise<
    DnoResponseBase & {
        orders: Record<OrderId, Partial<OrderBE>>;
        has_more_pages?: number;
        paging_state?: string;
    }
> {
    return http({
        method: 'POST',
        url: '/v3ordermanager/getorderentities',
        headers: {
            'Dno-Claims': JSON.stringify(getDnoClaimsHeader(targetId, targetType)),
        },
        data: {
            target_id: targetId,
            target_type: targetType,
            entity_types: entityType,
            include_promo_info: true,
            include_applicable_promo_info: true,
            include_order_state_history: true,
            ...(subordersDisabled && { sub_orders_disabled: subordersDisabled }),
        },
    });
}

function replaceOrder({
    targetId,
    offerId,
    entityType,
    changeType,
    targetType = TARGET_TYPE.ACCOUNT,
}: ReplaceOrderData): AxiosPromise<DnoResponseBase> {
    return http({
        method: 'POST',
        url: '/v3ordermanagerwrite/replaceorder',
        headers: {
            'Dno-Claims': JSON.stringify(getDnoClaimsHeader(targetId, targetType)),
        },
        data: {
            entity_id: offerId,
            target_id: targetId,
            target_type: targetType,
            change_type: changeType,
            entity_type: entityType,
        },
    });
}

function cancelReplaceOrder({ accountId }: CancelReplaceOrderData): AxiosPromise<DnoResponseBase> {
    return http({
        method: 'POST',
        url: '/v3ordermanagerwrite/cancelreplaceorder',
        headers: {
            'Dno-Claims': JSON.stringify(getDnoClaimsHeader(accountId, TARGET_TYPE.ACCOUNT)),
        },
        data: {
            target_id: accountId,
            target_type: USER_MANAGER_HIERARCHY.ACCOUNT,
        },
    });
}

/**
 * Proxy call to /api/v3/orderoe/activate_order
 * @param targetId
 * @param targetType
 * @param orderId
 */
function oeActivateOrder({
    targetId,
    targetType,
    orderId = '',
}: {
    targetId: string;
    targetType: TARGET_TYPE;
    orderId: string;
}) {
    return http({
        method: 'POST',
        url: '/v3ordermanagerwrite/oeactivateorder',
        headers: {
            'Dno-Claims': JSON.stringify(getDnoClaimsHeader(targetId, targetType)),
        },
        data: {
            target_id: targetId,
            target_type: targetType,
            ...(orderId && { order_id: orderId }),
        },
    });
}

/**
 * Proxy call to /api/v3/orderoe/cancel_order
 * @param targetId
 * @param targetType
 * @param orderId
 */
function oeCancelOrder({ targetId, targetType, orderId = '' }: OeCancelOrderData): AxiosPromise<DnoResponseBase> {
    return http({
        method: 'POST',
        url: '/v3ordermanagerwrite/oecancelorder',
        headers: {
            'Dno-Claims': JSON.stringify(getDnoClaimsHeader(targetId, targetType)),
        },
        data: {
            target_id: targetId,
            target_type: targetType,
            ...(orderId && { order_id: orderId }),
        },
    });
}

function changeOrder({
    accountId,
    orderId,
    removeIds,
    replaceIds,
    addIds,
    voucherCodes,
}: {
    accountId: string;
    orderId: string;
    removeIds?: string[];
    replaceIds?: { from: string; to: string }[];
    addIds?: string[];
    voucherCodes?: string[];
}): AxiosPromise<any> {
    return http({
        method: 'POST',
        url: '/v3ordermanagerwrite/changeorder',
        headers: {
            'Dno-Claims': JSON.stringify(getDnoClaimsHeader(accountId, TARGET_TYPE.ACCOUNT)),
        },
        data: {
            target_id: accountId,
            target_type: TARGET_TYPE.ACCOUNT,
            order_id: orderId,
            ...(replaceIds && replaceIds.length && { replace: replaceIds }),
            ...(removeIds && removeIds.length && { remove: removeIds }),
            ...(addIds && addIds.length && { add: addIds }),
            ...(voucherCodes && voucherCodes.length && { voucher_codes: voucherCodes }),
        },
    });
}

/**
 *
 * @param {string} accountId
 * @param {string} reservationId
 * @returns {AxiosPromise<{ reservation_id: string, execution_id: string}>} AxiosPromise
 */
function completePreorder({
    targetId,
    targetType,
    reservationId,
}: {
    targetId: string;
    targetType: number;
    reservationId: string;
}) {
    return http({
        method: 'POST',
        url: '/v3ordermanagerwrite/completepreorder',
        headers: {
            'Dno-Claims': JSON.stringify(getDnoClaimsHeader(targetId, targetType)),
        },
        data: {
            target_id: targetId,
            target_type: targetType,
            appointment_id: reservationId,
        },
    });
}

/**
 * Proxy call to /api/v3/orderoe/request_termination
 *
 * @param {Object} data - Terminate subscription request data
 * @param {string} data.targetId - Account Id
 * @param {number} data.targetType - Account type Id
 * @param {'user_requested'|'fraud'=} data.reasonCode - Termination reason code
 * @param {string=} data.reasonTxt - Termination reason text
 * @param {string=} data.subscriberId - Subscriber Id
 * @returns {AxiosPromise} AxiosPromise
 */
function terminateAccount({
    targetId,
    targetType,
    reasonCode,
    reasonTxt,
    subscriberId,
}: TerminateAccountData): AxiosPromise<DnoResponseBase> {
    return http({
        method: 'POST',
        url: '/v3ordermanagerwrite/requesttermination',
        headers: {
            'Dno-Claims': JSON.stringify(getDnoClaimsHeader(targetId, targetType)),
        },
        data: {
            target_id: targetId,
            target_type: targetType,
            ...(reasonCode && { termination_reason_code: reasonCode }),
            ...(reasonTxt && { termination_reason_string: reasonTxt }),
            ...(subscriberId && { subscriber_id: subscriberId }),
        },
    });
}

function oeGetUserData({ targetId, targetType }: TargetTuple): AxiosPromise<DnoResponseBase> {
    return http({
        method: 'POST',
        url: '/v3ordermanager/oegetuserdata',
        headers: {
            'Dno-Claims': JSON.stringify(getDnoClaimsHeader(targetId, targetType)),
        },
        data: {
            target_id: targetId,
            target_type: targetType,
        },
    });
}

function getOrderCounts(offerIds: string[]): AxiosPromise<DnoResponseBase> {
    return http({
        method: 'POST',
        url: '/v3ordermanager/getallcounts',
        data: {
            data: offerIds.map(offerId => ({
                count_id: offerId,
                count_type: 'offer',
            })),
        },
    });
}

/**
 * Fetches subscriber balances
 * @returns {Promise<void>}
 * @param targetId
 * @param targetType
 * @param ignoreInactive
 */
async function getBalances(
    targetId: string,
    targetType: number,
    ignoreInactive = false,
): Promise<AxiosPromise<DnoResponseBase>> {
    const response = await http({
        method: 'POST',
        url: '/v3ordermanager/getbalances',
        headers: {
            'Dno-Claims': JSON.stringify(getDnoClaimsHeader(targetId, targetType)),
        },
        data: {
            target_type: targetType,
            target_id: targetId,
            ignoreInactive,
        },
    });

    return getEntitiesFromResponseData(response.data);
}

/**
 * Fetches subscriber balances
 * @returns {Promise<void>}
 * @param subscriberId
 */
async function getSharedWalletBalances(targetId: string, targetType: TARGET_TYPE, ignoreInactive = false) {
    const response = await http({
        method: 'POST',
        url: '/v3ordermanager/getsharedwalletbalances',
        headers: {
            'Dno-Claims': JSON.stringify(getDnoClaimsHeader(targetId, targetType)),
        },
        data: {
            target_type: targetType,
            target_id: targetId,
            ignoreInactive,
        },
    });

    return getEntitiesFromResponseData(response.data);
}

/**
 *
 * @param subscriberId
 * @param startTimestamp
 * @param endTimestamp
 * @param type
 * @param maxResults
 * @param targetType
 * @returns {AxiosPromise}
 */
function getTransactionsStreaming(
    subscriberId: string,
    startTimestamp: number,
    endTimestamp: number,
    type: string,
    maxResults: number,
    targetType: number = USER_MANAGER_HIERARCHY.SUBSCRIBER,
): AxiosPromise<DnoResponseBase> {
    return http({
        method: 'POST',
        url: '/v3chargingtransaction/gettransactions',
        headers: {
            'Dno-Claims': JSON.stringify({
                subscriber_id: subscriberId,
            }),
        },
        data: {
            target_id: subscriberId,
            target_type: targetType,
            start_timestamp: startTimestamp,
            type,
            end_timestamp: endTimestamp,
            max_results: maxResults,
        },
    });
}

/**
 *
 * @param subscriberId
 * @param startTimestamp
 * @param endTimestamp
 * @param type
 * @param maxResults
 * @param targetType
 * @returns {AxiosPromise}
 */
function getTransactionsStreamingMasked(
    subscriberId: string,
    startTimestamp: number,
    endTimestamp: number,
    type: string,
    maxResults: number,
    targetType: number = USER_MANAGER_HIERARCHY.SUBSCRIBER,
): AxiosPromise<DnoResponseBase> {
    return http({
        method: 'POST',
        url: '/v3chargingtransaction/gettransactionsmasked',
        headers: {
            'Dno-Claims': JSON.stringify({
                subscriber_id: subscriberId,
            }),
        },
        data: {
            target_id: subscriberId,
            target_type: targetType,
            start_timestamp: startTimestamp,
            type,
            end_timestamp: endTimestamp,
            max_results: maxResults,
        },
    });
}

function getTransactions(id: string, targetType: number, sortType: string): AxiosPromise<DnoResponseBase> {
    const data = {
        target_id: id,
        target_type: targetType,
        ...(sortType && { sort_type: sortType }),
    };

    return http({
        method: 'POST',
        url: '/v3ordermanager/gettransactions',
        headers: {
            'Dno-Claims': JSON.stringify(getDnoClaimsHeader(id, targetType)),
        },
        data,
    });
}

function getPaymentTransactionsDetails(id: string, targetType: number): AxiosPromise<DnoResponseBase> {
    const data = {
        target_id: id,
        target_type: targetType,
    };

    return http({
        method: 'POST',
        url: '/v3ordermanager/getpaymenttransactionsdetails',
        headers: {
            'Dno-Claims': JSON.stringify(getDnoClaimsHeader(id, targetType)),
        },
        data,
    });
}

function getUnpaidBills(id: string, targetType: number): AxiosPromise<DnoResponseBase> {
    const data = {
        target_id: id,
        target_type: targetType,
    };

    return http({
        method: 'POST',
        url: '/v3ordermanager/getunpaidbills',
        headers: {
            'Dno-Claims': JSON.stringify(getDnoClaimsHeader(id, targetType)),
        },
        data,
    });
}

function getBills(id: string, targetType: number, limit: number): AxiosPromise<DnoResponseBase> {
    const data = {
        target_id: id,
        target_type: targetType,
        limit,
    };

    return http({
        method: 'POST',
        url: '/v3ordermanager/getbills',
        headers: {
            'Dno-Claims': JSON.stringify(getDnoClaimsHeader(id, targetType)),
        },
        data,
    });
}

function getBillingAccount(id: string, targetType: number): AxiosPromise<DnoResponseBase> {
    const data = {
        target_id: id,
        target_type: targetType,
    };

    return http({
        method: 'POST',
        url: '/v3ordermanager/getbillingaccount',
        headers: {
            'Dno-Claims': JSON.stringify(getDnoClaimsHeader(id, targetType)),
        },
        data,
    });
}

function getCharges(payload: any): AxiosPromise<DnoResponseBase> {
    return http({
        method: 'POST',
        url: '/v3ordermanager/getcharges',
        headers: {
            'Dno-Claims': JSON.stringify(getDnoClaimsHeader(payload.targetId, payload.targetType)),
        },
        data: payload,
    });
}

/**
 * @returns {AxiosPromise}
 * @param accountId
 */
function getOrdersOmDevEdge(accountId: string): AxiosPromise<DnoResponseBase> {
    return http({
        method: 'POST',
        url: '/v3omdevedge/getorders',
        headers: {
            'Dno-Claims': JSON.stringify(getDnoClaimsHeader(accountId, TARGET_TYPE.ACCOUNT)),
        },
        data: {
            target_id: accountId,
            target_type: TARGET_TYPE.ACCOUNT,
        },
    });
}

/**
 * @returns {AxiosPromise}
 * @param targetId
 * @param targetType
 */
interface DeliveryDetailsResponse extends DnoResponseBase {
    data: DeliveryBE[];
}

function getDeliveryDetails({ targetId, targetType }: TargetTuple): AxiosPromise<DeliveryDetailsResponse> {
    return http({
        method: 'POST',
        url: '/v3ordermanager/getdeliverydetails',
        headers: {
            'Dno-Claims': JSON.stringify(getDnoClaimsHeader(targetId, targetType)),
        },
        data: {
            target_id: targetId,
            target_type: targetType,
        },
    });
}

function getTerminationTime(data: { target_ids: string[] }): AxiosPromise<DeliveryDetailsResponse> {
    return http({
        method: 'POST',
        url: '/v3ordermanager/getTerminationTime',
        data,
    });
}

function upsertOrderState({
    targetId,
    targetType,
    orderId,
    orderState,
}: TargetTuple & {
    orderId: string;
    orderState: number;
}): AxiosPromise<DnoResponseBase> {
    return http({
        method: 'POST',
        url: '/v3ordermanagerwrite/upsertorderstate',
        headers: {
            'Dno-Claims': JSON.stringify(getDnoClaimsHeader(targetId, targetType)),
        },
        data: {
            target_id: targetId,
            target_type: targetType,
            order_id: orderId,
            order_state: orderState,
        },
    });
}

function createOrder({
    target_id,
    target_type,
    items,
    skip_charges = false,
}: {
    target_id: string;
    target_type: number;
    items: {
        entity_id: string;
        entity_type?: string;
        characteristics?: any;
        target_id?: string;
        target_type?: number;
    }[];
    skip_charges?: boolean;
}): AxiosPromise<DnoResponseBase> {
    return http({
        method: 'POST',
        url: '/v3order/createOrder',
        headers: {
            'Dno-Claims': JSON.stringify(getDnoClaimsHeader(target_id, target_type)),
        },
        data: {
            target_id,
            target_type,
            items: items.map(item => ({
                characteristics: {},
                entity_type: 'offer',
                target_id,
                target_type,
                ...item,
            })),
            skip_charges,
        },
    });
}

export function changeMSISDN(
    target_id: string,
    target_type: number,
    market_zip: string,
    msisdn: string,
    iccid: string,
): AxiosPromise<DnoResponseBase> {
    return http({
        method: 'POST',
        url: '/v3order/changemsisdn',
        data: {
            target_id,
            target_type,
            msisdn,
            iccid,
            additional_params: {
                market_zip,
            },
        },
    });
}

export function deactivateSubscriber(target_id: string, target_type: number): AxiosPromise<DnoResponseBase> {
    return http({
        method: 'POST',
        url: '/v3order/deactivatesubscriber',
        data: {
            target_id,
            target_type,
        },
    });
}

export function cancelDeactivateSubscriber(target_id: string, target_type: number): AxiosPromise<DnoResponseBase> {
    return http({
        method: 'POST',
        url: '/v3order/canceldeactivatesubscriber',
        data: {
            target_id,
            target_type,
        },
    });
}

export function suspendLine(target_id: string, target_type: number, reason: string): AxiosPromise<DnoResponseBase> {
    return http({
        method: 'POST',
        url: '/v3order/suspendline',
        data: {
            target_id,
            target_type,
            reason,
        },
    });
}

export function resumeSuspendLine(
    target_id: string,
    target_type: number,
    should_unblock_imei: boolean,
): AxiosPromise<DnoResponseBase> {
    return http({
        method: 'POST',
        url: '/v3order/resumesuspendline',
        data: {
            target_id,
            target_type,
            should_unblock_imei,
        },
    });
}

function swapSim({
    target_id,
    target_type,
    sim_type_new,
    offer_id,
    iccid_new,
}: {
    target_id: string;
    target_type: number;
    sim_type_new: SIM_TYPES_MAP;
    offer_id: string;
    iccid_new?: string;
}): AxiosPromise<DnoResponseBase> {
    return http({
        method: 'POST',
        url: '/v3order/swapSim',
        headers: {
            'Dno-Claims': JSON.stringify(getDnoClaimsHeader(target_id, target_type)),
        },
        data: {
            target_id,
            target_type,
            sim_type_new,
            offer_id,
            iccid_new,
        },
    });
}

function createOrderExternal(data: {
    target_id: string;
    target_type: number;
    external_transaction_id: string;
    entity_ids: Array<{
        id: string;
        type: string;
    }>;
}) {
    return http({
        method: 'POST',
        url: '/v3ordermanager/createOrderExternal',
        data,
    });
}

export default {
    getEntitiesFromResponseData,
    getOrderCounts,
    oeGetOrders,
    changeOrder,
    completePreorder,
    oeActivateOrder,
    oeCancelOrder,
    getBalances,
    getSharedWalletBalances,
    getTransactionsStreaming,
    getTransactionsStreamingMasked,
    replaceOrder,
    cancelReplaceOrder,
    getOrdersOmDevEdge,
    getTransactions,
    terminateAccount,
    oeGetUserData,
    getDeliveryDetails,
    upsertOrderState,
    getPaymentTransactionsDetails,
    getUnpaidBills,
    getBills,
    getBillingAccount,
    getCharges,
    getTerminationTime,
    createOrder,
    changeMSISDN,
    deactivateSubscriber,
    cancelDeactivateSubscriber,
    suspendLine,
    resumeSuspendLine,
    swapSim,
    createOrderExternal,
};
