import { AxiosPromise } from 'axios';
import http from '@/http/index';

export type Tenant = {
    companyId: number;
    name: string;
    description: string;
};

export function getTenants(): AxiosPromise<Record<number, Tenant>> {
    return http({
        method: 'GET',
        url: '/lfinternal/company',
    });
}

export function deleteTenant(data: { companyId: number }): AxiosPromise<unknown> {
    return http({
        method: 'DELETE',
        url: '/lfinternalwrite/company',
        data,
    });
}

export type AddTenentRequestBody = {
    name: string;
    data: string; // description
    notes: string;
    operatorName: string;
};

export function addTenant(data: AddTenentRequestBody): AxiosPromise<unknown> {
    return http({
        method: 'POST',
        url: '/lfinternalwrite/company',
        data,
    });
}

export type UpdateTenentRequestBody = {
    name: string;
    data: string; // description
    notes: string;
    operatorName: string;
};

export function updateTenant(data: UpdateTenentRequestBody): AxiosPromise<unknown> {
    return http({
        method: 'PUT',
        url: '/lfinternalwrite/company',
        data,
    });
}

export default {};
