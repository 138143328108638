<template>
    <AppDialogV2
        :title="formTitle"
        :visible="value"
        @close="close()"
        @submit="onSubmit()"
    >
        <div class="form-content">
            <h3
                v-if="isCancelAction"
                class="body-sm bold blue mb-3"
            >
                {{ $i18n.t('customerCare.cancelRequest.informAboutDeactivation') }}
            </h3>
            <div
                v-if="isCancelAction"
                class="reason mb-3"
            >
                <h3 class="heading-xs blue mb-2">
                    {{ $i18n.t('customerCare.cancelRequest.cancelRequestReason') }}
                </h3>

                <AppMultiselectV3
                    v-model="reason"
                    :options="reasonOptions"
                    :error="$v.reason.$error"
                    :errorMessage="$i18n.t('generic.validations.fieldIsRequired')"
                    label="label"
                />
            </div>

            <div class="mb-3">
                <h3 class="heading-xs blue mb-2">
                    {{ $i18n.t('generic.notes') }}
                </h3>

                <CustomTextarea
                    v-model="notesTxt"
                    :placeholder="$i18n.t('generic.addNotes')"
                    :invalid="isNotesInvalid"
                    class="input"
                />

                <p
                    v-show="isNotesInvalid"
                    class="error-message mt-2"
                >
                    {{ $i18n.t('generic.validations.fieldIsRequired') }}
                </p>
            </div>
        </div>
    </AppDialogV2>
</template>

<script>
// Components
import AppDialogV2 from '@/components/partials/AppDialogV2.vue';
import { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import CustomTextarea from '@/__new__/features/customerCare/subscriber/CustomTextarea.vue';
import AppMultiselectV3 from '@/components/partials/inputs/AppMultiselectV3.vue';
// http
import ossmvneHTTP from '@/__new__/services/dno/ossmvne/http/ossmvne';
import notesHTTP from '@/__new__/services/dno/crm/http/crm';
// Helpers
import {
    USER_MANAGER_HIERARCHY,
    SUBSCRIBER_CANCEL_REQUEST_ACTIONS,
    SUBSCRIBER_CANCEL_REQUEST_REASON_CODE,
} from '@/__new__/features/customerCare/common/customerCareHelper';
import { required } from 'vuelidate/lib/validators';
import { dateToEpoch } from '@/common/formatting';
import Actions from '@/store/mutation-types';
import { mapActions } from 'vuex';
import { Modules } from '@/store/store';

export default {
    name: 'CancelRequest',
    components: {
        AppDialogV2,
        CustomTextarea,
        AppMultiselectV3,
    },
    props: {
        value: {
            required: true,
            type: Boolean,
        },
        action: {
            required: true,
            type: String,
        },
        subscriberId: {
            default: '',
            type: String,
        },
    },
    data() {
        return {
            reason: '',
            notesTxt: '',
            shouldUnblockIMEI: false,
            BUTTON_TYPES,
        };
    },
    computed: {
        isNotesInvalid() {
            return this?.$v?.notesTxt?.$error;
        },
        isCancelAction() {
            return this.action === SUBSCRIBER_CANCEL_REQUEST_ACTIONS.CANCEL;
        },
        reasonOptions() {
            return [
                {
                    id: SUBSCRIBER_CANCEL_REQUEST_REASON_CODE.HIGH_COST,
                    label: this.$i18n.t('customerCare.subscriberTab.highCost'),
                },
                {
                    id: SUBSCRIBER_CANCEL_REQUEST_REASON_CODE.BAD_NETWORK,
                    label: this.$i18n.t('customerCare.subscriberTab.badNetwork'),
                },
            ];
        },
        formTitle() {
            return this.isCancelAction
                ? this.$i18n.t('customerCare.cancelRequest.cancelRequest')
                : this.$i18n.t('customerCare.cancelRequest.undoCancelRequest');
        },
    },
    validations() {
        return {
            reason: {
                required: this.isCancelAction ? required : false,
            },
            notesTxt: {
                required,
            },
        };
    },
    methods: {
        ...mapActions(Modules.customerCareSuite, [Actions.LOAD_ASSOCIATED_UM_ENTITIES]),
        close() {
            this.$emit('close');
        },
        onSubmit() {
            this.$v.$touch();
            if (this.$v.$invalid) {
                return;
            }

            this.submit();
            this.close();
        },
        async submit() {
            if (this.isCancelAction) {
                await this.cancelRequest().then(this.setAgentNote());
            } else {
                await this.undoCancelRequest().then(this.setAgentNote());
            }
        },
        async setAgentNote() {
            await this.$withProgressBar(
                () =>
                    notesHTTP.addNote({
                        id: this.subscriberId,
                        idType: USER_MANAGER_HIERARCHY.SUBSCRIBER,
                        epoch: dateToEpoch(Date.now()),
                        noteText: this.reason ? `${this.reason.label} : ${this.notesTxt}` : this.notesTxt,
                        tags: [this.$i18n.t('generic.stateMap.canceled')],
                    }),
                {
                    errorHandler: () =>
                        this.$showErrorAlert({
                            message: this.$i18n.t('alertMessage.somethingWentWrongSavingAgentNotes'),
                        }),
                },
            );
        },
        async cancelRequest() {
            await this.$withProgressBar(
                async () => {
                    await ossmvneHTTP.requestCancelLine(this.subscriberId, USER_MANAGER_HIERARCHY.SUBSCRIBER);
                    await this[Actions.LOAD_ASSOCIATED_UM_ENTITIES]({
                        targetType: USER_MANAGER_HIERARCHY.SUBSCRIBER,
                        targetId: this.subscriberId,
                    });
                },
                {
                    errorHandler: () =>
                        this.$showErrorAlert({
                            message: this.$i18n.t('customerCare.cancelRequest.alerts.cancelRequestFailed'),
                        }),
                },
            );
        },
        async undoCancelRequest() {
            await this.$withProgressBar(
                async () => {
                    await ossmvneHTTP.cancelLineCancellationRequest(
                        this.subscriberId,
                        USER_MANAGER_HIERARCHY.SUBSCRIBER,
                    );
                    await this[Actions.LOAD_ASSOCIATED_UM_ENTITIES]({
                        targetType: USER_MANAGER_HIERARCHY.SUBSCRIBER,
                        targetId: this.subscriberId,
                    });
                },
                {
                    errorHandler: () =>
                        this.$showErrorAlert({
                            message: this.$i18n.t('customerCare.cancelRequest.alerts.undoCancelRequestFailed'),
                        }),
                },
            );
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/_consistency';

.form-content {
    padding: $spacing-m $spacing-xxxl;
}

.error-message {
    color: $red;
}

#app .reason ::v-deep .multiselect__tags {
    padding-left: $spacing-xs;
}

.form-content ::v-deep textarea {
    width: 100%;
    padding-inline: $spacing-xs;
    border-radius: 0.5rem;

    &::placeholder {
        font-style: italic;
        opacity: 1;
        font-size: inherit;
    }
}
</style>
