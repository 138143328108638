<template>
    <div class="d-flex align-items-center period">
        <Dropdown
            :items="operationLabels"
            :colorSchema="Styles.White"
            :selected="[value && PeriodOperationLabels[value.op]]"
            class="mr-3"
            :isFilterOperation="true"
            :widthDependsOnContent="true"
            @selected="opLabel => onChange({ op: PeriodLabelsToOperations[opLabel] })"
        />

        <!-- todo pass isValid -->
        <FNumberInput
            v-show="isNumberInputDisplayed && !isNoArgOperation"
            ref="periodInput"
            :initialValue="defaultPeriodValue"
            :displayOperation="false"
            :displayPeriod="true"
            :periodUnitLabels="Object.values(PeriodUnitsLabels)"
            :changeCallback="changeCallback"
            @change="onChange"
        />

        <FDateInput
            v-show="isDateInputDisplayed && !isNoArgOperation"
            ref="dateInput"
            :initialValue="defaultDateValue"
            :displayOperation="false"
            @change="dateValue => onChange({ dateValue: dateValue.value })"
        />

        <Dropdown
            v-show="isMonthInputDisplayed"
            :items="months"
            :colorSchema="Styles.White"
            :selected="[value && months[value.monthValue]]"
            class="mr-3"
            @selected="monthLabel => onChange({ monthValue: months.indexOf(monthLabel) })"
        />
    </div>
</template>

<script>
import moment from 'moment';
import FNumberInput from '@/components/partials/filters/uiComponents/FNumberInput.vue';
import Dropdown, { Styles } from '@/components/partials/Dropdown.vue';
import {
    PeriodOperationLabels,
    PeriodLabelsToOperations,
    NumberOperations,
    DateOperations,
    PeriodOperations,
    NoArgOperations,
} from '@/common/filters/InputOperations';
import { PeriodUnitsLabels } from '@/common/filters/Units';
import FDateInput from '@/components/partials/filters/uiComponents/FDateInput.vue';

/**
 * The idea of this input is to re-use other inputs as child-components.
 * It looks a bit hacky, because originally other components where meant
 * to be extended only. That's why refs are used.
 */
export default {
    name: 'FPeriodInput',
    components: {
        FDateInput,
        Dropdown,
        FNumberInput,
    },
    props: {
        changeCallback: {
            type: Function,
            default: null,
        },
    },
    data() {
        return {
            Styles,
            PeriodUnitsLabels,
            PeriodOperationLabels,
            PeriodLabelsToOperations,
            value: null, // override
        };
    },
    computed: {
        operationLabels() {
            return Object.values(PeriodOperationLabels);
        },
        isNumberInputDisplayed() {
            return Object.values(NumberOperations).includes(this.value.op);
        },
        isDateInputDisplayed() {
            return Object.values(DateOperations).includes(this.value.op);
        },
        isMonthInputDisplayed() {
            return PeriodOperations.InMonth === this.value.op;
        },
        defaultPeriodValue() {
            // override
            return null;
        },
        defaultDateValue() {
            // override
            return null;
        },
        months() {
            return moment.months();
        },
        isNoArgOperation() {
            return this.value && NoArgOperations[this.value.op];
        },
    },
    methods: {
        onChange(partialValue) {
            this.onValueChange(partialValue);
            if (this.changeCallback) this.changeCallback();

            // update visible child component's value manually
            if (this.isNumberInputDisplayed) {
                this.$refs.periodInput.value = { ...this.$refs.periodInput.value, ...partialValue };
            } else if (this.isDateInputDisplayed) {
                // put dateValue to value field
                const updatedValue = partialValue.dateValue ? { value: partialValue.dateValue } : {};
                this.$refs.dateInput.value = { ...this.$refs.dateInput.value, ...partialValue, ...updatedValue };
            }
        },
        onValueChange(partialValue = {}) {
            return partialValue;
        }, // override
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/palette';
@import '~@/assets/scss/mixins';

.period {
    .dropdown {
        @include custom-height-dropdown(32px);
        @include custom-width-dropdown(180px);
    }
}
</style>
