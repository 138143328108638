
import Vue from 'vue';

// Components
import AbstractTableTile from '@/__new__/features/customerCareSuite/components/AbstractTableTile.vue';
import IconButton from '@/components/partials/IconButton.vue';

// Helpers
import { ICON_TYPES } from '@/common/iconHelper';
import { USER_MANAGER_HIERARCHY } from '@/__new__/features/customerCare/common/customerCareHelper';
import { PAYMENT_STATE_TO_LABEL } from '@/__new__/features/customerCareSuite/common/transactionHelper';
import luaErrors from '@/common/luaErrors';
import tableColumnType from '@/common/filterTable';
import { getOperatorConfigValue } from '@/services/permissions/permissions.service';

// HTTP
import ordersHTTP from '@/__new__/services/dno/orders/http/orders';
import customerCareHTTP from '@/__new__/services/dno/user/http/customer-care';

interface Bill {
    billId: string;
    billType: number;
    paymentState: number;
    billDate: number;
    billDueDate: number;
    billAmount: string;
    lastPaidDate: number;
}

export default Vue.extend({
    name: 'BillingStatementsNextGenTile',
    components: {
        AbstractTableTile,
        IconButton,
    },
    props: {
        tableKey: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            targetAccount: { id: this.$route.params.id, type: USER_MANAGER_HIERARCHY.ACCOUNT },
            apiResponse: {},
            bills: [] as Bill[],
            ICON_TYPES,
        };
    },
    computed: {
        columnsData() {
            return [
                {
                    name: this.$i18n.t('customerCareSuite.billing.billDate'),
                    key: 'billDate',
                    sortBy: (bill: Bill) => bill.billDate,
                    field: 'billDate',
                    filterType: tableColumnType.DATE,
                    mapper: (bill: Bill) => this.$localeLibrary.getFormattedDate(bill.billDate),
                },
                {
                    name: this.$i18n.t('customerCareSuite.billing.billDueDate'),
                    key: 'billDueDate',
                    sortBy: (bill: Bill) => bill.billDueDate,
                    field: 'billDueDate',
                    filterType: tableColumnType.DATE,
                    mapper: (bill: Bill) => this.$localeLibrary.getFormattedDate(bill.billDueDate),
                },
                {
                    name: this.$i18n.t('customerCareSuite.billing.billAmount'),
                    key: 'billAmount',
                    sortBy: (bill: Bill) => bill.billAmount,
                    field: 'billAmount',
                },
                {
                    name: this.$i18n.t('customerCareSuite.billing.paymentState'),
                    key: 'paymentStateLabel',
                    sortBy: (bill: Bill) => bill.paymentState,
                    field: 'paymentStateLabel',
                    mapper: (bill: Bill) =>
                        PAYMENT_STATE_TO_LABEL.get(bill.paymentState) || this.$i18n.t('generic.unknownState'),
                },
                {
                    name: this.$i18n.t('customerCareSuite.billing.lastPaidDate'),
                    key: 'lastPaidDate',
                    sortBy: (bill: Bill) => bill.lastPaidDate,
                    field: 'lastPaidDate',
                    filterType: tableColumnType.DATE,
                    mapper: (bill: Bill) => this.$localeLibrary.getFormattedDate(bill.lastPaidDate),
                },
                {
                    name: this.$i18n.t('customerCareSuite.billing.download'),
                    key: 'download',
                    field: 'download',
                    sortable: false,
                    filterable: false,
                },
            ];
        },
    },
    created() {
        this.fetchTileData();
    },
    methods: {
        fetchTileData() {
            this.$emit('isDataLoadingUpd', true);
            const limit = getOperatorConfigValue('billingStatementLimit');
            return this.$withProgressBar(
                async () => {
                    this.apiResponse = await ordersHTTP.getBills(this.targetAccount.id, this.targetAccount.type, limit);

                    this.bills = (this.apiResponse?.data.bills || []).map(bill => ({
                        billId: bill.bill_id,
                        billType: bill.bill_type,
                        paymentState: bill.payment_state,
                        billDate: bill.bill_date,
                        billDueDate: bill.bill_due_date,
                        billAmount: bill.bill_amount,
                        lastPaidDate: bill.last_paid_date,
                    }));
                    this.$emit('isDataLoadingUpd', false);
                },
                {
                    errorHandler: error => {
                        this.apiResponse = error;
                        this.$alert('dataFetchingError');
                        this.$emit('isDataLoadingUpd', false);
                    },
                },
            );
        },
        async onDownloadButtonClicked(entity: Bill) {
            this.$Progress.start();
            try {
                const response = await customerCareHTTP.getInvoice(
                    this.targetAccount.id,
                    this.targetAccount.type,
                    entity.billId,
                );

                if (response.data.invoice) {
                    window.open(response.data.invoice, '_blank');
                }

                this.$Progress.finish();
            } catch (error: any) {
                if (error.response?.data.code === luaErrors.BILLING_V4.FILE_NOT_FOUND_ON_S3.code) {
                    this.$alert(this.$i18n.t('customerCare.billingStatementDownloadNotFoundInvoice'));
                } else {
                    this.$alert(this.$i18n.t('customerCare.billingStatementDownloadError'));
                }
                this.$Progress.fail();
            }
        },
    },
});
