<template>
    <AbstractListPageWrapper
        :isOverviewEnabled="isOverviewEnabled"
        :pageTitle="pageTitle"
        :userGuideArticleId="userGuideArticleId"
        :entitiesCount="formattedTableEntitiesLength"
        @searchQueryChanged="onSearchQueryChanged"
    >
        <template #button>
            <ResponseModalButton
                :response="entitiesApiResponse"
                :title="pageTitle"
            />
        </template>

        <template #filterTable>
            <FilterTable
                :columns="tableColumnsData"
                @filterAdded="onFilterAdd"
            />
        </template>

        <template #headerButtons>
            <AppButton
                v-if="canEdit"
                :buttonType="BUTTON_TYPES.PRIMARY"
                :iconType="ICON_TYPES.PLUS"
                :label="addButtonLabel"
                data-test-id="add-entity-button"
                @click="onAdd"
            />
        </template>

        <template #allFilters>
            <TableFiltersTags
                :filterTableMixin="filterTableMixin"
                class="my-3 ml-2"
                @removeFilter="index => removeFilter(index, filtersLocalStorageKey)"
                @removeAllFilters="removeAllFilters(filtersLocalStorageKey)"
            />
        </template>

        <template #table>
            <slot
                name="modals"
                :selectedEntity="selectedEntity"
            />
            <AppTable
                :entityActionToHrefFunction="entityActionToHrefFunction"
                :entities="formattedFilteredEntities"
                :entityKeyDepth="entityKeyDepth"
                :canSelectColumns="true"
                :canSort="true"
                :selectedEntityId="selectedEntityId"
                :innerSearchQuery="searchQueryForTable"
                :isSearchEnabled="true"
                :searchKeys="searchKeys"
                :isInnerSearchQueryPassed="true"
                :columnsData="tableColumnsData"
                :enableRowStateControls="false"
                :entityType="entityType"
                :tableKey="`${tableKey}/${entityType}`"
                :data-test-id="`${tableKey}-${entityType}-table`"
                :emitEntityActions="true"
                :multiselectEnabled="multiselectEnabled"
                :idColumn="multiselectIdColumn"
                :multiselectActions="multiselectActions"
                @selectedEntityModel="onSelected"
                @multiselectActionClick="onMultiselectActionClick"
                @input="setTableEntitiesLength"
            >
                <template #customRowButtons="{ entity }">
                    <CommonTemplateRowStateControls
                        v-if="isUserAllowed(writePermission)"
                        :entity="entity"
                        :allowedActionsExternal="entity.allowedActionsExternal"
                        :entityActionToHrefFunction="entityActionToHrefFunction"
                        @edit="onEdit($event)"
                        @clone="onClone($event)"
                        @delete="onDelete($event)"
                        @readonly="onReadOnly($event)"
                        @approve="onApprove($event)"
                    />
                </template>

                <template #state="{ entity }">
                    <EntityStatusIndicator :status="entity.state" />
                    <slot
                        name="statusIndicator"
                        :entity="entity"
                    />
                </template>

                <template #entityVersion="{ entity }">
                    <EntityStatusIndicator :status="entity.entityVersion" />
                    <slot
                        name="versionIndicator"
                        :entity="entity"
                    />
                </template>

                <template #operations="{ entity }">
                    <slot
                        name="operations"
                        :entity="entity"
                    />
                </template>
            </AppTable>
        </template>

        <template #overview>
            <EntityOverview
                :entityType="entityType"
                :entity="selectedEntity"
                @closeOverview="isOverviewEnabled = false"
            >
                <template #section-1-header>
                    <div>
                        <OverviewHeaderV2
                            v-if="selectedEntity"
                            :entityId="selectedEntityId"
                            class="mt-2"
                            :entityName="selectedEntity.name"
                        />
                    </div>
                </template>

                <template #section-1-content>
                    <div>
                        <div class="py-3 pr-3">
                            <AppOverviewBlock
                                v-if="selectedEntity"
                                :isTitleCounterVisible="false"
                                :isRowTitleCounterVisible="false"
                                :maxItems="99"
                                :maxItemRows="99"
                                :items="overviewContent"
                            />
                        </div>
                        <div class="section container-fluid px-3">
                            <div class="container">
                                <slot
                                    name="overviewButtons"
                                    :selectedEntity="selectedEntity"
                                />
                            </div>
                        </div>
                    </div>
                </template>
            </EntityOverview>
        </template>
    </AbstractListPageWrapper>
</template>

<script>
import TableFiltersTags from '@/components/filters/TableFiltersTags.vue';
import AbstractListPageWrapper from '@/components/layout/AbstractListPageWrapper.vue';
import AppOverviewBlock from '@/components/partials/AppOverviewBlock.vue';
import AppTable from '@/components/partials/AppTable.vue';
import EntityOverview from '@/components/partials/entityOverview/EntityOverview.vue';
import OverviewHeaderV2 from '@/components/partials/entityOverview/OverviewHeaderV2.vue';
import FilterTable from '@/components/partials/FilterTable.vue';
import FilterTableMixin from '@/components/partials/FilterTableMixin.vue';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import ResponseModalButton from '@/components/partials/ResponseModalButton.vue';
import CommonTemplateRowStateControls from '@/__new__/features/template/common/CommonTemplateRowStateControls.vue';
import EntityStatusIndicator from '@/components/partials/EntityStatusIndicator.vue';

// helpers
import { ALERT_TYPES } from '@/common/alerts/Alert';
import Button from '@/common/button/Button';
import { EDITOR_MODE } from '@/common/entities/entityHelper';
import ENTITY_TYPES from '@/common/entities/entityTypes';
import tableColumnType from '@/common/filterTable';
import { ICON_TYPES } from '@/common/iconHelper';
import { isUserAllowed } from '@/services/permissions/permissions.service';

export default {
    name: 'CommonTemplateListPageWrapper',
    components: {
        AppOverviewBlock,
        AbstractListPageWrapper,
        AppButton,
        AppTable,
        EntityOverview,
        FilterTable,
        OverviewHeaderV2,
        TableFiltersTags,
        ResponseModalButton,
        CommonTemplateRowStateControls,
        EntityStatusIndicator,
    },
    mixins: [FilterTableMixin],
    props: {
        entityType: {
            type: String,
            default: ENTITY_TYPES.TEMPLATE,
        },
        pageTitle: {
            type: String,
            required: true,
        },
        addButtonLabel: {
            type: String,
            required: true,
        },
        listViewColumns: {
            type: Array,
            default: () => [],
        },
        entities: {
            type: Array,
            default: () => [],
        },
        entitiesApiResponse: {
            type: [Object, Array],
            default: null,
        },
        entityKeyDepth: {
            type: Number,
            default: 1,
        },
        editRouteName: {
            type: String,
            required: true,
        },
        overviewContentBuilder: {
            type: Function,
            required: true,
        },
        excludeBaseColumns: {
            type: Array,
            default: null,
        },
        multiselectEnabled: {
            type: Boolean,
            default: false,
        },
        multiselectIdColumn: {
            type: String,
            default: null,
        },
        multiselectActions: {
            type: Array,
            default: () => [],
        },
        searchKeys: {
            type: Array,
            default: () => ['id', 'name', 'description'],
        },
        writePermission: {
            type: String,
            required: false,
            default: null,
        },
        userGuideArticleId: {
            type: Number,
            default: null,
        },
        tableKey: {
            type: String,
            required: false,
            default: 'entity',
        },
        pagesWithImplementedDraft: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            isUserAllowed,
            searchQueryForTable: '',
            selectedEntityId: null,
            isOverviewEnabled: false,
            overviewContent: [],
            formattedTableEntitiesLength: 0,
            ICON_TYPES,
            BUTTON_TYPES,
            filtersLocalStorageKey: `entity-${this.pageTitle?.replace(/\s/g, '-').toLocaleLowerCase()}`,
            deleteConfirmationButton: new Button({
                label: this.$i18n.t('generic.delete'),
                alertType: ALERT_TYPES.warning,
            }),
            approveConfirmationButton: new Button({
                label: this.$i18n.t('generic.approve'),
                alertType: ALERT_TYPES.warning,
            }),
        };
    },
    computed: {
        canEdit() {
            return this.isUserAllowed(this.writePermission);
        },
        formattedFilteredEntities() {
            return this.filteredEntitiesMixin(this.entities);
        },
        tableColumnsData() {
            let baseColumns = [
                {
                    name: this.$i18n.t('generic.name'),
                    key: 'name',
                    field: 'name',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('generic.description'),
                    key: 'description',
                    field: 'description',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('generic.updateTime'),
                    key: 'updateTimeFormatted',
                    field: 'updateTime',
                    filterType: tableColumnType.DATE,
                    sortBy: entity => entity.updateTime,
                },
                {
                    name: this.isDraftImplemented
                        ? this.$i18n.t('generic.publishedState')
                        : this.$i18n.t('generic.state'),
                    key: 'state',
                    field: 'stateLabel',
                    filterType: tableColumnType.TEXT_LIMITED_OPTIONS,
                    limitedOptions: [...new Set(this.entities.map(entity => entity.stateLabel))],
                },
                {
                    name: this.$i18n.t('generic.externalId'),
                    key: 'externalId',
                    field: 'externalId',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
            ];

            if (this.isDraftImplemented) {
                baseColumns.push({
                    name: this.$i18n.t('generic.draftState'),
                    key: 'entityVersion',
                    field: 'entityVersionLabel',
                    filterType: tableColumnType.TEXT_LIMITED_OPTIONS,
                    limitedOptions: [...new Set(this.entities.map(entity => entity.entityVersionLabel))],
                });
            }
            if (this.excludeBaseColumns) {
                baseColumns = baseColumns.filter(x => !this.excludeBaseColumns.some(y => y === x.key));
            }
            return [...baseColumns, ...this.listViewColumns];
        },
        selectedEntity() {
            return this.entities.find(e => e.id === this.selectedEntityId);
        },
        isDraftImplemented() {
            return this.pagesWithImplementedDraft.includes(this.entityType);
        },
        /**
         * Builds functions to determine links for entity actions.
         * For more information see documentation for property `entityActionToHrefFunction` in the RowStateControls component.
         */
        entityActionToHrefFunction() {
            return {
                EDIT: entity => this.getRouterLink(entity.id, EDITOR_MODE.EDIT),
                READ_ONLY: entity => this.getRouterLink(entity.id, EDITOR_MODE.VIEW),
            };
        },
    },
    watch: {
        entities: {
            async handler() {
                this.setAppliedFiltersFromLocalStorage(this.filtersLocalStorageKey);
                if (this.isOverviewEnabled) {
                    this.overviewContent =
                        (await this.overviewContentBuilder(this.entityType, this.selectedEntity)) ?? [];
                }
            },
        },
    },
    methods: {
        /**
         * Returns a link to the editor page for the entity.
         * Link is determined based on the entity `id` and the `mode`
         * of the editor (eg: EDIT or VIEW).
         */
        getRouterLink(id, mode) {
            const routerObj = this.getRouterObject(id, false, mode);
            return this.$router.resolve(routerObj)?.href;
        },
        /**
         * Returns object describing route.
         * Intended to be used with `this.$router` methods.
         */
        getRouterObject(id, isClone, mode) {
            return {
                name: this.editRouteName,
                params: {
                    entityType: this.entityType,
                    id,
                    clone: isClone,
                    mode,
                    companyId: this.$route.params.companyId,
                },
            };
        },
        setTableEntitiesLength({ length }) {
            this.formattedTableEntitiesLength = length;
        },
        onSearchQueryChanged(searchQuery) {
            this.searchQueryForTable = searchQuery;
        },
        async onSelected(entity) {
            this.selectedEntityId = entity.id;
            this.isOverviewEnabled = true;
            this.overviewContent = (await this.overviewContentBuilder(this.entityType, this.selectedEntity)) ?? [];
            this.$emit('select', this.selectedEntity);
        },
        onAdd() {
            this.goToEditPage();
        },
        onEdit(entityId) {
            this.goToEditPage(entityId, false, EDITOR_MODE.EDIT);
        },
        onClone(entityId) {
            this.goToEditPage(entityId, true, EDITOR_MODE.EDIT);
        },
        onReadOnly(entityId) {
            this.goToEditPage(entityId, false, EDITOR_MODE.VIEW);
        },
        onDelete(entityId) {
            const entity = this.entities.find(e => entityId === e.id);
            this.confirmDeletion(entity);
        },
        confirmDeletion(entity) {
            this.$eventBus.$emit('showAlert', {
                message: this.$i18n.t('alerts.areYouSureDeleteEntity', {
                    entityName: entity.name,
                }),
                type: ALERT_TYPES.warning,
                buttons: [this.deleteConfirmationButton],
            });
            this.$eventBus.$once('buttonClicked', id => {
                if (id === this.deleteConfirmationButton.id) {
                    this.$emit('delete', entity);
                }
            });
        },
        goToEditPage(entityId, isClone, mode) {
            const routerObj = this.getRouterObject(entityId, isClone, mode);
            this.$router.push(routerObj);
        },
        onMultiselectActionClick(data) {
            this.$emit('multiselectActionClick', data);
        },
        onApprove({ data }) {
            this.confirmApprove(data);
        },
        confirmApprove(entity) {
            this.$eventBus.$emit('showAlert', {
                message: this.$i18n.t('alerts.areYouSureApprove', {
                    entityName: entity.name,
                }),
                type: ALERT_TYPES.warning,
                buttons: [this.approveConfirmationButton],
            });
            this.$eventBus.$once('buttonClicked', id => {
                if (id === this.approveConfirmationButton.id) {
                    this.$emit('approve', entity);
                }
            });
        },
        onFilterAdd(filter) {
            this.onFilterAdded(filter);
            this.saveFiltersToLocalStorage(filter, this.filtersLocalStorageKey);
        },
    },
};
</script>
