<template>
    <AbstractSubSidebarPageWrapper
        :pageTitle="$i18n.t('settings.settings')"
        :entitiesCount="count.displayNumberOfItems"
    >
        <template #headerButtons>
            <AppButton
                :buttonType="BUTTON_TYPES.PRIMARY"
                :label="$i18n.t('tenants.newTenant')"
                :iconType="ICON_TYPES.PLUS"
                @click="addTenant"
            />
        </template>

        <template #subSidebar>
            <SubSidebar
                v-model="selectedRouteName"
                :sections="permissionFilteredSections"
                class="sidebar"
                @change="changeActiveSection"
            />
        </template>

        <template #content>
            <AbstractListPageWrapper :isOverviewEnabled="isOverviewEnabled">
                <template #table>
                    <AppTable
                        :entities="availableTenants"
                        :selectedEntityId="selectedEntityId"
                        :isSearchEnabled="false"
                        :columnsData="tableColumnsData"
                        :canSelectColumns="true"
                        :isDataLoading="isDataLoading"
                        keyName="companyId"
                        tableKey="tenant"
                        @input="ent => setNumberOfItemsAfterSearch(ent.length, Object.values(availableTenants).length)"
                        @selectEntity="onSelectEntity"
                    >
                        <template #customRowButtons="{ entity }">
                            <IconButton
                                :label="$i18n.t('generic.edit')"
                                :icon="ICON_TYPES.EDIT"
                                @iconClick="editTenant(entity.companyId)"
                            />
                        </template>
                    </AppTable>
                </template>
                <template #overview>
                    <EntityOverview
                        :entityType="ENTITY_TYPES.TENANT"
                        :entity="selectedEntity"
                        @closeOverview="isOverviewEnabled = false"
                    >
                        <template #section-1-header>
                            <div>
                                <OverviewHeader
                                    v-if="selectedEntity"
                                    :entityName="selectedEntityName"
                                    :entityType="ENTITY_TYPES.TENANT"
                                />
                            </div>
                        </template>
                        <template #section-1-content>
                            <div>
                                <OverviewList :lists="detailsSections" />
                            </div>
                        </template>
                    </EntityOverview>
                </template>
            </AbstractListPageWrapper>
        </template>
    </AbstractSubSidebarPageWrapper>
</template>

<script>
// Components
import AbstractListPageWrapper from '@/components/layout/AbstractListPageWrapper.vue';
import AbstractSubSidebarPageWrapper from '@/components/layout/AbstractSubSidebarPageWrapper.vue';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import { ICON_TYPES } from '@/common/iconHelper';
import IconButton from '@/components/partials/IconButton.vue';
import AppTable from '@/components/partials/AppTable.vue';
import EntityOverview from '@/components/partials/entityOverview/EntityOverview.vue';
import OverviewList from '@/components/partials/entityOverview/OverviewList.vue';
import OverviewHeader from '@/components/partials/entityOverview/OverviewHeader.vue';
import SubSidebarMixin from '@/components/partials/SubSidebarMixin.vue';
import SettingsSubSidebarMixin from '@/__new__/features/settings/SettingsSubSidebarMixin.vue';
import SubSidebar from '@/components/layout/SubSidebar.vue';
import CountControlMixin from '@/components/partials/CountControlMixin.vue';

// Helpers
import RouteNames from '@/router/routeNames';
import ENTITY_TYPES from '@/common/entities/entityTypes';

// HTTP
import supportButtonMixin from '@/components/alerts/supportButtonMixin';

export default {
    name: 'Tenants',
    components: {
        AbstractListPageWrapper,
        AbstractSubSidebarPageWrapper,
        AppTable,
        EntityOverview,
        OverviewList,
        OverviewHeader,
        AppButton,
        IconButton,
        SubSidebar,
    },
    mixins: [SubSidebarMixin, SettingsSubSidebarMixin, CountControlMixin, supportButtonMixin],
    data() {
        return {
            selectedEntity: null,
            selectedEntityId: null,
            searchQueryForTable: '',
            tableColumnsData: [
                { name: this.$i18n.t('generic.name'), key: 'caption', forbidHideColumn: true },
                { name: this.$i18n.t('generic.description'), key: 'description' },
            ],
            isDataLoading: false,
            isOverviewEnabled: false,
            ICON_TYPES,
            BUTTON_TYPES,
            ENTITY_TYPES,
        };
    },
    computed: {
        availableTenants() {
            return this.$store.state.operators.availableTenants;
        },
        detailsSections() {
            if (!this.selectedEntity) {
                return [];
            }
            return [
                {
                    name: this.$i18n.t('tenants.tenantInfo'),
                    properties: [
                        {
                            value: this.selectedEntity.caption,
                            label: this.$i18n.t('generic.name'),
                        },
                        {
                            value: this.selectedEntity.description,
                            label: this.$i18n.t('generic.description'),
                        },
                    ],
                },
            ];
        },
        selectedEntityName() {
            return this.selectedEntity?.caption ?? '';
        },
    },
    created() {
        this.isDataLoading = true;
        this.$store.dispatch('operators/setAllTenants');
        this.isDataLoading = false;
    },
    methods: {
        onSelectEntity(companyId) {
            this.selectedEntityId = companyId;
            this.selectedEntity = Object.values(this.availableTenants).find(e => e.companyId === companyId);
            this.isOverviewEnabled = true;
        },
        addTenant() {
            this.$router.push({
                name: RouteNames.TENANT_EDITOR,
                params: { companyId: this.$route.params.companyId },
            });
        },
        editTenant(id) {
            this.$router.push({
                name: RouteNames.TENANT_EDITOR,
                params: { id, companyId: this.$route.params.companyId },
            });
        },
    },
};
</script>

<style lang="scss" scoped></style>
