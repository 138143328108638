<template>
    <div
        ref="otpCont"
        class="otp-wrapper"
    >
        <input
            v-for="(el, ind) in digits"
            :key="el + ind"
            v-model="digits[ind]"
            type="text"
            class="digit-box"
            :autofocus="ind === 0"
            maxlength="1"
            @keydown="handleKeyDown($event, ind)"
        />
    </div>
</template>

<script>
import Vue from 'vue';

export default {
    name: 'AppOtpCodeInput',
    props: {
        numberOfDigits: {
            type: Number,
            default: 4,
        },
    },
    data() {
        return {
            digits: new Array(this.numberOfDigits).fill(null),
        };
    },
    methods: {
        handleKeyDown(event, index) {
            // Allow user to move trough tabs
            if (event.key !== 'Tab' && event.key !== 'ArrowRight' && event.key !== 'ArrowLeft') {
                event.preventDefault();
            }

            // Allow user to delete digits
            if (event.key === 'Backspace') {
                Vue.set(this.digits, index, null);

                if (index !== 0) {
                    this.$nextTick(() => {
                        this.$refs.otpCont.children[index - 1].focus();
                    });
                }
            }

            // Check if input is digit
            if (/^-?\d+$/.test(event.key)) {
                Vue.set(this.digits, index, event.key);

                if (index !== this.numberOfDigits - 1) {
                    this.$nextTick(() => {
                        this.$refs.otpCont.children[index + 1].focus();
                    });
                }
            }

            this.$emit('update', this.digits.join(''));
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/palette';

.otp-wrapper {
    margin-top: 3rem;
    text-align: center;
}

.digit-box {
    height: 4rem;
    width: 2rem;
    border: none;
    border-bottom: 1px solid $gray30;
    margin: 0.5rem;
    padding: 0.5rem;
    font-weight: 700;
    font-size: 1.375rem;
}

.digit-box:focus {
    border-bottom: 2px solid $gray30;
}
</style>
