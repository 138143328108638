<template>
    <AbstractSubSidebarPageWrapper :pageTitle="$route.name">
        <template #subSidebar>
            <SubSidebar
                v-model="selectedRouteName"
                :sections="permissionFilteredSections"
                class="sidebar"
                @change="changeActiveSection"
            />
        </template>
        <template #content>
            <div class="default-page-content-wrapper px-4 mt-5">
                <transition>
                    <router-view />
                </transition>
            </div>
        </template>
    </AbstractSubSidebarPageWrapper>
</template>

<script>
// components
import AbstractSubSidebarPageWrapper from '@/components/layout/AbstractSubSidebarPageWrapper.vue';
import SubSidebar from '@/components/layout/SubSidebar.vue';
import SubSidebarMixin from '@/components/partials/SubSidebarMixin.vue';
import UserGuideSubSidebarMixin from '@/modules/userGuide/components/UserGuideSubSidebarMixin.vue';

export default {
    name: 'UserGuideHome',
    components: {
        AbstractSubSidebarPageWrapper,
        SubSidebar,
    },
    mixins: [SubSidebarMixin, UserGuideSubSidebarMixin],
};
</script>

<style lang="scss" scoped></style>
