var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid wrapper-container p-0"},[_c('AppHeader'),_c('div',{staticClass:"banner"},[_c('div',{staticClass:"banner-text"},[_c('h2',{staticClass:"lf-title m-0"},[_vm._v(" "+_vm._s(_vm.getWelcomeText)+" ")])])]),_c('section',{staticClass:"create-new-section d-flex flex-column"},[_c('h2',{staticClass:"lf-subtitle"},[_vm._v(_vm._s(_vm.$i18n.t('home.createNew'))+" section")]),_c('draggable',{staticClass:"h-100",attrs:{"list":_vm.newWidgetList,"group":{ name: 'widget', pull: true, put: true },"sort":true}},[_c('transition-group',{staticClass:"card-wrapper d-flex flex-wrap",attrs:{"name":"cards","tag":"div"}},_vm._l((_vm.newWidgetList),function(item){return _c('div',{key:`${item.name}-card`,staticClass:"card"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('h3',{class:['card-heading', 'lf-subtitle-blue', 'mb-0', item.colorScheme.textColorClass]},[_vm._v(" "+_vm._s(item.name)+" ")]),_c('span',{class:[
                                'card-new-btn',
                                item.colorScheme.bgColorClass,
                                item.colorScheme.textColorClass,
                            ]},[_c('AppIcon',{staticClass:"card-new-btn-icon",attrs:{"type":_vm.ICON_TYPES.PLUS}})],1)])])}),0)],1)],1),_c('aside',[_c('h2',{staticClass:"lf-subtitle"},[_vm._v("Widgets")]),_c('draggable',{staticClass:"h-100",attrs:{"list":_vm.createNewListFiltered,"group":{ name: 'widget', pull: true, put: true }}},[_c('transition-group',{staticClass:"widgets d-flex flex-column h-100",attrs:{"name":"widgets","tag":"div"}},_vm._l((_vm.createNewListFiltered),function(item){return _c('div',{key:`${item.name}-card`,staticClass:"card"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('h3',{class:['card-heading', 'lf-subtitle-blue', 'mb-0', item.colorScheme.textColorClass]},[_vm._v(" "+_vm._s(item.name)+" ")]),_c('span',{class:[
                                'card-new-btn',
                                item.colorScheme.bgColorClass,
                                item.colorScheme.textColorClass,
                            ]},[_c('AppIcon',{staticClass:"card-new-btn-icon",attrs:{"type":_vm.ICON_TYPES.PLUS}})],1)])])}),0)],1),_c('AppButton',{staticClass:"btn-save",attrs:{"buttonType":_vm.BUTTON_TYPES.PRIMARY,"iconType":_vm.ICON_TYPES.CHECK,"label":_vm.$i18n.t('generic.save')},on:{"click":_vm.onSave}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }