import last from 'lodash/last';
import i18n from '@/i18n';
import { abbreviate, getPercent } from '@/common/formatting';
import { spacedNumber } from '@/common/filters';

export class StatisticsComponent {
    iconCssClass;

    label;

    value;

    iconLabel;

    enabled = true;

    containerCssClass;

    constructor(iconCssClass, label = '', value = '', iconLabel = '', enabled, containerCssClass) {
        this.iconCssClass = iconCssClass;
        this.label = label;
        this.value = value;
        this.iconLabel = iconLabel;
        if (enabled != null) {
            this.enabled = enabled;
        }
        this.containerCssClass = containerCssClass;
    }
}

// todo rework this class after discussion with server team
export default class SegmentStatistics {
    total = null;

    filtered;

    emailable;

    pushEnabled;

    smsable;

    filteredPercent = getPercent(null, null);

    constructor(filtered, pushEnabled, emailable = null, smsable = null) {
        this.filtered = filtered;
        this.emailable = emailable;
        this.pushEnabled = pushEnabled;
        this.smsable = smsable;
    }

    emailableStr() {
        return abbreviate(this.emailable);
    }

    pushEnabledStr() {
        return abbreviate(this.pushEnabled);
    }

    smsableStr() {
        return abbreviate(this.smsable);
    }

    filteredStr() {
        if (this.filtered != null) {
            const filteredString = abbreviate(this.filtered);
            return this.filteredPercent && this.filteredPercent.length
                ? `${filteredString} (${this.filteredPercent})`
                : filteredString;
        }
        return '...';
    }

    ofTotalStr() {
        return this.total != null ? `of ${spacedNumber(this.total)} total users` : '...';
    }

    buildStaticsComponents() {
        return [
            new StatisticsComponent('mail-icon', 'Emailable', this.emailableStr(), '', false),
            new StatisticsComponent('push-icon', 'Push Enabled', this.pushEnabledStr()),
            new StatisticsComponent('sms-icon', 'SMS Reachable', this.smsableStr(), '', false),
            new StatisticsComponent('profile-icon', this.ofTotalStr(), this.filteredStr()),
        ];
    }

    buildComponentsWithOnlyTotal() {
        const stats = this.buildStaticsComponents();
        stats[0].value = '-';
        stats[1].value = '-';
        stats[2].value = '-';
        stats[3].value = '-';

        if (this.total != null) {
            stats[3].label = spacedNumber(this.total);
            stats[3].value = i18n.t('campaigns.totalUsers');
        }

        return stats;
    }

    // to display minimal set of data, i.e. for filter row
    buildCompactStaticsComponents() {
        const stats = this.buildStaticsComponents();
        const total = last(stats);
        total.value = total.label;
        return stats;
    }

    setTotal(total) {
        this.total = total;
        this.filteredPercent = getPercent(this.filtered, this.total);
        return this;
    }

    static fromJson(json) {
        return new SegmentStatistics(json.filtered, json.pushEnabled);
    }

    static fromCohortCountJson(json) {
        return new SegmentStatistics(json.count);
    }

    static empty() {
        return new SegmentStatistics(null, undefined, null, null, null);
    }
}
