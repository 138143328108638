import * as Sentry from '@sentry/vue';
import { TranslateResult } from 'vue-i18n';
import localeLibrary from '@/common/locale/localeLibrary';
import i18n from '@/i18n';
import { getUserNameById } from '@/__new__/services/portal/profile/http/profile';

export enum CHARGING_VERSION_PUSH_STATUS {
    NOT_PUSHED = 1,
    PUSHED = 2,
}

export const MAP_CHARGING_VERSION_PUSH_STATUS_TO_LABEL = {
    [CHARGING_VERSION_PUSH_STATUS.NOT_PUSHED]: i18n.t('generic.notPushed'),
    [CHARGING_VERSION_PUSH_STATUS.PUSHED]: i18n.t('generic.pushed'),
};

export type ChargingEntityVersionsConstructorArgs = {
    entityId: string;
    misc: {
        portal_id: string;
        remark: string;
        pushed_timestamp?: number;
        push_portal_id?: CHARGING_VERSION_PUSH_STATUS;
    };
    pushed: number;
    update_time: number;
    charging_version: string;
};

export class ChargingEntityVersions {
    entityId: string;
    userId: number | null;
    userName?: string | TranslateResult;
    remark: string;
    pushed: CHARGING_VERSION_PUSH_STATUS;
    updateTime: TranslateResult;
    chargingVersion: string;
    pushedTimestamp?: string | TranslateResult;
    pushStatusLabel?: TranslateResult;

    constructor(data: ChargingEntityVersionsConstructorArgs) {
        this.entityId = data.entityId || '';
        this.userId = Number(data.misc.portal_id) || null;
        this.remark = data?.misc?.remark || '';
        this.pushed = data.pushed;
        this.updateTime = data.update_time
            ? localeLibrary.getFormattedDateAndTime(data.update_time)
            : i18n.t('generic.N/A');
        this.chargingVersion = data.charging_version || '';
        this.userName = i18n.t('generic.N/A');
        this.pushedTimestamp = data.misc.pushed_timestamp
            ? localeLibrary.getFormattedDateAndTime(data.misc.pushed_timestamp)
            : i18n.t('generic.N/A');
        this.pushStatusLabel = this.pushed ? MAP_CHARGING_VERSION_PUSH_STATUS_TO_LABEL[this.pushed] : '';
    }

    async getUpdateUserName(): Promise<void> {
        try {
            if (this.userId) {
                const res = await getUserNameById(this.userId);
                if (res?.data) {
                    this.userName = res?.data;
                }
            }
        } catch (e) {
            Sentry.captureException(e);
        }
    }
}

export default ChargingEntityVersions;
