<template>
    <EntityListPageLayout
        :entityType="entityType"
        :listViewColumns="listViewColumns"
        :overrideEditRouteName="facetRoute"
        :excludeBaseColumns="excludeBaseColumns"
        :overviewContentBuilder="overviewContentBuilder"
        writePermission="FacetsWrite"
    />
</template>

<script>
import { mapGetters } from 'vuex';
import { Getters } from '@/store/mutation-types';

import ENTITY_TYPES from '@/common/entities/entityTypes';

import EntityListPageLayout from '@/__new__/features/pc/EntityListPageLayout.vue';
import RouteNames from '@/router/routeNames';
import { overviewContentBuilder } from './common/overviewContentBuilder';
import tableColumnType from '@/common/filterTable';

export default {
    name: 'FacetsListPage',
    components: {
        EntityListPageLayout,
    },
    data() {
        return {
            entityType: ENTITY_TYPES.FACET,
            facetRoute: RouteNames.FACET_EDITOR,
            excludeBaseColumns: ['description', 'externalId'],
            overviewContentBuilder,
        };
    },
    computed: {
        ...mapGetters('productcatalog', {
            getEntitiesByType: Getters.PC_GET_ENTITIES_BY_TYPE_NOT_DELETED,
        }),
        entities() {
            return this.getEntitiesByType(this.entityType);
        },
        listViewColumns() {
            return [
                {
                    name: this.$i18n.t('generic.sortPriority'),
                    key: 'sortPriority',
                    field: 'sortPriority',
                    filterType: tableColumnType.NUMBER,
                    mapper: entity => entity.properties.sort_priority,
                },
            ];
        },
    },
};
</script>
