<template>
    <div class="mb-2">
        <div v-show="chargeType === CHARGING_TYPE.perUnit">
            <div v-show="chargeValues[CHARGING_TYPE.perUnit].editingEnabled">
                <div class="d-flex align-items-center">
                    <div class="inline-text">
                        {{ $i18n.t('charging.chargingSpecifications.editor.userWillBeCharged') }}
                    </div>
                    <AppInputV3
                        v-model.number="chargeValues[CHARGING_TYPE.perUnit].moneyAmount"
                        :min="0"
                        :step="0.0001"
                        :roundNumber="true"
                        :disablePadding="true"
                        :invalid="requiredErrors.perUnitMoneyAmountError"
                        :errorMessage="genErrorMessage(chargeValues[CHARGING_TYPE.perUnit].moneyAmount)"
                        :class="{ 'invalid-input': requiredErrors.perUnitMoneyAmountError }"
                        :disabled="disabled"
                        class="monetary-amount"
                        placeholder="123"
                        type="number"
                        data-test-id="data-money-amount"
                        @input="setRequiredErrorVal('perUnitMoneyAmountError', false)"
                    />
                    <div
                        :class="{ 'disabled-area': isOnlyRateEnabled }"
                        class="d-flex align-items-center"
                    >
                        <div class="inline-text">
                            {{ $i18n.t('charging.chargingSpecifications.editor.every') }}
                        </div>
                        <UnitPicker
                            v-model.number="chargeValues[CHARGING_TYPE.perUnit].chargePeriodAmount"
                            :error="requiredErrors.perUnitChargePeriodAmountError"
                            :errorMessage="genErrorMessage(chargeValues[CHARGING_TYPE.perUnit].chargePeriodAmount)"
                            :class="{ 'invalid-input up-mt': requiredErrors.perUnitChargePeriodAmountError }"
                            :min="0"
                            :definitionsWidth="5"
                            :isEditorDesign="true"
                            :disabled="disabled"
                            type="data"
                            placeholder="123"
                            class="monetary-amount"
                            data-test-id="data-period-amount"
                            @input="setRequiredErrorVal('perUnitChargePeriodAmountError', false)"
                        />
                    </div>
                </div>
            </div>
        </div>
        <div v-show="chargeType === CHARGING_TYPE.perPeriod">
            <div class="d-flex align-items-center flex-wrap mb-1">
                <div class="inline-text">
                    {{ $i18n.t('charging.chargingSpecifications.editor.userWillBeCharged') }}
                </div>
                <AppInputV3
                    v-model.number="chargeValues[CHARGING_TYPE.perPeriod].moneyAmount"
                    :min="0"
                    :step="0.0001"
                    :roundNumber="true"
                    :invalid="requiredErrors.perPeriodMoneyAmountError"
                    :errorMessage="genErrorMessage(chargeValues[CHARGING_TYPE.perPeriod].moneyAmount)"
                    :class="{ 'invalid-input': requiredErrors.perPeriodMoneyAmountError }"
                    :disabled="disabled"
                    class="monetary-amount"
                    type="number"
                    name="monetary-amount"
                    placeholder="123"
                    data-test-id="data-money-amount"
                    @input="setRequiredErrorVal('perPeriodMoneyAmountError', false)"
                />
                <div
                    :class="{ 'disabled-area': isOnlyRateEnabled }"
                    class="d-flex align-items-center"
                >
                    <div class="inline-text">
                        {{ $i18n.t('charging.chargingSpecifications.editor.per') }}
                    </div>
                    <AppMultiselectV3
                        v-model.number="chargeValues[CHARGING_TYPE.perPeriod].periodType"
                        :allowEmpty="false"
                        :options="PERIOD_TYPES"
                        :preselectFirst="true"
                        :searchable="false"
                        :showLabels="false"
                        :disabled="disabled"
                        class="service-options"
                        label="label"
                        trackBy="id"
                    />
                </div>
            </div>
            <div class="d-flex align-items-center flex-wrap">
                <div
                    :class="{ 'disabled-area': isOnlyRateEnabled }"
                    class="d-flex align-items-center mr-2"
                >
                    <AppMultiselectV3
                        v-model="chargeValues[CHARGING_TYPE.perPeriod].selectedLimit"
                        :allowEmpty="false"
                        :options="limitOptions"
                        :preselectFirst="true"
                        :searchable="false"
                        :showLabels="false"
                        :borderNone="true"
                        :blueArrow="true"
                        :small="true"
                        :disabled="disabled"
                        class="per-period-option"
                        label="label"
                        trackBy="id"
                    />
                    <UnitPicker
                        v-if="!unlimitedPeriod"
                        v-model.number="chargeValues[CHARGING_TYPE.perPeriod].limitValue"
                        :min="0"
                        :definitionsWidth="5"
                        :error="requiredErrors.perPeriodPeriodLimitError"
                        :errorMessage="genErrorMessage(chargeValues[CHARGING_TYPE.perPeriod].limitValue)"
                        :class="{ 'invalid-input up-mt': requiredErrors.perPeriodPeriodLimitError }"
                        :isEditorDesign="true"
                        :disabled="disabled"
                        type="data"
                        placeholder="123"
                        @input="setRequiredErrorVal('perPeriodPeriodLimitError', false)"
                    />
                </div>
                <div
                    :class="{ 'disabled-area': isOnlyRateEnabled }"
                    class="d-flex align-items-center"
                >
                    <AppMultiselectV3
                        v-model="chargeValues[CHARGING_TYPE.perPeriod].selectedTotalLimit"
                        :allowEmpty="false"
                        :options="totatlLimitOptionsFormatted"
                        :preselectFirst="true"
                        :searchable="false"
                        :showLabels="false"
                        :borderNone="true"
                        :blueArrow="true"
                        :small="true"
                        :disabled="disabled"
                        class="per-period-option"
                        label="label"
                        trackBy="id"
                    />
                    <UnitPicker
                        v-if="!unlimitedTotal"
                        v-model.number="chargeValues[CHARGING_TYPE.perPeriod].totalLimitValue"
                        :min="0"
                        :definitionsWidth="5"
                        :error="requiredErrors.perPeriodTotalLimitError"
                        :errorMessage="genErrorMessage(chargeValues[CHARGING_TYPE.perPeriod].totalLimitValue)"
                        :class="{ 'invalid-input up-mt': requiredErrors.perPeriodTotalLimitError }"
                        :isEditorDesign="true"
                        :disabled="disabled"
                        type="data"
                        placeholder="123"
                        @input="setRequiredErrorVal('perPeriodTotalLimitError', false)"
                    />
                </div>
            </div>
        </div>
        <div v-show="chargeType === CHARGING_TYPE.tieredCharge">
            <div v-show="chargeValues[CHARGING_TYPE.tieredCharge].editingEnabled">
                <Thresholds
                    :statuses="chargeValues[chargeType].statuses"
                    :thresholds="chargeValues[chargeType].thresholds"
                    :thresholdType="TYPES_OPTIONS.QUOTA"
                    :definitions="unitTypes.DATA"
                    :requiredErrors="thresholdsError"
                    statusInputType="number"
                    :step="0.1"
                    :statusLabel="$i18n.t('generic.rate')"
                    :disabled="disabled"
                    :showCurrency="true"
                    class="mt-2 mb-4"
                    @change="updateThresholds"
                    @errorsDataChange="updateThresholdErrors"
                />
                <div
                    :class="{ 'disabled-area': isOnlyRateEnabled }"
                    class="d-flex align-items-center"
                >
                    <div class="inline-text">
                        {{ $i18n.t('charging.chargingSpecifications.editor.every') }}
                    </div>
                    <UnitPicker
                        v-model.number="chargeValues[chargeType].chargePeriodAmount"
                        :error="requiredErrors.tieredChargePeriodAmountError"
                        :errorMessage="genErrorMessage(chargeValues[chargeType].chargePeriodAmount)"
                        :class="{ 'invalid-input up-mt': requiredErrors.tieredChargePeriodAmountError }"
                        :min="0"
                        :definitionsWidth="5"
                        :isEditorDesign="true"
                        :disabled="disabled"
                        type="data"
                        placeholder="123"
                        class="monetary-amount"
                        data-test-id="data-period-amount"
                        @input="setRequiredErrorVal('tieredChargePeriodAmountError', false)"
                    />
                </div>
            </div>
        </div>
        <div v-show="chargeType === CHARGING_TYPE.quota">
            <div
                :class="{ 'disabled-area': isOnlyRateEnabled }"
                class="d-flex align-items-center"
            >
                <div class="inline-text">
                    {{ $i18n.t('charging.chargingSpecifications.editor.userWillGet') }}
                </div>
                <UnitPicker
                    v-model.number="chargeValues[CHARGING_TYPE.quota].countAmount"
                    :error="requiredErrors.quotaCountAmountError"
                    :errorMessage="genErrorMessage(chargeValues[CHARGING_TYPE.quota].countAmount)"
                    :class="{ 'invalid-input up-mt': requiredErrors.quotaCountAmountError }"
                    :min="0"
                    :definitionsWidth="5"
                    :isEditorDesign="true"
                    :disabled="disabled"
                    class="monetary-amount"
                    type="data"
                    placeholder="123"
                    data-test-id="data-count-amount"
                    @input="setRequiredErrorVal('quotaCountAmountError', false)"
                />
                <div class="inline-text">
                    {{ $i18n.t('charging.chargingSpecifications.editor.chargePeriod') }}
                </div>
                <UnitPicker
                    v-model.number="chargeValues[CHARGING_TYPE.quota].chargePeriodAmount"
                    :error="requiredErrors.quotaChargePeriodAmountError"
                    :errorMessage="genErrorMessage(chargeValues[CHARGING_TYPE.quota].chargePeriodAmount)"
                    :class="{ 'invalid-input up-mt': requiredErrors.quotaChargePeriodAmountError }"
                    :definitionsWidth="5"
                    :min="0"
                    :isEditorDesign="true"
                    :disabled="disabled"
                    class="monetary-amount"
                    type="data"
                    placeholder="123"
                    data-test-id="data-period-amount"
                    @input="setRequiredErrorVal('quotaChargePeriodAmountError', false)"
                />
                <div class="d-flex align-items-center">
                    <div class="inline-text">
                        {{ $i18n.t('charging.chargingSpecifications.editor.groupBalance') }}
                    </div>
                    <AppToggle
                        v-model="chargeValues[CHARGING_TYPE.quota].groupBalance"
                        :small="true"
                        :disabled="disabled"
                        class="group-checkbox"
                    />
                </div>
            </div>
        </div>
        <div v-show="chargeType === CHARGING_TYPE.unlimited">
            <div v-show="chargeValues[CHARGING_TYPE.unlimited].fupEnabled">
                <div class="d-flex align-items-center mt-2">
                    <div class="d-flex align-items-center">
                        <UnitPicker
                            v-model.number="chargeValues[CHARGING_TYPE.unlimited].fupAmount"
                            :min="0"
                            :definitionsWidth="5"
                            :isEditorDesign="true"
                            :disabled="disabled"
                            type="data"
                            class="align-items-center"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// Components
import AppMultiselectV3 from '@/components/partials/inputs/AppMultiselectV3.vue';
import AppToggle from '@/components/partials/inputs/AppToggle.vue';
import UnitPicker from '@/components/partials/inputs/UnitPicker.vue';
import AppInputV3 from '@/components/partials/inputs/AppInputV3.vue';
import Thresholds from '@/__new__/features/charging/Thresholds.vue';

// Mixins
import ChargeConfigurationMixin from '@/__new__/features/charging/ChargeConfigurationMixin.vue';

// Helpers
import { CHARGING_TYPE } from '@/__new__/services/dno/charging/common/chargingSpecificationHelper';
import permissionsService from '@/services/permissions/permissions.service';

export default {
    name: 'ChargeConfigurationDataV3',
    components: {
        AppMultiselectV3,
        AppToggle,
        UnitPicker,
        AppInputV3,
        Thresholds,
    },
    mixins: [ChargeConfigurationMixin],
    props: {
        value: {
            type: Object,
            default: null,
        },
        chargeType: {
            type: String,
            default: CHARGING_TYPE.perUnit,
        },
        limitOptions: {
            type: Array,
            default: () => [],
        },
        totatlLimitOptions: {
            type: Array,
            default: () => [],
        },
        isOnlyRateEnabled: {
            type: Boolean,
            default: false,
        },
        requiredError: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            permissionsService,
        };
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/base.scss';
@import '~@/assets/scss/_palette.scss';

.inline-text {
    font-size: 0.75rem;
    text-transform: uppercase;
    font-weight: bold;
    color: $gray-blue;
    margin-right: 0.75rem;
}

.monetary-amount {
    margin-right: 0.75rem;
}

.service-options {
    margin: 0.25rem 0.75rem 0 0;
    flex: 0 1 9rem;
    min-width: 118px;
}

.per-period-option {
    margin: 0.4375rem 0.75rem 0 0;
    flex: 1 0 11rem;
}

.group-checkbox {
    font-weight: normal;
}

.section-content {
    font-size: 0.75rem;
    font-weight: 600;
    margin-right: 1rem;
    color: $gray90;
}

.invalid-input {
    position: relative;
    top: 0.7rem;
    margin-bottom: 0.625rem;
}

.up-mt {
    margin-top: 0.6rem;
}
</style>
