<template>
    <div class="transactions-filter-picker">
        <div class="d-flex mr-2 align-items-center">
            <AppMultiselectV3
                :value="timeRangeTypeValue"
                :options="timeRangeOptionsList"
                :allowEmpty="false"
                :searchable="false"
                :showLabels="false"
                :isSmall="true"
                :borderNone="true"
                :blueArrow="true"
                openDirection="bottom"
                class="narrow time-range-type-picker"
                @input="onTimeRangeValuePicked"
            />
            <span class="all-caps-text">
                {{ $i18n.t('generic.for') }}
            </span>
            <span
                v-if="!usedInRewards"
                class="d-flex"
            >
                <AppMultiselectV3
                    v-model="selectedTransactionWalletType"
                    :options="transactionsWalletTypesList"
                    :isSmall="true"
                    :preselectFirst="true"
                    :allowEmpty="false"
                    :searchable="false"
                    :showLabels="false"
                    label="label"
                    openDirection="bottom"
                    class="small narrow time-range-type-picker"
                />
            </span>
            <span
                v-else-if="usedInRewards"
                class="d-flex"
            >
                <AppMultiselectV3
                    v-model="selectedRewardsTransactionWalletType"
                    :options="walletTypesRewards"
                    :isSmall="true"
                    :preselectFirst="true"
                    :allowEmpty="false"
                    :searchable="false"
                    :showLabels="false"
                    openDirection="bottom"
                    class="small narrow time-range-type-picker"
                />
            </span>
        </div>
        <div class="d-flex align-items-center">
            <div
                v-if="timeRangeTypeValue === TimeRangeOptions.postpaid.choose"
                class="d-flex align-items-center"
            >
                <div class="mr-2 all-caps-text">
                    {{ $i18n.t('generic.is') }}
                </div>
                <AppMultiselectV3
                    v-model="chosenBillingCycle"
                    :allowEmpty="false"
                    :preselectFirst="true"
                    :maxHeight="162"
                    :options="chooseBillingCycleOptions"
                    :searchable="false"
                    :showLabels="false"
                    :isSmall="true"
                    :placeholder="$i18n.t('customerCare.transactionHistory.billingCycle.choose')"
                    openDirection="bottom"
                    label="label"
                    trackBy="label"
                    class="small time-range-type-picker"
                />
            </div>
            <div
                v-if="isCustomTimeRange"
                class="d-flex align-items-center"
            >
                <div class="mr-2 all-caps-text">
                    {{ $i18n.t('generic.between') }}
                </div>
                <DateTimePicker
                    v-model="dateStart"
                    :disabledDates="disableDateStart"
                    type="datetime"
                    class="date-picker small mr-2"
                />
                <div class="mr-2 all-caps-text">
                    {{ $i18n.t('generic.and') }}
                </div>
                <DateTimePicker
                    v-model="dateEnd"
                    :disabledDates="disableDateEnd"
                    type="datetime"
                    class="date-picker small"
                />
            </div>
            <div
                v-if="timeRangeTypeValue === TimeRangeOptions.allOther.chooseCalendarMonth"
                class="d-flex align-items-center"
            >
                <div class="mr-2 all-caps-text">
                    {{ $i18n.t('generic.is') }}
                </div>
                <DateTimePicker
                    v-model="month"
                    :disabledDates="disableDateStart"
                    type="month"
                    class="date-picker small"
                />
            </div>
            <AppButton
                :isSmall="true"
                :disabled="isButtonDisabled"
                :label="$i18n.t('generic.add')"
                @click="onApplyClick"
            />
        </div>
    </div>
</template>
<script>
// COMPONENTS
import AppMultiselectV3 from '@/components/partials/inputs/AppMultiselectV3.vue';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import DateTimePicker from '@/components/partials/inputs/DateTimePicker.vue';

// HELPERS
import i18n from '@/i18n';
import moment, { Moment } from 'moment';
import isEqual from 'lodash/isEqual';
import { UM_SUBSCRIPTION_TYPES } from '@/__new__/features/customerCare/common/userInfoHelper';
import { REWARD_WALLET_TYPE } from '@/__new__/features/customerCare/common/ordersHelper';

export const transactionsWalletTypes = {
    all: 'All',
    monetary: 'Monetary wallet',
    bill: 'Bill',
};

export default {
    name: 'TransactionsFilterPicker',
    components: {
        AppButton,
        AppMultiselectV3,
        DateTimePicker,
    },
    props: {
        lastBillingDate: {
            type: Moment,
            required: true,
        },
        subscriberRegistrationTimestamp: {
            type: Number,
            required: true,
        },
        subscriptionType: {
            type: Number,
            required: true,
        },
        usedInRewards: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data() {
        return {
            chosenBillingCycle: '',
            timeRangeTypeValue: '',
            cachedTimestampsForTransactionsRequest: null,
            REWARD_WALLET_TYPE,
            dateStart: moment().subtract(30, 'days').toDate(),
            dateEnd: moment().toDate(),
            month: moment().toDate(),
            selectedTransactionWalletType: '',
            walletTypeMonitored: '',
            selectedRewardsTransactionWalletType: REWARD_WALLET_TYPE.COINS,
            walletTypesRewards: Object.values(REWARD_WALLET_TYPE),
            BUTTON_TYPES,
        };
    },
    computed: {
        disableDateEnd() {
            return date => {
                const restrictions = {
                    from: moment(this.dateStart).add(31, 'day').toDate(),
                    to: moment(this.dateStart).toDate(),
                };

                return date < restrictions.to || date > restrictions.from;
            };
        },
        transactionsWalletTypesList() {
            return [
                {
                    label: i18n.t('rewards.wallet.all'),
                    id: transactionsWalletTypes.all,
                },
                {
                    label: i18n.t('rewards.wallet.monetary'),
                    id: transactionsWalletTypes.monetary,
                },
                {
                    label: i18n.t('rewards.wallet.bill'),
                    id: transactionsWalletTypes.bill,
                },
            ];
        },
        timeRanges() {
            return {
                current: this.$i18n.t('customerCare.transactionHistory.billingCycle.current'),
                choose: this.$i18n.t('customerCare.transactionHistory.billingCycle.choose'),
                custom: this.$i18n.t('generic.custom'),
                currentMonth: this.$i18n.t('customerCare.transactionHistory.billingCycle.currentMonth'),
                lastMonth: this.$i18n.t('customerCare.transactionHistory.billingCycle.lastMonth'),
                chooseCalendarMonth: this.$i18n.t('customerCare.transactionHistory.billingCycle.chooseCalendarMonth'),
            };
        },
        TimeRangeOptions() {
            return {
                postpaid: {
                    current: this.timeRanges.current,
                    choose: this.timeRanges.choose,
                    custom: this.timeRanges.custom,
                },
                allOther: {
                    currentMonth: this.timeRanges.currentMonth,
                    lastMonth: this.timeRanges.lastMonth,
                    chooseCalendarMonth: this.timeRanges.chooseCalendarMonth,
                    custom: this.timeRanges.custom,
                },
                rewards: {
                    currentMonth: this.timeRanges.currentMonth,
                    lastMonth: this.timeRanges.lastMonth,
                    custom: this.timeRanges.custom,
                },
            };
        },
        disableDateStart() {
            return date => {
                const restrictions = {
                    from: moment(this.dateEnd).toDate(),
                };

                return date > restrictions.from;
            };
        },
        chooseBillingCycleOptions() {
            if (this.lastBillingDate && this.subscriberRegistrationTimestamp) {
                const resultArray = [];
                resultArray.push({
                    startTime: this.lastBillingDate,
                    endTime: moment(),
                    label: this.getLabelForCustomBillingCycleOption(this.lastBillingDate, moment()),
                });

                const monthsAmountBetweenLastBillingAndSubscriberCreation = moment
                    .duration(moment(this.lastBillingDate).diff(moment.unix(this.subscriberRegistrationTimestamp)))
                    .asMonths();

                for (let i = 0; i < Math.trunc(monthsAmountBetweenLastBillingAndSubscriberCreation); i += 1) {
                    const endTime = resultArray[resultArray.length - 1].startTime;
                    const startTime = endTime.clone().subtract(1, 'month');
                    resultArray.push({
                        endTime,
                        startTime,
                        label: this.getLabelForCustomBillingCycleOption(startTime, endTime),
                    });
                }

                if (monthsAmountBetweenLastBillingAndSubscriberCreation % 1 !== 0) {
                    const endTime = resultArray[resultArray.length - 1].startTime;
                    const startTime = moment.unix(this.subscriberRegistrationTimestamp);
                    resultArray.push({
                        endTime,
                        startTime,
                        label: this.getLabelForCustomBillingCycleOption(startTime, endTime),
                    });
                }

                return resultArray;
            }
            return [];
        },
        getTimestampsForTransactionsRequest() {
            let startTime = this.lastBillingDate;

            // adding one day to current time to make sure we cover all time zones
            let endTime = moment().add(1, 'day');

            switch (this.timeRangeTypeValue) {
                case this.timeRanges.currentMonth:
                    startTime = moment().startOf('month');
                    break;
                case this.timeRanges.lastMonth:
                    startTime = moment().subtract(1, 'months').startOf('month');
                    endTime = moment().subtract(1, 'months').endOf('month');
                    break;
                case this.timeRanges.chooseCalendarMonth:
                    startTime = moment(this.month).startOf('month');
                    endTime = moment(this.month).endOf('month');

                    break;
                case this.timeRanges.choose:
                    ({ startTime, endTime } = { ...this.chosenBillingCycle });
                    if (this.chosenBillingCycle && endTime.date() === moment().date()) {
                        // adding +1 day if current billing cycle was picked
                        endTime.add(1, 'day');
                    }
                    break;
                case this.timeRanges.custom:
                    startTime = moment(this.dateStart).seconds(0);
                    endTime = moment(this.dateEnd).seconds(0);
                    break;
                default:
                    break;
            }
            return {
                startTime,
                endTime,
            };
        },
        isButtonDisabled() {
            return (
                isEqual(this.cachedTimestampsForTransactionsRequest, this.getTimestampsForTransactionsRequest) &&
                this.walletTypeMonitored === this.selectedTransactionWalletType.id &&
                !this.usedInRewards
            );
        },
        timeRangeOptionsList() {
            if (this.usedInRewards) {
                return Object.values(this.TimeRangeOptions.rewards);
            }
            return this.subscriptionType === UM_SUBSCRIPTION_TYPES.POSTPAID
                ? Object.values(this.TimeRangeOptions.postpaid)
                : Object.values(this.TimeRangeOptions.allOther);
        },
        isCustomTimeRange() {
            return (
                this.timeRangeTypeValue === this.TimeRangeOptions.postpaid.custom ||
                this.timeRangeTypeValue === this.TimeRangeOptions.allOther.custom ||
                this.timeRangeTypeValue === this.TimeRangeOptions.rewards.custom
            );
        },
    },
    created() {
        this.timeRangeTypeValue = this.timeRanges.custom;
        this.onApplyClick();
    },
    methods: {
        onTimeRangeValuePicked(value) {
            this.timeRangeTypeValue = value;
        },
        getLabelForCustomBillingCycleOption(startTime, endTime) {
            return `${startTime.format('MMM DD, YYYY')} - ${endTime.format('MMM DD, YYYY')}`;
        },
        onApplyClick() {
            this.cachedTimestampsForTransactionsRequest = this.getTimestampsForTransactionsRequest;
            // if the values of the newly selected wallet_type and previosly selected wallet_type are the same,
            // keep the Apply button disabled
            if (this.walletTypeMonitored !== this.selectedTransactionWalletType.id) {
                this.walletTypeMonitored = this.selectedTransactionWalletType.id;
            }

            if (this.usedInRewards) {
                this.$emit('walletTypeChanged', this.selectedRewardsTransactionWalletType);
            }

            this.$emit('timeRangeChanged', {
                timestamps: this.getTimestampsForTransactionsRequest,
                walletType: this.selectedTransactionWalletType.id,
            });
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/palette';
@import '~@/assets/scss/mixins';

.transactions-filter-picker {
    display: flex;
    max-width: calc(100vw - 330px);

    @include tablet {
        flex-wrap: wrap;
    }
}

.time-range-type-picker {
    width: 205px;
}

.date-picker ::v-deep .datepicker-wrapper {
    min-width: 9rem;
    width: 9rem;
}

.transactions-label {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 0.875rem;
    color: $gray60;
}
</style>
