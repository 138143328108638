<template>
    <div class="modal-content-wrapper">
        <h2 class="mb-4 lf-title">
            {{ $i18n.t('customerCare.subscriberTab.swapSIM') }}
        </h2>
        <div class="form-content">
            <AppInputV3
                v-model="$v.newICCID.$model"
                :invalid="$v.newICCID.$error"
                :errorMessage="$i18n.t('customerCare.subscriberTab.ICCIDvalidationError')"
                :placeholder="$i18n.t('customerCare.subscriberTab.enterICCID')"
                :label="$i18n.t('customerCare.subscriberTab.enterICCID')"
                class="mb-3"
            />
            <AppInputV3
                v-model="$v.confirmationICCID.$model"
                :invalid="$v.confirmationICCID.$error"
                :errorMessage="$i18n.t('customerCare.subscriberTab.ICCIDmustMatch')"
                :placeholder="$i18n.t('customerCare.subscriberTab.reenterICCID')"
                :label="$i18n.t('customerCare.subscriberTab.reenterICCID')"
                class="mb-3"
            />
        </div>

        <hr />
        <div class="d-flex justify-content-end">
            <AppButton
                :label="$i18n.t('generic.cancel')"
                class="m-2 mr-3"
                @click="onCloseModal"
            />
            <AppButton
                :buttonType="BUTTON_TYPES.PRIMARY"
                data-test-id="external-user-mapping-save-button"
                :label="$i18n.t('generic.save')"
                class="m-2"
                @click="onSave"
            />
        </div>
    </div>
</template>

<script>
// Components
import AppInputV3 from '@/components/partials/inputs/AppInputV3.vue';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';

// http
import customerCareAJAX from '@/__new__/services/dno/user/http/customer-care';
// import luaErrorCodes from '@/common/luaErrors';

// helpers
import { USER_MANAGER_HIERARCHY } from '@/__new__/features/customerCare/common/customerCareHelper';
import { ALERT_TYPES } from '@/common/alerts/Alert';
import permissionsService from '@/services/permissions/permissions.service';
import Subscriber from '@/__new__/services/dno/user/models/Subscriber';
import ENTITY_TYPES from '@/common/entities/entityTypes';
import { validationMixin } from 'vuelidate';
import { required, sameAs, minLength, maxLength, numeric } from 'vuelidate/lib/validators';
import * as Sentry from '@sentry/vue';

export default {
    name: 'SimSwapModal',
    components: {
        AppInputV3,
        AppButton,
    },
    mixins: [validationMixin],
    props: {
        subscriberId: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            newICCID: '',
            confirmationICCID: '',
            permissionsService,
            flagsForUpdate: {},
            propertiesForUpdate: {},
            value: true,
            subscriber: new Subscriber({}),
            entityType: ENTITY_TYPES.USER_MANAGEMENT_SUBSCRIBER,
            simSwapDialogVisible: false,
            profileData: {},
            BUTTON_TYPES,
        };
    },
    validations() {
        return {
            newICCID: {
                required,
                numeric,
                minLength: minLength(18),
                maxLength: maxLength(22),
            },
            confirmationICCID: {
                sameAsNewICCID: sameAs('newICCID'),
            },
        };
    },
    async created() {
        try {
            this.$Progress.start();
            const profileInfo = await customerCareAJAX.getProfileInfo({
                targetType: USER_MANAGER_HIERARCHY.SUBSCRIBER,
                targetId: this.subscriberId,
            });
            this.$Progress.finish();
            // eslint-disable-next-line camelcase
            this.profileData = profileInfo.data?.profile_data;
        } catch (error) {
            Sentry.captureException(error);
            this.$Progress.fail();
        }
    },
    methods: {
        onSave() {
            this.$v.$touch();
            if (!this.$v.$anyError) {
                this.swapSim(this.newICCID);
            }
        },
        async swapSim(newICCID) {
            try {
                /* eslint-disable camelcase */
                const payload = {
                    msisdn: this.profileData?.msisdn,
                    target_id: this.subscriberId,
                    target_type: USER_MANAGER_HIERARCHY.SUBSCRIBER,
                    sim_type_old: this.profileData?.sim_type,
                    iccid_old: this.profileData?.iccid,
                    // hardcoded for now (needs clarification and UI update)
                    sim_type_new: 20,
                    iccid_new: newICCID,
                };
                /* eslint-enable camelcase */
                this.$Progress.start();
                await customerCareAJAX.swapSIM(payload);
                this.$Progress.finish();

                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('customerCare.subscriberTab.swapSIMSuccessAlert'),
                    type: ALERT_TYPES.success,
                });
            } catch (error) {
                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('customerCare.subscriberTab.swapSIMErrorAlert'),
                    type: ALERT_TYPES.error,
                });
                Sentry.captureException(error);
                this.$Progress.fail();
            } finally {
                setTimeout(() => {
                    this.onCloseModal();
                }, 2000);
            }
        },
        onCloseModal() {
            this.$emit('closeModal');
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/_layout.scss';

.modal-content-wrapper {
    margin: $spacing-l;

    height: 24rem;

    display: flex;
    flex-direction: column;
}
</style>
