<template>
    <div>
        <div
            v-for="(element, index) in nsiData.data"
            :key="index"
        >
            <div class="double_input">
                <NetworkSliceIdentifierKeyValue
                    v-model="nsiData.data[index]"
                    :idNumber="index"
                    :invalidSST="invalidSST[index]"
                    :sst="nsiData.data[index].sst"
                    :sd="nsiData.data[index].sd"
                    :index="index"
                    @input="emitValue(index)"
                />
                <img
                    v-if="showDeleteButton"
                    data-test-id="deleteNSI"
                    src="~@/assets/icons/delete-trash.svg"
                    class="delete-btn col-1"
                    alt="delete-button"
                    @click="deleteInput(index)"
                />
            </div>
        </div>
        <AppButton
            data-test-id="addNewNSI"
            :label="$i18n.t('productCatalog.services.editor.networkSliceIdentifierNew')"
            :iconType="ICON_TYPES.PLUS"
            :disabled="disableAdd"
            :isSmall="true"
            @click="addInput"
        />
    </div>
</template>

<script>
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import { ICON_TYPES } from '@/common/iconHelper';
import NetworkSliceIdentifierKeyValue from '@/__new__/features/charging/conditionParameters/conditions/NetworkSliceIdentifierKeyValue.vue';
import NsiCondition from '@/__new__/services/dno/charging/models/chargingSpecificationConditions/NsiCondition';
import { isEmpty } from 'lodash';

export default {
    name: 'NetworkSliceIdentifier',
    components: {
        AppButton,
        NetworkSliceIdentifierKeyValue,
    },
    props: {
        value: {
            type: Object,
            default: () => undefined,
        },
    },
    data() {
        return {
            NSIElements: {},
            enableDeleteButton: false,
            invalidSST: {},
            nsiData: {},
            ICON_TYPES,
            BUTTON_TYPES,
        };
    },
    computed: {
        showDeleteButton() {
            return this.getObjectLength(this.nsiData.data) > 1;
        },
        disableAdd() {
            return !(
                this.nsiData.data[Object.keys(this.nsiData.data)[Object.keys(this.nsiData.data).length - 1]].sst
                    .length > 0
            );
        },
    },
    watch: {
        value: {
            handler() {
                this.nsiData = new NsiCondition(this.value);
                this.invalidSST[this.getObjectLength(this.nsiData.data)] = false;
            },
        },
    },
    created() {
        if (!isEmpty(this.value.data)) {
            this.NSIElements = this.value;
        } else {
            const data = {
                [this.getObjectLength(this.NSIElements)]: {
                    sst: [],
                    sd: [],
                },
            };
            this.NSIElements = this.value;
            this.NSIElements.data = data;
        }
        this.nsiData = new NsiCondition(this.NSIElements);
        this.invalidSST[this.getObjectLength(this.nsiData.data)] = false;
    },
    methods: {
        emitValue(index) {
            this.invalidSST[index] = !this.sstUnique(index);
            if (this.invalidSST[index]) {
                this.nsiData.data[index].sst.splice(this.nsiData.data[index].sst.length - 1, 1);
            }

            this.dataInput();

            this.invalidSST[index] = false;
        },
        sstUnique(index) {
            let t = true;
            const lastElement = this.nsiData.data[index].sst[this.nsiData.data[index].sst.length - 1];
            for (const i in this.nsiData.data) {
                if (Object.prototype.hasOwnProperty.call(this.nsiData.data, i)) {
                    for (let j = 0; j < this.nsiData.data[i].sst.length; j += 1) {
                        if (!(i === index && j === Object.values(this.nsiData.data[i].sst).length - 1)) {
                            if (this.nsiData.data[i].sst[j] === lastElement) {
                                t = false;
                            }
                        }
                    }
                }
            }
            return t;
        },
        addInput() {
            this.$set(
                this.nsiData.data,
                Number(Object.keys(this.nsiData.data)[this.getObjectLength(this.nsiData.data) - 1]) + 1,
                {
                    sst: [],
                    sd: [],
                },
            );
            this.nsiData.validate();
        },
        deleteInput(index) {
            this.$delete(this.nsiData.data, index);
            this.$delete(this.invalidSST, index);

            this.nsiData.validate();
            this.$emit('input', this.nsiData);
        },
        getObjectLength(obj) {
            return !isEmpty(obj) ? Object.values(obj).length : 0;
        },
        dataInput() {
            this.nsiData.validate();
            this.$emit('input', this.nsiData);
        },
    },
};
</script>
<style lang="scss" scoped>
@import '~@/assets/scss/colors';
@import '~@/assets/scss/palette';
@import '~@/assets/scss/layout';
@import '~@/assets/scss/icons';

$icon-path: '~@/assets/icons/';
.double_input {
    display: flex;
    justify-content: flex-start;
    &:hover {
        .delete-btn {
            opacity: 1;
        }
    }
}
.input_item {
    margin-right: 3rem;
    min-width: 14rem;
    width: 24%;
    &.full-width {
        width: 100%;
    }
}
.delete-btn {
    cursor: pointer;
    top: 3rem;
    height: 1rem;
    transform: translateY(-50%);
    opacity: 0;
}
</style>
