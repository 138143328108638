
import Vue from '@/common/typedVue';

// components
import AppMultiselectV3 from '@/components/partials/inputs/AppMultiselectV3.vue';
import BulkUploadPageLayout from '@/__new__/features/resources/BulkUploadPageLayout.vue';
import { getUploadHistory } from '@/__new__/services/dno/progressTracker/http/progressTrackerService';

// helpers
import tableColumnType, { type TableColumn } from '@/common/filterTable';
import {
    formatEventCategory,
    getAllFileUploadStatusStrings,
    BPA_FILE_TYPE,
    type UploadedFileDetails,
} from '@/common/fileUploadHelper';
import type { CollapsibleListItem } from '@/common/AppCollapsibleListHelper';
import { DATAFLOW_API_TYPE } from '@/__new__/services/dno/progressTracker/progressTrackerHelper';
import { SIM_TYPES_MAP } from '@/__new__/features/customerCare/subscriber/common/simTypes';

type SimResourceUpload = {
    resourceType?: {
        id: SIM_TYPES_MAP;
        name: string;
    };
};

export default Vue.extend({
    name: 'BatchSimFileProcess',
    components: {
        AppMultiselectV3,
        BulkUploadPageLayout,
    },

    data() {
        return {
            multiple: false,
            tab: BPA_FILE_TYPE.INPUT,
            upload: {
                resourceType: undefined,
            } as SimResourceUpload,
            // table data
            entities: [] as UploadedFileDetails[],
            uploadHistoryColumnsData: [
                {
                    name: this.$t('generic.uploadTime'),
                    key: 'createdAtStr',
                    mapper: entity => this.$localeLibrary.getFormattedDateAndTime(entity.createdAt),
                    sortBy: (entity: UploadedFileDetails) => entity.createdAt,
                    field: 'createdAt',
                    filterType: tableColumnType.DATE,
                },
                {
                    name: this.$t('generic.filename'),
                    key: 'fileName',
                    field: 'fileName',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$t('qodNumberManagement.recordCount'),
                    key: 'recordCount',
                    field: 'recordCount',
                    filterType: tableColumnType.NUMBER,
                },
                {
                    name: this.$t('generic.status'),
                    key: 'fileUploadStatusStr',
                    field: 'fileUploadStatusStr',
                    filterType: tableColumnType.TEXT_LIMITED_OPTIONS,
                    limitedOptions: getAllFileUploadStatusStrings(),
                },
            ] as TableColumn[],
            uploadHistoryDefaultSort: {
                key: 'createdAtStr',
                sortBy: (entity: UploadedFileDetails) => entity.createdAt,
                type: 'desc',
            },
            isHistoryDataLoading: false as boolean,
        };
    },
    computed: {
        acceptType() {
            switch (this.tab) {
                case BPA_FILE_TYPE.INPUT:
                    return `.${BPA_FILE_TYPE.INPUT}`;
                case BPA_FILE_TYPE.GOOD:
                    return `.${BPA_FILE_TYPE.GOOD}`;
                case BPA_FILE_TYPE.CSV:
                    return `.${BPA_FILE_TYPE.CSV}`;
                default:
                    return '';
            }
        },
        dataflow() {
            switch (this.tab) {
                case BPA_FILE_TYPE.INPUT:
                    return DATAFLOW_API_TYPE.BULK_PRE_ACTIVATION_INPUT_FILE;
                case BPA_FILE_TYPE.GOOD:
                    return DATAFLOW_API_TYPE.BULK_PRE_ACTIVATION_GOOD_FILE;
                case BPA_FILE_TYPE.CSV:
                    return DATAFLOW_API_TYPE.BULK_PRE_ACTIVATION_WHITELIST_OFFER;
                default:
                    return '';
            }
        },
        apiTypeOptions(): Array<{
            id: BPA_FILE_TYPE;
            label: string;
        }> {
            return [
                {
                    id: BPA_FILE_TYPE.INPUT,
                    label: this.$t('batchSimFileProcess.step1'),
                },
                {
                    id: BPA_FILE_TYPE.GOOD,
                    label: this.$t('batchSimFileProcess.step2'),
                },
                {
                    id: BPA_FILE_TYPE.CSV,
                    label: this.$t('batchSimFileProcess.step3'),
                },
            ];
        },
        title(): string {
            return `${this.$t('batchSimFileProcess.batchSimFileProcess')}`;
        },
        resourceTypes(): Array<SimResourceUpload['resourceType']> {
            return [
                { name: this.$t('generic.eSIM'), id: SIM_TYPES_MAP.ESIM },
                { name: this.$t('generic.pSIM'), id: SIM_TYPES_MAP.PSIM },
            ];
        },
        uploadMetadata(): Record<string, string | number> {
            return {
                resourceType: String(this.upload.resourceType?.id),
            };
        },
        entitiesOfCurrentStep(): UploadedFileDetails[] {
            return this.entities.filter(entity => {
                const { fileName } = entity;
                const extision = fileName?.split('.').pop();
                return extision === this.tab;
            });
        },
        uploadDesc() {
            switch (this.tab) {
                case BPA_FILE_TYPE.INPUT:
                    return this.$t('batchSimFileProcess.step1Desc');
                case BPA_FILE_TYPE.GOOD:
                    return this.$t('batchSimFileProcess.step2Desc');
                case BPA_FILE_TYPE.CSV:
                    return this.$t('batchSimFileProcess.step3Desc');
                default:
                    return '';
            }
        },
    },
    created() {
        this.loadHistory();
    },
    methods: {
        mapSidebarData(entity: UploadedFileDetails): CollapsibleListItem[] {
            return [
                {
                    name: this.$t('generic.general'),
                    isCollapsed: false,
                    rows: [
                        {
                            name: this.$t('generic.filename'),
                            value: entity?.fileName ?? '',
                        },
                        {
                            name: this.$t('qodNumberManagement.bulkUploadId'),
                            value: entity?.bulkUploadId ?? this.$t('generic.unknown'),
                        },
                        {
                            name: this.$t('qodNumberManagement.recordCount'),
                            value: entity?.recordCount ?? this.$t('generic.unknown'),
                        },
                        {
                            name: this.$t('generic.uploadedBy'),
                            value: entity?.createdBy ?? this.$t('generic.unknown'),
                        },
                        {
                            name: this.$t('generic.uploadTime'),
                            value: this.$localeLibrary.getFormattedDateAndTime(entity?.createdAt),
                        },
                    ],
                },
                {
                    name: this.$t('qodNumberManagement.provisionStatus'),
                    rows: Object.entries(entity?.eventCategoryCounters || {}).map(([status, count]) => ({
                        name: formatEventCategory(status),
                        value: `${count}/${entity?.recordCount}`,
                    })),
                    isCollapsed: false,
                },
            ];
        },
        loadHistory(): Promise<void> {
            this.entities = [];
            return this.$withProgressBar(
                async () => {
                    this.isHistoryDataLoading = true;
                    const allSteps: DATAFLOW_API_TYPE[] = [
                        DATAFLOW_API_TYPE.BULK_PRE_ACTIVATION_INPUT_FILE,
                        DATAFLOW_API_TYPE.BULK_PRE_ACTIVATION_GOOD_FILE,
                        DATAFLOW_API_TYPE.BULK_PRE_ACTIVATION_WHITELIST_OFFER,
                    ];

                    await Promise.all(
                        allSteps.map(async dataflow => {
                            const data = await getUploadHistory(dataflow);
                            this.entities.push(...data);
                        }),
                    );

                    this.isHistoryDataLoading = false;
                },
                {
                    errorHandler: () => {
                        this.isHistoryDataLoading = false;
                        this.$alert(this.$t('qodNumberManagement.loadingBulkUploadHistoryFailed'));
                    },
                },
            );
        },
        onTabSelect(tabId: BPA_FILE_TYPE) {
            this.tab = tabId;
        },
        customFileValidation(files) {
            if (!this.upload.resourceType?.id) {
                this.$alert(this.$t('batchSimFileProcess.selectSimType'));
                return false;
            }
            if (!files.length) {
                this.$alert(this.$t('batchSimFileProcess.selectFile'));
                return false;
            }
            const uploadFileName = files[0].file.name;
            const targetFileNameExp = `_${this.upload.resourceType.name.toLowerCase()}.${this.tab}`;
            if (!uploadFileName.endsWith(targetFileNameExp)) {
                this.$alert(
                    this.$t('batchSimFileProcess.invalidFileName', {
                        selectedSimeType: this.upload.resourceType.name.toLowerCase(),
                        fileExtension: this.tab,
                    }),
                );
                return false;
            }
            return true;
        },
    },
});
