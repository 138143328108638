/* eslint-disable camelcase */
import ProductCatalogEntityBase from '@/__new__/services/dno/pc/models/ProductCatalogEntityBase';

import { productReservedKeys } from '@/__new__/features/pc/common/entitiesReservedKeys';

class ProductCatalogProductModel extends ProductCatalogEntityBase {
    properties;

    restrictedProduct;

    constructor(entityJson) {
        super(entityJson);

        // seting template engine properties data here
        this.properties = Object.keys(entityJson.data)
            .filter(key => !productReservedKeys.includes(key))
            .reduce((result, key) => {
                result[key] = entityJson.data[key];
                return result;
            }, {});

        this.restrictedProduct = entityJson.data.restrictedProduct || 'false';
    }

    // to parse data for the API call
    static toJson(entityData) {
        const genericDataFromatted = super.toJson(entityData);

        return {
            ...genericDataFromatted,
        };
    }
}

export default ProductCatalogProductModel;
