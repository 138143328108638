import i18n from '@/i18n';
import { TranslateResult } from 'vue-i18n';
import { LABEL_COLOR } from '@/common/labelsHelper';

export { STATUS_CODES } from '@/common/commonHelper';

export enum ORDER_NEXTGEN_ENTITY_STATES {
    // BE enums
    PENDING = 1,
    DONE = 2,
    PAYMENT_FAILED = 3,
    IN_PROGRESS = 4,
    ON_HOLD = 5,
    CANCELED = 6,
}

export const ORDER_NEXTGEN_STATUS_INDICATOR_MAP: Map<ORDER_NEXTGEN_ENTITY_STATES, string | undefined> = new Map([
    [ORDER_NEXTGEN_ENTITY_STATES.PENDING, LABEL_COLOR.yellow],
    [ORDER_NEXTGEN_ENTITY_STATES.DONE, LABEL_COLOR.green],
    [ORDER_NEXTGEN_ENTITY_STATES.PAYMENT_FAILED, LABEL_COLOR.red],
    [ORDER_NEXTGEN_ENTITY_STATES.IN_PROGRESS, LABEL_COLOR.yellow],
    [ORDER_NEXTGEN_ENTITY_STATES.ON_HOLD, LABEL_COLOR.yellow],
    [ORDER_NEXTGEN_ENTITY_STATES.CANCELED, LABEL_COLOR.red],
]);

export const ORDER_NEXTGEN_ENTITY_STATES_TO_STATUS_NAME_MAP: Map<ORDER_NEXTGEN_ENTITY_STATES, TranslateResult> =
    new Map([
        [ORDER_NEXTGEN_ENTITY_STATES.PENDING, i18n.t('generic.stateMap.pending')],
        [ORDER_NEXTGEN_ENTITY_STATES.DONE, i18n.t('generic.stateMap.done')],
        [ORDER_NEXTGEN_ENTITY_STATES.PAYMENT_FAILED, i18n.t('generic.stateMap.paymentFailed')],
        [ORDER_NEXTGEN_ENTITY_STATES.IN_PROGRESS, i18n.t('generic.stateMap.inProgress')],
        [ORDER_NEXTGEN_ENTITY_STATES.ON_HOLD, i18n.t('generic.stateMap.onHold')],
        [ORDER_NEXTGEN_ENTITY_STATES.CANCELED, i18n.t('generic.stateMap.canceled')],
    ]);
