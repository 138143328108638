<template>
    <div>
        <div
            :class="[
                'row d-flex align-items-center tree-item text--sm',
                { root: hasRootChildren, opened: !isRootOpened },
            ]"
            @click="selectItem(treeList, depth)"
        >
            <td
                v-for="(column, index) in columnsData"
                :key="index"
                :style="getStyles(column.width)"
                :class="{ 'pl-4': index !== 0 }"
                class="table-cell"
            >
                <template v-if="isRowEmpty(treeList[column.key])">
                    {{ $i18n.t('generic.empty') }}
                </template>
                <template v-else>
                    <template v-if="column.key === keyName">
                        <div
                            :style="{ paddingLeft: paddingLeft }"
                            :class="{ 'empty-root': isRootOpened }"
                        >
                            <template>
                                <AppVerticalLine
                                    v-if="showVerticalLine"
                                    :firstInLine="isRootOpened || (depth === 2 && firstChild)"
                                    :uuid="treeList.executionId"
                                    :height="verticalLineHeight"
                                />

                                <AppHorizontalLine
                                    v-if="depth > 1"
                                    :uuid="treeList.executionId"
                                />
                            </template>
                            <span
                                :alt="label"
                                :title="label"
                                class="label"
                            >
                                {{ label }}
                            </span>
                        </div>
                    </template>
                    <template v-else-if="column.key === 'executionStatus'">
                        <EntityStatusIndicator
                            :status="treeList.executionStatus"
                            :stateMap="EXECUTIONS_HIERARCHY_EXECUTION_STATES_CODES_LABEL_MAP"
                            :stateMapColor="EXECUTIONS_HIERARCHY_EXECUTION_STATES_CODES_TO_COLOR_MAP"
                        />
                    </template>
                    <template v-else
                        ><span :title="treeList[column.key]">
                            {{ treeList[column.key] }}
                        </span>
                    </template>
                </template>
            </td>
        </div>
        <template v-for="(treeItem, index) in filteredChildren">
            <OeExecutionsHierarchyItem
                v-show="isOpenFirst"
                :key="treeItem.executionId"
                :treeList="treeItem"
                :depth="depth + 1"
                :keyName="keyName"
                :firstChild="index === 0"
                :columnsData="columnsData"
            />
        </template>
    </div>
</template>

<script>
// Components
import AppVerticalLine from '@/components/partials/AppVerticalLine.vue';
import EntityStatusIndicator from '@/components/partials/EntityStatusIndicator.vue';
import AppHorizontalLine from '@/components/partials/AppHorizontalLine.vue';
import OeExecutionsHierarchyItem from '@/__new__/features/customerCare/OeExecutionsHierarchyItem.vue';
import {
    EXECUTIONS_HIERARCHY_EXECUTION_STATES_CODES_LABEL_MAP,
    EXECUTIONS_HIERARCHY_EXECUTION_STATES_CODES_TO_COLOR_MAP,
} from '@/__new__/features/orchestrationengine/common/executionStatusIndicatorHelper';

export default {
    name: 'OeExecutionsHierarchyRow',
    components: {
        AppVerticalLine,
        AppHorizontalLine,
        EntityStatusIndicator,
        OeExecutionsHierarchyItem,
    },
    props: {
        keyName: {
            type: String,
            default: 'id',
            required: true,
        },
        treeList: {
            type: Object,
            default: () => undefined,
            required: true,
        },
        columnsData: {
            type: Array,
            default: () => [],
            required: true,
        },
        depth: {
            type: Number,
            default: 1,
        },
        firstChild: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            isOpen: false,

            // Proxy imports
            EXECUTIONS_HIERARCHY_EXECUTION_STATES_CODES_LABEL_MAP,
            EXECUTIONS_HIERARCHY_EXECUTION_STATES_CODES_TO_COLOR_MAP,
        };
    },
    computed: {
        label() {
            return this.treeList[this.keyName];
        },
        filteredChildren() {
            if (this.treeList?.children) {
                return this.treeList.children.filter(child =>
                    [
                        'create_order_external',
                        'execute_order_phase',
                        'validity_add_on_order_fulfilment',
                        'repair_add_on_order_fulfilment',
                    ].includes(child.planId),
                );
            }

            return [];
        },
        paddingLeft() {
            return this.depth > 2 ? `${(this.depth - 2) * 24}px` : '';
        },
        hasRootChildren() {
            return this.depth === 1 && this.filteredChildren.length;
        },
        isRootEmpty() {
            return this.depth === 1 && !this?.treeList?.children?.length;
        },
        isRootOpened() {
            return this.isRootEmpty || !this.isOpenFirst;
        },
        verticalLineHeight() {
            let height;

            if (this.depth > 2) {
                height = '48px';
            } else {
                height = '52px';
            }

            return height;
        },
        showVerticalLine() {
            return this.depth !== 1 && !this.isRootEmpty;
        },
        isOpenFirst() {
            return this.isOpen || this.depth > 1;
        },
    },
    methods: {
        getStyles(width) {
            const currentWidth = width || 100 / this.columnsData.length;
            return `width: ${currentWidth}%`;
        },
        selectItem(entity, depth) {
            if (depth === 1) {
                this.isOpen = !this.isOpen;
            }

            this.$emit('selectedItem', entity, depth);
        },
        isRowEmpty(value) {
            if (value === '' || value === this.$i18n.t('generic.N/A')) {
                return true;
            }

            return false;
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/palette';
@import '~@/assets/scss/icons';
@import '~@/assets/scss/animations';
@import '~@/assets/scss/z-indexes';
@import '~@/assets/scss/base';
@import '~@/assets/scss/typographyV2';

$icon-path: '~@/assets/icons/';

.row {
    height: 48px;
    padding-left: 50px;
    position: relative;

    &.root {
        &::before {
            content: url($icon-path + $arrow-up-gray);
            position: absolute;
            left: 17px;
            top: 50%;
            transform: translateY(-50%) rotate(180deg);
            transition: transform $fast-animation-time;
        }

        &.opened {
            .label {
                padding-left: 24px !important;
            }
            &:before {
                transform: translateY(-50%);
            }
        }
    }

    &.tree-item {
        border-bottom: 1px solid #f6f6f6;
        color: #333333;
        background-color: #ffffff;
        overflow: visible;
        transition-property: background-color;
        transition-duration: 0.25s;

        .table-cell {
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;

            .empty-root {
                svg {
                    transform: none;
                }
            }
        }
    }
}
</style>
