import type { TranslateResult } from 'vue-i18n';
import type { DnoResponseBase, Entity } from '@/http';
import type { Event } from '@/__new__/services/dno/events/models/Event';
import type EventProp from '@/__new__/services/dno/events/models/EventProp';

export interface DocumentTemplate {
    id: string;
    state?: number;
    version?: number;
    updateTime?: TranslateResult;
    name: string | Record<string, string>;
    description?: string | Record<string, string>;
    templateDefinition: string;
}

export interface Document {
    id: string;
    state: number;
    version: number;
    updateTime?: TranslateResult;
    name: string | Record<string, string>;
    description?: string | Record<string, string>;
    payload: object;
    fileName: string;
}

export interface DocumentAsset {
    id: string;
    state?: number;
    version?: number;
    updateTime?: TranslateResult;
    name: string | Record<string, string>;
    description?: string | Record<string, string>;
}

export interface DocumentAssetsResponse<T> extends DnoResponseBase {
    data: {
        assets: Record<string, Entity<T>>;
    };
}

export interface DocumentAssetResponse extends DnoResponseBase {
    data: {
        asset_id?: string;
        signed_url: string;
    };
}

export interface GetDocumentTemplateResponse extends DnoResponseBase {
    data: {
        template_definition: string;
        metadata?: {
            properties?: {
                preview_payload: Record<string, any>;
                request_form?: DocumentRequestDataFormParams;
            };
            created_by: string;
            updated_by: string;
            created_at: number;
            updated_at: number;
            version: number;
        };
    };
}

export enum DOCUMENT_CREATION_MODE {
    API = 'API',
    EVENT = 'EVENT',
}

export enum DOCUMENT_GENERATE_TYPES {
    ACCEPTED = 'Accepted',
    PENDING = 'Pending',
    COMPLETED = 'Completed',
}

export enum DOCUMENT_JOB {
    CALLBACK = 'document_generation_callback',
    TRIGGER = 'document_generation_trigger',
}

export type Extension = 'html' | 'json' | 'pdf';

export type DocumentRequestDataFormParams = {
    mode: DOCUMENT_CREATION_MODE;
    eventId: Event['id'];
    documentId: EventProp['name'];
    callbackUrl: string;
    version?: number;
};

export type DocumentTemplatesUrlData = {
    url: string;
    signed_url: string;
};

export interface DocumentTemplatesResponse extends DnoResponseBase {
    data: {
        status: DOCUMENT_GENERATE_TYPES;
        document_id: string;
        operator_name: string;
        url_data?: DocumentTemplatesUrlData;
    };
}

export type DocumentTemplatesPayload = {
    payload: any;
    fileName: string;
    templateDefinition: string;
};

export interface AddTemplatePayload {
    templateId: string;
    templateDefinition: string;
    previewPayload?: Record<string, any>;
    requestForm?: DocumentRequestDataFormParams;
}

export interface DocumentTemplatesApiResponse<T> extends DnoResponseBase {
    data: {
        template_ids: Record<string, Entity<T>>;
    };
}
