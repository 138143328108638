export type ExternalResourceBe = {
    external_customer_id: string;
    external_order_id: string;
    external_order_action_id: string;
    external_product_instance_id: string;
    external_reservation_id: string;
    external_reserved_dp_nap_node_id: string;
    external_service_id: string;
    external_appointment_id: string;
    resource_expiration: string;
};

export default class ExternalResource {
    expirationDate: number | string;
    orderId: string;
    orderActionId: string;
    reservationId: string;
    reservedDpNapNodeId: string;
    serviceId: string;

    constructor(data: ExternalResource | Record<string, never> = {}) {
        this.expirationDate = Number.isInteger(data?.expirationDate) ? data?.expirationDate : '';
        this.orderId = data?.orderId || '';
        this.orderActionId = data?.orderActionId || '';
        this.reservationId = data?.reservationId || '';
        this.reservedDpNapNodeId = data?.reservedDpNapNodeId || '';
        this.serviceId = data?.serviceId || '';
    }

    static mapResourceFromBE(resource: ExternalResourceBe) {
        return {
            expirationDate: Number(resource?.resource_expiration),
            orderId: resource?.external_order_id,
            orderActionId: resource?.external_order_action_id,
            reservationId: resource?.external_reservation_id,
            reservedDpNapNodeId: resource?.external_reserved_dp_nap_node_id,
            serviceId: resource?.external_service_id,
        };
    }
}
