import http from '@/http/index';

import { REPORT_ENGINE } from '@/__new__/services/dno/reports/models/Report';
import type { BuiltReportRequestParams } from '@/__new__/features/reports/common/reportsStateHelper';
import type { ReportReplaceParams } from '@/__new__/services/dno/reports/common/reportsHelper';
import {
    fetchQrdReports,
    fetchQrdReport,
    generateQrdReport,
    publishQrdReport,
    previewQrdReport,
} from '@/__new__/services/dno/reports/http/qrdReports';
import {
    fetchOrdReports,
    fetchOrdReport,
    generateOrdReport,
    publishOrdReport,
    previewOrdReport,
} from '@/__new__/services/dno/reports/http/ordReports';

export function fetchReports({
    reportEngine,
    ...params
}: BuiltReportRequestParams & { size: number; pageToken?: string }) {
    switch (reportEngine) {
        case REPORT_ENGINE.QRD:
            return fetchQrdReports(params);

        case REPORT_ENGINE.ORD:
            return fetchOrdReports(params);

        default:
            return Promise.reject(new Error(`Unknown type: ${reportEngine}.`));
    }
}

export function fetchReport({ reportEngine, ...params }: BuiltReportRequestParams) {
    switch (reportEngine) {
        case REPORT_ENGINE.QRD:
            return fetchQrdReport(params);

        case REPORT_ENGINE.ORD:
            return fetchOrdReport(params);

        default:
            return Promise.reject(new Error(`Unknown type: ${reportEngine}.`));
    }
}

export function generateReport({ reportEngine, ...params }: BuiltReportRequestParams) {
    switch (reportEngine) {
        case REPORT_ENGINE.QRD:
            return generateQrdReport(params);

        case REPORT_ENGINE.ORD:
            return generateOrdReport(params);

        default:
            return Promise.reject(new Error(`Unknown type: ${reportEngine}.`));
    }
}

export function publishReport({ reportEngine, ...params }: BuiltReportRequestParams) {
    switch (reportEngine) {
        case REPORT_ENGINE.QRD:
            return publishQrdReport(params);

        case REPORT_ENGINE.ORD:
            return publishOrdReport(params);

        default:
            return Promise.reject(new Error(`Unknown type: ${reportEngine}.`));
    }
}

export function previewReport({ reportEngine, ...params }: BuiltReportRequestParams) {
    switch (reportEngine) {
        case REPORT_ENGINE.QRD:
            return previewQrdReport(params);

        case REPORT_ENGINE.ORD:
            return previewOrdReport(params);

        default:
            return Promise.reject(new Error(`Unknown type: ${reportEngine}.`));
    }
}

export function replaceReport({ definitionId, fileEntryId, uploadId }: ReportReplaceParams) {
    return http({
        method: 'POST',
        url: '/v3reports/replace',
        headers: { 'Content-type': 'application/json' },
        params: { report_definition_id: definitionId, file_entry_id: fileEntryId },
        data: { upload_id: uploadId },
    });
}

export function replaceReportSignedUrl({ definitionId, fileEntryId }: Omit<ReportReplaceParams, 'uploadId'>) {
    return http({
        method: 'POST',
        url: '/v3reports/signedurl',
        headers: { 'Content-type': 'application/json' },
        params: { report_definition_id: definitionId, file_entry_id: fileEntryId },
    }).then(
        ({
            data: {
                data: { signed_url: signedUrl, upload_id: uploadId },
            },
        }) => ({ signedUrl, uploadId }),
    );
}

export default {
    fetchReports,
    fetchReport,
    generateReport,
    publishReport,
    previewReport,
    replaceReport,
    replaceReportSignedUrl,
};
