/* eslint-disable camelcase */
import httpClient from './index';
import { uuidV4 } from '@/common/utils';
import { WALLET_TYPES } from '@/__new__/features/customerCare/common/ordersHelper';
import { USER_MANAGER_HIERARCHY_API_KEY } from '@/__new__/features/customerCare/common/customerCareHelper';

/**
 * @param transactionId
 * @param subscriberId
 * @return {AxiosPromise}
 */
function cancelTopUp(transactionId, subscriberId) {
    const options = {
        method: 'POST',
        url: '/v3topupwrite/canceltopup',
        headers: {
            'Content-type': 'application/json',
            'Dno-Claims': JSON.stringify({
                subscriber_id: subscriberId,
            }),
        },
        data: {
            transaction_id: transactionId,
        },
    };
    return httpClient(options);
}

/**
 * @param amount
 * @param id
 * @param userManagerHierarchy
 * @param voucher_code
 * @param wallet_type
 * @return {AxiosPromise}
 */
function topUpAmount(amount, id, userManagerHierarchy, voucher_code, wallet_type = WALLET_TYPES.PRIMARY) {
    const options = {
        method: 'POST',
        url: '/v3topupwrite/topupamount',
        headers: {
            'Content-type': 'application/json',
            'Dno-Claims': JSON.stringify({
                [USER_MANAGER_HIERARCHY_API_KEY[userManagerHierarchy]]: id,
            }),
        },
        data: {
            amount,
            external_transaction_id: uuidV4(),
            payment_info: {
                voucher_code,
            },
            remark: 'PORTAL',
            wallet_type,
            target_type: userManagerHierarchy,
            target_id: id,
        },
    };
    return httpClient(options);
}

/**
 * @param amount
 * @param id
 * @param userManagerHierarchy
 * @param voucher_code
 * @param wallet_type
 * @return {AxiosPromise}
 */
function topUpAmountSharedWallet(amount, id, userManagerHierarchy, voucher_code, wallet_type = walletTypes.PRIMARY) {
    const options = {
        method: 'POST',
        url: '/v3topupwrite/topupamountsharedwallet',
        headers: {
            'Content-type': 'application/json',
            'Dno-Claims': JSON.stringify({
                [USER_MANAGER_HIERARCHY_API_KEY[userManagerHierarchy]]: id,
            }),
        },
        data: {
            amount,
            external_transaction_id: uuidV4(),
            payment_info: {
                voucher_code,
            },
            remark: 'PORTAL',
            wallet_type,
            target_type: userManagerHierarchy,
            target_id: id,
        },
    };
    return httpClient(options);
}

/**
 * @param amount
 * @param id
 * @param userManagerHierarchy
 * @param wallet_type
 * @return {AxiosPromise}
 */
function deductAmount(amount, id, userManagerHierarchy, wallet_type = walletTypes.PRIMARY) {
    const options = {
        method: 'POST',
        url: '/v3topupwrite/deductbalance',
        headers: {
            'Content-type': 'application/json',
            'Dno-Claims': JSON.stringify({
                [USER_MANAGER_HIERARCHY_API_KEY[userManagerHierarchy]]: id,
            }),
        },
        data: {
            amount,
            external_transaction_id: uuidV4(),
            remark: 'PORTAL',
            wallet_type,
            target_id: userManagerHierarchy,
            target_type: id,
        },
    };
    return httpClient(options);
}

/**
 * @param amount
 * @param id
 * @param userManagerHierarchy
 * @param wallet_type
 * @return {AxiosPromise}
 */
function deductBalanceSharedWallet(amount, id, userManagerHierarchy, wallet_type = walletTypes.PRIMARY) {
    const options = {
        method: 'POST',
        url: '/v3topupwrite/deductbalancesharedwallet',
        headers: {
            'Content-type': 'application/json',
            'Dno-Claims': JSON.stringify({
                [USER_MANAGER_HIERARCHY_API_KEY[userManagerHierarchy]]: id,
            }),
        },
        data: {
            amount,
            external_transaction_id: uuidV4(),
            remark: 'PORTAL',
            wallet_type,
            target_id: userManagerHierarchy,
            target_type: id,
        },
    };
    return httpClient(options);
}

export default {
    cancelTopUp,
    topUpAmount,
    topUpAmountSharedWallet,
    deductAmount,
    deductBalanceSharedWallet,
};
