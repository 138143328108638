import { render, staticRenderFns } from "./BalanceInfoCard.vue?vue&type=template&id=17886b08&scoped=true"
import script from "./BalanceInfoCard.vue?vue&type=script&lang=js"
export * from "./BalanceInfoCard.vue?vue&type=script&lang=js"
import style0 from "./BalanceInfoCard.vue?vue&type=style&index=0&id=17886b08&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "17886b08",
  null
  
)

export default component.exports