import { AxiosPromise } from 'axios';
import http from '@/http/index';

import { getDnoClaimsHeader } from '@/__new__/features/customerCare/common/customerCareHelper';
import { TARGET_TYPE } from '@/__new__/services/dno/user/models/targetTuple';
import { GetConsentsResponse } from '@/__new__/features/customerCare/common/consentsHelper';

export function getConsents(targetId: string, targetType: TARGET_TYPE): AxiosPromise<GetConsentsResponse> {
    return http({
        method: 'POST',
        // TODO: update to v3consent instead once old UM deprecated
        url: '/v3usermanager/getconsent',
        headers: {
            'Dno-Claims': JSON.stringify(getDnoClaimsHeader(targetId, targetType)),
        },
        data: {
            target_id: targetId,
            target_type: targetType,
        },
    });
}

export default {
    getConsents,
};
