import Router from 'vue-router';
import routes from './routes';
import eventBus from '@/eventBus';
import i18n from '@/i18n';
import { ALERT_TYPES } from '@/common/alerts/Alert';
import Account, { userState } from '@/models/Account';
import RouteNames from '@/router/routeNames';
import RouteWrapper from '@/components/layout/RouteWrapper.vue';
import Tenant from '@/models/Tenant';

const router = new Router({
    scrollBehavior() {
        return {
            x: 0,
            y: 0,
        };
    },
    routes: [
        {
            path: '/:companyId',
            component: RouteWrapper,
            children: routes,
        },
    ],
});

const originalPush = Router.prototype.push;

/* Overriding push action */
Router.prototype.push = function push(location, onResolve, onReject) {
    if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject);
    return originalPush.call(this, location).catch(err => {
        if (Router.isNavigationFailure(err)) {
            // resolve err
            return err;
        }
        // rethrow error
        return Promise.reject(err);
    });
};

router.beforeEach(async (to, from, next) => {
    if (!Account.authenticated()) {
        if (to.path === '/') {
            next();
            return;
        }

        // TODO: Change to redirect to a 404 page.
        next('/');
        return;
    }

    // If we're authenticated and we're directly hitting the root path, there's
    // a possibility that we will not yet know the user's default company (which
    // is loaded within App.vue in the created() lifecycle hook).
    //
    // The path will be updated to "/<company_id>" once the account data has
    // finished loading within the created() hook and we know the default company.
    //
    if (to.path === '/') {
        next();
        return;
    }

    // TODO: Change to redirect to a 404 page.
    if (router.resolve(to.path).resolved.matched.length === 0) {
        next(`/${Account.storedInstance.companyId}`);
        return;
    }

    // TODO: Change to redirect to a 404 page.
    if (to.params.companyId && isNaN(to.params.companyId)) {
        next(`/${Account.storedInstance.companyId}`);
        return;
    }

    next();
});

router.beforeResolve((to, from, next) => {
    const authenticated = Account.authenticated();

    if (!authenticated && to.name !== RouteNames.LOGIN) {
        next({ name: RouteNames.LOGIN });
    } else if (authenticated && to.name === RouteNames.LOGIN) {
        next({ name: RouteNames.HOME_VIEW });
    } else if (authenticated && Account.getUserState() === userState.PENDING && to.name !== RouteNames.ACCOUNT_VIEW) {
        next({ name: RouteNames.ACCOUNT_VIEW });
    } else if (authenticated) {
        const { permissionFunc } = to.meta;

        if (!permissionFunc || (permissionFunc && permissionFunc())) {
            next();
        } else {
            eventBus.$emit('showAlert', {
                message: i18n.t('generic.youDontHavePermissions'),
                type: ALERT_TYPES.warning,
            });
        }

        next();
    } else {
        next();
    }
});

export default router;
