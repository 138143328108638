import Vue from 'vue';
import customerCareHTTP from '@/__new__/services/dno/user/http/customer-care';
import { USER_MANAGER_HIERARCHY } from '@/__new__/features/customerCare/common/customerCareHelper';
import userManagementHTTP from '@/__new__/services/dno/user/http/user-management';

export default {
    namespaced: true,
    state: {
        organizationInfo: {},
        accessList: {},
        // TODO: rethink, store this info here?
        accountInfos: [],
    },
    mutations: {
        setOrganizationInfo: (state, { organizationInfo, organizationId }) => {
            Vue.set(state.organizationInfo, organizationId, organizationInfo);
        },
        setAccessList: (state, { accessList, organizationId }) => {
            Vue.set(state.accessList, organizationId, accessList);
        },
        setAccountInfo: (state, { accountInfo }) => {
            state.accountInfos = accountInfo;
        },
    },
    actions: {
        async getOrganizationInfo({ commit }, organizationId) {
            const organizationInfoResponse = await customerCareHTTP.getOrganization(
                organizationId,
                USER_MANAGER_HIERARCHY.ORGANIZATION,
            );

            commit('setOrganizationInfo', {
                organizationInfo: organizationInfoResponse.data.organization_info,
                organizationId,
            });

            commit('setAccountInfo', {
                accountInfo: organizationInfoResponse.data.organization_info.elements,
            });
        },
        async getAccessList({ commit }, organizationId) {
            const response = await userManagementHTTP.getAccessList(
                organizationId,
                USER_MANAGER_HIERARCHY.ORGANIZATION,
            );

            commit('setAccessList', {
                accessList: response.data.access_list,
                organizationId,
            });
        },
    },
    getters: {
        getOrganizationInfoById: state => organizationId => state.organizationInfo[organizationId] || {},
        getAccessListById: state => organizationId => state.accessList[organizationId] || [],
        getAccountInfos: state => () => state.accountInfos || [],
    },
};
