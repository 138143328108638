<template>
    <AbstractEditPageWrapper>
        <template #header>
            <AppHeader :pageTitle="pageTitle" />
        </template>

        <template #content>
            <!-- General section -->
            <div class="editor-section">
                <div class="d-flex lf-subtitle">
                    <div>
                        {{ $i18n.t('generic.general') }}
                    </div>
                    <LanguageSwitcher
                        v-model="selectedLanguage"
                        :tooltipEnabled="true"
                    />
                    <AppButton
                        v-if="!isEditing"
                        :buttonType="BUTTON_TYPES.SECONDARY"
                        :label="$i18n.t('generic.advanced')"
                        class="ml-auto"
                        data-test-id="advanced-btn"
                        @click="openAdvanced"
                    />
                </div>
                <AppDialogV2
                    :visible="advanced"
                    :title="$i18n.t('generic.advanced')"
                    :disableDefaultSaveBtn="true"
                    @close="closeAdvanced"
                >
                    <AppInputV3
                        v-if="!isEditing"
                        id="template-name-uuid"
                        v-model="uuid"
                        :invalid="!validateUUID()"
                        :placeholder="$i18n.t('generic.uuid')"
                        :label="$i18n.t('generic.uuid')"
                        :explanationText="$i18n.t('generic.uuidtooltip')"
                        :tooltipOffset="0"
                        class="editor-input-largest pb-3"
                        data-test-id="uuid-input"
                    />
                    <template #modalFooter>
                        <AppButton
                            :buttonType="BUTTON_TYPES.PRIMARY"
                            :label="$i18n.t('generic.apply')"
                            :disabled="!validateUUID()"
                            @click="applyAdvanced()"
                        />
                    </template>
                </AppDialogV2>

                <p
                    v-show="uuid"
                    class="lf-primary-text pb-2"
                >
                    {{ $i18n.t('generic.id') }}: {{ uuid }}
                </p>

                <AppInputV3
                    v-model="data.name[selectedLanguage]"
                    :placeholder="$i18n.t('productCatalog.facets.addFacetName')"
                    :label="$i18n.t('generic.name')"
                    :disabled="readonly"
                    :invalid="requiredErrors.showNameFieldRequiredError"
                    class="col-xl-7 col-lg-7 col-md-7 col-12 section-content"
                    :errorMessage="$i18n.t('generic.validations.fieldIsRequired')"
                    data-test-id="name-input"
                />
            </div>
            <!-- Facet Parameters section -->
            <div class="editor-section">
                <div
                    v-t="'productCatalog.facets.facetParameters'"
                    class="editor-section-heading"
                />
                <AppInputV3
                    v-model.number="data.sort_priority"
                    :invalid="$v.data.sort_priority.$error"
                    :label="$i18n.t('productCatalog.categories.sortPriority')"
                    :min="0"
                    :step="1"
                    type="number"
                    class="editor-input-large mb-3"
                    :optional="true"
                    :disabled="readonly"
                    data-test-id="priority-input"
                />

                <AppMultiselectV3
                    v-model="data.selected_template"
                    :multiple="false"
                    :options="presetTemplatesOptions"
                    :showLabels="false"
                    :placeholder="templateLabel"
                    :additionalLabel="templateLabel"
                    :disabled="readonly"
                    label="name"
                    class="editor-input-largest mb-3"
                    data-test-id="selected-template"
                    trackBy="id"
                    optionId="id"
                />
            </div>
            <!-- Facet value parameter section -->
            <div class="editor-section">
                <div
                    v-t="'productCatalog.facets.facetValueParameter'"
                    class="editor-section-heading pb-3"
                />
                <div v-if="data.selected_template">
                    <AppMultiselectV3
                        v-model="data.selected_group"
                        :small="true"
                        :options="selectedGroupOptions"
                        :additionalLabel="$i18n.t('generic.group')"
                        :placeholder="$i18n.t('productCatalog.templates.chooseGroup')"
                        :disabled="readonly"
                        data-test-id="selected-group"
                        label="name"
                        trackBy="id"
                        class="editor-input-largest mb-3"
                    />
                </div>

                <div v-if="data.selected_group">
                    <AppMultiselectV3
                        v-model="data.selected_property"
                        :small="true"
                        :options="selectedPropertyOptions"
                        :additionalLabel="$i18n.t('productCatalog.facets.propertyName')"
                        :placeholder="$i18n.t('productCatalog.templates.chooseProperty')"
                        :disabled="readonly"
                        data-test-id="selected-parameter"
                        label="name"
                        trackBy="id"
                        class="editor-input-largest mb-3"
                    />
                </div>

                <div v-if="data.selected_property">
                    <div
                        v-t="'generic.externalId'"
                        class="editor-section-heading"
                    />
                    <div class="pb-3">
                        <span>{{ data.selected_property.id }}</span>
                    </div>
                    <ValueInputs
                        v-model="data.enumValues"
                        :initInputValues="templateEnumValues"
                        :label="$i18n.tc('productCatalog.entities.enums', PLURALIZATION.PLURAL)"
                        :addButtonLabel="$i18n.t('productCatalog.enums.addEnum')"
                        :placeholder="$i18n.t('productCatalog.enums.addEnum')"
                        :disabled="readonly"
                    />
                </div>
            </div>
        </template>

        <!-- Buttons section -->
        <template #controls>
            <EditorButtons
                :showSave="true"
                :disableSave="readonly"
                @cancel="onCancel"
                @save="onSave"
            />
        </template>
    </AbstractEditPageWrapper>
</template>

<script>
// components
import AbstractEditPageWrapper from '@/components/layout/AbstractEditPageWrapper.vue';
import AppMultiselectV3 from '@/components/partials/inputs/AppMultiselectV3.vue';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import AppHeader from '@/components/layout/AppHeader.vue';
import AppInputV3 from '@/components/partials/inputs/AppInputV3.vue';
import { ALERT_TYPES } from '@/common/alerts/Alert';
import LanguageSwitcher from '@/components/partials/inputs/LanguageSwitcher.vue';
import ValueInputs from '@/components/partials/inputs/ValueInputs.vue';
import AppDialogV2 from '@/components/partials/AppDialogV2.vue';
import EditorButtons from '@/components/layout/EditorButtons.vue';

// mixins
import entityEditorMixin from '@/common/entityEditorMixin';
import mutationDialogMixin from '@/components/partials/mutations/mutationDialogMixin.vue';
import { validationMixin } from 'vuelidate';

// helpers
import { languageMap } from '@/common/locale/language';
import * as Sentry from '@sentry/vue';
import { required } from 'vuelidate/lib/validators';
import ENTITY_TYPES from '@/common/entities/entityTypes';
import RouteNames from '@/router/routeNames';
import { PLURALIZATION } from '@/common/locale/labelSingularOrPlural';
import { TEMPLATE_TYPES_MAP } from '@/__new__/services/dno/pc/models/templateTypes';
import { FORM_BUILDER_TYPES } from '@/formBuilderConfig';
import { ICON_TYPES } from '@/common/iconHelper';

// API
import { addFacet, updateFacet } from '@/__new__/services/dno/pc/http/facets';

import { mapGetters, mapActions } from 'vuex';
import Actions, { Getters } from '@/store/mutation-types';
import { isValidUuid } from '@/common/uuidHelper';
import { EDITOR_MODE } from '@/common/entities/entityHelper';

export default {
    name: 'FacetEditor',
    components: {
        AppHeader,
        AppInputV3,
        AppButton,
        AppMultiselectV3,
        LanguageSwitcher,
        AbstractEditPageWrapper,
        ValueInputs,
        AppDialogV2,
        EditorButtons,
    },
    mixins: [validationMixin, entityEditorMixin, mutationDialogMixin],
    data() {
        return {
            data: {
                name: {},
                selected_template: null,
                selected_group: null,
                selected_property: null,
                sort_priority: 1,
                enumValues: null,
            },
            version: null,
            unapprovedError: this.$i18n.t('alertMessage.unapprovedEntity'),
            entityType: ENTITY_TYPES.FACET,
            isSaveButtonClicked: false,
            shouldInvalidateValues: false,
            requiredErrors: {
                showNameFieldRequiredError: false,
            },
            selectedLanguage: languageMap.en.key,
            advanced: false,
            uuid: '',
            readonly: false,
            ICON_TYPES,
            BUTTON_TYPES,
            PLURALIZATION,
        };
    },
    validations: {
        data: {
            name: {
                required,
            },
            sort_priority: {
                required,
            },
        },
    },
    computed: {
        ...mapGetters('productcatalog', {
            getTemplateById: Getters.PC_GET_TEMPLATE_BY_ID,
            getApprovedTemplatesByType: Getters.PC_GET_TEMPLATES_BY_TYPE_APPROVED,
            allTemplates: Getters.PC_GET_ALL_TEMPLATES_NOT_DELETED,
            getEntity: Getters.PC_GET_ENTITY_BY_TYPE_AND_ID,
        }),
        presetTemplatesOptions() {
            const templates = this.getApprovedTemplatesByType(TEMPLATE_TYPES_MAP.PRESET);
            const tempWithProps = templates.filter(x =>
                x.properties.some(
                    y =>
                        y.dataType === FORM_BUILDER_TYPES.DROPDOWN_MULTIPLE ||
                        y.dataType === FORM_BUILDER_TYPES.DROPDOWN,
                ),
            );

            return tempWithProps;
        },
        selectedGroupOptions() {
            return this.selectedTemplate?.groups;
        },
        selectedPropertyOptions() {
            const groupEnumProperties = this.selectedTemplate.properties?.filter(
                x =>
                    x.groupId === this.data.selected_group.id &&
                    (x.dataType === FORM_BUILDER_TYPES.DROPDOWN_MULTIPLE || x.dataType === FORM_BUILDER_TYPES.DROPDOWN),
            );

            return groupEnumProperties;
        },
        templateEnumValues() {
            if (this.isEditing && !this.shouldInvalidateValues) {
                const currentlyEditedFacet = this.getEntity(ENTITY_TYPES.FACET, this.$route.params.id);
                return currentlyEditedFacet.properties.schema.enum;
            }

            const enumValues = this.data.selected_property?.schema?.enum;
            return enumValues;
        },
        activeTemplate() {
            return this.data.selected_template;
        },
        activeProperty() {
            return this.data.selected_property;
        },
        selectedTemplate() {
            return this.getApprovedTemplatesByType(TEMPLATE_TYPES_MAP.PRESET).find(
                x => x.id === this.data.selected_template,
            );
        },
        templateLabel() {
            return this.$i18n.tc('productCatalog.entities.template', PLURALIZATION.SINGULAR);
        },
        isEditing() {
            return !!this.$route.params.id;
        },
        isCloning() {
            return !!this.$route.params.clone;
        },
    },
    watch: {
        activeProperty(newProp) {
            if (this.isEditing) {
                const currentlyEditedFacet = this.getEntity(ENTITY_TYPES.FACET, this.$route.params.id);
                if (newProp.id !== currentlyEditedFacet.properties.reference_external_id) {
                    this.shouldInvalidateValues = true;
                }
            }
        },
    },
    mounted() {
        this.$withLoadingSpinner(async () => {
            await this.fetchData();

            this.setData();

            this.addWatcher('$data');
        });
    },
    methods: {
        ...mapActions('productCatalog', {
            requestTemplatesByType: Actions.PC_REQUEST_TEMPLATES_BY_TYPE_AND_IDS,
        }),
        ...mapActions('productcatalog', {
            requestTemplatesByType: Actions.PC_REQUEST_TEMPLATES_BY_TYPE_AND_IDS,
            requestAllTemplates: Actions.PC_REQUEST_ALL_TEMPLATES,
            requestEntitiesByType: Actions.PC_REQUEST_ENTITIES_BY_TYPE_AND_IDS,
        }),
        async fetchData() {
            try {
                this.$Progress.start();
                await Promise.all([
                    this.requestTemplatesByType({ entityType: 'preset' }),
                    this.requestEntitiesByType({ entityType: ENTITY_TYPES.FACET }),
                ]);
                this.$Progress.finish();
            } catch (error) {
                this.$Progress.fail();
                Sentry.captureException(error);
                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('alertMessage.errorDoingSmthTryAgain', {
                        action: 'fetching',
                        entityName: 'template',
                    }),
                });
            }
        },
        async onSave() {
            // prevent sending requests until the first one is resolved
            if (this.isSaveButtonClicked) {
                return;
            }

            if (!this.checkDataBeforeSave()) {
                return;
            }

            this.isSaveButtonClicked = true;
            this.$v.$touch();

            if (this.$v.$invalid || !this.validateUUID()) {
                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('alertMessage.pleaseFixValidation'),
                });
                this.isSaveButtonClicked = false;
                this.addAlertWatcher('$data');
            } else {
                try {
                    this.$Progress.start();

                    if (this.isEditing && !this.isCloning) {
                        const updateFacetData = {
                            name: this.data.name[this.selectedLanguage],
                            sort_priority: this.data.sort_priority,
                            reference_preset_template_id: this.data.selected_template,
                            reference_external_id: this.data.selected_property.id,
                            schema: {
                                enum: this.data.enumValues,
                            },
                        };

                        await updateFacet(this.$route.params.id, updateFacetData, this.version);

                        this.$eventBus.$emit('showAlert', {
                            message: this.successSaveMessage,
                            type: ALERT_TYPES.success,
                        });
                    } else {
                        const addFacetData = {
                            name: this.data.name[this.selectedLanguage],
                            sort_priority: this.data.sort_priority,
                            reference_preset_template_id: this.data.selected_template,
                            reference_external_id: this.data.selected_property.id,
                            schema: {
                                enum: this.data.enumValues,
                            },
                        };

                        await addFacet({
                            data: addFacetData,
                            ...(this.uuid && { entity_id: this.uuid }),
                        });
                        this.$eventBus.$emit('showAlert', {
                            message: this.successSaveMessage,
                            type: ALERT_TYPES.success,
                        });
                    }
                    this.$Progress.finish();
                    this.entityEditorMixin.successfullySaved = true;
                    setTimeout(
                        () =>
                            this.$router.push({
                                name: RouteNames.FACET_LIST,
                                params: {
                                    companyId: this.$route.params.companyId,
                                },
                            }),
                        1000,
                    );
                } catch (e) {
                    this.$eventBus.$emit('showAlert', {
                        message: this.$i18n.t('alertMessage.errorDoingSmthTryAgain', {
                            action: 'saving',
                            entityName: 'facet',
                        }),
                    });
                    Sentry.captureException(e);
                    this.$Progress.fail();
                    this.isSaveButtonClicked = false;
                }
            }
        },
        setData() {
            if (this.isEditing) {
                const currentlyEditedFacet = this.getEntity(ENTITY_TYPES.FACET, this.$route.params.id);

                const approvedTemplates = this.getApprovedTemplatesByType(TEMPLATE_TYPES_MAP.PRESET);

                const editSelectedTemplate = approvedTemplates.find(
                    x => x.id === currentlyEditedFacet.properties.reference_preset_template_id,
                );

                const editSelectedProp = editSelectedTemplate.properties.find(
                    x => x.id === currentlyEditedFacet.properties.reference_external_id,
                );

                const editSelectedGroup = editSelectedTemplate.groups.find(x => x.id === editSelectedProp.groupId);
                this.version = currentlyEditedFacet.version;

                this.data = {
                    name: {
                        [this.selectedLanguage]: currentlyEditedFacet.name,
                    },
                    selected_template: editSelectedTemplate.id,
                    selected_group: editSelectedGroup,
                    selected_property: editSelectedProp,
                    sort_priority: currentlyEditedFacet.properties.sort_priority,
                    enumValues: currentlyEditedFacet.properties.schema.enum,
                };
            }

            if (this.isCloning) {
                this.data.name[this.selectedLanguage] += ` (${this.$i18n.t('generic.cloned')})`;
            }

            if (this.$route.params.id && this.$route.params.mode) {
                this.readonly = this.$route.params.mode === EDITOR_MODE.VIEW;
            }
        },
        checkDataBeforeSave() {
            if (!this.data.name[this.selectedLanguage]) {
                this.requiredErrors.showNameFieldRequiredError = true;
                return false;
            }
            return true;
        },
        validateUUID() {
            if (this.uuid) {
                return isValidUuid(this.uuid);
            }
            return true;
        },
        openAdvanced() {
            this.advanced = true;
        },
        closeAdvanced() {
            this.uuid = '';
            this.advanced = false;
        },
        applyAdvanced() {
            this.advanced = false;
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/base';
@import '~@/assets/scss/forms';
@import '~@/assets/scss/palette';
@import '~@/assets/scss/inputs';
@import '~@/assets/scss/animations';
@import '~@/assets/scss/editor-layout-v2';
@import '~@/assets/scss/typographyV2';
@import '~@/assets/scss/z-indexes';

.section-content {
    font-size: 0.75rem;
    font-weight: 600;
    margin-bottom: 1rem;
    margin-right: 1rem;
    color: $gray90;
}

.modal-content-wrapper {
    margin: $spacing-l;

    height: 100%;

    display: flex;
    flex-direction: column;
}

.content-controls {
    position: sticky;
    z-index: $overlap-smth-z-index;
    bottom: 0;
    left: 0;
    width: 100%;
    margin-top: 2rem;
    display: flex;
    justify-content: flex-end;
    height: 5.56rem;
    padding: 1.5rem 2.5rem;
    border-top: 1px solid $blue15;
    background-color: $white;
}
</style>
