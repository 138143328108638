/**
 * Purpose of this file is to:
 *  - have consistent color definition between scss and JS;
 *  - reduce the amount of duplication for color maps;
 */

import colors, { Color, ColorWeight, GenericColor, GenericColors } from '@/assets/scss/_consistency-colors.scss';

type ColorPalette = Readonly<
    GenericColors &
        Record<
            Color,
            {
                [T in ColorWeight]?: string;
            }
        >
>;

const COLOR_PALETTE = Object.entries(colors).reduce((palette, [colorName, value]: [any, string]) => {
    const [colorFamily, colorWeight]: [GenericColor] | [Color, ColorWeight] = colorName.split('-');

    if (colorWeight) {
        if (palette[colorFamily]) {
            Object.defineProperty(palette[colorFamily], colorWeight, {
                value,
            });
        } else {
            Object.defineProperty(palette, colorFamily, {
                value: {
                    [colorWeight]: value,
                },
            });
        }
    } else {
        Object.defineProperty(palette, colorFamily, { value });
    }

    return palette;
}, {} as ColorPalette);

export default COLOR_PALETTE;
