import { STATUS_CODES, STATUS_INDICATOR_MAP } from '@/common/commonHelper';
import { LABEL_COLOR } from '@/common/labelsHelper';

export const PAYOUT_TYPE_CODES = {
    NONE: 0,
    TOP_UP: 1,
    BENEFIT: 2,
    VOUCHER: 3,
    OFFER: 4,
    TICKET: 5,
};

export const WALLET_TYPES = {
    PRIMARY: 'primary',
    SPINS: 'spins',
    COINS: 'coins',
    EXTERNAL: 'external',
};

export const PAYOUT_TARGET_CODES = {
    USER: 0,
    REFERRER: 1,
};

export const payoutStateMap = new Map([
    [STATUS_CODES.UNAPPROVED, 'generic.stateMap.pendingApproval'],
    [STATUS_CODES.APPROVED, 'generic.stateMap.approved'],
    [STATUS_CODES.PAUSED, 'generic.stateMap.paused'],
    [STATUS_CODES.DELETED, 'generic.stateMap.deleted'],
]);

export const payoutTypeMap = new Map([
    [PAYOUT_TYPE_CODES.NONE, 'generic.payoutTypeMap.none'],
    [PAYOUT_TYPE_CODES.TOP_UP, 'generic.payoutTypeMap.topUp'],
    [PAYOUT_TYPE_CODES.BENEFIT, 'generic.payoutTypeMap.benefit'],
    [PAYOUT_TYPE_CODES.VOUCHER, 'generic.payoutTypeMap.voucher'],
    [PAYOUT_TYPE_CODES.OFFER, 'generic.payoutTypeMap.offer'],
    [PAYOUT_TYPE_CODES.TICKET, 'generic.payoutTypeMap.ticket'],
]);

export const walletTypeMap = new Map([
    [WALLET_TYPES.PRIMARY, 'generic.walletTypeMap.primary'],
    [WALLET_TYPES.SPINS, 'generic.walletTypeMap.spins'],
    [WALLET_TYPES.COINS, 'generic.walletTypeMap.coins'],
    [WALLET_TYPES.EXTERNAL, 'generic.walletTypeMap.external'],
]);

export const payoutTargetMap = {
    [PAYOUT_TARGET_CODES.USER]: 'generic.user',
    [PAYOUT_TARGET_CODES.REFERRER]: 'generic.referrer',
};

export const getPayoutStatus = state => (payoutStateMap.has(state) ? payoutStateMap.get(state) : 'generic.N/A');

export const getPayoutStatusColor = state => STATUS_INDICATOR_MAP.get(state);

export const getPayoutType = type => (payoutTypeMap.has(type) ? payoutTypeMap.get(type) : 'generic.N/A');

export const PAYOUT_PROVIDED_STATES = {
    SUCCESSFUL: 1,
    FAILED: 2,
};

export const payoutProvidedStateMap = new Map([
    [PAYOUT_PROVIDED_STATES.SUCCESSFUL, 'generic.stateMap.success'],
    [PAYOUT_PROVIDED_STATES.FAILED, 'generic.stateMap.failed'],
]);
export const payoutProvidedStateColorMap = new Map([
    [PAYOUT_PROVIDED_STATES.SUCCESSFUL, LABEL_COLOR.green],
    [PAYOUT_PROVIDED_STATES.FAILED, LABEL_COLOR.red],
]);
