<template>
    <ListPageLayout
        :pageTitle="pageTitle"
        :addButtonLabel="addButtonLabel"
        :editRouteName="editRouteName"
        :entityType="entityType"
        :entities="entities"
        :entitiesApiResponse="allTemplatesApiResponse"
        :entityKeyDepth="2"
        :listViewColumns="listViewColumns"
        :overviewContentBuilder="overviewContentBuilder"
        :searchKeys="['id', 'name', 'description']"
        writePermission="TemplatesWrite"
        @delete="onDelete"
        @refetchData="fetchTemplates()"
    >
        <template #statusIndicator="{ entity }">
            <PCTemplatesStatusIndicator :status="entity.state" />
        </template>
    </ListPageLayout>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Actions, { Getters } from '@/store/mutation-types';

// components
import ListPageLayout from '@/__new__/features/pc/ListPageLayout.vue';
import PCTemplatesStatusIndicator from './PCTemplatesStatusIndicator.vue';
import ListPageMixin from '@/__new__/features/pc/ListPageMixin.vue';
import FilterTableMixin from '@/components/partials/FilterTableMixin.vue';
import { ALERT_TYPES } from '@/common/alerts/Alert';

// helpers
import ENTITY_TYPES from '@/common/entities/entityTypes';
import RouteNames from '@/router/routeNames';
import tableColumnType from '@/common/filterTable';
import * as Sentry from '@sentry/vue';
import { ENTITIES_TYPES_MAP } from '@/__new__/features/pc/common/entitiesTypes';
import { TEMPLATE_STATES_NUMERIC } from '@/__new__/services/dno/pc/models/templateStates';
import { PLURALIZATION } from '@/common/locale/labelSingularOrPlural';
import { TEMPLATE_TYPES_MAP } from '@/__new__/services/dno/pc/models/templateTypes';
import { overviewContentBuilder } from './common/templateHelper';
import { EntityActions } from '@/common/baseStatesHelper';

// http
import { updateTemplateState } from '@/__new__/services/dno/pc/http/templates';

export default {
    name: 'TemplatesListPage',
    components: {
        ListPageLayout,
        PCTemplatesStatusIndicator,
    },
    mixins: [FilterTableMixin, ListPageMixin],
    data() {
        return {
            entityType: ENTITY_TYPES.TEMPLATE,

            pageTitle: this.$i18n.tc('productCatalog.entities.template', PLURALIZATION.PLURAL),
            addButtonLabel: this.$i18n.t('productCatalog.templates.newTemplate'),

            editRouteName: RouteNames.TEMPLATE_EDITOR,
            overviewContentBuilder,
        };
    },
    computed: {
        ...mapGetters('productcatalog', {
            allNonPresetTemplates: Getters.PC_GET_NOT_PRESET_TEMPLATES,
            allPresetTemplates: Getters.PC_GET_ALL_TEMPLATES_NOT_DELETED,
            allTemplatesApiResponse: Getters.PC_GET_ALL_TEMPLATES_API_RESPONSE,
        }),
        entities() {
            return Object.values(this.allNonPresetTemplates).map(template => ({
                ...template,
                presetTemplates: template.presetTemplates.map(pt => {
                    const presetRef = this.allPresetTemplates.find(item => item.id === pt.id);

                    return {
                        ...presetRef,
                        name: presetRef?.name,
                        externalId: presetRef?.name,
                    };
                }),
                entityTypeName: this.$i18n.tc(ENTITIES_TYPES_MAP[template.entityType]),
                usesPresetTemplates: template.presetTemplates.length
                    ? this.$i18n.t('generic.yes')
                    : this.$i18n.t('generic.no'),
                allowedActionsExternal: [
                    ...(template.state === TEMPLATE_STATES_NUMERIC.UNAPPROVED ? [EntityActions.APPROVE] : []),
                    EntityActions.CLONE,
                    EntityActions.EDIT,
                    EntityActions.DELETE,
                    EntityActions.READ_ONLY,
                ],
            }));
        },
        listViewColumns() {
            return [
                {
                    name: this.$i18n.t('productCatalog.templates.templateType'),
                    key: 'entityTypeName',
                    field: 'entityTypeName',
                    filterType: tableColumnType.TEXT_LIMITED_OPTIONS,
                    limitedOptions: [...new Set(this.entities.map(e => e.entityTypeName))],
                },
                {
                    name: this.$i18n.t('productCatalog.templates.usesPresets'),
                    key: 'usesPresetTemplates',
                    field: 'usesPresetTemplates',
                    filterType: tableColumnType.TEXT_LIMITED_OPTIONS,
                },
            ];
        },
    },
    mounted() {
        this.fetchTemplates();
    },
    methods: {
        ...mapActions('productcatalog', {
            requestAllTemplates: Actions.PC_REQUEST_ALL_TEMPLATES,
        }),
        onDelete(template) {
            this.archiveTemplate(template);
        },
        async archiveTemplate(template) {
            try {
                this.$Progress.start();
                await updateTemplateState(
                    template.id,
                    template.entityType,
                    TEMPLATE_STATES_NUMERIC.ARCHIVED,
                    template.updateTime,
                );
                this.$Progress.finish();

                this.$eventBus.$emit('showAlert', {
                    type: ALERT_TYPES.success,
                    message: this.$i18n.t('alertMessage.successMessageWithoutRedirect', {
                        action: this.$i18n.t('generic.deleted'),
                        entityName: template.name,
                    }),
                });

                // refetching templates to have latest data
                this.fetchTemplates();
            } catch (e) {
                this.handleEntityDeletionError(e, template);
            }
        },
        async fetchTemplates() {
            try {
                this.$Progress.start();
                await this.requestAllTemplates([
                    TEMPLATE_TYPES_MAP.OFFER,
                    TEMPLATE_TYPES_MAP.PRODUCT,
                    TEMPLATE_TYPES_MAP.SERVICE,
                    TEMPLATE_TYPES_MAP.RESOURCE,
                    TEMPLATE_TYPES_MAP.PRESET,
                ]);
                this.$Progress.finish();
            } catch (error) {
                this.$Progress.fail();
                Sentry.captureException(error);
                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('alertMessage.failedToLoadNecessaryData'),
                });
            }
        },
    },
};
</script>
