<template>
    <AbstractListPageWrapper
        :pageTitle="$i18n.t('authentication.authentication')"
        :isOverviewEnabled="false"
    >
        <template slot="table">
            <div class="content m-4">
                <div
                    v-for="(mapping, index) in mappings"
                    :key="index"
                    class="mapping"
                >
                    <AppMultiselectV3
                        v-model="mapping.role"
                        :options="roles"
                        :small="true"
                        :showLabels="false"
                        :allowEmpty="false"
                        additionalLabel="Role"
                        label="name"
                        trackBy="id"
                        :disabled="!!mapping.id"
                        class="map-role-select"
                        data-test-id="role"
                    />
                    <AppInputV3
                        v-model="mapping.externalGroupId"
                        label="External Group ID"
                        class="map-role-input"
                        :disabled="!!mapping.id"
                        data-test-id="external-group-id"
                    />
                    <AppButton
                        label="Save"
                        class="ml-3"
                        data-test-id="save"
                        :buttonType="BUTTON_TYPES.PRIMARY"
                        :disabled="!!mapping.id"
                        @click="saveMapping(index)"
                    />
                    <AppButton
                        label="Remove"
                        class="ml-3"
                        data-test-id="delete"
                        :buttonType="BUTTON_TYPES.TERMINATION"
                        @click="removeMapping(index)"
                    />
                </div>
                <AppButton
                    label="New mapping"
                    class="mt-4"
                    data-test-id="add"
                    :iconType="ICON_TYPES.PLUS"
                    @click="addMapping"
                />
            </div>
        </template>
    </AbstractListPageWrapper>
</template>

<script>
// Components
import AppInputV3 from '@/components/partials/inputs/AppInputV3.vue';
import AppMultiselectV3 from '@/components/partials/inputs/AppMultiselectV3.vue';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import AbstractListPageWrapper from '@/components/layout/AbstractListPageWrapper.vue';

// HTTP
import { addRoleMappings, getRoleMappings, removeRoleMappings } from '@/__new__/services/portal/auth/http/oauth';
import { getRoles } from '@/__new__/services/portal/admin/http/role';

import Vue from 'vue';

// helpers
import * as Sentry from '@sentry/vue';
import { ICON_TYPES } from '@/common/iconHelper';

export default {
    name: 'AuthRoleMapper',

    components: {
        AppButton,
        AppInputV3,
        AppMultiselectV3,
        AbstractListPageWrapper,
    },

    data() {
        return {
            mappings: [],
            roles: [],
            ICON_TYPES,
            BUTTON_TYPES,
        };
    },

    async created() {
        try {
            this.$Progress.start();

            // Get group to role mappings
            const res = (await getRoleMappings()).data.rolesToExternalGroups;
            if (res.length > 0) {
                this.mappings = res;
            }

            // Get roles list
            const rolesRes = await getRoles();
            this.roles = rolesRes.data;

            // Inject roles into mappings
            for (const mapping of this.mappings) {
                const role = this.roles.find(r => Number(r.id) === mapping.roleId);
                Vue.set(mapping, 'role', role); // Ensure mapping.role is reactive
            }

            this.$Progress.finish();
        } catch (e) {
            this.$Progress.fail();
            this.$eventBus.$emit('showAlert', {
                message: this.$t('alerts.unableToLoadGroups'),
            });
            Sentry.captureException(e);
        }
    },

    methods: {
        addMapping() {
            this.mappings.push({
                roleId: '',
                externalGroupId: '',
            });
        },
        async saveMapping(index) {
            try {
                // Check if it needs to be updated or created for first time
                if (Object.hasOwnProperty.call(this.mappings[index], 'id')) {
                    this.$eventBus.$emit('showAlert', {
                        message: this.$t('alerts.editingNotSupport'),
                    });
                    return false;
                }

                // Check Role Id is specified
                const roleId = this.mappings[index]?.role?.id;
                if (roleId == null) {
                    this.$showErrorAlert({
                        message: this.$i18n.t('alerts.roleMustBeSelectedForRoleMapping'),
                    });
                    return false;
                }

                // Check External Group id valid
                const externalGroupId = this.mappings[index]?.externalGroupId;
                if (!externalGroupId) {
                    this.$showErrorAlert({
                        message: this.$i18n.t('alerts.nonEmptyExternalGroupIdForRoleMapping'),
                    });
                    return false;
                }

                // Add role mapping
                this.$Progress.start();
                const res = await addRoleMappings(roleId, externalGroupId);
                Vue.set(this.mappings[index], 'id', res.data.id);
                this.$Progress.finish();

                // Success alert
                this.$showSuccessAlert({
                    message: this.$i18n.t('alerts.addedGroupToRoleMapping'),
                });
                return true;
            } catch (error) {
                this.$showErrorAlert({
                    message: this.$i18n.t('alerts.failedToSaveGroupToRoleMapping'),
                });
                this.$Progress.fail();
                Sentry.captureException(error);
                return false;
            }
        },

        async removeMapping(index) {
            // Check if it is in internal array of stored in DB.
            if (Object.hasOwnProperty.call(this.mappings[index], 'id')) {
                // Stored in DB
                this.$Progress.start();
                await removeRoleMappings(this.mappings[index].id);
                this.mappings.splice(index, 1);
                this.$Progress.finish();
                // this.$router.go(0);
                return true;
            }
            this.mappings.splice(index, 1);
            return true;
        },
    },
};
</script>

<style lang="scss" scoped>
.content {
    height: 100%;
}
.mapping {
    display: flex;
    align-items: flex-end;
}

.map-role-input {
    flex-grow: 1;
}

.map-role-select {
    width: 25%;
    margin-right: 1em;
}
</style>
