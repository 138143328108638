import { uuidV4 } from '@/common/utils';
import { ICON_COLORS, ICON_TYPES } from '@/common/iconHelper';
import { ALERT_TYPES } from '@/common/alerts/Alert';

type ButtonHandler = () => void;

type ButtonConstructorArgs = {
    // TODO: one of BUTTON_TYPES, fix import
    buttonType?: string;
    label: string;
    iconType?: ICON_TYPES;
    iconColor?: ICON_COLORS;
    isIconRight?: boolean;
    disabled?: boolean;
    tooltip?: string;
    alertType?: ALERT_TYPES;
    isSmall?: boolean;
    isLoading?: boolean;
    id?: string;
    clicked?: string;
    handler?: ButtonHandler;
};

export default class Button {
    id: string;

    action: string | undefined;

    handler?: ButtonHandler;

    // properties that are identical to AppButton.vue props
    label: string;
    iconColor?: string;
    isIconRight?: boolean;
    disabled?: boolean;
    tooltip?: string;
    alertType?: string;
    isSmall?: boolean;
    isLoading?: boolean;
    clicked?: string;

    constructor(args: ButtonConstructorArgs) {
        this.id = args.id || uuidV4();
        this.handler = args.handler;

        this.label = args.label;
        this.iconColor = args.iconColor;
        this.isIconRight = args.isIconRight;
        this.disabled = args.disabled;
        this.tooltip = args.tooltip;
        this.alertType = args.alertType;
        this.isSmall = args.isSmall;
        this.isLoading = args.isLoading;
        this.clicked = args.clicked;
    }
}
