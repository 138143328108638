<template>
    <AppDialogV2
        :visible="isModalVisible"
        :title="titleToDisplay"
        @close="onCloseModal()"
    >
        <div
            class="json-wrap"
            :class="{ invalid }"
        >
            <div
                id="responseEditor"
                class="h-100"
            />
        </div>

        <template slot="modalFooter">
            <AppButton
                :buttonType="BUTTON_TYPES.PRIMARY"
                :label="$t('generic.copyToClipboard')"
                @click="copyToClipboard(JSON.stringify(responseToDisplay))"
            />
        </template>
    </AppDialogV2>
</template>

<script>
// Components
import AppDialogV2 from '@/components/partials/AppDialogV2.vue';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';

// Helpers
import { copyToClipboard } from '@/common/utils';
import importMonacoHelper from '@/common/importMonacoHelper';

export default {
    components: { AppButton, AppDialogV2 },
    props: {
        responseModalVisible: {
            type: Boolean,
            required: true,
            default: false,
        },
        value: {
            type: [Array, Object],
            default: null,
        },
        title: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            titleToDisplay: this.$i18n.t('formBuilderTypes.JSON'),
            isModalVisible: false,
            invalid: false,
            editor: null,
            responseToDisplay: '',
            BUTTON_TYPES,
        };
    },
    watch: {
        responseModalVisible() {
            this.isModalVisible = this.responseModalVisible;

            if (this.responseModalVisible === true) {
                this.renderEditor();
            } else {
                this.editor = null;
            }
        },
        value() {
            this.responseToDisplay = this.remapResponse(this.value);
        },
    },
    mounted() {
        if (this.title) {
            this.titleToDisplay += `: ${this.title}`;
        }
    },
    methods: {
        copyToClipboard,
        onCloseModal() {
            this.isModalVisible = false;
            this.invalid = false;
            this.responseToDisplay = '';
            this.$emit('onCloseModal', this.isModalVisible);
        },
        async renderEditor() {
            await importMonacoHelper.importMonaco();
            if (!this.editor) {
                this.editor = window.monaco.editor.create(document.getElementById('responseEditor'), {
                    value: JSON.stringify(this.responseToDisplay, null, '  '),
                    language: 'json',
                    readOnly: false,
                    automaticLayout: true,
                });
            } else {
                this.editor.setValue(JSON.stringify(this.value, null, '  '));
            }

            // check every input in order to highlight it if input is invalid
            this.editor.onDidChangeModelContent(() => {
                try {
                    this.invalid = false;

                    // get latest value from editor
                    const jsonContent = this.editor.getValue();
                    // parse new value to JSON
                    const newObj = JSON.parse(jsonContent);

                    this.responseToDisplay = newObj;
                } catch {
                    this.invalid = true;
                }
            });
        },
        formatResponse(response) {
            if (response.status >= 200 && response.status < 300) {
                return response.data;
            }
            return response;
        },
        remapResponse(response) {
            if (response == null) {
                return null;
            }
            if (Array.isArray(response)) {
                return response.map(r => this.formatResponse(r));
            }
            return this.formatResponse(response);
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/filter-table';
@import '~@/assets/scss/palette';
@import '~@/assets/scss/icons';
@import '~@/assets/scss/mixins';
@import '~@/assets/scss/layout';
@import '~@/assets/scss/typographyV2';
@import '~@/assets/scss/z-indexes';

.json-wrap {
    border: 0.063rem solid $gray5;
    border-radius: 0.5rem;
    overflow: hidden;
    height: 35rem;
    width: calc(100% - 0.5rem);
}

.invalid {
    border-color: $red;
}

.label {
    font-size: $text-xs;
    color: $gray90;
    line-height: $label-line-height;
    font-weight: $medium-font-weight;
    margin-bottom: 0.25rem;
}
</style>
