<template>
    <div class="app-card">
        <div>
            <slot
                class="icon"
                name="headerIcon"
            />
            <div class="count">
                {{ countText }}
            </div>
            <div class="all-caps-text">
                {{ label }}
            </div>
        </div>
        <AppProgressCircle
            v-if="count"
            class="chart"
            :value="countPercentage"
            :innerText="unit.toUpperCase()"
            :color="circleColor"
        />
    </div>
</template>

<script>
import AppProgressCircle from '@/components/partials/AppProgressCircle.vue';
import { isFinite } from 'lodash';

export default {
    name: 'AppCardView',
    components: {
        AppProgressCircle,
    },
    props: {
        value: {
            type: Number,
            required: true,
        },
        count: {
            type: Number,
            default: Infinity,
        },
        label: {
            type: String,
            required: true,
        },
        unit: {
            type: String,
            default: '',
        },
        circleColor: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            progressBar: null,
        };
    },
    computed: {
        countText() {
            return `${this.value} / ${this.count}`;
        },
        countPercentage() {
            if (isFinite(this.value) && isFinite(this.count) && this.count !== 0) {
                return this.value / this.count;
            }

            return 1;
        },
    },
    methods: {},
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/palette';

.app-card {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    border-radius: 0.5rem;
    background-color: $white;
    padding: 1.5rem 2rem;
}

.chart {
    width: 70px;
    height: 70px;
    margin-left: 1.5rem;
}

.icon {
    margin-bottom: 0.25rem;
}

.count {
    font-size: 1.375rem;
    font-weight: 600;
    color: $navy;
    margin-bottom: 0.25rem;
}
</style>
