import http from '@/http';
import { type AxiosPromise } from 'axios';
import { type DnoResponseBase } from '@/http';
import { type OrderDataBe } from '@/__new__/services/dno/coreom/models/OrderData';

export interface SubscriptionsResponse extends DnoResponseBase {
    orders: Record<string, OrderDataBe>;
    subscriptions: any;
}

const getSubscriptions = (id: string, idType: number): AxiosPromise<SubscriptionsResponse> => {
    return http({
        method: 'POST',
        url: '/v3subscription/getsubscriptions',
        data: {
            target_id: id,
            target_type: idType,
        },
    });
};

export default {
    getSubscriptions,
};
