export const ENTITIES_TYPES_MAP = {
    offer: 'productCatalog.entities.offer',
    product: 'productCatalog.entities.product',
    template: 'productCatalog.entities.template',
    services: 'productCatalog.entities.service',
    resource: 'productCatalog.entities.resource',
    enum: 'productCatalog.entities.enums',
    service: 'productCatalog.entities.service',
    facet: 'productCatalog.entites.facet',
};

export default ENTITIES_TYPES_MAP;
