<template>
    <div class="d-flex justify-content-end py-2 app-pagination-component">
        <div class="d-flex align-items-center">
            <AppButton
                ref="prev"
                :disabled="isFirstPageActive"
                :icon="ICON_TYPES.ARROW_LEFT"
                :class="{ 'mr-2': value === 1 }"
                class="pagination-element"
                @click="prevPage"
            />
            <template v-if="pagesAmount <= 4">
                <div
                    v-for="pageNumber in pagesAmount"
                    :key="pageNumber"
                    :class="{ 'active-page': pageNumber === value }"
                    class="page-number"
                    @click="emitPaginated(pageNumber)"
                >
                    {{ pageNumber }}
                </div>
            </template>
            <template v-else>
                <div
                    :class="{ 'active-page': 1 === value }"
                    class="page-number"
                    @click="emitPaginated(1)"
                >
                    1
                </div>

                <div
                    v-if="value > 3"
                    class="page-number"
                >
                    ...
                </div>

                <template v-for="pageNumber in 3">
                    <div
                        v-if="isPageVisible(pageNumber)"
                        :key="pageNumber"
                        :class="{ 'active-page': pageNumber - 2 + value === value }"
                        class="page-number"
                        @click="emitPaginated(pageNumber - 2 + value)"
                    >
                        {{ pageNumber - 2 + value }}
                    </div>
                </template>

                <div
                    v-if="value < pagesAmount - 2"
                    class="page-number"
                >
                    ...
                </div>

                <div
                    :class="{ 'active-page': pagesAmount === value }"
                    class="page-number"
                    @click="emitPaginated(pagesAmount)"
                >
                    {{ pagesAmount }}
                </div>
            </template>

            <AppButton
                ref="next"
                :disabled="isLastPageActive"
                :icon="ICON_TYPES.ARROW_RIGHT"
                :class="{ 'ml-2': value === pagesAmount }"
                class="pagination-element mr-5"
                @click="nextPage"
            />

            <AppMultiselectV3
                :value="amountValue"
                :searchable="false"
                :showLabels="false"
                :options="amountOptions"
                :borderNone="true"
                :blueArrow="true"
                :placeholder="$i18n.t('table.amountPerPage')"
                label="label"
                trackBy="value"
                class="entities-per-page-picker-wrapper mr-2"
                @input="val => $emit('changeAmountPerPage', val)"
            />

            <div class="all-caps-text total-entities-amount">
                {{ `${$i18n.t('generic.resultsOf')} ${entitiesAmount}` }}
            </div>
        </div>
    </div>
</template>

<script>
// Components
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import AppMultiselectV3 from './inputs/AppMultiselectV3.vue';

// Helpers
import { ICON_TYPES } from '@/common/iconHelper';

export default {
    name: 'AppPaginationV3',
    components: {
        AppButton,
        AppMultiselectV3,
    },
    props: {
        value: {
            type: Number,
            required: true,
        },
        entitiesAmount: {
            type: Number,
            required: true,
        },
        amountOptions: {
            type: Array,
            default: () => [],
        },
        entitiesPerPage: {
            type: Number,
            default: 15,
        },
    },
    data() {
        return {
            ICON_TYPES,
            BUTTON_TYPES,
        };
    },
    computed: {
        pagesAmount() {
            const areAllPagesFullOfEntities = this.entitiesAmount % this.entitiesPerPage === 0;

            if (this.entitiesAmount === 0) {
                return 0;
            }
            if (areAllPagesFullOfEntities) {
                return this.entitiesAmount / this.entitiesPerPage;
            }

            return Math.floor(this.entitiesAmount / this.entitiesPerPage) + 1;
        },
        isNoPagesAtAll() {
            return this.pagesAmount === 0;
        },
        isFirstPageActive() {
            return this.isNoPagesAtAll ? true : this.value === 1;
        },
        isLastPageActive() {
            return this.isNoPagesAtAll ? true : this.value === this.pagesAmount;
        },
        // Amount options per one page
        amountValue() {
            return this.amountOptions.find(option => option.value === this.entitiesPerPage);
        },
    },
    methods: {
        prevPage() {
            this.emitPaginated(this.value - 1);
        },
        nextPage() {
            this.emitPaginated(this.value + 1);
        },
        emitPaginated(pickedPage) {
            this.$emit('reachedLastPage', { isReached: this.pagesAmount === pickedPage, pageNumber: pickedPage });
            this.$emit('input', pickedPage);
            this.$emit('paginated');
        },
        isPageVisible(pageNumber) {
            return pageNumber - 2 + this.value > 1 && pageNumber - 2 + this.value < this.pagesAmount;
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/palette';

.page-number {
    font-size: 0.8125rem;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 2rem;
    width: 2rem;
    color: $gray90;

    cursor: pointer;

    &.active-page {
        font-weight: 600;
        border-radius: 50%;
        background: $blue;
        color: $white;
    }
}

.entities-per-page-picker-wrapper {
    overflow: visible;
    width: 8rem;
}

::v-deep .multiselect__tags,
::v-deep .multiselect__single {
    background: transparent;
}
</style>
