<template>
    <div class="f-date-input display-flex align-items-center">
        <Dropdown
            v-if="displayOperation"
            :items="DateOperationLabels"
            :colorSchema="Styles.White"
            :selected="[value && DateOperationsToLabels[value.op]]"
            :isFilterOperation="true"
            :widthDependsOnContent="true"
            @selected="opLabel => onChange({ op: DateOperationLabelsToTypes[opLabel] })"
        />

        <DateTimePicker
            v-if="!displayDateRangePicker && !isNoArgOperation"
            :value="value && value.value[0]"
            type="date"
            class="ml-3"
            @input="date1 => onChange({ value: [date1, null] })"
        />

        <DateTimePicker
            v-if="displayDateRangePicker && !isNoArgOperation"
            :value="value ? [value.value[0], value.value[1]] : []"
            :range="true"
            type="date"
            class="ml-3"
            @range-selected="(date1, date2) => onChange({ value: [date1, date2] })"
        />
    </div>
</template>

<script>
import moment from 'moment';
import DateTimePicker from '@/components/partials/inputs/DateTimePicker.vue';
import {
    DateOperationLabels,
    DateOperationLabelsToTypes,
    DateOperations,
    DateOperationsToLabels,
    NoArgOperations,
} from '@/common/filters/InputOperations';
import Dropdown, { Styles } from '@/components/partials/Dropdown.vue';

export default {
    components: {
        Dropdown,
        DateTimePicker,
    },
    props: {
        changeCallback: { type: Function, default: null },
        displayOperation: { type: Boolean, default: true },
        initialValue: { type: Object, default: null },
    },
    data() {
        return {
            Styles,
            DateOperationLabels,
            DateOperationsToLabels,
            DateOperationLabelsToTypes,
            value: this.initialValue, // override
        };
    },
    computed: {
        displayDateRangePicker() {
            return this.value && this.value.op === DateOperations.Between;
        },
        formatDateRange() {
            if (this.value && this.value.value[0] && this.value.value[1]) {
                return `${moment(this.value.value[0]).format('MMM D, YYYY')} - ${moment(this.value.value[1]).format(
                    'MMM D, YYYY',
                )}`;
            }
            return '';
        },
        isNoArgOperation() {
            return this.value && NoArgOperations[this.value.op];
        },
    },
    methods: {
        onChange(partialValue) {
            this.onValueChange(partialValue);
            this.$emit('change', partialValue);
            if (this.changeCallback) this.changeCallback();
        },
        onValueChange(partialValue = {}) {
            return partialValue;
        }, // override
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/mixins';

.datepicker-wrapper {
    @include custom-height-datepicker(32px !important);
    @include custom-width-datepicker(260px);
}
</style>
