
import Vue, { type PropType } from 'vue';
// components
import AppTable from '@/components/partials/AppTable.vue';
import AppAditionalSidebar from '@/components/partials/AppAditionalSidebar.vue';
import EntityStatusIndicator from '@/components/partials/EntityStatusIndicator.vue';
import FilterTable from '@/components/partials/FilterTable.vue';
import IconButton from '@/components/partials/IconButton.vue';
import ResponseModalButton from '@/components/partials/ResponseModalButton.vue';
import SearchBox from '@/components/partials/inputs/SearchBox.vue';
import TableFiltersRenderless from '@/components/filters/TableFiltersRenderless.vue';
import TableFiltersTags from '@/components/filters/TableFiltersTags.vue';

// helpers
import OutageInfo from '@/__new__/services/dno/osstmofiber/models/OutageInfo';
import { ICON_TYPES } from '@/common/iconHelper';
import tableColumnType from '@/common/filterTable';
import {
    OUTAGE_STATUS_TO_COLOR_MAP,
    OUTAGE_STATUS_TO_LABEL,
} from '@/__new__/features/customerCare/common/outageHelper';

export default Vue.extend({
    name: 'OutageHistoryTable',
    components: {
        AppTable,
        AppAditionalSidebar,
        EntityStatusIndicator,
        FilterTable,
        IconButton,
        ResponseModalButton,
        SearchBox,
        TableFiltersRenderless,
        TableFiltersTags,
    },
    props: {
        outageData: {
            type: Array as PropType<OutageInfo[]>,
            default: () => [],
        },
        apiResponse: {
            type: Object,
            default: null,
        },
    },
    data() {
        return {
            columns: [
                {
                    name: this.$i18n.t('customerCare.userInformation.outageId'),
                    key: 'id',
                    field: 'id',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('generic.status'),
                    key: 'status',
                    field: 'status',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('generic.startTime'),
                    key: 'startTime',
                    field: 'startTime',
                    filterType: tableColumnType.DATE,
                },
            ],
            nestedColumns: [
                {
                    name: this.$i18n.t('generic.endTime'),
                    key: 'rowEndTime',
                    field: 'rowEndTime',
                    filterType: tableColumnType.DATE,
                },
                {
                    name: this.$i18n.t('customerCare.userInformation.outageReason'),
                    key: 'rowReason',
                    field: 'rowReason',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('customerCare.userInformation.estimatedResolution'),
                    key: 'rowResolution',
                    field: 'rowResolution',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
            ],
            isSidebarVisible: false,
            searchQuery: '',
            selectedEntity: new OutageInfo(),
            ICON_TYPES,
            OUTAGE_STATUS_TO_COLOR_MAP,
            OUTAGE_STATUS_TO_LABEL,
        };
    },
    computed: {
        columnsData() {
            return [...this.columns, ...this.nestedColumns];
        },
    },
    methods: {
        fetchOutagesData(): void {
            this.$emit('refreshData');
        },
        closeSidebar(): void {
            this.selectedEntity = new OutageInfo();
        },
        openSidebar(entity: OutageInfo): void {
            this.isSidebarVisible = true;
            this.selectedEntity = entity;
        },
    },
});
