import {
    EntityBaseConstructorArgs,
    EntityBaseModel,
    EntityBaseDraftModel,
} from '@/__new__/services/dno/charging/models/EntityBaseModel';
import i18n from '@/i18n';
import { TranslateResult } from 'vue-i18n';
import { EntityActions } from '@/common/baseStatesHelper';

interface ContentTypeConstructorArgs extends EntityBaseConstructorArgs {
    app_name: string;
    is_transferable: boolean;
}

export interface ContentTypeDraftConstructorArgs extends ContentTypeConstructorArgs {
    operator_name: string;
    type: string;
    update_portal_id: string;
    data?: ContentTypeConstructorArgs;
}

export class ContentType extends EntityBaseModel {
    appName: string;
    isTransferable: boolean;
    isTransferableLabel: TranslateResult;

    constructor(ct: ContentTypeConstructorArgs, draftEntities: ContentTypeDraft[]) {
        super(ct, draftEntities, [EntityActions.CLONE, EntityActions.EDIT, EntityActions.READ_ONLY]);
        this.appName = ct.app_name;
        this.isTransferable = ct.is_transferable || false;
        this.isTransferableLabel = this.isTransferable ? i18n.t('generic.yes') : i18n.t('generic.no');
    }
}

export class ContentTypeDraft extends EntityBaseDraftModel {
    appName: string;
    isTransferable: boolean;
    isTransferableLabel: TranslateResult;

    constructor(ct: ContentTypeDraftConstructorArgs) {
        super(ct);
        this.appName = ct.app_name;
        this.isTransferable = ct.is_transferable;
        this.isTransferableLabel = this.isTransferable ? i18n.t('generic.yes') : i18n.t('generic.no');
    }
}

export default ContentType;
