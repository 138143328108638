import { render, staticRenderFns } from "./OrderDetailsTileNextGen.vue?vue&type=template&id=21464d13&scoped=true"
import script from "./OrderDetailsTileNextGen.vue?vue&type=script&lang=ts"
export * from "./OrderDetailsTileNextGen.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "21464d13",
  null
  
)

export default component.exports