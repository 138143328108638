<template>
    <AbstractListPageWrapper
        :pageTitle="$i18n.t('authentication.authentication')"
        :isOverviewEnabled="false"
    >
        <template slot="table">
            <div class="default-page-content-wrapper main">
                <h3 class="modal-title">
                    {{ $i18n.t('authentication.authenticationSettings') }}
                </h3>
                <span class="small-gray-note mb-4">{{ $i18n.t('authentication.authenticationSettingsSummary') }}</span>
                <table>
                    <tbody>
                        <tr
                            v-for="setting in authSettings"
                            :key="setting.strategy"
                        >
                            <td>
                                <AppToggle
                                    v-model="setting.enabled"
                                    @input="onSliderClick(setting)"
                                />
                            </td>
                            <td>
                                <span>{{ $i18n.t(`authentication.authenticationTypes.${setting.strategy}`) }}</span>
                            </td>
                            <td>
                                <AppButton
                                    v-if="setting.strategy !== 'local'"
                                    :buttonType="BUTTON_TYPES.TERTIARY"
                                    :iconType="ICON_TYPES.SIDEBAR_SETTINGS"
                                    :isSmall="true"
                                    @click="configureAuth(setting.strategy)"
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </template>
    </AbstractListPageWrapper>
</template>

<script>
// Components
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import AbstractListPageWrapper from '@/components/layout/AbstractListPageWrapper.vue';
import AppToggle from '@/components/partials/inputs/AppToggle.vue';

// Helpers
import RouteNames from '@/router/routeNames';
import { ICON_TYPES } from '@/common/iconHelper';
import { ALERT_TYPES } from '@/common/alerts/Alert';
import * as Sentry from '@sentry/vue';

// HTTP
import { getAuthStrategyState, setAuthStrategyState } from '@/__new__/services/portal/auth/http/auth';

export default {
    name: 'AuthenticationMain',

    components: {
        AppButton,
        AbstractListPageWrapper,
        AppToggle,
    },

    data() {
        return {
            // Object with auth provider type as a key for its properties
            authProviders: {
                azuread: {
                    name: 'Azure Active Directory',
                    type: 'azuread',
                    enabled: false,
                },
            },
            authSettings: [],
            oAuthConfigs: [],
            ICON_TYPES,
            BUTTON_TYPES,
        };
    },

    async created() {
        // Retrieve configs if they exist toggle the correct ones on
        try {
            this.$Progress.start();

            const authSettingsRes = await getAuthStrategyState();
            if (authSettingsRes.data) {
                this.authSettings = authSettingsRes.data;
            }

            this.$Progress.finish();
        } catch (e) {
            this.$eventBus.$emit('showAlert', {
                message: this.$t('alerts.unableToLoadConfigs'),
            });
            Sentry.captureException(e);
        }
    },

    methods: {
        configureAuth(provider) {
            this.$router.push({
                name: RouteNames.AUTHENTICATION_CONFIGURATOR,
                query: { provider },
                params: { companyId: this.$route.params.companyId },
            });
        },
        async onSliderClick(setting) {
            const strategyStr = this.$i18n.t(`authentication.authenticationTypes.${setting.strategy}`);
            const enabledStr = setting.enabled
                ? this.$i18n.t('settings.alerts.enabled')
                : this.$i18n.t('settings.alerts.disabled');
            try {
                await setAuthStrategyState(setting.strategy, setting.enabled);
                const toggleWarningMessage = this.$i18n.t('settings.alerts.strategyToggled', {
                    strategy: strategyStr,
                    enabled: enabledStr,
                });
                this.$eventBus.$emit('showAlert', {
                    message: toggleWarningMessage,
                    type: ALERT_TYPES.warning,
                });
            } catch (error) {
                const toggleFailedMessage = this.$i18n.t('settings.alerts.strategyToggleFailed', {
                    strategy: strategyStr,
                    enabled: enabledStr,
                });
                this.$eventBus.$emit('showAlert', {
                    message: toggleFailedMessage,
                });
                Sentry.captureException(error);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/layout';

.main {
    padding: $spacing-xl;
}
.mapping-button {
    margin-top: $spacing-m;
}

table {
    table-layout: fixed;
    margin-top: $spacing-m;
}

th,
td {
    padding: $spacing-xs;
}
</style>
