import i18n from '@/i18n';
import { TranslateResult } from 'vue-i18n';
import { LABEL_COLOR } from '@/common/labelsHelper';

export enum EXECUTION_STATES {
    SUCCESS = 'SUCCESS',
    FAILED = 'FAILED',
    RUNNING = 'RUNNING',
    STOPPED = 'STOPPED',
    PENDING = 'PENDING',
    PAUSED = 'PAUSED',
    CALLBACK = 'WAITING FOR CALLBACK',
    EXECUTING = 'EXECUTING',
    WAITINGFOREXECUTIONS = 'WAITING_FOR_EXECUTIONS',
    WAITINGFORCALLBACK = 'WAITING_FOR_CALLBACK',
    WAITINGFORRESTART = 'WAITING_FOR_RESTART',
    MOVED_TO_ASYNCHRONOUS = 'MOVED_TO_ASYNCHRONOUS',
}

export enum EXECUTION_STATES_CODES {
    SUCCESS = 1,
    FAILED = 2,
    EXECUTING = 3,
    STOPPED = 4,
    PENDING = 5,
    WAITINGFOREXECUTIONS = 6,
    WAITINGFORCALLBACK = 7,
    WAITINGFORRESTART = 8,
}

export const EXECUTION_STATUS_TO_COLOR_MAP = new Map([
    [EXECUTION_STATES.PENDING, LABEL_COLOR.yellow],
    [EXECUTION_STATES.CALLBACK, LABEL_COLOR.yellow],
    [EXECUTION_STATES.SUCCESS, LABEL_COLOR.green],
    [EXECUTION_STATES.FAILED, LABEL_COLOR.red],
    [EXECUTION_STATES.STOPPED, LABEL_COLOR.gray],
    [EXECUTION_STATES.RUNNING, LABEL_COLOR.blue],
    [EXECUTION_STATES.EXECUTING, LABEL_COLOR.blue],
    [EXECUTION_STATES.WAITINGFOREXECUTIONS, LABEL_COLOR.yellow],
    [EXECUTION_STATES.WAITINGFORCALLBACK, LABEL_COLOR.yellow],
    [EXECUTION_STATES.WAITINGFORRESTART, LABEL_COLOR.gray],
    [EXECUTION_STATES.MOVED_TO_ASYNCHRONOUS, LABEL_COLOR.yellow],
]);

export const EXECUTION_ID_TO_STATUS: Record<number, EXECUTION_STATES> = {
    1: EXECUTION_STATES.SUCCESS,
    2: EXECUTION_STATES.FAILED,
    3: EXECUTION_STATES.EXECUTING,
    4: EXECUTION_STATES.STOPPED,
    5: EXECUTION_STATES.PENDING,
    6: EXECUTION_STATES.WAITINGFOREXECUTIONS,
    7: EXECUTION_STATES.WAITINGFORCALLBACK,
    8: EXECUTION_STATES.WAITINGFORRESTART,
};

export const EXECUTION_TO_LABEL: Record<string, TranslateResult> = {
    [EXECUTION_STATES.SUCCESS]: i18n.t('generic.stateMap.success'),
    [EXECUTION_STATES.FAILED]: i18n.t('generic.stateMap.failed'),
    [EXECUTION_STATES.EXECUTING]: i18n.t('generic.stateMap.executing'),
    [EXECUTION_STATES.STOPPED]: i18n.t('generic.stateMap.stopped'),
    [EXECUTION_STATES.PENDING]: i18n.t('generic.stateMap.pending'),
    [EXECUTION_STATES.WAITINGFOREXECUTIONS]: i18n.t('generic.stateMap.waitingForExecutions'),
    [EXECUTION_STATES.WAITINGFORCALLBACK]: i18n.t('generic.stateMap.waitingForCallback'),
    [EXECUTION_STATES.WAITINGFORRESTART]: i18n.t('generic.stateMap.paused'),
    [EXECUTION_STATES.MOVED_TO_ASYNCHRONOUS]: i18n.t('generic.stateMap.movedToAsynchronous'),
};

export const EXECUTION_STATE_TO_CANCELATION_MESSAGE = new Map([
    [EXECUTION_STATES.PENDING, i18n.t('customerCare.cancelTerminate.executionStatus.pending')],
    [EXECUTION_STATES.EXECUTING, i18n.t('customerCare.cancelTerminate.executionStatus.executing')],
    [EXECUTION_STATES.SUCCESS, i18n.t('customerCare.cancelTerminate.executionStatus.success')],
    [EXECUTION_STATES.WAITINGFORCALLBACK, i18n.t('customerCare.cancelTerminate.executionStatus.waitingForCallback')],
    [EXECUTION_STATES.PAUSED, i18n.t('customerCare.cancelTerminate.executionStatus.paused')],
    [EXECUTION_STATES.FAILED, i18n.t('customerCare.cancelTerminate.executionStatus.failed')],
    [EXECUTION_STATES.STOPPED, i18n.t('customerCare.cancelTerminate.executionStatus.stopped')],
]);

// Executions hierarchy table, execution state field

export enum EXECUTIONS_HIERARCHY_EXECUTION_STATES_CODES {
    SUCCESS = 1,
    FAILED = 2,
    STOPPED = 3,
    PENDING = 4,
    FAILED_EXTERNAL = 5,
    FAILED_INTERNAL = 6,
    EXECUTING_EXTERNAL = 7,
    EXECUTING_INTERNAL = 8,
}

export const EXECUTIONS_HIERARCHY_EXECUTION_STATES_CODES_LABEL_MAP = new Map([
    [EXECUTIONS_HIERARCHY_EXECUTION_STATES_CODES.SUCCESS, i18n.t('generic.stateMap.success')],
    [EXECUTIONS_HIERARCHY_EXECUTION_STATES_CODES.FAILED, i18n.t('generic.stateMap.failed')],
    [EXECUTIONS_HIERARCHY_EXECUTION_STATES_CODES.STOPPED, i18n.t('generic.stateMap.stopped')],
    [EXECUTIONS_HIERARCHY_EXECUTION_STATES_CODES.PENDING, i18n.t('generic.stateMap.pending')],
    [
        EXECUTIONS_HIERARCHY_EXECUTION_STATES_CODES.FAILED_EXTERNAL,
        i18n.t('orchestrationEngine.executionHierarchy.states.failedExternal'),
    ],
    [
        EXECUTIONS_HIERARCHY_EXECUTION_STATES_CODES.FAILED_INTERNAL,
        i18n.t('orchestrationEngine.executionHierarchy.states.failedInternal'),
    ],
    [
        EXECUTIONS_HIERARCHY_EXECUTION_STATES_CODES.EXECUTING_EXTERNAL,
        i18n.t('orchestrationEngine.executionHierarchy.states.executingExternalCall'),
    ],
    [
        EXECUTIONS_HIERARCHY_EXECUTION_STATES_CODES.EXECUTING_INTERNAL,
        i18n.t('orchestrationEngine.executionHierarchy.states.executingInternalCall'),
    ],
]);

export const EXECUTIONS_HIERARCHY_EXECUTION_STATES_CODES_TO_COLOR_MAP = new Map([
    [EXECUTIONS_HIERARCHY_EXECUTION_STATES_CODES.PENDING, LABEL_COLOR.yellow],
    [EXECUTIONS_HIERARCHY_EXECUTION_STATES_CODES.SUCCESS, LABEL_COLOR.green],
    [EXECUTIONS_HIERARCHY_EXECUTION_STATES_CODES.FAILED, LABEL_COLOR.red],
    [EXECUTIONS_HIERARCHY_EXECUTION_STATES_CODES.STOPPED, LABEL_COLOR.gray],
    [EXECUTIONS_HIERARCHY_EXECUTION_STATES_CODES.FAILED_EXTERNAL, LABEL_COLOR.gray],
    [EXECUTIONS_HIERARCHY_EXECUTION_STATES_CODES.FAILED_INTERNAL, LABEL_COLOR.gray],
    [EXECUTIONS_HIERARCHY_EXECUTION_STATES_CODES.EXECUTING_EXTERNAL, LABEL_COLOR.yellow],
    [EXECUTIONS_HIERARCHY_EXECUTION_STATES_CODES.EXECUTING_INTERNAL, LABEL_COLOR.yellow],
]);

export default {};
