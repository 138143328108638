<template>
    <div>
        <div
            v-t="'charging.chargingSpecifications.editor.conditionText.otherPartyRN'"
            class="table-title"
        />
        <div class="d-flex">
            <div class="mr-2">
                {{ operator }}
            </div>
            <div>
                {{ valuesFormatted }}
            </div>
        </div>
    </div>
</template>

<script>
import isEmpty from 'lodash/isEmpty';
import { CONDITIONS_OPTIONS } from '@/__new__/services/dno/charging/common/conditionsHelper';
import { getConditionByOperatorAndInverted } from '@/__new__/features/charging/conditionParameters/conditions/OtherPartyRNCondition.vue';

export default {
    name: 'OtherPartyRNValue',
    props: {
        value: {
            type: Object,
            required: true,
        },
    },
    computed: {
        operator() {
            if (!isEmpty(this.value)) {
                return CONDITIONS_OPTIONS.find(
                    option =>
                        option.id === getConditionByOperatorAndInverted(this.value?.operator, this.value.inverted),
                ).label;
            }
            return '?';
        },
        valuesFormatted() {
            return this.value?.values.map(option => option).join(', ');
        },
    },
};
</script>
