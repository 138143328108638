import Vue from 'vue';
import Actions, { State, Getters, Mutations } from '@/store/mutation-types';
import { getPromotions } from '@/modules/promotions/http/promotion';

export default {
    namespaced: true,
    state: {
        [State.PROMOTIONS]: {},
        [State.PROMOTIONS_API_RESPONSE]: null,
    },
    mutations: {
        [Mutations.SET_PROMOTIONS]: (state, promoRuleById) => {
            Object.values(promoRuleById).forEach(promotion => {
                Vue.set(state[State.PROMOTIONS], promotion.id, promotion);
            });
        },
        [Mutations.SET_PROMOTIONS_API_RESPONSE]: (state, apiResponse) => {
            state[State.PROMOTIONS_API_RESPONSE] = apiResponse;
        },
    },
    actions: {
        async [Actions.REQUEST_PROMOTIONS]({ commit }) {
            const response = await getPromotions();
            commit(Mutations.SET_PROMOTIONS_API_RESPONSE, response);
            const { promo_rule_by_id: promoRuleById } = response?.data || {};
            if (promoRuleById) {
                commit(Mutations.SET_PROMOTIONS, promoRuleById);
            }
        },
    },
    getters: {
        [Getters.PROMOTIONS_LIST]: state => Object.values(state[State.PROMOTIONS]),
        [Getters.PROMOTION_BY_ID]: state => PromotionId => state[State.PROMOTIONS][PromotionId],
    },
};
