import { LocalizedString } from '@/http';

export enum CYCLE_TYPE {
    FIXED_DATE = 'fixed',
    SERVICE_ACTIVATTION_DATE = 'service_activation',
}

export enum FIXED_CYCLE_PERIODS {
    WEEK = 'week',
    BIMONTHLY = 'bi-monthly',
    MONTHLY = 'monthly',
}

export enum DAY {
    SUNDAY = 'sunday',
    MONDAY = 'monday',
    TUESDAY = 'tuesday',
    WEDNESDAY = 'wednesday',
    THURSDAY = 'thursday',
    FRIDAY = 'friday',
    SATURDAY = 'saturday',
}

export interface FixedWeeklySpecification {
    type: CYCLE_TYPE.FIXED_DATE;
    fixed_cycle_period: FIXED_CYCLE_PERIODS.WEEK;
    day_of_week: DAY;
}

export interface FixedBimonthlySpecification {
    type: CYCLE_TYPE.FIXED_DATE;
    fixed_cycle_period: FIXED_CYCLE_PERIODS.BIMONTHLY;
}

export interface FixedMonthlySpecification {
    type: CYCLE_TYPE.FIXED_DATE;
    fixed_cycle_period: FIXED_CYCLE_PERIODS.MONTHLY;
    day_of_month: number;
}

export type FixedCycleSpecification =
    | FixedWeeklySpecification
    | FixedBimonthlySpecification
    | FixedMonthlySpecification;

export enum SERVICE_ACTIVATION_PERIODS {
    DAY = 'day',
    MONTH = 'month',
    WEEK = 'week',
}

export interface ServiceActivationSpecification {
    type: CYCLE_TYPE.SERVICE_ACTIVATTION_DATE;
    service_activation_period: SERVICE_ACTIVATION_PERIODS;
    service_activation_period_multiplier: number;
}

export interface CycleSpecification {
    description: LocalizedString;
    name: LocalizedString;
    specification: FixedCycleSpecification | ServiceActivationSpecification;
}
