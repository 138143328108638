<template>
    <div>
        <HeaderTabsNav
            v-model="activeTab"
            :tabName="tabName"
            :numOfTabs="numOfTabs"
        />
        <slot
            :activeTab="activeTab"
            name="content"
        />
        <FooterNav
            v-model="activeTab"
            :numOfTabs="numOfTabs"
            :previousText="previousText"
            :nextText="nextText"
        />
    </div>
</template>

<script>
// Generic Vue Components
import FooterNav from '@/components/partials/FooterNav.vue';
import HeaderTabsNav from '@/components/partials/HeaderTabsNav.vue';

export default {
    name: 'TabNavigation',
    components: {
        FooterNav,
        HeaderTabsNav,
    },
    props: {
        numOfTabs: {
            type: Number,
            default: 8,
        },
        tabName: {
            type: String,
            default: 'Tab',
        },
        previousText: {
            type: String,
            default: 'Previous',
        },
        nextText: {
            type: String,
            default: 'Configure next',
        },
    },

    data() {
        return {
            activeTab: 0,
        };
    },
};
</script>

<style scoped></style>
