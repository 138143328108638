import { AxiosPromise } from 'axios';
import http, { DnoResponseBase } from '@/http/index';
import { TariffSpecification } from '@/__new__/services/dno/charging/models/TariffSpecification';

export interface TariffSpecApiResponse extends DnoResponseBase {
    tariff_spec_by_id: Record<string, any>;
    condition_parameter_by_id?: Record<string, any>;
}

export const enum GET_TARIFF_SPECS_TYPES {
    GET_REGULAR = 1,
    GET_ZERO_RATE = 2,
    GET_ALL = 3,
}

/**
 * Get tariff specifications.
 *
 * @returns {AxiosPromise}
 */
export function getTariffSpecs(
    ids: string[] = [],
    tariffSpecGetType: GET_TARIFF_SPECS_TYPES = GET_TARIFF_SPECS_TYPES?.GET_REGULAR,
    returnChargingVersion = true,
    includeReferencedEntities = false,
    includePortalId = true,
    chargingVersion?: string,
): AxiosPromise<TariffSpecApiResponse> {
    return http({
        method: 'POST',
        url: '/v3charging/gettariffspecs',
        data: {
            ids,
            return_charging_version: returnChargingVersion,
            include_referenced_entities: includeReferencedEntities,
            charging_version: chargingVersion,
            include_portal_id: includePortalId,
            filter_params: {
                tariff_spec_type_to_get: tariffSpecGetType,
            },
        },
    });
}

/**
 * Add tariff specification.
 *
 * @returns {AxiosPromise}
 */
export function addTariffSpec(data: any): AxiosPromise<any> {
    return http({
        method: 'POST',
        url: '/v3chargingwrite/addtariffspec',
        data,
    });
}

/**
 * Update tariff specification.
 *
 * @returns {AxiosPromise}
 */
export function updateTariffSpec(
    id: string,
    version: number,
    data: TariffSpecification,
): AxiosPromise<TariffSpecification> {
    return http({
        method: 'POST',
        url: '/v3chargingwrite/updatetariffspec',
        data: {
            id,
            version,
            data,
        },
    });
}

/**
 * Update tariff specification state.
 *
 * @returns {AxiosPromise}
 */
export function updateTariffSpecState(id: string, version: number, state: number): AxiosPromise<TariffSpecification> {
    return http({
        method: 'POST',
        url: '/v3chargingwrite/updatestatetariffspec',
        data: {
            id,
            version,
            state,
        },
    });
}

interface TariffSpecsDraftListApiResponse extends DnoResponseBase {
    data: any;
}

/**
 * Get tariff specification draft list.
 *
 * @returns {AxiosPromise}
 */
export function getTariffSpecsDraftList(): AxiosPromise<TariffSpecsDraftListApiResponse> {
    return http({
        method: 'POST',
        url: '/v3charging/gettariffspecsdraftlist',
    });
}

/**
 * Get tariff specification entity draft.
 *
 * @returns {AxiosPromise}
 */
export function getTariffSpecEntityDraft(id: string): AxiosPromise<DnoResponseBase> {
    return http({
        method: 'POST',
        url: '/v3charging/gettariffspecdraft',
        data: {
            id,
        },
    });
}

/**
 * Set tariff specification draft.
 *
 * @returns {AxiosPromise}
 */
type TariffSpecDraftPayload = {
    id?: string;
    data: object;
};

export function setTariffSpecDraft(data: TariffSpecDraftPayload): AxiosPromise<DnoResponseBase> {
    return http({
        method: 'POST',
        url: '/v3charging/settariffspecdraft',
        data,
    });
}

/**
 * Delete tariff specification draft.
 *
 * @returns {AxiosPromise}
 */
export function deleteTariffSpecDraft(id: string): AxiosPromise<DnoResponseBase> {
    return http({
        method: 'POST',
        url: '/v3charging/deletetariffspecdraft',
        data: {
            id,
        },
    });
}

export default {
    getTariffSpecs,
    addTariffSpec,
    updateTariffSpec,
    updateTariffSpecState,
    getTariffSpecsDraftList,
    getTariffSpecEntityDraft,
    setTariffSpecDraft,
    deleteTariffSpecDraft,
};
