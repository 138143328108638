import i18n from '@/i18n';
import { GENERATION_TYPES_BY_KEYS } from '@/common/segments';
import { TranslateResult } from 'vue-i18n';

export const generationTypeLabelMap = (type: number) => {
    switch (type) {
        case GENERATION_TYPES_BY_KEYS.TRIGGER_BASED:
            return i18n.t('segments.generationLabels.triggerBased');
        case GENERATION_TYPES_BY_KEYS.DYNAMIC:
            return i18n.t('segments.generationLabels.dynamic');
        case GENERATION_TYPES_BY_KEYS.STATIC_FILTER:
            return i18n.t('generic.static');
        default:
            return type;
    }
};
export const invertGenerationTypeLabelMap = (type: TranslateResult) => {
    switch (type) {
        case i18n.t('segments.generationLabels.triggerBased'):
            return GENERATION_TYPES_BY_KEYS.TRIGGER_BASED;
        case i18n.t('segments.generationLabels.dynamic'):
            return GENERATION_TYPES_BY_KEYS.DYNAMIC;
        case i18n.t('generic.static'):
            return GENERATION_TYPES_BY_KEYS.STATIC_FILTER;
        default:
            return type;
    }
};

export default {
    generationTypeLabelMap,
};
