import { invert } from 'lodash';

export const MessageTypes = {
    PUSH: 'push',
    SMS: 'sms',
    EMAIL: 'email',
    OTT: 'ott',
};

export const MessageTypeLabels = {
    [MessageTypes.PUSH]: 'Push Notification',
    [MessageTypes.SMS]: 'SMS',
    [MessageTypes.EMAIL]: 'Email',
    [MessageTypes.OTT]: 'In-app',
};

export const MessageTypesHistoryIntMapping = {
    [MessageTypes.PUSH]: 0,
    [MessageTypes.SMS]: 1,
    [MessageTypes.EMAIL]: 3,
    [MessageTypes.OTT]: 4,
};

export const MessageTypeReverseHistoryIntMapping = invert(MessageTypesHistoryIntMapping);

export default {
    MessageTypes,
    MessageTypeLabels,
    MessageTypesHistoryIntMapping,
    MessageTypeReverseHistoryIntMapping,
};
