import { TranslateResult } from 'vue-i18n';
import moment, { Moment } from 'moment';
import 'moment-timezone';
import i18n from '@/i18n';
import currencies, { CURRENCY_POSITION } from '@/common/currency';
import Locale from '@/models/Locale';
import configStore, { ConfigStoreState } from '@/store/modules/config.store';

export const NO_END_TIME_EPOCH = 21474836479;

export const dateFormatTypes = {
    dayMonthYear: {
        id: 'day_month_year',
        format: 'DD-MM-YYYY',
    },
    monthDayYear: {
        id: 'month_day_year',
        format: 'MM-DD-YYYY',
    },
    yearMonthDay: {
        id: 'year_month_day',
        format: 'YYYY-MM-DD',
    },
};

export const timeFormatTypes = {
    twelveHour: {
        id: '12',
    },
    twentyFourHour: {
        id: '24',
    },
};

export const languages = {
    en_US: {
        id: 'en',
        name: 'English',
    },
    es_SP: {
        id: 'es',
        name: 'Spanish',
    },
    fr_FR: {
        id: 'fr',
        name: 'French',
    },
};

// https://www.npmjs.com/package/vuejs-datepicker#date-formatting
export const dateFormatMap = new Map([
    ['DD-MM-YYYY', 'dd-MM-yyyy'],
    ['MM-DD-YYYY', 'MM-dd-yyyy'],
    ['YYYY-MM-DD', 'yyyy-MM-dd'],
]);

const TimeStringFormat = 'hh:mma';
const TimeStringFormat24hours = 'HH:mm';
const TimeSecondsStringFormat = 'hh:mm:ssa';
const TimeSecondsStringFormat24hours = 'HH:mm:ss';
const defaultTimeZone = `(GMT${moment.tz(moment.tz.guess()).format('Z')}) ${moment.tz.guess()}`;

function getTimezone(): string {
    const localeSettings: Locale = (configStore.state as ConfigStoreState).locale;
    return localeSettings?.timezone ?? defaultTimeZone;
}

function getDateFormat(): string {
    const localeSettings: Locale = (configStore.state as ConfigStoreState).locale;
    return localeSettings?.dateFormat ?? dateFormatTypes.yearMonthDay.format;
}

function getTimeFormat(): string {
    const localeSettings: Locale = (configStore.state as ConfigStoreState).locale;
    return localeSettings?.timeFormat ?? timeFormatTypes.twelveHour.id;
}

export function getCurrency(): string {
    const localeSettings: Locale = (configStore.state as ConfigStoreState).locale;
    return localeSettings?.currency ?? 'USD';
}

const currencyDigitFormat = new Map([
    [CURRENCY_POSITION.After, 'de'], // means symbol goes after amount
    [CURRENCY_POSITION.Before, 'en'], // means symbol goes before amount
]);

const timeFormatMap = new Map([
    ['12', TimeStringFormat],
    ['24', TimeStringFormat24hours],
]);

const timeSecondsFormatMap = new Map([
    ['12', TimeSecondsStringFormat],
    ['24', TimeSecondsStringFormat24hours],
]);

/**
 * Function returns name of the timezone removing the GMT offset
 * Input: (GMT+00:00) Africa/Abidjan
 * Output: Africa/Abidjan
 * @param tz
 * @returns {string}
 */
function getTimezoneFormattedString(tz: string): string {
    return tz.substring(11).trim();
}

/**
 * Returns the timezone offset string for the current timezone.
 * If timezone is not defined or does not contain offset returns
 * an empty string.
 * Input: '(GMT+02:00) Europe/Businge'
 * Output: '(GMT+02:00)'
 */
function getCurrentTimezoneOffset(): string {
    return getTimezone()?.match(/\(.*\)/)?.[0] || '';
}

/**
 * Function cheks does timezone exist in the list
 * Input: Africa/Abidjan
 * Output: true
 * @param tz
 * @returns {string}
 */
function timeZoneIsValid(tz: string): boolean {
    return moment.tz.names().includes(tz);
}

/**
 * Function always cast timestamp into miliseconds
 * Input: 1318781876
 * Output: 1318781876000
 * @param timestamp
 * @returns {string}
 */
function normalizeTimestamp(timestamp: number): TranslateResult | number {
    if (timestamp) {
        if (timestamp.toString().length === 13) {
            return timestamp;
        }
        if (timestamp.toString().length <= 10) {
            return timestamp * 1000;
        }
        return timestamp;
    }
    return i18n.t('generic.N/A');
}

/**
 * Returns moment object in selected timezone
 * Input: 1318781876000
 * Output: Moment {...}
 * @param timestamp
 * @returns {string}
 */
function normalizeTimezone(timestamp: number): Moment {
    return moment.tz(normalizeTimestamp(timestamp), getTimezoneFormattedString(getTimezone()));
}

/**
 * Function returns the formatted date in selected timezone
 * Input: 1318781876
 * Output: 2021-03-29
 * @param timestamp
 * @returns {string}
 */
function getFormattedDate(timestamp: number) {
    if (timestamp && typeof timestamp === 'number' && timeZoneIsValid(getTimezoneFormattedString(getTimezone()))) {
        return normalizeTimezone(timestamp).format(getDateFormat());
    }
    return i18n.t('generic.N/A');
}

/**
 * Function returns the formatted date in default date format
 * formatted by default UTC standard
 * Input: 1318781876
 * Output: 2022-03-29 | 29-03-2022 | 03-29-2022
 * @param timestamp
 * @returns {string}
 */
function formatDateToUTC(timestamp: number): TranslateResult {
    if (timestamp) {
        return moment.tz(timestamp, 'Etc/UTC').format(getDateFormat());
    }
    return i18n.t('generic.N/A');
}

/**
 * Function returns the formatted time in selected timezone
 * Input: 1318781876
 * Output: 12:28pm
 * @param timestamp
 * @returns {string}
 */
function getFormattedTime(timestamp: number): TranslateResult {
    if (timestamp && timeZoneIsValid(getTimezoneFormattedString(getTimezone()))) {
        return normalizeTimezone(timestamp).format(timeFormatMap.get(getTimeFormat()));
    }
    return i18n.t('generic.N/A');
}

/**
 * Function returns the formated date and time in selected timezone
 * Input: 1318781876
 * Output: 29-03-2021 12:28pm
 * @param timestamp
 * @returns {string}
 */
function getFormattedDateAndTime(timestamp?: number): TranslateResult {
    if (timestamp && timeZoneIsValid(getTimezoneFormattedString(getTimezone()))) {
        return normalizeTimezone(timestamp).format(`${getDateFormat()} ${timeFormatMap.get(getTimeFormat())}`);
    }
    return i18n.t('generic.N/A');
}

/**
 * Function returns the formated date and time with seconds precision
 * @param timestamp
 * @returns {string}
 */
function getFormattedDateAndTimeWithSecondsPrecision(timestamp: number): TranslateResult {
    if (timestamp && timeZoneIsValid(getTimezoneFormattedString(getTimezone()))) {
        return normalizeTimezone(timestamp).format(`${getDateFormat()} ${timeSecondsFormatMap.get(getTimeFormat())}`);
    }
    return i18n.t('generic.N/A');
}

/**
 * Function returns the formated date and time with milliseconds precision
 * Input: 1664991086654
 * Output:  2022-10-05, 18:31:27:3127
 * @param millisTimestamp
 * @returns {string}
 */
function getFormattedDateAndTimeWithMillisecondPrecisions(millisTimestamp: number): TranslateResult {
    if (millisTimestamp && moment(millisTimestamp).isValid()) {
        const momentObj: Moment = normalizeTimezone(millisTimestamp);
        const formattedTime = momentObj.format('MM-DD-YYYY HH:mm:ss.SSS');
        return formattedTime;
    }
    return i18n.t('generic.N/A');
}

/**
 * Function returns the fromated date and time if timestamp is not equal 21474836479 (which means there is no end time)
 * Input: 1318781876
 * Output: 29-03-2021 12:28pm
 * @param timestamp
 * @returns {string}
 */

function getFormattedEndTimeForOffer(timestamp: number): TranslateResult {
    if (timestamp !== NO_END_TIME_EPOCH && timeZoneIsValid(getTimezoneFormattedString(getTimezone()))) {
        return normalizeTimezone(timestamp * 1000).format(`${getDateFormat()} ${timeFormatMap.get(getTimeFormat())}`);
    }
    return i18n.t('generic.N/A');
}

/**
 * Function returns the formated currecny amount
 * Input: 333, 2
 * Output: $333
 * @param amount
 * @returns {string}
 */
function getFormattedAmount(amount: string | number): string {
    const currency = getCurrency();
    return new Intl.NumberFormat(currencyDigitFormat.get(currencies[currency].format), {
        style: 'currency',
        currency,
    }).format(Number(amount));
}

/**
 * Function returns date with currently selected timezone and preserve local time
 * @param {Date} date
 * @returns {Date}
 */
function toUserConfiguredTimezone(date: Date): Date {
    return moment(date).tz(getTimezoneFormattedString(getTimezone()), true).toDate();
}

/**
 * Function returns date with UTC timezone and preserve local time
 * @param {Date} date
 * @returns {Date}
 */
function toUTC(date: Date): Date {
    return moment(date).utc(true).toDate();
}

/**
 * Function returns timezone offset
 * @returns {seconds}
 */
function userConfiguredTimezoneOffset(date: Date): number {
    return moment(date).tz(getTimezoneFormattedString(getTimezone())).utcOffset();
}

/**
 * Function returns current week number from epoch day
 * @returns {number}
 */
function getCurrentEpochWeek(): number {
    const now = new Date();
    const startOfWeek = new Date(now);

    // Calculate the number of milliseconds in a week
    const millisecondsInWeek = 7 * 24 * 60 * 60 * 1000;

    startOfWeek.setHours(0, 0, 0, 0);
    startOfWeek.setDate(now.getDate() - now.getDay());

    // Calculate the epoch week
    const epochWeek = Math.floor(startOfWeek.getTime() / millisecondsInWeek);
    return epochWeek;
}

/**
 * Converts from ISO 8601 string to milliseconds since epoch
 * @param isoTimestamp eg: "2025-01-29T12:46:24.400Z"
 * @returns milliseconds from epoch if given a valid ISO 8601 string, otherwise undefined
 */
function iso8601ToEpoch(isoTimestamp?: string): number | undefined {
    if (!isoTimestamp) {
        return undefined;
    }
    const date = new Date(isoTimestamp);
    const epoch = date.getTime();
    return isNaN(epoch) ? undefined : epoch;
}

function iso8601ToFormattedDateAndTime(isoTimestamp?: string): TranslateResult | undefined {
    const epoch = iso8601ToEpoch(isoTimestamp);
    return epoch ? getFormattedDateAndTime(epoch) : undefined;
}

export default {
    getTimezoneFormattedString,
    getCurrentTimezoneOffset,
    timeZoneIsValid,
    normalizeTimestamp,
    normalizeTimezone,
    getFormattedDate,
    getFormattedTime,
    getFormattedDateAndTime,
    getFormattedDateAndTimeWithSecondsPrecision,
    getFormattedAmount,
    getFormattedEndTimeForOffer,
    formatDateToUTC,
    getFormattedDateAndTimeWithMillisecondPrecisions,
    toUserConfiguredTimezone,
    toUTC,
    userConfiguredTimezoneOffset,
    getDateFormat,
    getTimeFormat,
    timeFormatMap,
    getCurrentEpochWeek,
    iso8601ToEpoch,
    iso8601ToFormattedDateAndTime,
};
