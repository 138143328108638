
import Vue from 'vue';

// Store
import { mapGetters, mapActions } from 'vuex';
import Actions, { Getters } from '@/store/mutation-types';
import { Modules } from '@/store/store';

// Components
import AbstractListPageWrapper from '@/components/layout/AbstractListPageWrapper.vue';
import FilterTable from '@/components/partials/FilterTable.vue';
import TableFiltersTags from '@/components/filters/TableFiltersTags.vue';
import AppTable from '@/components/partials/AppTable.vue';
import EntityStatusIndicator from '@/components/partials/EntityStatusIndicator.vue';
import EntityOverview from '@/components/partials/entityOverview/EntityOverview.vue';
import OverviewHeader from '@/components/partials/entityOverview/OverviewHeader.vue';
import LanguageSwitcher from '@/components/partials/inputs/LanguageSwitcher.vue';
import OverviewList from '@/components/partials/entityOverview/OverviewList.vue';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import { ICON_TYPES } from '@/common/iconHelper';
import ResponseModalButton from '@/components/partials/ResponseModalButton.vue';
import MutationDialog from '@/components/partials/MutationDialog.vue';
import IconButton from '@/components/partials/IconButton.vue';

// Mixins
import CountControlMixin from '@/components/partials/CountControlMixin.vue';
import FilterTableMixin from '@/components/partials/FilterTableMixin.vue';
import DownloadDetailsMixin from '@/__new__/features/charging/DownloadDetailsMixin.vue';
import ChargingCommonActionsMixin from '@/__new__/features/charging/ChargingCommonActionsMixin.vue';

// Http
import { deleteTariffSpecDraft, updateTariffSpecState } from '@/__new__/services/dno/charging/http/tariffSpecification';

// Helpers
import ENTITY_TYPES from '@/common/entities/entityTypes';
import tableColumnType, { type TableColumn } from '@/common/filterTable';
import {
    getMultiLangFieldValueByLocale,
    getEntityConsumers,
    formatMutationDialogEntities,
} from '@/common/entities/entityHelper';
import { languageMap } from '@/common/locale/language';
import RouteNames from '@/router/routeNames';
import { displayNaIfNotProvided } from '@/common/formatting';
import { TariffSpecification, DetailsSections } from '@/__new__/services/dno/charging/models/ChargingInterfaces';
import permissionsService, { isUserAllowed } from '@/services/permissions/permissions.service';
import { PLURALIZATION } from '@/common/locale/labelSingularOrPlural';
import { upperFirst } from 'lodash';
import * as Sentry from '@sentry/vue';
import { TranslateResult } from 'vue-i18n';
import { TARIFF_SPEC_ZERO_RATE_STRING } from '@/__new__/services/dno/charging/common/tariffSpecHelper';

export default Vue.extend({
    name: 'TariffSpecification',
    components: {
        AbstractListPageWrapper,
        FilterTable,
        TableFiltersTags,
        AppTable,
        EntityStatusIndicator,
        EntityOverview,
        OverviewHeader,
        LanguageSwitcher,
        OverviewList,
        AppButton,
        ResponseModalButton,
        MutationDialog,
        IconButton,
    },
    mixins: [CountControlMixin, FilterTableMixin, DownloadDetailsMixin, ChargingCommonActionsMixin],
    data() {
        return {
            ICON_TYPES,
            BUTTON_TYPES,
            ENTITY_TYPES,
            PLURALIZATION,
            entityType: ENTITY_TYPES.TARIFF_SPECIFICATION as string,
            editorRoute: RouteNames.CHARGING_TARIFF_SPECIFICATION_EDITOR as string,
            isOverviewEnabled: false as boolean,
            searchQueryForTable: '' as string,
            selectedEntityId: null as string | null,
            selectedEntity: null as TariffSpecification | null,
            isDataLoading: false as boolean,
            selectedLanguage: '' as string,
            isMutationModalVisible: false as boolean,
            mutationDialogDataType: '' as string,
            isZeroRate: false as boolean,
        };
    },
    computed: {
        ...mapGetters(Modules.chargingV2, [
            Getters.GET_TARIFF_SPECIFICATIONS,
            Getters.GET_TARIFF_SPECIFICATION_BY_ID,
            Getters.GET_TARIFF_SPECIFICATIONS_API_RESPONSE,
        ]),
        ...mapGetters('operators', [Getters.languageDefault, Getters.languagesWithoutDefault]),
        isEditEnabled(): boolean {
            return this.isZeroRate
                ? permissionsService.chargingTarifSpecificationsZeroRateEnabled() &&
                      isUserAllowed('TariffSpecificationsZeroRateWrite')
                : permissionsService.chargingTarifSpecificationsEnabled() && isUserAllowed('TariffSpecificationsWrite');
        },
        payoutsApiResponse(): any {
            return this[Getters.GET_TARIFF_SPECIFICATIONS_API_RESPONSE];
        },
        tariffSpecifications(): TariffSpecification[] {
            const data = [...this[Getters.GET_TARIFF_SPECIFICATIONS]] || [];
            return data.sort((wc1: TariffSpecification, wc2: TariffSpecification) =>
                (wc1.name as any).localeCompare(wc2.name),
            );
        },
        tableColumnsData(): TableColumn[] {
            const data = [
                {
                    name: this.$i18n.t('generic.name'),
                    key: 'name',
                    field: 'name',
                    forbidHideColumn: true,
                    classes: ['font-weight-bold'],
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('generic.type'),
                    key: 'serviceTypeLabel',
                    field: 'serviceTypeLabel',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('generic.lastUpdatedTime'),
                    key: 'updateTime',
                    field: 'updateTime',
                    filterType: tableColumnType.DATE,
                },
            ];

            if (!this.isZeroRate) {
                data.push({
                    name: this.$i18n.t('generic.version'),
                    key: 'chargingVersion',
                    field: 'chargingVersion',
                    filterType: tableColumnType.GENERAL_TEXT,
                });
            }

            data.push({
                name: this.$i18n.t('generic.state'),
                key: 'entityStatusCode',
                field: 'entityStatusCodeLabel',
                filterType: tableColumnType.TEXT_LIMITED_OPTIONS,
                limitedOptions: [
                    ...new Set(this.sortedFilteredEntities.map((e: TariffSpecification) => e.entityStatusCodeLabel)),
                ],
            });

            return data;
        },
        sortedFilteredEntities(): TariffSpecification[] {
            return this.filteredEntitiesMixin(this.tariffSpecifications);
        },
        detailsSections(): DetailsSections[] {
            if (!this.selectedEntityId) {
                return [];
            }

            const data: DetailsSections[] = [
                {
                    name: this.$i18n.t('generic.general'),
                    properties: [
                        {
                            value: this.selectedEntity.id,
                            label: this.$i18n.t('generic.id'),
                        },
                        {
                            value: displayNaIfNotProvided(this.selectedEntity?.name),
                            label: this.$i18n.t('generic.name'),
                        },
                        {
                            value: displayNaIfNotProvided(this.selectedEntity?.description?.[this.selectedLanguage]),
                            label: this.$i18n.t('generic.description'),
                        },
                        {
                            value: displayNaIfNotProvided(this.selectedEntity?.serviceTypeLabel),
                            label: this.$i18n.t('generic.type'),
                        },
                    ],
                },
            ];

            if (!this.isZeroRate) {
                const { [ENTITY_TYPES.TARIFF_SPECIFICATION_GROUP]: inUseByTsg = [] } = this.upperEntities();

                if (inUseByTsg.length) {
                    const tsgData: DetailsSections = {
                        name: `${upperFirst(
                            this.$i18n.tc('charging.tariffSpec.name', PLURALIZATION.SINGULAR),
                        )} ${this.$i18n.t('generic.usedBy')}`,
                        properties: [
                            {
                                label: `${inUseByTsg.length} ${this.$i18n.tc(
                                    'charging.tariffSpecGroup.name',
                                    PLURALIZATION.PLURAL,
                                )}`,
                                value: inUseByTsg.length,
                                linkLabel: this.$i18n.t('generic.seeDetails'),
                                formatter: 'link',
                                type: ENTITY_TYPES.TARIFF_SPECIFICATION_GROUP,
                            },
                        ],
                    };

                    data.push(tsgData);
                }
            }

            return data;
        },
        pageTitle(): TranslateResult {
            return this.isZeroRate
                ? this.$i18n.tc('charging.tariffSpec.zeroRateName', PLURALIZATION.PLURAL)
                : this.$i18n.tc('charging.tariffSpec.name', PLURALIZATION.PLURAL);
        },
    },
    created() {
        const { type } = this.$route.params;
        this.isZeroRate = type === TARIFF_SPEC_ZERO_RATE_STRING;
        this.initData();
    },
    methods: {
        ...mapActions(Modules.chargingV2, [
            Actions.REQUEST_TARIFF_SPECIFICATIONS,
            Actions.REQUEST_TARIFF_SPECIFICATION_GROUPS,
        ]),
        getMultiLangFieldValueByLocale,
        initData() {
            this.$withLoadingSpinner(
                async () => {
                    this.$Progress.start();
                    this.isDataLoading = true;

                    this.selectedLanguage = (this[Getters.languageDefault] || languageMap.en.key).toString();
                    const promises = [this[Actions.REQUEST_TARIFF_SPECIFICATIONS](this.isZeroRate)];

                    if (!this.isZeroRate) {
                        promises.push(this[Actions.REQUEST_TARIFF_SPECIFICATION_GROUPS]());
                    }

                    await Promise.all(promises);

                    this.$Progress.finish();
                    this.isDataLoading = false;
                },
                {
                    errorHandler: (e: any) => {
                        this.isDataLoading = false;
                        this.$Progress.fail();

                        Sentry.captureException(e);
                        this.$eventBus.$emit('showAlert', {
                            message: e.message,
                        });
                    },
                },
            );
        },
        setSearchQuery(query: string): void {
            this.searchQueryForTable = query;
        },
        onEntitySelected(entityId: string): void {
            this.selectedEntityId = entityId;
            this.selectedEntity = this[Getters.GET_TARIFF_SPECIFICATION_BY_ID](entityId);
            this.isOverviewEnabled = true;
        },
        newTariffSpec(): void {
            this.$router.push({
                name: RouteNames.CHARGING_TARIFF_SPECIFICATION_EDITOR,
                params: {
                    type: this.isZeroRate ? TARIFF_SPEC_ZERO_RATE_STRING : null,
                    companyId: this.$route.params.companyId,
                },
            });
        },
        getAffectedEntities(mutationDialogDataType: string): object {
            return formatMutationDialogEntities(this.upperEntities(), mutationDialogDataType);
        },
        showMutationDialog(type: string): void {
            this.isMutationModalVisible = true;
            this.mutationDialogDataType = type;
        },
        clearMutationDialogDataType(value: string): void {
            if (!value) {
                this.mutationDialogDataType = '';
            }
        },
        upperEntities(entityId: string = this.selectedEntityId): object {
            return getEntityConsumers(entityId, this.entityType);
        },
        onEdit(entityId: string): void {
            this.onEditAction(entityId, this.editorRoute, {
                type: this.isZeroRate ? TARIFF_SPEC_ZERO_RATE_STRING : null,
            });
        },
        onClone(entityId: string): void {
            this.onCloneAction(entityId, this.editorRoute, {
                type: this.isZeroRate ? TARIFF_SPEC_ZERO_RATE_STRING : null,
            });
        },
        onDelete(entityId: string): void {
            const { state, version } = this[Getters.GET_TARIFF_SPECIFICATION_BY_ID](entityId);

            let inUseByTsg = [];
            if (!this.isZeroRate) {
                const { [ENTITY_TYPES.TARIFF_SPECIFICATION_GROUP]: inUseByTsgData = [] } = this.upperEntities(entityId);
                inUseByTsg = inUseByTsgData;
            }

            this.showDeleteAlert(entityId, inUseByTsg.length, state, version, [
                updateTariffSpecState,
                deleteTariffSpecDraft,
                this.initData,
            ]);
        },
        onReadOnly(entityId: string): void {
            this.onReadOnlyAction(entityId, this.editorRoute, {
                type: this.isZeroRate ? TARIFF_SPEC_ZERO_RATE_STRING : null,
            });
        },
    },
});
