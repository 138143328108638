import { debounce } from 'lodash';
import HTTP from '@/http';
import { SECOND } from '@/common/cepHelper';

export default {};

const filtersRoutePrefix = 'ceapi/cep-site-backend/filters';

export const getFilterDefinitions = () => HTTP.get(`/${filtersRoutePrefix}/table/definitions`);

export const getSegments = () => HTTP.post(`ceapi/staticfilter/api/v3/segmentconfig/get_all`);

export const deleteSegment = ({ id, version, segmentType }) =>
    HTTP.post(`ceapi/staticfilter/api/v3/segmentconfig/delete`, {
        id,
        version,
        segmentType,
    });

export const updateOperationalStatus = ({ id, operation }) =>
    HTTP.post(`ceapi/staticfilter/api/v3/segmentconfig/update_operational_status`, {
        id,
        operation,
    });

export const createSegment = segment => HTTP.post('ceapi/staticfilter/api/v3/segmentconfig/add', segment.buildJson());

export const updateSegment = segment =>
    HTTP.post('ceapi/staticfilter/api/v3/segmentconfig/update', segment.buildJson());

/* id: string, diff: { add_entries: string[], delete_entries: string[] } */
// temporary is not used
export function updateSegmentEntries(id, diff) {
    return HTTP.post('/ceapi/segment/api/v3/segments/update_static_filter_entries', { id, ...diff });
}

export const getSegment = id => HTTP.post(`ceapi/staticfilter/api/v3/segmentconfig/get`, { id });

export function preapplyFilters(filters, thenHandler, catchHandler, idType) {
    HTTP.post(`/${filtersRoutePrefix}/preapply`, filters, {
        headers: {
            'id-type': idType,
        },
    })
        .then(thenHandler)
        .catch(catchHandler);
}

export const debouncedPreapplyFilters = debounce(preapplyFilters, SECOND, {
    leading: false,
    trailing: true,
    maxWait: 10 * SECOND,
});

export function getCohortExpressionCount(cohortExpressionObj) {
    return HTTP.post('/cepdew/segment/api/v3/segments/get_cohort_expression_count', {
        cohort_expression: cohortExpressionObj,
    });
}

export function getRegisteredIdsCount(idType) {
    return HTTP.post('/cepdew/segment/api/v3/segments/get_registered_ids_count', {
        id_type: idType,
    });
}

export function getSegmentData(id) {
    return HTTP.post('/cepdew/segment/api/v3/segments/stream_segment_data', {
        data: {
            id: id,
            page_size: 1000,
        },
    });
}
