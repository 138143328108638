<template>
    <AppInputV3
        :key="inputType"
        :type="inputType"
        :value="value"
        :invalid="invalid && displayError"
        :disabled="disabled"
        allowEmptyValueAsNull
        @input="emitUpdate"
    />
</template>

<script>
import AppInputV3 from '@/components/partials/inputs/AppInputV3.vue';
import { ARGUMENT_VALUE_TYPES } from '@/common/conditions-tree';

export default {
    name: 'ConditionArgInput',
    components: {
        AppInputV3,
    },
    props: {
        value: {
            type: [String, Number],
            default: null,
        },
        displayError: {
            type: Boolean,
            default: false,
        },
        config: {
            type: Object,
            default: () => ({}),
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        inputType() {
            return this.config.type || ARGUMENT_VALUE_TYPES.TEXT;
        },
        invalid() {
            return !this.value && this.value !== 0;
        },
    },
    watch: {
        invalid: {
            immediate: true,
            handler(value) {
                this.$emit('invalid', value);
            },
        },
    },
    methods: {
        emitUpdate(value) {
            this.$emit('input', value);
        },
    },
};
</script>
