<template>
    <div class="entity-header">
        <div class="d-flex pb-2">
            <div
                v-if="showIcon"
                :class="['icon', { empty: !iconSrc }]"
            >
                <img
                    v-if="iconSrc"
                    :src="iconSrc"
                    :alt="entityName"
                />
            </div>

            <div class="name-section">
                <div class="entity-name">
                    {{ entityName }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { entityTypeDisplayName } from '@/common/utils';

export default {
    name: 'OverviewHeader',
    props: {
        entityName: {
            required: true,
            type: String,
        },
        entityType: {
            required: true,
            type: String,
        },
        showIcon: {
            type: Boolean,
            default: false,
        },
        iconSrc: {
            type: String,
            default: null,
        },
    },
    computed: {
        entityNameFormatted() {
            return entityTypeDisplayName(this.entityType);
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/_colors';
@import '~@/assets/scss/_palette';
@import '~@/assets/scss/_icons';
@import '~@/assets/scss/_outlines';

$icon-path: '../../../assets/icons/';

.entity-header {
    padding: 0.5rem 0 0;

    .entity-type {
        line-height: 2;
        font-size: 14px;
        color: $gray5;
        text-transform: uppercase;
    }

    .entity-name {
        color: $navy;
        font-size: 18px;
        font-weight: bold;
    }

    .entity-type-basic {
        line-height: 2;
        font-size: 0.812rem;
        font-weight: 600;
        color: $gray90;
    }

    .entity-name-basic {
        color: $gray90;
    }
}

.icon {
    height: 56px;
    width: 56px;
    margin-right: 12px;
    background-image: url($icon-path + $camera);
    background-position: center;
    background-repeat: no-repeat;

    &.empty {
        background-color: $white;
    }

    img {
        height: 56px;
        width: 56px;
    }
}
</style>
