import { TranslateResult } from 'vue-i18n';
import { checkForUnpublishedChanges, STATUS_CODES } from '@/common/commonHelper';
import localeLibrary from '@/common/locale/localeLibrary';
import { getMultiLangFieldValueByLocale } from '@/common/entities/entityHelper';
import { STATUS_CODE_TO_STATUS_NAME_MAP } from '@/common/commonHelper';
import { EntityActions } from '@/common/baseStatesHelper';
import { isUserAllowed } from '@/services/permissions/permissions.service';

export interface EntityBaseConstructorArgs {
    id: string;
    state: number;
    version: number;
    update_time: number;
    approve_on_create?: boolean;
    name: string;
    description?: string;
    remark?: string;
    charging_version?: string;
    update_portal_id?: string;
    portal_id?: string;
}

export interface EntityBaseDraftConstructorArgs extends EntityBaseConstructorArgs {
    operator_name: string;
    type: string;
    update_portal_id: string;
    portal_id?: string;
    data?: EntityBaseConstructorArgs;
}

export class EntityBaseModel {
    id: string;
    state: number;
    version: number;
    name: string | Record<string, string>;
    description: string | Record<string, string>;
    updateTime: string | TranslateResult;
    update_time: number;
    formattedDate: string | TranslateResult;
    approveOnCreate: boolean;
    remark?: string;
    entityStatusCode: STATUS_CODES;
    entityStatusCodeLabel: TranslateResult | null;
    chargingVersion: string | null;
    updatePortalId?: string | null;
    allowedActionsExternal: EntityActions[];

    constructor(
        data: EntityBaseConstructorArgs,
        draftEntities: any[],
        allowedActionsExternal = [
            EntityActions.CLONE,
            ...(isUserAllowed('ChargingDeleteEntities') ? [EntityActions.DELETE] : []),
            EntityActions.EDIT,
            EntityActions.READ_ONLY,
        ],
    ) {
        this.id = data.id;
        this.state = data.state;
        this.version = data.version;
        this.updateTime = localeLibrary.getFormattedDate(data.update_time);
        this.update_time = data.update_time;
        this.formattedDate = this.updateTime;
        this.name = getMultiLangFieldValueByLocale(data.name);
        this.description = getMultiLangFieldValueByLocale(data.description);
        this.approveOnCreate = data.approve_on_create ?? false;
        this.remark = data.remark || '';
        this.entityStatusCode = checkForUnpublishedChanges(this, draftEntities);
        this.entityStatusCodeLabel = STATUS_CODE_TO_STATUS_NAME_MAP.get(this.entityStatusCode) || null;
        this.chargingVersion = data.charging_version || null;
        this.updatePortalId = data.update_portal_id || data.portal_id;
        this.allowedActionsExternal = allowedActionsExternal;
    }
}

export class EntityBaseDraftModel {
    id: string;
    state: number;
    version: number;
    updateTime: number;
    update_time?: number;
    formattedDate: string | TranslateResult;
    name: string | Record<string, string>;
    description: string | Record<string, string>;
    approveOnCreate?: boolean;
    remark?: string;
    updatePortalId?: string | null;
    entityStatusCode: STATUS_CODES;
    entityStatusCodeLabel: TranslateResult | null;
    isDraft?: boolean;
    chargingVersion?: string | null;
    operatorName: string;
    type: string;
    allowedActionsExternal: EntityActions[];

    constructor(
        data: EntityBaseDraftConstructorArgs,
        allowedActionsExternal = [EntityActions.DELETE, EntityActions.EDIT],
    ) {
        this.id = data.id;
        this.state = data.state || STATUS_CODES.NA;
        this.version = data.version;
        this.updateTime = data.update_time;
        this.update_time = data.update_time;
        this.formattedDate = localeLibrary.getFormattedDate(this.updateTime);
        this.name = getMultiLangFieldValueByLocale(data.name);
        this.description = getMultiLangFieldValueByLocale(data.description);
        this.approveOnCreate = data.approve_on_create ?? false;
        this.remark = data.remark || '';
        this.updatePortalId = data.update_portal_id || data.portal_id;
        this.entityStatusCode = STATUS_CODES.DRAFT;
        this.entityStatusCodeLabel = STATUS_CODE_TO_STATUS_NAME_MAP.get(this.entityStatusCode) || null;
        this.isDraft = true;
        this.chargingVersion = data.charging_version || null;
        this.operatorName = data.operator_name;
        this.type = data.type;
        this.allowedActionsExternal = allowedActionsExternal;
    }
}
