
// COMPONENTS
import AppLabel from '@/components/partials/AppLabel.vue';

// HELPERS
import {
    TEMPLATE_STATE_TO_STRING_MAP,
    TEMPLATE_STATE_TO_COLOR_MAP,
} from '@/__new__/services/dno/pc/models/templateStates';

export default {
    name: 'PCTemplatesStatusIndicator',
    components: {
        AppLabel,
    },
    props: {
        status: {
            required: true,
            type: [String, Number],
        },
    },
    computed: {
        statusConverter(): string {
            if (typeof this.status === 'number') {
                const result = TEMPLATE_STATE_TO_STRING_MAP.get(this.status);
                return result || '';
            }
            return this.status;
        },
        colorConverter(): string | undefined {
            return TEMPLATE_STATE_TO_COLOR_MAP.get(this.status);
        },
    },
};
