import { AxiosPromise } from 'axios';

import httpClient from '@/http/index';
import Locale from '@/models/Locale';
import Preferences from '@/models/Preferences';

/**
 * GET user preferences.
 * @returns {Promise<void>}
 */
export function getUserPreferences(): AxiosPromise<Preferences> {
    return httpClient({
        method: 'GET',
        url: '/profile/getpreferences',
    });
}

/**
 * POST user preferences.
 * @returns {Promise<void>}
 * @param data
 */
export function setUserPreferences(preferences: Preferences): AxiosPromise<void> {
    return httpClient({
        method: 'POST',
        url: '/profile/savepreferences',
        data: preferences,
    });
}

/**
 * GET operator preferences.
 * @returns {Promise<void>}
 */
export function getTenantPreferences(): AxiosPromise<Preferences> {
    return httpClient({
        method: 'GET',
        url: '/admin/getpreferences',
    });
}

/**
 * POST operator preferences.
 * @returns {Promise<void>}
 * @param data
 */
export function setTenantPreferences(preferences: Preferences): AxiosPromise<void> {
    return httpClient({
        method: 'POST',
        url: '/admin/savepreferences',
        data: preferences,
    });
}

/**
 * GET general preferences and localization.
 * @returns {Promise<void>}
 */
export function preferences(): AxiosPromise<{ locale: Locale; preferences: Preferences }> {
    return httpClient({
        method: 'GET',
        url: '/profile/preferences',
        headers: {
            'X-Requested-With': 'XMLHttpRequest',
        },
    });
}

export default {
    getUserPreferences,
    setUserPreferences,
    getTenantPreferences,
    setTenantPreferences,
};
