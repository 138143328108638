<template>
    <div class="zone-block flexbox-column">
        <div class="d-flex row">
            <div class="col-12">
                <div class="condition-title">
                    {{ $i18n.t('charging.chargingSpecifications.editor.conditionText.otherPartyNumberLength') }}
                </div>
                <div class="row">
                    <div class="col-6">
                        <AppMultiselectV3
                            v-model="otherPartyNumberLength.operator"
                            :allowEmpty="false"
                            :options="options"
                            :searchable="false"
                            :showLabels="false"
                            :borderNone="true"
                            :blueArrow="true"
                            data-test-id="other-party-number-length-operator-multiselect"
                            class="multiselect small condition-options"
                            label="label"
                            trackBy="id"
                        />
                    </div>
                    <div class="col-6">
                        <AppInputV3
                            v-model="otherPartyNumberLength.value"
                            data-test-id="other-party-number-length-value-input"
                            :placeholder="$i18n.t('charging.chargingSpecifications.editor.inputNumbers')"
                            type="number"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// Components
import AppMultiselectV3 from '@/components/partials/inputs/AppMultiselectV3.vue';
import AppInputV3 from '@/components/partials/inputs/AppInputV3.vue';

// Helpers
import OtherPartyNumberLength from '@/__new__/services/dno/charging/models/chargingSpecificationConditions/OtherPartyNumberLength';
import i18n from '@/i18n';

export const CONDITIONS_ENUM = {
    IS: 'is',
    IS_NOT: 'isNot',
    IN: 'in',
    NOT_IN: 'notIn',
};

export const CONDITIONS_MAP = {
    [CONDITIONS_ENUM.IS]: {
        id: CONDITIONS_ENUM.IS,
        label: i18n.t('charging.chargingSpecifications.editor.IS'),
    },
    [CONDITIONS_ENUM.IS_NOT]: {
        id: CONDITIONS_ENUM.IS_NOT,
        label: i18n.t('charging.chargingSpecifications.editor.ISNOT'),
    },
    [CONDITIONS_ENUM.IN]: {
        id: CONDITIONS_ENUM.IN,
        label: i18n.t('charging.chargingSpecifications.editor.IN'),
    },
    [CONDITIONS_ENUM.NOT_IN]: {
        id: CONDITIONS_ENUM.NOT_IN,
        label: i18n.t('charging.chargingSpecifications.editor.NOTIN'),
    },
};

const NUMBER_LENGTH_OPERATORS_ENUM = {
    GREATER: '>',
    GREATER_EQUAL: '>=',
    LESS: '<',
    LESS_EQUAL: '<=',
    EQUAL: '==',
    APPROXIMATELY_EQUAL: '~=',
};

const NUMBER_LENGTH_OPERATORS_MAP = {
    [NUMBER_LENGTH_OPERATORS_ENUM.GREATER]: {
        id: NUMBER_LENGTH_OPERATORS_ENUM.GREATER,
        label: i18n.t('conditionsTree.operation.greaterThan'),
    },
    [NUMBER_LENGTH_OPERATORS_ENUM.GREATER_EQUAL]: {
        id: NUMBER_LENGTH_OPERATORS_ENUM.GREATER_EQUAL,
        label: i18n.t('conditionsTree.operation.greaterOrEqual'),
    },
    [NUMBER_LENGTH_OPERATORS_ENUM.LESS]: {
        id: NUMBER_LENGTH_OPERATORS_ENUM.LESS,
        label: i18n.t('conditionsTree.operation.lessThan'),
    },
    [NUMBER_LENGTH_OPERATORS_ENUM.LESS_EQUAL]: {
        id: NUMBER_LENGTH_OPERATORS_ENUM.LESS_EQUAL,
        label: i18n.t('conditionsTree.operation.lessOrEqual'),
    },
    [NUMBER_LENGTH_OPERATORS_ENUM.EQUAL]: {
        id: NUMBER_LENGTH_OPERATORS_ENUM.EQUAL,
        label: i18n.t('conditionsTree.operation.equal'),
    },
    [NUMBER_LENGTH_OPERATORS_ENUM.APPROXIMATELY_EQUAL]: {
        id: NUMBER_LENGTH_OPERATORS_ENUM.APPROXIMATELY_EQUAL,
        label: i18n.t('conditionsTree.operation.approximatelyEqual'),
    },
};

const NUMBER_LENGTH_OPERATORS_OPTIONS = Object.values(NUMBER_LENGTH_OPERATORS_MAP);

export default {
    name: 'OtherPartyNumberLengthCondition',
    components: {
        AppMultiselectV3,
        AppInputV3,
    },
    props: {
        value: {
            type: Object,
            default: null,
        },
    },
    data() {
        return {
            otherPartyNumberLength: {
                value: '',
                operator: NUMBER_LENGTH_OPERATORS_MAP[NUMBER_LENGTH_OPERATORS_ENUM.GREATER],
            },
            otherPartyNumberLengthDataFormatted: new OtherPartyNumberLength(this.value),
            options: NUMBER_LENGTH_OPERATORS_OPTIONS,
        };
    },
    watch: {
        otherPartyNumberLength: {
            handler(newVal) {
                let operator;
                const { value } = newVal;

                if (newVal?.operator?.id) {
                    operator = newVal.operator.id;
                }

                this.otherPartyNumberLengthDataFormatted.data = {
                    operator,
                    value,
                };

                this.otherPartyNumberLengthDataFormatted.dataEmpty = false;
                this.emitData();
            },
            deep: true,
        },
    },
    mounted() {
        if (this.value && this.value.data) {
            this.otherPartyNumberLength.operator = this.options.find(
                option => NUMBER_LENGTH_OPERATORS_MAP[option.id].id === this.value.data.operator,
            );
            this.otherPartyNumberLength.value = this.value.data.value;
        }
    },
    methods: {
        emitData() {
            this.otherPartyNumberLengthDataFormatted.isDataValid();
            this.$emit('input', this.otherPartyNumberLengthDataFormatted);
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/_palette.scss';

.condition-title {
    font-size: 0.75rem;
    font-weight: 600;
    line-height: 2.17;
    color: $gray90;
    margin-bottom: 0.25rem;
}

.condition-options {
    min-width: 80px;
    width: max-content;
}
</style>
