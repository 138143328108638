<template>
    <div>
        <div class="section">
            <div class="section-title">
                {{ $i18n.t('prizeEngine.editor.generalParameters') }}
            </div>
            <div class="sub-section">
                <div class="row no-gutters">
                    <AppMultiselectV3
                        v-model="value.prizeType"
                        :options="prizeTypeOptions"
                        :additionalLabel="$i18n.t('prizeEngine.editor.prizeType')"
                        :placeholder="$i18n.t('generic.type')"
                        :small="true"
                        :allowEmpty="false"
                        :disabled="disabled"
                        class="col-3 min-width"
                        label="label"
                        trackBy="id"
                    />
                </div>
            </div>
            <div class="sub-section">
                <div class="d-flex default-prize-height">
                    <div class="sub-section-title align-self-center">
                        {{ $i18n.t('prizeEngine.editor.defaultPrize') }}
                    </div>
                    <AppToggle
                        v-model="value.defaultPrize"
                        :small="true"
                        :disabled="disabled"
                        class="group-checkbox"
                    />
                </div>
                <span class="explanation">
                    {{ $i18n.t('prizeEngine.editor.defaultPrizeRequired') }}
                </span>
            </div>
            <div
                v-if="value.prizeType && value.prizeType.id !== PRIZE_ENGINE_PRIZE_TYPE_OPTIONS.noWin.id"
                ref="prizeSelection"
                class="sub-section"
            >
                <div class="row no-gutters">
                    <AppMultiselectV3
                        v-model="value.prize"
                        :options="prizes"
                        :additionalLabel="$i18n.t('prizeEngine.editor.prize')"
                        :placeholder="$i18n.t('prizeEngine.editor.prize')"
                        :showLabels="false"
                        :small="true"
                        :allowEmpty="false"
                        :disabled="disabled"
                        class="col-3 min-width"
                        label="name"
                        trackBy="id"
                    />
                </div>
            </div>
            <div
                v-if="!isPrizeEngineTypeGeneric"
                class="sub-section"
                data-test-id="prize-position-section"
            >
                <div class="sub-section-title">
                    {{ $i18n.t('prizeEngine.editor.prizePosition') }}
                </div>
                <div class="d-flex align-items-center">
                    <div class="inline-text">
                        {{ $i18n.t('prizeEngine.editor.prizeSliceStarts') }}
                    </div>
                    <AppInputV3
                        v-model.number="value.startAngle"
                        :min="0"
                        :max="360"
                        :disabled="disabled"
                        type="number"
                        class="col-1"
                        name="start-angle"
                        placeholder="0°"
                    />
                    <div class="inline-text ml-2">
                        {{ $i18n.t('prizeEngine.editor.endsAt') }}
                    </div>
                    <AppInputV3
                        v-model.number="value.endAngle"
                        :min="0"
                        :max="360"
                        :disabled="disabled"
                        type="number"
                        class="col-1"
                        name="end-angle"
                        placeholder="0°"
                    />
                </div>
                <span class="explanation">
                    {{ $i18n.t('prizeEngine.editor.degreeExplanation') }}
                </span>
            </div>
            <div class="sub-section">
                <div class="row no-gutters">
                    <AppInputV3
                        v-model.number="value.probability"
                        :placeholder="$i18n.t('prizeEngine.editor.addPercent')"
                        :label="$i18n.t('prizeEngine.editor.probabilityPercent')"
                        :disabled="disabled"
                        type="number"
                        class="col-1 pb-1 min-width"
                    />
                </div>
                <div>
                    <span class="explanation">
                        {{ $i18n.t('prizeEngine.editor.probabilityPercentExplanation') }}
                    </span>
                </div>
                <div>
                    <span
                        data-test-id="probability-state"
                        class="explanation"
                        :class="{ error: invalidProbability }"
                    >
                        {{ $i18n.t('prizeEngine.editor.probabilitySumShouldBe100') }}
                        {{ $i18n.t('prizeEngine.editor.currentSum') }}: {{ prizesTotalProbability }}.
                    </span>
                </div>
            </div>
        </div>
        <div class="section">
            <div
                :class="{ 'mb-2': !value.redeemingPrize }"
                class="section-title"
            >
                <div class="d-flex">
                    {{ $i18n.t('prizeEngine.editor.successfulRedeemingPrize') }}
                    <AppToggle
                        v-model="value.redeemingPrize"
                        class="group-checkbox redeeming-prize-toggle-position"
                        :disabled="disabled"
                    />
                </div>
                <span class="explanation">
                    {{ $i18n.t('prizeEngine.editor.redeemingPrizeExplanation') }}
                </span>
            </div>
            <template v-if="value.redeemingPrize">
                <div class="sub-section">
                    <div class="row no-gutters">
                        <AppInputV3
                            v-model.number="value.successMessage"
                            :placeholder="$i18n.t('prizeEngine.editor.addMessage')"
                            :label="$i18n.t('prizeEngine.editor.successMessage')"
                            :disabled="disabled"
                            class="col-6 pb-1 min-width"
                        />
                    </div>
                </div>
                <div class="sub-section">
                    <div class="row no-gutters">
                        <AppInputV3
                            v-model.number="value.successImage"
                            :placeholder="$i18n.t('prizeEngine.editor.addUrl')"
                            :label="$i18n.t('prizeEngine.editor.successImage')"
                            :optional="true"
                            :disabled="disabled"
                            class="col-6 pb-1 min-width"
                        />
                    </div>
                </div>
                <div class="sub-section-title mb-0">
                    {{ $i18n.t('prizeEngine.editor.ctaButton') }}
                </div>
                <div class="d-flex align-items-center">
                    <div class="inline-text">
                        {{ $i18n.t('prizeEngine.editor.ctaButtonText') }}
                    </div>
                    <AppInputV3
                        v-model="value.ctaButtonText"
                        :placeholder="$i18n.t('prizeEngine.editor.addText')"
                        :disabled="disabled"
                        type="string"
                        class="col-2"
                        name="button-text"
                    />
                    <div class="inline-text ml-2">
                        {{ $i18n.t('prizeEngine.editor.ctaButtonLeads') }}
                    </div>
                    <AppInputV3
                        v-model="value.ctaButtonLeads"
                        :placeholder="$i18n.t('prizeEngine.editor.addUrl')"
                        :optional="true"
                        :optionalNoLabel="true"
                        :disabled="disabled"
                        type="string"
                        class="col-3"
                        name="button-leads"
                    />
                </div>
            </template>
        </div>
    </div>
</template>

<script>
// Generic Vue Components
import AppInputV3 from '@/components/partials/inputs/AppInputV3.vue';
import AppMultiselectV3 from '@/components/partials/inputs/AppMultiselectV3.vue';
import AppToggle from '@/components/partials/inputs/AppToggle.vue';

// Helpers
import { PRIZE_ENGINE_PRIZE_TYPE_OPTIONS } from '@/modules/rewards/common/RewardsHelper';

export default {
    name: 'PrizeParametersContent',

    components: {
        AppInputV3,
        AppMultiselectV3,
        AppToggle,
    },

    props: {
        value: {
            type: Object,
            default: () => undefined,
        },
        prizes: {
            type: Array,
            default: () => [],
        },
        prizesTotalProbability: {
            type: Number,
            default: 0,
        },
        invalidProbability: {
            type: Boolean,
            default: false,
        },
        isPrizeEngineTypeGeneric: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            PRIZE_ENGINE_PRIZE_TYPE_OPTIONS,
        };
    },

    computed: {
        prizeTypeOptions() {
            return Object.values(this.PRIZE_ENGINE_PRIZE_TYPE_OPTIONS).map(opt => ({
                ...opt,
                label: this.$i18n.t(opt.i18nLabel),
            }));
        },
    },
};
</script>

<style lang="scss" scoped>
@import '../../../assets/scss/editor-layout-v2';

.position-optional-row {
    margin-top: -10px;
}

.default-prize-height {
    height: 16px;
}

.redeeming-prize-toggle-position {
    top: 3px;
    position: relative;
}

.content-height {
    height: 36px;
}
</style>
