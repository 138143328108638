import i18n from '@/i18n';
import { TranslateResult } from 'vue-i18n';
import { LABEL_COLOR } from '@/common/labelsHelper';

export enum DELIVERY_STATES {
    CREATED = 0,
    IN_TRANSIT = 1,
    DELIVERED = 2,
    UNSUCCESSFUL = 3,
    IN_TRANSIT_AFTER_UNSUCCESSFUL = 4,
    RETURNED = 5,
    UNKNOWN = 6,
    CANCELLED = 7,
    REJECTED = 8,
    DELAYED = 9,
    CONFIGURED = 10,
}

export const DELIVERY_STATUS_INDICATOR_MAP: Map<DELIVERY_STATES, string | undefined> = new Map([
    [DELIVERY_STATES.CREATED, LABEL_COLOR.blue],
    [DELIVERY_STATES.IN_TRANSIT, LABEL_COLOR.yellow],
    [DELIVERY_STATES.DELIVERED, LABEL_COLOR.green],
    [DELIVERY_STATES.UNSUCCESSFUL, LABEL_COLOR.red],
    [DELIVERY_STATES.IN_TRANSIT_AFTER_UNSUCCESSFUL, LABEL_COLOR.yellow],
    [DELIVERY_STATES.RETURNED, LABEL_COLOR.red],
    [DELIVERY_STATES.UNKNOWN, LABEL_COLOR.gray],
    [DELIVERY_STATES.CANCELLED, LABEL_COLOR.red],
    [DELIVERY_STATES.REJECTED, LABEL_COLOR.red],
    [DELIVERY_STATES.DELAYED, LABEL_COLOR.yellow],
    [DELIVERY_STATES.CONFIGURED, LABEL_COLOR.green],
]);

export const DELIVERY_STATES_TO_STATUS_NAME_MAP: Map<DELIVERY_STATES, TranslateResult> = new Map([
    [DELIVERY_STATES.CREATED, i18n.t('customerCare.deliveries.stateMap.created')],
    [DELIVERY_STATES.IN_TRANSIT, i18n.t('customerCare.deliveries.stateMap.inTransit')],
    [DELIVERY_STATES.DELIVERED, i18n.t('customerCare.deliveries.stateMap.delivered')],
    [DELIVERY_STATES.UNSUCCESSFUL, i18n.t('customerCare.deliveries.stateMap.unsuccessful')],
    [
        DELIVERY_STATES.IN_TRANSIT_AFTER_UNSUCCESSFUL,
        i18n.t('customerCare.deliveries.stateMap.inTransitAfterUnsuccessful'),
    ],
    [DELIVERY_STATES.RETURNED, i18n.t('customerCare.deliveries.stateMap.returned')],
    [DELIVERY_STATES.UNKNOWN, i18n.t('customerCare.deliveries.stateMap.unknown')],
    [DELIVERY_STATES.CANCELLED, i18n.t('customerCare.deliveries.stateMap.canceled')],
    [DELIVERY_STATES.REJECTED, i18n.t('customerCare.deliveries.stateMap.rejected')],
    [DELIVERY_STATES.DELAYED, i18n.t('customerCare.deliveries.stateMap.delayed')],
    [DELIVERY_STATES.CONFIGURED, i18n.t('customerCare.deliveries.stateMap.configured')],
]);
