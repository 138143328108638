import { EXECUTION_ID_TO_STATUS } from '@/__new__/features/orchestrationengine/common/executionStatusIndicatorHelper';
import localeLibrary from '@/common/locale/localeLibrary';
import { TranslateResult } from 'vue-i18n';

/* eslint-disable camelcase */
export class MappedExecutions {
    executionId: string;
    planId: string;
    startedAt: number;
    startedAtFormatted: string | TranslateResult;
    finishedAt: number;
    finishedAtFormatted: string | TranslateResult;
    status: string;
    scheduledAt: number;
    scheduledAtFormatted: string | TranslateResult;
    scheduledFor: number;
    lastState: string;
    scheduledForFormatted: string | TranslateResult;

    constructor(transactionJSON: any) {
        this.executionId = transactionJSON?.execution_id;
        this.planId = transactionJSON?.plan_id || '';
        this.startedAt = transactionJSON?.details?.started_at || '';
        this.startedAtFormatted = localeLibrary.getFormattedDateAndTimeWithSecondsPrecision(this.startedAt);
        this.finishedAt = transactionJSON?.details?.finished_at || '';
        this.finishedAtFormatted = localeLibrary.getFormattedDateAndTimeWithSecondsPrecision(this.finishedAt);
        this.status = transactionJSON?.details?.status ? EXECUTION_ID_TO_STATUS[transactionJSON.details.status] : '';
        this.scheduledAt = transactionJSON?.details?.scheduled_at || '';
        this.scheduledAtFormatted = localeLibrary.getFormattedDateAndTimeWithSecondsPrecision(this.scheduledAt);
        this.scheduledFor = transactionJSON?.details?.scheduled_for || '';
        this.lastState = transactionJSON?.details?.last_state || '';
        this.scheduledForFormatted = localeLibrary.getFormattedDateAndTimeWithSecondsPrecision(this.scheduledFor);
    }
}

export default MappedExecutions;
