//

import axios from '@/http/index';

/**
 * Update Portal user profile.
 * @returns {Promise<void>}
 * @param data
 */
function updateProfile(data) {
    const request = {
        method: 'POST',
        url: '/profile/updateprofile',
        headers: {
            'content-type': 'application/json',
        },
        data,
    };

    return axios(request);
}

function getPermissions() {
    const request = {
        method: 'GET',
        url: '/profile/permissions',
        headers: {
            'content-type': 'application/json',
        },
    };
    return axios(request);
}

/**
 * Get user name.
 * @returns {AxiosResponse<String>}
 */
export function getUserNameById(id) {
    const request = {
        method: 'POST',
        url: '/profile/getusernamebyid',
        headers: {
            'content-type': 'application/json',
        },
        data: {
            id,
        },
    };
    return axios(request);
}

export default {
    getPermissions,
    updateProfile,
};
