import {
    ACTION,
    SEARCH_INDEX,
    STATUS,
    CustomerLine as CustomerLineDno,
    QueryMsisdnResponse,
    RegisteredEntityItem,
} from '@/__new__/services/dno/ossdevedge/models/QodMsisdnDno';
import {
    CustomerLine as CustomerLinePortal,
    COLUMN_TYPE_TO_COLUMN_DATA,
    COLUMN_DATA,
    API_TYPE_TO_CONFIG,
    API_TYPE_CONFIG,
} from '@/__new__/services/dno/ossdevedge/models/QodMsisdnPortal';
import {
    FILE_UPLOAD_STATUS,
    fileUploadStatusToStr,
    getFileUploadStatus,
    type UploadedFileDetails,
} from '@/common/fileUploadHelper';
import { AxiosResponse } from 'axios';
import {
    getEntityDetailsByName,
    getRegisteredEntities,
    queryMsisdn,
} from '@/__new__/services/dno/developerLineAuthorization/http/developerLineAuthorization';
import i18n from '@/i18n';
import { DEVICE_LINE_AUTH_API_TYPE } from '../ossdevedge/models/DeveloperLineAuthorizationDno';
import tableColumnType, { type TableColumn } from '@/common/filterTable';
import * as Sentry from '@sentry/vue';

export interface CustomerLineEntitiesAndResponse {
    entities: CustomerLinePortal[];
    response: AxiosResponse<QueryMsisdnResponse>;
}

export const getCustomerLines = async (
    apiType: DEVICE_LINE_AUTH_API_TYPE,
    searchIndex: SEARCH_INDEX,
    searchString: string,
): Promise<CustomerLineEntitiesAndResponse> => {
    const response = await queryMsisdn(apiType, searchIndex, searchString);
    const entities: CustomerLinePortal[] = [];
    const entitiesDno: CustomerLineDno[] = response.data.customer_lines || [];
    let conversionErrorOccurred = false; // indicates atleast 1 conversion failed
    for (const customerLine of entitiesDno) {
        try {
            const customerLinePortal = convertCustomerLineDnoToPortal(customerLine);
            entities.push(customerLinePortal);
        } catch {
            conversionErrorOccurred = true;
        }
    }
    if (conversionErrorOccurred) {
        Sentry.captureException(
            new Error(`Failed to process some customer lines. Initial request id: ${response.data.request_id}`),
        );
    }
    return {
        entities,
        response,
    };
};

/**
 * Utility function to convert customer line data from DNO to Portal
 * @param record
 * @returns
 */
const convertCustomerLineDnoToPortal = (record: CustomerLineDno): CustomerLinePortal => {
    return {
        msisdn: record.msisdn,
        appClientId: record.app_client_id,
        channel: record.channel,
        customerBan: record.customer_ban,
        payingCustomerId: record.paying_customer_id,
        commercialOfferName: record.commercial_offer_name,
        action: record.action,
        actionStr: convertActionToString(record.action),
        status: record.naas_provisioning_status,
        statusStr: convertNaasProvisioningStatusToString(record.naas_provisioning_status),
        lastUpdatedAt: record.update_timestamp,
        lastUpdatedBy: record.create_portal_id,
        bulkUploadId: record.bulk_upload_id,
        customerName: record.customer_name ?? '',
        qodProfiles: record.qod_profiles?.join(';') ?? '',
        approverName: record.approver_name ?? '',
        serviceIds: record.service_ids?.join(';') ?? '',
    };
};

const convertActionToString = (action: ACTION): string => {
    switch (action) {
        case ACTION.ADD:
            return i18n.t('generic.add').toString();
        case ACTION.REMOVE:
            return i18n.t('generic.remove').toString();
        default:
            return '';
    }
};

const convertNaasProvisioningStatusToString = (status: STATUS): string => {
    switch (status) {
        case STATUS.ACCEPTED_RECORD:
            return i18n.t('qodNumberManagement.acceptedRecord').toString();
        case STATUS.NAAS_REJECT_REQUEST:
            return i18n.t('qodNumberManagement.rejected').toString();
        case STATUS.NAAS_ACCEPTED_REQUEST:
            return i18n.t('qodNumberManagement.inProgress').toString();
        case STATUS.CALLBACK_WITH_FAILED_STATUS:
            return i18n.t('generic.error').toString();
        case STATUS.CALLBACK_WITH_SUCCESS_STATUS:
            return i18n.t('qodNumberManagement.provisioned').toString();
        case STATUS.CALLBACK_NEVER_HAPPENED:
            return i18n.t('qodNumberManagement.timeout').toString();
        default:
            return '';
    }
};

/**
 * Gets list of all possible naas strings
 * Why? Iterating over a large number of records to determine all options is expensive
 */
export const getAllNaasStatusStrings = (): string[] => [
    convertNaasProvisioningStatusToString(STATUS.ACCEPTED_RECORD),
    convertNaasProvisioningStatusToString(STATUS.NAAS_REJECT_REQUEST),
    convertNaasProvisioningStatusToString(STATUS.NAAS_ACCEPTED_REQUEST),
    convertNaasProvisioningStatusToString(STATUS.CALLBACK_WITH_FAILED_STATUS),
    convertNaasProvisioningStatusToString(STATUS.CALLBACK_WITH_SUCCESS_STATUS),
    convertNaasProvisioningStatusToString(STATUS.CALLBACK_NEVER_HAPPENED),
];

/**
 * Get list of uploaded files (aka bulk upload history)!
 */
export const getUploadHistory = async (dataflowId: string): Promise<UploadedFileDetails[]> => {
    const response = await getRegisteredEntities(dataflowId);
    const entities = response.data.data.items;
    return entities.map(entity => uploadFileDetailsFromEntity(dataflowId, entity));
};

/**
 * Transforms backend `RegisteredEntityItem` into Portal friendly `UploadedFileDetails`
 */
const uploadFileDetailsFromEntity = (dataflowId: string, entity: RegisteredEntityItem): UploadedFileDetails => {
    /**
     * Build details object
     * Explicitly leaving fields undefined since:
     *  - they will be updated asynchronously
     *  - they need to be reactive
     */
    const fileDetails: UploadedFileDetails = {
        entityName: entity.entity_name,
        createdAt: entity.created_at,
        lastUpdated: entity.last_updated,
        fileName: undefined,
        bulkUploadId: undefined,
        createdBy: undefined,
        recordCount: undefined,
        metadata: undefined,
    };

    /**
     * When we upload our file we append data to the filename.
     * So if you upload `<original filename>` it gets transformed into an entity name `<bulk upload id>-<username>-<portal user id>-<original filename>`.
     * Eg: Uploading `arfaiouasdfu.csv` actually uploads `a01e4383-d45b-4eeb-a86a-d36dfe89b0c5-Donkey Kong-1235-arfaiouasdfu.csv`.
     * In this step we go backwards to transform the entity name into a filename, bulk upload id, username and portal user id (user who uploaded the file).
     * If the filename doesn't match our expected pattern then we cannot determine bulk upload id and upload user id.
     */
    const filenameRegex =
        /^([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})(?:-([A-Za-z ]+))?-(\d+)-(.*)$/;
    const match = entity.entity_name.match(filenameRegex);
    if (match) {
        fileDetails.bulkUploadId = match[1];
        fileDetails.createdBy = match[2]; //username is optional for backward compatibility (files that were uploaded without it)
        fileDetails.createdByPortalId = Number(match[3]);
        fileDetails.fileName = match[4];
    } else {
        fileDetails.fileName = fileDetails.entityName;
    }

    /**
     * Determine file upload status
     * Attempt to load this async as we need an additional request
     */
    getEntityDetailsByName(dataflowId, entity.entity_name)
        .then(details => {
            const recordCount = details.data.data.expected_count;
            const eventCategoryCounters = details.data.data.event_category_counters;
            fileDetails.recordCount = recordCount;
            fileDetails.eventCategoryCounters = eventCategoryCounters;
            fileDetails.fileUploadStatus = getFileUploadStatus(recordCount, eventCategoryCounters);
            fileDetails.fileUploadStatusStr = fileUploadStatusToStr(fileDetails.fileUploadStatus);
            fileDetails.metadata = details.data.data.metadata;
        })
        .catch(() => {
            fileDetails.recordCount = 0;
            fileDetails.fileUploadStatus = FILE_UPLOAD_STATUS.UNKNOWN;
            fileDetails.fileUploadStatusStr = fileUploadStatusToStr(fileDetails.fileUploadStatus);
        });
    return fileDetails;
};

/**
 * Get an ordered list of `COLUMN_DATA` for a given `apiType`
 * @param apiType
 * @returns
 */
export const getColumnData = (apiType: DEVICE_LINE_AUTH_API_TYPE): COLUMN_DATA[] => {
    const config = API_TYPE_TO_CONFIG.get(apiType);
    if (!config) {
        return [];
    }
    return config.columns
        .map(columnType => COLUMN_TYPE_TO_COLUMN_DATA.get(columnType))
        .filter(columnData => columnData !== undefined) as COLUMN_DATA[];
};

/**
 * Given an API type, builds the columnData information needed for the AppTable component.
 * @param apiType
 * @returns
 */
export const getAppTableColumnsData = (apiType: DEVICE_LINE_AUTH_API_TYPE): TableColumn[] => {
    const columnData = getColumnData(apiType);
    return columnData.map(data => ({
        name: data.label,
        key: data.key,
        field: data.key,
        filterType: data.options ? tableColumnType.TEXT_LIMITED_OPTIONS : tableColumnType.GENERAL_TEXT,
        limitedOptions: data.options,
    }));
};

export const getApiTypeConfig = (apiType: DEVICE_LINE_AUTH_API_TYPE): API_TYPE_CONFIG => {
    const apiTypeConfig = API_TYPE_TO_CONFIG.get(apiType);
    if (!apiTypeConfig) {
        throw new Error(`Config undefined for API type ${apiType}`);
    }
    return apiTypeConfig;
};
