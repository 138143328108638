<template>
    <AppDialogV2
        :title="$i18n.t('customerCare.subscriberTab.swapESIM')"
        :visible="esimSwapDialogVisible"
        @close="onCloseModal"
        @submit="dealSwapEsim"
    >
        <div class="form-content">
            <p class="textSize">{{ $i18n.t('customerCare.subscriberTab.confirmSwapEsim') }} “{{ msisdn }}” ?</p>

            <div class="platformwrapper">
                <div class="platform-label">
                    <p class="textSize">{{ $i18n.t('customerCare.subscriberTab.platform') }}</p>
                </div>
                <div>
                    <div
                        v-for="option in platformOptions"
                        :key="option.name"
                        class="radio-item"
                    >
                        <AppRadioButton
                            :value="option.id"
                            :inputValue="option.id"
                            name="platform"
                            @input="onPlatformSelect"
                        >
                            <div class="label">
                                {{ option.id }}
                            </div>
                        </AppRadioButton>
                    </div>
                </div>
            </div>
        </div>
    </AppDialogV2>
</template>

<script>
// Components
import AppDialogV2 from '@/components/partials/AppDialogV2.vue';
import AppRadioButton from '@/components/partials/inputs/AppRadioButton.vue';

import Ossgomo from '@/__new__/services/dno/ossgomo/http/ossgomo';
import { PLATFORM } from '@/common/platform';

export default {
    name: 'ESimSwapModal',
    components: {
        AppDialogV2,
        AppRadioButton,
    },
    props: {
        esimSwapDialogVisible: {
            type: Boolean,
            required: true,
        },
        msisdn: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            platformOptions: [
                {
                    id: PLATFORM.ANDROID,
                    name: this.$i18n.t('customerCare.platform.andorid'),
                },
                {
                    id: PLATFORM.SMARTDEVICE,
                    name: this.$i18n.t('customerCare.platform.smartdevice'),
                },
                {
                    id: PLATFORM.IOS,
                    name: this.$i18n.t('customerCare.platform.ios'),
                },
            ],
            selectedPlatForm: '',
        };
    },
    created() {
        this.selectedPlatForm = this.platformOptions[this.platformOptions.length - 1].id;
    },
    methods: {
        async dealSwapEsim() {
            this.onCloseModal();
            await this.$withProgressBar(
                async () => {
                    await Ossgomo.swapEsimManually(this.msisdn, this.selectedPlatForm);
                    this.$showSuccessAlert(this.$i18n.t('customerCare.subscriberTab.esimSwapTips'));
                },
                {
                    errorHandler: () => {
                        this.$alert(this.$i18n.t('generic.somethingWentWrong'));
                    },
                },
            );
        },
        onPlatformSelect(val) {
            this.selectedPlatForm = val;
        },
        onCloseModal() {
            this.$emit('closeModal');
        },
    },
};
</script>

<style lang="scss" scoped>
.platform-label {
    margin-right: 1rem;
}
.radio-item {
    margin-bottom: 0.25rem;
}
.label {
    margin-left: 0.15rem;
}
.textSize {
    font-size: 0.875rem;
}
</style>
