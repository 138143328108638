import { parseExpressionsFromBe } from '@/__new__/services/dno/charging/common/expression';
import {
    MAP_CHARGING_SPEC_TYPES,
    CHARGING_SPEC_TYPE,
} from '@/__new__/services/dno/charging/common/chargingSpecificationHelper';
import {
    mapLowerEntitiesVersions,
    LowerEntitiesVersions,
} from '@/__new__/services/dno/charging/common/versioningHelper';
import { TariffSpecApiResponse } from '@/__new__/services/dno/charging/http/tariffSpecification';
import { getAllConditionParametersIds } from '@/__new__/services/dno/charging/common/expression';
import { cloneDeep } from 'lodash';
import {
    EntityBaseConstructorArgs,
    EntityBaseModel,
    EntityBaseDraftModel,
} from '@/__new__/services/dno/charging/models/EntityBaseModel';
import {
    ZERO_RATE_PRIORITY,
    VlrIdOptions,
    ZERO_RATE_PRIORITY_OPTION,
} from '@/__new__/services/dno/charging/common/tariffSpecHelper';

type TariffSpecChargingRatesConstructorArgs = {
    rate: string;
    rate_period?: number;
    condition_expr: any[];
    priority?: number;
    period_type: PeriodType;
};

type ServiceTypeObj = { key: number; id: string };

export enum PeriodType {
    CHARG = 1,
    EVENT = 5,
}

export interface TariffSpecConstructorArgs extends EntityBaseConstructorArgs {
    service_type: number;
    charging_rates: TariffSpecChargingRatesConstructorArgs[];
    zero_rate_priority?: ZERO_RATE_PRIORITY;
}

export interface TariffSpecDraftConstructorArgs extends TariffSpecConstructorArgs {
    operator_name: string;
    type: string;
    update_portal_id: string;
    data?: TariffSpecConstructorArgs;
}

export class TariffSpecification extends EntityBaseModel {
    isZeroRate: boolean;
    serviceType: number;
    serviceTypeLabel: string;
    serviceTypeObj?: ServiceTypeObj | null;
    chargingRates: TariffSpecChargingRateModal[];
    zeroRatePriority?: VlrIdOptions;

    constructor(
        isZeroRate = false,
        data: TariffSpecConstructorArgs,
        draftEntities: TariffSpecificationDraft[],
        responseData?: TariffSpecApiResponse,
    ) {
        super(data, draftEntities);
        this.isZeroRate = isZeroRate;
        this.serviceType = data.service_type;
        this.serviceTypeLabel = this.getServiceTypeLabel;
        this.serviceTypeObj = this.getServiceTypeObj;
        const chargingRatesData = cloneDeep(data.charging_rates) || [];
        this.chargingRates = chargingRatesData
            ?.sort()
            ?.map((el, i) => new TariffSpecChargingRateModal(el, i, responseData));
        this.zeroRatePriority = data?.zero_rate_priority
            ? ZERO_RATE_PRIORITY_OPTION[data.zero_rate_priority]
            : ZERO_RATE_PRIORITY_OPTION[ZERO_RATE_PRIORITY.MINIMUM];
    }

    get getServiceTypeLabel(): string {
        return MAP_CHARGING_SPEC_TYPES?.[this.serviceType]?.toString()?.toUpperCase() || '';
    }

    get getServiceTypeObj(): ServiceTypeObj | null {
        const obj = Object.values(CHARGING_SPEC_TYPE).filter(el => el.key === this.serviceType);
        return obj?.[0] || null;
    }
}

export class TariffSpecificationDraft extends EntityBaseDraftModel {
    serviceType: number;
    serviceTypeLabel: string;
    serviceTypeObj?: ServiceTypeObj | null;
    chargingRates: TariffSpecChargingRateModal[];
    zeroRatePriority?: VlrIdOptions;

    constructor(data: TariffSpecDraftConstructorArgs) {
        super(data);
        this.serviceType = data.service_type;
        this.serviceTypeLabel = this.getServiceTypeLabel;
        this.serviceTypeObj = this.getServiceTypeObj;
        const chargingRatesData = cloneDeep(data.charging_rates) || [];
        this.chargingRates = chargingRatesData?.sort()?.map((el, i) => new TariffSpecChargingRateModal(el, i));
        this.zeroRatePriority = data?.zero_rate_priority
            ? ZERO_RATE_PRIORITY_OPTION[data.zero_rate_priority]
            : ZERO_RATE_PRIORITY_OPTION[ZERO_RATE_PRIORITY.MINIMUM];
    }

    get getServiceTypeLabel(): string {
        return MAP_CHARGING_SPEC_TYPES?.[this.serviceType]?.toString()?.toUpperCase() || '';
    }

    get getServiceTypeObj(): ServiceTypeObj | null {
        const obj = Object.values(CHARGING_SPEC_TYPE).filter(el => el.key === this.serviceType);
        return obj?.[0] || null;
    }
}

export class TariffSpecChargingRateModal {
    rate: number;
    ratePeriod?: number;
    conditionExpr: any[];
    priority: number;
    lowerEntitiesVersions?: LowerEntitiesVersions[] | null;
    periodType: PeriodType;
    useEvent: boolean;

    constructor(data: TariffSpecChargingRatesConstructorArgs, priority: number, responseData?: TariffSpecApiResponse) {
        this.rate = +data?.rate || 0;
        this.ratePeriod = data?.rate_period || 1;
        this.priority = priority + 1;
        this.conditionExpr = data?.condition_expr ? parseExpressionsFromBe(data.condition_expr) : [];
        this.lowerEntitiesVersions =
            responseData?.condition_parameter_by_id && data.condition_expr
                ? mapLowerEntitiesVersions(
                      responseData?.condition_parameter_by_id,
                      getAllConditionParametersIds(data.condition_expr) || [],
                  )
                : null;
        this.periodType = data.period_type;
        this.useEvent = this.periodType === PeriodType.EVENT;
    }
}
