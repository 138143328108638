import Vue from 'vue';
import Actions, { State, Getters, Mutations } from '../mutation-types';
import { getUsers } from '@/__new__/services/portal/admin/http/account';

export default {
    namespaced: true,
    state: {
        [State.USERS]: [],
    },
    mutations: {
        [Mutations.SET_USERS]: (state, userList) => {
            Vue.set(state, State.USERS, userList);
        },
    },
    actions: {
        async [Actions.LOAD_ALL_USERS]({ commit }) {
            const userList = await getUsers();
            commit(Mutations.SET_USERS, userList.data);
        },
    },
    getters: {
        [Getters.GET_LIST_OF_USERS]: state =>
            state[State.USERS].map(user => ({
                id: user.id,
                name: user.name,
                email: user.email,
                state: user.state,
                role: user.userRoles[0]?.Role,
                externalUserId: user.externalUserId,
            })),
        [Getters.GET_USER_BY_ID]: state => id => state[State.USERS].find(user => id === user.id),
    },
};
