
import Vue from 'vue';

import Alert, { ALERT_TYPES } from '@/common/alerts/Alert';
import { uuidV4 } from '@/common/utils';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import AppIcon from '@/components/partials/icon/AppIcon.vue';
import { ICON_TYPES, ICON_COLORS } from '@/common/iconHelper';

export const PositioningTypes = {
    bottomCenter: 'bottom-center',
    rightBottomCorner: 'right-bottom-corner',
};
export const timeout = 2000;

interface AlertItem {
    key: string;
    value: Alert;
}

export default Vue.extend({
    name: 'Notification',
    components: {
        AppButton,
        AppIcon,
    },
    data() {
        return {
            alerts: [] as AlertItem[],
            position: PositioningTypes.bottomCenter,
            ALERT_TYPES: ALERT_TYPES as any,
            ICON_TYPES: ICON_TYPES as any,
            ICON_COLORS: ICON_COLORS as any,
            BUTTON_TYPES,
        };
    },
    computed: {
        compact() {
            return !!this.$store?.state.compactSidebar;
        },
    },
    watch: {
        $route() {
            this.clearAlerts();
        },
    },
    created() {
        this.$eventBus.$on('showAlert', ({ message, type, buttons, description, keepSuccessAlertVisible }) => {
            if (message) {
                const genKey = uuidV4();
                this.alerts.push({
                    key: genKey,
                    value: new Alert(message, type, buttons, description),
                });
                if (type === ALERT_TYPES.success && !keepSuccessAlertVisible) {
                    setTimeout(() => this.onClose(genKey), timeout);
                }
            }
        });
        this.$eventBus.$on('changePosition', value => {
            this.position = value;
        });
        this.$eventBus.$on('closeAlertIfError', () => {
            this.clearAllErrors();
        });
        this.$eventBus.$on('closeAllAlerts', () => {
            this.clearAlerts();
        });
    },
    methods: {
        emitButton(key: string, id: string): void {
            this.onClose(key);
            this.$eventBus.$emit('buttonClicked', id);
        },
        clearAlerts(): void {
            this.alerts = [];
        },
        clearAllErrors(): void {
            this.alerts = this.alerts.filter(el => el.value.type !== ALERT_TYPES.error);
        },
        onClose(key: string): void {
            const index = this.alerts.findIndex(el => el.key === key);
            if (index > -1) {
                this.alerts.splice(index, 1);
                this.$eventBus.$emit('alertClosed', key);
            }
        },
        getIconConfig(alertType: string) {
            if (alertType === ALERT_TYPES.error) {
                return {
                    type: ICON_TYPES.ALERT_INFO,
                    color: ICON_COLORS.RED,
                };
            }

            if (alertType === ALERT_TYPES.warning) {
                return {
                    type: ICON_TYPES.ALERT_INFO,
                    color: ICON_COLORS.YELLOW,
                };
            }

            if (alertType === ALERT_TYPES.success) {
                return {
                    type: ICON_TYPES.ALERT_SUCCESS,
                    color: ICON_COLORS.GREEN,
                };
            }

            return {
                type: ICON_TYPES.ALERT_INFO,
                color: ICON_COLORS.BLUE,
            };
        },
    },
});
