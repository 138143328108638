<template>
    <div :class="{ error }">
        <div
            v-if="hasLabel"
            class="datetimepicker-label"
        >
            <slot
                v-if="$slots.additionalLabel"
                name="additionalLabel"
            />
            <template v-else-if="additionalLabel">
                {{ additionalLabel }}
            </template>
        </div>
        <DatePicker
            :value="date"
            data-test-id="datepickerv2"
            :class="{ 'w-100': useFullWidth }"
            :type="type"
            :range="range"
            :placeholder="defaultPlaceholder"
            :format="defaultFormat"
            :titleFormat="defaultFormat"
            :timeTitleFormat="defaultFormat"
            :editable="editable"
            :clearable="clearable"
            :confirm="confirm"
            :multiple="multiple"
            :disabled="disabled"
            :disabledDate="disabledDates"
            :disabledTime="disabledTime"
            :inline="inline"
            :inputClass="inputClass"
            :inputAttr="inputAttr"
            :popupStyle="popupStyle"
            :popupClass="popupClass"
            :rangeSeparator="rangeSeparator"
            :showWeekNumber="showWeekNumber"
            :hourStep="hourStep"
            :minuteStep="minuteStep"
            :secondStep="secondStep"
            @input="onSelected"
        />
    </div>
</template>

<script>
import DatePicker from 'vue2-datepicker';

// HELPERS
import uniqueId from 'lodash/uniqueId';
import localeLibrary from '@/common/locale/localeLibrary';

export default {
    name: 'DateTimePicker',
    components: {
        DatePicker,
    },
    props: {
        // issue: Error: [Vue warn]: Invalid prop: type check failed for prop "value". Expected Array, Date, String, Object, got Date.
        // solution: pass type as "valueType" prop value.
        value: { type: null, default: '' },
        type: { type: String, default: 'date' }, // date|datetime|year|month|time|week
        range: { type: Boolean, default: false },
        placeholder: { type: String, default: '' },
        format: { type: String, default: '' },
        editable: { type: Boolean, default: false },
        clearable: { type: Boolean, default: true },
        confirm: { type: Boolean, default: false },
        multiple: { type: Boolean, default: false },
        disabled: { type: Boolean, default: false },
        disabledDates: { type: Function, required: false, default: () => undefined },
        disabledTime: { type: Function, required: false, default: () => undefined },
        inline: { type: Boolean, default: false },
        inputClass: { type: String, default: 'mx-input' },
        inputAttr: { type: Object, default: () => ({}) },
        popupStyle: { type: Object, default: () => ({}) },
        popupClass: { type: String, default: '' },
        rangeSeparator: { type: String, default: ' ~ ' },
        showWeekNumber: { type: Boolean, default: false },
        hourStep: { type: Number, default: 1 },
        minuteStep: { type: Number, default: 1 },
        secondStep: { type: Number, default: 10 },
        error: { type: Boolean, default: false },
        additionalLabel: { type: String, default: '' },
        timezoneNormalizing: { type: Boolean, default: true },
        useFullWidth: { type: Boolean, default: false },
    },
    data() {
        const dateFormat = localeLibrary.getDateFormat();
        const timeFormatString = localeLibrary.getTimeFormat() === 24 ? 'HH:mm' : 'hh:mm';

        const dateTimeFormat = {
            date: dateFormat,
            datetime: `${dateFormat} ${timeFormatString}`,
            year: 'YYYY',
            month: dateFormat
                .split('-')
                .filter(el => el !== 'DD')
                .join('-'),
            time: timeFormatString,
            week: dateFormat,
        };

        return {
            id: uniqueId('lf-datetimepicker'),
            dateTimeFormat,
        };
    },
    computed: {
        date() {
            if (this.range) {
                return [
                    this.timezoneNormalizing ? this.normalizeDateToPortalTimezone(this.value[0]) : this.value[0],
                    this.timezoneNormalizing ? this.normalizeDateToPortalTimezone(this.value[1]) : this.value[1],
                ];
            }
            return this.timezoneNormalizing ? this.normalizeDateToPortalTimezone(this.value) : this.value;
        },
        dateTimePickerPlaceholder() {
            return {
                date: this.$i18n.t('generic.selectDate'),
                datetime: this.$i18n.t('generic.selectDateTime'),
                year: this.$i18n.t('generic.selectYear'),
                month: this.$i18n.t('generic.selectMonth'),
                time: this.$i18n.t('generic.selectTime'),
                week: this.$i18n.t('generic.selectWeek'),
            };
        },
        dateTimeRangePickerPlaceholder() {
            return {
                date: this.$i18n.t('generic.selectDateRange'),
                datetime: this.$i18n.t('generic.selectDateTimeRange'),
                year: this.$i18n.t('generic.selectYearRange'),
                month: this.$i18n.t('generic.selectMonthRange'),
                time: this.$i18n.t('generic.selectTimeRange'),
                week: this.$i18n.t('generic.selectWeekRange'),
            };
        },
        defaultPlaceholder() {
            if (this.placeholder) {
                return this.placeholder;
            }
            if (this.range) {
                return this.dateTimeRangePickerPlaceholder[this.type];
            }

            return this.dateTimePickerPlaceholder[this.type];
        },
        defaultFormat() {
            if (this.format) {
                return this.format;
            }
            return this.dateTimeFormat[this.type];
        },
        hasLabel() {
            return this.$slots.additionalLabel || this.additionalLabel;
        },
    },
    methods: {
        // Adjusting date to show datetime according to tenant/user selected timezone due to limitations of library
        normalizeDateToPortalTimezone(date) {
            if (date && date instanceof Date) {
                const browserOffset = date.getTimezoneOffset() * 60000;
                const portalOffset = localeLibrary.userConfiguredTimezoneOffset(date) * 60000;
                return new Date(date.getTime() + browserOffset + portalOffset);
            }
            return date;
        },
        // Remove adjustments made with function above
        normalizeDateToUTC(date) {
            if (date && date instanceof Date) {
                const browserOffset = date.getTimezoneOffset() * 60000;
                const portalOffset = localeLibrary.userConfiguredTimezoneOffset(date) * 60000;
                return new Date(date.getTime() - browserOffset - portalOffset);
            }
            return date;
        },

        onSelected(date) {
            if (this.range) {
                this.$emit(
                    'range-selected',
                    this.timezoneNormalizing ? this.normalizeDateToUTC(date[0]) : date[0],
                    this.timezoneNormalizing ? this.normalizeDateToUTC(date[1]) : date[1],
                );
            } else if (this.multiple) {
                this.$emit('multiple-selected', date);
            } else {
                this.$emit('input', this.timezoneNormalizing ? this.normalizeDateToUTC(date) : date);
            }
        },
    },
};
</script>

<style lang="scss">
@import '~@/assets/scss/palette';

.datetimepicker-label {
    margin-bottom: 4px;
    color: $gray90;
    font-weight: 600;
}

.error {
    .mx-input {
        border-color: $red !important;
    }
}
</style>
