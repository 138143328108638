import httpClient from '@/http/index';
import { getDnoClaimsHeader, USER_MANAGER_HIERARCHY } from '@/__new__/features/customerCare/common/customerCareHelper';
import { SERVICE_TYPE } from '@/__new__/features/customerCareSuite/common/SensitiveTransactionHistoryHelper';

const getUser = (id, targetType) =>
    httpClient({
        method: 'POST',
        url: '/v3usermanager/getuser',
        headers: {
            'Dno-Claims': JSON.stringify(getDnoClaimsHeader(id, targetType)),
        },
        data: {
            target_id: id,
            target_type: targetType,
        },
    });

const getAccount = (id, targetType) =>
    httpClient({
        method: 'POST',
        url: '/v3usermanager/getaccount',
        headers: {
            'Dno-Claims': JSON.stringify(getDnoClaimsHeader(id, targetType)),
        },
        data: {
            target_id: id,
            target_type: targetType,
        },
    });

const getAccountByFriendlyAccountID = friendly_account_id =>
    httpClient({
        method: 'POST',
        url: '/v3usermanager/getAccountByFriendlyAccountID',
        data: {
            friendly_account_id,
        },
    });

const getSubscriber = (id, targetType) =>
    httpClient({
        method: 'POST',
        url: '/v3usermanager/getsubscriber',
        headers: {
            'Dno-Claims': JSON.stringify(getDnoClaimsHeader(id, targetType)),
        },
        data: {
            target_id: id,
            target_type: targetType,
        },
    });

function getStateHistory(id, targetType) {
    return httpClient({
        method: 'POST',
        url: '/v3usermanager/getstatehistory',
        data: {
            target_id: id,
            target_type: targetType,
        },
    });
}

function reattemptRewardPayout({ triggeredRewardId, rewardRuleId, payoutActionId, subscriberId }) {
    return httpClient({
        method: 'POST',
        url: '/v3reward/payoutreattempt',
        headers: {
            'Content-type': 'application/json',
            'Dno-Claims': JSON.stringify({
                subscriber_id: subscriberId,
            }),
        },
        data: {
            triggered_reward_id: triggeredRewardId,
            reward_rule_id: rewardRuleId,
            payout_action_id: payoutActionId,
        },
    });
}

// todo: remove update and add address from here and form addressEditor component
// when we remove old UM
/**
 * Add account address
 *
 * @param subscriberId
 * @returns {AxiosPromise}
 */
function addAddress({ id, type, hierarchy, parts }) {
    const claimsData = hierarchy === 1 ? { subscriber_id: id } : { account_id: id };
    return httpClient({
        method: 'POST',
        url: '/v3usermanagerwrite/addaddress',
        headers: {
            'Dno-Claims': JSON.stringify(claimsData),
        },
        data: {
            parts,
            type,
            hierarchy,
        },
    });
}

/**
 * Update account address
 *
 * @param subscriberId
 * @returns {AxiosPromise}
 */
function updateAddress({ id, type, hierarchy, parts, addressId }) {
    const claimsData = hierarchy === 1 ? { subscriber_id: id } : { account_id: id };
    return httpClient({
        method: 'POST',
        url: '/v3usermanagerwrite/updateaddress',
        headers: {
            'Dno-Claims': JSON.stringify(claimsData),
        },
        data: {
            parts,
            type,
            hierarchy,
            id: addressId,
        },
    });
}

/**
 * Update account email
 *
 * @param subscriberId
 * @returns {AxiosPromise}
 */
function updateAccountEmail({ subscriberId, email }) {
    return httpClient({
        method: 'POST',
        url: '/v3usermanagerwrite/updateaccountemail',
        headers: {
            'Content-type': 'application/json',
            'Dno-Claims': JSON.stringify({
                subscriber_id: subscriberId,
            }),
        },
        data: {
            email,
        },
    });
}

const getReferralCode = (id, targetType) =>
    httpClient({
        method: 'POST',
        url: '/v3reward/getreferralcode',
        headers: {
            'Dno-Claims': JSON.stringify(getDnoClaimsHeader(id, targetType)),
        },
        data: {
            target_id: id,
            target_type: targetType,
        },
    });

const getReferrerInfo = (id, targetType) =>
    httpClient({
        method: 'POST',
        url: '/v3reward/getreferrerinfo',
        headers: {
            'Dno-Claims': JSON.stringify(getDnoClaimsHeader(id, targetType)),
        },
        data: {
            target_id: id,
            target_type: targetType,
        },
    });

const getReferralHistory = (id, targetType) =>
    httpClient({
        method: 'POST',
        url: '/v3reward/getreferralhistory',
        headers: {
            'Dno-Claims': JSON.stringify(getDnoClaimsHeader(id, targetType)),
        },
        data: {
            target_id: id,
            target_type: targetType,
        },
    });

const getOrganization = (id, targetType) =>
    httpClient({
        method: 'POST',
        url: '/v3usermanager/getorganization',
        headers: {
            'Dno-Claims': JSON.stringify(getDnoClaimsHeader(id, targetType)),
        },
        data: {
            target_id: id,
            target_type: targetType,
        },
    });

const getSearchByIndex = (id, targetType) =>
    httpClient({
        method: 'POST',
        url: '/v3usermanager/getsearchbyindex',
        headers: {
            'Dno-Claims': JSON.stringify({
                index_value: id,
                index_type: targetType,
            }),
        },
        data: {
            index_value: id,
            index_type: targetType,
        },
    });

/**
 * Sends the OTP to the customer, to verify the customers identity.
 * @param id
 * @param targetType
 * @param contextType
 * @param challengeType
 * @returns {AxiosPromise}
 */
const sendOTP = (id, targetType, contextType, challengeType) =>
    httpClient({
        method: 'POST',
        url: '/v3usermanager/sendotp',
        headers: {
            'Dno-Claims': JSON.stringify(getDnoClaimsHeader(id, targetType)),
        },
        data: {
            target_id: id,
            target_type: targetType,
            context_type: contextType,
            challenge_type: challengeType,
        },
    });

/**
 * Sends the OTP to the customer, to verify the customers identity.
 * @param targetId
 * @param targetType
 * @param serviceType
 * @returns {AxiosPromise}
 */
const fetchServiceBlockerHistory = (targetId, targetType, serviceType, transactionType) => {
    return httpClient({
        method: 'POST',
        url: '/v3usermanager/fetchserviceblockerhistory',
        headers: {
            'Dno-Claims': JSON.stringify(getDnoClaimsHeader(targetId, targetType)),
        },
        data: {
            target_id: targetId,
            target_type: targetType,
            ...(serviceType && { service_type: serviceType }),
            ...(transactionType && { transaction_type: transactionType }),
        },
    });
};

/**
 * Verify the OTP that customer received, to verify the customers identity.
 * @param challengeId
 * @param otpCode
 * @param contextType
 * @param challengeType
 * @returns {AxiosPromise}
 */
const verifyOTP = (challengeId, otpCode, contextType, challengeType) =>
    httpClient({
        method: 'POST',
        url: '/v3usermanager/verifyotp',
        headers: {
            'Dno-Claims': JSON.stringify(getDnoClaimsHeader(challengeId)),
        },
        data: {
            challenge_id: challengeId,
            otp_code: otpCode,
            context_type: contextType,
            challenge_type: challengeType,
        },
    });

/**
 * Refunds transaction
 *
 * @param id
 * @param targetType
 * @param transactionId
 * @param amount
 * @returns {AxiosPromise}
 */
const refundTransaction = (id, targetType, transactionId, amount) =>
    httpClient({
        method: 'POST',
        url: '/v3refundwrite/refundtransaction',
        headers: {
            'Dno-Claims': JSON.stringify(getDnoClaimsHeader(id, targetType)),
        },
        data: {
            target_id: id,
            target_type: targetType,
            transaction_id: transactionId,
            amount,
        },
    });

/**
 * Refunds transaction
 *
 * @param data
 * @returns {AxiosPromise}
 */
const refundTransactionNextGen = data =>
    httpClient({
        method: 'POST',
        url: '/v3refundwrite/refundtransactionnextgen',
        headers: {
            'Dno-Claims': JSON.stringify(getDnoClaimsHeader(data.target_id, data.target_type)),
        },
        data,
    });

/**
 * Download invoice for transaction
 *
 * @param id
 * @param targetType
 * @param transactionId
 * @returns {AxiosPromise}
 */
const downloadInvoice = (id, targetType, transactionId) =>
    httpClient({
        method: 'POST',
        url: '/v3billingstatement/downloadinvoice',
        headers: {
            'Dno-Claims': JSON.stringify(getDnoClaimsHeader(id, targetType)),
        },
        data: {
            target_id: id,
            target_type: targetType,
            transaction_id: transactionId,
        },
    });

/**
 * Download invoice. Billing next gen
 *
 * @param id
 * @param targetType
 * @param billingId
 * @returns {AxiosPromise}
 */
const getInvoice = (id, targetType, billingId) =>
    httpClient({
        method: 'POST',
        url: '/v3billingstatement/getinvoice',
        headers: {
            'Dno-Claims': JSON.stringify(getDnoClaimsHeader(id, targetType)),
        },
        data: {
            target_id: id,
            target_type: targetType,
            bill_id: billingId,
        },
    });

/**
 * Generate invoice for transaction
 *
 * @param id
 * @param targetType
 * @param transactionId
 * @returns {AxiosPromise}
 */
const generateFile = (id, targetType, transactionId) =>
    httpClient({
        method: 'POST',
        url: '/v3billingstatement/generatefile',
        headers: {
            'Dno-Claims': JSON.stringify(getDnoClaimsHeader(id, targetType)),
        },
        data: {
            target_id: id,
            target_type: targetType,
            transaction_id: transactionId,
        },
    });

/**
 *
 * @param {Object} data
 * @param {number} data.targetType - User hierarchy
 * @param {string} data.targetId - Subscribed ID
 * @param {number=} data.tokenType - Used for getting the PUK data
 * @returns
 */
const getProfileInfo = ({ targetType, targetId, tokenType }) => {
    const dnoClaims = getDnoClaimsHeader(targetId, targetType);

    if (tokenType) {
        dnoClaims.type = tokenType;
    }

    return httpClient({
        method: 'POST',
        url: '/v3usermanager/getprofileinfo',
        headers: {
            'Dno-Claims': JSON.stringify(dnoClaims),
        },
        data: {
            target_type: targetType,
            target_id: targetId,
        },
    });
};

const swapSIM = payload =>
    httpClient({
        method: 'POST',
        url: '/v3usermanager/swapsim',
        headers: {
            'Dno-Claims': JSON.stringify(getDnoClaimsHeader(payload.target_id, payload.target_type)),
        },
        data: {
            ...payload,
        },
    });

const sendESIMActivationQR = subscriberId =>
    httpClient({
        method: 'POST',
        url: '/v3usermanager/esimSendQRCode',
        headers: {
            'Dno-Claims': JSON.stringify(getDnoClaimsHeader(subscriberId, USER_MANAGER_HIERARCHY.SUBSCRIBER)),
        },
        data: {
            target_type: USER_MANAGER_HIERARCHY.SUBSCRIBER,
            target_id: subscriberId,
        },
    });

const getSubscriberServices = (target_id, target_type) =>
    httpClient({
        method: 'POST',
        url: '/v3ossmvne/getsubscriberservices',
        headers: {
            'Dno-Claims': JSON.stringify(getDnoClaimsHeader(target_id, target_type)),
        },
        data: {
            target_id,
            target_type,
        },
    });

// Groups

function getGroup(id, targetType, includeUserInfo = false) {
    let payload = {
        target_id: id,
        target_type: targetType,
    };

    if (includeUserInfo) {
        payload = {
            ...payload,
            filters: {
                get_user_info: true,
            },
        };
    }
    return httpClient({
        method: 'POST',
        url: '/v3usermanager/getGroup',
        headers: {
            'Dno-Claims': JSON.stringify(getDnoClaimsHeader(id, targetType)),
        },
        data: { ...payload },
    });
}

const getClientStorage = (target_id, target_type, context) =>
    httpClient({
        method: 'POST',
        url: '/v3clientstorage/getState',
        headers: {
            'Dno-Claims': JSON.stringify(getDnoClaimsHeader(target_id, target_type)),
        },
        data: {
            target_id,
            target_type,
            context,
        },
    });

const setClientStorage = (target_id, target_type, context, data) =>
    httpClient({
        method: 'POST',
        url: '/v3clientstorage/addState',
        headers: {
            'Dno-Claims': JSON.stringify(getDnoClaimsHeader(target_id, target_type)),
        },
        data: {
            target_id,
            target_type,
            context,
            data,
        },
    });

/**
 * Get msisdn transaction history
 *
 * @param id
 * @param targetType
 * @param useCase
 * @returns {AxiosPromise}
 */
const getUserSensitiveTransactionHistory = (id, targetType, useCase) =>
    httpClient({
        method: 'POST',
        url: '/v3usermanager/getusersensitivetransactionhistory',
        headers: {
            'Dno-Claims': JSON.stringify(getDnoClaimsHeader(id, targetType)),
        },
        data: {
            target_id: id,
            target_type: targetType,
            use_case: useCase,
        },
    });

/**
 * Get msisdn transaction history
 *
 * @param id
 * @param targetType
 * @param useCase
 * @returns {AxiosPromise}
 */
const getSimSensitiveTransactionHistory = (id, targetType, useCase) =>
    httpClient({
        method: 'POST',
        url: '/v3usermanager/getsimsensitivetransactionhistory',
        headers: {
            'Dno-Claims': JSON.stringify(getDnoClaimsHeader(id, targetType)),
        },
        data: {
            target_id: id,
            target_type: targetType,
            use_case: useCase,
        },
    });

/**
 * Get subscriber id from iccid
 *
 * @param id
 * @param targetType
 * @param useCase
 * @returns {AxiosPromise}
 */
const getTargetFromSimId = (target_id, target_type, identifier_id) =>
    httpClient({
        method: 'POST',
        url: '/v3usermanager/getTargetFromSimId',
        data: {
            target_id,
            target_type,
            identifier_id,
            identifier_type: 'ICCID',
        },
    });

/**
 * Get msisdn transaction history
 *
 * @param id
 * @param targetType
 * @returns {AxiosPromise}
 */
const getSensitiveTransactionVerificationHistory = (id, targetType, serviceType) =>
    httpClient({
        method: 'POST',
        url: '/v3usermanager/getsensitivetransactionverificationhistory',
        headers: {
            'Dno-Claims': JSON.stringify(getDnoClaimsHeader(id, targetType)),
        },
        data: {
            target_id: id,
            target_type: targetType,
            service_type: serviceType,
        },
    });

/**
 * Get non sensitive info for target id and target type
 *
 * @param id
 * @param targetType
 * @returns {AxiosPromise}
 */
const getNonSensitiveInfo = (id, targetType) =>
    httpClient({
        method: 'POST',
        url: '/v3usermanager/getnonsensitiveinfo',
        headers: {
            'Dno-Claims': JSON.stringify(getDnoClaimsHeader(id, targetType)),
        },
        data: {
            target_id: id,
            target_type: targetType,
        },
    });

export default {
    getUser,
    getAccount,
    getAccountByFriendlyAccountID,
    getSubscriber,
    getStateHistory,
    reattemptRewardPayout,
    addAddress,
    updateAddress,
    updateAccountEmail,
    getReferralCode,
    getReferrerInfo,
    getReferralHistory,
    getOrganization,
    getSearchByIndex,
    sendOTP,
    verifyOTP,
    refundTransaction,
    downloadInvoice,
    generateFile,
    getProfileInfo,
    swapSIM,
    sendESIMActivationQR,
    getClientStorage,
    setClientStorage,
    fetchServiceBlockerHistory,
    getInvoice,
    refundTransactionNextGen,
    getGroup,
    getUserSensitiveTransactionHistory,
    getSimSensitiveTransactionHistory,
    getTargetFromSimId,
    getSensitiveTransactionVerificationHistory,
    getNonSensitiveInfo,
    getSubscriberServices,
};
