import { AxiosPromise } from 'axios';

import httpClient from '@/http';

export type LoginResponseBody = {
    name: string;
    email: string;
    companyName: string;
    permissions: string[];
    homepage: string;
    dnoIdentifier: string;
    companyId: number;
    operatorId: number;
};

/**
 * Login API call
 * @param email
 * @param password
 * @param otpCode
 * @return {AxiosPromise}
 */
export function login(email: string, password: string, otpCode?: string): AxiosPromise<LoginResponseBody> {
    return httpClient({
        method: 'POST',
        url: '/session/v3login',
        data: {
            email,
            password,
            otpCode,
        },
    }) as AxiosPromise<LoginResponseBody>;
}

export type AuthStrategyResponseBody = { strategies: string[] };

export function authStrategy(): AxiosPromise<AuthStrategyResponseBody> {
    return httpClient({
        method: 'GET',
        url: '/session/authstrategy',
    }) as AxiosPromise<AuthStrategyResponseBody>;
}

/**
 * Forgot password API call
 * @param email
 * @return {AxiosPromise}
 */
export function sendUserLinkToChangePassword(email: string): AxiosPromise<void> {
    return httpClient({
        method: 'POST',
        url: '/session/forgotpassword',
        headers: {
            'content-type': 'application/json',
        },
        data: {
            email,
        },
    });
}

/**
 * Change password API call
 * @param email
 * @param password
 * @param token
 * @return {AxiosPromise}
 */
export function changePassword(email: string, password: string, token: string): AxiosPromise<void> {
    return httpClient({
        method: 'POST',
        url: '/session/changepassword',
        headers: {
            'content-type': 'application/json',
        },
        data: {
            email,
            password,
            token,
        },
    });
}

export type GetCompanyFeaturesResponseBody = Record<
    number, // Company ID
    string[] // Features array
>;

export function getCompanyFeatures(companyId: number): AxiosPromise<GetCompanyFeaturesResponseBody> {
    return httpClient({
        method: 'POST',
        url: '/session/getcompanyfeatures',
        data: {
            companyId,
        },
    });
}

export type GetFeaturesResponseBody = Record<
    string,
    {
        id: number;
        key: string;
        rootFeatureId: number | null;
        parentFeatureId: number | null;
    }
>;

export function getFeatures(): AxiosPromise<GetFeaturesResponseBody> {
    return httpClient({
        method: 'GET',
        url: '/session/getfeatures',
    });
}

export type GetViewsResponseBody = Record<
    string,
    {
        id: number;
        key: string;
        rootViewId: number | null;
        parentViewId: number | null;
        displayName: string;
        description: string;
        alwaysEnabled: boolean;
    }
>;

export function getViews(): AxiosPromise<GetViewsResponseBody> {
    return httpClient({
        method: 'GET',
        url: '/views/getviews',
    });
}

export type GetCompanyViewsResponseBody = { [key: number]: string[] };

export function getCompanyViews(companyId: number): AxiosPromise<GetCompanyViewsResponseBody> {
    return httpClient({
        method: 'POST',
        url: '/views/getcompanyviews',
        data: {
            companyId,
        },
    });
}

export function getCompanyPermissions(): AxiosPromise<unknown> {
    return httpClient({
        method: 'GET',
        url: '/permissions/getcompanypermissions',
    });
}

export function updateOperatorFeatures(
    companyId: number,
    featuresKeysToAdd: string[],
    featuresKeysToRemove: string[],
): AxiosPromise<void> {
    return httpClient({
        method: 'POST',
        url: '/session/updateoperatorfeatures',
        data: {
            companyId,
            featuresKeysToAdd,
            featuresKeysToRemove,
        },
    });
}

export default {
    login,
};
