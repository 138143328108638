<template>
    <div class="page-header">
        <div class="d-flex align-items-center mr-auto">
            <h1
                :title="headerTitle"
                class="heading-lg elipsis"
            >
                {{ headerTitle }}
            </h1>
            <span
                v-if="shouldDisplayEntitiesCount"
                class="body-sm gray-400 pl-1"
            >
                ({{ entitiesCount }})
            </span>
        </div>

        <SearchBox
            v-if="isSearchEnabled"
            :expanded="true"
            class="header-searchbox"
            @input="query => $emit('input', query)"
        />

        <slot name="button" />

        <slot name="filterTable" />

        <slot name="headerButtons" />
    </div>
</template>

<script>
// Vue components
import SearchBox from '@/components/partials/inputs/SearchBox.vue';

export default {
    name: 'AppHeaderV2',

    components: {
        SearchBox,
    },

    props: {
        pageTitle: {
            type: String,
            default: null,
        },
        isSearchEnabled: {
            type: Boolean,
            default: false,
        },
        entitiesCount: {
            type: Number,
            default: NaN,
        },
    },

    computed: {
        headerTitle() {
            return this.pageTitle ? this.pageTitle : this.$route.name;
        },
        shouldDisplayEntitiesCount() {
            return Boolean(this.entitiesCount) || this.entitiesCount === 0;
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/mixins';
@import '~@/assets/scss/sidebar';
@import '~@/assets/scss/z-indexes';
@import '~@/assets/scss/layout';
@import '~@/assets/scss/consistency';

$header-height: 5rem;

.page-header {
    position: fixed;
    top: 0;
    z-index: $page-layout-item-z-index;
    display: flex;
    align-items: center;
    gap: $spacing-m;
    width: calc(100% - #{$sidebarFullWidth});
    height: $header-height;
    padding: $spacing-l $spacing-xxl;
    background-color: $white;

    // class in AuthorizedContainer.vue that wraps all pages content
    .page-content.compact-sidebar & {
        width: calc(100% - #{$sidebarCompactWidth});
    }
}

.elipsis {
    @include lineClamp();
}

.header-searchbox {
    width: 18.75rem;

    &.expanded {
        border-radius: 0.5rem;
    }

    &.expanded ::v-deep input {
        position: static;
        min-width: 9rem;
        border-radius: 0.5rem;
        padding: $spacing-s $spacing-xs $spacing-s $spacing-m;
        border-color: gray-150;
    }
}
</style>
