
// Components
import AppDialog from '@/components/partials/AppDialog.vue';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import AppInputV3 from '@/components/partials/inputs/AppInputV3.vue';
import AppCheckbox from '@/components/partials/inputs/AppCheckbox.vue';
import IconButton from '@/components/partials/IconButton.vue';

// Helpers
import Vue from 'vue';
import { groupBy, isEmpty, uniq } from 'lodash';
import { stringToSnakeCase } from '@/common/formatting';
import { sinkValidationFunctions } from '@/__new__/services/dno/sinkConfigs/models/SinkConfig';
import { ICON_TYPES } from '@/common/iconHelper';
import { getPropLabelFromType } from '@/__new__/services/dno/events/models/EventProp';

// interfaces declarations
type Prop = {
    id: string;
    eventName: string;
    name: string;
    type: string;
    isRequired: boolean;
};
type SelectedProps = { [key: string]: Prop };
type FilteredEvents = { [key: string]: Prop[] };

export default Vue.extend({
    name: 'SinkConfigColumnPopup',
    components: {
        AppCheckbox,
        AppDialog,
        AppButton,
        AppInputV3,
        IconButton,
    },
    props: {
        uiLabels: {
            type: Object,
            default: () => undefined,
        },
        events: {
            type: Array,
            default: () => [],
        },
        isVisible: {
            type: Boolean,
            default: false,
        },
        dontUseSnakeCaseInName: {
            type: Boolean,
            default: true,
        },
    },
    validations() {
        return {
            name: {
                validation: nameVal =>
                    this.dontUseSnakeCaseInName
                        ? sinkValidationFunctions.required.fn(nameVal)
                        : sinkValidationFunctions.required.fn(nameVal) &&
                          sinkValidationFunctions.validateSnakeCase.fn(nameVal),
            },
        };
    },
    data() {
        return {
            name: '',
            filterVal: '',
            selectedProps: {} as SelectedProps,
            ICON_TYPES,
            BUTTON_TYPES,
        };
    },
    computed: {
        selectedPropsCount(): number {
            return Object.keys(this.selectedProps).length;
        },
        totalPropsSelected(): string {
            return this.$t('sinkConfigs.totalPropsSelected', { count: this.selectedPropsCount }).toUpperCase();
        },
        filteredEvents(): FilteredEvents {
            return groupBy(this.filteredPropertiesArr, prop => prop.eventName);
        },
        shouldShowRowsSection(): boolean {
            return !!Object.keys(this.filteredEvents).length;
        },

        allPropertiesArr(): Prop[] {
            return this.events
                .map(ev =>
                    ev.properties.map(prop => ({
                        ...prop,
                        eventName: ev.name,
                        eventType: ev.eventType,
                        id: ev.name + prop.name,
                    })),
                )
                .flat();
        },
        filteredPropertiesArr(): Prop[] {
            let props = this.allPropertiesArr;

            if (!isEmpty(this.selectedProps)) {
                const selectedPropId: string[] = Object.keys(this.selectedProps);
                const selectedEventNames: string[] = uniq(
                    selectedPropId.map((propId: string) => this.selectedProps[propId].eventName),
                );
                const selectedPropTypes: string[] = uniq(
                    selectedPropId.map((propId: string) => this.selectedProps[propId].type),
                );
                props = props.filter((prop: Prop) => {
                    const propIsAlreadySelected = !!this.selectedProps[prop.id];
                    const propTypeIsSelected = selectedPropTypes.includes(prop.type);
                    const eventNameIsSelected = selectedEventNames.includes(prop.eventName);

                    return propIsAlreadySelected || (propTypeIsSelected && !eventNameIsSelected);
                });
            }

            return props.filter((prop: Prop) => new RegExp(this.filterVal, 'i').test(prop.name));
        },
    },

    methods: {
        getPropLabelFromType,
        validateData() {
            this.$v.$touch();
            return this.$v.$invalid;
        },
        onNameInput(val) {
            this.name = this.dontUseSnakeCaseInName ? val : stringToSnakeCase(val);
        },
        onPropertySelect(property) {
            const propAlreadySelected = !!this.selectedProps[property.id];
            if (propAlreadySelected) {
                this.$delete(this.selectedProps, property.id);
            } else {
                this.$set(this.selectedProps, property.id, property);
            }
        },
        resetState() {
            this.$v.$reset();
            this.clearSelectedProps();
            this.clearName();
        },
        clearSelectedProps() {
            this.selectedProps = {};
            this.clearFilterVal();
        },
        clearFilterVal() {
            this.filterVal = '';
        },
        clearName() {
            this.name = '';
        },
        onSave(closePopupFlag) {
            if (this.validateData()) return;
            this.$emit('save', {
                popupIsStillVisible: closePopupFlag,
                name: this.name,
                rowValues: Object.values(this.selectedProps),
            });
            this.resetState();
        },
        onAddNew(closePopupFlag) {
            if (this.validateData()) return;
            this.onSave(closePopupFlag);
            this.resetState();
        },
        closePopup() {
            this.resetState();
            this.$emit('close');
        },
    },
});
