<template>
    <div>
        <div
            v-t="'charging.chargingSpecifications.editor.trafficType'"
            class="table-title"
        />
        <div class="primary-text">
            <span
                v-for="(type, index) in trafficTypes"
                :key="index"
            >
                {{ type.label }}
            </span>
        </div>
    </div>
</template>

<script>
import { trafficTypeConditionOptions } from '@/__new__/services/dno/charging/common/chargingSpecificationHelper';

export default {
    name: 'TrafficTypeValue',
    props: {
        value: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            trafficTypeOptions: trafficTypeConditionOptions,
        };
    },
    computed: {
        trafficTypes() {
            return this.trafficTypeOptions.filter(trafficType => this.value.values.includes(trafficType.value));
        },
    },
};
</script>
