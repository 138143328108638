<template>
    <div class="tooltip-box">
        <div
            v-if="text.length"
            class="tooltip"
        >
            <span class="text body-xs pointer">
                {{ text }}
            </span>
        </div>
        <slot />
    </div>
</template>

<script>
export default {
    name: 'QuickFilterTooltip',
    props: {
        text: {
            type: String,
            required: true,
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/z-indexes';
@import '~@/assets/scss/_consistency';

.tooltip-box {
    position: relative;
    display: inline-block;
}

.tooltip-box:hover .tooltip {
    opacity: 1;
}

.tooltip {
    position: absolute;
    z-index: $overlap-smth-z-index;
    bottom: 120%;
    right: 0;
    opacity: 0;
    transition: ease-in-out 0.2s;
}

.text {
    margin: auto $spacing-s;
    font-size: $spacing-s;
    white-space: nowrap;
    background: $blue-400;
    color: $white;
    padding: $spacing-xxs $spacing-xs;
    border-radius: 3rem;
}

.pointer::after {
    display: block;
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0.35rem 0.35rem 0 0.35rem;
    border-color: $blue-400 transparent transparent transparent;
    position: absolute;
    right: 20%;
    bottom: -0.4rem;
}
</style>
