import { RatingGroupRule } from '@/__new__/services/dno/charging/models/ChargingInterfaces';
import { parseExpressionToTree, parseExpressionsFromBe } from '@/__new__/services/dno/charging/common/expression';
import cloneDeep from 'lodash/cloneDeep';
import {
    mapLowerEntitiesVersions,
    LowerEntitiesVersions,
} from '@/__new__/services/dno/charging/common/versioningHelper';
import { RatingGroupsApiResponse } from '@/__new__/services/dno/charging/http/ratingGroups';
import { getAllConditionParametersIds } from '@/__new__/services/dno/charging/common/expression';
import {
    EntityBaseConstructorArgs,
    EntityBaseModel,
    EntityBaseDraftModel,
} from '@/__new__/services/dno/charging/models/EntityBaseModel';

type RatingGroupRuleConstructorArgs = {
    quota_amount: number;
    priority: number;
    condition_expr: any[];
};

interface RatingGroupConstructorArgs extends EntityBaseConstructorArgs {
    quota_amount: number;
    holding_time: number;
    threshold_percentage: number;
    validity_time: number;
    number: number;
    priority: number;
    final_unit_action: number;
    redirection_addr?: string;
    redirection_type?: number;
    available_statuses?: string[];
    rules?: RatingGroupRuleConstructorArgs[];
    enable_dynamic_validity_time?: boolean;
}

export interface RatingGroupDraftConstructorArgs extends RatingGroupConstructorArgs {
    operator_name: string;
    type: string;
    update_portal_id: string;
    data?: RatingGroupConstructorArgs;
}

export class RatingGroup extends EntityBaseModel {
    quotaAmount: number;
    holdingTime: number;
    thresholdPercentage: number;
    validityTime: number;
    number: number;
    priority?: number;
    finalUnitAction: number;
    redirectionAddr?: string;
    redirectionType?: number;
    availableStatuses?: string[];
    rules?: RatingGroupRule[];
    enableDynamicValidityTime: boolean;

    constructor(
        payload: RatingGroupConstructorArgs,
        draftEntities: RatingGroupDraft[],
        responseData?: RatingGroupsApiResponse,
    ) {
        super(payload, draftEntities);
        this.quotaAmount = payload.quota_amount;
        this.holdingTime = payload.holding_time;
        this.thresholdPercentage = payload.threshold_percentage;
        this.validityTime = payload.validity_time;
        this.number = payload.number;
        this.priority = payload.priority;
        this.finalUnitAction = payload.final_unit_action;
        this.redirectionAddr = payload.redirection_addr;
        this.redirectionType = payload.redirection_type;
        this.availableStatuses = payload.available_statuses || undefined;
        const rulesData = Array.isArray(payload.rules) ? cloneDeep(payload.rules) : [];
        this.rules = rulesData
            ?.sort((rule1, rule2) => rule1.priority - rule2.priority)
            .map((rule, index) => new RatingGroupRuleModal(rule, index, responseData));
        this.enableDynamicValidityTime = payload.enable_dynamic_validity_time || false;
    }
}

export class RatingGroupDraft extends EntityBaseDraftModel {
    quotaAmount: number;
    holdingTime: number;
    thresholdPercentage: number;
    validityTime: number;
    number: number;
    priority?: number;
    finalUnitAction: number;
    redirectionAddr?: string;
    redirectionType?: number;
    rules?: RatingGroupRule[];
    enableDynamicValidityTime: boolean;

    constructor(payload: RatingGroupDraftConstructorArgs) {
        super(payload);
        this.quotaAmount = payload.quota_amount;
        this.holdingTime = payload.holding_time;
        this.thresholdPercentage = payload.threshold_percentage;
        this.validityTime = payload.validity_time;
        this.number = payload.number;
        this.priority = payload.priority;
        this.finalUnitAction = payload.final_unit_action;
        this.redirectionAddr = payload.redirection_addr;
        this.redirectionType = payload.redirection_type;
        const rulesData = Array.isArray(payload.rules) ? cloneDeep(payload.rules) : [];
        this.rules =
            rulesData
                ?.sort((rule1, rule2) => rule2.priority - rule1.priority)
                ?.map((rule, index) => new RatingGroupRuleModal(rule, index)) || [];
        this.enableDynamicValidityTime = payload.enable_dynamic_validity_time || false;
    }
}

export class RatingGroupRuleModal {
    quotaAmount: number;
    priority: number;
    conditionExpression: any[];
    identifier: number;
    lowerEntitiesVersions?: LowerEntitiesVersions[] | null;

    constructor(rules: RatingGroupRuleConstructorArgs, index: number, responseData?: RatingGroupsApiResponse) {
        this.quotaAmount = rules.quota_amount || 0;
        this.priority = rules.priority;
        this.conditionExpression = parseExpressionsFromBe(rules.condition_expr);
        this.identifier = index + 1;
        this.lowerEntitiesVersions =
            responseData?.condition_parameter_by_id && rules.condition_expr
                ? mapLowerEntitiesVersions(
                      responseData?.condition_parameter_by_id,
                      getAllConditionParametersIds(rules.condition_expr) || [],
                  )
                : null;
    }
}

export function payloadData(data: any, selectedLanguage = 'en') {
    return {
        name: {
            [selectedLanguage]: data.name,
        },
        remark: data.remark,
        number: data.number,
        priority: data.priority,
        quota_amount: data.quotaAmount,
        threshold_percentage: data.thresholdPercentage,
        validity_time: data.validityTime,
        holding_time: data.holdingTime,
        final_unit_action: data.finalUnitAction,
        redirection_type: data.redirectionType,
        redirection_addr: data.redirectionAddr,
        rules: data.rules.reverse().map((rule: RatingGroupRule, index: number) => ({
            priority: index + 1,
            quota_amount: rule.quotaAmount,
            condition_expr: parseExpressionToTree(rule.conditionExpression),
        })),
        enable_dynamic_validity_time: data.enableDynamicValidityTime || false,
    };
}

export default RatingGroup;
