import { render, staticRenderFns } from "./SummaryCard.vue?vue&type=template&id=5af2f94c&scoped=true"
import script from "./SummaryCard.vue?vue&type=script&lang=ts"
export * from "./SummaryCard.vue?vue&type=script&lang=ts"
import style0 from "./SummaryCard.vue?vue&type=style&index=0&id=5af2f94c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5af2f94c",
  null
  
)

export default component.exports