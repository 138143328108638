export default {
    defaultToken: '',
    tokenPostfix: '.mtext',
    ignoreCase: true,

    tokenizer: {
        root: [
            [/[{]{2}/, { token: 'keyword', bracket: '@open', next: '@mustache' }],
            [/[^{]+/], // text
        ],

        mustache: [
            [/[^}]+/, 'keyword'],
            [/[}]{2}/, { token: 'keyword', bracket: '@close', next: '@pop' }],
        ],
    },
};
