<template>
    <AbstractEditPageWrapper>
        <template #header>
            <AppHeader />
        </template>

        <template #content>
            <!-- General section -->
            <div class="editor-section change-table">
                <!-- Changes Table -->
                <div v-if="editCategories || editState || editProperties">
                    <div class="lf-subtitle">
                        <div class="d-flex title-margin">
                            {{ $i18n.t('generic.changes') }}
                        </div>
                        <div class="lf-secondary-text">
                            {{ $i18n.t('generic.changesWillEffect') }}
                        </div>
                    </div>
                    <AppTable
                        :entities="changeEntities"
                        :canSelectColumns="false"
                        :canSort="false"
                        :columnsData="changeColumnsData"
                        :tableKey="`bulkEntityEditChangeTable-${entityType}`"
                        :data-test-id="`bulkEditEntityChange-${entityType}-table`"
                    >
                        <template #fieldValueState="{ entity }">
                            <EntityStatusIndicator :status="entity.fieldValueState" />
                        </template>
                    </AppTable>
                </div>
                <!-- Template Changes: Preset Templates Updated -->
                <div
                    v-if="bulkEditChangeData.templateChanges"
                    id="templateChanges"
                >
                    <div class="lf-subtitle">
                        <div class="d-flex title-margin">
                            {{ $i18n.t('productCatalog.bulkEditor.templateChanges') }}
                        </div>
                    </div>
                    <div class="lf-subtitle">
                        <div class="lf-secondary-text">
                            {{ $i18n.t('productCatalog.bulkEditor.followingPropertiesWereUpdated') }}
                        </div>
                    </div>

                    <AppTable
                        id="templatePropertyChanges"
                        :entities="bulkEditChangeData.templateChanges.propertyChanges"
                        :canSelectColumns="false"
                        :canSort="false"
                        :columnsData="templatePropertyChangesColumnsData"
                        :tableKey="`bulkEditTemplatePropertyChanges`"
                    >
                    </AppTable>
                    <div class="lf-subtitle">
                        <div class="lf-secondary-text">
                            {{ $i18n.t('productCatalog.bulkEditor.followingPresetTemplatesWereAddedOrRemoved') }}
                        </div>
                    </div>
                    <AppTable
                        :entities="templatePresetChanges"
                        :canSelectColumns="false"
                        :canSort="false"
                        :columnsData="templatePresetChangesColumnsData"
                        :tableKey="`bulkEditTemplatePresetChanges`"
                    >
                    </AppTable>
                </div>
                <!-- Progress Bar -->
                <div class="lf-subtitle">
                    <div class="d-flex title-margin">
                        {{ $i18n.t('generic.inProgress') }}
                    </div>
                    <div>
                        <AppProgressBar
                            :currentValue="totalEntitiesProcessed"
                            :maximalValue="expectedCount"
                            :units="$i18n.t('generic.items')"
                        />
                    </div>
                    <div class="d-flex">
                        <AppButton
                            :buttonType="BUTTON_TYPES.SECONDARY"
                            :iconType="ICON_TYPES.CHECK"
                            :iconColor="ICON_COLORS.GREEN"
                            :label="successCount.toString()"
                            class="mr-2"
                            :data-test-id="`success-count-button`"
                        />

                        <AppButton
                            :buttonType="BUTTON_TYPES.SECONDARY"
                            :iconType="ICON_TYPES.WARNING"
                            :iconColor="ICON_COLORS.RED"
                            :label="errorCount.toString()"
                            class="mr-2"
                            :data-test-id="`fail-count-button`"
                        />
                    </div>
                </div>
            </div>

            <!-- Selected Entities Summary section -->
            <div>
                <div class="d-flex lf-subtitle">
                    <div>{{ $i18n.t('generic.items') }} ({{ allEditEntities.length }})</div>
                </div>
                <div class="summary-table">
                    <AppTable
                        :entities="allEditEntities"
                        :canSelectColumns="false"
                        :canSort="true"
                        :columnsData="tableColumnsData"
                        :entityType="entityType"
                        :tableKey="`bulkEntityEditTable-${entityType}`"
                        :data-test-id="`bulkEditEntity-${entityType}-table`"
                    >
                        <template #state="{ entity }">
                            <EntityStatusIndicator :status="entity.state" />
                        </template>
                        <template #bulkEditStatus="{ entity }">
                            <BulkStatusIndicator :statusKey="entity.bulkEditStatus" />
                        </template>
                        <template #bulkEditErrorDetail="{ entity }">
                            <div v-if="entity.bulkEditErrorDetail">
                                <AppButton
                                    :isSmall="true"
                                    :label="$t('generic.seeDetails')"
                                    :data-test-id="`details-button-${entity.id}`"
                                    @click="showErrorDetails(entity)"
                                />
                            </div>
                        </template>
                    </AppTable>
                </div>
            </div>
            <AppDialogV2
                :visible="showErrorDetailDialog"
                :title="$i18n.t('generic.details')"
                :disableDefaultSaveBtn="true"
                @close="onCloseErrorDetailDialog"
            >
                <div class="form-content">{{ $i18n.t('generic.error') }}: {{ currentErrorCode }}</div>
            </AppDialogV2>
        </template>
        <!-- Buttons section -->
        <template #controls>
            <AppButton
                :buttonType="BUTTON_TYPES.PRIMARY"
                :label="$t('generic.backToList')"
                :iconType="ICON_TYPES.CHECK"
                data-test-id="submit-button"
                @click="backToList"
            />
        </template>
    </AbstractEditPageWrapper>
</template>

<script>
import Vue from 'vue';

// components
import AbstractEditPageWrapper from '@/components/layout/AbstractEditPageWrapper.vue';
import AppProgressBar from '@/components/partials/AppProgressBar.vue';
import AppHeader from '@/components/layout/AppHeader.vue';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import AppTable from '@/components/partials/AppTable.vue';
import AppDialogV2 from '@/components/partials/AppDialogV2.vue';

import { ENTITIES_TYPES_MAP } from '@/__new__/features/pc/common/entitiesTypes';
import ENTITY_TYPES from '@/common/entities/entityTypes';
import tableColumnType from '@/common/filterTable';
import EntityStatusIndicator from '@/components/partials/EntityStatusIndicator.vue';
import BulkStatusIndicator from './BulkStatusIndicator.vue';
import { ALERT_TYPES } from '@/common/alerts/Alert';
import { ICON_COLORS, ICON_TYPES } from '@/common/iconHelper';

import * as Sentry from '@sentry/vue';
import { BULK_EDIT_STATES } from '@/__new__/features/pc/common/bulkEditStates';
import LIST_PAGE_BY_ENTITY_TYPE from '@/__new__/features/pc/common/entityListRoutes';

import Actions, { Getters, Mutations } from '@/store/mutation-types';

import { mapGetters, mapActions, mapMutations } from 'vuex';

import { getBulkProgress, getBulkDetail } from '@/__new__/services/dno/pc/http/bulkChange';
import { ProductCatalogBulkProgressModel } from '@/__new__/services/dno/pc/models/ProductCatalogBulkProgressModel';

const failureEventCategory = 'failed_general';
const successfulEntityEditStatus = 'successful';

export default {
    name: 'BulkEntityFeedback',
    components: {
        AbstractEditPageWrapper,
        AppHeader,
        AppButton,
        AppTable,
        AppProgressBar,
        AppDialogV2,
        EntityStatusIndicator,
        BulkStatusIndicator,
    },
    data() {
        return {
            jobStatusPoller: null,
            failedEntities: [],
            successEntities: [],
            entityType: this.$route.params.entityType,
            ICON_COLORS,
            ICON_TYPES,
            BUTTON_TYPES,
            categoryLabel: this.$i18n.t('productCatalog.entities.plural.categories'),
            stateLabel: this.$i18n.t('generic.state'),
            showErrorDetailDialog: false,
            currentErrorDetails: {},
            currentJobStatus: {},
            failedPollCount: 0,
        };
    },
    computed: {
        ...mapGetters('productcatalog', {
            getEntitiesApproved: Getters.PC_GET_ENTITIES_BY_TYPE_APPROVED,
            getEntitiesByType: Getters.PC_GET_ENTITIES_BY_TYPE,
            getBulkEntityIdsByType: Getters.PC_GET_BULK_EDIT_ENTITY_IDS_BY_TYPE,
            getBulkEditChangeData: Getters.PC_GET_BULK_EDIT_CHANGE_DATA,
            getBulkEditChangeCompletedStatus: Getters.PC_GET_BULK_EDIT_CHANGE_COMPLETED_STATUS,
        }),
        tableColumnsData() {
            const baseColumns = [
                {
                    name: this.$i18n.t('generic.id'),
                    key: 'id',
                    field: 'id',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('generic.name'),
                    key: 'name',
                    field: 'name',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('generic.externalId'),
                    key: 'externalId',
                    field: 'externalId',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('generic.status'),
                    key: 'bulkEditStatus',
                    field: 'bulkEditStatus',
                    filterType: tableColumnType.TEXT_LIMITED_OPTIONS_CONTAINS,
                    limitedOptions: [],
                },
                {
                    name: this.$i18n.t('generic.details'),
                    key: 'bulkEditErrorDetail',
                    field: 'bulkEditErrorDetail',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
            ];

            return baseColumns;
        },
        changeColumnsData() {
            const baseColumns = [
                {
                    name: this.$i18n.t('generic.fieldName'),
                    key: 'fieldName',
                    field: 'fieldName',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('generic.value'),
                    key: 'fieldValue',
                    field: 'fieldValue',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
            ];

            if (this.editState) {
                baseColumns.pop();
                baseColumns.push({
                    name: this.$i18n.t('generic.value'),
                    key: 'fieldValueState',
                    field: 'fieldValueState',
                    filterType: tableColumnType.GENERAL_TEXT,
                });
            }

            return baseColumns;
        },
        templatePresetChangesColumnsData() {
            return [
                {
                    name: this.$i18n.t('productCatalog.bulkEditor.presetTemplateName'),
                    key: 'name',
                    field: 'name',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('generic.change'),
                    key: 'change',
                    field: 'change',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
            ];
        },
        templatePropertyChangesColumnsData() {
            return [
                {
                    name: this.$i18n.t('productCatalog.bulkEditor.propertyId'),
                    key: 'id',
                    field: 'id',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('productCatalog.bulkEditor.propertyName'),
                    key: 'name',
                    field: 'name',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('generic.change'),
                    key: 'change',
                    field: 'change',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
            ];
        },
        allEditEntities() {
            const entitiesByType = this.getEntitiesByType(this.entityType);
            return (this.bulkEditIds || [])
                .map(id => entitiesByType[id])
                .map(entity => ({
                    ...entity,
                    bulkEditStatus: this.bulkActionStatus(entity),
                    bulkEditErrorDetail: !!this.failedEntities[entity?.id],
                }));
        },
        changeEntities() {
            if (this.editCategories) {
                return [
                    {
                        fieldName: this.categoryLabel,
                        fieldValue: this.editCategoryValue,
                    },
                ];
            }
            if (this.editState) {
                return [
                    {
                        fieldName: this.stateLabel,
                        fieldValueState: this.editStateValue,
                    },
                ];
            }
            if (this.editProperties) {
                return Object.entries(this.bulkEditChangeData?.data).map(([key, value]) => ({
                    fieldName: key,
                    fieldValue: value,
                }));
            }
            return [];
        },
        /**
         * List of preset templates added or removed from a template
         * Eg: [
         *      {
         *          name: "old template",
         *          change: "Removed",
         *      },
         *      {
         *          name: "new template",
         *          change: "Added",
         *      }
         * ]
         */
        templatePresetChanges() {
            const addedPresetTemplates = this.bulkEditChangeData?.templateChanges?.namesOfAddedPresetTemplates ?? [];
            const removedPresetTemplates =
                this.bulkEditChangeData?.templateChanges?.namesOfRemovedPresetTemplates ?? [];
            return [
                ...addedPresetTemplates.map(name => ({
                    name,
                    change: this.$i18n.t('productCatalog.bulkEditor.added'),
                })),
                ...removedPresetTemplates.map(name => ({
                    name,
                    change: this.$i18n.t('productCatalog.bulkEditor.removed'),
                })),
            ];
        },
        allCategories() {
            return this.getEntitiesApproved(ENTITY_TYPES.CATEGORY);
        },
        bulkEditIds() {
            return this.getBulkEntityIdsByType(this.entityType);
        },
        bulkEditChangeData() {
            return this.getBulkEditChangeData(this.entityType);
        },
        editCategories() {
            return this.bulkEditChangeData?.data ? 'categories' in this.bulkEditChangeData.data : false;
        },
        editState() {
            return this.bulkEditChangeData ? 'state' in this.bulkEditChangeData : false;
        },
        editProperties() {
            return Boolean(this.bulkEditChangeData?.data) && !this.editState && !this.editCategories;
        },
        editStateValue() {
            return this.bulkEditChangeData?.state;
        },
        editCategoryValue() {
            return this.bulkEditChangeData?.data?.categories?.map(
                catId => this.allCategories.find(x => x.id === catId)?.name || catId,
            );
        },
        confirmSaveMessage() {
            return this.$i18n.t('productCatalog.bulkEditor.confirmSubmission');
        },
        successCount() {
            return this.currentJobStatus?.eventCategoryCounters?.[successfulEntityEditStatus] ?? 0;
        },
        errorCount() {
            return this.totalEntitiesProcessed - this.successCount;
        },
        totalEntitiesProcessed() {
            return Object.values(this.currentJobStatus?.eventCategoryCounters || []).reduce(
                (total, count) => total + count,
                0,
            );
        },
        entityTypeString() {
            const entityTypeString = ENTITIES_TYPES_MAP[this.entityType];
            return this.$i18n.tc(entityTypeString);
        },
        currentErrorCode() {
            return this.currentErrorDetails?.errorReason?.code ?? this.$i18n.t('generic.unknown');
        },
        expectedCount() {
            return this.currentJobStatus?.expectedCount ?? 0;
        },
        previousBulkChangeJobStatus() {
            return this.getBulkEditChangeCompletedStatus(this.entityType);
        },
        /**
         * The list page to which we will redirect when clicking on the back button
         * By default this will redirect to the page of the entity in question.
         * Can be overriden via the `redirectRouteName` route param
         */
        redirectRouteName() {
            return this.$route.params.redirectRouteName || LIST_PAGE_BY_ENTITY_TYPE[this.entityType];
        },
    },
    mounted() {
        this.fetchEntities();
        this.fetchCategories();
        this.setupJobStatusPoller();
    },
    beforeDestroy() {
        this.clearPoller();
    },
    methods: {
        ...mapActions('productcatalog', {
            requestEntitiesByType: Actions.PC_REQUEST_ENTITIES_BY_TYPE_AND_IDS,
        }),
        ...mapMutations('productcatalog', {
            setBulkCompletedStatus: Mutations.PC_SET_BULK_EDIT_CHANGE_COMPLETED_STATUS,
        }),
        async fetchCategories() {
            try {
                this.$Progress.start();
                await this.requestEntitiesByType({ entityType: ENTITY_TYPES.CATEGORY });
                this.$Progress.finish();
            } catch (error) {
                this.$Progress.fail();
                Sentry.captureException(error);
                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('alertMessage.errorDoingSmthTryAgain', {
                        action: 'fetching',
                        entityName: ENTITY_TYPES.CATEGORY,
                    }),
                });
            }
        },
        async fetchEntities() {
            try {
                this.$Progress.start();
                await this.requestEntitiesByType({ entityType: this.entityType });
                this.$Progress.finish();
            } catch (error) {
                this.$Progress.fail();
                Sentry.captureException(error);
                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('alertMessage.errorDoingSmthTryAgain', {
                        action: 'fetching',
                        entityName: this.entityType,
                    }),
                });
            }
        },
        setupJobStatusPoller() {
            this.currentJobStatus = this.previousBulkChangeJobStatus;
            setTimeout(() => {
                // Additional delay to make first interval 10s and next ones 5s
                this.jobStatusPoller = setInterval(() => {
                    this.getJobStatus();
                }, 5000);
            }, 5000);
        },
        checkAllEntitiesProcessed() {
            if (
                this.currentJobStatus.expectedCount > 0 &&
                this.totalEntitiesProcessed === this.currentJobStatus.expectedCount
            ) {
                this.clearPoller();
                this.setBulkCompletedStatus({
                    entityType: ENTITY_TYPES.OFFER,
                    changeStatusData: this.currentJobStatus,
                });
                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('productCatalog.bulkEditor.bulkEditCompleted'),
                    type: ALERT_TYPES.success,
                });
            }
        },
        async getJobStatus() {
            try {
                if (
                    !this.currentJobStatus?.expectedCount ||
                    this.totalEntitiesProcessed !== this.currentJobStatus?.expectedCount
                ) {
                    const progressJson = await getBulkProgress(this.bulkEditChangeData.jobId);
                    this.currentJobStatus = new ProductCatalogBulkProgressModel(progressJson);
                }

                this.updateItemStatus();
                this.checkAllEntitiesProcessed();
            } catch (e) {
                this.failedPollCount += 1;
                if (this.failedPollCount > 5) {
                    this.clearPoller();
                }
            }
        },
        updateItemStatus() {
            Object.entries(this.currentJobStatus.entityIdsByCategory).forEach(entry => {
                const [status, entityIds] = entry;

                const isSuccess = status === successfulEntityEditStatus;
                const pool = isSuccess ? this.successEntities : this.failedEntities;

                entityIds.forEach(entityId => {
                    if (!(entityId in pool)) {
                        Vue.set(pool, entityId, {
                            id: entityId,
                            category: status,
                        });
                    }
                });
            });
        },
        getJobErrorDetail(eventCategory, eventId) {
            return getBulkDetail(this.bulkEditChangeData.jobId, eventCategory, eventId);
        },
        backToList() {
            this.$router.push({
                name: this.redirectRouteName,
                params: { companyId: this.$route.params.companyId },
            });
        },
        clearPoller() {
            clearInterval(this.jobStatusPoller);
        },
        bulkActionStatus(entity) {
            if (entity) {
                if (this.successEntities[entity.id]) {
                    return BULK_EDIT_STATES.COMPLETED;
                }
                if (this.failedEntities[entity.id]) {
                    return BULK_EDIT_STATES.ERROR;
                }
            }
            return BULK_EDIT_STATES.IN_PROGRESS;
        },
        showErrorDetails(entity) {
            this.showErrorDetailDialog = true;
            const errorDetail = this.getJobErrorDetail(failureEventCategory, entity.id);
            this.currentErrorDetails = errorDetail;
        },
        onCloseErrorDetailDialog() {
            this.showErrorDetailDialog = false;
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/base';
@import '~@/assets/scss/forms';
@import '~@/assets/scss/palette';
@import '~@/assets/scss/inputs';
@import '~@/assets/scss/animations';
@import '~@/assets/scss/editor-layout-v2';
@import '~@/assets/scss/typographyV2';

.summary-table {
    max-height: 40rem;
    overflow-y: scroll;
}

.change-table {
    background-color: $blue5;
    border-radius: 1rem;
    padding: 1.5rem;
    margin-right: 1rem;
    margin-bottom: 2rem;
}

.title-margin {
    margin-bottom: 0.5rem;
}

.modal-content-wrapper {
    margin: $spacing-l;

    display: flex;
    flex-direction: column;
}
</style>
