<template>
    <CommonTemplateEntityListPageWrapper
        :pageTitle="pageTitle"
        :addButtonLabel="addButtonLabel"
        :editRouteName="editRouteName"
        :entityType="entityType"
        :entities="entities"
        :entitiesApiResponse="entitiesApiResponse"
        :entityKeyDepth="2"
        :listViewColumns="listViewColumnsFormatted"
        :overviewContentBuilder="overviewContentBuilder"
        :pagesWithImplementedDraft="pagesWithImplementedDraft"
        :writePermission="writePermission"
        @delete="onDelete"
        @approve="onApprove"
    />
</template>

<script>
// components
import CommonTemplateEntityListPageWrapper from '@/__new__/features/template/CommonTemplateEntityListPageWrapper.vue';
// vuex
import Actions, { Getters } from '@/store/mutation-types';
import { mapActions, mapGetters } from 'vuex';
// http
import { updatePolicySpecificationState } from '@/__new__/services/dno/privacyConsent/http/privacy';
import { deleteEntityDraft } from '@/__new__/services/dno/privacyConsent/entities';
// helpers
import { overviewContentBuilder } from '@/__new__/features/consent/partyPrivacyProfileSpecification/overviewContentBuilder';
import { PLURALIZATION } from '@/common/locale/labelSingularOrPlural';
import { EntityStateMapping } from '@/common/baseStatesHelper';
import ENTITY_TYPES from '@/common/entities/entityTypes';
import { STATUS_CODES } from '@/common/commonHelper';
import { ALERT_TYPES } from '@/common/alerts/Alert';
import tableColumnType from '@/common/filterTable';
import RouteNames from '@/router/routeNames';
import cloneDeep from 'lodash/cloneDeep';

export default {
    name: 'PolicySpecificationListPageNew',
    components: {
        CommonTemplateEntityListPageWrapper,
    },
    data() {
        return {
            pageTitle: this.$i18n.tc(
                `productCatalog.entities.${ENTITY_TYPES.PARTY_PRIVACY_PROFILE_SPECIFICATION.toLowerCase()}`,
                PLURALIZATION.PLURAL,
            ),
            addButtonLabel: this.$i18n.t('productCatalog.newEntity', {
                entityType: this.$i18n.t('consent.partyPrivacyProfileSpecification'),
            }),
            pagesWithImplementedDraft: [ENTITY_TYPES.PARTY_PRIVACY_PROFILE_SPECIFICATION],
            editRouteName: RouteNames.POLICY_SPECIFICATION_ENTITY_EDITOR,
            entityType: ENTITY_TYPES.PARTY_PRIVACY_PROFILE_SPECIFICATION,
            writePermission: 'PartyPrivacyProfileSpecificationWrite',
            overviewContentBuilder,
            validityControls: [
                {
                    key: 'canNotBeRevoked',
                    description: this.$i18n.t(
                        'consent.partyPrivacyProfileSpecificationLocale.dropDown.validityControls.canNotBeRevoked',
                    ),
                },
                {
                    key: 'validUntilRevocation',
                    description: this.$i18n.t(
                        'consent.partyPrivacyProfileSpecificationLocale.dropDown.validityControls.validUntilRevocation',
                    ),
                },
                {
                    key: 'durationFromGrantDate',
                    description: this.$i18n.t(
                        'consent.partyPrivacyProfileSpecificationLocale.dropDown.validityControls.durationFromGrantDate',
                    ),
                },
                {
                    key: 'specificDateRange',
                    description: this.$i18n.t(
                        'consent.partyPrivacyProfileSpecificationLocale.dropDown.validityControls.specificDateRange',
                    ),
                },
                {
                    key: 'numberOfQueries',
                    description: this.$i18n.t(
                        'consent.partyPrivacyProfileSpecificationLocale.dropDown.validityControls.numberOfQueries',
                    ),
                },
            ],
            listViewColumns: [
                {
                    name: this.$i18n.t('consent.partyPrivacyProfileSpecificationLocale.validityControl'),
                    key: 'validityControl',
                    field: 'validityControl',
                    filterType: tableColumnType.TEXT_LIMITED_OPTIONS,
                    additionalFormatter: entity =>
                        this.validityControls.find(validityControl => validityControl.key === entity.validityControl)
                            ?.description,
                    limitedOptions: [],
                },
            ],
        };
    },
    computed: {
        ...mapGetters('privacy', [
            Getters.GET_PRIVACY_ENTITIES_BY_TYPE_NOT_DELETED,
            Getters.GET_PRIVACY_TEMPLATE_BY_ID,
            Getters.GET_PRIVACY_ENTITIES_API_RESPONSE_BY_TYPE,
        ]),
        listViewColumnsFormatted() {
            return this.listViewColumns.map(col => {
                const colFormatted = { ...col };
                if (col.filterType === tableColumnType.TEXT_LIMITED_OPTIONS) {
                    colFormatted.limitedOptions = [
                        ...new Set(
                            this.entities.reduce((acc, entity) => {
                                if (entity[col.field] !== null && entity[col.field] !== undefined) {
                                    acc.push(entity[col.field]);
                                }
                                return acc;
                            }, []),
                        ),
                    ];
                }
                return colFormatted;
            });
        },
        entities() {
            const entityObject = Object.values(this[Getters.GET_PRIVACY_ENTITIES_BY_TYPE_NOT_DELETED](this.entityType));
            const formatterColumns = this.listViewColumns.filter(({ additionalFormatter }) => additionalFormatter);
            const returnEntityObject = cloneDeep(entityObject);
            return returnEntityObject.map(entity => {
                const catList = entity.categories?.map(item => {
                    const catName = this.allCategories.find(x => x.id === item);
                    if (catName) {
                        return catName.name;
                    }
                    return item;
                });
                entity.categoriesDisplay = catList;
                const { name, externalId } = this[Getters.GET_PRIVACY_TEMPLATE_BY_ID](entity.templateId);
                entity.templateName = name;
                entity.templateExternalId = externalId;

                formatterColumns.forEach(col => {
                    entity[col.field] = col.additionalFormatter(entity);
                });
                return entity;
            });
        },
        entitiesApiResponse() {
            return this[Getters.GET_PRIVACY_ENTITIES_API_RESPONSE_BY_TYPE](this.entityType);
        },
    },
    created() {
        this.fetchEntities(this.entityType);
    },
    methods: {
        ...mapActions('privacy', {
            requestTemplatesByType: Actions.PRIVACY_REQUEST_TEMPLATES_BY_TYPE_AND_IDS,
            requestEntitiesByType: Actions.PRIVACY_REQUEST_ENTITIES_BY_TYPE_AND_IDS,
        }),
        async fetchEntities(entityType) {
            await this.$withProgressBar(
                async () => {
                    await this.requestEntitiesByType({ entityType });
                },
                {
                    errorHandler: () => this.$alert(this.$i18n.t('alertMessage.failedToLoadNecessaryData')),
                },
            );
        },
        async onDelete(entity) {
            await this.$withProgressBar(
                async () => {
                    if (entity.state !== STATUS_CODES.NA) {
                        await updatePolicySpecificationState(entity.id, entity.version, EntityStateMapping.DELETED);
                    } else {
                        await deleteEntityDraft(entity.entityType, entity.id);
                    }
                    this.fetchEntities(this.entityType);
                    this.$eventBus.$emit('showAlert', {
                        message: this.$i18n.t('alertMessage.successMessageWithoutRedirect', {
                            action: this.$i18n.t('generic.stateMap.deleted').toLowerCase(),
                            entityName: entity.name,
                        }),
                        type: ALERT_TYPES.success,
                    });
                },
                {
                    errorHandler: error => {
                        if (
                            error?.response?.status === 400 &&
                            error?.response?.data?.module === 'PRIVACY' &&
                            error?.response?.data?.code === 3
                        ) {
                            this.$eventBus.$emit('showAlert', {
                                message: this.$i18n.t('consent.alerts.forbidDelete'),
                            });
                        } else {
                            this.$eventBus.$emit('showAlert', {
                                message: this.$i18n.t('alertMessage.errorDoingSmthTryAgain', {
                                    action: this.$i18n.t('generic.deleting'),
                                    entityName: entity.name,
                                }),
                            });
                        }
                    },
                },
            );
        },
        async onApprove(entity) {
            await this.$withProgressBar(
                async () => {
                    await updatePolicySpecificationState(entity.id, entity.version, EntityStateMapping.APPROVED);
                    this.fetchEntities(this.entityType);
                    this.$eventBus.$emit('showAlert', {
                        message: this.$i18n.t('alertMessage.successMessageWithoutRedirect', {
                            action: this.$i18n.t('generic.stateMap.approved').toLowerCase(),
                            entityName: entity.name,
                        }),
                        type: ALERT_TYPES.success,
                    });
                },
                {
                    errorHandler: () => {
                        this.$eventBus.$emit('showAlert', {
                            message: this.$i18n.t('alertMessage.errorDoingSmthTryAgain', {
                                action: this.$i18n.t('generic.approving'),
                                entityName: entity.name,
                            }),
                        });
                    },
                },
            );
        },
    },
};
</script>
