<template>
    <div class="label display-flex align-items-center pt-1 pb-1 pr-3 pl-3">
        <img
            src="../../assets/icons/danger.svg"
            class="mr-3"
        />
        <span class="text"><slot /></span>
    </div>
</template>

<script>
export default {
    name: 'AppLabelDanger',
    props: {
        iconSrc: {
            type: String,
            default: null,
        },
    },
};
</script>

<style lang="scss" scoped>
@import '../../assets/scss/palette';

.label {
    background: transparentize($orange, 0.95);
    border: 1px dashed $orange;
    border-radius: 2px;
}

.text {
    color: $orange;
}
</style>
