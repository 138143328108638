<template>
    <div>
        <div
            v-t="'charging.chargingSpecifications.editor.conditionText.serviceId'"
            class="table-title"
        />
        <div
            v-for="source in sourcesFormatted"
            :key="source.serviceIdType"
            class="d-flex"
        >
            <div class="mr-2">{{ source.serviceIdType }}:</div>
            <div>{{ source.value }}</div>
        </div>
    </div>
</template>

<script>
import { MAP_SERVICE_ID_OPTION_TO_LABEL } from '@/__new__/services/dno/charging/common/serviceIdConditionHelper';

export default {
    name: 'ServiceIdValue',
    props: {
        value: {
            type: Object,
            required: true,
        },
    },
    computed: {
        sourcesFormatted() {
            return Object.entries(this.value?.values).map(([serviceIdType, values]) => ({
                serviceIdType: MAP_SERVICE_ID_OPTION_TO_LABEL?.[serviceIdType],
                value: values?.join(', '),
            }));
        },
    },
};
</script>
