<!--TODO: add tests and TS to this file CEP-5815 -->
<template>
    <CollapsibleItem
        class="mt-4"
        :title="$t('cohorts.cohortsData')"
    >
        <div class="p-3">
            <template>
                <CollapsibleItem
                    v-for="group in cohortsLists"
                    :key="group.title"
                    :value="true"
                    class="mt-2"
                    :title="group.title"
                >
                    <div class="p-3">
                        <template>
                            <CohortExpressionOverview
                                :groups="group.groups"
                                :value="false"
                            >
                                <template #underTitle>
                                    <div class="mt-3 d-flex justify-content-between">
                                        <span class="heading-xs">{{ $t('segments.segmentType') }}: </span>
                                        <span class="body-xs">{{ group.entryType.label }}</span>
                                    </div>
                                    <div class="my-2 d-flex justify-content-between">
                                        <span class="heading-xs">{{ $t('sinkConfigs.cohorts.matchByField') }}: </span>
                                        <span class="body-xs">{{ group.identifierField }}</span>
                                    </div>
                                </template>
                            </CohortExpressionOverview>
                        </template>
                    </div>
                </CollapsibleItem>
            </template>
        </div>
    </CollapsibleItem>
</template>

<script>
import CohortExpressionOverview from '@/components/partials/CohortExpressionOverview.vue';
import CollapsibleItem from '@/components/partials/CollapsibleItem.vue';
import { map } from 'lodash';
import { isNonEmptyCohortExpression } from '@/common/cohortExpressionHelper';
import { LOGICAL_OPERATORS } from '@/common/segments';

export default {
    components: { CollapsibleItem, CohortExpressionOverview },
    props: {
        cohortsDataByEventId: {
            required: true,
            type: Object,
        },
    },
    computed: {
        cohortsLists() {
            return map(this.cohortsDataByEventId, (cohortData, cohortId) => {
                if (isNonEmptyCohortExpression(cohortData.cohortsObj)) {
                    return {
                        entryType: cohortData.entryType,
                        identifierField: cohortData.identifierField,
                        title: cohortId,
                        groups: [
                            {
                                title: this.$t('editors.applicableTo'),
                                subtitle: cohortData.cohortsObj.everyApplicableCohort
                                    ? this.$t('editors.identifiersInEverySegment')
                                    : '',
                                rowsCombinator: cohortData.cohortsObj.everyApplicableCohort
                                    ? LOGICAL_OPERATORS.and
                                    : LOGICAL_OPERATORS.or,
                                rows: map(cohortData.cohortsObj.applicableCohorts, cohortItem => ({
                                    name: cohortItem.name,
                                    id: cohortItem.id,
                                })),
                            },
                            {
                                title: this.$t('editors.notApplicableTo'),
                                subtitle: cohortData.cohortsObj.everyNotApplicableCohort
                                    ? this.$t('editors.identifiersInEverySegment')
                                    : '',
                                rowsCombinator: cohortData.cohortsObj.everyNotApplicableCohort
                                    ? LOGICAL_OPERATORS.and
                                    : LOGICAL_OPERATORS.or,

                                rows: map(cohortData.cohortsObj.notApplicableCohorts, cohortItem => ({
                                    name: cohortItem.name,
                                    id: cohortItem.id,
                                })),
                            },
                        ],
                    };
                }
                return null;
            }).filter(Boolean);
        },
    },
};
</script>
