import httpClient from '@/http/index';

export const getBillDeliveryTargets = async (billId: string): Promise<any> => {
    return await httpClient({
        method: 'POST',
        url: '/polaris/polaris/billGenerationManagement/v1/getBillDeliveryTargets',
        data: {
            billId,
        },
    });
};

export const getBillAttachment = async (billId: string): Promise<any> => {
    return await httpClient({
        method: 'POST',
        url: '/polaris/polaris/billGenerationManagement/v1/getBillAttachment',
        data: {
            customerBill: {
                id: billId,
            },
        },
    });
};

export const calculateHotBill = async (billingAccountId: string): Promise<any> => {
    return await httpClient({
        method: 'POST',
        url: '/polaris/polaris/billCalculation/v1/calculateHotBill',
        data: {
            accountId: billingAccountId,
        },
    });
};

export const getBillingAccount = async (umAccountId: string): Promise<any> => {
    return await httpClient({
        method: 'GET',
        url: `/polaris/polaris/accountManagement/v5/billingAccount/?externalIdentifier.id=${umAccountId}`,
    });
};

export const getBills = async (billingAccountId: string, limit = 100): Promise<any> => {
    return await httpClient({
        method: 'GET',
        url: `/polaris/polaris/customerBill/v5/customerBill/?billingAccount.id=${billingAccountId}&limit=${limit}`,
    });
};

export const getCharges = async (billingAccountId: string): Promise<any> => {
    return await httpClient({
        method: 'GET',
        url: `/polaris/polaris/customerBill/v5/appliedCustomerBillingRate/?billingAccount.id=${billingAccountId}`,
    });
};
