import { render, staticRenderFns } from "./OverviewHeaderV2.vue?vue&type=template&id=b5c71c74&scoped=true"
import script from "./OverviewHeaderV2.vue?vue&type=script&lang=js"
export * from "./OverviewHeaderV2.vue?vue&type=script&lang=js"
import style0 from "./OverviewHeaderV2.vue?vue&type=style&index=0&id=b5c71c74&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b5c71c74",
  null
  
)

export default component.exports