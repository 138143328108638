export function overviewContentBuilder() {
    const predicates = this.selectedEntity?.dataElement;
    let predicatesDetail = [];
    if (predicates?.length) {
        predicatesDetail = predicates.map(predicate => {
            return {
                name: `${predicate?.name ? predicate.name : ''} (${this.$t(
                    'consent.partyPrivacyProfileSpecificationLocale.predicate',
                )})`,
                isCollapsed: false,
                rows: [
                    {
                        name: this.$t('generic.name'),
                        value: predicate?.name ? predicate.name : '',
                    },
                    {
                        name: this.$t('consent.purpose'),
                        value: predicate?.purpose ? predicate.purpose.join(',') : '',
                    },
                    {
                        name: this.$t('consent.scope'),
                        value: predicate?.scope ? predicate.scope.join(',') : '',
                    },
                    {
                        name: this.$t('consent.personalData'),
                        value: predicate?.pd ? predicate.pd.join(',') : '',
                    },
                    {
                        name: this.$t('consent.legalBasis'),
                        value: predicate?.legalBasis ? predicate.legalBasis.join(',') : '',
                    },
                    {
                        name: this.$t('consent.consentCheck'),
                        value: predicate?.consentCheck,
                    },
                    {
                        name: this.$t('consent.optoutCheck'),
                        value: predicate?.optoutCheck,
                    },
                ],
            };
        });
    }
    const detailSection = [
        {
            name: this.$t('generic.general'),
            isCollapsed: false,
            rows: [
                {
                    name: this.$t('generic.id'),
                    value: this.selectedEntity?.id ? this.selectedEntity.id : '',
                },
                {
                    name: this.$t('generic.name'),
                    value: this.selectedEntity?.name ? this.selectedEntity.name : '',
                },
                {
                    name: this.$t('generic.description'),
                    value: this.selectedEntity?.description ? this.selectedEntity.description : '',
                },
                {
                    name: this.$t('generic.status'),
                    value: this.selectedEntity?.stateLabel ? this.selectedEntity.stateLabel : '',
                },
                {
                    name: this.$t('consent.partyPrivacyProfileSpecificationLocale.validityControl'),
                    value: this.selectedEntity?.validityControl ? this.selectedEntity.validityControl : '',
                },
                {
                    name: this.$t('consent.partyPrivacyProfileSpecificationLocale.lifecycleStatus'),
                    value: this.selectedEntity?.lifecycleStatus ? this.selectedEntity.lifecycleStatus : '',
                },
                {
                    name: this.$t('consent.partyPrivacyProfileSpecificationLocale.externalId'),
                    value: this.selectedEntity?.externalId ? this.selectedEntity.externalId : '',
                },
                {
                    name: this.$t('consent.partyPrivacyProfileSpecificationLocale.serviceId'),
                    value: this.selectedEntity?.serviceId ? this.selectedEntity.serviceId : '',
                },
                {
                    name: this.$t('consent.version'),
                    value: this.selectedEntity?.version ? this.selectedEntity.version : '',
                },
                {
                    name: this.$t('consent.partyPrivacyProfileSpecificationLocale.requireNewVersion'),
                    value: this.selectedEntity?.specUpgrade,
                },
                {
                    name: this.$t('consent.partyPrivacyProfileSpecificationLocale.entityVersion'),
                    value: this.selectedEntity?.entityVersionLabel ? this.selectedEntity.entityVersionLabel : '',
                },
                {
                    name: this.$t('consent.partyPrivacyProfileSpecificationLocale.updateTime'),
                    value: this.selectedEntity?.updateTimeFormatted ? this.selectedEntity.updateTimeFormatted : '',
                },
                {
                    name: this.$t('consent.partyPrivacyProfileSpecificationLocale.isDraft'),
                    value: this.selectedEntity?.isDraft ? this.selectedEntity.isDraft : '',
                },
                {
                    name: this.$t('consent.partyPrivacyProfileSpecificationLocale.updateUser'),
                    value: this.selectedEntity?.updateUser ? this.selectedEntity.updateUser : '',
                },
                {
                    name: this.$t('consent.partyPrivacyProfileSpecificationLocale.validityStartDate'),
                    value: this.selectedEntity?.validityStartDate
                        ? this.$localeLibrary.getFormattedDateAndTime(Number(this.selectedEntity?.validityStartDate))
                        : '',
                },
                {
                    name: this.$t('consent.partyPrivacyProfileSpecificationLocale.validityEndDate'),
                    value: this.selectedEntity?.validityEndDate
                        ? this.$localeLibrary.getFormattedDateAndTime(Number(this.selectedEntity?.validityEndDate))
                        : '',
                },
            ],
        },
        {
            name: this.$i18n.t('consent.partyPrivacyProfileSpecificationLocale.properties'),
            isCollapsed: false,
            rows: [
                {
                    name: this.$t('consent.partyPrivacyProfileSpecificationLocale.serviceId'),
                    value: this.selectedEntity?.properties?.service_id || '',
                },
                {
                    name: this.$t('consent.partyPrivacyProfileSpecificationLocale.grantType'),
                    value: this.selectedEntity?.properties?.grant_type || '',
                },
                {
                    name: this.$t('consent.application'),
                    value: this.selectedEntity?.properties?.applications || '',
                },
            ],
        },
        {
            name: this.$i18n.t('consent.partyPrivacyProfileSpecificationLocale.template'),
            isCollapsed: false,
            rows: [
                {
                    name: this.$t('generic.id'),
                    value: this.selectedEntity?.templateId || '',
                },
                {
                    name: this.$t('generic.name'),
                    value: this.selectedEntity?.templateName || '',
                },
                {
                    name: this.$t('consent.partyPrivacyProfileSpecificationLocale.templateExternalId'),
                    value: this.selectedEntity?.templateExternalId || '',
                },
            ],
        },
    ];
    return [...detailSection, ...predicatesDetail];
}
