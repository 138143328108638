export enum TARGET_TYPE {
    SUBSCRIBER = 1,
    ACCOUNT = 2,
    USER = 3,
    ORGANIZATION = 5,
}

export interface TargetTuple {
    targetId: string;
    targetType: TARGET_TYPE;
}

export default {
    TARGET_TYPE,
};
