import { AxiosPromise } from 'axios';
import http from '@/http/index';
import {
    DocumentTemplatesPayload,
    DocumentTemplatesResponse,
    Extension,
} from '@/__new__/services/dno/documents/models/DocumentInterfaces';

/**
 * Get generated documents.
 *
 * @returns {AxiosPromise}
 */
export function getGeneratedDocuments(templateId: string, extension: string): AxiosPromise<any> {
    return http({
        method: 'GET',
        url: '/v3documents/getgenerateddocuments',
        data: {
            template_id: templateId,
            extension,
        },
    });
}

/**
 * Preview document
 *
 * @returns {AxiosPromise}
 */
export function previewDocument(
    { payload, fileName, templateDefinition }: DocumentTemplatesPayload,
    extension: Extension = 'pdf',
): AxiosPromise<DocumentTemplatesResponse> {
    return http({
        method: 'POST',
        url: `/v3documents/previewdocument?extension=${extension}`,
        data: {
            template_definition: templateDefinition,
            payload,
            file_name: fileName,
        },
    });
}

/**
 * @returns {AxiosPromise}
 */
export function enrichTemplate({
    payload,
    templateDefinition,
}: {
    payload: string;
    templateDefinition: string;
}): AxiosPromise<{ data: { enriched: { template_definition: string } } }> {
    return http({
        method: 'POST',
        url: `/v3documents/enrichtemplate`,
        data: {
            templates: {
                template_definition: templateDefinition,
            },
            payload,
        },
    });
}

export default {
    getGeneratedDocuments,
    previewDocument,
};
