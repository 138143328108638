var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('AbstractTableTile',{attrs:{"entities":_vm.formattedEntities,"columnsData":_vm.columnsData,"apiResponse":_vm.rawResponse,"entityKey":'id'},on:{"rowSelected":id => _vm.selectItem(id)},scopedSlots:_vm._u([{key:"state",fn:function({ entity }){return [_c('div',{staticClass:"d-table"},[_c('AppLabel',{attrs:{"title":entity.stateLabel,"color":entity.stateColor}})],1)]}}])}),(_vm.showAdditionalSidebar)?_c('AppAditionalSidebar',{attrs:{"visible":_vm.showAdditionalSidebar},on:{"input":function($event){_vm.showAdditionalSidebar = false}},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('h2',{staticClass:"heading-sm gray-600 mb-2 text-uppercase"},[_vm._v(" "+_vm._s(_vm.$i18n.t('generic.rewardHistoryDetails'))+" ")])]},proxy:true},{key:"content",fn:function(){return [(_vm.selectedEntity)?_c('dl',{staticClass:"sidebar-data"},[_c('dt',[_vm._v(_vm._s(_vm.$i18n.t('generic.rewardRuleId')))]),_c('dd',{staticClass:"mb-0"},[_c('router-link',{attrs:{"to":{
                            name: _vm.RouteNames.REWARD_EDITOR,
                            params: {
                                id: _vm.selectedEntity.rewardRule.id,
                            },
                        },"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.selectedEntity.rewardRule.id)+" ")])],1),_c('dt',[_vm._v(_vm._s(_vm.$i18n.t('generic.rewardRuleDetails')))]),_c('dd',[(_vm.selectedEntity.rewardRule.data)?_c('AppJSON',{attrs:{"value":_vm.selectedEntity.rewardRule.data,"textareaHeight":"20rem"}}):[_vm._v(" "+_vm._s(_vm.$i18n.t('generic.empty'))+" ")]],2),_c('dt',[_vm._v(_vm._s(_vm.$i18n.t('generic.payoutProps')))]),_c('dd',[_c('AppJSON',{attrs:{"value":_vm.selectedEntity.props,"textareaHeight":"20rem"}})],1),[_c('dt',[_vm._v(_vm._s(_vm.$i18n.t('generic.orchestrationExecId')))]),_c('dd',[(_vm.selectedEntity.executionId && _vm.selectedEntity.executionPlan)?_c('router-link',{attrs:{"to":{
                                name: _vm.RouteNames.ORCHESTRATION_ENGINE_EXECUTION_DETAILS,
                                params: {
                                    id: _vm.selectedEntity.executionPlan,
                                    execId: _vm.selectedEntity.executionId,
                                },
                            },"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.selectedEntity.executionId)+" ")]):[_vm._v(" "+_vm._s(_vm.$i18n.t('generic.none'))+" ")]],2)]],2):_vm._e()]},proxy:true}],null,false,1738910925)}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }