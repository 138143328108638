import HTTP from '@/http';
import { AxiosPromise } from 'axios';

type SendTestEventsAPIPayload = {
    events: any[];
    sinks: Record<string, string[]>;
    // eslint-disable-next-line camelcase
    dry_run: boolean;
};

const prefix = {
    events: `/cepdew/customevent/api/v3/cdp/events/schema`,
    registry: `/cepdew/customevent/api/v3/schemaregistry`,
};

export function createEvent(event: unknown) {
    return HTTP.post(`${prefix.registry}/create_schema`, event);
}

export function getAllEvents() {
    return HTTP.post(`${prefix.registry}/get_all_schemas`, {
        disable_definitions_enrichment: true,
    });
}

export function updateEvent(event: unknown) {
    return HTTP.post(`${prefix.registry}/update_schema`, event);
}

export function deleteEvent(id: string, event_type: string, version: number) {
    return HTTP.post(`${prefix.registry}/delete_schema`, { id, event_type, version });
}

export function fetchPropertyTypes() {
    return HTTP.get(`${prefix.events}/get_property_types`);
}

export function getRequiredFields() {
    return HTTP.get(`${prefix.registry}/get_required_fields`);
}

export function fetchDataSources() {
    return HTTP.get('/ceapi/cep-site-backend/datasources');
}

export function sendTestEvent(payload: SendTestEventsAPIPayload) {
    return HTTP.post(`/cepdew/event/api/v3/event/send_raw_events_to_exact_sinks`, payload);
}

export function executeJSONPath(JSONPath: string, data: string): AxiosPromise<object> {
    return HTTP.post(`/cepdew/event/api/v3/event/execute_jsonpath`, {
        JSONPath,
        data,
    });
}

export default {
    fetchPropertyTypes,
    createEvent,
    updateEvent,
    deleteEvent,
    getAllEvents,
    getRequiredFields,
    fetchDataSources,
};
