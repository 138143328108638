import { type TableColumn } from '@/common/filterTable';

/* eslint-disable camelcase */
export type TableFilter = {
    field_name: string;
    operator: string;
    value: number;
    isBetween?: boolean;
};
/* eslint-enable camelcase */

/* eslint-disable camelcase */
export type CurrentPlan = { submitted_at: number };
/* eslint-enable camelcase */

export const EQUALITY = {
    IS_NOT_EQUAL_TO: 'is_not_equal_to',
    IS_EQUAL_TO: 'is_equal_to',
};

export type CutomTableColumn = TableColumn & { fieldNameOnBE: string };
