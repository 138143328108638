import i18n from '@/i18n';
import { LABEL_COLOR } from '@/common/labelsHelper';

export enum USER_STATE {
    ACTIVE = 'Active',
    PENDING = 'Pending',
    LOCKED = 'Locked',
}

export const USER_STATUS = new Map([
    [USER_STATE.ACTIVE, i18n.t('generic.stateMap.active')],
    [USER_STATE.PENDING, i18n.t('generic.stateMap.pending')],
    [USER_STATE.LOCKED, i18n.t('generic.stateMap.locked')],
]);

export const USER_STATE_INDICATOR_MAP = new Map([
    [USER_STATE.ACTIVE, LABEL_COLOR.green],
    [USER_STATE.PENDING, LABEL_COLOR.yellow],
    [USER_STATE.LOCKED, LABEL_COLOR.red],
]);
