/**
 * This file contains data structures of
 * tax attribution data coming from the DNO
 */

export interface TaxAttributionDataDno {
    tax_inclusive: boolean;
    tax_distribution: TaxDistributionDno[];
}

export interface TaxDistributionDno {
    entity_id: string;
    entity_type: string;
    distribution_amount: string;
    distribution_type: TaxDistributionTypeDno;
    rounding_indicator: boolean;
}

export enum TaxDistributionTypeDno {
    FIXED = 1,
    PERCENT = 2,
}
