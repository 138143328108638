<script>
import moment from 'moment';
import AbstractSegmentPage from '@/__new__/features/segments/dynamic/AbstractSegmentPage.vue';
import Segment from '@/__new__/services/dno/segments/models/Segment';
import {
    SEGMENT_SUPPORTED_ID_TYPES_BY_KEYS,
    GENERATION_TYPES_BY_KEYS,
    SEGMENT_CREATION_SOURCES,
    SEGMENT_CREATION_SOURCES_MAP,
} from '@/common/segments';
import { createSegment } from '@/__new__/services/dno/segments/http/segments';
import Actions, { State } from '@/store/mutation-types';
import { ALERT_TYPES } from '@/common/alerts/Alert';
import * as Sentry from '@sentry/vue';
import { isEmpty, omit } from 'lodash';
import store, { Modules } from '@/store/store';
import { callCatchWithStatus, errorMessageBuilder } from '@/common/cepHelper';

export default {
    name: 'NewSegment',
    extends: AbstractSegmentPage,
    data() {
        return {
            segmentName: '',
        };
    },
    created() {
        this.$withLoadingSpinner(async () => {
            await this.loadInitialData();
            this.addWatcher('$data');
        });
    },
    methods: {
        async loadInitialData() {
            try {
                this.$Progress.start();
                [this.segmentIdType] = this.segmentIdTypeOptions;

                this.generationType = this.$route.params.generationType
                    ? GENERATION_TYPES_BY_KEYS[this.$route.params.generationType.toUpperCase()]
                    : GENERATION_TYPES_BY_KEYS.DYNAMIC;

                const areTriggersLoaded = !isEmpty(store.state[Modules.triggers][State.TRIGGER_DEFINITIONS_BY_ID]);
                const areFiltersLoaded = !isEmpty(store.state[Modules.segments][State.FILTER_DEFINITIONS_BY_ID]);

                const promises = [this.fetchAndSetOperatorStats()];

                if (this.isDynamicGenerationType && !areFiltersLoaded) {
                    promises.push(this[Actions.LOAD_FILTER_DEFINITIONS]());
                }
                if (this.isTriggerBasedGenerationType && !areTriggersLoaded) {
                    promises.push(this[Actions.LOAD_TRIGGER_DEFINITIONS]());
                }

                await Promise.all(promises);

                this.$Progress.finish();
            } catch (err) {
                Sentry.captureException(err);
                this.$Progress.fail();
                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('alertMessage.failedToLoadNecessaryData'),
                });
            }
        },
        saveSegment() {
            if (this.saveInProcess) {
                return;
            }

            const { segmentDescription } = this.$refs;
            segmentDescription.$v.name.$touch();
            if (segmentDescription.$v.name.$invalid) {
                this.$alert(segmentDescription.errorMessage);
                return;
            }

            const segment = new Segment(
                null,
                this.segmentName,
                moment().unix(),
                this.updatedFiltersJson.map(f => omit(f, 'filterDefinitionId')),
                this.segmentDescription,
                SEGMENT_SUPPORTED_ID_TYPES_BY_KEYS[this.segmentIdType],
                this.generationType,
                this.updatedAddConditionsJson.map(f => omit(f, 'filterDefinitionId')),
                this.updatedRemoveConditionsJson.map(f => omit(f, 'filterDefinitionId')),
                Number(SEGMENT_CREATION_SOURCES_MAP[SEGMENT_CREATION_SOURCES.USER_MADE]),
            );
            segment.produceEventsEnterLeave = this.produceEventsEnterLeave;
            try {
                this.validateSegment(segment);
                if (!this.isSegmentValid) {
                    return;
                }

                this.$Progress.start();
                this.saveInProcess = true;
                createSegment(segment)
                    .then(response => {
                        segment.id = response.data;
                        this.entityEditorMixin.successfullySaved = true;
                        this.$eventBus.$emit('showAlert', {
                            message: this.successSaveMessage,
                            type: ALERT_TYPES.success,
                        });
                        // here we change saveInProcess to false
                        this.navigateToSegmentsListPageWithTimeout();
                    })
                    .catch(
                        callCatchWithStatus(
                            errorMessageBuilder(errorMessageFn => {
                                this.$Progress.fail();
                                this.saveInProcess = false;
                                this.$eventBus.$emit('showAlert', {
                                    message: errorMessageFn(segment.name),
                                });
                            }),
                        ),
                    )
                    .finally(() => {
                        this.$Progress.finish();
                    });
            } catch (e) {
                Sentry.captureException(e);
                this.$Progress.fail();
                // ToDo: handle segment invalid name highlight when server implements error codes
                this.$eventBus.$emit('showAlert', {
                    message: e.message,
                });
            }
        },
    },
};
</script>
