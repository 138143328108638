<template>
    <svg
        :height="height"
        width="8"
        class="vertical-svg"
    >
        <marker
            :id="`circle-blue-${uuid}`"
            markerWidth="8"
            markerHeight="8"
            refX="4"
            refY="0"
        >
            <circle
                cx="4"
                cy="4"
                r="4"
                stroke="none"
                fill="#54cfe4"
            />
        </marker>
        <line
            v-if="firstInLine"
            :marker-start="`url(#circle-blue-${uuid})`"
            x1="0"
            y1="0"
            x2="0"
            y2="100"
            style="stroke: #f1f1f1; stroke-width: 1px"
            class="vertical-service-line"
        />
        <line
            v-else
            x1="0"
            y1="0"
            x2="0"
            y2="100"
            style="stroke: #f1f1f1; stroke-width: 1px"
            class="vertical-service-line"
        />
    </svg>
</template>

<script>
export default {
    name: 'AppVerticalLine',
    props: {
        firstInLine: {
            type: Boolean,
            default: false,
        },
        uuid: {
            type: String,
            default: '',
        },
        height: {
            type: String,
            default: '46px',
        },
    },
};
</script>

<style lang="scss" scoped>
.vertical-svg {
    transform: translateY(-50%);
}

.vertical-service-line {
    transform: translateX(50%);
}
</style>
