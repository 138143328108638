<template>
    <AbstractListPageWrapper
        :pageTitle="$i18n.t('productCatalog.entities.plural.categories')"
        :isOverviewEnabled="isOverviewEnabled"
        :entitiesCount="filteredCategories.length"
        @searchQueryChanged="setSearchQuery"
    >
        <template slot="button">
            <ResponseModalButton
                :response="categoriesApiResponse"
                :title="$i18n.t('productCatalog.entities.plural.categories')"
            />
        </template>
        <template slot="filterTable">
            <FilterTable
                :columns="tableColumnsData"
                @filterAdded="onFilterAdded"
            />
        </template>

        <template slot="headerButtons">
            <AppButton
                v-if="isUserAllowed('CategoriesWrite')"
                :buttonType="BUTTON_TYPES.PRIMARY"
                :iconType="ICON_TYPES.PLUS"
                :label="$i18n.t('productCatalog.categories.newCategory')"
                @click="createCategory"
            />
        </template>

        <template slot="allFilters">
            <TableFiltersTags
                :filterTableMixin="filterTableMixin"
                class="my-3 ml-2"
                @removeFilter="removeFilter"
                @removeAllFilters="removeAllFilters"
            />
        </template>

        <template slot="table">
            <div class="container-fluid h-100 position-relative">
                <AppSpinner :isVisible="isDataLoading" />
                <div class="table-header row">
                    <div class="col-2">
                        {{ $i18n.t('generic.icon') }}
                    </div>
                    <div class="col-4">
                        {{ $i18n.t('generic.name') }}
                    </div>
                    <div class="col-2">
                        {{ $i18n.t('category.usedIn') }}
                    </div>
                    <div class="col-2">
                        {{ $i18n.t('generic.publishedState') }}
                    </div>
                    <div class="col-2">
                        {{ $i18n.t('generic.draftState') }}
                    </div>
                </div>
                <template v-if="filteredCategories.length">
                    <div class="table-content">
                        <NodeTree
                            v-for="category in filteredCategories"
                            :key="category.id"
                            :treeList="category"
                            :mapper="treeItem => getMultiLangFieldValueByLocale(treeItem.name)"
                            :selectedId="selectedEntityId"
                            :searchQuery="searchQueryForTable"
                            :hoverButtons="hoverButtons"
                            class="root"
                            @selectedItem="selectCategory"
                            @hoverBtnClick="handleHoverBtnClick"
                        />
                    </div>
                </template>
                <template v-else-if="!isDataLoading">
                    <div class="empty-table-row d-flex flex-row justify-content-center align-items-center">
                        <img
                            src="@/assets/icons/empty-table.svg"
                            class="empty-table-row-icon"
                        />
                        <div class="empty-table-row-text">
                            {{ $i18n.t('table.empty') }}
                        </div>
                    </div>
                </template>
            </div>
        </template>

        <template slot="overview">
            <EntityOverview
                :entity="selectedEntity"
                :sections="sectionsList"
                :sectionsEnabled="true"
                entityType="category"
                @closeOverview="isOverviewEnabled = false"
            >
                <div :slot="`section-1-header`">
                    <OverviewHeader
                        v-if="selectedEntityId"
                        :entityName="getMultiLangFieldValueByLocale(selectedEntity.name, selectedLanguage)"
                        :showIcon="true"
                        entityType="category"
                    />
                    <div
                        v-show="overviewDescription"
                        class="pr-4 pb-4"
                    >
                        <div class="description-title">DESCRIPTION</div>
                        <div class="description-content">
                            {{ overviewDescription }}
                        </div>
                    </div>
                </div>

                <div :slot="`section-1-content`">
                    <OverviewList :lists="detailsSections" />
                    <AppLabelDanger
                        v-if="errorText"
                        class="label-danger"
                    >
                        {{ errorText }}
                    </AppLabelDanger>
                </div>

                <div :slot="`section-2-header`">
                    <OverviewHeader
                        v-if="selectedEntityId"
                        :entityName="getMultiLangFieldValueByLocale(selectedEntity.name)"
                        :showIcon="true"
                        :collapse="true"
                        entityType="category"
                    />
                </div>

                <div :slot="`section-2-content`">
                    <OverviewList :lists="offersSections" />
                </div>
            </EntityOverview>
        </template>
    </AbstractListPageWrapper>
</template>

<script>
// Vuex
import { createNamespacedHelpers } from 'vuex';
import Actions, { Getters } from '@/store/mutation-types';

import { cloneDeep, groupBy } from 'lodash';
import AbstractListPageWrapper from '@/components/layout/AbstractListPageWrapper.vue';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import { ICON_TYPES } from '@/common/iconHelper';
import AppLabelDanger from '@/components/partials/AppLabelDanger.vue';
import RouteNames from '@/router/routeNames';
import EntityOverview from '@/components/partials/entityOverview/EntityOverview.vue';
import OverviewList from '@/components/partials/entityOverview/OverviewList.vue';
import OverviewHeader from '@/components/partials/entityOverview/OverviewHeader.vue';
import ENTITY_TYPES from '@/common/entities/entityTypes';
import { approveEntity, deleteEntity } from '@/__new__/services/dno/pc/entityState';
import NodeTree from './NodeTree.vue';
import { EntityStateMapping, entityStateReverseMap } from '@/common/baseStatesHelper';
import { ALERT_TYPES } from '@/common/alerts/Alert';
import { EDITOR_MODE, getMultiLangFieldValueByLocale } from '@/common/entities/entityHelper';
import { onlyFirstLetterUppercase } from '@/common/utils';
import { deleteEntityDraft } from '@/__new__/services/dno/pc/entities';
import FilterTable from '@/components/partials/FilterTable.vue';
import tableColumnType from '@/common/filterTable';
import FilterTableMixin from '@/components/partials/FilterTableMixin.vue';
import TableFiltersTags from '@/components/filters/TableFiltersTags.vue';
import AppSpinner from '@/components/partials/AppSpinner.vue';
import ResponseModalButton from '@/components/partials/ResponseModalButton.vue';
import { isUserAllowed } from '@/services/permissions/permissions.service';
import { PLURALIZATION } from '@/common/locale/labelSingularOrPlural';

const { mapGetters, mapActions } = createNamespacedHelpers('productcatalog');
const entityType = 'category';

export default {
    name: 'Categories',
    components: {
        AbstractListPageWrapper,
        AppButton,
        AppLabelDanger,
        EntityOverview,
        OverviewList,
        OverviewHeader,
        NodeTree,
        FilterTable,
        AppSpinner,
        TableFiltersTags,
        ResponseModalButton,
    },

    mixins: [FilterTableMixin],

    data() {
        return {
            alertButtons: {},
            searchQueryForTable: '',
            selectedLanguage: '',
            sectionsList: [
                {
                    id: 1,
                    name: 'Details',
                    icon: 'details-secondary',
                },
                {
                    id: 2,
                    name: 'Offers',
                    icon: 'details',
                },
            ],
            selectedEntity: null,
            isDataLoading: false,
            isOverviewEnabled: false,
            ICON_TYPES,
            BUTTON_TYPES,
        };
    },

    computed: {
        ...mapGetters([Getters.PC_GET_ENTITIES_BY_TYPE_NOT_DELETED, Getters.PC_GET_ENTITIES_API_RESPONSE_BY_TYPE]),
        languageDefault() {
            return this.$store.getters[`operators/${Getters.languageDefault}`];
        },
        allCategories() {
            return this[Getters.PC_GET_ENTITIES_BY_TYPE_NOT_DELETED](ENTITY_TYPES.CATEGORY);
        },
        categoriesApiResponse() {
            return this[Getters.PC_GET_ENTITIES_API_RESPONSE_BY_TYPE](ENTITY_TYPES.CATEGORY);
        },
        categoryList() {
            const categoriesGroupedByParent = groupBy(this.allCategories, c => c?.parent);
            return this.allCategories.map(category => {
                category.formattedState = this.entityState(category.state);
                category.offers = this.filterOffers(category);
                category.usedIn =
                    (categoriesGroupedByParent[category.id] || []).length || this.filterOffers(category).length
                        ? this.$i18n.t('category.inUse')
                        : this.$i18n.t('category.notInUse');
                category.children = categoriesGroupedByParent[category.id] || [];
                return category;
            });
        },
        filteredAllCategories() {
            return this.filteredEntitiesMixin(this.categoryList);
        },
        filteredCategories() {
            return this.categoriesToShow.filter(c => this.anyCategoryInFiltered(c, false));
        },
        categoriesToShow() {
            const categories = cloneDeep(this.categoryList);
            return categories.filter(c => !c?.parent);
        },
        offers() {
            return this[Getters.PC_GET_ENTITIES_BY_TYPE_NOT_DELETED](ENTITY_TYPES.OFFER);
        },
        selectedEntityId() {
            return this.selectedEntity ? this.selectedEntity.id : null;
        },
        detailsSections() {
            if (!this.selectedEntityId || !this.filteredCategories.length) {
                return [];
            }
            return [
                {
                    name: this.$i18n.t('category.details'),
                    properties: [
                        {
                            value: this.selectedEntity.id,
                            label: this.$i18n.t('generic.id'),
                        },
                        {
                            value: getMultiLangFieldValueByLocale(this.selectedEntity.name),
                            label: this.$i18n.tc('generic.label', PLURALIZATION.SINGULAR),
                        },
                        {
                            value: this.selectedEntity.sortPriority,
                            label: this.$i18n.t('generic.sortPriority'),
                        },
                        {
                            value: this.selectedEntity.unique ?? false,
                            label: this.$i18n.t('productCatalog.categories.isUnique'),
                        },
                    ],
                },
            ];
        },
        offersSections() {
            if (!this.selectedEntityId || !this.filteredCategories.length) {
                return [];
            }

            const properties = this.selectedEntity.offers.map(offer => ({
                label: getMultiLangFieldValueByLocale(offer.name),
            }));

            return [
                {
                    name: this.$i18n.t('category.offersWithin'),
                    properties,
                },
            ];
        },
        errorText() {
            if (!this.selectedEntityId || !this.filteredCategories.length) {
                return '';
            }

            if (!this.selectedEntity.children.length && !this.selectedEntity.offers.length) {
                if (this.selectedEntity.depth === 1) {
                    return this.$i18n.t('alertMessage.categories.notConnected');
                }
                return this.$i18n.t('alertMessage.categories.empty');
            }

            const hasActiveChildren = this.selectedEntity.children
                .concat(this.selectedEntity.offers)
                .find(item => item.state === EntityStateMapping.APPROVED);

            if (!hasActiveChildren) {
                return this.$i18n.t('alertMessage.categories.hasNoActiveChildren');
            }

            if (this.selectedEntity.children.length && this.selectedEntity.offers.length) {
                return this.$i18n.t('alertMessage.categories.hasMixChildren');
            }

            return '';
        },
        overviewDescription() {
            if (!this.selectedEntityId || !this.filteredCategories.length) {
                return '';
            }

            return getMultiLangFieldValueByLocale(this.selectedEntity.description, this.selectedLanguage);
        },
        tableColumnsData() {
            return [
                {
                    name: this.$i18n.t('generic.name'),
                    field: 'name',
                    filterType: tableColumnType.GENERAL_TEXT,
                    mapper: category => getMultiLangFieldValueByLocale(category.name),
                },
                {
                    name: this.$i18n.t('category.usedIn'),
                    filterType: tableColumnType.TEXT_LIMITED_OPTIONS,
                    field: 'usedIn',
                    limitedOptions: Array.from(new Set(this.categoryList.map(e => e.usedIn))),
                },
                {
                    name: this.$i18n.t('generic.state'),
                    filterType: tableColumnType.TEXT_LIMITED_OPTIONS,
                    field: 'formattedState',
                    limitedOptions: Array.from(new Set(this.categoryList.map(e => e.formattedState))),
                },
            ];
        },
        hoverButtons() {
            if (isUserAllowed('CategoriesWrite')) {
                return {
                    approveBtn: {
                        key: 'approve',
                        label: this.$i18n.t('generic.approve'),
                        icon: ICON_TYPES.CHECK,
                        isVisible: elem => elem.state === EntityStateMapping.UNAPPROVED,
                    },
                    editBtn: {
                        key: 'edit',
                        label: this.$i18n.t('generic.edit'),
                        icon: ICON_TYPES.EDIT,
                        isVisible: elem =>
                            elem.state === EntityStateMapping.UNAPPROVED ||
                            elem.state === EntityStateMapping.APPROVED ||
                            elem.state === EntityStateMapping.DRAFT,
                    },
                    deleteBtn: {
                        key: 'delete',
                        label: this.$i18n.t('generic.delete'),
                        icon: ICON_TYPES.DELETE,
                        isVisible: elem =>
                            elem.state === EntityStateMapping.UNAPPROVED ||
                            elem.state === EntityStateMapping.APPROVED ||
                            elem.state === EntityStateMapping.DRAFT,
                    },
                    cloneBtn: {
                        key: 'clone',
                        label: this.$i18n.t('generic.clone'),
                        icon: ICON_TYPES.CLONE,
                        isVisible: elem =>
                            elem.state === EntityStateMapping.UNAPPROVED ||
                            elem.state === EntityStateMapping.APPROVED ||
                            elem.state === EntityStateMapping.DELETED,
                    },
                    readonlyBtn: {
                        key: 'readonly',
                        label: this.$i18n.t('generic.readonly'),
                        icon: ICON_TYPES.INFO,
                        isVisible: elem =>
                            elem.state === EntityStateMapping.UNAPPROVED || elem.state === EntityStateMapping.APPROVED,
                    },
                };
            }
            return {};
        },
    },

    async created() {
        try {
            this.selectedLanguage = this.languageDefault;
            this.isDataLoading = true;
            await Promise.all([
                this[Actions.PC_REQUEST_ENTITIES_BY_TYPE_AND_IDS]({ entityType: ENTITY_TYPES.OFFER }),
                this[Actions.PC_REQUEST_ENTITIES_BY_TYPE_AND_IDS]({ entityType: ENTITY_TYPES.CATEGORY }),
                this[Actions.PC_REQUEST_ENTITIES_BY_TYPE_AND_IDS]({ entityType: ENTITY_TYPES.SERVICE }),
            ]);
        } catch (error) {
            this.$eventBus.$emit('showAlert', {
                message: this.$i18n.t('alertMessage.failedToLoadNecessaryData'),
            });
        } finally {
            this.isDataLoading = false;
        }
    },

    methods: {
        ...mapActions([Actions.PC_REQUEST_ENTITIES_BY_TYPE_AND_IDS]),
        createCategory() {
            this.$router.push({
                name: RouteNames.CATEGORY_EDITOR,
                params: { companyId: this.$route.params.companyId },
            });
        },
        handleHoverBtnClick(handleObj) {
            switch (handleObj.key) {
                case this.hoverButtons.approveBtn.key:
                    this.approveAction(handleObj.value.id, handleObj.value.version);
                    break;
                case this.hoverButtons.editBtn.key:
                    this.editAction(handleObj.value.id);
                    break;
                case this.hoverButtons.deleteBtn.key:
                    this.deleteAction(handleObj.value.id, handleObj.value.version, handleObj.value.state);
                    break;
                case this.hoverButtons.cloneBtn.key:
                    this.cloneAction(handleObj.value.id);
                    break;
                case this.hoverButtons.readonlyBtn.key:
                    this.readonlyAction(handleObj.value.id);
                    break;
                default:
                    break;
            }
        },
        async approveAction(id, version) {
            try {
                this.$Progress.start();
                await approveEntity(entityType, id, version);
                await this[Actions.PC_REQUEST_ENTITIES_BY_TYPE_AND_IDS]({ entityType: ENTITY_TYPES.CATEGORY });
                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('alertMessage.successMessageWithoutRedirect', {
                        entityName: this.$i18n.t('productCatalog.entities.category'),
                        action: this.$i18n.t('generic.stateMap.approved').toLowerCase(),
                    }),
                    type: ALERT_TYPES.success,
                });
                this.$Progress.finish();
            } catch (e) {
                this.$Progress.fail();
                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('entityStatesControl.couldNotApprove'),
                    type: ALERT_TYPES.warning,
                });
            }
        },
        editAction(id) {
            this.$router.push({
                name: RouteNames.CATEGORY_EDITOR,
                params: {
                    id,
                    mode: EDITOR_MODE.EDIT,
                    companyId: this.$route.params.companyId,
                },
            });
        },
        async deleteAction(id, version, state) {
            try {
                this.$Progress.start();
                if (state !== 24) {
                    await deleteEntity(entityType, id, version);
                } else {
                    await deleteEntityDraft(entityType, id);
                }
                await this[Actions.PC_REQUEST_ENTITIES_BY_TYPE_AND_IDS]({ entityType: ENTITY_TYPES.CATEGORY });
                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('alertMessage.successMessageWithoutRedirect', {
                        entityName: this.$i18n.t('productCatalog.entities.category'),
                        action: this.$i18n.t('generic.deleted'),
                    }),
                    type: ALERT_TYPES.success,
                });
                this.$Progress.finish();
            } catch (e) {
                this.$Progress.fail();
                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('alertMessage.errorDoingSmthTryAgain', {
                        action: this.$i18n.t('generic.deleting'),
                        entityName: this.$i18n.t('productCatalog.entities.plural.categories'),
                    }),
                });
            }
        },
        cloneAction(id) {
            const clone = true;

            this.$router.push({
                name: RouteNames.CATEGORY_EDITOR,
                params: {
                    id,
                    clone,
                    mode: EDITOR_MODE.EDIT,
                    companyId: this.$route.params.companyId,
                },
            });
        },
        readonlyAction(id) {
            this.$router.push({
                name: RouteNames.CATEGORY_EDITOR,
                params: {
                    id,
                    mode: EDITOR_MODE.VIEW,
                    companyId: this.$route.params.companyId,
                },
            });
        },
        setSearchQuery(query) {
            this.searchQueryForTable = query;
        },
        selectCategory(entity, depth) {
            this.selectedEntity = entity;
            this.selectedEntity.depth = depth;
            this.isOverviewEnabled = true;
        },
        entityState(s) {
            return s
                ? this.$i18n.t(`finalStateMapper.${onlyFirstLetterUppercase(entityStateReverseMap(s))}`)
                : this.$i18n.t('generic.N/A');
        },
        filterOffers(category) {
            return this.offers.filter(o => o.categories?.find(c => c === category.id));
        },
        anyCategoryInFiltered(category, any) {
            let found = any;
            if (this.filteredAllCategories.some(c => c.id === category.id)) found = true;
            if (!found) {
                if (Object.prototype.hasOwnProperty.call(category, 'children') && Array.isArray(category.children)) {
                    category.children.forEach(c => {
                        found = found || this.anyCategoryInFiltered(c, false);
                    });
                }
            }
            return found;
        },
        getMultiLangFieldValueByLocale,
        isUserAllowed,
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/palette';
@import '~@/assets/scss/_colors';
@import '~@/assets/scss/_icons';
@import '~@/assets/scss/_animations';

$icon-path: '~@/assets/icons/';

.table {
    margin-bottom: 0;

    &-header {
        font-weight: bold;
        padding-left: 50px;
    }

    &-content {
        height: calc(100vh - 170px);
        overflow-y: scroll;
        overflow-x: hidden;
        font-size: 14px;
    }

    &-title {
        height: 48px;
        font-size: 16px;
        align-items: center;
    }

    &-button {
        max-width: 200px;
    }
}

.description {
    &-title {
        font-size: 13px;
        font-weight: 600;
        color: $gray5;
        margin-bottom: 8px;
    }

    &-content {
        padding: 10px 12px;
        background: $white;
        border-radius: 4px;
        font-size: 14px;
        color: $gray60;
    }
}

.label-danger {
    margin: 0 16px 16px;
}

.root {
    border-bottom: 1px solid $dirty-white;
}

.empty-table-row {
    text-align: center;
    background-color: $white;
    border-bottom: 1px solid $dirty-white;
    padding-top: 3rem;
    padding-bottom: 3rem;

    .empty-table-row-text {
        opacity: 0.5;
        font-size: 1rem;
        color: $blue;
        width: fit-content;
        font-weight: 600;
    }

    .empty-table-row-icon {
        margin-right: 2rem;
        width: 100px;
        height: 100px;
    }
}
</style>
