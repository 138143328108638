<template>
    <div class="d-flex">
        <span
            class="tag-element heading-xs overview-mode mb-1"
            @click="emitClick"
        >
            {{ label }}
        </span>
    </div>
</template>

<script>
export default {
    name: 'ChargingTagLabel',
    props: {
        label: {
            type: [String, Number],
            required: true,
            default: '',
        },
    },
    methods: {
        emitClick(e) {
            this.$emit('click', e);
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/_consistency';

.tag-element {
    display: inline-flex;
    align-items: center;
    color: $blue-600;
    background: $gray-200;
    padding: $spacing-xxs $spacing-xxs $spacing-xxs $spacing-xs;
    border-top-left-radius: $spacing-xxs;
    border-bottom-left-radius: $spacing-xxs;
    cursor: pointer;

    &.overview-mode {
        border-radius: $spacing-xxs;
        padding: $spacing-xxs $spacing-xs;
    }
}
</style>
