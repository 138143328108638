
import Vue, { type PropType } from 'vue';

export type Tab = {
    id: number | string;
    label: string;
    dataflow?: string;
};

export default Vue.extend({
    name: 'BulkUploadTabs',
    props: {
        value: {
            type: [String, Number] as PropType<Tab['id']>,
            required: true,
        },
        tabs: {
            type: Array as PropType<Tab[]>,
            required: true,
        },
    },
    created() {
        if (this.tabs.length) {
            this.$emit('input', this.tabs[0].id);
        }
    },
});
