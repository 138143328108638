import { AxiosPromise } from 'axios';
import http from '@/http/index';

export interface LayoutDataItem {
    i: string;
    x: number;
    y: number;
    size: string;
    props: object;
    tileTitle: string;
    componentKey: string;
}

export interface LayoutData {
    layout: LayoutDataItem[];
}
export interface LayoutPageResponse {
    layout: LayoutData[];
}

export function getLayoutPageByPageId(page: string): AxiosPromise<LayoutPageResponse> {
    return http({
        method: 'GET',
        url: '/layout/getpagebyid',
        headers: {
            'Content-type': 'application/json',
        },
        params: { page },
    });
}

export function getLayoutDefaultByPageId(page: string): AxiosPromise<LayoutPageResponse> {
    return http({
        method: 'GET',
        url: '/layout/defaultforpage',
        headers: {
            'Content-type': 'application/json',
        },
        params: { page },
    });
}

export function saveLayoutPageByPageId(page: string, layout: object): AxiosPromise<LayoutPageResponse> {
    return http({
        method: 'POST',
        url: '/layout',
        headers: {
            'Content-type': 'application/json',
        },
        data: { page, layout },
    });
}

export function saveLayoutDefaultPageByPageId(page: string, layout: object): AxiosPromise<LayoutPageResponse> {
    return http({
        method: 'POST',
        url: '/layout/default',
        headers: {
            'Content-type': 'application/json',
        },
        data: { page, layout },
    });
}

export function revertLayoutPageByPageId(page: string): AxiosPromise<LayoutPageResponse> {
    return http({
        method: 'POST',
        url: '/layout/revert',
        headers: {
            'Content-type': 'application/json',
        },
        data: { page },
    });
}
