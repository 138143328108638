<template>
    <div v-click-outside="closeFilter">
        <slot
            name="button"
            :clickHandler="toggleFilterVisibility"
        >
            <AppButton
                :buttonType="BUTTON_TYPES.SECONDARY"
                :iconType="ICON_TYPES.FILTER"
                :label="$i18n.t('generic.filter')"
                :class="{ clicked: isFilterVisible }"
                class="btn position-relative"
                @click="toggleFilterVisibility"
            />
        </slot>
        <div
            v-if="isFilterVisible"
            class="d-flex align-items-center filter-component pr-4 pl-1"
        >
            <AppMultiselectV3
                v-model="selectedColumn"
                :options="columns"
                :placeholder="$i18n.t('generic.columnName')"
                :allowEmpty="false"
                :small="true"
                :newDesign="true"
                :widthStyleObject="multiselectWidth"
                class="mr-2 multiselect-picker"
                label="name"
                trackBy="name"
                @input="onColumnSelected"
            />
            <template v-if="selectedColumn">
                <template v-if="selectedColumn.filterType.key === tableColumnType.GENERAL_TEXT.key">
                    <AppMultiselectV3
                        v-model="condition[tableColumnType.GENERAL_TEXT.key]"
                        :options="conditions"
                        :placeholder="$i18n.t('components.appmultiselect.placeholders.chooseOption')"
                        :allowEmpty="false"
                        :small="true"
                        :borderNone="true"
                        :blueArrow="true"
                        :newDesign="true"
                        :widthStyleObject="{ width: '7.7rem' }"
                        class="mr-2"
                        label="label"
                        trackBy="key"
                    />
                    <AppInputV3
                        v-model="values[tableColumnType.GENERAL_TEXT.key].value"
                        :type="'text'"
                        class="input-min-width"
                        :placeholder="$i18n.t('generic.value')"
                        :small="true"
                    />
                </template>
                <template v-else-if="selectedColumn.filterType.key === tableColumnType.NUMBER.key">
                    <AppMultiselectV3
                        v-model="condition[tableColumnType.NUMBER.key]"
                        :options="conditions"
                        :placeholder="$i18n.t('components.appmultiselect.placeholders.chooseOption')"
                        :allowEmpty="false"
                        :small="true"
                        :borderNone="true"
                        :blueArrow="true"
                        :newDesign="true"
                        :widthStyleObject="{ width: '9rem' }"
                        class="mr-2"
                        label="label"
                        trackBy="key"
                    />
                    <AppInputV3
                        v-model="values[tableColumnType.NUMBER.key].value"
                        :type="'number'"
                        class="input-min-width"
                        :placeholder="$i18n.t('generic.value')"
                    />
                </template>
                <template v-else-if="selectedColumn.filterType.key === tableColumnType.DATE.key">
                    <AppMultiselectV3
                        v-model="condition[tableColumnType.DATE.key]"
                        :options="conditions"
                        :placeholder="$i18n.t('components.appmultiselect.placeholders.chooseOption')"
                        :allowEmpty="false"
                        :small="true"
                        :borderNone="true"
                        :blueArrow="true"
                        :newDesign="true"
                        :widthStyleObject="{ width: '9rem' }"
                        class="mr-2"
                        label="label"
                        trackBy="key"
                    />
                    <DateTimePicker
                        :value="dateTimePickerRange"
                        :range="rangeDate"
                        type="date"
                        class="filter-date-time-picker"
                        data-test-id="filter-table-date-picker"
                        @input="onDateSelected"
                        @range-selected="onDateRangeSelected"
                    />
                </template>
                <template v-else-if="selectedColumn.filterType.key === tableColumnType.TEXT_LIMITED_OPTIONS.key">
                    <AppMultiselectV3
                        v-model="condition[tableColumnType.TEXT_LIMITED_OPTIONS.key]"
                        :options="conditions"
                        :placeholder="$i18n.t('components.appmultiselect.placeholders.chooseOption')"
                        :allowEmpty="false"
                        :small="true"
                        :borderNone="true"
                        :blueArrow="true"
                        :newDesign="true"
                        :widthStyleObject="{ width: '7.7rem' }"
                        class="mr-2"
                        label="label"
                        trackBy="key"
                    />
                    <AppMultiselectV3
                        v-model="values[tableColumnType.TEXT_LIMITED_OPTIONS.key].value"
                        :options="limitedOptions"
                        :allowEmpty="false"
                        :placeholder="$i18n.t('components.appmultiselect.placeholders.chooseOption')"
                        :small="true"
                        :borderNone="true"
                        :blueArrow="true"
                        :newDesign="true"
                        :widthStyleObject="{ width: '12.5rem' }"
                    />
                </template>
                <template
                    v-else-if="selectedColumn.filterType.key === tableColumnType.TEXT_LIMITED_OPTIONS_CONTAINS.key"
                >
                    <AppMultiselectV3
                        v-model="condition[tableColumnType.TEXT_LIMITED_OPTIONS_CONTAINS.key]"
                        :options="conditions"
                        :placeholder="$i18n.t('components.appmultiselect.placeholders.chooseOption')"
                        :allowEmpty="false"
                        :small="true"
                        :borderNone="true"
                        :blueArrow="true"
                        :newDesign="true"
                        :widthStyleObject="{ width: '7.7rem' }"
                        class="mr-2"
                        label="label"
                        trackBy="key"
                    />
                    <AppMultiselectV3
                        v-model="values[tableColumnType.TEXT_LIMITED_OPTIONS_CONTAINS.key].value"
                        :options="limitedOptions"
                        :allowEmpty="false"
                        :placeholder="$i18n.t('components.appmultiselect.placeholders.chooseOption')"
                        :small="true"
                        :borderNone="true"
                        :blueArrow="true"
                        :newDesign="true"
                        :widthStyleObject="{ width: '12.5rem' }"
                    />
                </template>
                <template v-else-if="selectedColumn.filterType.key === tableColumnType.NUMBER_UNITS.key">
                    <AppMultiselectV3
                        v-model="condition[tableColumnType.NUMBER_UNITS.key]"
                        :options="conditions"
                        :placeholder="$i18n.t('components.appmultiselect.placeholders.chooseOption')"
                        :allowEmpty="false"
                        :small="true"
                        :borderNone="true"
                        :blueArrow="true"
                        :newDesign="true"
                        :widthStyleObject="{ width: '9rem' }"
                        class="mr-2"
                        label="label"
                        trackBy="key"
                    />
                    <AppInputV3
                        v-model="values[tableColumnType.NUMBER_UNITS.key].value"
                        :type="'number'"
                        :placeholder="$i18n.t('generic.value')"
                        class="mr-2"
                    />
                    <AppMultiselectV3
                        v-model="values[tableColumnType.NUMBER_UNITS.key].unit"
                        :options="getUnitDefinitions(selectedColumn.unitType)"
                        :placeholder="$i18n.t('components.appmultiselect.placeholders.chooseUnit')"
                        :allowEmpty="false"
                        :preselectFirst="true"
                        :small="true"
                        :borderNone="true"
                        :blueArrow="true"
                        :newDesign="true"
                        :widthStyleObject="{ width: '7rem' }"
                        class="mr-2"
                        label="label"
                        trackBy="id"
                    />
                </template>
                <template v-else-if="selectedColumn.filterType.key === tableColumnType.DATETIME.key">
                    <AppMultiselectV3
                        v-model="condition[tableColumnType.DATETIME.key]"
                        :options="conditions"
                        :placeholder="$i18n.t('components.appmultiselect.placeholders.chooseOption')"
                        :allowEmpty="false"
                        :small="true"
                        :borderNone="true"
                        :blueArrow="true"
                        :newDesign="true"
                        :widthStyleObject="{ width: '9rem' }"
                        class="mr-2"
                        label="label"
                        trackBy="key"
                    />
                    <DateTimePicker
                        :value="values[tableColumnType.DATETIME.key].value"
                        type="datetime"
                        class="filter-date-time-picker"
                        data-test-id="filter-table-date-time-picker"
                        @input="onDateTimeRangeStartSelected"
                    />
                    <div
                        v-if="
                            condition[tableColumnType.DATETIME.key].key ===
                            tableColumnType.DATETIME.options.is_between.key
                        "
                        class="mr-2 ml-2 d-flex align-items-center all-caps-text"
                    >
                        {{ $i18n.t('generic.and') }}
                    </div>
                    <DateTimePicker
                        v-if="
                            condition[tableColumnType.DATETIME.key].key ===
                            tableColumnType.DATETIME.options.is_between.key
                        "
                        :value="values[tableColumnType.DATETIME.key].endValue"
                        type="datetime"
                        class="filter-date-time-picker"
                        data-test-id="filter-table-date-time-picker-range-end"
                        @input="onDateTimeRangeEndSelected"
                    />
                </template>
            </template>
            <span
                v-t="'generic.add'"
                :class="{ disabled: addButtonDisabled }"
                class="add-button ml-4"
                data-test-id="filter-table-add-filter"
                @click="onAddFilterClicked"
            />
        </div>
    </div>
</template>

<script>
// Components
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import AppMultiselectV3 from '@/components/partials/inputs/AppMultiselectV3.vue';
import DateTimePicker from '@/components/partials/inputs/DateTimePicker.vue';
import AppInputV3 from '@/components/partials/inputs/AppInputV3.vue';

// Helpers
import tableColumnType from '@/common/filterTable';
import { getUnitDefinitions } from '@/common/formatting';
import vClickOutside from 'v-click-outside';
import { ICON_TYPES } from '@/common/iconHelper';

export default {
    name: 'FilterTable',

    components: {
        AppInputV3,
        AppMultiselectV3,
        AppButton,
        DateTimePicker,
    },

    directives: {
        clickOutside: vClickOutside.directive,
    },

    props: {
        columns: {
            type: Array,
            required: true,
        },
        multiselectWidth: {
            type: Object,
            default() {
                return { width: '12rem' };
            },
        },
    },

    data() {
        return {
            tableColumnType,
            ICON_TYPES,
            BUTTON_TYPES,
            getUnitDefinitions,
            isFilterVisible: false,
            selectedColumn: null,
            condition: {
                [tableColumnType.GENERAL_TEXT.key]: null,
                [tableColumnType.NUMBER.key]: null,
                [tableColumnType.DATE.key]: null,
                [tableColumnType.TEXT_LIMITED_OPTIONS.key]: null,
                [tableColumnType.NUMBER_UNITS.key]: null,
                [tableColumnType.DATETIME.key]: null,
                [tableColumnType.TEXT_LIMITED_OPTIONS_CONTAINS.key]: null,
            },
            values: {
                [tableColumnType.GENERAL_TEXT.key]: {
                    value: null,
                },
                [tableColumnType.NUMBER.key]: {
                    value: null,
                },
                [tableColumnType.DATE.key]: {
                    value: '',
                    endValue: 'default',
                },
                [tableColumnType.TEXT_LIMITED_OPTIONS.key]: {
                    value: null,
                },
                [tableColumnType.NUMBER_UNITS.key]: {
                    value: null,
                    unit: null,
                },
                [tableColumnType.DATETIME.key]: {
                    value: null,
                    endValue: null,
                },
                [tableColumnType.TEXT_LIMITED_OPTIONS_CONTAINS.key]: {
                    value: null,
                },
            },
            limitedOptions: [],
        };
    },
    computed: {
        addButtonDisabled() {
            return !this.selectedColumn || !this.values[this.selectedColumn.filterType.key].value;
        },
        conditions() {
            if (this.selectedColumn) {
                return Object.values(tableColumnType[this.selectedColumn.filterType.key].options).map(option => ({
                    ...option,
                    label: this.$i18n.t(option.i18nLabel),
                }));
            }
            return null;
        },
        rangeDate() {
            return Object.hasOwnProperty.call(this.condition[this.selectedColumn.filterType.key], 'isRange');
        },
        dateTimePickerRange() {
            return this.rangeDate
                ? [this.values[tableColumnType.DATE.key].value, this.values[tableColumnType.DATE.key].endValue]
                : this.values[tableColumnType.DATE.key].value;
        },
    },
    watch: {
        // set of limited options was not updated automatically when set of possible values in table updates
        // reason we need those watchers
        columns: {
            handler(newVal) {
                if (
                    this.selectedColumn &&
                    (this.selectedColumn.filterType === tableColumnType.TEXT_LIMITED_OPTIONS ||
                        this.selectedColumn.filterType === tableColumnType.TEXT_LIMITED_OPTIONS_CONTAINS)
                ) {
                    this.limitedOptions = newVal.find(
                        column => column.name === this.selectedColumn.name,
                    ).limitedOptions;
                }
            },
            deep: true,
        },
        selectedColumn(newColumn) {
            if (newColumn) {
                this.values[tableColumnType.TEXT_LIMITED_OPTIONS.key].value = null;
                this.values[tableColumnType.TEXT_LIMITED_OPTIONS_CONTAINS.key].value = null;
                if (
                    newColumn.filterType === tableColumnType.TEXT_LIMITED_OPTIONS ||
                    newColumn.filterType === tableColumnType.TEXT_LIMITED_OPTIONS_CONTAINS
                ) {
                    this.limitedOptions = newColumn.limitedOptions;
                }
            }
        },
    },
    methods: {
        toggleFilterVisibility() {
            this.isFilterVisible = !this.isFilterVisible;
        },
        closeFilter(event) {
            // Check if we clicked on datepicker dropdown
            if (
                event?.composedPath &&
                Array.isArray(event.composedPath()) &&
                event
                    .composedPath()
                    .some(el => el.className && el.className.includes && el.className.includes('mx-datepicker-main'))
            ) {
                return;
            }
            this.isFilterVisible = false;
        },
        onAddFilterClicked() {
            if (!this.addButtonDisabled) {
                const filter = {
                    column: this.selectedColumn,
                    condition: this.condition[this.selectedColumn.filterType.key],
                    values: { ...this.values[this.selectedColumn.filterType.key] },
                };
                this.$emit('filterAdded', filter);
                this.isFilterVisible = false;
                this.values[this.selectedColumn.filterType.key].value = null;
                if (this.values[this.selectedColumn.filterType.key].endValue) {
                    this.values[this.selectedColumn.filterType.key].endValue = null;
                }
                this.selectedColumn = null;
            }
        },
        onDateSelected(date) {
            this.values[tableColumnType.DATE.key].value = date;
        },
        onDateRangeSelected(startDate, endDate) {
            this.values[tableColumnType.DATE.key].value = startDate;
            this.values[tableColumnType.DATE.key].endValue = endDate;
        },
        onDateTimeRangeStartSelected(startDateTime) {
            this.values[tableColumnType.DATETIME.key].value = startDateTime;
        },
        onDateTimeRangeEndSelected(endDateTime) {
            this.values[tableColumnType.DATETIME.key].endValue = endDateTime;
        },
        onColumnSelected() {
            [this.condition[this.selectedColumn.filterType.key]] = this.conditions;
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/palette';
@import '~@/assets/scss/z-indexes';

.filter-component {
    height: 2.5rem;
    width: fit-content;
    position: absolute;
    right: 2rem;
    top: 3.75rem;
    box-shadow: 0 2px 6px 0 rgba(51, 81, 149, 0.3);
    border-radius: 0.5rem;
    background-color: $white;
    z-index: $header-dropdowns-z-index;
}

.add-button {
    color: $blue;
    cursor: pointer;
    font-weight: 700;
    font-size: 0.875rem;
    line-height: 1.625rem;

    &.disabled {
        cursor: default;
        color: $gray10;
    }
}

.date-min-width {
    min-width: 15.5rem;
}

.input-min-width {
    min-width: 10rem;
}

.filter-date-time-picker {
    ::v-deep.datepicker-wrapper {
        width: 12rem;
    }

    ::v-deep.date-time-picker {
        width: 7rem;
    }
}
</style>
