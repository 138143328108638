import http from '@/http';
import { type AxiosPromise } from 'axios';
import { type DnoResponseBase } from '@/http/index';
import { type ApplicationDataBe } from '@/__new__/services/dno/ossdevedge/models/ApplicationData';

export interface ApplicationsResponse extends DnoResponseBase {
    apps: ApplicationDataBe[];
}

/**
 * Swap Esim manually
 *
 * @param msisdn
 * @param platform
 * @returns {AxiosPromise}
 */
const swapEsimManually = (msisdn: string, platform: string): AxiosPromise<ApplicationsResponse> =>
    http({
        method: 'POST',
        url: '/v3esimswap/manuallyswapesim',
        data: {
            msisdn,
            platform,
        },
    });

export default {
    swapEsimManually,
};
