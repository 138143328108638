
// components
import AppOverviewBlock from '@/components/partials/AppOverviewBlock.vue';
import AppButton from '@/components/partials/inputs/AppButton.vue';
// helpers
import ListDropdownSectionItem from '@/components/partials/entityOverview/ListDropdownSectionItem.vue';
import AppDialogV2 from '@/components/partials/AppDialogV2.vue';
import EventProp, { mapPropertyRowsForOverview } from '@/__new__/services/dno/events/models/EventProp';
import AppJSON from '@/components/partials/AppJSON.vue';
import AppEntitiesManagementBlock from '@/components/partials/AppEntitiesManagementBlock.vue';
import { CollapsibleListItem } from '@/common/AppCollapsibleListHelper';
import { Event } from '@/__new__/services/dno/events/models/Event';
import { PropType } from 'vue';
import { isEmpty } from 'lodash';

export default {
    components: {
        AppEntitiesManagementBlock,
        AppJSON,
        AppDialogV2,
        AppButton,
        ListDropdownSectionItem,
        AppOverviewBlock,
    },
    props: {
        event: {
            type: Object as PropType<Event>,
            required: true,
        },
    },
    data() {
        return {
            isPopupVisible: false,
            activeProperty: null as EventProp | null,
        };
    },
    computed: {
        generalPropertiesOverview(): CollapsibleListItem[] {
            return [
                {
                    name: this.$t('generic.general'),
                    isCollapsed: false,
                    rows: mapPropertyRowsForOverview(this.activeProperty),
                },
            ];
        },
    },
    watch: {
        event: {
            immediate: true,
            handler() {
                const [firstItem] = this.event.properties;
                this.activeProperty = firstItem ?? null;
            },
        },
    },
    methods: {
        isEmpty,
        changeActiveEventProperty(id: string): void {
            this.activeProperty = this.event.properties.find((prop: EventProp) => prop.id === id);
        },
    },
};
