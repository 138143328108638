
import Vue, { type PropType } from 'vue';

// components
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import AppDialogV2 from '@/components/partials/AppDialogV2.vue';
import AppYAML from '@/components/partials/AppYAML.vue';
import BulkUploadTabs, { type Tab } from '@/__new__/features/resources/BulkUploadTabs.vue';

// helpers
import { ICON_TYPES } from '@/common/iconHelper';
import { copyToClipboard } from '@/common/utils';
import { JOB_EXPORT_DOC_URL } from '@/__new__/features/settings/common/applicationManagerHelper';

export default Vue.extend({
    name: 'DataflowsConfigDialog',
    components: {
        AppButton,
        AppDialogV2,
        AppYAML,
        BulkUploadTabs,
    },
    props: {
        value: {
            type: [Boolean, String] as PropType<Tab['id'] | boolean>,
            required: true,
        },
        tabs: {
            type: Array as PropType<Tab[]>,
            default: () => [],
        },
        config: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            JOB_EXPORT_DOC_URL,
            tab: '' as Tab['id'],
            submitBtn: {
                buttonType: BUTTON_TYPES.PRIMARY,
                iconType: ICON_TYPES.COPY,
                label: this.$t('generic.copyToClipboard'),
            },
        };
    },
    watch: {
        value(tabId: Tab['id']) {
            this.tab = tabId;
        },
    },
    methods: {
        copyToClipboard,
    },
});
