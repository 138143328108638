
import MonacoLoader from '@monaco-editor/loader';
import { editor } from 'monaco-editor/esm/vs/editor/editor.api';

export default {
    name: 'AppJSON',
    props: {
        disabled: {
            type: Boolean,
            default: false,
        },
        value: {
            type: [Array, Object, String, Boolean, Number],
            default: null,
        },
        label: {
            type: String,
            default: null,
        },
        textareaHeight: {
            type: String,
            default: '10rem',
        },
        hidePreview: {
            type: Boolean,
            default: false,
        },
        errorMessage: {
            type: String,
            default: '',
        },
        // for case when input is validated within parent component
        invalidInput: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            editor: null as editor.IStandaloneCodeEditor | null,
            invalid: false,
            options: {
                // Monaco Editor Options
                language: 'json',
                fontSize: 14,
                formatOnPaste: false,
                scrollBeyondLastLine: false,
                padding: { top: 5, bottom: 5 },
                renderLineHighlight: 'none' as editor.IEditorOptions['renderLineHighlight'],
                renderLineHighlightOnlyWhenFocus: false,
                readOnly: this.disabled,
                domReadOnly: this.disabled,
            },
        };
    },
    computed: {
        valueStringified(): string {
            return this.value ? JSON.stringify(this.value, null, '  ') : '';
        },
    },
    watch: {
        valueStringified(value) {
            if (this.editor && value !== this.editor.getValue()) {
                this.editor.setValue(value);
            }
        },
    },
    mounted() {
        MonacoLoader.init().then(monaco => {
            this.editor = monaco.editor.create(this.$refs.monaco_container as HTMLElement, {
                ...this.options,
                value: this.valueStringified,
                minimap: {
                    enabled: !this.hidePreview,
                },
            });

            this.editor.onDidChangeModelContent(() => {
                if (!this.editor) return;

                const newVal = this.editor.getValue();
                try {
                    this.invalid = false;
                    const newObj = JSON.parse(newVal);

                    this.$emit('input', newObj);
                } catch {
                    this.invalid = true;
                }
                this.$emit('validation', !this.invalid);
            });
        });
    },
    beforeDestroy() {
        if (this.editor) {
            this.editor.dispose();
        }
    },
};
