<template>
    <div
        class="filters"
        :class="{ 'is-disabled': isDisabled }"
    >
        <div
            v-if="!hideHeader"
            class="d-flex justify-content-between"
        >
            <div class="subtitle">{{ $t('generic.create') }} {{ entityType }}</div>
        </div>

        <div
            class="filters-table"
            :class="{ 'is-disabled': isDisabled }"
        >
            <template v-for="(conditionInstanceByDefinitionId, conditionInstanceIndex) in conditionInstances">
                <FilterComponentV2
                    v-for="(_, conditionDefinitionId) in conditionInstanceByDefinitionId"
                    :key="conditionDefinitionId + conditionInstanceIndex"
                    class="filter-component"
                    :conditionInstanceTopLevelById="conditionInstanceByDefinitionId"
                    :conditionDefinition="conditionDefinitionsById[conditionDefinitionId]"
                    :class="{ 'is-last': conditionInstanceIndex === conditionInstances.length - 1 }"
                    :filtersIcon="filtersIcon"
                    :changeCallback="changeCallback"
                    :stats="stats[conditionInstanceIndex]"
                    :topLevelCombinator="topLevelCombinator"
                    :showStats="showStats"
                    :entityType="entityType"
                    :allJsonPathOptions="allJsonPathOptionsOrNone"
                    :propertyDefinitions="propertyDefinitionsByConditionDefinitionId[conditionDefinitionId]"
                    :conditionName="conditionNamesByDefinitionId[conditionDefinitionId]"
                    @addPropertyGroup="(...args) => addPropertyGroup(conditionInstanceIndex, ...args)"
                    @addProperty="(...args) => addProperty(conditionInstanceIndex, ...args)"
                    @updateProperty="(...args) => updateProperty(conditionInstanceIndex, ...args)"
                    @deleteProperty="(...args) => deleteProperty(conditionInstanceIndex, ...args)"
                    @deleteGroup="(...args) => deleteGroup(conditionInstanceIndex, ...args)"
                />
            </template>

            <div
                :class="{ 'cursor-not-allowed': filtersAddingDisabled }"
                class="add-filter"
            >
                <AppMultiselectV3
                    :options="conditionDefinitionsArray"
                    :error="selectFilterInvalid"
                    :disabled="filtersAddingDisabled"
                    :additionalLabel="`Choose ${EntityDropdownTitle[entityType]}`"
                    :placeholder="`Choose ${EntityDropdownTitle[entityType]}`"
                    :class="{ 'pointer-events-none': filtersAddingDisabled }"
                    class="col-lg-6"
                    :maxHeight="250"
                    :small="true"
                    label="name"
                    @input="onFilterSelect"
                />
            </div>
        </div>
    </div>
</template>

<script>
// components
import AppMultiselectV3 from '@/components/partials/inputs/AppMultiselectV3.vue';
import FilterComponentV2 from '@/components/partials/filters/FilterComponentV2.vue';

// helpers
import { ICON_TYPES } from '@/common/iconHelper';
import {
    DefinitionPropertyUIKeyToInstanceClass,
    JsonPathTypeToPropertyValue,
} from '@/models/filters/uiComponents/UIComponentInstance';
import { mapValues, flattenDeep } from 'lodash';
import { mapGetters } from 'vuex';
import { Modules } from '@/store/store';
import { Getters } from '@/store/mutation-types';

export const EntityType = {
    Filter: 'Filter',
    Trigger: 'Trigger',
};

const EntityDropdownTitle = {
    [EntityType.Filter]: 'filter',
    [EntityType.Trigger]: 'event',
};

export default {
    name: 'Filters',
    components: {
        AppMultiselectV3,
        FilterComponentV2,
    },
    props: {
        filtersIcon: { type: String, default: ICON_TYPES.USER },
        stats: { type: Array, default: () => [] }, // this is one to one with filters
        filterValidations: { type: Array, default: () => [] }, // same here
        selectFilterInvalid: { type: Boolean, default: false },
        entityType: { type: String, default: EntityType.Filter },
        topLevelCombinator: { type: String, default: 'and' }, // 'or' is possible, this is just a label
        showStats: { type: Boolean, default: true },
        hideHeader: { type: Boolean, default: false },
        allowSingleFilter: { type: Boolean, default: false },
        isDisabled: { type: Boolean, default: false },
        conditionInstancesJson: { type: Array, default: () => [] },
        conditionDefinitionsById: { type: Object, default: () => ({}) },
    },
    data() {
        return {
            ICON_TYPES,
            EntityDropdownTitle,
            selectedConditionDefinition: null,

            // [ // objects in array are combined with topLevelCombinator any of AND/OR
            //   {
            //     condition1_definition_id: [ // nested arrays in array are ORed
            //       [
            //         {prop1_condition1}, {prop1_condition2} // ANDed props, may be the same prop or different
            //       ],
            //       [
            //         {prop1_condition3}
            //       ]
            //     ]
            //   }
            // ]
            conditionInstances: [],
        };
    },
    computed: {
        ...mapGetters(Modules.sinkConfigs, {
            allJsonPathOptions: Getters.GET_JSON_PATH_OPTIONS,
        }),
        allJsonPathOptionsOrNone() {
            return this.allJsonPathOptions || {};
        },
        eventDefinitionsByConditionDefinitionId() {
            return mapValues(
                this.conditionDefinitionsById,
                value => value.parameters[0].properties[0].DropDown.values[0].EventSelectable,
            );
        },
        eventDefinitionTypesByConditionDefinitionId() {
            return mapValues(this.eventDefinitionsByConditionDefinitionId, event => event.value);
        },
        propertyDefinitionsByConditionDefinitionId() {
            return mapValues(this.eventDefinitionsByConditionDefinitionId, event => event.properties);
        },
        conditionNamesByDefinitionId() {
            return mapValues(this.conditionDefinitionsById, value => value.name);
        },
        conditionDefinitionsArray() {
            return Object.values(this.conditionDefinitionsById);
        },
        filtersAddingDisabled() {
            return this.allowSingleFilter && this.conditionInstances.length > 0;
        },
    },
    watch: {
        conditionDefinitionsById: {
            immediate: true,
            handler() {
                this.initiallyParseConditionInstancesJson(this.conditionInstancesJson);
            },
        },
        conditionInstancesJson: {
            immediate: true,
            handler(conditionInstancesJson) {
                this.initiallyParseConditionInstancesJson(conditionInstancesJson);
            },
        },
    },
    methods: {
        updatedConditionInstancesJson() {
            return this.conditionInstances.map(instanceById => {
                const conditionDefinitionId = Object.keys(instanceById)[0];
                const eventType = this.eventDefinitionTypesByConditionDefinitionId[conditionDefinitionId];

                return {
                    filterDefinitionId: conditionDefinitionId,
                    values: Object.values(instanceById)[0].map(propertyGroup => ({
                        [eventType]: {
                            p: propertyGroup.map(property => ({
                                [property.propertyDefinition.name]: {
                                    value: property.parentUIComponentClassInstance.componentDefinition.buildJson(
                                        property.parentUIComponentClassInstance.value,
                                    ),
                                    type: property.propertyDefinition.type,
                                },
                            })),
                        },
                    })),
                };
            });
        },
        changeCallback() {
            this.emitUpdatedConditionInstancesJson();
        },
        propertyDefinitionFromPropertyJson(propertyJson) {
            const jsonPathPropertyName = Object.keys(propertyJson)[0];
            const jsonPathType = propertyJson[jsonPathPropertyName].type;
            return {
                name: jsonPathPropertyName,
                type: jsonPathType,
                description: '',
                value: JsonPathTypeToPropertyValue[jsonPathType],
                label: jsonPathPropertyName,
                isJsonPathEnabled: true,
            };
        },
        propertyNameFromJsonPathPropertyJson(propertyJson) {
            return Object.keys(propertyJson)[0].toString().replace('$.', '').split('.')[0];
        },
        getJsonPathPropertyDefinitions(conditionDefinitionId, propertyJson) {
            const parentPropertyDef = this.propertyDefinitionsByConditionDefinitionId[conditionDefinitionId].find(
                propertyDefinition =>
                    propertyDefinition.name === this.propertyNameFromJsonPathPropertyJson(propertyJson),
            );
            const jsonPathPropertyDef = this.propertyDefinitionFromPropertyJson(propertyJson);

            return {
                propertyDefinition: jsonPathPropertyDef,
                parentPropertyDefinition: parentPropertyDef,
            };
        },
        initiallyParseConditionInstancesJson(conditionInstancesJson) {
            if (!conditionInstancesJson) {
                return;
            }

            this.conditionInstances = []; // reset state before parsing
            conditionInstancesJson.forEach((conditionInstanceJson, conditionInstanceIndex) => {
                const conditionDefinitionId = conditionInstanceJson.filterDefinitionId;
                if (!conditionDefinitionId || !this.propertyDefinitionsByConditionDefinitionId[conditionDefinitionId]) {
                    // condition definitions not initialized yet - immediate exit
                    return;
                }

                this.addConditionInstance(conditionDefinitionId);

                conditionInstanceJson.values.forEach((propertyGroupsJson, groupIndex) => {
                    this.addPropertyGroup(conditionInstanceIndex, groupIndex);

                    Object.values(propertyGroupsJson)[0].p.forEach((propertyJson, propertyIndex) => {
                        const propertyDefFromResponse = this.propertyDefinitionsByConditionDefinitionId[
                            conditionDefinitionId
                        ].find(propertyDefinition => propertyDefinition.name === Object.keys(propertyJson)[0]);

                        const propertyDefsFromJsonPath = this.getJsonPathPropertyDefinitions(
                            conditionDefinitionId,
                            propertyJson,
                        );
                        const propertyDefinition =
                            propertyDefFromResponse || propertyDefsFromJsonPath.propertyDefinition;

                        this.addProperty(
                            conditionInstanceIndex,
                            groupIndex,
                            propertyIndex,
                            propertyDefinition,
                            Object.values(propertyJson)[0],
                            propertyDefsFromJsonPath.parentPropertyDefinition,
                        );
                    });
                });
            });
        },
        onFilterSelect(conditionDefinition) {
            this.selectedConditionDefinition = conditionDefinition;
            this.addConditionInstance(conditionDefinition.id);
        },
        emitConditionsValidationIfError() {
            const validationResult = this.conditionInstances.map(conditionsById =>
                Object.values(conditionsById)[0].map(conditionGroups =>
                    conditionGroups.map(propertyCondition => propertyCondition.uiComponentClassInstance.validate()),
                ),
            );
            const firstError = flattenDeep(validationResult).find(error => error);
            if (firstError) {
                this.$emit('onConditionsValidationError', firstError);
                return firstError;
            }

            this.$emit('onConditionsValidationError');
            return null;
        },
        emitUpdatedConditionInstancesJson() {
            if (!this.emitConditionsValidationIfError()) {
                this.$emit('updatedConditionInstancesJson', this.updatedConditionInstancesJson());
            }
        },
        addConditionInstance(conditionDefinitionId) {
            this.$set(this.conditionInstances, this.conditionInstances.length, { [conditionDefinitionId]: [[]] });
            this.emitUpdatedConditionInstancesJson();
        },
        addPropertyGroup(conditionInstanceIndex, groupIndex) {
            const modifiedArray = [...Object.values(this.conditionInstances[conditionInstanceIndex])[0]];
            modifiedArray[groupIndex] = [];

            this.$set(this.conditionInstances, conditionInstanceIndex, {
                [Object.keys(this.conditionInstances[conditionInstanceIndex])[0]]: modifiedArray,
            });
            this.emitUpdatedConditionInstancesJson();
        },
        buildInitialRowData(propertyDefinition, isJsonPathEnabled, eventType, parentPropertyDefinition) {
            return {
                jsonPathType: propertyDefinition.type,
                jsonPath: {
                    jsonPath: propertyDefinition.isJsonPathEnabled
                        ? propertyDefinition.name
                        : `$.${propertyDefinition.name}`,
                    type: propertyDefinition.type,
                },
                eventType,
                property: {
                    name: parentPropertyDefinition?.name || propertyDefinition.name,
                    type: parentPropertyDefinition?.type || propertyDefinition.type,
                },
                isJsonPathEnabled,
            };
        },
        updateProperty(conditionInstanceIndex, groupIndex, propertyIndex, propertyDefinition, rowData) {
            const uiComponentConstructorName = Object.keys(propertyDefinition.value)[0];
            const uiComponentDefinitionJson = propertyDefinition.value[uiComponentConstructorName];

            const Constructor = DefinitionPropertyUIKeyToInstanceClass[uiComponentConstructorName];
            const uiComponentClassInstance = new Constructor(uiComponentDefinitionJson);

            const modifiedArray = [...Object.values(this.conditionInstances[conditionInstanceIndex])[0]];
            const currentCondition = modifiedArray[groupIndex][propertyIndex];

            modifiedArray[groupIndex][propertyIndex] = {
                uiComponentClassInstance,
                propertyDefinition,
                rowData,
                parentPropertyDefinition: currentCondition.parentPropertyDefinition,
                parentUIComponentClassInstance: currentCondition.parentUIComponentClassInstance,
            };
            this.$set(this.conditionInstances, conditionInstanceIndex, {
                [Object.keys(this.conditionInstances[conditionInstanceIndex])[0]]: modifiedArray,
            });
            this.emitUpdatedConditionInstancesJson();
        },
        getUIComponentInstanceFromPropertyDefinition(propertyDef, maybeConditionInstanceJson) {
            const uiComponentConstructorName = Object.keys(propertyDef.value)[0];
            const uiComponentDefinitionJson = propertyDef.value[uiComponentConstructorName];

            const Constructor = DefinitionPropertyUIKeyToInstanceClass[uiComponentConstructorName];
            return new Constructor(uiComponentDefinitionJson, maybeConditionInstanceJson);
        },
        addProperty(
            conditionInstanceIndex,
            groupIndex,
            propertyIndex,
            propertyDef,
            maybeConditionInstanceJson,
            parentPropertyDef,
        ) {
            if (!propertyDef) return; // may happen in case Custom Event with no properties

            const uiComponentClassInstance = this.getUIComponentInstanceFromPropertyDefinition(
                propertyDef,
                maybeConditionInstanceJson,
            );

            const parentUIComponentClassInstance = parentPropertyDef
                ? this.getUIComponentInstanceFromPropertyDefinition(parentPropertyDef, maybeConditionInstanceJson)
                : null;

            const conditionDefinitionId = Object.keys(this.conditionInstances[conditionInstanceIndex])[0];
            const eventType = this.eventDefinitionTypesByConditionDefinitionId[conditionDefinitionId];

            const modifiedArray = [...Object.values(this.conditionInstances[conditionInstanceIndex])[0]];
            const rowData = this.buildInitialRowData(
                propertyDef,
                propertyDef.isJsonPathEnabled || false,
                eventType,
                parentPropertyDef,
            );

            modifiedArray[groupIndex][propertyIndex] = {
                uiComponentClassInstance,
                propertyDefinition: propertyDef,
                rowData,
                parentPropertyDefinition: parentPropertyDef || propertyDef,
                parentUIComponentClassInstance: parentUIComponentClassInstance || uiComponentClassInstance,
            };

            this.$set(this.conditionInstances, conditionInstanceIndex, {
                [Object.keys(this.conditionInstances[conditionInstanceIndex])[0]]: modifiedArray,
            });
            this.emitUpdatedConditionInstancesJson();
        },
        deleteProperty(conditionInstanceIndex, groupIndex, propertyIndex) {
            const modifiedArray = [...Object.values(this.conditionInstances[conditionInstanceIndex])[0]];
            modifiedArray[groupIndex] = modifiedArray[groupIndex].filter((_, index) => index !== propertyIndex);

            this.$set(this.conditionInstances, conditionInstanceIndex, {
                [Object.keys(this.conditionInstances[conditionInstanceIndex])[0]]: modifiedArray,
            });
            this.emitUpdatedConditionInstancesJson();
        },
        deleteGroup(conditionInstanceIndex, groupIndex) {
            const modifiedArray = [...Object.values(this.conditionInstances[conditionInstanceIndex])[0]].filter(
                (_, index) => index !== groupIndex,
            );

            if (modifiedArray.length === 0) {
                // no more conditions left for this ConditionInstance
                this.$delete(this.conditionInstances, conditionInstanceIndex);
            } else {
                this.$set(this.conditionInstances, conditionInstanceIndex, {
                    [Object.keys(this.conditionInstances[conditionInstanceIndex])[0]]: modifiedArray,
                });
            }
            this.emitUpdatedConditionInstancesJson();
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/palette';
@import '~@/assets/scss/mixins';
@import '~@/assets/scss/icons';
@import '~@/assets/scss/consistency-spacing';

$icon-path: '~@/assets/icons/';

.filters {
    margin-top: 1rem;
    position: relative;
    &.is-disabled {
        cursor: not-allowed;
    }
    ::v-deep .dropdown {
        div .dropdown-input,
        div .dropdown-arrow {
            border-radius: 0.5rem;
        }
        div .dropdown-arrow {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            border-left: none;
        }
    }
    .filters-table {
        margin-top: 0.875rem;
        font-size: 0.875rem;
        &.is-disabled {
            pointer-events: none;
        }
        .row {
            padding-right: 0.9375rem;
            padding-left: 0.9375rem;
        }

        .control {
            display: flex;
            align-items: center;
            max-width: 2.3125rem;
            padding-left: 0;
            padding-right: 1.0625rem;

            &:hover {
                cursor: pointer;
            }

            .expanded {
                transform: rotate(180deg);
            }
        }

        .label {
            margin-top: 0.3125rem;
            margin-bottom: 0.375rem;
            padding-left: 0;
            padding-right: 0;
        }

        .add-filter::v-deep {
            .select-label {
                padding-left: 4rem;
            }

            .dropdown {
                @include custom-height-dropdown(2rem);
                @include custom-width-dropdown(18rem);
            }
        }

        ::v-deep {
            .dropdown-item {
                overflow: visible;
            }

            .tooltip-content {
                padding: 0;
                margin: 0;
            }
        }
    }
}

::v-deep {
    .tree-node {
        margin-inline: $spacing-m;
    }
    .icon-button-container {
        flex-shrink: 0;
    }
}

.filter-component {
    border-radius: 0.5rem;

    &.is-last {
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
    }
}

.filters .filters-table {
    line-height: 1.8rem;
}

.pointer-events-none {
    pointer-events: none;
}
.cursor-not-allowed {
    cursor: not-allowed;
}
</style>
