
import Vue from 'vue';

import { ICON_TYPES } from '@/common/iconHelper';
import IconButton from '@/components/partials/IconButton.vue';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';

export default Vue.extend({
    name: 'AppDialogV2',
    components: {
        AppButton,
        IconButton,
    },
    model: {
        prop: 'visible',
    },
    props: {
        visible: {
            type: Boolean,
            required: true,
        },
        title: {
            type: String,
            default: '',
        },
        description: {
            type: String,
            default: '',
        },
        saveButtonDisabled: {
            type: Boolean,
            default: false,
        },
        saveButtonLoading: {
            type: Boolean,
            default: false,
        },
        disableDefaultSaveBtn: {
            type: Boolean,
            default: false,
        },
        isSubmittionOnEnterEnabled: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            BUTTON_TYPES,
            ICON_TYPES,

            contentContainerHasOverflow: false as boolean,
        };
    },
    watch: {
        visible: {
            immediate: true,
            handler(newVal) {
                if (newVal) {
                    this.$nextTick(() => {
                        this.checkContentContainerOverflow();
                    });
                }
            },
        },
    },
    mounted() {
        window.addEventListener('keydown', this.handleKeyDown);
    },
    beforeDestroy() {
        window.removeEventListener('keydown', this.handleKeyDown);
    },
    methods: {
        closeDialog(): void {
            if (this.visible) {
                this.$emit('input', false);
            }

            this.$emit('close');
        },
        checkContentContainerOverflow(): void {
            const { mainContainer }: { mainContainer: { scrollHeight: number; clientHeight: number } } = this
                .$refs as any;

            if (mainContainer?.scrollHeight && mainContainer?.clientHeight) {
                this.contentContainerHasOverflow = mainContainer.scrollHeight >= mainContainer.clientHeight;
            } else {
                this.contentContainerHasOverflow = false;
            }
        },
        handleKeyDown(event: KeyboardEvent): void {
            if (this.isSubmittionOnEnterEnabled && event.key === 'Enter') {
                this.$emit('submit');
            }

            if (event.key === 'Escape') {
                this.$emit('close');
            }
        },
    },
});
