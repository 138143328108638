import UserManagerBaseModel from '@/__new__/services/dno/user/models/UserManagerBaseModel';
import localeLibrary from '@/common/locale/localeLibrary';
import {
    OUTAGE_STATUS,
    OUTAGE_TYPE,
    type OutageInfoResponse,
} from '@/__new__/features/customerCare/common/outageHelper';

export default class OutageInfo extends UserManagerBaseModel {
    id?: string;
    status?: OUTAGE_STATUS;
    reason?: string;
    description?: string;
    createdTime?: number;
    startTime?: number;
    endTime?: number;
    estimatedResolution?: number;
    outageType?: OUTAGE_TYPE;

    constructor({
        id,
        status,
        reason,
        description,
        createdTime,
        startTime,
        endTime,
        estimatedResolution,
        outageType,
    }: {
        id?: string;
        status?: OUTAGE_STATUS;
        reason?: string;
        description?: string;
        createdTime?: number;
        startTime?: number;
        endTime?: number;
        estimatedResolution?: number;
        outageType?: number;
    } = {}) {
        super();
        this.id = this.validateString('id', id) ? (id as string) : undefined;
        this.status = this.validateByEnum('status', status, OUTAGE_STATUS) ? (status as OUTAGE_STATUS) : undefined;
        this.reason = this.validateString('reason', reason) ? (reason as string) : undefined;
        this.description = this.validateString('description', description) ? (description as string) : undefined;
        this.createdTime = this.validateDay('createdTime', createdTime)
            ? (localeLibrary.normalizeTimestamp(createdTime as number) as number)
            : undefined;
        this.startTime = this.validateDay('startTime', startTime)
            ? (localeLibrary.normalizeTimestamp(startTime as number) as number)
            : undefined;
        this.endTime = this.validateDay('endTime', endTime)
            ? (localeLibrary.normalizeTimestamp(endTime as number) as number)
            : undefined;
        this.estimatedResolution = this.validateNumberInteger('estimatedResolution', estimatedResolution)
            ? (estimatedResolution as number)
            : undefined;
        this.outageType = this.validateByEnum('outageType', outageType, OUTAGE_TYPE)
            ? (outageType as OUTAGE_TYPE)
            : undefined;
    }

    get isActive() {
        return [OUTAGE_STATUS.IN_PROGRESS, OUTAGE_STATUS.OPEN].includes(this.status as OUTAGE_STATUS);
    }

    static mapOutageFromBe(data: OutageInfoResponse) {
        return {
            id: data.outage_id,
            status: data.outage_status,
            reason: data.outage_reason,
            description: data.outage_description,
            startTime: data.outage_start_time_epoch,
            endTime: data.outage_end_time_epoch,
            createdTime: data.created_date_time,
            estimatedResolution: data.estimated_hours_to_resolution,
            outageType: data.outage_type || OUTAGE_TYPE.UNPLANNED,
        };
    }
}
