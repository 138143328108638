import VueI18n, { TranslateResult } from 'vue-i18n';
import localeLibrary from '@/common/locale/localeLibrary';

// Enums
export enum APPT_ACTION {
    CREATE = 'create',
    EDIT = 'edit',
    DELETE = 'delete',
}

export enum APPT_TYPE {
    INSTALLATION = 'Installation',
    MAINTAINANCE = 'Maintenance',
    DAMAGE = 'Damages',
}

export enum APPOINTMENT_ACTION_TYPE {
    CREATE = 'CREATE',
    RESCHEDULE = 'RESCHEDULE',
}

export enum APPT_STATE {
    NONE = 0,
    PENDING = 1,
    RESERVED = 2,
    COMPLETED = 3,
    CANCELED = 4,
    UPDATING = 5,
    UNDEFINED = 100,
}

export enum APPT_CANCELLATION_CONTEXT {
    USER = 1,
    PORTAL = 2,
    EXTERNAL = 3,
}

export enum APPT_SOURCE {
    BUYFLOW = 'Buyflow (Customer)',
    CANCELLATION_FLOW = 'Cancellation Flow (Customer)',
    INSTALL_ELIGIBLE = 'Install Eligible (Customer)',
    PORTAL = 'Portal (Care)',
    REACTIVATION_FLOW = 'Reactivation Flow (Customer)',
    WIZARD = 'Wizard (Customer)',
    LF_PORTAL = 'LF Portal',
    CUSTOMER_FLOW = 'Customer (Buyflow)',
}

const appointmentTypePerModalModeToLabelMap: Record<
    APPT_TYPE,
    Record<Extract<APPT_ACTION, APPT_ACTION.CREATE | APPT_ACTION.EDIT>, VueI18n.Path>
> = {
    [APPT_TYPE.INSTALLATION]: {
        [APPT_ACTION.CREATE]: 'customerCare.appointmentsSection.installation',
        [APPT_ACTION.EDIT]: 'customerCare.appointmentsSection.editInstallationAppointment',
    },
    [APPT_TYPE.MAINTAINANCE]: {
        [APPT_ACTION.CREATE]: 'customerCare.appointmentsSection.troubleCall',
        [APPT_ACTION.EDIT]: 'customerCare.appointmentsSection.editMaintainanceAppointment',
    },
    [APPT_TYPE.DAMAGE]: {
        [APPT_ACTION.CREATE]: 'customerCare.appointmentsSection.damages',
        [APPT_ACTION.EDIT]: 'customerCare.appointmentsSection.editDamageAppointment',
    },
};

export const getAppointmentActivityLabel = (
    type: APPT_TYPE,
    mode: Extract<APPT_ACTION, APPT_ACTION.CREATE | APPT_ACTION.EDIT>,
): VueI18n.Path => {
    return appointmentTypePerModalModeToLabelMap[type][mode];
};

export const appointmentStateToLabelMap: Map<APPT_STATE, VueI18n.Path> = new Map([
    [APPT_STATE.NONE, 'generic.stateMap.none'],
    [APPT_STATE.PENDING, 'generic.stateMap.pending'],
    [APPT_STATE.RESERVED, 'generic.stateMap.reserved'],
    [APPT_STATE.COMPLETED, 'generic.completed'],
    [APPT_STATE.CANCELED, 'generic.stateMap.canceled'],
    [APPT_STATE.UPDATING, 'generic.stateMap.updating'],
    [APPT_STATE.UNDEFINED, 'generic.stateMap.undefined'],
]);

export const getAppointmentStateLabel = (state: APPT_STATE): VueI18n.Path => {
    return (appointmentStateToLabelMap.get(state) || appointmentStateToLabelMap.get(APPT_STATE.NONE)) as VueI18n.Path;
};

export const apptSourceMap = new Map<APPT_SOURCE, VueI18n.Path>([
    [APPT_SOURCE.BUYFLOW, 'generic.apptSourceMap.buyflow'],
    [APPT_SOURCE.CANCELLATION_FLOW, 'generic.apptSourceMap.cancellationFlow'],
    [APPT_SOURCE.INSTALL_ELIGIBLE, 'generic.apptSourceMap.installEligible'],
    [APPT_SOURCE.PORTAL, 'generic.apptSourceMap.portal'],
    [APPT_SOURCE.REACTIVATION_FLOW, 'generic.apptSourceMap.reactivationFlow'],
    [APPT_SOURCE.WIZARD, 'generic.apptSourceMap.wizard'],
    [APPT_SOURCE.LF_PORTAL, 'generic.apptSourceMap.lfPortal'],
    [APPT_SOURCE.CUSTOMER_FLOW, 'generic.apptSourceMap.customerFlow'],
]);

export const getApptSourceLabel = (source: APPT_SOURCE) =>
    (apptSourceMap.has(source) ? apptSourceMap.get(source) : source) as VueI18n.Path;

// Appointment time slots related stuff

export interface APPT_TIMESLOT_TIMEZONE_BE {
    short_name?: string;
    long_name?: string;
    short_name_dst?: string;
    long_name_dst?: string;
    offset_minutes?: number;
}

export interface APPT_TIMESLOT_TIMEZONE_FE {
    shortName?: string;
    longName?: string;
    shortNameDistrict?: string;
    longNameDistrict?: string;
    offsetMinutes?: number;
}

export class AppointmentTimeSlotTimeZone implements APPT_TIMESLOT_TIMEZONE_FE {
    shortName?: string;
    longName?: string;
    shortNameDistrict?: string;
    longNameDistrict?: string;
    offsetMinutes?: number;

    constructor(appointmentTimeSlotTimeZoneData: APPT_TIMESLOT_TIMEZONE_BE) {
        this.shortName = appointmentTimeSlotTimeZoneData.short_name;
        this.longName = appointmentTimeSlotTimeZoneData.long_name;
        this.shortNameDistrict = appointmentTimeSlotTimeZoneData.short_name_dst;
        this.longNameDistrict = appointmentTimeSlotTimeZoneData.long_name_dst;
        this.offsetMinutes = appointmentTimeSlotTimeZoneData.offset_minutes;
    }
}

export interface APPT_TIMESLOT_BE {
    start_time: string;
    end_time: string;
    time_slot_id?: string;
    time_zone?: APPT_TIMESLOT_TIMEZONE_BE;
}

export interface APPT_PROPS {
    external_appointment_status?: string;
    reason_code?: string;
}

export interface APPT_NOTE {
    text: string;
    type: string;
    created_by: string;
    created_on: string;
}

export interface APPT_TIMESLOT_FE {
    timeFrom: string;
    timeTo: string;
    timeSlotId?: string;
    date: Date;
    timeZone?: APPT_TIMESLOT_TIMEZONE_FE;
}

export class AppointmentTimeSlot implements APPT_TIMESLOT_FE {
    timeSlotId?: string;
    timeFrom: string;
    timeTo: string;
    date: Date;
    timeZone?: APPT_TIMESLOT_TIMEZONE_FE;

    constructor(appointmentTimesloteData: APPT_TIMESLOT_BE) {
        this.timeSlotId = appointmentTimesloteData?.time_slot_id;
        this.timeFrom = appointmentTimesloteData?.start_time;
        this.timeTo = appointmentTimesloteData?.end_time;
        this.date = new Date(appointmentTimesloteData?.start_time);
        this.timeZone = appointmentTimesloteData?.time_zone
            ? new AppointmentTimeSlotTimeZone(appointmentTimesloteData.time_zone)
            : undefined;
    }
}

// Appointment related stuff

export type AppointmentFromBE = {
    appointment_id: string;
    time_slot: APPT_TIMESLOT_BE;
    memo_agent: string;
    activity_type: APPT_TYPE;
    state: APPT_STATE;
    appointment_source?: APPT_SOURCE;
    updated_appointment_source?: APPT_SOURCE;
    properties?: APPT_PROPS;
    notes?: [APPT_NOTE];
    created_at?: string;
    updated_at?: string;
    created_by?: string;
    updated_by?: string;
    updated_by_channel?: string;
};

// Class itself
export class Appointment {
    appointmentId: string;
    memoAgent: string;
    type: APPT_TYPE;
    state: APPT_STATE;
    activityType: string;
    timeSlot: APPT_TIMESLOT_FE;
    appointmentSource?: APPT_SOURCE;
    updatedAppointmentSource?: APPT_SOURCE;
    externalStatus?: string;
    reasonCode?: string;
    updatedAt?: string | TranslateResult;
    createdAt?: string | TranslateResult;
    createdBy?: string;
    updatedBy?: string;
    updatedByChannel?: string;

    constructor(appointmentData: AppointmentFromBE) {
        this.appointmentId = appointmentData.appointment_id;
        this.memoAgent = appointmentData.memo_agent;
        this.type = appointmentData.activity_type;
        this.state = appointmentData.state;
        this.activityType = appointmentData.activity_type;
        this.appointmentSource = appointmentData.appointment_source;
        this.updatedAppointmentSource = appointmentData.updated_appointment_source;
        this.externalStatus = appointmentData?.properties?.external_appointment_status || '';
        this.reasonCode = appointmentData?.properties?.reason_code || '';
        this.createdAt = appointmentData?.created_at
            ? localeLibrary.getFormattedDateAndTime(Date.parse(appointmentData?.created_at))
            : '';
        this.updatedAt = appointmentData?.updated_at
            ? localeLibrary.getFormattedDateAndTime(Date.parse(appointmentData?.updated_at))
            : '';
        this.createdBy = appointmentData?.created_by || '';
        this.updatedBy = appointmentData?.updated_by || '';
        this.updatedByChannel = appointmentData?.updated_by_channel || '';

        // time slot handling
        const timeSlotData = appointmentData.time_slot;
        this.timeSlot = {
            ...appointmentData.time_slot,
            date: new Date(appointmentData.time_slot.start_time),
            timeFrom: appointmentData.time_slot.start_time,
            timeTo: appointmentData.time_slot.end_time,
            timeSlotId: appointmentData.time_slot?.time_slot_id,
        };

        // time slot time zone handling
        const timeZoneData = timeSlotData?.time_zone;
        if (timeZoneData) {
            this.timeSlot.timeZone = {
                shortName: timeZoneData?.short_name,
                longName: timeZoneData?.long_name,
                shortNameDistrict: timeZoneData?.short_name_dst,
                longNameDistrict: timeZoneData?.long_name_dst,
                offsetMinutes: timeZoneData?.offset_minutes,
            };
        }
    }
}

export default {
    APPT_ACTION,
    APPT_TYPE,
    APPOINTMENT_ACTION_TYPE,
    APPT_STATE,
    APPT_CANCELLATION_CONTEXT,
    getAppointmentActivityLabel,
    getAppointmentStateLabel,
    Appointment,
    AppointmentTimeSlot,
};
