
import Vue from 'vue';

// Vue components
import AppointmentCard from '@/components/partials/cards/AppointmentCard.vue';
import AppDialogV2 from '@/components/partials/AppDialogV2.vue';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import { ICON_TYPES } from '@/common/iconHelper';
import DateTimePicker from '@/components/partials/inputs/DateTimePicker.vue';
import AppMultiselectV3 from '@/components/partials/inputs/AppMultiselectV3.vue';
import AppTextareaV3 from '@/components/partials/inputs/AppTextareaV3.vue';
import AppToggle from '@/components/partials/inputs/AppToggle.vue';
import AppAdditionalSidebar from '@/components/partials/AppAditionalSidebar.vue';

// Vuex
import { mapActions, mapGetters } from 'vuex';
import Actions, { Getters } from '@/store/mutation-types';
import { Modules } from '@/store/store';

// Helpers
import {
    APPT_ACTION,
    APPT_STATE,
    APPT_TYPE,
    APPOINTMENT_ACTION_TYPE,
    getAppointmentActivityLabel,
    Appointment,
    APPT_TIMESLOT_BE,
    APPT_TIMESLOT_FE,
    AppointmentFromBE,
} from '@/__new__/features/customerCare/common/appointmentsHelper';
import { SUBSCRIBER_STATE, USER_MANAGER_HIERARCHY } from '@/__new__/features/customerCare/common/customerCareHelper';
import { ORDER_ENTITY_STATES } from '@/__new__/features/customerCare/common/orderStateHelper';
import * as Sentry from '@sentry/vue';
import Button from '@/common/button/Button';
import { ALERT_TYPES } from '@/common/alerts/Alert';
import {
    isAddressEligible,
    getAddressbookParams,
    getOperatorEligibilityIds,
    ADDRESS_ELIGIBILITY,
} from '@/common/addressbookHelper';
import permissionsService, {
    isUserAllowed,
    getBillingVersion,
    BILLING_VERSIONS,
    isViewEnabled,
} from '@/services/permissions/permissions.service';
import { TOOLTIP_POSITION } from '@/common/tooltip';
import Order from '@/__new__/services/dno/orders/models/Order';
import Account from '@/models/Account';
import luaErrors from '@/common/luaErrors';

// HTTP
import * as appointmentsHTTP from '@/http/appointments';
import addressbookHTTP from '@/http/addressbook';
import ordersHTTP from '@/__new__/services/dno/orders/http/orders';
import providerHTTP from '@/http/provider';

const NOTE_MAX_LENGTH = 1000;

export default Vue.extend({
    name: 'AppointmentHistoryTile',
    components: {
        AppointmentCard,
        AppDialogV2,
        AppButton,
        AppMultiselectV3,
        AppTextareaV3,
        AppToggle,
        DateTimePicker,
        AppAdditionalSidebar,
    },
    props: {
        userManagerHierarchy: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            providerInfo: null,
            appointmentsInfo: null,

            // appts per type
            installationAppointments: [] as Array<Appointment>,
            maintenanceAppointments: [] as Array<Appointment>,
            damageAppointments: [] as Array<Appointment>,

            // modal related stuff
            modalMode: null,
            isModalVisible: false,
            changeAppointmentTime: false,
            edittedAppointment: false,
            appointmentsModalData: {
                date: null,
                timeSlot: '',
                type: '',
                memoAgent: '',
                isNotificationEnabled: true,
            },
            isFetchingTimeSlots: false,
            appointmentsTimeSlots: [] as Array<APPT_TIMESLOT_FE>,
            allAvailableTimeSlots: [] as Array<APPT_TIMESLOT_FE>,
            hasEligibleAddress: false,

            alertButtons: {
                confirmEditButton: new Button({
                    label: this.$i18n.t('generic.confirm'),
                    alertType: ALERT_TYPES.warning,
                }),
                confirmDeleteButton: new Button({
                    label: this.$i18n.t('generic.confirm'),
                    alertType: ALERT_TYPES.warning,
                }),
            },

            // Appointment History Sidebar
            appointmentHistory: [],
            appointmentHistoryType: '',
            showAditionalSidebar: false,

            // proxy
            isViewEnabled,
            TOOLTIP_POSITION,
            APPT_ACTION,
            ICON_TYPES,
            BUTTON_TYPES,
            NOTE_MAX_LENGTH,
        };
    },
    computed: {
        ...mapGetters(Modules.customerCareSuite, [Getters.GET_ASSOCIATED_UM_ENTITY_BY_TYPE_AND_ID]),
        ...mapGetters(Modules.orders, {
            getOrders: Getters.GET_ORDERS,
            getPrimaryOrders: Getters.GET_PRIMARY_ORDERS,
        }),
        targetId() {
            return this.$route.params.id || '';
        },
        entity() {
            return this[Getters.GET_ASSOCIATED_UM_ENTITY_BY_TYPE_AND_ID]({
                targetType: this.userManagerHierarchy,
                targetId: this.targetId,
            });
        },
        entityInfo() {
            return this.targetId ? this.entity : null;
        },
        activityType() {
            return this.appointmentsModalData.type?.value || this.appointmentsModalData.type;
        },
        allowCreateAppointment() {
            const addresses =
                this.userManagerHierarchy === USER_MANAGER_HIERARCHY.ACCOUNT
                    ? this?.entity?.addresses
                    : this?.entity?.addressesMapped;
            return (
                isUserAllowed('UMAccountAppointmentWrite') &&
                this.createOptions.length &&
                addresses?.some(el => el.eligibility === ADDRESS_ELIGIBILITY.ELIGIBLE)
            );
        },
        allowAllInstallActions() {
            return (
                permissionsService.accountAppointmentCRUDInstallAlways() &&
                isUserAllowed('UMAccountAppointmentCRUDInstallAlways')
            );
        },
        allowAllMaintenanceActions() {
            return (
                permissionsService.accountAppointmentCRUDMaintenanceAlways() &&
                isUserAllowed('UMAccountAppointmentCRUDMaintenanceAlways')
            );
        },
        dateAndTimeLabel() {
            const localizedDateLabel = this.formatDate(this.edittedAppointment.timeSlot.date);
            const localizedStartTime = this.$localeLibrary.getFormattedTime(this.edittedAppointment.timeSlot.timeFrom);
            const localizedEndTime = this.$localeLibrary.getFormattedTime(this.edittedAppointment.timeSlot.timeTo);

            return `${localizedDateLabel}, ${localizedStartTime} - ${localizedEndTime}`;
        },
        isInstallationAppointmentsEmpty() {
            return !this.installationAppointments.length;
        },
        isMaintenanceAppointmentsEmpty() {
            return !this.maintenanceAppointments.length;
        },
        isDamageAppointmentsEmpty() {
            return !this.damageAppointments.length;
        },
        supportedAppointmentTypes() {
            return this.providerInfo?.integration_params?.appointments?.supported_appointment_types || [];
        },
        isDamageAppointmentEnabled() {
            return this.supportedAppointmentTypes.includes(APPT_TYPE.DAMAGE);
        },
        isMaintenanceAppointmentAllowed() {
            return this.allowAllMaintenanceActions || this.isAccountActive;
        },
        isPreorderAppointmentEnabled() {
            return Boolean(getOperatorEligibilityIds());
        },
        isPreorderAppointment() {
            if (!this.isPreorderAppointmentEnabled) {
                return false;
            }

            return (
                this.entity?.state === SUBSCRIBER_STATE.PENDING &&
                this.hasNoAppointments &&
                this.hasPreorder &&
                this.hasEligibleAddress
            );
        },
        hasNoAppointments() {
            return (
                this.isInstallationAppointmentsEmpty &&
                this.isMaintenanceAppointmentsEmpty &&
                this.isDamageAppointmentsEmpty
            );
        },
        getModalHeader() {
            if (this.modalMode === APPT_ACTION.EDIT) {
                return this.$i18n.t(getAppointmentActivityLabel(this.edittedAppointment.type, APPT_ACTION.EDIT));
            }

            return this.$i18n.t('customerCare.appointmentsSection.scheduleAppointment');
        },
        shouldShowDateAndTimeSlotPicker() {
            if (this.modalMode === APPT_ACTION.EDIT) {
                return this.changeAppointmentTime;
            }
            return true;
        },
        appointmentsData() {
            const data = [
                {
                    type: APPT_TYPE.INSTALLATION,
                    title: this.$i18n.t('appointments.installations'),
                    noItemsLabel: this.isInstallationAppointmentsEmpty
                        ? this.$i18n.t('appointments.noInstallationAppointments')
                        : '',
                    appointments: this.installationAppointments,
                },
                {
                    type: APPT_TYPE.MAINTAINANCE,
                    title: this.$i18n.t('appointments.maintenance'),
                    noItemsLabel: this.isMaintenanceAppointmentsEmpty
                        ? this.$i18n.t('appointments.noActiveMaintenanceAppointments')
                        : '',
                    appointments: this.maintenanceAppointments,
                },
            ];

            if (this.isDamageAppointmentEnabled) {
                data.push({
                    type: APPT_TYPE.DAMAGE,
                    title: this.$i18n.t('appointments.damages'),
                    noItemsLabel: this.isDamageAppointmentsEmpty
                        ? this.$i18n.t('appointments.noActiveDamageAppointments')
                        : '',
                    appointments: this.damageAppointments,
                });
            }

            return data;
        },
        createOptions() {
            if (!this.appointmentsData.length) {
                return [];
            }
            return this.appointmentsData
                .filter(i => this.getApptActionsPerType(i.type).includes(APPT_ACTION.CREATE))
                .map(i => ({
                    value: i.type,
                    label: this.$i18n.t(getAppointmentActivityLabel(i.type, APPT_ACTION.CREATE)),
                }));
        },
        // orders
        orders(): Array<Order> {
            return getBillingVersion() === BILLING_VERSIONS.v4 ? this.getOrders : this.getPrimaryOrders;
        },
        ordersData() {
            return Object.values(this.orders).sort((a: Order, b: Order) => b.createTimestamp - a.createTimestamp);
        },
        currentOrder(): Order {
            return this.ordersData[0];
        },
        hasPreorder() {
            return this.currentOrder && this.currentOrder.isPreorder;
        },
        isOrderPendingActivation() {
            return this.currentOrder && this.currentOrder.state === ORDER_ENTITY_STATES.PENDING_ACTIVATION;
        },
        isAccountActive() {
            return (
                this.entity?.state === SUBSCRIBER_STATE.ACTIVE ||
                this.entity?.state === SUBSCRIBER_STATE.GRACE_WITH_SUBSCRIPTION
            );
        },
        hasCanceledOrder() {
            return this.currentOrder && this.currentOrder.state === ORDER_ENTITY_STATES.CANCELED;
        },
        additionalSidebarHeader() {
            switch (this.appointmentHistoryType) {
                case APPT_TYPE.INSTALLATION:
                    return this.$i18n.t('customerCare.appointmentsSection.installationAppointmentHistory');
                case APPT_TYPE.MAINTAINANCE:
                    return this.$i18n.t('customerCare.appointmentsSection.troubleCallAppointmentHistory');
                case APPT_TYPE.DAMAGE:
                    return this.$i18n.t('customerCare.appointmentsSection.damageAppointmentHistory');
                default:
                    return '';
            }
        },
    },
    watch: {
        account(newVal) {
            if (newVal) {
                this.fetchAddressEligibility();
            }
        },
    },
    created() {
        this.fetchTileData();
    },
    methods: {
        ...mapActions(Modules.orders, [Actions.LOAD_ALL_OEORDERS_ACCOUNT]),
        fetchTileData() {
            this.$withProgressBar(
                async () => {
                    const targetId =
                        this.userManagerHierarchy === USER_MANAGER_HIERARCHY.ACCOUNT
                            ? this.targetId
                            : this?.entity?.accountId;
                    await Promise.all([this.getProvider(), this.fetchAddressEligibility()]);
                    await Promise.all([this.fetchAppointments(), this[Actions.LOAD_ALL_OEORDERS_ACCOUNT](targetId)]);
                    this.$emit('isDataLoadingUpd', false);
                },
                {
                    errorHandler: () => {
                        this.$emit('dataFetchingError');
                        this.$emit('isDataLoadingUpd', false);
                    },
                },
            );
        },
        async fetchAppointments() {
            await this.$withProgressBar(
                async () => {
                    this.$emit('isDataLoadingUpd', true);
                    this.appointmentsInfo = await appointmentsHTTP.getAppointments({
                        targetId: this.targetId,
                        targetType: this.userManagerHierarchy,
                    });
                    this.parseAppointments();
                    this.$emit('isDataLoadingUpd', false);
                },
                {
                    errorHandler: () => {
                        this.$emit('dataFetchingError');
                        this.$emit('isDataLoadingUpd', false);
                    },
                },
            );
        },
        closeModal() {
            this.isModalVisible = false;
            this.clearModalData();
        },
        clearModalData() {
            this.appointmentsModalData = {
                date: null,
                timeSlot: '',
                memoAgent: undefined,
                type: '',
                isNotificationEnabled: true,
            };
            this.allAvailableTimeSlots = [];
        },
        openCreateModal() {
            this.clearModalData();
            this.modalMode = APPT_ACTION.CREATE;
            this.isModalVisible = true;
        },
        openEditModal(appointment: Appointment) {
            this.edittedAppointment = appointment;
            this.modalMode = APPT_ACTION.EDIT;
            this.changeAppointmentTime = false;
            this.appointmentsModalData = {
                date: appointment.timeSlot.date,
                memoAgent: appointment.memoAgent,
                idOfEditedAppointment: appointment.appointmentId,
                timeSlot: null,
                type: appointment.type,
            };
            this.filterAvailableTimeSlots(appointment);

            // this.$set(this.appointmentsModalData, 'timeSlot', this.selectTimeSlotOnEdit(appointment));
            this.isModalVisible = true;
        },
        openAppointmentHistory(id, type) {
            this.showAditionalSidebar = true;
            this.appointmentHistoryType = type;
            this.fetchAppointmentHistory(id);
        },
        async fetchTimeSlots() {
            try {
                this.appointmentsTimeSlots = [];
                this.allAvailableTimeSlots = [];
                this.isFetchingTimeSlots = true;
                this.appointmentsModalData.date = '';

                if (this.activityType) {
                    this.$Progress.start();
                    const res = await appointmentsHTTP.getAvailableTimeslots({
                        targetId: this.targetId,
                        targetType: this.userManagerHierarchy,
                        type: this.activityType,
                    });
                    this.allAvailableTimeSlots = res?.data?.time_slots.map((el: APPT_TIMESLOT_BE) => ({
                        date: new Date(el.start_time),
                        timeFrom: el.start_time,
                        timeTo: el.end_time,
                        id: el.time_slot_id,
                    }));

                    if (!this.allAvailableTimeSlots?.length) {
                        this.$showInfoAlert({
                            message: this.$i18n.t('appointments.alerts.noAppointmentTimeSlots'),
                        });
                    }

                    this.$Progress.finish();
                }
            } catch (error) {
                this.$Progress.fail();
                Sentry.captureException(error);
                if (!error.isInterceptorAlertShown) {
                    this.$alert(this.$i18n.t('appointments.alerts.failedLoadingAppointmentTimeSlots'));
                }
                this.clearModalData();
            } finally {
                this.isFetchingTimeSlots = false;
            }
        },
        parseAppointments() {
            this.$Progress.start();
            const appointmentsResponse = this.appointmentsInfo?.data || [];
            if (Array.isArray(appointmentsResponse.appointments)) {
                const apptsParsed = appointmentsResponse.appointments.map(
                    (apptBE: AppointmentFromBE) => new Appointment(apptBE),
                );

                this.installationAppointments = apptsParsed.filter(
                    (appointment: Appointment) => appointment.type === APPT_TYPE.INSTALLATION || !appointment.type,
                );
                this.maintenanceAppointments = apptsParsed.filter(
                    (appointment: Appointment) => appointment.type === APPT_TYPE.MAINTAINANCE,
                );
                this.damageAppointments = apptsParsed.filter(
                    (appointment: Appointment) => appointment.type === APPT_TYPE.DAMAGE,
                );
            }
            this.$Progress.finish();
        },
        formatDate(date) {
            let month = `${date.getMonth() + 1}`;
            let day = `${date.getDate()}`;
            const year = date.getFullYear();

            if (month.length < 2) {
                month = `0${month}`;
            }
            if (day.length < 2) {
                day = `0${day}`;
            }

            return [year, month, day].join('-');
        },
        filterAvailableTimeSlots() {
            this.$set(this.appointmentsModalData, 'timeSlot', '');
            const selectedDate = this.formatDate(this.appointmentsModalData.date);
            /**
             *  @param {string} time - e.g. '2023-04-26T15:00:00'
             *  @returns {string} e.g. '3:00 PM'
             */
            const formatTimeLabel = time => {
                const hour = time.substring(11, 13) > 12 ? time.substring(11, 13) - 12 : time.substring(11, 13);
                const min = time.substring(14, 16);
                const meridiem = time.substring(11, 13) >= 12 ? 'PM' : 'AM';

                return `${hour}:${min} ${meridiem}`;
            };

            this.appointmentsTimeSlots = this.allAvailableTimeSlots.reduce((arr, ts) => {
                if (ts.timeFrom.substring(0, 10) === selectedDate) {
                    const { timeFrom, timeTo, id } = ts;

                    arr.push({
                        label: `${formatTimeLabel(timeFrom)} - ${formatTimeLabel(timeTo)}`,
                        timeFrom,
                        timeTo,
                        id,
                    });
                }
                return arr;
            }, []);
        },
        getApptActionsPerType(type) {
            let actions = [];
            const isNotAllowingMultiple = ({ state }) => ![APPT_STATE.CANCELED, APPT_STATE.COMPLETED].includes(state);

            switch (type) {
                case APPT_TYPE.DAMAGE:
                    actions = [APPT_ACTION.EDIT, APPT_ACTION.DELETE];

                    if (
                        !this.damageAppointments.some(isNotAllowingMultiple) &&
                        (this.isAccountActive || isUserAllowed('UMAccountAppointmentCRUDMaintenanceAlways'))
                    ) {
                        actions.push(APPT_ACTION.CREATE);
                    }
                    break;

                case APPT_TYPE.MAINTAINANCE:
                    if (this.isMaintenanceAppointmentAllowed) {
                        actions = [APPT_ACTION.EDIT, APPT_ACTION.DELETE];

                        if (
                            !this.maintenanceAppointments.some(isNotAllowingMultiple) &&
                            (this.isAccountActive || isUserAllowed('UMAccountAppointmentCRUDMaintenanceAlways'))
                        ) {
                            actions.push(APPT_ACTION.CREATE);
                        }
                    }
                    break;

                case APPT_TYPE.INSTALLATION:
                default:
                    if (
                        !this.installationAppointments.some(isNotAllowingMultiple) &&
                        (this.isOrderPendingActivation || isUserAllowed('UMAccountAppointmentCRUDInstallAlways'))
                    ) {
                        actions.push(APPT_ACTION.CREATE);
                    }

                    if (
                        this.allowAllInstallActions ||
                        (this.currentOrder &&
                            !this.hasCanceledOrder &&
                            (!this.hasPreorder || (this.hasPreorder && this.hasEligibleAddress)))
                    ) {
                        actions.push(APPT_ACTION.EDIT);
                    }

                    if (this.allowAllInstallActions || this.hasCanceledOrder) {
                        actions.push(APPT_ACTION.DELETE);
                    }
            }

            return actions;
        },
        selectTimeSlotOnEdit(appointment) {
            const timeSlot = this.appointmentsTimeSlots.find(
                ts => ts.timeFrom === appointment.timeSlot.timeFrom && ts.timeTo === appointment.timeSlot.timeTo,
            );

            return timeSlot;
        },
        async onSave() {
            if (this.modalMode === APPT_ACTION.EDIT) {
                this.onUpdateAppointment();
            } else {
                this.$Progress.start();
                this.isFetchingTimeSlots = true;
                await this.fetchAddressEligibility();
                await this.createAppointmentCall();
                this.isFetchingTimeSlots = false;
                this.$Progress.finish();
            }
        },
        onUpdateAppointment() {
            this.$alert(this.$i18n.t('appointments.alerts.editAppointmentConfirm'), {
                type: ALERT_TYPES.warning,
                buttons: [this.alertButtons.confirmEditButton],
            });
            this.$eventBus.$once('buttonClicked', buttonId => {
                if (buttonId === this.alertButtons.confirmEditButton.id) {
                    this.updateAppointmentsCall();
                }
            });
        },
        onDeleteAppointment(appointmentId) {
            this.$alert(this.$i18n.t('appointments.alerts.areYouSureDelete'), {
                type: ALERT_TYPES.warning,
                buttons: [this.alertButtons.confirmDeleteButton],
            });
            this.$eventBus.$once('buttonClicked', buttonId => {
                if (buttonId === this.alertButtons.confirmDeleteButton.id) {
                    this.deleteAppointmentCall(appointmentId);
                }
            });
        },
        async createAppointmentCall() {
            try {
                this.$Progress.start();
                const res = await appointmentsHTTP.reserveAppointment({
                    targetId: this.targetId,
                    targetType: this.userManagerHierarchy,
                    timeFrom: this.appointmentsModalData.timeSlot.timeFrom,
                    timeTo: this.appointmentsModalData.timeSlot.timeTo,
                    timeSlotId: this.appointmentsModalData.timeSlot.id,
                    activityType: this.activityType,
                    createdBy: Account.storedInstance.name,
                });

                const reservationId = res.data.reservation_id;

                if (this.isPreorderAppointment) {
                    await ordersHTTP.completePreorder({
                        targetId: this.targetId,
                        targetType: this.userManagerHierarchy,
                        reservationId,
                    });
                } else {
                    await appointmentsHTTP.bookAppointment({
                        targetId: this.targetId,
                        targetType: this.userManagerHierarchy,
                        reservationId,
                        note: this.appointmentsModalData.memoAgent,
                        activityType: this.activityType,
                    });
                }

                this.isModalVisible = false;

                this.$showSuccessAlert({
                    message: this.$i18n.t('appointments.alerts.successfullyCreated'),
                });

                // refresh appointments
                this.fetchAppointments();

                this.$Progress.finish();
            } catch (e) {
                Sentry.captureException(e);
                this.$Progress.fail();
                this.$alert(this.$i18n.t('appointments.errors.unableToCreate'));
            }
        },
        async updateAppointmentsCall() {
            try {
                this.$Progress.start();
                this.isFetchingTimeSlots = true;

                const { targetId } = this;
                const { timeSlot, memoAgent, isNotificationEnabled, idOfEditedAppointment, type } =
                    this.appointmentsModalData;

                let ts: APPT_TIMESLOT_BE | null;
                if (this.changeAppointmentTime) {
                    ts = {
                        start_time: timeSlot.timeFrom,
                        end_time: timeSlot.timeTo,
                        ...(timeSlot.id && { time_slot_id: timeSlot.id }),
                    };
                } else {
                    ts = null;
                }

                await appointmentsHTTP.updateAppointment({
                    targetId,
                    targetType: this.userManagerHierarchy,
                    idOfEditedAppointment,
                    timeSlot: ts,
                    note: memoAgent,
                    isNotificationEnabled,
                    activityType: type,
                    updatedBy: Account.storedInstance.name,
                    ...(this.changeAppointmentTime && { reasonCode: 'SELF_SERVICE' }),
                });

                this.isModalVisible = false;

                this.$showSuccessAlert({
                    message: this.$i18n.t('appointments.alerts.successfullyUpdated'),
                });

                // refresh appointments
                this.fetchAppointments();

                this.$Progress.finish();
            } catch (e) {
                Sentry.captureException(e);
                this.$Progress.fail();
                this.$alert(this.$i18n.t('appointments.errors.unableToUpdate'));
            } finally {
                this.isFetchingTimeSlots = false;
            }
        },
        async deleteAppointmentCall(appointmentId) {
            try {
                this.$Progress.start();

                await appointmentsHTTP.deleteAppointment({
                    targetId: this.targetId,
                    targetType: this.userManagerHierarchy,
                    appointmentId,
                    updatedBy: Account.storedInstance.name,
                    reasonCode: 'SELF_SERVICE',
                });

                this.isModalVisible = false;

                this.$showSuccessAlert({
                    message: this.$i18n.t('appointments.alerts.successfullyDeleted'),
                });

                // refresh appointments
                this.fetchAppointments();
                this.$Progress.finish();
            } catch (e) {
                this.$Progress.fail();
                Sentry.captureException(e);
                if (e?.response?.data?.code === luaErrors.APPOINTMENTS.CANCEL_APPOINTMENT_NOT_ALLOWED.code) {
                    this.$alert(luaErrors.APPOINTMENTS.CANCEL_APPOINTMENT_NOT_ALLOWED.errorMessage);
                } else {
                    this.$alert(this.$i18n.t('appointments.errors.unableToDelete'));
                }
            }
        },
        async fetchAddressEligibility() {
            if (!this.isPreorderAppointmentEnabled || !this.entityInfo) {
                return;
            }
            try {
                const addresses =
                    this.userManagerHierarchy === USER_MANAGER_HIERARCHY.ACCOUNT
                        ? this?.entity?.addresses
                        : this?.entity?.addressesMapped;
                const reqs = Object.values(addresses).map(address =>
                    addressbookHTTP.checkEligibility(getAddressbookParams(address)),
                );
                const results = await Promise.all(reqs);

                this.hasEligibleAddress = results
                    .map(({ data }, i) => isAddressEligible(data, addresses[i].unitNumber))
                    .some(Boolean);
            } catch (e) {
                this.$Progress.fail();
                Sentry.captureException(e);
                this.$showErrorAlert({
                    message: this.$i18n.t('alertMessage.userManagement.somethingWentWrongFetchingAddressEligibility'),
                });
            }
        },
        checkDisabledDate(date) {
            for (const ts of this.allAvailableTimeSlots) {
                if (ts.date.toDateString() === date.toDateString()) {
                    return false;
                }
            }
            return true;
        },
        async getProvider() {
            if (!this.entity?.providerId) {
                return;
            }
            await this.$withProgressBar(
                async () => {
                    const res = await providerHTTP.getProviders([this.entity.providerId]);
                    const providers = res?.data?.providers;
                    if (Array.isArray(providers)) {
                        this.providerInfo = providers.find(p => p.provider_id === this.entity.providerId);
                    }
                },
                {
                    errorHandler: () =>
                        this.$showErrorAlert({
                            message: this.$i18n.t('alertMessage.userManagement.somethingWentWrongGettingProvider'),
                        }),
                },
            );
        },
        fetchAppointmentHistory(id) {
            this.$withProgressBar(
                async () => {
                    this.$emit('isDataLoadingUpd', true);
                    const response = await appointmentsHTTP.getAppointmentHistory({
                        targetId: this.targetId,
                        targetType: this.userManagerHierarchy,
                        appointmentId: id,
                    });
                    this.appointmentHistory = (response?.data?.appointment_history || [])
                        .filter(el =>
                            [APPOINTMENT_ACTION_TYPE.RESCHEDULE, APPOINTMENT_ACTION_TYPE.CREATE].includes(
                                el.action_type,
                            ),
                        )
                        .map(el => new Appointment(el));
                    this.$emit('isDataLoadingUpd', false);
                },
                {
                    errorHandler: () => {
                        this.$emit('dataFetchingError');
                        this.$emit('isDataLoadingUpd', false);
                    },
                },
            );
        },
    },
});
