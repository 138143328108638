<template>
    <div class="h-100 entity-overview white-bg">
        <!-- Header / Top Label -->
        <div class="row d-flex no-gutters font-weight-bold align-items-center justify-content-between header-bar">
            <div class="col-8 header-title">{{ entityNameFormatted }} {{ $i18n.t('generic.details') }}</div>
            <IconButton
                iconSize="1rem"
                data-test-id="close-icon"
                :icon="ICON_TYPES.CLOSE"
                @iconClick="$emit('closeOverview')"
            />
            <div
                v-if="languageSupport"
                class="col-4 languages-wrapper"
            >
                <span
                    v-for="(language, index) in languages"
                    :key="index"
                    :class="{ active: activeLanguage || language }"
                    class="language"
                    @click="onLanguagePicked(language)"
                >
                    {{ language }}
                </span>
            </div>
        </div>

        <!-- Entity Data overview -->
        <div
            v-if="entity"
            class="entity-view"
        >
            <!-- Tabs -->
            <div
                v-if="sectionsEnabled"
                class="row no-gutters"
            >
                <div
                    v-for="section in sections"
                    :key="section.id"
                    :class="[
                        tabColumn,
                        {
                            active: selectedSectionId === section.id,
                        },
                    ]"
                    class="section-tab d-flex align-items-center text-uppercase"
                    @click="selectTab(section.id)"
                >
                    {{ section.name }}
                </div>
            </div>
            <!-- Main Content in section -->
            <div class="entity-content">
                <!-- Header -->
                <div class="section-header">
                    <slot :name="'section-1-header'" />
                </div>
                <!-- Content -->
                <div :class="{ 'section-content': shouldScrollContent }">
                    <slot :name="`section-${selectedSectionId}-content`" />
                </div>
            </div>
        </div>

        <!-- Display Info Message when nothing is selected -->
        <EmptyDetails
            v-else
            :name="entityNameFormatted"
        />
    </div>
</template>

<script>
// components
import IconButton from '@/components/partials/IconButton.vue';

// helpers
import { Getters } from '@/store/mutation-types';
import { mapGetters } from 'vuex';
import { entityTypeDisplayName } from '@/common/utils';
import EmptyDetails from '@/components/partials/table/EmptyDetails.vue';
import { ICON_TYPES } from '@/common/iconHelper';

export default {
    name: 'EntityOverview',
    components: {
        IconButton,
        EmptyDetails,
    },
    props: {
        entityType: {
            required: true,
            type: String,
        },
        entity: {
            required: false,
            type: Object,
            default: null,
        },
        preselectSection: {
            required: false,
            default: 1,
            type: Number,
        },
        languageSupport: {
            required: false,
            default: false,
            type: Boolean,
        },
        sections: {
            // Array of Objects { id: {int}, name: {string}, scrollContent?: {boolean} }
            type: Array,
            default: () => [],
        },
        sectionsEnabled: {
            default: false,
            type: Boolean,
        },
        scrollContent: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            ICON_TYPES,
            sectionId: null,
            activeLanguage: '',
        };
    },
    computed: {
        ...mapGetters('operators', [Getters.allLanguages]),
        languages() {
            return this[Getters.allLanguages];
        },
        entityNameFormatted() {
            return entityTypeDisplayName(this.entityType);
        },
        tabColumn() {
            const colCount = Math.floor(12 / this.sections.length);
            if (colCount !== 1) {
                return `col-${colCount}`;
            }
            return 'col';
        },
        selectedSectionId: {
            get() {
                return this.sectionId ? this.sectionId : this.preselectSection;
            },
            set(id) {
                this.sectionId = id;
            },
        },
        selectedSection() {
            return this.sections.find(section => section.id === this.selectedSectionId);
        },
        shouldScrollContent() {
            return this.selectedSection?.scrollContent ?? this.scrollContent;
        },
    },
    watch: {
        sections() {
            // set first tab as active in case when sections list is changed
            if (this.sectionsEnabled) {
                this.selectTab(this.sections[0]?.id);
            }
        },
    },
    methods: {
        onLanguagePicked(language) {
            if (language !== this.activeLanguage) {
                this.$emit('languagePicked', language);
                this.activeLanguage = language;
            }
        },
        selectTab(id) {
            this.selectedSectionId = id;
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/_palette';
@import '~@/assets/scss/_colors';
@import '~@/assets/scss/_consistency';

.entity-overview {
    box-shadow: 0 0.063rem 0.125rem 0 $box-shadow-color;
    min-height: 25rem;
    height: 100%;
    padding: $spacing-l $spacing-xl $spacing-l $spacing-xl;

    .header-bar {
        background-color: $white;
        font-size: 0.875rem;
        font-weight: 600;
        color: $gray90;
        margin-bottom: 1rem;
        & > * {
            line-height: 2.5rem;
        }

        .header-title {
            font-size: 0.75rem;
            line-height: 1.25rem;
            text-transform: uppercase;
            font-weight: 700;
            color: $gray-blue;
        }
    }

    .languages-wrapper {
        text-align: right;
    }

    .language {
        width: 1.125rem;
        height: 1.125rem;
        padding: 0 0.625rem;
        font-size: 0.812rem;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-transform: uppercase;
        color: $gray30;
        cursor: pointer;

        &.active {
            color: $blue;
        }

        &:hover {
            background-color: $dirty-white;
            color: $blue;
        }
    }

    .entity-view {
        height: calc(100% - 3.5rem);

        .section-tab {
            background-color: $white;
            border: 0.063rem solid $dirty-white;
            border-right: none;
            /* Adding transparent border to make both columns equal height */
            border-top: 3px solid transparent;

            height: 2.5rem;

            font-size: 0.8125rem;
            font-weight: 600;
            text-transform: uppercase;
            color: $gray30;
            padding-left: 1rem;

            &.active {
                background-color: $white;
                border-top: 0.188rem solid $blue;
                border-bottom: 0.063rem solid transparent;
                color: $blue;
            }

            &:first-child {
                border-left: none;
            }

            &:last-child {
                border-right: none;
            }

            &:hover:not(.active) {
                background-color: $dirty-white;
                color: $blue;
                cursor: pointer;
            }
        }

        .entity-content {
            display: flex;
            flex-direction: column;
            height: calc(100% - 3rem);

            .section-content {
                flex-grow: 1;
                overflow-y: auto;
                max-height: calc(100vh - 15rem);
            }
        }
    }
}

.cursor-pointer {
    cursor: pointer;
}
</style>
