
// Components
import AbstractTableTile from '@/__new__/features/customerCareSuite/components/AbstractTableTile.vue';
import DeviceStatusIndicator from '@/components/partials/DeviceStatusIndicator.vue';
import IconButton from '@/components/partials/IconButton.vue';
import { ICON_TYPES } from '@/common/iconHelper';
import Session, { DEVICE_STATE } from '@/__new__/services/dno/user/models/Session';
import { ALERT_TYPES } from '@/common/alerts/Alert';
import * as Sentry from '@sentry/vue';
import userManagementHTTP from '@/__new__/services/dno/user/http/user-management';
import { USER_MANAGER_HIERARCHY } from '@/__new__/features/customerCare/common/customerCareHelper';
import tableColumnType from '@/common/filterTable';

export default {
    name: 'LoggedInDevicesTile',
    components: {
        AbstractTableTile,
        IconButton,
        DeviceStatusIndicator,
    },
    props: {
        tableKey: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            sessions: [],
            apiResponse: {},

            ICON_TYPES,
            DEVICE_STATE,
        };
    },
    computed: {
        haveActiveSessions() {
            return this.sessions.some(session => session.state === DEVICE_STATE.ACTIVE);
        },
        columnsData() {
            return [
                {
                    name: this.$i18n.t('customerCare.userInformation.deviceID'),
                    key: 'id',
                    field: 'id',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('customerCare.userInformation.lastValidVersion'),
                    key: 'lastValidVersion',
                    field: 'lastValidVersion',
                    filterType: tableColumnType.NUMBER,
                },
                {
                    name: this.$i18n.t('generic.status'),
                    key: 'state',
                    field: 'stateString',
                    filterType: tableColumnType.TEXT_LIMITED_OPTIONS,
                    limitedOptions: [...new Set(this.sessions.map(entity => entity.stateString))],
                },
            ];
        },
    },
    created() {
        this.fetchTileData();
    },
    methods: {
        async fetchTileData() {
            try {
                this.$Progress.start();
                this.$emit('isDataLoadingUpd', true);
                this.apiResponse = await userManagementHTTP.getSessionsInfo(
                    this.$route.params.id,
                    USER_MANAGER_HIERARCHY.USER,
                );
                this.sessions = this.apiResponse.data.sessions.map(
                    session => new Session(Session.remapSessionFromBe(session)),
                );
                this.$Progress.finish();
                this.$emit('isDataLoadingUpd', false);
            } catch (e) {
                Sentry.captureException(e);
                this.$Progress.fail();
                this.$emit('isDataLoadingUpd', false);
                this.$alert(this.$i18n.t('alertMessage.failedToLoadNecessaryData'));
            }
        },
        async onLogoutAllClicked(userId) {
            try {
                this.$Progress.start();
                await this.endSession(userId);
                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('customerCare.userInformation.successLogOutDevice'),
                    type: ALERT_TYPES.success,
                });
                this.$Progress.finish();
            } catch (e) {
                Sentry.captureException(e);
                this.$Progress.fail();
                this.$alert(this.$i18n.t('customerCare.userInformation.failLogOutDevice'));
            }
        },
        async onLogoutSessionClicked(userId, sessionId) {
            try {
                this.$Progress.start();
                await this.endSession(userId, sessionId);
                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('customerCare.userInformation.successLogOutDevice'),
                    type: ALERT_TYPES.success,
                });
                this.$Progress.finish();
            } catch (e) {
                Sentry.captureException(e);
                this.$Progress.fail();
                this.$alert(this.$i18n.t('customerCare.userInformation.failLogOutDevice'));
            }
        },
        async endSession(userId, sessionId) {
            try {
                this.$Progress.start();
                await userManagementHTTP.logout(userId, USER_MANAGER_HIERARCHY.USER, sessionId);
                this.$Progress.finish();
                this.fetchTileData(userId);
            } catch (e) {
                Sentry.captureException(e);
                this.$Progress.fail();
                this.$alert(this.$i18n.t('alertMessage.failedToLoadNecessaryData'));
            }
        },
    },
};
