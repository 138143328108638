<template>
    <EntityListPageLayout
        :entityType="entityType"
        :listViewColumns="listViewColumns"
        writePermission="ServicesWrite"
    />
</template>

<script>
import EntityListPageLayout from '@/__new__/features/pc/EntityListPageLayout.vue';
import ENTITY_TYPES from '@/common/entities/entityTypes';
import tableColumnType from '@/common/filterTable';

export default {
    name: 'ServiceListPage',
    components: {
        EntityListPageLayout,
    },
    data() {
        return {
            entityType: ENTITY_TYPES.SERVICE,
        };
    },
    computed: {
        listViewColumns() {
            return [
                {
                    name: this.$i18n.t('productCatalog.templates.templateName'),
                    key: 'templateName',
                    field: 'templateName',
                    filterType: tableColumnType.TEXT_LIMITED_OPTIONS,
                    limitedOptions: [],
                },
                {
                    name: this.$i18n.t('productCatalog.templates.templateExternalId'),
                    key: 'templateExternalId',
                    field: 'templateExternalId',
                    filterType: tableColumnType.TEXT_LIMITED_OPTIONS,
                    limitedOptions: [],
                },
            ];
        },
    },
};
</script>
