<template>
    <AbstractEditPageWrapper>
        <template #header>
            <AppHeader :pageTitle="pageTitle" />
        </template>

        <template #content>
            <div class="editor-container">
                <div class="section">
                    <div class="d-flex align-items-center mb-3">
                        <div class="lf-subtitle mb-0">
                            {{ $i18n.t('generic.general') }}
                            <p
                                v-show="inEditMode && isRewardsDraftEnabled"
                                class="lf-secondary-text mb-0"
                            >
                                {{ $i18n.t('generic.id') }}: {{ $route.params.id }}
                            </p>
                            <p
                                v-show="inEditMode && isRewardsDraftEnabled"
                                class="lf-secondary-text mb-0"
                            >
                                {{ $i18n.t('generic.updateTime') }}:
                                {{ $localeLibrary.getFormattedDateAndTime(updateTime) }}
                            </p>
                            <p
                                v-show="inEditMode && isRewardsDraftEnabled"
                                class="lf-secondary-text mb-0"
                            >
                                {{ $i18n.t('generic.updateUser') }}: {{ updateName }}
                            </p>
                        </div>
                        <LanguageSwitcher
                            v-model="selectedLanguage"
                            :tooltipEnabled="true"
                        />
                        <AppLabel
                            v-if="isDraft"
                            :title="$i18n.t('generic.stateMap.draft')"
                            :color="LABEL_COLOR.gray"
                            class="justify-content-center state-label ml-auto"
                        />
                        <AppLabel
                            v-if="isPublished"
                            :title="$i18n.t('generic.stateMap.published')"
                            :color="LABEL_COLOR.green"
                            class="justify-content-center state-label ml-auto"
                        />
                        <AppLabel
                            v-if="isUnpublished"
                            :title="$i18n.t('generic.stateMap.unpublishedChanges')"
                            :color="LABEL_COLOR.gray"
                            class="justify-content-center state-label ml-3"
                        />
                        <AppButton
                            v-if="allowEditDraftBtn"
                            :buttonType="BUTTON_TYPES.SECONDARY"
                            :label="$i18n.t('generic.editDraft')"
                            :class="['ml-3', { 'ml-auto': noLabels }]"
                            @click="reloadEditor(false)"
                        />
                        <AppButton
                            v-if="allowViewPublishedBtn"
                            :buttonType="BUTTON_TYPES.SECONDARY"
                            :label="$i18n.t('generic.viewPublished')"
                            :class="['ml-3', { 'ml-auto': noLabels }]"
                            @click="reloadEditor(true)"
                        />
                    </div>
                    <div class="sub-section">
                        <div class="row no-gutters">
                            <AppInputV3
                                v-model="name[selectedLanguage]"
                                :placeholder="$i18n.t('generic.addName')"
                                :label="$i18n.t('generic.name')"
                                :invalid="$v.name[selectedLanguage].$error"
                                :disabled="readonly"
                                type="text"
                                class="col-6 min-width"
                            />
                        </div>
                    </div>
                    <div class="sub-section">
                        <div class="row no-gutters">
                            <AppTextareaV3
                                v-model="description[selectedLanguage]"
                                :placeholder="$i18n.t('generic.addDescription')"
                                :label="$i18n.t('generic.description')"
                                :resizeNone="true"
                                :disabled="readonly"
                                class="col-6 min-width"
                            />
                        </div>
                    </div>
                    <div class="sub-section">
                        <div class="row no-gutters">
                            <AppMultiselectV3
                                v-model="type"
                                :options="typeOptions"
                                :additionalLabel="$i18n.t('generic.type')"
                                :placeholder="$i18n.t('generic.type')"
                                :small="true"
                                :preselectFirst="true"
                                :allowEmpty="false"
                                :disabled="readonly"
                                class="col-3 min-width"
                                data-test-id="engine-type-select"
                                label="label"
                                trackBy="id"
                            />
                        </div>
                    </div>
                    <div class="sub-section">
                        <div class="row no-gutters">
                            <AppInputV3
                                v-model.number="priority"
                                :placeholder="$i18n.t('prizeEngine.editor.priority')"
                                :label="$i18n.t('prizeEngine.editor.priority')"
                                :min="1"
                                :roundNumber="true"
                                :disabled="readonly"
                                type="number"
                                class="col-1 pb-1 min-width"
                            />
                        </div>
                    </div>
                    <div
                        v-if="!isPrizeEngineTypeGeneric"
                        class="sub-section"
                        data-test-id="spin-wheel-image"
                    >
                        <div class="row no-gutters">
                            <AppInputV3
                                v-model.trim="spinWheelImageUrl"
                                :placeholder="$i18n.t('prizeEngine.editor.addUrl')"
                                :label="$i18n.t('prizeEngine.editor.spinWheelImage')"
                                :optional="true"
                                :disabled="readonly"
                                class="col-6 pb-1 min-width"
                            />
                        </div>
                    </div>
                    <div class="sub-section">
                        <div class="row no-gutters">
                            <AppInputV3
                                v-model.number="numPrizes"
                                :placeholder="$i18n.t('prizeEngine.editor.addNumber')"
                                :label="$i18n.t('prizeEngine.editor.numberOfPrizes')"
                                :notAllowZero="true"
                                :disabled="readonly"
                                type="number"
                                class="col-1 pb-1 min-width"
                            />
                        </div>
                        <span class="explanation">
                            {{ $i18n.t('prizeEngine.editor.recommendedNumOfPrizes') }}
                        </span>
                    </div>
                    <div class="sub-section">
                        <div class="row no-gutters align-items-end">
                            <AppMultiselectV3
                                v-model="costType"
                                :options="costOptions"
                                :additionalLabel="$i18n.t('prizeEngine.editor.costOfSpin')"
                                :placeholder="$i18n.t('prizeEngine.editor.addCost')"
                                :small="true"
                                :showLabels="false"
                                :borderNone="true"
                                :blueArrow="true"
                                :preselectFirst="true"
                                :allowEmpty="false"
                                :disabled="readonly"
                                label="label"
                                trackBy="id"
                                class="col-2 min-width"
                            />
                            <AppInputV3
                                v-model="costAmount"
                                :placeholder="$i18n.t('prizeEngine.editor.addCost')"
                                :disabled="readonly"
                                :invalid="$v.costAmount.$error"
                                class="col-3 min-width"
                            />
                        </div>
                    </div>
                    <div class="sub-section">
                        <div
                            v-if="!isEditing"
                            class="d-flex"
                        >
                            <div class="sub-section-title">
                                {{ $i18n.t('rewards.editor.general.approveOnCreation') }}
                            </div>
                            <AppToggle
                                v-model="approveOnCreation"
                                :small="true"
                                :disabled="readonly"
                                class="group-checkbox"
                            />
                        </div>
                    </div>
                </div>
                <!-- Cohort expression -->
                <div class="section pb-3">
                    <div
                        v-if="cohortsEnabled"
                        class="cohort mb-4"
                    >
                        <div class="section-title mb-3">
                            {{ $i18n.t('segments.cohortExpression') }}
                            <span class="text-gray"> ({{ $i18n.t('generic.optional') }}) </span>
                        </div>
                        <CohortExpression
                            v-model="cohortsObj"
                            :disabled="readonly"
                            class="cohort-expression mb-4"
                            data-test-id="cohort-expression"
                            @input="cohortsObj = $event"
                        />
                    </div>
                </div>
                <div
                    v-if="numPrizes"
                    class="section row no-gutters"
                >
                    <div class="section-title">
                        {{ $i18n.t('rewards.prizeParameters') }}
                    </div>
                    <TabNavigation
                        :numOfTabs="numPrizes"
                        :tabName="$i18n.t('prizeEngine.editor.prize')"
                        :previousText="$i18n.t('prizeEngine.editor.previousPrize')"
                        :nextText="$i18n.t('prizeEngine.editor.configureNextPrize')"
                        class="col-12"
                    >
                        <template #content="{ activeTab }">
                            <PrizeParametersContent
                                v-model="prizeParametersArray[activeTab]"
                                :isPrizeEngineTypeGeneric="isPrizeEngineTypeGeneric"
                                :invalidProbability="invalidPrizesProbability"
                                :prizesTotalProbability="prizesTotalProbability"
                                :prizes="
                                    prizeParametersArray[activeTab]
                                        ? prizeParametersArray[activeTab].defaultPrize
                                            ? defaultPrizes
                                            : prizes
                                        : prizes
                                "
                                :disabled="readonly"
                            />
                        </template>
                    </TabNavigation>
                </div>
            </div>
        </template>
        <template #controls>
            <EditorButtons
                :showSaveDraft="isRewardsDraftEnabled && !readonly"
                :showPublish="isRewardsDraftEnabled && !readonly"
                :showSave="!isRewardsDraftEnabled"
                :disableSave="readonly"
                @cancel="onCancel"
                @saveDraft="onSave(false)"
                @publish="onSave(true)"
                @save="onSave(true)"
            />
        </template>
    </AbstractEditPageWrapper>
</template>

<script>
// Generic Vue Components
import AbstractEditPageWrapper from '@/components/layout/AbstractEditPageWrapper.vue';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import AppHeader from '@/components/layout/AppHeader.vue';
import AppInputV3 from '@/components/partials/inputs/AppInputV3.vue';
import AppTextareaV3 from '@/components/partials/inputs/AppTextareaV3.vue';
import AppMultiselectV3 from '@/components/partials/inputs/AppMultiselectV3.vue';
import AppToggle from '@/components/partials/inputs/AppToggle.vue';
import TabNavigation from '@/components/partials/TabNavigation.vue';
import PrizeParametersContent from '@/modules/rewards/components/PrizeParametersContent.vue';
import LanguageSwitcher from '@/components/partials/inputs/LanguageSwitcher.vue';
import { ALERT_TYPES } from '@/common/alerts/Alert';
import CohortExpression from '@/components/cohortExpression/CohortExpression.vue';
import EditorButtons from '@/components/layout/EditorButtons.vue';
import { LABEL_COLOR } from '@/common/labelsHelper';
import AppLabel from '@/components/partials/AppLabel.vue';
import { STATUS_CODES } from '@/common/commonHelper';
import * as Sentry from '@sentry/vue';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';

// Helpers
import cloneDeep from 'lodash/cloneDeep';
import { mapActions, mapGetters } from 'vuex';
import {
    PRIZE_ENGINE_TYPE_KEYS,
    PRIZE_ENGINE_TYPE_OPTIONS,
    PRIZE_ENGINE_CURRENCY_TYPES,
    SEGMENT_COMBINE_OPERATION_TYPES,
    PRIZE_ENGINE_PRIZE_TYPE_OPTIONS,
    REQUIRED_PRIZES_TOTAL_PROBABILITY,
} from '@/modules/rewards/common/RewardsHelper';
import ENTITY_TYPES from '@/common/entities/entityTypes';
import { getMultiLangFieldValueByLocale, entityTypeToEntityLabel } from '@/common/entities/entityHelper';

import { Modules } from '@/store/store';
import Actions, { Getters } from '@/store/mutation-types';
import entityEditorMixin from '@/common/entityEditorMixin';
import RouteNames from '@/router/routeNames';
import permissionsService, { isUserAllowed } from '@/services/permissions/permissions.service';
import {
    isNonEmptyCohortExpression,
    cohortExpressionsFromJson,
    cohortExpressionsToJson,
} from '@/common/cohortExpressionHelper';
import { ICON_TYPES } from '@/common/iconHelper';

import { manageEntityAdd, manageEntityUpdate } from '@/common/EntityLoadHelper';
import { getUserNameById } from '@/__new__/services/portal/profile/http/profile';

// HTTP
import {
    addPrizeEngine,
    updatePrizeEngine,
    setPrizeEngineDraft,
    getPrizeEngineDraft,
} from '@/__new__/services/dno/rewards/http/rewards';

const prizeParameter = {
    prizeType: null,
    defaultPrize: false,
    prize: null,
    probability: 0,
    startAngle: 0,
    endAngle: 45,
    redeemingPrize: true,
    successMessage: '',
    successImage: '',
    ctaButtonLeads: '',
    ctaButtonText: '',
};

export default {
    name: 'PrizeEngineEditor',

    components: {
        AbstractEditPageWrapper,
        AppButton,
        AppInputV3,
        AppHeader,
        AppTextareaV3,
        AppMultiselectV3,
        AppToggle,
        TabNavigation,
        PrizeParametersContent,
        CohortExpression,
        AppLabel,
        EditorButtons,
        LanguageSwitcher,
    },

    mixins: [entityEditorMixin, validationMixin],

    data() {
        return {
            selectedLanguage: '',
            PRIZE_ENGINE_TYPE_KEYS,
            PRIZE_ENGINE_TYPE_OPTIONS,
            PRIZE_ENGINE_CURRENCY_TYPES,
            entityType: ENTITY_TYPES.PRIZE_ENGINE,
            type: null,
            approveOnCreation: false,
            name: {},
            description: {},
            priority: 1,
            numPrizes: 0,
            costType: null,
            costAmount: null,
            version: null,
            initialData: null,
            entityDraft: undefined,
            publishedEntity: undefined,
            updateName: this.$i18n.t('generic.N/A'),
            updateTime: null,
            readonly: false,
            isUnpublished: false,
            isOnlyDraft: false,
            isRewardsDraftEnabled: permissionsService.isRewardsDraftEnabled(),
            saveButtonClicked: false,
            draftSavedId: null,

            // on numPrizes change change this array
            prizeParametersArray: [],
            spinWheelImageUrl: '',
            cohortsObj: {
                applicableCohorts: [],
                notApplicableCohorts: [],
                everyApplicableCohort: false,
                everyNotApplicableCohort: false,
            },

            // Expose imported colors and icons so they can be used in the template:
            ICON_TYPES,
            BUTTON_TYPES,
            LABEL_COLOR,
        };
    },

    validations() {
        return {
            name: {
                [this.selectedLanguage]: {
                    required,
                },
            },
            costAmount: {
                required,
            },
        };
    },

    computed: {
        ...mapGetters('rewards', {
            getEntity: Getters.GET_REWARDS_ENTITY_BY_TYPE_AND_ID,
            getNotDeletedEntities: Getters.GET_NOT_DELETED_REWARDS_ENTITIES_BY_TYPE,
        }),
        ...mapGetters(Modules.segments, {
            segments: Getters.CACHED_SEGMENTS,
            groupedSegments: Getters.GROUPED_SEGMENTS_BY_ID_TYPE,
        }),
        cohortsEnabled() {
            return (
                (permissionsService.segmentsEnabled() || permissionsService.userGroupsEnabled()) &&
                isUserAllowed('SegmentsRead', 'SegmentsWrite')
            );
        },
        arrayCohortsAll() {
            return this.groupedSegments.map(group => group.groupValues).flat();
        },
        pageInEditMode() {
            return Boolean(this.$route.params.id);
        },
        languageDefault() {
            return this.$store.getters[`operators/${Getters.languageDefault}`];
        },
        typeOptions() {
            return Object.values(this.PRIZE_ENGINE_TYPE_OPTIONS).map(opt => ({
                ...opt,
                label: this.$i18n.t(opt.i18nLabel),
            }));
        },
        prizeTypeOptions() {
            return Object.values(PRIZE_ENGINE_PRIZE_TYPE_OPTIONS).map(opt => ({
                ...opt,
                label: this.$i18n.t(opt.i18nLabel),
            }));
        },
        costOptions() {
            return Object.values(this.PRIZE_ENGINE_CURRENCY_TYPES).map(opt => ({
                ...opt,
                label: this.$i18n.t(opt.i18nLabel),
            }));
        },
        eligibilityTypes() {
            return Object.values(SEGMENT_COMBINE_OPERATION_TYPES).map(opt => ({
                ...opt,
                label: this.$i18n.t(opt.i18nLabel),
            }));
        },
        prizes() {
            return this.getNotDeletedEntities(ENTITY_TYPES.PRIZE).map(prize => ({
                ...prize,
                name: getMultiLangFieldValueByLocale(prize.data.name, this.languageDefault),
            }));
        },
        defaultPrizes() {
            return this.prizes.filter(prize => !Object.prototype.hasOwnProperty.call(prize.data, 'max_purchases'));
        },
        prizesTotalProbability() {
            return this.prizeParametersArray.reduce((sum, prize) => sum + prize.probability, 0);
        },
        invalidPrizesProbability() {
            return this.prizesTotalProbability !== REQUIRED_PRIZES_TOTAL_PROBABILITY;
        },
        isPrizeEngineTypeGeneric() {
            return this.type?.id === PRIZE_ENGINE_TYPE_KEYS.GENERIC;
        },
        inEditMode() {
            return Boolean(this.$route.params.id) && !this.$route.params.clone;
        },
        isDraft() {
            return (
                this.$route.params.id && !this.$route.params.readonly && this.entityDraft && this.isRewardsDraftEnabled
            );
        },
        isPublished() {
            return this.$route.params.id && this.$route.params.readonly && this.isRewardsDraftEnabled;
        },
        noLabels() {
            return !this.isDraft && !this.isPublished;
        },
        allowViewPublishedBtn() {
            return (
                this.$route.params.id && !this.$route.params.readonly && !this.isOnlyDraft && this.isRewardsDraftEnabled
            );
        },
        allowEditDraftBtn() {
            return (
                this.$route.params.id && this.$route.params.readonly && !this.isOnlyDraft && this.isRewardsDraftEnabled
            );
        },
        pageTitle() {
            const label = entityTypeToEntityLabel[ENTITY_TYPES.PRIZE_ENGINE];
            if (this.$route.params.readonly) {
                return label;
            }
            if (this.inEditMode) {
                return `${this.$i18n.t('generic.edit')} ${label} ${
                    this.isDraft ? this.$i18n.t('generic.stateMap.draft') : ''
                }`;
            }
            return `${this.$i18n.t('generic.addNew')} ${label}`;
        },
    },

    watch: {
        numPrizes() {
            if (this.numPrizes === 0 || this.numPrizes === '') {
                this.numPrizes = this.prizeParametersArray.length;
            } else if (this.numPrizes > this.prizeParametersArray.length) {
                for (let i = this.prizeParametersArray.length; i < this.numPrizes; i += 1) {
                    const emptyPrizeConfig = {};
                    Object.assign(emptyPrizeConfig, prizeParameter);
                    this.$set(this.prizeParametersArray, i, emptyPrizeConfig);
                }
            } else {
                this.prizeParametersArray.splice(this.numPrizes, this.prizeParametersArray.length - this.numPrizes);
            }
        },
    },

    mounted() {
        this.addWatcher('$data');
    },

    created() {
        this.$withLoadingSpinner(async () => {
            if (this.$route.params.readonly) {
                this.readonly = this.$route.params.readonly;
            }

            const promises = [
                this.$store.dispatch(`rewards/${Actions.REQUEST_REWARDS_ENTITIES_BY_TYPE}`, ENTITY_TYPES.PRIZE_ENGINE),
                this.$store.dispatch(`rewards/${Actions.REQUEST_REWARDS_ENTITIES_BY_TYPE}`, ENTITY_TYPES.PRIZE),
            ];
            if (permissionsService.segmentsEnabled() && isUserAllowed('SegmentsRead', 'SegmentsWrite')) {
                promises.push(this.requestSegments());
            }
            await Promise.all(promises);

            this.selectedLanguage = this.languageDefault;

            if (this.$route.params.id) {
                const { id } = this.$route.params;
                if (this.isRewardsDraftEnabled && !this.$route.params.readonly) {
                    const result = await getPrizeEngineDraft(id);
                    this.entityDraft = result?.data?.data ? result.data.data[id] : null;
                    if (this.entityDraft) this.entityDraft.state = STATUS_CODES.NA;
                }

                this.publishedEntity = this.getEntity(ENTITY_TYPES.PRIZE_ENGINE, id);
                if (this.entityDraft && !(this.$route.params.clone && this.publishedEntity)) {
                    this.initialData = cloneDeep(this.entityDraft);
                    this.initialData.version = this.publishedEntity.version;
                    this.isOnlyDraft = this.publishedEntity.state === STATUS_CODES.NA;
                    if (this.publishedEntity.update_time <= this.initialData.update_time) {
                        this.isUnpublished = true;
                    }
                } else {
                    this.initialData = cloneDeep(this.publishedEntity);
                }

                this.version = this.initialData.version;
                this.updateTime = this.initialData.update_time;
                this.getUpdateUserName(this.initialData.update_portal_id || this.initialData.portal_id || null);

                this.initData(this.initialData.data);

                if (this.$route.params.clone) {
                    this.name[this.selectedLanguage] += ' (cloned)';
                }
            } else {
                this.numPrizes = 8;
            }
        });
    },

    methods: {
        ...mapActions(Modules.segments, {
            requestSegments: Actions.FETCH_SEGMENTS,
        }),

        buildPrizeEngine() {
            const prizes = this.prizeParametersArray.map(param => {
                // if endAngle < startAngle we adjust 360 to get value in [0:360] range
                const deltaDegrees = param.endAngle - param.startAngle;
                const sizeDegrees = param.endAngle > param.startAngle ? deltaDegrees : deltaDegrees + 360;
                const metadata = !this.isPrizeEngineTypeGeneric
                    ? {
                          start_angle_degrees: param.startAngle,
                          size_degrees: sizeDegrees,
                      }
                    : {};

                const parsedPrize = {
                    is_default_prize: param.defaultPrize,
                    probability_percent: param.probability,
                    metadata,
                };

                // if contains prize and if prize is a common prize
                if (param.prize && param.prizeType.id === this.prizeTypeOptions[0].id) {
                    parsedPrize.prize_id = param.prize.id;
                }

                if (param.redeemingPrize) {
                    if (param.successImage) {
                        parsedPrize.image_url = param.successImage;
                    }
                    if (param.successMessage) {
                        parsedPrize.success_message = param.successMessage;
                    }
                    if (param.ctaButtonLeads) {
                        parsedPrize.cta_button_leads = param.ctaButtonLeads;
                    }
                    if (param.ctaButtonText) {
                        parsedPrize.cta_button_text = param.ctaButtonText;
                    }
                }
                return parsedPrize;
            });
            let cohortExpression;
            if (isNonEmptyCohortExpression(this.cohortsObj)) {
                cohortExpression = cohortExpressionsToJson(this.cohortsObj);
            }
            return {
                name: { ...this.name },
                description: { ...this.description },
                prize_engine_type: this.type.id,
                priority: this.priority,
                cohort_expression: cohortExpression,
                metadata: !this.isPrizeEngineTypeGeneric
                    ? {
                          spin_wheel_image_url: this.spinWheelImageUrl,
                      }
                    : {},
                prize_selection_cost_amount: {
                    [this.costType.id]: this.costAmount,
                },
                prizes,
            };
        },
        async onSave(isPublish) {
            if (this.saveButtonClicked) {
                return;
            }
            this.saveButtonClicked = true;
            if (isPublish) {
                this.$v.$touch();
                if (this.$v.$invalid) {
                    this.$eventBus.$emit('showAlert', {
                        message: this.$i18n.t('alertMessage.pleaseFixValidation'),
                    });
                } else {
                    await this.submit(true);
                }
            } else {
                await this.submit(false);
            }
            this.saveButtonClicked = false;
        },
        async submit(isPublish) {
            try {
                this.$Progress.start();

                const builtPrizeEngine = this.buildPrizeEngine();

                const addDraftAction = async () => {
                    if (!this.isRewardsDraftEnabled) return null;
                    const resp = await setPrizeEngineDraft({ data: builtPrizeEngine });
                    this.draftSavedId = resp?.data?.id;
                    return resp;
                };

                const addEntityAction = id =>
                    addPrizeEngine({
                        data: builtPrizeEngine,
                        approve_on_create: this.approveOnCreation,
                        id,
                    });
                const updateDraftAction = () =>
                    this.isRewardsDraftEnabled
                        ? setPrizeEngineDraft({
                              data: builtPrizeEngine,
                              id: this.$route.params.id ?? this.draftSavedId,
                          })
                        : null;
                const updateEntityAction = () =>
                    updatePrizeEngine(this.$route.params.id, this.version, builtPrizeEngine);
                const hasStoredDraft = this.draftSavedId || (this.entityDraft && !this.$route.params.clone);
                const hasStoredEntity =
                    this.publishedEntity && this.publishedEntity.state !== STATUS_CODES.NA && !this.$route.params.clone;
                if (!hasStoredEntity) {
                    await manageEntityAdd(
                        hasStoredDraft ? updateDraftAction : addDraftAction,
                        addEntityAction,
                        isPublish,
                    );
                } else {
                    await manageEntityUpdate(updateDraftAction, updateEntityAction, isPublish);
                }

                this.$Progress.finish();
                this.$eventBus.$emit('showAlert', {
                    message: this.successSaveMessage,
                    type: ALERT_TYPES.success,
                });
                this.entityEditorMixin.successfullySaved = true;
                setTimeout(
                    () =>
                        this.$router.push({
                            name: RouteNames.PRIZE_ENGINE_VIEW,
                            params: { companyId: this.$route.params.companyId },
                        }),
                    1000,
                );
            } catch (e) {
                this.$Progress.fail();
                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('alertMessage.errorDoingSmthTryAgain', {
                        action: this.$i18n.t('generic.saving'),
                        entityName: this.$i18n.t('productCatalog.entities.prizeEngine'),
                    }),
                });
            }
        },
        initData(data) {
            const entries = Object.entries(data.prize_selection_cost_amount);
            if (entries.length === 0) {
                entries[0] = ['primary', null];
            }
            const [costType, costAmount] = entries[0];

            const prizeParams = data.prizes.map(param => {
                const typeId = param.prize_id
                    ? PRIZE_ENGINE_PRIZE_TYPE_OPTIONS.common.id
                    : PRIZE_ENGINE_PRIZE_TYPE_OPTIONS.noWin.id;

                // if endAngle > 360 we need adjust to get value in [0:360] range
                const endAngle = (param.metadata.start_angle_degrees ?? 0) + (param.metadata.size_degrees ?? 0);

                const paramFormatted = {
                    prizeType: this.prizeTypeOptions.find(type => type.id === typeId),
                    defaultPrize: param.is_default_prize,
                    probability: param.probability_percent,
                    startAngle: param.metadata.start_angle_degrees ?? 0,
                    endAngle: endAngle <= 360 ? endAngle : endAngle - 360,
                };

                if (param.prize_id) {
                    paramFormatted.prize = this.prizes.find(prize => prize.id === param.prize_id);
                }

                if (param.image_url || param.success_message || param.cta_button_leads || param.cta_button_text) {
                    paramFormatted.redeemingPrize = true;
                    paramFormatted.successMessage = param.success_message;
                    paramFormatted.successImage = param.image_url;
                    paramFormatted.ctaButtonLeads = param.cta_button_leads;
                    paramFormatted.ctaButtonText = param.cta_button_text;
                }

                return paramFormatted;
            });

            if (Object.hasOwnProperty.call(data, 'metadata')) {
                this.spinWheelImageUrl = data.metadata.spin_wheel_image_url;
            }

            if (data.cohort_expression) {
                const cohortExpression = data.cohort_expression;
                this.cohortsObj = cohortExpressionsFromJson(cohortExpression, this.arrayCohortsAll);
            }
            this.name = data.name;
            this.description = data?.description ?? {};
            this.priority = data.priority;
            this.type = this.typeOptions.find(type => type.id === data.prize_engine_type);
            this.numPrizes = data.prizes.length;
            this.costType = costType ? this.costOptions.find(type => type.id === costType) : null;
            this.costAmount = costAmount;
            this.prizeParametersArray = prizeParams;
        },
        async getUpdateUserName(id) {
            try {
                if (id) {
                    const response = await getUserNameById(Number(id));
                    if (response?.data) {
                        this.updateName = response.data;
                    }
                }
            } catch (e) {
                Sentry.captureException(e);
            }
        },
        reloadEditor(isReadonly) {
            const { id } = this.$route.params;
            // Use push to list page because router don`t want to reload same page
            this.$router.push({ name: RouteNames.PRIZE_ENGINE_VIEW }).then(() => {
                this.$router.push({
                    name: RouteNames.PRIZE_ENGINE_EDITOR,
                    params: {
                        entityType: ENTITY_TYPES.PRIZE_ENGINE,
                        id,
                        readonly: isReadonly,
                    },
                });
            });
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/_colors';
@import '~@/assets/scss/_palette';
@import '~@/assets/scss/_editor-layout-v2';

.text-gray {
    color: $gray30;
    font-weight: 400;
}

.editor {
    background-color: $white;
    display: flex;
    flex-flow: column;
    height: 100%;
}

.editor-container {
    padding: 2.25rem 1.5rem 0 1.5rem;
}

.section-title {
    font-size: 1.125rem;
    color: $gray90;
    font-weight: 600;
    padding-bottom: 1.25rem;
}

.sub-section-desc {
    font-size: 0.75rem;
    line-height: 1.67;
    color: $gray60;
}

.cohort-expression {
    padding: 0.625rem;
    border: 0.0625rem solid $gray5;
    border-radius: 0.5rem;
    max-width: 45rem;
}
</style>
