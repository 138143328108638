<template>
    <div>
        <div
            v-if="!(!multiple && hasSelectedFiles)"
            :class="[{ 'upload-error': isInvalid }, { 'drag-on': isDragOn }]"
            class="upload-container mb-3"
            @drag="preventAndStop"
            @dragover="preventAndStop"
            @dragstart="preventAndStop"
            @dragend="preventAndStop"
            @dragenter="[addDragOnClass(), e => preventAndStop(e)]"
            @dragleave="[deleteDragOnClass(), e => preventAndStop(e)]"
            @drop="onDrop"
        >
            <input
                id="file"
                :multiple="multiple"
                :accept="acceptType"
                type="file"
                name="file"
                class="inputfile"
                @change="addFilesToList"
            />
            <div class="w-100 d-flex flex-column">
                <img
                    :class="['upload-img', { 'small-icon': smallIcon }]"
                    src="@/assets/icons/file-upload.svg"
                />
                <div class="upload-text d-flex justify-content-center">
                    {{ $i18n.t('partials.fileUploader.dragAndDropText') }}
                    <label
                        for="file"
                        class="upload-link ml-1"
                    >
                        {{ $i18n.t('partials.fileUploader.browseText') }}
                    </label>
                </div>
            </div>
        </div>
        <div
            v-if="hasSelectedFiles"
            class="files-wrap"
        >
            <div
                v-for="(file, index) in selectedFiles"
                :key="index"
                class="file"
            >
                <div class="file-list d-flex align-items-center justify-content-between">
                    <div class="d-flex w-100">
                        <div class="preview-image-wrap my-2 mr-2">
                            <img
                                :src="getImage(file)"
                                :alt="file.name"
                                class="preview-image-small"
                            />

                            <img
                                :src="getImage(file)"
                                :alt="file.name"
                                class="preview-image-big"
                            />
                        </div>
                        <div class="file-info d-flex flex-column justify-content-center">
                            <div class="file-name">
                                {{ file.name }}
                            </div>
                            <div
                                v-if="!hideProgress"
                                class="file-status d-flex justify-content-between align-items-center"
                            >
                                <div class="file-loading">
                                    <div
                                        class="file-loading-green"
                                        :style="getLoadingWidth(index)"
                                    />
                                    <div class="file-loading-gray" />
                                </div>
                            </div>
                            <div class="file-subtext">
                                {{ getSizeText(file.size, index) }}
                            </div>
                        </div>
                        <div class="d-flex align-items-center">
                            <div
                                class="file-icon"
                                :class="{ success: getLoadingPercentage(index) >= 100 }"
                                @click="removeFile(index)"
                            >
                                <img
                                    src="@/assets/icons/close-white.svg"
                                    class="icon-close"
                                />
                                <img
                                    src="@/assets/icons/success.svg"
                                    class="icon-success"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ALERT_TYPES } from '@/common/alerts/Alert';
import { formatDataAmount } from '@/common/formatting';
import fileImage from '@/assets/icons/file.svg';

export default {
    name: 'FileUploader',
    props: {
        acceptType: {
            type: String,
            default: '',
        },
        multiple: {
            type: Boolean,
            default: true,
        },
        invalid: {
            type: Boolean,
            default: false,
        },
        // Array<Number>
        loadingPercentage: {
            type: Array,
            default: () => [],
        },
        smallIcon: {
            type: Boolean,
            default: false,
        },
        hideProgress: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            selectedFiles: [],
            progressPercentage: '0%',
            isDragOn: false,
        };
    },
    computed: {
        isInvalid() {
            return this.multiple ? this.invalid : this.invalid || this.selectedFiles.length > 0;
        },
        hasSelectedFiles() {
            return this.selectedFiles.length;
        },
    },
    methods: {
        addFilesToList(e) {
            const files = e.target.files || e.dataTransfer.files;
            if (files.length) {
                this.selectedFiles = this.selectedFiles.concat(Array.from(files));
            }
            this.$emit('updateFileList', this.selectedFiles);
        },
        isFileImage(file) {
            return file.type.includes('image/');
        },
        getImage(file) {
            if (this.isFileImage(file)) {
                return URL.createObjectURL(file);
            }

            return fileImage;
        },
        removeFile(index) {
            this.$delete(this.selectedFiles, index);
            this.$emit('updateFileList', this.selectedFiles);
        },
        addDragOnClass() {
            if (!this.isDragOn) {
                this.isDragOn = true;
            }
        },
        deleteDragOnClass() {
            if (this.isDragOn) {
                this.isDragOn = false;
            }
        },
        preventAndStop(e) {
            e.preventDefault();
            e.stopPropagation();
        },
        onDrop(e) {
            this.preventAndStop(e);
            this.deleteDragOnClass();

            if (!this.multiple && e.dataTransfer.files.length > 1) {
                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('partials.fileUploader.multipleFileErrorText'),
                    type: ALERT_TYPES.warning,
                });
            } else {
                // eslint-disable-next-line prefer-destructuring
                const name = e.dataTransfer.files[0].name;
                if (this.isAppropriateFileType(name)) {
                    const droppedFiles = e.dataTransfer.files;
                    this.selectedFiles = Array.from(droppedFiles);
                    this.$emit('updateFileList', this.selectedFiles);
                } else {
                    this.$eventBus.$emit('showAlert', {
                        message: `${this.$i18n.t('partials.fileUploader.invalidFileTypeText')} ${this.$i18n.t(
                            'partials.fileUploader.allowedFileTypeText',
                        )} ${this.acceptType}.`,
                        type: ALERT_TYPES.warning,
                    });
                }
            }
        },
        isAppropriateFileType(name) {
            // The following expression returns a list of all accepted file extensions because the
            // input type file element accepts a string of comma separated extension ( ".txt, .csv")
            const types = this.acceptType
                .trim()
                .split(',')
                .map(extension => extension.trim());
            return types.some(acceptedType => name.endsWith(acceptedType));
        },
        getLoadingPercentage(index) {
            return this.loadingPercentage[index] || 0;
        },
        getLoadingWidth(index) {
            return { width: `${this.getLoadingPercentage(index)}%` };
        },
        getSizeText(size, index) {
            const sizeFormatted = formatDataAmount(size);
            if (!this.hideProgress) {
                const loadingPercentage = this.getLoadingPercentage(index);
                const sizeLoaded = Math.round(loadingPercentage * parseFloat(sizeFormatted)) / 100;

                return `${sizeLoaded} ${this.$i18n.t('generic.of')} ${sizeFormatted} (${loadingPercentage}%)`;
            }
            return `Size: ${sizeFormatted}`;
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/palette';
@import '~@/assets/scss/mixins';
@import '~@/assets/scss/z-indexes';

.inputfile {
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: $hide-smth-z-index;
}

.upload-container {
    border-radius: 0.5rem;
    border: dashed 0.0625rem $gray5;
    padding: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;

    &.upload-error {
        opacity: 0.5;
        pointer-events: none;
    }

    &.drag-on {
        background: rgba(51, 81, 149, 0.05);
    }
}

.upload-img {
    margin-bottom: 0.75rem;

    &.small-icon {
        align-self: center;
        width: 7rem;
    }
}

.upload-text {
    font-size: 0.875rem;
    font-weight: 600;
    color: $gray90;
    margin-bottom: 0.25rem;
}

.upload-link {
    color: $blue;
    z-index: $overlap-smth-z-index;
    cursor: pointer;

    &:hover {
        text-decoration: underline;
    }
}

.file-list {
    .preview-image-small {
        object-fit: cover;
        border-radius: 0.25rem;
        width: 4.75rem;
        height: 3.625rem;
    }

    .preview-image-big {
        display: none;
    }

    .preview-image-wrap {
        position: relative;
        margin-right: 1.5rem;

        &:hover .preview-image-big {
            display: block;
            max-width: 50vw;
            max-height: 50vh;
            border-radius: 0.5rem;
            z-index: $overlap-smth-z-index;
            position: absolute;
            bottom: 0;
            right: -0.25rem;
            transform: translateX(100%);
        }
    }
}

.file-info {
    font-size: 0.75rem;
    width: 100%;
}

.file-name {
    font-weight: 600;
    line-height: 2.17;
    margin-bottom: 0.25rem;
}

.file-status {
    margin-bottom: 0.25rem;
}

.file-loading {
    height: 0.5rem;
    width: 100%;
    margin-right: 1.5rem;
    position: relative;

    &-green,
    &-gray {
        height: 100%;
        border-radius: 0.5rem;
        position: absolute;
        top: 0;
        left: 0;
    }

    &-green {
        background: $green;
        z-index: $overlap-smth-z-index;
    }

    &-gray {
        background: $dirty-white;
        width: 100%;
    }
}

.file-subtext {
    color: $gray30;
    line-height: 1.75;
}

.icon-close,
.icon-success {
    width: 0.75rem;
    height: 0.75rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.icon-success {
    display: none;
}

.file-icon {
    min-width: 1rem;
    width: 1rem;
    height: 1rem;
    position: relative;
    cursor: pointer;
    background: #a8aab7;
    border-radius: 50%;

    &.success {
        background: none;
        cursor: unset;

        .icon-close {
            display: none;
        }

        .icon-success {
            display: block;
        }
    }
}

.small-gray-note {
    text-align: center;
}

.files-wrap {
    display: flex;
    flex-wrap: wrap;
}

.file {
    width: 100%;
}
</style>
