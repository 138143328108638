<template>
    <div class="page-background">
        <AppHeader :pageTitle="pageTitle">
            <template
                v-if="isViasatDemo && viasatCustomerSignUpUrl"
                slot="headerButtons"
            >
                <a
                    :href="viasatCustomerSignUpUrl"
                    target="_blank"
                >
                    <AppButton
                        class="mr-3"
                        :buttonType="BUTTON_TYPES.PRIMARY"
                        :iconType="ICON_TYPES.PLUS"
                        :label="'New Customer Sign-up'"
                    />
                </a>
            </template>
        </AppHeader>

        <div class="content-container">
            <SearchBanner
                :title="$i18n.t('customerCare.search.searchForCustomers')"
                :primaryText="$i18n.t('customerCare.search.chooseParameter')"
            />

            <div class="search-wrapper container">
                <form
                    class="d-flex align-items-center justify-content-center flex-wrap"
                    data-test-id="um-search-form"
                    @submit.prevent
                    @keydown.enter.prevent="onSearch"
                >
                    <span class="pt-1 pr-2 lf-all-caps">
                        {{ $i18n.t('customerCare.search.userWith') }}
                    </span>
                    <AppMultiselectV3
                        v-model="selectedSearchOption"
                        :options="Object.values(searchOptions)"
                        :allowEmpty="false"
                        :preselectFirst="true"
                        :small="true"
                        :borderNone="true"
                        :blueArrow="true"
                        :newDesign="true"
                        trackBy="key"
                        label="label"
                        class="search-options-dropdown pt-1"
                        @select="onOptionSelect"
                    />

                    <AppMultiselectV3
                        v-model="selectedSearchOperator"
                        :options="searchOperations"
                        :allowEmpty="false"
                        :preselectFirst="true"
                        :small="true"
                        :borderNone="true"
                        :blueArrow="true"
                        :newDesign="true"
                        trackBy="key"
                        label="label"
                        class="search-operator-dropdown mr-3 pt-1"
                    />

                    <div class="pr-3 value-input-container">
                        <AppInputV3
                            id="search-value"
                            v-model="searchValue"
                            :placeholder="selectedSearchOption.placeholder"
                            class="id-field"
                        />
                    </div>
                    <div class="search-button-wrapper">
                        <AppButton
                            :buttonType="BUTTON_TYPES.PRIMARY"
                            label="Search"
                            :iconType="ICON_TYPES.SEARCH"
                            @click="onSearch"
                        />
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
// Store
import { mapState } from 'vuex';
import { Modules } from '@/store/store';

// Vue components
import AppHeader from '@/components/layout/AppHeader.vue';
import AppInputV3 from '@/components/partials/inputs/AppInputV3.vue';
import AppMultiselectV3 from '@/components/partials/inputs/AppMultiselectV3.vue';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import { ICON_TYPES } from '@/common/iconHelper';
import {
    getTenantUMSearchOptions,
    getOperatorConfigValue,
    getIsEmailCaseSensitive,
} from '@/services/permissions/permissions.service';
import SearchBanner from '@/components/partials/SearchBanner.vue';

// Router related files
import RouteNames from '@/router/routeNames';

// Helpers and libraries
import userInfoHelper, {
    SEARCH_QUERY_OPTION,
    SEARCH_QUERY_FILTER_OPERATOR,
    SEARCH_QUERY_FILTER_OPERATOR_LABEL_MAP,
} from '@/__new__/features/customerCare/common/userInfoHelper';
import { ALERT_TYPES } from '@/common/alerts/Alert';
import luaErrorCodes, { modules } from '@/common/luaErrors';

// 3rd party libs
import * as Sentry from '@sentry/vue';

export default {
    name: 'UserManagement',

    components: {
        AppHeader,
        AppButton,
        AppMultiselectV3,
        AppInputV3,
        SearchBanner,
    },

    props: {
        useUsersSearchOutsideUm: {
            type: Boolean,
            default: false,
        },
        customPageTitle: {
            type: String,
            default: '',
        },
        searchBy: {
            type: Array,
            default: () => [],
        },
    },

    data() {
        return {
            RouteNames,
            ICON_TYPES,
            BUTTON_TYPES,
            searchValue: '',
            selectedSearchOption: '',
            selectedSearchOperator: '',
            isCaseSensitiveSearchEnabled: getIsEmailCaseSensitive(),
        };
    },

    computed: {
        ...mapState(Modules.config, ['preferences']),
        searchOptions() {
            const searchOptions = getTenantUMSearchOptions();
            return this.useUsersSearchOutsideUm && this.searchBy.length
                ? Object.keys(searchOptions).reduce((data, current) => {
                      if (this.searchBy.includes(searchOptions[current].key)) {
                          data[current] = searchOptions[current];
                      }
                      return data;
                  }, {})
                : searchOptions;
        },
        searchOperations() {
            return Object.values(SEARCH_QUERY_FILTER_OPERATOR)
                .filter(key =>
                    [SEARCH_QUERY_FILTER_OPERATOR.MATCH, SEARCH_QUERY_FILTER_OPERATOR.STARTS_WITH].includes(key),
                )
                .map(key => ({
                    key,
                    label: SEARCH_QUERY_FILTER_OPERATOR_LABEL_MAP.get(key),
                    $isDisabled: !(this.selectedSearchOption?.supportedOperators || []).includes(key),
                }));
        },
        isViasatDemo() {
            return getOperatorConfigValue('isViasatDemo');
        },
        viasatCustomerSignUpUrl() {
            return getOperatorConfigValue('viasatCustomerSignUpUrl');
        },
        pageTitle() {
            return this.customPageTitle ? this.customPageTitle : this.$i18n.t('customerCare.userManager');
        },
    },

    mounted() {
        this.setSelectedSearchOption();
    },

    methods: {
        async onSearch() {
            const disableValidator =
                this.selectedSearchOption.key === SEARCH_QUERY_OPTION.MAC_ADDRESS &&
                this.selectedSearchOperator.key === SEARCH_QUERY_FILTER_OPERATOR.STARTS_WITH;
            if (
                this.selectedSearchOption &&
                typeof this.selectedSearchOption?.validator === 'function' &&
                this.selectedSearchOption.validator(this.searchValue, disableValidator)
            ) {
                this.$Progress.start();

                let searchIdValue;

                if (this.selectedSearchOption.key === this.searchOptions.Email?.key) {
                    searchIdValue = this.isCaseSensitiveSearchEnabled
                        ? this.searchValue
                        : this.searchValue.toLowerCase();
                } else {
                    searchIdValue = this.searchValue;
                }

                const searchParams = {
                    queryOption: this.selectedSearchOption.key,
                    queryOperator: this.selectedSearchOperator.key,
                    id: searchIdValue,
                };

                try {
                    const response = await userInfoHelper.searchAndLoadAccount(
                        searchParams.queryOption,
                        searchParams.id,
                        searchParams.queryOperator,
                    );
                    this.$Progress.finish();

                    if (this.useUsersSearchOutsideUm) {
                        this.$emit('onUserSearch', {
                            searchValue: this.searchValue,
                            selectedSearchOption: this.selectedSearchOption.key,
                        });
                    } else {
                        const path = userInfoHelper.getSearchQueryUrlPath(
                            searchParams.queryOption,
                            this.$route.params.companyId,
                            response,
                        );

                        this.$router.push({ path });
                    }
                } catch (error) {
                    const { module: moduleName, code } = { ...error.response?.data };

                    this.$Progress.fail();
                    if (
                        moduleName === modules.USER &&
                        (code === luaErrorCodes.USER.USER_NOT_EXIST.code ||
                            code === luaErrorCodes.USER.ACCOUNT_NOT_FOUND.code ||
                            code === luaErrorCodes.USER.SUBSCRIBER_NOT_FOUND.code)
                    ) {
                        const searchQuery = userInfoHelper.getSearchQueryLabel(searchParams.queryOption);
                        const searchQueryEntity = userInfoHelper.getSearchQueryEntityType(searchParams.queryOption);
                        this.$alert(
                            this.$i18n.t('alertMessage.userManagement.entityNotFound', {
                                entityId: searchQuery,
                                entityType: searchQueryEntity,
                            }),
                        );
                    } else if (
                        moduleName === modules.COMMON &&
                        code === luaErrorCodes.COMMON.INVALID_MSISDN_FORMAT.code
                    ) {
                        this.$alert(this.$i18n.t('customerCare.errorAlertMessages.msisdnInvalidFormat'));
                    } else {
                        // capturing errors only here intentionaly since "user not found" is not really an issue
                        Sentry.captureException(error);
                        this.$alert(
                            this.$i18n.t('alertMessage.userManagement.somethingWentWrongFetchingUserProfileData'),
                        );
                    }
                }
            } else {
                this.$Progress.fail();
                this.$eventBus.$emit('showAlert', {
                    message: `${this.selectedSearchOption.errorMessage}.`,
                    type: ALERT_TYPES.warning,
                });
            }
        },
        setSelectedSearchOption() {
            try {
                const searchOptions = Object.values(getTenantUMSearchOptions());

                const { userSearchParam } = this.preferences;

                if (userSearchParam && searchOptions.some(el => el.key === userSearchParam)) {
                    this.selectedSearchOption = searchOptions.find(el => el.key === userSearchParam);
                }
            } catch (e) {
                Sentry.captureException(e);
                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('alertMessage.userManagement.somethingWentWrongFetchingUserProfileData'),
                });
            }
        },
        onOptionSelect(o) {
            if (o?.supportedOperators?.length === 1) {
                this.selectedSearchOperator = this.searchOperations.find(({ key }) => key === o.supportedOperators[0]);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/palette';
@import '~@/assets/scss/layout';
@import '~@/assets/scss/typographyV2';

.page-background {
    background-color: $white;
    height: 100%;
}

.content-container {
    width: 70%;
    margin: 0 15%;
}

.lf-all-caps {
    color: $gray-blue;
}

.search-options-dropdown,
.search-operator-dropdown {
    width: min-content;
    min-width: 4.75rem;
    white-space: nowrap;

    ::v-deep .multiselect__content-wrapper {
        min-width: 9rem;
    }
}

.value-input-container {
    width: 100%;
    max-width: 600px;
}

.search-button-wrapper {
    margin-left: auto;
}
</style>
