// from
// "b50a466b-8722-4aeb-9870-f9a6780bf717": {
//     "values": [
//         "123"
//     ],
//     "type": "special_number"
// }
// to
// {
//     "uuid": "b50a466b-8722-4aeb-9870-f9a6780bf717",
//     "name": "Special Numbers",
//     "data": [
//         "123"
//     ],
//     "type": "specialNumber"
// }
export default (id, pcObject) => ({
    type: 'special_number',
    name: 'Special Numbers',
    data: [...pcObject.values],
    uuid: id,
});
