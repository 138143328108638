import { render, staticRenderFns } from "./TariffSpecificationGroupEditor.vue?vue&type=template&id=2389329a&scoped=true"
import script from "./TariffSpecificationGroupEditor.vue?vue&type=script&lang=ts"
export * from "./TariffSpecificationGroupEditor.vue?vue&type=script&lang=ts"
import style0 from "./TariffSpecificationGroupEditor.vue?vue&type=style&index=0&id=2389329a&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2389329a",
  null
  
)

export default component.exports