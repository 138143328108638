/**
 * FeeRule related models to be used by Portals front end code
 */
import { Entity, EntityState } from '@/http';
import {
    ACTION_CONFIGURATION_TYPE,
    AMOUNT_CALCULATION_METHOD,
    FeeRule as DnoFeeRule,
    PRORATION_METHOD,
    PRORATION_UNIT,
    Scope,
    SCOPE_LEVEL,
} from '@/__new__/services/dno/pricing/models/pricingDno';

export interface FeeRuleEntity {
    id: string;
    name: string;
    description: string;
    type: ACTION_CONFIGURATION_TYPE;
    scope: SCOPE_LEVEL;
    offers: string[];
    prorationMethod: PRORATION_METHOD | null;
    amount: string;
    amountCalculationMethod: AMOUNT_CALCULATION_METHOD | null;
    prorationUnit: PRORATION_UNIT | null;
    state: EntityState;
    version: number;
}

// Note: Some of the fields below can be null, this is primarily because the backend
//       does not guarantee these fields (even though it's sensible information for Portal).
//       Keeping it optional so that our model is flexible.
export class FeeRule {
    #id: string;
    #amount: string;
    #amountCalculationMethod: AMOUNT_CALCULATION_METHOD | null;
    #name: string;
    #description: string;
    #offers: string[];
    #prorationMethod: PRORATION_METHOD | null;
    #prorationUnit: PRORATION_UNIT | null;
    #type: ACTION_CONFIGURATION_TYPE;
    #scope: SCOPE_LEVEL;
    #state: EntityState;
    #version: number;

    constructor(rawFeeRule: Entity<DnoFeeRule>) {
        this.#id = rawFeeRule.id;
        this.#name = rawFeeRule.data.name.en;
        this.#description = rawFeeRule.data.description.en;
        this.#type = rawFeeRule.data.actions[0].action_configuration_type;
        this.#scope = rawFeeRule.data.scope.level;
        this.#state = rawFeeRule.state;
        this.#version = rawFeeRule.version;

        // Determine offers
        const scope: Scope = rawFeeRule.data.scope;
        if (scope.level === SCOPE_LEVEL.LINE_ITEM_LEVEL) {
            this.#offers = scope.applicable_line_item_offer_ids;
        } else {
            this.#offers = [];
        }

        // Determine proration method, unit, amount, calc method
        if (rawFeeRule.data.actions[0].action_configuration_type !== ACTION_CONFIGURATION_TYPE.NATIVE) {
            const params = rawFeeRule.data.actions[0].predefined_rule_params;
            this.#prorationMethod = params.proration_method;
            this.#prorationUnit = params.proration_unit ?? null;
            this.#amount = params.amount;
            this.#amountCalculationMethod = params.amount_calculation_method;
        } else {
            this.#prorationMethod = null;
            this.#prorationUnit = null;
            this.#amount = '';
            this.#amountCalculationMethod = null;
        }
    }

    get amount(): string {
        return this.#amount;
    }

    get amountCalculationMethod(): AMOUNT_CALCULATION_METHOD | null {
        return this.#amountCalculationMethod;
    }

    get id(): string {
        return this.#id;
    }

    get name(): string {
        return this.#name;
    }

    get description(): string {
        return this.#description;
    }

    get offers(): string[] {
        return this.#offers;
    }

    get prorationMethod(): PRORATION_METHOD | null {
        return this.#prorationMethod;
    }

    get prorationUnit(): PRORATION_UNIT | null {
        return this.#prorationUnit;
    }

    get type(): ACTION_CONFIGURATION_TYPE {
        return this.#type;
    }

    get scope(): SCOPE_LEVEL {
        return this.#scope;
    }

    get state(): EntityState {
        return this.#state;
    }

    get version(): number {
        return this.#version;
    }
}
