
import StatusChecker, { StatusLabelAndColor } from '@/components/partials/StatusChecker.vue';
// http
import providerHTTP from '@/http/provider';
// helper
import { ONT_STATUS, ontStatusColorMap, ontStatusLabelMap } from '@/common/ontStateHelper';
import { isWholeNumber } from '@/common/formatting';
import { ALERT_TYPES } from '@/common/alerts/Alert';

export default {
    name: 'OntStatusChecker',
    components: {
        StatusChecker,
    },
    props: {
        id: {
            type: String,
            required: true,
        },
        idType: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            isLoading: false,
            status: { title: '', color: undefined } as StatusLabelAndColor,
        };
    },
    methods: {
        getStatus() {
            this.$withProgressBar(
                async () => {
                    this.isLoading = true;

                    const {
                        data: { operational_status: status },
                    } = await providerHTTP.osaGetServiceStatus(this.id, this.idType);

                    if (isWholeNumber(status)) {
                        this.mapStatus(status);
                    }

                    if (status === ONT_STATUS.IN_PROGRESS) {
                        this.$alert(this.$i18n.t('alertMessage.account.ontInProgress'), {
                            type: ALERT_TYPES.warning,
                        });
                    }
                },
                {
                    errorHandler: () =>
                        this.$showErrorAlert({
                            message: this.$i18n.t('alertMessage.userManagement.somethingWentWrongGettingOntStatus'),
                        }),
                },
            ).finally(() => {
                this.isLoading = false;
            });
        },
        mapStatus(status: ONT_STATUS) {
            this.status.title = ontStatusLabelMap.get(status)
                ? this.$i18n.t(ontStatusLabelMap.get(status))
                : this.$i18n.t('generic.stateMap.unknown');
            this.status.color = ontStatusColorMap.get(status);
        },
    },
};
