<template>
    <DuplicateSlot
        v-model="inputValues"
        :addButtonLabel="addButtonLabel"
        :enableDeleteButton="true"
        :optional="optional"
        :label="label"
        :disabled="disabled"
        deleteIconWithoutOffset
        class="w-100 kay-value-pairs"
    >
        <template slot-scope="{ slotNumber }">
            <div class="d-flex mb-2 row col-11">
                <AppInputV3
                    v-model.trim="inputValues[slotNumber].key"
                    :placeholder="firstInputPlaceholder"
                    :invalid="isInvalid(slotNumber)"
                    :type="types"
                    :min="minimums"
                    :max="maximums"
                    :class="{ width: setWidth }"
                    :disablePadding="true"
                    :disabled="disabled"
                    class="col-12 col-md-6"
                />

                <AppInputV3
                    v-model="inputValues[slotNumber].value"
                    :placeholder="secondInputPlaceholder"
                    :invalid="showErrors && invalidIndices.includes(slotNumber)"
                    :type="types"
                    :min="minimums"
                    :max="maximums"
                    :class="{ width: setWidth }"
                    :disablePadding="true"
                    :disabled="disabled"
                    class="col-12 col-md-6 mt-1 mt-md-0"
                />
            </div>
        </template>
    </DuplicateSlot>
</template>

<script>
import AppInputV3 from '@/components/partials/inputs/AppInputV3.vue';
import DuplicateSlot from '@/components/partials/DuplicateSlot.vue';
import { validateUniqValues } from '@/common/formatting';

export default {
    name: 'KeyValueInputs',

    components: {
        AppInputV3,
        DuplicateSlot,
    },

    props: {
        value: {
            type: Object,
            default: () => undefined,
        },
        initInputValues: {
            type: Array,
            default: () => [],
        },
        addButtonLabel: {
            type: String,
            default: '',
        },
        firstInputPlaceholder: {
            type: String,
            default: '',
        },
        secondInputPlaceholder: {
            type: String,
            default: '',
        },
        showInputErrors: {
            type: Boolean,
            default: false,
        },
        optional: {
            type: Boolean,
            default: false,
        },
        label: {
            type: String,
            default: '',
        },
        types: {
            type: String,
            default: 'text',
        },
        minimums: {
            type: Number,
            default: 0,
        },
        maximums: {
            type: Number,
            default: 100000000,
        },
        setWidth: {
            type: Boolean,
            default: false,
        },
        allowEmptyValues: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            inputValues: [
                {
                    key: '',
                    value: '',
                },
            ],
            invalidIndices: [],
            showErrors: this.showInputErrors,
        };
    },

    watch: {
        inputValues: {
            handler() {
                this.validateInputs();
            },
            deep: true,
        },
        initInputValues: {
            handler(newVal) {
                if (newVal && newVal.length) {
                    this.inputValues = newVal;
                }
            },
            immediate: true,
        },
        showInputErrors(newVal) {
            this.showErrors = newVal;
        },
    },
    methods: {
        isInvalid(slotNum) {
            return this.showErrors && this.invalidIndices.includes(slotNum);
        },
        validateInputs() {
            // In the case that we only have a single row where both the key and value are
            // falsy values, we consider it to be empty.
            //
            const lastRow = this.inputValues[this.inputValues.length - 1];
            const result = this.inputValues.length === 1 && !lastRow.key && !lastRow.value ? [] : this.inputValues;

            const invalidIndices = [];
            this.invalidIndices = validateUniqValues(result, invalidIndices, this.allowEmptyValues);

            // Validate that keys are valid identifiers. It's possible that an index will
            // show up twice in `this.invalidIndex`, but that should be ok.
            //
            result.forEach((kvp, index) => {
                if (!/^\w+$/.test(kvp.key)) {
                    this.invalidIndices.push(index);
                }
            });

            this.$emit('input', {
                data: result,
                isValid: this.invalidIndices.length === 0,
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.kay-value-pairs {
    max-width: 36.5rem;
}

.width {
    width: 100%;
}

.input-position {
    align-self: flex-end;
}
</style>
