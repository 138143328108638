import { type AxiosPromise } from 'axios';
import http, { type DnoResponseBase } from '@/http/index';
import type { DlqFileResponse } from '@/__new__/features/sinkConfigs/common/dlqHelper';

export interface GetDlqFilesResponse extends DnoResponseBase {
    data: DlqFileResponse[];
}

export interface SignedUrlDlqResponse extends DnoResponseBase {
    data: {
        signed_url: string;
        upload_id: string;
    };
}

export function getDlqFiles(): AxiosPromise<GetDlqFilesResponse> {
    return http({
        method: 'GET',
        url: '/v1pyper/dlq/getfiles',
        headers: {
            'Content-Type': 'application/json',
        },
    });
}

export function getDownloadUrl(fid: string): AxiosPromise<SignedUrlDlqResponse> {
    return http({
        method: 'GET',
        url: '/v1pyper/dlq/signedurl/download',
        headers: { 'Content-Type': 'application/json' },
        params: {
            file_entry_id: fid,
        },
    });
}

export function getUploadUrl(): AxiosPromise<SignedUrlDlqResponse> {
    return http({
        method: 'POST',
        url: '/v1pyper/dlq/signedurl/upload',
        headers: { 'Content-Type': 'application/json' },
    });
}

export function redriveDlq(fid: string): AxiosPromise<DnoResponseBase> {
    return http({
        method: 'POST',
        url: '/v1pyper/dlq/redrive',
        headers: { 'Content-Type': 'application/json' },
        params: {
            file_entry_id: fid,
        },
    });
}

export function replaceDlq(fid: string, uid: string): AxiosPromise<DnoResponseBase> {
    return http({
        method: 'POST',
        url: '/v1pyper/dlq/replace',
        headers: { 'Content-Type': 'application/json' },
        data: {
            upload_id: uid,
        },
        params: {
            file_entry_id: fid,
        },
    });
}

export default {
    getDlqFiles,
    getDownloadUrl,
    getUploadUrl,
    redriveDlq,
    replaceDlq,
};
