import i18n from '@/i18n';
import { TranslateResult } from 'vue-i18n';
import { STATUS_CODES, STATUS_INDICATOR_MAP } from '@/common/commonHelper';
import { LABEL_COLOR } from '@/common/labelsHelper';
import { getMultiLangFieldValueByLocale } from '@/common/entities/entityHelper';

// now we have different logic for mapping offers and rewards
// it would be good to have a reusable mapping for the  whole application
export const rewardStateMap = new Map([
    [STATUS_CODES.UNAPPROVED, i18n.t('generic.stateMap.unapproved')],
    [STATUS_CODES.APPROVED, i18n.t('generic.stateMap.approved')],
    [STATUS_CODES.PAUSED, i18n.t('generic.stateMap.paused')],
    [STATUS_CODES.DELETED, i18n.t('generic.stateMap.deleted')],
    [STATUS_CODES.PENDING, i18n.t('generic.stateMap.pending')],
    [STATUS_CODES.REJECTED, i18n.t('generic.stateMap.rejected')],
]);

export const getRewardStatus = (
    state: number | undefined,
    startTime: number,
    endTime?: TranslateResult | number,
): TranslateResult | undefined => {
    const now = Date.now();

    if (state === STATUS_CODES.APPROVED) {
        const isExpired = !(endTime === i18n.t('generic.N/A') || (typeof endTime === 'number' && now < endTime));
        if (now > startTime && !isExpired) {
            return i18n.t('generic.statusMap.running');
        }
        if (now < startTime) {
            return i18n.t('generic.statusMap.upcoming');
        }
        if (isExpired) {
            return i18n.t('generic.statusMap.finished');
        }
    }

    if (state != null && rewardStateMap.has(state)) {
        return rewardStateMap.get(state);
    }

    return i18n.t('generic.N/A');
};

export const getRewardStatusColor = (
    state: number | undefined,
    startTime: number,
    endTime?: TranslateResult | number,
): string | undefined => {
    const now = Date.now();

    if (state === STATUS_CODES.APPROVED) {
        const isExpired = !(endTime === i18n.t('generic.N/A') || (typeof endTime === 'number' && now < endTime));
        if (now > startTime && !isExpired) {
            return LABEL_COLOR.green;
        }
        if (now < startTime) {
            return LABEL_COLOR.blue;
        }
        if (isExpired) {
            return LABEL_COLOR.gray;
        }
    }

    if (state != null && STATUS_INDICATOR_MAP.has(state)) {
        return STATUS_INDICATOR_MAP.get(state);
    }

    // fallback to default.
    return;
};

export enum payoutLimitDuration {
    DAYS = 1,
    WEEKS = 2,
    MONTHS = 3,
    YEARS = 5,
}

export enum rewardReceiver {
    USER = 0,
    REFERRER = 1,
}

export const compareConditions = function (a: any, b: any): boolean {
    const deepCompareTables = function (a: any, b: any): boolean {
        const keysA = Object.keys(a);
        const keysB = Object.keys(b);
        if (keysA.length !== keysB.length) {
            return false;
        }
        for (const key in a) {
            if (!compareConditions(b[key], a[key])) {
                return false;
            }
        }
        return true;
    };

    const deepCompareArrays = function (a: any[], b: any[]): boolean {
        if (a.length !== b.length) {
            return false;
        }
        const aClone = [...a];
        const bClone = [...b];
        while (aClone.length > 0) {
            const aValue = aClone[0];
            let match = false;
            let i = 0;
            for (const bValue of bClone) {
                i++;
                match = compareConditions(bValue, aValue);
                if (match) {
                    break;
                }
            }
            if (match) {
                bClone.splice(i - 1, 1);
                aClone.splice(0, 1);
            } else {
                return false;
            }
        }
        return true;
    };

    if (typeof a === 'object' && a !== null) {
        if (typeof b !== 'object' || b === null) {
            return false;
        }
        if (Array.isArray(a)) {
            if (!Array.isArray(b)) {
                return false;
            }
            return deepCompareArrays(a, b);
        } else {
            return deepCompareTables(a, b);
        }
    } else {
        if (typeof a !== typeof b) {
            return false;
        }
        return a === b;
    }
};

export function getDuplicatesMessage(
    id: string,
    condition: object,
    isClone: boolean,
    allEntities: any[],
): TranslateResult | undefined {
    const duplicates = allEntities.filter(
        r => compareConditions(r.data.condition, condition) && (isClone || r.id !== id),
    );
    let message = undefined;
    if (duplicates.length > 0) {
        message = i18n.t('alerts.conditionSameWithRunning');
        for (let i = 0; i < duplicates.length; i += 1) {
            message += ` \"${getMultiLangFieldValueByLocale(duplicates[i].data.name)}\"`;
            if (i < duplicates.length - 1) {
                message += ',';
            }
        }
    }

    return message;
}
