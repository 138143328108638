
import Vue from 'vue';

// Components
import AssociatedUMEntityPicker from '@/__new__/features/customerCareSuite/components/AssociatedUMEntityPicker.vue';
import AppProgressBar from '@/components/partials/AppProgressBar.vue';
import AppButton from '@/components/partials/inputs/AppButton.vue';
import AppAditionalSidebar from '@/components/partials/AppAditionalSidebar.vue';

// Helpers
import keyBy from 'lodash/keyBy';
import { USER_MANAGER_HIERARCHY } from '@/__new__/features/customerCare/common/customerCareHelper';
import { BUCKET_TYPE, mapBuckets } from '@/__new__/features/customerCare/common/balanceHelper';
import { getStateAsString } from '@/__new__/services/dno/charging/common/chargingSpecificationHelper';
import { CHARGING_SPECIFICATION_OPTIONS } from '@/common/chargingSpecification';
import { DATA_DEFINITIONS, convertSecondsToMinutes, getScaledAmount } from '@/common/formatting';

// HTTP
import ordersHTTP from '@/__new__/services/dno/orders/http/orders';
import { getChargingSpecifications } from '@/__new__/services/dno/charging/http/chargingSpecifications';

export default Vue.extend({
    name: 'ServiceBalanceTile',
    components: {
        AssociatedUMEntityPicker,
        AppProgressBar,
        AppButton,
        AppAditionalSidebar,
    },
    data() {
        return {
            selectedAssociatedAccount: null,
            balances: {},
            chargingSpecifications: {},
            showSidebar: false,

            // proxy
            USER_MANAGER_HIERARCHY,
            CHARGING_SPECIFICATION_OPTIONS,
        };
    },
    computed: {
        balancesFormatted() {
            if (this.balances?.buckets) {
                const allBalances = mapBuckets(this.balances.buckets, this.chargingSpecifications);
                const nonMonetaryBalances = this.getHierarchyForTable(
                    Object.values(allBalances[BUCKET_TYPE.NON_MONETARY]),
                );
                return nonMonetaryBalances.map(el => {
                    if (el.isParent) {
                        return {
                            ...el,
                            ...this.getRemappedBalances(el),
                        };
                    }
                    return {
                        ...el,
                        stateFormatted: el.is_active ? getStateAsString(el.is_active) : this.$i18n.t('generic.empty'),
                        ...this.getRemappedBalances(el),
                    };
                });
            }
            return [];
        },
        expandButtonLabel() {
            return this.$i18n.t(!this.showSidebar ? 'generic.seeMore' : 'generic.seeLess');
        },
    },
    watch: {
        selectedAssociatedAccount: {
            handler(newVal) {
                if (newVal) {
                    this.fetchTileData();
                }
            },
            immediate: true,
            deep: true,
        },
    },
    created() {
        if (this.selectedAssociatedAccount) {
            this.fetchTileData();
        } else {
            this.$emit('isDataLoadingUpd', false);
        }
    },
    methods: {
        fetchTileData() {
            this.$emit('isDataLoadingUpd', true);
            this.$withProgressBar(
                async () => {
                    this.balances = await ordersHTTP.getBalances(
                        this.selectedAssociatedAccount.id,
                        USER_MANAGER_HIERARCHY.SUBSCRIBER,
                    );

                    const response = await getChargingSpecifications();

                    const { charging_spec_by_id: chargingSpecById } = response.data;
                    const chargingSpecificationsTemp =
                        Object.values(chargingSpecById).map(el => ({
                            ...el,
                            chargingVersion: el.charging_version,
                            entityVersion: null,
                        })) || {};
                    this.chargingSpecifications = Object.values(keyBy([...chargingSpecificationsTemp], 'id'));

                    this.$emit('isDataLoadingUpd', false);
                },
                {
                    errorHandler: () => {
                        this.$emit('dataFetchingError');
                        this.$emit('isDataLoadingUpd', false);
                    },
                },
            );
        },
        getHierarchyForTable(values) {
            return values.reduce((acc, val) => {
                if (val[0].product_name) {
                    acc.push({
                        name: val[0].product_name,
                        isParent: true,
                    });
                }
                acc.push(...val);

                return acc;
            }, []);
        },
        scaleBytes(bytes) {
            return getScaledAmount(DATA_DEFINITIONS[1], bytes);
        },
        getStateAsString,
        convertSecondsToMinutes,
        getRemappedBalances(item) {
            switch (item.serviceTypeFirst) {
                case CHARGING_SPECIFICATION_OPTIONS.Voice.key:
                    return {
                        currentValue: this.convertSecondsToMinutes(item.amount_used),
                        maximalValue: this.convertSecondsToMinutes(item.amount_initial),
                        tooltipText: `${item.amount_available} ${this.$i18n.t('generic.seconds')}`,
                        color: '#335195',
                        units: this.$i18n.t('generic.mins'),
                        label: this.$i18n.t(CHARGING_SPECIFICATION_OPTIONS.Voice.i18nLabel),
                    };
                case CHARGING_SPECIFICATION_OPTIONS.Data.key:
                    return {
                        currentValue: this.scaleBytes(item.amount_used),
                        maximalValue: this.scaleBytes(item.amount_initial),
                        tooltipText: `${item.amount_available} ${this.$i18n.t('generic.bytes').toLowerCase()}`,
                        color: '#37c9aa',
                        units: this.$i18n.t('generic.mb'),
                        label: this.$i18n.t(CHARGING_SPECIFICATION_OPTIONS.Data.i18nLabel),
                    };
                case CHARGING_SPECIFICATION_OPTIONS.SMS.key:
                    return {
                        currentValue: item.amount_used,
                        maximalValue: item.amount_initial,
                        tooltipText: `${item.amount_available} ${this.$i18n.t('generic.smss')}`,
                        color: '#ffad20',
                        units: this.$i18n.t('generic.smss'),
                        label: this.$i18n.t(CHARGING_SPECIFICATION_OPTIONS.SMS.i18nLabel),
                    };
                case CHARGING_SPECIFICATION_OPTIONS.MMS.key:
                    return {
                        currentValue: item.amount_used,
                        maximalValue: item.amount_initial,
                        tooltipText: `${item.amount_available} ${this.$i18n.t('generic.mmss')}`,
                        color: '#ffad20',
                        units: this.$i18n.t('generic.mmss'),
                        label: this.$i18n.t(CHARGING_SPECIFICATION_OPTIONS.MMS.i18nLabel),
                    };
                case CHARGING_SPECIFICATION_OPTIONS.API.key:
                    return {
                        currentValue: item.amount_used,
                        maximalValue: item.amount_initial,
                        tooltipText: `${item.amount_available} ${this.$i18n.t('generic.apis')}`,
                        color: '#ffad20',
                        units: this.$i18n.t('generic.apis'),
                        label: this.$i18n.t(CHARGING_SPECIFICATION_OPTIONS.API.i18nLabel),
                    };
                default:
                    return {};
            }
        },
    },
});
