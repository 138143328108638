<script>
// helpers
import * as Sentry from '@sentry/vue';
import Button from '@/common/button/Button';
import { ENTITIES_TYPES_MAP } from '@/__new__/features/pc/common/entitiesTypes';
import { ALERT_TYPES } from '@/common/alerts/Alert';

export default {
    name: 'ListPageMixin',
    data() {
        return {
            searchQueryForTable: '',
            deleteConfirmationButton: new Button({
                label: this.$i18n.t('generic.delete'),
                alertType: ALERT_TYPES.warning,
            }),
        };
    },
    methods: {
        setSearchQuery(query) {
            this.searchQueryForTable = query;
        },
        onAddEntityClick() {
            this.$router.push({
                name: this.editRouteName,
                params: { entityType: this.entityType, companyId: this.$route.params.companyId },
            });
        },
        goToEditPage(entityId, isClone) {
            this.$router.push({
                name: this.editRouteName,
                params: {
                    entityType: this.entityType,
                    id: entityId,
                    clone: isClone,
                    companyId: this.$route.params.companyId,
                },
            });
        },
        confirmEntityDeletion(entity) {
            this.$eventBus.$emit('showAlert', {
                message: this.$i18n.t('alerts.areYouSureDeleteEntity', {
                    entityName: entity.name,
                }),
                type: ALERT_TYPES.warning,
                buttons: [this.deleteConfirmationButton],
            });
            this.$eventBus.$once('buttonClicked', id => {
                if (id === this.deleteConfirmationButton.id) {
                    this.deleteEntity(entity);
                }
            });
        },
        handleEntityDeletionError(error, spec) {
            Sentry.captureException(error);
            this.$Progress.fail();
            if (error.response.data.code === 4) {
                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('alertMessage.pcv4.referencedBy', {
                        entityName: this.$i18n.tc(ENTITIES_TYPES_MAP[this.entityType]).toLowerCase(),
                    }),
                });
            } else {
                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('alertMessage.errorDoingSmthTryAgain', {
                        action: this.$i18n.t('generic.deleting'),
                        entityName: spec.name,
                    }),
                });
            }
        },
    },
};
</script>
