import NcsBaseCondition from '@/__new__/services/dno/charging/models/chargingSpecificationConditions/NCSbaseCondition';

export default class OtherPartyNumberLengthCondition extends NcsBaseCondition {
    constructor({ data, uuid, type, dataEmpty = true, dataValid = false }) {
        super();
        this.data = data;
        this.type = type;
        this.uuid = uuid;
        this.dataEmpty = dataEmpty;
        this.dataValid = dataValid;
    }

    validationCondition() {
        return Boolean(this.data.operator && this.data.value);
    }

    isDataValid() {
        this.dataValid = this.validationCondition();
    }
}
