import luaErrors from '@/common/luaErrors';
import i18n from '@/i18n';
import { TranslateResult } from 'vue-i18n';

import { snakeCaseToCommonString, textToLowerCase } from '@/common/formatting';

export enum PAYMENT_TRANSACTION_TYPE {
    RESERVE = 1, // Credit card *will* be charged, but has not been charged yet.
    COMMIT = 2, // Credit card *has* been charged (preceeded by a RESERVE transaction)
    REFUND = 3, // Funds are being returned
    DIRECT_DEBIT = 4, // Funds are being taken directly (NOT preceeded by a COMMIT transaction)
}

export function paymentTransactionTypeToString(type: PAYMENT_TRANSACTION_TYPE): string {
    switch (type) {
        case PAYMENT_TRANSACTION_TYPE.RESERVE:
            return i18n.t('billing.reserve').toString();
        case PAYMENT_TRANSACTION_TYPE.COMMIT:
            return i18n.t('billing.commit').toString();
        case PAYMENT_TRANSACTION_TYPE.REFUND:
            return i18n.t('billing.refund').toString();
        case PAYMENT_TRANSACTION_TYPE.DIRECT_DEBIT:
            return i18n.t('billing.directDebit').toString();
        default:
            return i18n.t('generic.error').toString();
    }
}

export function getResponseMessageFromModuleCode(moduleName: string, code: number): string | TranslateResult {
    const [errorName] =
        Object.entries(luaErrors[moduleName] || {}).find(([, errorData]) => errorData.code === code) || [];

    return errorName ? snakeCaseToCommonString(textToLowerCase(errorName)) : i18n.t('generic.unknown');
}

export default {};
