<script>
import { filterEntities } from '@/common/filterTable';

export default {
    name: 'FilterTableMixin',
    data() {
        return {
            filterTableMixin: {
                allFilters: [],
            },
        };
    },
    methods: {
        onFilterAdded(filter) {
            this.filterTableMixin.allFilters.push(filter);
        },
        removeFilter(index) {
            this.filterTableMixin.allFilters.splice(index, 1);
        },
        removeAllFilters() {
            this.filterTableMixin.allFilters = [];
        },
        filteredEntitiesMixin(entities) {
            return filterEntities(entities, this.filterTableMixin.allFilters);
        },
        computedFilterValue(filter) {
            return filter.condition.getDisplayValue
                ? filter.condition.getDisplayValue(filter.values)
                : filter.values.value;
        },
        computedFilterLabel(filter) {
            return [filter.column.name, filter.condition.label, this.computedFilterValue(filter)].join(' ');
        },
        columnsForFiltering() {
            return this.tableColumnsData.filter(
                column => !Object.prototype.hasOwnProperty.call(column, 'notForFiltering'),
            );
        },
    },
};
</script>
