import Vue from 'vue';
import Actions, { State, Getters, Mutations } from '../mutation-types';
import ordersHTTP from '@/__new__/services/dno/orders/http/orders';
import ENTITY_TYPES from '@/common/entities/entityTypes';
import { CHARGED_TO, WALLET_TYPES, remapOrdersFromBe } from '@/__new__/features/customerCare/common/ordersHelper';
import { remapDeliveriesFromBe } from '@/__new__/features/customerCare/common/deliveriesHelper';
import { USER_MANAGER_HIERARCHY } from '@/__new__/features/customerCare/common/customerCareHelper';
import permissionsService, { isViewEnabled } from '@/services/permissions/permissions.service';

export default {
    namespaced: true,
    state: {
        [State.ORDERS]: {},
        [State.ORDERS_API_RESPONSE]: {},
        [State.DELIVERIES]: {},
        [State.DELIVERIES_API_RESPONSE]: {},
    },
    mutations: {
        [Mutations.SET_ORDERS]: (state, allOrders) => {
            Vue.set(state, State.ORDERS, allOrders);
        },
        [Mutations.SET_ORDERS_API_RESPONSE]: (state, apiResponse) => {
            Vue.set(state, State.ORDERS_API_RESPONSE, apiResponse);
        },
        [Mutations.SET_DELIVERIES]: (state, deliveries) => {
            Vue.set(state, State.DELIVERIES, deliveries);
        },
        [Mutations.SET_DELIVERIES_API_RESPONSE]: (state, apiResponse) => {
            Vue.set(state, State.DELIVERIES_API_RESPONSE, apiResponse);
        },
    },
    actions: {
        async [Actions.LOAD_ALL_ORDERS]({ commit }, subscriberId) {
            try {
                const response = await ordersHTTP.oeGetOrders({
                    accountId: subscriberId,
                    entityType: [ENTITY_TYPES.OFFER],
                    targetType: USER_MANAGER_HIERARCHY.SUBSCRIBER,
                });
                commit(Mutations.SET_ORDERS, remapOrdersFromBe(response?.data?.orders) || {});
                commit(Mutations.SET_ORDERS_API_RESPONSE, response);
            } catch (error) {
                commit(Mutations.SET_ORDERS_API_RESPONSE, error.response);
            }
        },
        async [Actions.LOAD_ALL_OEORDERS_ACCOUNT]({ commit }, accountId) {
            try {
                const subordersDisabled =
                    permissionsService.accountOrdersSubordersDisabled() &&
                    isViewEnabled('UMAccountOrderDetailsSubordersDisabled');
                const response = await ordersHTTP.oeGetOrders({
                    targetId: accountId,
                    entityType: subordersDisabled ? [ENTITY_TYPES.OFFER, ENTITY_TYPES.PRODUCT] : [ENTITY_TYPES.OFFER],
                    targetType: USER_MANAGER_HIERARCHY.ACCOUNT,
                    subordersDisabled,
                });
                commit(Mutations.SET_ORDERS, remapOrdersFromBe(response?.data?.orders) || {});
                commit(Mutations.SET_ORDERS_API_RESPONSE, response);
            } catch (error) {
                commit(Mutations.SET_ORDERS_API_RESPONSE, error.response);
            }
        },
        async [Actions.LOAD_ALL_OMDDEVEDGEORDERS_ACCOUNT]({ commit }, accountId) {
            try {
                const response = await ordersHTTP.getOrdersOmDevEdge(accountId);
                commit(Mutations.SET_ORDERS, remapOrdersFromBe(response?.data?.orders));
                commit(Mutations.SET_ORDERS_API_RESPONSE, response);
            } catch (error) {
                commit(Mutations.SET_ORDERS_API_RESPONSE, error.response);
            }
        },
        async [Actions.REPLACE_ORDER]({ dispatch }, data) {
            await ordersHTTP.replaceOrder({
                targetId: data.accountId,
                offerId: data.offerId,
                entityType: ENTITY_TYPES.OFFER,
                changeType: data.changeType,
            });
            await dispatch(Actions.LOAD_ALL_OEORDERS_ACCOUNT, data.accountId);
        },
        async [Actions.CANCEL_REPLACE_ORDER]({ dispatch }, data) {
            await ordersHTTP.cancelReplaceOrder({
                accountId: data.accountId,
            });
            await dispatch(Actions.LOAD_ALL_OEORDERS_ACCOUNT, data.accountId);
        },
        async [Actions.GET_DELIVERIES]({ commit }, accountId) {
            try {
                const response = await ordersHTTP.getDeliveryDetails({
                    targetId: accountId,
                    targetType: USER_MANAGER_HIERARCHY.ACCOUNT,
                });
                commit(Mutations.SET_DELIVERIES, remapDeliveriesFromBe(response.data?.data));
                commit(Mutations.SET_DELIVERIES_API_RESPONSE, response);
            } catch (error) {
                commit(Mutations.SET_DELIVERIES_API_RESPONSE, error.response);
            }
        },
        [Actions.RESET_DELIVERIES]({ commit }) {
            commit(Mutations.SET_DELIVERIES, {});
            commit(Mutations.SET_DELIVERIES_API_RESPONSE, {});
        },
        [Actions.RESET_ORDERS]({ commit }) {
            commit(Mutations.SET_ORDERS, {});
            commit(Mutations.SET_ORDERS_API_RESPONSE, {});
        },
    },
    getters: {
        [Getters.GET_PRIMARY_ORDERS]: state =>
            Object.entries(state[State.ORDERS]).reduce((acc, [key, val]) => {
                if (
                    val.chargedTo === CHARGED_TO.BILL ||
                    val.chargedTo === CHARGED_TO.EXTERNAL ||
                    val.walletType === WALLET_TYPES.PRIMARY
                ) {
                    acc[key] = val;
                }
                return acc;
            }, {}),
        [Getters.GET_ORDERS]: state => state[State.ORDERS],
        [Getters.GET_ORDERS_API_RESPONSE]: state => state[State.ORDERS_API_RESPONSE],
        [Getters.GET_REDEMPTION_HISTORY]: state =>
            Object.entries(state[State.ORDERS]).reduce((acc, [key, val]) => {
                if (val.walletType === WALLET_TYPES.COINS || val.walletType === WALLET_TYPES.SPINS) {
                    acc[key] = val;
                }
                return acc;
            }, {}),
        [Getters.GET_DELIVERIES]: state => state[State.DELIVERIES],
        [Getters.GET_DELIVERIES_API_RESPONSE]: state => state[State.DELIVERIES_API_RESPONSE],
    },
};
