
import { USER_MANAGER_HIERARCHY } from '@/__new__/features/customerCare/common/customerCareHelper';
import AbstractActionDialogWithAgentNote from './AbstractActionDialogWithAgentNote.vue';
import ossmvneHTTP from '@/__new__/services/dno/ossmvne/http/ossmvne';

export default {
    name: 'CancelDeviceLocationModal',
    components: {
        AbstractActionDialogWithAgentNote,
    },
    props: {
        value: {
            required: true,
            type: Boolean,
        },
        subscriberId: {
            default: '',
            type: String,
        },
        msisdn: {
            default: '',
            type: String,
        },
    },
    methods: {
        cancelDeviceLocation(successHandler?: () => void): Promise<void> {
            return this.$withProgressBar(
                async () => {
                    await ossmvneHTTP.cancelDeviceLocation(
                        this.subscriberId,
                        USER_MANAGER_HIERARCHY.SUBSCRIBER,
                        this.msisdn,
                    );
                    await this.$showSuccessAlert({
                        message: this.$i18n.t('customerCare.cancelDeviceLocation.alertSuccess'),
                    });
                },
                {
                    successHandler,
                    errorHandler: () => {
                        this.$alert(this.$t('customerCare.cancelDeviceLocation.alertsFailed'));
                    },
                },
            );
        },
    },
};
