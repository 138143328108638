import axios from '@/http/index';

export function getRoles() {
    const options = {
        method: 'GET',
        url: '/admin/role',
    };
    return axios(options);
}

export function addRole(data) {
    return axios({
        method: 'POST',
        url: '/admin/role',
        data,
    });
}

export function updateRole(data) {
    return axios({
        method: 'PUT',
        url: '/admin/role',
        data,
    });
}

export function deleteRole(roleId) {
    return axios({
        method: 'DELETE',
        url: '/admin/role',
        data: {
            roleId,
        },
    });
}

export default {
    getRoles,
};
