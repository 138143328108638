import VueI18n from 'vue-i18n';

export enum DEVICE_INFO_TYPE {
    WIFI_EXTENDER = 'wifi_extender',
    ROUTER = 'router',
    ONT = 'ont',
}

export const deviceInfoTypeMap = new Map<DEVICE_INFO_TYPE, VueI18n.Path>([
    [DEVICE_INFO_TYPE.ONT, 'generic.deviceInfoTypeMap.ont'],
    [DEVICE_INFO_TYPE.ROUTER, 'generic.deviceInfoTypeMap.router'],
    [DEVICE_INFO_TYPE.WIFI_EXTENDER, 'generic.deviceInfoTypeMap.wifiExtender'],
]);

export const getDeviceInfoTypeLabel = (source: DEVICE_INFO_TYPE) =>
    (deviceInfoTypeMap.has(source) ? deviceInfoTypeMap.get(source) : 'generic.N/A') as VueI18n.Path;
