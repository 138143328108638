<template>
    <AppDialog
        :visible="value"
        maxWidth="1008px"
        maxHeight="calc(100vh - 120px)"
        @input="emitValue"
    >
        <div>
            <div class="custom-content">
                <div class="custom-header d-flex justify-content-between align-items-center">
                    <div class="title">
                        {{ title }}
                    </div>
                    <div class="d-flex flex-column-reverse">
                        <slot name="moreAditionalBtns" />
                        <div
                            v-if="hasAdditionalBtn"
                            class="additional-btn ml-3"
                            :class="{ 'additional-btn-disabled': additionalBtnDisabled }"
                            @click="additionalBtnClicked"
                        >
                            {{ additionalBtnLabel }}
                        </div>
                    </div>
                </div>

                <div class="custom-info-wrap">
                    <div class="custom-info d-flex">
                        <div class="img-wrap">
                            <img
                                :src="photoSrc"
                                alt="Avatar image"
                            />

                            <div
                                v-if="canUploadPhoto"
                                class="add-photo-btn"
                                @click="showFileUploader"
                            />
                        </div>

                        <div>
                            <div class="id-title">
                                {{ titleForIdLabel }}
                            </div>
                            <div class="id">
                                {{ id }}
                            </div>
                        </div>
                    </div>

                    <FileUploader
                        v-show="canUploadPhoto && isFileUploaderVisible"
                        acceptType="image/*"
                        :multiple="false"
                        class="file-uploader"
                        @updateFileList="e => $emit('updateAvatarImg', e)"
                    />
                </div>

                <slot />
            </div>

            <div class="custom-footer d-flex justify-content-end">
                <AppButton
                    :buttonType="BUTTON_TYPES.SECONDARY"
                    :label="$i18n.t('generic.cancel')"
                    class="cancel-btn mr-3"
                    @click="() => $emit('cancel')"
                />
                <AppButton
                    :buttonType="BUTTON_TYPES.PRIMARY"
                    :label="$i18n.t('generic.save')"
                    :iconType="ICON_TYPES.CHECK"
                    class="save-btn"
                    @click="() => $emit('save')"
                />
            </div>
        </div>
    </AppDialog>
</template>

<script>
// COMPONENTS
import AppDialog from '@/components/partials/AppDialog.vue';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import { ICON_TYPES } from '@/common/iconHelper';
import FileUploader from '@/components/partials/inputs/FileUploader.vue';

// HELPERS
import defaultImage from '@/assets/images/empty-customer-img.svg';

export default {
    name: 'AppCustomDialog',
    components: {
        AppDialog,
        AppButton,
        FileUploader,
    },
    props: {
        value: {
            type: Boolean,
            required: true,
        },
        title: {
            type: String,
            default: '',
        },
        hasAdditionalBtn: {
            type: Boolean,
            default: false,
        },
        additionalBtnDisabled: {
            type: Boolean,
            default: false,
        },
        additionalBtnLabel: {
            type: String,
            default: '',
        },
        photo: {
            type: String,
            default: 'default',
        },
        canUploadPhoto: {
            type: Boolean,
            default: false,
        },
        titleForIdLabel: {
            type: String,
            default: '',
        },
        id: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            ICON_TYPES,
            BUTTON_TYPES,
            isFileUploaderVisible: false,
        };
    },
    computed: {
        photoSrc() {
            return this.photo === 'default' ? defaultImage : this.photo;
        },
    },
    methods: {
        emitValue(val) {
            this.$emit('input', val);
        },
        showFileUploader() {
            this.isFileUploaderVisible = true;
        },
        additionalBtnClicked() {
            if (!this.additionalBtnDisabled) {
                this.$emit('clickAdditionalBtn');
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/palette';
@import '~@/assets/scss/icons';

$icon-path: '~@/assets/icons/';

.custom-content {
    padding: 2rem 9.5rem 3.75rem;
    border-bottom: solid 0.0625rem rgba($color: $blue, $alpha: 0.15);
}

.custom-footer {
    padding: 1.5rem 9.5rem;
}

.title {
    font-size: 1.375rem;
    font-weight: 600;
    color: $gray90;
}

.custom-header {
    margin-bottom: 3.375rem;
}

.additional-btn {
    font-size: 0.875rem;
    font-weight: 600;
    line-height: 1.86;
    color: $red;
    cursor: pointer;

    &.additional-btn-disabled {
        color: $gray60;
        cursor: auto;
    }
}

.img-wrap {
    width: 5rem;
    height: 5rem;
    margin-right: 1.25rem;
    position: relative;

    img {
        max-width: 100%;
        max-height: 100%;
    }
}

.add-photo-btn {
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    box-shadow: 0 0.25rem 0.5rem 0 rgba($color: $green, $alpha: 0.3);
    background-color: $white;
    position: absolute;
    bottom: 0;
    right: 0;
    cursor: pointer;

    &::after {
        content: url($icon-path + $plus-light);
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    &:hover {
        &::after {
            content: url($icon-path + $plus-light-hover);
        }
    }
}

.id-title {
    font-size: 0.75rem;
    font-weight: 600;
    line-height: 2.17;
    margin-bottom: 0.25rem;
    color: $gray90;
}

.id {
    font-size: 0.875rem;
    line-height: 1.5;
    color: $gray90;
    padding: 0.3125rem 1.3125rem 0.3125rem 0.5rem;
    border-radius: 0.5rem;
    border: solid 0.0625rem rgba(55, 201, 170, 0.15);
    background-color: rgba(55, 201, 170, 0.1);
}

.custom-info-wrap {
    margin-bottom: 2.3125rem;
}

.custom-info {
    margin-bottom: 2rem;
}
</style>
