<template>
    <AbstractEditPageWrapper>
        <template #header>
            <AppHeader :pageTitle="pageTitle" />
        </template>

        <template #content>
            <!-- General -->
            <div class="mb-3">
                <div class="d-flex align-items-center mb-3">
                    <div class="lf-subtitle mb-0">
                        {{ $i18n.t('generic.general') }}
                        <p
                            v-show="inEditMode && isRewardsDraftEnabled"
                            class="lf-secondary-text mb-0"
                        >
                            {{ $i18n.t('generic.id') }}: {{ $route.params.id }}
                        </p>
                        <p
                            v-show="inEditMode && isRewardsDraftEnabled"
                            class="lf-secondary-text mb-0"
                        >
                            {{ $i18n.t('generic.updateTime') }}:
                            {{ $localeLibrary.getFormattedDateAndTime(updateTime) }}
                        </p>
                        <p
                            v-show="inEditMode && isRewardsDraftEnabled"
                            class="lf-secondary-text mb-0"
                        >
                            {{ $i18n.t('generic.updateUser') }}: {{ updateName }}
                        </p>
                    </div>
                    <LanguageSwitcher
                        v-model="selectedLanguage"
                        :tooltipEnabled="true"
                    />
                    <AppLabel
                        v-if="isDraft"
                        :title="$i18n.t('generic.stateMap.draft')"
                        :color="LABEL_COLOR.gray"
                        class="justify-content-center state-label ml-auto"
                    />
                    <AppLabel
                        v-if="isPublished"
                        :title="$i18n.t('generic.stateMap.published')"
                        :color="LABEL_COLOR.green"
                        class="justify-content-center state-label ml-auto"
                    />
                    <AppLabel
                        v-if="isUnpublished"
                        :title="$i18n.t('generic.stateMap.unpublishedChanges')"
                        :color="LABEL_COLOR.gray"
                        class="justify-content-center state-label ml-3"
                    />
                    <AppButton
                        v-if="allowEditDraftBtn"
                        :buttonType="BUTTON_TYPES.SECONDARY"
                        :label="$i18n.t('generic.editDraft')"
                        :class="['ml-3', { 'ml-auto': noLabels }]"
                        @click="reloadEditor(false)"
                    />
                    <AppButton
                        v-if="allowViewPublishedBtn"
                        :buttonType="BUTTON_TYPES.SECONDARY"
                        :label="$i18n.t('generic.viewPublished')"
                        :class="['ml-3', { 'ml-auto': noLabels }]"
                        @click="reloadEditor(true)"
                    />
                </div>

                <AppInputV3
                    v-model="lottery.name[selectedLanguage]"
                    class="editor-input-largest mb-3"
                    data-test-id="name-input"
                    :label="$i18n.t('generic.name')"
                    :placeholder="$i18n.t('generic.addName')"
                    :invalid="$v.lottery.name[selectedLanguage].$error"
                    :disabled="readonly"
                    type="text"
                />
                <AppTextareaV3
                    v-model.trim="lottery.description[selectedLanguage]"
                    class="description-textarea editor-input-largest mb-3"
                    :label="$i18n.t('generic.description')"
                    :placeholder="$i18n.t('partials.appTextarea.description')"
                    :disabled="readonly"
                />
                <div
                    v-if="!inEditMode"
                    class="d-flex heading-xs mb-3"
                >
                    <span class="label mr-2">
                        {{ $i18n.t('generic.approveOnCreate') }}
                    </span>
                    <AppToggle
                        v-model="approveOnCreate"
                        :small="true"
                        :disabled="readonly"
                    />
                </div>
                <DateTimePicker
                    v-model="lottery.startTime"
                    class="mb-3"
                    data-test-id="start-time-datepicker"
                    :additionalLabel="`${$i18n.t('generic.startTime')} (${userGMTString})`"
                    :error="$v.lottery.startTime.$error"
                    :disabled="readonly"
                    type="datetime"
                />
                <DateTimePicker
                    v-model="lottery.endTime"
                    data-test-id="end-time-datepicker"
                    :additionalLabel="`${$i18n.t('generic.endTime')} (${userGMTString})`"
                    :disabled="readonly"
                    :error="$v.lottery.endTime.$error"
                    type="datetime"
                />
            </div>
            <!-- Winner Categories -->
            <div class="mb-3">
                <div
                    v-t="'rewards.lottery.winCategories'"
                    class="heading-md mb-3"
                />
                <div class="explanation mb-2">
                    {{ $i18n.t('rewards.lottery.winCategoriesExplanation') }}
                </div>
                <DuplicateSlot
                    v-model="lottery.winCategories"
                    :addButtonLabel="$i18n.t('rewards.lottery.winCategoriesAddCategory')"
                    :enableDeleteButton="true"
                    :optional="false"
                    :customDataStructureOnAdd="{ label: null, key: null, num: null, payout: null }"
                    :disabled="readonly"
                    deleteIconWithoutOffset
                >
                    <template slot-scope="{ slotNumber }">
                        <div class="col-10 mb-2">
                            <div class="row">
                                <div class="col-2">
                                    <AppInputV3
                                        v-model="lottery.winCategories[slotNumber].label"
                                        :placeholder="$i18n.t('rewards.lottery.winLabelPlaceholder')"
                                        :label="slotNumber == 0 ? $i18n.t('rewards.lottery.winLabelLabel') : null"
                                        :explanationText="
                                            slotNumber == 0 ? $i18n.t('rewards.lottery.winLabelTooltip') : null
                                        "
                                        :tooltipPosition="TOOLTIP_POSITION.right"
                                        :invalid="
                                            $v.lottery.winCategories.$error &&
                                            !winCategoriesValidations[slotNumber].label
                                        "
                                        :disabled="readonly"
                                        type="text"
                                        data-test-id="win-categories-label"
                                        class="mr-3"
                                    />
                                </div>
                                <div class="col-2">
                                    <AppInputV3
                                        v-model="lottery.winCategories[slotNumber].key"
                                        :placeholder="$i18n.t('rewards.lottery.winKeyPlaceholder')"
                                        :label="slotNumber == 0 ? $i18n.t('rewards.lottery.winKeyLabel') : null"
                                        :explanationText="
                                            slotNumber == 0 ? $i18n.t('rewards.lottery.winKeyTooltip') : null
                                        "
                                        :tooltipPosition="TOOLTIP_POSITION.right"
                                        :invalid="
                                            $v.lottery.winCategories.$error && !winCategoriesValidations[slotNumber].key
                                        "
                                        :disabled="readonly"
                                        type="text"
                                        data-test-id="win-categories-key"
                                        class="mr-3"
                                    />
                                </div>
                                <div class="col-2">
                                    <AppInputV3
                                        v-model="lottery.winCategories[slotNumber].num"
                                        :placeholder="$i18n.t('rewards.lottery.winNumberPlaceholder')"
                                        :label="slotNumber == 0 ? $i18n.t('rewards.lottery.winNumberLabel') : null"
                                        :explanationText="
                                            slotNumber == 0 ? $i18n.t('rewards.lottery.winNumberTooltip') : null
                                        "
                                        :tooltipPosition="TOOLTIP_POSITION.right"
                                        :min="1"
                                        :max="maxWinners"
                                        :invalid="
                                            $v.lottery.winCategories.$error && !winCategoriesValidations[slotNumber].num
                                        "
                                        :disabled="readonly"
                                        type="number"
                                        data-test-id="win-categories-number"
                                        class="mr-3"
                                    />
                                </div>
                                <div class="col-5">
                                    <AppMultiselectV3
                                        v-model="lottery.winCategories[slotNumber].payout"
                                        data-test-id="win-categories-payout"
                                        class="mr-3"
                                        :options="payoutOptions"
                                        :placeholder="$i18n.t('rewards.lottery.winPayoutPlaceholder')"
                                        :multiple="false"
                                        :small="true"
                                        :closeOnSelect="true"
                                        :disabled="readonly"
                                        :additionalLabel="
                                            slotNumber == 0 ? $i18n.t('rewards.lottery.winPayoutLabel') : null
                                        "
                                        :explanationText="
                                            slotNumber == 0 ? $i18n.t('rewards.lottery.winPayoutTooltip') : null
                                        "
                                        :tooltipPosition="TOOLTIP_POSITION.right"
                                        :error="
                                            $v.lottery.winCategories.$error &&
                                            !winCategoriesValidations[slotNumber].payout
                                        "
                                        trackBy="id"
                                        label="name"
                                    />
                                </div>
                            </div>
                        </div>
                    </template>
                </DuplicateSlot>
            </div>
            <!-- Ticket Code Auto Generation -->
            <div class="mb-3">
                <div class="heading-md mb-3">
                    {{ $i18n.t('rewards.lottery.codeAutoGeneration') }}
                </div>
                <div class="explanation mb-2">
                    {{ $i18n.t('rewards.lottery.codeAutoGenerationExplanation') }}
                </div>
                <template v-if="lottery.ticketSettings.codeAutoGeneration">
                    <div class="explanation mb-2">
                        {{ $i18n.t('rewards.lottery.codeAutoGenerationMaskInstructions') }}
                    </div>
                    <div class="d-flex align-items-center">
                        <AppInputV3
                            v-model="lottery.ticketSettings.codeMask"
                            :placeholder="$i18n.t('rewards.lottery.codeAutoGenerationMaskExample')"
                            class="input-wrapper col-6 min-width"
                            :disabled="readonly || isEditing"
                            data-test-id="code-mask"
                        />
                        <span
                            v-if="possibleCombos > 0"
                            :class="{ 'header-xs yellow': lowNumberOfCodes }"
                            class="header-xs"
                        >
                            {{ possibleUniqueCodesText }}
                        </span>
                    </div>
                </template>
            </div>
            <!-- Ticket Limits -->
            <div class="mb-3">
                <div class="heading-md mb-3">
                    {{ $i18n.t('rewards.lottery.ticketLimits') }}
                    <span class="text-gray"> ({{ $i18n.t('generic.optional') }}) </span>
                </div>
                <div class="d-flex align-items-center">
                    <span class="heading-sm gray-600">
                        {{ $i18n.t('rewards.lottery.maxOf') }}
                    </span>
                    <AppInputV3
                        :value="lottery.maxPurchases"
                        :invalid="$v.lottery.maxPurchases.$error"
                        :disabled="readonly"
                        data-test-id="max-purchases-input"
                        class="mx-3"
                        type="number"
                        placeholder="0"
                        @input="lottery.maxPurchases = $event || null"
                    />
                    <span class="heading-sm gray-600">
                        {{ $i18n.t('rewards.lottery.ticketsInTotal') }}
                    </span>
                </div>
                <div class="d-flex align-items-center mt-2">
                    <span class="heading-sm gray-600">
                        {{ $i18n.t('rewards.lottery.maxOf') }}
                    </span>
                    <AppInputV3
                        :value="lottery.maxPurchasesPerUser"
                        :invalid="$v.lottery.maxPurchasesPerUser.$error"
                        :disabled="readonly"
                        data-test-id="max-purchases-per-user-input"
                        class="mx-3"
                        type="number"
                        placeholder="0"
                        @input="lottery.maxPurchasesPerUser = $event || null"
                    />
                    <span class="heading-sm gray-600">
                        {{ $i18n.t('rewards.lottery.ticketsPerCustomerInALifetime') }}
                    </span>
                </div>
                <div class="d-flex align-items-center mt-2">
                    <span class="heading-sm gray-600">{{ $i18n.t('rewards.lottery.maxOf') }}</span>
                    <AppInputV3
                        :value="lottery.maxPurchasesPerDuration.overall.max"
                        :invalid="$v.lottery.maxPurchasesPerDuration.overall.max.$error"
                        :disabled="readonly || isEditing"
                        data-test-id="overall-max-input"
                        class="mx-3"
                        type="number"
                        placeholder="0"
                        @input="lottery.maxPurchasesPerDuration.overall.max = $event || null"
                    />
                    <span class="heading-sm gray-600">
                        {{ $i18n.t('rewards.lottery.ticketsOverallEvery') }}
                    </span>
                    <AppInputV3
                        :value="lottery.maxPurchasesPerDuration.overall.numberOfDurations"
                        :invalid="$v.lottery.maxPurchasesPerDuration.overall.numberOfDurations.$error"
                        :disabled="readonly || isEditing"
                        data-test-id="overall-number-of-durations-input"
                        class="mx-3"
                        type="number"
                        placeholder="0"
                        @input="lottery.maxPurchasesPerDuration.overall.numberOfDurations = $event || null"
                    />
                    <AppMultiselectV3
                        v-model="lottery.maxPurchasesPerDuration.overall.duration"
                        class="time-select"
                        data-test-id="overall-duration-input"
                        :options="payoutLimitDurationOptions"
                        :showLabels="false"
                        :borderNone="true"
                        :small="true"
                        :allowEmpty="false"
                        :preselectFirst="true"
                        :disabled="readonly || isEditing"
                        label="label"
                        trackBy="id"
                    />
                </div>
                <div class="d-flex align-items-center mt-2">
                    <span class="heading-sm gray-600">
                        {{ $i18n.t('rewards.lottery.maxOf') }}
                    </span>
                    <AppInputV3
                        :value="lottery.maxPurchasesPerDuration.perSubscriber.max"
                        :invalid="$v.lottery.maxPurchasesPerDuration.perSubscriber.max.$error"
                        :disabled="readonly || isEditing"
                        data-test-id="per-subscriber-max-input"
                        class="mx-3"
                        type="number"
                        placeholder="0"
                        @input="lottery.maxPurchasesPerDuration.perSubscriber.max = $event || null"
                    />
                    <span class="heading-sm gray-600">
                        {{ $i18n.t('rewards.lottery.ticketsPerCustomerEvery') }}
                    </span>
                    <AppInputV3
                        :value="lottery.maxPurchasesPerDuration.perSubscriber.numberOfDurations"
                        :invalid="$v.lottery.maxPurchasesPerDuration.perSubscriber.numberOfDurations.$error"
                        :disabled="readonly || isEditing"
                        data-test-id="per-subscriber-number-of-durations-input"
                        class="mx-3"
                        type="number"
                        placeholder="0"
                        @input="lottery.maxPurchasesPerDuration.perSubscriber.numberOfDurations = $event || null"
                    />
                    <AppMultiselectV3
                        v-model="lottery.maxPurchasesPerDuration.perSubscriber.duration"
                        class="time-select"
                        data-test-id="per-subscriber-duration-input"
                        :options="payoutLimitDurationOptions"
                        :showLabels="false"
                        :borderNone="true"
                        :small="true"
                        :allowEmpty="false"
                        :preselectFirst="true"
                        :disabled="readonly || isEditing"
                        label="label"
                        trackBy="id"
                    />
                </div>
            </div>
        </template>
        <!-- Footer section -->
        <template #controls>
            <EditorButtons
                :showSaveDraft="isRewardsDraftEnabled && !readonly"
                :showPublish="isRewardsDraftEnabled && !readonly"
                :showSave="!isRewardsDraftEnabled"
                :disableSave="readonly"
                @cancel="onCancel"
                @saveDraft="onSave(false)"
                @publish="onSave(true)"
                @save="onSave(true)"
            />
        </template>
    </AbstractEditPageWrapper>
</template>

<script>
// Vuex
import Actions, { Getters } from '@/store/mutation-types';
import { mapGetters, mapActions } from 'vuex';

import AppHeader from '@/components/layout/AppHeader.vue';
import AppInputV3 from '@/components/partials/inputs/AppInputV3.vue';
import AppTextareaV3 from '@/components/partials/inputs/AppTextareaV3.vue';
import AppMultiselectV3 from '@/components/partials/inputs/AppMultiselectV3.vue';
import AppToggle from '@/components/partials/inputs/AppToggle.vue';
import AbstractEditPageWrapper from '@/components/layout/AbstractEditPageWrapper.vue';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import { ICON_TYPES } from '@/common/iconHelper';
import DateTimePicker from '@/components/partials/inputs/DateTimePicker.vue';
import DuplicateSlot from '@/components/partials/DuplicateSlot.vue';
import entityEditorMixin from '@/common/entityEditorMixin';
import ENTITY_TYPES from '@/common/entities/entityTypes';
import LanguageSwitcher from '@/components/partials/inputs/LanguageSwitcher.vue';
import RouteNames from '@/router/routeNames';
import { validationMixin } from 'vuelidate';
import { ALERT_TYPES } from '@/common/alerts/Alert';
import { payoutLimitDuration } from '@/modules/rewards/common/rewardsRulesHelper';
import { getMultiLangFieldValueByLocale } from '@/common/entities/entityHelper';
import { getUserGMTString } from '@/common/utils';
import * as Sentry from '@sentry/vue';
import { required, minLength, minValue, numeric, requiredIf } from 'vuelidate/lib/validators';
import moment from 'moment';
import permissionsService from '@/services/permissions/permissions.service';
import EditorButtons from '@/components/layout/EditorButtons.vue';
import { manageEntityAdd, manageEntityUpdate } from '@/common/EntityLoadHelper';
import {
    addLottery,
    updateLottery,
    getLotteryDraft,
    setLotteryDraft,
} from '@/__new__/services/dno/rewards/http/rewards';
import { cloneDeep } from 'lodash';
import { getUserNameById } from '@/__new__/services/portal/profile/http/profile';
import { LABEL_COLOR } from '@/common/labelsHelper';
import AppLabel from '@/components/partials/AppLabel.vue';
import { STATUS_CODES } from '@/common/commonHelper';
import { languageMap } from '@/common/locale/language';
import { TOOLTIP_POSITION } from '@/common/tooltip';
import { DRAW_STATE } from '@/__new__/services/dno/rewards/models/LotteryModelHelper';

export default {
    name: 'LotteryEditor',
    components: {
        AppHeader,
        AppInputV3,
        AppTextareaV3,
        AppToggle,
        AppMultiselectV3,
        AppLabel,
        AppButton,
        AbstractEditPageWrapper,
        DateTimePicker,
        LanguageSwitcher,
        EditorButtons,
        DuplicateSlot,
    },
    mixins: [validationMixin, entityEditorMixin],
    data() {
        return {
            ICON_TYPES,
            BUTTON_TYPES,
            LABEL_COLOR,
            TOOLTIP_POSITION,
            entityType: this.$i18n.t('rewards.lottery.lottery'),
            selectedLanguage: languageMap.en.key,
            lottery: {
                name: {},
                description: {},
                startTime: new Date(),
                endTime: new Date(),
                drawType: 1,
                recurrenceNum: 0,
                ticketSettings: {
                    codeAutoGeneration: true,
                    codeCharacterSet: null,
                    codeMask: null,
                    lcgCharacterSet: null,
                },
                winCategories: [
                    {
                        label: null,
                        key: null,
                        num: null,
                        payout: null,
                    },
                ],
                maxPurchases: null,
                maxPurchasesPerUser: null,
                maxPurchasesPerDuration: {
                    overall: {
                        max: null,
                        duration: null,
                        numberOfDurations: null,
                    },
                    perSubscriber: {
                        max: null,
                        duration: null,
                        numberOfDurations: null,
                    },
                },
            },
            initialData: null,
            approveOnCreate: false,
            userGMTString: getUserGMTString(),
            refreshDateKey: 0,
            permissionsService,
            entityDraft: undefined,
            publishedEntity: undefined,
            updateName: this.$i18n.t('generic.N/A'),
            updateTime: null,
            readonly: false,
            isUnpublished: false,
            isOnlyDraft: false,
            isRewardsDraftEnabled: permissionsService.isRewardsDraftEnabled(),
            saveButtonClicked: false,
            draftSavedId: null,
            possibleCombos: 0,
            winCategoriesValidations: null,
            maxWinners: 100,
        };
    },

    validations() {
        return {
            lottery: {
                name: {
                    [this.selectedLanguage]: {
                        required,
                    },
                },
                startTime: {
                    required,
                },
                endTime: {
                    required,
                },
                winCategories: {
                    minLength: minLength(1),
                    required,
                    validWinCategories() {
                        let valid = true;
                        const keySet = new Set();
                        let winnerCount = 0;
                        this.winCategoriesValidations = [];
                        for (let i = 0; i < this.lottery.winCategories.length; i += 1) {
                            const category = this.lottery.winCategories[i];
                            const result = {
                                label: true,
                                key: true,
                                num: true,
                                payout: true,
                            };
                            if (!category.label) {
                                result.label = false;
                                valid = false;
                            }
                            if (!category.key || keySet.has(category.key)) {
                                result.key = false;
                                valid = false;
                            } else {
                                keySet.add(category.key);
                            }
                            if (!category.num) {
                                result.num = false;
                                valid = false;
                            } else {
                                winnerCount += category.num;
                            }
                            if (winnerCount > this.maxWinners) {
                                result.num = false;
                                valid = false;
                            }
                            if (!category.payout) {
                                result.payout = false;
                                valid = false;
                            }
                            this.winCategoriesValidations.push(result);
                        }
                        return valid;
                    },
                },
                maxPurchases: {
                    numeric,
                    minValue: minValue(1),
                },
                maxPurchasesPerUser: {
                    numeric,
                    minValue: minValue(1),
                },
                maxPurchasesPerDuration: {
                    overall: {
                        max: {
                            required: requiredIf(
                                () => !!this.lottery.maxPurchasesPerDuration.overall.numberOfDurations,
                            ),
                            numeric,
                            minValue: minValue(1),
                        },
                        numberOfDurations: {
                            required: requiredIf(() => !!this.lottery.maxPurchasesPerDuration.overall.max),
                            numeric,
                            minValue: minValue(1),
                        },
                    },
                    perSubscriber: {
                        max: {
                            required: requiredIf(
                                () => !!this.lottery.maxPurchasesPerDuration.perSubscriber.numberOfDurations,
                            ),
                            numeric,
                            minValue: minValue(1),
                        },
                        numberOfDurations: {
                            required: requiredIf(() => !!this.lottery.maxPurchasesPerDuration.perSubscriber.max),
                            numeric,
                            minValue: minValue(1),
                        },
                    },
                },
            },
        };
    },

    computed: {
        ...mapGetters('rewards', {
            getEntities: Getters.GET_REWARDS_ENTITIES_LIST_BY_TYPE,
            getEntity: Getters.GET_REWARDS_ENTITY_BY_TYPE_AND_ID,
            getNonDeletedEntities: Getters.GET_NOT_DELETED_REWARDS_ENTITIES_BY_TYPE,
            getApprovedEntities: Getters.GET_APPROVED_REWARDS_ENTITIES_BY_TYPE,
        }),
        payouts() {
            return this.getNonDeletedEntities(ENTITY_TYPES.REWARD_PAYOUT);
        },
        payoutOptions() {
            return this.payouts.map(entity => {
                const { name } = entity.data;
                return {
                    id: entity.id,
                    name: getMultiLangFieldValueByLocale(name),
                };
            });
        },
        payoutOptionsMap() {
            return this.payoutOptions.reduce((acc, payout) => {
                acc[payout.id] = payout;
                return acc;
            }, {});
        },
        payoutLimitDurationOptions() {
            return [
                {
                    id: 1,
                    label: this.$i18n.t('generic.days').toString().toUpperCase(),
                    value: payoutLimitDuration.DAYS,
                },
                {
                    id: 2,
                    label: this.$i18n.t('generic.weeks').toString().toUpperCase(),
                    value: payoutLimitDuration.WEEKS,
                },
                {
                    id: 3,
                    label: this.$i18n.t('generic.months').toString().toUpperCase(),
                    value: payoutLimitDuration.MONTHS,
                },
                {
                    id: 5,
                    label: this.$i18n.t('generic.years').toString().toUpperCase(),
                    value: payoutLimitDuration.YEARS,
                },
            ];
        },
        inEditMode() {
            return Boolean(this.$route.params.id) && !this.$route.params.clone;
        },
        isDraft() {
            return (
                this.$route.params.id && !this.$route.params.readonly && this.entityDraft && this.isRewardsDraftEnabled
            );
        },
        isPublished() {
            return this.$route.params.id && this.$route.params.readonly && this.isRewardsDraftEnabled;
        },
        noLabels() {
            return !this.isDraft && !this.isPublished;
        },
        allowViewPublishedBtn() {
            return (
                this.$route.params.id && !this.$route.params.readonly && !this.isOnlyDraft && this.isRewardsDraftEnabled
            );
        },
        allowEditDraftBtn() {
            return (
                this.$route.params.id && this.$route.params.readonly && !this.isOnlyDraft && this.isRewardsDraftEnabled
            );
        },
        pageTitle() {
            if (this.$route.params.readonly) {
                return `${this.entityType}`;
            }
            if (this.inEditMode) {
                return `${this.$i18n.t('generic.edit')} ${this.entityType} ${this.$i18n.t('generic.stateMap.draft')}`;
            }
            return `${this.$i18n.t('generic.addNew')} ${this.entityType}`;
        },
        lowNumberOfCodes() {
            const warningThreshold = 100000;
            return this.possibleCombos > 0 && this.possibleCombos < warningThreshold;
        },
        possibleUniqueCodesText() {
            return `${this.possibleCombos} ${this.$i18n.t('rewards.lottery.possibleUniqueCodes')} ${
                this.lowNumberOfCodes ? this.$i18n.t('rewards.lottery.lowNumberOfCodes') : ''
            }`;
        },
    },
    watch: {
        'lottery.ticketSettings.codeMask': {
            handler() {
                let count = 0;
                const mask = this.lottery.ticketSettings.codeMask;
                for (let i = 0; i < mask.length; i += 1) {
                    if (mask[i] === '#') {
                        count += 1;
                    }
                }
                if (mask.length === 0) {
                    this.possibleCombos = 0;
                } else {
                    const defaultMaxCodes = 1e12; // same as backend
                    const charSet =
                        this.lottery.ticketSettings.codeCharacterSet ?? 'ABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890'; // same as backend
                    this.possibleCombos = charSet.length ** count;
                    // cap to max (same logic as backend)
                    if (this.possibleCombos > defaultMaxCodes) {
                        this.possibleCombos =
                            charSet.length ** Math.floor(Math.log(defaultMaxCodes) / Math.log(charSet.length));
                    }
                }
            },
        },
        'lottery.startTime': {
            handler() {
                // to display initial value properly
                this.refreshDateKey += 1;
            },
        },
        'lottery.endTime': {
            handler() {
                // to display initial value properly
                this.refreshDateKey += 1;
            },
        },
    },

    created() {
        this.$withLoadingSpinner(async () => {
            if (this.$route.params.readonly) {
                this.readonly = this.$route.params.readonly;
            }
            const promises = [
                this[Actions.REQUEST_REWARDS_ENTITIES_BY_TYPE](ENTITY_TYPES.LOTTERY),
                this[Actions.REQUEST_REWARDS_ENTITIES_BY_TYPE](ENTITY_TYPES.REWARD_PAYOUT),
            ];
            await Promise.all(promises);
            if (this.$route.params.id) {
                if (this.isRewardsDraftEnabled && !this.$route.params.readonly) {
                    const { id } = this.$route.params;
                    const result = await getLotteryDraft(id);
                    this.entityDraft = result?.data?.data ? result.data.data[id] : null;
                    if (this.entityDraft) this.entityDraft.state = STATUS_CODES.NA;
                }
                this.setupEditPageData();
            }
        });
    },

    methods: {
        ...mapActions('rewards', [Actions.REQUEST_REWARDS_ENTITIES_BY_TYPE]),
        setupEditPageData() {
            this.publishedEntity = this.getEntity(ENTITY_TYPES.LOTTERY, this.$route.params.id);
            if (this.entityDraft && !(this.$route.params.clone && this.publishedEntity)) {
                this.initialData = cloneDeep(this.entityDraft);
                this.initialData.version = this.publishedEntity.version;
                this.isOnlyDraft = !this.publishedEntity || this.publishedEntity.state === STATUS_CODES.NA;
                if (this.publishedEntity.update_time <= this.initialData.update_time) {
                    this.isUnpublished = true;
                }
            } else {
                this.initialData = cloneDeep(this.publishedEntity);
            }

            this.updateTime = this.initialData.update_time;
            this.getUpdateUserName(this.initialData.update_portal_id || this.initialData.portal_id || null);

            this.lottery.name[this.selectedLanguage] = this.$route.params.clone
                ? `${this.initialData.data.name[this.selectedLanguage]} (cloned)`
                : this.initialData.data.name[this.selectedLanguage];
            this.lottery.description[this.selectedLanguage] = this.initialData.data.description[this.selectedLanguage];
            this.lottery.startTime = moment(this.initialData.data.start_time * 1000).toDate();
            this.lottery.endTime = moment(this.initialData.data.end_time * 1000).toDate();
            this.lottery.recurrenceNum = this.initialData.data.recurrence_num;
            this.lottery.drawState = this.initialData.data.draw_state;
            // Set up ticket settings
            this.lottery.ticketSettings.codeMask = this.initialData.data.ticket_settings.code_mask;
            this.lottery.ticketSettings.codeCharacterSet = this.initialData.data.ticket_settings.code_character_set;
            this.lottery.ticketSettings.lcgCharacterSet = this.initialData.data.ticket_settings.lcg_character_set;
            this.lottery.ticketSettings.codeAutoGeneration = this.initialData.data.ticket_settings.code_auto_generation;
            // Set up win categories
            this.lottery.winCategories = [];
            for (let i = 0; i < this.initialData.data.win_categories.length; i += 1) {
                const initialCategory = this.initialData.data.win_categories[i];
                const convertedCategory = {
                    label: null,
                    key: null,
                    num: null,
                    payout: null,
                };
                convertedCategory.label = getMultiLangFieldValueByLocale(initialCategory.name);
                convertedCategory.key = initialCategory.key;
                convertedCategory.num = initialCategory.spots;
                convertedCategory.payout = this.payoutOptionsMap[initialCategory.payouts[0]];
                this.lottery.winCategories.push(convertedCategory);
            }
            this.setupEditPageMaxPurchases();
            if (this.lottery.drawState === DRAW_STATE.DRAWN) {
                this.readonly = true;
            }
        },
        setupEditPageMaxPurchases() {
            this.lottery.maxPurchases = this.initialData.data.max_purchases;
            this.lottery.maxPurchasesPerUser = this.initialData.data.max_purchases_per_user;
            /* eslint-disable */
            if (this.initialData.data.max_purchases_per_duration?.overall?.max) {
                this.lottery.maxPurchasesPerDuration.overall.max =
                    this.initialData.data.max_purchases_per_duration?.overall?.max;
                this.lottery.maxPurchasesPerDuration.overall.numberOfDurations =
                    this.initialData.data.max_purchases_per_duration?.overall?.number_of_durations;
                this.lottery.maxPurchasesPerDuration.overall.duration = this.payoutLimitDurationOptions.find(
                    op => op.value === this.initialData.data.max_purchases_per_duration?.overall?.duration,
                );
            }
            if (this.initialData.data.max_purchases_per_duration?.per_subscriber?.max) {
                this.lottery.maxPurchasesPerDuration.perSubscriber.max =
                    this.initialData.data.max_purchases_per_duration?.per_subscriber?.max;
                this.lottery.maxPurchasesPerDuration.perSubscriber.numberOfDurations =
                    this.initialData.data.max_purchases_per_duration?.per_subscriber?.number_of_durations;
                this.lottery.maxPurchasesPerDuration.perSubscriber.duration = this.payoutLimitDurationOptions.find(
                    op => op.value === this.initialData.data.max_purchases_per_duration?.per_subscriber?.duration,
                );
            }
        },
        onCancel() {
            this.showDontLeaveAlert(this.goToListPage);
        },
        async trySaveDraft() {
            await this.submit(false);
        },
        async tryPublish() {
            // check if there are validation errors
            this.$v.$touch();
            if (this.$v.$invalid) {
                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('alertMessage.pleaseFixValidation'),
                });
                return;
            }
            // check if the start date is in the past
            if (moment(this.lottery.startTime).isBefore(moment(new Date()))) {
                await this.showDateInPastAlert(
                    () => this.submit(true),
                    this.$i18n.t('rewards.lottery.lotteryLowerCase'),
                );
                return;
            }
            // submit
            await this.submit(true);
        },
        async onSave(isPublish) {
            if (this.saveButtonClicked) {
                return;
            }
            this.saveButtonClicked = true;

            if (!isPublish) {
                await this.trySaveDraft();
            } else {
                await this.tryPublish();
            }

            this.saveButtonClicked = false;
        },
        async submit(isPublish) {
            const submissionData = await this.buildSubmissionData();
            if (!isPublish) {
                await this.addOrUpdate(submissionData, false);
                return;
            }
            await this.addOrUpdate(submissionData, true);
        },
        async buildSubmissionData() {
            const submissionData = {
                name: this.lottery.name,
                description: this.lottery.description,
                start_time: Date.parse(this.lottery.startTime) / 1000,
                end_time: Date.parse(this.lottery.endTime) / 1000,
                win_categories: this.lottery.winCategories.map(category => {
                    return {
                        name: {
                            [this.selectedLanguage]: category.label,
                        },
                        key: category.key,
                        spots: category.num,
                        payouts: [category.payout.id],
                    };
                }),
                ticket_settings: {
                    code_auto_generation: true,
                    code_character_set: this.lottery.ticketSettings.codeCharacterSet ?? undefined,
                    code_mask: this.lottery.ticketSettings.codeMask ?? undefined,
                    lcg_character_set: this.lottery.ticketSettings.lcgCharacterSet ?? undefined,
                },
                max_purchases: this.lottery.maxPurchases ?? undefined,
                max_purchases_per_user: this.lottery.maxPurchasesPerUser ?? undefined,
                draw_type: 0, // manual only until implement auto
                recurrence_num: this.lottery.recurrenceNum ?? 0,
                draw_state: this.lottery.drawState ?? 0,
            };
            if (
                this.lottery.maxPurchasesPerDuration.overall.max ||
                this.lottery.maxPurchasesPerDuration.perSubscriber.max
            ) {
                submissionData.max_purchases_per_duration = {};
            }
            if (this.lottery.maxPurchasesPerDuration.overall.max) {
                submissionData.max_purchases_per_duration.overall = {
                    max: this.lottery.maxPurchasesPerDuration.overall.max,
                    duration: this.lottery.maxPurchasesPerDuration.overall.duration.value,
                    number_of_durations: this.lottery.maxPurchasesPerDuration.overall.numberOfDurations,
                };
            }
            if (this.lottery.maxPurchasesPerDuration.perSubscriber.max) {
                submissionData.max_purchases_per_duration.per_subscriber = {
                    max: this.lottery.maxPurchasesPerDuration.perSubscriber.max,
                    duration: this.lottery.maxPurchasesPerDuration.perSubscriber.duration.value,
                    number_of_durations: this.lottery.maxPurchasesPerDuration.perSubscriber.numberOfDurations,
                };
            }
            return submissionData;
        },
        async addOrUpdate(submissionData, isPublish) {
            try {
                this.$Progress.start();
                const payload = {
                    data: submissionData,
                };
                const addDraftAction = async () => {
                    if (!this.isRewardsDraftEnabled) return null;
                    const resp = await setLotteryDraft(payload);
                    this.draftSavedId = resp?.data?.id;
                    return resp;
                };
                const addEntityAction = id =>
                    addLottery({
                        ...payload,
                        approve_on_create: this.approveOnCreate,
                        id,
                    });
                const updateDraftAction = () =>
                    this.isRewardsDraftEnabled
                        ? setLotteryDraft({
                              ...payload,
                              id: this.$route.params.id ?? this.draftSavedId,
                          })
                        : null;
                const updateEntityAction = () =>
                    updateLottery(this.$route.params.id, this.initialData.version, submissionData);
                const hasStoredDraft = this.draftSavedId || (this.entityDraft && !this.$route.params.clone);
                const hasStoredEntity =
                    this.publishedEntity && this.publishedEntity.state !== STATUS_CODES.NA && !this.$route.params.clone;
                if (!hasStoredEntity) {
                    await manageEntityAdd(
                        hasStoredDraft ? updateDraftAction : addDraftAction,
                        addEntityAction,
                        isPublish,
                    );
                } else {
                    await manageEntityUpdate(updateDraftAction, updateEntityAction, isPublish);
                }
                this.$eventBus.$emit('showAlert', {
                    message: this.successSaveMessage,
                    type: ALERT_TYPES.success,
                });
                this.entityEditorMixin.successfullySaved = true;
                await this.getEntities(ENTITY_TYPES.LOTTERY);
                setTimeout(this.goToListPage, 1000);
            } catch (error) {
                Sentry.captureException(error);
                this.$Progress.fail();
                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('alertMessage.errorDoingSmthTryAgain', {
                        action: 'saving',
                        entityName: 'lottery',
                    }),
                });
            }
        },
        goToListPage() {
            this.$router.push({ name: RouteNames.LOTTERIES, params: { companyId: this.$route.params.companyId } });
        },
        async getUpdateUserName(id) {
            try {
                if (id) {
                    const response = await getUserNameById(Number(id));
                    if (response?.data) {
                        this.updateName = response.data;
                    }
                }
            } catch (e) {
                Sentry.captureException(e);
            }
        },
        reloadEditor(isReadonly) {
            const { id } = this.$route.params;
            // Use push to list page because router don`t want to reload same page
            this.$router
                .push({ name: RouteNames.LOTTERIES, params: { companyId: this.$route.params.companyId } })
                .then(() => {
                    this.$router.push({
                        name: RouteNames.LOTTERY_EDITOR,
                        params: {
                            id,
                            readonly: isReadonly,
                            companyId: this.$route.params.companyId,
                        },
                    });
                });
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/consistency-colors';
@import '~@/assets/scss/consistency-spacing';
@import '~@/assets/scss/consistency-typography';
</style>
