<template>
    <AppDialogV2
        :visible="visible"
        :title="$i18n.t('orchestrationEngine.retryExecution')"
        @close="onCloseModal"
    >
        <p class="body-sm mb-1">
            {{ $t('orchestrationEngine.input') }}
        </p>
        <AppTextareaV3
            v-model="inputJsonString"
            :rows="8"
            :invalid="$v.inputJsonString.$error"
            :placeholder="$t('orchestrationEngine.jsonInputPlaceholder')"
            :errorMessage="$t('orchestrationEngine.jsonInput')"
            data-test-id="input-execution-modal"
        />

        <p class="body-sm mb-1 mt-3">
            {{ $t('generic.note') }}
        </p>
        <AppTextareaV3
            v-model="noteInput"
            :rows="8"
            :placeholder="$t('orchestrationEngine.noteInputPlaceholder')"
            :invalid="$v.noteInput.$error"
        />

        <p class="body-sm mb-1 mt-3">
            {{ $t('orchestrationEngine.resultOfExecution') }}
        </p>
        <AppTable
            :entities="resultOfRetryExecution"
            :isDefaultHoverEnabled="false"
            :newDesign="true"
            :canSelectColumns="true"
            :columnsData="tableColumnsData"
            @selectedEntityModel="executionDetailsAction"
        >
            <template #status="{ entity }">
                <ExecutionStatusIndicator :status="entity.status" />
            </template>
            <template
                v-if="writeEnabled"
                #customRowButtons="{ entity }"
            >
                <IconButton
                    :label="$i18n.t('generic.details')"
                    :icon="ICON_TYPES.DETAILS"
                    class="mr-2"
                    @iconClick="executionDetailsAction(entity)"
                />
            </template>
        </AppTable>

        <template #modalFooter>
            <AppButton
                v-show="!retryExecutionFinished"
                :buttonType="BUTTON_TYPES.PRIMARY"
                data-test-id="on-action-button"
                :label="$i18n.t('generic.retry')"
                :disabled="isRetryButtonClicked || !writeEnabled"
                @click="onRetryExecutionClick"
            />
        </template>
    </AppDialogV2>
</template>

<script>
// Vue components
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import AppDialogV2 from '@/components/partials/AppDialogV2.vue';
import AppTextareaV3 from '@/components/partials/inputs/AppTextareaV3.vue';
import AppTable from '@/components/partials/AppTable.vue';
import ExecutionStatusIndicator from '@/__new__/features/orchestrationengine/ExecutionStatusIndicator.vue';
import {
    EXECUTION_STATES,
    EXECUTION_TO_LABEL,
} from '@/__new__/features/orchestrationengine/common/executionStatusIndicatorHelper';
import { ALERT_TYPES } from '@/common/alerts/Alert';
import Button from '@/common/button/Button';

// Helpers & Misc
import * as Sentry from '@sentry/vue';
import RouteNames from '@/router/routeNames';
import IconButton from '@/components/partials/IconButton.vue';
import { ICON_TYPES } from '@/common/iconHelper';
import permissionsService, { isUserAllowed } from '@/services/permissions/permissions.service';
import {
    NOTES_ID_TYPE,
    oeNoteTag,
} from '@/__new__/features/orchestrationengine/common/orchestrationExecutionDetailsPageHelper';
import { dateToEpoch } from '@/common/formatting';

// HTTP
import { scheduleExecution } from '@/__new__/services/dno/orchestrationengine/http/orchestration-engine';
import httpNotes from '@/__new__/services/dno/crm/http/crm';

// Validation
import { required } from 'vuelidate/lib/validators';

export default {
    name: 'RetryExecutionModal',

    components: {
        AppButton,
        AppDialogV2,
        AppTextareaV3,
        AppTable,
        IconButton,
        ExecutionStatusIndicator,
    },

    props: {
        visible: {
            required: true,
            type: Boolean,
        },
        planId: {
            required: true,
            type: String,
        },
        executionInput: {
            type: Object,
            default: () => undefined,
        },
        executionId: {
            type: String,
            default: '',
        },
    },

    data() {
        return {
            // Proxy imports
            ICON_TYPES,
            BUTTON_TYPES,

            inputJsonString: '',
            retryExecutionResult: [],
            isRetryButtonClicked: false,
            retryExecutionFinished: false,
            alertButtons: {},
            noteInput: '',
        };
    },

    computed: {
        resultOfRetryExecution() {
            return this.retryExecutionResult;
        },
        tableColumnsData() {
            return [
                {
                    name: this.$i18n.t('generic.id'),
                    key: 'id',
                    field: 'id',
                },
                {
                    name: this.$i18n.t('orchestrationEngine.startTimestamp'),
                    key: 'startDateFormatted',
                    field: 'startDateMoment',
                    mapper: entity => this.$localeLibrary.getFormattedDateAndTime(entity.startDateFormatted),
                },
                {
                    name: this.$i18n.t('orchestrationEngine.endTimestamp'),
                    key: 'endDateFormatted',
                    field: 'endDateMoment',
                    mapper: entity => this.$localeLibrary.getFormattedDateAndTime(entity.endDateFormatted),
                },
                {
                    name: this.$i18n.t('generic.status'),
                    key: 'status',
                    field: 'statusFilterFormatted',
                    mapper: entity => EXECUTION_TO_LABEL(entity.status),
                },
            ];
        },
        writeEnabled() {
            return isUserAllowed('OrchestrationPlansWrite') && permissionsService.orchestrationEnginePlansEnabled();
        },
    },

    created() {
        this.alertButtons.confirmButton = new Button({
            label: this.$i18n.t('generic.yes'),
            alertType: ALERT_TYPES.warning,
        });
    },

    mounted() {
        this.inputJsonString = JSON.stringify(this.executionInput);
    },

    validations: {
        inputJsonString: {
            validation: function validation(value) {
                if (value) {
                    try {
                        JSON.parse(this.inputJsonString);

                        return true;
                    } catch (e) {
                        return false;
                    }
                }

                return false;
            },
        },
        noteInput: {
            required,
        },
    },

    methods: {
        onCloseModal() {
            this.$emit('close');

            // Reset validation
            this.$v.$reset();

            // Reset modal data
            this.isRetryButtonClicked = false;
            this.retryExecutionFinished = false;
            this.retryExecutionResult = [];
        },
        onRetryExecutionClick() {
            this.isRetryButtonClicked = true;
            this.$v.$touch();

            if (this.$v.$invalid) {
                this.isRetryButtonClicked = false;
                return;
            }

            this.$eventBus.$emit('showAlert', {
                message: this.$i18n.t('alerts.areYouSureRetryExecution'),
                type: ALERT_TYPES.warning,
                buttons: [this.alertButtons.confirmButton],
            });

            this.$eventBus.$once('alertClosed', () => {
                this.isRetryButtonClicked = false;
            });

            // Helper to avoid multiple api calls even though retry button is clicked several times
            this.$eventBus.$off('buttonClicked');

            this.$eventBus.$once('buttonClicked', buttonId => {
                if (buttonId === this.alertButtons.confirmButton.id) {
                    this.isRetryButtonClicked = true;
                    this.onRetryExecution();
                }
            });
        },
        async onRetryExecution() {
            try {
                this.$Progress.start();

                const startAt = new Date() / 1000;
                const jsonAsObject = JSON.parse(this.inputJsonString);

                // Run execution
                const response = await scheduleExecution(this.planId, jsonAsObject, { startAt });

                // This hide 'Retry' button when retry is done
                this.retryExecutionFinished = true;

                // Set data for table
                this.setRetryExecutionResultData(response);

                // Add note for execution
                await httpNotes.addNote({
                    id: this.executionId,
                    idType: NOTES_ID_TYPE.OE_EXECUTION,
                    epoch: dateToEpoch(Date.now()),
                    noteText: this.noteInput,
                    tags: [oeNoteTag],
                });

                this.$Progress.finish();
                this.$emit('retryExecution');
            } catch (error) {
                Sentry.captureException(error);
                this.$Progress.fail();

                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('orchestrationEngine.somethingWentWrongRetryExecution'),
                });
            } finally {
                this.isRetryButtonClicked = false;
            }
        },
        executionDetailsAction(entity) {
            this.$router.push({
                name: RouteNames.ORCHESTRATION_ENGINE_EXECUTION_DETAILS,
                params: {
                    id: this.planId,
                    execId: entity.id,
                    companyId: this.$route.params.companyId,
                },
            });

            // Refresh page in order to change execution and display right data
            this.$router.go(0);
        },
        setRetryExecutionResultData(response) {
            if (response?.data) {
                let status = '';

                const { ended_in_success: endedInSuccess = false, ended_in_failure: endedInFailure = false } =
                    response.data;

                if (!endedInSuccess && !endedInFailure) {
                    status = EXECUTION_STATES.MOVED_TO_ASYNCHRONOUS;
                } else if (endedInSuccess) {
                    status = EXECUTION_STATES.SUCCESS;
                } else if (endedInFailure) {
                    status = EXECUTION_STATES.FAILED;
                }

                this.retryExecutionResult = [
                    {
                        id: response.data.execution_data.execution_id || '',
                        startDateFormatted: response.data.execution_data.started_at || '',
                        endDateFormatted: response.data.execution_data.finished_at || '',
                        status,
                    },
                ];
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/consistency';
@import '~@/assets/scss/layout';
@import '~@/assets/scss/icons';
</style>
