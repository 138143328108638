
import Vue from 'vue';
import * as Sentry from '@sentry/vue';

// HTTP
import {
    addChargingSpecifications,
    setChargingSpecificationDraft,
} from '@/__new__/services/dno/charging/http/chargingSpecifications';
import {
    addChargingSpecificationsGroups,
    setChargingSpecificationsGroupDraft,
} from '@/__new__/services/dno/charging/http/chargingSpecificationsGroups';
import { addPolicyCounters, setPolicyCounterDraft } from '@/__new__/services/dno/charging/http/policyCounters';
import { addPolicyRules, setPolicyRuleDraft } from '@/__new__/services/dno/charging/http/policyRules';
import { addRatingGroup, setRatingGroupDraft } from '@/__new__/services/dno/charging/http/ratingGroups';
import { addUsageCounter, setUsageCounteDraft } from '@/__new__/services/dno/charging/http/usageCounters';
import {
    addConditionParameter,
    setConditionParameterDraft,
} from '@/__new__/services/dno/charging/http/conditionParameters';
import { addWalletCounter, setWalletCounterDraft } from '@/__new__/services/dno/charging/http/walletCounters';
import { addContentType, setContentTypeDraft } from '@/__new__/services/dno/charging/http/contentType';
import { addTariffSpec, setTariffSpecDraft } from '@/__new__/services/dno/charging/http/tariffSpecification';
import {
    addTariffSpecGroup,
    setTariffSpecGroupDraft,
} from '@/__new__/services/dno/charging/http/tariffSpecificationGroups';

// Helpers
import { manageEntityAdd, manageEntityUpdate } from '@/common/EntityLoadHelper';
import ENTITY_TYPES from '@/common/entities/entityTypes';
import { MAP_ENTITY_TYPE_TO_ROUTE_NAME } from '@/__new__/services/dno/charging/common/chargingCommonActionHelper';
import { ALERT_TYPES } from '@/common/alerts/Alert';
import { TranslateResult } from 'vue-i18n';
import Button from '@/common/button/Button';
import { EntityStateMapping } from '@/common/entityStateMapper';
import { STATUS_CODES } from '@/common/commonHelper';
import { TARIFF_SPEC_ZERO_RATE_STRING } from '@/__new__/services/dno/charging/common/tariffSpecHelper';

export default Vue.extend({
    name: 'ChargingCommonActionsMixin',
    data() {
        return {
            disableSaveButton: false,
            alertButtons: {
                deleteButton: new Button({
                    label: this.$i18n.t('generic.delete'),
                    alertType: ALERT_TYPES.warning,
                }),
                cloneButton: new Button({
                    label: this.$i18n.t('generic.clone'),
                    alertType: ALERT_TYPES.warning,
                }),
            } as Record<string, Button>,
        };
    },
    computed: {
        isEditingExisting(): boolean {
            return this.$route.params.id && !this.$route.params.clone;
        },
    },
    methods: {
        async saveEntityData(isOnlyDraft: boolean, affectedEntities: any[], isPublish = true): Promise<void> {
            const currentRouteName: string = MAP_ENTITY_TYPE_TO_ROUTE_NAME[this.entityType] || '';

            try {
                if (this.disableSaveButton) {
                    return;
                }

                const approveOnCreate = isPublish;
                this.disableSaveButton = true;
                let message: TranslateResult = '';

                if (this.isEditingExisting && !isOnlyDraft) {
                    message = this.$i18n.t('charging.updatedEntity', {
                        entity: currentRouteName,
                    });

                    if (affectedEntities.length && isPublish) {
                        this.disableSaveButton = false;
                        this.$eventBus.$emit('closeAllAlerts');
                        this.$eventBus.$emit('showAlert', {
                            message: this.$i18n.t('alerts.affectedEntities'),
                            type: ALERT_TYPES.warning,
                            buttons: [this.alertButtons.details, this.alertButtons.confirmButton],
                        });

                        this.$eventBus.$once('buttonClicked', async (buttonId: string) => {
                            if (buttonId === this.alertButtons.details.id) {
                                this.showModal = true;
                            } else if (buttonId === this.alertButtons.confirmButton.id) {
                                this.disableSaveButton = true;
                                await manageEntityUpdate(
                                    this.saveEntityDraft,
                                    () => this.saveAfterConfirm(false),
                                    isPublish,
                                );
                            }
                        });

                        return;
                    }

                    this.$Progress.start();
                    await manageEntityUpdate(this.saveEntityDraft, () => this.saveAfterConfirm(), isPublish);
                    this.showMsgAndRedirectBack(message, currentRouteName);
                    return;
                }

                message = this.$i18n.t('charging.addedEntity', {
                    entity: currentRouteName,
                });
                this.$Progress.start();

                await manageEntityAdd(
                    this.saveEntityDraft,
                    async (draftId: string): Promise<any> => {
                        const [, entitySave] = this.mapEntityTypeToApi();
                        await entitySave({
                            approve_on_create: approveOnCreate,
                            data: this.data,
                            ...(draftId && { id: draftId }),
                        });
                    },
                    isPublish,
                );

                this.showMsgAndRedirectBack(message, currentRouteName);
            } catch (e) {
                this.disableSaveButton = false;
                this.$Progress.fail();
                Sentry.captureException(e);

                if (e.response && e.response.data) {
                    const errorMessage = JSON.parse(e.response.data);
                    if (errorMessage.code === 9) {
                        this.$eventBus.$emit('showAlert', {
                            message: this.$i18n.t('productCatalog.ratingGroups.duplicateGroup'),
                        });
                    }
                } else {
                    this.$eventBus.$emit('showAlert', {
                        message: this.$i18n.t('alertMessage.errorDoingSmthTryAgain', {
                            action: this.$i18n.t('generic.saving'),
                            entityName: currentRouteName,
                        }),
                    });
                }
            }
        },
        saveEntityDraft(): any {
            const [draftApi] = this.mapEntityTypeToApi();

            // TO DO:
            // Drafts BE is not ready for tariff spec zero rates
            // after is done check will be removed
            if (this.entityType === ENTITY_TYPES.TARIFF_SPECIFICATION_ZERO_RATE) {
                return null;
            }

            return draftApi({
                ...(this.isEditing && { id: this.entityId }),
                data: this.data,
            });
        },
        mapEntityTypeToApi(): any {
            switch (this.entityType) {
                case ENTITY_TYPES.CHARGING_SPECIFICATION:
                    return [setChargingSpecificationDraft, addChargingSpecifications];
                case ENTITY_TYPES.CHARGING_SPECIFICATIONS_GROUP:
                    return [setChargingSpecificationsGroupDraft, addChargingSpecificationsGroups];
                case ENTITY_TYPES.POLICY_COUNTER:
                    return [setPolicyCounterDraft, addPolicyCounters];
                case ENTITY_TYPES.POLICY_RULE:
                    return [setPolicyRuleDraft, addPolicyRules];
                case ENTITY_TYPES.RATING_GROUP:
                    return [setRatingGroupDraft, addRatingGroup];
                case ENTITY_TYPES.USAGE_COUNTER:
                    return [setUsageCounteDraft, addUsageCounter];
                case ENTITY_TYPES.CONDITION_PARAMETERS:
                    return [setConditionParameterDraft, addConditionParameter];
                case ENTITY_TYPES.WALLET_COUNTERS:
                    return [setWalletCounterDraft, addWalletCounter];
                case ENTITY_TYPES.CONTENT_TYPES:
                    return [setContentTypeDraft, addContentType];
                case ENTITY_TYPES.TARIFF_SPECIFICATION:
                case ENTITY_TYPES.TARIFF_SPECIFICATION_ZERO_RATE:
                    return [setTariffSpecDraft, addTariffSpec];
                case ENTITY_TYPES.TARIFF_SPECIFICATION_GROUP:
                    return [setTariffSpecGroupDraft, addTariffSpecGroup];
                default:
                    throw new Error(`Entity Type: ${this.entityType} doesn't exists on api mapper function`);
            }
        },
        showMsgAndRedirectBack(message: TranslateResult, currentRouteName: string): void {
            this.disableSaveButton = false;
            this.$Progress.finish();

            this.$eventBus.$emit('showAlert', {
                message,
                type: ALERT_TYPES.success,
            });

            setTimeout(
                () =>
                    this.$router.push({
                        name: currentRouteName,
                        params: {
                            companyId: this.$route.params.companyId,
                            type:
                                this.entityType === ENTITY_TYPES.TARIFF_SPECIFICATION_ZERO_RATE
                                    ? TARIFF_SPEC_ZERO_RATE_STRING
                                    : null,
                        },
                    }),
                1000,
            );
        },
        showDeleteAlert(
            entityId: string,
            inUseByUpperEntity: boolean,
            state: STATUS_CODES,
            version: number,
            functionsArray: Array<() => void>,
        ) {
            if (inUseByUpperEntity) {
                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('alerts.impactedEntity'),
                    type: ALERT_TYPES.warning,
                });
            } else {
                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('alerts.areYouSure'),
                    type: ALERT_TYPES.warning,
                    buttons: [this.alertButtons.deleteButton],
                });
                this.$eventBus.$once('buttonClicked', (buttonId: string) => {
                    if (buttonId === this.alertButtons.deleteButton.id) {
                        this.deleteEntity(entityId, state, version, functionsArray);
                    }
                });
            }
        },
        deleteEntity(
            entityId: string,
            state: STATUS_CODES,
            version: number,
            [updateState, deleteDraft, initData]: Array<
                (dataId?: string, dataVersion?: number, entityState?: number) => void
            >,
        ) {
            this.$withLoadingSpinner(
                async () => {
                    // hide sidebar if selected entity is deleted
                    if (this.selectedEntityId === entityId) {
                        this.isOverviewEnabled = false;
                    }

                    if (state !== STATUS_CODES.NA) {
                        await updateState(entityId, version, EntityStateMapping.DELETED);
                    }
                    await deleteDraft(entityId);
                    initData();
                },
                {
                    errorHandler: () => {
                        this.$eventBus.$emit('showAlert', {
                            message: this.$i18n.t('alertMessage.errorDoingSmthTryAgain', {
                                action: 'deleting',
                                entityName: 'entity',
                            }),
                        });
                    },
                },
            );
        },
        onReadOnlyAction(entityId: string, routeName: string, paramsData: Record<string, any> = {}) {
            this.$router.push({
                name: routeName,
                params: {
                    id: entityId,
                    readonly: true,
                    companyId: this.$route.params.companyId,
                    ...paramsData,
                },
            });
        },
        onCloneAction(entityId: string, routeName: string, paramsData: Record<string, any> = {}) {
            this.$eventBus.$emit('showAlert', {
                message: this.$i18n.t('alerts.areYouSureClone'),
                type: ALERT_TYPES.warning,
                buttons: [this.alertButtons.cloneButton],
            });

            this.$eventBus.$once('buttonClicked', (buttonId: string) => {
                if (buttonId === this.alertButtons.cloneButton.id) {
                    this.$router.push({
                        name: routeName,
                        params: {
                            id: entityId,
                            clone: true,
                            companyId: this.$route.params.companyId,
                            ...paramsData,
                        },
                    });
                }
            });
        },
        onEditAction(entityId: string, routeName: string, paramsData: Record<string, any> = {}) {
            this.$router.push({
                name: routeName,
                params: {
                    id: entityId,
                    companyId: this.$route.params.companyId,
                    ...paramsData,
                },
            });
        },
    },
});
