import {
    serviceTypeOptions,
    PC_CONDITION_TYPES,
} from '@/__new__/services/dno/charging/common/chargingSpecificationHelper';

export default (id, pcObject) => ({
    data: serviceTypeOptions.filter(option => option.key === pcObject.service_type),
    uuid: id,
    type: PC_CONDITION_TYPES.SERVICE_TYPE,
});
