<template>
    <transition>
        <router-view />
    </transition>
</template>

<script>
export default {
    name: 'Settings',
};
</script>
