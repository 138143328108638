export default class VoucherCode {
    voucherCode: string;
    createdTime: string | null;
    assignedTime?: string | null;
    usageTime?: string | null;

    constructor({ voucherCode = '', createdTime = null, assignedTime = null, usageTime = null } = {}) {
        this.voucherCode = voucherCode;
        this.createdTime = createdTime;
        this.assignedTime = assignedTime;
        this.usageTime = usageTime;
    }
}
