import { getResetPeriod, ResetPeriod } from '@/__new__/services/dno/charging/common/usageCountersEditorHelper';
import {
    CHARGING_ENTITY_TYPES,
    mapLowerEntitiesVersions,
    LowerEntitiesVersionsObject,
} from '@/__new__/services/dno/charging/common/versioningHelper';
import { UsageCountersApiResponse } from '@/__new__/services/dno/charging/http/usageCounters';
import { getAllConditionParametersIds } from '@/__new__/services/dno/charging/common/expression';
import {
    EntityBaseConstructorArgs,
    EntityBaseModel,
    EntityBaseDraftModel,
} from '@/__new__/services/dno/charging/models/EntityBaseModel';
import { TranslateResult } from 'vue-i18n';
import i18n from '@/i18n';
import { parseExpressionsFromBe } from '@/__new__/services/dno/charging/common/expression';

export interface UsageCounterConstructorArgs extends EntityBaseConstructorArgs {
    reset_period: string;
    condition_expr: object;
    available_statuses?: string[];
}

export interface UsageCounterDraftConstructorArgs extends UsageCounterConstructorArgs {
    operator_name: string;
    type: string;
    update_portal_id: string;
    data?: UsageCounterConstructorArgs;
}

export class UsageCounter extends EntityBaseModel {
    resetPeriod: string;
    resetPeriodData: ResetPeriod;
    resetPeriodLabel: TranslateResult;
    resetPeriodDuration: string;
    conditionExpr: any[];
    availableStatuses?: string[];
    lowerEntitiesVersions?: LowerEntitiesVersionsObject | null;

    constructor(
        usageCounter: UsageCounterConstructorArgs,
        draftEntities: UsageCounterDraft[] = [],
        responseData?: UsageCountersApiResponse,
    ) {
        super(usageCounter, draftEntities);
        this.resetPeriod = usageCounter.reset_period;
        this.conditionExpr = usageCounter?.condition_expr ? parseExpressionsFromBe(usageCounter.condition_expr) : [];
        this.resetPeriodData = this.parsResetPeriodData(this.resetPeriod);
        this.resetPeriodLabel = this.resetPeriodData.label || i18n.t('generic.N/A');
        this.resetPeriodDuration = this.resetPeriodData.duration;
        this.availableStatuses = usageCounter.available_statuses || undefined;
        this.lowerEntitiesVersions =
            responseData?.condition_parameter_by_id && usageCounter?.condition_expr
                ? this.getLowerEntitiesVersions(responseData?.condition_parameter_by_id, usageCounter.condition_expr)
                : null;
    }

    getLowerEntitiesVersions(data: Record<string, any>, conditionExpr: any): any {
        return {
            [CHARGING_ENTITY_TYPES.CONDITION_PARAMETER]: mapLowerEntitiesVersions(
                data,
                getAllConditionParametersIds(conditionExpr) || [],
            ),
        };
    }

    parsResetPeriodData(resetPeriod: string): ResetPeriod {
        return getResetPeriod(resetPeriod);
    }
}

export class UsageCounterDraft extends EntityBaseDraftModel {
    resetPeriod: string;
    resetPeriodData: ResetPeriod;
    resetPeriodLabel: TranslateResult;
    resetPeriodDuration: string;
    conditionExpr: any[];

    constructor(usageCounter: UsageCounterDraftConstructorArgs) {
        super(usageCounter);
        this.resetPeriod = usageCounter.reset_period;
        this.conditionExpr = usageCounter?.condition_expr ? parseExpressionsFromBe(usageCounter.condition_expr) : [];
        this.resetPeriodData = this.parsResetPeriodData(this.resetPeriod);
        this.resetPeriodLabel = this.resetPeriodData.label || i18n.t('generic.N/A');
        this.resetPeriodDuration = this.resetPeriodData.duration;
    }

    parsResetPeriodData(resetPeriod: string): ResetPeriod {
        return getResetPeriod(resetPeriod);
    }
}

export default UsageCounter;
