import http from './index';
// import { apiMock } from '@/common/utils';

export const BENEFITS_TYPES = Object.freeze({
    AVAILABLE: 'available',
    HISTORICAL: 'historical',
});

type benefitData = {
    recurrence_limit: number;
    name: string;
    description: string;
    short_descriptions: [
        {
            promotion_short_description: string;
            benefit_short_description: string;
        },
    ];
    amounts: { primary: string };
    terms_and_conditions: string;
};

export const getBenefits = (benefitsType: string, targetId: string, targetType: number) => {
    switch (benefitsType) {
        case BENEFITS_TYPES.AVAILABLE:
            return getAvailableBenefits(targetId, targetType);
        case BENEFITS_TYPES.HISTORICAL:
            return getHistoricalBenefits(targetId, targetType);
        default:
            throw new Error(`Unrecognized benefits type ${benefitsType}`);
    }
};

const getAvailableBenefits = (targetId: string, targetType: number) =>
    http({
        method: 'POST',
        url: '/v3benefits/getavailable',
        data: {
            target_id: targetId,
            target_type: targetType,
        },
    });
// ToDo: Uncomment for test in dev environment and delete after tests finish
// export const getAvailableBenefits = () => (apiMock(true, 500, {
//     data: {
//         request_id: '4cafa2f42551fe4ca1f7d5e21968e387',
//         code: 0,
//         module: 'COMMON',
//         benefits_by_id: {
//             '3f70290e-f937-11eb-9e8e-c2d4ff7fe195': {
//                 short_descriptions: [{
//                     promotion_short_description: 'sample item',
//                     benefit_short_description: '-$10'
//                 }],
//                 state: 1,
//                 base_order_id: '3ea5f8fa-f937-11eb-acae-c12859138a77',
//                 promo_rule_type: 2,
//                 description: 'test rule',
//                 promo_rule_id: '7aa5569e938d42b9907115a1af28d3f0',
//                 update_time: 1628530134623,
//                 benefit_id: '3f70290e-f937-11eb-9e8e-c2d4ff7fe195',
//                 name: 'test promo rule ishan aug9',
//                 grant_type: 1,
//                 reserved_order_id: '3ea5f8fa-f937-11eb-acae-c12859138a77',
//                 recurrence_counter: 1,
//                 amounts: { primary: '10' },
//             },
//             '53f1c4dc-f937-11eb-9699-0299fedf0e77': {
//                 short_descriptions: [{
//                     promotion_short_description: 'sample item',
//                     benefit_short_description: '-$30'
//                 }],
//                 tate: 1,
//                 promo_rule_type: 2,
//                 description: 'test rule',
//                 promo_rule_id: 'a0c6107e0bcc40fd95b4a399c7ea0711',
//                 update_time: 1628530159180,
//                 benefit_id: '53f1c4dc-f937-11eb-9699-0299fedf0e77',
//                 name: 'test promo rule',
//                 grant_type: 2,
//                 recurrence_counter: 0,
//                 amounts: { primary: '30' },
//             },
//         },
//         server_time: 1628780212,
//     },
// }));

const getHistoricalBenefits = (targetId: string, targetType: number) =>
    http({
        method: 'POST',
        url: '/v3benefits/gethistorical',
        data: {
            target_id: targetId,
            target_type: targetType,
        },
    });

export const deleteBenefit = (targetId: string, targetType: number, benefitId: string) =>
    http({
        method: 'POST',
        url: '/v3benefitswrite/delete',
        data: {
            target_id: targetId,
            target_type: targetType,
            benefit_id: benefitId,
        },
    });
// ToDo: Uncomment for test in dev environment and delete after tests finish
// export const deleteBenefit = () => (apiMock(true, 500, {
//     data: {
//         server_time: 1545667505,
//         msg: 'string',
//         code: 0,
//         module: 'PROMO',
//     },
// }));

export const grantBenefit = (targetId: string, targetType: number, promotionRuleId: string) =>
    http({
        method: 'POST',
        url: '/v3benefitswrite/grant',
        data: {
            target_id: targetId,
            target_type: targetType,
            promotion_rule_id: promotionRuleId,
        },
    });
// ToDo: Uncomment for test in dev environment and delete after tests finish
// export const grantBenefit = () => (apiMock(true, 500, {
//     data: {
//         benefit_id: '12f2f5cc-268e-0fe3-51f3-29b014f87a23',
//         server_time: 1545667505,
//         msg: 'string',
//         code: 0,
//         module: 'PROMO',
//     },
// }));

export const updateBenefit = (targetId: string, targetType: number, benefitId: string, data: benefitData) =>
    http({
        method: 'POST',
        url: '/v3benefitswrite/update',
        data: {
            target_id: targetId,
            target_type: targetType,
            benefit_id: benefitId,
            data,
        },
    });
// ToDo: Uncomment for test in dev environment and delete after tests finish
// export const updateBenefit = () => (apiMock(true, 500, {
//     data: {
//         server_time: 1545667505,
//         msg: 'string',
//         code: 0,
//         module: 'PROMO',
//     },
// }));

export default {
    BENEFITS_TYPES,
    getBenefits,
    deleteBenefit,
    grantBenefit,
    updateBenefit,
};
