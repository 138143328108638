export default class Benefit {
    benefitId;

    promotionId;

    baseOrderId;

    creationDate;

    grantType;

    name;

    description;

    promoShortDescription;

    benefitShortDescription;

    termsAndConditions;

    promotionTypeId;

    amount;

    recurrenceLimit;

    transactionId;

    constructor({
        benefitId,
        promotionId,
        baseOrderId,
        creationDate,
        grantType,
        name,
        description,
        promoShortDescription,
        benefitShortDescription,
        termsAndConditions,
        promotionTypeId,
        amount,
        recurrenceCounter,
        recurrenceLimit,
        transactionId,
    } = {}) {
        this.benefitId = benefitId;
        this.promotionId = promotionId;
        this.baseOrderId = baseOrderId;
        this.creationDate = creationDate;
        this.grantType = grantType;
        this.name = name;
        this.description = description;
        this.promoShortDescription = promoShortDescription;
        this.benefitShortDescription = benefitShortDescription;
        this.termsAndConditions = termsAndConditions;
        this.promotionTypeId = promotionTypeId;
        this.amount = amount;
        this.recurrenceCounter = recurrenceCounter;
        this.recurrenceLimit = recurrenceLimit;
        this.transactionId = transactionId;
    }

    static remapBenefitFromBe(benefit) {
        return {
            benefitId: benefit?.benefit_id || '',
            promotionId: benefit?.promo_rule_id || '',
            baseOrderId: benefit?.base_order_id || '',
            creationDate: benefit?.grant_time,
            grantType: benefit?.grant_type || null,
            name: benefit?.name || '',
            description: benefit?.description || '',
            promoShortDescription: benefit?.short_descriptions?.[0]?.promotion_short_description || '',
            benefitShortDescription: benefit?.short_descriptions?.[0]?.benefit_short_description || '',
            termsAndConditions: benefit?.terms_and_conditions || '',
            promotionTypeId: benefit?.promo_rule_type || null,
            amount: benefit?.amounts?.primary || null,
            recurrenceCounter: benefit?.recurrence_counter || null,
            recurrenceLimit: benefit?.recurrence_limit || null,
            transactionId: benefit?.transaction_id || benefit?.order_id || '',
        };
    }
}
