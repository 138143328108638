/* eslint-disable camelcase */
import { getMultiLangFieldValueByLocale } from '@/common/entities/entityHelper';
import ProductCatalogEntityBase from '@/__new__/services/dno/pc/models/ProductCatalogEntityBase';
import { DRAW_STATE, LOTTERY_PHASE } from '@/__new__/services/dno/rewards/models/LotteryModelHelper';

type WinCategory = {
    name: object;
    key: string;
    spots: number;
    payouts: string[];
};

type PortalWinCategory = {
    label: string | null;
    key: string | null;
    num: number | null;
    payout: string | null;
};

type LotteryEntityData = {
    recurrence_num: number;
    draw_state: number;
    metadata: {
        entry_count: number | null;
    } | null;
    win_categories: WinCategory[];
    start_time: number;
    end_time: number;
};

type LotteryEntity = {
    data: LotteryEntityData;
    id: string;
};

export default class LotteryModel extends ProductCatalogEntityBase {
    id: string;

    entryCount: number;

    data: LotteryEntityData;

    winCategories: PortalWinCategory[];

    recurrenceNum: number;

    drawState: DRAW_STATE;

    constructor(entityJson: LotteryEntity) {
        super(entityJson);
        this.id = entityJson.id;
        const entityData = entityJson.data;
        this.entryCount = entityData.metadata?.entry_count ?? 0;
        this.data = entityData;
        this.winCategories = [];
        this.constructWinCategories();
        this.recurrenceNum = this.data.recurrence_num;
        this.drawState = this.data.draw_state;
    }

    constructWinCategories(): void {
        this.winCategories = [];
        for (const initialCategory of this.data.win_categories || []) {
            const convertedCategory: PortalWinCategory = {
                label: null,
                key: null,
                num: null,
                payout: null,
            };
            convertedCategory.label = getMultiLangFieldValueByLocale(initialCategory.name);
            convertedCategory.key = initialCategory.key;
            convertedCategory.num = initialCategory.spots;
            convertedCategory.payout = initialCategory.payouts[0];
            this.winCategories.push(convertedCategory);
        }
    }

    getPhase(): LOTTERY_PHASE {
        if (this.data.draw_state == DRAW_STATE.DRAWN) {
            return LOTTERY_PHASE.DRAWN;
        }
        if (this.data.end_time * 1000 > Date.now()) {
            return LOTTERY_PHASE.PENDING;
        }
        return LOTTERY_PHASE.DRAWABLE;
    }
}
