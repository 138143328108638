
import Vue from '@/common/typedVue';

// components
import AppInputV3 from '@/components/partials/inputs/AppInputV3.vue';
import CardListRadioInput from '@/components/partials/cards/CardListRadioInput.vue';
import AppMultiselectV3 from '@/components/partials/inputs/AppMultiselectV3.vue';
import AppTextareaV3 from '@/components/partials/inputs/AppTextareaV3.vue';
import AppCheckbox from '@/components/partials/inputs/AppCheckbox.vue';

// Http
import ordersHTTP from '@/__new__/services/dno/orders/http/orders';
import notesHTTP from '@/__new__/services/dno/crm/http/crm';
import offersHTTP, { Offer } from '@/__new__/services/dno/pc/http/offer';
import { getRemoteConfig } from '@/__new__/services/dno/remoteConfig/http/remoteConfig';

// helpers
import routeNames from '@/router/routeNames';
import { minLength, required, sameAs } from 'vuelidate/lib/validators';
import {
    ADDRESS_TYPE,
    ADDRESS_MATCH_RESPONSE,
    SUBSCRIBER_STATE,
} from '@/__new__/features/customerCare/common/customerCareHelper';
import { dateToEpoch, formatAddressLines } from '@/common/formatting';
import { mapActions, mapGetters } from 'vuex';
import { Modules } from '@/store/store';
import Actions, { Getters } from '@/store/mutation-types';
import UM_HIERARCHY from '@/common/userManagerHierarchy';
import Account, { AccountAddress } from '@/__new__/services/dno/user/models/Account';
import { TranslateResult } from 'vue-i18n';
import { REMOTE_CONFIG_KEYS } from '@/__new__/services/dno/remoteConfig/common/remoteConfigHelper';
import { EntityState } from '@/http';
import { SIM_TYPES_MAP } from '../../customerCare/subscriber/common/simTypes';

type MultiselectOption = { id: number | string; name: string };

export default Vue.extend({
    name: 'RequestSimReplacement',
    components: {
        AppInputV3,
        AppCheckbox,
        AppMultiselectV3,
        AppTextareaV3,
        CardListRadioInput,
    },
    data() {
        return {
            subscriber: undefined as MultiselectOption | undefined,

            simTypeOptions: [{ id: SIM_TYPES_MAP.PSIM, name: this.$t('generic.pSIM') }] as Array<MultiselectOption>,
            simType: { id: SIM_TYPES_MAP.PSIM, name: this.$t('generic.pSIM') } as MultiselectOption,

            isCommunicatedDeviceCompatibility: false,

            addressMatchOptions: [
                { id: ADDRESS_MATCH_RESPONSE.YES, label: this.$t('generic.yes') },
                { id: ADDRESS_MATCH_RESPONSE.NO, label: this.$t('generic.no') },
            ] as Array<Record<'id' | 'label', string>>,
            isAddressMatches: undefined as Record<'id' | 'label', string> | undefined,
            isCommunicatedShipping: false,
            isCommunicatedAddressChange: false,

            offers: {} as Record<string, Offer>,
            offersCategoriesByCountry: {} as Record<string, string>,
            shippingOffer: undefined as MultiselectOption | undefined,

            agentNotes: '',

            isCommunicatedSimSwap: false,

            ADDRESS_MATCH_RESPONSE,
        };
    },
    validations: {
        subscriber: { required },
        simType: { required },
        shippingOffer: { required },
        agentNotes: { required, minLength: minLength(10) },
        isCommunicatedDeviceCompatibility: { required, sameAs: sameAs(() => true) },
        isCommunicatedShipping: { required, sameAs: sameAs(() => true) },
        isCommunicatedSimSwap: { required, sameAs: sameAs(() => true) },
    },
    computed: {
        ...mapGetters(Modules.customerCareSuite, [
            Getters.GET_UM_ENTITY_HIERARCHY_BY_TYPE_AND_ID,
            Getters.GET_ASSOCIATED_UM_ENTITY_BY_TYPE_AND_ID,
        ]),
        account(): Account {
            return this[Getters.GET_ASSOCIATED_UM_ENTITY_BY_TYPE_AND_ID]({
                targetId: this.$route.params.targetId,
                targetType: UM_HIERARCHY.ACCOUNT,
            }) as Account;
        },
        shippingAddress(): AccountAddress | undefined {
            return Object.values(this.account?.addresses || {}).find(({ type }) => type === ADDRESS_TYPE.SHIPPING);
        },
        shippingAddressString(): string {
            return this.shippingAddress ? formatAddressLines(this.shippingAddress).lines?.join(', ') || '' : '';
        },
        subscriberOptions(): Array<MultiselectOption> {
            const hierarchy = this[Getters.GET_UM_ENTITY_HIERARCHY_BY_TYPE_AND_ID]({
                targetId: this.$route.params.targetId,
                targetType: UM_HIERARCHY.ACCOUNT,
            });
            return (hierarchy?.children || [])
                .filter(({ state }: { state: SUBSCRIBER_STATE }) => state === SUBSCRIBER_STATE.ACTIVE)
                .map((subscriber: any) => ({
                    id: subscriber.id,
                    name: subscriber.msisdn,
                }));
        },
        isSubmitDisabled(): boolean {
            return ![
                this.isAddressMatches?.id === ADDRESS_MATCH_RESPONSE.YES,
                this.replacementOffer,
                this.shippingOffer,
            ].every(Boolean);
        },
        errorAlertMessage(): TranslateResult | string {
            if (this.isAddressMatches?.id === ADDRESS_MATCH_RESPONSE.NO) {
                return this.$t('customerCare.replacementPsim.error.updateShippingAddress');
            }
            if (!this.replacementOffer) {
                return this.$t('customerCare.replacementPsim.error.noReplacementOffer');
            }
            return this.$t('customerCare.replacementPsim.error.fillRequiredFields');
        },
        replacementOffer(): Offer | undefined {
            return Object.values(this.offers).find(
                offer =>
                    offer.data?.is_replacement &&
                    offer.data?.resource_type === this.simType.id &&
                    offer.state === EntityState.APPROVED,
            );
        },
        shippingOfferOptions(): Array<MultiselectOption> {
            const shippingCategory =
                this.offersCategoriesByCountry[this.shippingAddress?.country || ''] ||
                this.offersCategoriesByCountry.DEFAULT;
            return Object.values(this.offers)
                .filter(
                    offer =>
                        (offer.data?.categories || []).includes(shippingCategory) &&
                        offer.state === EntityState.APPROVED,
                )
                .map(offer => ({
                    id: offer.id,
                    name: offer.data.name,
                }));
        },
        selectedShippingOffer(): Offer | undefined {
            return this.offers[this.shippingOffer?.id || ''];
        },
    },
    created() {
        this[Actions.LOAD_ASSOCIATED_UM_ENTITIES]({
            targetId: this.$route.params.targetId,
            targetType: UM_HIERARCHY.ACCOUNT,
        });
        this.fetchOffers();
        this.validateForm();
    },
    methods: {
        ...mapActions(Modules.customerCareSuite, [Actions.LOAD_ASSOCIATED_UM_ENTITIES]),
        goBack() {
            this.$router.push({ name: routeNames.CCS_ACCOUNT_PAGE, params: { id: this.$route.params.targetId } });
        },

        requestReplacement() {
            this.$v.$touch();
            if (this.isSubmitDisabled || this.$v.$invalid) {
                this.$alert(this.errorAlertMessage);
                return false;
            }
            return this.$withLoadingSpinner(
                async () => {
                    await ordersHTTP.createOrder({
                        target_id: this.account.id,
                        target_type: UM_HIERARCHY.ACCOUNT,
                        items: [
                            {
                                entity_id: this.replacementOffer?.id || '',
                                target_id: this.subscriber?.id || '',
                                target_type: UM_HIERARCHY.SUBSCRIBER,
                            },
                            {
                                entity_id: this.shippingOffer?.id || '',
                            },
                        ],
                        skip_charges: true,
                    });

                    await this.setAgentNote();

                    this.$showSuccessAlert({
                        message: this.$t('customerCare.replacementPsim.successMessage'),
                    });

                    setTimeout(this.goBack, 3000);
                },
                {
                    errorHandler: (error: any) => {
                        if (error?.response?.data?.module === 'ORDER' && error?.response?.data?.code === 24) {
                            this.$alert(this.$t('customerCare.replacementPsim.error.noMorePsims'));
                            return;
                        }

                        if (error?.response?.data?.module === 'CATALOG' && error?.response?.data?.code === 30) {
                            this.$alert(this.$t('customerCare.replacementPsim.error.validationFailure'), {
                                description: this.$t('customerCare.replacementPsim.error.tooManyPsims'),
                            });
                            return;
                        }

                        this.$alert(this.$t('customerCare.replacementPsim.error.submitRequestFailed'));
                    },
                },
            );
        },
        setAgentNote() {
            return this.$withProgressBar(
                () =>
                    notesHTTP.addNote({
                        id: this.$route.params.targetId,
                        idType: parseInt(this.$route.params.targetType, 10),
                        epoch: dateToEpoch(Date.now()),
                        noteText: this.agentNotes,
                        tags: [this.$t('customerCare.replacementPsim.agentNoteTag')],
                    }),
                {
                    errorHandler: () => {
                        this.$alert(this.$i18n.t('alertMessage.somethingWentWrongSavingAgentNotes'));
                    },
                },
            );
        },
        async fetchOffers() {
            try {
                const response = await offersHTTP.getOffers();
                this.offers = response?.data.offer_by_id || {};
            } catch (error) {
                this.$alert(this.$t('customerCare.replacementPsim.error.fetchOffersFailed'));
            }

            try {
                const { data: remoteConfig } = await getRemoteConfig();
                if (remoteConfig?.data[REMOTE_CONFIG_KEYS.REPLACEMENT_SIM_SHIPPING_CATEGORIES]) {
                    this.offersCategoriesByCountry = JSON.parse(
                        remoteConfig.data[REMOTE_CONFIG_KEYS.REPLACEMENT_SIM_SHIPPING_CATEGORIES].value,
                    );
                }
            } catch (error) {
                this.$alert(this.$t('customerCare.replacementPsim.error.fetchRemoteConfigFailed'));
            }

            if (this.shippingOfferOptions.length === 1) {
                [this.shippingOffer] = this.shippingOfferOptions;
            }

            if (this.subscriberOptions.length === 1) {
                [this.subscriber] = this.subscriberOptions;
            }

            this.validateForm();
        },
        validateForm() {
            const isValid = !this.$v.$anyError && !this.isSubmitDisabled;
            this.$emit('validationStatus', isValid);
        },
    },
});
