import i18n from '@/i18n';
import {
    USER_MANAGER_HIERARCHY,
    getUserManagerEntityTypeById,
} from '@/__new__/features/customerCare/common/customerCareHelper';
import Account from '@/__new__/services/dno/user/models/Account';
import User from '@/__new__/services/dno/user/models/User';
import Subscriber from '@/__new__/services/dno/user/models/Subscriber';
import { TranslateResult } from 'vue-i18n';

const ccsModuleURL = '/customer-care-suite';

function getBreadCrumbPath(umEntityType?: USER_MANAGER_HIERARCHY, id?: string): string {
    switch (umEntityType) {
        case USER_MANAGER_HIERARCHY.SUBSCRIBER:
            return `${ccsModuleURL}/subscriber/${id}`;
        case USER_MANAGER_HIERARCHY.ACCOUNT:
            return `${ccsModuleURL}/account/${id}`;
        case USER_MANAGER_HIERARCHY.USER:
            return `${ccsModuleURL}/user/${id}`;
        case USER_MANAGER_HIERARCHY.ORGANIZATION:
            return `${ccsModuleURL}/organization/${id}`;
        default:
            // this one handles search breadcrumb
            return ccsModuleURL;
    }
}

export class Breadcrumb {
    name: TranslateResult;
    link: string | null;

    constructor(umEntityType?: USER_MANAGER_HIERARCHY, umEntityId?: string, nonClickable = false) {
        this.name = umEntityType
            ? getUserManagerEntityTypeById(umEntityType)
            : i18n.t('customerCare.breadcrumbs.search');
        this.link = nonClickable ? null : getBreadCrumbPath(umEntityType, umEntityId);
    }
}

export function getBreadcrumbsList(umEntity: User | Account | Subscriber) {
    const result: Array<Breadcrumb> = [new Breadcrumb()];
    if (umEntity.umEntityType === USER_MANAGER_HIERARCHY.SUBSCRIBER) {
        // TODO: figure out where to take org from (if we need) since not present in old UM
        result.push(new Breadcrumb(USER_MANAGER_HIERARCHY.USER, umEntity.userId));
        result.push(new Breadcrumb(USER_MANAGER_HIERARCHY.ACCOUNT, umEntity.accountId));
        result.push(new Breadcrumb(USER_MANAGER_HIERARCHY.SUBSCRIBER, umEntity.id, true));
    } else if (umEntity.umEntityType === USER_MANAGER_HIERARCHY.ACCOUNT) {
        if (umEntity.organizationId) {
            result.push(new Breadcrumb(USER_MANAGER_HIERARCHY.ORGANIZATION, umEntity.organizationId));
        }
        result.push(new Breadcrumb(USER_MANAGER_HIERARCHY.USER, umEntity.userId));
        result.push(new Breadcrumb(USER_MANAGER_HIERARCHY.ACCOUNT, umEntity.id, true));
    } else if (umEntity.umEntityType === USER_MANAGER_HIERARCHY.USER) {
        // TODO: not present in current UM, clarify
        // if (umEntity.organizationIds && umEntity.organizationIds.length) {
        //     result.push(new Breadcrumb(USER_MANAGER_HIERARCHY.ORGANIZATION, umEntity.organizationIds[0]));
        // }
        result.push(new Breadcrumb(USER_MANAGER_HIERARCHY.USER, umEntity.id, true));
    }
    return result;
}

export default {
    getBreadcrumbsList,
};
