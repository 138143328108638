var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.showHierarchy)?_c('div',{staticClass:"associated-entities-tile-wrapper mx-1"},[_c('div',{staticClass:"entity-card"},[_c('h3',{staticClass:"entity-card-title"},[_vm._v(_vm._s(_vm.$i18n.t('generic.user')))]),_c('div',{staticClass:"entity-card-content"},[_c('div',{staticClass:"entity-card-field"},[_c('div',{staticClass:"entity-card-label body-xs bold"},[_vm._v(_vm._s(_vm.$i18n.t('account.fullName')))]),_c('div',{staticClass:"body-xs"},[_vm._v(_vm._s(_vm.hierarchy.name))])]),_c('div',{staticClass:"entity-card-field"},[_c('div',{staticClass:"entity-card-label body-xs bold"},[_vm._v(_vm._s(_vm.$i18n.t('generic.email')))]),_c('span',{staticClass:"entity-card-value body-xs"},[_vm._v(" "+_vm._s(_vm.hierarchy.email)+" ")]),_c('IconButton',{staticClass:"entity-card-copy flex-shrink-0",attrs:{"icon":_vm.ICON_TYPES.CLONE,"label":_vm.$t('customerCareSuite.copyEmail'),"iconSize":'12px'},on:{"iconClick":function($event){return _vm.copyToClipboard(_vm.hierarchy.email)}}})],1),_c('div',{staticClass:"entity-card-field"},[_c('div',{staticClass:"entity-card-label body-xs bold"},[_vm._v(" "+_vm._s(_vm.$i18n.t('customerCare.userInformation.userId'))+" ")]),_c('router-link',{staticClass:"entity-card-value link-xs",attrs:{"to":{
                        name: _vm.RouteNames.CCS_USER_PAGE,
                        params: {
                            id: _vm.hierarchy.id,
                        },
                    }}},[_vm._v(" "+_vm._s(_vm.hierarchy.id)+" ")]),_c('IconButton',{staticClass:"entity-card-copy flex-shrink-0 ml-auto",attrs:{"icon":_vm.ICON_TYPES.CLONE,"label":_vm.$t('customerCareSuite.copyUserId'),"iconSize":'12px'},on:{"iconClick":function($event){return _vm.copyToClipboard(_vm.hierarchy.id)}}})],1)])]),_vm._l((_vm.hierarchy.children),function(acc){return _c('div',{key:acc.id},[_c('div',{staticClass:"entity-card"},[_c('h3',{staticClass:"entity-card-title"},[_vm._v(" "+_vm._s(_vm.$i18n.t('generic.account'))+" "),_c('SubscriberStatusIndicator',{staticClass:"status-label ml-auto",attrs:{"status":acc.state}})],1),_c('div',{staticClass:"entity-card-content"},[_c('div',{staticClass:"entity-card-field"},[_c('div',{staticClass:"entity-card-label body-xs bold"},[_vm._v(_vm._s(_vm.$i18n.t('customerCare.accountId')))]),_c('router-link',{staticClass:"entity-card-value link-xs",attrs:{"to":{
                            name: _vm.RouteNames.CCS_ACCOUNT_PAGE,
                            params: {
                                id: acc.id,
                            },
                        },"title":acc.id,"data-test-id":"account-id-link"}},[_vm._v(" "+_vm._s(acc.id)+" ")]),_c('IconButton',{staticClass:"entity-card-copy flex-shrink-0 ml-auto",attrs:{"icon":_vm.ICON_TYPES.CLONE,"data-test-id":"copy-account-id","label":_vm.$t('customerCareSuite.copyAccountId'),"iconSize":'12px'},on:{"iconClick":function($event){return _vm.copyToClipboard(acc.id)}}})],1)])]),_vm._l((acc.children),function(sub){return _c('div',{key:sub.id,staticClass:"entity-card"},[_c('h3',{staticClass:"entity-card-title"},[_vm._v(" "+_vm._s(_vm.$i18n.t('generic.subscriber'))+" "),_c('SubscriberStatusIndicator',{staticClass:"status-label ml-auto",attrs:{"status":sub.state}})],1),_c('div',{staticClass:"entity-card-content"},[_c('div',{staticClass:"entity-card-field"},[_c('div',{staticClass:"entity-card-label body-xs bold"},[_vm._v(_vm._s(_vm.$i18n.t('generic.address')))]),_c('span',{staticClass:"entity-card-value body-xs"},[_vm._v(" "+_vm._s(sub.address)+" ")]),_c('IconButton',{staticClass:"entity-card-copy flex-shrink-0 ml-auto",attrs:{"icon":_vm.ICON_TYPES.CLONE,"label":_vm.$t('customerCareSuite.copyAddress'),"iconSize":'12px'},on:{"iconClick":function($event){return _vm.copyToClipboard(sub.address)}}})],1),_c('div',{staticClass:"entity-card-field"},[_c('div',{staticClass:"entity-card-label body-xs bold"},[_vm._v(_vm._s(_vm.$i18n.t('customerCare.subscriberId')))]),_c('router-link',{staticClass:"entity-card-value link-xs",attrs:{"to":{
                            name: _vm.RouteNames.CCS_SUBSCRIBER_PAGE,
                            params: {
                                id: sub.id,
                            },
                        },"title":sub.id}},[_vm._v(" "+_vm._s(sub.id)+" ")]),_c('IconButton',{staticClass:"entity-card-copy flex-shrink-0 ml-auto",attrs:{"icon":_vm.ICON_TYPES.CLONE,"label":_vm.$t('customerCareSuite.copySubscriberId'),"iconSize":'12px'},on:{"iconClick":function($event){return _vm.copyToClipboard(sub.id)}}})],1)])])})],2)})],2):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }