<template>
    <div test-id="gor-payment-history">
        <TableFiltersRenderless :entities="paymentList">
            <template #default="{ filteredEntities }">
                <div>
                    <div class="row mb-2 align-items-center table-header-row justify-content-between">
                        <div class="component-title-um">
                            {{ $i18n.t('customerCare.account.GORPaymentHistory') }}
                        </div>
                        <div class="display-flex">
                            <ResponseModalButton
                                :response="paymentListAPIResponse"
                                :title="$i18n.t('customerCare.account.paymentTransactionHistory')"
                            />
                        </div>
                    </div>

                    <AppTable
                        :entities="filteredEntities"
                        :isDefaultHoverEnabled="true"
                        :isCustomButtonPassed="true"
                        :disableBoxShadow="true"
                        :canSelectColumns="true"
                        :newDesign="true"
                        :columnsData="columnsData"
                        tableKey="account/transaction-history"
                        :innerSearchQuery="searchQueryHasAccessTable"
                    >
                        <template #gor_order_id="{ entity }">
                            {{ entity.properties.gor_order_id }}
                        </template>

                        <template #internal_order_id="{ entity }">
                            {{ entity.properties.internal_order_id }}
                        </template>

                        <template #amount="{ entity }"> ₱{{ entity.amount }} </template>

                        <template #transaction_status="{ entity }">
                            <PaymentStatusIndicator :status="entity.transaction_status" />
                        </template>

                        <template #payment_method="{ entity }">
                            {{ getPaymentMethod(entity.payment_method) }}
                        </template>

                        <template #create_timestamp="{ entity }">
                            {{ $localeLibrary.getFormattedDateAndTimeWithSecondsPrecision(entity.create_timestamp) }}
                        </template>
                    </AppTable>
                </div>
            </template>
        </TableFiltersRenderless>
    </div>
</template>

<script>
// COMPONENTS
import AppTable from '@/components/partials/AppTable.vue';
import TableFiltersRenderless from '@/components/filters/TableFiltersRenderless.vue';
import ResponseModalButton from '@/components/partials/ResponseModalButton.vue';
import tableColumnType from '@/common/filterTable';
import ossgpfiberService from '@/__new__/services/dno/ossgpfiber/http/ossgpfiber';
import { GOR_METHOD_MAP } from '@/__new__/services/dno/user/models/GORPayment';
import PaymentStatusIndicator from '@/__new__/features/customerCare/gorPayment/PaymentStatusIndicator.vue';
import UM_HIERARCHY from '@/common/userManagerHierarchy';

export default {
    name: 'GORPaymentHistory',
    components: {
        AppTable,
        ResponseModalButton,
        TableFiltersRenderless,
        PaymentStatusIndicator,
    },
    props: {
        msisdn: {
            required: true,
            type: String,
        },
    },
    data() {
        return {
            paymentList: [],
            paymentListAPIResponse: null,
            searchQueryHasAccessTable: '',
        };
    },
    computed: {
        columnsData() {
            return [
                {
                    name: this.$i18n.t('customerCare.gorPaymentHistory.paymentReferenceId'),
                    key: 'payment_session_id',
                    field: 'payment_session_id',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('customerCare.gorPaymentHistory.timestamp'),
                    key: 'create_timestamp',
                    field: 'create_timestamp',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('customerCare.gorPaymentHistory.gorOrderId'),
                    key: 'gor_order_id',
                    field: 'gor_order_id',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('customerCare.gorPaymentHistory.orderId'),
                    key: 'internal_order_id',
                    field: 'internal_order_id',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('customerCare.gorPaymentHistory.amount'),
                    key: 'amount',
                    field: 'amount',
                },
                {
                    name: this.$i18n.t('customerCare.gorPaymentHistory.method'),
                    key: 'payment_method',
                    field: 'payment_method',
                },
                {
                    name: this.$i18n.t('generic.status'),
                    key: 'transaction_status',
                    field: 'transaction_status',
                },
            ];
        },
    },
    watch: {
        msisdn(val) {
            this.getPaymentList(val);
        },
    },
    methods: {
        async getPaymentList(msisdn) {
            await this.$withProgressBar(
                async () => {
                    this.paymentListAPIResponse = await this.fetchPaymentList(msisdn);
                    this.paymentList = this.paymentListAPIResponse.data.transactions;
                },
                {
                    errorHandler: error => {
                        this.$alert(`Error: ${error.message}`);
                    },
                },
            );
        },
        fetchPaymentList(msisdn) {
            return ossgpfiberService.getPaymentTransaction({
                target_id: msisdn,
                target_type: UM_HIERARCHY.UNREGISTERED_MSISDN,
            });
        },
        getPaymentMethod(paymentMethod) {
            return GOR_METHOD_MAP[paymentMethod];
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/palette';

.all-caps-text {
    margin-bottom: 1.125rem;
}

.modal-title {
    font-size: 1.375rem;
    font-weight: 600;
    color: $gray90;
    margin-bottom: 2.1875rem;
}

.modal-row {
    margin-bottom: 0.875rem;
}

.row-title {
    font-size: 0.75rem;
    font-weight: 600;
    width: 10rem;
}

.row-value {
    font-size: 0.875rem;
    width: 100%;
}
</style>
