import { AxiosPromise } from 'axios';
import http from '@/http/index';
import {
    EntityDetailsByName,
    GetSignedUrlParams,
    QueryMsisdnResponse,
    RegisteredEntities,
    SEARCH_INDEX,
    SignedUrl,
    SignedUrlForDownloadResponse,
} from '@/__new__/services/dno/ossdevedge/models/QodMsisdnDno';
import { DEVICE_LINE_AUTH_API_TYPE } from '@/__new__/services/dno/ossdevedge/models/DeveloperLineAuthorizationDno';

/**
 * Gets signed url for uploading a file
 */
export function getSignedURL(params: GetSignedUrlParams, dataflowId: string): AxiosPromise<SignedUrl> {
    return http({
        method: 'GET',
        url: '/v3developerlineauthorization/signedurl/upload',
        headers: {
            'Content-type': 'application/json',
        },
        params: {
            name: params.name,
            dataflow_id: dataflowId,
        },
    });
}

/**
 * Gets signed url for downloading a file
 */
export function getSignedURLForDownload(
    apiType: DEVICE_LINE_AUTH_API_TYPE,
    filename: string,
): AxiosPromise<SignedUrlForDownloadResponse> {
    return http({
        method: 'POST',
        url: '/v3developerlineauthorization/signedurl/download',
        headers: {
            'Content-type': 'application/json',
        },
        data: {
            api_type: apiType,
            file_name: filename,
        },
    });
}

// Note: Unfortunately the backend can only provide 500 entites maximum so defaulting the size to 500
export function getRegisteredEntities(dataflowId: string, page = 1, size = 500): AxiosPromise<RegisteredEntities> {
    return http({
        method: 'GET',
        url: '/v3developerlineauthorization/registeredentities',
        headers: {
            'Content-type': 'application/json',
        },
        params: {
            page,
            size,
            dataflow_id: dataflowId,
        },
    });
}

export function getEntityDetailsByName(dataflowId: string, name: string): AxiosPromise<EntityDetailsByName> {
    return http({
        method: 'GET',
        url: '/v3developerlineauthorization/entitydetailsbyname',
        headers: {
            'Content-type': 'application/json',
        },
        params: {
            dataflow_id: dataflowId,
            name,
        },
    });
}

/**
 * Gets for a given file (entity) information about an event (category).
 * Event categories are returned by the `getEntityDetailsByName` route.
 * A typical usecase for this route is to identify which lines had errors
 * under the given event category in a file.
 * @param dataflowId the dataflow the file was uploaded to
 * @param name name of the file you would like to know about
 * @param category event category. Usually it's a failure you're interested in (eg: `failed_invalid_line_schema`).
 * @param page
 * @param size
 * @returns
 */
export function getEntityDetailsByCategory(
    dataflowId: string,
    name: string,
    category: string,
    page: number,
    size: number,
): AxiosPromise<object> {
    return http({
        method: 'GET',
        url: '/v3developerlineauthorization/entitydetailsbycategory',
        headers: {
            'Content-type': 'application/json',
        },
        params: {
            dataflow_id: dataflowId,
            name,
            category,
            page,
            size,
        },
    });
}

/**
 * @param msisdn
 * @returns {AxiosPromise}
 */
export function queryMsisdn(
    apiType: DEVICE_LINE_AUTH_API_TYPE,
    searchIndex: SEARCH_INDEX,
    searchString: string,
): AxiosPromise<QueryMsisdnResponse> {
    return http({
        method: 'POST',
        url: '/v3developerlineauthorization/querymsisdn',
        data: {
            api_type: apiType,
            search_field: searchIndex,
            search_value: searchString,
        },
    });
}

export default {};
