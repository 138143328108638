import http from '@/http';
import { type AxiosPromise } from 'axios';
import { type DnoResponseBase } from '@/http/index';
import { type ApplicationDataBe } from '@/__new__/services/dno/ossdevedge/models/ApplicationData';

export interface ApplicationsResponse extends DnoResponseBase {
    apps: ApplicationDataBe[];
}

export interface ApplicationDetailsResponse extends DnoResponseBase {
    app: ApplicationDataBe;
}

const getApplications = (id: string, idType: number): AxiosPromise<ApplicationsResponse> => {
    return http({
        method: 'POST',
        url: '/v3ossdevedge/getapplications',
        data: {
            target_id: id,
            target_type: idType,
        },
    });
};

export const getApplicationByAppClientId = (id: string): AxiosPromise<ApplicationDetailsResponse> => {
    return http({
        method: 'POST',
        url: '/v3ossdevedge/getapplicationbyappclientid',
        data: {
            app_client_id: id,
        },
    });
};

export default {
    getApplications,
    getApplicationByAppClientId,
};
