<template>
    <input
        :class="['no-outline', color]"
        :placeholder="placeholder"
        :value="value"
        :disabled="disabled"
        :invalid="invalid"
        type="text"
        @input="e => $emit('input', e.target.value)"
        @change="e => $emit('change', e.target.value)"
        @keyup="e => $emit('keyup', e)"
    />
</template>

<script>
export const InputStyle = {
    default: 'default',
    transparent: 'transparent',
};

export default {
    name: 'CustomInput',
    props: {
        placeholder: { type: String, default: null },
        value: { type: [String, Number], default: null },
        disabled: { type: Boolean, default: false },
        invalid: { type: Boolean, default: false },
        color: { type: String, default: InputStyle.default },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/_palette';

input {
    padding: 0 12px;
    height: 32px;
    line-height: 32px;

    color: $navy;
    font-size: 14px;

    border: 1px solid $gray5;

    // colors

    &.transparent {
        background-color: transparent;
        border-color: transparent;

        &:focus {
            background-color: $white;
        }
    }

    // colors end

    &::placeholder {
        color: $gray30;
    }

    &[invalid] {
        border-color: $red;
    }

    &:focus {
        border-color: $blue;
    }

    &:disabled {
        color: $gray30;
        background-color: transparent;
    }
}
</style>
