
import Vue, { PropType } from 'vue';

// Components
import AppMultiselectV3 from '@/components/partials/inputs/AppMultiselectV3.vue';
import AppInputV3 from '@/components/partials/inputs/AppInputV3.vue';

// Helpers
import { Element, SECTION_TYPES } from '@/__new__/features/dataflows/common/DataflowInterfaces';
import DataflowElement from '@/__new__/services/dno/dataflows/models/DataflowElement';

export default Vue.extend({
    name: 'DataflowElement',
    components: {
        AppMultiselectV3,
        AppInputV3,
    },
    props: {
        value: {
            type: Object as PropType<Element>,
            required: true,
        },
    },
    data() {
        return {
            SECTION_TYPES,
            dataflowElementData: new DataflowElement(this.value),
        };
    },
    computed: {
        getElementValue(): string | null {
            return typeof this.dataflowElementData?.value === 'string' ||
                typeof this.dataflowElementData?.value === 'number'
                ? this.dataflowElementData.value
                : this.dataflowElementData.value?.value || null;
        },
        elementErrorMsg(): string {
            return this.getElementValue && this.dataflowElementData?.regex
                ? this.dataflowElementData.regex.message
                : this.$i18n.t('generic.validations.fieldIsRequired');
        },
        validateElementByRegex(): boolean {
            if (this.dataflowElementData?.regex?.expression) {
                const match = this.getElementValue?.match(
                    new RegExp(this.dataflowElementData?.regex?.expression, 'gi'),
                );
                return match === null;
            }

            return false;
        },
        isInputField(): boolean {
            return [SECTION_TYPES.TEXT_INPUT, SECTION_TYPES.NUMBER_INPUT].includes(this.dataflowElementData.type);
        },
        isInputFieldType(): string {
            return this.dataflowElementData.type === SECTION_TYPES.TEXT_INPUT ? 'text' : 'number';
        },
    },
    methods: {
        validateElement() {
            this.dataflowElementData.error =
                this.dataflowElementData.required && (this.getElementValue === null || this.validateElementByRegex);
        },
        onElementChange(): void {
            this.validateElement();
            this.$emit('input', this.dataflowElementData);
        },
    },
});
