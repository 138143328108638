<template>
    <div ref="progressCircle" />
</template>

<script>
import ProgressBar from 'progressbar.js';

export default {
    name: 'AppProgressCircle',
    props: {
        // SHOULD BE FROM 0 TO 1
        value: {
            type: Number,
            required: true,
        },
        color: {
            type: String,
            default: '#335195',
        },
        innerText: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            progressBar: null,
        };
    },
    beforeDestroy() {
        // Fix for progressbar.js to correctly stop animation before destroying it.
        // eslint-disable-next-line no-unused-expressions, no-underscore-dangle, no-underscore-dangle
        this.progressBar?._progressPath?._tweenable?.stop?.(true);
        this.progressBar.destroy();
    },
    mounted() {
        this.progressBar = new ProgressBar.Circle(this.$refs.progressCircle, this.getProgressBarConfig());

        this.progressBar.animate(this.value);
    },
    methods: {
        getProgressBarConfig() {
            return {
                strokeWidth: 8,
                easing: 'easeInOut',
                duration: 1000,
                color: this.color,
                trailColor: '#f5f6f9',
                trailWidth: 8,
                svgStyle: true,
                text: {
                    // Initial value for text.
                    // Default: null
                    value: this.innerText,
                    style: {
                        // Text color.
                        // Default: same as stroke color (options.color)
                        color: '#999999',
                        position: 'absolute',
                        left: '50%',
                        top: '50%',
                        padding: 0,
                        margin: 0,
                        // You can specify styles which will be browser prefixed
                        transform: {
                            prefix: true,
                            value: 'translate(-50%, -50%)',
                        },
                    },
                },
            };
        },
    },
};
</script>

<style lang="scss" scoped></style>
