<template>
    <div class="section-title section-margin mt-5">
        <div>
            {{ $i18n.t('customerCare.account.taxes') }}
        </div>

        <div class="details details-c">
            <div class="details-info">
                <AppInfoBlock
                    v-for="(info, i) in flagsSection.slice(0, 2)"
                    :key="i"
                    :title="info.title"
                    :fields="info.value"
                    :explanationText="info.explanationText"
                    class="info"
                />
            </div>
        </div>

        <AppButton
            v-if="showReqDecline"
            :label="$i18n.t('customerCare.account.declineBtn')"
            :isSmall="true"
            :buttonType="BUTTON_TYPES.TERMINATION"
            class="mt-2"
            data-test-id="show-req-decline"
            @click="onUpdateAppointment(0)"
        />

        <AppButton
            v-else-if="showReqApproved"
            :label="$i18n.t('customerCare.account.approveBtn')"
            :isSmall="true"
            :buttonType="BUTTON_TYPES.SECONDARY"
            class="mt-2"
            @click="onUpdateAppointment(1)"
        />
    </div>
</template>

<script>
// Components
import { ALERT_TYPES } from '@/common/alerts/Alert';
import Button from '@/common/button/Button';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import AppInfoBlock from '@/components/partials/AppInfoBlock.vue';

// HTTP
import userManagementHTTP from '@/__new__/services/dno/user/http/user-management';

// helper
import { USER_MANAGER_HIERARCHY } from '@/__new__/features/customerCare/common/customerCareHelper';
import { isEmpty } from 'lodash';
import { mapActions } from 'vuex';
import supportButtonMixin from '@/components/alerts/supportButtonMixin';
import { FLAG_TAX_EXEMPTION } from '@/__new__/services/dno/user/models/Flag';

// sentry
import * as Sentry from '@sentry/vue';

export default {
    name: 'AccountEditorTaxExemption',
    components: {
        AppButton,
        AppInfoBlock,
    },
    mixins: [supportButtonMixin],
    props: {
        entity: {
            type: Object,
            required: true,
        },
        accountId: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            isEmpty,
            BUTTON_TYPES,
            flagsForUpdate: {},
            alertButtons: {
                confirmTaxExemption: new Button({
                    label: this.$i18n.t('generic.confirm'),
                    alertType: ALERT_TYPES.warning,
                }),
            },
        };
    },
    computed: {
        showReqDecline() {
            return this.entity?.originalValue === FLAG_TAX_EXEMPTION.REQUEST_DECLINE;
        },
        showReqApproved() {
            return this.entity?.originalValue === FLAG_TAX_EXEMPTION.REQUEST_APPROVED;
        },
        flagsSection() {
            if (isEmpty(this.entity)) {
                return [];
            }
            const flags = {
                value: [],
            };

            if (this.entity !== undefined) {
                flags.value.push({
                    name: this.entity.name,
                    value: this.entity.detailedValue,
                });
            }

            return [flags];
        },
    },
    methods: {
        ...mapActions('userManagementAccount', ['getAccountInfo']),
        onUpdateAppointment(status) {
            this.$eventBus.$emit('showAlert', {
                message: status ? this.$i18n.t('account.taxExempted') : this.$i18n.t('account.taxExemptedDecline'),
                type: ALERT_TYPES.warning,
                buttons: [this.alertButtons.confirmTaxExemption],
            });
            this.$eventBus.$once('buttonClicked', buttonId => {
                if (buttonId === this.alertButtons.confirmTaxExemption.id) {
                    this.approveDeclineTaxRequest(status);
                }
            });
        },
        async approveDeclineTaxRequest(status) {
            this.updateFlag('tax_exemption', !!status);

            this.$Progress.start();
            try {
                // FLAGS
                if (!isEmpty(this.flagsForUpdate)) {
                    await userManagementHTTP.updateFlags(
                        this.accountId,
                        USER_MANAGER_HIERARCHY.ACCOUNT,
                        this.flagsForUpdate,
                    );
                }

                await this.getAccountInfo(this.$route.params.id);
            } catch (e) {
                Sentry.captureException(e);
                this.$Progress.fail();
                this.showSupportAlert(
                    this.$i18n.t('alertMessage.somethingWentWrongFetchingNecessaryData'),
                    ALERT_TYPES.error,
                );
            } finally {
                this.$Progress.finish();
                this.$set(this.entity, 'value', status ? FLAG_TAX_EXEMPTION.APPROVED : FLAG_TAX_EXEMPTION.DECLINE);
            }
        },
        updateFlag(field, value) {
            this.flagsForUpdate[field] = value;
        },
    },
};
</script>

<style lang="scss" scoped>
.details-c {
    margin-top: 0.625rem;
}
</style>
