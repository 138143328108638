
import { PropType } from 'vue';
import AppOverviewBlock from '@/components/partials/AppOverviewBlock.vue';
import BulkUploadTabs from '@/__new__/features/resources/BulkUploadTabs.vue';
import VueJsonPretty from 'vue-json-pretty';
import {
    ApplicationOwner,
    ApprovalStatusType,
    APPROVAL_STATUS_TO_LABEL_MAPPING,
    APPROVAL_STATUS_TO_COLOR_MAPPING,
} from '@/__new__/features/operateAPIs/common/ApplicationOwner';
import OverviewHeaderV2 from '@/components/partials/entityOverview/OverviewHeaderV2.vue';
import EntityStatusIndicator from '@/components/partials/EntityStatusIndicator.vue';
import AppApprovalBlock from '@/components/partials/AppApprovalBlock.vue';
import { updateApplicationOwner } from '@/__new__/services/dno/operateAPIs/http/operateAPIs';

export default {
    name: 'ApplicationOwnerOverview',

    components: {
        AppOverviewBlock,
        BulkUploadTabs,
        VueJsonPretty,
        OverviewHeaderV2,
        EntityStatusIndicator,
        AppApprovalBlock,
    },

    props: {
        applicationOwner: {
            type: Object as PropType<ApplicationOwner>,
            required: true,
            default: () => ({}),
        },
    },

    data() {
        return {
            APPROVAL_STATUS_TO_LABEL_MAPPING,
            APPROVAL_STATUS_TO_COLOR_MAPPING,
            selectedTabId: 'general',
            ApprovalStatusType,
            tabs: [
                {
                    id: 'general',
                    label: this.$i18n.t('generic.general'),
                },
                {
                    id: 'json',
                    label: this.$i18n.t('generic.json'),
                },
            ],
        };
    },

    computed: {
        detailsSections() {
            return [
                {
                    isCollapsed: false,
                    name: this.$i18n.t('generic.general'),
                    rows: [
                        {
                            value: this.applicationOwner?.id || this.$i18n.t('generic.empty'),
                            name: this.$i18n.t('generic.id'),
                        },
                        {
                            value: this.applicationOwner?.name || this.$i18n.t('generic.empty'),
                            name: this.$i18n.t('generic.name'),
                        },
                        {
                            value: this.applicationOwner?.description || this.$i18n.t('generic.empty'),
                            name: this.$i18n.t('generic.description'),
                        },
                        {
                            value: this.applicationOwner?.engagedPartyName || this.$i18n.t('generic.empty'),
                            name: this.$i18n.t('operateAPIs.engagedParty'),
                        },
                        {
                            value: this.applicationOwner?.channelPartnerName || this.$i18n.t('generic.empty'),
                            name: this.$i18n.t('operateAPIs.channelPartner'),
                        },
                        {
                            value: this.applicationOwner?.status || this.$i18n.t('generic.empty'),
                            name: this.$i18n.t('generic.status'),
                        },
                        {
                            value: this.applicationOwner?.statusReason || this.$i18n.t('generic.empty'),
                            name: this.$i18n.t('operateAPIs.statusReason'),
                        },
                        {
                            value: this.applicationOwner?.approvalStatus || this.$i18n.t('generic.empty'),
                            name: this.$i18n.t('operateAPIs.approvalStatus'),
                        },
                        {
                            value: this.applicationOwner?.approvalStatusReason || this.$i18n.t('generic.empty'),
                            name: this.$i18n.t('operateAPIs.approvalStatusReason'),
                        },
                        {
                            value: this.applicationOwner?.href || this.$i18n.t('generic.empty'),
                            name: this.$i18n.t('generic.link'),
                        },
                    ],
                },
                {
                    isCollapsed: false,
                    name: this.$i18n.t('operateAPIs.validFor'),
                    rows: [
                        {
                            value: this.applicationOwner?.startDateTimeString || this.$i18n.t('generic.empty'),
                            name: this.$i18n.t('operateAPIs.startDateTime'),
                        },
                        {
                            value: this.applicationOwner?.endDateTimeString || this.$i18n.t('generic.empty'),
                            name: this.$i18n.t('operateAPIs.endDateTime'),
                        },
                    ],
                },
            ];
        },
    },
    methods: {
        async updateApprovalStatus(status: ApprovalStatusType, reason: string) {
            await this.$withLoadingSpinner(
                async () => {
                    await updateApplicationOwner({
                        id: this.applicationOwner.id,
                        payload: {
                            approvalStatus: status,
                            approvalStatusReason: reason,
                        },
                        channelPartnerId: this.applicationOwner.channelPartnerId,
                    });

                    this.$showSuccessAlert({
                        message: this.$i18n.t('operateAPIs.successfullyUpdatedApplicationOwner'),
                    });

                    this.applicationOwner.approvalStatus = status;
                },
                {
                    errorHandler: () => {
                        this.$alert(this.$i18n.t('operateAPIs.somethingWentWrongUpdatedApplicationOwner'));
                    },
                },
            );
        },
    },
};
