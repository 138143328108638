
import Vue from 'vue';
import { getBillingAccount, getBills } from '@/__new__/features/charterDemo/charterHttp';
import AppInfoBlock from '@/components/partials/AppInfoBlock.vue';

export default Vue.extend({
    name: 'CharterDemoBillingAccountGeneralTile',
    components: {
        AppInfoBlock,
    },
    data() {
        return {
            apiResponse: {} as object,
            billingAccount: {} as object,
            lastBill: {} as object,
            fields: [] as any[],
        };
    },
    computed: {
        id() {
            return this.$route.params.id || '';
        },
    },
    watch: {},
    async created() {
        await this.$withProgressBar(
            async () => {
                this.$emit('isDataLoadingUpd', true);
                let res = await getBillingAccount(this.id);
                this.billingAccount = { ...res.data[0] };
                res = await getBills(this.billingAccount.id, 1);
                this.lastBill = { ...res.data[0] };
                this.fields = [
                    {
                        name: 'Name',
                        value: this.billingAccount.name,
                    },
                    {
                        name: 'Last modified',
                        value: this.$localeLibrary.getFormattedDate(
                            new Date(this.billingAccount?.lastUpdate).getTime(),
                        ),
                    },
                    {
                        name: 'Billing cycle',
                        value: this.billingAccount?.billStructure?.cycleSpecification?.name,
                    },
                    {
                        name: 'Dunning Profile',
                        value: '-',
                    },
                    {
                        name: 'Next billing date',
                        value: this.$localeLibrary.getFormattedDate(new Date(this.lastBill?.nextBillDate).getTime()),
                    },
                ];
                this.$emit('isDataLoadingUpd', false);
            },
            {
                errorHandler: error => {
                    this.apiResponse = error;
                    this.$emit('dataFetchingError');
                    this.$emit('isDataLoadingUpd', false);
                },
            },
        );
    },
    methods: {},
});
