import { STATUS_CODES } from '@/common/commonHelper';
import { isEmpty } from 'lodash';

export default {
    methods: {
        async loadEntityDraft(entityType, id) {
            try {
                // Cannot load draft if we don't have an id
                if (!id) {
                    return;
                }
                // Read-only mode does not see draft data
                if (this.readonly) {
                    return;
                }
                // Load draft data
                const result = await this.collectEntityDraft(entityType, id);
                // If the data contents of the draft is empty then we consider it null
                // Short circuit so we don't consider this a valid draft
                const isNullDraft = isEmpty(result?.data?.data?.[id]?.data);
                if (isNullDraft) {
                    return;
                }
                // Construct entityDraft data
                const EntityTypeModel = this.getEntityTypesToModelMap().get(entityType);
                this.entityDraft = new EntityTypeModel({
                    ...result.data.data[id],
                    entityType: this.entityType,
                    state: STATUS_CODES.NA,
                });
                // Set unpublished flag since we have a draft (that's not null)
                this.isUnpublished = true;
            } catch (error) {
                this.$alert(this.$i18n.t('alertMessage.failedToLoadDraftData'), { type: ALERT_TYPES.error });
                Sentry.captureException(error);
            }
        },
    },
};
