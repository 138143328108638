<template>
    <div>
        <div
            v-if="label"
            class="label lf-labels"
        >
            {{ label }}
        </div>

        <CardListWrapper
            :value="value"
            :cardsValues="cardsValues"
            :disabled="disabled"
            :requiredError="error"
            :small="small"
            data-test-id="card-list-radio-input"
            @input="emitValue"
        >
            <template
                v-for="cardItem in cardsValues"
                :slot="`cardBody${cardItem.id}`"
            >
                <div
                    :key="cardItem.id"
                    :class="{ small, active: value && cardItem.id === value.id }"
                    class="card-body d-flex flex-column align-items-start"
                >
                    <span class="card-label lf-subtitle">
                        {{ cardItem.label }}
                    </span>
                    <span class="description lf-secondary-text">
                        {{ cardItem.description }}
                    </span>
                </div>
                <slot :name="`cardAdditionalContent${cardItem.id}`" />
            </template>
        </CardListWrapper>
    </div>
</template>

<script>
import CardListWrapper from '@/components/partials/cards/CardListWrapper.vue';

export default {
    name: 'CardListRadioInput',
    components: {
        CardListWrapper,
    },
    props: {
        // Example:
        // [
        //     {
        //         id: 1,
        //         label: 'testLabel',
        //         description: 'testDescription',
        //         disabled?: true,
        //     }
        // ]
        cardsValues: {
            type: Array,
            default: () => [],
        },
        // Property which if set to true disables input on all cards
        disabled: {
            type: Boolean,
            default: false,
        },
        error: {
            type: Boolean,
            default: false,
        },
        value: {
            type: Object,
            default: null,
        },
        label: {
            type: String,
            default: null,
        },
        small: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        emitValue(val) {
            this.$emit('input', { ...val });
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/_palette.scss';
@import '~@/assets/scss/_animations.scss';
@import '~@/assets/scss/_typographyV2.scss';

.label {
    color: $gray90;
    margin-bottom: 0.25rem;
}

.card-body {
    height: 100%;
    position: relative;
    padding-left: 52px;

    .card-label {
        margin: 0;
    }

    .description {
        text-align: left;
        line-height: 1.25;
        margin-top: 0.125rem;
    }

    &::before {
        content: '';
        width: 16px;
        height: 16px;
        opacity: 0.4;
        border: solid 2px $gray10;
        border-radius: 50%;
        position: absolute;
        top: 50%;
        left: 18px;
        transform: translateY(-50%);
        transition: $medium-animation-time;
    }

    &.active {
        &::before {
            opacity: 1;
            background-color: $blue;
            border-color: rgba($color: $blue, $alpha: 0.4);
            background-clip: padding-box;
        }

        .card-label {
            color: $blue;
        }
    }

    &.small {
        padding-left: 1.75rem;

        &::before {
            left: 4px;
            top: 10px;
        }

        .card-label {
            font-size: 14px;
            text-align: left;
        }

        .description {
            font-size: 10px;
        }
    }
}
</style>
