<template>
    <div>
        <div
            v-t="'productCatalog.ratingGroups.ratingGroup'"
            class="table-title"
        />
        <div class="primary-text">
            <span
                v-for="(ratingGroup, index) in ratingGroups"
                :key="index"
            >
                {{ rgTagLabelFormatter(ratingGroup) }}
            </span>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { Modules } from '@/store/store';
import Actions, { Getters } from '@/store/mutation-types';
import * as Sentry from '@sentry/vue';
import { rgTagLabelFormatter } from '@/__new__/services/dno/charging/common/ratingGroupHelper';

export default {
    name: 'RatingGroupValue',
    props: {
        value: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            options: [],
            isDataLoading: true,
        };
    },
    computed: {
        ...mapGetters(Modules.chargingV2, [Getters.GET_APPROVED_RATING_GROUPS]),
        ratingGroups() {
            return this.options.filter(rg => this.value.rating_group_numbers.includes(rg.number));
        },
    },
    async created() {
        this.$Progress.start();
        this.isDataLoading = true;

        await this.$store.dispatch(`${Modules.chargingV2}/${Actions.REQUEST_RATING_GROUPS}`);

        this.options = this[Getters.GET_APPROVED_RATING_GROUPS];
        try {
            this.fetchApprovedRatingGroups();
            this.$Progress.finish();
        } catch (e) {
            this.$Progress.fail();
            Sentry.captureException(e);
            this.$eventBus.$emit('showAlert', {
                message: this.$i18n.t('productCatalog.ratingGroups.failedToLoadRatingGroups'),
            });
        } finally {
            this.isDataLoading = false;
        }
    },
    methods: {
        fetchApprovedRatingGroups() {
            this.options = this[Getters.GET_APPROVED_RATING_GROUPS].map(r => ({
                id: r.id,
                name: r.name.en,
                number: r.number,
            }));
        },
        rgTagLabelFormatter,
    },
};
</script>
