import invert from 'lodash/invert';
import i18n from '@/i18n';

// mapped on server
export const SEGMENT_ID_TYPES: Record<keyof typeof SEGMENT_ID_TYPE, SEGMENT_ID_TYPE> = {
    SUBSCRIBER: 1,
    ACCOUNT: 2,
    USER: 3,
    IMSI: 7,
    MSISDN: 8,
    ICCID: 9,
    EMAIL: 11,
    GOOGLE_PLACE_ID: 12,
    PROVIDER_ID: 13,
    MARKET_ID: 14,
    ADDRESSBOOK_ID: 15,
    ACCOUNT_ID: 16,
    SUBSCRIBER_ID: 17,
    USER_ID: 18,
    UNREGISTERED_ID: 100,
    EXTERNAL_IDP_ID: 400,
};
export enum SEGMENT_ID_TYPE {
    SUBSCRIBER = 1,
    ACCOUNT = 2,
    USER = 3,
    IMSI = 7,
    MSISDN = 8,
    ICCID = 9,
    EMAIL = 11,
    GOOGLE_PLACE_ID = 12,
    PROVIDER_ID = 13,
    MARKET_ID = 14,
    ADDRESSBOOK_ID = 15,
    ACCOUNT_ID = 16,
    SUBSCRIBER_ID = 17,
    USER_ID = 18,
    UNREGISTERED_ID = 100,
    EXTERNAL_IDP_ID = 400,
}

export const StaticFilterLabel: Record<SEGMENT_ID_TYPE, string> = {
    [SEGMENT_ID_TYPE.SUBSCRIBER]: 'Subscriber',
    [SEGMENT_ID_TYPE.ACCOUNT]: 'Account',
    [SEGMENT_ID_TYPE.USER]: 'User',
    [SEGMENT_ID_TYPE.UNREGISTERED_ID]: 'Unregistered id',
    [SEGMENT_ID_TYPE.MSISDN]: 'MSISDN',
    [SEGMENT_ID_TYPE.ICCID]: 'ICCID',
    [SEGMENT_ID_TYPE.IMSI]: 'IMSI',
    [SEGMENT_ID_TYPE.EMAIL]: 'Email',
    [SEGMENT_ID_TYPE.GOOGLE_PLACE_ID]: 'Google Place ID',
    [SEGMENT_ID_TYPE.ADDRESSBOOK_ID]: 'Addressbook ID',
    [SEGMENT_ID_TYPE.PROVIDER_ID]: 'Provider ID',
    [SEGMENT_ID_TYPE.MARKET_ID]: 'Market ID',
    [SEGMENT_ID_TYPE.ACCOUNT_ID]: 'Account ID',
    [SEGMENT_ID_TYPE.SUBSCRIBER_ID]: 'Subscriber ID',
    [SEGMENT_ID_TYPE.USER_ID]: 'User ID',
    [SEGMENT_ID_TYPE.EXTERNAL_IDP_ID]: 'External IDP ID',
};

export const SEGMENT_ID_TYPES_REVERSE = invert(SEGMENT_ID_TYPES);

export const StaticFilterLabels = {
    [SEGMENT_ID_TYPES.SUBSCRIBER]: 'Subscriber',
    [SEGMENT_ID_TYPES.ACCOUNT]: 'Account',
    [SEGMENT_ID_TYPES.USER]: 'User',
    [SEGMENT_ID_TYPES.UNREGISTERED_ID]: 'Unregistered id',
    [SEGMENT_ID_TYPES.MSISDN]: 'MSISDN',
    [SEGMENT_ID_TYPES.ICCID]: 'ICCID',
    [SEGMENT_ID_TYPES.IMSI]: 'IMSI',
    [SEGMENT_ID_TYPES.EMAIL]: 'Email',
    [SEGMENT_ID_TYPES.GOOGLE_PLACE_ID]: 'Google Place ID',
    [SEGMENT_ID_TYPES.ADDRESSBOOK_ID]: 'Addressbook ID',
    [SEGMENT_ID_TYPES.PROVIDER_ID]: 'Provider ID',
    [SEGMENT_ID_TYPES.MARKET_ID]: 'Market ID',
    [SEGMENT_ID_TYPES.ACCOUNT_ID]: 'Account ID',
    [SEGMENT_ID_TYPES.SUBSCRIBER_ID]: 'Subscriber ID',
    [SEGMENT_ID_TYPES.USER_ID]: 'User ID',
    [SEGMENT_ID_TYPES.EXTERNAL_IDP_ID]: 'External IDP ID',
};

export const StaticFilterLabelsReverse = invert(StaticFilterLabels);

export const SEGMENT_ID_TYPE_FOR_MSG_HISTORY = [
    {
        id: SEGMENT_ID_TYPE.USER,
        label: StaticFilterLabel[SEGMENT_ID_TYPE.USER],
    },
    {
        id: SEGMENT_ID_TYPE.EXTERNAL_IDP_ID,
        label: StaticFilterLabel[SEGMENT_ID_TYPE.EXTERNAL_IDP_ID],
    },
    {
        id: SEGMENT_ID_TYPE.SUBSCRIBER,
        label: StaticFilterLabel[SEGMENT_ID_TYPE.SUBSCRIBER],
    },
    {
        id: SEGMENT_ID_TYPE.ACCOUNT,
        label: StaticFilterLabel[SEGMENT_ID_TYPE.ACCOUNT],
    },
    {
        id: 101,
        label: i18n.t('productCatalog.validationRules.UNREGISTERED_EMAIL'),
    },
    {
        id: 102,
        label: i18n.t('productCatalog.validationRules.UNREGISTERED_MSISDN'),
    },
];

export const STATIC_SEGMENT_INPUT_METHODS = {
    IDS: 'manually',
    RANGES: 'range',
};

export enum ADD_ENTRY_VIEW {
    NONE = 'none',
    SINGLE = 'single',
    FILE = 'file',
}

export function validateEntry(type: string, value: string): boolean {
    const rules = [String(value).length > 0];

    if (type === StaticFilterLabels[SEGMENT_ID_TYPES.EMAIL]) {
        const regExp = new RegExp(
            /^(([a-zA-Z0-9_+\-.]+)@([a-zA-Z0-9_\-+]+)(\.([a-zA-Z]{2,5}){1,25})+)*(\s*[,.]\s*(([a-zA-Z0-9_+\-.]+)@([a-zA-Z0-9_\-+]+)(\.([a-zA-Z]{2,5}){1,25})+)*)*$/gm,
        );
        rules.push(regExp.test(value));
    }

    if (
        type === StaticFilterLabels[SEGMENT_ID_TYPES.MSISDN] ||
        type === StaticFilterLabels[SEGMENT_ID_TYPES.IMSI] ||
        type === StaticFilterLabels[SEGMENT_ID_TYPES.ICCID]
    ) {
        rules.push(!isNaN(Number(value)));
    }

    return rules.every(Boolean);
}
