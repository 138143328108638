import { LABEL_COLOR } from '@/common/labelsHelper';

export enum EventStatuses {
    Published = 'Published',
    Draft = 'Draft',
    Unpublished = 'Unpublished',
}

export const EVENT_STATUS_TO_COLOR_MAP = new Map([
    [EventStatuses.Published, LABEL_COLOR.green],
    [EventStatuses.Draft, LABEL_COLOR.yellow],
    [EventStatuses.Unpublished, LABEL_COLOR.red],
]);
