
import { cohortSearchEntry } from '@/__new__/services/dno/segments/http/filters';
import { SEGMENT_ID_TYPES_REVERSE } from '@/common/StaticFilter';

import SearchBox from '@/components/partials/inputs/SearchBox.vue';

export default {
    name: 'SearchUserInSegment',
    components: {
        SearchBox,
    },
    props: {
        segment: { type: Object, required: true },
    },

    data() {
        return {
            isLoading: false,
        };
    },

    methods: {
        search(value: string) {
            if (!value.length) {
                this.$emit('search', null);
                return;
            }

            this.isLoading = true;
            this.$Progress.start();

            cohortSearchEntry(this.segment.id, SEGMENT_ID_TYPES_REVERSE[this.segment.segmentIdType], value)
                .then(({ data }) => {
                    this.$emit('search', {
                        entry: value,
                        isFound: data.result,
                    });
                    this.$Progress.finish();
                })
                .catch(() => {
                    this.$alert(this.$t('alerts.errorFindingIdentifier'));
                    this.$Progress.fail();
                })
                .finally(() => {
                    this.isLoading = false;
                });
        },

        clearSearch(value: string) {
            if (!value.length) {
                this.$emit('search', null);
            }
        },
    },
};
