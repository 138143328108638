import { getMultiLangFieldValueByLocale } from '@/common/entities/entityHelper';

class ProductCatalogCategoryModel {
    id;

    name;

    sortPriority;

    unique;

    state;

    parent;

    version;

    entityVersion;

    miscParams;

    updateTime;

    updateUser;

    constructor(entityJson) {
        this.id = entityJson.id;

        this.name = getMultiLangFieldValueByLocale(entityJson.data.name);

        this.description = entityJson.data.description
            ? getMultiLangFieldValueByLocale(entityJson.data.description)
            : '';

        // eslint-disable-next-line camelcase
        this.sortPriority = entityJson.data?.sort_priority || 1;

        this.unique = entityJson.data?.unique ?? false;

        this.state = entityJson?.state;

        this.parent = entityJson?.data?.parent;

        this.version = entityJson?.version;

        this.updateTime = entityJson.update_time || null;

        this.updateUser = entityJson.update_portal_id || entityJson.portal_id || null;

        this.entityVersion = entityJson?.entityVersion || null;

        // eslint-disable-next-line camelcase
        this.miscParams = entityJson?.data?.misc_params;
    }
}

export default ProductCatalogCategoryModel;
