import NcsBaseCondition from '@/__new__/services/dno/charging/models/chargingSpecificationConditions/NCSbaseCondition';
import { uuidV4 } from '@/common/utils';
import isEmpty from 'lodash/isEmpty';
import { CONDITION_TYPES } from '@/__new__/services/dno/charging/common/chargingSpecificationHelper';
import { DEFAULT_DATA_OBJECT, VlrIdConditionValues } from '@/__new__/services/dno/charging/common/vlrIdConditionHelper';

export default class VlrIdConditionModel extends NcsBaseCondition {
    type: string;
    uuid: string;
    dataValid: boolean;

    constructor(
        { data, uuid, type, dataEmpty = true, dataValid = false } = {
            data: {},
            uuid: uuidV4(),
            type: CONDITION_TYPES.VLR_ID,
            dataEmpty: true,
            dataValid: false,
        },
    ) {
        super();
        this.data = isEmpty(data) ? DEFAULT_DATA_OBJECT : data;
        this.type = type;
        this.uuid = uuid;
        this.dataEmpty = dataEmpty;
        this.dataValid = dataValid;
    }

    validationCondition(): boolean {
        return this.data.src.every(
            (el: VlrIdConditionValues) => el.values.length && el.values.every((el: string) => el !== ''),
        );
    }

    isDataValid(): void {
        this.dataValid = this.validationCondition();
    }
}
