<template>
    <div>
        <form
            @submit.prevent
            @keydown.enter.prevent="loginAction"
        >
            <div class="heading">
                <div v-if="!twoFactorInput">
                    <h1 class="font-weight-bold lf-welcome">
                        {{ $t('login.welcome') }}
                    </h1>
                    <span class="lf-subtitle-blue">{{ $t('login.pleaseLogin') }}</span>
                </div>
                <div v-else>
                    <h1 class="font-weight-bold lf-welcome">
                        {{ $t('login.twoFactorAuth') }}
                    </h1>
                    <p class="lf-subtitle-blue">
                        {{ $t('login.pleaseEnterVerificationCode') }}<br />{{ $t('login.enterYour6DigitCode') }}
                    </p>
                </div>
            </div>
            <!-- Form inputs -->
            <div
                v-if="!twoFactorInput"
                class="login-form"
            >
                <div v-if="localAuthEnabled">
                    <AppInputV3
                        v-model="email"
                        :placeholder="$t('generic.email')"
                        :invalid="$v.email.$error"
                        type="email"
                        class="form-inputs"
                        :errorMessage="$t('login.email')"
                    />

                    <AppInputV3
                        v-model="password"
                        :placeholder="$t('generic.password')"
                        :invalid="$v.password.$error"
                        class="form-inputs"
                        :errorMessage="$t('login.password')"
                        type="password"
                    />
                    <!-- ToDo: Uncomment when forgot password feature will be reworked
                    <span
                        class="text-button"
                        @click="openForgotPassword"
                    >
                        Forgot password
                    </span>
                    -->
                    <div class="button-wrapper">
                        <AppButton
                            :label="$t('login.login')"
                            :buttonType="loginButtonsType"
                            class="login-button"
                            @click="loginAction"
                        />
                    </div>
                </div>
            </div>

            <div
                v-else
                class="two-factor-auth"
            >
                <div class="digit-input mb-5">
                    <div
                        v-for="(digit, index) in otpCodeElements"
                        :key="index"
                        class="single-digit-box"
                    >
                        <label>
                            <input
                                v-model="otpCodeElements[index]"
                                maxlength="1"
                                class="input-separator"
                                @input="moveFocusedInputBox(index)"
                            />
                        </label>
                    </div>
                </div>

                <AppButton
                    :label="$t('login.verify')"
                    :buttonType="loginButtonsType"
                    class="login-button"
                    @click="loginAction"
                />
            </div>
        </form>
        <!-- Auth Strategy selection -->
        <div
            v-if="externalAuthEnabled"
            class="auth-strategy"
        >
            <div
                v-if="localAuthEnabled"
                class="separator"
            >
                <div class="line" />
                <span class="log-in-inline-text">{{ $t('login.loginUsing') }}</span>
                <div class="line" />
            </div>

            <div class="strategy-list">
                <AppButton
                    v-for="item in externalAuthStrategies"
                    :key="item.id"
                    :label="item.text"
                    :buttonType="loginButtonsType"
                    class="auth-button"
                    @click="loginUsing(item.id)"
                />
            </div>
        </div>
    </div>
</template>
<script>
// Vue components
import AppInputV3 from '@/components/partials/inputs/AppInputV3.vue';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';

// Models
import Tenant from '@/models/Tenant';

// HTTP layer
import { authStrategy, login } from '@/__new__/services/portal/auth/http/login';
import { getAzureClientConfig, azurePopUplogin } from '@/__new__/services/portal/auth/http/auth';

// Validation
import { validationMixin } from 'vuelidate';
import { required, email } from 'vuelidate/lib/validators';

// Helpers
import RouteNames from '@/router/routeNames';

// Azure AD popup lib
import * as msal from '@azure/msal-browser';

export default {
    name: 'SignIn',
    components: {
        AppButton,
        AppInputV3,
    },
    mixins: [validationMixin],
    props: {
        loginButtonsType: {
            type: String,
            default: BUTTON_TYPES.PRIMARY,
        },
    },
    data() {
        return {
            email: '',
            password: '',
            otpCodeElements: ['', '', '', '', '', ''],
            // Everyone gets local/email strategy.
            AuthStrategies: {
                local: {
                    id: 'local',
                    text: 'Email',
                    enabled: false,
                },
                azuread: {
                    id: 'azuread',
                    text: this.$t('login.azure'),
                    enabled: false,
                },
                okta: {
                    id: 'okta',
                    text: this.$t('login.okta'),
                    enabled: false,
                },
            },
            selectedAuthStrategy: {
                id: 'local',
                text: 'Email',
                enabled: true,
            },
            // State Flags
            twoFactorInput: false,
            RouteNames,
            BUTTON_TYPES,
        };
    },

    computed: {
        externalAuthStrategies() {
            // we don't need to display AuthStrategies local as additional button
            const enabledStrategies = Object.values(this.AuthStrategies).filter(auth => auth.enabled);
            return enabledStrategies.filter(auth => auth.id !== this.AuthStrategies.local.id);
        },
        externalAuthEnabled() {
            return this.externalAuthStrategies.length > 0;
        },
        localAuthEnabled() {
            return Boolean(this.AuthStrategies.local.enabled);
        },
        isPortalInIframe() {
            return window !== window.parent;
        },
    },

    async created() {
        try {
            const res = await authStrategy();
            // Should be an array of strings
            const authStrategies = res.data.strategies;
            authStrategies.forEach(as => {
                if (Object.prototype.hasOwnProperty.call(this.AuthStrategies, as)) {
                    this.AuthStrategies[as].enabled = true;
                }
            });

            if (authStrategies.includes(this.AuthStrategies.azuread.id) && this.isPortalInIframe) {
                const azureConf = await this.fetchAzureAdPopupConfig();
                if (azureConf) {
                    this.msalConfig = {
                        auth: {
                            clientId: azureConf.clientId,
                            authority: azureConf.authority,
                            redirectUri: `${window.location.href.replace('/#/', '/')}`,
                        },
                    };
                }
            }
        } catch (e) {
            this.$eventBus.$emit('showAlert', {
                message: e.response.data.error || e,
            });
        }
    },

    validations() {
        return {
            email: {
                required,
                email,
            },
            password: {
                required,
            },
        };
    },

    methods: {
        async loginAction() {
            let response = {};

            try {
                this.$v.$touch();
                if (!this.$v.$invalid) {
                    this.$Progress.start();
                    if (this.selectedAuthStrategy.id === this.AuthStrategies.local.id) {
                        if (this.twoFactorInput) {
                            response = await login(this.email, this.password, this.otpCodeElements.join(''));
                        } else {
                            response = await login(this.email, this.password);
                        }
                    } else {
                        // Skip this section
                    }
                    // Two factor mode
                    if (response.status === 202) {
                        this.twoFactorInput = true;
                        this.$nextTick(() => {
                            const firstDigitInput = document
                                .querySelector('.two-factor-auth')
                                .querySelectorAll('input')[0];
                            firstDigitInput.focus();
                        });
                        document.addEventListener('paste', evt => {
                            const cb = evt.clipboardData || window.clipboardData;
                            // Detecting that user has copied to his clipboard a string of 6 characters
                            // which matches our OTP code format so auto-filling that in
                            if (cb.getData('text/plain').length === 6) {
                                this.otpCodeElements = cb.getData('text/plain').split('');
                            }
                        });
                    } else if (response.status === 200 && response?.data?.code === 302 && response?.data?.redirectUrl) {
                        this.loading = false;
                        this.$Progress.finish();
                        this.$eventBus.$emit('showAlert', {
                            message: this.$i18n.t('alertMessage.login.redirectingToNewPortal'),
                        });
                        window.location.replace(response.data.redirectUrl);
                    } else {
                        const activeTenant = new Tenant({
                            id: response.data.companyId,
                            name: response.data.companyName,
                            dnoIdentifier: response.data.dnoIdentifier,
                            operatorId: response.data.operatorId,
                        });
                        activeTenant.store();
                        this.$Progress.finish();
                        window.location.reload();
                    }
                } else {
                    this.$eventBus.$emit('showAlert', {
                        message: this.$i18n.t('alertMessage.pleaseFixValidation'),
                    });
                }
            } catch (e) {
                this.$eventBus.$emit('showAlert', {
                    message: e?.response?.data?.error,
                });
                this.$Progress.fail();
            }
        },
        openForgotPassword() {
            this.$emit('forgotPassword');
        },
        async loginUsing(authType) {
            if (authType === this.AuthStrategies.azuread.id && this.isPortalInIframe) {
                this.msal = new msal.PublicClientApplication(this.msalConfig);
                await this.msal.initialize();
                await this.signIntoAzure();
                window.location.assign('/');
            } else {
                window.location.href = `/oauth/login?provider=${encodeURIComponent(authType)}`;
            }
        },
        moveFocusedInputBox(index) {
            if (index + 1 < this.otpCodeElements.length) {
                const digitInput = document.querySelector('.two-factor-auth').querySelectorAll('input')[index + 1];
                digitInput.focus();
            }
        },
        async signIntoAzure() {
            try {
                const accounts = this.msal.getAllAccounts();
                const loginResponse = await this.msal.ssoSilent(accounts[0]);
                await azurePopUplogin(loginResponse);
                return loginResponse;
            } catch (err) {
                const loginPopUpResponse = await this.msal.loginPopup();
                await azurePopUplogin(loginPopUpResponse);
                return null;
            }
        },
        async fetchAzureAdPopupConfig() {
            try {
                this.$Progress.start();
                const configResponse = await getAzureClientConfig();
                this.$Progress.finish();
                return configResponse?.data;
            } catch (err) {
                this.$eventBus.$emit('showAlert', {
                    message: err,
                });
                this.$Progress.fail();
                return null;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/palette';
@import '~@/assets/scss/typographyV2.scss';
@import '~@/assets/scss/layout';

h1 {
    margin: 0;
}

.lf-welcome {
    font-family: $font-primary;
    font-size: $text-xxl;
    font-weight: $bold-font-weight;
    line-height: $normal-line-height;
    letter-spacing: normal;
    color: $blue;
    display: flex;

    @media screen and (prefers-color-scheme: dark) {
        color: #fff;
    }

    @media screen and (max-width: 1024px) {
        font-size: 2rem;
    }
}

.lf-subtitle-blue {
    display: flex;
    margin-left: 0.5rem;

    @media screen and (prefers-color-scheme: dark) {
        color: #fff;
    }
}

.form-inputs {
    padding-bottom: $spacing-m;
    max-width: 400px;
    width: 100%;

    @media screen and (max-width: 1024px) {
        max-width: 100%;
    }
}

.heading {
    margin-bottom: 2rem;
    @media screen and (max-width: 680px) {
        text-align: center;
    }
}

.login-form {
    display: flex;
    flex-direction: column;
    width: 100%;

    @media screen and (max-width: 680px) {
        justify-content: center;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

.strategy-list {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-top: $spacing-xl;
}

.login-button {
    margin-top: 10px;
    width: 100%;
    margin-bottom: 57px;
}

.auth-button {
    width: 100%;
}
.auth-button:not(:first-child) {
    margin-left: 1rem;
}

.separator {
    display: flex;
    align-items: center;
}

.separator h2 {
    padding: 0 2rem; /* creates the space */
}

.separator .line {
    flex: 1;
    height: 1px;
    background-color: $gray5;
}

.text-button {
    display: flex;
    color: $white;
    text-decoration: underline;
    cursor: pointer;

    &.blue {
        color: $blueDark20;
    }
}

.log-in-inline-text {
    width: 82px;
    height: 18px;
    margin: 0 25px 0 24px;
    font-family: 'Open Sans';
    font-size: 13px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #aeaeae;
}

.image-container {
    height: 100vh;
    box-sizing: border-box;
    width: 50%;
    max-width: 704px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    @media screen and (max-width: 1024px) {
        max-width: 100%;
        width: 100%;
        margin-top: 3rem;
    }
}

.lf-image {
    height: 100%;
    width: 100%;
    min-height: 150px;
    background: url('../../../assets/images/login/illustration-login.svg') no-repeat center;
    background-size: contain;

    @media screen and (prefers-color-scheme: light) {
        background: url('../../../assets/images/login/illustration-login.svg') no-repeat center;
        background-size: contain;
    }
}

.lf-logo {
    width: 252.3px;
    height: 44.2px;
    margin-bottom: 172px;

    @media screen and (max-width: 680px) {
        width: 182.3px;
        height: 44.2px;
        margin-bottom: 69px;
    }
}

@media screen and (prefers-color-scheme: dark) {
    .lf-logo {
        background: url('../../../assets/images/login/logo-color-1.svg') no-repeat center;
        background-size: contain;
    }
}

@media screen and (prefers-color-scheme: light) {
    .lf-logo {
        background: url('../../../assets/images/login/logo-color-2.svg') no-repeat center;
        background-size: contain;
    }
}

.two-factor-auth {
    max-width: 400px;
    width: 100%;
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 1024px) {
        max-width: 100%;
        margin: 0 auto;
    }
}

.digit-input {
    display: flex;
    justify-content: space-between;
}

.single-digit-box {
    input {
        width: 3.5rem;
        height: 3.5rem;
        border-radius: 0.5rem;
        border: solid 1px $gray5;
        background-color: $white;
        text-align: center;
        font-size: 1.75rem;
        color: $blue;
    }
}

.auth-strategy {
    max-width: 400px;
    width: 100%;

    @media screen and (max-width: 1024px) {
        max-width: 100%;
        margin: 0 auto;
    }
}

.button-wrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 400px;

    @media screen and (max-width: 1024px) {
        width: 100%;
        max-width: 100%;
    }
}

.input-separator {
    margin-right: 13px;
    margin-bottom: 44px;
}
</style>
