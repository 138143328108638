export type MonacoEditorMarkerData = {
    resource: Record<string, any>;
    owner: string;
    code?: string | Record<string, any>;
    message: string;
    severity: number;
    source?: string;
    startLineNumber: number;
    startColumn: number;
    endLineNumber: number;
    endColumn: number;
    relatedInformation?: any[];
    tags?: any;
};

function importMonacoLoader() {
    if (document.getElementById('loader-min')) {
        return null;
    }
    return new Promise<void>((resolve, reject) => {
        const script = document.createElement('script');
        script.id = 'loader-min';
        script.onload = () => {
            resolve();
        };
        script.onerror = message => {
            reject(message);
        };
        script.src = 'https://cdnjs.cloudflare.com/ajax/libs/monaco-editor/0.30.1/min/vs/loader.min.js';
        document.getElementsByTagName('head')[0].appendChild(script);
    });
}

function importMonacoEditorMain() {
    if (document.getElementById('editor-main')) {
        return null;
    }
    return new Promise<void>((resolve, reject) => {
        const script = document.createElement('script');
        script.id = 'editor-main';
        script.onload = () => {
            resolve();
        };
        script.onerror = message => {
            reject(message);
        };
        script.innerHTML = `require.config({ paths: { 'vs': 'https://cdnjs.cloudflare.com/ajax/libs/monaco-editor/0.30.1/min/vs' } });
                require(['vs/editor/editor.main'], () => {
                    document.getElementById('editor-main').dispatchEvent(new Event('load'));
                });`;
        document.getElementsByTagName('head')[0].appendChild(script);
    });
}

async function importMonaco() {
    await importMonacoLoader();
    await importMonacoEditorMain();
}

export default {
    importMonaco,
};
