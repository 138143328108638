<template>
    <AbstractListPageWrapper
        :isOverviewEnabled="isOverviewEnabled"
        :pageTitle="pageTitle"
        :entitiesCount="formattedFilteredEntities.length"
        @searchQueryChanged="setSearchQuery"
    >
        <template slot="button">
            <ResponseModalButton
                :response="prizesApiResponse"
                :title="pageTitle"
            />
        </template>

        <template slot="filterTable">
            <FilterTable
                :columns="tableColumnsData"
                :multiselectWidth="{ width: '13rem' }"
                @filterAdded="onFilterAdded"
            />
        </template>

        <template slot="headerButtons">
            <AppButton
                :buttonType="BUTTON_TYPES.PRIMARY"
                :iconType="ICON_TYPES.PLUS"
                :label="$i18n.t('rewards.newPrize')"
                :disabled="!writeEnabled"
                @click="createPrize"
            />
        </template>

        <template slot="allFilters">
            <TableFiltersTags
                :filterTableMixin="filterTableMixin"
                class="my-3 ml-2"
                @removeFilter="removeFilter"
                @removeAllFilters="removeAllFilters"
            />
        </template>

        <template slot="table">
            <AppTable
                :entities="formattedFilteredEntities"
                :canSelectColumns="true"
                :canSort="true"
                :selectedEntityId="selectedEntityId"
                :innerSearchQuery="searchQueryForTable"
                :isSearchEnabled="true"
                :isInnerSearchQueryPassed="false"
                :enableRowStateControls="writeEnabled"
                :entityType="entityType"
                :columnsData="tableColumnsData"
                :isDataLoading="isDataLoading"
                tableKey="prize"
                data-test-id="app-table"
                @selectEntity="onEntitySelected"
            >
                <template #stateLabel="{ entity }">
                    <EntityStatusIndicator :status="entity.state" />
                </template>
                <template #entityVersion="{ entity }">
                    <EntityStatusIndicator :status="entity.entityVersion" />
                </template>
            </AppTable>
        </template>

        <template slot="overview">
            <EntityOverview
                :entity="selectedEntity"
                :entityType="entityType"
                @closeOverview="isOverviewEnabled = false"
            >
                <div :slot="`section-1-header`">
                    <OverviewHeader
                        v-if="selectedEntityId"
                        :entityName="getMultiLangFieldValueByLocale(selectedEntity.data.name, selectedLanguage)"
                        :entityType="entityType"
                    />
                    <LanguageSwitcher v-model="selectedLanguage" />
                </div>
                <div :slot="`section-1-content`">
                    <OverviewList :lists="detailsSection" />
                    <!-- ToDo: Make a separate card component -->
                    <div class="card-container">
                        <div
                            v-t="'rewards.prizeWinningRules'"
                            class="card-title"
                        />
                        <ul class="card-list">
                            <li class="card-content">
                                {{ maxPurchasesPerDurationOverall }}
                            </li>
                            <li class="card-content">
                                {{ maxPurchases }}
                            </li>
                        </ul>
                    </div>
                    <div class="card-container">
                        <div
                            v-t="'rewards.prizeWinningRulesPerUser'"
                            class="card-title"
                        />
                        <ul class="card-list">
                            <li class="card-content">
                                {{ maxPurchasesPerSubscriber }}
                            </li>
                            <li class="card-content">
                                {{ maxPurchasesPerUser }}
                            </li>
                        </ul>
                    </div>
                </div>
            </EntityOverview>
        </template>
    </AbstractListPageWrapper>
</template>

<script>
// Vuex
import { mapActions, mapGetters } from 'vuex';
import Actions, { Getters, State } from '@/store/mutation-types';

// Components
import AbstractListPageWrapper from '@/components/layout/AbstractListPageWrapper.vue';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import { ICON_TYPES } from '@/common/iconHelper';
import AppTable from '@/components/partials/AppTable.vue';
import EntityOverview from '@/components/partials/entityOverview/EntityOverview.vue';
import OverviewHeader from '@/components/partials/entityOverview/OverviewHeader.vue';
import OverviewList from '@/components/partials/entityOverview/OverviewList.vue';
import EntityStatusIndicator from '@/components/partials/EntityStatusIndicator.vue';
import FilterTable from '@/components/partials/FilterTable.vue';
import TableFiltersTags from '@/components/filters/TableFiltersTags.vue';
import ResponseModalButton from '@/components/partials/ResponseModalButton.vue';

// Helpers
import ENTITY_TYPES from '@/common/entities/entityTypes';
import RouteNames from '@/router/routeNames';
import { rewardTimeUnit } from '@/modules/rewards/common/RewardsHelper';
import { getPayoutType } from '@/modules/rewards/common/payoutsHelper';
import { onlyFirstLetterUppercase } from '@/common/utils';
import { StateActionsMapping, entityStateReverseMap } from '@/modules/rewards/common/entityStateMapper';
import { getMultiLangFieldValueByLocale } from '@/common/entities/entityHelper';
import moment from 'moment';
import FilterTableMixin from '@/components/partials/FilterTableMixin.vue';
import tableColumnType from '@/common/filterTable';
import LanguageSwitcher from '@/components/partials/inputs/LanguageSwitcher.vue';
import { languageMap } from '@/common/locale/language';
import permissionsService, { isUserAllowed } from '@/services/permissions/permissions.service';
import { STATUS_CODES } from '@/common/commonHelper';

export default {
    name: 'Prizes',
    components: {
        AbstractListPageWrapper,
        AppButton,
        AppTable,
        EntityOverview,
        OverviewHeader,
        OverviewList,
        EntityStatusIndicator,
        FilterTable,
        LanguageSwitcher,
        TableFiltersTags,
        ResponseModalButton,
    },
    mixins: [FilterTableMixin],
    data() {
        return {
            pageTitle: this.$i18n.t('rewards.prizes'),
            searchQueryForTable: '',
            isDataLoading: false,
            selectedEntity: null,
            selectedEntityId: null,
            selectedLanguage: '',
            activeLocale: this.languageDefault,
            ICON_TYPES,
            BUTTON_TYPES,
            ENTITY_TYPES,
            rewardTimeUnit,
            entityType: ENTITY_TYPES.PRIZE,
            getMultiLangFieldValueByLocale,
            isOverviewEnabled: false,
            sectionsList: [
                {
                    id: 1,
                    name: this.$i18n.t('generic.details'),
                    icon: 'details',
                },
                {
                    id: 2,
                    name: this.$i18n.t('rewards.prizes'),
                    icon: 'Prizes',
                },
            ],
            isRewardsDraftEnabled: permissionsService.isRewardsDraftEnabled(),
        };
    },
    computed: {
        ...mapGetters('rewards', [
            Getters.GET_NOT_DELETED_REWARDS_ENTITIES_BY_TYPE,
            Getters.GET_REWARDS_ENTITY_BY_TYPE_AND_ID,
            Getters.GET_REWARDS_ENTITIES_API_RESPONSE_BY_TYPE,
        ]),
        ...mapGetters('operators', [Getters.languageDefault, Getters.languagesWithoutDefault]),
        languages() {
            return this.$store.state.operators[State.Languages];
        },
        prizes() {
            return this[Getters.GET_NOT_DELETED_REWARDS_ENTITIES_BY_TYPE](ENTITY_TYPES.PRIZE);
        },
        prizesApiResponse() {
            return this[Getters.GET_REWARDS_ENTITIES_API_RESPONSE_BY_TYPE](ENTITY_TYPES.PRIZE);
        },
        allowedActions() {
            return StateActionsMapping[this.selectedEntity.state];
        },
        prizeMaxPurchaseEnabled() {
            return this.selectedEntity && this.selectedEntity.data.max_purchases;
        },
        prizeMaxPurchasePerUserEnabled() {
            return this.selectedEntity && this.selectedEntity.data.max_purchases_per_user;
        },
        prizeMaxPurchasePerDurationPerUserEnabled() {
            return (
                this.selectedEntity &&
                this.selectedEntity.data.max_purchases_per_duration &&
                this.selectedEntity.data.max_purchases_per_duration.per_subscriber
            );
        },
        prizeMaxPurchasePerDurationOverallEnabled() {
            return (
                this.selectedEntity &&
                this.selectedEntity.data.max_purchases_per_duration &&
                this.selectedEntity.data.max_purchases_per_duration.overall
            );
        },
        maxPrizeAmount() {
            if (this.selectedEntity) {
                return this.selectedEntity.data.max_purchases_per_duration.overall.max;
            }
            return this.$i18n.t('generic.N/A');
        },
        maxPrizeAmountPerUser() {
            if (this.selectedEntity) {
                return this.selectedEntity.data.max_purchases_per_duration.per_subscriber.max;
            }
            return this.$i18n.t('generic.N/A');
        },
        numberOfDurations() {
            if (this.selectedEntity) {
                return this.selectedEntity.data.max_purchases_per_duration.overall.number_of_durations;
            }
            return this.$i18n.t('generic.N/A');
        },
        numberOfDurationsPerUser() {
            if (this.selectedEntity) {
                return this.selectedEntity.data.max_purchases_per_duration.per_subscriber.number_of_durations;
            }
            return this.$i18n.t('generic.N/A');
        },
        duration() {
            if (this.selectedEntity) {
                return this.$i18n.t(
                    this.rewardTimeUnit.get(this.selectedEntity.data.max_purchases_per_duration.overall.duration),
                );
            }
            return this.$i18n.t('generic.N/A');
        },
        durationPerUser() {
            if (this.selectedEntity) {
                return this.$i18n.t(
                    this.rewardTimeUnit.get(
                        this.selectedEntity.data.max_purchases_per_duration.per_subscriber.duration,
                    ),
                );
            }
            return this.$i18n.t('generic.N/A');
        },
        totalNumber() {
            if (this.selectedEntity) {
                return this.selectedEntity.data.max_purchases;
            }
            return this.$i18n.t('generic.N/A');
        },
        totalNumberPerUser() {
            if (this.selectedEntity) {
                return this.selectedEntity.data.max_purchases_per_user;
            }
            return this.$i18n.t('generic.N/A');
        },
        // Formatting strings for displaying
        maxPurchasesPerDurationOverall() {
            return this.prizeMaxPurchasePerDurationOverallEnabled
                ? `${this.$i18n.t('rewards.prizeCanBeWon')} ${this.maxPrizeAmount} ${this.$i18n.t('rewards.timesIn')} ${
                      this.numberOfDurations
                  } ${this.duration}`
                : this.$i18n.t('prize.maximumWinningsPerDurationNotConfigured');
        },
        maxPurchases() {
            return this.prizeMaxPurchaseEnabled
                ? `${this.$i18n.t('rewards.prizeCanBeWon')} ${this.totalNumber} ${this.$i18n.t(
                      'rewards.timesIn',
                  )} ${this.$i18n.t('rewards.total')}`
                : this.$i18n.t('prize.maximumOverallWinningsNotConfigured');
        },
        maxPurchasesPerSubscriber() {
            return this.prizeMaxPurchasePerDurationPerUserEnabled
                ? `${this.$i18n.t('rewards.prizeCanBeWon')} ${this.maxPrizeAmountPerUser} ${this.$i18n.t(
                      'rewards.timesIn',
                  )} ${this.numberOfDurationsPerUser} ${this.durationPerUser}`
                : this.$i18n.t('prize.maximumWinningsPerDurationNotConfigured');
        },
        maxPurchasesPerUser() {
            return this.prizeMaxPurchasePerUserEnabled
                ? `${this.$i18n.t('rewards.prizeCanBeWon')} ${this.totalNumberPerUser} ${this.$i18n.t(
                      'rewards.timesIn',
                  )} ${this.$i18n.t('rewards.total')}`
                : this.$i18n.t('prize.maximumOverallWinningsNotConfigured');
        },
        // Overview sections
        generalDetails() {
            const selectedEntityData = this.selectedEntity.data;
            return {
                name: this.$i18n.t('generic.general'),
                properties: [
                    {
                        value: this.selectedEntity.id,
                        label: this.$i18n.t('generic.id'),
                    },
                    {
                        value: selectedEntityData.name[this.selectedLanguage] || this.$i18n.t('generic.N/A'),
                        label: this.$i18n.t('generic.name'),
                    },
                    {
                        value: selectedEntityData?.description
                            ? selectedEntityData.description[this.selectedLanguage]
                            : this.$i18n.t('generic.N/A'),
                        label: this.$i18n.t('generic.description'),
                    },
                    {
                        value: selectedEntityData.notes,
                        label: this.$i18n.t('generic.operatorNotes'),
                    },
                ],
            };
        },
        prizeParameterDetails() {
            const payout = this[Getters.GET_REWARDS_ENTITY_BY_TYPE_AND_ID](
                ENTITY_TYPES.REWARD_PAYOUT,
                this.selectedEntity.data.payout_id,
            );
            const { name: payoutName, payout_type: payoutTypeKey } = payout?.data || {};
            return {
                name: this.$i18n.t('rewards.prizeParameters'),
                properties: [
                    {
                        value: payout?.id || this.$i18n.t('generic.N/A'),
                        label: this.$i18n.t('generic.payoutId'),
                    },
                    {
                        value: getMultiLangFieldValueByLocale(payoutName),
                        label: this.$i18n.t('generic.payoutName'),
                    },
                    {
                        value: this.$i18n.t(getPayoutType(payoutTypeKey)),
                        label: this.$i18n.t('generic.payoutType'),
                    },
                ],
            };
        },
        formattedFilteredEntities() {
            return this.filteredEntitiesMixin(this.formattedEntities);
        },
        formattedEntities() {
            return this.prizes.map(entity => {
                const { name, description } = entity.data;
                return {
                    ...entity,
                    id: entity.id,
                    name: getMultiLangFieldValueByLocale(name),
                    description,
                    maxPurchases: entity.data.max_purchases,
                    maxPurchasesPerUser: entity.data.max_purchases_per_user,
                    stateLabel: this.entityState(entity.state),
                    updatedTimeDisplay: Object.prototype.hasOwnProperty.call(entity, 'update_time')
                        ? this.$localeLibrary.getFormattedDate(entity.update_time)
                        : this.$i18n.t('generic.N/A'),
                    updatedTime: Object.prototype.hasOwnProperty.call(entity, 'update_time')
                        ? moment.unix(entity.update_time)
                        : null,
                };
            });
        },
        languageDefault() {
            return (this.$store.getters[`operators/${Getters.languageDefault}`] || languageMap.en.key).toString();
        },
        descriptionAvailable() {
            if (!this.selectedEntity.data.description[this.activeLocale]) {
                return this.$i18n.t('generic.N/A');
            }
            return this.selectedEntity.data.description[this.activeLocale];
        },
        detailsSection() {
            if (!this.selectedEntityId || !this.prizes.length) {
                return [];
            }
            return [this.generalDetails, this.prizeParameterDetails];
        },
        tableColumnsData() {
            const columns = [
                {
                    name: this.$i18n.t('generic.name'),
                    key: 'name',
                    field: 'name',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('rewards.maxPayouts'),
                    key: 'maxPurchases',
                    field: 'maxPurchases',
                    filterType: tableColumnType.NUMBER,
                },
                {
                    name: this.$i18n.t('rewards.perUser'),
                    key: 'maxPurchasesPerUser',
                    field: 'maxPurchasesPerUser',
                    filterType: tableColumnType.NUMBER,
                },
                {
                    name: this.$i18n.t('generic.updatedAt'),
                    key: 'updatedTimeDisplay',
                    field: 'updatedTime',
                    sortBy: entity => entity.updatedTime.toDate(),
                    filterType: tableColumnType.DATE,
                },
                {
                    name: this.$i18n.t('generic.state'),
                    key: 'stateLabel',
                    field: 'stateLabel',
                    filterType: tableColumnType.TEXT_LIMITED_OPTIONS,
                    limitedOptions: Array.from(new Set(this.formattedFilteredEntities.map(e => e.stateLabel))),
                },
            ];

            if (this.isRewardsDraftEnabled) {
                columns.push({
                    name: this.$i18n.t('generic.version'),
                    key: 'entityVersion',
                    field: 'entityVersionLabel',
                    filterType: tableColumnType.TEXT_LIMITED_OPTIONS,
                    limitedOptions: [
                        ...new Set(this.formattedFilteredEntities.map(entity => entity.entityVersionLabel)),
                    ],
                });
            }

            return columns;
        },
        writeEnabled() {
            return isUserAllowed('PrizesWrite') && permissionsService.rewardsEnabled();
        },
    },
    async created() {
        try {
            this.$Progress.start();
            this.isDataLoading = true;
            await Promise.all([
                this[Actions.REQUEST_REWARDS_ENTITIES_BY_TYPE](ENTITY_TYPES.PRIZE),
                this[Actions.REQUEST_REWARDS_ENTITIES_BY_TYPE](ENTITY_TYPES.REWARD_PAYOUT),
            ]);
            this.selectedLanguage = this[Getters.languageDefault];
            this.$Progress.finish();
        } catch (error) {
            this.$Progress.fail();
            this.$eventBus.$emit('showAlert', {
                message: this.$i18n.t('alertMessage.failedToLoadNecessaryData'),
            });
        } finally {
            this.isDataLoading = false;
        }
    },
    methods: {
        ...mapActions('rewards', [Actions.REQUEST_REWARDS_ENTITIES_BY_TYPE]),
        setSearchQuery(query) {
            this.searchQueryForTable = query;
        },
        entityState(s) {
            return s && s !== STATUS_CODES.NA
                ? this.$i18n.t(`finalStateMapper.${onlyFirstLetterUppercase(entityStateReverseMap(s))}`)
                : this.$i18n.t('generic.N/A');
        },
        onEntitySelected(id) {
            this.isOverviewEnabled = true;
            this.selectedEntity = this[Getters.GET_REWARDS_ENTITY_BY_TYPE_AND_ID](this.entityType, id);
            this.selectedEntityId = id;
        },
        createPrize() {
            this.$router.push({ name: RouteNames.PRIZE_EDITOR, params: { companyId: this.$route.params.companyId } });
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/palette';

.card-container {
    margin: 24px;
    padding: 10px 16px;
    border-radius: 8px;
    background-color: rgba($color: $blue, $alpha: 0.15);

    .card-title {
        color: $blue;
        font-size: 0.75rem;
        font-weight: 600;
    }

    .card-list {
        .card-content {
            font-size: 0.875rem;
            color: $gray90;
        }

        li::before {
            content: '•';
            width: 4px;
            height: 4px;
            margin-right: 6px;
            color: rgba($color: $blue, $alpha: 0.5);
        }
    }
}
</style>
