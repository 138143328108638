
// Components
import AbstractTableTile from '@/__new__/features/customerCareSuite/components/AbstractTableTile.vue';
import EntityStatusIndicator from '@/components/partials/EntityStatusIndicator.vue';
import IconButton from '@/components/partials/IconButton.vue';
import AdditionalSidebarForCharges from '@/__new__/features/customerCareSuite/components/AdditionalSidebarForCharges.vue';

// Helpers
import * as Sentry from '@sentry/vue';
import supportButtonMixin from '@/components/alerts/supportButtonMixin';
import tableColumnType from '@/common/filterTable';
import { formatAmountBasedOnCurrency } from '@/common/formatting';
import {
    CHARGE_STATES_NEXT_GEN,
    CHARGE_STATES_INDICATOR_MAP_NEXT_GEN,
    CHARGE_STATES_TO_STATUS_NAME_MAP_NEXT_GEN,
} from '@/common/userManager/transactionHistoryV4StatusHelper';
import { USER_MANAGER_HIERARCHY } from '@/__new__/features/customerCare/common/customerCareHelper';
import { ICON_TYPES } from '@/common/iconHelper';
import { isViewEnabled } from '@/services/permissions/permissions.service';
import {
    BILLING_TRX_PAYMENT_TYPES,
    BILLING_TRX_TYPE_TO_LABEL,
    TRANSACTION_TYPE,
    getTransactionTypeLabel,
    TRANSACTION_TYPE_TO_LABEL,
} from '@/__new__/features/customerCareSuite/common/transactionHelper';

// HTTP
import ordersHTTP from '@/__new__/services/dno/orders/http/orders';
import customerCareHTTP from '@/__new__/services/dno/user/http/customer-care';

export default {
    name: 'BillingTransactionTile',
    components: {
        AbstractTableTile,
        EntityStatusIndicator,
        IconButton,
        AdditionalSidebarForCharges,
    },
    mixins: [supportButtonMixin],
    data() {
        return {
            transactionHistory: [],
            rawResponse: {},

            showSidebar: false,
            selectedEntityId: '' as string,

            ICON_TYPES,
            CHARGE_STATES_INDICATOR_MAP_NEXT_GEN,
            CHARGE_STATES_TO_STATUS_NAME_MAP_NEXT_GEN,
        };
    },
    computed: {
        // Table related properties
        selectedEntity(): any {
            return this.selectedEntityId
                ? this.transactionHistory.find(tx => tx.id === this.selectedEntityId)
                : undefined;
        },
        columnsData() {
            return [
                {
                    name: this.$i18n.t('customerCare.transactionHistory.transactionId'),
                    key: 'id',
                    field: 'id',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('generic.type'),
                    key: 'typeFormatted',
                    field: 'typeFormatted',
                    filterType: tableColumnType.TEXT_LIMITED_OPTIONS,
                    limitedOptions: Array.from(new Set(this.transactionHistory.map(entity => entity.typeFormatted))),
                },
                {
                    name: this.$i18n.t('customerCare.transactionHistory.timestamp'),
                    key: 'timestamp',
                    mapper: entity => {
                        if (entity) {
                            return this.$localeLibrary.getFormattedDateAndTimeWithSecondsPrecision(entity.timestamp);
                        }

                        return '';
                    },
                    sortBy: entity => entity.create_timestamp,
                    field: 'timestamp',
                    filterType: tableColumnType.DATE,
                },
                {
                    name: this.$i18n.t('customerCare.transactionHistory.amount'),
                    key: 'amountFormatted',
                    sortBy: entity => entity.amount,
                    field: 'amount',
                    filterType: tableColumnType.NUMBER,
                },
                {
                    name: this.$i18n.t('customerCare.transactionHistory.originalAmount'),
                    key: 'originalAmount',
                    sortBy: entity => entity.originalAmount,
                    field: 'originalAmount',
                    filterType: tableColumnType.NUMBER,
                },
                {
                    name: this.$i18n.t('customerCare.transactionHistory.discount'),
                    key: 'discount',
                    sortBy: entity => entity.discount,
                    field: 'discount',
                    filterType: tableColumnType.NUMBER,
                },
                {
                    name: this.$i18n.t('customerCare.transactionHistory.externalTransactionId'),
                    key: 'externalTransactionId',
                    field: 'externalTransactionId',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('generic.status'),
                    key: 'state',
                    field: 'stateLabel',
                    filterType: tableColumnType.TEXT_LIMITED_OPTIONS,
                    limitedOptions: Array.from(new Set(this.transactionHistory.map(entity => entity.stateLabel))),
                },
                {
                    name: this.$i18n.t('customerCare.transactionHistory.transactionType'),
                    key: 'transactionType',
                    field: 'transactionType',
                    filterType: tableColumnType.TEXT_LIMITED_OPTIONS,
                    limitedOptions: Array.from(new Set(this.transactionHistory.map(entity => entity.transactionType))),
                },
                {
                    name: this.$i18n.t('customerCare.transactionHistory.failureReason'),
                    key: 'failureReason',
                    field: 'failureReason',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
            ];
        },
    },
    async created() {
        await this.fetchTileData();
    },
    methods: {
        onShowSidebar(id: string) {
            const transaction = this.transactionHistory.find(tx => tx.id === id);
            if (this.checkIfRefundAvailable(transaction)) {
                this.selectedEntityId = id;
                this.showSidebar = true;
            } else {
                this.selectedEntityId = '';
                this.showSidebar = false;
            }
        },

        onCloseSidebar() {
            this.showSidebar = false;
            this.selectedEntityId = '';
        },
        async fetchTileData() {
            try {
                this.$Progress.start();
                this.$emit('isDataLoadingUpd', true);
                this.rawResponse = await ordersHTTP.getPaymentTransactionsDetails(
                    this.$route.params.id,
                    USER_MANAGER_HIERARCHY.ACCOUNT,
                );
                const transactions = this.rawResponse?.data?.payment_transactions_details;
                if (transactions) {
                    this.transactionHistory = transactions.map(el => ({
                        id: el.billing_payment_id,
                        targetType: el.target_type,
                        timestamp: el.payment_date,
                        amount: Number(el.payment_amount),
                        amountFormatted:
                            formatAmountBasedOnCurrency(Number(el.payment_amount), el?.currency) ||
                            this.$i18n.t('generic.empty'),
                        originalAmount: el?.additional_info?.original_amount,
                        discount: el?.additional_info?.discount_amount,
                        state: el.payment_status,
                        stateLabel: this.CHARGE_STATES_TO_STATUS_NAME_MAP_NEXT_GEN.get(el.payment_status),
                        billingId: el?.metadata?.bill_id,
                        typeFormatted: BILLING_TRX_TYPE_TO_LABEL.get(el?.payment_type) || this.$i18n.t('generic.N/A'),
                        paymentType: el?.payment_type,
                        billingPaymentId: el?.billing_payment_id,
                        targetId: el?.target_id,
                        billId:
                            el?.payment_type === BILLING_TRX_PAYMENT_TYPES.DIRECT_BILL_PAYMENT
                                ? el?.additional_info?.entity_id
                                : '',
                        orderId:
                            el?.payment_type === BILLING_TRX_PAYMENT_TYPES.IMMEDIATE_PAYMENT
                                ? el?.additional_info?.entity_id
                                : '',
                        transactionType: getTransactionTypeLabel(el.transaction_type),
                        externalTransactionId: el?.payment_response?.external_transaction_id,
                        failureReason:
                            el.payment_status === CHARGE_STATES_NEXT_GEN.FAILED ? el?.original_error?.msg : '',
                    }));
                }
                this.$Progress.finish();
            } catch (error) {
                Sentry.captureException(error);
                this.$Progress.fail();
                this.$emit('dataFetchingError');
            } finally {
                this.$emit('isDataLoadingUpd', false);
            }
        },
        async onDownloadButtonClicked(entity) {
            const { billingId } = entity;
            this.$Progress.start();
            try {
                const response = await customerCareHTTP.getInvoice(
                    this.$route.params.id,
                    USER_MANAGER_HIERARCHY.ACCOUNT,
                    billingId,
                );

                if (response?.data?.url) {
                    window.open(response.data.url, '_blank');
                }

                this.$Progress.finish();
            } catch (error) {
                this.$alert(this.$i18n.t('customerCare.billingStatementDownloadError'));
                this.$Progress.fail();
            }
        },
        checkIfRefundAvailable(entity) {
            if (!isViewEnabled('BillingTransactionTileNextGenRefund')) {
                return false;
            }
            if (
                !entity?.paymentType ||
                entity?.state !== CHARGE_STATES_NEXT_GEN.SUCCESS ||
                entity?.transactionType === TRANSACTION_TYPE_TO_LABEL[TRANSACTION_TYPE.RESERVE]
            ) {
                return false;
            }
            return [
                BILLING_TRX_PAYMENT_TYPES.DIRECT_BILL_PAYMENT,
                BILLING_TRX_PAYMENT_TYPES.IMMEDIATE_PAYMENT,
            ].includes(entity?.paymentType);
        },
        openRefund(entity) {
            this.refundModal = true;
            this.selectedTransaction = entity;
        },
        onRefundModalClose() {
            this.refundModal = false;
            this.selectedTransaction = {};
        },
    },
};
