import { DataflowSection, SectionElement, SECTION_TYPES } from '@/__new__/features/dataflows/common/DataflowInterfaces';

export function createDataflowApiPayloadData(dataflows: any[], initSectionId?: string): Record<string, string> {
    let payloadData: Record<string, string> = {};
    dataflows.forEach((section: DataflowSection) => {
        const sectionId = initSectionId ? `${initSectionId}.${section.id}` : section.id;
        if (section.collapsed === false) {
            section.elements.forEach((data: SectionElement) => {
                const objectId = `${sectionId}.${data.id}`;
                if (data.type === SECTION_TYPES.SECTION) {
                    payloadData = {
                        ...payloadData,
                        ...createDataflowApiPayloadData([data], sectionId),
                    };
                } else {
                    const getElementData =
                        typeof data.value == 'string' || typeof data.value == 'number'
                            ? data.value
                            : data.value?.value || null;

                    if (getElementData) {
                        payloadData[objectId] = getElementData;
                    }
                }
            });
        }
    });

    return payloadData;
}

export function convertDataflowNameOfId(name: string): string {
    return name.replace(/\s+/g, '_').toLowerCase();
}
