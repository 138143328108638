import httpClient, { DnoResponseBase, Entity, EntityState } from '@/http/index';
import { AxiosPromise } from 'axios';
import { CycleSpecification } from '@/__new__/services/dno/billing/models/cycleSpecificationDno';
import ENTITY_TYPES from '@/common/entities/entityTypes';

export interface CycleSpecificationsResponse extends DnoResponseBase {
    cycle_specification_by_id: Record<string, Entity<CycleSpecification>>;
}

export const getCycleSpecifications = (): AxiosPromise<CycleSpecificationsResponse> => {
    return httpClient({
        method: 'GET',
        url: `/demo-entity/${ENTITY_TYPES.CYCLE_SPECIFICATION}`,
    });
};

export const getCycleSpecificationById = (id: string): AxiosPromise<CycleSpecificationsResponse> => {
    return httpClient({
        method: 'GET',
        url: `/demo-entity/${ENTITY_TYPES.CYCLE_SPECIFICATION}?id=${id}`,
    });
};

export const createCycleSpecification = (
    cycleSpecification: CycleSpecification,
): AxiosPromise<CycleSpecificationsResponse> => {
    return httpClient({
        method: 'POST',
        url: `/demo-entity/${ENTITY_TYPES.CYCLE_SPECIFICATION}`,
        data: {
            data: JSON.stringify(cycleSpecification),
        },
    });
};

export const updateCycleSpecification = (
    id: string,
    version: number,
    cycleSpecification: CycleSpecification,
): AxiosPromise<CycleSpecificationsResponse> => {
    return httpClient({
        method: 'PUT',
        url: `/demo-entity/${ENTITY_TYPES.CYCLE_SPECIFICATION}`,
        params: {
            id,
        },
        data: {
            version,
            data: JSON.stringify(cycleSpecification),
        },
    });
};

export const updateCycleSpecificationState = (id: string, version: number, state: EntityState) => {
    return httpClient({
        method: 'PATCH',
        url: `/demo-entity/${ENTITY_TYPES.CYCLE_SPECIFICATION}`,
        data: {
            id,
            version,
            state,
        },
    });
};
