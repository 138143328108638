<template>
    <div class="h-100">
        <AbstractListPageWrapper
            :pageTitle="$i18n.t('customerCare.partnerManager')"
            :entitiesCount="partners.length"
            :isOverviewEnabled="false"
            @searchQueryChanged="setSearchQuery"
        >
            <template slot="filterTable">
                <FilterTable
                    :columns="columnsData"
                    @filterAdded="onFilterAdded"
                />
            </template>

            <template slot="headerButtons">
                <AppButton
                    :buttonType="BUTTON_TYPES.SECONDARY"
                    :iconType="ICON_TYPES.PLUS"
                    :label="'Import CSV'"
                    class="mr-3"
                    @click="isImportModalOpen = true"
                />
                <AppButton
                    :buttonType="BUTTON_TYPES.PRIMARY"
                    :iconType="ICON_TYPES.PLUS"
                    :label="'New Partner'"
                    @click="openNewPartner"
                />
            </template>

            <template slot="allFilters">
                <TableFiltersTags
                    :filterTableMixin="filterTableMixin"
                    class="my-3 ml-2"
                    @removeFilter="removeFilter"
                    @removeAllFilters="removeAllFilters"
                />
            </template>

            <template slot="table">
                <AppTable
                    :entities="sortedFilteredEntities"
                    :isSearchEnabled="true"
                    :isDefaultHoverEnabled="false"
                    :innerSearchQuery="searchQuery"
                    :columnsData="columnsData"
                    tableKey="viasat/partners"
                >
                    <template #customRowButtons="{ entity }">
                        <IconButton
                            :label="$i18n.t('generic.edit')"
                            :icon="ICON_TYPES.EDIT"
                            class="mr-1"
                            @iconClick="openEditPartner(entity)"
                        />
                        <IconButton
                            :label="$i18n.t('generic.delete')"
                            :icon="ICON_TYPES.DELETE"
                            class="mr-3"
                            @iconClick="confirmDeletePartner(entity)"
                        />
                    </template>

                    <template #dateCreated="{ entity }">
                        {{ $localeLibrary.getFormattedDate(entity.dateCreated) }}
                    </template>

                    <template #status="{ entity }">
                        <AppLabel
                            :title="entity.status || $i18n.t('generic.empty')"
                            :color="getPartnerStateColor(entity.status)"
                        />
                    </template>
                </AppTable>
            </template>
        </AbstractListPageWrapper>
        <AppDialog
            :visible="isImportModalOpen"
            maxWidth="50vw"
            maxHeight="100%"
        >
            <div class="modal-wrapper d-flex flex-column">
                <h2 class="mb-4">Import Partners from CSV</h2>
                <AgentNotesDragDropUploader
                    :acceptType="'.csv'"
                    :triggerUploadFiles="uploadInProgress"
                    :uploadSuccessIndex="uploadSuccessIndex"
                    :triggerUploadFailed="uploadFailed"
                />
                <div class="button-wrapper d-flex justify-content-end mt-2">
                    <AppButton
                        :buttonType="BUTTON_TYPES.SECONDARY"
                        :label="'Cancel'"
                        class="mr-3"
                        @click="closeModal"
                    />
                    <AppButton
                        :buttonType="BUTTON_TYPES.PRIMARY"
                        :label="'Import'"
                        @click="closeModal"
                    />
                </div>
            </div>
        </AppDialog>
        <AppDialog
            :visible="isNewPartnerModalOpen"
            maxWidth="50vw"
            maxHeight="100%"
        >
            <div class="modal-wrapper d-flex flex-column">
                <h2 class="mb-4">New Partner</h2>
                <div>
                    <div class="mb-2">
                        <AppInputV3
                            v-model="newPartner.firstName"
                            :label="'First name'"
                        />
                    </div>
                    <div class="mb-2">
                        <AppInputV3
                            v-model="newPartner.lastName"
                            :label="'Last name'"
                        />
                    </div>
                    <div class="mb-2">
                        <AppInputV3
                            v-model="newPartner.email"
                            :label="'Email'"
                        />
                    </div>
                    <div class="mb-2">
                        <AppInputV3
                            v-model="newPartner.phone"
                            :label="'Phone'"
                        />
                    </div>
                    <div class="mb-2">
                        <AppInputV3
                            v-model="newPartner.company"
                            :label="'Company'"
                        />
                    </div>
                    <div class="mb-2">
                        <AppInputV3
                            v-model="newPartner.group"
                            :label="'Group'"
                        />
                    </div>
                    <div class="mb-2">
                        <AppInputV3
                            v-model="newPartner.role"
                            :label="'Role'"
                        />
                    </div>
                    <div class="mb-2">
                        <AppInputV3
                            v-model="newPartner.bankDetails"
                            :label="'Banking details'"
                        />
                    </div>
                    <div class="mb-2">
                        <AppMultiselectV3
                            v-model="newPartner.commissionStructure"
                            :additionalLabel="'Commission structure'"
                            :small="true"
                            :options="availableCommisions"
                        />
                    </div>
                    <div class="mb-2">
                        <AppMultiselectV3
                            v-model="newPartner.status"
                            :additionalLabel="'Status'"
                            :small="true"
                            :options="partnerStates"
                        />
                    </div>
                    <div class="mb-2">
                        <p class="mb-1">
                            <strong>Tags</strong>
                        </p>
                        <TagsInput
                            :value="newPartner.tags.map(t => ({ text: t }))"
                            @input="updateTags"
                        />
                    </div>
                </div>
                <div class="button-wrapper d-flex justify-content-end mt-4">
                    <AppButton
                        :buttonType="BUTTON_TYPES.SECONDARY"
                        :label="'Cancel'"
                        class="mr-3"
                        @click="closeModal"
                    />
                    <AppButton
                        :buttonType="BUTTON_TYPES.PRIMARY"
                        :label="'Save'"
                        @click="savePartner"
                    />
                </div>
            </div>
        </AppDialog>
    </div>
</template>

<script>
// components
import AbstractListPageWrapper from '@/components/layout/AbstractListPageWrapper.vue';
import AgentNotesDragDropUploader from '@/__new__/features/customerCare/AgentNotesDragDropUploader.vue';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import AppDialog from '@/components/partials/AppDialog.vue';
import AppInputV3 from '@/components/partials/inputs/AppInputV3.vue';
import AppLabel from '@/components/partials/AppLabel.vue';
import AppMultiselectV3 from '@/components/partials/inputs/AppMultiselectV3.vue';
import AppTable from '@/components/partials/AppTable.vue';
import FilterTable from '@/components/partials/FilterTable.vue';
import FilterTableMixin from '@/components/partials/FilterTableMixin.vue';
import IconButton from '@/components/partials/IconButton.vue';
import TableFiltersTags from '@/components/filters/TableFiltersTags.vue';
import TagsInput from '@/components/partials/inputs/TagsInput.vue';
// helpers
import { ICON_TYPES } from '@/common/iconHelper';
import { LABEL_COLOR } from '@/common/labelsHelper';
import Button from '@/common/button/Button';
import tableColumnType from '@/common/filterTable';
import { getOperatorConfigValue } from '@/services/permissions/permissions.service';
import LocalStorageHelper from '@/common/LocalStorageHelper';

// models
import ViasatPartner from '@/__new__/features/customerCare/viasat/ViasatPartner';

const LEAD_STATE = {
    PENDING: 'Pending',
    ACTIVE: 'Active',
    TERMINATED: 'Terminated',
};

const leadStatusToColor = new Map([
    [LEAD_STATE.PENDING, LABEL_COLOR.yellow],
    [LEAD_STATE.ACTIVE, LABEL_COLOR.green],
    [LEAD_STATE.TERMINATED, LABEL_COLOR.red],
]);

export default {
    name: 'ViasatPartnerManagement',
    components: {
        AbstractListPageWrapper,
        AgentNotesDragDropUploader,
        AppButton,
        AppDialog,
        AppInputV3,
        AppLabel,
        AppMultiselectV3,
        AppTable,
        FilterTable,
        IconButton,
        TableFiltersTags,
        TagsInput,
    },
    mixins: [FilterTableMixin],

    data() {
        return {
            ICON_TYPES,
            BUTTON_TYPES,
            partners: JSON.parse(LocalStorageHelper.get('viasatPartners')) || [],
            isImportModalOpen: false,
            isNewPartnerModalOpen: false,
            columnsData: [
                {
                    name: this.$i18n.t('customerCare.userInformation.firstName'),
                    key: 'firstName',
                    field: 'firstName',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('customerCare.userInformation.lastName'),
                    key: 'lastName',
                    field: 'lastName',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('customerCare.userInformation.email'),
                    key: 'email',
                    field: 'email',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: 'Phone',
                    key: 'phone',
                    field: 'phone',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: 'ID',
                    key: 'id',
                    field: 'id',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: 'Company',
                    key: 'company',
                    field: 'company',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: 'Group',
                    key: 'group',
                    field: 'group',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: 'Role',
                    key: 'role',
                    field: 'role',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: 'Banking details',
                    key: 'bankDetails',
                    formatter: val => this.getLast4Digits(val),
                    field: 'bankDetails',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: 'Commission structure',
                    key: 'commissionStructure',
                    field: 'commissionStructure',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: 'Status',
                    key: 'status',
                    field: 'status',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: 'Date created',
                    key: 'dateCreated',
                    field: 'dateCreated',
                    filterType: tableColumnType.DATE,
                },
                {
                    name: 'Tags',
                    key: 'tags',
                    field: 'tags',
                    filterType: tableColumnType.GENERAL_TEXT,
                    formatter: tags => tags?.join(', ') || '',
                },
            ],
            searchQuery: '',
            uploadedDocsIds: [],
            uploadInProgress: false,
            uploadFinished: false,
            uploadSuccessIndex: null,
            uploadFailed: false,
            newPartner: new ViasatPartner(),
            partnerStates: ['Pending', 'Active', 'Terminated'],
            availableCommisions: [
                '5% of new activation revenue',
                '10% of new activation revenue',
                '15% of new activation revenue',
                '1% of monthly recurring revenue',
                '2% of monthly recurring revenue',
                '3% of annual gross revenue',
            ],
            deleteConfirmationButton: new Button({
                label: this.$i18n.t('generic.delete'),
            }),
            leadStatusToColor,
        };
    },
    computed: {
        sortedFilteredEntities() {
            return this.filteredEntitiesMixin(this.partners);
        },
    },
    created() {
        if (!this.partners.length) {
            this.partners = getOperatorConfigValue('viasatPartners', []);
            this.savePartners();
        }
    },
    methods: {
        setSearchQuery(query) {
            this.searchQuery = query;
        },
        closeModal() {
            this.isImportModalOpen = false;
            this.isNewPartnerModalOpen = false;
        },
        updateTags(tags) {
            this.newPartner.tags = tags.map(t => t.text);
        },
        savePartner() {
            this.addNewPartner();
            this.savePartners();
            this.closeModal();
        },
        addNewPartner() {
            if (!this.partners.some(p => p.id === this.newPartner.id)) {
                this.newPartner = new ViasatPartner(this.newPartner);
                this.partners.push(this.newPartner);
            }
        },
        savePartners() {
            LocalStorageHelper.set('viasatPartners', JSON.stringify(this.partners));
        },
        openNewPartner() {
            this.newPartner = new ViasatPartner();
            this.isNewPartnerModalOpen = true;
        },
        openEditPartner({ id }) {
            this.newPartner = this.partners.find(p => p.id === id);
            this.isNewPartnerModalOpen = true;
        },
        confirmDeletePartner(entity) {
            this.$showWarningAlert({
                message: this.$i18n.t('alerts.areYouSureDeleteEntity', {
                    entityName: `${entity.firstName} ${entity.lastName}`,
                }),
                buttons: [this.deleteConfirmationButton],
            });
            this.$eventBus.$once('buttonClicked', id => {
                if (id === this.deleteConfirmationButton.id) {
                    this.deletePartner(entity.id);
                }
            });
        },
        deletePartner(partnerId) {
            this.partners = this.partners.filter(p => p.id !== partnerId);
            this.savePartners();
        },
        getPartnerStateColor(status) {
            return leadStatusToColor.get(status);
        },
        getLast4Digits(bankDetails) {
            const { last4 } = { ...bankDetails.match(/[-. \dX]+(?<last4>\d{4})/)?.groups };

            return last4 ? `XXXX-XXXX-XXXX-${last4}` : '';
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/consistency';

.modal-wrapper {
    width: 50vw;
    padding: $spacing-xxl;
}
</style>
