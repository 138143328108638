const formatNSI = nssid => {
    const nsi = nssid.values;
    const obj = {};
    let counter = 0;
    for (const i in nsi) {
        if (Object.prototype.hasOwnProperty.call(nsi, i)) {
            obj[counter] = {
                sst: [parseInt(i, 10)],
                sd: nsi[i],
            };
            counter += 1;
        }
    }
    return obj;
};

export default (id, pcObject) => ({
    type: 'nssid',
    data: formatNSI(pcObject),
    uuid: id,
});
