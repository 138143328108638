
// Components
import AbstractTableTile from '@/__new__/features/customerCareSuite/components/AbstractTableTile.vue';
import AppToggle from '@/components/partials/inputs/AppToggle.vue';

// Helpers
import { ALERT_TYPES } from '@/common/alerts/Alert';
import { cloneDeep } from 'lodash';
import { ICON_TYPES } from '@/common/iconHelper';
import { formatAmountBasedOnCurrency } from '@/common/formatting';

// HTTP
import { getPaymentsForBillingAccount } from '@/__new__/services/portal/postpaid/payments/payments';

export default {
    name: 'BillingAccountPaymentsTile',
    components: {
        AbstractTableTile,
        AppToggle,
    },
    data() {
        return {
            entities: [],
            rawResponse: {},
            selectedEntity: {},
            showAdditionalSidebar: false,
            account: null,
            showOnlyUnprocessedPayments: false,
            ICON_TYPES,
        };
    },
    computed: {
        formattedEntities() {
            const paymentsInfoCopy = cloneDeep(this.rawResponse);
            const payments = paymentsInfoCopy?.data;
            if (payments?.length) {
                return payments.map(item => ({
                    id: item.id,
                    paymentDate: this.$localeLibrary.getFormattedDateAndTime(item.paymentDate),
                    amount: formatAmountBasedOnCurrency(item.amount),
                    paymentMethod: item.paymentMethod,
                    paymentTransactionId: item.paymentTransactionId,
                    paymentNote: item.paymentNote,
                    invoiceId: item.invoiceId ?? this.$i18n.t('billingAccount.unprocessed'),
                    state: item.state,
                }));
            }
            return [];
        },
        columnsData() {
            return [
                {
                    name: this.$i18n.t('billingAccount.id'),
                    key: 'id',
                },
                {
                    name: this.$i18n.t('generic.date'),
                    key: 'paymentDate',
                },
                {
                    name: this.$i18n.t('generic.amount'),
                    key: 'amount',
                },
                {
                    name: this.$i18n.t('billingAccount.paymentMethod'),
                    key: 'paymentMethod',
                },
                {
                    name: this.$i18n.t('billingAccount.paymentTransactionId'),
                    key: 'paymentTransactionId',
                },
                {
                    name: this.$i18n.t('generic.note'),
                    key: 'paymentNote',
                },
                {
                    name: this.$i18n.t('billingAccount.invoiceId'),
                    key: 'invoiceId',
                },
                {
                    name: this.$i18n.t('generic.state'),
                    key: 'state',
                },
            ];
        },
    },
    watch: {
        showOnlyUnprocessedPayments: {
            deep: true,
            handler() {
                this.fetchTileData();
            },
        },
    },
    async created() {
        await this.fetchTileData();
    },
    methods: {
        async fetchTileData() {
            await this.$withProgressBar(
                async () => {
                    this.$emit('isDataLoadingUpd', true);

                    this.rawResponse = await getPaymentsForBillingAccount(
                        this.$route.params.id,
                        this.showOnlyUnprocessedPayments,
                    );
                    this.$emit('isDataLoadingUpd', false);
                },
                {
                    errorHandler: () => {
                        this.$alert(this.$i18n.t('alertMessage.somethingWentWrongFetchingNecessaryData'), {
                            type: ALERT_TYPES.error,
                        });
                        this.$emit('isDataLoadingUpd', false);
                    },
                },
            );
        },
        selectedRow(entity: object): void {
            this.selectedEntity = entity;
            this.showAdditionalSidebar = true;
        },
    },
};
