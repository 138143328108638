import NcsBaseCondition from '@/__new__/services/dno/charging/models/chargingSpecificationConditions/NCSbaseCondition';
import { csConditionZoneOptions } from '@/__new__/services/dno/charging/common/zone/zones';
import { isEmpty } from 'lodash';

export default class ApnCondition extends NcsBaseCondition {
    constructor({ data, uuid, type, dataEmpty = true, dataValid = false }) {
        super();
        this.data = isEmpty(data)
            ? {
                  inverted: csConditionZoneOptions.is,
                  value: '',
              }
            : data;
        this.type = type;
        this.uuid = uuid;
        this.dataEmpty = dataEmpty;
        this.dataValid = dataValid;
    }

    validationCondition() {
        return this.data.value !== '';
    }

    isDataValid() {
        this.dataValid = this.validationCondition();
    }

    isDataEmpty() {
        this.dataEmpty = !this.validationCondition();
    }
}
