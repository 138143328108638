<template>
    <div
        v-if="visible"
        class="wrapper"
        @click.self="closeDialog"
    >
        <div
            :style="styles"
            :class="['content', { 'overflow-auto': overflowAuto }, contentCustomClass]"
        >
            <slot />
        </div>
    </div>
</template>

<script>
export default {
    name: 'AppDialog',
    model: {
        prop: 'visible',
    },
    props: {
        visible: {
            type: Boolean,
            required: true,
        },
        maxWidth: {
            type: String,
            default: '100%',
        },
        maxHeight: {
            type: String,
            default: '100%',
        },
        overflowAuto: {
            type: Boolean,
            default: true,
        },
        contentCustomClass: {
            type: String,
            default: '',
        },
    },
    computed: {
        styles() {
            return {
                'max-width': this.maxWidth,
                'max-height': this.maxHeight,
            };
        },
    },
    methods: {
        closeDialog() {
            if (this.visible) {
                this.$emit('input', false);
            }
            this.$emit('close');
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/palette';
@import '~@/assets/scss/base';
@import '~@/assets/scss/z-indexes';

.wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: $modal-z-index;
    background: rgba($color: $navy, $alpha: 0.9);
}

.content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 8px;
    background-color: $white;
    width: 100%;
    overflow-y: scroll;
}
</style>
