<template>
    <AppDialogV2
        :title="formTitle"
        :visible="value"
        @close="close()"
        @submit="onSubmit()"
    >
        <div
            v-if="isSuspendAction"
            class="form-content"
        >
            <div class="reason mb-3">
                <h3 class="heading-xs blue mb-2">
                    {{ $i18n.t('customerCare.suspendServices.suspensionReason') }}
                </h3>

                <AppMultiselectV3
                    v-model="reason"
                    :options="reasonOptions"
                    :error="$v.reason.$error"
                    :errorMessage="$i18n.t('generic.validations.fieldIsRequired')"
                    label="label"
                    data-test-id="reason-select"
                />
            </div>

            <div class="mb-5">
                <h3 class="heading-xs blue mb-2">
                    {{ $i18n.t('generic.notes') }}
                </h3>

                <CustomTextarea
                    v-model="notesTxt"
                    :placeholder="$i18n.t('generic.addNotes')"
                    :invalid="isNotesInvalid"
                    class="input"
                    data-test-id="notes-textarea"
                />

                <p
                    v-show="isNotesInvalid"
                    class="error-message mt-2"
                >
                    {{ $i18n.t('generic.validations.fieldIsRequired') }}
                </p>
            </div>
        </div>

        <AppToggle
            v-if="!isSuspendAction"
            v-model="shouldUnblockIMEI"
            :label="$i18n.t('customerCare.suspendServices.unblockIMEI')"
            :small="true"
            class="mb-3 ml-3"
            data-test-id="unblock-imei-toggle"
        />
    </AppDialogV2>
</template>

<script>
// Components
import AppDialogV2 from '@/components/partials/AppDialogV2.vue';
import { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import AppMultiselectV3 from '@/components/partials/inputs/AppMultiselectV3.vue';
import CustomTextarea from '@/__new__/features/customerCare/subscriber/CustomTextarea.vue';
import AppToggle from '@/components/partials/inputs/AppToggle.vue';

// http
import ossmvneHTTP from '@/__new__/services/dno/ossmvne/http/ossmvne';
import notesHTTP from '@/__new__/services/dno/crm/http/crm';

// Helpers
import {
    USER_MANAGER_HIERARCHY,
    SUBSCRIBER_SUSPEND_SERVICES_ACTIONS,
    SUBSCRIBER_SUSPEND_SERVICES_REASON_CODE,
} from '@/__new__/features/customerCare/common/customerCareHelper';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import { dateToEpoch } from '@/common/formatting';
import Actions from '@/store/mutation-types';
import { mapActions } from 'vuex';
import { Modules } from '@/store/store';

export default {
    name: 'SuspendServices',
    components: {
        AppDialogV2,
        AppMultiselectV3,
        CustomTextarea,
        AppToggle,
    },
    mixins: [validationMixin],
    props: {
        value: {
            required: true,
            type: Boolean,
        },
        action: {
            required: true,
            type: String,
        },
        subscriberId: {
            default: '',
            type: String,
        },
    },
    data() {
        return {
            reason: '',
            notesTxt: '',
            shouldUnblockIMEI: false,
            BUTTON_TYPES,
        };
    },
    computed: {
        isSuspendAction() {
            return this.action === SUBSCRIBER_SUSPEND_SERVICES_ACTIONS.BAR;
        },
        isNotesInvalid() {
            return this?.$v?.notesTxt?.$error;
        },
        reasonOptions() {
            return [
                {
                    id: SUBSCRIBER_SUSPEND_SERVICES_REASON_CODE.LOST,
                    label: this.$i18n.t('customerCare.subscriberTab.lost'),
                    reasonKey: 'suspend_line_lost',
                },
                {
                    id: SUBSCRIBER_SUSPEND_SERVICES_REASON_CODE.STOLEN,
                    label: this.$i18n.t('customerCare.subscriberTab.stolen'),
                    reasonKey: 'suspend_line_stolen',
                },
            ];
        },
        formTitle() {
            return this.isSuspendAction
                ? this.$i18n.t('customerCare.suspendServices.suspendServices')
                : this.$i18n.t('customerCare.suspendServices.resumeServices');
        },
    },
    validations() {
        return {
            reason: {
                required: this.isSuspendAction ? required : false,
            },
            notesTxt: {
                required: this.isSuspendAction ? required : false,
            },
        };
    },
    methods: {
        ...mapActions(Modules.customerCareSuite, [Actions.LOAD_ASSOCIATED_UM_ENTITIES]),
        close() {
            this.$emit('close');
        },
        onSubmit() {
            this.$v.$touch();

            if (this.$v.$invalid) {
                return;
            }

            this.submit();
            this.close();
        },
        async submit() {
            if (this.isSuspendAction) {
                await this.suspendServices().then(this.setAgentNote());
            } else {
                await this.restoreServices();
            }
        },
        async setAgentNote() {
            await this.$withProgressBar(
                () =>
                    notesHTTP.addNote({
                        id: this.subscriberId,
                        idType: USER_MANAGER_HIERARCHY.SUBSCRIBER,
                        epoch: dateToEpoch(Date.now()),
                        noteText: this.notesTxt,
                        tags: [this.$i18n.t('generic.stateMap.suspended')],
                    }),
                {
                    errorHandler: () =>
                        this.$showErrorAlert({
                            message: this.$i18n.t('alertMessage.somethingWentWrongSavingAgentNotes'),
                        }),
                },
            );
        },
        async suspendServices() {
            await this.$withProgressBar(
                async () => {
                    await ossmvneHTTP.suspendLine(
                        this.subscriberId,
                        USER_MANAGER_HIERARCHY.SUBSCRIBER,
                        this.reason.reasonKey,
                    );
                    this.$showSuccessAlert({
                        message: this.$i18n.t('customerCare.suspendServices.alerts.serviceSuccess'),
                    });
                    await this[Actions.LOAD_ASSOCIATED_UM_ENTITIES]({
                        targetType: USER_MANAGER_HIERARCHY.SUBSCRIBER,
                        targetId: this.subscriberId,
                    });
                },
                {
                    errorHandler: () =>
                        this.$showErrorAlert({
                            message: this.$i18n.t('customerCare.suspendServices.alerts.serviceSuspensionFailed'),
                        }),
                },
            );
        },
        async restoreServices() {
            await this.$withProgressBar(
                async () => {
                    await ossmvneHTTP.resumeSuspendLine(
                        this.subscriberId,
                        USER_MANAGER_HIERARCHY.SUBSCRIBER,
                        this.shouldUnblockIMEI,
                    );
                    this.$showSuccessAlert({
                        message: this.$i18n.t('customerCare.suspendServices.alerts.serviceSuccess'),
                    });
                    await this[Actions.LOAD_ASSOCIATED_UM_ENTITIES]({
                        targetType: USER_MANAGER_HIERARCHY.SUBSCRIBER,
                        targetId: this.subscriberId,
                    });
                },
                {
                    errorHandler: () =>
                        this.$showErrorAlert({
                            message: this.$i18n.t('customerCare.suspendServices.alerts.serviceRestorationFailed'),
                        }),
                },
            );
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/_consistency';

.form-content {
    padding: $spacing-m $spacing-xxxl;
}

.error-message {
    color: $red;
}

#app .reason ::v-deep .multiselect__tags {
    padding-left: $spacing-xs;
}

.form-content ::v-deep textarea {
    width: 100%;
    padding-inline: $spacing-xs;
    border-radius: 0.5rem;

    &::placeholder {
        font-style: italic;
        opacity: 1;
        font-size: inherit;
    }
}
</style>
