import ENTITY_TYPES from '@/common/entities/entityTypes';

import Campaign, { CampaignStatus, DeliveryType } from '@/__new__/services/dno/campaigns/models/Campaign';
import { createCampaignCategory, storeCampaign } from '@/__new__/services/dno/campaigns/http/campaigns';

import Segment from '@/__new__/services/dno/segments/models/Segment';
import { createSegment } from '@/__new__/services/dno/segments/http/segments';

import { type SinkSpecificConfig } from '@/__new__/services/dno/sinkConfigs/models/SinkConfig';
import { type ApiInvokerJobDefinition } from '@/__new__/services/dno/sinkConfigs/models/ApiInvokerSinkConfig';
import { type OrdReportJobDefinition } from '@/__new__/services/dno/sinkConfigs/models/ORDSinkConfig';
import { SinkConfigCol } from '@/__new__/services/dno/sinkConfigs/models/SinkConfigCol';
import { type JobDefinitionItem } from '@/store/modules/sinkConfigs.store';

import {
    upsertReportConfig,
    createRedshiftConfig,
    createKafkaConfig,
    upsertApiInvokerConfig,
    createEventCompositionConfig,
} from '@/__new__/services/dno/sinkConfigs/http/sinkConfigs';

import { createEvent } from '@/__new__/services/dno/events/http/events';
import { invertGenerationTypeLabelMap } from '@/__new__/features/segments/common/segmentHelper';
import Message from '@/__new__/services/dno/campaigns/models/Message';
import { PRIORITY_LABELS_INVERT } from '@/common/cepHelper';
import { addQuietHours } from '@/__new__/services/dno/quietHours/http/quietHours';
import { omit } from 'lodash';
import { BackendEvent } from '@/__new__/services/dno/events/models/Event';

type SinkConfigJSON = {
    data: {
        name: string;
        id: string;
        trigger_condition?: any;
        specific_configuration?: SinkSpecificConfig;
        version?: number;
        state?: number | string;
        fields_configuration?: SinkConfigCol[];
        api_control?: any;
        entry_type?: any;
        input_methods?: any;
        properties?: any;
        cohorts_configuration?: Record<string, any>;
    };
    state: string;
};

function prepareORDConfig(json: JobDefinitionItem<OrdReportJobDefinition>) {
    return {
        data: {
            name: json.job_definition.name,
            specific_configuration: json.job_definition,
            trigger_condition: {
                id_type: null,
                filters: json.job_definition.sink_entity_properties.trigger_condition.filters.map((f: any) =>
                    omit(f, 'filterDefinitionId'),
                ),
            },
            fields_configuration: json.job_definition.sink_entity_properties.fields_configuration,
            state: json.state,
            cohorts_configuration: json.job_definition.sink_entity_properties.cohorts_configuration,
        },
        version: 0,
    };
}
function prepareAPIIConfig(json: JobDefinitionItem<ApiInvokerJobDefinition>) {
    return {
        data: {
            name: json.job_definition.name,
            specific_configuration: {
                ...json.job_definition,
                'request_params.uri.value': json.job_definition.request_params.uri?.value,
            },
            trigger_condition: {
                id_type: null,
                filters: json.job_definition.sink_entity_properties.trigger_condition.filters.map((f: any) =>
                    omit(f, 'filterDefinitionId'),
                ),
            },
            fields_configuration: json.job_definition.sink_entity_properties.fields_configuration,
            state: json.state,
            cohorts_configuration: json.job_definition.sink_entity_properties.cohorts_configuration,
        },
        version: 0,
    };
}
function prepareSinkConfig(json: SinkConfigJSON) {
    return {
        data: {
            name: json.data.name,
            specific_configuration: json.data.specific_configuration,
            trigger_condition: {
                id_type: null,
                filters: json.data?.trigger_condition.filters.map((f: any) => omit(f, 'filterDefinitionId')),
            },
            fields_configuration: json.data.fields_configuration,
            state: json.data.state,
            cohorts_configuration: json.data.cohorts_configuration,
        },
        version: 0,
    };
}

export type ImportEntityType =
    | ENTITY_TYPES.CAMPAIGN
    | ENTITY_TYPES.SEGMENT
    | ENTITY_TYPES.API_INVOKER_SINK_CONFIG
    | ENTITY_TYPES.KAFKA_SINK_CONFIG
    | ENTITY_TYPES.REDSHIFT_CONFIG
    | ENTITY_TYPES.ORD_CONFIG
    | ENTITY_TYPES.EVENT
    | ENTITY_TYPES.QUIET_HOUR
    | ENTITY_TYPES.CAMPAIGN_CATEGORY
    | ENTITY_TYPES.EVENT_COMPOSITION;

export const entityCreators: Record<ImportEntityType, (json: any) => any> = {
    [ENTITY_TYPES.CAMPAIGN]: (json: Campaign) => {
        const newCampaign = new Campaign(
            undefined,
            json.name,
            json.creationDate,
            json.startTime,
            json.endTime,
            DeliveryType.fromJson(json.deliveryType),
            json.target,
            json.messages.map((msgs: any[]) => msgs.map(Message.fromJson)),
            json.status as CampaignStatus,
            json.segmentIds,
            json.campaignType,
            json.triggerCondition,
            json.lastRunTimestamp,
            json.updateTimestamp,
            json.version,
            Number(PRIORITY_LABELS_INVERT[json.selectedPriority as string]),
        );
        return storeCampaign(newCampaign);
    },
    [ENTITY_TYPES.SEGMENT]: (json: any) => {
        const newSegment = new Segment(
            json.id,
            json.name,
            json.updateTime,
            json.filtersJson,
            json.description,
            json.segmentIdType,
            invertGenerationTypeLabelMap(json.generationType),
            json.addCondition,
            json.removeCondition,
            json.createdBy,
            json.entryValues || [],
            json.inputMethods,
            json.ranges,
            json.version,
            json.entriesDifferenceData,
        );

        return createSegment(newSegment);
    },

    [ENTITY_TYPES.API_INVOKER_SINK_CONFIG]: async (json: JobDefinitionItem<ApiInvokerJobDefinition>) =>
        upsertApiInvokerConfig(prepareAPIIConfig(json)).catch(err => {
            throw new Error(err.response.data.message);
        }),
    [ENTITY_TYPES.QUIET_HOUR]: (json: any) =>
        addQuietHours(json).catch(err => {
            throw new Error(err.response.data.message);
        }),
    [ENTITY_TYPES.CAMPAIGN_CATEGORY]: (json: any) =>
        createCampaignCategory(json).catch(err => {
            throw new Error(err.response.data.message);
        }),

    [ENTITY_TYPES.KAFKA_SINK_CONFIG]: (json: SinkConfigJSON) => createKafkaConfig(prepareSinkConfig(json)),

    [ENTITY_TYPES.REDSHIFT_CONFIG]: (json: SinkConfigJSON) => createRedshiftConfig(prepareSinkConfig(json)),

    [ENTITY_TYPES.ORD_CONFIG]: (json: JobDefinitionItem<OrdReportJobDefinition>) =>
        upsertReportConfig(prepareORDConfig(json)).catch(err => {
            throw new Error(err.response.data.message);
        }),

    [ENTITY_TYPES.EVENT_COMPOSITION]: (json: SinkConfigJSON) =>
        createEventCompositionConfig({
            ...prepareSinkConfig(json),
            type: 'event_composition_config',
        }),

    [ENTITY_TYPES.EVENT]: (json: BackendEvent) => {
        return createEvent({
            data: {
                json_schema: json.data.json_schema,
                labels: json.data.labels,
                auth: json.data.api_control.auth,
                priority: json.data.priority,
            },
        });
    },
};

export function shouldEntityImportResetVersion(entityType: ENTITY_TYPES): boolean {
    return [
        ENTITY_TYPES.API_INVOKER_SINK_CONFIG,
        ENTITY_TYPES.KAFKA_SINK_CONFIG,
        ENTITY_TYPES.REDSHIFT_CONFIG,
        ENTITY_TYPES.ORD_CONFIG,
        ENTITY_TYPES.EVENT,
    ].includes(entityType);
}
