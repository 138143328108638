import i18n from '@/i18n';
import { LABEL_COLOR } from '@/common/labelsHelper';

export const INVENTORY_STATE_KEYS = {
    OK: 'OK',
    WARNING: 'WARNING',
    OVER: 'OVER',
    EMPTY: 'EMPTY',
    UNKNOWN: 'UNKNOWN',
};

export const COUNT_LIMITS_APPLY_ON = {
    VOUCHER_SET: 'voucher_set',
    VOUCHER_CODE: 'voucher_code',
};

export const CODES_RELEASE_OPTION = {
    ALL: 1,
    UNASSIGNED: 2,
    NONE: 3,
};

export const voucherSetInventoryStateMap = new Map([
    [INVENTORY_STATE_KEYS.OK, i18n.t('vouchers.inventoryStateMap.ok')],
    [INVENTORY_STATE_KEYS.WARNING, i18n.t('vouchers.inventoryStateMap.warning')],
    [INVENTORY_STATE_KEYS.OVER, i18n.t('vouchers.inventoryStateMap.over')],
    [INVENTORY_STATE_KEYS.EMPTY, i18n.t('vouchers.inventoryStateMap.empty')],
    [INVENTORY_STATE_KEYS.UNKNOWN, i18n.t('vouchers.inventoryStateMap.unknown')],
]);

export const inventoryStateIndicatorMap = new Map([
    [voucherSetInventoryStateMap.get(INVENTORY_STATE_KEYS.OK), LABEL_COLOR.green],
    [voucherSetInventoryStateMap.get(INVENTORY_STATE_KEYS.WARNING), LABEL_COLOR.yellow],
    [voucherSetInventoryStateMap.get(INVENTORY_STATE_KEYS.OVER), LABEL_COLOR.red],
    [voucherSetInventoryStateMap.get(INVENTORY_STATE_KEYS.EMPTY), LABEL_COLOR.red],
    [voucherSetInventoryStateMap.get(INVENTORY_STATE_KEYS.UNKNOWN), LABEL_COLOR.gray],
]);
