import { render, staticRenderFns } from "./LatestOrderDetailsTileNextGen.vue?vue&type=template&id=b4be4ffc&scoped=true"
import script from "./LatestOrderDetailsTileNextGen.vue?vue&type=script&lang=ts"
export * from "./LatestOrderDetailsTileNextGen.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b4be4ffc",
  null
  
)

export default component.exports