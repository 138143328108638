<template>
    <AbstractSubSidebarPageWrapper :pageTitle="$i18n.t('settings.settings')">
        <template slot="subSidebar">
            <SubSidebar
                v-model="selectedRouteName"
                :sections="permissionFilteredSections"
                class="sidebar"
                @change="changeActiveSection"
            />
        </template>
        <template slot="content">
            <AbstractEditPageWrapper>
                <template #content>
                    <div class="editor-section">
                        <div class="d-flex w-100 align-items-center editor-section-heading">
                            <div class="mr-3">
                                {{ $i18n.t('generic.general') }}
                            </div>
                            <EntityStatusIndicator
                                v-if="user.state && !user.externalUserId"
                                :status="user.state"
                                :stateMapColor="USER_STATE_INDICATOR_MAP"
                                :stateMap="USER_STATUS"
                            />
                        </div>
                        <div class="section-content">
                            <AppInputV3
                                key="name"
                                v-model.trim="user.name"
                                :label="$i18n.t('generic.name')"
                                :invalid="$v.user.name.$error"
                                type="text"
                                class="editor-input-largest mb-3"
                                :disabled="user.externalUserId"
                            />
                            <AppInputV3
                                key="email"
                                v-model.trim="user.email"
                                :label="$i18n.t('generic.email')"
                                :invalid="$v.user.email.$error"
                                type="text"
                                class="editor-input-largest mb-3"
                                :disabled="user.externalUserId"
                            />
                            <AppMultiselectV3
                                v-if="!user.externalUserId"
                                v-model="selectRole"
                                :options="roles"
                                :small="true"
                                :showLabels="false"
                                :allowEmpty="false"
                                :error="$v.selectRole.id.$error"
                                :additionalLabel="$i18n.t('users.selectUserRole')"
                                label="name"
                                trackBy="id"
                                class="editor-input-largest multiselect"
                            />
                        </div>
                    </div>
                    <div
                        v-if="user.externalUserId"
                        class="editor-section"
                    >
                        <div class="section-content">
                            <div class="form-subtitle">
                                {{ $i18n.t('users.editor.externalUserId') }}
                            </div>
                            <div class="d-flex align-items-end mb-3">
                                {{ user.externalUserId }}
                            </div>
                        </div>
                    </div>
                    <div
                        v-if="!user.externalUserId"
                        class="editor-section"
                    >
                        <div class="editor-section-heading">
                            {{ $i18n.t('users.editor.credentials') }}
                        </div>
                        <div class="section-content">
                            <AppCheckbox
                                v-if="!userIsEditing"
                                v-model="generateRandomPassword"
                                :labelRight="$i18n.t('users.editor.generateRandomPassword')"
                                :inputValue="true"
                                name="generateRandomPassword"
                                class="mb-1"
                                @input="clearPassword"
                            />
                            <div class="d-flex align-items-end mb-3">
                                <AppInputV3
                                    key="password"
                                    v-model.trim="user.password"
                                    :label="$i18n.t('generic.password')"
                                    :disabled="generateRandomPassword"
                                    :invalid="$v.user.password.$error"
                                    :errorMessage="$i18n.t('account.passwordHint')"
                                    :optional="userIsEditing"
                                    class="editor-input-largest inner-input"
                                    type="password"
                                />
                                <AppTooltip
                                    :offset="30"
                                    :tooltipPosition="TOOLTIP_POSITION.top"
                                    :contentWidth="'15rem'"
                                    class="password-tooltip"
                                >
                                    <template slot="label">
                                        <div class="questionmark" />
                                    </template>

                                    <template slot="content">
                                        <div class="password-requirement">
                                            <div class="ml-2 mb-2">
                                                {{ $i18n.t('account.passwordHint') }}
                                            </div>
                                        </div>
                                    </template>
                                </AppTooltip>
                            </div>
                            <AppInputV3
                                key="confirmPassword"
                                v-model.trim="user.confirmPassword"
                                :label="$i18n.t('generic.confirmPassword')"
                                :disabled="generateRandomPassword"
                                :invalid="$v.user.confirmPassword.$error || validationPassword"
                                :errorMessage="
                                    validationPassword
                                        ? $i18n.t('alertMessage.account.passwordConfirmationDoesNotMatch')
                                        : ''
                                "
                                :optional="userIsEditing"
                                class="editor-input-largest inner-input"
                                type="password"
                                @input="validationPassword = false"
                            />
                        </div>
                    </div>
                </template>

                <template #controls>
                    <AppButton
                        :label="$i18n.t('generic.cancel')"
                        class="mr-5"
                        @click="onCancel"
                    />
                    <AppButton
                        v-if="!user.externalUserId"
                        id="experiment-save-button"
                        :buttonType="BUTTON_TYPES.PRIMARY"
                        :label="$i18n.t('generic.save')"
                        :iconType="ICON_TYPES.CHECK"
                        @click="onSave"
                    />
                </template>
            </AbstractEditPageWrapper>
        </template>
    </AbstractSubSidebarPageWrapper>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { required, requiredIf, sameAs, email } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';
import Actions, { Getters } from '@/store/mutation-types';
import AbstractEditPageWrapper from '@/components/layout/AbstractEditPageWrapper.vue';
import AppTooltip from '@/components/partials/AppTooltip.vue';
import { TOOLTIP_POSITION } from '@/common/tooltip';
import AppInputV3 from '@/components/partials/inputs/AppInputV3.vue';
import AppMultiselectV3 from '@/components/partials/inputs/AppMultiselectV3.vue';
import AppCheckbox from '@/components/partials/inputs/AppCheckbox.vue';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import { ICON_TYPES } from '@/common/iconHelper';
import { addUser, updateUser } from '@/__new__/services/portal/admin/http/account';
import RouteNames from '@/router/routeNames';
import { ALERT_TYPES } from '@/common/alerts/Alert';
import EntityStatusIndicator from '@/components/partials/EntityStatusIndicator.vue';
import { USER_STATUS, USER_STATE_INDICATOR_MAP } from '@/common/userStateHelper';
import SubSidebarMixin from '@/components/partials/SubSidebarMixin.vue';
import SettingsSubSidebarMixin from '@/__new__/features/settings/SettingsSubSidebarMixin.vue';
import AbstractSubSidebarPageWrapper from '@/components/layout/AbstractSubSidebarPageWrapper.vue';
import SubSidebar from '@/components/layout/SubSidebar.vue';
import supportButtonMixin from '@/components/alerts/supportButtonMixin';
import permissionsService from '@/services/permissions/permissions.service';

export default {
    name: 'UserEditor',
    components: {
        AbstractEditPageWrapper,
        AppInputV3,
        AppMultiselectV3,
        AppCheckbox,
        AppTooltip,
        AppButton,
        EntityStatusIndicator,
        SubSidebar,
        AbstractSubSidebarPageWrapper,
    },
    mixins: [validationMixin, SubSidebarMixin, SettingsSubSidebarMixin, supportButtonMixin],
    data() {
        return {
            isSaveButtonClicked: false,
            validationPassword: false,
            TOOLTIP_POSITION,
            successSaveMessage: '',
            user: {
                name: '',
                email: '',
                password: '',
                confirmPassword: '',
                state: '',
                externalUserId: null,
            },
            selectRole: null,
            userIsEditing: false,
            generateRandomPassword: false,
            BUTTON_TYPES,
            ICON_TYPES,
            USER_STATUS,
            USER_STATE_INDICATOR_MAP,
            isLfAdmin: permissionsService.isUserLotusFlareAdmin(),
        };
    },
    validations() {
        return {
            user: {
                name: {
                    required,
                },
                email: {
                    required,
                    email,
                },
                password: {
                    required: requiredIf(() => !this.generateRandomPassword && !this.userIsEditing),
                    // to clarify with someone is it the
                    // reuqirement to have one character because that was not the case before
                    goodPassword: password => {
                        if (password) {
                            return (
                                password.length >= 9 &&
                                /[a-z]/.test(password) &&
                                /[A-Z]/.test(password) &&
                                /[0-9]/.test(password) &&
                                /[!@#$%^&*]/.test(password)
                            );
                        }
                        return true;
                    },
                },
                confirmPassword: {
                    required: requiredIf(() => !this.generateRandomPassword && !this.userIsEditing),
                    sameAsNewPassword: sameAs('password'),
                },
            },
            selectRole: {
                id: {
                    required,
                },
            },
        };
    },
    computed: {
        ...mapGetters('users', [Getters.GET_USER_BY_ID]),
        ...mapGetters('roles', {
            roles: Getters.GET_ROLES,
        }),
        getUserInfo() {
            return this[Getters.GET_USER_BY_ID](this.$route.params.id);
        },
    },
    async created() {
        try {
            this[Actions.LOAD_ROLE]();
            if (this.$route.params.id) {
                this.$Progress.start();
                this.userIsEditing = true;
                await this[Actions.LOAD_ALL_USERS]();
                const editingUser = this.getUserInfo;
                this.$set(this.user, 'name', editingUser.name);
                this.$set(this.user, 'email', editingUser.email);
                this.$set(this.user, 'state', editingUser.state);
                this.$set(this.user, 'externalUserId', editingUser.externalUserId);
                this.selectRole = this.roles.find(role => role.id === String(editingUser.userRoles[0].roleId));
                this.$Progress.finish();
            }
        } catch (error) {
            this.$eventBus.$emit('showAlert', {
                message: this.$i18n.t('alertMessage.failedToLoadNecessaryData'),
            });
            this.$Progress.fail();
        }
    },
    methods: {
        ...mapActions('users', [Actions.LOAD_ALL_USERS]),
        ...mapActions('roles', [Actions.LOAD_ROLE]),
        clearPassword() {
            this.user.password = '';
            this.user.confirmPassword = '';
            this.validationPassword = false;
            this.$v.user.password.$reset();
            this.$v.user.confirmPassword.$reset();
        },
        onCancel() {
            this.$router.go(-1);
        },
        async onSave() {
            if (this.isSaveButtonClicked) {
                return;
            }

            this.$v.$touch();

            if (this.$v.user.confirmPassword.$error && this.user.confirmPassword) {
                this.validationPassword = true;
            }

            if (!this.$v.$invalid) {
                try {
                    this.$Progress.start();
                    this.isSaveButtonClicked = true;
                    const user = {
                        name: this.user.name,
                        email: this.user.email,
                        role: this.selectRole.id,
                        csrfValue: this.$cookie.get('sessionToken'),
                        csrfKey: this.$cookie.get('sessionTokenKey'),
                    };
                    // Adding user requires filling password fields or checking generate password checkbox
                    // In case editing user.
                    // We have 2 options, if user wants to change password and without changing password.
                    // Anyway we should improve our validations here

                    if (!this.generateRandomPassword && this.user.password && this.$v.user.password.goodPassword) {
                        user.password = this.user.password;
                        user.passwordConfirmation = this.user.confirmPassword;
                    }

                    if (this.$route.params.id) {
                        user.id = Number(this.$route.params.id);
                        await updateUser(user);
                        this.successSaveMessage = this.$i18n.t('users.alerts.successfullyUpdated');
                    } else {
                        await addUser(user);
                        this.successSaveMessage = this.$i18n.t('users.alerts.successfullyAdded');
                    }
                    this.$eventBus.$emit('showAlert', {
                        message: this.successSaveMessage,
                        type: ALERT_TYPES.success,
                    });
                    setTimeout(
                        () =>
                            this.$router.push({
                                name: RouteNames.USER,
                                params: { companyId: this.$route.params.companyId },
                            }),
                        1000,
                    );
                } catch (error) {
                    const emailInUseResponse = error?.response?.data?.error === 'email is already in use';
                    const duplicateExternalId =
                        error?.response?.data?.error === 'unable to update user - duplicate external id';
                    if (emailInUseResponse) {
                        this.$eventBus.$emit('showAlert', {
                            message: this.$i18n.t('users.alerts.emailInUse'),
                        });
                    } else if (duplicateExternalId) {
                        this.showSupportAlert(this.$i18n.t('users.alerts.duplicateExternalId'), ALERT_TYPES.error);
                    } else {
                        this.showSupportAlert(this.$i18n.t('alertMessage.somethingWentWrong'), ALERT_TYPES.error);
                    }

                    this.isSaveButtonClicked = false;
                }
            } else {
                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('alertMessage.pleaseFixValidation'),
                });
                this.isSaveButtonClicked = false;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/palette';

.user-editor {
    background-color: $white;
}

.heading {
    margin: 0;
    padding-top: 2.25rem;
    padding-bottom: 1.25rem;
}

.password-requirement {
    width: 200px;
}

.password-tooltip {
    padding-left: 6px;
    padding-bottom: 4px;
}
</style>
