import UIComponentDefinition from '@/models/filters/uiComponents/UIComponentDefinition';
import { NumberOperations } from '@/common/filters/InputOperations';

export class SelectableDefinition extends UIComponentDefinition {
    value;

    label;

    constructor(json) {
        super();
        this.label = json.label;
        this.value = json.value != null ? String(json.value) : json.id || json.name;
    }

    /** Checks if rawValue belongs to this selectable */
    isValueOfThisSelectable(jsonValue) {
        return this.value === String(jsonValue.value);
    }

    /* eslint-disable-next-line no-unused-vars */
    buildJson(rawValue) {
        return this;
    } // override

    defaultValue() {
        return null;
    } // override

    displayProperty() {
        return 'label';
    } // override
}

export class SelectableParam extends SelectableDefinition {
    payload;

    constructor(json) {
        super(json);

        Object.entries(json).forEach(([k, v]) => {
            // $FlowFixMe, copy all fields from json. Used for CarrierAppsFilter
            if (!this[k]) this[k] = v;
        });

        this.payload = json.popup;
    }
}

export class SelectableNumber extends SelectableParam {
    constructor(json) {
        super(json);
        this.value = json.value;
    }

    displayProperty() {
        return 'value';
    }

    buildJson() {
        return { value: [this.value, null], op: NumberOperations.LessThan };
    }

    isValueOfThisSelectable(jsonValue) {
        return this.value === jsonValue.value[0];
    }
}

export class IntSelectable extends SelectableParam {
    buildJson() {
        return { value: [Number(this.value), null], op: NumberOperations.Equals };
    }

    isValueOfThisSelectable(jsonValue) {
        return Number(this.value) === jsonValue.value[0];
    }
}

const SelectableTypeToDefinitionClass = {
    SelectableParam,
    BoolSelectable: SelectableParam,
    SelectableCampaign: SelectableParam,
    CarrierApp: SelectableParam,
    SelectableNumber,
    IntSelectable,
};

export function SelectableDefinitionsFactory(selectsArrayJson) {
    return selectsArrayJson.map(selectableJson => {
        const selectableConstrName = Object.keys(selectableJson).length === 1 && Object.keys(selectableJson)[0];

        const selectablePayload = selectableConstrName ? selectableJson[selectableConstrName] : selectableJson;

        const SelectableConstructor = SelectableTypeToDefinitionClass[selectableConstrName];
        return new SelectableConstructor(selectablePayload);
    });
}
