
import Vue from 'vue';
import { mapGetters } from 'vuex';
import { Getters } from '@/store/mutation-types';

// Layout
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import FilterTable from '@/components/partials/FilterTable.vue';
import FilterTableMixin from '@/components/partials/FilterTableMixin.vue';
import AbstractListPageWrapper from '@/components/layout/AbstractListPageWrapper.vue';
import AppTable from '@/components/partials/AppTable.vue';
import TableFiltersTags from '@/components/filters/TableFiltersTags.vue';
import tableColumnType from '@/common/filterTable';
import ResponseModalButton from '@/components/partials/ResponseModalButton.vue';
import IconButton from '@/components/partials/IconButton.vue';
import Button from '@/common/button/Button';
import EntityOverview from '@/components/partials/entityOverview/EntityOverview.vue';
import OverviewList from '@/components/partials/entityOverview/OverviewList.vue';
import OverviewHeader from '@/components/partials/entityOverview/OverviewHeader.vue';
import LanguageSwitcher from '@/components/partials/inputs/LanguageSwitcher.vue';

// Types
import { ALERT_TYPES } from '@/common/alerts/Alert';
import ENTITY_TYPES from '@/common/entities/entityTypes';
import { ICON_TYPES } from '@/common/iconHelper';
import { EntityState } from '@/http';

// Dunning Profiles
import { getDunningProfiles, deleteDunningProfile } from '@/__new__/services/dno/billing/dunningProfilesService';
import { DunningProfileEntity } from '@/__new__/services/dno/billing/models/dunningProfilesPortal';

// Permissions
import permissionsService from '@/services/permissions/permissions.service';

// Misc
import * as Sentry from '@sentry/vue';
import RouteNames from '@/router/routeNames';
import { EDITOR_MODE } from '@/common/entities/entityHelper';

export default Vue.extend({
    name: 'DunningProfilesListPage',
    components: {
        AbstractListPageWrapper,
        AppButton,
        AppTable,
        FilterTable,
        IconButton,
        TableFiltersTags,
        ResponseModalButton,
        EntityOverview,
        OverviewList,
        OverviewHeader,
        LanguageSwitcher,
    },
    mixins: [FilterTableMixin],
    data() {
        return {
            ALERT_TYPES,
            BUTTON_TYPES,
            ICON_TYPES,
            EDITOR_MODE,
            ENTITY_TYPES,
            entities: [] as DunningProfileEntity[],
            dnoResponse: {},
            searchQueryForTable: '',
            isDataLoading: false,
            selectedEntity: null as DunningProfileEntity | null,
            isOverviewEnabled: false,
        };
    },
    computed: {
        ...mapGetters('operators', {
            selectedLanguage: Getters.languageDefault,
        }),
        /**
         * Subset of entities that are allowed to be displayed
         */
        displayedEntities(): any[] {
            // Omit deleted entities
            return this.entities.filter(e => e.state !== EntityState.DELETED);
        },
        /**
         * Details of the entity which are shown on the overview (right hand panel) when a row on the table (entity) is clicked
         */
        entityOverviewDetails() {
            if (!this.selectedEntity) {
                return [];
            }
            return [
                {
                    name: this.$i18n.t('generic.details'),
                    properties: [
                        {
                            label: this.$i18n.t('generic.id'),
                            value: this.selectedEntity.id,
                        },
                        {
                            label: this.$i18n.t('generic.name'),
                            value: this.selectedEntity.name,
                        },
                        {
                            label: this.$i18n.t('generic.description'),
                            value: this.selectedEntity.description,
                        },
                    ],
                },
            ];
        },
        filteredEntities() {
            return this.filteredEntitiesMixin(this.displayedEntities);
        },
        tableColumnsData() {
            return [
                {
                    name: this.$i18n.t('generic.name'),
                    key: 'name',
                    field: 'name',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('generic.description'),
                    key: 'description',
                    field: 'description',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
            ].filter(column => column);
        },
    },
    async created() {
        await this.loadEntities();
    },
    methods: {
        goToEditor() {
            this.$router.push({
                name: RouteNames.DUNNING_PROFILES_EDITOR,
                params: { companyId: this.$route.params.companyId, mode: EDITOR_MODE.EDIT },
            });
        },
        async loadEntities() {
            try {
                // Loading + Progress
                this.isDataLoading = true;
                this.$Progress.start();

                // Get Entities
                const data = await getDunningProfiles();
                this.dnoResponse = data.response;
                this.entities = data.entities;

                // Finish loading + progress
                this.isDataLoading = false;
                this.$Progress.finish();
            } catch (error) {
                this.$alert(this.$i18n.t('alertMessage.billing.failedToGetDunningProfiles'));
                Sentry.captureException(error);
                this.isDataLoading = false;
                this.$Progress.fail();
            }
        },
        /**
         * Creates an alert which confirms if entity should be deleted
         */
        confirmDelete(entity: DunningProfileEntity) {
            // Create delete button
            const button = new Button({
                label: this.$i18n.t('generic.delete'),
                alertType: ALERT_TYPES.warning,
                handler: () => {
                    this.deleteEntity(entity);
                },
            });

            // Deletion Confirmation Alert
            this.$alert(
                this.$i18n.t('alerts.areYouSureDeleteEntity', {
                    entityName: entity.name,
                }),
                {
                    type: ALERT_TYPES.warning,
                    buttons: [button],
                },
            );
        },
        /**
         * Sends request to DNO to delete the entity
         */
        async deleteEntity(entity: DunningProfileEntity) {
            try {
                // Send request
                this.$Progress.start();
                await deleteDunningProfile(entity);
                this.$Progress.finish();

                // Show success alert
                this.$alert(this.$i18n.t('alertMessage.billing.successfullyDeletedDunningProfile'), {
                    type: ALERT_TYPES.success,
                });

                // Reload entities
                await this.loadEntities();
            } catch (error) {
                this.$alert(this.$i18n.t('alertMessage.billing.failedToDeleteDunningProfile'), {
                    type: ALERT_TYPES.error,
                });
                this.$Progress.fail();
                Sentry.captureException(error);
            }
        },
        openEditor(entity: DunningProfileEntity, mode: EDITOR_MODE) {
            this.$router.push({
                name: RouteNames.DUNNING_PROFILES_EDITOR,
                params: {
                    id: entity.id,
                    mode,
                    companyId: this.$route.params.companyId,
                },
            });
        },
        onEntitySelected(entity: DunningProfileEntity) {
            this.selectedEntity = entity;
            this.isOverviewEnabled = true;
        },
        userHasWriteAccess(): boolean {
            return permissionsService.isFeatureFlagEnabled('postpaid-demo');
        },
        setSearchQuery(query) {
            this.searchQueryForTable = query;
        },
    },
});
