<template>
    <div
        class="auth-container"
        :class="{ 'app-in-iframe': isAppInIframe }"
    >
        <!-- Sidebar -->
        <Sidebar
            v-if="showNavigation"
            class="sidebar-container"
        />

        <!-- Main content -->
        <AppLoading
            :class="{ 'compact-sidebar': compactSidebar, 'disable-sidebar': !showNavigation }"
            class="page-content"
        >
            <router-view class="view h-100" />
            <Notification />
        </AppLoading>
    </div>
</template>

<script>
import { mapState } from 'vuex';

// Vue Components
import Sidebar from '@/components/layout/Sidebar.vue';
import Notification from '@/components/partials/Notification.vue';
import AppLoading from '@/components/partials/AppLoading.vue';

// Models
import Account from '@/models/Account';

// libraries and helpers
import * as Sentry from '@sentry/vue';
import { getOperatorConfigValue, isUserAllowed } from '@/services/permissions/permissions.service';
import RouteNames from '@/router/routeNames';

// http
import osstmofiberHTTP from '@/__new__/services/dno/osstmofiber/http/osstmofiber';

export default {
    name: 'AuthorizedContainer',

    components: {
        Sidebar,
        Notification,
        AppLoading,
    },

    computed: {
        ...mapState('operators', ['availableOperators']),
        currentCompany() {
            return this.availableOperators?.[this.$route.params.companyId];
        },
        compactSidebar() {
            return this.$store.state.compactSidebar;
        },
        isAppInIframe() {
            return window !== window.parent;
        },
        showNavigation() {
            return getOperatorConfigValue('allowDisableNavigation') ? isUserAllowed('UMNavigation') : true;
        },
    },

    watch: {
        currentCompany: {
            handler() {
                this.updateSentryContext();
            },
            immediate: true,
        },
        $route: {
            handler() {
                this.updateSentryContext();
            },
            immediate: true,
        },
    },

    created() {
        this.$store.dispatch('operators/setAllOperators');
        this.$store.dispatch('operators/setActiveOperator');

        // Adding user info to Sentry payload
        Sentry.getCurrentScope().setUser(Account.storedInstance);
    },

    mounted() {
        this.requestAtlasRedirect();
    },

    methods: {
        updateSentryContext() {
            if (!this.currentCompany) {
                return;
            }

            this.$store.dispatch('operators/setLanguages');

            const scope = Sentry.getCurrentScope();
            scope.setTag('route', this.$route.fullPath);
            scope.setTag('tenant.name', this.currentCompany.caption);
            scope.setExtra('tenant', this.currentCompany.dnoIdentifier);
        },
        async requestAtlasRedirect() {
            await this.$withProgressBar(
                async () => {
                    if (isUserAllowed('LoginFromAtlas')) {
                        if (localStorage.getItem('assistedam-uri')) {
                            const assistedamUriObject = JSON.parse(localStorage.getItem('assistedam-uri'));
                            localStorage.removeItem('assistedam-uri');
                            const params = {};
                            assistedamUriObject?.fullPath?.replace(/[?&]+([^=&]+)=([^&]*)/gi, (m, key, value) => {
                                params[key] = value;
                            });
                            // Right now we whitelist any referer but that will be changed.
                            if (params?.Referer && params?.Source === 'retailCare') {
                                const response = await osstmofiberHTTP.getAccountFromAuthCode(params.DASAuthCode);
                                if (response?.data?.account_id) {
                                    this.$router.push({
                                        name: RouteNames.CCS_ACCOUNT_PAGE,
                                        params: { id: response?.data?.account_id },
                                    });
                                }
                            } else {
                                this.$alert(this.$i18n.t('alertMessage.login.loginViaAtlas'));
                            }
                        } else {
                            this.$alert(this.$i18n.t('alertMessage.login.loginViaAtlas'));
                        }
                    }
                },
                {
                    errorHandler: () => {
                        this.$alert(this.$i18n.t('alertMessage.failedToGetAccountFromAuth'));
                    },
                },
            );
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/sidebar.scss';
@import '~@/assets/scss/z-indexes';

.auth-container {
    height: 100%;
    display: flex;
    flex-direction: row;
}

.sidebar-container {
    z-index: $page-layout-item-z-index;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
}

.page-content {
    position: relative;
    width: 100%;
    overflow-x: hidden;
    margin-left: $sidebarFullWidth;
    transition: margin-left 0.5s;

    &.compact-sidebar {
        margin-left: $sidebarCompactWidth;
    }

    &.disable-sidebar {
        margin-left: 0;
    }
}
</style>
