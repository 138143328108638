import { AxiosPromise } from 'axios';
import http, { DnoResponseBase } from '@/http/index';
import { TariffSpecGroup } from '@/__new__/services/dno/charging/models/TariffSpecificationGroup';

interface TariffSpecGroupApiResponse extends DnoResponseBase {
    tariff_spec_group_by_id: Record<string, any>;
}

/**
 * Get tariff specification group groups.
 *
 * @returns {AxiosPromise}
 */
export function getTariffSpecGroups(
    ids: string[] = [],
    returnChargingVersion = true,
    includeReferencedEntities = false,
    chargingVersion?: string,
): AxiosPromise<TariffSpecGroupApiResponse> {
    return http({
        method: 'POST',
        url: '/v3charging/gettariffspecgroups',
        data: {
            ids,
            return_charging_version: returnChargingVersion,
            include_referenced_entities: includeReferencedEntities,
            charging_version: chargingVersion,
        },
    });
}

/**
 * Add tariff specification group.
 *
 * @returns {AxiosPromise}
 */
export function addTariffSpecGroup(data: any): AxiosPromise<any> {
    return http({
        method: 'POST',
        url: '/v3chargingwrite/addtariffspecgroup',
        data,
    });
}

/**
 * Update tariff specification group.
 *
 * @returns {AxiosPromise}
 */
export function updateTariffSpecGroup(
    id: string,
    version: number,
    data: TariffSpecGroup,
): AxiosPromise<TariffSpecGroup> {
    return http({
        method: 'POST',
        url: '/v3chargingwrite/updatetariffspecgroup',
        data: {
            id,
            version,
            data,
        },
    });
}

/**
 * Update tariff specification group state.
 *
 * @returns {AxiosPromise}
 */
export function updateTariffSpecGroupState(id: string, version: number, state: number): AxiosPromise<TariffSpecGroup> {
    return http({
        method: 'POST',
        url: '/v3chargingwrite/updatestatetariffspecgroup',
        data: {
            id,
            version,
            state,
        },
    });
}

interface TariffSpecGroupsDraftListApiResponse extends DnoResponseBase {
    data: any;
}

/**
 * Get tariff specification groups draft list.
 *
 * @returns {AxiosPromise}
 */
export function getTariffSpecGroupDraftList(): AxiosPromise<TariffSpecGroupsDraftListApiResponse> {
    return http({
        method: 'POST',
        url: '/v3charging/gettariffspecgroupsdraftlist',
    });
}

/**
 * Get tariff specification group entity draft.
 *
 * @returns {AxiosPromise}
 */
export function getTariffSpecGroupEntityDraft(id: string): AxiosPromise<DnoResponseBase> {
    return http({
        method: 'POST',
        url: '/v3charging/gettariffspecgroupdraft',
        data: {
            id,
        },
    });
}

/**
 * Set tariff specification group draft.
 *
 * @returns {AxiosPromise}
 */
type TariffSpecGroupDraftPayload = {
    id?: string;
    data: object;
};

export function setTariffSpecGroupDraft(data: TariffSpecGroupDraftPayload): AxiosPromise<DnoResponseBase> {
    return http({
        method: 'POST',
        url: '/v3charging/settariffspecgroupdraft',
        data,
    });
}

/**
 * Delete tariff specification group draft.
 *
 * @returns {AxiosPromise}
 */
export function deleteTariffSpecGroupDraft(id: string): AxiosPromise<DnoResponseBase> {
    return http({
        method: 'POST',
        url: '/v3charging/deletetariffspecgroupdraft',
        data: {
            id,
        },
    });
}

export default {
    getTariffSpecGroups,
    addTariffSpecGroup,
    updateTariffSpecGroup,
    updateTariffSpecGroupState,
    getTariffSpecGroupDraftList,
    getTariffSpecGroupEntityDraft,
    setTariffSpecGroupDraft,
    deleteTariffSpecGroupDraft,
};
