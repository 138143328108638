import { TranslateResult } from 'vue-i18n';
import i18n from '@/i18n';
import { LABEL_COLOR } from '@/common/labelsHelper';

export enum FLAG_TYPE {
    IS_TEST = 'is_test',
    RESTRICTED = 'restricted',
    HAS_TRIAL = 'has_trial',
    TAX_EXEMPTION = 'tax_exemption',
    IS_LOCALLY_TIED = 'is_locally_tied',
    IS_BLOCKED = 'is_blocked',
    PROFILE_DOWNLOADED = 'profile_downloaded',
    IS_IN_COOLDOWN = 'is_in_cooldown',
    IS_FRAUD = 'is_fraud',
    CANCEL_REQUESTED = 'cancel_requested',
    PORT_IN_INITIATED = 'port_in_initiated',
    PORT_OUT_INITIATED = 'port_out_initiated',
    IS_DNO_BLOCKED = 'is_dno_blocked',
    IS_PROFILE_DELETED = 'is_profile_marked_as_deleted',
    IS_ENTERPRISE = 'is_enterprise',
    TC_GLOBAL_ACCEPTED = 'auto_payment',
    IS_PASSWORD_UPDATE_REQUIRED = 'is_password_update_required',
    IS_OTP_VALIDATED = 'is_otp_validated',
    IS_PIN_SETUP = 'is_pin_setup',
    SUSPEND_LINE_LOST = 'suspend_line_lost',
    SUSPEND_LINE_STOLEN = 'suspend_line_stolen',
    BLOCK_PORT_OUT = 'block_port_out',
    IS_SIM_SWAP_BLOCKED = 'is_sim_swap_blocked',
}

export enum FLAG_MAP {
    FALSE = 0,
    FALSE_PENDING = 1,
    TRUE_PENDING = 2,
    TRUE = 3,
}

export const FLAG_BOOLEAN_VALUE_MAP: Record<FLAG_MAP, boolean> = {
    [FLAG_MAP.TRUE]: true,
    [FLAG_MAP.TRUE_PENDING]: true,
    [FLAG_MAP.FALSE]: false,
    [FLAG_MAP.FALSE_PENDING]: false,
};

export const FLAG_NAME_MAP: Record<FLAG_TYPE, TranslateResult> = {
    [FLAG_TYPE.IS_TEST]: i18n.t('customerCare.account.flagTypes.isTest'),
    [FLAG_TYPE.RESTRICTED]: i18n.t('customerCare.account.flagTypes.restricted'),
    [FLAG_TYPE.HAS_TRIAL]: i18n.t('customerCare.account.flagTypes.hasTrial'),
    [FLAG_TYPE.TAX_EXEMPTION]: i18n.t('customerCare.account.flagTypes.taxExemption'),
    [FLAG_TYPE.IS_LOCALLY_TIED]: i18n.t('customerCare.userInformation.localTies'),
    [FLAG_TYPE.IS_BLOCKED]: i18n.t('customerCare.subscriberTab.blocked'),
    [FLAG_TYPE.PROFILE_DOWNLOADED]: i18n.t('customerCare.subscriberTab.profileDownloaded'),
    [FLAG_TYPE.IS_IN_COOLDOWN]: i18n.t('customerCare.subscriberTab.allowedToPortOut'),
    [FLAG_TYPE.IS_FRAUD]: i18n.t('customerCare.subscriberTab.isFraud'),
    [FLAG_TYPE.CANCEL_REQUESTED]: i18n.t('customerCare.subscriberTab.cancelRequested'),
    [FLAG_TYPE.PORT_IN_INITIATED]: i18n.t('customerCare.subscriberTab.portInInitiated'),
    [FLAG_TYPE.PORT_OUT_INITIATED]: i18n.t('customerCare.subscriberTab.portOutInitiated'),
    [FLAG_TYPE.IS_DNO_BLOCKED]: i18n.t('customerCare.subscriberTab.dnoBlocked'),
    [FLAG_TYPE.IS_PROFILE_DELETED]: i18n.t('customerCare.account.flagTypes.isProfileDeleted'),
    [FLAG_TYPE.IS_ENTERPRISE]: i18n.t('customerCare.account.enterprise'),
    [FLAG_TYPE.TC_GLOBAL_ACCEPTED]: i18n.t('customerCare.consentsContextType.autoPaymentTc'),
    [FLAG_TYPE.IS_PASSWORD_UPDATE_REQUIRED]: i18n.t('customerCare.account.flagTypes.isPasswordUpdateRequired'),
    [FLAG_TYPE.IS_OTP_VALIDATED]: i18n.t('customerCare.account.flagTypes.isOtpValidated'),
    [FLAG_TYPE.IS_PIN_SETUP]: i18n.t('customerCare.account.flagTypes.isPinSetup'),
    [FLAG_TYPE.SUSPEND_LINE_LOST]: i18n.t('customerCare.account.flagTypes.suspendLineLost'),
    [FLAG_TYPE.SUSPEND_LINE_STOLEN]: i18n.t('customerCare.account.flagTypes.suspendLineStolen'),
    [FLAG_TYPE.BLOCK_PORT_OUT]: i18n.t('customerCare.account.flagTypes.blockPortOut'),
    [FLAG_TYPE.IS_SIM_SWAP_BLOCKED]: i18n.t('customerCare.account.flagTypes.isSimSwapBlocked'),
};

export const FLAG_VALUE_LABEL_MAP = new Map([
    [FLAG_MAP.TRUE, i18n.t('generic.yes')],
    [FLAG_MAP.TRUE_PENDING, `${i18n.t('generic.yes')} (${i18n.t('finalStateMapper.Pending')})`],
    [FLAG_MAP.FALSE, i18n.t('generic.no')],
    [FLAG_MAP.FALSE_PENDING, `${i18n.t('generic.no')} (${i18n.t('finalStateMapper.Pending')})`],
]);

export const FLAG_STATUS_TO_LABEL = new Map([
    [FLAG_MAP.FALSE, i18n.t('generic.disabled')],
    [FLAG_MAP.TRUE, i18n.t('generic.enabled')],
]);

export const FLAG_STATUS_TO_COLOR = new Map([
    [FLAG_MAP.FALSE, LABEL_COLOR.red],
    [FLAG_MAP.TRUE, LABEL_COLOR.green],
]);

export enum FLAG_IS_ENTERPRISE_B_R {
    RESIDENTIAL = 0,
    BUSINESS = 1,
    ENTERPRISE = 3,
}

export const FLAG_IS_ENTERPRISE_VALUE_LABEL_MAP = new Map([
    [FLAG_IS_ENTERPRISE_B_R.RESIDENTIAL, i18n.t('customerCare.account.residential')],
    [FLAG_IS_ENTERPRISE_B_R.BUSINESS, i18n.t('customerCare.account.business')],
    [FLAG_IS_ENTERPRISE_B_R.ENTERPRISE, i18n.t('customerCare.account.enterprise')],
]);

export enum FLAG_TAX_EXEMPTION {
    DECLINE = 0,
    REQUEST_DECLINE = 1,
    REQUEST_APPROVED = 2,
    APPROVED = 3,
}

export const FLAG_TAX_EXEMPTION_VALUE_LABEL_MAP = new Map([
    [FLAG_TAX_EXEMPTION.DECLINE, i18n.t('finalStateMapper.declined')],
    [FLAG_TAX_EXEMPTION.REQUEST_DECLINE, i18n.t('customerCare.account.declineReq')],
    [FLAG_TAX_EXEMPTION.REQUEST_APPROVED, i18n.t('customerCare.account.approveReq')],
    [FLAG_TAX_EXEMPTION.APPROVED, i18n.t('finalStateMapper.Approved')],
]);

export enum FLAG_TC_GLOBAL_ACCEPTED {
    FALSE = 0,
    TRUE = 3,
}

export const FLAG_TC_GLOBAL_ACCEPTED_VALUE_LABEL_MAP = new Map([
    [FLAG_TC_GLOBAL_ACCEPTED.FALSE, i18n.t('customerCare.messageStates.notAccepted')],
    [FLAG_TC_GLOBAL_ACCEPTED.TRUE, i18n.t('customerCare.messageStates.accepted')],
]);

export enum FLAG_TC_GLOBAL_ACCEPTED_TEXT {
    ACCEPTED = 'Accepted',
    NOT_ACCEPTED = 'Not Accepted',
}

export enum FLAG_IS_LOCALLY_TIED {
    NO = 0,
    YES = 3,
}

export const FLAG_IS_LOCALLY_TIED_LABEL_MAP = new Map([
    [FLAG_IS_LOCALLY_TIED.NO, i18n.t('generic.no')],
    [FLAG_IS_LOCALLY_TIED.YES, i18n.t('generic.yes')],
]);

export type FlagIsLocallyTiedOption = { key: FLAG_IS_LOCALLY_TIED; label: TranslateResult };

export interface Flags<Type = Flag> {
    [key: string]: Type;
}

export type FlagStatus = {
    name: Flag['name'];
    value: Flag['detailedValue'];
};

export type FlagValue =
    | FLAG_MAP
    | FLAG_IS_ENTERPRISE_B_R
    | FLAG_TAX_EXEMPTION
    | FLAG_TC_GLOBAL_ACCEPTED
    | FLAG_IS_LOCALLY_TIED
    | undefined;

export const ACCOUNT_FLAGS = [
    FLAG_TYPE.RESTRICTED,
    FLAG_TYPE.HAS_TRIAL,
    FLAG_TYPE.CANCEL_REQUESTED,
    FLAG_TYPE.IS_IN_COOLDOWN,
    FLAG_TYPE.IS_TEST,
    FLAG_TYPE.TAX_EXEMPTION,
    FLAG_TYPE.BLOCK_PORT_OUT,
    FLAG_TYPE.IS_SIM_SWAP_BLOCKED,
];

export const SUBSCRIBER_FLAGS = [
    FLAG_TYPE.PROFILE_DOWNLOADED,
    FLAG_TYPE.IS_BLOCKED,
    FLAG_TYPE.IS_IN_COOLDOWN,
    FLAG_TYPE.IS_FRAUD,
    FLAG_TYPE.CANCEL_REQUESTED,
    FLAG_TYPE.PORT_IN_INITIATED,
    FLAG_TYPE.PORT_OUT_INITIATED,
    FLAG_TYPE.IS_DNO_BLOCKED,
    FLAG_TYPE.SUSPEND_LINE_LOST,
    FLAG_TYPE.SUSPEND_LINE_STOLEN,
    FLAG_TYPE.BLOCK_PORT_OUT,
    FLAG_TYPE.IS_SIM_SWAP_BLOCKED,
    FLAG_TYPE.IS_TEST,
];

export const USER_FLAGS = [
    FLAG_TYPE.IS_LOCALLY_TIED,
    FLAG_TYPE.IS_TEST,
    FLAG_TYPE.IS_PROFILE_DELETED,
    FLAG_TYPE.IS_PASSWORD_UPDATE_REQUIRED,
    FLAG_TYPE.IS_OTP_VALIDATED,
    FLAG_TYPE.IS_PIN_SETUP,
];

export default class Flag {
    field: FLAG_TYPE;
    name: TranslateResult;
    value: boolean | undefined;
    originalValue: FlagValue;
    detailedValue: TranslateResult;
    disabled = false;

    constructor(field: FLAG_TYPE, value: FlagValue) {
        this.field = field;
        this.originalValue = value;
        this.name = FLAG_NAME_MAP[this.field];
        this.value = Flag.getBooleanValue(field, value);
        this.detailedValue = Flag.getValueLabel(field, value) || i18n.t('generic.N/A');
        this.disabled = Flag.isDisabled(field, value);
    }

    static getBooleanValue(field: FLAG_TYPE, value: FlagValue) {
        switch (field) {
            case FLAG_TYPE.IS_TEST:
                return value === FLAG_MAP.TRUE;
            default:
                return FLAG_BOOLEAN_VALUE_MAP[value as FLAG_MAP];
        }
    }

    static getValueLabel(field: FLAG_TYPE, value: FlagValue) {
        switch (field) {
            case FLAG_TYPE.IS_ENTERPRISE:
                return FLAG_IS_ENTERPRISE_VALUE_LABEL_MAP.get(value as FLAG_IS_ENTERPRISE_B_R);

            case FLAG_TYPE.TC_GLOBAL_ACCEPTED:
                return FLAG_TC_GLOBAL_ACCEPTED_VALUE_LABEL_MAP.get(value as FLAG_TC_GLOBAL_ACCEPTED);

            case FLAG_TYPE.TAX_EXEMPTION:
                return FLAG_TAX_EXEMPTION_VALUE_LABEL_MAP.get(value as FLAG_TAX_EXEMPTION);

            default:
                return FLAG_VALUE_LABEL_MAP.get(value as FLAG_MAP);
        }
    }

    static isDisabled(field: FLAG_TYPE, value: FlagValue) {
        switch (field) {
            case FLAG_TYPE.IS_PROFILE_DELETED:
                return value === FLAG_MAP.FALSE_PENDING || value === FLAG_MAP.TRUE_PENDING;
            default:
                return false;
        }
    }

    static mapFlags(entityFlags: FLAG_TYPE[], flags: Flags<number>) {
        const processedFlags: Flags = {};
        entityFlags.forEach(flag => {
            if (Object.hasOwnProperty.call(flags, flag)) {
                processedFlags[flag] = new Flag(flag, flags[flag]);
            }
        });
        return processedFlags;
    }

    static mapAccountFlags(flags: Flags<number> = {}) {
        return Flag.mapFlags(ACCOUNT_FLAGS, flags);
    }

    static mapSubscriberFlags(flags: Flags<number> = {}) {
        return Flag.mapFlags(SUBSCRIBER_FLAGS, flags);
    }

    static mapUserFlags(flags: Flags<number> = {}) {
        return Flag.mapFlags(USER_FLAGS, flags);
    }

    static mapFlagStatus(flags: Flags) {
        const flagsStatus: FlagStatus[] = [];

        Object.values(flags).forEach(flag => {
            flagsStatus.push({
                name: flag.name,
                value: flag.detailedValue,
            });
        });

        return flagsStatus;
    }

    static isFlagSuspendingSomeServices(flag: Flag) {
        if (flag.field === FLAG_TYPE.IS_PROFILE_DELETED) {
            return [FLAG_MAP.FALSE_PENDING, FLAG_MAP.TRUE_PENDING, FLAG_MAP.TRUE].includes(
                flag.originalValue as FLAG_MAP,
            );
        }
        return (
            [FLAG_TYPE.IS_BLOCKED, FLAG_TYPE.IS_FRAUD, FLAG_TYPE.CANCEL_REQUESTED, FLAG_TYPE.IS_DNO_BLOCKED].includes(
                flag.field,
            ) && flag.value
        );
    }
}
