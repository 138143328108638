import { AxiosPromise } from 'axios';
import http from '@/http/index';

export function getBillingAccountById(id: number): AxiosPromise<any> {
    return http({
        method: 'GET',
        url: `/billingaccount/${id}`,
        headers: {
            'Content-type': 'application/json',
        },
    });
}

export function updateBillingAccount(id: number, billingAccount: any): AxiosPromise<any> {
    return http({
        method: 'PATCH',
        url: `/billingaccount/${id}`,
        data: billingAccount,
    });
}

export function generateHotBillForBillingAccount(hotBillParams: any): AxiosPromise<any> {
    return http({
        method: 'POST',
        url: `/billingaccount/generatehotbill`,
        data: hotBillParams,
    });
}
