import { AxiosPromise } from 'axios';
import httpClient from '@/http/index';

/**
 * Gets a string label describing the environment (if configured)
 * Eg: 'Staging'
 * @returns {Promise<string>}
 */
export function getEnvLabel(): AxiosPromise<string> {
    return httpClient({
        method: 'GET',
        url: '/env/label',
    });
}

/**
 * Gets url of the grafan host for the environment (if configured)
 * Eg: 'Staging'
 * @returns {Promise<string>}
 */
export function getGrafanaHost(): AxiosPromise<string> {
    return httpClient({
        method: 'GET',
        url: '/env/grafanahost',
    });
}
