import Vue from 'vue';
import { createTreeNode } from '@/common/conditions-tree';
import { convertToClientTree, convertToServerTree } from '@/common/conditionsTreeConverter';
import { cloneDeep } from 'lodash';

export default {
    namespaced: true,
    state: {
        /* It is not necessary to clean up map data
         * Getter tree is always fresh and will be sent to server.
         * Map is used only for accessing node by id when needed.
         */
        conditionsMap: null,
    },
    getters: {
        conditionsTree(state) {
            if (!state.conditionsMap) return null;
            return Object.values(state.conditionsMap).find(node => !node.parentId);
        },
        nodeById: state => id => {
            if (!state.conditionsMap || !id) return null;
            return state.conditionsMap[id];
        },
    },
    mutations: {
        nestTree(state, newNode) {
            // get the original node
            const node = state.conditionsMap[newNode.id];
            // update parent reference
            if (node.parentId) {
                const parentNode = state.conditionsMap[node.parentId];
                Vue.set(parentNode.nested, node.id, newNode);
            }
            // copy original node with new id
            const copyNode = createTreeNode({
                parentId: node.id, // parent is its old id
                operation: node.operation,
                args: node.args,
                nested: node.nested,
                config: node.config,
            });
            // update child nodes parentId
            for (let key in copyNode.nested) {
                copyNode.nested[key].parentId = copyNode.id;
                const childNode = state.conditionsMap[key];
                Vue.set(childNode, 'parentId', copyNode.id);
            }
            // add clone
            Vue.set(state.conditionsMap, copyNode.id, copyNode);
            // replace the original node with new node
            Vue.set(state.conditionsMap, node.id, newNode);
            // add original as child of new node
            Vue.set(state.conditionsMap[newNode.id], 'nested', {});
            Vue.set(state.conditionsMap[newNode.id].nested, copyNode.id, copyNode);
        },
        updateTree(state, node) {
            if (!state.conditionsMap) {
                state.conditionsMap = {};
            }
            Vue.set(state.conditionsMap, node.id, node);
            if (!node.parentId) return;
            const parentNode = state.conditionsMap[node.parentId];
            if (!parentNode.nested) {
                parentNode.nested = {};
            }
            Vue.set(parentNode.nested, node.id, node);
        },
        deleteTreeNode(state, context) {
            const nodeId = context.nodeId;
            const transferChildrenToParent = context.transferChildrenToParent;

            const node = state.conditionsMap[nodeId];
            const { parentId } = node;

            const parentNode = parentId ? state.conditionsMap[parentId] : null;
            if (parentNode) {
                const siblings = cloneDeep(parentNode.nested); // re-do to maintain order
                Vue.set(parentNode, 'nested', {});
                for (let siblingId in siblings) {
                    if (siblingId === nodeId) {
                        if (transferChildrenToParent) {
                            for (let childId in node.nested) {
                                const childNode = state.conditionsMap[childId];
                                Vue.set(childNode, 'parentId', parentId); // change parentId of children to grandparent
                                Vue.set(parentNode.nested, childId, childNode); // add childId to grandparent nested
                            }
                        }
                    } else {
                        Vue.set(parentNode.nested, siblingId, siblings[siblingId]); // add back
                    }
                }
            } else {
                // transferring to root (should only have one)
                if (transferChildrenToParent) {
                    const childNode = state.conditionsMap[Object.keys(node.nested)[0]];
                    Vue.set(childNode, 'parentId', null); // change parentId
                }
            }

            Vue.delete(state.conditionsMap, nodeId); // delete node
        },
        clearConditionsMap(state) {
            state.conditionsMap = null;
        },
    },
    actions: {
        buildClientConditionsTree({ commit }, context) {
            const root = context.root;
            const optionsConfig = context.optionsConfig;
            const nodesToUpdate = convertToClientTree(root, optionsConfig);
            nodesToUpdate.forEach(n => commit('updateTree', n));
        },
        buildServerConditionsTree(_, root) {
            return convertToServerTree(root);
        },
    },
};
