import { LABEL_COLOR } from '@/common/labelsHelper';
import localeLibrary from '@/common/locale/localeLibrary';
import i18n from '@/i18n';

/**
 * Interface representing an Application Owner entity
 */
export interface ApplicationOwner {
    /** Unique identifier */
    id: string;
    /** Description of the application owner */
    description: string;
    /** Name of the application owner */
    name: string;
    /** Current status */
    status: string;
    /** Reason for the current status */
    statusReason: string;
    /** Reference URL */
    href: string;
    /** Current approval status (approved, rejected, pendingApproval, etc.) */
    approvalStatus: string;
    /** Reason for the approval status */
    approvalStatusReason: string;
    /** Start date/time as epoch timestamp */
    startDateTime?: number;
    /** End date/time as epoch timestamp */
    endDateTime?: number;
    /** Formatted start date/time string */
    startDateTimeString?: string;
    /** Formatted end date/time string */
    endDateTimeString?: string;
    /** Last update timestamp */
    lastUpdateTimestamp?: number;
    /** Channel partner ID */
    channelPartnerId: string;
    /** Name of the associated channel partner */
    channelPartnerName: string;
    /** Name of the engaged party */
    engagedPartyName: string;
    /** Original raw data from the API response */
    rawData: ApplicationOwnerBackend;
}

export enum ApplicationOwnerStatusType {
    ACTIVE = 'active',
    INACTIVE = 'inactive',
}

export enum ApprovalStatusType {
    APPROVED = 'approved',
    REJECTED = 'rejected',
    PENDING_APPROVAL = 'pendingApproval',
}

export const APPROVAL_STATUS_TO_COLOR_MAPPING = new Map([
    [ApprovalStatusType.APPROVED, LABEL_COLOR.green],
    [ApprovalStatusType.PENDING_APPROVAL, LABEL_COLOR.yellow],
    [ApprovalStatusType.REJECTED, LABEL_COLOR.red],
]);

export const APPROVAL_STATUS_TO_LABEL_MAPPING = new Map([
    [ApprovalStatusType.APPROVED, i18n.t('generic.stateMap.approved')],
    [ApprovalStatusType.PENDING_APPROVAL, i18n.t('generic.stateMap.pendingApproval')],
    [ApprovalStatusType.REJECTED, i18n.t('generic.stateMap.rejected')],
]);

/**
/**
 * Interface representing the raw Application Owner data from the backend
 */
export interface ApplicationOwnerBackend {
    /** Unique identifier */
    id: string;
    /** Description of the application owner */
    description?: string;
    /** Name of the application owner */
    name?: string;
    /** Current status */
    status?: ApplicationOwnerStatusType;
    /** Reason for the current status */
    statusReason?: string;
    /** Reference URL */
    href?: string;
    /** Current approval status */
    approvalStatus?: ApprovalStatusType;
    /** Reason for the approval status */
    approvalStatusReason?: string;
    /** Valid period information */
    validFor?: {
        /** Start date/time in ISO 8601 format */
        startDateTime?: string;
        /** End date/time in ISO 8601 format */
        endDateTime?: string;
    };
    /** Last update time in ISO 8601 format */
    lastUpdate?: string;
    /** Engaged party information */
    engagedParty: any;
    /** Channel partner information */
    channelPartner?: {
        name?: string;
        id?: string;
        href?: string;
        partyId?: string;
        partyName?: string;
    };
}

export function mapApplicationOwnerToFeData(appOwner: ApplicationOwnerBackend): ApplicationOwner {
    const startDateTime = localeLibrary.iso8601ToEpoch(appOwner?.validFor?.startDateTime);
    const endDateTime = localeLibrary.iso8601ToEpoch(appOwner?.validFor?.endDateTime);
    return {
        id: appOwner.id || '',
        description: appOwner?.description || '',
        name: appOwner?.name || '',
        status: appOwner?.status || '',
        statusReason: appOwner?.statusReason || '',
        href: appOwner?.href || '',
        approvalStatus: appOwner?.approvalStatus || '',
        approvalStatusReason: appOwner?.approvalStatusReason || '',
        startDateTime,
        endDateTime,
        startDateTimeString: localeLibrary.getFormattedDateAndTime(startDateTime).toString(),
        endDateTimeString: localeLibrary.getFormattedDateAndTime(endDateTime).toString(),
        lastUpdateTimestamp: localeLibrary.iso8601ToEpoch(appOwner?.lastUpdate),
        channelPartnerId: appOwner?.channelPartner?.id || '',
        channelPartnerName: appOwner?.channelPartner?.name || '',
        engagedPartyName: appOwner?.engagedParty?.name || '',
        rawData: appOwner,
    };
}
