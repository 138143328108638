
import { LABEL_COLOR, LABEL_COLOR_TO_BACKGROUND_MAP } from '@/common/labelsHelper';

export default {
    name: 'AppLabel',
    props: {
        title: {
            type: String,
            required: true,
        },
        color: {
            type: String,
            default: LABEL_COLOR.gray,
            validator(color) {
                return Object.values(LABEL_COLOR).includes(color);
            },
        },
    },
    computed: {
        backgroundColor(): string | undefined {
            return LABEL_COLOR_TO_BACKGROUND_MAP.get(this.color);
        },
    },
};
