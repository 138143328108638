export const VISIBILITY_OPTIONS = {
    PRIVATE: {
        label: 'Private',
        value: 0,
    },
    PUBLIC: {
        label: 'Public',
        value: 1,
    },
    DELETED: {
        label: 'Deleted',
        value: 2,
    },
};

export default {};
