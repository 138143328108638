
import { PropType } from 'vue';

// Components
import AppInputV3 from '@/components/partials/inputs/AppInputV3.vue';
import AppDialogV2 from '@/components/partials/AppDialogV2.vue';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';

// http
import ossmvneHTTP, { NetworkCoverageInfo } from '@/__new__/services/dno/ossmvne/http/ossmvne';

// Helpers
import { chunk } from 'lodash';
import { ADDRESS_TYPE, USER_MANAGER_HIERARCHY } from '@/__new__/features/customerCare/common/customerCareHelper';
import Subscriber from '@/__new__/services/dno/user/models/Subscriber';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';

export default {
    name: 'NetworkCoverageModal',
    components: {
        AppDialogV2,
        AppButton,
        AppInputV3,
    },
    mixins: [validationMixin],
    props: {
        visible: {
            required: true,
            type: Boolean,
        },
        subscriber: {
            default: null,
            type: Object as PropType<Subscriber | null>,
        },
    },
    data() {
        return {
            isLoading: false,
            address: {
                street1: '',
                street2: '',
                city: '',
                state: '',
                zip: '',
                zip4: '',
            },
            coverage: null as NetworkCoverageInfo | null,

            BUTTON_TYPES,
        };
    },
    computed: {
        coverageGroups(): Array<[string, string][]> {
            const bands = Object.entries(this.coverage?.bands || {});
            return [Object.entries(this.coverage?.status || {}), ...chunk(bands, Math.ceil(bands.length / 2))];
        },
        submitDisabled(): boolean {
            return this.$v.$invalid;
        },
    },
    validations() {
        return {
            address: {
                street1: { required },
                city: { required },
                state: { required },
                zip: { required },
            },
        };
    },
    watch: {
        subscriber: {
            deep: true,
            immediate: true,
            handler() {
                const ppuAddress = Object.values(this.subscriber?.addresses || {}).find(
                    address => address.type === ADDRESS_TYPE.PPU,
                );
                if (ppuAddress) {
                    this.address = {
                        street1: ppuAddress.address_1,
                        street2: ppuAddress.address_2,
                        city: ppuAddress.city,
                        state: ppuAddress.state,
                        zip: ppuAddress.zip,
                        zip4: ppuAddress.zip_4,
                    };
                }
            },
        },
    },
    methods: {
        async onSubmit() {
            this.$v.$touch();

            if (this.$v.$invalid) {
                return;
            }

            this.isLoading = true;
            await this.$withProgressBar(
                async () => {
                    const response = await ossmvneHTTP.subscriberNetworkCoverage({
                        target_id: this.subscriber?.id || '',
                        target_type: USER_MANAGER_HIERARCHY.SUBSCRIBER,
                        ...this.address,
                    });
                    this.coverage = response.data;
                },
                () => {
                    this.$alert(this.$t('alertMessage.somethingWentWrongFetchingCoverageData'));
                },
            );
            this.isLoading = false;
        },
    },
};
