<template>
    <div class="sensitive-container">
        <div class="sensitive-title-wrapper">
            <span class="sensitive-title sensitive">
                {{ $i18n.t('generic.sensitiveEdit') }}
            </span>
        </div>
        <div class="sensitive-text">
            {{ $i18n.t('messages.sensitiveInfo') }}
        </div>
        <div class="sensitive-buttons">
            <AppButton
                :label="$i18n.t('generic.cancel')"
                @click="close"
            />
            <AppButton
                :buttonType="BUTTON_TYPES.PRIMARY"
                :iconType="ICON_TYPES.LOCK"
                :label="$i18n.t('generic.proceed')"
                class="ml-5"
                @click="onModalAction"
            />
        </div>
    </div>
</template>

<script>
// Components
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import { ICON_TYPES } from '@/common/iconHelper';

export default {
    name: 'SensitiveEditModal',
    components: {
        AppButton,
    },
    data() {
        return {
            ICON_TYPES,
            BUTTON_TYPES,
        };
    },
    methods: {
        onModalAction() {
            this.$emit('proceed');
        },
        close() {
            this.$emit('close');
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/_palette';
@import '~@/assets/scss/icons';

$icon-path: '~@/assets/icons/';

.sensitive-container {
    padding: 32px 40px;
}

.sensitive-title-wrapper {
    margin-bottom: 4%;

    .sensitive-title {
        position: relative;
        font-size: 1.375rem;
        font-weight: 600;
        color: $gray90;

        &.sensitive {
            &::after {
                content: url($icon-path + $alert);
                position: absolute;
                right: -30px;
            }
        }
    }
}

.sensitive-text {
    font-size: 0.875rem;
    color: $gray90;
}

.sensitive-buttons {
    display: flex;
    justify-content: flex-end;
}
</style>
