<template>
    <div class="container-fluid h-100 wrapper-container">
        <AppHeader
            v-if="pageTitle"
            :pageTitle="pageTitle"
            :isSearchEnabled="isSearchEnabled"
            :selectedLanguage="selectedLanguage"
            :entitiesCount="entitiesCount"
            @input="query => $emit('searchQueryChanged', query)"
        >
            <slot
                slot="button"
                name="button"
            />

            <slot
                slot="filterTable"
                name="filterTable"
            />
            <slot
                slot="headerButtons"
                name="headerButtons"
            />
        </AppHeader>

        <div class="row no-gutters">
            <slot name="allFilters" />
        </div>
        <div>
            <slot name="modal" />
        </div>
        <div class="row default-page-content-wrapper">
            <div
                :class="isOverviewEnabled ? 'col-xl-7' : 'col-xl-12'"
                class="table-column col-md-12"
            >
                <slot name="customHtml" />
                <slot name="table" />
            </div>
            <div
                v-show="isOverviewEnabled"
                class="col-xl-5 col-md-12 overview-column over"
            >
                <slot name="overview" />
            </div>
            <div
                v-if="userGuideArticleId"
                class="user-guide-icon-wrapper"
            >
                <IconButton
                    :label="$i18n.t('userGuide.userGuide')"
                    :icon="ICON_TYPES.INFO"
                    :iconSize="'24px'"
                    @iconClick="goToUserGuideArticle"
                />
            </div>
        </div>

        <MutationDialog
            v-model="showModal"
            :entities="getAffectedEntities()"
        />
    </div>
</template>

<script>
// components
import AppHeader from '@/components/layout/AppHeader.vue';
import MutationDialog from '@/components/partials/MutationDialog.vue';
import IconButton from '@/components/partials/IconButton.vue';

// helpers
import permissionsService from '@/services/permissions/permissions.service';
import { languageMap } from '@/common/locale/language';
import { formatMutationDialogEntities } from '@/common/entities/entityHelper';
import RouteNames from '@/router/routeNames';
import { ICON_TYPES } from '@/common/iconHelper';

// Vuex
import { mapGetters } from 'vuex';
import { Getters } from '@/store/mutation-types';

export default {
    name: 'AbstractListPageWrapper',
    components: {
        AppHeader,
        MutationDialog,
        IconButton,
    },
    props: {
        pageTitle: {
            type: String,
            required: false,
            default: '',
        },
        isOverviewEnabled: {
            type: Boolean,
            default: true,
        },
        selectedLanguage: {
            type: String,
            default: languageMap.en.key,
        },
        isSearchEnabled: {
            type: Boolean,
            default: true,
        },
        userGuideArticleId: {
            type: Number,
            default: null,
        },
        entitiesCount: {
            type: Number,
            default: NaN,
        },
    },
    data() {
        return {
            Getters,
            ICON_TYPES,
        };
    },
    computed: {
        ...mapGetters('entitymutation', {
            showModal: Getters.GET_SHOW_MODAL,
            affectedEntities: Getters.GET_AFFECTED_ENTITIES,
        }),

        rewardsEnabled() {
            return permissionsService.rewardsEnabled();
        },
    },
    methods: {
        getAffectedEntities() {
            return formatMutationDialogEntities(this.affectedEntities);
        },
        goToUserGuideArticle() {
            this.$router.push({
                name: RouteNames.USER_GUIDE_ARTICLE,
                params: { id: this.userGuideArticleId, companyId: this.$route.params.companyId },
            });
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/z-indexes';
@import '~@/assets/scss/layout';

.table-column {
    padding-right: 2px;
    height: calc(100vh - #{$header-height});
}

.overview-column {
    padding-left: 0;
}

.wrapper-container {
    padding: 0;
}

.user-guide-icon-wrapper {
    position: absolute;
    bottom: 1.5rem;
    right: 1.5rem;
    z-index: $tooltip-z-index;
}
</style>
