//
import Vue from 'vue';
import moment from 'moment';
import uniqueId from 'lodash/uniqueId';
import isEqual from 'lodash/isEqual';
import FTextInput from '@/components/partials/filters/uiComponents/FTextInput.vue';
import FDropdown from '@/components/partials/filters/uiComponents/FDropdown.vue';
import FNumberInput from '@/components/partials/filters/uiComponents/FNumberInput.vue';
import FObjectInput from '@/components/partials/filters/uiComponents/FObjectInput.vue';
import FPeriodInput from '@/components/partials/filters/uiComponents/FPeriodInput.vue';
import FArrayInput from '@/components/partials/filters/uiComponents/FArrayInput.vue';
import DropdownDefinition from '@/models/filters/uiComponents/DropdownDefinition';
import {
    DateOperations,
    DateOperationsToLabels,
    NumberOperations,
    PropFilterNumberOperationToLabel,
    StringOperationLabels,
    StringOperations,
    NoArgOperations,
    PeriodOperationLabels,
    StringOperationsToLabels,
    DropdownOperationLabelsToType,
    ObjectOperationsToLabels,
    JsonPathOperations,
    JsonPathOperationsToLabels,
    ArrayOperationsToLabels,
    ArrayOperations,
} from '@/common/filters/InputOperations';
import {
    AbstractNumberInput,
    ArrayInput,
    JsonPathInput,
    ObjectInput,
    StringInput,
} from '@/models/filters/uiComponents/InputDefinition';
import PeriodInputDefinition, { ServerPeriodClasses } from '@/models/filters/uiComponents/PeriodInputDefinition';
import i18n from '@/i18n';
import { CDP_PROPERTY_TYPE } from '@/__new__/services/dno/events/models/EventProp';
import FJsonPathInput from '@/components/partials/filters/uiComponents/FJsonPathInput.vue';

/**
 * Represents UI Param instance and contains Vue component instance
 * in opposite to UI Param (definition)
 */
export class UIComponentInstance {
    uniqueId;

    uiComponentInstance; // vue instance

    value;

    isValid = true;

    isConflict = false;

    isDuplicate = false;

    constructor(uiComponentDefinition, rawValue) {
        this.uniqueId = uniqueId('ui_param_v2_');
        this.value = rawValue;
    }

    /** Validate the value and return error message if any */
    validate() {
        return null;
    }

    /** Return string representation of component and it's value */
    toString() {
        return '';
    }
}

export class DropdownInstance extends UIComponentInstance {
    selectables = [];

    componentDefinition;

    constructor(dropdownDefinitionJson, value) {
        super(dropdownDefinitionJson, value);
        this.componentDefinition = new DropdownDefinition(dropdownDefinitionJson);
        this.selectables = this.componentDefinition.selectables;

        this.value = value ? this.componentDefinition.valueFromJson(value) : this.componentDefinition.defaultValue();

        const that = this;

        this.uiComponentInstance = Vue.extend({
            extends: FDropdown,
            data: () => ({
                value: that.value,
                items: this.componentDefinition.selectables,
            }),
            computed: {
                displayProperty() {
                    return (that.value && that.value.displayProperty && that.value.displayProperty()) || 'label';
                },
                isValid() {
                    return this.value && that.isValid;
                },
            },
            methods: {
                onValueChange(partialValue) {
                    const updatedValue = { ...this.value, ...partialValue };
                    this.value = updatedValue;
                    that.value = updatedValue;
                },
            },
        });
    }

    validate() {
        if (this.isDuplicate) {
            this.isValid = false;
            return i18n.t('alertMessage.cep.errorOptionDuplication');
        }

        if (this.isConflict) {
            this.isValid = false;
            return i18n.t('alertMessage.cep.errorFiltersNotMatchOrRedundant');
        }

        this.isValid = this.uniqueId != null;

        return this.isValid ? null : i18n.t('alertMessage.cep.errorOptionMustBeSelected');
    }

    toString() {
        if (this.value && NoArgOperations[this.value.op]) {
            return DropdownOperationLabelsToType[this.value.op];
        }

        const displayProperty = (this.value && this.value.displayProperty && this.value.displayProperty()) || 'label';

        const string = (this.value && this.value[displayProperty]) || '';
        return `${DropdownOperationLabelsToType[this.value.op]} ${string}`;
    }
}

class AbstractNumberInputInstance extends UIComponentInstance {
    componentDefinition;

    constructor(uiParam, rawValue, maybeComponentDefinition) {
        super(uiParam, rawValue);
        this.componentDefinition = maybeComponentDefinition || new AbstractNumberInput(uiParam);
        this.value = rawValue
            ? this.componentDefinition.valueFromJson(rawValue)
            : this.componentDefinition.defaultValue();

        const { isDouble, parseNumber, isOperationVisible, isDropdownInput } = this;

        const that = this;

        this.uiComponentInstance = Vue.extend({
            extends: FNumberInput,
            data: () => ({
                value: that.value,
            }),
            computed: {
                isDouble,
                isOperationVisible,
                isDropdownInput,
                predefinedValues() {
                    return uiParam.predefinedValues;
                },
                isValid() {
                    return this.value && that.isValid;
                },
            },
            methods: {
                parseNumber,
                onValueChange(partialValue = {}) {
                    const value = { ...this.value, ...partialValue };
                    this.value = value;
                    that.value = value;
                },
            },
        });
    }

    isDouble() {
        return false;
    }

    parseNumber(number) {
        return Number(number);
    }

    isNumberValid(number) {
        return this.isDouble() ? !Number.isNaN(number) : Number.isInteger(number);
    }

    isOperationVisible() {
        return true;
    }

    isDropdownInput() {
        return false;
    }

    validate() {
        if (this.value && NoArgOperations[this.value.op]) return null;

        try {
            // $FlowFixMe
            const number1 = this.parseNumber(this.value.value[0]);

            if (!this.isNumberValid(number1)) {
                this.isValid = false;
                return i18n.t('alertMessage.cep.errorEnterCorrectNumber');
            }

            if (number1 < 0) {
                this.isValid = false;
                return i18n.t('alertMessage.cep.errorNumberMustBeGreaterOrEqualZero');
            }

            // $FlowFixMe
            if (this.value.op === NumberOperations.Between) {
                // $FlowFixMe
                const number2 = this.parseNumber(this.value.value[1]);

                if (!this.isNumberValid(number2)) {
                    this.isValid = false;
                    return i18n.t('alertMessage.cep.errorEnterCorrectNumber');
                }

                if (number2 < number1) {
                    this.isValid = false;
                    return i18n.t('alertMessage.cep.errorSecondNumberMustBeGreaterOrEqual');
                }
            }
        } catch (e) {
            this.isValid = false;
            return i18n.t('alertMessage.cep.errorNotValidNumber');
        }

        this.isValid = true;
        return null;
    }

    toString() {
        if (this.value && NoArgOperations[this.value.op]) return PropFilterNumberOperationToLabel[this.value.op];

        if (!this.value || !this.value.value) return '';

        const operationStr =
            this.value && this.isOperationVisible()
                ? PropFilterNumberOperationToLabel[this.value.op].toLowerCase()
                : '';
        const numberStr = this.value ? `${operationStr} ${this.value.value[0]}` : '';
        return this.value && this.value.op === NumberOperations.Between
            ? // $FlowFixMe
              `${numberStr} and ${this.value.value[1]}`
            : numberStr;
    }
}

class QuantityInputInstance extends AbstractNumberInputInstance {}

class DoubleInputInstance extends AbstractNumberInputInstance {
    isDouble() {
        return true;
    }

    parseNumber(number) {
        return Number.parseFloat(number);
    }
}

class ObjectInputInstance extends UIComponentInstance {
    predefinedValues;

    componentDefinition;

    constructor(uiParam, rawValue) {
        super(uiParam, rawValue);
        this.componentDefinition = new ObjectInput(uiParam);
        this.value = rawValue
            ? this.componentDefinition.valueFromJson(rawValue)
            : this.componentDefinition.defaultValue();
        this.predefinedValues = this.componentDefinition.predefinedValues;

        const that = this;

        this.uiComponentInstance = Vue.extend({
            extends: FObjectInput,
            data: () => ({ value: that.value }),
            computed: {
                isValid() {
                    return this.value && that.isValid;
                },
            },
            methods: {
                onValueChange(partialValue = {}) {
                    const value = { ...this.value, ...partialValue };
                    this.value = value;
                    that.value = value;
                },
            },
        });
    }

    toString() {
        if (this.value && NoArgOperations[this.value.op]) return ObjectOperationsToLabels[this.value.op];
        return '';
    }

    validate() {
        this.isValid = !!NoArgOperations[this.value.op];
        return this.isValid ? null : 'Object validation failed';
    }
}

class ArrayInputInstance extends UIComponentInstance {
    predefinedValues;

    componentDefinition;

    constructor(uiParam, rawValue) {
        super(uiParam, rawValue);
        this.componentDefinition = new ArrayInput(uiParam);
        this.value = rawValue
            ? this.componentDefinition.valueFromJson(rawValue)
            : this.componentDefinition.defaultValue();
        this.predefinedValues = this.componentDefinition.predefinedValues;

        const that = this;

        this.uiComponentInstance = Vue.extend({
            extends: FArrayInput,
            data: () => ({ value: that.value }),
            computed: {
                isValid() {
                    return this.value && that.isValid;
                },
            },
            methods: {
                onValueChange(partialValue = {}) {
                    const value = { ...this.value, ...partialValue };
                    this.value = value;
                    that.value = value;
                },
            },
        });
    }

    toString() {
        if (this.value && ArrayOperations[this.value.op]) return ArrayOperationsToLabels[this.value.op];
        return '';
    }

    validate() {
        this.isValid = !!NoArgOperations[this.value.op];
        return this.isValid ? null : 'Object validation failed';
    }
}

class JsonPathInputInstance extends UIComponentInstance {
    predefinedValues;

    componentDefinition;

    constructor(uiParam, rawValue) {
        super(uiParam, rawValue);
        this.componentDefinition = new JsonPathInput(uiParam);
        this.value = rawValue
            ? this.componentDefinition.valueFromJson(rawValue)
            : this.componentDefinition.defaultValue();
        this.predefinedValues = this.componentDefinition.predefinedValues;

        const that = this;

        this.uiComponentInstance = Vue.extend({
            extends: FJsonPathInput,
            data: () => ({ value: that.value }),
            computed: {
                isValid() {
                    return this.value && that.isValid;
                },
            },
            methods: {
                onValueChange(partialValue = {}) {
                    const value = { ...this.value, ...partialValue };
                    this.value = value;
                    that.value = value;
                },
            },
        });
    }

    toString() {
        if (this.value && JsonPathOperations[this.value.op]) return JsonPathOperationsToLabels[this.value.op];
        return '';
    }

    validate() {
        this.isValid = !!JsonPathOperations[this.value.op];
        return this.isValid ? null : 'Object validation failed';
    }
}

class StringInputInstance extends UIComponentInstance {
    predefinedValues;

    componentDefinition;

    constructor(uiParam, rawValue) {
        super(uiParam, rawValue);
        this.componentDefinition = new StringInput(uiParam);
        this.value = rawValue
            ? this.componentDefinition.valueFromJson(rawValue)
            : this.componentDefinition.defaultValue();
        this.predefinedValues = this.componentDefinition.predefinedValues;

        const that = this;

        this.uiComponentInstance = Vue.extend({
            extends: FTextInput,
            data: () => ({ value: that.value }),
            computed: {
                predefinedValues() {
                    return that.predefinedValues;
                },
                isValid() {
                    return this.value && that.isValid;
                },
                operationLabels() {
                    if (
                        that.predefinedValues &&
                        that.predefinedValues.length === 2 &&
                        isEqual(
                            that.predefinedValues.map(v => v.value),
                            ['true', 'false'],
                        )
                    ) {
                        return [StringOperations.Equals];
                    }
                    return StringOperationLabels;
                },
            },
            methods: {
                onValueChange(partialValue = {}) {
                    const value = { ...this.value, ...partialValue };
                    this.value = value;
                    that.value = value;
                },
            },
        });
    }

    toString() {
        if (this.value && NoArgOperations[this.value.op]) return StringOperationsToLabels[this.value.op];

        const selectedPredefinedValue =
            this.predefinedValues &&
            this.predefinedValues.find(predef => this.value && this.value.value === predef.value);
        const string = selectedPredefinedValue ? selectedPredefinedValue.label : this.value && this.value.value;
        return this.value && string ? `${StringOperationsToLabels[this.value.op]} ${string}` : '';
    }

    validate() {
        this.isValid = !!(
            this.value &&
            ((this.value.value && this.value.value.length) || NoArgOperations[this.value.op])
        );
        return this.isValid ? null : 'String must not be empty';
    }
}

class PeriodInputInstance extends AbstractNumberInputInstance {
    constructor(uiParam, rawValue) {
        super(uiParam, rawValue, new PeriodInputDefinition());
        const that = this;

        this.uiComponentInstance = Vue.extend({
            extends: FPeriodInput,
            data: () => ({ value: that.value }),
            computed: {
                isValid() {
                    return this.value && that.isValid;
                },
                defaultPeriodValue() {
                    return that.value;
                },
                defaultDateValue() {
                    const value = that.value || {};
                    return { ...value, value: value.dateValue };
                },
            },
            methods: {
                onValueChange(partialValue = {}) {
                    const value = { ...this.value, ...partialValue };
                    this.value = value;
                    that.value = value;
                },
            },
        });
    }

    toString() {
        const value = this.value || {};
        const periodClass = PeriodInputDefinition.getPeriodType(value);
        if (NoArgOperations[value.op]) {
            return PeriodOperationLabels[value.op];
        }
        if (periodClass === ServerPeriodClasses.Period) {
            return `${super.toString()} ${value.unit.toLowerCase()} ago`;
        }
        if (periodClass === ServerPeriodClasses.Date) {
            return PeriodInputInstance.stringify({
                value: value.dateValue,
                op: value.op,
            });
        }
        if (periodClass === ServerPeriodClasses.Month) {
            return `in ${moment.months()[value.monthValue]}`;
        }

        return '';
    }

    static stringify(value) {
        if (!value || !value.value) return '';

        const value0string = moment(value.value[0]).format('MMM D, YYYY');
        // $FlowFixMe
        const value1string = moment(value.value[1]).format('MMM D, YYYY');

        // $FlowFixMe
        const dateStr = `${DateOperationsToLabels[value.op].toLowerCase()} ${value0string}`;
        // $FlowFixMe
        return value.op === DateOperations.Between ? `${dateStr} and ${value1string}` : dateStr;
    }
}

export const DefinitionPropertyUIKeyToInstanceClass = {
    QuantityInput: QuantityInputInstance,
    StringInput: StringInputInstance,
    DoubleInput: DoubleInputInstance,
    DropDown: DropdownInstance,
    TimePeriodInput: PeriodInputInstance,
    ObjectInput: ObjectInputInstance,
    ArrayInput: ArrayInputInstance,
    JsonPathInput: JsonPathInputInstance,
};

export const JsonPathTypeToPropertyValue = {
    [CDP_PROPERTY_TYPE.INTEGER]: {
        QuantityInput: {},
    },
    [CDP_PROPERTY_TYPE.STRING]: {
        StringInput: {},
    },
    [CDP_PROPERTY_TYPE.NUMBER]: {
        DoubleInput: {},
    },
    [CDP_PROPERTY_TYPE.DATE]: {
        TimePeriodInput: {},
    },
    [CDP_PROPERTY_TYPE.TIMESTAMP]: {
        TimePeriodInput: {},
    },
    [CDP_PROPERTY_TYPE.OBJECT]: {
        ObjectInput: {},
    },
    [CDP_PROPERTY_TYPE.ARRAY]: {
        ArrayInput: {},
    },
};
