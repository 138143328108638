<template>
    <!-- Winner Modal -->
    <AppDialogV2
        :visible="true"
        :overflowAuto="false"
        :title="$i18n.t('rewards.lottery.setWinners')"
        @close="onCancel()"
    >
        <div class="body-sm mb-3">{{ $i18n.t('rewards.lottery.setWinnersExplanation') }}</div>
        <div
            v-for="(category, index) in lottery.winCategories || []"
            :key="category.key"
            class="mb-3"
        >
            <div
                v-for="subIndex in generateRange(category.num)"
                :key="subIndex"
                class="mb-3"
            >
                <div class="col-12 mb-2">
                    <div class="row">
                        <div class="col-2">
                            <AppInputV3
                                :value="category.label"
                                :label="index + subIndex == 1 ? $i18n.t('productCatalog.entities.category') : null"
                                :tooltipPosition="TOOLTIP_POSITION.right"
                                :disabled="true"
                                type="text"
                                class="mr-3"
                            />
                        </div>
                        <div class="col-2">
                            <AppInputV3
                                :value="category.key"
                                :label="index + subIndex == 1 ? $i18n.t('generic.key') : null"
                                :tooltipPosition="TOOLTIP_POSITION.right"
                                :disabled="true"
                                type="text"
                                class="mr-3"
                            />
                        </div>
                        <div class="col-2">
                            <AppInputV3
                                :value="subIndex"
                                :label="index + subIndex == 1 ? $i18n.t('rewards.lottery.spot') : null"
                                :tooltipPosition="TOOLTIP_POSITION.right"
                                :disabled="true"
                                type="number"
                                class="mr-3"
                            />
                        </div>
                        <div class="col-6">
                            <AppInputV3
                                ref="winnerInput"
                                v-model="winnerInfo[getPreviousCount(category) + subIndex - 1]"
                                :label="index + subIndex == 1 ? $i18n.t('rewards.lottery.exampleRow') : null"
                                :placeholder="$i18n.t('rewards.lottery.winnerRow')"
                                :disabled="isDrawn"
                                class="mr-3"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <template #modalFooter>
            <AppButton
                ref="saveButton"
                :isMandatory="true"
                :label="$i18n.t('generic.save')"
                @click="setWinners"
            />
        </template>
    </AppDialogV2>
</template>

<script>
// Components
import AppButton from '@/components/partials/inputs/AppButton.vue';
import AppInputV3 from '@/components/partials/inputs/AppInputV3.vue';
import AppDialogV2 from '@/components/partials/AppDialogV2.vue';

// Helpers
import Button from '@/common/button/Button';
import * as Sentry from '@sentry/vue';
import { setLotteryWinners, getLotteryWinners } from '@/__new__/services/dno/rewards/http/rewards';
import { TOOLTIP_POSITION } from '@/common/tooltip';
import { ALERT_TYPES } from '@/common/alerts/Alert';
import { DRAW_STATE } from '@/__new__/services/dno/rewards/models/LotteryModelHelper';

export default {
    name: 'LotteryWinnersModal',
    components: {
        AppInputV3,
        AppButton,
        AppDialogV2,
    },
    props: {
        lottery: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            TOOLTIP_POSITION,
            totalCount: 0,
            hasChanges: false,
            isSaveClicked: false,
            winnerInfo: [],
        };
    },
    computed: {
        isDrawn() {
            return this.lottery.drawState === DRAW_STATE.DRAWN;
        },
    },
    async created() {
        await this.$withProgressBar(
            async () => {
                this.isDataLoading = true;
                const response = await getLotteryWinners(this.lottery.id, this.lottery.recurrenceNum);
                let existingWinnerInfos = response?.data?.winners ?? [];
                if (existingWinnerInfos[0] === undefined) {
                    existingWinnerInfos = [];
                }
                this.initializeWinnerInfo(existingWinnerInfos);
                this.isDataLoading = false;
            },
            {
                errorHandler: () => {
                    this.isDataLoading = false;
                    this.$eventBus.$emit('showAlert', {
                        message: this.$i18n.t('alertMessage.failedToLoadNecessaryData'),
                    });
                },
            },
        );
    },
    methods: {
        setLotteryWinners,
        enableHasChangesFlag() {
            this.hasChanges = true;
        },
        generateRange(num) {
            return new Array(num).fill().map((_, index) => index + 1);
        },
        getPreviousCount(currentCategory) {
            let count = 0;
            for (const category of this.lottery.winCategories || []) {
                if (category.key === currentCategory.key) {
                    break;
                }
                count += category.num;
            }
            return count;
        },
        initializeWinnerInfo(existingWinnerInfos) {
            let totalIndex = 0;
            for (const category of this.lottery.winCategories || []) {
                for (let spot = 1; spot <= category.num; spot += 1) {
                    const existingInfo = existingWinnerInfos.find(
                        info => info.category === category.key && info.spot === spot,
                    );
                    if (existingInfo != null) {
                        const infoValue = `${existingInfo.target_id}, ${existingInfo.target_type}, ${existingInfo.ticket_id}`;
                        this.$set(this.winnerInfo, totalIndex, infoValue);
                    } else {
                        this.$set(this.winnerInfo, totalIndex, undefined);
                    }
                    totalIndex += 1;
                }
            }
        },
        getProcessedRows() {
            const winners = [];
            let totalIndex = 0;
            for (const category of this.lottery.winCategories || []) {
                for (let spot = 1; spot <= category.num; spot += 1) {
                    const value = this.winnerInfo[totalIndex];
                    const split = (value || '').trim().split(',');
                    if (split.length < 3) {
                        return null;
                    }
                    const targetId = split[0].trim();
                    const targetType = parseInt(split[1], 10) || -1;
                    const ticketId = split[2].trim();
                    winners.push({
                        target_id: targetId,
                        target_type: targetType,
                        ticket_id: ticketId,
                        category: category.key,
                        spot,
                    });
                    totalIndex += 1;
                }
            }
            return winners;
        },
        async setWinners() {
            if (this.isSaveClicked) {
                return;
            }
            this.isSaveClicked = true;
            const processedRows = this.getProcessedRows();
            if (processedRows == null) {
                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('rewards.lottery.badWinnerRows'),
                });
                this.isSaveClicked = false;
            } else {
                await this.$withProgressBar(
                    async () => {
                        await this.setLotteryWinners(this.lottery.id, processedRows);
                        this.isSaveClicked = false;
                        this.$emit('close');
                        this.$eventBus.$emit('showAlert', {
                            message: this.$i18n.t('rewards.lottery.successSetWinners'),
                            type: ALERT_TYPES.success,
                        });
                    },
                    {
                        errorHandler: error => {
                            this.isSaveClicked = false;
                            Sentry.captureException(error);
                            this.$eventBus.$emit('showAlert', {
                                message: this.$i18n.t('rewards.lottery.failSetWinners'),
                            });
                        },
                    },
                );
            }
        },
        onCancel() {
            if (this.hasChanges) {
                const yesButton = new Button({
                    label: this.$i18n.t('generic.yes'),
                    alertType: ALERT_TYPES.warning,
                });
                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('alerts.allRuleChangesWillBeLost'),
                    type: ALERT_TYPES.warning,
                    buttons: [yesButton],
                });
                this.$eventBus.$once('buttonClicked', () => {
                    this.$emit('cancel', false);
                });
            } else {
                this.$emit('cancel', false);
            }
        },
    },
};
</script>

<style scoped lang="scss"></style>
