import { CDP_PROPERTY_TYPE } from '@/__new__/services/dno/events/models/EventProp';
import { difference, uniq } from 'lodash';

export class SinkTypeCaster {
    readonly label: string;
    readonly toType: CDP_PROPERTY_TYPE | string;
    readonly sourceTypes: CDP_PROPERTY_TYPE[] | [];
    readonly isRequired: boolean;
    isDefaultCasterForTypes?: CDP_PROPERTY_TYPE[];
    constructor({
        label,
        toType,
        sourceTypes = [],
        isRequired = false,
        isDefaultCasterForTypes = [],
    }: Partial<SinkTypeCaster> = {}) {
        this.label = label || '';
        this.toType = toType || '';
        this.sourceTypes = sourceTypes || [];
        this.isRequired = isRequired || false;
        this.isDefaultCasterForTypes = isDefaultCasterForTypes || [];
    }
    static fromJson(response: any): SinkTypeCaster[] {
        const { casters, required, data_type_to_default_caster } = response.data;
        const result = Object.entries(casters).map(
            ([finalType, caster]: any) =>
                new SinkTypeCaster({
                    label: caster.label,
                    toType: finalType,
                    sourceTypes: caster.compatible_original_types,
                    isRequired: required,
                    isDefaultCasterForTypes: [], // will be updated below
                }),
        );
        if (data_type_to_default_caster) {
            Object.entries(data_type_to_default_caster).forEach(([propType, casterType]) => {
                const relatedCaster = result.find(caster => caster.toType === casterType);
                if (relatedCaster?.isDefaultCasterForTypes) {
                    relatedCaster.isDefaultCasterForTypes.push(propType as CDP_PROPERTY_TYPE);
                }
            });
        }

        return result;
    }
}
export function getAvailableTypeCasters(types: CDP_PROPERTY_TYPE[], typeCasters: SinkTypeCaster[]) {
    const filteredCasters = typeCasters.filter(tc => {
        return difference(uniq(types), tc.sourceTypes).length === 0;
    });
    if (uniq(types).length === 1) {
        // if selected type has default\predefined caster - we put it on the first place by sorting an array
        return filteredCasters.sort((tc1, tc2) => {
            if (tc1.isDefaultCasterForTypes?.includes(types[0])) return -1;
            if (tc2.isDefaultCasterForTypes?.includes(types[0])) return 1;
            return 0;
        });
    } else {
        const basicCasters = typeCasters.filter(tc => types.includes(tc.toType as CDP_PROPERTY_TYPE));
        return basicCasters.length ? [...filteredCasters, ...basicCasters] : filteredCasters;
    }
}

export default SinkTypeCaster;
