
// COMPONENTS
import AppLabel from '@/components/partials/AppLabel.vue';

// HELPERS
import { capitalizeWord } from '@/common/formatting';
import {
    EXECUTION_TO_LABEL,
    EXECUTION_STATUS_TO_COLOR_MAP,
} from '@/__new__/features/orchestrationengine/common/executionStatusIndicatorHelper';

export default {
    name: 'ExecutionStatusIndicator',
    components: {
        AppLabel,
    },
    props: {
        status: {
            required: true,
            type: String,
        },
    },
    computed: {
        statusConverter(): string {
            return EXECUTION_TO_LABEL[this.status] || capitalizeWord(this.status);
        },
        colorConverter(): string | undefined {
            return EXECUTION_STATUS_TO_COLOR_MAP.get(this.status);
        },
    },
};
