// Generic
import * as Sentry from '@sentry/vue';
import { Module, ActionContext } from 'vuex';
import keyBy from 'lodash/keyBy';
import Actions, { State, Getters, Mutations } from '@/store/mutation-types';

// Helpers
import { EntityStateMapping } from '@/common/commonEntityStateMapper';
import { filterOutDeletedEntities } from '@/common/commonHelper';
import {
    PolicyCounter,
    PolicyRule,
    RatingGroup,
    UsageCounter,
    WalletCounter,
    ContentType,
    TariffSpecification,
    TariffSpecGroup,
} from '@/__new__/services/dno/charging/models/ChargingInterfaces';
import { PolicyRule as PolicyRuleModel, PolicyRuleDraft } from '@/__new__/services/dno/charging/models/PolicyRule';
import {
    PolicyCounter as PolicyCounterModel,
    PolicyCounterDraft,
} from '@/__new__/services/dno/charging/models/PolicyCounter';
import { RatingGroup as RatingGroupModel, RatingGroupDraft } from '@/__new__/services/dno/charging/models/RatingGroup';
import {
    WalletCounter as WalletCounterModel,
    WalletCounterDraft,
} from '@/__new__/services/dno/charging/models/WalletCounter';
import {
    UsageCounter as UsageCounterModel,
    UsageCounterDraft,
} from '@/__new__/services/dno/charging/models/UsageCounter';
import { ContentType as ContentTypeModel, ContentTypeDraft } from '@/__new__/services/dno/charging/models/ContentType';
import {
    TariffSpecification as TariffSpecificationModel,
    TariffSpecificationDraft,
} from '@/__new__/services/dno/charging/models/TariffSpecification';
import {
    TariffSpecGroup as TariffSpecGroupModel,
    TariffSpecGroupDraft,
} from '@/__new__/services/dno/charging/models/TariffSpecificationGroup';

// Http
import { getPolicyRules, getPolicyRulesDraftList } from '@/__new__/services/dno/charging/http/policyRules';
import { getPolicyCounters, getPolicyCountersDraftList } from '@/__new__/services/dno/charging/http/policyCounters';
import { getRatingGroups, getRatingGroupsDraftList } from '@/__new__/services/dno/charging/http/ratingGroups';
import { getWalletCounters, getWalletCountersDraftList } from '@/__new__/services/dno/charging/http/walletCounters';
import { getUsageCounters, getUsageCountersDraftList } from '@/__new__/services/dno/charging/http/usageCounters';
import { getContentTypes, getContentTypesDraftList } from '@/__new__/services/dno/charging/http/contentType';
import {
    getTariffSpecs,
    getTariffSpecsDraftList,
    GET_TARIFF_SPECS_TYPES,
} from '@/__new__/services/dno/charging/http/tariffSpecification';
import {
    getTariffSpecGroups,
    getTariffSpecGroupDraftList,
} from '@/__new__/services/dno/charging/http/tariffSpecificationGroups';

type VersionData = {
    id: string;
    version: string;
};

interface ChargingState {
    [State.POLICY_COUNTERS]: Record<string, PolicyCounter>;
    [State.POLICY_COUNTERS_API_RESPONSE]: object;
    [State.POLICY_COUNTER_BY_VERSION]: PolicyCounter | null;
    [State.POLICY_RULES]: Record<string, PolicyRule>;
    [State.POLICY_RULES_API_RESPONSE]: object;
    [State.POLICY_RULE_BY_VERSION]: PolicyRule | null;
    [State.RATING_GROUPS]: Record<string, RatingGroup>;
    [State.RATING_GROUPS_API_RESPONSE]: object;
    [State.RATING_GROUP_BY_VERSION]: RatingGroup | null;
    [State.USAGE_COUNTERS]: Record<string, UsageCounter>;
    [State.USAGE_COUNTERS_API_RESPONSE]: object;
    [State.USAGE_COUNTER_BY_VERSION]: UsageCounter | null;
    [State.WALLET_COUNTERS]: Record<string, WalletCounter>;
    [State.WALLET_COUNTERS_API_RESPONSE]: object;
    [State.WALLET_COUNTER_BY_VERSION]: WalletCounter | null;
    [State.CONTENT_TYPES]: Record<string, ContentType>;
    [State.CONTENT_TYPES_API_RESPONSE]: object;
    [State.CONTENT_TYPE_BY_VERSION]: ContentType | null;
    [State.TARIFF_SPECIFICATIONS]: Record<string, TariffSpecification>;
    [State.TARIFF_SPECIFICATIONS_API_RESPONSE]: object;
    [State.TARIFF_SPECIFICATION_BY_VERSION]: TariffSpecification | null;
    [State.TARIFF_SPECIFICATION_GROUPS]: Record<string, TariffSpecGroup>;
    [State.TARIFF_SPECIFICATION_GROUPS_API_RESPONSE]: object;
}

const store: Module<ChargingState, unknown> = {
    namespaced: true,
    state: {
        [State.POLICY_COUNTERS]: {},
        [State.POLICY_COUNTERS_API_RESPONSE]: {},
        [State.POLICY_COUNTER_BY_VERSION]: null,
        [State.POLICY_RULES]: {},
        [State.POLICY_RULES_API_RESPONSE]: {},
        [State.POLICY_RULE_BY_VERSION]: null,
        [State.RATING_GROUPS]: {},
        [State.RATING_GROUPS_API_RESPONSE]: {},
        [State.RATING_GROUP_BY_VERSION]: null,
        [State.USAGE_COUNTERS]: {},
        [State.USAGE_COUNTERS_API_RESPONSE]: {},
        [State.USAGE_COUNTER_BY_VERSION]: null,
        [State.WALLET_COUNTERS]: {},
        [State.WALLET_COUNTERS_API_RESPONSE]: {},
        [State.WALLET_COUNTER_BY_VERSION]: null,
        [State.CONTENT_TYPES]: {},
        [State.CONTENT_TYPES_API_RESPONSE]: {},
        [State.CONTENT_TYPE_BY_VERSION]: null,
        [State.TARIFF_SPECIFICATIONS]: {},
        [State.TARIFF_SPECIFICATIONS_API_RESPONSE]: {},
        [State.TARIFF_SPECIFICATION_BY_VERSION]: null,
        [State.TARIFF_SPECIFICATION_GROUPS]: {},
        [State.TARIFF_SPECIFICATION_GROUPS_API_RESPONSE]: {},
    },
    mutations: {
        [Mutations.SET_POLICY_COUNTERS]: (
            state: ChargingState,
            policyCounters: Record<string, PolicyCounter>,
        ): void => {
            state[State.POLICY_COUNTERS] = policyCounters;
        },
        [Mutations.SET_POLICY_COUNTERS_API_RESPONSE]: (state: ChargingState, response: any): void => {
            state[State.POLICY_COUNTERS_API_RESPONSE] = response;
        },
        [Mutations.SET_POLICY_COUNTER_BY_VERSION]: (state: ChargingState, response: PolicyCounter): void => {
            state[State.POLICY_COUNTER_BY_VERSION] = response;
        },
        [Mutations.SET_POLICY_RULES]: (state: ChargingState, policyRules: Record<string, PolicyRule>): void => {
            state[State.POLICY_RULES] = policyRules;
        },
        [Mutations.SET_POLICY_RULES_API_RESPONSE]: (state: ChargingState, response: any): void => {
            state[State.POLICY_RULES_API_RESPONSE] = response;
        },
        [Mutations.SET_POLICY_RULE_BY_VERSION]: (state: ChargingState, response: PolicyRule): void => {
            state[State.POLICY_RULE_BY_VERSION] = response;
        },
        [Mutations.SET_RATING_GROUPS]: (state: ChargingState, ratingGroups: Record<string, RatingGroup>): void => {
            state[State.RATING_GROUPS] = ratingGroups;
        },
        [Mutations.SET_RATING_GROUPS_API_RESPONSE]: (state: ChargingState, response: any): void => {
            state[State.RATING_GROUPS_API_RESPONSE] = response;
        },
        [Mutations.SET_RATING_GROUP_BY_VERSION]: (state: ChargingState, ratingGroups: RatingGroup): void => {
            state[State.RATING_GROUP_BY_VERSION] = ratingGroups;
        },
        [Mutations.SET_USAGE_COUNTERS]: (state: ChargingState, usageCounters: Record<string, UsageCounter>): void => {
            state[State.USAGE_COUNTERS] = usageCounters;
        },
        [Mutations.SET_USAGE_COUNTERS_API_RESPONSE]: (state: ChargingState, response: any): void => {
            state[State.USAGE_COUNTERS_API_RESPONSE] = response;
        },
        [Mutations.SET_USAGE_COUNTER_BY_VERSION]: (state: ChargingState, walletCounter: UsageCounter): void => {
            state[State.USAGE_COUNTER_BY_VERSION] = walletCounter;
        },
        [Mutations.SET_WALLET_COUNTERS]: (
            state: ChargingState,
            walletCounters: Record<string, WalletCounter>,
        ): void => {
            state[State.WALLET_COUNTERS] = walletCounters;
        },
        [Mutations.SET_WALLET_COUNTER_BY_VERSION]: (state: ChargingState, walletCounter: WalletCounter): void => {
            state[State.WALLET_COUNTER_BY_VERSION] = walletCounter;
        },
        [Mutations.SET_WALLET_COUNTERS_API_RESPONSE]: (state: ChargingState, response: any): void => {
            state[State.WALLET_COUNTERS_API_RESPONSE] = response;
        },
        [Mutations.SET_CONTENT_TYPES]: (state: ChargingState, contentTypes: Record<string, ContentType>): void => {
            state[State.CONTENT_TYPES] = contentTypes;
        },
        [Mutations.SET_CONTENT_TYPES_API_RESPONSE]: (state: ChargingState, response: any): void => {
            state[State.CONTENT_TYPES_API_RESPONSE] = response;
        },
        [Mutations.SET_CONTENT_TYPE_BY_VERSION]: (state: ChargingState, contentTypes: ContentType): void => {
            state[State.CONTENT_TYPE_BY_VERSION] = contentTypes;
        },
        [Mutations.SET_TARIFF_SPECIFICATIONS]: (
            state: ChargingState,
            tariffSpecification: Record<string, TariffSpecification>,
        ): void => {
            state[State.TARIFF_SPECIFICATIONS] = tariffSpecification;
        },
        [Mutations.SET_TARIFF_SPECIFICATIONS_API_RESPONSE]: (state: ChargingState, response: any): void => {
            state[State.TARIFF_SPECIFICATIONS_API_RESPONSE] = response;
        },
        [Mutations.SET_TARIFF_SPECIFICATION_BY_VERSION]: (
            state: ChargingState,
            response: TariffSpecification,
        ): void => {
            state[State.TARIFF_SPECIFICATION_BY_VERSION] = response;
        },
        [Mutations.SET_TARIFF_SPECIFICATION_GROUPS]: (
            state: ChargingState,
            tariffSpecGroup: Record<string, TariffSpecGroup>,
        ): void => {
            state[State.TARIFF_SPECIFICATION_GROUPS] = tariffSpecGroup;
        },
        [Mutations.SET_TARIFF_SPECIFICATION_GROUPS_API_RESPONSE]: (state: ChargingState, response: any): void => {
            state[State.TARIFF_SPECIFICATIONS_API_RESPONSE] = response;
        },
    },
    actions: {
        async [Actions.REQUEST_POLICY_COUNTERS](context: ActionContext<ChargingState, unknown>): Promise<void> {
            try {
                const [response, draftResponse] = await Promise.all([
                    getPolicyCounters(),
                    getPolicyCountersDraftList(),
                ]);
                const draftEntities: PolicyCounterDraft[] = Object.values(draftResponse?.data?.data || {}).map(
                    (e: any) =>
                        new PolicyCounterDraft({
                            ...e,
                            ...e.data,
                            updateTime: e.update_time,
                        }),
                );

                const { policy_counter_by_id: policyCounterById } = response.data;

                const policyCountersData: PolicyCounterModel[] = Object.values(policyCounterById).map(
                    (pc: any) =>
                        new PolicyCounterModel(
                            {
                                ...pc,
                                ...pc.data,
                            },
                            draftEntities,
                        ),
                );

                context.commit(
                    Mutations.SET_POLICY_COUNTERS,
                    keyBy([...draftEntities, ...policyCountersData], ({ id }) => id),
                );
                context.commit(Mutations.SET_POLICY_COUNTERS_API_RESPONSE, response);
            } catch (e: any) {
                context.commit(Mutations.SET_POLICY_COUNTERS_API_RESPONSE, { response: e.response });
                Sentry.captureException(e);
                throw e;
            }
        },
        async [Actions.REQUEST_POLICY_COUNTER_BY_VERSION](
            context: ActionContext<ChargingState, unknown>,
            { id, version }: VersionData,
        ): Promise<void> {
            try {
                const [response, draftResponse] = await Promise.all([
                    getPolicyCounters([id], true, true, true, version),
                    getPolicyCountersDraftList(),
                ]);
                const draftEntities: PolicyCounterDraft[] = Object.values(draftResponse?.data?.data || {}).map(
                    (e: any) =>
                        new PolicyCounterDraft({
                            ...e,
                            ...e.data,
                            updateTime: e.update_time,
                        }),
                );

                const { policy_counter_by_id: policyCounterById = {} } = response.data;

                const policyCounterData = policyCounterById?.[id] || null;
                let policyCounter = null;

                if (policyCounterData) {
                    policyCounter = new PolicyCounterModel(
                        {
                            ...policyCounterData,
                            ...policyCounterData.data,
                        },
                        draftEntities,
                        response.data,
                    );
                }

                context.commit(Mutations.SET_POLICY_COUNTER_BY_VERSION, policyCounter);
            } catch (e: any) {
                Sentry.captureException(e);
                throw e;
            }
        },
        async [Actions.REQUEST_POLICY_RULES](context: ActionContext<ChargingState, unknown>): Promise<void> {
            try {
                const [response, draftResponse] = await Promise.all([getPolicyRules(), getPolicyRulesDraftList()]);
                const draftEntities: PolicyRuleDraft[] = Object.values(draftResponse?.data?.data || {}).map(
                    (e: any) =>
                        new PolicyRuleDraft({
                            ...e,
                            ...e.data,
                            updateTime: e.update_time,
                        }),
                );

                const { policy_rule_by_id: policyRuleById } = response.data;
                const policyRulesData: PolicyRuleModel[] = Object.values(policyRuleById).map(
                    (pr: any) =>
                        new PolicyRuleModel(
                            {
                                ...pr,
                                ...pr.data,
                            },
                            draftEntities,
                        ),
                );

                context.commit(
                    Mutations.SET_POLICY_RULES,
                    keyBy([...draftEntities, ...policyRulesData], ({ id }) => id),
                );
                context.commit(Mutations.SET_POLICY_RULES_API_RESPONSE, response);
            } catch (e: any) {
                context.commit(Mutations.SET_POLICY_RULES_API_RESPONSE, { response: e.response });
                Sentry.captureException(e);
                throw e;
            }
        },
        async [Actions.REQUEST_POLICY_RULE_BY_VERSION](
            context: ActionContext<ChargingState, unknown>,
            { id, version }: VersionData,
        ): Promise<void> {
            try {
                const [response, draftResponse] = await Promise.all([
                    getPolicyRules([id], true, true, true, version),
                    getPolicyRulesDraftList(),
                ]);

                const draftEntities: PolicyRuleDraft[] = Object.values(draftResponse?.data?.data || {} || {}).map(
                    (e: any) =>
                        new PolicyRuleDraft({
                            ...e,
                            ...e.data,
                            updateTime: e.update_time,
                        }),
                );

                const { policy_rule_by_id: policyRuleById } = response.data;
                const policyRuleData = policyRuleById?.[id] || null;
                let policyRule = null;

                if (policyRuleData) {
                    policyRule = new PolicyRuleModel(
                        {
                            ...policyRuleData,
                            ...policyRuleData.data,
                        },
                        draftEntities,
                    );
                }

                context.commit(Mutations.SET_POLICY_RULE_BY_VERSION, policyRule);
            } catch (e: any) {
                context.commit(Mutations.SET_POLICY_RULES_API_RESPONSE, { response: e.response });
                Sentry.captureException(e);
                throw e;
            }
        },
        async [Actions.REQUEST_RATING_GROUPS](context: ActionContext<ChargingState, unknown>): Promise<void> {
            try {
                const [response, draftResponse] = await Promise.all([getRatingGroups(), getRatingGroupsDraftList()]);
                const draftEntities: RatingGroupDraft[] = Object.values(draftResponse?.data?.data || {}).map(
                    (e: any) =>
                        new RatingGroupDraft({
                            ...e,
                            ...e.data,
                        }),
                );

                const { rating_group_by_id: ratingGroupById } = response.data;
                const ratingGroupData = Object.values(ratingGroupById).map(
                    (rg: any) =>
                        new RatingGroupModel(
                            {
                                ...rg,
                                ...rg.data,
                            },
                            draftEntities,
                        ),
                );

                context.commit(
                    Mutations.SET_RATING_GROUPS,
                    keyBy([...draftEntities, ...ratingGroupData], ({ id }) => id),
                );
                context.commit(Mutations.SET_RATING_GROUPS_API_RESPONSE, response);
            } catch (e: any) {
                context.commit(Mutations.SET_RATING_GROUPS_API_RESPONSE, { response: e.response });
                Sentry.captureException(e);
                throw e;
            }
        },
        async [Actions.REQUEST_RATING_GROUP_BY_VERSION](
            context: ActionContext<ChargingState, unknown>,
            { id, version }: VersionData,
        ): Promise<void> {
            try {
                const [response, draftResponse] = await Promise.all([
                    getRatingGroups([id], true, true, true, version),
                    getRatingGroupsDraftList(),
                ]);

                const draftEntities: RatingGroupDraft[] = Object.values(draftResponse?.data?.data || {}).map(
                    (e: any) =>
                        new RatingGroupDraft({
                            ...e,
                            ...e.data,
                        }),
                );

                const { rating_group_by_id: ratingGroupById } = response.data;
                const ratingGroupData = ratingGroupById?.[id] || null;
                let ratingGroup = null;

                if (ratingGroupData) {
                    ratingGroup = new RatingGroupModel(
                        {
                            ...ratingGroupData,
                            ...ratingGroupData.data,
                        },
                        draftEntities,
                        response.data,
                    );
                }

                context.commit(Mutations.SET_RATING_GROUP_BY_VERSION, ratingGroup);
            } catch (e: any) {
                Sentry.captureException(e);
                throw e;
            }
        },
        async [Actions.REQUEST_USAGE_COUNTERS](context: ActionContext<ChargingState, unknown>): Promise<void> {
            try {
                const [response, draftResponse] = await Promise.all([getUsageCounters(), getUsageCountersDraftList()]);

                const draftEntities: UsageCounterDraft[] = Object.values(draftResponse?.data?.data || {}).map(
                    (e: any) =>
                        new UsageCounterDraft({
                            ...e,
                            ...e.data,
                        }),
                );

                const { usage_counter_by_id: usageCounterByiId } = response.data;
                const usageCountersList = Object.values(usageCounterByiId).map(
                    (uc: any) =>
                        new UsageCounterModel(
                            {
                                ...uc,
                                ...uc.data,
                            },
                            draftEntities,
                        ),
                );

                context.commit(
                    Mutations.SET_USAGE_COUNTERS,
                    keyBy([...draftEntities, ...usageCountersList], ({ id }) => id),
                );
                context.commit(Mutations.SET_USAGE_COUNTERS_API_RESPONSE, response);
            } catch (e: any) {
                context.commit(Mutations.SET_USAGE_COUNTERS_API_RESPONSE, { response: e.response });
                Sentry.captureException(e);
                throw e;
            }
        },
        async [Actions.REQUEST_USAGE_COUNTER_BY_VERSION](
            context: ActionContext<ChargingState, unknown>,
            { id, version }: VersionData,
        ): Promise<void> {
            try {
                const [response, draftResponse] = await Promise.all([
                    getUsageCounters([id], true, true, true, version),
                    getUsageCountersDraftList(),
                ]);

                const draftEntities: UsageCounterDraft[] = Object.values(draftResponse?.data?.data || {}).map(
                    (e: any) =>
                        new UsageCounterDraft({
                            ...e,
                            ...e.data,
                        }),
                );

                const { usage_counter_by_id: usageCounterByiId } = response.data;
                const usageCounterData = usageCounterByiId?.[id] || null;
                let usageCounter = null;

                if (usageCounterData) {
                    usageCounter = new UsageCounterModel(
                        {
                            ...usageCounterData,
                            ...usageCounterData.data,
                        },
                        draftEntities,
                        response.data,
                    );
                }

                context.commit(Mutations.SET_USAGE_COUNTER_BY_VERSION, usageCounter);
            } catch (e: any) {
                Sentry.captureException(e);
                throw e;
            }
        },
        async [Actions.REQUEST_WALLET_COUNTERS](context: ActionContext<ChargingState, unknown>): Promise<void> {
            try {
                const [response, draftResponse] = await Promise.all([
                    getWalletCounters(),
                    getWalletCountersDraftList(),
                ]);

                const draftEntities: WalletCounterDraft[] = Object.values(draftResponse?.data?.data || {}).map(
                    (e: any) =>
                        new WalletCounterDraft({
                            ...e,
                            ...e.data,
                        }),
                );

                const { wallet_counter_by_id: walletCounterById = {} } = response.data;
                const wcArr = Object.values(walletCounterById).map(
                    (wc: any) =>
                        new WalletCounterModel(
                            {
                                ...wc,
                                ...wc.data,
                            },
                            draftEntities,
                        ),
                );

                context.commit(
                    Mutations.SET_WALLET_COUNTERS,
                    keyBy([...draftEntities, ...wcArr], ({ id }) => id),
                );
                context.commit(Mutations.SET_WALLET_COUNTERS_API_RESPONSE, response);
            } catch (e: any) {
                context.commit(Mutations.SET_WALLET_COUNTERS_API_RESPONSE, { response: e.response });
                Sentry.captureException(e);
                throw e;
            }
        },
        async [Actions.REQUEST_WALLET_COUNTER_BY_VERSION](
            context: ActionContext<ChargingState, unknown>,
            { id, version }: VersionData,
        ): Promise<void> {
            try {
                const [response, draftResponse] = await Promise.all([
                    getWalletCounters([id], true, true, true, version),
                    getWalletCountersDraftList(),
                ]);

                const draftEntities: WalletCounterDraft[] = Object.values(draftResponse?.data?.data || {}).map(
                    (e: any) =>
                        new WalletCounterDraft({
                            ...e,
                            ...e.data,
                        }),
                );

                const { wallet_counter_by_id: walletCounterById = {} } = response.data;
                const walletCounterData = walletCounterById?.[id] || null;
                let walletCounter = null;

                if (walletCounterData) {
                    walletCounter = new WalletCounterModel(
                        {
                            ...walletCounterData,
                            ...walletCounterData.data,
                        },
                        draftEntities,
                        response.data,
                    );
                }

                context.commit(Mutations.SET_WALLET_COUNTER_BY_VERSION, walletCounter);
            } catch (e: any) {
                Sentry.captureException(e);
                throw e;
            }
        },
        async [Actions.REQUEST_CONTENT_TYPES](context: ActionContext<ChargingState, unknown>): Promise<void> {
            try {
                const [response, draftResponse] = await Promise.all([getContentTypes(), getContentTypesDraftList()]);

                const draftEntities: ContentTypeDraft[] = Object.values(draftResponse?.data?.data || {}).map(
                    (e: any) =>
                        new ContentTypeDraft({
                            ...e,
                            ...e.data,
                        }),
                );

                const { content_type_by_id: contentTypeById = {} } = response.data;
                const ctArr = Object.values(contentTypeById).map(
                    (ct: any) =>
                        new ContentTypeModel(
                            {
                                ...ct,
                                ...ct.data,
                            },
                            draftEntities,
                        ),
                );

                context.commit(
                    Mutations.SET_CONTENT_TYPES,
                    keyBy([...draftEntities, ...ctArr], ({ id }) => id),
                );
                context.commit(Mutations.SET_CONTENT_TYPES_API_RESPONSE, response);
            } catch (e: any) {
                context.commit(Mutations.SET_CONTENT_TYPES_API_RESPONSE, { response: e.response });
                Sentry.captureException(e);
                throw e;
            }
        },
        async [Actions.REQUEST_CONTENT_TYPE_BY_VERSION](
            context: ActionContext<ChargingState, unknown>,
            { id, version }: VersionData,
        ): Promise<void> {
            try {
                const [response, draftResponse] = await Promise.all([
                    getContentTypes([id], true, true, true, version),
                    getContentTypesDraftList(),
                ]);

                const draftEntities: ContentTypeDraft[] = Object.values(draftResponse?.data?.data || {}).map(
                    (e: any) =>
                        new ContentTypeDraft({
                            ...e,
                            ...e.data,
                        }),
                );

                const { content_type_by_id: contentTypeById = {} } = response.data;
                const contentTypeData = contentTypeById?.[id] || null;
                let contentType = null;

                if (contentTypeData) {
                    contentType = new ContentTypeModel(
                        {
                            ...contentTypeData,
                            ...contentTypeData.data,
                        },
                        draftEntities,
                    );
                }

                context.commit(Mutations.SET_CONTENT_TYPE_BY_VERSION, contentType);
            } catch (e: any) {
                Sentry.captureException(e);
                throw e;
            }
        },
        async [Actions.REQUEST_TARIFF_SPECIFICATIONS](
            context: ActionContext<ChargingState, unknown>,
            isZeroRate = false,
        ): Promise<void> {
            try {
                const response = await getTariffSpecs(
                    [],
                    isZeroRate ? GET_TARIFF_SPECS_TYPES?.GET_ZERO_RATE : GET_TARIFF_SPECS_TYPES?.GET_REGULAR,
                );

                let draftEntities: TariffSpecificationDraft[] = [];
                if (!isZeroRate) {
                    const draftResponse = await getTariffSpecsDraftList();
                    draftEntities = Object.values(draftResponse?.data?.data || {}).map(
                        (e: any) =>
                            new TariffSpecificationDraft({
                                ...e,
                                ...e.data,
                                updateTime: e.update_time,
                            }),
                    );
                }

                const { tariff_spec_by_id: tariffSpecById } = response.data;
                const tariffSpecsData: TariffSpecificationModel[] = Object.values(tariffSpecById).map(
                    (ts: any) =>
                        new TariffSpecificationModel(
                            isZeroRate,
                            {
                                ...ts,
                                ...ts.data,
                            },
                            draftEntities,
                        ),
                );

                context.commit(
                    Mutations.SET_TARIFF_SPECIFICATIONS,
                    keyBy([...draftEntities, ...tariffSpecsData], ({ id }) => id),
                );
                context.commit(Mutations.SET_TARIFF_SPECIFICATIONS_API_RESPONSE, response);
            } catch (e: any) {
                context.commit(Mutations.SET_TARIFF_SPECIFICATIONS_API_RESPONSE, { response: e.response });
                Sentry.captureException(e);
                throw e;
            }
        },
        async [Actions.REQUEST_TARIFF_SPECIFICATION_BY_VERSION](
            context: ActionContext<ChargingState, unknown>,
            { id, version }: VersionData,
        ): Promise<void> {
            try {
                const [response, draftResponse] = await Promise.all([
                    getTariffSpecs([id], GET_TARIFF_SPECS_TYPES?.GET_REGULAR, true, true, true, version),
                    getTariffSpecsDraftList(),
                ]);

                const draftEntities: TariffSpecificationDraft[] = Object.values(draftResponse?.data?.data || {}).map(
                    (e: any) =>
                        new TariffSpecificationDraft({
                            ...e,
                            ...e.data,
                            updateTime: e.update_time,
                        }),
                );

                const { tariff_spec_by_id: tariffSpecById } = response.data;
                const tariffSpecData = tariffSpecById?.[id] || null;
                let tariffSpec = null;

                if (tariffSpecData) {
                    tariffSpec = new TariffSpecificationModel(
                        false,
                        {
                            ...tariffSpecData,
                            ...tariffSpecData.data,
                        },
                        draftEntities,
                        response.data,
                    );
                }

                context.commit(Mutations.SET_TARIFF_SPECIFICATION_BY_VERSION, tariffSpec);
            } catch (e: any) {
                Sentry.captureException(e);
                throw e;
            }
        },
        async [Actions.REQUEST_TARIFF_SPECIFICATION_GROUPS](
            context: ActionContext<ChargingState, unknown>,
        ): Promise<void> {
            try {
                const [response, draftResponse] = await Promise.all([
                    getTariffSpecGroups(),
                    getTariffSpecGroupDraftList(),
                ]);
                const draftEntities: TariffSpecGroupDraft[] = Object.values(draftResponse?.data?.data || {}).map(
                    (e: any) =>
                        new TariffSpecGroupDraft({
                            ...e,
                            ...e.data,
                            updateTime: e.update_time,
                        }),
                );

                const { tariff_spec_group_by_id: tariffSpecGroupById = {} } = response?.data;
                const tariffSpecGroupsData: TariffSpecGroupModel[] = Object.values(tariffSpecGroupById).map(
                    (ts: any) =>
                        new TariffSpecGroupModel(
                            {
                                ...ts,
                                ...ts.data,
                            },
                            draftEntities,
                        ),
                );

                context.commit(
                    Mutations.SET_TARIFF_SPECIFICATION_GROUPS,
                    keyBy([...draftEntities, ...tariffSpecGroupsData], ({ id }) => id),
                );
                context.commit(Mutations.SET_TARIFF_SPECIFICATION_GROUPS_API_RESPONSE, response);
            } catch (e: any) {
                context.commit(Mutations.SET_TARIFF_SPECIFICATION_GROUPS_API_RESPONSE, { response: e.response });
                Sentry.captureException(e);
                throw e;
            }
        },
    },
    getters: {
        // Policy Counters
        [Getters.GET_POLICY_COUNTERS]: (state: ChargingState): PolicyCounter[] =>
            Object.values(state[State.POLICY_COUNTERS]) || [],
        [Getters.GET_APPROVED_POLICY_COUNTERS]: (state: ChargingState): PolicyCounter[] =>
            Object.values(state[State.POLICY_COUNTERS]).filter(
                (pc: PolicyCounter) => pc.state === EntityStateMapping.APPROVED,
            ) || [],
        [Getters.GET_POLICY_COUNTERS_API_RESPONSE]: (state: ChargingState): any =>
            state[State.POLICY_COUNTERS_API_RESPONSE] || {},
        [Getters.GET_POLICY_COUNTER_BY_ID]:
            (state: ChargingState): PolicyCounter | object =>
            (id: string) =>
                state[State.POLICY_COUNTERS][id] || {},
        [Getters.GET_POLICY_COUNTER_BY_IDS]: (state: ChargingState) => (ids: string[]) => {
            const pcs: PolicyCounter[] = [];
            ids.forEach((id: string) => {
                if (state[State.POLICY_COUNTERS] && state[State.POLICY_COUNTERS][id]) {
                    pcs.push(state[State.POLICY_COUNTERS][id]);
                }
            });
            return pcs;
        },
        [Getters.GET_POLICY_COUNTER_BY_VERSION]: (state: ChargingState): PolicyCounter | null =>
            state[State.POLICY_COUNTER_BY_VERSION] || null,
        // Policy Rules
        [Getters.GET_POLICY_RULES]: (state: ChargingState): PolicyRule[] =>
            Object.values(state[State.POLICY_RULES]) || [],
        [Getters.GET_POLICY_RULES_API_RESPONSE]: (state: ChargingState): object =>
            state[State.POLICY_RULES_API_RESPONSE] || {},
        [Getters.GET_POLICY_RULE_BY_ID]:
            (state: ChargingState) =>
            (id: string): PolicyRule =>
                state[State.POLICY_RULES][id],
        [Getters.GET_POLICY_RULE_BY_VERSION]: (state: ChargingState): PolicyRule | null =>
            state[State.POLICY_RULE_BY_VERSION] || null,

        // Rating Groups
        [Getters.GET_APPROVED_RATING_GROUPS]: (state: ChargingState): RatingGroup[] =>
            Object.values(state[State.RATING_GROUPS]).filter(
                (rg: RatingGroup) => rg.state === EntityStateMapping.APPROVED,
            ),
        [Getters.GET_NOT_DELETED_RATING_GROUPS]: (state: ChargingState): RatingGroup[] | object[] =>
            filterOutDeletedEntities(Object.values(state[State.RATING_GROUPS])),
        [Getters.GET_RATING_GROUPS_API_RESPONSE]: (state: ChargingState): any =>
            state[State.RATING_GROUPS_API_RESPONSE] || {},
        [Getters.GET_RATING_GROUP_BY_ID]:
            (state: ChargingState) =>
            (id: string): RatingGroup =>
                state[State.RATING_GROUPS][id],
        [Getters.GET_RATING_GROUP_BY_VERSION]: (state: ChargingState): RatingGroup | null =>
            state[State.RATING_GROUP_BY_VERSION] || null,

        // Usage Counters
        [Getters.GET_USAGE_COUNTERS]: (state: ChargingState): UsageCounter[] =>
            Object.values(state[State.USAGE_COUNTERS]) || [],
        [Getters.GET_USAGE_COUNTER_BY_ID]:
            (state: ChargingState) =>
            (id: string): UsageCounter =>
                state[State.USAGE_COUNTERS][id],
        [Getters.GET_APPROVED_USAGE_COUNTERS]: (state: ChargingState): UsageCounter[] =>
            Object.values(state[State.USAGE_COUNTERS]).filter(rg => rg.state === EntityStateMapping.APPROVED),
        [Getters.GET_USAGE_COUNTERS_BY_IDS]:
            (state: ChargingState) =>
            (ids: string[]): UsageCounter[] => {
                const ucs: UsageCounter[] = [];
                ids.forEach((id: string) => {
                    if (state[State.USAGE_COUNTERS] && state[State.USAGE_COUNTERS][id]) {
                        ucs.push(state[State.USAGE_COUNTERS][id]);
                    }
                });
                return ucs;
            },
        [Getters.GET_USAGE_COUNTERS_API_RESPONSE]: (state: ChargingState): any =>
            state[State.USAGE_COUNTERS_API_RESPONSE] || {},
        [Getters.GET_USAGE_COUNTER_BY_VERSION]: (state: ChargingState): UsageCounter | null =>
            state[State.USAGE_COUNTER_BY_VERSION] || null,

        // Wallet Counters
        [Getters.GET_WALLET_COUNTERS]: (state: ChargingState): WalletCounter[] =>
            Object.values(state.walletCounters) || [],
        [Getters.GET_APPROVED_WALLET_COUNTERS]: (state: ChargingState): WalletCounter[] | object[] =>
            filterOutDeletedEntities(Object.values(state[State.WALLET_COUNTERS])),
        [Getters.GET_WALLET_COUNTER_BY_ID]:
            (state: ChargingState): ChargingState | object =>
            (id: string): WalletCounter =>
                state.walletCounters[id],
        [Getters.GET_WALLET_COUNTERS_BY_IDS]:
            (state: ChargingState) =>
            (ids: string[]): WalletCounter[] => {
                const pcs: WalletCounter[] = [];
                ids.forEach((id: string) => {
                    if (state?.[State.WALLET_COUNTERS]?.[id]) {
                        pcs.push(state[State.WALLET_COUNTERS][id]);
                    }
                });
                return pcs;
            },
        [Getters.GET_WALLET_COUNTERS_API_RESPONSE]: (state: ChargingState): any =>
            state[State.WALLET_COUNTERS_API_RESPONSE] || {},
        [Getters.GET_WALLET_COUNTER_BY_VERSION]: (state: ChargingState): WalletCounter | null =>
            state[State.WALLET_COUNTER_BY_VERSION] || null,

        // Content Types
        [Getters.GET_CONTENT_TYPES]: (state: ChargingState): ContentType[] =>
            Object.values(state[State.CONTENT_TYPES]) || [],
        [Getters.GET_CONTENT_TYPE_BY_ID]:
            (state: ChargingState): ContentType | object =>
            (id: string): ContentType =>
                state[State.CONTENT_TYPES][id] || null,
        [Getters.GET_APPROVED_CONTENT_TYPES]: (state: ChargingState): ContentType[] | object[] =>
            filterOutDeletedEntities(Object.values(state[State.CONTENT_TYPES])),
        [Getters.GET_ONLY_PUBLISHED_CONTENT_TYPES]: (state: ChargingState): ContentType[] | object[] =>
            Object.values(state[State.CONTENT_TYPES]).filter(
                (data: ContentType) => data.state === EntityStateMapping.APPROVED,
            ),
        [Getters.GET_CONTENT_TYPES_API_RESPONSE]: (state: ChargingState): any =>
            state[State.CONTENT_TYPES_API_RESPONSE] || {},
        [Getters.GET_CONTENT_TYPE_BY_VERSION]: (state: ChargingState): ContentType | null =>
            state[State.CONTENT_TYPE_BY_VERSION] || null,

        // Tariff Specifications
        [Getters.GET_TARIFF_SPECIFICATIONS]: (state: ChargingState): TariffSpecification[] =>
            Object.values(state[State.TARIFF_SPECIFICATIONS]) || [],
        [Getters.GET_APPROVED_TARIFF_SPECIFICATIONS]: (state: ChargingState): TariffSpecification[] =>
            Object.values(state[State.TARIFF_SPECIFICATIONS]).filter(
                (ts: TariffSpecification) => ts.state === EntityStateMapping.APPROVED,
            ),
        [Getters.GET_TARIFF_SPECIFICATIONS_API_RESPONSE]: (state: ChargingState): any =>
            state[State.TARIFF_SPECIFICATIONS_API_RESPONSE] || {},
        [Getters.GET_TARIFF_SPECIFICATION_BY_ID]:
            (state: ChargingState): TariffSpecification | object =>
            (id: string) =>
                state[State.TARIFF_SPECIFICATIONS][id] || {},
        [Getters.GET_TARIFF_SPECIFICATION_BY_VERSION]: (state: ChargingState): TariffSpecification | null =>
            state[State.TARIFF_SPECIFICATION_BY_VERSION] || null,

        // Tariff Specification Groups
        [Getters.GET_TARIFF_SPECIFICATION_GROUPS]: (state: ChargingState): TariffSpecGroup[] =>
            Object.values(state[State.TARIFF_SPECIFICATION_GROUPS]) || [],
        [Getters.GET_APPROVED_TARIFF_SPECIFICATION_GROUPS]: (state: ChargingState): TariffSpecGroup[] =>
            Object.values(state[State.TARIFF_SPECIFICATION_GROUPS]).filter(
                (tsg: TariffSpecGroup) => tsg.state !== EntityStateMapping.DELETE,
            ),
        [Getters.GET_TARIFF_SPECIFICATION_GROUP_BY_ID]:
            (state: ChargingState): TariffSpecGroup | object =>
            (id: string) =>
                state[State.TARIFF_SPECIFICATION_GROUPS][id] || {},
        [Getters.GET_TARIFF_SPECIFICATION_GROUPS_API_RESPONSE]: (state: ChargingState): any =>
            state[State.TARIFF_SPECIFICATIONS_API_RESPONSE] || {},
    },
};

export default store;
