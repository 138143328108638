<template>
    <div>
        <div
            :class="{ 'drag-on': isDragOn }"
            class="upload-container mb-3"
            @drag="preventAndStop"
            @dragover="preventAndStop"
            @dragstart="preventAndStop"
            @dragend="preventAndStop"
            @dragenter="[addDragOnClass(), e => preventAndStop(e)]"
            @dragleave="[deleteDragOnClass(), e => preventAndStop(e)]"
            @drop="onDrop"
        >
            <input
                id="file-dragdrop"
                :multiple="true"
                :accept="acceptType"
                :disabled="triggerUploadFiles"
                type="file"
                name="file"
                class="input-file-dragdrop"
                @change="onSelect"
            />
            <div class="w-100 d-flex flex-row align-items-center">
                <img
                    class="upload-img"
                    src="@/assets/icons/file-upload.svg"
                />
                <div class="upload-text-wrapper">
                    <div
                        class="upload-text d-flex justify-content-center"
                        :class="{ 'drag-on': isDragOn }"
                    >
                        {{ $i18n.t('partials.fileUploader.dragAndDropText') }}
                        <label
                            for="file-dragdrop"
                            class="upload-link ml-1"
                            :class="{ 'drag-on': isDragOn }"
                        >
                            {{ $i18n.t('partials.fileUploader.browseText') }}
                        </label>
                    </div>
                    <div
                        class="text-center upload-subtext"
                        :class="{ 'drag-on': isDragOn }"
                    >
                        {{ $i18n.t('partials.fileUploader.youCanUpload') + acceptType }}
                    </div>
                </div>
            </div>
        </div>
        <div
            v-if="hasSelectedFiles"
            class="files-wrap"
        >
            <div
                v-for="(file, index) in formattedFiles"
                :key="index"
                class="file-wrapper"
            >
                <FileUploaderSingleFile
                    :file="file"
                    :data-test-id="`single-file-${index}`"
                    @removeFile="removeFile(index)"
                />
            </div>
        </div>
    </div>
</template>

<script>
// COMPONENTS
import FileUploaderSingleFile from '@/components/partials/fileUploader/FileUploaderSingleFile.vue';

// HELPERS
import { ALERT_TYPES } from '@/common/alerts/Alert';
import { FILE_UPLOAD_STATES } from '@/common/commonHelper';
import { uuidV4 } from '@/common/utils';

export default {
    name: 'AgentNotesDragDropUploader',
    components: {
        FileUploaderSingleFile,
    },
    props: {
        // Comma separated extension ( ".txt, .csv")
        acceptType: {
            type: String,
            default: '.pdf',
        },
        triggerUploadFiles: {
            type: Boolean,
            default: false,
        },
        uploadSuccessIndex: {
            type: Number,
            default: null,
        },
        triggerUploadFailed: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            selectedFiles: [],
            isDragOn: false,
            FILE_UPLOAD_STATES,
            formattedFiles: [],
        };
    },
    computed: {
        hasSelectedFiles() {
            return this.selectedFiles.length;
        },
    },
    watch: {
        formattedFiles: {
            handler() {
                this.$emit('updateFiles', this.formattedFiles);
            },
            deep: true,
        },
        triggerUploadFiles: {
            handler() {
                if (this.triggerUploadFiles) {
                    this.formattedFiles.forEach(file => {
                        if (file.status === FILE_UPLOAD_STATES.PENDING) {
                            file.status = FILE_UPLOAD_STATES.UPLOADING;
                        }
                    });
                }
            },
        },
        uploadSuccessIndex: {
            handler(newVal) {
                if (this.uploadSuccessIndex !== null) {
                    this.formattedFiles[newVal].status = FILE_UPLOAD_STATES.SUCCESSFUL_COMPLETED;
                }
            },
        },
        triggerUploadFailed: {
            handler() {
                this.formattedFiles.forEach(file => {
                    if (file.status === FILE_UPLOAD_STATES.UPLOADING) {
                        file.status = FILE_UPLOAD_STATES.UPLOADING_FAILED;
                    }
                });
            },
        },
    },
    methods: {
        onSelect(e) {
            const files = e.target.files || e.dataTransfer.files;
            this.addFilesToList(files);
        },
        addFilesToList(files) {
            if (files.length) {
                this.selectedFiles =
                    this.selectedFiles.length > 0 ? this.arrayUnique(Array.from(files)) : Array.from(files);

                this.updateFormattedFiles();
            }
        },
        updateFormattedFiles() {
            // Leave out already existing files
            const fileNames = new Set(this.formattedFiles.map(d => d.name));

            // Format files for uploading
            const newFiles = this.selectedFiles
                .filter(d => !fileNames.has(d.name))
                .map(file => ({
                    file,
                    blob: null,
                    id: uuidV4(),
                    url: '',
                    name: file.name,
                    size: file.size,
                    status: FILE_UPLOAD_STATES.PENDING,
                    loadingPercentage: 100,
                }));

            this.formattedFiles.push(...newFiles);
        },
        removeFile(index) {
            this.selectedFiles.splice(index, 1);
            this.formattedFiles.splice(index, 1);
        },
        addDragOnClass() {
            if (!this.isDragOn) {
                this.isDragOn = true;
            }
        },
        deleteDragOnClass() {
            if (this.isDragOn) {
                this.isDragOn = false;
            }
        },
        preventAndStop(e) {
            e.preventDefault();
            e.stopPropagation();
        },
        arrayUnique(newFiles) {
            const fileNames = new Set(this.selectedFiles.map(({ name }) => name));
            return [...this.selectedFiles, ...newFiles.filter(({ name }) => !fileNames.has(name))];
        },
        onDrop(e) {
            this.preventAndStop(e);
            this.deleteDragOnClass();

            // Check if file type is appropriate
            const appropriateFileType = !Object.values(e.dataTransfer.files).some(
                ({ name }) => !this.isAppropriateFileType(name),
            );

            // Add new files to list if file type is appropriate
            if (appropriateFileType) {
                const droppedFiles = e.dataTransfer.files;
                this.addFilesToList(droppedFiles);
            } else {
                this.$eventBus.$emit('showAlert', {
                    message: `${this.$i18n.t('partials.fileUploader.invalidFileTypeText')} ${this.$i18n.t(
                        'partials.fileUploader.youCanUpload',
                    )} ${this.acceptType}.`,
                    type: ALERT_TYPES.warning,
                });
            }
        },
        isAppropriateFileType(name) {
            // The following expression returns a list of all accepted file extensions because the
            // input type file element accepts a string of comma separated extension ( ".txt, .csv")
            const types = this.acceptType
                .trim()
                .split(',')
                .map(extension => extension.trim());
            return types.some(acceptedType => name.endsWith(acceptedType));
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/mixins';
@import '~@/assets/scss/filter-table';
@import '~@/assets/scss/palette';
@import '~@/assets/scss/z-indexes';

.input-file-dragdrop {
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: $hide-smth-z-index;
}

.upload-container {
    border-radius: 0.5rem;
    border: dashed 0.0625rem rgba($gray5, 0.5);
    padding: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 11rem;

    &.drag-on {
        background: $blue5;
        opacity: unset;
        border-color: $blue;
    }
}

.upload-img {
    align-self: center;
    width: 3.5rem;
    margin-left: 2rem;
}

.upload-text-wrapper {
    width: calc(100% - 9.5rem);
}

.upload-text {
    font-size: 0.875rem;
    font-weight: 600;
    color: rgba($gray90, 0.5);
    margin-bottom: 0.25rem;

    &.drag-on {
        color: $gray90;
    }
}

.upload-link {
    color: rgba($blue, 0.5);
    cursor: pointer;

    &:hover {
        text-decoration: underline;
    }

    &.drag-on {
        color: $blue;
    }
}

.upload-subtext {
    color: rgba($gray60, 0.5);
    font-size: 0.75rem;

    &.drag-on {
        color: $gray60;
    }
}

.files-wrap {
    display: flex;
    flex-wrap: wrap;
}

.file-wrapper {
    width: 100%;
}

.file-wrapper:last-child {
    margin-bottom: 0;
}
</style>
