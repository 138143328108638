<template>
    <AbstractEditPageWrapper :shouldDisplaySidebar="true">
        <template #header>
            <AppHeader :pageTitle="pageTitle" />
        </template>

        <template #content>
            <div class="d-flex align-items-center mb-3">
                <div class="lf-subtitle mb-0">
                    {{ $i18n.t('generic.general') }}
                    <p
                        v-show="inEditMode"
                        class="lf-secondary-text mb-0"
                    >
                        {{ $i18n.t('generic.id') }}: {{ $route.params.id }}
                    </p>
                    <p
                        v-show="inEditMode"
                        class="lf-secondary-text mb-0"
                    >
                        {{ $i18n.t('generic.updateTime') }}:
                        {{ $localeLibrary.getFormattedDateAndTime(entityData.updateTime) }}
                    </p>
                    <p
                        v-show="inEditMode"
                        class="lf-secondary-text mb-0"
                    >
                        {{ $i18n.t('generic.updateUser') }}: {{ updateName }}
                    </p>
                </div>
                <!-- Bar containing labels and buttons -->
                <div class="labels-and-buttons-bar flex-grow-1 d-flex align-items-center justify-content-end">
                    <EntityStatusIndicator
                        v-if="entityData.state && entityData.state !== STATUS_CODES.NA"
                        :status="entityData.state"
                        class="state-label"
                    >
                    </EntityStatusIndicator>
                    <AppLabel
                        v-if="isDraft"
                        :title="$i18n.t('generic.stateMap.draft')"
                        :color="LABEL_COLOR.gray"
                        class="state-label"
                    />
                    <AppLabel
                        v-if="isPublished"
                        :title="$i18n.t('generic.stateMap.published')"
                        :color="LABEL_COLOR.green"
                        class="state-label"
                    />
                    <AppLabel
                        v-if="isUnpublished"
                        :title="$i18n.t('generic.stateMap.unpublishedChanges')"
                        :color="LABEL_COLOR.gray"
                        class="state-label"
                    />
                    <AppButton
                        v-if="!inEditMode"
                        :buttonType="BUTTON_TYPES.SECONDARY"
                        :label="$i18n.t('generic.advanced')"
                        data-test-id="advanced-btn"
                        @click="openAdvanced"
                    />
                    <AppButton
                        v-if="allowEditDraftBtn"
                        :buttonType="BUTTON_TYPES.SECONDARY"
                        :label="$i18n.t('generic.editDraft')"
                        @click="reloadEditor(EDITOR_MODE.EDIT)"
                    />
                    <AppButton
                        v-if="allowViewPublishedBtn"
                        :buttonType="BUTTON_TYPES.SECONDARY"
                        :label="$i18n.t('generic.viewPublished')"
                        @click="reloadEditor(EDITOR_MODE.VIEW)"
                    />
                    <AppButton
                        v-if="isRevertAvailable"
                        :buttonType="BUTTON_TYPES.SECONDARY"
                        :label="$i18n.t('productCatalog.editors.revertToPublished')"
                        @click="resetToPublished"
                    />
                </div>
            </div>
            <!-- generic fields for all entity types -->
            <AppDialogV2
                :visible="advanced"
                :title="$i18n.t('generic.advanced')"
                :disableDefaultSaveBtn="true"
                @close="closeAdvanced()"
            >
                <AppInputV3
                    v-if="!inEditMode"
                    id="template-name-uuid"
                    v-model="uuid"
                    :invalid="!validateUUID()"
                    :placeholder="$i18n.t('generic.uuid')"
                    :label="$i18n.t('generic.uuid')"
                    :explanationText="$i18n.t('generic.uuidtooltip')"
                    :tooltipOffset="0"
                    class="editor-input-largest pb-3"
                    data-test-id="uuid-input"
                />
                <template #modalFooter>
                    <AppButton
                        :buttonType="BUTTON_TYPES.PRIMARY"
                        :label="$i18n.t('generic.apply')"
                        :disabled="!validateUUID()"
                        @click="applyAdvanced()"
                    />
                </template>
            </AppDialogV2>

            <p
                v-show="uuid"
                class="lf-primary-text"
            >
                {{ $i18n.t('generic.id') }}: {{ uuid }}
            </p>

            <AppInputV3
                v-model="entityData.name"
                :placeholder="$i18n.t('generic.name')"
                :label="$i18n.t('generic.name')"
                :disabled="readonly"
                :invalid="!entityData.name && showValidation"
                class="editor-input-largest mb-3"
                data-test-id="entity-name"
            />
            <AppTextareaV3
                v-model="entityData.description"
                :placeholder="$i18n.t('generic.addDescription')"
                :label="$i18n.t('generic.description')"
                :resizeNone="true"
                :disabled="readonly"
                data-test-id="entity-description"
                class="editor-input-largest mb-3"
            />
            <AppInputV3
                v-model="entityData.externalId"
                :placeholder="$i18n.t('generic.externalId')"
                :label="$i18n.t('generic.externalId')"
                :invalid="!validateExternalId()"
                :errorMessage="$i18n.t('alertMessage.pc.externalIdWithUppercaseSupportInvalid')"
                :explanationText="$i18n.t('productCatalog.offers.editor.externalIdInformation')"
                :tooltipOffset="20"
                :optional="true"
                :disabled="readonly"
                class="editor-input-largest mb-3"
                data-test-id="entity-externalid"
            />

            <PolicySpecificationCoreData
                v-if="isPolicySpecificationEditor"
                ref="policySpecificationCoreDataComponent"
                v-model="policySpecificationCoreData"
                :initialPolicySpecificationData="initialPolicySpecificationData"
                :showMainValidation="showValidation"
                :childEntities="entityData.childEntities"
                :readonly="readonly"
                @policySpecificationValidationResult="
                    isPolicySpecificationValid => onPolicySpecificationValidated(isPolicySpecificationValid)
                "
            />

            <div v-if="childEntitiesAvailable">
                <h3 class="lf-subtitle mb-2">
                    {{ $i18n.tc('productCatalog.childEntities', PLURALIZATION.PLURAL) }}
                </h3>
                <AppMultiselectV3
                    v-if="isMultipleChildEntitiesTypes"
                    v-model="selectedChildEntitiesType"
                    :options="childEntitiesTypesOptions"
                    :additionalLabel="$i18n.t('productCatalog.childEntityType')"
                    :placeholder="$i18n.t('productCatalog.chooseChildEntityType')"
                    :small="true"
                    :disabled="readonly"
                    label="typeName"
                    data-test-id="child-entities-type-picker"
                    trackBy="type"
                    class="editor-input-largest mb-3"
                />
                <AppMultiselectV3
                    v-if="!isAtiotDemo"
                    v-model="entityData.childEntities"
                    :options="childEntitiesOptionsWithDisplayName"
                    :additionalLabel="childEntitiesLabel"
                    :placeholder="$i18n.t('productCatalog.pleaseSelectChildEntityYouWantToAdd')"
                    :disabled="(isMultipleChildEntitiesTypes && !selectedChildEntitiesType) || readonly"
                    :small="true"
                    :multiple="true"
                    label="displayName"
                    data-test-id="child-entities"
                    trackBy="id"
                    class="editor-input-largest mb-5"
                />
                <AtiotOfferEntityCardinality
                    v-if="isAtiotDemo"
                    v-model="entityData.childEntities"
                    :options="childEntitiesOptions"
                    :optionsType="selectedChildEntitiesType"
                    :disabled="(isMultipleChildEntitiesTypes && !selectedChildEntitiesType) || readonly"
                    class="mb-5"
                />
            </div>

            <!-- template data part -->
            <div class="lf-subtitle">
                {{ $i18n.tc('productCatalog.entities.template', PLURALIZATION.SINGULAR) }}
            </div>
            <AppMultiselectV3
                v-model="entityData.templateId"
                :small="true"
                :disabled="disabledTemplateMultiselect || readonly"
                :verified="disabledTemplateMultiselect"
                :options="templates"
                :additionalLabel="$i18n.tc('productCatalog.entities.template', PLURALIZATION.SINGULAR)"
                :placeholder="$i18n.tc('productCatalog.entities.template', PLURALIZATION.SINGULAR)"
                :error="showValidation && !entityData.templateId"
                data-test-id="template"
                label="name"
                trackBy="id"
                optionId="id"
                class="editor-input-largest mb-5"
                @input="id => buildDataForFormBuilder(id)"
            />
            <div
                v-if="templateRenderError"
                class="lf-error-message"
            >
                {{ $i18n.t('alertMessage.pc.errorRenderingTemplate') }}
            </div>
            <!-- Generate Template Fields -->
            <div v-else>
                <div v-if="entityData.templateId">
                    <div class="lf-primary-text mb-3">
                        {{ templateName }}
                    </div>
                    <GroupGenerator
                        v-model="entityData.templateData"
                        :groups="templateGroups"
                        :showValidation="showValidation && (showTemplateValidation || showFormBuilderWidgetValidation)"
                        :disabled="readonly"
                        class="w-60 mb-5"
                        data-test-id="template-form"
                    >
                        <template #header="{ group }">
                            <AppTextUnderline
                                :text="group.name"
                                class="mb-3"
                            />
                        </template>

                        <template #divider>
                            <div class="mb-3" />
                        </template>

                        <template #dividerGroup>
                            <div class="mb-5" />
                        </template>
                    </GroupGenerator>
                </div>
                <div
                    v-else
                    class="lf-secondary-text mb-3"
                >
                    {{ $i18n.t('productCatalog.chooseTemplate') }}
                </div>
            </div>
            <EntityNotes
                v-if="enableEntityNotes"
                v-model="entityNotes"
                class="w-60"
                :entityType="entityTypeString"
            />
            <div class="end-line-content" />
        </template>

        <template
            v-if="readonly"
            #sideBar
        >
            <div class="lf-subtitle m-3">
                {{ $i18n.t('productCatalog.editors.versionHistory') }}
            </div>
            <div
                v-for="entry in versionsHistory"
                :key="entry.version"
            >
                <div
                    class="d-flex lf-primary-text version-item"
                    :class="{ active: entry.version === currentVersion.version }"
                    @click="selectVersion(entry)"
                >
                    <AppIcon
                        v-if="entry.version === currentVersion.version"
                        type="check-new"
                        size="1rem"
                        class="icon mr-2"
                    />
                    <span>{{ entry.label }}</span>
                    <span
                        v-if="entityNoteOperatorConfig && entry.publishMessage"
                        class="entity-note"
                        :title="entry.publishMessage"
                    >
                        <AppIcon
                            type="document"
                            size="1rem"
                            class="icon"
                        />
                    </span>
                    <EntityStatusIndicator
                        v-if="entry.state"
                        :status="entry.state"
                        class="justify-content-end flex-grow-1 state-label"
                    >
                    </EntityStatusIndicator>
                </div>
            </div>
        </template>

        <template #controls>
            <EditorButtons
                :showSaveDraft="!readonly"
                :showPublish="!readonly"
                :disableSave="isSaveDisabled"
                @cancel="routeToListPage"
                @saveDraft="saveEntity(false)"
                @publish="saveEntity(true)"
                @save="saveEntity"
            />
        </template>
    </AbstractEditPageWrapper>
</template>

<script>
// Components
import PolicySpecificationCoreData from '@/__new__/features/consent/partyPrivacyProfileSpecification/PolicySpecificationCoreData.vue';
import AtiotOfferEntityCardinality from '@/__new__/features/pc/atiot/AtiotOfferEntityCardinality.vue';
import GroupGenerator from '@/components/FormBuilder/GroupGenerator.vue';
import AbstractEditPageWrapper from '@/components/layout/AbstractEditPageWrapper.vue';
import AppHeader from '@/components/layout/AppHeader.vue';
import EditorButtons from '@/components/layout/EditorButtons.vue';
import AppDialogV2 from '@/components/partials/AppDialogV2.vue';
import AppLabel from '@/components/partials/AppLabel.vue';
import AppTextUnderline from '@/components/partials/AppTextUnderline.vue';
import EntityStatusIndicator from '@/components/partials/EntityStatusIndicator.vue';
import AppIcon from '@/components/partials/icon/AppIcon.vue';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import AppInputV3 from '@/components/partials/inputs/AppInputV3.vue';
import AppMultiselectV3 from '@/components/partials/inputs/AppMultiselectV3.vue';
import AppTextareaV3 from '@/components/partials/inputs/AppTextareaV3.vue';
import EntityNotes from '@/components/partials/inputs/EntityNotes.vue';

// Helpers
import { CommonTemplateEntityBaseModel } from '@/__new__/services/dno/template/models/CommonTemplateEntityBaseModel';
import { ALERT_TYPES } from '@/common/alerts/Alert';
import Button from '@/common/button/Button';
import { STATUS_CODES } from '@/common/commonHelper';
import ENTITY_TYPES from '@/common/entities/entityTypes';
import tableColumnType from '@/common/filterTable';
import { textToLowerCase, validateAlphaNumericWithDashAndUnderscore, validateKebabCase } from '@/common/formatting';
import { ICON_TYPES } from '@/common/iconHelper';
import { LABEL_COLOR } from '@/common/labelsHelper';
import { PLURALIZATION } from '@/common/locale/labelSingularOrPlural';
import luaErrors, { modules } from '@/common/luaErrors';
import { FORM_BUILDER_TYPES, WIDGETS } from '@/formBuilderConfig';
import RouteNames from '@/router/routeNames';
import permissionsService, { getOperatorConfigValue } from '@/services/permissions/permissions.service';
import { isAxiosError } from 'axios';

// HTTP
import { EDITOR_MODE } from '@/common/entities/entityHelper';
import { isValidUuid } from '@/common/uuidHelper';

export default {
    name: 'CommonTemplateEntityEditorWrapperBase',
    components: {
        AbstractEditPageWrapper,
        AppHeader,
        AppButton,
        AppInputV3,
        AppTextareaV3,
        AppMultiselectV3,
        GroupGenerator,
        AppTextUnderline,
        AppDialogV2,
        AtiotOfferEntityCardinality,
        EditorButtons,
        AppLabel,
        PolicySpecificationCoreData,
        AppIcon,
        EntityStatusIndicator,
        EntityNotes,
    },
    props: {
        enableEntityNotes: {
            type: Boolean,
            default: false,
        },
        childEntitiesTypes: {
            type: Array,
            default: () => [],
            required: false,
        },
        entitiesTypesMap: {
            type: Object,
            default: () => ({}),
            required: false,
        },
    },
    data() {
        return {
            // entity data to input
            entityData: {
                childEntities: [],
                templateData: {},
                formattedDataForFormBuilder: [],
            },

            entityDraft: undefined,
            isOnlyDraft: false,
            updateName: this.$i18n.t('generic.N/A'),

            /**
             * Validation flags
             */
            // flag indicating whether validation errors should be shown
            showValidation: false,
            // flag indicating if there's an error with form builder widgets
            showFormBuilderWidgetValidation: false,
            // flag indicating if any template has validation errors
            showTemplateValidation: false,

            historyEntity: undefined,
            currentVersion: undefined,
            versionsHistory: [],

            headerOptions: [
                this.$i18n.t('generic.min'),
                this.$i18n.t('generic.max'),
                this.$i18n.t('generic.pricePerUnitPerPeriod'),
            ],

            advanced: false,
            uuid: '',

            readonly: false,
            isUnpublished: false,
            isTemplateIdSet: false,
            isOutdatedDraft: false,

            // generic stuff
            entityType: this.$route.params.entityType,
            selectedChildEntitiesType: null,
            ICON_TYPES,
            BUTTON_TYPES,
            ENTITY_TYPES,
            EDITOR_MODE,
            PLURALIZATION,
            STATUS_CODES,
            permissionsService,
            validateKebabCase,
            deleteConfirmationButton: new Button({
                label: this.$i18n.t('generic.delete'),
                alertType: ALERT_TYPES.warning,
                handler: this.deleteAction,
            }),
            revertConfirmationButton: new Button({
                label: this.$i18n.t('productCatalog.editors.revert'),
                alertType: ALERT_TYPES.warning,
                handler: () => this.initData(true),
            }),
            resetDraftButton: new Button({
                label: this.$i18n.t('productCatalog.editors.reset'),
                alertType: ALERT_TYPES.warning,
                handler: this.resetDraft,
            }),
            restartDraftEditButton: new Button({
                label: this.$i18n.t('generic.refreshPage'),
                alertType: ALERT_TYPES.warning,
                handler: () => this.$router.go(0),
            }),
            LABEL_COLOR,

            /**
             * Flag indicating there was an error when attempting to render the template
             * chosen for the entity.
             */
            templateRenderError: false,
            /**
             * Remove after demo.
             */
            isAtiotDemo: getOperatorConfigValue('isAtiotDemo'),
            entityNotes: undefined,
            entityNoteOperatorConfig: getOperatorConfigValue('entityNotesEnabled', false),
        };
    },
    computed: {
        templateName() {
            const template = this.templates.find(tmp => tmp.id === this.entityData.templateId);
            return template?.name || this.$i18n.t('generic.N/A');
        },
        isMultipleChildEntitiesTypes() {
            return this.childEntitiesTypes.length > 1;
        },
        childEntitiesAvailable() {
            return this.childEntitiesTypes.length;
        },
        childEntitiesTypesOptions() {
            return this.childEntitiesAvailable
                ? this.childEntitiesTypes.map(type => ({
                      typeName: this.$i18n.tc(this.entitiesTypesMap[type]),
                      type,
                  }))
                : [];
        },
        childEntitiesOptions() {
            if (this.childEntitiesAvailable) {
                if (this.isMultipleChildEntitiesTypes && this.selectedChildEntitiesType) {
                    return this.getEntitiesApproved(this.selectedChildEntitiesType.type);
                }
                return this.getEntitiesApproved(this.childEntitiesTypes[0]);
            }

            return [];
        },
        childEntitiesOptionsWithDisplayName() {
            return this.childEntitiesOptions.map(childEntity => {
                childEntity.displayName = `${childEntity.name} - ${childEntity.id}`;
                return childEntity;
            });
        },
        childEntitiesLabel() {
            return this.isMultipleChildEntitiesTypes
                ? this.$i18n.tc('productCatalog.childEntities', PLURALIZATION.PLURAL)
                : '';
        },
        // routeListName() {
        //     switch (this.entityType) {
        //         case ENTITY_TYPES.PARTY_PRIVACY_PROFILE_SPECIFICATION:
        //             return RouteNames.PARTY_PRIVACY_PROFILE_SPECIFICATION;
        //         default:
        //             return '';
        //     }
        // },
        pageTitle() {
            if (this.readonly) {
                return `${this.entityTypeString}`;
            }
            if (this.inEditMode) {
                return `${this.$i18n.t('generic.edit')} ${this.entityTypeString} ${this.$i18n.t(
                    'generic.stateMap.draft',
                )}`;
            }
            return `${this.$i18n.t('generic.addNew')} ${this.entityTypeString}`;
        },
        inEditMode() {
            return Boolean(this.$route.params.id) && !this.$route.params.clone;
        },
        disabledTemplateMultiselect() {
            return Boolean(this.inEditMode && !this.isDraftTemplateIdSet);
        },
        isDraftTemplateIdSet() {
            return this.isOnlyDraft && !this.isTemplateIdSet;
        },
        templates() {
            return this.getTemplatesApproved(this.entityType);
        },
        parentRelationsColumnsData() {
            return [
                {
                    name: this.$i18n.t('generic.name'),
                    key: 'name',
                    field: 'name',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('generic.type'),
                    key: 'type',
                    field: 'type',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
            ];
        },
        childRelationsColumsData() {
            return [...this.parentRelationsColumnsData];
        },
        allowViewPublishedBtn() {
            return this.$route.params.id && !this.readonly && !this.isOnlyDraft && this.inEditMode;
        },
        allowEditDraftBtn() {
            return this.$route.params.id && this.readonly && !this.isOnlyDraft;
        },
        isDraft() {
            return this.$route.params.id && !this.readonly && this.entityDraft;
        },
        isPublished() {
            return this.$route.params.id && this.readonly;
        },
        isRevertAvailable() {
            return this.inEditMode && !this.readonly && !this.isOnlyDraft;
        },
        templateGroups() {
            return this?.entityData?.formattedDataForFormBuilder || [];
        },
        // Indicating if there's an error with standard fields (eg: name) in the entity editor
        isMainLayoutInvalid() {
            return (
                !this.entityData.name ||
                !this.entityData.templateId ||
                !this.validateExternalId() ||
                !this.validateUUID()
            );
        },
        isSaveDisabled() {
            if (!this.entityNoteOperatorConfig) {
                return false;
            }
            if (this.readonly) return true;
            if (!this.enableEntityNotes) return false;
            return !this.entityNotes;
        },
    },
    created() {
        this.loadData();
    },
    methods: {
        textToLowerCase,
        selectVersion(entry) {
            this.currentVersion = entry;
            this.initData();
        },
        reloadEditor(mode) {
            const { id } = this.$route.params;
            // Use push to list page because router don`t want ot reload same page
            this.$router
                .push({ name: this.routeListName, params: { companyId: this.$route.params.companyId } })
                .then(() => {
                    this.$router.push({
                        name: RouteNames.ENTITY_EDITOR,
                        params: {
                            entityType: this.entityType,
                            id,
                            mode,
                            companyId: this.$route.params.companyId,
                        },
                    });
                });
        },
        resetToPublished() {
            this.$alert(this.$i18n.t('productCatalog.editors.revertWarning'), {
                type: ALERT_TYPES.warning,
                buttons: [this.revertConfirmationButton],
            });
        },
        async resetDraft() {
            await this.initData(true);
            this.save(false, true);
        },
        getTemplateDefaultProperties() {
            const template = this.getTemplate(this.entityType, this.templateId);
            const properties = template?.properties || [];

            return properties.reduce((acc, prop) => {
                if (Object.prototype.hasOwnProperty.call(prop, 'defaultValue') && prop.defaultValue !== undefined) {
                    acc[prop.id] = prop.defaultValue;
                } else if (
                    Object.prototype.hasOwnProperty.call(this.$lfFormBuilder.config[prop.dataType], 'defaultValue')
                ) {
                    acc[prop.id] = this.$lfFormBuilder.config[prop.dataType]?.defaultValue;
                } else {
                    acc[prop.id] = '';
                }

                return acc;
            }, {});
        },
        async deleteAction() {
            if (this.deleteActionApiCall && typeof this.deleteActionApiCall === 'function') {
                await this.deleteActionApiCall(this.entityData, this.routeToListPage);
            }
            // try {
            //     if (this.entityData.state !== STATUS_CODES.NA) {
            //         await this.deleteEntity(this.entityData.id, this.entityData.version);
            //     } else {
            //         await this.deleteEntityDraft(this.entityType, this.entityData.id);
            //     }
            //     this.$alert(
            //         this.$i18n.t('alertMessage.successActionMessageWithRedirect', {
            //             action: this.$i18n.t('generic.deleted'),
            //             entityName: this.entityTypeString,
            //             entityNameRedirect: this.entityTypeString.toLowerCase(),
            //         }),
            //         { type: ALERT_TYPES.success },
            //     );
            //     setTimeout(this.routeToListPage, 1000);
            // } catch (e) {
            //     Sentry.captureException(e);
            //     this.$alert(
            //         this.$i18n.t('alertMessage.errorDoingSmthTryAgain', {
            //             action: this.$i18n.t('generic.deleting'),
            //             entityName: this.entityTypeString.toLowerCase(),
            //         }),
            //     );
            // }
        },
        /**
         * Checks if any form builder widgets have detected errors.
         * If there is an error the `value.error` property of the widget will be true.
         */
        validateFormBuilderWidgets() {
            if (!this.entityData.templateData) {
                return false;
            }
            const groups = Object.values(this.entityData.templateData);

            // Build a list of property tuples {externalId, value, type}
            const properties = groups
                .map(group =>
                    Object.entries(group?.groupValue?.value ?? []).map(([externalId, value]) => ({
                        externalId,
                        value,
                        type: group.fields?.find(field => field.id === externalId)?.type,
                    })),
                )
                .flat();

            // Filter out all properties which aren't presets (widgets)
            const presetTypes = Object.keys(WIDGETS);
            const presets = properties.filter(prop => presetTypes.includes(prop.type));

            // Check if any of the widgets report an error
            this.showFormBuilderWidgetValidation = presets.some(preset => preset.value?.error);
            return this.showFormBuilderWidgetValidation;
        },
        getIsInvalid() {
            return this.isMainLayoutInvalid || this.validateFormBuilderWidgets();
        },
        validateUUID() {
            if (this.uuid) {
                return isValidUuid(this.uuid);
            }
            return true;
        },
        validateExternalId() {
            return !this.entityData.externalId || validateAlphaNumericWithDashAndUnderscore(this.entityData.externalId);
        },
        saveEntity(isPublish = true) {
            if (isPublish) {
                this.showValidation = true;
                if (this.getIsInvalid()) {
                    this.$alert(this.$i18n.t('alertMessage.pleaseFixValidation'), { type: ALERT_TYPES.error });
                    return;
                }
            }
            if (typeof this.validateSpecificEntityData === 'function') {
                if (!this.validateSpecificEntityData()) {
                    return;
                }
            }
            this.save(isPublish);
        },
        save(isPublish = true, stayInEditor = false) {
            this.$withProgressBar(
                async () => {
                    let payload = {};
                    // Entity type specific data part
                    if (typeof this.formatSpecificEntityPayload === 'function') {
                        payload = this.formatSpecificEntityPayload();
                    } else {
                        payload = CommonTemplateEntityBaseModel.toJson(this.entityData);
                    }
                    // Template data part
                    let properties;
                    if (this.entityData.templateId && this.entityData.templateData) {
                        // parsing template related data
                        const templateDataArr = Object.values(this.entityData.templateData);
                        properties = {};

                        templateDataArr.forEach(group => {
                            const formattedProperties = {};

                            Object.entries(group.groupValue.value).forEach(([key, value]) => {
                                const matchingProp = group.fields.find(field => field.id === key);
                                if (matchingProp.type === FORM_BUILDER_TYPES.JSON) {
                                    formattedProperties[key] = value || null;
                                } else if (matchingProp.type === FORM_BUILDER_TYPES.REFERENCE) {
                                    if (value) {
                                        formattedProperties[key] = value.id;
                                    }
                                } else {
                                    formattedProperties[key] = value;
                                }
                            });

                            properties = {
                                ...properties,
                                ...formattedProperties,
                            };
                        });

                        // actually formatting payload which will go to the API
                        payload = {
                            ...payload,
                            ...properties,
                        };
                    } else if (this.entityData.properties) {
                        payload = {
                            ...payload,
                            ...this.entityData.properties,
                        };
                    } else {
                        properties = this.getTemplateDefaultProperties();
                    }
                    const propertiesFormatted = properties || this.entityData.properties;
                    Object.entries(propertiesFormatted).forEach(([key, val]) => {
                        const isArrayValValid = Array.isArray(val) ? val.length : true;

                        if (val === null || !isArrayValValid) {
                            delete propertiesFormatted[key];
                        }
                    });

                    // Make changes on DNO
                    if (isPublish) {
                        if (this.inEditMode) {
                            if (this.isOnlyDraft) {
                                await this.addEntityApiCall(payload, this.$route.params.id);
                            } else {
                                await this.updateEntityApiCall(payload, this.entityNotes);
                            }
                        } else {
                            await this.addEntityApiCall(payload);
                        }
                    } else {
                        await this.setEntityDraftApiCall(payload);
                    }

                    // Show alert indicating change was made successfully
                    const wasEntityUpdated = this.inEditMode && !this.isOnlyDraft;
                    this.$alert(
                        this.$i18n.t('alertMessage.successActionMessageWithRedirect', {
                            action: wasEntityUpdated
                                ? this.$i18n.t('generic.updated')
                                : this.$i18n.t('generic.created'),
                            entityName: this.entityTypeString,
                            entityNameRedirect: this.entityTypeString.toLowerCase(),
                        }),
                        { type: ALERT_TYPES.success },
                    );

                    if (stayInEditor) {
                        this.reloadEditor(EDITOR_MODE.EDIT);
                    } else {
                        setTimeout(this.routeToListPage, 1000);
                    }
                },
                {
                    errorHandler: error => {
                        if (
                            isAxiosError(error) &&
                            error.response?.data &&
                            error.response.data.module === modules.CATALOG &&
                            error.response.data.code === luaErrors.CATALOG.WRONG_VERSION.code
                        ) {
                            this.$alert(
                                this.$i18n.t('alertMessage.pleaseRestartDraftEdit', {
                                    entityName: this.entityTypeString.toLowerCase(),
                                }),
                                {
                                    buttons: [this.restartDraftEditButton],
                                },
                            );
                        } else {
                            this.$alert(
                                this.$i18n.t('alertMessage.errorDoingSmthTryAgain', {
                                    action: this.$i18n.t('generic.saving'),
                                    entityName: this.entityTypeString.toLowerCase(),
                                }),
                            );
                        }
                    },
                },
            );
        },
        routeToListPage() {
            this.$router.push({ name: this.routeListName, params: { companyId: this.$route.params.companyId } });
        },
        async addEntityApiCall(payload, draftId) {
            const formattedPayload = {
                template_id: this.entityData.templateId,
                data: {
                    ...payload,
                    name: this.entityData.name,
                    description: this.entityData.description,
                },
                ...(draftId && { entity_id: draftId }),
                ...(this.uuid && { entity_id: this.uuid }),
            };
            await this.addEntity(formattedPayload, this.entityType);
        },
        async updateEntityApiCall(data, publishMessage) {
            await this.updateEntity(
                {
                    id: this.$route.params.id,
                    version: this.entityData.version,
                    data,
                },
                this.entityType,
                publishMessage,
            );
        },
        async setEntityDraftApiCall(data) {
            const response = await this.setEntityDraft(
                {
                    ...(!this.inEditMode && this.uuid && { id: this.uuid }),
                    ...(this.inEditMode && { id: this.$route.params.id }),
                    data,
                    template_id: this.entityData.templateId,
                },
                this.entityType,
            );
            return response;
        },
        openAdvanced() {
            this.advanced = true;
        },
        closeAdvanced() {
            this.uuid = '';
            this.advanced = false;
        },
        applyAdvanced() {
            this.advanced = false;
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/consistency';
@import '~@/assets/scss/editor-layout-v2';
@import '~@/assets/scss/typographyV2';
@import '~@/assets/scss/z-indexes';
@import '~@/assets/scss/palette';

.w-60 {
    width: 60%;
}

.w-80 {
    width: 80%;
}

.end-line-content {
    height: 1rem;
}
.labels-and-buttons-bar > * {
    margin-left: 1rem;
}
.state-label {
    height: 1.5rem;
}

.table-input {
    width: 5rem;
}

.table-multiselect {
    max-height: 2rem;
}

.link:hover {
    color: $blue;
    text-decoration: underline;
}

.pricing-input {
    max-width: 18rem;
}

.unit-picker {
    // align unit picker
    margin-right: -1rem;

    ::v-deep.multiselect-picker {
        width: 9rem;
    }
}

.modal-content-wrapper {
    margin: $spacing-l;

    height: 100%;

    display: flex;
    flex-direction: column;
}

.content-controls {
    position: sticky;
    z-index: $overlap-smth-z-index;
    bottom: 0;
    left: 0;
    width: 100%;
    margin-top: 2rem;
    display: flex;
    justify-content: flex-end;
    height: 5.56rem;
    padding: 1.5rem 2.5rem;
    border-top: 1px solid $blue15;
    background-color: $white;
}

.inline-warning {
    color: $yellow-800;
    padding: 0.5rem;
    background-color: $yellow-100;
    border: 0.063rem solid $yellow-400;
    border-radius: 0.25rem;
}

.version-item {
    font-size: 0.875rem;
    padding: 1rem 1.5rem;
    cursor: pointer;

    &:hover {
        background-color: $blue5;
    }

    &.active {
        color: $blue;
        background-color: $blue15;
    }

    .icon {
        position: relative;
        top: 3px;
    }
}

.entity-note {
    margin-left: 0.5rem;
}
</style>
