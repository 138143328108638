// static templates that can not be deleted or edited because they are not in database
export const templatesReservedKeys: string[] = [
    'loop',
    'choice',
    'lambda',
    'loop',
    'callback',
    'wait',
    'hello_world_plan',
    'simple_task',
    'parallel',
];

export default { templatesReservedKeys };
