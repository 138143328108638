<template>
    <div>
        <hr />
        <div
            v-if="address && address.type"
            class="address-title"
        >
            <img
                :src="getImageTypeLogo(address.type)"
                alt="address-type-logo"
                class="mr-1"
            />
            {{ ADDRESS_TYPE_TO_LABEL_MAP.get(address.type) }}
        </div>

        <!-- Address Editor -->
        <div
            v-if="mode === modes.Edit"
            class="editor-mode"
        >
            <!-- TODO: Figure out if changing address type is allowed -->
            <div
                v-if="!address.type"
                class="addressType"
            >
                <AppMultiselectV3
                    v-model="newAddress.type"
                    :options="addressTypesOptions"
                    :small="true"
                    additionalLabel="Type"
                    label="label"
                    trackBy="value"
                    @select="setAddressFields()"
                />
            </div>

            <div v-if="addressFields && Object.keys(addressFields).length">
                <div
                    v-for="(addressField, index) in Object.keys(newAddress)"
                    :key="index"
                >
                    <div
                        v-if="addressFields[addressField] && addressFields[addressField].enabled"
                        :class="`${addressField}-edit`"
                    >
                        <AppInputV3
                            v-model="newAddress[addressField]"
                            :label="getAddressFieldInputLabel(addressField)"
                        />
                    </div>
                </div>

                <div class="buttons">
                    <AppButton
                        v-if="!Boolean(id)"
                        :label="$t('generic.save')"
                        :buttonType="BUTTON_TYPES.PRIMARY"
                        class="mr-2"
                        @click="addAddress"
                    />
                    <AppButton
                        v-else-if="Boolean(id)"
                        :label="$t('generic.update')"
                        :buttonType="BUTTON_TYPES.PRIMARY"
                        class="mr-2"
                        @click="updateAddress"
                    />
                    <AppButton
                        v-if="showRemoveButton"
                        :label="$t('generic.remove')"
                        :buttonType="BUTTON_TYPES.TERMINATION"
                        class="mr-2"
                        @click="removeAddress(id)"
                    />
                    <AppButton
                        :label="$t('generic.cancel')"
                        :buttonType="BUTTON_TYPES.SECONDARY"
                        @click="cancelEdit"
                    />
                </div>
            </div>
        </div>

        <!-- View mode -->
        <div
            v-if="mode === modes.View"
            class="display-mode"
        >
            <div
                v-for="(addressField, index) in Object.keys(address)"
                :key="index"
            >
                <div
                    v-if="displayAddressField(addressField)"
                    class="address"
                >
                    <div :class="`${addressField}-view`">
                        <p class="view-label">
                            {{ `${getAddressFieldViewLabel(addressField)}: ` }}
                        </p>
                        <p class="view-value">
                            {{ address[addressField] }}
                        </p>
                    </div>
                </div>
            </div>

            <!-- Edit links -->
            <div class="edit-links">
                <div
                    v-if="isUserAllowed('UMAccountEditAccountUpdateFields')"
                    class="edit-mode-toggle mr-3"
                    @click="editAddress"
                >
                    {{ $t('generic.edit') }}
                </div>
                <div
                    v-if="showEditTaxButton"
                    class="tax-edit-toggle"
                    @click="editTax"
                >
                    {{ $t('customerCare.account.editTax') }}
                </div>
            </div>
        </div>

        <!-- Tax ID information -->
        <div
            v-if="mode === modes.Tax"
            class="tax-id-editor"
        >
            <AppInputV3
                v-model="newTaxAreaId"
                :label="$t('customerCare.account.taxAreaID')"
            />
            <div class="buttons">
                <AppButton
                    :label="$t('generic.cancel')"
                    class="mr-3"
                    @click="cancelEdit"
                />
                <AppButton
                    :label="$t('generic.save')"
                    :buttonType="BUTTON_TYPES.PRIMARY"
                    :isSmall="true"
                    @click="updateTAID"
                />
            </div>
        </div>
    </div>
</template>

<script>
// Components
import AppInputV3 from '@/components/partials/inputs/AppInputV3.vue';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import AppMultiselectV3 from '@/components/partials/inputs/AppMultiselectV3.vue';

// Helpers
import {
    ADDRESS_TYPE,
    ADDRESS_TYPE_TO_LABEL_MAP,
    isAddressTypeWithGeoData,
    USER_MANAGER_HIERARCHY,
    ADDRESS_FIELDS,
} from '@/__new__/features/customerCare/common/customerCareHelper';
import permissionsService, { isUserAllowed } from '@/services/permissions/permissions.service';
import { camelToSnakeCase, snakeToCamelCase, camelCaseToText } from '@/common/utils';
import { snakeCaseToCommonString } from '@/common/formatting';
import { ALERT_TYPES } from '@/common/alerts/Alert';
import luaErrors, { modules } from '@/common/luaErrors';

// HTTP
import userManagerHTTP from '@/__new__/services/dno/user/http/user-management';
import taxHTTP from '@/http/tax';
import * as Sentry from '@sentry/vue';

// Enums
export const modes = {
    View: 'view',
    Edit: 'edit',
    Tax: 'tax',
};

export default {
    name: 'AddressEditor',

    components: {
        AppMultiselectV3,
        AppButton,
        AppInputV3,
    },

    props: {
        // Account ID or others.
        targetEntityId: {
            type: String,
            required: true,
        },
        initialMode: {
            type: String,
            required: false,
            default: modes.View,
        },
        address: {
            type: Object,
            required: false,
            default: () => undefined,
        },
        taxAreaId: {
            type: String,
            required: false,
            default: undefined,
        },
    },

    data() {
        return {
            // Proxy
            isAddressTypeWithGeoData,
            ADDRESS_TYPE_TO_LABEL_MAP,
            modes,
            ADDRESS_FIELDS,
            BUTTON_TYPES,

            // Values
            mode: modes.View,
            newAddress: {},
            newTaxAreaId: '',
            addressFields: {},
        };
    },

    computed: {
        id() {
            return this.address.addressId ? this.address.addressId : null;
        },
        showRemoveButton() {
            return Boolean(this.id);
        },
        addressOptions() {
            return permissionsService.getOperatorAllowedAddressTypes(USER_MANAGER_HIERARCHY.ACCOUNT);
        },
        addressTypesOptions() {
            // Confusing I know.
            // Vue (at least 2.x) does not support iterating over JS collections like maps and sets
            return [...ADDRESS_TYPE_TO_LABEL_MAP]
                .map(a => ({
                    label: a[1],
                    value: a[0],
                }))
                .filter(type => this.addressOptions?.includes(type.value));
        },
        showEditTaxButton() {
            return permissionsService.taxScrubbingEnabled() && isUserAllowed('UMAccountEditAccountUpdateFields');
        },
    },

    watch: {
        address: {
            handler() {
                this.setAddressFields();
            },
            immediate: true,
            deep: true,
        },
    },

    created() {
        this.mode = this.initialMode;
        this.setAddressFields();
    },

    methods: {
        isUserAllowed,
        editAddress() {
            this.mode = modes.Edit;
        },

        editTax() {
            this.mode = modes.Tax;
        },

        setAddressFields() {
            const type = this.address?.type || this.newAddress?.type?.value;
            if (type) {
                this.addressFields = permissionsService.getOperatorsAddressFieldsByAddressType(type);

                this.newAddress.type = {
                    value: this.mode === modes.Edit ? type : null,
                    label: this.mode === modes.Edit ? ADDRESS_TYPE_TO_LABEL_MAP.get(type) : '',
                };

                Object.keys(this.addressFields).forEach(field => {
                    this.newAddress[field] = this.address[snakeToCamelCase(field)] || '';
                });

                if (this.id) {
                    this.newAddress.id = this.id;
                }
            }
        },

        getAddressFieldInputLabel(key) {
            return snakeCaseToCommonString(key);
        },

        getAddressFieldViewLabel(key) {
            return camelCaseToText(key);
        },

        displayAddressField(addressField) {
            return (
                this.addressFields[camelToSnakeCase(addressField)]?.enabled &&
                this.newAddress[camelToSnakeCase(addressField)]
            );
        },

        async updateTAID() {
            try {
                this.$Progress.start();

                await taxHTTP.changeTaid(
                    this.targetEntityId,
                    USER_MANAGER_HIERARCHY.ACCOUNT,
                    this.newTaxAreaId,
                    this.id,
                );

                this.$emit('updatedData');
                this.mode = modes.View;

                this.$Progress.finish();
            } catch (e) {
                Sentry.captureException(e);
                this.$Progress.fail();
            }
        },

        /**
         * Mutating props intentionally to show it as if it was updated while I wait for parent to
         * refresh the component with new values.
         */
        mutateProps() {
            this.address.type = this.newAddress.type.value;
            Object.keys(this.addressFields).forEach(field => {
                this.address[snakeToCamelCase(field)] = this.newAddress[field];
            });
        },

        populateParts() {
            const parts = {};
            Object.keys(this.addressFields).forEach(key => {
                parts[key] = this.newAddress[key];
            });
            return parts;
        },

        async addAddress() {
            try {
                this.$Progress.start();
                // Hardcoded to account as that is currently only support UM entity type that has addresses
                const parts = this.populateParts();
                await userManagerHTTP.addAddress(
                    this.targetEntityId,
                    USER_MANAGER_HIERARCHY.ACCOUNT,
                    this.newAddress.type.value,
                    parts,
                );
                this.$Progress.finish();
                this.$emit('updatedData');
                this.mutateProps();
                this.mode = modes.View;
            } catch (e) {
                const { module: moduleName, code } = { ...e.response?.data };

                this.$Progress.fail();

                if (moduleName === modules.USER && code === luaErrors.USER.ACCOUNT_SERVICE_ADDRESS_ALREADY_EXIST.code) {
                    this.$eventBus.$emit('showAlert', {
                        message: this.$i18n.t('alertMessage.account.addressOnlyOneAllowed'),
                        type: ALERT_TYPES.error,
                    });
                } else {
                    Sentry.captureException(e);
                }
            }
        },

        async updateAddress() {
            try {
                this.$Progress.start();
                // Hardcoded to account as that is currently only support UM entity type that has addresses
                const parts = this.populateParts();
                const type = this.address.type || this.newAddress.type.value;
                await userManagerHTTP.updateAddress(
                    this.targetEntityId,
                    USER_MANAGER_HIERARCHY.ACCOUNT,
                    this.id,
                    type,
                    parts,
                );
                this.$Progress.finish();
                this.$emit('updatedData');
                this.mutateProps();
                this.mode = modes.View;
            } catch (e) {
                Sentry.captureException(e);
                this.$Progress.fail();
            }
        },

        /**
         * Removes an address from account
         * @param addressId
         */
        async removeAddress(addressId) {
            try {
                this.$Progress.start();
                await userManagerHTTP.removeAddress(this.targetEntityId, USER_MANAGER_HIERARCHY.ACCOUNT, addressId);
                this.$emit('updatedData');
                this.mode = modes.View;
                this.$emit('removeDeletedAddress');
                this.$Progress.finish();
            } catch (e) {
                Sentry.captureException(e);
                this.$Progress.fail();
            }
        },

        /**
         * Close the edit mode and go back to view mode in case it is already created address
         */
        cancelEdit() {
            if (this.id) {
                this.mode = modes.View;
            } else {
                this.$emit('closeEmptyAddress');
            }
        },

        getImageTypeLogo(type) {
            switch (type) {
                /* eslint-disable global-require */
                case ADDRESS_TYPE.SHIPPING:
                    return require('@/assets/icons/usermanager/addressTypes/icon-shipping.svg');
                case ADDRESS_TYPE.MAILING:
                    return require('@/assets/icons/usermanager/addressTypes/icon-mailing.svg');
                case ADDRESS_TYPE.SERVICE:
                    return require('@/assets/icons/usermanager/addressTypes/icon-message.svg');
                case ADDRESS_TYPE.PERMANENT:
                    return require('@/assets/icons/usermanager/addressTypes/icon-permanent.svg');
                case ADDRESS_TYPE.BILLING:
                    return require('@/assets/icons/usermanager/addressTypes/icon-wallet.svg');
                case ADDRESS_TYPE.UNKNOWN:
                    return require('@/assets/icons/usermanager/addressTypes/icon-permanent.svg');
                default:
                    return require('@/assets/icons/usermanager/addressTypes/icon-permanent.svg');
                /* eslint-enable global-require */
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/palette';
@import '~@/assets/scss/_typography';

.display-mode {
    font-size: 0.85rem;
}

.address-title {
    display: flex;
    align-items: center;

    text-transform: uppercase;
    color: $gray-blue;
    font-size: 0.75rem;
    font-weight: bold;
    line-height: 2.17;
    letter-spacing: normal;
}

.edit-links {
    text-decoration: underline;
    color: $gray-blue;

    cursor: pointer;

    display: flex;
}

.buttons {
    display: flex;

    margin-top: 0.75rem;
    margin-bottom: 1rem;
}

.view-label {
    display: inline-block;
    width: 10rem;
    margin: 0;
}

.view-value {
    display: inline-block;
    margin: 0;
}
</style>
