import VueCookie from 'vue-cookie';
import store from '@/store/store';
import { AUTH_STRATEGY } from '@/common/auth/strategies';

export const userState: { [key: string]: string } = {
    ACTIVE: 'ACTIVE',
    PENDING: 'PENDING',
    LOCKED: 'LOCKED',
};

export default class Account {
    id: number | null;
    name: string;
    email: string;
    companyName: string;
    companyId: string;
    accountState: string;
    permissions: string[];
    groups: string[];
    externalUserId: string;
    internal: boolean;
    authStrategy: AUTH_STRATEGY | null;
    companyNameToRoleNames: Record<string, string[]>;

    constructor({
        id = null,
        name = '',
        email = '',
        companyName = 'Tenant',
        companyId = '',
        accountState = userState.ACTIVE,
        permissions = [] as string[],
        groups = [],
        externalUserId = '',
        internal = false,
        authStrategy = null,
        companyNameToRoleNames = {},
    }) {
        this.id = id;
        this.name = name;
        this.email = email;
        this.companyName = companyName;
        this.companyId = companyId;
        this.accountState = accountState;
        this.permissions = permissions;
        this.groups = groups;
        this.externalUserId = externalUserId;
        this.internal = internal;
        this.authStrategy = authStrategy;
        this.companyNameToRoleNames = companyNameToRoleNames;
    }

    /**
     * Method to be used for storing users account information in persistent store (cookies).
     * As cost of doing partial and full updates is same (parsing whole object) I am not providing per property
     * update methods. Instead if you want to update one property just override the whole object.
     */
    async store(): Promise<void> {
        await store.dispatch('config/loadAccountData');
    }

    // Really not the best when it comes to performance but we have to make sure to load it from cookie as it is only
    // cross-domain storage we have. Passing it then back into this object to use its safe constructor.
    static get storedInstance(): Account {
        return new Account(store.getters[`config/getAccountData`] || {});
    }

    static deleteStore(): void {
        VueCookie.delete('lf-portal-session');
        document.cookie = '';
    }

    static authenticated(): boolean {
        return Boolean(VueCookie.get('lf-portal-session'));
    }

    static getUserState(): string {
        return this.storedInstance?.accountState || userState.ACTIVE;
    }
}
