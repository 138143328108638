
import Button from '@/common/button/Button';
import { ICON_TYPES } from '@/common/iconHelper';
import { streamStaticFilterEntries } from '@/__new__/services/dno/segments/http/staticFilter';
import ENTITY_TYPES from '@/common/entities/entityTypes';

import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import IconButton from '@/components/partials/IconButton.vue';
import SearchUserInSegment from '@/__new__/features/segments/SearchUserInSegment.vue';

export default {
    name: 'StaticSegmentEntriesList',
    components: {
        SearchUserInSegment,
        IconButton,
        AppButton,
    },
    props: {
        entriesPerPage: { type: Number, required: false, default: 250 },
        segment: { type: Object, required: false, default: () => undefined },
        entries: { type: Array, required: true },
    },

    data() {
        return {
            BUTTON_TYPES,
            ICON_TYPES,
            observer: null as IntersectionObserver | null,
            entityType: ENTITY_TYPES.STATIC_FILTER,
            isLoading: false,
            isLoadedAllEntries: false,
            searchResult: null as { entry: string; isFound: boolean } | null,
        };
    },

    computed: {
        filteredEntries() {
            const foundEntry = this.searchResult?.isFound ? [this.searchResult.entry] : [];
            return this.searchResult ? foundEntry : this.entries;
        },

        shouldShowNoEntries() {
            return (
                (this.isLoadedAllEntries && this.entries.length < 1) ||
                (this.searchResult && !this.searchResult.isFound)
            );
        },

        shouldShowMoreButton() {
            return !this.isLoadedAllEntries && !this.searchResult && this.segment;
        },
    },

    watch: {
        segment() {
            this.createObserver();
        },
    },

    mounted() {
        this.createObserver();
    },

    methods: {
        createObserver() {
            if (!this.segment || this.observer) return;
            const root = document.querySelector('.list-entries');
            this.observer = new IntersectionObserver(this.trigger, { root });
            this.observer?.observe(document.querySelector('#load-more') as Element);
        },

        trigger([{ isIntersecting }]: any[]): void {
            if (isIntersecting && !this.isLoading) {
                this.fetchEntriesPage();
            }
        },

        fetchEntriesPage() {
            this.isLoading = true;
            const limit = this.entries.length + this.entriesPerPage;
            streamStaticFilterEntries(this.segment.id, limit).then(({ data }) => {
                const increment = data.flat();
                if (increment.length < this.entriesPerPage || increment.length === this.entries.length) {
                    this.isLoadedAllEntries = true;
                }

                this.isLoading = false;
                this.$emit('entriesLoaded', increment);
            });
        },

        deleteEntry(entry: string) {
            this.$alert(this.$t('alerts.areYouSureDeleteEntity', { entityName: entry }), {
                type: this.$ALERT_TYPES.warning,
                buttons: [
                    new Button({
                        label: this.$t('generic.proceed'),
                        handler: () => {
                            this.$emit('removeEntry', entry);
                        },
                    }),
                ],
            });
        },
    },
};
