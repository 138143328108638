import { AxiosPromise } from 'axios';
import http, { DnoResponseBase, Entity } from '@/http/index';

const basePathRead = '/v3productcatalog';
const basePathWrite = '/v3productcatalogwrite';

type ResourceData = {
    name: string;
    description: string;
} & Record<string, any>;

type GetResourcesResponseBody = DnoResponseBase & {
    resource_by_id: Record<string, Entity<ResourceData>>;
};

/**
 * Add resource.
 *
 * @returns {AxiosPromise}
 */
export function getResources(ids: string[] = []): AxiosPromise<GetResourcesResponseBody> {
    return http({
        method: 'POST',
        url: `${basePathRead}/getresources`,
        headers: {
            'X-Requested-With': 'XMLHttpRequest',
        },
        data: {
            ids,
        },
    });
}

export type AddResourceRequestBody = {
    data: ResourceData;
    template_id: string;
    entity_id?: string;
};

/**
 * Add resource.
 *
 * @returns {AxiosPromise}
 */
export function addResource(data: AddResourceRequestBody): AxiosPromise<DnoResponseBase> {
    return http({
        method: 'POST',
        url: `${basePathWrite}/addresource`,
        headers: {
            'X-Requested-With': 'XMLHttpRequest',
        },
        data,
    });
}

/**
 * Update resource state.
 *
 * @returns {AxiosPromise}
 */
export function updateResourceState(id: string, version: number, state: number): AxiosPromise<DnoResponseBase> {
    return http({
        method: 'POST',
        url: `${basePathWrite}/updateresourcestate`,
        headers: {
            'X-Requested-With': 'XMLHttpRequest',
        },
        data: {
            id,
            version,
            state,
        },
    });
}

/**
 * Update resource.
 *
 * @returns {AxiosPromise}
 */
export function updateResource(id: string, version: number, data: ResourceData): AxiosPromise<DnoResponseBase> {
    return http({
        method: 'POST',
        url: `${basePathWrite}/updateresource`,
        headers: {
            'X-Requested-With': 'XMLHttpRequest',
        },
        data: {
            id,
            version,
            data,
        },
    });
}

/**
 * Get resource draft list.
 *
 * @returns {AxiosPromise}
 */
export function getResourceDraftList() {
    return http({
        method: 'POST',
        url: '/v3productcatalog/getresourcedraftlist',
    });
}

/**
 * Delete resource draft.
 *
 * @returns {AxiosPromise}
 */
export function deleteResourceDraft(id: string) {
    return http({
        method: 'POST',
        url: '/v3productcatalog/resourcedraftdelete',
        data: {
            id,
        },
    });
}

/**
 * Get resource draft.
 *
 * @returns {AxiosPromise}
 */
export function getResourceDraft(id: string) {
    return http({
        method: 'POST',
        url: '/v3productcatalog/getresourcedraft',
        data: {
            id,
        },
    });
}

/**
 * Set resource draft.
 *
 * @returns {AxiosPromise}
 */
type ResourceDraftPayload = {
    id?: string;
    data: object;
    template_id: string;
};

export function setResourceDraft(data: ResourceDraftPayload) {
    return http({
        method: 'POST',
        url: '/v3productcatalogwrite/setresourcedraft',
        data,
    });
}

export default {
    getResources,
    addResource,
    updateResourceState,
    updateResource,
};
