export default class EsimProfileData {
    constructor(getProfileInfoResponse) {
        this.resourceId = getProfileInfoResponse.resource_id || '';
        this.profileType = getProfileInfoResponse.profile_type || '';
        this.eid = getProfileInfoResponse.eid || '';
        this.msisdn = getProfileInfoResponse.msisdn || '';
        this.imei = getProfileInfoResponse.imei || '';
        this.imsi = getProfileInfoResponse.imsi || '';
        this.iccid = getProfileInfoResponse.iccid || '';
    }
}
