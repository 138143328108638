import ENTITY_TYPES from '@/common/entities/entityTypes';
import {
    getPolicySpecifications,
    addPolicySpecification,
    updatePolicySpecification,
    getPolicySpecificationDraftList,
    deletePolicySpecificationDraft,
    getPolicySpecificationDraft,
    setPolicySpecificationDraft,
} from '@/__new__/services/dno/privacyConsent/http/privacy';

export function getEntities(entityType, entityIds = []) {
    if (entityType === ENTITY_TYPES.PARTY_PRIVACY_PROFILE_SPECIFICATION) {
        return getPolicySpecifications(entityIds);
    }
    return new Error('wrong entity type');
}

export function getEntityDrafts(entityType) {
    if (entityType === ENTITY_TYPES.PARTY_PRIVACY_PROFILE_SPECIFICATION) {
        return getPolicySpecificationDraftList();
    }
    return new Error('wrong entity type');
}

export function deleteEntityDraft(entityType, id) {
    if (entityType === ENTITY_TYPES.PARTY_PRIVACY_PROFILE_SPECIFICATION) {
        return deletePolicySpecificationDraft(id);
    }
    return new Error('wrong entity type');
}

export function addEntity(entityData, entityType) {
    if (entityType === ENTITY_TYPES.PARTY_PRIVACY_PROFILE_SPECIFICATION) {
        return addPolicySpecification(entityData);
    }
    return new Error('wrong entity type');
}

export function updateEntity(entityData, entityType, publishMessage) {
    if (entityType === ENTITY_TYPES.PARTY_PRIVACY_PROFILE_SPECIFICATION) {
        return updatePolicySpecification(entityData.id, entityData.version, entityData.data);
    }
    return new Error('wrong entity type');
}

export function getEntityDraft(entityType, entityId) {
    if (entityType === ENTITY_TYPES.PARTY_PRIVACY_PROFILE_SPECIFICATION) {
        return getPolicySpecificationDraft(entityId);
    }
    return new Error('wrong entity type');
}

export function setEntityDraft(entityData, entityType) {
    if (entityType === ENTITY_TYPES.PARTY_PRIVACY_PROFILE_SPECIFICATION) {
        return setPolicySpecificationDraft(entityData);
    }
    return new Error('wrong entity type');
}

export default {
    getEntities,
    addEntity,
    updateEntity,
    getEntityDraft,
    deleteEntityDraft,
    setEntityDraft,
};
