<template>
    <TableFiltersRenderless :entities="filteredSubscribers">
        <!-- eslint-disable vue/no-unused-vars -->
        <template
            #default="{
                // state
                allFilters,
                filteredEntities,
                // methods
                onFilterAdded,
                removeFilter,
                removeAllFilters,
                filteredEntitiesMixin,
            }"
        >
            <div>
                <div class="row mb-2 align-items-center table-header-row justify-content-between">
                    <div class="component-title-um">
                        {{ $i18n.t('customerCare.subscriberTab.subscribers') }}
                    </div>
                    <div class="display-flex">
                        <SearchBox
                            v-model="subscribersSearchQuery"
                            class="table-search-box"
                            :smallSearchIcon="true"
                        />
                        <ResponseModalButton
                            :response="subscriberInfosApiResponse"
                            :title="$i18n.t('customerCare.subscriberTab.subscribers')"
                        />
                        <div class="filters-control position-relative">
                            <FilterTable
                                :columns="columnData"
                                :multiselectWidth="{ width: '12rem' }"
                                @filterAdded="onFilterAdded"
                            >
                                <template #button="slotProps">
                                    <IconButton
                                        :label="$i18n.t('generic.filter')"
                                        :icon="ICON_TYPES.FILTER"
                                        @iconClick="slotProps.clickHandler"
                                    />
                                </template>
                            </FilterTable>
                        </div>
                    </div>
                </div>
                <div>
                    <TableFiltersTags
                        :filterTableMixin="{ allFilters }"
                        class="mt-1 mb-3"
                        @removeFilter="removeFilter"
                        @removeAllFilters="removeAllFilters"
                    />
                    <AppTable
                        :entities="filteredEntities"
                        :innerSearchQuery="subscribersSearchQuery"
                        :isDefaultHoverEnabled="false"
                        :isPaginationEnabled="true"
                        :isSearchEnabled="true"
                        :newDesign="true"
                        :columnsData="columnData"
                        :canSelectColumns="true"
                        tableKey="customer-care-new/account/subscribers"
                    >
                        <template #status="{ entity }">
                            <SubscriberStatusIndicator :status="entity.status" />
                        </template>

                        <template #subscriberIdLink="{ entity }">
                            <router-link
                                v-if="entity.subscriberId"
                                :to="subscriberIdRoute(entity.subscriberId)"
                                class="table-link"
                            >
                                {{ entity.subscriberId }}
                            </router-link>
                            <i v-else>
                                {{ $i18n.t('generic.empty') }}
                            </i>
                        </template>
                    </AppTable>
                </div>
            </div>
        </template>
    </TableFiltersRenderless>
</template>

<script>
// components
import AppTable from '@/components/partials/AppTable.vue';
import FilterTable from '@/components/partials/FilterTable.vue';
import FilterTableMixin from '@/components/partials/FilterTableMixin.vue';
import IconButton from '@/components/partials/IconButton.vue';
import ResponseModalButton from '@/components/partials/ResponseModalButton.vue';
import SearchBox from '@/components/partials/inputs/SearchBox.vue';
import SubscriberStatusIndicator from '@/__new__/features/customerCare/subscriber/SubscriberStatusIndicator.vue';
import TableFiltersRenderless from '@/components/filters/TableFiltersRenderless.vue';
import TableFiltersTags from '@/components/filters/TableFiltersTags.vue';
// helpers
import { ICON_TYPES } from '@/common/iconHelper';
import RouteNames from '@/router/routeNames';
import tableColumnType from '@/common/filterTable';
// vuex
import { mapGetters } from 'vuex';

export default {
    name: 'AccountSubscribers',
    components: {
        AppTable,
        FilterTable,
        IconButton,
        ResponseModalButton,
        SearchBox,
        SubscriberStatusIndicator,
        TableFiltersRenderless,
        TableFiltersTags,
    },
    mixins: [FilterTableMixin],
    props: {
        subscribers: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            subscribersSearchQuery: '',
            ICON_TYPES,
        };
    },
    computed: {
        ...mapGetters('userManagementSubscriber', ['getSubscriberInfoApiResponseById']),
        columnData() {
            return [
                {
                    name: this.$i18n.t('customerCare.msisdn'),
                    key: 'msisdn',
                    field: 'msisdn',
                    filterType: tableColumnType.NUMBER,
                },
                {
                    name: this.$i18n.t('customerCare.subscriberId'),
                    key: 'subscriberIdLink',
                    field: 'subscriberIdLink',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('customerCare.account.blocked'),
                    key: 'blocked',
                    field: 'blocked',
                    filterType: tableColumnType.TEXT_LIMITED_OPTIONS,
                    limitedOptions: [...new Set(this.subscribers.map(s => s.blocked))],
                },
                {
                    name: this.$i18n.t('generic.status'),
                    key: 'status',
                    field: 'status',
                    filterType: tableColumnType.TEXT_LIMITED_OPTIONS,
                    limitedOptions: [...new Set(this.subscribers.map(s => s.status))],
                },
            ];
        },
        filteredSubscribers() {
            return this.filteredEntitiesMixin(this.subscribers);
        },
        subscriberInfosApiResponse() {
            if (!this.subscribers?.length) {
                return {
                    [this.$i18n.t('generic.portalError')]: this.$i18n.t(
                        'customerCare.account.subscriberInfoApiUnavailable',
                    ),
                };
            }
            return this.subscribers.map(({ subscriberId }) => this.getSubscriberInfoApiResponseById(subscriberId));
        },
    },
    methods: {
        subscriberIdRoute(subscriberId) {
            return {
                name: RouteNames.USER_MANAGEMENT_SUBSCRIBER_V2,
                params: {
                    id: subscriberId,
                },
            };
        },
    },
};
</script>

<style lang="scss" scoped>
@import 'src/assets/scss/consistency-colors';

.table-link {
    color: $text-color;

    &:hover {
        color: $blue-400;
    }
}
</style>
