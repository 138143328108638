<template>
    <div
        :class="{ visible, collapsed, hovered }"
        class="aditional-sidebar"
    >
        <div
            class="content-c"
            @mouseover="setHoveredSidebar(true)"
            @mouseleave="setHoveredSidebar(false)"
        >
            <div
                v-if="visible && isCollapsible"
                class="sidebar-option"
            >
                <div
                    class="sidebar-icon"
                    @click="toggleSidebar(!collapsed)"
                />
            </div>

            <div
                class="d-flex justify-content-between"
                :class="{ sticky: useStickyHeader }"
            >
                <div class="content">
                    <slot name="header" />
                </div>

                <div
                    class="d-flex"
                    :class="{ 'pt-4': useStickyHeader }"
                >
                    <div class="content">
                        <slot name="actions" />
                    </div>

                    <IconButton
                        :label="$i18n.t('generic.close')"
                        :icon="ICON_TYPES.CLOSE"
                        class="close-btn"
                        @iconClick="hideModal"
                    />
                </div>
            </div>
            <div
                :class="{ 'sidebar-title-sticky': useStickyHeader }"
                class="content"
            >
                <slot name="contentTitle" />
            </div>

            <div class="content mainContent">
                <slot name="content" />
            </div>
        </div>
        <IconButton
            :label="$i18n.t('generic.close')"
            :icon="ICON_TYPES.CLOSE"
            class="second-close-btn"
            @iconClick="hideModal"
        />
    </div>
</template>

<script>
import IconButton from '@/components/partials/IconButton.vue';
import { ICON_TYPES } from '@/common/iconHelper';

export default {
    name: 'AppAditionalSidebar',
    components: {
        IconButton,
    },
    model: {
        prop: 'visible',
    },
    props: {
        visible: {
            type: Boolean,
            required: true,
        },
        useStickyHeader: {
            type: Boolean,
            default: false,
        },
        isCollapsible: {
            type: Boolean,
            default: false,
        },
        rebuildLayout: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            hovered: false,
            collapsed: false,
            ICON_TYPES,
        };
    },
    watch: {
        visible: {
            immediate: true,
            handler(val) {
                if (this.rebuildLayout) {
                    this.$emit('rebuildLayout', val);
                }
            },
        },
    },
    methods: {
        hideModal() {
            this.collapsed = false;
            this.$emit('input', false);
            if (this.rebuildLayout) {
                this.$emit('rebuildLayout', false);
                this.$emit('rebuildLayoutToggle', false);
            }
        },
        setHoveredSidebar(state) {
            this.hovered = state;
        },
        toggleSidebar(state) {
            this.collapsed = state;
            if (this.rebuildLayout) {
                this.$emit('rebuildLayout', !state);
                this.$emit('rebuildLayoutToggle', state);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/palette';
@import '~@/assets/scss/animations';
@import '~@/assets/scss/outlines';
@import '~@/assets/scss/icons';
@import '~@/assets/scss/z-indexes';

$icon-path: '~@/assets/icons/';
$sidebar-width: 40rem;
$sidebar-height: calc(100vh - 4.25rem);

.aditional-sidebar {
    width: $sidebar-width;
    height: $sidebar-height;
    background: $white;
    position: fixed;
    top: 4.25rem;
    right: -$sidebar-width;
    z-index: $additional-sidebar-z-index;
    overflow-y: scroll;
    box-shadow: $box-shadow-default;
    transition: $medium-animation-time;
    overflow: unset;

    .content-c {
        padding: 1.375rem 1.5rem;
        height: calc(100vh - 8rem);
    }

    .content {
        opacity: 0;
        transition: $slow-animation-time;
    }

    .mainContent {
        height: 80vh;
        overflow: hidden;
        overflow-y: auto;
        scrollbar-width: none;
    }

    .close-btn {
        opacity: 1;
    }

    .second-close-btn {
        opacity: 0;
        position: absolute;
        bottom: 1.5rem;
        left: 0.5rem;
    }

    .close-btn,
    .second-close-btn {
        transition: $medium-animation-time;
    }

    &.visible {
        right: 0;

        .content {
            opacity: 1;
        }

        .sidebar-icon {
            &::before {
                transform: translate(calc(-50% + 2px), -50%);
            }
        }
    }

    &.collapsed {
        right: 0;
        min-width: 64px;
        width: 64px;

        .content {
            opacity: 0;
        }

        .sidebar-icon {
            &::before {
                transform: translate(-50%, -50%) rotate(180deg);
            }
        }

        .close-btn {
            opacity: 0;
        }

        .second-close-btn {
            opacity: 1;
        }

        &.hovered {
            .close-btn {
                opacity: 1;
            }

            .second-close-btn {
                opacity: 0;
            }
        }
    }

    &.hovered {
        width: $sidebar-width;
        height: $sidebar-height;

        .content {
            opacity: 1;
        }
    }

    .sidebar-icon {
        height: 24px;
        width: 24px;
        border-radius: 50%;
        background: $white;
        box-shadow: $box-shadow-default;
        position: absolute;
        top: 50px;
        left: 0;
        transform: translateX(-50%);
        z-index: 1;
        transition: $fast-animation-time;
        cursor: pointer;

        &:before {
            content: url($icon-path + $arrow-right);
            height: 20px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(calc(-50% + 2px), -50%);
        }
    }

    .sidebar-option {
        transform: none;
        width: 310px;
    }
}

.close-btn {
    cursor: pointer;
}

.sticky {
    position: sticky;
    top: -1.4rem;
    z-index: $overlap-smth-z-index;
    background: white;
    margin-top: -2rem;
}

.sidebar-title-sticky {
    position: sticky;
    top: 2.9rem;
    z-index: $overlap-smth-z-index;
    background: white;
    border-bottom: $border-main;
    margin-bottom: 1rem;
}
</style>
