import i18n from '@/i18n';
import { TARGET_TYPE } from '@/__new__/services/dno/user/models/targetTuple';
import { isEmpty } from 'lodash';

export enum APPLICATION_STATUS {
    PENDING_REGISTRATION = 1,
    REGISTRATION_FAILED = 2,
    ACTIVE = 3,
    PENDING_DELETION = 4,
    DELETION_FAILED = 5,
    UPDATING = 6,
    UPDATE_FAILED = 7,
}
export interface ApiSubscriptionBe {
    offer_id: string;
    product_id: string;
    purpose: string;
    order_creation_timestamp: number;
    naas_service_id: string;
}

export type ApplicationDataBe = {
    id: string;
    naas_env_id: string;
    naas_org_id: string;
    naas_parent_org_id: string;
    naas_partner_id: string;
    naas_caller_partner_id: string;
    naas_app_client_id: string;
    app_use_type: string;
    name: string;
    description: string;
    public_key: string;
    api_subscriptions: ApiSubscriptionBe[];
    api_redirect_uris: string[];
    pending_name: string;
    pending_description: string;
    pending_public_key: string;
    pending_api_subscriptions: ApiSubscriptionBe[];
    pending_api_redirect_uris: string[];
    status: APPLICATION_STATUS;
    update_timestamp: number;
    target_id: string;
    target_type: number;
};

export interface ApiSubscriptionFe {
    offerId: string;
    productId: string;
    purpose: string;
    orderCreationTimestamp: number;
    naasServiceId: string;
}

export default class ApplicationData {
    id: string;
    naasEnvId: string;
    naasOrgId: string;
    naasParentOrgId: string;
    naasPartnerId: string;
    naasCallerPartnerId: string;
    naasAppClientId: string;
    appUseType: string;
    name: string;
    description: string;
    publicKey: string;
    apiSubscriptions: ApiSubscriptionFe[];
    apiRedirectUris: string[];
    pendingName: string;
    pendingDescription: string;
    pendingPublicKey: string;
    pendingApiSubscriptions: ApiSubscriptionFe[];
    pendingApiRedirectUris: string[];
    status: APPLICATION_STATUS;
    statusStr: string;
    updateTimestamp: number;
    targetId: string;
    targetType: TARGET_TYPE | null;

    constructor(data: ApplicationData | Record<string, never> = {}) {
        this.id = data?.id || '';
        this.naasEnvId = data?.naasEnvId || '';
        this.naasOrgId = data?.naasOrgId || '';
        this.naasParentOrgId = data?.naasParentOrgId || '';
        this.naasPartnerId = data?.naasPartnerId || '';
        this.naasCallerPartnerId = data?.naasCallerPartnerId || '';
        this.naasAppClientId = data?.naasAppClientId || '';
        this.appUseType = data?.appUseType || '';
        this.name = data?.name || '';
        this.description = data?.description || '';
        this.publicKey = data?.publicKey || '';
        this.apiSubscriptions = data?.apiSubscriptions || [];
        this.apiRedirectUris = data?.apiRedirectUris || [];
        this.pendingName = data?.pendingName || '';
        this.pendingDescription = data?.pendingDescription || '';
        this.pendingPublicKey = data?.pendingPublicKey || '';
        this.pendingApiSubscriptions = data?.pendingApiSubscriptions || [];
        this.pendingApiRedirectUris = data?.pendingApiRedirectUris || [];
        this.status = data?.status || 0;
        this.statusStr = applicationStatusToString(data?.status);
        this.updateTimestamp = data?.updateTimestamp || 0;
        this.targetId = data?.targetId || '';
        this.targetType = data?.targetType || null;
    }
    static mapResourceFromBE(app: ApplicationDataBe) {
        return {
            id: app.id,
            naasEnvId: app.naas_env_id,
            naasOrgId: app.naas_org_id,
            naasParentOrgId: app.naas_parent_org_id,
            naasPartnerId: app.naas_partner_id,
            naasCallerPartnerId: app.naas_caller_partner_id,
            naasAppClientId: app.naas_app_client_id,
            appUseType: app.app_use_type,
            name: app.name,
            description: app.description,
            publicKey: app.public_key,
            apiSubscriptions: ApplicationData.mapApiSubscriptionFromBE(app.api_subscriptions),
            apiRedirectUris: app.api_redirect_uris,
            pendingName: app.pending_name,
            pendingDescription: app.pending_description,
            pendingPublicKey: app.pending_public_key,
            pendingApiSubscriptions: ApplicationData.mapApiSubscriptionFromBE(app.pending_api_subscriptions),
            pendingApiRedirectUris: app.pending_api_redirect_uris,
            status: app.status,
            updateTimestamp: app.update_timestamp,
            targetId: app.target_id,
            targetType: app.target_type,
        };
    }
    static mapApiSubscriptionFromBE(apiSubscriptions: ApiSubscriptionBe[]): ApiSubscriptionFe[] {
        if (isEmpty(apiSubscriptions)) {
            return [];
        }
        return apiSubscriptions.map(el => ({
            offerId: el.offer_id ?? '',
            productId: el.product_id ?? '',
            purpose: el.purpose ?? '',
            orderCreationTimestamp: el.order_creation_timestamp ?? 0,
            naasServiceId: el.naas_service_id ?? '',
        }));
    }
}

const applicationStatusToString = (status: APPLICATION_STATUS): string => {
    switch (status) {
        case APPLICATION_STATUS.PENDING_REGISTRATION:
            return i18n.t('generic.stateMap.pendingRegistration').toString();
        case APPLICATION_STATUS.REGISTRATION_FAILED:
            return i18n.t('generic.stateMap.registrationFailed').toString();
        case APPLICATION_STATUS.ACTIVE:
            return i18n.t('generic.active').toString();
        case APPLICATION_STATUS.PENDING_DELETION:
            return i18n.t('generic.stateMap.pendingDeletion').toString();
        case APPLICATION_STATUS.DELETION_FAILED:
            return i18n.t('generic.stateMap.deletionFailed').toString();
        case APPLICATION_STATUS.UPDATING:
            return i18n.t('generic.stateMap.updating').toString();
        case APPLICATION_STATUS.UPDATE_FAILED:
            return i18n.t('generic.stateMap.updatingFailed').toString();
        default:
            return '';
    }
};
