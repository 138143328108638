<template>
    <div>
        <div class="text-blue mb-3">
            {{ text }}
        </div>

        <div class="underline-blue">
            <div
                class="pointer"
                :style="{ background: bgLayout }"
            />
        </div>
    </div>
</template>

<script>
export default {
    name: 'AppTextUnderline',
    props: {
        text: {
            type: String,
            required: true,
        },
        bgLayout: {
            type: String,
            default: 'white',
        },
    },
};
</script>

<style lang="scss" scoped>
@import '../../assets/scss/palette';
@import '../../assets/scss/icons';

$icon-path: '../../assets/icons/';

.text-blue {
    font-size: 0.625rem;
    font-weight: bold;
    text-transform: uppercase;
    line-height: 2;
    color: $steel;
}

.underline-blue {
    border-bottom: solid 0.0625rem rgba(51, 81, 149, 0.15);
    position: relative;
}

.pointer {
    background: $white;
    position: absolute;
    border-top: solid 0.0625rem rgba(51, 81, 149, 0.15);
    border-left: solid 0.0625rem rgba(51, 81, 149, 0.15);
    border-radius: 0.125rem 0 100% 0;
    height: 0.625rem;
    width: 0.625rem;
    transform: translate(-50%, -50%) rotate(45deg);
    left: 1.875rem;
}
</style>
