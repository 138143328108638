import { AxiosResponse } from 'axios';
import {
    CycleSpecificationsResponse,
    createCycleSpecification as createDnoEntity,
    getCycleSpecifications as getDnoEntities,
    getCycleSpecificationById as getDnoEntityById,
    updateCycleSpecification as updateDnoEntity,
    updateCycleSpecificationState as updateDnoEntityState,
} from '@/__new__/services/dno/billing/http/cycleSpecifications';

import { CycleSpecificationEntity } from '@/__new__/services/dno/billing/models/cycleSpecificationPortal';

import {
    CycleSpecification,
    CYCLE_TYPE,
    FixedBimonthlySpecification,
    FixedCycleSpecification,
    FixedMonthlySpecification,
    FixedWeeklySpecification,
    FIXED_CYCLE_PERIODS,
    ServiceActivationSpecification,
} from '@/__new__/services/dno/billing/models/cycleSpecificationDno';
import { Entity, EntityState } from '@/http';

export type CycleSpecificationData = {
    response: AxiosResponse<CycleSpecificationsResponse>;
    entities: CycleSpecificationEntity[];
};

export const getCycleSpecifications = async (languageKey = 'en'): Promise<CycleSpecificationData> => {
    const response = await getDnoEntities();

    const entitiesDno = Object.values(response.data.cycle_specification_by_id ?? {});
    const entities = entitiesDno.map(entityDno => convertEntityDnoToPortal(entityDno, languageKey));

    return { response, entities };
};

export const getCycleSpecificationById = async (id: string, languageKey = 'en'): Promise<CycleSpecificationEntity> => {
    const response = await getDnoEntityById(id);

    // Build Portal consumable entities
    const entitiesDno = Object.values(response.data.cycle_specification_by_id ?? {});
    const entities = entitiesDno.map(entityDno => convertEntityDnoToPortal(entityDno, languageKey));

    // Throw error if we're unable to locate desired entity in payload
    if (entities.length && entities[0].id === id) {
        return entities[0];
    } else {
        throw new Error(`Error fetching cycle specification (${id}) from DNO`);
    }
};

const convertEntityDnoToPortal = (
    entityDno: Entity<CycleSpecification>,
    languageKey = 'en',
): CycleSpecificationEntity => {
    return {
        id: entityDno.id,
        name: entityDno.data.name[languageKey] ?? '',
        description: entityDno.data.description[languageKey] ?? '',
        version: entityDno.version,
        state: entityDno.state,
        nameByLanguage: entityDno.data.name,
        descriptionByLanguage: entityDno.data.description,
        type: entityDno.data.specification.type,
        fixedCyclePeriod:
            entityDno.data.specification.type === CYCLE_TYPE.FIXED_DATE
                ? entityDno.data.specification.fixed_cycle_period
                : undefined,
        dayOfWeek:
            entityDno.data.specification.type === CYCLE_TYPE.FIXED_DATE &&
            entityDno.data.specification.fixed_cycle_period === FIXED_CYCLE_PERIODS.WEEK
                ? entityDno.data.specification.day_of_week
                : undefined,
        dayOfMonth:
            entityDno.data.specification.type === CYCLE_TYPE.FIXED_DATE &&
            entityDno.data.specification.fixed_cycle_period === FIXED_CYCLE_PERIODS.MONTHLY
                ? String(entityDno.data.specification.day_of_month)
                : undefined,
        serviceActivationPeriod:
            entityDno.data.specification.type === CYCLE_TYPE.SERVICE_ACTIVATTION_DATE
                ? entityDno.data.specification.service_activation_period
                : undefined,
        serviceActivationPeriodMultiplier:
            entityDno.data.specification.type === CYCLE_TYPE.SERVICE_ACTIVATTION_DATE
                ? String(entityDno.data.specification.service_activation_period_multiplier)
                : undefined,
    };
};

const convertEntityPortalToDno = (entityPortal: CycleSpecificationEntity, languageKey = 'en'): CycleSpecification => {
    return {
        name: {
            ...entityPortal.nameByLanguage,
            [languageKey]: entityPortal.name,
        },
        description: {
            ...entityPortal.descriptionByLanguage,
            [languageKey]: entityPortal.description,
        },
        specification: buildSpecification(entityPortal),
    };
};

const buildSpecification = (
    entityPortal: CycleSpecificationEntity,
): FixedCycleSpecification | ServiceActivationSpecification => {
    switch (entityPortal.type) {
        case CYCLE_TYPE.FIXED_DATE:
            return buildFixedSpecification(entityPortal);
        case CYCLE_TYPE.SERVICE_ACTIVATTION_DATE:
            return buildServiceActivationSpecification(entityPortal);
        default:
            throw new Error(`unrecognized cycle type ${entityPortal.type}`);
    }
};

const buildFixedSpecification = (entityPortal: CycleSpecificationEntity): FixedCycleSpecification => {
    switch (entityPortal.fixedCyclePeriod) {
        case FIXED_CYCLE_PERIODS.WEEK:
            return buildFixedWeeklySpecification(entityPortal);
        case FIXED_CYCLE_PERIODS.BIMONTHLY:
            return buildFixedBimonthlySpecification();
        case FIXED_CYCLE_PERIODS.MONTHLY:
            return buildFixedMonthlySpecification(entityPortal);
        default:
            throw new Error(`unrecognized fixedCyclePeriod ${entityPortal.fixedCyclePeriod}`);
    }
};

const buildFixedWeeklySpecification = (entityPortal: CycleSpecificationEntity): FixedWeeklySpecification => {
    if (entityPortal.dayOfWeek === undefined) {
        throw new Error('day of week undefined');
    }
    return {
        type: CYCLE_TYPE.FIXED_DATE,
        fixed_cycle_period: FIXED_CYCLE_PERIODS.WEEK,
        day_of_week: entityPortal.dayOfWeek,
    };
};
const buildFixedBimonthlySpecification = (): FixedBimonthlySpecification => {
    return {
        type: CYCLE_TYPE.FIXED_DATE,
        fixed_cycle_period: FIXED_CYCLE_PERIODS.BIMONTHLY,
    };
};
const buildFixedMonthlySpecification = (entityPortal: CycleSpecificationEntity): FixedMonthlySpecification => {
    if (entityPortal.dayOfMonth === undefined) {
        throw new Error('day of month undefined');
    }
    return {
        type: CYCLE_TYPE.FIXED_DATE,
        fixed_cycle_period: FIXED_CYCLE_PERIODS.MONTHLY,
        day_of_month: Number(entityPortal.dayOfMonth),
    };
};

const buildServiceActivationSpecification = (
    entityPortal: CycleSpecificationEntity,
): ServiceActivationSpecification => {
    if (entityPortal.serviceActivationPeriod === undefined) {
        throw new Error(`serviceActivationPeriod is undefined`);
    }
    if (entityPortal.serviceActivationPeriodMultiplier === undefined) {
        throw new Error(`serviceActivationPeriodMultiplier is undefined`);
    }
    return {
        type: CYCLE_TYPE.SERVICE_ACTIVATTION_DATE,
        service_activation_period: entityPortal.serviceActivationPeriod,
        service_activation_period_multiplier: Number(entityPortal.serviceActivationPeriodMultiplier),
    };
};

export const deleteCycleSpecification = async (entity: CycleSpecificationEntity): Promise<void> => {
    await updateDnoEntityState(entity.id, entity.version, EntityState.DELETED);
};

export const createCycleSpecification = async (entity: CycleSpecificationEntity, languageKey = 'en'): Promise<void> => {
    const entityDno = convertEntityPortalToDno(entity, languageKey);
    await createDnoEntity(entityDno);
};

export const updateCycleSpecification = async (entity: CycleSpecificationEntity, languageKey = 'en'): Promise<void> => {
    await updateDnoEntity(entity.id, entity.version, convertEntityPortalToDno(entity, languageKey));
};
