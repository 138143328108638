<template>
    <AppSlider v-if="tabs.length">
        <div class="multiple-tabs-wrapper">
            <div class="d-flex">
                <div
                    v-for="(tab, index) in tabs"
                    :key="isComplexTab ? tab.id : tab"
                    :class="[
                        checkIfTabIsActive(tab, index) ? 'active-tab' : 'clickable',
                        errorIndexes.includes(index) ? 'error' : '',
                    ]"
                    class="tab tab-margin"
                    @click="$emit('input', tab.id || index)"
                >
                    {{ tab.title || tab }}
                </div>
            </div>
            <div class="underline" />
        </div>
    </AppSlider>
</template>

<script>
import AppSlider from '@/components/partials/AppSlider.vue';

export default {
    name: 'MultipleTablesTabs',
    components: {
        AppSlider,
    },
    props: {
        value: {
            type: [String, Number],
            required: true,
        },
        // Array<String> OR Array<Object> in case isComplexTab is true
        tabs: {
            type: Array,
            required: true,
        },
        // index of tab with error
        errorIndexes: {
            type: Array,
            required: false,
            default: () => [],
        },
        isComplexTab: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        checkIfTabIsActive(tab, index) {
            return this.isComplexTab ? this.value === tab.id : this.value === index;
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/palette';
@import '~@/assets/scss/animations';

.multiple-tabs-wrapper {
    width: max-content;
    min-width: 100%;
}

.underline {
    height: 0;
    border-bottom: solid 1px rgba(51, 81, 149, 0.16);
    position: relative;
    top: -0.1rem;
}

.clickable {
    cursor: pointer;
    font-size: 1rem;
    opacity: 0.5;
    color: $blue;
    font-weight: 600;
}

.tab-margin {
    margin-right: 2.5rem;
}

.active-tab {
    font-size: 1rem;
    color: $blue;
    font-weight: 600;
    border-bottom: solid 0.125rem $blue;
    transition: opacity $fast-animation-time ease-in;
    &.error {
        color: $red;
        border-bottom-color: $red;
    }
}
.error {
    color: $red;
    border-bottom-color: $red;
}
</style>
