import { uniqueId, get, cloneDeep, isEmpty } from 'lodash';
import i18n from '@/i18n';

export const OPERATION_TYPE = {
    LOGICAL: 'LOGICAL',
    COMPARISON: 'COMPARISON',
    CUSTOM: 'CUSTOM',
};

export const CUSTOM_TYPE = {
    SCHEMA: 'SCHEMA',
    FIELD: 'FIELD',
};

export const SCHEMA_TYPE = {
    SOURCE: 'SOURCE',
    EVENT: 'EVENT',
};

export const OPERATION = {
    AND: 'AND',
    OR: 'OR',
    NOT: 'NOT',
    // ------------------
    EQ: 'EQ',
    NOT_EQ: 'NOT_EQ',
    LT: 'LT',
    LT_OR_EQ: 'LT_OR_EQ',
    GT: 'GT',
    GT_OR_EQ: 'GT_OR_EQ',
    // many-to-many
    MATCHES: 'MATCHES',
    ARE_DISJOINT: 'ARE_DISJOINT',
    INTERSECTS: 'INTERSECTS',
    IS_SUBSET_OF: 'IS_SUBSET_OF',
    IS_SUPERSET_OF: 'IS_SUPERSET_OF',
    // ------------------
    EXISTS: 'EXISTS',
    NOT_EXISTS: 'NOT_EXISTS',
};

export const ARGUMENT_VALUE_TYPES = {
    TEXT: 'text',
    NUMBER: 'number',
};

export const createTreeNode = ({
    id = null,
    parentId = null,
    operation = null,
    args = null,
    nested = null,
    config = null,
}) => ({
    id: id || uniqueId('TMP_'),
    parentId,
    operation,
    args,
    nested,
    config: config || {},
});

export const COLOR_SCHEME = [
    '#ffffff',
    '#f2f2f2',
    '#e6e6e6',
    '#d9d9d9',
    '#cccccc',
    '#bfbfbf',
    '#b3b3b3',
    '#a6a6a6',
    '#999999',
    '#8c8c8c',
    '#808080',
];

export const DATA_TYPES = {
    NATIVE: 0,
    VOUCHER_SET: 1,
    OFFER: 2,
    PRODUCT: 3,
};

export const DATA_TYPE_OPTIONS = [
    {
        id: DATA_TYPES.NATIVE,
        i18nLabel: 'conditionsTree.dataType.native',
    },
    {
        id: DATA_TYPES.VOUCHER_SET,
        i18nLabel: 'conditionsTree.dataType.voucherSet',
    },
    {
        id: DATA_TYPES.OFFER,
        i18nLabel: 'conditionsTree.dataType.offer',
    },
    {
        id: DATA_TYPES.PRODUCT,
        i18nLabel: 'conditionsTree.dataType.product',
    },
];

export const OPERATOR_TYPE_TO_I18N_KEY = {
    [OPERATION_TYPE.LOGICAL]: 'conditionsTree.operationType.logical',
    [OPERATION_TYPE.COMPARISON]: 'conditionsTree.operationType.comparison',
    [OPERATION_TYPE.CUSTOM]: 'conditionsTree.operationType.custom',
    [CUSTOM_TYPE.FIELD]: 'conditionsTree.operationType.field',
    [SCHEMA_TYPE.SOURCE]: 'conditionsTree.operationType.source',
    [SCHEMA_TYPE.EVENT]: 'conditionsTree.operationType.event',
};

export const ARGUMENT_COMPONENTS = {
    INPUT: 'INPUT',
    INPUT_TAGS: 'INPUT_TAGS',
    SELECT: 'SELECT',
    SELECT_DATA_TYPE: 'SELECT_DATA_TYPE',
};

export const OPERATION_TYPE_TO_OPERATION_MAP = {
    [OPERATION.AND]: {
        i18nNameKey: 'conditionsTree.operation.and',
        type: OPERATION_TYPE.LOGICAL,
        op: OPERATION.AND,
        args: null,
        config: {
            minNestedNum: 2,
            isReplaceableWith: {
                [OPERATION.OR]: true,
            },
        },
    },
    [OPERATION.OR]: {
        i18nNameKey: 'conditionsTree.operation.or',
        type: OPERATION_TYPE.LOGICAL,
        op: OPERATION.OR,
        args: null,
        config: {
            minNestedNum: 2,
            isReplaceableWith: {
                [OPERATION.AND]: true,
            },
        },
    },
    [OPERATION.NOT]: {
        i18nNameKey: 'conditionsTree.operation.not',
        type: OPERATION_TYPE.LOGICAL,
        op: OPERATION.NOT,
        args: null,
        config: {
            minNestedNum: 1,
        },
    },
    [OPERATION.EQ]: {
        i18nNameKey: 'conditionsTree.operation.equal',
        type: OPERATION_TYPE.COMPARISON,
        op: OPERATION.EQ,
        args: {
            dataType: null,
            key: null,
            value: null,
        },
        config: {
            isReplaceableWith: {
                [OPERATION.NOT_EQ]: true,
            },
        },
    },
    [OPERATION.NOT_EQ]: {
        i18nNameKey: 'conditionsTree.operation.notEqual',
        type: OPERATION_TYPE.COMPARISON,
        op: OPERATION.NOT_EQ,
        args: {
            dataType: null,
            key: null,
            value: null,
        },
        config: {
            isReplaceableWith: {
                [OPERATION.EQ]: true,
            },
        },
    },
    [OPERATION.LT]: {
        i18nNameKey: 'conditionsTree.operation.lessThan',
        type: OPERATION_TYPE.COMPARISON,
        op: OPERATION.LT,
        args: {
            key: null,
            value: null,
        },
        config: {
            args: {
                value: {
                    type: ARGUMENT_VALUE_TYPES.NUMBER,
                },
            },
            isReplaceableWith: {
                [OPERATION.LT_OR_EQ]: true,
                [OPERATION.GT]: true,
                [OPERATION.GT_OR_EQ]: true,
            },
        },
    },
    [OPERATION.LT_OR_EQ]: {
        i18nNameKey: 'conditionsTree.operation.lessOrEqual',
        type: OPERATION_TYPE.COMPARISON,
        op: OPERATION.LT_OR_EQ,
        args: {
            key: null,
            value: null,
        },
        config: {
            args: {
                value: {
                    type: ARGUMENT_VALUE_TYPES.NUMBER,
                },
            },
            isReplaceableWith: {
                [OPERATION.LT]: true,
                [OPERATION.GT]: true,
                [OPERATION.GT_OR_EQ]: true,
            },
        },
    },
    [OPERATION.GT]: {
        i18nNameKey: 'conditionsTree.operation.greaterThan',
        type: OPERATION_TYPE.COMPARISON,
        op: OPERATION.GT,
        args: {
            key: null,
            value: null,
        },
        config: {
            args: {
                value: {
                    type: ARGUMENT_VALUE_TYPES.NUMBER,
                },
            },
            isReplaceableWith: {
                [OPERATION.LT]: true,
                [OPERATION.LT_OR_EQ]: true,
                [OPERATION.GT_OR_EQ]: true,
            },
        },
    },
    [OPERATION.GT_OR_EQ]: {
        i18nNameKey: 'conditionsTree.operation.greaterOrEqual',
        type: OPERATION_TYPE.COMPARISON,
        op: OPERATION.GT_OR_EQ,
        args: {
            key: null,
            value: null,
        },
        config: {
            args: {
                value: {
                    type: ARGUMENT_VALUE_TYPES.NUMBER,
                },
            },
            isReplaceableWith: {
                [OPERATION.LT]: true,
                [OPERATION.LT_OR_EQ]: true,
                [OPERATION.GT]: true,
            },
        },
    },
    [OPERATION.MATCHES]: {
        i18nNameKey: 'conditionsTree.operation.matches',
        type: OPERATION_TYPE.COMPARISON,
        op: OPERATION.MATCHES,
        args: {
            dataType: null,
            key: null,
            value: [],
        },
        config: {
            args: {
                value: {
                    multiple: true,
                },
            },
            isReplaceableWith: {
                [OPERATION.ARE_DISJOINT]: true,
                [OPERATION.INTERSECTS]: true,
                [OPERATION.IS_SUBSET_OF]: true,
                [OPERATION.IS_SUPERSET_OF]: true,
            },
        },
    },
    [OPERATION.ARE_DISJOINT]: {
        i18nNameKey: 'conditionsTree.operation.areDisjoint',
        type: OPERATION_TYPE.COMPARISON,
        op: OPERATION.ARE_DISJOINT,
        args: {
            dataType: null,
            key: null,
            value: [],
        },
        config: {
            args: {
                value: {
                    multiple: true,
                },
            },
            isReplaceableWith: {
                [OPERATION.MATCHES]: true,
                [OPERATION.INTERSECTS]: true,
                [OPERATION.IS_SUBSET_OF]: true,
                [OPERATION.IS_SUPERSET_OF]: true,
            },
        },
    },
    [OPERATION.INTERSECTS]: {
        i18nNameKey: 'conditionsTree.operation.intersects',
        type: OPERATION_TYPE.COMPARISON,
        op: OPERATION.INTERSECTS,
        args: {
            dataType: null,
            key: null,
            value: [],
        },
        config: {
            args: {
                value: {
                    multiple: true,
                },
            },
            isReplaceableWith: {
                [OPERATION.MATCHES]: true,
                [OPERATION.ARE_DISJOINT]: true,
                [OPERATION.IS_SUBSET_OF]: true,
                [OPERATION.IS_SUPERSET_OF]: true,
            },
        },
    },
    [OPERATION.IS_SUBSET_OF]: {
        i18nNameKey: 'conditionsTree.operation.isSubset',
        type: OPERATION_TYPE.COMPARISON,
        op: OPERATION.IS_SUBSET_OF,
        args: {
            dataType: null,
            key: null,
            value: [],
        },
        config: {
            args: {
                value: {
                    multiple: true,
                },
            },
            isReplaceableWith: {
                [OPERATION.MATCHES]: true,
                [OPERATION.ARE_DISJOINT]: true,
                [OPERATION.INTERSECTS]: true,
                [OPERATION.IS_SUPERSET_OF]: true,
            },
        },
    },
    [OPERATION.IS_SUPERSET_OF]: {
        i18nNameKey: 'conditionsTree.operation.isSuperset',
        type: OPERATION_TYPE.COMPARISON,
        op: OPERATION.IS_SUPERSET_OF,
        args: {
            dataType: null,
            key: null,
            value: [],
        },
        config: {
            args: {
                value: {
                    multiple: true,
                },
            },
            isReplaceableWith: {
                [OPERATION.MATCHES]: true,
                [OPERATION.ARE_DISJOINT]: true,
                [OPERATION.INTERSECTS]: true,
                [OPERATION.IS_SUBSET_OF]: true,
            },
        },
    },
    [OPERATION.EXISTS]: {
        i18nNameKey: 'conditionsTree.operation.exists',
        type: OPERATION_TYPE.COMPARISON,
        op: OPERATION.EXISTS,
        args: {
            key: null,
        },
        config: {
            isReplaceableWith: {
                [OPERATION.NOT_EXISTS]: true,
            },
        },
    },
    [OPERATION.NOT_EXISTS]: {
        i18nNameKey: 'conditionsTree.operation.not_exists',
        type: OPERATION_TYPE.COMPARISON,
        op: OPERATION.NOT_EXISTS,
        args: {
            key: null,
        },
        config: {
            isReplaceableWith: {
                [OPERATION.EXISTS]: true,
            },
        },
    },
    [CUSTOM_TYPE.SCHEMA]: {
        //i18nNameKey need to fill in for every event
        type: CUSTOM_TYPE.SCHEMA,
        op: CUSTOM_TYPE.SCHEMA,
        args: null,
        config: {
            minNestedNum: 1,
        },
    },
    [CUSTOM_TYPE.FIELD]: {
        //i18nNameKey need to fill in for every field
        type: CUSTOM_TYPE.FIELD,
        op: CUSTOM_TYPE.FIELD,
        args: null,
        config: {
            minNestedNum: 1,
        },
    },
};

export const DEFAULT_OPERATION_GROUPS = [
    {
        i18nGroupKey: 'conditionsTree.operationType.logical',
        operations: [
            {
                id: 1,
                i18nNameKey: 'conditionsTree.operation.and',
                type: OPERATION_TYPE.LOGICAL,
                op: OPERATION.AND,
                args: null,
                config: {
                    minNestedNum: 2,
                    isReplaceableWith: {
                        [OPERATION.OR]: true,
                    },
                },
            },
            {
                id: 2,
                i18nNameKey: 'conditionsTree.operation.or',
                type: OPERATION_TYPE.LOGICAL,
                op: OPERATION.OR,
                args: null,
                config: {
                    minNestedNum: 2,
                    isReplaceableWith: {
                        [OPERATION.AND]: true,
                    },
                },
            },
            {
                id: 3,
                i18nNameKey: 'conditionsTree.operation.not',
                type: OPERATION_TYPE.LOGICAL,
                op: OPERATION.NOT,
                args: null,
                config: {
                    minNestedNum: 1,
                },
            },
        ],
    },
    {
        i18nGroupKey: 'conditionsTree.operationType.comparison',
        operations: [
            {
                id: 4,
                i18nNameKey: 'conditionsTree.operation.equal',
                type: OPERATION_TYPE.COMPARISON,
                op: OPERATION.EQ,
                args: {
                    dataType: null,
                    key: null,
                    value: null,
                },
                config: {},
            },
            {
                id: 5,
                i18nNameKey: 'conditionsTree.operation.notEqual',
                type: OPERATION_TYPE.COMPARISON,
                op: OPERATION.NOT_EQ,
                args: {
                    dataType: null,
                    key: null,
                    value: null,
                },
                config: {},
            },
            {
                id: 6,
                i18nNameKey: 'conditionsTree.operation.lessThan',
                type: OPERATION_TYPE.COMPARISON,
                op: OPERATION.LT,
                args: {
                    key: null,
                    value: null,
                },
                config: {
                    args: {
                        value: {
                            type: ARGUMENT_VALUE_TYPES.NUMBER,
                        },
                    },
                },
            },
            {
                id: 7,
                i18nNameKey: 'conditionsTree.operation.lessOrEqual',
                type: OPERATION_TYPE.COMPARISON,
                op: OPERATION.LT_OR_EQ,
                args: {
                    key: null,
                    value: null,
                },
                config: {
                    args: {
                        value: {
                            type: ARGUMENT_VALUE_TYPES.NUMBER,
                        },
                    },
                },
            },
            {
                id: 8,
                i18nNameKey: 'conditionsTree.operation.greaterThan',
                type: OPERATION_TYPE.COMPARISON,
                op: OPERATION.GT,
                args: {
                    key: null,
                    value: null,
                },
                config: {
                    args: {
                        value: {
                            type: ARGUMENT_VALUE_TYPES.NUMBER,
                        },
                    },
                },
            },
            {
                id: 9,
                i18nNameKey: 'conditionsTree.operation.greaterOrEqual',
                type: OPERATION_TYPE.COMPARISON,
                op: OPERATION.GT_OR_EQ,
                args: {
                    key: null,
                    value: null,
                },
                config: {
                    args: {
                        value: {
                            type: ARGUMENT_VALUE_TYPES.NUMBER,
                        },
                    },
                },
            },
            {
                id: 10,
                i18nNameKey: 'conditionsTree.operation.matches',
                type: OPERATION_TYPE.COMPARISON,
                op: OPERATION.MATCHES,
                args: {
                    dataType: null,
                    key: null,
                    value: [],
                },
                config: {
                    args: {
                        value: {
                            multiple: true,
                        },
                    },
                },
            },
            {
                id: 11,
                i18nNameKey: 'conditionsTree.operation.areDisjoint',
                type: OPERATION_TYPE.COMPARISON,
                op: OPERATION.ARE_DISJOINT,
                args: {
                    dataType: null,
                    key: null,
                    value: [],
                },
                config: {
                    args: {
                        value: {
                            multiple: true,
                        },
                    },
                },
            },
            {
                id: 12,
                i18nNameKey: 'conditionsTree.operation.intersects',
                type: OPERATION_TYPE.COMPARISON,
                op: OPERATION.INTERSECTS,
                args: {
                    dataType: null,
                    key: null,
                    value: [],
                },
                config: {
                    args: {
                        value: {
                            multiple: true,
                        },
                    },
                },
            },
            {
                id: 13,
                i18nNameKey: 'conditionsTree.operation.isSubset',
                type: OPERATION_TYPE.COMPARISON,
                op: OPERATION.IS_SUBSET_OF,
                args: {
                    dataType: null,
                    key: null,
                    value: [],
                },
                config: {
                    args: {
                        value: {
                            multiple: true,
                        },
                    },
                },
            },
            {
                id: 14,
                i18nNameKey: 'conditionsTree.operation.isSuperset',
                type: OPERATION_TYPE.COMPARISON,
                op: OPERATION.IS_SUPERSET_OF,
                args: {
                    dataType: null,
                    key: null,
                    value: [],
                },
                config: {
                    args: {
                        value: {
                            multiple: true,
                        },
                    },
                },
            },
        ],
    },
];

const COMMON_ARGS_KEYS = {
    DATA_TYPE: 'dataType',
    KEY: 'key',
};

const COMMON_ARGS_KEYS_TO_COMPONENT_MAP = {
    [COMMON_ARGS_KEYS.DATA_TYPE]: ARGUMENT_COMPONENTS.SELECT_DATA_TYPE,
    [COMMON_ARGS_KEYS.KEY]: ARGUMENT_COMPONENTS.INPUT,
};

const DATA_TYPE_TO_COMPONENT_MAP = {
    [DATA_TYPES.OFFER]: ARGUMENT_COMPONENTS.SELECT,
    [DATA_TYPES.PRODUCT]: ARGUMENT_COMPONENTS.SELECT,
    [DATA_TYPES.VOUCHER_SET]: ARGUMENT_COMPONENTS.SELECT,
};

export const getArgumentComponent = (argsKey, dataType, config) => {
    if (config?.[argsKey]?.component) {
        return config?.[argsKey]?.component;
    }
    if (COMMON_ARGS_KEYS_TO_COMPONENT_MAP[argsKey]) {
        return COMMON_ARGS_KEYS_TO_COMPONENT_MAP[argsKey];
    }
    if (DATA_TYPE_TO_COMPONENT_MAP[dataType]) {
        return DATA_TYPE_TO_COMPONENT_MAP[dataType];
    }
    if (config?.[argsKey]?.multiple) {
        return ARGUMENT_COMPONENTS.INPUT_TAGS;
    }
    return ARGUMENT_COMPONENTS.INPUT;
};

const createOperationFromConfig = (optionsConfig, path, id) => {
    if (!path || !id) {
        return null;
    }
    const config = get(optionsConfig, path);
    const opType = config.op_type;
    const operation = cloneDeep(OPERATION_TYPE_TO_OPERATION_MAP[opType]);
    operation.id = id; // for tracking only
    operation.config.path = path; // gets passed to node
    operation.config.schemaField = config.schema_field; // gets passed to node
    if (opType === CUSTOM_TYPE.SCHEMA || opType === CUSTOM_TYPE.FIELD) {
        const pathEnd = path.split('.').pop();
        operation.label = pathEnd;
    }
    operation.name = operation.i18nNameKey ? i18n.t(operation.i18nNameKey) : operation.label;
    return operation;
};

export const getOptionsFromConfig = (optionsConfig, optionsConfigPath, excludeLegacy) => {
    // map optionsConfig to "operations" (e.g. AND, OR, NOT, voucher_event, order_confirmation)
    const parentConfig = optionsConfigPath ? get(optionsConfig, optionsConfigPath) : optionsConfig;
    let paths = parentConfig.options || [];

    // add legacy paths
    const legacyPaths = parentConfig.legacy_options || [];
    if (!excludeLegacy) {
        paths = paths.concat(legacyPaths);
    }

    // each operation has an id #
    let idCounter = 0;
    const configOperationGroups = [];

    // each path leads to an option or a group of options
    const defaultOperationGroup = {
        i18nGroupKey: 'conditionsTree.operationType.default',
        operations: [],
    };
    configOperationGroups.push(defaultOperationGroup);

    paths.forEach(path => {
        const config = get(optionsConfig, path);
        if (config) {
            const groupType = config.group_type;
            const operationGroup = {
                i18nGroupKey: OPERATOR_TYPE_TO_I18N_KEY[groupType],
                operations: [],
            };
            configOperationGroups.push(operationGroup);

            if (groupType) {
                // leads to a group of options - expand out sub options
                const subPaths = config.options;
                if (!isEmpty(subPaths)) {
                    subPaths.forEach(subPath => {
                        idCounter += 1;
                        const operation = createOperationFromConfig(optionsConfig, subPath, idCounter);
                        operationGroup.operations.push(operation);
                    });
                }
            } else {
                // leads to only one option, add it to default operations
                idCounter += 1;
                const operation = createOperationFromConfig(optionsConfig, path, idCounter);
                defaultOperationGroup.operations.push(operation);
            }
        }
    });
    return configOperationGroups
        .filter(group => group.operations.length > 0)
        .map(group => ({
            ...group,
            group: i18n.t(group.i18nGroupKey),
            operations: group.operations.map(op => ({
                ...op,
                name: op.i18nNameKey ? i18n.t(op.i18nNameKey) : op.label,
            })),
        }));
};
