import { render, staticRenderFns } from "./EventPropertiesOverview.vue?vue&type=template&id=4043a312&scoped=true"
import script from "./EventPropertiesOverview.vue?vue&type=script&lang=ts"
export * from "./EventPropertiesOverview.vue?vue&type=script&lang=ts"
import style0 from "./EventPropertiesOverview.vue?vue&type=style&index=0&id=4043a312&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4043a312",
  null
  
)

export default component.exports