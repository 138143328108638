
import AbstractEditPageWrapper from '@/components/layout/AbstractEditPageWrapper.vue';
import AppHeader from '@/components/layout/AppHeader.vue';
import AppInputV3 from '@/components/partials/inputs/AppInputV3.vue';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import IconButton from '@/components/partials/IconButton.vue';

import { ICON_TYPES } from '@/common/iconHelper';
import {
    ApplicationDetailsResponse,
    getApplicationByAppClientId,
} from '@/__new__/services/dno/ossdevedge/http/ossdevedge';
import ApplicationData from '@/__new__/services/dno/ossdevedge/models/ApplicationData';
import { type AxiosResponse } from 'axios';
import luaErrorCodes, { modules } from '@/common/luaErrors';
import * as Sentry from '@sentry/vue';

import { getRouteNameFromTargetType } from '@/__new__/features/customerCare/common/customerCareHelper';
import { isEqual, uniq } from 'lodash';
import { TARGET_TYPE } from '@/__new__/services/dno/user/models/targetTuple';
import { getAccount } from '@/__new__/services/dno/user/userManagerService';
import Account from '@/__new__/services/dno/user/models/Account';
import { copyToClipboard } from '@/common/utils';
import RouteNames from '@/router/routeNames';
import { getOffers } from '@/__new__/services/dno/pc/http/offer';
import { isUserAllowed } from '@/services/permissions/permissions.service';
import { EDITOR_MODE } from '@/common/entities/entityHelper';
import ENTITY_TYPES from '@/common/entities/entityTypes';
import { getProducts } from '@/__new__/services/dno/pc/http/product';

export default {
    name: 'DevedgeApplication',
    components: {
        AbstractEditPageWrapper,
        AppHeader,
        AppInputV3,
        AppButton,
        IconButton,
    },
    props: {},
    data() {
        return {
            RouteNames,
            BUTTON_TYPES,
            ICON_TYPES,
            ENTITY_TYPES,
            getRouteNameFromTargetType,
            // Account that owns the application
            account: null as Account | null,
            apiResponse: null as AxiosResponse<ApplicationDetailsResponse> | null,
            applicationData: null as ApplicationData | null,
            pageTitle: this.$t('devedgeApplications.applications'),
            searchString: '',
            showSearchStringError: false,
            offerIdToLabel: {} as Record<string, string>,
            userHasPermissionToViewOffer: isUserAllowed('OffersRead', 'OffersWrite'),
            offerEditorMode: isUserAllowed('OffersWrite') ? EDITOR_MODE.EDIT : EDITOR_MODE.VIEW,
            productIdToLabel: {} as Record<string, string>,
            userHasPermissionToViewProducts: isUserAllowed('ProductsRead', 'ProductsWrite'),
            productEditorMode: isUserAllowed('ProductsWrite') ? EDITOR_MODE.EDIT : EDITOR_MODE.VIEW,
        };
    },
    methods: {
        copyToClipboard,
        isEqual,
        async onSearch() {
            this.showSearchStringError = false;
            if (!this.searchString) {
                this.showSearchStringError = true;
                return;
            }
            await this.$withLoadingSpinner(async () => {
                try {
                    this.apiResponse = await getApplicationByAppClientId(this.searchString);
                    this.applicationData = new ApplicationData(
                        ApplicationData.mapResourceFromBE(this.apiResponse?.data?.app),
                    );
                    // Get owner account
                    if (this.applicationData?.targetType === TARGET_TYPE.ACCOUNT && this.applicationData?.targetId) {
                        this.account = await getAccount(this.applicationData.targetId);
                    }
                    if (this.userHasPermissionToViewOffer) {
                        // Get offer ids:
                        const offerIds = uniq(
                            [
                                ...this.applicationData.apiSubscriptions,
                                ...this.applicationData.pendingApiSubscriptions,
                            ].map(sub => sub.offerId),
                        );

                        // Determine offer names
                        const offersResponse = await getOffers(offerIds);
                        const offerIdToLabel = Object.fromEntries(
                            offerIds.map(id => {
                                const offer = offersResponse.data.offer_by_id[id];
                                const label = offer?.data?.name ? `${offer?.data?.name} (${id})` : '';
                                return [id, label];
                            }),
                        );
                        this.$set(this, 'offerIdToLabel', offerIdToLabel);
                    }

                    if (this.userHasPermissionToViewProducts) {
                        // Get product ids:
                        const productIds = uniq(
                            [
                                ...this.applicationData.apiSubscriptions,
                                ...this.applicationData.pendingApiSubscriptions,
                            ].map(sub => sub.productId),
                        );

                        // Determine product names
                        const productsResponse = await getProducts(productIds);
                        const productIdToLabel = Object.fromEntries(
                            productIds.map(id => {
                                const product = productsResponse.data.product_by_id[id];
                                const label = product?.data?.name ? `${product?.data?.name} (${id})` : '';
                                return [id, label];
                            }),
                        );
                        this.$set(this, 'productIdToLabel', productIdToLabel);
                    }
                } catch (error) {
                    const { module, code } = { ...error?.response?.data };
                    if (module === modules.OSS_DEVEDGE && code === luaErrorCodes.APP_NOT_FOUND_IN_REVERSE_INDEX_TABLE) {
                        this.$alert(this.$t('devedgeApplications.applicationMatchingCriteriaNotFound'));
                    } else {
                        this.$alert(this.$t('devedgeApplications.errorOccurredWhileSearching'));
                        Sentry.captureException(error);
                    }
                }
            });
        },
    },
};
