<template>
    <div
        ref="graphContainer"
        class="line-graph"
    />
</template>

<script>
import c3 from 'c3';

export default {
    name: 'LineGraph',

    props: {
        columns: {
            type: Array,
            default: () => [],
        },
        xAxis: {
            type: Array,
            default: () => [],
        },
    },

    data() {
        return {
            graph: null,
            options: {},
        };
    },

    mounted() {
        // Init process
        this.options.bindto = this.$el;

        const c3TimeFormat = '%Y-%m-%d %H:%M:%S';

        this.options.data = {
            columns: this.columns,
            x: 'x',
            xFormat: c3TimeFormat,
        };

        this.options.axis = {
            x: {
                type: 'timeseries',
                tick: {
                    format: c3TimeFormat,
                },
            },
        };

        this.graph = c3.generate(this.options);
    },
};
</script>

<style lang="scss">
@import 'c3/c3';
</style>
