import NcsBaseCondition from '@/__new__/services/dno/charging/models/chargingSpecificationConditions/NCSbaseCondition';
import { BucketTypeConditionArg } from '@/__new__/services/dno/charging/common/bucketTypeConditionHelper';

export default class BucketTypeCondition extends NcsBaseCondition {
    data: any;

    type: string;

    uuid: string;

    dataValid: boolean;

    dataEmpty: boolean;

    constructor({ data, uuid, type, dataEmpty = true, dataValid = false }: BucketTypeConditionArg) {
        super();
        this.data = data;
        this.type = type;
        this.uuid = uuid;
        this.dataEmpty = dataEmpty;
        this.dataValid = dataValid;
    }

    validationCondition(): boolean {
        return !Boolean(this.data);
    }

    isDataValid(): void {
        this.dataValid = !this.validationCondition();
    }
}
