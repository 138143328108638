
import Vue, { PropType } from 'vue';

// Validations
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';

// Components
import AppInputV3 from '@/components/partials/inputs/AppInputV3.vue';
import TargetToAddressTypeTable from '@/__new__/features/pc/products/TargetToAddressTypeTable.vue';

// Helpers
import {
    TaxAddressTypePerTargetDNO,
    TargetAddressPair,
    convertTargetToAddressTypeBeToFe,
    convertTargetToAddressTypeFeToBe,
} from './common/productTaxAttribute';
import { TOOLTIP_POSITION } from '@/common/tooltip';
import {
    ADDRESS_TYPE_TO_LABEL_MAP,
    addressTypeToString,
    getUserManagerEntityTypeById,
} from '@/__new__/features/customerCare/common/customerCareHelper';

interface ProductTaxAttributeData {
    // eslint-disable-next-line camelcase
    tax_component_id: string;
    // eslint-disable-next-line camelcase
    tax_address_type_per_target: TaxAddressTypePerTargetDNO;
    error: boolean;
}

export default Vue.extend({
    name: 'ProductTaxAttributeFormBuilderWrapper',
    components: {
        AppInputV3,
        TargetToAddressTypeTable,
    },
    mixins: [validationMixin],
    props: {
        value: {
            type: Object as PropType<ProductTaxAttributeData>,
            default: () => ({
                tax_component_id: '',
                tax_address_type_per_target: {},
                error: false,
            }),
        },
        /**
         * Disables all child components. Used for read only mode.
         */
        disabled: {
            type: Boolean,
            default: false,
        },
        /**
         * Indicates if errors will be shown. Used for validation purposes.
         */
        showValidation: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            /**
             * Class data members
             */
            targetToAddressType: [] as TargetAddressPair[], // Contains target to address type mappings in a format consumable by FE components.
            targetToAddressTypeHasError: false,
            /**
             * Imported data members
             */
            addressTypeToString,
            TOOLTIP_POSITION,
            ADDRESS_TYPE_TO_LABEL_MAP,
            getUserManagerEntityTypeById,
        };
    },
    computed: {
        /**
         * Returns true if target to address type table has atleast 1 row where both target and addressType are specified
         */
        tableHasValidEntry() {
            return this.targetToAddressType.some(pair => pair.target && pair.addressType);
        },
    },
    watch: {
        value: {
            handler() {
                if (!this.value?.tax_address_type_per_target) {
                    this.targetToAddressType = [];
                } else {
                    // Convert backend data to front end structure
                    this.targetToAddressType = convertTargetToAddressTypeBeToFe(this.value.tax_address_type_per_target);
                }

                // Run validations
                this.$v.$touch();
            },
            immediate: true,
        },
    },
    methods: {
        onTargetToAddressTypeChange() {
            // Convert frontend structure to backend data
            this.value.tax_address_type_per_target = convertTargetToAddressTypeFeToBe(this.targetToAddressType);
        },
        onTargetToAddressTypeError(hasError: boolean) {
            this.targetToAddressTypeHasError = hasError;
            this.updateErrorState();
        },
        onTaxComponentIdChange() {
            this.$v.$touch();
            this.updateErrorState();
        },
        updateErrorState() {
            this.value.error = this.targetToAddressTypeHasError || this.$v.value.tax_component_id.$error;
        },
    },
    validations: {
        value: {
            tax_component_id: {
                required,
            },
        },
    },
});
