<template>
    <div class="mb-5">
        <!-- Pricing part -->
        <div
            v-t="'productCatalog.offers.editor.pricing'"
            class="lf-subtitle"
        />
        <div class="d-flex">
            <AppCheckbox
                v-if="isInBulkEdit"
                :key="CORE_PROPERTIES_FE.PRICE"
                :value="findSelectedProperty(CORE_PROPERTIES_FE.PRICE)"
                class="mr-4"
                @input="onCorePropertySelected('price')"
            />
            <div class="editor-input-largest">
                <div
                    v-if="isOfferBundle"
                    class="inline-warning editor-input-largest mb-5"
                >
                    {{ $i18n.t('productCatalog.offers.bundlePriceWarning') }}
                </div>
                <div
                    v-if="isOfferBundleChild"
                    class="inline-warning editor-input-largest mb-5"
                >
                    {{ $i18n.t('productCatalog.offers.bundlePriceChildWarning') }}
                </div>
                <div class="d-flex editor-input-largest mb-3">
                    <AppMultiselectV3
                        v-model="offerData.selectedCurrency"
                        :options="currencyOptions"
                        :additionalLabel="$i18n.t('generic.currency')"
                        :small="true"
                        :explanationText="currencySelectorExplanationText"
                        :tooltipOffset="20"
                        :disabled="!multipleCurrenciesAvailable || readonly || isOfferBundle || isOfferBundleChild"
                        :error="validationModifier(!offerData.selectedCurrency)"
                        data-test-id="currency-type-field"
                        class="w-100"
                    />
                    <UnitPicker
                        v-show="isDataCurrency"
                        :value="offerData.dataTypeDefinition"
                        :hiddenOptionIds="hiddenUnitOptionIds"
                        :definitionsWidth="5"
                        :isEditorDesign="true"
                        :emitObject="true"
                        :enableValueInput="false"
                        :label="$i18n.t('generic.dataType')"
                        :defaultUnitId="defaultDataTypeDefinition"
                        :disabled="readonly"
                        type="data"
                        class="unit-picker ml-3"
                        data-test-id="currency-data-type-field"
                        @input="transformDataPrice"
                    />
                </div>
                <AppMultiselectV3
                    v-model="offerData.selectedPriceType"
                    :options="priceTypeOptions"
                    :additionalLabel="$i18n.t('generic.priceType')"
                    :placeholder="$i18n.t('editors.choosePriceType')"
                    :small="true"
                    :explanationText="$i18n.t('productCatalog.offers.editor.priceTypeInformation')"
                    :tooltipOffset="20"
                    :disabled="readonly || isOfferBundle || isOfferBundleChild"
                    data-test-id="price-type-field"
                    label="typeName"
                    trackBy="type"
                    class="editor-input-largest mb-3"
                    @select="onPriceTypeSelect()"
                />

                <AttiotOfferListSelect
                    v-if="flatPriceSelected && isAtiotDemo"
                    v-model="offerData.price"
                    :disabled="readonly || isOfferBundle"
                    :validationModifier="validationModifier"
                    :validatePrice="validatePrice"
                    class="mb-5"
                />
                <AppInputV3
                    v-if="flatPriceSelected && !isAtiotDemo"
                    v-model="offerData.price"
                    :placeholder="$i18n.t('generic.price')"
                    :label="$i18n.t('generic.price')"
                    :invalid="validationModifier(!validatePrice(offerData.price), CORE_PROPERTIES_FE.PRICE)"
                    :step="0.01"
                    :disabled="readonly || isOfferBundle"
                    type="number"
                    data-test-id="price-field"
                    class="editor-input-largest mb-3"
                />
                <PriceTable
                    v-if="!flatPriceSelected"
                    :headerOptions="headerOptions"
                    :optionsArray="offerData.optionsArray"
                    :widthPriceColumn="'60'"
                    :enableDeleteButton="true"
                    :indexesOfPriceToShowError="indexesOfPriceToShowError"
                    :disabled="readonly"
                    @updateArrayOptions="offerData.optionsArray = $event"
                />
            </div>
        </div>
        <!-- End of pricing part -->

        <div
            v-if="!isInBulkEdit"
            v-t="'productCatalog.offers.editor.offerParameters'"
            class="lf-subtitle"
        />
        <div
            v-if="isCategoriesEnabled"
            class="mb-5"
        >
            <AppMultiselectV3
                v-model="offerData.categories"
                :options="categories"
                :showLabels="false"
                :multiple="true"
                :error="!isCategorySelectionValid && showValidation"
                :errorMessage="$t('productCatalog.offers.editor.uniqueCategoryNotice')"
                :additionalLabel="$i18n.t('productCatalog.entities.plural.categories')"
                :placeholder="$i18n.t('editors.chooseACategory')"
                :optional="true"
                :small="true"
                :disabled="readonly"
                label="displayName"
                trackBy="id"
                class="editor-input-largest"
            />
        </div>

        <div
            v-t="'productCatalog.editors.eligibility'"
            class="lf-subtitle"
        />
        <div class="d-flex">
            <AppCheckbox
                v-if="isInBulkEdit"
                :key="CORE_PROPERTIES_FE.TIME"
                :value="findSelectedProperty(CORE_PROPERTIES_FE.TIME)"
                class="mr-4"
                @input="onCorePropertySelected(CORE_PROPERTIES_FE.TIME)"
            />
            <div>
                <DateTimePicker
                    v-if="(inEditMode && offerData.startTime) || !inEditMode"
                    v-model="offerData.startTime"
                    :additionalLabel="$i18n.t('generic.startTime')"
                    :error="validationModifier(!offerData.startTime, CORE_PROPERTIES_FE.TIME)"
                    :disabled="readonly"
                    :clearable="false"
                    type="datetime"
                    data-test-id="start-time"
                    class="editor-input-largest mb-3"
                />
                <DateTimePicker
                    v-if="isVisibleEndTime"
                    v-model="offerData.endTime"
                    :additionalLabel="$i18n.t('generic.endTime')"
                    :error="validationModifier(!offerData.noEndTime && !offerData.endTime, CORE_PROPERTIES_FE.TIME)"
                    :disabled="offerData.noEndTime || readonly"
                    :clearable="false"
                    type="datetime"
                    data-test-id="end-time"
                    class="editor-input-largest mb-3"
                />
                <AppToggle
                    v-model="offerData.noEndTime"
                    :small="true"
                    :label="$i18n.t('productCatalog.offers.editor.noEndTime')"
                    :fitContent="true"
                    :disabled="readonly"
                    data-test-id="no-end-time"
                    class="mb-3"
                />
            </div>
        </div>
    </div>
</template>

<script>
// Components
import AppInputV3 from '@/components/partials/inputs/AppInputV3.vue';
import AppCheckbox from '@/components/partials/inputs/AppCheckbox.vue';
import AppMultiselectV3 from '@/components/partials/inputs/AppMultiselectV3.vue';
import AppToggle from '@/components/partials/inputs/AppToggle.vue';
import AttiotOfferListSelect from '@/__new__/features/pc/atiot/AtiotOfferPriceListSelect.vue';
import PriceTable from '@/__new__/features/pc/offers/PriceTable.vue';
import DateTimePicker from '@/components/partials/inputs/DateTimePicker.vue';
import UnitPicker from '@/components/partials/inputs/UnitPicker.vue';

// Helpers
import ENTITY_TYPES from '@/common/entities/entityTypes';
import { formatAmountBasedOnCurrency, getUnitDefinitions, unitTypes, DATA_DEFINITION_IDS } from '@/common/formatting';
import {
    PRICE_TYPES_MAP,
    PRICE_TYPES_TO_ENUM,
    ENUM_TO_PRICE_TYPE,
    defaultDataPriceMultiplier,
} from '@/__new__/services/dno/pc/models/ProductCatalogOfferModel';
import permissionsService, { getOperatorConfigValue } from '@/services/permissions/permissions.service';
import { ALERT_TYPES } from '@/common/alerts/Alert';
import moment from 'moment';
import { NO_END_TIME_EPOCH } from '@/common/locale/localeLibrary';
import * as Sentry from '@sentry/vue';
import CORE_PROPERTIES_FE from '@/__new__/features/pc/offers/common/offerCorePropertiesFE';
import CURRENCY_TYPES from '@/__new__/features/pc/common/currencyTypes';

// HTTP
import { getConfig } from '@/__new__/services/dno/pc/http/catalog';

// Vuex
import Actions, { Getters } from '@/store/mutation-types';
import { mapActions, mapGetters } from 'vuex';

const defaultDataTypeDefinition = DATA_DEFINITION_IDS.MEGABYTES;

const DEFAULT_OPTIONS_VALUE = [
    {
        range_start: null,
        max: null,
        amount: null,
    },
];

export default {
    name: 'OfferCoreData',

    components: {
        AppInputV3,
        AppCheckbox,
        AppMultiselectV3,
        AppToggle,
        AttiotOfferListSelect,
        PriceTable,
        DateTimePicker,
        UnitPicker,
    },

    props: {
        // passed in case of editing of the existing offer in EntityEditor
        initialOfferData: {
            type: Object,
            default: null,
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        childEntities: {
            type: Array,
            default: () => [],
        },
        inEditMode: {
            type: Boolean,
            default: false,
        },
        showMainValidation: {
            type: Boolean,
            default: false,
        },
        // Basically disabled categories in case of true passed
        isInBulkEdit: {
            type: Boolean,
            default: false,
        },
        // only available for Bulk edit case
        selectedCoreProperties: {
            type: Array,
            default: () => [],
        },
    },

    data() {
        return {
            // actual offer data
            offerData: {
                startTime: null,
                endtime: null,
                price: null,
                categories: [],
                noEndTime: false,
                selectedCurrency: null,
                dataTypeDefinition: getUnitDefinitions(unitTypes.DATA).find(
                    ({ id }) => id === defaultDataTypeDefinition,
                ),
                selectedPriceType: {
                    type: PRICE_TYPES_MAP.FLAT,
                    typeName: this.$i18n.t('productCatalog.offers.editor.flat'),
                },
                // default value for optionsArray in case there is no data from API
                optionsArray: DEFAULT_OPTIONS_VALUE,
            },
            // data for component
            flatPriceSelected: true,
            offerCoreFieldsInvalid: false,

            // price related data
            currencyOptions: [],
            hiddenUnitOptionIds: [DATA_DEFINITION_IDS.BYTES],
            headerOptions: [
                this.$i18n.t('generic.min'),
                this.$i18n.t('generic.max'),
                this.$i18n.t('generic.pricePerUnitPerPeriod'),
            ],
            defaultDataTypeDefinition,
            indexesOfPriceToShowError: [],
            CORE_PROPERTIES_FE,
            isAtiotDemo: getOperatorConfigValue('isAtiotDemo'),
        };
    },
    computed: {
        ...mapGetters('productcatalog', {
            getEntitiesApproved: Getters.PC_GET_ENTITIES_BY_TYPE_APPROVED,
            getEntityByTypeAndId: Getters.PC_GET_ENTITY_BY_TYPE_AND_ID,
            getEntitiesByType: Getters.PC_GET_ENTITIES_BY_TYPE,
        }),
        offers() {
            return this.getEntitiesByType(ENTITY_TYPES.OFFER);
        },
        showValidation() {
            return this.showMainValidation || this.offerCoreFieldsInvalid;
        },
        isOfferBundle() {
            return Boolean(this.childEntities?.find(child => child?.entityType === ENTITY_TYPES.OFFER));
        },
        isOfferBundleChild() {
            if (this.offers) {
                return Boolean(
                    Object.values(this.offers).find(offer =>
                        offer.childEntities.find(child => child?.id === this.$route.params.id),
                    ),
                );
            }
            return false;
        },
        offerBundlePrices() {
            const childOffers = this.childEntities.filter(child => child?.entityType === ENTITY_TYPES.OFFER);

            const bundlePrices = {};
            childOffers.forEach(offer => {
                bundlePrices[offer.currencyType] = (bundlePrices[offer.currencyType] ?? 0) + Number(offer.price);
            });

            return bundlePrices;
        },
        formattedOfferBundlePrices() {
            const prices = Object.entries(this.offerBundlePrices).map(([currency, price]) =>
                formatAmountBasedOnCurrency(price, currency),
            );

            return prices;
        },
        priceTypeOptions() {
            return Object.values(PRICE_TYPES_MAP).map(type => ({
                type,
                typeName: this.$i18n.t(`productCatalog.offers.editor.${type}`),
            }));
        },
        isVisibleEndTime() {
            return ((this.inEditMode && this.offerData.endTime) || !this.inEditMode) && !this.offerData.noEndTime;
        },
        multipleCurrenciesAvailable() {
            return this.currencyOptions.length > 1;
        },
        currencySelectorExplanationText() {
            return this.isDataCurrency ? this.$i18n.t('productCatalog.offers.editor.dataPriceWillBeRounded') : '';
        },
        isDataCurrency() {
            return this.offerData.selectedCurrency === unitTypes.DATA.toUpperCase();
        },
        isCategorySelectionValid() {
            const hasUniqueCategory = !!this.offerData.categories?.find(c => c.unique);
            return !hasUniqueCategory || (hasUniqueCategory && this.offerData.categories.length === 1);
        },
        isCategoriesEnabled() {
            return !this.isInBulkEdit && permissionsService.productCatalogCategoriesEnabled();
        },
        categories() {
            const categories = this.getEntitiesApproved(ENTITY_TYPES.CATEGORY).map(c => this.decorateUniqueCategory(c));
            return this.getCategoriesWithDisplayName(categories);
        },
    },
    watch: {
        initialOfferData: {
            handler(newOfferData, oldOfferData) {
                if (newOfferData !== oldOfferData) {
                    this.initData();
                }
            },
        },
        offerData: {
            handler(newVal) {
                if (!this.isCategoriesEnabled) {
                    delete newVal.categories;
                }
                this.$emit('input', newVal);
            },
            deep: true,
        },
    },
    async created() {
        await this.getConfiguration();
        if (this.isCategoriesEnabled) {
            await this.requestEntitiesByType({ entityType: ENTITY_TYPES.CATEGORY });
        }
        await this.requestEntitiesByType({ entityType: ENTITY_TYPES.OFFER });
    },
    methods: {
        ...mapActions('productcatalog', {
            requestEntitiesByType: Actions.PC_REQUEST_ENTITIES_BY_TYPE_AND_IDS,
        }),
        getCategoriesWithDisplayName(categories) {
            const allCategories = this.getEntitiesApproved(ENTITY_TYPES.CATEGORY).map(c =>
                this.decorateUniqueCategory(c),
            );
            const categoriesMap = allCategories.reduce((accumulator, current) => {
                accumulator[current.id] = current;
                return accumulator;
            }, {});

            const categoriesWithDisplayName = categories.map(category => {
                category.displayName = category.name;
                if (category.parent) {
                    let head = category;
                    while (head.parent && categoriesMap[head.parent]) {
                        category.displayName = `${categoriesMap[head.parent].name} / ${category.displayName}`;
                        head = categoriesMap[head.parent];
                    }
                }
                return category;
            });

            categoriesWithDisplayName.sort((a, b) => a.displayName.localeCompare(b.displayName));
            return categoriesWithDisplayName;
        },
        async getConfiguration() {
            try {
                const configResponse = await getConfig();
                // eslint-disable-next-line camelcase
                this.currencyOptions = configResponse?.data?.catalog_config?.currency_type || [CURRENCY_TYPES.MONETARY];
                // always setting to monetary since BE requires it even if only 1 currency is available
                // will be rewritten in the initData method in case of edit
                this.offerData.selectedCurrency = CURRENCY_TYPES.MONETARY;
            } catch (error) {
                Sentry.captureException(error);
                this.$alert(
                    this.$i18n.t('alertMessage.errorDoingSmthTryAgain', {
                        action: this.$i18n.t('generic.fetching'),
                        entityName: this.$i18n.t('productCatalog.offers.offerConfiguration'),
                    }),
                );
            }
        },
        transformDataPrice(val) {
            const oldMultiplier =
                this.offerData.dataTypeDefinition?.definition?.multiplier || defaultDataPriceMultiplier;
            const multiplier = oldMultiplier / val.definition.multiplier;

            if (this.offerData.optionsArray.length) {
                this.offerData.optionsArray = this.offerData.optionsArray.map(opt => ({
                    ...opt,
                    amount: opt.amount * multiplier,
                }));
            }
            if (this.offerData.price) {
                this.offerData.price *= multiplier;
            }

            this.offerData.dataTypeDefinition = val;
        },
        onPriceTypeSelect() {
            this.flatPriceSelected = false;

            this.flatPriceSelected = this.offerData.selectedPriceType.type === PRICE_TYPES_MAP.FLAT;
        },
        validatePrice(price) {
            return (Boolean(price) && price > 0) || price === 0;
        },
        validatePriceDecimalValue(amountValue) {
            if (amountValue.includes('.')) {
                return amountValue.toString().split('.')[1].length > 2;
            }
            return false;
        },
        validateBundleChildCurrencyTypes() {
            if (this.selectedChildEntitiesType?.type !== ENTITY_TYPES.OFFER) {
                return true;
            }

            let currencyType;
            return this.offerData.childEntities.every(entity => {
                // Ensure that all child currencies are the same.
                if (!currencyType) {
                    currencyType = entity.currencyType;
                } else if (currencyType !== entity.currencyType) {
                    return false;
                }

                return true;
            });
        },
        validateBundleChildPriceTypes() {
            if (this.selectedChildEntitiesType?.type !== ENTITY_TYPES.OFFER) {
                return true;
            }

            // All child entities must have flat prices.
            return this.initialOfferData.childEntities.every(
                entity => entity.priceType === PRICE_TYPES_TO_ENUM[PRICE_TYPES_MAP.FLAT],
            );
        },
        validateOfferData() {
            const hasChildEntities = this.childEntities.length > 0;

            let isOfferInvalid = false;

            if (!this.isInBulkEdit) {
                isOfferInvalid =
                    // currency selection is mandatory when multiple currencies available
                    !this.offerData.selectedCurrency ||
                    // flat price validation
                    (!hasChildEntities && this.flatPriceSelected && !this.validatePrice(this.offerData.price)) ||
                    // start time issimply mandatory
                    !this.offerData.startTime ||
                    // same for the end time unless it's disabled
                    (!this.offerData.noEndTime && !this.offerData.endTime) ||
                    // if categories selected - validating them in a separate computed property
                    !this.isCategorySelectionValid;
                // don't get the logic here, but it was here for some time
                // (!hasChildEntities && this.multipleCurrenciesAvailable && !this.offerData.selectedCurrency);
            } else if (this.isInBulkEdit && this.selectedCoreProperties.length) {
                // similar to the above validation, but each part depends on the selection of the
                // properties to update in the bulk edit page

                isOfferInvalid =
                    (this.findSelectedProperty(CORE_PROPERTIES_FE.PRICE) && !this.offerData.selectedCurrency) ||
                    (this.findSelectedProperty(CORE_PROPERTIES_FE.PRICE) &&
                        !hasChildEntities &&
                        this.flatPriceSelected &&
                        !this.validatePrice(this.offerData.price)) ||
                    (this.findSelectedProperty(CORE_PROPERTIES_FE.TIME) && !this.offerData.startTime) ||
                    (this.findSelectedProperty(CORE_PROPERTIES_FE.TIME) &&
                        !this.offerData.noEndTime &&
                        !this.offerData.endTime);
            }

            if (isOfferInvalid) {
                this.offerCoreFieldsInvalid = true;
                this.$alert(this.$i18n.t('alertMessage.pleaseFixValidation'), { type: ALERT_TYPES.error });
            }

            if (this.validationModifier(!this.validateBundleChildCurrencyTypes())) {
                this.$alert(this.$i18n.t('productCatalog.offers.invalidParentBundleDueToCurrencyTypeMismatchWarning'), {
                    type: ALERT_TYPES.error,
                });
                isOfferInvalid = true;
            }

            if (this.validationModifier(!this.validateBundleChildPriceTypes())) {
                this.$alert(this.$i18n.t('productCatalog.offers.invalidParentBundleDueToPriceTypeMismatch'), {
                    type: ALERT_TYPES.error,
                });
                isOfferInvalid = true;
            }

            if (!this.flatPriceSelected) {
                this.indexesOfPriceToShowError = [];
                let nonFlatPriceAlertMessage = this.$i18n.t('alertMessage.offers.price');

                if (!this.offerData.optionsArray.length) {
                    this.indexesOfPriceToShowError.push(0);
                } else {
                    this.offerData.optionsArray.forEach((priceOption, index) => {
                        if (!priceOption?.amount) {
                            this.indexesOfPriceToShowError.push(index);
                        } else if (this.validatePriceDecimalValue(priceOption?.amount?.toString())) {
                            nonFlatPriceAlertMessage = this.$i18n.t('alertMessage.amountFieldDecimalValidation');
                            this.indexesOfPriceToShowError.push(index);
                        }
                    });
                }

                if (this.indexesOfPriceToShowError.length) {
                    isOfferInvalid = true;
                    this.$alert(nonFlatPriceAlertMessage, { type: ALERT_TYPES.error });
                }
            }
            this.$emit('offerValidationResult', !isOfferInvalid);
        },
        decorateUniqueCategory(cat) {
            return {
                ...cat,
                name: `${cat.name}${cat.unique ? ' *' : ''}`,
            };
        },
        initData() {
            if (!this.initialOfferData) {
                return;
            }
            let endTimeToSet;
            let noEndTime;
            if (this.initialOfferData.endTime === NO_END_TIME_EPOCH) {
                endTimeToSet = new Date();
                noEndTime = true;
            } else {
                endTimeToSet = moment(this.initialOfferData.endTime * 1000).toDate();
            }

            this.offerData = {
                ...this.initialOfferData,
                startTime: moment(this.initialOfferData.startTime * 1000).toDate(),
                endTime: endTimeToSet,
                noEndTime,
                // default value for options
                optionsArray: DEFAULT_OPTIONS_VALUE,
            };

            if (this.initialOfferData.currencyType) {
                this.offerData.selectedCurrency = this.initialOfferData.currencyType;
            }

            // set which option is selected and which field will be visible
            if (this.initialOfferData.priceType) {
                const priceTypeString = ENUM_TO_PRICE_TYPE[this.initialOfferData.priceType] || '';
                this.flatPriceSelected = this.initialOfferData.priceType === PRICE_TYPES_TO_ENUM[PRICE_TYPES_MAP.FLAT];

                this.offerData.selectedPriceType = {
                    type: PRICE_TYPES_MAP[priceTypeString.toUpperCase()],
                    typeName: this.$i18n.t(`productCatalog.offers.editor.${priceTypeString}`),
                };
            }

            const multiplier = this.isDataCurrency ? defaultDataPriceMultiplier : 1;
            // set updated options from api for array
            const optionsArray = this.initialOfferData?.amountRange || this.offerData.optionsArray;
            this.offerData.optionsArray = optionsArray.map(opt => ({
                ...opt,
                amount: String(opt.amount / multiplier),
            }));

            if (Object.prototype.hasOwnProperty.call(this.initialOfferData, 'price')) {
                this.offerData.price /= multiplier;
            }

            // categories parsing
            if (this.initialOfferData.categories) {
                this.offerData.categories = this.initialOfferData.categories.map(categoryId => {
                    const catFromVuex = this.getEntityByTypeAndId(ENTITY_TYPES.CATEGORY, categoryId);
                    return this.decorateUniqueCategory(catFromVuex);
                });
                this.offerData.categories = this.getCategoriesWithDisplayName(this.offerData.categories);
            }
        },
        onCorePropertySelected(propertyId) {
            this.$emit('corePropertySelected', propertyId);
        },
        findSelectedProperty(propertyId) {
            return this.selectedCoreProperties.includes(propertyId);
        },
        validationModifier(condition, propertyId) {
            // when used in entity editor - only showValidation and condition determines
            // when used in bulk - property selection  condition is added
            if (!this.isInBulkEdit || (this.isInBulkEdit && this.findSelectedProperty(propertyId))) {
                return this.showValidation && condition;
            }
            return false;
        },
    },
};
</script>

<style lang="scss" scoped></style>
