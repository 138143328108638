import { GetViewsResponseBody, GetCompanyViewsResponseBody } from '@/__new__/services/portal/auth/http/login';
import { Views, ViewsRestructure } from '@/models/ViewsHelper';
import i18n from '@/i18n';

export type ViewListRemapped = {
    [key: string]: {
        id: number;
        viewId: string;
        parentViewId: number | null;
        rootViewId: number | null;
        displayName: string;
        description: string;
        alwaysEnabled: boolean;
    };
};

export type ViewPermission = {
    key: string;
    displayName: string;
    viewKey: string;
    enabled: boolean;
};

export function getViewsByCompanyId(
    getViewsResponseBody: GetViewsResponseBody,
    getCompanyViewsResponseBody: GetCompanyViewsResponseBody,
): Record<number, Views> {
    const viewsByCompanyId: Record<number, Views> = {};
    for (const companyId in getCompanyViewsResponseBody) {
        if (
            Object.prototype.hasOwnProperty.call(getCompanyViewsResponseBody, companyId) &&
            getCompanyViewsResponseBody[companyId]
        ) {
            const viewsRemap = getCompanyViewsResponseBody[companyId].reduce(
                (viewsObj: ViewListRemapped, key: string) => {
                    const lastKey = key.split('.').slice(-1)[0];
                    if (lastKey && getViewsResponseBody[lastKey]) {
                        const { key, ...obj } = getViewsResponseBody[lastKey];
                        viewsObj[getViewsResponseBody[lastKey].id] = {
                            ...obj,
                            viewId: key,
                        };
                    }

                    return viewsObj;
                },
                {},
            );

            viewsByCompanyId[companyId] = {};

            for (const view in viewsRemap) {
                if (Object.prototype.hasOwnProperty.call(viewsRemap, view)) {
                    const { rootViewId, parentViewId, viewId } = viewsRemap[view];

                    if (!rootViewId && !parentViewId) {
                        viewsByCompanyId[companyId][viewId] = {
                            enabled: true,
                        };
                    }

                    if (rootViewId && rootViewId === parentViewId) {
                        if (!viewsByCompanyId[companyId][viewsRemap[parentViewId].viewId].views) {
                            viewsByCompanyId[companyId][viewsRemap[parentViewId].viewId].views = {};
                        }
                        viewsByCompanyId[companyId][viewsRemap[parentViewId].viewId].views![viewId] = {
                            enabled: true,
                        };
                    }

                    if (rootViewId && parentViewId && rootViewId !== parentViewId) {
                        if (
                            !viewsByCompanyId[companyId][viewsRemap[rootViewId].viewId].views?.[
                                viewsRemap[parentViewId].viewId
                            ].views
                        ) {
                            viewsByCompanyId[companyId][viewsRemap[rootViewId].viewId].views![
                                viewsRemap[parentViewId].viewId
                            ].views = {};
                        }
                        viewsByCompanyId[companyId][viewsRemap[rootViewId].viewId].views![
                            viewsRemap[parentViewId].viewId
                        ].views![viewId] = {
                            enabled: true,
                        };
                    }
                }
            }
        }
    }
    return viewsByCompanyId;
}
export function mapViewsTreeToArray(viewsTree: any): string[] {
    return mapViewsTreeToArrayRecursive(viewsTree, [], '');
}

function mapViewsTreeToArrayRecursive(viewsTree: any, viewList: any[], prefix: string) {
    for (const key in viewsTree) {
        if (viewsTree[key].enabled) {
            viewList.push(prefix + key);
            if (viewsTree[key].views != null && Object.keys(viewsTree[key].views).length > 0) {
                mapViewsTreeToArrayRecursive(viewsTree[key].views, viewList, prefix + key + '.');
            }
        }
    }
    return viewList;
}

export function mapViewsTemplate(
    getViewsResponseBody: GetViewsResponseBody,
    operatorViews: Views,
    viewPermissions: ViewPermission[],
    rolePermissions: any[],
    onlyEnabled: boolean,
) {
    const viewsRestructure: ViewsRestructure = {};

    const viewsRemap = Object.values(getViewsResponseBody).reduce((viewsObj: ViewListRemapped, view) => {
        viewsObj[view.id] = {
            id: view.id,
            viewId: view.key,
            rootViewId: view.rootViewId,
            parentViewId: view.parentViewId,
            displayName: view.displayName,
            description: view.description,
            alwaysEnabled: view.alwaysEnabled,
        };
        return viewsObj;
    }, {});

    // Manual addition of Core view group

    viewsRemap[0] = {
        id: 0,
        viewId: 'Core',
        rootViewId: null,
        parentViewId: null,
        displayName: i18n.t('views.Core.name').toString(),
        description: i18n.t('views.Core.description').toString(),
        alwaysEnabled: true,
    };

    if (rolePermissions && rolePermissions.length > 0) {
        viewPermissions = viewPermissions.map(item => ({
            ...item,
            enabled: rolePermissions.includes(item.key),
        }));
    }

    // Cycle to create object with all features structured
    for (const view in viewsRemap) {
        if (Object.prototype.hasOwnProperty.call(viewsRemap, view)) {
            const { rootViewId, parentViewId, viewId } = viewsRemap[view];

            if (!rootViewId && !parentViewId) {
                viewsRestructure[viewId] = {
                    enabled: false,
                    viewId: viewsRemap[view].id,
                    displayName: viewsRemap[view].displayName,
                    description: viewsRemap[view].description,
                    id: viewId,
                    permissions: [],
                    alwaysEnabled: viewsRemap[view].alwaysEnabled,
                };
            }

            if (rootViewId && rootViewId === parentViewId) {
                if (!viewsRestructure[viewsRemap[parentViewId].viewId].views) {
                    viewsRestructure[viewsRemap[parentViewId].viewId].views = {};
                }
                viewsRestructure[viewsRemap[parentViewId].viewId].views![viewId] = {
                    enabled: false,
                    viewId: viewsRemap[view].id,
                    id: viewId,
                    displayName: viewsRemap[view].displayName,
                    description: viewsRemap[view].description,
                    permissions: [],
                    alwaysEnabled: viewsRemap[view].alwaysEnabled,
                };
            }

            if (rootViewId && parentViewId && rootViewId !== parentViewId) {
                if (!viewsRestructure[viewsRemap[rootViewId].viewId].views![viewsRemap[parentViewId].viewId].views) {
                    viewsRestructure[viewsRemap[rootViewId].viewId].views![viewsRemap[parentViewId].viewId].views = {};
                }
                viewsRestructure[viewsRemap[rootViewId].viewId].views![viewsRemap[parentViewId].viewId].views![viewId] =
                    {
                        enabled: false,
                        viewId: viewsRemap[view].id,
                        id: viewId,
                        displayName: viewsRemap[view].displayName,
                        description: viewsRemap[view].description,
                        permissions: [],
                        alwaysEnabled: viewsRemap[view].alwaysEnabled,
                    };
            }
        }
    }

    // Remap data from operator features onto blank template created earlier
    for (const rootViewKey in operatorViews) {
        if (
            Object.prototype.hasOwnProperty.call(operatorViews, rootViewKey) &&
            rootViewKey &&
            operatorViews[rootViewKey]
        ) {
            const rootView = operatorViews[rootViewKey];
            viewsRestructure[rootViewKey].enabled = rootView.enabled;
            viewsRestructure[rootViewKey].permissions = viewPermissions.filter(item => item.viewKey === rootViewKey);
            if (rootView.views) {
                for (const subViewKey in rootView.views) {
                    if (
                        Object.prototype.hasOwnProperty.call(rootView.views, subViewKey) &&
                        subViewKey &&
                        rootView.views[subViewKey]
                    ) {
                        const subView = rootView.views[subViewKey];
                        viewsRestructure[rootViewKey].views![subViewKey].enabled = subView.enabled;
                        viewsRestructure[rootViewKey].views![subViewKey].permissions = viewPermissions.filter(
                            item => item.viewKey === subViewKey,
                        );
                        if (subView.views) {
                            for (const viewKey in subView.views) {
                                if (
                                    Object.prototype.hasOwnProperty.call(subView.views, viewKey) &&
                                    viewKey &&
                                    subView.views[viewKey]
                                ) {
                                    const view = subView.views[viewKey];
                                    viewsRestructure[rootViewKey].views![subViewKey].views![viewKey].enabled =
                                        view.enabled;
                                    viewsRestructure[rootViewKey].views![subViewKey].views![viewKey].permissions =
                                        viewPermissions.filter(item => item.viewKey === viewKey);
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    viewsRestructure['Core'].enabled = true;
    viewsRestructure['Core'].permissions = viewPermissions.filter(item => item.viewKey === 'Core');

    let returnViewRestructure: ViewsRestructure = {};
    if (onlyEnabled) {
        for (const rootViewKey in viewsRestructure) {
            const rootView = viewsRestructure[rootViewKey];
            if (rootView.enabled) {
                returnViewRestructure[rootViewKey] = rootView;
                if (rootView.views) {
                    for (const subViewKey in rootView.views) {
                        if (
                            Object.prototype.hasOwnProperty.call(rootView.views, subViewKey) &&
                            subViewKey &&
                            rootView.views[subViewKey]
                        ) {
                            const subView = rootView.views[subViewKey];
                            if (subView.enabled) {
                                returnViewRestructure[rootViewKey].views![subViewKey] = subView;
                            }
                            if (subView.views) {
                                for (const viewKey in subView.views) {
                                    if (
                                        Object.prototype.hasOwnProperty.call(subView.views, viewKey) &&
                                        viewKey &&
                                        subView.views[viewKey]
                                    ) {
                                        const view = subView.views[viewKey];
                                        if (view.enabled) {
                                            returnViewRestructure[rootViewKey].views![subViewKey].views![viewKey] =
                                                view;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    } else {
        returnViewRestructure = viewsRestructure;
    }
    return returnViewRestructure;
}

export default {
    mapViewsDataFromAPI: getViewsByCompanyId,
    mapViewsTemplate,
};
