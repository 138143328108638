<template>
    <SinkConfigDashboard
        :entityType="ENTITY_TYPES.KAFKA_SINK_CONFIG"
        :pageTitle="$i18n.t('generic.kafkaConfiguration')"
        :entities="kafkaConfigsFormatted"
        :isDataLoading="isDataLoading"
        :entitiesApiResponse="kafkaConfigsApiResponse"
        :editRouteName="RouteNames.KAFKA_SINK_CONFIG_EDITOR"
        :uiParams="kafkaUIParams"
        :updateTimeFormatterFn="secondsToDate"
        :writePermission="'KafkaConfigurationsWrite'"
        @fetchConfigs="fetchConfigs"
        @deleteConfig="config => confirmConfigDeletion(config, Actions.DELETE_KAFKA_SINK_CONFIG)"
    />
</template>

<script>
// components
import ENTITY_TYPES from '@/common/entities/entityTypes';
import SinkConfigDashboard from '@/__new__/features/sinkConfigs/SinkConfigDashboard.vue';
// helpers
import Actions, { Getters } from '@/store/mutation-types';
import { mapActions, mapGetters } from 'vuex';
import { Modules } from '@/store/store';
import RouteNames from '@/router/routeNames';
import { mapKafkaSinkConfigActions } from '@/__new__/services/dno/sinkConfigs/models/SinkConfigStatus';
import SinkConfigEditorMixin from '@/__new__/features/sinkConfigs/common/SinkConfigEditorMixin';
import { secondsToDate } from '@/common/formatting';
import * as Sentry from '@sentry/vue';

export default {
    name: 'KafkaConfigDashboard',
    components: { SinkConfigDashboard },
    mixins: [SinkConfigEditorMixin],
    data() {
        return {
            secondsToDate,
            Actions,
            ENTITY_TYPES,
            RouteNames,
            isDataLoading: false,
        };
    },
    computed: {
        ...mapGetters(Modules.sinkConfigs, {
            kafkaConfigs: Getters.GET_KAFKA_SINK_CONFIGS,
            kafkaConfigsApiResponse: Getters.GET_KAFKA_SINK_CONFIGS_API_RESPONSE,
            kafkaUIParams: Getters.GET_KAFKA_SINK_CONFIGS_SETTINGS,
        }),
        ...mapGetters(Modules.segments, {
            segments: Getters.CACHED_SEGMENTS,
        }),

        kafkaConfigsFormatted() {
            if (this.kafkaConfigs.length) {
                return this.kafkaConfigs.map(kc => ({
                    ...kc,
                    allowedActionsExternal: mapKafkaSinkConfigActions(kc),
                }));
            }
            return [];
        },
    },
    created() {
        this.isDataLoading = true;
        this.$Progress.start();
        const mainPromises = [
            this.fetchEvents(),
            this.fetchSinkFormatters(),
            this[Actions.LOAD_KAFKA_SINK_CONFIGS_SETTINGS](),
            this.fetchTriggers(),
        ];

        if (!this.segments.length) {
            mainPromises.push(this.fetchSegments());
        }

        Promise.all(mainPromises)
            .then(() => {
                this.fetchConfigs().then(() => {
                    this.$Progress.finish();
                    this.isDataLoading = false;
                });
            })
            .catch(error => {
                this.$alert(this.$i18n.t('events.alerts.failedToLoadConfigs'));
                this.$Progress.fail();
                Sentry.captureException(error);
                this.isDataLoading = false;
            });
    },

    methods: {
        ...mapActions(Modules.sinkConfigs, [
            Actions.LOAD_KAFKA_SINK_CONFIGS,
            Actions.LOAD_KAFKA_SINK_CONFIGS_SETTINGS,
            Actions.DELETE_KAFKA_SINK_CONFIG,
        ]),
        fetchConfigs() {
            return this[Actions.LOAD_KAFKA_SINK_CONFIGS]();
        },
    },
};
</script>
