<template>
    <EntityOverview
        :entityType="ENTITY_TYPES.SEGMENT"
        :entity="segment"
        :sectionsEnabled="true"
        @closeOverview="$emit('closeOverview')"
    >
        <div
            :slot="`section-1-header`"
            class="mb-3 block mt-0"
        >
            <OverviewHeaderV2
                v-if="selectedEntityId"
                :entityId="selectedEntityId"
                :entityName="segment.name"
            />
        </div>

        <div
            slot="section-1-content"
            key="1"
        >
            <!-- searchbar-->
            <div class="block">
                <SearchUserInSegment
                    :segment="segment"
                    @search="idSearchResult = $event"
                />

                <div
                    v-if="idSearchResult !== null"
                    v-t="idSearchResult.isFound ? 'segments.identifierIsInSegment' : 'segments.noIdentifierFound'"
                    class="users-found table-title my-3"
                />
            </div>

            <!--reach info-->
            <div
                v-if="stats || stats === 0"
                class="block"
            >
                <div class="reach-stats px-3">
                    <div class="d-flex align-items-center">
                        <AppIcon
                            :type="ICON_TYPES.SIDEBAR_CAMPAIGNS"
                            :color="ICON_COLORS.BLUE_LIGHT"
                            class="mr-3"
                        />
                        <h4 class="font-weight-bold mr-1">
                            {{ statsLabel }}
                        </h4>
                        <span class="body-sm">{{ stats }}</span>
                    </div>
                </div>
            </div>
            <!-- general information block-->
            <AppOverviewBlock
                :isRowTitleCounterVisible="false"
                :popupTitle="segment.name"
                :maxItems="99"
                :maxItemRows="99"
                :items="detailsList"
                class="block"
            />
            <!-- entity tracker-->
            <EntityTracker
                v-if="segment.id"
                :key="segment.entityType"
                :popupTitle="segment.name"
                :entityId="segment.id"
                :entityType="EntityType.SegmentConfig"
                class="block"
            />
            <!-- filters overview-->
            <div
                v-if="isDynamicGenerationType"
                class="block"
            >
                <FiltersRenderer
                    slot="tab1_sectionContent3"
                    :popupTitle="segment.name"
                    :titleIcon="ICON_TYPES.FORK"
                    :isDynamicSegment="isDynamicGenerationType"
                    :filters="filtersForRendering"
                    :conditionDefinitionsById="conditionDefinitionsById"
                    :startRowsFromUppercase="true"
                />
            </div>
            <div
                v-else-if="isTriggerBasedGenerationType"
                class="block"
            >
                <div>
                    <FiltersRenderer
                        slot="tab1_sectionContent3"
                        :title="$t('segments.filters.label.addConditionFilters')"
                        :filters="addConditionsForRendering"
                        :conditionDefinitionsById="conditionDefinitionsById"
                        :titleIcon="ICON_TYPES.FORK"
                        startRowsFromUppercase
                        topLevelCombinator="or"
                    />
                </div>
                <div
                    v-if="removeConditionsForRendering && removeConditionsForRendering.length"
                    class="mt-4"
                >
                    <FiltersRenderer
                        slot="tab1_sectionContent3"
                        :title="$t('segments.filters.label.removeConditionFilters')"
                        :filters="removeConditionsForRendering"
                        :conditionDefinitionsById="conditionDefinitionsById"
                        startRowsFromUppercase
                        topLevelCombinator="or"
                        :titleIcon="ICON_TYPES.FORK"
                    />
                </div>
            </div>
        </div>
    </EntityOverview>
</template>

<script>
// components
import FiltersRenderer from '@/components/partials/filters/FiltersRenderer.vue';
import EntityOverview from '@/components/partials/entityOverview/EntityOverview.vue';
import OverviewHeaderV2 from '@/components/partials/entityOverview/OverviewHeaderV2.vue';
import SearchUserInSegment from '@/__new__/features/segments/SearchUserInSegment.vue';
import AppOverviewBlock from '@/components/partials/AppOverviewBlock.vue';
import EntityTracker from '@/components/entityTracker/EntityTracker.vue';
// helpers
import ENTITY_TYPES from '@/common/entities/entityTypes';
import { EntityType } from '@/components/entityTracker/entityTracker';
import AppIcon from '@/components/partials/icon/AppIcon.vue';
import supportButtonMixin from '@/components/alerts/supportButtonMixin';
import { GENERATION_TYPES_BY_KEYS } from '@/common/segments';
import { generationTypeLabelMap } from '@/__new__/features/segments/common/segmentHelper';
import { ICON_COLORS, ICON_TYPES } from '@/common/iconHelper';

export default {
    name: 'SegmentOverview',
    components: {
        AppOverviewBlock,
        EntityTracker,
        EntityOverview,
        FiltersRenderer,
        OverviewHeaderV2,
        AppIcon,
        SearchUserInSegment,
    },
    mixins: [supportButtonMixin],
    props: {
        segment: { type: Object, required: true },
        stats: {
            type: [String, Number],
            required: true,
            default: '...',
        },
        conditionDefinitionsById: { type: Object, default: () => ({}) },
    },

    data() {
        return {
            ENTITY_TYPES,
            EntityType,
            ICON_COLORS,
            ICON_TYPES,
            idSearchResult: null,
        };
    },
    computed: {
        detailsList() {
            return [
                {
                    name: this.$t('generic.general'),
                    rows: [
                        {
                            name: this.$t('generic.type'),
                            value: generationTypeLabelMap(this.segment.generationType),
                        },
                        {
                            name: this.$t('generic.createdBy'),
                            value: this.segment.createdBy,
                        },
                        {
                            name: this.$t('generic.idType'),
                            value: this.segment.segmentIdTypeLabel,
                        },
                        {
                            name: this.$t('generic.description'),
                            value: this.segment.description,
                        },
                        {
                            name: this.$t('segments.produceEventsEnterLeave'),
                            value: this.segment.produceEventsEnterLeave,
                        },
                    ],
                },
            ];
        },

        filtersForRendering() {
            return this.isDynamicGenerationType && this.selectedEntityId && this.segment.filtersJson;
        },
        addConditionsForRendering() {
            return this.isTriggerBasedGenerationType && this.selectedEntityId && this.segment.addCondition;
        },
        removeConditionsForRendering() {
            return this.isTriggerBasedGenerationType && this.selectedEntityId && this.segment.removeCondition;
        },
        selectedEntityId() {
            return this.segment && this.segment.id;
        },
        isTriggerBasedGenerationType() {
            return this.segment.generationType === generationTypeLabelMap(GENERATION_TYPES_BY_KEYS.TRIGGER_BASED);
        },
        isDynamicGenerationType() {
            return this.segment.generationType === generationTypeLabelMap(GENERATION_TYPES_BY_KEYS.DYNAMIC);
        },
        isStaticFilter() {
            return this.segment.entityType === ENTITY_TYPES.STATIC_FILTER;
        },
        statsLabel() {
            return this.isStaticFilter
                ? this.$i18n.t('rolesAndPermissions.numberOfUsers')
                : this.$i18n.t('campaigns.reach');
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/_consistency';
.block {
    margin-top: $spacing-xl;
    padding-right: $spacing-l;
}
.reach-stats {
    padding-top: $spacing-s;
    padding-bottom: $spacing-s;
    border: 1px solid $gray-200;
    border-radius: $spacing-xs;
}
</style>
