
import Vue from 'vue';

// Components
import AbstractTableTile from '@/__new__/features/customerCareSuite/components/AbstractTableTile.vue';
import OntStatusChecker from '@/__new__/features/customerCare/account/OntStatusChecker.vue';
import ResetSSIDAndPassphraseModal from '@/__new__/features/customerCareSuite/components/ResetSSIDAndPassphraseModal.vue';
import AppButton from '@/components/partials/inputs/AppButton.vue';

// Helpers
import tableColumnType from '@/common/filterTable';
import { USER_MANAGER_HIERARCHY } from '@/__new__/features/customerCare/common/customerCareHelper';
import { getDeviceInfoTypeLabel, DEVICE_INFO_TYPE } from '@/__new__/features/customerCare/common/deviceInfoHelper';
import { isUserAllowed } from '@/services/permissions/permissions.service';
import { groupBy } from 'lodash';

// HTTP
import osstmofiberHTTP from '@/__new__/services/dno/osstmofiber/http/osstmofiber';
import DeviceInfo from '@/__new__/services/dno/osstmofiber/models/DeviceInfo';
import { mapGetters } from 'vuex';
import { Modules } from '@/store/store';
import { Getters } from '@/store/mutation-types';

export default Vue.extend({
    name: 'DeviceInfoTile',
    components: {
        AbstractTableTile,
        OntStatusChecker,
        ResetSSIDAndPassphraseModal,
        AppButton,
    },
    data() {
        return {
            deviceInfo: [],
            resetModalValue: false,

            // proxy
            DEVICE_INFO_TYPE,
            USER_MANAGER_HIERARCHY,
            isUserAllowed,

            // table
            columnsData: [
                {
                    name: this.$i18n.t('customerCareSuite.deviceInfoTile.deviceType'),
                    key: 'deviceType',
                    field: 'deviceType',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('customerCareSuite.deviceInfoTile.deviceModel'),
                    key: 'model',
                    field: 'model',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('customerCareSuite.deviceInfoTile.serialNumber'),
                    key: 'serialNumber',
                    field: 'serialNumber',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('customerCareSuite.deviceInfoTile.macAddress'),
                    key: 'macAddress',
                    field: 'macAddress',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('customerCareSuite.deviceInfoTile.staticIp'),
                    key: 'staticIpAddresses',
                    formatter: this.staticIpAddressesFormatted,
                    field: 'staticIpAddresses',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
            ],
        };
    },
    computed: {
        ...mapGetters(Modules.customerCareSuite, [Getters.GET_ASSOCIATED_UM_ENTITY_BY_TYPE_AND_ID]),
        accountData() {
            return this[Getters.GET_ASSOCIATED_UM_ENTITY_BY_TYPE_AND_ID]({
                targetType: USER_MANAGER_HIERARCHY.ACCOUNT,
                targetId: this.$route.params.id,
            });
        },
        isUpdatingAllowed() {
            return isUserAllowed('UMAccountUpdateWIFICredentials') && this.accountData?.providerId === 'sifi';
        },
    },
    mounted() {
        this.fetchTileData();
    },
    methods: {
        fetchTileData() {
            this.$emit('isDataLoadingUpd', true);
            this.$withProgressBar(
                async () => {
                    const deviceInfoResponse = await osstmofiberHTTP.getDeviceInfo(
                        this.$route?.params?.id,
                        USER_MANAGER_HIERARCHY.ACCOUNT,
                    );

                    if (deviceInfoResponse?.data?.device_info) {
                        this.deviceInfo = deviceInfoResponse.data.device_info?.map(info => new DeviceInfo(info));
                    }

                    this.$emit('isDataLoadingUpd', false);
                },
                {
                    errorHandler: (e: any) => {
                        this.deviceInfoApiResponse = e.response;
                        this.$alert(this.$i18n.t('alertMessage.userManagement.somethingWentWrongFetchingDeviceData'));
                        this.$emit('isDataLoadingUpd', false);
                    },
                },
            );
        },
        deviceInfoTypeLabel(type) {
            return this.$i18n.t(getDeviceInfoTypeLabel(type));
        },
        staticIpAddressesFormatted(staticIpAddresses) {
            if (Array.isArray(staticIpAddresses)) {
                const result = groupBy(staticIpAddresses, el => el.protocol);
                const ipv4String = result?.IPv4?.length
                    ? `IPv4: ${result.IPv4.map(el => el.ip_address).join(', ')}`
                    : '';
                const ipv6String = result?.IPv6?.length
                    ? `IPv6: ${result.IPv6.map(el => el.ip_address).join(', ')}`
                    : '';
                return [ipv4String, ipv6String].filter(Boolean).join('; ');
            }
            return '';
        },
    },
});
