
import Vue from 'vue';
// Vue components
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import AppHeader from '@/components/layout/AppHeader.vue';
import AppMultiselectV3 from '@/components/partials/inputs/AppMultiselectV3.vue';
import AppPaginationLoadMore from '@/components/partials/AppPaginationLoadMore.vue';
import AppTable, { tableSizes } from '@/components/partials/AppTable.vue';
import Breadcrumbs from '@/components/partials/Breadcrumbs.vue';
import FilterTable from '@/components/partials/FilterTable.vue';
import IconButton from '@/components/partials/IconButton.vue';
import ResponseModalButton from '@/components/partials/ResponseModalButton.vue';
import SearchBox from '@/components/partials/inputs/SearchBox.vue';
import TableFiltersRenderless from '@/components/filters/TableFiltersRenderless.vue';
import TableFiltersTags from '@/components/filters/TableFiltersTags.vue';

// helpers
import { compact } from 'lodash';
import tableColumnType, { type TableColumn } from '@/common/filterTable';
import { ICON_TYPES } from '@/common/iconHelper';
import {
    getAddressSearchFieldsOrder,
    getQuerySearchFilterParams,
    type UserSearchResult,
    type UserSearchResultMapped,
} from '@/__new__/features/customerCare/common/userInfoHelper';

// HTTP
import userManagementHTTP from '@/__new__/services/dno/user/http/user-management';
import RouteNames from '@/router/routeNames';
import { getOperatorConfigValue } from '@/services/permissions/permissions.service';

export default Vue.extend({
    name: 'CustomerCareUserSearchResults',
    components: {
        AppButton,
        AppHeader,
        AppMultiselectV3,
        AppPaginationLoadMore,
        AppTable,
        Breadcrumbs,
        FilterTable,
        IconButton,
        ResponseModalButton,
        SearchBox,
        TableFiltersRenderless,
        TableFiltersTags,
    },
    props: {
        defaultPageSize: {
            type: Number,
            default: 25,
        },
    },
    data() {
        return {
            addressFields: getAddressSearchFieldsOrder() as ReturnType<typeof getAddressSearchFieldsOrder>,
            apiResponse: null as any,
            BUTTON_TYPES,
            ICON_TYPES,
            searchQuery: '',
            users: [] as UserSearchResultMapped[],
            userRoute: RouteNames.CCS_USER_PAGE,
            isDataLoading: false,
            pageNum: 0,
            pageAmountOptions: tableSizes,
            pageAmount: tableSizes.find(o => o.value === this.defaultPageSize),
        };
    },
    computed: {
        breadcrumbList() {
            return [
                {
                    name: this.$i18n.t('customerCare.breadcrumbs.search'),
                    link: '/customer-care-suite/',
                },
                {
                    name: this.$i18n.t('customerCare.breadcrumbs.searchResults'),
                },
            ];
        },
        columnsData() {
            const cols: TableColumn[] = [
                {
                    name: this.$i18n.t('customerCare.userInformation.userId'),
                    key: 'userId',
                    field: 'userId',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('customerCare.userInformation.firstName'),
                    key: 'firstName',
                    field: 'firstName',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('customerCare.userInformation.lastName'),
                    key: 'lastName',
                    field: 'lastName',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('customerCare.userInformation.email'),
                    key: 'email',
                    field: 'email',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('customerCare.userInformation.phoneNumber'),
                    key: 'phoneNumber',
                    field: 'phoneNumber',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('customerCare.userInformation.address'),
                    key: 'address',
                    field: 'address',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('customerCare.userInformation.orderPlaced'),
                    key: 'orderPlaced',
                    field: 'orderPlaced',
                    filterType: tableColumnType.TEXT_LIMITED_OPTIONS,
                    limitedOptions: [this.$i18n.t('generic.yes'), this.$i18n.t('generic.no')],
                },
            ];

            if (this.isTerminatedColumnEnabled) {
                cols.push({
                    name: this.$i18n.t('generic.stateMap.terminated'),
                    key: 'isTerminated',
                    field: 'isTerminated',
                    filterType: tableColumnType.TEXT_LIMITED_OPTIONS,
                    limitedOptions: [this.$i18n.t('generic.yes'), this.$i18n.t('generic.no')],
                });
            }

            if (this.isUserCreatedColumnEnabled) {
                cols.push({
                    name: this.$i18n.t('customerCare.userInformation.userCreated'),
                    key: 'userCreatedFormatted',
                    sortBy: entity => entity.userCreated,
                    field: 'userCreated',
                    filterType: tableColumnType.DATE,
                });
            }

            return cols;
        },
        hasMorePages(): boolean {
            return !this.isDataLoading && this.users.length >= this.pageSize;
        },
        isTerminatedColumnEnabled() {
            return getOperatorConfigValue('userSearchShowTerminated', false);
        },
        isUserCreatedColumnEnabled() {
            return getOperatorConfigValue('userSearchShowUserCreated', false);
        },
        pageSize(): number {
            return Number(this.pageAmount?.value) || this.defaultPageSize;
        },
    },
    created() {
        this.fetchUserData();
    },
    methods: {
        async fetchUserData() {
            await this.$withProgressBar(
                async () => {
                    this.isDataLoading = true;
                    const params = {
                        ...getQuerySearchFilterParams(this.$route.params),
                        pageNum: this.pageNum,
                        pageSize: this.pageSize,
                    };

                    this.apiResponse = await userManagementHTTP.getUsersByReverseIndex(params);

                    const { users } = this.apiResponse.data;

                    this.users = Array.isArray(users) ? this.mapUsers(users) : [];
                    this.isDataLoading = false;
                },
                {
                    errorHandler: () => {
                        this.isDataLoading = false;
                        this.$alert(
                            this.$i18n.t('alertMessage.userManagement.somethingWentWrongFetchingUserProfileData'),
                        );
                    },
                },
            );
        },
        mapUsers(users: UserSearchResult[]): UserSearchResultMapped[] {
            return users.map(user => ({
                userId: user.user_id,
                firstName: user.properties?.first_name,
                lastName: user.properties?.last_name,
                email: user.notification_info?.emails.join(', '),
                phoneNumber: user.notification_info?.msisdns.join(', '),
                isTerminated: user.flags?.is_user_terminated ? this.$i18n.t('generic.yes') : this.$i18n.t('generic.no'),
                address: user.addresses
                    .map(address => compact(this.addressFields.map(field => address[field])).join(' '))
                    .join(', '),
                userCreated: user.general_info?.user_create_time,
                userCreatedFormatted: this.$localeLibrary.getFormattedDateAndTimeWithSecondsPrecision(
                    user.general_info?.user_create_time,
                ),
                orderPlaced: user.properties?.order_creation_time?.length
                    ? this.$i18n.t('generic.yes')
                    : this.$i18n.t('generic.no'),
            }));
        },
        onCancel() {
            this.$router.push({
                name: RouteNames.CCS_SEARCH,
                params: { companyId: this.$route.params.companyId },
            });
        },
        updatePageSize() {
            this.pageNum = 0;
            this.fetchUserData();
        },
    },
});
