<template>
    <div>
        <div
            v-t="'charging.chargingSpecifications.editor.zone'"
            class="table-title"
        />
        <ZoneDetails :zones="zones" />
    </div>
</template>

<script>
import zoneParser from '@/__new__/services/dno/charging/common/pcConditionsParser/zoneParser';
import ZoneDetails from '@/__new__/features/charging/conditionParameters/conditionsValues/ZoneDetails.vue';

export default {
    name: 'ZoneValue',
    components: {
        ZoneDetails,
    },
    props: {
        value: {
            type: Object,
            required: true,
        },
    },
    computed: {
        zones() {
            return zoneParser(null, this.value).data;
        },
    },
};
</script>
