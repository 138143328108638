
// components
import CollapsibleItem from '@/components/partials/CollapsibleItem.vue';
import AppIcon from '@/components/partials/icon/AppIcon.vue';

// helpers
import { ICON_COLORS, ICON_TYPES } from '@/common/iconHelper';
import { TranslateResult } from 'vue-i18n';

// types
export type APIControlItem = {
    description: TranslateResult;
    label: TranslateResult;
    value: boolean;
};

export default {
    name: 'AppCheckboxCardViewList',
    components: {
        AppIcon,
        CollapsibleItem,
    },
    props: {
        title: {
            type: String,
            required: false,
            default: '',
        },
        items: {
            type: Array,
            required: true,
            default: () => [],
        },
    },
    data() {
        return {
            ICON_TYPES,
            ICON_COLORS,
        };
    },
};
