<template>
    <div v-if="isUserIdUrlParamPresent">
        <div class="container-fluid wrapper-container background-color">
            <AccountDocModal
                v-show="accountDocModalVisible"
                :imageUrl="accountDocImageUrl"
                @close="onAccountDocModalClosed"
            />
            <AppHeader :pageTitle="$i18n.t('customerCare.userProfile')">
                <template #headerButtons>
                    <AppButton
                        v-if="isEditEnabled"
                        :buttonType="BUTTON_TYPES.SECONDARY"
                        :iconType="ICON_TYPES.EDIT"
                        :label="$i18n.t('customerCare.userInformation.editUser')"
                        :disabled="!isEditAllowed"
                        @click="showEditor"
                    />
                    <AppButton
                        v-if="isNewCustomerCareEnabled"
                        :buttonType="BUTTON_TYPES.PRIMARY"
                        :iconType="ICON_TYPES.DATA"
                        :label="$i18n.t('customerCareSuite.tryNewCustomerCare')"
                        class="mx-2"
                        @click="newCustomerCare"
                    />
                </template>
            </AppHeader>

            <UserAuthenticationBanner
                v-if="!isEditAllowed"
                :userId="user.id"
            />

            <Breadcrumbs
                :breadcrumbList="breadcrumbList"
                class="ml-4"
            />

            <AppCustomerHeader :infoData="infoData">
                <template #msisdn>
                    <LabelWithVerificationStatus
                        v-for="(item, k) in user.notificationIdentifiers.filter(
                            i => i.identifier_type === USER_VERIFICATION_ID_TYPES.MSISDN,
                        )"
                        :key="k"
                        :label="item.identifier"
                        :status="item.verified"
                    />
                </template>

                <template #email>
                    <LabelWithVerificationStatus
                        v-for="(item, k) in user.notificationIdentifiers.filter(
                            i => i.identifier_type === USER_VERIFICATION_ID_TYPES.EMAIL,
                        )"
                        :key="k"
                        :label="item.identifier"
                        :status="item.verified"
                    />
                </template>
            </AppCustomerHeader>

            <div
                v-if="userAuthenticationEnabled"
                class="section-layout mt-5"
            >
                <UserAuthentication />
            </div>

            <div
                v-if="isSessionsEnabled"
                class="section-layout"
            >
                <TableFiltersRenderless :entities="sessions">
                    <template
                        #default="{
                            // state
                            allFilters,
                            filteredEntities,
                            // methods
                            onFilterAdded,
                            removeFilter,
                            removeAllFilters,
                        }"
                    >
                        <div>
                            <div class="d-flex justify-content-between align-items-center position-relative">
                                <div class="table-title">
                                    {{ $i18n.t('customerCare.userInformation.loggedInDevices') }}
                                </div>
                                <div class="d-flex align-items-center">
                                    <SearchBox
                                        v-model="searchQueryDeviceTable"
                                        class="search-box"
                                        :smallSearchIcon="true"
                                    />
                                    <ResponseModalButton
                                        :response="sessionsApiResponse"
                                        :title="$i18n.t('customerCare.userInformation.loggedInDevices')"
                                    />
                                    <FilterTable
                                        :columns="tableDeviceColumnsData"
                                        :multiselectWidth="{ width: '12rem' }"
                                        @filterAdded="onFilterAdded"
                                    >
                                        <template #button="slotProps">
                                            <IconButton
                                                :label="$i18n.t('generic.filter')"
                                                :icon="ICON_TYPES.FILTER"
                                                @iconClick="slotProps.clickHandler"
                                            />
                                        </template>
                                    </FilterTable>

                                    <IconButton
                                        :label="$i18n.t('customerCare.userInformation.logOutAll')"
                                        :icon="ICON_TYPES.LOGOUT"
                                        :disabled="!haveActiveSessions"
                                        data-test-id="end-session-all-button-id"
                                        @iconClick="onLogoutAllClicked(user.id)"
                                    />
                                </div>
                            </div>
                            <TableFiltersTags
                                :filterTableMixin="{ allFilters }"
                                class="mt-1 mb-3"
                                @removeFilter="removeFilter"
                                @removeAllFilters="removeAllFilters"
                            />

                            <AppTable
                                :entities="filteredEntities"
                                :isDefaultHoverEnabled="false"
                                :isPaginationEnabled="true"
                                :canSelectColumns="true"
                                :newDesign="true"
                                :columnsData="tableDeviceColumnsData"
                                :isSearchEnabled="true"
                                :innerSearchQuery="searchQueryDeviceTable"
                                tableKey="customer-care-new/logged-devices"
                            >
                                <template #state="{ entity }">
                                    <DeviceStatusIndicator :status="entity.state" />
                                </template>

                                <template #customRowButtons="{ entity }">
                                    <IconButton
                                        :label="$i18n.t('generic.logOut')"
                                        :icon="ICON_TYPES.LOGOUT"
                                        :disabled="entity.state !== DEVICE_STATE.ACTIVE"
                                        data-test-id="end-session-one-device"
                                        @iconClick="onLogoutSessionClicked(entity.userId, entity.id)"
                                    />
                                </template>
                            </AppTable>
                        </div>
                    </template>
                </TableFiltersRenderless>
            </div>

            <UserCredentialChangeHistory
                v-if="isCredentialsHistoryEnabled"
                class="section-layout"
            />

            <div class="section-layout">
                <TableFiltersRenderless :entities="user.permissions">
                    <template
                        #default="{
                            // state
                            allFilters,
                            filteredEntities,
                            // methods
                            onFilterAdded,
                            removeFilter,
                            removeAllFilters,
                        }"
                    >
                        <div>
                            <div class="d-flex justify-content-between align-items-center position-relative">
                                <div class="table-title">
                                    {{ $i18n.t('customerCare.userInformation.hasAccessTo') }}
                                </div>

                                <div class="d-flex align-items-center">
                                    <SearchBox
                                        v-model="searchQueryHasAccessTable"
                                        class="search-box"
                                        :smallSearchIcon="true"
                                    />
                                    <ResponseModalButton
                                        :response="userInfoApiResponse"
                                        :title="$i18n.t('customerCare.userInformation.hasAccessTo')"
                                    />
                                    <FilterTable
                                        :columns="tableHasAccessColumnsData"
                                        :multiselectWidth="{ width: '12rem' }"
                                        @filterAdded="onFilterAdded"
                                    >
                                        <template #button="slotProps">
                                            <IconButton
                                                :label="$i18n.t('generic.filter')"
                                                :icon="ICON_TYPES.FILTER"
                                                @iconClick="slotProps.clickHandler"
                                            />
                                        </template>
                                    </FilterTable>
                                </div>
                            </div>
                            <TableFiltersTags
                                :filterTableMixin="{ allFilters }"
                                class="mt-1 mb-3"
                                @removeFilter="removeFilter"
                                @removeAllFilters="removeAllFilters"
                            />

                            <AppTable
                                :entities="filteredEntities"
                                :isDefaultHoverEnabled="false"
                                :isPaginationEnabled="true"
                                :canSelectColumns="true"
                                :newDesign="true"
                                :columnsData="tableHasAccessColumnsData"
                                :isSearchEnabled="true"
                                :innerSearchQuery="searchQueryHasAccessTable"
                                tableKey="customer-care-new/has-access-to"
                            >
                                <template #targetId="{ entity }">
                                    <div
                                        v-if="entity.targetId"
                                        class="link"
                                        @click="goToRoute(getBreadcrumbPath(entity.targetTypeId, entity.targetId))"
                                    >
                                        {{ entity.targetId }}
                                    </div>
                                    <i v-else>
                                        {{ $i18n.t('generic.empty') }}
                                    </i>
                                </template>
                            </AppTable>
                        </div>
                    </template>
                </TableFiltersRenderless>
            </div>

            <AssociatedRoles
                v-if="isLFAdmin"
                :id="$route.params.id"
                :idType="USER_MANAGER_HIERARCHY.USER"
                :roles="user.permissions"
                :apiResponse="userInfoApiResponse"
                class="section-layout"
            />

            <GrantPermission
                v-if="userGrantPermissionEnabled"
                :userId="$route.params.id"
                class="section-layout"
            />

            <NotesSection
                v-if="isNotesEnabled && user.id"
                :idType="USER_MANAGER_HIERARCHY.USER"
                :entities="user.ownedResources"
                class="section-layout"
            />

            <div
                v-if="isVoucherFeaturesAvailable"
                class="section-layout"
            >
                <div class="component-title-um mb-2">
                    {{ $i18n.t('customerCare.voucherHistory.voucherHistory') }}
                </div>

                <VoucherHistory
                    :targetId="$route.params.id"
                    :targetType="USER_MANAGER_HIERARCHY.USER"
                />
            </div>

            <div
                v-if="isMessageHistoryEnabled"
                class="section-layout"
                data-test-id="user-message-history-section"
            >
                <div class="component-title-um mb-2">
                    {{ $i18n.t('customerCare.messageHistory') }}
                </div>

                <UserMessageHistory
                    :id="$route.params.id"
                    :idType="USER_MANAGER_HIERARCHY.USER"
                />
            </div>

            <RewardsHistory
                v-if="rewardsHistoryUserProfileEnabled"
                :id="$route.params.id"
                :idType="USER_MANAGER_HIERARCHY.USER"
            />

            <UserProfileEditor
                :key="isEditorVisible"
                v-model="isEditorVisible"
                :entity="user"
                @saveSuccess="refreshUserData"
            />
            <div
                v-if="isGroupsEnabled"
                class="section-layout"
            >
                <div class="table-title">
                    {{ $i18n.t('account.groups') }}
                </div>
                <GroupTile :overrideUserData="userInfo" />
            </div>
        </div>
    </div>
</template>

<script>
// COMPONENTS
import AppHeader from '@/components/layout/AppHeader.vue';
import Breadcrumbs from '@/components/partials/Breadcrumbs.vue';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import { ICON_TYPES } from '@/common/iconHelper';
import AppTable from '@/components/partials/AppTable.vue';
import AppCustomerHeader from '@/components/partials/AppCustomerHeader.vue';
import DeviceStatusIndicator from '@/components/partials/DeviceStatusIndicator.vue';
import GrantPermission from '@/__new__/features/customerCare/user/GrantPermission.vue';
import UserProfileEditor from '@/__new__/features/customerCare/user/UserProfileEditor.vue';
import IconButton from '@/components/partials/IconButton.vue';
import SearchBox from '@/components/partials/inputs/SearchBox.vue';
import AccountDocModal from '@/__new__/features/customerCare/user/AccountDocModal.vue';
import NotesSection from '@/__new__/features/customerCare/NotesSection.vue';
import TableFiltersRenderless from '@/components/filters/TableFiltersRenderless.vue';
import TableFiltersTags from '@/components/filters/TableFiltersTags.vue';
import FilterTable from '@/components/partials/FilterTable.vue';
import VoucherHistory from '@/__new__/features/customerCare/VoucherHistory.vue';
import UserAuthentication from '@/__new__/features/customerCare/user/UserAuthentication.vue';
import UserAuthenticationBanner from '@/__new__/features/customerCare/UserAuthenticationBanner.vue';
import UserCredentialChangeHistory from '@/__new__/features/customerCare/user/UserCredentialChangeHistory.vue';
import UserMessageHistory from '@/__new__/features/customerCare/UserMessageHistory.vue';
import RewardsHistory from '@/__new__/features/customerCare/RewardsHistory.vue';
import AssociatedRoles from '@/__new__/features/customerCare/AssociatedRoles.vue';
import ResponseModalButton from '@/components/partials/ResponseModalButton.vue';
import LabelWithVerificationStatus from '@/__new__/features/customerCare/user/LabelWithVerificationStatus.vue';
import GroupTile from '@/__new__/features/customerCareSuite/components/GroupTile.vue';

// HELPERS
import { mapActions, mapGetters } from 'vuex';
import User from '@/__new__/services/dno/user/models/User';
import {
    EKYC_STATUS_TO_I18N_LABEL,
    handleUserNotFoundError,
    USER_MANAGER_HIERARCHY,
    USER_VERIFICATION_ID_TYPES,
} from '@/__new__/features/customerCare/common/customerCareHelper';
import Flag, { FLAG_TYPE, FLAG_TC_GLOBAL_ACCEPTED_TEXT } from '@/__new__/services/dno/user/models/Flag';
import { getConsentsContextTypeI18nKey } from '@/__new__/features/customerCare/common/consentsHelper';
import * as Sentry from '@sentry/vue';
import { ALERT_TYPES } from '@/common/alerts/Alert';
import { isEmpty } from 'lodash';
import permissionsService, {
    getEkycConfig,
    getUserConsentsDefault,
    getUserDetailFields,
    isUserAllowed,
    isViewEnabled,
    getOperatorConfigValue,
} from '@/services/permissions/permissions.service';
import { ASSET_TYPE } from '@/__new__/features/customerCare/common/userInfoHelper';
import { onlyFirstLetterUppercase } from '@/common/utils';
import luaErrors from '@/common/luaErrors';
import tableColumnType from '@/common/filterTable';
import { LABEL_COLOR } from '@/common/labelsHelper';
import { USER_DETAIL_FIELD } from '@/__new__/features/customerCare/common/detailFieldsHelper';
import { DEVICE_STATE } from '@/__new__/services/dno/user/models/Session';

// HTTP
import getAssetUrl from '@/http/assets';
import RouteNames from '@/router/routeNames';

import ordersHTTP from '@/__new__/services/dno/orders/http/orders';

export default {
    name: 'UserProfile',
    components: {
        AppHeader,
        Breadcrumbs,
        AppButton,
        AppTable,
        AppCustomerHeader,
        AssociatedRoles,
        DeviceStatusIndicator,
        GrantPermission,
        UserProfileEditor,
        UserAuthenticationBanner,
        IconButton,
        SearchBox,
        AccountDocModal,
        NotesSection,
        TableFiltersRenderless,
        TableFiltersTags,
        FilterTable,
        LabelWithVerificationStatus,
        VoucherHistory,
        UserAuthentication,
        UserCredentialChangeHistory,
        UserMessageHistory,
        RewardsHistory,
        ResponseModalButton,
        GroupTile,
    },
    data() {
        return {
            ICON_TYPES,
            BUTTON_TYPES,
            entities: [],
            user: new User({}),
            isEditorVisible: false,
            DEVICE_STATE,
            USER_MANAGER_HIERARCHY,
            USER_VERIFICATION_ID_TYPES,
            searchQueryDeviceTable: '',
            searchQueryHasAccessTable: '',
            isSessionsEnabled:
                permissionsService.userManagementSessionsEnabled() && isUserAllowed('UMUserLoggedInDevicesRead'),
            isCredentialsHistoryEnabled:
                permissionsService.userManagementCredsHistoryEnabled() &&
                isViewEnabled('UMUserCredentialChangeHistory'),
            isEditEnabled: isUserAllowed(
                'UMUserEditUserUpdateFields',
                'UMUserEditUserToggleFlag',
                'UMUserEditUserVerificationIdClear',
                'UMUserEditUserVerificationIdSet',
            ),
            isGrantPermissionEnabled: isUserAllowed('UMUserGrantPermission'),
            isLFAdmin: permissionsService.isUserLotusFlareAdmin(),
            isNotesEnabled: permissionsService.userManagementCRMEnabled() && isUserAllowed('UMUserAgentNotesRead'),
            isVoucherFeaturesAvailable:
                permissionsService.userVoucherEnabled() && isUserAllowed('UMUserVoucherHistoryRead'),
            isEkycEnabled: permissionsService.userManagementEKYCEnabled() && getEkycConfig(),
            isMessageHistoryEnabled: permissionsService.isCepReadRole() && isUserAllowed('UMUserMessageHistoryRead'),
            userAuthenticationEnabled:
                permissionsService.userManagementUserAuthenticationEnabled() &&
                isViewEnabled('UMUserUserAuthentication'),
            rewardsHistoryUserProfileEnabled:
                permissionsService.rewardsHistoryUserProfileEnabled() && isUserAllowed('UMUserRewardsHistoryRead'),
            userGrantPermissionEnabled:
                permissionsService.userManagementUserGrantPermissionsEnabled() &&
                isUserAllowed('UMUserGrantPermission'),
            isNewCustomerCareEnabled: getOperatorConfigValue('customerCareOptIn', false),
            isGroupsEnabled: permissionsService.userManagementUserGroupsEnabled() && isUserAllowed('UMUserGroupsRead'),
            accountDocImageUrl: null,
            accountDocModalVisible: false,
            consentsDefault: getUserConsentsDefault(),
            userDetailFields: getUserDetailFields(),
            terminationData: '',
        };
    },
    computed: {
        ...mapGetters('userManagementUser', {
            getUserInfoById: 'getUserInfoById',
            getUserInfoApiResponseById: 'getUserInfoApiResponseById',
            getSessionsById: 'getSessionsById',
            getSessionsApiResponseById: 'getSessionsApiResponseById',
            getEkycStatusById: 'getEkycStatusById',
            getAllConsents: 'getAllConsents',
            getUserVerificationStatusById: 'getUserVerificationStatusById',
        }),
        isUserIdUrlParamPresent() {
            return !!this.$route.params.id;
        },
        userInfo() {
            return this.$route.params.id ? this.getUserInfoById(this.$route.params.id) : {};
        },
        userInfoApiResponse() {
            return this.$route.params.id ? this.getUserInfoApiResponseById(this.$route.params.id) : null;
        },
        sessions() {
            return this.$route.params.id ? this.getSessionsById(this.$route.params.id) : [];
        },
        sessionsApiResponse() {
            return this.$route.params.id ? this.getSessionsApiResponseById(this.$route.params.id) : null;
        },
        isVerified() {
            return this.$route.params.id ? this.getUserVerificationStatusById(this.$route.params.id) : false;
        },
        isEditAllowed() {
            return !this.userAuthenticationEnabled || this.isVerified;
        },
        isConsentsEnabled() {
            return (
                permissionsService.userManagementConsentsEnabled() &&
                (this.isDetailFieldEnabled(USER_DETAIL_FIELD.CONSENTS_ACCEPTED) ||
                    this.isDetailFieldEnabled(USER_DETAIL_FIELD.CONSENTS_ALL))
            );
        },
        ekycStatus() {
            return this.$route.params.id ? this.getEkycStatusById(this.$route.params.id) : '';
        },
        consents() {
            return this.getAllConsents || [];
        },
        breadcrumbList() {
            return [
                {
                    name: this.$i18n.t('customerCare.breadcrumbs.search'),
                    link: '/customer-care/user-management',
                },
                {
                    name: this.$i18n.t('customerCare.breadcrumbs.userProfile'),
                },
            ];
        },
        tableDeviceColumnsData() {
            return [
                {
                    name: this.$i18n.t('customerCare.userInformation.deviceID'),
                    key: 'id',
                    field: 'id',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('customerCare.userInformation.lastValidVersion'),
                    key: 'lastValidVersion',
                    field: 'lastValidVersion',
                    filterType: tableColumnType.NUMBER,
                },
                {
                    name: this.$i18n.t('generic.status'),
                    key: 'state',
                    field: 'stateString',
                    filterType: tableColumnType.TEXT_LIMITED_OPTIONS,
                    limitedOptions: [...new Set(this.sessions.map(entity => entity.stateString))],
                },
            ];
        },
        tableHasAccessColumnsData() {
            return [
                {
                    name: this.$i18n.t('generic.id'),
                    key: 'targetId',
                    field: 'targetId',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('generic.type'),
                    key: 'targetType',
                    field: 'targetType',
                    filterType: tableColumnType.TEXT_LIMITED_OPTIONS,
                    limitedOptions: [...new Set(this.user.permissions.map(entity => entity.targetType))],
                },
                {
                    name: this.$i18n.t('generic.role'),
                    key: 'roleName',
                    field: 'roleName',
                    filterType: tableColumnType.TEXT_LIMITED_OPTIONS,
                    limitedOptions: [...new Set(this.user.permissions.map(entity => entity.roleName))],
                },
            ];
        },
        infoData() {
            const infoData = [
                {
                    title: this.$i18n.t('customerCare.basicInfo'),
                    value: this.basicInfo,
                    initiallyExpanded: true,
                },
            ];

            if (this.contactInfo.length) {
                infoData.push({
                    title: this.$i18n.t('customerCare.userInformation.contactInfo'),
                    value: this.contactInfo,
                });
            }

            if (this.kycInfo.length) {
                infoData.push({
                    title: this.$i18n.t('customerCare.userInformation.kyc'),
                    value: this.kycInfo,
                });
            }

            if (this.consentInfo.length) {
                infoData.push({
                    title: this.$i18n.t('customerCare.userInformation.consents'),
                    value: this.consentInfo,
                });
            }

            if (this.flagInfo.length) {
                infoData.push({
                    title: this.$i18n.t('customerCare.flags'),
                    value: this.flagInfo,
                    additionalLabels: this.flagStatusIndicators,
                });
            }

            return infoData;
        },
        basicInfo() {
            const data = [];

            if (this.isDetailFieldEnabled(USER_DETAIL_FIELD.USER_ID)) {
                data.push({
                    name: this.$i18n.t('customerCare.userInformation.userId'),
                    value: this.user.id,
                });
            }

            if (this.isDetailFieldEnabled(USER_DETAIL_FIELD.FIRST_NAME)) {
                data.push({
                    name: this.$i18n.t('customerCare.userInformation.firstName'),
                    value: this.user.name,
                });
            }

            if (this.isDetailFieldEnabled(USER_DETAIL_FIELD.LAST_NAME)) {
                data.push({
                    name: this.$i18n.t('customerCare.userInformation.lastName'),
                    value: this.user.surname,
                });
            }

            if (this.isDetailFieldEnabled(USER_DETAIL_FIELD.MIDDLE_NAME)) {
                data.push({
                    name: this.$i18n.t('customerCare.userInformation.middleName'),
                    value: this.user.middleName,
                });
            }

            if (this.isDetailFieldEnabled(USER_DETAIL_FIELD.MOTHERS_MAIDEN_NAME)) {
                data.push({
                    name: this.$i18n.t('customerCare.userInformation.mothersMaidenName'),
                    value: this.user.mothersMaidenName,
                });
            }

            if (this.isDetailFieldEnabled(USER_DETAIL_FIELD.VERIFICATION_STATUS)) {
                const verificationStatus = this.user.notificationIdentifiers.find(item => item.isDefault);

                data.push({
                    name: this.$i18n.t('customerCare.userInformation.verificationStatus'),
                    value: verificationStatus?.verified
                        ? this.$i18n.t('generic.verified')
                        : this.$i18n.t('generic.notVerified'),
                });
            }

            if (this.isDetailFieldEnabled(USER_DETAIL_FIELD.CREDENTIALS)) {
                this.user.creds.forEach(({ id, id_type: idType }) => {
                    if (!['msisdn', 'facebook'].includes(idType.toLowerCase())) {
                        data.push({
                            name: onlyFirstLetterUppercase(idType.replace(/_/g, ' ')),
                            value: id,
                        });
                    }
                });
            }

            if (this.isDetailFieldEnabled(USER_DETAIL_FIELD.FACEBOOK)) {
                data.push({
                    name: this.$i18n.t('customerCare.userInformation.facebook'),
                    value: this.user.facebook,
                });
            }

            if (this.isDetailFieldEnabled(USER_DETAIL_FIELD.CREATION_DATE)) {
                data.push({
                    name: this.$i18n.t('customerCare.userInformation.creationDate'),
                    value: this.user.creationDate,
                });
            }

            if (this.isDetailFieldEnabled(USER_DETAIL_FIELD.LAST_ACTIVE)) {
                data.push({
                    name: this.$i18n.t('customerCare.userInformation.lastActive'),
                    value: this.user.lastActiveDate,
                });
            }

            if (this.isDetailFieldEnabled(USER_DETAIL_FIELD.CARDINALITY)) {
                data.push({
                    name: this.$i18n.t('customerCare.userInformation.cardinality'),
                    value: this.user.cardinality,
                });
            }
            if (this.isDetailFieldEnabled(USER_DETAIL_FIELD.TERMINATION_DATE)) {
                data.push({
                    name: this.$i18n.t('customerCare.userInformation.terminationDate'),
                    value: this.terminationData,
                });
            }

            return data;
        },
        contactInfo() {
            const data = [];

            if (
                this.isDetailFieldEnabled(USER_DETAIL_FIELD.MSISDN) ||
                this.isDetailFieldEnabled(USER_DETAIL_FIELD.MSISDN_WITH_VERIFY_STATUS)
            ) {
                data.push({
                    name: this.$i18n.t('customerCare.msisdn'),
                    value: this.user.msisdn?.replace(/,/g, ', '),
                    ...(this.isDetailFieldEnabled(USER_DETAIL_FIELD.MSISDN_WITH_VERIFY_STATUS) && {
                        key: USER_VERIFICATION_ID_TYPES.MSISDN,
                    }),
                });
            }

            if (
                this.isDetailFieldEnabled(USER_DETAIL_FIELD.EMAIL) ||
                this.isDetailFieldEnabled(USER_DETAIL_FIELD.EMAIL_WITH_VERIFY_STATUS)
            ) {
                data.push({
                    name: this.$i18n.t('customerCare.userInformation.email'),
                    value: this.user.notificationEmail,
                    ...(this.isDetailFieldEnabled(USER_DETAIL_FIELD.EMAIL_WITH_VERIFY_STATUS) && {
                        key: USER_VERIFICATION_ID_TYPES.EMAIL,
                    }),
                });
            }

            if (!this.isEkycEnabled && this.isDetailFieldEnabled(USER_DETAIL_FIELD.BIRTHDAY)) {
                data.push({
                    name: this.$i18n.t('customerCare.userInformation.birthday'),
                    value: this.user.birthday || '',
                });
            }

            return data;
        },
        kycInfo() {
            const data = [];

            if (!this.isEkycEnabled) {
                if (this.isDetailFieldEnabled(USER_DETAIL_FIELD.DOCUMENT)) {
                    data.push({
                        name: this.$i18n.t('customerCare.userInformation.document'),
                        value: [
                            {
                                text: this.user.documentType,
                                link: this.user.documentSrc,
                            },
                        ],
                    });
                }

                if (this.isDetailFieldEnabled(USER_DETAIL_FIELD.DOCUMENT_NUMBER)) {
                    data.push({
                        name: this.$i18n.t('customerCare.userInformation.documentNumber'),
                        value: this.user.documentNumber,
                        onClick:
                            !!this.accountDocImageUrl &&
                            (() => {
                                this.accountDocModalVisible = true;
                            }),
                    });
                }
            } else {
                if (this.isDetailFieldEnabled(USER_DETAIL_FIELD.EKYC_STATUS)) {
                    data.push({
                        name: this.$i18n.t('generic.status'),
                        value: EKYC_STATUS_TO_I18N_LABEL.get(this.ekycStatus) || '',
                    });
                }

                if (this.isDetailFieldEnabled(USER_DETAIL_FIELD.IS_LOCALLY_TIED)) {
                    data.push({
                        name: this.user.flags?.[FLAG_TYPE.IS_LOCALLY_TIED]?.name,
                        value: this.user.flags?.[FLAG_TYPE.IS_LOCALLY_TIED]?.detailedValue,
                    });
                }

                if (this.isDetailFieldEnabled(USER_DETAIL_FIELD.BIRTHDAY)) {
                    data.push({
                        name: this.$i18n.t('customerCare.userInformation.birthday'),
                        value: this.user.birthday,
                    });
                }

                if (this.isDetailFieldEnabled(USER_DETAIL_FIELD.NATIONALITY)) {
                    data.push({
                        name: this.$i18n.t('customerCare.userInformation.nationality'),
                        value: this.user.nationality,
                    });
                }

                if (this.isDetailFieldEnabled(USER_DETAIL_FIELD.DOCUMENT_TYPE)) {
                    data.push({
                        name: this.$i18n.t('customerCare.userInformation.documentType'),
                        value: [
                            {
                                text: this.user.documentType,
                                link: this.user.documentSrc,
                            },
                        ],
                    });
                }

                if (this.isDetailFieldEnabled(USER_DETAIL_FIELD.DOCUMENT_SUBTYPE)) {
                    data.push({
                        name: this.$i18n.t('customerCare.userInformation.documentSubtype'),
                        value: this.user.documentSubtype,
                    });
                }
            }

            return data;
        },
        consentInfo() {
            const data = [];

            /**
             * @param {number} consentType
             * @param {number} epoch
             * @param {string} contextId
             * @param {boolean} isSet
             */
            const mapConsentItem = (consentType, epoch, contextId, isSet) => {
                let label = isSet
                    ? this.$i18n.t('customerCare.messageStates.acceptedOn')
                    : this.$i18n.t('customerCare.messageStates.notAccepted');

                if (isSet && epoch) {
                    label += ` ${this.$localeLibrary.getFormattedDateAndTimeWithSecondsPrecision(epoch)}`;
                }

                const item = {
                    name: this.$i18n.t(getConsentsContextTypeI18nKey(consentType)),
                    value: [label],
                };

                if (contextId) {
                    item.value.push(`${this.$i18n.t('customerCare.context')} ${contextId}`);
                }

                return item;
            };

            if (this.isDetailFieldEnabled(USER_DETAIL_FIELD.CONSENTS_TERMS_AND_CONDITIONS)) {
                data.push({
                    name: this.$i18n.t('customerCare.userInformation.tcAcceptance'),
                    // Checking if the DevEdge T&C is accepted so in relation to that T&C timestamp is displayed
                    value:
                        this.user.tcGlobalAccepted === FLAG_TC_GLOBAL_ACCEPTED_TEXT.ACCEPTED
                            ? `${this.$i18n.t('customerCare.messageStates.acceptedOn')} ${this.$localeLibrary
                                  .getFormattedDateAndTimeWithSecondsPrecision(this.user.tcAcceptanceTimestamp)
                                  .toString()}`
                            : this.user.tcGlobalAccepted,
                });
            }

            if (this.isDetailFieldEnabled(USER_DETAIL_FIELD.CONSENTS_ACCEPTED)) {
                // Map accepted consents only.
                this.consents.forEach(consent => {
                    data.push(mapConsentItem(consent.context_type, consent.epoch, consent.context_id, consent.is_set));
                });
            } else if (this.isDetailFieldEnabled(USER_DETAIL_FIELD.CONSENTS_ALL)) {
                // Map all consents(accepted/not-accepted).
                this.consentsDefault.forEach(consentType => {
                    // Get the latest consent of a $consentType(multiple entries).
                    const consent = this.consents.reduce((latest, current) => {
                        if (current.context_type === consentType) {
                            return latest.epoch > current.epoch ? latest : current;
                        }
                        return latest;
                    }, {});

                    data.push(mapConsentItem(consentType, consent?.epoch, consent?.context_id, consent?.is_set));
                });
            }

            return data;
        },
        flagInfo() {
            const data = [];

            const isProfileDeletedFlag = this.user.flags?.[FLAG_TYPE.IS_PROFILE_DELETED];

            if (this.isDetailFieldEnabled(USER_DETAIL_FIELD.FLAG_PROFILE_DELETED) && isProfileDeletedFlag) {
                data.push({
                    name: isProfileDeletedFlag.name,
                    value: isProfileDeletedFlag.detailedValue,
                });
            }

            return data;
        },
        flagStatusIndicators() {
            const additionalLabels = [];
            const hasSuspendedServices = Object.values(this.user.flags).some(Flag.isFlagSuspendingSomeServices);

            if (hasSuspendedServices) {
                additionalLabels.push({
                    title: this.$i18n.t('customerCare.suspendServices.someServicesSuspended'),
                    color: LABEL_COLOR.yellow,
                });
            }

            return additionalLabels;
        },
        haveActiveSessions() {
            return this.sessions.some(session => session.state === DEVICE_STATE.ACTIVE);
        },
    },
    async created() {
        try {
            const { id } = this.$route.params;

            if (!id) {
                await this.$router.push({
                    name: RouteNames.CUSTOMER_CARE_USER_MANAGEMENT,
                    params: { companyId: this.$route.params.companyId },
                });
                return;
            }

            if (!isEmpty(this.userInfo)) {
                this.setUser();
            }

            if (this.isDetailFieldEnabled(USER_DETAIL_FIELD.TERMINATION_DATE)) {
                this.getUserTerminationTime(id);
            }

            await this.setUserInfo(id);

            const dataFetchesPromises = [this.setSessions(id), this.setRoles()];
            if (this.isCredentialsHistoryEnabled) {
                dataFetchesPromises.push(this.setCredsHistory(id));
            }
            if (this.isEkycEnabled) {
                dataFetchesPromises.push(this.fetchEkycStatus(id));
            }
            if (this.isConsentsEnabled) {
                dataFetchesPromises.push(this.fetchConsents(id));
            }

            await Promise.all(dataFetchesPromises);

            if (this.user.documentNumber) {
                try {
                    const response = await getAssetUrl(this.userInfo.account_ids[0], ASSET_TYPE.ID);
                    this.accountDocImageUrl = response.data.url;
                } catch (e) {
                    if (
                        e.response &&
                        e.response.status === 400 &&
                        e.response.data.module === 'ASSET' &&
                        e.response.data.code === luaErrors.ASSET.ASSET_NOT_FOUND.code
                    ) {
                        // Note: Supposedly valid case. e.g. user has no pictures uploaded for some reason.
                    } else {
                        Sentry.captureException(e);
                        this.$eventBus.$emit('showAlert', {
                            message: this.$i18n.t(
                                'alertMessage.userManagement.somethingWentWrongFetchingUserProfileData',
                            ),
                        });
                    }
                }
            }
        } catch (error) {
            if (handleUserNotFoundError(error)) {
                await this.$router.push({
                    name: RouteNames.CUSTOMER_CARE_USER_MANAGEMENT,
                    params: { companyId: this.$route.params.companyId },
                });
                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('alertMessage.userManagement.userProfileNotFound'),
                });
            } else {
                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('alertMessage.userManagement.somethingWentWrongFetchingUserProfileData'),
                });
            }
        }
    },
    methods: {
        ...mapActions('userManagementUser', [
            'getRoles',
            'getUserInfo',
            'getSessions',
            'getCredentialsChangeHistory',
            'endSession',
            'getEkycStatus',
            'getConsents',
        ]),
        isViewEnabled,
        refreshUserData() {
            try {
                const { id } = this.$route.params;
                this.setUserInfo(id);
            } catch (error) {
                Sentry.captureException(error);
                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('alertMessage.failedToLoadNecessaryData'),
                    type: ALERT_TYPES.error,
                });
            }
        },
        goToRoute(breadcrumb) {
            this.$router.push({ path: `/${this.$route.params.companyId}${breadcrumb.link}` });
        },
        getBreadcrumbPath(type, id) {
            switch (type) {
                case USER_MANAGER_HIERARCHY.SUBSCRIBER:
                    return {
                        link: `/customer-care/subscriber/${id}`,
                    };
                case USER_MANAGER_HIERARCHY.ACCOUNT:
                    return {
                        link: `/customer-care/account/${id}`,
                    };
                case USER_MANAGER_HIERARCHY.USER:
                    return {
                        link: `/customer-care/user/${id}`,
                    };
                case USER_MANAGER_HIERARCHY.ORGANIZATION:
                    return {
                        link: `/customer-care/organization/${id}`,
                    };
                default:
                    return '';
            }
        },
        setUser() {
            this.user = new User(User.remapUserFromBe(this.userInfo));
        },
        showEditor() {
            this.isEditorVisible = true;
        },
        async onLogoutSessionClicked(userId, sessionId) {
            try {
                this.$Progress.start();
                await this.endSession({
                    userId,
                    sessionId,
                });
                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('customerCare.userInformation.successLogOutDevice'),
                    type: ALERT_TYPES.success,
                });
                this.$Progress.finish();
            } catch (e) {
                Sentry.captureException(e);
                this.$Progress.fail();
                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('customerCare.userInformation.failLogOutDevice'),
                    type: ALERT_TYPES.error,
                });
            }
        },
        async onLogoutAllClicked(userId) {
            try {
                this.$Progress.start();
                await this.endSession({ userId });
                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('customerCare.userInformation.successLogOutDevice'),
                    type: ALERT_TYPES.success,
                });
                this.$Progress.finish();
            } catch (e) {
                Sentry.captureException(e);
                this.$Progress.fail();
                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('customerCare.userInformation.failLogOutDevice'),
                    type: ALERT_TYPES.error,
                });
            }
        },
        async setUserInfo(id) {
            try {
                this.$Progress.start();
                await this.getUserInfo(id);

                this.setUser();
                this.$Progress.finish();
            } catch (e) {
                this.$Progress.fail();
                throw e;
            }
        },
        async setSessions(id) {
            if (this.isSessionsEnabled) {
                try {
                    this.$Progress.start();
                    await this.getSessions(id);
                    this.$Progress.finish();
                } catch (e) {
                    Sentry.captureException(e);
                    this.$Progress.fail();
                    this.$eventBus.$emit('showAlert', {
                        message: this.$i18n.t('alertMessage.failedToLoadNecessaryData'),
                        type: ALERT_TYPES.error,
                    });
                }
            }
        },
        async setCredsHistory(id) {
            if (this.isCredentialsHistoryEnabled) {
                try {
                    this.$Progress.start();
                    await this.getCredentialsChangeHistory(id);
                    this.$Progress.finish();
                } catch (e) {
                    Sentry.captureException(e);
                    this.$Progress.fail();
                    this.$eventBus.$emit('showAlert', {
                        message: this.$i18n.t('alertMessage.failedToLoadNecessaryData'),
                        type: ALERT_TYPES.error,
                    });
                }
            }
        },
        async setRoles() {
            try {
                this.$Progress.start();
                await this.getRoles();
                this.$Progress.finish();
            } catch (e) {
                Sentry.captureException(e);
                this.$Progress.fail();
                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('alertMessage.failedToLoadNecessaryData'),
                    type: ALERT_TYPES.error,
                });
            }
        },
        async fetchEkycStatus(id) {
            try {
                this.$Progress.start();
                await this.getEkycStatus(id);
                this.$Progress.finish();
            } catch (e) {
                Sentry.captureException(e);
                this.$Progress.fail();
                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('alertMessage.failedToLoadNecessaryData'),
                    type: ALERT_TYPES.error,
                });
            }
        },
        async fetchConsents(id) {
            try {
                this.$Progress.start();
                await this.getConsents(id);
                this.$Progress.finish();
            } catch (e) {
                Sentry.captureException(e);
                this.$Progress.fail();
                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('alertMessage.failedToLoadNecessaryData'),
                    type: ALERT_TYPES.error,
                });
            }
        },
        onAccountDocModalClosed() {
            this.accountDocModalVisible = false;
        },
        isDetailFieldEnabled(fieldName) {
            return this.userDetailFields.includes(fieldName);
        },
        newCustomerCare() {
            this.$router.push({
                name: RouteNames.CCS_USER_PAGE,
                params: { id: this.$route.params.id },
            });
        },
        async getUserTerminationTime(userId) {
            const {
                data: { result },
            } = await ordersHTTP.getTerminationTime({
                target_type: USER_MANAGER_HIERARCHY.USER,
                target_ids: [userId],
            });
            if (result?.[userId]) {
                this.terminationData = this.$localeLibrary.getFormattedDate(result[userId]);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/palette';
@import '~@/assets/scss/layout';

.search-box {
    width: 15rem;
}

.background-color {
    background-color: $dirty-white;
}

.section-layout {
    margin: 0 1.5rem 1.5rem 1.5rem;
}

.section-subtitle {
    font-size: 0.75rem;
    font-weight: 600;
    color: $gray90;
}

.section-title {
    font-size: 1rem;
    font-weight: 600;
    color: $gray90;
}

.table-title {
    font-size: 1rem;
    font-weight: 600;
}

.link:hover {
    color: $blue;
    text-decoration: underline;
}
</style>
