import { CDP_PROPERTY_TYPE } from '@/__new__/services/dno/events/models/EventProp';

type BEDataSource = {
    dataSourceName: string;
    fieldType: CDP_PROPERTY_TYPE;
    label: string;
};

export default class EventDataSource {
    dataSourceName: string;
    fieldType: CDP_PROPERTY_TYPE;
    label: string;

    constructor(data: Readonly<BEDataSource>) {
        this.dataSourceName = data.dataSourceName;
        this.fieldType = data.fieldType;
        this.label = data.label;
    }
}
