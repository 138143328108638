<template>
    <OperateApisListPageLayout
        :pageTitle="$i18n.t('operateAPIs.apiProductOrders')"
        :entityType="OPERATE_APIS_TYPES.API_PRODUCT_ORDER"
        :entityResponse="apiProductOrdersApiResponse"
        :entityData="entityData"
        :addButtonLabel="$i18n.t('operateAPIs.addNewApiProductOrder')"
        :writePermission="isUserAllowed('OperateAPIsProductOrdersWrite')"
        :addNewRouteName="addNewRouteName"
        :tabs="tabs"
        :defaultTabId="defaultTabId"
        @onSelectEntity="onSelectApiProductOrder"
        @onSelectPartyRole="onSelectPartyRole"
        @onTabChange="selectedTabId = $event"
    >
        <template #sidebarHeader>
            <OverviewHeaderV2
                v-if="selectedApiProductOrder"
                :entityName="selectedApiProductOrder.id"
                :entityId="selectedApiProductOrder.id"
            >
                <template #statusIndicator>
                    <EntityStatusIndicator
                        :status="selectedApiProductOrder.state"
                        :stateMap="API_PRODUCT_ORDER_STATE_TO_LABEL_MAP"
                        :stateMapColor="API_PRODUCT_ORDER_STATE_TO_COLOR_MAP"
                    />
                </template>
            </OverviewHeaderV2>
        </template>
        <template #sidebarOverview>
            <div v-if="selectedTabId === 'general'">
                <AppOverviewBlock
                    :items="generalDetails"
                    :isRowTitleCounterVisible="false"
                    :isTitleCounterVisible="false"
                    :maxItemRows="10"
                    class="mb-3"
                />
                <AppOverviewBlock
                    :items="agreements"
                    :title="$i18n.t('operateAPIs.agreements')"
                    :isRowTitleCounterVisible="false"
                    class="mb-3"
                />
                <AppOverviewBlock
                    :items="externalIds"
                    :title="$i18n.t('generic.externalIds')"
                    :isRowTitleCounterVisible="false"
                />
                <template v-if="isUserInternal()">
                    <h2 class="lf-table-title mb-2 mt-4">
                        {{ $i18n.t('operateAPIs.relatedExecutions') }}
                    </h2>
                    <AppTable
                        :entities="executionsForEntity"
                        :columnsData="tableColumnsData"
                        :isDataLoading="executionsTableLoading"
                        :canSelectColumns="true"
                    >
                        <template #executionId="{ entity }">
                            <div
                                v-if="entity.executionId"
                                class="truncate-text"
                            >
                                <router-link
                                    :to="{
                                        name: RouteNames.ORCHESTRATION_ENGINE_EXECUTION_DETAILS,
                                        params: {
                                            id: 'create_application',
                                            execId: entity.executionId,
                                        },
                                    }"
                                    target="_blank"
                                >
                                    {{ entity.executionId }}
                                </router-link>
                            </div>
                        </template>
                        <template #status="{ entity }">
                            <ExecutionStatusIndicator
                                v-if="entity.status"
                                :status="entity.status"
                            />
                        </template>
                        <template #customRowButtons="{ entity }">
                            <div
                                v-if="displayCustomRowButtons(entity)"
                                class="d-flex"
                            >
                                <IconButton
                                    :label="$i18n.t('generic.approve')"
                                    :icon="ICON_TYPES.CHECK"
                                    class="mr-2"
                                    @iconClick="approveRejectApiProductOrder(entity, true)"
                                />
                                <IconButton
                                    :label="$i18n.t('generic.reject')"
                                    :icon="ICON_TYPES.CLOSE"
                                    class="mr-2"
                                    @iconClick="approveRejectApiProductOrder(entity, false)"
                                />
                            </div>
                        </template>
                    </AppTable>
                </template>
                <template v-if="Object.keys(lastMappedExecution).length">
                    <div class="d-flex">
                        <AppButton
                            :buttonType="BUTTON_TYPES.SECONDARY"
                            :label="$i18n.t('generic.approve')"
                            class="mr-2"
                            @click="approveRejectApiProductOrder(lastMappedExecution, true)"
                        />
                        <AppButton
                            :buttonType="BUTTON_TYPES.SECONDARY"
                            :label="$i18n.t('generic.reject')"
                            @click="approveRejectApiProductOrder(lastMappedExecution, false)"
                        />
                    </div>
                </template>
            </div>
        </template>
    </OperateApisListPageLayout>
</template>

<script>
// Components
import AppOverviewBlock from '@/components/partials/AppOverviewBlock.vue';
import OperateApisListPageLayout from '@/__new__/features/operateAPIs/OperateApisListPageLayout.vue';
import AppTable from '@/components/partials/AppTable.vue';
import ExecutionStatusIndicator from '@/__new__/features/orchestrationengine/ExecutionStatusIndicator.vue';
import IconButton from '@/components/partials/IconButton.vue';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import Button from '@/common/button/Button';
import OverviewHeaderV2 from '@/components/partials/entityOverview/OverviewHeaderV2.vue';
import EntityStatusIndicator from '@/components/partials/EntityStatusIndicator.vue';

// HTTP
import { getApiProductOrders } from '@/__new__/services/dno/operateAPIs/http/operateAPIs';
import { callbackExecution } from '@/__new__/services/dno/orchestrationengine/http/orchestration-engine';

// Helpers
import RouteNames from '@/router/routeNames';
import tableColumnType from '@/common/filterTable';
import { EXECUTION_STATES } from '@/__new__/features/orchestrationengine/common/executionStatusIndicatorHelper';
import { ICON_TYPES } from '@/common/iconHelper';
import { isUserInternal, isUserAllowed } from '@/services/permissions/permissions.service';
import Actions, { Getters } from '@/store/mutation-types';
import { ALERT_TYPES } from '@/common/alerts/Alert';
import {
    OPERATE_APIS_TYPES,
    API_PRODUCT_ORDER_STATE_TO_LABEL_MAP,
    API_PRODUCT_ORDER_STATE_TO_COLOR_MAP,
} from '@/__new__/features/operateAPIs/common/operateAPIsHelper';

// Vuex
import { mapActions, mapGetters } from 'vuex';

export default {
    name: 'ApiProductOrdersListPage',

    components: {
        AppButton,
        AppOverviewBlock,
        OperateApisListPageLayout,
        AppTable,
        ExecutionStatusIndicator,
        IconButton,
        OverviewHeaderV2,
        EntityStatusIndicator,
    },

    data() {
        return {
            apiProductOrdersApiResponse: {},
            entityData: [],
            addNewRouteName: RouteNames.OPERATE_APIS_PRODUCT_ORDERS_EDITOR,
            selectedApiProductOrder: null,
            executionsForEntity: [],
            executionsTableLoading: false,
            lastMappedExecution: {},

            tableColumnsData: [
                {
                    name: this.$i18n.t('orchestrationEngine.execId'),
                    key: 'executionId',
                    field: 'executionId',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('generic.status'),
                    key: 'status',
                    field: 'status',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
            ],
            defaultTabId: 'general',
            selectedTabId: 'general',
            tabs: [
                {
                    id: 'general',
                    label: this.$i18n.t('generic.general'),
                },
                {
                    id: 'json',
                    label: this.$i18n.t('generic.json'),
                },
            ],

            BUTTON_TYPES,
            RouteNames,
            EXECUTION_STATES,
            ICON_TYPES,
            isUserInternal,
            OPERATE_APIS_TYPES,
            API_PRODUCT_ORDER_STATE_TO_LABEL_MAP,
            API_PRODUCT_ORDER_STATE_TO_COLOR_MAP,
        };
    },

    computed: {
        ...mapGetters('orchestrationengine', {
            getOEMappedExecutions: Getters.GET_OE_MAPPED_EXECUTIONS,
        }),
        selectedEntityId() {
            return this.selectedApiProductOrder ? this.selectedApiProductOrder.id : null;
        },
        externalIds() {
            return (
                this.selectedApiProductOrder?.externalId?.map(externalIdObj => ({
                    isCollapsed: true,
                    name: externalIdObj.id,
                    rows: [
                        {
                            name: this.$i18n.t('generic.id'),
                            value: externalIdObj.id,
                        },
                        {
                            name: this.$i18n.t('generic.owner'),
                            value: externalIdObj.owner,
                        },
                        {
                            name: this.$i18n.t('generic.type'),
                            value: externalIdObj.externalIdentifierType,
                        },
                    ],
                })) ?? []
            );
        },
        agreements() {
            return (
                this.selectedApiProductOrder?.agreement?.map(agreementObj => ({
                    isCollapsed: true,
                    name: agreementObj.name,
                    rows: [
                        {
                            name: this.$i18n.t('generic.id'),
                            value: agreementObj.id,
                        },
                        {
                            name: this.$i18n.t('generic.name'),
                            value: agreementObj.name,
                        },
                        {
                            name: this.$i18n.t('generic.link'),
                            value: agreementObj.href,
                        },
                    ],
                })) ?? []
            );
        },
        generalDetails() {
            return [
                {
                    isCollapsed: false,
                    name: this.$i18n.t('generic.general'),
                    rows: [
                        {
                            name: this.$i18n.t('generic.id'),
                            value: this.selectedApiProductOrder?.id || this.$i18n.t('generic.empty'),
                        },
                        {
                            name: this.$i18n.t('generic.description'),
                            value: this.selectedApiProductOrder?.description || this.$i18n.t('generic.empty'),
                        },
                        {
                            name: this.$i18n.t('generic.link'),
                            value: this.selectedApiProductOrder?.href || this.$i18n.t('generic.empty'),
                        },
                        {
                            name: this.$i18n.t('operateAPIs.channelPartner'),
                            value: this.selectedApiProductOrder?.channelPartnerId || this.$i18n.t('generic.empty'),
                        },
                        {
                            name: this.$i18n.t('generic.state'),
                            value: this.selectedApiProductOrder?.state || this.$i18n.t('generic.empty'),
                        },
                        {
                            name: this.$i18n.t('benefits.creationDate'),
                            value: this.selectedApiProductOrder?.creationDateStr || this.$i18n.t('generic.empty'),
                        },
                        {
                            name: this.$i18n.t('generic.expectedCompletionDate'),
                            value:
                                this.selectedApiProductOrder?.expectedCompletionDateStr ||
                                this.$i18n.t('generic.empty'),
                        },
                        {
                            name: this.$i18n.t('customerCare.userInformation.portingColumns.dateCompletion'),
                            value: this.selectedApiProductOrder?.completionDateStr || this.$i18n.t('generic.empty'),
                        },
                        {
                            name: this.$i18n.t('generic.updateTime'),
                            value:
                                this.selectedApiProductOrder?.lastUpdateTimestampStr || this.$i18n.t('generic.empty'),
                        },
                    ],
                },
            ];
        },
    },

    methods: {
        ...mapActions('orchestrationengine', {
            requestOEMappedExecutions: Actions.REQUEST_OE_MAPPED_EXECUTIONS,
        }),
        isUserAllowed,
        displayCustomRowButtons(entity) {
            return (
                (entity.lastState === 'rover_create_application' || entity.lastState === 'add_scope') &&
                entity.status === EXECUTION_STATES.WAITINGFORCALLBACK
            );
        },
        onSelectPartyRole(id) {
            this.$withLoadingSpinner(
                async () => {
                    await this.fetchApiProductOrders(id);
                },
                {
                    errorHandler: () => {
                        this.$alert(this.$t('operateAPIs.somethingWentWrongFetchingApiProductOrders'));
                    },
                },
            );
        },
        async fetchApiProductOrders(partyRoleId) {
            const response = await getApiProductOrders(partyRoleId);
            this.apiProductOrdersApiResponse = response;

            this.entityData = response.data.map(apiProductOrder => ({
                id: apiProductOrder?.id || '',
                description: apiProductOrder?.description || '',
                state: apiProductOrder?.state || '',
                creationDate: this.$localeLibrary.iso8601ToEpoch(apiProductOrder?.creationDate),
                creationDateStr: this.$localeLibrary.iso8601ToFormattedDateAndTime(apiProductOrder?.creationDate),
                expectedCompletionDate: this.$localeLibrary.iso8601ToEpoch(apiProductOrder?.expectedCompletionDate),
                expectedCompletionDateStr: this.$localeLibrary.iso8601ToFormattedDateAndTime(
                    apiProductOrder?.expectedCompletionDate,
                ),
                completionDate: this.$localeLibrary.iso8601ToEpoch(apiProductOrder?.completionDate),
                completionDateStr: this.$localeLibrary.iso8601ToFormattedDateAndTime(apiProductOrder?.completionDate),
                lastUpdateTimestamp: this.$localeLibrary.iso8601ToEpoch(apiProductOrder?.lastUpdate),
                lastUpdateTimestampStr: this.$localeLibrary.iso8601ToFormattedDateAndTime(apiProductOrder?.lastUpdate),
                href: apiProductOrder?.href || '',
                channelPartnerId: apiProductOrder?.channelPartner?.id || '',
                externalId: apiProductOrder?.externalId,
                agreement: apiProductOrder?.agreement,
            }));
        },
        onSelectApiProductOrder(applicationId) {
            this.$withLoadingSpinner(
                async () => {
                    [this.selectedApiProductOrder] = this.entityData.filter(data => data.id === applicationId);

                    // Reset displayed executions
                    this.executionsForEntity = [];

                    // Set flag for table loading
                    this.executionsTableLoading = true;

                    await this.requestOEMappedExecutions({
                        plan_ids: ['create_api_product_order'],
                        mapper_ids: [applicationId],
                    });

                    // Set executions for selected entity
                    this.executionsForEntity = this.getOEMappedExecutions;

                    // Get the latest execution in order to have that data for approve/reject button for non lf admins
                    const filteredRelatedExecutions = this.executionsForEntity.filter(
                        execution =>
                            execution.status === EXECUTION_STATES.WAITINGFORCALLBACK &&
                            (execution.lastState === 'rover_create_application' || execution.lastState === 'add_scope'),
                    );

                    if (filteredRelatedExecutions.length) {
                        this.lastMappedExecution = this.executionsForEntity[this.executionsForEntity.length - 1];
                    }

                    // Reset flag for table loading
                    this.executionsTableLoading = false;
                },
                {
                    errorHandler: () => {
                        this.$alert(this.$t('operateAPIs.somethingWentWrongLoadingApplicationExecutions'));
                    },
                },
            );
        },
        approveRejectApiProductOrder(entity, isApproved) {
            this.$eventBus.$emit('closeAllAlerts');

            const message = isApproved
                ? this.$i18n.t('operateAPIs.areYouSureApproveApiProductOrder')
                : this.$i18n.t('operateAPIs.areYouSureRejectApiProductOrder');

            const confirmButton = new Button({
                label: isApproved ? this.$i18n.t('generic.approve') : this.$i18n.t('generic.reject'),
                handler: () => this.confirmApproveRejectApiProductOrder(entity, isApproved),
            });

            this.$alert(message, {
                type: ALERT_TYPES.warning,
                buttons: [confirmButton],
            });
        },
        confirmApproveRejectApiProductOrder(entity, isApproved) {
            this.$withLoadingSpinner(
                async () => {
                    await callbackExecution({
                        execution_id: entity.executionId,
                        plan_id: entity.planId,
                        approved: isApproved,
                    });

                    this.$router.go();
                },
                {
                    errorHandler: () => {
                        this.$alert(this.$t('operateAPIs.somethingWentWrongApproveRejecApiProductOrder'));
                    },
                },
            );
        },
    },
};
</script>
