const MessageNames = {
    INVALID_SEGMENT_NAME: 'invalidSegmentName',
    SEGMENT_SAVED: 'segmentSaved',
    CAMPAIGN_NAME_EMPTY: 'campaignNameEmpty',
    CAMPAIGN_NAME_INVALID: 'campaignNameInvalid',
    NO_SEGMENTS_SELECTED: 'noSegmentsSelected',
    START_DATE_IN_PAST: 'startDateInPast',
    END_DATE_IN_PAST: 'endDateInPast',
    END_DATE_BEFORE_START: 'endDateBeforeStart',
    MESSAGE_TEXT_IS_MISSING: 'messageTextMissing',
};

export const MessageCodes = {
    [MessageNames.INVALID_SEGMENT_NAME]: 2,
    [MessageNames.SEGMENT_SAVED]: 1,
    [MessageNames.CAMPAIGN_NAME_EMPTY]: 4,
    [MessageNames.CAMPAIGN_NAME_INVALID]: 3,
    [MessageNames.NO_SEGMENTS_SELECTED]: 5,
    [MessageNames.START_DATE_IN_PAST]: 6,
    [MessageNames.END_DATE_IN_PAST]: 7,
    [MessageNames.END_DATE_BEFORE_START]: 8,
    [MessageNames.MESSAGE_TEXT_IS_MISSING]: 11,
};

export const Messages = new Map([
    // client side
    [1, () => 'Success! Your segment has been saved!'],
    [2, (name: string) => `Segment name '${name}' is invalid`], // todo will be implemented on server too
    [3, () => 'Campaign name is invalid'],
    [4, () => 'Campaign name is empty'],
    [5, () => 'No segments selected'],
    [6, () => 'Campaign start date cannot be in the past'],
    [7, () => 'Campaign end date cannot be in the past'],
    [8, () => 'Campaign end date cannot be earlier than a start date'],
    [11, () => 'Message must not be empty'],

    // php side
    [500, () => 'Email and password combination is wrong'],

    // server side
    [1001, () => 'Failed to retrieve a filter definition'],
    [1002, (name: string) => `Segment name '${name}' already exists`],
    [1003, () => 'Failed to load segments'],
    [1004, () => 'Failed to get filter coverage approximation'],
    [1005, () => 'Failed to get segment coverage approximation'],
    [1006, () => 'Failed to decode request'],
    [1007, () => 'Internal server error'],
    [1008, () => 'Campaign name is empty'],
]);

export function messageByName(name: string) {
    return Messages.get(MessageCodes[name]) || (args => `Unknown error: ${args}`);
}

export default MessageNames;
