
// Vue components
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import AppDialogV2 from '@/components/partials/AppDialogV2.vue';
import AppTextareaV3 from '@/components/partials/inputs/AppTextareaV3.vue';

// Helpers & Misc
import permissionsService, { isUserAllowed } from '@/services/permissions/permissions.service';
import { dateToEpoch } from '@/common/formatting';
import { ICON_TYPES } from '@/common/iconHelper';
import {
    NOTES_ID_TYPE,
    oeNoteTag,
} from '@/__new__/features/orchestrationengine/common/orchestrationExecutionDetailsPageHelper';

// HTTP
import { haltExecution } from '@/__new__/services/dno/orchestrationengine/http/orchestration-engine';
import httpNotes from '@/__new__/services/dno/crm/http/crm';

// Error handling
import * as Sentry from '@sentry/vue';

// Validation
import { required } from 'vuelidate/lib/validators';

export default {
    name: 'StopExecutionModal',

    components: {
        AppButton,
        AppDialogV2,
        AppTextareaV3,
    },

    props: {
        visible: {
            required: true,
            type: Boolean,
        },
        executionId: {
            required: true,
            type: String,
        },
    },

    data() {
        return {
            // Proxy imports
            BUTTON_TYPES,
            ICON_TYPES,

            stopExecutionClicked: false as boolean,
            noteInput: '' as string,
        };
    },

    computed: {
        writeEnabled(): boolean {
            return isUserAllowed('OrchestrationPlansWrite') && permissionsService.orchestrationEnginePlansEnabled();
        },
    },

    validations: {
        noteInput: {
            required,
        },
    },

    methods: {
        async stopExecution(): Promise<any> {
            this.$v.$touch();

            if (!this.$v.$invalid) {
                try {
                    this.stopExecutionClicked = true;

                    this.$Progress.start();

                    // Stop execution
                    await haltExecution(this.executionId);

                    // Add note for execution
                    await httpNotes.addNote({
                        id: this.executionId,
                        idType: NOTES_ID_TYPE.OE_EXECUTION,
                        epoch: dateToEpoch(Date.now()),
                        noteText: this.noteInput,
                        tags: [oeNoteTag],
                    });

                    this.$showSuccessAlert({
                        message: this.$i18n.t('orchestrationEngine.stopExecutionSuccess'),
                    });

                    this.$Progress.finish();

                    this.$emit('stopExecution');
                } catch (error: any) {
                    this.$Progress.fail();
                    Sentry.captureException(error);
                    this.$showErrorAlert('showAlert', {
                        message: this.$i18n.t('orchestrationEngine.stopExecutionFail'),
                    });
                } finally {
                    this.stopExecutionClicked = false;
                }
            }
        },
        onCloseModal(): void {
            this.$emit('close');
        },
    },
};
