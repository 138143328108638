
import Vue, { type PropType } from 'vue';

// components
import AppTable from '@/components/partials/AppTable.vue';
import IconButton from '@/components/partials/IconButton.vue';
import EntityOverview from '@/components/partials/entityOverview/EntityOverview.vue';
import OverviewHeaderV2 from '@/components/partials/entityOverview/OverviewHeaderV2.vue';
import JobEditModal from '@/__new__/features/settings/applicationManager/JobEditModal.vue';
import DataflowsConfigDialog from '@/__new__/features/dataflows/DataflowsConfigDialog.vue';

// models
import Dataflow from '@/__new__/services/dno/dataflows/models/Dataflow';
import DataflowJob from '@/__new__/services/dno/dataflows/models/DataflowJob';
import { Job } from '@/__new__/features/settings/common/applicationManagerHelper';

// http
import pipelinesHTTP from '@/__new__/services/dno/pipelines/http/pipelines';

// helpers
import { ICON_TYPES } from '@/common/iconHelper';

import tableColumnType, { type TableColumn } from '@/common/filterTable';
import { isUserAllowed } from '@/services/permissions/permissions.service';
import { PLURALIZATION } from '@/common/locale/labelSingularOrPlural';

export default Vue.extend({
    name: 'DataflowsSidebar',
    components: {
        AppTable,
        IconButton,
        EntityOverview,
        OverviewHeaderV2,
        JobEditModal,
        DataflowsConfigDialog,
    },
    props: {
        value: {
            type: Boolean,
            default: false,
        },
        dataflow: {
            type: Object as PropType<Dataflow>,
            required: true,
        },
    },
    data() {
        return {
            ICON_TYPES,
            PLURALIZATION,
            // edit
            isJobEditEnabled: isUserAllowed('DataflowWrite'),
            isEditModalOpen: false,
            selectedJob: new Job(),
            appName: '',
            // export
            isExportModalOpen: false,
            exportTabs: [
                {
                    id: 'yaml',
                    label: 'YAML',
                },
            ],
            exportJobYaml: '',
            exportJobJson: {},
        };
    },
    computed: {
        tableColumnData(): TableColumn[] {
            return [
                {
                    name: this.$t('generic.id'),
                    key: 'id',
                    field: 'id',
                    filterType: tableColumnType.GENERAL_TEXT,
                    initiallyHidden: true,
                },
                {
                    name: this.$t('generic.application'),
                    key: 'app',
                    field: 'app',
                    filterType: tableColumnType.GENERAL_TEXT,
                    classes: ['font-weight-bold'],
                    width: 30,
                },
                {
                    name: this.$tc('generic.job'),
                    key: 'job',
                    field: 'job',
                    mapper: e => this.mapJobLabel(e as unknown as DataflowJob),
                    filterType: tableColumnType.GENERAL_TEXT,
                    width: 70,
                },
            ];
        },
    },

    methods: {
        mapJobLabel({ job, blueprintId }: DataflowJob): string {
            return this.dataflow?.templates?.length === 1 ? job : `${job} (${blueprintId})`;
        },
        onClose(): void {
            this.onCloseEdit();
            this.$emit('input', false);
        },
        onCloseEdit(): void {
            this.appName = '';
            this.selectedJob = new Job();
            this.exportJobYaml = '';
            this.isEditModalOpen = false;
        },
        onSaveEdit(): void {
            this.onCloseEdit();
            this.$emit('edit');
        },
        editJob({ app, job }: DataflowJob): void {
            this.appName = app;
            this.selectedJob = new Job({ id: job });
            this.isEditModalOpen = true;
        },
        exportJob(entity: DataflowJob) {
            this.getJobAsYaml(entity);
            this.isExportModalOpen = true;
        },
        getJobAsYaml({ app, job }: DataflowJob): Promise<void> {
            return this.$withProgressBar(
                async () => {
                    if (this.exportJobYaml) {
                        return;
                    }
                    const { data: yamlData } = await pipelinesHTTP.getJobStaticExportById(app, job);
                    this.exportJobYaml = yamlData?.data?.toString();
                },
                {
                    errorHandler: () => {
                        this.exportJobYaml = this.$t('generic.error');
                        this.$alert(this.$t('alertMessage.somethingWentWrong'));
                    },
                },
            );
        },
    },
});
