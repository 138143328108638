<template>
    <div class="editor-section">
        <div class="editor-section-heading">
            {{ title }}
            <div class="small-gray-date-label mt-1">
                {{ subtitle }}
            </div>
        </div>

        <div class="editor-input-largest">
            <AppCheckboxCard
                v-for="(item, index) in items"
                :key="index"
                :value="item.value"
                :label="item.label"
                :description="item.description"
                :disabled="item.disabled"
                :invalid="invalid && !item.disabled"
                fullWidth
                class="mb-3"
                @input="updateValue($event, item)"
            />
        </div>
    </div>
</template>

<script>
// components
import AppCheckboxCard from '@/components/partials/inputs/AppCheckboxCard.vue';

export default {
    name: 'AppCheckboxCardEditList',
    components: {
        AppCheckboxCard,
    },
    props: {
        title: {
            type: String,
            required: false,
            default: '',
        },
        subtitle: {
            type: String,
            required: false,
            default: '',
        },
        items: {
            type: Array,
            required: false,
            default: () => [],
        },
        invalid: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        updateValue(value, item) {
            this.$emit('updateValue', item, value);
        },
    },
};
</script>
