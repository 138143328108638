<script>
export default {
    name: 'CountControlMixin',
    data() {
        return {
            count: {
                displayNumberOfItems: 0,
                totalNumberOfItems: 0,
            },
        };
    },
    methods: {
        setNumberOfItemsAfterSearch(displayNumberOfItems, totalNumberOfItems) {
            this.count.displayNumberOfItems = displayNumberOfItems;
            this.count.totalNumberOfItems = totalNumberOfItems;
        },
    },
};
</script>
