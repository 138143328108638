<template>
    <OperateApisListPageLayout
        :pageTitle="$i18n.t('operateAPIs.apiProducts')"
        :entityType="OPERATE_APIS_TYPES.API_PRODUCT"
        :entityResponse="apiProductsApiResponse"
        :entityData="entityData"
        @onSelectEntity="onSelectEntity"
        @onSelectPartyRole="onSelectPartyRole"
    >
        <template slot="sidebarOverview">
            <OverviewList :lists="detailsSections" />
        </template>
    </OperateApisListPageLayout>
</template>

<script>
// Components
import OperateApisListPageLayout from '@/__new__/features/operateAPIs/OperateApisListPageLayout.vue';
import OverviewList from '@/components/partials/entityOverview/OverviewList.vue';

// HTTP
import { getApiProducts } from '@/__new__/services/dno/operateAPIs/http/operateAPIs';

// Helpers
import { isUserAllowed } from '@/services/permissions/permissions.service';
import { OPERATE_APIS_TYPES } from '@/__new__/features/operateAPIs/common/operateAPIsHelper';

export default {
    name: 'ApiProductsListPage',

    components: {
        OperateApisListPageLayout,
        OverviewList,
    },

    data() {
        return {
            apiProductsApiResponse: {},
            entityData: [],
            selectedApiProduct: null,

            OPERATE_APIS_TYPES,
        };
    },

    computed: {
        selectedEntityId() {
            return this.selectedApiProduct ? this.selectedApiProduct.id : null;
        },
        detailsSections() {
            if (!this.selectedEntityId) {
                return [];
            }

            return [
                {
                    name: this.$i18n.t('generic.general'),
                    properties: [
                        {
                            value: this.selectedApiProduct?.id || this.$i18n.t('generic.empty'),
                            label: this.$i18n.t('generic.id'),
                        },
                        {
                            value: this.selectedApiProduct?.status || this.$i18n.t('generic.empty'),
                            label: this.$i18n.t('generic.status'),
                        },
                        {
                            value: this.selectedApiProduct?.createOn || this.$i18n.t('generic.empty'),
                            label: this.$i18n.t('customerCare.userInformation.creationDate'),
                        },
                        {
                            value: this.selectedApiProduct?.startDate || this.$i18n.t('generic.empty'),
                            label: this.$i18n.t('generic.startDate'),
                        },
                        {
                            value: this.selectedApiProduct?.apiName || this.$i18n.t('generic.empty'),
                            label: this.$i18n.t('operateAPIs.APIName'),
                        },
                    ],
                },
                {
                    name: this.$i18n.t('operateAPIs.productOffering'),
                    properties: [
                        {
                            value: this.selectedApiProduct?.productOfferingId || this.$i18n.t('generic.empty'),
                            label: this.$i18n.t('generic.id'),
                        },
                        {
                            value: this.selectedApiProduct?.productOfferingName || this.$i18n.t('generic.empty'),
                            label: this.$i18n.t('generic.name'),
                        },
                    ],
                },
                {
                    name: this.$i18n.t('operateAPIs.aoiGrantInformation'),
                    properties: [
                        {
                            value: this.selectedApiProduct?.apiGrantInformationType || this.$i18n.t('generic.empty'),
                            label: this.$i18n.t('generic.type'),
                        },
                        {
                            value: this.selectedApiProduct?.apiGrantInformationPurpose || this.$i18n.t('generic.empty'),
                            label: this.$i18n.t('customerCareSuite.applicationTile.purpose'),
                        },
                        {
                            value: this.selectedApiProduct?.apiGrantInformationScope || this.$i18n.t('generic.empty'),
                            label: this.$i18n.t('pricingAndFees.label.scope'),
                        },
                    ],
                },
            ];
        },
    },

    methods: {
        isUserAllowed,
        onSelectEntity(entity) {
            [this.selectedApiProduct] = this.entityData.filter(data => data.id === entity);
        },
        onSelectPartyRole(id) {
            this.$withLoadingSpinner(
                async () => {
                    await this.fetchApiProducts(id);
                },
                {
                    errorHandler: () => {
                        this.$alert(this.$t('operateAPIs.somethingWentWrongFetchingApiProducts'));
                    },
                },
            );
        },
        async fetchApiProducts(partyRoleId) {
            const response = await getApiProducts(partyRoleId);
            this.apiProductsApiResponse = response;

            this.entityData = response.data.map(apiProduct => {
                const prodOffering = response.data?.productOffering;
                const grantInformations = response.data?.apiVersionDetails?.apiGrantInformation || {};

                return {
                    id: apiProduct?.id || '',
                    name: apiProduct?.name || '',
                    description: apiProduct?.description || '',
                    createOn: apiProduct?.createOn || '',
                    startDate: apiProduct?.startDate || '',
                    status: apiProduct?.status || '',
                    apiName: apiProduct?.apiName || '',
                    productOfferingId: prodOffering?.id || '',
                    productOfferingName: prodOffering?.name || '',
                    apiGrantInformationType: grantInformations?.['@type'] || '',
                    apiGrantInformationPurpose: grantInformations?.purpose || '',
                    apiGrantInformationScope: grantInformations?.scope || '',
                };
            });
        },
    },
};
</script>
