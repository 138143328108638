<template>
    <AbstractEditPageWrapper>
        <template #header>
            <AppHeader :pageTitle="pageTitle" />
        </template>

        <template #content>
            <!-- General -->
            <div class="section">
                <div class="d-flex align-items-center mb-3">
                    <div class="lf-subtitle mb-0">
                        {{ $i18n.t('generic.general') }}
                        <p
                            v-show="inEditMode && isRewardsDraftEnabled"
                            class="lf-secondary-text mb-0"
                        >
                            {{ $i18n.t('generic.id') }}: {{ $route.params.id }}
                        </p>
                        <p
                            v-show="inEditMode && isRewardsDraftEnabled"
                            class="lf-secondary-text mb-0"
                        >
                            {{ $i18n.t('generic.updateTime') }}:
                            {{ $localeLibrary.getFormattedDateAndTime(updateTime) }}
                        </p>
                        <p
                            v-show="inEditMode && isRewardsDraftEnabled"
                            class="lf-secondary-text mb-0"
                        >
                            {{ $i18n.t('generic.updateUser') }}: {{ updateName }}
                        </p>
                    </div>
                    <AppLabel
                        v-if="isDraft"
                        :title="$i18n.t('generic.stateMap.draft')"
                        :color="LABEL_COLOR.gray"
                        class="justify-content-center state-label ml-auto"
                    />
                    <AppLabel
                        v-if="isPublished"
                        :title="$i18n.t('generic.stateMap.published')"
                        :color="LABEL_COLOR.green"
                        class="justify-content-center state-label ml-auto"
                    />
                    <AppLabel
                        v-if="isUnpublished"
                        :title="$i18n.t('generic.stateMap.unpublishedChanges')"
                        :color="LABEL_COLOR.gray"
                        class="justify-content-center state-label ml-3"
                    />
                    <AppButton
                        v-if="allowEditDraftBtn"
                        :buttonType="BUTTON_TYPES.SECONDARY"
                        :label="$i18n.t('generic.editDraft')"
                        :class="['ml-3', { 'ml-auto': noLabels }]"
                        @click="reloadEditor(false)"
                    />
                    <AppButton
                        v-if="allowViewPublishedBtn"
                        :buttonType="BUTTON_TYPES.SECONDARY"
                        :label="$i18n.t('generic.viewPublished')"
                        :class="['ml-3', { 'ml-auto': noLabels }]"
                        @click="reloadEditor(true)"
                    />
                </div>
            </div>
            <AppInputV3
                v-model.trim="name[selectedLanguage]"
                :placeholder="$i18n.t('generic.addName')"
                :label="`${$i18n.t('generic.name')}`"
                :disabled="readonly"
                :invalid="$v.name[selectedLanguage].$error"
                class="editor-input-largest mb-3"
            />
            <AppTextareaV3
                v-model.trim="description[selectedLanguage]"
                :placeholder="$i18n.t('vouchers.editor.addDescription')"
                :label="`${$i18n.t('generic.description')}`"
                :disabled="readonly"
                class="editor-input-largest mb-3"
            />
            <div v-if="!isEditing">
                <AppToggle
                    v-model="approveOnCreate"
                    :label="$i18n.t('vouchers.editor.approveOnCreation')"
                    :small="true"
                    :disabled="readonly"
                />
            </div>

            <hr class="solid" />

            <!-- Product Types -->
            <!-- Voucher code creation -->
            <div class="section-title">
                {{ $i18n.t('vouchers.editor.voucherCodeCreation') }}
            </div>

            <CardListRadioInput
                v-model="voucherData.type"
                :cardsValues="voucherTypes"
                :label="$i18n.t('vouchers.editor.chooseVoucherType')"
                :disabled="readonly"
                :error="$v.voucherData.type.$error"
                data-test-id="voucher-type"
            />
            <hr class="solid" />

            <div
                v-if="isVoucherTypeGeneric"
                class="section-title d-flex align-items-center"
            >
                {{ $i18n.t('vouchers.editor.genericVoucherCodeBulkUpload') }}
                <AppToggle
                    v-model="voucherData.codeBulkUpload"
                    class="ml-2"
                    :small="true"
                    :disabled="disableCodeBulkUploadToggle || readonly"
                />
            </div>

            <AppInputV3
                v-if="displayCodesTextInput"
                v-model="voucherData.genericVoucherCode"
                :placeholder="$i18n.t('vouchers.editor.addVoucherCodes')"
                :label="$i18n.t('vouchers.editor.voucherCodes')"
                :disabled="readonly"
                class="editor-input-largest mb-3"
            />

            <!-- Voucher Code Auto Generation -->
            <template v-if="isVoucherTypeUnique">
                <div
                    ref="voucherCodeAutoGenerationSection"
                    class="section-title"
                >
                    <div class="d-flex align-items-center">
                        {{ $i18n.t('vouchers.editor.voucherCodeAutoGeneration') }}
                        <AppToggle
                            v-model="voucherData.codeAutoGeneration"
                            data-test-id="voucher-code-auto-generation-toggle"
                            class="ml-2"
                            :small="true"
                            :disabled="disableCodeAutoGenerationToggle || readonly"
                        />
                    </div>
                    <div class="explanation pb-2">
                        {{ $i18n.t('vouchers.editor.voucherCodeAutoGenerationExplanation') }}
                    </div>

                    <template v-if="voucherData.codeAutoGeneration">
                        <div class="d-flex align-items-center">
                            {{ $i18n.t('vouchers.editor.voucherCodeAutoGenerationOverrideDefaultGeneration') }}
                            <AppToggle
                                v-model="voucherData.codeAutoGenerationOverrideDefault"
                                class="ml-2"
                                :small="true"
                                :explanationText="
                                    $i18n.t('vouchers.editor.voucherCodeAutoGenerationOverrideDefaultExplanation')
                                "
                                :disabled="disableCodeAutoGenerationOverrideDefaultToggle || readonly"
                            />
                        </div>

                        <template v-if="voucherData.codeAutoGenerationOverrideDefault">
                            <div class="explanation pb-2">
                                {{ $i18n.t('vouchers.editor.defaultVoucherCodeSize') }}
                            </div>
                            <div class="d-flex align-items-center explanation mb-3">
                                <span>
                                    {{ $i18n.t('vouchers.editor.voucherCodeQuantity') }}
                                </span>
                                <AppInputV3
                                    v-model="voucherData.voucherCodeCount"
                                    :placeholder="'max 6000'"
                                    :invalid="$v.voucherData.voucherCodeCount.$error"
                                    class="input-wrapper col-3 min-width"
                                    type="number"
                                    :max="6000"
                                    :min="1"
                                    :disabled="disableCodeAutoGenerationOverrideDefaultToggle || readonly"
                                />
                            </div>
                        </template>

                        <div class="explanation pb-2">
                            {{ $i18n.t('vouchers.editor.voucherCodeAutoGenerationMaskInstructions') }}
                        </div>
                        <div class="d-flex align-items-center">
                            <AppInputV3
                                v-model="voucherData.codeMask"
                                :placeholder="$i18n.t('vouchers.editor.voucherCodeAutoGenerationMaskExample')"
                                :invalid="$v.voucherData.codeMask.$error"
                                data-test-id="input-code-mask"
                                class="input-wrapper col-3 min-width"
                                :disabled="disableCodeAutoGenerationToggle || readonly"
                            />
                        </div>
                    </template>
                </div>
            </template>

            <template v-if="displayFileUploader">
                <section class="d-flex">
                    <div class="content pt-0 inner-layout mt-2">
                        <DragDropFileUploader
                            :acceptType="uploadCodesType"
                            class="file-upload-field"
                            data-test-id="drag-drop-file-uploader-fe"
                            :label="$i18n.t('vouchers.editor.uploadVoucherCodes')"
                            :description="$i18n.t('vouchers.editor.uploadVoucherCodesDescription')"
                            :configFileUploader="configFileUploader"
                            :triggerUploadFiles="triggerUploadFiles"
                            :triggerClearPollers="triggerClearPollers"
                            :disabled="readonly"
                            @updateUploadStatus="updateUploadFilesStatus"
                            @updateAmountFilesUpload="updateAmountFilesUpload"
                            @error="displayFailedLines"
                        />
                    </div>
                </section>
            </template>

            <!-- Voucher code assigment -->
            <template v-if="isVoucherTypeGeneric">
                <div class="section-title d-flex align-items-center">
                    {{ $i18n.t('vouchers.editor.assignmentCount') }}
                </div>

                <div class="body-xs d-flex align-items-center mb-3">
                    <span>
                        {{ $i18n.t('vouchers.editor.codeCountLimitsSelect') }}
                    </span>
                    <AppMultiselectV3
                        v-model="voucherData.countLimitsApplyOn"
                        :options="countLimitsOptions"
                        :small="true"
                        :borderNone="true"
                        :blueArrow="true"
                        :preselectFirst="true"
                        :allowEmpty="false"
                        :disabled="disableCountLimitsToggle || readonly"
                        label="label"
                        trackBy="id"
                        class="col-2 ml-2"
                    />
                </div>

                <div class="body-xs d-flex align-items-center mb-3">
                    <span>
                        {{ $i18n.t('vouchers.editor.maxOf') }}
                    </span>

                    <AppInputV3
                        v-model="voucherData.maxAssignments"
                        class="mx-3"
                        type="number"
                        placeholder="0"
                        :disabled="readonly"
                    />

                    <span v-if="countLimitsPerSet">
                        {{ $i18n.t('vouchers.editor.voucherSetOverallAssignments') }}
                    </span>

                    <span v-if="countLimitsPerCode">
                        {{ $i18n.t('vouchers.editor.voucherCodeOverallAssignments') }}
                    </span>
                </div>
            </template>

            <!-- Voucher Codes -->
            <template v-if="isEditing && isVoucherCodesAllowed">
                <div class="section-title">
                    {{ $i18n.t('vouchers.editor.voucherCodes') }}
                </div>

                <AppTable
                    :entities="savedVoucherCodes"
                    :isDefaultHoverEnabled="false"
                    :isPaginationEnabled="true"
                    :newDesign="true"
                    :canSelectColumns="false"
                    :columnsData="codesColumnsData"
                    :isDataLoading="isCodesDataLoading"
                    :alwaysShowPagination="true"
                    :useFullWidth="false"
                    tableKey="vouchers/codes"
                    data-test-id="codes-table-test-id"
                    @updatePageSize="updatePageSize"
                    @reachedLastPage="setReachedLastPage"
                >
                    <template #createdTime="{ entity }">
                        <div class="truncate-text">
                            {{ $localeLibrary.getFormattedDateAndTime(entity.createdTime) }}
                        </div>
                    </template>
                    <template #usageTime="{ entity }">
                        <div class="truncate-text">
                            {{ getTimeForVoucherCodeTable(entity.usageTime) }}
                        </div>
                    </template>
                    <template #assignmentTime="{ entity }">
                        <div class="truncate-text">
                            {{ getTimeForVoucherCodeTable(entity.assignmentTime) }}
                        </div>
                    </template>
                    <template #customPaginationComponent>
                        <AppPaginationLoadMore
                            v-model="currentPageIndex"
                            :hasMore="hasMorePages"
                            @nextPage="getVoucherCodes(true)"
                            @prevPage="getVoucherCodes(false)"
                        />
                    </template>
                </AppTable>
            </template>

            <div class="section-title">
                {{ $i18n.t('vouchers.editor.voucherSetGroupSection') }}
            </div>
            <div class="d-flex align-items-center mb-3">
                <span>
                    {{ $i18n.t('vouchers.editor.chooseGroupName') }}
                </span>
                <AppMultiselectV3
                    v-model="voucherData.namespace"
                    :options="namespacesOptions"
                    :placeholder="$i18n.t('vouchers.editor.groupInputPlaceholder')"
                    :small="true"
                    :borderNone="false"
                    :blueArrow="false"
                    :allowEmpty="false"
                    :disabled="disableNamespaceSelect || readonly"
                    :error="$v.voucherData.namespace.$error"
                    label="label"
                    trackBy="id"
                    class="col-3 ml-3"
                />

                <AppButton
                    :buttonType="BUTTON_TYPES.SECONDARY"
                    :label="$i18n.t('vouchers.editor.addGroup')"
                    :disabled="disableNamespaceSelect"
                    class="namespace-button"
                    @click="onAddNamespaceClicked"
                />
            </div>

            <AppDialogV2
                v-model="namespacesModalVisible"
                :title="$i18n.t('vouchers.editor.addGroup')"
                class="dialog"
                @close="onCloseNamespaceModal()"
            >
                <AppInputV3
                    v-model="newNamespaceName"
                    :placeholder="$i18n.t('vouchers.editor.groupInputPlaceholder')"
                    :label="$i18n.t('vouchers.editor.enterGroupName')"
                    data-test-id="input-namespace-name"
                    class="col-12 w-100 mb-3"
                    @input="onNamespaceNameChanged"
                />
                <AppInputV3
                    v-model="newNamespaceId"
                    :placeholder="$i18n.t('vouchers.editor.groupInputPlaceholder')"
                    :label="$i18n.t('vouchers.editor.groupIdInput')"
                    data-test-id="input-namespace-id"
                    class="col-12 w-100 mb-3"
                />
                <template #modalFooter>
                    <AppButton
                        :buttonType="BUTTON_TYPES.PRIMARY"
                        :label="$i18n.t('generic.add')"
                        class="ml-5"
                        data-test-id="create-new-namespace-button"
                        @click="createNewNamespace()"
                    />
                </template>
            </AppDialogV2>

            <div class="section-title">
                {{ $i18n.t('vouchers.editor.voucherCategorySection') }}
            </div>

            <div class="d-flex align-items-center mb-3">
                <span>
                    {{ $i18n.t('vouchers.editor.chooseCategories') }}
                </span>

                <AppMultiselectV3
                    v-model="applicableCategories"
                    :options="categories"
                    :multiple="true"
                    :placeholder="$i18n.t('vouchers.editor.chooseCategories')"
                    :small="true"
                    :borderNone="false"
                    :blueArrow="false"
                    :allowEmpty="true"
                    :disabled="readonly"
                    label="name"
                    trackBy="id"
                    data-test-id="input-category-multiselect"
                    class="col-3 ml-3"
                />

                <AppButton
                    :buttonType="BUTTON_TYPES.SECONDARY"
                    :label="$i18n.t('vouchers.editor.addCategory')"
                    :disabled="readonly"
                    class="namespace-button"
                    @click="onAddCategoryClicked"
                />
            </div>

            <AppDialogV2
                v-model="categoryModalVisible"
                :title="$i18n.t('vouchers.editor.addCategory')"
                class="dialog"
                @close="onCloseCategoryeModal()"
            >
                <AppInputV3
                    v-model="newCategory.name[languageDefault]"
                    :placeholder="$i18n.t('vouchers.editor.categoryNameInputPlaceholder')"
                    :label="$i18n.t('vouchers.editor.enterCategoryName')"
                    data-test-id="input-category-name"
                    class="col-12 w-100 mb-3"
                />
                <AppTextareaV3
                    v-model.trim="newCategory.description[languageDefault]"
                    :placeholder="$i18n.t('vouchers.editor.categoryDescriptionInputPlaceholder')"
                    :label="$i18n.t('vouchers.editor.enterCategoryDescription')"
                    :disabled="readonly"
                    class="editor-input-largest mb-3"
                />
                <AppInputV3
                    v-model.number="newCategory.sortPriority"
                    :label="$i18n.t('productCatalog.categories.sortPriority')"
                    :min="0"
                    :step="1"
                    type="number"
                    class="editor-input-large mb-3"
                    :optional="false"
                    data-test-id="input-category-priority"
                />
                <template #modalFooter>
                    <AppButton
                        :buttonType="BUTTON_TYPES.PRIMARY"
                        :label="$i18n.t('generic.add')"
                        class="ml-5"
                        data-test-id="create-new-category-button"
                        @click="createNewCategory"
                    />
                </template>
            </AppDialogV2>

            <!-- Voucher code usage -->
            <div class="section-title">
                {{ $i18n.t('vouchers.editor.voucherCodeUsage') }}
            </div>

            <CardListRadioInput
                v-model="voucherData.usage"
                :cardsValues="voucherCodeUsageTypes"
                :label="$i18n.t('vouchers.codeUsage')"
                :disabled="readonly"
                :error="$v.voucherData.usage.$error"
                class="mb-3"
            />

            <template v-if="voucherData.usage && voucherData.usage.id === voucherCodeUsageTypeIds.internal">
                <div class="section-content">
                    {{ $i18n.t('generic.applicability') }}
                </div>
                <div class="d-flex align-items-center mb-3">
                    <TagsMultiselect
                        v-model="voucherData.applicableOffers"
                        :options="offers"
                        :small="true"
                        :placeholder="$i18n.t('vouchers.editor.chooseAnOffer')"
                        :labelFormatter="entity => entity.name"
                        :disabled="readonly"
                        label="name"
                        trackBy="id"
                    >
                        <template #beforeMultiselect>
                            <div class="inline-text">
                                {{ $i18n.t('vouchers.editor.voucherIsUsedFor') }}
                            </div>
                        </template>
                    </TagsMultiselect>
                </div>
                <div class="section-message">
                    {{ $i18n.t('vouchers.editor.afterRedeemingVoucherCode') }}
                </div>
            </template>

            <div class="section-content">
                {{ $i18n.t('generic.expiration') }}
            </div>
            <CardListRadioInput
                v-model="voucherData.expirationType"
                :cardsValues="expirationTypes"
                :disabled="readonly"
                class="mb-3"
            />
            <UnitPicker
                v-if="voucherData.expirationType && voucherData.expirationType.id === expirationTypeIds.variable"
                v-model="voucherData.expiration"
                :label="$i18n.t('vouchers.voucherAvailable')"
                :isWidthStatic="true"
                :emitObject="true"
                :disabled="readonly"
                type="duration"
                class="mb-3"
            />
            <template v-if="voucherData.expirationType && voucherData.expirationType.id === expirationTypeIds.fixed">
                <div class="sub-section mb-2">
                    <DateTimePicker
                        v-model="voucherData.usabilityStartTime"
                        :additionalLabel="$i18n.t('vouchers.editor.voucherValidityStartDate')"
                        :disabled="readonly"
                        type="datetime"
                    />
                </div>
                <div class="sub-section mb-2">
                    <DateTimePicker
                        v-model="voucherData.usabilityEndTime"
                        :additionalLabel="$i18n.t('vouchers.editor.voucherValidityEndDate')"
                        :disabled="readonly"
                        :error="$v.voucherData.usabilityEndTime.$error"
                        type="datetime"
                    />
                </div>
            </template>

            <!-- UNCOMMENT HERE AND IN ONSAVE METHOD WHEN
                             SINGLE TARGET MULTIPLE USE SUPPORTED ON BACK-END -->
            <!--<template v-if="voucherData.type && voucherData.type.id === voucherTypeIds.generic">
                            <div class="section-content">
                                {{ $i18n.t('vouchers.editor.voucherRedeemingRules') }}
                            </div>

                            <div class="d-flex align-items-center mb-3">
                                <div class="inline-text">
                                    {{ $i18n.t('vouchers.editor.voucherCanBeRedeemed') }}
                                </div>
                                <AppMultiselectV3 v-model="voucherData.redeemingType"
                                                  :options="redeemingTypes"
                                                  :small="true"
                                                  :borderNone="true"
                                                  :blueArrow="true"
                                                  :preselectFirst="true"
                                                  :allowEmpty="false"
                                                  label="label"
                                                  trackBy="id"
                                                  class="col-2 ml-2"
                                />
                            </div>
                        </template>-->

            <!-- UNCOMMENT AFTER ADDING REDEMPTION COUNTS ON BACK-END -->
            <!-- <div class="d-flex align-items-center mb-3">
                            <div class="inline-text">
                                {{ $i18n.t('vouchers.editor.eachUserCanRedeem') }}
                            </div>
                            <AppInputV3 v-model="voucherData.timesInTotal"
                                        :type="'number'"
                                        placeholder="0"
                            />
                            <div class="inline-text ml-2">{{ $i18n.t('generic.timesInTotal') }}</div>
                        </div> -->

            <template v-if="voucherData.usage && voucherData.usage.id === voucherCodeUsageTypeIds.internal">
                <div class="section-content">
                    <AppToggle
                        v-model="voucherData.isTransferable"
                        :label="$i18n.t('vouchers.editor.voucherIsTransferable')"
                        :small="true"
                        :disabled="readonly"
                        class="ml-2"
                    />
                </div>
                <div class="section-message">
                    {{ $i18n.t('vouchers.editor.afterReceivingVoucherCode') }} <br />
                    {{ $i18n.t('vouchers.editor.availableOnlyForInternally') }}
                </div>
            </template>

            <!-- Appearance  -->
            <div class="section-title">
                {{ $i18n.t('editors.appearance') }}
            </div>
            <div class="section-content mb-3">
                <div class="row no-gutters align-items-end">
                    <AppInputV3
                        v-model="voucherData.imageUrl"
                        :label="$i18n.t('editors.imageUrl')"
                        :placeholder="$i18n.t('editors.addImageUrl')"
                        :disabled="readonly"
                        class="col-6 min-width"
                    />
                </div>
            </div>

            <!--Terms and Conditions-->
            <div class="section-title">
                <div class="d-flex align-items-center">
                    {{ $i18n.t('vouchers.editor.termsAndConditions') }}
                    <AppToggle
                        v-model="termsAndConditionsEnabled"
                        class="ml-2"
                        :small="true"
                        :disabled="readonly"
                    />
                </div>
                <div class="explanation pb-2">
                    {{ $i18n.t('vouchers.editor.t&cExplanation') }}
                </div>
                <div
                    v-if="termsAndConditionsEnabled"
                    class="row no-gutters align-items-end"
                >
                    <AppMultiselectV3
                        v-model="termsAndConditions.type"
                        :options="tcOptions"
                        :placeholder="$i18n.t('vouchers.editor.t&cURL')"
                        :small="true"
                        :borderNone="true"
                        :blueArrow="true"
                        :preselectFirst="true"
                        :allowEmpty="false"
                        :disabled="readonly"
                        label="label"
                        trackBy="id"
                        class="min-width"
                    />
                    <template v-if="termsAndConditions.type.id === termsAndConditionsTypes.url.id">
                        <AppTextareaV3
                            v-model="termsAndConditions.URL[selectedLanguage]"
                            :placeholder="$i18n.t('vouchers.editor.addURL')"
                            :disabled="readonly"
                            class="col-4 min-width"
                        />
                    </template>
                    <template v-else>
                        <div class="w-100" />
                        <AppTextareaV3
                            v-model.trim="termsAndConditions.text[selectedLanguage]"
                            :placeholder="$i18n.t('vouchers.editor.addT&C')"
                            :disabled="readonly"
                            class="col-8 min-width"
                        />
                    </template>
                </div>
            </div>

            <!-- Minimum threshold -->
            <div
                v-if="voucherData.type && voucherData.type.id === voucherTypeIds.unique"
                class="section-title"
            >
                <div class="d-flex align-items-center">
                    {{ $i18n.t('generic.minimumThreshold') }}
                    <AppToggle
                        v-model="voucherData.thresholdEnable"
                        class="ml-2"
                        :small="true"
                        :disabled="readonly"
                    />
                </div>
                <div class="explanation pb-2">
                    {{ $i18n.t('vouchers.editor.getNotified') }}
                </div>

                <template v-if="voucherData.thresholdEnable">
                    <div class="explanation pb-2">
                        {{ $i18n.t('vouchers.editor.thresholdInstructions') }}
                    </div>
                    <div class="d-flex align-items-center">
                        <AppInputV3
                            v-model="voucherData.threshold"
                            :placeholder="$i18n.t('vouchers.editor.voucherThresholdsExample')"
                            :disabled="readonly"
                            :invalid="$v.voucherData.threshold.$error"
                            class="input-wrapper col-6 min-width"
                        />
                    </div>
                </template>
            </div>

            <!-- Integration parameters -->
            <div class="section-title">
                <div class="mb-2">
                    {{ $i18n.t('generic.integrationParameter') }}
                </div>
                <div class="small-gray-note">
                    {{ $i18n.t('vouchers.editor.intParamInfo') }}
                </div>
                <div class="d-flex align-items-center">
                    <KeyValueInputs
                        v-model="voucherData.integration_params"
                        :addButtonLabel="$i18n.t('generic.addIntegrationParameter')"
                        :firstInputPlaceholder="$i18n.t('generic.key')"
                        :secondInputPlaceholder="$i18n.t('generic.value')"
                        :initInputValues="initIntegrationParameters"
                        :showInputErrors="true"
                        :allowEmptyValues="true"
                        :disabled="readonly"
                    />
                </div>
            </div>
            <!-- Misc parameters -->
            <div class="section-title">
                <div class="mb-2">
                    {{ $i18n.t('generic.miscParams') }}
                </div>
                <div class="small-gray-note">
                    {{ $i18n.t('vouchers.editor.intParamInfo') }}
                </div>
                <div class="d-flex align-items-center">
                    <KeyValueInputs
                        v-model="voucherData.misc_params"
                        :addButtonLabel="$i18n.t('generic.addMiscParams')"
                        :firstInputPlaceholder="$i18n.t('generic.key')"
                        :secondInputPlaceholder="$i18n.t('generic.value')"
                        :initInputValues="initMiscParameters"
                        :showInputErrors="true"
                        :allowEmptyValues="true"
                        :disabled="readonly"
                    />
                </div>
            </div>
            <!-- Notifications -->
            <div class="section-title">
                <div class="d-flex align-items-center">
                    {{ $i18n.t('vouchers.editor.notifications') }}
                    <AppToggle
                        v-model="voucherData.notificationsEnable"
                        class="ml-2"
                        :small="true"
                        :disabled="readonly"
                    />
                </div>
                <div class="explanation pb-2">
                    {{ $i18n.t('vouchers.editor.notificationsDescription') }}
                </div>
                <template v-if="voucherData.notificationsEnable">
                    <!-- Voucher Code Usage Notification -->
                    <div class="d-flex align-items-center">
                        <div class="inline-text">
                            {{ $i18n.t('vouchers.editor.usageReminderPrefix') }}
                        </div>
                        <AppInputV3
                            v-model.number="voucherData.voucherCodeUsageReminderDays"
                            type="number"
                            placeholder="0"
                            :max="365"
                            :min="0"
                            :disabled="readonly"
                        />
                        <div class="inline-text-right">
                            {{ $i18n.t('vouchers.editor.usageReminderPostfix') }}
                        </div>
                    </div>
                    <div class="explanation pb-2">
                        {{ $i18n.t('vouchers.editor.usageReminderDescription') }}
                    </div>
                    <!-- Voucher Code Expiry Notification -->
                    <div class="d-flex align-items-center">
                        <div class="inline-text">
                            {{ $i18n.t('vouchers.editor.codeExpiryNotify') }}
                        </div>
                        <AppToggle
                            v-model="voucherData.voucherCodeExpiryNotification"
                            class="ml-2"
                            :small="true"
                            :disabled="readonly"
                        />
                    </div>
                    <div class="explanation pb-2">
                        {{ $i18n.t('vouchers.editor.codeExpiryNotifyDescription') }}
                    </div>
                </template>
            </div>
        </template>

        <template #modal>
            <MutationDialog
                v-model="showModal"
                :saveButtonEnabled="true"
                :entities="getAffectedEntities()"
                :disabled="readonly"
                @updateEntity="saveAfterConfirmVoucher"
            />
        </template>

        <template #controls>
            <EditorButtons
                :showSaveDraft="isRewardsDraftEnabled && !readonly"
                :showPublish="isRewardsDraftEnabled && !readonly"
                :showSave="!isRewardsDraftEnabled"
                :disableSave="readonly || !isSaveAllowed"
                @cancel="onCancel"
                @saveDraft="onSave(false)"
                @publish="onSave(true)"
                @save="onSave(true)"
            />
        </template>
    </AbstractEditPageWrapper>
</template>

<script>
// Vuex
import { mapActions, mapGetters } from 'vuex';
import Actions, { Getters, State } from '@/store/mutation-types';

// Generic Vue Components
import AppHeader from '@/components/layout/AppHeader.vue';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import { ICON_TYPES } from '@/common/iconHelper';
import AppInputV3 from '@/components/partials/inputs/AppInputV3.vue';
import AppMultiselectV3 from '@/components/partials/inputs/AppMultiselectV3.vue';
import AppTextareaV3 from '@/components/partials/inputs/AppTextareaV3.vue';
import AppToggle from '@/components/partials/inputs/AppToggle.vue';
import CardListRadioInput from '@/components/partials/cards/CardListRadioInput.vue';
import DateTimePicker from '@/components/partials/inputs/DateTimePicker.vue';
import DragDropFileUploader from '@/components/partials/fileUploader/DragDropFileUploader.vue';
import KeyValueInputs from '@/components/partials/inputs/KeyValueInputs.vue';
import MutationDialog from '@/components/partials/MutationDialog.vue';
import TagsMultiselect from '@/components/partials/inputs/TagsMultiselect.vue';
import UnitPicker from '@/components/partials/inputs/UnitPicker.vue';
import AbstractEditPageWrapper from '@/components/layout/AbstractEditPageWrapper.vue';
import AppDialogV2 from '@/components/partials/AppDialogV2.vue';
import EditorButtons from '@/components/layout/EditorButtons.vue';
import AppLabel from '@/components/partials/AppLabel.vue';

// Helpers
import moment from 'moment';
import { validationMixin } from 'vuelidate';
import { required, requiredIf } from 'vuelidate/lib/validators';
import { ALERT_TYPES } from '@/common/alerts/Alert';
import ENTITY_TYPES from '@/common/entities/entityTypes';
import RouteNames from '@/router/routeNames';
import entityEditorMixin from '@/common/entityEditorMixin';
import {
    entityTypeToEntityLabel,
    formatMutationDialogEntities,
    getAffectedEntities,
} from '@/common/entities/entityHelper';
import mutationDialogMixin from '@/components/partials/mutations/mutationDialogMixin.vue';
import { manageEntityAdd, manageEntityUpdate } from '@/common/EntityLoadHelper';
import { COUNT_LIMITS_APPLY_ON } from '@/modules/rewards/common/voucherSetsHelper';
import { cloneDeep, isEmpty } from 'lodash';
import { LABEL_COLOR } from '@/common/labelsHelper';
import { STATUS_CODES } from '@/common/commonHelper';
import permissionsService, { isUserAllowed } from '@/services/permissions/permissions.service';
import * as Sentry from '@sentry/vue';
import keyBy from 'lodash/keyBy';

// HTTP
import { getArrayFromObject, getObjectFromArray } from '@/common/formatting';
import {
    addCategory,
    addVoucherSet,
    createNamespace,
    generateVoucherCodes,
    getAllNamespaces,
    getEntityDetailsByCategory,
    getEntityDetailsByName,
    getRegisteredEntities,
    getSignedURL,
    getVoucherCodes,
    getVoucherSetDraft,
    setVoucherSetDraft,
    updateVoucherSet,
} from '@/modules/rewards/http/vouchers';
import { getUserNameById } from '@/__new__/services/portal/profile/http/profile';
import tableColumnType from '@/common/filterTable';
import AppTable, { tableSizes } from '@/components/partials/AppTable.vue';
import AppPaginationLoadMore from '@/components/partials/AppPaginationLoadMore.vue';
import VoucherCodeResponse from '@/models/VoucherCodeResponse';
import VoucherCode from '@/models/VoucherCode';
import Button from '@/common/button/Button';

const termsAndConditionsTypes = {
    url: {
        id: 'url',
        i18nLabel: 'productCatalog.products.editor.t&cURL',
        field: 'url',
    },
    manually: {
        id: 'manually',
        i18nLabel: 'productCatalog.products.editor.uploadManually',
        field: 'text',
    },
};

const CODE_CHARACTER_SET = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
const DEFAULT_VOUCHER_CODE_COUNT = 5;

export default {
    name: 'VoucherSetEditor',

    components: {
        AppPaginationLoadMore,
        AppTable,
        AppDialogV2,
        AppHeader,
        AppButton,
        AppInputV3,
        AppMultiselectV3,
        AppTextareaV3,
        AppToggle,
        CardListRadioInput,
        DragDropFileUploader,
        KeyValueInputs,
        MutationDialog,
        TagsMultiselect,
        UnitPicker,
        DateTimePicker,
        AbstractEditPageWrapper,
        AppLabel,
        EditorButtons,
    },

    mixins: [validationMixin, entityEditorMixin, mutationDialogMixin],

    data() {
        return {
            RouteNames,
            termsAndConditionsTypes,
            selectedLanguage: '',
            name: {},
            description: {},
            configFileUploader: {
                getSignedURL,
                getEntityDetailsByCategory,
                getEntityDetailsByName,
                getRegisteredEntities,
                getSignedURLParams: params => ({
                    uuid: params.uuid,
                    voucherSetId: params.voucherSetId,
                    fileName: params.fileName,
                }),
                customData: {
                    voucherSetId: undefined,
                },
                filesUpdateNotify: size => this.fileUpdateNotify(size),
            },
            data: {},
            voucherData: {
                type: null,
                usage: null,
                expiration: null,
                expirationType: { id: 'noExpiration' },
                timesInTotal: 0,
                thresholdEnable: false,
                notificationsEnable: false,
                threshold: null,
                codeAssignment: null,
                voucherCodes: '',
                genericVoucherCode: '',
                oldVoucherCodes: [''],
                redeemingType: null,
                usabilityStartTime: new Date(),
                usabilityEndTime: new Date(),
                isTransferable: false,
                applicableOffers: [],
                integration_params: {
                    data: [],
                    isValid: true,
                },
                misc_params: {
                    data: [],
                    isValid: true,
                },
                voucherCodeUsageReminderDays: 0,
                voucherCodeExpiryNotification: false,
                imageUrl: '',
                file: null,
                namespace: null,
            },
            version: null,
            approveOnCreate: false,
            entityType: ENTITY_TYPES.VOUCHER_SET,
            ICON_TYPES,
            BUTTON_TYPES,
            LABEL_COLOR,
            termsAndConditionsEnabled: false,
            termsAndConditions: {
                type: null,
                URL: {},
                text: {},
            },
            voucherTypeIds: {
                generic: 'generic',
                unique: 'unique',
            },
            voucherCodeUsageTypeIds: {
                internal: 'internal',
                external: 'external',
            },
            expirationTypeIds: {
                noExpiration: 'noExpiration',
                variable: 'variable',
                fixed: 'fixed',
            },
            stayLeaveButtons: {
                stay: new Button({
                    label: this.$i18n.t('generic.stay'),
                    alertType: ALERT_TYPES.warning,
                }),
                leave: new Button({
                    label: this.$i18n.t('generic.leave'),
                    alertType: ALERT_TYPES.warning,
                }),
            },
            initIntegrationParameters: null,
            initMiscParameters: null,
            triggerUploadFiles: false,
            triggerClearPollers: false,
            isUploadFilesInProgress: false,
            isFilesUploadAmount: false,
            uploadCodesType: '.gpg, .csv',
            disableCountLimitsToggle: false,
            disableCodeBulkUploadToggle: false,
            disableCodeAutoGenerationToggle: false,
            disableCodeAutoGenerationOverrideDefaultToggle: false,
            allNamespacesResponse: null,
            namespaces: [],
            disableNamespaceSelect: false,
            namespacesModalVisible: false,
            categoryModalVisible: false,
            newNamespaceName: '',
            newNamespaceId: '',
            initialData: null,
            entityDraft: undefined,
            publishedEntity: undefined,
            updateName: this.$i18n.t('generic.N/A'),
            updateTime: null,
            readonly: false,
            isUnpublished: false,
            isOnlyDraft: false,
            isRewardsDraftEnabled: permissionsService.isRewardsDraftEnabled(),
            saveClicked: false,
            draftSavedId: null,
            newCategory: {
                name: {},
                description: {},
                sortPriority: 1,
            },
            applicableCategories: [],
            savedVoucherCodes: [],
            isCodesDataLoading: false,
            currentPageIndex: 0,
            pageSize: tableSizes[1].value,
            pagingState: null,
            pagingStates: new Map(),
            stateCodes: new Map(),
            pagingStateIndex: 0,
            reachedLastPage: false,
            isGeneric: false,
            voucherCodeCount: DEFAULT_VOUCHER_CODE_COUNT,
            stayAfterSave: false,
            isSaveAllowed: true,
        };
    },

    validations() {
        return {
            name: {
                [this.selectedLanguage]: {
                    required,
                },
            },
            voucherData: {
                type: {
                    id: {
                        required,
                    },
                },
                usage: {
                    id: {
                        required,
                    },
                },
                threshold: {
                    csvThreshold: threshold => {
                        if (threshold) {
                            return this.parseThresholds(this.voucherData.threshold) != null;
                        }
                        return true;
                    },
                },
                usabilityEndTime: {
                    valid: endTime => {
                        if (
                            this.voucherData.expirationType &&
                            this.voucherData.expirationType.id === this.expirationTypeIds.fixed
                        ) {
                            const startTime = moment(this.voucherData.usabilityStartTime).unix();
                            const isEndTimeValid = startTime < moment(endTime).unix();
                            return isEndTimeValid;
                        }
                        return true;
                    },
                },
                integration_params: {
                    valid: params => params.isValid,
                },
                misc_params: {
                    valid: params => params.isValid,
                },
                namespace: {
                    required: requiredIf(() => !this.isEditing && !this.voucherData.namespace),
                },
                codeMask: {
                    valid: codeMask => {
                        return this.isCodeMaskValid(codeMask);
                    },
                },
                voucherCodeCount: {
                    required: requiredIf(() => this.voucherData.codeAutoGenerationOverrideDefault),
                },
            },
        };
    },

    computed: {
        ...mapGetters('productcatalog', [Getters.PC_GET_ENTITIES_BY_TYPE_APPROVED]),
        ...mapGetters('rewards', {
            getEntity: Getters.GET_REWARDS_ENTITY_BY_TYPE_AND_ID,
            getApprovedByType: Getters.GET_APPROVED_REWARDS_ENTITIES_BY_TYPE,
        }),
        isVoucherCodesAllowed() {
            return isUserAllowed('VoucherSetCodesRead');
        },
        affectedEntities() {
            return getAffectedEntities(this.$attrs.id, ENTITY_TYPES.PRODUCT);
        },
        expirationTypes() {
            return [
                {
                    id: this.expirationTypeIds.noExpiration,
                    label: this.$i18n.t('vouchers.noExpiration'),
                },
                {
                    id: this.expirationTypeIds.variable,
                    label: this.$i18n.t('vouchers.variableExpiration'),
                },
                {
                    id: this.expirationTypeIds.fixed,
                    label: this.$i18n.t('vouchers.fixedExpiration'),
                },
            ];
        },
        languages() {
            return this.$store.state.operators[State.Languages];
        },
        languageDefault() {
            return this.$store.getters[`operators/${Getters.languageDefault}`];
        },
        offers() {
            return this[Getters.PC_GET_ENTITIES_BY_TYPE_APPROVED](ENTITY_TYPES.OFFER).map(offer => ({
                id: offer.id,
                name: offer.name,
            }));
        },
        categories() {
            return this.getApprovedByType(ENTITY_TYPES.CATEGORY).map(category => ({
                id: category.id,
                name: category.data.name[this.languageDefault],
            }));
        },
        redeemingTypes() {
            return [
                {
                    id: 1,
                    label: this.$i18n.t('generic.only1Time'),
                    isUnlimited: false,
                },
                {
                    id: 2,
                    label: this.$i18n.t('generic.unlimitedTime'),
                    isUnlimited: true,
                },
            ];
        },
        tcOptions() {
            return Object.values(this.termsAndConditionsTypes).map(opt => ({
                ...opt,
                label: this.$i18n.t(opt.i18nLabel),
            }));
        },
        voucherCodeUsageTypes() {
            return [
                {
                    id: this.voucherCodeUsageTypeIds.internal,
                    label: this.$i18n.t('vouchers.internalUsage'),
                    description: this.$i18n.t('vouchers.codeUsedInside'),
                },
                {
                    id: this.voucherCodeUsageTypeIds.external,
                    label: this.$i18n.t('vouchers.externalUsage'),
                    description: this.$i18n.t('vouchers.codeUsedOutside'),
                },
            ];
        },
        voucherTypes() {
            return [
                {
                    id: this.voucherTypeIds.generic,
                    label: this.$i18n.t('generic.generic'),
                    description: this.$i18n.t('vouchers.voucherCodeTheSame'),
                },
                {
                    id: this.voucherTypeIds.unique,
                    label: this.$i18n.t('generic.unique'),
                    description: this.$i18n.t('vouchers.eachUserGetsVoucher'),
                },
            ];
        },
        isVoucherTypeUnique() {
            return this.voucherData.type && this.voucherData.type.id === this.voucherTypeIds.unique;
        },
        isVoucherTypeGeneric() {
            return this.voucherData.type && this.voucherData.type.id === this.voucherTypeIds.generic;
        },
        displayFileUploader() {
            return (
                !this.$route.params.readonly &&
                ((this.isVoucherTypeUnique && !this.voucherData.codeAutoGeneration) ||
                    (this.isVoucherTypeGeneric && this.voucherData.codeBulkUpload))
            );
        },
        displayCodesTextInput() {
            return this.isVoucherTypeGeneric && !this.voucherData.codeBulkUpload;
        },
        countLimitsOptions() {
            return [
                {
                    id: COUNT_LIMITS_APPLY_ON.VOUCHER_SET,
                    label: this.$i18n.t('vouchers.voucherSet'),
                },
                {
                    id: COUNT_LIMITS_APPLY_ON.VOUCHER_CODE,
                    label: this.$i18n.t('vouchers.voucherCode'),
                },
            ];
        },
        countLimitsPerCode() {
            return (
                this.voucherData.countLimitsApplyOn &&
                this.voucherData.countLimitsApplyOn.id === COUNT_LIMITS_APPLY_ON.VOUCHER_CODE
            );
        },
        countLimitsPerSet() {
            return (
                this.voucherData.countLimitsApplyOn &&
                this.voucherData.countLimitsApplyOn.id === COUNT_LIMITS_APPLY_ON.VOUCHER_SET
            );
        },
        namespacesOptions() {
            return this.namespaces;
        },
        inEditMode() {
            return Boolean(this.$route.params.id) && !this.$route.params.clone;
        },
        isDraft() {
            return (
                this.$route.params.id && !this.$route.params.readonly && this.entityDraft && this.isRewardsDraftEnabled
            );
        },
        isPublished() {
            return this.$route.params.id && this.$route.params.readonly && this.isRewardsDraftEnabled;
        },
        noLabels() {
            return !this.isDraft && !this.isPublished;
        },
        allowViewPublishedBtn() {
            return (
                this.$route.params.id && !this.$route.params.readonly && !this.isOnlyDraft && this.isRewardsDraftEnabled
            );
        },
        allowEditDraftBtn() {
            return (
                this.$route.params.id && this.$route.params.readonly && !this.isOnlyDraft && this.isRewardsDraftEnabled
            );
        },
        pageTitle() {
            const label = entityTypeToEntityLabel[ENTITY_TYPES.VOUCHER_SET];
            if (this.$route.params.readonly) {
                return label;
            }
            if (this.inEditMode) {
                return `${this.$i18n.t('generic.edit')} ${label} ${
                    this.isDraft ? this.$i18n.t('generic.stateMap.draft') : ''
                }`;
            }
            return `${this.$i18n.t('generic.addNew')} ${label}`;
        },
        hasMorePages() {
            return !this.reachedLastPage;
        },
        codesColumnsData() {
            return [
                {
                    name: this.$i18n.t('generic.code'),
                    key: 'voucherCode',
                    field: 'voucherCode',
                    fieldNameOnBE: 'voucherCode',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('generic.created'),
                    key: 'createdTime',
                    field: 'createdTime',
                    fieldNameOnBE: 'createdTime',
                    filterType: tableColumnType.DATE,
                },
                {
                    name: this.$i18n.t('generic.assigned'),
                    key: 'assignmentTime',
                    field: 'assignmentTime',
                    fieldNameOnBE: 'assignmentTime',
                    filterType: tableColumnType.DATE,
                },
                {
                    name: this.$i18n.t('generic.used'),
                    key: 'usageTime',
                    field: 'usageTime',
                    fieldNameOnBE: 'usageTime',
                    filterType: tableColumnType.DATE,
                },
            ];
        },
    },

    created() {
        this.$withLoadingSpinner(async () => {
            this.selectedLanguage = this[Getters.languageDefault];

            await this.fetchData();

            if (this.$route.params.readonly) {
                this.readonly = this.$route.params.readonly;
            }

            if (this.$route.params.id) {
                if (this.isRewardsDraftEnabled && !this.$route.params.readonly) {
                    const { id } = this.$route.params;
                    const result = await getVoucherSetDraft(id);
                    this.entityDraft = result?.data?.data ? result.data.data[id] : null;
                    if (this.entityDraft) this.entityDraft.state = STATUS_CODES.NA;
                }

                this.publishedEntity = this.getEntity(ENTITY_TYPES.VOUCHER_SET, this.$route.params.id);
                if (this.entityDraft) {
                    this.initialData = cloneDeep(this.entityDraft);
                    this.initialData.version = this.publishedEntity.version;
                    this.isOnlyDraft = !this.publishedEntity || this.publishedEntity.state === STATUS_CODES.NA;
                    if (this.publishedEntity.update_time <= this.initialData.update_time) {
                        this.isUnpublished = true;
                    }
                } else {
                    this.initialData = cloneDeep(this.publishedEntity);
                }
                this.updateTime = this.initialData.update_time;
                this.getUpdateUserName(this.initialData.update_portal_id || this.initialData.portal_id || null);

                this.version = this.initialData.version;
                this.initIntegrationParameters = getArrayFromObject(this.initialData.data.integration_params);
                this.initMiscParameters = getArrayFromObject(this.initialData.data.misc_params);
                this.initData(this.initialData.data);

                if (this.$route.params.clone) {
                    this.name[this.selectedLanguage] += ' (cloned)';
                }
            } else {
                [this.termsAndConditions.type] = [this.tcOptions[0]];
            }
        });
    },

    methods: {
        ...mapActions('productcatalog', [Actions.PC_REQUEST_ENTITIES_BY_TYPE_AND_IDS]),
        ...mapActions('rewards', [Actions.REQUEST_REWARDS_ENTITIES_BY_TYPE]),
        async onSave(isPublish) {
            if (this.saveClicked) {
                return;
            }
            this.saveClicked = true;
            // vaildate if it's publish
            if (isPublish) {
                this.$v.$touch();
                if (this.$v.$invalid) {
                    this.$eventBus.$emit('showAlert', {
                        message: this.$i18n.t('alertMessage.pleaseFixValidation'),
                    });
                    this.saveClicked = false;
                    return;
                }
            }

            if (!isPublish && this.voucherData.file) {
                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('alerts.draftVoucherSetNoCodes'),
                    type: ALERT_TYPES.warning,
                    buttons: [this.alertButtons.confirmButton],
                });
                this.$eventBus.$once('buttonClicked', async buttonId => {
                    if (buttonId === this.alertButtons.confirmButton.id) {
                        await this.submit(isPublish);
                    }
                });
                this.saveClicked = false;
                return;
            }
            await this.submit(isPublish);
            this.saveClicked = false;
        },
        async submit(isPublish) {
            try {
                this.$Progress.start();

                this.data = {
                    name: this.name,
                    description: this.description,
                    voucher_type: this.voucherData?.type?.id,
                    voucher_settings: {
                        assignment_type: 'internal',
                    },
                    notifications: {},
                };

                if (this.termsAndConditionsEnabled) {
                    this.data.terms_and_conditions =
                        this.termsAndConditions.type.id === this.termsAndConditionsTypes.manually.id
                            ? this.termsAndConditions.text
                            : this.termsAndConditions.URL;
                    this.data.terms_and_conditions_type = this.termsAndConditions.type.field;
                }

                // UNCOMMENT HERE AND IN TEMPLATE WHEN SINGLE TARGET MULTIPLE USE SUPPORTED ON BACK-END
                // if (this.voucherData.type
                //     && this.voucherData.type.id === this.voucherTypeIds.generic) {
                //     this.data.voucher_settings.unlimited_uses = this.voucherData.redeemingType.isUnlimited;
                // }

                if (this.voucherData.usage && this.voucherData.usage.id === this.voucherCodeUsageTypeIds.internal) {
                    this.data.voucher_settings.is_transferrable = this.voucherData.isTransferable;
                }

                if (
                    this.voucherData.usage &&
                    this.voucherData.usage.id === this.voucherCodeUsageTypeIds.internal &&
                    this.voucherData.applicableOffers.length
                ) {
                    this.data.voucher_settings.applicable_to_offers = this.voucherData.applicableOffers.map(
                        offer => offer.id,
                    );
                }

                if (
                    this.voucherData.type &&
                    this.voucherData.type.id === this.voucherTypeIds.generic &&
                    this.voucherData.genericVoucherCode
                ) {
                    this.data.voucher_settings.generic_voucher_code = this.voucherData.genericVoucherCode;
                }

                if (this.voucherData.usage) {
                    this.data.voucher_settings.use_type = this.voucherData.usage.id;
                }

                if (
                    this.voucherData.expirationType &&
                    this.voucherData.expirationType.id === this.expirationTypeIds.variable &&
                    this.voucherData.expiration &&
                    this.voucherData.expiration.value
                ) {
                    this.data.usability_duration = {
                        duration_in_units: this.voucherData.expiration.value,
                        unit: this.voucherData.expiration.definition.field,
                    };
                }

                if (
                    this.voucherData.expirationType &&
                    this.voucherData.expirationType.id === this.expirationTypeIds.fixed &&
                    this.voucherData.usabilityStartTime &&
                    this.voucherData.usabilityEndTime
                ) {
                    this.data.usability_start_time = moment(this.voucherData.usabilityStartTime).unix();
                    this.data.usability_end_time = moment(this.voucherData.usabilityEndTime).unix();
                }

                if (this.voucherData.thresholdEnable && this.voucherData.threshold) {
                    this.data.voucher_settings.minimum_threshold = this.parseThresholds(this.voucherData.threshold);
                }

                if (this.voucherData.integration_params) {
                    this.data.integration_params = getObjectFromArray(this.voucherData.integration_params.data);
                }

                if (this.voucherData.misc_params) {
                    this.data.misc_params = getObjectFromArray(this.voucherData.misc_params.data);
                }

                if (this.voucherData.notificationsEnable) {
                    const reminderDays = this.voucherData.voucherCodeUsageReminderDays;
                    if (reminderDays) {
                        this.data.notifications.voucher_code_usage_reminder_days = reminderDays;
                    }

                    this.data.notifications.voucher_code_expiry = this.voucherData.voucherCodeExpiryNotification;
                }

                if (this.voucherData.imageUrl) {
                    this.data.image_url = this.voucherData.imageUrl;
                }

                if (this.voucherData.codeAutoGeneration) {
                    this.data.voucher_settings.code_auto_generation = true;
                    this.data.voucher_settings.code_mask = this.voucherData.codeMask;
                    this.data.voucher_settings.code_character_set = CODE_CHARACTER_SET;
                    this.data.voucher_settings.voucher_code_count = this.voucherData.autoGenerationOverrideDefault
                        ? this.voucherData.voucherCodeCount
                        : DEFAULT_VOUCHER_CODE_COUNT;
                }

                if (this.voucherData.codeBulkUpload) {
                    this.data.voucher_settings.code_bulk_upload = true;
                }

                if (this.voucherData.countLimitsApplyOn) {
                    this.data.count_limits_apply_on = this.voucherData.countLimitsApplyOn.id;
                }

                if (this.voucherData.maxAssignments) {
                    this.data.max_purchases = this.voucherData.maxAssignments;
                    this.data.voucher_settings.generic_max_assignments = this.voucherData.maxAssignments;
                }

                if (this.voucherData.namespace) {
                    this.data.namespace_id = this.voucherData.namespace.id;
                }

                if (!isEmpty(this.applicableCategories)) {
                    this.data.relationships = this.applicableCategories.map(category => ({
                        id: category.id,
                        type: ENTITY_TYPES.CATEGORY,
                    }));
                }

                const addEntityAction = async id => {
                    const response = await addVoucherSet({
                        data: this.data,
                        approve_on_create: this.approveOnCreate,
                        id,
                    });
                    this.saveVoucherCodes(response?.data?.id);
                    this.generateVoucherCodes(response?.data?.id);
                };
                const addDraftAction = async () => {
                    if (!this.isRewardsDraftEnabled) return null;
                    const resp = await setVoucherSetDraft({ data: this.data });
                    this.draftSavedId = resp?.data?.id;
                    return resp;
                };
                const updateDraftAction = () =>
                    this.isRewardsDraftEnabled
                        ? setVoucherSetDraft({
                              data: this.data,
                              id: this.$route.params.id ?? this.draftSavedId,
                          })
                        : null;
                const hasStoredDraft = this.draftSavedId || (this.entityDraft && !this.$route.params.clone);
                const hasStoredEntity =
                    this.publishedEntity && this.publishedEntity.state !== STATUS_CODES.NA && !this.$route.params.clone;
                if (!hasStoredEntity) {
                    const draftAction = hasStoredDraft ? updateDraftAction : addDraftAction;
                    await manageEntityAdd(draftAction, addEntityAction, isPublish);

                    this.showStayAlertIfNeeded();
                } else {
                    if (!isPublish) {
                        await updateDraftAction();
                        this.redirectToVoucherSets();
                        return;
                    }
                    if (this.affectedEntities.offer.length > 0) {
                        this.$Progress.finish();
                        this.$eventBus.$emit('showAlert', {
                            message: this.$i18n.t('alerts.affectedEntities'),
                            type: ALERT_TYPES.warning,
                            buttons: [this.alertButtons.details, this.alertButtons.confirmButton],
                        });
                        this.$eventBus.$once('buttonClicked', buttonId => {
                            if (buttonId === this.alertButtons.confirmButton.id) {
                                manageEntityUpdate(updateDraftAction, this.saveAfterConfirmVoucher, true);
                            } else if (buttonId === this.alertButtons.details.id) {
                                this.showModal = true;
                            }
                        });
                        this.redirectToVoucherSets();
                        return;
                    }
                    await manageEntityUpdate(updateDraftAction, this.saveAfterConfirmVoucher, true);
                    this.showStayAlertIfNeeded();
                }
                this.$Progress.finish();
            } catch (e) {
                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('alertMessage.errorDoingSmthTryAgain', {
                        action: this.$i18n.t('generic.saving'),
                        entityName: this.$i18n.t('vouchers.voucherSet'),
                    }),
                });
                this.$Progress.fail();
            }
        },
        showStayAlertIfNeeded() {
            if (!this.voucherData.file) {
                this.showAlertAndRedirect();
            } else {
                this.isSaveAllowed = false;
                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('vouchers.editor.trackProgress'),
                    type: ALERT_TYPES.warning,
                    buttons: [this.stayLeaveButtons.stay, this.stayLeaveButtons.leave],
                });

                this.$eventBus.$once('buttonClicked', buttonId => {
                    if (buttonId === this.stayLeaveButtons.stay.id) {
                        this.stayAfterSave = true;
                        this.$refs.voucherCodeAutoGenerationSection.scrollIntoView();
                    } else if (buttonId === this.stayLeaveButtons.leave.id) {
                        this.showAlertAndRedirect();
                    }
                });
            }
        },
        async fetchData() {
            try {
                const promises = [
                    this[Actions.REQUEST_REWARDS_ENTITIES_BY_TYPE](ENTITY_TYPES.VOUCHER_SET),
                    this[Actions.PC_REQUEST_ENTITIES_BY_TYPE_AND_IDS]({ entityType: ENTITY_TYPES.OFFER }),
                    this[Actions.REQUEST_REWARDS_ENTITIES_BY_TYPE](ENTITY_TYPES.CATEGORY),
                    this.getNamespaces(),
                ];

                if (this.isEditing && this.isVoucherCodesAllowed) {
                    promises.push(this.getVoucherCodes());
                }

                await Promise.all(promises);
            } catch (error) {
                this.$Progress.fail();
                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('alertMessage.errorDoingSmthTryAgain', {
                        action: this.$i18n.t('generic.fetching'),
                        entityName: this.$i18n.t('vouchers.voucherSet'),
                    }),
                });
                this.redirectToVoucherSets();
            }
        },
        async getNamespaces() {
            await this.$withProgressBar(
                async () => {
                    const response = await getAllNamespaces();
                    this.allNamespacesResponse = response;
                    if (response?.data?.namespaces.length) {
                        this.namespaces = response.data.namespaces.map(ns => ({ id: ns.namespace_id, label: ns.name }));
                    }
                },
                {
                    errorHandler: () => {
                        this.$showErrorAlert({
                            message: this.$i18n.t('vouchers.alerts.somethingWentWrongFetchingNamespaces'),
                        });
                    },
                },
            );
        },
        async getVoucherCodes(next = true) {
            this.isCodesDataLoading = true;

            this.changeStateIndex(next);
            this.updatePagingState();

            if (!this.stateCodes.has(this.pagingStateIndex)) {
                await this.fetchVoucherCodes();
            }

            this.isCodesDataLoading = false;
        },
        changeStateIndex(next) {
            this.pagingStateIndex += next ? 1 : -1;
        },
        updatePagingState() {
            this.pagingState = this.pagingStates.get(this.pagingStateIndex) || null;
            this.savedVoucherCodes = this.stateCodes.get(this.pagingStateIndex) || [];
        },
        async fetchVoucherCodes() {
            const response = await getVoucherCodes(this.$route.params.id, this.pageSize, this.pagingState);

            const voucherCodeResponse = new VoucherCodeResponse({
                voucherCodes: response.data.voucher_codes.map(code => {
                    return new VoucherCode({
                        voucherCode: code.voucher_code,
                        createdTime: code.created_time,
                        assignedTime: code.assigned_timestamp,
                        usageTime: code.usage_timestamp,
                    });
                }),
                pagingState: response.data.paging_state,
            });

            this.savedVoucherCodes = voucherCodeResponse.voucherCodes;
            this.reachedLastPage = voucherCodeResponse.pagingState === null;

            this.isCodesDataLoading = false;
            this.pagingStates.set(this.pagingStateIndex + 1, voucherCodeResponse.pagingState);
            this.stateCodes.set(this.pagingStateIndex, voucherCodeResponse.voucherCodes);
        },
        async createNewNamespace() {
            this.$Progress.start();
            try {
                if (!this.validateNamespaceName()) {
                    this.$eventBus.$emit('showAlert', {
                        message: this.$i18n.t('vouchers.alerts.invalidNamespaceSpecified'),
                    });
                    this.$Progress.fail();
                    return;
                }

                await createNamespace(this.newNamespaceId, this.newNamespaceName);
                this.getNamespaces();

                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('generic.success'),
                    type: ALERT_TYPES.success,
                });
                this.$Progress.finish();
                this.namespacesModalVisible = false;
            } catch (e) {
                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('vouchers.alerts.somethingWentWrongAddingNamespace'),
                });
                this.$Progress.fail();
            }
            this.$Progress.finish();
        },
        async createNewCategory() {
            this.$Progress.start();
            try {
                if (isEmpty(this.newCategory.name[this.languageDefault])) {
                    this.$eventBus.$emit('showAlert', {
                        message: this.$i18n.t('vouchers.alerts.invalidCategoryName'),
                    });
                    this.$Progress.fail();
                    return;
                }

                const categoryData = {
                    approve_on_create: true,
                    data: {
                        name: this.newCategory.name,
                        description: this.newCategory.description,
                        sort_priority: this.newCategory.sortPriority,
                    },
                };
                await addCategory(categoryData);
                await this[Actions.REQUEST_REWARDS_ENTITIES_BY_TYPE](ENTITY_TYPES.CATEGORY);

                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('generic.success'),
                    type: ALERT_TYPES.success,
                });
                this.$Progress.finish();
                this.categoryModalVisible = false;
            } catch (e) {
                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('vouchers.alerts.somethingWentWrongAddingNamespace'),
                });
                this.$Progress.fail();
            }
            this.$Progress.finish();
        },
        initData(data) {
            this.name = data.name || {};
            this.description = data.description || {};
            this.voucherData.type = this.voucherTypes.find(type => type.id === data.voucher_type);
            this.isGeneric = this.voucherData.type.id === this.voucherTypeIds.generic;
            if (Object.hasOwnProperty.call(data.voucher_settings, 'generic_voucher_code')) {
                this.voucherData.genericVoucherCode = data.voucher_settings.generic_voucher_code;
            }
            if (Object.hasOwnProperty.call(data.voucher_settings, 'generic_max_assignments')) {
                this.voucherData.codeAssignment = data.voucher_settings.generic_max_assignments;
            }
            if (Object.hasOwnProperty.call(data.voucher_settings, 'is_transferrable')) {
                this.voucherData.isTransferable = data.voucher_settings.is_transferrable;
            }
            this.voucherData.usage = this.voucherCodeUsageTypes.find(
                type => type.id === data.voucher_settings.use_type,
            );
            if (Object.hasOwnProperty.call(data, 'usability_duration')) {
                this.voucherData.expirationType = this.expirationTypes.find(
                    type => type.id === this.expirationTypeIds.variable,
                );
                this.voucherData.expiration = {
                    value: data.usability_duration.duration_in_units,
                    definition: {
                        field: `${data.usability_duration.unit}`,
                    },
                };
            } else if (data.usability_start_time && data.usability_end_time) {
                this.voucherData.expirationType = this.expirationTypes.find(
                    type => type.id === this.expirationTypeIds.fixed,
                );
                this.voucherData.usabilityStartTime = new Date(moment.unix(data.usability_start_time));
                this.voucherData.usabilityEndTime = new Date(moment.unix(data.usability_end_time));
            }
            if (Object.hasOwnProperty.call(data.voucher_settings, 'applicable_to_offers')) {
                this.voucherData.applicableOffers = this.offers.filter(offer =>
                    data.voucher_settings.applicable_to_offers.includes(offer.id),
                );
            }
            this.voucherData.redeemingType = this.redeemingTypes.find(
                type => type.isUnlimited === data.voucher_settings.unlimited_uses,
            );

            this.termsAndConditionsEnabled = Boolean(data.terms_and_conditions);
            if (this.termsAndConditionsEnabled && data.terms_and_conditions && data.terms_and_conditions_type) {
                this.termsAndConditions.type = this.tcOptions.find(opt => opt.field === data.terms_and_conditions_type);
                if (this.termsAndConditions.type.field === termsAndConditionsTypes.manually.field) {
                    this.termsAndConditions.text = data.terms_and_conditions || {};
                } else {
                    this.termsAndConditions.URL = data.terms_and_conditions || {};
                }
            } else {
                [this.termsAndConditions.type] = [this.tcOptions[0]];
            }

            const minimumThreshold = this.parseThresholds(data.voucher_settings.minimum_threshold);
            const thresholdEnable = Boolean(minimumThreshold !== null);
            this.voucherData.thresholdEnable = thresholdEnable;
            if (thresholdEnable) {
                this.voucherData.threshold = minimumThreshold.toString();
            }

            const notificationsEnable = Boolean(data.notifications && Object.keys(data.notifications).length);
            if (notificationsEnable) {
                let notificationEnabled = false;

                // set initial state for voucher_code_usage_reminder_days notification
                const usageReminderDays = data.notifications.voucher_code_usage_reminder_days;
                if (usageReminderDays) {
                    this.voucherData.voucherCodeUsageReminderDays = usageReminderDays;
                    notificationEnabled = true;
                }

                // set initial state for voucher_code_expiry notification
                const codeExpiry = data.notifications.voucher_code_expiry;
                if (codeExpiry) {
                    this.voucherData.voucherCodeExpiryNotification = codeExpiry;
                    notificationEnabled = true;
                }

                // should have at least one notification active for notifications to show enabled
                if (notificationEnabled) {
                    this.voucherData.notificationsEnable = notificationsEnable;
                }
            }

            if (data.image_url) {
                this.voucherData.imageUrl = data.image_url;
            }

            this.disableCodeAutoGenerationToggle = true;
            if (data.voucher_settings.code_auto_generation) {
                this.voucherData.codeAutoGeneration = data.voucher_settings.code_auto_generation;
                this.voucherData.codeMask = data.voucher_settings.code_mask;

                this.disableCodeAutoGenerationOverrideDefaultToggle = true;
                this.voucherData.codeAutoGenerationOverrideDefault = false;
            }

            this.disableCodeBulkUploadToggle = true;
            if (data.voucher_settings.code_bulk_upload) {
                this.voucherData.codeBulkUpload = true;
            }

            this.voucherData.countLimitsApplyOn = this.countLimitsOptions.find(
                limitOption => limitOption.id === data.count_limits_apply_on,
            );

            if (!this.voucherData.countLimitsApplyOn) {
                this.voucherData.countLimitsApplyOn = this.countLimitsOptions.find(
                    limitOption => limitOption.id === COUNT_LIMITS_APPLY_ON.VOUCHER_SET,
                );
            }

            if (data.max_purchases) {
                this.disableCountLimitsToggle = true;
                this.voucherData.maxAssignments = data.max_purchases;
            } else if (data.voucher_settings.generic_max_assignments) {
                this.disableCountLimitsToggle = true;
                this.voucherData.maxAssignments = data.voucher_settings.generic_max_assignments;
            }

            if (data.namespace_id) {
                this.voucherData.namespace = this.namespacesOptions.find(ns => ns.id === data.namespace_id);
            }

            if (
                (data?.metadata?.total_added_voucher_codes && data.metadata.total_added_voucher_codes > 0) ||
                data.voucher_settings.generic_voucher_code
            ) {
                this.disableNamespaceSelect = true;
            }

            if (data.relationships) {
                const names = keyBy(this.categories, c => c.id);
                this.applicableCategories = data.relationships
                    .filter(category => category.type === ENTITY_TYPES.CATEGORY)
                    .map(c => names[c.id]);
            }
        },
        saveVoucherCodes(id) {
            try {
                if (this.voucherData.file) {
                    this.configFileUploader.customData.voucherSetId = id;
                    this.triggerUploadFiles = !this.triggerUploadFiles;
                }
            } catch (error) {
                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('alertMessage.errorDoingSmthTryAgain', {
                        action: this.$i18n.t('generic.fetching'),
                        entityName: this.$i18n.t('generic.vouchers'),
                    }),
                });
            }
        },
        generateVoucherCodes(id) {
            if (this.voucherData.codeAutoGenerationOverrideDefault) {
                generateVoucherCodes(id, this.voucherData.voucherCodeCount);
            }
        },
        fileUpdateNotify(size) {
            this.voucherData.file = size > 0;
        },
        redirectToVoucherSets() {
            setTimeout(
                () =>
                    this.$router.push({
                        name: RouteNames.VOUCHER_SET_VIEW,
                        params: { companyId: this.$route.params.companyId },
                    }),
                2000,
            );
        },
        showAlertAndRedirect() {
            const hasStoredEntity =
                this.publishedEntity && this.publishedEntity.state !== STATUS_CODES.NA && !this.$route.params.clone;
            this.$eventBus.$emit('showAlert', {
                message: this.$i18n.t('alertMessage.successMessageWithoutRedirect', {
                    entityName: this.name[this.selectedLanguage],
                    action: hasStoredEntity ? 'updated' : 'added',
                }),
                type: ALERT_TYPES.success,
            });
            this.redirectToVoucherSets();
        },
        async saveAfterConfirmVoucher() {
            try {
                this.$Progress.start();
                await updateVoucherSet(this.$route.params.id, this.version, this.data);
                await this.saveVoucherCodes(this.$route.params.id);
                this.$Progress.finish();
            } catch (error) {
                this.$Progress.fail();
                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('alertMessage.errorDoingSmthTryAgain', {
                        action: this.$i18n.t('generic.saving'),
                        entityName: this.$i18n.t('vouchers.voucherSet'),
                    }),
                });
            }
        },
        displayFailedLines(lines) {
            this.failedLinesErrorMessage = [
                this.$i18n.tc('segments.filters.error.failedLinesMessage', lines.length > 1 ? 2 : 1, {
                    n: lines,
                }),
            ];
            this.showErrorModal = true;
        },
        getAffectedEntities() {
            return formatMutationDialogEntities(this.affectedEntities);
        },
        parseThresholds(thresholdInput) {
            const thresholds = thresholdInput?.toString().split(',').map(Number);
            if (
                thresholds?.every(element => {
                    const n = Math.floor(element);
                    return n !== Infinity && !Number.isNaN(n) && n > 0 && n <= 100000000;
                })
            ) {
                return thresholds;
            }
            return null;
        },
        updateUploadFilesStatus(status) {
            this.isUploadFilesInProgress = status;

            if (this.stayAfterSave && !status) {
                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('vouchers.editor.comfirmLeavingPage'),
                    type: ALERT_TYPES.warning,
                    buttons: [this.stayLeaveButtons.leave],
                });

                this.$eventBus.$once('buttonClicked', buttonId => {
                    if (buttonId === this.stayLeaveButtons.leave.id) {
                        this.redirectToVoucherSets();
                    }
                });
            }
        },
        updateAmountFilesUpload(totalAmount) {
            this.isFilesUploadAmount = !!totalAmount;
        },
        getTimeForVoucherCodeTable(timestamp) {
            return this.isGeneric ? this.$t('generic.generic') : this.$localeLibrary.getFormattedDateAndTime(timestamp);
        },

        onAddNamespaceClicked() {
            this.namespacesModalVisible = true;
        },
        onAddCategoryClicked() {
            this.categoryModalVisible = true;
        },
        onCloseNamespaceModal() {
            this.namespacesModalVisible = false;
        },
        onCloseCategoryeModal() {
            this.categoryModalVisible = false;
        },
        transformNamespaceNameToId(name) {
            const trimmed = name.trim();
            const lowercase = trimmed.toLowerCase();
            // Replace non-alphanumeric characters with underscores
            const replaced = lowercase.replace(/[^a-zA-Z0-9]+/g, '_');
            return replaced;
        },
        validateNamespaceName() {
            // pattern that matches letters, numbers, and underscores
            const namespaceIdPattern = /^[a-z0-9_]+$/;
            return this.newNamespaceName && namespaceIdPattern.test(this.newNamespaceId);
        },
        onNamespaceNameChanged(name) {
            this.newNamespaceId = this.transformNamespaceNameToId(name);
        },
        async getUpdateUserName(id) {
            try {
                if (id) {
                    const response = await getUserNameById(Number(id));
                    if (response?.data) {
                        this.updateName = response.data;
                    }
                }
            } catch (e) {
                Sentry.captureException(e);
            }
        },
        reloadEditor(isReadonly) {
            const { id } = this.$route.params;
            // Use push to list page because router don`t want to reload same page
            this.$router.push({ name: RouteNames.VOUCHER_SET_VIEW }).then(() => {
                this.$router.push({
                    name: RouteNames.VOUCHER_SET_EDITOR,
                    params: {
                        entityType: ENTITY_TYPES.VOUCHER_SET,
                        id,
                        readonly: isReadonly,
                    },
                });
            });
        },
        updatePageSize(size) {
            this.pageSize = size;
        },
        setReachedLastPage(val) {
            this.reachedLastPage = val.isReached;
        },
        isCodeMaskValid(codeMask) {
            if (!this.voucherData.codeAutoGeneration) {
                return true;
            }

            if (!codeMask || !codeMask.includes('#')) {
                return false;
            }

            const hashCount = this.voucherData.codeMask.match(/#/g).length;
            const characterSetCount = CODE_CHARACTER_SET.length;
            const maxCodeCount = characterSetCount ** hashCount;
            const codeCount = this.voucherData.autoGenerationOverrideDefault
                ? this.voucherData.voucherCodeCount
                : DEFAULT_VOUCHER_CODE_COUNT;

            return codeCount <= maxCodeCount;
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/consistency-spacing';
@import '~@/assets/scss/consistency-typography';
@import '~@/assets/scss/palette';
@import '~@/assets/scss/z-indexes';
@import '~@/assets/scss/editor-layout-v2';

.section-message {
    font-size: 0.75rem;
    margin-bottom: 0.75rem;
    color: $gray60;
}

.service-type-cards {
    padding: 1rem;
}

/* Solid border */
hr.solid {
    border-top: solid 1px $blue15;
    margin-top: 0.75rem;
}

.inputfile {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: $hide-smth-z-index;
}

.upload-button {
    font-size: 0.875rem;
    font-weight: 600;
    line-height: 1.86;
    color: $blue;
    cursor: pointer;
}

.inline-text {
    font-size: 0.75rem;
    text-transform: uppercase;
    font-weight: bold;
    color: $gray-blue;
    margin-right: 0.75rem;
}

.inline-text-right {
    font-size: 0.75rem;
    text-transform: uppercase;
    font-weight: bold;
    color: $gray-blue;
    margin-right: 0.75rem;
    margin-left: 0.75rem;
}

.explanation {
    line-height: 1.25rem;
    color: $gray60;
    font-size: 0.75rem;
    font-weight: 500;
}

.icon-card {
    background: $blue15;
    height: 2.5rem;
    width: 2.5rem;
    margin-bottom: 0.75rem;
    border-radius: 50%;
}

.editor-link {
    text-decoration: underline;
    cursor: pointer;
}

.tooltip-text {
    color: $gray90;
    min-width: 12.5rem;
    opacity: 1;
}

.sub-section-desc {
    font-size: 0.75rem;
    line-height: 1.67;
    color: $gray60;
}

.sub-section-title {
    font-size: 0.75rem;
    font-weight: 600;
    color: $gray90;
    margin-bottom: 0.3125rem;
}

.namespace-button {
    margin-left: 1.5rem;
}
</style>
