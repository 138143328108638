<template>
    <textarea
        :class="['no-outline', { resizable, invalid }]"
        :rows="rows"
        :disabled="disabled"
        :value="value"
        :placeholder="placeholder"
        @input="e => $emit('input', e.target.value)"
    >
<!-- force re-render with :value -->
    </textarea>
</template>

<script>
export default {
    name: 'CustomTextarea',
    props: {
        resizable: { type: Boolean, default: false },
        rows: { type: Number, default: 2 },
        disabled: { type: Boolean, default: false },
        value: { type: String, default: null },
        invalid: { type: Boolean, default: false },
        placeholder: { type: String, default: null },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/_palette';

textarea {
    padding: 0 12px;
    line-height: 32px;

    border: 1px solid $gray5;
    color: $navy;

    resize: none;

    &.invalid {
        border-color: $red;
    }

    &:focus {
        border-color: $blue;
    }

    &.resizable {
        resize: vertical;
    }

    &:disabled {
        color: $gray30;
        background-color: transparent;
    }

    &::placeholder {
        color: $gray30;
    }
}
</style>
