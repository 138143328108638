
import Vue from 'vue';
// components
import AppIcon from '@/components/partials/icon/AppIcon.vue';
// helpers
import { ICON_COLORS, ICON_TYPES } from '@/common/iconHelper';

export default Vue.extend({
    name: 'LabelWithVerificationStatus',
    components: {
        AppIcon,
    },
    props: {
        label: {
            type: String,
            required: true,
        },
        status: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        iconData() {
            return {
                color: this.status ? ICON_COLORS.GREEN : ICON_COLORS.RED,
                type: this.status ? ICON_TYPES.CHECK : ICON_TYPES.CLOSE,
                title: this.status ? this.$i18n.t('generic.verified') : this.$i18n.t('generic.notVerified'),
            };
        },
    },
});
