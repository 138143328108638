<template>
    <div class="schedule">
        <div class="days-container">
            <div class="condition-title">
                {{ $t('generic.day') }}
            </div>
            <div class="days">
                <div
                    v-for="day in scheduleConditionData.data.days"
                    :key="day.key"
                    :class="{ active: day.active }"
                    class="day"
                    @click="toggleDay(day)"
                >
                    {{ $t(day.i18nKey) }}
                </div>
            </div>
        </div>
        <div class="time-frames">
            <div class="condition-title">
                {{ $t('productCatalog.services.editor.conditions.dateTime.timeFrame') }}
            </div>
            <div
                v-for="(ts, index) in scheduleConditionData.data.timeSlots"
                :key="index"
                class="time-slot d-flex mb-3"
                @mouseover="hoveredTimeSlot = index"
                @mouseleave="hoveredTimeSlot = null"
            >
                <div class="d-flex align-items-center">
                    <span class="time-frame-label">{{ $t('generic.from') }}</span>
                    <AppInputV3
                        v-model="ts.s"
                        :invalid="isTimeSlotStringInvalid[index].s"
                        placeholder="00:00"
                        class="time-slot-value"
                        :data-test-id="`timeSlotStart-${index}`"
                    />
                </div>
                <div class="ml-2 mr-4 d-flex align-items-center">
                    <span class="time-frame-label">{{ $t('generic.to') }}</span>
                    <!-- TODO: Create TimeInput component that would handle string input (custom input type=time)
                    but provide : in the middle and prevent non numeric chars from being inputted.
                    Also would support both 24 and AM/PM formats. -->
                    <AppInputV3
                        v-model="ts.e"
                        :invalid="isTimeSlotStringInvalid[index].e"
                        placeholder="23:59"
                        class="time-slot-value"
                        :data-test-id="`timeSlotEnd-${index}`"
                    />
                </div>
                <img
                    v-if="hoveredTimeSlot === index"
                    src="~@/assets/icons/delete-trash.svg"
                    alt="remove"
                    class="cursor-pointer"
                    :data-test-id="`removeTimeSlot-${index}`"
                    @click="removeTimeSlot(index)"
                />
                <div
                    v-else
                    class="trash-placeholder"
                />
            </div>
            <div
                class="add-timeslot cursor-pointer"
                data-test-id="addTimeSlot"
                @click="addTimeSlot"
            >
                <img
                    src="~@/assets/icons/plus-blue.svg"
                    alt="plus"
                    class="mr-2"
                />
                {{ $t('productCatalog.services.editor.conditions.dateTime.addTimeframe') }}
            </div>
        </div>
    </div>
</template>

<script>
// Vue components
import AppInputV3 from '@/components/partials/inputs/AppInputV3.vue';
import { scheduleInitValue } from '@/__new__/services/dno/charging/common/chargingSpecificationHelper';
import ScheduleCondition from '@/__new__/services/dno/charging/models/chargingSpecificationConditions/ScheduleCondition';

class TimeSlot {
    constructor(s = '', e = '') {
        this.s = s;
        this.e = e;
    }
}

export default {
    name: 'Schedule',
    components: { AppInputV3 },
    model: {
        prop: 'value',
        event: 'updateSchedule',
    },
    props: {
        // Here ideally if would be able to define interface if we had TS
        // or something similar and just comply to that interface (Object as Class/Type/Interface)
        // so in a way safest method here is having shared init object that can be customized.
        // I can provide class or a factory function to provide a bit nicer abstraction for customizing it
        // but it is not required at the moment.
        value: {
            type: Object,
            default: () => scheduleInitValue,
        },
    },
    data() {
        return {
            hoveredTimeSlot: null,
            scheduleConditionData: new ScheduleCondition(this.value),
        };
    },
    computed: {
        isTimeSlotStringInvalid() {
            return this.scheduleConditionData.data.timeSlots.map(ts => ({
                s: !this.isTimeValid(ts.s),
                e: !this.isTimeValid(ts.e),
            }));
        },
    },
    watch: {
        value: {
            handler() {
                this.scheduleConditionData = new ScheduleCondition(this.value);
            },
        },
    },
    methods: {
        callValidateFunctionAndEmitData() {
            this.scheduleConditionData.validate();
            this.$emit('updateSchedule', this.scheduleConditionData);
        },
        toggleDay(day) {
            this.scheduleConditionData.data.days[day.key].active =
                !this.scheduleConditionData.data.days[day.key].active;
            this.callValidateFunctionAndEmitData();
        },
        addTimeSlot() {
            this.scheduleConditionData.data.timeSlots.push(new TimeSlot());
            this.callValidateFunctionAndEmitData();
        },
        removeTimeSlot(index) {
            this.scheduleConditionData.data.timeSlots.splice(index, 1);
            this.callValidateFunctionAndEmitData();
        },
        isTimeValid(time) {
            if (!time) {
                return true;
            }
            const hoursMinutesAndSeconds = time.split(':');
            // check if it has a ":" character
            const hasColonSymbol = hoursMinutesAndSeconds.length === 2;
            if (!hasColonSymbol) {
                return false;
            }
            const hours = Number(hoursMinutesAndSeconds[0]);
            if (hours < 0 || hours > 23) {
                return false;
            }
            let minutes = hoursMinutesAndSeconds[1];
            if (minutes.length < 2) {
                return false;
            }
            minutes = Number(minutes);
            if (minutes < 0 || minutes > 59) {
                return false;
            }

            return true;
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/palette';

.condition-title {
    font-size: 0.75rem;
    font-weight: 600;

    margin-bottom: 1rem;
    margin-right: 1rem;

    color: $gray90;

    text-transform: capitalize;
}

.schedule {
    display: flex;
    flex-wrap: wrap;
}

.days-container,
.time-frames {
    flex: 1;
}

.days {
    display: flex;
    flex-wrap: wrap;
}

.day {
    width: 50px;
    height: 32px;

    border-radius: 0.5rem;
    // just to make rgba color work on the border
    background-clip: padding-box;
    border: solid 1px $blue15;

    background-color: $white;

    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 0.75rem;
    font-weight: 600;

    color: $blue;

    margin: 0.25rem;

    &:first-child {
        margin-left: 0;
    }

    &:last-child {
        margin-right: 0;
    }

    &.active {
        background: $blue15;
    }
}

.time-frame-label {
    font-weight: bold;
    font-size: 0.75rem;

    margin-right: 0.5rem;

    text-transform: uppercase;

    color: $steel;
}

.trash-placeholder {
    width: 16px;
    height: 16px;
    color: transparent;
    content: '';
}

.time-slot-value {
    width: 100px;
}

.cursor-pointer {
    cursor: pointer;
}

.add-timeslot {
    font-size: 0.75rem;

    display: flex;
    align-items: center;

    margin-top: 1rem;
}
</style>
