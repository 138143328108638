<template><div /></template>

<script>
export default {
    name: 'OperationInProcess',
    data() {
        return {
            isOperationInProcess: false,
        };
    },
    methods: {
        /*
                Function wrapper to prevent async operations duplication where needed.
                -------------------------------
                Operation in process will block start of another wrapped operations on the page.
                So only one active operation is possible among all wrapped operations on the page.
             */
        async runOperationIfPossible(operation) {
            if (this.isOperationInProcess) {
                return;
            }
            try {
                this.isOperationInProcess = true;
                await operation();
            } catch (err) {
                // Should be catched in operation
            } finally {
                this.isOperationInProcess = false;
            }
        },
    },
};
</script>
