
import Vue, { PropType } from 'vue';

// Components
import AppMultiselectV3 from '@/components/partials/inputs/AppMultiselectV3.vue';
import AppInputV3 from '@/components/partials/inputs/AppInputV3.vue';

// Models
import type DataflowElement from '@/__new__/services/dno/dataflows/models/DataflowElement';

// Helpers
import { SECTION_TYPE } from '@/__new__/features/dataflows/common/DataflowInterfaces';

export default Vue.extend({
    name: 'DataflowElement',
    components: {
        AppMultiselectV3,
        AppInputV3,
    },
    props: {
        value: {
            type: Object as PropType<DataflowElement>,
            required: true,
        },
    },
    data() {
        return {
            SECTION_TYPE,
            element: this.value,
        };
    },
    computed: {
        elementErrorMsg(): string {
            return this.element?.getValue && this.element?.regex
                ? this.element.regex.message
                : this.$t('generic.validations.fieldIsRequired');
        },
        validateElementByRegex(): boolean {
            if (this.element?.regex?.expression) {
                const match = (this.element?.getValue as string | null)?.match(
                    new RegExp(this.element?.regex?.expression, 'gi'),
                );
                return match === null;
            }

            return false;
        },
        isInputField(): boolean {
            return [SECTION_TYPE.TEXT_INPUT, SECTION_TYPE.NUMBER_INPUT].includes(this.element.type as SECTION_TYPE);
        },
        inputFieldType(): string {
            return this.element.type === SECTION_TYPE.TEXT_INPUT ? 'text' : 'number';
        },
    },
    watch: {
        value: {
            immediate: true,
            deep: true,
            handler(value) {
                this.element = value;
            },
        },
    },
    methods: {
        validateElement() {
            this.element.error =
                this.element.required && (this.element.getValue === null || this.validateElementByRegex);
        },
        onElementChange(): void {
            this.validateElement();
            this.$emit('input', this.element);
        },
    },
});
