<template>
    <AppAditionalSidebar
        :visible="showSidebar"
        @input="val => hideSidebar(val)"
    >
        <template #header>
            <div class="modal-row d-flex align-items-center mt-2">
                <div class="all-caps-text">
                    {{ $i18n.t('customerCare.orderDetails') }}
                </div>
            </div>
        </template>
        <template #content>
            <template>
                <div
                    v-if="selectedParentOrder"
                    class="order-wrap"
                >
                    <AppMultiselectV3
                        v-model="selectedOffer"
                        :options="offerOptions"
                        :isLabelColorLight="true"
                        :showLabels="false"
                        :isSmall="true"
                        :additionalLabel="$i18n.t('customerCare.orderHistory.offer')"
                        label="entity_name"
                        trackBy="entity_id"
                        class="mb-4"
                        data-test-id="additional-sidebar-order-entities-offer"
                        @select="filterProducts"
                    />
                    <template v-if="selectedOffer">
                        <div class="modal-row d-flex align-items-center">
                            <div class="all-caps-text">
                                {{ selectedOffer.entity_name }}
                            </div>
                        </div>
                        <div class="modal-row d-flex align-items-center">
                            <div class="row-title">
                                {{ $i18n.t('generic.id') }}
                            </div>

                            <div class="row-value">
                                {{ selectedOffer.entity_id }}
                            </div>
                        </div>

                        <div class="modal-row d-flex align-items-center">
                            <div class="row-title">
                                {{ $i18n.t('generic.quantity') }}
                            </div>

                            <div class="row-value">
                                {{ selectedOffer.quantity }}
                            </div>
                        </div>

                        <div class="modal-row d-flex align-items-center">
                            <div class="row-title">
                                {{ $i18n.t('generic.status') }}
                            </div>
                            <EntityStatusIndicator
                                v-if="!isUndefined(selectedOffer.state)"
                                :stateMap="ORDER_ENTITY_STATES_TO_STATUS_NAME_MAP"
                                :stateMapColor="ORDER_STATUS_INDICATOR_MAP"
                                :status="selectedOffer.state"
                                :isInTable="false"
                                class="row-value"
                            />
                        </div>
                    </template>
                </div>
            </template>
            <template v-if="selectedOffer && Object.keys(filteredProducts).length > 0">
                <div class="order-wrap">
                    <AppMultiselectV3
                        v-model="selectedProduct"
                        :options="Object.values(filteredProducts)"
                        :isLabelColorLight="true"
                        :showLabels="false"
                        :isSmall="true"
                        :additionalLabel="$i18n.t('customerCare.orderHistory.product')"
                        label="entity_name"
                        trackBy="entity_id"
                        class="mb-4"
                    />
                    <template v-if="selectedProduct">
                        <div class="modal-row d-flex align-items-center">
                            <div class="all-caps-text">
                                {{ selectedProduct.entity_name }}
                            </div>
                        </div>
                        <div class="modal-row d-flex align-items-center">
                            <div class="row-title">
                                {{ $i18n.t('generic.id') }}
                            </div>

                            <div class="row-value">
                                {{ selectedProduct.entity_id }}
                            </div>
                        </div>

                        <div class="modal-row d-flex align-items-center">
                            <div class="row-title">
                                {{ $i18n.t('generic.quantity') }}
                            </div>

                            <div class="row-value">
                                {{ selectedProduct.quantity }}
                            </div>
                        </div>

                        <div class="modal-row d-flex align-items-center">
                            <div class="row-title">
                                {{ $i18n.t('generic.status') }}
                            </div>
                            <EntityStatusIndicator
                                v-if="!isUndefined(selectedProduct.state)"
                                :stateMap="ORDER_ENTITY_STATES_TO_STATUS_NAME_MAP"
                                :stateMapColor="ORDER_STATUS_INDICATOR_MAP"
                                :status="selectedProduct.state"
                                :isInTable="false"
                                class="row-value"
                            />
                        </div>
                    </template>
                </div>
            </template>
            <template v-if="selectedParentOrder && isStateHistoryNotEmpty">
                <section class="order-wrap mb-5">
                    <div class="modal-row d-flex align-items-center">
                        <div class="all-caps-text">
                            {{ $i18n.t('customerCare.stateHistory') }}
                        </div>
                    </div>
                    <OrderStateHistory v-model="selectedParentOrder.orderStateHistory" />
                </section>
            </template>
            <template v-if="isOePlansVisible">
                <div class="modal-row d-flex align-items-center">
                    <div class="all-caps-text">
                        {{ $i18n.t('orchestrationEngine.oeWorkflow') }}
                    </div>
                </div>
                <AppTable
                    :entities="oeMappingsList"
                    :isDefaultHoverEnabled="false"
                    :forbidHideColumn="true"
                    :disableBoxShadow="true"
                    :newDesign="true"
                    :canSelectColumns="false"
                    :isDataLoading="isOeLoading"
                    :columnsData="oeColumnsData"
                    tableKey="user/account-oe"
                    keyName="executionId"
                    class="auto-height"
                >
                    <template #planId="{ entity }">
                        <div
                            v-if="entity.planId"
                            class="truncate-text"
                        >
                            <router-link
                                :to="{
                                    name: RouteNames.ORCHESTRATION_ENGINE_EXECUTION_DETAILS,
                                    params: {
                                        id: entity.planId,
                                        execId: entity.executionId,
                                    },
                                }"
                                target="_blank"
                            >
                                {{ entity.planId }}
                            </router-link>
                        </div>
                    </template>
                    <template #status="{ entity }">
                        <ExecutionStatusIndicator :status="entity.status" />
                    </template>
                </AppTable>
            </template>
            <template v-if="isOeExecutionsHierarchyTableVisible">
                <div class="modal-row d-flex align-items-center">
                    <div class="all-caps-text">
                        {{ $i18n.t('orchestrationEngine.oeExecutionsHierarchy') }}
                    </div>
                </div>
                <OeExecutionsHierarchyTable
                    :isDataLoading="isExecutionsHierarchyTableLoading"
                    :oeMappingsList="executionsHierarchyToDisplay"
                />
            </template>
        </template>
    </AppAditionalSidebar>
</template>
<script>
// COMPONENTS
import AppAditionalSidebar from '@/components/partials/AppAditionalSidebar.vue';
import AppMultiselectV3 from '@/components/partials/inputs/AppMultiselectV3.vue';
import EntityStatusIndicator from '@/components/partials/EntityStatusIndicator.vue';
import ExecutionStatusIndicator from '@/__new__/features/orchestrationengine/ExecutionStatusIndicator.vue';
import AppTable from '@/components/partials/AppTable.vue';
import OrderStateHistory from '@/__new__/features/customerCare/OrderStateHistory.vue';
import OeExecutionsHierarchyTable from '@/__new__/features/customerCare/OeExecutionsHierarchyTable.vue';

// HELPERS
import {
    ORDER_ENTITY_STATES_TO_STATUS_NAME_MAP,
    ORDER_STATUS_INDICATOR_MAP,
} from '@/__new__/features/customerCare/common/orderStateHelper';
import { isUndefined } from 'lodash';
import RouteNames from '@/router/routeNames';
import OePlansMixin from '@/__new__/features/customerCare/mixins/OePlansMixin.vue';
import permissionsService, { isUserAllowed } from '@/services/permissions/permissions.service';
import { ALERT_TYPES } from '@/common/alerts/Alert';
import {
    EXECUTION_STATES_CODES,
    EXECUTIONS_HIERARCHY_EXECUTION_STATES_CODES,
} from '@/__new__/features/orchestrationengine/common/executionStatusIndicatorHelper';

// HTTP
import { getExecutionHierarchy } from '@/__new__/services/dno/orchestrationengine/http/orchestration-engine';

export default {
    name: 'AdditionalSidebarOrdersEntities',
    components: {
        AppAditionalSidebar,
        AppMultiselectV3,
        EntityStatusIndicator,
        ExecutionStatusIndicator,
        AppTable,
        OrderStateHistory,
        OeExecutionsHierarchyTable,
    },
    mixins: [OePlansMixin],
    props: {
        selectedParentOrder: {
            type: Object,
            default: null,
        },
        showAdditionalSidebar: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            selectedOffer: null,
            selectedProduct: null,
            filteredProducts: {},
            isSidebarVisible: false,
            executionsHierarchy: [],
            executionsHierarchyToDisplay: [],
            isExecutionsHierarchyTableLoading: false,

            // Proxy imports
            ORDER_ENTITY_STATES_TO_STATUS_NAME_MAP,
            ORDER_STATUS_INDICATOR_MAP,
            RouteNames,
            permissionsService,
        };
    },
    computed: {
        showSidebar() {
            return this.isSidebarVisible;
        },
        offerOptions() {
            return Object.values(this.selectedParentOrder?.entities?.offer || {});
        },
        isStateHistoryNotEmpty() {
            return this.selectedParentOrder.orderStateHistory && this.selectedParentOrder.orderStateHistory.length;
        },
        isOePlansVisible() {
            return (
                this.oePlansEnabled &&
                permissionsService.isUserLotusFlareAdmin() &&
                isUserAllowed('UMAccountOrderDetailsOEPlans')
            );
        },
        isOeExecutionsHierarchyTableVisible() {
            return (
                permissionsService.userManagementOEExecutionHierarchyEnabled() &&
                permissionsService.isUserLotusFlareAdmin()
            );
        },
    },
    watch: {
        // copy value of prop showAdditionalSidebar to isSidebarVisible data to avoid mutation prop error in console
        showAdditionalSidebar: {
            handler() {
                this.isSidebarVisible = this.showAdditionalSidebar;
            },
        },
        // sending back to the parent component everytime
        // the sidebar is closed in order to opet it again from parent component
        isSidebarVisible: {
            handler() {
                // unselect subOrder everytime sidebar is closed
                if (!this.isSidebarVisible) {
                    this.resetSidebarData();
                }
            },
        },
        selectedParentOrder: {
            async handler() {
                this.resetSidebarData();
                this.oeMappingsList = [];
                this.executionsHierarchy = [];
                this.executionsHierarchyToDisplay = [];
                this.isExecutionsHierarchyTableLoading = true;

                if (this.oePlansEnabled && this.selectedParentOrder) {
                    await this.fetchExecutions({
                        mapper_ids: [this.selectedParentOrder.orderId],
                    });

                    if (this.permissionsService.userManagementOEExecutionHierarchyEnabled()) {
                        // Filtering executions from create_order_external plan in order to show their children
                        const executionsWithChildren = this.oeMappingsList.filter(
                            execution => execution.planId === 'create_order_external',
                        );

                        if (executionsWithChildren.length) {
                            executionsWithChildren.forEach(async (execution, index) => {
                                await this.fetchOEExecutionsHierarchy(execution.executionId);

                                if (Object.keys(this.executionsHierarchy).length) {
                                    this.executionsHierarchyToDisplay.push(this.executionsHierarchy);
                                }

                                if (index === executionsWithChildren.length - 1) {
                                    this.isExecutionsHierarchyTableLoading = false;
                                }
                            });
                        } else {
                            this.isExecutionsHierarchyTableLoading = false;
                        }
                    }
                }
            },
        },
        selectedOffer: {
            handler() {
                if (!this.selectedOffer) {
                    this.resetSidebarData();
                }
            },
        },
    },
    methods: {
        isUndefined,
        hideSidebar(val) {
            this.isSidebarVisible = val;
            this.$emit('closeSidebar', this.isSidebarVisible);
        },
        resetSidebarData() {
            this.selectedOffer = null;
            this.selectedProduct = null;
            this.filteredProducts = {};
        },
        filterProducts() {
            if (this.selectedParentOrder.entities?.product) {
                for (const product in this.selectedParentOrder.entities.product) {
                    // eslint-disable-next-line max-len
                    if (
                        this.selectedParentOrder.entities.product[product].parent_entity_id ===
                        this.selectedOffer.entity_id
                    ) {
                        this.filteredProducts[product] = this.selectedParentOrder.entities.product[product];
                    }
                }
            }
        },
        formatOeExecutionsHierarchy(execution) {
            const startedAt = execution?.started_at || '';
            const finishedAt = execution?.finished_at || '';
            const scheduledFor = execution?.scheduled_for || '';

            return {
                planId: execution?.plan_id || '',
                executionId: execution?.execution_id || '',
                startedAtFormatted: this.$localeLibrary.getFormattedDateAndTimeWithSecondsPrecision(startedAt),
                finishedAtFormatted: this.$localeLibrary.getFormattedDateAndTimeWithSecondsPrecision(finishedAt),
                executionStatus: this.formatExecutionStatus(execution),
                scheduledForFormatted: this.$localeLibrary.getFormattedDateAndTimeWithSecondsPrecision(scheduledFor),
                children: execution?.children
                    ? execution.children.map(child => this.formatOeExecutionsHierarchy(child))
                    : [],
                visibility: this.formatVisibility(execution?.visibility?.is_external),
                comments: this.formatComments(execution?.visibility?.comments),
            };
        },
        formatExecutionStatus(execution) {
            const status = execution?.status;
            const visibility = execution?.visibility?.is_external;

            switch (status) {
                case EXECUTION_STATES_CODES.SUCCESS:
                    return EXECUTIONS_HIERARCHY_EXECUTION_STATES_CODES.SUCCESS;

                case EXECUTION_STATES_CODES.FAILED:
                    return EXECUTIONS_HIERARCHY_EXECUTION_STATES_CODES.FAILED;

                case EXECUTION_STATES_CODES.STOPPED:
                    return EXECUTIONS_HIERARCHY_EXECUTION_STATES_CODES.STOPPED;

                case EXECUTION_STATES_CODES.PENDING:
                    return EXECUTIONS_HIERARCHY_EXECUTION_STATES_CODES.PENDING;

                case EXECUTION_STATES_CODES.WAITINGFORRESTART:
                    if (visibility !== undefined) {
                        return visibility
                            ? EXECUTIONS_HIERARCHY_EXECUTION_STATES_CODES.FAILED_EXTERNAL
                            : EXECUTIONS_HIERARCHY_EXECUTION_STATES_CODES.FAILED_INTERNAL;
                    }
                    return '';

                case EXECUTION_STATES_CODES.EXECUTING:
                case EXECUTION_STATES_CODES.WAITINGFORCALLBACK:
                    if (visibility !== undefined) {
                        return visibility
                            ? EXECUTIONS_HIERARCHY_EXECUTION_STATES_CODES.EXECUTING_EXTERNAL
                            : EXECUTIONS_HIERARCHY_EXECUTION_STATES_CODES.EXECUTING_INTERNAL;
                    }
                    return '';

                default:
                    return '';
            }
        },
        formatVisibility(visibility) {
            if (visibility !== undefined) {
                if (visibility) {
                    return this.$i18n.t('orchestrationEngine.external');
                }
                return this.$i18n.t('orchestrationEngine.internal');
            }

            return '';
        },
        formatComments(commments) {
            if (commments !== undefined) {
                return commments.join(', ');
            }

            return '';
        },
        async fetchOEExecutionsHierarchy(executionId) {
            try {
                const executionsHierarchyResponse = await getExecutionHierarchy(executionId);

                this.executionsHierarchy = this.formatOeExecutionsHierarchy(executionsHierarchyResponse.data);
            } catch (error) {
                this.showSupportAlert(this.$i18n.t('alertMessage.somethingWentWrong'), ALERT_TYPES.error);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/palette';
@import '~@/assets/scss/base';

.auto-height {
    height: auto !important;
}

.order-wrap {
    margin-bottom: 1.75rem;
    min-height: 5rem;
}

.modal-row {
    margin-bottom: 0.875rem;
}

.row-title {
    font-size: 0.75rem;
    font-weight: 600;
    width: 7.875rem;
}

.row-value {
    font-size: 0.875rem;
    width: 100%;
}
</style>
