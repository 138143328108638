import i18n from '@/i18n';
import { isNaN } from 'lodash';
import { TranslateResult } from 'vue-i18n';

const isValueBoolean = (value: any): boolean => typeof value === 'boolean';
const isValueNumeric = (value: any): boolean => !isNaN(value);

interface OperatorOptionForDiagramUpdate {
    key: string;
    label: string | TranslateResult;
    validator?: (value: any) => boolean;
    errorMessage?: string | TranslateResult;
    type: string;
}

export enum STATE_TYPES {
    CHOICE = 'choice',
    CATCH = 'catch',
    LoopV2 = 'loop_v2',
}

export enum FLAG_VALUE_OPTIONS {
    SIMPLE = 'simple',
    AND = 'and',
    OR = 'or',
}

export const FLAG_VALUE_OPTIONS_TO_LABEL: Map<string, TranslateResult> = new Map([
    [FLAG_VALUE_OPTIONS.SIMPLE, i18n.t('orchestrationEngine.updateDiagramModal.flagValues.simple')],
    [FLAG_VALUE_OPTIONS.AND, i18n.t('orchestrationEngine.updateDiagramModal.flagValues.and')],
    [FLAG_VALUE_OPTIONS.OR, i18n.t('orchestrationEngine.updateDiagramModal.flagValues.or')],
]);

export enum IS_NOT_ENUMS {
    EMPTY = 1,
    IS_NOT = 2,
}

export const NOT_OPTIONS: { id: number; label: string | TranslateResult }[] = [
    {
        id: IS_NOT_ENUMS.EMPTY,
        label: '',
    },
    {
        id: IS_NOT_ENUMS.IS_NOT,
        label: i18n.t('orchestrationEngine.updateDiagramModal.not'),
    },
];

export enum TYPES_FOR_OPERATOR {
    BOOLEAN = 'boolean',
    NUMERIC = 'numeric',
    STRING = 'string',
    TIMESTAMP = 'timestamp',
}

export const operatorOptionsForDiagramUpdate: { [key: string]: OperatorOptionForDiagramUpdate } = {
    is_boolean: {
        key: 'is_boolean',
        label: i18n.t('orchestrationEngine.updateDiagramModal.conditions.isBoolean'),
        validator: value => isValueBoolean(value),
        errorMessage: i18n.t('orchestrationEngine.updateDiagramModal.errorMessages.booleanValidation'),
        type: TYPES_FOR_OPERATOR.BOOLEAN,
    },
    is_empty: {
        key: 'is_empty',
        label: i18n.t('orchestrationEngine.updateDiagramModal.conditions.isEmpty'),
        validator: value => isValueBoolean(value),
        errorMessage: i18n.t('orchestrationEngine.updateDiagramModal.errorMessages.booleanValidation'),
        type: TYPES_FOR_OPERATOR.BOOLEAN,
    },
    is_nil: {
        key: 'is_nil',
        label: i18n.t('orchestrationEngine.updateDiagramModal.conditions.isNil'),
        validator: value => isValueBoolean(value),
        errorMessage: i18n.t('orchestrationEngine.updateDiagramModal.errorMessages.booleanValidation'),
        type: TYPES_FOR_OPERATOR.BOOLEAN,
    },
    is_null: {
        key: 'is_null',
        label: i18n.t('orchestrationEngine.updateDiagramModal.conditions.isNull'),
        validator: value => isValueBoolean(value),
        errorMessage: i18n.t('orchestrationEngine.updateDiagramModal.errorMessages.booleanValidation'),
        type: TYPES_FOR_OPERATOR.BOOLEAN,
    },
    is_number: {
        key: 'is_number',
        label: i18n.t('orchestrationEngine.updateDiagramModal.conditions.isNumber'),
        validator: value => isValueBoolean(value),
        errorMessage: i18n.t('orchestrationEngine.updateDiagramModal.errorMessages.booleanValidation'),
        type: TYPES_FOR_OPERATOR.BOOLEAN,
    },
    is_string: {
        key: 'is_string',
        label: i18n.t('orchestrationEngine.updateDiagramModal.conditions.isString'),
        validator: value => isValueBoolean(value),
        errorMessage: i18n.t('orchestrationEngine.updateDiagramModal.errorMessages.booleanValidation'),
        type: TYPES_FOR_OPERATOR.BOOLEAN,
    },
    boolean_equals: {
        key: 'boolean_equals',
        label: i18n.t('orchestrationEngine.updateDiagramModal.conditions.booleanEquals'),
        validator: value => isValueBoolean(value),
        errorMessage: i18n.t('orchestrationEngine.updateDiagramModal.errorMessages.booleanValidation'),
        type: TYPES_FOR_OPERATOR.BOOLEAN,
    },
    timestamp_equals: {
        key: 'timestamp_equals',
        label: i18n.t('orchestrationEngine.updateDiagramModal.conditions.timestampEquals'),
        validator: value => isValueNumeric(value),
        errorMessage: i18n.t('orchestrationEngine.updateDiagramModal.errorMessages.timestampValidation'),
        type: TYPES_FOR_OPERATOR.TIMESTAMP,
    },
    timestamp_less_than: {
        key: 'timestamp_less_than',
        label: i18n.t('orchestrationEngine.updateDiagramModal.conditions.timestampLessThan'),
        validator: value => isValueNumeric(value),
        errorMessage: i18n.t('orchestrationEngine.updateDiagramModal.errorMessages.timestampValidation'),
        type: TYPES_FOR_OPERATOR.TIMESTAMP,
    },
    timestamp_less_than_equals: {
        key: 'timestamp_less_than_equals',
        label: i18n.t('orchestrationEngine.updateDiagramModal.conditions.timestampLessThanEquals'),
        validator: value => isValueNumeric(value),
        errorMessage: i18n.t('orchestrationEngine.updateDiagramModal.errorMessages.timestampValidation'),
        type: TYPES_FOR_OPERATOR.TIMESTAMP,
    },
    timestamp_greater_than: {
        key: 'timestamp_greater_than',
        label: i18n.t('orchestrationEngine.updateDiagramModal.conditions.timestampGreaterThan'),
        validator: value => isValueNumeric(value),
        errorMessage: i18n.t('orchestrationEngine.updateDiagramModal.errorMessages.timestampValidation'),
        type: TYPES_FOR_OPERATOR.TIMESTAMP,
    },
    timestamp_greater_than_equals: {
        key: 'timestamp_greater_than_equals',
        label: i18n.t('orchestrationEngine.updateDiagramModal.conditions.timestampGreaterThanEquals'),
        validator: value => isValueNumeric(value),
        errorMessage: i18n.t('orchestrationEngine.updateDiagramModal.errorMessages.timestampValidation'),
        type: TYPES_FOR_OPERATOR.TIMESTAMP,
    },
    string_equals: {
        key: 'string_equals',
        label: i18n.t('orchestrationEngine.updateDiagramModal.conditions.stringEquals'),
        errorMessage: '',
        type: TYPES_FOR_OPERATOR.STRING,
    },
    string_less_than: {
        key: 'string_less_than',
        label: i18n.t('orchestrationEngine.updateDiagramModal.conditions.stringLessThan'),
        errorMessage: '',
        type: TYPES_FOR_OPERATOR.STRING,
    },
    string_less_than_equals: {
        key: 'string_less_than_equals',
        label: i18n.t('orchestrationEngine.updateDiagramModal.conditions.stringLessThanEquals'),
        errorMessage: '',
        type: TYPES_FOR_OPERATOR.STRING,
    },
    string_greater_than: {
        key: 'string_greater_than',
        label: i18n.t('orchestrationEngine.updateDiagramModal.conditions.stringGreaterThan'),
        errorMessage: '',
        type: TYPES_FOR_OPERATOR.STRING,
    },
    string_greater_than_equals: {
        key: 'string_greater_than_equals',
        label: i18n.t('orchestrationEngine.updateDiagramModal.conditions.stringGreaterThanEquals'),
        errorMessage: '',
        type: TYPES_FOR_OPERATOR.STRING,
    },
    string_contains: {
        key: 'string_contains',
        label: i18n.t('orchestrationEngine.updateDiagramModal.conditions.stringContains'),
        errorMessage: '',
        type: TYPES_FOR_OPERATOR.STRING,
    },
    numeric_equals: {
        key: 'numeric_equals',
        label: i18n.t('orchestrationEngine.updateDiagramModal.conditions.numericEquals'),
        validator: value => isValueNumeric(value),
        errorMessage: i18n.t('orchestrationEngine.updateDiagramModal.errorMessages.numericValidation'),
        type: TYPES_FOR_OPERATOR.NUMERIC,
    },
    numeric_less_than: {
        key: 'numeric_less_than',
        label: i18n.t('orchestrationEngine.updateDiagramModal.conditions.numericLessThan'),
        validator: value => isValueNumeric(value),
        errorMessage: i18n.t('orchestrationEngine.updateDiagramModal.errorMessages.numericValidation'),
        type: TYPES_FOR_OPERATOR.NUMERIC,
    },
    numeric_less_than_equals: {
        key: 'numeric_less_than_equals',
        label: i18n.t('orchestrationEngine.updateDiagramModal.conditions.numericLessThanEquals'),
        validator: value => isValueNumeric(value),
        errorMessage: i18n.t('orchestrationEngine.updateDiagramModal.errorMessages.numericValidation'),
        type: TYPES_FOR_OPERATOR.NUMERIC,
    },
    numeric_greater_than: {
        key: 'numeric_greater_than',
        label: i18n.t('orchestrationEngine.updateDiagramModal.conditions.numericGreaterThan'),
        validator: value => isValueNumeric(value),
        errorMessage: i18n.t('orchestrationEngine.updateDiagramModal.errorMessages.numericValidation'),
        type: TYPES_FOR_OPERATOR.NUMERIC,
    },
    numeric_greater_than_equals: {
        key: 'numeric_greater_than_equals',
        label: i18n.t('orchestrationEngine.updateDiagramModal.conditions.numericGreaterThanEquals'),
        validator: value => isValueNumeric(value),
        errorMessage: i18n.t('orchestrationEngine.updateDiagramModal.errorMessages.numericValidation'),
        type: TYPES_FOR_OPERATOR.NUMERIC,
    },
};

export const formatValueBasedOnSelectedOperator = (value: any, condition: any): string | number | boolean => {
    if (operatorOptionsForDiagramUpdate[condition.selectedOperator].type === TYPES_FOR_OPERATOR.BOOLEAN) {
        if (value.toLowerCase() === 'true' || value.toLowerCase() === 'false') {
            return value.toLowerCase() === 'true';
        }
        return '';
    }

    if (
        operatorOptionsForDiagramUpdate[condition.selectedOperator].type === TYPES_FOR_OPERATOR.NUMERIC ||
        operatorOptionsForDiagramUpdate[condition.selectedOperator].type === TYPES_FOR_OPERATOR.TIMESTAMP
    ) {
        return Number(value);
    }

    return value;
};

/**
 * Function for formatting value based on selected operator
 * @param value
 * @param operator
 */

export function formatValueBasedOnOperator(value: any, operator: any): number | string | boolean {
    switch (operator) {
        case TYPES_FOR_OPERATOR.NUMERIC:
        case TYPES_FOR_OPERATOR.TIMESTAMP:
            return Number(value);
        case TYPES_FOR_OPERATOR.BOOLEAN:
            return value.toLowerCase() === 'true';
        default:
            return value;
    }
}

export default {
    STATE_TYPES,
    FLAG_VALUE_OPTIONS,
    IS_NOT_ENUMS,
    NOT_OPTIONS,
    FLAG_VALUE_OPTIONS_TO_LABEL,
    formatValueBasedOnOperator,
};
