
// components
import IconButton from '@/components/partials/IconButton.vue';
// helpers
import AppButton from '@/components/partials/inputs/AppButton.vue';
import { ICON_COLORS, ICON_TYPES } from '@/common/iconHelper';
import AppIcon from '@/components/partials/icon/AppIcon.vue';
import { isEmpty } from 'lodash';
import { LOGICAL_OPERATORS } from '@/common/segments';
import { CohortExpressionOverviewGroup } from '@/components/partials/CohortExpressionOverview.vue';
import { PropType } from 'vue';
import { isUserAllowed } from '@/services/permissions/permissions.service';

export default {
    name: 'CohortExpressionOverviewItem',
    components: {
        AppButton,
        AppIcon,
        IconButton,
    },
    props: {
        groups: {
            type: Array as PropType<CohortExpressionOverviewGroup[]>,
            default: () => [],
        },
        title: {
            type: String,
            default: '',
        },
        shouldShowFullGroupsData: {
            type: Boolean,
            default: false,
        },
        titleButtonLabel: {
            type: String,
            default: '',
        },
        maxItemRows: {
            type: Number,
            default: 5,
        },
    },
    data() {
        return {
            ICON_TYPES,
            ICON_COLORS,
            LOGICAL_OPERATORS,
            isEmpty,
        };
    },
    computed: {},
    methods: {
        shouldShowNavigateBtn(rowId: string | number) {
            return !!rowId && isUserAllowed('SegmentsRead');
        },
        shouldShowCombinatorLine(group: CohortExpressionOverviewGroup) {
            return group.rows?.length > 1;
        },
        shouldShowCombinator(group: CohortExpressionOverviewGroup, rowIndex: number) {
            return rowIndex !== group.rows?.length - 1;
        },
    },
};
