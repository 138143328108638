<template>
    <AppDialogV2
        :visible="true"
        :title="heading"
        @close="$emit('close')"
    >
        <div v-if="paragraphList.length > 0">
            <div
                v-for="paragraph in paragraphList"
                :key="paragraph"
                class="body-sm mb-3"
            >
                {{ paragraph }}
            </div>
        </div>
        <template #modalFooter>
            <AppButton
                v-if="actionButtonLabel"
                :buttonType="BUTTON_TYPES.PRIMARY"
                :label="actionButtonLabel"
                @click="$emit('action')"
            />
            <!-- to not show default save btn if actionButtonLabel is false -->
            <div v-else />
        </template>
    </AppDialogV2>
</template>

<script>
// Components
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import AppDialogV2 from '@/components/partials/AppDialogV2.vue';

export default {
    name: 'FileUploaderModal',
    components: {
        AppButton,
        AppDialogV2,
    },
    props: {
        heading: {
            type: String,
            default: '',
        },
        paragraphList: {
            type: Array,
            default: () => [],
        },
        actionButtonLabel: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            // proxy
            BUTTON_TYPES,
        };
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/consistency.scss';
</style>
