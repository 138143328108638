
// Components
import AppMultiselectV3 from '@/components/partials/inputs/AppMultiselectV3.vue';
import AppInputV3 from '@/components/partials/inputs/AppInputV3.vue';
import IconButton from '@/components/partials/IconButton.vue';
import AppButton from '@/components/partials/inputs/AppButton.vue';

// Helpers
import { ICON_TYPES } from '@/common/iconHelper';
import UM_HIERARCHY from '@/common/userManagerHierarchy';
import { SUBSCRIPTION_LIMIT_HIERARCHY_OPTIONS, HierarchyOption } from '@/__new__/features/pc/common/limitsHelper';
import { isEmpty } from 'lodash';

// Types and Classes
export type SubscriptionLimitComponentData = {
    hierarchy: HierarchyOption;
    maxSubscriptionLimit: number;
};

class SubscriptionLimitRowData {
    maxSubscriptionLimit: number | null;

    hierarchy: HierarchyOption;

    constructor(hierarchyKey: UM_HIERARCHY, maxSubscription: number) {
        this.hierarchy = SUBSCRIPTION_LIMIT_HIERARCHY_OPTIONS
            // eslint-disable-next-line eqeqeq
            .find((option: HierarchyOption) => option.key == hierarchyKey);
        this.maxSubscriptionLimit = maxSubscription;
    }
}

export default {
    name: 'SubscriptionLimit',
    components: {
        AppMultiselectV3,
        AppInputV3,
        IconButton,
        AppButton,
    },
    props: {
        disabled: {
            type: Boolean,
            default: false,
        },
        value: {
            type: Object || null,
            required: false,
            default: null,
        },
    },
    data() {
        return {
            maxSubscriptionLimit: '',
            selectedHierarchy: '',
            subscriptionLimitRows: [] as SubscriptionLimitComponentData[],
            SUBSCRIPTION_LIMIT_HIERARCHY_OPTIONS,
            ICON_TYPES,
        };
    },
    computed: {
        hierarchyDropdownOptions(): HierarchyOption[] | [] {
            const res = SUBSCRIPTION_LIMIT_HIERARCHY_OPTIONS.filter(
                (option: HierarchyOption) =>
                    !this.subscriptionLimitRows
                        .map((row: SubscriptionLimitComponentData) => row.hierarchy.key)
                        .includes(option.key),
            );
            return res;
        },
        subscriptionLimitInitVal() {
            return new SubscriptionLimitRowData(this.hierarchyDropdownOptions[0].key, 1);
        },
        displayDeleteRowIcon() {
            return this.subscriptionLimitRows.length;
        },
        addButtonDisabled() {
            return this.subscriptionLimitRows.length === SUBSCRIPTION_LIMIT_HIERARCHY_OPTIONS.length;
        },
    },
    watch: {
        subscriptionLimitRows: {
            handler(newVal: SubscriptionLimitComponentData[]) {
                let formattedValue = {};
                if (newVal.length) {
                    newVal.forEach(row => {
                        formattedValue[row.hierarchy.key] = { max_subscription: row.maxSubscriptionLimit };
                    });
                } else {
                    formattedValue = null;
                }
                this.$emit('input', formattedValue);
            },
            deep: true,
        },
    },
    mounted() {
        if (this.value || !isEmpty(this.value)) {
            Object.entries(this.value).forEach(([hierarchyKey, subscriptionLimitData]) => {
                const SubscriptionLimitRowDataTest = new SubscriptionLimitRowData(
                    hierarchyKey,
                    subscriptionLimitData.max_subscription,
                );
                this.subscriptionLimitRows.push(SubscriptionLimitRowDataTest);
            });
        }
    },
    methods: {
        onAddSubscriptionLimit() {
            this.$set(this.subscriptionLimitRows, this.subscriptionLimitRows.length, this.subscriptionLimitInitVal);
        },
        onDeleteRow(hierarchyKey: UM_HIERARCHY) {
            const index = this.subscriptionLimitRows.findIndex(rowItem => rowItem.hierarchy.key === hierarchyKey);
            this.subscriptionLimitRows.splice(index, 1);
        },
    },
};
