<template>
    <div>
        <div class="d-flex align-items-center justify-content-between">
            <span class="small-gray-note">
                {{ $i18n.t('customerCare.userInformation.mostRecentUsage') }}
            </span>

            <div class="d-flex align-items-center flex-shrink-0 justify-content-end position-relative">
                <SearchBox
                    v-model="searchQuery"
                    class="table-search-box"
                    :smallSearchIcon="true"
                />
                <ResponseModalButton
                    :response="apiResponse"
                    :title="$i18n.t('customerCare.transactionHistory.transactionId')"
                />
                <FilterTable
                    :columns="getColumnDataForFilter"
                    :multiselectWidth="{ width: '12rem' }"
                    @filterAdded="onFilterAdd"
                >
                    <template #button="slotProps">
                        <IconButton
                            :label="$i18n.t('generic.filter')"
                            :icon="ICON_TYPES.FILTER"
                            @iconClick="slotProps.clickHandler"
                        />
                    </template>
                </FilterTable>

                <TransactionTypeFilter
                    v-if="selectedTabIndex === transactionTab.purchase"
                    :subscriptionType="subscriptionType"
                    class="position-relative"
                    @walletTypeChanged="fetchTransactionsData"
                />

                <TransactionDurationFilter
                    :lastBillingDate="lastBillingDate"
                    :subscriberRegistrationTimestamp="subscriberRegistrationTimestamp"
                    :subscriptionType="subscriptionType"
                    class="position-relative"
                    @timeRangeChanged="fetchTransactionsData"
                />

                <AppTooltip
                    :tooltipPosition="TOOLTIP_POSITION.bottom"
                    :showContentOnClick="true"
                    class="wallet-tooltip mr-2"
                >
                    <template #label>
                        <IconButton
                            :label="$i18n.t('generic.download')"
                            :icon="ICON_TYPES.DOWNLOAD"
                            :allowClickPropagation="true"
                        />
                    </template>

                    <template #content>
                        <div class="download-picker-content">
                            <div
                                class="picker-option"
                                @click="triggerDownloadingTransactionsCSV"
                            >
                                {{ $i18n.t('customerCare.transactionHistory.downloadVisibleHistory') }}
                            </div>
                            <div
                                class="picker-option"
                                @click="downloadTransactionsHistoryFile"
                            >
                                {{ $i18n.t('customerCare.transactionHistory.downloadFullHistory') }}
                            </div>
                        </div>
                    </template>
                </AppTooltip>

                <div class="d-flex align-items-center">
                    <div class="section-toggle-label mr-3">
                        {{ $i18n.t('customerCare.userInformation.nonEmptyRecords') }}
                    </div>
                    <AppToggle
                        v-model="showOnlyNotEmptyTransactions"
                        :small="true"
                        customId="new-um-show-only-not-empty-transactions"
                    />
                </div>
            </div>
        </div>

        <TableFiltersTags
            :filterTableMixin="{ allFilters }"
            class="mt-1 mb-3"
            @removeFilter="removeFilter"
            @removeAllFilters="removeAllFilters"
        />

        <AppTabsV2
            :sectionsCount="1"
            :tabsCount="2"
            :disableSectionPaddings="true"
            :enableHeaderMargin="false"
            @tabSelected="changeSelectedIndex"
        >
            <template #tabHeader1>
                {{ $i18n.t('customerCare.transactionHistory.usageEvents') }}
            </template>

            <template #tab1_sectionContent1>
                <div class="w-100">
                    <TableFiltersRenderless
                        ref="usageTableFilterWrapper"
                        :entities="usageEventsFiltered"
                        @allFiltersUpdate="allFilters = $event"
                    >
                        <template
                            #default="{
                                // state
                                filteredEntities,
                            }"
                        >
                            <AppTable
                                :entities="filteredEntities"
                                :isPaginationEnabled="true"
                                :selectedEntityId="selectedUsageEntityId"
                                :isDefaultHoverEnabled="true"
                                :isSearchEnabled="true"
                                :canSelectColumns="true"
                                :innerSearchQuery="searchQuery"
                                :isDataLoading="isDataLoading"
                                :newDesign="true"
                                :columnsData="usageColumnsData"
                                :requestEntities="requestUsageEntities"
                                :tableTabs="true"
                                :keyName="usageKeyName"
                                :defaultSort="usageDefaultTableSort"
                                tableKey="user/transaction-usage"
                                class="pb-3 transaction-usage-table"
                                @returnCleanEntities="downloadTransactionsCSV('usageDetails', $event)"
                                @selectEntity="e => [selectUsageEvent(e), showSidebar()]"
                            />
                        </template>
                    </TableFiltersRenderless>
                </div>
            </template>

            <template #tabHeader2>
                {{ $i18n.t('customerCare.transactionHistory.purchaseEvents') }}
            </template>

            <template #tab2_sectionContent1>
                <div class="w-100">
                    <TableFiltersRenderless
                        ref="purchaseTableFilterWrapper"
                        :entities="purchaseEvents"
                        @allFiltersUpdate="allFilters = $event"
                    >
                        <template
                            #default="{
                                // state
                                filteredEntities,
                            }"
                        >
                            <AppTable
                                :entities="filteredEntities"
                                :selectedEntityId="selectedPurchaseEntityId"
                                :isDefaultHoverEnabled="true"
                                :isPaginationEnabled="true"
                                :isSearchEnabled="true"
                                :newDesign="true"
                                :canSelectColumns="true"
                                :innerSearchQuery="searchQuery"
                                :isDataLoading="isDataLoading"
                                :columnsData="purchaseColumnsData"
                                :requestEntities="requestPurchaseEntities"
                                :tableTabs="true"
                                :defaultSort="purchaseDefaultTableSort"
                                tableKey="user/transaction-purchase"
                                class="pb-3 transaction-purchase-table"
                                @returnCleanEntities="downloadTransactionsCSV('purchaseDetails', $event)"
                                @selectEntity="e => [selectPurchaseEvent(e), showSidebar()]"
                            >
                                <template #customRowButtons="{ entity }">
                                    <AppButton
                                        v-if="isTypeTopUp(entity)"
                                        :isSmall="true"
                                        :label="$i18n.t('generic.cancel')"
                                        class="cancel-btn"
                                        @click="onCancelTopUpClick(entity)"
                                    />
                                </template>
                            </AppTable>
                        </template>
                    </TableFiltersRenderless>
                </div>
            </template>
        </AppTabsV2>

        <AppAditionalSidebar
            v-model="showAditionalSidebar"
            @input="clearSelectedEntities"
        >
            <template #content>
                <div
                    v-if="selectedUsageEvent"
                    class="usage-wrap"
                >
                    <div class="modal-title">
                        {{ $i18n.t('customerCare.transactionHistory.usageEventsDetails') }}
                    </div>

                    <div class="all-caps-text">
                        {{ $i18n.t('generic.general') }}
                    </div>

                    <div class="modal-row d-flex align-items-center">
                        <div class="row-title">
                            {{ $i18n.t('customerCare.msisdn') }}
                        </div>

                        <div class="row-value">
                            {{ selectedUsageEvent.msisdn | displayNaIfNotProvided }}
                        </div>
                    </div>

                    <div class="modal-row d-flex align-items-center">
                        <div class="row-title">
                            {{ $i18n.t('customerCare.imsi') }}
                        </div>

                        <div class="row-value">
                            {{ selectedUsageEvent.imsi | displayNaIfNotProvided }}
                        </div>
                    </div>

                    <div class="modal-row d-flex align-items-center">
                        <div class="row-title">
                            {{ $i18n.t('customerCare.subscriberId') }}
                        </div>

                        <div class="row-value">
                            {{ selectedUsageEvent.subscriber_id | displayNaIfNotProvided }}
                        </div>
                    </div>

                    <div class="modal-row d-flex align-items-center">
                        <div class="row-title">
                            {{ $i18n.t('customerCare.imei') }}
                        </div>

                        <div class="row-value">
                            {{ selectedUsageEvent.imei | displayNaIfNotProvided }}
                        </div>
                    </div>

                    <div class="all-caps-text">
                        {{ $i18n.t('customerCare.transactionHistory.sessionInfo') }}
                    </div>

                    <div class="modal-row d-flex align-items-center">
                        <div class="row-title">
                            {{ $i18n.t('customerCare.transactionHistory.sessionInfo') }}
                        </div>

                        <div class="row-value">
                            {{ selectedUsageEvent.startTimestamp | displayNaIfNotProvided }}
                        </div>
                    </div>

                    <div class="modal-row d-flex align-items-center">
                        <div class="row-title">
                            {{ $i18n.t('customerCare.transactionHistory.sessionId') }}
                        </div>

                        <div class="row-value">
                            {{ selectedUsageEvent.session_id | displayNaIfNotProvided }}
                        </div>
                    </div>

                    <div class="all-caps-text">
                        {{ $i18n.t('customerCare.transactionHistory.terminatingSubscribersInfo') }}
                    </div>

                    <div class="modal-row d-flex align-items-center">
                        <div class="row-title">
                            {{ $i18n.t('customerCare.transactionHistory.calledParty') }}
                        </div>

                        <div class="row-value">
                            {{ selectedUsageEvent.called_party | displayNaIfNotProvided }}
                        </div>
                    </div>

                    <div class="modal-row d-flex align-items-center">
                        <div class="row-title">
                            {{ $i18n.t('customerCare.transactionHistory.forwardingParty') }}
                        </div>

                        <div class="row-value">
                            {{ selectedUsageEvent.forwarding_party | displayNaIfNotProvided }}
                        </div>
                    </div>

                    <div class="modal-row d-flex align-items-center">
                        <div class="row-title">
                            {{ $i18n.t('customerCare.transactionHistory.specialNumberInfo') }}
                        </div>

                        <div class="row-value">
                            {{ selectedUsageEvent.special_number_info | displayNaIfNotProvided }}
                        </div>
                    </div>

                    <template v-if="topUpUsageEventEnriesList.length">
                        <div class="all-caps-text">
                            {{ $i18n.t('customerCare.transactionHistory.topUpCodeMap') }}
                        </div>

                        <div
                            v-for="entry in topUpUsageEventEnriesList"
                            :key="entry[0]"
                            class="modal-row d-flex align-items-center"
                        >
                            <div class="row-title">
                                {{ entry[0] }}
                            </div>

                            <div class="row-value">
                                {{ entry[1] }}
                            </div>
                        </div>
                    </template>

                    <div class="all-caps-text">
                        {{ $i18n.t('customerCare.transactionHistory.usageInformation') }}
                    </div>

                    <div class="modal-row d-flex align-items-center">
                        <div class="row-title">
                            {{ $i18n.t('productCatalog.services.serviceType') }}
                        </div>

                        <div class="row-value">
                            {{ selectedUsageEvent.serviceType | displayNaIfNotProvided }}
                        </div>
                    </div>

                    <div class="modal-row d-flex align-items-center">
                        <div class="row-title">
                            {{ $i18n.t('customerCare.transactionHistory.totalAmountRated') }}
                        </div>

                        <div class="row-value">
                            {{ selectedUsageEvent.total_amount_rated | displayNaIfNotProvided }}
                        </div>
                    </div>

                    <div class="modal-row d-flex align-items-center">
                        <div class="row-title">
                            {{ $i18n.t('customerCare.transactionHistory.chargingTimestamp') }}
                        </div>

                        <div class="row-value">
                            {{ selectedUsageEvent.charging_timestamp | displayNaIfNotProvided }}
                        </div>
                    </div>

                    <div class="modal-row d-flex align-items-center">
                        <div class="row-title">
                            {{ $i18n.t('generic.currency') }}
                        </div>

                        <div class="row-value">
                            {{ selectedUsageEvent.currency | displayNaIfNotProvided }}
                        </div>
                    </div>

                    <div class="modal-row d-flex align-items-center">
                        <div class="row-title">
                            {{ $i18n.t('customerCare.transactionHistory.freeDuration') }}
                        </div>

                        <div class="row-value">
                            {{ selectedUsageEvent.total_free_duration | displayNaIfNotProvided }}
                        </div>
                    </div>

                    <div class="modal-row d-flex align-items-center">
                        <div class="row-title">
                            {{ $i18n.t('customerCare.transactionHistory.freeVolume') }}
                        </div>

                        <div class="row-value">
                            {{ selectedUsageEvent.total_free_volume | displayNaIfNotProvided }}
                        </div>
                    </div>

                    <div class="modal-row d-flex align-items-center">
                        <div class="row-title">
                            {{ $i18n.t('customerCare.transactionHistory.freeUnits') }}
                        </div>

                        <div class="row-value">
                            {{ selectedUsageEvent.total_free_units | displayNaIfNotProvided }}
                        </div>
                    </div>

                    <div class="modal-row d-flex align-items-center">
                        <div class="row-title">
                            {{ $i18n.t('customerCare.transactionHistory.totalUplinkVolume') }}
                        </div>

                        <div class="row-value">
                            {{ selectedUsageEvent.total_uplink_volume | displayNaIfNotProvided }}
                        </div>
                    </div>

                    <div class="modal-row d-flex align-items-center">
                        <div class="row-title">
                            {{ $i18n.t('customerCare.transactionHistory.totalDownlinkVolume') }}
                        </div>

                        <div class="row-value">
                            {{ selectedUsageEvent.total_downlink_volume | displayNaIfNotProvided }}
                        </div>
                    </div>

                    <div class="modal-row d-flex align-items-center">
                        <div class="row-title">
                            {{ $i18n.t('customerCare.transactionHistory.totalVolume') }}
                        </div>

                        <div class="row-value">
                            {{ selectedUsageEvent.total_volume | displayNaIfNotProvided }}
                        </div>
                    </div>

                    <div class="modal-row d-flex align-items-center">
                        <div class="row-title">
                            {{ $i18n.t('customerCare.transactionHistory.totalRoundedVolume') }}
                        </div>

                        <div class="row-value">
                            {{ selectedUsageEvent.total_rounded_volume | displayNaIfNotProvided }}
                        </div>
                    </div>

                    <div class="modal-row d-flex align-items-center">
                        <div class="row-title">
                            {{ $i18n.t('customerCare.transactionHistory.totalDuration') }}
                        </div>

                        <div class="row-value">
                            {{ selectedUsageEvent.total_duration | displayNaIfNotProvided }}
                        </div>
                    </div>

                    <div class="modal-row d-flex align-items-center">
                        <div class="row-title">
                            {{ $i18n.t('customerCare.transactionHistory.roundedDuration') }}
                        </div>

                        <div class="row-value">
                            {{ selectedUsageEvent.rounded_duration | displayNaIfNotProvided }}
                        </div>
                    </div>

                    <div class="modal-row d-flex align-items-center">
                        <div class="row-title">
                            {{ $i18n.t('customerCare.transactionHistory.totalUnits') }}
                        </div>

                        <div class="row-value">
                            {{ selectedUsageEvent.total_units | displayNaIfNotProvided }}
                        </div>
                    </div>

                    <div class="all-caps-text">
                        {{ $i18n.t('customerCare.transactionHistory.usageInformation') }}
                    </div>

                    <div class="modal-row d-flex align-items-center">
                        <div class="row-title">
                            {{ $i18n.t('customerCare.transactionHistory.MscGgsnSmsc') }}
                        </div>

                        <div class="row-value">
                            {{ selectedUsageEvent.core_network_element | displayNaIfNotProvided }}
                        </div>
                    </div>

                    <div class="modal-row d-flex align-items-center">
                        <div class="row-title">
                            {{ $i18n.t('customerCare.transactionHistory.ApnInfo') }}
                        </div>

                        <div class="row-value">
                            {{ selectedUsageEvent.apn_info | displayNaIfNotProvided }}
                        </div>
                    </div>

                    <div class="modal-row d-flex align-items-center">
                        <div class="row-title">
                            {{ $i18n.t('customerCare.transactionHistory.callType') }}
                        </div>

                        <div class="row-value">
                            {{ selectedUsageEvent.traffic_type | displayNaIfNotProvided }}
                        </div>
                    </div>

                    <div class="modal-row d-flex align-items-center">
                        <div class="row-title">
                            {{ $i18n.t('customerCare.transactionHistory.ratingGroup') }}
                        </div>

                        <div class="row-value">
                            {{ selectedUsageEvent.rating_group_name | displayNaIfNotProvided }}
                        </div>
                    </div>

                    <div class="modal-row d-flex align-items-center">
                        <div class="row-title">
                            {{ $i18n.t('customerCare.transactionHistory.bearerIdentifier') }}
                        </div>

                        <div class="row-value">
                            {{ selectedUsageEvent.bearer_identifier | displayNaIfNotProvided }}
                        </div>
                    </div>

                    <div class="modal-row d-flex align-items-center">
                        <div class="row-title">
                            {{ $i18n.t('customerCare.transactionHistory.zoneLocation') }}
                        </div>

                        <div class="row-value">
                            {{ selectedUsageEvent.zone_location | displayNaIfNotProvided }}
                        </div>
                    </div>

                    <div class="modal-row d-flex align-items-center">
                        <div class="row-title">
                            {{ $i18n.t('customerCare.transactionHistory.zoneDestination') }}
                        </div>

                        <div class="row-value">
                            {{ selectedUsageEvent.zone_destination | displayNaIfNotProvided }}
                        </div>
                    </div>

                    <div class="modal-row d-flex align-items-center">
                        <div class="row-title">
                            {{ $i18n.t('customerCare.transactionHistory.chargeApplied') }}
                        </div>

                        <div class="row-value">
                            {{ selectedUsageEvent.chargeApplied }}
                        </div>
                    </div>

                    <div class="modal-row d-flex align-items-center">
                        <div class="row-title">
                            {{ $i18n.t('customerCare.transactionHistory.processType') }}
                        </div>

                        <div class="row-value">
                            {{ selectedUsageEvent.processType | displayNaIfNotProvided }}
                        </div>
                    </div>

                    <div class="modal-row d-flex align-items-center">
                        <div class="row-title">
                            {{ $i18n.t('customerCare.transactionHistory.remark') }}
                        </div>

                        <div class="row-value">
                            {{ selectedUsageEvent.remark | displayNaIfNotProvided }}
                        </div>
                    </div>

                    <div class="all-caps-text">
                        {{ $i18n.t('customerCare.transactionHistory.bucketInformation') }}
                    </div>

                    <div class="modal-row d-flex align-items-center">
                        <div class="row-title">
                            {{ $i18n.t('customerCare.transactionHistory.bucketID') }}
                        </div>

                        <div class="row-value">
                            {{ selectedUsageEvent.bucket_id | displayNaIfNotProvided }}
                        </div>
                    </div>

                    <div class="modal-row d-flex align-items-center">
                        <div class="row-title">
                            {{ $i18n.t('customerCare.transactionHistory.bucketName') }}
                        </div>

                        <div class="row-value">
                            {{ selectedUsageEvent.bucket_name | displayNaIfNotProvided }}
                        </div>
                    </div>

                    <div class="modal-row d-flex align-items-center">
                        <div class="row-title">
                            {{ $i18n.t('customerCare.transactionHistory.bucketType') }}
                        </div>

                        <div class="row-value">
                            {{ selectedUsageEvent.typeOfBalance | displayNaIfNotProvided }}
                        </div>
                    </div>
                </div>

                <div
                    v-else-if="selectedPurchaseEvent"
                    class="purchase-wrap"
                >
                    <div class="modal-title">
                        {{ $i18n.t('customerCare.transactionHistory.purchaseEventsDetails') }}
                    </div>

                    <div class="all-caps-text">
                        {{ $i18n.t('generic.general') }}
                    </div>

                    <div class="modal-row d-flex align-items-center">
                        <div class="row-title">
                            {{ $i18n.t('customerCare.transactionHistory.offerId') }}
                        </div>

                        <div class="row-value">
                            {{ selectedPurchaseEvent.offer_id | displayNaIfNotProvided }}
                        </div>
                    </div>

                    <div class="modal-row d-flex align-items-center">
                        <div class="row-title">
                            {{ $i18n.t('generic.id') }}
                        </div>

                        <div class="row-value">
                            {{ selectedPurchaseEvent.id | displayNaIfNotProvided }}
                        </div>
                    </div>

                    <div class="modal-row d-flex align-items-center">
                        <div class="row-title">
                            {{ $i18n.t('customerCare.transactionHistory.originalId') }}
                        </div>

                        <div class="row-value">
                            {{ selectedPurchaseEvent.original_id | displayNaIfNotProvided }}
                        </div>
                    </div>

                    <div class="all-caps-text">
                        {{ $i18n.t('customerCare.transactionHistory.purchaseInformation') }}
                    </div>

                    <div class="modal-row d-flex align-items-center">
                        <div class="row-title">
                            {{ $i18n.t('customerCare.transactionHistory.transactionId') }}
                        </div>

                        <div class="row-value">
                            {{ selectedPurchaseEvent.transaction_id | displayNaIfNotProvided }}
                        </div>
                    </div>

                    <div class="modal-row d-flex align-items-center">
                        <div class="row-title">
                            {{ $i18n.t('customerCare.transactionHistory.correlationId') }}
                        </div>

                        <div class="row-value">
                            {{ selectedPurchaseEvent.correlation_id | displayNaIfNotProvided }}
                        </div>
                    </div>

                    <template v-if="topUpPurchaseEventEnriesList.length">
                        <div class="all-caps-text">
                            {{ $i18n.t('customerCare.transactionHistory.topUpCodeMap') }}
                        </div>

                        <div
                            v-for="entry in topUpPurchaseEventEnriesList"
                            :key="entry[0]"
                            class="modal-row d-flex align-items-center"
                        >
                            <div class="row-title">
                                {{ entry[0] }}
                            </div>

                            <div class="row-value">
                                {{ entry[1] }}
                            </div>
                        </div>
                    </template>
                </div>
            </template>
        </AppAditionalSidebar>
    </div>
</template>

<script>
// COMPONENTS
import AppTabsV2 from '@/components/partials/AppTabsV2.vue';
import AppTable from '@/components/partials/AppTable.vue';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import AppTooltip from '@/components/partials/AppTooltip.vue';
import { TOOLTIP_POSITION } from '@/common/tooltip';
import IconButton from '@/components/partials/IconButton.vue';
import { ICON_TYPES } from '@/common/iconHelper';
import SearchBox from '@/components/partials/inputs/SearchBox.vue';
import AppToggle from '@/components/partials/inputs/AppToggle.vue';
import TransactionDurationFilter from '@/__new__/features/customerCare/TransactionDurationFilter.vue';
import TransactionTypeFilter from '@/__new__/features/customerCare/TransactionTypeFilter.vue';
import AppAditionalSidebar from '@/components/partials/AppAditionalSidebar.vue';
import TableFiltersRenderless from '@/components/filters/TableFiltersRenderless.vue';
import TableFiltersTags from '@/components/filters/TableFiltersTags.vue';
import FilterTable from '@/components/partials/FilterTable.vue';
import ResponseModalButton from '@/components/partials/ResponseModalButton.vue';

// HELPERS
import moment from 'moment';
import Papa from 'papaparse';
import download from 'downloadjs';
import { validationMixin } from 'vuelidate';
import { ALERT_TYPES } from '@/common/alerts/Alert';
import { displayNaIfNotProvided, getBeautifulBoolean, toDashedDateWithoutTime } from '@/common/formatting';
import { getUsageTotals } from '@/__new__/features/customerCare/common/transactionHistoryHelper';
import {
    parseUsageTransactionProcessType,
    USER_MANAGER_HIERARCHY,
} from '@/__new__/features/customerCare/common/customerCareHelper';
import { formatNumberForDisplay } from '@/common/utils';
import Button from '@/common/button/Button';
import permissionsService, { isUserAllowed } from '@/services/permissions/permissions.service';
import { getPurchaseEventType } from '@/modules/rewards/common/RewardsHelper';
import * as Sentry from '@sentry/vue';
import supportButtonMixin from '@/components/alerts/supportButtonMixin';

// HTTP
import ordersHTTP from '@/__new__/services/dno/orders/http/orders';
import topUpHTTP from '@/http/topUp';
import tableColumnType from '@/common/filterTable';

export const TRANSACTION_TYPES = {
    PURCHASE: 'purchase',
    USAGE: 'usage',
};

// on the client side user can select Bill or Monetary wallet_type at this point
const walletTypes = {
    MONETARY: 'Monetary wallet',
    BILL: 'Bill',
    COINS: 'Coins wallet',
    SPINS: 'Spins wallet',
    EXTERNAL: 'External',
    DATA: 'Data',
};

const purchaseTypes = {
    order: 1,
    topUp: 2,
};

const transactionTab = {
    usage: {
        key: 'usageTableFilterWrapper',
        id: 1,
    },
    purchase: {
        key: 'purchaseTableFilterWrapper',
        id: 2,
    },
};

function getTransactionServiceTypeText(serviceTypeCode) {
    switch (serviceTypeCode) {
        case 21:
            return 'Data';
        case 22:
            return 'Voice';
        case 23:
            return 'SMS';
        case 24:
            return 'MMS';
        default:
            return 'N/A';
    }
}

export default {
    name: 'TransactionHistory',

    components: {
        AppTabsV2,
        AppTable,
        AppButton,
        SearchBox,
        AppToggle,
        AppTooltip,
        IconButton,
        TransactionDurationFilter,
        TransactionTypeFilter,
        AppAditionalSidebar,
        TableFiltersRenderless,
        TableFiltersTags,
        FilterTable,
        ResponseModalButton,
    },

    filters: {
        displayNaIfNotProvided(val) {
            return displayNaIfNotProvided(val);
        },
    },

    mixins: [validationMixin, supportButtonMixin],

    props: {
        subscriberId: {
            type: String,
            required: true,
        },
        subscriberRegistrationTimestamp: {
            type: Number,
            required: true,
        },
        subscriptionType: {
            type: Number,
            required: true,
        },
        lastBillingDate: {
            type: Object, // moment js entity
            required: true,
        },
    },

    data() {
        return {
            usageEvents: [],
            purchaseEvents: [],
            allFilters: [],
            showOnlyNotEmptyTransactions: true,
            searchQuery: '',
            isDataLoading: false,
            requestUsageEntities: false,
            requestPurchaseEntities: false,
            selectedTabIndex: 1,
            walletType: '',
            purchaseTypes,
            TOOLTIP_POSITION,
            ICON_TYPES,
            BUTTON_TYPES,
            transactionTab,
            showAditionalSidebar: false,
            selectedUsageEvent: null,
            selectedPurchaseEvent: null,
            usageKeyName: 'unique_id',
            apiResponse: null,
            usageDefaultTableSort: {
                key: 'startTimestampLabel',
                sortBy: entity => entity.start_timestamp,
                type: 'desc',
            },
            purchaseDefaultTableSort: {
                key: 'timestampLabel',
                sortBy: entity => entity.timestamp,
                type: 'desc',
            },
            isAllowedUnmasked:
                permissionsService.subscriberUnmaskedCdrEnabled() &&
                isUserAllowed('UMSubscriberReturnUnmaskedCDRsRead'),
            isCancelTopUpEnabled:
                permissionsService.topUpFeaturesAvailable() && isUserAllowed('UMSubscriberWalletTopUpSubscriberLevel'),
        };
    },

    computed: {
        isPurchaseTabSelected() {
            return this.selectedTabIndex === transactionTab.purchase.id;
        },
        usageEventsFiltered() {
            if (this.showOnlyNotEmptyTransactions) {
                return this.usageEvents.filter(ue => {
                    if (ue.serviceType === 'Data' || ue.serviceType === 'App Data') {
                        return ue.total_volume !== 0;
                    }
                    if (ue.serviceType === 'Voice') {
                        return ue.total_duration !== 0;
                    }
                    return ue.total_units !== 0;
                });
            }
            return this.usageEvents;
        },
        topUpUsageEventEnriesList() {
            if (this.selectedUsageEvent.top_up_code) {
                const entries = Object.entries(this.selectedUsageEvent.top_up_code);
                if (entries.length) {
                    return entries;
                }
            }
            return [];
        },
        topUpPurchaseEventEnriesList() {
            if (this.selectedPurchaseEvent.top_up_code) {
                const entries = Object.entries(this.selectedPurchaseEvent.top_up_code);
                if (entries.length) {
                    return entries;
                }
            }
            return [];
        },
        getSelectedTabKey() {
            if (this.selectedTabIndex === transactionTab.purchase.id) {
                return transactionTab.purchase.key;
            }
            return transactionTab.usage.key;
        },
        getColumnDataForFilter() {
            if (this.selectedTabIndex === transactionTab.purchase.id) {
                return this.purchaseColumnsData;
            }
            return this.usageColumnsData;
        },
        usageColumnsData() {
            return [
                {
                    name: this.$i18n.t('customerCare.transactionHistory.dateAndTime'),
                    key: 'startTimestampLabel',
                    sortBy: entity => entity.start_timestamp,
                    forbidHideColumn: true,
                    field: 'startTimestampMoment',
                    filterType: tableColumnType.DATETIME,
                },
                {
                    name: this.$i18n.t('productCatalog.services.serviceType'),
                    key: 'serviceType',
                    forbidHideColumn: true,
                    field: 'serviceType',
                    filterType: tableColumnType.TEXT_LIMITED_OPTIONS,
                    limitedOptions: [...new Set(this.usageEventsFiltered.map(entity => entity.serviceType))],
                },
                {
                    name: this.$i18n.t('customerCare.transactionHistory.calledParty'),
                    key: 'called_party',
                    field: 'called_party',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('customerCare.transactionHistory.ratingGroup'),
                    key: 'rating_group_name',
                    field: 'rating_group_name',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('customerCare.transactionHistory.bucketName'),
                    key: 'bucket_name',
                    field: 'bucket_name',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('customerCare.transactionHistory.offerName'),
                    key: 'entity_name',
                    field: 'entity_name',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('customerCare.transactionHistory.totalAmountRated'),
                    key: 'total_amount_rated',
                    mapper: entity => formatNumberForDisplay(entity.total_amount_rated),
                    field: 'total_amount_rated',
                    filterType: tableColumnType.NUMBER,
                },
                {
                    name: `${this.$i18n.t('customerCare.transactionHistory.totalUsage')}`,
                    key: 'total_usage',
                    field: 'total_usage',
                    filterType: tableColumnType.NUMBER,
                },
                {
                    name: `${this.$i18n.t('customerCare.transactionHistory.totalRoundedUsage')}`,
                    key: 'total_rounded_usage',
                    field: 'total_rounded_usage',
                    filterType: tableColumnType.NUMBER,
                },
                {
                    name: `${this.$i18n.t('customerCare.transactionHistory.totalUsageAmount')}`,
                    key: 'total_usage_amount',
                    mapper: entity => formatNumberForDisplay(entity.total_usage_amount),
                    field: 'total_usage_amount',
                    filterType: tableColumnType.NUMBER,
                    initiallyHidden: true,
                },
                {
                    name: `${this.$i18n.t('customerCare.transactionHistory.totalRoundedUsageAmount')}`,
                    key: 'total_rounded_usage_amount',
                    mapper: entity => formatNumberForDisplay(entity.total_rounded_usage_amount),
                    field: 'total_rounded_usage_amount',
                    filterType: tableColumnType.NUMBER,
                    initiallyHidden: true,
                },
                {
                    name: `${this.$i18n.t('customerCare.transactionHistory.totalUsageType')}`,
                    key: 'total_usage_type',
                    field: 'total_usage_type',
                    filterType: tableColumnType.GENERAL_TEXT,
                    initiallyHidden: true,
                },
            ];
        },
        purchaseColumnsData() {
            return [
                {
                    name: this.$i18n.t('generic.type'),
                    key: 'typeString',
                    forbidHideColumn: true,
                    field: 'typeString',
                    filterType: tableColumnType.TEXT_LIMITED_OPTIONS,
                    limitedOptions: [...new Set(this.purchaseEvents.map(entity => entity.typeString))],
                },
                {
                    name: this.$i18n.t('customerCare.transactionHistory.timestamp'),
                    key: 'timestampLabel',
                    mapper: entity => this.$localeLibrary.getFormattedDateAndTime(entity.timestamp),
                    sortBy: entity => entity.timestamp,
                    field: 'timestampLabelMoment',
                    filterType: tableColumnType.DATETIME,
                },
                {
                    name: this.$i18n.t('customerCare.transactionHistory.offerName'),
                    key: 'offer_name',
                    forbidHideColumn: true,
                    field: 'offer_name',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('customerCare.transactionHistory.walletType'),
                    key: 'wallet_type',
                    field: 'wallet_type',
                    filterType: tableColumnType.TEXT_LIMITED_OPTIONS,
                    limitedOptions: [...new Set(this.purchaseEvents.map(entity => entity.wallet_type))],
                },
                {
                    name: this.$i18n.t('customerCare.transactionHistory.amount'),
                    key: 'amount',
                    mapper: entity => formatNumberForDisplay(entity.amount),
                    field: 'amount',
                    filterType: tableColumnType.NUMBER,
                },
                {
                    name: this.$i18n.t('customerCare.transactionHistory.balance'),
                    key: 'balance',
                    mapper: entity => formatNumberForDisplay(entity.balance),
                    field: 'balance',
                    filterType: tableColumnType.NUMBER,
                },
                {
                    name: this.$i18n.t('customerCare.transactionHistory.activeStateDate'),
                    key: 'active_epoch',
                    formatter: toDashedDateWithoutTime,
                    field: 'activeEpochMoment',
                    filterType: tableColumnType.DATE,
                },
                {
                    name: this.$i18n.t('customerCare.transactionHistory.graceStateDate'),
                    key: 'grace_epoch',
                    formatter: toDashedDateWithoutTime,
                    field: 'graceEpochMoment',
                    filterType: tableColumnType.DATE,
                },
                {
                    name: this.$i18n.t('customerCare.transactionHistory.remark'),
                    key: 'remark',
                    field: 'remark',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
            ];
        },
        selectedUsageEntityId() {
            return this.selectedUsageEvent?.[this.usageKeyName] || '';
        },
        selectedPurchaseEntityId() {
            return this.selectedPurchaseEvent?.id || '';
        },
    },

    methods: {
        getTypeOfBalanceFullString(typeOfBalance) {
            switch (typeOfBalance) {
                case 'M':
                    return 'Monetary';
                case 'V':
                    return 'Volume';
                case 'D':
                    return 'Duration';
                case 'S':
                    return 'Service specific';
                default:
                    return '';
            }
        },
        setTransactionsData(purchaseEvents = [], usageEvents = []) {
            if (purchaseEvents.length) {
                let filteredPurchaseEvents = [...purchaseEvents];

                if (this.walletType === walletTypes.BILL) {
                    // filter only purchaseEvents that have wallet_type: Bill
                    filteredPurchaseEvents = purchaseEvents.filter(event => event.wallet_type === walletTypes.BILL);
                } else if (this.walletType === walletTypes.MONETARY) {
                    // filter only purchaseEvents that have wallet_type: Monetary wallet
                    filteredPurchaseEvents = purchaseEvents.filter(event => event.wallet_type === walletTypes.MONETARY);
                } else {
                    // only Monetary, Bill, External, Data wallet_types should be visible
                    filteredPurchaseEvents = purchaseEvents.filter(
                        event =>
                            event.wallet_type === walletTypes.MONETARY ||
                            event.wallet_type === walletTypes.BILL ||
                            event.wallet_type === walletTypes.EXTERNAL ||
                            event.wallet_type === walletTypes.DATA,
                    );
                }

                // if wallet type is All or an empty string, show all purchaseEvents
                this.purchaseEvents = filteredPurchaseEvents.map(pe => ({
                    ...pe,
                    typeString: getPurchaseEventType(pe.type),
                    timestampLabel: this.$localeLibrary.getFormattedDateAndTime(pe.timestamp),
                    graceEpochMoment: moment(this.$localeLibrary.normalizeTimestamp(pe.grace_epoch)),
                    activeEpochMoment: moment(this.$localeLibrary.normalizeTimestamp(pe.active_epoch)),
                    timestampLabelMoment: moment(this.$localeLibrary.normalizeTimestamp(pe.timestamp)),
                    isPending: getBeautifulBoolean(pe.is_pending),
                    isPartial: getBeautifulBoolean(pe.is_partial),
                }));
            } else {
                this.purchaseEvents = [];
            }

            if (usageEvents.length) {
                this.usageEvents = usageEvents.map(ue => ({
                    ...ue,
                    startTimestampLabel: this.$localeLibrary.getFormattedDateAndTime(ue.start_timestamp),
                    startTimestampMoment: moment(this.$localeLibrary.normalizeTimestamp(ue.start_timestamp)),
                    serviceType: getTransactionServiceTypeText(ue.service_code),
                    typeOfBalance: this.getTypeOfBalanceFullString(ue.type_of_balance),
                    chargeApplied: getBeautifulBoolean(ue.charge_applied),
                    processType: parseUsageTransactionProcessType(ue.process_type),
                    // 2 FIELDS FOR SEARCHING IN TABLE
                    topUpCodeKeys: ue.top_up_code ? Object.keys(ue.top_up_code) : [],
                    topUpCodeValues: ue.top_up_code ? Object.values(ue.top_up_code) : [],
                    ...getUsageTotals(ue),
                }));
            } else {
                this.usageEvents = [];
            }
        },
        async fetchTransactionsData({ timestamps, walletType }) {
            this.walletType = walletType;
            try {
                this.$Progress.start();
                // TODO: need to fix or try after next update
                // DANGER: this line break all TransactionHistory tests
                // this.isDataLoading = true;

                let startTime;
                let endTime;

                if (timestamps) {
                    // for any value received from time picker
                    ({ startTime, endTime } = timestamps);
                    // add filter after receiving the event from TransactionDurationFilter
                    // nextTick is required for the data to render and $refs to update
                    const timestampColumn = this.getTimestampColumnForBillingCycleFilter();
                    if (timestampColumn && startTime && endTime) {
                        this.$nextTick(() => {
                            const existingFilterIndex = this.allFilters.findIndex(
                                f => f.column.key === timestampColumn.key,
                            );
                            if (existingFilterIndex > -1) {
                                this.removeFilter(existingFilterIndex);
                            }
                            this.onFilterAdd({
                                column: timestampColumn,
                                condition: timestampColumn.filterType.options.is_between,
                                values: { value: startTime, endValue: endTime },
                            });
                        });
                    }
                } else {
                    // for case when triggered by SubscriberTab.vue
                    // we have one more case to trigger just wallet type filter
                    // in that case we want to check biling date if doesn't exist show all records in past 2 years
                    startTime =
                        this.lastBillingDate && Object.values(this.lastBillingDate)?.length
                            ? this.lastBillingDate
                            : moment().subtract(2, 'year');
                    endTime = moment().add(1, 'day');
                }

                const [purchaseTransactions, usageTransactions] = await this.fetchTransactions(startTime, endTime);

                this.apiResponse = [purchaseTransactions, usageTransactions];
                this.setTransactionsData(
                    purchaseTransactions?.data?.purchase || [],
                    usageTransactions?.data?.usage || [],
                );

                this.$Progress.finish();
            } catch (error) {
                this.$Progress.fail();
                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('alertMessage.userManagement.somethingWentWrongFetchingTransactions'),
                    type: ALERT_TYPES.error,
                });
                Sentry.captureException(error);
                this.apiResponse = [error.response];
            } finally {
                this.isDataLoading = false;
            }
        },
        fetchTransactions(startTime, endTime) {
            if (this.isAllowedUnmasked) {
                return Promise.all([
                    ordersHTTP.getTransactionsStreaming(
                        this.subscriberId,
                        startTime.unix(),
                        endTime.unix(),
                        TRANSACTION_TYPES.PURCHASE,
                        50,
                        USER_MANAGER_HIERARCHY.SUBSCRIBER,
                    ),
                    ordersHTTP.getTransactionsStreaming(
                        this.subscriberId,
                        startTime.unix(),
                        endTime.unix(),
                        TRANSACTION_TYPES.USAGE,
                        500,
                        USER_MANAGER_HIERARCHY.SUBSCRIBER,
                    ),
                ]);
            }

            return Promise.all([
                ordersHTTP.getTransactionsStreamingMasked(
                    this.subscriberId,
                    startTime.unix(),
                    endTime.unix(),
                    TRANSACTION_TYPES.PURCHASE,
                    50,
                    USER_MANAGER_HIERARCHY.SUBSCRIBER,
                ),
                ordersHTTP.getTransactionsStreamingMasked(
                    this.subscriberId,
                    startTime.unix(),
                    endTime.unix(),
                    TRANSACTION_TYPES.USAGE,
                    500,
                    USER_MANAGER_HIERARCHY.SUBSCRIBER,
                ),
            ]);
        },
        triggerDownloadingTransactionsCSV() {
            // selectedTabIndex stands for displaing which table selected now(Usage Events or Purchase Events)
            if (this.selectedTabIndex === 1) {
                this.requestUsageEntities = !this.requestUsageEntities;
            } else if (this.selectedTabIndex === 2) {
                this.requestPurchaseEntities = !this.requestPurchaseEntities;
            }
        },
        async downloadTransactionsHistoryFile() {
            try {
                this.$Progress.start();

                const currentUnixTimestamp = Math.floor(new Date().getTime() / 1000);

                const [purchaseTransactions, usageTransactions] = await Promise.all([
                    ordersHTTP.getTransactionsStreaming(
                        this.subscriberId,
                        0,
                        currentUnixTimestamp,
                        TRANSACTION_TYPES.PURCHASE,
                        50,
                    ),
                    ordersHTTP.getTransactionsStreaming(
                        this.subscriberId,
                        0,
                        currentUnixTimestamp,
                        TRANSACTION_TYPES.USAGE,
                        500,
                    ),
                ]);

                // Due to different data structures of each transaction type in order to keep all the fields in csv
                // we need to split it into two files so parser can map it properly

                const formattedListOfPurchaseTransactions = purchaseTransactions.data.purchase.map(transaction => ({
                    ...transaction,
                    top_up_code: transaction.top_up_code ? JSON.stringify(transaction.top_up_code) : null,
                }));

                const formattedListOfUsageTransactions = usageTransactions.data.usage.map(transaction => ({
                    ...transaction,
                    ...getUsageTotals(transaction),
                    top_up_code: transaction.top_up_code ? JSON.stringify(transaction.top_up_code) : null,
                }));

                download(
                    Papa.unparse(formattedListOfPurchaseTransactions, {
                        worker: true,
                        delimiter: '\t',
                    }),
                    `${this.subscriberId}-purchase-transactions.tsv`,
                    'text/plain',
                );

                download(
                    Papa.unparse(formattedListOfUsageTransactions, {
                        worker: true,
                        delimiter: '\t',
                    }),
                    `${this.subscriberId}-usage-transactions.tsv`,
                    'text/plain',
                );

                this.$Progress.finish();
            } catch (error) {
                Sentry.captureException(error);
                this.$Progress.fail();
                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('customerCare.transactionHistory.downloadFullHistoryError'),
                });
            }
        },
        downloadTransactionsCSV(name, entities) {
            download(Papa.unparse(entities), `${name}.csv`, 'text/plain');
        },
        changeSelectedIndex(index) {
            this.removeAllFilters();
            this.selectedTabIndex = index;
        },
        onCancelTopUpClick(transaction) {
            const confirmButton = new Button({
                label: this.$i18n.t('generic.confirm'),
                alertType: ALERT_TYPES.warning,
                id: 'cancel-top-up-transaction',
            });
            const topUpAmount = Object.values(transaction.top_up_code)[0];
            this.$eventBus.$emit('showAlert', {
                message: this.$i18n.t('alertMessage.userManagement.areYouSureTopUpCancel', { amount: topUpAmount }),
                type: ALERT_TYPES.warning,
                buttons: [confirmButton],
            });
            this.$eventBus.$once('buttonClicked', id => {
                if (id === confirmButton.id) {
                    this.cancelTopUp(transaction.transaction_id);
                }
            });
        },
        async cancelTopUp(transactionId) {
            this.$Progress.start();
            try {
                await topUpHTTP.cancelTopUp(transactionId, this.subscriberId);
                this.$Progress.finish();
                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('alertMessage.successMessageWithoutRedirect', {
                        entityName: this.$i18n.t('customerCare.transactionHistory.topUp'),
                        action: this.$i18n.t('generic.stateMap.canceled'),
                    }),
                    type: ALERT_TYPES.success,
                });
            } catch (error) {
                this.$Progress.fail();
                this.showSupportAlert(this.$i18n.t('alertMessage.userManagement.topUpCancelFailed'), ALERT_TYPES.error);
                Sentry.captureException(error);
            }
        },
        isTypeTopUp(entity) {
            return this.isCancelTopUpEnabled && entity.type === this.purchaseTypes.topUp;
        },
        showSidebar() {
            this.showAditionalSidebar = true;
        },
        clearSelectedEntities(state) {
            if (!state) {
                this.showAditionalSidebar = false;
                this.selectedUsageEvent = null;
                this.selectedPurchaseEvent = null;
            }
        },
        selectUsageEvent(id) {
            this.selectedPurchaseEvent = null;
            this.selectedUsageEvent = this.usageEventsFiltered.find(e => e[this.usageKeyName] === id);
        },
        selectPurchaseEvent(id) {
            this.selectedUsageEvent = null;
            this.selectedPurchaseEvent = this.purchaseEvents.find(e => e.id === id);
        },
        onFilterAdd(val) {
            this.$refs[this.getSelectedTabKey].onFilterAdded(val);
        },
        computedFilterValue(val) {
            return this.$refs[this.getSelectedTabKey].computedFilterValue(val);
        },
        removeAllFilters() {
            this.$refs[this.getSelectedTabKey].removeAllFilters();
        },
        removeFilter(val) {
            this.$refs[this.getSelectedTabKey].removeFilter(val);
        },
        getTimestampColumnForBillingCycleFilter() {
            if (this.selectedTabIndex === transactionTab.purchase.id) {
                return this.purchaseColumnsData.find(c => c.key === 'timestampLabel');
            }
            return this.usageColumnsData.find(c => c.key === 'startTimestampLabel');
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/palette';
@import '~@/assets/scss/icons';

$icon-path: '~@/assets/icons/';

.search-box-wrapper {
    position: relative;
    width: 15rem;
    height: 2.5rem;
}

.table-search-box {
    width: 15rem;
}

.all-caps-text {
    margin-bottom: 1.125rem;
}

.modal-title {
    font-size: 1.375rem;
    font-weight: 600;
    color: $gray90;
    margin-bottom: 2.1875rem;
}

.modal-row {
    margin-bottom: 0.875rem;
}

.row-title {
    font-size: 0.75rem;
    font-weight: 600;
    width: 14rem;
}

.row-value {
    word-break: break-all;
    font-size: 0.875rem;
    width: 100%;
}

.download-picker-content {
    width: 11.25rem;
    margin: -0.25rem -1rem;

    .picker-option {
        font-size: 0.875rem;
        padding: 0.25rem 0.5rem;
        cursor: pointer;

        &:hover {
            background-color: $blue15;
        }
    }
}
</style>
