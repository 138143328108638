enum CHARGING_SPECIFICATION_TYPES {
    Data = 'Data',
    Voice = 'Voice',
    SMS = 'SMS',
    MMS = 'MMS',
    VoiceSMS = 'VoiceSMS',
    API = 'API',
}

type CHARGING_SPECIFICATION_TYPES_OBJECT = {
    [T in CHARGING_SPECIFICATION_TYPES]: {
        key: number;
        label: CHARGING_SPECIFICATION_TYPES | string;
        i18nLabel: string;
    };
};

export const CHARGING_SPECIFICATION_OPTIONS: Readonly<CHARGING_SPECIFICATION_TYPES_OBJECT> = {
    [CHARGING_SPECIFICATION_TYPES.Data]: {
        key: 1,
        label: CHARGING_SPECIFICATION_TYPES.Data,
        i18nLabel: 'generic.chargingSpecificationTypes.data',
    },
    [CHARGING_SPECIFICATION_TYPES.Voice]: {
        key: 2,
        label: CHARGING_SPECIFICATION_TYPES.Voice,
        i18nLabel: 'generic.chargingSpecificationTypes.voice',
    },
    [CHARGING_SPECIFICATION_TYPES.SMS]: {
        key: 3,
        label: CHARGING_SPECIFICATION_TYPES.SMS,
        i18nLabel: 'generic.chargingSpecificationTypes.sms',
    },
    [CHARGING_SPECIFICATION_TYPES.MMS]: {
        key: 5,
        label: CHARGING_SPECIFICATION_TYPES.MMS,
        i18nLabel: 'generic.chargingSpecificationTypes.mms',
    },
    [CHARGING_SPECIFICATION_TYPES.VoiceSMS]: {
        key: 23,
        label: 'Voice and SMS',
        i18nLabel: 'generic.chargingSpecificationTypes.voiceAndSMS',
    },
    [CHARGING_SPECIFICATION_TYPES.API]: {
        key: 6,
        label: 'API',
        i18nLabel: 'generic.chargingSpecificationTypes.api',
    },
};
