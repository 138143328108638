
// COMPONENTS
import AppLabel from '@/components/partials/AppLabel.vue';

// HELPERS
import { LABEL_COLOR } from '@/common/labelsHelper';
import {
    GOR_PAYMENT_STATUS as GORPaymentStatus,
    GOR_PAYMENT_STATUS_MAP as GORPaymentStatusMap,
} from '@/__new__/services/dno/user/models/GORPayment';

export default {
    name: 'PaymentStatusIndicator',
    components: {
        AppLabel,
    },
    props: {
        status: {
            required: true,
            type: [Number],
        },
    },
    computed: {
        statusConverter(): string {
            return GORPaymentStatusMap[this.status] as string;
        },
        colorConverter(): string | undefined {
            switch (this.status) {
                case GORPaymentStatus.PAYMENT_SUCCESS:
                    return LABEL_COLOR.green;
                case GORPaymentStatus.REFUNDED:
                    return LABEL_COLOR.green;
                default:
                    return LABEL_COLOR.gray;
            }
        },
    },
};
