<template>
    <AbstractListPageWrapper
        :isOverviewEnabled="false"
        :pageTitle="$i18n.t('orderResume.orderResume')"
        :entitiesCount="numberOfExecutions"
        @searchQueryChanged="onSearchQueryChanged"
    >
        <template #button>
            <ResponseModalButton
                :response="executionsApiResponse"
                :title="$i18n.t('orderResume.orderResume')"
            />
        </template>

        <template #filterTable>
            <FilterTable
                :columns="tableColumnsData"
                @filterAdded="onFilterAdded"
            />
        </template>

        <template #allFilters>
            <TableFiltersTags
                :filterTableMixin="filterTableMixin"
                class="my-3 ml-2"
                @removeFilter="removeFilter"
                @removeAllFilters="removeAllFilters"
            />
        </template>

        <template #table>
            <AppTable
                :entities="formattedFilteredEntities"
                :canSelectColumns="true"
                :canSort="true"
                :selectedEntityId="selectedEntityId"
                :innerSearchQuery="searchQueryForTable"
                :isSearchEnabled="true"
                :isInnerSearchQueryPassed="true"
                :columnsData="tableColumnsData"
                tableKey="manual-order-resume-table"
                data-test-id="manual-order-resume-table"
                :emitEntityActions="true"
            >
                <template #customRowButtons="{ entity }">
                    <IconButton
                        :label="$i18n.t('generic.resume')"
                        :icon="ICON_TYPES.START"
                        class="mr-4"
                        @iconClick="openModal(entity)"
                    />
                </template>
            </AppTable>
        </template>

        <template #modal>
            <AppDialogV2
                :title="$i18n.t('orderResume.manualOrderResume')"
                :visible="isModalVisible"
                @close="onCloseModal()"
            >
                <div class="form-content">
                    <AppInputV3
                        v-model="clientId"
                        :placeholder="$i18n.t('orderResume.clientId')"
                        :label="$i18n.t('orderResume.clientId')"
                        :invalid="$v.clientId.$error"
                    />
                    <AppInputV3
                        v-model="clientSecret"
                        :placeholder="$i18n.t('orderResume.clientSecret')"
                        :label="$i18n.t('orderResume.clientSecret')"
                        class="mt-3"
                        :invalid="$v.clientSecret.$error"
                    />
                </div>

                <template #modalFooter>
                    <AppButton
                        :label="$i18n.t('generic.resume')"
                        :buttonType="BUTTON_TYPES.PRIMARY"
                        :disabled="buttonClicked"
                        data-test-id="submit-btn"
                        @click="onSave"
                    />
                </template>
            </AppDialogV2>
        </template>
    </AbstractListPageWrapper>
</template>

<script>
// Components
import AbstractListPageWrapper from '@/components/layout/AbstractListPageWrapper.vue';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import AppTable from '@/components/partials/AppTable.vue';
import FilterTableMixin from '@/components/partials/FilterTableMixin.vue';
import ListPageMixin from '@/__new__/features/pc/ListPageMixin.vue';
import FilterTable from '@/components/partials/FilterTable.vue';
import TableFiltersTags from '@/components/filters/TableFiltersTags.vue';
import ResponseModalButton from '@/components/partials/ResponseModalButton.vue';
import AppDialogV2 from '@/components/partials/AppDialogV2.vue';
import AppInputV3 from '@/components/partials/inputs/AppInputV3.vue';
import IconButton from '@/components/partials/IconButton.vue';

// Helpers
import permissionsService, { isUserAllowed } from '@/services/permissions/permissions.service';
import tableColumnType from '@/common/filterTable';
import { ICON_TYPES } from '@/common/iconHelper';

// Vuex
import { mapGetters, mapActions } from 'vuex';
import Actions, { Getters } from '@/store/mutation-types';

// Validation
import { required } from 'vuelidate/lib/validators';

// Http
import { callbackExecution } from '@/__new__/services/dno/orchestrationengine/http/orchestration-engine';

const planName = 'mock_mc_provisioning';

export default {
    name: 'ManualOrderResume',
    components: {
        AppButton,
        AppDialogV2,
        AppTable,
        AbstractListPageWrapper,
        AppInputV3,
        ResponseModalButton,
        TableFiltersTags,
        IconButton,
        FilterTable,
    },
    mixins: [FilterTableMixin, ListPageMixin],
    props: {
        writePermission: {
            type: String,
            required: false,
            default: null,
        },
    },
    data() {
        return {
            searchQueryForTable: '',
            selectedEntityId: null,
            executionsApiResponse: {},
            clientId: '',
            clientSecret: '',
            buttonClicked: false,
            isModalVisible: false,

            permissionsService,
            ICON_TYPES,
            BUTTON_TYPES,
        };
    },

    validations: {
        clientId: {
            required,
        },
        clientSecret: {
            required,
        },
    },

    computed: {
        ...mapGetters('orchestrationengine', {
            getExecutions: Getters.GET_OE_EXECUTIONS_BY_PLAN_ID,
            getExecutionsApiResponse: Getters.GET_OE_EXECUTIONS_API_RESPONSE,
        }),
        formattedFilteredEntities() {
            return this.filteredEntitiesMixin(this.getExecutions(planName));
        },
        tableColumnsData() {
            return [
                {
                    name: this.$i18n.t('orchestrationEngine.execId'),
                    key: 'id',
                    field: 'id',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('customerCare.orderHistory.planId'),
                    key: 'planId',
                    field: 'planId',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
            ];
        },
        numberOfExecutions() {
            return this.getExecutions(planName)?.length || 0;
        },
    },
    async created() {
        await this.$withProgressBar(
            async () => {
                await this.requestExecutions({
                    planId: planName,
                    pageSize: 1000,
                    fromPage: 0,
                    filters: [
                        {
                            field_name: 'status',
                            operator: 'is_equal_to',
                            value: 7,
                        },
                        {
                            field_name: 'plan_id',
                            operator: 'is',
                            value: planName,
                        },
                        {
                            field_name: 'last_state',
                            operator: 'is',
                            value: 'send_email',
                        },
                    ],
                    sortBy: [
                        {
                            field_name: 'scheduled_at',
                            operator: 'desc',
                        },
                    ],
                });

                this.executionsApiResponse = this.getExecutionsApiResponse;
            },
            {
                errorHandler: () => {
                    this.$alert(this.$i18n.t('alertMessage.somethingWentWrongFetchingNecessaryData'));
                },
            },
        );
    },
    methods: {
        ...mapActions('orchestrationengine', {
            requestExecutions: Actions.REQUEST_OE_EXECUTIONS,
        }),
        onSearchQueryChanged(searchQuery) {
            this.searchQueryForTable = searchQuery;
        },
        openModal(entity) {
            this.selectedEntityId = entity.id;
            this.isModalVisible = true;
        },
        onCloseModal() {
            this.isModalVisible = false;
            this.clientId = '';
            this.clientSecret = '';
            this.selectedEntityId = null;
        },
        async onSave() {
            await this.$withProgressBar(
                async () => {
                    this.buttonClicked = true;

                    this.$v.$touch();

                    if (this.$v.$invalid) {
                        return;
                    }

                    await callbackExecution({
                        execution_id: this.selectedEntityId,
                        client_id: this.clientId,
                        client_secret: this.clientSecret,
                    });

                    this.$showSuccessAlert({
                        message: this.$i18n.t('orderResume.orderResumeSuccess'),
                    });

                    this.$router.go();
                },
                {
                    errorHandler: () => {
                        this.$alert(this.$i18n.t('orderResume.orderResumeFail'));
                    },
                },
            );

            this.isModalVisible = false;
        },
        isUserAllowed,
    },
};
</script>
