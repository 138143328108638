<template>
    <div>
        <div
            v-t="'charging.chargingSpecifications.editor.specialNumber'"
            class="table-title"
        />
        <div class="primary-text">
            <span
                v-for="(specialNumber, index) in value.values"
                :key="index"
            >
                {{ specialNumber }}
            </span>
        </div>
    </div>
</template>

<script>
export default {
    name: 'SpecialNumberValue',
    props: {
        value: {
            type: Object,
            required: true,
        },
    },
};
</script>
