
// Components
import AppSpinner from '@/components/partials/AppSpinner.vue';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import QuietHoursDisplayRules from './QuietHoursDisplayRules.vue';
import SubSidebarMixin from '@/components/partials/SubSidebarMixin.vue';
import SettingsSubSidebarMixin from '@/__new__/features/settings/SettingsSubSidebarMixin.vue';
import AbstractSubSidebarPageWrapper from '@/components/layout/AbstractSubSidebarPageWrapper.vue';
import SubSidebar from '@/components/layout/SubSidebar.vue';

// HTTP
import { getQuietHours } from '@/__new__/services/dno/quietHours/http/quietHours';

// Helpers
import toUpper from 'lodash/toUpper';
import { QuietHoursTypes, dayOfWeek, dayRangeTypes, holidayRangeTypes } from './quietHoursHelper';
import RouteNames from '@/router/routeNames';
import * as Sentry from '@sentry/vue';
import { ICON_TYPES } from '@/common/iconHelper';
import AppTable from '@/components/partials/AppTable.vue';
import IconButton from '@/components/partials/IconButton.vue';
import tableColumnType, { TableColumn } from '@/common/filterTable';
import AbstractListPageWrapper from '@/components/layout/AbstractListPageWrapper.vue';
import OverviewHeaderV2 from '@/components/partials/entityOverview/OverviewHeaderV2.vue';
import EntityOverview from '@/components/partials/entityOverview/EntityOverview.vue';
import ENTITY_TYPES from '@/common/entities/entityTypes';
import ImportEntitiesModalButton from '@/components/partials/ImportEntitiesModalButton.vue';
import DetailsJsonModal from '@/components/partials/DetailsJsonModal.vue';

export default {
    name: 'QuietHours',
    components: {
        DetailsJsonModal,
        ImportEntitiesModalButton,
        EntityOverview,
        OverviewHeaderV2,
        AbstractListPageWrapper,
        IconButton,
        AppTable,
        AppSpinner,
        AppButton,
        QuietHoursDisplayRules,
        SubSidebar,
        AbstractSubSidebarPageWrapper,
    },
    mixins: [SubSidebarMixin, SettingsSubSidebarMixin],
    data() {
        return {
            toUpper,
            ICON_TYPES,
            ENTITY_TYPES,
            hasRules: null,
            isDataLoading: true,
            QuietHoursTypes,
            dayOfWeek,
            dayRangeTypes,
            holidayRangeTypes,
            BUTTON_TYPES,
            allQh: null,
            selectedQhId: '',
            selectedQh: null,
            isOverviewEnabled: false,
        };
    },
    computed: {
        tableColumnsData(): TableColumn[] {
            return [
                {
                    name: this.$t('generic.name'),
                    key: 'name',
                    field: 'name',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
            ];
        },
    },
    created() {
        this.loadQh();
    },
    methods: {
        async loadQh() {
            try {
                this.$Progress.start();
                this.isDataLoading = true;
                const qh = await getQuietHours();
                this.allQh = qh.data.length
                    ? qh.data.map(qhr => ({
                          ...qhr,
                          name: qhr.profile_name || this.$t('settings.campaignCategories.defaultQH'),
                      }))
                    : qh.data;
                this.hasRules = !!this.allQh[0]?.rules?.length;
                this.$Progress.finish();
            } catch (error) {
                Sentry.captureException(error);
                this.$Progress.fail();
                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('alertMessage.failedToLoadQuietHoursData'),
                });
            } finally {
                this.isDataLoading = false;
            }
        },
        onDetailsBtnClick(entity: any) {
            this.$refs.DetailsJsonModal.display(entity);
        },
        onSelectEntity(id: string | number) {
            this.selectedQhId = id;
            this.selectedQh = this.allQh.find(qh => qh.id === id);
            this.isOverviewEnabled = true;
        },
        createQuietHours() {
            this.$router.push({
                name: RouteNames.QUIET_HOURS_EDITOR,
                params: { companyId: this.$route.params.companyId },
            });
        },
        goToEditQuietHoursPage({ id }: any) {
            this.$router.push({
                name: RouteNames.QUIET_HOURS_EDITOR,
                params: { id, companyId: this.$route.params.companyId },
            });
        },
    },
};
