export default class Promotion {
    id;

    name;

    description;

    promoShortDescription;

    benefitShortDescription;

    termsAndConditions;

    promotionTypeId;

    amount;

    startTimestamp;

    endTimestamp;

    offersForDiscountIds;

    recurrenceLimit;

    voucherSetsIds;

    constructor({
        id,
        name,
        description,
        promoShortDescription,
        benefitShortDescription,
        termsAndConditions,
        promotionTypeId,
        amount,
        startTimestamp,
        endTimestamp,
        offersForDiscountIds,
        recurrenceLimit,
        voucherSetsIds,
    }) {
        this.id = id;
        this.name = name;
        this.description = description;
        this.promoShortDescription = promoShortDescription;
        this.benefitShortDescription = benefitShortDescription;
        this.termsAndConditions = termsAndConditions;
        this.promotionTypeId = promotionTypeId;
        this.amount = amount;
        this.startTimestamp = startTimestamp;
        this.endTimestamp = endTimestamp;
        this.offersForDiscountIds = offersForDiscountIds;
        this.recurrenceLimit = recurrenceLimit;
        this.voucherSetsIds = voucherSetsIds;
    }

    static remapPromotionFromBe(promotion) {
        return {
            /* eslint-disable camelcase */
            id: promotion?.id || '',
            name: promotion?.data?.name?.en || '',
            description: promotion?.data?.description?.en || '',
            promoShortDescription: promotion?.data?.short_descriptions?.[0]?.promotion_short_description || '',
            benefitShortDescription: promotion?.data?.short_descriptions?.[0]?.benefit_short_description || '',
            termsAndConditions: promotion?.data?.terms_and_conditions?.en || '',
            promotionTypeId: promotion?.data?.promo_rule_type || null,
            amount: promotion?.data?.amounts?.primary || null,
            startTimestamp: promotion?.data?.start_time,
            endTimestamp: promotion?.data?.end_time,
            offersForDiscountIds: promotion?.data?.applicable_offers || [],
            recurrenceLimit: promotion?.data?.recurrence_limit || null,
            voucherSetsIds: promotion?.data?.voucher_set_ids || [],
            /* eslint-enable camelcase */
        };
    }
}
