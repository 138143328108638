<template>
    <div class="login">
        <div class="login-container">
            <vue-progress-bar />
            <div class="form-container">
                <!-- Heading -->
                <div class="lf-logo" />
                <SignIn
                    v-if="loginState.singin"
                    :loginButtonsType="loginButtonsType"
                    @forgotPassword="openForgotPassword"
                />
                <ForgotPassword
                    v-else-if="loginState.forgotPassword"
                    :loginButtonsType="loginButtonsType"
                    @getBackToLoginPage="getBackToLoginPage"
                />
                <ChangePassword
                    v-else-if="loginState.changePassword"
                    :loginButtonsType="loginButtonsType"
                    @passwordUpdated="resetScreen"
                />
            </div>
            <div class="image-container">
                <div class="lf-image" />
            </div>
        </div>
        <Notification />
    </div>
</template>
<script>
// Vue components
import Notification from '@/components/partials/Notification.vue';
import SignIn from './SignIn.vue';
import ForgotPassword from './ForgotPassword.vue';
import ChangePassword from './ChangePassword.vue';

// Validation
import { validationMixin } from 'vuelidate';
import { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';

const ColorModes = {
    Dark: 'dark',
    Light: 'light',
};

export default {
    name: 'Login',
    components: {
        Notification,
        SignIn,
        ForgotPassword,
        ChangePassword,
    },
    mixins: [validationMixin],
    data() {
        return {
            ColorModes,
            loginState: {
                singin: true,
                forgotPassword: false,
                changePassword: false,
            },
            colorMode: window.matchMedia('(prefers-color-scheme: dark)').matches ? ColorModes.Dark : ColorModes.Light,
        };
    },
    computed: {
        loginButtonsType() {
            return this.colorMode === this.ColorModes.Light ? BUTTON_TYPES.PRIMARY : BUTTON_TYPES.SECONDARY;
        },
    },
    created() {
        try {
            if (this.$route.query.email && this.$route.query.token) {
                this.loginState.singin = false;
                this.loginState.forgotPassword = false;
                this.loginState.changePassword = true;
            }
            // Detection of light mode for button color selection
            window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', event => {
                if (event.matches) {
                    // dark mode
                    this.colorMode = this.ColorModes.Dark;
                } else {
                    this.colorMode = this.ColorModes.Light;
                }
            });
        } catch (e) {
            this.$eventBus.$emit('showAlert', {
                message: e.response.data.error,
            });
        }
    },
    methods: {
        openForgotPassword() {
            this.loginState.singin = false;
            this.loginState.forgotPassword = true;
        },
        resetScreen() {
            this.loginState.singin = true;
            this.loginState.forgotPassword = false;
            this.loginState.changePassword = false;
        },
        getBackToLoginPage() {
            this.loginState.singin = true;
            this.loginState.forgotPassword = false;
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/palette';
@import '~@/assets/scss/_typographyV2.scss';
@import '~@/assets/scss/_layout';

.login-container {
    display: flex;
    box-sizing: border-box;
    width: 100%;
    overflow: auto;
    // we have attached margin: 8px into body
    height: 100vh;
    justify-content: center;
    align-items: center;
    background: #fbfbfc;
    padding: 0 7.5rem;

    @media screen and (prefers-color-scheme: dark) {
        background: #293458;
    }

    @media screen and (max-width: 1024px) {
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        padding: 5rem 1rem 1rem 1rem;
    }
}

.form-container {
    height: 100%;
    width: 50%;
    display: flex;
    justify-content: center;
    flex-direction: column;

    @media screen and (max-width: 680px) {
        width: 100%;
    }
}

.image-container {
    height: 100vh;
    box-sizing: border-box;
    width: 50%;
    max-width: 704px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    @media screen and (max-width: 1024px) {
        max-width: 100%;
        width: 100%;
        margin-top: 3rem;
    }
}

.lf-image {
    height: 100%;
    width: 100%;
    min-height: 150px;
    background: url('../../../assets/images/login/illustration-login.svg') no-repeat center;
    background-size: contain;

    @media screen and (prefers-color-scheme: light) {
        background: url('../../../assets/images/login/illustration-login.svg') no-repeat center;
        background-size: contain;
    }
}

.lf-logo {
    width: 252.3px;
    height: 44.2px;
    margin-bottom: 172px;

    @media screen and (max-width: 680px) {
        width: 182.3px;
        height: 44.2px;
        margin-bottom: 69px;
    }
}

@media screen and (prefers-color-scheme: dark) {
    .lf-logo {
        background: url('../../../assets/images/login/logo-color-1.svg') no-repeat center;
        background-size: contain;
    }
}

@media screen and (prefers-color-scheme: light) {
    .lf-logo {
        background: url('../../../assets/images/login/logo-color-2.svg') no-repeat center;
        background-size: contain;
    }
}
</style>
