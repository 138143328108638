
// Vue components
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import AppDialogV2 from '@/components/partials/AppDialogV2.vue';
import AppTextareaV3 from '@/components/partials/inputs/AppTextareaV3.vue';
import AppMultiselectV3 from '@/components/partials/inputs/AppMultiselectV3.vue';
import AppToggleV2 from '@/components/partials/inputs/AppToggleV2.vue';

// Helpers & Misc
import * as Sentry from '@sentry/vue';
import { ICON_TYPES } from '@/common/iconHelper';
import { dateToEpoch } from '@/common/formatting';
import { ALERT_TYPES } from '@/common/alerts/Alert';
import {
    VERSION_OPTIONS,
    EXECUTION_HISTORY_TYPES,
    NOTES_ID_TYPE,
    oeNoteTag,
} from '@/__new__/features/orchestrationengine/common/orchestrationExecutionDetailsPageHelper';
import { TranslateResult } from 'vue-i18n';

// HTTP
import httpNotes from '@/__new__/services/dno/crm/http/crm';
import { restartState } from '@/__new__/services/dno/orchestrationengine/http/orchestration-engine';

// Validation
import { required } from 'vuelidate/lib/validators';

// Mixins
import OESkipStatesMixin from '@/__new__/features/orchestrationengine/mixins/OESkipStatesMixin.vue';

export default {
    name: 'ResumeExecutionModal',

    components: {
        AppButton,
        AppDialogV2,
        AppTextareaV3,
        AppMultiselectV3,
        AppToggleV2,
    },

    mixins: [OESkipStatesMixin],

    props: {
        visible: {
            required: true,
            type: Boolean,
        },
        firstPlanVersion: {
            type: Object,
            default: () => undefined,
        },
        lastPlanVersion: {
            type: Object,
            default: () => undefined,
        },
        writeEnabled: {
            type: Boolean,
            default: false,
        },
        executionId: {
            type: String,
            default: '',
        },
        execHistory: {
            type: Array,
            default: () => undefined,
        },
        currentExecutionInput: {
            type: Object,
            default: () => undefined,
        },
    },

    data() {
        return {
            // Proxy imports
            ICON_TYPES,
            BUTTON_TYPES,

            selectedVersion: VERSION_OPTIONS.VERSION_IT_STARTED as number,
            stateToResume: {} as { label: string },
            noteInput: '' as string,
            resumeButtonClicked: false as boolean,
            isInputDisabled: true as boolean,
        };
    },

    validations: {
        inputJsonString: {
            isJsonValid: value => {
                try {
                    if (value) {
                        JSON.parse(value);
                    }
                    return true;
                } catch (e) {
                    return false;
                }
            },
        },
        noteInput: {
            required,
        },
    },

    computed: {
        stateToResumeOptions(): { label: string }[] | [] {
            let plan = this.lastPlanVersion;

            // If user selects first plan version then show states from that version
            if (this.selectedVersion === VERSION_OPTIONS.VERSION_IT_STARTED) {
                plan = this.firstPlanVersion;
            }

            if (plan?.states) {
                return [...new Set(Object.keys(plan.states))].map(state => ({
                    label: state,
                }));
            }

            return [];
        },
        selectedStateForResume(): { label: string } {
            const lastStateWithExecutionPaused = this.execHistory.filter(
                (execByState: { type: any }) => execByState.type === EXECUTION_HISTORY_TYPES.EXECUTION_PAUSED,
            );

            if (lastStateWithExecutionPaused.length) {
                // the first one in array is the last one executed since
                // sorting is opposite way returned from BE
                return { label: lastStateWithExecutionPaused[0].state };
            }

            return { label: '' };
        },
        versionOptions(): { label: TranslateResult; key: number }[] {
            return [
                {
                    label: this.$i18n.t('orchestrationEngine.resumeModal.resumeItOnVersionItStarted'),
                    key: VERSION_OPTIONS.VERSION_IT_STARTED,
                },
                {
                    label: this.$i18n.t('orchestrationEngine.resumeModal.resumeItOnNewestVersion'),
                    key: VERSION_OPTIONS.NEWEST_VERSION,
                },
            ];
        },
    },

    created() {
        // Select state which paused execution
        this.stateToResume = this.selectedStateForResume;
    },

    mounted() {
        // Fill input textarea with current execution input
        this.inputJsonString = JSON.stringify(this.currentExecutionInput);
    },

    methods: {
        selectVersion(): void {
            this.skipStates = [];
        },
        async resumeExecution(): Promise<any> {
            this.$v.$touch();

            if (!this.$v.$invalid) {
                try {
                    this.$Progress.start();
                    this.resumeButtonClicked = true;

                    const input = JSON.parse(this.inputJsonString);

                    await restartState({
                        execution_id: this.executionId,
                        continue_state: this.stateToResume.label,
                        ignore_not_retryable: false,
                        plan_version: this.selectedVersion,
                        input,
                    });

                    await httpNotes.addNote({
                        id: this.executionId,
                        idType: NOTES_ID_TYPE.OE_EXECUTION,
                        epoch: dateToEpoch(Date.now()),
                        noteText: this.noteInput,
                        tags: [oeNoteTag],
                    });

                    this.$Progress.finish();

                    this.$eventBus.$emit('showAlert', {
                        message: this.$i18n.t('orchestrationEngine.resumeExecutionSuccess'),
                        type: ALERT_TYPES.success,
                    });

                    this.$router.go(0);
                } catch (error: any) {
                    Sentry.captureException(error);
                    this.$Progress.fail();

                    this.$eventBus.$emit('showAlert', {
                        message: this.$i18n.t('orchestrationEngine.resumeExecutionFail'),
                    });

                    this.resumeButtonClicked = false;
                }
            }
        },
    },
};
