<template>
    <AbstractListPageWrapper
        class="rewards"
        :isOverviewEnabled="isOverviewEnabled"
        :pageTitle="pageTitle"
        :entitiesCount="formattedFilteredEntities.length"
        @searchQueryChanged="setSearchQuery"
    >
        <template slot="button">
            <ResponseModalButton
                :response="rewardsApiResponse"
                :title="pageTitle"
            />
        </template>

        <template slot="filterTable">
            <FilterTable
                :columns="tableColumnsData"
                :multiselectWidth="{ width: '15rem' }"
                @filterAdded="onFilterAdded"
            />
        </template>

        <template slot="headerButtons">
            <AppButton
                data-testid="create-btn"
                :buttonType="BUTTON_TYPES.PRIMARY"
                :label="$i18n.t('rewards.newReward')"
                :iconType="ICON_TYPES.PLUS"
                :disabled="!writeEnabled"
                @click="createReward"
            />
        </template>

        <template slot="allFilters">
            <TableFiltersTags
                :filterTableMixin="filterTableMixin"
                class="my-3 ml-2"
                @removeFilter="removeFilter"
                @removeAllFilters="removeAllFilters"
            />
        </template>

        <template slot="table">
            <AppTable
                data-testid="app-table"
                :entities="formattedFilteredEntities"
                :canSelectColumns="true"
                :canSort="true"
                :selectedEntityId="selectedEntityId"
                :innerSearchQuery="searchQueryForTable"
                :isSearchEnabled="true"
                :isInnerSearchQueryPassed="false"
                :entityType="entityType"
                :columnsData="tableColumnsData"
                :isDataLoading="isDataLoading"
                tableKey="rewards"
                @selectEntity="onEntitySelected"
            >
                <template #status="{ entity }">
                    <RewardStatusIndicator
                        :status="entity.state"
                        :startTime="entity.startTime"
                        :endTime="entity.endTime"
                    />
                </template>

                <template #entityVersion="{ entity }">
                    <EntityStatusIndicator :status="entity.entityVersion" />
                </template>

                <template
                    v-if="writeEnabled"
                    #customRowButtons="{ entity }"
                >
                    <IconButton
                        v-if="entity.state === EntityStateMapping.APPROVED"
                        :label="$i18n.t('generic.pause')"
                        :icon="ICON_TYPES.PAUSE"
                        @iconClick="updateStateAction(entity, EntityStateMapping.PAUSED)"
                    />
                    <IconButton
                        v-if="
                            entity.state === EntityStateMapping.UNAPPROVED || entity.state === EntityStateMapping.PAUSED
                        "
                        :label="$i18n.t('generic.approve')"
                        :icon="ICON_TYPES.CHECK"
                        @iconClick="updateStateAction(entity, EntityStateMapping.APPROVED)"
                    />
                    <IconButton
                        :label="$i18n.t('generic.clone')"
                        :icon="ICON_TYPES.CLONE"
                        @iconClick="openEditorAction(entity, true)"
                    />
                    <IconButton
                        v-if="entity.state !== EntityStateMapping.DELETED"
                        :label="$i18n.t('generic.delete')"
                        :icon="ICON_TYPES.DELETE"
                        @iconClick="updateStateAction(entity, EntityStateMapping.DELETED)"
                    />
                    <IconButton
                        v-if="entity.state !== EntityStateMapping.DELETED"
                        :label="$i18n.t('generic.edit')"
                        :icon="ICON_TYPES.EDIT"
                        @iconClick="openEditorAction(entity)"
                    />
                    <IconButton
                        v-if="entity.state !== EntityStateMapping.DRAFT"
                        :label="$i18n.t('generic.readonly')"
                        :icon="ICON_TYPES.INFO"
                        @iconClick="openEditorAction(entity, false, true)"
                    />
                </template>
            </AppTable>
        </template>

        <template slot="overview">
            <EntityOverview
                :entity="selectedEntity"
                :entityType="entityType"
                @closeOverview="isOverviewEnabled = false"
            >
                <div :slot="`section-1-header`">
                    <OverviewHeaderV2
                        v-if="selectedEntityId"
                        :entityId="selectedEntityId"
                        class="mt-2"
                        :entityName="getMultiLangFieldValueByLocale(selectedEntity.data.name, selectedLanguage)"
                    >
                        <template slot="statusIndicator">
                            <RewardStatusIndicator
                                :status="selectedEntity.state"
                                :startTime="$localeLibrary.normalizeTimestamp(selectedEntity.data.start_time)"
                                :endTime="
                                    $localeLibrary.normalizeTimestamp(selectedEntity.data.end_time) || $t('generic.N/A')
                                "
                            />
                        </template>
                    </OverviewHeaderV2>
                    <LanguageSwitcher v-model="selectedLanguage" />
                </div>

                <div
                    :slot="`section-1-content`"
                    class="pr-4 mt-4"
                >
                    <AppOverviewBlock
                        :items="detailsSection"
                        :isRowTitleCounterVisible="false"
                        :isTitleCounterVisible="false"
                        :maxItemRows="99"
                        :maxItems="99"
                    />
                    <CohortExpressionOverview
                        v-if="shouldShowCohortOverviewBlock"
                        :title="$t('campaigns.targetAudience')"
                        :popupTitle="getMultiLangFieldValueByLocale(selectedEntity.data.name, selectedLanguage)"
                        :titleButtonLabel="$t('generic.seeDetails')"
                        :groups="cohortExpressionOverviewGroups"
                        class="mt-4"
                    />
                </div>
            </EntityOverview>
        </template>
    </AbstractListPageWrapper>
</template>

<script>
// components
import EntityOverview from '@/components/partials/entityOverview/EntityOverview.vue';
import OverviewHeaderV2 from '@/components/partials/entityOverview/OverviewHeaderV2.vue';
import AbstractListPageWrapper from '@/components/layout/AbstractListPageWrapper.vue';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import IconButton from '@/components/partials/IconButton.vue';
import AppTable from '@/components/partials/AppTable.vue';
import RewardStatusIndicator from '@/components/partials/RewardStatusIndicator.vue';
import LanguageSwitcher from '@/components/partials/inputs/LanguageSwitcher.vue';
import CohortExpressionOverview from '@/components/partials/CohortExpressionOverview.vue';
import AppOverviewBlock from '@/components/partials/AppOverviewBlock.vue';
import FilterTable from '@/components/partials/FilterTable.vue';
import ResponseModalButton from '@/components/partials/ResponseModalButton.vue';
import EntityStatusIndicator from '@/components/partials/EntityStatusIndicator.vue';
import TableFiltersTags from '@/components/filters/TableFiltersTags.vue';

// helpers
import { mapActions, mapGetters } from 'vuex';
import Actions, { Getters } from '@/store/mutation-types';
import { getRewardStatus, getDuplicatesMessage } from '@/modules/rewards/common/rewardsRulesHelper';
import { EntityStateMapping } from '@/common/commonEntityStateMapper';
import RouteNames from '@/router/routeNames';
import { ICON_TYPES } from '@/common/iconHelper';
import ENTITY_TYPES from '@/common/entities/entityTypes';
import FilterTableMixin from '@/components/partials/FilterTableMixin.vue';
import tableColumnType from '@/common/filterTable';
import { getMultiLangFieldValueByLocale } from '@/common/entities/entityHelper';
import { payoutTargetMap } from '@/modules/rewards/common/payoutsHelper';
import * as Sentry from '@sentry/vue';
import { ALERT_TYPES } from '@/common/alerts/Alert';
import { updateRewardRuleStateV4, deleteRewardRuleDraft } from '@/__new__/services/dno/rewards/http/rewards';
import Button from '@/common/button/Button';
import permissionsService, { isUserAllowed } from '@/services/permissions/permissions.service';
import { STATUS_CODE_TO_STATUS_NAME_MAP } from '@/common/commonHelper';
import { isEmpty } from 'lodash';
import { CohortList } from '@/__new__/services/dno/sinkConfigs/models/CohortExpression';
import { Modules } from '@/store/store';
import {
    rawCohortExpressionToCohortExpressionOverviewGroup,
    shouldShowCohortOverviewBlock,
} from '@/common/cohortExpressionHelper';

export default {
    name: 'Rewards',
    components: {
        AppOverviewBlock,
        AbstractListPageWrapper,
        AppButton,
        AppTable,
        EntityOverview,
        OverviewHeaderV2,
        EntityStatusIndicator,
        RewardStatusIndicator,
        FilterTable,
        LanguageSwitcher,
        IconButton,
        TableFiltersTags,
        ResponseModalButton,
        CohortExpressionOverview,
    },
    mixins: [FilterTableMixin],
    data() {
        return {
            pageTitle: this.$i18n.t('rewards.rewardsTitle'),
            searchQueryForTable: '',
            selectedLanguage: '',
            selectedEntityId: null,
            selectedEntity: null,
            entityType: ENTITY_TYPES.REWARD_RULE,
            alertButtons: {
                confirmButton: new Button({
                    label: this.$i18n.t('generic.confirm'),
                    alertType: ALERT_TYPES.warning,
                }),
                cloneButton: new Button({
                    label: this.$i18n.t('generic.clone'),
                    alertType: ALERT_TYPES.warning,
                }),
            },
            ICON_TYPES,
            BUTTON_TYPES,
            CohortList,
            EntityStateMapping,
            isDataLoading: false,
            getMultiLangFieldValueByLocale,
            isOverviewEnabled: false,
            isRewardsDraftEnabled: permissionsService.isRewardsDraftEnabled(),
        };
    },
    computed: {
        ...mapGetters('operators', [Getters.languageDefault]),
        ...mapGetters('rewards', [
            Getters.GET_NOT_DELETED_REWARDS_ENTITIES_BY_TYPE,
            Getters.GET_REWARDS_ENTITY_BY_TYPE_AND_ID,
            Getters.GET_REWARDS_ENTITIES_API_RESPONSE_BY_TYPE,
            Getters.GET_APPROVED_REWARDS_ENTITIES_BY_TYPE,
        ]),
        ...mapGetters(Modules.segments, {
            segmentsById: Getters.CACHED_SEGMENTS_BY_ID,
        }),
        shouldShowCohortOverviewBlock() {
            return shouldShowCohortOverviewBlock(this.selectedEntity?.cohortExpressions);
        },
        cohortExpressionOverviewGroups() {
            return rawCohortExpressionToCohortExpressionOverviewGroup(
                this.selectedEntity?.cohortExpressions,
                this.segmentsById,
            );
        },
        writeEnabled() {
            return isUserAllowed('RewardRulesWrite') && permissionsService.rewardsEnabled();
        },
        rewards() {
            return this[Getters.GET_NOT_DELETED_REWARDS_ENTITIES_BY_TYPE](this.entityType);
        },
        rewardsApiResponse() {
            return this[Getters.GET_REWARDS_ENTITIES_API_RESPONSE_BY_TYPE](this.entityType);
        },
        formattedEntities() {
            return this.rewards.map(entity => {
                const subscriberLifetimePayoutCount = entity.data.max_purchases_per_user;
                const startTime = this.$localeLibrary.normalizeTimestamp(entity.data.start_time);
                const endTime = this.$localeLibrary.normalizeTimestamp(entity.data.end_time);
                const { name, description, duration } = entity.data;
                return {
                    id: entity.id,
                    name: getMultiLangFieldValueByLocale(name),
                    description,
                    state: entity.state,
                    status: getRewardStatus(entity.state, startTime, endTime),
                    maxLifetimePayouts: subscriberLifetimePayoutCount,
                    frequency: duration,
                    version: entity.version,
                    startTime,
                    endTime,
                    frequencyForFiltering: duration || this.$i18n.t('generic.N/A'),
                    entityVersion: entity.entityVersion || null,
                    entityVersionLabel: STATUS_CODE_TO_STATUS_NAME_MAP.get(entity.entityVersion),
                };
            });
        },
        formattedFilteredEntities() {
            return this.filteredEntitiesMixin(this.formattedEntities);
        },
        generalDetails() {
            const endTime = this.selectedEntity.data.end_time
                ? this.$localeLibrary.getFormattedDate(this.selectedEntity.data.end_time)
                : this.$i18n.t('generic.N/A');
            const startTime = this.selectedEntity.data.start_time
                ? this.$localeLibrary.getFormattedDate(this.selectedEntity.data.start_time)
                : this.$i18n.t('generic.N/A');
            const incompatibleRulesNames = this.selectedEntity.data.incompatible_rules
                ? this.selectedEntity.data.incompatible_rules
                      .map(id => {
                          // eslint-disable-next-line
                          const rule = this[Getters.GET_NOT_DELETED_REWARDS_ENTITIES_BY_TYPE](
                              ENTITY_TYPES.REWARD_RULE,
                          ).find(r => r.id === id);
                          if (!rule) {
                              return `${id} (${this.$i18n.t('finalStateMapper.Deleted')})`;
                          }
                          return getMultiLangFieldValueByLocale(rule.data.name);
                      })
                      .sort((a, b) => a.localeCompare(b))
                      .join(', ')
                : this.$i18n.t('generic.N/A');
            return {
                name: this.$i18n.t('generic.reward'),
                rows: [
                    {
                        value: this.descriptionAvailable,
                        name: this.$i18n.t('generic.description'),
                    },
                    {
                        value: startTime || this.$i18n.t('generic.N/A'),
                        name: this.$i18n.t('generic.startTime'),
                    },
                    {
                        value: endTime || this.$i18n.t('generic.N/A'),
                        name: this.$i18n.t('generic.endTime'),
                    },
                    {
                        // eslint-disable-next-line camelcase
                        value: this.$i18n.t(payoutTargetMap[this.selectedEntity?.data?.payout_target] || 'generic.N/A'),
                        name: this.$i18n.t('rewards.payoutTarget'),
                    },
                    {
                        // eslint-disable-next-line camelcase
                        value: this.selectedEntity?.data?.count_payout_on_referral
                            ? this.$i18n.t('generic.yes')
                            : this.$i18n.t('generic.no'),
                        name: this.$i18n.t('rewards.countPayoutOnReferrer'),
                    },
                    {
                        value: this.getPayoutsNames(this.selectedEntity?.data?.payouts) || this.$i18n.t('generic.N/A'),
                        name: this.$i18n.t('generic.payouts'),
                    },
                    {
                        // eslint-disable-next-line camelcase
                        value: this.selectedEntity?.data?.max_purchases || this.$i18n.t('generic.N/A'),
                        name: this.$i18n.t('rewards.maxPurchases'),
                    },
                    {
                        // eslint-disable-next-line camelcase
                        value: this.selectedEntity?.data?.max_purchases_per_user || this.$i18n.t('generic.N/A'),
                        name: this.$i18n.t('rewards.maxPurchasesPerCustomer'),
                    },
                    {
                        // eslint-disable-next-line camelcase
                        value: this.getFormattedPurchases(this.selectedEntity?.data?.max_purchases_per_duration),
                        name: this.$i18n.t('rewards.maxPurchasesPerDuration'),
                    },
                    {
                        // eslint-disable-next-line camelcase
                        value: incompatibleRulesNames,
                        name: this.$i18n.t('rewards.editor.incompatibleRules'),
                    },
                    {
                        // eslint-disable-next-line camelcase
                        value: this.selectedEntity?.data?.metadata?.trigger_count || 0,
                        name: this.$i18n.t('rewards.editor.triggerCount'),
                    },
                ],
            };
        },
        descriptionAvailable() {
            const resultDesc = this.selectedEntity.data.description[this.selectedLanguage];
            return resultDesc || this.$i18n.t('generic.N/A');
        },
        detailsSection() {
            if (!this.selectedEntityId) {
                return [];
            }
            return [this.generalDetails];
        },
        tableColumnsData() {
            const columns = [
                {
                    name: this.$i18n.t('generic.name'),
                    key: 'name',
                    field: 'name',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('generic.status'),
                    key: 'status',
                    field: 'status',
                    filterType: tableColumnType.TEXT_LIMITED_OPTIONS,
                    limitedOptions: Array.from(new Set(this.formattedFilteredEntities.map(entity => entity.status))),
                },
                {
                    name: this.$i18n.t('rewards.maxLifetimePayouts'),
                    key: 'maxLifetimePayouts',
                    field: 'maxLifetimePayouts',
                    filterType: tableColumnType.NUMBER,
                },
            ];

            if (this.isRewardsDraftEnabled) {
                columns.push({
                    name: this.$i18n.t('generic.version'),
                    key: 'entityVersion',
                    field: 'entityVersionLabel',
                    filterType: tableColumnType.TEXT_LIMITED_OPTIONS,
                    limitedOptions: [
                        ...new Set(this.formattedFilteredEntities.map(entity => entity.entityVersionLabel)),
                    ],
                });
            }

            return columns;
        },
    },
    async created() {
        this.$Progress.start();
        this.isDataLoading = true;
        try {
            this.selectedLanguage = this[Getters.languageDefault];
            await Promise.all([
                this[Actions.REQUEST_REWARDS_ENTITIES_BY_TYPE](ENTITY_TYPES.REWARD_RULE),
                this[Actions.REQUEST_REWARDS_ENTITIES_BY_TYPE](ENTITY_TYPES.REWARD_PAYOUT),
            ]);
            if (isEmpty(this.segmentsById)) {
                this[Actions.FETCH_SEGMENTS]();
            }
            this.$Progress.finish();
        } catch (error) {
            Sentry.captureException(error);
            this.$Progress.fail();
            this.$eventBus.$emit('showAlert', {
                message: this.$i18n.t('alertMessage.failedToLoadNecessaryData'),
            });
        } finally {
            this.isDataLoading = false;
        }
    },
    methods: {
        ...mapActions('rewards', [Actions.REQUEST_REWARDS_ENTITIES_BY_TYPE]),
        ...mapActions(Modules.segments, [Actions.FETCH_SEGMENTS]),
        createReward() {
            this.$router.push({ name: RouteNames.REWARD_EDITOR, params: { companyId: this.$route.params.companyId } });
        },
        onEntitySelected(id) {
            this.selectedEntity = this[Getters.GET_REWARDS_ENTITY_BY_TYPE_AND_ID](this.entityType, id);
            this.selectedEntityId = id;
            this.isOverviewEnabled = true;
        },
        setSearchQuery(query) {
            this.searchQueryForTable = query;
        },
        getPayoutsNames(ids) {
            if (!ids?.length) return '-';
            return ids
                .map(id => this[Getters.GET_REWARDS_ENTITY_BY_TYPE_AND_ID](ENTITY_TYPES.REWARD_PAYOUT, id))
                .map(p => p?.data?.name?.[this.selectedLanguage])
                .filter(name => !!name)
                .join(', ');
        },
        getFormattedPurchases(data) {
            const getResultStr = item => `${this.$i18n.t('rewards.formattedPurchaseDetails', item)}.`;
            let result = '';
            if (data?.overall) {
                result = `${this.$i18n.t('generic.overall')}: ${getResultStr(data.overall)}`;
            }
            // eslint-disable-next-line camelcase
            if (data?.overall && data?.per_subscriber) {
                result += ' ';
            }
            // eslint-disable-next-line camelcase
            if (data?.per_subscriber) {
                result += `${this.$i18n.t('generic.perUser')}: ${getResultStr(data.per_subscriber)}`;
            }
            return result || this.$i18n.t('generic.N/A');
        },
        openEditorAction(entity, clone = false, readonly = false) {
            const { id } = entity;

            if (clone) {
                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('alerts.areYouSureClone'),
                    type: ALERT_TYPES.warning,
                    buttons: [this.alertButtons.cloneButton],
                });

                this.$eventBus.$once('buttonClicked', buttonId => {
                    if (buttonId === this.alertButtons.cloneButton.id) {
                        this.$router.push({
                            name: RouteNames.REWARD_EDITOR,
                            params: { id, clone, companyId: this.$route.params.companyId },
                        });
                    }
                });
            } else {
                this.$router.push({
                    name: RouteNames.REWARD_EDITOR,
                    params: {
                        id,
                        readonly,
                        companyId: this.$route.params.companyId,
                    },
                });
            }
        },
        updateStateAction(entity, state) {
            let warningMessage;
            let errorMessage;

            switch (state) {
                case EntityStateMapping.APPROVED:
                    warningMessage = this.$i18n.t('alerts.areYouSureApprove');
                    errorMessage = this.$i18n.t('entityStatesControl.couldNotApprove');
                    break;
                case EntityStateMapping.PAUSED:
                    warningMessage = this.$i18n.t('alerts.areYouSurePause');
                    errorMessage = this.$i18n.t('entityStatesControl.couldNotPause');
                    break;
                case EntityStateMapping.DELETED:
                    warningMessage = this.$i18n.t('alerts.areYouSure');
                    errorMessage = this.$i18n.t('entityStatesControl.couldNotDelete');
                    break;
                default:
                    break;
            }

            // check for rules with duplicate conditions
            if (state === EntityStateMapping.APPROVED) {
                const entityToApprove = this[Getters.GET_REWARDS_ENTITY_BY_TYPE_AND_ID](this.entityType, entity.id);
                const approvedRules = this[Getters.GET_APPROVED_REWARDS_ENTITIES_BY_TYPE](ENTITY_TYPES.REWARD_RULE);
                const duplicatesMessage = getDuplicatesMessage(
                    entityToApprove.id,
                    entityToApprove.data.condition,
                    false,
                    approvedRules,
                );
                if (duplicatesMessage) {
                    warningMessage = `${warningMessage} ${duplicatesMessage}`;
                }
            }

            this.$eventBus.$emit('showAlert', {
                message: warningMessage,
                type: ALERT_TYPES.warning,
                buttons: [this.alertButtons.confirmButton],
            });

            this.$eventBus.$once('buttonClicked', async buttonId => {
                if (buttonId === this.alertButtons.confirmButton.id) {
                    try {
                        this.$Progress.start();
                        if (entity.state !== EntityStateMapping.DRAFT) {
                            await updateRewardRuleStateV4(entity.id, entity.version, state);
                        }
                        // need to delete the draft too if deleting
                        if (state === EntityStateMapping.DELETED) {
                            await deleteRewardRuleDraft(entity.id);
                        }
                        await this[Actions.REQUEST_REWARDS_ENTITIES_BY_TYPE](ENTITY_TYPES.REWARD_RULE);
                        this.$Progress.finish();
                    } catch (e) {
                        Sentry.captureException(e);
                        this.$eventBus.$emit('showAlert', {
                            message: errorMessage,
                            type: ALERT_TYPES.error,
                        });
                    }
                }
            });
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/palette';

.rewards {
    ::v-deep {
        .property-value-name {
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
        }

        .section-property {
            .value {
                word-break: normal;
            }
        }
    }
}

.description-highlight {
    padding: 1.5rem 0;
    margin: 0 auto;

    .description-h {
        font-size: 0.812rem;
        font-weight: 600;
        line-height: 2;
        color: $gray90;
    }

    .description-info {
        padding: 0.75rem 1.25rem;
        background-color: $dirty-white;
        font-size: 0.812rem;
        font-weight: normal;
        color: $gray60;
    }
}
</style>
