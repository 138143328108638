import http from '@/http/index';

/**
 * Gets profile information
 *
 * @param iccid
 * @returns {AxiosPromise}
 */
export function getProfileInfo(iccid: string) {
    return http({
        method: 'POST',
        url: '/v3esim/getprofileinfo',
        headers: {
            'Dno-Claims': JSON.stringify({
                iccid,
            }),
        },
        data: {},
    });
}

/**
 * Gets ESIM subscription status
 *
 * @param iccid
 * @returns {AxiosPromise}
 */
export function getEsimSubscriptoinStatus(iccid: string) {
    return http({
        method: 'POST',
        url: '/v3esim/checkesimsubscription',
        headers: {
            'Dno-Claims': JSON.stringify({
                iccid,
            }),
        },
        data: {
            check_subscriptions: {
                esim_profile: true,
                line_subscription: true,
            },
        },
    });
}

/**
 * Gets ESIM Idetifier history
 *
 * @param iccid
 * @returns {AxiosPromise}
 */
export function getEsimIdentifierHistory(iccid: string) {
    return http({
        method: 'POST',
        url: '/v3esim/getesimidentifierhistory',
        headers: {
            'Dno-Claims': JSON.stringify({
                iccid,
            }),
        },
        data: {
            fetch_history_for: ['ICCID'],
        },
    });
}

export default {
    getProfileInfo,
    getEsimSubscriptoinStatus,
    getEsimIdentifierHistory,
};
