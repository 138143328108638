export const RULE_TYPES = {
    REQUIRE: 1,
    REQUIRED_BY: 2,
    CONFLICT: 3,
};

export const RULE_EVAL_OPERATION = {
    AND: 1,
    OR: 2,
};

export default class CompatibilityRuleModel {
    targetId;

    ruleType;

    ruleEvalOperation;

    constructor(targetId, ruleType, ruleEvalOperation) {
        this.targetId = targetId;
        this.ruleType = ruleType;
        this.ruleEvalOperation = ruleEvalOperation;
    }

    equals(other) {
        if (this.ruleType === RULE_TYPES.CONFLICT) {
            return this.targetId === other.targetId && this.ruleType === other.ruleType;
        }

        return (
            this.targetId === other.targetId &&
            this.ruleType === other.ruleType &&
            this.ruleEvalOperation === other.ruleEvalOperation
        );
    }

    static fromRaw(rawData) {
        return new CompatibilityRuleModel(
            rawData.id,
            rawData.rule_type,

            // FIXME: Workaround for SER-14921 where the server is returning an array for `rule_eval_operation` in
            //        CONFLICT rules. This field should just be removed in its entirety for CONFLICT rules. Here,
            //        we just set it to "undefined".
            //
            Array.isArray(rawData.rule_eval_operation) ? undefined : rawData.rule_eval_operation,
        );
    }

    toRaw() {
        return {
            id: this.targetId,
            rule_type: this.ruleType,
            rule_eval_operation: this.ruleEvalOperation,
        };
    }
}
