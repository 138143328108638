<template>
    <div class="h-100 position-relative">
        <div
            :class="[
                'table-scroll-wrapper',
                {
                    'has-entities': oeMappingsList.length > 0,
                },
            ]"
        >
            <div
                class="table-wrapper"
                :class="{ 'min-width-table': isDataLoading }"
            >
                <div class="scroll-content-wrapper">
                    <div
                        class="scroll-content"
                        :style="{
                            'min-width': `${210 * oeColumnsData.length}px`,
                        }"
                    >
                        <div class="row">
                            <td
                                v-for="(column, index) in oeColumnsData"
                                :key="column.key"
                                :class="[{ 'pr-5': index + 1 === oeColumnsData.length }, { 'pl-4': index !== 0 }]"
                                class="d-flex align-items-center all-caps-text justify-content-between truncate-text position-relative"
                                :style="getStyles(column.width)"
                            >
                                {{ column.name }}
                            </td>
                        </div>
                        <template v-if="oeMappingsList.length">
                            <OeExecutionsHierarchyRow
                                v-for="execution in oeMappingsList"
                                :key="execution.executionId"
                                :keyName="'planId'"
                                :treeList="execution"
                                :columnsData="oeColumnsData"
                                class="root"
                            />
                        </template>
                        <template v-else-if="!isDataLoading">
                            <div class="empty-table-row d-flex flex-row justify-content-center align-items-center">
                                <img
                                    src="@/assets/icons/empty-table.svg"
                                    class="empty-table-row-icon"
                                />
                                <div class="empty-table-row-text">
                                    {{ $i18n.t('table.empty') }}
                                </div>
                            </div>
                        </template>
                    </div>
                </div>
            </div>
            <AppSpinner :isVisible="isDataLoading" />
        </div>
    </div>
</template>

<script>
import AppSpinner from '@/components/partials/AppSpinner.vue';
import OeExecutionsHierarchyRow from '@/__new__/features/customerCare/OeExecutionsHierarchyRow.vue';

export default {
    name: 'OeExecutionsHierarchyTable',
    components: {
        AppSpinner,
        OeExecutionsHierarchyRow,
    },
    props: {
        isDataLoading: {
            type: Boolean,
            default: false,
        },
        oeMappingsList: {
            type: Array,
            default: () => [],
        },
    },
    computed: {
        oeColumnsData() {
            return [
                {
                    name: this.$i18n.t('customerCare.orderHistory.planId'),
                    key: 'planId',
                    width: 15,
                },
                {
                    name: this.$i18n.t('customerCare.orderHistory.executionId'),
                    key: 'executionId',
                    width: 20,
                },
                {
                    name: this.$i18n.t('orchestrationEngine.startTimestamp'),
                    key: 'startedAtFormatted',
                },
                {
                    name: this.$i18n.t('orchestrationEngine.endTimestamp'),
                    key: 'finishedAtFormatted',
                },
                {
                    name: this.$i18n.t('orchestrationEngine.scheduledFor'),
                    key: 'scheduledForFormatted',
                },
                {
                    name: this.$i18n.t('orchestrationEngine.executionState'),
                    key: 'executionStatus',
                    width: 15,
                },
                {
                    name: this.$i18n.t('settings.remoteConfig.visibility'),
                    key: 'visibility',
                },
                {
                    name: this.$i18n.t('customerCare.orderHistory.additionalInfo'),
                    key: 'comments',
                },
            ];
        },
    },
    created() {
        this.oeColumnsData.forEach(col => {
            if (!col.width) {
                col.width = Math.round(100 / this.oeColumnsData.length);
            }
        });
        const totalWidth = this.oeColumnsData.reduce((acc, col) => acc + col.width, 0);

        this.oeColumnsData.forEach(col => {
            col.width = (col.width * 100) / totalWidth;
        });
    },
    methods: {
        getStyles(width) {
            const currentWidth = width || 100 / this.oeColumnsData.length;
            return `width: ${currentWidth}%`;
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/palette';
@import '~@/assets/scss/icons';
@import '~@/assets/scss/animations';
@import '~@/assets/scss/z-indexes';
@import '~@/assets/scss/base';

$icon-path: '~@/assets/icons/';

.table-scroll-wrapper {
    position: relative;
    overflow: auto;

    .table-wrapper {
        &.min-width-table {
            min-height: 8rem;
        }

        &.show-border-fade::after {
            content: '';
            position: sticky;
            top: 0;
            right: 0;
            width: 50px;
            height: calc(100% - 1.875rem);
            background: linear-gradient(to left, $white 75%, transparent);
        }

        &.has-entities {
            &.show-border-fade::after {
                height: calc(100% - 3.125rem);
            }

            .table-content-container {
                height: 100%;
                overflow: auto;
                padding-bottom: 1.25rem;
            }
        }

        .scroll-content-wrapper {
            height: 100%;

            .scroll-content {
                display: flex;
                flex-direction: column;

                .row {
                    height: 48px;
                    padding-left: 50px;
                    position: relative;
                    border-top: 1px solid #f1f1f1;
                    border-bottom: 1px solid #f1f1f1;

                    &.root {
                        &::before {
                            content: url($icon-path + $arrow-up-gray);
                            position: absolute;
                            left: 17px;
                            top: 50%;
                            transform: translateY(-50%) rotate(180deg);
                            transition: transform $fast-animation-time;
                        }

                        &.opened {
                            .label {
                                padding-left: 24px !important;
                            }
                            &:before {
                                transform: translateY(-50%);
                            }
                        }
                    }
                }

                .empty-table-row {
                    text-align: center;
                    background-color: $white;
                    border-bottom: 1px solid $dirty-white;
                    padding-top: 3rem;
                    padding-bottom: 3rem;

                    .empty-table-row-text {
                        opacity: 0.5;
                        font-size: 1rem;
                        color: $blue;
                        width: fit-content;
                        font-weight: 600;
                    }

                    .empty-table-row-icon {
                        margin-right: 2rem;
                        width: 100px;
                        height: 100px;
                    }
                }
            }

            &::-webkit-scrollbar {
                height: 30px;
                width: 30px;
            }

            &::-webkit-scrollbar-track {
                border-radius: 100px;
                box-shadow: inset 0 0 4px 4px #eaedf0;
                border: solid 13px transparent;
            }

            &::-webkit-scrollbar-thumb {
                border-radius: 100px;
                box-shadow: inset 0 0 4px 4px #cdd0d7;
                border: solid 13px transparent;
            }

            &::-webkit-scrollbar-corner {
                background-color: transparent;
            }
        }
    }
}
</style>
