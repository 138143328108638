<template>
    <div
        v-tooltip="{
            content: unavailable ? '' : tooltipText,
            classes: ['dark'],
            placement: 'bottom',
            trigger: 'hover click',
            autoHide: false,
        }"
        :class="{ 'with-title': isTitleSlotPassed }"
        class="app-progress-bar"
    >
        <div class="bar">
            <div
                :style="{ width: `${widthInPercent}%`, background: color }"
                :class="{ unavailable: unavailable || widthInPercent === 0 }"
                class="value"
            />
        </div>
        <div class="info d-flex">
            <div class="title">
                <slot name="title" />
            </div>
            <div
                v-if="unavailable"
                class="counter"
            >
                N/A
            </div>
            <div
                v-else-if="counter"
                class="counter"
            >
                {{ counter }}
            </div>
            <div
                v-else
                class="counter"
            >
                {{ result }}
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'AppProgressBar',
    props: {
        currentValue: {
            type: Number,
            default: 0,
        },
        maximalValue: {
            type: Number,
            default: 100,
        },
        units: {
            type: String,
            default: '%',
        },
        tooltipText: {
            type: String,
            default: '',
        },
        unavailable: {
            type: Boolean,
            default: false,
        },
        color: {
            type: String,
            default: '',
        },
        reverseValue: {
            type: Boolean,
            default: false,
        },
        counter: {
            type: String,
            default: '',
        },
    },
    computed: {
        widthInPercent() {
            if (this.unavailable) {
                return 0;
            }
            const currentValueNumber = Number(this.currentValue);
            const maximalValueNumber = Number(this.maximalValue);

            if (this.reverseValue) {
                return currentValueNumber === 0 || maximalValueNumber === 0
                    ? 0
                    : ((maximalValueNumber - currentValueNumber) / maximalValueNumber) * 100;
            }

            return currentValueNumber === 0 || maximalValueNumber === 0
                ? 0
                : (currentValueNumber / maximalValueNumber) * 100;
        },
        isTitleSlotPassed() {
            return this.$slots.title;
        },
        result() {
            const currentValueTransformed = this.reverseValue
                ? this.maximalValue - this.currentValue
                : this.currentValue;

            return `${currentValueTransformed} / ${this.maximalValue} ${this.units}`;
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/palette';

.app-progress-bar {
    height: 26px;
    width: 100%;

    &.with-title {
        height: auto;
        overflow: hidden;

        .bar {
            margin-bottom: 8px;
        }

        .info {
            justify-content: space-between;
        }
    }

    .bar {
        height: 12px;
        border-radius: 8px;
        border: solid 2px $white;
        background-color: $gray5;

        .value {
            height: 100%;
            border-radius: 8px;
            border: solid 2px transparent;
            background-color: $blue;

            &.unavailable {
                border: none;
            }
        }
    }

    .info {
        justify-content: flex-end;

        .title {
            font-size: 13px;
            line-height: 15px;
            color: $gray30;
        }

        .counter {
            font-size: 12px;
            line-height: 15px;
            text-align: right;
            color: $gray30;
        }
    }
}
</style>
