
import Vue from 'vue';
import AppDialogV2 from '@/components/partials/AppDialogV2.vue';
import AppInputV3 from '@/components/partials/inputs/AppInputV3.vue';
import AppMultiselectV3 from '@/components/partials/inputs/AppMultiselectV3.vue';
import { TranslateResult } from 'vue-i18n';
import remoteConfigHttp from '@/__new__/services/dno/remoteConfig/http/remoteConfig';
import orderHTTP from '@/__new__/services/dno/orders/http/orders';
import { required } from 'vuelidate/lib/validators';
import notesHTTP from '@/__new__/services/dno/crm/http/crm';
import { dateToEpoch } from '@/common/formatting';
import { USER_MANAGER_HIERARCHY } from '@/__new__/features/customerCare/common/customerCareHelper';

function generateRandomString(length: number): string {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = 0; i < length; ) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        result += characters[randomIndex];
        i += 1;
    }
    return result;
}

type SearchOperator = {
    key: string;
    label: TranslateResult | undefined;
    $isDisabled: boolean;
};

export default Vue.extend({
    name: 'ManualSeeding',
    components: {
        AppDialogV2,
        AppInputV3,
        AppMultiselectV3,
    },
    props: {
        showManualSeed: {
            type: Boolean,
        },
    },
    data() {
        return {
            reason: '',
            selectedSearchOption: null,
            offerList: [],
            isFetchingCreateOrder: false,
            isFetchingOfferList: false,
        };
    },
    computed: {
        searchOperations(): SearchOperator[] {
            return this.offerList.map(offer => ({
                key: offer.offerId,
                label: offer.offerName,
                $isDisabled: false,
            }));
        },
    },
    validations: {
        reason: {
            required,
        },
        selectedSearchOption: {
            required,
        },
    },
    created() {
        this.getManualSeedingOffers();
    },
    methods: {
        handleCloseDialog() {
            this.$emit('closeDialog');
        },
        validate() {
            this.$v.$touch();
            if (this.$v.$invalid) {
                this.$alert(this.$t('alertMessage.pleaseFixValidation'));
            }
        },
        async getManualSeedingOffers() {
            this.isFetchingOfferList = true;
            await this.$withProgressBar(
                async () => {
                    const {
                        data: {
                            data: { ManualSeedingOfferNameList },
                        },
                    } = await remoteConfigHttp.getRemoteConfig();
                    this.isFetchingOfferList = false;
                    if (!ManualSeedingOfferNameList?.value) {
                        return;
                    }
                    this.offerList = JSON.parse(ManualSeedingOfferNameList.value);
                },
                {
                    errorHandler: () => {
                        this.isFetchingOfferList = false;
                        this.$eventBus.$emit('showAlert', {
                            message: this.$i18n.t('customerCare.account.manualSeed.getOfferListError'),
                        });
                    },
                },
            );
        },
        onSave() {
            this.validate();
            if (this.$v.$invalid) {
                return;
            }
            const transactionId = `GFP${generateRandomString(14)}`;
            this.$withProgressBar(
                async () => {
                    this.isFetchingCreateOrder = true;
                    const result = await orderHTTP.createOrderExternal({
                        target_id: this.$route.params.id,
                        target_type: USER_MANAGER_HIERARCHY.ACCOUNT,
                        external_transaction_id: transactionId,
                        entity_ids: [
                            {
                                id: this.selectedSearchOption.key,
                                type: 'offer',
                            },
                        ],
                    });
                    this.isFetchingCreateOrder = false;
                    this.addNote(result?.data?.order_id);
                },
                {
                    errorHandler: error => {
                        this.isFetchingCreateOrder = false;
                        this.$eventBus.$emit('showAlert', {
                            message: error.message,
                        });
                    },
                },
            );
        },
        addNote(orderId: string) {
            notesHTTP.addNote({
                id: `${this.$route.params.id}-manualseeding`,
                idType: USER_MANAGER_HIERARCHY.ACCOUNT,
                epoch: dateToEpoch(Date.now()),
                noteText: this.reason,
                tags: [orderId],
            });
        },
    },
});
