<template>
    <div class="mb-2">
        <div v-show="chargeType === CHARGING_TYPE.perUnit">
            <div v-show="chargeValues[CHARGING_TYPE.perUnit].editingEnabled">
                <div class="d-flex align-items-center">
                    <div class="inline-text">
                        {{ $i18n.t('charging.chargingSpecifications.editor.userWillBeCharged') }}
                    </div>
                    <AppInputV3
                        v-model.number="chargeValues[CHARGING_TYPE.perUnit].moneyAmount"
                        :min="0"
                        :step="0.0001"
                        :roundNumber="true"
                        :disablePadding="true"
                        :invalid="requiredErrors.perUnitMoneyAmountError"
                        :errorMessage="genErrorMessage(chargeValues[CHARGING_TYPE.perUnit].moneyAmount)"
                        :class="{ 'invalid-input': requiredErrors.perUnitMoneyAmountError }"
                        :disabled="disabled"
                        class="monetary-amount"
                        placeholder="123"
                        type="number"
                        data-test-id="data-money-amount"
                        @input="setRequiredErrorVal('perUnitMoneyAmountError', false)"
                    />
                    <div
                        :class="{ 'disabled-area': isOnlyRateEnabled }"
                        class="d-flex align-items-center"
                    >
                        <div class="inline-text">
                            {{ $i18n.t('charging.chargingSpecifications.editor.every') }}
                        </div>

                        <div
                            v-if="isApiSubTypeDurationOrSubscriptionBased"
                            class="inline-text d-flex align-items-center"
                        >
                            <UnitPicker
                                v-model.number="chargeValues[CHARGING_TYPE.perUnit].chargePeriodAmount"
                                :min="0"
                                :definitionsWidth="5"
                                :isEditorDesign="true"
                                :disabled="disabled"
                                :type="unitTypes.DURATION"
                                class="align-items-center"
                            />
                        </div>
                        <div
                            v-else
                            class="inline-text d-flex align-items-center"
                        >
                            <AppInputV3
                                v-model.number="chargeValues[CHARGING_TYPE.perUnit].chargePeriodAmount"
                                :min="0"
                                :step="1"
                                :roundNumber="true"
                                :disablePadding="true"
                                :invalid="requiredErrors.perUnitChargePeriodAmountError"
                                :errorMessage="genErrorMessage(chargeValues[CHARGING_TYPE.perUnit].chargePeriodAmount)"
                                :class="{ 'invalid-input up-mt': requiredErrors.perUnitChargePeriodAmountError }"
                                :disabled="disabled"
                                class="monetary-amount"
                                placeholder="123"
                                type="number"
                                data-test-id="data-period-amount"
                                @input="setRequiredErrorVal('perUnitChargePeriodAmountError', false)"
                            />
                            <div class="inline-text">
                                {{ $i18n.t('generic.chargingSpecificationTypes.apiRequests') }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-show="chargeType === CHARGING_TYPE.quota">
            <div
                :class="{ 'disabled-area': isOnlyRateEnabled }"
                class="d-flex align-items-center"
            >
                <div class="inline-text">
                    {{ $i18n.t('charging.chargingSpecifications.editor.userWillGet') }}
                </div>
                <AppInputV3
                    v-model.number="chargeValues[CHARGING_TYPE.quota].countAmount"
                    :min="0"
                    :invalid="requiredErrors.quotaCountAmountError"
                    :errorMessage="genErrorMessage(chargeValues[CHARGING_TYPE.quota].countAmount)"
                    :class="{ 'invalid-input': requiredErrors.quotaCountAmountError }"
                    :disabled="disabled"
                    class="monetary-amount"
                    type="number"
                    placeholder="123"
                    @input="setRequiredErrorVal('quotaCountAmountError', false)"
                />
                <div class="inline-text">
                    {{ $i18n.t('generic.chargingSpecificationTypes.apiRequests') }}
                </div>
            </div>
        </div>
        <div v-show="chargeType === CHARGING_TYPE.unlimited">
            <div v-show="chargeValues[CHARGING_TYPE.unlimited].fupEnabled">
                <div class="d-flex align-items-center mt-2">
                    <div class="d-flex align-items-center">
                        <UnitPicker
                            v-model.number="chargeValues[CHARGING_TYPE.unlimited].fupAmount"
                            :min="0"
                            :definitionsWidth="5"
                            :isEditorDesign="true"
                            :disabled="disabled"
                            type="data"
                            class="align-items-center"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// Components
import AppInputV3 from '@/components/partials/inputs/AppInputV3.vue';
import UnitPicker from '@/components/partials/inputs/UnitPicker.vue';

// Mixins
import ChargeConfigurationMixin from '@/__new__/features/charging/ChargeConfigurationMixin.vue';

// Helpers
import { CHARGING_TYPE, API_CHARGING_TYPE } from '@/__new__/services/dno/charging/common/chargingSpecificationHelper';
import permissionsService from '@/services/permissions/permissions.service';
import { unitTypes } from '@/common/formatting';

export default {
    name: 'ChargeConfigurationApi',
    components: {
        AppInputV3,
        UnitPicker,
    },
    mixins: [ChargeConfigurationMixin],
    props: {
        value: {
            type: Object,
            default: null,
        },
        chargeType: {
            type: String,
            default: CHARGING_TYPE.perUnit,
        },
        apiSubType: {
            type: Object,
            default: null,
        },
        isOnlyRateEnabled: {
            type: Boolean,
            default: false,
        },
        requiredError: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            permissionsService,
            unitTypes,
        };
    },
    computed: {
        isApiSubTypeDurationOrSubscriptionBased() {
            return [API_CHARGING_TYPE.durationBased, API_CHARGING_TYPE.subscriptionBased].includes(this.apiSubType?.id);
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/base.scss';
@import '~@/assets/scss/_palette.scss';

.inline-text {
    font-size: 0.75rem;
    text-transform: uppercase;
    font-weight: bold;
    color: $gray-blue;
    margin-right: 0.75rem;
}

.monetary-amount {
    margin-right: 0.75rem;
}

.service-options {
    margin: 0.25rem 0.75rem 0 0;
    flex: 0 1 9rem;
    min-width: 118px;
}

.per-period-option {
    margin: 0.4375rem 0.75rem 0 0;
    flex: 1 0 11rem;
}

.group-checkbox {
    font-weight: normal;
}

.section-content {
    font-size: 0.75rem;
    font-weight: 600;
    margin-right: 1rem;
    color: $gray90;
}

.invalid-input {
    position: relative;
    top: 0.7rem;
    margin-bottom: 0.625rem;
}

.up-mt {
    margin-top: 0.6rem;
}
</style>
