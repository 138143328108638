
// Vue
import Vue, { PropType } from 'vue';

// Components
import IconButton from '@/components/partials/IconButton.vue';
import AppSpinner from '@/components/partials/AppSpinner.vue';

// Helpers
import { ICON_TYPES } from '@/common/iconHelper';
import { TileItem } from '@/__new__/features/customerCareSuite/common/tileItem';
import * as Sentry from '@sentry/vue';
import { USER_MANAGER_HIERARCHY } from '@/__new__/features/customerCare/common/customerCareHelper';

export default Vue.extend({
    name: 'AbstractTileWrapper',

    components: {
        IconButton,
        AppSpinner,
    },

    props: {
        isEditModeEnabled: {
            type: Boolean,
            default: false,
        },
        item: {
            type: Object as PropType<TileItem>,
            default: () => ({}),
        },
        userManagerHierarchy: {
            type: Number as PropType<USER_MANAGER_HIERARCHY>,
            required: true,
        },
    },
    data() {
        return {
            isDataLoading: true,

            // proxy
            ICON_TYPES,
        };
    },
    computed: {
        isSpinnerVisible() {
            return !this.isEditModeEnabled && this.isDataLoading && this.item.isTileAvailable;
        },
    },
    methods: {
        removeTile(tileIndex) {
            this.$emit('removeTile', {
                index: tileIndex,
            });
        },
        async refreshTileData() {
            try {
                await this.$refs.tileComponent?.fetchTileData();
            } catch (error) {
                Sentry.captureException(error);
            }
        },
    },
});
