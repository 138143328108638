import { EntityStateMapping } from '@/common/entityStateMapper';
import axios from '@/http';
import ENTITY_TYPES from '@/common/entities/entityTypes';
import { updateCategoryState } from '@/__new__/services/dno/pc/http/categories';
import { updateOfferState } from '@/__new__/services/dno/pc/http/offer';
import { updateProductState } from '@/__new__/services/dno/pc/http/product';
import { updateServiceState } from '@/__new__/services/dno/pc/http/service';
import { updateResourceState } from '@/__new__/services/dno/pc/http/resource';
import {
    updatePrizeEngineState,
    updatePrizeState,
    updateRewardRuleStateV4,
    updateRewardPayoutState,
    updateLotteryState,
} from '@/__new__/services/dno/rewards/http/rewards';

async function updateEntityState(type, id, version, state) {
    const options = {
        method: 'POST',
        url: '/v3productcatalogwrite/updatestate',
        data: {
            type,
            id,
            version,
            state,
        },
    };

    const response = await axios(options);
    return response.data;
}

export function approveEntity(type, id, version) {
    switch (type) {
        case ENTITY_TYPES.CATEGORY:
            return updateCategoryState(id, version, EntityStateMapping.APPROVED);
        case ENTITY_TYPES.OFFER:
            return updateOfferState(id, version, EntityStateMapping.APPROVED);
        case ENTITY_TYPES.PRODUCT:
            return updateProductState(id, version, EntityStateMapping.APPROVED);
        case ENTITY_TYPES.SERVICE:
            return updateServiceState(id, version, EntityStateMapping.APPROVED);
        case ENTITY_TYPES.RESOURCE:
            return updateResourceState(id, version, EntityStateMapping.APPROVED);
        default:
            return updateEntityState(type, id, version, EntityStateMapping.APPROVED);
    }
}

export function unapproveEntity(type, id, version) {
    switch (type) {
        case ENTITY_TYPES.CATEGORY:
            return updateCategoryState(id, version, EntityStateMapping.UNAPPROVED);
        case ENTITY_TYPES.OFFER:
            return updateOfferState(id, version, EntityStateMapping.UNAPPROVED);
        default:
            return updateEntityState(type, id, version, EntityStateMapping.UNAPPROVED);
    }
}

export function deleteEntity(type, id, version, publish_message) {
    switch (type) {
        case ENTITY_TYPES.CATEGORY:
            return updateCategoryState(id, version, EntityStateMapping.DELETED);
        case ENTITY_TYPES.OFFER:
            return updateOfferState(id, version, EntityStateMapping.DELETED, publish_message);
        case ENTITY_TYPES.PRODUCT:
            return updateProductState(id, version, EntityStateMapping.DELETED, publish_message);
        case ENTITY_TYPES.SERVICE:
            return updateServiceState(id, version, EntityStateMapping.DELETED);
        case ENTITY_TYPES.RESOURCE:
            return updateResourceState(id, version, EntityStateMapping.DELETED);
        case ENTITY_TYPES.REWARD_RULE:
            return updateRewardRuleStateV4(id, version, EntityStateMapping.DELETED);
        case ENTITY_TYPES.PRIZE_ENGINE:
            return updatePrizeEngineState(id, version, EntityStateMapping.DELETED);
        case ENTITY_TYPES.PRIZE:
            return updatePrizeState(id, version, EntityStateMapping.DELETED);
        case ENTITY_TYPES.REWARD_PAYOUT:
            return updateRewardPayoutState(id, version, EntityStateMapping.DELETED);
        case ENTITY_TYPES.LOTTERY:
            return updateLotteryState(id, version, EntityStateMapping.DELETED);
        default:
            return updateEntityState(type, id, version, EntityStateMapping.DELETED);
    }
}

export function pauseEntity(type, id, version, publish_message) {
    switch (type) {
        case ENTITY_TYPES.OFFER:
            return updateOfferState(id, version, EntityStateMapping.PAUSED, publish_message);
        default:
            return updateEntityState(type, id, version, EntityStateMapping.PAUSED);
    }
}
