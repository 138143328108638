var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"field-name body-sm bold",style:({ width: _vm.nameWidth })},[_vm._v(" "+_vm._s(_vm.name)+" ")]),_c('div',{staticClass:"field-value-wrapper",class:{ 'custom-value-content': _vm.customValueContent },style:({ width: `calc(100% - ${_vm.nameWidth})` })},[_vm._t("default",function(){return [_c('p',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                    enabled: _vm.tooltipEnabled,
                    content: _vm.tooltipTextFormatted,
                    classes: ['dark'],
                    placement: 'bottom',
                    trigger: 'hover click',
                    autoHide: false,
                }),expression:"{\n                    enabled: tooltipEnabled,\n                    content: tooltipTextFormatted,\n                    classes: ['dark'],\n                    placement: 'bottom',\n                    trigger: 'hover click',\n                    autoHide: false,\n                }"}],staticClass:"field-value-text mb-0 body-sm truncate-text",class:{ link: _vm.isLink },on:{"click":_vm.linkClicked}},[_vm._v(" "+_vm._s(_vm._f("displayNaIfNotProvided")(_vm.value))+" ")])]})],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }