import { AxiosPromise } from 'axios';
import http, { DnoResponseBase } from '@/http/index';
import { ChargingEntityVersionsConstructorArgs } from '@/__new__/services/dno/charging/models/ChargingEntityVersions';

type ChargingVersionsById = {
    [key: string]: ChargingEntityVersionsConstructorArgs[];
};

interface ChargingEntityVersionsMetadataResponse extends DnoResponseBase {
    charging_versions_by_id: ChargingVersionsById;
}

export function getChargingVersionHistory(
    entityType: string,
    ids: string[],
): AxiosPromise<ChargingEntityVersionsMetadataResponse> {
    return http({
        method: 'POST',
        url: '/v3charging/getchargingversionhistory',
        data: {
            entity_type: entityType,
            ids,
        },
    });
}

export default {
    getChargingVersionHistory,
};
