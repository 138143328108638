
import Vue from 'vue';
import AppTextareaV3 from '@/components/partials/inputs/AppTextareaV3.vue';

export default Vue.extend({
    name: 'EntityNotes',
    components: {
        AppTextareaV3,
    },
    model: {
        prop: 'value',
        event: 'input',
    },
    props: {
        entityType: {
            type: String,
            default: '',
        },
        maxLength: {
            type: Number,
            default: 1000,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        value: {
            type: String,
            default: '',
        },
    },
    data() {
        return {};
    },
    computed: {
        entityNotes: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            },
        },
    },
});
