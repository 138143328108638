
import Vue from 'vue';

// Vuex
import { mapGetters, mapActions } from 'vuex';

import { Modules } from '@/store/store';
import Actions, { Getters } from '@/store/mutation-types';

// Components
import AppCustomerHeader from '@/components/partials/AppCustomerHeader.vue';

// Models
import Subscriber from '@/__new__/services/dno/user/models/Subscriber';
import Flag from '@/__new__/services/dno/user/models/Flag';

// HTTP

import customerCareHTTP from '@/__new__/services/dno/user/http/customer-care';
import ossmvneHTTP from '@/__new__/services/dno/ossmvne/http/ossmvne';

// Helpers

import {
    getOperatorConfigValue,
    getSubscriberDetailFields,
    isUserAllowed,
} from '@/services/permissions/permissions.service';
import { invert, isEmpty, pick } from 'lodash';
import { SIM_TYPES_MAP } from '@/__new__/features/customerCare/subscriber/common/simTypes';
import { AUTH_ENUM_TOKEN_TYPE } from '@/__new__/features/customerCare/subscriber/common/authHelper';
import { SIM_MUTATE_HISTORY } from '@/__new__/features/customerCare/subscriber/common/simMutateHistory';
import { SUBSCRIBER_DETAIL_FIELD } from '@/__new__/features/customerCare/common/detailFieldsHelper';
import {
    USER_MANAGER_HIERARCHY,
    handleUserNotFoundError,
} from '@/__new__/features/customerCare/common/customerCareHelper';
import { ALERT_TYPES } from '@/common/alerts/Alert';
// TODO: should be renamed to UMEntity State History once old UM deprecated since not Subscriber specific impl
import SubscriberStateHistory from '@/__new__/features/customerCare/subscriber/SubscriberStateHistory.vue';
import RouteNames from '@/router/routeNames';
import luaErrorCodes from '@/common/luaErrors';
import * as Sentry from '@sentry/vue';
import supportButtonMixin from '@/components/alerts/supportButtonMixin';

export default Vue.extend({
    name: 'SubscriberPageAnchor',
    components: {
        AppCustomerHeader,
        SubscriberStateHistory,
    },
    mixins: [supportButtonMixin],
    data() {
        return {
            subscriber: new Subscriber({}),
            pukIccidData: {} as { iccid?: string; puk?: string },
            simType: null as SIM_TYPES_MAP | null,
            referralCode: '',
            qrCodeInstallTime: null as number | null,
            esimExternalStatus: '',
        };
    },
    computed: {
        ...mapGetters(Modules.customerCareSuite, [Getters.GET_ASSOCIATED_UM_ENTITY_BY_TYPE_AND_ID]),
        ...mapGetters('userManagementUser', ['getUserVerificationStatusById']),
        ...mapGetters('userManagementSubscriber', ['getSubscriberInfoById', 'getStateHistoryByIdAscending']),
        infoData() {
            let result = [];

            result = [
                {
                    title: this.$i18n.t('customerCare.basicInfo'),
                    value: this.detailsData,
                    additionalLabels: this.detailsStatusIndicators,
                    initiallyExpanded: true,
                },
                {
                    title: this.$i18n.t('customerCare.userInformation.simAndNumber'),
                    value: this.simAndNumberData,
                },
            ];

            if (this.isDetailFieldEnabled(SUBSCRIBER_DETAIL_FIELD.FLAGS) && !isEmpty(this.subscriber.flags)) {
                const subscriberFlagsConfig = getOperatorConfigValue(
                    'service_config.lf-user.flags.subscriber_flags',
                    [],
                );
                const isFilteringPossible =
                    Array.isArray(subscriberFlagsConfig) && !getOperatorConfigValue('isFlagFilteringDisabled');

                const subscriberFlags = isFilteringPossible
                    ? pick(this.subscriber?.flags, subscriberFlagsConfig)
                    : this.subscriber?.flags;

                const flags = {
                    title: this.$i18n.t('customerCare.account.flags'),
                    value: Flag.mapFlagStatus(subscriberFlags),
                    additionalLabels: this.flagStatusIndicators,
                    initiallyExpanded: true,
                };

                if (flags.value.length) {
                    result.push(flags);
                }
            }

            return result;
        },
        detailsData() {
            const data = [];

            if (this.isDetailFieldEnabled(SUBSCRIBER_DETAIL_FIELD.SUBSCRIBER_ID)) {
                data.push({
                    name: this.$i18n.t('customerCare.subscriberTab.subscriberID'),
                    value: this.subscriber.id,
                });
            }

            if (this.isDetailFieldEnabled(SUBSCRIBER_DETAIL_FIELD.ACTIVATION_DATE)) {
                data.push({
                    name: this.$i18n.t('generic.activationDate'),
                    value: this.subscriber.activationDate,
                });
            }

            if (this.isDetailFieldEnabled(SUBSCRIBER_DETAIL_FIELD.CREATION_DATE)) {
                data.push({
                    name: this.$i18n.t('customerCare.userInformation.creationDate'),
                    value: this.subscriber.creationDate,
                });
            }

            if (this.isDetailFieldEnabled(SUBSCRIBER_DETAIL_FIELD.SUBSCRIPTION_TYPE)) {
                data.push({
                    name: this.$i18n.t('customerCare.userInformation.subscriptionType'),
                    value: this.subscriber.subscriptionTypeString,
                });
            }

            if (this.isDetailFieldEnabled(SUBSCRIBER_DETAIL_FIELD.MSISDN)) {
                const msisdnLabel = getOperatorConfigValue('msisdnLabel', 'customerCare.msisdn');
                data.push({
                    name: this.$i18n.t(msisdnLabel),
                    value: this.subscriber.msisdn,
                });
            }

            if (this.isDetailFieldEnabled(SUBSCRIBER_DETAIL_FIELD.REFERRAL_CODE)) {
                data.push({
                    name: this.$i18n.t('customerCare.userInformation.referralCode'),
                    value: this.referralCode ?? '',
                });
            }

            return data;
        },
        simAndNumberData() {
            const data = [];

            if (this.isDetailFieldEnabled(SUBSCRIBER_DETAIL_FIELD.MSISDN)) {
                data.push({
                    name: this.$i18n.t('customerCare.msisdn'),
                    value: this.subscriber.msisdn,
                });
            }

            if (this.isDetailFieldEnabled(SUBSCRIBER_DETAIL_FIELD.IMSI)) {
                data.push({
                    name: this.$i18n.t('customerCare.imsi'),
                    value: this.subscriber.imsi,
                });
            }

            if (
                this.isDetailFieldEnabled(SUBSCRIBER_DETAIL_FIELD.PUK_ICCID) ||
                this.isDetailFieldEnabled(SUBSCRIBER_DETAIL_FIELD.ICCID)
            ) {
                data.push({
                    name: this.isSIMTypeESIM
                        ? this.$i18n.t('customerCare.esimICCID')
                        : this.$i18n.t('customerCare.psimICCID'),
                    value: this.pukIccidData.iccid,
                });
            }

            if (
                this.isDetailFieldEnabled(SUBSCRIBER_DETAIL_FIELD.PUK_ICCID) ||
                this.isDetailFieldEnabled(SUBSCRIBER_DETAIL_FIELD.PUK)
            ) {
                data.push({
                    name: this.$i18n.t('customerCare.puk'),
                    value: this.pukIccidData.puk,
                });
            }

            if (this.isDetailFieldEnabled(SUBSCRIBER_DETAIL_FIELD.QR_CODE_INSTALL_TIME) && this.isSIMTypeESIM) {
                data.push({
                    name: this.$i18n.t('customerCare.eSIM.qrCodeInstallTime'),
                    value: this.qrCodeInstallTime
                        ? this.$localeLibrary.getFormattedDateAndTime(this.qrCodeInstallTime)
                        : this.$i18n.t('customerCare.eSIM.notInstalled'),
                });
            }

            if (this.isDetailFieldEnabled(SUBSCRIBER_DETAIL_FIELD.ESIM_EXTERNAL_STATUS) && this.isSIMTypeESIM) {
                data.push({
                    name: this.$i18n.t('customerCare.eSIM.externalStatus'),
                    value: this.esimExternalStatus,
                });
            }

            if (this.showSendQRButton) {
                data.push({
                    name: this.$i18n.t('customerCare.esimQRCode'),
                    value: this.$i18n.t('customerCare.eSIM.sendActivationQR'),
                    onClick: this.onESIMQRSend,
                });
            }

            return data;
        },
        // State History related part
        stateHistory() {
            return this.$route.params.id ? this.getStateHistoryByIdAscending(this.$route.params.id) : [];
        },
        stateHistoryFormatted() {
            return this.stateHistory.map(stateData => ({
                state: stateData.state,
                date: this.$localeLibrary.getFormattedDate(stateData.change_time),
                time: this.$localeLibrary.getFormattedTime(stateData.change_time),
            }));
        },
        subscriberDetailFields() {
            return getSubscriberDetailFields();
        },
        subscriberInfo() {
            return this.$route.params.id ? this.getSubscriberInfoById(this.$route.params.id) : {};
        },
        isUserVerified() {
            return this.subscriber.userId ? this.getUserVerificationStatusById(this.subscriber.userId) : false;
        },
        isEditAllowed() {
            return !this.userAuthenticationEnabled || this.isUserVerified;
        },
        isSIMTypeESIM() {
            return this.simType === SIM_TYPES_MAP.ESIM;
        },
        showSendQRButton() {
            return this.isSIMTypeESIM && isUserAllowed('UMSubscriberSendESIMActivationQR');
        },
    },
    async mounted() {
        try {
            const { id } = this.$route.params;

            if (!id) {
                await this.$router.push({
                    name: RouteNames.CCS_SEARCH,
                    params: { companyId: this.$route.params.companyId },
                });
                return;
            }

            if (!isEmpty(this.subscriberInfo)) {
                this.setSubscriber();
            }

            await this.fetchSubscriberById(id);

            const promises = id
                ? [this.fetchReferralCode(), this.fetchStateHistoryById(id), this.getSubscriberSimInfo(id)]
                : [];

            await Promise.all(promises);
        } catch (error) {
            if (handleUserNotFoundError(error)) {
                await this.$router.push({
                    name: RouteNames.CCS_SEARCH,
                    params: { companyId: this.$route.params.companyId },
                });
                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('alertMessage.userManagement.subscriberNotFound'),
                });
            } else {
                this.$showErrorAlert(
                    this.$i18n.t('alertMessage.somethingWentWrongFetchingNecessaryData'),
                    ALERT_TYPES.error,
                );
            }
        }
    },
    methods: {
        ...mapActions('userManagementSubscriber', ['getSubscriberInfo', 'getStateHistory']),
        ...mapActions('customerCare', [Actions.SET_CURRENT_SUBSCRIBER_ID]),
        fetchStateHistory() {
            this.$withProgressBar(
                async () => {
                    await this.getStateHistory(this.$route.params.id);
                },
                {
                    errorHandler: () => {
                        this.$showErrorAlert({
                            message: this.$i18n.t('alertMessage.somethingWentWrongFetchingNecessaryData'),
                        });
                    },
                },
            );
        },
        isDetailFieldEnabled(fieldName) {
            return this.subscriberDetailFields.includes(fieldName);
        },
        setSubscriber() {
            this.subscriber = new Subscriber(Subscriber.remapUserFromBe(this.subscriberInfo));
            this[Actions.SET_CURRENT_SUBSCRIBER_ID](this.subscriber.id);
        },
        async fetchSubscriberById(id) {
            try {
                this.$Progress.start();
                await this.getSubscriberInfo(id);
                this.setSubscriber();

                this.$Progress.finish();
            } catch (error) {
                this.$Progress.fail();
                throw error;
            }
        },
        async getSubscriberSimInfo(id) {
            if (
                ![
                    SUBSCRIBER_DETAIL_FIELD.PUK_ICCID,
                    SUBSCRIBER_DETAIL_FIELD.ICCID,
                    SUBSCRIBER_DETAIL_FIELD.PUK,
                    SUBSCRIBER_DETAIL_FIELD.QR_CODE_INSTALL_TIME,
                    SUBSCRIBER_DETAIL_FIELD.ESIM_EXTERNAL_STATUS,
                ].some(this.isDetailFieldEnabled) ||
                !this.subscriber.msisdn
            ) {
                return;
            }

            this.$Progress.start();
            try {
                const {
                    data: { profile_data: profileData },
                } = await customerCareHTTP.getProfileInfo({
                    targetId: id,
                    targetType: USER_MANAGER_HIERARCHY.SUBSCRIBER,
                    tokenType: AUTH_ENUM_TOKEN_TYPE.TENANT,
                });

                this.simType = profileData?.sim_type;
                this.pukIccidData = {
                    iccid: profileData?.iccid || '',
                    puk: profileData?.puk || '',
                };

                const mutateHistory = invert(profileData?.mutate_history) as Record<SIM_MUTATE_HISTORY, string>;
                this.qrCodeInstallTime = mutateHistory[SIM_MUTATE_HISTORY.INSTALLED]
                    ? parseInt(mutateHistory[SIM_MUTATE_HISTORY.INSTALLED], 10)
                    : null;

                await this.getESIMExternalStatus();
            } catch (e) {
                Sentry.captureException(e);
            }
            this.$Progress.finish();
        },
        async getESIMExternalStatus() {
            if (
                !this.isSIMTypeESIM ||
                !this.pukIccidData.iccid ||
                !this.isDetailFieldEnabled(SUBSCRIBER_DETAIL_FIELD.ESIM_EXTERNAL_STATUS)
            ) {
                return;
            }
            try {
                const { data } = await ossmvneHTTP.getEsimProfile(this.pukIccidData.iccid);
                this.esimExternalStatus = data?.profiles?.[0]?.profileState || '';
            } catch (e) {
                Sentry.captureException(e);
            }
        },
        async fetchReferralCode() {
            if (this.isDetailFieldEnabled(SUBSCRIBER_DETAIL_FIELD.REFERRAL_CODE)) {
                try {
                    this.$Progress.start();
                    const {
                        data: { referral_code: refCode },
                    } = await customerCareHTTP.getReferralCode(
                        this.$route.params.id,
                        USER_MANAGER_HIERARCHY.SUBSCRIBER,
                    );
                    this.referralCode = refCode;
                    this.$Progress.finish();
                } catch (error) {
                    this.$Progress.fail();
                    this.showSupportAlert(
                        this.$i18n.t('alertMessage.somethingWentWrongFetchingNecessaryData'),
                        ALERT_TYPES.error,
                    );
                    Sentry.captureException(error);
                }
            }
        },
        async fetchStateHistoryById(id) {
            try {
                this.$Progress.start();

                await this.getStateHistory(id);

                this.$Progress.finish();
            } catch (error) {
                this.$Progress.fail();
                this.showSupportAlert(
                    this.$i18n.t('alertMessage.somethingWentWrongFetchingNecessaryData'),
                    ALERT_TYPES.error,
                );
                Sentry.captureException(error);
            }
        },
        async onESIMQRSend() {
            try {
                this.$Progress.start();
                await customerCareHTTP.sendESIMActivationQR(this.$route.params.id);
                this.showSupportAlert(this.$i18n.t('customerCare.eSIM.QRSuccess'), ALERT_TYPES.success);
                this.$Progress.finish();
            } catch (err) {
                this.$Progress.fail();
                Sentry.captureException(err);
                let errorMessage = this.$i18n.t('generic.somethingWentWrong');
                const luaErrorCode = err?.response?.data?.code;
                const luaModule = err?.response?.data?.module;
                if (luaModule === 'SIM') {
                    if (luaErrorCode === luaErrorCodes.SIM.MORE_THAN_ONE_ACTIVE_ICCID.code) {
                        errorMessage = this.$i18n.t('customerCare.eSIM.moreThanOneICCID');
                    } else if (luaErrorCode === luaErrorCodes.SIM.INTERNAL_ERROR.code) {
                        errorMessage = this.$i18n.t('customerCare.eSIM.failedToSendEmailQR');
                    }
                } else if (luaModule === 'OSA' && luaErrorCode === luaErrorCodes.OSA.INVALID_EXTERNAL_RESPONSE.code) {
                    errorMessage = this.$i18n.t('customerCare.eSIM.failedToGenerateQR');
                }
                this.showSupportAlert(errorMessage, ALERT_TYPES.error);
            }
        },
    },
});
