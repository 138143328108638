
import Vue, { PropType } from 'vue';
import { mapGetters } from 'vuex';
import { Getters } from '@/store/mutation-types';

// Vue components
import AppInputV3 from '@/components/partials/inputs/AppInputV3.vue';
import AppMultiselectV3 from '@/components/partials/inputs/AppMultiselectV3.vue';
import AppToggle from '@/components/partials/inputs/AppToggle.vue';
import AppTooltip from '@/components/partials/AppTooltip.vue';

// Validations
import { validationMixin } from 'vuelidate';
import { minLength, required, requiredIf } from 'vuelidate/lib/validators';

// Helpers
import { DISCOUNT_TARGET, LineItemType, SimplePricingRule } from '@/__new__/services/dno/pricing/pricingRulesPortal';
import ENTITY_TYPES from '@/common/entities/entityTypes';
import { getOffers } from '@/__new__/services/dno/pc/http/offer';
import { getCategories } from '@/__new__/services/dno/pc/http/categories';
import { getMultiLangFieldValueByLocale } from '@/common/entities/entityHelper';
import * as Sentry from '@sentry/vue';
import {
    AMOUNT_CALCULATION_METHOD,
    COMPARISON_OP,
    MULTIPLIER,
    TARGET,
} from '@/__new__/services/dno/pricing/models/pricingDno';
import { TOOLTIP_POSITION } from '@/common/tooltip';
import { ICON_COLORS, ICON_TYPES } from '@/common/iconHelper';
import { summary as getSimpleRuleSummary } from '@/__new__/services/dno/pricing/pricingRulesService';
/**
 * Structure of line item options
 */
export interface LineItemOption {
    id: string;
    name: string;
}
type IdToNameDictionary = Record<string, string>;
/**
 * Function used to load line item options
 */
type LoaderFunction = () => Promise<LineItemOption[]>;

/**
 * Reusable Vuelidate Validators
 */

// eslint-disable-next-line func-names
const isEmpty = function (value: any) {
    return value === '' || value === undefined || value === null;
};
// eslint-disable-next-line func-names
const positive = function (value: any) {
    if (isEmpty(value)) {
        return true; // Skip validation on empty values
    }
    return value > 0;
};

// eslint-disable-next-line func-names
const number = function (value: any) {
    if (isEmpty(value)) {
        return true; // Skip validation on empty values
    }
    const num = Number(value);
    return !Number.isNaN(num);
};

// eslint-disable-next-line func-names
const finite = function (value: any) {
    if (isEmpty(value)) {
        return true; // Skip validation on empty values
    }
    return Number.isFinite(Number(value));
};

// eslint-disable-next-line func-names
const integer = function (value: any) {
    if (isEmpty(value)) {
        return true; // Skip validation on empty values
    }
    return Number.isInteger(Number(value));
};

export default Vue.extend({
    name: 'SimplePricingRuleForm',
    components: {
        AppInputV3,
        AppMultiselectV3,
        AppToggle,
        AppTooltip,
    },
    mixins: [validationMixin],
    props: {
        value: {
            type: Object as PropType<SimplePricingRule>,
            default: () => ({
                lineItemType: null,
                lineItems: [],
                spendingAmountOperator: COMPARISON_OP.GT_OR_EQ,
                spendingAmount: null,
                spendingAmountTarget: TARGET.TOTAL_BEFORE_TAX,
                spendingConditionEnabled: false,
                allLineItemsMandatoryConditionEnabled: false,
                lineItemQuantityConditionEnabled: false,
                lineItemQuantityOperator: COMPARISON_OP.GT_OR_EQ,
                lineItemQuantity: null,
                minLineItemAndSubscriptionQuantityConditionEnabled: false,
                minLineItemAndSubscriptionQuantity: null,
                discountTarget: DISCOUNT_TARGET.TOTAL_BEFORE_TAX,
                discountTargetEntityType: null,
                discountTargetEntityIds: [],
                discountAmount: null,
                discountAmountType: AMOUNT_CALCULATION_METHOD.FIXED,
                incrementalDiscountAmount: null,
                incrementalDiscountAmountType: null,
                incrementalDiscountMultiplier: null,
            }),
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        showValidation: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            ICON_COLORS,
            ICON_TYPES,
            TOOLTIP_POSITION,
            TARGET,
            DISCOUNT_TARGET,
            lineItemTypeOptions: [
                {
                    id: ENTITY_TYPES.OFFER,
                    name: this.$i18n.tc('productCatalog.entities.offer', 1),
                },
            ],
            lineItemOptionsLoading: {
                [ENTITY_TYPES.OFFER]: true,
                [ENTITY_TYPES.CATEGORY]: true,
            },
            lineItemOptionsByType: {
                [ENTITY_TYPES.OFFER]: [] as LineItemOption[],
                [ENTITY_TYPES.CATEGORY]: [] as LineItemOption[],
            } as Record<LineItemType, LineItemOption[]>,
            entityNamesByType: {
                [ENTITY_TYPES.OFFER]: {},
                [ENTITY_TYPES.CATEGORY]: {},
            } as Record<LineItemType, IdToNameDictionary>,
            comparisonOperatorOptions: [
                {
                    id: COMPARISON_OP.GT_OR_EQ,
                    name: this.$i18n.t('generic.atLeast'),
                },
                {
                    id: COMPARISON_OP.GT,
                    name: this.$i18n.t('generic.moreThan'),
                },
            ],
            showDiscountMultiplier: false,
            spendingAmountTargetOptions: [
                {
                    id: TARGET.TOTAL_BEFORE_TAX,
                    name: this.$i18n.t('pricingAndFees.total'),
                },
                {
                    id: TARGET.LINE_ITEMS_BEFORE_TAX,
                    name: this.$i18n.t('pricingAndFees.lineItems'),
                },
            ],
            discountTargetOptions: [
                {
                    id: DISCOUNT_TARGET.TOTAL_BEFORE_TAX,
                    name: this.$i18n.t('pricingAndFees.total'),
                },
                {
                    id: DISCOUNT_TARGET.LINE_ITEMS_BEFORE_TAX,
                    name: this.$i18n.t('pricingAndFees.lineItems'),
                },
                {
                    id: DISCOUNT_TARGET.SPECIFIC_LINE_ITEM_BEFORE_TAX,
                    name: this.$i18n.t('pricingAndFees.specificLineItems'),
                },
            ],
            amountTypeOptions: [
                {
                    id: AMOUNT_CALCULATION_METHOD.FIXED,
                    name: this.$i18n.t('generic.flat'),
                },
                {
                    id: AMOUNT_CALCULATION_METHOD.PERCENT,
                    name: this.$i18n.t('pricingAndFees.amountCalculationMethodToString.percent'),
                },
            ],
            discountMultiplerOptions: [
                {
                    id: MULTIPLIER.NUMBER_OF_LINE_AND_EXISTING_ITEMS,
                    name: this.$i18n.t('pricingAndFees.numberOfLineAndExistingItems'),
                },
                {
                    id: MULTIPLIER.NUMBER_OF_LINE_ITEMS,
                    name: this.$i18n.t('pricingAndFees.numberOfLineItems'),
                },
                {
                    id: MULTIPLIER.NUMBER_OF_EXISTING_ITEMS,
                    name: this.$i18n.t('pricingAndFees.numberOfExistingItems'),
                },
            ],
        };
    },
    computed: {
        ...mapGetters('operators', {
            selectedLanguage: Getters.languageDefault,
        }),
        summary(): string {
            const lineItemNames = this.getEntityNames(this.value.lineItemType, this.value.lineItems);
            const targetItemNames = this.getEntityNames(
                this.value.discountTargetEntityType,
                this.value.discountTargetEntityIds,
            );
            return getSimpleRuleSummary(this.value, lineItemNames, targetItemNames);
        },
    },
    watch: {
        value: {
            handler() {
                this.$v.$touch();
                this.$emit('error', this.$v.$error);
            },
            immediate: true,
        },
    },
    mounted() {
        this.loadOffers();
        this.loadCategories();
    },
    methods: {
        onValueChange() {
            this.$emit('input', this.value);
            this.$v.$touch();
            this.$emit('error', this.$v.$error);
        },
        /**
         * Will return a list of entity names corresponding to the given entity ids and their type
         */
        getEntityNames(type: LineItemType | undefined, ids: string[] | undefined): string[] {
            if (!type || !ids) {
                return [];
            }
            return ids.map(id => this.entityNamesByType[type][id]);
        },
        onLineItemTypeChange(lineItemType: LineItemType) {
            this.value.lineItemType = lineItemType;
            this.value.lineItems.splice(0);
            this.onValueChange();
        },
        onLineItemsChange(lineItems: string[]) {
            this.value.lineItems = lineItems;
            this.onValueChange();
        },
        onSpendingConditionEnabledChange(spendingConditionEnabled: boolean) {
            this.value.spendingConditionEnabled = spendingConditionEnabled;
            this.onValueChange();
        },
        onSpendingAmountOperatorChange(operator: COMPARISON_OP) {
            this.value.spendingAmountOperator = operator;
            this.onValueChange();
        },
        onSpendingAmountChange(amount: number) {
            this.value.spendingAmount = amount;
            this.onValueChange();
        },
        onSpendingAmountTargetChange(target: TARGET) {
            this.value.spendingAmountTarget = target;
            this.onValueChange();
        },
        onAllLineItemsMandatoryChange(allLineItemsMandatory: boolean) {
            this.value.allLineItemsMandatoryConditionEnabled = allLineItemsMandatory;
            this.onValueChange();
        },
        onLineItemQuantityConditionEnabled(lineItemQuantityConditionEnabled: boolean) {
            this.value.lineItemQuantityConditionEnabled = lineItemQuantityConditionEnabled;
            this.onValueChange();
        },
        onLineItemQuantityOperatorChange(operator: COMPARISON_OP) {
            this.value.lineItemQuantityOperator = operator;
            this.onValueChange();
        },
        onLineItemQuantityChange(quantity: number) {
            this.value.lineItemQuantity = quantity;
            this.onValueChange();
        },
        onMinLineItemAndSubscriptionQuantityConditionEnabled(enabled: boolean) {
            this.value.minLineItemAndSubscriptionQuantityConditionEnabled = enabled;
            this.onValueChange();
        },
        onMinLineItemAndSubscriptionQuantityChange(quantity: number) {
            this.value.minLineItemAndSubscriptionQuantity = quantity;
            this.onValueChange();
        },
        onDiscountTargetChange(target: DISCOUNT_TARGET) {
            this.value.discountTarget = target;
            this.value.discountTargetEntityType = null;
            this.value.discountTargetEntityIds = [];
            this.onValueChange();
        },
        onDiscountAmountChange(amount: number) {
            this.value.discountAmount = amount;
            this.onValueChange();
        },
        onDiscountAmountTypeChange(type: AMOUNT_CALCULATION_METHOD) {
            this.value.discountAmountType = type;
            this.onValueChange();
        },
        onIncrementalDiscountAmountChange(amount: number) {
            this.value.incrementalDiscountAmount = amount;
            this.onValueChange();
        },
        onIncrementalDiscountAmountTypeChange(type: AMOUNT_CALCULATION_METHOD) {
            this.value.incrementalDiscountAmountType = type;
            this.onValueChange();
        },
        onIncrementalDiscountMultiplierChange(multiplier: MULTIPLIER) {
            this.value.incrementalDiscountMultiplier = multiplier;
            this.onValueChange();
        },
        onDiscountTargetEntityTypeChange(targetEntityType: LineItemType) {
            this.value.discountTargetEntityType = targetEntityType;
            this.value.discountTargetEntityIds = [];
            this.onValueChange();
        },
        onDiscountTargetEntityIdsChange(targetEntityIds: string[]) {
            this.value.discountTargetEntityIds = targetEntityIds;
            this.onValueChange();
        },
        loadOffers() {
            this.loadOptions(
                ENTITY_TYPES.OFFER,
                this.$i18n.t('alertMessage.pricingAndFees.failedToGetOffers'),
                async () => {
                    const res = await getOffers();
                    return Object.values(res.data.offer_by_id).map(offer => ({
                        id: offer.id,
                        name: getMultiLangFieldValueByLocale(offer.data.name, this.selectedLanguage),
                    }));
                },
            );
        },
        loadCategories() {
            this.loadOptions(
                ENTITY_TYPES.CATEGORY,
                this.$i18n.t('alertMessage.pricingAndFees.failedToGetCategories'),
                async () => {
                    const res = await getCategories();
                    return Object.values(res.data.category_by_id).map(category => ({
                        id: category.id,
                        name: getMultiLangFieldValueByLocale(category.data.name, this.selectedLanguage),
                    }));
                },
            );
        },
        async loadOptions(entityType: LineItemType, errorMessage: string, loader: LoaderFunction) {
            try {
                this.lineItemOptionsLoading[entityType] = true;
                this.lineItemOptionsByType[entityType] = await loader();
                this.entityNamesByType[entityType] = this.lineItemOptionsByType[entityType].reduce(
                    (dictionary: Record<string, string>, option: LineItemOption) => {
                        dictionary[option.id] = option.name;
                        return dictionary;
                    },
                    {},
                );
                this.lineItemOptionsLoading[entityType] = false;
            } catch (error) {
                this.lineItemOptionsLoading[entityType] = false;
                Sentry.captureException(error);
                this.$alert(errorMessage);
            }
        },
    },
    validations: {
        value: {
            lineItemType: {
                required,
            },
            lineItems: {
                required,
                minLength: minLength(1),
            },
            spendingAmount: {
                // eslint-disable-next-line func-names
                required: requiredIf(function () {
                    return Boolean(this.value.spendingConditionEnabled);
                }),
                number,
                positive,
                finite,
            },
            spendingAmountOperator: {
                // eslint-disable-next-line func-names
                required: requiredIf(function () {
                    return Boolean(this.value.spendingConditionEnabled);
                }),
            },
            spendingAmountTarget: {
                // eslint-disable-next-line func-names
                required: requiredIf(function () {
                    return Boolean(this.value.spendingConditionEnabled);
                }),
            },
            lineItemQuantityOperator: {
                // eslint-disable-next-line func-names
                required: requiredIf(function () {
                    return Boolean(this.value.lineItemQuantityConditionEnabled);
                }),
            },
            lineItemQuantity: {
                // eslint-disable-next-line func-names
                required: requiredIf(function () {
                    return Boolean(this.value.lineItemQuantityConditionEnabled);
                }),
                number,
                positive,
                finite,
                integer,
            },
            discountTarget: {
                required,
            },
            discountTargetEntityType: {
                // eslint-disable-next-line func-names
                required: requiredIf(function () {
                    return this.value.discountTarget === DISCOUNT_TARGET.SPECIFIC_LINE_ITEM_BEFORE_TAX;
                }),
            },
            discountTargetEntityIds: {
                // eslint-disable-next-line func-names
                required: requiredIf(function () {
                    return this.value.discountTarget === DISCOUNT_TARGET.SPECIFIC_LINE_ITEM_BEFORE_TAX;
                }),
                minLength: minLength(1),
            },
            discountAmount: {
                required,
                number,
                finite,
            },
            discountAmountType: {
                required,
            },
            minLineItemAndSubscriptionQuantity: {
                // eslint-disable-next-line func-names
                required: requiredIf(function () {
                    return Boolean(this.value.minLineItemAndSubscriptionQuantityConditionEnabled);
                }),
                number,
                positive,
                finite,
                integer,
            },
            incrementalDiscountAmount: {
                // eslint-disable-next-line func-names
                required: requiredIf(function () {
                    return (
                        this.value.incrementalDiscountAmountType != null ||
                        this.value.incrementalDiscountMultiplier != null
                    );
                }),
                number,
                finite,
            },
            incrementalDiscountAmountType: {
                // eslint-disable-next-line func-names
                required: requiredIf(function () {
                    return (
                        Boolean(this.value.incrementalDiscountAmount) ||
                        this.value.incrementalDiscountMultiplier != null
                    );
                }),
            },
            incrementalDiscountMultiplier: {
                // eslint-disable-next-line func-names
                required: requiredIf(function () {
                    return (
                        Boolean(this.value.incrementalDiscountAmount) ||
                        this.value.incrementalDiscountAmountType != null
                    );
                }),
            },
        },
    },
});
