import Actions, { Getters, Mutations, State } from '../mutation-types';
import { Module } from 'vuex';

interface ModuleState {
    [State.SELECTED_CHANNEL_PARTNER_BY_COMPANY]: { [key: number]: string };
}

export interface ChannelPartnerData {
    channelPartnerId: string;
    companyId: number;
}

export default {
    namespaced: true,
    state: {
        [State.SELECTED_CHANNEL_PARTNER_BY_COMPANY]: {},
    },
    mutations: {
        [Mutations.SET_CHANNEL_PARTNER]: (_state, payload: ChannelPartnerData) => {
            _state[State.SELECTED_CHANNEL_PARTNER_BY_COMPANY][payload.companyId] = payload.channelPartnerId;
        },
    },
    actions: {
        [Actions.SET_CHANNEL_PARTNER_FOR_COMPANY]: ({ commit }, payload: ChannelPartnerData) => {
            commit(Mutations.SET_CHANNEL_PARTNER, payload);
        },
    },
    getters: {
        [Getters.GET_CHANNEL_PARTNER]: state => (companyId: number) => {
            return state[State.SELECTED_CHANNEL_PARTNER_BY_COMPANY][companyId];
        },
    },
} as Module<ModuleState, unknown>;
