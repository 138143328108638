import { DaysOfWeekNames } from '@/__new__/features/campaigns/common/DaysOfWeek';

export const RepeatTypes = {
    daily: 'Daily',
    weekly: 'Weekly',
    monthly: 'Monthly',
};

const RepeatTypesServerMapping = {
    [RepeatTypes.daily]: 0,
    [RepeatTypes.weekly]: 1,
    [RepeatTypes.monthly]: 2,
};

const RepeatTypesFromAnyMapping = {
    [RepeatTypesServerMapping[RepeatTypes.daily]]: RepeatTypes.daily,
    [RepeatTypesServerMapping[RepeatTypes.weekly]]: RepeatTypes.weekly,
    [RepeatTypesServerMapping[RepeatTypes.monthly]]: RepeatTypes.monthly,
    [RepeatTypes.daily]: RepeatTypes.daily,
    [RepeatTypes.weekly]: RepeatTypes.weekly,
    [RepeatTypes.monthly]: RepeatTypes.monthly,
};

export const RepeatTypesUnit = {
    [RepeatTypes.daily]: 'day',
    [RepeatTypes.weekly]: 'week',
    [RepeatTypes.monthly]: 'month',
};

export class Repeat {
    type;
    hours; // 24h
    minutes;
    every;
    dayOfWeek;
    dayOfMonth;
    lastDayOfMonth;

    constructor(
        type: string,
        hours: number,
        minutes: number,
        every: number,
        dayOfWeek: keyof typeof DaysOfWeekNames | null,
        dayOfMonth: number | null,
        lastDayOfMonth: boolean,
    ) {
        this.type = type;
        this.hours = hours || 0;
        this.minutes = minutes || 0;
        this.every = every;
        this.dayOfWeek = dayOfWeek;
        this.dayOfMonth = dayOfMonth;
        this.lastDayOfMonth = lastDayOfMonth;
    }

    buildJson() {
        return {
            type: RepeatTypesServerMapping[this.type],
            hours: this.hours,
            minutes: this.minutes,
            every: this.every,
            dayOfWeek: this.dayOfWeek,
            dayOfMonth: this.lastDayOfMonth ? null : this.dayOfMonth,
            lastDayOfMonth: this.lastDayOfMonth,
        };
    }

    static fromJson(json: Record<string, any>): Repeat {
        return new Repeat(
            RepeatTypesFromAnyMapping[json.type],
            json.hours,
            json.minutes,
            json.every,
            json.dayOfWeek,
            json.dayOfMonth,
            json.lastDayOfMonth,
        );
    }
}
