<template>
    <div class="double_input">
        <div>
            <AppInputV3
                :id="index + `sst`"
                v-model.trim="sstMask"
                type="number"
                :label="$i18n.t('productCatalog.services.editor.networkSliceIdentifierSST')"
                :placeholder="$i18n.t('productCatalog.services.editor.networkSliceIdentifierKey')"
                :invalid="invalidSST"
                class="input_item"
                :min="0"
                :max="255"
                :errorMessage="errorMessageSST"
                :disabled="isDisabled.sst"
                @keyup.enter="updateSST(sstMask)"
            />
            <div
                class="tags-container"
                :class="isManyTagsSST && { 'expanded-container': isExpandedSST, 'collapsed-container': !isExpandedSST }"
            >
                <div ref="sstTag">
                    <Tags
                        v-if="sstData.length"
                        v-model="sstData"
                        class="pt-1 pb-1"
                        @input="deleteTagSST(sstData)"
                    />
                </div>
            </div>
            <div v-if="isManyTagsSST">
                <div class="d-flex justify-content-start align-items-center">
                    <span
                        :class="{ 'blue-arrow-down': !isExpandedSST, 'blue-arrow-up': isExpandedSST }"
                        class="arrow-position-fix"
                    />
                    <span
                        v-if="!isExpandedSST"
                        class="seeMoreButton"
                        @click="toggleExpandedSST"
                    >
                        {{ `${$i18n.t('generic.seeMore')}...` }}
                    </span>
                    <span
                        v-else
                        class="seeMoreButton"
                        @click="toggleExpandedSST"
                    >
                        {{ `${$i18n.t('generic.seeLess')}` }}
                    </span>
                </div>
            </div>
        </div>
        <div>
            <AppInputV3
                :id="index + `sd`"
                v-model.trim="sdMask"
                :label="$i18n.t('productCatalog.services.editor.networkSliceIdentifierSD')"
                :placeholder="$i18n.t('productCatalog.services.editor.networkSliceIdentifierValue')"
                :errorMessage="errorMessageSD"
                class="input_item"
                :invalid="invalidSD"
                :disabled="isDisabled.sd"
                @keyup.enter="updateSD(sdMask)"
            />
            <div
                class="tags-container"
                :class="isManyTagsSD && { 'expanded-container': isExpandedSD, 'collapsed-container': !isExpandedSD }"
            >
                <div ref="sdTag">
                    <Tags
                        v-if="sdData.length"
                        v-model="sdData"
                        class="pt-1 pb-1"
                        @input="deleteTagSD(sdData)"
                    />
                </div>
            </div>
            <div v-if="isManyTagsSD">
                <div class="d-flex justify-content-start align-items-center">
                    <span
                        :class="{ 'blue-arrow-down': !isExpandedSD, 'blue-arrow-up': isExpandedSD }"
                        class="arrow-position-fix"
                    />
                    <span
                        v-if="!isExpandedSD"
                        class="seeMoreButton"
                        @click="toggleExpandedSD"
                    >
                        {{ `${$i18n.t('generic.seeMore')}...` }}
                    </span>
                    <span
                        v-else
                        class="seeMoreButton"
                        @click="toggleExpandedSD"
                    >
                        {{ `${$i18n.t('generic.seeLess')}` }}
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import AppInputV3 from '@/components/partials/inputs/AppInputV3.vue';
import Tags from '@/components/partials/inputs/Tags.vue';
import { required, between } from 'vuelidate/lib/validators';

const validateSD = value => {
    if (typeof value === 'undefined' || value === null || value === '') {
        return true;
    }
    return /^[A-Fa-f0-9]{6}$/.test(value);
};

export default {
    name: 'NetworkSliceIdentifierKeyValue',
    components: {
        AppInputV3,
        Tags,
    },
    props: {
        invalidSST: {
            type: Boolean,
            default: false,
        },
        index: {
            type: String,
            default: null,
        },
        sst: {
            type: Array,
            default: () => [],
        },
        sd: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            sstMask: 0,
            sdMask: '',
            isExpandedSST: false,
            isExpandedSD: false,
            tagsContainerHeightSST: null,
            tagsContainerHeightSD: null,
            invalidSD: false,
            sstData: [],
            sdData: [],
        };
    },
    validations() {
        return {
            sstMask: {
                required,
                between: between(0, 255),
            },
            sdMask: {
                validateSD,
            },
        };
    },
    computed: {
        isDisabled() {
            return {
                sd: this.sstData.length > 1,
                sst: Boolean(this.sstData.length > 0 && this.sdData.length > 0),
            };
        },
        isManyTagsSST() {
            return this.tagsContainerHeightSST > 60;
        },
        isManyTagsSD() {
            return this.tagsContainerHeightSD > 60;
        },
        errorMessageSST() {
            return this.invalidSST ? this.$i18n.t('events.alerts.nsiSstIsNotUnique') : '';
        },
        errorMessageSD() {
            return this.invalidSD ? this.$i18n.t('events.alerts.nsiSstIsNotUnique') : '';
        },
    },
    watch: {
        isManyTagsSST(isMany) {
            if (isMany === false) {
                this.isExpandedSST = false;
            }
        },
        isManyTagsSD(isMany) {
            if (isMany === false) {
                this.isExpandedSD = false;
            }
        },
    },
    created() {
        this.sstData = this.sst;
        this.sdData = this.sd;
    },
    updated() {
        this.tagsContainerHeightSST = this.$refs.sstTag.getBoundingClientRect().height;
        this.tagsContainerHeightSD = this.$refs.sdTag.getBoundingClientRect().height;
    },
    methods: {
        updateSST(sst) {
            this.$v.$touch();
            if (!this.$v.$invalid && !this.invalidSST) {
                this.sstMask = 0;
                this.sstData.push(sst);
            }

            this.$emit('input', { sst: this.sstData, sd: this.sdData });
        },
        updateSD(sd) {
            this.invalidSD = !(this.sdUnique(sd) && validateSD(sd) && this.sstData.length > 0);
            if (!this.invalidSD) {
                this.sdMask = '';
                this.sdData.push(sd);
                this.$emit('input', { sst: this.sstData, sd: this.sdData });
            }
        },
        deleteTagSST(value) {
            this.sstData = value;
            this.$emit('input', { sst: this.sstData, sd: this.sdData });
        },
        deleteTagSD(value) {
            this.sdData = value;
            this.$emit('input', { sst: this.sstData, sd: this.sdData });
        },
        sdUnique(element) {
            return !this.sdData.includes(element);
        },
        toggleExpandedSD() {
            this.isExpandedSD = !this.isExpandedSD;
        },
        toggleExpandedSST() {
            this.isExpandedSST = !this.isExpandedSST;
        },
    },
};
</script>
<style lang="scss" scoped>
@import '~@/assets/scss/colors';
@import '~@/assets/scss/palette';
@import '~@/assets/scss/layout';
@import '~@/assets/scss/icons';

$icon-path: '~@/assets/icons/';
.double_input {
    display: flex;
    justify-content: flex-start;
    &:hover {
        .delete-btn {
            opacity: 1;
        }
    }
}
.input_item {
    margin-right: 3rem;
    min-width: 14rem;
    width: 24%;
    &.full-width {
        width: 100%;
    }
}
.blue-arrow-down {
    width: 1rem;
    height: 1rem;
    content: url($icon-path + $blue-arrow-down);
}
.blue-arrow-up {
    width: 1rem;
    height: 1rem;
    content: url($icon-path + $blue-arrow-up);
}
.arrow-position-fix {
    margin-right: 0.5rem;
}
.tags-container {
    position: relative;
}
.seeMoreButton {
    font-size: 0.75rem;
    text-align: center;
    color: $blue;
    cursor: pointer;
    font-weight: 600;
}
.collapsed-container {
    height: 0.1rem;
    overflow: hidden;
}
</style>
