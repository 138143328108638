<!-- Demo Component: Throw away code -->
<template>
    <AbstractEditPageWrapper>
        <template #header>
            <AppHeader :pageTitle="pageTitle" />
        </template>
        <template #content>
            <div class="lf-subtitle mb-2">
                {{ $i18n.t('generic.general') }}
            </div>
            <AppInputV3
                v-model="pricingRule.name"
                :placeholder="$i18n.t('generic.addName')"
                :label="$i18n.t('generic.name')"
                :invalid="$v.pricingRule.name.$error"
                class="editor-input-largest mb-2"
            />
            <AppTextareaV3
                v-model="pricingRule.description"
                :placeholder="$i18n.t('generic.addDescription')"
                :label="$i18n.t('generic.description')"
                class="editor-input-largest"
            />

            <div class="section position-relative mt-3">
                <div
                    v-t="'generic.conditions'"
                    class="lf-subtitle"
                />
                <AppButton
                    v-if="!gConditionsTree"
                    class="mb-2"
                    :label="$t('rewards.editor.createCondition')"
                    isSmall
                    @click="createConditionsTreeRootNode"
                />
                <AppButton
                    v-if="gConditionsTree"
                    class="mb-2"
                    :label="conditionsTreeToggleBtnLabel"
                    isSmall
                    @click="conditionsTreeIsCollapsed = !conditionsTreeIsCollapsed"
                />
                <ConditionsTree
                    v-if="!!gConditionsTree"
                    class="editor-input-largest"
                    :node="gConditionsTree"
                    :isCollapsed="conditionsTreeIsCollapsed"
                    :displayError="conditionsTreeDisplayError"
                    :pricingDemo="true"
                    @updateErrorState="conditionsTreeHasError = $event"
                />
                <span
                    v-if="!gConditionsTree && $v.gConditionsTree.$error"
                    class="condition-error-message"
                >
                    {{ $t('rewards.editor.pleaseSetupCondition') }}
                </span>
            </div>

            <div class="lf-subtitle mb-2">
                {{ $t('generic.actions') }}
            </div>
            <div class="mb-2">
                <div class="mb-2 condition-wrapper">
                    <div
                        v-for="(action, index) of pricingRule.actions"
                        :key="`action-${index}`"
                        class="input-content-row"
                    >
                        <div>
                            <AppMultiselectV3
                                v-model="pricingRule.actions[index].offer"
                                :options="offers"
                                label="name"
                                trackBy="id"
                                optionId="id"
                                :additionalLabel="$i18n.t('pricingAndFees.label.scopeLineItem')"
                                :placeholder="'Select offer'"
                                :small="true"
                                :allowEmpty="false"
                                :searchable="false"
                                :showLabels="false"
                                :blueArrow="true"
                                :error="$v.pricingRule.actions && $v.pricingRule.actions.$each[index].offer.$error"
                                class="multiselect small offer-options"
                            />
                        </div>
                        <div class="d-flex mt-2">
                            <AppMultiselectV3
                                v-model="pricingRule.actions[index].discount"
                                :options="discountOptions"
                                label="label"
                                trackBy="id"
                                optionId="id"
                                :additionalLabel="$t('generic.discount')"
                                :small="true"
                                :allowEmpty="false"
                                :searchable="false"
                                :showLabels="false"
                                :blueArrow="true"
                                class="multiselect small condition-options mr-2"
                            />
                            <div class="amount-container">
                                <AppInputV3
                                    v-model="pricingRule.actions[index].amount"
                                    :placeholder="$t('generic.amount')"
                                    class="mr-1 amount-input"
                                    :invalid="
                                        $v.pricingRule.actions && $v.pricingRule.actions.$each[index].amount.$error
                                    "
                                />
                                <p class="mb-2 symbol-p">{{ pricingRule.actions[index].discount === 1 ? '%' : '$' }}</p>
                            </div>
                        </div>

                        <img
                            v-if="pricingRule.actions.length > 1"
                            src="@/assets/icons/delete-trash.svg"
                            class="delete-btn col-1"
                            @click="deleteAction(index)"
                        />
                    </div>
                    <AppButton
                        :label="$i18n.t('charging.chargingSpecifications.editor.addCsAction')"
                        :iconType="ICON_TYPES.PLUS"
                        :isSmall="true"
                        class="mt-2"
                        @click="addNewAction"
                    />
                </div>
            </div>
        </template>
        <template #controls>
            <EditorButtons
                :showSave="true"
                @cancel="routeToListPage"
                @save="savePricingRule"
            />
        </template>
    </AbstractEditPageWrapper>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
import { required } from 'vuelidate/lib/validators';
import Actions, { Getters } from '@/store/mutation-types';
import { createTreeNode } from '@/common/conditions-tree';
import { validationMixin } from 'vuelidate';

// Vue components
import AbstractEditPageWrapper from '@/components/layout/AbstractEditPageWrapper.vue';
import AppHeader from '@/components/layout/AppHeader.vue';
import AppInputV3 from '@/components/partials/inputs/AppInputV3.vue';
import AppTextareaV3 from '@/components/partials/inputs/AppTextareaV3.vue';
import EditorButtons from '@/components/layout/EditorButtons.vue';
import AppButton from '@/components/partials/inputs/AppButton.vue';
import ConditionsTree from '@/modules/rewards/components/conditions-tree/ConditionsTree.vue';
import AppMultiselectV3 from '@/components/partials/inputs/AppMultiselectV3.vue';

// Misc
import RouteNames from '@/router/routeNames';
import { uuidV4 } from '@/common/utils';
import ENTITY_TYPES from '@/common/entities/entityTypes';
import { ICON_TYPES } from '@/common/iconHelper';
import { ALERT_TYPES } from '@/common/alerts/Alert';

export default {
    name: 'PricingRulesEditorKuiperDemo',
    components: {
        AppMultiselectV3,
        AbstractEditPageWrapper,
        AppHeader,
        AppInputV3,
        AppTextareaV3,
        EditorButtons,
        AppButton,
        ConditionsTree,
    },
    mixins: [validationMixin],
    data() {
        return {
            isEditing: false,
            pricingRule: {
                id: null,
                name: null,
                description: null,
                state: null,
                condition: null,
                actions: [
                    {
                        discount: 1,
                        amount: '',
                    },
                ],
            },
            conditionsTreeIsCollapsed: false,
            conditionsTreeDisplayError: false,
            conditionsTreeHasError: false,
            discountOptions: [
                {
                    id: 1,
                    label: 'Percentage discount',
                },
                {
                    id: 2,
                    label: 'Absolute discount',
                },
            ],

            ICON_TYPES,
            ALERT_TYPES,
        };
    },
    validations() {
        return {
            gConditionsTree: {
                validCondition() {
                    return !this.conditionsTreeHasError;
                },
            },
            pricingRule: {
                name: {
                    required,
                },
                actions: {
                    $each: {
                        amount: {
                            required,
                            validation: function validation(val, parent) {
                                const { discount } = parent;
                                const convertedToNumber = Number(val);

                                // Return invalid if is entered something different than number
                                if (Number.isNaN(convertedToNumber)) {
                                    return false;
                                }

                                // Return invalid if percentage discount is selected and amount is greater than 100
                                if (discount === 1 && convertedToNumber > 100) {
                                    return false;
                                }

                                return true;
                            },
                        },
                        offer: {
                            required,
                        },
                    },
                },
            },
        };
    },
    computed: {
        ...mapGetters('productcatalog', {
            getEntitiesByType: Getters.PC_GET_ENTITIES_BY_TYPE,
        }),
        offers() {
            return Object.values(this.getEntitiesByType(ENTITY_TYPES.OFFER));
        },
        ...mapGetters({
            gConditionsTree: 'conditionsTree/conditionsTree',
        }),
        conditionsTreeToggleBtnLabel() {
            return this.conditionsTreeIsCollapsed ? this.$t('generic.expandAll') : this.$t('generic.collapseAll');
        },
        pageTitle() {
            return this.$route.params.id
                ? this.$i18n.t('pricing.editPricingRule')
                : this.$i18n.t('pricing.addPricingRule');
        },
    },
    watch: {
        gConditionsTree(newVal, oldVal) {
            if (!newVal || !oldVal) {
                this.$v.gConditionsTree.$reset();
                this.conditionsTreeHasError = false;
                this.conditionsTreeIsCollapsed = false;
            }
        },
    },
    created() {
        this.$withLoadingSpinner(async () => {
            const promises = [
                this[Actions.PC_REQUEST_ENTITIES_BY_TYPE_AND_IDS]({ entityType: ENTITY_TYPES.PRODUCT }),
                this[Actions.PC_REQUEST_ENTITIES_BY_TYPE_AND_IDS]({ entityType: ENTITY_TYPES.OFFER }),
            ];

            await Promise.all(promises);

            if (this.$route.params.id) {
                const { id } = this.$route.params;

                const vrStr = localStorage.getItem('pricingRules');
                const vrObj = JSON.parse(vrStr || '{}');
                const vr = vrObj[id];

                if (vr == null) {
                    throw Error('Could not find Pricing Rule');
                }

                // await this.setupEditPageData();
                await this.aBuildClientConditionsTree({
                    root: vr.condition,
                });
            }
        });
    },
    async mounted() {
        const { id } = this.$route.params;

        this.isEditing = Boolean(id);

        if (this.isEditing) {
            await this.loadEditor(id);
        }
    },
    beforeDestroy() {
        this.mClearConditionsMap();
    },
    methods: {
        ...mapActions('productcatalog', [Actions.PC_REQUEST_ENTITIES_BY_TYPE_AND_IDS]),
        ...mapMutations({
            mUpdateTree: 'conditionsTree/updateTree',
            mClearConditionsMap: 'conditionsTree/clearConditionsMap',
        }),
        ...mapActions('conditionsTree', {
            aBuildServerConditionsTree: 'buildServerConditionsTree',
            aBuildClientConditionsTree: 'buildClientConditionsTree',
        }),
        loadEditor(id) {
            const vrStr = localStorage.getItem('pricingRules');
            const vrObj = JSON.parse(vrStr || '{}');
            const vr = vrObj[id];

            if (vr == null) {
                throw Error('Could not find Validation Rule');
            }

            this.pricingRule = {
                id: vr.id,
                name: vr.name,
                description: vr.description,
                state: vr.state,
                actions: vr.actions,
            };
        },
        async savePricingRule() {
            this.conditionsTreeDisplayError = false;
            this.$v.$reset();
            await this.$nextTick();
            this.$v.$touch();

            if (this.$v.$invalid) {
                this.conditionsTreeDisplayError = this.$v.gConditionsTree.$error;

                this.$alert(this.$i18n.t('alertMessage.pleaseFixValidation'));
                return;
            }

            // Load from LocalStorage
            const validationRulesStr = localStorage.getItem('pricingRules') || '{}';
            const pricingRules = JSON.parse(validationRulesStr);

            // Build change
            let id;

            if (this.isEditing) {
                id = this.pricingRule.id || '';
            } else {
                id = uuidV4();
            }

            const vr = {
                id,
                name: this.pricingRule.name,
                description: this.pricingRule.description,
                state: this.pricingRule.state || 1,
                condition: this.gConditionsTree
                    ? await this.aBuildServerConditionsTree(this.gConditionsTree)
                    : undefined,
                actions: this.pricingRule.actions,
            };

            pricingRules[id] = vr;

            // Save to LocalStorage
            localStorage.setItem('pricingRules', JSON.stringify(pricingRules));

            // Success Alert
            this.$showSuccessAlert({
                message: 'Successfully saved pricing rule.',
            });

            // Route to List page
            setTimeout(() => this.routeToListPage(), 1000);
        },
        addNewAction() {
            this.pricingRule.actions.push({
                discount: 1,
                amount: '',
            });
        },
        deleteAction(index) {
            this.pricingRule.actions.splice(index, 1);
        },
        createConditionsTreeRootNode() {
            const node = createTreeNode({});
            this.mUpdateTree(node);
        },
        routeToListPage() {
            this.$router.push({
                name: RouteNames.PRICING_RULES_KUIPER_DEMO,
                params: { companyId: this.$route.params.companyId },
            });
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/_palette.scss';
@import '~@/assets/scss/_layout.scss';
@import '~@/assets/scss/_typographyV2.scss';
@import '~@/assets/scss/editor-layout-v2';
@import '~@/assets/scss/typographyV2';
@import '~@/assets/scss/palette';
@import '~@/assets/scss/icons';
@import '~@/assets/scss/z-indexes';

.execution-type {
    margin-left: $spacing-s;
    width: 15rem;
}

.hierarchy-scope {
    width: calc(60% - 15.75rem);
}

.condition-wrapper {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
}

.border-bottom {
    border-bottom: solid 0.063rem rgba(51, 81, 149, 0.15);
}

.condition-label {
    text-transform: uppercase;
    font-size: 0.625rem;
    font-weight: bold;
    line-height: 2;
    color: $steel;
    margin-right: 0.75rem;
    border-bottom: solid 0.063rem rgba(51, 81, 149, 0.15);
    margin-bottom: 0.5rem;
}

.condition-options {
    min-width: 12rem;
    width: max-content;
}

.offer-options {
    min-width: 24rem;
    width: max-content;
}

.input-content-row {
    display: flex;
    border: 1px solid rgba(51, 81, 149, 0.15);
    padding: 0.75rem;
    margin-bottom: 0.5rem;
    position: relative;
    flex-direction: column;
    width: 60%;
}

.operator-multiselect {
    width: 30%;
}

.delete-btn {
    cursor: pointer;
    top: 1rem;
    height: 1rem;
    transform: translateY(calc(50% + 0.5rem));
    position: absolute;
    top: -0.5rem;
    right: -0.75rem;
}

.amount-container {
    display: flex;
    align-items: flex-end;
}

.amount-input {
    width: 5rem;
}

.symbol-p {
    color: #335195;
    font-size: 14px;
    font-weight: 500;
}
</style>
