import http from '@/http/index';

/**
 * @returns {AxiosPromise}
 */
export function getSignedURL(data) {
    return http({
        method: 'POST',
        url: '/v3simupload/signedurl',
        headers: {
            'Content-type': 'application/json',
        },
        data,
    });
}

/**
 * @param page
 * @param size
 * @returns {AxiosPromise}
 */
export function getRegisteredEntities(page = 1, size = 500) {
    return http({
        method: 'GET',
        url: '/v3simuploadwrite/registeredentities',
        headers: {
            'Content-type': 'application/json',
        },
        params: { page, size },
    });
}

/**
 * @param name
 * @returns {AxiosPromise}
 */
export function getEntityDetailsByName(name) {
    return http({
        method: 'GET',
        url: '/v3simuploadwrite/entitydetailsbyname',
        headers: {
            'Content-type': 'application/json',
        },
        params: { name },
    });
}

/**
 * @param name
 * @param category
 * @param page
 * @param size
 * @returns {AxiosPromise}
 */
export function getEntityDetailsByCategory(name, category, page, size) {
    return http({
        method: 'GET',
        url: '/v3simuploadwrite/entitydetailsbycategory',
        headers: {
            'Content-type': 'application/json',
        },
        params: {
            name,
            category,
            page,
            size,
        },
    });
}

/**
 * @returns {AxiosPromise}
 */
export function getCounts() {
    return http({
        method: 'POST',
        url: '/v3simupload/getcounts',
    });
}

/**
 * @returns {AxiosPromise}
 */
export function fetchResource(data) {
    return http({
        method: 'POST',
        url: '/v3simprofile/fetchresource',
        data,
    });
}

export default {};
