import { DOCUMENT_JOB } from '@/__new__/services/dno/documents/models/DocumentInterfaces';
import EventProp, { CDP_PROPERTY_TYPE } from '@/__new__/services/dno/events/models/EventProp';
import type { Event } from '@/__new__/services/dno/events/models/Event';
import type { Job } from '@/__new__/features/settings/common/applicationManagerHelper';
import type ApiInvokerSinkConfig from '@/__new__/services/dno/sinkConfigs/models/ApiInvokerSinkConfig';

export function getValidDocumentIdsFromEventProperties(event: Event): EventProp[] {
    const excludedNames = ['id_type', 'id_value', 'operator_name', 'operatorName', 'epoch_millis', 'event_type'];
    const excludedTypes = [
        CDP_PROPERTY_TYPE.BOOLEAN,
        CDP_PROPERTY_TYPE.ARRAY,
        CDP_PROPERTY_TYPE.OBJECT,
        CDP_PROPERTY_TYPE.MIXED,
    ];
    const isValidDocumentId = (p: EventProp) =>
        !excludedTypes.includes(p.type) && !excludedNames.includes(p.name) && p.name.charAt(0) !== '@';

    return event?.properties?.filter(isValidDocumentId);
}

export function isDocumentTemplateJobId(jobId: Job['id'] | ApiInvokerSinkConfig['id']): boolean {
    return [DOCUMENT_JOB.CALLBACK, DOCUMENT_JOB.TRIGGER].some(prefix => jobId?.startsWith(prefix));
}
