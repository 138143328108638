import i18n from '@/i18n';
import { TranslateResult } from 'vue-i18n';
import _upperFirst from 'lodash/upperFirst';

export type resetPeriodKeys = 'h' | 'd' | 'w' | 'm' | 'product_duration' | 'recurrence_period';

export enum RESET_PERIOD_TYPES_MAPPER {
    h = 'h',
    d = 'd',
    w = 'w',
    W = 'W',
    m = 'm',
    M = 'M',
    product_duration = 'product_duration',
    recurrence_period = 'recurrence_period',
}

export enum RESET_PERIOD_TYPES {
    h = '24h',
    d = 'd',
    w = 'w',
    m = 'm',
    product_duration = 'product_duration',
    recurrence_period = 'recurrence_period',
}

export interface TriggerOptionsObject {
    label?: TranslateResult;
    value: string;
}

export type ResetPeriodOptions = {
    [T in resetPeriodKeys]: TriggerOptionsObject;
};

export const resetPeriodOptions: Readonly<ResetPeriodOptions> = {
    [RESET_PERIOD_TYPES_MAPPER.h]: {
        label: _upperFirst(i18n.t('charging.chargingSpecifications.periodTypes.24hours') as string),
        value: RESET_PERIOD_TYPES.h,
    },
    [RESET_PERIOD_TYPES_MAPPER.d]: {
        label: _upperFirst(i18n.t('generic.days') as string),
        value: RESET_PERIOD_TYPES.d,
    },
    [RESET_PERIOD_TYPES_MAPPER.w]: {
        label: _upperFirst(i18n.t('generic.weeks') as string),
        value: RESET_PERIOD_TYPES.w,
    },
    [RESET_PERIOD_TYPES_MAPPER.m]: {
        label: _upperFirst(i18n.t('generic.months') as string),
        value: RESET_PERIOD_TYPES.m,
    },
    [RESET_PERIOD_TYPES_MAPPER.product_duration]: {
        label: i18n.t('charging.usageCounters.productDuration'),
        value: RESET_PERIOD_TYPES.product_duration,
    },
    [RESET_PERIOD_TYPES_MAPPER.recurrence_period]: {
        label: i18n.t('charging.usageCounters.recurrencePeriod'),
        value: RESET_PERIOD_TYPES.recurrence_period,
    },
};

export function setResetPeriodDurationLabel(type: resetPeriodKeys): string {
    switch (type) {
        case RESET_PERIOD_TYPES.d:
            return i18n.t('generic.days') as string;
        case RESET_PERIOD_TYPES.w:
            return i18n.t('generic.weeks') as string;
        case RESET_PERIOD_TYPES.m:
            return i18n.t('generic.months') as string;

        default:
            return '' as string;
    }
}

export const resetPeriodStartDayOptions = {
    [RESET_PERIOD_TYPES.w]: {
        [RESET_PERIOD_TYPES_MAPPER.w]: {
            label: i18n.t('charging.usageCounters.startOfWeek'),
            value: RESET_PERIOD_TYPES.w,
        },
        [RESET_PERIOD_TYPES_MAPPER.W]: {
            label: i18n.t('charging.usageCounters.dayOfPurchase'),
            value: RESET_PERIOD_TYPES_MAPPER.W,
        },
    },
    [RESET_PERIOD_TYPES.m]: {
        [RESET_PERIOD_TYPES_MAPPER.m]: {
            label: i18n.t('charging.usageCounters.startOfMonth'),
            value: RESET_PERIOD_TYPES_MAPPER.m,
        },
        [RESET_PERIOD_TYPES_MAPPER.M]: {
            label: i18n.t('charging.usageCounters.dayOfPurchase'),
            value: RESET_PERIOD_TYPES_MAPPER.M,
        },
    },
};

const resetPeriodStartDayLabelMapper: Partial<Record<RESET_PERIOD_TYPES_MAPPER, TranslateResult>> = {
    [RESET_PERIOD_TYPES_MAPPER.m]: i18n.t('charging.usageCounters.startOfMonth'),
    [RESET_PERIOD_TYPES_MAPPER.M]: i18n.t('charging.usageCounters.dayOfPurchase'),
    [RESET_PERIOD_TYPES_MAPPER.w]: i18n.t('charging.usageCounters.startOfWeek'),
    [RESET_PERIOD_TYPES_MAPPER.W]: i18n.t('charging.usageCounters.dayOfPurchase'),
};

export function makeResetPeriod({ value }: { value: string }, duration?: number, startDay?: any): string {
    switch (value) {
        case RESET_PERIOD_TYPES.h || RESET_PERIOD_TYPES.product_duration || RESET_PERIOD_TYPES.recurrence_period:
            return value;
        case RESET_PERIOD_TYPES.d:
            return duration && value ? `${duration}${value}` : '';
        case RESET_PERIOD_TYPES.w:
        case RESET_PERIOD_TYPES.m:
            return duration && startDay?.value ? `${duration}${startDay.value}` : '';

        default:
            return value;
    }
}

function getResetPeriodType(data: string): string {
    return data.replace(/[0-9]/g, '');
}

function getResetPeriodLabel(data: string): TranslateResult | undefined {
    if (data === '') return '';
    const type = data.replace(/[0-9]/g, '').toLowerCase();
    if (!(type in resetPeriodOptions)) {
        throw new Error('Unknown reset period option');
    }

    return resetPeriodOptions?.[type as keyof ResetPeriodOptions]?.label;
}

function getResetPeriodDuration(data: string): string {
    const duration = data.replace(/\D/g, '');
    return duration === '24' || duration === '' ? '-' : duration;
}

function getResetPeriodStartingDay(data: string): TranslateResult | undefined {
    const mapperType = data.replace(/[0-9]/g, '');
    if (!(mapperType in RESET_PERIOD_TYPES_MAPPER)) {
        return undefined;
    }

    return resetPeriodStartDayLabelMapper[mapperType as RESET_PERIOD_TYPES_MAPPER];
}

export interface ResetPeriod {
    type: string;
    label: TranslateResult | undefined;
    duration: string;
    startDay?: TranslateResult;
    startDayType: string;
}

export function getResetPeriod(data: string): ResetPeriod {
    return {
        type: getResetPeriodType(data).toLowerCase(),
        label: getResetPeriodLabel(data),
        duration: getResetPeriodDuration(data),
        startDay: getResetPeriodStartingDay(data),
        startDayType: getResetPeriodType(data),
    };
}

export default {};
