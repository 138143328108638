import i18n from '@/i18n';
import { epochToDate } from '@/common/formatting';
import { FORM_BUILDER_TYPES } from '@/formBuilderConfig';
import { TEMPLATE_STATE_TO_STRING_MAP } from '@/__new__/services/dno/template/models/templateStates';
import { TEMPLATE_TYPES_MAP } from '@/__new__/services/dno/template/models/templateTypes';
import localeLibrary from '@/common/locale/localeLibrary';

/* eslint-disable camelcase */
export class CommonTemplateEntityTemplate {
    id;

    name;

    externalId;

    description;

    state;

    stateLabel;

    entityType;

    createTime;

    updateTime;

    updateTimeFormatted;

    properties;

    groups;

    isRegularTemplateReferenced;

    referencedEntityIds;

    /**
     * Flag indicating that the template was created *internally* by the DNO and is therefore immutable
     */
    isInternal;

    constructor(templateJson, isInternal = false) {
        this.id = templateJson.id;
        this.name = templateJson.name || '';
        this.externalId = templateJson.external_id || '';
        this.description = templateJson.description || '';
        this.state = templateJson.state;
        this.stateLabel = TEMPLATE_STATE_TO_STRING_MAP.get(templateJson.state);
        this.createTime = templateJson.create_time ? epochToDate(templateJson.create_time) : '';
        this.updateTime = templateJson.update_time;
        this.updateTimeFormatted = localeLibrary.getFormattedDateAndTime(templateJson.update_time);
        this.entityType = templateJson.type;
        this.presetTemplates =
            templateJson?.referenced_templates.filter(template => template.type === TEMPLATE_TYPES_MAP.PRESET) || [];
        this.properties = Object.entries(templateJson.property_definitions).map(([key, value]) =>
            this.fromJsonProperty(key, value),
        );
        this.groups = Object.entries(templateJson.groups)
            .map(([groupId, groupData]) => this.fromJsonGroup(groupId, groupData))
            .sort((a, b) => b.sortWeight - a.sortWeight);
        this.isRegularTemplateReferenced = Boolean(templateJson?.entity_refs_by?.length) || false;
        this.referencedEntityIds = templateJson?.entity_refs_by || [];
        this.referencedTemplate = templateJson?.template_refs_by || [];
        this.isInternal = isInternal;
        // TODO: version when implemented + states mapping
    }

    fromJsonGroup(groupId, groupData) {
        return {
            id: groupId,
            name: groupData.name,
            description: groupData.description,
            sortWeight: groupData.sort_weight,
            parentGroupId: groupData.parent_group_id,
        };
    }

    fromJsonProperty(id, property) {
        const dataType = property.schema.type;
        const propertyFormatted = {
            id,
            name: property.name || id,
            description: property.description,
            placeholder: property?.ui_properties?.placeholder,
            isTextArea: property?.ui_properties?.is_text_area,
            referencedType: property?.ui_properties?.referenced_type,
            dataType: property.schema.type,
            groupId: property.group_id,
            isRequired: property.is_required,
            step: property?.ui_properties?.step,
            sortWeight: property.sort_weight,
            schema: property.schema,
            enumMapping: property.enum_mapping,
        };

        if (Object.hasOwnProperty.call(property, 'default_value')) {
            propertyFormatted.defaultValue = property.default_value;
        }

        if (dataType === FORM_BUILDER_TYPES.INPUT && propertyFormatted?.referencedType) {
            propertyFormatted.dataType = FORM_BUILDER_TYPES.REFERENCE;
        }

        // TODO: remove integer part once BE updates to number
        if (dataType === FORM_BUILDER_TYPES.NUMBER || dataType === 'integer') {
            propertyFormatted.dataType = FORM_BUILDER_TYPES.NUMERIC;
        }

        // if (dataType === FORM_BUILDER_TYPES.INPUT) {
        //     propertyFormatted.dataType = FORM_BUILDER_TYPES.INPUT;
        // }
        if (dataType === FORM_BUILDER_TYPES.INPUT && property?.ui_properties?.is_text_area) {
            propertyFormatted.dataType = FORM_BUILDER_TYPES.TEXTAREA;
        }

        if (dataType === 'char_spec') {
            propertyFormatted.dataType = FORM_BUILDER_TYPES.DROPDOWN;
            propertyFormatted.enum = property.char_spec_id;
        }

        if (dataType === 'array_char_spec') {
            propertyFormatted.dataType = FORM_BUILDER_TYPES.DROPDOWN_MULTIPLE;
            propertyFormatted.enum = property.char_spec_id;
            propertyFormatted.defaultValue = propertyFormatted.defaultValue || [];
        }

        return propertyFormatted;
    }

    static toJsonProperty(property) {
        return {
            name: property.name,
            description: property.description || '',
            placeholder: property.placeholder || '',
            data_type:
                FORM_BUILDER_TYPES.TEXTAREA === property.dataType || FORM_BUILDER_TYPES.REFERENCE === property.dataType
                    ? FORM_BUILDER_TYPES.INPUT
                    : property.dataType,
            sort_weight: property.sortWeight,
            step: property?.step || 1,
            default_value: `${property.defaultValue}`,
            is_required: property.isRequired,
            is_textarea: FORM_BUILDER_TYPES.TEXTAREA === property.dataType,
            group_id: property.groupId,
        };
    }

    static toJsonGroup(group) {
        return {
            id: group.id,
            name: group.name,
            description: group.description,
            sort_weight: group.sortWeight,
            parent_group_id: group.parentGroupId,
        };
    }

    // to parse data for the API call
    static toJson(entityData) {
        return {
            name: entityData.name,
            description: entityData.description,
            template_id: entityData.entityTemplateId,
            state: entityData.state,
            referenced_templates: entityData.refferencedTemplates || [],
            groups: entityData.groups.reduce((acc, group) => {
                acc[group.id] = CommonTemplateEntityTemplate.toJsonGroup(group);
                return acc;
            }, {}),
            properties_definition: entityData.properties.reduce((acc, property) => {
                acc[property.id] = CommonTemplateEntityTemplate.toJsonProperty(property);
                return acc;
            }, {}),
            // TODO: version when implemented + states mapping
        };
    }

    // map from BE JSON to FormBuilder format
    static async formattedDataForFormBuilder(groups, properties) {
        const groupsFormatted = {};

        if (groups && groups.length) {
            groups.forEach(({ id, name, description }) => {
                groupsFormatted[id] = {
                    id,
                    name,
                    description,
                    fields: [],
                };
            });
        }

        if (properties && properties.length) {
            /* eslint-disable no-await-in-loop */
            for (const prop of properties) {
                const field = await CommonTemplateEntityTemplate.formatProperty(prop);
                groupsFormatted[prop.groupId].fields.push(field);
            }
            /* eslint-enable no-await-in-loop */
        }

        // sorting properties based on sort_weight
        return Object.values(groupsFormatted).map(groupFormatted => {
            groupFormatted.fields = groupFormatted.fields.sort((a, b) => a.sortWeight - b.sortWeight);
            return groupFormatted;
        });
    }

    static async formatProperty(prop) {
        const labelKey =
            prop.dataType === FORM_BUILDER_TYPES.DROPDOWN ||
            prop.dataType === FORM_BUILDER_TYPES.DROPDOWN_MULTIPLE ||
            prop.dataType === FORM_BUILDER_TYPES.REFERENCE
                ? 'additionalLabel'
                : 'label';

        const field = {
            id: prop.id,
            key: prop.id,
            name: prop.name || '',
            type: prop.dataType,
            description: prop.description || '',
            placeholder: prop.placeholder || '',
            defaultValue: Object.hasOwnProperty.call(prop, 'defaultValue') ? prop.defaultValue : null,
            isRequired: prop.isRequired,
            groupId: prop.groupId,
            sortWeight: prop.sortWeight,

            componentProps: {
                ...prop.componentProps,
                [labelKey]: prop.name,
                explanationText: `${i18n.t('generic.externalId')}: ${prop.id}
${i18n.t('generic.description')}: ${prop.description || i18n.t('generic.empty')}`,
                placeholder: prop.placeholder,
            },
        };

        if (prop.dataType === FORM_BUILDER_TYPES.DROPDOWN || prop.dataType === FORM_BUILDER_TYPES.DROPDOWN_MULTIPLE) {
            if (prop?.enumMapping) {
                const sortedEnumMapping = Object.fromEntries(
                    (Array.isArray(prop.schema.enum) ? prop.schema.enum : []).map(key => [key, prop.enumMapping[key]]),
                );
                field.componentProps.options = Object.entries(sortedEnumMapping).map(([key, value]) => ({
                    label: `${value} (key: ${key})`,
                    key,
                }));
                field.componentProps.trackBy = 'key';
                field.componentProps.label = 'label';
                field.componentProps.optionId = 'key';
            } else {
                field.componentProps.options = prop?.schema?.enum || [];
            }
            field.componentProps.tooltipOffset = 10;
        }

        if (Object.hasOwnProperty.call(prop, 'isRequired')) {
            field.componentProps.optional = !prop.isRequired;
        }
        if (prop.isRequired) {
            if (prop.dataType === FORM_BUILDER_TYPES.DROPDOWN_MULTIPLE) {
                field.validation = val => val.length;
            } else {
                field.validation = val => val;
            }
        }

        if (prop.step) {
            field.componentProps.step = prop.step;
        }

        return field;
    }
}

export default CommonTemplateEntityTemplate;
