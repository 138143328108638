
import IconButton from '@/components/partials/IconButton.vue';
import AppLabel from '@/components/partials/AppLabel.vue';

// Helpers
import { LABEL_COLOR } from '@/common/labelsHelper';
import { ICON_TYPES } from '@/common/iconHelper';

export default {
    name: 'ServiceStatusTile',
    components: {
        IconButton,
        AppLabel,
    },
    data() {
        return {
            // proxy imports
            LABEL_COLOR,
            ICON_TYPES,
        };
    },
    created() {
        this.fetchTileData();
    },
    methods: {
        fetchTileData() {
            this.$emit('isDataLoadingUpd', true);
            // TODO: remove timeout when we use real data
            setTimeout(() => {
                this.$emit('isDataLoadingUpd', false);
            }, 500);
        },
    },
};
