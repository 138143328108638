<script>
// helpers
import download from 'downloadjs';
import Papa from 'papaparse';

export default {
    name: 'DownloadDetailsMixin',
    data() {
        return {
            requestEntities: false,
        };
    },
    methods: {
        triggerDownloadingCSV() {
            this.requestEntities = !this.requestEntities;
        },
        downloadOffersCSV(entities, filename = this.entityType) {
            download(Papa.unparse(entities), `${filename}.csv`, 'text/plain');
        },
    },
};
</script>
