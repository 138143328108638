
import Vue from 'vue';

// Vuex
import { mapGetters, mapActions } from 'vuex';
import Actions, { Getters } from '@/store/mutation-types';
import { Modules } from '@/store/store';

// Components
import AbstractEditPageWrapper from '@/components/layout/AbstractEditPageWrapper.vue';
import AppHeader from '@/components/layout/AppHeader.vue';
import AppInputV3 from '@/components/partials/inputs/AppInputV3.vue';
import AppTextareaV3 from '@/components/partials/inputs/AppTextareaV3.vue';
import AppMultiselectV3 from '@/components/partials/inputs/AppMultiselectV3.vue';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import { ICON_TYPES } from '@/common/iconHelper';
import MutationDialog from '@/components/partials/MutationDialog.vue';
import ConditionsExpressionEditor from '@/__new__/features/charging/ConditionsExpressionEditor.vue';
import AppLabel from '@/components/partials/AppLabel.vue';
import EditorButtons from '@/components/layout/EditorButtons.vue';
import AppIcon from '@/components/partials/icon/AppIcon.vue';
import EntityStatusIndicator from '@/components/partials/EntityStatusIndicator.vue';
import ChargingTagLabel from '@/__new__/features/charging/ChargingTagLabel.vue';

// Mixins
import entityEditorMixin from '@/common/entityEditorMixin';
import mutationDialogMixin from '@/components/partials/mutations/mutationDialogMixin.vue';
import ChargingCommonActionsMixin from '@/__new__/features/charging/ChargingCommonActionsMixin.vue';

// HTTP
import { getUsageCounteEntityDraft } from '@/__new__/services/dno/charging/http/usageCounters';
import { getUserNameById } from '@/__new__/services/portal/profile/http/profile';

// Validations
import { validationMixin } from 'vuelidate';
import { required, minValue, requiredIf } from 'vuelidate/lib/validators';

// Routers
import RouteNames from '@/router/routeNames';

// Helpers
import * as Sentry from '@sentry/vue';
import {
    resetPeriodOptions,
    RESET_PERIOD_TYPES,
    setResetPeriodDurationLabel,
    resetPeriodStartDayOptions,
    makeResetPeriod,
    TriggerOptionsObject,
} from '@/__new__/services/dno/charging/common/usageCountersEditorHelper';
import {
    CONDITION_TYPES,
    allConditionOptions,
    getTextLabelForConditionType,
} from '@/__new__/services/dno/charging/common/chargingSpecificationHelper';
import { EntityStateMapping } from '@/common/commonEntityStateMapper';
import { parseExpressionToTree } from '@/__new__/services/dno/charging/common/expression';
import { ALERT_TYPES } from '@/common/alerts/Alert';
import { TOOLTIP_POSITION } from '@/common/tooltip';
import { languageMap } from '@/common/locale/language';
import {
    getProperlyFormattedMultilangFieldValue,
    getAffectedEntities,
    formatMutationDialogEntities,
    EDITOR_MODE,
} from '@/common/entities/entityHelper';
import cloneDeep from 'lodash/cloneDeep';
import ENTITY_TYPES from '@/common/entities/entityTypes';
import { UsageCounter, ChargingEntityVersion } from '@/__new__/services/dno/charging/models/ChargingInterfaces';
import { UsageCounterDraft } from '@/__new__/services/dno/charging/models/UsageCounter';
import { LABEL_COLOR } from '@/common/labelsHelper';
import Button from '@/common/button/Button';
import { STATUS_CODES } from '@/common/commonHelper';
import {
    loadVersionHistory,
    CHARGING_ENTITY_TYPES,
    getLowerEntitiesVersions,
} from '@/__new__/services/dno/charging/common/versioningHelper';
import { TranslateResult } from 'vue-i18n';
import { PLURALIZATION } from '@/common/locale/labelSingularOrPlural';

export default Vue.extend({
    name: 'UsageCountersEditor',
    components: {
        AbstractEditPageWrapper,
        AppHeader,
        AppInputV3,
        AppTextareaV3,
        AppMultiselectV3,
        AppButton,
        MutationDialog,
        ConditionsExpressionEditor,
        AppLabel,
        EditorButtons,
        AppIcon,
        EntityStatusIndicator,
        ChargingTagLabel,
    },
    mixins: [validationMixin, entityEditorMixin, mutationDialogMixin, ChargingCommonActionsMixin],
    data() {
        return {
            id: null as string | null,
            name: {} as string | Record<string, string>,
            description: {} as string | Record<string, string>,
            remark: '' as string,
            approveOnCreate: false as boolean,
            resetPeriodType: {
                value: '',
            } as TriggerOptionsObject,
            selectedCondition: null as any,
            resetPeriodDuration: null as string | null,
            resetPeriodDurationLabel: '' as string,
            resetPeriodStartDay: null as string | null,
            expression: [] as any[],
            entityType: ENTITY_TYPES.USAGE_COUNTER as string,
            version: null as number | null,
            data: {} as any,
            conditionIsChoosing: false as boolean,
            selectedLanguage: '' as string,
            ICON_TYPES,
            BUTTON_TYPES,
            allConditionOptions,
            getTextLabelForConditionType,
            CONDITION_TYPES,
            TOOLTIP_POSITION,
            PLURALIZATION,
            entityId: null as string | null,

            readonly: false as boolean,
            updateName: this.$i18n.t('generic.N/A') as string,
            entityDraft: null as any,
            isUnpublished: false as boolean,
            isOnlyDraft: false as boolean,
            updateTime: null as number | null,
            revertConfirmationButton: new Button({
                label: this.$i18n.t('productCatalog.editors.revert'),
                alertType: ALERT_TYPES.warning,
                handler: () => this.initData(true),
            }),
            LABEL_COLOR,
            EDITOR_MODE,
            STATUS_CODES,
            versionsHistory: [] as ChargingEntityVersion[],
            currentVersion: null as string | null,
            state: EntityStateMapping.UNAPPROVED,
            lowerEntitiesVersions: null,
            CHARGING_ENTITY_TYPES,
            getLowerEntitiesVersions,
            RouteNames,
        };
    },
    validations() {
        return {
            name: {
                en: {
                    required,
                },
            },
            resetPeriodType: {
                value: {
                    required,
                },
            },
            resetPeriodDuration: {
                // eslint-disable-next-line func-names
                required: requiredIf(function () {
                    return this.showResetPeriodDuration;
                }),
                minValue: minValue(1),
            },
            resetPeriodStartDay: {
                // eslint-disable-next-line func-names
                required: requiredIf(function () {
                    return this.showResetPeriodStartDay;
                }),
            },
        };
    },
    computed: {
        ...mapGetters('operators', [Getters.languageDefault]),
        ...mapGetters(Modules.charging, [Getters.GET_APPROVED_CONDITION_PARAMETERS]),
        ...mapGetters(Modules.chargingV2, [
            Getters.GET_USAGE_COUNTER_BY_ID,
            Getters.GET_USAGE_COUNTERS,
            Getters.GET_USAGE_COUNTER_BY_VERSION,
        ]),
        usageCounters(): UsageCounter[] {
            return this[Getters.GET_USAGE_COUNTERS];
        },
        usageCountersData(): UsageCounter {
            return this[Getters.GET_USAGE_COUNTER_BY_ID](this.entityId) || {};
        },
        usageCounterByVersion(): UsageCounter | null {
            return this[Getters.GET_USAGE_COUNTER_BY_VERSION];
        },
        isNewElement(): boolean {
            return !this.entityId || this.$route.params.clone;
        },
        resetPeriodOptions(): TriggerOptionsObject[] {
            return Object.values(resetPeriodOptions).map(type => ({
                ...type,
                label: type.label,
            }));
        },
        resetPeriodStartDayOptions(): TriggerOptionsObject[] {
            if (!this.resetPeriodType.value) {
                return [];
            }
            return Object.values(resetPeriodStartDayOptions[this.resetPeriodType.value]).map(
                (type: TriggerOptionsObject) => ({
                    ...type,
                    label: type.label,
                }),
            );
        },
        conditionParameters(): any[] {
            if (!this[Getters.GET_APPROVED_CONDITION_PARAMETERS]) return [];
            const cpData = cloneDeep(this[Getters.GET_APPROVED_CONDITION_PARAMETERS]);
            return cpData
                .sort((entity1: any, entity2: any) => entity1.name.localeCompare(entity2.name))
                .map((condition: any) => ({
                    conditionUUID: condition.id,
                    label: condition.name,
                    type: condition.conditionParameter.type,
                }));
        },
        conditionList(): any[] {
            const conditionOptionsKeys = this.conditionOptions().map((el: any) => el.type);
            return this.conditionParameters.filter((condition: any) => conditionOptionsKeys.includes(condition.type));
        },
        alreadyUsedName(): boolean {
            const dataForUser = this.usageCounters;

            if (this.isEditingExistingEntity) {
                if (this.name !== this.usageCountersData?.name) {
                    return Object.values(dataForUser).some((uc: UsageCounter) => uc.name === this.name);
                }
                return false;
            }
            return Object.values(dataForUser).some((uc: UsageCounter) => uc.name === this.name);
        },
        affectedEntities(): object {
            return getAffectedEntities(this.$attrs.id, this.entityType);
        },
        showResetPeriodDuration(): boolean {
            return (
                this.resetPeriodType?.value === RESET_PERIOD_TYPES.d ||
                this.resetPeriodType?.value === RESET_PERIOD_TYPES.w ||
                this.resetPeriodType?.value === RESET_PERIOD_TYPES.m
            );
        },
        showResetPeriodStartDay(): boolean {
            return (
                this.resetPeriodType?.value === RESET_PERIOD_TYPES.w ||
                this.resetPeriodType?.value === RESET_PERIOD_TYPES.m
            );
        },
        isEditingExistingEntity(): boolean {
            return Boolean(this.entityId && !this.$route.params.clone);
        },
        isDraft() {
            return this.entityId && !this.readonly && this.entityDraft;
        },
        isPublished() {
            return this.entityId && this.readonly;
        },
        allowEditDraftBtn() {
            return this.isPublished && !this.isOnlyDraft;
        },
        isRevertAvailable() {
            return this.isEditing && !this.readonly && !this.isOnlyDraft;
        },
        allowViewPublishedBtn() {
            return this.entityId && !this.readonly && !this.isOnlyDraft;
        },
        pageTitle(): TranslateResult {
            if (this.readonly) {
                return this.$i18n.tc('charging.usageCounters.name', PLURALIZATION.SINGULAR);
            }
            if (this.entityId) {
                return this.$i18n.t('charging.usageCounters.editUsageCounter');
            }

            return this.$i18n.t('charging.usageCounters.addNewUsageCounter');
        },
        showStatusIndicator(): boolean {
            return Boolean(this.entityId && this.state !== STATUS_CODES.NA);
        },
    },
    created() {
        this.$withLoadingSpinner(async () => {
            const { id, readonly, mode, clone, chargingVersion } = this.$route.params;
            this.entityId = id;

            if (this.entityId && readonly) {
                this.readonly = readonly;
            }

            if (this.entityId && mode) {
                this.readonly = mode === EDITOR_MODE.VIEW;
            }

            if (chargingVersion) {
                this.currentVersion = chargingVersion;
            }

            const promises = [this[Actions.REQUEST_USAGE_COUNTERS](), this[Actions.REQUEST_CONDITION_PARAMETERS]()];

            if (!clone) {
                promises.push(this.loadEntityDraft());
            }

            await Promise.all(promises);
            this.selectedLanguage = (this[Getters.languageDefault] || languageMap.en.key).toString();

            if (this.entityId) {
                this.initData(false, !this.currentVersion);
                if (this.readonly) {
                    this.loadVersionHistory(CHARGING_ENTITY_TYPES.USAGE_COUNTER, this.entityId, chargingVersion);
                }
            }
        });
    },
    methods: {
        ...mapActions(Modules.charging, [Actions.REQUEST_CONDITION_PARAMETERS]),
        ...mapActions(Modules.chargingV2, [Actions.REQUEST_USAGE_COUNTERS, Actions.REQUEST_USAGE_COUNTER_BY_VERSION]),
        async initData(forceInitPublished = false, initVersion = true): Promise<void> {
            if (this.entityId) {
                const uc = this.usageCountersData;
                let ucData: any;

                if (initVersion) {
                    this.currentVersion = uc?.chargingVersion || null;
                }

                if (this.entityDraft && !forceInitPublished) {
                    const draftData = new UsageCounterDraft({
                        ...this.entityDraft,
                        ...this.entityDraft.data,
                    });

                    draftData.version = uc.version;
                    ucData = draftData;
                    this.isOnlyDraft = uc?.state === STATUS_CODES.NA;

                    if (uc?.update_time <= ucData?.update_time) {
                        this.isUnpublished = true;
                    }
                } else if (this.currentVersion) {
                    await this.loadHistoryEntity(this.entityId, this.currentVersion);
                    ucData = cloneDeep(this.usageCounterByVersion);
                } else {
                    ucData = cloneDeep(uc);
                }

                const {
                    name,
                    version,
                    description,
                    resetPeriodData,
                    update_time: updateTime,
                    updatePortalId,
                    remark,
                    state,
                    lowerEntitiesVersions,
                } = ucData;

                this.version = version;
                const properlyFormattedName = getProperlyFormattedMultilangFieldValue(name);
                this.updateTime = updateTime;

                if (this.$route.params.clone) {
                    Object.entries(properlyFormattedName).forEach(([localeName, localizedName]) => {
                        properlyFormattedName[localeName] = `${localizedName} (cloned)`;
                    });
                }

                this.name = properlyFormattedName;
                this.description = getProperlyFormattedMultilangFieldValue(description);
                this.remark = remark;
                this.state = state;
                this.lowerEntitiesVersions = lowerEntitiesVersions || null;

                const { type, duration, startDayType } = resetPeriodData;

                this.resetPeriodType = resetPeriodOptions?.[type];
                this.resetPeriodDuration = duration === '-' ? null : duration;

                if (type === RESET_PERIOD_TYPES.w || type === RESET_PERIOD_TYPES.m) {
                    this.resetPeriodStartDay = resetPeriodStartDayOptions?.[type]?.[startDayType];
                }

                this.loadConditionsData(ucData);
                this.getUpdateUserName(updatePortalId);
            }
        },
        loadConditionsData({ conditionExpr }: UsageCounter): void {
            this.expression = conditionExpr || [];
            if (!conditionExpr) {
                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('charging.CSG.editor.conditionsExpressionError'),
                    type: ALERT_TYPES.error,
                });
            }
        },
        onSelectResetPeriod(): void {
            this.resetPeriodDuration = null;
            this.resetPeriodStartDay = null;
            this.$v.resetPeriodDuration.$reset();
            this.$v.resetPeriodStartDay.$reset();
            this.resetPeriodDurationLabel = setResetPeriodDurationLabel(this.resetPeriodType.value);
        },
        conditionOptions(): any[] {
            const options = [
                this.allConditionOptions[CONDITION_TYPES.RATING_GROUP],
                this.allConditionOptions[CONDITION_TYPES.APN],
                this.allConditionOptions[CONDITION_TYPES.BEARER],
                this.allConditionOptions[CONDITION_TYPES.ZONE],
                this.allConditionOptions[CONDITION_TYPES.SPECIAL_NUMBER],
                this.allConditionOptions[CONDITION_TYPES.SCHEDULE],
                this.allConditionOptions[CONDITION_TYPES.TRAFFIC_TYPE],
                this.allConditionOptions[CONDITION_TYPES.TIME_IN_CALL],
                this.allConditionOptions[CONDITION_TYPES.NETWORK_SLICE_IDENTIFIER],
                this.allConditionOptions[CONDITION_TYPES.SERVICE_TYPE],
                this.allConditionOptions[CONDITION_TYPES.SUBSCRIBER_FLAG],
                this.allConditionOptions[CONDITION_TYPES.BUCKET_FLAG],
                this.allConditionOptions[CONDITION_TYPES.SUBSCRIBER_LOCATION],
                this.allConditionOptions[CONDITION_TYPES.OTHER_PARTY_NUMBER],
                this.allConditionOptions[CONDITION_TYPES.VLR_ID],
                this.allConditionOptions[CONDITION_TYPES.SERVICE_ID],
            ];

            return options.sort((option1, option2) => option1.type.localeCompare(option2.type));
        },
        populateFields(): void {
            this.data.reset_period = this.resetPeriodType
                ? makeResetPeriod(this.resetPeriodType, this.resetPeriodDuration, this.resetPeriodStartDay)
                : '';

            const expressionData = {
                condition_expr: {},
            };

            if (this.expression && this.expression.length > 0 && this.expression[0].value) {
                const expressionParsed = parseExpressionToTree(this.expression);
                if (expressionParsed) {
                    expressionData.condition_expr = expressionParsed;
                } else {
                    expressionData.condition_expr = {};
                }
            }

            this.data = {
                ...this.data,
                ...expressionData,
            };
        },
        checkDataBeforeSave(): boolean {
            this.$v.$touch();
            if (this.$v.$invalid) {
                return false;
            }
            if (this.alreadyUsedName) {
                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('charging.usageCounters.nameOfusageCountersMustBeUnique'),
                });
                return false;
            }

            return true;
        },
        async onSave(isPublish = true) {
            if (isPublish && !this.checkDataBeforeSave()) {
                return;
            }

            this.populateFields();
            this.data.name = this.name[this.selectedLanguage];
            this.data.description = this.description;
            this.data.remark = this.remark;

            await this.saveEntityData(this.isOnlyDraft, this.affectedEntities?.usage_counter || [], isPublish);
        },
        getAffectedEntities(): object {
            return formatMutationDialogEntities(this.affectedEntities);
        },
        async loadEntityDraft() {
            if (this.entityId) {
                try {
                    if (!this.readonly) {
                        const result = await getUsageCounteEntityDraft(this.entityId);
                        this.entityDraft = result?.data?.data?.[this.entityId] || null;
                    }
                } catch (e) {
                    this.$alert(this.$i18n.t('alertMessage.failedToLoadNecessaryData'));
                }
            }
        },
        async getUpdateUserName(id: number): Promise<void> {
            try {
                if (id) {
                    const response = await getUserNameById(Number(id));
                    if (response?.data) {
                        this.updateName = response.data;
                    }
                }
            } catch (e) {
                Sentry.captureException(e);
            }
        },
        resetToPublished(): void {
            this.$alert(this.$i18n.t('productCatalog.editors.revertWarning'), {
                type: ALERT_TYPES.warning,
                buttons: [this.revertConfirmationButton],
            });
        },
        reloadEditor(mode: string) {
            const { id, companyId } = this.$route.params;
            // Use push to list page because router don`t want ot reload same page
            this.$router
                .push({
                    name: RouteNames.CHARGING_USAGE_COUNTERS,
                    params: { companyId },
                })
                .then(() => {
                    this.$router.push({
                        name: RouteNames.CHARGING_USAGE_COUNTERS_EDITOR,
                        params: {
                            id,
                            mode,
                            companyId,
                        },
                    });
                });
        },
        async loadVersionHistory(
            entityType: CHARGING_ENTITY_TYPES,
            id: string,
            initChargingVersion?: string,
        ): Promise<void> {
            this.versionsHistory = await loadVersionHistory(entityType, id);

            if (initChargingVersion) {
                this.currentVersion = initChargingVersion;
            } else {
                const [latestVersion] = this.versionsHistory;
                this.currentVersion = latestVersion?.chargingVersion || null;
            }
        },
        selectVersion(entry: ChargingEntityVersion) {
            if (this.currentVersion !== entry?.chargingVersion) {
                this.currentVersion = entry?.chargingVersion;
                this.initData(false, false);
            }
        },
        async loadHistoryEntity(id: string, version: string): Promise<void> {
            if (id) {
                await this.$withProgressBar(
                    async () => {
                        await this[Actions.REQUEST_USAGE_COUNTER_BY_VERSION]({
                            id,
                            version,
                        });
                    },
                    {
                        errorHandler: () => {
                            this.$alert(this.$i18n.t('alertMessage.failedToLoadNecessaryData'), {
                                type: ALERT_TYPES.error,
                            });
                        },
                    },
                );
            }
        },
        openLowerEntitiData(entry: any, routeName: string): void {
            this.$router.push({
                name: routeName,
                params: {
                    companyId: this.$route.params.companyId,
                    id: entry.id,
                    readonly: true,
                    chargingVersion: entry.chargingVersion,
                },
            });
        },
    },
});
