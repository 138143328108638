<template>
    <div
        @mouseenter="$emit('mouseenter')"
        @mouseleave="$emit('mouseleave')"
    >
        <div
            v-if="additionalLabel"
            :class="['label', { lightColor: isLabelColorLight }]"
        >
            {{ additionalLabel }}
        </div>
        <Multiselect
            v-bind="customProps"
            :value="valueFormatted"
            :class="{ error: error, small: isSmall, disabled: disabled }"
            :allowEmpty="allowEmpty"
            @input="(arg1, arg2) => emitEvent('input', arg1, arg2)"
            @select="(arg1, arg2) => emitEvent('select', arg1, arg2)"
            @remove="(arg1, arg2) => emitEvent('remove', arg1, arg2)"
            @search-change="(arg1, arg2) => emitEvent('search-change', arg1, arg2)"
            @tag="(arg1, arg2) => emitEvent('tag', arg1, arg2)"
            @open="emitEvent('open', $event)"
            @close="(arg1, arg2) => emitEvent('close', arg1, arg2)"
        >
            <template
                v-for="key in slotKeys"
                #[key]
            >
                <slot :name="key" />
            </template>

            <template
                v-for="key in scopedSlotKeys"
                #[key]="{ option, tag, search, remove, toggle }"
            >
                <slot
                    :name="key"
                    :option="option"
                    :tag="tag"
                    :search="search"
                    :remove="remove"
                    :toggle="toggle"
                />
            </template>
        </Multiselect>
    </div>
</template>

<script>
import Multiselect from 'vue-multiselect';

export default {
    name: 'AppMultiselect',
    components: {
        Multiselect,
    },
    props: {
        isSmall: {
            type: Boolean,
            default: false,
        },
        additionalLabel: {
            type: String,
            default: '',
        },
        isLabelColorLight: {
            type: Boolean,
            default: false,
        },
        error: {
            type: Boolean,
            default: false,
        },
        options: {
            type: Array,
            required: true,
        },
        optionId: {
            type: String,
            default: null,
        },
        searchable: {
            type: Boolean,
            default: true,
        },
        clearOnSelect: {
            type: Boolean,
            default: true,
        },
        closeOnSelect: {
            type: Boolean,
            default: true,
        },
        internalSearch: {
            type: Boolean,
            default: true,
        },
        showNoResults: {
            type: Boolean,
            default: true,
        },
        showPointer: {
            type: Boolean,
            default: true,
        },
        showLabels: {
            type: Boolean,
            default: false,
        },
        allowEmpty: {
            type: Boolean,
            default: true,
        },
        id: {
            type: [Number, String],
            default: null,
        },
        value: {
            type: [Array, Object, String, Number],
            default: null,
        },
        multiple: {
            type: Boolean,
            default: false,
        },
        trackBy: {
            type: String,
            default: null,
        },
        label: {
            type: String,
            default: null,
        },
        placeholder: {
            type: String,
            default: null,
        },
        resetAfter: {
            type: Boolean,
            default: false,
        },
        customLabel: {
            type: Function,
            default: null,
        },
        taggable: {
            type: Boolean,
            default: false,
        },
        tagPlaceholder: {
            type: String,
            default: null,
        },
        tagPosition: {
            type: String,
            default: null,
        },
        max: {
            type: Number,
            default: null,
        },
        optionsLimit: {
            type: Number,
            default: null,
        },
        groupValues: {
            type: String,
            default: null,
        },
        groupLabel: {
            type: String,
            default: null,
        },
        groupSelect: {
            type: Boolean,
            default: false,
        },
        blockKeys: {
            type: Array,
            default: null,
        },
        preserveSearch: {
            type: Boolean,
            default: false,
        },
        preselectFirst: {
            type: Boolean,
            default: false,
        },
        name: {
            type: String,
            default: null,
        },
        selectLabel: {
            type: String,
            default: null,
        },
        selectGroupLabel: {
            type: String,
            default: null,
        },
        selectedLabel: {
            type: String,
            default: null,
        },
        deselectLabel: {
            type: String,
            default: null,
        },
        deselectGroupLabel: {
            type: String,
            default: null,
        },
        limit: {
            type: Number,
            default: null,
        },
        limitText: {
            type: Function,
            default: null,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        maxHeight: {
            type: Number,
            default: null,
        },
        openDirection: {
            type: String,
            default: null,
        },
        tabindex: {
            type: Number,
            default: null,
        },
        optionHeight: {
            type: Number,
            default: null,
        },
    },
    computed: {
        customProps() {
            const customProps = {};
            for (const key in this.$props) {
                if (this.$props[key] !== undefined && this.$props[key] !== null) {
                    customProps[key] = this.$props[key];
                }
            }
            return customProps;
        },
        slotKeys() {
            return Object.keys(this.$slots);
        },
        scopedSlotKeys() {
            return Object.keys(this.$scopedSlots);
        },
        valueFormatted() {
            return this.optionId && this.value
                ? this.options.filter(option => this.value.includes(option[this.optionId]))
                : this.value;
        },
    },
    methods: {
        emitEvent(emitName, arg1, arg2) {
            let options = arg1;
            if (this.optionId && arg1) {
                if (Array.isArray(arg1)) {
                    options = arg1.map(arg => (typeof arg === 'object' ? arg[this.optionId] : arg));
                } else {
                    options = typeof arg1 === 'object' ? arg1[this.optionId] : arg1;
                }
            }
            this.$emit(emitName, options, arg2);
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/palette';
@import '../../../assets/scss/palette';

.label {
    display: block;
    font-size: 14px;
    font-weight: 600;
    color: $gray60;
    margin-bottom: 4px;

    &.lightColor {
        color: #e8eaeb;
    }
}
</style>
