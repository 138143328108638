
import Vue from 'vue';

// Components
import EntityStatusIndicator from '@/components/partials/EntityStatusIndicator.vue';
import AppField from '@/components/partials/AppField.vue';
import AdditionalSidebarForOrdersNextGen from '@/__new__/features/customerCareSuite/components/AdditionalSidebarForOrdersNextGen.vue';
import AssociatedUMEntityPicker from '@/__new__/features/customerCareSuite/components/AssociatedUMEntityPicker.vue';

// Helpers
import { USER_MANAGER_HIERARCHY } from '@/__new__/features/customerCare/common/customerCareHelper';
import {
    ORDER_NEXTGEN_STATUS_INDICATOR_MAP,
    ORDER_NEXTGEN_ENTITY_STATES_TO_STATUS_NAME_MAP,
} from '@/__new__/features/customerCareSuite/common/orderNextGenStateHelper';

// HTTP
import coreOMHTTP from '@/__new__/services/dno/coreom/http/coreom';
import OrderData from '@/__new__/services/dno/coreom/models/OrderData';

export default Vue.extend({
    name: 'LatestOrderTile',
    components: {
        EntityStatusIndicator,
        AppField,
        AdditionalSidebarForOrdersNextGen,
        AssociatedUMEntityPicker,
    },
    data() {
        return {
            latestOrder: {},
            selectedAssociatedUMEntity: null,
            showDialog: false,
            // proxy
            ORDER_NEXTGEN_ENTITY_STATES_TO_STATUS_NAME_MAP,
            ORDER_NEXTGEN_STATUS_INDICATOR_MAP,
            USER_MANAGER_HIERARCHY,
        };
    },
    computed: {
        orderId() {
            return this.latestOrder?.id;
        },
        orderDate() {
            return this.$localeLibrary.getFormattedDateAndTime(this.latestOrder?.createTimestamp);
        },
        orderStatus() {
            if (this.latestOrder?.state) {
                return this.latestOrder.state;
            }
            return this.$i18n.t('generic.N/A');
        },
    },
    watch: {
        selectedAssociatedUMEntity: {
            handler(newVal) {
                if (newVal) {
                    this.fetchTileData();
                }
            },
            deep: true,
        },
    },
    methods: {
        fetchTileData() {
            this.$withProgressBar(
                async () => {
                    this.$emit('isDataLoadingUpd', true);
                    const response = await coreOMHTTP.getOrders(
                        this.selectedAssociatedUMEntity.id,
                        USER_MANAGER_HIERARCHY.ACCOUNT,
                    );

                    if (response?.data?.orders) {
                        const entities = Object.values(response.data.orders).map(el => {
                            return new OrderData(OrderData.mapOrdersFromBE(el));
                        });

                        [this.latestOrder] = entities.sort((a, b) => a.createTimestamp - b.createTimestamp);
                    }

                    this.$emit('isDataLoadingUpd', false);
                },
                {
                    errorHandler: () => {
                        this.$showErrorAlert({
                            message: this.$i18n.t('alertMessage.somethingWentWrongFetchingNecessaryData'),
                        });
                        this.$emit('isDataLoadingUpd', false);
                    },
                },
            );
        },
    },
});
