import {
    DataflowUISectionResponse,
    DataflowElementResponse,
    SECTION_TYPE,
} from '@/__new__/features/dataflows/common/DataflowInterfaces';
import DataflowElement from '@/__new__/services/dno/dataflows/models/DataflowElement';

export default class DataflowSectionModel {
    id: string;
    required: boolean;
    label: string;
    description?: string;
    type: SECTION_TYPE.SECTION;
    elements: Array<DataflowSectionModel | DataflowElement>;
    error: boolean;
    enabled: boolean;
    isNested: boolean;
    isSection = true;

    constructor(data: DataflowUISectionResponse & { error?: boolean; isNested?: boolean }) {
        this.id = data.id;
        this.required = data.required;
        this.label = data.label;
        this.description = data.description || '';
        this.error = data.error || false;
        this.enabled = DataflowSectionModel.isSectionEnabled(data);
        this.elements = this.mapElements(data.elements) || [];
        this.type = data.type || SECTION_TYPE.SECTION;
        this.isNested = data.isNested || false;
    }

    get isOptional() {
        return !DataflowSectionModel.isSectionEnabled(this);
    }

    static isSectionEnabled(data: DataflowUISectionResponse | DataflowSectionModel): boolean {
        return data.required && !!data?.elements?.some(el => el.required);
    }

    mapElements(elements: DataflowUISectionResponse['elements']) {
        return elements?.map(e =>
            e.type === SECTION_TYPE.SECTION
                ? new DataflowSectionModel({ ...e, isNested: true })
                : new DataflowElement(e as DataflowElementResponse),
        );
    }
}
