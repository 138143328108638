import {
    SECTION_TYPE,
    DataflowNumberInputResponse,
    DataflowTextInputResponse,
    DataflowSelectResponse,
    DataflowElementResponse,
    ElementOption,
    ElementRegex,
} from '@/__new__/features/dataflows/common/DataflowInterfaces';

export default class DataflowElement {
    id: string;
    required: boolean;
    label: string;
    description?: string;
    type: Omit<SECTION_TYPE, SECTION_TYPE.SECTION>;
    value?: DataflowElementResponse['value'] | null;
    error: boolean;
    // Select
    options?: ElementOption[];
    // Text
    regex?: ElementRegex;
    // Number
    step?: number;
    min?: number;
    max?: number;
    isSection = false;

    constructor(data: DataflowElementResponse & { error?: boolean }) {
        this.id = data.id;
        this.required = data.required;
        this.label = data.label;
        this.description = data.description || '';
        this.type = data.type;
        this.value = data.value || null;
        this.error = data.error || false;
        this.options = (data as DataflowSelectResponse).values;
        this.regex = (data as DataflowTextInputResponse).regex;
        this.step = (data as DataflowNumberInputResponse).step;
        this.min = (data as DataflowNumberInputResponse).min;
        this.max = (data as DataflowNumberInputResponse).max;
    }

    get getValue(): string | number | null {
        return this.type === SECTION_TYPE.SELECTION_INPUT
            ? (this.value as ElementOption)?.value || null
            : (this.value as string | number);
    }
}
