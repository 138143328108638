import Vue from 'vue';
import userManagementHTTP from '@/__new__/services/dno/user/http/user-management';
import { USER_MANAGER_HIERARCHY } from '@/__new__/features/customerCare/common/customerCareHelper';
import Session from '@/__new__/services/dno/user/models/Session';
import CredentialChange from '@/__new__/services/dno/user/models/CredentialChange';
import permissionsService, { isUserAllowed } from '@/services/permissions/permissions.service';

export default {
    namespaced: true,
    // Objects in state are list (except roles)
    // key is user id
    // value is info
    state: {
        // All keys are defined in User.ts model
        userInfo: {},
        userInfoApiResponse: {},
        // Array<Object>
        roles: [],
        // Array<Object>
        // All Object keys are defined in Session.js model
        sessions: {},
        sessionsApiResponse: {},
        credsHistory: {},
        credsHistoryApiResponse: {},
        ekycStatus: {},
        consents: [],
        userVerificationStatus: {},
    },
    mutations: {
        setUserInfo: (state, { userInfo, userId }) => {
            Vue.set(state.userInfo, userId, userInfo);
        },
        setUserInfoApiResponse: (state, { response, userId }) => {
            Vue.set(state.userInfoApiResponse, userId, response);
        },
        setSessions: (state, { sessions, userId }) => {
            Vue.set(
                state.sessions,
                userId,
                sessions.map(session => new Session(Session.remapSessionFromBe(session))),
            );
        },
        setSessionsApiResponse: (state, { response, userId }) => {
            Vue.set(state.sessionsApiResponse, userId, response);
        },
        setCredentialsChangeHistory: (state, { credsHistory, userId }) => {
            Vue.set(
                state.credsHistory,
                userId,
                credsHistory.map(
                    credHistRecord =>
                        new CredentialChange(CredentialChange.remapCredentialChangeFromBe(credHistRecord)),
                ),
            );
        },
        setCredentialsChangeHistoryApiResponse: (state, { response, userId }) => {
            Vue.set(state.credsHistoryApiResponse, userId, response);
        },
        setUserRoles: (state, roles) => {
            Vue.set(state, 'roles', roles);
        },
        setUserVerificationStatus: (state, { userId, status }) => {
            Vue.set(state.userVerificationStatus, userId, status);
        },
        setEkycStatus: (state, { userId, status }) => {
            Vue.set(state.ekycStatus, userId, status);
        },
        setConsents: (state, consents) => {
            Vue.set(state, 'consents', consents);
        },
    },
    actions: {
        async getUserInfo({ commit }, userId) {
            let userInfoResponse;
            try {
                userInfoResponse = await userManagementHTTP.getUser(userId, USER_MANAGER_HIERARCHY.USER);
                commit('setUserInfo', {
                    userInfo: userInfoResponse.data.user_info,
                    userId,
                });
                commit('setUserInfoApiResponse', {
                    response: userInfoResponse,
                    userId,
                });
            } catch (error) {
                commit('setUserInfoApiResponse', {
                    response: error.response,
                    userId,
                });
                throw error;
            }
        },
        async getRoles({ commit }) {
            const userInfoResponse = await userManagementHTTP.getRoles();

            commit('setUserRoles', userInfoResponse.data.roles);
        },
        async getSessions({ commit }, userId) {
            let sessionsResponse;
            try {
                sessionsResponse = await userManagementHTTP.getSessionsInfo(userId, USER_MANAGER_HIERARCHY.USER);
                commit('setSessionsApiResponse', { response: sessionsResponse, userId });
            } catch (error) {
                commit('setSessionsApiResponse', { response: error.response, userId });
                throw error;
            }

            commit('setSessions', {
                sessions: sessionsResponse.data.sessions,
                userId,
            });
        },
        async getCredentialsChangeHistory({ commit }, userId) {
            let credsHistoryResponse;
            try {
                credsHistoryResponse = await userManagementHTTP.getCredentialsChangeHistory(
                    userId,
                    USER_MANAGER_HIERARCHY.USER,
                );
                commit('setCredentialsChangeHistoryApiResponse', {
                    response: credsHistoryResponse,
                    userId,
                });
            } catch (error) {
                commit('setCredentialsChangeHistoryApiResponse', {
                    response: error.response,
                    userId,
                });
                throw error;
            }

            commit('setCredentialsChangeHistory', {
                credsHistory: credsHistoryResponse.data.creds_history,
                userId,
            });
        },
        async endSession({ dispatch }, { userId, sessionId }) {
            await userManagementHTTP.logout(userId, USER_MANAGER_HIERARCHY.USER, sessionId);
            await dispatch('getSessions', userId);
        },
        async getEkycStatus({ commit }, userId) {
            const ekycStatusResponse = await userManagementHTTP.getEkycStatus(userId, USER_MANAGER_HIERARCHY.USER);
            commit('setEkycStatus', {
                userId,
                status: ekycStatusResponse?.data?.status,
            });
        },
        async getConsents({ commit }, userId) {
            const consentsResponse = await userManagementHTTP.getConsents(userId, USER_MANAGER_HIERARCHY.USER);
            commit('setConsents', consentsResponse?.data?.consents);
        },
    },
    getters: {
        getRoles: state => state.roles,
        getUserInfoById: state => userId => state.userInfo[userId] || {},
        getUserInfoApiResponseById: state => userId => state.userInfoApiResponse[userId],
        getSessionsById: state => userId => state.sessions[userId] || [],
        getSessionsApiResponseById: state => userId => state.sessionsApiResponse[userId],
        getCredsHistoryById: state => userId => state.credsHistory[userId] || [],
        getCredsHistoryApiResponseById: state => userId => state.credsHistoryApiResponse[userId],
        getEkycStatusById: state => userId => state.ekycStatus[userId] || '',
        getAllConsents: state => state.consents || [],
        getUserVerificationStatusById: state => userId => {
            const allowEditWithoutVerification =
                permissionsService.userManagementEditWithoutVerificationEnabled() &&
                isUserAllowed('UMUserEditWithoutVerification');

            return allowEditWithoutVerification || state.userVerificationStatus[userId];
        },
    },
};
