export const documentTypes: { [type: string]: string } = {
    PDF: '.pdf',
    DOC: '.doc',
    DOCX: '.docx',
    JPG: '.jpg',
    PNG: '.png',
};

export const mimeTypes: { [type: string]: string } = {
    PDF: 'application/pdf',
    DOC: 'application/msword',
    DOCX: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    JPG: 'image/jpeg',
    PNG: 'image/png',
};

export const MAP_DOCUMENT_TYPES_TO_MIME_TYPE = new Map([
    [documentTypes.PDF, mimeTypes.PDF],
    [documentTypes.DOC, mimeTypes.DOC],
    [documentTypes.DOCX, mimeTypes.DOCX],
    [documentTypes.JPG, mimeTypes.JPG],
    [documentTypes.PNG, mimeTypes.PNG],
]);

export function getMimeType(filename: string) {
    const type = Object.values(documentTypes).find(t => filename.toLowerCase().endsWith(t));

    if (!type) {
        throw new Error(`Unable to determine MIME type for file ${filename}`);
    }

    return MAP_DOCUMENT_TYPES_TO_MIME_TYPE.get(type);
}

export function getDocumentType(mime: string) {
    const [type] = Object.entries(mimeTypes).find(([, mimeType]) => mimeType === mime) || [];

    if (!type) {
        throw new Error(`No document type for MIME type ${mime}`);
    }

    return documentTypes[type];
}
