import UserManagerBaseModel from '@/__new__/services/dno/user/models/UserManagerBaseModel';
import i18n from '@/i18n';
import localeLibrary from '@/common/locale/localeLibrary';
import { type TranslateResult } from 'vue-i18n';

export enum CREDENTIALS_TYPE {
    EMAIL_SECRET = 1,
    MSISDN = 2,
    GOOGLE = 3,
    FACEBOOK = 4,
    INSTAGRAM = 5,
    APPLE = 6,
    EXTERNAL = 7,
    AZURE = 8,
    SECURE_ID = 9,
    USERNAME_SECRET = 10,
    EMAIL_OTP = 11,
    MSISDN_OTP = 12,
    SECURE_ID_INTERNAL = 13,
    AUTH0 = 14,
}

const CREDENTIALS_TYPE_LABEL_MAP = new Map([
    [CREDENTIALS_TYPE.EMAIL_SECRET, i18n.t('customerCare.userInformation.credChangeHistory.emailSecret')],
    [CREDENTIALS_TYPE.MSISDN, 'MSISDN'],
    [CREDENTIALS_TYPE.GOOGLE, 'Google'],
    [CREDENTIALS_TYPE.FACEBOOK, 'Facebook'],
    [CREDENTIALS_TYPE.INSTAGRAM, 'Instagram'],
    [CREDENTIALS_TYPE.APPLE, 'Apple'],
    [CREDENTIALS_TYPE.EXTERNAL, i18n.t('customerCare.userInformation.credChangeHistory.external')],
    [CREDENTIALS_TYPE.AZURE, i18n.t('customerCare.userInformation.credChangeHistory.azure')],
    [CREDENTIALS_TYPE.SECURE_ID, i18n.t('customerCare.userInformation.credChangeHistory.secureID')],
    [CREDENTIALS_TYPE.USERNAME_SECRET, i18n.t('customerCare.userInformation.credChangeHistory.usernameSecret')],
    [CREDENTIALS_TYPE.EMAIL_OTP, i18n.t('customerCare.userInformation.credChangeHistory.emailOtp')],
    [CREDENTIALS_TYPE.MSISDN_OTP, i18n.t('customerCare.userInformation.credChangeHistory.msisdnOtp')],
    [CREDENTIALS_TYPE.SECURE_ID_INTERNAL, i18n.t('customerCare.userInformation.credChangeHistory.secureIdInternal')],
    [CREDENTIALS_TYPE.AUTH0, i18n.t('customerCare.userInformation.credChangeHistory.auth0')],
]);

export enum CREDENTIAL_STATUS {
    REQUESTED = 'requested',
    SUCCESSFUL = 'successful',
}

const CREDENTIAL_STATUS_I18N_MAP = new Map([
    [CREDENTIAL_STATUS.REQUESTED, i18n.t('generic.stateMap.requested')],
    [CREDENTIAL_STATUS.SUCCESSFUL, i18n.t('generic.stateMap.successful')],
]);

const enum CREDENTIALS_ACTIVITY_TYPE {
    RESET_SECRET = 'reset_secret',
    UPDATE_EMAIL = 'update_email',
    ADD_CREDENTIALS = 'add_credentials',
    REMOVE_CREDENTIALS = 'remove_credentials',
    SOCIAL_LOGIN = 'social_login',
    UPDATE_SECRET = 'update_secret',
    UPDATE_LOGIN_IDENTIFIER = 'update_login_identifier',
}

const CREDENTIALS_ACTIVITY_TYPE_LABEL_MAP = new Map([
    [CREDENTIALS_ACTIVITY_TYPE.RESET_SECRET, i18n.t('customerCare.userInformation.credChangeHistory.resetSecret')],
    [CREDENTIALS_ACTIVITY_TYPE.UPDATE_EMAIL, i18n.t('customerCare.userInformation.credChangeHistory.updateEmail')],
    [
        CREDENTIALS_ACTIVITY_TYPE.ADD_CREDENTIALS,
        i18n.t('customerCare.userInformation.credChangeHistory.addCredentials'),
    ],
    [
        CREDENTIALS_ACTIVITY_TYPE.REMOVE_CREDENTIALS,
        i18n.t('customerCare.userInformation.credChangeHistory.removeCredentials'),
    ],
    [CREDENTIALS_ACTIVITY_TYPE.SOCIAL_LOGIN, i18n.t('customerCare.userInformation.credChangeHistory.socialLogin')],
    [
        CREDENTIALS_ACTIVITY_TYPE.UPDATE_LOGIN_IDENTIFIER,
        i18n.t('customerCare.userInformation.credChangeHistory.updateLoginIdentifier'),
    ],
    [CREDENTIALS_ACTIVITY_TYPE.UPDATE_SECRET, i18n.t('customerCare.userInformation.credChangeHistory.updateSecret')],
]);

type CredentialChangeResponse = {
    change_time: number;
    activity: CREDENTIALS_ACTIVITY_TYPE;
    activity_data?: Record<'status', CREDENTIAL_STATUS>;
    login_method: CREDENTIALS_TYPE;
};

export default class CredentialChange extends UserManagerBaseModel {
    // backend fields
    changeTime: ReturnType<typeof localeLibrary.normalizeTimestamp>;
    activity: CREDENTIALS_ACTIVITY_TYPE;
    loginMethod: CREDENTIALS_TYPE;
    status: CREDENTIAL_STATUS;
    // formatted fields for display
    changeTimeLabel: ReturnType<typeof localeLibrary.getFormattedDateAndTimeWithSecondsPrecision>;
    activityLabel: TranslateResult;
    loginMethodLabel: TranslateResult;
    statusLabel: TranslateResult;

    constructor(credChange: CredentialChange) {
        // Call constructor of UserManagerBaseModel class for creating new object with unique invalidKeys Map
        super();
        this.changeTime = credChange.changeTime;
        this.activity = credChange.activity;
        this.loginMethod = credChange.loginMethod;
        this.status = credChange.status;
        // labels
        this.changeTimeLabel = localeLibrary.getFormattedDateAndTimeWithSecondsPrecision(
            credChange.changeTime as number,
        );
        this.activityLabel = this.getMappedLabel(CREDENTIALS_ACTIVITY_TYPE_LABEL_MAP, credChange.activity);
        this.loginMethodLabel = this.getMappedLabel(CREDENTIALS_TYPE_LABEL_MAP, credChange.loginMethod);
        this.statusLabel = this.getMappedLabel(CREDENTIAL_STATUS_I18N_MAP, credChange.status);
    }

    getMappedLabel(map: Map<any, TranslateResult>, value: any): TranslateResult {
        return map.get(value) || i18n.t('customerCare.userInformation.credChangeHistory.unknown');
    }

    static remapCredentialChangeFromBe(backendModel: CredentialChangeResponse) {
        return {
            changeTime: localeLibrary.normalizeTimestamp(backendModel?.change_time),
            activity: backendModel?.activity || null,
            status: backendModel?.activity_data?.status || null,
            loginMethod: backendModel?.login_method || null,
        };
    }
}
