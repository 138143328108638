
// Components
import AbstractTableTile from '@/__new__/features/customerCareSuite/components/AbstractTableTile.vue';
import AppAditionalSidebar from '@/components/partials/AppAditionalSidebar.vue';
import AppMultiselectV3 from '@/components/partials/inputs/AppMultiselectV3.vue';
import DateTimePicker from '@/components/partials/inputs/DateTimePicker.vue';

// Helpers
import { USER_MANAGER_HIERARCHY } from '@/__new__/features/customerCare/common/customerCareHelper';
import {
    DevedgeBYONSeatUsage,
    DevedgeCDRsAudit,
    DevedgeUsageDetail,
} from '@/__new__/services/dno/ossdevedge/models/DevedgeUsageDetail';
import tableColumnType, { TableColumn } from '@/common/filterTable';

// HTTP
import ossdevedgeHTTP, { SERVICE_TYPES } from '@/__new__/services/dno/ossdevedge/http/ossdevedge';

const MAX_ALLOWED_DAYS = 30;
const MAX_DATE_RANGE_IN_EPOCH_MILLISECONDS = MAX_ALLOWED_DAYS * 24 * 60 * 60 * 1000 - 1000;

function convertSizeToReadable(size: number): string {
    if (!size) return 'Empty';
    const i = size === 0 ? 0 : Math.floor(Math.log(size) / Math.log(1024));
    return `${Number((size / 1024 ** i).toFixed(2))} ${['B', 'kB', 'MB', 'GB', 'TB'][i]}`;
}

function isExtendedSession(isExtendedSessionVal: boolean | null): string {
    return isExtendedSessionVal ? '✓' : 'x';
}

// eslint-disable-next-line no-shadow
enum DEVEDGE_USAGE_TYPE_IN_DROPDOWN {
    QoD = 'QoD usage details',
    BYON = 'BYON Seat usage',
    CDRS_AUDIT = 'CDRs Audit',
}

type UsageTypeOption = {
    type: DEVEDGE_USAGE_TYPE_IN_DROPDOWN;
    name: string;
};

export default {
    name: 'DevedgeUsageTile',
    components: {
        AbstractTableTile,
        DateTimePicker,
        AppMultiselectV3,
        AppAditionalSidebar,
    },
    data() {
        return {
            DEVEDGE_USAGE_TYPE_IN_DROPDOWN,
            entities: [],
            selectedEntity: null,
            apiResponse: {},
            datePickerOptions: {
                fromDateValue: new Date(new Date().getTime() - MAX_DATE_RANGE_IN_EPOCH_MILLISECONDS),
                endDateValue: new Date(),
                type: 'date',
            },
            showAdditionalSidebar: false,
            selectedUsageType: null,
            serviceUsageTypes: [
                {
                    type: DEVEDGE_USAGE_TYPE_IN_DROPDOWN.QoD,
                    name: this.$i18n.t('customerCareSuite.devedgeUsageTile.qodServices'),
                },
                {
                    type: DEVEDGE_USAGE_TYPE_IN_DROPDOWN.BYON,
                    name: this.$i18n.t('customerCareSuite.devedgeUsageTile.byonServices'),
                },
                {
                    type: DEVEDGE_USAGE_TYPE_IN_DROPDOWN.CDRS_AUDIT,
                    name: this.$i18n.t('customerCareSuite.devedgeUsageTile.cdrsAudit'),
                },
            ],
            qodUsageTableColumns: [
                {
                    name: this.$i18n.t('customerCareSuite.devedgeUsageTile.appId'),
                    key: 'dnoAppId',
                    field: 'dnoAppId',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('generic.channel'),
                    key: 'channels',
                    field: 'channels',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('customerCareSuite.devedgeUsageTile.customerName'),
                    key: 'customerName',
                    field: 'customerName',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('generic.msisdn'),
                    key: 'msisdn',
                    field: 'msisdn',
                    filterType: tableColumnType.TEXT_LIMITED_OPTIONS,
                },
                {
                    name: this.$i18n.t('customerCareSuite.devedgeUsageTile.sessionStartTime'),
                    key: 'startTime',
                    mapper: entity => this.$localeLibrary.getFormattedDateAndTimeWithSecondsPrecision(entity.startTime),
                    sortBy: entity => entity.startTime,
                    field: 'startTime',
                    filterType: tableColumnType.DATE,
                },
                {
                    name: this.$i18n.t('customerCareSuite.devedgeUsageTile.sessionEndTime'),
                    key: 'endTime',
                    mapper: entity => this.$localeLibrary.getFormattedDateAndTimeWithSecondsPrecision(entity.endTime),
                    sortBy: entity => entity.endTime,
                    field: 'endTime',
                    filterType: tableColumnType.DATE,
                },
                {
                    name: this.$i18n.t('customerCareSuite.devedgeUsageTile.dataSessionLength'),
                    key: 'dataSessionLength',
                    field: 'dataSessionLength',
                    filterType: tableColumnType.TEXT_LIMITED_OPTIONS,
                },
                {
                    name: this.$i18n.t('customerCareSuite.devedgeUsageTile.dataSessionLengthTotal'),
                    key: 'dataSessionLengthTotal',
                    field: 'dataSessionLengthTotal',
                    filterType: tableColumnType.TEXT_LIMITED_OPTIONS,
                },
                {
                    name: this.$i18n.t('customerCareSuite.devedgeUsageTile.consumedDataSize'),
                    key: 'consumedDataSize',
                    field: 'consumedDataSize',
                    filterType: tableColumnType.TEXT_LIMITED_OPTIONS,
                    mapper: entity => convertSizeToReadable(entity.consumedDataSize),
                },
                {
                    name: this.$i18n.t('customerCareSuite.devedgeUsageTile.consumedDataSizeTotal'),
                    key: 'consumedDataSizeTotal',
                    field: 'consumedDataSizeTotal',
                    filterType: tableColumnType.TEXT_LIMITED_OPTIONS,
                    mapper: entity => convertSizeToReadable(entity.consumedDataSizeTotal),
                },
                {
                    name: this.$i18n.t('customerCareSuite.devedgeUsageTile.isExtendedSession'),
                    key: 'isExtendedSession',
                    field: 'isExtendedSession',
                    filterType: tableColumnType.TEXT_LIMITED_OPTIONS,
                    mapper: entity => isExtendedSession(entity.isExtendedSession),
                },
            ] as TableColumn[],
            byonUsageTableColumns: [
                {
                    name: 'service',
                    key: 'serviceId',
                    field: 'serviceId',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: 'purchased seat',
                    key: 'purchasedSeatCount',
                    field: 'purchasedSeatCount',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: 'used seat',
                    key: 'seatUsageCount',
                    field: 'seatUsageCount',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: 'date',
                    key: 'reportTime',
                    field: 'reportTime',
                    filterType: tableColumnType.DATE,
                    mapper: entity => this.$localeLibrary.getFormattedDate(entity.reportTime),
                },
            ] as TableColumn[],
            cdrsAuditTableColumns: [
                {
                    name: this.$i18n.t('generic.filename'),
                    key: 'fileName',
                    field: 'fileName',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('customerCareSuite.devedgeUsageTile.fileTimestamp'),
                    key: 'fileTimestamp',
                    field: 'fileTimestamp',
                    filterType: tableColumnType.DATE,
                    mapper: entity =>
                        this.$localeLibrary.getFormattedDateAndTimeWithSecondsPrecision(entity.fileTimestamp),
                },
                {
                    name: this.$i18n.t('generic.version'),
                    key: 'fileVersion',
                    field: 'fileVersion',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('customerCareSuite.devedgeUsageTile.rowNumber'),
                    key: 'fileRowNumber',
                    field: 'fileRowNumber',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
            ] as TableColumn[],
        };
    },
    computed: {
        setDateTimePickerValue(): Date[] {
            return [this.datePickerOptions.fromDateValue, this.datePickerOptions.endDateValue];
        },
    },
    created() {
        this.$emit('isDataLoadingUpd', false);
    },
    methods: {
        isUsageTypeSelected(type: DEVEDGE_USAGE_TYPE_IN_DROPDOWN): boolean {
            return this.selectedUsageType?.type === type;
        },
        setEntities(response: any, targetType: DEVEDGE_USAGE_TYPE_IN_DROPDOWN) {
            this.apiResponse = response;
            switch (targetType) {
                case DEVEDGE_USAGE_TYPE_IN_DROPDOWN.QoD:
                    if (response?.data?.usage_details) {
                        this.entities = response.data.usage_details.map(el => {
                            return DevedgeUsageDetail.mapDevedgeUsageDetailFromBE(el);
                        });
                    }
                    break;
                case DEVEDGE_USAGE_TYPE_IN_DROPDOWN.BYON:
                    if (response?.data?.byon_seat_usage) {
                        this.entities = response.data.byon_seat_usage.map(el => {
                            return DevedgeBYONSeatUsage.mapDevedgeBYONSeatUsageFromBE(el);
                        });
                    }
                    break;
                case DEVEDGE_USAGE_TYPE_IN_DROPDOWN.CDRS_AUDIT:
                    if (response?.data?.cdrs) {
                        this.entities = response.data.cdrs.map(el => {
                            return DevedgeCDRsAudit.mapDevedgeCDRsAuditFromBE(el);
                        });
                    }
                    break;
                default:
                    break;
            }
        },
        fetchTileData(): void {
            if (!this.selectedUsageType) return;

            switch (this.selectedUsageType?.type) {
                case DEVEDGE_USAGE_TYPE_IN_DROPDOWN.QoD:
                    this.fetchUsageData(
                        this.datePickerOptions.fromDateValue,
                        this.datePickerOptions.endDateValue,
                        this.fetchQoDUsageDetails,
                    );
                    break;
                case DEVEDGE_USAGE_TYPE_IN_DROPDOWN.BYON:
                    this.fetchUsageData(
                        this.datePickerOptions.fromDateValue,
                        this.datePickerOptions.endDateValue,
                        this.fetchBYONSeatUsage,
                    );
                    break;
                case DEVEDGE_USAGE_TYPE_IN_DROPDOWN.CDRS_AUDIT:
                    this.fetchUsageData(
                        this.datePickerOptions.fromDateValue,
                        this.datePickerOptions.endDateValue,
                        this.fetchCDRsAudit,
                    );
                    break;
                default:
                    break;
            }
        },
        async fetchQoDUsageDetails(fromTime: number, toTime: number): Promise<void> {
            const response = await ossdevedgeHTTP.getUsageDetails(
                this.$route.params.id,
                USER_MANAGER_HIERARCHY.ACCOUNT,
                SERVICE_TYPES.QOD,
                fromTime,
                toTime,
            );
            this.setEntities(response, DEVEDGE_USAGE_TYPE_IN_DROPDOWN.QoD);
        },
        async fetchBYONSeatUsage(fromTime: number, toTime: number): Promise<void> {
            const response = await ossdevedgeHTTP.getBYONSeatUsage(
                this.$route.params.id,
                USER_MANAGER_HIERARCHY.ACCOUNT,
                fromTime,
                toTime,
            );
            this.setEntities(response, DEVEDGE_USAGE_TYPE_IN_DROPDOWN.BYON);
        },
        async fetchCDRsAudit(fromTime: number, toTime: number): Promise<void> {
            const response = await ossdevedgeHTTP.getCDRsAudit(
                this.$route.params.id,
                USER_MANAGER_HIERARCHY.ACCOUNT,
                fromTime,
                toTime,
            );
            this.setEntities(response, DEVEDGE_USAGE_TYPE_IN_DROPDOWN.CDRS_AUDIT);
        },
        async fetchUsageData(
            from: Date | null,
            to: Date | null,
            fetchServiceUsageFn: (fromTime: number, toTime: number) => void,
        ): Promise<void> {
            const defaultFrom = new Date().getTime() - MAX_DATE_RANGE_IN_EPOCH_MILLISECONDS;
            const fromTime = from ? from.getTime() : defaultFrom;
            const toTime = to ? to.getTime() : new Date().getTime();

            await this.$withProgressBar(
                async () => {
                    this.$emit('isDataLoadingUpd', true);
                    await fetchServiceUsageFn(fromTime, toTime);
                    this.$emit('isDataLoadingUpd', false);
                },
                {
                    errorHandler: (e: any) => {
                        this.apiResponse = e.response;
                        this.$alert(this.$i18n.t('customerCare.dataTransferFetchError'));
                        this.$emit('isDataLoadingUpd', false);
                    },
                },
            );
        },
        rangeDateSelected(startDate: Date, endDate: Date) {
            if (endDate.getTime() - startDate.getTime() > MAX_DATE_RANGE_IN_EPOCH_MILLISECONDS) {
                this.$alert(
                    this.$i18n.t('customerCareSuite.devedgeUsageTile.dateRangeError', {
                        maxAllowedDays: MAX_ALLOWED_DAYS,
                    }),
                );
                return;
            }

            endDate.setHours(23, 59, 59, 999);

            this.datePickerOptions.fromDateValue = startDate;
            this.datePickerOptions.endDateValue = endDate;

            switch (this.selectedUsageType?.type) {
                case DEVEDGE_USAGE_TYPE_IN_DROPDOWN.QoD:
                    this.fetchUsageData(startDate, endDate, this.fetchQoDUsageDetails);
                    break;
                case DEVEDGE_USAGE_TYPE_IN_DROPDOWN.BYON:
                    this.fetchUsageData(startDate, endDate, this.fetchBYONSeatUsage);
                    break;
                case DEVEDGE_USAGE_TYPE_IN_DROPDOWN.CDRS_AUDIT:
                    this.fetchUsageData(startDate, endDate, this.fetchCDRsAudit);
                    break;
                default:
                    break;
            }
        },
        checkIsDateDisabled(date: Date) {
            return date > new Date();
        },
        onUsageTypeChange(selectedUsageType: UsageTypeOption) {
            this.entities = [];

            const fetchTileDataByDateRange = (fetchFunction: any) => {
                this.fetchUsageData(
                    this.datePickerOptions.fromDateValue || null,
                    this.datePickerOptions.endDateValue || null,
                    fetchFunction,
                );
            };

            switch (selectedUsageType.type) {
                case DEVEDGE_USAGE_TYPE_IN_DROPDOWN.QoD:
                    fetchTileDataByDateRange(this.fetchQoDUsageDetails);
                    break;
                case DEVEDGE_USAGE_TYPE_IN_DROPDOWN.BYON:
                    fetchTileDataByDateRange(this.fetchBYONSeatUsage);
                    break;
                case DEVEDGE_USAGE_TYPE_IN_DROPDOWN.CDRS_AUDIT:
                    fetchTileDataByDateRange(this.fetchCDRsAudit);
                    break;
                default:
                    break;
            }
        },
        selectCDRAudit(entity: any) {
            this.selectedEntity = entity;
            this.showAdditionalSidebar = true;
        },
    },
};
