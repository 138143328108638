<template>
    <AppAditionalSidebar
        :visible="isVisible"
        @input="$emit('closeSidebar')"
    >
        <template #header>
            <h3 class="lf-table-title text-uppercase d-flex align-items-center h-100">
                {{ $i18n.t('customerCare.promotions.promotionDetails') }}
            </h3>
        </template>

        <template #content>
            <dl>
                <dt class="heading-xs">{{ $i18n.t('customerCare.promotions.promotionId') }}</dt>
                <dd class="body-sm">{{ selectedBenefit.promotionId || $i18n.t('generic.empty') }}</dd>

                <dt class="heading-xs">{{ $i18n.t('benefits.promoShortDescription') }}</dt>
                <dd class="body-sm">{{ selectedBenefit.promoShortDescription || $i18n.t('generic.empty') }}</dd>

                <dt class="heading-xs">{{ $i18n.t('benefits.benefitShortDescription') }}</dt>
                <dd class="body-sm">{{ selectedBenefit.benefitShortDescription || $i18n.t('generic.empty') }}</dd>
            </dl>
        </template>
    </AppAditionalSidebar>
</template>

<script>
// COMPONENTS
import AppAditionalSidebar from '@/components/partials/AppAditionalSidebar.vue';

export default {
    name: 'BenefitTableSidebar',
    components: {
        AppAditionalSidebar,
    },
    props: {
        selectedBenefit: {
            type: Object,
            default: () => ({}),
        },
        isVisible: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/consistency';

.lf-table-title {
    color: rgba($blue-400, 0.5);
}

dl {
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: baseline;
    gap: 0.875rem $spacing-m;
    margin-top: $spacing-xl;
}
</style>
