<template>
    <AbstractEditPageWrapper>
        <template #header>
            <AppHeader :pageTitle="pageTitle" />
        </template>

        <template #content>
            <div class="editor-section">
                <div class="section">
                    <div class="d-flex align-items-center mb-3">
                        <div class="lf-subtitle mb-0">
                            {{ $i18n.t('generic.general') }}
                            <p
                                v-show="inEditMode"
                                class="lf-secondary-text mb-0"
                            >
                                {{ $i18n.t('generic.id') }}: {{ $route.params.id }}
                            </p>
                            <p
                                v-show="inEditMode"
                                class="lf-secondary-text mb-0"
                            >
                                {{ $i18n.t('generic.updateTime') }}:
                                {{ $localeLibrary.getFormattedDateAndTime(updateTime) }}
                            </p>
                        </div>
                    </div>
                    <!-- hardcoded to english name for now -->
                    <AppInputV3
                        v-model="service.data.name"
                        :placeholder="`${$i18n.t('generic.name')}`"
                        :label="$i18n.t('generic.name')"
                        :invalid="$v.service.data.name.$error"
                        :disabled="readonly"
                        type="text"
                        class="editor-input-largest mb-3"
                    />
                    <AppTextareaV3
                        v-model="service.data.description"
                        placeholder=" "
                        :label="$i18n.t('generic.description')"
                        :disabled="readonly"
                        data-test-id="description"
                        class="editor-input-largest mb-3"
                    />
                    <AppMultiselectV3
                        v-model="selectedType"
                        :options="typeOptions"
                        :additionalLabel="`${$i18n.t('consent.type')}`"
                        :placeholder="$i18n.t('consent.type')"
                        :showLabels="false"
                        :small="true"
                        :allowEmpty="false"
                        :disabled="readonly"
                        :error="$v.selectedType.$error"
                        class="editor-input-largest mb-3"
                        label="description"
                        trackBy="key"
                    />
                </div>
            </div>
            <div
                v-if="selectedType && selectedType.key === 'api'"
                id="api_detail"
                class="editor-section"
            >
                <div class="section">
                    <AppMultiselectV3
                        v-model="selectedApiType"
                        :options="apiTypeOptions"
                        :additionalLabel="`${$i18n.t('consent.serviceLocale.apiType')}`"
                        :placeholder="$i18n.t('consent.serviceLocale.apiType')"
                        :showLabels="false"
                        :small="true"
                        :allowEmpty="false"
                        :disabled="readonly"
                        class="editor-input-largest mb-3"
                        label="description"
                        trackBy="key"
                    />
                    <AppInputV3
                        v-model="service.data.apiVersion"
                        placeholder="API Version"
                        label="API Version"
                        :disabled="readonly"
                        type="text"
                        class="editor-input-largest mb-3"
                    />
                    <AppInputV3
                        v-model="service.data.apiPath"
                        placeholder="API Path"
                        label="API Path"
                        :disabled="readonly"
                        type="text"
                        class="editor-input-largest mb-3"
                    />
                </div>
            </div>
            <div
                v-if="selectedType && selectedType.key === 'api'"
                class="editor-section"
            >
                <div class="d-flex align-items-center">
                    <div
                        v-t="'consent.scope'"
                        class="editor-section-heading pb-0"
                    />
                    <div class="label d-flex align-items-center">
                        <AppTooltip
                            :offset="20"
                            class="ml-2"
                        >
                            <template #label>
                                <div class="info-icon" />
                            </template>
                            <template #content>
                                <div class="tooltip-info">{{ $i18n.t('consent.serviceLocale.scopeTips') }}</div>
                            </template>
                        </AppTooltip>
                    </div>
                </div>
                <div class="position-relative">
                    <div class="row optional-key-value-max-width">
                        <span
                            v-t="'partials.appInput.optional'"
                            class="d-flex justify-content-end col-11 pr-2 mb-2 optional-label"
                        />
                    </div>
                    <KeyValueInputs
                        v-model="service.data.scope"
                        :addButtonLabel="$i18n.t('consent.addScope')"
                        :firstInputPlaceholder="$i18n.t('consent.scope')"
                        :secondInputPlaceholder="$i18n.t('generic.description')"
                        :initInputValues="initScopeData"
                        :disabled="readonly"
                        :showInputErrors="false"
                    />
                </div>
            </div>
            <div class="editor-section">
                <div class="d-flex align-items-center">
                    <div
                        v-t="'consent.purpose'"
                        class="editor-section-heading pb-0"
                    />
                    <div class="label d-flex align-items-center">
                        <AppTooltip
                            :offset="25"
                            class="ml-2"
                        >
                            <template #label>
                                <div class="info-icon" />
                            </template>
                            <template #content>
                                <div class="tooltip-info">{{ $i18n.t('consent.serviceLocale.purposeTips') }}</div>
                            </template>
                        </AppTooltip>
                    </div>
                </div>
                <div class="position-relative">
                    <div class="row optional-key-value-max-width">
                        <span
                            v-t="'partials.appInput.optional'"
                            class="d-flex justify-content-end col-11 pr-2 mb-2 optional-label"
                        />
                    </div>
                    <KeyValueInputs
                        v-model="service.data.validPurpose"
                        :addButtonLabel="$i18n.t('consent.addPurpose')"
                        :firstInputPlaceholder="$i18n.t('consent.purpose')"
                        :secondInputPlaceholder="$i18n.t('generic.description')"
                        :initInputValues="initPurposeData"
                        :disabled="readonly"
                        :showInputErrors="false"
                    />
                </div>
            </div>
            <div class="editor-section">
                <div class="d-flex align-items-center">
                    <div
                        v-t="'consent.legalBasis'"
                        class="editor-section-heading pb-0"
                    />
                    <div class="label d-flex align-items-center">
                        <AppTooltip
                            :offset="30"
                            class="ml-2"
                        >
                            <template #label>
                                <div class="info-icon" />
                            </template>
                            <template #content>
                                <div class="tooltip-info">{{ $i18n.t('consent.serviceLocale.legalBasisTips') }}</div>
                            </template>
                        </AppTooltip>
                    </div>
                </div>
                <div class="position-relative">
                    <div class="row optional-key-value-max-width">
                        <span
                            v-t="'partials.appInput.optional'"
                            class="d-flex justify-content-end col-11 pr-2 mb-2 optional-label"
                        />
                    </div>
                    <KeyValueInputs
                        v-model="service.data.legalBasis"
                        :addButtonLabel="$i18n.t('consent.serviceLocale.addLegalBasis')"
                        :firstInputPlaceholder="$i18n.t('consent.legalBasis')"
                        :secondInputPlaceholder="$i18n.t('generic.description')"
                        :initInputValues="initLegalBasisData"
                        :disabled="readonly"
                        :showInputErrors="false"
                    />
                </div>
            </div>
            <div class="editor-section">
                <div class="d-flex align-items-center">
                    <div
                        v-t="'consent.personalDataCategories'"
                        class="editor-section-heading pb-0"
                    />
                    <div class="label d-flex align-items-center">
                        <AppTooltip
                            :offset="50"
                            class="ml-2"
                        >
                            <template #label>
                                <div class="info-icon" />
                            </template>
                            <template #content>
                                <div class="tooltip-info">{{ $i18n.t('consent.serviceLocale.pdTips') }}</div>
                            </template>
                        </AppTooltip>
                    </div>
                </div>
                <div class="position-relative">
                    <div class="row optional-key-value-max-width">
                        <span
                            v-t="'partials.appInput.optional'"
                            class="d-flex justify-content-end col-11 pr-2 mb-2 optional-label"
                        />
                    </div>
                    <KeyValueInputs
                        v-model="service.data.personalDataCategories"
                        :addButtonLabel="$i18n.t('consent.addPersonalDataCategory')"
                        :firstInputPlaceholder="$i18n.t('consent.personalDataCategory')"
                        :secondInputPlaceholder="$i18n.t('generic.description')"
                        :initInputValues="initPersonalDataCategoriesData"
                        :disabled="readonly"
                        :showInputErrors="false"
                    />
                </div>
            </div>
            {{ service.data.type }}
        </template>

        <template #controls>
            <EditorButtons
                :showSave="!readonly"
                :disableSave="readonly"
                @cancel="onCancel"
                @save="onSave()"
            />
        </template>
    </AbstractEditPageWrapper>
</template>

<script>
// Generic Vue Components
import { ICON_TYPES } from '@/common/iconHelper';
import { LABEL_COLOR } from '@/common/labelsHelper';
import AbstractEditPageWrapper from '@/components/layout/AbstractEditPageWrapper.vue';
import EditorButtons from '@/components/layout/EditorButtons.vue';
import { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import AppInputV3 from '@/components/partials/inputs/AppInputV3.vue';
import AppMultiselectV3 from '@/components/partials/inputs/AppMultiselectV3.vue';
import AppTextareaV3 from '@/components/partials/inputs/AppTextareaV3.vue';
import KeyValueInputs from '@/components/partials/inputs/KeyValueInputs.vue';
import AppTooltip from '@/components/partials/AppTooltip.vue';

// Helpers
import { entityTypeToEntityLabel } from '@/common/entities/entityHelper';
import ENTITY_TYPES from '@/common/entities/entityTypes';
import entityEditorMixin from '@/common/entityEditorMixin';
import RouteNames from '@/router/routeNames';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';

// Vuex
import {
    getConsentServiceById,
    patchConsentService,
    postConsentService,
} from '@/__new__/services/dno/privacyConsent/http/consent';
import AppHeader from '@/components/layout/AppHeader.vue';
import { Getters, State } from '@/store/mutation-types';

export default {
    name: 'ConsentServiceEditor',

    components: {
        AbstractEditPageWrapper,
        AppInputV3,
        AppTextareaV3,
        AppHeader,
        KeyValueInputs,
        EditorButtons,
        AppMultiselectV3,
        AppTooltip,
    },

    mixins: [entityEditorMixin, validationMixin],

    data() {
        return {
            entityType: ENTITY_TYPES.CONSENT_SERVICE,
            ICON_TYPES,
            BUTTON_TYPES,
            LABEL_COLOR,
            selectedLanguage: '',
            initScopeData: [],
            initPurposeData: [],
            initLegalBasisData: [],
            initPersonalDataCategoriesData: [],
            oldServiceVal: null,
            service: {
                data: {
                    '@type': 'Service',
                    name: null,
                    description: null,
                    scope: null,
                    validPurpose: null,
                    legalBasis: null,
                    personalDataCategories: null,
                    apiType: null,
                    apiVersion: null,
                    apiPath: null,
                },
            },
            apiType: 'api',
            selectedApiType: {
                key: 'internal',
                description: 'Internal',
            },
            selectedType: {
                key: 'api',
                description: 'API',
            },
            types: [
                {
                    key: 'api',
                    description: 'API',
                },
                {
                    key: 'general',
                    description: 'General',
                },
            ],
            apiTypes: [
                {
                    key: 'internal',
                    description: 'Internal',
                },
                {
                    key: 'partner',
                    description: 'Partner',
                },
            ],
            updateTime: null,
            readonly: false,
            saveButtonClicked: false,
        };
    },

    validations() {
        return {
            service: {
                data: {
                    name: {
                        required,
                    },
                },
            },
            selectedType: {
                required,
            },
        };
    },

    computed: {
        languages() {
            return this.$store.state.operators[State.Languages];
        },
        languageDefault() {
            return this.$store.getters[`operators/${Getters.languageDefault}`];
        },
        typeOptions() {
            return this.types;
        },
        apiTypeOptions() {
            return this.apiTypes;
        },
        inEditMode() {
            return Boolean(this.$route.params.id);
        },
        pageTitle() {
            const label = entityTypeToEntityLabel[ENTITY_TYPES.CONSENT_SERVICE];
            if (this.$route.params.readonly) {
                return label;
            }
            if (this.inEditMode) {
                return `${this.$i18n.t('generic.edit')} ${label}`;
            }
            return `${this.$i18n.t('generic.addNew')} ${label}`;
        },
    },

    created() {
        this.$withLoadingSpinner(
            async () => {
                if (this.$route.params.readonly) {
                    this.readonly = this.$route.params.readonly;
                }
                this.selectedLanguage = this[Getters.languageDefault];
                if (this.inEditMode) {
                    const { id } = this.$route.params;
                    const service = await getConsentServiceById(id);
                    this.service.data.name = service?.data?.name;
                    this.service.data.description = service?.data?.description;
                    if (service.data?.type) {
                        this.selectedType = {
                            key: service.data.type,
                            description: this.types.find(type => type.key === service.data.type).description,
                        };
                    }
                    if (service.data?.type === this.apiType) {
                        this.selectedApiType = {
                            key: service.data.apiType,
                            description: this.apiTypes.find(type => type.key === service.data.apiType).description,
                        };
                        this.service.data.apiVersion = service?.data?.apiVersion;
                        this.service.data.apiPath = service?.data?.apiPath;
                    }
                    this.initScopeData = service?.data?.scope?.map(scope => ({
                        key: scope.key,
                        value: scope.description,
                        isValid: true,
                    }));
                    this.initPurposeData = service?.data?.validPurpose?.map(purpose => ({
                        key: purpose.key,
                        value: purpose.description,
                        isValid: true,
                    }));
                    this.initLegalBasisData =
                        service?.data?.legalBasis?.map(basis => ({
                            key: basis.key,
                            value: basis.description,
                            isValid: true,
                        })) || [];
                    this.initPersonalDataCategoriesData =
                        service?.data?.personalDataCategories?.map(category => ({
                            key: category.key,
                            value: category.description,
                            isValid: true,
                        })) || [];
                    this.updateTime = service?.data?.lastUpdate;
                }
            },
            {
                errorHandler: () => {
                    this.$showErrorAlert({
                        message: this.$i18n.t('alertMessage.somethingWentWrongFetchingNecessaryData'),
                    });
                    this.$router.push({ name: RouteNames.CONSENT_SERVICES });
                },
            },
        );
    },

    methods: {
        onCancel() {
            this.$router.go(-1);
        },
        onSave() {
            if (this.saveButtonClicked) {
                return;
            }
            this.saveButtonClicked = true;
            this.$v.$touch();
            if (this.$v.$invalid) {
                this.$eventBus.$emit('showAlert', {
                    message: this.$i18n.t('alertMessage.pleaseFixValidation'),
                });
            } else {
                this.$withLoadingSpinner(
                    async () => {
                        let servicePayload = {
                            '@type': 'Service',
                            type: this.selectedType?.key,
                            name: this.service?.data?.name,
                            description: this.service?.data?.description,
                            validPurpose: this.service?.data?.validPurpose?.data.map(purpose => ({
                                key: purpose.key,
                                description: purpose.value,
                            })),
                            legalBasis: this.service?.data?.legalBasis?.data.map(basis => ({
                                key: basis.key,
                                description: basis.value,
                            })),
                            personalDataCategories: this.service?.data?.personalDataCategories?.data.map(category => ({
                                key: category.key,
                                description: category.value,
                            })),
                        };
                        if (this.selectedType?.key === 'api') {
                            servicePayload = {
                                ...servicePayload,
                                apiType: this.selectedApiType?.key,
                                apiVersion: this.service.data.apiVersion,
                                apiPath: this.service.data.apiPath,
                                scope: this.service?.data?.scope?.data.map(scope => ({
                                    key: scope.key,
                                    description: scope.value,
                                })),
                            };
                        } else {
                            servicePayload.apiType = null;
                            servicePayload.apiVersion = null;
                            servicePayload.apiPath = null;
                            servicePayload.scope = [];
                        }
                        if (this.inEditMode) {
                            await patchConsentService(this.$route.params.id, servicePayload);
                            this.$showSuccessAlert({
                                message: this.$i18n.t('consent.serviceLocale.updatedSuccessfully'),
                            });
                        } else {
                            await postConsentService(servicePayload);
                            this.$showSuccessAlert({
                                message: this.$i18n.t('consent.serviceLocale.createdSuccessfully'),
                            });
                        }
                        setTimeout(
                            () =>
                                this.$router.push({
                                    name: RouteNames.CONSENT_SERVICES,
                                    params: { companyId: this.$route.params.companyId },
                                }),
                            1000,
                        );
                    },
                    {
                        errorHandler: () => {
                            this.$showErrorAlert({
                                message: this.$i18n.t('alertMessage.somethingWentWrong'),
                            });
                            this.$router.push({ name: RouteNames.CONSENT_SERVICES });
                        },
                    },
                );
            }
            this.saveButtonClicked = false;
        },
    },
};
</script>
