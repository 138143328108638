
import Vue from 'vue';

// Components
import AppAditionalSidebar from '@/components/partials/AppAditionalSidebar.vue';
import AbstractTableTile from '@/__new__/features/customerCareSuite/components/AbstractTableTile.vue';
import EntityStatusIndicator from '@/components/partials/EntityStatusIndicator.vue';

// Helpers
import tableColumnType from '@/common/filterTable';
import { USER_MANAGER_HIERARCHY } from '@/__new__/features/customerCare/common/customerCareHelper';
import {
    OUTAGE_STATUS_TO_COLOR_MAP,
    OUTAGE_STATUS_TO_LABEL,
    getOutageTypeLabel,
    groupOutagesByOutageId,
} from '@/__new__/features/customerCare/common/outageHelper';
import OutageInfo from '@/__new__/services/dno/osstmofiber/models/OutageInfo';

// HTTP
import osstmofiberHTTP from '@/__new__/services/dno/osstmofiber/http/osstmofiber';

export default Vue.extend({
    name: 'OutageHistoryTile',
    components: {
        AbstractTableTile,
        AppAditionalSidebar,
        EntityStatusIndicator,
    },
    props: {
        userManagerHierarchy: {
            type: Number as USER_MANAGER_HIERARCHY,
            required: true,
        },
    },
    data() {
        return {
            outageHistory: [],
            apiResponse: {},

            selectedEntity: new OutageInfo(),

            isSidebarVisible: false,

            // proxy
            OUTAGE_STATUS_TO_COLOR_MAP,
            OUTAGE_STATUS_TO_LABEL,

            // table
            columns: [
                {
                    name: this.$i18n.t('customerCare.userInformation.outageId'),
                    key: 'id',
                    field: 'id',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('customerCare.userInformation.outageType'),
                    key: 'outageTypeFormatted',
                    field: 'outageTypeFormatted',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('generic.status'),
                    key: 'status',
                    field: 'status',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('generic.startTime'),
                    key: 'startTime',
                    field: 'startTime',
                    filterType: tableColumnType.DATE,
                },
            ],
            nestedColumns: [
                {
                    name: this.$i18n.t('generic.endTime'),
                    key: 'rowEndTime',
                    field: 'rowEndTime',
                    filterType: tableColumnType.DATE,
                },
                {
                    name: this.$i18n.t('customerCare.userInformation.outageReason'),
                    key: 'rowReason',
                    field: 'rowReason',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('customerCare.userInformation.estimatedResolution'),
                    key: 'rowResolution',
                    field: 'rowResolution',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
            ],
        };
    },
    computed: {
        columnsData() {
            return [...this.columns, ...this.nestedColumns];
        },
    },
    mounted() {
        this.fetchTileData();
    },
    methods: {
        fetchTileData() {
            this.$emit('isDataLoadingUpd', true);
            this.$withProgressBar(
                async () => {
                    this.outageHistoryApiResponse = await osstmofiberHTTP.getOutageHistory(
                        this.$route?.params?.id,
                        USER_MANAGER_HIERARCHY.ACCOUNT,
                    );

                    const { outage_history: outageHistory } = this.outageHistoryApiResponse?.data || {};

                    const outagesById = groupOutagesByOutageId(outageHistory);
                    this.outageHistory = outagesById?.map(outage => ({
                        ...outage,
                        outageTypeFormatted: this.$i18n.t(getOutageTypeLabel(outage.outageType)),
                    }));

                    this.$emit('isDataLoadingUpd', false);
                },
                {
                    errorHandler: (e: any) => {
                        this.outageHistoryApiResponse = e.response;
                        this.$alert(this.$i18n.t('alertMessage.userManagement.somethingWentWrongFetchingOutageData'));
                        this.$emit('isDataLoadingUpd', false);
                    },
                },
            );
        },
        closeSidebar(): void {
            this.selectedEntity = new OutageInfo();
        },
        openSidebar(entity: OutageInfo): void {
            this.isSidebarVisible = true;
            this.selectedEntity = entity;
        },
    },
});
