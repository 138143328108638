import strapiAxios from '@/modules/userGuide/http/index';

export function getHomePage() {
    return strapiAxios({
        method: 'GET',
        url: '/home-page',
        data: {},
    });
}

export function getTags(id = null) {
    let url = 'tags';
    if (id) {
        url = `${url}/${id}`;
    }
    return strapiAxios({
        method: 'GET',
        url: `${url}?populate=*`,
    });
}

export function getArticles(id = null) {
    let url = 'articles';
    if (id) {
        url = `${url}/${id}`;
    }
    return strapiAxios({
        method: 'GET',
        url: `${url}?populate=*`,
    });
}

export default {
    getHomePage,
    getTags,
    getArticles,
};
