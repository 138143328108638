<template>
    <AbstractListPageWrapper
        :pageTitle="$i18n.t('operateAPIs.channelPartners')"
        :entitiesCount="entitiesCount"
        :isOverviewEnabled="isOverviewEnabled"
        @searchQueryChanged="onSearchQueryChanged"
    >
        <template #button>
            <ResponseModalButton
                :response="channelPartnersData"
                :title="$i18n.t('operateAPIs.channelPartners')"
            />
        </template>

        <template #filterTable>
            <FilterTable
                :columns="tableColumnsData"
                @filterAdded="onFilterAdd"
            />
        </template>

        <template #allFilters>
            <TableFiltersTags
                :filterTableMixin="filterTableMixin"
                class="my-3 ml-2"
                @removeFilter="index => removeFilter(index, filtersLocalStorageKey)"
                @removeAllFilters="removeAllFilters(filtersLocalStorageKey)"
            />
        </template>

        <template #table>
            <AppTable
                :entities="channelPartnersData"
                :innerSearchQuery="searchQueryForTable"
                :isSearchEnabled="true"
                :columnsData="tableColumnsData"
                :selectedEntityId="selectedEntityId"
                tableKey="channel-partners"
                @selectEntity="selectEntity"
            />
        </template>

        <template #overview>
            <EntityOverview
                :entityType="ENTITY_TYPES.CHANNEL_PARTNER"
                :entity="selectedEntity"
                @closeOverview="isOverviewEnabled = false"
            >
                <template #section-1-header>
                    <OverviewHeaderV2
                        v-if="selectedEntity"
                        :entityName="selectedEntity.name"
                        :entityId="selectedEntity.id"
                    />
                </template>
                <template #section-1-content>
                    <div key="1">
                        <h2 class="lf-table-title mb-2">
                            {{ $i18n.t('customerCareSuite.characteristics') }}
                        </h2>
                        <template v-if="selectedEntityHasCharacteristics">
                            <dl>
                                <template v-for="(characteristic, index) in characteristics">
                                    <dt :key="`${index}-term`">
                                        {{ characteristic.label }}
                                    </dt>
                                    <dd :key="`${index}-data`">
                                        {{ characteristic.value }}
                                    </dd>
                                </template>
                            </dl>
                        </template>
                        <p
                            v-else
                            class="body-sm"
                        >
                            {{ $i18n.t('generic.empty') }}
                        </p>
                    </div>
                </template>
            </EntityOverview>
        </template>
    </AbstractListPageWrapper>
</template>

<script>
// Components
import AbstractListPageWrapper from '@/components/layout/AbstractListPageWrapper.vue';
import AppTable from '@/components/partials/AppTable.vue';
import FilterTableMixin from '@/components/partials/FilterTableMixin.vue';
import ListPageMixin from '@/__new__/features/pc/ListPageMixin.vue';
import FilterTable from '@/components/partials/FilterTable.vue';
import TableFiltersTags from '@/components/filters/TableFiltersTags.vue';
import ResponseModalButton from '@/components/partials/ResponseModalButton.vue';
import EntityOverview from '@/components/partials/entityOverview/EntityOverview.vue';
import OverviewHeaderV2 from '@/components/partials/entityOverview/OverviewHeaderV2.vue';

// Helpers
import tableColumnType from '@/common/filterTable';

// Http
import { getPartyRoles } from '@/__new__/services/dno/partyRolesPermissionsManagement/http/partyRolesPermissionsManagement';
import ENTITY_TYPES from '@/common/entities/entityTypes';

export default {
    name: 'ChannelPartnersListPage',

    components: {
        AppTable,
        AbstractListPageWrapper,
        ResponseModalButton,
        TableFiltersTags,
        FilterTable,
        EntityOverview,
        OverviewHeaderV2,
    },

    mixins: [FilterTableMixin, ListPageMixin],

    props: {
        writePermission: {
            type: Boolean,
            required: false,
            default: false,
        },
    },

    data() {
        return {
            searchQueryForTable: '',
            isOverviewEnabled: false,
            selectedEntity: null,
            partyRoleOptions: [],
            selectedEntityId: null,
            channelPartnersData: [],
            filtersLocalStorageKey: 'operate-apis-channel-partners',

            ENTITY_TYPES,
        };
    },

    computed: {
        formattedFilteredEntities() {
            return this.filteredEntitiesMixin(this.channelPartnersData);
        },
        entitiesCount() {
            return this.channelPartnersData?.length || 0;
        },
        tableColumnsData() {
            return [
                {
                    name: this.$i18n.t('generic.id'),
                    key: 'id',
                    field: 'id',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('generic.name'),
                    key: 'name',
                    field: 'name',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
            ];
        },
        selectedEntityHasCharacteristics() {
            return Array.isArray(this.selectedEntity?.characteristics) && this.selectedEntity?.characteristics?.length;
        },
        characteristics() {
            const formatterCharacteristics = [];
            const { characteristics = [] } = this.selectedEntity;

            characteristics.forEach(characteristic => {
                formatterCharacteristics.push(
                    {
                        label: this.$t('generic.id'),
                        value: characteristic.id,
                    },
                    {
                        label: this.$t('generic.name'),
                        value: characteristic.name,
                    },
                    {
                        label: this.$t('operateAPIs.valueType'),
                        value: characteristic.valueType,
                    },
                    {
                        label: this.$t('operateAPIs.value'),
                        value: characteristic.value,
                    },
                );
            });

            return formatterCharacteristics;
        },
    },

    created() {
        this.$withLoadingSpinner(
            async () => {
                const response = await getPartyRoles('ChannelPartner');

                this.channelPartnersData =
                    response?.data.map(channelPartner => ({
                        id: channelPartner?.id || '',
                        name: channelPartner?.name || '',
                        characteristics: channelPartner?.characteristic || [],
                    })) || [];
                this.setAppliedFiltersFromLocalStorage(this.filtersLocalStorageKey);
            },
            {
                errorHandler: () => {
                    this.$alert(this.$i18n.t('alertMessage.somethingWentWrongFetchingNecessaryData'));
                },
            },
        );
    },

    methods: {
        onSearchQueryChanged(searchQuery) {
            this.searchQueryForTable = searchQuery;
        },
        selectEntity(entity) {
            this.selectedEntityId = entity;
            this.selectedEntity = this.channelPartnersData.find(pr => pr.id === entity);
            this.isOverviewEnabled = true;
        },
        onFilterAdd(filter) {
            this.onFilterAdded(filter);
            this.saveFiltersToLocalStorage(filter, this.filtersLocalStorageKey);
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/consistency';

dl {
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: baseline;
    gap: 0.875rem $spacing-m;
    margin-top: $spacing-xl;
}
</style>
