
import Vue from 'vue';

// Components
import SubscriberStatusIndicator from '@/__new__/features/customerCare/subscriber/SubscriberStatusIndicator.vue';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import GrantPermissionModal from '@/__new__/features/customerCareSuite/components/GrantPermissionModal.vue';
import IconButton from '@/components/partials/IconButton.vue';

// Helpers
import RouteNames from '@/router/routeNames';
import { ICON_TYPES } from '@/common/iconHelper';
import isEmpty from 'lodash/isEmpty';
import { AssociatedPermissionItem } from '@/__new__/features/customerCareSuite/common/associatedUMEntitiesHelper';
import { getOperatorConfigValue, isViewEnabled } from '@/services/permissions/permissions.service';
import { copyToClipboard } from '@/common/utils';

// Vuex
import { mapGetters } from 'vuex';
import { Modules } from '@/store/store';
import { Getters } from '@/store/mutation-types';

// HTTP
import authorizationHTTP from '@/__new__/services/dno/authorization/http/authorization';
import { USER_MANAGER_HIERARCHY } from '@/__new__/features/customerCare/common/customerCareHelper';

export default Vue.extend({
    name: 'AssociatedEntitiesTile',
    components: {
        SubscriberStatusIndicator,
        AppButton,
        GrantPermissionModal,
        IconButton,
    },
    props: {
        userManagerHierarchy: {
            type: Number as USER_MANAGER_HIERARCHY,
            required: true,
        },
    },
    data() {
        return {
            userPermissionsList: [] as Array<AssociatedPermissionItem>,
            isModalVisible: false,

            // proxy
            ICON_TYPES,
            BUTTON_TYPES,
            RouteNames,
            isViewEnabled,
            copyToClipboard,
        };
    },
    computed: {
        ...mapGetters(Modules.customerCareSuite, [
            Getters.GET_UM_ENTITY_HIERARCHY_BY_TYPE_AND_ID,
            Getters.GET_ASSOCIATED_UM_ENTITY_BY_TYPE_AND_ID,
        ]),
        id() {
            return this.$route.params.id || '';
        },
        isOnOrganizationPage() {
            return this.userManagerHierarchy === USER_MANAGER_HIERARCHY.ORGANIZATION;
        },
        organization() {
            if (this.isOnOrganizationPage) {
                return this[Getters.GET_ASSOCIATED_UM_ENTITY_BY_TYPE_AND_ID]({
                    targetType: USER_MANAGER_HIERARCHY.ORGANIZATION,
                    targetId: this.id,
                });
            }
            return undefined;
        },
        hierarchy() {
            const userId =
                this.isOnOrganizationPage && this.organization ? this.organization.userOwnerId : this.$route.params.id;
            return this[Getters.GET_UM_ENTITY_HIERARCHY_BY_TYPE_AND_ID]({
                targetId: userId,
                targetType: USER_MANAGER_HIERARCHY.USER,
            });
        },
        showHierarchy() {
            return !isEmpty(this.hierarchy);
        },
    },
    created() {
        this.fetchTileData();
    },
    methods: {
        fetchTileData() {
            this.$withProgressBar(
                async () => {
                    const response = await authorizationHTTP.getPermissions({
                        caller_id: this.id,
                    });
                    this.userPermissionsList = response?.data || [];
                    this.$emit('isDataLoadingUpd', false);
                },
                {
                    errorHandler: () => {
                        this.$emit('dataFetchingError');
                        this.$emit('isDataLoadingUpd', false);
                    },
                },
            );
        },
        getPermissionValue(umId: string): string {
            return (
                this.userPermissionsList.find(permission => permission.target_id === umId)?.role ||
                this.$i18n.t('generic.N/A')
            );
        },
        onToggleModal(isModalVisible: boolean) {
            this.isModalVisible = isModalVisible;
        },
        isActive(id: string) {
            return id === this.$route.params.id;
        },
        getAccountLabel(account) {
            switch (getOperatorConfigValue('associatedEntitiesTileAccountLabelKey')) {
                case 'name':
                    return account.name;
                default:
                    return account.id;
            }
        },
    },
});
