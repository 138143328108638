import Vue from 'vue';
import { Module, ActionContext } from 'vuex';
import Actions, { Mutations, State, Getters } from '@/store/mutation-types';
import * as Sentry from '@sentry/vue';
import { TARGET_TYPE } from '@/__new__/services/dno/user/models/targetTuple';
import {
    getTargetIdDataByType,
    TargetDataType,
} from '@/__new__/features/customerCareSuite/common/endUserAuthorizationHelper';

// Http
import { getMembersNotificationIdentifiers } from '@/__new__/services/dno/endUserAuthorization/http/endUserAuthorization';

export const LOCAL_STORAGE_KEY = 'endUserAuthorizationState';

export type UserAuthorizationState = {
    token1fa?: string;
    successful2faObj: Record<string, boolean>;
    authUserIdForAuthStateMapping: string;
};

const getLsData = (): UserAuthorizationState => {
    return JSON.parse(sessionStorage.getItem(LOCAL_STORAGE_KEY) || '{}');
};

const updateLsData = (
    key: keyof UserAuthorizationState,
    value: string | number | boolean | Record<string, boolean>,
): void => {
    const lsData: UserAuthorizationState = getLsData();
    const newLsData = {
        ...lsData,
        [key]: value,
    };
    sessionStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(newLsData));
};

export interface UserAuthorizationStoreState {
    [State.AUTH_TOKEN_1FA]?: string;
    [State.AUTH_TARGET_EMAILS]: string[];
    [State.AUTH_TARGET_MSISDNS]: string[];
    [State.AUTH_2FA_SUCCESSFUL]: Record<string, boolean>;
    [State.AUTH_USER_ID_FOR_AUTH_STATE_MAPPING]: string;
}

const store: Module<UserAuthorizationStoreState, unknown> = {
    namespaced: true,
    state: {
        [State.AUTH_TOKEN_1FA]: '',
        [State.AUTH_TARGET_EMAILS]: [],
        [State.AUTH_TARGET_MSISDNS]: [],
        [State.AUTH_2FA_SUCCESSFUL]: {},
        [State.AUTH_USER_ID_FOR_AUTH_STATE_MAPPING]: '',
    },
    mutations: {
        [Mutations.SET_AUTH_TARGET_EMAILS]: (state: UserAuthorizationStoreState, emails: string[]) => {
            state[State.AUTH_TARGET_EMAILS] = emails;
        },
        [Mutations.SET_AUTH_TARGET_MSISDNS]: (state: UserAuthorizationStoreState, msisdns: string[]) => {
            state[State.AUTH_TARGET_MSISDNS] = msisdns;
        },
        [Mutations.SET_AUTH_TOKEN_1FA]: (state: UserAuthorizationStoreState, token1fa: string) => {
            state[State.AUTH_TOKEN_1FA] = token1fa;
            updateLsData('token1fa', token1fa);
        },
        [Mutations.UPDATE_AUTH_2FA_SUCCESSFUL_STATUS]: (
            state: UserAuthorizationStoreState,
            { targetId, authState = false }: { targetId: string; authState: boolean },
        ) => {
            if (targetId) {
                Vue.set(state[State.AUTH_2FA_SUCCESSFUL], targetId, authState);
                updateLsData('successful2faObj', state[State.AUTH_2FA_SUCCESSFUL]);
            }
        },
        [Mutations.SET_AUTH_USER_ID_FOR_AUTH_STATE_MAPPING]: (state: UserAuthorizationStoreState, userId: string) => {
            state[State.AUTH_USER_ID_FOR_AUTH_STATE_MAPPING] = userId;
            updateLsData('authUserIdForAuthStateMapping', userId);
        },
    },
    actions: {
        async [Actions.REQUEST_MEMBERS_NOTIFICATION_IDENTIFIERS](
            context: ActionContext<UserAuthorizationStoreState, unknown>,
            data: { targetId: string; targetType: TARGET_TYPE; adminsOnly: boolean },
        ): Promise<void> {
            try {
                const res = await getMembersNotificationIdentifiers(data.targetId, data.targetType, data.adminsOnly);

                const accountMembersInfo = res?.data?.account_members_info || [];
                const emails: string[] = getTargetIdDataByType(accountMembersInfo, TargetDataType.email);
                const msisdns: string[] = getTargetIdDataByType(accountMembersInfo, TargetDataType.msisdn);

                context.commit(Mutations.SET_AUTH_TARGET_EMAILS, emails);
                context.commit(Mutations.SET_AUTH_TARGET_MSISDNS, msisdns);
            } catch (e: any) {
                Sentry.captureException(e);
                throw e;
            }
        },
        [Actions.SET_AUTH_2FA_SUCCESSFUL_STATE](context: ActionContext<UserAuthorizationStoreState, unknown>): void {
            const lsData: UserAuthorizationState = JSON.parse(sessionStorage.getItem(LOCAL_STORAGE_KEY) || '{}');
            if (lsData?.successful2faObj) {
                Object.keys(lsData?.successful2faObj).forEach(targetId => {
                    context.commit(Mutations.UPDATE_AUTH_2FA_SUCCESSFUL_STATUS, {
                        targetId,
                        authState: lsData.successful2faObj[targetId],
                    });
                });
            }
        },
    },
    getters: {
        [Getters.GET_AUTH_TARGET_EMAILS]: (state: UserAuthorizationStoreState): string[] =>
            state[State.AUTH_TARGET_EMAILS],
        [Getters.GET_AUTH_TARGET_MSISDNS]: (state: UserAuthorizationStoreState): string[] =>
            state[State.AUTH_TARGET_MSISDNS],
        [Getters.GET_AUTH_TOKEN_1FA]: (state: UserAuthorizationStoreState): string | undefined =>
            state[State.AUTH_TOKEN_1FA],
        [Getters.GET_AUTH_2FA_SUCCESSFUL]: (state: UserAuthorizationStoreState) => (targetId: string) =>
            state[State.AUTH_2FA_SUCCESSFUL]?.[targetId] || false,
        [Getters.GET_AUTH_USER_ID_FOR_AUTH_STATE_MAPPING]: (state: UserAuthorizationStoreState): string =>
            state[State.AUTH_USER_ID_FOR_AUTH_STATE_MAPPING] || '',
    },
};

export default store;
