var render = function render(){var _vm=this,_c=_vm._self._c;return _c('AppAditionalSidebar',{staticClass:"expand-pause-sidebar",attrs:{"visible":_vm.visibleSidebar},on:{"input":val => (_vm.isSidebarVisible = val)},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticClass:"d-flex align-items-center h-100"},[_c('p',{staticClass:"sidebar-header lf-table-title mb-2 text-uppercase"},[_vm._v(" "+_vm._s(_vm.$i18n.t('orchestrationEngine.expandPauseDuration'))+" ")])])]},proxy:true},{key:"content",fn:function(){return [_c('div',{staticClass:"entity-view"},[_c('div',{staticClass:"entity-row"},[_c('div',{staticClass:"entity-content"},[_c('div',{staticClass:"modal-row d-flex align-items-center"},[_c('div',{staticClass:"body-sm mr-4"},[_vm._v(" "+_vm._s(_vm.$i18n.t('customerCare.orderHistory.executionId'))+" ")]),_c('div',{staticClass:"body-sm"},[_c('router-link',{staticClass:"execution-link",attrs:{"to":{
                                    name: _vm.RouteNames.ORCHESTRATION_ENGINE_EXECUTION_DETAILS,
                                    params: {
                                        id: _vm.execution.planId,
                                        execId: _vm.execution.executionId,
                                    },
                                },"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.execution.executionId)+" ")])],1)]),_c('AppMultiselectV3',{staticClass:"multiselect small condition-options",attrs:{"options":_vm.DURATION_OPTIONS,"label":"label","trackBy":"id","optionId":"id","allowEmpty":false,"searchable":false,"showLabels":false,"borderNone":true,"blueArrow":true,"additionalLabel":_vm.$i18n.t('orchestrationEngine.selectDurationOfPause'),"explanationText":_vm.$i18n.t('orchestrationEngine.selectDurationOfPauseExplanation'),"tooltipOffset":20,"tooltipPosition":_vm.TOOLTIP_POSITION.top},model:{value:(_vm.selectedDuration),callback:function ($$v) {_vm.selectedDuration=$$v},expression:"selectedDuration"}})],1),_c('div',{staticClass:"entity-actions"},[_c('hr'),_c('div',{staticClass:"d-flex justify-content-end"},[_c('AppButton',{staticClass:"m-3",attrs:{"buttonType":_vm.BUTTON_TYPES.PRIMARY,"label":_vm.$i18n.t('generic.expand'),"disabled":_vm.expandExecutionButtonClicked || !_vm.writeEnabled,"data-test-id":"expand-button"},on:{"click":_vm.onExpand}})],1)])])])]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }