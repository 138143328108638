import http from '@/http/index';

const uploadPercentage = [];

/**
 * @param index
 * @returns {*}
 */
export function getUploadingPercentage(index) {
    return uploadPercentage[index];
}

/**
 * @param value
 */
export function setUploadingPercentage(value) {
    uploadPercentage.push(value);
}

/**
 * @param start
 * @param length
 */
export function removeUploadingPercentage(start = 0, length = uploadPercentage.length) {
    uploadPercentage.splice(start, length);
}

/**
 * @param files
 * @param url
 * @param index
 * @param requestHeaders
 * @returns {AxiosPromise}
 */
export function uploadToSignedURL(files, url, index, requestHeaders) {
    return http({
        method: 'PUT',
        url,
        headers: requestHeaders,
        data: files,
        onUploadProgress(progressEvent) {
            // eslint-disable-next-line radix
            uploadPercentage[index] = parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100));
        },
    });
}

/**
 * @param files
 * @param url
 * @param contentType
 * @returns {AxiosPromise}
 */
export function uploadToSignedURLNoProgress(files, url, contentType) {
    return http({
        method: 'PUT',
        url,
        headers: {
            'Content-Type': contentType,
            'x-ms-blob-type': 'BlockBlob',
        },
        data: files,
    });
}

export default {};
