import Vue from 'vue';
import Actions, { State, Getters, Mutations } from '@/store/mutation-types';
import { filterOutDeletedEntities, checkForUnpublishedChanges, STATUS_CODES } from '@/common/commonHelper';
import { EntityStateMapping } from '@/common/commonEntityStateMapper';
import ENTITY_TYPES from '@/common/entities/entityTypes';
import {
    getRewardRulesV4,
    getPrizes,
    getPrizeEngine,
    getRewardPayouts,
    getLotteries,
    getRewardRuleDraftList,
    getRewardPayoutDraftList,
    getPrizeDraftList,
    getPrizeEngineDraftList,
    getLotteryDraftList,
} from '@/__new__/services/dno/rewards/http/rewards';
import { getVoucherCategories, getVoucherSets, getVoucherSetDraftList } from '@/modules/rewards/http/vouchers';
import permissionsService from '@/services/permissions/permissions.service';
import LotteryModel from '../../__new__/services/dno/rewards/models/LotteryModel';

const ENTITIES_TYPES_TO_MODEL_MAP = new Map([[ENTITY_TYPES.LOTTERY, LotteryModel]]);

export default {
    namespaced: true,
    state: {
        [State.REWARDS_ENTITIES_BY_TYPE]: {},
        [State.REWARDS_ENTITIES_API_RESPONSE_BY_TYPE]: {},
    },
    mutations: {
        [Mutations.SET_REWARDS_ENTITIES]: (state, { type, entities }) => {
            Vue.set(state[State.REWARDS_ENTITIES_BY_TYPE], type, entities);
        },
        [Mutations.SET_REWARDS_ENTITIES_API_RESPONSE]: (state, { type, response }) => {
            Vue.set(state[State.REWARDS_ENTITIES_API_RESPONSE_BY_TYPE], type, response);
        },
    },
    actions: {
        async [Actions.REQUEST_REWARDS_ENTITIES_BY_TYPE]({ commit }, type, ids) {
            let response;
            let draftResponse;
            // Fetch Rewards Entities:
            try {
                if (type === ENTITY_TYPES.REWARD_RULE) {
                    response = await getRewardRulesV4(ids);
                    if (permissionsService.isRewardsDraftEnabled()) {
                        draftResponse = await getRewardRuleDraftList();
                    }
                }
                if (type === ENTITY_TYPES.PRIZE) {
                    const promises = [getPrizes(ids)];
                    if (permissionsService.isRewardsDraftEnabled()) {
                        promises.push(getPrizeDraftList());
                    }
                    [response, draftResponse] = await Promise.all(promises);
                }
                if (type === ENTITY_TYPES.PRIZE_ENGINE) {
                    const promises = [getPrizeEngine(ids)];
                    if (permissionsService.isRewardsDraftEnabled()) {
                        promises.push(getPrizeEngineDraftList());
                    }
                    [response, draftResponse] = await Promise.all(promises);
                }
                if (type === ENTITY_TYPES.VOUCHER_SET) {
                    const promises = [getVoucherSets(ids)];
                    if (permissionsService.isRewardsDraftEnabled()) {
                        promises.push(getVoucherSetDraftList());
                    }
                    [response, draftResponse] = await Promise.all(promises);
                }
                if (type === ENTITY_TYPES.REWARD_PAYOUT) {
                    const promises = [getRewardPayouts(ids)];
                    if (permissionsService.isRewardsDraftEnabled()) {
                        promises.push(getRewardPayoutDraftList());
                    }
                    [response, draftResponse] = await Promise.all(promises);
                }
                if (type === ENTITY_TYPES.LOTTERY) {
                    const promises = [getLotteries(ids)];
                    if (permissionsService.isRewardsDraftEnabled()) {
                        promises.push(getLotteryDraftList());
                    }
                    [response, draftResponse] = await Promise.all(promises);
                }
                if (type === ENTITY_TYPES.CATEGORY) {
                    response = await getVoucherCategories();
                }
                commit(Mutations.SET_REWARDS_ENTITIES_API_RESPONSE, { type, response });
            } catch (error) {
                commit(Mutations.SET_REWARDS_ENTITIES_API_RESPONSE, { type, response: error.response });
                throw error;
            }

            let entities = response?.data?.[`${type}_by_id`] || {};
            let draftentities = draftResponse?.data?.data || {};

            const EntityTypeModel = ENTITIES_TYPES_TO_MODEL_MAP.get(type);
            if (EntityTypeModel) {
                const replacementDraftEntities = {};
                const replacementEntities = {};
                Object.keys(draftentities).forEach(key => {
                    replacementDraftEntities[key] = new EntityTypeModel({
                        ...draftentities[key],
                        entityType: type,
                        isDraft: true,
                        state: STATUS_CODES.NA,
                        entityVersion: STATUS_CODES.DRAFT,
                        updateUser: draftentities[key].update_portal_id || draftentities[key].portal_id || null,
                        updateTime: draftentities[key].update_time,
                    });
                });
                Object.keys(entities).map(key => {
                    replacementEntities[key] = new EntityTypeModel({
                        ...entities[key],
                        entityType: type,
                        entityVersion: checkForUnpublishedChanges(entities[key], Object.values(draftentities)),
                        updateUser: entities[key].update_portal_id || entities[key].portal_id || null,
                    });
                });
                draftentities = replacementDraftEntities;
                entities = replacementEntities;
            } else {
                // todo error case:
                Object.keys(draftentities).forEach(key => {
                    draftentities[key].entityType = type;
                    draftentities[key].isDraft = true;
                    draftentities[key].state = STATUS_CODES.NA;
                    draftentities[key].entityVersion = STATUS_CODES.DRAFT;
                    draftentities[key].updateUser =
                        draftentities[key].update_portal_id || draftentities[key].portal_id || null;
                    draftentities[key].updateTime = draftentities[key].update_time;
                    draftentities[key].cohortExpressions = draftentities[key].data.cohort_expression;
                });
                Object.keys(entities).forEach(key => {
                    entities[key].entityType = type;
                    entities[key].entityVersion = checkForUnpublishedChanges(
                        entities[key],
                        Object.values(draftentities),
                    );
                    entities[key].updateUser = entities[key].update_portal_id || entities[key].portal_id || null;
                    entities[key].cohortExpressions = entities[key].data.cohort_expression;
                });
            }
            commit(Mutations.SET_REWARDS_ENTITIES, { type, entities: { ...draftentities, ...entities } });
        },
    },
    getters: {
        // this getter includes deleted entities
        [Getters.GET_REWARDS_ENTITIES_LIST_BY_TYPE]: state => type => {
            if (state[State.REWARDS_ENTITIES_BY_TYPE][type]) {
                return Object.values(state[State.REWARDS_ENTITIES_BY_TYPE][type]);
            }
            return [];
        },
        [Getters.GET_REWARDS_ENTITIES_API_RESPONSE_BY_TYPE]: state => type =>
            state[State.REWARDS_ENTITIES_API_RESPONSE_BY_TYPE][type],
        [Getters.GET_NOT_DELETED_REWARDS_ENTITIES_BY_TYPE]: (state, getters) => type =>
            filterOutDeletedEntities(getters[Getters.GET_REWARDS_ENTITIES_LIST_BY_TYPE](type)),
        [Getters.GET_REWARDS_ENTITY_BY_TYPE_AND_ID]: state => (type, id) =>
            state[State.REWARDS_ENTITIES_BY_TYPE][type] ? state[State.REWARDS_ENTITIES_BY_TYPE][type][id] : null,
        [Getters.GET_APPROVED_REWARDS_ENTITIES_BY_TYPE]: (state, getters) => type =>
            getters[Getters.GET_REWARDS_ENTITIES_LIST_BY_TYPE](type).filter(
                e => e.state === EntityStateMapping.APPROVED,
            ),
    },
};
