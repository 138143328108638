import { PLURALIZATION } from '@/common/locale/labelSingularOrPlural';
import { isEmpty, groupBy } from 'lodash';

export function overviewContentBuilder(_, template) {
    const propertyDefs = groupBy(template.properties, p => p.groupId);

    const groups = template.groups
        .map(group => ({
            name: group.name,
            isCollapsed: false,
            rows: propertyDefs[group.id]
                .sort((a, b) => a.sortWeight - b.sortWeight)
                .map(p => ({
                    name: p.name,
                    value: p.description,
                })),
        }))
        .sort((a, b) => a.sortWeight - b.sortWeight);

    let presetTemplates = [];
    if (template.presetTemplates?.length > 0) {
        const pluralLabel = template.presetTemplates?.length > 1 ? PLURALIZATION.PLURAL : PLURALIZATION.SINGULAR;

        presetTemplates = [
            {
                name: this.$i18n.tc('productCatalog.entities.presetTemplate', pluralLabel),
                isCollapsed: false,
                rows: template.presetTemplates.map(pt => ({
                    name: pt.name,
                    value: pt.externalId,
                })),
            },
        ];
    }

    return [
        {
            name: this.$i18n.t('generic.general'),
            isCollapsed: false,
            rows: [
                {
                    value: template.id,
                    name: this.$i18n.t('generic.id'),
                },
                {
                    value: template.externalId,
                    name: this.$i18n.t('generic.externalId'),
                },
            ],
        },
        ...groups,
        ...presetTemplates,
    ];
}

export function createComplexProp(field) {
    const result = [];
    // recursion function generates properties on all nesting levels
    function recursivelyCreateProperty(fieldName, properties, isMandatory) {
        Object.keys(properties).forEach(propName => {
            const prop = properties[propName];
            const containsNestedProp = !isEmpty(prop.properties);
            const subProp = {
                name: fieldName + '.' + propName,
                mandatory: isMandatory,
                type: prop.type,
                ...(containsNestedProp && { properties: prop.properties }),
            };
            result.push(subProp);
            if (containsNestedProp) {
                recursivelyCreateProperty(subProp.name, subProp.properties, isMandatory);
            }
        });
    }
    recursivelyCreateProperty(field.name, field.rawSchema.properties, field.mandatory);
    return result;
}

export default {};
