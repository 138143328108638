import httpClient from '@/http';
import { AxiosPromise } from 'axios';

export enum OAuthStrategyType {
    AZUREAD = 'azuread',
    OKTA = 'okta',
}

export interface OAuthConfig {
    clientId: string;
    clientSecret: string;
    companyId: number;
    id: number;
    name: string;
    oauthScopes: string;
    redirectUrl: string;
    type: OAuthStrategyType;
    urlAccessToken: string;
    urlAuthorize: string;
}

export interface OAuthCreateConfig {
    type: OAuthStrategyType;
    clientId: number;
    clientSecret: string;
    urlAuthorize: string;
    urlAccessToken: string;
    oauthScopes: string;
}

export function getOAuthConfig(provider: OAuthStrategyType): AxiosPromise<OAuthConfig> {
    return httpClient({
        method: 'GET',
        url: `/oauthwrite/config?provider=${provider}`,
    });
}

export function createOAuthConfig(config: OAuthCreateConfig) {
    return httpClient({
        method: 'POST',
        url: '/oauthwrite/config',
        data: {
            ...config,
        },
    });
}

export function getRoleMappings() {
    return httpClient({
        method: 'GET',
        url: '/oauthwrite/rolesmap',
    });
}

export function addRoleMappings(roleId: string, externalGroupId: string) {
    return httpClient({
        method: 'POST',
        url: '/oauthwrite/rolesmap',
        data: {
            roleId,
            externalGroupId,
        },
    });
}

export function removeRoleMappings(id: number) {
    return httpClient({
        method: 'DELETE',
        url: '/oauthwrite/rolesmap',
        data: {
            id,
        },
    });
}

export function getMappings() {
    return httpClient({
        method: 'GET',
        url: '/oauthwrite/externalidrolemapping',
    });
}

export function addMapping(externalUserId: string, roleId: string) {
    return httpClient({
        method: 'POST',
        url: '/oauthwrite/externalidrolemapping',
        data: {
            externalUserId,
            roleId,
        },
    });
}

export function updateMapping(id: number, externalUserId: string, roleId: string) {
    return httpClient({
        method: 'PUT',
        url: '/oauthwrite/externalidrolemapping',
        data: {
            id,
            externalUserId,
            roleId,
        },
    });
}

export function deleteMapping(id: number) {
    return httpClient({
        method: 'DELETE',
        url: '/oauthwrite/externalidrolemapping',
        data: {
            id,
        },
    });
}

export default {
    getRoleMappings,
    getOAuthConfig,
    createOAuthConfig,
    removeRoleMappings,
    addRoleMappings,
    getMappings,
    addMapping,
    updateMapping,
    deleteMapping,
};
