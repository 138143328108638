<template>
    <div class="f-text-input d-flex align-items-center">
        <Dropdown
            :items="operationLabels"
            :colorSchema="Styles.White"
            :selected="[value && JsonPathOperationsToLabels[value.op]]"
            caption="Select operation"
            :isFilterOperation="true"
            :widthDependsOnContent="true"
            @selected="opLabel => onChange({ op: JsonPathOperationLabelsToType[opLabel] })"
        />
    </div>
</template>

<script>
import Dropdown, { Styles } from '@/components/partials/Dropdown.vue';
import {
    JsonPathOperationLabels,
    JsonPathOperationLabelsToType,
    JsonPathOperationsToLabels,
    NoArgOperations,
} from '@/common/filters/InputOperations';

export default {
    name: 'FJsonPathInput',
    components: { Dropdown },
    props: {
        changeCallback: { type: Function, default: null },
        initialValue: { type: Array, default: null },
    },
    data() {
        return {
            Styles,
            JsonPathOperationsToLabels,
            JsonPathOperationLabelsToType,
            NoArgOperations,
            value: this.initialValue, // override
        };
    },
    computed: {
        isValid() {
            return true;
        },
        operationLabels() {
            return JsonPathOperationLabels;
        },
    },
    methods: {
        onChange(partialValue) {
            this.onValueChange(partialValue);
            if (this.changeCallback) this.changeCallback();
        },
        onValueChange(partialValue = {}) {
            return partialValue;
        },
    },
};
</script>

<style lang="scss" scoped>
@import '../../../~@/assets/scss/mixins';

.dropdown {
    @include custom-height-dropdown(32px);
    @include custom-width-dropdown(180px);
}
</style>
