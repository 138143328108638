/**
 * Fetch the list of all countries in Zones object.
 * @param list
 * @returns {Array}
 */
export function countries(list) {
    // Go over every element and check if it already exist in new array.
    // If it does exist move on, if it does not exist map and create new object in new array.
    const countryList = [];
    list.forEach(c => {
        // Check if exists in current array
        const countryMapped = {
            iso: c.iso,
            mcc: c.mcc,
            country_code: c.country_code,
            country: c.country,
        };

        if (!countryList.some(existingCountry => existingCountry.iso === c.iso)) {
            countryList.push(countryMapped);
        }
    });

    return countryList;
}

/**
 * Fetch only networks for said country.
 * @param iso
 * @param list
 * @returns {Array}
 */
export function networksForCountrySelected(iso, list) {
    const networkList = [];
    list.forEach(n => {
        if (!networkList.some(network => network.network === n.network) && n.iso === iso) {
            networkList.push({
                mnc: n.mnc,
                mcc: n.mcc,
                network: n.network,
                iso: n.iso,
            });
        }
    });
    return networkList;
}

/**
 * Function takes zones data object and converts it into something easier and faster to search.
 * @param zones
 */
export function parseZonesToMap(zones) {
    const res = {};

    zones.forEach(z => {
        if (!(z.iso in res)) {
            res[z.iso] = {
                country: z.country,
                mcc: z.mcc,
                networks: {},
            };
        }
        if (!res[z.iso].networks[z.network]) {
            res[z.iso].networks[z.network] = {
                mncs: [],
            };
        }
        res[z.iso].networks[z.network].mncs.push(z.mnc);
    });

    return res;
}
