import { TranslateResult } from 'vue-i18n';
import localeLibrary from '@/common/locale/localeLibrary';
import * as Utils from '@/common/utils';
import { OPERATION_TYPE, RescheduledExecutionDno } from '@/__new__/services/dno/orchestrationengine/batchActions';

export class BatchActions {
    id: string;
    operatorName: string;
    operationType: OPERATION_TYPE;
    createTime: number;
    createTimeLabel: TranslateResult;
    name: string;
    description: string;
    executionsAffected: string[];
    batchId: string;

    constructor(data: RescheduledExecutionDno, type: OPERATION_TYPE) {
        this.id = Utils.uuidV4();
        this.operatorName = data.operator_name;
        this.operationType = type;
        this.createTime = data.create_time;
        this.createTimeLabel = localeLibrary.getFormattedDate(data.create_time);
        this.name = data.name;
        this.description = data.description || '';
        this.executionsAffected = data.executions_affected;
        this.batchId = data.batch_id;
    }
}

export default BatchActions;
