import {
    SECTION_TYPES,
    ElementValue,
    Element,
    ElementRegex,
} from '@/__new__/features/dataflows/common/DataflowInterfaces';

export default class DataflowElement {
    id: string;

    required: boolean;

    label: string;

    description?: string;

    type: SECTION_TYPES;

    value?: string | ElementValue | null;

    values?: ElementValue[] | null;

    regex?: ElementRegex | null;

    error: boolean;

    constructor(data: Element) {
        this.id = data.id;
        this.required = data.required;
        this.label = data.label;
        this.description = data.description || '';
        this.type = data.type;
        this.value = data.value || null;
        this.values = data.values || null;
        this.regex = data.regex || null;
        this.error = false;
    }
}
