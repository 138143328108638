
import Vue from 'vue';

// Vuex
import { mapActions, mapGetters } from 'vuex';
import { Modules } from '@/store/store';
import Actions, { Getters } from '@/store/mutation-types';

// Components
import AppToggle from '@/components/partials/inputs/AppToggle.vue';

// http
import userManagementHTTP from '@/__new__/services/dno/user/http/user-management';

// Helpers
import { ALERT_TYPES } from '@/common/alerts/Alert';
import { FLAG_MAP, FLAG_TYPE, FLAG_VALUE_LABEL_MAP } from '@/__new__/services/dno/user/models/Flag';
import { isUserAllowed } from '@/services/permissions/permissions.service';
import Button from '@/common/button/Button';
import * as Sentry from '@sentry/vue';
import { TranslateResult } from 'vue-i18n';

type Flag = {
    detailedValue: TranslateResult;
    originalValue: FLAG_MAP;
    value: boolean;
    field: FLAG_TYPE;
};

export default Vue.extend({
    name: 'FlagsChange',
    components: {
        AppToggle,
    },
    props: {
        targetId: {
            required: true,
            type: String,
        },
        targetType: {
            required: true,
            type: Number,
        },
    },
    data() {
        return {
            flags: [],
            flagsForUpdate: {} as Record<FLAG_TYPE, boolean>,
            FLAG_TYPE,
        };
    },
    computed: {
        ...mapGetters(Modules.customerCareSuite, [Getters.GET_ASSOCIATED_UM_ENTITY_BY_TYPE_AND_ID]),
        entityData() {
            return this[Getters.GET_ASSOCIATED_UM_ENTITY_BY_TYPE_AND_ID]({
                targetType: this.targetType,
                targetId: this.targetId,
            });
        },
    },
    async created() {
        await this.$withProgressBar(
            async () => {
                await this[Actions.LOAD_ASSOCIATED_UM_ENTITIES]({
                    targetType: this.targetType,
                    targetId: this.targetId,
                });
                this.flags = [
                    {
                        label: this.$i18n.t('customerCare.subscriberTab.isFraud'),
                        flagData: this.getFlagData(FLAG_TYPE.IS_FRAUD),
                    },
                    {
                        label: this.$i18n.t('customerCare.account.flagTypes.blockPortOut'),
                        flagData: this.getFlagData(FLAG_TYPE.BLOCK_PORT_OUT),
                    },
                    {
                        label: this.$i18n.t('customerCare.account.flagTypes.isSimSwapBlocked'),
                        flagData: this.getFlagData(FLAG_TYPE.IS_SIM_SWAP_BLOCKED),
                    },
                ];
            },
            {
                errorHandler: () => {
                    this.$alert(this.$i18n.t('alertMessage.somethingWentWrongFetchingNecessaryData'), {
                        type: ALERT_TYPES.error,
                    });
                },
            },
        );
    },
    methods: {
        ...mapActions(Modules.customerCareSuite, [Actions.LOAD_ASSOCIATED_UM_ENTITIES]),
        getFlagData(flagType: FLAG_TYPE): Flag {
            return (
                this.entityData?.flags?.[flagType] || {
                    detailedValue: FLAG_VALUE_LABEL_MAP.get(FLAG_MAP.FALSE),
                    originalValue: FLAG_MAP.FALSE,
                    value: false,
                    field: flagType,
                }
            );
        },
        isFlagPending(flag: Flag): boolean {
            return flag.originalValue === FLAG_MAP.FALSE_PENDING || flag.originalValue === FLAG_MAP.TRUE_PENDING;
        },
        isFlagEditDisabled(flag: Flag): boolean {
            if (flag.field === FLAG_TYPE.IS_FRAUD) {
                return !isUserAllowed('UMManageFraudBlockFlag');
            }

            return this.isFlagPending(flag) || !isUserAllowed('UMSubscriberEditSubscriberToggleFlag');
        },
        updateFlag(field: FLAG_TYPE, value: boolean): void {
            if ((field === FLAG_TYPE.BLOCK_PORT_OUT || field === FLAG_TYPE.IS_SIM_SWAP_BLOCKED) && value) {
                this.$nextTick(() => {
                    document.getElementById(`${field}_flag`).checked = false;
                });
                const button = new Button({
                    label: this.$i18n.t('generic.confirm'),
                    alertType: ALERT_TYPES.warning,
                    handler: (): void => {
                        document.getElementById(`${field}_flag`).checked = true;
                        this.flagsForUpdate[field] = value;
                    },
                });
                this.$alert(
                    this.$i18n.t(
                        field === FLAG_TYPE.BLOCK_PORT_OUT
                            ? 'customerCare.errorAlertMessages.confirmBlockPortOutSubscriber'
                            : 'customerCare.errorAlertMessages.confirmBlockSimSwapSubscriber',
                    ),
                    {
                        type: ALERT_TYPES.warning,
                        buttons: [button],
                    },
                );
            } else {
                this.flagsForUpdate[field] = value;
            }
        },
        async updateFlags(): Promise<void> {
            await this.$withProgressBar(
                async () => {
                    await userManagementHTTP.updateFlags(this.targetId, this.targetType, this.flagsForUpdate);
                    this.$alert(this.$i18n.t('customerCare.subscriberTab.flagUpdateRequestSuccessful'), {
                        type: ALERT_TYPES.success,
                    });
                    this.$emit('isSensitiveOperationCompleted', true);
                },
                {
                    errorHandler: (error: any) => {
                        this.$alert(this.$i18n.t('customerCare.subscriberTab.flagUpdateRequestFail'), {
                            type: ALERT_TYPES.error,
                        });
                        Sentry.captureException(error);
                    },
                },
            );
        },
    },
});
