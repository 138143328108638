import httpClient, { DnoResponseBase, Entity, EntityState } from '@/http/index';
import { AxiosPromise } from 'axios';
import { FeeRule } from '@/__new__/services/dno/pricing/models/pricingDno';

export interface FeeRulesResponse extends DnoResponseBase {
    fee_rule_by_id: Record<string, Entity<FeeRule>>;
}

type getFeeRulesRequestPayload = {
    ids?: string[];
};

export const getFeeRules = (ids?: string[]): AxiosPromise<FeeRulesResponse> => {
    // Initialize data
    const data = {} as getFeeRulesRequestPayload;

    // Add ids if specified:
    if (ids) {
        data.ids = ids;
    }

    // Send request
    return httpClient({
        method: 'POST',
        url: '/v3pricing/getfeerules',
        data,
    });
};

export interface UpdateResponse extends DnoResponseBase {
    id: string;
    version: number;
}

export const updateFeeRuleState = (id: string, version: number, state: EntityState): AxiosPromise<UpdateResponse> => {
    return httpClient({
        method: 'POST',
        url: '/v3pricing/updatefeerulestate',
        data: {
            id,
            version,
            state,
        },
    });
};

export interface AddResponse extends DnoResponseBase {
    id: string;
}

export const addFeeRule = (data: FeeRule): AxiosPromise<AddResponse> => {
    return httpClient({
        method: 'POST',
        url: '/v3pricing/addfeerule',
        data: {
            data,
        },
    });
};

export const updateFeeRule = (id: string, version: number, data: FeeRule): AxiosPromise<UpdateResponse> => {
    return httpClient({
        method: 'POST',
        url: '/v3pricing/updatefeerule',
        data: {
            id,
            version,
            data,
        },
    });
};
