
// Components
import AbstractEditPageWrapper from '@/components/layout/AbstractEditPageWrapper.vue';
import AppHeader from '@/components/layout/AppHeader.vue';
import AppInputV3 from '@/components/partials/inputs/AppInputV3.vue';
import AppMultiselectV3 from '@/components/partials/inputs/AppMultiselectV3.vue';
import AppTextareaV3 from '@/components/partials/inputs/AppTextareaV3.vue';
import EditorButtons from '@/components/layout/EditorButtons.vue';
import DateTimePicker from '@/components/partials/inputs/DateTimePicker.vue';
import CardListRadioInput from '@/components/partials/cards/CardListRadioInput.vue';
import ValidationRuleSummary from '@/components/partials/cards/SummaryCard.vue';
import CustomOfferOption from '@/__new__/features/pc/validationRules/CustomOfferOption.vue';
import PurchaseLimit from '@/__new__/features/pc/offers/PurchaseLimit.vue';

// Misc
import Vue from 'vue';
import { mapGetters } from 'vuex';
import { compact, keyBy, map, set } from 'lodash';
import RouteNames from '@/router/routeNames';
import { Modules } from '@/store/store';
import { Getters } from '@/store/mutation-types';
import { TOOLTIP_POSITION } from '@/common/tooltip';
import { required, minLength } from 'vuelidate/lib/validators';
import {
    ValidationRuleType as RuleType,
    TargetTypeMap,
    PrerequisiteEval,
    ValidationRuleTargetType,
    VROperatorExpression,
    VROperatorExpressionMap,
} from '@/__new__/features/pc/validationRules/common/validationRule';
import ENTITY_TYPES from '@/common/entities/entityTypes';
import { ValidationRule, RawValidationRule } from '@/__new__/services/dno/pc/models/ValidationRule';
import { ValidationRulesService } from '@/__new__/services/dno/pc/http/validationRules';
import entityEditorMixin from '@/common/entityEditorMixin';
import EntityFetcherMixin from '@/__new__/features/pc/EntityFetcherMixin.vue';
import ProductCatalogOfferModel from '@/__new__/services/dno/pc/models/ProductCatalogOfferModel';

interface PurchaseLimitData extends RawValidationRule {
    /* eslint-disable-next-line camelcase */
    approve_on_create?: boolean;
}
export default Vue.extend({
    name: 'ValidationRuleEditor',

    components: {
        AbstractEditPageWrapper,
        AppHeader,
        AppInputV3,
        AppMultiselectV3,
        AppTextareaV3,
        DateTimePicker,
        EditorButtons,
        CardListRadioInput,
        ValidationRuleSummary,
        CustomOfferOption,
        PurchaseLimit,
    },

    mixins: [entityEditorMixin, EntityFetcherMixin],

    data() {
        return {
            entityType: ENTITY_TYPES.VALIDATION_RULE,
            validationRule: ValidationRule.fromDefault(),
            loadingOptions: false,
            validationRuleCopy: null as ValidationRule | null,
            evalOperationOptions: [
                { id: PrerequisiteEval.ALL, label: this.$t('productCatalog.validationRules.evalMustHaveAll') },
                { id: PrerequisiteEval.ANY, label: this.$t('productCatalog.validationRules.evalMustHaveOne') },
            ],
            operatorExpressionOptions: Object.values(VROperatorExpression).map(id => ({
                id,
                label: VROperatorExpressionMap[id],
            })),
            TOOLTIP_POSITION,
        };
    },

    computed: {
        ...mapGetters(Modules.productcatalog, [Getters.PC_GET_ENTITIES_BY_TYPE_NOT_DELETED]),
        isLoading(): boolean {
            return this.$Progress.get() > 0 && this.$Progress.get() < 100;
        },
        isSubscriptionLimitRule(): boolean {
            return this.validationRule.type === RuleType.SUBSCRIPTION_LIMIT;
        },
        isDynamicSubscriptionLimitRule(): boolean {
            return this.validationRule.type === RuleType.DYNAMIC_SUBSCRIPTION_LIMIT;
        },
        isPrerequisiteRule(): boolean {
            return this.validationRule.type === RuleType.PREREQUISITE;
        },
        isConflictRule(): boolean {
            return this.validationRule.type === RuleType.CONFLICT;
        },
        isPurchaseLimitRule(): boolean {
            return this.validationRule.type === RuleType.PURCHASE_LIMIT;
        },
        targetTypeOptions(): { id: number; value: number; label: string }[] {
            return compact(
                map(TargetTypeMap, (id, label) => {
                    if (this.isSubscriptionLimitRule && id === TargetTypeMap[ValidationRuleTargetType.GLOBAL])
                        return null;

                    return {
                        id,
                        value: id,
                        label: this.$t(`productCatalog.validationRules.${label}`),
                    };
                }),
            );
        },
        offers(): Record<string, ProductCatalogOfferModel[]> {
            const all = this[Getters.PC_GET_ENTITIES_BY_TYPE_NOT_DELETED](
                ENTITY_TYPES.OFFER,
            ) as ProductCatalogOfferModel[];
            // exclude offers that are already selected in another field
            return {
                all,
                primary: all.filter(
                    offer =>
                        ![this.validationRule.conflicting_entity_ids, this.validationRule.requires_entity_ids]
                            .flat()
                            .includes(offer.id),
                ),
                secondary: all.filter(offer => !this.validationRule.entity_ids.includes(offer.id)),
            };
        },
        offersById(): Record<string, ProductCatalogOfferModel> {
            return keyBy(this.offers.all, 'id');
        },
        isMaxPurchaseOverallPerDurationAvailable(): boolean {
            const payload = this.validationRule.toJson();
            const maxPurchaseOverAll = payload.data.max_purchases_overall_per_duration;
            return (
                maxPurchaseOverAll?.duration_unit &&
                maxPurchaseOverAll?.max_purchase &&
                maxPurchaseOverAll?.number_of_duration_units
            );
        },
        isMaxPurchasePerTargetAvailable(): boolean {
            const payload = this.validationRule.toJson();
            const maxPurchasePerTarget = payload.data.max_purchase_per_target || {};
            const targets = Object.values(maxPurchasePerTarget);
            if (targets.length === 0) {
                return false;
            }
            for (let i = 0, len = targets.length; i < len; i += 1) {
                if (
                    !targets[i].max_purchases_per_duration.duration_unit ||
                    !targets[i].max_purchases_per_duration.max_purchase ||
                    !targets[i].max_purchases_per_duration.number_of_duration_units
                ) {
                    return false;
                }
            }
            return true;
        },
        ruleTypeOptions(): { id: RuleType; label: string }[] {
            return Object.values(RuleType).map(id => ({
                id,
                label: this.$t(`productCatalog.validationRules.${id}`),
            }));
        },
        perEntityLimitOffers(): string[] {
            if (!this.isDynamicSubscriptionLimitRule) {
                return [];
            }

            return [...this.validationRule.entity_ids, ...this.validationRule.requires_entity_ids];
        },
        getLimitValue(): (offer: string, type: 'min' | 'max') => number {
            return (offer, type) => this.validationRule.per_entity_limit[offer]?.[type] || 0;
        },
    },

    validations: {
        validationRule: {
            name: {
                required,
            },
            max_subscription_limit: {
                maximum: {
                    required(value) {
                        return !this.isSubscriptionLimitRule || value > 0;
                    },
                },
            },
            entity_ids: {
                required,
                minLength: minLength(1),
            },
            conflicting_entity_ids: {
                required(value) {
                    return !this.isConflictRule || value.length > 0;
                },
            },
            requires_entity_ids: {
                required(value) {
                    return !this.isPrerequisiteRule || value.length > 0;
                },
            },
        },
    },

    mounted() {
        this.loadEditorData();
        this.loadOffers();
    },

    methods: {
        handlePurchaseLimit(purchaseLimit: any[]) {
            for (const key in purchaseLimit) {
                if (Object.prototype.hasOwnProperty.call(purchaseLimit, key)) {
                    this.validationRule[key] = purchaseLimit[key];
                }
            }
        },
        loadEditorData() {
            const { id } = this.$route.params;
            if (!id) return;

            this.$withLoadingSpinner(
                async () => {
                    const { data } = await ValidationRulesService.fetchOne(id);
                    this.validationRule = ValidationRule.fromJson(data.validation_rule_by_id[id]);
                    this.validationRuleCopy = ValidationRule.fromJson(data.validation_rule_by_id[id]);
                    this.addWatcher('validationRule');
                },
                {
                    errorHandler: () => {
                        this.$alert(this.$t('alertMessage.failedToLoadNecessaryData'));
                    },
                },
            );
        },
        async loadOffers() {
            this.loadingOptions = true;
            try {
                await this.fetchEntities(ENTITY_TYPES.OFFER);
            } catch (error) {
                this.$alert(this.$t('alertMessage.failedToLoadNecessaryData'));
            }
            this.loadingOptions = false;
        },
        onTypeChange({ id }: { id: RuleType }) {
            this.validationRule.type = id;
            this.validationRule.conflicting_entity_ids = [];
            this.validationRule.requires_entity_ids = [];
        },
        setLimitValue(offer: string, type: 'min' | 'max', value: number) {
            const limits = { ...this.validationRule.per_entity_limit };
            set(limits, [offer, type], value);
            this.validationRule.per_entity_limit = limits;
        },
        routeToListPage() {
            this.$router.push({
                name: RouteNames.VALIDATION_RULES_LIST,
                params: { companyId: this.$route.params.companyId },
            });
        },
        validatePurchaseLimitRuleTypeData(payload: PurchaseLimitData) {
            delete payload.data.target_type;
            if (!this.isMaxPurchaseOverallPerDurationAvailable) {
                delete payload.data.max_purchases_overall_per_duration;
            }
            if (!this.isMaxPurchasePerTargetAvailable) {
                delete payload.data.max_purchase_per_target;
            }
            if (!payload.data.max_purchases) {
                delete payload.data.max_purchases;
            }
            if (!this.isEditing) {
                delete payload.version;
            }

            if (
                !this.isMaxPurchaseOverallPerDurationAvailable &&
                !this.isMaxPurchasePerTargetAvailable &&
                !payload.data.max_purchases
            ) {
                return false;
            }
            return true;
        },
        saveValidationRule() {
            this.$Progress.start();
            this.$v.$touch();
            if (this.$v.$invalid) {
                this.$Progress.fail();
                return;
            }
            const save = this.isEditing ? ValidationRulesService.update : ValidationRulesService.add;
            const payload = this.validationRule.toJson();
            if (payload.data.type === RuleType.PURCHASE_LIMIT) {
                if (!this.validatePurchaseLimitRuleTypeData(payload)) {
                    this.$alert(this.$t('productCatalog.validationRules.inputMaxPurchaseOverall'));
                    return;
                }
            }
            save(payload)
                .then(() => {
                    this.$Progress.finish();
                    this.$alert(this.$t('productCatalog.validationRules.successfullySaved'), {
                        type: this.$ALERT_TYPES.success,
                    });
                    this.entityEditorMixin.successfullySaved = true;
                    setTimeout(this.routeToListPage, 3000);
                })
                .catch((error: { message: string }) => {
                    this.$Progress.fail();
                    this.$alert(error.message);
                });
        },
    },
});
