// Generic
import Vue from 'vue';
import Actions, { Mutations, State, Getters } from '@/store/mutation-types';
import { Module, ActionContext } from 'vuex';
import moment from 'moment';
import i18n from '@/i18n';

// Helpers
import { LAYOUT_TILE_SIZES, COMPONENT_KEYS } from '@/__new__/features/customerCareSuite/common/layoutSectionHelper';
import { CHALLENGE_TYPES, USER_MANAGER_HIERARCHY } from '@/__new__/features/customerCare/common/customerCareHelper';
import isEmpty from 'lodash/isEmpty';
import { mapUMEntityToHierarhyItem } from '@/__new__/features/customerCareSuite/common/umHierarhyMapperHelper';
import type { HierarchyItem } from '@/__new__/features/customerCareSuite/common/umHierarhyMapperHelper';
import { TranslateResult } from 'vue-i18n';

// Models
import Organization, { AccInfoInOrgInstance } from '@/__new__/services/dno/user/models/Organization';
import User from '@/__new__/services/dno/user/models/User';
import Account from '@/__new__/services/dno/user/models/Account';
import Subscriber from '@/__new__/services/dno/user/models/Subscriber';
import AccessRole from '@/__new__/services/dno/user/models/AccessRole';

// HTTP
import customerCareHTTP from '@/__new__/services/dno/user/http/customer-care';
import UM_HIERARCHY from '@/common/userManagerHierarchy';

export const LAYOUT_SECTION_CONFIG_MOCK = {
    testOperator: [
        {
            sectionName: 'TestName',
            layout: [
                {
                    i: 'id',
                    x: 1,
                    y: 1,
                    size: 'large',
                    tileTitle: 'testTitle',
                    componentKey: 'testKey',
                    props: {},
                },
            ],
        },
    ],
};

export interface LayoutTileConfig {
    i: string;
    x: number;
    y: number;
    size: LAYOUT_TILE_SIZES;
    tileTitle: string;
    componentKey: COMPONENT_KEYS;
    props: Record<string, unknown>;
}

export interface LayoutSectionConfig {
    sectionName: string;
    layout: LayoutTileConfig[];
}

export class AuthenticationHistoryRecord {
    dateAndTime: string;

    channel: CHALLENGE_TYPES;

    status: TranslateResult;

    challengeId: string | undefined;

    constructor(
        dateAndTime: string,
        channel: CHALLENGE_TYPES,
        status: TranslateResult,
        challengeId: string | undefined,
    ) {
        this.dateAndTime = dateAndTime;
        this.channel = channel;
        this.status = status;
        this.challengeId = challengeId;
    }
}

export interface CustomerCareSuiteStoreState {
    [State.LAYOUT_SECTION_CONFIGS]: Record<string, LayoutSectionConfig[]>;
    [State.ASSOCIATED_UM_ENTITIES]: Record<USER_MANAGER_HIERARCHY, Record<string, Account | User | Subscriber>>;
    [State.UM_ENTITIES_HIERARCHY]: Record<string, HierarchyItem>;
    [State.USER_AUTHENTICATION_STATE]: AuthenticationHistoryRecord | null;
}

const store: Module<CustomerCareSuiteStoreState, unknown> = {
    namespaced: true,
    state: {
        [State.LAYOUT_SECTION_CONFIGS]: {},
        [State.ASSOCIATED_UM_ENTITIES]: {
            [USER_MANAGER_HIERARCHY.USER]: {},
            [USER_MANAGER_HIERARCHY.ACCOUNT]: {},
            [USER_MANAGER_HIERARCHY.SUBSCRIBER]: {},
            [USER_MANAGER_HIERARCHY.ORGANIZATION]: {},
        },
        [State.UM_ENTITIES_HIERARCHY]: {},
        [State.USER_AUTHENTICATION_STATE]: null,
    },
    mutations: {
        [Mutations.SET_LAYOUT_SECTION_CONFIGS]: (
            state: CustomerCareSuiteStoreState,
            layoutSectionConfigs: Record<string, LayoutSectionConfig[]>,
        ) => {
            state[State.LAYOUT_SECTION_CONFIGS] = layoutSectionConfigs;
        },
        [Mutations.SET_ASSOCIATED_UM_ENTITIES]: (
            state: CustomerCareSuiteStoreState,
            associatedEntities: Record<USER_MANAGER_HIERARCHY, Record<string, Account | User | Subscriber>>,
        ) => {
            state[State.ASSOCIATED_UM_ENTITIES] = associatedEntities;
        },
        [Mutations.SET_UM_ENTITIES_HIERARCHY]: (
            state: CustomerCareSuiteStoreState,
            payload: Record<string, HierarchyItem>,
        ) => {
            state[State.UM_ENTITIES_HIERARCHY] = payload;
        },
        [Mutations.SET_ORGANIZATION_DATA]: (
            state: CustomerCareSuiteStoreState,
            payload: {
                organizationId: string;
                formattedOrganizationData: Organization;
            },
        ) => {
            Vue.set(
                state[State.ASSOCIATED_UM_ENTITIES][USER_MANAGER_HIERARCHY.ORGANIZATION],
                payload.organizationId,
                payload.formattedOrganizationData,
            );
        },
        [Mutations.SET_USER_DATA]: (
            state: CustomerCareSuiteStoreState,
            payload: {
                userId: string;
                formattedUserData: User;
            },
        ) => {
            Vue.set(
                state[State.ASSOCIATED_UM_ENTITIES][USER_MANAGER_HIERARCHY.USER],
                payload.userId,
                payload.formattedUserData,
            );
        },
        [Mutations.SET_ACCOUNT_DATA]: (
            state: CustomerCareSuiteStoreState,
            payload: {
                userId: string;
                formattedAccountData: Account;
            },
        ) => {
            Vue.set(
                state[State.ASSOCIATED_UM_ENTITIES][USER_MANAGER_HIERARCHY.ACCOUNT],
                payload.userId,
                payload.formattedAccountData,
            );
        },
        [Mutations.SET_SUBSCRIBER_DATA]: (
            state: CustomerCareSuiteStoreState,
            payload: {
                userId: string;
                formattedSubscriberData: Subscriber;
            },
        ) => {
            Vue.set(
                state[State.ASSOCIATED_UM_ENTITIES][USER_MANAGER_HIERARCHY.SUBSCRIBER],
                payload.userId,
                payload.formattedSubscriberData,
            );
        },
        [Mutations.SET_USER_AUTHENTICATION_STATE]: (
            state: CustomerCareSuiteStoreState,
            payload: {
                userAuthenticationState: AuthenticationHistoryRecord;
            },
        ) => {
            state[State.USER_AUTHENTICATION_STATE] = payload.userAuthenticationState;
        },
    },
    actions: {
        async [Actions.LOAD_LAYOUT_SECTION_CONFIGS](
            context: ActionContext<CustomerCareSuiteStoreState, unknown>,
        ): Promise<void> {
            //ToDo: Implement layout config load when API will be implemented
            context.commit(Mutations.SET_LAYOUT_SECTION_CONFIGS, LAYOUT_SECTION_CONFIG_MOCK);
        },
        async [Actions.LOAD_LAYOUT_SECTION_CONFIGS_BY_PAGE](
            context: ActionContext<CustomerCareSuiteStoreState, unknown>,
            // page: string,
        ): Promise<void> {
            //ToDo: Implement layout config load when API will be implemented
            context.commit(Mutations.SET_LAYOUT_SECTION_CONFIGS, LAYOUT_SECTION_CONFIG_MOCK);
        },
        async [Actions.SAVE_LAYOUT_SECTION_CONFIGS](
            context: ActionContext<CustomerCareSuiteStoreState, unknown>,
            // layoutSectionConfig: LayoutSectionConfig[],
        ): Promise<void> {
            //ToDo: Implement layout config save when API will be implemented
            context.commit(Mutations.SET_LAYOUT_SECTION_CONFIGS, LAYOUT_SECTION_CONFIG_MOCK);
        },
        async [Actions.SAVE_LAYOUT_SECTION_CONFIGS_BY_PAGE](
            context: ActionContext<CustomerCareSuiteStoreState, unknown>,
            // payload: { layoutSectionConfig: LayoutSectionConfig[]; page: string },
        ): Promise<void> {
            //ToDo: Implement layout config save when API will be implemented
            context.commit(Mutations.SET_LAYOUT_SECTION_CONFIGS, LAYOUT_SECTION_CONFIG_MOCK);
        },
        async [Actions.LOAD_ASSOCIATED_UM_ENTITIES](
            context: ActionContext<CustomerCareSuiteStoreState, unknown>,
            payload: {
                targetType: USER_MANAGER_HIERARCHY;
                targetId: string;
            },
        ): Promise<void> {
            const umEntitiesForHierarchy: Array<HierarchyItem> = [];
            if (payload.targetType === USER_MANAGER_HIERARCHY.ORGANIZATION) {
                const formattedOrganizationData = await context.dispatch(Actions.LOAD_ORGANIZATION, payload.targetId);

                if (formattedOrganizationData?.userOwnerId) {
                    const formattedUserData = await context.dispatch(
                        Actions.LOAD_USER,
                        formattedOrganizationData.userOwnerId,
                    );
                    umEntitiesForHierarchy.push(
                        mapUMEntityToHierarhyItem(formattedUserData, USER_MANAGER_HIERARCHY.USER, null),
                    );

                    if (formattedOrganizationData.accounts.length) {
                        formattedOrganizationData.accounts.forEach((acc: AccInfoInOrgInstance) => {
                            umEntitiesForHierarchy.push(
                                mapUMEntityToHierarhyItem(
                                    acc,
                                    USER_MANAGER_HIERARCHY.ACCOUNT,
                                    formattedOrganizationData.userOwnerId,
                                ),
                            );
                        });
                    }
                }
            }

            if (payload.targetType == USER_MANAGER_HIERARCHY.USER) {
                const formattedUserData = await context.dispatch(Actions.LOAD_USER, payload.targetId);

                umEntitiesForHierarchy.push(
                    mapUMEntityToHierarhyItem(formattedUserData, USER_MANAGER_HIERARCHY.USER, null),
                );

                // working with user related accs
                const accountIds = formattedUserData.permissions
                    .filter((permission: AccessRole) => permission.targetTypeId === USER_MANAGER_HIERARCHY.ACCOUNT)
                    .map((accPerm: AccessRole) => accPerm.targetId);

                const accountPromises: Array<Promise<Account>> = [];
                let accountsFormatted: Array<Account> = [];
                if (accountIds.length) {
                    accountIds.forEach((accId: string) =>
                        accountPromises.push(context.dispatch(Actions.LOAD_ACCOUNT, accId)),
                    );
                    accountsFormatted = await Promise.all(accountPromises);

                    for (const accountFormatted of accountsFormatted) {
                        umEntitiesForHierarchy.push(
                            mapUMEntityToHierarhyItem(
                                accountFormatted,
                                USER_MANAGER_HIERARCHY.ACCOUNT,
                                payload.targetId,
                            ),
                        );

                        // working with acc related subs
                        if (accountFormatted.subscribers?.length) {
                            const subscriberPromises: Array<Promise<Subscriber>> = [];
                            accountFormatted.subscribers.forEach((subscriberId: string) => {
                                subscriberPromises.push(context.dispatch(Actions.LOAD_SUBSCRIBER, subscriberId));
                            });
                            const subscriberMapped: Array<Subscriber> = await Promise.all(subscriberPromises);
                            subscriberMapped.forEach(subscriberMapped => {
                                umEntitiesForHierarchy.push(
                                    mapUMEntityToHierarhyItem(
                                        subscriberMapped,
                                        USER_MANAGER_HIERARCHY.SUBSCRIBER,
                                        accountFormatted.id,
                                    ),
                                );
                            });
                        }
                    }
                }
            }

            if (payload.targetType == USER_MANAGER_HIERARCHY.ACCOUNT) {
                // fetching accs
                const formattedAccountData = await context.dispatch(Actions.LOAD_ACCOUNT, payload.targetId);

                const userId = formattedAccountData.userId;

                umEntitiesForHierarchy.push(
                    mapUMEntityToHierarhyItem(formattedAccountData, USER_MANAGER_HIERARCHY.ACCOUNT, userId),
                );

                // fetching related user
                const formattedUserData = await context.dispatch(Actions.LOAD_USER, userId);

                umEntitiesForHierarchy.push(
                    mapUMEntityToHierarhyItem(formattedUserData, USER_MANAGER_HIERARCHY.USER, null),
                );

                if (formattedAccountData.subscribers?.length) {
                    const subscriberPromises: Array<Promise<Subscriber>> = [];
                    formattedAccountData.subscribers.forEach((subscriberId: string) => {
                        subscriberPromises.push(context.dispatch(Actions.LOAD_SUBSCRIBER, subscriberId));
                    });
                    const subscriberMapped: Array<Subscriber> = await Promise.all(subscriberPromises);
                    subscriberMapped.forEach(subscriberMapped => {
                        umEntitiesForHierarchy.push(
                            mapUMEntityToHierarhyItem(
                                subscriberMapped,
                                USER_MANAGER_HIERARCHY.SUBSCRIBER,
                                formattedAccountData.id,
                            ),
                        );
                    });
                }
            }

            if (payload.targetType == USER_MANAGER_HIERARCHY.SUBSCRIBER) {
                const formattedSubscriberData = await context.dispatch(Actions.LOAD_SUBSCRIBER, payload.targetId);

                umEntitiesForHierarchy.push(
                    mapUMEntityToHierarhyItem(
                        formattedSubscriberData,
                        USER_MANAGER_HIERARCHY.SUBSCRIBER,
                        formattedSubscriberData.accountId,
                    ),
                );

                const formattedAccountData = await context.dispatch(
                    Actions.LOAD_ACCOUNT,
                    formattedSubscriberData.accountId,
                );

                umEntitiesForHierarchy.push(
                    mapUMEntityToHierarhyItem(
                        formattedAccountData,
                        USER_MANAGER_HIERARCHY.ACCOUNT,
                        formattedSubscriberData.userId,
                    ),
                );

                // fetching related user
                const formattedUserData = await context.dispatch(Actions.LOAD_USER, formattedSubscriberData.userId);

                umEntitiesForHierarchy.push(
                    mapUMEntityToHierarhyItem(formattedUserData, USER_MANAGER_HIERARCHY.USER, null),
                );
            }

            if (umEntitiesForHierarchy && umEntitiesForHierarchy.length > 0) {
                const user = umEntitiesForHierarchy.find(
                    (umHierarchyEntity: HierarchyItem) => umHierarchyEntity.type === USER_MANAGER_HIERARCHY.USER,
                );
                const hierarchyTree = {
                    ...user,
                };
                const accounts = umEntitiesForHierarchy.filter((el: any) => el.type === USER_MANAGER_HIERARCHY.ACCOUNT);
                accounts.forEach(account => {
                    if (hierarchyTree.children) {
                        hierarchyTree.children.push(account);
                    }
                });

                const subscribers = umEntitiesForHierarchy.filter(
                    (el: any) => el.type === USER_MANAGER_HIERARCHY.SUBSCRIBER,
                );
                subscribers.forEach(subscriber => {
                    const acc = hierarchyTree?.children?.find((el: any) => el.id === subscriber.parentId);
                    if (acc?.children) {
                        acc.children.push(subscriber);
                    }
                });

                if (!isEmpty(hierarchyTree)) {
                    const hierarchyMainKey =
                        payload.targetType === USER_MANAGER_HIERARCHY.ORGANIZATION && user?.id
                            ? user.id
                            : payload.targetId;
                    context.commit(Mutations.SET_UM_ENTITIES_HIERARCHY, {
                        [hierarchyMainKey]: hierarchyTree,
                    });
                }
            }
        },
        async [Actions.LOAD_ORGANIZATION](
            context: ActionContext<CustomerCareSuiteStoreState, unknown>,
            targetId: string,
        ): Promise<Organization> {
            try {
                const organizationResponse = await customerCareHTTP.getOrganization(
                    targetId,
                    USER_MANAGER_HIERARCHY.ORGANIZATION,
                );

                const organizationInfo = organizationResponse?.data?.organization_info;

                const formattedOrganizationData = new Organization(
                    Organization.remapOrganizationFromBe(organizationInfo),
                );

                context.commit(Mutations.SET_ORGANIZATION_DATA, {
                    organizationId: targetId,
                    formattedOrganizationData,
                });

                return formattedOrganizationData;
            } catch (error) {
                throw error;
            }
        },
        async [Actions.LOAD_USER](
            context: ActionContext<CustomerCareSuiteStoreState, unknown>,
            targetId: string,
        ): Promise<User> {
            try {
                const userResponse = await customerCareHTTP.getUser(targetId, USER_MANAGER_HIERARCHY.USER);

                const formattedUserData = new User(User.remapUserFromBe(userResponse?.data?.user_info));

                context.commit(Mutations.SET_USER_DATA, {
                    userId: targetId,
                    formattedUserData,
                });

                return formattedUserData;
            } catch (error) {
                throw error;
            }
        },
        async [Actions.LOAD_ACCOUNT](
            context: ActionContext<CustomerCareSuiteStoreState, unknown>,
            targetId: string,
        ): Promise<Account> {
            try {
                const accountResponse = await customerCareHTTP.getAccount(targetId, USER_MANAGER_HIERARCHY.ACCOUNT);

                const formattedAccountData = new Account(
                    Account.remapAccountFromBe(accountResponse?.data?.account_info),
                );

                context.commit(Mutations.SET_ACCOUNT_DATA, {
                    userId: targetId,
                    formattedAccountData,
                });

                return formattedAccountData;
            } catch (error) {
                throw error;
            }
        },
        async [Actions.LOAD_SUBSCRIBER](
            context: ActionContext<CustomerCareSuiteStoreState, unknown>,
            targetId: string,
        ): Promise<Subscriber> {
            try {
                const subscriberResponse = await customerCareHTTP.getSubscriber(
                    targetId,
                    USER_MANAGER_HIERARCHY.SUBSCRIBER,
                );

                const formattedSubscriberData = new Subscriber(
                    Subscriber.remapUserFromBe(subscriberResponse?.data?.subscriber_info),
                );

                context.commit(Mutations.SET_SUBSCRIBER_DATA, {
                    userId: targetId,
                    formattedSubscriberData,
                });

                return formattedSubscriberData;
            } catch (error) {
                throw error;
            }
        },
        async [Actions.GENERATE_CODE](
            context: ActionContext<CustomerCareSuiteStoreState, unknown>,
            payload: {
                id: string;
                userType: number;
                contextType: number;
                challengeType: CHALLENGE_TYPES;
            },
        ) {
            try {
                const { data } = await customerCareHTTP.sendOTP(
                    payload.id,
                    payload.userType,
                    payload.contextType,
                    CHALLENGE_TYPES[payload.challengeType],
                );
                context.commit(Mutations.SET_USER_AUTHENTICATION_STATE, {
                    userAuthenticationState: new AuthenticationHistoryRecord(
                        moment().format('YYYY-MM-DD HH:mm'),
                        payload.challengeType,
                        i18n.t('customerCare.userAuthentication.sent'),
                        data.challenge_id,
                    ),
                });
            } catch (error) {
                throw error;
            }
        },
        async [Actions.VERIFY_CODE](
            context: ActionContext<CustomerCareSuiteStoreState, unknown>,
            payload: {
                challengeId: string;
                otp: number;
                contextType: number;
                challengeType: CHALLENGE_TYPES;
            },
        ) {
            try {
                await customerCareHTTP.verifyOTP(
                    payload.challengeId,
                    payload.otp,
                    payload.contextType,
                    CHALLENGE_TYPES[payload.challengeType],
                );
                context.commit(Mutations.SET_USER_AUTHENTICATION_STATE, {
                    userAuthenticationState: new AuthenticationHistoryRecord(
                        moment().format('YYYY-MM-DD HH:mm'),
                        payload.challengeType,
                        i18n.t('customerCare.userAuthentication.verified'),
                        undefined,
                    ),
                });
            } catch (error) {
                throw error;
            }
        },
    },
    getters: {
        [Getters.GET_LAYOUT_SECTION_CONFIGS]: (
            state: CustomerCareSuiteStoreState,
        ): Record<string, LayoutSectionConfig[]> => state[State.LAYOUT_SECTION_CONFIGS] || {},
        [Getters.GET_ASSOCIATED_UM_ENTITY_BY_TYPE_AND_ID]:
            (state: CustomerCareSuiteStoreState) =>
            (payload: { targetType: USER_MANAGER_HIERARCHY; targetId: string }): User | Account | Subscriber | null =>
                state[State.ASSOCIATED_UM_ENTITIES][payload.targetType][payload.targetId] || null,
        [Getters.GET_UM_ENTITY_HIERARCHY_BY_TYPE_AND_ID]:
            (state: CustomerCareSuiteStoreState) =>
            (payload: { targetId: string; targetType: UM_HIERARCHY }): HierarchyItem | null => {
                if (payload.targetType === UM_HIERARCHY.USER) {
                    return state[State.UM_ENTITIES_HIERARCHY]?.[payload.targetId] || null;
                }
                if (payload.targetType === UM_HIERARCHY.ACCOUNT) {
                    const user = Object.values(state[State.UM_ENTITIES_HIERARCHY])[0];
                    return user?.children.find(child => child.id === payload.targetId) || null;
                }
                return null;
                // TODO: subscriber handling
            },
        [Getters.GET_ASSOSIATED_CHILD_ENTITIES_BY_TYPE_AND_ID]:
            (state: CustomerCareSuiteStoreState) =>
            (payload: { targetId: string; targetType: UM_HIERARCHY }): Array<HierarchyItem> => {
                if (payload.targetType === UM_HIERARCHY.USER) {
                    return state[State.UM_ENTITIES_HIERARCHY]?.[payload.targetId]?.children || [];
                } else if (payload.targetType === UM_HIERARCHY.ACCOUNT) {
                    const user = Object.values(state[State.UM_ENTITIES_HIERARCHY])[0];
                    const acc = user?.children.find(children => children.id === payload.targetId);
                    return acc?.children || [];
                }
                return [];
            },
        [Getters.GET_USER_AUTHENTICATION_STATE]: _state => _state[State.USER_AUTHENTICATION_STATE],
    },
};

export default store;
