
import Vue from 'vue';
// Validation
import { validationMixin } from 'vuelidate';
// components
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import { ICON_TYPES } from '@/common/iconHelper';
import AppInputV3 from '@/components/partials/inputs/AppInputV3.vue';
import AppMultiselectV3 from '@/components/partials/inputs/AppMultiselectV3.vue';
// http
import userManagementHTTP from '@/__new__/services/dno/user/http/user-management';
// helpers
import { mapGetters } from 'vuex';
import { USER_MANAGER_HIERARCHY } from '@/__new__/features/customerCare/common/customerCareHelper';
import { getGrantRoleOptions } from '@/services/permissions/permissions.service';
import { isValidUuid } from '@/common/uuidHelper';
import luaErrors from '@/common/luaErrors';
import * as Sentry from '@sentry/vue';

export const userManagerHierarchyTypeToIdLabel = new Map([
    [USER_MANAGER_HIERARCHY.ACCOUNT, 'customerCare.accountId'],
    [USER_MANAGER_HIERARCHY.SUBSCRIBER, 'customerCare.accountId'],
    [USER_MANAGER_HIERARCHY.ORGANIZATION, 'customerCare.accountId'],
]);

export default Vue.extend({
    name: 'GrantPermission',
    components: {
        AppButton,
        AppInputV3,
        AppMultiselectV3,
    },
    mixins: [validationMixin],
    props: {
        userId: {
            required: true,
            type: String,
        },
    },
    data() {
        return {
            ICON_TYPES,
            BUTTON_TYPES,
            entityId: '',
            roleName: '',
            roleType: '' as any | { id: number; label: string },
            roleTypes: getGrantRoleOptions(),
        };
    },
    validations: {
        entityId: {
            valid: id => isValidUuid(id),
        },
    },
    computed: {
        ...mapGetters('userManagementUser', {
            roles: 'getRoles',
        }),
        roleNames() {
            return [...new Set(Array.isArray(this.roles) ? this.roles.map(el => el.role) : [])];
        },
        disabledSetRoleBtn() {
            return !this.roleName || !this.roleType?.id || !this.entityId || this.$v.$error;
        },
        placeholderEntityId() {
            return userManagerHierarchyTypeToIdLabel.has(this.roleType.id)
                ? this.$i18n.t(userManagerHierarchyTypeToIdLabel.get(this.roleType.id))
                : '';
        },
        errorMessageEntityId() {
            let message = this.$i18n.t('customerCare.tooltips.invalidFormat');

            if (userManagerHierarchyTypeToIdLabel.has(this.roleType.id)) {
                message += ` ${this.$i18n.t(userManagerHierarchyTypeToIdLabel.get(this.roleType.id))}`;
            }

            return message;
        },
    },
    methods: {
        clearGrantPermissionId() {
            this.entityId = '';
        },
        async sendSetRoleRequest() {
            try {
                this.$v.$touch();
                if (this.$v.$error) {
                    return;
                }

                this.$Progress.start();

                await userManagementHTTP.setRole(this.entityId, this.roleType.id, this.userId, this.roleName);

                this.$Progress.finish();
                this.$showSuccessAlert({
                    message: this.$i18n.t('alertMessage.successMessageWithoutRedirect', {
                        entityName: this.$i18n.t('generic.role'),
                        action: this.$i18n.t('generic.set'),
                    }),
                });
            } catch (e) {
                this.$Progress.fail();
                const errorCode = e?.response?.data?.code;

                if (
                    [luaErrors.USER.ACCOUNT_NOT_FOUND.code, luaErrors.USER.SUBSCRIBER_NOT_FOUND.code].includes(
                        errorCode,
                    )
                ) {
                    this.$showErrorAlert({
                        message: this.$i18n.t('alertMessage.userManagement.invalidInfoProvidedForRoleSet'),
                    });
                } else {
                    Sentry.captureException(e);
                    this.$showErrorAlert({
                        message: this.$i18n.t('alertMessage.errorDoingSmthTryAgain', {
                            action: this.$i18n.t('generic.setting'),
                            entityName: this.$i18n.t('generic.role').toLowerCase(),
                        }),
                    });
                }
            }
        },
    },
});
