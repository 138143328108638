
import Vue, { type PropType } from 'vue';

// Components
import AppDialogV2 from '@/components/partials/AppDialogV2.vue';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import AppIcon from '@/components/partials/icon/AppIcon.vue';
import FileUploader from '@/components/partials/inputs/FileUploader.vue';

// Helpers
import { ICON_TYPES, ICON_COLORS } from '@/common/iconHelper';
import type { DlqFileResponse } from '@/__new__/features/sinkConfigs/common/dlqHelper';

// Http
import pyperHTTP from '@/__new__/services/dno/pyper/dlq';
import { uploadToSignedURL } from '@/http/fileUploader';

export default Vue.extend({
    name: 'DLQUploadDialog',
    components: {
        AppDialogV2,
        AppButton,
        AppIcon,
        FileUploader,
    },
    props: {
        visible: {
            type: Boolean,
            default: false,
        },
        entity: {
            type: Object as PropType<DlqFileResponse> | undefined,
            default: undefined,
        },
    },
    data() {
        return {
            BUTTON_TYPES,
            ICON_COLORS,
            ICON_TYPES,
            file: undefined as File | undefined,
            fileLoadingPercentage: 0 as number,
            fileIndex: 0 as number,
            fileUploadId: '' as string,
            isSubmiting: false as boolean,
            isUploading: false as boolean,
        };
    },
    computed: {
        title(): string {
            return this.entity ? this.$t('dlq.replaceDlq') : this.$t('dlq.uploadNewDlq');
        },
        description(): string {
            return this.entity ? this.$t('dlq.replaceDialogDescription', { fileName: this.entity.fileName }) : '';
        },
        submitBtnLabel(): string {
            return this.entity ? this.$t('generic.replace') : this.$t('operator.upload');
        },
        isSubmitDisabled(): boolean {
            return !this.file || this.isUploading || this.isSubmiting;
        },
    },
    methods: {
        closeModal(): void {
            this.file = undefined;
            this.fileLoadingPercentage = 0;
            this.$emit('close');
        },
        onFileChange(files: File[]): void {
            [this.file] = files;
        },
        trackFileUploadPercentage(): void {
            const inter = setInterval(() => {
                this.fileLoadingPercentage = this.$Progress.get();

                if (this.isSubmiting) {
                    this.fileLoadingPercentage = 100;
                    clearInterval(inter);
                }
            }, 500);
        },
        async uploadFile(): Promise<void> {
            await this.$withProgressBar(
                async () => {
                    this.isUploading = true;
                    // eslint-disable-next-line prettier/prettier
                    const { data: { data: { signed_url: uploadUrl, upload_id: uploadId } } } = await pyperHTTP.getUploadUrl();
                    this.fileUploadId = uploadId;

                    const newFile = new File([this.file as File], this.fileUploadId);
                    const blob = new Blob([newFile], { type: 'binary' });

                    this.trackFileUploadPercentage();
                    await uploadToSignedURL(blob, uploadUrl, this.fileIndex, undefined);
                    this.isUploading = false;

                    if (!this.entity) {
                        this.$showSuccessAlert({ message: this.$t('dlq.uploadSuccess') });
                        this.$emit('upload');
                    }
                },
                {
                    errorHandler: () => {
                        this.isUploading = false;
                        this.$alert(this.$t('dlq.uploadFail'));
                    },
                },
            );
        },
        async replaceFile(): Promise<void> {
            await this.$withProgressBar(
                async () => {
                    this.isUploading = false;
                    this.isSubmiting = true;

                    await pyperHTTP.replaceDlq(this.entity.fileEntryID, this.fileUploadId);

                    this.$showSuccessAlert({ message: this.$t('dlq.replaceSuccess') });
                    this.isSubmiting = false;
                    this.$emit('replace', this.entity);
                },
                {
                    errorHandler: () => {
                        this.isSubmiting = false;
                        this.$alert(this.$t('dlq.replaceFail'));
                    },
                },
            );
        },
        async onSubmit(): Promise<void> {
            await this.uploadFile();

            if (this.entity) {
                await this.replaceFile();
            }

            this.closeModal();
        },
    },
});
