import { render, staticRenderFns } from "./SubscriberDetailsEditor.vue?vue&type=template&id=420a5f32&scoped=true"
import script from "./SubscriberDetailsEditor.vue?vue&type=script&lang=ts"
export * from "./SubscriberDetailsEditor.vue?vue&type=script&lang=ts"
import style0 from "./SubscriberDetailsEditor.vue?vue&type=style&index=0&id=420a5f32&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "420a5f32",
  null
  
)

export default component.exports