<template>
    <div>
        <div
            :class="{ 'flex-wrap': wrapHeader }"
            class="d-flex align-items-center"
        >
            <slot name="beforeMultiselect" />
            <AppMultiselectV3
                :options="unselectedOptions"
                :placeholder="placeholder"
                :multiple="true"
                :small="true"
                :label="label"
                :trackBy="trackBy"
                :loading="loading"
                :additionalLabel="additionalLabel"
                :error="error"
                :errorMessage="errorMessage"
                :optional="optional"
                :disabled="disabled"
                :taggable="taggable"
                :tagPlaceholder="tagPlaceholder"
                class="half-of-input-width"
                :class="{ 'full-width': fullWidth }"
                @input="updateValue"
                @tag="onTagInput"
            />
        </div>
        <div class="explanation">
            {{ explanation }}
        </div>
        <div
            :class="isManyTags && { 'expanded-container': isExpanded, 'collapsed-container': !isExpanded }"
            class="tags-container"
        >
            <div
                v-if="!loading"
                ref="tagsContainer"
            >
                <Tags
                    v-model="selectedItems"
                    :labelFormatter="labelFormatter"
                    :disabled="disabled"
                    class="pt-1 pb-1"
                />
            </div>
        </div>
        <div v-if="isManyTags">
            <div class="d-flex justify-content-start align-items-center">
                <span
                    :class="{ 'blue-arrow-down': !isExpanded, 'blue-arrow-up': isExpanded }"
                    class="arrow-position-fix"
                />
                <span
                    v-if="!isExpanded"
                    class="seeMoreButton"
                    @click="toggleExpand"
                >
                    {{ `${$i18n.t('generic.seeMore')}...` }}
                </span>
                <span
                    v-else
                    class="seeMoreButton"
                    @click="toggleExpand"
                >
                    {{ `${$i18n.t('generic.seeLess')}` }}
                </span>
            </div>
        </div>
    </div>
</template>

<script>
import AppMultiselectV3 from '@/components/partials/inputs/AppMultiselectV3.vue';
import Tags from '@/components/partials/inputs/Tags.vue';
import differenceWith from 'lodash/differenceWith';
import isEqual from 'lodash/isEqual';

export default {
    name: 'TagsMultiselect',
    components: {
        AppMultiselectV3,
        Tags,
    },
    props: {
        options: {
            type: Array,
            required: true,
        },
        value: {
            type: Array,
            required: true,
        },
        label: {
            default: undefined,
            type: String,
        },
        trackBy: {
            default: undefined,
            type: String,
        },
        placeholder: {
            type: String,
            default: '',
        },
        labelFormatter: {
            type: Function,
            default: entity => entity,
        },
        explanation: {
            type: String,
            default: null,
        },
        tagPlaceholder: {
            type: String,
            required: false,
            default: '',
        },
        loading: {
            type: Boolean,
            default: false,
        },
        wrapHeader: {
            type: Boolean,
            default: true,
        },
        additionalLabel: {
            type: String,
            default: '',
        },
        error: {
            type: Boolean,
            default: false,
        },
        errorMessage: {
            type: String,
            default: '',
        },
        optionId: {
            type: String,
            default: '',
        },
        optional: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        fullWidth: {
            type: Boolean,
            default: false,
        },
        taggable: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            isExpanded: false,
            tagsContainerHeight: null,
        };
    },
    computed: {
        unselectedOptions() {
            return differenceWith(this.options, this.valueFormatted, isEqual);
        },
        valueFormatted() {
            if (this.optionId) {
                return this.options.filter(option => this.value.includes(option[this.optionId]));
            }

            return this.value;
        },
        selectedItems: {
            get() {
                return this.valueFormatted;
            },
            set(selectedItems) {
                let items = [...selectedItems];

                if (this.optionId) {
                    items = items.map(item => item[this.optionId]);
                }

                this.$emit('input', items);
            },
        },
        isManyTags() {
            return this.tagsContainerHeight > 80;
        },
    },
    watch: {
        // to reset the state to be callapsed by default
        isManyTags(isMany) {
            if (isMany === false) {
                this.isExpanded = false;
            }
        },
    },
    updated() {
        this.tagsContainerHeight = this.$refs?.tagsContainer?.getBoundingClientRect()?.height || 0;
    },
    methods: {
        updateValue(event) {
            const selectedItem = event[0];
            let updatedValue = [...this.valueFormatted, selectedItem];

            if (this.optionId) {
                updatedValue = updatedValue.map(val => val[this.optionId]);
            }

            this.$emit('input', updatedValue);
        },
        toggleExpand() {
            this.isExpanded = !this.isExpanded;
        },
        onTagInput(tag) {
            if (this.optionId) {
                this.updateValue([
                    {
                        [this.optionId]: tag,
                        [this.label]: tag,
                    },
                ]);
            } else {
                this.updateValue([tag]);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/_palette.scss';
@import '~@/assets/scss/palette';

@import '~@/assets/scss/icons';

$icon-path: '~@/assets/icons/';

.blue-arrow-down {
    width: 1rem;
    height: 1rem;
    content: url($icon-path + $blue-arrow-down);
}

.blue-arrow-up {
    width: 1rem;
    height: 1rem;
    content: url($icon-path + $blue-arrow-up);
}

.arrow-position-fix {
    margin-right: 0.5rem;
}

.explanation {
    font-size: 0.75rem;
    color: $gray60;
    margin-top: 0.5rem;
}
.half-of-input-width {
    min-width: 17.6875rem;
    width: 30%;

    &.full-width {
        width: 100%;
    }
}
.transitioning-area {
    background-image: linear-gradient(to bottom, transparent, $white);

    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 45px;
    width: 100%;

    display: flex;
    justify-content: center;
    align-items: flex-end;
}

.tags-container {
    position: relative;
}

.seeMoreButton {
    font-size: 0.75rem;
    text-align: center;
    color: $blue;
    cursor: pointer;
    font-weight: 600;
}

.expanded-container {
    height: 100%;
}

.collapsed-container {
    height: 40px;
    overflow: hidden;
}
</style>
