<template>
    <div>
        <AbstractTableTile
            :entities="entities"
            :columnsData="columnsData"
            :apiResponse="rawResponse"
            :entityKey="'execution_id'"
            @rowSelected="id => selectItem(id)"
        >
            <template #planId="{ entity }">
                <div
                    v-if="entity.planId"
                    class="truncate-text"
                >
                    <router-link
                        :to="{
                            name: RouteNames.ORCHESTRATION_ENGINE_EXECUTION_DETAILS,
                            params: {
                                id: entity.planId,
                                execId: entity.executionId,
                            },
                        }"
                        target="_blank"
                    >
                        {{ entity.planId }}
                    </router-link>
                </div>
            </template>
            <template #status="{ entity }">
                <ExecutionStatusIndicator
                    v-if="entity.status"
                    :status="entity.status"
                />
            </template>
        </AbstractTableTile>
    </div>
</template>

<script>
import Vue from 'vue';

// components
import AbstractTableTile from '@/__new__/features/customerCareSuite/components/AbstractTableTile.vue';
import ExecutionStatusIndicator from '@/__new__/features/orchestrationengine/ExecutionStatusIndicator.vue';

// helpers
import tableColumnType from '@/common/filterTable';
import RouteNames from '@/router/routeNames';
import { mapActions, mapGetters } from 'vuex';
import Actions, { Getters } from '@/store/mutation-types';
import * as Sentry from '@sentry/vue';

export default Vue.extend({
    name: 'OEWorkflow',
    components: {
        AbstractTableTile,
        ExecutionStatusIndicator,
    },
    data() {
        return {
            entities: [],
            rawResponse: {},
            selectedEntity: {},
            showAdditionalSidebar: false,

            RouteNames,
        };
    },
    computed: {
        ...mapGetters('orchestrationengine', {
            getOEMappedExecutions: Getters.GET_OE_MAPPED_EXECUTIONS,
            getOEMappedExecutionsApiResponse: Getters.GET_OE_MAPPED_EXECUTIONS_API_RESPONSE,
        }),
        columnsData() {
            return [
                {
                    name: this.$i18n.t('customerCare.orderHistory.planId'),
                    key: 'planId',
                    forbidHideColumn: true,
                    field: 'planId',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('customerCare.orderHistory.executionId'),
                    key: 'executionId',
                    forbidHideColumn: true,
                    field: 'executionId',
                    filterType: tableColumnType.GENERAL_TEXT,
                    width: 20,
                },
                {
                    name: this.$i18n.t('orchestrationEngine.startTimestamp'),
                    key: 'startedAtFormatted',
                    forbidHideColumn: true,
                    sortBy: cycle => cycle.startedAt,
                    field: 'startedAt',
                    filterType: tableColumnType.DATE,
                },
                {
                    name: this.$i18n.t('orchestrationEngine.endTimestamp'),
                    key: 'finishedAtFormatted',
                    forbidHideColumn: true,
                    sortBy: cycle => cycle.finishedAt,
                    field: 'finishedAt',
                    filterType: tableColumnType.DATE,
                },
                {
                    name: this.$i18n.t('orchestrationEngine.scheduledAt'),
                    key: 'scheduledAtFormatted',
                    field: 'scheduledAt',
                    sortBy: entity => entity.scheduledAt,
                    filterType: tableColumnType.DATETIME,
                },
                {
                    name: this.$i18n.t('orchestrationEngine.scheduledFor'),
                    key: 'scheduledForFormatted',
                    field: 'scheduledFor',
                    sortBy: entity => entity.scheduledFor,
                    filterType: tableColumnType.DATETIME,
                },
                {
                    name: this.$i18n.t('generic.status'),
                    key: 'status',
                    forbidHideColumn: true,
                    field: 'status',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
            ];
        },
    },
    async created() {
        await this.fetchTileData();
    },
    methods: {
        ...mapActions('orchestrationengine', {
            requestOEMappedExecutions: Actions.REQUEST_OE_MAPPED_EXECUTIONS,
        }),
        async fetchTileData() {
            this.$emit('isDataLoadingUpd', true);
            await this.$withProgressBar(
                async () => {
                    await this.requestOEMappedExecutions({ mapper_ids: [this.$route.params.id] });
                    this.entities = this.getOEMappedExecutions;
                    this.rawResponse = this.getOEMappedExecutionsApiResponse;
                    this.$emit('isDataLoadingUpd', false);
                },
                {
                    errorHandler: e => {
                        this.apiResponse = e.response;
                        this.$alert(this.$i18n.t('alertMessage.somethingWentWrong'));
                        Sentry.captureException(e);
                        this.$emit('isDataLoadingUpd', false);
                    },
                },
            );
        },
        selectItem(id) {
            this.selectedEntity = this.entities.find(el => el.execution_id === id);
            this.showAdditionalSidebar = true;
        },
    },
});
</script>
