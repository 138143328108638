import NcsBaseCondition from '@/__new__/services/dno/charging/models/chargingSpecificationConditions/NCSbaseCondition';
import { uuidV4 } from '@/common/utils';
import { PC_CONDITION_TYPES } from '@/__new__/services/dno/charging/common/chargingSpecificationHelper';

export default class SubscriberLocationConditionModel extends NcsBaseCondition {
    constructor(
        { data, uuid, type, dataEmpty = true, dataValid = false } = {
            data: {
                inverted: false,
                src: { '': [] },
            },
            uuid: uuidV4(),
            type: PC_CONDITION_TYPES.SUBSCRIBER_LOCATION,
            dataEmpty: true,
            dataValid: false,
        },
    ) {
        super();
        this.data = data;
        this.type = type;
        this.uuid = uuid;
        this.dataEmpty = dataEmpty;
        this.dataValid = dataValid;
    }

    validationCondition() {
        return Object.entries(this.data.src).every(([key]) => key !== '');
    }

    isDataValid() {
        this.dataValid = this.validationCondition();
    }
}
