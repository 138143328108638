export default {
    defaultToken: '',
    tokenPostfix: '.mhtml',
    ignoreCase: true,

    tokenizer: {
        root: [
            [/<!--/, 'comment', '@comment'],
            [/(<)((?:[\w-]+:)?[\w-]+)(\s*)(\/>)/, ['delimiter', 'tag', '', 'delimiter']],
            [/(<)((?:[\w-]+:)?[\w-]+)/, ['delimiter', { token: 'tag', next: '@otherTag' }]],
            [/(<\/)((?:[\w-]+:)?[\w-]+)/, ['delimiter', { token: 'tag', next: '@otherTag' }]],
            [
                /[{]{2}/,
                {
                    token: 'keyword',
                    bracket: '@open',
                    next: '@mustache',
                },
            ],
            [/</, 'delimiter'],
            [/[^<{]+/], // text
        ],

        comment: [
            [/-->/, 'comment', '@pop'],
            [/[^-]+/, 'comment.content'],
            [/./, 'comment.content'],
        ],

        otherTag: [
            [/\/?>/, 'delimiter', '@pop'],
            [/"([^"]*)"/, 'attribute.value'],
            [/'([^']*)'/, 'attribute.value'],
            [/[\w-]+/, 'attribute.name'],
            [/=/, 'delimiter'],
            [/[ \t\r\n]+/], // whitespace
        ],

        mustache: [
            [/[^}]+/, 'keyword'],
            [
                /[}]{2}/,
                {
                    token: 'keyword',
                    bracket: '@close',
                    next: '@pop',
                },
            ],
        ],
    },
};
