
// Components
import AppIcon from '@/components/partials/icon/AppIcon.vue';
import AppCheckbox from '@/components/partials/inputs/AppCheckbox.vue';

// Helpers
import { ICON_COLORS, ICON_TYPES } from '@/common/iconHelper';

export default {
    name: 'ViewsTree',
    components: {
        AppIcon,
        AppCheckbox,
    },
    props: {
        view: {
            type: Object,
            default: () => undefined,
            required: true,
        },
        depth: {
            type: Number,
            default: 1,
        },
        lastChild: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            isOpen: false,
            isRowHovered: false,

            // Proxy imports
            ICON_COLORS,
            ICON_TYPES,
        };
    },
    computed: {
        children() {
            return this.view.views;
        },
        numChildren() {
            return typeof this?.view?.views === 'object' && !Array.isArray(this.view.views)
                ? Object.values(this.view.views).length
                : 0;
        },
    },
    methods: {
        onArrowClicked() {
            this.isOpen = !this.isOpen;
        },
        emitFeatureStateChange(value) {
            if (value) {
                this.isOpen = true;
            }
            this.$emit('change', {
                keys: [
                    {
                        viewId: this.view.id,
                    },
                ],
                value,
                viewId: this.view.id,
            });
        },
        emitFeatureTreeChange(event) {
            this.$emit('change', {
                keys: [
                    {
                        viewId: this.view.id,
                    },
                    ...event.keys,
                ],
                value: event.value,
                viewId: event.viewId,
            });
        },
    },
};
